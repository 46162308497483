'use strict'

angular
  .module('basalteApp')
  .constant('BAS_DISCOVERY_LOCAL', {
    EVT_DISCOVERY_LOCAL_START: 'evtBasDiscoveryLocalStart',
    EVT_DISCOVERY_LOCAL_CORE_SERVICE_FOUND:
            'evtBasDiscoveryLocalCoreServiceFound',
    EVT_DISCOVERY_LOCAL_CORE_SERVICE_LOST:
            'evtBasDiscoveryLocalCoreServiceLost',
    MIN_TIMEOUT_BETWEEN_START_MS: 500
  })
