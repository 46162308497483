'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var log = require('./logger')

/**
 * Holds all necessary information for Deezer requests
 *
 * @constructor
 * @extends EventEmitter
 * @param {number} id CobraNet ID
 * @param {BasCore} basCore
 * @since 1.5.0
 */
function Deezer (id, basCore) {

  EventEmitter.call(this)

  this._basCore = basCore
  this._id = id

  // TODO Handle Linking via WebRTC

  this._linked = false

  this._token = ''
  this._userId = 0
  this._username = ''
  this._type = -1

  this._clear()

  this._dirty = true

  this._statusPromise = null

  this._handleLinkUrl = this._onLinkUrl.bind(this)
}

Deezer.prototype = Object.create(EventEmitter.prototype)
Deezer.prototype.constructor = Deezer

// region Events

/**
 * @event Deezer#EVT_LINK_CHANGED
 * @param {boolean} isLinked Current link state
 */

/**
 * Oauth redirect has reached the basCore
 *
 * @event Deezer#EVT_LINK_FINISHED
 * @param {boolean} finished
 * @since 1.7.0
 */

/**
 * Oauth failed
 *
 * @event Deezer#EVT_LINK_ERROR
 * @param {string} message
 * @since 1.7.0
 */

// endregion

/**
 * @constant {string}
 */
Deezer.EVT_DEEZER_LINK_CHANGED = 'deezerLinkChanged'

/**
 * @constant {string}
 * @since 1.7.0
 */
Deezer.EVT_LINK_FINISHED = 'deezerLinkFinished'

/**
 * @constant {string}
 * @since 1.7.0
 */
Deezer.EVT_LINK_ERROR = 'deezerLinkError'

/**
 * @constant {string}
 */
Deezer.ERR_INVALID_LINK_URL = 'errDeezerInvalidLinkUrl'

/**
 * @constant {string}
 */
Deezer.ERR_INVALID_RESPONSE = 'errDeezerInvalidResponse'

/**
 * @name Deezer#isLinked
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Deezer.prototype, 'isLinked', {
  get: function () {
    return this._linked
  }
})

/**
 * @name Deezer#token
 * @type {string}
 * @readonly
 */
Object.defineProperty(Deezer.prototype, 'token', {
  get: function () {
    return this._token
  }
})

/**
 * @name Deezer#userId
 * @type {number}
 * @readonly
 */
Object.defineProperty(Deezer.prototype, 'userId', {
  get: function () {
    return this._userId
  }
})

/**
 * Subscription type
 *
 * @name Deezer#type
 * @type {number}
 * @readonly
 */
Object.defineProperty(Deezer.prototype, 'type', {
  get: function () {
    return this._type
  }
})

/**
 * @name Deezer#username
 * @type {string}
 * @readonly
 */
Object.defineProperty(Deezer.prototype, 'username', {
  get: function () {
    return this._username
  }
})

/**
 * @name Deezer#dirty
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Deezer.prototype, 'dirty', {
  get: function () {
    return this._dirty
  }
})

/**
 * Parse Deezer object from messages received from the basCore
 *
 * @param {?Object} message
 */
Deezer.prototype.parse = function (message) {

  // Check message
  if (BasUtil.isObject(message)) {

    // Check for errors
    if (BasUtil.safeHasOwnProperty(message, P.ERROR)) {

      log.error(
        'Deezer - Parse' +
        ' - Player ' + this._id +
        ' - Error',
        message[P.ERROR]
      )
    }

    // Token
    if (BasUtil.isNEString(message[P.TOKEN])) {

      this._token = message[P.TOKEN]
    }

    // User ID
    if (BasUtil.isVNumber(message[P.USERID])) {

      this._userId = message[P.USERID]
    }

    // Username
    if (BasUtil.isNEString(message[P.USERNAME])) {

      this._username = message[P.USERNAME]
    }

    // Type
    if (BasUtil.isVNumber(message[P.STATUS])) {

      this._type = message[P.STATUS]
    }

    // Link finished
    if (BasUtil.isBool(message[P.LINK_FINISHED])) {

      this.emit(
        Deezer.EVT_LINK_FINISHED,
        message[P.LINK_FINISHED]
      )
    }

    // Link errors
    if (BasUtil.isString(message[P.LINK_ERROR])) {

      // Check for known errors
      switch (message[P.LINK_ERROR]) {
        case P.LINK_ERROR_USER_DENIED:
        case P.LINK_ERROR_TOKEN:
        case P.LINK_ERROR_UNKNOWN_ERROR:

          this.emit(
            Deezer.EVT_LINK_ERROR,
            message[P.LINK_ERROR]
          )

          break
        default:

          log.error(
            'Deezer - parse' +
            ' - linkError' +
            ' - Unknown error',
            message
          )

          this.emit(
            Deezer.EVT_LINK_ERROR,
            message[P.LINK_ERROR_UNKNOWN]
          )
      }
    }

    // Linked
    if (BasUtil.isBool(message[P.LINKED])) {

      this._dirty = false

      if (this._linked !== message[P.LINKED]) {

        this._linked = message[P.LINKED]

        this.emit(
          Deezer.EVT_DEEZER_LINK_CHANGED,
          this._linked
        )
      }
    }

    // Clear link information if necessary
    if (!this._linked) this._clear()
  }
}

/**
 * Clears the link information
 *
 * @private
 */
Deezer.prototype._clear = function () {

  this._token = ''
  this._userId = 0
  this._username = ''
  this._type = -1
}

/**
 * Fetch the Deezer status
 *
 * @returns {Promise<Deezer>}
 */
Deezer.prototype.status = function () {

  var _this, data

  _this = this

  if (this._statusPromise) return this._statusPromise

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DEEZER][P.TYPE] = P.STATUS

  this._statusPromise = this._basCore.requestRetry(
    data,
    CONSTANTS.RETRY_OPTS_LONG_LOGIN
  ).then(_onStatus, _onError)

  return this._statusPromise

  function _onStatus (result) {

    _this._statusPromise = null

    if (_this.isValidDeezerMessage(result)) {

      _this.parse(result[P.PLAYER][P.DEEZER])

      return _this
    }

    return Promise.reject(Deezer.ERR_INVALID_RESPONSE)
  }

  function _onError () {

    _this._statusPromise = null
  }
}

/**
 * Retrieve the Deezer link URL from the basCore
 *
 * @returns {Promise<string>}
 */
Deezer.prototype.linkUrl = function () {

  var data

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DEEZER][P.TYPE] = P.LINK_URL

  return this._basCore.requestRetry(data).then(this._handleLinkUrl)
}

/**
 * @private
 * @param {Object} result
 * @returns {(string|Promise)}
 */
Deezer.prototype._onLinkUrl = function (result) {

  var _linkUrl

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (this.isValidDeezerMessage(result)) {

    _linkUrl = result[P.PLAYER][P.DEEZER][P.LINK_URL]

    return BasUtil.isNEString(_linkUrl)
      ? this._basCore.getHTTPUrl(_linkUrl)
      : Promise.reject(Deezer.ERR_INVALID_LINK_URL)
  }

  return Promise.reject(Deezer.ERR_INVALID_RESPONSE)
}

/**
 * Disconnect the current Deezer link
 */
Deezer.prototype.disconnect = function () {

  var data = this._getBasCoreMessage()
  data[P.PLAYER][P.DEEZER][P.TYPE] = P.LINK_DISCONNECT

  this._basCore.send(data)
}

/**
 * Checks whether a basCore message is a valid Deezer message
 *
 * @param {?Object} message
 * @returns {boolean}
 */
Deezer.prototype.isValidDeezerMessage = function (message) {
  return (
    BasUtil.isObject(message) &&
    BasUtil.isObject(message[P.PLAYER]) &&
    message[P.PLAYER][P.ID] === this._id &&
    BasUtil.isObject(message[P.PLAYER][P.DEEZER])
  )
}

/**
 * @returns {Object}
 */
Deezer.prototype._getBasCoreMessage = function () {

  var data = {}
  data[P.PLAYER] = {}
  data[P.PLAYER][P.ID] = this._id
  data[P.PLAYER][P.DEEZER] = {}

  return data
}

/**
 * Destructor
 *
 * @since 1.9.0
 */
Deezer.prototype.destroy = function destroy () {

  this.removeAllListeners()
  this._basCore = null
}

module.exports = Deezer
