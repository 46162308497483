'use strict'

angular
  .module('basalteApp')
  .factory('BasSchedulerFilter', basSchedulerFilterFactory)

/**
 * @callback CBasSchedulerFilterCheck
 * @param {BasSchedulerPoint} point
 * @returns {boolean}
 */

function basSchedulerFilterFactory () {

  /**
   * @constructor
   */
  function BasSchedulerFilter () {

    /**
     * @type {string}
     */
    this.id = ''

    /**
     * @type {string}
     */
    this.translationId = ''

    /**
     * @type {CBasSchedulerFilterCheck}
     */
    this.filter = allFilter
  }

  return BasSchedulerFilter

  function allFilter () {
    return true
  }
}
