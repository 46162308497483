'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('TidalHelper', [
    'BAS_API',
    'BAS_TIDAL',
    'BAS_LIBRARY_ERRORS',
    'BAS_SOURCE',
    'TidalElement',
    'LibraryState',
    'Logger',
    TidalHelper
  ])

/**
 * Helper Service for getting Tidal Objects
 *
 * @constructor
 * @param BAS_API
 * @param {BAS_TIDAL} BAS_TIDAL
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param TidalElement
 * @param {LibraryState} LibraryState
 * @param Logger
 */
function TidalHelper (
  BAS_API,
  BAS_TIDAL,
  BAS_LIBRARY_ERRORS,
  BAS_SOURCE,
  TidalElement,
  LibraryState,
  Logger
) {
  var serviceName = 'Tidal Helper'

  var ALBUM_ID = 'albumIds'
  var ARTIST_ID = 'artistIds'
  var PLAYLIST_ID = 'uuids'
  var TRACK_ID = 'trackIds'

  /**
   * @param {string} imageId
   * @param {string} size
   * @returns {string}
   */
  this.getImage = function (imageId, size) {

    var link = 'https://resources.tidal.com/images/'

    link += imageId.replace(/-/g, '/')

    link += '/' + size

    link += '.jpg'

    return link
  }

  /**
   * @param {TidalElement} element
   * @param {string} [option]
   */
  this.play = function (element, option) {

    var uri

    // Get player instance
    var basSource = LibraryState.getCurrentSource()

    if (
      basSource &&
      basSource.source &&
      basSource.source.queue
    ) {

      switch (element.type) {
        case TidalElement.TYPE_ALBUM:

          basSource.source.queue.addTidalAlbum(
            element.id,
            option
          )

          break
        case TidalElement.TYPE_ARTIST:

          basSource.source.queue.addTidalArtist(
            element.id,
            option
          )

          break
        case TidalElement.TYPE_PLAYLIST:

          basSource.source.queue.addTidalPlaylist(
            element.id,
            option
          )

          break
        case TidalElement.TYPE_TRACK:

          basSource.source.queue.addTidalTrack(
            element.id,
            option
          )

          break
        default:
          Logger.warn(serviceName +
                        ' - Play' +
                        ' - Unsupported type')
      }
    } else if (
      basSource &&
      basSource.isAudioSource
    ) {

      uri = this.getTidalUri(element.repeatId)

      if (basSource.canAddToQueue) {

        if (element.type === TidalElement.TYPE_TRACK) {

          basSource.source.queueAddItems([uri], option, true)

        } else {

          basSource.source.queueAddUri(uri, option, true)
        }
      } else {

        basSource.playUri(uri)
      }
    }
  }

  /**
   * @param {number[]} items
   * @param {string} option
   */
  this.playSelection = function (items, option) {

    var uris, i, length

    var basSource = LibraryState.getCurrentSource()

    if (
      basSource &&
      basSource.source
    ) {

      if (basSource.isAudioSource) {

        uris = []

        length = items.length
        for (i = 0; i < length; i++) {
          uris.push(this.getTidalUri(items[i], true))
        }

        basSource.source.queueAddItems(uris, option, true)

      } else if (basSource.source.queue) {

        basSource.source.queue.addTidalTrackList(items, option)
      }

    }
  }

  /**
   * @param {string} type
   * @param {number} id
   * @param {Object} [basSource]
   * @returns Promise
   */
  this.checkFavourite = function (
    type,
    id,
    basSource
  ) {
    var _basSource

    // Get player instance
    _basSource = !basSource ? LibraryState.getTidalSource() : basSource

    if (_basSource && _basSource.tidal.isLinked()) {

      return _basSource.tidal.getFavorites().then(checkId)

    } else {

      return Promise.reject('Invalid player instance')
    }

    function checkId (result) {
      var contain

      switch (type) {
        case TidalElement.TYPE_ALBUM:
          contain = result['ALBUM'].indexOf('' + id) >= 0
          break
        case TidalElement.TYPE_ARTIST:
          contain = result['ARTIST'].indexOf('' + id) >= 0
          break
        case TidalElement.TYPE_PLAYLIST:
          contain = result['PLAYLIST'].indexOf('' + id) >= 0
          break
        case TidalElement.TYPE_TRACK:
          contain = result['TRACK'].indexOf('' + id) >= 0
          break
        default:
          return Promise.reject(new Error('not a valid Tidal element'))
      }

      return Promise.resolve(contain)
    }
  }

  /**
   * Add or remove Tidal favourite ID
   *
   * @param {number|string} tidalId
   * @param {string} tidalType
   * @param {boolean} isFavourite
   * @returns {Promise<(boolean|string)>}
   */
  this.addRemoveFavouriteId = function (
    tidalId,
    tidalType,
    isFavourite
  ) {
    var basSource, type, data

    basSource = LibraryState.getTidalSource()

    // Check player
    if (!(basSource && basSource.tidal.isLinked())) {

      return Promise.reject(BAS_LIBRARY_ERRORS.NO_TIDAL)
    }

    // Adjust params according to Tidal object type
    switch (tidalType) {
      case TidalElement.TYPE_ALBUM:

        type = BAS_TIDAL.FAV_ALBUMS
        data = ALBUM_ID + '=' + tidalId

        break
      case TidalElement.TYPE_ARTIST:

        type = BAS_TIDAL.FAV_ARTISTS
        data = ARTIST_ID + '=' + tidalId

        break
      case TidalElement.TYPE_PLAYLIST:

        type = BAS_TIDAL.FAV_PLAYLISTS
        data = PLAYLIST_ID + '=' + tidalId

        break
      case TidalElement.TYPE_TRACK:

        type = BAS_TIDAL.FAV_TRACKS
        data = TRACK_ID + '=' + tidalId

        break
      default:
        Logger.warn(
          serviceName + ' - addRemoveTidalFavourite - Unsupported type',
          tidalType
        )
        return Promise.reject('Unknown type')
    }

    if (isFavourite === true) {

      // Remove favorite
      return basSource.tidal.removeFavourite(type, tidalId)

    } else if (isFavourite === false) {

      // Add favourite
      return basSource.tidal.addFavourite(type, data)

    } else {
      Logger.warn(
        serviceName + ' - addRemoveTidalFavourite - makeFavourite is invalid',
        isFavourite
      )
      return Promise.reject('Invalid favourite state')
    }
  }

  /**
   * @param {TidalElement} element
   * @param {TidalCollection} collection
   * @param {Object} can
   */
  this.checkContextCapabilities = function (
    element,
    collection,
    can
  ) {
    var canArtist, isArtistDetail, canAlbum, isAlbumDetail, hasDetail
    var basSource, onlyReplace, hasQueueCapabilities, canPlayUri

    onlyReplace = false
    hasQueueCapabilities = false
    canPlayUri = false

    // Check element
    if (BasUtil.isObject(element) &&
      BasUtil.isNEString(element.type)) {

      basSource = LibraryState.getCurrentSource()

      if (basSource) {
        if (
          basSource.source &&
          basSource.source.queue
        ) {

          onlyReplace = (
            basSource.source.queue.type ===
            BAS_API.Queue.TYPE_STREAM ||
            basSource.source.queue.type ===
            BAS_API.Queue.TYPE_DEEZER_FLOW ||
            basSource.source.queue.type ===
            BAS_API.Queue.TYPE_DEEZER_RADIO
          )
        }

        hasQueueCapabilities = basSource.isAudioSource
          ? basSource.canAddToQueue
          : true

        canPlayUri = basSource.isAudioSource
          ? basSource.source.allowsExecute(
            BAS_API.AudioSource.C_PLAY_URI
          )
          : true
      }

      switch (element.type) {
        case TidalElement.TYPE_ALBUM:

          canArtist = BasUtil.isObject(element.artist)
          isArtistDetail = (
            BasUtil.isObject(collection) &&
            BasUtil.isObject(collection.detailElement) &&
            collection.detailElement.type ===
            TidalElement.TYPE_ARTIST
          )

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.tidal = true
          can.addPlaylist = true
          can.goToArtist = !isArtistDetail && canArtist

          break
        case TidalElement.TYPE_PLAYLIST:

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.tidal = true
          can.addPlaylist = true

          break
        case TidalElement.TYPE_ARTIST:
          break
        case TidalElement.TYPE_RADIO:

          can.playNow = canPlayUri

          break
        case TidalElement.TYPE_TRACK:

          hasDetail = (
            BasUtil.isObject(collection) &&
            BasUtil.isObject(collection.detailElement)
          )
          canArtist = BasUtil.isObject(element.artist)
          isArtistDetail = (
            hasDetail &&
            collection.detailElement.type ===
            TidalElement.TYPE_ARTIST
          )
          canAlbum = BasUtil.isObject(element.album)
          isAlbumDetail = (
            hasDetail &&
            collection.detailElement.type ===
            TidalElement.TYPE_ALBUM
          )

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.tidal = true
          can.addPlaylist = true
          can.goToAlbum = canAlbum && !isAlbumDetail
          can.goToArtist = canArtist && !isArtistDetail

          break
        default:
          Logger.warn(serviceName + ' - Unsupported type', element.type)
      }
    } else {
      Logger.warn(serviceName + ' - Unknown element', element)
    }
  }

  /**
   * @param {TidalElement} element
   * @returns {Promise}
   */
  this.getElementData = function (element) {

    var basSource, tidal, func

    if (BasUtil.isObject(element) &&
      BasUtil.isNEString(element.type)) {

      switch (element.type) {

        case TidalElement.TYPE_TRACK:
          return Promise.resolve([element.id])

        case TidalElement.TYPE_ALBUM:

          basSource = LibraryState.getTidalSource()

          if (basSource && basSource.tidal.isLinked()) {

            tidal = basSource.tidal
            func = tidal.getAlbumTracksLib.bind(tidal, element.id)

            return basSource.tidal.getXElements(
              func,
              BAS_TIDAL.TIDAL_MAX_COLLECTION_RETRIEVE_LENGTH,
              0,
              -1
            ).then(onData)
          }

          return Promise.reject(BAS_LIBRARY_ERRORS.NO_TIDAL)

        case TidalElement.TYPE_PLAYLIST:

          basSource = LibraryState.getTidalSource()

          if (basSource && basSource.tidal.isLinked()) {

            tidal = basSource.tidal
            func = tidal.getPlaylistTracksLib
              .bind(tidal, element.id)
            return basSource.tidal.getXElements(
              func,
              BAS_TIDAL.TIDAL_MAX_COLLECTION_RETRIEVE_LENGTH,
              0,
              -1
            )
              .then(onData)
          }

          return Promise.reject(BAS_LIBRARY_ERRORS.NO_TIDAL)
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ELEMENT)

    function onData (result) {

      var i, length
      var array = []

      if (BasUtil.isObject(result) &&
        Array.isArray(result.items)) {

        length = result.items.length

        for (i = 0; i < length; i++) {

          if (BasUtil.isPNumber(result.items[i].id)) {

            array.push(result.items[i].id)
          }
        }
      }

      return array
    }
  }

  /**
   * @param {TidalElement} element
   * @returns {boolean}
   */
  this.isPlaylist = function (element) {

    var basSource = LibraryState.getTidalSource()

    return (
      element &&
      element.type === TidalElement.TYPE_PLAYLIST &&
      basSource &&
      basSource.tidal.getId() === element.creator
    )
  }

  /**
   * @param {string} id
   * @param {TidalElement} element
   * @returns {Promise}
   */
  this.addElementToPlaylist = function (id, element) {

    var _this = this

    return BasUtil.isNEString(id)
      ? this.getElementData(element).then(onData)
      : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ID)

    function onData (result) {

      return Array.isArray(result)
        ? _this.addToPlaylist(id, result)
        : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
    }
  }

  /**
   * @param {string} playlistName
   * @param {TidalElement} element
   * @returns {Promise}
   */
  this.addElementToNewPlaylist = function (
    playlistName,
    element
  ) {
    var _this = this

    return BasUtil.isNEString(playlistName)
      ? this.getElementData(element).then(onData)
      : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_NAME)

    function onData (result) {

      return Array.isArray(result)
        ? _this.addToNewPlaylist(playlistName, result)
        : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
    }
  }

  /**
   * @param {string} id
   * @param {number[]} items
   * @returns {Promise}
   */
  this.addToPlaylist = function (id, items) {

    var basSource = LibraryState.getTidalSource()

    return (
      basSource &&
      basSource.tidal &&
      basSource.tidal.isLinked &&
      basSource.tidal.isLinked()
    )
      ? basSource.tidal.getPlaylist(id)
        .then(
          basSource.tidal.addSongsToPlaylist.bind(basSource.tidal, id, items)
        )
      : Promise.reject(BAS_LIBRARY_ERRORS.NO_TIDAL)
  }

  /**
   * @param {string} name
   * @param {number[]} items
   * @returns {Promise}
   */
  this.addToNewPlaylist = function (name, items) {

    var _this, basSource

    _this = this
    basSource = LibraryState.getTidalSource()

    return (
      basSource &&
      basSource.tidal &&
      basSource.tidal.isLinked &&
      basSource.tidal.isLinked()
    )
      ? basSource.tidal.addNewPlaylist(
        '' + basSource.tidal.getId(),
        name
      ).then(onSuccess)
      : Promise.reject(BAS_LIBRARY_ERRORS.NO_TIDAL)

    function onSuccess (result) {

      if (BasUtil.isObject(result) &&
        BasUtil.isNEString(result.uuid)) {

        return _this.addToPlaylist(result.uuid, items)
      }

      return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ID)
    }
  }

  /**
   * @returns {Promise<Object[]>}
   */
  this.getPlaylists = function () {

    var basSource, id, func

    basSource = LibraryState.getTidalSource()

    if (BasUtil.isObject(basSource) &&
      basSource.tidal.isLinked()) {

      id = basSource.tidal.getId()

      func = basSource.tidal.getUserPersPlaylistsLib
        .bind(basSource.tidal, id)

      return basSource.tidal.getXElements(
        func,
        BAS_TIDAL.TIDAL_MAX_COLLECTION_RETRIEVE_LENGTH,
        0,
        -1
      )
        .then(processTidalPlaylists)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_TIDAL)

    function processTidalPlaylists (result) {

      var i, length, obj
      var array = []

      if (BasUtil.isObject(result) &&
        Array.isArray(result.items)) {

        length = result.items.length
        for (i = 0; i < length; i++) {
          obj = result.items[i]

          if (BasUtil.isNEString(obj.uuid) &&
            BasUtil.isNEString(obj.title)) {

            array.push({
              id: obj.uuid,
              title: obj.title
            })
          }
        }
      }

      return array
    }
  }

  /**
   * @param {(number|string)} id
   * @param {boolean} [addTrack = false]
   * @returns {string}
   */
  this.getTidalUri = function (id, addTrack) {

    return BAS_SOURCE.URI_PREFIX_TIDAL +
      (
        addTrack === true
          ? 'track:'
          : ''
      ) +
      id
  }
}
