'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceSleep', [
    'BAS_HTML',
    deviceSleep
  ])

function deviceSleep (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.sleepDeviceSettings,
    controller: [
      '$rootScope',
      'BAS_PREFERENCES',
      'BAS_INPUT_MODAL',
      'BasAppDevice',
      'BasPreferences',
      'BasInputModal',
      'BasUtilities',
      controller
    ],
    controllerAs: 'sleep'
  }

  /**
   * @param $rootScope
   * @param BAS_PREFERENCES
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {BasAppDevice} BasAppDevice
   * @param {BasPreferences} BasPreferences
   * @param {BasInputModal} BasInputModal
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    BAS_PREFERENCES,
    BAS_INPUT_MODAL,
    BasAppDevice,
    BasPreferences,
    BasInputModal,
    BasUtilities
  ) {

    var sleep = this

    var _listeners = []

    /**
     * @type {TBasModalInputSelectOption[]}
     */
    sleep.SLEEP_TIMING_OPTIONS = []

    /**
     * @type {number}
     */
    sleep.timing = BasPreferences.getSleepTime()

    sleep.showEditTiming = showEditTiming

    sleep.$onDestroy = _onDestroy

    init()

    function init () {

      _listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        _onLanguageChanged
      ))

      _syncUiTiming()
      _generateTimingOptions()
    }

    function showEditTiming () {

      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'sleep',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            options: sleep.SLEEP_TIMING_OPTIONS,
            initialValue: sleep.timing
          }
        ],
        autoAdvance: true,
        allowDismiss: true,
        autoSave: true,
        validCheck: BasUtil.isNEArray
      }).then(onModal)

      function onModal (modal) {

        modal.close.then(function (result) {

          if (BasUtil.isNEArray(result)) {

            sleep.timing = result[0]
            BasPreferences.setSleepTime(sleep.timing)
            _syncUiTiming()
          }
        })
      }
    }

    function _generateTimingOptions () {
      sleep.SLEEP_TIMING_OPTIONS = BasAppDevice.hasOLEDScreen()
        ? _generateTimingOptionsArray([
          BAS_PREFERENCES.SLEEP_10,
          BAS_PREFERENCES.SLEEP_30,
          BAS_PREFERENCES.SLEEP_60,
          BAS_PREFERENCES.SLEEP_120
          // BAS_PREFERENCES.SLEEP_NEVER
        ])
        : _generateTimingOptionsArray([
          BAS_PREFERENCES.SLEEP_10,
          BAS_PREFERENCES.SLEEP_30,
          BAS_PREFERENCES.SLEEP_60,
          BAS_PREFERENCES.SLEEP_120,
          BAS_PREFERENCES.SLEEP_300,
          BAS_PREFERENCES.SLEEP_600
          // BAS_PREFERENCES.SLEEP_NEVER
        ])
    }

    /**
     * @private
     * @param {number[]} options
     * @returns {Array}
     */
    function _generateTimingOptionsArray (options) {

      var result, length, i, entry

      result = []

      if (Array.isArray(options)) {

        length = options.length
        for (i = 0; i < length; i++) {

          entry = options[i]
          if (BasUtil.isVNumber(entry)) {

            result.push({
              value: entry,
              text: _getUiValue(entry)
            })
          }
        }
      }

      return result
    }

    function _syncUiTiming () {

      sleep.uiTiming = _getUiValue(sleep.timing)
    }

    function _getUiValue (seconds) {
      switch (seconds) {
        case BAS_PREFERENCES.SLEEP_10:
          return BasUtilities.translate(
            'mf_second',
            {
              count: 10
            },
            'messageformat'
          )
        case BAS_PREFERENCES.SLEEP_30:
          return BasUtilities.translate(
            'mf_second',
            {
              count: 30
            },
            'messageformat'
          )
        case BAS_PREFERENCES.SLEEP_60:
          return BasUtilities.translate(
            'mf_minute',
            {
              count: 1
            },
            'messageformat'
          )
        case BAS_PREFERENCES.SLEEP_120:
          return BasUtilities.translate(
            'mf_minute',
            {
              count: 2
            },
            'messageformat'
          )
        case BAS_PREFERENCES.SLEEP_300:
          return BasUtilities.translate(
            'mf_minute',
            {
              count: 5
            },
            'messageformat'
          )
        case BAS_PREFERENCES.SLEEP_600:
          return BasUtilities.translate(
            'mf_minute',
            {
              count: 10
            },
            'messageformat'
          )
        case BAS_PREFERENCES.SLEEP_NEVER:
          return 'Never'
      }
    }

    function _onDestroy () {
      BasUtil.executeArray(_listeners)
      _listeners = []
    }

    function _onLanguageChanged () {

      _syncUiTiming()
      _generateTimingOptions()
    }
  }
}
