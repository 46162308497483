'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomCameras', [
    'CurrentBasCore',
    'BasCamera',
    basRoomCamerasFactory
  ])

/**
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasCamera
 * @returns BasRoomCameras
 */
function basRoomCamerasFactory (
  CurrentBasCore,
  BasCamera
) {
  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomCameras (basRoom) {

    /**
     * @type {BasCamera[]}
     */
    this.cameras = []

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomCameras.hasCameras = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.cameras)
    )
  }

  /**
   * Parse the raw room from the parent BasRoom
   */
  BasRoomCameras.prototype.parseRoom = function parseRoom () {

    if (BasRoomCameras.hasCameras(this._basRoom)) {

      this.syncCameras()
    }
  }

  BasRoomCameras.prototype.syncCameras = function syncCameras () {

    var length, i, uuid, device, camera

    this.resetCameras()

    if (BasRoomCameras.hasCameras(this._basRoom)) {

      length = this._basRoom.room.cameras.length
      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.cameras[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) {

          camera = new BasCamera(device)

          this.cameras.push(camera)
        }
      }
    }
  }

  /**
   * Checks if this BasRoomCameras instance has any cameras
   *
   * @returns {boolean}
   */
  BasRoomCameras.prototype.hasCameras = function hasCameras () {

    return BasUtil.isNEArray(this.cameras)
  }

  /**
   * Gets a BasCamera for the uuid
   *
   * @param {string} uuid
   * @returns {?BasCamera}
   */
  BasRoomCameras.prototype.getCameraById = function (uuid) {

    var length, i

    length = this.cameras.length
    for (i = 0; i < length; i++) {

      if (this.cameras[i].uuid === uuid) return this.cameras[i]
    }

    return null
  }

  /**
   * Clears all cameras
   */
  BasRoomCameras.prototype.clearCameras = function clearCameras () {

    var length, i

    length = this.cameras.length
    for (i = 0; i < length; i++) {

      if (this.cameras[i]) this.cameras[i].clear()
    }
  }

  /**
   * Resets the cameras, removing them
   */
  BasRoomCameras.prototype.resetCameras = function resetCameras () {

    this.clearCameras()

    this.cameras = []

  }

  BasRoomCameras.prototype.destroy = function destroy () {

    this.resetCameras()

    this._basRoom = null
  }

  return BasRoomCameras
}
