'use strict'

angular
  .module('basalteApp')
  .factory('BasLibraryTab', [
    'BasUtilities',
    'Logger',
    basLibraryTabFactory
  ])

/**
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns BasLibraryTab
 */
function basLibraryTabFactory (
  BasUtilities,
  Logger
) {
  var className = 'BasLibraryTab'

  Logger.debug(className)

  /**
   * Object that represents a tab in the music library
   *
   * @constructor
   * @param {BasLibraryCollection} collection
   */
  function BasLibraryTab (collection) {

    if (Array.isArray(collection)) {
      this.name = BasUtilities.translate(collection[0].nameId)
      this.translateName = collection[0].nameId
      this.collection = collection
    } else {
      this.name = BasUtilities.translate(collection.nameId)
      this.translateName = collection.nameId
      this.collection = collection
    }

    this.class = {}
    this.class[BasLibraryTab.CLASS_TAB_ACTIVE] = false
    this.class[BasLibraryTab.CLASS_TAB_SELECTED] = false

  }

  /**
   * @constant {string}
   */
  BasLibraryTab.CLASS_TAB_ACTIVE = 'bas-library-tab-active'

  /**
   * @constant {string}
   */
  BasLibraryTab.CLASS_TAB_SELECTED = 'bas-library-tab-selected'

  BasLibraryTab.prototype.setActiveTab = function (value) {
    this.class[BasLibraryTab.CLASS_TAB_ACTIVE] = value === true
  }

  BasLibraryTab.prototype.setSelectedTab = function (value) {
    this.class[BasLibraryTab.CLASS_TAB_SELECTED] = value === true
  }

  return BasLibraryTab
}
