'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('appCtrl', [
    '$window',
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    '$transitions',
    'STATES',
    'BAS_MODAL',
    'BAS_SPLASH',
    'BAS_CORE_CLIENT',
    'BAS_APP_STORAGE',
    'BAS_LIVE_ACCOUNT',
    'BAS_PREFERENCES',
    'BasState',
    'BasStateHelper',
    'BasStateConditions',
    'UiHelper',
    'BasLiveAccount',
    'BasCoreConnection',
    'CurrentBasCore',
    'MainStartupState',
    'BasSplash',
    'BasSplashScreen',
    'BasAppDevice',
    'BasPreferences',
    'BasModal',
    'BasAppShortcuts',
    'BasWidgets',
    'BasStorage',
    appCtrl
  ])

/**
 * @param $window
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param $transitions
 * @param {STATES} STATES
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BasState} BasState
 * @param {BasStateHelper} BasStateHelper
 * @param {BasStateConditions} BasStateConditions
 * @param {UiHelper} UiHelper
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasCoreConnection} BasCoreConnection
 * @param {CurrentBasCore} CurrentBasCore
 * @param {MainStartupState} MainStartupState
 * @param {BasSplash} BasSplash
 * @param {BasSplashScreen} BasSplashScreen
 * @param {BasAppDevice} BasAppDevice
 * @param {BasPreferences} BasPreferences
 * @param {BasModal} BasModal
 * @param {BasAppShortcuts} BasAppShortcuts
 * @param {BasWidgets} BasWidgets
 * @param {BasStorage} BasStorage
 */
function appCtrl (
  $window,
  $rootScope,
  $scope,
  $uiRouterGlobals,
  $transitions,
  STATES,
  BAS_MODAL,
  BAS_SPLASH,
  BAS_CORE_CLIENT,
  BAS_APP_STORAGE,
  BAS_LIVE_ACCOUNT,
  BAS_PREFERENCES,
  BasState,
  BasStateHelper,
  BasStateConditions,
  UiHelper,
  BasLiveAccount,
  BasCoreConnection,
  CurrentBasCore,
  MainStartupState,
  BasSplash,
  BasSplashScreen,
  BasAppDevice,
  BasPreferences,
  BasModal,
  BasAppShortcuts,
  BasWidgets,
  BasStorage
) {
  var root = this

  var _BASALTE_DEVICE_MAC_ADDRESS_TIMEOUT_MS = 750

  var _listeners = []

  var _stateTransitionListener = null
  var _stateTransitionTimeoutId = 0

  var _stateTransitionTimeoutReached = false

  var _basalteDeviceMacAddressListener = null
  var _basalteDeviceMacAddressTimeoutId = 0

  var _isStateTransitionInProgress = false

  var _basalteDeviceMacAddressResolve = null
  var _basalteDeviceMacAddressReject = null

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasLiveAccountState}
   */
  var basLiveAccountState = BasLiveAccount.get()

  /**
   * @type {TBasState}
   */
  root.state = BasStateConditions.get()

  /**
   * @type {BasAppDeviceState}
   */
  root.device = BasAppDevice.get()

  /**
   * @type {TPreferenceState}
   */
  root.preferences = BasPreferences.get()

  /**
   * @type {TBasSplashState}
   */
  root.basSplashState = BasSplash.get()

  init()

  function init () {

    $window.basTModule.tAppCtrl = Date.now()

    _logStart()

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))
    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_STATE_CHANGED,
      _onSplashState
    ))

    // Start the UiHelper
    UiHelper.resume()

    // Don't show this modal if zero functionality will work
    // (isLiveOnly && !supportsBasalteLive)
    // Other warnings/info will be given to the user
    if (
      !root.device.supportedBrowser &&
      !(
        !root.device.supportsBasalteLive &&
        BasAppDevice.isLiveOnly()
      )
    ) {
      BasModal.show(
        BAS_MODAL.T_UNSUPPORTED_BROWSER,
        {
          modalOptions: {
            locationChangeSuccess: false
          }
        }
      )
    }

    if (BasAppDevice.isCoreClient()) {

      if (BAS_CORE_CLIENT.STATE.macNum) {

        // MAC address is known already

      } else {

        _basalteDeviceMacAddressListener = $rootScope.$on(
          BAS_CORE_CLIENT.EVT_CORE_CLIENT_MAC_ADDRESS,
          _onBasalteDeviceMacAddress
        )

        _basalteDeviceMacAddressTimeoutId = setTimeout(
          _onBasalteDeviceMacAddressTimeout,
          _BASALTE_DEVICE_MAC_ADDRESS_TIMEOUT_MS
        )
      }
    }

    $transitions.onSuccess(
      {},
      _onFirstTransition,
      {
        invokeLimit: 1
      }
    )

    _stateTransitionListener = $transitions.onStart(
      {},
      _onStart,
      {
        priority: 100000
      }
    )

    // Wait for next function execution time frame
    // If there was a state transition,
    // the "onStart" handler should be executed
    // right after this controller in the same execution context.
    _stateTransitionTimeoutId = setTimeout(
      _onStateTransitionTimeout
    )
  }

  function _ignore () {
    // Empty
  }

  function _onStateTransitionTimeout () {

    _stateTransitionTimeoutReached = true

    _clearStateTransitionTimeout()
    _clearStateTransitionListener()

    _isStateTransitionInProgress = !!$uiRouterGlobals.transition

    if (!_isStateTransitionInProgress) {

      $window.basTModule.startType = 'manual'
      $window.basTModule.tBeforeStart = Date.now()

      if (BasAppDevice.getPreferDemo()) {

        BasCoreConnection.start()

      } else if (BasAppDevice.isCoreClient()) {

        if (BAS_CORE_CLIENT.STATE.macNum) {

          BasCoreConnection.start()

        } else {

          // Do nothing, wait fot other events
          // See _onBasalteDeviceMacAddress or
          // _onBasalteDeviceMacAddressTimeout
          // For further handling
        }

      } else if (BasAppDevice.isLiveOnly()) {

        if (root.device.supportsBasalteLive) {

          BasLiveAccount.check().then(_onBasLiveCheck)

        } else {

          BasState.go(STATES.CONNECT_UNSUPPORTED)

          BasSplashScreen.hide(5)
        }

      } else if (root.device.supportsBasalteLive) {

        BasLiveAccount.check().then(_onBasLiveCheck)

      } else {

        BasCoreConnection.start()
      }
    }

    function _onBasLiveCheck () {

      const _basAppShortcutsTarget = BasAppShortcuts.getTarget()
      const _basWidgetsTarget = BasWidgets.getTarget()

      if (!basLiveAccountState.isLoggedIn &&
        (
          !basLiveAccountState.continueWithoutLive ||
          BasAppDevice.isLiveOnly()
        )
      ) {

        if (root.device.isProLiveHosted) {

          // Redirect to Pro Live login

          if ($window.location) {

            $window.location.href = BasAppDevice.getProLiveLoginUrl()
          }

        } else {

          BasState.go(STATES.CONNECT_LIVE)

          BasSplashScreen.hide(5)
        }

      } else if (_basAppShortcutsTarget) {
        BasAppShortcuts.clearState()

        BasCoreConnection.start(
          _basAppShortcutsTarget,
          null,
          null,
          false
        )

      } else if (_basWidgetsTarget) {

        BasCoreConnection.start(
          _basWidgetsTarget,
          null,
          null,
          false
        )

      } else {

        BasCoreConnection.start()
      }
    }
  }

  function _onStart (transition) {

    var target, targetStateName

    _clearStateTransitionTimeout()
    _clearStateTransitionListener()

    _isStateTransitionInProgress = true

    $window.basTModule.startType = 'state'
    $window.basTModule.tBeforeStart = Date.now()

    target = transition.targetState()
    targetStateName = target.name()

    // TODO Resolve connection before proceeding to state

    if (BasAppDevice.getPreferDemo()) {

      return BasCoreConnection.start(
        null,
        null,
        null,
        true
      ).catch(_onBasCoreStartError)

    } else if (BasAppDevice.isCoreClient()) {

      if (BAS_CORE_CLIENT.STATE.macNum) {

        return BasCoreConnection.start(
          null,
          null,
          null,
          true
        ).catch(_onBasCoreStartError)

      } else {

        return new Promise(_basalteDeviceMacAddressPromiseConstructor)
          .then(
            _onBasalteDeviceMacAddressPromiseResult,
            _onBasalteDeviceMacAddressPromiseError
          )
      }

    } else if (root.device.supportsBasalteLive) {

      return BasLiveAccount.check().then(_onBasLiveCheck)

    } else if (targetStateName) {

      if (
        BasStateHelper.hasBaseState(
          targetStateName,
          STATES.CONNECT_LIVE
        )
      ) {

        BasSplashScreen.hide(5)

        return BasState.target(STATES.CONNECT_DISCOVERY)

      } else if (
        BasStateHelper.hasBaseState(
          targetStateName,
          STATES.CONNECT_UNSUPPORTED
        )
      ) {

        BasSplashScreen.hide(5)

      } else if (targetStateName === STATES.CONNECT_DISCOVERY) {

        BasSplashScreen.hide(5)

      } else if (
        BasStateHelper.hasBaseState(
          targetStateName,
          STATES.DEVICE_SETTINGS
        )
      ) {

        BasSplashScreen.hide(5)

      } else if (
        BasStateHelper.hasBaseState(
          targetStateName,
          STATES.CONNECT_PROFILES
        )
      ) {

        return BasCoreConnection.start(
          null,
          {
            noLogin: true
          },
          null,
          true
        )
          .then(_onBasCoreStartState, _onBasCoreStartError)

      } else {

        return BasCoreConnection.start(
          null,
          null,
          null,
          true
        )
          .then(_onBasCoreStartState, _onBasCoreStartError)
      }

    } else {

      return BasCoreConnection.start(
        null,
        null,
        null,
        true
      )
        .then(_onBasCoreStartState, _onBasCoreStartError)
    }

    function _onBasLiveCheck () {

      if (
        targetStateName &&
        BasStateHelper.hasBaseState(
          targetStateName,
          STATES.CONNECT_LIVE
        )
      ) {
        BasSplashScreen.hide(5)

        if (targetStateName === STATES.CONNECT_LIVE) {

          // Empty

        } else if (targetStateName === STATES.CONNECT_LIVE_LOGIN) {

          if (basLiveAccountState.isLoggedIn) {

            return BasState.target(STATES.CONNECT_LIVE)

          } else {

            // Empty
          }

        } else if (targetStateName === STATES.CONNECT_LIVE_REGISTER) {

          if (basLiveAccountState.isLoggedIn) {

            return BasState.target(STATES.CONNECT_LIVE)

          } else {

            // Empty
          }

        } else {

          // Empty
        }

      } else if (
        targetStateName &&
        BasStateHelper.hasBaseState(
          targetStateName,
          STATES.CONNECT_UNSUPPORTED
        )
      ) {

        BasSplashScreen.hide(5)

      } else if (targetStateName === STATES.CONNECT_DISCOVERY) {

        BasSplashScreen.hide(5)

      } else if (
        targetStateName &&
        BasStateHelper.hasBaseState(
          targetStateName,
          STATES.DEVICE_SETTINGS
        )
      ) {

        BasSplashScreen.hide(5)

      } else if (targetStateName === STATES.CONNECT_PROFILES) {

        if (basLiveAccountState.isLoggedIn) {

          return BasLiveAccount.listProjects()
            .then(_onBasLiveProjects, _onBasLiveProjectsError)

        } else {

          // TODO Light version of regular check

          if (
            BasStateHelper.hasBaseState(
              targetStateName,
              STATES.CONNECT_PROFILES
            )
          ) {

            return BasCoreConnection.start(
              null,
              {
                noLogin: true
              },
              null,
              true
            )
              .then(_onBasCoreStartState, _onBasCoreStartError)

          } else {

            return BasCoreConnection.start(
              null,
              null,
              null,
              true
            )
              .then(_onBasCoreStartState, _onBasCoreStartError)
          }
        }

      } else {

        if (basLiveAccountState.isLoggedIn) {

          return BasLiveAccount.listProjects()
            .then(_onBasLiveProjects, _onBasLiveProjectsError)

        } else {

          if (root.device.isProLiveHosted) {

            // Redirect to Pro Live login

            if ($window.location) {

              $window.location.href = BasAppDevice.getProLiveLoginUrl()
            }

            return Promise.reject(new Error('Could not redirect to login'))

          } else {

            return BasCoreConnection.start(
              null,
              null,
              null,
              true
            )
              .then(_onBasCoreStartState, _onBasCoreStartError)
          }
        }
      }
    }

    function _onBasLiveProjects () {

      // TODO Light version of regular check for STATES.CONNECT_PROFILES

      if (
        BasStateHelper.hasBaseState(
          targetStateName,
          STATES.CONNECT_PROFILES
        )
      ) {

        return BasCoreConnection.start(
          null,
          {
            noLogin: true
          },
          null,
          true
        )
          .then(_onBasCoreStartState, _onBasCoreStartError)

      } else {

        return BasCoreConnection.start(
          null,
          null,
          null,
          true
        )
          .then(_onBasCoreStartState, _onBasCoreStartError)
      }
    }

    function _onBasLiveProjectsError () {

      return BasCoreConnection.start(
        null,
        null,
        null,
        true
      )
        .then(_onBasCoreStartState, _onBasCoreStartError)
    }

    /**
     * @param {Object} state
     * @returns {(Object|Promise)}
     */
    function _onBasCoreStartState (state) {

      var _state

      _state = state.$state()

      if (_state.is(STATES.MAIN)) {

        return MainStartupState.determine(currentBasCoreState.core)
          .then(_onStartupStateDetermined)

      } else {

        return state
      }
    }

    function _onStartupStateDetermined (result) {

      if (BasStateHelper.hasBaseState(targetStateName, STATES.MAIN)) {

        // Allow transition to continue

      } else {

        return result
      }
    }

    function _onBasalteDeviceMacAddressPromiseResult () {

      if (BAS_CORE_CLIENT.STATE.macNum) {

        return BasCoreConnection.start(
          null,
          null,
          null,
          true
        ).catch(_onBasCoreStartError)

      } else {

        // eslint-disable-next-line no-console
        console.warn(
          'APP CTRL - INVALID ELLIE/LISA MAC DURING STATE TRANSITION'
        )

        BasCoreConnection.prepareChangeBasCore()

        BasSplashScreen.hide(5)

        return BasState.target(STATES.CONNECT_DISCOVERY)
      }
    }

    function _onBasalteDeviceMacAddressPromiseError () {

      // eslint-disable-next-line no-console
      console.warn(
        'APP CTRL - NO ELLIE/LISA MAC DURING STATE TRANSITION'
      )

      BasCoreConnection.prepareChangeBasCore()

      BasSplashScreen.hide(5)

      return BasState.target(STATES.CONNECT_DISCOVERY)
    }

    function _onBasCoreStartError () {

      // Connection that was started on app start failed, we need to
      //  return a state promise here, so we don't land on a black screen
      return BasState.target(STATES.CONNECT_DISCOVERY)
    }
  }

  function _basalteDeviceMacAddressPromiseConstructor (resolve, reject) {

    _basalteDeviceMacAddressResolve = resolve
    _basalteDeviceMacAddressReject = reject
  }

  function _onBasalteDeviceMacAddress () {

    var _resolve

    _clearBasalteDeviceMacAddressListener()
    _clearbasalteDeviceMacAddressTimeout()

    if (_stateTransitionTimeoutReached) {

      _onStateTransitionTimeout()

    } else if (_isStateTransitionInProgress) {

      if (BasUtil.isFunction(_basalteDeviceMacAddressResolve)) {

        _resolve = _basalteDeviceMacAddressResolve

        _basalteDeviceMacAddressResolve = null
        _basalteDeviceMacAddressReject = null

        _resolve()
      }

    } else {

      // Neither flow is started yet
    }
  }

  function _onBasalteDeviceMacAddressTimeout () {

    var _reject

    if (_isStateTransitionInProgress) {

      if (BasUtil.isFunction(_basalteDeviceMacAddressReject)) {

        _reject = _basalteDeviceMacAddressReject

        _basalteDeviceMacAddressResolve = null
        _basalteDeviceMacAddressReject = null

        _reject(new Error('get MAC timeout'))

      } else {

        // eslint-disable-next-line no-console
        console.warn(
          'APP CTRL - ELLIE/LISA MAC TIMEOUT DURING STATE TRANSITION'
        )
      }

    } else {

      BasCoreConnection.start()
    }
  }

  function _onSplashVisibility () {

    $scope.$applyAsync()
  }

  function _onSplashState () {

    $scope.$applyAsync()
  }

  function _onFirstTransition (transition) {

    var target, lastServer, knownServers, settings, ellieSettings, liveState

    $window.basTModule.tAppStarted = Date.now()

    target = transition.targetState()

    settings = BasStorage.get(BAS_PREFERENCES.K_DEVICE)
    ellieSettings = BasStorage.get(BAS_PREFERENCES.K_ELLIE)
    liveState = BasStorage.get(BAS_LIVE_ACCOUNT.STORAGE_KEY_BAS_LIVE_STATE)
    lastServer = BasStorage.get(BAS_APP_STORAGE.K_LAST_KNOWN_SERVER_V3)
    knownServers = BasStorage.get(BAS_APP_STORAGE.K_KNOWN_SERVERS_V3)

    $window.basTModule.logEvt({
      evtType: $window.basTModule.T_APP_STARTED,
      startState: target ? target.name() : '',
      appSettings: settings,
      ellieSettings: ellieSettings,
      liveState: liveState,
      lastKnownServer: lastServer,
      knownServers: knownServers
    })
  }

  function _logStart () {

    $window.basTModule.logEvt({
      evtType: $window.basTModule.T_APP_START
    })
  }

  function _clearStateTransitionListener () {

    BasUtil.execute(_stateTransitionListener)
    _stateTransitionListener = null
  }

  function _clearStateTransitionTimeout () {

    clearTimeout(_stateTransitionTimeoutId)
    _stateTransitionTimeoutId = 0
  }

  function _clearBasalteDeviceMacAddressListener () {

    BasUtil.execute(_basalteDeviceMacAddressListener)
    _basalteDeviceMacAddressListener = null
  }

  function _clearbasalteDeviceMacAddressTimeout () {

    clearTimeout(_basalteDeviceMacAddressTimeoutId)
    _basalteDeviceMacAddressTimeoutId = 0
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []

    _clearStateTransitionListener()
    _clearStateTransitionTimeout()
    _clearBasalteDeviceMacAddressListener()
    _clearbasalteDeviceMacAddressTimeout()
  }
}
