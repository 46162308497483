'use strict'

angular
  .module('basalteApp')
  .constant('BAS_FAVOURITES', {
    ID_T_FAVOURITES: 'favourites',
    ID_T_LOCAL: 'playlists',
    ID_T_RADIO: 'radio_stations',
    ID_T_DEEZER: 'deezer',
    ID_T_TIDAL: 'tidal',
    ID_T_SPOTIFY: 'spotify',
    ID_T_SONOS: 'sonos',
    ID_T_VIDEO: 'favourites',
    S_LOCAL: 'local',
    S_SONOS: 'sonos',
    S_TUNEIN: 'tunein',
    S_SPOTIFY: 'spotify',
    S_DEEZER: 'deezer',
    S_TIDAL: 'tidal',
    FILTER_ALL: null,
    FILTER_LOCAL: null,
    FILTER_RADIO: null,
    FILTER_DEEZER: null,
    FILTER_TIDAL: null,
    FILTER_SPOTIFY_CONNECT: null,
    FILTER_SONOS: null,
    FILTER_VIDEO: null,
    COL_ID_LOCAL: 'colIdFavouritesLocal',
    COL_ID_RADIO: 'colIdFavouritesRadio',
    COL_ID_DEEZER: 'colIdFavouritesDeezer',
    COL_ID_TIDAL: 'colIdFavouritesTidal',
    COL_ID_SPOTIFY: 'colIdFavouritesSpotify',
    COL_ID_SPOTIFY_CURRENT: 'colIdFavouritesSpotifyCurrent',
    COL_ID_SONOS: 'colIdFavouritesSonos',
    COL_ID_QUAD: 'colIdFavouritesQuad',
    ERR_NO_SOURCE: 'errNoSource',
    ERR_INVALID_TYPE: 'errInvalidType',
    ERR_NOT_SUPPORTED: 'errNotSupported',
    ERR_NO_CORE: 'errNoCore'
  })
