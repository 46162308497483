'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasProfilePreferences', BasProfilePreferences)

/**
 * @typedef {Object} TProfileSetting
 * @property {boolean} [showBackground]
 * @property {boolean} [skipLinkLiveModal]
 * @property {string} [defaultWeather]
 * @property {TStartupPreference} [startupView]
 */

/**
 * @typedef {Object} TStartupPreference
 * @property {number} view
 * @property {string} room
 */

/**
 * @constructor
 */
function BasProfilePreferences () {

  /**
   * @type {TProfileSetting}
   */
  var settings = {}

  this.getShowBackground = getShowBackground
  this.getStartupView = getStartupView
  this.setShowBackground = setShowBackground
  this.setStartupView = setStartupView

  this.getSkipLinkLiveModal = getSkipLinkLiveModal
  this.setSkipLinkLiveModal = setSkipLinkLiveModal

  this.getDefaultWeather = getDefaultWeather
  this.setDefaultWeather = setDefaultWeather

  this.syncSettings = syncSettings
  this.clearSettings = clearSettings

  function getShowBackground () {

    return settings.showBackground
  }

  /**
   * @param {boolean} value
   * @param {BasCoreContainer} [core]
   */
  function setShowBackground (value, core) {

    settings.showBackground = value
    _setSettings(core)
  }

  function getStartupView () {

    return settings.startupView
  }

  /**
   * @param {TStartupPreference} value
   * @param {BasCoreContainer} [core]
   */
  function setStartupView (value, core) {

    settings.startupView = value
    _setSettings(core)
  }

  function getSkipLinkLiveModal () {

    return settings.skipLinkLiveModal
  }

  /**
   * @param {boolean} value
   * @param {BasCoreContainer} [core]
   */
  function setSkipLinkLiveModal (value, core) {

    settings.skipLinkLiveModal = value
    _setSettings(core)
  }

  function getDefaultWeather () {

    return settings.defaultWeather
  }

  /**
   * @param {string} value
   * @param {BasCoreContainer} [core]
   */
  function setDefaultWeather (value, core) {

    settings.defaultWeather = value
    _setSettings(core)
  }

  /**
   * @param {BasCoreContainer} [core]
   */
  function syncSettings (core) {

    var _settings

    if (core && core.getStoredSettingsForLastUser) {

      _settings = core.getStoredSettingsForLastUser()

      settings = _settings
        ? BasUtil.copyObject(_settings)
        : {}
    }
  }

  function clearSettings () {

    settings = {}
  }

  /**
   * @private
   * @param {BasCoreContainer} [core]
   */
  function _setSettings (core) {

    if (core && core.saveSettingsForLastUser) {

      core.saveSettingsForLastUser(settings)
    }
  }
}
