'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourceQueue', [
    '$rootScope',
    'ModalService',
    'BAS_API',
    'BAS_SOURCE',
    'BAS_HTML',
    'BAS_MODAL',
    'BasModal',
    'modalHelperService',
    'BasString',
    'BasUtilities',
    basSourceQueue
  ])

/**
 * @param $rootScope
 * @param ModalService
 * @param BAS_API
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasModal} BasModal
 * @param modalHelperService
 * @param BasString
 * @param {BasUtilities} BasUtilities
 * @returns BasSourceQueue
 */
function basSourceQueue (
  $rootScope,
  ModalService,
  BAS_API,
  BAS_SOURCE,
  BAS_HTML,
  BAS_MODAL,
  BasModal,
  modalHelperService,
  BasString,
  BasUtilities
) {
  var CSS_NE_QUEUE = 'bpy-not-empty-queue'
  var CSS_QUEUE_MENU_OPEN = 'bpy-queue-menu-open'
  var QUEUE_PAGE_SIZE = 20

  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourceQueue (basSource) {

    /**
     * @type {Array}
     */
    this.queue = []

    /**
     * Total length of the queue, including items that are not loaded yet due
     * to pagination
     *
     * @type {number}
     */
    this.length = 0

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * @type {string}
     */
    this.contentType = ''

    /**
     * @type {boolean}
     */
    this.canTap = true

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this._resetCss()

    /**
     * @private
     * @type {BasSource}
     */
    this._basSource = basSource

    /**
     * @private
     * @type {?AudioSource}
     */
    this._audioSource = (
      basSource &&
      basSource.source &&
      basSource.source.queueList
    )
      ? basSource.source
      : null

    /**
     * @private
     * @type {number}
     */
    this._queuePaginationOffset = 0

    /**
     * @type {boolean}
     */
    this.dirty = true

    this._handleResetTap = this._resetTap.bind(this)
    this._handleUpdateQueue = this._onUpdateQueue.bind(this)

    this.handleQueueChanged = this._onQueueChanged.bind(this)
    this.handleQueueAdded = this._onQueueAdded.bind(this)
    this.handleQueueMoved = this._onQueueMoved.bind(this)
    this.handleQueueRemoved = this._onQueueRemoved.bind(this)
  }

  BasSourceQueue.prototype.syncQueue = function () {

    var _this = this

    if (this._audioSource) {

      if (this.dirty) {

        this.retrievePaginated().then(onRetrievePaginated, _ignore)
      }

    } else if (
      this._basSource &&
      this._basSource.source &&
      this._basSource.source.queue
    ) {

      this.clearQueue()

      if (this._basSource.source.queue.dirty) {

        this._basSource.source.queue.update()
          .then(this._handleUpdateQueue)

      } else {

        this.updateQueue()
      }
    }

    function onRetrievePaginated () {

      _this.dirty = false
    }
  }

  BasSourceQueue.prototype._onUpdateQueue = function (songs) {

    return this.updateQueue(songs)
  }

  BasSourceQueue.prototype._onQueueAdded = function (data) {

    var i, length, sorted, insertedCount, track, tracks

    if (BasUtil.isObject(data)) {

      tracks = data.tracks

      if (BasUtil.isNEString(data.contentType)) {

        this.contentType = data.contentType
      }

    } else if (Array.isArray(data)) {

      tracks = data
    }

    if (Array.isArray(tracks)) {

      // Add tracks at correct position, if the track can be added to the end
      //  of the current loaded queue

      insertedCount = 0
      sorted = tracks.sort(compareTrackPos)

      length = sorted.length
      for (i = 0; i < length; i++) {

        track = sorted[i]

        if (track.pos <= this.queue.length) {

          // Insert track at pos
          insertedCount += 1
          this.queue.splice(track.pos, 0, track)
        }
      }

      this._queuePaginationOffset += insertedCount
      this.length += tracks.length

      if (insertedCount > 0) {

        this._checkQueue()
      }
    }

    function compareTrackPos (track1, track2) {

      if (
        track1 &&
        track2 &&
        BasUtil.isNumber(track1.pos) &&
        BasUtil.isNumber(track2.pos)
      ) {
        return track1.pos - track2.pos
      }

      return 0
    }
  }

  BasSourceQueue.prototype._onQueueMoved = function (data) {

    var from, to, track

    if (
      data &&
      BasUtil.isPNumber(data.from, true) &&
      data.track &&
      BasUtil.isPNumber(data.track.pos, true)
    ) {

      from = data.from
      to = data.track.pos
      track = data.track

      // Only continue if the 'from' position is outside of our loaded range,
      //  or if it is inside our loaded range and the track matches the position
      //  of the element at that position in our loaded range.
      if (
        from >= this.queue.length ||
        (
          this.queue[from] &&
          this.queue[from].id === track.id
        )
      ) {

        // If 'from' position is in range, remove
        if (from < this.queue.length) {

          this.queue.splice(from, 1)
          this._queuePaginationOffset -= 1
        }

        // If 'to' position is in range, insert again
        if (to <= this.queue.length) {

          this.queue.splice(to, 0, track)
          this._queuePaginationOffset += 1
        }

        this._checkQueue()
      }
    }
  }

  BasSourceQueue.prototype._onQueueRemoved = function (ids) {

    var i, length, j, length2, removedCount, id, idIndex

    if (Array.isArray(ids)) {

      removedCount = 0

      // Remove each track with matching id from ids array

      length = ids.length
      for (i = 0; i < length; i++) {

        if (BasUtil.isPNumber(ids[i], true)) {

          id = ids[i]

          idIndex = -1

          // Search trough track list for track with matching id, save index

          length2 = this.queue.length
          for (j = 0; j < length2; j++) {

            if (this.queue[j].id === id) {

              idIndex = j
              break
            }
          }

          // If matching track was found, remove it

          if (idIndex !== -1) {

            this.queue.splice(idIndex, 1)
            removedCount += 1
          }
        }
      }

      this.length -= removedCount
      this._queuePaginationOffset -= removedCount
    }
  }

  BasSourceQueue.prototype._onQueueChanged = function () {

    this.clearQueue()
    this.syncQueue()
  }

  BasSourceQueue.prototype.updateQueue = function (songs) {

    var uuid, id, songsPresent

    songsPresent = false

    if (this._audioSource) {

      uuid = this._audioSource.uuid
      this.type = BAS_API.Queue.TYPE_QUEUE

      if (songs && Array.isArray(songs.list)) {

        Array.prototype.splice.apply(
          this.queue,
          [songs.offset, songs.list.length].concat(songs.list)
        )
        this._queuePaginationOffset = this.queue.length
        this.length = songs.total
        this.contentType = songs.contentType
        this._basSource.state.syncAudioQueueType()
        this._checkQueue()

        songsPresent = songs.list.length > 0
      }

    } else if (this._basSource) {

      this.clearQueue()

      uuid = this._basSource.uuid
      id = this._basSource.id

      if (
        this._basSource.source &&
        this._basSource.source.queue
      ) {

        this.type = this._basSource.source.queue.type

        if (Array.isArray(this._basSource.source.queue.songs)) {

          this.queue = this._basSource.source.queue.songs
          this.length = this.queue.length
          this._checkQueue()
          songsPresent = this._basSource.source.queue.songs > 0
        }
      }

      if (
        this._basSource.state &&
        this._basSource.state.handleQueueChanged
      ) {

        this._basSource.state.handleQueueChanged()
      }
    }

    $rootScope.$emit(
      BAS_SOURCE.EVT_QUEUE_CHANGE,
      uuid || undefined,
      BasUtil.isVNumber(id) ? id : undefined
    )

    if (this._basSource) this._basSource._syncSourceInfo()

    $rootScope.$applyAsync()

    return songsPresent
  }

  BasSourceQueue.prototype.clearQueue = function () {

    this.queue = []
    this.type = BAS_API.Queue.TYPE_QUEUE
    this._queuePaginationOffset = 0
    this.length = 0
    this.dirty = true

    this._checkQueue()
  }

  BasSourceQueue.prototype.queueTap = function (index) {

    if (
      this.canTap &&
      this.queue[index] &&
      this._basSource &&
      this._basSource.source
    ) {

      if (this._audioSource) {

        this._audioSource.queuePlayItem(index)

      } else if (this._basSource.type === BAS_SOURCE.T_PLAYER) {

        this._basSource.source.playId(this.queue[index].id)
      }
    }
  }

  BasSourceQueue.prototype.queueSwipe = function (index) {

    var _this, removedElement

    _this = this

    BasUtilities.waitFrames(2).then(this._handleResetTap)

    if (
      this.queue[index] &&
      this._basSource &&
      this._basSource.source
    ) {

      if (this._audioSource) {

        removedElement = this.queue.splice(index, 1)[0]

        this.length--
        this._queuePaginationOffset--

        this._audioSource.queueRemoveItems([removedElement.id])
          .catch(_onQueueItemRemovedError)

      } else if (
        this._basSource.source.queue &&
        this._basSource.type === BAS_SOURCE.T_PLAYER &&
        this.type !== BAS_API.Queue.TYPE_DEEZER_FLOW &&
        this.type !== BAS_API.Queue.TYPE_DEEZER_RADIO
      ) {

        this._basSource.source.queue.removeSongId(
          this.queue[index].id
        )
        this.queue.splice(index, 1)
        this.length = this.queue.length
        this._checkQueue()
      }
    }

    function _onQueueItemRemovedError (_error) {

      // Queue item removal failed, add back to local queue
      _this.queue.splice(index, 0, removedElement)
    }
  }

  BasSourceQueue.prototype.queueBefore = function (event) {

    if (
      this._basSource && (
        this._audioSource ||
        (
          this._basSource.type === BAS_SOURCE.T_PLAYER ||
          this.type !== BAS_API.Queue.TYPE_DEEZER_FLOW ||
          this.type !== BAS_API.Queue.TYPE_DEEZER_RADIO
        )
      )
    ) {

      this.canTap = false

    } else {

      event.preventDefault()
    }
  }

  BasSourceQueue.prototype.queueCancel = function () {

    this._resetTap()
  }

  BasSourceQueue.prototype.queueReorder = function (
    spliceIndex,
    originalIndex
  ) {
    var listItem

    BasUtilities.waitFrames(2).then(this._handleResetTap)

    if (
      this._basSource &&
      this._basSource.source &&
      this._basSource.queue && (
        (
          this._basSource.type === BAS_SOURCE.T_PLAYER &&
          this._basSource.source.queue &&
          this.type !== BAS_API.Queue.TYPE_DEEZER_FLOW &&
          this.type !== BAS_API.Queue.TYPE_DEEZER_RADIO
        ) ||
        this._audioSource
      )
    ) {

      // Update local
      listItem = this.queue[originalIndex]
      this.queue.splice(originalIndex, 1)
      this.queue.splice(spliceIndex, 0, listItem)

      // Update API
      if (this._audioSource) {

        this._audioSource.queueMoveItem(originalIndex, spliceIndex)

      } else {

        this._basSource.source.queue.moveSongId(listItem.id, spliceIndex)
      }

    }
  }

  /**
   * @returns {Promise}
   */
  BasSourceQueue.prototype.retrievePaginated = function () {

    if (this._audioSource) {

      if (
        this._audioSource.allowsExecute(
          BAS_API.AudioSource.C_LIST,
          BAS_API.AudioSource.C_QUEUE
        )
      ) {

        return this._audioSource.queueList(
          this._queuePaginationOffset,
          QUEUE_PAGE_SIZE
        )
          .then(this._handleUpdateQueue)

      } else {

        // No capabilities to query queue
        return Promise.reject(new Error('no capabilities to query queue'))
      }
    }

    // No AudioSource
    return Promise.reject(new Error('no AudioSource'))
  }

  BasSourceQueue.prototype.queueClear = function () {

    var _this = this

    // Show confirmation modal
    BasModal.show(BAS_MODAL.T_QUEUE_CLEAR).then(onQueueClearModal)

    function onQueueClearModal (modal) {

      // Set close Promise
      modal.close.then(onQueueClearModalClose)
    }

    function onQueueClearModalClose (result) {

      switch (result) {
        case BAS_MODAL.C_YES:

          if (
            (
              _this._basSource &&
              _this._basSource.source &&
              (
                _this._basSource.source.queue &&
                _this.type === BAS_API.Queue.TYPE_QUEUE
              )
            ) ||
            _this._audioSource
          ) {

            if (_this._audioSource) {

              _this._audioSource.queueClear()

            } else {

              _this._basSource.source.queue.clear()
            }

            _this.queue = []
            _this._checkQueue()
          }

          break
      }
    }
  }

  BasSourceQueue.prototype.queueSaveToPlaylist = function (event) {

    var _this = this

    // Show "Add to playlist" modal
    ModalService.showModal({
      template: BAS_HTML.addToPlaylistModal,
      controller: 'addToPlaylistModalCtrl',
      controllerAs: 'modal',
      inputs: {
        event: event
      }
    }).then(onAddToPlaylistModal)

    function onAddToPlaylistModal (modal) {

      _this.css[CSS_QUEUE_MENU_OPEN] = true

      // Set close Promise
      modal.close.then(onAddToPlaylistModalClose)

      // Set closed Promise
      modal.closed.then(onAddToPlaylistModalClosed)
    }

    function onAddToPlaylistModalClose () {

      _this.css[CSS_QUEUE_MENU_OPEN] = false
    }

    function onAddToPlaylistModalClosed () {

      _this.css[CSS_QUEUE_MENU_OPEN] = false

      // Reset modal style
      modalHelperService.resetModalStyle()
    }
  }

  BasSourceQueue.prototype._resetTap = function () {
    this.canTap = true
  }

  BasSourceQueue.prototype._checkQueue = function () {

    var i, length, song

    this.css[CSS_NE_QUEUE] = (
      this.type === BAS_API.Queue.TYPE_QUEUE &&
      this.queue.length > 0
    )

    length = this.queue.length

    for (i = 0; i < length; i++) {

      song = this.queue[i]

      if (song) {

        song.uiTitle = new BasString()

        if (BasUtil.isNEString(song.title)) {

          song.uiTitle.setLiteral(song.title)

        } else {

          song.uiTitle.setKey('unknown')
        }
      }
    }
  }

  BasSourceQueue.prototype.updateTranslation = function () {

    var i, length, song

    length = this.queue.length

    for (i = 0; i < length; i++) {

      song = this.queue[i]

      if (song && song.uiTitle instanceof BasString) {

        song.uiTitle.updateTranslation()
      }
    }
  }

  BasSourceQueue.prototype._resetCss = function () {

    this.css[CSS_NE_QUEUE] = false
    this.css[CSS_QUEUE_MENU_OPEN] = false
  }

  BasSourceQueue.prototype.destroy = function () {

    this.queue = []
    this.length = 0

    this._basSource = null
    this._audioSource = null
  }

  return BasSourceQueue
}

function _ignore () {
  // Empty
}
