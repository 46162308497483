'use strict'

angular
  .module('basalteApp')
  .controller('lisaShadesCtrl', [
    'STATES',
    'BAS_ROOMS',
    'CurrentRoom',
    'BasState',
    lisaShadesCtrl
  ])

/**
 * @param {STATES} STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 * @param {BasState} BasState
 */
function lisaShadesCtrl (
  STATES,
  BAS_ROOMS,
  CurrentRoom,
  BasState
) {
  var lisa = this

  /**
   * @type {BasRooms}
   */
  lisa.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  lisa.currentRoom = CurrentRoom.get()

  lisa.handleSelect = handleSelect

  function handleSelect (shade) {

    BasState.go(
      STATES.LISA_SHADE_DETAIL,
      {
        shade: shade.uuid
      }
    )
  }
}
