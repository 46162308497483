'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var Device = require('./device')

/**
 * @typedef {Object} TButton
 * @property {string} uuid
 * @property {string} name
 * @property {string} username
 * @property {string} password
 * @property {number} relay
 */

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function DoorPhoneDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._directDial = ''

  this._doorPhoneGateway = ''

  this._doorPhoneIdentifier = ''

  this._buttons = []

  this._cameras = []

  this._dtmfDoor = ''

  this._openClose = ''

  this.parse(device)
}

DoorPhoneDevice.prototype = Object.create(Device.prototype)
DoorPhoneDevice.prototype.constructor = DoorPhoneDevice

/**
 * @constant {string}
 */
DoorPhoneDevice.ST_SIEDLE = P.SIEDLE

/**
 * @constant {string}
 */
DoorPhoneDevice.ST_GIRA = P.GIRA

/**
 * @constant {string}
 */
DoorPhoneDevice.ST_RESIDIUM = P.RESIDIUM

/**
 * @param {Object} msg
 * @param {TDeviceParseOptions} [options]
 * @returns {boolean}
 */
// eslint-disable-next-line no-unused-vars
DoorPhoneDevice.prototype.parse = function (msg, options) {

  var valid = BasUtil.isObject(msg)

  if (valid) {

    if (BasUtil.isNEArray(msg[P.CAMERAS])) {

      this._cameras = msg[P.CAMERAS]
    }

    if (BasUtil.isObject(msg[P.DOORBIRD])) {

      if (BasUtil.isNEArray(msg[P.DOORBIRD][P.BUTTONS])) {

        this.parseButtons(msg[P.DOORBIRD][P.BUTTONS])
      }
    }

    if (BasUtil.isNEString(msg[P.DIRECT_DIAL_NUMBER])) {

      this._directDial = msg[P.DIRECT_DIAL_NUMBER].trim()
    }

    // Set identifier to direct dial number if no id if specified
    if (BasUtil.isNEString(msg[P.ID])) {

      this._doorPhoneIdentifier = msg[P.ID].trim()

    } else if (BasUtil.isNEString(msg[P.DIRECT_DIAL_NUMBER])) {

      this._doorPhoneIdentifier = msg[P.DIRECT_DIAL_NUMBER].trim()
    }

    if (BasUtil.isNEString(msg[P.DOOR_PHONE_GATEWAY])) {

      this._doorPhoneGateway = msg[P.DOOR_PHONE_GATEWAY]
    }

    if (BasUtil.isObject(msg[P.DTMF])) {

      if (BasUtil.isNEString(msg[P.DTMF][P.DOOR])) {

        this._dtmfDoor = msg[P.DTMF][P.DOOR].trim()
      }
    }

    if (BasUtil.isNEString(msg[P.OPEN_CLOSE])) {

      this._openClose = msg[P.OPEN_CLOSE]
    }
  }

  return valid
}

/**
 * Parse buttons
 *
 * @param {Array} buttons
 */
DoorPhoneDevice.prototype.parseButtons = function (buttons) {

  var i, length, buttonObj

  if (BasUtil.isNEArray(buttons)) {

    length = buttons.length
    for (i = 0; i < length; i++) {

      buttonObj = buttons[i]

      if (BasUtil.isObject(buttonObj)) {

        if (BasUtil.isNEString(P.UUID) &&
          BasUtil.isNEString(P.USERNAME) &&
          BasUtil.isNEString(P.PASSWORD)
        ) {

          this._buttons.push(buttonObj)
        }
      }
    }
  }
}

/**
 * @name DoorPhoneDevice#cameras
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(DoorPhoneDevice.prototype, 'cameras', {
  get: function () {
    return this._cameras
  }
})

/**
 * @name DoorPhoneDevice#buttons
 * @type {TButton[]}
 * @readonly
 */
Object.defineProperty(DoorPhoneDevice.prototype, 'buttons', {
  get: function () {
    return this._buttons
  }
})

/**
 * @name DoorPhoneDevice#directDial
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneDevice.prototype, 'directDial', {
  get: function () {
    return this._directDial
  }
})

/**
 * @name DoorPhoneDevice#doorPhoneGateway
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneDevice.prototype, 'doorPhoneGateway', {
  get: function () {
    return this._doorPhoneGateway
  }
})

/**
 * @name DoorPhoneDevice#doorPhoneIdentifier
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneDevice.prototype, 'doorPhoneIdentifier', {
  get: function () {
    return this._doorPhoneIdentifier
  }
})

/**
 * @name DoorPhoneDevice#dtmfDoor
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneDevice.prototype, 'dtmfDoor', {
  get: function () {
    return this._dtmfDoor
  }
})

/**
 * @name DoorPhoneDevice#openClose
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneDevice.prototype, 'openClose', {
  get: function () {
    return this._openClose
  }
})

module.exports = DoorPhoneDevice
