'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasCoreConnection', [
    '$rootScope',
    '$uiRouterGlobals',
    '$state',
    '$transitions',
    '$window',
    'APP_CONFIG',
    'BAS_CONNECT',
    'STATES',
    'BAS_ERRORS',
    'BAS_APP',
    'BAS_APP_STORAGE',
    'BAS_CONNECTION',
    'BAS_CURRENT_CORE',
    'BAS_MODAL',
    'BAS_SPLASH',
    'BAS_DEMO',
    'BAS_UTILITIES',
    'BasApp',
    'BasAppDevice',
    'BasDiscovery',
    'BasServerStorage',
    'BasConnectInfo',
    'BasLiveAccount',
    'BasSmartConnect',
    'BasCoreContainer',
    'CurrentBasCore',
    'BasModal',
    'BasSplash',
    'BasSplashScreen',
    'BasServerRestart',
    'BasState',
    'BasStateHelper',
    'BasStorage',
    'BasApiMismatch',
    'BasLegacyTidalCheck',
    'BasError',
    'Logger',
    BasCoreConnection
  ])

/**
 * @callback basCoreConnectCallback
 * @param {*} error
 * @param {*} [result]
 */

/**
 * @typedef {Object} TBasCoreConnectOptions
 * @property {boolean} [manualSelect = false]
 * @property {boolean} [noSplashDelay = false]
 * @property {boolean} [showModalOnError = true]
 * @property {boolean} [isInitialConnectionAttempt = false]
 * @property {boolean} [isInitialStart = false] originates from startConnection
 */

/**
 * @typedef {Object} TBasCoreConnectLastServerOptions
 * @property {boolean} [includeFailedAttempt = false]
 * @property {boolean} [allowOther = false]
 */

/**
 * @typedef {Object} TPrepareChangeBasCoreOptions
 * @property {boolean} [stopDiscovery = false]
 * @property {boolean} [stopAutoReconnect = true]
 */

/**
 * @typedef {Object} TAutoReconnectOptions
 * @property {number} [retryDelay = 10000]
 * @property {boolean} [showModalOnError = true]
 */

/**
 * @callback CBasCoreConnectionOnResumeCallback
 * @returns {?boolean}
 */

/**
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param $state
 * @param $transitions
 * @param $window
 * @param APP_CONFIG
 * @param {BAS_CONNECT} BAS_CONNECT
 * @param {STATES} STATES
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BAS_APP} BAS_APP
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @param {BAS_CONNECTION} BAS_CONNECTION
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BAS_DEMO} BAS_DEMO
 * @param {BAS_UTILITIES} BAS_UTILITIES
 * @param {BasApp} BasApp
 * @param {BasAppDevice} BasAppDevice
 * @param {BasDiscovery} BasDiscovery
 * @param {BasServerStorage} BasServerStorage
 * @param BasConnectInfo
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasSmartConnect} BasSmartConnect
 * @param BasCoreContainer
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasModal} BasModal
 * @param {BasSplash} BasSplash
 * @param {BasSplashScreen} BasSplashScreen
 * @param {BasServerRestart} BasServerRestart
 * @param {BasState} BasState
 * @param {BasStateHelper} BasStateHelper
 * @param {BasStorage} BasStorage
 * @param {BasApiMismatch} BasApiMismatch
 * @param {BasLegacyTidalCheck} BasLegacyTidalCheck
 * @param BasError
 * @param {Logger} Logger
 */
function BasCoreConnection (
  $rootScope,
  $uiRouterGlobals,
  $state,
  $transitions,
  $window,
  APP_CONFIG,
  BAS_CONNECT,
  STATES,
  BAS_ERRORS,
  BAS_APP,
  BAS_APP_STORAGE,
  BAS_CONNECTION,
  BAS_CURRENT_CORE,
  BAS_MODAL,
  BAS_SPLASH,
  BAS_DEMO,
  BAS_UTILITIES,
  BasApp,
  BasAppDevice,
  BasDiscovery,
  BasServerStorage,
  BasConnectInfo,
  BasLiveAccount,
  BasSmartConnect,
  BasCoreContainer,
  CurrentBasCore,
  BasModal,
  BasSplash,
  BasSplashScreen,
  BasServerRestart,
  BasState,
  BasStateHelper,
  BasStorage,
  BasApiMismatch,
  BasLegacyTidalCheck,
  BasError,
  Logger
) {
  /**
   * @private
   * @type {?TBasSmartConnect}
   */
  var _basSmartConnect = null

  /**
   * @private
   * @type {?TBasServerRestart}
   */
  var _basServerRestart = null

  /**
   * @private
   * @type {?TBasApiMismatch}
   */
  var _basApiMismatch = null

  var _WAIT_FOR_CONNECTING_UI_TIMEOUT_MS = 100
  var _WAIT_FOR_CONNECTING_UI_CORE_CLIENT_TIMEOUT_MS = 1000

  var _CONNECTION_LOST_RECONNECT_TIMEOUT = 1000
  var _AUTO_RECONNECT_TIMEOUT = 10000
  var _CORE_HOSTED_OTHER_TIMEOUT = 1000

  var _connectingTimeoutId = 0

  var _disconnectAfterPauseTimeoutMs = 0
  var _disconnectAfterPauseTimeoutId = 0

  var _periodicAutoReconnectTimeoutId = 0

  /**
   * @private
   * @type {CBasCoreConnectionOnResumeCallback[]}
   */
  var _onResumeCallbacks = []

  /**
   * @type {TBasAppState}
   */
  var basAppState = BasApp.get()

  /**
   * @type {TBasLiveAccountState}
   */
  var basLiveAccountState = BasLiveAccount.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasStateObj}
   */
  var basStateState = BasState.get()

  /**
   * @type {TBasSplashState}
   */
  var basSplashState = BasSplash.get()

  var _isStarted = false
  var _networkIsChanging = false
  var _disconnectIsExpected = false
  var _periodicAutoReconnect = false

  var _onPauseState = ''

  /**
   * @type {?TBasSmartConnect}
   */
  let _lastAttemptedTarget = null

  this.getInfo = getInfo
  this.start = start
  this.retry = retry
  this.stopAutoReconnect = stopAutoReconnect
  this.handleLogin = handleLogin
  this.removeLoginCredentials = removeLoginCredentials
  this.logout = logout
  this.selectCore = selectCore
  this.selectDemoCore = selectDemoCore
  this.setNetworkChanging = setNetworkChanging
  this.setDisconnectExpected = setDisconnectExpected
  this.restartServer = restartServer
  this.startPeriodicAutoReconnect = startPeriodicAutoReconnect
  this.prepareChangeBasCore = prepareChangeBasCore
  this.prepareSelectCore = prepareSelectCore
  this.prepareRestartServer = prepareRestartServer
  this.prepareLogout = prepareLogout
  this.addResumeCallback = addResumeCallback
  this.removeResumeCallback = removeResumeCallback

  init()

  function init () {

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      _onBasCurrentCoreConnected
    )

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_V2_CONNECTED,
      _onBasCurrentCoreV2Connected
    )

    $rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    )
    $rootScope.$on(
      BAS_APP.EVT_PAUSE,
      _onPause
    )
    $rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onResume
    )
    $rootScope.$on(
      BAS_APP.EVT_NETWORK_CONNECTION_CHANGED,
      _onNetworkConnectionChanged
    )
    $rootScope.$on(
      BAS_APP.EVT_NETWORK_CONNECTION_OFFLINE,
      _onNetworkConnectionOffline
    )

    $transitions.onSuccess(
      {},
      _onStateSuccess
    )
  }

  function getInfo () {
    return {
      basSmartConnect: _basSmartConnect,
      basServerRestart: _basServerRestart,
      basApiMismatch: _basApiMismatch,
      connectingTimeId: _connectingTimeoutId,
      disconnectAfterPauseTimeoutMs: _disconnectAfterPauseTimeoutMs,
      disconnectAfterPauseTimeoutId: _disconnectAfterPauseTimeoutId,
      periodicAutoReconnectTimeoutId: _periodicAutoReconnectTimeoutId,
      isStarted: _isStarted,
      networkIsChanging: _networkIsChanging,
      disconnectIsExpected: _disconnectIsExpected,
      shouldPreventDefaultOnResume: _shouldPreventDefaultOnResumeBehaviour(),
      periodicAutoReconnect: _periodicAutoReconnect,
      onPauseState: _onPauseState,
      currentStateName:
        $uiRouterGlobals.current ? $uiRouterGlobals.current.name : null,
      currentBasCoreHasCore: CurrentBasCore.hasCore(),
      basServerIsCoreConnected:
        CurrentBasCore.hasCore()
          ? currentBasCoreState.core.core.server
            ? currentBasCoreState.core.core.server.isCoreConnected()
            : null
          : null,
      basServer:
        CurrentBasCore.hasCore() ? currentBasCoreState.core.core.server : null
    }
  }

  /**
   * Should only be executed once, on app start
   *
   * @param {?TBasSmartConnect} [target]
   * @param {?TBasSmartConnectOptions} [options]
   * @param {?TBasCoreConnectOptions} [coreOptions]
   * @param {boolean} [returnStatePromise]
   * @returns {(Promise|undefined)}
   */
  function start (
    target,
    options,
    coreOptions,
    returnStatePromise
  ) {
    var coreOpts

    coreOpts = BasUtil.isObject(coreOptions) ? coreOptions : {}

    if (!BasUtil.isBool(coreOpts.isInitialStart)) {
      coreOpts.isInitialStart = true
    }

    if (returnStatePromise) {

      return new Promise(_startPromiseConstructor)

    } else {

      _startConnection(target, options, coreOpts, _onStartConnection)
    }

    function _onStartConnection (_error, result) {

      var _stateObj

      // Abort will also result in isStarted = true

      _isStarted = true

      if (result && result.$state) {

        _stateObj = result.$state()

        if (!$state.is(_stateObj)) {

          BasState.go(_stateObj)
        }
      }
    }

    function _startPromiseConstructor (resolve, reject) {

      _startConnection(target, options, coreOpts, _onConnect)

      function _onConnect (error, result) {

        // Abort will also result in an error

        if (error) {
          reject(error)
        } else {
          resolve(result)
        }
      }
    }
  }

  /**
   * Pauses all BasCoreConnection logic.
   * - Discovery is halted
   * - Auto reconnect is stopped
   * - Any current (ongoing) connection attempt is cleared
   */
  function pause () {

    BasDiscovery.halt()

    stopAutoReconnect()

    _clearCurrentBasConnect()
  }

  /**
   * Resumes BasCoreConnection logic and navigates to a start state
   */
  function resume () {

    BasDiscovery.resume()
  }

  /**
   * @param {TBasSmartConnect} [target]
   * @param {TBasSmartConnectOptions} [options]
   * @param {TBasCoreConnectOptions} [coreOptions]
   */
  function retry (
    target,
    options,
    coreOptions
  ) {
    var _target, _options

    stopAutoReconnect()

    _target = target || (
      _basSmartConnect ? _basSmartConnect.target : undefined
    )
    _options = options || (
      _basSmartConnect
        ? _basSmartConnect.options
        : undefined
    )

    if (!_target) {

      _target = {
        allowOther: true
      }
    }

    _connectWithServer(_target, _options, coreOptions)
  }

  /**
   * @param {BasServer} basServer
   * @returns {Promise}
   */
  function handleLogin (basServer) {

    return _handleCurrentBasConnectLogin(basServer)
  }

  /**
   * Called when a user explicitly chooses to logout or change user
   */
  function removeLoginCredentials () {

    if (CurrentBasCore.has()) {

      currentBasCoreState.core.removeLastUserCredentials()
    }
  }

  function logout () {

    if (CurrentBasCore.has()) {

      currentBasCoreState.core.removeLastUser()
      currentBasCoreState.core.logout()
        .then(_onLogoutUser, _onLogoutUser)
    }
  }

  /**
   * @param {(
   * BasLiveProject |
   * BasStoredServer |
   * BasDiscoveredCore |
   * TBasConnectServer |
   * string
   * )} serverInfo
   */
  function selectCore (serverInfo) {

    var _basConnectInfo

    prepareSelectCore()

    _basConnectInfo = BasConnectInfo.build(serverInfo)

    _connectWithServer(
      {
        preferred: _basConnectInfo,
        allowOther: false
      },
      null,
      {
        manualSelect: true,
        isInitialConnectionAttempt: true
      }
    )
  }

  function selectDemoCore () {

    var _basConnectInfo

    prepareSelectCore()

    _basConnectInfo = BasConnectInfo.build({
      demo: BAS_DEMO.DATA
    })

    _connectWithServer(
      {
        preferred: _basConnectInfo,
        allowOther: false
      }
    )
  }

  /**
   * @param {boolean} isChanging
   */
  function setNetworkChanging (isChanging) {

    _networkIsChanging = isChanging
  }

  /**
   * @param {boolean} isExpected
   */
  function setDisconnectExpected (isExpected) {

    _disconnectIsExpected = isExpected
  }

  /**
   * @param {string} reason
   */
  function restartServer (reason) {

    _clearCurrentBasServerRestart()

    if (CurrentBasCore.has()) {

      prepareRestartServer(reason)

      BasDiscovery.restart()

      // TODO Handle PAUSE/RESUME/NETWORK events during restart

      _basServerRestart = BasServerRestart.restartServer(
        currentBasCoreState.core,
        _onRestartServer
      )
    }
  }

  /**
   * @private
   * @param {?BasError} error
   * @param {TBasServerRestart} result
   */
  function _onRestartServer (error, result) {

    if (_basServerRestart === result) {

      // Restart is done

      _basServerRestart = null

      _disconnectIsExpected = false

      if (error) {

        // TODO Show extra info modal?

        BasSplash.show(BAS_SPLASH.S_CONNECTING_FAILED)

        _goToDiscovery()

      } else {

        _onBasSmartConnect(error, result.basSmartConnect)
      }

    } else {

      // Not interested anymore in this restart attempt
    }
  }

  /**
   * Prepares change of core
   * Has option to stop discovery and/or autoReconnect
   *
   * @param {TPrepareChangeBasCoreOptions} [options]
   */
  function prepareChangeBasCore (
    options
  ) {
    var _stopDiscovery, _stopAutoReconnect

    _stopDiscovery = false
    _stopAutoReconnect = true

    if (BasUtil.isObject(options)) {

      if (BasUtil.isBool(options.stopDiscovery)) {

        _stopDiscovery = options.stopDiscovery
      }

      if (BasUtil.isBool(options.stopAutoReconnect)) {

        _stopAutoReconnect = options.stopAutoReconnect
        if (_stopAutoReconnect) _lastAttemptedTarget = null
      }
    }

    // Clear current BasCore
    CurrentBasCore.set(null)

    _clearConnectState()

    if (_stopDiscovery) {

      BasDiscovery.stop()

    } else {

      BasDiscovery.restart()
    }

    if (_stopAutoReconnect) {

      stopAutoReconnect()
    }
  }

  function prepareSelectCore () {

    prepareChangeBasCore(
      {
        stopDiscovery: true,
        stopAutoReconnect: true
      }
    )
  }

  /**
   * @param {string} reason
   */
  function prepareRestartServer (reason) {

    switch (reason) {
      case BAS_CONNECTION.REBOOT_REASON_UPDATE:

        BasSplash.show(BAS_SPLASH.S_CONNECTING_UPDATING)

        break
      case BAS_CONNECTION.REBOOT_REASON_RESTART:
      default:

        BasSplash.show(BAS_SPLASH.S_CONNECTING_RESTARTING)

        break
    }

    _disconnectIsExpected = true
  }

  function prepareLogout () {

    // TODO

    _disconnectIsExpected = true
  }

  /**
   * @param {CBasCoreConnectionOnResumeCallback} callback
   */
  function addResumeCallback (callback) {

    if (
      BasUtil.isFunction(callback) &&
      _onResumeCallbacks.indexOf(callback) === -1
    ) {
      _onResumeCallbacks.push(callback)
    }
  }

  /**
   * @param {CBasCoreConnectionOnResumeCallback} callback
   */
  function removeResumeCallback (callback) {

    var idx

    idx = _onResumeCallbacks.indexOf(callback)
    if (idx !== -1) _onResumeCallbacks.splice(idx, 1)
  }

  function _shouldPreventDefaultOnResumeBehaviour () {

    var length, i, cb

    length = _onResumeCallbacks.length
    for (i = 0; i < length; i++) {
      cb = _onResumeCallbacks[i]
      if (BasUtil.isFunction(cb) && cb() === false) return true
    }

    return false
  }

  function _resetConnectState () {

    _clearConnectingTimeoutId()
  }

  function _clearConnectState () {

    // Clear current BasConnect
    _clearCurrentBasConnect()

    // Clear current restart
    _clearCurrentBasServerRestart()

    _resetConnectState()
  }

  function stopAutoReconnect () {

    _periodicAutoReconnect = false

    _clearCurrentBasConnect()

    _clearPeriodicAutoReconnectTimeout()
  }

  /**
   * Clears the current BasConnect.
   * Destroys the BasConnect instance
   * if there is no link with the current Bas Core.
   *
   * @private
   */
  function _clearCurrentBasConnect () {

    if (_basSmartConnect) {

      _basSmartConnect.abort()
    }

    _basSmartConnect = null
  }

  function _clearCurrentBasServerRestart () {

    if (_basServerRestart) {

      _basServerRestart.abort()
    }

    _basServerRestart = null
  }

  /**
   * Should only be executed once, on app start
   *
   * @private
   * @param {?TBasSmartConnect} [target]
   * @param {?TBasSmartConnectOptions} [options]
   * @param {?TBasCoreConnectOptions} [coreOptions]
   * @param {basCoreConnectCallback} [callback]
   */
  function _startConnection (
    target,
    options,
    coreOptions,
    callback
  ) {
    var _lastServer, _storedServer, _basConnectInfo, coreOpts, coreOptsNoModal
    var projectId, _smartConnectTarget, _optsCoreHostedTimeout

    coreOpts = BasUtil.isObject(coreOptions) ? coreOptions : {}
    if (!BasUtil.isBool(coreOpts.isInitialConnectionAttempt)) {
      coreOpts.isInitialConnectionAttempt = true
    }
    coreOptsNoModal = BasUtil.copyObjectShallow(coreOpts)
    if (!BasUtil.isBool(coreOptsNoModal.showModalOnError)) {
      coreOptsNoModal.showModalOnError = false
    }

    _optsCoreHostedTimeout = BasUtil.copyObjectShallow(options) || {}
    _optsCoreHostedTimeout.otherTimeout = _CORE_HOSTED_OTHER_TIMEOUT

    // Check for given "target" otherwise check URL search param for project

    _smartConnectTarget = BasSmartConnect.checkValidSmartConnectTarget(target)

    const preferredServer = BasAppDevice.getPreferredServer()

    if (_smartConnectTarget) {

      // Empty

    } else if (BasAppDevice.getPreferDemo()) {

      _smartConnectTarget = {
        preferred: BasConnectInfo.build({
          demo: BAS_DEMO.DATA
        }),
        allowOther: false
      }

    } else if (preferredServer) {

      _smartConnectTarget = {
        preferred: BasConnectInfo.build(preferredServer),
        allowOther: false
      }

    } else if (BasAppDevice.isLiveOnly()) {

      projectId = BasAppDevice.getPreferredProjectID()

      if (projectId) {

        _smartConnectTarget = {
          preferred: BasConnectInfo.build(
            BAS_CONNECT.PREFIX_LIVE_PROJECT + projectId
          ),
          preferredProjectId: projectId,
          allowOther: false
        }
      }
    }

    // Connect with server

    if (_smartConnectTarget) {

      _connectWithServer(
        _smartConnectTarget,
        options,
        coreOpts,
        callback
      )

    } else {

      BasDiscovery.restart()

      _lastServer = BasServerStorage.getLastServer()

      if (_lastServer) {

        _basConnectInfo = BasConnectInfo.build(_lastServer)

        if (_lastServer.isDemo()) {

          _connectWithServer(
            {
              preferred: _basConnectInfo,
              allowOther: false
            },
            options,
            coreOpts,
            callback
          )

        } else {

          if (_basConnectInfo.type === BAS_CONNECT.T_REMOTE) {

            if (basLiveAccountState.isLoggedIn) {

              if (BasAppDevice.isLiveOnlyOrWebRTC()) {

                _handleLiveOnlyOrWebRTC()

              } else {

                _connectWithServer(
                  {
                    preferred: _basConnectInfo,
                    preferredProjectId: _lastServer.cid,
                    preferredMac: _lastServer.macN,
                    preferredAddress: _lastServer.address,
                    allowOther: !(
                      BasAppDevice.isLiveOnly() &&
                      BasAppDevice.isProLive()
                    )
                  },
                  options,
                  coreOpts,
                  callback
                )
              }

            } else {

              if (BasAppDevice.isLiveOnlyOrWebRTC()) {

                BasSplash.hideAfterFrames(10)
                _goToLive(callback)

              } else {

                _storedServer =
                  BasServerStorage.getServerByProjectId(
                    _lastServer.cid,
                    BAS_APP_STORAGE.T_LOCAL
                  )

                if (_storedServer) {

                  _basConnectInfo =
                    BasConnectInfo.build(_storedServer)

                  _connectWithServer(
                    {
                      preferred: _basConnectInfo,
                      preferredProjectId: _lastServer.cid,
                      preferredMac: _lastServer.macN,
                      preferredAddress: _lastServer.address,
                      allowOther: !(
                        BasAppDevice.isLiveOnly() &&
                        BasAppDevice.isProLive()
                      )
                    },
                    options,
                    coreOpts,
                    callback
                  )

                } else {

                  if (BasAppDevice.isPossiblyCoreHosted()) {

                    _basConnectInfo = BasConnectInfo.build(
                      BasAppDevice.getHost()
                    )

                    _connectWithServer(
                      {
                        preferred: _basConnectInfo,
                        allowOther: !(
                          BasAppDevice.isLiveOnly() &&
                          BasAppDevice.isProLive()
                        ),
                        otherTimeout: _CORE_HOSTED_OTHER_TIMEOUT
                      },
                      options,
                      coreOpts,
                      callback
                    )

                  } else {

                    _connectWithServer(
                      {
                        allowOther: !(
                          BasAppDevice.isLiveOnly() &&
                          BasAppDevice.isProLive()
                        )
                      },
                      options,
                      coreOpts,
                      callback
                    )
                  }
                }
              }
            }

          } else {

            if (BasAppDevice.isLiveOnlyOrWebRTC()) {

              if (basLiveAccountState.isLoggedIn) {

                _handleLiveOnlyOrWebRTC()

              } else {

                BasSplash.hideAfterFrames(10)
                _goToLive(callback)
              }

            } else {

              _connectWithServer(
                {
                  preferred: _basConnectInfo,
                  preferredProjectId: _lastServer.cid,
                  preferredMac: _lastServer.macN,
                  preferredAddress: _lastServer.address,
                  allowOther: true
                },
                options,
                coreOpts,
                callback
              )
            }
          }
        }

      } else if (BasAppDevice.isLiveOnly() && BasAppDevice.isProLive()) {

        BasSplash.hideAfterFrames(1)
        BasSplashScreen.hide(2)
        _goToDiscovery(callback)

      } else {

        if (BasAppDevice.isPossiblyCoreHosted()) {

          _basConnectInfo = BasConnectInfo.build(BasAppDevice.getHost())

          _connectWithServer(
            {
              preferred: _basConnectInfo,
              allowOther: true
            },
            _optsCoreHostedTimeout,
            coreOptsNoModal,
            callback
          )

        } else if (BasAppDevice.isLiveOnlyOrWebRTC()) {

          if (basLiveAccountState.isLoggedIn) {

            if (basLiveAccountState.projectsChecked) {

              if (basLiveAccountState.uiProjectsOnline.length > 0) {

                _connectWithServer(
                  {
                    allowOther: true
                  },
                  options,
                  coreOptsNoModal,
                  callback
                )

              } else {

                BasSplash.hideAfterFrames(10)
                _goToDiscovery(callback)
              }

            } else {

              // TODO Stop connecting?

              _connectWithServer(
                {
                  allowOther: true
                },
                options,
                coreOptsNoModal,
                callback
              )
            }

          } else {

            BasSplash.hideAfterFrames(10)
            _goToLive(callback)
          }

        } else {

          _connectWithServer(
            {
              allowOther: true
            },
            options,
            coreOptsNoModal,
            callback
          )
        }
      }
    }

    function _handleLiveOnlyOrWebRTC () {

      if (basLiveAccountState.projectsChecked) {

        if (basLiveAccountState.uiProjectsOnline.length > 0) {

          _connectWithServer(
            {
              preferred: _basConnectInfo,
              preferredProjectId: _lastServer.cid,
              preferredMac: _lastServer.macN,
              preferredAddress: _lastServer.address,
              allowOther: true
            },
            options,
            coreOpts,
            callback
          )

        } else {

          BasSplash.hideAfterFrames(10)
          _goToDiscovery(callback)
        }

      } else {

        // TODO Stop connecting?

        _connectWithServer(
          {
            preferred: _basConnectInfo,
            preferredProjectId: _lastServer.cid,
            preferredMac: _lastServer.macN,
            preferredAddress: _lastServer.address,
            allowOther: true
          },
          options,
          coreOpts,
          callback
        )
      }
    }
  }

  /**
   * @param {TAutoReconnectOptions} [options]
   */
  function startPeriodicAutoReconnect (
    options
  ) {
    var _retryDelay, _showModalOnError

    _periodicAutoReconnect = true

    _retryDelay = _AUTO_RECONNECT_TIMEOUT
    _showModalOnError = true

    if (BasUtil.isObject(options)) {

      if (BasUtil.isPNumber(options.retryDelay, false)) {

        _retryDelay = options.retryDelay
      }

      if (BasUtil.isBool(options.showModalOnError)) {

        _showModalOnError = options.showModalOnError
      }
    }

    _checkReconnectState()

    function _checkReconnectState (state) {

      var _state

      if (!_periodicAutoReconnect && state) {

        BasSplash.hideAfterFrames(2)
        BasSplashScreen.hide()

        _state = state.$state()

        if (_state && !$state.includes(_state)) BasState.go(_state)

      } else {

        _clearPeriodicAutoReconnectTimeout()

        _periodicAutoReconnectTimeoutId = setTimeout(
          _tryReconnect,
          _retryDelay
        )
      }
    }

    function _tryReconnect () {

      if (_periodicAutoReconnect) {

        _connectWithLastServer(
          null,
          {
            showModalOnError: _showModalOnError
          },
          {
            includeFailedAttempt: true,
            allowOther: false
          },
          _onConnectWithLastServer
        )
      }
    }

    function _onConnectWithLastServer (error, result) {

      // Ignore aborted attempt
      if (error?.basType === BAS_ERRORS.T_ABORT) {

        Logger.info(
          'BCC - startPeriodicAutoReconnect - _onConnectWithLastServer ABORTED'
        )
        return
      }

      _checkReconnectState(result)
    }
  }

  /**
   * @private
   * @param {?TBasSmartConnectOptions} [smartConnectOptions]
   * @param {?TBasCoreConnectOptions} [coreOptions]
   * @param {?TBasCoreConnectLastServerOptions} [connectLastServerOptions]
   * @param {basCoreConnectCallback} [callback]
   */
  function _connectWithLastServer (
    smartConnectOptions,
    coreOptions,
    connectLastServerOptions,
    callback
  ) {
    const lastServer = BasServerStorage.getLastServer()

    const includeFailedAttempt =
      connectLastServerOptions?.includeFailedAttempt === true
    const allowOther = connectLastServerOptions?.allowOther === true

    if (includeFailedAttempt && _lastAttemptedTarget) {

      _connectWithServer(
        _lastAttemptedTarget,
        smartConnectOptions,
        coreOptions,
        callback
      )
    } else if (lastServer) {

      const basConnectInfo = BasConnectInfo.build(lastServer)
      if (lastServer.isDemo()) {

        _connectWithServer(
          {
            preferred: basConnectInfo,
            allowOther: allowOther
          },
          smartConnectOptions,
          coreOptions,
          callback
        )

      } else {

        if (basConnectInfo.type === BAS_CONNECT.T_REMOTE) {

          if (basLiveAccountState.isLoggedIn) {

            _connectWithServer(
              {
                preferred: basConnectInfo,
                preferredProjectId: lastServer.cid,
                preferredMac: lastServer.macN,
                preferredAddress: lastServer.address,
                allowOther: allowOther
              },
              smartConnectOptions,
              coreOptions,
              callback
            )

          } else {

            const storedServer = BasServerStorage.getServerByProjectId(
              lastServer.cid,
              BAS_APP_STORAGE.T_LOCAL
            )

            if (storedServer) {

              _connectWithServer(
                {
                  preferred: BasConnectInfo.build(storedServer),
                  preferredProjectId: lastServer.cid,
                  preferredMac: lastServer.macN,
                  preferredAddress: lastServer.address,
                  allowOther: allowOther
                },
                smartConnectOptions,
                coreOptions,
                callback
              )

            } else {

              _goToDiscovery(callback)
            }
          }

        } else {

          _connectWithServer(
            {
              preferred: basConnectInfo,
              preferredProjectId: lastServer.cid,
              preferredMac: lastServer.macN,
              preferredAddress: lastServer.address,
              allowOther: allowOther
            },
            smartConnectOptions,
            coreOptions,
            callback
          )
        }
      }
    } else {

      // Should not occur
      _startConnection(null, null, null, callback)
    }
  }

  /**
   * @private
   * @param {TBasSmartConnect} target
   * @param {?TBasSmartConnectOptions} [options]
   * @param {?TBasCoreConnectOptions} [coreOptions]
   * @param {basCoreConnectCallback} [callback]
   */
  function _connectWithServer (
    target,
    options,
    coreOptions,
    callback
  ) {
    var _manualSelect, _noSplashDelay, _splashTimeoutMs

    window.basTModule.tConnectWithServer = Date.now()

    _manualSelect = false
    _noSplashDelay = false

    if (BasUtil.isObject(coreOptions)) {

      if (BasUtil.isBool(coreOptions.manualSelect)) {

        _manualSelect = coreOptions.manualSelect
      }

      if (BasUtil.isBool(coreOptions.noSplashDelay)) {

        _noSplashDelay = coreOptions.noSplashDelay
      }
    }

    // TODO Handle calls to _connectWithServer while connect is in progress?
    //  Disconnected event comes while connecting...

    if (_basSmartConnect) {

      _basSmartConnect.abort()
    }

    BasDiscovery.restart()

    if (
      basSplashState.state !== BAS_SPLASH.S_CONNECTION_LOST ||
      !basSplashState.uiShow
    ) {

      if (_noSplashDelay) {

        _splashTimeoutMs = 0

      } else {

        _splashTimeoutMs = (BasAppDevice.isCoreClient() && !_manualSelect)
          ? _WAIT_FOR_CONNECTING_UI_CORE_CLIENT_TIMEOUT_MS
          : _WAIT_FOR_CONNECTING_UI_TIMEOUT_MS
      }

      _connectingTimeoutId = setTimeout(
        _showConnectingSplash,
        _splashTimeoutMs
      )
    }

    _basSmartConnect = BasSmartConnect.connect(
      target,
      options,
      _onConnect
    )
    _lastAttemptedTarget = target

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasSmartConnect} result
     */
    function _onConnect (error, result) {

      if (result === _basSmartConnect) {

        _onBasSmartConnect(error, result, coreOptions, callback)

      } else {

        // Not interested anymore in this connection attempt

        if (callback) {

          if (error && error.basType === BAS_ERRORS.T_INVALID_INPUT) {

            callback(error)

          } else {

            callback(new BasError(
              BAS_ERRORS.T_ABORT,
              undefined,
              BAS_ERRORS.M_ABORTED
            ))
          }
        }
      }
    }
  }

  /**
   * @private
   * @param {?BasError} error
   * @param {TBasSmartConnect} result
   * @param {TBasCoreConnectOptions} [coreOptions]
   * @param {basCoreConnectCallback} [callback]
   */
  function _onBasSmartConnect (
    error,
    result,
    coreOptions,
    callback
  ) {
    var _showModalOnError, _target, _opts, _actionParam

    /**
     * @type {TBasCoreConnectOptions}
     */
    var _coreOpts

    _clearConnectingTimeoutId()

    BasDiscovery.stop()

    _showModalOnError = true

    if (
      coreOptions &&
      BasUtil.isBool(coreOptions.showModalOnError)
    ) {

      _showModalOnError = coreOptions.showModalOnError
    }

    window.basTModule.tConnectWithServerDone = Date.now()

    _target = _basSmartConnect ? _basSmartConnect.target : undefined
    _opts = _basSmartConnect ? _basSmartConnect.options : undefined
    // Retry connection attempt is initiated by user, show feedback instantly
    _coreOpts = {
      noSplashDelay: true
    }
    _actionParam = [_target, _opts, _coreOpts]

    if (error) {

      if (error.basType === BAS_ERRORS.T_ABORT) {

        // Ignore

        if (callback) callback(error)

      } else if (error.basType === BAS_ERRORS.T_LIVE_NOT_AUTHORIZED) {

        if (_showModalOnError) {

          BasSplash.show(BAS_SPLASH.S_CONNECTING_FAILED_NOT_AUTHORIZED)
          BasSplash.setActionParam(_actionParam)

        } else {

          // Usually occurs on first start,
          // Hide splash screen with delay to allow state transition

          BasSplash.hideAfterFrames(10)
        }

        _goToDiscovery(callback)

      } else if (error.basType === BAS_ERRORS.T_LIVE_NO_INTEGRATOR_ACCESS) {

        if (_showModalOnError) {

          BasSplash.show(BAS_SPLASH.S_CONNECTING_FAILED_NO_INTEGRATOR_ACCESS)
          BasSplash.setActionParam(_actionParam)

        } else {

          // Usually occurs on first start,
          // Hide splash screen with delay to allow state transition

          BasSplash.hideAfterFrames(10)
        }

        _goToDiscovery(callback)

      } else if (error.basType === BAS_ERRORS.T_LIVE_NO_ACTIVE_SERVERS_FOUND) {

        if (_showModalOnError) {

          BasSplash.show(BAS_SPLASH.S_CONNECTING_FAILED_NO_ACTIVE_SERVERS)
          BasSplash.setActionParam(_actionParam)

        } else {

          // Usually occurs on first start,
          // Hide splash screen with delay to allow state transition

          BasSplash.hideAfterFrames(10)
        }

        _goToDiscovery(callback)

      } else if (error.basType === BAS_ERRORS.T_LIVE_WAF) {

        if (_showModalOnError) {

          BasSplash.show(BAS_SPLASH.S_CONNECTING_FAILED_NO_RETRY)
          BasSplash.setActionParam(_actionParam)

        } else {

          // Usually occurs on first start,
          // Hide splash screen with delay to allow state transition

          BasSplash.hideAfterFrames(10)
        }

        _goToDiscovery(callback)

      } else {

        // Start periodic auto reconnect when not a WAF exception
        if (!(
          BasAppDevice.isLiveOnlyOrWebRTC() &&
          error.basType === BAS_ERRORS.T_LIVE_WAF
        )) {
          startPeriodicAutoReconnect({
            showModalOnError: _showModalOnError
          })
        }

        if (_showModalOnError) {

          BasSplash.show(
            (
              basStateState.current.CONNECT ||
              !$uiRouterGlobals.current.name
            )
              ? BAS_SPLASH.S_CONNECTING_FAILED
              : BAS_SPLASH.S_CONNECTION_LOST
          )
          BasSplash.setActionParam(_actionParam)

        } else {

          // Usually occurs on first start,
          // Hide splash screen with delay to allow state transition

          BasSplash.hideAfterFrames(10)
        }

        _goToDiscovery(callback)
      }

    } else {

      if (result.basServer) {

        // Reachable server

        if (result.basServer.unsupportedApi === 0) {

          if (
            coreOptions &&
            coreOptions.isInitialConnectionAttempt
          ) {

            _onInitialConnection()
          }

          if (result.basConnectRetry.basConnect.credentials) {

            _handleCurrentBasConnectLogin(
              result.takeBasServer(),
              callback
            ).catch(callback)

          } else {

            _handleUsersOrInvalidCredentials(
              result.takeBasServer(),
              callback
            )
          }

        } else {

          _handleUnsupportedApi(
            result.takeBasServer(),
            callback
          )
        }

      } else {

        // Should not occur, error should exist

        BasSplash.show(BAS_SPLASH.S_CONNECTING_FAILED)
        BasSplash.setActionParam(_actionParam)

        _goToDiscovery(callback)
      }
    }
  }

  /**
   * @private
   * @param {BasServer} basServer
   * @param {basCoreConnectCallback} [callback]
   * @returns {Promise}
   */
  function _handleCurrentBasConnectLogin (
    basServer,
    callback
  ) {
    var _currentBasCoreContainer, _basCoreContainer

    _currentBasCoreContainer = currentBasCoreState.core

    _basCoreContainer = (
      _currentBasCoreContainer &&
      _currentBasCoreContainer.basServer === basServer
    )
      ? _currentBasCoreContainer
      : new BasCoreContainer(basServer)

    // Save server and user in local storage

    _basCoreContainer.saveToStorage(true)

    CurrentBasCore.set(_basCoreContainer)

    _clearConnectState()

    window.basTModule.tConnectCore = Date.now()

    // Connect

    return _basCoreContainer.connectCore()
      .then(_onCoreConnect, _onCoreConnectError)

    function _onCoreConnect () {

      window.basTModule.tConnectCoreDone = Date.now()

      _clearConnectState()

      BasSplashScreen.hide()

      if (callback) {

        callback(null, BasState.target(STATES.MAIN))
        // No state transition promise to hook into, instantly hide splash
        _hideSplash()

      } else {

        if (!$state.includes(STATES.MAIN)) {

          // TODO Move to BasState.go logic with callback result
          window.basTModule.tGoToMain = Date.now()

          // Hide splash after state transition to main state is complete
          BasState.go(STATES.MAIN)
            .then(_onMainState, _onMainStateError)
            .then(_hideSplash)

        } else {

          _hideSplash()
        }
      }
    }

    function _onCoreConnectError () {

      // Failed to connect

      window.basTModule.tConnectCoreDone = Date.now()

      _clearConnectState()

      BasSplash.hideAfterFrames(2)
      BasSplashScreen.hide()

      if (callback) {

        callback(null, BasState.target(STATES.CONNECT_PROFILES))

      } else {

        if ($state.is(STATES.CONNECT_PROFILES)) {

          // Empty

        } else {

          BasState.go(STATES.CONNECT_PROFILES)
        }
      }

      return Promise.reject(new Error('onCoreConnectError'))
    }
  }

  function _onMainState () {

    window.basTModule.tGoToMainDone = Date.now()
  }

  function _onMainStateError () {

    window.basTModule.tGoToMainDone = Date.now()
  }

  function _hideSplash () {

    BasSplash.hideAfterFrames(2)
  }

  /**
   * @private
   * @param {BasServer} basServer
   * @param {basCoreConnectCallback} [callback]
   */
  function _handleUsersOrInvalidCredentials (
    basServer,
    callback
  ) {
    var _currentBasCoreContainer, _basCoreContainer

    _currentBasCoreContainer = currentBasCoreState.core

    _basCoreContainer = (
      _currentBasCoreContainer &&
      _currentBasCoreContainer.basServer === basServer
    )
      ? _currentBasCoreContainer
      : new BasCoreContainer(basServer)

    // Save server and user in local storage

    _basCoreContainer.saveToStorage(true)

    CurrentBasCore.set(_basCoreContainer)

    // Clear current BasConnect after creating and setting
    // the BasCoreContainer
    _clearConnectState()

    BasSplash.hideAfterFrames(2)
    BasSplashScreen.hide()

    if (callback) {

      callback(null, BasState.target(STATES.CONNECT_PROFILES))

    } else {

      if ($state.is(STATES.CONNECT_PROFILES)) {

        // Empty

      } else {

        BasState.go(STATES.CONNECT_PROFILES)
      }
    }
  }

  /**
   * @private
   * @param {BasServer} basServer
   * @param {basCoreConnectCallback} [callback]
   */
  function _handleUnsupportedApi (
    basServer,
    callback
  ) {
    var __basApiMisMatch

    // TODO Needs testing
    // TODO Handle PAUSE/RESUME/NETWORK events

    __basApiMisMatch = _basApiMismatch = BasApiMismatch.handleApiMismatch(
      basServer,
      _onApiMismatch
    )

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasApiMismatch} result
     */
    function _onApiMismatch (error, result) {

      var _basServer

      if (__basApiMisMatch === _basApiMismatch) {

        if (error) {

          // TODO Handle user abort?

          _goToDiscovery(callback)

        } else {

          if (result.basServer) {

            _basServer = result.takeBasServer()

            if (_basServer.unsupportedApi === 0) {

              if (_basServer.credentials.length) {

                _handleCurrentBasConnectLogin(
                  _basServer,
                  callback
                ).catch(callback)

              } else {

                _handleUsersOrInvalidCredentials(
                  _basServer,
                  callback
                )
              }

            } else {

              // Should not occur

              _basServer.destroy()
              _basServer = null

              BasSplash.show(BAS_SPLASH.S_CONNECTING_FAILED)

              _goToDiscovery(callback)
            }

          } else {

            // Should not occur

            BasSplash.show(BAS_SPLASH.S_CONNECTING_FAILED)

            _goToDiscovery(callback)
          }
        }

      } else {

        // Not interested anymore

        // TODO Handle this case?

        if (callback) {

          callback(new BasError(
            BAS_ERRORS.T_ABORT,
            undefined,
            BAS_ERRORS.M_ABORTED
          ))
        }
      }
    }
  }

  /**
   * @private
   * @param {basCoreConnectCallback} [callback]
   */
  function _goToLive (callback) {

    prepareChangeBasCore()

    // Waits 2 frames by default
    BasSplashScreen.hide()

    if (callback) {

      callback(null, BasState.target(STATES.CONNECT_LIVE))

    } else {

      if (!$state.is(STATES.CONNECT_LIVE)) {

        BasState.go(STATES.CONNECT_LIVE)
      }
    }
  }

  /**
   * @private
   * @param {basCoreConnectCallback} [callback]
   */
  function _goToDiscovery (callback) {

    // Setting stopAutoReconnect to true would break autoReconnect
    prepareChangeBasCore({ stopAutoReconnect: false })

    // Waits 2 frames by default
    BasSplashScreen.hide()

    if (callback) {

      callback(null, BasState.target(STATES.CONNECT_DISCOVERY))

    } else {

      if (!$state.is(STATES.CONNECT_DISCOVERY)) {

        BasState.go(STATES.CONNECT_DISCOVERY)
      }
    }
  }

  function _onLogoutUser () {

    // TODO Reset some state?
  }

  function _showConnectingSplash () {

    _clearConnectingTimeoutId()

    BasSplashScreen.hide()

    BasSplash.show(BAS_SPLASH.S_CONNECTING_CORES)

    $rootScope.$applyAsync()
  }

  function _disconnectCurrentBasCore () {

    if (CurrentBasCore.has()) {

      currentBasCoreState.core.disconnect()
    }
  }

  /**
   * @private
   * @param {boolean} [allowOther=false]
   * @param {?TBasSmartConnectOptions} [options]
   * @param {?TBasCoreConnectOptions} [coreOptions]
   */
  function _reconnectOnResume (
    allowOther,
    options,
    coreOptions
  ) {

    if (BasStateHelper.hasBaseState(_onPauseState, STATES.CONNECT_PROFILES)) {
      // In profiles view, we should reconnect to remote servers to retrieve
      //  profiles again.
      const lastServer = BasServerStorage.getLastServer()

      if (lastServer) {

        const basConnectInfo = BasConnectInfo.build(lastServer)

        if (basConnectInfo.type === BAS_CONNECT.T_REMOTE) {

          if (basLiveAccountState.isLoggedIn) {

            _connectWithServer(
              {
                preferred: basConnectInfo,
                preferredProjectId: lastServer.cid,
                preferredMac: lastServer.macN,
                preferredAddress: lastServer.address,
                allowOther: allowOther
              },
              {
                ...options,
                noLogin: true
              },
              coreOptions
            )
          }
        }
      }
    } else {

      _connectWithLastServer(
        options,
        coreOptions,
        {
          includeFailedAttempt: true,
          allowOther: allowOther
        }
      )
    }
  }

  function _onPauseDisconnectTimeout () {

    _clearDisconnectAfterPauseTimeout()

    _disconnectIsExpected = true

    _disconnectCurrentBasCore()
  }

  function _clearConnectingTimeoutId () {

    clearTimeout(_connectingTimeoutId)
    _connectingTimeoutId = 0
  }

  function _clearDisconnectAfterPauseTimeout () {

    clearTimeout(_disconnectAfterPauseTimeoutId)
    _disconnectAfterPauseTimeoutId = 0
  }

  function _clearPeriodicAutoReconnectTimeout () {

    clearTimeout(_periodicAutoReconnectTimeoutId)
    _periodicAutoReconnectTimeoutId = 0
  }

  // region Events

  /**
   * @param {Object} _event
   * @param {BasCoreContainer} _basCoreContainer
   * @param {boolean} isConnected
   */
  function _onBasCurrentCoreConnected (
    _event,
    _basCoreContainer,
    isConnected
  ) {
    Logger.info(
      'BCC - BasCurrentCoreConnected',
      isConnected,
      _disconnectIsExpected,
      basAppState.isPaused,
      _networkIsChanging,
      navigator.onLine,
      _shouldPreventDefaultOnResumeBehaviour(),
      CurrentBasCore.hasCore(),
      (
        CurrentBasCore.hasCore()
          ? currentBasCoreState.core.core.server
            ? currentBasCoreState.core.core.server.isCoreConnected()
            : undefined
          : null
      ),
      CurrentBasCore.hasCore() ? currentBasCoreState.core.core.server : null,
      BasServerStorage.getLastServer(),
      basLiveAccountState.isLoggedIn
    )
    _onCoreConnectionEvent(isConnected)
  }

  /**
   * @param {Object} _event
   * @param {BasCoreContainer} _basCoreContainer
   * @param {boolean} isConnected
   */
  function _onBasCurrentCoreV2Connected (
    _event,
    _basCoreContainer,
    isConnected
  ) {
    Logger.info(
      'BCC - BasCurrentCoreV2Connected',
      isConnected,
      _disconnectIsExpected,
      basAppState.isPaused,
      _networkIsChanging,
      navigator.onLine,
      _shouldPreventDefaultOnResumeBehaviour(),
      CurrentBasCore.hasCore(),
      (
        CurrentBasCore.hasCore()
          ? currentBasCoreState.core.core.server
            ? currentBasCoreState.core.core.server.isCoreConnected()
            : undefined
          : null
      ),
      CurrentBasCore.hasCore() ? currentBasCoreState.core.core.server : null,
      BasServerStorage.getLastServer(),
      basLiveAccountState.isLoggedIn
    )

    _onCoreConnectionEvent(isConnected)
  }

  function _onCoreConnectionEvent (isConnected) {

    if (isConnected) {

      _disconnectIsExpected = false

      // Clear auto reconnect and current BasConnect
      // Connected means we are done
      stopAutoReconnect()
      _lastAttemptedTarget = null

    } else {

      if (!_disconnectIsExpected) {

        if (basAppState.isPaused) {

          // Ignore disconnect for now, reconnect when un-paused

          _clearDisconnectAfterPauseTimeout()

        } else if (!_networkIsChanging) {

          BasSplash.show(BAS_SPLASH.S_CONNECTION_LOST)

          startPeriodicAutoReconnect(
            { retryDelay: _CONNECTION_LOST_RECONNECT_TIMEOUT }
          )
        }
      }
    }
  }

  function _onInitialConnection () {

    BasLegacyTidalCheck.check()
  }
  function _onSplashVisibility (_event, isVisible) {
    const _currentState = $uiRouterGlobals.current.name

    if (!isVisible) {

      if (BasStateHelper.hasBaseState(_currentState, STATES.MAIN)) {
        _showOutDatedServerModalIfNeeded()
      }
    }
  }

  function _onStateSuccess (transition) {
    const from = transition.from()
    const to = transition.targetState()

    if (
      !BasStateHelper.hasBaseState(from.name, STATES.MAIN) &&
      BasStateHelper.hasBaseState(to.name(), STATES.MAIN)
    ) {
      const basTm = $window.basTModule
      const currentCore = currentBasCoreState.core
      _showOutDatedServerModalIfNeeded()

      if (
        currentCore &&
        currentCore.core?.version &&
        currentCore.basServer
      ) {

        basTm.logEvt({
          evtType: basTm.T_APP_CONNECTED_TO_SERVER,
          connectedServerVersion: currentCore.core.version.textFull,
          projectUuid: currentCore.basServer.cid
        })
      }
    }
  }

  function _onPause () {

    _onPauseState = $uiRouterGlobals.current.name

    pause()

    // Pause timers

    _clearDisconnectAfterPauseTimeout()

    // Timers can become very inaccurate when app is in standby
    // Record start time and compare to determine actual time when resumed

    _disconnectAfterPauseTimeoutMs =
      BasAppDevice.getPauseDisconnectTimeoutMs()
    _disconnectAfterPauseTimeoutId = setTimeout(
      _onPauseDisconnectTimeout,
      _disconnectAfterPauseTimeoutMs
    )
  }

  function _onResume () {

    var _basServer

    Logger.info(
      'BCC - RESUME',
      _onPauseState,
      _disconnectAfterPauseTimeoutId,
      navigator.onLine,
      _shouldPreventDefaultOnResumeBehaviour(),
      CurrentBasCore.hasCore(),
      (
        CurrentBasCore.hasCore()
          ? currentBasCoreState.core.core.server
            ? currentBasCoreState.core.core.server.isCoreConnected()
            : undefined
          : null
      ),
      CurrentBasCore.hasCore() ? currentBasCoreState.core.core.server : null,
      BasServerStorage.getLastServer(),
      basLiveAccountState.isLoggedIn
    )

    _showOutDatedServerModalIfNeeded()

    if (!$state.is(_onPauseState)) {

      Logger.warn(
        'BCC - RESUME - PAUSE state is not equal to current state',
        _onPauseState,
        $state.$current
          ? $state.$current.name
          : null,
        $state.$current
      )
    }

    resume()

    // Certain modals should be explicitly shown to the user
    if (_shouldPreventDefaultOnResumeBehaviour()) {

      return
    }

    _basServer = CurrentBasCore.hasCore()
      ? currentBasCoreState.core.core.server
      : null

    if (_disconnectAfterPauseTimeoutId) {

      // Should not have been disconnected in the meantime

      _clearDisconnectAfterPauseTimeout()

      if (BasStateHelper.hasBaseState(_onPauseState, STATES.MAIN)) {

        if (_basServer && _basServer.isCoreConnected()) {

          // OK

        } else {

          _reconnectOnResume()
        }

      } else if (
        BasStateHelper.hasBaseState(_onPauseState, STATES.CONNECT_PROFILES)
      ) {

        _basServer = CurrentBasCore.hasCore()
          ? currentBasCoreState.core.core.server
          : null

        if (_basServer && _basServer.isConnected()) {

          // OK

        } else {

          _reconnectOnResume(
            false,
            {
              noLogin: true
            },
            {
              noSplashDelay: true
            }
          )
        }

      } else if (
        BasStateHelper.hasBaseState(_onPauseState, STATES.CONNECT_DISCOVERY)
      ) {

        if (CurrentBasCore.hasCore()) {

          _basServer = currentBasCoreState.core.core.server

          if (_basServer && _basServer.isConnected()) {

            // OK

          } else {

            // Start reconnect procedure

            _reconnectOnResume(
              !BasAppDevice.isLiveOnly() && BasAppDevice.isProLive(),
              null,
              {
                noSplashDelay: true
              }
            )
          }

        } else {

          _handleResumeOnDiscovery()
        }

      } else if (!_onPauseState) {

        _goToDiscovery()
      }

    } else if (_onPauseState) {

      if (
        BasStateHelper.hasBaseState(_onPauseState, STATES.CONNECT_DISCOVERY)
      ) {

        _handleResumeOnDiscovery()

      } else if (
        BasStateHelper.hasBaseState(_onPauseState, STATES.CONNECT_PROFILES)
      ) {

        _reconnectOnResume(
          (BasAppDevice.isLiveOnly() && BasAppDevice.isProLive())
            ? false
            : !_onPauseState,
          {
            noLogin: true
          }
        )

      } else if (
        BasStateHelper.hasBaseState(_onPauseState, STATES.MAIN)
      ) {

        _reconnectOnResume(
          (BasAppDevice.isLiveOnly() && BasAppDevice.isProLive())
            ? false
            : !_onPauseState
        )

      } else {

        // Do nothing

      }

    } else {

      _goToDiscovery()
    }
  }

  function _showOutDatedServerModalIfNeeded () {
    const currentCore = currentBasCoreState.core

    if (currentCore?.core?.version) {

      const basCoreVersion = currentCore.core.version
      const serverVersionMajor = basCoreVersion.major
      const appVersionMajor = APP_CONFIG.major
      const majorDifference = appVersionMajor - serverVersionMajor

      if (majorDifference > 1) {

        const modalTimeStamp =
          BasStorage.get(BAS_APP_STORAGE.K_MODAL_TIMESTAMP)
            ? new Date(BasStorage.get(BAS_APP_STORAGE.K_MODAL_TIMESTAMP))
            : 0

        const modalTimeStampDiff = Date.now() - modalTimeStamp

        if (modalTimeStampDiff > BAS_UTILITIES.T_1H_MS) {
          _showOutDatedServerModal()
        }
      }
    }
  }

  function _showOutDatedServerModal () {
    BasModal.show(BAS_MODAL.T_UNSUPPORTED_API_INFO)
    BasStorage.set(BAS_APP_STORAGE.K_MODAL_TIMESTAMP, Date.now())
  }

  function _onNetworkConnectionChanged (_data, _previous, _current) {

    if (basStateState.current.MAIN || _basSmartConnect) {

      _reconnectWithLastServer()
    }
  }

  function _onNetworkConnectionOffline () {

    if (basStateState.current.MAIN || _basSmartConnect) {

      _reconnectWithLastServer()
    }
  }

  function _reconnectWithLastServer () {

    _disconnectCurrentBasCore()
    _clearCurrentBasConnect()
    prepareChangeBasCore({
      stopDiscovery: false,
      stopAutoReconnect: true
    })
    _connectWithLastServer(
      null,
      {
        noSplashDelay: true
      }
    )
  }

  function _handleResumeOnDiscovery () {

    if (BasAppDevice.isCoreClient()) {

      // Always attempt reconnecting for Core clients

      _reconnectOnResume(
        true,
        null,
        {
          noSplashDelay: true
        }
      )

    } else if (BasAppDevice.isLiveOnly() && BasAppDevice.isProLive()) {

      // Do nothing

    } else {

      // Check if app has connected with a server at least once
      // before attempting to connect with a server

      if (BasServerStorage.hasKnownCores()) {

        _reconnectOnResume(
          true,
          null,
          {
            noSplashDelay: true
          }
        )

      } else {

        // Do nothing, never connected to a server before
      }
    }
  }

  // endregion
}
