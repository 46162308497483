'use strict'

angular
  .module('basalteApp')
  .service('BasCoreClientState', [
    '$transitions',
    'SETTINGS_STATES',
    'BasStateHelper',
    'BasSettingsStateHelper',
    'BasAppDevice',
    'CurrentBasCore',
    BasCoreClientState
  ])

/**
 * @param $transitions
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {BasStateHelper} BasStateHelper
 * @param {BasSettingsStateHelper} BasSettingsStateHelper
 * @param {BasAppDevice} BasAppDevice
 * @param {CurrentBasCore} CurrentBasCore
 * @constructor
 */
function BasCoreClientState (
  $transitions,
  SETTINGS_STATES,
  BasStateHelper,
  BasSettingsStateHelper,
  BasAppDevice,
  CurrentBasCore

) {

  /**
   * @type {TCurrentBasCoreState}
   */
  const currentBasCoreState = CurrentBasCore.get()

  if (BasAppDevice.isCoreClient()) {

    $transitions.onStart({}, _onTransitionStart)
  }

  function _onTransitionStart (transition) {

    const targetState = transition.targetState()
    const toName = targetState.name()

    const settingsStatePrefix = BasSettingsStateHelper.getPrefix(toName)

    if (
      !currentBasCoreState.core?.core?.profile.admin &&
      BasStateHelper.hasBaseState(
        toName,
        BasSettingsStateHelper.getSettingsState(
          SETTINGS_STATES.PROJECT,
          settingsStatePrefix
        )
      )
    ) {
      return false
    }
  }
}
