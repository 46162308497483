'use strict'

import { Capacitor } from '@capacitor/core'
import { StatusBar } from '@capacitor/status-bar'

angular
  .module('basalteApp')
  .service('BasStatusBar', [
    '$window',
    BasStatusBar
  ])

function BasStatusBar (
  $window
) {
  this.hide = hide
  this.show = show

  function hide () {

    var _statusBar = _getStatusBar()

    return _statusBar ? _statusBar.hide() : undefined
  }

  function show () {

    var _statusBar = _getStatusBar()

    return _statusBar ? _statusBar.show() : undefined
  }

  function _getStatusBar () {

    var _statusBar

    if (Capacitor.isPluginAvailable('StatusBar')) {
      _statusBar = StatusBar
    } else if ($window['basalteCordova']) {
      _statusBar = $window['basalteCordova']['BasStatusBar']
    }

    return (_statusBar && _statusBar.hide && _statusBar.show)
      ? _statusBar
      : null
  }
}
