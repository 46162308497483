'use strict'

var BasUtil = require('@basalte/bas-util')

var BasCoreSocket = require('./bas_core_socket')

var P = require('./parser_constants')

var BasCrypto = require('./bas_crypto')

/**
 * @constructor
 * @extends BasCoreSocket
 * @param {BasRemoteServer} basRemoteServer
 * @param {string} path
 * @since 3.0.0
 */
function BasCoreSocketRtcCore (basRemoteServer, path) {

  BasCoreSocket.call(this, path)

  /**
   * @private
   * @type {BasRemoteServer}
   */
  this._basRemoteServer = basRemoteServer

  this._handleOpen = this._onOpen.bind(this)
}

BasCoreSocketRtcCore.prototype = Object.create(BasCoreSocket.prototype)
BasCoreSocketRtcCore.prototype.constructor = BasCoreSocketRtcCore

/**
 * @returns {boolean}
 */
BasCoreSocketRtcCore.prototype.isConnected = function () {

  return this._basRemoteServer.isRemoteCoreConnected()
}

/**
 * @param {string} path
 * @param {TCoreCredentials} credentials
 * @returns {Promise}
 */
BasCoreSocketRtcCore.prototype.openConnection = function (
  path,
  credentials
) {

  var data, cNonce, hash

  cNonce = BasCrypto.getCNonce()

  hash = BasCrypto.sha256(
    credentials.credentialTokenHash +
    cNonce +
    BasCrypto.sha256(path)
  )

  data = {}

  if (credentials.user) {

    data[P.U] = encodeURIComponent(credentials.user)
    data[P.T] = encodeURIComponent(credentials.token)
    data[P.CN] = cNonce
    data[P.H] = hash
  }

  if (credentials.jwt) {

    data[P.JWT] = credentials.jwt
  }

  return this.request(data).then(this._handleOpen)
}

/**
 * @private
 * @param {Object} result
 * @returns {Promise}
 */
BasCoreSocketRtcCore.prototype._onOpen = function (result) {

  if (BasUtil.isObject(result)) {

    if (result[P.RESULT] === P.OK) {

      return Promise.resolve()

    } else if (result[P.ERROR_CODE] === BasCoreSocket.STATUSCODE_JWT_REVOKED) {

      this._emitJWTRevoked()
      return Promise.reject('JWT Revoked')
    }
  }

  return Promise.reject('Unknown error')
}

/**
 * @returns {Promise}
 */
BasCoreSocketRtcCore.prototype.closeConnection = function () {

  return this._basRemoteServer.reconnectRemoteCoreConnection()
}

/**
 * @param {Object} data
 * @returns {boolean}
 */
BasCoreSocketRtcCore.prototype.sendData = function (data) {

  return this._basRemoteServer.sendCoreData(data)
}

module.exports = BasCoreSocketRtcCore
