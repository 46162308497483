'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('SpotifyCollection', [
    'BAS_SPOTIFY',
    'BAS_LIBRARY_ERRORS',
    'BasLibraryCollection',
    'SpotifyModel',
    'LibraryState',
    'BasSourceSpotify',
    'Logger',
    spotifyCollectionFactory
  ])

/**
 * @param {BAS_SPOTIFY} BAS_SPOTIFY
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param BasLibraryCollection
 * @param {SpotifyModel} SpotifyModel
 * @param {LibraryState} LibraryState
 * @param BasSourceSpotify
 * @param Logger
 * @returns SpotifyCollection
 */
function spotifyCollectionFactory (
  BAS_SPOTIFY,
  BAS_LIBRARY_ERRORS,
  BasLibraryCollection,
  SpotifyModel,
  LibraryState,
  BasSourceSpotify,
  Logger
) {
  var className = 'Spotify Collection'
  var SEARCH_THUMB_AMOUNT = 3

  var K_ITEMS = 'items'

  Logger.debug(className)

  /**
   * @constructor
   * @param {Function} handler
   */
  function SpotifyCollection (handler) {

    BasLibraryCollection.call(this, handler)

    /**
     * @instance
     * @type {string}
     */
    this.type = ''

    /**
     * @instance
     * @type {string}
     */
    this.contentType = SpotifyCollection.TYPE_TRACK

    /**
     * @instance
     * @type {string}
     */
    this.id = ''

    /**
     * @instance
     * @type {string}
     */
    this.query = ''

    /**
     * @instance
     * @type {number}
     */
    this.limit = -1

    /**
     * @instance
     * @type {Object}
     */
    this.lastPagedResult = null
  }

  // Set new prototype from inherited object
  SpotifyCollection.prototype = Object.create(BasLibraryCollection.prototype)

  // Set correct constructor after setting new prototype
  SpotifyCollection.prototype.constructor = SpotifyCollection

  /**
   * @constant {string}
   */
  SpotifyCollection.CT_ALBUM = 'album'

  /**
   * @constant {string}
   */
  SpotifyCollection.CT_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  SpotifyCollection.CT_GENRE = 'genre'

  /**
   * @constant {string}
   */
  SpotifyCollection.CT_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  SpotifyCollection.CT_TRACK = 'track'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_ME = 'me'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_GENRE = 'genre'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_ALBUM = 'album'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_NEW_RELEASES = 'new releases'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_FEATURED = 'featured'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_CHARTS = 'charts'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_RECENT = 'recent'

  /**
   * @constant {string}
   */
  SpotifyCollection.TYPE_SEARCH = 'search'

  /**
   * @param {string} contentType
   * @param {boolean} setTitle
   */
  SpotifyCollection.prototype.setContentType = function (
    contentType,
    setTitle
  ) {
    var _this = this

    // Set contentType
    this.contentType = contentType

    // Check contentType
    switch (contentType) {
      case SpotifyCollection.CT_ALBUM:

        setTitleChecked('albums')

        break
      case SpotifyCollection.CT_ARTIST:

        setTitleChecked('artists')

        break
      case SpotifyCollection.CT_GENRE:

        setTitleChecked('genres')

        break
      case SpotifyCollection.CT_PLAYLIST:

        setTitleChecked('playlists')

        break
      case SpotifyCollection.CT_RADIO:

        setTitleChecked('mixes')

        break
      case SpotifyCollection.CT_TRACK:

        setTitleChecked('songs')

        break
      default:

        // Reset contentType
        this.contentType = SpotifyCollection.CT_TRACK

        Logger.warn(className + ' - setType- Unknown contentType', contentType)
    }

    function setTitleChecked (str) {

      if (setTitle === true) _this.setTitleId(str)
    }
  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   * @returns {Promise}
   */
  SpotifyCollection.prototype.itemReorder = function (
    spliceIndex,
    originalIndex
  ) {
    var id, basSource, data, index

    if (spliceIndex === originalIndex) return Promise.resolve()

    if (!BasUtil.isObject(this.detailElement)) {
      return Promise.reject(BasLibraryCollection.NO_UUID)
    }

    index = spliceIndex > originalIndex ? spliceIndex + 1 : spliceIndex

    data = {
      range_start: originalIndex,
      insert_before: index
    }

    // Get playlist uuid
    id = this.detailElement.id

    basSource = LibraryState.getSpotifySource()

    return basSource
      ? basSource.spotify.reorderSongs(id, data)
      : Promise.reject(new Error('no basSource'))
  }

  /**
   * @param {number} index
   * @returns {Promise}
   */
  SpotifyCollection.prototype.itemSwipe = function (index) {

    var id, uri, tracks, data, basSource

    if (!BasUtil.isObject(this.detailElement)) {
      return Promise.reject(BasLibraryCollection.NO_UUID)
    }

    // Get playlist uuid
    id = this.detailElement.id

    if (this.elements[index]) {

      uri = BasUtil.isNEString(this.elements[index].linkedUri)
        ? this.elements[index].linkedUri
        : this.elements[index].uri

      tracks = [{
        uri: uri,
        positions: [index]
      }]
    }

    if (Array.isArray(tracks)) {

      data = { tracks: tracks }

      basSource = LibraryState.getSpotifySource()

      if (basSource) {
        return basSource.spotify.removeSongFromPlaylist(id, data)
          .then(this.handlePlaylists)
      }
    }

    return Promise.reject(new Error('no tracks'))
  }

  SpotifyCollection.prototype.refresh = function () {

    var basSource, func

    basSource = LibraryState.getSpotifySource()
    func = this.retrieveFunction()

    if (func && basSource && basSource.spotify.isLinked()) {

      this.isFetching = true
      return basSource.spotify.getXElements(
        func,
        this.limit,
        this.elements.length
      )
        .then(
          this.processElements.bind(this),
          this.onRetrieveError.bind(this)
        )
        .then(this.replaceItems.bind(this))
        .catch(this.onFail.bind(this))
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)
  }

  SpotifyCollection.prototype.onFail = function () {
    this.hasReachedEnd = false
    this.isFetching = false
  }

  /**
   * @returns {Promise}
   */
  SpotifyCollection.prototype.retrieveAll = function () {

    var basSource, func

    basSource = LibraryState.getSpotifySource()
    func = this.retrieveFunction()

    if (func && basSource && basSource.spotify.isLinked()) {

      this.isFetching = true
      return basSource.spotify.getXElements(func, 100, -1)
        .then(
          this.processElements.bind(this),
          this.onRetrieveError.bind(this)
        )
        .then(this.replaceItems.bind(this))
        .catch(this.onFail.bind(this))
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)
  }

  /**
   * @returns {Promise}
   */
  SpotifyCollection.prototype.retrieve = function () {

    var func = this.retrieveFunction()

    if (func) {

      // Set fetching state
      this.isFetching = true

      return func()
        .then(
          this.processElements.bind(this),
          this.onRetrieveError.bind(this)
        )
        .then(this.concatItems.bind(this))

    } else {

      return Promise.reject(BAS_LIBRARY_ERRORS.NO_RETRIEVE_FUNCTION)
    }
  }

  /**
   * @returns {?Function}
   */
  SpotifyCollection.prototype.retrieveFunction = function () {

    var basSource, spotify, params

    basSource = LibraryState.getSpotifySource()

    if (!basSource) return null

    spotify = basSource.spotify

    if (this.type === SpotifyCollection.TYPE_SEARCH) {

      params = {}
      params[BAS_SPOTIFY.PARAM_TYPE] = this.contentType
      params[BAS_SPOTIFY.PARAM_QUERY] = this.query

      if (this.limit > 0) {
        params[BAS_SPOTIFY.PARAM_LIMIT] = this.limit
      }

      return spotify.searchLib.bind(spotify, params)
    }

    switch (this.contentType) {

      case SpotifyCollection.CT_ALBUM:

        switch (this.type) {
          case SpotifyCollection.TYPE_NEW_RELEASES:
            return spotify.getNewReleasesLib.bind(spotify)
          case SpotifyCollection.TYPE_ME:
            return spotify.getUserAlbumsLib.bind(spotify)
          case SpotifyCollection.TYPE_ARTIST:
            return spotify
              .getArtistAlbumsLib.bind(spotify, this.id)
          default:
            return null
        }

      case SpotifyCollection.CT_GENRE:

        return spotify.getGenresLib.bind(spotify)

      case SpotifyCollection.CT_ARTIST:

        switch (this.type) {
          case SpotifyCollection.TYPE_ARTIST:
            return spotify
              .getArtistRelatedLib.bind(spotify, this.id)
          case SpotifyCollection.TYPE_ME:
            return spotify.getUserArtistsLib.bind(spotify)
          default:
            return null
        }

      case SpotifyCollection.CT_PLAYLIST:

        switch (this.type) {
          case SpotifyCollection.TYPE_GENRE:
            return spotify
              .getGenrePlaylistsLib.bind(spotify, this.id)
          case SpotifyCollection.TYPE_FEATURED:
            return spotify.getFeaturedPlaylistsLib.bind(spotify)
          case SpotifyCollection.TYPE_CHARTS:
            return spotify.getChartsLib.bind(spotify)
          case SpotifyCollection.TYPE_ME:
            return spotify.getUserPlaylists.bind(spotify)
          default:
            return null
        }

      case SpotifyCollection.CT_TRACK:

        switch (this.type) {
          case SpotifyCollection.TYPE_PLAYLIST:
            return spotify.getPlaylistSongsLib
              .bind(spotify, this.id)
          case SpotifyCollection.TYPE_ALBUM:
            return spotify.getAlbumSongsLib.bind(spotify, this.id)
          case SpotifyCollection.TYPE_ME:
            return spotify.getUserSongsLib.bind(spotify)
          case SpotifyCollection.TYPE_RECENT:
            return spotify.getUserRecentSongs.bind(spotify)
          case SpotifyCollection.TYPE_ARTIST:
            return spotify.getArtistTopTracksLib
              .bind(spotify, this.id)
          default:
            return null
        }
    }

    return null
  }

  /**
   * Returns an array of all element uris
   *
   * @returns {string[] | null}
   */
  SpotifyCollection.prototype.getUris = function () {

    var i, length, element, result

    if (Array.isArray(this.elements)) {

      result = []

      length = this.elements.length
      for (i = 0; i < length; i++) {

        element = this.elements[i]

        if (BasUtil.isObject(element) &&
          BasUtil.isNEString(element.uri)) {

          result.push(element.uri)
        }
      }

      return result
    }

    return null
  }

  /**
   * @param {Object} result
   * @returns {(Array|Promise)}
   */
  SpotifyCollection.prototype.processElements = function (result) {

    var processed

    this.isFetching = false

    if (BasUtil.safeHasOwnProperty(result, K_ITEMS)) {

      // Hide the collection if empty
      if (result.items.length === 0) {

        this.lastElement = null
        this.setTitle('')

        this.hasReachedEnd = true

        return []
      }

      this.lastPagedResult = result

      // Check if end is reached
      if (!BasUtil.isNEString(result.next)) {

        // End is reached
        this.hasReachedEnd = true
      }

      // Check if Show More is still needed
      if (result.total < SEARCH_THUMB_AMOUNT) {
        this.lastElement = null
      }

      // Process Spotify objects
      processed = SpotifyModel.processSpotifyObjects(
        result.items,
        {
          images: (
            this.detailElement &&
            this.detailElement.spotify
          )
            ? SpotifyModel.getSpotifyImages(this.detailElement.spotify)
            : undefined
        }
      )

      return processed

    } else {

      Logger.warn(className + ' - processElements - Invalid result', result)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
  }

  SpotifyCollection.prototype.concatItems = function (items) {
    this.elements = this.elements.concat(items)
  }

  SpotifyCollection.prototype.replaceItems = function (items) {
    this.elements = items
  }

  /**
   * @returns {Promise<boolean>}
   */
  SpotifyCollection.prototype.onReachedEnd = function () {

    var basSource, config

    config = {}

    basSource = LibraryState.getSpotifySource()

    // Check if fetch is needed
    if (
      !this.isFetching &&
      !this.hasReachedEnd &&
      basSource
    ) {
      if (
        BasUtil.isObject(this.lastPagedResult) &&
        BasUtil.isNEString(this.lastPagedResult.next)
      ) {
        // Set fetching state
        this.isFetching = true

        // Request next
        config.url = this.lastPagedResult.next
        return basSource.spotify.requestLib(config)
          .then(
            this.processElements.bind(this),
            this.onRetrieveError.bind(this)
          )
          .then(this.concatItems.bind(this))

      } else {

        return this.retrieve()
      }
    }

    return Promise.resolve(false)
  }

  SpotifyCollection.prototype.onRetrieveError = function (error) {

    if (BasSourceSpotify.checkLocalisationError(error)) {
      return this.retrieve()
    }

    Logger.warn(className + ' - Retrieve ERROR ', error)

    // Fetching is done
    this.isFetching = false

    return Promise.reject(error)
  }

  return SpotifyCollection
}
