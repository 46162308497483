'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSettingsStateHelper', [
    '$uiRouterGlobals',
    'SETTINGS_STATES',
    BasSettingsStateHelper
  ])

/**
 * @constructor
 * @param $uiRouterGlobals
 * @param {SETTINGS_STATES} SETTINGS_STATES
 */
function BasSettingsStateHelper (
  $uiRouterGlobals,
  SETTINGS_STATES
) {
  this.getPrefix = getPrefix
  this.getHelper = getHelper
  this.getSettingsState = getSettingsState
  this.isExact = isExact
  this.isCurrent = isCurrent

  /**
   * @param {string} [stateName] Default value is current state name
   * @returns {string}
   */
  function getPrefix (stateName) {

    var _stateName, idx, _statePrefix

    _stateName = BasUtil.isNEString(stateName)
      ? stateName
      : $uiRouterGlobals.current.name

    if (BasUtil.isNEString(_stateName)) {

      idx = _stateName.indexOf('.' + SETTINGS_STATES.SETTINGS)

      if (idx !== -1) {

        _statePrefix = _stateName.substring(0, idx)

        if (_statePrefix) return _statePrefix
      }
    }

    return ''
  }

  /**
   * @param {string} [statePrefix]
   * @returns {Object}
   */
  function getHelper (statePrefix) {

    var _statePrefix

    _statePrefix = BasUtil.isNEString(statePrefix)
      ? statePrefix
      : getPrefix()

    return {
      get: _getSettingsState.bind(null, _statePrefix),
      isExact: _isExact.bind(null, _statePrefix),
      isCurrent: isCurrent.bind(null, _statePrefix)
    }
  }

  /**
   * @private
   * @param {string} statePrefix
   * @param {string} settingsState
   * @returns {string}
   */
  function _getSettingsState (statePrefix, settingsState) {

    return statePrefix + '.' + settingsState
  }

  /**
   * @param {string} settingsState
   * @param {string} [statePrefix]
   * @returns {string}
   */
  function getSettingsState (settingsState, statePrefix) {

    var _settingsState, _statePrefix

    if (
      BasUtil.isNEString(settingsState) &&
      BasUtil.isNEString(statePrefix)
    ) {

      _settingsState = settingsState
      _statePrefix = statePrefix

    } else if (
      BasUtil.isNEString(settingsState) &&
      BasUtil.isUndefined(statePrefix)
    ) {

      _settingsState = settingsState
      _statePrefix = getPrefix()

    } else {

      return ''
    }

    return _getSettingsState(_statePrefix, _settingsState)
  }

  /**
   * @param {string} statePrefix
   * @param {string} settingsState
   * @returns {boolean}
   */
  function _isExact (statePrefix, settingsState) {

    var _currentStateName, _stateName

    _currentStateName = $uiRouterGlobals.current.name

    if (_currentStateName) {

      _stateName = getSettingsState(settingsState, statePrefix)

      return _currentStateName === _stateName
    }

    return false
  }

  /**
   * @param {string} settingsState
   * @returns {boolean}
   */
  function isExact (settingsState) {

    var _statePrefix

    _statePrefix = getPrefix()

    return _isExact(_statePrefix, settingsState)
  }

  /**
   * @param {string} statePrefix
   * @param {string} settingsState
   * @returns {boolean}
   */
  function isCurrent (statePrefix, settingsState) {

    var _currentStateName, _stateName

    _currentStateName = $uiRouterGlobals.current.name

    if (_currentStateName) {

      _stateName = getSettingsState(settingsState, statePrefix)

      if (_stateName) {

        return _currentStateName.indexOf(_stateName) === 0
      }
    }

    return false
  }
}
