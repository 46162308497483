'use strict'

angular
  .module('basalteApp')
  .factory('BasSchedulerEntry', [
    'BasSchedulerPoint',
    basSchedulerEntryFactory
  ])

/**
 * @param BasSchedulerPoint
 * @returns BasSchedulerEntry
 */
function basSchedulerEntryFactory (
  BasSchedulerPoint
) {
  /**
   * @constructor
   */
  function BasSchedulerEntry () {

    /**
     * @type {string}
     */
    this.title = ''

    /**
     * @type {number[]}
     */
    this.uiPoints = []

    /**
     * @type {BasSchedulerPoint[]}
     */
    this.points = []
  }

  /**
   * @param {BasSchedulerFilter} [filter]
   */
  BasSchedulerEntry.prototype.generateUiPoints = function (filter) {

    var length, i, point

    this.uiPoints = []

    length = this.points.length
    for (i = 0; i < length; i++) {

      if (filter) {

        point = this.points[i]

        if (filter.filter(point)) {

          this.uiPoints.push(i)
        }

      } else {

        this.uiPoints.push(i)
      }
    }
  }

  BasSchedulerEntry.prototype.addNewPoint = function () {

    this.uiPoints.push(this.points.push(new BasSchedulerPoint()) - 1)
  }

  /**
   * @param {BasSchedulerPoint} _point
   * @param {number} _x
   * @returns {boolean}
   */
  BasSchedulerEntry.prototype.isXAllowed = function (
    _point,
    _x
  ) {
    return true
  }

  /**
   * @param {BasSchedulerPoint} _point
   * @param {number} _y
   * @returns {boolean}
   */
  BasSchedulerEntry.prototype.isYAllowed = function (
    _point,
    _y
  ) {
    return true
  }

  /**
   * @param {number} x
   * @returns {boolean}
   */
  BasSchedulerEntry.prototype.hasPointWithX = function (x) {

    var length, i, point

    length = this.points.length
    for (i = 0; i < length; i++) {

      point = this.points[i]

      if (point && point.x === x) return true
    }

    return false
  }

  /**
   * @param {number} x
   * @returns {BasSchedulerPoint[]}
   */
  BasSchedulerEntry.prototype.getPointsWithX = function (x) {

    var result, length, i, point

    result = []

    length = this.points.length
    for (i = 0; i < length; i++) {

      point = this.points[i]

      if (point && point.x === x) result.push(point)
    }

    return result
  }

  /**
   * When setting points from an entry with a filter,
   * the order and UI points should generated again.
   *
   * @param {BasSchedulerEntry} entry
   * @param {BasSchedulerFilter} [filter]
   */
  BasSchedulerEntry.prototype.setPointsFromEntry = function (
    entry,
    filter
  ) {
    var length, i, point, newLength

    if (entry) {

      if (filter) {

        this.deletePoints(filter)

        length = entry.points.length
        for (i = 0; i < length; i++) {

          point = entry.points[i]

          if (point && filter.filter(point)) {

            this.points.push(point)
          }
        }

      } else {

        this.points = []
        this.uiPoints = []

        length = entry.points.length
        for (i = 0; i < length; i++) {

          point = entry.points[i]

          if (point) {

            newLength = this.points.push(point.clone())
            this.uiPoints.push(newLength - 1)
          }
        }
      }
    }
  }

  /**
   * @param {BasSchedulerPoint} point
   */
  BasSchedulerEntry.prototype.deletePoint = function (point) {

    var idx

    idx = this.points.indexOf(point)

    if (idx > -1) {

      this.points.splice(idx, 1)

      this.generateUiPoints()
    }
  }

  /**
   * If a filter is given, only delete the points that match the filter.
   *
   * @param {BasSchedulerFilter} filter
   */
  BasSchedulerEntry.prototype.deletePoints = function (filter) {

    var newPoints, newUiPoints, length, i, point, newLength

    if (filter) {

      newPoints = []
      newUiPoints = []

      length = this.points.length
      for (i = 0; i < length; i++) {

        point = this.points[i]

        if (!filter.filter(point)) {

          newLength = newPoints.push(point)

          if (this.uiPoints.indexOf(i) > -1) {

            newUiPoints.push(newLength - 1)
          }
        }
      }

      this.points = newPoints
      this.uiPoints = newUiPoints

    } else {

      this.points = []
      this.uiPoints = []
    }
  }

  BasSchedulerEntry.prototype.updateTranslation = function () {

    // Empty
  }

  /**
   * @returns {BasSchedulerEntry}
   */
  BasSchedulerEntry.prototype.clone = function () {
    const basSchedulerEntry = new BasSchedulerEntry()

    basSchedulerEntry.points = this.points.map(el => el.clone())
    basSchedulerEntry.title = this.title
    basSchedulerEntry.uiPoints = [...this.uiPoints]

    return basSchedulerEntry
  }

  return BasSchedulerEntry
}
