'use strict'

angular
  .module('basalteApp')
  .component('thermostatDetailTabs', {
    template: [
      'BAS_HTML',
      thermostatDetailTabsTemplate
    ],
    bindings: {
      thermostats: '<'
    },
    controller: [
      '$uiRouterGlobals',
      'CurrentRoom',
      'STATES',
      thermostatDetailTabsCtrl
    ],
    controllerAs: 'basThermostat'
  })

function thermostatDetailTabsTemplate (BAS_HTML) {

  return BAS_HTML.thermostatDetailTabs
}

/**
 * @param $uiRouterGlobals
 * @param CurrentRoom
 * @param STATES
 */

function thermostatDetailTabsCtrl (
  $uiRouterGlobals,
  CurrentRoom,
  STATES
) {
  var basThermostat = this

  basThermostat.uiThermostatCurrent = $uiRouterGlobals.params.thermostat
  basThermostat.selectThermostat = selectThermostat

  /**
   * @param {string} thermostatId
   */
  function selectThermostat (thermostatId) {
    CurrentRoom.go(
      STATES.THERMOSTAT,
      {
        room: $uiRouterGlobals.params.room,
        thermostat: thermostatId
      }
    )
  }
}
