'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var DspModule = require('./dsp')
var Device = require('./cobra_audio_device')

/**
 * A class that represents a device output.
 *
 * @constructor
 * @extends DspModule
 * @param {Object} cfg
 * @param {string} cfg.uuid
 * @param {string} cfg.name
 * @param {number} cfg.channelMode
 * @param {number} cfg.volume
 * @param {boolean} cfg.muted
 * @param {boolean} cfg.dspSupport
 * @param {BasCore} basCore
 * @param {Device} device
 * @since 1.7.0
 */
function DeviceOutput (cfg, basCore, device) {

  // Call "super" constructor
  DspModule.call(this, DspModule.INSTANCE_TYPE_DEVICE_OUTPUT, basCore)

  this._uuid = cfg[P.UUID]
  this._name = cfg[P.NAME]
  this._channelMode = cfg[P.CHANNELMODE]
  this._muted = BasUtil.isBool(cfg[P.MUTED])
    ? cfg[P.MUTED]
    : false
  this._volume = BasUtil.isNumber(cfg[P.VOLUME])
    ? cfg[P.VOLUME]
    : 0
  this._device = device
  this._dirtyDsp = true
  this._dspSupport = BasUtil.isBool(cfg[P.DSP_SUPPORT])
    ? cfg[P.DSP_SUPPORT]
    : BasUtil.isObject(this._device) && this._device.dspSupport
}

DeviceOutput.prototype = Object.create(DspModule.prototype)
DeviceOutput.prototype.constructor = DeviceOutput

/**
 * Fired when the volume for this deviceOutput has changed
 *
 * @event DeviceOutput#EVT_VOLUME
 * @param {number} volume
 */

/**
 * Fired when the mute status for this deviceOutput has changed
 *
 * @event DeviceOutput#EVT_MUTED
 * @param {boolean} mute
 */

/**
 * @constant {string}
 */
DeviceOutput.EVT_VOLUME = 'volume'

/**
 * @constant {string}
 */
DeviceOutput.EVT_MUTED = 'muted'

/**
 * @constant {number}
 */
DeviceOutput.CHANNEL_MODE_STEREO = 0

/**
 * @constant {number}
 */
DeviceOutput.CHANNEL_MODE_MONO_LEFT = 1

/**
 * @constant {number}
 */
DeviceOutput.CHANNEL_MODE_MONO_RIGHT = 2

/**
 * @constant {number}
 */
DeviceOutput.CHANNEL_MODE_MONO_SUM = 3

/**
 * @name DeviceOutput#uuid
 * @type {string}
 */
Object.defineProperty(DeviceOutput.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name DeviceOutput#name
 * @type {string}
 */
Object.defineProperty(DeviceOutput.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * There are 4 possible return values for channelMode:
 * * 0 = STEREO
 * * 1 = MONO_LEFT
 * * 2 = MONO_RIGHT
 * * 3 = MONO_SUM
 *
 * @name DeviceOutput#channelMode
 * @type {number}
 */
Object.defineProperty(DeviceOutput.prototype, 'channelMode', {
  get: function () {
    if (BasUtil.isNumber(this._channelMode)) {
      return this._channelMode
    }
    return -1
  }
})

/**
 * Whether the audio output has DSP support
 *
 * @name DeviceOutput#dspSupport
 * @type {boolean}
 */
Object.defineProperty(DeviceOutput.prototype, 'dspSupport', {
  get: function () {
    return this._dspSupport
  }
})

/**
 * Is the audio output a tweeter or woofer. (part of Asano speaker)
 *
 * @name DeviceOutput#isTweeterWoofer
 * @type {boolean}
 */
Object.defineProperty(DeviceOutput.prototype, 'isTweeterWoofer', {
  get: function () {
    return (
      BasUtil.isObject(this._device) &&
      this._device.type === Device.TYPE_ASANO_SPK
    )
  }
})

/**
 * @name DeviceOutput#muted
 * @type {boolean}
 */
Object.defineProperty(DeviceOutput.prototype, 'muted', {
  get: function () {
    return this._muted
  },
  set: function (muted) {
    this._muted = muted === true
    this.updateMute()
    this.emit(DeviceOutput.EVT_MUTED, this._muted)
  }
})

/**
 * @name DeviceOutput#volume
 * @type {number}
 */
Object.defineProperty(DeviceOutput.prototype, 'volume', {
  get: function () {
    return this._volume
  },
  set: function (volume) {
    if (BasUtil.isPNumber(volume, true)) {

      this._volume = volume
      this.updateVolume()
      this.emit(DeviceOutput.EVT_VOLUME, this._volume)
    }
  }
})

/**
 * Send an update for the current mute state
 *
 * @private
 */
DeviceOutput.prototype.updateMute = function () {

  var data, state

  data = {}
  data[P.AUDIO_OUTPUT] = {}
  data[P.AUDIO_OUTPUT][P.UUID] = this._uuid
  state = data[P.AUDIO_OUTPUT][P.STATE] = {}
  state[P.MUTED] = this._muted

  this._basCore.send(data)
}

/**
 * Send an update for the current volume state
 *
 * @private
 */
DeviceOutput.prototype.updateVolume = function () {

  var data, state

  data = {}
  data[P.AUDIO_OUTPUT] = {}
  data[P.AUDIO_OUTPUT][P.UUID] = this._uuid
  state = data[P.AUDIO_OUTPUT][P.STATE] = {}
  state[P.VOLUME] = this._volume

  this._basCore.send(data)
}

/**
 * LEGACY
 *
 * Parse an AudioOutput message
 *
 * @param {Object} obj
 */
DeviceOutput.prototype.parse = function (obj) {
  var value
  var changedMuted = false
  var changedVolume = false

  // Make sure the AudiOutput has been initialized
  if (!this._dirtyDsp &&
      BasUtil.isObject(obj) &&
      BasUtil.isObject(obj[P.AUDIO_OUTPUT]) &&
      BasUtil.isNEString(obj[P.AUDIO_OUTPUT][P.UUID]) &&
      obj[P.AUDIO_OUTPUT][P.UUID] === this._uuid) {

    if (obj[P.AUDIO_OUTPUT][P.STATE]) {
      if (BasUtil.isBool(obj[P.AUDIO_OUTPUT][P.STATE][P.MUTED])) {

        value = obj[P.AUDIO_OUTPUT][P.STATE][P.MUTED]

        if (value !== this._muted) {
          this._muted = value
          changedMuted = true
        }
      }

      if (BasUtil.isNumber(obj[P.AUDIO_OUTPUT][P.STATE][P.VOLUME])) {

        value = obj[P.AUDIO_OUTPUT][P.STATE][P.VOLUME]
        if (value !== this._volume) {
          this._volume = value
          changedVolume = true
        }
      }
    }

    if (changedMuted) {
      this.emit(DeviceOutput.EVT_MUTED, this._muted)
    }

    if (changedVolume) {
      this.emit(DeviceOutput.EVT_VOLUME, this.volume)
    }
    // Parse DSP object
    this.parseDsp(obj[P.AUDIO_OUTPUT][P.DSP])
  }
}

module.exports = DeviceOutput
