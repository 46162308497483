'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourceTuneIn', [
    '$http',
    'Querystringify',
    'BAS_API',
    'BAS_TUNE_IN',
    'CurrentBasCore',
    'BasUtilities',
    'Logger',
    basSourceTuneInFactory
  ])

/**
 * @param $http
 * @param Querystringify
 * @param BAS_API
 * @param {BAS_TUNE_IN} BAS_TUNE_IN
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns BasSourceTuneIn
 */
function basSourceTuneInFactory (
  $http,
  Querystringify,
  BAS_API,
  BAS_TUNE_IN,
  CurrentBasCore,
  BasUtilities,
  Logger
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @constructor
   */
  function BasSourceTuneIn () {

    this.language = BasUtilities.getTranslateKey()
  }

  /**
   * Browse custom stations. Will return a TuneInResult object.
   *
   * @param {Object} [params]
   * @param {boolean} [isCustomStationPath]
   * @returns {Promise<Object[]|string>}
   */
  BasSourceTuneIn.prototype.browseCustomStations = function (
    params,
    isCustomStationPath
  ) {
    var tunein, _params

    if (CurrentBasCore.hasCore()) {

      tunein = currentBasCoreState.core.core.tunein

      if (BasUtil.isObject(tunein)) {

        _params = !params ? {} : params

        _params.isCustomStationPath = !!isCustomStationPath

        return tunein.getCustomStations(_params.httpResponse)
          .then(this.parseCustomStations.bind(this, _params))
      }

      Promise.reject(
        'BasTuneIn - browseCustomStations - ' +
        'No valid TuneIn'
      )
    }

    Promise.reject('BasTuneIn - browseCustomStations - No valid core')
  }

  /**
   * Browse custom stations. Will return a TuneInResult object.
   *
   * @param {Object} params
   * @param {Object} data
   * @returns {Promise<Object[]|string>}
   */
  BasSourceTuneIn.prototype.parseCustomStations = function (params, data) {
    var resultArray
    var keys, i, length, country, station
    var tempTuneInObject
    var tempParams
    var countryKey = ''
    var countries
    var query

    // Check if custom stations are available
    if (data[BAS_TUNE_IN.KEY_HAS_CUSTOM_STATIONS] === true) {

      // Create TuneInResult
      resultArray = []

      if (BasUtil.isObject(params)) {

        // Used to add custom radios to search
        if (BasUtil.isNEString(params[BAS_TUNE_IN.PARAM_QUERY])) {

          query = params[BAS_TUNE_IN.PARAM_QUERY].toLowerCase()

          if (data &&
            data.searchArray &&
            BasUtil.isNEString(query)) {

            // Iterate stations for country
            keys = Object.keys(data.searchArray)
            length = keys.length
            for (i = 0; i < length; i += 1) {

              // Set station reference
              station = data.searchArray[keys[i]]

              if (station &&
                station.searchString &&
                station.searchString
                  .indexOf(query) > -1) {

                // Convert custom station to TuneIn element
                tempTuneInObject =
                  _convertCustomStation(station)

                if (BasUtil.isObject(tempTuneInObject)) {
                  resultArray.push(tempTuneInObject)
                }
              }
            }
          }
          // Used for custom station path
        } else if (params.isCustomStationPath) {

          // Check for valid countries object
          if (BasUtil.isObject(
            data[BAS_TUNE_IN.KEY_CUSTOM_STATIONS_COUNTRIES]
          )) {

            countries = data[BAS_TUNE_IN.KEY_CUSTOM_STATIONS_COUNTRIES]

            // Iterate all countries
            keys = Object.keys(countries)
            length = keys.length

            // Check params to determine response type
            if (BasUtil.isObject(params) &&
              BasUtil.safeHasOwnProperty(params, BAS_TUNE_IN.CS_KEY_COUNTRY)) {

              countryKey = params[BAS_TUNE_IN.CS_KEY_COUNTRY]

              // Check if country is stored in data
              if (keys.indexOf(countryKey) !== -1) {

                // Set country reference
                country = countries[countryKey]

                // Check country object
                if (BasUtil.isObject(country)) {

                  // Iterate stations for country
                  keys = Object.keys(country)
                  length = keys.length
                  for (i = 0; i < length; i += 1) {

                    // Set station reference
                    station = country[keys[i]]

                    // Convert custom station to TuneIn element
                    tempTuneInObject =
                      _convertCustomStation(station)

                    if (BasUtil.isObject(tempTuneInObject)) {
                      resultArray.push(tempTuneInObject)
                    }
                  }
                }
              } else {
                Logger.warn(
                  'TuneIn  browseCustomStations  Country is not available',
                  params
                )
              }
            } else {

              // Return list of countries

              for (i = 0; i < length; i += 1) {

                // Create TuneIn object for country
                tempTuneInObject = {}
                tempTuneInObject[BAS_TUNE_IN.EL_KEY_TEXT] = keys[i]
                tempTuneInObject[BAS_TUNE_IN.EL_KEY_KEY] = keys[i]
                tempTuneInObject[BAS_TUNE_IN.EL_ELEMENT_KEY] =
                  BAS_TUNE_IN.EL_VAL_OUTLINE
                tempTuneInObject[BAS_TUNE_IN.EL_KEY_TYPE] =
                  BAS_TUNE_IN.EL_TYPE_LINK

                // Create parameter for country
                tempParams = {}
                tempParams[BAS_TUNE_IN.CS_KEY_COUNTRY] = keys[i]

                // Create custom URL with parameter for country
                tempTuneInObject[BAS_TUNE_IN.EL_KEY_URL] =
                  BAS_TUNE_IN.CS_LINK +
                  Querystringify.stringify(tempParams, true)

                // Set path
                tempTuneInObject[BAS_TUNE_IN.EL_BAS_PATH] =
                  BAS_TUNE_IN.PATH_CUSTOM_STATIONS

                // Needs translation
                tempTuneInObject[BAS_TUNE_IN.EL_BAS_TRANSLATION] = true

                // Add TuneIn element to result
                resultArray.push(tempTuneInObject)
              }
            }
          }
        }
      }

      // Sort resulting list
      resultArray.sort(_compareTuneInObjectsAlphabetically)

      return Promise.resolve(resultArray)

    } else {
      Logger.warn('TuneIn  browseCustomStations No custom stations')
      return Promise.reject(BAS_TUNE_IN.ERROR_NO_CS)
    }

  }

  /**
   * @param {string} gid
   * @returns {Promise}
   */
  BasSourceTuneIn.prototype.stationInfo = function (gid) {

    var _this, tunein, params

    _this = this

    params = {
      id: gid
    }

    if (CurrentBasCore.hasCore()) {

      tunein = currentBasCoreState.core.core.tunein

      if (BasUtil.isObject(tunein)) {

        return tunein.stationInfo(gid).catch(_onError)
      }
    }

    return this.request(
      BAS_TUNE_IN.PATH_DESCRIBE,
      params
    )

    function _onError (error) {

      // Check for known error, custom station was not found
      if (error === BAS_API.TuneIn.ERR_NO_STATION) {

        return _this.request(
          BAS_TUNE_IN.PATH_DESCRIBE,
          params
        )
      }

      return Promise.reject(error)
    }
  }

  /**
   * TuneIn API request
   *
   * @param {string} path
   * @param {Object} [parameters]
   * @param {number} [partnerId]
   * @param {number} [serial]
   * @param {string} [language]
   * @returns {Promise<(Object|string)>}
   */
  BasSourceTuneIn.prototype.request = function (
    path,
    parameters,
    partnerId,
    serial,
    language
  ) {
    var _this, data

    _this = this

    // Create URL parameters
    data = {}

    // Add parameters
    BasUtil.mergeObjects(data, parameters)

    // Check partnerId
    if (BasUtil.isPNumber(partnerId)) {
      data.partnerId = partnerId
    }

    // Check serial
    if (BasUtil.isPNumber(serial, true)) {
      data.serial = serial
    }

    // Check language
    if (BasUtil.isNEString(language)) {
      data.locale = language
    }

    // Depending on path, process result differently
    switch (path) {
      case BAS_TUNE_IN.PATH_BROWSE:

        return _this._request(path, data)
          .then(_onBrowseResult.bind(null, data))

      case BAS_TUNE_IN.PATH_DESCRIBE:

        // Check id is given
        if (BasUtil.isObject(parameters) &&
          BasUtil.isNEString(parameters[BAS_TUNE_IN.PARAM_ID])) {

          // Perform the actual request
          return _this._request(path, data)
            .then(_onDescribeResult)

        } else {
          Logger.warn(
            'TuneIn - request - Describe request needs ' +
            parameters[BAS_TUNE_IN.PARAM_ID],
            parameters
          )
          return Promise.reject(BAS_TUNE_IN.ERROR_INPUT)
        }

      case BAS_TUNE_IN.PATH_SEARCH:

        // Check query is given
        if (BasUtil.isObject(parameters) &&
          BasUtil.isNEString(parameters[BAS_TUNE_IN.PARAM_QUERY])) {

          // Perform the actual request
          return _this._request(path, data)
            .then(_onBrowseResult.bind(null, data))

        } else {
          Logger.warn(
            'TuneIn - request - Search request needs ' +
            BAS_TUNE_IN.PARAM_QUERY,
            parameters
          )
          return Promise.reject(BAS_TUNE_IN.ERROR_INPUT)
        }

      default:
        Logger.warn('TuneIn - request - Unsupported path', path)
        return Promise.reject(BAS_TUNE_IN.ERROR_INPUT)
    }
  }

  /**
   * @param {string} path
   * @param {Object<string, (string | number | boolean)>} [params]
   * @returns {Promise}
   */
  BasSourceTuneIn.prototype._request = function (path, params) {

    var url, _params, tunein

    if (BasUtil.isString(path)) {

      _params = {
        render: 'json',
        formats: 'mp3,aac,wma,ogg,hls'
      }

      if (BasUtil.isNEString(this.language)) {

        _params['locale'] = this.language
      }

      if (CurrentBasCore.hasCore()) {

        tunein = currentBasCoreState.core.core.tunein

        if (BasUtil.isObject(tunein)) {

          _params['partnerId'] = tunein.partnerId
          _params['serial'] = tunein.serial
        }
      }

      url = BAS_TUNE_IN.BASE_URL

      if (path.length > 0) url += '/' + path

      if (BasUtil.isObject(params)) {

        BasUtil.mergeObjects(_params, params)
      }

      return $http.jsonp(
        url,
        {
          params: _params,
          jsonpCallbackParam: 'callback'
        }
      )
    }

    return Promise.reject('Invalid path')
  }

  BasSourceTuneIn.prototype.updateTranslation = function () {

    this.language = BasUtilities.getTranslateKey()
  }

  BasSourceTuneIn.prototype.destroy = function () {

    // Empty
  }

  /**
   * Legacy result processing function.
   * Returns TuneIn object without modifications
   *
   * @param {?Object} result
   * @returns {Promise<(Object|string)>}
   */
  function _onDescribeResult (result) {
    var _result, checkResult

    // $http result is wrapped
    _result = result.data

    // Check result
    checkResult = _checkResult(_result)

    // Check for result errors
    if (checkResult.ok === true) {

      // Check there is only on element
      if (_result.body.length === 1) {

        // Check body element
        checkResult = _checkStationElement(_result.body[0])

        // Check result errors
        if (checkResult.ok === true) {

          // Resolve with station object
          return Promise.resolve(_result.body[0])

        } else {
          Logger.warn(
            'TuneIn - onDescribeResult - Invalid station object',
            _result.body[0]
          )
          return Promise.reject(BAS_TUNE_IN.ERROR_TUNEIN_RESULT)
        }
      } else {
        Logger.warn(
          'TuneIn - onDescribeResult - Invalid number of body elements',
          _result.body,
          _result.body.length
        )
        return Promise.reject(BAS_TUNE_IN.ERROR_TUNEIN_RESULT)
      }
    } else {
      return Promise.reject(checkResult.text)
    }
  }

  /**
   * Process TuneIn results
   *
   * @param {Object} parameters
   * @param {?Object} result
   * @returns {Promise<Object[]|string>}
   */
  function _onBrowseResult (parameters, result) {
    var _result, processedElement
    var checkResult, i, length
    var resultArray
    var paramLength = Object.keys(parameters).length

    // $http result is wrapped
    _result = result.data

    // Check result
    checkResult = _checkResult(_result)

    // Check for result errors
    if (checkResult.ok === true) {

      Logger.debug('TuneIn - onBrowseResult', parameters, _result)

      // Create Array
      resultArray = []

      // Iterate over body objects
      length = _result.body.length
      for (i = 0; i < length; i += 1) {

        // Process TuneIn element
        processedElement = _processTuneInElement(_result.body[i])

        // Check processed element
        if (Array.isArray(processedElement)) {

          // Concatenate array
          resultArray = resultArray.concat(processedElement)

        } else if (BasUtil.isObject(processedElement)) {

          // Add TuneIn element
          resultArray.push(processedElement)
        }
      }

      // Check if most simple request is made,
      // which is the main menu content
      if (paramLength === 1) {
        resultArray.push(_makeCustomStationObj())
      }

      // Resolve with TuneIn result
      return Promise.resolve(resultArray)

    } else {
      return Promise.reject(checkResult.text)
    }
  }

  /**
   * @returns {Object}
   * @private
   */
  function _makeCustomStationObj () {
    var customStation = {}
    customStation.basPath = BAS_TUNE_IN.PATH_CUSTOM_STATIONS
    customStation.type = BAS_TUNE_IN.EL_TYPE_LINK
    customStation.text = BasUtilities.translate('radio_stations_other')
    return customStation
  }

  /**
   * Checks for valid header and body elements
   *
   * @param {?Object} result
   * @returns {Object}
   */
  function _checkResult (result) {
    var output, headCheck

    // Create output object
    output = {
      ok: false,
      text: ''
    }

    // Check result
    if (BasUtil.isObject(result)) {

      // Check head
      headCheck = _checkHeader(result.head)

      // Check head result
      if (headCheck.ok === true) {

        // Set head title
        output.text = headCheck.text
      } else {
        return headCheck
      }

      // Check body
      if (Array.isArray(result.body)) {

        // Result seems fine
        output.ok = true
      } else {
        Logger.warn('TuneIn - checkResult - Invalid body', result)

        output.ok = false
        output.text = BAS_TUNE_IN.ERROR_TUNEIN_RESULT
      }
    } else {
      Logger.warn('TuneIn - checkResult - Invalid result object', result)

      output.ok = false
      output.text = BAS_TUNE_IN.ERROR_TUNEIN_RESULT
    }

    return output
  }

  /**
   * Checks if element is a valid station object
   *
   * @param element
   * @returns {Object}
   */
  function _checkStationElement (element) {
    var result = {
      ok: false,
      text: ''
    }

    // Check element
    if (BasUtil.isObject(element) &&
      element[BAS_TUNE_IN.EL_ELEMENT_KEY] ===
      BAS_TUNE_IN.EL_VAL_STATION &&
      BasUtil.isNEString(element[BAS_TUNE_IN.EL_KEY_GUIDE_ID])) {

      // Set result object
      result.ok = true
      result.text = element[BAS_TUNE_IN.EL_KEY_GUIDE_ID]
    }

    return result
  }

  /**
   * @param {Object} head
   * @returns {Object}
   */
  function _checkHeader (head) {
    var output, statusOk

    // Create output object
    output = {
      ok: false,
      text: ''
    }

    // Check head
    if (BasUtil.isObject(head)) {

      // Check status type
      switch (typeof head.status) {
        case 'number':
          statusOk = 200
          break
        case 'string':
          statusOk = '200'
          break
        default:
          Logger.warn(
            'TuneIn - onBrowseResult - Status is unknown',
            head.status
          )

          output.ok = false
          output.text = BAS_TUNE_IN.ERROR_TUNEIN_RESULT_STATUS +
            head.status

          return output
      }

      // Check status code
      if (head.status !== statusOk) {
        Logger.warn(
          'TuneIn - onBrowseResult - Status is not ok',
          head.status,
          head
        )

        output.ok = false
        output.text = BAS_TUNE_IN.ERROR_TUNEIN_RESULT_STATUS +
          head.status

        return output
      }

      output.ok = true

      // Check title
      if (BasUtil.isNEString(head.title)) {

        // Set head title
        output.text = head.title
      }
    } else {

      Logger.warn('TuneIn - onBrowseResult - Header is not ok', head)
      output.text = BAS_TUNE_IN.ERROR_TUNEIN_RESULT_STATUS + head
    }

    return output
  }

  /**
   * Process TuneIn element object
   *
   * @private
   * @param {Object} element
   * @returns {?Object|Object[]}
   */
  function _processTuneInElement (element) {

    Logger.debug('TuneIn - processTuneInElement', element)

    // Check element
    if (BasUtil.isObject(element) &&
      element[BAS_TUNE_IN.EL_ELEMENT_KEY] ===
      BAS_TUNE_IN.EL_VAL_OUTLINE) {

      // Process element according to type
      switch (element[BAS_TUNE_IN.EL_KEY_TYPE]) {
        case BAS_TUNE_IN.EL_TYPE_AUDIO:

          // Check for station, no Podcasts, Shows and episodes
          if (element[BAS_TUNE_IN.EL_KEY_ITEM] ===
            BAS_TUNE_IN.EL_VAL_STATION) {
            return element
          }

          break
        case BAS_TUNE_IN.EL_TYPE_LINK:

          if (!BasUtil.safeHasOwnProperty(element, BAS_TUNE_IN.EL_KEY_ITEM)) {

            // Set path
            element[BAS_TUNE_IN.EL_BAS_PATH] =
              BAS_TUNE_IN.PATH_BROWSE

            // Check key
            if (BasUtil.safeHasOwnProperty(element, BAS_TUNE_IN.EL_KEY_KEY)) {

              // Check for supported keys
              switch (element[BAS_TUNE_IN.EL_KEY_KEY]) {
                case BAS_TUNE_IN.EL_KEY_LOCAL:
                case BAS_TUNE_IN.EL_KEY_MUSIC:
                case BAS_TUNE_IN.EL_KEY_LOCATION:
                case BAS_TUNE_IN.EL_KEY_LANGUAGE:
                case BAS_TUNE_IN.EL_KEY_POPULAR:
                case BAS_TUNE_IN.EL_KEY_PIVOT_GENRE:
                case BAS_TUNE_IN.EL_KEY_PIVOT_NAME:
                case BAS_TUNE_IN.EL_KEY_NEXT_STATIONS:

                  // Make sure element does not have children
                  if (
                    !BasUtil.safeHasOwnProperty(
                      element,
                      BAS_TUNE_IN.EL_CHILDREN_KEY
                    )
                  ) {
                    return element
                  }

                  break
              }
            } else {

              // Make sure element does not have children
              if (
                !BasUtil.safeHasOwnProperty(
                  element,
                  BAS_TUNE_IN.EL_CHILDREN_KEY
                )
              ) {
                return element
              }
            }
          }

          break

        default:

          // Check key
          switch (element[BAS_TUNE_IN.EL_KEY_KEY]) {
            case BAS_TUNE_IN.EL_KEY_LOCAL:
            case BAS_TUNE_IN.EL_KEY_STATIONS:
            case BAS_TUNE_IN.EL_KEY_PIVOT:

              // Check for children
              if (
                BasUtil.safeHasOwnProperty(
                  element,
                  BAS_TUNE_IN.EL_CHILDREN_KEY
                )
              ) {

                // Process children
                return _processTuneInElementChildren(
                  element
                )
              }
          }
      }
    }

    // Not a supported element
    return null
  }

  /**
   * Process a TuneIn element with children
   *
   * @param {Object} element
   * @returns {?(Object[])}
   */
  function _processTuneInElementChildren (element) {
    var result, processedElement, i, length

    Logger.debug('TuneIn - processTuneInElementChildren', element)

    // Check element
    if (BasUtil.isObject(element) &&
      Array.isArray(element[BAS_TUNE_IN.EL_CHILDREN_KEY])) {

      // Create array to hold elements
      result = []

      // Iterate children
      length = element[BAS_TUNE_IN.EL_CHILDREN_KEY].length
      for (i = 0; i < length; i += 1) {

        // Process element
        processedElement = _processTuneInElement(
          element[BAS_TUNE_IN.EL_CHILDREN_KEY][i]
        )

        // Check element
        if (Array.isArray(processedElement)) {

          // Concatenate the array
          result = result.concat(processedElement)

        } else if (BasUtil.isObject(processedElement)) {

          // Add element to collection
          result.push(processedElement)

        }
      }

      // Return the array
      return result
    }

    return null
  }

  /**
   * Convert a custom station object to a more regular TuneIn element
   * This returns a modified describe object
   *
   * @private
   * @param {Object} object
   * @returns {?Object}
   */
  function _convertCustomStation (object) {
    var result

    if (_isCustomStation(object)) {

      // Set describe object
      result = object[BAS_TUNE_IN.CS_KEY_DESCRIBE]

      // Fill in expected TuneIn keys
      result[BAS_TUNE_IN.EL_KEY_TYPE] = BAS_TUNE_IN.EL_TYPE_AUDIO
      result[BAS_TUNE_IN.EL_KEY_IMAGE] = result[BAS_TUNE_IN.CS_KEY_LOGO]
      result[BAS_TUNE_IN.EL_KEY_TEXT] = result[BAS_TUNE_IN.CS_KEY_NAME]

      return result
    } else {
      return null
    }
  }

  /**
   * Checks whether the object is a valid custom station object
   *
   * @private
   * @param {?Object} object
   * @returns {boolean}
   */
  function _isCustomStation (object) {
    return (
      BasUtil.isObject(object) &&
      BasUtil.isObject(object[BAS_TUNE_IN.CS_KEY_DESCRIBE])
    )
  }

  /**
   * Compare function for Array of TuneIn objects.
   *
   * @private
   * @param {Object} a
   * @param {Object} b
   * @returns {number}
   */
  function _compareTuneInObjectsAlphabetically (a, b) {

    if (BasUtil.isObject(a) &&
      BasUtil.isObject(b) &&
      typeof a.text === 'string' &&
      typeof b.text === 'string') {
      return a.text.localeCompare(b.text)
    } else {
      return 0
    }
  }

  return BasSourceTuneIn
}
