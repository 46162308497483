'use strict'

angular.module('basComponents', [])
  .directive('basImg', basImg)
  .directive('basSubmit', basSubmit)
  .directive('basToggle', basToggle)
  .directive('basSpinner', basSpinner)
  .directive('basSpinnerSwoop', basSpinnerSwoop)
  .directive('basVisible', basVisible)
  .directive('basShow', basShow)
  .directive('basLivePassword', [
    'BAS_UTILITIES',
    basLivePassword
  ])
  .directive('basEqual', basEqual)

function basImg () {

  return {
    restrict: 'A',
    link: link
  }

  function link (_scope, element, attrs) {

    // Observer attribute 'basImg'
    attrs.$observe('basImg', onAttrObserve)

    // Attribute has been observed
    function onAttrObserve (value) {
      element.css({ 'background-image': 'url(' + value + ')' })
    }
  }
}

function basSubmit () {

  return {
    restrict: 'A',
    link: link
  }

  function link (_scope, element) {

    // Check if element is valid
    if (element && element[0]) {

      element[0].onsubmit = onSubmit
    }

    function onSubmit (event) {
      var inputs, i, length

      // Prevent default action to avoid platform differences
      event.preventDefault()

      // Get child input elements
      inputs = element[0].getElementsByTagName('input')

      // Check if child inputs were found
      if (inputs && inputs.length) {

        // Iterate all child inputs
        length = inputs.length
        for (i = 0; i < length; i++) {

          // Remove focus
          inputs[i].blur()
        }
      }
    }
  }
}

function basToggle () {

  return {
    restrict: 'E',
    scope: {
      basToggled: '=',
      basDisabled: '=',
      basToggle: '&?'
    },
    template:
      '<div class="bas-toggle" ' +
      'ng-class="{ \'active\': basToggled, \'disabled\': basDisabled }" ' +
      'bas-click="basToggle()"' +
      'data-stop-propagation="true">' +
      '<div class="toggle-background"></div>' +
      '<div class="toggle-handle"></div>' +
      '</div>'
  }
}

function basSpinner () {

  return {
    restrict: 'AE',
    template:
      '<div class="bas-spinner-blade bas-spinner-blade-1"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-2"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-3"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-4"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-5"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-6"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-7"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-8"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-9"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-10"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-11"></div>' +
      '<div class="bas-spinner-blade bas-spinner-blade-12"></div>'
  }
}

function basSpinnerSwoop () {

  return {
    restrict: 'AE',
    template:
      '<div class="bas-spinner-swoop">' +
      '<div class="bas-spinner-swoop-circle">' +
      '<div class="bas-spinner-swoop-circle-inner"></div>' +
      '</div>' +
      '<div class="bas-spinner-swoop-circle bas-spinner-swoop-circle-2">' +
      '<div class="bas-spinner-swoop-circle-inner"></div>' +
      '</div>' +
      '</div>'
  }
}

function basVisible () {

  var KEY_BAS_VISIBLE = 'basVisible'
  var BAS_VISIBLE_CLASS = 'bas-visible'
  var BAS_INVISIBLE_CLASS = 'bas-invisible'

  return {
    restrict: 'A',
    link: link
  }

  function link (scope, element, attributes) {

    // Watch for changes on the visibility statement
    scope.$watch(attributes[KEY_BAS_VISIBLE], basVisibleWatchAction)

    // Process value
    function basVisibleWatchAction (value) {

      // Evaluate expression
      if (value) {

        // Add the visible class
        element.addClass(BAS_VISIBLE_CLASS)
        element.removeClass(BAS_INVISIBLE_CLASS)
      } else {

        // Add the invisible class
        element.addClass(BAS_INVISIBLE_CLASS)
        element.removeClass(BAS_VISIBLE_CLASS)
      }
    }
  }
}

function basShow () {

  var KEY_BAS_SHOW = 'basShow'
  var BAS_SHOW_CLASS = 'bas-show'
  var BAS_HIDE_CLASS = 'bas-hide'

  return {
    restrict: 'A',
    link: link
  }

  function link (scope, element, attributes) {

    // Watch for changes on the show statement
    scope.$watch(attributes[KEY_BAS_SHOW], basShowWatchAction)

    // Process value
    function basShowWatchAction (value) {

      // Evaluate expression
      if (value) {

        // Add the show class
        element.addClass(BAS_SHOW_CLASS)
        element.removeClass(BAS_HIDE_CLASS)
      } else {

        // Add the hide class
        element.addClass(BAS_HIDE_CLASS)
        element.removeClass(BAS_SHOW_CLASS)
      }
    }
  }
}

/**
 * @param {BAS_UTILITIES} BAS_UTILITIES
 * @returns directive
 */
function basLivePassword (
  BAS_UTILITIES
) {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: link
  }

  function link (_scope, _element, _attributes, ngModel) {

    // Check for ngModel
    if (!ngModel) return

    ngModel.$validators.basLivePassword = _basLivePasswordValidator

    function _basLivePasswordValidator (modelValue, viewValue) {

      var value, length

      value = modelValue || viewValue || ''

      length = value.length

      // Minimum length of 8
      if (length < 8) return false

      // At least 1 lower case letter
      if (!BAS_UTILITIES.REGEX_LOWER_CASE.test(value)) return false

      // At least 1 upper case letter
      if (!BAS_UTILITIES.REGEX_UPPER_CASE.test(value)) return false

      // At least 1 number
      if (!BAS_UTILITIES.REGEX_NUMBER.test(value)) return false

      return true
    }
  }
}

function basEqual () {

  return {
    restrict: 'A',
    require: '?ngModel',
    link: link
  }

  function link (scope, _element, attributes, ngModel) {

    var otherValue

    // Check for ngModel
    if (!ngModel) return

    otherValue = undefined

    ngModel.$validators.basEqual = _basEqualValidator

    scope.$watch(attributes.basEqual, _onBasEqualWatch)

    function _basEqualValidator (modelValue, viewValue) {

      var value

      value = modelValue || viewValue || ''

      return value
        ? value === otherValue
        : false
    }

    function _onBasEqualWatch (value) {

      otherValue = value

      ngModel.$validate()
    }
  }
}
