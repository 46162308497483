'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basMainScheduler', [
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'BAS_HTML',
    'BAS_MODAL',
    'BasModal',
    'CurrentRoom',
    basMainSchedulerDirective
  ])

/**
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasModal} BasModal
 * @param {CurrentRoom} CurrentRoom
 * @returns basMainScheduler
 */
function basMainSchedulerDirective (
  $uiRouterGlobals,
  BAS_ROOMS,
  BAS_HTML,
  BAS_MODAL,
  BasModal,
  CurrentRoom
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.mainBasScheduler,
    controller: controller,
    controllerAs: 'mainScheduler',
    bindToController: {
      title: '@?',
      uiFilters: '<?',
      filters: '<?',
      activeFilterId: '@?',
      lines: '<?',
      xMin: '<?',
      xMax: '<?',
      mainToggleState: '<?',
      mainToggleDisabled: '<?',
      uiEntries: '<',
      entries: '<',
      mainToggle: '&?',
      selectFilter: '&?',
      selectEntry: '&?',
      copyEntry: '&?'
    }
  }

  function controller () {

    const mainScheduler = this

    /**
     * Original selected entry
     *
     * @type {string}
     */
    mainScheduler.selectedEntryId = ''

    /**
     * Selected entries to copy into
     *
     * @type {Object<string, boolean>}
     */
    mainScheduler.selectedEntryIds = {}

    /**
     * Whether there are selected copy destinations (apart from the source)
     *
     * @type {boolean}
     */
    mainScheduler.hasSelectedEntryIds = false

    mainScheduler.handleSelectorClick = handleSelectorClick
    mainScheduler.clear = clear

    /**
     * @param {string} entryId
     */
    function handleSelectorClick (entryId) {

      if (BasUtil.isNEString(entryId)) {

        if (mainScheduler.selectedEntryId) {

          if (entryId === mainScheduler.selectedEntryId) {

            _resetEntrySelection()

          } else {

            // Toggle entry as copy destinations

            mainScheduler.selectedEntryIds[entryId] =
              !mainScheduler.selectedEntryIds[entryId]

            if (BasUtil.isFunction(mainScheduler.copyEntry)) {

              if (!mainScheduler.entries[entryId]?.points.length) {

                mainScheduler.copyEntry(
                  {
                    entryId: mainScheduler.selectedEntryId,
                    entryIds: [entryId]
                  }
                )
              } else {

                BasModal.show(BAS_MODAL.T_OVERWRITE).then(onModal)
              }
            }
          }

          mainScheduler.hasSelectedEntryIds = _hasSelectedEntryIds()

        } else {

          // Start copy selection with entryId as the source

          mainScheduler.selectedEntryId = entryId
          mainScheduler.selectedEntryIds = {}
          mainScheduler.hasSelectedEntryIds = false
        }
      }

      function onModal (modal) {

        modal.close.then(onModalClose)
      }

      function onModalClose (result) {

        if (result === BAS_MODAL.C_YES) {

          mainScheduler.copyEntry(
            {
              entryId: mainScheduler.selectedEntryId,
              entryIds: [entryId]
            }
          )
        }
      }
    }

    function _resetEntrySelection () {

      mainScheduler.selectedEntryId = ''
      mainScheduler.selectedEntryIds = {}
      mainScheduler.hasSelectedEntryIds = false
    }

    function clear () {

      const basSchedule = _getBasScheduler()

      if (basSchedule) BasModal.show(BAS_MODAL.T_CLEAR_SCHEDULES).then(onModal)

      mainScheduler.selectedEntryId = ''

      function onModal (modal) {

        modal.close.then(onModalClose)
      }

      function onModalClose (result) {

        if (result === BAS_MODAL.C_YES) {

          for (const value of Object.values(mainScheduler.entries)) {

            if (value) {

              value.points = []
              value.uiPoints = []
            }
          }

          basSchedule.commit()
          basSchedule.updateSchedule()
        }
      }
    }

    /**
     * @private
     * @returns {boolean}
     */
    function _hasSelectedEntryIds () {

      return !!Object.values(mainScheduler.selectedEntryIds)
        .find(entry => !!entry)
    }

    /**
     * @private
     * @returns {?BasThermostatScheduler}
     */
    function _getBasScheduler () {
      const currentRoom = CurrentRoom.get()
      return BAS_ROOMS.ROOMS.rooms[currentRoom.roomId]?.thermostats
        .getThermostat($uiRouterGlobals.params.thermostat)?.thermostatScheduler
    }
  }
}
