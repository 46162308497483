import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasVolume', [
    '$rootScope',
    'BAS_VOLUME',
    'BasAppDevice',
    'BasSound',
    'BasPreferences',
    'BasDeviceVolume',
    BasVolume
  ])

/**
 * @typedef {Object} TBasVolumeOptions
 * @property {boolean} [audioFeedback]
 * @property {boolean} [notificationVolume]
 */

/**
 * On Android the following 'special' (non-runtime) permission is needed for
 * changing notification volume: ACCESS_NOTIFICATION_POLICY
 *
 * To avoid this the 'alarms' volume is use in this app.
 * This way the volume can be separated in multiple types like:
 * 'notification' and 'normal' volume,
 * but no additional permissions are needed.
 *
 * browserMusicVolume is used for non notification purposes e.g. videoview
 *
 * browserNotificationVolume is used for limiting notification
 * played from browser, because the notification volume
 * of the device does not work in browser
 *
 * deviceMusicVolume is used to keep track of the device volume
 *
 * usingDeviceVolume indicates if limiting happens by device volume or
 * limiting happens in browser context
 *
 * muted keep track if the user explicitly enabled 'do not disturb' mode
 *
 * uiMuted is true if muted is true or if uiVolume is zero.
 * This is used to show the 'do not disturb' mode
 *
 * @typedef {Object} TBasVolumeState
 * @property {number} uiVolume
 * @property {boolean} uiMuted
 * @property {number} browserMusicVolume
 * @property {number} browserNotificationVolume
 * @property {number} deviceMusicVolume
 * @property {boolean} usingDeviceVolume
 * @property {boolean} muted
 * @property {Object<string, boolean>} css
 */

/**
 * Service which handles volume related functions
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_VOLUME} BAS_VOLUME
 * @param {BasAppDevice} BasAppDevice
 * @param {BasSound} BasSound
 * @param {BasPreferences} BasPreferences
 * @param {BasDeviceVolume} BasDeviceVolume
 */
function BasVolume (
  $rootScope,
  BAS_VOLUME,
  BasAppDevice,
  BasSound,
  BasPreferences,
  BasDeviceVolume
) {
  var CSS_DO_NOT_DISTURB = 'bas-volume--do-not-disturb'

  var _notificationTimeoutId = 0

  /**
   * @type {TBasVolumeState}
   */
  var state = {}
  state.uiVolume = BAS_VOLUME.DEFAULT_VOLUME
  state.uiMuted = false

  state.muted = false
  state.browserMusicVolume = BAS_VOLUME.DEFAULT_VOLUME
  state.browserNotificationVolume = BAS_VOLUME.DEFAULT_VOLUME
  state.deviceMusicVolume = BAS_VOLUME.DEFAULT_VOLUME

  state.usingDeviceVolume = false

  state.css = {}
  _resetCss()

  this.get = get
  this.setVolume = _setAndSaveVolume
  this.toggleMute = _setAndSaveToggleMute

  init()

  function init () {

    state.usingDeviceVolume = BasAppDevice.isCoreClient()

    state.muted = BasPreferences.getMute()
    _setVolume(BasPreferences.getVolume())
    _syncUI()
    _toggleMute(state.muted)
  }

  /**
   * @returns {TBasVolumeState}
   */
  function get () {

    return state
  }

  function _syncUI () {

    var _preference = BasPreferences.getVolume()

    state.uiVolume =
      state.muted && BasUtil.isPNumber(_preference, true)
        ? _preference
        : state.usingDeviceVolume
          ? state.deviceMusicVolume
          : state.browserMusicVolume
    state.uiMuted = state.muted || state.uiVolume === 0

    state.css[CSS_DO_NOT_DISTURB] = state.uiMuted
  }

  /**
   * @param {boolean} [force]
   */
  function _setAndSaveToggleMute (force) {

    _toggleMute(force)
    _saveMute()
  }

  /**
   * @param {boolean} [force]
   */
  function _toggleMute (force) {

    var _mute, _volume

    _mute = BasUtil.isBool(force)
      ? force
      : !state.uiMuted

    state.muted = _mute

    if (_mute) {

      _setVolume(0, { notificationVolume: true })

    } else {

      _volume = state.uiVolume !== 0
        ? state.uiVolume
        : BAS_VOLUME.DEFAULT_VOLUME

      _setVolume(_volume)
    }
  }

  function _saveMute () {

    BasPreferences.setMute(state.muted)
  }

  /**
   * @param {number} volume 0 - 100
   * @param {TBasVolumeOptions} [options]
   */
  function _setAndSaveVolume (volume, options) {

    _setVolume(volume, options)
    _saveVolume(volume)
  }

  /**
   * @param {number} volume 0 - 100
   * @param {TBasVolumeOptions} [options]
   */
  function _setVolume (volume, options) {

    var _audioFeedback, _notificationVolume

    if (!BasUtil.isPNumber(volume, true) || volume > 100) return

    _audioFeedback = false
    _notificationVolume = false

    if (BasUtil.isObject(options)) {

      if (BasUtil.isBool(options.audioFeedback)) {
        _audioFeedback = options.audioFeedback
      }

      if (BasUtil.isBool(options.notificationVolume)) {
        _notificationVolume = options.notificationVolume
      }
    }

    // Set device volume if needed for native plugins (SIP)
    if (state.usingDeviceVolume) {

      // Browser volume can be default because deviceVolume will limit volume
      state.browserMusicVolume = BAS_VOLUME.DEFAULT_VOLUME

      if (_notificationVolume) {

        BasDeviceVolume.setVolume(
          volume,
          BasDeviceVolume.T_ALARM,
          _onVolume
        )
      } else {

        state.deviceMusicVolume = volume

        if (state.muted) {

          BasDeviceVolume.setVolume(
            volume,
            BasDeviceVolume.T_ALL_BUT_NOTIFICATION_AND_ALARM,
            _onVolume
          )

        } else {

          BasDeviceVolume.setVolume(
            volume,
            BasDeviceVolume.T_ALL_BUT_NOTIFICATION,
            _onVolume
          )
        }
      }

    } else {

      // Browser volume is used because deviceVolume will NOT limit volume
      state.browserMusicVolume = volume

      _onDone()
    }

    function _onVolume (_error, result) {

      if (BasUtil.isPNumber(result, true) && !_notificationVolume) {

        state.deviceMusicVolume = result
      }

      _onDone()
    }

    function _onDone () {

      state.browserNotificationVolume =
        state.muted
          ? 0
          : state.browserMusicVolume

      if (_audioFeedback) {

        _clearVolumeNotificationTimeout()

        _notificationTimeoutId = setTimeout(
          _playVolumeNotification,
          200
        )
      }

      _syncUI()

      $rootScope.$emit(BAS_VOLUME.EVT_VOLUME_CHANGED)
    }
  }

  function _saveVolume (volume) {

    BasPreferences.setVolume(volume)
  }

  function _playVolumeNotification () {

    _clearVolumeNotificationTimeout()

    BasSound.playVolumeNotification(state.browserMusicVolume)
  }

  function _clearVolumeNotificationTimeout () {
    clearTimeout(_notificationTimeoutId)
    _notificationTimeoutId = 0
  }

  function _resetCss () {

    state.css[CSS_DO_NOT_DISTURB] = false
  }
}
