'use strict'

angular.module('basSlip', [])
  .directive('basSlip', [
    '$window',
    '$parse',
    basSlip
  ])

function basSlip (
  $window,
  $parse
) {
  // Defines the slip events that can be handled.
  var eventTypes = [
    {
      eventName: 'slipBeforeReorder',
      slipEventName: 'slip:beforereorder'
    },
    {
      eventName: 'slipBeforeSwipe',
      slipEventName: 'slip:beforeswipe'
    },
    {
      eventName: 'slipBeforeWait',
      slipEventName: 'slip:beforewait'
    },
    {
      eventName: 'slipAfterSwipe',
      slipEventName: 'slip:afterswipe'
    },
    {
      eventName: 'slipTap',
      slipEventName: 'slip:tap'
    },
    {
      eventName: 'slipCancelSwipe',
      slipEventName: 'slip:cancelswipe'
    },
    {
      eventName: 'slipReorder',
      slipEventName: 'slip:reorder',
      prepLocals: _prepLocals,
      defaultResponse: _defaultResponse
    }
  ]

  return {
    restrict: 'A',
    controller: controller,
    link: link
  }

  function controller () {

    var _this = this
    var registeredEventHandlers = {}

    _this.registerEventHandler = registerEventHandler

    // Walk up the DOM to find the list item
    // that owns the particular child.
    function findListItem (childElement) {

      var element = childElement

      // eslint-disable-next-line eqeqeq
      while (element && element.parentNode != _this.listElement) {

        element = element.parentNode
      }

      return element
    }

    // Determine the index of an element relative to its parent.
    function determineElementIndex (listItemElement) {

      var element, itemIndex

      element = listItemElement
      itemIndex = 0

      while (element) {

        if (element.nodeType === 1) ++itemIndex
        element = element.previousSibling
      }

      return itemIndex - 1
    }

    // Functions to register event handlers.
    function registerEventHandler (handler, eventType, handlerScope) {

      var registered

      if (!registeredEventHandlers[eventType.eventName]) {

        registeredEventHandlers[eventType.eventName] = []
      }

      registered = registeredEventHandlers[eventType.eventName]

      if (registered.length === 0) {

        // Lazily register the event handler when the user defines it.
        _this.listElement.addEventListener(
          eventType.slipEventName,
          onEvent
        )
      }

      registered.push(handler)

      function onEvent (event) {

        var itemIndex, listItemElement, length, i, _listener

        itemIndex = -1
        listItemElement = findListItem(event.target)

        if (listItemElement) {
          itemIndex = determineElementIndex(listItemElement)
        }

        length = registered.length
        for (i = 0; i < length; i++) {

          _listener = registered[i]
          if (_listener) _processListener(_listener)
        }

        function _processListener (listener) {

          var locals

          locals = {
            $event: event,
            $index: itemIndex
          }

          if (eventType.prepLocals) {

            // Each event type can add
            // its own locals to the mix if necessary.
            angular.extend(locals, eventType.prepLocals(event))
          }

          handlerScope.$apply(function () {

            if (listener(handlerScope, locals)) {

              if (eventType.defaultResponse) {

                // A truthy return value from the user's event
                // handler enables the default event response.
                eventType.defaultResponse(event)
              }
            }
          })
        }
      }
    }
  }

  function link (scope, element, attrs, ctrl) {

    var el, currentValue, slip

    el = element[0]
    ctrl.listElement = el

    currentValue = attrs['basSlip']
    attrs.$observe('basSlip', onObserve)

    slip = new $window.Slip(el)

    processValue()

    _registerEvents()

    function onObserve (value) {

      if (currentValue !== value) {

        currentValue = value

        processValue()
      }
    }

    function processValue () {

      if (currentValue === 'false') {

        slip.detach()

      } else {

        slip.attach(el)
      }
    }

    function _registerEvents () {

      var length, i, eventType, handler

      length = eventTypes.length
      for (i = 0; i < length; i++) {

        eventType = eventTypes[i]

        if (attrs[eventType.eventName]) {

          handler = $parse(attrs[eventType.eventName], null, true)
          ctrl.registerEventHandler(handler, eventType, scope)
        }
      }
    }
  }

  function _prepLocals (event) {

    return {
      $spliceIndex: event.detail.spliceIndex,
      $originalIndex: event.detail.originalIndex
    }
  }

  function _defaultResponse (event) {

    event.target.parentNode.insertBefore(
      event.target,
      event.detail.insertBefore
    )
  }
}
