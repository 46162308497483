'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('modalHelperService', [
    'UI_HELPER',
    'UiHelper',
    modalHelperService
  ])

/**
 * Service for calculating modal window properties
 *
 * @constructor
 * @param {UI_HELPER} UI_HELPER
 * @param {UiHelper} UiHelper
 */
function modalHelperService (
  UI_HELPER,
  UiHelper
) {
  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  var V_AUTO = -1

  var CSS_FORCE_CUSTOM_POSITION = 'modal-menu-force-custom-position'

  var _types = {
    horizontal: 1,
    vertical: 2,
    verticalRight: 3,
    aboveRight: 4,
    aboveLeft: 5,
    belowRight: 6,
    belowLeft: 7
  }

  // Target bounding rectangle
  var buttonRect = {}

  // Number of items that the modal is showing
  var visibleNumberOfItems = 1

  // Final modal style properties
  var modalStyleWrapper = {}

  // Store values for modal style
  var styleValues = {}

  /**
   * Amount of ms to wait
   * before starting height calculations for the modal
   *
   * @type {number}
   */
  this.modalReadyMs = 50

  this.type = _types

  this.getModalStyle = getModalStyle
  this.resetModalStyle = resetModalStyle
  this.calcPosition = calcPosition
  this.calcHeight = calcHeight

  init()

  function init () {

    modalStyleWrapper.modalStyle = {}
    modalStyleWrapper.modalContainerStyle = {}
    modalStyleWrapper.modalClass = {}

    styleValues.modal = {}
    styleValues.modalContainer = {}

    resetModalStyle()
  }

  /**
   * Wrapper for modal style
   *
   * @returns {Object}
   */
  function getModalStyle () {
    return modalStyleWrapper
  }

  /**
   * Reset the modalStyle height attribute
   */
  function resetModalStyle () {
    modalStyleWrapper.modalStyle.height = null
    modalStyleWrapper.modalStyle.top = 'auto'
    modalStyleWrapper.modalStyle.bottom = 'auto'
    modalStyleWrapper.modalStyle.left = 'auto'
    modalStyleWrapper.modalStyle.right = 'auto'
    modalStyleWrapper.modalStyle.transition = 'unset'
    modalStyleWrapper.modalStyle.transform = 'translate3d(0,0,0)'

    modalStyleWrapper.modalContainerStyle['overflow-x'] = 'hidden'
    modalStyleWrapper.modalContainerStyle['overflow-y'] = 'auto'

    modalStyleWrapper.modalClass[CSS_FORCE_CUSTOM_POSITION] = false

    // Number values
    styleValues.modal.height = -1
    styleValues.modal.top = -1
    styleValues.modal.bottom = -1
    styleValues.modal.left = -1
    styleValues.modal.right = -1
  }

  function processStyleValues () {
    var styleProperties = [
      'top',
      'bottom',
      'left',
      'right'
    ]
    var i, length, key

    // Iterate style properties
    length = styleProperties.length
    for (i = 0; i < length; i += 1) {
      key = styleProperties[i]

      // Check for positive number
      if (BasUtil.isPNumber(styleValues.modal[key], true)) {
        modalStyleWrapper.modalStyle[key] =
          styleValues.modal[key] + 'px'
      } else {
        modalStyleWrapper.modalStyle[key] = 'auto'
      }
    }
  }

  /**
   * Searches for the DOM element which has been used as button
   *
   * @param {Object} event
   * @param {string} targetClassName
   */
  function getTargetBoundingRectangle (
    event,
    targetClassName
  ) {
    var tempElement, foundElement, coordinatesObject

    // Check viewport type and event
    if (
      BasUtil.isObject(event) &&
      BasUtil.isObject(event.target)
    ) {

      tempElement = event.target
      foundElement = true

      // Check SVG because SVG has a non-string classname
      // noinspection JSUnresolvedVariable
      while (tempElement instanceof SVGElement) {

        // Check for parent element
        if (BasUtil.isObject(tempElement) &&
          BasUtil.isObject(tempElement.parentElement)) {
          tempElement = tempElement.parentElement
        } else {
          foundElement = false
        }
      }

      if (foundElement) {

        // Search for the target element
        while (
          BasUtil.isString(tempElement.className) &&
          tempElement.className.search(targetClassName) === -1
        ) {

          // Go to next parent element
          if (BasUtil.isObject(tempElement) &&
            BasUtil.isObject(tempElement.parentElement)) {
            tempElement = tempElement.parentElement
          } else {
            foundElement = false
            break
          }
        }
      }

      if (foundElement) {

        // Get bounding rectangle for the button
        buttonRect = document.children[0] === tempElement
          ? {
              left: event.clientX,
              top: event.clientY,
              right: event.clientX,
              bottom: event.clientY,
              width: 0,
              height: 0
            }
          : tempElement.getBoundingClientRect()

      } else {

        coordinatesObject = event.touches && event.touches[0]
          ? event.touches[0]
          : event

        // If no matching element was found, we use the event coordinates to
        //  create a bounding rect
        buttonRect = {
          left: coordinatesObject.clientX,
          top: coordinatesObject.clientY,
          right: coordinatesObject.clientX,
          bottom: coordinatesObject.clientY,
          width: 0,
          height: 0
        }
      }

      // Calculate bottom and right offset
      if (buttonRect && BasUtil.isVNumber(buttonRect.top)) {

        buttonRect.posBottom =
          basUi.prop.vh - buttonRect.bottom
        buttonRect.posRight =
          basUi.prop.vw - buttonRect.right
      }
    }
  }

  function correctModalPositioning () {
    var modalWidth
    var modalOffsetTotal
    var modalOffsetDifference

    // Check for known width of modal menu
    if (BasUtil.isVNumber(basUi.modalTemplate.width)) {

      modalWidth = basUi.modalTemplate.width

      // Check modal HORIZONTAL
      switch (styleValues.modal.right) {
        case V_AUTO:

          // Calculate outer modal border
          modalOffsetTotal =
            styleValues.modal.left + modalWidth

          // Check if left modal + width exceeds viewport
          if (modalOffsetTotal > basUi.prop.vw) {

            modalOffsetDifference =
              modalOffsetTotal - basUi.prop.vw

            // Move modal to the left + 5% extra spacing
            styleValues.modal.left =
              styleValues.modal.left -
              modalOffsetDifference -
              0.05 * basUi.prop.vw
          }

          break
        default:

        // TODO other modal positions

      }
    }
  }

  /**
   * Calculates the position of the modal
   * relative to the pressed button
   *
   * @param event
   * @param {string} targetClassName
   * @param {number} type
   */
  function calcPosition (
    event,
    targetClassName,
    type
  ) {
    var extraSpacing, targetCenterY

    getTargetBoundingRectangle(event, targetClassName)

    // If center of touch target is smaller than 100, the button is probably
    //  in one of the top bars. Mobile view should have a calculated position
    //  too.
    targetCenterY = (buttonRect.top + (buttonRect.bottom - buttonRect.top) / 2)

    // Force custom position if target element is in top 150px of the screen
    //  (headers). If not, the modal can be forced to the bottom of the screen
    //  if the screen height is low enough.
    modalStyleWrapper.modalClass[CSS_FORCE_CUSTOM_POSITION] =
      targetCenterY < 150

    if (BasUtil.isVNumber(buttonRect.top)) {

      // Remove transform
      modalStyleWrapper.modalStyle.transform = 'translate3d(0,0,0)'

      switch (type) {
        case _types.vertical:

          // VERTICAL
          if (buttonRect.top > buttonRect.posBottom) {
            styleValues.modal.top = V_AUTO
            styleValues.modal.bottom =
              buttonRect.posBottom - (buttonRect.height / 2)
          } else {
            styleValues.modal.top =
              buttonRect.top +
              buttonRect.height +
              (buttonRect.height / 2)
            styleValues.modal.bottom = V_AUTO
          }

          // HORIZONTAL
          if (buttonRect.left > buttonRect.posRight) {
            styleValues.modal.right =
              buttonRect.posRight + buttonRect.width
            styleValues.modal.left = V_AUTO
          } else {
            styleValues.modal.right = V_AUTO
            styleValues.modal.left =
              buttonRect.left + buttonRect.width
          }
          break

        case _types.verticalRight:

          extraSpacing = 12

          // HORIZONTAL - Probably incorrect calculation
          // because of negative value
          if (buttonRect.top > buttonRect.posBottom) {
            styleValues.modal.top = V_AUTO
            styleValues.modal.bottom =
              buttonRect.posBottom +
              buttonRect.height +
              extraSpacing
          } else {
            styleValues.modal.top =
              buttonRect.top +
              buttonRect.height +
              extraSpacing
            styleValues.modal.bottom = V_AUTO
          }

          // HORIZONTAL - + 10px for background shadow
          styleValues.modal.right =
            buttonRect.posRight + extraSpacing
          styleValues.modal.left = V_AUTO
          break

        case _types.aboveRight:

          // VERTICAL - Above button
          styleValues.modal.top = V_AUTO
          styleValues.modal.bottom =
            buttonRect.posBottom + buttonRect.height

          // HORIZONTAL - Right side of button
          styleValues.modal.right = V_AUTO
          styleValues.modal.left = buttonRect.left

          break

        case _types.aboveLeft:

          // VERTICAL - Above button
          styleValues.modal.top = V_AUTO
          styleValues.modal.bottom =
            buttonRect.posBottom + buttonRect.height

          // HORIZONTAL - Left side of button
          styleValues.modal.right = buttonRect.posRight
          styleValues.modal.left = V_AUTO

          break
        case _types.belowRight:

          // VERTICAL - Below button
          styleValues.modal.top =
            buttonRect.top + buttonRect.height
          styleValues.modal.bottom = V_AUTO

          // HORIZONTAL - Right side of button
          styleValues.modal.right = V_AUTO
          styleValues.modal.left = buttonRect.left

          break
        case _types.horizontal:
        default:

          // VERTICAL
          if (buttonRect.top > buttonRect.posBottom) {
            styleValues.modal.top = V_AUTO
            styleValues.modal.bottom =
              buttonRect.posBottom + (buttonRect.height / 2)
          } else {
            styleValues.modal.top =
              buttonRect.top + (buttonRect.height / 2)
            styleValues.modal.bottom = V_AUTO
          }

          // HORIZONTAL
          if (buttonRect.left > buttonRect.posRight) {
            styleValues.modal.right =
              buttonRect.posRight + buttonRect.width
            styleValues.modal.left = V_AUTO
          } else {
            styleValues.modal.right = V_AUTO
            styleValues.modal.left =
              buttonRect.left + buttonRect.width
          }
          break
      }

      // Check for modals out of view
      correctModalPositioning()

      // Set calculated values as style properties
      processStyleValues()
    }
  }

  /**
   * Calculate the height of the modal
   *
   * @param {number} numberOfItemsInit
   * @param {number} numberOfItems
   * @param {Object} [scope]
   */
  function calcHeight (
    numberOfItemsInit,
    numberOfItems,
    scope
  ) {
    var maxVisibleNumberOfItems

    visibleNumberOfItems = numberOfItemsInit

    if (
      basUi.prop.vh > UI_HELPER.D_W_MEDIUM ||
      basUi.prop.deviceType !== UI_HELPER.D_T_PHONE
    ) {

      // Calculate possible number of items
      // Make sure there is at least the height of
      // 1 modal menu item margin between
      // the modal and the border of the viewport. (-1)
      if (modalStyleWrapper.modalStyle.top === 'auto') {

        // Calculate number of visible items above button
        visibleNumberOfItems =
          Math.floor(
            buttonRect.top /
            basUi.modalTemplate.height
          ) - 1

      } else if (modalStyleWrapper.modalStyle.bottom === 'auto') {

        // Calculate number of visible items below button
        visibleNumberOfItems =
          Math.floor(
            buttonRect.posBottom /
            basUi.modalTemplate.height
          ) - 1
      }

      // Compare with number of items that need to be shown
      visibleNumberOfItems = Math.min(numberOfItems, visibleNumberOfItems)

    } else {

      // Modal menu forced to bottom in css

      // Fill screen as much as possible
      // Make sure there is in total 2 times
      // the height of a modal menu item as margin. (-2)
      visibleNumberOfItems = numberOfItems
      maxVisibleNumberOfItems =
        Math.floor(
          basUi.prop.vh /
          basUi.modalTemplate.height
        ) - 2

      // Compare with max number of items that can be shown
      visibleNumberOfItems = Math.min(
        visibleNumberOfItems,
        maxVisibleNumberOfItems
      )
    }

    // To complement CSS bug when only 2 items are shown
    if (visibleNumberOfItems < 3) {

      modalStyleWrapper.modalContainerStyle['overflow-x'] = 'visible'
      modalStyleWrapper.modalContainerStyle['overflow-y'] = 'visible'
    }

    if (BasUtil.isPNumber(basUi.modalTemplate.height)) {

      modalStyleWrapper.modalStyle.height =
        (visibleNumberOfItems * basUi.modalTemplate.height) + 'px'
    }

    // Sync scope if a scope was given
    if (BasUtil.isObject(scope) &&
      BasUtil.isFunction(scope.$applyAsync)) {

      scope.$applyAsync()
    }
  }
}
