'use strict'

angular
  .module('basalteApp')
  .service('BasIntercomHelper', [
    'BAS_ROOMS',
    'BasAppDevice',
    'CurrentBasCore',
    BasIntercomHelper
  ])

/**
 * @constructor
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasAppDevice} BasAppDevice
 * @param {CurrentBasCore} CurrentBasCore
 */
function BasIntercomHelper (
  BAS_ROOMS,
  BasAppDevice,
  CurrentBasCore
) {
  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  this.shouldShowIntercom = shouldShowIntercom

  /**
   * Only show intercom if:
   * - Is ellie/lisa build
   * - Is ellie/lisa profile
   * - Has the right to use the intercom
   * - Has binding with HTTP service (bound with discovery app as HTTP server)
   *   (Ellie)
   * - HTTP Web server is up and running
   *   (Lisa)
   * - Is lisa build in demo
   *
   * @returns {boolean}
   */
  function shouldShowIntercom () {
    // Show intercom when
    //  - Core Client build
    //  - Logged in with Core Client profile
    //  - Intercom access and rooms with intercom function
    // OR
    //  - Lisa in demo mode
    return (
      CurrentBasCore.isLoggedInWithCoreClientDeviceProfile() &&
      BasAppDevice.isCoreClient() &&
      CurrentBasCore.hasIntercomAccess() &&
      rooms.info.hasIntercomRooms
    ) || (
      BasAppDevice.isLisa() &&
      CurrentBasCore.has() &&
      CurrentBasCore.get().core.isDemo()
    )
  }
}
