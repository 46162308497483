'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasTimersState', [
    '$transitions',
    'STATES',
    'RoomsHelper',
    'UiHelper',
    BasTimersState
  ])

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {RoomsHelper} RoomsHelper
 * @param {UiHelper} UiHelper
 */
function BasTimersState (
  $transitions,
  STATES,
  RoomsHelper,
  UiHelper
) {
  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  init()

  function init () {

    $transitions.onStart(
      {
        to: STATES.TIMERS
      },
      _onBasTimersState
    )
  }

  function _onBasTimersState (transition) {

    var target, toName, from, fromName, homeTimers

    target = transition.targetState()
    toName = target.name()
    from = transition.from()
    fromName = from.name

    if (toName === STATES.TIMERS) {

      homeTimers = _getHomeTimers()

      if (homeTimers) {

        if (
          basUi.prop.wMedium &&
          homeTimers.uiTimers &&
          homeTimers.uiTimers.length > 0
        ) {
          return target
            .withState(STATES.TIMER_WEEK)
            .withParams({
              timer: homeTimers.uiTimers[0]
            })
        }

      } else {

        return BasUtil.startsWith(fromName, STATES.TIMERS)
          ? target.withState(STATES.HOME)
          : false
      }
    }
  }

  /**
   * @private
   * @returns {?BasRoomTimers}
   */
  function _getHomeTimers () {

    var room

    room = RoomsHelper.getHome()

    if (room && room.timers && room.timers.onOrderUpdated) {

      return room.timers
    }

    return null
  }
}
