'use strict'

angular
  .module('basalteApp')
  .constant('BAS_DISCOVERY_CLOUD', {
    API_PATH_DISCOVER: 'https://api.basalte.net/discover',
    API_SERVER_PATH_PROJECT: 'api/project',
    EVT_DISCOVERY_CLOUD_CORE_SERVICE_FOUND:
            'evtBasDiscoveryCloudCoreServiceFound',
    EVT_DISCOVERY_CLOUD_CORE_SERVICE_LOST:
            'evtBasDiscoveryCloudCoreServiceLost',
    K_LOCAL_IP: 'localIp',
    K_MAC: 'mac',
    K_TYPE: 'type',
    K_TYPE_ID: 'typeId',
    K_UUID: 'uuid',
    K_NAME: 'name',
    K_IMAGE: 'image',
    K_MASTER: 'master',
    K_LOCATION: 'location',
    K_CITY: 'city',
    K_COUNTRY: 'country',
    UPDATE_INTERVAL_MS: 5000,
    UPDATE_INTERVAL_LONG_MS: 10000,
    UPDATE_INTERVAL_LONGER_MS: 30000,
    MIN_TIME_DIFF_MS: 4800,
    CLOUD_DISCOVERY_TIMEOUT_MS: 5000,
    PROJECT_INFO_TIMEOUT_MS: 3000
  })
