'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('devicePlaybackGain', devicePlaybackGain)

function devicePlaybackGain () {

  return {
    restrict: 'AE',
    template: '<div class="bbs-name" data-translate="playback_gain"></div>' +
      '<div class="bbs-slider-wrapper">' +
      '<bas-slider class="bbs-slider bas-slider-dark"' +
      ' data-slider-model-object="playbackGain"' +
      ' data-key="playbackGainUi"' +
      ' data-min="playbackGain.playbackGainMin"' +
      ' data-max="playbackGain.playbackGainMax"' +
      ' data-slider-change="playbackGain.playbackGainChange()"' +
      ' data-slider-changed="playbackGain.playbackGainChange()"></bas-slider>' +
      '<div class="bbs-slider-value"' +
      ' ng-bind="playbackGain.playbackGainUi"></div>' +
      '<div class="bbs-slider-unit">dB</div>' +
      '</div>',
    controller: [
      'BAS_PREFERENCES',
      'BasPreferences',
      controller
    ],
    controllerAs: 'playbackGain'
  }

  /**
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BasPreferences} BasPreferences
   */
  function controller (
    BAS_PREFERENCES,
    BasPreferences
  ) {
    var playbackGain = this

    playbackGain.playbackGainMax = BAS_PREFERENCES.GAIN_MAX

    playbackGain.playbackGainMin = BAS_PREFERENCES.GAIN_MIN

    playbackGain.playbackGainChange = playbackGainChange

    initUiValue()

    /**
     * Set ui value for gain.
     * Used for keeping the current set gain while changing the limits
     * of the slider.
     */
    function initUiValue () {

      playbackGain.playbackGain = BasPreferences.getPlaybackGain()

      playbackGain.playbackGainUi = BasUtil.getCorrectedValue(
        playbackGain.playbackGain,
        {
          min: playbackGain.playbackGainMin,
          max: playbackGain.playbackGainMax,
          step: 1
        }
      )
    }

    function playbackGainChange () {

      BasPreferences.setPlaybackGain(playbackGain.playbackGainUi)
    }
  }
}
