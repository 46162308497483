'use strict'

angular
  .module('basalteApp')
  .controller('camerasDetailCtrl', [
    '$uiRouterGlobals',
    'BasCameras',
    camerasDetailCtrl
  ])

/**
 * @param $uiRouterGlobals
 * @param {BasCameras} BasCameras
 */
function camerasDetailCtrl (
  $uiRouterGlobals,
  BasCameras
) {
  var cameras = this

  cameras.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {TCamerasState}
   */
  cameras.camerasState = BasCameras.get()
}
