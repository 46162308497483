'use strict'

angular
  .module('basalteApp')
  .directive('basQueue', [
    'BAS_HTML',
    basQueueDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basQueue
 */
function basQueueDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    scope: {},
    template: BAS_HTML.basQueue,
    controller: [
      '$element',
      'BAS_API',
      controller
    ],
    controllerAs: 'basQueueDir',
    bindToController: {
      basSource: '<?'
    }
  }

  /**
   * @param $element
   * @param BAS_API
   */
  function controller (
    $element,
    BAS_API
  ) {
    var basQueueDir = this

    basQueueDir.queueTap = queueTap

    /**
     * @param {number} index
     */
    function queueTap (index) {

      var queue

      queue = _getQueue()

      if (queue) {

        if (
          queue.type === BAS_API.Queue.TYPE_DEEZER_FLOW ||
          queue.type === BAS_API.Queue.TYPE_DEEZER_RADIO
        ) {
          $element[0].scrollTop = 0
        }

        queue.queueTap(index)
      }
    }

    /**
     * @private
     * @returns {?BasSourceQueue}
     */
    function _getQueue () {

      var basSource

      basSource = _getBasSource()

      return (basSource && basSource.queue && basSource.queue.queueTap)
        ? basSource.queue
        : null
    }

    /**
     * @private
     * @returns {?BasSource}
     */
    function _getBasSource () {
      return (basQueueDir.basSource && basQueueDir.basSource.togglePlay)
        ? basQueueDir.basSource
        : null
    }
  }
}
