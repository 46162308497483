'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceFullScreen', deviceFullScreen)

function deviceFullScreen () {

  return {
    restrict: 'AE',
    template:
      '<div class="' +
      'bbs bbs-item bbs-centered' +
      ' bbs-flex bbs-active-select"' +
      ' bas-click="fullScreen.toggle()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      ' data-translate="full_screen"></div>' +
      '  <bas-toggle class="bbs-toggle bas-toggle-dark"' +
      ' data-bas-toggle="fullScreen.toggle()"' +
      ' data-bas-toggled="fullScreen.state.isFullScreen"></bas-toggle>' +
      '</div>',
    controller: [
      '$rootScope',
      '$scope',
      'BasFullScreen',
      controller
    ],
    controllerAs: 'fullScreen'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BasFullScreen} BasFullScreen
   */
  function controller (
    $rootScope,
    $scope,
    BasFullScreen
  ) {
    var fullScreen = this

    var _listeners = []

    /**
     * @type {TBasFullScreenState}
     */
    fullScreen.state = BasFullScreen.get()

    fullScreen.toggle = toggle

    fullScreen.$postLink = _onPostLink
    fullScreen.$onDestroy = _onDestroy

    function _onPostLink () {

      _listeners.push($rootScope.$on(
        BasFullScreen.EVT_FULL_SCREEN_CHANGED,
        _onFullScreenChanged
      ))
    }

    function _onFullScreenChanged () {

      $scope.$applyAsync()
    }

    function toggle () {

      BasFullScreen.toggle()
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
