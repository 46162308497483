'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('shadesSceneCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_CURRENT_CORE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BasSceneHelper',
    shadesSceneCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BasSceneHelper} BasSceneHelper
 */
function shadesSceneCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_CURRENT_CORE,
  BAS_ROOMS,
  BAS_ROOM,
  BasSceneHelper
) {
  var shades = this

  var _listeners = []
  var _oldState = BasSceneHelper.copyState($uiRouterGlobals)

  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  shades.$uiRouterGlobals = $uiRouterGlobals
  shades.liveState = false
  shades.shades = []

  shades.liveToggle = liveToggle
  shades.selectShade = selectShade

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SHADES_INITIALIZED,
      _onShades
    ))
    // TODO Support config without music
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _clearShades
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_FUNCTION_INPUT,
      _onData
    ))

    _syncShades()
  }

  /**
   * Toggle live state on every shade
   * Adds all shades to the step if live state is true
   */
  function liveToggle () {

    var length, i

    shades.liveState = !shades.liveState

    length = shades.shades.length
    for (i = 0; i < length; i++) {

      shades.shades[i].setSyncMode(shades.liveState)
      if (shades.liveState) _addToStep(shades.shades[i])
    }
  }

  function selectShade (shade) {

    _toggleOnStep(shade)
  }

  /**
   * @private
   * @param {Object} _evt
   * @param {string} roomId
   */
  function _onShades (_evt, roomId) {

    var _step, _room

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.room) _room = rooms.rooms[_step.room]

    if (_room && _room.id === roomId) _syncShades()
  }

  function _syncShades () {

    var _step, _room, length, i, shade

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    _clearShades()

    if (_step && _step.room) _room = rooms.rooms[_step.room]

    if (_room && _room.shades) {

      shades.shades = _room.shades.createEditableClones()
      length = shades.shades.length

      for (i = 0; i < length; i++) {

        shade = shades.shades[i]
        shade.setSyncMode(shades.liveState)
        shade.syncBasShade(_step.shades[shade.uuid])
      }
    }
  }

  function _clearShades () {

    var length, i

    length = shades.shades.length
    for (i = 0; i < length; i++) {

      if (shades.shades[i]) shades.shades[i].clear()
    }

    shades.shades = []
  }

  /**
   * @private
   * @param _evt
   * @param {BasShade} evtShade
   */
  function _onData (_evt, evtShade) {

    var length, i, shade

    length = shades.shades.length
    for (i = 0; i < length; i++) {

      shade = shades.shades[i]

      if (shade === evtShade) {

        _addToStep(shade)
        break
      }
    }
  }

  /**
   * @private
   * @param {BasShade} shade
   */
  function _addToStep (shade) {

    var _step

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step) _step.addShade(shade)
  }

  /**
   * @private
   * @param {BasShade} shade
   */
  function _toggleOnStep (shade) {

    var _step

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step) _step.toggleShade(shade)
  }

  function _onDestroy () {

    var _step

    _step = BasSceneHelper.getStep($scope, _oldState)

    if (_step) _step.updateSummary()

    BasUtil.executeArray(_listeners)
    _clearShades()
  }
}
