'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasCoreHelper', [
    'BAS_API',
    BasCoreHelper
  ])

/**
 * @constructor
 * @param BAS_API
 */
function BasCoreHelper (BAS_API) {

  this.checkAddressIsBasCore = checkAddressIsBasCore
  this.isCoreCredentials = isCoreCredentials

  /**
   * @param {*} object
   * @returns {boolean}
   */
  function isCoreCredentials (object) {
    return (
      BasUtil.isObject(object) &&
      BasUtil.isNEString(object[BAS_API.BasCore.K_USER]) &&
      BasUtil.isNEString(object[BAS_API.BasCore.K_TOKEN]) &&
      BasUtil.isNEString(object[BAS_API.BasCore.K_CREDENTIAL_HASH]) &&
      BasUtil.isNEString(object[BAS_API.BasCore.K_CREDENTIAL_TOKEN_HASH])
    )
  }

  /**
   * Checks if a specified location is a BasCore server.
   *
   * @param {string} address
   * @returns {Promise}
   */
  function checkAddressIsBasCore (address) {

    return BAS_API.BasCore.getUsers(address)
  }
}
