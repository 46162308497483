'use strict'

angular
  .module('basSupports', [])
  .provider('BasSupports', [
    '$windowProvider',
    BasSupportsProvider
  ])

function BasSupportsProvider ($windowProvider) {

  var basWindow, basSupportsState

  /**
   * @type {Window}
   */
  basWindow = $windowProvider.$get()

  /**
   * @type {TBasSupports}
   */
  basSupportsState = basWindow.basSupportResult

  this.get = get

  this.$get = ['$injector', BasSupportsFactory]

  /**
   * @returns {TBasSupports}
   */
  function get () {

    return basSupportsState
  }

  // Factory

  function BasSupportsFactory ($injector) {
    return $injector.instantiate(BasSupports)
  }

  // Service

  /**
   * @constructor
   */
  function BasSupports () {

    // noinspection JSPotentiallyInvalidUsageOfThis
    this.get = get
  }
}
