'use strict'

angular
  .module('basalteApp')
  .service('BasTime', [
    '$rootScope',
    'BAS_INTL',
    'BAS_CURRENT_CORE',
    'BAS_TIME',
    'BAS_APP',
    'BasIntl',
    'BasApp',
    BasTime
  ])

/**
 * @typedef {Object} TBasTime
 * @property {Date} time
 * @property {Object} ui
 * @property {string} ui.time
 * @property {string} ui.timeAMPM
 * @property {boolean} frozen
 */

/**
 * Service for keeping track of time
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_TIME} BAS_TIME
 * @param {BAS_APP} BAS_APP
 * @param {BasIntl} BasIntl
 * @param {BasApp} BasApp
 */
function BasTime (
  $rootScope,
  BAS_INTL,
  BAS_CURRENT_CORE,
  BAS_TIME,
  BAS_APP,
  BasIntl,
  BasApp
) {
  var uiTypes = [
    BAS_INTL.LOCALE_OPTION_SHORT,
    BAS_INTL.LOCALE_OPTION_HOUR,
    BAS_INTL.LOCALE_OPTION_LONG,
    BAS_INTL.LOCALE_OPTION_DAY_MONTH
  ]

  var timeoutId

  /**
   * @private
   * @type {TBasTime} _state
   */
  var _state = {
    time: undefined,
    ui: {
      time: '',
      timeAMPM: ''
    },
    frozen: false
  }

  /**
   * @type {TBasIntlState}
   */
  var basIntlState = BasIntl.get()

  /**
   * @type {TBasAppState}
   */
  var basAppState = BasApp.get()

  this.get = get
  // Exposed so time can be frozen using the console for e.g. continuity when
  // recording the screen.
  //
  // Usage:
  //  angular.element(document.body)
  //    .injector()
  //    .get('BasTime')
  //    .freezeTime(new Date("Fri Nov 26 2021 13:27:53 GMT+0100"))
  this.freezeTime = freezeTime
  this.unfreezeTime = unfreezeTime

  init()

  function init () {

    _syncTime()

    if (!basAppState.isPaused) _setSyncTimeTimeout()

    $rootScope.$on(
      BAS_INTL.EVT_TIME_FORMAT_CHANGED,
      _onTimeFormatChanged
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_SYSTEM,
      _onSystemProperties
    )
    $rootScope.$on(
      BAS_APP.EVT_PAUSE,
      _onPause
    )
    $rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onResume
    )
  }

  /**
   * @returns {TBasTime}
   */
  function get () {
    return _state
  }

  function freezeTime (date) {

    _state.frozen = true

    if (date) _state.time = date
    _syncUiTime()
    _emitTimeUpdated()
  }

  function unfreezeTime () {

    _state.frozen = false
    _syncTime()
  }

  function _setSyncTimeTimeout () {

    // Schedule timeout when current minute is over
    timeoutId = setTimeout(_onTimeout, _msUntilNextMinute())
  }

  function _onTimeout () {

    _syncTime()

    // Schedule timeout when current minute is over
    timeoutId = setTimeout(_onTimeout, _msUntilNextMinute())
  }

  function _msUntilNextMinute () {

    return 60000 - (Date.now() % 60000)
  }

  function _syncTime () {

    if (!_state.frozen) {
      _state.time = new Date()
      _syncUiTime()
      _emitTimeUpdated()
    }

    _emitTimeUpdated()
  }

  function _syncUiTime () {

    var length, i

    _state.ui.time = BasIntl.dateToString(
      _state.time,
      BAS_INTL.LOCALE_OPTION_SHORT_WITHOUT_MERIDIEM
    )

    _state.ui.timeAMPM = basIntlState.timeFormat === BAS_INTL.TIME_FORMAT_12
      ? BasIntl.dateToString(
        _state.time,
        BAS_INTL.LOCALE_OPTION_MERIDIEM
      )
      : ''

    length = uiTypes.length
    for (i = 0; i < length; i++) {
      _state.ui[uiTypes[i]] = BasIntl.dateToString(_state.time, uiTypes[i])
    }
  }

  function _onTimeFormatChanged () {

    _syncUiTime()
  }

  function _onSystemProperties () {

    _syncUiTime()
    _emitTimeUpdated()
  }

  function _onPause () {

    clearTimeout(timeoutId)
  }

  function _onResume () {

    _syncTime()
    _setSyncTimeTimeout()
  }

  function _emitTimeUpdated () {

    $rootScope.$emit(BAS_TIME.EVT_TIME_UPDATED)
  }
}
