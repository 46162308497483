'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basSourceName', basSourceNameDirective)

function basSourceNameDirective () {

  return {
    restrict: 'AE',
    controller: [
      '$rootScope',
      '$element',
      'BAS_SOURCE',
      'SourcesHelper',
      'BasUtilities',
      controller
    ],
    bindToController: {
      basSourceName: '<?'
    }
  }

  /**
   * @param $rootScope
   * @param $element
   * @param {BAS_SOURCE} BAS_SOURCE
   * @param {SourcesHelper} SourcesHelper
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    $element,
    BAS_SOURCE,
    SourcesHelper,
    BasUtilities
  ) {
    var basSourceNameDir = this

    var _listeners = []

    this.$onChanges = _onChanges
    this.$onDestroy = _onDestroy

    init()

    function init () {

      _listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_NAME_UPDATED,
        _onSourceName
      ))
      _listeners.push($rootScope.$on('$translateChangeSuccess', _onTranslate))
    }

    function _onChanges (changes) {

      if (changes.basSourceName) _syncSourceName()
    }

    /**
     * @private
     * @param _event
     * @param {string} sourceId
     */
    function _onSourceName (_event, sourceId) {

      var basSource

      basSource = basSourceNameDir.basSourceName

      if (basSource && basSource.uuid === sourceId) _syncSourceName()
    }

    function _onTranslate () {

      _syncSourceName()
    }

    function _syncSourceName () {

      var basSource, playerSource, sourceName, txtOff

      txtOff = '- ' + BasUtilities.translate('off') + ' -'

      basSource = _getSource()

      if (basSource) {

        if (basSource.type === BAS_SOURCE.T_BARP) {

          playerSource = SourcesHelper.getPlayer(basSource.id)

          if (playerSource) {

            sourceName = playerSource.name

          } else {

            sourceName = basSource.name
              ? basSource.name
              : txtOff
          }

        } else if (basSource.name) {

          sourceName = basSource.name

        } else {

          switch (basSource.id) {
            case BAS_SOURCE.V_MIXED:
              sourceName = BasUtilities.translate('mixed_sources')
              break
            case BAS_SOURCE.V_AV_INPUT_UNKNOWN:
            case BAS_SOURCE.V_AV_INPUT_NONE:
              sourceName = BasUtilities.translate('unknown_src')
              break
            case BAS_SOURCE.V_EMPTY:
            default:
              sourceName = txtOff
              break
          }
        }

      } else {

        sourceName = txtOff
      }

      $element[0].textContent = sourceName
    }

    /**
     * @private
     * @returns {?BasSource}
     */
    function _getSource () {

      return (
        basSourceNameDir.basSourceName &&
        basSourceNameDir.basSourceName.togglePlay
      )
        ? basSourceNameDir.basSourceName
        : null
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
