'use strict'

/**
 * Represents all rooms
 *
 * @typedef {Object} BasRooms
 * @property {Object<string, BasRoom>} rooms
 * @property {string} root
 * @property {Object} filters
 * @property {Object<string, RoomFilter>} filters.filters
 * @property {Object<string, string>} filters.services
 * @property {string} filters.services.all
 * @property {string} filters.services.allMusic
 * @property {string} filters.services.groups
 * @property {string} filters.services.active
 * @property {string} filters.services.allThermostats
 * @property {Object<string, string>} filters.buildings
 * @property {Object<string, string>} filters.floors
 * @property {Object<string, string>} filters.tags
 * @property {Object<string, BasRoomBuilding>} buildings
 * @property {BasRoomsComponent} home
 * @property {BasRoomsComponent} music
 * @property {BasRoomsComponent} video
 * @property {BasRoomsComponent} thermostats
 * @property {BasRoomsComponent} openCloseDevices
 * @property {BasRoomsComponent} intercom
 * @property {Object} info
 * @property {boolean} info.hasRoomMenu
 * @property {boolean} info.hasHomeRooms
 * @property {boolean} info.hasMusicRooms
 * @property {boolean} info.hasVideoRooms
 * @property {boolean} info.hasThermostatRooms
 * @property {boolean} info.hasIntercomRooms
 * @property {boolean} info.hasEnergyRooms
 */

angular
  .module('basalteApp')
  .constant('BAS_ROOMS', {
    EVT_CURRENT_ROOM_CHANGED: 'evtCurrentRoomChanged',
    EVT_CURRENT_ROOM_SOURCE_CHANGED: 'evtCurrentRoomSourceChanged',
    EVT_CURRENT_ROOM_CURRENT_SONG: 'evtCurrentRoomCurrentSong',
    EVT_ROOMS_UPDATED: 'evtRoomsServiceRoomsUpdated',
    EVT_ROOMS_CLEARED: 'evtRoomsServiceRoomsCleared',
    P_ROOMS: 'areas',
    P_ZONES: 'zones',
    P_AV: 'av',
    BC_HOME: 'home',
    BC_THERMOSTATS: 'thermostats',
    BC_MUSIC: 'music',
    BC_OPEN_CLOSE_DEVICES: 'openCloseDevices',
    BC_INTERCOM: 'intercom',

    /**
     * Translation IDs
     */
    FUNCTIONS: {
      MUSIC: 'music',
      VIDEO: 'video',
      LIGHTS: 'lights',
      GENERIC_DEVICES: 'generic_devices',
      SHADES: 'shades',
      THERMOSTAT: 'thermostat',
      SCENES: 'scenes',
      SECURITY: 'security',
      INTERCOM: 'intercom'
    },

    /**
     * @type {BasRooms}
     */
    ROOMS: {
      rooms: {},
      root: '',
      info: {
        hasRoomMenu: false,
        hasHomeRooms: false,
        hasMusicRooms: false,
        hasVideoRooms: false,
        hasThermostatRooms: false,
        hasIntercomRooms: false,
        hasEnergyRooms: false
      }
    }
  })
