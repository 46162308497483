'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourceFavourites', [
    '$rootScope',
    'BAS_API',
    'BAS_DEEZER',
    'BAS_SOURCE',
    'BAS_FAVOURITES',
    'BAS_FAVOURITE',
    'SourcesHelper',
    'CurrentBasCore',
    'BasFavourite',
    'BasFilter',
    'BasCollection',
    basSourceFavouritesFactory
  ])

/**
 * @typedef {Object} TBasFavouritePaginationInfo
 * @property {number} loadedItemCount
 * @property {number} totalItemCount
 * @property {boolean} allItemsLoaded
 */

/**
 * @param $rootScope
 * @param {BAS_API} BAS_API
 * @param {BAS_DEEZER} BAS_DEEZER
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_FAVOURITES} BAS_FAVOURITES
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {SourcesHelper} SourcesHelper
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasFavourite
 * @param BasFilter
 * @param BasCollection
 * @returns BasSourceFavourites
 */
function basSourceFavouritesFactory (
  $rootScope,
  BAS_API,
  BAS_DEEZER,
  BAS_SOURCE,
  BAS_FAVOURITES,
  BAS_FAVOURITE,
  SourcesHelper,
  CurrentBasCore,
  BasFavourite,
  BasFilter,
  BasCollection
) {
  var K_PLAYLISTS = 'playlists'
  var K_RADIOS = 'radios'
  var K_DEEZER = 'deezer'
  var K_TIDAL = 'tidal'

  var CLEAN_FAVOURITES = 'serverFavourites'
  var CLEAN_SPOTIFY_PRESETS = 'spotifyPresets'

  var DEFAULT_ORDER = [
    BAS_FAVOURITE.T_SONOS,
    BAS_FAVOURITE.T_LOCAL_PLAYLIST,
    BAS_FAVOURITE.T_RADIO,
    BAS_FAVOURITE.T_DEEZER,
    BAS_FAVOURITE.T_TIDAL,
    BAS_FAVOURITE.T_SPOTIFY_CONNECT,
    BAS_FAVOURITE.T_VIDEO
  ]
  var DO_LENGTH = DEFAULT_ORDER.length

  var QUAD_FAVOURITES_LENGTH = 4

  var PAGE_FAVOURITE_COUNT = 25
  var PAGE_FAVOURITE_COUNT_MAX = 100

  // After retrieving this many favourite pages while retrieving all favourites,
  //  we assume that something went wrong and that we are in an infinite loop.
  //
  // (When retrieving all favourites using pagination, a page size of 100 is
  //  used, so this would detect large favourite collections of +- 10.000 or
  //  more favourites as an infinite loop)
  var MAX_ALL_FAVOURITE_PAGE_LOOP = 100

  var currentBasCoreState = CurrentBasCore.get()

  // All

  BAS_FAVOURITES.FILTER_ALL = new BasFilter()
  BAS_FAVOURITES.FILTER_ALL.translationId =
    BAS_FAVOURITES.FILTER_ALL.id = 'all'
  BAS_FAVOURITES.FILTER_ALL.order = 0

  // Local

  BAS_FAVOURITES.FILTER_LOCAL = new BasFilter()
  BAS_FAVOURITES.FILTER_LOCAL.translationId =
    BAS_FAVOURITES.FILTER_LOCAL.id = BAS_FAVOURITES.ID_T_LOCAL
  BAS_FAVOURITES.FILTER_LOCAL.order = 1

  // Radio

  BAS_FAVOURITES.FILTER_RADIO = new BasFilter()
  BAS_FAVOURITES.FILTER_RADIO.translationId =
    BAS_FAVOURITES.FILTER_RADIO.id = BAS_FAVOURITES.ID_T_RADIO
  BAS_FAVOURITES.FILTER_RADIO.order = 2

  // Deezer

  BAS_FAVOURITES.FILTER_DEEZER = new BasFilter()
  BAS_FAVOURITES.FILTER_DEEZER.translationId =
    BAS_FAVOURITES.FILTER_DEEZER.id = BAS_FAVOURITES.ID_T_DEEZER
  BAS_FAVOURITES.FILTER_DEEZER.order = 3

  // Tidal

  BAS_FAVOURITES.FILTER_TIDAL = new BasFilter()
  BAS_FAVOURITES.FILTER_TIDAL.translationId =
    BAS_FAVOURITES.FILTER_TIDAL.id = BAS_FAVOURITES.ID_T_TIDAL
  BAS_FAVOURITES.FILTER_TIDAL.order = 4

  // Spotify Connect

  BAS_FAVOURITES.FILTER_SPOTIFY_CONNECT = new BasFilter()
  BAS_FAVOURITES.FILTER_SPOTIFY_CONNECT.translationId =
    BAS_FAVOURITES.FILTER_SPOTIFY_CONNECT.id = BAS_FAVOURITES.ID_T_SPOTIFY
  BAS_FAVOURITES.FILTER_SPOTIFY_CONNECT.order = 5

  // Sonos

  BAS_FAVOURITES.FILTER_SONOS = new BasFilter()
  BAS_FAVOURITES.FILTER_SONOS.translationId =
    BAS_FAVOURITES.FILTER_SONOS.id = BAS_FAVOURITES.ID_T_SONOS
  BAS_FAVOURITES.FILTER_SONOS.order = 6

  // Video

  BAS_FAVOURITES.FILTER_VIDEO = new BasFilter()
  BAS_FAVOURITES.FILTER_VIDEO.translationId =
    BAS_FAVOURITES.FILTER_VIDEO.id = BAS_FAVOURITES.ID_T_VIDEO
  BAS_FAVOURITES.FILTER_VIDEO.order = 7

  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourceFavourites (basSource) {

    /**
     * @type {Object<string, BasFavourite>}
     */
    this.favourites = {}

    /**
     * @type {string[]}
     */
    this.local = []

    /**
     * @type {string[]}
     */
    this.radio = []

    /**
     * @type {string[]}
     */
    this.deezer = []

    /**
     * @type {string[]}
     */
    this.tidal = []

    /**
     * @type {string[]}
     */
    this.spotifyConnect = []

    /**
     * @type {string[]}
     */
    this.sonos = []

    /**
     * @type {string[]}
     */
    this.video = []

    /**
     * All favourite collections
     *
     * @type {BasCollection[]}
     */
    this.uiAll = []

    /**
     * All favourite collections with Spotify Current user entry
     *
     * @type {BasCollection[]}
     */
    this.uiAllWithSpotifyCurrent = []

    /**
     * Single collection with maximum 4 items
     *
     * @type {BasCollection[]}
     */
    this.uiQuadFavourites = []

    /**
     * @type {BasCollection[]}
     */
    this.uiLocal = []

    /**
     * @type {BasCollection[]}
     */
    this.uiRadio = []

    /**
     * @type {BasCollection[]}
     */
    this.uiDeezer = []

    /**
     * @type {BasCollection[]}
     */
    this.uiTidal = []

    /**
     * @type {BasCollection[]}
     */
    this.uiVideo = []

    /**
     * @type {BasCollection[]}
     */
    this.uiSpotifyConnectWithCurrent = []

    /**
     * @type {BasCollection[]}
     */
    this.uiSpotifyConnect = []

    /**
     * @type {BasCollection[]}
     */
    this.uiSonos = []

    /**
     * @type {BasCollection[]}
     */
    this.uiCurrent = []

    /**
     * @type {BasFilter}
     */
    this.uiCurrentFilter = BAS_FAVOURITES.FILTER_ALL

    /**
     * @type {BasFilter[]}
     */
    this.uiFilters = []

    /**
     * @type {Object<string,?Promise>}
     */
    this.loadingFilters = {}

    /**
     * @type {?string[]}
     */
    this.savedQuickFavourites = null

    /**
     * @type {boolean}
     */
    this.canAddManual = basSource
      ? basSource.isVideoSource && basSource.type === BAS_SOURCE.T_VIDEO
      : false

    /**
     * @type {BasFavourite}
     */
    this._FAV_DEEZER_FLOW =
      new BasFavourite(BAS_FAVOURITE.UUID_DEEZER_FLOW)

    /**
     * @type {BasFavourite}
     */
    this._FAV_SPOTIFY_CONNECT_CURRENT =
      new BasFavourite(BAS_FAVOURITE.UUID_SPOTIFY_CONNECT_CURRENT)

    /**
     * Holds a reference to the last received Deezer favourites
     *
     * @private
     * @type {?Array}
     */
    this.__deezerFavourites = null

    /**
     * Holds a reference to the last received TIDAL favourites
     *
     * @private
     * @type {?Array}
     */
    this.__tidalFavourites = null

    /**
     * Holds a reference to the last received Spotify Connect presets
     *
     * @private
     * @type {?Array}
     */
    this.__spotifyConnectPresets = null

    // Pagination

    /**
     * @type {boolean}
     */
    this.supportsPagination = false

    /**
     * Map that keeps track of pagination per favourite type id
     *
     * @type {Object<string, TBasFavouritePaginationInfo>}
     */
    this.pagination = {}

    /**
     * Global indicator if all items of all filters have been loaded
     *
     * @type {boolean}
     */
    this.globalPaginationAllItemsLoaded = false

    /**
     * Global indicator of combined loaded items of all filters
     *
     * @type {number}
     */
    this.globalPaginationLoadedItemCount = 0

    /**
     * Indicator of loaded item count for current filter
     *
     * @type {number}
     */
    this.paginationLoadedItemCount = 0

    /**
     * Indicator of total item count for current filter
     *
     * @type {number}
     */
    this.paginationTotalItemCount = 0

    /**
     * Indicator of whether all items of current filter have been loaded
     *
     * @type {boolean}
     */
    this.paginationAllItemsLoaded = false

    /**
     * Indicator if items for current filter are being loaded
     *
     * @type {boolean}
     */
    this.paginationIsLoading = false

    /**
     * @type {?Promise}
     */
    this._avSourceFavouritesPromise = null

    /**
     * @private
     * @type {?BasSource}
     */
    this._basSource = basSource || null

    this._listeners = []

    this.handleFavouritesChanged = this._onFavouritesChanged.bind(this)
    this.handleFavouriteAdded = this._onAVFavouriteAdded.bind(this)
    this.handleFavouriteRemoved = this._onAVFavouriteRemoved.bind(this)
    this.handleFavouriteUpdated = this._onAVFavouriteUpdated.bind(this)
    this.handleLocalPlaylistsChanged =
      this._onLocalPlaylistsChanged.bind(this)

    this.handleDeezerLinked = this._onDeezerLinked.bind(this)
    this.handleTidalLinked = this._onTidalLinked.bind(this)

    this.handleSpotifyPresetsChanged =
      this._onSpotifyPresetsChanged.bind(this)
    this.handleSpotifyClientChanged =
      this._onSpotifyClientChanged.bind(this)

    this._handleFavourites = this._onFavourites.bind(this)
    this._handleAudioFavourites = this._onAudioFavourites.bind(this)
    this._handleAudioQuickFavourites = this._onAudioQuickFavourites.bind(this)
    this._handleVideoFavourites = this._onVideoFavourites.bind(this)
    this._handleFavouritesServices = this._onFavouritesServices.bind(this)
    this._handleSpotifyPresets = this._onSpotifyPresets.bind(this)
    this._handleSpotifyUser = this._onSpotifyUser.bind(this)

    this._handleLocalPlaylists = this._onLocalPlaylists.bind(this)

    this.__syncUi = this._syncUi.bind(this)

    this._handleFavouritesUpdated = this._emitUpdated.bind(this)

    this.resume()
  }

  /**
   * Extracts all favourite UUID's from an UI array
   *
   * @private
   * @param {BasCollection[]} uiArr
   * @returns {string[]}
   */
  BasSourceFavourites._getAllFavourites = function (uiArr) {

    var result, i, length, collection

    result = []

    if (Array.isArray(uiArr)) {

      length = uiArr.length
      for (i = 0; i < length; i++) {

        collection = uiArr[i]

        if (BasUtil.isObject(collection) &&
          Array.isArray(collection.items)) {

          result = result.concat(collection.items)
        }
      }
    }

    return result
  }

  /**
   * @param {BasFilter} filter
   */
  BasSourceFavourites.prototype.setFilter = function (filter) {

    if (filter instanceof BasFilter) {

      this.uiCurrentFilter = filter

      this._syncGlobalPagination()
      this._processCurrentFilter()
    }
  }

  /**
   * Sync global pagination with pagination for current filter
   *
   * Note: it is of utmost importance that after running this function,
   *  this.globalPaginationAllItemsLoaded is correctly updated IN ALL CASES. If
   *  not, this will result in an infinite loop.
   * (There are some fail-safes in place, but still...)
   */
  BasSourceFavourites.prototype._syncGlobalPagination = function () {

    var pagination, length, i, keys, loadedCount, totalCount, isLoadingAnyFilter

    // Global pagination

    keys = Object.keys(this.pagination)
    length = keys.length

    if (length === 0) {

      // No pagination info known, reset all

      this.globalPaginationLoadedItemCount = 0
      this.globalPaginationAllItemsLoaded = false
      this.paginationAllItemsLoaded = false
      this.paginationTotalItemCount = 0
      this.paginationLoadedItemCount = 0
      this.paginationIsLoading = false

    } else {

      loadedCount = 0
      totalCount = 0
      this.globalPaginationAllItemsLoaded = true
      for (i = 0; i < length; i++) {

        loadedCount += this.pagination[keys[i]].loadedItemCount
        totalCount += this.pagination[keys[i]].totalItemCount

        if (!this.pagination[keys[i]].allItemsLoaded) {
          this.globalPaginationAllItemsLoaded = false
        }
      }
      this.globalPaginationLoadedItemCount = loadedCount

      // Update pagination variables for current filter

      pagination = this.pagination[this.uiCurrentFilter.id]

      isLoadingAnyFilter = false
      keys = Object.keys(this.loadingFilters)
      length = keys.length
      for (i = 0; i < length; i++) {

        if (this.loadingFilters[keys[i]]) {

          isLoadingAnyFilter = true
          break
        }
      }

      if (pagination) {

        // Specific filter

        this.paginationAllItemsLoaded = pagination.allItemsLoaded
        this.paginationTotalItemCount = pagination.totalItemCount
        this.paginationLoadedItemCount = pagination.loadedItemCount
        // Indicate that this page is loading if any filter is loading and not
        //  all favourites for this page have been loaded
        this.paginationIsLoading = (
          isLoadingAnyFilter &&
          !pagination.allItemsLoaded
        )

      } else {

        // All filter

        this.paginationAllItemsLoaded = this.globalPaginationAllItemsLoaded
        this.paginationTotalItemCount = totalCount
        this.paginationLoadedItemCount = loadedCount
        this.paginationIsLoading = isLoadingAnyFilter
      }
    }
  }

  BasSourceFavourites.prototype._processCurrentFilter = function () {

    if (this.uiFilters.indexOf(this.uiCurrentFilter) !== -1) {

      switch (this.uiCurrentFilter.id) {
        case BAS_FAVOURITES.ID_T_LOCAL:
          this.uiCurrent = this.uiLocal
          break
        case BAS_FAVOURITES.ID_T_RADIO:
          this.uiCurrent = this.uiRadio
          break
        case BAS_FAVOURITES.ID_T_DEEZER:
          this.uiCurrent = this.uiDeezer
          break
        case BAS_FAVOURITES.ID_T_TIDAL:
          this.uiCurrent = this.uiTidal
          break
        case BAS_FAVOURITES.ID_T_SPOTIFY:
          this.uiCurrent = this.uiSpotifyConnectWithCurrent
          break
        case BAS_FAVOURITES.ID_T_SONOS:
          this.uiCurrent = this.uiSonos
          break
        case BAS_FAVOURITES.ID_T_VIDEO:
          this.uiCurrent = this.uiVideo
          break
        default:
          this.uiCurrent = this.uiAllWithSpotifyCurrent
      }

    } else {

      this.uiCurrent = this.uiAllWithSpotifyCurrent
    }
  }

  /**
   * @param {string} uuid
   * @returns {?BasFavourite}
   */
  BasSourceFavourites.prototype.getFavourite = function (uuid) {

    var favourite = this.favourites[uuid]

    if (favourite && favourite.processLocalPlaylists) return favourite

    return null
  }

  /**
   * @param {string} alarmId
   * @param {number} [type]
   * @returns {?BasFavourite}
   */
  BasSourceFavourites.prototype.getFavouriteFromAlarmId = function (
    alarmId,
    type
  ) {
    var keys, length, i, favourite

    keys = Object.keys(this.favourites)
    length = keys.length
    for (i = 0; i < length; i++) {

      favourite = this.favourites[keys[i]]

      if (
        favourite &&
        favourite.processLocalPlaylists
      ) {

        if (BasUtil.isPNumber(type)) {

          if (favourite.type === type && favourite.alarmId === alarmId) {

            return favourite
          }

        } else {

          if (favourite.alarmId === alarmId) {

            return favourite
          }
        }
      }
    }

    return null
  }

  /**
   * @param {string} uuid Favourite UUID
   * @returns {boolean}
   */
  BasSourceFavourites.prototype.isFavourite = function (uuid) {

    return !!(this.favourites[uuid])
  }

  /**
   * Play a favourite
   *
   * @param {string} uuid
   * @param {boolean} [showModalOnError = false]
   *  Show modal when playing the
   *   favourite failed, only used when basSource.isAudioSource is true. If no
   *   reply is received within 700ms, promise will auto resolve to keep UI
   *   responsive.
   * @returns {Promise}
   */
  BasSourceFavourites.prototype.play = function (uuid, showModalOnError) {

    var favourite, spotifySource, id

    if (
      BasUtil.isNEString(uuid) &&
      this._basSource &&
      this._basSource.source
    ) {

      favourite = this.favourites[uuid]

      if (favourite) {

        if (
          this._basSource.isAudioSource ||
          this._basSource.isVideoSource
        ) {

          return this._basSource.playUri(
            favourite.sid,
            {
              showModalOnError: showModalOnError
            }
          )
        }

        switch (favourite.type) {
          case BAS_FAVOURITE.T_LOCAL_PLAYLIST:

            if (this._basSource.source.queue) {

              this._basSource.source.queue.addPlaylist(
                favourite.sid,
                BAS_API.Queue.ADD_OPTIONS.replaceNow
              )

              return Promise.resolve()
            }

            break
          case BAS_FAVOURITE.T_RADIO:

            this._basSource.source.startTuneInStream(favourite.sid)

            return Promise.resolve()
          case BAS_FAVOURITE.T_DEEZER:

            if (this._basSource.deezer &&
              this._basSource.source.queue &&
              this._basSource.deezer.isLinked()) {

              if (favourite.sid ===
                BAS_FAVOURITE.UUID_DEEZER_FLOW) {

                this._basSource.source.queue.startDeezerFlow()

                return Promise.resolve()

              } else if (favourite.contentType ===
                BAS_FAVOURITE.CT_DEEZER_PLAYLIST) {

                id = parseInt(favourite.sid, 10)

                if (BasUtil.isVNumber(id)) {

                  this._basSource.source.queue
                    .addDeezerPlaylist(
                      id,
                      BAS_API.Queue
                        .ADD_OPTIONS.replaceNow
                    )

                  return Promise.resolve()
                }

              } else if (favourite.contentType ===
                BAS_FAVOURITE.CT_DEEZER_RADIO) {

                id = parseInt(favourite.sid, 10)

                if (BasUtil.isVNumber(id)) {

                  this._basSource.source.queue
                    .startDeezerRadio(id)

                  return Promise.resolve()
                }
              }
            }

            break
          case BAS_FAVOURITE.T_TIDAL:

            if (this._basSource.tidal &&
              this._basSource.source.queue &&
              this._basSource.tidal.isLinked()) {

              if (favourite.contentType ===
                BAS_FAVOURITE.CT_TIDAL_PLAYLIST) {

                this._basSource.source.queue.addTidalPlaylist(
                  favourite.sid,
                  BAS_API.Queue.ADD_OPTIONS.replaceNow
                )

                return Promise.resolve()
              }
            }

            break
          case BAS_FAVOURITE.T_SPOTIFY_CONNECT:

            spotifySource = SourcesHelper.getSpotifySource(
              this._basSource.getId()
            )

            if (spotifySource &&
              spotifySource.source) {

              if (favourite.sid ===
                BAS_FAVOURITE.UUID_SPOTIFY_CONNECT_CURRENT) {

                spotifySource.source.play()

                return Promise.resolve()

              } else {

                // TODO Handle LOAD and ERROR events

                spotifySource.source.preset(
                  BAS_API.SpotifyBarp.PRESET_ACTION_LOAD,
                  favourite.sid
                )

                return Promise.resolve()
              }
            }

            break
        }
      }
    }

    return Promise.reject(new Error('no source'))
  }

  /**
   * @param {number} type
   * @param {?(string|number)} [id]
   * @param {?string} [name]
   * @returns {Promise}
   */
  BasSourceFavourites.prototype.add = function (
    type,
    id,
    name
  ) {
    var _this = this

    if (
      this._basSource &&
      this._basSource.source
    ) {

      if (this._basSource.isAudioSource) {

        return this._basSource.source.addFavourite(id)

      } else if (this._basSource.isVideoSource) {

        return this._basSource.source.addFavourite(name, id)

      } else {

        switch (type) {
          case BAS_FAVOURITE.T_LOCAL_PLAYLIST:

            return this._basSource.source.addFavouritePlaylist(id)

          case BAS_FAVOURITE.T_RADIO:

            return this._basSource.source.addFavouriteRadioStation(id)

          case BAS_FAVOURITE.T_DEEZER:

            return this._basSource.source.addFavouriteDeezer(id)

          case BAS_FAVOURITE.T_TIDAL:

            return this._basSource.source.addFavouriteTidal(id)

          case BAS_FAVOURITE.T_SPOTIFY_CONNECT:

            return _addSpotifyFavourite()

          default:

            return Promise.reject(BAS_FAVOURITES.ERR_INVALID_TYPE)
        }
      }
    }

    return Promise.reject(BAS_FAVOURITES.ERR_NO_SOURCE)

    function _addSpotifyFavourite () {

      var source = SourcesHelper.getSpotifySource(_this._basSource.id)

      if (source && source.source) {

        source.source.preset(BAS_API.SpotifyBarp.PRESET_ACTION_SAVE)

        return Promise.resolve()
      }

      return Promise.reject(BAS_FAVOURITES.ERR_NO_SOURCE)
    }
  }

  /**
   * Remove a favourite
   *
   * @param {string} uuid
   * @returns {Promise}
   */
  BasSourceFavourites.prototype.remove = function (uuid) {

    var _this, favourite, canRemove

    _this = this

    if (BasUtil.isNEString(uuid)) {

      canRemove = (
        this._basSource &&
        this._basSource.type === BAS_SOURCE.T_PLAYER
      )

      favourite = this.favourites[uuid]

      if (
        this._basSource.isAudioSource ||
        this._basSource.isVideoSource
      ) {

        if (this._basSource.source) {

          return this._basSource.source.removeFavourite(uuid)
        }

      } else if (favourite && canRemove) {

        switch (favourite.type) {
          case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
          case BAS_FAVOURITE.T_RADIO:
          case BAS_FAVOURITE.T_DEEZER:
          case BAS_FAVOURITE.T_TIDAL:

            if (
              canRemove &&
              this._basSource.source
            ) {

              return this._basSource.source
                .removeFavourite(favourite.name)
            }

            break
          case BAS_FAVOURITE.T_SPOTIFY_CONNECT:

            if (canRemove) {

              _removeSpotifyFavourite(favourite)
              return Promise.resolve()
            }

            break
        }
      }
    }

    return Promise.reject(new Error('invalid uuid'))

    /**
     * @private
     * @param {BasFavourite} spotifyFavourite
     */
    function _removeSpotifyFavourite (spotifyFavourite) {

      /**
       * @type {?BasSource}
       */
      var source = SourcesHelper.getSpotifySource(
        _this._basSource.id
      )

      if (source && source.source) {

        if (spotifyFavourite.sid ===
          BAS_FAVOURITE.UUID_SPOTIFY_CONNECT_CURRENT) {

          source.source.removeConnected()

        } else {

          source.source.preset(
            BAS_API.SpotifyBarp.PRESET_ACTION_CLEAR,
            spotifyFavourite.sid
          )
        }
      }
    }
  }

  /**
   * Favourites have changed
   *
   * @private
   */
  BasSourceFavourites.prototype._onFavouritesChanged = function () {

    if (this._basSource) {

      if (this._basSource.type === BAS_SOURCE.T_PLAYER &&
        BasUtil.isObject(this._basSource.source)) {

        this._basSource.source.getFavourites()
          .then(this._handleFavourites)
          .then(this.__syncUi)

      }
    }
  }

  /**
   * Favourite was added
   *
   * @private
   * @param {TAudioSourceFavourite} favourite
   */
  BasSourceFavourites.prototype._onAVFavouriteAdded = function (favourite) {

    var collection, favouriteTypeId, pagination, itemIndex, arr

    // As long as not all items are loaded, ignore favourites added events.
    if (!this.globalPaginationAllItemsLoaded) return

    if (
      favourite &&
      BasUtil.isString(favourite.uri)
    ) {

      favouriteTypeId = BasUtil.isNEString(favourite.service)
        ? this._getFavouriteTypeIdForService(favourite.service)
        : this._basSource.isVideoSource
          ? BAS_FAVOURITES.ID_T_VIDEO
          : BAS_FAVOURITES.ID_T_SONOS

      switch (favouriteTypeId) {
        case BAS_FAVOURITES.ID_T_TIDAL:
          arr = this.tidal
          break
        case BAS_FAVOURITES.ID_T_LOCAL:
          arr = this.local
          break
        case BAS_FAVOURITES.ID_T_SPOTIFY:
          arr = this.spotifyConnect
          break
        case BAS_FAVOURITES.ID_T_RADIO:
          arr = this.radio
          break
        case BAS_FAVOURITES.ID_T_DEEZER:
          arr = this.deezer
          break
        case BAS_FAVOURITES.ID_T_SONOS:
          arr = this.sonos
          break
        case BAS_FAVOURITES.ID_T_VIDEO:
          arr = this.video
          break
      }

      if (
        !arr ||
        arr.indexOf(favourite.uri) !== -1
      ) {

        // Duplicate favourite or unknown type, abort!
        return
      }

      this.favourites[favourite.uri] = new BasFavourite(
        favourite,
        {
          isAudioSourceFavourite: this._basSource.isAudioSource,
          isVideoSourceFavourite: this._basSource.isVideoSource
        }
      )

      // Update pagination (+1 counts) or create pagination if it did not yet
      //  exist

      pagination = this.pagination[favouriteTypeId]
      itemIndex = -1

      if (!pagination) {

        pagination = {
          allItemsLoaded: true,
          totalItemCount: 1,
          loadedItemCount: 1
        }

        this.pagination[favouriteTypeId] = pagination

        // Item is first item for this filter
        itemIndex = 0

      } else {

        pagination.totalItemCount++

        if (pagination.allItemsLoaded) {

          // Add to back of item list and increase loaded item count
          itemIndex = pagination.loadedItemCount++

        } else {

          // Since not all items for this category are loaded yet, we cannot
          //  add this to the back of them, just set allItemsLoaded to false
          pagination.allItemsLoaded = false
        }
      }

      if (itemIndex !== -1) {

        arr[itemIndex] = favourite.uri

        collection = this._getCollectionForType(favouriteTypeId)

        if (collection) {

          if (collection.items.indexOf(favourite.uri) === -1) {

            collection.items[itemIndex] = favourite.uri
          }
        }

        this._syncUi(false)
      }

      this._syncGlobalPagination()
      this._emitUpdated()
    }
  }

  /**
   * Favourite was removed
   *
   * @private
   * @param {Object} favourite
   */
  BasSourceFavourites.prototype._onAVFavouriteRemoved = function (favourite) {

    var i, arr, idx, uiArr, favouriteTypeId

    if (favourite && BasUtil.isNEString(favourite.uri)) {

      // Remove favourite object
      this.favourites[favourite.uri] = null

      // Loop over all arrays if array contains favourite, remove it
      for (i = 0; i < DO_LENGTH; i++) {

        switch (DEFAULT_ORDER[i]) {
          case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
            arr = this.local
            uiArr = this.uiLocal
            break
          case BAS_FAVOURITE.T_RADIO:
            arr = this.radio
            uiArr = this.uiRadio
            break
          case BAS_FAVOURITE.T_DEEZER:
            arr = this.deezer
            uiArr = this.uiDeezer
            break
          case BAS_FAVOURITE.T_TIDAL:
            arr = this.tidal
            uiArr = this.uiTidal
            break
          case BAS_FAVOURITE.T_SPOTIFY_CONNECT:
            arr = this.spotifyConnect
            uiArr = this.uiSpotifyConnectWithCurrent
            break
          case BAS_FAVOURITE.T_SONOS:
            arr = this.sonos
            uiArr = this.uiSonos
            break
          case BAS_FAVOURITE.T_VIDEO:
            arr = this.video
            uiArr = this.uiVideo
            break
        }

        // Update array

        if (arr) {

          idx = arr.indexOf(favourite.uri)

          if (idx !== -1) {

            arr.splice(idx, 1)

            favouriteTypeId = this._getFavouriteTypeIdForFavouriteType(
              DEFAULT_ORDER[i]
            )

            if (this.pagination[favouriteTypeId]) {

              this.pagination[favouriteTypeId].totalItemCount--
              this.pagination[favouriteTypeId].loadedItemCount--
            }
          }
        }

        // Find element in ui Arrays and remove it. We only need to look in the
        //  first collection in the array since it contains only 1 element.
        if (uiArr && uiArr[0]) {

          // Remove from ui collection
          idx = uiArr[0].items.indexOf(favourite.uri)

          if (idx !== -1) {

            uiArr[0].items.splice(idx, 1)

            if (uiArr[0].items.length === 0) {

              uiArr.splice(0, 1)
            }
          }
        }
      }

      this._syncGlobalPagination()
      this._syncUi()
    }
  }

  /**
   * Favourite was updated
   *
   * @private
   * @param {Object} favourite
   */
  BasSourceFavourites.prototype._onAVFavouriteUpdated = function (favourite) {

    var basFavourite

    if (favourite && BasUtil.isNEString(favourite.uri)) {

      basFavourite = this.favourites[favourite.uri]

      if (basFavourite) {

        basFavourite.parse(
          favourite,
          {
            isAudioSourceFavourite: true,
            update: true
          }
        )
        this._emitUpdated()
      }
    }
  }

  /**
   * Playlists have changed
   *
   * @private
   */
  BasSourceFavourites.prototype._onLocalPlaylistsChanged = function () {

    var length = this.local.length

    if (length > 0 &&
      this._basSource &&
      this._basSource.playlists) {

      // Retrieve playlists and their cover art
      this._basSource.playlists.retrieve()
        .then(this._handleLocalPlaylists)
        .then(this._handleFavouritesUpdated)
    }
  }

  /**
   * Retrieve favourites
   *
   * @returns {Promise}
   */
  BasSourceFavourites.prototype.retrieve = function () {

    if (this._basSource) {

      if (
        this._basSource.type === BAS_SOURCE.T_PLAYER &&
        BasUtil.isObject(this._basSource.source)
      ) {

        // Regular favourites

        this._basSource.source.getFavourites()
          .then(this._handleFavourites)
          .then(this.__syncUi)

        // Spotify Connect Presets

        this._getSpotifyConnectPresets()
          .then(this._handleSpotifyPresets)
          .then(this.__syncUi)

      } else if (
        this._basSource.isAudioSource ||
        this._basSource.isVideoSource
      ) {

        return this.retrieveAllPaginated()
      }
    }

    return Promise.reject(BAS_FAVOURITES.ERR_NOT_SUPPORTED)
  }

  /**
   * Retrieves a new page of favourites
   *
   * @param {number} [count]
   * @returns {Promise}
   */
  BasSourceFavourites.prototype.retrievePaginated = function (
    count
  ) {
    var _count

    if (this.supportsPagination) {

      // If favourites are already being retrieved, return that promise
      if (this._avSourceFavouritesPromise) {

        return this._avSourceFavouritesPromise
      }

      // If all items are loaded already: do nothing
      if (this.globalPaginationAllItemsLoaded) {

        return Promise.resolve()
      }

      if (CurrentBasCore.hasCore()) {

        _count = BasUtil.isPNumber(count, false)
          ? count
          : PAGE_FAVOURITE_COUNT

        // Request favourites
        this._avSourceFavouritesPromise = (
          this._basSource.isAudioSource
            ? this._listFavourites(_count)
              .then(this._handleAudioFavourites)
            : this._listFavourites(_count)
              .then(this._handleVideoFavourites)
        ).then(this.__syncUi)

        return this._avSourceFavouritesPromise

      } else {

        return Promise.reject(BAS_FAVOURITES.ERR_NO_CORE)
      }
    }

    return Promise.reject(BAS_FAVOURITES.ERR_NOT_SUPPORTED)
  }

  /**
   * Retrieves all favourites using paginated requests
   *
   * @param {number} [pageSize]
   * @param {number} [maxPages]
   * @returns {Promise}
   */
  BasSourceFavourites.prototype.retrieveAllPaginated = function (
    pageSize,
    maxPages
  ) {
    var _this, _count, _maxPages, pagesRetrieved

    _this = this

    pagesRetrieved = 0

    _count = BasUtil.isPNumber(pageSize, false)
      ? pageSize
      : PAGE_FAVOURITE_COUNT_MAX
    _maxPages = BasUtil.isPNumber(maxPages, false)
      ? pageSize
      : MAX_ALL_FAVOURITE_PAGE_LOOP

    return this.retrievePaginated(_count)
      .then(onRetrievePaginated)

    function onRetrievePaginated () {

      _this._syncGlobalPagination()

      if (
        !_this.globalPaginationAllItemsLoaded &&
        ++pagesRetrieved < _maxPages
      ) {

        _this.retrievePaginated(_count).then(onRetrievePaginated)
      }
    }
  }

  /**
   * List favourites, takes current filter into account
   *
   * @private
   * @param {number} count
   * @returns {Promise}
   */
  BasSourceFavourites.prototype._listFavourites = function (count) {

    var _this, promise

    _this = this

    if (Object.keys(this.pagination).length === 0) {

      // No pagination info yet, fetch first and check for each service if
      //  it has any favourites

      promise = this._getPaginationInfo()

    } else {

      promise = Promise.resolve()
    }

    return promise.then(onPaginationInfo)

    function onPaginationInfo () {

      var i, pagination, service, favouriteTypeId, filter, _promise, keys

      // At this point, 'this.pagination' should be filled.
      //
      // If only 1 pagination element (Sonos / Video) or no pagination elements:
      //  retrieve for Sonos or video based on source type

      keys = Object.keys(_this.pagination)

      if (
        keys.length === 0 ||
        (
          keys.length === 1 ||
          (
            keys[0] === BAS_FAVOURITES.ID_T_SONOS ||
            keys[0] === BAS_FAVOURITES.ID_T_VIDEO
          )
        )
      ) {

        // Use global pagination (not per type)

        if (_this._basSource && _this._basSource.source) {

          if (_this._basSource.isAudioSource) {

            return _this._basSource.source.listFavouritesLegacy(
              _this.paginationLoadedItemCount,
              count
            ).then(validateRequestedCount)

          } else if (_this._basSource.isVideoSource) {

            return _this._basSource.source.listFavourites(
              _this.paginationLoadedItemCount,
              count
            ).then(validateRequestedCount)
          }
        }

      } else {

        // Use pagination per type

        // Select service based on their pagination status
        for (i = 0; i < DO_LENGTH; i++) {

          favouriteTypeId = _this._getFavouriteTypeIdForFavouriteType(
            DEFAULT_ORDER[i]
          )

          pagination = _this.pagination[favouriteTypeId]

          if (pagination && !pagination.allItemsLoaded) {

            service = _this._getServiceForFavouriteTypeId(favouriteTypeId)
            break
          }
        }

        filter = _this._getFilterForService(service)

        _promise = BasUtil.isNEString(service)
          ? _this._basSource.source.listFavourites(
            service,
            pagination.loadedItemCount,
            count
          ).then(validateRequestedCount)
          // No service was selected, all items are loaded
          : Promise.resolve()

        if (filter) _this.loadingFilters[filter.id] = _promise

        _this._syncGlobalPagination()
        _this._emitUpdated()

        _promise.then(resetLoadingFilter)

        return _promise
      }

      function resetLoadingFilter () {

        if (filter) {

          _this.loadingFilters[filter.id] = null
        }

        _this._syncGlobalPagination()
        _this._emitUpdated()
      }

      /**
       * Validates that the amount of favourites we retrieved is complete
       *  according to the offset, total amount and requested amount. If
       *  invalid, returns Promise.reject(), else it returns the inner result
       *
       * @param {Object} result
       * @returns {Object}
       */
      function validateRequestedCount (result) {

        // Check if the received favourites count is correct when comparing
        //  with total and offset from reply
        return (
          result &&
          Array.isArray(result.list) &&
          result.list.length === Math.min(
            // Requested count
            count,
            // Available count
            result.total - result.offset
          )
        )
          ? Promise.resolve(result)
          : Promise.reject('Conflicting information in favourites data')
      }
    }
  }

  /**
   * List favourites services
   *
   * @returns {Promise}
   */
  BasSourceFavourites.prototype._getPaginationInfo = function () {

    // Use 'legacy' favourites fetching for Sonos.
    // List Asano favourites per favourite type

    if (
      CurrentBasCore.hasAVFullSupport() &&
      this._basSource &&
      this._basSource.type === BAS_SOURCE.T_ASANO &&
      this._basSource.source &&
      this._basSource.source.listFavouritesServices
    ) {
      this.paginationIsLoading = true
      this._emitUpdated()

      return this._basSource.source.listFavouritesServices()
        .then(this._handleFavouritesServices)
    }
    return Promise.resolve()
  }

  /**
   * Handle favourites services info and update this.pagination
   *
   * @param {TAudioSourceFavouritesServicesResult} services
   */
  BasSourceFavourites.prototype._onFavouritesServices = function (services) {

    var i, length, keys, total, favouriteTypeId, pagination, service

    if (services) {

      keys = Object.keys(services)
      length = keys.length
      for (i = 0; i < length; i++) {

        service = keys[i]
        total = services[service]

        favouriteTypeId = this._getFavouriteTypeIdForService(service)

        pagination = this.pagination[favouriteTypeId]

        if (pagination) {

          pagination.totalItemCount = total
          pagination.allItemsLoaded =
            pagination.totalItemCount === pagination.loadedItemCount

        } else {

          this.pagination[favouriteTypeId] = {
            allItemsLoaded: total === 0,
            totalItemCount: total,
            loadedItemCount: 0
          }
        }
      }

      this._syncUi()
    }
  }

  /**
   * Get service for favourite type
   *
   * @private
   * @param {string} favouriteType
   * @returns {string}
   */
  BasSourceFavourites.prototype._getServiceForFavouriteTypeId = function (
    favouriteType
  ) {

    switch (favouriteType) {
      case BAS_FAVOURITES.ID_T_LOCAL:
        return BAS_FAVOURITES.S_LOCAL
      case BAS_FAVOURITES.ID_T_DEEZER:
        return BAS_FAVOURITES.S_DEEZER
      case BAS_FAVOURITES.ID_T_TIDAL:
        return BAS_FAVOURITES.S_TIDAL
      case BAS_FAVOURITES.ID_T_SPOTIFY:
        return BAS_FAVOURITES.S_SPOTIFY
      case BAS_FAVOURITES.ID_T_RADIO:
        return BAS_FAVOURITES.S_TUNEIN
      case BAS_FAVOURITES.ID_T_SONOS:
        return BAS_FAVOURITES.S_SONOS
      default:
        return ''
    }
  }

  /**
   * Get favourite type for a service
   *
   * @private
   * @param {string} service
   * @returns {string}
   */
  BasSourceFavourites.prototype._getFavouriteTypeIdForService = function (
    service
  ) {

    switch (service) {
      case BAS_FAVOURITES.S_LOCAL:
        return BAS_FAVOURITES.ID_T_LOCAL
      case BAS_FAVOURITES.S_DEEZER:
        return BAS_FAVOURITES.ID_T_DEEZER
      case BAS_FAVOURITES.S_TIDAL:
        return BAS_FAVOURITES.ID_T_TIDAL
      case BAS_FAVOURITES.S_SPOTIFY:
        return BAS_FAVOURITES.ID_T_SPOTIFY
      case BAS_FAVOURITES.S_TUNEIN:
        return BAS_FAVOURITES.ID_T_RADIO
      case BAS_FAVOURITES.S_SONOS:
        return BAS_FAVOURITES.ID_T_SONOS
      default:
        return ''
    }
  }

  /**
   * Get filter for a specific service
   *
   * @private
   * @param {string} service
   * @returns {?BasFilter}
   */
  BasSourceFavourites.prototype._getFilterForService = function (service) {

    switch (service) {
      case BAS_FAVOURITES.S_LOCAL:
        return BAS_FAVOURITES.FILTER_LOCAL
      case BAS_FAVOURITES.S_DEEZER:
        return BAS_FAVOURITES.FILTER_DEEZER
      case BAS_FAVOURITES.S_TIDAL:
        return BAS_FAVOURITES.FILTER_TIDAL
      case BAS_FAVOURITES.S_SPOTIFY:
        return BAS_FAVOURITES.FILTER_SPOTIFY_CONNECT
      case BAS_FAVOURITES.S_TUNEIN:
        return BAS_FAVOURITES.FILTER_RADIO
      case BAS_FAVOURITES.S_SONOS:
        return BAS_FAVOURITES.FILTER_SONOS
      default:
        return null
    }
  }

  /**
   * Get favourite type id for favourite type
   *
   * @private
   * @param {number} favouriteType
   * @returns {string}
   */
  BasSourceFavourites.prototype._getFavouriteTypeIdForFavouriteType = function (
    favouriteType
  ) {

    switch (favouriteType) {
      case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
        return BAS_FAVOURITES.ID_T_LOCAL
      case BAS_FAVOURITE.T_DEEZER:
        return BAS_FAVOURITES.ID_T_DEEZER
      case BAS_FAVOURITE.T_TIDAL:
        return BAS_FAVOURITES.ID_T_TIDAL
      case BAS_FAVOURITE.T_SPOTIFY_CONNECT:
        return BAS_FAVOURITES.ID_T_SPOTIFY
      case BAS_FAVOURITE.T_RADIO:
        return BAS_FAVOURITES.ID_T_RADIO
      case BAS_FAVOURITE.T_SONOS:
        return BAS_FAVOURITES.ID_T_SONOS
      case BAS_FAVOURITE.T_VIDEO:
        return BAS_FAVOURITES.ID_T_VIDEO
      default:
        return ''
    }
  }

  /**
   * Get filter for favourite type
   *
   * @private
   * @param {number} favouriteType
   * @returns {?BasFilter}
   */
  BasSourceFavourites.prototype._getFilterForFavouriteType = function (
    favouriteType
  ) {

    switch (favouriteType) {
      case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
        return BAS_FAVOURITES.FILTER_LOCAL
      case BAS_FAVOURITE.T_DEEZER:
        return BAS_FAVOURITES.FILTER_DEEZER
      case BAS_FAVOURITE.T_TIDAL:
        return BAS_FAVOURITES.FILTER_TIDAL
      case BAS_FAVOURITE.T_SPOTIFY_CONNECT:
        return BAS_FAVOURITES.FILTER_SPOTIFY_CONNECT
      case BAS_FAVOURITE.T_RADIO:
        return BAS_FAVOURITES.FILTER_RADIO
      case BAS_FAVOURITE.T_SONOS:
        return BAS_FAVOURITES.FILTER_SONOS
      default:
        return null
    }
  }

  /**
   * Clears the AV favourites
   */
  BasSourceFavourites.prototype.clearAVFavourites = function () {

    this._avSourceFavouritesPromise = null
    this.pagination = {}
    this._syncGlobalPagination()
    this._clearUiFavourites()
    this._clearUiSpotifyPresets()
  }

  /**
   * Retrieves the quick favourites
   *
   * @returns {Promise}
   */
  BasSourceFavourites.prototype.retrieveQuickFavourites = function () {

    if (this._basSource && this._basSource.isAudioSource) {

      return this._basSource.source.listQuickFavourites()
        .then(this._handleAudioQuickFavourites)
    }

    return Promise.reject(BAS_FAVOURITES.ERR_NOT_SUPPORTED)
  }

  /**
   * Handles quick favourites
   *
   * @param {TAudioSourceFavourite[]} favourites
   */
  BasSourceFavourites.prototype._onAudioQuickFavourites = function (
    favourites
  ) {

    var i, length, favourite, favouriteObj, uiQuickFavourites

    if (Array.isArray(favourites)) {

      uiQuickFavourites = []

      length = favourites.length
      for (i = 0; i < length; i++) {

        favourite = favourites[i]

        if (favourite && BasUtil.isNEString(favourite.uri)) {

          // Parse favourite
          favouriteObj = new BasFavourite(
            favourite,
            { isAudioSourceFavourite: true }
          )
          this.favourites[favouriteObj.uuid] = favouriteObj

          uiQuickFavourites.push(favouriteObj.uuid)

        } else {

          uiQuickFavourites.push('')
        }
      }

      this.savedQuickFavourites = uiQuickFavourites

      if (
        BasUtil.filter(
          this.savedQuickFavourites,
          BasUtil.isNEString
        ).length
      ) {

        this._processQuad(
          this.savedQuickFavourites.slice(
            0,
            QUAD_FAVOURITES_LENGTH
          )
        )

      } else {

        // No saved favourites, generate them ourselves
        this._generateUiQuadFavourites()
      }

      this._emitUpdated()
    }
  }

  /**
   * @private
   * @returns {?BasSource}
   */
  BasSourceFavourites.prototype._getSpotifySource = function () {

    if (this._basSource) {

      return SourcesHelper.getSpotifySource(this._basSource.id)
    }

    return null
  }

  /**
   * @private
   * @returns {string}
   */
  BasSourceFavourites.prototype._getSpotifyConnectClient = function () {

    var spotifySource = this._getSpotifySource()

    if (spotifySource && spotifySource.source) {

      return spotifySource.source.clientName
    }

    return ''
  }

  /**
   * @private
   * @returns {Promise}
   */
  BasSourceFavourites.prototype._getSpotifyConnectPresets = function () {

    var spotifySource = this._getSpotifySource()

    if (spotifySource && spotifySource.source) {

      return spotifySource.source.getPresets()
    }

    return Promise.reject('Invalid player or Spotify Barp')
  }

  /**
   * @private
   * @param {Object<string, Array>} result
   */
  BasSourceFavourites.prototype._onFavourites = function (
    result
  ) {
    this._clearUiFavourites()

    if (BasUtil.isObject(result)) {

      // Local playlists

      this._processLocal(result[K_PLAYLISTS])

      // Radio

      this._processRadio(result[K_RADIOS])

      // Deezer

      this._processDeezer(result[K_DEEZER])

      // TIDAL

      this._processTidal(result[K_TIDAL])
    }

    this._cleanFavourites(CLEAN_FAVOURITES)
  }

  /**
   * @private
   * @param {TAudioSourceFavouritesResult} result
   */
  BasSourceFavourites.prototype._onAudioFavourites = function (
    result
  ) {
    var length, i, favourite, collection, list, itemIndex, pagination
    var favouriteTypeId

    this._avSourceFavouritesPromise = undefined

    // Don't clear ui favourites, audio favourites are paginated and can add
    //  to the existing list

    if (result) {

      list = result.list
      itemIndex = -1

      if (Array.isArray(list)) {

        length = list.length

        // Update pagination

        favouriteTypeId = BasUtil.isNEString(result.service)
          ? this._getFavouriteTypeIdForService(result.service)
          : BAS_FAVOURITES.ID_T_SONOS

        pagination = this.pagination[favouriteTypeId]

        if (!pagination) {

          pagination = {
            allItemsLoaded: false,
            totalItemCount: result.total,
            loadedItemCount: 0
          }
          this.pagination[favouriteTypeId] = pagination
        }

        pagination.loadedItemCount = result.offset + length
        pagination.allItemsLoaded =
          pagination.totalItemCount === pagination.loadedItemCount

        if (length > 0) {

          collection = this._getCollectionForType(favouriteTypeId)

          for (i = 0; i < length; i++) {

            favourite = new BasFavourite(
              list[i],
              { isAudioSourceFavourite: true }
            )

            this.favourites[favourite.uuid] = favourite

            itemIndex = result.offset + i

            switch (favourite.type) {
              case BAS_FAVOURITE.T_TIDAL:
                this.tidal[itemIndex] = favourite.uuid
                break
              case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
                this.local[itemIndex] = favourite.uuid
                break
              case BAS_FAVOURITE.T_SPOTIFY:
                this.spotifyConnect[itemIndex] = favourite.uuid
                break
              case BAS_FAVOURITE.T_RADIO:
                this.radio[itemIndex] = favourite.uuid
                break
              case BAS_FAVOURITE.T_DEEZER:
                this.deezer[itemIndex] = favourite.uuid
                break
              case BAS_FAVOURITE.T_SONOS:
              default:
                this.sonos[itemIndex] = favourite.uuid
                break
            }

            collection.items[itemIndex] = favourite.uuid
          }
        }

        this._syncGlobalPagination()
      }
    }
  }

  /**
   * @private
   * @param {TVideoSourceFavouritesResult} result
   */
  BasSourceFavourites.prototype._onVideoFavourites = function (
    result
  ) {
    var length, i, favourite, collection, list, itemIndex, pagination

    this._avSourceFavouritesPromise = undefined

    // Don't clear ui favourites, video favourites are paginated and can add
    //  to the existing list

    if (BasUtil.isObject(result)) {

      list = result.list
      itemIndex = -1

      if (Array.isArray(list)) {

        length = list.length

        // Update pagination

        pagination = this.pagination[BAS_FAVOURITES.ID_T_VIDEO]

        if (!pagination) {

          pagination = {
            allItemsLoaded: false,
            totalItemCount: result.total,
            loadedItemCount: 0
          }
          this.pagination[BAS_FAVOURITES.ID_T_VIDEO] = pagination
        }

        pagination.loadedItemCount = result.offset + length
        pagination.allItemsLoaded =
          pagination.totalItemCount === pagination.loadedItemCount

        for (i = 0; i < length; i++) {

          favourite = new BasFavourite(
            list[i],
            { isVideoSourceFavourite: true }
          )

          this.favourites[favourite.uuid] = favourite

          itemIndex = BasUtil.isPNumber(list[i].index, true)
            ? list[i].index
            : result.offset + i

          collection = this._getCollectionForType(BAS_FAVOURITES.ID_T_VIDEO)

          this.video[itemIndex] = favourite.uuid

          collection.items[itemIndex] = favourite.uuid
        }

        this._syncGlobalPagination()
      }
    }
  }

  /**
   * @private
   * @param {string} favouriteType
   * @returns {BasCollection}
   */
  BasSourceFavourites.prototype._getCollectionForType = function (
    favouriteType
  ) {

    var collection, ui

    if (
      CurrentBasCore.hasCore() &&
      CurrentBasCore.hasAVFullSupport()
    ) {

      switch (this._basSource.type) {
        case BAS_SOURCE.T_ASANO:

          switch (favouriteType) {
            case BAS_FAVOURITES.ID_T_TIDAL:
              ui = this.uiTidal
              break
            case BAS_FAVOURITES.ID_T_DEEZER:
              ui = this.uiDeezer
              break
            case BAS_FAVOURITES.ID_T_SPOTIFY:
              ui = this.uiSpotifyConnectWithCurrent
              break
            case BAS_FAVOURITES.ID_T_SONOS:
              ui = this.uiSonos
              break
            case BAS_FAVOURITES.ID_T_RADIO:
              ui = this.uiRadio
              break
            case BAS_FAVOURITES.ID_T_LOCAL:
              ui = this.uiLocal
              break
            default:
              ui = this.uiAll
              break
          }

          break

        case BAS_SOURCE.T_SONOS:

          // Empty, handled in sonos case at end of function
          break

        case BAS_SOURCE.T_VIDEO:

          switch (favouriteType) {
            case BAS_FAVOURITES.ID_T_VIDEO:
              ui = this.uiVideo
              break
            default:
              ui = this.uiAll
              break
          }

          break
      }

      if (ui) {
        if (ui[0]) {

          return ui[0]

        } else {

          collection = new BasCollection(favouriteType)
          collection.setId(favouriteType)

          ui.push(collection)

          return collection
        }
      }
    }

    if (this.uiSonos[0]) {

      return this.uiSonos[0]

    } else {

      collection = new BasCollection(BAS_FAVOURITES.ID_T_SONOS)
      this.uiSonos.push(collection)

      return collection
    }
  }

  /**
   * To be used in a promise chain, passes through result
   *
   * @private
   * @param {TAudioSourceFavouritesResult} result
   * @returns {TAudioSourceFavouritesResult}
   */
  BasSourceFavourites.prototype._onClearSonosFavourites = function (
    result
  ) {
    this.uiSonos = []
    return result
  }

  /**
   * @private
   * @param {Array} result
   */
  BasSourceFavourites.prototype._onSpotifyPresets = function (result) {

    this.__spotifyConnectPresets = result

    this._processSpotifyConnect()
  }

  /**
   * @private
   */
  BasSourceFavourites.prototype._onSpotifyPresetsChanged = function () {

    this._getSpotifyConnectPresets()
      .then(this._handleSpotifyPresets)
      .then(this.__syncUi)
  }

  /**
   * @private
   */
  BasSourceFavourites.prototype._onSpotifyClientChanged = function () {

    this._syncSpotifyConnectClient()

    this._checkSpotifyUser()

    this._processSpotifyConnect()
  }

  /**
   * @private
   * @param _event
   * @param {number} id
   */
  BasSourceFavourites.prototype._onSpotifyUser = function (
    _event,
    id
  ) {
    if (this._basSource && this._basSource.id === id) {

      this._checkSpotifyUser()

      this._emitUpdated()
    }
  }

  /**
   * Checks if Spotify Connected client is deletable
   *
   * @private
   */
  BasSourceFavourites.prototype._checkSpotifyUser = function () {

    var spotifySource = this._getSpotifySource()

    if (spotifySource &&
      spotifySource.spotify &&
      spotifySource.source) {

      this._FAV_SPOTIFY_CONNECT_CURRENT.cssToggleShowDelete(
        spotifySource.spotify.spotifyId !==
        spotifySource.source.clientName
      )

    } else {

      this._FAV_SPOTIFY_CONNECT_CURRENT.cssToggleShowDelete(true)
    }
  }

  /**
   * Syncs the Spotify Connect client name
   * with the dedicated Spotify Connect favourite
   *
   * @private
   */
  BasSourceFavourites.prototype._syncSpotifyConnectClient = function () {

    var _this, spotifySource

    _this = this
    spotifySource = this._getSpotifySource()

    if (spotifySource && spotifySource.spotify) {

      spotifySource.spotify.connectedNamePromise.then(onName, onNameFail)
    }

    this._checkSpotifyUser()

    function onName () {

      if (spotifySource && spotifySource.spotify) {

        _this._FAV_SPOTIFY_CONNECT_CURRENT.ui.subtitle =
          spotifySource.spotify.connectedName

        _this._emitUpdated()
      }
    }

    function onNameFail () {

      _this._FAV_SPOTIFY_CONNECT_CURRENT.ui.subtitle = ''
    }
  }

  /**
   * @private
   */
  BasSourceFavourites.prototype._processSpotifyConnect = function () {

    var clientName = this._getSpotifyConnectClient()

    if (BasUtil.isNEString(clientName)) {

      this._processSpotifyConnectPresets()

    } else {

      this._clearUiSpotifyPresets()
      this._cleanFavourites(CLEAN_SPOTIFY_PRESETS)
    }

    this._syncUi()
  }

  /**
   * Processes Spotify Connect presets, assuming Spotify Connect is "ready"
   *
   * @private
   */
  BasSourceFavourites.prototype._processSpotifyConnectPresets = function () {

    var i, length, favourite, collection, collectionWithCurrent
    var spotifySource

    this._clearUiSpotifyPresets()

    this._syncSpotifyConnectClient()

    collection = new BasCollection()
    collection.setId(BAS_FAVOURITES.COL_ID_SPOTIFY)
    collection.setTitleTranslationId(BAS_FAVOURITES.ID_T_SPOTIFY)

    collectionWithCurrent = new BasCollection()
    collectionWithCurrent.setId(BAS_FAVOURITES.COL_ID_SPOTIFY_CURRENT)
    collectionWithCurrent
      .setTitleTranslationId(BAS_FAVOURITES.ID_T_SPOTIFY)

    this.favourites[this._FAV_SPOTIFY_CONNECT_CURRENT.uuid] =
      this._FAV_SPOTIFY_CONNECT_CURRENT
    this.spotifyConnect.push(this._FAV_SPOTIFY_CONNECT_CURRENT.uuid)
    collectionWithCurrent.items.push(
      this._FAV_SPOTIFY_CONNECT_CURRENT.uuid
    )

    if (Array.isArray(this.__spotifyConnectPresets)) {

      length = this.__spotifyConnectPresets.length
      for (i = 0; i < length; i++) {

        favourite = new BasFavourite(this.__spotifyConnectPresets[i])

        if (favourite.type === BAS_FAVOURITE.T_SPOTIFY_CONNECT) {

          this.favourites[favourite.uuid] = favourite
          this.spotifyConnect.push(favourite.uuid)

          collection.items.push(favourite.uuid)
          collectionWithCurrent.items.push(favourite.uuid)

          spotifySource = SourcesHelper.getSpotifySource(
            this._basSource.id
          )

          // Only request extra favourite info if:
          // - Spotify source is valid
          // - Spotify source has a valid link url
          //   (enabled library access)
          if (spotifySource &&
            spotifySource.spotify &&
            spotifySource.spotify.getLinkUrlPath()) {

            spotifySource.spotify
              .getFavouriteInfo(favourite.sid)
              .then(favourite.handleSpotifyInfo)
              .then(this._handleFavouritesUpdated)
          }
        }
      }
    }

    if (collection.items && collection.items.length > 0) {

      this.uiSpotifyConnect.push(collection)
    }

    this.uiSpotifyConnectWithCurrent.push(collectionWithCurrent)

    this._cleanFavourites(CLEAN_SPOTIFY_PRESETS)
  }

  /**
   * @private
   * @param {boolean} [emitUpdated = true]
   */
  BasSourceFavourites.prototype._syncUi = function (emitUpdated) {

    this._generateUi()
    this._generateFilters()
    this._processCurrentFilter()

    if (emitUpdated !== false) this._emitUpdated()
  }

  /**
   * @private
   * @param {Array} arr
   */
  BasSourceFavourites.prototype._processLocal = function (arr) {

    var i, length, favourite, collection

    this.local = []
    this.uiLocal = []

    if (Array.isArray(arr)) {

      length = arr.length

      if (length > 0) {

        collection = new BasCollection()
        collection.setId(BAS_FAVOURITES.COL_ID_LOCAL)
        collection.setTitleTranslationId(BAS_FAVOURITES.ID_T_LOCAL)
      }

      for (i = 0; i < length; i++) {

        favourite = new BasFavourite(arr[i])

        if (favourite.type === BAS_FAVOURITE.T_LOCAL_PLAYLIST) {

          this.favourites[favourite.uuid] = favourite
          this.local.push(favourite.uuid)
          collection.items.push(favourite.uuid)
        }
      }

      if (length > 0) {

        if (this._basSource &&
          this._basSource.playlists) {

          // Retrieve playlists and their cover art
          this._basSource.playlists.retrieve()
            .then(this._handleLocalPlaylists)
            .then(this._handleFavouritesUpdated)
        }

        this.uiLocal.push(collection)
      }
    }
  }

  BasSourceFavourites.prototype._onLocalPlaylists = function () {

    var i, length, favourite

    if (this._basSource &&
      this._basSource.playlists) {

      length = this.local.length
      for (i = 0; i < length; i++) {

        favourite = this.favourites[this.local[i]]

        if (favourite &&
          favourite.type === BAS_FAVOURITE.T_LOCAL_PLAYLIST) {

          favourite.processLocalPlaylists(this._basSource.playlists)
            .then(this._handleFavouritesUpdated)
        }
      }
    }
  }

  /**
   * @private
   * @param {Array} arr
   */
  BasSourceFavourites.prototype._processRadio = function (arr) {

    var i, length, favourite, collection
    var promises = []

    this.radio = []
    this.uiRadio = []

    if (Array.isArray(arr)) {

      length = arr.length

      if (length > 0) {

        collection = new BasCollection()
        collection.setId(BAS_FAVOURITES.COL_ID_RADIO)
        collection.setTitleTranslationId(BAS_FAVOURITES.ID_T_RADIO)
      }

      for (i = 0; i < length; i++) {

        favourite = new BasFavourite(arr[i])

        if (favourite.type === BAS_FAVOURITE.T_RADIO) {

          this.favourites[favourite.uuid] = favourite
          this.radio.push(favourite.uuid)
          collection.items.push(favourite.uuid)

          if (this._basSource && this._basSource.tunein) {

            if (this._basSource.source &&
              this._basSource.source.dirty) {

              promises.push(
                this._basSource.source.status()
                  .then(
                    this._basSource.tunein.stationInfo.bind(
                      this._basSource.tunein,
                      favourite.sid
                    )
                  ).then(favourite.handleTuneInInfo)
              )

            } else {

              promises.push(
                this._basSource.tunein
                  .stationInfo(favourite.sid)
                  .then(favourite.handleTuneInInfo)
              )
            }
          }
        }
      }

      BasUtil.promiseAll(promises).then(this._handleFavouritesUpdated)

      if (length > 0) {

        this.uiRadio.push(collection)
      }
    }
  }

  /**
   * @private
   * @param {Array} arr
   */
  BasSourceFavourites.prototype._processDeezer = function (arr) {

    this.__deezerFavourites = arr

    this.deezer = []
    this.uiDeezer = []

    if (this._basSource &&
      this._basSource.source &&
      this._basSource.source.deezer) {

      if (this._basSource.source.deezer.dirty) {

        // "_onDeezerLinked" will handle it from here
        this._basSource.source.deezer.status()

      } else if (this._basSource.deezer &&
        this._basSource.deezer.isLinked()) {

        this._processDeezerIsLinked()
      }
    }
  }

  /**
   * Handles Deezer link changes.
   * This should only be triggered when also listening for favourites.
   *
   * @private
   */
  BasSourceFavourites.prototype._onDeezerLinked = function () {

    if (
      this._basSource &&
      this._basSource.isAudioSource
    ) {

      // We do not need to handle this event, audioSource favourites events
      //  will be sent out accordingly.
      return
    }

    if (this._basSource &&
      this._basSource.deezer &&
      this._basSource.deezer.isLinked()) {

      this._processDeezerIsLinked()

    } else {

      this.deezer = []
      this.uiDeezer = []
    }

    this._syncUi()
  }

  /**
   * Processes Deezer favourites, assuming Deezer is linked
   *
   * @private
   */
  BasSourceFavourites.prototype._processDeezerIsLinked = function () {

    var i, length, favourite, collection
    var promises = []

    this.deezer = []
    this.uiDeezer = []

    collection = new BasCollection()
    collection.setId(BAS_FAVOURITES.COL_ID_DEEZER)
    collection.setTitleTranslationId(BAS_FAVOURITES.ID_T_DEEZER)

    this.favourites[this._FAV_DEEZER_FLOW.uuid] = this._FAV_DEEZER_FLOW
    this.deezer.push(this._FAV_DEEZER_FLOW.uuid)
    collection.items.push(this._FAV_DEEZER_FLOW.uuid)

    if (Array.isArray(this.__deezerFavourites)) {

      length = this.__deezerFavourites.length
      for (i = 0; i < length; i++) {

        favourite = new BasFavourite(this.__deezerFavourites[i])

        if (favourite.type === BAS_FAVOURITE.T_DEEZER) {

          this.favourites[favourite.uuid] = favourite
          this.deezer.push(favourite.uuid)
          collection.items.push(favourite.uuid)

          if (this._basSource && this._basSource.deezer) {

            if (favourite.contentType ===
              BAS_DEEZER.FAV_TYPE_PLAYLIST) {

              promises.push(
                this._basSource.deezer
                  .getPlaylist(favourite.sid)
                  .then(favourite.handleDeezer)
              )

            } else if (favourite.contentType ===
              BAS_DEEZER.FAV_TYPE_RADIO) {

              promises.push(
                this._basSource.deezer.getRadio(favourite.sid)
                  .then(favourite.handleDeezer)
              )
            }
          }
        }
      }

      BasUtil.promiseAll(promises).then(this._handleFavouritesUpdated)
    }

    this.uiDeezer.push(collection)
  }

  /**
   * @private
   * @param {Array} arr
   */
  BasSourceFavourites.prototype._processTidal = function (arr) {

    this.__tidalFavourites = arr

    this.tidal = []
    this.uiTidal = []

    if (
      this._basSource &&
      this._basSource.source &&
      this._basSource.source.tidal
    ) {

      if (this._basSource.source.tidal.dirty) {

        // "_onTidalLinked" will handle it from here
        this._basSource.source.tidal.status()

      } else if (this._basSource.tidal &&
        this._basSource.tidal.isLinked()) {

        this._processTidalIsLinked()
      }
    }
  }

  /**
   * Handles TIDAL link changes.
   * This should only be triggered when also listening for favourites.
   *
   * @private
   */
  BasSourceFavourites.prototype._onTidalLinked = function () {

    if (
      this._basSource &&
      this._basSource.isAudioSource
    ) {

      // We do not need to handle this event, audioSource favourites events
      //  will be sent out accordingly.
      return
    }

    if (this._basSource &&
      this._basSource.tidal &&
      this._basSource.tidal.isLinked()) {

      this._processTidalIsLinked()

    } else {

      this.tidal = []
      this.uiTidal = []
    }

    this._syncUi()
  }

  /**
   * Processes Deezer favourites, assuming Deezer is linked
   *
   * @private
   */
  BasSourceFavourites.prototype._processTidalIsLinked = function () {

    var i, length, favourite, collection
    var promises = []

    this.tidal = []
    this.uiTidal = []

    if (Array.isArray(this.__tidalFavourites)) {

      length = this.__tidalFavourites.length

      if (length > 0) {

        collection = new BasCollection()
        collection.setId(BAS_FAVOURITES.COL_ID_TIDAL)
        collection.setTitleTranslationId(BAS_FAVOURITES.ID_T_TIDAL)
      }

      for (i = 0; i < length; i++) {

        favourite = new BasFavourite(this.__tidalFavourites[i])

        if (favourite.type === BAS_FAVOURITE.T_TIDAL) {

          this.favourites[favourite.uuid] = favourite
          this.tidal.push(favourite.uuid)
          collection.items.push(favourite.uuid)

          if (this._basSource && this._basSource.tidal) {

            promises.push(
              this._basSource.tidal.getPlaylist(favourite.sid)
                .then(favourite.handleTidal)
            )
          }
        }
      }

      BasUtil.promiseAll(promises).then(this._handleFavouritesUpdated)

      if (length > 0) {

        this.uiTidal.push(collection)
      }
    }
  }

  /**
   * @param {Array} uiArr
   * @private
   */
  BasSourceFavourites.prototype._processQuad = function (uiArr) {

    var collection

    if (uiArr && uiArr.length > 0) {

      this.uiQuadFavourites = []

      collection = new BasCollection(BAS_FAVOURITES.ID_T_FAVOURITES)
      collection.items = uiArr

      this.uiQuadFavourites.push(collection)
    }
  }

  /**
   * Create all UI BasCollections
   *
   * @private
   */
  BasSourceFavourites.prototype._generateUi = function () {

    var i

    this.uiAll = []
    this.uiAllWithSpotifyCurrent = []

    for (i = 0; i < DO_LENGTH; i++) {

      switch (DEFAULT_ORDER[i]) {
        case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
          if (this.uiLocal.length > 0) {

            this.uiAllWithSpotifyCurrent.push(this.uiLocal[0])
            this.uiAll.push(this.uiLocal[0])
          }
          break
        case BAS_FAVOURITE.T_RADIO:
          if (this.uiRadio.length > 0) {

            this.uiAllWithSpotifyCurrent.push(this.uiRadio[0])
            this.uiAll.push(this.uiRadio[0])
          }
          break
        case BAS_FAVOURITE.T_DEEZER:
          if (this.uiDeezer.length > 0) {

            this.uiAllWithSpotifyCurrent.push(this.uiDeezer[0])
            this.uiAll.push(this.uiDeezer[0])
          }
          break
        case BAS_FAVOURITE.T_TIDAL:
          if (this.uiTidal.length > 0) {

            this.uiAllWithSpotifyCurrent.push(this.uiTidal[0])
            this.uiAll.push(this.uiTidal[0])
          }
          break
        case BAS_FAVOURITE.T_SPOTIFY_CONNECT:
          if (this.uiSpotifyConnect.length > 0) {

            this.uiAll.push(this.uiSpotifyConnect[0])
          }

          if (this.uiSpotifyConnectWithCurrent.length > 0) {

            this.uiAllWithSpotifyCurrent
              .push(this.uiSpotifyConnectWithCurrent[0])
          }
          break
        case BAS_FAVOURITE.T_SONOS:
          if (this.uiSonos.length > 0) {

            this.uiAllWithSpotifyCurrent.push(this.uiSonos[0])
            this.uiAll.push(this.uiSonos[0])
          }
          break
        case BAS_FAVOURITE.T_VIDEO:
          if (this.uiVideo.length > 0) {

            this.uiAllWithSpotifyCurrent.push(this.uiVideo[0])
            this.uiAll.push(this.uiVideo[0])
          }
          break
      }
    }

    if (
      this._basSource &&
      this._basSource.isAudioSource &&
      currentBasCoreState.core.core.supportsQuickFavourites &&
      (
        !this.savedQuickFavourites ||
        this.savedQuickFavourites.filter(BasUtil.isNEString).length
      )
    ) {

      // Do nothing, quad favourites are set (or generated) when we receive
      //  the quick favourites

    } else {

      // Only generate quad favourites if this source is not an audio-source,
      //  custom quad favourites are not supported, or custom quad favourites
      //  are loaded but empty
      this._generateUiQuadFavourites()
    }
  }

  /**
   * Create the uiQuadFavourites
   *
   * @private
   */
  BasSourceFavourites.prototype._generateUiQuadFavourites = function () {

    var i, collection, quadFavourites, uiArr

    this.uiQuadFavourites = []

    quadFavourites = []

    for (i = 0; i < DO_LENGTH; i++) {

      uiArr = null

      switch (DEFAULT_ORDER[i]) {
        case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
          uiArr = this.uiLocal
          break
        case BAS_FAVOURITE.T_RADIO:
          uiArr = this.uiRadio
          break
        case BAS_FAVOURITE.T_DEEZER:
          uiArr = this.uiDeezer
          break
        case BAS_FAVOURITE.T_TIDAL:
          uiArr = this.uiTidal
          break
        case BAS_FAVOURITE.T_SPOTIFY_CONNECT:
          uiArr = this.uiSpotifyConnectWithCurrent
          break
        case BAS_FAVOURITE.T_SONOS:
          uiArr = this.uiSonos
          break
      }

      if (uiArr) _addToQuadFavourites()
    }

    if (quadFavourites.length > 0) {

      collection = new BasCollection()
      collection.setId(BAS_FAVOURITES.COL_ID_QUAD)
      collection.setTitleTranslationId(BAS_FAVOURITES.ID_T_FAVOURITES)
      collection.items = quadFavourites

      this.uiQuadFavourites.push(collection)
    }

    /**
     * @private
     */
    function _addToQuadFavourites () {

      var quadLength, diff, favourites, length

      // How many favourites are needed to fill quad favourites
      quadLength = quadFavourites.length
      diff = QUAD_FAVOURITES_LENGTH - quadLength

      if (diff > 0) {

        favourites = BasSourceFavourites._getAllFavourites(uiArr)
        length = favourites.length

        if (length > 0) {

          // Slice creates new array with shallow copy
          quadFavourites = quadFavourites
            .concat(favourites.slice(0, diff))
        }
      }
    }
  }

  /**
   * Generates filters for uiAllWithSpotifyCurrent
   *
   * @private
   */
  BasSourceFavourites.prototype._generateFilters = function () {

    var i, favouriteTypeId, filters

    this.uiFilters = []
    filters = []

    for (i = 0; i < DO_LENGTH; i++) {

      // Based on pagination total
      favouriteTypeId = this._getFavouriteTypeIdForFavouriteType(
        DEFAULT_ORDER[i]
      )

      if (this.pagination[favouriteTypeId]) {

        if (this.pagination[favouriteTypeId].totalItemCount > 0) {

          // Pagination says there are items, add filter and skip collections
          //  check
          filters.push(this._getFilterForFavouriteType(DEFAULT_ORDER[i]))
          continue
        }
      }

      // Based on uiCollection
      switch (DEFAULT_ORDER[i]) {
        case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
          if (this.uiLocal.length > 0) {

            filters.push(BAS_FAVOURITES.FILTER_LOCAL)
          }
          break
        case BAS_FAVOURITE.T_RADIO:
          if (this.uiRadio.length > 0) {

            filters.push(BAS_FAVOURITES.FILTER_RADIO)
          }
          break
        case BAS_FAVOURITE.T_DEEZER:
          if (this.uiDeezer.length > 0) {

            filters.push(BAS_FAVOURITES.FILTER_DEEZER)
          }
          break
        case BAS_FAVOURITE.T_TIDAL:
          if (this.uiTidal.length > 0) {

            filters.push(BAS_FAVOURITES.FILTER_TIDAL)
          }
          break
        case BAS_FAVOURITE.T_SPOTIFY_CONNECT:
          if (this.uiSpotifyConnectWithCurrent.length > 0) {

            filters.push(BAS_FAVOURITES.FILTER_SPOTIFY_CONNECT)
          }
          break
        case BAS_FAVOURITE.T_SONOS:
          if (this.uiSonos.length > 0) {

            filters.push(BAS_FAVOURITES.FILTER_SONOS)
          }
          break
        case BAS_FAVOURITE.T_VIDEO:
          if (this.uiVideo.length > 0) {

            filters.push(BAS_FAVOURITES.FILTER_VIDEO)
          }
          break
      }
    }

    // Only visualize and add 'all' filter if there is more than 1 filter
    if (filters.length > 1) {

      filters.splice(0, 0, BAS_FAVOURITES.FILTER_ALL)
      this.uiFilters = filters
    }

    if (this.uiFilters.indexOf(this.uiCurrentFilter) === -1) {

      this.setFilter(BAS_FAVOURITES.FILTER_ALL)
    }
  }

  BasSourceFavourites.prototype.updateTranslation = function () {

    _collectionsUpdateTranslation(this.uiLocal)
    _collectionsUpdateTranslation(this.uiRadio)
    _collectionsUpdateTranslation(this.uiDeezer)
    _collectionsUpdateTranslation(this.uiTidal)
    _collectionsUpdateTranslation(this.uiSpotifyConnect)
    _collectionsUpdateTranslation(this.uiSpotifyConnectWithCurrent)
    _collectionsUpdateTranslation(this.uiSonos)
    _collectionsUpdateTranslation(this.uiQuadFavourites)

    function _collectionsUpdateTranslation (collections) {

      var length, i, collection

      length = collections.length
      for (i = 0; i < length; i++) {

        collection = collections[i]

        if (collection && collection.updateTranslation) {

          collection.updateTranslation()
        }
      }
    }
  }

  /**
   * Clear the UI favourites
   *
   * @private
   */
  BasSourceFavourites.prototype._clearUiFavourites = function () {

    this.local = []
    this.radio = []
    this.deezer = []
    this.tidal = []
    this.sonos = []

    this.uiLocal = []
    this.uiRadio = []
    this.uiDeezer = []
    this.uiTidal = []
    this.uiSonos = []

    this.pagination = {}
  }

  /**
   * Cleans the favourites. Only favourite UUIDs,
   * that are mentioned in one of the favourite collections,
   * are kept around.
   *
   * @private
   * @param {string} type
   */
  BasSourceFavourites.prototype._cleanFavourites = function (type) {

    var newFavourites, keys, i, length, favourite

    newFavourites = {}

    keys = Object.keys(this.favourites)
    length = keys.length
    for (i = 0; i < length; i++) {

      favourite = this.favourites[keys[i]]

      if (favourite instanceof BasFavourite) {

        if (type === CLEAN_FAVOURITES) {

          if (favourite.type === BAS_FAVOURITE.T_SPOTIFY_CONNECT ||
            this._isShownFavourite(favourite.uuid)) {

            // Copy favourite
            newFavourites[favourite.uuid] = favourite
          }

        } else if (type === CLEAN_SPOTIFY_PRESETS) {

          if (favourite.type !== BAS_FAVOURITE.T_SPOTIFY_CONNECT ||
            this._isShownFavourite(favourite.uuid)) {

            // Copy favourite
            newFavourites[favourite.uuid] = favourite
          }
        }
      }
    }

    this.favourites = newFavourites
  }

  BasSourceFavourites.prototype._clearUiSpotifyPresets = function () {

    this.spotifyConnect = []
    this.uiSpotifyConnectWithCurrent = []
    this.uiSpotifyConnect = []
  }

  /**
   * Checks if the favourite UUID is part of any favourite collection
   *
   * @private
   * @param {string} uuid
   * @returns {boolean}
   */
  BasSourceFavourites.prototype._isShownFavourite = function (uuid) {

    if (this.local.indexOf(uuid) !== -1) return true
    if (this.radio.indexOf(uuid) !== -1) return true
    if (this.deezer.indexOf(uuid) !== -1) return true
    if (this.tidal.indexOf(uuid) !== -1) return true
    if (this.sonos.indexOf(uuid) !== -1) return true
    return this.spotifyConnect.indexOf(uuid) !== -1
  }

  BasSourceFavourites.prototype._emitUpdated = function () {

    $rootScope.$emit(
      BAS_SOURCE.EVT_FAVOURITES_UPDATED,
      this._basSource
    )
  }

  BasSourceFavourites.prototype._clearListeners = function () {

    BasUtil.executeArray(this._listeners)
    this._listeners = []
  }

  BasSourceFavourites.prototype._clearPromises = function () {

    this.loadingFilters = {}
  }

  BasSourceFavourites.prototype.resume = function () {

    this.supportsPagination = (
      this._basSource &&
      (
        this._basSource.isAudioSource ||
        this._basSource.isVideoSource
      )
    )

    this._clearListeners()

    this._listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_SPOTIFY_USER_UPDATED,
      this._handleSpotifyUser
    ))
  }

  BasSourceFavourites.prototype.suspend = function () {

    this._clearListeners()
  }

  BasSourceFavourites.prototype.destroy = function destroy () {

    this._clearListeners()
    this._clearPromises()

    this._clearUiFavourites()
    this._clearUiSpotifyPresets()

    this._basSource = null
  }

  return BasSourceFavourites
}
