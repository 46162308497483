'use strict'

var BasUtil = require('@basalte/bas-util')

var BasServer = require('./bas_server')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var BasCoreSocketWeb = require('./bas_core_socket_web')
var BasHost = require('./bas_host')

var requestUtil = require('./request_util')

/**
 * @constructor
 * @extends BasServer
 * @param {(string|BasHost)} host
 * @param {TBasServerOptions} [options]
 * @since 3.0.0
 */
function BasHttpServer (host, options) {

  BasServer.call(this, options)

  if (BasUtil.isString(host)) {

    this._host = new BasHost(host)

  } else if (host instanceof BasHost) {

    this._host = host
  }

  this._socket = new BasCoreSocketWeb(
    this._host,
    CONSTANTS.PATH_WS_MUSIC
  )
  this._v2Socket = new BasCoreSocketWeb(
    this._host,
    CONSTANTS.PATH_WS_API,
    false
  )

  /**
   * @private
   * @type {function(RequestJs.RequestJsResultType): TBasServerResponse}
   */
  this._handleRequest = this._onRequest.bind(this)
}

BasHttpServer.prototype = Object.create(BasServer.prototype)
BasHttpServer.prototype.constructor = BasHttpServer

/**
 * @param {TBasServerRequestConfig} config
 * @returns {Promise<TBasServerResponse>}
 */
BasHttpServer.prototype.request = function (config) {

  var _config

  if (this._host) {

    if (BasUtil.isObject(config)) {

      _config = {}

      _config.url = this._host.getHTTPUrl(config.path)
      _config.method = config.method || 'GET'
      _config.json = true

      if (BasUtil.isObject(config.params)) {

        _config.params = config.params
      }

      if (BasUtil.isObject(config.headers)) {

        _config.headers = config.headers
      }

      if (BasUtil.isPNumber(config.timeout)) {

        _config.timeout = config.timeout
      }

      if (BasUtil.safeHasOwnProperty(config, 'data')) {

        _config.data = JSON.stringify(config.data)
      }

      return requestUtil.request(_config).then(this._handleRequest)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  return Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)
}

/**
 * @private
 * @param {RequestJs.RequestJsResultType} result
 * @returns {TBasServerResponse}
 */
BasHttpServer.prototype._onRequest = function (result) {

  var _httpResponse

  _httpResponse = {}

  _httpResponse.status = 0
  _httpResponse.headers = null
  _httpResponse.data = undefined

  if (BasUtil.isObject(result)) {

    _httpResponse.status = result.status
    _httpResponse.headers = requestUtil.parseHeaders(result.headers)
    _httpResponse.apiVersion = parseInt(
      _httpResponse.headers[P.H_X_BASALTE_API],
      10
    )
    _httpResponse.data = result.data
  }

  return _httpResponse
}

/**
 * @param {TBasServerRequestConfig} config
 * @returns {Promise<TBasServerResponse>}
 */
BasHttpServer.prototype.requestProxy = function (config) {

  var _data, _config

  if (this.isConnected()) {

    _config = BasServer.parseBasServerRequestConfig(config)

    if (_config) {

      _data = {}
      _data[P.REQUEST] = _config

      return this._socket.request(
        _data,
        BasServer.PROXY_REQUEST_TIMEOUT_MS
      ).then(BasServer.handleRequestProxy)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @param {string} path
 * @returns {Promise<string>}
 */
BasHttpServer.prototype.requestImageSource = function (path) {

  if (BasUtil.isNEString(path)) {

    if (BasUtil.hasValidKnownScheme(path)) return Promise.resolve(path)

    return this._host
      ? Promise.resolve(this._host.getHTTPUrl(path))
      : Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {(TCoreCredentials|Array<TCoreCredentials>)} credentials
 * @returns {Promise}
 */
BasHttpServer.prototype.connectToCore = function (credentials) {

  var wsPromise, _credentials, _altCredentials

  if (Array.isArray(credentials)) {

    _credentials = credentials[0]
    _altCredentials = credentials[1]

  } else {

    _credentials = _altCredentials = credentials
  }

  wsPromise = this._socket
    ? this._socket.open(_credentials)
    : Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)

  if (this._useSubscriptionSocket && this._v2Socket) {
    this._v2Socket.open(_altCredentials)
  }

  return wsPromise
}

/**
 * @returns {Promise}
 */
BasHttpServer.prototype.disconnectFromCore = function () {

  if (this._v2Socket) this._v2Socket.close()

  return this._socket
    ? this._socket.close()
    : Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @returns {boolean}
 */
BasHttpServer.prototype.isConnected = function () {

  return true
}

/**
 * @returns {boolean}
 */
BasHttpServer.prototype.isCoreConnected = function () {

  return this._socket ? this._socket.isConnected() : false
}

/**
 * Creates a clone of this BasServer
 *
 * @returns {BasHttpServer}
 */
BasHttpServer.prototype.clone = function () {

  var _options

  _options = {}

  if (this._macN) _options.macAddress = this._macN
  if (this._cid) _options.cid = this._cid
  if (BasUtil.isBool(this._useSubscriptionSocket)) {

    _options.useSubscriptionSocket = this._useSubscriptionSocket
  }

  return new BasHttpServer(this._host, _options)
}

module.exports = BasHttpServer
