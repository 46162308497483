'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('weatherCtrl', [
    '$scope',
    '$rootScope',
    'BAS_WEATHER',
    'Weather',
    weatherCtrl
  ])

/**
 * @param $scope
 * @param $rootScope
 * @param {BAS_WEATHER} BAS_WEATHER
 * @param {Weather} Weather
 */
function weatherCtrl (
  $scope,
  $rootScope,
  BAS_WEATHER,
  Weather
) {
  var weather = this

  var listeners = []

  /**
   * @type {TWeatherState}
   */
  weather.weather = Weather.get()

  init()

  function init () {

    Weather.update()

    $scope.$on('$destroy', cleanUp)

    listeners.push($rootScope.$on(
      BAS_WEATHER.EVT_WEATHER_STATION_STATE_UPDATED,
      _syncScope
    ))
  }

  function _syncScope () {

    $scope.$applyAsync()
  }

  function cleanUp () {

    BasUtil.executeArray(listeners)
    listeners = []
  }

  // Bug on ios 9.3.5: when orientation changes the 'wind-arrow' in the wind
  // compass doesn't recalculate the transform-origin css property.
  // After rotation the arrow is misplaced. By reassigning the
  // transform-origin a different value it recalculates it's position.
  // Possible solution, every time orientation changes, change
  // transform-origin between 49.9999% and 50.0001% (value needs to be
  // different each time for this fix to work). E.g. run this code on
  // angularJs 'orientationchange' event.
  //       document.getElementsByClassName("wind-arrow")[0]
  //           .style.transformOrigin = booleanHelperVar?'50.0001%':'49.999%';
  // This bug might be because we are transforming group elements within an
  // svg element.
}
