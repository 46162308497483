'use strict'

angular
  .module('basalteApp')
  .directive('basScenes', [
    'BAS_HTML',
    basScenesDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basScenes
 */
function basScenesDirective (
  BAS_HTML
) {
  return {
    restrict: 'A',
    scope: {},
    template: BAS_HTML.scenes,
    controller: 'scenesCtrl',
    controllerAs: 'scenes',
    bindToController: {
      room: '<'
    }
  }
}
