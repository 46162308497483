'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var DspModule = require('./dsp')
var Barp = require('./barp')
var Player = require('./player')
var ExternalSource = require('./external')
var BluetoothSource = require('./bluetooth')

/**
 * The class representing a Zone
 *
 * @constructor
 * @extends DspModule
 * @param {Object} cfg The zone configuration
 * @param {string} cfg.id The id of the zone
 * @param {string} cfg.name The name of the zone
 * @param {string} cfg.floor The floor of the zone
 * @param {string} cfg.building The floor of the zone
 * @param {number} cfg.order The order of appearance
 * @param {number} cfg.source The source of the zone
 * @param {number} cfg.volume The volume of the zone
 * @param {boolean} cfg.muted The mute state of the zone
 * @param {(string[]|null)} [cfg.group] IDs if this is a group
 * @param {string[]} cfg.tags The tags associated with this zone
 * @param {boolean} cfg.dspSupport Boolean to indicate DSP can be modified
 * @param {number} [cfg.bass] Bass [-12, +12]
 * @param {number} [cfg.treble] Treble [-12, +12]
 * @param {number} [cfg.startupVolume] Startup volume
 * @param {BasCore} basCore
 * @since 0.0.1
 */
function Zone (cfg, basCore) {
  // Call "super" constructor
  DspModule.call(this, DspModule.INSTANCE_TYPE_ZONE, basCore)

  this._id = cfg[P.ID]
  this._name = BasUtil.isNEString(cfg[P.NAME]) ? cfg[P.NAME] : ''
  this._building = BasUtil.isNEString(cfg[P.BUILDING])
    ? cfg[P.BUILDING]
    : ''
  this._floor = BasUtil.isNEString(cfg[P.FLOOR])
    ? cfg[P.FLOOR]
    : ''
  this._tags = Array.isArray(cfg[P.TAGS]) ? cfg[P.TAGS] : []
  this._order = BasUtil.isPNumber(cfg[P.ORDER], true)
    ? cfg[P.ORDER]
    : Zone.DEFAULT_ORDER

  this._group = Array.isArray(cfg[P.GROUP])
    ? cfg[P.GROUP]
    : undefined

  this._source = cfg[P.SOURCE]
  this._volume = CONSTANTS.convertFromServerVolume(cfg[P.VOLUME])
  this._muted = cfg[P.MUTED]

  this._hasSettings =
    BasUtil.isNumber(cfg[P.BASS]) ||
    BasUtil.isNumber(cfg[P.TREBLE]) ||
    BasUtil.isNumber(cfg[P.STARTUP_VOLUME])

  this._bass = BasUtil.isNumber(cfg[P.BASS])
    ? cfg[P.BASS]
    : undefined
  this._treble = BasUtil.isNumber(cfg[P.TREBLE])
    ? cfg[P.TREBLE]
    : undefined
  this._startupVolume = BasUtil.isNumber(cfg[P.STARTUP_VOLUME])
    ? CONSTANTS.convertFromServerVolume(cfg[P.STARTUP_VOLUME])
    : undefined

  this._audioOutputs = Array.isArray(cfg[P.AUDIO_OUTPUTS])
    ? cfg[P.AUDIO_OUTPUTS]
    : []
  this._audioOutputsDirty = true
  this._basCore = basCore
  this._dspSupport = cfg[P.DSP_SUPPORT] === true

}

// Inheritance
Zone.prototype = Object.create(DspModule.prototype)
Zone.prototype.constructor = Zone

// region Events

/**
 * Fired when the source for this zone has changed
 *
 * @event Zone#EVT_SOURCE
 * @param {number} src The id of the new source
 */

/**
 * Fired when the volume for this zone has changed
 *
 * @event Zone#EVT_VOLUME
 * @param {number} vol The new volume for this zone, -1 if mixed
 */

/**
 * Fired when the mute status for this zone has changed
 *
 * @event Zone#EVT_MUTED
 * @param {boolean} mute Indicating that at least one zone is not muted
 */

/**
 * Fired when the bass changes
 *
 * @event Zone#EVT_BASS
 * @param {number} bass
 * @since 1.9.0
 */

/**
 * Fired when the treble changes
 *
 * @event Zone#EVT_TREBLE
 * @param {number} treble
 * @since 1.9.0
 */

/**
 * Fired when the startup volume changes
 *
 * @event Zone#EVT_STARTUP_VOLUME
 * @param {number} startupVolume
 * @since 1.9.0
 */

// endregion

/**
 * @constant {string}
 */
Zone.EVT_SOURCE = 'source'

/**
 * @constant {string}
 */
Zone.EVT_VOLUME = 'volume'

/**
 * @constant {string}
 */
Zone.EVT_MUTED = 'muted'

/**
 * @constant {string}
 */
Zone.EVT_BASS = 'bass'

/**
 * @constant {string}
 */
Zone.EVT_TREBLE = 'treble'

/**
 * @constant {string}
 */
Zone.EVT_STARTUP_VOLUME = 'startupVolume'

/**
 * @constant {number}
 */
Zone.DEFAULT_ORDER = 4294967290

// region Properties

/**
 * The id of the current zone
 *
 * @name Zone#id
 * @type {string}
 * @readonly
 */
Object.defineProperty(Zone.prototype, 'id', {
  get: function () {
    return this._id
  }
})

/**
 * The name of the current zone
 *
 * @name Zone#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(Zone.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * The floor of the current zone
 *
 * @name Zone#floor
 * @type {string}
 * @readonly
 */
Object.defineProperty(Zone.prototype, 'floor', {
  get: function () {
    return this._floor
  }
})

/**
 * The building of the current zone
 *
 * @name Zone#building
 * @type {string}
 * @readonly
 * @since 1.1.0
 */
Object.defineProperty(Zone.prototype, 'building', {
  get: function () {
    return this._building
  }
})

/**
 * The tags associated with this Zone
 *
 * @name Zone#tags
 * @type {string[]}
 * @readonly
 * @since 0.1.0
 */
Object.defineProperty(Zone.prototype, 'tags', {
  get: function () {
    return this._tags
  }
})

/**
 * The order of the current zone
 *
 * @name Zone#order
 * @type {number}
 * @readonly
 * @since 1.1.0
 */
Object.defineProperty(Zone.prototype, 'order', {
  get: function () {
    return this._order
  }
})

/**
 * The child zones of this zone when this zone is a group.
 *
 * @name Zone#group
 * @type {(string[] | null)}
 * @readonly
 */
Object.defineProperty(Zone.prototype, 'group', {
  get: function () {
    return this._group
  }
})

/**
 * The source ID of this zone.
 * Setting this will trigger a message to the basCore which
 * in turn will result in a {@link Zone#EVT_SOURCE} event
 *
 * If the zone is a group and the child zones have mixed sources
 * this value will be equal to -1
 *
 * @name Zone#sourceID
 * @type {number}
 * @since 1.1.0
 */
Object.defineProperty(Zone.prototype, 'sourceID', {
  get: function () {
    return this._source
  },
  set: function (newSourceId) {

    if (BasUtil.isNumber(newSourceId) && newSourceId !== this._source) {

      this._source = newSourceId
      this.emit(Zone.EVT_SOURCE, this._source)
    }

    // Always send source update if a group has a child which is turned off
    this.updateSource()
  }
})

/**
 * The source of this zone.
 * Setting this will trigger a message to the basCore which
 * in turn will result in a {@link Zone#EVT_SOURCE} event
 *
 * Get:
 * * Can be an Barp or Player source, verify with 'instanceof'
 * * If the zone has no source connected this value will be 0
 * * If the zone is a group and the child zones have mixed sources this value
 * will be equal to -1
 * * If you don't have permission to control the source, this value will be
 * undefined
 *
 * Set:
 * * Connect a source by giving an instance of Barp or Player
 * * Turn off the zone by setting 0
 *
 * @name Zone#source
 * @type {(Barp|Player|number|undefined)}
 */
Object.defineProperty(Zone.prototype, 'source', {
  get: function () {
    return this._basCore.sourceForId(this._source)
  },
  set: function (newSource) {

    var newSourceId, currentSource, changed

    changed = false

    if (newSource instanceof ExternalSource ||
      newSource instanceof BluetoothSource ||
      newSource instanceof Barp) {

      newSourceId = newSource.id

    } else {

      newSourceId = newSource
    }

    if (BasUtil.isVNumber(newSourceId) && newSourceId !== this._source) {

      this._source = newSourceId
      changed = true

    } else if (newSource instanceof Player) {

      newSourceId = newSource.id

      currentSource = this._basCore.sourceForId(newSourceId)

      if (currentSource instanceof Barp) {

        // Stop the Barp first if connected
        currentSource.stop()
        changed = true
      }

      if (newSourceId !== this._source) {

        this._source = newSourceId
        changed = true
      }
    }

    // Emit event in case of changes for early UI update
    // (or mock if KNX is not available)
    if (changed) {

      this.emit(Zone.EVT_SOURCE, this._source)
    }

    // Always send source update if a group has a child which is turned off
    this.updateSource()
  }
})

/**
 * The mute status of this zone.
 * Setting this will trigger a message to the basCore which
 * in turn will result in a {@link Zone#EVT_MUTED} event
 *
 * If the zone is a group and the child zones have mixed mute statuses
 * this value will be equal to false if at least one child zone is not muted
 * otherwise true
 *
 * @name Zone#muted
 * @type {boolean}
 */
Object.defineProperty(Zone.prototype, 'muted', {
  get: function () {
    return this._muted
  },
  set: function (muted) {

    this._muted = muted === true
    this.updateMute()
    this.emit(Zone.EVT_MUTED, this._muted)
  }
})

/**
 * The volume of this zone.
 * Setting this will trigger a message to the basCore which
 * in turn will result in a {@link Zone#EVT_VOLUME} event
 *
 * If the zone is a group and the child zones have mixed volumes
 * this value will be equal to -1
 *
 * Range is [0-100]
 *
 * @name Zone#volume
 * @type {number}
 */
Object.defineProperty(Zone.prototype, 'volume', {
  get: function () {
    return this._volume
  },
  set: function (volume) {

    if (BasUtil.isPNumber(volume, true)) {

      this._volume = volume
      this.updateVolume()
      this.emit(Zone.EVT_VOLUME, this._volume)
    }
  }
})

/**
 * Whether this zone has any configurable parameters
 *
 * @name Zone#hasSettings
 * @type {boolean}
 * @since 1.9.0
 */
Object.defineProperty(Zone.prototype, 'hasSettings', {
  get: function () {
    return this._hasSettings
  }
})

/**
 * @name Zone#bass
 * @type {number}
 * @since 1.9.0
 */
Object.defineProperty(Zone.prototype, 'bass', {
  get: function () {
    return this._bass
  },
  set: function (value) {

    var data

    this._bass = value

    data = {}
    data[P.BASS] = this._bass

    this.update(data)
  }
})

/**
 * @name Zone#treble
 * @type {number}
 * @since 1.9.0
 */
Object.defineProperty(Zone.prototype, 'treble', {
  get: function () {
    return this._treble
  },
  set: function (value) {

    var data

    this._treble = value

    data = {}
    data[P.TREBLE] = this._treble

    this.update(data)
  }
})

/**
 * @name Zone#startupVolume
 * @type {number}
 * @since 1.9.0
 */
Object.defineProperty(Zone.prototype, 'startupVolume', {
  get: function () {
    return this._startupVolume
  },
  set: function (value) {

    var data

    this._startupVolume = value

    data = {}
    data[P.STARTUP_VOLUME] =
      CONSTANTS.convertToServerVolume(this._startupVolume)

    this.update(data)
  }
})

/**
 * Audio outputs for this zone
 *
 * @name Zone#audioOutputs
 * @type {string[]}
 * @since 1.9.0
 */
Object.defineProperty(Zone.prototype, 'audioOutputs', {
  get: function () {
    return this._audioOutputs
  }
})

// region Methods

/**
 * Parse a message from the connected basCore
 *
 * @param {Object} obj The object which we received from the basCore
 */
Zone.prototype.parse = function (obj) {

  var data, newVolume

  if (BasUtil.isObject(obj) &&
    (
      (BasUtil.isObject(obj[P.ZONE])) ||
      (this.isGroup() && BasUtil.isObject(obj[P.GROUP]))
    )) {

    data = this.isGroup() ? obj[P.GROUP] : obj[P.ZONE]

    if (data[P.ID] === this._id) {

      if (BasUtil.isNumber(data[P.VOLUME])) {

        newVolume = CONSTANTS.convertFromServerVolume(
          data[P.VOLUME]
        )

        if (newVolume !== this._volume) {

          this._volume = newVolume
          this.emit(Zone.EVT_VOLUME, this._volume)
        }
      }

      if (BasUtil.isNumber(data[P.SOURCE])) {

        this._source = data[P.SOURCE]

        // Always emit because Barp could have stopped
        this.emit(Zone.EVT_SOURCE, this._source)
      }

      if (BasUtil.isBool(data[P.MUTED])) {

        if (data[P.MUTED] !== this._muted) {
          this._muted = data[P.MUTED]
          this.emit(Zone.EVT_MUTED, this._muted)
        }
      }

      if (BasUtil.isNumber(data[P.BASS])) {

        if (data[P.BASS] !== this._bass) {

          this._bass = data[P.BASS]
          this.emit(Zone.EVT_BASS, this._bass)
        }
      }

      if (BasUtil.isNumber(data[P.TREBLE])) {

        if (data[P.TREBLE] !== this._treble) {

          this._treble = data[P.TREBLE]
          this.emit(Zone.EVT_TREBLE, this._treble)
        }
      }

      if (BasUtil.isNumber(data[P.STARTUP_VOLUME])) {

        newVolume = CONSTANTS.convertFromServerVolume(
          data[P.STARTUP_VOLUME]
        )

        if (newVolume !== this._startupVolume) {

          this._startupVolume = newVolume
          this.emit(Zone.EVT_STARTUP_VOLUME, this._startupVolume)
        }
      }

      if (BasUtil.isObject(data[P.DSP])) {

        this.parseDsp(data[P.DSP])
      }
    }
  }
}

/**
 * This will emit an EVT_SOURCE event.
 *
 * Primarily used to let a Zone know that its source has changed
 * but not necessarily its source (CobraNet) ID.
 *
 * Example: Barp takes over Player on same CobraNet ID
 *
 * @param {number} sourceId
 * @since 1.7.0
 */
Zone.prototype.notifySourceChange = function (sourceId) {

  this.emit(Zone.EVT_SOURCE, sourceId)
}

/**
 * Boolean indicating if the Zone is a group
 *
 * @returns {boolean}
 * @since 1.0.0
 */
Zone.prototype.isGroup = function () {

  return Array.isArray(this._group)
}

/**
 * Send an update of the current zone to the basCore
 *
 * @private
 * @param {Object} obj
 * @since 1.2.0
 */
Zone.prototype.update = function (obj) {

  var data

  obj.id = this._id
  obj.name = this._name

  data = {}

  if (this.isGroup()) {

    data[P.GROUP] = obj

  } else {

    data[P.ZONE] = obj
  }

  this._basCore.send(data)
}

/**
 * Send an update for the current source
 *
 * @private
 * @since 1.2.0
 */
Zone.prototype.updateSource = function () {

  var data

  data = {}
  data[P.SOURCE] = this._source

  this.update(data)
}

/**
 * Send an update for the current volume
 *
 * @private
 * @since 1.2.0
 */
Zone.prototype.updateVolume = function () {

  var data

  data = {}
  data[P.VOLUME] = CONSTANTS.convertToServerVolume(this._volume)

  this.update(data)
}

/**
 * Send an update for the current mute state
 *
 * @private
 * @since 1.2.0
 */
Zone.prototype.updateMute = function () {

  var data

  data = {}
  data[P.MUTED] = this._muted

  this.update(data)
}

/**
 * Retrieves the Audio Outputs (if not retrieved already)
 *
 * @since 1.9.0
 * @returns {Promise}
 */
Zone.prototype.retrieveAudioOutputs = function () {

  var _this, data

  _this = this

  if (this._basCore.supportsDescribeZone) {

    if (this._audioOutputsDirty) {

      data = {}
      data[P.ZONE] = {}
      data[P.ZONE][P.ID] = this._id
      data[P.ZONE][P.DESCRIBE_ZONE] = true

      return this._basCore.requestRetry(data).then(_onResult)
    }

    return Promise.resolve(this._audioOutputs)
  }

  return Promise.reject(CONSTANTS.ERR_UNSUPPORTED)

  function _onResult (result) {

    if (BasUtil.isObject(result) &&
      BasUtil.isObject(result[P.ZONE]) &&
      result[P.ZONE][P.ID] === _this._id &&
      Array.isArray(result[P.ZONE][P.AUDIO_OUTPUTS])) {

      _this._audioOutputs = result[P.ZONE][P.AUDIO_OUTPUTS]
      _this._audioOutputsDirty = false

      return _this._audioOutputs
    }

    return Promise.reject(CONSTANTS.ERR_RESULT)
  }
}

// endregion

/**
 * @since 2.0.0
 */
Zone.prototype.destroy = function () {

  this._basCore = null
  this.removeAllListeners()
}

module.exports = Zone
