'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('contextModalCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'UI_HELPER',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_FAVOURITE',
    'BAS_LIBRARY',
    'BAS_SPLASH',
    'CurrentRoom',
    'Sources',
    'SourcesHelper',
    'LibraryState',
    'TidalHelper',
    'LocalHelper',
    'DeezerHelper',
    'SpotifyHelper',
    'BasFavouritesHelper',
    'DeezerElement',
    'TidalElement',
    'modalHelperService',
    'BasUtilities',
    'Logger',
    'close',
    'event',
    contextModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {UI_HELPER} UI_HELPER
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {CurrentRoom} CurrentRoom
 * @param {Sources} Sources
 * @param {SourcesHelper} SourcesHelper
 * @param {LibraryState} LibraryState
 * @param {TidalHelper} TidalHelper
 * @param {LocalHelper} LocalHelper
 * @param {DeezerHelper} DeezerHelper
 * @param {SpotifyHelper} SpotifyHelper
 * @param {BasFavouritesHelper} BasFavouritesHelper
 * @param DeezerElement
 * @param TidalElement
 * @param modalHelperService
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @param close
 * @param event
 */
function contextModalCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  UI_HELPER,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_FAVOURITE,
  BAS_LIBRARY,
  BAS_SPLASH,
  CurrentRoom,
  Sources,
  SourcesHelper,
  LibraryState,
  TidalHelper,
  LocalHelper,
  DeezerHelper,
  SpotifyHelper,
  BasFavouritesHelper,
  DeezerElement,
  TidalElement,
  modalHelperService,
  BasUtilities,
  Logger,
  close,
  event
) {
  var modal = this
  var controllerName = 'contextModalCtrl'

  // Display variables - Button Target HTML element class
  var basButtonClass = 'modal-target'

  // Time in ms to wait for closing context
  var CLOSE_DELAY_MS = 500

  var _closeModalTimeoutId = 0

  var _destroyed = false
  var _listeners = []

  var playerId = -1
  var libTypeHelper = null

  // Modal properties
  modal.properties = {
    roomSettings: false,
    libShortcut: false,
    ctxDeezer: false,
    deezerFavourite: false,
    ctxTidal: false,
    tidalFavourite: false,
    ctxSpotify: false,
    hasSpotifyContext: false,
    isFavorite: false,
    showPlaylists: false
  }

  // Set modal style
  modal.style = modalHelperService.getModalStyle()

  // New playlist name
  modal.newPlaylistName = ''

  /**
   * @type {BasRooms}
   */
  modal.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BAS_SOURCES}
   */
  modal.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {BAS_FAVOURITE}
   */
  modal.BAS_FAVOURITE = BAS_FAVOURITE

  /**
   * @type {TCurrentRoomState}
   */
  modal.currentRoom = CurrentRoom.get()

  /**
   * @type {string}
   */
  modal.favouriteUuid = ''

  modal.closeModal = closeModal
  modal.onModalReady = onModalReady
  modal.openRoomSettings = openRoomSettings
  modal.toggleDeezerFavourite = toggleDeezerFavourite
  modal.toggleTidalFavourite = toggleTidalFavourite
  modal.toggleSpotifyPreset = toggleSpotifyPreset
  modal.goToArtist = goToArtist
  modal.goToAlbum = goToAlbum
  modal.libShortcut = libShortcut
  modal.goPlaylists = goPlaylists
  modal.back = back
  modal.addToPlaylist = addToPlaylist
  modal.addToNewPlaylist = addToNewPlaylist

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      UI_HELPER.EVT_RESIZE,
      _onResize
    ))

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SOURCE_CHANGED,
      _onSource
    ))

    _listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_CURRENT_SONG,
      _onCurrentSong
    ))

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    setLibTypeHelper()

    // Set modal properties based on type
    modalType()
    _syncFavouriteUuid()
  }

  function _onResize () {

    closeModal()
  }

  /**
   * @private
   * @param _event
   * @param {string} arg Room UUID
   */
  function _onSource (_event, arg) {

    // TODO Do not depend on state
    //  => Directive and share controller with bindings?
    if (arg === $uiRouterGlobals.params.room) {

      closeModal()
    }
  }

  /**
   * @private
   * @param _event
   * @param {string} arg BasSource
   */
  function _onCurrentSong (_event, arg) {

    var currentBasSource

    // TODO Do not depend on state/CurrentRoom
    //  => Directive and share controller with bindings?

    currentBasSource = CurrentRoom.getBasSource()

    if (currentBasSource && arg && currentBasSource.isSame(arg)) {

      closeModal()
    }
  }

  function _onSplashVisibility () {

    closeModal()
  }

  function openRoomSettings () {

    var roomMusic

    roomMusic = CurrentRoom.getRoomMusic()

    if (roomMusic) roomMusic.openDSPConfig()

    closeModal()
  }

  function setLibTypeHelper () {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (basSource) {

      if (basSource.isPlayingLocal) {

        libTypeHelper = LocalHelper

      } else if (basSource.isPlayingDeezer) {

        libTypeHelper = DeezerHelper

      } else if (basSource.isPlayingTidal) {

        libTypeHelper = TidalHelper

      } else if (basSource.isPlayingSpotify) {

        libTypeHelper = SpotifyHelper
      }
    }
  }

  function goToArtist () {

    libShortcut(BAS_LIBRARY.SHORTCUT_ARTIST)
  }

  function goToAlbum () {

    libShortcut(BAS_LIBRARY.SHORTCUT_ALBUM)
  }

  function libShortcut (type) {

    var basSource, currentSong

    basSource = CurrentRoom.getBasSource()

    if (basSource) {

      if (basSource.isPlayingLocal) {

        if (basSource.source && basSource.source.currentSong) {

          LibraryState.setPlayer(basSource.getId())

          currentSong = basSource.source.currentSong

          if (
            currentSong && (
              currentSong.album ||
              currentSong.artist ||
              currentSong.contextArtistUri ||
              currentSong.contextAlbumUri
            )
          ) {

            CurrentRoom.go(STATES.MUSIC_LIBRARY_LOCAL, {
              type: type,
              album: encodeURIComponent(
                currentSong.album && currentSong.album.name
                  ? currentSong.album.name
                  : currentSong.album
              ),
              artist: encodeURIComponent(currentSong.artist),
              albumUri: encodeURIComponent(currentSong.contextAlbumUri),
              artistUri: encodeURIComponent(currentSong.contextArtistUri)
            })
          }
        }

      } else if (basSource.isPlayingDeezer) {

        LibraryState.setPlayer(basSource.getId())

        CurrentRoom.go(STATES.MUSIC_LIBRARY_DEEZER, {
          type: type,
          id: basSource.state.currentSongId
        })

      } else if (basSource.isPlayingTidal) {

        LibraryState.setPlayer(basSource.getId())

        CurrentRoom.go(STATES.MUSIC_LIBRARY_TIDAL, {
          type: type,
          id: basSource.state.currentSongId
        })

      } else if (basSource.isPlayingSpotify) {

        LibraryState.setPlayer(basSource.getId())

        CurrentRoom.go(STATES.MUSIC_LIBRARY_SPOTIFY, {
          type: type,
          id: basSource.state.currentSongId
        })
      }
    }
  }

  function goPlaylists () {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (basSource) {

      LibraryState.setPlayer(basSource.getId())

      if (libTypeHelper && libTypeHelper.getPlaylists) {

        modal.properties.showPlaylists = true
        libTypeHelper.getPlaylists().then(_onPlaylists)

        calcHeight()
      }
    }
  }

  function _onPlaylists (playlists) {

    if (_destroyed) return

    if (Array.isArray(playlists)) {

      modal.playlists = playlists
      calcHeight()

      $scope.$applyAsync()
    }
  }

  function back () {

    modal.playlists = []
    modal.properties.showPlaylists = false

    calcHeight()
  }

  function addToPlaylist (playlist) {

    var basSource, currentSong

    basSource = CurrentRoom.getBasSource()

    if (basSource &&
      playlist &&
      libTypeHelper &&
      libTypeHelper.addToPlaylist) {

      if (basSource.isPlayingLocal) {

        if (basSource.source && basSource.source.currentSong) {

          currentSong = basSource.source.currentSong

          libTypeHelper.addToPlaylist(
            playlist.id,
            [currentSong.uri ? currentSong.uri : currentSong.file]
          )
        }

      } else {

        if (basSource.state) {

          libTypeHelper.addToPlaylist(
            playlist.id,
            [
              basSource.isPlayingSpotify
                ? basSource.state.currentSongUri
                : basSource.state.currentSongId
            ]
          )
        }
      }
    }

    closeModal()
  }

  function addToNewPlaylist () {

    var basSource, currentSong

    basSource = CurrentRoom.getBasSource()

    if (basSource &&
      libTypeHelper &&
      libTypeHelper.addToNewPlaylist) {

      if (basSource.isPlayingLocal) {

        if (basSource.source && basSource.source.currentSong) {

          currentSong = basSource.source.currentSong

          libTypeHelper.addToNewPlaylist(
            modal.newPlaylistName,
            [currentSong.uri ? currentSong.uri : currentSong.file]
          )
        }

      } else {

        if (basSource.state) {

          libTypeHelper.addToNewPlaylist(
            modal.newPlaylistName,
            [
              basSource.isPlayingSpotify
                ? basSource.state.currentSongUri
                : basSource.state.currentSongId
            ]
          )
        }
      }
    }

    closeModal()
  }

  /**
   * @param {boolean} isFavourite
   */
  function toggleDeezerFavourite (isFavourite) {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (BasUtil.isBool(isFavourite)) {

      if (basSource && basSource.state) {

        if (BasUtil.isNEString(basSource.state.currentSongId)) {

          if (basSource.deezer) {

            basSource.deezer.addRemoveDeezerFavouriteId(
              basSource.state.currentSongId,
              DeezerElement.TYPE_TRACK,
              isFavourite
            ).then(_onDeezerFavouriteChanged)
          }

        } else {

          Logger.warn(
            controllerName + ' - toggleDeezerFavourite - Invalid currentSongId',
            basSource.state.currentSongId
          )
        }
      }

    } else {

      Logger.warn(
        controllerName + ' - toggleDeezerFavourite - Invalid favourite status',
        typeof isFavourite,
        isFavourite
      )
    }

    _delayedClose()
  }

  /**
   * @param {boolean} isFavourite
   */
  function toggleTidalFavourite (isFavourite) {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (BasUtil.isBool(isFavourite)) {

      if (basSource && basSource.state) {

        if (BasUtil.isNEString(basSource.state.currentSongId)) {

          TidalHelper.addRemoveFavouriteId(
            basSource.state.currentSongId,
            TidalElement.TYPE_TRACK,
            isFavourite
          ).then(_onTidalFavouriteChanged)

        } else {

          Logger.warn(
            controllerName + ' - toggleDeezerFavourite - Invalid currentSongId',
            basSource.state.currentSongId
          )
        }
      }

    } else {

      Logger.warn(
        controllerName + ' - toggleDeezerFavourite - Invalid favourite status',
        typeof isFavourite,
        isFavourite
      )
    }

    _delayedClose()
  }

  function toggleSpotifyPreset () {

    var basSource, source

    basSource = CurrentRoom.getBasSource()

    if (basSource && basSource.isPlayingSpotify) {

      source = basSource.isAudioSource
        ? basSource
        : SourcesHelper.getPlayer(basSource.id)

      if (
        source &&
        source.favourites &&
        modal.favouriteUuid
      ) {

        // Check favourite state
        if (
          source.favourites.favourites &&
          source.favourites.favourites[modal.favouriteUuid]
        ) {

          source.favourites.remove(modal.favouriteUuid)

        } else {

          source.favourites.add(
            BAS_FAVOURITE.T_SPOTIFY_CONNECT,
            modal.favouriteUuid
          )
        }
      }
    }

    _delayedClose()
  }

  function modalType () {

    var roomMusic, basSource

    roomMusic = CurrentRoom.getRoomMusic()
    basSource = CurrentRoom.getBasSource()

    if (basSource) {

      playerId = basSource.getId()

      Sources.registerFor(
        BAS_SOURCE.COL_EVT_FAVOURITES,
        playerId
      )
    }

    if (roomMusic && roomMusic.hasSettings()) {

      modal.properties.roomSettings = true
    }

    // Check basSource subType
    if (basSource) {

      if (basSource.isPlayingLocal) {

        modal.properties.libShortcut = true
        calcHeight()

      } else if (basSource.isPlayingDeezer) {

        modal.properties.ctxDeezer = true
        modal.properties.ctxTidal = false
        modal.properties.ctxSpotify = false

        if (basSource && basSource.deezer && basSource.deezer.linked) {

          basSource.deezer.linked().then(_onDeezerLinked)
        }

      } else if (basSource.isPlayingTidal) {

        // Set modal type
        modal.properties.ctxDeezer = false
        modal.properties.ctxTidal = true
        modal.properties.ctxSpotify = false

        if (basSource && basSource.tidal && basSource.tidal.linked) {

          basSource.tidal.linked().then(_onTidalLinked)
        }

      } else if (basSource.isPlayingSpotify) {

        // Set modal type
        modal.properties.ctxDeezer = false
        modal.properties.ctxTidal = false
        modal.properties.ctxSpotify = true

        if (basSource && basSource.spotify && basSource.spotify.linked) {

          basSource.spotify.linked().then(_onSpotifyLinked)
        }
      }
    }

    // Modal position relative to button
    modalHelperService.calcPosition(
      event,
      basButtonClass,
      modalHelperService.type.aboveLeft
    )
  }

  function _onSpotifyLinked (result) {

    var basSource

    if (_destroyed) return

    if (result === true) {

      basSource = CurrentRoom.getBasSource()

      if (basSource && basSource.state) {

        modal.properties.libShortcut = BasUtil.isNEString(
          basSource.state.currentSongId
        )

        modal.properties.hasSpotifyContext = BasUtil.isNEString(
          basSource.state.currentSongContext
        )

        calcHeight()

        $scope.$applyAsync()
      }
    }
  }

  function _onDeezerLinked (result) {

    var basSource, songId

    if (_destroyed) return

    if (result === true) {

      modal.properties.libShortcut = true

      basSource = CurrentRoom.getBasSource()

      if (
        basSource &&
        basSource.deezer &&
        basSource.deezer.checkFavouriteStatus &&
        basSource.state
      ) {

        if (BasUtil.isNEString(basSource.state.currentSongId)) {

          songId = parseInt(basSource.state.currentSongId)

          if (BasUtil.isPNumber(songId, true)) {

            // Check status
            basSource.deezer.checkFavouriteStatus(
              songId,
              DeezerElement.TYPE_TRACK
            ).then(_onDeezerFavouriteState)

          } else {
            Logger.warn(
              controllerName + ' - Invalid current song Deezer ID',
              basSource.state.currentSongId
            )
          }
        }
      }

      calcHeight()

      $scope.$applyAsync()
    }
  }

  function _onTidalLinked (result) {

    var basSource, songId, state

    if (_destroyed) return

    if (result === true) {

      modal.properties.libShortcut = true

      basSource = CurrentRoom.getBasSource()

      if (basSource && basSource.state) {

        state = basSource.state

        if (BasUtil.isNEString(state.currentSongId)) {

          songId = parseInt(state.currentSongId)

          if (BasUtil.isPNumber(songId, true)) {

            // Check status
            TidalHelper.checkFavourite(
              TidalElement.TYPE_TRACK,
              songId,
              basSource
            ).then(_onTidalFavouriteState)

          } else {
            Logger.warn(
              controllerName + ' - Invalid current song Tidal ID',
              state.currentSongId
            )
          }
        }
      }

      calcHeight()

      $scope.$applyAsync()
    }
  }

  function getNumberOfItems () {

    var result, basSource

    if (modal.properties.showPlaylists === true &&
      Array.isArray(modal.playlists)) {

      // Extra 2 items are the new playlist input and back button
      return modal.playlists.length + 2
    }

    result = 0

    basSource = CurrentRoom.getBasSource()

    // Increment with 1 to account for room settings button
    if (modal.properties.roomSettings) result += 1

    // Increment with 3 for:
    // Show artist button
    // Show album button
    // Add to playlist button
    if (modal.properties.libShortcut) result += 3

    if (basSource) {

      if (basSource.isPlayingDeezer) {

        if (modal.properties.ctxDeezer) {

          // Increment with 1 to account for Deezer title item
          result += 1
        }

        if (modal.properties.deezerFavourite) {

          // Increment with 1 to account for Deezer favourite item
          result += 1
        }

      } else if (basSource.isPlayingTidal) {

        if (modal.properties.ctxTidal) {

          // Increment with 1 to account for Tidal title item
          result += 1
        }

        if (modal.properties.tidalFavourite) {

          // Increment with 1 to account for Tidal favourite item
          result += 1
        }
      } else if (basSource.isPlayingSpotify) {

        if (modal.properties.ctxSpotify) {

          // Increment with 2 for:
          // Spotify title item
          // Spotify Asano favourite item
          result += 2
        }

        if (modal.properties.hasSpotifyContext) {

          // Increment with 1 to account for Spotify context
          result += 1
        }
      }
    }

    return result
  }

  function calcHeight () {

    var basSource

    if (_destroyed) return

    basSource = CurrentRoom.getBasSource()

    if (basSource) {

      if (
        basSource.isPlayingLocal ||
        basSource.isPlayingTidal ||
        basSource.isPlayingDeezer
      ) {

        modalHelperService.calcHeight(
          1,
          getNumberOfItems()
        )

      } else if (basSource.isPlayingSpotify) {

        modalHelperService.calcHeight(
          3,
          getNumberOfItems()
        )
      }
    }
  }

  function _onDeezerFavouriteState (result) {

    if (_destroyed) return

    if (BasUtil.isBool(result)) modal.properties.isFavorite = result

    modal.properties.deezerFavourite = true

    calcHeight()

    $scope.$applyAsync()
  }

  function _onTidalFavouriteState (result) {

    if (_destroyed) return

    if (BasUtil.isBool(result)) modal.properties.isFavorite = result

    modal.properties.tidalFavourite = true

    calcHeight()

    $scope.$applyAsync()
  }

  function _onDeezerFavouriteChanged () {

    if (_destroyed) return

    modal.properties.isFavorite = !modal.properties.isFavorite

    $scope.$applyAsync()
  }

  function _onTidalFavouriteChanged () {

    if (_destroyed) return

    modal.properties.isFavorite = !modal.properties.isFavorite

    $scope.$applyAsync()
  }

  function onModalReady () {

    if (_destroyed) return

    BasUtilities.waitFrames(5).then(_onFramesAfterReady)
  }

  function _onFramesAfterReady () {

    if (_destroyed) return

    calcHeight()

    $scope.$applyAsync()
  }

  function _syncFavouriteUuid () {

    var basSource, player

    modal.favouriteUuid = ''

    basSource = CurrentRoom.getBasSource()

    if (basSource) {

      if (basSource.isPlayingSpotify) {

        player = SourcesHelper.getPlayer(basSource.id)

        if (basSource.isAudioSource) {

          if (
            basSource.favourites &&
            basSource.state &&
            basSource.state.currentSongContextUri
          ) {

            modal.favouriteUuid = basSource.state.currentSongContextUri
          }

        } else if (
          player &&
          player.favourites &&
          basSource.state &&
          basSource.state.currentSongContextUri
        ) {

          modal.favouriteUuid = BasFavouritesHelper.getUuid(
            BAS_FAVOURITE.T_SPOTIFY_CONNECT,
            basSource.state.currentSongContextUri
          )
        }
      }
    }
  }

  function _clearCloseModalTimeout () {

    clearTimeout(_closeModalTimeoutId)
    _closeModalTimeoutId = 0
  }

  function _delayedClose () {

    // Make sure previous close was cancelled
    _clearCloseModalTimeout()

    // Set new delay
    _closeModalTimeoutId = setTimeout(closeModal, CLOSE_DELAY_MS)
  }

  function closeModal () {

    _onDestroy()

    // Close the modal
    close()
  }

  function _onDestroy () {

    _destroyed = true

    _clearCloseModalTimeout()

    if (BasUtil.isPNumber(playerId) || BasUtil.isNEString(playerId)) {

      Sources.unregisterFor(
        BAS_SOURCE.COL_EVT_FAVOURITES,
        playerId
      )
    }

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
