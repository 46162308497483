'use strict'

var BasUtil = require('@basalte/bas-util')
var Topic = require('./topic')
var Device = require('./device')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var log = require('./logger')

/**
 * @typedef {Object} TAudioAlertEventPlay
 * @property {string} name
 * @property {string} uuid
 * @property {number} repeat
 * @property {boolean} playAudio
 * @property {number} [duration]
 * @property {string} [audio]
 */

/**
 * Class representing audio alert topic
 *
 * @constructor
 * @extends Topic
 * @param {BasCore} basCore
 */
function AudioAlertTopic (basCore) {

  Topic.call(this)

  this.topic = P.AUDIO_ALERT

  this._basCore = basCore
}

AudioAlertTopic.prototype = Object.create(Topic.prototype)
AudioAlertTopic.prototype.constructor = AudioAlertTopic

/**
 * @constant {string}
 */
AudioAlertTopic.EVT_AUDIO_ALERT_PLAY = 'evtAudioAlertTopicAudioAlertPlay'

/**
 * @constant {string}
 */
AudioAlertTopic.EVT_AUDIO_ALERT_STOP = 'evtAudioAlertTopicAudioAlertStop'

/**
 * Parse an audio alert message
 *
 * @param {Object} msg
 */
AudioAlertTopic.prototype.parse = function (msg) {

  var data, _this

  _this = this

  if (
    msg &&
    msg[P.DATA] &&
    BasUtil.isNEString(msg[P.DATA][P.ACTION])
  ) {

    data = msg[P.DATA]

    switch (data[P.ACTION]) {

      case P.PLAY:

        _processEventPlay()
        break

      case P.STOP:

        _processEventStop()
        break

      default:

        log.warn('Unknown audioAlert event: ', msg)
        break
    }
  }

  function _processEventPlay () {

    /**
     * @type {TAudioAlertEventPlay}
     */
    var evt

    if (
      BasUtil.isNEString(data[P.NAME]) &&
      BasUtil.isVNumber(data[P.REPEAT]) &&
      BasUtil.isNEString(data[P.UUID])
    ) {

      evt = {
        uuid: data[P.UUID],
        repeat: data[P.REPEAT],
        name: data[P.NAME],
        playAudio: data[P.PLAY_AUDIO] !== false
      }

      if (BasUtil.isNEString(data[P.AUDIO])) evt.audio = data[P.AUDIO]
      if (BasUtil.isPNumber(data[P.DURATION])) evt.duration = data[P.DURATION]

      _this.emit(AudioAlertTopic.EVT_AUDIO_ALERT_PLAY, evt)

    } else {

      log.warn('Invalid \'play\' event:', data)
    }
  }

  function _processEventStop () {

    if (BasUtil.isNEString(data[P.UUID])) {

      _this.emit(
        AudioAlertTopic.EVT_AUDIO_ALERT_STOP,
        data[P.UUID],
        data[P.REASON] === P.INTERRUPTED
      )

    } else {

      log.warn('Invalid \'stop\' event:', data)
    }
  }
}

/**
 * Stop an ongoing audio alert
 *
 * @param {string} audioAlertUuid
 * @returns {Promise}
 */
AudioAlertTopic.prototype.stopAudioAlert = function (audioAlertUuid) {

  var data

  if (BasUtil.isNEString(audioAlertUuid)) {

    data = {}
    data[P.ACTION] = P.STOP
    data[P.UUID] = audioAlertUuid

    return this._request(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Send audio alert api message
 *
 * @param {Object} data
 * @protected
 * @returns {Promise}
 */
AudioAlertTopic.prototype._request = function (data) {

  var msg

  msg = {}
  msg[P.TOPIC] = P.AUDIO_ALERT
  msg[P.DATA] = data

  return this._basCore.requestV2(msg)
    .then(Device.handleResponse)
}

module.exports = AudioAlertTopic
