'use strict'

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var BasServer = require('./bas_server')

var BasDataChannel = require('./bas_data_channel')
var BasCoreSocketRtcProxy = require('./bas_core_socket_rtc_proxy')
var BasCoreSocketRtcCore = require('./bas_core_socket_rtc_core')

var log = require('./logger')

/**
 * @constructor
 * @extends BasServer
 * @param {string} cid
 * @param {boolean} [useSubscriptionSocket = false]
 * @since 3.0.0
 */
function BasRemoteServer (cid, useSubscriptionSocket) {

  BasServer.call(
    this,
    {
      cid: cid,
      useSubscriptionSocket: useSubscriptionSocket
    }
  )

  /**
   * @private
   * @type {?RTCPeerConnection}
   */
  this._peerConnection = null

  /**
   * @private
   * @type {Array}
   */
  this._peerConnectionListeners = []

  /**
   * @private
   * @type {RTCIceCandidate[]}
   */
  this._iceCandidates = []

  /**
   * @private
   * @type {boolean}
   */
  this._iceGatheringFinished = false

  /**
   * @private
   * @type {?BasDataChannel}
   */
  this._proxyChannel = null

  /**
   * @private
   * @type {?BasDataChannel}
   */
  this._coreChannel = null

  /**
   * @private
   * @type {Array}
   */
  this._proxyChannelListeners = []

  /**
   * @private
   * @type {Array}
   */
  this._coreChannelListeners = []

  /**
   * @private
   * @type {BasCoreSocketRtcProxy}
   */
  this._proxySocket = new BasCoreSocketRtcProxy(this, CONSTANTS.PATH_WS_MUSIC)

  /**
   * @private
   * @type {BasCoreSocketRtcCore}
   */
  this._socket = new BasCoreSocketRtcCore(this, CONSTANTS.PATH_WS_MUSIC)

  /**
   * Tracks whether there is a "valid" session for Core channel
   * The Core channel should always stay connected as long as necessary.
   * Login information needs to be send over the Core channel.
   * Otherwise the Core channel is not authenticated with a profile.
   *
   * @private
   * @type {boolean}
   */
  this._isCoreConnected = false

  /**
   * @private
   * @type {?Promise}
   */
  this._openCoreChannelPromise = null

  this._handleConnectionStateChange =
    this._onConnectionStateChange.bind(this)
  this._handleIceConnectionStateChange =
    this._onIceConnectionStateChange.bind(this)
  this._handleIceCandidate = this._onIceCandidate.bind(this)
  this._handleIceCandidateError = this._onIceCandidateError.bind(this)
  this._handleOffer = this._onOffer.bind(this)
  this._handleOfferError = this._onOfferError.bind(this)
  this._handleSetLocalDescription = this._onSetLocalDescription.bind(this)
  this._handleSetLocalDescriptionError =
    this._onSetLocalDescriptionError.bind(this)

  this._handleProxyChannelConnected =
    this._onProxyChannelConnected.bind(this)
  this._handleProxyChannelMessage = this._onProxyChannelMessage.bind(this)
  this._handleCoreChannelConnected = this._onCoreChannelConnected.bind(this)
  this._handleCoreChannelMessage = this._onCoreChannelMessage.bind(this)

  this._handleOpenCoreSocket = this._onOpenCoreSocket.bind(this)

  this._handleOpenCoreChannel = this._onOpenCoreChannel.bind(this)
  this._handleOpenCoreChannelErrpr = this._onOpenCoreChannelError.bind(this)
}

BasRemoteServer.prototype = Object.create(BasServer.prototype)
BasRemoteServer.prototype.constructor = BasRemoteServer

/**
 * @event BasRemoteServer#EVT_ICE_CANDIDATE
 * @param {RTCIceCandidate|null} iceCandidate
 */

/**
 * @event BasRemoteServer#EVT_REMOTE_PROXY_CONNECTED
 * @param {boolean} isRemoteProxyConnected
 */

/**
 * @event BasRemoteServer#EVT_REMOTE_CORE_CONNECTED
 * @param {boolean} isRemoteCoreConnected
 */

/**
 * @constant {string}
 */
BasRemoteServer.EVT_ICE_CANDIDATE = 'evtBasRemoteIceCandidate'

/**
 * @constant {string}
 */
BasRemoteServer.EVT_REMOTE_PROXY_CONNECTED = 'evtBasRemoteServerProxyConnected'

/**
 * @constant {string}
 */
BasRemoteServer.EVT_REMOTE_CORE_CONNECTED = 'evtBasRemoteServerCoreConnected'

/**
 * @param {string} cid
 * @param {RTCConfiguration} rtcConfig
 * @param {boolean} useSubscriptionSocket
 * @returns {Promise<BasRemoteServer>}
 */
BasRemoteServer.build = function (
  cid,
  rtcConfig,
  useSubscriptionSocket
) {

  var _basRemoteServer

  _basRemoteServer = new BasRemoteServer(cid, useSubscriptionSocket)

  return _basRemoteServer.create(rtcConfig)
}

/**
 * @param {RTCConfiguration} rtcConfig
 * @returns {Promise<BasRemoteServer>}
 */
BasRemoteServer.prototype.create = function (rtcConfig) {

  this._peerConnection = new RTCPeerConnection(rtcConfig)

  this._setPeerConnectionListeners()

  this._proxyChannel = new BasDataChannel(
    this._peerConnection.createDataChannel('proxy'),
    'proxy'
  )

  this._setProxyChannelListeners()

  this._coreChannel = new BasDataChannel(
    this._peerConnection.createDataChannel('music'),
    'music'
  )

  this._setCoreChannelListeners()

  return this._peerConnection.createOffer().then(
    this._handleOffer,
    this._handleOfferError
  )
}

/**
 * @returns {?RTCSessionDescription}
 */
BasRemoteServer.prototype.getLocalDescription = function () {

  return this._peerConnection
    ? this._peerConnection.localDescription
    : null
}

/**
 * @returns {RTCIceCandidate[]}
 */
BasRemoteServer.prototype.getCurrentIceCandidates = function () {

  return this._iceCandidates
}

/**
 * @param {RTCSessionDescriptionInit} answer
 * @returns {Promise}
 */
BasRemoteServer.prototype.setAnswer = function (answer) {

  return this._peerConnection
    ? this._peerConnection.setRemoteDescription(answer)
    : Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)
}

/**
 * @param {RTCIceCandidate|RTCIceCandidateInit} iceCandidate
 * @returns {Promise}
 */
BasRemoteServer.prototype.addIceCandidate = function (iceCandidate) {

  return this._peerConnection
    ? this._peerConnection.addIceCandidate(iceCandidate)
    : Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)
}

/**
 * @private
 * @param {RTCSessionDescription} result
 * @returns {Promise<BasRemoteServer>}
 */
BasRemoteServer.prototype._onOffer = function (result) {

  return this._peerConnection
    ? this._peerConnection.setLocalDescription(result)
      .then(
        this._handleSetLocalDescription,
        this._handleSetLocalDescriptionError
      )
    : Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)
}

/**
 * @private
 * @param {*} error
 * @returns {Promise}
 */
BasRemoteServer.prototype._onOfferError = function (error) {

  log.error('PeerConnection createOffer ERROR', error)

  return Promise.reject(error)
}

/**
 * @private
 * @returns {(BasRemoteServer|Promise)}
 */
BasRemoteServer.prototype._onSetLocalDescription = function () {

  return this._peerConnection
    ? this
    : Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)
}

/**
 * @private
 * @param {*} error
 * @returns {Promise}
 */
BasRemoteServer.prototype._onSetLocalDescriptionError = function (error) {

  log.error('PeerConnection setLocalDescription ERROR', error)

  return Promise.reject(error)
}

/**
 * @private
 */
BasRemoteServer.prototype._onConnectionStateChange = function () {

  log.info(
    'RTC PC - Connection state',
    this._peerConnection
      ? this._peerConnection.connectionState
      : null
  )

  switch (this._peerConnection.connectionState) {
    case 'disconnected':
    case 'failed':
    case 'closed':

      // TODO NOT handle "disconnected" "failed" ?
      // Difference Firefox and Chrome, Safari

      this._isCoreConnected = false

      this._socket.clearHeartbeat()

      this.emit(BasServer.EVT_CORE_CONNECTED, false)
      this.emit(BasServer.EVT_CONNECTED, false)

      break
  }
}

/**
 * @private
 */
BasRemoteServer.prototype._onIceConnectionStateChange = function () {

  log.info(
    'RTC PC - ICE Connection state',
    this._peerConnection
      ? this._peerConnection.iceConnectionState
      : null
  )

  // TODO Remove this?

  switch (this._peerConnection.iceConnectionState) {
    case 'failed':
    case 'closed':

      // TODO Handle "disconnected" ?
      // Difference Firefox and Chrome, Safari

      this._isCoreConnected = false

      this._socket.clearHeartbeat()

      this.emit(BasServer.EVT_CORE_CONNECTED, false)
      this.emit(BasServer.EVT_CONNECTED, false)

      break
  }
}

/**
 * @private
 * @param {RTCPeerConnectionIceEvent} event
 */
BasRemoteServer.prototype._onIceCandidate = function (event) {

  var _candidate

  _candidate = event.candidate

  if (this._iceGatheringFinished) {

    // Clear previous ICE candidates
    this._iceCandidates = []
  }

  if (_candidate) {

    this._iceCandidates.push(_candidate)

  } else {

    this._iceGatheringFinished = true
  }

  this.emit(BasRemoteServer.EVT_ICE_CANDIDATE, _candidate)
}

/**
 * @private
 * @param {RTCPeerConnectionIceErrorEvent} event
 */
BasRemoteServer.prototype._onIceCandidateError = function (event) {

  var _errorCode

  if (BasUtil.isObject(event)) {

    _errorCode = event.errorCode

    if (_errorCode >= 300 && _errorCode < 700) {

      // STUN error
      log.warn('PeerConnection IceCandidate STUN ERROR', event)

    } else if (_errorCode >= 700 && _errorCode < 800) {

      // ICE Server could not be reached

    } else {

      log.warn('PeerConnection IceCandidate unknown code ERROR', event)
    }

  } else {

    log.warn('PeerConnection IceCandidate unknown ERROR', event)
  }
}

BasRemoteServer.prototype._setPeerConnectionListeners = function () {

  this._clearPeerConnectionListeners()

  if (this._peerConnection) {

    this._peerConnectionListeners.push(BasUtil.setDOMListener(
      this._peerConnection,
      'connectionstatechange',
      this._handleConnectionStateChange
    ))
    this._peerConnectionListeners.push(BasUtil.setDOMListener(
      this._peerConnection,
      'iceconnectionstatechange',
      this._handleIceConnectionStateChange
    ))
    this._peerConnectionListeners.push(BasUtil.setDOMListener(
      this._peerConnection,
      'icecandidate',
      this._handleIceCandidate
    ))
    this._peerConnectionListeners.push(BasUtil.setDOMListener(
      this._peerConnection,
      'icecandidateerror',
      this._handleIceCandidateError
    ))
  }
}

BasRemoteServer.prototype._clearPeerConnectionListeners = function () {

  BasUtil.executeArray(this._peerConnectionListeners)
  this._peerConnectionListeners = []
}

BasRemoteServer.prototype._setProxyChannelListeners = function () {

  this._clearProxyChannelListeners()

  if (this._proxyChannel) {

    this._proxyChannelListeners.push(BasUtil.setEventListener(
      this._proxyChannel,
      BasDataChannel.EVT_CONNECTED,
      this._handleProxyChannelConnected
    ))
    this._proxyChannelListeners.push(BasUtil.setEventListener(
      this._proxyChannel,
      BasDataChannel.EVT_MESSAGE,
      this._handleProxyChannelMessage
    ))
  }
}

BasRemoteServer.prototype._clearProxyChannelListeners = function () {

  BasUtil.execute(this._proxyChannelListeners)
  this._proxyChannelListeners = []
}

/**
 * @private
 * @param {boolean} isConnected
 */
BasRemoteServer.prototype._onProxyChannelConnected = function (isConnected) {

  this.emit(BasRemoteServer.EVT_REMOTE_PROXY_CONNECTED, isConnected)
  this.emit(BasServer.EVT_CONNECTED, isConnected)
}

/**
 * @private
 * @param {Object} message
 */
BasRemoteServer.prototype._onProxyChannelMessage = function (message) {

  this._proxySocket.onIncomingMessage(message)
}

BasRemoteServer.prototype._setCoreChannelListeners = function () {

  this._clearCoreChannelListeners()

  if (this._coreChannel) {

    this._coreChannelListeners.push(BasUtil.setEventListener(
      this._coreChannel,
      BasDataChannel.EVT_CONNECTED,
      this._handleCoreChannelConnected
    ))
    this._coreChannelListeners.push(BasUtil.setEventListener(
      this._coreChannel,
      BasDataChannel.EVT_MESSAGE,
      this._handleCoreChannelMessage
    ))
  }
}

BasRemoteServer.prototype._clearCoreChannelListeners = function () {

  BasUtil.execute(this._coreChannelListeners)
  this._coreChannelListeners = []
}

/**
 * @private
 * @param {boolean} isConnected
 */
BasRemoteServer.prototype._onCoreChannelConnected = function (isConnected) {

  this.emit(BasRemoteServer.EVT_REMOTE_CORE_CONNECTED, isConnected)

  // Emit isConnected false
  if (!isConnected) {

    this._socket.clearHeartbeat()

    this.emit(BasServer.EVT_CORE_CONNECTED, isConnected)
  }
}

/**
 * @private
 * @param {Object} message
 */
BasRemoteServer.prototype._onCoreChannelMessage = function (message) {

  this._socket.onIncomingMessage(message)
}

/**
 * Whether the WebRTC PeerConnection is connected and stable.
 *
 * @returns {boolean}
 */
BasRemoteServer.prototype.isPeerConnectionConnected = function () {

  return (
    this._peerConnection &&
    this._peerConnection.connectionState === 'connected' &&
    this._peerConnection.signalingState === 'stable'
  )
}

/**
 * @returns {boolean}
 */
BasRemoteServer.prototype.isRemoteProxyConnected = function () {

  return this._proxyChannel ? this._proxyChannel.isConnected() : false
}

/**
 * @returns {boolean}
 */
BasRemoteServer.prototype.isRemoteCoreConnected = function () {

  return this._coreChannel ? this._coreChannel.isConnected() : false
}

/**
 * @returns {boolean}
 */
BasRemoteServer.prototype.isConnected = function () {

  return this.isRemoteProxyConnected()
}

/**
 * @returns {boolean}
 */
BasRemoteServer.prototype.isCoreConnected = function () {

  return this._isCoreConnected
}

/**
 * @param {Object} data
 * @returns {boolean}
 */
BasRemoteServer.prototype.sendProxyData = function (data) {

  return this._proxyChannel
    ? this._proxyChannel.send(JSON.stringify(data))
    : false
}

/**
 * @param {Object} data
 * @returns {boolean}
 */
BasRemoteServer.prototype.sendCoreData = function (data) {

  return this._coreChannel
    ? this._coreChannel.send(JSON.stringify(data))
    : false
}

/**
 * HTTP request
 *
 * @param {TBasServerRequestConfig} config
 * @returns {Promise<TBasServerResponse>}
 */
BasRemoteServer.prototype.request = function (config) {

  return this.requestProxy(config)
}

/**
 * @param {TBasServerRequestConfig} config
 * @returns {Promise<TBasServerResponse>}
 */
BasRemoteServer.prototype.requestProxy = function (config) {

  var _data, _config

  if (this.isRemoteProxyConnected()) {

    _config = BasServer.parseBasServerRequestConfig(config)

    if (_config) {

      _data = {}
      _data[P.REQUEST] = _config

      return this._proxySocket.request(
        _data,
        BasServer.PROXY_REQUEST_TIMEOUT_MS
      ).then(BasServer.handleRequestProxy)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  return Promise.reject(CONSTANTS.ERR_NETWORK)
}

/**
 * @param {string} path
 * @returns {Promise<string>}
 */
BasRemoteServer.prototype.requestImageSource = function (path) {

  return BasUtil.isNEString(path)
    ? this.retrieveBase64Image(path)
    : Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {string} path
 * @returns {Promise<string>}
 */
BasRemoteServer.prototype.retrieveBase64Image = function (path) {

  return this.request({
    path: path,
    timeout: BasServer.BASE64_IMAGE_TIMEOUT_MS,
    encoding: BasServer.REQUEST_ENCODING_BASE64
  }).then(BasServer.handleBasServerResponseBase64Image)
}

/**
 * This will close and re-open the "Core" DataChannel.
 * This way the session is invalidated.
 *
 * If the peerConnection is not connected, the channel will not be re-opened.
 *
 * @returns {Promise}
 */
BasRemoteServer.prototype.reconnectRemoteCoreConnection = function () {

  this._isCoreConnected = false

  if (this._openCoreChannelPromise) return this._openCoreChannelPromise

  this._clearCoreChannelListeners()

  let closePromise = Promise.resolve()
  if (this._coreChannel) {
    closePromise = this._coreChannel.closeConnection()
  }
  this._coreChannel = null

  this._socket.clearHeartbeat()

  this.emit(BasServer.EVT_CORE_CONNECTED, false)

  return (
    this._openCoreChannelPromise = closePromise
      .then(this._openCoreChannel.bind(this))
      .then(this._handleOpenCoreChannel, this._handleOpenCoreChannelErrpr)
  )
}

/**
 * @private
 * @param {*} result
 * @returns {*}
 */
BasRemoteServer.prototype._onOpenCoreChannel = function (result) {

  this._onOpenCoreChannelFinished()

  return result
}

/**
 * @private
 * @param {*} error
 * @returns {Promise}
 */
BasRemoteServer.prototype._onOpenCoreChannelError = function (error) {

  this._onOpenCoreChannelFinished()

  return Promise.reject(error)
}

/**
 * @private
 */
BasRemoteServer.prototype._onOpenCoreChannelFinished = function () {

  this._openCoreChannelPromise = null
}

/**
 * @private
 * @returns {Promise}
 */
BasRemoteServer.prototype._openCoreChannel = function () {

  var _this

  _this = this

  return new Promise(_openCoreChannelPromiseConstructor)

  function _openCoreChannelPromiseConstructor (resolve, reject) {

    var _listeners

    _listeners = []

    if (_this.isPeerConnectionConnected()) {

      _this._coreChannel = new BasDataChannel(
        _this._peerConnection.createDataChannel('music'),
        'music'
      )

      _this._setCoreChannelListeners()

      _listeners.push(BasUtil.setEventListener(
        _this._coreChannel,
        BasDataChannel.EVT_CONNECTED,
        _onConnected
      ))

    } else {

      reject()
    }

    /**
     * @private
     * @param {boolean} isConnected
     */
    function _onConnected (isConnected) {

      _cleanUp()

      if (isConnected) {

        resolve()

      } else {

        reject()
      }
    }

    function _cleanUp () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}

/**
 * @param {(TCoreCredentials|Array<TCoreCredentials>)} credentials
 * @returns {Promise}
 */
// eslint-disable-next-line no-unused-vars
BasRemoteServer.prototype.connectToCore = function (credentials) {

  return this._socket.open(
    Array.isArray(credentials)
      ? credentials[0]
      : credentials
  ).then(this._handleOpenCoreSocket)
}

/**
 * @private
 * @param {Object} result
 */
// eslint-disable-next-line no-unused-vars
BasRemoteServer.prototype._onOpenCoreSocket = function (result) {

  this._isCoreConnected = true

  this._socket.heartbeat(true)

  this.emit(BasServer.EVT_CORE_CONNECTED, true)
}

/**
 * @returns {Promise}
 */
BasRemoteServer.prototype.disconnectFromCore = function () {

  this._isCoreConnected = false

  return this._socket
    ? this._socket.close()
    : Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * This will make the instance unusable for future connections
 */
BasRemoteServer.prototype.disconnect = function () {

  this._isCoreConnected = false

  this._socket.clearHeartbeat()

  this._clearCoreChannelListeners()
  this._clearProxyChannelListeners()
  this._clearPeerConnectionListeners()

  if (this._coreChannel) this._coreChannel.destroy()
  this._coreChannel = null

  if (this._proxyChannel) this._proxyChannel.destroy()
  this._proxyChannel = null

  if (this._peerConnection) this._peerConnection.close()
  this._peerConnection = null
}

/**
 * @returns {boolean}
 */
BasRemoteServer.prototype.isRemote = function () {

  return true
}

/**
 * Creates a clone of this BasServer
 *
 * @returns {BasRemoteServer}
 */
BasRemoteServer.prototype.clone = function () {

  return new BasRemoteServer(this._cid, this._useSubscriptionSocket)
}

BasRemoteServer.prototype.destroy = function () {

  this.disconnect()

  BasServer.prototype.destroy.call(this)
}

module.exports = BasRemoteServer
