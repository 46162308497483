'use strict'

/* eslint-disable max-len */

angular
  .module('basalteApp')
  .constant('BAS_HTML', {
    splash:
      require('../../app/splash/splash.html'),
    connect:
      require('../../app/connect/connect.html'),
    unsupported:
      require('../../app/unsupported/unsupported.html'),
    live:
      require('../../app/live/live.html'),
    liveLogin:
      require('../../app/live/login/login.live.html'),
    liveRegister:
      require('../../app/live/register/register.live.html'),
    liveVerify:
      require('../../app/live/verify/verify.live.html'),
    liveForgot:
      require('../../app/live/forgot/forgot.live.html'),
    liveChange:
      require('../../app/live/change/change.live.html'),
    discovery:
      require('../../app/discovery/discovery.html'),
    profiles:
      require('../../app/profiles/profiles.html'),
    basProfileLogin:
      require('../../app/profiles/basprofilelogin.html'),
    loginProfilesModal:
      require('../../app/modals/login.profiles.modal.html'),
    main:
      require('../../app/main/main.html'),
    home:
      require('../../app/home/home.html'),
    lights:
      require('../../app/lights/lights.html'),
    light:
      require('../../app/lights/light.html'),
    genericDevices:
      require('../../app/genericdevice/genericdevices.html'),
    genericDevice:
      require('../../app/genericdevice/genericdevice.html'),
    shades:
      require('../../app/shades/shades.html'),
    shade:
      require('../../app/shades/shade.html'),
    timers:
      require('../../app/timers/timers.html'),
    timerWeek:
      require('../../app/timers/week.timers.html'),
    timerDay:
      require('../../app/timers/day.timers.html'),
    homeScenes:
      require('../../app/scenes/home.scenes.html'),
    scenes:
      require('../../app/scenes/scenes.html'),
    scene:
      require('../../app/scenes/scene.html'),
    roomsDetailScene:
      require('../../app/scenes/rooms.detail.scene.html'),
    functionsDetailScene:
      require('../../app/scenes/functions.detail.scene.html'),
    musicDetailScene:
      require('../../app/scenes/music/music.detail.scene.html'),
    videoDetailScene:
      require('../../app/scenes/video/video.detail.scene.html'),
    lightsDetailScene:
      require('../../app/scenes/lights/lights.detail.scene.html'),
    devicesDetailScene:
      require('../../app/scenes/genericdevice/genericdevice.detail.scene.html'),
    scenesDetailScene:
      require('../../app/scenes/scenes/scenes.scene.basscenes.html'),
    thermostatDetailScene:
      require('../../app/scenes/thermostat/thermostat.detail.scene.html'),
    thermostatPickerScene:
      require('../../app/scenes/thermostat/thermostat.picker.scene.html'),
    shadesDetailScene:
      require('../../app/scenes/shades/shades.detail.scene.html'),
    musicFavouritesDetailScene:
      require('../../app/scenes/music/music.favourites.detail.scene.html'),
    videoFavouritesDetailScene:
      require('../../app/scenes/video/video.favourites.detail.scene.html'),
    player:
      require('../../app/now-playing/now-playing.html'),
    remote:
      require('../../app/media/remote.html'),
    rooms:
      require('../../app/rooms/rooms.html'),
    sources:
      require('../../app/sources/sources.html'),
    favourites:
      require('../../app/favourites/favourites.html'),
    library:
      require('../../app/library/library.html'),
    templateLibrary:
      require('../../app/library/template/root.html'),
    basicCoverArt:
      require('../../app/library/templateDirectives/basic.coverart.html'),
    radioCoverArt:
      require('../../app/library/templateDirectives/radio.coverart.html'),
    multipleCoverArt:
      require('../../app/library/templateDirectives/multiple.coverart.html'),
    alarms:
      require('../../app/alarms/alarms.html'),
    alarm:
      require('../../app/alarms/detail.alarms.html'),
    deviceSettings:
      require('../../app/settings/devicesettings.html'),
    settings:
      require('../../app/settings/settings.html'),
    generalSettings:
      require('../../app/settings/general/general.settings.html'),
    streamingSettings:
      require('../../app/settings/music/streaming/streaming.settings.html'),
    deezerSettings:
      require('../../app/settings/music/deezer/deezer.settings.html'),
    tidalSettings:
      require('../../app/settings/music/tidal/tidal.settings.html'),
    spotifySettings:
      require('../../app/settings/music/spotify/spotify.settings.html'),
    defaultRoomsSettings:
      require('../../app/settings/music/defaultrooms/defaultrooms.settings.html'),
    defaultRoomsSettingsRooms:
      require('../../app/settings/music/defaultrooms/rooms.defaultrooms.settings.html'),
    knxPresetsSettings:
      require('../../app/settings/music/knxpresets/knxpresets.settings.html'),
    presetsKNXPresets:
      require('../../app/settings/music/knxpresets/presets.knxpresets.html'),
    presetKNXPresets:
      require('../../app/settings/music/knxpresets/preset.knxpresets.html'),
    doorPhoneSettings:
      require('../../app/settings/doorphone/doorphone.settings.html'),
    camerasSettings:
      require('../../app/settings/cameras/cameras.settings.html'),
    networkSettings:
      require('../../app/settings/network/network.settings.html'),
    aboutSettings:
      require('../../app/settings/about/about.settings.html'),
    liveSettings:
      require('../../app/settings/live/live.settings.html'),
    musicSettings:
      require('../../app/settings/music/music.settings.html'),
    projectSettings:
      require('../../app/settings/project/project.settings.html'),
    projectImageSettings:
      require('../../app/settings/project/image/image.project.settings.html'),
    connectModal:
      require('../../app/modals/connect.modal.html'),
    lisaOrderSettings:
      require('../../app/settings/lisa/lisa.order.settings.html'),
    lisaStartPageSettings:
      require('../../app/settingscomponents/lisastartpage.devicesettings.html'),
    mainModal:
      require('../../app/modals/main.modal.html'),
    muteDeviceModal:
      require('../../app/modals/mutedevice.modal.html'),
    messageModal:
      require('../../app/modals/message.modal.html'),
    contextModal:
      require('../../app/modals/context.modal.html'),
    addServerModal:
      require('../../app/modals/addserver.modal.html'),
    addToPlaylistModal:
      require('../../app/modals/addtoplaylist.modal.html'),
    templateContextModal:
      require('../../app/library/template/context.modal.html'),
    templateModal:
      require('../../app/modals/template.modal.html'),
    basContextModal:
      require('../../app/modals/bascontext.modal.html'),
    loginModal:
      require('../../app/modals/login.modal.html'),
    dspModal:
      require('../../app/modals/dsp.modal.html'),
    textInputModal:
      require('../../app/modals/text-input.modal.html'),
    multiScreenModal:
      require('../../app/modals/multi-screen-input.modal.html'),
    timeInputModal:
      require('../../app/modals/time-input.modal.html'),
    avRoomSelectModal:
      require('../../app/modals/avroom-select.modal.html'),
    numberInputModal:
      require('../../app/modals/number-input.modal.html'),
    miniPlayer:
      require('../../app/now-playing/mini.player.html'),
    basQueue:
      require('../../app/now-playing/basqueue.html'),
    pageBasLibrary:
      require('../../app/library/template/page.bas-library.html'),
    headerBasLibrary:
      require('../../app/library/template/header.bas-library.html'),
    roomTile:
      require('../../app/rooms/room-tile.html'),
    roomMusic:
      require('../../app/rooms/room-music.html'),
    roomThermostat:
      require('../../app/rooms/room-thermostat.html'),
    roomIntercom:
      require('../../app/rooms/room-intercom.html'),
    roomDash:
      require('../../app/room-dashboard/room-dashboard.html'),
    roomScenes:
      require('../../app/scenes/room.scenes.html'),
    scenePresets:
      require('../../app/scenes/presets.room.scene.html'),
    sceneImage:
      require('../../app/scenes/image.room.scene.html'),
    widgetThermostat:
      require('../../app/thermostat/widget.thermostat.html'),
    appHeader:
      require('../../app/header/app.header.html'),
    appHeaderLisa:
      require('../../app/header/app.header.lisa.html'),
    mediaFooter:
      require('../../app/media/basmediafooter.html'),
    video:
      require('../../app/media/video.html'),
    music:
      require('../../app/music/music.html'),
    mainBasScheduler:
      require('../../app/scheduler/main.basscheduler.html'),
    notificationModal:
      require('../../app/modals/notification.modal.html'),
    audioAlertModal:
      require('../../app/audio-alert/audioalert.modal.html'),
    entryBasScheduler:
      require('../../app/scheduler/entry.basscheduler.html'),
    thermostatDetail:
      require('../../app/thermostat/detail.thermostat.html'),
    thermostatDetailTabs:
      require('../../app/thermostat/detail.tabs.thermostat.html'),
    thermostatDetailSlider:
      require('../../app/thermostat/detail.slider.thermostat.html'),
    thermostatDetailSettings:
      require('../../app/thermostat/detail.settings.thermostat.html'),
    thermostatSceneDetail:
      require('../../app/thermostat/detail.thermostat.scene.html'),
    thermostat:
      require('../../app/thermostat/thermostat.html'),
    thermostatWeek:
      require('../../app/thermostat/scheduler/week.thermostat.html'),
    thermostatDay:
      require('../../app/thermostat/scheduler/day.thermostat.html'),
    colorPickerModal:
      require('../../app/modals/colorpicker.modal.html'),
    scenePickerModal:
      require('../../app/modals/scenepicker.modal.html'),
    favouritesPickerModal:
      require('../../app/modals/favouritespicker.modal.html'),
    energy:
      require('../../app/energy/energy.html'),
    energyDetail:
      require('../../app/energy/detail.energy.html'),
    schedules:
      require('../../app/scenes/scheduler/schedules.bassceneschedule.html'),
    schedule:
      require('../../app/scenes/scheduler/schedule.bassceneschedule.html'),
    scheduleScenes:
      require('../../app/scenes/scheduler/scenes.schedule.bassceneschedule.html'),
    scheduleTiming:
      require('../../app/scenes/scheduler/timing.schedule.bassceneschedule.html'),
    roomTypesModal:
      require('../../app/modals/roomtypes.modal.html'),
    serverStatusModal:
      require('../../app/modals/serverstatus.modal.html'),
    musicWidget:
      require('../../app/room-dashboard/basmusicwidget.html'),
    musicWidgetTitle:
      require('../../app/room-dashboard/basmusicwidgettitle.html'),
    messagesCarousel:
      require('../../app/home/basmessagescarousel.html'),
    shadeModal:
      require('../../app/modals/shade.modal.html'),
    cameras:
      require('../../app/camera/cameras.html'),
    cameraDetail:
      require('../../app/camera/detail.cameras.html'),
    openCloseDevices:
      require('../../app/open_close/openclosedevices.html'),
    openCloseDevice:
      require('../../app/open_close/openclosedevice.html'),
    weather:
      require('../../app/weather/weather.html'),
    weatherCarousel:
      require('../../app/weather/basweathercarousel.html'),
    weatherTopWidget:
      require('../../app/weather/basweathertopwidget.html'),
    callHistory:
      require('../../app/call-history/callhistory.html'),
    callHistoryDetail:
      require('../../app/call-history/detail.callhistory.html'),
    basLiveSettings:
      require('../../app/settingscomponents/baslive.devicesettings.html'),
    ellieIpSettings:
      require('../../app/settingscomponents/ellieip.devicesettings.html'),
    screenOrientation:
      require('../../app/settingscomponents/screenorientation.devicesettings.html'),
    imagePicker:
      require('../../app/imagepicker/basimagepicker.html'),
    roomImagePicker:
      require('../../app/imagepicker/room.basimagepicker.html'),
    sceneImagePicker:
      require('../../app/imagepicker/scene.basimagepicker.html'),
    roomImagePickerModal:
      require('../../app/modals/roomimagepicker.modal.html'),
    roomImageSettings:
      require('../../app/settings/project/roomimages/roomimages.settings.html'),
    roomImageSettingsRoom:
      require('../../app/settings/project/roomimages/roomimages.room.settings.html'),
    lisa:
      require('../../app/lisa/lisa.html'),
    lisaScenes:
      require('../../app/lisa/detail-pages/lisa.scenes.html'),
    lisaLights:
      require('../../app/lisa/detail-pages/lisa.lights.html'),
    lisaLightDetails:
      require('../../app/lisa/detail-pages/lisa.light.details.html'),
    lisaMusic:
      require('../../app/lisa/detail-pages/lisa.music.html'),
    lisaThermostat:
      require('../../app/lisa/detail-pages/lisa.thermostat.html'),
    lisaShades:
      require('../../app/lisa/detail-pages/lisa.shades.html'),
    lisaShadeDetails:
      require('../../app/lisa/detail-pages/lisa.shade.details.html'),
    lisaTiles:
      require('../../app/lisa/lisa.tiles.html'),
    lisaCameras:
      require('../../app/lisa/detail-pages/lisa.cameras.html'),
    lisaIntercom:
      require('../../app/lisa/detail-pages/lisa.intercom.html'),
    lisaListElement:
      require('../../app/lisa/lisa.list-element.html'),
    basStep:
      require('../../app/scenes/basstep.html'),
    languageDeviceSettings:
      require('../../app/settingscomponents/language.devicesettings.html'),
    ellieProximityDeviceSettings:
      require('../../app/settingscomponents/ellieproximity.devicesettings.html'),
    sleepDeviceSettings:
      require('../../app/settingscomponents/sleep.devicesettings.html'),
    startupViewDeviceSettings:
      require('../../app/settingscomponents/startupview.devicesettings.html'),
    temperatureDeviceSettings:
      require('../../app/settingscomponents/temperature.devicesettings.html'),
    timeFormatDeviceSettings:
      require('../../app/settingscomponents/timeformat.devicesettings.html'),
    ellieDefaultViewDeviceSettings:
      require('../../app/settingscomponents/elliedefaultview.devicesettings.html'),
    dspDeviceSettings:
      require('../../app/settingscomponents/dsp.devicesettings.html'),
    cloudEnvironmentSettings:
      require('../../app/settingscomponents/cloudenvironment.devicesettings.html'),
    settingsHeader:
      require('../../app/settingscomponents/header.settings.html'),
    timeTile:
      require('../../app/tiles/time.tile.html'),
    thermostatTile:
      require('../../app/tiles/thermostat.tile.html'),
    weatherTile:
      require('../../app/tiles/weather.tile.html'),
    basVideoButtonGrids:
      require('../../app/media/basvideobuttongrids.html'),
    streamingServiceSettingsModal:
      require('../../app/modals/settings.streamingservice.modal.html')
  })
