'use strict'

angular
  .module('basalteApp')
  .constant('BAS_LIGHT', {
    EVT_LIGHT_DEVICE_TOGGLE_UPDATED: 'evtBasLightDeviceToggleUpdated',
    EVT_LIGHT_DEVICE_WHITE_UPDATED: 'evtBasLightDeviceWhite',
    EVT_LIGHT_DEVICE_BRIGHTNESS_UPDATED: 'evtLightDeviceBrightness',
    MODE_COLOR: 'basLightModeColor',
    MODE_COLOR_TEMPERATURE: 'basLightModeColorTemperature',
    MODE_WHITE: 'basLightModeWhite',
    MODE_UNKNOWN: 'basLightModeUnknown'
  })
