'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Message = require('./message')

var log = require('./logger')

/**
 * System class which holds system status regarding updates and errors
 *
 * @constructor
 * @extends EventEmitter
 * @param {BasCore} basCore
 * @since 1.7.8
 */
function System (basCore) {

  EventEmitter.call(this)

  this._basCore = basCore

  /**
   * @private
   * @type {boolean}
   */
  this._audioOnly = true

  /**
   * @private
   * @type {boolean}
   */
  this._singleRoom = false

  /**
   * @type {boolean}
   * @private
   */
  this._singleSource = false

  /**
   * @type {boolean}
   * @private
   */
  this._supportsAV = false

  /**
   * @private
   * @type {boolean}
   */
  this._supportsAVAsano = false

  /**
   * @private
   * @type {boolean}
   */
  this._hasSpotify = false

  /**
   * @private
   * @type {string}
   */
  this._locale = ''

  /**
   * @private
   * @type {string}
   */
  this._timezone = ''

  /**
   * @private
   * @type {string}
   */
  this._temperatureUnit = CONSTANTS.TU_CELSIUS

  /**
   * @private
   * @type {string}
   */
  this._city = ''

  /**
   * @private
   * @type {string}
   */
  this._country = ''

  /**
   * @private
   * @type {number}
   */
  this._latitude = 0

  /**
   * @private
   * @type {number}
   */
  this._longitude = 0

  /**
   * @private
   * @type {boolean}
   */
  this._integratorAccess = false

  /**
   * @private
   * @type {string}
   */
  this._integratorCountry = ''

  /**
   * @private
   * @type {string}
   */
  this._integratorCompany = ''

  /**
   * @private
   * @type {string}
   */
  this._integratorName = ''

  /**
   * @private
   * @type {string}
   */
  this._integratorPhone = ''

  /**
   * @private
   * @type {Message[]}
   */
  this._messages = []

  /**
   * @private
   * @type {boolean}
   */
  this._propertiesDirty = false

  this._handleIntegratorInfo = this._onIntegratorInfo.bind(this)

  this.reset()
}

System.prototype = Object.create(EventEmitter.prototype)
System.prototype.constructor = System

// region Events

/**
 * Fired when system properties have been received
 * (audioOnly, singleRoom, singleSource, locale, units, location, ...)
 *
 * @event System#EVT_SYSTEM_PROPERTIES
 */

/**
 * @event System#EVT_MESSAGES_UPDATED
 */

// endregion

/**
 * @constant {string}
 */
System.EVT_SYSTEM_PROPERTIES = 'evtSystemProperties'

/**
 * @constant {string}
 */
System.EVT_MESSAGES_UPDATED = 'evtSystemMessagesUpdated'

/**
 * @constant {string}
 */
System.ERR_INVALID_RESPONSE = 'systemInvalidResponse'

/**
 * @name System#audioOnly
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(System.prototype, 'audioOnly', {
  get: function () {
    return this._audioOnly
  }
})

/**
 * @name System#singleRoom
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(System.prototype, 'singleRoom', {
  get: function () {
    return this._singleRoom
  }
})

/**
 * @name System#singleSource
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(System.prototype, 'singleSource', {
  get: function () {
    return this._singleSource
  }
})

/**
 * @name System#supportsAV
 * @type {boolean}
 * @readonly
 * @since 3.4.0
 */
Object.defineProperty(System.prototype, 'supportsAV', {
  get: function () {
    return this._supportsAV
  }
})

/**
 * @name System#supportsAVAsano
 * @type {boolean}
 * @readonly
 * @since 3.4.0
 */
Object.defineProperty(System.prototype, 'supportsAVAsano', {
  get: function () {
    return this._supportsAVAsano
  }
})

/**
 * @name System#hasSpotify
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(System.prototype, 'hasSpotify', {
  get: function () {
    return this._hasSpotify
  }
})

/**
 * @name System#locale
 * @type {string}
 * @readonly
 */
Object.defineProperty(System.prototype, 'locale', {
  get: function () {
    return this._locale
  }
})

/**
 * @name System#timezone
 * @type {string}
 * @readonly
 */
Object.defineProperty(System.prototype, 'timezone', {
  get: function () {
    return this._timezone
  }
})

/**
 * @name System#temperatureUnit
 * @type {string}
 * @readonly
 */
Object.defineProperty(System.prototype, 'temperatureUnit', {
  get: function () {
    return this._temperatureUnit
  }
})

/**
 * @name System#city
 * @type {string}
 * @readonly
 */
Object.defineProperty(System.prototype, 'city', {
  get: function () {
    return this._city
  }
})

/**
 * @name System#country
 * @type {string}
 * @readonly
 */
Object.defineProperty(System.prototype, 'country', {
  get: function () {
    return this._country
  }
})

/**
 * @name System#latitude
 * @type {number}
 * @readonly
 */
Object.defineProperty(System.prototype, 'latitude', {
  get: function () {
    return this._latitude
  }
})

/**
 * @name System#longitude
 * @type {number}
 * @readonly
 */
Object.defineProperty(System.prototype, 'longitude', {
  get: function () {
    return this._longitude
  }
})

/**
 * @name System#integratorAccess
 * @type {boolean}
 * @readonly
 * @since 2.9.2
 */
Object.defineProperty(System.prototype, 'integratorAccess', {
  get: function () {
    return this._integratorAccess
  }
})

/**
 * @name System#integratorCountry
 * @type {string}
 * @readonly
 * @since 3.1.0
 */
Object.defineProperty(System.prototype, 'integratorCountry', {
  get: function () {
    return this._integratorCountry
  }
})

/**
 * @name System#integratorCompany
 * @type {string}
 * @readonly
 * @since 3.1.0
 */
Object.defineProperty(System.prototype, 'integratorCompany', {
  get: function () {
    return this._integratorCompany
  }
})

/**
 * @name System#integratorName
 * @type {string}
 * @readonly
 * @since 3.1.0
 */
Object.defineProperty(System.prototype, 'integratorName', {
  get: function () {
    return this._integratorName
  }
})

/**
 * @name System#integratorPhone
 * @type {string}
 * @readonly
 * @since 3.1.0
 */
Object.defineProperty(System.prototype, 'integratorPhone', {
  get: function () {
    return this._integratorPhone
  }
})

/**
 * @name System#messages
 * @type {Message[]}
 * @readonly
 */
Object.defineProperty(System.prototype, 'messages', {
  get: function () {
    return this._messages
  }
})

/**
 * @name System#propertiesDirty
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(System.prototype, 'propertiesDirty', {
  get: function () {
    return this._propertiesDirty
  }
})

/**
 * Parse "system" messages
 *
 * @param {Object} obj
 */
System.prototype.parse = function (obj) {

  var system, emitSystemProperties, i, length, item

  if (BasUtil.isObject(obj) &&
    BasUtil.isObject(obj[P.SYSTEM])) {

    system = obj[P.SYSTEM]
    emitSystemProperties = false

    // Version
    if (BasUtil.isNEString(system[P.VERSION])) {

      this._basCore.setVersionFromSystem(system[P.VERSION])
    }

    // Locale
    if (BasUtil.isNEString(system[P.LOCALE])) {

      this._locale = system[P.LOCALE]
      emitSystemProperties = true
    }

    // Timezone
    if (BasUtil.isNEString(system[P.TIMEZONE])) {

      this._timezone = system[P.TIMEZONE]
      emitSystemProperties = true
    }

    // Units
    if (BasUtil.isObject(system[P.UNITS])) {

      // Temperature
      if (BasUtil.isNEString(system[P.UNITS][P.TEMPERATURE])) {

        switch (system[P.UNITS][P.TEMPERATURE]) {
          case P.CELSIUS:
            this._temperatureUnit = CONSTANTS.TU_CELSIUS
            break
          case P.FAHRENHEIT:
            this._temperatureUnit = CONSTANTS.TU_FAHRENHEIT
            break
          default:
            log.warn(
              'System - Unsupported temperature unit',
              system[P.UNITS][P.TEMPERATURE]
            )
        }

        emitSystemProperties = true
      }
    }

    // Location
    if (BasUtil.isNEString(system[P.CITY])) {

      this._city = system[P.CITY]
      emitSystemProperties = true
    }
    if (BasUtil.isNEString(system[P.COUNTRY])) {

      this._country = system[P.COUNTRY]
      emitSystemProperties = true
    }
    if (BasUtil.isNumber(system[P.LATITUDE])) {

      this._latitude = system[P.LATITUDE]
      emitSystemProperties = true
    }
    if (BasUtil.isNumber(system[P.LONGITUDE])) {

      this._longitude = system[P.LONGITUDE]
      emitSystemProperties = true
    }

    // Audio only
    if (BasUtil.isBool(system[P.AUDIO_ONLY])) {

      this._audioOnly = system[P.AUDIO_ONLY]
      emitSystemProperties = true
    }

    // Single room
    if (BasUtil.isBool(system[P.SINGLE_ROOM])) {

      this._singleRoom = system[P.SINGLE_ROOM]
      emitSystemProperties = true
    }

    // Single source
    if (BasUtil.isBool(system[P.SINGLE_SOURCE])) {

      this._singleSource = system[P.SINGLE_SOURCE]
      emitSystemProperties = true
    }

    // AV support
    if (BasUtil.isBool(system[P.AV_SUPPORT])) {

      this._supportsAV = system[P.AV_SUPPORT]
      emitSystemProperties = true
    }

    // Asano AV support
    if (BasUtil.isBool(system[P.ASANO_AV_SUPPORT])) {

      this._supportsAVAsano = system[P.ASANO_AV_SUPPORT]
      emitSystemProperties = true
    }

    // Spotify presence
    if (BasUtil.isBool(system[P.SPOTIFY])) {

      this._hasSpotify = system[P.SPOTIFY]
      emitSystemProperties = true
    }

    // Settings
    if (BasUtil.isObject(system[P.SETTINGS])) {

      item = system[P.SETTINGS]

      // Integrator Access
      if (BasUtil.isBool(item[P.INTEGRATOR_ACCESS])) {

        this._integratorAccess = item[P.INTEGRATOR_ACCESS]
        emitSystemProperties = true
      }
    }

    // Check if event needs to be emitted
    if (emitSystemProperties) {

      this._propertiesDirty = false
      this.emit(System.EVT_SYSTEM_PROPERTIES)
    }

    if (Array.isArray(system[P.MESSAGES])) {

      this._messages = []

      length = system[P.MESSAGES].length

      for (i = 0; i < length; i++) {

        item = new Message(system[P.MESSAGES][i])
        this.messages.push(item)
      }

      this.emit(System.EVT_MESSAGES_UPDATED)
    }
  }
}

/**
 * Restarts the system.
 * All servers will be restarted in a multi-server setup.
 *
 * @since 2.0.2
 */
System.prototype.restart = function () {

  var data = {}
  data[P.SYSTEM] = {}
  data[P.SYSTEM][P.ACTION] = P.RESTART

  this._basCore.send(data)
}

/**
 * Start the update checker for the system.
 * All servers will check for updates in a multi-server setup.
 *
 * @since 2.0.2
 */
System.prototype.checkForUpdates = function () {

  var data = {}
  data[P.SYSTEM] = {}
  data[P.SYSTEM][P.ACTION] = P.CHECK_FOR_UPDATES

  this._basCore.send(data)
}

/**
 * @param {boolean} [force]
 * @since 2.9.2
 */
System.prototype.toggleIntegratorAccess = function (force) {

  var data

  data = {}
  data[P.SYSTEM] = {}
  data[P.SYSTEM][P.SETTINGS] = {}
  data[P.SYSTEM][P.SETTINGS][P.INTEGRATOR_ACCESS] =
    BasUtil.isBool(force) ? force : !this._integratorAccess

  this._basCore.send(data)
}

/**
 * @returns {Promise<System>}
 * @since 3.1.0
 */
System.prototype.requestIntegratorInfo = function () {

  var data

  data = {}
  data[P.SYSTEM] = {}
  data[P.SYSTEM][P.INTEGRATOR_INFO] = {}
  data[P.SYSTEM][P.INTEGRATOR_INFO][P.ACTION] = P.STATUS

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(this._handleIntegratorInfo)
}

/**
 * @private
 * @param {Object} result
 * @returns {(System|Promise)}
 * @since 3.1.0
 */
System.prototype._onIntegratorInfo = function (result) {

  var info, value

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.SYSTEM]) &&
    BasUtil.isObject(result[P.SYSTEM][P.INTEGRATOR_INFO])) {

    info = result[P.SYSTEM][P.INTEGRATOR_INFO]

    value = info[P.STATUS_CODE]

    if (BasUtil.isPNumber(value) && value >= 200 && value < 300) {

      this._resetIntegratorInfo()

      value = info[P.COUNTRY]
      if (BasUtil.isNEString(value)) this._integratorCountry = value

      value = info[P.COMPANY]
      if (BasUtil.isNEString(value)) this._integratorCompany = value

      value = info[P.NAME]
      if (BasUtil.isNEString(value)) this._integratorName = value

      value = info[P.PHONE]
      if (BasUtil.isNEString(value)) this._integratorPhone = value

      return this
    }
  }

  return Promise.reject()
}

/**
 * @private
 * @since 3.1.0
 */
System.prototype._resetIntegratorInfo = function () {

  this._integratorCountry = ''
  this._integratorCompany = ''
  this._integratorName = ''
  this._integratorPhone = ''
}

/**
 * Resets properties to their default values
 */
System.prototype.reset = function () {

  this._audioOnly = true
  this._locale = 'en_US'
  this._timezone = ''
  this._city = 'Merelbeke'
  this._country = 'Belgium'
  this._latitude = 51
  this._longitude = 3.75

  this._resetIntegratorInfo()

  this._propertiesDirty = true
}

/**
 * @since 2.0.0
 */
System.prototype.destroy = function () {

  this._propertiesDirty = true
  this.removeAllListeners()
}

module.exports = System
