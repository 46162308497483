'use strict'

/**
 * @typedef {Object} TBasSipState
 * @property {boolean} hasDoorPhone
 * @property {string} uiSipStateDomain
 * @property {string[]} uiSipStateUsernames
 * @property {string} uiSipStateRegistrationSimple
 * @property {string} uiSipStateRegistrationDetailed
 * @property {boolean} uiShowSipState
 */

angular
  .module('basalteApp')
  .constant('BAS_CORE_CLIENT', {
    EVT_CORE_CLIENT_URI: 'evtCoreClientURI',
    EVT_CORE_CLIENT_MAC_ADDRESS: 'evtCoreClientMacAddress',
    EVT_CORE_CLIENT_SERIAL_NUMBER: 'evtCoreClientSerialNumber',
    EVT_CORE_CLIENT_SUPPORTS_MUTE_AREA_ON_CALL:
      'evtCoreClientSupportsMuteAreaOnCall',
    EVT_UPDATER_UPDATE_INFO: 'evtUpdaterUpdateInfo',
    ALLOW_PREFERRED_STATE_TIMEOUT_MS: 5000,
    STATE: {

      // Device testing
      // mac: '38B19E4E1D63',
      // macNum: 62335516286307,
      // macStr: '38:B1:9E:4E:1D:63',
      // uiMac: '38:B1:9E:4E:1D:63',
      // uiSerial: '98205550',

      /**
       * MAC address in hex string
       *
       * @type {string}
       */
      mac: '',

      /**
       * MAC address in decimal form
       *
       * @type {number}
       */
      macNum: 0,

      /**
       * MAC address in string form "00:11:22:33:44:55"
       *
       * @type {string}
       */
      macStr: '',

      /**
       * MAC address in string form "00:11:22:33:44:55" or "-"
       *
       * @type {string}
       */
      uiMac: '-',

      /**
       * Serial number
       *
       * @type {string}
       */
      uiSerial: '-'
    }
  })
