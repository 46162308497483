'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('CurrentBasCore', [
    '$rootScope',
    'BAS_CORE',
    'BAS_CURRENT_CORE',
    CurrentBasCore
  ])

/**
 * @typedef {Object} TCurrentBasCoreState
 * @property {?BasCoreContainer} core
 * @property {number} lastCoreConnectedTimestamp
 * @property {string} lastConnectedCoreCID
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_CORE} BAS_CORE
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 */
function CurrentBasCore (
  $rootScope,
  BAS_CORE,
  BAS_CURRENT_CORE
) {
  var _coreListeners

  /**
   * @type {TCurrentBasCoreState}
   */
  var state = {
    core: null,
    lastCoreConnectedTimestamp: -1,
    lastConnectedCoreCID: ''
  }

  this.get = get
  this.getServer = getServer
  this.set = set
  this.has = has
  this.hasCore = hasCore
  this.getName = getName
  this.getLastRoomId = getLastRoomId
  this.setLastRoomId = setLastRoomId
  this.hasHomePage = hasHomePage
  this.hasRoomsOverview = hasRoomsOverview
  this.hasIntercomAccess = hasIntercomAccess
  this.hasSpotifyConnect = hasSpotifyConnect
  this.hasAVPartialSupport = hasAVPartialSupport
  this.hasAVFullSupport = hasAVFullSupport
  this.isLoggedInWithCoreClientDeviceProfile =
    isLoggedInWithCoreClientDeviceProfile
  this.isAudioOnly = isAudioOnly
  this.getDevice = getDevice
  this.retrieveProjectInfo = retrieveProjectInfo

  init()

  function init () {

    _setCoreListeners()
  }

  /**
   * @returns {TCurrentBasCoreState}
   */
  function get () {

    return state
  }

  /**
   * @returns {?BasServer}
   */
  function getServer () {

    var _server

    if (hasCore()) {

      _server = state.core.core.server

      if (!_server) {

        _server = state.core.basServer
      }

    } else if (has()) {

      _server = state.core.basServer
    }

    return _server || null
  }

  /**
   * @param {?BasCoreContainer} basCoreContainer
   */
  function set (basCoreContainer) {

    var _oldCore, _server

    if (state.core !== basCoreContainer) {

      if (state.core) {

        _oldCore = state.core
        state.core = null

        _oldCore.destroy()
      }

      state.core = basCoreContainer

      _server = getServer()

      if (_server && _server.isCoreConnected()) {

        state.core.getAllSharedStorage()
      }

      $rootScope.$emit(
        BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED,
        state.core
      )
    }
  }

  /**
   * Whether there is a BasCoreContainer
   *
   * @returns {boolean}
   */
  function has () {

    return !!(state.core && state.core.hasCore)
  }

  /**
   * Whether there is a BasCoreContainer with a valid BasCore
   *
   * @returns {boolean}
   */
  function hasCore () {

    return has() && state.core.hasCore()
  }

  /**
   * @returns {string}
   */
  function getName () {

    var basServer

    basServer = getServer()

    return (basServer && basServer.getName) ? basServer.getName() : ''
  }

  /**
   * Returns the last (selected) room ID for the current BasCore
   *
   * @returns {string}
   */
  function getLastRoomId () {

    return has() ? state.core.getLastRoomIdForLastUser() : ''
  }

  /**
   * @param {string} roomId
   */
  function setLastRoomId (roomId) {

    if (has()) {

      state.core.saveLastRoomIdForLastUser(roomId)
    }
  }

  /**
   * Whether there is home dashboard/page access or not.
   * Returns true:
   *  - if server is not available
   *  - if server does not have dashboard access property
   *
   * @returns {boolean}
   */
  function hasHomePage () {

    return has() ? state.core.hasHomePage() : true
  }

  /**
   * Whether there should be a rooms overview or not.
   * Returns true:
   *  - if server config is NOT (audio-only and has a single audio room)
   *  - if server config has "home" access
   *  - if server config has multiple rooms
   * Returns false:
   *  - if server config IS audio-only and has a single audio room
   *  - if server has a single room and has no "home" access
   *
   * @returns {boolean}
   */
  function hasRoomsOverview () {

    var hasHome, audioOnly, singleRoomId, singleAudioRoomId

    hasHome = hasHomePage()
    audioOnly = isAudioOnly()
    singleRoomId = ''
    singleAudioRoomId = ''

    if (hasCore()) {

      singleRoomId = state.core.core.singleRoomId
      singleAudioRoomId = state.core.core.singleAudioRoomId
    }

    return !(
      (audioOnly && singleAudioRoomId) ||
      (!hasHome && singleRoomId)
    )
  }

  /**
   * Whether this profile is allowed to make/start intercom calls.
   *
   * @returns {boolean}
   */
  function hasIntercomAccess () {

    return has() ? state.core.hasIntercomAccess() : false
  }

  /**
   * Whether the core has spotify connect
   *
   * @returns {boolean}
   */
  function hasSpotifyConnect () {

    return hasCore()
      ? state.core.core._supportsAvAudioDsp
        ? state.core.core.system.hasSpotify
        : state.core.core.hasSpotifyConnect
      : false
  }

  /**
   * Whether the core supports the new AV framework, but only for Sonos
   *
   * @returns {boolean}
   */
  function hasAVPartialSupport () {

    return has() ? state.core.supportsAVPartially : false
  }

  /**
   * Whether the core supports the new AV framework for Asano sources
   *
   * @returns {boolean}
   */
  function hasAVFullSupport () {

    return has() ? state.core.supportsAVFully : false
  }

  /**
   * Whether this profile is an ellie profile.
   *
   * @returns {boolean}
   */
  function isLoggedInWithCoreClientDeviceProfile () {

    return has() ? state.core.isLoggedInWithCoreClientProfile() : false
  }

  /**
   * Whether the current core is audio only or not.
   * Returns true is there is no valid core, or the property is not found.
   *
   * @returns {boolean}
   */
  function isAudioOnly () {

    return has() ? state.core.isAudioOnly() : true
  }

  /**
   * Get Device for given UUID
   *
   * @param {string} uuid
   * @returns {?Device}
   */
  function getDevice (uuid) {

    return has() ? state.core.getDevice(uuid) : null
  }

  /**
   * @returns {Promise}
   */
  function retrieveProjectInfo () {

    var basServer

    basServer = getServer()

    return (basServer && basServer.getProjectInfo)
      ? basServer.getProjectInfo().then(_onProjectInfo)
      : Promise.reject(new Error('getProjectInfo not available'))
  }

  function _onProjectInfo (result) {

    $rootScope.$emit(BAS_CURRENT_CORE.EVT_CURRENT_CORE_PROJECT_INFO, result)

    return result
  }

  // region Event handlers

  /**
   * @private
   * @param {Object} event
   * @param {BasCoreContainer} basCoreContainer
   * @param {*} [extra] Extra event parameter, depends on the event
   */
  function _onCoreEvent (event, basCoreContainer, extra) {

    var _eventName

    if (state.core === basCoreContainer) {

      _eventName = event.name

      switch (_eventName) {
        case BAS_CORE.EVT_CORE_CONNECTED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_CONNECTED,
            basCoreContainer,
            extra
          )
          break
        case BAS_CORE.EVT_CORE_CORE_CONNECTED:

          if (extra) basCoreContainer.getAllSharedStorage()

          state.lastCoreConnectedTimestamp = Date.now()
          if (basCoreContainer.basServer) {
            state.lastConnectedCoreCID = basCoreContainer.basServer.cid
          }

          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
            basCoreContainer,
            extra
          )
          break
        case BAS_CORE.EVT_CORE_CORE_V2_CONNECTED:

          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_CORE_V2_CONNECTED,
            basCoreContainer,
            extra
          )
          break
        case BAS_CORE.EVT_CORE_VERSION:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_VERSION,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_USER_CREATED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_USER_CREATED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_PROFILE_CREATED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_PROFILE_CREATED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_IS_ADMIN:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_IS_ADMIN,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_LIVE_INFO:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_LIVE_INFO,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_SYSTEM:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_SYSTEM,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_HAS_UPDATE:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_HAS_UPDATE,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_AV_SOURCES_RECEIVED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_AV_SOURCES_RECEIVED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_MUSIC_RECEIVED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_ROOMS_RECEIVED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_ROOMS_RECEIVED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_DEVICES_UPDATED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_DEVICES_UPDATED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_CONNECTED_DEVICES_UPDATED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_CONNECTED_DEVICES_UPDATED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_SERVER_DEVICES_UPDATED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_SERVER_DEVICES_UPDATED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_MESSAGES_UPDATED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_MESSAGES_UPDATED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_CUSTOM_RADIOS:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_CUSTOM_RADIOS,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_CAMERAS_ORDER:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_CAMERAS_ORDER,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_OPEN_CLOSE_DEVICES_ORDER:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_OPEN_CLOSE_DEVICES_ORDER,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_SCHEDULES_ORDER:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_SCHEDULES_ORDER,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_TIMERS_ORDER:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_TIMERS_ORDER,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_SCENES_ORDER:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_SCENES_ORDER,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_SCENES_FAVOURITES:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_SCENES_FAVOURITES,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_LIGHT_GROUP_ORDER:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_LIGHT_GROUP_ORDER,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_LISA_TILES_ORDER:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_LISA_TILES_ORDER,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_LISA_SHOW_START:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_LISA_SHOW_START,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_TIDAL_LEGACY_AUTH_DONT_ASK:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_TIDAL_LEGACY_AUTH_DONT_ASK,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_MUSIC_LIBRARY_SCANNING_CHANGED:
          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_MUSIC_LIBRARY_SCANNING_CHANGED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_MUSIC_LIBRARY_CHANGED:

          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_MUSIC_LIBRARY_CHANGED,
            basCoreContainer
          )
          break
        case BAS_CORE.EVT_CORE_CONNECTION_JWT_REVOKED:

          $rootScope.$emit(
            BAS_CURRENT_CORE.EVT_CORE_CONNECTION_JWT_REVOKED,
            basCoreContainer
          )
          break
      }
    }
  }

  // endregion

  function _clearCoreListeners () {

    BasUtil.executeArray(_coreListeners)
    _coreListeners = []
  }

  function _setCoreListeners () {

    _clearCoreListeners()

    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_CONNECTED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_CORE_CONNECTED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_CORE_V2_CONNECTED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_VERSION,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_USER_CREATED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_PROFILE_CREATED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_SYSTEM,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_IS_ADMIN,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_LIVE_INFO,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_AV_SOURCES_RECEIVED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_MUSIC_RECEIVED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_ROOMS_RECEIVED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_DEVICES_UPDATED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_CONNECTED_DEVICES_UPDATED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_SERVER_DEVICES_UPDATED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_CONNECTION_JWT_REVOKED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_MESSAGES_UPDATED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_CUSTOM_RADIOS,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_CAMERAS_ORDER,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_OPEN_CLOSE_DEVICES_ORDER,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_SCHEDULES_ORDER,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_TIMERS_ORDER,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_SCENES_ORDER,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_SCENES_FAVOURITES,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_LIGHT_GROUP_ORDER,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_LISA_TILES_ORDER,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_LISA_SHOW_START,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_MUSIC_LIBRARY_SCANNING_CHANGED,
      _onCoreEvent
    ))
    _coreListeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_MUSIC_LIBRARY_CHANGED,
      _onCoreEvent
    ))
  }
}
