'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLibraryElement', [
    'ICONS',
    'BAS_LIBRARY',
    'BasFavouritesHelper',
    'BasUtilities',
    basLibraryElementFactory
  ])

/**
 * @typedef {Object} TCoversObj
 * @property {string} small
 * @property {string} medium
 * @property {string} large
 */

/**
 * @param {ICONS} ICONS
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BasFavouritesHelper} BasFavouritesHelper
 * @param {BasUtilities} BasUtilities
 * @returns BasLibraryElement
 */
function basLibraryElementFactory (
  ICONS,
  BAS_LIBRARY,
  BasFavouritesHelper,
  BasUtilities
) {
  /**
   * Object that represent a single UI element.
   *
   * @constructor
   */
  function BasLibraryElement () {

    /**
     * @instance
     * @type {string}
     */
    this.libraryType = BAS_LIBRARY.TYPE_BASE

    /**
     * @instance
     * @type {string}
     */
    this.title = ''

    /**
     * Optionally formatted (translated) string
     *
     * @instance
     * @type {string}
     */
    this.subtitle = ''

    /**
     * @instance
     * @type {number}
     */
    this.numberOfItems = 0

    /**
     * @instance
     * @type {number}
     */
    this.duration = 0

    /**
     * Formatted (translated) duration string
     *
     * @instance
     * @type {string}
     */
    this.durationString = '0:00'

    /**
     * @instance
     * @type {(number|string)}
     */
    this.id = 0

    /**
     * @type {string}
     */
    this.favouriteKey = ''

    /**
     * Asano favourite ID
     *
     * @instance
     * @type {string}
     */
    this.asanoFavouriteId = ''

    /**
     * Asano favourite uri
     *
     * @instance
     * @type {string}
     */
    this.asanoFavouriteUri = ''

    /**
     * Asano favourite uri service prefix
     *
     * @instance
     * @type {string}
     */
    this.servicePrefix = ''

    /**
     * @instance
     * @type {string}
     */
    this.repeatId = ''

    /**
     * @instance
     * @type {Object}
     */
    this.searchData = {}

    /**
     * @instance
     * @type {Covers}
     */
    this.covers = new Covers()

    /**
     * @instance
     * @type {Covers}
     */
    this.svgs = new Covers()

    /**
     * @instance
     * @type {boolean}
     */
    this.canBrowse = false

    /**
     * @instance
     * @type {boolean}
     */
    this.hasCover = false

    /**
     * CSS classes
     *
     * @instance
     * @type {Object}
     */
    this.class = {}

    // Create generic CSS class variables
    this.class[BasLibraryElement.CLASS_CAN_BROWSE] = false
    this.class[BasLibraryElement.CLASS_HAS_CONTEXT] = false
    this.class[BasLibraryElement.CLASS_CAN_ASANO_FAVOURITE] = false
    this.class[BasLibraryElement.CLASS_CAN_PLAY] = false
    this.class[BasLibraryElement.CLASS_HAS_DURATION] = false
    this.class[BasLibraryElement.CLASS_GREYED_OUT] = false
  }

  function Covers () {
    /**
     * @instance
     * @type {string}
     */
    this.small = ''

    /**
     * @instance
     * @type {string}
     */
    this.medium = ''

    /**
     * @instance
     * @type {string}
     */
    this.large = ''
  }

  // region CSS types

  /**
   * @constant {string}
   */
  BasLibraryElement.CLASS_CAN_BROWSE = 'bas-library-element-can-browse'

  /**
   * @constant {string}
   */
  BasLibraryElement.CLASS_HAS_CONTEXT = 'bas-library-element-has-context'

  /**
   * @constant {string}
   */
  BasLibraryElement.CLASS_CAN_ASANO_FAVOURITE =
    'bas-library-element-can-favourite'

  /**
   * @constant {string}
   */
  BasLibraryElement.CLASS_CAN_SELECT =
    'bas-library-element-can-select'

  /**
   * @constant {string}
   */
  BasLibraryElement.CLASS_CAN_PLAY = 'bas-library-element-can-play'

  /**
   * @constant {string}
   */
  BasLibraryElement.CLASS_HAS_DURATION =
    'bas-library-element-has-duration'

  /**
   * @constant {string}
   */
  BasLibraryElement.CLASS_GREYED_OUT = 'bas-library-element-greyed-out'

  // endregion

  // Static methods

  // Instance methods

  /**
   * @param {number} type
   * @param {string} value
   */
  BasLibraryElement.prototype.setAsanoFavouriteId = function (
    type,
    value
  ) {

    if (
      BasUtil.isVNumber(type) &&
      BasUtil.isNEString(value)
    ) {

      this.asanoFavouriteId = BasFavouritesHelper.getUuid(type, value)
      this.asanoFavouriteUri = (
        this.libraryType === BAS_LIBRARY.TYPE_LOCAL
          ? ''
          : this.servicePrefix
      ) + value

      this.favouriteKey = BasFavouritesHelper.getKey(type)

    } else {

      this.asanoFavouriteId = ''
    }
  }

  /**
   * @param {string} value
   */
  BasLibraryElement.prototype.setTitle = function (value) {

    if (BasUtil.isNEString(value)) {
      this.title = value
    } else {
      this.title = ''
    }
  }

  BasLibraryElement.prototype.setSubtitle = function (value) {
    if (BasUtil.isNEString(value)) {
      this.subtitle = value
    } else {
      this.subtitle = ''
    }
  }

  /**
   * @param {number} value
   */
  BasLibraryElement.prototype.setNumberOfItems = function (value) {

    // Check input
    if (BasUtil.isPNumber(value, true)) {
      this.numberOfItems = value
    } else {
      this.numberOfItems = 0
    }
  }

  /**
   * Sets numberOfItems and creates subtitle
   *
   * @param {number} value
   */
  BasLibraryElement.prototype.setNumberOfSongs = function (value) {

    // Set number of items
    this.setNumberOfItems(value)

    // Create subtitle
    this.subtitle = BasUtilities.translate(
      'mf_song',
      {
        count: value
      },
      'messageformat'
    )
  }

  /**
   * Fills in 'asanoFavouriteId'
   *
   * @param {number} type
   */
  BasLibraryElement.prototype.createAsanoFavouriteId = function (type) {

    // Create Asano Favourite ID
    this.asanoFavouriteId = BasFavouritesHelper.getUuid(
      type,
      this.type + ':' + this.id
    )

    this.asanoFavouriteUri = this.servicePrefix + this.repeatId

    this.favouriteKey = BasFavouritesHelper.getKey(type)
  }

  /**
   * Fills in 'repeatId'
   */
  BasLibraryElement.prototype.createRepeatId = function () {

    this.repeatId = this.type + ':' + this.id
  }

  /**
   * @returns {(number|string)}
   */
  BasLibraryElement.prototype.getSelectionKey = function () {
    return this.id
  }

  /**
   * @param {number} type
   * @param {(number|string)} id
   */
  BasLibraryElement.prototype.setId = function (type, id) {

    // Check input
    if (BasUtil.isPNumber(id, true) || BasUtil.isNEString(id)) {
      this.id = id
      this.createRepeatId()
      this.createAsanoFavouriteId(type)
    } else {
      this.id = 0
      this.asanoFavouriteId = ''
      this.repeatId = ''
    }
  }

  /**
   * @param {number} duration
   */
  BasLibraryElement.prototype.setDuration = function (duration) {

    // Check input
    if (BasUtil.isPNumber(duration, true)) {
      this.duration = duration
      this.durationString =
        BasUtil.durationToString(duration)
      this.class[BasLibraryElement.CLASS_HAS_DURATION] = true
    } else {
      this.duration = 0
      this.durationString = '0:00'
      this.class[BasLibraryElement.CLASS_HAS_DURATION] = false
    }
  }

  /**
   * @param {TCoversObj} object
   */
  BasLibraryElement.prototype.setCoverArt = function (object) {

    this.resetCoverArt()

    if (BasUtil.isObject(object)) {

      if (BasUtil.isNEString(object.small)) {

        this.covers.small = object.small

      } else if (BasUtil.isNEString(object.imgw100)) {

        this.covers.small = object.imgw100

      } else {

        this.svgs.small = ICONS.defaultMusic
      }

      if (BasUtil.isNEString(object.medium)) {

        this.covers.medium = object.medium

      } else {

        this.covers.medium = this.covers.small

        if (this.covers.medium === '') {

          this.svgs.medium = ICONS.defaultMusic
        }
      }

      if (BasUtil.isNEString(object.large)) {

        this.covers.large = object.large

      } else if (BasUtil.isNEString(object.orig)) {

        this.covers.large = object.orig

      } else {

        this.covers.large = this.covers.medium

        if (this.covers.large === '') {

          this.svgs.large = ICONS.defaultMusic
        }
      }
    }

    this.hasCover = true
  }

  BasLibraryElement.prototype.resetCoverArt = function () {

    this.svgs.small = ''
    this.svgs.medium = ''
    this.svgs.large = ''

    this.covers.small = ''
    this.covers.medium = ''
    this.covers.large = ''
  }

  /**
   * @param {boolean} value
   * @param {boolean} [addButton] default true
   */
  BasLibraryElement.prototype.setCanBrowse = function (value, addButton) {

    var addClass = true
    if (addButton === false) {
      addClass = false
    }

    this.canBrowse = value === true
    this.class[BasLibraryElement.CLASS_CAN_BROWSE] =
      addClass && value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryElement.prototype.setCanPlay = function (value) {
    this.class[BasLibraryElement.CLASS_CAN_PLAY] = value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryElement.prototype.setHasContext = function (value) {
    this.class[BasLibraryElement.CLASS_HAS_CONTEXT] = value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryElement.prototype.setCanAsanoFavourite = function (value) {
    this.class[BasLibraryElement.CLASS_CAN_ASANO_FAVOURITE] =
      value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryElement.prototype.setCanSelect = function (value) {
    this.class[BasLibraryElement.CLASS_CAN_SELECT] = value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryElement.prototype.setGreyedOut = function (value) {
    this.class[BasLibraryElement.CLASS_GREYED_OUT] = value === true
  }

  return BasLibraryElement
}
