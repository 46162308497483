'use strict'

import BasMemoryStorage from './basmemorystorage'

var K_ACT_KEYS = 'keys'
var K_ACT_SET_ITEM = 'setItem'
var K_ACT_GET_ITEM = 'getItem'
var K_ACT_REMOVE_ITEM = 'remove'
var K_ACT_CLEAR = 'clear'

/**
 * @typedef {Object} TBasNativeStorageActionResult
 * @property {boolean} finished
 * @property {string} [key]
 * @property {*} result
 * @property {*} error
 */

/**
 * @callback CBasNativeStorageActionCallback
 * @param {*} error
 * @param {TBasNativeStorageActionResult} result
 */

/**
 * @typedef {Object} TBasNativeStorageTask
 * @property {boolean} finished
 * @property {boolean} running
 * @property task
 * @property {TBasNativeStorageActionResult} [taskResult]
 */

/**
 * @constructor
 * @param nativeStorage
 */
function BasNativeStorage (nativeStorage) {

  this._nativeStorage = nativeStorage
  this._basMemoryStorage = new BasMemoryStorage()

  /**
   * The nativeQueue contains all tasks that need to be executed in-order.
   * This is necessary because the localStorage API is a synchronous API.
   * To keep the native storage in-sync with the in-memory localStorage,
   * all mutations should be done in-order.
   *
   * @private
   * @type {TBasNativeStorageTask[]}
   */
  this._nativeQueue = []
}

Object.defineProperty(BasNativeStorage.prototype, 'length', {
  get: function () {
    return this._basMemoryStorage.length
  }
})

/**
 * Read all values from native storage.
 * This needs to be pre-loaded because LocalStorage getItem is synchronous.
 *
 * @param callback
 */
BasNativeStorage.prototype.init = function (callback) {

  var _this, _cbCalled

  _this = this

  _cbCalled = false

  this._nativeStorage.keys(_onKeysSuccess, _onKeysError)

  function _onKeysSuccess (result) {

    var requests, length, i

    requests = []

    length = result.length
    for (i = 0; i < length; i++) {

      requests.push(_this._doNativeAction(
        K_ACT_GET_ITEM,
        result[i],
        null,
        _onGetItemCallback
      ))
    }

    if (_areAllRequestsFinished()) _cb(null)

    /**
     * @private
     * @param {*} _error
     * @param {TBasNativeStorageActionResult} getItemResult
     */
    function _onGetItemCallback (_error, getItemResult) {

      if (getItemResult && typeof getItemResult.result === 'string') {

        _this._basMemoryStorage.setItem(
          getItemResult.key,
          getItemResult.result
        )
      }

      if (_areAllRequestsFinished()) _cb(null)
    }

    /**
     * @private
     * @returns {boolean}
     */
    function _areAllRequestsFinished () {

      var _length, _i

      _length = requests.length
      for (_i = 0; _i < _length; _i++) {

        if (requests[_i].finished === false) return false
      }

      return true
    }
  }

  function _onKeysError (error) {

    _cb(error)
  }

  function _cb (error) {

    if (!_cbCalled) {

      _cbCalled = true

      if (typeof callback === 'function') callback(error)
    }
  }
}

BasNativeStorage.prototype.setItem = function (key, value) {

  this._basMemoryStorage.setItem(key, value)
  this._addTask(K_ACT_SET_ITEM, key, value)
}

BasNativeStorage.prototype.getItem = function (key) {

  var result

  result = this._basMemoryStorage.getItem(key)
  // Does NOT need to be executed
  // this._addTask(K_ACT_GET_ITEM, key);

  return result
}

BasNativeStorage.prototype.removeItem = function (key) {

  this._basMemoryStorage.removeItem(key)
  this._addTask(K_ACT_REMOVE_ITEM, key, null)
}

BasNativeStorage.prototype.clear = function () {

  this._basMemoryStorage.clear()
  this._addTask(K_ACT_CLEAR)
}

BasNativeStorage.prototype.keys = function () {

  return this._basMemoryStorage.keys()
}

/**
 * @private
 * @param {string} action
 * @param {(?string|undefined)} key
 * @param {(?string|undefined)} value
 * @param {CBasNativeStorageActionCallback} callback
 * @returns {TBasNativeStorageActionResult}
 */
BasNativeStorage.prototype._doNativeAction = function (
  action,
  key,
  value,
  callback
) {

  var _result

  _result = {
    finished: false,
    key: key,
    result: null,
    error: null
  }

  switch (action) {
    case K_ACT_SET_ITEM:
      this._nativeStorage[action](
        key,
        String(value),
        _onSuccess,
        _onError
      )
      break
    case K_ACT_GET_ITEM:
    case K_ACT_REMOVE_ITEM:
      this._nativeStorage[action](key, _onSuccess, _onError)
      break
    case K_ACT_KEYS:
    case K_ACT_CLEAR:
      this._nativeStorage[action](_onSuccess, _onError)
      break
  }

  return _result

  function _onSuccess (result) {

    _result.result = result
    _cb(null)
  }

  function _onError (error) {

    _result.error = error
    _cb(error)
  }

  function _cb (error) {

    _result.finished = true

    if (typeof callback === 'function') callback(error, _result)
  }
}

/**
 * @private
 * @param {string} action
 * @param {string} [key]
 * @param {string} [value]
 */
BasNativeStorage.prototype._addTask = function (
  action,
  key,
  value
) {
  this._nativeQueue.push({
    finished: false,
    running: false,
    task: this._doNativeAction.bind(this, action, key, value),
    taskResult: undefined
  })

  this._runTasks()
}

BasNativeStorage.prototype._runTasks = function () {

  var _this, _firstTask

  _this = this
  _firstTask = this._nativeQueue[0]

  if (_firstTask) {

    if (_firstTask.running) {

      // Do nothing, other function context is handling the running tasks

    } else {

      _runTask(_firstTask)
    }
  }

  /**
   * @private
   * @param {TBasNativeStorageTask} task
   */
  function _runTask (task) {

    task.taskResult = task.task(_onTaskCallback)

    /**
     * @private
     */
    function _onTaskCallback () {

      task.running = false
      task.finished = true

      _this._nativeQueue.shift()

      _this._runTasks()
    }
  }
}

export default BasNativeStorage
