'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('shadeModalCtrl', [
    '$scope',
    '$rootScope',
    'BAS_SPLASH',
    'BasShade',
    'close',
    'shade',
    shadeModalCtrl
  ])

/**
 * @param $scope
 * @param $rootScope
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param BasShade
 * @param close
 * @param shade
 */
function shadeModalCtrl (
  $scope,
  $rootScope,
  BAS_SPLASH,
  BasShade,
  close,
  shade
) {
  var modal = this

  var _listeners = []

  modal.close = close
  modal.BasShade = BasShade

  /**
   * @type {BasShade}
   */
  modal.shade = shade

  modal.onPositionChange = onPositionChange
  modal.onRotationChange = onRotationChange

  init()

  function init () {
    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)
  }

  function onPositionChange () {

    if (isValidShade()) modal.shade.changePosition()
  }

  function onRotationChange () {

    if (isValidShade()) modal.shade.changeRotation()
  }

  function isValidShade () {

    return modal.shade instanceof BasShade
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
