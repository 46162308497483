{
  "rooms": {
    "Root": {
      "devices": [
        "HomeSceneController",
        "HomeEnergy",
        "HomeEnergySolar",
        "PoolEnergy",
        "HomeEnergyGas",
        "HomeEnergyWater",
        "HomeTimers",
        "HomeFrontDoorCamera",
        "HomeStreetCamera",
        "HomeYardCamera"
      ],
      "functions": [
        "scenes",
        "energyMeter",
        "security"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/home_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/home_01.jpg"
      },
      "level": 3,
      "name": "",
      "order": 0,
      "tags": [],
      "type": 0,
      "uuid": "Root"
    },
    "Home": {
      "devices": [
        "homeFrontGate",
        "homeGarage",
        "homeFrontDoor",
        "homeBackWindow",
        "homeGardenGate"
      ],
      "level": 2,
      "name": "",
      "order": 1,
      "parent": "Root",
      "tags": [],
      "type": 1,
      "uuid": "Home"
    },
    "GroundFloor": {
      "devices": [],
      "level": 1,
      "name": "",
      "order": 2,
      "parent": "Home",
      "tags": [],
      "type": 51,
      "uuid": "GroundFloor"
    },
    "FirstFloor": {
      "devices": [],
      "level": 1,
      "name": "",
      "order": 3,
      "parent": "Home",
      "tags": [],
      "type": 53,
      "uuid": "FirstFloor"
    },
    "Basement": {
      "devices": [],
      "level": 1,
      "name": "",
      "order": 4,
      "parent": "Home",
      "tags": [],
      "type": 52,
      "uuid": "Basement"
    },
    "Garden": {
      "devices": [],
      "level": 1,
      "name": "",
      "order": 5,
      "parent": "Home",
      "tags": [],
      "type": 55,
      "uuid": "Garden"
    },
    "GroundFloorHall": {
      "devices": [
        "HallPendantLight",
        "HallDownLightsFront",
        "HallDownLightArt",
        "HallStaircase",
        "HallSceneController",
        "HallThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/hall_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/hall_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 6,
      "parent": "GroundFloor",
      "tags": [],
      "type": 122,
      "uuid": "GroundFloorHall",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 41,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceGeorge"
          }
        }
      }
    },
    "GroundFloorLivingRoom": {
      "devices": [
        "LivingRoomDownLightsWall",
        "LivingRoomDownLightsCenter",
        "LivingRoomDownLightsCabinet",
        "LivingRoomDownLightsWindow",
        "LivingRoomHueLamp",
        "LivingRoomFloorLamp",
        "LivingRoomLuminaire",
        "LivingRoomSceneController",
        "LivingRoomLeft",
        "LivingRoomCenter",
        "LivingRoomRight",
        "LivingRoomFireplace",
        "LivingRoomThermostat1",
        "LivingRoomThermostat2"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av",
        "generic"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/living_room_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/living_room_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 7,
      "parent": "GroundFloor",
      "tags": [],
      "type": 102,
      "uuid": "GroundFloorLivingRoom",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "dsp": {
              "equalisers": [
                {
                  "id": 0,
                  "gain": 5,
                  "frequency": 64
                },
                {
                  "id": 1,
                  "gain": 0,
                  "frequency": 250
                },
                {
                  "id": 2,
                  "gain": 5,
                  "frequency": 1000
                },
                {
                  "id": 3,
                  "gain": -6,
                  "frequency": 4000
                },
                {
                  "id": 4,
                  "gain": 1,
                  "frequency": 16000
                }
              ]
            },
            "treble": 0,
            "volume": 41,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceJane"
          }
        },
        "video": {
          "reachable": true,
          "capabilities": {
            "on": "rw",
            "volumeDown": "x",
            "volumeUp": "x",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceLivingRoomSamsungTVInternal",
                "sourceLivingRoomRoku",
                "sourceLivingRoomPlayStation"
              ]
            }
          },
          "state": {
            "mute": false,
            "on": false,
            "source": "",
            "volume": 70
          }
        }
      }
    },
    "GroundFloorDiningRoom": {
      "devices": [
        "DiningRoomChandelier",
        "DiningRoomDownLightsLeft",
        "DiningRoomDownLightsRight",
        "DiningRoomWallLight",
        "DiningRoomSockets",
        "DiningRoomSceneController",
        "DiningRoomLeft",
        "DiningRoomRight",
        "DiningRoomThermostat",
        "diningRoomC02Threshold"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av",
        "generic"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/dining_room_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/dining_room_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 8,
      "parent": "GroundFloor",
      "tags": [],
      "type": 121,
      "uuid": "GroundFloorDiningRoom",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 40,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceJane"
          }
        }
      }
    },
    "GroundFloorKitchen": {
      "devices": [
        "KitchenPendantLight",
        "KitchenTable",
        "KitchenCabinet",
        "KitchenTrackLight",
        "KitchenSceneController",
        "KitchenCurtain",
        "KitchenThermostat",
        "kitchenWindowLeft",
        "kitchenWindowRight"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "openClose",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/kitchen_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/kitchen_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 9,
      "parent": "GroundFloor",
      "tags": [],
      "type": 103,
      "uuid": "GroundFloorKitchen",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 40,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceJane"
          }
        }
      }
    },
    "GroundFloorFamilyRoom": {
      "devices": [
        "FamilyRoomPendantLight",
        "FamilyRoomDownLightsWindow",
        "FamilyRoomDownLightsWall",
        "FamilyRoomHueLamp",
        "FamilyRoomFloorLamp",
        "FamilyRoomSockets",
        "FamilyRoomSceneController",
        "FamilyRoomLeft",
        "FamilyRoomRight",
        "FamilyRoomThermostat1",
        "FamilyRoomThermostat2"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/family_room_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/family_room_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 10,
      "parent": "GroundFloor",
      "tags": [],
      "type": 126,
      "uuid": "GroundFloorFamilyRoom",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 47,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceJudy"
          }
        }
      }
    },
    "GroundFloorBar": {
      "devices": [
        "BarDownLightsCenter",
        "BarSceneController",
        "BarCurtain",
        "BarThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/bar_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/bar_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 11,
      "parent": "GroundFloor",
      "tags": [],
      "type": 120,
      "uuid": "GroundFloorBar",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 16,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceGeorge"
          }
        }
      }
    },
    "FirstFloorHomeOffice": {
      "devices": [
        "HomeOfficeDesk",
        "HomeOfficeDownLightsLeft",
        "HomeOfficeDownLightsRight",
        "HomeOfficeFloorLamp",
        "HomeOfficeSceneController",
        "HomeOfficeCurtain",
        "HomeOfficeThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/home_office_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/home_office_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 12,
      "parent": "FirstFloor",
      "tags": [],
      "type": 111,
      "uuid": "FirstFloorHomeOffice",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 16,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceGeorge"
          }
        }
      }
    },
    "FirstFloorMasterBedroom": {
      "devices": [
        "MasterBedroomDownLights",
        "MasterBedroomBedsideLeft",
        "MasterBedroomBedsideRight",
        "MasterBedroomFloorLamp",
        "MasterBedroomSceneController",
        "MasterBedroomCurtain",
        "MasterBedroomShutter",
        "MasterBedroomThermostat",
        "masterBedroomPanoramicWindow",
        "masterBedroomWindowSide",
        "masterBedroomSkylight"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "openClose",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/bedroom_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/bedroom_01.jpg"
      },
      "level": 0,
      "name": "Master",
      "order": 13,
      "parent": "FirstFloor",
      "tags": [],
      "type": 104,
      "uuid": "FirstFloorMasterBedroom",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 31,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceGeorge"
          }
        },
        "video": {
          "reachable": true,
          "capabilities": {
            "on": "rw",
            "volumeDown": "x",
            "volumeUp": "x",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceMasterBedroomSonyTVInternal"
              ]
            }
          },
          "state": {
            "mute": false,
            "on": false,
            "source": "",
            "volume": 43
          }
        }
      }
    },
    "FirstFloorMasterBathroom": {
      "devices": [
        "MasterBathroomDownLights",
        "MasterBathroomShower",
        "MasterBathroomBath",
        "MasterBathroomSink",
        "MasterBathroomSceneController",
        "MasterBathroomLeft",
        "MasterBathroomRight",
        "MasterBathroomThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/bathroom_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/bathroom_01.jpg"
      },
      "level": 0,
      "name": "Master",
      "order": 14,
      "parent": "FirstFloor",
      "tags": [],
      "type": 105,
      "uuid": "FirstFloorMasterBathroom",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 35,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceJane"
          }
        }
      }
    },
    "FirstFloorBedroomJudy": {
      "devices": [
        "BedroomJudyDownLights",
        "BedroomJudyBedside",
        "BedroomJudyDesk",
        "BedroomJudySceneController",
        "BedroomJudyCurtain",
        "BedroomJudyShutter",
        "BedroomJudyThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/bedroom_02.jpg",
        "imgw200": "img/demo/rooms/thumbs/bedroom_02.jpg"
      },
      "level": 0,
      "name": "Judy",
      "order": 15,
      "parent": "FirstFloor",
      "tags": [],
      "type": 104,
      "uuid": "FirstFloorBedroomJudy",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 30,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceJudy"
          }
        }
      }
    },
    "FirstFloorBedroomElroy": {
      "devices": [
        "BedroomElroyWallLight",
        "BedroomElroyDownLights",
        "BedroomElroyWhiteLights",
        "BedroomElroyBedside",
        "BedroomElroyDesk",
        "BedroomElroySceneController",
        "BedroomElroyCurtain",
        "BedroomElroyShutter",
        "BedroomElroyShade",
        "BedroomElroyThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/bedroom_03.jpg",
        "imgw200": "img/demo/rooms/thumbs/bedroom_03.jpg"
      },
      "level": 0,
      "name": "Elroy",
      "order": 16,
      "parent": "FirstFloor",
      "tags": [],
      "type": 104,
      "uuid": "FirstFloorBedroomElroy",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 37,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceElroy"
          }
        }
      }
    },
    "FirstFloorBathroomChildren": {
      "devices": [
        "BathroomChildrenDownLights",
        "BathroomChildrenShower",
        "BathroomChildrenSink",
        "BathroomChildrenSceneController",
        "BathroomChildrenShade",
        "BathroomChildrenThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/bathroom_02.jpg",
        "imgw200": "img/demo/rooms/thumbs/bathroom_02.jpg"
      },
      "level": 0,
      "name": "Children",
      "order": 17,
      "parent": "FirstFloor",
      "tags": [],
      "type": 105,
      "uuid": "FirstFloorBathroomChildren",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 25,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceJudy"
          }
        }
      }
    },
    "FirstFloorGuestRoom": {
      "devices": [
        "GuestRoomDownLights",
        "GuestRoomBedsideLeft",
        "GuestRoomBedsideRight",
        "GuestRoomFloorLamp",
        "GuestRoomSceneController",
        "GuestRoomCurtain",
        "GuestRoomShutter",
        "GuestRoomThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "windowTreatments",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/guest_room_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/guest_room_01.jpg"
      },
      "level": 0,
      "name": "Guest",
      "order": 18,
      "parent": "FirstFloor",
      "tags": [],
      "type": 104,
      "uuid": "FirstFloorGuestRoom",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 25,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceGeorge"
          }
        }
      }
    },
    "BasementHomeCinema": {
      "devices": [
        "HomeCinemaDownLightsFront",
        "HomeCinemaDownLightCenter",
        "HomeCinemaDownLightsRear",
        "HomeCinemaSceneController",
        "HomeCinemaThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/home_cinema_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/home_cinema_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 19,
      "parent": "Basement",
      "tags": [],
      "type": 116,
      "uuid": "BasementHomeCinema",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 25,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceHomeCinemaBluRay"
          }
        },
        "video": {
          "reachable": true,
          "capabilities": {
            "on": "rw",
            "volumeDown": "x",
            "volumeUp": "x",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceHomeCinemaBluRay",
                "sourceHomeCinemaSetTopBox",
                "sourceHomeCinemaAppleTV"
              ]
            }
          },
          "state": {
            "mute": false,
            "on": true,
            "source": "sourceHomeCinemaBluRay",
            "volume": 40
          }
        }
      }
    },
    "BasementFitness": {
      "devices": [
        "FitnessDownLightsFront",
        "FitnessDownLightCenter",
        "FitnessDownLightsRear",
        "FitnessSceneController",
        "FitnessThermostat"
      ],
      "functions": [
        "audio",
        "scenes",
        "lights",
        "thermostat",
        "av"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/gym_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/gym_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 20,
      "parent": "Basement",
      "tags": [],
      "type": 115,
      "uuid": "BasementFitness",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 50,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceJudy"
          }
        }
      }
    },
    "GardenTerrace": {
      "devices": [
        "TerraceDownLights",
        "TerraceGarden",
        "TerraceSprinklers",
        "TerraceFountain",
        "genericDeviceV2Sockets",
        "genericDeviceV2Pool",
        "TerraceSceneController",
        "TerraceWeatherStation"
      ],
      "functions": [
        "audio",
        "scenes",
        "lights",
        "weatherStation",
        "av",
        "generic"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/terrace_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/terrace_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 21,
      "parent": "Garden",
      "tags": [],
      "type": 113,
      "uuid": "GardenTerrace",
      "av": {
        "audio": {
          "type": "asano",
          "reachable": true,
          "capabilities": {
            "alert": "r",
            "on": "rw",
            "bass": "rw",
            "treble": "rw",
            "volume": "rw"
          },
          "attributes": {
            "source": {
              "values": [
                "sourceGeorge",
                "sourceJane",
                "sourceJudy",
                "sourceElroy",
                "sourceTurntable",
                "sourceIpodJudy",
                "sourceIpodElroy",
                "sourceLivingRoomTV",
                "sourceMasterBedroomTV",
                "sourceHomeCinemaTV"
              ]
            }
          },
          "state": {
            "bass": 0,
            "treble": 0,
            "volume": 25,
            "mute": false,
            "startupVolume": 25,
            "on": true,
            "source": "sourceJudy"
          }
        }
      }
    },
    "GardenPool": {
      "devices": [
        "PoolPoolLights",
        "PoolUpLights",
        "PoolGarden",
        "PoolSceneController",
        "PoolCover",
        "PoolPoolShutter"
      ],
      "functions": [
        "scenes",
        "windowTreatments",
        "lights",
        "generic"
      ],
      "images": {
        "blurw1600": "img/demo/rooms/blurred/pool_01.jpg",
        "imgw200": "img/demo/rooms/thumbs/pool_01.jpg"
      },
      "level": 0,
      "name": "",
      "order": 22,
      "parent": "Garden",
      "tags": [],
      "type": 109,
      "uuid": "GardenPool"
    }
  }
}
