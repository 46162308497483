'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasCoreContainer', [
    '$rootScope',
    'BAS_API',
    'BAS_ERRORS',
    'BAS_CORE',
    'BasServerStorage',
    'SourcesHelper',
    'BasError',
    basCoreContainerFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BAS_CORE} BAS_CORE
 * @param {BasServerStorage} BasServerStorage
 * @param {SourcesHelper} SourcesHelper
 * @param BasError
 * @returns BasCoreContainer
 */
function basCoreContainerFactory (
  $rootScope,
  BAS_API,
  BAS_ERRORS,
  BAS_CORE,
  BasServerStorage,
  SourcesHelper,
  BasError
) {
  /**
   * @constructor
   * @param {BasServer} basServer
   */
  function BasCoreContainer (basServer) {

    /**
     * @type {?BasServer}
     */
    this.basServer = (BasUtil.isObject(basServer) && basServer.getUsers)
      ? basServer
      : null

    /**
     * @private
     * @type {string}
     */
    this._storedKey = ''

    /**
     * @type {?BasCore}
     */
    this.core = null

    /**
     * @type {?boolean}
     */
    this.supportsAVFully = false

    /**
     * @type {?boolean}
     */
    this.supportsAVPartially = false

    this._coreListeners = []

    this._handleHasUpdate = this._onHasUpdate.bind(this)
    this._handleConnected = this._onConnected.bind(this)
    this._handleCoreConnected = this._onCoreConnected.bind(this)
    this._handleCoreV2Connected =
      this._onCoreV2Connected.bind(this)
    this._handleVersion = this._onVersion.bind(this)
    this._handleUserCreated = this._onUserCreated.bind(this)
    this._handleProfileCreated = this._onProfileCreated.bind(this)
    this._handleIsAdmin = this._onIsAdmin.bind(this)
    this._handleLiveInfo = this._onLiveInfo.bind(this)
    this._handleAVSourcesUpdated =
      this._onAVSourcesUpdated.bind(this)
    this._handleMusicConfigUpdated = this._onMusicConfigUpdated.bind(this)
    this._handleRoomsUpdated = this._onRoomsUpdated.bind(this)
    this._handleConnectedDevicesUpdated =
      this._onConnectedDevicesUpdated.bind(this)
    this._handleDevicesUpdated =
      this._onDevicesUpdated.bind(this)
    this._handleServersUpdated = this._onServersUpdated.bind(this)
    this._handleConnectionJWTRevoked = this._onConnectionJWTRevoked.bind(this)
    this._handleSystemProperties = this._onSystemProperties.bind(this)
    this._handleMessagesUpdated = this._onMessagesUpdated.bind(this)
    this._handleCustomRadios = this._onCustomRadios.bind(this)
    this._handleCameraOrderUpdated = this._onCamerasOrderUpdated.bind(this)
    this._handleOpenCloseDevicesOrderUpdated =
      this._onOpenCloseDevicesOrderUpdated.bind(this)
    this._handleSchedulesOrderUpdated =
      this._onSchedulesOrderUpdated.bind(this)
    this._handleTimersOrderUpdated = this._onTimersOrderUpdated.bind(this)
    this._handleScenesOrderUpdated = this._onScenesOrderUpdated.bind(this)
    this._handleScenesFavouritesUpdated =
      this._onScenesFavouritesUpdated.bind(this)
    this._handleLightGroupOrderUpdated =
      this._onLightGroupOrderUpdated.bind(this)
    this._handleLisaTilesOrderUpdated =
      this._onLisaTilesOrderUpdated.bind(this)
    this._handleLisaShowStartUpdated =
      this._onLisaShowStartUpdated.bind(this)
    this._handleTidalLegacyAuthDontAskUpdated =
      this._onTidalLegacyAuthDontAskUpdated.bind(this)
    this._handleMusicLibraryScanningChanged =
      this._onMusicLibraryScanningChanged.bind(this)
    this._handleMusicLibraryChanged =
      this._onMusicLibraryChanged.bind(this)
  }

  /**
   * Saves the BasServer information to storage.
   * Also saves credential/user information if available.
   * The stored key is kept on this BasCoreContainer instance.
   *
   * @param {boolean} [saveAsLast]
   */
  BasCoreContainer.prototype.saveToStorage = function (saveAsLast) {

    if (this.basServer) {

      this._storedKey = BasServerStorage.saveServer(this.basServer)

      if (saveAsLast) this.saveAsLastToStorage()
    }
  }

  /**
   * Saves the current connection info as last server.
   */
  BasCoreContainer.prototype.saveAsLastToStorage = function () {

    var _credentials

    if (this._storedKey) {

      BasServerStorage.saveLastServerKey(this._storedKey)

      if (this.basServer) {

        _credentials = this.basServer.credentials

        if (_credentials.length) {

          BasServerStorage.saveLastUserKey(
            this._storedKey,
            _credentials[0].user
          )
        }
      }
    }
  }

  /**
   * @returns {?BasStoredServer}
   */
  BasCoreContainer.prototype.getStoredServer = function () {

    return this._storedKey
      ? BasServerStorage.getServer(this._storedKey)
      : null
  }

  BasCoreContainer.prototype.removeLastUserCredentials = function () {

    if (this._storedKey) {

      BasServerStorage.removeLastUserLoginCredentials(this._storedKey)
    }
  }

  BasCoreContainer.prototype.removeLastUser = function () {

    if (this._storedKey) {

      BasServerStorage.removeLastUser(this._storedKey)
    }
  }

  /**
   * @returns {?BasStoredUser}
   */
  BasCoreContainer.prototype.getStoredLastUser = function () {

    var _storedServer, _serverSettings

    _storedServer = this.getStoredServer()

    if (_storedServer && _storedServer.serverSettingsKey) {

      _serverSettings = BasServerStorage
        .getServerSettings(_storedServer.serverSettingsKey)

      if (_serverSettings) return _serverSettings.getLastUser()
    }

    return null
  }

  /**
   * @returns {string}
   */
  BasCoreContainer.prototype.getLastRoomIdForLastUser = function () {

    var _lastUser

    _lastUser = this.getStoredLastUser()

    return _lastUser ? _lastUser.room : ''
  }

  /**
   * @param {string} roomId
   */
  BasCoreContainer.prototype.saveLastRoomIdForLastUser = function (roomId) {

    if (this._storedKey) {

      BasServerStorage.saveLastRoomIdForLastUser(this._storedKey, roomId)
    }
  }

  /**
   * @returns {?Object}
   */
  BasCoreContainer.prototype.getStoredSettingsForLastUser = function () {

    var _lastUser

    _lastUser = this.getStoredLastUser()

    return _lastUser ? _lastUser.settings : null
  }

  /**
   * @param {Object} settings
   */
  BasCoreContainer.prototype.saveSettingsForLastUser = function (settings) {

    if (this._storedKey) {

      BasServerStorage.saveSettingsForLastUser(this._storedKey, settings)
    }
  }

  /**
   * @returns {?Object}
   */
  BasCoreContainer.prototype.getStoredHistoryForLastUser = function () {

    var _lastUser

    _lastUser = this.getStoredLastUser()

    return _lastUser ? _lastUser.history : null
  }

  /**
   * @param {Object} history
   */
  BasCoreContainer.prototype.saveHistoryForLastUser = function (history) {

    if (this._storedKey) {

      BasServerStorage.saveHistoryForLastUser(this._storedKey, history)
    }
  }

  /**
   * @param {TCoreCredentials} credentials
   * @returns {Promise}
   */
  BasCoreContainer.prototype.changeProfile = function (credentials) {

    if (!credentials) {

      return Promise.reject(new BasError(
        BAS_ERRORS.T_INVALID_INPUT,
        undefined,
        'Invalid credentials'
      ))
    }

    if (!this.basServer) {

      return Promise.reject(new BasError(
        BAS_ERRORS.T_INTERNAL,
        undefined,
        BAS_ERRORS.M_NO_SERVER
      ))
    }

    this.saveToStorage(true)

    if (this.core) {

      this.core.logout().catch(_onLogoutError)
    }

    const handleChangeProfileDisconnectCore = () => {
      if (this.hasCore()) {

        this.core.setBasServer(this.basServer)
        if (credentials.jwt) {
          this.basServer.clearCredentials()
          this.basServer.setJWT(credentials.jwt)
        }
        return this.core.connectCore()
      }

      return Promise.reject(new BasError(
        BAS_ERRORS.T_INTERNAL,
        undefined,
        BAS_ERRORS.M_NO_CORE
      ))
    }

    return this.basServer.disconnectCore().then(
      handleChangeProfileDisconnectCore,
      handleChangeProfileDisconnectCore
    )
  }

  /**
   * @returns {Promise}
   */
  BasCoreContainer.prototype.logout = function () {

    return this.hasCore()
      ? this.core.logout()
      : Promise.reject(new BasError(
        BAS_ERRORS.T_INTERNAL,
        undefined,
        BAS_ERRORS.M_NO_CORE
      ))
  }

  /**
   * @returns {Promise}
   */
  BasCoreContainer.prototype.restart = function () {

    var _basServer

    if (this.hasCore()) {

      if (this.core.supportsCheckingUpdates) {

        this.core.restartSystem()
        return Promise.resolve()

      } else {

        _basServer = this.core.server

        return _basServer
          ? _basServer.restart()
          : Promise.reject(new BasError(
            BAS_ERRORS.T_INTERNAL,
            this.core,
            BAS_ERRORS.M_NO_SERVER
          ))
      }
    }

    return Promise.reject(new BasError(
      BAS_ERRORS.T_INTERNAL,
      undefined,
      BAS_ERRORS.M_NO_CORE
    ))
  }

  /**
   * @returns {Promise}
   */
  BasCoreContainer.prototype.connectCore = function () {

    if (this.basServer) {

      if (this.core) {

        if (this.core.server === this.basServer) {

          // Empty

        } else {

          this.core.setBasServer(this.basServer)
        }

      } else {

        this.core = new BAS_API.BasCore(this.basServer)
        this._setCoreListeners()
      }

      return this.core.connectCore()
    }

    return Promise.reject(new BasError(
      BAS_ERRORS.T_INTERNAL,
      undefined,
      BAS_ERRORS.M_NO_SERVER
    ))
  }

  /**
   * Terminates all connections
   */
  BasCoreContainer.prototype.disconnect = function () {

    if (this.hasCore()) this.core.disconnect()
    if (this.basServer) this.basServer.disconnect()
  }

  /**
   * @returns {boolean}
   */
  BasCoreContainer.prototype.hasCredentials = function () {

    return !!(this.basServer && this.basServer.credentials.length)
  }

  /**
   * @returns {boolean}
   */
  BasCoreContainer.prototype.hasCore = function () {

    return BasUtil.isObject(this.core)
  }

  /**
   * @returns {boolean}
   */
  BasCoreContainer.prototype.isDemo = function () {

    var _basServer

    if (this.hasCore()) {

      _basServer = this.core.server

      return _basServer
        ? _basServer.isDemo()
        : this.basServer
          ? this.basServer.isDemo()
          : false
    }

    return this.basServer
      ? this.basServer.isDemo()
      : false
  }

  BasCoreContainer.prototype.checkForUpdates = function () {

    var _system

    if (this.hasCore()) {

      if (this.core.supportsCheckingUpdates) {

        _system = this.core.system

        if (_system) {

          _system.checkForUpdates()
          return Promise.resolve()
        }

      } else {

        if (this.basServer) return this.basServer.startUpdateCheck()
      }
    }

    return Promise.reject(new BasError(
      BAS_ERRORS.T_INTERNAL,
      undefined,
      BAS_ERRORS.M_NO_CORE
    ))
  }

  /**
   * Check for supported API version.
   * Returns NaN if no BasCore instance is available
   *
   * @returns {number}
   */
  BasCoreContainer.prototype.supportedApi = function () {

    return this.hasCore() ? this.core.supportedApi() : NaN
  }

  /**
   * Get core MAC address
   *
   * @deprecated Use server device(s)
   * @returns {string}
   */
  BasCoreContainer.prototype.legacyGetServerMacAddress = function () {

    return this.hasCore() ? this.core.mac : ''
  }

  /**
   * @returns {?Profile}
   */
  BasCoreContainer.prototype.getProfile = function () {

    return this.hasCore() ? this.core.profile : null
  }

  /**
   * Get the current logged in user.
   * Returns null if core is not valid
   *
   * @returns {?User}
   */
  BasCoreContainer.prototype.getUser = function () {

    return this.hasCore() ? this.core.user : null
  }

  /**
   * Get Device for given UUID
   *
   * @param {string} uuid
   * @returns {?Device}
   */
  BasCoreContainer.prototype.getDevice = function (uuid) {

    var device

    if (this.hasCore()) {

      device = this.core.devices[uuid]

      if (device) return device
    }

    return null
  }

  /**
   * @returns {External[]}
   */
  BasCoreContainer.prototype.getExternals = function () {

    var sources

    if (this.hasCore()) {

      sources = this.core.externals

      if (BasUtil.isObject(sources)) {

        return BasUtil.objectToArray(sources)
      }
    }

    return []
  }

  /**
   * Whether there is home dashboard/page access or not.
   * Returns true:
   *  - if server is not available
   *  - if server does not have dashboard access property
   *
   * @returns {boolean}
   */
  BasCoreContainer.prototype.hasHomePage = function () {

    var _profile

    if (this.hasCore()) {

      _profile = this.getProfile()

      if (_profile) return _profile.dashboardAccess
    }

    return true
  }

  /**
   * Whether this profile is allowed to make/start intercom calls
   *
   * @returns {boolean}
   */
  BasCoreContainer.prototype.hasIntercomAccess = function () {

    var _profile

    if (this.hasCore()) {

      _profile = this.getProfile()

      if (_profile) return _profile.intercomAccess
    }

    return false
  }

  /**
   * Whether there is alarms access or not.
   *
   * @returns {boolean}
   */
  BasCoreContainer.prototype.hasAlarmsAccess = function () {

    var _profile

    // AlarmsAccess is only possible in an audio only config
    if (this.hasCore() && this.isAudioOnly()) {

      // Enforce profile check if supported
      if (this.core.supportsAlarmsAccess) {

        _profile = this.getProfile()

        if (_profile) return _profile.alarmsAccess

      } else {

        return true
      }
    }

    return false
  }

  /**
   * Whether this profile is an ellie profile
   *
   * @returns {boolean}
   */
  BasCoreContainer.prototype.isLoggedInWithCoreClientProfile = function () {

    var _profile

    if (this.hasCore()) {

      _profile = this.getProfile()

      if (_profile) return _profile.isCoreClientDevice
    }

    return false
  }

  /**
   * Whether the core is audio only or not. Returns true if there is no core.
   *
   * @returns {boolean}
   */
  BasCoreContainer.prototype.isAudioOnly = function () {

    if (this.hasCore() && this.core.system) {

      return this.core.system.audioOnly
    }

    return true
  }

  /**
   * Get the default source for current user.
   * Returns a source ID of 0 if no default source was found.
   *
   * @returns {number}
   */
  BasCoreContainer.prototype.getDefaultSourceId = function () {

    var user

    if (this.hasCore()) {

      user = this.core.supportsProfile
        ? this.getProfile()
        : this.getUser()
    }

    return user
      ? user.defaultSource
      : 0
  }

  /**
   * Get the default source for current user.
   * Returns an empty string if no default source was found.
   *
   * @returns {string}
   */
  BasCoreContainer.prototype.getDefaultSourceUuid = function () {

    var user

    if (this.hasCore()) {

      user = this.core.supportsProfile
        ? this.getProfile()
        : this.getUser()
    }

    return user
      ? user.defaultSourceUuid
      : ''
  }

  /**
   * Get the default source for current user.
   * Returns null if no default source was found.
   *
   * @returns {?BasSource}
   */
  BasCoreContainer.prototype.getDefaultSource = function () {

    var id, source

    id = this.getDefaultSourceUuid()

    if (id) {

      source = SourcesHelper.getBasSource(id)

      if (source) return source
    }

    id = this.getDefaultSourceId()

    if (id) {

      source = SourcesHelper.getPlayer(id)

      if (source) return source
    }

    return null
  }

  /**
   * Determines the source to turn on.
   * Based on default source, otherwise first source.
   * Returns a source ID of 0 if no valid source was found.
   *
   * @returns {number}
   */
  BasCoreContainer.prototype.getSourceToTurnOn = function () {

    var _defaultSourceId, array, length, i, entry

    _defaultSourceId = this.getDefaultSourceId()

    if (_defaultSourceId > 0) return _defaultSourceId

    if (this.hasCore()) {

      array = this.core.getSortedPlayers()

      length = array.length
      for (i = 0; i < length; i++) {

        entry = array[i]
        if (entry) return entry.id
      }

      array = this.getExternals()

      length = array.length
      for (i = 0; i < length; i++) {

        entry = array[i]
        if (entry) return entry.id
      }
    }

    return 0
  }

  /**
   * @returns {string}
   */
  BasCoreContainer.prototype.getTemperatureUnit = function () {

    var _system, _unit

    if (this.hasCore()) {

      _system = this.core.system

      if (_system) {

        _unit = _system.temperatureUnit

        if (BasUtil.isNEString(_unit)) return _unit
      }
    }

    return BAS_API.CONSTANTS.TU_CELSIUS
  }

  /**
   * @param {string} [locale]
   * @returns {Promise}
   */
  BasCoreContainer.prototype.retrieveWeatherData = function (locale) {

    var params

    if (this.hasCore()) {

      if (BasUtil.isNEString(locale)) {

        params = {}
        params['locale'] = locale
      }

      return this.core.retrieveWeatherData(params)
    }

    return Promise.reject(new BasError(
      BAS_ERRORS.T_INTERNAL,
      undefined,
      BAS_ERRORS.M_NO_CORE
    ))
  }

  /**
   * @returns {Promise}
   */
  BasCoreContainer.prototype.getAllSharedStorage = function () {

    if (this.hasCore() && this.core.sharedServerStorage) {

      return this.core.sharedServerStorage.dirty
        ? this.core.sharedServerStorage.getAll()
        : Promise.resolve()
    }

    return Promise.reject(new BasError(
      BAS_ERRORS.T_INTERNAL,
      undefined,
      BAS_ERRORS.M_NO_CORE
    ))
  }

  BasCoreContainer.prototype._clearCore = function () {

    this._clearCoreListeners()
    if (this.core) this.core.destroy()
    this.core = null
  }

  BasCoreContainer.prototype.destroy = function () {

    this._clearCore()

    if (this.basServer) this.basServer.destroy()
    this.basServer = null
  }

  // region Event handlers

  /**
   * @private
   */
  BasCoreContainer.prototype._onHasUpdate = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_HAS_UPDATE, this)
  }

  /**
   * @private
   * @param {boolean} isConnected
   */
  BasCoreContainer.prototype._onConnected = function (isConnected) {

    $rootScope.$emit(BAS_CORE.EVT_CORE_CONNECTED, this, isConnected)
  }

  /**
   * @private
   * @param {boolean} isConnected
   */
  BasCoreContainer.prototype._onCoreConnected = function (isConnected) {

    $rootScope.$emit(BAS_CORE.EVT_CORE_CORE_CONNECTED, this, isConnected)
  }

  /**
   * @private
   * @param {boolean} isConnected
   */
  BasCoreContainer.prototype._onCoreV2Connected = function (
    isConnected
  ) {

    $rootScope.$emit(
      BAS_CORE.EVT_CORE_CORE_V2_CONNECTED,
      this,
      isConnected
    )
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onVersion = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_VERSION, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onUserCreated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_USER_CREATED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onProfileCreated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_PROFILE_CREATED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onIsAdmin = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_IS_ADMIN, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onLiveInfo = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_LIVE_INFO, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onSystemProperties = function () {

    this.supportsAVFully = (this.hasCore() && this.core.system)
      ? (
          // Server flags
          this.core.system.supportsAV &&
          this.core.system.supportsAVAsano &&
          // Server version check
          this.core.supportsAsanoAV
        ) ||
        // Server version 7.0.0 or higher: AV is always fully supported
        this.core.supportsAvAudioDsp
      : false
    this.supportsAVPartially = (this.hasCore() && this.core.system)
      ? (
          this.core.system.supportsAV &&
          !this.supportsAVFully
        )
      : false

    $rootScope.$emit(BAS_CORE.EVT_CORE_SYSTEM, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onAVSourcesUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_AV_SOURCES_RECEIVED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onMusicConfigUpdated = function () {

    // TODO check default rooms support

    $rootScope.$emit(BAS_CORE.EVT_CORE_MUSIC_RECEIVED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onRoomsUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_ROOMS_RECEIVED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onDevicesUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_DEVICES_UPDATED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onConnectedDevicesUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_CONNECTED_DEVICES_UPDATED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onServersUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_SERVER_DEVICES_UPDATED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onConnectionJWTRevoked = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_CONNECTION_JWT_REVOKED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onMessagesUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_MESSAGES_UPDATED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onCustomRadios = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_CUSTOM_RADIOS, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onCamerasOrderUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_CAMERAS_ORDER, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onOpenCloseDevicesOrderUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_OPEN_CLOSE_DEVICES_ORDER, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onSchedulesOrderUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_SCHEDULES_ORDER, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onTimersOrderUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_TIMERS_ORDER, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onScenesOrderUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_SCENES_ORDER, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onScenesFavouritesUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_SCENES_FAVOURITES, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onLightGroupOrderUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_LIGHT_GROUP_ORDER, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onLisaTilesOrderUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_LISA_TILES_ORDER, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onLisaShowStartUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_LISA_SHOW_START, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onTidalLegacyAuthDontAskUpdated = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_TIDAL_LEGACY_AUTH_DONT_ASK, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onMusicLibraryScanningChanged = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_MUSIC_LIBRARY_SCANNING_CHANGED, this)
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._onMusicLibraryChanged = function () {

    $rootScope.$emit(BAS_CORE.EVT_CORE_MUSIC_LIBRARY_CHANGED, this)
  }

  // endregion

  /**
   * @private
   */
  BasCoreContainer.prototype._setCoreListeners = function () {

    this._clearCoreListeners()

    if (this.hasCore()) {

      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_HAS_UPDATE,
        this._handleHasUpdate
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_CONNECTED,
        this._handleConnected
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_CORE_CONNECTED,
        this._handleCoreConnected
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_CORE_V2_CONNECTED,
        this._handleCoreV2Connected
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_VERSION,
        this._handleVersion
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_USER_CREATED,
        this._handleUserCreated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.profile,
        BAS_API.Profile.EVT_PROFILE_CREATED,
        this._handleProfileCreated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.profile,
        BAS_API.Profile.EVT_IS_ADMIN,
        this._handleIsAdmin
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.live,
        BAS_API.Live.EVT_LIVE_INFO_UPDATED,
        this._handleLiveInfo
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.system,
        BAS_API.System.EVT_SYSTEM_PROPERTIES,
        this._handleSystemProperties
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_AV_SOURCES_UPDATED,
        this._handleAVSourcesUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_MUSIC_CONFIG_UPDATED,
        this._handleMusicConfigUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_ROOMS_UPDATED,
        this._handleRoomsUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_DEVICES_UPDATED,
        this._handleDevicesUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_CONNECTED_DEVICES_UPDATED,
        this._handleConnectedDevicesUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_SERVERS_UPDATED,
        this._handleServersUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core,
        BAS_API.BasCore.EVT_CONNECTION_JWT_REVOKED,
        this._handleConnectionJWTRevoked
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.system,
        BAS_API.System.EVT_MESSAGES_UPDATED,
        this._handleMessagesUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.tunein,
        BAS_API.TuneIn.EVT_CUSTOM_UPDATED,
        this._handleCustomRadios
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_CAMERAS_ORDER_UPDATED,
        this._handleCameraOrderUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_OPEN_CLOSE_DEVICES_ORDER_UPDATED,
        this._handleOpenCloseDevicesOrderUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_SCHEDULES_ORDER_UPDATED,
        this._handleSchedulesOrderUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_TIMERS_ORDER_UPDATED,
        this._handleTimersOrderUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_SCENES_ORDER_UPDATED,
        this._handleScenesOrderUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_SCENES_FAVOURITES_UPDATED,
        this._handleScenesFavouritesUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_LIGHT_GROUP_ORDER_UPDATED,
        this._handleLightGroupOrderUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_LISA_TILES_ORDER_UPDATED,
        this._handleLisaTilesOrderUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_LISA_SHOW_START_UPDATED,
        this._handleLisaShowStartUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.sharedServerStorage,
        BAS_API.SharedServerStorage.EVT_TIDAL_LEGACY_AUTH_DONT_ASK_UPDATED,
        this._handleTidalLegacyAuthDontAskUpdated
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.musicLibrary,
        BAS_API.MusicLibrary.EVT_SCANNING_CHANGED,
        this._handleMusicLibraryScanningChanged
      ))
      this._coreListeners.push(BasUtil.setEventListener(
        this.core.musicLibrary,
        BAS_API.MusicLibrary.EVT_LIBRARY_CHANGED,
        this._handleMusicLibraryChanged
      ))
    }
  }

  /**
   * @private
   */
  BasCoreContainer.prototype._clearCoreListeners = function () {

    BasUtil.executeArray(this._coreListeners)
    this._coreListeners = []
  }

  return BasCoreContainer

  function _onLogoutError () {

    // Empty
  }
}
