'use strict'

angular
  .module('basalteApp')
  .factory('basMissingTranslationHandler', missingTranslationHandler)

function missingTranslationHandler () {

  /**
   * @param {string} translationId
   * @returns {string}
   */
  function basMissingTranslationHandler (translationId) {

    // eslint-disable-next-line no-console
    console.warn('Missing translation', translationId)

    return ''
  }

  return basMissingTranslationHandler
}
