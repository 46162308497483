'use strict'

/* global Hammer */

import * as BasUtil from '@basalte/bas-util'

var directiveName = 'basHm'

var attrTap = directiveName + 'Tap'
var attrPress = directiveName + 'Press'
var attrPressUp = directiveName + 'Pressup'

var attrStopPropagation = directiveName + 'StopPropagation'
var attrPreventDefault = directiveName + 'PreventDefault'

angular
  .module('basalteApp')
  .directive(directiveName, [
    '$parse',
    basPress
  ])

function basPress ($parse) {

  // TODO Use controller and inject $window to access Hammer

  return {
    restrict: 'A',
    link: link
  }

  function link (scope, element, attr) {

    var _value
    var _fnTapped, _fnPressed, _fnPressedUp
    var _hmElement, _recTap, _recPress, _pressed, _tapped, _pressedUp
    var _preventDefault, _stopPropagation
    var _clickListener
    var _resetTapTimeoutId, _resetPressTimeoutId, _resetPressUpTimeoutId
    var _tapEvent, _pressEvent, _pressUpEvent

    var ANTI_GHOST_DELAY = 200
    var ANTI_GHOST_THRESHOLD = 10

    _pressed = false
    _tapped = false
    _pressedUp = false

    _preventDefault = false
    _stopPropagation = false

    _resetTapTimeoutId = 0
    _resetPressTimeoutId = 0
    _resetPressUpTimeoutId = 0

    if (attr[attrTap]) {

      _fnTapped = $parse(attr[attrTap]).bind(null, scope)
    }

    if (attr[attrPress]) {

      _fnPressed = $parse(attr[attrPress]).bind(null, scope)
    }

    if (attr[attrPressUp]) {

      _fnPressedUp = $parse(attr[attrPressUp]).bind(null, scope)
    }

    _value = attr[attrPreventDefault]

    if (_value && _value.toLowerCase() === 'true') {

      _preventDefault = true
    }

    _value = attr[attrStopPropagation]

    if (_value && _value.toLowerCase() === 'true') {

      _stopPropagation = true
    }

    if (_fnTapped || _fnPressed || _fnPressedUp) {

      scope.$on('$destroy', onDestroy)

      // Set regular click listener
      _clickListener = BasUtil.setDOMListener(
        document,
        'click',
        onClick,
        true
      )

      _hmElement = new Hammer.Manager(element[0])
      _hmElement.options.domEvents = true

      _recTap = new Hammer.Tap({
        time: 399
      })
      _recPress = new Hammer.Press({
        time: 400
      })

      _hmElement.add(_recTap)
      _hmElement.add(_recPress)

      _hmElement.on('tap', onTap)
      _hmElement.on('press', onPress)
      _hmElement.on('pressup', onPressUp)
    }

    function onClick (clickEvent) {

      var event

      if (_pressed) event = _pressEvent
      if (_tapped) event = _tapEvent
      if (_pressedUp) event = _pressUpEvent

      if (event) {

        // Possible ghost click detected, check threshold

        if (
          Math.abs(event.clientX - clickEvent.clientX) <=
          ANTI_GHOST_THRESHOLD &&
          Math.abs(event.clientY - clickEvent.clientY) <=
          ANTI_GHOST_THRESHOLD
        ) {

          // Ghost click is within threshold, cancel it

          clickEvent.preventDefault()
          clickEvent.stopPropagation()

          _pressedUp = false
          _pressed = false
          _tapped = false
        }
      }
    }

    function onTap (event) {

      _preProcessHammerEvent(event)

      _tapped = true
      _tapEvent = event.srcEvent

      clearTimeout(_resetTapTimeoutId)

      scope.$apply(_fnTapped)

      _resetTapTimeoutId = setTimeout(
        _onResetTapTimeout,
        ANTI_GHOST_DELAY
      )
    }

    function onPress (event) {

      _preProcessHammerEvent(event)

      _pressed = true
      _pressEvent = event.srcEvent

      clearTimeout(_resetPressTimeoutId)

      scope.$apply(_executeHandler)

      _resetPressTimeoutId = setTimeout(
        _onResetPressTimeout,
        ANTI_GHOST_DELAY
      )

      function _executeHandler () {

        _fnPressed({ $event: event })
      }
    }

    function onPressUp (event) {

      _preProcessHammerEvent(event)

      if (event && event.srcEvent) {

        event.srcEvent.preventDefault()
        event.srcEvent.stopPropagation()
      }

      _pressedUp = true
      _pressUpEvent = event.srcEvent

      clearTimeout(_resetPressUpTimeoutId)

      scope.$apply(_fnPressedUp)

      _resetPressUpTimeoutId = setTimeout(
        _onResetPressUpTimeout,
        ANTI_GHOST_DELAY
      )
    }

    function _onResetTapTimeout () {

      _tapped = false
      _tapEvent = undefined
    }

    function _onResetPressTimeout () {

      _pressed = false
      _pressEvent = undefined
    }

    function _onResetPressUpTimeout () {

      _pressedUp = false
      _pressUpEvent = undefined
    }

    function _preProcessHammerEvent (event) {

      if (_preventDefault) {

        if (event) {

          if (event.preventDefault) {

            event.preventDefault()
          }

          if (event.srcEvent &&
            event.srcEvent.preventDefault) {

            event.srcEvent.preventDefault()
          }
        }
      }

      if (_stopPropagation) {

        if (event &&
          event.srcEvent &&
          event.srcEvent.stopPropagation) {

          event.srcEvent.stopPropagation()
        }
      }
    }

    function onDestroy () {

      setTimeout(BasUtil.execute, ANTI_GHOST_DELAY, _clickListener)
      _hmElement.destroy()

      clearTimeout(_resetTapTimeoutId)
      clearTimeout(_resetPressTimeoutId)
      clearTimeout(_resetPressUpTimeoutId)
    }
  }
}
