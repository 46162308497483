'use strict'

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

/**
 * @constructor
 * @param {?Object} [profile]
 */
function BasProfile (profile) {

  this.username = ''
  this.avatarPath = ''
  this.uuid = ''
  this.jwt = ''
  this.hasPassword = false
  this.isCloudAccount = false
  this.isAdmin = false
  this.defaultSource = BasProfile.NO_DEFAULT_SOURCE

  if (BasUtil.isObject(profile)) {

    if (BasUtil.isNEString(profile[P.USERNAME])) {

      this.username = profile[P.USERNAME]
    }

    if (BasUtil.isNEString(profile[P.AVATAR])) {

      this.avatarPath =
        CONSTANTS.PATH_API_IMAGES +
        '/' +
        profile[P.AVATAR] +
        '/imgw500'
    }

    if (BasUtil.isNEString(profile[P.JWT])) {

      this.jwt = profile[P.JWT]
    }

    if (BasUtil.isNEString(profile[P.UUID])) {

      this.uuid = profile[P.UUID]
    }

    if (BasUtil.isBool(profile[P.PASSWORD])) {

      this.hasPassword = profile[P.PASSWORD]
    }

    if (BasUtil.isBool(profile[P.ADMIN])) {

      this.isAdmin = profile[P.ADMIN]
    }

    if (BasUtil.isPNumber(profile[P.DEFAULT])) {

      this.defaultSource = profile[P.DEFAULT]
    }
  }
}

/**
 * @constant {number}
 */
BasProfile.NO_DEFAULT_SOURCE = -1

/**
 * @param {?Object} obj
 * @returns {boolean}
 */
BasProfile.isUserObject = function (obj) {
  return (
    BasUtil.isObject(obj) &&
    BasUtil.isNEString(obj[P.USERNAME])
  )
}

/**
 * Checks if an user/profile object from the server is an admin user/profile
 *
 * @param {Object} obj
 * @returns {boolean}
 */
BasProfile.isAdmin = function (obj) {
  return BasUtil.isObject(obj) && obj[P.ADMIN] === true
}

module.exports = BasProfile
