'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasGenericDeviceControl', [
    'BAS_API',
    'BasUtilities',
    basGenericDeviceControlFactory
  ])

/**
 * @param BAS_API
 * @param {BasUtilities} BasUtilities
 * @returns BasGenericDeviceControl
 */
function basGenericDeviceControlFactory (
  BAS_API,
  BasUtilities
) {
  var REQUEST_TIMEOUT = 2000

  var CSS_DEVICE_SHOW_VALUE = 'brs-show-value'
  var CSS_DEVICE_SHOW_INDICATOR = 'brs-show-indicator'
  var CSS_DEVICE_SHOW_TOGGLE = 'brs-show-toggle'
  var CSS_DEVICE_SHOW_SLIDER = 'brs-show-slider'
  var CSS_DEVICE_SHOW_SLIDER_VALUE = 'brs-show-slider-value'
  var CSS_DEVICE_SHOW_TRIGGER = 'brs-show-trigger'
  var CSS_DEVICE_SHOW_EDIT = 'brs-show-edit'
  var CSS_DEVICE_VALUE_ACTIVE = 'brs-value-active'
  var CSS_DEVICE_VALUE_UPPER = 'brs-value-upper'
  var CSS_DEVICE_HAS_UNIT = 'brs-has-unit'

  /**
   * @constructor
   * @param {GenericDeviceControl} data
   * @param {BasGenericDevice} device
   */
  function BasGenericDeviceControl (
    data,
    device
  ) {
    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * @type {(boolean|number|string)}
     */
    this.value = 0

    /**
     * @type {string}
     */
    this.uiValue = ''

    /**
     * Used for rounding/normalization of a value
     *
     * @type {number}
     */
    this.step = 1

    /**
     * Step precision, kept up to date as 'step' property changes
     *
     * @type {number}
     */
    this.stepPrecision = 0

    /**
     * @type {boolean}
     */
    this._canToggle = false

    this.toggleTimeout = null

    /**
     * @type {boolean}
     */
    this.canEdit = false

    /**
     * @type {string}
     */
    this.unit = ''

    /**
     * @type {string}
     */
    this.actionText = ''

    /**
     * @type {string}
     */
    this.uiActionText = ''

    /**
     * @type {Object}
     */
    this.css = {}
    this._resetCss()

    this._device = device

    this._data = data

    this._enableToggleInput = this.enableToggleInput.bind(this)

    if (data) this.parseData(data)
  }

  BasGenericDeviceControl.prototype.toggle = function toggle () {

    if (this.canEdit && this._canToggle) {

      this.value = !this.value
      this.updateUiValue()
      this._uiToggleActive(this.value)
      this.commit()

      this._canToggle = false

      this.toggleTimeout = setTimeout(
        this._enableToggleInput,
        REQUEST_TIMEOUT
      )
    }
  }

  BasGenericDeviceControl.prototype.enableToggleInput = function () {

    // Clear timeouts
    clearTimeout(this.toggleTimeout)

    // Enable input
    this._canToggle = true
  }

  /**
   * Checks whether control is "active" or not.
   * "active" means have a non-default value.
   *
   * @returns {boolean}
   */
  BasGenericDeviceControl.prototype.isActive = function () {

    return !!this.value
  }

  BasGenericDeviceControl.prototype.onSliderChange = function () {

    this.commit()
  }

  BasGenericDeviceControl.prototype.onNonDebouncedSliderChange = function () {

    // We need to update the UI value for the slider for numeric inputs each
    //  time the slider value has changed, since showing the value
    //  directly in UI can cause javascript rounding errors to be visualized and
    //  jumpiness when using the slider with decimal precision.

    // NOTE: this function will be executed very rapidly: we should do as little
    //  as possible here
    if (
      this.type === BAS_API.GenericDeviceControl.T_SLIDER ||
      this.type === BAS_API.GenericDeviceControl.T_NUMERIC_INPUT
    ) {

      this.updateUiValue()
    }
  }

  BasGenericDeviceControl.prototype.updateUiValue = function () {

    switch (this.type) {

      case BAS_API.GenericDeviceControl.T_TRIGGER:
      case BAS_API.GenericDeviceControl.T_TOGGLE:
      case BAS_API.GenericDeviceControl.T_STRING:

        // Just make string out of value
        this.uiValue = '' + this.value
        break

      case BAS_API.GenericDeviceControl.T_BOOLEAN:

        if (this._data) {

          this.uiValue = BasGenericDeviceControl._getUiBooleanValue(
            this._data.value,
            this._data.getLabelType(),
            this._data.getLabelInverted(),
            this._data.getLabelCustomTrue(),
            this._data.getLabelCustomFalse()
          )
        }
        break

      case BAS_API.GenericDeviceControl.T_NUMBER:

        // No step configuration possible, always round to 2 decimal values
        this.uiValue = BasUtil.toFixedRounded(
          this.value,
          2
        )
        break

      case BAS_API.GenericDeviceControl.T_PERCENTAGE:

        this.uiValue = BasUtil.toFixedRounded(
          this.value,
          this.stepPrecision
        )
        break

      case BAS_API.GenericDeviceControl.T_SLIDER:
      case BAS_API.GenericDeviceControl.T_NUMERIC_INPUT:

        // Don't use 'BasUtil.toFixedRounded' because this can result in
        //  flickery values when changing the value quickly by using the slider
        //  (e.g. going from 10 to 10.5)
        this.uiValue = this.value.toFixed(this.stepPrecision)
        break
    }
  }

  BasGenericDeviceControl.prototype.onTriggerActive = function () {

    if (this.canEdit) {

      this.value = true
      this.updateUiValue()
      this.commit()
    }
  }

  BasGenericDeviceControl.prototype.onTriggerRelease = function () {

    if (this.canEdit) {

      this.value = false
      this.updateUiValue()
      this.commit()
    }
  }

  /**
   * Sets and sends out a new value for a NUMERIC_INPUT generic control
   *
   * @param {number} value
   */
  BasGenericDeviceControl.prototype.setValue = function (value) {

    if (
      this.canEdit &&
      this.type === BAS_API.GenericDeviceControl.T_NUMERIC_INPUT
    ) {
      this.value = BasUtil.getCorrectedValue(
        value,
        {
          min: this.min,
          max: this.max,
          step: this.step
        }
      )
      this.updateUiValue()
      this.commit()
    }
  }

  BasGenericDeviceControl.prototype.commit = function () {

    if (this.canEdit && this._device) {

      switch (this.type) {

        case BAS_API.GenericDeviceControl.T_TRIGGER:
        case BAS_API.GenericDeviceControl.T_TOGGLE:
        case BAS_API.GenericDeviceControl.T_NUMERIC_INPUT:

          this._device.commit(this.uuid, this.value)
          break

        case BAS_API.GenericDeviceControl.T_SLIDER:

          this._device.commit(this.uuid, this.value / 100)
          break
      }
    }
  }

  /**
   * @param {GenericDeviceControl} [data]
   */
  BasGenericDeviceControl.prototype.parseData = function (data) {

    this._resetCss()
    this._resetCapabilities()
    this._resetLabels()

    if (BasUtil.isObject(data)) {

      this.uuid = data.uuid
      this.name = data.name
      this.type = data.type

      if (!BasUtil.isNEString(this.name)) {

        this.name = '-'
      }

      this._setUnit(data.labelUnit)
      this._setUiActionText(data.labelActionText)

      if (data.allowsRead(BAS_API.GenericDeviceControl.VALUE)) {

        switch (this.type) {

          case BAS_API.GenericDeviceControl.T_TOGGLE:

            this._uiToggleToggle(true)
            this.value = data.value

            break

          case BAS_API.GenericDeviceControl.T_SLIDER:

            this._uiToggleSlider(true)
            this._uiToggleSliderValue(true)
            this.min = 0
            this.max = 100
            this.step = 1
            this.stepPrecision = 0
            this.value = BasUtil.getCorrectedValue(
              Math.round(data.value * 100),
              {
                min: this.min,
                max: this.max,
                step: this.step
              }
            )

            break

          case BAS_API.GenericDeviceControl.T_TRIGGER:

            this.value = data.value
            this._uiToggleTrigger(true)

            break

          case BAS_API.GenericDeviceControl.T_NUMBER:

            this.value = data.value
            this._uiToggleValue(true)

            break

          case BAS_API.GenericDeviceControl.T_PERCENTAGE:

            this.step = data.step
            this.stepPrecision = BasUtil.getPrecision(this.step)
            this.value = data.value * 100
            this._uiToggleValue(true)

            break

          case BAS_API.GenericDeviceControl.T_NUMERIC_INPUT:

            this.min = data.min
            this.max = data.max
            this.step = data.step
            this.stepPrecision = BasUtil.getPrecision(this.step)
            this.value = BasUtil.getCorrectedValue(
              data.value,
              {
                min: this.min,
                max: this.max,
                step: this.step
              }
            )
            this._uiToggleValue(true)

            if (
              data.inputTypes.indexOf(
                BAS_API.GenericDeviceControl.A_IT_KEYPAD
              ) > -1
            ) {
              this._uiToggleEdit(true)
            }

            if (
              data.inputTypes.indexOf(
                BAS_API.GenericDeviceControl.A_IT_SLIDER
              ) > -1
            ) {
              this._uiToggleSlider(true)
              this._uiToggleSliderValue(false)
            }

            break

          case BAS_API.GenericDeviceControl.T_BOOLEAN:

            this.value = data.value
            this._uiToggleValue(true)
            this._uiToggleValueUpper(true)

            break

          case BAS_API.GenericDeviceControl.T_STRING:

            this.value = data.value
            this._uiToggleValue(true)

            break
        }

        this.updateUiValue(data)

        if (data.allowsWrite(BAS_API.GenericDeviceControl.VALUE)) {

          // Check for number type to work around server bug
          this.canEdit =
            this.type !== BAS_API.GenericDeviceControl.T_NUMBER
        }
      }
    }

    this._uiToggleActive(this.value)
    this.updateTranslation()
    this.enableToggleInput()
  }

  /**
   * @param {TSceneDeviceControl} control
   */
  BasGenericDeviceControl.prototype.parseSceneControl = function (control) {

    if (BasUtil.isBool(control.value)) {

      this.value = control.value
      this.updateUiValue()
      this.canEdit = true

    } else if (BasUtil.isVNumber(control.value)) {

      // Percentage slider [0-100] is saved as [0-1], while generic number input
      //  is saved as-is
      this.value = this.type === BAS_API.GenericDeviceControl.T_NUMERIC_INPUT
        ? control.value / 100
        : control.value
      this.updateUiValue()
      this.canEdit = true
    }

    this._uiToggleActive(this.value)
  }

  /**
   * @returns {TSceneDeviceControl}
   */
  BasGenericDeviceControl.prototype.getSceneObj = function () {

    var result = {}

    if (this.canEdit) {
      // Percentage slider [0-100] is saved as [0-1], while generic number input
      //  is saved as-is
      result.value = this.type === BAS_API.GenericDeviceControl.T_NUMERIC_INPUT
        ? this.value * 100
        : this.value
    }

    return result
  }

  /**
   * @returns {boolean}
   */
  BasGenericDeviceControl.prototype.isEditable = function () {

    // Check for number type to work around server bug

    return (
      BasUtil.isObject(this._data) &&
      this._data.allowsWrite(BAS_API.GenericDeviceControl.VALUE) &&
      this._data.type !== BAS_API.GenericDeviceControl.T_NUMBER
    )
  }

  /**
   * @param {BasGenericDeviceControl} control
   */
  BasGenericDeviceControl.prototype.syncBasGenericDeviceControl =
    function (control) {

      if (control instanceof BasGenericDeviceControl) {

        this.value = control.value
        this.updateUiValue()
        this._uiToggleActive(this.value)
      }
    }

  /**
   * String representation of device state
   *
   * @returns {string}
   */
  BasGenericDeviceControl.prototype.getString = function () {

    var result

    result = ''

    switch (this.type) {

      case BAS_API.GenericDeviceControl.T_TOGGLE:
        result += 'toggle'
        break

      case BAS_API.GenericDeviceControl.T_SLIDER:
        result += 'slider'
        break

      case BAS_API.GenericDeviceControl.T_TRIGGER:
        result += 'trigger'
        break

      case BAS_API.GenericDeviceControl.T_NUMBER:
        result += 'number'
        break

      case BAS_API.GenericDeviceControl.T_PERCENTAGE:
        result += 'percentage'
        break

      case BAS_API.GenericDeviceControl.T_NUMERIC_INPUT:
        result += 'genericNumber'
        break

      case BAS_API.GenericDeviceControl.T_BOOLEAN:
        result += 'boolean'
        break

      case BAS_API.GenericDeviceControl.T_STRING:
        result += 'string'
        break
    }

    result += this.uiValue

    return result
  }

  BasGenericDeviceControl.prototype.updateTranslation = function () {

    if (this.type === BAS_API.GenericDeviceControl.T_BOOLEAN) {

      this.updateUiValue()
    }

    this._syncUiActionText()
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasGenericDeviceControl.prototype._uiToggleValue = function (force) {

    this.css[CSS_DEVICE_SHOW_VALUE] = BasUtil.isBool(force)
      ? force
      : !this.css[CSS_DEVICE_SHOW_VALUE]
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasGenericDeviceControl.prototype._uiToggleIndicator = function (force) {

    this.css[CSS_DEVICE_SHOW_INDICATOR] = BasUtil.isBool(force)
      ? force
      : !this.css[CSS_DEVICE_SHOW_INDICATOR]
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasGenericDeviceControl.prototype._uiToggleToggle = function (force) {

    this.css[CSS_DEVICE_SHOW_TOGGLE] = BasUtil.isBool(force)
      ? force
      : !this.css[CSS_DEVICE_SHOW_TOGGLE]
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasGenericDeviceControl.prototype._uiToggleSlider = function (force) {

    this.css[CSS_DEVICE_SHOW_SLIDER] = BasUtil.isBool(force)
      ? force
      : !this.css[CSS_DEVICE_SHOW_SLIDER]
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasGenericDeviceControl.prototype._uiToggleSliderValue = function (force) {

    this.css[CSS_DEVICE_SHOW_SLIDER_VALUE] = BasUtil.isBool(force)
      ? force
      : !this.css[CSS_DEVICE_SHOW_SLIDER_VALUE]
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasGenericDeviceControl.prototype._uiToggleTrigger = function (force) {

    this.css[CSS_DEVICE_SHOW_TRIGGER] = BasUtil.isBool(force)
      ? force
      : !this.css[CSS_DEVICE_SHOW_TRIGGER]
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasGenericDeviceControl.prototype._uiToggleEdit = function (force) {

    this.css[CSS_DEVICE_SHOW_EDIT] = BasUtil.isBool(force)
      ? force
      : !this.css[CSS_DEVICE_SHOW_EDIT]
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasGenericDeviceControl.prototype._uiToggleActive = function (force) {

    this.css[CSS_DEVICE_VALUE_ACTIVE] = BasUtil.isBool(force)
      ? force
      : !this.css[CSS_DEVICE_VALUE_ACTIVE]
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasGenericDeviceControl.prototype._uiToggleValueUpper = function (force) {

    this.css[CSS_DEVICE_VALUE_UPPER] = BasUtil.isBool(force)
      ? force
      : !this.css[CSS_DEVICE_VALUE_UPPER]
  }

  /**
   * @private
   * @param {string} unit
   */
  BasGenericDeviceControl.prototype._setUnit = function (unit) {

    this.unit = BasUtil.isNEString(unit) ? unit : ''
    this._syncUnitCss()
  }

  /**
   * @private
   */
  BasGenericDeviceControl.prototype._syncUnitCss = function () {

    this.css[CSS_DEVICE_HAS_UNIT] = BasUtil.isNEString(this.unit)
  }

  /**
   * @private
   * @param {string} text
   */
  BasGenericDeviceControl.prototype._setUiActionText = function (text) {

    this.actionText = BasUtil.isNEString(text) ? text : ''
    this._syncUiActionText()
  }

  /**
   * @private
   */
  BasGenericDeviceControl.prototype._syncUiActionText = function () {

    this.uiActionText = BasUtil.isNEString(this.actionText)
      ? this.actionText
      : BasUtilities.translate('act_push')
  }

  BasGenericDeviceControl.prototype._resetLabels = function () {

    this._setUiActionText('')
    this.unit = ''
  }

  BasGenericDeviceControl.prototype._resetCss = function resetCss () {

    this.css[CSS_DEVICE_SHOW_VALUE] = false
    this.css[CSS_DEVICE_SHOW_INDICATOR] = false
    this.css[CSS_DEVICE_SHOW_TOGGLE] = false
    this.css[CSS_DEVICE_SHOW_SLIDER] = false
    this.css[CSS_DEVICE_SHOW_SLIDER_VALUE] = false
    this.css[CSS_DEVICE_SHOW_TRIGGER] = false
    this.css[CSS_DEVICE_SHOW_EDIT] = false
    this.css[CSS_DEVICE_HAS_UNIT] = false
    this.css[CSS_DEVICE_VALUE_ACTIVE] = false
    this.css[CSS_DEVICE_VALUE_UPPER] = false
  }

  BasGenericDeviceControl.prototype._resetCapabilities = function () {

    this.canEdit = false
  }

  /**
   * Clears the device listeners and the device reference
   */
  BasGenericDeviceControl.prototype.clear = function clear () {

    this._device = null
  }

  /**
   * @private
   * @param {boolean} value
   * @param {number} type
   * @param {boolean} inverted
   * @param {string} customTrue
   * @param {string} customFalse
   * @returns {string}
   */
  BasGenericDeviceControl._getUiBooleanValue = function (
    value,
    type,
    inverted,
    customTrue,
    customFalse
  ) {
    var _value, _result, _translate

    _result = ''

    _translate = true

    _value = inverted
      ? !value
      : value

    switch (type) {
      case BAS_API.GenericDeviceControl.TYPES.ON_OFF:
        _result = _value
          ? 'state_on'
          : 'state_off'
        break
      case BAS_API.GenericDeviceControl.TYPES.OPEN_CLOSED:
        _result = _value
          ? 'state_opened'
          : 'state_closed'
        break
      case BAS_API.GenericDeviceControl.TYPES.IN_OUT:
        _result = _value
          ? 'state_in'
          : 'state_out'
        break
      case BAS_API.GenericDeviceControl.TYPES.UP_DOWN:
        _result = _value
          ? 'state_up'
          : 'state_down'
        break
      case BAS_API.GenericDeviceControl.TYPES.LEFT_RIGHT:
        _result = _value
          ? 'state_left'
          : 'state_right'
        break
      case BAS_API.GenericDeviceControl.TYPES.TRUE_FALSE:
        _result = _value
          ? 'state_true'
          : 'state_false'
        break
      case BAS_API.GenericDeviceControl.TYPES.ARMED_DISARMED:
        _result = _value
          ? 'state_armed'
          : 'state_disarmed'
        break
      case BAS_API.GenericDeviceControl.TYPES.STARTED_STOPPED:
        _result = _value
          ? 'state_started'
          : 'state_stopped'
        break
      case BAS_API.GenericDeviceControl.TYPES.ENABLED_DISABLED:
        _result = _value
          ? 'state_enabled'
          : 'state_disabled'
        break
      case BAS_API.GenericDeviceControl.TYPES.HIGH_LOW:
        _result = _value
          ? 'state_high'
          : 'state_low'
        break
      case BAS_API.GenericDeviceControl.TYPES.ACTIVE_INACTIVE:
        _result = _value
          ? 'state_active'
          : 'state_inactive'
        break
      case BAS_API.GenericDeviceControl.TYPES.OCCUPIED_FREE:
        _result = _value
          ? 'state_occupied'
          : 'state_free'
        break
      case BAS_API.GenericDeviceControl.TYPES.CUSTOM:
        _translate = false
        _result = _value
          ? customTrue
          : customFalse
        break
    }

    return _translate
      ? BasUtilities.translate(_result)
      : _result
  }

  return BasGenericDeviceControl
}
