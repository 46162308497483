import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('textInputModalCtrl', [
    '$rootScope',
    '$scope',
    'BAS_SPLASH',
    'close',
    'basModalConfig',
    textInputModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param close
 * @param {BasInputModalConfig} basInputModalConfig
 * @param {string} basInputModalConfig.title
 * @param {TBasModalInput[]} basInputModalConfig.inputs
 * @param {string} basInputModalConfig.saveTextId
 * @param {string} basInputModalConfig.errorTextId
 */
function textInputModalCtrl (
  $rootScope,
  $scope,
  BAS_SPLASH,
  close,
  basInputModalConfig
) {
  var modal = this

  var _listeners = []

  modal.closeModal = closeModal
  modal.save = save
  modal.onInputChanged = onInputChanged
  modal.onSubmit = onSubmit

  modal.basInputModalConfig = basInputModalConfig

  modal.inputs = []

  modal.title = basInputModalConfig.title
  modal.errorReason = basInputModalConfig.errorTextId
  modal.saveTextId = basInputModalConfig.saveTextId
  modal.isValid = isValid()

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)

    initializeInputs()
  }

  function initializeInputs () {

    var i, length

    if (Array.isArray(basInputModalConfig.inputs)) {

      length = basInputModalConfig.inputs.length
      for (i = 0; i < length; i++) {
        modal.inputs[i] = basInputModalConfig.inputs[i].initialValue
      }
    }
  }

  function closeModal (reason) {

    if (!modal.basInputModalConfig ||
      modal.basInputModalConfig.allowDismiss) {

      close(reason)
    }
  }

  function isValid () {

    var length, i

    if (Array.isArray(basInputModalConfig.inputs)) {

      length = basInputModalConfig.inputs.length
      for (i = 0; i < length; i++) {

        if (
          BasUtil.isFunction(basInputModalConfig.inputs[i].validCheck) &&
          basInputModalConfig.inputs[i].validCheck(modal.inputs[i]) === false
        ) {

          return false
        }
      }
    }

    return true
  }

  function save () {

    if (isValid()) {

      close(modal.inputs, 0)
    }
  }

  function onInputChanged () {

    modal.isValid = isValid()
  }

  function onSubmit (event) {

    var inputElement = event.target[0]

    save()

    // Blur and focus to force touched state on the input element
    inputElement.blur()
    inputElement.focus()
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
