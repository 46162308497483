'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasDiscoveredCore', [
    'BAS_API',
    'BAS_CONNECT',
    basDiscoveredCoreFactory
  ])

/**
 * @param BAS_API
 * @param {BAS_CONNECT} BAS_CONNECT
 * @returns BasDiscoveredCore
 */
function basDiscoveredCoreFactory (
  BAS_API,
  BAS_CONNECT
) {
  var _counter = 0

  /**
   * @constructor
   */
  function BasDiscoveredCore () {

    /**
     * Used as identifier
     *
     * @type {string}
     */
    this.id = ''

    /**
     * Local discovery service name
     *
     * @type {string}
     */
    this.serviceName = ''

    /**
     * Cloud discovery artefact
     *
     * @type {?BasServer}
     */
    this.basServer = null

    /**
     * @type {number}
     */
    this.macN = 0

    /**
     * @type {string}
     */
    this.mac = ''

    /**
     * @type {string}
     */
    this.coreName = ''

    /**
     * Project UUID
     *
     * @type {string}
     */
    this.cid = ''

    /**
     * @type {string}
     */
    this.projectName = ''

    /**
     * @type {string[]}
     */
    this.addresses = []

    /**
     * @type {string}
     */
    this.uiName = ''

    /**
     * @type {string}
     */
    this.uiAddress = ''

    /**
     * @type {string}
     */
    this.city = ''

    /**
     * @type {string}
     */
    this.country = ''

    /**
     * @type {?Object}
     */
    this.images = null
  }

  /**
   * Compares 2 services, sorts lowest MAC address first
   *
   * @param {BasDiscoveredCore} service1
   * @param {BasDiscoveredCore} service2
   * @returns {number}
   */
  BasDiscoveredCore.compareLowestMacFirst = function (
    service1,
    service2
  ) {

    var mac1, mac2

    if (service1 instanceof BasDiscoveredCore &&
      service2 instanceof BasDiscoveredCore) {

      mac1 = service1.macN
      mac2 = service2.macN

      if (mac1 < mac2) return -1
      if (mac1 > mac2) return 1

    } else if (service1 instanceof BasDiscoveredCore) {

      return -1

    } else if (service2 instanceof BasDiscoveredCore) {

      return 1
    }

    return 0
  }

  /**
   * @param {(string|number)} macAddress
   * @returns {boolean}
   */
  BasDiscoveredCore.prototype.hasSameMac = function (macAddress) {

    if (BasUtil.isNEString(macAddress)) {

      return this.mac === macAddress

    } else if (BasUtil.isPNumber(macAddress)) {

      return this.macN === macAddress
    }

    return false
  }

  /**
   * Checks if specific IP/DNS address is one of this service addresses
   *
   * @param {string} address
   * @returns {boolean}
   */
  BasDiscoveredCore.prototype.hasAddress = function (address) {

    var length, i

    if (BasUtil.isNEString(address)) {

      length = this.addresses.length
      for (i = 0; i < length; i++) {

        if (this.addresses[i] === address) return true
      }
    }

    return false
  }

  /**
   * Returns the first valid address found.
   *
   * @returns {string}
   */
  BasDiscoveredCore.prototype.getAddress = function () {

    var length, i, address

    length = this.addresses.length
    for (i = 0; i < length; i++) {

      address = this.addresses[i]
      if (BasUtil.isNEString(address)) return address
    }

    return ''
  }

  /**
   * @param {string} id
   */
  BasDiscoveredCore.prototype.setId = function (id) {

    this.id = BasUtil.isNEString(id) ? id : 'Core S4 - ' + _getId()
  }

  /**
   * This will set 'id' to mac number (in string form)
   *
   * @param {(string|number)} mac
   */
  BasDiscoveredCore.prototype.setMac = function (mac) {

    var value

    if (BasUtil.isNEString(mac)) {

      value = BAS_API.MacAddressUtil.convertToNumber(mac)

      if (value) {

        this.macN = value
        this.mac = BAS_API.MacAddressUtil.convertToMac(value)
      }

    } else if (BasUtil.isPNumber(mac)) {

      value = BAS_API.MacAddressUtil.convertToMac(mac)

      if (value) {

        this.macN = mac
        this.mac = value
      }
    }

    if (!this.id) this.id = '' + this.macN
  }

  BasDiscoveredCore.prototype.generateUi = function () {

    this.uiName = this._getUiName()
    this.uiAddress = this.addresses[0] ? this.addresses[0] : ''
  }

  /**
   * @returns {TBasConnectServer}
   */
  BasDiscoveredCore.prototype.getBasConnectServer = function () {

    var result

    result = {
      type: BAS_CONNECT.T_LOCAL,
      macAddress: this.macN,
      address: this.addresses[0]
    }

    if (this.cid) result.cid = this.cid

    return result
  }

  /**
   * @private
   * @returns {string}
   */
  BasDiscoveredCore.prototype._getUiName = function () {

    if (this.projectName) return this.projectName
    if (this.coreName) return this.coreName
    return this.id
  }

  return BasDiscoveredCore

  /**
   * Generate unique number
   *
   * @private
   * @returns {number}
   */
  function _getId () {

    return _counter++
  }
}
