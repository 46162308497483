'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('timerDayCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_API',
    'BAS_ROOMS',
    'BAS_SCHEDULER',
    'RoomsHelper',
    timerDayCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param BAS_API
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SCHEDULER} BAS_SCHEDULER
 * @param {RoomsHelper} RoomsHelper
 */
function timerDayCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_API,
  BAS_ROOMS,
  BAS_SCHEDULER,
  RoomsHelper
) {
  var day = this

  var _listeners = []

  day.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BasRooms}
   */
  day.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasSchedulerLine[]}
   */
  day.verticalLines = BAS_SCHEDULER.HOUR_LINES_13

  /**
   * @type {BasSchedulerLine[]}
   */
  day.horizontalLines = BAS_SCHEDULER.VALUE_LINES_2

  /**
   * @type {number}
   */
  day.yStep = 1

  // noinspection JSUnusedGlobalSymbols
  /**
   * Angular UI-router callback
   */
  this.uiOnParamsChanged = _onParamsChanged

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _updateLimits()
  }

  function _onParamsChanged () {

    _updateLimits()

    $scope.$applyAsync()
  }

  function _updateLimits () {

    var timer

    day.yStep = 1

    day.horizontalLines = BAS_SCHEDULER.VALUE_LINES_2

    timer = _getBasScheduler()

    if (timer) {

      if (timer.type === BAS_API.Timer.T_PERCENTAGE) {

        day.yStep = 0.01

        day.horizontalLines = BAS_SCHEDULER.VALUE_LINES_5
      }
    }
  }

  /**
   * @private
   * @returns {?BasTimer}
   */
  function _getBasScheduler () {

    var timerId, home, timer

    timerId = _getTimerId()

    if (timerId) {

      home = RoomsHelper.getHome()

      if (
        home &&
        home.timers &&
        home.timers.onOrderUpdated
      ) {

        timer = home.timers.timers[timerId]

        if (timer) return timer
      }
    }

    return null
  }

  /**
   * @private
   * @returns {string}
   */
  function _getTimerId () {

    var timerId

    timerId = $uiRouterGlobals.params.timer

    return BasUtil.isNEString(timerId) ? timerId : ''
  }

  function _onDestroy () {

    var scheduler

    BasUtil.executeArray(_listeners)
    _listeners = []

    scheduler = _getBasScheduler()

    if (scheduler) {

      scheduler.commit()
      scheduler.updateTimer()
    }
  }
}
