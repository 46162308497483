'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('LocalParser', [
    '$rootScope',
    'BAS_LOCAL_LIBRARY',
    'BAS_FAVOURITE',
    'BAS_API',
    'LocalElement',
    'BasResource',
    'BasUtilities',
    'Logger',
    LocalParser
  ])

/**
 * Helper class for creating LocalElements from Library Objects
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_LOCAL_LIBRARY} BAS_LOCAL_LIBRARY
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param BAS_API
 * @param LocalElement
 * @param {BasResource} BasResource
 * @param {BasUtilities} BasUtilities
 * @param Logger
 */
function LocalParser (
  $rootScope,
  BAS_LOCAL_LIBRARY,
  BAS_FAVOURITE,
  BAS_API,
  LocalElement,
  BasResource,
  BasUtilities,
  Logger
) {
  var serviceName = 'Local model'

  var K_TITLE = 'title'
  var K_NAME = 'name'
  var K_ARTIST = 'artist'
  var K_DURATION = 'length'
  var K_FILE = 'file'
  var K_COVER_URL = 'coverartUrl'
  var K_THUMB_URL = 'thumbnailUrl'
  var K_COVER = 'coverart'
  var K_THUMB = 'thumbnail'
  var K_ALBUM = 'album'
  var K_CONTEXT_ALBUM_URI = 'contextAlbumUri'
  var K_CONTEXT_ARTIST_URI = 'contextArtistUri'
  var K_URI = 'uri'
  var K_IMAGES = 'images'

  this.processObjects = processObjects
  this.processObject = processObject
  this.processPlaylistCoverArt = processPlaylistCoverArt

  /**
   * @param {LocalElement} element
   * @param {?(Object|BasTrack)} object
   */
  function processAlbum (element, object) {

    if (
      object instanceof BAS_API.BasTrack &&
      object.type === BAS_API.BasTrack.TYPE_ALBUM
    ) {

      // Title
      if (BasUtil.isNEString(object.title)) {
        element.title = object.title
      }

      // Subtitle
      if (BasUtil.isNEString(object.artist)) {
        element.artist = object.artist
        element.subtitle = object.artist
      }

      // Uri
      if (BasUtil.isNEString(object.uri)) {
        element.uri = object.uri
      }

      // Cover art
      setCoverArt(
        element,
        object.coverartImages
          ? object.coverartImages
          : {}
      )

    } else {

      // Title
      if (BasUtil.isNEString(object[K_NAME])) {
        element.title = object[K_NAME]
      }

      // Subtitle
      if (BasUtil.isNEString(object[K_ARTIST])) {
        element.artist = object[K_ARTIST]
        element.subtitle = object[K_ARTIST]
      }

      // CoverArt
      setCoverArt(
        element,
        {
          small: object[K_THUMB_URL],
          medium: object[K_THUMB_URL],
          large: object[K_COVER_URL]
        }
      )

      if (BasUtil.isObject(object[K_IMAGES])) {

        setCoverArt(element, object[K_IMAGES])
      }

      if (object[K_ARTIST]) {

        if (BasUtil.isNEString(object[K_ARTIST][K_NAME])) {

          element.artist = object[K_ARTIST][K_NAME]
          element.subtitle = object[K_ARTIST][K_NAME]
        }
      }
    }
  }

  /**
   * @param {LocalElement} element
   * @param {string} artist
   */
  function processArtist (element, artist) {

    // Title
    if (BasUtil.isNEString(artist)) element.title = artist

    // Title
    if (BasUtil.isObject(artist)) {

      if (BasUtil.isNEString(artist[K_NAME])) element.title = artist[K_NAME]
    }

    if (BasUtil.isObject(artist)) {

      if (BasUtil.isNEString(artist[K_URI])) element.uri = artist[K_URI]
    }
  }

  /**
   * @param {LocalElement} element
   * @param {?Playlist} playlist
   */
  function processPlaylist (element, playlist) {

    var i, length, covers

    // Title
    element.title = playlist.title

    // Set number of song subtitle
    element.setNumberOfSongs(playlist.numberOfSongs)

    // Locked
    element.locked = playlist.locked

    // Editable
    element.editable = playlist.editable

    // iTunes
    element.iTunes = playlist.iTunes

    // Owner
    if (
      BasUtil.isNEString(playlist.owner) &&
      !element.editable
    ) {
      element.setOwner(playlist.owner)
    }

    if (
      BasUtil.isNEString(playlist.ownerUuid) &&
      !element.editable
    ) {
      element.setownerUuid(playlist.ownerUuid)
    }

    // Shareable
    element.shareable = playlist.shareable

    // Is sharing
    element.isSharing = playlist.isSharing

    // Id
    element.setId(
      BAS_FAVOURITE.T_LOCAL_PLAYLIST,
      playlist.id
    )

    // Favourite id
    element.setAsanoFavouriteId(
      BAS_FAVOURITE.T_LOCAL_PLAYLIST,
      playlist.id
    )

    // CoverArt
    element.setCoverArt(playlist.coverArts ? playlist.coverArts : {})
    if (BasUtil.isNEArray(playlist.coverArts)) {

      covers = []

      length = playlist.coverArts.length

      for (i = 0; i < length; i++) {

        covers.push({
          coverart: playlist.coverArts[i],
          thumbnail: playlist.coverArts[i]
        })
      }

      if (covers.length > 3) {
        element.setMultipleCovers(covers)
      } else {
        element.setCoverArt({
          small: covers[0].thumbnail,
          medium: covers[0].coverart,
          large: covers[0].coverart
        })
      }
    }

    // Uri
    if (BasUtil.isNEString(playlist.uri)) {

      element.uri = playlist.uri
    }

    // Keep a reference to the data
    element.playlistId = playlist.id
  }

  /**
   * @param {LocalElement} element
   * @param {Array} array
   */
  function processPlaylistCoverArt (element, array) {

    var object, i

    if (array.length > 0) {

      object = array[0]

      // CoverArt
      setCoverArt(
        element,
        {
          small: object[K_THUMB],
          medium: object[K_THUMB],
          large: object[K_COVER]
        }
      )

      if (array.length > 3) {

        element.hasMultipleCovers = true

        for (i = 0; i < array.length; i++) {
          getImageFromMultipleCover(i, array[i])
        }
      }
    }

    function getImageFromMultipleCover (position, path) {

      BasResource.getImages(path).then(onImage)

      function onImage (image) {
        var multipleCovers = element.multiCovers
        if (!multipleCovers) multipleCovers = []
        multipleCovers[position] = image
        element.setMultipleCovers(multipleCovers)
        $rootScope.$applyAsync()
      }
    }
  }

  /**
   * @param {LocalElement} element
   * @param {?Object} object
   */
  function processTrack (element, object) {

    var UNKNOWN = 'unknown'

    // Title
    if (BasUtil.isNEString(object[K_TITLE])) {

      element.title = object[K_TITLE]

    } else {

      element.title = BasUtilities.translate(UNKNOWN)
    }

    if (
      BasUtil.isNEString(object[K_ALBUM])
    ) {

      element.album = object[K_ALBUM]
    }

    // Check duration
    if (BasUtil.isPNumber(object[K_DURATION])) {

      element.setDuration(object[K_DURATION])
    }

    // Subtitle
    if (BasUtil.isNEString(object[K_ARTIST])) {

      element.subtitle = object[K_ARTIST]
      element.artist = object[K_ARTIST]
    }

    if (BasUtil.isNEString(object[K_FILE])) {

      // File
      element.file = object[K_FILE]

      // Repeat ID
      element.repeatId = object.file
    } else {
      element.setCanSelect(false)
    }

    if (BasUtil.isNEString(object[K_URI])) {

      element.uri = object[K_URI]
    }

    if (BasUtil.isNEString(object[K_CONTEXT_ALBUM_URI])) {

      element.albumUri = object[K_CONTEXT_ALBUM_URI]

    } else if (element.album) {

      element.albumUri = element.album
    }

    if (BasUtil.isNEString(object[K_CONTEXT_ARTIST_URI])) {

      element.artistUri = object[K_CONTEXT_ARTIST_URI]

    } else if (element.artist) {

      element.artistUri = element.artist
    }

    // CoverArt
    setCoverArt(
      element,
      {
        small: object[K_THUMB_URL],
        medium: object[K_THUMB_URL],
        large: object[K_COVER_URL]
      }
    )
  }

  /**
   * @param {?Object | string | Playlist} object
   * @param {string} type
   * @returns {LocalElement}
   */
  function processObject (object, type) {
    var element = new LocalElement()

    if (
      object instanceof BAS_API.BasTrack &&
      object.type === BAS_API.BasTrack.TYPE_ALBUM
    ) {

      // Set type
      element.type = BAS_LOCAL_LIBRARY.T_EL_ALBUM

      // Process album
      processAlbum(element, object)

      // UI properties
      element.setCanBrowse(true)
      element.setHasContext(true)

    } else

    // Check if object is valid
    if (BasUtil.isObject(object)) {

      // Check object type
      switch (type) {
        case BAS_LOCAL_LIBRARY.T_EL_ALBUM:

          // Set type
          element.type = BAS_LOCAL_LIBRARY.T_EL_ALBUM

          // Process album
          processAlbum(element, object)

          // UI properties
          element.setCanBrowse(true)
          element.setHasContext(true)

          break
        case BAS_LOCAL_LIBRARY.T_EL_PLAYLIST:

          // Set type
          element.type = BAS_LOCAL_LIBRARY.T_EL_PLAYLIST

          // Process playlist
          processPlaylist(element, object)

          // UI properties
          element.setCanBrowse(true, false)
          element.setCanAsanoFavourite(true)
          element.setHasContext(true)

          break
        case BAS_LOCAL_LIBRARY.T_EL_TRACK:

          // Set type
          element.type = BAS_LOCAL_LIBRARY.T_EL_TRACK

          // Process track
          processTrack(element, object)

          // UI properties
          if (
            BasUtil.isNEString(element.file) ||
            BasUtil.isNEString(element.uri)
          ) {
            element.setHasContext(true)
            element.setCanSelect(true)
          }

          break
        case BAS_LOCAL_LIBRARY.T_EL_ARTIST:

          // Process artist
          processArtist(element, object)

          // Set type
          element.type = BAS_LOCAL_LIBRARY.T_EL_ARTIST

          // UI properties
          element.setCanBrowse(true)
          element.setHasContext(true)

          break
        default:
          Logger.warn(serviceName + ' - Unknown type')
      }

    } else if (type === BAS_LOCAL_LIBRARY.T_EL_ARTIST) {

      // Process artist
      processArtist(element, object)

      // Set type
      element.type = BAS_LOCAL_LIBRARY.T_EL_ARTIST

      // UI properties
      element.setCanBrowse(true)
      element.setHasContext(true)
    }

    return element
  }

  /**
   * Can take an optional offset
   * to start processing items at index offset
   *
   * @param {(?Object|string|Playlist)[]} array
   * @param {string} type
   * @param {number} [offset]
   * @returns {LocalElement[]}
   */
  function processObjects (array, type, offset) {
    var result, finalOffset, length, i, element

    result = []
    finalOffset = 0

    // Check offset
    if (typeof offset === 'number') finalOffset = offset

    // Make sure argument is array
    if (Array.isArray(array)) {

      // Iterate array
      length = array.length
      for (i = finalOffset; i < length; i += 1) {

        element = processObject(array[i], type)

        // Add Element to results
        if (BasUtil.isNEString(element.title)) result.push(element)

      }
    } else {
      Logger.warn(
        serviceName + ' - processObjects - Parameter is NO ARRAY',
        array
      )
    }

    return result
  }

  function setCoverArt (element, imagePaths) {

    var keys, length, i, empty, path

    if (!element) return

    empty = false

    keys = Object.keys(imagePaths)
    length = keys.length

    for (i = 0; i < length; i++) {

      path = imagePaths[keys[i]]

      if (BasUtil.isNEString(path)) {

        empty = false
        break
      }
    }

    element.setCoverArt(
      {
        small: '',
        medium: '',
        large: ''
      }
    )

    if (length === 0 || empty) return

    BasResource.getImages(imagePaths).then(
      function (result) {
        element.setCoverArt(result)
        $rootScope.$applyAsync()
      }
    )
  }
}
