'use strict'

// This service acts as a wrapper around a set of core 'actions' that are likely
//  to be triggered after application resume while the server connection has not
//  yet been established again.
//
// If an action can be executed (core is connected), it will immediately be
//  executed. If not, it will be  added to the command queue. When the core is
//  connected, the service will try to re-execute commands.
//
// This service also includes some logic to clean up the command queue based
//  on context, e.g. for some actions only the last action is relevant.

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasCommandQueue', [
    '$rootScope',
    'BAS_APP',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_ERRORS',
    'CurrentBasCore',
    'Logger',
    BasCommandQueue
  ])

/**
 * @typedef {Object} TCommandQueueCommand
 * @property {string} type
 * @property {string} functionName
 * @property {string} [deviceUuid]
 * @property {string} [roomUuid]
 * @property {string} [sourceUuid]
 * @property {Array<*>} [params]
 * @property {?TOutstandingPromise} [outstandingPromise]
 * @property {number} [timestamp]
 * @property {string} [projectCID]
 */

/**
 * @callback COutstandingPromiseResolve
 */

/**
 * @callback COutstandingPromiseReject
 */

/**
 * @typedef {Object} TOutstandingPromise
 * @property {COutstandingPromiseResolve} resolve
 * @property {COutstandingPromiseReject} reject
 */

/**
 * @typedef {Object} TPlayUriOptions
 * @property {string} [contextUri]
 * @property {number} [contextOffset]
 */

/**
 *
 * @param $rootScope
 * @param {BAS_APP} BAS_APP
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {CurrentBasCore} CurrentBasCore
 * @param Logger
 * @constructor
 */
function BasCommandQueue (
  $rootScope,
  BAS_APP,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_ERRORS,
  CurrentBasCore,
  Logger
) {
  const serviceName = 'CommandQueue'

  const TYPE_SCENE_DEVICE = 'sceneDevice'
  const TYPE_AUDIO = 'audio'
  const TYPE_AUDIO_SOURCE = 'audioSource'
  const TYPE_VIDEO_SOURCE = 'videoSource'
  const TYPE_THERMOSTAT_DEVICE = 'thermostatDevice'

  // Function names of API classes
  // SceneCtrlDevice
  const F_ACTIVATE_SCENE = 'getActivateSceneMessage'
  // AVAudio
  const F_SET_VOLUME = 'getSetVolumeMessage'
  // AudioSource
  const F_PREVIOUS = 'getPreviousMessage'
  const F_NEXT = 'getNextMessage'
  const F_TOGGLE_PLAY_PAUSE = 'getTogglePlayPauseMessage'
  const F_PLAY_URI = 'getPlayUriMessage'
  // ThermostatDevice
  const F_SET_NEW_SETPOINT = 'getSetNewSetPointMessage'
  const F_SET_MODE = 'getSetModeMessage'
  const F_SET_FAN_MODE = 'getSetFanModeMessage'
  const F_SET_LOUVER_MODE = 'getSetLouverModeMessage'
  const F_SET_CONTROL_ACTIVE = 'getSetControlActiveMessage'

  const CONNECTION_TIMEOUT_MS = 5000

  /**
   *
   * @type {TCurrentBasCoreState}
   */
  const currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {Array<TCommandQueueCommand>}
   */
  let commandQueue = []

  this.sceneActivate = sceneActivate

  this.audioSourceNext = audioSourceNext
  this.audioSourcePrevious = audioSourcePrevious
  this.audioSourceTogglePlayPause = audioSourceTogglePlayPause
  this.audioSourcePlayUri = audioSourcePlayUri

  this.videoSourcePlayUri = videoSourcePlayUri

  this.roomAudioSetVolume = roomAudioSetVolume

  this.thermostatSetNewSetPoint = thermostatSetNewSetPoint
  this.thermostatSetMode = thermostatSetMode
  this.thermostatSetFanMode = thermostatSetFanMode
  this.thermostatSetLouverMode = thermostatSetLouverMode
  this.thermostatSetControlActive = thermostatSetControlActive

  init()

  function init () {

    $rootScope.$on(
      BAS_APP.EVT_PAUSE,
      _onPause
    )
    $rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onResume
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      _onCoreConnected
    )
  }

  /**
   * @param {string} sceneCtrlDeviceUuid
   * @param {string} sceneUuid
   * @returns {Promise}
   */
  function sceneActivate (sceneCtrlDeviceUuid, sceneUuid) {

    return _tryOrAddCommand({
      type: TYPE_SCENE_DEVICE,
      deviceUuid: sceneCtrlDeviceUuid,
      functionName: F_ACTIVATE_SCENE,
      params: [sceneUuid]
    })
  }

  /**
   * @param {string} audioSourceUuid
   * @returns {Promise}
   */
  function audioSourceNext (audioSourceUuid) {

    return _tryOrAddCommand({
      type: TYPE_AUDIO_SOURCE,
      sourceUuid: audioSourceUuid,
      functionName: F_NEXT
    })
  }

  /**
   * @param {string} audioSourceUuid
   * @returns {Promise}
   */
  function audioSourcePrevious (audioSourceUuid) {

    return _tryOrAddCommand({
      type: TYPE_AUDIO_SOURCE,
      sourceUuid: audioSourceUuid,
      functionName: F_PREVIOUS
    })
  }

  /**
   * @param {string} audioSourceUuid
   * @param {boolean} force
   * @returns {Promise}
   */
  function audioSourceTogglePlayPause (audioSourceUuid, force) {

    return _tryOrAddCommand({
      type: TYPE_AUDIO_SOURCE,
      sourceUuid: audioSourceUuid,
      functionName: F_TOGGLE_PLAY_PAUSE,
      params: [force]
    })
  }

  /**
   * @param {string} audioSourceUuid
   * @param {string|string[]} uri
   * @param {TPlayUriOptions} [options]
   * @returns {Promise}
   */
  function audioSourcePlayUri (
    audioSourceUuid,
    uri,
    options
  ) {

    var _contextUri, _contextOffset

    if (options) {

      _contextUri = options.contextUri
      _contextOffset = options.contextOffset
    }

    return _tryOrAddCommand({
      type: TYPE_AUDIO_SOURCE,
      sourceUuid: audioSourceUuid,
      functionName: F_PLAY_URI,
      params: [uri, _contextUri, _contextOffset]
    })
  }

  /**
   * @param {string} videoSourceUuid
   * @param {string|string[]} uri
   * @returns {Promise}
   */
  function videoSourcePlayUri (
    videoSourceUuid,
    uri
  ) {
    return _tryOrAddCommand({
      type: TYPE_VIDEO_SOURCE,
      sourceUuid: videoSourceUuid,
      functionName: F_PLAY_URI,
      params: [uri]
    })
  }

  /**
   * @param {string} roomUuid
   * @param {number} volume
   * @returns {Promise}
   */
  function roomAudioSetVolume (roomUuid, volume) {

    return _tryOrAddCommand({
      type: TYPE_AUDIO,
      roomUuid: roomUuid,
      functionName: F_SET_VOLUME,
      params: [volume]
    })
  }

  /**
   * @param {string} thermostatDeviceUuid
   * @param {number} setPoint
   * @param {string} temperatureUnit
   * @returns {Promise}
   */
  function thermostatSetNewSetPoint (
    thermostatDeviceUuid,
    setPoint,
    temperatureUnit
  ) {

    return _tryOrAddCommand({
      type: TYPE_THERMOSTAT_DEVICE,
      deviceUuid: thermostatDeviceUuid,
      functionName: F_SET_NEW_SETPOINT,
      params: [setPoint, temperatureUnit]
    })
  }

  /**
   * @param {string} thermostatDeviceUuid
   * @param {string} mode
   * @returns {Promise}
   */
  function thermostatSetMode (thermostatDeviceUuid, mode) {

    return _tryOrAddCommand({
      type: TYPE_THERMOSTAT_DEVICE,
      deviceUuid: thermostatDeviceUuid,
      functionName: F_SET_MODE,
      params: [mode]
    })
  }

  /**
   * @param {string} thermostatDeviceUuid
   * @param {string} mode
   * @returns {Promise}
   */
  function thermostatSetFanMode (thermostatDeviceUuid, mode) {

    return _tryOrAddCommand({
      type: TYPE_THERMOSTAT_DEVICE,
      deviceUuid: thermostatDeviceUuid,
      functionName: F_SET_FAN_MODE,
      params: [mode]
    })
  }

  /**
   * @param {string} thermostatDeviceUuid
   * @param {string} mode
   * @returns {Promise}
   */
  function thermostatSetLouverMode (thermostatDeviceUuid, mode) {

    return _tryOrAddCommand({
      type: TYPE_THERMOSTAT_DEVICE,
      deviceUuid: thermostatDeviceUuid,
      functionName: F_SET_LOUVER_MODE,
      params: [mode]
    })
  }

  /**
   * @param {string} thermostatDeviceUuid
   * @param {string} controlUuid
   * @param {boolean} active
   * @returns {Promise}
   */
  function thermostatSetControlActive (
    thermostatDeviceUuid,
    controlUuid,
    active
  ) {

    return _tryOrAddCommand({
      type: TYPE_THERMOSTAT_DEVICE,
      deviceUuid: thermostatDeviceUuid,
      functionName: F_SET_CONTROL_ACTIVE,
      params: [controlUuid, active]
    })
  }

  function _onPause () {

    _clearCommandQueue()
  }

  function _onResume () {

    // We don't want to trigger any actions that started in the previous
    //  resume/pause cycle, so we clear the command queue. The chance that there
    //  is still a non timed-out command in the queue at this point is pretty
    //  much non-existent anyway.
    _clearCommandQueue()
  }

  function _onCoreConnected () {

    _checkCommands()
  }

  /**
   * Try to execute commands in the command queue that correspond to one of the
   *  types from the types parameter. If no types are given, all commands are
   *  tried.
   *
   * @private
   * @param {Array<string>} [types]
   */
  function _checkCommands (types) {

    _cleanupCommandQueue()

    if (_isCoreConnected()) {

      const length = commandQueue.length
      for (let i = length - 1; i >= 0; i--) {

        const command = commandQueue[i]

        // Only handle commands with given type
        if (Array.isArray(types) && types.indexOf(command.type) === -1) continue

        // If a promise is returned, remove command from queue
        if (tryCommand(command)) {
          Logger.info(
            serviceName + ' - Succesfully executed command in command queue: ',
            command
          )
          commandQueue.splice(i, 1)
        }
      }
    }
  }

  /**
   * Try to execute any command. If execution is not yet possible due to no
   *  connection or missing data, the command will be added to the command
   *  queue. In that case, a promise is returned which will resolve or reject
   *  when the command can be and is executed.
   *
   * @private
   * @param {TCommandQueueCommand} command
   * @returns {Promise}
   */
  function _tryOrAddCommand (command) {

    command.timestamp = Date.now()
    command.projectCID = currentBasCoreState.lastConnectedCoreCID

    const promise = tryCommand(command)

    if (promise) return promise

    Logger.info(serviceName + ' - Adding command to command queue:', command)

    // Add to command queue
    commandQueue.push(command)

    const returnPromise = new Promise(promiseConstructor)

    _cleanupCommandQueue()

    setTimeout(_cleanupCommandQueue, CONNECTION_TIMEOUT_MS)

    return returnPromise

    function promiseConstructor (resolve, reject) {

      // Save resolve and reject functions as outstanding promise on command
      command.outstandingPromise = {
        resolve: resolve,
        reject: reject
      }
    }
  }

  /**
   * Try to execute any command
   *
   * @private
   * @param {TCommandQueueCommand} command
   * @returns {?Promise}
   */
  function tryCommand (command) {

    const promise = _tryCommand(command)

    if (promise && command.outstandingPromise) {

      promise.then(
        _onResolve,
        _onReject
      )
    }
    return promise

    function _onResolve (result) {

      removeCommand()
      BasUtil.exec(command.outstandingPromise.resolve, result)
    }

    function _onReject (reason) {

      removeCommand()
      BasUtil.exec(command.outstandingPromise.reject, reason)
    }

    function removeCommand () {

      const idx = commandQueue.indexOf(command)

      if (idx > -1) commandQueue.splice(idx, 1)
    }
  }

  /**
   * Try to execute a Device command
   *
   * @private
   * @param {TCommandQueueCommand} command
   * @returns {?Promise}
   */
  function _tryCommand (command) {

    if (_isCoreConnected()) {

      const functionClass = (() => {
        switch (command.type) {
          case TYPE_SCENE_DEVICE:
            return BAS_API.SceneCtrlDevice
          case TYPE_THERMOSTAT_DEVICE:
            return BAS_API.ThermostatDevice
          case TYPE_AUDIO_SOURCE:
            return BAS_API.AudioSource
          case TYPE_AUDIO:
            return BAS_API.AVAudio
          case TYPE_VIDEO_SOURCE:
            return BAS_API.VideoSource
        }
      })()

      const deviceFunction = functionClass[command.functionName]

      if (BasUtil.isFunction(deviceFunction)) {
        const apiCore = CurrentBasCore.get().core.core
        const wsMessage = deviceFunction(
          (() => {
            switch (command.type) {
              case TYPE_SCENE_DEVICE:
              case TYPE_THERMOSTAT_DEVICE:
                return command.deviceUuid
              case TYPE_AUDIO_SOURCE:
              case TYPE_VIDEO_SOURCE:
                return command.sourceUuid
              case TYPE_AUDIO:
                return command.roomUuid
            }
          })(),
          ...(command.params ?? [])
        )

        const result = (() => {
          switch (command.type) {
            case TYPE_SCENE_DEVICE:
            case TYPE_THERMOSTAT_DEVICE:
              return apiCore.send(wsMessage)
            case TYPE_AUDIO_SOURCE:
            case TYPE_AUDIO:
            case TYPE_VIDEO_SOURCE:
              return apiCore.requestRetry(
                wsMessage,
                BAS_API.CONSTANTS.RETRY_OPTS_ONE_SHOT
              )
          }
        })()

        return Promise.resolve(result)
      }
    }
    return null
  }

  function _cleanupCommandQueue () {

    const now = Date.now()

    const length = commandQueue.length
    for (let i = length - 1; i >= 0; i--) {

      const origCommand = commandQueue[i]

      const coreConnectedTimestamp =
        currentBasCoreState.lastCoreConnectedTimestamp

      const connTimeoutLimit = origCommand.timestamp + CONNECTION_TIMEOUT_MS

      // Check if command timed out WHILE connecting to core
      const connectionTimedOut = _isCoreConnected()
        ? coreConnectedTimestamp > connTimeoutLimit
        : now > connTimeoutLimit

      const projectCIDChanged =
        currentBasCoreState.lastConnectedCoreCID !== origCommand.projectCID

      if (connectionTimedOut || projectCIDChanged) {

        const reason = connectionTimedOut
          ? 'connectionTimedOut'
          : 'projectCIDChanged'

        Logger.info(serviceName +
          ' - Failed to execute command in command queue: ' +
          reason, origCommand)

        // Remove command from command queue, adjust i
        commandQueue.splice(i, 1)

        // Abort outstanding promise
        if (origCommand.outstandingPromise) {

          origCommand.outstandingPromise.reject(
            projectCIDChanged
              ? BAS_ERRORS.T_PROJECT_CID_MISMATCH
              : BAS_ERRORS.T_TIMEOUT
          )
        }
        continue
      }

      // Loop over all previous commands and remove no longer relevant commands
      for (let j = i - 1; j >= 0; j--) {

        const checkCommand = commandQueue[j]

        if (_commandMakesOtherCommandObsolete(origCommand, checkCommand)) {

          // Remove command from command queue, adjust 'i' counter of outer loop
          commandQueue.splice(j, 1)
          i--

          // Abort outstanding promise
          if (checkCommand.outstandingPromise) {

            checkCommand.outstandingPromise.reject(BAS_ERRORS.T_ABORT)
          }
        }
      }
    }
  }

  /**
   * Check if a newer command makes another command obsolete, based on context
   *  (type, function name, uuid, ...)
   *
   * e.g.
   *  - only the last volume command for the same room is kept
   *  - only the last activation of a specific scene is kept
   *  - ...
   *
   * @private
   * @param {TCommandQueueCommand} command
   * @param {TCommandQueueCommand} checkCommand
   * @returns {boolean}
   */
  function _commandMakesOtherCommandObsolete (command, checkCommand) {

    if (command.type !== checkCommand.type) return false

    switch (command.type) {

      case TYPE_SCENE_DEVICE:

        // Keep all UNIQUE commands
        return (
          command.functionName === checkCommand.functionName &&
          command.deviceUuid === checkCommand.deviceUuid &&
          BasUtil.isEqualArray(command.params, checkCommand.params)
        )

      case TYPE_AUDIO:

        // Keep last volume
        return (
          command.roomUuid === checkCommand.roomUuid &&
          (
            (
              command.functionName === F_SET_VOLUME &&
              checkCommand.functionName === F_SET_VOLUME
            )
          )
        )

      case TYPE_AUDIO_SOURCE:

        // Keep only last previous OR next command, keep last playback command
        return (
          command.sourceUuid === checkCommand.sourceUuid &&
          (
            (
              (
                command.functionName === F_PREVIOUS ||
                command.functionName === F_NEXT
              ) &&
              (
                checkCommand.functionName === F_PREVIOUS ||
                checkCommand.functionName === F_NEXT
              )
            ) || (
              command.functionName === F_TOGGLE_PLAY_PAUSE &&
              checkCommand.functionName === F_TOGGLE_PLAY_PAUSE
            ) || (
              command.functionName === F_PLAY_URI &&
              checkCommand.functionName === F_PLAY_URI
            )
          )
        )

      case TYPE_THERMOSTAT_DEVICE: {

        // Keep last command per function
        return (
          command.deviceUuid === checkCommand.deviceUuid &&
          command.functionName === checkCommand.functionName
        )
      }
    }
  }

  /**
   * @private
   * @returns {boolean}
   */
  function _isCoreConnected () {

    return CurrentBasCore.getServer()?.isCoreConnected() ?? false
  }

  function _clearCommandQueue () {

    const length = commandQueue.length
    for (let i = 0; i < length; i++) {

      const command = commandQueue[i]

      if (command.outstandingPromise) {

        command.outstandingPromise.reject(BAS_ERRORS.T_ABORT)
      }
    }
    commandQueue = []
  }
}
