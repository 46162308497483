'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('DeezerModel', [
    'BAS_FAVOURITE',
    'DeezerElement',
    'BasUtilities',
    'Logger',
    DeezerModel
  ])

/**
 * Helper class for creating DeezerElements from Deezer Objects
 *
 * @constructor
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param DeezerElement
 * @param {BasUtilities} BasUtilities
 * @param Logger
 */
function DeezerModel (
  BAS_FAVOURITE,
  DeezerElement,
  BasUtilities,
  Logger
) {
  var serviceName = 'Deezer model'

  // Main sections

  var SECTION_FLOW = 'flow'
  var SECTION_MY_MUSIC = 'myMusic'
  var SECTION_POPULAR = 'popular'
  var SECTION_GENRES = 'genres'

  // Special UI elements
  var SECTION_MORE = 'more'

  // Deezer keys
  var K_ID = 'id'
  var K_TITLE = 'title'
  var K_DESCRIPTION = 'description'
  var K_NAME = 'name'
  var K_ARTIST = 'artist'
  var K_ALBUM = 'album'
  var K_DURATION = 'duration'
  var K_NB_TRACKS = 'nb_tracks'
  var K_COVER_SMALL = 'cover_small'
  var K_COVER_MEDIUM = 'cover_medium'
  var K_COVER_BIG = 'cover_big'
  var K_PICTURE_SMALL = 'picture_small'
  var K_PICTURE_MEDIUM = 'picture_medium'
  var K_PICTURE_BIG = 'picture_big'
  var K_IS_LOVED_TRACK = 'is_loved_track'
  var K_CREATOR = 'creator'

  /**
   * @constant {string}
   */
  this.SECTION_FLOW = SECTION_FLOW

  /**
   * @constant {string}
   */
  this.SECTION_MY_MUSIC = SECTION_MY_MUSIC

  /**
   * @constant {string}
   */
  this.SECTION_POPULAR = SECTION_POPULAR

  /**
   * @constant {string}
   */
  this.SECTION_GENRES = SECTION_GENRES

  /**
   * @constant {string}
   */
  this.SECTION_MORE = SECTION_MORE

  this.processDeezerObject = processDeezerObject
  this.processDeezerObjects = processDeezerObjects
  this.createSection = createSection
  this.isIdZero = isIdZero
  this.isLovedTrack = isLovedTrack

  /**
   * @param {DeezerElement} element
   * @param {?Object} object
   */
  function processDeezerAlbum (element, object) {

    // Title
    element.title = object[K_TITLE]

    // Check artist
    if (BasUtil.isObject(object[K_ARTIST])) {

      // Subtitle
      element.subtitle = object[K_ARTIST][K_NAME]
    }

    // Check duration
    if (BasUtil.isPNumber(object[K_DURATION])) {
      element.setDuration(object[K_DURATION])
    }

    // CoverArt
    element.setCoverArt({
      small: object[K_COVER_SMALL],
      medium: object[K_COVER_MEDIUM],
      large: object[K_COVER_BIG]
    })

    // Check for number of songs
    if (typeof object[K_NB_TRACKS] === 'number') {

      // Set number of items
      element.setNumberOfItems(object[K_NB_TRACKS])
    }
  }

  /**
   * @param {DeezerElement} element
   * @param {?Object} object
   */
  function processDeezerArtist (element, object) {

    // Title
    element.title = object[K_NAME]

    // CoverArt
    element.setCoverArt({
      small: object[K_PICTURE_SMALL],
      medium: object[K_PICTURE_MEDIUM],
      large: object[K_PICTURE_BIG]
    })
  }

  function processDeezerGenre (element, object) {

    // Title
    element.title = object[K_NAME]

    // CoverArt
    element.setCoverArt({
      small: object[K_PICTURE_SMALL],
      medium: object[K_PICTURE_MEDIUM],
      large: object[K_PICTURE_BIG]
    })
  }

  /**
   * @param {DeezerElement} element
   * @param {?Object} object
   */
  function processDeezerPlaylist (element, object) {

    // Title
    element.title = object[K_TITLE]

    // Check duration
    if (BasUtil.isPNumber(object[K_DURATION])) {
      element.setDuration(object[K_DURATION])
    }

    if (BasUtil.isObject(object[K_CREATOR]) &&
      BasUtil.isPNumber(object[K_CREATOR][K_ID])) {

      element.creatorId = object[K_CREATOR][K_ID]
    }

    // Check if number of tracks is valid
    if (typeof object[K_NB_TRACKS] === 'number') {

      // Set number of song subtitle
      element.setNumberOfSongs(object[K_NB_TRACKS])
    }

    // CoverArt
    element.setCoverArt({
      small: object[K_PICTURE_SMALL],
      medium: object[K_PICTURE_MEDIUM],
      large: object[K_PICTURE_BIG]
    })
  }

  /**
   * @param {DeezerElement} element
   * @param {?Object} object
   */
  function processDeezerRadio (element, object) {

    // Title
    element.title = object[K_TITLE]
    element.subtitle = object[K_DESCRIPTION]

    // CoverArt
    element.setCoverArt({
      small: object[K_PICTURE_SMALL],
      medium: object[K_PICTURE_MEDIUM],
      large: object[K_PICTURE_BIG]
    })
  }

  /**
   * @param {DeezerElement} element
   * @param {?Object} object
   */
  function processDeezerTrack (element, object) {

    // Title
    element.title = object[K_TITLE]

    // Check duration
    if (BasUtil.isPNumber(object[K_DURATION])) {
      element.setDuration(object[K_DURATION])
    }

    // Check if artist is valid
    if (BasUtil.isObject(object[K_ARTIST])) {

      // Subtitle
      element.subtitle = object[K_ARTIST][K_NAME]
    }

    // Check if album is valid
    if (BasUtil.isObject(object[K_ALBUM])) {

      // CoverArt
      element.setCoverArt({
        small: object[K_ALBUM][K_COVER_SMALL],
        medium: object[K_ALBUM][K_COVER_MEDIUM],
        large: object[K_ALBUM][K_COVER_BIG]
      })
    }
  }

  /**
   * @param {?Object} object
   * @returns {DeezerElement}
   */
  function processDeezerObject (object) {
    var element = new DeezerElement(object)

    // Check if object is valid
    if (BasUtil.isObject(object)) {

      // Set ID
      element.setId(
        BAS_FAVOURITE.T_DEEZER,
        object[K_ID]
      )

      // Check Deezer object type
      switch (object.type) {
        case DeezerElement.TYPE_ALBUM:

          // Set type
          element.type = DeezerElement.TYPE_ALBUM

          // Process album
          processDeezerAlbum(element, object)

          // UI properties
          element.setCanBrowse(true)
          element.setHasContext(true)

          break
        case DeezerElement.TYPE_ARTIST:

          // Set type
          element.type = DeezerElement.TYPE_ARTIST

          // Process artist
          processDeezerArtist(element, object)

          // UI properties
          element.setCanBrowse(true)
          element.setHasContext(true)

          break
        case DeezerElement.TYPE_GENRE:

          // Set type
          element.type = DeezerElement.TYPE_GENRE

          // Process genre
          processDeezerGenre(element, object)

          // UI properties
          element.setCanBrowse(true)

          break
        case DeezerElement.TYPE_PLAYLIST:

          // Set type
          element.type = DeezerElement.TYPE_PLAYLIST

          // Process playlist
          processDeezerPlaylist(element, object)

          // UI properties
          element.canBrowse = true
          element.setCanAsanoFavourite(true)
          element.setHasContext(true)

          break
        case DeezerElement.TYPE_RADIO:

          // Set type
          element.type = DeezerElement.TYPE_RADIO

          // Process radio
          processDeezerRadio(element, object)

          // UI properties
          element.setCanAsanoFavourite(true)
          element.setHasContext(true)

          break
        case DeezerElement.TYPE_TRACK:

          // Set type
          element.type = DeezerElement.TYPE_TRACK

          // Process track
          processDeezerTrack(element, object)

          // UI properties
          element.setHasContext(true)
          element.setCanSelect(true)

          break
        default:
          Logger.warn(serviceName +
                        ' - Unknown Deezer type' + object.type)
      }

      // Make sure the Asano favourite Id is created
      element.createRepeatId()
      element.createAsanoFavouriteId(BAS_FAVOURITE.T_DEEZER)
    }

    return element
  }

  /**
   * Can take an optional offset
   * to start processing items at index offset
   *
   * @param {Array<?Object>} array
   * @param {number} [offset]
   * @returns {Array<DeezerElement>}
   */
  function processDeezerObjects (array, offset) {
    var result, finalOffset, length, i

    result = []
    finalOffset = 0

    // Check offset
    if (BasUtil.isPNumber(offset, true)) finalOffset = offset

    // Make sure argument is array
    if (Array.isArray(array)) {

      // Iterate array
      length = array.length
      for (i = finalOffset; i < length; i += 1) {

        // Add Deezer Ui Element to results
        result.push(
          processDeezerObject(array[i])
        )
      }
    } else {
      Logger.warn(
        serviceName + ' - processDeezerObjects - Parameter is NO ARRAY',
        array
      )
    }

    return result
  }

  /**
   * @param {string} sectionType
   * @returns {DeezerElement}
   */
  function createSection (sectionType) {

    // Create new UI element for type
    var element = new DeezerElement()

    // Set ID
    element.id = sectionType

    // Type
    element.type = DeezerElement.TYPE_SECTION

    // Sub type
    element.subType = sectionType

    // Customize according to section type
    switch (sectionType) {
      case SECTION_FLOW:

        // Titles
        element.title =
          BasUtilities.translate('deezer_flow')
        element.subtitle =
          BasUtilities.translate('deezer_flow_description')

        // Has more content
        element.setCanPlay(true)

        break
      case SECTION_MY_MUSIC:

        // Titles
        element.title =
          BasUtilities.translate('my_music')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_POPULAR:

        // Titles
        element.title =
          BasUtilities.translate('popular')

        // Set ID to genre ID "All" 0
        element.id = 0

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_GENRES:

        // Titles
        element.title =
          BasUtilities.translate('genres')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_MORE:

        // Titles
        element.title =
          BasUtilities.translate('show_more')

        // Has more content
        element.setCanBrowse(true)

        break
      default:
        Logger.warn(serviceName + '- Invalid sectionType', sectionType)
    }

    return element
  }

  // Filters

  function isIdZero (element) {
    return (
      BasUtil.isObject(element) &&
      element[K_ID] === 0
    )
  }

  function isLovedTrack (element) {
    return (
      BasUtil.isObject(element) &&
      element[K_IS_LOVED_TRACK] === true
    )
  }
}
