'use strict'

var BasUtil = require('@basalte/bas-util')

var Device = require('./device')
var Temperature = require('./temperature')
var SetPoint = require('./set_point')
var ThermostatControl = require('./thermostat_control')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

/**
 * @typedef {Object} TThermostatScheduler
 * @property {boolean} [enabled]
 * @property {TSetPoint[]} [monday]
 * @property {TSetPoint[]} [tuesday]
 * @property {TSetPoint[]} [wednesday]
 * @property {TSetPoint[]} [thursday]
 * @property {TSetPoint[]} [friday]
 * @property {TSetPoint[]} [saturday]
 * @property {TSetPoint[]} [sunday]
 */

/**
 * @typedef {Object} TSetPoint
 * @property {number} time Seconds
 * @property {number} [celsius]
 * @property {number} [fahrenheit]
 * @property {boolean} [off]
 * @property {string} [mode]
 */

/**
 * @typedef {Object} TSetpointRange
 * @property {Temperature} minSetpoint
 * @property {Temperature} maxSetpoint
 */

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function ThermostatDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._state = BasUtil.isObject(device[P.STATE])
    ? device[P.STATE]
    : {}

  /**
   * @private
   * @type {Temperature}
   */
  this._temperature = new Temperature()

  /**
   * @private
   * @type {Temperature}
   */
  this._setpoint = new Temperature()

  /**
   * @private
   * @type {TSetpointRange}
   */
  this._setpointRange = {
    minSetpoint: new Temperature(),
    maxSetpoint: new Temperature()
  }

  this._setpointRange.minSetpoint.setCelsius(
    CONSTANTS.SETPOINT_RANGE_MIN_DEFAULT
  )

  this._setpointRange.maxSetpoint.setCelsius(
    CONSTANTS.SETPOINT_RANGE_MAX_DEFAULT
  )

  /**
   * Additional thermostat controls
   *
   * @private
   * @type {ThermostatControl[]}
   */
  this._controls = []

  /**
   * Scheduler enabled state
   * Data coming from server
   *
   * @private
   * @type {boolean}
   */
  this._serverEnabled = false

  /**
   * Data coming from server
   *
   * @private
   * @type {Object<string, SetPoint[]>}
   */
  this._serverDays = {}

  /**
   * Scheduler enabled state
   * Data changed through server and user changes
   *
   * @private
   * @type {boolean}
   */
  this._enabled = false

  /**
   * Data changed through server and user changes
   *
   * @private
   * @type {Object<string, SetPoint[]>}
   */
  this._days = {}

  this._handleUpdate = this._onUpdateResult.bind(this)

  this.parse(
    device,
    {
      emit: false
    }
  )

  this._checkTemperature()
}

ThermostatDevice.prototype = Object.create(Device.prototype)
ThermostatDevice.prototype.constructor = ThermostatDevice

// region Events

/**
 * Fires when state has changed.
 *
 * @event ThermostatDevice#EVT_STATE
 */

/**
 * Fires when controls has changed.
 *
 * @event ThermostatDevice#EVT_CONTROLS
 * @param {ThermostatControl[]} controls
 */

/**
 * Fires when scheduler has changed.
 *
 * @event ThermostatDevice#EVT_SCHEDULER
 */

// endregion

// region Constants

/**
 * @constant {string}
 */
ThermostatDevice.EVT_STATE = 'evtThermostatState'

/**
 * @constant {string}
 */
ThermostatDevice.EVT_CONTROLS = 'evtThermostatControls'

/**
 * @constant {string}
 */
ThermostatDevice.EVT_SCHEDULER = 'evtThermostatScheduler'

/**
 * @constant {string}
 */
ThermostatDevice.C_TEMPERATURE = P.TEMPERATURE

/**
 * @constant {string}
 */
ThermostatDevice.C_HUMIDITY = P.HUMIDITY

/**
 * @constant {string}
 */
ThermostatDevice.C_SETPOINT = P.SETPOINT

/**
 * @constant {string}
 */
ThermostatDevice.C_PRECISION = P.PRECISION

/**
 * @constant {string}
 */
ThermostatDevice.C_SETPOINT_RANGE_MIN = P.MIN

/**
 * @constant {string}
 */
ThermostatDevice.C_SETPOINT_RANGE_MAX = P.MAX

/**
 * @constant {string}
 */
ThermostatDevice.C_THERMOSTAT_MODE = P.THERMOSTAT_MODE

/**
 * @constant {string}
 */
ThermostatDevice.C_HEAT_COOL_MODE = P.HEAT_COOL_MODE

/**
 * @constant {string}
 */
ThermostatDevice.C_FAN_MODE = P.FAN_MODE

/**
 * @constant {string}
 */
ThermostatDevice.C_LOUVER_MODE = P.LOUVER_MODE

/**
 * @constant {string}
 */
ThermostatDevice.C_HEATING_ACTIVE = P.HEATING_ACTIVE

/**
 * @constant {string}
 */
ThermostatDevice.C_COOLING_ACTIVE = P.COOLING_ACTIVE

/**
 * @constant {string}
 */
ThermostatDevice.C_SCHEDULER = P.SCHEDULER

/**
 * @constant {string}
 */
ThermostatDevice.C_SCHEDULER_V2 = P.SCHEDULER_V2

/**
 * @constant {string}
 */
ThermostatDevice.A_TM_HEATING = P.HEATING

/**
 * @constant {string}
 */
ThermostatDevice.A_TM_COOLING = P.COOLING

/**
 * @constant {string}
 */
ThermostatDevice.A_TM_AUTO = P.AUTO

/**
 * @constant {string}
 */
ThermostatDevice.A_TM_OFF = P.OFF

/**
 * @constant {string}
 */
ThermostatDevice.A_TM_DRYING = P.DRYING

/**
 * @constant {string}
 */
ThermostatDevice.A_TM_FAN_ONLY = P.FAN_ONLY

/**
 * @constant {number}
 */
ThermostatDevice.PB_TM_OFF = 1

/**
 * @constant {number}
 */
ThermostatDevice.PB_TM_HEATING = 2

/**
 * @constant {number}
 */
ThermostatDevice.PB_TM_COOLING = 4

/**
 * @constant {number}
 */
ThermostatDevice.PB_TM_AUTO = 8

/**
 * @constant {string}
 */
ThermostatDevice.A_FM_OFF = P.OFF

/**
 * @constant {string}
 */
ThermostatDevice.A_FM_LOW = P.LOW

/**
 * @constant {string}
 */
ThermostatDevice.A_FM_MID = P.MID

/**
 * @constant {string}
 */
ThermostatDevice.A_FM_HIGH = P.HIGH

/**
 * @constant {string}
 */
ThermostatDevice.A_FM_AUTO = P.AUTO

/**
 * @constant {string}
 */
ThermostatDevice.A_LM_SWING = P.SWING

/**
 * @constant {string}
 */
ThermostatDevice.A_LM_30_DEG = P.DEG_30

/**
 * @constant {string}
 */
ThermostatDevice.A_LM_45_DEG = P.DEG_45

/**
 * @constant {string}
 */
ThermostatDevice.A_LM_60_DEG = P.DEG_60

/**
 * @constant {string}
 */
ThermostatDevice.A_LM_HORIZONTAL = P.HORIZONTAL

/**
 * @constant {string}
 */
ThermostatDevice.A_LM_VERTICAL = P.VERTICAL

/**
 * @constant {string}
 */
ThermostatDevice.A_LM_OFF = P.OFF

/**
 * @constant {number}
 */
ThermostatDevice.PB_FM_OFF = 1

/**
 * @constant {number}
 */
ThermostatDevice.PB_FM_LOW = 2

/**
 * @constant {number}
 */
ThermostatDevice.PB_FM_MID = 4

/**
 * @constant {number}
 */
ThermostatDevice.PB_FM_HIGH = 8

/**
 * @constant {number}
 */
ThermostatDevice.PB_FM_AUTO = 16

// endregion

/**
 * @param {string} mode
 * @returns {boolean}
 */
ThermostatDevice.isThermostatMode = function (mode) {
  return (
    mode === ThermostatDevice.A_TM_AUTO ||
    mode === ThermostatDevice.A_TM_HEATING ||
    mode === ThermostatDevice.A_TM_COOLING ||
    mode === ThermostatDevice.A_TM_OFF ||
    mode === ThermostatDevice.A_TM_DRYING ||
    mode === ThermostatDevice.A_TM_FAN_ONLY
  )
}

/**
 * @param {string} mode
 * @returns {boolean}
 */
ThermostatDevice.isTemperatureMode = function (mode) {
  return (
    mode === ThermostatDevice.A_TM_HEATING ||
    mode === ThermostatDevice.A_TM_COOLING ||
    mode === ThermostatDevice.A_TM_OFF
  )
}

/**
 * @param {string} mode
 * @returns {boolean}
 */
ThermostatDevice.isFanMode = function (mode) {
  return (
    mode === ThermostatDevice.A_FM_OFF ||
    mode === ThermostatDevice.A_FM_LOW ||
    mode === ThermostatDevice.A_FM_MID ||
    mode === ThermostatDevice.A_FM_HIGH ||
    mode === ThermostatDevice.A_FM_AUTO
  )
}

/**
 * @param {string} mode
 * @returns {boolean}
 */
ThermostatDevice.isLouverMode = function (mode) {
  return (
    mode === ThermostatDevice.A_LM_30_DEG ||
    mode === ThermostatDevice.A_LM_45_DEG ||
    mode === ThermostatDevice.A_LM_60_DEG ||
    mode === ThermostatDevice.A_LM_SWING ||
    mode === ThermostatDevice.A_LM_HORIZONTAL ||
    mode === ThermostatDevice.A_LM_VERTICAL ||
    mode === ThermostatDevice.A_LM_OFF
  )
}

/**
 * Points have to be in the same order.
 *
 * @param {SetPoint[]} day1
 * @param {SetPoint[]} day2
 * @returns {boolean}
 */
ThermostatDevice.compareDays = function (day1, day2) {

  var i, point1, point2, temp1, temp2, length1, length2, isDayArray

  isDayArray = Array.isArray(day1)

  if (!isDayArray) return false
  if (isDayArray !== Array.isArray(day2)) return false

  length1 = day1.length
  length2 = day2.length

  if (length1 !== length2) return false

  for (i = 0; i < length1; i++) {

    point1 = day1[i]
    point2 = day2[i]

    if (point1.mode !== point2.mode) return false
    if (point1.time !== point2.time) return false
    if (point1.off !== point2.off) return false

    temp1 = point1.temperature?.getTemperature(CONSTANTS.TU_KELVIN)
    temp2 = point2.temperature?.getTemperature(CONSTANTS.TU_KELVIN)

    if (temp1 !== temp2) return false
  }

  return true
}

/**
 * @private
 * @param {TSetPoint[]} day
 * @returns {SetPoint[]}
 */
ThermostatDevice._parseDay = function (day) {

  var _setPoints, length, i, _point, point

  _setPoints = []

  length = day.length
  for (i = 0; i < length; i++) {

    point = day[i]

    _point = SetPoint.parse(point, i)

    if (_point) _setPoints.push(_point)
  }

  return _setPoints
}

/**
 * @param {SetPoint[]} setPoints
 * @returns {TSetPoint[]}
 */
ThermostatDevice._makeSetPoints = function (setPoints) {

  var _setPoints, length, i, point

  _setPoints = []

  length = setPoints.length
  for (i = 0; i < length; i++) {

    point = setPoints[i]

    if (point && point.getApiSetPoint) {

      _setPoints.push(point.getApiSetPoint())
    }
  }

  return _setPoints
}

Object.defineProperties(ThermostatDevice.prototype, {

  /**
   * @name ThermostatDevice#humidity
   * @type {number}
   * @readonly
   */
  humidity: {
    get: function () {

      return BasUtil.isPNumber(this._state[ThermostatDevice.C_HUMIDITY])
        ? this._state[ThermostatDevice.C_HUMIDITY]
        : 0
    }
  },

  /**
   * @name ThermostatDevice#mode
   * @type {string}
   * @readonly
   */
  mode: {
    get: function () {

      return ThermostatDevice
        .isThermostatMode(this._state[ThermostatDevice.C_THERMOSTAT_MODE])
        ? this._state[ThermostatDevice.C_THERMOSTAT_MODE]
        : ''
    }
  },

  /**
   * @name ThermostatDevice#activeMode
   * @type {string}
   * @readonly
   */
  activeMode: {
    get: function () {

      return ThermostatDevice
        .isTemperatureMode(this._state[ThermostatDevice.C_HEAT_COOL_MODE])
        ? this._state[ThermostatDevice.C_HEAT_COOL_MODE]
        : ''
    }
  },

  /**
   * @name ThermostatDevice#heatingActive
   * @type {boolean}
   * @readonly
   */
  heatingActive: {
    get: function () {

      return BasUtil.isBool(this._state[ThermostatDevice.C_HEATING_ACTIVE])
        ? this._state[ThermostatDevice.C_HEATING_ACTIVE]
        : false
    }
  },

  /**
   * @name ThermostatDevice#coolingActive
   * @type {boolean}
   * @readonly
   */
  coolingActive: {
    get: function () {

      return BasUtil.isBool(this._state[ThermostatDevice.C_COOLING_ACTIVE])
        ? this._state[ThermostatDevice.C_COOLING_ACTIVE]
        : false
    }
  },

  /**
   * @name ThermostatDevice#fanMode
   * @type {string}
   * @readonly
   */
  fanMode: {
    get: function () {

      return ThermostatDevice
        .isFanMode(this._state[ThermostatDevice.C_FAN_MODE])
        ? this._state[ThermostatDevice.C_FAN_MODE]
        : ''
    }
  },

  /**
   * @name ThermostatDevice#louverMode
   * @type {string}
   * @readonly
   */
  louverMode: {
    get: function () {

      return ThermostatDevice
        .isLouverMode(this._state[ThermostatDevice.C_LOUVER_MODE])
        ? this._state[ThermostatDevice.C_LOUVER_MODE]
        : ''
    }
  },

  /**
   * @name ThermostatDevice#controls
   * @type {ThermostatControl[]}
   * @readonly
   */
  controls: {
    get: function () {

      return this._controls
    }
  },

  /**
   * @name ThermostatDevice#enabled
   * @type {boolean}
   * @readonly
   */
  enabled: {
    get: function () {

      return this._enabled
    }
  },

  /**
   * @name ThermostatDevice#scheduler
   * @type {Object<string, SetPoint[]>}
   * @readonly
   */
  scheduler: {
    get: function () {

      return this._days
    }
  },

  /**
   * @name ThermostatDevice#setpointRange
   * @type {TSetpointRange}
   * @readonly
   */
  setpointRange: {
    get: function () {

      return this._setpointRange
    }
  },

  /**
   * @name ThermostatDevice#fanOptions
   * @type {string[]}
   * @readonly
   */
  fanOptions: {
    get: function () {

      if (this._attributes) return this._attributes[P.FAN_MODE]
      return []
    }
  },

  /**
   * @name ThermostatDevice#modeOptions
   * @type {string[]}
   * @readonly
   */
  modeOptions: {
    get: function () {

      if (this._attributes) return this._attributes[P.THERMOSTAT_MODE]
      return []
    }
  },

  /**
   * @name ThermostatDevice#precision
   * @type {?number}
   * @readonly
   */
  precision: {
    get: function () {
      if (
        this._attributes &&
        BasUtil.isObject(this._attributes[P.SETPOINT]) &&
        BasUtil.isPNumber(this._attributes[P.SETPOINT][P.PRECISION])
      ) {
        return this._attributes[P.SETPOINT][P.PRECISION]
      }
      return null
    }
  },

  /**
   * @name ThermostatDevice#louverOptions
   * @type {string[]}
   * @readonly
   */
  louverOptions: {
    get: function () {

      if (this._attributes) return this._attributes[P.LOUVER_MODE]
      return []
    }
  },

  /**
   * @name ThermostatDevice#supportsSchedulerOffPoints
   * @type {boolean}
   * @readonly
   */
  supportsSchedulerOffPoints: {
    get: function () {
      return !!this._supports[P.SCHEDULER_OFF_POINTS]
    }
  }

})

/**
 * Parse a ThermostatDevice message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
ThermostatDevice.prototype.parse = function (msg, options) {

  var valid, emit, obj

  emit = true
  valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    // State

    obj = msg[P.STATE]

    if (BasUtil.isObject(obj) && this._state !== obj) {

      if (!BasUtil.isEqualPartialObject(this._state, obj)) {

        BasUtil.mergeObjectsDeep(this._state, obj)

        this._checkTemperature()

        if (emit) this.emit(ThermostatDevice.EVT_STATE)
      }
    }

    // Controls

    obj = msg[P.CONTROLS]

    if (Array.isArray(obj)) this.parseControls(obj, options)

    // Scheduler

    obj = msg[P.SCHEDULER]

    if (BasUtil.isObject(obj)) this.parseScheduler(obj, options)
  }

  return valid
}

/**
 * Used for creating and updating controls
 * controls do not necessarily contain all controls of thermostat
 * e.g. an update of one control state
 *
 * @param {ThermostatControl[]} controls
 * @param {TDeviceParseOptions} [options]
 */
ThermostatDevice.prototype.parseControls = function (
  controls,
  options
) {
  var emit, i, length, control, index

  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  length = controls.length
  for (i = 0; i < length; i++) {

    control = controls[i]

    if (BasUtil.isObject(control) &&
      BasUtil.isNEString(control.uuid)) {

      if (!Array.isArray(this._controls)) this._controls = []

      index = this.indexOfControl(control)

      if (index !== -1) {

        this._controls[index].parse(control)

      } else {

        this._controls.push(new ThermostatControl(
          control,
          null
        ))
      }
    }
  }

  if (emit) this.emit(ThermostatDevice.EVT_CONTROLS, this._controls)
}

/**
 * @param {TThermostatScheduler} scheduler
 * @param {TDeviceParseOptions} [options]
 */
ThermostatDevice.prototype.parseScheduler = function (
  scheduler,
  options
) {
  var emit, hasChanged, i, day, key, dayKey, parsedDay

  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  hasChanged = false

  // Parse days
  for (i = 0; i < 7; i++) {

    key = CONSTANTS.DAYS[i]
    dayKey = CONSTANTS.PARSER_DAYS[i]
    day = scheduler[dayKey]

    if (Array.isArray(day)) {

      parsedDay = ThermostatDevice._parseDay(day)

      if (!ThermostatDevice.compareDays(this._days[key], parsedDay)) {

        this._days[key] = parsedDay
        hasChanged = true
      }

      this._serverDays[key] = parsedDay
    }
  }

  if (BasUtil.isBool(scheduler[P.ENABLED]) &&
    this._serverEnabled !== scheduler[P.ENABLED]) {

    this._serverEnabled = scheduler[P.ENABLED]
    this._enabled = scheduler[P.ENABLED]

    hasChanged = true
  }

  if (hasChanged && emit) this.emit(ThermostatDevice.EVT_SCHEDULER)
}

/**
 * @param {(string|ThermostatDevice)} control
 * @returns {number} index of the control
 */
ThermostatDevice.prototype.indexOfControl = function (
  control
) {
  var uuid, i, length

  if (Array.isArray(this._controls)) {

    if (BasUtil.isObject(control)) {

      uuid = control.uuid

    } else if (BasUtil.isNEString(control)) {

      uuid = control
    }

    if (BasUtil.isNEString(uuid)) {

      length = this._controls.length
      for (i = 0; i < length; i++) {

        if (uuid === this._controls[i].uuid) return i
      }
    }
  }

  return -1
}

ThermostatDevice.prototype._checkTemperature = function () {

  var temp, min, max, minC, maxC, minF, maxF, minComp, maxComp, setpoint

  // Temperature

  if (BasUtil.isObject(this._state[ThermostatDevice.C_TEMPERATURE])) {

    temp = this._state[ThermostatDevice.C_TEMPERATURE]

    if (BasUtil.isVNumber(temp[CONSTANTS.TU_CELSIUS])) {

      this._temperature.setCelsius(temp[CONSTANTS.TU_CELSIUS])

    } else if (BasUtil.isVNumber(temp[CONSTANTS.TU_FAHRENHEIT])) {

      this._temperature.setFahrenheit(temp[CONSTANTS.TU_FAHRENHEIT])
    }
  }

  // Set point

  if (BasUtil.isObject(this._state[ThermostatDevice.C_SETPOINT])) {

    temp = this._state[ThermostatDevice.C_SETPOINT]

    if (BasUtil.isVNumber(temp[CONSTANTS.TU_CELSIUS])) {

      this._setpoint.setCelsius(temp[CONSTANTS.TU_CELSIUS])

    } else if (BasUtil.isVNumber(temp[CONSTANTS.TU_FAHRENHEIT])) {

      this._setpoint.setFahrenheit(temp[CONSTANTS.TU_FAHRENHEIT])
    }
  }

  setpoint = this._attributes[ThermostatDevice.C_SETPOINT]

  if (BasUtil.isObject(setpoint)) {

    min = setpoint[ThermostatDevice.C_SETPOINT_RANGE_MIN]
    max = setpoint[ThermostatDevice.C_SETPOINT_RANGE_MAX]

    if (BasUtil.isObject(min) &&
      BasUtil.isObject(max)) {

      minC = min[CONSTANTS.TU_CELSIUS]
      maxC = max[CONSTANTS.TU_CELSIUS]
      minF = min[CONSTANTS.TU_FAHRENHEIT]
      maxF = max[CONSTANTS.TU_FAHRENHEIT]

      if ((BasUtil.isVNumber(minC) || BasUtil.isVNumber(minF)) &&
        (BasUtil.isVNumber(maxF) || BasUtil.isVNumber(maxC))) {

        // Extract same unit values to compare min and max
        minComp = BasUtil.isVNumber(minC)
          ? minC
          : Temperature.fahrenheitToCelsius(minF)

        maxComp = BasUtil.isVNumber(maxC)
          ? maxF
          : Temperature.fahrenheitToCelsius(maxF)

        if (minComp < maxComp) {

          this._setpointRange = {
            minSetpoint: new Temperature(),
            maxSetpoint: new Temperature()
          }

          if (BasUtil.isVNumber(minC)) {

            this._setpointRange.minSetpoint.setCelsius(
              min[CONSTANTS.TU_CELSIUS]
            )

          } else if (BasUtil.isVNumber(minF)) {

            this._setpointRange.minSetpoint.setFahrenheit(
              min[CONSTANTS.TU_FAHRENHEIT]
            )
          }

          if (BasUtil.isVNumber(maxC)) {

            this._setpointRange.maxSetpoint.setCelsius(
              max[CONSTANTS.TU_CELSIUS]
            )

          } else if (BasUtil.isVNumber(maxF)) {

            this._setpointRange.maxSetpoint.setFahrenheit(
              max[CONSTANTS.TU_FAHRENHEIT]
            )
          }
        }
      }
    }
  }
}

/**
 * Current temperature
 *
 * @returns {Temperature}
 */
ThermostatDevice.prototype.getTemperature = function () {

  return this._temperature
}

/**
 * Current setpoint
 *
 * @returns {Temperature}
 */
ThermostatDevice.prototype.getSetpoint = function () {

  return this._setpoint
}

/**
 * @param {number} setPoint
 * @param {string} temperatureUnit
 */
ThermostatDevice.prototype.setNewSetpoint = function (
  setPoint,
  temperatureUnit
) {
  const msg = ThermostatDevice.getSetNewSetPointMessage(
    this.uuid,
    setPoint,
    temperatureUnit
  )

  if (msg) this._basCore.send(msg)
}

/**
 * @param {string} mode
 */
ThermostatDevice.prototype.setMode = function (mode) {

  const msg = ThermostatDevice.getSetModeMessage(this.uuid, mode)
  if (msg) this._basCore.send(msg)
}

/**
 * @param {string} mode
 */
ThermostatDevice.prototype.setFanMode = function (mode) {

  const msg = ThermostatDevice.getSetFanModeMessage(this.uuid, mode)
  if (msg) this._basCore.send(msg)
}

/**
 * @param {string} mode
 */
ThermostatDevice.prototype.setLouverMode = function (mode) {

  const msg = ThermostatDevice.getSetLouverModeMessage(this.uuid, mode)
  if (msg) this._basCore.send(msg)
}

/**
 * Set the control value
 *
 * @param {string} controlUuid
 * @param {boolean} active
 */
ThermostatDevice.prototype.setControlActive = function (
  controlUuid,
  active
) {
  const msg = ThermostatDevice.getSetControlActiveMessage(
    this.uuid,
    controlUuid,
    active
  )
  if (msg) this._basCore.send(msg)
}

/**
 * @param {boolean} enabled
 */
ThermostatDevice.prototype.setEnabled = function (enabled) {

  if (BasUtil.isBool(enabled)) {

    this._enabled = enabled
  }
}

/**
 * @param {Object<string, SetPoint[]>} days
 */
ThermostatDevice.prototype.setSetpoints = function (days) {

  var keys, length, i, key, day

  if (BasUtil.isObject(days)) {

    keys = Object.keys(days)
    length = keys.length

    for (i = 0; i < length; i++) {

      key = keys[i]
      day = days[key]

      if (Array.isArray(day) && CONSTANTS.DAYS.indexOf(key) !== -1) {

        this._days[key] = day
      }
    }
  }
}

/**
 * @returns {Promise}
 */
ThermostatDevice.prototype.updateDevice = function () {

  var msg, scheduler, canSend

  canSend = false

  scheduler = {}

  if (this._fillScheduler(scheduler)) {

    msg = this._getBasCoreMessage()
    msg[P.DEVICE][P.SCHEDULER] = scheduler
    canSend = true
  }

  return canSend
    ? this._basCore.requestRetry(msg).then(this._handleUpdate)
    : Promise.resolve(this)
}

/**
 * Returns Promise.resolved with the device if result is ok
 * returns Promise.rejected with a matching error message
 *
 * @param {Object} result
 * @returns {(Promise|ThermostatDevice)}
 */
ThermostatDevice.prototype._onUpdateResult = function (result) {

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.DEVICE]) &&
    result[P.DEVICE][P.RESULT] === P.OK) {

    return this
  }

  return Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)
}

/**
 * @param {Object} scheduler
 * @returns {boolean}
 */
ThermostatDevice.prototype._fillScheduler = function (scheduler) {

  var changed, keys, i, length, key, day, daysIndex, parserKey, serverDay

  changed = false

  if (this._enabled !== this._serverEnabled) {

    scheduler[P.ENABLED] = this._enabled
    changed = true
  }

  if (BasUtil.isObject(this._days)) {

    keys = Object.keys(this._days)
    length = keys.length

    for (i = 0; i < length; i++) {

      key = keys[i]
      day = this._days[key]
      serverDay = this._serverDays[key]
      daysIndex = CONSTANTS.DAYS.indexOf(key)

      if (Array.isArray(day) &&
        daysIndex !== -1 &&
        !ThermostatDevice.compareDays(day, serverDay)) {

        parserKey = CONSTANTS.PARSER_DAYS[daysIndex]
        scheduler[parserKey] = ThermostatDevice._makeSetPoints(day)
        changed = true
      }
    }
  }

  return changed
}

/**
 * @param {string} thermostatUuid
 * @param {number} setPoint
 * @param {string} temperatureUnit
 * @returns {?Object}
 */
ThermostatDevice.getSetNewSetPointMessage = function (
  thermostatUuid,
  setPoint,
  temperatureUnit
) {
  if (
    BasUtil.isNEString(thermostatUuid) &&
    BasUtil.isVNumber(setPoint)
  ) {
    let obj

    if (BasUtil.isVNumber(setPoint)) {

      switch (temperatureUnit) {
        case CONSTANTS.TU_CELSIUS:

          obj = {}
          obj[P.CELSIUS] = setPoint

          break
        case CONSTANTS.TU_FAHRENHEIT:

          obj = {}
          obj[P.FAHRENHEIT] = setPoint

          break
        case CONSTANTS.TU_KELVIN:

          obj = {}
          obj[P.KELVIN] = setPoint

          break
      }
    }

    if (obj) {

      const msg = Device.getBasCoreMessage(thermostatUuid)
      msg[P.DEVICE][P.STATE] = { [ThermostatDevice.C_SETPOINT]: obj }

      return msg
    }
  }

  return null
}

/**
 * @param {string} thermostatUuid
 * @param {string} mode
 * @returns {Object?}
 */
ThermostatDevice.getSetModeMessage = function (
  thermostatUuid,
  mode
) {

  if (ThermostatDevice.isThermostatMode(mode)) {

    const msg = Device.getBasCoreMessage(thermostatUuid)
    msg[P.DEVICE][P.STATE] = { [ThermostatDevice.C_THERMOSTAT_MODE]: mode }

    return msg
  }

  return null
}

/**
 * @param {string} thermostatUuid
 * @param {string} mode
 * @returns {Object?}
 */
ThermostatDevice.getSetFanModeMessage = function (
  thermostatUuid,
  mode
) {

  if (ThermostatDevice.isFanMode(mode)) {

    const msg = Device.getBasCoreMessage(thermostatUuid)
    msg[P.DEVICE][P.STATE] = { [ThermostatDevice.C_FAN_MODE]: mode }

    return msg
  }

  return null
}

/**
 * @param {string} thermostatUuid
 * @param {string} mode
 * @returns {Object?}
 */
ThermostatDevice.getSetLouverModeMessage = function (
  thermostatUuid,
  mode
) {

  if (ThermostatDevice.isLouverMode(mode)) {

    const msg = Device.getBasCoreMessage(thermostatUuid)
    msg[P.DEVICE][P.STATE] = { [ThermostatDevice.C_LOUVER_MODE]: mode }

    return msg
  }
  return null
}

/**
 * Creates a template basCore message for this device
 *
 * @param {string} thermostatUuid
 * @param {string} controlUuid
 * @param {boolean} active
 * @returns {Object}
 */
ThermostatDevice.getSetControlActiveMessage = function (
  thermostatUuid,
  controlUuid,
  active
) {
  if (BasUtil.isBool(active)) {
    const msg = Device.getBasCoreMessage(thermostatUuid)
    msg[P.DEVICE][P.ACTION] = P.UPDATE
    msg[P.DEVICE][P.CONTROL] = {
      [P.UUID]: controlUuid,
      [P.STATE]: {
        [P.ACTIVE]: active
      }
    }
    return msg
  }

  return null
}

module.exports = ThermostatDevice
