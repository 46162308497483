'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('registerLiveCtrl', [
    '$scope',
    'STATES',
    'BAS_LIVE_ACCOUNT',
    'BAS_MODAL',
    'BasState',
    'BasLiveAccount',
    'BasModal',
    registerLiveCtrl
  ])

/**
 * @param $scope
 * @param {STATES} STATES
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasState} BasState
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasModal} BasModal
 */
function registerLiveCtrl (
  $scope,
  STATES,
  BAS_LIVE_ACCOUNT,
  BAS_MODAL,
  BasState,
  BasLiveAccount,
  BasModal
) {
  var register = this

  /**
   * @type {TBasLiveAccountState}
   */
  register.basLiveAccountState = BasLiveAccount.get()

  /**
   * @type {string}
   */
  register.email = ''

  /**
   * @type {string}
   */
  register.password = ''

  /**
   * @type {string}
   */
  register.passwordConfirmation = ''

  /**
   * @type {number}
   */
  register.minimumPasswordLength = 8

  register.performSignUp = performSignUp

  function performSignUp (form) {

    var email, password

    if (form.$valid) {

      email = _getEmail()
      password = _getPassword()

      BasLiveAccount.signUp(
        email,
        password,
        _onSignUp
      )

    } else {

      BasModal.show(BAS_MODAL.T_ERROR)
    }

    function _onSignUp (error, result) {

      if (error) {

        if (error.code === BAS_LIVE_ACCOUNT.A_ERR_USER_EXISTS) {

          BasModal.show(BAS_MODAL.T_ERROR_USER_EXISTS)

        } else {

          BasModal.show(BAS_MODAL.T_ERROR)
        }

      } else {

        // Call parent controller

        if ($scope.live && $scope.live.toVerify) {

          $scope.live.toVerify(result.user, password)
        }

        BasState.go(STATES.CONNECT_LIVE_VERIFY)
      }

      $scope.$applyAsync()
    }
  }

  /**
   * @private
   * @returns {string}
   */
  function _getEmail () {

    return BasUtil.isNEString(register.email)
      ? register.email.toLowerCase()
      : ''
  }

  /**
   * @private
   * @returns {string}
   */
  function _getPassword () {

    return (
      BasUtil.isNEString(register.password) &&
      register.password === register.passwordConfirmation
    )
      ? register.password
      : ''
  }
}
