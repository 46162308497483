'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('DeezerLibraryPage', [
    '$rootScope',
    'LibraryState',
    'BasLibraryPage',
    'DeezerCollection',
    'DeezerModel',
    'Logger',
    DeezerLibraryPageFactory
  ])

/**
 * @typedef {Object} TDeezerCollectionData
 * @property {string} name
 * @property {string} titleName
 * @property {boolean} [hasTab] default true
 * @property {boolean} [canGridView] default true
 * @property {string} contentType
 * @property {string} type
 * @property {number} id
 */

/**
 * @param $rootScope
 * @param LibraryState
 * @param BasLibraryPage
 * @param DeezerCollection
 * @param {DeezerModel} DeezerModel
 * @param Logger
 * @returns {DeezerLibraryPage}
 */
function DeezerLibraryPageFactory (
  $rootScope,
  LibraryState,
  BasLibraryPage,
  DeezerCollection,
  DeezerModel,
  Logger
) {
  var className = 'DeezerLibraryPage'

  /**
   * Object that contains all info to create a library page
   *
   * @constructor
   * @extends BasLibraryPage
   */
  function DeezerLibraryPage () {

    BasLibraryPage.call(this)

    /**
     * @type {string}
     */
    this.content = DeezerLibraryPage.CONTENT_GENERIC

    /**
     * @type {string}
     */
    this.searchQuery = ''
  }

  // Set new prototype from inherited object
  DeezerLibraryPage.prototype = Object.create(BasLibraryPage.prototype)

  // Set correct constructor after setting new prototype
  DeezerLibraryPage.prototype.constructor = DeezerLibraryPage

  /**
   * @constant {string}
   */
  DeezerLibraryPage.CONTENT_GENERIC = 'generic'

  /**
   * @constant {string}
   */
  DeezerLibraryPage.CONTENT_SEARCH = 'search'

  /**
   * @constant {string}
   */
  DeezerLibraryPage.CONTENT_MY_MUSIC = 'myMusic'

  /**
   * @constant {string}
   */
  DeezerLibraryPage.CONTENT_GENRES = 'genres'

  /**
   * @constant {string}
   */
  DeezerLibraryPage.CONTENT_GENRE = 'genre'

  // Methods

  /**
   * Helper function to set the no content state based on the page type
   */
  DeezerLibraryPage.prototype.setStateNoContent = function () {
    switch (this.type) {
      case BasLibraryPage.TYPE_NORMAL:
        if (this.content !== BasLibraryPage.CONTENT_NOT_CONNECTED) {
          this.setState(BasLibraryPage.STATE_NO_CONTENT)
        }
        break
      case BasLibraryPage.TYPE_SEARCH:
        this.setState(BasLibraryPage.STATE_NO_SEARCH_RESULTS)
        break
      default:
        Logger.warn(className +
                      ' - setStateNoContent' +
                      ' - Unknown page type', this.type)
    }
  }

  /**
   * @param {string} content
   */
  DeezerLibraryPage.prototype.setContent = function (content) {

    // Check input
    if (BasUtil.isNEString(content)) {
      this.content = content

      // Check content
      switch (this.content) {
        case BasLibraryPage.CONTENT_NOT_CONNECTED:

          // Show message
          this.setMessage('not_linked')
          // Add message CSS
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_OVERLAY,
            true
          )
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_MESSAGE,
            true
          )
          this.processClasses()

          break
      }
    } else {
      this.content = DeezerLibraryPage.CONTENT_GENERIC
    }
  }

  /**
   * @param {TDeezerCollectionData} data
   */
  DeezerLibraryPage.prototype.makeCollection = function (data) {
    var collection

    // Check defaults
    if (data.hasTab !== false) data.hasTab = true

    if (data.canGridView !== false) data.canGridView = true

    // Make collection
    collection = new DeezerCollection(this.handler)

    // Set properties
    collection.setName(data.name, data.titleName)
    collection.setHasTab(data.hasTab)
    collection.setCanGridView(data.canGridView)
    collection.setContentType(data.contentType)
    collection.type = data.type
    collection.id = data.id

    // Set detail
    if (BasUtil.isObject(this.collections[0].detail)) {
      collection.detail = this.collections[0].detail
    }

    // Set detailElement
    if (BasUtil.isObject(this.collections[0].detailElement)) {
      collection.detailElement = this.collections[0].detailElement
    }

    // Push collection
    this.collectionsData.push(collection)
  }

  DeezerLibraryPage.prototype.setQuery = function (query) {
    if (BasUtil.isNEString(query)) {
      this.searchQuery = query
    }
  }

  /**
   * @returns {Promise}
   */
  DeezerLibraryPage.prototype.retrieve = function () {

    var basSource
    var options

    basSource = LibraryState.getCurrentSource()

    // Check if player and Deezer is valid
    if (basSource && basSource.deezer && basSource.deezer.isLinked()) {

      // Check if search
      if (this.content === DeezerLibraryPage.CONTENT_SEARCH) {

        // Set fetching state
        this.isFetching = true

        options = {
          limit: 2,
          track: true,
          artist: true,
          album: true,
          playlist: true,
          radio: true
        }

        // Perform search
        return basSource.deezer.pageSearch(
          this.searchQuery,
          options
        ).then(
          this.onDeezerBatchItems.bind(this),
          onRetrieveError.bind(this)
        )

      } else if (this.hasTabs) {

        return this.selectTab(this.tabs[0])

      } else {

        return BasLibraryPage.prototype.retrieve.call(this)
      }
    } else {
      Logger.warn(
        className + ' - Retrieve - Invalid source and/or Deezer',
        basSource
      )

      return Promise.reject('Invalid player and/or Deezer')
    }
  }

  /**
   * @param {Object} result
   */
  DeezerLibraryPage.prototype.onDeezerBatchItems = function (result) {
    Logger.debug(className + ' - onDeezerBatchItems', this, result)

    if (Array.isArray(result['batchResults']) &&
      Array.isArray(result['resultsMap'])) {

      // Process and add UI elements
      this.collections = this.processDeezerBatchObjects(
        result,
        this.searchQuery
      )
    } else {
      Logger.warn(className + ' - onDeezerItems - Invalid result', result)
    }

    $rootScope.$applyAsync()

    // Fetching is done
    this.isFetching = false
  }

  /**
   * Call destructors of collections
   */
  DeezerLibraryPage.prototype.destroy = function () {
    var i, length

    // Iterate collections
    length = this.collections.length
    for (i = 0; i < length; i += 1) {

      // Call destructor of collection
      this.collections[i].destroy()
    }
  }

  // Helper functions

  DeezerLibraryPage.prototype.processDeezerCollection =
    processDeezerCollection

  /**
   * @param {string} type
   * @param {Array<?Object>} data
   * @param {string} searchQuery
   * @returns {DeezerCollection}
   */
  function processDeezerCollection (type, data, searchQuery) {
    var collection, showMoreElement

    // Create a collection
    collection = new DeezerCollection(this.handler)

    // Set collection type and title
    collection.setContentType(type, true)

    // Process all Deezer objects and add them to collection
    collection.setElements(DeezerModel.processDeezerObjects(data))

    // Create show more element
    showMoreElement = DeezerModel
      .createSection(DeezerModel.SECTION_MORE)

    showMoreElement.contentType = type
    showMoreElement.query = searchQuery

    // Add "Show More" element to collection
    collection.setLastElement(showMoreElement)

    return collection
  }

  DeezerLibraryPage.prototype.processDeezerBatchObjects =
    processDeezerBatchObjects

  /**
   * @param {Object} batchResult
   * @param {string} searchQuery
   * @returns {Array<DeezerCollection>}
   */
  function processDeezerBatchObjects (
    batchResult,
    searchQuery
  ) {
    var result, collections, length, i, type

    collections = []

    // Iterate results
    length = batchResult.batchResults.length
    for (i = 0; i < length; i += 1) {

      // Assign temporary variable
      result = batchResult.batchResults[i]
      type = batchResult.resultsMap[i]

      // Check result is non empty array
      if (Array.isArray(result.data) &&
        result.data.length > 0) {

        // Add new collection
        collections.push(
          this.processDeezerCollection(
            type,
            result.data,
            searchQuery
          )
        )
      }
    }

    return collections
  }

  function onRetrieveError (error) {
    Logger.warn(className + ' - Retrieve ERROR', error)
  }

  return DeezerLibraryPage
}
