'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceEllieProximity', [
    'BAS_HTML',
    deviceEllieProximity
  ])

function deviceEllieProximity (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.ellieProximityDeviceSettings,
    controller: [
      'BAS_PREFERENCES',
      'BAS_INPUT_MODAL',
      'BasPreferences',
      'BasInputModal',
      controller
    ],
    controllerAs: 'proximity'
  }

  /**
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {BasPreferences} BasPreferences
   * @param {BasInputModal} BasInputModal
   */
  function controller (
    BAS_PREFERENCES,
    BAS_INPUT_MODAL,
    BasPreferences,
    BasInputModal
  ) {
    var proximity = this

    /**
     * @type {TBasModalInputSelectOption[]}
     */
    proximity.PROXIMITY_OPTIONS = [
      {
        value: BAS_PREFERENCES.PROXIMITY_OFF,
        text: 'off',
        translate: true
      },
      {
        value: BAS_PREFERENCES.PROXIMITY_NEAR,
        text: 'proximity_near',
        translate: true
      },
      {
        value: BAS_PREFERENCES.PROXIMITY_MEDIUM,
        text: 'proximity_medium',
        translate: true
      },
      {
        value: BAS_PREFERENCES.PROXIMITY_FAR,
        text: 'proximity_far',
        translate: true
      }
    ]

    /**
     * @type {number}
     */
    proximity.proximity = BasPreferences.getProximitySensitivity()

    proximity.showEditProximity = showEditProximity

    init()

    function init () {

      _syncUiProximity()
    }

    function showEditProximity () {

      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'proximity_activation',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            options: proximity.PROXIMITY_OPTIONS,
            initialValue: proximity.proximity
          }
        ],
        autoAdvance: true,
        allowDismiss: true,
        autoSave: true,
        validCheck: BasUtil.isNEArray
      }).then(onModal)

      function onModal (modal) {

        modal.close.then(function (result) {

          if (BasUtil.isNEArray(result)) {

            proximity.proximity = result[0]
            BasPreferences.setProximitySensitivity(proximity.proximity)
            _syncUiProximity()
          }
        })
      }
    }

    function _syncUiProximity () {

      switch (proximity.proximity) {
        case BAS_PREFERENCES.PROXIMITY_OFF:
          proximity.uiProximity = 'off'
          break
        case BAS_PREFERENCES.PROXIMITY_NEAR:
          proximity.uiProximity = 'proximity_near'
          break
        case BAS_PREFERENCES.PROXIMITY_MEDIUM:
          proximity.uiProximity = 'proximity_medium'
          break
        case BAS_PREFERENCES.PROXIMITY_FAR:
          proximity.uiProximity = 'proximity_far'
          break
        default:
          proximity.uiProximity = '-'
      }
    }
  }
}
