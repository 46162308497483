'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('TidalManager', [
    '$rootScope',
    'BAS_HTML',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_LIBRARY',
    'BAS_LIBRARY_ERRORS',
    'BAS_FAVOURITE',
    'BAS_SOURCE',
    'BAS_MODAL',
    'BAS_APP_PROFILE',
    'ModalService',
    'modalHelperService',
    'BasModal',
    'Selection',
    'BasCurrentAppProfile',
    'BasLibraryManager',
    'BasLibraryBody',
    'BasLibraryPage',
    'BasLibraryDetail',
    'TidalLibraryPage',
    'TidalCollection',
    'TidalElement',
    'TidalHelper',
    'TidalModel',
    'BasUtilities',
    'Logger',
    tidalManagerFactory
  ])

/**
 * @typedef {Object} contextData
 * @property {TidalElement} element
 * @property {TidalCollection} collection
 * @property {Object} event
 */

/**
 * @param $rootScope
 * @param {BAS_HTML} BAS_HTML
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param ModalService
 * @param modalHelperService
 * @param {BasModal} BasModal
 * @param Selection
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param BasLibraryManager
 * @param BasLibraryBody
 * @param BasLibraryPage
 * @param BasLibraryDetail
 * @param TidalLibraryPage
 * @param TidalCollection
 * @param TidalElement
 * @param {TidalHelper} TidalHelper
 * @param {TidalModel} TidalModel
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns {TidalManager}
 */
function tidalManagerFactory (
  $rootScope,
  BAS_HTML,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_LIBRARY,
  BAS_LIBRARY_ERRORS,
  BAS_FAVOURITE,
  BAS_SOURCE,
  BAS_MODAL,
  BAS_APP_PROFILE,
  ModalService,
  modalHelperService,
  BasModal,
  Selection,
  BasCurrentAppProfile,
  BasLibraryManager,
  BasLibraryBody,
  BasLibraryPage,
  BasLibraryDetail,
  TidalLibraryPage,
  TidalCollection,
  TidalElement,
  TidalHelper,
  TidalModel,
  BasUtilities,
  Logger
) {
  var className = 'Tidal Library Manager'

  /**
   * @constructor
   * @extends BasLibraryManager
   * @param {PlayerLibraryState} libraryState
   */
  function TidalManager (libraryState) {

    this._handleRemovePlaylist = this._onRemovePlaylist.bind(this)
    this._handleChangePlaylistTitle =
      this._onChangePlaylistTitle.bind(this)

    // Call template constructor
    BasLibraryManager.call(this, libraryState)

    // Link the control functions
    this.linkControls()

    // Initialize header
    this.header.setTitle(BasUtilities.translate('tidal'))
    this.header.setSubtitle('')
    this.header.enableSearch(true)
    this.header.enableNavigation(true)

    // Selection
    this.selection = new Selection()
    this.selection.type = Selection.TYPE_TIDAL

    // Add the start state
    this.addPage(this.startPage)

    /**
     * @instance
     * @type {number}
     */
    this.tidalId = -1
  }

  // Set new prototype from inherited object
  TidalManager.prototype = Object.create(BasLibraryManager.prototype)

  // Set correct constructor after setting new prototype
  TidalManager.prototype.constructor = TidalManager

  TidalManager.prototype.linkControls = function () {

    // Body controls
    this.body.control[BasLibraryBody.CLK_ELEMENT] =
      this.selectElement.bind(this)
    this.body.control[BasLibraryBody.CLK_BROWSE] =
      this.selectElement.bind(this)
    this.body.control[BasLibraryBody.CLK_CONTEXT] =
      this.clickContext.bind(this)
    this.body.control[BasLibraryBody.CLK_PLAY] =
      this.clickPlay.bind(this)
    this.body.control[BasLibraryBody.CLK_REMOVE] =
      this.clickRemove.bind(this)
    this.body.control[BasLibraryBody.CLK_FAVOURITE] =
      this.clickFavourite.bind(this)
  }

  // region Control functions

  /**
   * @param {TidalElement} element
   * @param {string} [shortcut]
   */
  TidalManager.prototype.selectElement = function (
    element,
    shortcut
  ) {
    var page, retrieve, detailElement, detail, album, artist
    var collection, basSource

    Logger.debug(className + ' - Select', this.currentPage, element)

    // Check if a new page needs to be created
    if (isValidShortcut(shortcut) ||
      element.canBrowse) {

      // Create a new page
      page = new TidalLibraryPage(this.handler)
      page.addCollection(new TidalCollection(this.handler))
    }

    // Check for shortcut
    switch (shortcut) {
      case BAS_LIBRARY.SHORTCUT_ALBUM:

        // Check element for Tidal Album object
        if (BasUtil.isObject(element) &&
          BasUtil.isObject(element.album) &&
          typeof element.album.id === 'number') {

          album = element.album
          artist = element.artist

          // Titles
          page.setTitleId('album')
          page.setSubtitle(album.title)

          // Create new element for detail view
          detailElement = TidalModel
            .processTidalObject(album, TidalElement.TYPE_ALBUM)

          // Create detail
          detail = new BasLibraryDetail()
          if (BasUtil.isNEString(album.title)) {
            detail.title = album.title
          }
          if (BasUtil.isObject(artist) &&
            BasUtil.isNEString(artist.name)) {
            detail.subtitle = artist.name
          }

          // Detail specifics
          detail.setCanPlay(true)
          detail.setHasContext(true)
          detail.setCanSelect(true)

          basSource = this.libraryState.getTidalSource()
          if (basSource) {

            basSource.tidal.getAlbum(album.id).then(onExtraAlbum)
          }

          collection = page.getCollection(0)
          if (collection) {

            collection.setDetail(detail)
            collection.setDetailElement(detailElement)
            collection.setContentType(TidalCollection.CT_TRACK)
            collection.type = TidalCollection.TYPE_ALBUM
            collection.id = album.id
          }

          retrieve = true

        } else {
          Logger.warn(
            className + ' - Select - SHORTCUT album - Invalid album object',
            element
          )
        }

        break
      case BAS_LIBRARY.SHORTCUT_ARTIST:

        // Check element for Tidal Artist object
        if (BasUtil.isObject(element) &&
          BasUtil.isObject(element.artist) &&
          typeof element.artist.id === 'number') {

          artist = element.artist

          // Titles
          page.setTitleId('artist')
          page.setSubtitle(artist.name)
          page.lockSubtitle = true

          // Create new element for detail view
          detailElement = TidalModel
            .processTidalObject(artist, TidalElement.TYPE_ARTIST)

          // Create detail
          detail = new BasLibraryDetail()
          if (BasUtil.isNEString(artist.name)) {
            detail.title = artist.name
          }
          detail.setHasContext(true)

          basSource = this.libraryState.getTidalSource()
          if (basSource) {

            basSource.tidal.getArtist(artist.id)
              .then(onExtraArtist)
          }

          collection = page.getCollection(0)
          if (collection) {

            collection.setDetail(detail)
            collection.setDetailElement(detailElement)
          }

          // Detail specifics
          this.setPageCollections(
            page,
            TidalCollection.TYPE_ARTIST,
            artist.id
          )

          retrieve = true

        } else {
          Logger.warn(
            className + ' - Select - SHORTCUT artist - Invalid artist object',
            element
          )
        }

        break
      default:

        // Process selected UI element
        retrieve = this.processElement(page, element)
    }

    // Check page
    if (BasUtil.isObject(page)) {

      this.addPage(page, retrieve)
    }

    function onExtraAlbum (result) {

      var _element, _collection

      _element = TidalModel.processTidalObject(result, TidalElement.TYPE_ALBUM)
      _collection = page.getCollection(0)

      if (BasUtil.isObject(_element) && _collection) {
        // Set detail element
        _collection.setDetailElement(_element)

        // Check for coverart
        detail.setHasCoverArt(_element.covers.large)
      }
    }

    function onExtraArtist (result) {
      var _element, collections, _collection, length, i

      // Process Element
      _element = TidalModel.processTidalObject(result, TidalElement.TYPE_ARTIST)

      if (BasUtil.isObject(_element)) {

        collections = page.collectionsData
        length = collections.length
        for (i = 0; i < length; i++) {
          _collection = collections[i]
          _collection.setDetailElement(_element)

          // Check if it has coverArt
          detail.setHasCoverArt(_element.covers.large)
        }
      }
    }
  }

  TidalManager.prototype.processElement = function (page, element) {

    var retrieve, detail, collection

    switch (element.type) {
      case TidalElement.TYPE_SECTION:

        switch (element.subType) {
          case TidalModel.SECTION_NEW:

            // Title
            page.setTitleId('new')

            // Set content
            page.setContent(TidalLibraryPage.CONTENT_NEW)

            // Make collections
            this.setPageCollections(page, TidalCollection.TYPE_NEW)

            retrieve = true
            break
          case TidalModel.SECTION_TIDAL_RISING:

            // Title
            page.setTitleId('tidal_rising')

            // Set content
            page.setContent(TidalLibraryPage.CONTENT_TIDAL_RISING)

            // Make collections
            this.setPageCollections(
              page,
              TidalCollection.TYPE_RISING
            )

            retrieve = true
            break
          case TidalModel.SECTION_PLAYLISTS:

            // Title
            page.setTitleId('moods')

            // Content type
            page.setContent(TidalLibraryPage.CONTENT_PLAYLISTS)

            collection = page.getCollection(0)
            if (collection) {

              collection.setContentType(TidalCollection.CT_MOOD)
              collection.setCanGridView(true)
            }

            retrieve = true

            break
          case TidalModel.SECTION_GENRES:

            // Title
            page.setTitleId('genres')

            // Content type
            page.setContent(TidalLibraryPage.CONTENT_GENRES)

            collection = page.getCollection(0)
            if (collection) {

              collection.setContentType(TidalCollection.CT_GENRE)
              collection.setCanGridView(true)
            }

            retrieve = true

            break
          case TidalModel.SECTION_MY_MUSIC:

            // Title
            page.setTitleId('my_music')

            // Set content
            page.setContent(TidalLibraryPage.CONTENT_MY_MUSIC)

            // Make collections
            this.setPageCollections(
              page,
              TidalCollection.TYPE_USER
            )

            retrieve = true
            break
          case TidalModel.SECTION_MORE:

            // Title
            page.setTitleId('search')

            // Content type
            page.setContent(TidalLibraryPage.CONTENT_GENERIC)

            collection = page.getCollection(0)
            if (collection) {

              collection.setContentType(element.searchData.type)
              collection.id = element.searchData.query
              collection.setCanGridView(true)
              collection.type = TidalCollection.TYPE_SEARCH
            }

            retrieve = true

            break
        }
        break
      case TidalElement.TYPE_MOOD:

        // Title
        page.setTitleId('mood')
        page.setSubtitle(element.title)

        // Content type
        page.setContent(TidalLibraryPage.CONTENT_GENRE)

        collection = page.getCollection(0)
        if (collection) {
          collection.setContentType(TidalCollection.CT_PLAYLIST)
          collection.setCanGridView(true)
          collection.type = TidalCollection.TYPE_MOOD
          collection.id = element.path
        }

        retrieve = true
        break
      case TidalElement.TYPE_GENRE:

        // Title
        page.setTitle(element.title)

        // Set content
        page.setContent(TidalLibraryPage.CONTENT_GENRE)

        // Make collections
        this.setPageCollections(
          page,
          TidalCollection.TYPE_GENRE,
          element.path
        )

        retrieve = true
        break
      case TidalElement.TYPE_PLAYLIST:

        // Title
        page.setTitleId('playlist')
        page.setSubtitle(element.title)

        // Create detail
        detail = new BasLibraryDetail()

        collection = page.getCollection(0)
        if (collection) {

          collection.setCanEdit(element.creator === this.tidalId)
          collection.type = TidalCollection.TYPE_PLAYLIST
          collection.setContentType(TidalCollection.CT_TRACK)
          collection.setDetail(detail)
          collection.setDetailElement(element)
          collection.id = element.id
        }

        // Fill detail
        detail.title = element.title
        detail.subtitle = element.subtitle
        detail.setHasCoverArt(element.covers.large)
        detail.setCanPlay(true)
        detail.setHasContext(true)
        detail.setCanSelect(true)
        detail.setCanAsanoFavourite(true)
        if (collection) detail.setCanEdit(collection.canEdit)

        // Content type
        page.setContent(TidalLibraryPage.CONTENT_GENERIC)

        retrieve = true

        break
      case TidalElement.TYPE_ALBUM:

        // Title
        page.setTitleId('album')
        page.setSubtitle(element.title)

        // Create detail
        detail = new BasLibraryDetail()
        detail.title = element.title
        detail.subtitle = element.subtitle
        detail.setHasCoverArt(element.covers.large)
        detail.setCanPlay(true)
        detail.setHasContext(true)
        detail.setCanSelect(true)

        collection = page.getCollection(0)
        if (collection) {

          collection.setDetail(detail)
          collection.setDetailElement(element)
          collection.setContentType(TidalCollection.CT_TRACK)
          collection.type = TidalCollection.TYPE_ALBUM
          collection.id = element.id
        }

        // Content type
        page.setContent(TidalLibraryPage.CONTENT_GENERIC)

        retrieve = true

        break
      case TidalElement.TYPE_ARTIST:

        // Title
        page.setTitleId('artist')
        page.setSubtitle(element.title)

        // Create detail
        detail = new BasLibraryDetail()
        detail.title = element.title
        detail.subtitle = element.subtitle
        detail.setHasCoverArt(element.covers.large)
        detail.setHasContext(true)
        detail.setCanPlay(true)

        collection = page.getCollection(0)
        if (collection) {

          collection.setDetail(detail)
          collection.setDetailElement(element)
        }

        // Set lockSubtitle
        page.lockSubtitle = true

        // Content type
        page.setContent(TidalLibraryPage.CONTENT_GENERIC)

        // Make collections
        this.setPageCollections(
          page,
          TidalCollection.TYPE_ARTIST,
          element.id
        )

        retrieve = true

        break
      case TidalElement.TYPE_TRACK:

        TidalHelper.play(
          element,
          BAS_API.Queue.ADD_OPTIONS.now
        )

        break
      default:
        Logger.warn(className + ' - ProcessElement - ' +
                      'element type unknown', element)
        break
    }

    return retrieve
  }

  /**
   *
   * @param {TidalElement} element
   */
  TidalManager.prototype.clickPlay = function (element) {

    TidalHelper.play(
      element,
      BAS_API.Queue.ADD_OPTIONS.replaceNow
    )
  }

  /**
   * @param {TidalElement} element
   */
  TidalManager.prototype.clickFavourite = function (element) {

    var basSource, identifier

    basSource = this.libraryState.getBasSource()

    identifier = (basSource && element)
      ? basSource.isAudioSource
        ? element.asanoFavouriteUri
        : element.asanoFavouriteId
      : ''

    // Check element
    if (
      BasUtil.isObject(element) &&
      basSource &&
      basSource.favourites &&
      BasUtil.isNEString(identifier)
    ) {

      if (
        basSource.favourites.isFavourite(identifier)
      ) {

        basSource.favourites.remove(identifier)

      } else {

        basSource.favourites.add(
          BAS_FAVOURITE.T_TIDAL,
          basSource.isAudioSource
            ? identifier
            : element.getFavouriteId()
        )
      }
    }
  }

  /**
   * @param {contextData} data
   */
  TidalManager.prototype.clickContext = function (data) {
    var _this

    _this = this

    Logger.debug(className + ' - clickContext', data.element, data.event)

    ModalService
      .showModal({
        template: BAS_HTML.templateContextModal,
        controller: 'basLibraryContextCtrl',
        controllerAs: 'modal',
        inputs: {
          event: data.event,
          element: data.element,
          collection: data.collection,
          libType: BAS_LIBRARY.TYPE_TIDAL,
          selection: null,
          handler: this.handler
        }
      })
      .then(onContextModal)

    // Set scroll lock
    this.body.setClass(BasLibraryBody.CLASS_LOCK_SCROLLING)

    function onContextModal (modal) {

      // Check modal
      if (BasUtil.isObject(modal) &&
        modal.controller &&
        typeof modal.controller.onModalReady === 'function') {

        // Execute modal ready function
        modal.controller.onModalReady()
      }

      // Set closed Promise
      modal.closed
        .then(onContextModalClosed)
    }

    function onContextModalClosed () {

      // Reset modal style
      modalHelperService.resetModalStyle()

      // Clear scroll lock
      _this.body.setClass(BasLibraryBody.CLASS_LOCK_SCROLLING, false)
    }
  }

  TidalManager.prototype.selectMenu = function (event, selection, handler) {

    return ModalService
      .showModal({
        template: BAS_HTML.templateContextModal,
        controller: 'basLibraryContextCtrl',
        controllerAs: 'modal',
        inputs: {
          event: event,
          selection: selection,
          element: null,
          collection: null,
          libType: BAS_LIBRARY.TYPE_TIDAL,
          handler: handler
        }
      })
      .then(onModalShown)

    function onModalShown (modal) {
      Logger.debug(className +
                     ' - onModalShown', modal, modal.controller)

      // Modal is ready, DOM manipulation is allowed
      if (modal.controller &&
        modal.controller.onModalReady &&
        typeof modal.controller.onModalReady === 'function') {

        modal.controller.onModalReady()
      }

      // Set modal closed Promise callbacks
      return modal.closed.then(onModalClosed)

      function onModalClosed () {

        // Reset modal height
        modalHelperService.resetModalStyle()

        return Promise.resolve(BAS_LIBRARY.MODAL_CLOSED)
      }
    }
  }

  TidalManager.prototype.clickRemove = function () {

    var _this = this

    // Show confirmation modal
    BasModal.show(BAS_MODAL.T_REMOVE).then(onPlaylistRemoveModal)

    function onPlaylistRemoveModal (modal) {

      // Set close Promise
      modal.close.then(onPlaylistRemoveModalClose)
    }

    function onPlaylistRemoveModalClose (result) {

      var basSource, collection, id

      basSource = _this.libraryState.getTidalSource()

      if (result === BAS_MODAL.C_YES) {

        collection = _this.currentPage.getCollection(0)

        if (BasUtil.isObject(collection.detailElement)) {

          if (basSource && basSource.tidal) {

            id = collection.detailElement.id
            basSource.tidal.removePlaylist(id)
              .then(_this._handleRemovePlaylist)
          }
        }
      }
    }
  }

  TidalManager.prototype._onRemovePlaylist = function () {

    this.handler(BAS_LIBRARY.EVT_PLAYLIST_CHANGED)

    this.back()
  }

  /**
   * @param {string} title
   * @param {string} id
   * @returns {Promise}
   */
  TidalManager.prototype.changeTitleRequest = function (title, id) {

    var basSource

    basSource = this.libraryState.getTidalSource()

    if (basSource && basSource.tidal.isLinked()) {

      return basSource.tidal.changePlaylistTitle(id, title)
        .then(this._handleChangePlaylistTitle)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_TIDAL)
  }

  /**
   * @private
   */
  TidalManager.prototype._onChangePlaylistTitle = function () {

    this.handler(BAS_LIBRARY.EVT_PLAYLIST_CHANGED)
  }

  // endregion

  // region Header controls

  TidalManager.prototype.evtPlaylistChanged = function () {

    var length, i, page, pages, collection

    pages = this.pages

    length = pages.length
    for (i = 0; i < length; i++) {

      page = pages[i]
      collection = page.getCollection(0)

      if (BasUtil.isObject(collection) &&
        collection.contentType === TidalCollection.CT_PLAYLIST &&
        !BasUtil.isObject(collection.detail)) {

        page.dirty = true
      }
    }
  }

  TidalManager.prototype.search = function (searchQuery) {

    var searchPage

    if (BasUtil.isNEString(searchQuery)) {

      searchPage = new TidalLibraryPage(this.handler)

      // Page content type
      searchPage.content = TidalLibraryPage.CONTENT_SEARCH

      // Titles
      searchPage.setTitleId('search')
      searchPage.setSubtitle(searchQuery)

      // Make pages
      searchPage.addCollection(this.makeSearchCollection(
        searchQuery,
        TidalCollection.CT_ALBUM
      ))
      searchPage.addCollection(this.makeSearchCollection(
        searchQuery,
        TidalCollection.CT_ARTIST
      ))
      searchPage.addCollection(this.makeSearchCollection(
        searchQuery,
        TidalCollection.CT_TRACK
      ))
      searchPage.addCollection(this.makeSearchCollection(
        searchQuery,
        TidalCollection.CT_PLAYLIST
      ))

      // Add page
      this.addPage(searchPage, true)

      // Make sure to close the search
      this.header.toggleSearchInput(false)

      BasCurrentAppProfile.addSearchHistory(
        searchQuery,
        BAS_APP_PROFILE.K_TIDAL
      )

      // Clear searchQuery
      this.searchQuery = ''
    }
  }

  TidalManager.prototype.toggleSearch = function () {

    if (this.currentPage &&
      this.currentPage.content === TidalLibraryPage.CONTENT_SEARCH) {

      this.searchQuery = this.currentPage.subtitle
    }
  }

  // endregion

  // region Helper function

  /**
   * @param shortcut
   * @returns {boolean}
   */
  function isValidShortcut (shortcut) {
    return (
      shortcut === BAS_LIBRARY.SHORTCUT_ALBUM ||
      shortcut === BAS_LIBRARY.SHORTCUT_ARTIST
    )
  }

  /**
   * @param {TidalLibraryPage} page
   * @param {string} type
   * @param {string} [path]
   */
  TidalManager.prototype.setPageCollections = function (page, type, path) {

    switch (type) {
      case TidalCollection.TYPE_NEW:
      case TidalCollection.TYPE_GENRE:
        page.makeCollection({
          name: 'playlists_l',
          titleName: 'playlists',
          contentType: TidalCollection.CT_PLAYLIST,
          type: type,
          id: path
        })
        page.makeCollection({
          name: 'albums',
          titleName: 'albums',
          contentType: TidalCollection.CT_ALBUM,
          type: type,
          id: path
        })
        page.makeCollection({
          name: 'songs',
          titleName: 'songs',
          contentType: TidalCollection.CT_TRACK,
          type: type,
          id: path
        })
        break
      case TidalCollection.TYPE_RISING:
        page.makeCollection({
          name: 'albums',
          titleName: 'albums',
          contentType: TidalCollection.CT_ALBUM,
          type: type,
          id: path
        })
        page.makeCollection({
          name: 'songs',
          titleName: 'songs',
          contentType: TidalCollection.CT_TRACK,
          type: type,
          id: path
        })
        break
      case TidalCollection.TYPE_ARTIST:
        page.makeCollection({
          name: 'top_tracks',
          titleName: 'top_tracks',
          canGridView: false,
          contentType: TidalCollection.CT_TRACK,
          type: type,
          id: path
        })
        page.makeCollection({
          name: 'albums',
          titleName: 'albums',
          canGridView: false,
          contentType: TidalCollection.CT_ALBUM,
          type: type,
          id: path
        })
        page.makeCollection({
          name: 'related_artists',
          titleName: 'related_artists',
          canGridView: false,
          contentType: TidalCollection.CT_ARTIST,
          type: type,
          id: path
        })
        break
      case TidalCollection.TYPE_USER:
        page.makeCollection({
          name: 'playlists_l',
          titleName: 'playlists',
          contentType: TidalCollection.CT_PLAYLIST,
          type: type,
          id: this.tidalId
        })
        page.makeCollection({
          name: 'artists',
          titleName: 'artists',
          contentType: TidalCollection.CT_ARTIST,
          type: type,
          id: this.tidalId
        })
        page.makeCollection({
          name: 'albums',
          titleName: 'albums',
          contentType: TidalCollection.CT_ALBUM,
          type: type,
          id: this.tidalId
        })
        page.makeCollection({
          name: 'songs',
          titleName: 'songs',
          contentType: TidalCollection.CT_TRACK,
          type: type,
          id: this.tidalId
        })
        break
    }

    page.makeTabs()
  }

  TidalManager.prototype.createStartPage = function () {

    // Create new page
    this.startPage = new TidalLibraryPage(this.handler)
    this.startPage.addCollection(new TidalCollection(this.handler))
    this.startPage.setTitleId('tidal')

    // Set page content type
    this.startPage.setContent(BasLibraryPage.CONTENT_START)

    // Add start elements
    this.startPage.addElement(
      0,
      TidalModel.createSection(TidalModel.SECTION_NEW)
    )
    this.startPage.addElement(
      0,
      TidalModel.createSection(TidalModel.SECTION_MY_MUSIC)
    )
    this.startPage.addElement(
      0,
      TidalModel.createSection(TidalModel.SECTION_TIDAL_RISING)
    )
    this.startPage.addElement(
      0,
      TidalModel.createSection(TidalModel.SECTION_PLAYLISTS)
    )
    this.startPage.addElement(
      0,
      TidalModel.createSection(TidalModel.SECTION_GENRES)
    )
  }

  TidalManager.prototype.makeSearchCollection = function (
    query,
    contentType
  ) {
    var searchCollection, showMoreElement

    searchCollection = new TidalCollection(this.handler)
    searchCollection.setTitleId(getCollectionTitle(contentType))
    searchCollection.setContentType(contentType)
    searchCollection.hasReachedEnd = true
    searchCollection.type = TidalCollection.TYPE_SEARCH
    searchCollection.limit = 2
    searchCollection.id = query

    showMoreElement = TidalModel.createSection(
      TidalModel.SECTION_MORE,
      contentType,
      query
    )

    searchCollection.setLastElement(showMoreElement)

    return searchCollection
  }

  function getCollectionTitle (contentType) {

    switch (contentType) {
      case TidalCollection.CT_ALBUM:
        return 'albums'
      case TidalCollection.CT_ARTIST:
        return 'artists'
      case TidalCollection.CT_TRACK:
        return 'songs'
      case TidalCollection.CT_PLAYLIST:
        return 'playlists'
    }
    return BasUtilities.translate('songs')
  }

  // endregion

  // region Resume & Suspend

  TidalManager.prototype.onTidalLinkChanged = function (
    _event,
    id
  ) {
    if (id === this.libraryState.playerId) this.checkTidalLink()
  }

  TidalManager.prototype.onServerVariables = function () {

    this.checkTidalLink()
  }

  /**
   * Checks whether the linked tidal is still valid
   */
  TidalManager.prototype._checkTidalLink = function () {

    var id
    var basSource = this.libraryState.getTidalSource()

    if (basSource &&
      basSource.tidal.isLinked()) {

      id = basSource.tidal.getId()

      // Make sure NoTidalPage is gone before you want to go home
      this.removeNotConnectedPage()

      if (id !== this.tidalId &&
        BasUtil.isPNumber(this.tidalId, true)) {

        // Clear selection
        this.selection.clear()

        // Go to homepage
        this.home()
      }

      // Set tidal after check
      this.tidalId = id

    } else {

      this.tidalId = -1

      // Clear selection
      this.selection.clear()

      // Go to homepage
      this.home()

      // Show "No Tidal" page
      this.addNotConnectedPage()
    }
  }

  TidalManager.prototype.checkTidalLink = function () {

    var basSource = this.libraryState.getTidalSource()

    if (basSource) {

      basSource.tidal.linked().then(this._checkTidalLink.bind(this))
    }
  }

  /**
   * Resume listening to events
   *
   * @method
   */
  TidalManager.prototype.resume = function () {

    Logger.debug(className + ' - Resume')

    // Wait one frame to set new scrollPos
    if (BasUtil.isObject(this.currentPage)) {

      BasUtilities.waitFrames(2)
        .then(
          this.setScrollPos.bind(
            this,
            this.currentPage.getScrollPercent()
          )
        )
    }

    // Set listeners
    this.listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_TIDAL_LINK_CHANGED,
      this.onTidalLinkChanged.bind(this)
    ))
    this.listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      this.onServerVariables.bind(this)
    ))

    // Check link
    this.checkTidalLink()

    // Translate
    this.onTranslationChange()

    // Resume the current page
    if (BasUtil.isObject(this.currentPage)) {

      this.currentPage.resume()
    }
  }

  // endregion

  return TidalManager
}
