'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasUpdater', [
    '$window',
    '$rootScope',
    'BAS_UPDATER',
    'BasAppDevice',
    BasUpdater
  ])

/**
 * @typedef {Object} TBasUpdaterServer
 * @property {string} mac
 * @property {string} ip
 */

/**
 * @typedef {Object} TBasUpdaterNetworkConfig
 * @property {string} configuration
 * @property {string} [ip]
 * @property {string} [subnetMask]
 * @property {string} [gateway]
 * @property {string} [dns]
 */

/**
 * @constructor
 * @param $window
 * @param $rootScope
 * @param {BAS_UPDATER} BAS_UPDATER
 * @param {BasAppDevice} BasAppDevice
 */
function BasUpdater (
  $window,
  $rootScope,
  BAS_UPDATER,
  BasAppDevice
) {

  /**
   * @constant {string}
   */
  var APP_URI = 'musicapp.basalte.be'

  /**
   * @type {Object}
   */
  var _updater = null

  /**
   * @typedef {Object} BasUpdaterState
   * @property {?Updater} updater Cordova plugin instance
   * @property {?Object} updateInfo
   * @property {?Object} systemInfo
   * @property {?Object} appsInfo
   * @property {string} uiCurrentVersion
   * @property {(string|number)} uiNewVersion
   * @property {string} updateState
   * @property {string} uiUpdateState
   * @property {boolean} uiHasUpdate
   * @property {boolean} updateRequiresRestart
   * @property {Object} css
   */

  /**
   * @type {BasUpdaterState}
   */
  var state = {}
  state.updateInfo = null
  state.uiCurrentVersion = '-'
  state.uiNewVersion = '-'
  state.uiUpdateState = BAS_UPDATER.S_UPDATE_NONE
  state.updateRequiresRestart = false
  state.uiHasUpdate = false
  state.css = {}

  this.get = get
  this.getUpdateInfo = getUpdateInfo
  this.install = install
  this.setup = setup
  this.setupApps = setupApps
  this.performInstall = performInstall
  this.checkForUpdates = checkForUpdates
  this.getCurrentTimestamp = getSystemCurrentTimestamp
  this.getCurrentTimestampStr = getSystemCurrentTimestampStr
  this.getNewTimestamp = getSystemNewTimestamp
  this.getNewTimestampStr = getSystemNewTimestampStr
  this.getCurrentVersionStr = getSystemCurrentVersionStr
  this.getCurrentVersion = getSystemCurrentVersion
  this.getNewVersion = getSystemNewVersion
  this.getSystemState = getSystemState
  this.getSystemProgress = getSystemProgress

  init()

  function init () {

    var updater

    _syncCurrentVersion()
    _syncNewVersion()
    _syncCss()
    _syncUpdateState()

    if (BasAppDevice.isCoreClient()) {

      updater = _getUpdater()

      if (updater) {

        _updater = updater

        updater.addListener(_onUpdaterMessage)
        updater.init()

        // Copy initial update
        state.updateInfo = updater.updateInfo

        state.systemInfo = updater.systemInfo
        state.appsInfo = updater.appsInfo

        getUpdateInfo()
      }
    }
  }

  /**
   * @returns {BasUpdaterState}
   */
  function get () {

    return state
  }

  function getUpdateInfo () {

    var updater = _getUpdater()

    if (updater) updater.getUpdateInfo()
  }

  function install () {

    var updater = _getUpdater()

    if (updater) updater.installUpdate()
  }

  function setup () {

    var updater = _getUpdater()

    if (updater) updater.setup()
  }

  function setupApps () {

    var updater = _getUpdater()

    if (updater) updater.setupApps()
  }

  function checkForUpdates () {

    var updater = _getUpdater()

    if (updater) updater.checkForUpdates()
  }

  /**
   * Perform install, check whether install is system install or app update.
   */
  function performInstall () {

    var appsInfo, systemHasUpdate, appsHasUpdate

    appsInfo = getAppsInfo()

    if (appsInfo) {

      systemHasUpdate = getSystemHasUpdate()

      if (systemHasUpdate) {

        install()

      } else {

        appsHasUpdate = getAppsHasUpdate()

        if (appsHasUpdate) setup()
      }

    } else {

      // Legacy
      install()
    }
  }

  /**
   * @returns {?Object}
   */
  function getSystemInfo () {

    if (_updater && BasUtil.isObject(_updater.systemInfo)) {

      return _updater.systemInfo
    }

    return null
  }

  /**
   * @returns {?Object}
   */
  function getAppsInfo () {

    if (_updater && BasUtil.isObject(_updater.appsInfo)) {

      return _updater.appsInfo
    }

    return null
  }

  /**
   * @returns {?Object}
   */
  function getUpdateInfoObj () {

    if (_updater && BasUtil.isObject(_updater.updateInfo)) {

      return _updater.updateInfo
    }

    return null
  }

  /**
   * @returns {number}
   */
  function getSystemCurrentTimestamp () {

    var systemInfo, currentTimestamp

    systemInfo = getSystemInfo()

    if (systemInfo) {

      currentTimestamp = systemInfo[BAS_UPDATER.K_CURRENT_TIMESTAMP]
    }

    return BasUtil.isPNumber(currentTimestamp) ? currentTimestamp : -1
  }

  /**
   * @returns {number}
   */
  function getSystemNewTimestamp () {

    var systemInfo, newTimestamp

    systemInfo = getSystemInfo()

    if (systemInfo) {

      newTimestamp = systemInfo[BAS_UPDATER.K_NEW_TIMESTAMP]
    }

    return BasUtil.isPNumber(newTimestamp) ? newTimestamp : -1
  }

  /**
   * @returns {string}
   */
  function getSystemCurrentTimestampStr () {

    return '' + getSystemCurrentTimestamp()
  }

  /**
   * @returns {string}
   */
  function getSystemNewTimestampStr () {

    return '' + getSystemNewTimestamp()
  }

  /**
   * @returns {string}
   */
  function getSystemCurrentVersionStr () {

    var systemInfo, updateInfo, currentVersion

    systemInfo = getSystemInfo()

    if (systemInfo) {

      currentVersion = systemInfo[BAS_UPDATER.K_CURRENT_VERSION]

    } else {

      updateInfo = getUpdateInfoObj()

      if (updateInfo) {

        currentVersion = updateInfo[BAS_UPDATER.K_CURRENT_VERSION]
      }
    }

    return BasUtil.isNEString(currentVersion) ? currentVersion : ''
  }

  /**
   * Returns -1 if current version is not a valid number
   *
   * @returns {number}
   */
  function getSystemCurrentVersion () {

    var str, result

    str = getSystemCurrentVersionStr()

    result = parseInt(str, 10)

    return BasUtil.isVNumber(result) ? result : -1
  }

  /**
   * Returns -1 if no valid new version was found
   *
   * @returns {number}
   */
  function getSystemNewVersion () {

    var systemInfo, updateInfo, newVersion

    systemInfo = getSystemInfo()

    if (systemInfo) {

      newVersion = systemInfo[BAS_UPDATER.K_NEW_VERSION]

    } else {

      updateInfo = getUpdateInfoObj()

      if (updateInfo) {

        newVersion = updateInfo[BAS_UPDATER.K_NEW_VERSION]
      }
    }

    return BasUtil.isPNumber(newVersion, true) ? newVersion : -1
  }

  /**
   * @returns {string}
   */
  function getSystemState () {

    var systemInfo, updateInfo, status

    systemInfo = getSystemInfo()

    if (systemInfo) {

      status = systemInfo[BAS_UPDATER.K_STATUS]

    } else {

      updateInfo = getUpdateInfoObj()

      if (updateInfo) {

        status = updateInfo[BAS_UPDATER.K_STATUS]
      }
    }

    return BasUtil.isNEString(status) ? status : ''
  }

  /**
   * Legacy updateInfo
   *
   * @returns {string}
   */
  function getUpdateInfoState () {

    var updateInfo, status

    updateInfo = getUpdateInfoObj()

    if (updateInfo) {

      status = updateInfo[BAS_UPDATER.K_STATUS]
    }

    return BasUtil.isNEString(status) ? status : ''
  }

  /**
   * @returns {number}
   */
  function getSystemProgress () {

    var systemInfo, updateInfo, progress

    systemInfo = getSystemInfo()

    if (systemInfo) {

      progress = systemInfo[BAS_UPDATER.K_PROGRESS]

    } else {

      updateInfo = getUpdateInfoObj()

      if (updateInfo) {

        progress = updateInfo[BAS_UPDATER.K_PROGRESS]
      }
    }

    return BasUtil.isPNumber(progress, true) ? progress : -1
  }

  /**
   * @private
   * @returns {string[]}
   */
  function _getAllAppsStateArr () {

    var result, appsInfo, keys, length, i, appInfo, _state

    result = []

    appsInfo = getAppsInfo()

    if (appsInfo) {

      keys = Object.keys(appsInfo)
      length = keys.length
      for (i = 0; i < length; i++) {

        appInfo = appsInfo[keys[i]]

        if (BasUtil.isObject(appInfo)) {

          _state = appInfo[BAS_UPDATER.K_STATUS]

          if (BasUtil.isNEString(_state)) {

            result.push(_state)
          }
        }
      }
    }

    return result
  }

  /**
   * Returns the "lowest" app state of all states.
   *
   * For example: 1 app is "downloading"
   * the others are "done" or "verifying",
   * apps state will be "downloading".
   *
   * @returns {string}
   */
  function getAppsState () {

    return _getLowestState(_getAllAppsStateArr())
  }

  /**
   * Returns the "lowest" state of all states.
   *
   * For example: 1 state is "downloading"
   * the others are "done" or "verifying",
   * lowest state will be "downloading".
   *
   * @private
   * @param {string[]} states
   * @returns {string}
   */
  function _getLowestState (states) {

    var length

    if (Array.isArray(states)) {

      length = states.length

      if (length > 0) {

        if (states.indexOf(BAS_UPDATER.S_CHECKING) > -1) {

          return BAS_UPDATER.S_CHECKING

        } else if (states.indexOf(BAS_UPDATER.S_DOWNLOADING) > -1) {

          return BAS_UPDATER.S_DOWNLOADING

        } else if (states.indexOf(BAS_UPDATER.S_VERIFYING) > -1) {

          return BAS_UPDATER.S_VERIFYING

        } else if (states.indexOf(BAS_UPDATER.S_INSTALLING) > -1) {

          return BAS_UPDATER.S_INSTALLING

        } else if (states.indexOf(BAS_UPDATER.S_READY) > -1) {

          return BAS_UPDATER.S_READY

        } else if (states.indexOf(BAS_UPDATER.S_DONE) > -1) {

          return BAS_UPDATER.S_DONE
        }
      }
    }

    return ''
  }

  /**
   * @returns {(boolean|undefined)}
   */
  function getSystemHasUpdate () {

    var systemInfo, updateInfo, hasUpdate

    systemInfo = getSystemInfo()

    if (systemInfo) {

      hasUpdate = systemInfo[BAS_UPDATER.K_HAS_UPDATE]

    } else {

      updateInfo = getUpdateInfoObj()

      if (updateInfo) {

        hasUpdate = updateInfo[BAS_UPDATER.K_HAS_UPDATE]
      }
    }

    return BasUtil.isBool(hasUpdate) ? hasUpdate : undefined
  }

  /**
   * If at least 1 app has an update, return true.
   *
   * @returns {(boolean|undefined)}
   */
  function getAppsHasUpdate () {

    var appsInfo, keys, length, i, appInfo, value, hasUpdate

    hasUpdate = undefined

    appsInfo = getAppsInfo()

    if (appsInfo) {

      keys = Object.keys(appsInfo)
      length = keys.length
      for (i = 0; i < length; i++) {

        appInfo = appsInfo[keys[i]]

        if (BasUtil.isObject(appInfo)) {

          value = appInfo[BAS_UPDATER.K_HAS_UPDATE]

          if (BasUtil.isBool(value)) {

            if (hasUpdate === undefined) {

              hasUpdate = value

            } else {

              if (value) hasUpdate = value
            }
          }
        }
      }
    }

    return hasUpdate
  }

  /**
   * If at least 1 app has an update, return true.
   *
   * @returns {(boolean|undefined)}
   */
  function getHomeAppHasUpdate () {

    var appsInfo, appInfo, value

    appsInfo = getAppsInfo()

    if (appsInfo) {

      appInfo = appsInfo[APP_URI]

      if (BasUtil.isObject(appInfo)) {

        value = appInfo[BAS_UPDATER.K_HAS_UPDATE]

        return value === true
      }
    }

    return false
  }

  /**
   * Fired when Updater configuration has changed
   *
   * @private
   * @param {?Object} message
   * @param {string} message.type
   * @param {*} [message.data]
   */
  function _onUpdaterMessage (message) {

    var updater, type, data

    updater = _getUpdater()

    if (
      updater &&
      BasUtil.isObject(message)
    ) {

      type = message[updater.K_TYPE]
      data = message[updater.K_DATA]

      switch (type) {
        case updater.T_UPDATER_UPDATE_INFO:

          if (BasUtil.isObject(data)) {

            state.updateInfo = data

            _syncCurrentVersion()
            _syncNewVersion()
            _syncCss()
            _syncUpdateState()

            $rootScope.$emit(BAS_UPDATER.EVT_UPDATER_UPDATE_INFO)
          }

          break
        case updater.T_UPDATER_SYSTEM_INFO:

          _syncCurrentVersion()
          _syncNewVersion()
          _syncCss()
          _syncUpdateState()

          $rootScope.$emit(BAS_UPDATER.EVT_UPDATER_UPDATE_INFO)

          break
        case updater.T_UPDATER_APP_INFO:

          _syncCurrentVersion()
          _syncNewVersion()
          _syncCss()
          _syncUpdateState()

          $rootScope.$emit(BAS_UPDATER.EVT_UPDATER_UPDATE_INFO)

          break
      }
    }
  }

  function _syncCurrentVersion () {

    var value = getSystemCurrentVersionStr()
    state.uiCurrentVersion = BasUtil.isNEString(value) ? value : '-'
  }

  function _syncNewVersion () {

    var value = getSystemNewVersion()
    state.uiNewVersion = value > -1 ? value : '-'
  }

  function _syncUpdateState () {

    var appsInfo, updaterSystemState, appsState
    var systemHasUpdate, appsHasUpdate, lowestState, updateState
    var updateRequiresRestart

    appsInfo = getAppsInfo()

    if (appsInfo) {

      updaterSystemState = getSystemState()
      appsState = getAppsState()
      lowestState = _getLowestState([updaterSystemState, appsState])

      updateState = _determineUpdateState(lowestState)

      updateRequiresRestart = false

      if (updateState === BAS_UPDATER.S_UPDATE_NONE) {

        systemHasUpdate = getSystemHasUpdate()
        appsHasUpdate = getAppsHasUpdate()

        if (systemHasUpdate || appsHasUpdate) {

          updateState = BAS_UPDATER.S_UPDATE_READY

          updateRequiresRestart = !!getHomeAppHasUpdate()
        }
      }

      state.uiUpdateState = updateState
      state.updateRequiresRestart = updateRequiresRestart

    } else {

      _legacySyncUiUpdateState(getUpdateInfoState())
    }
  }

  function _syncCss () {

    var appsInfo, appsState, systemHasUpdate, appsHasUpdate
    var updaterSystemState, lowestState

    appsInfo = getAppsInfo()

    if (appsInfo) {

      systemHasUpdate = getSystemHasUpdate()
      appsHasUpdate = getAppsHasUpdate()

      // New version

      state.css[BAS_UPDATER.CSS_CORE_CLIENT_HAS_NEW_VERSION] =
        !!(systemHasUpdate || appsHasUpdate)

      // Update progressing

      updaterSystemState = getSystemState()
      appsState = getAppsState()
      lowestState = _getLowestState([updaterSystemState, appsState])

      state.css[BAS_UPDATER.CSS_CORE_CLIENT_UPDATE_PROGRESS] = false

      switch (lowestState) {
        case BAS_UPDATER.S_CHECKING:
        case BAS_UPDATER.S_DOWNLOADING:
        case BAS_UPDATER.S_VERIFYING:
        case BAS_UPDATER.S_INSTALLING:

          state.css[BAS_UPDATER.CSS_CORE_CLIENT_UPDATE_PROGRESS] = true

          break
      }

    } else {

      _legacySyncUiCss()
    }

    state.uiHasUpdate = state.css[BAS_UPDATER.CSS_CORE_CLIENT_HAS_NEW_VERSION]
  }

  /**
   * For older updater versions.
   *
   * @private
   * @param {string} updaterState
   */
  function _legacySyncUiUpdateState (updaterState) {

    var updateState

    updateState = _determineUpdateState(updaterState)

    // To work around potential bug
    // where state was not correctly send from updater after download

    if (updateState === BAS_UPDATER.S_UPDATE_NONE) {

      updateState = _determineUpdateReady()
    }

    state.uiUpdateState = updateState
    state.updateRequiresRestart = updateState === BAS_UPDATER.S_UPDATE_READY
  }

  /**
   * For older updater versions.
   *
   * @private
   */
  function _legacySyncUiCss () {

    var currentVersion, newVersion, systemState

    currentVersion = getSystemCurrentVersion()
    newVersion = getSystemNewVersion()

    // New version

    if (newVersion > -1) {

      if (currentVersion > -1) {

        state.css[BAS_UPDATER.CSS_CORE_CLIENT_HAS_NEW_VERSION] =
          newVersion !== currentVersion

      } else {

        state.css[BAS_UPDATER.CSS_CORE_CLIENT_HAS_NEW_VERSION] = true
      }

    } else {

      state.css[BAS_UPDATER.CSS_CORE_CLIENT_HAS_NEW_VERSION] = false
    }

    systemState = getUpdateInfoState()

    // Update progressing

    switch (systemState) {
      case BAS_UPDATER.S_CHECKING:
      case BAS_UPDATER.S_DOWNLOADING:
      case BAS_UPDATER.S_VERIFYING:
      case BAS_UPDATER.S_INSTALLING:

        state.css[BAS_UPDATER.CSS_CORE_CLIENT_UPDATE_PROGRESS] = true

        break

      default:

        state.css[BAS_UPDATER.CSS_CORE_CLIENT_UPDATE_PROGRESS] = false

        break
    }
  }

  /**
   * @private
   * @param {string} updaterState
   * @returns {string}
   */
  function _determineUpdateState (updaterState) {

    switch (updaterState) {
      case BAS_UPDATER.S_CHECKING:

        return BAS_UPDATER.S_UPDATE_CHECKING

      case BAS_UPDATER.S_DOWNLOADING:

        return BAS_UPDATER.S_UPDATE_DOWNLOADING

      case BAS_UPDATER.S_VERIFYING:

        return BAS_UPDATER.S_UPDATE_VERIFYING

      case BAS_UPDATER.S_INSTALLING:

        return BAS_UPDATER.S_UPDATE_INSTALLING

      case BAS_UPDATER.S_READY:
      case BAS_UPDATER.S_DONE:
      default:

        return BAS_UPDATER.S_UPDATE_NONE
    }
  }

  /**
   * Determine if system update is available or not
   * based on current and new version system version.
   *
   * @private
   * @returns {string}
   */
  function _determineUpdateReady () {

    var currentVersion, newVersion

    currentVersion = getSystemCurrentVersion()
    newVersion = getSystemNewVersion()

    if (newVersion > -1) {

      if (currentVersion > -1) {

        if (currentVersion !== newVersion) {

          return BAS_UPDATER.S_UPDATE_READY

        } else {

          return BAS_UPDATER.S_UPDATE_NONE
        }

      } else {

        return BAS_UPDATER.S_UPDATE_READY
      }

    } else {

      return BAS_UPDATER.S_UPDATE_NONE
    }
  }

  /**
   * Get Updater plugin instance
   *
   * @private
   * @returns {?Updater}
   */
  function _getUpdater () {

    if (BasUtil.isObject($window['basalteCordova']) &&
      BasUtil.isObject($window['basalteCordova']['coreClientUpdater'])) {

      return $window['basalteCordova']['coreClientUpdater']
    }

    return null
  }
}
