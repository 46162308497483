'use strict'

angular
  .module('basalteApp')
  .constant('BAS_SCHEDULER', {

    /**
     * @type {BasSchedulerLine[]}
     */
    HOUR_LINES_13: [],
    HOUR_LINES_13_LENGTH: 13,

    /**
     * @type {BasSchedulerLine[]}
     */
    VALUE_LINES_5: [],
    VALUE_LINES_5_LENGTH: 5,

    /**
     * @type {BasSchedulerLine[]}
     */
    VALUE_LINES_6: [],
    VALUE_LINES_6_LENGTH: 6,

    /**
     * @type {BasSchedulerLine[]}
     */
    VALUE_LINES_2: [],
    VALUE_LINES_2_LENGTH: 2,

    CSS_MINOR: 'bsch-line--minor',
    CSS_SUBTEXT_BEFORE: 'bsch-line-subtext-before'
  })
