'use strict'

angular
  .module('basalteApp')
  .controller('forgotLiveCtrl', [
    '$scope',
    'STATES',
    'BAS_MODAL',
    'BAS_UTILITIES',
    'BasState',
    'BasLiveAccount',
    'BasModal',
    forgotLiveCtrl
  ])

/**
 * @param $scope
 * @param {STATES} STATES
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_UTILITIES} BAS_UTILITIES
 * @param {BasState} BasState
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasModal} BasModal
 */
function forgotLiveCtrl (
  $scope,
  STATES,
  BAS_MODAL,
  BAS_UTILITIES,
  BasState,
  BasLiveAccount,
  BasModal
) {
  var forgot = this

  /**
   * @type {TBasLiveAccountState}
   */
  forgot.basLiveAccountState = BasLiveAccount.get()

  /**
   * @type {string}
   */
  forgot.email = ''

  /**
   * @type {string}
   */
  forgot.code = ''

  /**
   * @type {string}
   */
  forgot.password = ''

  /**
   * @type {string}
   */
  forgot.passwordConfirmation = ''

  /**
   * @type {boolean}
   */
  forgot.hasCognitoUser = false

  forgot.setNewPasword = setNewPassword
  forgot.resendCode = resendCode

  init()

  function init () {

    forgot.hasCognitoUser = !!_getCognitoUserFromLive()
  }

  function setNewPassword (form) {

    var _cognitoUser, _password

    if (form.$valid) {

      _cognitoUser = _getCognitoUser()

      if (_cognitoUser) {

        _password = forgot.password

        BasLiveAccount.confirmNewPassword(
          _cognitoUser,
          forgot.code,
          forgot.password,
          _onConfirmNewPassword
        )

      } else {

        BasModal.show(BAS_MODAL.T_ERROR_INVALID_USER)
      }

    } else {

      BasModal.show(BAS_MODAL.T_ERROR)
    }

    function _onConfirmNewPassword (error) {

      if (error) {

        BasModal.show(BAS_MODAL.T_ERROR_INVALID_CODE)
          .then(_onInvalidCodeModal)

      } else {

        if ($scope.live) {

          $scope.live.toVerify(_cognitoUser, _password)
        }

        if (_getCognitoUserFromLive()) {

          // Login with saved credentials

          $scope.live.loginToVerifyUser()

        } else {

          // Go to login

          BasState.go(STATES.CONNECT_LIVE_LOGIN)
        }
      }
    }
  }

  function resendCode () {

    const email = forgot.email.toLowerCase()

    BasLiveAccount.forgotPassword(
      email,
      _onForgotPassword
    )

    function _onForgotPassword (error, result) {

      if (error) {

        BasModal.show(BAS_MODAL.T_ERROR)

      } else if (result === 'COMPLETE') {

        // Account wasn't verified yet
        BasState.go(STATES.CONNECT_LIVE_LOGIN)
        BasModal.show(BAS_MODAL.T_VERIFICATION_MAIL_SENT)

      } else {
        BasModal.show(
          BAS_MODAL.T_CONFIRMATION_CODE_SENT,
          {
            email: email
          }
        )
      }
    }
  }

  /**
   * @private
   * @returns {?CognitoUser}
   */
  function _getCognitoUser () {

    var _cognitoUser

    if (forgot.email &&
      forgot.email.length > 3 &&
      BAS_UTILITIES.REGEX_EMAIL.test(forgot.email)) {

      _cognitoUser = BasLiveAccount.getCognitoUser(
        forgot.email.toLowerCase()
      )
    }

    if (!_cognitoUser) {

      _cognitoUser = _getCognitoUserFromLive()
    }

    return _cognitoUser || null
  }

  function _onInvalidCodeModal (modal) {

    modal.closed.then(_onInvalidCoreModalClosed)
  }

  function _onInvalidCoreModalClosed () {

    forgot.code = ''
  }

  /**
   * @private
   * @returns {?CognitoUser}
   */
  function _getCognitoUserFromLive () {

    return $scope.live
      ? $scope.live.getToVerifyUser()
      : null
  }
}
