'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasApiMismatch', [
    '$window',
    'ModalService',
    'BAS_HTML',
    'BAS_MODAL',
    'BAS_SPLASH',
    'BAS_ERRORS',
    'BasModal',
    'BasSplash',
    'AppLink',
    'BasAppDevice',
    'BasServerRestart',
    'BasError',
    BasApiMismatch
  ])

/**
 * @callback CBasApiMismatchCallback
 * @param {?BasError} error
 * @param {TBasApiMismatch} result
 */

/**
 * @callback CBasApiMismatchAbort
 */

/**
 * @callback CBasApiMismatchTakeBasServer
 * @returns {?BasServer}
 */

/**
 * @typedef {Object} TBasApiMismatch
 * @property {CBasApiMismatchAbort} abort
 * @property {CBasApiMismatchTakeBasServer} takeBasServer
 * @property {boolean} finished
 * @property {BasServer} [basServer]
 * @property {TBasServerRestart} [basServerRestart]
 */

/**
 * @constructor
 * @param $window
 * @param ModalService
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BasModal} BasModal
 * @param {BasSplash} BasSplash
 * @param {AppLink} AppLink
 * @param {BasAppDevice} BasAppDevice
 * @param {BasServerRestart} BasServerRestart
 * @param BasError
 */
function BasApiMismatch (
  $window,
  ModalService,
  BAS_HTML,
  BAS_MODAL,
  BAS_SPLASH,
  BAS_ERRORS,
  BasModal,
  BasSplash,
  AppLink,
  BasAppDevice,
  BasServerRestart,
  BasError
) {
  var _WAIT_TOTAL_UPDATE_DOWNLOAD_TIMEOUT_MS = 120000
  var _WAIT_CHECK_UPDATE_DOWNLOAD_TIMEOUT_MS = 5000

  this.handleApiMismatch = handleApiMismatch

  /**
   * @param {BasServer} basServer
   * @param {CBasApiMismatchCallback} [callback]
   * @returns {TBasApiMismatch}
   */
  function handleApiMismatch (
    basServer,
    callback
  ) {
    var _result, _aborted, _cbCalled
    var _unsupportedApi
    var modalOpts, _outdatedModal, _loginProfilesModal
    var _totalUpdateDownloadTimeoutId, _checkUpdateDownloadTimeoutId

    _aborted = false
    _cbCalled = false

    _result = {
      abort: abort,
      takeBasServer: takeBasServer,
      finished: false
    }

    modalOpts = {
      locationChangeSuccess: false
    }

    if (basServer) {

      _unsupportedApi = basServer.unsupportedApi

      if (isNaN(_unsupportedApi)) {

        _cb(new BasError(
          BAS_ERRORS.T_INVALID_INPUT,
          _unsupportedApi,
          'Invalid unsupportedApi on BasServer'
        ))

      } else {

        if (_unsupportedApi === 0) {

          // App and server are compatible

          _result.basServer = basServer

          _cb(null)

        } else if (_unsupportedApi > 0) {

          // App is newer, update server

          BasModal.show(
            BAS_MODAL.T_UNSUPPORTED_API_ACTION,
            {
              modalOptions: modalOpts
            }
          )
            .then(_onOutdatedModal)
            .then(_updateServer, _onUserAborted)

        } else {

          // Server is newer, update app

          if (BasAppDevice.isBrowser()) {

            BasModal.show(
              BAS_MODAL.T_UNSUPPORTED_APP_BROWSER,
              {
                modalOptions: modalOpts
              }
            )
              .then(_onOutdatedModal)
              .then(_reloadBrowser, _onUserAborted)

          } else if (BasAppDevice.isIos()) {

            BasModal.show(
              BAS_MODAL.T_UNSUPPORTED_APP_IOS,
              {
                modalOptions: modalOpts
              }
            )
              .then(_onOutdatedModal)
              .then(_openStore, _onUserAborted)

          } else if (BasAppDevice.isAndroid()) {

            BasModal.show(
              BAS_MODAL.T_UNSUPPORTED_APP_ANDROID,
              {
                modalOptions: modalOpts
              }
            )
              .then(_onOutdatedModal)
              .then(_openStore, _onUserAborted)

          } else {

            BasModal.show(
              BAS_MODAL.T_UNSUPPORTED_APP,
              {
                modalOptions: modalOpts
              }
            )
              .then(_onOutdatedModal)
              .then(_onUserAborted, _onUserAborted)
          }
        }
      }

    } else {

      _cb(new BasError(
        BAS_ERRORS.T_INVALID_INPUT,
        basServer,
        'Invalid BasServer'
      ))
    }

    return _result

    function _onOutdatedModal (modal) {

      _outdatedModal = modal

      return modal.closed.then(_onOutdatedModalClosed)
    }

    function _onOutdatedModalClosed (result) {

      if (result === BAS_MODAL.C_YES) return result

      return Promise.reject(BAS_MODAL.C_NO)
    }

    function _reloadBrowser () {

      if (_aborted || _cbCalled) return

      // Forced reload (without using the cache)
      $window.location.reload(true)
    }

    function _openStore () {

      if (_aborted || _cbCalled) return

      AppLink.openStore(AppLink.APP_BASASLTE)

      _cleanUp()

      _cb(null)
    }

    function _updateServer () {

      if (_aborted || _cbCalled) return

      if (basServer.apiVersion === 0) {

        // Legacy
        _updateServerLegacy()

      } else {

        // Newer
        _updateServerLegacy()

        // TODO Have a multi-server proof way of updating
      }
    }

    function _updateServerLegacy () {

      basServer.startUpdateCheck().catch(_onStartUpdateCheckError)

      basServer.getUsers().then(_onUsers, _onUsersError)
    }

    function _onStartUpdateCheckError (error) {

      if (_aborted || _cbCalled) return

      _cleanUp()

      _cb(new BasError(
        BAS_ERRORS.T_CONNECTION,
        error,
        'startUpdateCheck error'
      ))
    }

    function _onUsers () {

      var admins, length, i, profile, candidate

      if (_aborted || _cbCalled) return

      // Look for admin without password

      admins = basServer.admins

      length = admins.length
      for (i = 0; i < length; i++) {

        profile = admins[i]

        if (!profile.hasPassword) {

          candidate = profile
          break
        }
      }

      if (candidate) {

        basServer.login(profile.username)
          .then(_onAdminLogin, _onAdminLoginError)

      } else {

        ModalService.showModal({
          template: BAS_HTML.loginProfilesModal,
          controller: 'loginProfilesModalCtrl',
          controllerAs: 'loginProfilesModal',
          inputs: {
            basServer: basServer,
            profilesKey: 'admins'
          }
        })
          .then(_onLoginProfilesModal)
          .then(_onAdminLogin, _onUserAborted)
      }
    }

    function _onUsersError (error) {

      if (_aborted || _cbCalled) return

      _cleanUp()

      _cb(new BasError(
        BAS_ERRORS.T_CONNECTION,
        error,
        'getUsers error'
      ))
    }

    function _onLoginProfilesModal (modal) {

      return modal.close.then(_onLoginProfilesModalClosed)
    }

    /**
     * @private
     * @param {(undefined|BasServer)} result
     * @returns {(BasServer|Promise)}
     */
    function _onLoginProfilesModalClosed (result) {

      return result || Promise.reject(new Error('no result'))
    }

    function _onAdminLogin () {

      if (_aborted || _cbCalled) return

      BasSplash.show(BAS_SPLASH.S_CONNECTING_UPDATING)

      _totalUpdateDownloadTimeoutId = setTimeout(
        _onTotalUpdateDownloadTimeout,
        _WAIT_TOTAL_UPDATE_DOWNLOAD_TIMEOUT_MS
      )

      basServer.getStatus()
        .then(_onBasServerStatus, _onBasServerStatusError)
    }

    function _onAdminLoginError (error) {

      if (_aborted || _cbCalled) return

      _cleanUp()

      _cb(new BasError(
        BAS_ERRORS.T_CONNECTION,
        error,
        'Admin login error'
      ))
    }

    function _onBasServerStatus () {

      if (_aborted || _cbCalled) return

      if (basServer.status) {

        if (basServer.status.hasUpdate) {

          _clearTotalUpdateDownloadTimeout()
          _clearCheckUpdateDownloadTimeout()

          _result.basServerRestart = BasServerRestart.restartServer(
            basServer,
            _onRestartServer
          )

        } else {

          _clearCheckUpdateDownloadTimeout()

          _checkUpdateDownloadTimeoutId = setTimeout(
            _checkUpdateDownload,
            _WAIT_CHECK_UPDATE_DOWNLOAD_TIMEOUT_MS
          )
        }

      } else {

        _cleanUp()

        _cb(new BasError(
          BAS_ERRORS.T_INTERNAL,
          basServer.status,
          'Invalid status'
        ))
      }
    }

    function _onBasServerStatusError (error) {

      if (_aborted || _cbCalled) return

      // Server is not reachable

      _cleanUp()

      _cb(new BasError(
        BAS_ERRORS.T_CONNECTION,
        error,
        'onStatus error'
      ))
    }

    function _checkUpdateDownload () {

      if (_aborted || _cbCalled) return

      basServer.getStatus()
        .then(_onBasServerStatus, _onBasServerStatusError)
    }

    function _onTotalUpdateDownloadTimeout () {

      if (_aborted || _cbCalled) return

      _cleanUp()

      _cb(new BasError(
        BAS_ERRORS.T_TIMEOUT,
        undefined,
        BAS_ERRORS.M_TIMEOUT
      ))
    }

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasServerRestart} result
     */
    function _onRestartServer (error, result) {

      if (_aborted || _cbCalled) return

      if (error) {

        _cleanUp()

        _cb(new BasError(
          BAS_ERRORS.T_CONNECTION,
          error,
          'onRestart error'
        ))

      } else {

        _result.basServer = result.basSmartConnect.takeBasServer()

        _cleanUp()

        _cb(null)
      }
    }

    /**
     * Called when user declines modal
     *
     * @private
     */
    function _onUserAborted () {

      if (_aborted || _cbCalled) return

      _cleanUp()

      _cb(new BasError(
        BAS_ERRORS.T_USER_DECLINE,
        undefined,
        'USer declined process'
      ))
    }

    function _clearOutdatedModal () {

      if (_outdatedModal &&
        _outdatedModal.controller &&
        _outdatedModal.controller.close) {

        _outdatedModal.controller.close()
      }

      _outdatedModal = null
    }

    function _clearLoginProfilesModal () {

      if (_loginProfilesModal &&
        _loginProfilesModal.controller &&
        _loginProfilesModal.controller.close) {

        _loginProfilesModal.controller.close()
      }

      _loginProfilesModal = null
    }

    function _clearTotalUpdateDownloadTimeout () {

      clearTimeout(_totalUpdateDownloadTimeoutId)
      _totalUpdateDownloadTimeoutId = 0
    }

    function _clearCheckUpdateDownloadTimeout () {

      clearTimeout(_checkUpdateDownloadTimeoutId)
      _checkUpdateDownloadTimeoutId = 0
    }

    function _cleanUp () {

      _clearCheckUpdateDownloadTimeout()
      _clearTotalUpdateDownloadTimeout()

      _clearOutdatedModal()
      _clearLoginProfilesModal()
    }

    /**
     * @returns {?BasServer}
     */
    function takeBasServer () {

      var _basServer

      _basServer = _result.basServer
      _result.basServer = null

      return _basServer
    }

    function abort () {

      _aborted = true

      _cleanUp()

      _cb(new BasError(
        BAS_ERRORS.T_ABORT,
        undefined,
        BAS_ERRORS.M_ABORTED
      ))
    }

    /**
     * @private
     * @param {?BasError} error
     */
    function _cb (error) {

      if (!_cbCalled) {

        _cbCalled = true

        _result.finished = true

        if (BasUtil.isFunction(callback)) {

          callback(error, _result)
        }
      }
    }
  }
}
