'use strict'

var macAddressUtil = require('@gidw/mac-address-util')
var BasUtil = require('@basalte/bas-util')

var Device = require('./device')

var P = require('./parser_constants')

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {BasCore} basCore
 * @since 2.2.0
 */
function ConnectedDevice (device, basCore) {

  Device.call(this, device, basCore)

  /**
   * @private
   * @type {number}
   */
  this._mac = BasUtil.isPNumber(device[P.MAC]) ? device[P.MAC] : 0

  /**
   * @private
   * @type {?BasUtil.BasVersion}
   */
  this._version = BasUtil.isNEString(device[P.VERSION])
    ? new BasUtil.BasVersion(device[P.VERSION])
    : null

  /**
   * @private
   * @type {string}
   */
  this._macStr = macAddressUtil.convertToMac(this._mac)
}

ConnectedDevice.prototype = Object.create(Device.prototype)
ConnectedDevice.prototype.constructor = ConnectedDevice

/**
 * @name ConnectedDevice#mac
 * @type {number}
 * @readonly
 */
Object.defineProperty(ConnectedDevice.prototype, 'mac', {
  get: function () {
    return this._mac
  }
})

/**
 * @name ConnectedDevice#version
 * @type {?BasUtil.BasVersion}
 * @readonly
 */
Object.defineProperty(ConnectedDevice.prototype, 'version', {
  get: function () {
    return this._version
  }
})

/**
 * @name ConnectedDevice#macStr
 * @type {string}
 * @readonly
 */
Object.defineProperty(ConnectedDevice.prototype, 'macStr', {
  get: function () {
    return this._macStr
  }
})

module.exports = ConnectedDevice
