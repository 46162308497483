'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('RadioElement', [
    'BAS_LIBRARY',
    'BAS_TUNE_IN',
    'BasLibraryElement',
    'Logger',
    radioElementFactory
  ])

/**
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BAS_TUNE_IN} BAS_TUNE_IN
 * @param BasLibraryElement
 * @param Logger
 * @returns RadioElement
 */
function radioElementFactory (
  BAS_LIBRARY,
  BAS_TUNE_IN,
  BasLibraryElement,
  Logger
) {
  var className = 'Radio Element'

  /**
   * @constructor
   * @extends BasLibraryElement
   */
  function RadioElement () {

    // Call 'super' constructor
    BasLibraryElement.call(this)

    this.libraryType = BAS_LIBRARY.TYPE_TUNEIN

    /**
     * @instance
     * @type {string}
     */
    this.type = BAS_TUNE_IN.EL_TYPE_LINK

    /**
     * @instance
     * @type {string}
     */
    this.URL = ''

    /**
     * @instance
     * @type {string}
     */
    this.path = ''

    /**
     * @instance
     * @type {boolean}
     */
    this.hasRadioCover = false

    /**
     * @instance
     * @type {string}
     */
    this.servicePrefix = 'tunein:radio:'
  }

  // Set new prototype from inherited object
  RadioElement.prototype = Object.create(BasLibraryElement.prototype)

  // Set correct constructor after setting new prototype
  RadioElement.prototype.constructor = RadioElement

  // Instance methods

  /**
   * @param {string} value
   */
  RadioElement.prototype.setType = function (value) {

    // Check input
    if (value === BAS_TUNE_IN.EL_TYPE_LINK ||
      value === BAS_TUNE_IN.EL_TYPE_AUDIO) {
      this.type = value
    } else {
      Logger.warn(className + ' - setType - Invalid type', value)
    }
  }

  /**
   * @param {string} value
   */
  RadioElement.prototype.setUrl = function (value) {

    // Check input
    if (BasUtil.isNEString(value)) {
      this.URL = value
    } else {
      this.URL = ''
    }
  }

  /**
   * @param {TCoversObj} object
   */
  RadioElement.prototype.setCoverArt = function (object) {

    BasLibraryElement.prototype.setCoverArt.call(this, object)

    this.hasCover = false
    this.hasRadioCover = true
  }

  /**
   * @param {string} value
   */
  RadioElement.prototype.setPath = function (value) {

    // Check input
    if (BasUtil.isNEString(value)) {
      this.path = value
    } else {
      this.path = ''
    }
  }

  return RadioElement
}
