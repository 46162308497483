'use strict'

angular
  .module('basalteApp')
  .service('BasLiveHelper', [
    'STATES',
    'BasState',
    'BasCoreConnection',
    'BasLiveAccount',
    BasLiveHelper
  ])

/**
 * @constructor
 * @param {STATES} STATES
 * @param {BasState} BasState
 * @param {BasCoreConnection} BasCoreConnection
 * @param {BasLiveAccount} BasLiveAccount
 */
function BasLiveHelper (
  STATES,
  BasState,
  BasCoreConnection,
  BasLiveAccount
) {
  /**
   * @type {TBasLiveAccountState}
   */
  var basLiveAccountState = BasLiveAccount.get()

  this.onLiveLoginSuccess = onLiveLoginSuccess

  function onLiveLoginSuccess () {

    var length, projectId, basLiveProject

    length = basLiveAccountState.uiProjectsOnline.length

    BasLiveAccount.toggleShowBackButton(false)

    if (length === 1) {

      projectId = basLiveAccountState.uiProjectsOnline[0]

      basLiveProject = basLiveAccountState.projects[projectId]

      if (basLiveProject) {

        BasCoreConnection.prepareSelectCore()

        BasCoreConnection.selectCore(basLiveProject)

      } else {

        BasState.go(STATES.CONNECT_DISCOVERY)
      }

    } else {

      BasState.go(STATES.CONNECT_DISCOVERY)
    }
  }
}
