'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('RoomsHelper', [
    'BAS_API',
    'BAS_ROOMS',
    'BAS_ROOM',
    RoomsHelper
  ])

/**
 * @callback CBasForEachRoom
 * @param {BasRoom} room
 * @param {number} index
 * @param {string[]} uuids
 */

/**
 * @constructor
 * @param BAS_API
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 */
function RoomsHelper (
  BAS_API,
  BAS_ROOMS,
  BAS_ROOM
) {
  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  this.forEachRoom = forEachRoom
  this.basRoomCompare = basRoomCompare
  this.basRoomUuidCompare = basRoomUuidCompare
  this.checkFloors = checkFloors
  this.roomHasFunction = roomHasFunction
  this.roomHasFunctionAudio = roomHasFunctionAudio
  this.roomHasFunctionScenes = roomHasFunctionScenes
  this.roomHasFunctionLights = roomHasFunctionLights
  this.roomHasFunctionWindowTreatments = roomHasFunctionWindowTreatments
  this.roomHasFunctionThermostat = roomHasFunctionThermostat
  this.roomHasFunctionGeneric = roomHasFunctionGeneric
  this.roomHasFunctionEnergy = roomHasFunctionEnergy
  this.roomHasFunctionSecurity = roomHasFunctionSecurity
  this.roomHasFunctionTimers = roomHasFunctionTimers
  this.roomHasFunctionOpenClose = roomHasFunctionOpenClose
  this.roomHasFunctionIntercom = roomHasFunctionIntercom
  this.roomHasFunctionWeatherStation = roomHasFunctionWeatherStation
  this.roomHasFunctionAV = roomHasFunctionAV
  this.hasMusic = hasMusic
  this.hasVideo = hasVideo
  this.hasThermostat = hasThermostat
  this.hasCameras = hasCameras
  this.hasIntercomRooms = hasIntercomRooms
  this.hasEnergyRooms = hasEnergyRooms
  this.getHome = getHome
  this.getRoomForId = getRoomForId
  this.getFirstRoom = getFirstRoom
  this.getRoomBuilding = getRoomBuilding
  this.getRoomFloor = getRoomFloor
  this.getFloorBuildingId = getFloorBuildingId
  this.getRoomBuildingPartKey = getRoomBuildingPartKey
  this.getRoomFloorPartKey = getRoomFloorPartKey
  this.getCameraByUuid = getCameraByUuid
  this.getDoorPhoneGatewayByUuid = getDoorPhoneGatewayByUuid
  this.getEnergyRooms = getEnergyRooms
  this.getEnergyRoomsPerBuilding = getEnergyRoomsPerBuilding
  this.hasAudio = hasAudio
  this.getUniqueSourceIdentifier = getUniqueSourceIdentifier
  this.getRoomWithDefaultSource = getRoomWithDefaultSource
  this.getFirstRoomWithSource = getFirstRoomWithSource

  /**
   * @param {CBasForEachRoom} callback
   * @param {string} [variant]
   */
  function forEachRoom (callback, variant) {

    var keys, length, i, uuid, room, _checkVariant

    _checkVariant = BasUtil.isNEString(variant)

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (room && room.isRoom) {

          if (_checkVariant) {

            if (room.variant === variant) callback(room, i, keys)

          } else {

            callback(room, i, keys)
          }
        }
      }
    }
  }

  /**
   * Compares 2 rooms by order.
   *
   * @param {BasRoom} a
   * @param {BasRoom} b
   * @returns {number}
   */
  function basRoomCompare (a, b) {
    return a.order - b.order
  }

  /**
   * @param {string} uuid1
   * @param {string} uuid2
   * @returns {number}
   */
  function basRoomUuidCompare (uuid1, uuid2) {
    var _room1, _room2

    if (uuid1 && uuid2) {

      _room1 = rooms.rooms[uuid1]
      _room2 = rooms.rooms[uuid2]

      if (_room1 && _room1.isRoom && _room2 && _room2.isRoom) {

        return basRoomCompare(_room1, _room2)

      } else if (_room1 && _room1.isRoom) {

        return -1

      } else if (_room2 && _room2.isRoom) {

        return 1
      }

    } else if (uuid1) {

      _room1 = rooms.rooms[uuid1]

      return (_room1 && _room1.isRoom) ? -1 : 0

    } else if (uuid2) {

      _room2 = rooms.rooms[uuid2]

      return (_room2 && _room2.isRoom) ? 1 : 0
    }

    return 0
  }

  /**
   * @param {BasRoom} room
   * @param {string[]} floorIds
   * @returns {boolean}
   */
  function checkFloors (room, floorIds) {
    return floorIds.indexOf(room.getFloorId()) > -1
  }

  /**
   * @param {BasRoom} room
   * @param {string} func
   * @returns {boolean}
   */
  function roomHasFunction (room, func) {
    return (
      func &&
      room &&
      room.isRoom &&
      room.room &&
      room.room.functions &&
      room.room.functions[func]
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionAudio (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.AUDIO)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionScenes (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.SCENES)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionLights (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.LIGHTS)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionWindowTreatments (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.WINDOW_TREATMENTS)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionThermostat (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.THERMOSTAT)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionGeneric (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.GENERIC)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionEnergy (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.ENERGY_METER)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionSecurity (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.SECURITY)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionTimers (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.TIMERS)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionOpenClose (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.OPEN_CLOSE)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionIntercom (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.INTERCOM)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionWeatherStation (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.WEATHER_STATION)
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  function roomHasFunctionAV (room) {
    return roomHasFunction(room, BAS_API.Room.FUNCTIONS.AV)
  }

  /**
   * @returns {boolean}
   */
  function hasMusic () {

    return _anyRoomHasFunction(
      BAS_API.Room.FUNCTIONS.AUDIO
    ) || (
      _anyRoomHasFunction(
        BAS_API.Room.FUNCTIONS.AV,
        {
          extraRoomCheck: roomHasAvAudio
        }
      )
    )

    /**
     * @param {BasRoom} room
     */
    function roomHasAvAudio (room) {
      return room && room.room && room.room.av && room.room.av.audio
    }
  }

  /**
   * @returns {boolean}
   */
  function hasVideo () {

    return _anyRoomHasFunction(BAS_API.Room.FUNCTIONS.AV, {
      extraRoomCheck: roomHasAvVideo
    })

    /**
     * @param {BasRoom} room
     */
    function roomHasAvVideo (room) {
      return room && room.room && room.room.av && room.room.av.video
    }
  }

  /**
   * @returns {boolean}
   */
  function hasThermostat () {

    return _anyRoomHasFunction(
      BAS_API.Room.FUNCTIONS.THERMOSTAT
    )
  }

  /**
   * @returns {boolean}
   */
  function hasCameras () {

    // Check areas because Camera's can be assigned to floors, root, ...
    return _anyRoomHasFunction(BAS_API.Room.FUNCTIONS.SECURITY)
  }

  /**
   * @param {string[]} [excludeRooms]
   * @returns {boolean}
   */
  function hasIntercomRooms (excludeRooms) {

    return _anyRoomHasFunction(
      BAS_API.Room.FUNCTIONS.INTERCOM,
      {
        excludeRooms: excludeRooms
      }
    )
  }

  /**
   * @param {string[]} [excludeRooms]
   * @returns {boolean}
   */
  function hasEnergyRooms (excludeRooms) {

    return _anyRoomHasFunction(
      BAS_API.Room.FUNCTIONS.ENERGY_METER,
      {
        excludeRooms: excludeRooms
      }
    )
  }

  /**
   * @returns {?BasRoom}
   */
  function getHome () {

    return getRoomForId(rooms.root)
  }

  /**
   * Returns the Room instance that corresponds with zone id
   *
   * @param {string} id
   * @returns {?BasRoom}
   */
  function getRoomForId (id) {

    var room

    if (id) {

      room = rooms.rooms[id]

      return (room && room.isRoom) ? room : null
    }

    return null
  }

  /**
   * Returns the first room instance
   *
   * @returns {?BasRoom}
   */
  function getFirstRoom () {

    var keys = Object.keys(rooms.rooms)

    return keys.length > 0 ? rooms.rooms[keys[0]] : null
  }

  /**
   * Building for the given room
   *
   * @param {BasRoom} room
   * @returns {(BasRoom|string|null)}
   */
  function getRoomBuilding (room) {

    var _room

    if (room) {

      _room = rooms.rooms[room.buildingId]

      if (_room) return _room
      if (room.building) return room.building
    }

    return null
  }

  /**
   * Floor for the given room
   *
   * @param {BasRoom} room
   * @returns {(BasRoom|string|null)}
   */
  function getRoomFloor (room) {

    var _room

    if (room) {

      _room = rooms.rooms[room.floorId]

      if (_room) return _room
      if (room.floor) return room.floor
    }

    return null
  }

  /**
   * Generate an ID based on parent floor and building
   *
   * @param {BasRoom} room
   * @returns {string}
   */
  function getFloorBuildingId (room) {

    var result, building, floor

    result = ''

    if (room) {

      building = getRoomBuilding(room)

      if (building) {

        result += 'building:' +
          (BasUtil.isObject(building) ? building.id : building)
      }

      floor = getRoomFloor(room)

      if (floor) {

        result += 'floor:' +
          (BasUtil.isObject(floor) ? floor.id : floor)
      }
    }

    return result
  }

  /**
   * @param {BasRoom} room
   * @returns {string}
   */
  function getRoomBuildingPartKey (room) {

    var _room

    _room = getRoomBuilding(room)

    if (_room) {

      if (BasUtil.isObject(_room)) return _room.basTitle.getPartKey()
      return _room
    }

    return ''
  }

  /**
   * @param {BasRoom} room
   * @returns {string}
   */
  function getRoomFloorPartKey (room) {

    var _room

    _room = getRoomFloor(room)

    if (_room) {

      if (BasUtil.isObject(_room)) return _room.basTitle.getPartKey()
      return _room
    }

    return ''
  }

  /**
   * @param cameraUuid
   * @returns {?BasCamera}
   */
  function getCameraByUuid (cameraUuid) {

    var keys, length, i, uuid, room, camera

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (room && room.cameras && room.cameras.getCameraById) {

          camera = room.cameras.getCameraById(cameraUuid)
          if (camera) return camera
        }
      }
    }

    return null
  }

  /**
   * @param {string} doorPhoneGatewayUuid
   * @returns {?BasDoorPhoneGateway}
   */
  function getDoorPhoneGatewayByUuid (doorPhoneGatewayUuid) {

    var keys, length, i, uuid, room, gateway

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (
          room &&
          room.doorPhoneGateways &&
          room.doorPhoneGateways.getDoorPhoneGateway
        ) {

          gateway = room.doorPhoneGateways
            .getDoorPhoneGateway(doorPhoneGatewayUuid)

          if (gateway) return gateway
        }
      }
    }

    return null
  }

  /**
   * @returns {(null|(string[]))}
   */
  function getEnergyRooms () {

    var result, keys, length, i, uuid, room, hasRooms

    result = []

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (
          room &&
          room.energy &&
          room.energy.uiHasEnergy &&
          room.energy.uiHasEnergy()
        ) {
          result.push(uuid)
          hasRooms = true
        }
      }
    }

    return hasRooms ? result : null
  }

  /**
   * @returns {?Object<string, string[]>}
   */
  function getEnergyRoomsPerBuilding () {

    var result, keys, length, i, uuid, room, hasRooms

    result = {}

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (
          room &&
          room.energy &&
          room.energy.uiHasEnergy &&
          room.energy.uiHasEnergy()
        ) {
          if (!Array.isArray(result[room.buildingId])) {
            result[room.buildingId] = []
          }
          result[room.buildingId].push(uuid)
          hasRooms = true
        }
      }
    }

    return hasRooms ? result : null
  }

  /**
   * @param {?Room} room
   * @returns {boolean}
   */
  function hasAudio (room) {

    var value

    if (BasUtil.isObject(room)) {

      value = room.functions

      if (BasUtil.isObject(value)) {

        // Check "Audio" function

        if (value[BAS_API.Room.FUNCTIONS.AUDIO]) return true

        // Check "AV" function and av.audio

        if (value[BAS_API.Room.FUNCTIONS.AV]) {

          value = room.av

          if (BasUtil.isObject(value) &&
            BasUtil.isObject(value.audio)) {

            return true
          }
        }
      }
    }

    return false
  }

  /**
   * Returns the name of a room including it's floor if available, in the
   * format 'Floorname - Roommname' in Sonos setups, since a Sonos room is 1:1
   * with sources
   *
   * Returns the source name of rooms current source in Asano setups
   *
   * @param {BasRoom} room
   * @returns {string}
   */
  function getUniqueSourceIdentifier (room) {

    var floor, actualRoom, source

    if (BasUtil.isObject(room)) {

      source = room.getMusicBasSource()

      if (room.music) {

        if (room.music.type === BAS_ROOM.MUSIC_T_SONOS) {

          actualRoom = room.isSourceGroup()
            ? getRoomWithDefaultSource(room.sourceUuid)
            : room

          if (actualRoom) {

            floor = getRoomForId(actualRoom.floorId)

            return BasUtil.isObject(floor)
              ? floor.uiTitle + ' - ' + room.uiTitle
              : room.uiTitle
          }
        }

        if (
          room.music.type === BAS_ROOM.MUSIC_T_SONOS ||
          room.music.type === BAS_ROOM.MUSIC_T_ASANO
        ) {

          source = room.getMusicBasSource()

          if (source) {

            return source.name
          }
        }
      }
    }

    return ''
  }

  /**
   * @param {string} sourceUuid
   * @returns {?BasRoom}
   */
  function getRoomWithDefaultSource (sourceUuid) {

    var keys, length, i, uuid, room

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (
          room &&
          room.music &&
          room.music.getDefaultSource &&
          room.music.getDefaultSource() === sourceUuid
        ) {

          return room
        }
      }
    }

    return null
  }

  /**
   * @param {string} sourceUuid
   * @returns {?BasRoom}
   */
  function getFirstRoomWithSource (sourceUuid) {

    var keys, length, i, uuid, room

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        room = rooms.rooms[uuid]

        if (
          room &&
          room.variant !== BAS_ROOM.VAR_AV_GROUP &&
          room.music &&
          room.music.basSource &&
          room.music.basSource.uuid === sourceUuid
        ) {

          return room
        }
      }
    }

    return null
  }

  /**
   * @private
   * @param {string} func
   * @param {?Object} [options]
   * @param {?string[]} [options.excludeRooms]
   * @param {?Function} [options.extraRoomCheck]
   * @returns {boolean}
   */
  function _anyRoomHasFunction (func, options) {

    var keys, length, i, uuid, _hasExclusion

    _hasExclusion = options && Array.isArray(options.excludeRooms)

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        // Check excluded rooms, skip this iteration excluded
        if (_hasExclusion) {

          if (options.excludeRooms.indexOf(uuid) > -1) {

            // Continue searching
            continue
          }
        }

        // Extra room check, skip this iteration if check exists and fails
        if (options && BasUtil.isFunction(options.extraRoomCheck)) {

          if (!options.extraRoomCheck(rooms.rooms[uuid])) {

            continue
          }
        }

        // Actual room function check
        if (roomHasFunction(rooms.rooms[uuid], func)) {

          return true
        }
      }
    }

    return false
  }
}
