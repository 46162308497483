'use strict'

/* global Hammer */

angular
  .module('basalteApp')
  .directive('basButtonInput', basButtonInput)

function basButtonInput () {

  // TODO Replace link with controller and inject $window to access Hammer

  return {
    restrict: 'A',
    scope: {
      click: '&',
      start: '&',
      end: '&'
    },
    link: link
  }

  function link ($scope, $element) {

    var _hmElement = new Hammer.Manager($element[0])

    var _recTap = new Hammer.Tap()
    var _recPan = new Hammer.Pan()
    var _recPress = new Hammer.Press({
      time: 250
    })

    $scope.$on('$destroy', onDestroy)

    _hmElement.add(_recTap)
    _hmElement.add(_recPress)
    _hmElement.add(_recPan)

    _hmElement.on('tap', onClick)
    _hmElement.on('press', onStart)
    _hmElement.on('pressup', onEnd)
    _hmElement.on('panend', onEnd)

    function onClick () {

      if (typeof $scope.click === 'function') $scope.click()
      $scope.$applyAsync()
    }

    function onStart () {

      if (typeof $scope.start === 'function') $scope.start()
      $scope.$applyAsync()
    }

    function onEnd () {

      if (typeof $scope.end === 'function') $scope.end()
      $scope.$applyAsync()
    }

    function onDestroy () {

      _hmElement.destroy()
    }
  }
}
