'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('loginModalCtrl', [
    '$scope',
    '$rootScope',
    'BAS_API',
    'BAS_MODAL',
    'BAS_SPLASH',
    'SourcesHelper',
    'playerId',
    'close',
    loginModalCtrl
  ])

/**
 * @param $scope
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {SourcesHelper} SourcesHelper
 * @param playerId
 * @param close
 */
function loginModalCtrl (
  $scope,
  $rootScope,
  BAS_API,
  BAS_MODAL,
  BAS_SPLASH,
  SourcesHelper,
  playerId,
  close
) {
  var modal = this

  var _listeners = []

  var K_STATUS = 'status'
  var CSS_SHOW_SPINNER = 'bas-modal-spinner-show'

  modal.css = {}
  modal.css[CSS_SHOW_SPINNER] = false

  modal.title = 'tidal'
  modal.subtitle = 'sign_in'
  modal.username = ''
  modal.password = ''
  modal.message = ''

  /**
   * @type {BAS_MODAL}
   */
  modal.BAS_MODAL = BAS_MODAL

  modal.close = closeModal

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)
  }

  function closeModal (answer) {

    var basSource

    if (answer === BAS_MODAL.C_YES) {

      basSource = SourcesHelper.getPlayer(playerId)

      if (
        basSource &&
        basSource.source &&
        basSource.source.tidal &&
        basSource.source.tidal.login
      ) {

        basSource.source.tidal.login(modal.username, modal.password)
          .then(onSuccess, onFail)

        modal.css[CSS_SHOW_SPINNER] = true
      }

    } else {

      close()
    }

    function onSuccess (result) {

      if (checkLoginResult(result)) {

        close()

      } else {

        // Stop spinning
        modal.css[CSS_SHOW_SPINNER] = false
        modal.message = 'wrong_username_and_or_password'

        $scope.$applyAsync()
      }
    }

    function onFail (error) {

      // Stop spinning
      modal.css[CSS_SHOW_SPINNER] = false

      // Set message
      modal.message = (
        error === BAS_API.Tidal.ERR_INVALID_RESPONSE ||
        error === BAS_API.CONSTANTS.ERR_TIMEOUT
      )
        ? 'something_went_wrong'
        : 'wrong_username_and_or_password'

      $scope.$applyAsync()
    }
  }

  /**
   * @param {Object} result
   * @returns {boolean}
   */
  function checkLoginResult (result) {
    return !BasUtil.safeHasOwnProperty(result, K_STATUS)
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
