'use strict'

angular
  .module('basalteApp')
  .directive('deviceAdelanteSip', deviceAdelanteSip)

function deviceAdelanteSip () {

  return {
    restrict: 'A',
    template: `
      <div class="bbs-ellie">
         <div class="bbsi bbsi-no-top-margin bbsi-small-font bbsi-flex" bas-click="adelanteSip.toggleDebugMode()">
           <div class="bbsi-name ellipsis-overflow" data-translate="status"></div>
           <div class="bbsi-value" ng-class="{'bbsi-value--success': !state.hasError, 'bbsi-value--danger': adelanteSip.state.globalState.hasError}" ng-bind="adelanteSip.state.globalState.status"></div>
         </div>

         <div ng-if="adelanteSip.debugMode">
          <div class="bbsi bbsi-small-font bbsi-flex">
             <div class="bbsi-name ellipsis-overflow" data-translate="domain"></div>
             <div class="bbsi-value" ng-bind="adelanteSip.state.globalState.domain"></div>
          </div>
        
          <div class="bbsi bbsi-small-font bbsi-flex bbsi-heading">
             <div class="bbsi-name ellipsis-overflow" data-translate="alt_user"></div>
             <div class="bbsi-value" data-translate="status"></div>
          </div>

           <div ng-repeat="state in adelanteSip.state.states track by $index">
             <div class="bbsi bbsi-small-font bbsi-flex">
               <div class="bbsi-name ellipsis-overflow" ng-bind="state.username"></div>
               <div class="bbsi-value" ng-class="{'bbsi-value--success': !state.hasError, 'bbsi-value--danger': state.hasError}" ng-bind="state.state"></div>
             </div>
           </div>
        </div>
      </div>
    `,
    controller: [
      '$scope',
      'BAS_SIP',
      controller
    ],
    controllerAs: 'adelanteSip'
  }

  /**
   * @param $scope
   * @param {BAS_SIP} BAS_SIP
   */
  function controller (
    $scope,
    BAS_SIP
  ) {
    var adelanteSip = this

    let timerId

    adelanteSip.debugMode = false
    adelanteSip.toggleDebugMode = toggleDebugMode

    /**
     * @type {TBasAdelanteState}
     */
    adelanteSip.state = BAS_SIP.ADELANTE_STATE

    adelanteSip.showExtra = false

    init()

    function init () {

      $scope.$on('$destroy', destroy)
    }

    function clearTimer () {
      if (timerId) {
        clearTimeout(timerId)
        timerId = undefined
      }
    }

    function turnOffDebugMode () {
      adelanteSip.debugMode = false

      clearTimer()
      $scope.$applyAsync()
    }

    function toggleDebugMode () {
      clearTimer()

      if (!adelanteSip.debugMode) {
        timerId = setTimeout(turnOffDebugMode, 10000)
      }

      adelanteSip.debugMode = !adelanteSip.debugMode
      $scope.$applyAsync()
    }

    function destroy () {
      clearTimer()
    }
  }
}
