'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var Device = require('./device')

/**
 * @constructor
 * @param {TDevice} device
 * @param {BasCore} basCore
 * @since 2.7.0
 */
function OpenCloseDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._state = BasUtil.isObject(device[P.STATE])
    ? device[P.STATE]
    : {}
}

OpenCloseDevice.prototype = Object.create(Device.prototype)
OpenCloseDevice.prototype.constructor = OpenCloseDevice

/**
 * Open Close types
 * From proto.be.basalte.config.OpenCloseDevice.OpenCloseType
 *
 * @readonly
 * @enum {number}
 */
OpenCloseDevice.TYPE = {
  GENERIC: 0,
  DOOR: 1,
  WINDOW: 2,
  GATE: 3
}

/**
 * Reverse enum
 *
 * @readonly
 * @enum {string}
 */
OpenCloseDevice.TYPE_R = BasUtil.switchObjectKeyValue(OpenCloseDevice.TYPE)

/**
 * @constant {string}
 */
OpenCloseDevice.EVT_STATE = 'evtOpenCloseState'

/**
 * @constant {string}
 */
OpenCloseDevice.C_OPEN_CLOSE = P.OPEN_CLOSE

/**
 * @constant {string}
 */
OpenCloseDevice.C_TOGGLE = P.TOGGLE

/**
 * @constant {string}
 */
OpenCloseDevice.C_TRIGGER = P.TRIGGER

/**
 * @constant {string}
 */
OpenCloseDevice.C_OPEN = P.OPEN

/**
 * @constant {string}
 */
OpenCloseDevice.C_STOP = P.STOP

/**
 * @constant {string}
 */
OpenCloseDevice.C_CLOSE = P.CLOSE

/**
 * Returns state of "openClose" for "open".
 * Check capabilities (openClose) to see if this property is supported.
 *
 * @name OpenCloseDevice#opened
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(OpenCloseDevice.prototype, 'opened', {
  get: function () {

    return this._state[OpenCloseDevice.C_OPEN_CLOSE] === P.OPEN
  }
})

/**
 * Returns state of "openClose" for "close".
 * Check capabilities (openClose) to see if this property is supported.
 *
 * @name OpenCloseDevice#closed
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(OpenCloseDevice.prototype, 'closed', {
  get: function () {

    return this._state[OpenCloseDevice.C_OPEN_CLOSE] === P.CLOSE
  }
})

/**
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
OpenCloseDevice.prototype.parse = function (msg, options) {

  var valid, emit

  emit = true
  valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    if (BasUtil.isObject(msg[P.STATE])) {

      if (!BasUtil.isEqualObject(this._state, msg[P.STATE])) {

        BasUtil.mergeObjectsDeep(this._state, msg[P.STATE])

        if (emit) this.emit(OpenCloseDevice.EVT_STATE)
      }
    }
  }

  return valid
}

/**
 * Sending toggle action to server.
 * Active indicates if the toggle is pressed.
 *
 * @param {boolean} active
 */
OpenCloseDevice.prototype.toggle = function (active) {

  this.performAction(OpenCloseDevice.C_TOGGLE, active)
}

/**
 * Sending trigger action to server.
 * Active indicates if the trigger is pressed.
 *
 * @param {boolean} active
 */
OpenCloseDevice.prototype.trigger = function (active) {

  this.performAction(OpenCloseDevice.C_TRIGGER, active)
}

OpenCloseDevice.prototype.open = function () {

  this.performAction(OpenCloseDevice.C_OPEN)
}

OpenCloseDevice.prototype.close = function () {

  this.performAction(OpenCloseDevice.C_CLOSE)
}

OpenCloseDevice.prototype.stop = function () {

  this.performAction(OpenCloseDevice.C_STOP)
}

module.exports = OpenCloseDevice
