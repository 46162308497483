'use strict'

angular
  .module('basalteApp')
  .constant('BAS_DEVICE', {
    T_VERSION_SHORT: 'short',
    T_VERSION_LONG: 'long',
    EVT_SERVER_DEVICE_UPDATED: 'evtBasServerUpdated',
    EVT_SERVER_DEVICE_UPDATE_STATE: 'evtBasServerUpdateState'
  })
