'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('settingsCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'SETTINGS_STATES',
    'UI_HELPER',
    'BAS_CURRENT_CORE',
    'BAS_CORE_CLIENT',
    'BAS_CONNECTED_DEVICES',
    'BAS_SOURCES',
    'BAS_CAMERAS',
    'BAS_ROOMS',
    'BAS_SIP',
    'BasState',
    'SettingsState',
    'UiHelper',
    'CurrentBasCore',
    'BasCoreClient',
    'BasUpdater',
    'BasCameras',
    'BasConnectedDevices',
    'BasAppDevice',
    'BasSettingsStateHelper',
    settingsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {UI_HELPER} UI_HELPER
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 * @param {BAS_CONNECTED_DEVICES} BAS_CONNECTED_DEVICES
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_CAMERAS} BAS_CAMERAS
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SIP} BAS_SIP
 * @param {BasState} BasState
 * @param {SettingsState} SettingsState
 * @param {UiHelper} UiHelper
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasCoreClient} BasCoreClient
 * @param {BasUpdater} BasUpdater
 * @param {BasCameras} BasCameras
 * @param {BasConnectedDevices} BasConnectedDevices
 * @param {BasAppDevice} BasAppDevice
 * @param {BasSettingsStateHelper} BasSettingsStateHelper
 */
function settingsCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  SETTINGS_STATES,
  UI_HELPER,
  BAS_CURRENT_CORE,
  BAS_CORE_CLIENT,
  BAS_CONNECTED_DEVICES,
  BAS_SOURCES,
  BAS_CAMERAS,
  BAS_ROOMS,
  BAS_SIP,
  BasState,
  SettingsState,
  UiHelper,
  CurrentBasCore,
  BasCoreClient,
  BasUpdater,
  BasCameras,
  BasConnectedDevices,
  BasAppDevice,
  BasSettingsStateHelper
) {
  var settings = this

  var _listeners = []

  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  /**
   * @type {STATES}
   */
  settings.STATES = STATES

  /**
   * @type {SETTINGS_STATES}
   */
  settings.SETTINGS_STATES = SETTINGS_STATES

  /**
   * @type {BAS_SOURCES}
   */
  settings.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {BAS_ROOMS}
   */
  settings.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {TBasSettingsStateObj}
   */
  settings.basSettingsState = SettingsState.get()

  /**
   * @type {TBasStateObj}
   */
  settings.basState = BasState.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  settings.currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasCoreClientState}
   */
  settings.basCoreClient = BasCoreClient.get()

  /**
   * @type {TBasSipState}
   */
  settings.basSip = BAS_SIP.STATE

  /**
   * @type {TBasAdelanteState}
   */
  settings.basSipAdelante = BAS_SIP.ADELANTE_STATE

  /**
   * @type {TBasSipState}
   */
  settings.basUpdater = BasUpdater.get()

  /**
   * @type {TCamerasState}
   */
  settings.camerasState = BasCameras.get()

  /**
   * @type {TBasConnectedDevicesState}
   */
  settings.connectedDevices = BasConnectedDevices.get()

  /**
   * @type {BasAppDeviceState}
   */
  settings.basAppDeviceState = BasAppDevice.get()

  /**
   * @type {boolean}
   */
  settings.isCoreClient = BasAppDevice.isCoreClient()

  settings.selectItem = selectItem

  init()

  function init () {

    $scope.$on('$destroy', onDestroy)

    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      onServerConnected
    ))
    _listeners.push($rootScope.$on(
      UI_HELPER.EVT_RESIZE,
      onUiSize
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _onMusicConfig
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_SOURCES.EVT_SOURCES_UPDATED,
      _onSourcesUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_CONNECTED_DEVICES.EVT_BAS_SERVERS_UPDATED,
      onServersUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_CAMERAS.EVT_CAMERAS_UPDATED,
      onCamerasSync
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_IS_ADMIN,
      onAdminSet
    ))
    _listeners.push($rootScope.$on(
      BAS_CORE_CLIENT.EVT_CORE_CLIENT_SUPPORTS_MUTE_AREA_ON_CALL,
      onSupportsMuteAreaOnCall
    ))

    checkServerStatus()
    updateServerStatus()
  }

  function onAdminSet () {

    _scopeSync()
  }

  function onCamerasSync () {

    _scopeSync()
  }

  function onUiSize () {

    checkScreenWidth()
  }

  /**
   *
   * @param {Object} _event
   * @param {BasCoreContainer} _basCoreContainer
   * @param {boolean} isConnected
   */
  function onServerConnected (
    _event,
    _basCoreContainer,
    isConnected
  ) {
    if (isConnected) {

      checkServerStatus()
    }
  }

  function _onMusicConfig () {

    checkServerStatus()
  }

  function _onRoomsUpdated () {

    _scopeSync()
  }

  function _onSourcesUpdated () {

    _scopeSync()
  }

  function onServersUpdated () {

    updateServerStatus()
  }

  function onSupportsMuteAreaOnCall () {

    _scopeSync()
  }

  function checkScreenWidth () {

    if (
      basUi.prop.wMedium &&
      $uiRouterGlobals.current.name === STATES.SETTINGS
    ) {
      BasState.go(STATES.SETTINGS_GENERAL)
    }
  }

  function checkServerStatus () {

    var _basServer

    if (CurrentBasCore.hasCore()) {

      _basServer = settings.currentBasCoreState.core.core.server

      if (_basServer) {

        _basServer.getStatus().then(_scopeSync)
      }
    }
  }

  function updateServerStatus () {

    var promises, i, length, promise

    if (CurrentBasCore.hasCore()) {

      promises = BasConnectedDevices.retrieveServerStatus()

      if (!Array.isArray(promises)) promises = [promises]

      length = promises.length
      for (i = 0; i < length; i++) {

        promise = promises[i]
        if (BasUtil.isObject(promise) &&
          BasUtil.isFunction(promise.then)) {

          promise.then(_scopeSync, _ignore)
        }
      }
    }

    function _ignore () {
      // Empty
    }
  }

  function selectItem (state) {

    BasState.go(BasSettingsStateHelper.getSettingsState(state))
  }

  function _scopeSync () {

    $scope.$applyAsync()
  }

  function onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
