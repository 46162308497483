'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('sourcesCtrl', [
    'STATES',
    'MEDIA_STATES',
    'BAS_ROOMS',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_MODAL',
    'BasAppDevice',
    'CurrentRoom',
    'SourcesHelper',
    'BasModal',
    'BasUtilities',
    'BasString',
    'BasMediaStateHelper',
    '$uiRouterGlobals',
    sourcesCtrl
  ])

/**
 * @param {STATES} STATES
 * @param {MEDIA_STATES} MEDIA_STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasAppDevice} BasAppDevice
 * @param {CurrentRoom} CurrentRoom
 * @param {SourcesHelper} SourcesHelper
 * @param {BasModal} BasModal
 * @param {BasUtilities} BasUtilities
 * @param BasString
 * @param {BasMediaStateHelper} BasMediaStateHelper
 * @param $uiRouterGlobals
 */
function sourcesCtrl (
  STATES,
  MEDIA_STATES,
  BAS_ROOMS,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_MODAL,
  BasAppDevice,
  CurrentRoom,
  SourcesHelper,
  BasModal,
  BasUtilities,
  BasString,
  BasMediaStateHelper,
  $uiRouterGlobals
) {
  var sources = this

  var allowSelect = false

  /**
   * @type {BasRooms}
   */
  sources.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BAS_SOURCES}
   */
  sources.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {BAS_SOURCE}
   */
  sources.BAS_SOURCE = BAS_SOURCE

  /**
   * @type {number}
   */
  sources.dpi = BasAppDevice.getDpi()

  /**
   * @type {TCurrentRoomState}
   */
  sources.currentRoom = CurrentRoom.get()

  /**
   * @type {string}
   */
  sources.mediaKey =
    BasUtil.startsWith($uiRouterGlobals.current.name, STATES.VIDEO)
      ? 'video'
      : 'music'

  sources.selectSource = selectSource
  sources.activatePairing = activatePairing

  BasUtilities.waitFrames(5)
    .then(afterFrames)

  function afterFrames () {

    allowSelect = true
  }

  /**
   * @param {string} uuid
   */
  function selectSource (uuid) {

    var room, roomMedia, basSource, _state

    if (allowSelect && BasUtil.isNEString(uuid)) {

      room = CurrentRoom.getRoom()
      roomMedia = room[sources.mediaKey]
      basSource = SourcesHelper.getBasSource(uuid)

      if (
        roomMedia &&
        basSource &&
        basSource.available &&
        BasUtil.isObject(basSource.source)
      ) {

        _state = BasMediaStateHelper.getHelper()

        // Assign source to zone
        roomMedia.setSource(basSource.getId())

        CurrentRoom.go(_state.get(MEDIA_STATES.PLAYER))
      }
    }
  }

  function activatePairing (source) {

    if (
      source &&
      (
        source.type === BAS_SOURCE.T_BLUETOOTH ||
        source.subType === BAS_SOURCE.ST_BLUETOOTH
      )
    ) {

      source.activatePairing()

      // Show confirmation modal
      BasModal.show(BAS_MODAL.T_BLUETOOTH_PAIRING, {
        subtitle: new BasString({ literal: source.name })
      }).then(onModal)
    }

    function onModal (modal) {

      // Set close Promise
      modal.close.then(onModalClose)
    }

    function onModalClose (result) {

      if (source && result === BAS_MODAL.C_NO) {

        source.disablePairing()
      }
    }
  }
}
