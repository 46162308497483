'use strict'

angular
  .module('basalteApp')
  .factory('BasDevice', [
    'BAS_API',
    basDeviceFactory
  ])

function basDeviceFactory (BAS_API) {

  /**
   * @constructor
   * @param {ConnectedDevice} device
   */
  function BasDevice (device) {

    this.uuid = ''
    this.typeStr = BasDevice._DEF_TYPE
    this.name = BasDevice._DEF_NAME
    this.mac = BasDevice._DEF_MAC
    this.version = BasDevice._DEF_VERSION

    this.css = {}
    this._resetCss()

    /**
     * @type {?(ConnectedDevice|ServerDevice)}
     */
    this.device = null

    this.parse(device)
  }

  /**
   * @private
   * @constant {string}
   */
  BasDevice._DEF_NAME = 'device'

  /**
   * @private
   * @constant {string}
   */
  BasDevice._DEF_TYPE = '-'

  /**
   * @private
   * @constant {string}
   */
  BasDevice._DEF_MAC = '-'

  /**
   * @private
   * @constant {string}
   */
  BasDevice._DEF_VERSION = '-'

  /**
   * @param {ConnectedDevice} device
   */
  BasDevice.prototype.parse = function (device) {

    var value

    if (device instanceof BAS_API.ConnectedDevice) {

      this.device = device

      this.uuid = device.uuid

      value = BAS_API.Device.getDeviceType(device.basType)
      this.typeStr = value || BasDevice._DEF_TYPE

      this.name = device.name
        ? device.name
        : BasDevice._DEF_NAME
      this.mac = device.macStr
        ? device.macStr
        : BasDevice._DEF_MAC
      this.version = device.version
        ? device.version.text
        : BasDevice._DEF_VERSION
    }
  }

  /**
   * @private
   */
  BasDevice.prototype._resetCss = function () {

    // Empty
  }

  BasDevice.prototype.destroy = function () {

    this.device = null
  }

  return BasDevice
}
