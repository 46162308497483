'use strict'

if (typeof Object.assign !== 'function') {

  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign (target, _varArgs) {
      'use strict'

      var result, i, nextSource, nextKey

      if (target === null || target === undefined) {

        throw new TypeError(
          'Cannot convert undefined or null to object'
        )
      }

      result = Object(target)

      for (i = 1; i < arguments.length; i++) {

        nextSource = arguments[i]

        if (nextSource !== null && nextSource !== undefined) {

          for (nextKey in nextSource) {

            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty
              .call(nextSource, nextKey)) {

              result[nextKey] = nextSource[nextKey]
            }
          }
        }
      }

      return result
    },
    writable: true,
    configurable: true
  })
}
