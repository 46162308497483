{
  "data": {
    "currently": {
      "apparentTemperature": 27.64,
      "cloudCover": 0.12,
      "dewPoint": 16.41,
      "humidity": 0.52,
      "icon": "clear-day",
      "ozone": 321.9,
      "precipIntensity": 0,
      "precipProbability": 0,
      "pressure": 1015.38,
      "summary": "Clear",
      "temperature": 20,
      "time": 1562747366,
      "uvIndex": 4,
      "visibility": 10.723,
      "windBearing": 74,
      "windGust": 4.93,
      "windSpeed": 3.5
    },
    "daily": {
      "data": [
        {
          "apparentTemperatureHigh": 29.18,
          "apparentTemperatureHighTime": 1562770800,
          "apparentTemperatureLow": 23.83,
          "apparentTemperatureLowTime": 1562814000,
          "apparentTemperatureMax": 29.18,
          "apparentTemperatureMaxTime": 1562770800,
          "apparentTemperatureMin": 19.96,
          "apparentTemperatureMinTime": 1562731200,
          "cloudCover": 0.13,
          "dewPoint": 18.13,
          "humidity": 0.66,
          "icon": "clear-day",
          "moonPhase": 0.3,
          "ozone": 319.3,
          "precipIntensity": 0.0015,
          "precipIntensityMax": 0.0083,
          "precipIntensityMaxTime": 1562760000,
          "precipProbability": 0.01,
          "precipType": "rain",
          "pressure": 1014.55,
          "summary": "Partly cloudy throughout the day.",
          "sunriseTime": 1562733524,
          "sunsetTime": 1562786566,
          "temperatureHigh": 23.28,
          "temperatureHighTime": 1562770800,
          "temperatureLow": 23.28,
          "temperatureLowTime": 1562814000,
          "temperatureMax": 27.83,
          "temperatureMaxTime": 1562770800,
          "temperatureMin": 19.96,
          "temperatureMinTime": 1562731200,
          "time": 1562709600,
          "uvIndex": 9,
          "uvIndexTime": 1562760000,
          "visibility": 13.229,
          "windBearing": 95,
          "windGust": 4.95,
          "windGustTime": 1562749200,
          "windSpeed": 2.76
        },
        {
          "apparentTemperatureHigh": 30.39,
          "apparentTemperatureHighTime": 1562850000,
          "apparentTemperatureLow": 24.53,
          "apparentTemperatureLowTime": 1562900400,
          "apparentTemperatureMax": 30.39,
          "apparentTemperatureMaxTime": 1562850000,
          "apparentTemperatureMin": 23.83,
          "apparentTemperatureMinTime": 1562814000,
          "cloudCover": 0.09,
          "dewPoint": 20.16,
          "humidity": 0.72,
          "icon": "cloudy",
          "moonPhase": 0.33,
          "ozone": 303.3,
          "precipIntensity": 0.0022,
          "precipIntensityMax": 0.0072,
          "precipIntensityMaxTime": 1562803200,
          "precipProbability": 0.01,
          "precipType": "rain",
          "pressure": 1014.41,
          "summary": "Clear throughout the day.",
          "sunriseTime": 1562819964,
          "sunsetTime": 1562872942,
          "temperatureHigh": 19,
          "temperatureHighTime": 1562850000,
          "temperatureLow": 23.97,
          "temperatureLowTime": 1562900400,
          "temperatureMax": 28.7,
          "temperatureMaxTime": 1562850000,
          "temperatureMin": 23.28,
          "temperatureMinTime": 1562814000,
          "time": 1562796000,
          "uvIndex": 10,
          "uvIndexTime": 1562846400,
          "visibility": 16.093,
          "windBearing": 138,
          "windGust": 5.64,
          "windGustTime": 1562868000,
          "windSpeed": 2.92
        },
        {
          "apparentTemperatureHigh": 30.89,
          "apparentTemperatureHighTime": 1562943600,
          "apparentTemperatureLow": 25.28,
          "apparentTemperatureLowTime": 1562986800,
          "apparentTemperatureMax": 30.89,
          "apparentTemperatureMaxTime": 1562943600,
          "apparentTemperatureMin": 24.53,
          "apparentTemperatureMinTime": 1562900400,
          "cloudCover": 0.05,
          "dewPoint": 20.32,
          "humidity": 0.69,
          "icon": "rain",
          "moonPhase": 0.37,
          "ozone": 298,
          "precipIntensity": 0.0006,
          "precipIntensityMax": 0.0051,
          "precipIntensityMaxTime": 1562882400,
          "precipProbability": 0.02,
          "precipType": "rain",
          "pressure": 1014.62,
          "summary": "Clear throughout the day.",
          "sunriseTime": 1562906404,
          "sunsetTime": 1562959317,
          "temperatureHigh": 18,
          "temperatureHighTime": 1562943600,
          "temperatureLow": 24.74,
          "temperatureLowTime": 1562986800,
          "temperatureMax": 29.07,
          "temperatureMaxTime": 1562943600,
          "temperatureMin": 23.97,
          "temperatureMinTime": 1562900400,
          "time": 1562882400,
          "uvIndex": 11,
          "uvIndexTime": 1562932800,
          "visibility": 16.093,
          "windBearing": 131,
          "windGust": 5.53,
          "windGustTime": 1562943600,
          "windSpeed": 3.31
        },
        {
          "apparentTemperatureHigh": 31.03,
          "apparentTemperatureHighTime": 1563030000,
          "apparentTemperatureLow": 25.3,
          "apparentTemperatureLowTime": 1563076800,
          "apparentTemperatureMax": 31.03,
          "apparentTemperatureMaxTime": 1563030000,
          "apparentTemperatureMin": 25.28,
          "apparentTemperatureMinTime": 1562986800,
          "cloudCover": 0.38,
          "dewPoint": 21,
          "humidity": 0.71,
          "icon": "partly-cloudy-day",
          "moonPhase": 0.4,
          "ozone": 302.6,
          "precipIntensity": 0.0032,
          "precipIntensityMax": 0.0336,
          "precipIntensityMaxTime": 1563055200,
          "precipProbability": 0.07,
          "precipType": "rain",
          "pressure": 1012.46,
          "summary": "Partly cloudy throughout the day.",
          "sunriseTime": 1562992845,
          "sunsetTime": 1563045690,
          "temperatureHigh": 22,
          "temperatureHighTime": 1563030000,
          "temperatureLow": 24.61,
          "temperatureLowTime": 1563076800,
          "temperatureMax": 28.7,
          "temperatureMaxTime": 1563030000,
          "temperatureMin": 24.74,
          "temperatureMinTime": 1562986800,
          "time": 1562968800,
          "uvIndex": 9,
          "uvIndexTime": 1563019200,
          "visibility": 16.093,
          "windBearing": 102,
          "windGust": 7.91,
          "windGustTime": 1563019200,
          "windSpeed": 3.64
        },
        {
          "apparentTemperatureHigh": 14.92,
          "apparentTemperatureHighTime": 1570280400,
          "apparentTemperatureLow": 4.8399999999999999,
          "apparentTemperatureLowTime": 1570338000,
          "apparentTemperatureMax": 14.92,
          "apparentTemperatureMaxTime": 1570280400,
          "apparentTemperatureMin": 6.7599999999999998,
          "apparentTemperatureMinTime": 1570312800,
          "cloudCover": 0.59999999999999998,
          "dewPoint": 10.130000000000001,
          "humidity": 0.92000000000000004,
          "icon": "rain",
          "moonPhase": 0.25,
          "ozone": 276.69999999999999,
          "precipIntensity": 0.032000000000000001,
          "precipIntensityMax": 0.15939999999999999,
          "precipIntensityMaxTime": 1570226400,
          "precipProbability": 0.46000000000000002,
          "precipType": "rain",
          "pressure": 1014.12,
          "summary": "Mostly cloudy throughout the day.",
          "sunriseTime": 1570254756,
          "sunsetTime": 1570295802,
          "temperatureHigh": 15.289999999999999,
          "temperatureHighTime": 1570280400,
          "temperatureLow": 6.5700000000000003,
          "temperatureLowTime": 1570338000,
          "temperatureMax": 15.289999999999999,
          "temperatureMaxTime": 1570280400,
          "temperatureMin": 8.3300000000000001,
          "temperatureMinTime": 1570312800,
          "time": 1570226400,
          "uvIndex": 3,
          "uvIndexTime": 1570276800,
          "visibility": 16.093,
          "windBearing": 9,
          "windGust": 9.4000000000000004,
          "windGustTime": 1570262400,
          "windSpeed": 3.9399999999999999
        },
        {
          "apparentTemperatureHigh": 14.01,
          "apparentTemperatureHighTime": 1570370400,
          "apparentTemperatureLow": 3.7400000000000002,
          "apparentTemperatureLowTime": 1570428000,
          "apparentTemperatureMax": 14.01,
          "apparentTemperatureMaxTime": 1570370400,
          "apparentTemperatureMin": 4.8399999999999999,
          "apparentTemperatureMinTime": 1570338000,
          "cloudCover": 0.84999999999999998,
          "dewPoint": 8.8699999999999992,
          "humidity": 0.92000000000000004,
          "icon": "rain",
          "moonPhase": 0.28999999999999998,
          "ozone": 282.60000000000002,
          "precipIntensity": 0.075600000000000001,
          "precipIntensityMax": 0.59950000000000003,
          "precipIntensityMaxTime": 1570374000,
          "precipProbability": 0.39000000000000001,
          "precipType": "rain",
          "pressure": 1018.24,
          "summary": "Overcast throughout the day.",
          "sunriseTime": 1570341253,
          "sunsetTime": 1570382069,
          "temperatureHigh": 14.380000000000001,
          "temperatureHighTime": 1570370400,
          "temperatureLow": 6.29,
          "temperatureLowTime": 1570428000,
          "temperatureMax": 14.380000000000001,
          "temperatureMaxTime": 1570370400,
          "temperatureMin": 6.5700000000000003,
          "temperatureMinTime": 1570338000,
          "time": 1570312800,
          "uvIndex": 2,
          "uvIndexTime": 1570363200,
          "visibility": 16.093,
          "windBearing": 121,
          "windGust": 10.41,
          "windGustTime": 1570399200,
          "windSpeed": 3.8900000000000001
        },
        {
          "apparentTemperatureHigh": 13.609999999999999,
          "apparentTemperatureHighTime": 1570453200,
          "apparentTemperatureLow": 5.2199999999999998,
          "apparentTemperatureLowTime": 1570510800,
          "apparentTemperatureMax": 13.609999999999999,
          "apparentTemperatureMaxTime": 1570453200,
          "apparentTemperatureMin": 3.7400000000000002,
          "apparentTemperatureMinTime": 1570428000,
          "cloudCover": 0.23999999999999999,
          "dewPoint": 8.4800000000000004,
          "humidity": 0.92000000000000004,
          "icon": "clear-day",
          "moonPhase": 0.32000000000000001,
          "ozone": 310.69999999999999,
          "precipIntensity": 0.024500000000000001,
          "precipIntensityMax": 0.14990000000000001,
          "precipIntensityMaxTime": 1570438800,
          "precipProbability": 0.28000000000000003,
          "precipType": "rain",
          "pressure": 1020.74,
          "summary": "Partly cloudy throughout the day.",
          "sunriseTime": 1570427749,
          "sunsetTime": 1570468336,
          "temperatureHigh": 13.98,
          "temperatureHighTime": 1570453200,
          "temperatureLow": 6.0099999999999998,
          "temperatureLowTime": 1570510800,
          "temperatureMax": 13.98,
          "temperatureMaxTime": 1570453200,
          "temperatureMin": 6.29,
          "temperatureMinTime": 1570428000,
          "time": 1570399200,
          "uvIndex": 3,
          "uvIndexTime": 1570449600,
          "visibility": 16.093,
          "windBearing": 4,
          "windGust": 10.460000000000001,
          "windGustTime": 1570402800,
          "windSpeed": 4.3099999999999996
        },
        {
          "apparentTemperatureHigh": 14.02,
          "apparentTemperatureHighTime": 1570543200,
          "apparentTemperatureLow": 4.96,
          "apparentTemperatureLowTime": 1570597200,
          "apparentTemperatureMax": 14.02,
          "apparentTemperatureMaxTime": 1570543200,
          "apparentTemperatureMin": 5.2199999999999998,
          "apparentTemperatureMinTime": 1570510800,
          "cloudCover": 0.45000000000000001,
          "dewPoint": 8.1600000000000001,
          "humidity": 0.91000000000000003,
          "icon": "partly-cloudy-day",
          "moonPhase": 0.34999999999999998,
          "ozone": 268.30000000000001,
          "precipIntensity": 0.0023,
          "precipIntensityMax": 0.0094000000000000004,
          "precipIntensityMaxTime": 1570543200,
          "precipProbability": 0.040000000000000001,
          "precipType": "rain",
          "pressure": 1025.6300000000001,
          "summary": "Mostly cloudy throughout the day.",
          "sunriseTime": 1570514247,
          "sunsetTime": 1570554604,
          "temperatureHigh": 14.390000000000001,
          "temperatureHighTime": 1570543200,
          "temperatureLow": 6.7800000000000002,
          "temperatureLowTime": 1570597200,
          "temperatureMax": 14.390000000000001,
          "temperatureMaxTime": 1570543200,
          "temperatureMin": 6.0099999999999998,
          "temperatureMinTime": 1570510800,
          "time": 1570485600,
          "uvIndex": 3,
          "uvIndexTime": 1570536000,
          "visibility": 16.093,
          "windBearing": 98,
          "windGust": 5.3200000000000003,
          "windGustTime": 1570572000,
          "windSpeed": 1.78
        }
      ],
      "icon": "clear-day",
      "summary": "No precipitation throughout the week, with high temperatures rising to 22°C."
    },
    "hourly": {
      "data": [
        {
          "apparentTemperature": 17.690000000000001,
          "cloudCover": 0.75,
          "dewPoint": 13.710000000000001,
          "humidity": 0.78000000000000003,
          "icon": "rain",
          "ozone": 276.10000000000002,
          "precipIntensity": 0.66269999999999996,
          "precipProbability": 0.55000000000000004,
          "precipType": "rain",
          "pressure": 999.83000000000004,
          "summary": "Possible Light Rain",
          "temperature": 17.690000000000001,
          "time": 1569942000,
          "uvIndex": 1,
          "visibility": 11.012,
          "windBearing": 235,
          "windGust": 9.5199999999999996,
          "windSpeed": 4.7599999999999998
        },
        {
          "apparentTemperature": 17.52,
          "cloudCover": 0.78000000000000003,
          "dewPoint": 13.41,
          "humidity": 0.77000000000000002,
          "icon": "rain",
          "ozone": 278.39999999999998,
          "precipIntensity": 0.66490000000000005,
          "precipProbability": 0.57999999999999996,
          "precipType": "rain",
          "pressure": 999.88,
          "summary": "Possible Light Rain",
          "temperature": 17.52,
          "time": 1569945600,
          "uvIndex": 0,
          "visibility": 12.394,
          "windBearing": 232,
          "windGust": 9.2200000000000006,
          "windSpeed": 3.9900000000000002
        },
        {
          "apparentTemperature": 16.859999999999999,
          "cloudCover": 0.91000000000000003,
          "dewPoint": 13.66,
          "humidity": 0.81000000000000005,
          "icon": "rain",
          "ozone": 281.69999999999999,
          "precipIntensity": 0.22189999999999999,
          "precipProbability": 0.44,
          "precipType": "rain",
          "pressure": 999.95000000000005,
          "summary": "Possible Drizzle",
          "temperature": 16.859999999999999,
          "time": 1569949200,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 230,
          "windGust": 8.9399999999999995,
          "windSpeed": 3.4700000000000002
        },
        {
          "apparentTemperature": 16.109999999999999,
          "cloudCover": 1,
          "dewPoint": 13.84,
          "humidity": 0.85999999999999999,
          "icon": "rain",
          "ozone": 286,
          "precipIntensity": 0.92169999999999996,
          "precipProbability": 0.62,
          "precipType": "rain",
          "pressure": 1000.2,
          "summary": "Possible Light Rain",
          "temperature": 16.109999999999999,
          "time": 1569952800,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 241,
          "windGust": 8.9100000000000001,
          "windSpeed": 3.2200000000000002
        },
        {
          "apparentTemperature": 15.58,
          "cloudCover": 0.94999999999999996,
          "dewPoint": 13.69,
          "humidity": 0.89000000000000001,
          "icon": "rain",
          "ozone": 292.19999999999999,
          "precipIntensity": 0.92579999999999996,
          "precipProbability": 0.62,
          "precipType": "rain",
          "pressure": 1000.3200000000001,
          "summary": "Possible Light Rain",
          "temperature": 15.58,
          "time": 1569956400,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 247,
          "windGust": 9.4900000000000002,
          "windSpeed": 3.1099999999999999
        },
        {
          "apparentTemperature": 15.050000000000001,
          "cloudCover": 0.88,
          "dewPoint": 13.51,
          "humidity": 0.91000000000000003,
          "icon": "rain",
          "ozone": 299.30000000000001,
          "precipIntensity": 0.67659999999999998,
          "precipProbability": 0.59999999999999998,
          "precipType": "rain",
          "pressure": 1000.64,
          "summary": "Possible Light Rain",
          "temperature": 15.050000000000001,
          "time": 1569960000,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 262,
          "windGust": 10.34,
          "windSpeed": 3.3999999999999999
        },
        {
          "apparentTemperature": 14.35,
          "cloudCover": 0.76000000000000001,
          "dewPoint": 13.369999999999999,
          "humidity": 0.93999999999999995,
          "icon": "rain",
          "ozone": 304.19999999999999,
          "precipIntensity": 0.32650000000000001,
          "precipProbability": 0.5,
          "precipType": "rain",
          "pressure": 1001.1900000000001,
          "summary": "Possible Drizzle",
          "temperature": 14.35,
          "time": 1569963600,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 243,
          "windGust": 10.69,
          "windSpeed": 4.3499999999999996
        },
        {
          "apparentTemperature": 13.880000000000001,
          "cloudCover": 0.73999999999999999,
          "dewPoint": 13.289999999999999,
          "humidity": 0.95999999999999996,
          "icon": "rain",
          "ozone": 305.10000000000002,
          "precipIntensity": 0.29380000000000001,
          "precipProbability": 0.5,
          "precipType": "rain",
          "pressure": 1001.92,
          "summary": "Possible Drizzle",
          "temperature": 13.880000000000001,
          "time": 1569967200,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 215,
          "windGust": 9.9299999999999997,
          "windSpeed": 4.2699999999999996
        },
        {
          "apparentTemperature": 13.58,
          "cloudCover": 0.71999999999999997,
          "dewPoint": 13.44,
          "humidity": 0.98999999999999999,
          "icon": "rain",
          "ozone": 303.69999999999999,
          "precipIntensity": 0.2034,
          "precipProbability": 0.41999999999999998,
          "precipType": "rain",
          "pressure": 1002.63,
          "summary": "Possible Drizzle",
          "temperature": 13.58,
          "time": 1569970800,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 317,
          "windGust": 8.6699999999999999,
          "windSpeed": 3.9100000000000001
        },
        {
          "apparentTemperature": 13.18,
          "cloudCover": 0.71999999999999997,
          "dewPoint": 13.18,
          "humidity": 1,
          "icon": "rain",
          "ozone": 302.19999999999999,
          "precipIntensity": 0.24399999999999999,
          "precipProbability": 0.44,
          "precipType": "rain",
          "pressure": 1003.47,
          "summary": "Possible Drizzle",
          "temperature": 13.18,
          "time": 1569974400,
          "uvIndex": 0,
          "visibility": 15.528,
          "windBearing": 280,
          "windGust": 7.9699999999999998,
          "windSpeed": 3.9399999999999999
        },
        {
          "apparentTemperature": 12.59,
          "cloudCover": 0.79000000000000004,
          "dewPoint": 12.59,
          "humidity": 1,
          "icon": "rain",
          "ozone": 301.19999999999999,
          "precipIntensity": 0.24129999999999999,
          "precipProbability": 0.44,
          "precipType": "rain",
          "pressure": 1004.35,
          "summary": "Possible Drizzle",
          "temperature": 12.59,
          "time": 1569978000,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 245,
          "windGust": 8.5899999999999999,
          "windSpeed": 4.3099999999999996
        },
        {
          "apparentTemperature": 11.890000000000001,
          "cloudCover": 0.85999999999999999,
          "dewPoint": 11.890000000000001,
          "humidity": 1,
          "icon": "rain",
          "ozone": 300.10000000000002,
          "precipIntensity": 0.26379999999999998,
          "precipProbability": 0.47999999999999998,
          "precipType": "rain",
          "pressure": 1005.4400000000001,
          "summary": "Possible Drizzle",
          "temperature": 11.890000000000001,
          "time": 1569981600,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 34,
          "windGust": 9.7699999999999996,
          "windSpeed": 4.6600000000000001
        },
        {
          "apparentTemperature": 11.199999999999999,
          "cloudCover": 0.91000000000000003,
          "dewPoint": 10.98,
          "humidity": 0.98999999999999999,
          "icon": "rain",
          "ozone": 299.10000000000002,
          "precipIntensity": 0.33839999999999998,
          "precipProbability": 0.51000000000000001,
          "precipType": "rain",
          "pressure": 1006.48,
          "summary": "Possible Drizzle",
          "temperature": 11.199999999999999,
          "time": 1569985200,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 17,
          "windGust": 10.390000000000001,
          "windSpeed": 4.5199999999999996
        },
        {
          "apparentTemperature": 10.390000000000001,
          "cloudCover": 0.67000000000000004,
          "dewPoint": 10.18,
          "humidity": 0.98999999999999999,
          "icon": "partly-cloudy-night",
          "ozone": 298.10000000000002,
          "precipIntensity": 0.21629999999999999,
          "precipProbability": 0.35999999999999999,
          "precipType": "rain",
          "pressure": 1007.63,
          "summary": "Mostly Cloudy",
          "temperature": 10.390000000000001,
          "time": 1569988800,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 9,
          "windGust": 9.7699999999999996,
          "windSpeed": 3.9700000000000002
        },
        {
          "apparentTemperature": 8.0500000000000007,
          "cloudCover": 0.81999999999999995,
          "dewPoint": 9.6500000000000004,
          "humidity": 0.98999999999999999,
          "icon": "partly-cloudy-night",
          "ozone": 297.19999999999999,
          "precipIntensity": 0.082100000000000006,
          "precipProbability": 0.20000000000000001,
          "precipType": "rain",
          "pressure": 1008.64,
          "summary": "Mostly Cloudy",
          "temperature": 9.8000000000000007,
          "time": 1569992400,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 2,
          "windGust": 8.5899999999999999,
          "windSpeed": 3.4100000000000001
        },
        {
          "apparentTemperature": 7.7300000000000004,
          "cloudCover": 0.67000000000000004,
          "dewPoint": 9.25,
          "humidity": 0.98999999999999999,
          "icon": "partly-cloudy-day",
          "ozone": 296.30000000000001,
          "precipIntensity": 0.056599999999999998,
          "precipProbability": 0.12,
          "precipType": "rain",
          "pressure": 1009.84,
          "summary": "Mostly Cloudy",
          "temperature": 9.4199999999999999,
          "time": 1569996000,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 358,
          "windGust": 7.7400000000000002,
          "windSpeed": 3.1400000000000001
        },
        {
          "apparentTemperature": 8.0800000000000001,
          "cloudCover": 0.65000000000000002,
          "dewPoint": 9.4299999999999997,
          "humidity": 0.97999999999999998,
          "icon": "partly-cloudy-day",
          "ozone": 296,
          "precipIntensity": 0.034299999999999997,
          "precipProbability": 0.10000000000000001,
          "precipType": "rain",
          "pressure": 1010.91,
          "summary": "Mostly Cloudy",
          "temperature": 9.7799999999999994,
          "time": 1569999600,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 352,
          "windGust": 7.5499999999999998,
          "windSpeed": 3.29
        },
        {
          "apparentTemperature": 10.859999999999999,
          "cloudCover": 0.71999999999999997,
          "dewPoint": 9.5,
          "humidity": 0.91000000000000003,
          "icon": "partly-cloudy-day",
          "ozone": 295.69999999999999,
          "precipIntensity": 0.026700000000000002,
          "precipProbability": 0.080000000000000002,
          "precipType": "rain",
          "pressure": 1012.02,
          "summary": "Mostly Cloudy",
          "temperature": 10.859999999999999,
          "time": 1570003200,
          "uvIndex": 1,
          "visibility": 16.093,
          "windBearing": 348,
          "windGust": 7.7000000000000002,
          "windSpeed": 4.1699999999999999
        },
        {
          "apparentTemperature": 11.85,
          "cloudCover": 0.68999999999999995,
          "dewPoint": 9.2100000000000009,
          "humidity": 0.83999999999999997,
          "icon": "partly-cloudy-day",
          "ozone": 295.39999999999998,
          "precipIntensity": 0.035499999999999997,
          "precipProbability": 0.059999999999999998,
          "precipType": "rain",
          "pressure": 1013.1,
          "summary": "Mostly Cloudy",
          "temperature": 11.85,
          "time": 1570006800,
          "uvIndex": 2,
          "visibility": 16.093,
          "windBearing": 341,
          "windGust": 7.9299999999999997,
          "windSpeed": 4.79
        },
        {
          "apparentTemperature": 12.74,
          "cloudCover": 0.67000000000000004,
          "dewPoint": 8.6300000000000008,
          "humidity": 0.76000000000000001,
          "icon": "partly-cloudy-day",
          "ozone": 294.69999999999999,
          "precipIntensity": 0.028799999999999999,
          "precipProbability": 0.059999999999999998,
          "precipType": "rain",
          "pressure": 1013.95,
          "summary": "Mostly Cloudy",
          "temperature": 12.74,
          "time": 1570010400,
          "uvIndex": 2,
          "visibility": 16.093,
          "windBearing": 333,
          "windGust": 8.1899999999999995,
          "windSpeed": 5.3200000000000003
        },
        {
          "apparentTemperature": 13.369999999999999,
          "cloudCover": 0.63,
          "dewPoint": 7.9199999999999999,
          "humidity": 0.69999999999999996,
          "icon": "partly-cloudy-day",
          "ozone": 293.69999999999999,
          "precipIntensity": 0.019,
          "precipProbability": 0.050000000000000003,
          "precipType": "rain",
          "pressure": 1014.67,
          "summary": "Mostly Cloudy",
          "temperature": 13.369999999999999,
          "time": 1570014000,
          "uvIndex": 3,
          "visibility": 16.093,
          "windBearing": 330,
          "windGust": 8.5600000000000005,
          "windSpeed": 6.1200000000000001
        },
        {
          "apparentTemperature": 13.52,
          "cloudCover": 0.68999999999999995,
          "dewPoint": 7.5300000000000002,
          "humidity": 0.67000000000000004,
          "icon": "partly-cloudy-day",
          "ozone": 293.39999999999998,
          "precipIntensity": 0.012200000000000001,
          "precipProbability": 0.050000000000000003,
          "precipType": "rain",
          "pressure": 1015.22,
          "summary": "Mostly Cloudy",
          "temperature": 13.52,
          "time": 1570017600,
          "uvIndex": 3,
          "visibility": 16.093,
          "windBearing": 329,
          "windGust": 9.0099999999999998,
          "windSpeed": 6.5899999999999999
        },
        {
          "apparentTemperature": 13.369999999999999,
          "cloudCover": 0.62,
          "dewPoint": 7.7800000000000002,
          "humidity": 0.68999999999999995,
          "icon": "partly-cloudy-day",
          "ozone": 294.30000000000001,
          "precipIntensity": 0.011299999999999999,
          "precipProbability": 0.059999999999999998,
          "precipType": "rain",
          "pressure": 1015.83,
          "summary": "Mostly Cloudy",
          "temperature": 13.369999999999999,
          "time": 1570021200,
          "uvIndex": 2,
          "visibility": 16.093,
          "windBearing": 327,
          "windGust": 9.7400000000000002,
          "windSpeed": 6.8499999999999996
        },
        {
          "apparentTemperature": 12.98,
          "cloudCover": 0.56999999999999995,
          "dewPoint": 8.2400000000000002,
          "humidity": 0.72999999999999998,
          "icon": "partly-cloudy-day",
          "ozone": 295.69999999999999,
          "precipIntensity": 0.0126,
          "precipProbability": 0.050000000000000003,
          "precipType": "rain",
          "pressure": 1016.4400000000001,
          "summary": "Mostly Cloudy",
          "temperature": 12.98,
          "time": 1570024800,
          "uvIndex": 2,
          "visibility": 16.093,
          "windBearing": 326,
          "windGust": 10.58,
          "windSpeed": 6.79
        },
        {
          "apparentTemperature": 12.619999999999999,
          "cloudCover": 0.42999999999999999,
          "dewPoint": 8.2899999999999991,
          "humidity": 0.75,
          "icon": "partly-cloudy-day",
          "ozone": 296.30000000000001,
          "precipIntensity": 0.014800000000000001,
          "precipProbability": 0.070000000000000007,
          "precipType": "rain",
          "pressure": 1017.13,
          "summary": "Partly Cloudy",
          "temperature": 12.619999999999999,
          "time": 1570028400,
          "uvIndex": 1,
          "visibility": 16.093,
          "windBearing": 324,
          "windGust": 11,
          "windSpeed": 6.3099999999999996
        },
        {
          "apparentTemperature": 12.380000000000001,
          "cloudCover": 0.39000000000000001,
          "dewPoint": 7.9299999999999997,
          "humidity": 0.73999999999999999,
          "icon": "partly-cloudy-day",
          "ozone": 295.5,
          "precipIntensity": 0.0183,
          "precipProbability": 0.059999999999999998,
          "precipType": "rain",
          "pressure": 1017.75,
          "summary": "Partly Cloudy",
          "temperature": 12.380000000000001,
          "time": 1570032000,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 320,
          "windGust": 10.73,
          "windSpeed": 5.5999999999999996
        },
        {
          "apparentTemperature": 11.51,
          "cloudCover": 0.29999999999999999,
          "dewPoint": 7.6100000000000003,
          "humidity": 0.77000000000000002,
          "icon": "partly-cloudy-day",
          "ozone": 294,
          "precipIntensity": 0.023900000000000001,
          "precipProbability": 0.050000000000000003,
          "precipType": "rain",
          "pressure": 1018.36,
          "summary": "Partly Cloudy",
          "temperature": 11.51,
          "time": 1570035600,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 312,
          "windGust": 10.06,
          "windSpeed": 4.1699999999999999
        },
        {
          "apparentTemperature": 10.69,
          "cloudCover": 0.5,
          "dewPoint": 7.2800000000000002,
          "humidity": 0.79000000000000004,
          "icon": "partly-cloudy-night",
          "ozone": 292.19999999999999,
          "precipIntensity": 0.024500000000000001,
          "precipProbability": 0.040000000000000001,
          "precipType": "rain",
          "pressure": 1018.96,
          "summary": "Mostly Cloudy",
          "temperature": 10.69,
          "time": 1570039200,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 302,
          "windGust": 9.4299999999999997,
          "windSpeed": 3.48
        },
        {
          "apparentTemperature": 10,
          "cloudCover": 0.32000000000000001,
          "dewPoint": 7.1699999999999999,
          "humidity": 0.82999999999999996,
          "icon": "partly-cloudy-night",
          "ozone": 290.19999999999999,
          "precipIntensity": 0.0138,
          "precipProbability": 0.040000000000000001,
          "precipType": "rain",
          "pressure": 1019.49,
          "summary": "Partly Cloudy",
          "temperature": 10,
          "time": 1570042800,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 287,
          "windGust": 8.9800000000000004,
          "windSpeed": 3.4500000000000002
        },
        {
          "apparentTemperature": 7.7000000000000002,
          "cloudCover": 0.28999999999999998,
          "dewPoint": 7.2800000000000002,
          "humidity": 0.85999999999999999,
          "icon": "partly-cloudy-night",
          "ozone": 288.30000000000001,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1019.92,
          "summary": "Partly Cloudy",
          "temperature": 9.5600000000000005,
          "time": 1570046400,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 276,
          "windGust": 8.5800000000000001,
          "windSpeed": 3.52
        },
        {
          "apparentTemperature": 7.3799999999999999,
          "cloudCover": 0.25,
          "dewPoint": 7.4800000000000004,
          "humidity": 0.89000000000000001,
          "icon": "partly-cloudy-night",
          "ozone": 286.39999999999998,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1019.95,
          "summary": "Partly Cloudy",
          "temperature": 9.2200000000000006,
          "time": 1570050000,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 279,
          "windGust": 8.1500000000000004,
          "windSpeed": 3.3500000000000001
        },
        {
          "apparentTemperature": 6.9199999999999999,
          "cloudCover": 0.070000000000000007,
          "dewPoint": 7.6200000000000001,
          "humidity": 0.92000000000000004,
          "icon": "clear-night",
          "ozone": 285,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1019.99,
          "summary": "Clear",
          "temperature": 8.8300000000000001,
          "time": 1570053600,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 318,
          "windGust": 7.6399999999999997,
          "windSpeed": 3.3300000000000001
        },
        {
          "apparentTemperature": 6.3200000000000003,
          "cloudCover": 0.19,
          "dewPoint": 7.6799999999999997,
          "humidity": 0.94999999999999996,
          "icon": "partly-cloudy-night",
          "ozone": 283.80000000000001,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1019.9299999999999,
          "summary": "Partly Cloudy",
          "temperature": 8.5,
          "time": 1570057200,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 223,
          "windGust": 7.0999999999999996,
          "windSpeed": 3.6800000000000002
        },
        {
          "apparentTemperature": 6.1500000000000004,
          "cloudCover": 0.059999999999999998,
          "dewPoint": 7.75,
          "humidity": 0.95999999999999996,
          "icon": "clear-night",
          "ozone": 282,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1019.85,
          "summary": "Clear",
          "temperature": 8.3499999999999996,
          "time": 1570060800,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 260,
          "windGust": 6.7000000000000002,
          "windSpeed": 3.6699999999999999
        },
        {
          "apparentTemperature": 6.0700000000000003,
          "cloudCover": 0.089999999999999997,
          "dewPoint": 7.7000000000000002,
          "humidity": 0.95999999999999996,
          "icon": "clear-night",
          "ozone": 279.19999999999999,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1020.1799999999999,
          "summary": "Clear",
          "temperature": 8.2599999999999998,
          "time": 1570064400,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 259,
          "windGust": 6.5700000000000003,
          "windSpeed": 3.6099999999999999
        },
        {
          "apparentTemperature": 5.9400000000000004,
          "cloudCover": 0.33000000000000002,
          "dewPoint": 7.5999999999999996,
          "humidity": 0.95999999999999996,
          "icon": "partly-cloudy-night",
          "ozone": 275.60000000000002,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1020.35,
          "summary": "Partly Cloudy",
          "temperature": 8.1600000000000001,
          "time": 1570068000,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 257,
          "windGust": 6.5700000000000003,
          "windSpeed": 3.6299999999999999
        },
        {
          "apparentTemperature": 5.8099999999999996,
          "cloudCover": 0.28000000000000003,
          "dewPoint": 7.4199999999999999,
          "humidity": 0.95999999999999996,
          "icon": "partly-cloudy-night",
          "ozone": 273.19999999999999,
          "precipIntensity": 0.0051000000000000004,
          "precipProbability": 0.02,
          "precipType": "rain",
          "pressure": 1020.2,
          "summary": "Partly Cloudy",
          "temperature": 7.96,
          "time": 1570071600,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 249,
          "windGust": 6.5099999999999998,
          "windSpeed": 3.4100000000000001
        },
        {
          "apparentTemperature": 5.04,
          "cloudCover": 0.14000000000000001,
          "dewPoint": 6.9400000000000004,
          "humidity": 0.97999999999999998,
          "icon": "partly-cloudy-night",
          "ozone": 272.5,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1020.36,
          "summary": "Partly Cloudy",
          "temperature": 7.3099999999999996,
          "time": 1570075200,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 246,
          "windGust": 6.29,
          "windSpeed": 3.3900000000000001
        },
        {
          "apparentTemperature": 4.2300000000000004,
          "cloudCover": 0.25,
          "dewPoint": 6.46,
          "humidity": 0.98999999999999999,
          "icon": "partly-cloudy-night",
          "ozone": 272.60000000000002,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1020.09,
          "summary": "Partly Cloudy",
          "temperature": 6.5899999999999999,
          "time": 1570078800,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 245,
          "windGust": 6.0099999999999998,
          "windSpeed": 3.2799999999999998
        },
        {
          "apparentTemperature": 4.1299999999999999,
          "cloudCover": 0.10000000000000001,
          "dewPoint": 6.4400000000000004,
          "humidity": 1,
          "icon": "clear-day",
          "ozone": 272.69999999999999,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1019.73,
          "summary": "Clear",
          "temperature": 6.4400000000000004,
          "time": 1570082400,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 242,
          "windGust": 5.7199999999999998,
          "windSpeed": 3.1600000000000001
        },
        {
          "apparentTemperature": 5.2300000000000004,
          "cloudCover": 0.37,
          "dewPoint": 7.1600000000000001,
          "humidity": 0.97999999999999998,
          "icon": "partly-cloudy-day",
          "ozone": 272.19999999999999,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1020.12,
          "summary": "Partly Cloudy",
          "temperature": 7.4400000000000004,
          "time": 1570086000,
          "uvIndex": 0,
          "visibility": 16.093,
          "windBearing": 240,
          "windGust": 5.5,
          "windSpeed": 3.3199999999999998
        },
        {
          "apparentTemperature": 7.5300000000000002,
          "cloudCover": 0.44,
          "dewPoint": 7.8899999999999997,
          "humidity": 0.90000000000000002,
          "icon": "partly-cloudy-day",
          "ozone": 271.60000000000002,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1020.26,
          "summary": "Partly Cloudy",
          "temperature": 9.4800000000000004,
          "time": 1570089600,
          "uvIndex": 1,
          "visibility": 16.093,
          "windBearing": 242,
          "windGust": 5.25,
          "windSpeed": 3.6499999999999999
        },
        {
          "apparentTemperature": 11.34,
          "cloudCover": 0.46999999999999997,
          "dewPoint": 7.9500000000000002,
          "humidity": 0.80000000000000004,
          "icon": "partly-cloudy-day",
          "ozone": 271,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1020.4299999999999,
          "summary": "Partly Cloudy",
          "temperature": 11.34,
          "time": 1570093200,
          "uvIndex": 2,
          "visibility": 16.093,
          "windBearing": 246,
          "windGust": 4.8899999999999997,
          "windSpeed": 3.8799999999999999
        },
        {
          "apparentTemperature": 12.74,
          "cloudCover": 0.48999999999999999,
          "dewPoint": 7.5899999999999999,
          "humidity": 0.70999999999999996,
          "icon": "partly-cloudy-day",
          "ozone": 270.69999999999999,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1020.34,
          "summary": "Partly Cloudy",
          "temperature": 12.74,
          "time": 1570096800,
          "uvIndex": 3,
          "visibility": 16.093,
          "windBearing": 253,
          "windGust": 4.2599999999999998,
          "windSpeed": 4.0599999999999996
        },
        {
          "apparentTemperature": 13.52,
          "cloudCover": 0.75,
          "dewPoint": 7,
          "humidity": 0.65000000000000002,
          "icon": "partly-cloudy-day",
          "ozone": 270.30000000000001,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1020.39,
          "summary": "Mostly Cloudy",
          "temperature": 13.52,
          "time": 1570100400,
          "uvIndex": 3,
          "visibility": 16.093,
          "windBearing": 256,
          "windGust": 3.79,
          "windSpeed": 3.79
        },
        {
          "apparentTemperature": 14.33,
          "cloudCover": 0.81999999999999995,
          "dewPoint": 6.5099999999999998,
          "humidity": 0.58999999999999997,
          "icon": "partly-cloudy-day",
          "ozone": 269.89999999999998,
          "precipIntensity": 0,
          "precipProbability": 0,
          "pressure": 1019.99,
          "summary": "Mostly Cloudy",
          "temperature": 14.33,
          "time": 1570104000,
          "uvIndex": 3,
          "visibility": 16.093,
          "windBearing": 250,
          "windGust": 3.46,
          "windSpeed": 3.46
        },
        {
          "apparentTemperature": 14.74,
          "cloudCover": 0.79000000000000004,
          "dewPoint": 6.2199999999999998,
          "humidity": 0.56999999999999995,
          "icon": "partly-cloudy-day",
          "ozone": 269.19999999999999,
          "precipIntensity": 0.0071999999999999998,
          "precipProbability": 0.01,
          "precipType": "rain",
          "pressure": 1019.26,
          "summary": "Mostly Cloudy",
          "temperature": 14.74,
          "time": 1570107600,
          "uvIndex": 2,
          "visibility": 16.093,
          "windBearing": 237,
          "windGust": 3.27,
          "windSpeed": 3.27
        },
        {
          "apparentTemperature": 14.98,
          "cloudCover": 0.91000000000000003,
          "dewPoint": 5.9800000000000004,
          "humidity": 0.55000000000000004,
          "icon": "cloudy",
          "ozone": 268.39999999999998,
          "precipIntensity": 0.0071999999999999998,
          "precipProbability": 0.01,
          "precipType": "rain",
          "pressure": 1018.63,
          "summary": "Overcast",
          "temperature": 14.98,
          "time": 1570111200,
          "uvIndex": 2,
          "visibility": 16.093,
          "windBearing": 228,
          "windGust": 3.3199999999999998,
          "windSpeed": 3.3199999999999998
        },
        {
          "apparentTemperature": 14.789999999999999,
          "cloudCover": 1,
          "dewPoint": 5.6100000000000003,
          "humidity": 0.54000000000000004,
          "icon": "cloudy",
          "ozone": 267.80000000000001,
          "precipIntensity": 0.0071999999999999998,
          "precipProbability": 0.01,
          "precipType": "rain",
          "pressure": 1017.99,
          "summary": "Overcast",
          "temperature": 14.789999999999999,
          "time": 1570114800,
          "uvIndex": 1,
          "visibility": 16.093,
          "windBearing": 219,
          "windGust": 3.3799999999999999,
          "windSpeed": 3.3799999999999999
        }
      ],
      "icon": "rain",
      "summary": "Light rain until tomorrow morning."
    }
  }
}
