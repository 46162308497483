'use strict'

angular
  .module('basalteApp')
  .service('UiBasTiles', [
    '$rootScope',
    'ICONS',
    'BAS_ROOMS',
    'BasTile',
    UiBasTiles
  ])

/**
 * @typedef {Object} TUiBasTiles
 * @property {BasTile} lights
 * @property {BasTile} shades
 * @property {BasTile} music
 * @property {BasTile} tv
 * @property {BasTile} scenes
 * @property {BasTile} genericDevices
 * @property {BasTile} thermostat
 * @property {BasTile} cameras
 * @property {BasTile} intercom
 * @property {BasTile} scheduler
 * @property {BasTile} energy
 * @property {BasTile} timers
 * @property {BasTile} security
 * @property {BasTile} doors
 * @property {BasTile} settings
 * @property {BasTile} weather
 * @property {BasTile} callHistory
 * @property {BasTile} lisa_start
 * @property {BasTile} lisa_thermostat
 * @property {BasTile} lisa_intercom
 * @property {BasTile} lisa_lights
 * @property {BasTile} lisa_shades
 * @property {BasTile} lisa_scenes
 * @property {BasTile} lisa_settings
 * @property {BasTile} lisa_music
 * @property {BasTile} lisa_cameras
 */

/**
 * @constructor
 * @param $rootScope
 * @param {ICONS} ICONS
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param BasTile
 */
function UiBasTiles (
  $rootScope,
  ICONS,
  BAS_ROOMS,
  BasTile
) {

  /**
   * @type {TUiBasTiles}
   */
  var root = {
    lights: new BasTile(BAS_ROOMS.FUNCTIONS.LIGHTS),
    shades: new BasTile(BAS_ROOMS.FUNCTIONS.SHADES),
    music: new BasTile(BAS_ROOMS.FUNCTIONS.MUSIC),
    tv: new BasTile(BAS_ROOMS.FUNCTIONS.VIDEO),
    scenes: new BasTile(BAS_ROOMS.FUNCTIONS.SCENES),
    genericDevices: new BasTile(BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES),
    thermostat: new BasTile(BAS_ROOMS.FUNCTIONS.THERMOSTAT),
    cameras: new BasTile(BasTile.ID_CAMERAS),
    intercom: new BasTile(BasTile.ID_INTERCOM),
    scheduler: new BasTile(BasTile.ID_SCHEDULER),
    energy: new BasTile(BasTile.ID_ENERGY),
    timers: new BasTile(BasTile.ID_TIMERS),
    security: new BasTile(BasTile.ID_SECURITY),
    doors: new BasTile(BasTile.ID_DOORS),
    settings: new BasTile(BasTile.ID_SETTINGS),
    weather: new BasTile(BasTile.ID_WEATHER),
    callHistory: new BasTile(BasTile.ID_CALL_HISTORY),

    lisa_intercom: new BasTile(BasTile.ID_INTERCOM),
    lisa_cameras: new BasTile(BasTile.ID_CAMERAS),
    lisa_settings: new BasTile(BasTile.ID_SETTINGS),
    lisa_shades: new BasTile(BAS_ROOMS.FUNCTIONS.SHADES),
    lisa_music: new BasTile(BAS_ROOMS.FUNCTIONS.MUSIC),
    lisa_lights: new BasTile(BAS_ROOMS.FUNCTIONS.LIGHTS),
    lisa_scenes: new BasTile(BAS_ROOMS.FUNCTIONS.SCENES),
    lisa_start: new BasTile(BasTile.ID_LISA_START),
    lisa_thermostat: new BasTile(BAS_ROOMS.FUNCTIONS.THERMOSTAT)
  }

  root.lights.setTranslation('lights')
  root.shades.setTranslation('shades')
  root.music.setTranslation('music')
  root.tv.setTranslation('tv')
  root.scenes.setTranslation('scenes')
  root.genericDevices.setTranslation('other')
  root.thermostat.setTranslation('thermostat')
  root.cameras.setTranslation('cameras')
  root.intercom.setTranslation('intercom')
  root.scheduler.setTranslation('scheduler')
  root.energy.setTranslation('energy')
  root.timers.setTranslation('timers')
  root.security.setTranslation('security')
  root.doors.setTranslation('doors')
  root.settings.setTranslation('settings')
  root.weather.setTranslation('weather')
  root.callHistory.setTranslation('call_history')

  root.lisa_start.setTranslation('start')
  root.lisa_thermostat.setTranslation('thermostat')
  root.lisa_lights.setTranslation('lights')
  root.lisa_shades.setTranslation('shades')
  root.lisa_music.setTranslation('music')
  root.lisa_scenes.setTranslation('scenes')
  root.lisa_settings.setTranslation('settings')
  root.lisa_cameras.setTranslation('cameras')
  root.lisa_intercom.setTranslation('intercom')

  root.lights.icon = ICONS.light
  root.shades.icon = ICONS.screen
  root.music.icon = ICONS.music
  root.music.toggleCss(BasTile.CSS_TILE_SVG_55)
  root.music.toggleCss(BasTile.CSS_TILE_MUSIC)
  root.tv.icon = ICONS.externalTv
  root.tv.toggleCss(BasTile.CSS_TILE_SVG_80)
  root.scenes.icon = ICONS.squaresGrid
  root.genericDevices.icon = ICONS.mixed
  root.genericDevices.toggleCss(BasTile.CSS_TILE_SVG_50)
  root.thermostat.icon = ICONS.temperature
  root.thermostat.toggleCss(BasTile.CSS_TILE_SVG_55)
  root.cameras.icon = ICONS.camera2
  root.cameras.toggleCss(BasTile.CSS_TILE_SVG_55)
  root.intercom.icon = ICONS.callStart
  root.intercom.toggleCss(BasTile.CSS_TILE_SVG_55)
  root.scheduler.icon = ICONS.clock2
  root.scheduler.toggleCss(BasTile.CSS_TILE_SVG_60)
  root.timers.icon = ICONS.timers
  root.timers.toggleCss(BasTile.CSS_TILE_SVG_60)
  root.timers.toggleCss(BasTile.CSS_TILE_TOP_OFFSET)
  root.security.icon = ICONS.lock2
  root.security.toggleCss(BasTile.CSS_TILE_SVG_60)
  root.doors.icon = ICONS.keyhole
  root.doors.toggleCss(BasTile.CSS_TILE_SVG_60)
  root.energy.icon = ICONS.energy
  root.energy.toggleCss(BasTile.CSS_TILE_SVG_60)
  root.settings.icon = ICONS.settings
  root.settings.toggleCss(BasTile.CSS_TILE_SVG_60)
  root.weather.icon =
    '<bas-weather-tile class="bas-tile-svg-wrapper"></bas-weather-tile>'
  root.weather.toggleCss(BasTile.CSS_TILE_SVG_75)
  root.weather.compileIcon = true
  root.callHistory.icon = ICONS.callLog
  root.callHistory.toggleCss(BasTile.CSS_TILE_SVG_45)

  root.lisa_start.icon =
    '<bas-time-tile class="bas-tile-svg-wrapper"></bas-time-tile>'
  root.lisa_start.toggleCss(BasTile.CSS_TILE_SVG_65)
  root.lisa_start.compileIcon = true

  root.lisa_thermostat.icon =
    '<bas-thermostat-tile class="bas-tile-svg-wrapper"></bas-thermostat-tile>'
  root.lisa_thermostat.toggleCss(BasTile.CSS_TILE_SVG_60)
  root.lisa_thermostat.compileIcon = true

  root.lisa_cameras.icon = ICONS.camera2
  root.lisa_cameras.toggleCss(BasTile.CSS_TILE_SVG_40)

  root.lisa_music.icon = ICONS.music
  root.lisa_music.toggleCss(BasTile.CSS_TILE_SVG_45)
  root.lisa_music.toggleCss(BasTile.CSS_TILE_MUSIC)

  root.lisa_lights.icon = ICONS.light
  root.lisa_lights.toggleCss(BasTile.CSS_TILE_SVG_55)

  root.lisa_intercom.icon = ICONS.callStart
  root.lisa_intercom.toggleCss(BasTile.CSS_TILE_SVG_55)

  root.lisa_shades.icon = ICONS.screen
  root.lisa_shades.toggleCss(BasTile.CSS_TILE_SVG_55)

  root.lisa_scenes.icon = ICONS.squaresGrid
  root.lisa_scenes.toggleCss(BasTile.CSS_TILE_SVG_55)

  root.lisa_settings.icon = ICONS.settings
  root.lisa_settings.toggleCss(BasTile.CSS_TILE_SVG_45)

  $rootScope.$on('$translateChangeSuccess', onTranslationChange)

  /**
   * @returns {TUiBasTiles}
   */
  this.get = function () {
    return root
  }

  function onTranslationChange () {

    var tile, keys, i, length

    keys = Object.keys(root)
    length = keys.length
    for (i = 0; i < length; i++) {

      tile = root[keys[i]]
      if (tile instanceof BasTile) {

        tile.updateTranslation()
      }
    }
  }
}
