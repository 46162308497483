'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasStoredServers', [
    '$rootScope',
    'BAS_APP_STORAGE',
    'BAS_CONNECT',
    'BAS_STORED_SERVERS',
    'BasAppDevice',
    'BasStorage',
    BasStoredServers
  ])

/**
 * @typedef {Object} TBasStoredServer
 * @property {string} name
 * @property {string} address
 */

/**
 * @typedef {Object} TBasStoredServersState
 * @property {Object<string, ?TBasStoredServer>} servers
 * @property {string[]} uiServers
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @param {BAS_CONNECT} BAS_CONNECT
 * @param {BAS_STORED_SERVERS} BAS_STORED_SERVERS
 * @param {BasAppDevice} BasAppDevice
 * @param BasStorage
 */
function BasStoredServers (
  $rootScope,
  BAS_APP_STORAGE,
  BAS_CONNECT,
  BAS_STORED_SERVERS,
  BasAppDevice,
  BasStorage
) {
  var OK = 'ok'

  var ERR_SERVER_EXISTS = 'BasStoredServersErrorServerExists'
  var ERR_INVALID_ADDRESS = 'BasStoredServersErrorInvalidAddress'

  /**
   * @type {TBasStoredServersState}
   */
  var state = {}
  state.servers = {}
  state.uiServers = []

  /**
   * @constant {string}
   */
  this.OK = OK

  /**
   * @constant {string}
   */
  this.ERR_SERVER_EXISTS = ERR_SERVER_EXISTS

  /**
   * @constant {string}
   */
  this.ERR_INVALID_ADDRESS = ERR_INVALID_ADDRESS

  this.get = get
  this.isValidAddress = isValidAddress
  this.getServer = getServer
  this.getServerAddress = getServerAddress
  this.getBasConnectServer = getBasConnectServer
  this.addServer = addServer
  this.removeServer = removeServer
  this.removeServerById = removeServerById

  init()

  function init () {

    _readStorage()
  }

  /**
   * @returns {TBasStoredServersState}
   */
  function get () {

    return state
  }

  /**
   * @param {string} address
   * @returns {boolean}
   */
  function isValidAddress (address) {

    return BasUtil.isValidHostname(address)
  }

  /**
   * @param {string} id
   * @returns {?TBasStoredServer}
   */
  function getServer (id) {

    var _server = state.servers[id]

    return BasUtil.isObject(_server) ? _server : null
  }

  /**
   * @param {string} id
   * @returns {string}
   */
  function getServerAddress (id) {

    var _server = state.servers[id]

    return BasUtil.isObject(_server)
      ? _server[BAS_APP_STORAGE.K_ADDRESS]
      : ''
  }

  /**
   * @param {string} id
   * @returns {?TBasConnectServer}
   */
  function getBasConnectServer (id) {

    var _storedServer

    _storedServer = getServer(id)

    if (_storedServer) {

      return {
        type: BAS_CONNECT.T_LOCAL,
        address: _storedServer.address
      }
    }

    return null
  }

  /**
   * @param {string} name
   * @param {string} address
   * @returns {string}
   */
  function addServer (name, address) {

    var server

    server = _getServer(address)

    if (server) return ERR_SERVER_EXISTS
    if (!isValidAddress(address)) return ERR_INVALID_ADDRESS

    _addServer(name, address)
    _writeStorage()

    $rootScope.$emit(BAS_STORED_SERVERS.EVT_STORED_CORES_UPDATED)

    return OK
  }

  /**
   * Add server without checks and without writing to storage
   *
   * @private
   * @param {string} name
   * @param {string} address
   */
  function _addServer (name, address) {

    var serverId

    serverId = _getServerId(name, address)

    state.servers[serverId] = _createServer(name, address)
    state.uiServers.push(serverId)
  }

  /**
   * @param {string} name
   * @param {string} address
   */
  function removeServer (name, address) {

    var server

    server = _getServer(address)

    if (server) {

      removeServerById(_getServerId(name, address))
    }
  }

  /**
   * @param {string} serverId
   */
  function removeServerById (serverId) {

    if (BasUtil.isNEString(serverId)) {

      _removeServerById(serverId)

      _writeStorage()

      $rootScope.$emit(BAS_STORED_SERVERS.EVT_STORED_CORES_UPDATED)
    }
  }

  /**
   * Remove server by serverId without checks and without writing to storage
   *
   * @private
   * @param {string} serverId
   */
  function _removeServerById (serverId) {

    var idx

    idx = state.uiServers.indexOf(serverId)

    if (idx > -1) state.uiServers.splice(idx, 1)
    state.servers[serverId] = null
  }

  function _writeStorage () {

    var data, length, i, server

    // Disable support for manually stored servers
    if (BasAppDevice.isLiveOnlyOrWebRTC()) return

    data = []

    length = state.uiServers.length
    for (i = 0; i < length; i++) {

      server = state.servers[state.uiServers[i]]

      if (BasUtil.isObject(server)) {

        data.push(server)
      }
    }

    BasStorage.set(BAS_APP_STORAGE.K_STORED_SERVERS, data)
  }

  function _readStorage () {

    var data, length, i, dataServer

    // Disable support for manually stored servers
    if (BasAppDevice.isLiveOnlyOrWebRTC()) return

    _clearServers()

    data = BasStorage.get(BAS_APP_STORAGE.K_STORED_SERVERS)

    if (Array.isArray(data)) {

      length = data.length
      for (i = 0; i < length; i++) {

        dataServer = data[i]

        if (BasUtil.isObject(dataServer) &&
          BasUtil.isNEString(dataServer[BAS_APP_STORAGE.K_NAME]) &&
          BasUtil.isNEString(dataServer[BAS_APP_STORAGE.K_ADDRESS])) {

          _addServer(
            dataServer[BAS_APP_STORAGE.K_NAME],
            dataServer[BAS_APP_STORAGE.K_ADDRESS]
          )
        }
      }
    }
  }

  /**
   * Looks for server with same address
   *
   * @private
   * @param {string} address
   * @returns {?TBasStoredServer}
   */
  function _getServer (address) {

    var keys, length, i, server

    keys = Object.keys(state.servers)
    length = keys.length
    for (i = 0; i < length; i++) {

      server = state.servers[keys[i]]

      if (BasUtil.isObject(server) &&
        server[BAS_APP_STORAGE.K_ADDRESS] === address) {

        return server
      }
    }

    return null
  }

  /**
   * @private
   * @param {string} name
   * @param {string} address
   * @returns {string}
   */
  function _getServerId (name, address) {

    return name + address
  }

  /**
   * @private
   * @param {string} name
   * @param {string} address
   * @returns {TBasStoredServer}
   */
  function _createServer (name, address) {

    var result = {}

    result[BAS_APP_STORAGE.K_NAME] = name
    result[BAS_APP_STORAGE.K_ADDRESS] = address

    return result
  }

  function _clearServers () {

    state.servers = {}
    state.uiServers = []
  }
}
