'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasConditionsHelper', [
    '$rootScope',
    'BAS_CORE',
    'BAS_ERRORS',
    'BAS_CURRENT_CORE',
    'BAS_ROOMS',
    'CurrentBasCore',
    'CurrentRoom',
    'BasError',
    BasConditionsHelper
  ])

/**
 * @callback CBasEventWaitAbort
 */

/**
 * @callback CBasEventCallback
 * @param {BasError} [error]
 */

/**
 * @callback CBasConditionValidator
 * @param {...*} [args]
 * @returns {boolean}
 */

/**
 * @callback CBasEventValidator
 * @param {...*} [args]
 * @returns {boolean}
 */

/**
 * @callback CBasWaitDeRegister
 */

/**
 * @callback CBasWaitSetEventListener
 * @param {(string|(string[]))} event
 * @param callback
 * @returns {CBasWaitDeRegister}
 */

/**
 * @typedef {Object} TBaseBasWaitOptions
 * @property {number} [timeout]
 */

/**
 * @typedef {Object} TBasWaitForEventOptions
 * @property {(string|(string[]))} event
 * @property {CBasConditionValidator} [conditionValidator]
 * @property {CBasEventValidator} [eventValidator]
 * @property {CBasWaitSetEventListener} [setEventListener]
 * @property {number} [timeout]
 */

/**
 * @typedef {Object} TBasWaitForBasCoreContainerEventOptions
 * @property {BasCoreContainer} basCoreContainer
 * @property {number} [timeout]
 */

/**
 * @typedef {Object} TBasEventWait
 * @property {CBasEventWaitAbort} abort
 * @property {boolean} finished
 * @property {boolean} success
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_CORE} BAS_CORE
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 * @param BasError
 */
function BasConditionsHelper (
  $rootScope,
  BAS_CORE,
  BAS_ERRORS,
  BAS_CURRENT_CORE,
  BAS_ROOMS,
  CurrentBasCore,
  CurrentRoom,
  BasError
) {
  this.waitForCurrentBasCore = waitForCurrentBasCore
  this.waitForConnectedCurrentBasCore = waitForConnectedCurrentBasCore
  this.waitForCoreConnectedCurrentBasCore = waitForCoreConnectedCurrentBasCore
  this.waitForVersion = waitForVersion
  this.waitForSystemAndProfile = waitForSystemAndProfile
  this.waitForMusicConfig = waitForMusicConfig
  this.waitForRooms = waitForRooms
  this.waitForCurrentRoom = waitForCurrentRoom
  this.waitForSharedStorageTidalLegacyAuthDontAsk =
    waitForSharedStorageTidalLegacyAuthDontAsk
  this.waitForSharedStorageLisaShowStart =
    waitForSharedStorageLisaShowStart

  /**
   * @param {?TBaseBasWaitOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForCurrentBasCore (
    options,
    callback
  ) {
    var waitOptions

    waitOptions = {}
    waitOptions.event = BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED
    waitOptions.conditionValidator = currentBasCoreValidator
    waitOptions.eventValidator = currentBasCoreValidator

    if (BasUtil.isObject(options)) waitOptions.timeout = options.timeout

    return waitForEvent(waitOptions, callback)
  }

  /**
   * @param {?TBaseBasWaitOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForConnectedCurrentBasCore (
    options,
    callback
  ) {
    var waitOptions

    waitOptions = {}
    waitOptions.event = [
      BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED,
      BAS_CURRENT_CORE.EVT_CORE_CONNECTED
    ]
    waitOptions.conditionValidator = connectedCurrentBasCoreValidator
    waitOptions.eventValidator = connectedCurrentBasCoreValidator

    if (BasUtil.isObject(options)) waitOptions.timeout = options.timeout

    return waitForEvent(waitOptions, callback)
  }

  /**
   * @param {?TBaseBasWaitOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForCoreConnectedCurrentBasCore (
    options,
    callback
  ) {
    var waitOptions

    waitOptions = {}
    waitOptions.event = [
      BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED,
      BAS_CURRENT_CORE.EVT_CORE_CONNECTED,
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED
    ]
    waitOptions.conditionValidator = coreConnectedCurrentBasCoreValidator
    waitOptions.eventValidator = coreConnectedCurrentBasCoreValidator

    if (BasUtil.isObject(options)) waitOptions.timeout = options.timeout

    return waitForEvent(waitOptions, callback)
  }

  /**
   * Waits for system version on given core container, finishes with
   * success: false after timeout is reached and no system version was received
   *
   * @param {TBasWaitForBasCoreContainerEventOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForVersion (
    options,
    callback
  ) {
    var checkInputResult, waitOptions, basCoreContainer

    checkInputResult = _checkOptions(options, callback)
    if (checkInputResult) return checkInputResult

    basCoreContainer = options.basCoreContainer

    checkInputResult = _checkBasCoreContainer(basCoreContainer, callback)
    if (checkInputResult) return checkInputResult

    waitOptions = {
      event: BAS_CORE.EVT_CORE_VERSION,
      conditionValidator:
        basCoreVersionValidator.bind(null, basCoreContainer),
      eventValidator: _getBasCoreContainerEventValidator(basCoreContainer),
      timeout: options.timeout
    }

    return waitForEvent(waitOptions, callback)
  }

  /**
   * Waits for profile on given core container, finishes with success: false
   * after timeout is reached and profile was not received
   *
   * @param {TBasWaitForBasCoreContainerEventOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForProfile (
    options,
    callback
  ) {
    var checkInputResult, waitOptions, basCoreContainer

    checkInputResult = _checkOptions(options, callback)
    if (checkInputResult) return checkInputResult

    basCoreContainer = options.basCoreContainer

    checkInputResult = _checkBasCoreContainer(basCoreContainer, callback)
    if (checkInputResult) return checkInputResult

    waitOptions = {
      event: BAS_CORE.EVT_CORE_PROFILE_CREATED,
      conditionValidator:
        basCoreProfileValidator.bind(null, basCoreContainer),
      eventValidator: _getBasCoreContainerEventValidator(basCoreContainer),
      timeout: options.timeout
    }

    return waitForEvent(waitOptions, callback)
  }

  /**
   * Waits for system properties on given core container, finishes with
   * success: false after timeout is reached and system properties were not
   * received
   *
   * @param {TBasWaitForBasCoreContainerEventOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForSystemProperties (
    options,
    callback
  ) {
    var checkInputResult, waitOptions, basCoreContainer

    checkInputResult = _checkOptions(options, callback)
    if (checkInputResult) return checkInputResult

    basCoreContainer = options.basCoreContainer

    checkInputResult = _checkBasCoreContainer(basCoreContainer, callback)
    if (checkInputResult) return checkInputResult

    waitOptions = {
      event: BAS_CORE.EVT_CORE_SYSTEM,
      conditionValidator:
        basCoreSystemValidator.bind(null, basCoreContainer),
      eventValidator: _getBasCoreContainerEventValidator(basCoreContainer),
      timeout: options.timeout
    }

    return waitForEvent(waitOptions, callback)
  }

  /**
   * Waits for music config on given core container, finishes with
   * success: false after timeout is reached and music config was not received
   *
   * @param {TBasWaitForBasCoreContainerEventOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForMusicConfig (
    options,
    callback
  ) {
    var checkInputResult, waitOptions, basCoreContainer

    checkInputResult = _checkOptions(options, callback)
    if (checkInputResult) return checkInputResult

    basCoreContainer = options.basCoreContainer

    checkInputResult = _checkBasCoreContainer(basCoreContainer, callback)
    if (checkInputResult) return checkInputResult

    waitOptions = {
      event: BAS_CORE.EVT_CORE_MUSIC_RECEIVED,
      conditionValidator:
        basCoreMusicValidator.bind(null, basCoreContainer),
      eventValidator: _getBasCoreContainerEventValidator(basCoreContainer),
      timeout: options.timeout
    }

    return waitForEvent(waitOptions, callback)
  }

  /**
   * Waits for rooms to be received on given core container, finishes
   * with success: false after timeout is reached and rooms were not received
   *
   * @param {TBasWaitForBasCoreContainerEventOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForRooms (
    options,
    callback
  ) {
    var checkInputResult, waitOptions, basCoreContainer

    checkInputResult = _checkOptions(options, callback)
    if (checkInputResult) return checkInputResult

    basCoreContainer = options.basCoreContainer

    checkInputResult = _checkBasCoreContainer(basCoreContainer, callback)
    if (checkInputResult) return checkInputResult

    waitOptions = {
      event: BAS_CORE.EVT_CORE_ROOMS_RECEIVED,
      conditionValidator:
        basCoreRoomsValidator.bind(null, basCoreContainer),
      eventValidator: _getBasCoreContainerEventValidator(basCoreContainer),
      timeout: options.timeout
    }

    return waitForEvent(waitOptions, callback)
  }

  /**
   * Waits for a room to be selected in CurrentRoom, finishes with
   * success: false after timeout is reached and no such event was emitted
   *
   * @param {?TBaseBasWaitOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForCurrentRoom (
    options,
    callback
  ) {
    var waitOptions

    waitOptions = {}
    waitOptions.event = BAS_ROOMS.EVT_CURRENT_ROOM_CHANGED
    waitOptions.conditionValidator = currentRoomValidator
    waitOptions.eventValidator = currentRoomValidator

    if (BasUtil.isObject(options)) waitOptions.timeout = options.timeout

    return waitForEvent(waitOptions, callback)
  }

  /**
   * Waits for result of getting all shared storage
   *  EVT_CORE_TIDAL_LEGACY_AUTH_DONT_ASK
   *
   * @param {?TBaseBasWaitOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForSharedStorageTidalLegacyAuthDontAsk (
    options,
    callback
  ) {
    var waitOptions

    waitOptions = {}
    waitOptions.event = BAS_CORE.EVT_CORE_TIDAL_LEGACY_AUTH_DONT_ASK
    waitOptions.conditionValidator = basCoreAllSharedStorageValidator
    waitOptions.eventValidator = basCoreAllSharedStorageValidator

    if (BasUtil.isObject(options)) waitOptions.timeout = options.timeout

    return waitForEvent(waitOptions, callback)
  }

  /**
   * Waits for result of getting all shared storage
   *
   * @param {?TBaseBasWaitOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForSharedStorageLisaShowStart (
    options,
    callback
  ) {
    var waitOptions

    waitOptions = {}
    waitOptions.event = BAS_CORE.EVT_CORE_LISA_SHOW_START
    waitOptions.conditionValidator = basCoreAllSharedStorageValidator
    waitOptions.eventValidator = basCoreAllSharedStorageValidator

    if (BasUtil.isObject(options)) waitOptions.timeout = options.timeout

    return waitForEvent(waitOptions, callback)
  }

  /**
   * Waits for system and profile, finishes with success: false after timeout is
   * reached and no such event was emitted
   *
   * @param {TBasWaitForBasCoreContainerEventOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForSystemAndProfile (
    options,
    callback
  ) {
    var result, checkInputResult, waitOptions, basCoreContainer, timeoutId
    var cbCalled, profileWait, systemWait

    checkInputResult = _checkOptions(options, callback)
    if (checkInputResult) return checkInputResult

    basCoreContainer = options.basCoreContainer

    checkInputResult = _checkBasCoreContainer(basCoreContainer, callback)
    if (checkInputResult) return checkInputResult

    if (
      !basCoreContainer.core.profile ||
      !basCoreContainer.core.system
    ) {
      Promise.resolve().then(BasUtil.exec.bind(
        null,
        callback,
        new BasError(
          BAS_ERRORS.T_INVALID_INPUT,
          basCoreContainer,
          BAS_ERRORS.M_INVALID_INPUT
        )
      ))
      return {
        finished: true,
        success: false,
        abort: abort
      }
    }

    cbCalled = false

    result = {
      finished: false,
      success: false,
      abort: abort
    }

    if (BasUtil.isPNumber(options.timeout)) {

      timeoutId = setTimeout(_onTimeout, options.timeout)
    }

    waitOptions = {
      basCoreContainer: basCoreContainer
    }

    if (basCoreContainer.core.supportsProfile) {

      profileWait = waitForProfile(waitOptions, _onEvent)
      systemWait = waitForSystemProperties(waitOptions, _onEvent)

    } else if (basCoreContainer.core.supportsSystemProperties) {

      systemWait = waitForSystemProperties(waitOptions, _onEvent)

    } else {

      Promise.resolve().then(BasUtil.exec.bind(
        null,
        callback,
        new BasError(
          BAS_ERRORS.T_NOT_SUPPORTED,
          basCoreContainer,
          'Profile and system properties not supported'
        )
      ))
      return {
        finished: true,
        success: false,
        abort: abort
      }
    }

    return result

    /**
     * @private
     * @param {BasError} [error]
     */
    function _onEvent (error) {

      var allFinished

      if (error) {

        _finish(error)

      } else {

        allFinished = !(profileWait && !profileWait.finished)
        if (systemWait && !systemWait.finished) allFinished = false

        if (allFinished) _finish()
      }
    }

    function abort () {

      _finish(new BasError(
        BAS_ERRORS.T_ABORT,
        undefined,
        BAS_ERRORS.M_ABORTED
      ))
    }

    function _onTimeout () {

      _finish(new BasError(
        BAS_ERRORS.T_TIMEOUT,
        undefined,
        BAS_ERRORS.M_TIMEOUT
      ))
    }

    /**
     * @private
     * @param {BasError} [error]
     */
    function _finish (error) {

      clearTimeout(timeoutId)
      timeoutId = 0

      if (systemWait) {
        BasUtil.execute(systemWait.abort)
        systemWait = undefined
      }

      if (profileWait) {
        BasUtil.execute(profileWait.abort)
        profileWait = undefined
      }

      if (!cbCalled) {

        cbCalled = true
        Promise.resolve().then(BasUtil.exec.bind(null, callback, error))
      }
    }
  }

  /**
   * Waits for a given event, finishes with success: false after timeout is
   * reached and no such event was emitted. If a validate function is given,
   * it will be called with the event data. If this validate function returns
   * true, this will finish with success: true, else it will keep waiting.
   *
   * @param {TBasWaitForEventOptions} options
   * @param {CBasEventCallback} callback
   * @returns {TBasEventWait}
   */
  function waitForEvent (
    options,
    callback
  ) {
    var result, event, conditionValidator, eventValidator
    var setEventListener, timeout, listener, timeoutId

    result = {
      finished: false,
      success: false,
      abort: abort
    }

    if (!BasUtil.isObject(options) || !BasUtil.isNEString(options.event)) {

      result.finished = true
      Promise.resolve().then(BasUtil.exec.bind(
        null,
        callback,
        new BasError(
          BAS_ERRORS.T_INVALID_INPUT,
          options,
          BAS_ERRORS.M_INVALID_INPUT
        )
      ))
      return result
    }

    conditionValidator = options.conditionValidator

    if (BasUtil.isFunction(conditionValidator) && conditionValidator()) {

      result.finished = true
      result.success = true
      Promise.resolve().then(BasUtil.exec.bind(null, callback))
      return result
    }

    event = options.event
    setEventListener = options.setEventListener
    eventValidator = options.eventValidator
    timeout = options.timeout

    // Start listening for event
    listener = BasUtil.isFunction(setEventListener)
      ? setEventListener(event, _onEvent)
      : _setEventListener(event, _onEvent)

    // Set timeout if needed
    if (BasUtil.isPNumber(timeout)) {

      timeoutId = setTimeout(_onTimeout, timeout)
    }

    return result

    /**
     * @private
     * @param {(string|(string[]))} events
     * @param evtCallback
     * @returns {CBasWaitDeRegister}
     */
    function _setEventListener (events, evtCallback) {

      var _listeners, length, i

      _listeners = []

      if (Array.isArray(events)) {

        length = events.length
        for (i = 0; i < length; i++) {

          _listeners.push($rootScope.$on(
            events[i],
            evtCallback
          ))
        }

      } else if (BasUtil.isNEString(events)) {

        _listeners.push($rootScope.$on(
          events,
          evtCallback
        ))
      }

      return function () {
        BasUtil.executeArray(_listeners)
        _listeners = []
      }
    }

    function _onEvent () {

      if (BasUtil.isFunction(eventValidator)) {

        if (eventValidator.apply(null, arguments)) {

          result.succes = true
          _finish()

        } else {

          // Wait for next event to validate
        }

      } else {

        result.succes = true
        _finish()
      }
    }

    function _onTimeout () {

      _finish(new BasError(
        BAS_ERRORS.T_TIMEOUT,
        undefined,
        BAS_ERRORS.M_TIMEOUT
      ))
    }

    function abort () {

      _finish(new BasError(
        BAS_ERRORS.T_ABORT,
        undefined,
        BAS_ERRORS.M_ABORTED
      ))
    }

    function _finish (error) {

      clearTimeout(timeoutId)
      timeoutId = 0
      BasUtil.execute(listener)
      listener = null

      if (!result.finished) {

        result.finished = true
        BasUtil.exec(callback, error)
      }
    }
  }

  /**
   * @private
   * @param {Object} options
   * @param {CBasEventCallback} callback
   * @returns {?TBasEventWait}
   */
  function _checkOptions (
    options,
    callback
  ) {
    if (!BasUtil.isObject(options)) {

      Promise.resolve().then(BasUtil.exec.bind(
        null,
        callback,
        new BasError(
          BAS_ERRORS.T_INVALID_INPUT,
          options,
          BAS_ERRORS.M_INVALID_INPUT
        )
      ))
      return {
        finished: true,
        success: false,
        abort: dummyAbort
      }
    }
  }

  /**
   * @private
   * @param {BasCoreContainer} basCoreContainer
   * @param {CBasEventCallback} callback
   * @returns {?TBasEventWait}
   */
  function _checkBasCoreContainer (
    basCoreContainer,
    callback
  ) {
    if (
      !basCoreContainer ||
      !basCoreContainer.hasCore ||
      !basCoreContainer.hasCore()
    ) {
      Promise.resolve().then(BasUtil.exec.bind(
        null,
        callback,
        new BasError(
          BAS_ERRORS.T_INVALID_INPUT,
          basCoreContainer,
          BAS_ERRORS.M_INVALID_INPUT
        )
      ))
      return {
        finished: true,
        success: false,
        abort: dummyAbort
      }
    }
  }

  /**
   * @returns {boolean}
   */
  function currentBasCoreValidator () {
    return CurrentBasCore.has()
  }

  /**
   * @returns {boolean}
   */
  function connectedCurrentBasCoreValidator () {

    var server

    server = CurrentBasCore.getServer()

    return server
      ? server.isConnected()
      : false
  }

  /**
   * @returns {boolean}
   */
  function coreConnectedCurrentBasCoreValidator () {

    var server

    server = CurrentBasCore.getServer()

    return server
      ? server.isCoreConnected()
      : false
  }

  /**
   * @param {BasCoreContainer} basCoreContainer
   * @returns {boolean}
   */
  function basCoreVersionValidator (basCoreContainer) {
    return !!basCoreContainer.core.version
  }

  /**
   * @param {BasCoreContainer} basCoreContainer
   * @returns {boolean}
   */
  function basCoreProfileValidator (basCoreContainer) {
    return (
      basCoreContainer.core.profile &&
      !basCoreContainer.core.profile.propertiesDirty
    )
  }

  /**
   * @param {BasCoreContainer} basCoreContainer
   * @returns {boolean}
   */
  function basCoreSystemValidator (basCoreContainer) {
    return (
      basCoreContainer.core.system &&
      !basCoreContainer.core.system.propertiesDirty
    )
  }

  /**
   * @param {BasCoreContainer} basCoreContainer
   * @returns {boolean}
   */
  function basCoreMusicValidator (basCoreContainer) {
    return basCoreContainer.core.musicConfigReceived
  }

  /**
   * @param {BasCoreContainer} basCoreContainer
   * @returns {boolean}
   */
  function basCoreRoomsValidator (basCoreContainer) {
    return basCoreContainer.core.roomsReceived
  }

  /**
   * @returns {boolean}
   */
  function currentRoomValidator () {
    return !!CurrentRoom.getRoom()
  }

  /**
   * @returns {boolean}
   */
  function basCoreAllSharedStorageValidator () {
    return (
      CurrentBasCore.hasCore() &&
      !CurrentBasCore.get().core.core.sharedServerStorage.dirty
    )
  }

  /**
   * @private
   * @param basCoreContainer
   * @returns {CBasEventValidator}
   */
  function _getBasCoreContainerEventValidator (basCoreContainer) {

    return basCoreContainerEventValidator

    /**
     * @param _event
     * @param {BasCoreContainer} eventBasCoreContainer
     * @returns {boolean}
     */
    function basCoreContainerEventValidator (
      _event,
      eventBasCoreContainer
    ) {
      return eventBasCoreContainer === basCoreContainer
    }
  }

  /**
   * Dummy abort function that is given if the wait is not abortable
   */
  function dummyAbort () {
    // Empty
  }
}
