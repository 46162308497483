'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceRemoteJsSetting', deviceRemoteJsSetting)

function deviceRemoteJsSetting () {

  return {
    restrict: 'AE',
    template:
      '<div' +
      ' class="bbs bbs-small bbs-item bbs-centered bbs-action' +
      ' bbs-flex bbs-active-select"' +
      ' ng-class="{' +
      '   \'bbs-disabled\':' +
      '      remoteJsSetting.basRemoteJsState.enableRemoteJsPromise &&' +
      '      !remoteJsSetting.basRemoteJsState.code' +
      ' }"' +
      ' bas-click="remoteJsSetting.toggleRemoteJs()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      ' ng-bind="remoteJsSetting.uiStateTxt"></div>' +
      '</div>' +
      '<div ng-if="remoteJsSetting.basRemoteJsState.code"' +
      ' class="bbs bbs-small bbs-item bbs-centered bbs-flex">' +
      '<div class="bbs-name ellipsis-overflow"' +
      ' data-translate="debug_code"></div>' +
      '<div class="bbs-value bas-debug-code"' +
      ' ng-bind="remoteJsSetting.basRemoteJsState.code"></div>' +
      '</div>' +
      '<div ng-if="remoteJsSetting.basRemoteJsState.code"' +
      ' class="bbs bbs-extra-small bbs-item bbs-centered">' +
      '<div class="bbs-name" data-translate="debug_remote_exit_info"></div>' +
      '</div>',
    controller: [
      '$rootScope',
      '$scope',
      'BAS_REMOTE_JS',
      'BAS_MODAL',
      'BasAppDevice',
      'BasCoreClient',
      'BasRemoteJs',
      'BasModal',
      'BasUtilities',
      controller
    ],
    controllerAs: 'remoteJsSetting'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_REMOTE_JS} BAS_REMOTE_JS
   * @param {BAS_MODAL} BAS_MODAL
   * @param {BasAppDevice} BasAppDevice
   * @param {BasCoreClient} BasCoreClient
   * @param {BasRemoteJs} BasRemoteJs
   * @param {BasModal} BasModal
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    $scope,
    BAS_REMOTE_JS,
    BAS_MODAL,
    BasAppDevice,
    BasCoreClient,
    BasRemoteJs,
    BasModal,
    BasUtilities
  ) {
    var remoteJsSetting = this

    var _listeners = []

    remoteJsSetting.uiStateTxt = ''

    /**
     * @type {TBasRemoteJsState}
     */
    remoteJsSetting.basRemoteJsState = BasRemoteJs.get()

    remoteJsSetting.toggleRemoteJs = toggleRemoteJs

    remoteJsSetting.$postLink = _onPostLink
    remoteJsSetting.$onDestroy = _onDestroy

    init()

    function init () {

      _listeners.push($rootScope.$on(
        BAS_REMOTE_JS.EVT_BAS_REMOTE_JS_STATE_CHANGED,
        _onBasRemoteJsStateChanged
      ))
    }

    function _onPostLink () {

      _syncButtonTxt()
    }

    function _onBasRemoteJsStateChanged () {

      _syncButtonTxt()

      $scope.$applyAsync()
    }

    function toggleRemoteJs () {

      // Disable button when request is ongoing
      if (
        !remoteJsSetting.basRemoteJsState.enableRemoteJsPromise ||
        remoteJsSetting.basRemoteJsState.code
      ) {
        if (remoteJsSetting.basRemoteJsState.code) {
          BasModal.show(BAS_MODAL.T_APP_RESTART).then(_onAppRestartModal)
        } else {
          BasRemoteJs.enableRemoteJs()
        }

        _syncButtonTxt()
      }

      function _onAppRestartModal (modal) {
        modal.close.then(_onAppRestartModalClose)
      }

      function _onAppRestartModalClose (result) {

        if (result === BAS_MODAL.C_YES) {

          if (BasAppDevice.isCoreClient()) {

            BasCoreClient.restartApp()

          } else {

            BasAppDevice.reloadPage()
          }
        }
      }
    }

    function _syncButtonTxt () {

      remoteJsSetting.uiStateTxt = remoteJsSetting.basRemoteJsState.code
        ? BasUtilities.translate('restart_app')
        : remoteJsSetting.basRemoteJsState.enableRemoteJsPromise
          ? BasUtilities.translate('retrieving')
          : BasUtilities.translate('debug_remote_enable')
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
