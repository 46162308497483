'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basRoomImagePicker', [
    'BAS_HTML',
    basRoomImagePickerDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basRoomImagePicker
 */
function basRoomImagePickerDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.roomImagePicker,
    controller: [
      '$window',
      '$scope',
      '$rootScope',
      'ICONS',
      'BAS_IMAGE',
      'BAS_ROOMS',
      'BasImage',
      'BasRoom',
      'RoomsHelper',
      controller
    ],
    controllerAs: 'roomImagePicker',
    bindToController: {
      roomId: '<',
      close: '&?'
    }
  }

  /**
   * @param $window
   * @param $scope
   * @param $rootScope
   * @param {ICONS} ICONS
   * @param {BAS_IMAGE} BAS_IMAGE
   * @param {BAS_ROOMS} BAS_ROOMS
   * @param BasImage
   * @param BasRoom
   * @param {RoomsHelper} RoomsHelper
   */
  function controller (
    $window,
    $scope,
    $rootScope,
    ICONS,
    BAS_IMAGE,
    BAS_ROOMS,
    BasImage,
    BasRoom,
    RoomsHelper
  ) {
    var roomImagePicker = this

    var basTm = $window.basTModule

    var _listeners = []

    /**
     * @type {number}
     */
    var _closeTimeoutId = 0

    /**
     * @type {BAS_ROOMS}
     */
    roomImagePicker.BAS_ROOMS = BAS_ROOMS

    roomImagePicker.getImage = getImage
    roomImagePicker.getClearImage = getClearImage
    roomImagePicker.onImageApproved = onImageApproved
    roomImagePicker.onRevertApproved = onRevertApproved

    roomImagePicker.biImgOpts = {
      customClass: [
        BAS_IMAGE.C_BG_COVER
      ]
    }

    roomImagePicker.biSvgOpts = {
      customClass: [
        BAS_IMAGE.C_BG_CONTAIN,
        BAS_IMAGE.C_COLOR_LIGHT_CONTROL,
        BAS_IMAGE.C_SIZE_60
      ]
    }

    roomImagePicker.defaultIcon = ICONS.roomType_custom

    init()

    function init () {

      $scope.$on('$destroy', _onDestroy)

      _listeners.push($rootScope.$on(
        BAS_ROOMS.EVT_ROOMS_UPDATED,
        _onRoomsUpdated
      ))
    }

    /**
     * Format image to your liking.
     * A basImage is returned to the picker
     *
     * @param {string} imageDataURI
     * @returns {BasImage}
     */
    function getImage (imageDataURI) {

      return new BasImage(imageDataURI, roomImagePicker.biImgOpts)
    }

    function onImageApproved (image, imageDataURI) {

      var room

      if (basTm) {

        basTm.logEvt({
          evtType: basTm.T_APP_IMAGE_PICKER,
          evtSubType: 'room'
        })
      }

      if (image && imageDataURI) {

        room = RoomsHelper.getRoomForId(roomImagePicker.roomId)

        return room
          ? room.updateImage(image).then(_onServerImageSuccess)
          : Promise.reject('no room')
      }

      return Promise.reject('no valid image present')

      function _onServerImageSuccess () {

        var _room, images

        _room = RoomsHelper.getRoomForId(roomImagePicker.roomId)

        if (_room) {

          images = {}
          images.blurw800 = imageDataURI
          images.imgw400 = imageDataURI

          _room.setImages(images)
        }

        _closeTimeoutId = setTimeout(_close, 500)
      }
    }

    function getClearImage () {

      var _icon, room

      room = RoomsHelper.getRoomForId(roomImagePicker.roomId)

      if (room) {

        _icon = BasRoom.getIconForRoomType(room.type)

        return new BasImage(
          _icon || roomImagePicker.defaultIcon,
          roomImagePicker.biSvgOpts
        )
      }

      return null
    }

    function onRevertApproved () {

      var room

      room = RoomsHelper.getRoomForId(roomImagePicker.roomId)

      return room
        ? room.updateImage('').then(_onServerImageRevertSuccess)
        : Promise.reject('no room')
    }

    function _onServerImageRevertSuccess () {

      var room

      room = RoomsHelper.getRoomForId(roomImagePicker.roomId)

      if (room) room.setImages(null)

      _closeTimeoutId = setTimeout(_close, 500)
    }

    function _onRoomsUpdated () {

      if (!RoomsHelper.getRoomForId(roomImagePicker.roomId)) _close()
    }

    function _close () {

      clearTimeout(_closeTimeoutId)
      if (BasUtil.isFunction(roomImagePicker.close)) roomImagePicker.close()
    }

    function _onDestroy () {

      clearTimeout(_closeTimeoutId)
      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
