'use strict'

var macAddressUtil = require('@gidw/mac-address-util')
var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var Device = require('./device')

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function DoorPhoneGatewayDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._mac = BasUtil.isPNumber(device[P.MAC])
    ? device[P.MAC]
    : 0

  this._macStr = BasUtil.isPNumber(this._mac)
    ? macAddressUtil.convertToMac(this._mac)
    : ''

  this._ip = BasUtil.isNEString(device[P.IP])
    ? device[P.IP]
    : ''

  this._host = BasUtil.isNEString(device[P.HOST])
    ? device[P.HOST].trim()
    : ''

  this._port = BasUtil.isPNumber(device[P.PORT])
    ? device[P.PORT]
    : 5060

  this._dtmfDoor = ''
  this._dtmfLight = ''

  if (BasUtil.isObject(device[P.DTMF])) {

    if (BasUtil.isNEString(device[P.DTMF][P.DOOR])) {

      this._dtmfDoor = device[P.DTMF][P.DOOR].trim()
    }

    if (BasUtil.isNEString(device[P.DTMF][P.LIGHT])) {

      this._dtmfLight = device[P.DTMF][P.LIGHT].trim()
    }
  }
}

DoorPhoneGatewayDevice.prototype = Object.create(Device.prototype)
DoorPhoneGatewayDevice.prototype.constructor = DoorPhoneGatewayDevice

/**
 * @constant {string}
 */
DoorPhoneGatewayDevice.ST_SIEDLE = P.SIEDLE

/**
 * @constant {string}
 */
DoorPhoneGatewayDevice.ST_GIRA = P.GIRA

/**
 * @constant {string}
 */
DoorPhoneGatewayDevice.ST_RESIDIUM = P.RESIDIUM

/**
 * @name DoorPhoneGatewayDevice#mac
 * @type {number}
 * @readonly
 */
Object.defineProperty(DoorPhoneGatewayDevice.prototype, 'mac', {
  get: function () {
    return this._mac
  }
})

/**
 * @name DoorPhoneGatewayDevice#macStr
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneGatewayDevice.prototype, 'macStr', {
  get: function () {
    return this._macStr
  }
})

/**
 * @name DoorPhoneGatewayDevice#ip
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneGatewayDevice.prototype, 'ip', {
  get: function () {
    return this._ip
  }
})

/**
 * @name DoorPhoneGatewayDevice#host
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneGatewayDevice.prototype, 'host', {
  get: function () {
    return this._host
  }
})

/**
 * @name DoorPhoneGatewayDevice#port
 * @type {number}
 * @readonly
 */
Object.defineProperty(DoorPhoneGatewayDevice.prototype, 'port', {
  get: function () {
    return this._port
  }
})

/**
 * @name DoorPhoneGatewayDevice#dtmfDoor
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneGatewayDevice.prototype, 'dtmfDoor', {
  get: function () {
    return this._dtmfDoor
  }
})

/**
 * @name DoorPhoneGatewayDevice#dtmfLight
 * @type {string}
 * @readonly
 */
Object.defineProperty(DoorPhoneGatewayDevice.prototype, 'dtmfLight', {
  get: function () {
    return this._dtmfLight
  }
})

module.exports = DoorPhoneGatewayDevice
