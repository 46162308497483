'use strict'

angular
  .module('basalteApp')
  .factory('BasRoomBuilding', basRoomBuildingFactory)

function basRoomBuildingFactory () {

  /**
   * @constructor
   * @param {BasRoom} room
   * @param {number} order
   */
  function BasRoomBuilding (room, order) {
    /**
     * @type {string}
     */
    this.id = room.getBuildingId()

    /**
     * @type {number}
     */
    this.order = order

    /**
     * @type {Object<string, BasRoomFloor>}
     */
    this.floors = {}
  }

  return BasRoomBuilding
}
