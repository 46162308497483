[
  {
    "album": {
      "artist": "ADELE",
      "name": "21"
    },
    "artist": "ADELE",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/adele.jpg\",\"thumb\":\"img/demo/local_library/thumbs/adele.jpg\"}",
    "date": "2011-01-21T08:00:00Z",
    "file": "nas0/000Klaas/Music/Adele/21/01 Rolling In the Deep.m4a",
    "genre": "Pop",
    "length": 228,
    "prio": 0,
    "id": 0,
    "title": "Rolling In the Deep"
  },
  {
    "album": {
      "artist": "ADELE",
      "name": "21"
    },
    "artist": "ADELE",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/adele.jpg\",\"thumb\":\"img/demo/local_library/thumbs/adele.jpg\"}",
    "date": "2011-01-21T08:00:00Z",
    "file": "nas0/000Klaas/Music/Adele/21/02 Rumour Has It.m4a",
    "genre": "Pop",
    "length": 223,
    "prio": 0,
    "id": 1,
    "title": "Rumour Has It"
  },
  {
    "album": {
      "artist": "Adele",
      "name": "25"
    },
    "artist": "Adele",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/hello.jpg\",\"thumb\":\"img/demo/local_library/thumbs/hello.jpg\"}",
    "date": "2015-11-20T08:00:00Z",
    "file": "nas0/000Klaas/Music/Adele/25/01 Hello.m4a",
    "genre": "Pop",
    "length": 296,
    "prio": 0,
    "id": 2,
    "title": "Hello"
  },
  {
    "album": {
      "artist": "ADELE",
      "name": "Skyfall - Single"
    },
    "artist": "ADELE",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/skyfall.jpg\",\"thumb\":\"img/demo/local_library/thumbs/skyfall.jpg\"}",
    "date": "2012-10-05T07:00:00Z",
    "file": "nas0/000Klaas/Music/Adele/Skyfall - Single/01 Skyfall.m4a",
    "genre": "Pop",
    "length": 286,
    "prio": 0,
    "id": 3,
    "title": "Skyfall"
  },
  {
    "album": {
      "artist": "Birdy",
      "name": "Birdy"
    },
    "artist": "Birdy",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/birdy.jpg\",\"thumb\":\"img/demo/local_library/thumbs/birdy.jpg\"}",
    "date": "2011-11-04T07:00:00Z",
    "file": "nas0/000Klaas/Music/Birdy/Birdy/03 People Help the People.m4a",
    "genre": "Alternatief",
    "length": 257,
    "prio": 0,
    "id": 4,
    "title": "People Help the People"
  },
  {
    "album": {
      "artist": "Bob Sinclar",
      "name": "Rock This Party - EP"
    },
    "artist": "Bob Sinclar",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/bob.jpg\",\"thumb\":\"img/demo/local_library/thumbs/bob.jpg\"}",
    "date": "2006-08-21T07:00:00Z",
    "file": "nas0/000Klaas/Music/Bob Sinclar/Rock This Party - EP/01 Rock This Party (Radio Edit).m4a",
    "genre": "Pop",
    "length": 199,
    "prio": 0,
    "id": 5,
    "title": "Rock This Party (Radio Edit)"
  },
  {
    "album": {
      "artist": "Bruno Mars",
      "name": "Grenade - Single"
    },
    "artist": "Bruno Mars",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/grenade.jpg\",\"thumb\":\"img/demo/local_library/thumbs/grenade.jpg\"}",
    "date": "2010-09-28T07:00:00Z",
    "file": "nas0/000Klaas/Music/Bruno Mars/Grenade - Single/01 Grenade.m4a",
    "genre": "Pop",
    "length": 222,
    "prio": 0,
    "id": 6,
    "title": "Grenade"
  },
  {
    "album": {
      "artist": "Bruno Mars",
      "name": "Just the Way You Are - Single"
    },
    "artist": "Bruno Mars",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/brunoface.jpg\",\"thumb\":\"img/demo/local_library/thumbs/brunoface.jpg\"}",
    "date": "2010-07-20T07:00:00Z",
    "file": "nas0/000Klaas/Music/Bruno Mars/Just the Way You Are - Single/01 Just the Way You Are.m4a",
    "genre": "Pop",
    "length": 221,
    "prio": 0,
    "id": 7,
    "title": "Just the Way You Are"
  },
  {
    "album": {
      "artist": "Coldplay",
      "name": "A Head Full of Dreams"
    },
    "artist": "Coldplay",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/coldplay.jpg\",\"thumb\":\"img/demo/local_library/thumbs/coldplay.jpg\"}",
    "date": "2015-12-04T08:00:00Z",
    "file": "nas0/000Klaas/Music/Coldplay/A Head Full of Dreams/05 Adventure of a Lifetime.m4a",
    "genre": "Alternatief",
    "length": 264,
    "prio": 0,
    "id": 8,
    "title": "Adventure of a Lifetime"
  },
  {
    "album": {
      "artist": "Various Artists",
      "name": "Spanish Guitar"
    },
    "artist": "Spanish Guitars",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/guitars.jpg\",\"thumb\":\"img/demo/local_library/thumbs/guitars.jpg\"}",
    "date": "2008-12-08T08:00:00Z",
    "file": "nas0/000Klaas/Music/Compilations/Spanish Guitar/06 Jeux Interdits.m4a",
    "genre": "Wereld",
    "length": 158,
    "prio": 0,
    "id": 9,
    "title": "Jeux Interdits"
  },
  {
    "album": {
      "artist": "Various Artists",
      "name": "The Best of Bond... James Bond"
    },
    "artist": "Garbage",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/garbage.jpg\",\"thumb\":\"img/demo/local_library/thumbs/garbage.jpg\"}",
    "date": "2008-10-28T07:00:00Z",
    "file": "nas0/000Klaas/Music/Compilations/The Best of Bond... James Bond/21 The World Is Not Enough.m4a",
    "genre": "Soundtrack",
    "length": 237,
    "prio": 0,
    "id": 10,
    "title": "The World Is Not Enough"
  },
  {
    "album": {
      "artist": "Destiny's Child",
      "name": "#1's"
    },
    "artist": "Destiny's Child",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/destiny.jpg\",\"thumb\":\"img/demo/local_library/thumbs/destiny.jpg\"}",
    "date": "2003-05-19T07:00:00Z",
    "file": "nas0/000Klaas/Music/Destiny's Child/#1's/07 Lose My Breath.m4a",
    "genre": "R&B/Soul",
    "length": 213,
    "prio": 0,
    "id": 11,
    "title": "Lose My Breath"
  },
  {
    "album": {
      "artist": "Destiny's Child",
      "name": "#1's"
    },
    "artist": "Destiny's Child",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/destiny.jpg\",\"thumb\":\"img/demo/local_library/thumbs/destiny.jpg\"}",
    "date": "2003-05-19T07:00:00Z",
    "file": "nas0/000Klaas/Music/Destiny's Child/#1's/11 Bootylicious.m4a",
    "genre": "R&B/Soul",
    "length": 208,
    "prio": 0,
    "id": 12,
    "title": "Bootylicious"
  },
  {
    "album": {
      "artist": "Dev, The Cataracs & Far East Movement",
      "name": "Like a G6 (feat. Cataracs & Dev) - Single"
    },
    "artist": "Far East Movement",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/g6.jpg\",\"thumb\":\"img/demo/local_library/thumbs/g6.jpg\"}",
    "date": "2010-09-20T07:00:00Z",
    "file": "nas0/000Klaas/Music/Dev, The Cataracs & Far East Movement/Like a G6 (feat. Cataracs & Dev) - Single/01 Like a G6 (feat. Cataracs & Dev).m4a",
    "genre": "Pop",
    "length": 218,
    "prio": 0,
    "id": 13,
    "title": "Like a G6 (feat. Cataracs & Dev)"
  },
  {
    "album": {
      "artist": "Dire Straits",
      "name": "Sultans of Swing - The Very Best of Dire Straits"
    },
    "artist": "Dire Straits",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/straits.jpg\",\"thumb\":\"img/demo/local_library/thumbs/straits.jpg\"}",
    "date": "1998-11-10T00:00:00Z",
    "file": "nas0/000Klaas/Music/Dire Straits/Sultans of Swing - The Very Best of Dire Straits/05 Private Investigations.m4a",
    "genre": "Rock",
    "length": 351,
    "prio": 0,
    "id": 14,
    "title": "Private Investigations"
  },
  {
    "album": {
      "artist": "Ellie Goulding",
      "name": "Bright Lights (Deluxe Edition)"
    },
    "artist": "Ellie Goulding",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lights.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lights.jpg\"}",
    "date": "2010-01-01T08:00:00Z",
    "file": "nas0/000Klaas/Music/Ellie Goulding/Bright Lights (Deluxe Edition)/11 Lights (Single Version).m4a",
    "genre": "Pop",
    "length": 211,
    "prio": 0,
    "id": 15,
    "title": "Lights (Single Version)"
  },
  {
    "album": {
      "artist": "Ellie Goulding",
      "name": "Burn - Single"
    },
    "artist": "Ellie Goulding",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/burn.jpg\",\"thumb\":\"img/demo/local_library/thumbs/burn.jpg\"}",
    "date": "2013-01-01T08:00:00Z",
    "file": "nas0/000Klaas/Music/Ellie Goulding/Burn - Single/01 Burn.m4a",
    "genre": "Pop",
    "length": 231,
    "prio": 0,
    "id": 16,
    "title": "Burn"
  },
  {
    "album": {
      "artist": "Ellie Goulding",
      "name": "Delirium (Deluxe)"
    },
    "artist": "Ellie Goulding",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/mymind.jpg\",\"thumb\":\"img/demo/local_library/thumbs/mymind.jpg\"}",
    "date": "2015-11-06T08:00:00Z",
    "file": "nas0/000Klaas/Music/Ellie Goulding/Delirium (Deluxe)/05 On My Mind.m4a",
    "genre": "Pop",
    "length": 214,
    "prio": 0,
    "id": 17,
    "title": "On My Mind"
  },
  {
    "album": {
      "artist": "Emeli Sandé",
      "name": "Our Version of Events"
    },
    "artist": "Emeli Sandé",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/emeli.jpg\",\"thumb\":\"img/demo/local_library/thumbs/emeli.jpg\"}",
    "date": "2012-02-10T08:00:00Z",
    "file": "nas0/000Klaas/Music/Emeli Sandé/Our Version of Events/1-01 Heaven.m4a",
    "genre": "Pop",
    "length": 252,
    "prio": 0,
    "id": 18,
    "title": "Heaven"
  },
  {
    "album": {
      "artist": "Emma Bale",
      "name": "Fortune Cookie (feat. Milow) - Single"
    },
    "artist": "Emma Bale",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/emma.jpg\",\"thumb\":\"img/demo/local_library/thumbs/emma.jpg\"}",
    "date": "2015-11-20T08:00:00Z",
    "file": "nas0/000Klaas/Music/Emma Bale/Fortune Cookie (feat. Milow) - Single/01 Fortune Cookie (feat. Milow).m4a",
    "genre": "Pop",
    "length": 182,
    "prio": 0,
    "id": 19,
    "title": "Fortune Cookie (feat. Milow)"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/01 Whatever.m4a",
    "genre": "R&B",
    "length": 261,
    "prio": 0,
    "id": 20,
    "title": "Whatever"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1996",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/02 Don't Let Go (Love).m4a",
    "genre": "R&B",
    "length": 293,
    "prio": 0,
    "id": 21,
    "title": "Don't Let Go (Love)"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/03 Right Direction.m4a",
    "genre": "R&B",
    "length": 307,
    "prio": 0,
    "id": 22,
    "title": "Right Direction"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/04 Damn I Wanna Be Your Lover.m4a",
    "genre": "R&B",
    "length": 325,
    "prio": 0,
    "id": 23,
    "title": "Damn I Wanna Be Your Lover"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/05 Too Gone, Too Long.m4a",
    "genre": "R&B",
    "length": 283,
    "prio": 0,
    "id": 24,
    "title": "Too Gone, Too Long"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/06 You're All I Need.m4a",
    "genre": "R&B",
    "length": 217,
    "prio": 0,
    "id": 25,
    "title": "You're All I Need"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/07 Let It Flow.m4a",
    "genre": "R&B",
    "length": 339,
    "prio": 0,
    "id": 26,
    "title": "Let It Flow"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/08 Sitting By Heaven's Door.m4a",
    "genre": "R&B",
    "length": 275,
    "prio": 0,
    "id": 27,
    "title": "Sitting By Heaven's Door"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/09 Love Makes You Do Thangs.m4a",
    "genre": "R&B",
    "length": 268,
    "prio": 0,
    "id": 28,
    "title": "Love Makes You Do Thangs"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/10 What A Difference A Day Makes.m4a",
    "genre": "R&B",
    "length": 252,
    "prio": 0,
    "id": 29,
    "title": "What A Difference A Day Makes"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/11 Eyes Of A Child.m4a",
    "genre": "R&B",
    "length": 272,
    "prio": 0,
    "id": 30,
    "title": "Eyes Of A Child"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/12 Does Anybody Hear Me.m4a",
    "genre": "R&B",
    "length": 191,
    "prio": 0,
    "id": 31,
    "title": "Does Anybody Hear Me"
  },
  {
    "album": {
      "artist": "En Vogue",
      "name": "Ev3"
    },
    "artist": "En Vogue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/vogue.jpg\",\"thumb\":\"img/demo/local_library/thumbs/vogue.jpg\"}",
    "date": "1997",
    "file": "nas0/000Klaas/Music/En Vogue/Ev3/13 I've Got Your Gun.m4a",
    "genre": "R&B",
    "length": 257,
    "prio": 0,
    "id": 32,
    "title": "I've Got Your Gun"
  },
  {
    "album": {
      "artist": "Eric Prydz",
      "name": "Opus"
    },
    "artist": "Eric Prydz",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/eric.jpg\",\"thumb\":\"img/demo/local_library/thumbs/eric.jpg\"}",
    "date": "2016-02-05T08:00:00Z",
    "file": "nas0/000Klaas/Music/Eric Prydz/Opus/19 Opus.m4a",
    "genre": "Electronic",
    "length": 544,
    "prio": 0,
    "id": 33,
    "title": "Opus"
  },
  {
    "album": {
      "artist": "Faithless",
      "name": "Forever Faithless - The Greatest Hits"
    },
    "artist": "Faithless",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/faithless.jpg\",\"thumb\":\"img/demo/local_library/thumbs/faithless.jpg\"}",
    "date": "2005-05-14T07:00:00Z",
    "file": "nas0/000Klaas/Music/Faithless/Forever Faithless - The Greatest Hits/03 God Is a DJ.m4a",
    "genre": "Electronic",
    "length": 209,
    "prio": 0,
    "id": 34,
    "title": "God Is a DJ"
  },
  {
    "album": {
      "artist": "Hooverphonic",
      "name": "Badaboum - Single"
    },
    "artist": "Hooverphonic",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/badaboum.jpg\",\"thumb\":\"img/demo/local_library/thumbs/badaboum.jpg\"}",
    "date": "2015-11-06T08:00:00Z",
    "file": "nas0/000Klaas/Music/Hooverphonic/Badaboum - Single/01 Badaboum.m4a",
    "genre": "Pop",
    "length": 198,
    "prio": 0,
    "id": 35,
    "title": "Badaboum"
  },
  {
    "album": {
      "artist": "Hooverphonic",
      "name": "The Magnificent Tree"
    },
    "artist": "Hooverphonic",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/hoover.jpg\",\"thumb\":\"img/demo/local_library/thumbs/hoover.jpg\"}",
    "date": "2000-01-07T08:00:00Z",
    "file": "nas0/000Klaas/Music/Hooverphonic/The Magnificent Tree/02 Mad About You.m4a",
    "genre": "Rock",
    "length": 224,
    "prio": 0,
    "id": 36,
    "title": "Mad About You"
  },
  {
    "album": {
      "artist": "Hooverphonic",
      "name": "The Magnificent Tree"
    },
    "artist": "Hooverphonic",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/hoover.jpg\",\"thumb\":\"img/demo/local_library/thumbs/hoover.jpg\"}",
    "date": "2000-01-07T08:00:00Z",
    "file": "nas0/000Klaas/Music/Hooverphonic/The Magnificent Tree/04 Jackie Cane.m4a",
    "genre": "Rock",
    "length": 260,
    "prio": 0,
    "id": 37,
    "title": "Jackie Cane"
  },
  {
    "album": {
      "artist": "Jennifer Lopez",
      "name": "On the 6"
    },
    "artist": "Jennifer Lopez",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lopez.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lopez.jpg\"}",
    "date": "1999-06-01T07:00:00Z",
    "file": "nas0/000Klaas/Music/Jennifer Lopez/On the 6/05 Let's Get Loud.m4a",
    "genre": "Dance",
    "length": 239,
    "prio": 0,
    "id": 38,
    "title": "Let's Get Loud"
  },
  {
    "album": {
      "artist": "Johannes Genard",
      "name": "Honeybee - Single"
    },
    "artist": "Johannes Genard",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/johan.jpg\",\"thumb\":\"img/demo/local_library/thumbs/johan.jpg\"}",
    "date": "2016-01-12T08:00:00Z",
    "file": "nas0/000Klaas/Music/Johannes Genard/Honeybee - Single/01 Honeybee.m4a",
    "genre": "Pop",
    "length": 235,
    "prio": 0,
    "id": 39,
    "title": "Honeybee"
  },
  {
    "album": {
      "artist": "Jonas Blue",
      "name": "Fast Car (feat. Dakota) [Radio Edit] - Single"
    },
    "artist": "Jonas Blue",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/cars.jpg\",\"thumb\":\"img/demo/local_library/thumbs/cars.jpg\"}",
    "date": "2015-12-04T08:00:00Z",
    "file": "nas0/000Klaas/Music/Jonas Blue/Fast Car (feat. Dakota) [Radio Edit] - Single/01 Fast Car (feat. Dakota) [Radio Edit].m4a",
    "genre": "Dance",
    "length": 212,
    "prio": 0,
    "id": 40,
    "title": "Fast Car (feat. Dakota) [Radio Edit]"
  },
  {
    "album": {
      "artist": "Katy Perry",
      "name": "PRISM (Deluxe)"
    },
    "artist": "Katy Perry",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/roar.jpg\",\"thumb\":\"img/demo/local_library/thumbs/roar.jpg\"}",
    "date": "2013-01-01T08:00:00Z",
    "file": "nas0/000Klaas/Music/Katy Perry/PRISM (Deluxe)/01 Roar.m4a",
    "genre": "Pop",
    "length": 224,
    "prio": 0,
    "id": 41,
    "title": "Roar"
  },
  {
    "album": {
      "artist": "Katy Perry",
      "name": "Teenage Dream: The Complete Confection"
    },
    "artist": "Katy Perry",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/firework.jpg\",\"thumb\":\"img/demo/local_library/thumbs/firework.jpg\"}",
    "date": "2012-03-23T07:00:00Z",
    "file": "nas0/000Klaas/Music/Katy Perry/Teenage Dream_ The Complete Confection/04 Firework.m4a",
    "genre": "Pop",
    "length": 228,
    "prio": 0,
    "id": 42,
    "title": "Firework"
  },
  {
    "album": {
      "artist": "Kings of Leon",
      "name": "Only By the Night"
    },
    "artist": "Kings of Leon",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/sexonfire.jpg\",\"thumb\":\"img/demo/local_library/thumbs/sexonfire.jpg\"}",
    "date": "2008-09-19T07:00:00Z",
    "file": "nas0/000Klaas/Music/Kings of Leon/Only By the Night/03 Sex On Fire.m4a",
    "genre": "Alternatief",
    "length": 203,
    "prio": 0,
    "id": 43,
    "title": "Sex On Fire"
  },
  {
    "album": {
      "artist": "Lana Del Rey",
      "name": "Born to Die"
    },
    "artist": "Lana Del Rey",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lana.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lana.jpg\"}",
    "date": "2012-01-27T08:00:00Z",
    "file": "nas0/000Klaas/Music/Lana Del Rey/Born to Die/01 Born to Die.m4a",
    "genre": "Alternatief",
    "length": 286,
    "prio": 0,
    "id": 44,
    "title": "Born to Die"
  },
  {
    "album": {
      "artist": "Lana Del Rey",
      "name": "Born to Die"
    },
    "artist": "Lana Del Rey",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lana.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lana.jpg\"}",
    "date": "2012-01-27T08:00:00Z",
    "file": "nas0/000Klaas/Music/Lana Del Rey/Born to Die/04 Video Games (Remastered).m4a",
    "genre": "Alternatief",
    "length": 282,
    "prio": 0,
    "id": 45,
    "title": "Video Games (Remastered)"
  },
  {
    "album": {
      "artist": "Arctic Monkeys",
      "name": "AM"
    },
    "artist": "Arctic Monkeys",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/arcticmonkey.jpg\",\"thumb\":\"img/demo/local_library/thumbs/arcticmonkey.jpg\"}",
    "date": "2013-09-09T07:00:00Z",
    "file": "nas0/000Klaas/Music new/Arctic Monkeys/AM/01 Do I Wanna Know_.m4a",
    "genre": "Alternatief",
    "length": 272,
    "prio": 0,
    "id": 46,
    "title": "Do I Wanna Know?"
  },
  {
    "album": {
      "artist": "Mr. Soul",
      "name": "Jazz Tunes"
    },
    "artist": "Mr. Soul",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/mrjazz.jpg\",\"thumb\":\"img/demo/local_library/thumbs/mrjazz.jpg\"}",
    "date": "2013-09-14T07:00:00Z",
    "file": "nas0/safe_covers/Mr. Soul - Jazz Tunes.m4a",
    "genre": "Jazz",
    "length": 231,
    "prio": 0,
    "id": 47,
    "title": "Jazz Tunes"
  },
  {
    "album": {
      "artist": "Mademoiselle",
      "name": "Ballad Concerts"
    },
    "artist": "Mademoiselle",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/mademoiselle.jpg\",\"thumb\":\"img/demo/local_library/thumbs/mademoiselle.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Mademoiselle - Beautiful Ballad (live).mp3",
    "genre": "Pop",
    "length": 251,
    "prio": 0,
    "id": 48,
    "title": "Beautiful Ballad (live)"
  },
  {
    "album": {
      "artist": "Lisa Stansfield",
      "name": "Lisa Stansfield"
    },
    "artist": "Lisa Stansfield",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lisa_stansfield.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lisa_stansfield.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Lisa Stansfield - Never Gonna Fall.mp3",
    "genre": "Pop",
    "length": 305,
    "prio": 0,
    "id": 49,
    "title": "Never Gonna Fall"
  },
  {
    "album": {
      "artist": "Lisa Stansfield",
      "name": "Face Up"
    },
    "artist": "Lisa Stansfield",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lisa_faceup.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lisa_faceup.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Stansfield - Face Up.mp3",
    "genre": "Pop",
    "length": 269,
    "prio": 0,
    "id": 50,
    "title": "8-3-1"
  },
  {
    "album": {
      "artist": "Lisa Stansfield",
      "name": "Real Love"
    },
    "artist": "Lisa Stansfield",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lisa_reallove.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lisa_reallove.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Stansfield - Real Love.mp3",
    "genre": "Pop",
    "length": 269,
    "prio": 0,
    "id": 51,
    "title": "All Woman"
  },
  {
    "album": {
      "artist": "Lisa Stansfield",
      "name": "The Moment"
    },
    "artist": "Lisa Stansfield",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lisa_moment.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lisa_moment.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Lisa - Moment.mp3",
    "genre": "Pop",
    "length": 287,
    "prio": 0,
    "id": 52,
    "title": "If I Hadn't Got You"
  },
  {
    "album": {
      "artist": "Lisa Stansfield",
      "name": "Affection"
    },
    "artist": "Lisa Stansfield",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lisa_affection.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lisa_affection.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Lisa - Affection.mp3",
    "genre": "Pop",
    "length": 287,
    "prio": 0,
    "id": 53,
    "title": "Sincerity"
  },
  {
    "album": {
      "artist": "Mister Cover",
      "name": "Lena"
    },
    "artist": "Mister Cover",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lena_mister_cover.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lena_mister_cover.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Mister - Lena.mp3",
    "genre": "Pop",
    "length": 287,
    "prio": 0,
    "id": 54,
    "title": "Lena"
  },
  {
    "album": {
      "artist": "Lena",
      "name": "My Cassette Player"
    },
    "artist": "Lena",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lena_5.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lena_5.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Cassette - Lena.mp3",
    "genre": "Pop",
    "length": 234,
    "prio": 0,
    "id": 55,
    "title": "Satellite"
  },
  {
    "album": {
      "artist": "Lena",
      "name": "Lena (Version España)"
    },
    "artist": "Lena",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lena_4.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lena_4.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Sigueme - Lena.mp3",
    "genre": "Pop",
    "length": 218,
    "prio": 0,
    "id": 56,
    "title": "Sigueme"
  },
  {
    "album": {
      "artist": "Lena",
      "name": "Lena"
    },
    "artist": "Lena",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lena_3.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lena_3.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Viejos tiempos-Lena.mp3",
    "genre": "Pop",
    "length": 274,
    "prio": 0,
    "id": 57,
    "title": "Viejos tiempos"
  },
  {
    "album": {
      "artist": "2 Belgen",
      "name": "Essential"
    },
    "artist": "2 Belgen",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lena_2_belgen.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lena_2_belgen.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Essential-2-Belgen.mp3",
    "genre": "Pop",
    "length": 290,
    "prio": 0,
    "id": 58,
    "title": "Lena - 2003 Remastered Version"
  },
  {
    "album": {
      "artist": "2 Belgen",
      "name": "Lena - Single"
    },
    "artist": "2 Belgen",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lena_2.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lena_2.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Lena-2-Belgen.mp3",
    "genre": "Pop",
    "length": 221,
    "prio": 0,
    "id": 59,
    "title": "Lena"
  },
  {
    "album": {
      "artist": "2 Belgen",
      "name": "Lena - Remastered"
    },
    "artist": "2 Belgen",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lena_2_belgen_2.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lena_2_belgen_2.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Lena-2-Belgen_2.mp3",
    "genre": "Pop",
    "length": 221,
    "prio": 0,
    "id": 60,
    "title": "Lena"
  },
  {
    "album": {
      "artist": "Lena",
      "name": "Neon (Lonely People)"
    },
    "artist": "Lena",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lena_neon_1.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lena_neon_1.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Lena-neon-1-.mp3",
    "genre": "Pop",
    "length": 221,
    "prio": 0,
    "id": 61,
    "title": "Lena"
  },
  {
    "album": {
      "artist": "Lena",
      "name": "Neon (Lonely People) - Remastered"
    },
    "artist": "Lena",
    "coverart": "{\"cover\":\"img/demo/local_library/normal/lena_neon_2.jpg\",\"thumb\":\"img/demo/local_library/thumbs/lena_neon_2.jpg\"}",
    "date": "2013-09-15T10:00:00Z",
    "file": "nas0/safe_covers/Lena-neon-2-.mp3",
    "genre": "Pop",
    "length": 221,
    "prio": 0,
    "id": 62,
    "title": "Lena"
  }
]
