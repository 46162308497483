'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasCollection', [
    'BasString',
    basCollection
  ])

/**
 * @param BasString
 * @returns BasCollection
 */
function basCollection (
  BasString
) {
  /**
   * @constructor
   * @param {string} [titleTranslationId]
   */
  function BasCollection (titleTranslationId) {

    /**
     * Can be used to identify a collection
     *
     * @type {string}
     */
    this.id = ''

    /**
     * @type {BasString}
     */
    this.basTitle = new BasString()

    /**
     * @type {Array}
     */
    this.items = []

    if (BasUtil.isNEString(titleTranslationId)) {

      this.setTitleTranslationId(titleTranslationId)
    }
  }

  /**
   * @param {string} id
   */
  BasCollection.prototype.setId = function (id) {

    this.id = id
  }

  /**
   * @param {string} id
   */
  BasCollection.prototype.setTitleTranslationId = function (id) {

    this.basTitle.setKey(id)

    this.updateTranslation()
  }

  BasCollection.prototype.updateTranslation = function () {

    this.basTitle.updateTranslation()
  }

  /**
   * Returns a new array with a shallow copy of the items.
   *
   * @returns {Array}
   */
  BasCollection.prototype.cloneItems = function () {

    return this.items.slice()
  }

  /**
   * @returns {BasCollection}
   */
  BasCollection.prototype.clone = function () {

    var newCollection

    newCollection = new BasCollection()
    newCollection.title = this.title
    newCollection.titleTranslationId = this.titleTranslationId
    newCollection.basTitle = this.basTitle.clone()
    newCollection.items = this.cloneItems()

    return newCollection
  }

  return BasCollection
}
