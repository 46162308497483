'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basMediaFooter', [
    'BAS_HTML',
    basMediaFooterDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basMediaFooterDirective
 */
function basMediaFooterDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.mediaFooter,
    controller: [
      '$rootScope',
      '$element',
      'MEDIA_STATES',
      'BAS_STATE',
      'BAS_CURRENT_CORE',
      'BAS_ROOM',
      'BAS_SOURCE',
      'CurrentBasCore',
      'CurrentRoom',
      'BasUiQueue',
      'BasUiRemote',
      'BasMusicHelper',
      'BasVideoHelper',
      'BasMediaStateHelper',
      'SourcesHelper',
      'BasUtilities',
      controller
    ],
    controllerAs: 'mediaFootCtrl',
    bindToController: {
      basRoom: '<?',
      basSource: '<?',
      basMediaContext: '@'
    }
  }

  /**
   * @param $rootScope
   * @param $element
   * @param {MEDIA_STATES} MEDIA_STATES
   * @param {BAS_STATE} BAS_STATE
   * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
   * @param {BAS_ROOM} BAS_ROOM
   * @param {BAS_SOURCE} BAS_SOURCE
   * @param {CurrentBasCore} CurrentBasCore
   * @param {CurrentRoom} CurrentRoom
   * @param {BasUiQueue} BasUiQueue
   * @param {BasUiRemote} BasUiRemote
   * @param {BasMusicHelper} BasMusicHelper
   * @param {BasVideoHelper} BasVideoHelper
   * @param BasMediaStateHelper
   * @param {SourcesHelper} SourcesHelper
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    $element,
    MEDIA_STATES,
    BAS_STATE,
    BAS_CURRENT_CORE,
    BAS_ROOM,
    BAS_SOURCE,
    CurrentBasCore,
    CurrentRoom,
    BasUiQueue,
    BasUiRemote,
    BasMusicHelper,
    BasVideoHelper,
    BasMediaStateHelper,
    SourcesHelper,
    BasUtilities
  ) {
    var mediaFootCtrl = this

    var CSS_ENABLE_SOURCES = 'main-footer-sources-enabled'
    var CSS_ENABLE_LIBRARY = 'main-footer-library-enabled'
    var CSS_ENABLE_FAVOURITES = 'main-footer-favourites-enabled'

    var CSS_ALLOW_SOURCES = 'main-footer-sources-allowed'
    var CSS_ALLOW_FAVOURITES = 'main-footer-favourites-allowed'
    var CSS_ALLOW_LIBRARY = 'main-footer-library-allowed'

    var CSS_ACT_PLAYER = 'main-footer-player-active'
    var CSS_ACT_SOURCES = 'main-footer-sources-active'
    var CSS_ACT_FAVOURITES = 'main-footer-favourites-active'
    var CSS_ACT_LIBRARY = 'main-footer-library-active'

    var _listeners = []

    /**
     * @type {Object<string, boolean>}
     */
    var css = {}

    /**
     * @type {TCurrentBasCoreState}
     */
    var currentBasCoreState = CurrentBasCore.get()

    /**
     * @type {MEDIA_STATES}
     */
    mediaFootCtrl.MEDIA_STATES = MEDIA_STATES

    /**
     * @type {BAS_SOURCE}
     */
    mediaFootCtrl.BAS_SOURCE = BAS_SOURCE

    /**
     * @type {BasImageTrans}
     */
    mediaFootCtrl.mediaPlayerIconBit = BasMusicHelper.musicPlayerIconBit

    /**
     * @type {?BasSource}
     */
    mediaFootCtrl.contextBasSource = null

    /**
     * @type {boolean}
     */
    mediaFootCtrl.isVideoContext = false

    mediaFootCtrl.$onChanges = _onChanges
    mediaFootCtrl.$postLink = _onPostLink
    mediaFootCtrl.$onDestroy = _onDestroy

    mediaFootCtrl.select = select

    init()

    function init () {

      _syncIsVideo()

      _resetCss()

      _listeners.push($rootScope.$on(
        BAS_STATE.EVT_STATE_SUCCESS,
        _onStateChange
      ))
      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
        _onMusicConfig
      ))
      _listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_LISTENING_ROOMS_CHANGE,
        _onListeningRoomsChange
      ))
    }

    /**
     * @param {string} stateName
     */
    function select (stateName) {

      var basRoom, basSource, _state

      _state = BasMediaStateHelper.getHelper()

      basRoom = _getRoom()
      basSource = _getFooterSource()

      switch (stateName) {
        case MEDIA_STATES.PLAYER:

          if (_state.isCurrent(MEDIA_STATES.PLAYER)) {

            if (!mediaFootCtrl.isVideoContext) {

              BasUiQueue.toggle()

            } else {

              BasUiRemote.toggle()
            }

          } else {

            CurrentRoom.go(_state.get(stateName))
          }

          break
        case MEDIA_STATES.SOURCES:

          if (basRoom) {

            CurrentRoom.go(_state.get(stateName))
          }

          break
        case MEDIA_STATES.FAVOURITES:

          if (
            (
              basRoom &&
              basRoom.isSourcePlayerOrBarp()
            ) ||
            (
              basSource && (
                basSource.isAudioSource ||
                basSource.isVideoSource
              )
            )
          ) {
            CurrentRoom.go(_state.get(MEDIA_STATES.FAVOURITES))
          }

          break
        case MEDIA_STATES.LIBRARY:

          if (
            basRoom &&
            basRoom.isSourcePlayerOrBarp()
          ) {
            CurrentRoom.go(
              _state.get(MEDIA_STATES.LIBRARY),
              null,
              {
                custom: {
                  goToLastLibraryState: true
                }
              }
            )
          }

          break
        default:

          CurrentRoom.go(_state.get(stateName))
      }
    }

    function _onChanges (changes) {

      if (changes.basMediaContext) _syncIsVideo()
      if (changes.basRoom) _onRoomChange()
      if (changes.basSource) _onSourceChange()

      _syncCss()
    }

    function _onPostLink () {

      _syncState()
      _syncCss()
    }

    function _onStateChange () {

      _syncState()
      _syncCss()
    }

    function _onMusicConfig () {

      _syncCssRoom()
      _syncCss()
    }

    function _onListeningRoomsChange () {

      _syncCssRoom()
      _syncCss()
    }

    function _onRoomChange () {

      _syncCssRoom()
      _syncCss()
    }

    function _onSourceChange () {

      _synccontextBasSource()
      _syncCssSource()
      _syncCss()
    }

    function _syncState () {

      var _state

      _state = BasMediaStateHelper.getHelper()

      _resetActCss()

      css[CSS_ACT_PLAYER] = _state.isCurrent(MEDIA_STATES.PLAYER)
      css[CSS_ACT_SOURCES] = _state.isCurrent(MEDIA_STATES.SOURCES)
      css[CSS_ACT_FAVOURITES] = _state.isCurrent(MEDIA_STATES.FAVOURITES)
      css[CSS_ACT_LIBRARY] = _state.isCurrent(MEDIA_STATES.LIBRARY)
    }

    /**
     * @private
     */
    function _syncCssRoom () {

      var basRoomMusic, basRoomVideo, type, basRoom

      basRoom = mediaFootCtrl.basRoom

      if (basRoom) {
        if (basRoom.hasVideo()) basRoomVideo = basRoom.video
        if (basRoom.hasMusic()) basRoomMusic = basRoom.music
      }

      type = mediaFootCtrl.isVideoContext
        ? basRoomVideo ? basRoomVideo.type : null
        : basRoomMusic ? basRoomMusic.type : null

      // Room music type determines which features should be ENABLED

      switch (type) {

        case BAS_ROOM.MUSIC_T_SONOS:

          css[CSS_ENABLE_LIBRARY] = false
          css[CSS_ENABLE_SOURCES] = false
          css[CSS_ENABLE_FAVOURITES] = true

          break

        case BAS_ROOM.MUSIC_T_ASANO:
        case BAS_ROOM.MUSIC_T_AVR:

          css[CSS_ENABLE_SOURCES] = basRoom && basRoom.isSourceGroup()
            ? false
            : CurrentBasCore.hasCore()
              ? !currentBasCoreState.core.core.hasSingleSource()
              : true
          css[CSS_ENABLE_LIBRARY] = true
          css[CSS_ENABLE_FAVOURITES] = true

          break

        case BAS_ROOM.VIDEO_T_ASANO:

          css[CSS_ENABLE_LIBRARY] = false
          css[CSS_ENABLE_SOURCES] = mediaFootCtrl.isVideoContext
            ? basRoomVideo && basRoomVideo.getCompatibleSources().length > 1
            : basRoomMusic && basRoomMusic.getCompatibleSources().length > 1
          css[CSS_ENABLE_FAVOURITES] = true

          break

        default:

          // Unknown type, allow all for debugging purposes
          css[CSS_ENABLE_LIBRARY] = true
          css[CSS_ENABLE_SOURCES] = true
          css[CSS_ENABLE_FAVOURITES] = true

          break
      }

      _syncCssSource()
    }

    /**
     * @private
     */
    function _syncCssSource () {

      var basSource, basRoom, isSourcePlayerOrBarp

      basSource = _getFooterSource()
      basRoom = _getRoom()

      // Source determines which features should be ALLOWED

      isSourcePlayerOrBarp = basSource ? basSource.isPlayerOrBarp() : false

      css[CSS_ALLOW_FAVOURITES] = false
      css[CSS_ALLOW_LIBRARY] = false
      css[CSS_ALLOW_SOURCES] = false

      if (basRoom && basRoom.isInactiveSourceGroup()) {

        css[CSS_ALLOW_FAVOURITES] = false
        css[CSS_ALLOW_LIBRARY] = false
        css[CSS_ALLOW_SOURCES] = false

      } else if (basSource) {

        if (basSource.isVideoSource) {

          css[CSS_ALLOW_FAVOURITES] = basSource.canListFavourites
          css[CSS_ALLOW_SOURCES] = true

        } else if (basSource.isAudioSource) {

          css[CSS_ALLOW_FAVOURITES] = basSource.canListFavourites
          css[CSS_ALLOW_LIBRARY] = basSource.supportsLibrary
          css[CSS_ALLOW_SOURCES] = true

        } else {

          css[CSS_ALLOW_FAVOURITES] = isSourcePlayerOrBarp
          css[CSS_ALLOW_LIBRARY] = isSourcePlayerOrBarp
          css[CSS_ALLOW_SOURCES] = true
        }

      } else {

        // Does this ever happen? or do we have source with type empty
        // TODO: based on capability
        css[CSS_ALLOW_SOURCES] = true
      }
    }

    function _syncIsVideo () {

      mediaFootCtrl.isVideoContext =
        mediaFootCtrl.basMediaContext === BAS_ROOM.K_VIDEO

      mediaFootCtrl.mediaPlayerIconBit = mediaFootCtrl.isVideoContext
        ? BasVideoHelper.videoMainBit
        : BasMusicHelper.musicPlayerIconBit

      _synccontextBasSource()
    }

    function _synccontextBasSource () {

      var basSource

      basSource = _getSource()

      // Actual source must has same type as context

      mediaFootCtrl.contextBasSource = (
        basSource &&
        basSource.isVideoSource === mediaFootCtrl.isVideoContext
      )
        ? basSource
        : null
    }

    function _syncCss () {

      var el

      el = $element[0]

      if (el) el.className = BasUtilities.generateClassName(el.className, css)
    }

    /**
     * @private
     * @returns {?BasRoom}
     */
    function _getRoom () {

      var room

      room = mediaFootCtrl.basRoom

      return (room && room.isRoom) ? room : null
    }

    /**
     * @private
     * @returns {?BasSource}
     */
    function _getSource () {

      var source

      source = mediaFootCtrl.basSource

      return (source && source.play) ? source : null
    }

    /**
     * @private
     * @returns {?BasSource}
     */
    function _getFooterSource () {

      var defaultSource, basSource, basRoom

      basRoom = _getRoom()

      // Sonos: Use Sonos room's default source so the user can always go back
      //  to Sonos playback (since there is no source selection to go out of tv
      //  playback).
      if (
        basRoom &&
        basRoom.music &&
        basRoom.music.type === BAS_ROOM.MUSIC_T_SONOS
      ) {

        defaultSource = basRoom.music.getDefaultSource()

        if (defaultSource) {

          basSource = SourcesHelper.getAudioSource(defaultSource)

          if (basSource) return basSource
        }
      }

      // Else: use contextBasSource
      return mediaFootCtrl.contextBasSource
    }

    function _resetActCss () {

      css[CSS_ACT_PLAYER] = false
      css[CSS_ACT_SOURCES] = false
      css[CSS_ACT_FAVOURITES] = false
      css[CSS_ACT_LIBRARY] = false
    }

    function _resetAllowCss () {

      css[CSS_ALLOW_SOURCES] = false
      css[CSS_ALLOW_FAVOURITES] = false
      css[CSS_ALLOW_LIBRARY] = false
    }

    function _resetCss () {

      css[CSS_ENABLE_SOURCES] = false
      css[CSS_ENABLE_FAVOURITES] = false
      css[CSS_ENABLE_LIBRARY] = false

      _resetAllowCss()
      _resetActCss()
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
