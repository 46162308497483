'use strict'

var BasUtil = require('@basalte/bas-util')

var Device = require('./device')
var ConnectedDevice = require('./connected_device')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

/**
 * @typedef {Object} TServerError
 * @property {number} cid
 * @property {string} component
 * @property {string} error
 * @property {ServerDevice.ERR_LVL} lvl
 */

/**
 * @constructor
 * @extends ConnectedDevice
 * @param {TDevice} device
 * @param {BasCore} basCore
 * @since 2.0.0
 */
function ServerDevice (device, basCore) {

  ConnectedDevice.call(this, device, basCore)

  /**
   * @private
   * @type {string}
   */
  this._ip = BasUtil.isNEString(device[P.IP]) ? device[P.IP] : ''

  /**
   * @private
   * @type {TServerError[]}
   */
  this._errors = Array.isArray(device[P.ERRORS])
    ? device[P.ERRORS]
    : []

  /**
   * @private
   * @type {number}
   */
  this._updateStatus = ServerDevice.UPDATE_STATUS.NONE

  // TODO Check update status on constructor device

  this._parse = this.parse.bind(this)
  this._handleStatus = this._onStatus.bind(this)
}

ServerDevice.prototype = Object.create(ConnectedDevice.prototype)
ServerDevice.prototype.constructor = ServerDevice

// region Events

/**
 * Server errors have been updated
 *
 * @event ServerDevice#EVT_ERRORS
 * @param {TServerError[]} errors
 */

/**
 * IP address has been changed
 *
 * @event ServerDevice#EVT_IP
 * @param {string} ip
 */

/**
 * Version has been updated
 *
 * @event ServerDevice#EVT_VERSION
 * @param {?BasUtil.BasVersion} version
 */

/**
 * @event ServerDevice#EVT_UPDATE_STATUS
 * @param {string} updateStatus
 */

// endregion

/**
 * @constant {string}
 */
ServerDevice.EVT_ERRORS = 'evtServerDeviceErrors'

/**
 * @constant {string}
 */
ServerDevice.EVT_IP = 'evtServerDeviceIp'

/**
 * @constant {string}
 */
ServerDevice.EVT_VERSION = 'evtServerDeviceVersion'

/**
 * @constant {string}
 */
ServerDevice.EVT_UPDATE_STATUS = 'evtServerDeviceUpdateStatus'

/**
 * @readonly
 * @enum {number}
 */
ServerDevice.ERR_LVL = {
  NO_ERROR: 0,
  WARNING: 1,
  ERROR: 2,
  FATAL: 3
}

/**
 * Reverse enum
 *
 * @readonly
 * @enum {string}
 */
ServerDevice.ERR_LVL_R = BasUtil.switchObjectKeyValue(ServerDevice.ERR_LVL)

/**
 * @readonly
 * @enum {number}
 * @since 2.0.2
 */
ServerDevice.UPDATE_STATUS = {
  NONE: 0,
  CHECKING: 1,
  DOWNLOADING: 2,
  READY: 3
}

/**
 * @name ServerDevice#ip
 * @type {string}
 * @readonly
 */
Object.defineProperty(ServerDevice.prototype, 'ip', {
  get: function () {
    return this._ip
  }
})

/**
 * @name ServerDevice#errors
 * @type {TServerError[]}
 * @readonly
 */
Object.defineProperty(ServerDevice.prototype, 'errors', {
  get: function () {
    return this._errors
  }
})

/**
 * @name ServerDevice#updateStatus
 * @type {ServerDevice.UPDATE_STATUS}
 * @readonly
 * @since 2.0.2
 */
Object.defineProperty(ServerDevice.prototype, 'updateStatus', {
  get: function () {
    return this._updateStatus
  }
})

/**
 * @name ServerDevice#hasUpdate
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(ServerDevice.prototype, 'hasUpdate', {
  get: function () {
    return this._updateStatus === ServerDevice.UPDATE_STATUS.READY
  }
})

/**
 * @name ServerDevice#checkingForUpdates
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(ServerDevice.prototype, 'checkingForUpdates', {
  get: function () {
    return this._updateStatus === ServerDevice.UPDATE_STATUS.CHECKING
  }
})

/**
 * @name ServerDevice#downloadingUpdates
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(ServerDevice.prototype, 'downloadingUpdates', {
  get: function () {
    return this._updateStatus === ServerDevice.UPDATE_STATUS.DOWNLOADING
  }
})

/**
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
ServerDevice.prototype.parse = function (msg, options) {

  var _valid, _emit

  _emit = true
  _valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) _emit = options.emit
  }

  if (_valid) {

    if (BasUtil.isObject(msg[P.STATE])) {

      this._parseState(msg[P.STATE], _emit)

    } else {

      this._parseState(msg, _emit)
    }

    // Version
    if (BasUtil.isNEString(msg[P.VERSION]) &&
      (
        !this._version ||
        this._version.text !== msg[P.VERSION]
      )) {

      this._version = new BasUtil.BasVersion(msg[P.VERSION])
      if (_emit) this.emit(ServerDevice.EVT_VERSION, this._version)
    }

    // IP address
    if (BasUtil.isNEString(msg[P.IP]) &&
      msg[P.IP] !== this._ip) {

      this._ip = msg[P.IP]
      if (_emit) this.emit(ServerDevice.EVT_IP, this._ip)
    }
  }

  return _valid
}

/**
 * Parser for errors and updates states
 *
 * @private
 * @param {Object} obj
 * @param {boolean} emit
 */
ServerDevice.prototype._parseState = function (obj, emit) {

  var _oldUpdateStatus

  _oldUpdateStatus = this._updateStatus

  // Errors
  if (Array.isArray(obj[P.ERRORS])) {

    this._errors = obj[P.ERRORS]
    if (emit) this.emit(ServerDevice.EVT_ERRORS, this._errors)
  }

  // Update status
  switch (obj[P.UPDATE]) {
    case P.NONE:
      this._updateStatus = ServerDevice.UPDATE_STATUS.NONE
      break
    case P.CHECKING:
      this._updateStatus = ServerDevice.UPDATE_STATUS.CHECKING
      break
    case P.DOWNLOADING:
      this._updateStatus = ServerDevice.UPDATE_STATUS.DOWNLOADING
      break
    case P.READY:
      this._updateStatus = ServerDevice.UPDATE_STATUS.READY
      break
  }

  if (_oldUpdateStatus !== this._updateStatus) {

    if (emit) {

      this.emit(ServerDevice.EVT_UPDATE_STATUS, this._updateStatus)
    }
  }
}

/**
 * Requests the server status
 *
 * @returns {Promise}
 */
ServerDevice.prototype.status = function () {

  var msg

  if (this._basCore) {

    msg = this._getBasCoreMessage()
    msg[P.DEVICE][P.ACTION] = P.STATUS

    return this._basCore.requestRetry(msg).then(this._handleStatus)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @private
 * @param {Object} result
 * @returns {*}
 */
ServerDevice.prototype._onStatus = function (result) {

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.DEVICE])) {

    return this._parse(result[P.DEVICE])
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

module.exports = ServerDevice
