'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasConnectInfo', [
    'BAS_API',
    'BAS_CONNECT',
    'BAS_CORE_CLIENT',
    'BAS_DEMO',
    'BasAppDevice',
    'BasServerStorage',
    'BasUtilities',
    basConnectInfoFactory
  ])

/**
 * @typedef {Object} TBasConnectServer
 * @property {string} type local, remote, demo
 * @property {(string|number)} [macAddress]
 * @property {string} [address]
 * @property {string} [cid]
 */

/**
 * @typedef {Object} TBasConnectCredentials
 * @property {string} username
 * @property {string} [password]
 * @property {string} [credentialHash]
 */

/**
 * @typedef {Object} TBasConnectOptions
 * @property {number} [coreClientDeviceMac]
 * @property {Object} [demo] Demo configuration data
 * @property {boolean} [noLogin] Don't actually login, only setup connection
 */

/**
 * @param BAS_API
 * @param {BAS_CONNECT} BAS_CONNECT
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 * @param {BAS_DEMO} BAS_DEMO
 * @param {BasAppDevice} BasAppDevice
 * @param {BasServerStorage} BasServerStorage
 * @param {BasUtilities} BasUtilities
 * @returns BasConnectInfo
 */
function basConnectInfoFactory (
  BAS_API,
  BAS_CONNECT,
  BAS_CORE_CLIENT,
  BAS_DEMO,
  BasAppDevice,
  BasServerStorage,
  BasUtilities
) {

  /**
   * @constructor
   */
  function BasConnectInfo () {

    /**
     * @type {string}
     */
    this.id = ''

    /**
     * @type {string}
     */
    this.type = BAS_CONNECT.T_LOCAL

    /**
     * @type {TBasConnectServer}
     */
    this.server = {
      type: BAS_CONNECT.T_LOCAL
    }

    /**
     * @type {?TBasConnectCredentials}
     */
    this.credentials = null

    /**
     * @type {?TBasConnectOptions}
     */
    this.options = null

    /**
     * @type {?BasServer}
     */
    this.basServer = null

    /**
     * @type {string}
     */
    this.derivedProjectName = ''
  }

  /**
   * @param {TBasConnectServer} basConnectServer
   * @param {boolean} [includeType = true]
   * @returns {string}
   */
  BasConnectInfo.getBasConnectServerId = function (
    basConnectServer,
    includeType
  ) {
    var id, value, _includeType

    _includeType = BasUtil.isBool(includeType) ? includeType : true

    id = ''

    if (_includeType) id += basConnectServer.type

    if (basConnectServer.macAddress) {
      value = BasUtilities.getMacNumber(basConnectServer.macAddress)
      if (BasUtil.isPNumber(value)) id += 'mac:' + value
    }

    if (basConnectServer.address) id += 'address:' + basConnectServer.address
    if (basConnectServer.cid) id += 'cid:' + basConnectServer.cid

    return id
  }

  /**
   * @param {(
   * BasServer |
   * BasLiveProject |
   * BasStoredServer |
   * BasDiscoveredCore |
   * TBasConnectServer |
   * TBasConnectOptions |
   * string
   * )} serverInfo
   * @returns {?BasConnectInfo}
   */
  BasConnectInfo.build = function (
    serverInfo
  ) {
    var result, value, value2, valid

    valid = false

    result = new BasConnectInfo()

    if (BasAppDevice.isCoreClient()) {

      value = BAS_CORE_CLIENT.STATE.macNum

      if (value) {

        result.options = {}
        result.options.coreClientDeviceMac = value
      }
    }

    if (BasUtil.isObject(serverInfo)) {

      if (serverInfo instanceof BAS_API.BasServer) {

        // BasConnectServer

        /**
         * @type {TBasConnectServer}
         */
        value = {
          type: ''
        }

        // Configuration ID
        value2 = serverInfo.cid
        if (value2) value.cid = value2

        // MAC address

        value2 = serverInfo.macN
        if (value2) value.macAddress = value2

        // Address

        value2 = serverInfo.host
        if (value2 && value2.host) value.address = value2.host

        result.server = value

        // BasConnectCredentials

        value2 = serverInfo.credentials

        if (value2) {

          /**
           * @type {TBasConnectCredentials}
           */
          value = {
            username: ''
          }

          value.username = value2.user
          value.credentialHash = value2.credentialHash

          result.credentials = value
        }

        if (serverInfo instanceof BAS_API.BasHttpServer) {

          valid = true

          result.type = result.server.type = BAS_CONNECT.T_LOCAL

        } else if (serverInfo instanceof BAS_API.BasRemoteServer) {

          valid = true

          result.type = result.server.type = BAS_CONNECT.T_REMOTE

        } else if (serverInfo instanceof BAS_API.BasDemoServer) {

          valid = true

          result.type = result.server.type = BAS_CONNECT.T_DEMO

          value = serverInfo.demo

          if (value) {

            value2 = value.data

            if (value2) {

              if (!result.options) result.options = {}

              result.options.demo = value2
            }
          }

          if (!result.options || !result.options.demo) {

            if (!result.options) result.options = {}

            result.options.demo = BAS_DEMO.DATA
          }
        }

      } else if (serverInfo.toStorageData) {

        // "serverInfo" is BasStoredServer instance

        valid = true

        value = serverInfo.getBasConnectServer()

        result.type = value.type
        result.server = value

        value2 = BasServerStorage
          .getServerSettingsForStoredServer(serverInfo)

        if (value2) {

          result.credentials =
            value2.getBasConnectCredentialsLastUser()
        }

        if (serverInfo.isDemo()) {

          if (!result.options) result.options = {}

          result.options.demo = BAS_DEMO.DATA
        }

      } else if (serverInfo.setId) {

        // "serverInfo" is BasDiscoveredCore instance

        valid = true

        result.type = BAS_CONNECT.T_LOCAL
        result.server = serverInfo.getBasConnectServer()

        result.derivedProjectName = serverInfo.projectName

      } else if (BasUtil.isNEString(serverInfo.address)) {

        // "serverInfo" is TBasConnectServer instance

        valid = true

        result.type = serverInfo.type
          ? serverInfo.type
          : BAS_CONNECT.T_LOCAL
        result.server = {
          type: result.type,
          address: serverInfo.address
        }

        if (serverInfo.macAddress) {

          result.server.macAddress = serverInfo.macAddress
        }

        if (serverInfo.cid) {

          result.server.cid = serverInfo.cid
        }

      } else if (BasUtil.isObject(serverInfo.demo)) {

        // "serverInfo" is TBasConnectOptions instance with demo data

        valid = true

        result.type = BAS_CONNECT.T_DEMO

        if (!result.options) result.options = {}

        result.options.demo = serverInfo.demo

        result.server = {
          type: BAS_CONNECT.T_DEMO,
          cid: BAS_API.Demo.DEMO_CID,
          macAddress: BAS_API.Demo.DEMO_MAC_N,
          address: BAS_API.Demo.DEMO_HOST
        }

      } else if (BasUtil.isNEString(serverInfo.uuid)) {

        // "serverInfo" is BasLiveProject

        valid = true

        result.type = BAS_CONNECT.T_REMOTE
        result.server = {
          type: BAS_CONNECT.T_REMOTE,
          cid: serverInfo.uuid
        }
      }

    } else if (BasUtil.isNEString(serverInfo)) {

      // "serverInfo" is an address

      value = serverInfo.indexOf(BAS_CONNECT.PREFIX_LIVE_PROJECT)

      if (value === 0) {

        value2 = serverInfo.substring(BAS_CONNECT.PREFIX_LIVE_PROJECT.length)

        if (BasUtil.isNEString(value2)) {

          valid = true

          result.type = BAS_CONNECT.T_REMOTE
          result.server = {
            type: BAS_CONNECT.T_REMOTE,
            cid: value2
          }
        }

      } else {

        valid = true

        result.type = BAS_CONNECT.T_LOCAL
        result.server = {
          type: BAS_CONNECT.T_LOCAL,
          address: serverInfo
        }
      }

      const serverSettings = BasServerStorage
        .getServerSettingsForAddress(serverInfo)

      if (serverSettings) {

        result.credentials = serverSettings.getBasConnectCredentialsLastUser()
      }
    }

    if (valid) {

      result.generateId()

      return result
    }

    return null
  }

  /**
   * Updates the ID for this BasConnectInfo instance
   */
  BasConnectInfo.prototype.generateId = function () {

    var id

    id = '' + this.type

    // Server

    id += BasConnectInfo.getBasConnectServerId(this.server, false)

    // Credentials

    if (this.credentials) {

      if (this.credentials.username) {

        id += 'user:' + this.credentials.username
      }
    }

    // Options

    if (this.options) {

      if (this.options.coreClientDeviceMac) {

        id += 'deviceMac:' + this.options.coreClientDeviceMac
      }

      if (this.options.demo) id += 'demo'
    }

    this.id = id
  }

  /**
   * @param {?TBasConnectCredentials} credentials
   */
  BasConnectInfo.prototype.setCredentials = function (
    credentials
  ) {
    this.credentials = BasUtil.copyObjectShallow(credentials)
    this.generateId()
  }

  /**
   * @param {string} username
   * @param {string} password
   */
  BasConnectInfo.prototype.setCredentialsFrom = function (
    username,
    password
  ) {
    if (BasUtil.isNEString(username)) {

      if (!this.credentials) this.credentials = {}

      this.credentials.username = username

      if (BasUtil.isNEString(password)) {

        this.credentials.password = password
      }
    }
  }

  return BasConnectInfo
}
