'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('genericDevicesSceneCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_INPUT_MODAL',
    'BasGenericDeviceV2',
    'BasSceneHelper',
    'BasInputModal',
    genericDevicesSceneCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param BasGenericDeviceV2
 * @param {BasSceneHelper} BasSceneHelper
 * @param {BasInputModal} BasInputModal
 */
function genericDevicesSceneCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_INPUT_MODAL,
  BasGenericDeviceV2,
  BasSceneHelper,
  BasInputModal
) {
  var devices = this

  var _oldState = BasSceneHelper.copyState($uiRouterGlobals)
  var _listeners = []

  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  devices.$uiRouterGlobals = $uiRouterGlobals
  devices.liveState = false

  /**
   * @type {(BasGenericDeviceV1[] | BasGenericDeviceV2[])}
   */
  devices.devices = []

  devices.liveToggle = liveToggle
  devices.selectControl = selectControl
  devices.addToStep = addToStep
  devices.editControlValue = editControlValue

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_GENERIC_DEVICES_INITIALIZED,
      _onDevices
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _clearDevices
    ))

    _syncDevices()
  }

  /**
   * Toggle live state on every device
   * Adds all controls to the step if live state is true
   */
  function liveToggle () {

    var length, i, device

    devices.liveState = !devices.liveState

    length = devices.devices.length
    for (i = 0; i < length; i++) {

      device = devices.devices[i]

      if (BasUtil.isObject(device) &&
        BasUtil.isFunction(device.setSyncMode)) {

        device.setSyncMode(devices.liveState)

        if (devices.liveState) {

          _deviceAddControlsToStep(device)
        }

        device.removeNonEditableControls()
      }
    }
  }

  /**
   * @param {BasGenericDeviceControl} control
   */
  function selectControl (control) {

    _toggleOnStep(control)
  }

  /**
   * @param {BasGenericDeviceControl} control
   */
  function addToStep (control) {

    _addToStep(control)
    $rootScope.$applyAsync()
  }

  /**
   * @param {(BasGenericDeviceV1 | BasGenericDeviceV2)} device
   * @param {BasGenericDeviceControl} control
   */
  function editControlValue (device, control) {

    if (
      control &&
      control.type === BAS_API.GenericDeviceControl.T_NUMERIC_INPUT
    ) {

      BasInputModal.show(
        BAS_INPUT_MODAL.T_NUMBER,
        {
          title: device.name,
          subTitle: control.name,
          min: control.min,
          max: control.max,
          step: control.step,
          validCheck: _isValueValid
        }
      ).then(_onModal)
    }

    function _onModal (modal) {

      modal.close.then(_onModalClose)
    }

    function _onModalClose (result) {

      var number

      if (BasUtil.isNEString(result)) {

        number = parseFloat(result)

        if (!isNaN(number)) {

          control.setValue(number)
          _addToStep(control)
        }
      }
    }

    /**
     * @private
     * @param value
     * @returns {boolean}
     */
    function _isValueValid (value) {

      var number

      number = parseFloat(value)

      return (
        BasUtil.isVNumber(number) &&
        number >= control.min &&
        number <= control.max
      )
    }
  }

  /**
   * @private
   * @param {Object} _evt
   * @param {string} roomId
   */
  function _onDevices (_evt, roomId) {

    var _step, _room

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.room) _room = rooms.rooms[_step.room]

    if (_room && _room.id === roomId) _syncDevices()
  }

  function _syncDevices () {

    var _step, _room, length, i, device

    _clearDevices()

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.room) _room = rooms.rooms[_step.room]

    if (
      _room &&
      _room.genericDevices &&
      _room.genericDevices.createEditableClones
    ) {
      devices.devices = _room.genericDevices.createEditableClones()

      length = devices.devices.length
      for (i = 0; i < length; i++) {

        device = devices.devices[i]

        // "ng-model" could create object that is not a
        // BasGenericDeviceControl
        if (device && BasUtil.isFunction(device.setSyncMode)) {

          device.setOnlyInputControls(true)
          device.setSyncMode(devices.liveState)
        }

        _syncControls(_step, device)
      }
    }
  }

  /**
   * @private
   * @param {BasStep} step
   * @param {(BasGenericDeviceV1 | BasGenericDeviceV2)} device
   */
  function _syncControls (step, device) {

    var length, i, controls, control

    if (step && BasUtil.isObject(device)) {

      controls = device instanceof BasGenericDeviceV2
        ? device.categories.flatMap(category => category.controls)
        : device.controls

      if (Array.isArray(controls)) {

        length = controls.length
        for (i = 0; i < length; i++) {

          control = controls[i]

          // "ng-model" could create object that is not a
          // BasGenericDeviceControl
          if (BasUtil.isObject(control) &&
            BasUtil.isNEString(control.uuid) &&
            BasUtil.isFunction(control.syncBasGenericDeviceControl)
          ) {
            control.syncBasGenericDeviceControl(
              step.controls[control.uuid]
            )
          }
        }
      }

      control = device.singleControl

      // "ng-model" could create object that is not a
      // BasGenericDeviceControl
      if (BasUtil.isObject(control) &&
        BasUtil.isNEString(control.uuid) &&
        BasUtil.isFunction(control.syncBasGenericDeviceControl)) {

        control.syncBasGenericDeviceControl(
          step.controls[control.uuid]
        )
      }
    }
  }

  function _clearDevices () {

    var length, i, device

    length = devices.devices.length
    for (i = 0; i < length; i++) {

      device = devices.devices[i]

      // "ng-model" could create object that is not a
      // BasGenericDeviceControl
      if (BasUtil.isObject(device) &&
        BasUtil.isFunction(device.clear)) {

        device.clear()
      }
    }

    devices.devices = []
  }

  /**
   * @private
   * @param {(BasGenericDeviceV1 | BasGenericDeviceV2)} device
   */
  function _deviceAddControlsToStep (device) {

    var length, i, controls, control

    if (BasUtil.isObject(device)) {

      controls = device.controls

      if (Array.isArray(controls)) {

        length = controls.length
        for (i = 0; i < length; i++) {

          control = controls[i]

          // "ng-model" could create object that is not a
          // BasGenericDeviceControl
          if (BasUtil.isObject(control) &&
            BasUtil.isNEString(control.uuid)) {

            _addToStep(control)
          }
        }
      }

      control = device.singleControl

      // "ng-model" could create object that is not a
      // BasGenericDeviceControl
      if (BasUtil.isObject(control) &&
        BasUtil.isNEString(control.uuid)) {

        _addToStep(control)
      }
    }
  }

  /**
   * @private
   * @param {BasGenericDeviceControl} control
   */
  function _addToStep (control) {

    var _step

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step) _step.addDeviceControl(control)
  }

  /**
   * @private
   * @param {BasGenericDeviceControl} control
   */
  function _toggleOnStep (control) {

    var _step

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step) _step.toggleDeviceControl(control)
  }

  function _onDestroy () {

    var _step

    _step = BasSceneHelper.getStep($scope, _oldState)

    if (_step) _step.updateSummary()

    BasUtil.executeArray(_listeners)
    _clearDevices()
  }
}
