'use strict'

angular
  .module('basalteApp')
  .controller('streamingServiceSettingsModalCtrl', [
    'BasUtilities',
    'sourceName',
    'streamingServiceTransId',
    'linkedName',
    'unlink',
    'link',
    'close',
    streamingServiceSettingsModalCtrl
  ])

/**
 * @param {BasUtilities} BasUtilities
 * @param {string} sourceName
 * @param {string} streamingServiceTransId
 * @param {string} linkedName
 * @param unlink
 * @param link
 * @param close
 */
function streamingServiceSettingsModalCtrl (
  BasUtilities,
  sourceName,
  streamingServiceTransId,
  linkedName,
  unlink,
  link,
  close
) {
  var modal = this

  modal.streamingService = BasUtilities.translate(streamingServiceTransId)
  modal.linkedName = linkedName
  modal.sourceName = sourceName
  modal.close = close
  modal.link = link
  modal.unlink = unlink
}
