'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('defaultRoomsSettingCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BasState',
    'Sources',
    'CurrentBasCore',
    defaultRoomsSettingCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BasState} BasState
 * @param {Sources} Sources
 * @param {CurrentBasCore} CurrentBasCore
 */
function defaultRoomsSettingCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_SOURCES,
  BAS_SOURCE,
  BasState,
  Sources,
  CurrentBasCore
) {
  var defaultRooms = this

  var _listeners

  defaultRooms.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BAS_SOURCES}
   */
  defaultRooms.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {TBasStateObj}
   */
  defaultRooms.basState = BasState.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  defaultRooms.currentBasCoreState = CurrentBasCore.get()

  defaultRooms.back = back
  defaultRooms.selectStream = selectStream

  // Register destructor function
  $scope.$on('$destroy', onDestroy)

  init()

  function init () {

    _listeners = []

    _listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_DEFAULT_ROOMS_UPDATED,
      _onDefaultRoomsUpdated
    ))

    Sources.registerFor(BAS_SOURCE.COL_EVT_DEFAULT_ROOMS)
  }

  function back () {

    if (defaultRooms.basState.current.SETTINGS_MUSIC_DEFAULT_ROOMS_ROOMS) {

      BasState.go(STATES.SETTINGS_MUSIC_DEFAULT_ROOMS)
    }
  }

  /**
   * @param {string} streamUuid
   */
  function selectStream (streamUuid) {

    if (BasUtil.isNEString(streamUuid)) {

      BasState.go(
        STATES.SETTINGS_MUSIC_DEFAULT_ROOMS_ROOMS,
        {
          stream: streamUuid
        }
      )
    }
  }

  function _onDefaultRoomsUpdated () {

    $scope.$applyAsync()
  }

  function onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = null

    Sources.unregisterFor(BAS_SOURCE.COL_EVT_DEFAULT_ROOMS)
  }
}
