'use strict'

angular
  .module('basalteApp')
  .constant('MEDIA_STATES', {
    MEDIA: 'media',
    PLAYER: 'media.player',
    SOURCES: 'media.sources',
    FAVOURITES: 'media.favourites',
    LIBRARY: 'media.library'
  })
