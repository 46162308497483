'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasServerRestart', [
    'BAS_API',
    'BAS_CONNECT',
    'BAS_ERRORS',
    'BasLiveAccount',
    'BasConnectInfo',
    'BasSmartConnect',
    'BasError',
    BasServerRestart
  ])

/**
 * @callback CBasServerRestartCallback
 * @param {?BasError} error
 * @param {TBasServerRestart} result
 */

/**
 * @callback CBasServerRestartAbort
 */

/**
 * @typedef {Object} TBasServerRestart
 * @property {CBasServerRestartAbort} abort
 * @property {boolean} finished
 * @property {TBasSmartConnect} [basSmartConnect]
 */

/**
 * @constructor
 * @param BAS_API
 * @param {BAS_CONNECT} BAS_CONNECT
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BasLiveAccount} BasLiveAccount
 * @param BasConnectInfo
 * @param {BasSmartConnect} BasSmartConnect
 * @param BasError
 */
function BasServerRestart (
  BAS_API,
  BAS_CONNECT,
  BAS_ERRORS,
  BasLiveAccount,
  BasConnectInfo,
  BasSmartConnect,
  BasError
) {
  // 5 minutes
  var _SERVER_TIMEOUT_MS = 300000

  var _PROJECT_ONLINE_CONFIRMATION_WAIT_MS = 5000

  var _SERVER_INITIAL_CHECK_TIMEOUT_MS = 30000
  var _SERVER_CHECK_TIMEOUT_MS = 5000
  var _SERVER_DEMO_TIMEOUT_MS = 1000

  /**
   * @type {TBasLiveAccountState}
   */
  var basLiveAccountState = BasLiveAccount.get()

  this.restartServer = restartServer

  /**
   * Possible BasError types
   * - T_INVALID_INPUT
   * - T_INTERNAL
   * - T_AUTHENTICATION
   * - T_CONNECTION
   * - T_TIMEOUT
   * - T_ABORT
   *
   * @param {(BasServer|BasCoreContainer)} server
   * @param {CBasServerRestartCallback} [callback]
   * @returns {TBasServerRestart}
   */
  function restartServer (
    server,
    callback
  ) {
    var _result, _aborted, _cbCalled
    var _basCoreContainer, _basServer, _basConnectInfo
    var _basSmartConnectTarget
    var _projectId
    var _projectStatusObservable, _projectStatusSubscription
    var _waitForServerTimeoutId, _serverTimoutId, _demoServerTimeoutId
    var _projectOnlineConfirmationTimeoutId
    var _basSmartConnect

    _aborted = false
    _cbCalled = false

    _waitForServerTimeoutId = 0
    _serverTimoutId = 0
    _demoServerTimeoutId = 0

    _result = {
      abort: abort,
      finished: false
    }

    if (server) {

      if (BasUtil.safeHasOwnProperty(server, 'basServer') &&
        BasUtil.safeHasOwnProperty(server, 'core')) {

        // "server" is BasCoreContainer

        _basCoreContainer = server

        if (_basCoreContainer.hasCore()) {

          _basServer = _basCoreContainer.core.server
        }

      } else {

        // Assume "server" is BasServer

        _basServer = server
      }

      if (_basServer) {

        _projectId = _basServer.cid

        if (_projectId) {

          _restartServer()

        } else {

          _basServer.getProjectInfo()
            .then(_onProjectInfo, _onProjectInfoError)
        }

      } else {

        _cb(new BasError(
          BAS_ERRORS.T_INVALID_INPUT,
          server,
          'Invalid BasServer from BasCoreContainer'
        ))
      }

    } else {

      _cb(new BasError(
        BAS_ERRORS.T_INVALID_INPUT,
        server,
        'Invalid BasServer'
      ))
    }

    return _result

    function _restartServer () {

      _basConnectInfo = BasConnectInfo.build(_basServer)

      if (_basConnectInfo) {

        _basSmartConnectTarget = {
          preferred: _basConnectInfo,
          preferredProjectId: _basConnectInfo.server.cid,
          preferredMac: _basConnectInfo.server.macAddress,
          preferredAddress: _basConnectInfo.server.address,
          allowOther: false
        }

        if (
          _basCoreContainer &&
          _basCoreContainer.hasCore() &&
          _basCoreContainer.core.supportsCheckingUpdates &&
          _basServer.isCoreConnected()
        ) {

          _basCoreContainer.core.restartSystem()
          _onServerRestart()

        } else {

          _basServer.restart()
            .then(_onServerRestart, _onServerRestartError)
        }

      } else {

        _cleanUp()

        _cb(new BasError(
          BAS_ERRORS.T_INTERNAL,
          {
            basServer: _basServer,
            basConnectInfo: _basConnectInfo
          },
          'BasConnectInfo error'
        ))
      }
    }

    function _onProjectInfo () {

      _projectId = _basServer.cid

      _restartServer()
    }

    function _onProjectInfoError () {

      // No project ID, could be legacy server

      _restartServer()
    }

    function _onServerRestart () {

      if (_aborted || _cbCalled) return

      _handleServerRestartStarted()
    }

    function _onServerRestartError (error) {

      if (_aborted || _cbCalled) return

      if (error === BAS_API.CONSTANTS.ERR_TIMEOUT) {

        // Server restart started

        _handleServerRestartStarted()

      } else if (error === BAS_API.CONSTANTS.ERR_FORBIDDEN) {

        _cleanUp()

        _cb(new BasError(
          BAS_ERRORS.T_AUTHENTICATION,
          error,
          'Server restart forbidden'
        ))

      } else {

        _cleanUp()

        _cb(new BasError(
          BAS_ERRORS.T_INTERNAL,
          error,
          'Server restart error'
        ))
      }
    }

    function _handleServerRestartStarted () {

      if (_basConnectInfo.type === BAS_CONNECT.T_REMOTE) {

        if (basLiveAccountState.isLoggedIn) {

          if (_projectId) {

            _projectStatusObservable =
              BasLiveAccount.subscribeToProjectStatus(_projectId)

            if (_projectStatusObservable) {

              _serverTimoutId = setTimeout(
                _onServerTimeout,
                _SERVER_TIMEOUT_MS
              )

              _projectStatusSubscription = _projectStatusObservable.subscribe(
                _onProjectStatusChanged
              )

            } else {

              _startWaitForServer()
            }

          } else {

            _cleanUp()

            _cb(new BasError(
              BAS_ERRORS.T_INTERNAL,
              undefined,
              'Basalte Live no project ID'
            ))
          }

        } else {

          _cleanUp()

          _cb(new BasError(
            BAS_ERRORS.T_AUTHENTICATION,
            undefined,
            'handleServerRestartStarted - ' +
              'Basalte Live not logged in'
          ))
        }

      } else if (_basConnectInfo.type === BAS_CONNECT.T_LOCAL) {

        _startWaitForServer()

      } else if (_basConnectInfo.type === BAS_CONNECT.T_DEMO) {

        _clearDemoServerTimeout()

        _demoServerTimeoutId = setTimeout(
          _onDemoServerTimeout,
          _SERVER_DEMO_TIMEOUT_MS
        )

      } else {

        _cleanUp()

        _cb(new BasError(
          BAS_ERRORS.T_INVALID_INPUT,
          {
            basConnectInfo: _basConnectInfo,
            basServer: _basServer
          },
          'Unsupported BasServer type'
        ))
      }
    }

    function _onDemoServerTimeout () {

      if (_aborted || _cbCalled) return

      _clearDemoServerTimeout()

      _clearBasSmartConnect()

      _basSmartConnect = BasSmartConnect.connect(
        _basSmartConnectTarget,
        null,
        _onDemoBasSmartConnect
      )
    }

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasSmartConnect} basSmartConnectResult
     */
    function _onDemoBasSmartConnect (
      error,
      basSmartConnectResult
    ) {
      if (_aborted || _cbCalled || !_basSmartConnect) return

      if (error) {

        // Demo server error

        _cleanUp()

        _cb(new BasError(
          BAS_ERRORS.T_INTERNAL,
          error,
          'BasSmartConnect Demo error'
        ))

      } else {

        _checkBasSmartConnectResult(
          error,
          basSmartConnectResult
        )
      }
    }

    function _onProjectStatusChanged (event) {

      if (_aborted || _cbCalled) return

      if (BasUtil.isObject(event)) {

        if (event.online === true) {

          // Wait some time after online event
          // because of issues with server restarting
          // Server could still send offline event
          // because restart did not occur yet

          _projectOnlineConfirmationTimeoutId = setTimeout(
            _onProjectOnlineConfirmed,
            _PROJECT_ONLINE_CONFIRMATION_WAIT_MS
          )

        } else {

          _clearProjectOnlineConfirmationTimeout()
        }
      }
    }

    function _onProjectOnlineConfirmed () {

      if (_aborted || _cbCalled) return

      // Connect with server

      _clearBasSmartConnect()

      _basSmartConnect = BasSmartConnect.connect(
        _basSmartConnectTarget,
        null,
        _onProjectBasSmartConnect
      )
    }

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasSmartConnect} basSmartConnectResult
     */
    function _onProjectBasSmartConnect (
      error,
      basSmartConnectResult
    ) {
      if (_aborted || _cbCalled || !_basSmartConnect) return

      if (error) {

        // Unable to connect

        _cleanUp()

        _cb(new BasError(
          BAS_ERRORS.T_CONNECTION,
          error,
          'Basalte Live connection error'
        ))

      } else {

        _checkBasSmartConnectResult(
          error,
          basSmartConnectResult
        )
      }
    }

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasSmartConnect} basSmartConnectResult
     */
    function _checkBasSmartConnectResult (error, basSmartConnectResult) {

      if (_basSmartConnect === basSmartConnectResult) {

        // Server is connected

        _result.basSmartConnect = _basSmartConnect
        _basSmartConnect = null

        _cleanUp()

        _cb(null)

      } else {

        // Should not occur, different basSmartConnect

        if (_waitForServerTimeoutId) {

          // Do nothing, wait for timers

        } else {

          _cb(new BasError(
            BAS_ERRORS.T_INVALID_RESULT,
            error,
            'BasSmartConnect different basSmartConnect'
          ))
        }
      }
    }

    function _startWaitForServer () {

      _serverTimoutId = setTimeout(
        _onServerTimeout,
        _SERVER_TIMEOUT_MS
      )

      _waitForServerTimeoutId = setTimeout(
        _onWaitForServerTimeout,
        _SERVER_INITIAL_CHECK_TIMEOUT_MS
      )
    }

    function _onWaitForServerTimeout () {

      if (_aborted || _cbCalled) return

      // Check online state

      _clearBasSmartConnect()

      _basSmartConnect = BasSmartConnect.connect(
        _basSmartConnectTarget,
        null,
        _onBasSmartConnect
      )
    }

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasSmartConnect} basSmartConnectResult
     */
    function _onBasSmartConnect (
      error,
      basSmartConnectResult
    ) {
      if (_aborted || _cbCalled || !_basSmartConnect) return

      if (error) {

        // Server is not online yet

        _clearWaitForServerTimeout()

        _waitForServerTimeoutId = setTimeout(
          _onWaitForServerTimeout,
          _SERVER_CHECK_TIMEOUT_MS
        )

      } else {

        _checkBasSmartConnectResult(
          error,
          basSmartConnectResult
        )
      }
    }

    function _onServerTimeout () {

      if (_aborted || _cbCalled) return

      _cleanUp()

      _cb(new BasError(
        BAS_ERRORS.T_TIMEOUT,
        undefined,
        BAS_ERRORS.M_TIMEOUT
      ))
    }

    function _clearBasSmartConnect () {

      if (_basSmartConnect) _basSmartConnect.abort()
      _basSmartConnect = null
    }

    function _unsubscribeProjectStatus () {
      if (_projectStatusSubscription) _projectStatusSubscription.unsubscribe()
      _projectStatusSubscription = null
    }

    function _clearDemoServerTimeout () {

      clearTimeout(_demoServerTimeoutId)
      _demoServerTimeoutId = 0
    }

    function _clearWaitForServerTimeout () {

      clearTimeout(_waitForServerTimeoutId)
      _waitForServerTimeoutId = 0
    }

    function _clearProjectOnlineConfirmationTimeout () {

      clearTimeout(_projectOnlineConfirmationTimeoutId)
      _projectOnlineConfirmationTimeoutId = 0
    }

    function _clearServerTimeout () {

      clearTimeout(_serverTimoutId)
      _serverTimoutId = 0
    }

    function _cleanUp () {

      _clearDemoServerTimeout()
      _clearWaitForServerTimeout()
      _clearProjectOnlineConfirmationTimeout()
      _clearServerTimeout()

      _clearBasSmartConnect()
      _unsubscribeProjectStatus()
    }

    function abort () {

      _aborted = true

      _cleanUp()

      _cb(new BasError(
        BAS_ERRORS.T_ABORT,
        undefined,
        BAS_ERRORS.M_ABORTED
      ))
    }

    /**
     * @private
     * @param {?BasError} error
     */
    function _cb (error) {

      if (!_cbCalled) {

        _cbCalled = true

        _result.finished = true

        if (BasUtil.isFunction(callback)) {

          callback(error, _result)
        }
      }
    }
  }
}
