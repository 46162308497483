'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourceKNXPresets', [
    'BAS_KNXPRESETS',
    'BAS_FAVOURITE',
    'BasKNXPreset',
    'Logger',
    basSourceKNXPresetsFactory
  ])

/**
 * @param {BAS_KNXPRESETS} BAS_KNXPRESETS
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param BasKNXPreset
 * @param Logger
 * @returns BasSourceKNXPresets
 */
function basSourceKNXPresetsFactory (
  BAS_KNXPRESETS,
  BAS_FAVOURITE,
  BasKNXPreset,
  Logger
) {

  var MAX_PRESETS_COUNT = 300

  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourceKNXPresets (basSource) {

    /**
     * @type {Object<string, BasKNXPreset>}
     */
    this.presets = {}

    /**
     * @type {number[]}
     */
    this.uiPresets = []

    /**
     * @type {number}
     */
    this.length = 0

    this.handlePresetsChanged = this._onPresetsChanged.bind(this)

    this._handlePresets = this._processPresets.bind(this)

    this.handlePresetLinked = this._onPresetLinked.bind(this)

    /**
     * @private
     * @type {?BasSource}
     */
    this._basSource = basSource || null
  }

  BasSourceKNXPresets.prototype._getTag = function () {

    var str = 'BasSourceKNXPresets'
    if (this._basSource) str += '(' + this._basSource.getId() + ')'
    str += ' - '
    return str
  }

  /**
   * Synchronous check for KNX presets
   *
   * @returns {boolean}
   */
  BasSourceKNXPresets.prototype.hasPresets = function () {

    if (this._basSource) {

      if (this._basSource.isAudioSource) {

        return this._basSource.knxPresets
          ? this._basSource.knxPresets.length > 0
          : false

      } else {

        if (
          this._basSource.source &&
          !this._basSource.source.presetsDirty
        ) {

          return BasUtil.isNEArray(this._basSource.source.presets)
        }
      }

    }

    return false
  }

  /**
   * Asynchronous check for KNX presets
   *
   * @returns {Promise}
   */
  BasSourceKNXPresets.prototype.retrievePresets = function () {

    if (this._basSource) {

      if (
        this._basSource.source &&
        this._basSource.source.getPresets
      ) {
        return this._basSource.source.getPresets().then(this._handlePresets)
      }
    }

    return Promise.reject('Invalid source')
  }

  /**
   * Asynchronous and paginated check for KNX presets
   *
   * @returns {Promise}
   */
  BasSourceKNXPresets.prototype.retrievePresets = function () {

    if (this._basSource.isAudioSource) {

      return this._basSource.source
        .listPresets(0, MAX_PRESETS_COUNT)
        .then(this._handlePresets)
    }

    return Promise.reject('Invalid source')
  }

  /**
   * @private
   * @param {(Object[]|TAudioSourcePresetsResult)} presets
   * @returns {number[]}
   */
  BasSourceKNXPresets.prototype._processPresets = function (presets) {

    var i, length, preset, basPreset

    if (Array.isArray(presets)) {

      this.clearPresets()

      length = presets.length
      for (i = 0; i < length; i++) {

        preset = presets[i]

        if (BasUtil.isObject(preset)) {

          basPreset = new BasKNXPreset(preset)

          if (this.presets[basPreset.id]) {

            Logger.warn(
              this._getTag() + 'Preset will be overwritten',
              this.presets[basPreset.id],
              basPreset
            )

          } else {

            // Only add new ID's
            this.uiPresets.push(basPreset.id)
          }

          this.presets[basPreset.id] = basPreset
        }
      }

      this.length = this.uiPresets.length

    } else if (presets && Array.isArray(presets.list)) {

      this.clearPresets()

      length = presets.list.length
      for (i = 0; i < length; i++) {

        preset = presets.list[i]

        if (BasUtil.isObject(preset)) {

          basPreset = new BasKNXPreset(preset)

          if (this.presets[basPreset.id]) {

            Logger.warn(
              this._getTag() + 'Preset will be overwritten',
              this.presets[basPreset.id],
              basPreset
            )

          } else {

            // Only add new ID's
            this.uiPresets[i] = basPreset.id
          }

          this.presets[basPreset.id] = basPreset
        }
      }

      this.length = presets.total
    }

    return this.uiPresets
  }

  BasSourceKNXPresets.prototype.clearPresets = function () {

    this.presets = {}
    this.uiPresets = []
    this.length = 0
  }

  /**
   * @param {(string|number)} id Preset ID
   * @param {string} favouriteId
   */
  BasSourceKNXPresets.prototype.link = function (id, favouriteId) {

    var _id, _preset, favourite, basPreset

    if (this._basSource) {

      if (this._basSource.isAudioSource) {

        this._basSource.source.linkPreset(parseInt(id), favouriteId)

      } else {

        if (BasUtil.isNEString(id)) {

          _id = parseInt(id, 10)
        }

        if (
          BasUtil.isVNumber(_id) &&
          BasUtil.isNEString(favouriteId) &&
          this._basSource.favourites &&
          this._basSource.source
        ) {

          favourite = this._basSource.favourites.favourites[favouriteId]

          if (favourite) {

            _preset = {}

            switch (favourite.type) {
              case BAS_FAVOURITE.T_LOCAL_PLAYLIST:

                _preset.type = BAS_KNXPRESETS.T_LOCAL
                _preset.subtype = BAS_KNXPRESETS.ST_PLAYLIST
                _preset.value = favourite.sid

                break
              case BAS_FAVOURITE.T_RADIO:

                _preset.type = BAS_KNXPRESETS.T_TUNEIN
                _preset.value = favourite.sid

                break
              case BAS_FAVOURITE.T_DEEZER:

                _preset.type = BAS_KNXPRESETS.T_DEEZER

                if (favourite.uuid === BAS_FAVOURITE.UUID_DEEZER_FLOW) {

                  _preset.subtype = BAS_KNXPRESETS.ST_FLOW

                } else {

                  _preset.subtype = favourite.contentType
                  _preset.value = favourite.sid
                }

                break
              case BAS_FAVOURITE.T_TIDAL:

                _preset.type = BAS_KNXPRESETS.T_TIDAL

                _preset.subtype = favourite.contentType
                _preset.value = favourite.sid

                break
              case BAS_FAVOURITE.T_SPOTIFY_CONNECT:

                _preset.type = BAS_KNXPRESETS.T_SPOTIFY

                if (favourite.uuid ===
                  BAS_FAVOURITE.UUID_SPOTIFY_CONNECT_CURRENT) {

                  _preset.value =
                    BAS_KNXPRESETS.V_SPOTIFY_CONNECT_CLIENT

                } else {

                  _preset.value = favourite.sid
                }

                break
            }

            if ('type' in _preset) {

              // Toggle current in memory version for faster UI response
              basPreset = this.presets[_id]
              if (basPreset) basPreset.favouriteId = favouriteId

              this._basSource.source.linkPreset(_id, _preset)
            }
          }
        }
      }
    }
  }

  BasSourceKNXPresets.prototype._onPresetsChanged = function () {

    this.retrievePresets()
  }

  /**
   * @private
   * @param {number} id
   * @param {string} uri
   */
  BasSourceKNXPresets.prototype._onPresetLinked = function (id, uri) {

    var preset

    preset = this.presets[id]

    if (preset) preset.favouriteId = uri
  }

  BasSourceKNXPresets.prototype.destroy = function () {

    this.clearPresets()

    this._basSource = null
  }

  return BasSourceKNXPresets
}
