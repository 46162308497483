'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('TidalModel', [
    'BAS_FAVOURITE',
    'TidalElement',
    'TidalHelper',
    'BasUtilities',
    'Logger',
    TidalModel
  ])

/**
 * Helper class for creating TidalElements from Tidal Objects
 *
 * @constructor
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param TidalElement
 * @param {TidalHelper} TidalHelper
 * @param {BasUtilities} BasUtilities
 * @param Logger
 */
function TidalModel (
  BAS_FAVOURITE,
  TidalElement,
  TidalHelper,
  BasUtilities,
  Logger
) {
  var serviceName = 'Tidal model'

  // Main sections
  var SECTION_NEW = 'new'
  var SECTION_TIDAL_RISING = 'tidalRising'
  var SECTION_PLAYLISTS = 'playlists'
  var SECTION_GENRES = 'genres'
  var SECTION_MY_MUSIC = 'myMusic'
  var SECTION_MORE = 'more'

  var K_TITLE = 'title'
  var K_NAME = 'name'
  var K_IMAGE = 'image'
  var K_SQUARE_IMAGE = 'squareImage'
  var K_COVER = 'cover'
  var K_PICTURE = 'picture'
  var K_NUM_OF_TR = 'numberOfTracks'
  var K_DURATION = 'duration'
  var K_URL = 'url'
  var K_UUID = 'uuid'
  var K_ID = 'id'
  var K_ARTIST = 'artist'
  var K_ALBUM = 'album'
  var K_PATH = 'path'
  var K_CREATED = 'created'
  var K_CREATOR = 'creator'

  // Based on size from http://developer.tidal.com/technical/images/
  var SIZE_ALBUM = '160x160'
  var SIZE_ALBUM_BIG = '640x640'
  var SIZE_ARTIST = '160x160'
  var SIZE_ARTIST_BIG = '480x480'
  var SIZE_GENRE_MOOD = '320x320'
  var SIZE_PLAYLIST = '160x107'
  var SIZE_PLAYLIST_MED = '320x214'
  var SIZE_PLAYLIST_BIG = '640x428'
  var SIZE_PLAYLIST_SQUARE = '160x160'
  var SIZE_PLAYLIST_SQUARE_MED = '320x320'
  var SIZE_PLAYLIST_SQUARE_BIG = '640x640'

  var CT_ALBUM = 'album'
  var CT_ARTIST = 'artist'
  var CT_PLAYLIST = 'playlist'
  var CT_GENRE = 'genre'
  var CT_MOOD = 'mood'
  var CT_TRACK = 'track'

  /**
   * @constant {string}
   */
  this.CT_ALBUM = CT_ALBUM

  /**
   * @constant {string}
   */
  this.CT_ARTIST = CT_ARTIST

  /**
   * @constant {string}
   */
  this.CT_PLAYLIST = CT_PLAYLIST

  /**
   * @constant {string}
   */
  this.CT_GENRE = CT_GENRE

  /**
   * @constant {string}
   */
  this.CT_MOOD = CT_MOOD

  /**
   * @constant {string}
   */
  this.CT_TRACK = CT_TRACK

  /**
   * @constant {string}
   */
  this.SECTION_NEW = SECTION_NEW

  /**
   * @constant {string}
   */
  this.SECTION_TIDAL_RISING = SECTION_TIDAL_RISING

  /**
   * @constant {string}
   */
  this.SECTION_PLAYLISTS = SECTION_PLAYLISTS

  /**
   * @constant {string}
   */
  this.SECTION_GENRES = SECTION_GENRES

  /**
   * @constant {string}
   */
  this.SECTION_MY_MUSIC = SECTION_MY_MUSIC

  /**
   * @constant {string}
   */
  this.SECTION_MORE = SECTION_MORE

  this.createSection = createSection

  this.processTidalObjects = processTidalObjects

  this.processTidalObject = processTidalObject

  this.processPlaylistsSimple = processPlaylistsSimple

  function processPlaylistsSimple (array) {
    var i, length
    var result = []

    // Make sure argument is array
    if (Array.isArray(array)) {

      // Iterate array
      length = array.length
      for (i = 0; i < length; i += 1) {

        // Add Tidal Ui Element to results
        result.push(
          processSimplePlaylist(array[i])
        )
      }
    } else {
      Logger.warn(
        serviceName + ' - processPlaylistsSimple - Parameter is NO ARRAY',
        array
      )
    }

    return result
  }

  function processSimplePlaylist (object) {

    var element, _obj

    element = {}

    _obj = BasUtil.safeHasOwnProperty(object, 'item')
      ? object.item
      : object

    // Title
    if (BasUtil.isNEString(_obj[K_TITLE])) {
      element.title = _obj[K_TITLE]
    }

    // Set ID
    if (BasUtil.isNEString(_obj[K_UUID])) {
      element.id = _obj[K_UUID]
    }

    return element
  }

  /**
   * Can take an optional offset
   * to start processing items at index offset
   *
   * @param {Array<?Object>} array
   * @param {string} type
   * @param {boolean} [clean]
   * @returns {Array<TidalElement>}
   */
  function processTidalObjects (array, type, clean) {
    var i, length
    var result = []

    // Make sure argument is array
    if (Array.isArray(array)) {

      // Iterate array
      length = array.length
      for (i = 0; i < length; i += 1) {

        // Add Tidal Ui Element to results
        result.push(processTidalObject(array[i], type, clean))
      }
    } else {
      Logger.warn(
        serviceName + ' - processTidalObjects - Parameter is NO ARRAY',
        array
      )
    }

    return result
  }

  /**
   * @param {?Object} object
   * @param {string} type
   * @param {boolean} [clean]
   * @returns {TidalElement}
   */
  function processTidalObject (
    object,
    type,
    clean
  ) {
    var _obj, element, time

    element = new TidalElement()
    time = ''

    if (BasUtil.safeHasOwnProperty(object, 'item')) {

      time = object.created
      _obj = object.item

    } else {

      _obj = object
    }

    // Check if object is valid
    if (BasUtil.isObject(_obj)) {

      // Check Tidal object type
      if (type === TidalElement.TYPE_PLAYLIST) {

        // Set type
        element.type = TidalElement.TYPE_PLAYLIST

        // Process album
        processTidalPlaylist(element, _obj, time)

        // UI properties
        element.setCanBrowse(true, false)
        element.setHasContext(true)
        element.setCanAsanoFavourite(true)

      } else if (type === TidalElement.TYPE_ALBUM) {

        // Set type
        element.type = TidalElement.TYPE_ALBUM

        // Process album
        processTidalAlbum(element, _obj)

        // UI properties
        element.setCanBrowse(true)
        element.setHasContext(true)

      } else if (type === TidalElement.TYPE_ARTIST) {

        // Set type
        element.type = TidalElement.TYPE_ARTIST

        // Process album
        processTidalArtist(element, _obj)

        // UI properties
        element.setCanBrowse(true)
        element.setHasContext(true)

      } else if (type === TidalElement.TYPE_TRACK) {

        // Set type
        element.type = TidalElement.TYPE_TRACK

        // Process album
        processTidalTrack(element, _obj, clean)

        // UI properties
        element.setCanSelect(true)
        element.setHasContext(true)

      } else if (type === TidalElement.TYPE_MOOD) {

        // Set type
        element.type = TidalElement.TYPE_MOOD

        // Process album
        processTidalGenre(element, _obj)

        // UI properties
        element.setCanBrowse(true)

      } else if (type === TidalElement.TYPE_GENRE) {

        // Set type
        element.type = TidalElement.TYPE_GENRE

        // Process album
        processTidalGenre(element, _obj)

        // UI properties
        element.setCanBrowse(true)

      } else {
        Logger.warn(serviceName +
                      ' - Unknown Tidal object', _obj)
      }

      // Make sure the Asano favourite Id is created
      element.createAsanoFavouriteId(BAS_FAVOURITE.T_TIDAL)
    }

    return element
  }

  /**
   * @param {TidalElement} element
   * @param {?Object} object
   * @param {?string} time
   */
  function processTidalPlaylist (
    element,
    object,
    time
  ) {
    var cover, coverMed, coverBig

    // Title
    if (BasUtil.isNEString(object[K_TITLE])) {
      element.title = object[K_TITLE]
    }

    // Number of tracks
    if (BasUtil.isPNumber(object[K_NUM_OF_TR])) {
      element.setNumberOfSongs(object[K_NUM_OF_TR])
    }

    if (BasUtil.isObject(object[K_CREATOR]) &&
      BasUtil.isPNumber(object[K_CREATOR][K_ID], true)) {

      element.creator = object[K_CREATOR][K_ID]
    }

    // Timestamp
    if (BasUtil.isNEString(object[K_CREATED])) {
      element.timeCreated = object[K_CREATED]
    }

    // Timestamp
    if (BasUtil.isNEString(time)) {
      element.timeCreated = time
    }

    // Duration
    if (BasUtil.isPNumber(object[K_DURATION])) {
      element.setDuration(object[K_DURATION])
    }

    // Set ID
    element.setId(
      BAS_FAVOURITE.T_TIDAL,
      object[K_UUID]
    )

    // Set URL
    if (BasUtil.isNEString(object[K_URL])) {
      element.url = object[K_URL]
    }

    cover = ''
    coverMed = ''
    coverBig = ''

    // Check if coverArt is valid
    if (BasUtil.isNEString(object[K_SQUARE_IMAGE])) {

      cover = TidalHelper.getImage(object[K_SQUARE_IMAGE], SIZE_PLAYLIST_SQUARE)
      coverMed = TidalHelper.getImage(
        object[K_SQUARE_IMAGE],
        SIZE_PLAYLIST_SQUARE_MED
      )
      coverBig = TidalHelper.getImage(
        object[K_SQUARE_IMAGE],
        SIZE_PLAYLIST_SQUARE_BIG
      )

    } else if (BasUtil.isNEString(object[K_IMAGE])) {

      cover = TidalHelper.getImage(object[K_IMAGE], SIZE_PLAYLIST)
      coverMed = TidalHelper.getImage(object[K_IMAGE], SIZE_PLAYLIST_MED)
      coverBig = TidalHelper.getImage(object[K_IMAGE], SIZE_PLAYLIST_BIG)
    }

    // Set cover art
    element.setCoverArt({
      small: cover,
      medium: coverMed,
      large: coverBig
    })
  }

  /**
   * @param {TidalElement} element
   * @param {?Object} object
   */
  function processTidalAlbum (element, object) {

    var cover, coverBig

    // Title
    if (BasUtil.isNEString(object[K_TITLE])) {
      element.title = object[K_TITLE]
    }

    // Artist
    if (BasUtil.isObject(object[K_ARTIST]) &&
      BasUtil.isNEString(object[K_ARTIST][K_NAME])) {
      element.subtitle = object[K_ARTIST][K_NAME]
      element.artist = object[K_ARTIST]
    }

    // Set ID
    element.setId(
      BAS_FAVOURITE.T_TIDAL,
      object[K_ID]
    )

    // Set URL
    if (BasUtil.isNEString(object[K_URL])) {
      element.url = object[K_URL]
    }

    cover = ''
    coverBig = ''

    // Check if coverArt is valid
    if (BasUtil.isNEString(object[K_COVER])) {

      cover = TidalHelper.getImage(object[K_COVER], SIZE_ALBUM)
      coverBig = TidalHelper.getImage(object[K_COVER], SIZE_ALBUM_BIG)
    }

    // Set cover art
    element.setCoverArt({
      small: cover,
      medium: cover,
      large: coverBig
    })
  }

  /**
   * @param {TidalElement} element
   * @param {?Object} object
   */
  function processTidalArtist (element, object) {

    var cover, coverBig

    // Title
    if (BasUtil.isNEString(object[K_NAME])) {
      element.title = object[K_NAME]
    }

    // Artist
    if (BasUtil.isObject(object[K_ARTIST]) &&
      BasUtil.isNEString(object[K_ARTIST][K_NAME])) {
      element.subtitle = object[K_ARTIST][K_NAME]
    }

    // Set ID
    element.setId(
      BAS_FAVOURITE.T_TIDAL,
      object[K_ID]
    )

    // Set URL
    if (BasUtil.isNEString(object[K_URL])) {
      element.url = object[K_URL]
    }

    cover = ''
    coverBig = ''

    // Check if coverArt is valid
    if (BasUtil.isNEString(object[K_PICTURE])) {

      cover = TidalHelper.getImage(object[K_PICTURE], SIZE_ARTIST)
      coverBig = TidalHelper.getImage(object[K_PICTURE], SIZE_ARTIST_BIG)
    }

    // Set cover art
    element.setCoverArt({
      small: cover,
      medium: cover,
      large: coverBig
    })
  }

  /**
   * @param {TidalElement} element
   * @param {?Object} object
   * @param {boolean} clean
   */
  function processTidalTrack (element, object, clean) {

    var cover, coverBig

    // Title
    if (BasUtil.isNEString(object[K_TITLE])) {
      element.title = object[K_TITLE]
    }

    // Artist
    if (BasUtil.isObject(object[K_ARTIST]) &&
      BasUtil.isNEString(object[K_ARTIST][K_NAME])) {
      element.subtitle = object[K_ARTIST][K_NAME]
      element.artist = object[K_ARTIST]
    }

    // Duration
    if (BasUtil.isPNumber(object[K_DURATION])) {
      element.setDuration(object[K_DURATION])
    }

    // Set ID
    element.setId(
      BAS_FAVOURITE.T_TIDAL,
      object[K_ID]
    )

    // Set URL
    if (BasUtil.isNEString(object[K_URL])) {
      element.url = object[K_URL]
    }

    cover = ''
    coverBig = ''

    // Check if coverArt is valid
    if (BasUtil.isObject(object[K_ALBUM])) {

      element.album = object[K_ALBUM]

      if (BasUtil.isNEString(object[K_ALBUM][K_COVER])) {

        cover = TidalHelper.getImage(object[K_ALBUM][K_COVER], SIZE_ALBUM)
        coverBig = TidalHelper.getImage(
          object[K_ALBUM][K_COVER],
          SIZE_ALBUM_BIG
        )
      }
    }

    if (!clean) {
      // Set cover art
      element.setCoverArt({
        small: cover,
        medium: cover,
        large: coverBig
      })
    }
  }

  /**
   * @param {TidalElement} element
   * @param {?Object} object
   */
  function processTidalGenre (element, object) {

    var cover

    // Title
    if (BasUtil.isNEString(object[K_NAME])) {
      element.title = object[K_NAME]
    }

    cover = ''

    // Check if coverArt is valid
    if (BasUtil.isNEString(object[K_IMAGE])) {

      cover = TidalHelper.getImage(object[K_IMAGE], SIZE_GENRE_MOOD)
    }

    // Set cover art
    element.setCoverArt({
      small: cover,
      medium: cover,
      large: cover
    })

    if (BasUtil.isNEString(object[K_PATH])) {
      element.path = object[K_PATH]
    }
  }

  /**
   * @param {string} sectionType
   * @param {string} [type]
   * @param {string} [query]
   * @returns {TidalElement}
   */
  function createSection (sectionType, type, query) {

    // Create new UI element for type
    var element = new TidalElement()

    // Set ID
    element.id = sectionType

    // Type
    element.type = TidalElement.TYPE_SECTION

    // Sub type
    element.subType = sectionType

    // Customize according to section type
    switch (sectionType) {
      case SECTION_NEW:

        // Titles
        element.title = BasUtilities.translate('new')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_TIDAL_RISING:

        // Titles
        element.title = BasUtilities.translate('tidal_rising')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_PLAYLISTS:

        // Titles
        element.title = BasUtilities.translate('moods')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_GENRES:

        // Titles
        element.title = BasUtilities.translate('genres')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_MY_MUSIC:

        // Titles
        element.title = BasUtilities.translate('my_music')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_MORE:

        // Titles
        element.title = BasUtilities.translate('show_more')

        // Has more content
        element.setCanBrowse(true)
        element.searchData.type = type
        element.searchData.query = query

        break
      default:
        Logger.warn(serviceName + '- Invalid sectionType', sectionType)
    }

    return element
  }

}
