'use strict'

var BasUtil = require('@basalte/bas-util')

var GenericDevice = require('./generic_device')
var GenericDeviceControl = require('./generic_device_control')

var P = require('./parser_constants')

/**
 * @constructor
 * @extends GenericDevice
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function GenericDeviceV1 (device, basCore) {
  GenericDevice.call(this, device, basCore)

  /**
   * @type {GenericDeviceControl[]}
   * @private
   */
  this._controls = []

  this.parse(device, { emit: false })
}

GenericDeviceV1.prototype = Object.create(GenericDevice.prototype)
GenericDeviceV1.prototype.constructor = GenericDeviceV1

/**
 * @name GenericDeviceV1#controls
 * @type {GenericDeviceControl[]}
 * @readonly
 */
Object.defineProperty(GenericDeviceV1.prototype, 'controls', {
  get: function () {
    return Array.isArray(this._controls)
      ? this._controls
      : []
  }
})

/**
 * Parse a GenericDevice message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
GenericDeviceV1.prototype.parse = function (msg, options) {

  var valid, emit, _this

  emit = true
  _this = this

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  valid = GenericDevice.prototype.parse.call(this, msg, options)

  if (valid) parseControls(msg)

  return valid

  /**
   * Parse generic device controllers
   *
   * @param {Object} message
   */
  function parseControls (message) {
    var controls, index, b, length, control

    if (BasUtil.isNEArray(message[P.CONTROLS])) {

      controls = message[P.CONTROLS]

      length = controls.length

      for (b = 0; b < length; b++) {

        control = controls[b]

        if (
          BasUtil.isObject(control) &&
          BasUtil.isNEString(control.uuid)
        ) {

          if (!Array.isArray(_this._controls)) _this._controls = []

          index = _this.indexOfGenericDeviceControl(_this._controls, control)

          if (index !== -1) {

            _this._controls[index].parse(control)

          } else {

            _this._controls.push(new GenericDeviceControl(
              control,
              null
            ))
          }
        }
      }

      if (emit) _this.emit(GenericDevice.EVT_CONTROLS_UPDATED, _this.controls)
    }
  }
}

/**
 * Custom destroy to destroy all child generic device controls.
 */
GenericDeviceV1.prototype.destroy = function () {

  var length, i

  if (Array.isArray(this._controls)) {

    length = this._controls.length
    for (i = 0; i < length; i++) {

      BasUtil.executeFunction(this._controls[i], 'destroy')
    }
  }

  GenericDevice.prototype.destroy.call(this)
}

module.exports = GenericDeviceV1
