'use strict'

angular
  .module('basalteApp')
  .directive('basHomeWeatherWidgetSizer', basHomeWeatherWidgetSizer)

function basHomeWeatherWidgetSizer () {

  return {
    restrict: 'A',
    require: '^basHomeSizer',
    link: link
  }

  function link (
    _scope,
    elem,
    _attr,
    controller
  ) {
    controller.setWeatherWidgetElement(elem[0])
  }
}
