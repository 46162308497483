// source: CoreAppData.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var CoreCommon_pb = require('./CoreCommon_pb.js');
goog.object.extend(proto, CoreCommon_pb);
var enums_pb = require('./enums_pb.js');
goog.object.extend(proto, enums_pb);
goog.exportSymbol('proto.be.basalte.config.appdata.AppData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.AudioData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.DeviceData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.DspData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.DspEqualiser', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.DspPassFilter', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.DspPassFilter.DspBesselNormType', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.DspPassFilter.DspPassFilterType', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.DspRoomData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.DspShelfFilter', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.ProjectSettings', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.Scene', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.AV', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.GenericControl', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.GenericControl.ValueCase', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.Light', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.Light.LightMode', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.LightGroup', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.SceneItem', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.SceneItem.ItemCase', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.SceneTarget', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.SceneTarget.TargetCase', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.Subscene', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.Thermostat', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.WindowTreatment', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneControllerData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneJob', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.SceneJob.TimeTypeCase', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.ScheduleData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.ScheduleData.SchedulePoint', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.ScriptData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.StreamData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.TVData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.ThermostatSchedule', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.SetpointType', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.UIDeviceData', null, global);
goog.exportSymbol('proto.be.basalte.config.appdata.UserData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.AppData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.AppData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.AppData.displayName = 'proto.be.basalte.config.appdata.AppData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.ProjectSettings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.ProjectSettings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.ProjectSettings.displayName = 'proto.be.basalte.config.appdata.ProjectSettings';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.UserData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.be.basalte.config.appdata.UserData.repeatedFields_, null);
};
goog.inherits(proto.be.basalte.config.appdata.UserData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.UserData.displayName = 'proto.be.basalte.config.appdata.UserData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.AudioData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.AudioData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.AudioData.displayName = 'proto.be.basalte.config.appdata.AudioData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.StreamData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.be.basalte.config.appdata.StreamData.repeatedFields_, null);
};
goog.inherits(proto.be.basalte.config.appdata.StreamData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.StreamData.displayName = 'proto.be.basalte.config.appdata.StreamData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.DspData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.DspData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.DspData.displayName = 'proto.be.basalte.config.appdata.DspData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.DspRoomData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.DspRoomData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.DspRoomData.displayName = 'proto.be.basalte.config.appdata.DspRoomData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.DspEqualiser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.DspEqualiser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.DspEqualiser.displayName = 'proto.be.basalte.config.appdata.DspEqualiser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.DspShelfFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.DspShelfFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.DspShelfFilter.displayName = 'proto.be.basalte.config.appdata.DspShelfFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.DspPassFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.DspPassFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.DspPassFilter.displayName = 'proto.be.basalte.config.appdata.DspPassFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.DeviceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.DeviceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.DeviceData.displayName = 'proto.be.basalte.config.appdata.DeviceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.ThermostatSchedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.be.basalte.config.appdata.ThermostatSchedule.repeatedFields_, null);
};
goog.inherits(proto.be.basalte.config.appdata.ThermostatSchedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.ThermostatSchedule.displayName = 'proto.be.basalte.config.appdata.ThermostatSchedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.displayName = 'proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneControllerData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.be.basalte.config.appdata.SceneControllerData.repeatedFields_, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneControllerData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneControllerData.displayName = 'proto.be.basalte.config.appdata.SceneControllerData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.Scene = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.Scene, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.Scene.displayName = 'proto.be.basalte.config.appdata.Scene';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneJob = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.be.basalte.config.appdata.SceneJob.oneofGroups_);
};
goog.inherits(proto.be.basalte.config.appdata.SceneJob, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneJob.displayName = 'proto.be.basalte.config.appdata.SceneJob';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.be.basalte.config.appdata.SceneConfig.repeatedFields_, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.displayName = 'proto.be.basalte.config.appdata.SceneConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.Light = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.Light, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.Light.displayName = 'proto.be.basalte.config.appdata.SceneConfig.Light';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.LightGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.LightGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.LightGroup.displayName = 'proto.be.basalte.config.appdata.SceneConfig.LightGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.WindowTreatment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.displayName = 'proto.be.basalte.config.appdata.SceneConfig.WindowTreatment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.displayName = 'proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.Thermostat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.Thermostat.displayName = 'proto.be.basalte.config.appdata.SceneConfig.Thermostat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.Subscene, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.Subscene.displayName = 'proto.be.basalte.config.appdata.SceneConfig.Subscene';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.AV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.AV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.AV.displayName = 'proto.be.basalte.config.appdata.SceneConfig.AV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.be.basalte.config.appdata.SceneConfig.GenericControl.oneofGroups_);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.GenericControl, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.GenericControl.displayName = 'proto.be.basalte.config.appdata.SceneConfig.GenericControl';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.displayName = 'proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.SceneTarget, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.SceneTarget.displayName = 'proto.be.basalte.config.appdata.SceneConfig.SceneTarget';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.be.basalte.config.appdata.SceneConfig.SceneItem.oneofGroups_);
};
goog.inherits(proto.be.basalte.config.appdata.SceneConfig.SceneItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.SceneConfig.SceneItem.displayName = 'proto.be.basalte.config.appdata.SceneConfig.SceneItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.UIDeviceData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.UIDeviceData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.UIDeviceData.displayName = 'proto.be.basalte.config.appdata.UIDeviceData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.ScheduleData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.be.basalte.config.appdata.ScheduleData.repeatedFields_, null);
};
goog.inherits(proto.be.basalte.config.appdata.ScheduleData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.ScheduleData.displayName = 'proto.be.basalte.config.appdata.ScheduleData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.ScheduleData.SchedulePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.displayName = 'proto.be.basalte.config.appdata.ScheduleData.SchedulePoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.TVData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.TVData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.TVData.displayName = 'proto.be.basalte.config.appdata.TVData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.be.basalte.config.appdata.ScriptData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.be.basalte.config.appdata.ScriptData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.be.basalte.config.appdata.ScriptData.displayName = 'proto.be.basalte.config.appdata.ScriptData';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.AppData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.AppData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.AppData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.AppData.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectSettings: (f = msg.getProjectSettings()) && proto.be.basalte.config.appdata.ProjectSettings.toObject(includeInstance, f),
    userDataMap: (f = msg.getUserDataMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.UserData.toObject) : [],
    audioData: (f = msg.getAudioData()) && proto.be.basalte.config.appdata.AudioData.toObject(includeInstance, f),
    timestamp: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    timestampUs: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    description: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    uuid: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    deviceData: (f = msg.getDeviceData()) && proto.be.basalte.config.appdata.DeviceData.toObject(includeInstance, f),
    storageMap: (f = msg.getStorageMap()) ? f.toObject(includeInstance, undefined) : [],
    scriptData: (f = msg.getScriptData()) && proto.be.basalte.config.appdata.ScriptData.toObject(includeInstance, f),
    blobsMap: (f = msg.getBlobsMap()) ? f.toObject(includeInstance, proto.be.basalte.config.common.DataBlob.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.AppData}
 */
proto.be.basalte.config.appdata.AppData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.AppData;
  return proto.be.basalte.config.appdata.AppData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.AppData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.AppData}
 */
proto.be.basalte.config.appdata.AppData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = new proto.be.basalte.config.appdata.ProjectSettings;
      reader.readMessage(value,proto.be.basalte.config.appdata.ProjectSettings.deserializeBinaryFromReader);
      msg.setProjectSettings(value);
      break;
    case 1:
      var value = msg.getUserDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.UserData.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.UserData());
         });
      break;
    case 2:
      var value = new proto.be.basalte.config.appdata.AudioData;
      reader.readMessage(value,proto.be.basalte.config.appdata.AudioData.deserializeBinaryFromReader);
      msg.setAudioData(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestampUs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 7:
      var value = new proto.be.basalte.config.appdata.DeviceData;
      reader.readMessage(value,proto.be.basalte.config.appdata.DeviceData.deserializeBinaryFromReader);
      msg.setDeviceData(value);
      break;
    case 8:
      var value = msg.getStorageMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 9:
      var value = new proto.be.basalte.config.appdata.ScriptData;
      reader.readMessage(value,proto.be.basalte.config.appdata.ScriptData.deserializeBinaryFromReader);
      msg.setScriptData(value);
      break;
    case 11:
      var value = msg.getBlobsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.common.DataBlob.deserializeBinaryFromReader, "", new proto.be.basalte.config.common.DataBlob());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.AppData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.AppData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.AppData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.AppData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectSettings();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.be.basalte.config.appdata.ProjectSettings.serializeBinaryToWriter
    );
  }
  f = message.getUserDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.UserData.serializeBinaryToWriter);
  }
  f = message.getAudioData();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.be.basalte.config.appdata.AudioData.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDeviceData();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.be.basalte.config.appdata.DeviceData.serializeBinaryToWriter
    );
  }
  f = message.getStorageMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(8, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getScriptData();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.be.basalte.config.appdata.ScriptData.serializeBinaryToWriter
    );
  }
  f = message.getBlobsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.common.DataBlob.serializeBinaryToWriter);
  }
};


/**
 * optional ProjectSettings project_settings = 10;
 * @return {?proto.be.basalte.config.appdata.ProjectSettings}
 */
proto.be.basalte.config.appdata.AppData.prototype.getProjectSettings = function() {
  return /** @type{?proto.be.basalte.config.appdata.ProjectSettings} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.ProjectSettings, 10));
};


/**
 * @param {?proto.be.basalte.config.appdata.ProjectSettings|undefined} value
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
*/
proto.be.basalte.config.appdata.AppData.prototype.setProjectSettings = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearProjectSettings = function() {
  return this.setProjectSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.AppData.prototype.hasProjectSettings = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * map<string, UserData> user_data = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.UserData>}
 */
proto.be.basalte.config.appdata.AppData.prototype.getUserDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.UserData>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.be.basalte.config.appdata.UserData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearUserDataMap = function() {
  this.getUserDataMap().clear();
  return this;};


/**
 * optional AudioData audio_data = 2;
 * @return {?proto.be.basalte.config.appdata.AudioData}
 */
proto.be.basalte.config.appdata.AppData.prototype.getAudioData = function() {
  return /** @type{?proto.be.basalte.config.appdata.AudioData} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.AudioData, 2));
};


/**
 * @param {?proto.be.basalte.config.appdata.AudioData|undefined} value
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
*/
proto.be.basalte.config.appdata.AppData.prototype.setAudioData = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearAudioData = function() {
  return this.setAudioData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.AppData.prototype.hasAudioData = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 timestamp = 3;
 * @return {number}
 */
proto.be.basalte.config.appdata.AppData.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.setTimestamp = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearTimestamp = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.AppData.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 timestamp_us = 6;
 * @return {number}
 */
proto.be.basalte.config.appdata.AppData.prototype.getTimestampUs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.setTimestampUs = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearTimestampUs = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.AppData.prototype.hasTimestampUs = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.be.basalte.config.appdata.AppData.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.setDescription = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearDescription = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.AppData.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string uuid = 5;
 * @return {string}
 */
proto.be.basalte.config.appdata.AppData.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.setUuid = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearUuid = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.AppData.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DeviceData device_data = 7;
 * @return {?proto.be.basalte.config.appdata.DeviceData}
 */
proto.be.basalte.config.appdata.AppData.prototype.getDeviceData = function() {
  return /** @type{?proto.be.basalte.config.appdata.DeviceData} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.DeviceData, 7));
};


/**
 * @param {?proto.be.basalte.config.appdata.DeviceData|undefined} value
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
*/
proto.be.basalte.config.appdata.AppData.prototype.setDeviceData = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearDeviceData = function() {
  return this.setDeviceData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.AppData.prototype.hasDeviceData = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * map<string, string> storage = 8;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.be.basalte.config.appdata.AppData.prototype.getStorageMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 8, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearStorageMap = function() {
  this.getStorageMap().clear();
  return this;};


/**
 * optional ScriptData script_data = 9;
 * @return {?proto.be.basalte.config.appdata.ScriptData}
 */
proto.be.basalte.config.appdata.AppData.prototype.getScriptData = function() {
  return /** @type{?proto.be.basalte.config.appdata.ScriptData} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.ScriptData, 9));
};


/**
 * @param {?proto.be.basalte.config.appdata.ScriptData|undefined} value
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
*/
proto.be.basalte.config.appdata.AppData.prototype.setScriptData = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearScriptData = function() {
  return this.setScriptData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.AppData.prototype.hasScriptData = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * map<string, be.basalte.config.common.DataBlob> blobs = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.common.DataBlob>}
 */
proto.be.basalte.config.appdata.AppData.prototype.getBlobsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.common.DataBlob>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      proto.be.basalte.config.common.DataBlob));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.AppData} returns this
 */
proto.be.basalte.config.appdata.AppData.prototype.clearBlobsMap = function() {
  this.getBlobsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.ProjectSettings.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.ProjectSettings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.ProjectSettings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ProjectSettings.toObject = function(includeInstance, msg) {
  var f, obj = {
    integratorRemoteAccess: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.ProjectSettings}
 */
proto.be.basalte.config.appdata.ProjectSettings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.ProjectSettings;
  return proto.be.basalte.config.appdata.ProjectSettings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.ProjectSettings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.ProjectSettings}
 */
proto.be.basalte.config.appdata.ProjectSettings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIntegratorRemoteAccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.ProjectSettings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.ProjectSettings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.ProjectSettings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ProjectSettings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool integrator_remote_access = 1;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ProjectSettings.prototype.getIntegratorRemoteAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.ProjectSettings} returns this
 */
proto.be.basalte.config.appdata.ProjectSettings.prototype.setIntegratorRemoteAccess = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ProjectSettings} returns this
 */
proto.be.basalte.config.appdata.ProjectSettings.prototype.clearIntegratorRemoteAccess = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ProjectSettings.prototype.hasIntegratorRemoteAccess = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.be.basalte.config.appdata.UserData.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.UserData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.UserData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.UserData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.UserData.toObject = function(includeInstance, msg) {
  var f, obj = {
    alarmsList: jspb.Message.toObjectList(msg.getAlarmsList(),
    CoreCommon_pb.AlarmClock.toObject, includeInstance),
    roomclustersList: jspb.Message.toObjectList(msg.getRoomclustersList(),
    CoreCommon_pb.RoomCluster.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.UserData}
 */
proto.be.basalte.config.appdata.UserData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.UserData;
  return proto.be.basalte.config.appdata.UserData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.UserData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.UserData}
 */
proto.be.basalte.config.appdata.UserData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new CoreCommon_pb.AlarmClock;
      reader.readMessage(value,CoreCommon_pb.AlarmClock.deserializeBinaryFromReader);
      msg.addAlarms(value);
      break;
    case 2:
      var value = new CoreCommon_pb.RoomCluster;
      reader.readMessage(value,CoreCommon_pb.RoomCluster.deserializeBinaryFromReader);
      msg.addRoomclusters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.UserData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.UserData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.UserData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.UserData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlarmsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      CoreCommon_pb.AlarmClock.serializeBinaryToWriter
    );
  }
  f = message.getRoomclustersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      CoreCommon_pb.RoomCluster.serializeBinaryToWriter
    );
  }
};


/**
 * repeated be.basalte.config.common.AlarmClock alarms = 1;
 * @return {!Array<!proto.be.basalte.config.common.AlarmClock>}
 */
proto.be.basalte.config.appdata.UserData.prototype.getAlarmsList = function() {
  return /** @type{!Array<!proto.be.basalte.config.common.AlarmClock>} */ (
    jspb.Message.getRepeatedWrapperField(this, CoreCommon_pb.AlarmClock, 1));
};


/**
 * @param {!Array<!proto.be.basalte.config.common.AlarmClock>} value
 * @return {!proto.be.basalte.config.appdata.UserData} returns this
*/
proto.be.basalte.config.appdata.UserData.prototype.setAlarmsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.be.basalte.config.common.AlarmClock=} opt_value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.common.AlarmClock}
 */
proto.be.basalte.config.appdata.UserData.prototype.addAlarms = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.be.basalte.config.common.AlarmClock, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.UserData} returns this
 */
proto.be.basalte.config.appdata.UserData.prototype.clearAlarmsList = function() {
  return this.setAlarmsList([]);
};


/**
 * repeated be.basalte.config.common.RoomCluster roomclusters = 2;
 * @return {!Array<!proto.be.basalte.config.common.RoomCluster>}
 */
proto.be.basalte.config.appdata.UserData.prototype.getRoomclustersList = function() {
  return /** @type{!Array<!proto.be.basalte.config.common.RoomCluster>} */ (
    jspb.Message.getRepeatedWrapperField(this, CoreCommon_pb.RoomCluster, 2));
};


/**
 * @param {!Array<!proto.be.basalte.config.common.RoomCluster>} value
 * @return {!proto.be.basalte.config.appdata.UserData} returns this
*/
proto.be.basalte.config.appdata.UserData.prototype.setRoomclustersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.be.basalte.config.common.RoomCluster=} opt_value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.common.RoomCluster}
 */
proto.be.basalte.config.appdata.UserData.prototype.addRoomclusters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.be.basalte.config.common.RoomCluster, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.UserData} returns this
 */
proto.be.basalte.config.appdata.UserData.prototype.clearRoomclustersList = function() {
  return this.setRoomclustersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.AudioData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.AudioData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.AudioData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.AudioData.toObject = function(includeInstance, msg) {
  var f, obj = {
    playlistsMap: (f = msg.getPlaylistsMap()) ? f.toObject(includeInstance, proto.be.basalte.config.common.Playlist.toObject) : [],
    streamDataMap: (f = msg.getStreamDataMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.StreamData.toObject) : [],
    dspOutputDataMap: (f = msg.getDspOutputDataMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.DspData.toObject) : [],
    dspRoomDataMap: (f = msg.getDspRoomDataMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.DspRoomData.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.AudioData}
 */
proto.be.basalte.config.appdata.AudioData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.AudioData;
  return proto.be.basalte.config.appdata.AudioData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.AudioData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.AudioData}
 */
proto.be.basalte.config.appdata.AudioData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getPlaylistsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.common.Playlist.deserializeBinaryFromReader, "", new proto.be.basalte.config.common.Playlist());
         });
      break;
    case 2:
      var value = msg.getStreamDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.StreamData.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.StreamData());
         });
      break;
    case 3:
      var value = msg.getDspOutputDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.DspData.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.DspData());
         });
      break;
    case 4:
      var value = msg.getDspRoomDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.DspRoomData.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.DspRoomData());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.AudioData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.AudioData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.AudioData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.AudioData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlaylistsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.common.Playlist.serializeBinaryToWriter);
  }
  f = message.getStreamDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.StreamData.serializeBinaryToWriter);
  }
  f = message.getDspOutputDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.DspData.serializeBinaryToWriter);
  }
  f = message.getDspRoomDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.DspRoomData.serializeBinaryToWriter);
  }
};


/**
 * map<string, be.basalte.config.common.Playlist> playlists = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.common.Playlist>}
 */
proto.be.basalte.config.appdata.AudioData.prototype.getPlaylistsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.common.Playlist>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.be.basalte.config.common.Playlist));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.AudioData} returns this
 */
proto.be.basalte.config.appdata.AudioData.prototype.clearPlaylistsMap = function() {
  this.getPlaylistsMap().clear();
  return this;};


/**
 * map<string, StreamData> stream_data = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.StreamData>}
 */
proto.be.basalte.config.appdata.AudioData.prototype.getStreamDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.StreamData>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.be.basalte.config.appdata.StreamData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.AudioData} returns this
 */
proto.be.basalte.config.appdata.AudioData.prototype.clearStreamDataMap = function() {
  this.getStreamDataMap().clear();
  return this;};


/**
 * map<string, DspData> dsp_output_data = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.DspData>}
 */
proto.be.basalte.config.appdata.AudioData.prototype.getDspOutputDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.DspData>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.be.basalte.config.appdata.DspData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.AudioData} returns this
 */
proto.be.basalte.config.appdata.AudioData.prototype.clearDspOutputDataMap = function() {
  this.getDspOutputDataMap().clear();
  return this;};


/**
 * map<string, DspRoomData> dsp_room_data = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.DspRoomData>}
 */
proto.be.basalte.config.appdata.AudioData.prototype.getDspRoomDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.DspRoomData>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.be.basalte.config.appdata.DspRoomData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.AudioData} returns this
 */
proto.be.basalte.config.appdata.AudioData.prototype.clearDspRoomDataMap = function() {
  this.getDspRoomDataMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.be.basalte.config.appdata.StreamData.repeatedFields_ = [1,3,4,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.StreamData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.StreamData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.StreamData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.StreamData.toObject = function(includeInstance, msg) {
  var f, obj = {
    favouritesList: jspb.Message.toObjectList(msg.getFavouritesList(),
    CoreCommon_pb.Preset.toObject, includeInstance),
    knxPresetsMap: (f = msg.getKnxPresetsMap()) ? f.toObject(includeInstance, proto.be.basalte.config.common.Preset.toObject) : [],
    defaultRoomUuidsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    favouriteUrisList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    presetUrisMap: (f = msg.getPresetUrisMap()) ? f.toObject(includeInstance, undefined) : [],
    quickFavouriteUrisList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    favouriteInfoMap: (f = msg.getFavouriteInfoMap()) ? f.toObject(includeInstance, proto.be.basalte.config.common.Favourite.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.StreamData}
 */
proto.be.basalte.config.appdata.StreamData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.StreamData;
  return proto.be.basalte.config.appdata.StreamData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.StreamData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.StreamData}
 */
proto.be.basalte.config.appdata.StreamData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new CoreCommon_pb.Preset;
      reader.readMessage(value,CoreCommon_pb.Preset.deserializeBinaryFromReader);
      msg.addFavourites(value);
      break;
    case 2:
      var value = msg.getKnxPresetsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.common.Preset.deserializeBinaryFromReader, 0, new proto.be.basalte.config.common.Preset());
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addDefaultRoomUuids(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addFavouriteUris(value);
      break;
    case 5:
      var value = msg.getPresetUrisMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readString, null, 0, "");
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addQuickFavouriteUris(value);
      break;
    case 7:
      var value = msg.getFavouriteInfoMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.common.Favourite.deserializeBinaryFromReader, "", new proto.be.basalte.config.common.Favourite());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.StreamData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.StreamData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.StreamData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.StreamData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFavouritesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      CoreCommon_pb.Preset.serializeBinaryToWriter
    );
  }
  f = message.getKnxPresetsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.common.Preset.serializeBinaryToWriter);
  }
  f = message.getDefaultRoomUuidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getFavouriteUrisList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getPresetUrisMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getQuickFavouriteUrisList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getFavouriteInfoMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.common.Favourite.serializeBinaryToWriter);
  }
};


/**
 * repeated be.basalte.config.common.Preset favourites = 1;
 * @return {!Array<!proto.be.basalte.config.common.Preset>}
 */
proto.be.basalte.config.appdata.StreamData.prototype.getFavouritesList = function() {
  return /** @type{!Array<!proto.be.basalte.config.common.Preset>} */ (
    jspb.Message.getRepeatedWrapperField(this, CoreCommon_pb.Preset, 1));
};


/**
 * @param {!Array<!proto.be.basalte.config.common.Preset>} value
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
*/
proto.be.basalte.config.appdata.StreamData.prototype.setFavouritesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.be.basalte.config.common.Preset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.common.Preset}
 */
proto.be.basalte.config.appdata.StreamData.prototype.addFavourites = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.be.basalte.config.common.Preset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.clearFavouritesList = function() {
  return this.setFavouritesList([]);
};


/**
 * map<uint32, be.basalte.config.common.Preset> knx_presets = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.be.basalte.config.common.Preset>}
 */
proto.be.basalte.config.appdata.StreamData.prototype.getKnxPresetsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.be.basalte.config.common.Preset>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.be.basalte.config.common.Preset));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.clearKnxPresetsMap = function() {
  this.getKnxPresetsMap().clear();
  return this;};


/**
 * repeated string default_room_uuids = 3;
 * @return {!Array<string>}
 */
proto.be.basalte.config.appdata.StreamData.prototype.getDefaultRoomUuidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.setDefaultRoomUuidsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.addDefaultRoomUuids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.clearDefaultRoomUuidsList = function() {
  return this.setDefaultRoomUuidsList([]);
};


/**
 * repeated string favourite_uris = 4;
 * @return {!Array<string>}
 */
proto.be.basalte.config.appdata.StreamData.prototype.getFavouriteUrisList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.setFavouriteUrisList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.addFavouriteUris = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.clearFavouriteUrisList = function() {
  return this.setFavouriteUrisList([]);
};


/**
 * map<uint32, string> preset_uris = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,string>}
 */
proto.be.basalte.config.appdata.StreamData.prototype.getPresetUrisMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.clearPresetUrisMap = function() {
  this.getPresetUrisMap().clear();
  return this;};


/**
 * repeated string quick_favourite_uris = 6;
 * @return {!Array<string>}
 */
proto.be.basalte.config.appdata.StreamData.prototype.getQuickFavouriteUrisList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.setQuickFavouriteUrisList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.addQuickFavouriteUris = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.clearQuickFavouriteUrisList = function() {
  return this.setQuickFavouriteUrisList([]);
};


/**
 * map<string, be.basalte.config.common.Favourite> favourite_info = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.common.Favourite>}
 */
proto.be.basalte.config.appdata.StreamData.prototype.getFavouriteInfoMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.common.Favourite>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.be.basalte.config.common.Favourite));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.StreamData} returns this
 */
proto.be.basalte.config.appdata.StreamData.prototype.clearFavouriteInfoMap = function() {
  this.getFavouriteInfoMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.DspData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.DspData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.DspData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspData.toObject = function(includeInstance, msg) {
  var f, obj = {
    equalisersMap: (f = msg.getEqualisersMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.DspEqualiser.toObject) : [],
    shelfFiltersMap: (f = msg.getShelfFiltersMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.DspShelfFilter.toObject) : [],
    passFiltersMap: (f = msg.getPassFiltersMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.DspPassFilter.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.DspData}
 */
proto.be.basalte.config.appdata.DspData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.DspData;
  return proto.be.basalte.config.appdata.DspData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.DspData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.DspData}
 */
proto.be.basalte.config.appdata.DspData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getEqualisersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.DspEqualiser.deserializeBinaryFromReader, 0, new proto.be.basalte.config.appdata.DspEqualiser());
         });
      break;
    case 2:
      var value = msg.getShelfFiltersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.DspShelfFilter.deserializeBinaryFromReader, 0, new proto.be.basalte.config.appdata.DspShelfFilter());
         });
      break;
    case 3:
      var value = msg.getPassFiltersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.DspPassFilter.deserializeBinaryFromReader, 0, new proto.be.basalte.config.appdata.DspPassFilter());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.DspData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.DspData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.DspData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEqualisersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.DspEqualiser.serializeBinaryToWriter);
  }
  f = message.getShelfFiltersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.DspShelfFilter.serializeBinaryToWriter);
  }
  f = message.getPassFiltersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.DspPassFilter.serializeBinaryToWriter);
  }
};


/**
 * map<uint32, DspEqualiser> equalisers = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.be.basalte.config.appdata.DspEqualiser>}
 */
proto.be.basalte.config.appdata.DspData.prototype.getEqualisersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.be.basalte.config.appdata.DspEqualiser>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.be.basalte.config.appdata.DspEqualiser));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DspData} returns this
 */
proto.be.basalte.config.appdata.DspData.prototype.clearEqualisersMap = function() {
  this.getEqualisersMap().clear();
  return this;};


/**
 * map<uint32, DspShelfFilter> shelf_filters = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.be.basalte.config.appdata.DspShelfFilter>}
 */
proto.be.basalte.config.appdata.DspData.prototype.getShelfFiltersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.be.basalte.config.appdata.DspShelfFilter>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.be.basalte.config.appdata.DspShelfFilter));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DspData} returns this
 */
proto.be.basalte.config.appdata.DspData.prototype.clearShelfFiltersMap = function() {
  this.getShelfFiltersMap().clear();
  return this;};


/**
 * map<uint32, DspPassFilter> pass_filters = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.be.basalte.config.appdata.DspPassFilter>}
 */
proto.be.basalte.config.appdata.DspData.prototype.getPassFiltersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.be.basalte.config.appdata.DspPassFilter>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.be.basalte.config.appdata.DspPassFilter));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DspData} returns this
 */
proto.be.basalte.config.appdata.DspData.prototype.clearPassFiltersMap = function() {
  this.getPassFiltersMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.DspRoomData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.DspRoomData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspRoomData.toObject = function(includeInstance, msg) {
  var f, obj = {
    equalisersMap: (f = msg.getEqualisersMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.DspEqualiser.toObject) : [],
    shelfFiltersMap: (f = msg.getShelfFiltersMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.DspShelfFilter.toObject) : [],
    bass: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    treble: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.DspRoomData}
 */
proto.be.basalte.config.appdata.DspRoomData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.DspRoomData;
  return proto.be.basalte.config.appdata.DspRoomData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.DspRoomData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.DspRoomData}
 */
proto.be.basalte.config.appdata.DspRoomData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getEqualisersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.DspEqualiser.deserializeBinaryFromReader, 0, new proto.be.basalte.config.appdata.DspEqualiser());
         });
      break;
    case 2:
      var value = msg.getShelfFiltersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readUint32, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.DspShelfFilter.deserializeBinaryFromReader, 0, new proto.be.basalte.config.appdata.DspShelfFilter());
         });
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBass(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTreble(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.DspRoomData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.DspRoomData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspRoomData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEqualisersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.DspEqualiser.serializeBinaryToWriter);
  }
  f = message.getShelfFiltersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeUint32, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.DspShelfFilter.serializeBinaryToWriter);
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * map<uint32, DspEqualiser> equalisers = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.be.basalte.config.appdata.DspEqualiser>}
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.getEqualisersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.be.basalte.config.appdata.DspEqualiser>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.be.basalte.config.appdata.DspEqualiser));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DspRoomData} returns this
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.clearEqualisersMap = function() {
  this.getEqualisersMap().clear();
  return this;};


/**
 * map<uint32, DspShelfFilter> shelf_filters = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.be.basalte.config.appdata.DspShelfFilter>}
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.getShelfFiltersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.be.basalte.config.appdata.DspShelfFilter>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.be.basalte.config.appdata.DspShelfFilter));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DspRoomData} returns this
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.clearShelfFiltersMap = function() {
  this.getShelfFiltersMap().clear();
  return this;};


/**
 * optional double bass = 3;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.getBass = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspRoomData} returns this
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.setBass = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspRoomData} returns this
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.clearBass = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.hasBass = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double treble = 4;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.getTreble = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspRoomData} returns this
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.setTreble = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspRoomData} returns this
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.clearTreble = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspRoomData.prototype.hasTreble = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.DspEqualiser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.DspEqualiser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspEqualiser.toObject = function(includeInstance, msg) {
  var f, obj = {
    bypass: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    frequency: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    gain: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    bandwidth: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.DspEqualiser}
 */
proto.be.basalte.config.appdata.DspEqualiser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.DspEqualiser;
  return proto.be.basalte.config.appdata.DspEqualiser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.DspEqualiser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.DspEqualiser}
 */
proto.be.basalte.config.appdata.DspEqualiser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBypass(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGain(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBandwidth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.DspEqualiser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.DspEqualiser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspEqualiser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional bool bypass = 4;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.getBypass = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.DspEqualiser} returns this
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.setBypass = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspEqualiser} returns this
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.clearBypass = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.hasBypass = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 frequency = 1;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspEqualiser} returns this
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.setFrequency = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspEqualiser} returns this
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.clearFrequency = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.hasFrequency = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double gain = 2;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.getGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspEqualiser} returns this
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.setGain = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspEqualiser} returns this
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.clearGain = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.hasGain = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double bandwidth = 3;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.getBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspEqualiser} returns this
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.setBandwidth = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspEqualiser} returns this
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.clearBandwidth = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspEqualiser.prototype.hasBandwidth = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.DspShelfFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.DspShelfFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspShelfFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    frequency: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    gain: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.DspShelfFilter}
 */
proto.be.basalte.config.appdata.DspShelfFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.DspShelfFilter;
  return proto.be.basalte.config.appdata.DspShelfFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.DspShelfFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.DspShelfFilter}
 */
proto.be.basalte.config.appdata.DspShelfFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrequency(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGain(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.DspShelfFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.DspShelfFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspShelfFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional uint32 frequency = 1;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspShelfFilter} returns this
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.setFrequency = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspShelfFilter} returns this
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.clearFrequency = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.hasFrequency = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double gain = 2;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.getGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspShelfFilter} returns this
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.setGain = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspShelfFilter} returns this
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.clearGain = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspShelfFilter.prototype.hasGain = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.DspPassFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.DspPassFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspPassFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    bypass: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    invert: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    filterType: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    besselNorm: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    qFactor: (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null ? undefined : f,
    frequency: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    gain: (f = jspb.Message.getOptionalFloatingPointField(msg, 7)) == null ? undefined : f,
    order: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter}
 */
proto.be.basalte.config.appdata.DspPassFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.DspPassFilter;
  return proto.be.basalte.config.appdata.DspPassFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.DspPassFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter}
 */
proto.be.basalte.config.appdata.DspPassFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBypass(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInvert(value);
      break;
    case 3:
      var value = /** @type {!proto.be.basalte.config.appdata.DspPassFilter.DspPassFilterType} */ (reader.readEnum());
      msg.setFilterType(value);
      break;
    case 4:
      var value = /** @type {!proto.be.basalte.config.appdata.DspPassFilter.DspBesselNormType} */ (reader.readEnum());
      msg.setBesselNorm(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQFactor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFrequency(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGain(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.DspPassFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.DspPassFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DspPassFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.appdata.DspPassFilter.DspPassFilterType} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.appdata.DspPassFilter.DspBesselNormType} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.be.basalte.config.appdata.DspPassFilter.DspPassFilterType = {
  DSP_PF_VARIABLE_Q: 0,
  DSP_PF_BUTTERWORTH: 1,
  DSP_PF_LINKWITZ_RILEY: 2,
  DSP_PF_BESSEL: 3
};

/**
 * @enum {number}
 */
proto.be.basalte.config.appdata.DspPassFilter.DspBesselNormType = {
  DSP_BN_PHASE_MATCH: 0,
  DSP_BN_TIME_DELAY: 1,
  DSP_BN_MIN_3DB: 2,
  DSP_BN_MIN_6DB: 3,
  DSP_BN_NONE: 4
};

/**
 * optional bool bypass = 1;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.getBypass = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.setBypass = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.clearBypass = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.hasBypass = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool invert = 2;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.getInvert = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.setInvert = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.clearInvert = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.hasInvert = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DspPassFilterType filter_type = 3;
 * @return {!proto.be.basalte.config.appdata.DspPassFilter.DspPassFilterType}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.getFilterType = function() {
  return /** @type {!proto.be.basalte.config.appdata.DspPassFilter.DspPassFilterType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.be.basalte.config.appdata.DspPassFilter.DspPassFilterType} value
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.setFilterType = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.clearFilterType = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.hasFilterType = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DspBesselNormType bessel_norm = 4;
 * @return {!proto.be.basalte.config.appdata.DspPassFilter.DspBesselNormType}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.getBesselNorm = function() {
  return /** @type {!proto.be.basalte.config.appdata.DspPassFilter.DspBesselNormType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.be.basalte.config.appdata.DspPassFilter.DspBesselNormType} value
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.setBesselNorm = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.clearBesselNorm = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.hasBesselNorm = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double q_factor = 5;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.getQFactor = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.setQFactor = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.clearQFactor = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.hasQFactor = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 frequency = 6;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.setFrequency = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.clearFrequency = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.hasFrequency = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double gain = 7;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.getGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.setGain = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.clearGain = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.hasGain = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 order = 8;
 * @return {number}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.setOrder = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.DspPassFilter} returns this
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.clearOrder = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.DspPassFilter.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.DeviceData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.DeviceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.DeviceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DeviceData.toObject = function(includeInstance, msg) {
  var f, obj = {
    thermostatSchedulesMap: (f = msg.getThermostatSchedulesMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.ThermostatSchedule.toObject) : [],
    scenecontrollerDataMap: (f = msg.getScenecontrollerDataMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.SceneControllerData.toObject) : [],
    uiDeviceDataMap: (f = msg.getUiDeviceDataMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.UIDeviceData.toObject) : [],
    scheduleDataMap: (f = msg.getScheduleDataMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.ScheduleData.toObject) : [],
    tvDataMap: (f = msg.getTvDataMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.TVData.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.DeviceData}
 */
proto.be.basalte.config.appdata.DeviceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.DeviceData;
  return proto.be.basalte.config.appdata.DeviceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.DeviceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.DeviceData}
 */
proto.be.basalte.config.appdata.DeviceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getThermostatSchedulesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.ThermostatSchedule.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.ThermostatSchedule());
         });
      break;
    case 2:
      var value = msg.getScenecontrollerDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.SceneControllerData.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.SceneControllerData());
         });
      break;
    case 3:
      var value = msg.getUiDeviceDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.UIDeviceData.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.UIDeviceData());
         });
      break;
    case 4:
      var value = msg.getScheduleDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.ScheduleData.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.ScheduleData());
         });
      break;
    case 5:
      var value = msg.getTvDataMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.TVData.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.TVData());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.DeviceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.DeviceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.DeviceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.DeviceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getThermostatSchedulesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.ThermostatSchedule.serializeBinaryToWriter);
  }
  f = message.getScenecontrollerDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.SceneControllerData.serializeBinaryToWriter);
  }
  f = message.getUiDeviceDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.UIDeviceData.serializeBinaryToWriter);
  }
  f = message.getScheduleDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.ScheduleData.serializeBinaryToWriter);
  }
  f = message.getTvDataMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.TVData.serializeBinaryToWriter);
  }
};


/**
 * map<string, ThermostatSchedule> thermostat_schedules = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.ThermostatSchedule>}
 */
proto.be.basalte.config.appdata.DeviceData.prototype.getThermostatSchedulesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.ThermostatSchedule>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.be.basalte.config.appdata.ThermostatSchedule));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DeviceData} returns this
 */
proto.be.basalte.config.appdata.DeviceData.prototype.clearThermostatSchedulesMap = function() {
  this.getThermostatSchedulesMap().clear();
  return this;};


/**
 * map<string, SceneControllerData> scenecontroller_data = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneControllerData>}
 */
proto.be.basalte.config.appdata.DeviceData.prototype.getScenecontrollerDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneControllerData>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.be.basalte.config.appdata.SceneControllerData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DeviceData} returns this
 */
proto.be.basalte.config.appdata.DeviceData.prototype.clearScenecontrollerDataMap = function() {
  this.getScenecontrollerDataMap().clear();
  return this;};


/**
 * map<string, UIDeviceData> ui_device_data = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.UIDeviceData>}
 */
proto.be.basalte.config.appdata.DeviceData.prototype.getUiDeviceDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.UIDeviceData>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.be.basalte.config.appdata.UIDeviceData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DeviceData} returns this
 */
proto.be.basalte.config.appdata.DeviceData.prototype.clearUiDeviceDataMap = function() {
  this.getUiDeviceDataMap().clear();
  return this;};


/**
 * map<string, ScheduleData> schedule_data = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.ScheduleData>}
 */
proto.be.basalte.config.appdata.DeviceData.prototype.getScheduleDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.ScheduleData>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.be.basalte.config.appdata.ScheduleData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DeviceData} returns this
 */
proto.be.basalte.config.appdata.DeviceData.prototype.clearScheduleDataMap = function() {
  this.getScheduleDataMap().clear();
  return this;};


/**
 * map<string, TVData> tv_data = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.TVData>}
 */
proto.be.basalte.config.appdata.DeviceData.prototype.getTvDataMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.TVData>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.be.basalte.config.appdata.TVData));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.DeviceData} returns this
 */
proto.be.basalte.config.appdata.DeviceData.prototype.clearTvDataMap = function() {
  this.getTvDataMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.be.basalte.config.appdata.ThermostatSchedule.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.ThermostatSchedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.ThermostatSchedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ThermostatSchedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    setpointsList: jspb.Message.toObjectList(msg.getSetpointsList(),
    proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.ThermostatSchedule;
  return proto.be.basalte.config.appdata.ThermostatSchedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.ThermostatSchedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = new proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint;
      reader.readMessage(value,proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.deserializeBinaryFromReader);
      msg.addSetpoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.ThermostatSchedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.ThermostatSchedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ThermostatSchedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getSetpointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    dayOfWeek: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    time: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    setpoint: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    type: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint;
  return proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDayOfWeek(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSetpoint(value);
      break;
    case 4:
      var value = /** @type {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.SetpointType} */ (reader.readEnum());
      msg.setType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.SetpointType} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.SetpointType = {
  ANY: 0,
  HEAT: 1,
  COOL: 2,
  AUTO: 3
};

/**
 * optional uint32 day_of_week = 1;
 * @return {number}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.getDayOfWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.clearDayOfWeek = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.hasDayOfWeek = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 time = 2;
 * @return {number}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.clearTime = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double setpoint = 3;
 * @return {number}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.getSetpoint = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.setSetpoint = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.clearSetpoint = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.hasSetpoint = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SetpointType type = 4;
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.SetpointType}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.getType = function() {
  return /** @type {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.SetpointType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.SetpointType} value
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.setType = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.clearType = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint.prototype.hasType = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.setEnabled = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.clearEnabled = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.hasEnabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Setpoint setpoints = 2;
 * @return {!Array<!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint>}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.getSetpointsList = function() {
  return /** @type{!Array<!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint, 2));
};


/**
 * @param {!Array<!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint>} value
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule} returns this
*/
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.setSetpointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint}
 */
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.addSetpoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.be.basalte.config.appdata.ThermostatSchedule.Setpoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.ThermostatSchedule} returns this
 */
proto.be.basalte.config.appdata.ThermostatSchedule.prototype.clearSetpointsList = function() {
  return this.setSetpointsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.be.basalte.config.appdata.SceneControllerData.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneControllerData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneControllerData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneControllerData.toObject = function(includeInstance, msg) {
  var f, obj = {
    scenesMap: (f = msg.getScenesMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.Scene.toObject) : [],
    jobsMap: (f = msg.getJobsMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.SceneJob.toObject) : [],
    favouritesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneControllerData}
 */
proto.be.basalte.config.appdata.SceneControllerData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneControllerData;
  return proto.be.basalte.config.appdata.SceneControllerData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneControllerData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneControllerData}
 */
proto.be.basalte.config.appdata.SceneControllerData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getScenesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.Scene.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.Scene());
         });
      break;
    case 2:
      var value = msg.getJobsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.SceneJob.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.SceneJob());
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addFavourites(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneControllerData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneControllerData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneControllerData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScenesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.Scene.serializeBinaryToWriter);
  }
  f = message.getJobsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.SceneJob.serializeBinaryToWriter);
  }
  f = message.getFavouritesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * map<string, Scene> scenes = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.Scene>}
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.getScenesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.Scene>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.be.basalte.config.appdata.Scene));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.SceneControllerData} returns this
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.clearScenesMap = function() {
  this.getScenesMap().clear();
  return this;};


/**
 * map<string, SceneJob> jobs = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneJob>}
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.getJobsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneJob>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.be.basalte.config.appdata.SceneJob));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.SceneControllerData} returns this
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.clearJobsMap = function() {
  this.getJobsMap().clear();
  return this;};


/**
 * repeated string favourites = 3;
 * @return {!Array<string>}
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.getFavouritesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.be.basalte.config.appdata.SceneControllerData} returns this
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.setFavouritesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.appdata.SceneControllerData} returns this
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.addFavourites = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.SceneControllerData} returns this
 */
proto.be.basalte.config.appdata.SceneControllerData.prototype.clearFavouritesList = function() {
  return this.setFavouritesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.Scene.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.Scene.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.Scene} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.Scene.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    uuid: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    preset: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    knxValue: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    avatar: msg.getAvatar_asB64(),
    colour: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    favourite: (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f,
    data: (f = msg.getData()) && proto.be.basalte.config.appdata.SceneConfig.toObject(includeInstance, f),
    shared: (f = jspb.Message.getBooleanField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.Scene}
 */
proto.be.basalte.config.appdata.Scene.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.Scene;
  return proto.be.basalte.config.appdata.Scene.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.Scene} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.Scene}
 */
proto.be.basalte.config.appdata.Scene.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 6:
      var value = /** @type {!proto.be.basalte.enums.ScenePreset} */ (reader.readEnum());
      msg.setPreset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setKnxValue(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAvatar(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setColour(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFavourite(value);
      break;
    case 8:
      var value = new proto.be.basalte.config.appdata.SceneConfig;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setShared(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.Scene.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.Scene.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.Scene} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.Scene.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {!proto.be.basalte.enums.ScenePreset} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.be.basalte.config.appdata.SceneConfig.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.be.basalte.config.appdata.Scene.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.setName = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.clearName = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.hasName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string uuid = 2;
 * @return {string}
 */
proto.be.basalte.config.appdata.Scene.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.setUuid = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.clearUuid = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional be.basalte.enums.ScenePreset preset = 6;
 * @return {!proto.be.basalte.enums.ScenePreset}
 */
proto.be.basalte.config.appdata.Scene.prototype.getPreset = function() {
  return /** @type {!proto.be.basalte.enums.ScenePreset} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.be.basalte.enums.ScenePreset} value
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.setPreset = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.clearPreset = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.hasPreset = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 knx_value = 3;
 * @return {number}
 */
proto.be.basalte.config.appdata.Scene.prototype.getKnxValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.setKnxValue = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.clearKnxValue = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.hasKnxValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bytes avatar = 4;
 * @return {!(string|Uint8Array)}
 */
proto.be.basalte.config.appdata.Scene.prototype.getAvatar = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes avatar = 4;
 * This is a type-conversion wrapper around `getAvatar()`
 * @return {string}
 */
proto.be.basalte.config.appdata.Scene.prototype.getAvatar_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAvatar()));
};


/**
 * optional bytes avatar = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAvatar()`
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.Scene.prototype.getAvatar_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAvatar()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.setAvatar = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.clearAvatar = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.hasAvatar = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 colour = 5;
 * @return {number}
 */
proto.be.basalte.config.appdata.Scene.prototype.getColour = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.setColour = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.clearColour = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.hasColour = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool favourite = 7;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.getFavourite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.setFavourite = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.clearFavourite = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.hasFavourite = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SceneConfig data = 8;
 * @return {?proto.be.basalte.config.appdata.SceneConfig}
 */
proto.be.basalte.config.appdata.Scene.prototype.getData = function() {
  return /** @type{?proto.be.basalte.config.appdata.SceneConfig} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.SceneConfig, 8));
};


/**
 * @param {?proto.be.basalte.config.appdata.SceneConfig|undefined} value
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
*/
proto.be.basalte.config.appdata.Scene.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.hasData = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool shared = 9;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.getShared = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.setShared = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.Scene} returns this
 */
proto.be.basalte.config.appdata.Scene.prototype.clearShared = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.Scene.prototype.hasShared = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.be.basalte.config.appdata.SceneJob.oneofGroups_ = [[5,6,7]];

/**
 * @enum {number}
 */
proto.be.basalte.config.appdata.SceneJob.TimeTypeCase = {
  TIME_TYPE_NOT_SET: 0,
  REPEAT: 5,
  DATETIME: 6,
  SUN_TIME: 7
};

/**
 * @return {proto.be.basalte.config.appdata.SceneJob.TimeTypeCase}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.getTimeTypeCase = function() {
  return /** @type {proto.be.basalte.config.appdata.SceneJob.TimeTypeCase} */(jspb.Message.computeOneofCase(this, proto.be.basalte.config.appdata.SceneJob.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneJob.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneJob} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneJob.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    name: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    enabled: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f,
    sceneUuid: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    repeat: (f = msg.getRepeat()) && CoreCommon_pb.RepeatedTime.toObject(includeInstance, f),
    datetime: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    sunTime: (f = msg.getSunTime()) && CoreCommon_pb.SunriseSunsetTime.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneJob}
 */
proto.be.basalte.config.appdata.SceneJob.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneJob;
  return proto.be.basalte.config.appdata.SceneJob.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneJob} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneJob}
 */
proto.be.basalte.config.appdata.SceneJob.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSceneUuid(value);
      break;
    case 5:
      var value = new CoreCommon_pb.RepeatedTime;
      reader.readMessage(value,CoreCommon_pb.RepeatedTime.deserializeBinaryFromReader);
      msg.setRepeat(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDatetime(value);
      break;
    case 7:
      var value = new CoreCommon_pb.SunriseSunsetTime;
      reader.readMessage(value,CoreCommon_pb.SunriseSunsetTime.deserializeBinaryFromReader);
      msg.setSunTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneJob.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneJob} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneJob.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRepeat();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      CoreCommon_pb.RepeatedTime.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSunTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      CoreCommon_pb.SunriseSunsetTime.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.setUuid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.clearUuid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.setName = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.clearName = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool enabled = 3;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.setEnabled = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.clearEnabled = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.hasEnabled = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string scene_uuid = 4;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.getSceneUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.setSceneUuid = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.clearSceneUuid = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.hasSceneUuid = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional be.basalte.config.common.RepeatedTime repeat = 5;
 * @return {?proto.be.basalte.config.common.RepeatedTime}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.getRepeat = function() {
  return /** @type{?proto.be.basalte.config.common.RepeatedTime} */ (
    jspb.Message.getWrapperField(this, CoreCommon_pb.RepeatedTime, 5));
};


/**
 * @param {?proto.be.basalte.config.common.RepeatedTime|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
*/
proto.be.basalte.config.appdata.SceneJob.prototype.setRepeat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.be.basalte.config.appdata.SceneJob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.clearRepeat = function() {
  return this.setRepeat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.hasRepeat = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional int64 datetime = 6;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.getDatetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.setDatetime = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.be.basalte.config.appdata.SceneJob.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.clearDatetime = function() {
  return jspb.Message.setOneofField(this, 6, proto.be.basalte.config.appdata.SceneJob.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.hasDatetime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional be.basalte.config.common.SunriseSunsetTime sun_time = 7;
 * @return {?proto.be.basalte.config.common.SunriseSunsetTime}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.getSunTime = function() {
  return /** @type{?proto.be.basalte.config.common.SunriseSunsetTime} */ (
    jspb.Message.getWrapperField(this, CoreCommon_pb.SunriseSunsetTime, 7));
};


/**
 * @param {?proto.be.basalte.config.common.SunriseSunsetTime|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
*/
proto.be.basalte.config.appdata.SceneJob.prototype.setSunTime = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.be.basalte.config.appdata.SceneJob.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneJob} returns this
 */
proto.be.basalte.config.appdata.SceneJob.prototype.clearSunTime = function() {
  return this.setSunTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneJob.prototype.hasSunTime = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.be.basalte.config.appdata.SceneConfig.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.be.basalte.config.appdata.SceneConfig.SceneItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig}
 */
proto.be.basalte.config.appdata.SceneConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig;
  return proto.be.basalte.config.appdata.SceneConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig}
 */
proto.be.basalte.config.appdata.SceneConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.be.basalte.config.appdata.SceneConfig.SceneItem;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.SceneItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.be.basalte.config.appdata.SceneConfig.SceneItem.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.Light.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Light} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.Light.toObject = function(includeInstance, msg) {
  var f, obj = {
    onOff: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    brightness: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    colorTemperature: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    hue: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    saturation: (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null ? undefined : f,
    white: (f = jspb.Message.getOptionalFloatingPointField(msg, 6)) == null ? undefined : f,
    height: (f = jspb.Message.getOptionalFloatingPointField(msg, 8)) == null ? undefined : f,
    balance: (f = jspb.Message.getOptionalFloatingPointField(msg, 9)) == null ? undefined : f,
    mode: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.Light;
  return proto.be.basalte.config.appdata.SceneConfig.Light.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Light} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOnOff(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBrightness(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setColorTemperature(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHue(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSaturation(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWhite(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHeight(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBalance(value);
      break;
    case 7:
      var value = /** @type {!proto.be.basalte.config.appdata.SceneConfig.Light.LightMode} */ (reader.readEnum());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.Light.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Light} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.Light.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = /** @type {!proto.be.basalte.config.appdata.SceneConfig.Light.LightMode} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeEnum(
      7,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.LightMode = {
  UNKNOWN: 0,
  COLOR: 1,
  COLOR_TEMPERATURE: 2,
  WHITE: 3
};

/**
 * optional bool on_off = 1;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.getOnOff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.setOnOff = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.clearOnOff = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.hasOnOff = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double brightness = 2;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.getBrightness = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.setBrightness = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.clearBrightness = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.hasBrightness = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double color_temperature = 3;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.getColorTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.setColorTemperature = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.clearColorTemperature = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.hasColorTemperature = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double hue = 4;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.getHue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.setHue = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.clearHue = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.hasHue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double saturation = 5;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.getSaturation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.setSaturation = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.clearSaturation = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.hasSaturation = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional double white = 6;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.getWhite = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.setWhite = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.clearWhite = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.hasWhite = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double height = 8;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.getHeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.setHeight = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.clearHeight = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.hasHeight = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional double balance = 9;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.getBalance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.setBalance = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.clearBalance = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional LightMode mode = 7;
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light.LightMode}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.getMode = function() {
  return /** @type {!proto.be.basalte.config.appdata.SceneConfig.Light.LightMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Light.LightMode} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.setMode = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Light} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.clearMode = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Light.prototype.hasMode = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.LightGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.LightGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.LightGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.LightGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupMap: (f = msg.getGroupMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.SceneConfig.Light.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.LightGroup}
 */
proto.be.basalte.config.appdata.SceneConfig.LightGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.LightGroup;
  return proto.be.basalte.config.appdata.SceneConfig.LightGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.LightGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.LightGroup}
 */
proto.be.basalte.config.appdata.SceneConfig.LightGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getGroupMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.SceneConfig.Light.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.SceneConfig.Light());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.LightGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.LightGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.LightGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.LightGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.SceneConfig.Light.serializeBinaryToWriter);
  }
};


/**
 * map<string, Light> group = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneConfig.Light>}
 */
proto.be.basalte.config.appdata.SceneConfig.LightGroup.prototype.getGroupMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneConfig.Light>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.be.basalte.config.appdata.SceneConfig.Light));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.LightGroup} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.LightGroup.prototype.clearGroupMap = function() {
  this.getGroupMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.toObject = function(includeInstance, msg) {
  var f, obj = {
    openClose: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    autoMode: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    position: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    rotation: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.WindowTreatment;
  return proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOpenClose(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutoMode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPosition(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setRotation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional bool open_close = 1;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.getOpenClose = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.setOpenClose = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.clearOpenClose = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.hasOpenClose = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool auto_mode = 2;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.getAutoMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.setAutoMode = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.clearAutoMode = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.hasAutoMode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double position = 3;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.getPosition = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.setPosition = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.clearPosition = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double rotation = 4;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.getRotation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.setRotation = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.clearRotation = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.prototype.hasRotation = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupMap: (f = msg.getGroupMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup;
  return proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getGroupMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.SceneConfig.WindowTreatment());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.SceneConfig.WindowTreatment.serializeBinaryToWriter);
  }
};


/**
 * map<string, WindowTreatment> group = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment>}
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.prototype.getGroupMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneConfig.WindowTreatment>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.be.basalte.config.appdata.SceneConfig.WindowTreatment));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.prototype.clearGroupMap = function() {
  this.getGroupMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.Thermostat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    thermostatMode: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    fanMode: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    setpointK: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    controlsMap: (f = msg.getControlsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.Thermostat;
  return proto.be.basalte.config.appdata.SceneConfig.Thermostat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setThermostatMode(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFanMode(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSetpointK(value);
      break;
    case 5:
      var value = msg.getControlsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.Thermostat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getControlsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.setUuid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.clearUuid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 thermostat_mode = 2;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.getThermostatMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.setThermostatMode = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.clearThermostatMode = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.hasThermostatMode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 fan_mode = 3;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.getFanMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.setFanMode = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.clearFanMode = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.hasFanMode = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double setpoint_k = 4;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.getSetpointK = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.setSetpointK = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.clearSetpointK = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.hasSetpointK = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * map<string, bool> controls = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.getControlsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Thermostat} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Thermostat.prototype.clearControlsMap = function() {
  this.getControlsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.Subscene.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Subscene} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.toObject = function(includeInstance, msg) {
  var f, obj = {
    uuid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    scene: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Subscene}
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.Subscene;
  return proto.be.basalte.config.appdata.SceneConfig.Subscene.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Subscene} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Subscene}
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUuid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setScene(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.Subscene.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.Subscene} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string uuid = 1;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.getUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Subscene} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.setUuid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Subscene} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.clearUuid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string scene = 2;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.getScene = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Subscene} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.setScene = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.Subscene} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.clearScene = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.Subscene.prototype.hasScene = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.AV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.AV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.AV.toObject = function(includeInstance, msg) {
  var f, obj = {
    volume: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    mute: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    sourceUuid: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    content: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.AV;
  return proto.be.basalte.config.appdata.SceneConfig.AV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.AV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setVolume(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMute(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSourceUuid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.AV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.AV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.AV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional double volume = 1;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.getVolume = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.setVolume = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.clearVolume = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.hasVolume = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool mute = 2;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.getMute = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.setMute = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.clearMute = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.hasMute = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string source_uuid = 3;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.getSourceUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.setSourceUuid = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.clearSourceUuid = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.hasSourceUuid = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.setContent = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.AV} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.clearContent = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.AV.prototype.hasContent = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.ValueCase = {
  VALUE_NOT_SET: 0,
  BOOL_VALUE: 1,
  NUMBER_VALUE: 2,
  STRING_VALUE: 3
};

/**
 * @return {proto.be.basalte.config.appdata.SceneConfig.GenericControl.ValueCase}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.getValueCase = function() {
  return /** @type {proto.be.basalte.config.appdata.SceneConfig.GenericControl.ValueCase} */(jspb.Message.computeOneofCase(this, proto.be.basalte.config.appdata.SceneConfig.GenericControl.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.GenericControl.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.GenericControl} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.toObject = function(includeInstance, msg) {
  var f, obj = {
    boolValue: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    numberValue: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    stringValue: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControl}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.GenericControl;
  return proto.be.basalte.config.appdata.SceneConfig.GenericControl.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.GenericControl} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControl}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNumberValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.GenericControl.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.GenericControl} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional bool bool_value = 1;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControl} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.be.basalte.config.appdata.SceneConfig.GenericControl.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControl} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.be.basalte.config.appdata.SceneConfig.GenericControl.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double number_value = 2;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.getNumberValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControl} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.setNumberValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.appdata.SceneConfig.GenericControl.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControl} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.clearNumberValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.appdata.SceneConfig.GenericControl.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.hasNumberValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string string_value = 3;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControl} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.be.basalte.config.appdata.SceneConfig.GenericControl.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControl} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 3, proto.be.basalte.config.appdata.SceneConfig.GenericControl.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControl.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    groupMap: (f = msg.getGroupMap()) ? f.toObject(includeInstance, proto.be.basalte.config.appdata.SceneConfig.GenericControl.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup;
  return proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getGroupMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.be.basalte.config.appdata.SceneConfig.GenericControl.deserializeBinaryFromReader, "", new proto.be.basalte.config.appdata.SceneConfig.GenericControl());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGroupMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.be.basalte.config.appdata.SceneConfig.GenericControl.serializeBinaryToWriter);
  }
};


/**
 * map<string, GenericControl> group = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneConfig.GenericControl>}
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.prototype.getGroupMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.be.basalte.config.appdata.SceneConfig.GenericControl>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.be.basalte.config.appdata.SceneConfig.GenericControl));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.prototype.clearGroupMap = function() {
  this.getGroupMap().clear();
  return this;};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_ = [[2,3,4,5,6,7,8]];

/**
 * @enum {number}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.TargetCase = {
  TARGET_NOT_SET: 0,
  LIGHTS: 2,
  WINDOW_TREATMENTS: 3,
  THERMOSTAT: 4,
  SUBSCENE: 5,
  AUDIO: 6,
  GENERIC_CONTROLS: 7,
  VIDEO: 8
};

/**
 * @return {proto.be.basalte.config.appdata.SceneConfig.SceneTarget.TargetCase}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.getTargetCase = function() {
  return /** @type {proto.be.basalte.config.appdata.SceneConfig.SceneTarget.TargetCase} */(jspb.Message.computeOneofCase(this, proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.SceneTarget.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.toObject = function(includeInstance, msg) {
  var f, obj = {
    areaUuid: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lights: (f = msg.getLights()) && proto.be.basalte.config.appdata.SceneConfig.LightGroup.toObject(includeInstance, f),
    windowTreatments: (f = msg.getWindowTreatments()) && proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.toObject(includeInstance, f),
    thermostat: (f = msg.getThermostat()) && proto.be.basalte.config.appdata.SceneConfig.Thermostat.toObject(includeInstance, f),
    subscene: (f = msg.getSubscene()) && proto.be.basalte.config.appdata.SceneConfig.Subscene.toObject(includeInstance, f),
    audio: (f = msg.getAudio()) && proto.be.basalte.config.appdata.SceneConfig.AV.toObject(includeInstance, f),
    genericControls: (f = msg.getGenericControls()) && proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.toObject(includeInstance, f),
    video: (f = msg.getVideo()) && proto.be.basalte.config.appdata.SceneConfig.AV.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.SceneTarget;
  return proto.be.basalte.config.appdata.SceneConfig.SceneTarget.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAreaUuid(value);
      break;
    case 2:
      var value = new proto.be.basalte.config.appdata.SceneConfig.LightGroup;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.LightGroup.deserializeBinaryFromReader);
      msg.setLights(value);
      break;
    case 3:
      var value = new proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.deserializeBinaryFromReader);
      msg.setWindowTreatments(value);
      break;
    case 4:
      var value = new proto.be.basalte.config.appdata.SceneConfig.Thermostat;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.Thermostat.deserializeBinaryFromReader);
      msg.setThermostat(value);
      break;
    case 5:
      var value = new proto.be.basalte.config.appdata.SceneConfig.Subscene;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.Subscene.deserializeBinaryFromReader);
      msg.setSubscene(value);
      break;
    case 6:
      var value = new proto.be.basalte.config.appdata.SceneConfig.AV;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.AV.deserializeBinaryFromReader);
      msg.setAudio(value);
      break;
    case 7:
      var value = new proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.deserializeBinaryFromReader);
      msg.setGenericControls(value);
      break;
    case 8:
      var value = new proto.be.basalte.config.appdata.SceneConfig.AV;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.AV.deserializeBinaryFromReader);
      msg.setVideo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.SceneTarget.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLights();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.be.basalte.config.appdata.SceneConfig.LightGroup.serializeBinaryToWriter
    );
  }
  f = message.getWindowTreatments();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup.serializeBinaryToWriter
    );
  }
  f = message.getThermostat();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.be.basalte.config.appdata.SceneConfig.Thermostat.serializeBinaryToWriter
    );
  }
  f = message.getSubscene();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.be.basalte.config.appdata.SceneConfig.Subscene.serializeBinaryToWriter
    );
  }
  f = message.getAudio();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.be.basalte.config.appdata.SceneConfig.AV.serializeBinaryToWriter
    );
  }
  f = message.getGenericControls();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup.serializeBinaryToWriter
    );
  }
  f = message.getVideo();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.be.basalte.config.appdata.SceneConfig.AV.serializeBinaryToWriter
    );
  }
};


/**
 * optional string area_uuid = 1;
 * @return {string}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.getAreaUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.setAreaUuid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.clearAreaUuid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.hasAreaUuid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional LightGroup lights = 2;
 * @return {?proto.be.basalte.config.appdata.SceneConfig.LightGroup}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.getLights = function() {
  return /** @type{?proto.be.basalte.config.appdata.SceneConfig.LightGroup} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.SceneConfig.LightGroup, 2));
};


/**
 * @param {?proto.be.basalte.config.appdata.SceneConfig.LightGroup|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
*/
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.setLights = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.clearLights = function() {
  return this.setLights(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.hasLights = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional WindowTreatmentGroup window_treatments = 3;
 * @return {?proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.getWindowTreatments = function() {
  return /** @type{?proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup, 3));
};


/**
 * @param {?proto.be.basalte.config.appdata.SceneConfig.WindowTreatmentGroup|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
*/
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.setWindowTreatments = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.clearWindowTreatments = function() {
  return this.setWindowTreatments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.hasWindowTreatments = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Thermostat thermostat = 4;
 * @return {?proto.be.basalte.config.appdata.SceneConfig.Thermostat}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.getThermostat = function() {
  return /** @type{?proto.be.basalte.config.appdata.SceneConfig.Thermostat} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.SceneConfig.Thermostat, 4));
};


/**
 * @param {?proto.be.basalte.config.appdata.SceneConfig.Thermostat|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
*/
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.setThermostat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.clearThermostat = function() {
  return this.setThermostat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.hasThermostat = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Subscene subscene = 5;
 * @return {?proto.be.basalte.config.appdata.SceneConfig.Subscene}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.getSubscene = function() {
  return /** @type{?proto.be.basalte.config.appdata.SceneConfig.Subscene} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.SceneConfig.Subscene, 5));
};


/**
 * @param {?proto.be.basalte.config.appdata.SceneConfig.Subscene|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
*/
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.setSubscene = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.clearSubscene = function() {
  return this.setSubscene(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.hasSubscene = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AV audio = 6;
 * @return {?proto.be.basalte.config.appdata.SceneConfig.AV}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.getAudio = function() {
  return /** @type{?proto.be.basalte.config.appdata.SceneConfig.AV} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.SceneConfig.AV, 6));
};


/**
 * @param {?proto.be.basalte.config.appdata.SceneConfig.AV|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
*/
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.setAudio = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.clearAudio = function() {
  return this.setAudio(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.hasAudio = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional GenericControlGroup generic_controls = 7;
 * @return {?proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.getGenericControls = function() {
  return /** @type{?proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup, 7));
};


/**
 * @param {?proto.be.basalte.config.appdata.SceneConfig.GenericControlGroup|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
*/
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.setGenericControls = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.clearGenericControls = function() {
  return this.setGenericControls(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.hasGenericControls = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional AV video = 8;
 * @return {?proto.be.basalte.config.appdata.SceneConfig.AV}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.getVideo = function() {
  return /** @type{?proto.be.basalte.config.appdata.SceneConfig.AV} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.SceneConfig.AV, 8));
};


/**
 * @param {?proto.be.basalte.config.appdata.SceneConfig.AV|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
*/
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.setVideo = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.be.basalte.config.appdata.SceneConfig.SceneTarget.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneTarget} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.clearVideo = function() {
  return this.setVideo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneTarget.prototype.hasVideo = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.ItemCase = {
  ITEM_NOT_SET: 0,
  TARGET: 1,
  DELAY_MS: 2
};

/**
 * @return {proto.be.basalte.config.appdata.SceneConfig.SceneItem.ItemCase}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.getItemCase = function() {
  return /** @type {proto.be.basalte.config.appdata.SceneConfig.SceneItem.ItemCase} */(jspb.Message.computeOneofCase(this, proto.be.basalte.config.appdata.SceneConfig.SceneItem.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.SceneConfig.SceneItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.SceneConfig.SceneItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: (f = msg.getTarget()) && proto.be.basalte.config.appdata.SceneConfig.SceneTarget.toObject(includeInstance, f),
    delayMs: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneItem}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.SceneConfig.SceneItem;
  return proto.be.basalte.config.appdata.SceneConfig.SceneItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.SceneItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneItem}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.be.basalte.config.appdata.SceneConfig.SceneTarget;
      reader.readMessage(value,proto.be.basalte.config.appdata.SceneConfig.SceneTarget.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelayMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.SceneConfig.SceneItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.SceneConfig.SceneItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.be.basalte.config.appdata.SceneConfig.SceneTarget.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional SceneTarget target = 1;
 * @return {?proto.be.basalte.config.appdata.SceneConfig.SceneTarget}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.getTarget = function() {
  return /** @type{?proto.be.basalte.config.appdata.SceneConfig.SceneTarget} */ (
    jspb.Message.getWrapperField(this, proto.be.basalte.config.appdata.SceneConfig.SceneTarget, 1));
};


/**
 * @param {?proto.be.basalte.config.appdata.SceneConfig.SceneTarget|undefined} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneItem} returns this
*/
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.setTarget = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.be.basalte.config.appdata.SceneConfig.SceneItem.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneItem} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 delay_ms = 2;
 * @return {number}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.getDelayMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneItem} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.setDelayMs = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.appdata.SceneConfig.SceneItem.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneItem} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.clearDelayMs = function() {
  return jspb.Message.setOneofField(this, 2, proto.be.basalte.config.appdata.SceneConfig.SceneItem.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.SceneConfig.SceneItem.prototype.hasDelayMs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated SceneItem items = 1;
 * @return {!Array<!proto.be.basalte.config.appdata.SceneConfig.SceneItem>}
 */
proto.be.basalte.config.appdata.SceneConfig.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.be.basalte.config.appdata.SceneConfig.SceneItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.be.basalte.config.appdata.SceneConfig.SceneItem, 1));
};


/**
 * @param {!Array<!proto.be.basalte.config.appdata.SceneConfig.SceneItem>} value
 * @return {!proto.be.basalte.config.appdata.SceneConfig} returns this
*/
proto.be.basalte.config.appdata.SceneConfig.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.be.basalte.config.appdata.SceneConfig.SceneItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.appdata.SceneConfig.SceneItem}
 */
proto.be.basalte.config.appdata.SceneConfig.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.be.basalte.config.appdata.SceneConfig.SceneItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.SceneConfig} returns this
 */
proto.be.basalte.config.appdata.SceneConfig.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.UIDeviceData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.UIDeviceData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.UIDeviceData.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    passwordSalt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    muteAreaOnCall: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.UIDeviceData}
 */
proto.be.basalte.config.appdata.UIDeviceData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.UIDeviceData;
  return proto.be.basalte.config.appdata.UIDeviceData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.UIDeviceData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.UIDeviceData}
 */
proto.be.basalte.config.appdata.UIDeviceData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPasswordSalt(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMuteAreaOnCall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.UIDeviceData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.UIDeviceData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.UIDeviceData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.UIDeviceData} returns this
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.setPassword = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.UIDeviceData} returns this
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.clearPassword = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.hasPassword = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string password_salt = 2;
 * @return {string}
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.getPasswordSalt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.UIDeviceData} returns this
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.setPasswordSalt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.UIDeviceData} returns this
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.clearPasswordSalt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.hasPasswordSalt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool mute_area_on_call = 3;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.getMuteAreaOnCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.UIDeviceData} returns this
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.setMuteAreaOnCall = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.UIDeviceData} returns this
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.clearMuteAreaOnCall = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.UIDeviceData.prototype.hasMuteAreaOnCall = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.be.basalte.config.appdata.ScheduleData.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.ScheduleData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.ScheduleData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.ScheduleData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ScheduleData.toObject = function(includeInstance, msg) {
  var f, obj = {
    enabled: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    pointsList: jspb.Message.toObjectList(msg.getPointsList(),
    proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.ScheduleData}
 */
proto.be.basalte.config.appdata.ScheduleData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.ScheduleData;
  return proto.be.basalte.config.appdata.ScheduleData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.ScheduleData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.ScheduleData}
 */
proto.be.basalte.config.appdata.ScheduleData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 2:
      var value = new proto.be.basalte.config.appdata.ScheduleData.SchedulePoint;
      reader.readMessage(value,proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.deserializeBinaryFromReader);
      msg.addPoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.ScheduleData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.ScheduleData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.ScheduleData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ScheduleData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getPointsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    dayOfWeek: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    time: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    armed: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f,
    percentage: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.ScheduleData.SchedulePoint;
  return proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDayOfWeek(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTime(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setArmed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPercentage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
};


/**
 * optional uint32 day_of_week = 1;
 * @return {number}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.getDayOfWeek = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.setDayOfWeek = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.clearDayOfWeek = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.hasDayOfWeek = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 time = 2;
 * @return {number}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.setTime = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.clearTime = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool armed = 3;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.getArmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.setArmed = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.clearArmed = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.hasArmed = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double percentage = 4;
 * @return {number}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.getPercentage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.setPercentage = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.clearPercentage = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ScheduleData.SchedulePoint.prototype.hasPercentage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool enabled = 1;
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ScheduleData.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.be.basalte.config.appdata.ScheduleData} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.prototype.setEnabled = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.ScheduleData} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.prototype.clearEnabled = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.ScheduleData.prototype.hasEnabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated SchedulePoint points = 2;
 * @return {!Array<!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint>}
 */
proto.be.basalte.config.appdata.ScheduleData.prototype.getPointsList = function() {
  return /** @type{!Array<!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.be.basalte.config.appdata.ScheduleData.SchedulePoint, 2));
};


/**
 * @param {!Array<!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint>} value
 * @return {!proto.be.basalte.config.appdata.ScheduleData} returns this
*/
proto.be.basalte.config.appdata.ScheduleData.prototype.setPointsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint=} opt_value
 * @param {number=} opt_index
 * @return {!proto.be.basalte.config.appdata.ScheduleData.SchedulePoint}
 */
proto.be.basalte.config.appdata.ScheduleData.prototype.addPoints = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.be.basalte.config.appdata.ScheduleData.SchedulePoint, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.be.basalte.config.appdata.ScheduleData} returns this
 */
proto.be.basalte.config.appdata.ScheduleData.prototype.clearPointsList = function() {
  return this.setPointsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.TVData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.TVData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.TVData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.TVData.toObject = function(includeInstance, msg) {
  var f, obj = {
    authToken: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.TVData}
 */
proto.be.basalte.config.appdata.TVData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.TVData;
  return proto.be.basalte.config.appdata.TVData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.TVData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.TVData}
 */
proto.be.basalte.config.appdata.TVData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.TVData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.TVData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.TVData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.TVData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string auth_token = 1;
 * @return {string}
 */
proto.be.basalte.config.appdata.TVData.prototype.getAuthToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.be.basalte.config.appdata.TVData} returns this
 */
proto.be.basalte.config.appdata.TVData.prototype.setAuthToken = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.be.basalte.config.appdata.TVData} returns this
 */
proto.be.basalte.config.appdata.TVData.prototype.clearAuthToken = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.be.basalte.config.appdata.TVData.prototype.hasAuthToken = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.be.basalte.config.appdata.ScriptData.prototype.toObject = function(opt_includeInstance) {
  return proto.be.basalte.config.appdata.ScriptData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.be.basalte.config.appdata.ScriptData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ScriptData.toObject = function(includeInstance, msg) {
  var f, obj = {
    memoryMap: (f = msg.getMemoryMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.be.basalte.config.appdata.ScriptData}
 */
proto.be.basalte.config.appdata.ScriptData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.be.basalte.config.appdata.ScriptData;
  return proto.be.basalte.config.appdata.ScriptData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.be.basalte.config.appdata.ScriptData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.be.basalte.config.appdata.ScriptData}
 */
proto.be.basalte.config.appdata.ScriptData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getMemoryMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBytes, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.be.basalte.config.appdata.ScriptData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.be.basalte.config.appdata.ScriptData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.be.basalte.config.appdata.ScriptData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.be.basalte.config.appdata.ScriptData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMemoryMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBytes);
  }
};


/**
 * map<string, bytes> memory = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!(string|Uint8Array)>}
 */
proto.be.basalte.config.appdata.ScriptData.prototype.getMemoryMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!(string|Uint8Array)>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.be.basalte.config.appdata.ScriptData} returns this
 */
proto.be.basalte.config.appdata.ScriptData.prototype.clearMemoryMap = function() {
  this.getMemoryMap().clear();
  return this;};


goog.object.extend(exports, proto.be.basalte.config.appdata);
