'use strict'

angular
  .module('basalteApp')
  .service('BasScenesPresets', [
    '$rootScope',
    'BAS_API',
    'BAS_SCENE_PRESETS',
    'BasScenePreset',
    BasScenesPresets
  ])

/**
 * @constructor
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_SCENE_PRESETS} BAS_SCENE_PRESETS
 * @param BasScenePreset
 */
function BasScenesPresets (
  $rootScope,
  BAS_API,
  BAS_SCENE_PRESETS,
  BasScenePreset
) {
  processPresets()

  $rootScope.$on('$translateChangeSuccess', onTranslationChange)

  function translateScenePresets () {

    var keys, i, length

    keys = Object.keys(BAS_SCENE_PRESETS.PRESETS)
    length = keys.length
    for (i = 0; i < length; i++) {

      BAS_SCENE_PRESETS.PRESETS[keys[i]].translate()
    }
  }

  /**
   * Should only be run once
   */
  function processPresets () {

    var keys, i, length, key, preset, customKey

    keys = Object.keys(BAS_API.Scene.TEMPLATES)
    length = keys.length
    for (i = 0; i < length; i++) {

      key = keys[i]
      preset = BAS_SCENE_PRESETS.PRESETS[key] = new BasScenePreset(
        key,
        BAS_API.Scene.TEMPLATES[key]
      )

      if (preset.number === BAS_API.Scene.TEMPLATES.ALL_ON ||
        preset.number === BAS_API.Scene.TEMPLATES.ALL_OFF) {

        continue
      }

      if (preset.number === BAS_API.Scene.TEMPLATES.CUSTOM) {

        customKey = preset.key

      } else {

        BAS_SCENE_PRESETS.UI_PRESETS.push(preset.key)
        BAS_SCENE_PRESETS.UI_PRESETS_WITH_CUSTOM.push(preset.key)
      }
    }

    BAS_SCENE_PRESETS.UI_PRESETS_WITH_CUSTOM.push(customKey)
    BAS_SCENE_PRESETS.K_CUSTOM = customKey

    // Create the unknown preset
    BAS_SCENE_PRESETS.PRESETS[BAS_SCENE_PRESETS.K_UNKNOWN] =
      new BasScenePreset(
        BAS_SCENE_PRESETS.K_UNKNOWN,
        BAS_SCENE_PRESETS.N_UNKNOWN
      )
  }

  function onTranslationChange () {

    translateScenePresets()
  }
}
