{
  "12_hr": "12小时",
  "24_hr": "24小时",
  "24_hr_time": "24小时制",
  "about": "关于",
  "account": "账号",
  "account_exists": "账号存在",
  "account_exists_l1": "此账号已存在。",
  "account_exists_l2": "请选择不同的电子邮箱或使用此电子邮箱登录。",
  "account_not_confirmed": "账号未确认",
  "account_not_confirmed_l1": "此帐号尚未确认。",
  "account_not_confirmed_l2": "请检查您电子邮箱中的确认邮件。",
  "account_not_confirmed_l3": "是否验证此账号？",
  "act_in": "入",
  "activate": "启用",
  "active": "生效",
  "act_out": "向外",
  "act_push": "PUSH",
  "adaptive_brightness": "自动适应亮度",
  "add": "添加",
  "add_favourite": "添加收藏夹",
  "add_group": "添加群组",
  "add_home": "添加服务器",
  "address": "地址",
  "add_to_fav": "添加至收藏夹",
  "add_to_playlist": "添加至歌单",
  "add_to_queue": "添加至播放队列",
  "air_conditioning": "空调",
  "air_pressure": "空气压力",
  "alarm_at": "闹钟",
  "alarm_missing_txt": "缺少一个或多个必填字段",
  "alarm_not_selected_l1": "未选择闹钟。",
  "alarm_not_selected_l2": "请选择闹钟。",
  "alarms": "闹钟",
  "album": "专辑",
  "albums": "专辑",
  "alert": "警告",
  "all": "全部",
  "all_off": "全关",
  "all_off_txt_l1": "您即将关闭所有房间。",
  "all_off_txt_l2": "您确定要继续吗？",
  "all_on": "全开",
  "almost_done": "即将完成。",
  "almost_done_txt_l1": "您没有已收藏的歌单或无线电台。",
  "almost_done_txt_l2": "请先添加歌曲到您的收藏夹。",
  "already_linked": "连接至Basalte帐户",
  "already_verified": "已验证",
  "alt_power": "电源",
  "alt_user": "用户",
  "application": "应用程序",
  "application_version": "应用程序版本",
  "app_update_required": "App需要更新",
  "app_update_required_android_l2": "请在安卓Play商店更新app。",
  "app_update_required_ios_l2": "请在苹果App商店更新app。",
  "app_update_required_l2": "请在软件商店中更新app。",
  "artist": "艺术家",
  "artists": "艺术家",
  "attention": "注意！",
  "audio_unavailable": "音频系统不可用",
  "audio_zones": "音频区",
  "author": "作者",
  "back": "返回",
  "basalte_account": "Basalte账号",
  "basalte_info_l1": "通过Basalte帐号，您可以在家庭网络之外连接并控制您的家。",
  "basalte_link_server_l1": "是否将您的Basalte账号和此家庭进行连接?",
  "basalte_link_server_l2": "您将能够在本地网络之外控制此家庭。",
  "basalte_unlink_server_l1": "是否将此家庭和您的Basalte账号断开连接?",
  "basalte_unlink_server_l2": "您将不再能够在本地网络之外访问此家庭。",
  "bass": "低音",
  "BE": "比利时",
  "beta": "beta",
  "bluetooth": "蓝牙",
  "bluetooth_pairing_txt": "您现在可以通过蓝牙连接您的设备。",
  "brightness": "亮度",
  "browse": "浏览",
  "browse_deezer": "Deezer收藏夹",
  "browse_library": "音乐库",
  "browser_app_update_required_l2": "请重新载入页面或更新服务器。",
  "browse_stations": "无线电台",
  "buffering": "缓冲",
  "building_building": "建筑",
  "building_garage": "车库",
  "building_main_house": "主要住宅",
  "building_pool_house": "泳池房",
  "building_stables": "马厩",
  "cameras": "摄像头",
  "cancel": "取消",
  "categories": "类别",
  "category": "种类",
  "celsius": "摄氏度",
  "change_account": "更改帐户",
  "change_home": "修改家庭",
  "change_image": "修改图像",
  "change_password": "修改密码",
  "change_password_l1": "您即将修改密码。",
  "change_user": "更改用户",
  "channel_number": "频道号",
  "charts": "图表",
  "check_for_updates": "检查更新",
  "checking": "检查中",
  "checking_for_updates": "检查更新中",
  "choose_favourite": "是否想选择您的收藏歌曲？",
  "choose_home": "选择家庭",
  "choose_music": "是否想选择您的歌曲？",
  "choose_preset": "选择预置",
  "choose_source": "是否想选择音源？",
  "clear": "清除",
  "cleared": "已清除",
  "clear_queue_txt_l1": "您即将删除播放队列。",
  "clear_queue_txt_l2": "您确定要继续吗？",
  "clear_selection": "清除选择",
  "clear_selection_txt_l1": "您即将删除所选内容。",
  "clear_selection_txt_l2": "您确定要继续吗？",
  "close": "关闭",
  "closed": "关闭",
  "code": "确认码",
  "color": "颜色",
  "color_temperature": "色温",
  "color_theme": "颜色主题",
  "color_white": "白色",
  "comfort": "舒适",
  "configure": "配置",
  "confirmation_code_sent": "确认码已发送",
  "confirmation_code_sent_l1": "确认码已发送致以下地址:",
  "confirm_continue": "是否确实要继续?",
  "connect": "链接",
  "connected": "已连接",
  "connecting": "链接中",
  "connecting_no_active_servers": "服务器未在线。",
  "connecting_no_integrator_access": "此服务器上未启用远程配置。",
  "connecting_not_authorized": "此服务器连接未授权。",
  "connecting_small": "链接中",
  "connecting_stop_and_retry_text": "无法连接服务器。10秒后重试。",
  "connecting_stop_text": "链接服务器失败。",
  "connecting_text": "链接中…",
  "connecting_text_small": "链接中…",
  "connection_lost": "无连接",
  "connection_lost_text": "重新链接服务器",
  "connection_unable": "无法连接到指定服务器。",
  "connect_offline_server_l1": "您的家已连接，但似乎离线。",
  "connect_offline_server_l2": "是否尝试链接？",
  "connect_server": "链接服务器",
  "consumption": "消耗量",
  "contact_support": "联络支持",
  "continue_anyway": "仍然继续",
  "continue_without_basalte_account": "不使用Basalte账号继续",
  "cover_art_background": "封面背景",
  "current_password": "当前密码",
  "current_temperature": "当前",
  "custom": "自定义",
  "date": "日期",
  "day": "日",
  "day_friday": "周五",
  "day_monday": "周一",
  "day_saturday": "周六",
  "day_short2_friday": "周五",
  "day_short2_monday": "周一",
  "day_short2_saturday": "周六",
  "day_short2_sunday": "周日",
  "day_short2_thursday": "周四",
  "day_short2_tuesday": "周二",
  "day_short2_wednesday": "周三",
  "day_sunday": "周日",
  "day_thursday": "周四",
  "day_tuesday": "周二",
  "day_wednesday": "周三",
  "debug": "调试",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "您即将从播放器上断开Deezer帐号。",
  "deezer_favourite": "Deezer 收藏夹",
  "deezer_flow": "流",
  "deezer_flow_description": "您的私人配乐",
  "deezer_flow_full": "Deezer流",
  "deezer_no_link": "无Deezer账户链接",
  "deezer_oauth_error": "Deezer验证错误",
  "default_rooms": "默认房间",
  "default_rooms_hint": "为了使家庭流媒体正常工作，请确保至少选择一个房间作为默认房间。",
  "default_rooms_info_l1": "第三方音频流(如Spotify Connect)的默认房间。",
  "default_rooms_info_l2": "当无房间连接流媒体时，请激活指定房间。",
  "default_rooms_spotify_hint": "为了让Spotify Connect正常运行，请确保至少选择一个房间作为默认房间。",
  "default_rooms_spotify_warning_l1": "请为每个流媒体至少设置一个默认房间",
  "default_rooms_spotify_warning_l2": "来完成配置。",
  "default_rooms_spotify_warning_title": "现支持Spotify Connect",
  "default_view": "默认视图",
  "delay": "延时",
  "delay_seconds_b": "延时（秒）",
  "delete": "删除",
  "delete_account": "删除帐户",
  "demo_enter": "演示模式",
  "demo_functionality_warning_txt": "演示模式不支持此功能。",
  "demo_location": "Ghent",
  "demo_server_name": "Home演示版",
  "demo_title": "演示",
  "demo_warning_txt": "您即将进入演示模式。",
  "detail": "详情",
  "device": "设备",
  "devices": "设备",
  "device_settings": "设备设置",
  "device_volume": "设备音量",
  "dhcp": "DHCP",
  "direction_east": "东",
  "direction_east-northeast": "东偏东北",
  "direction_east-southeast": "东偏东南",
  "direction_north": "北",
  "direction_northeast": "东北",
  "direction_north-northeast": "北偏东北",
  "direction_north-northwest": "北偏西北",
  "direction_northwest": "西北",
  "direction_short_east": "东",
  "direction_short_east-northeast": "东偏东北",
  "direction_short_east-southeast": "东偏东南",
  "direction_short_north": "北",
  "direction_short_northeast": "东北",
  "direction_short_north-northeast": "北偏东北",
  "direction_short_north-northwest": "北偏西北",
  "direction_short_northwest": "西北",
  "direction_short_south": "南",
  "direction_short_southeast": "东南",
  "direction_short_south-southeast": "南偏东南",
  "direction_short_south-southwest": "南偏西南",
  "direction_short_southwest": "西南",
  "direction_short_west": "西",
  "direction_short_west-northwest": "西偏西北",
  "direction_short_west-southwest": "西偏西南",
  "direction_south": "南",
  "direction_southeast": "东南",
  "direction_south-southeast": "南偏东南",
  "direction_south-southwest": "南偏西南",
  "direction_southwest": "西南",
  "direction_west": "西",
  "direction_west-northwest": "西偏西北",
  "direction_west-southwest": "西偏西南",
  "disband_group": "解散组",
  "dns": "DNS",
  "domain": "领域",
  "done": "完成",
  "do_not_disturb": "请勿打扰",
  "do_not_disturb_off_l1": "當前已打開所有聲音通知",
  "do_not_disturb_on_l1": "當前所有聲音通知均已關閉",
  "do_not_show_again": "不再显示",
  "door_phone": "可视对讲",
  "doors": "门",
  "down": "向下",
  "downloading": "下载",
  "downloading_updates": "下载更新",
  "do_you_want_to_continue": "是否要继续?",
  "drag_and_drop_images": "选择图像或拉入此处",
  "drag_and_drop_lights": "在这里拖放灯光",
  "drag_to_reorder": "拖动并重新排序",
  "dsp": "DSP",
  "dsp_configuration": "DSP配置",
  "dsp_profile_club": "俱樂部",
  "dsp_profile_custom": "定制",
  "dsp_profile_flat": "平坦的",
  "dsp_profile_jazz": "爵士乐",
  "dsp_profile_live": "现场",
  "dsp_profile_pop": "流行",
  "dsp_profile_rock": "摇滚",
  "dsp_shortcut_l1": "您即将打开DSP设置。",
  "echo_cancellation": "回声消除",
  "echo_limit": "回声限制",
  "edit": "编辑",
  "edit_dns": "变更DNS",
  "edit_gateway": "调整网关",
  "edit_ip_address": "更改IP地址",
  "edit_subnet_mask": "调整子网掩码",
  "ellie_room": "Ellie室",
  "ellie_system_update": "Ellie系统更新",
  "ellie_system_update_l1": "Ellie需要重新启动才能安装系统更新。",
  "ellie_update": "Ellie更新",
  "email": "电子邮箱",
  "empty": "空",
  "empty_queue": "播放队列为空",
  "empty_queue_txt_l1": "似乎您的播放队列里没有歌曲。",
  "empty_queue_txt_l2": "请添加一些。",
  "empty_song": "播放停止",
  "empty_song_txt_l1": "播放队列已触底",
  "empty_song_txt_l2": "点击播放重新启动。",
  "empty_src": "未选择音源",
  "empty_src_txt_l1": "似乎您没有选择音源。",
  "empty_src_txt_l2": "请先选择一个音源。",
  "empty_zone": "未选择房间。",
  "empty_zone_txt_l1": "似乎您没有选择房间。",
  "empty_zone_txt_l2": "请先选择一个房间。",
  "energy": "能源",
  "energy_meter": "电表",
  "eq": "EQ",
  "error": "错误",
  "external": "外部",
  "extra_permissions_required": "有些功能可能需要额外的设备许可。",
  "ext_src": "外部音源",
  "fahrenheit": "华氏",
  "failed": "失败",
  "fan": "风扇",
  "fan_auto": "自动",
  "fan_high": "高",
  "fan_low": "低",
  "fan_medium": "中",
  "fan_off": "关闭",
  "favourite": "收藏",
  "favourites": "收藏",
  "favourite_scenes_full": "收藏场景已满",
  "favourite_scenes_full_sub": "您可以在一个房间内最多设置4个收藏场景。",
  "favourite_songs": "收藏歌曲",
  "featured_playlists": "特色播放列表",
  "floor_attic": "阁楼",
  "floor_basement": "地下室",
  "floor_floor": "楼层",
  "floor_garden": "花园",
  "floor_heating": "地暖",
  "floor_loft": "阁楼",
  "floor_lower": "下层",
  "floor_lvl_0": "一层",
  "floor_lvl_1": "二层",
  "floor_lvl_2": "三层",
  "floor_lvl_3": "四层",
  "floor_lvl_4": "五层",
  "floor_lvl_5": "六层",
  "floor_lvl_6": "七层",
  "floor_lvl_7": "八层",
  "floor_lvl_8": "九层",
  "floor_lvl_9": "十层",
  "floor_lvl_10": "十一层",
  "floor_lvl_11": "十二层",
  "floor_lvl_12": "十三层",
  "floor_lvl_13": "十四层",
  "floor_lvl_14": "十五层",
  "floor_lvl_15": "十六层",
  "floor_lvl_16": "十七层",
  "floor_lvl_17": "十八层",
  "floor_lvl_18": "十九层",
  "floor_lvl_19": "二十层",
  "floor_lvl_20": "二十一层",
  "floor_upper": "上层",
  "forgot_password": "忘记密码",
  "frequency": "频率",
  "full_screen": "全屏",
  "functions": "功能",
  "gas": "燃气",
  "gateway": "网关",
  "general": "通用",
  "generic_devices": "设备",
  "generic_devices_appliances": "电器",
  "generic_devices_artwork": "艺术品",
  "generic_devices_energy": "能源",
  "generic_devices_fire_place": "壁炉",
  "generic_devices_fountain": "喷泉",
  "generic_devices_garage": "车库",
  "generic_devices_garden": "花园",
  "generic_devices_irrigation": "灌溉",
  "generic_devices_pool": "泳池",
  "generic_devices_security": "安全",
  "generic_devices_socket": "插座",
  "generic_devices_ventilation": "通风",
  "generic_devices_wellness_spa": "健康水疗",
  "genre": "流派",
  "genre_moods": "流派和风格",
  "genres": "流派",
  "go_to_login_l1": "登录或注册使用Basalte账号。",
  "go_to_manage_l1": "管理您的Basalte账号。",
  "group": "集团",
  "groups": "组",
  "home": "家",
  "humidity": "湿度",
  "hz": "Hz",
  "images": "图片",
  "image_smaller_than_5mb": "图像需小于5MB",
  "in": "入",
  "incorrect_album": "未找到专辑",
  "incorrect_artist": "未找到艺术家",
  "install": "安装",
  "installing": "安装中",
  "install_update": "安装升级",
  "install_updates": "安装升级",
  "integrator": "集成商",
  "integrator_access_allow": "允许远程配置",
  "integrator_access_ask": "请询问业主启动远程配置。",
  "integrator_access_disabled": "远程配置已被禁用",
  "intercom": "内部通信",
  "internal": "内部",
  "invalid_address": "无效地址",
  "invalid_code": "无效码",
  "invalid_code_l1": "此码不正确。",
  "invalid_code_l2": "请确保密码正确或请求一个新码。",
  "invalid_input": "无效输入",
  "invalid_user": "无效用户",
  "invalid_user_l1": "请确保电子邮箱有效。",
  "ios_plist_string_NSCameraUsageDescription": "相机可用于为房间拍照",
  "ios_plist_string_NSLocalNetworkUsageDescription": "本地网络访问可用于查找服务器，IP摄像机，...",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "照片库可用于保存房间照片",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "照片库可用于选择房间照片",
  "ip": "IP",
  "ip_address": "IP地址",
  "join": "+ 加入",
  "join_alt": "+ 加入",
  "keep_awake": "保持iPad唤醒",
  "keep_awake_device": "保持设备唤醒",
  "kelvin": "Kelvin",
  "Key": "en_GB",
  "knx_presets": "KNX预置",
  "knx_presets_info": "修改现有KNX预置以播放其他内容。",
  "language": "语言",
  "language_system": "系统语言",
  "last-7-days": "最近7日",
  "last_hour": "前一小时",
  "last_room": "上一房间",
  "last_view": "最后视图",
  "learn_more": "進一步了解",
  "leave_group": "离开组",
  "left": "剩下",
  "lena_system_update": "Lena系统更新",
  "lena_system_update_l1": "Lena需要重新启动才能安装系统更新。",
  "library": "库",
  "library_empty": "库为空",
  "library_scanning": "扫描库",
  "light_accent_light": "口音灯",
  "light_art_light": "艺术灯",
  "light_cabinet": "陈列室",
  "light_ceiling_mounted_fixture": "吸顶灯",
  "light_chandelier": "吊灯",
  "light_desk": "桌灯",
  "light_down": "筒灯",
  "light_floor_lamp": "落地灯",
  "light_lampshade": "灯罩",
  "light_led": "LED灯",
  "light_led_strip": "LED灯带",
  "light_mirror": "镜子",
  "light_mood_light": "情景灯光",
  "light_pendant": "吊灯",
  "light_reading_light": "阅读灯",
  "lights": "灯光",
  "lights_off_all": "关闭所有灯光",
  "light_spot": "射灯",
  "light_table_lamp": "台灯",
  "light_track": "轨道灯",
  "light_via": "Via",
  "light_wall_mounted_fixture": "壁灯",
  "link": "连接",
  "link_deezer_info_l1": "请链接Deezer帐户到流媒体。",
  "link_deezer_info_l2": "能够在流上浏览和播放Deezer媒体。",
  "linked_homes": "已连接家庭",
  "linked_projects": "已连接项目",
  "linked_with": "与连接",
  "link_home": "在本地设备上打开Basalte app，并在设置中链接您的家。",
  "link_server": "连接至Basalte账号。",
  "link_server_full_sub": "是否将此项目连接至以下Basalte账号？",
  "link_spotify_info_l1": "请链接Spotify帐户到流媒体。",
  "link_spotify_info_l2": "能够在流上浏览和播放Spotify媒体。",
  "link_tidal_info_l1": "请链接TIDAL帐户到流媒体。",
  "link_tidal_info_l2": "能够在流上浏览和播放TIDAL媒体。",
  "lisa_order_hint": "改变页面和窗口的顺序及可见性。",
  "lisa_system_update": "Lisa系统更新",
  "lisa_system_update_l1": "Lisa需要重新启动才能安装系统更新。",
  "listen_in": "默认收听",
  "liters": "公升",
  "live_adjustments": "现场调整",
  "loading": "加载…",
  "local": "本地",
  "location": "地点",
  "location_back": "背面",
  "location_bottom": "底部",
  "location_center": "中心",
  "location_chair": "座椅",
  "location_corner": "角落",
  "location_desk": "书桌",
  "location_east": "东",
  "location_front": "正面",
  "location_high": "高",
  "location_island": "岛",
  "location_left": "左",
  "location_low": "低",
  "location_mid": "适中",
  "location_niche": "壁龛",
  "location_north": "北",
  "location_right": "右",
  "location_shelves": "架子",
  "location_south": "南",
  "location_stove": "火炉",
  "location_table": "桌子",
  "location_top": "顶部",
  "location_west": "西",
  "logged_in_as": "登录",
  "log_out": "注销",
  "log_out_everywhere": "在任何地方注销",
  "long-press-save-scene": "长按更新场景",
  "louver_30": "30度",
  "louver_45": "45度",
  "louver_60": "60度",
  "louver_horizontal": "水平",
  "louver_mode": "百叶窗模式",
  "louver_off": "关闭",
  "louver_swing": "摆动",
  "louver_vertical": "垂直",
  "mac_address": "MAC地址",
  "manage": "管理",
  "manage_account": "管理帐户",
  "manual_conn": "手动连接",
  "manual_text": "请输入所需服务器的地址。",
  "max": "最大限度",
  "menu": "菜单",
  "mf_device": "{count, plural, other {设备}}",
  "mf_feels_like": "感觉像{value}",
  "mf_minute": "{count} {count, plural, other {分钟}}",
  "mf_powered_by": "由{value}提供支持",
  "mf_second": "{count} {count, plural, other {秒}}",
  "mf_server": "{count, plural, other {服务器}}",
  "mf_song": "{count} {count, plural, other {歌曲}}",
  "mf_source": "資料來源 {value}",
  "microphone_gain": "麦克风增益",
  "min": "最小",
  "mix": "混音",
  "mixed_sources": "多重音源",
  "mixed_sources_txt_l1": "这个组的房间包含多个音源。",
  "mixed_sources_txt_l2": "请选择一个音源。",
  "mixes": "混合",
  "mixes_small": "混合",
  "mode": "模式",
  "mode_auto": "自动",
  "mode_cooling": "制冷",
  "mode_drying": "除湿",
  "mode_fan_only": "仅使用风扇",
  "mode_heating": "制热",
  "mode_off": "关闭",
  "month": "月",
  "month_april": "4 月",
  "month_august": "8 月",
  "month_december": "12 月",
  "month_february": "2 月",
  "month_january": "1 月",
  "month_july": "7 月",
  "month_june": "6 月",
  "month_march": "3 月",
  "month_may": "5 月",
  "month_november": "11 月",
  "month_october": "10 月",
  "month_september": "9 月",
  "month_short_april": "4 月",
  "month_short_august": "8 月",
  "month_short_december": "12 月",
  "month_short_february": "2 月",
  "month_short_january": "1 月",
  "month_short_july": "7 月",
  "month_short_june": "6 月",
  "month_short_march": "3 月",
  "month_short_may": "5 月",
  "month_short_november": "11 月",
  "month_short_october": "10 月",
  "month_short_september": "9 月",
  "mood": "情绪",
  "moods": "情绪",
  "music": "音乐",
  "music_choice": "音乐选择",
  "music_go_to": "到音乐界面",
  "music_is_active": "音乐已激活",
  "music_is_playing": "正在播放音乐",
  "music_off": "音乐关闭",
  "music_off_all": "关闭所有房间",
  "music_off_all_rooms": "关闭所有音乐",
  "music_off_current": "关闭当前房间",
  "music_off_current_room": "在这个房间里放音乐",
  "music_off_txt_l1": "您即将关掉一个或所有的房间。",
  "music_off_txt_l2": "您确定要继续吗？",
  "music_on_current": "打开当前房间",
  "music_server": "音乐服务器",
  "mute_area_on_call": "通话时将音乐静音",
  "my_favorites": "我的收藏夹",
  "my_music": "我的音乐",
  "name": "名称",
  "nds": "搜索服务器",
  "nds_music": "搜索音乐服务器",
  "nds_none": "未搜索到服务器！",
  "nds_none_music": "未搜索到音乐服务器！",
  "nds_text_l1": "可能需要几秒钟时间…",
  "nds_text_l2": "请确保您的设备已连接到您的家庭Wi-Fi网络。",
  "network": "网络",
  "network_input_invalid": "所有输入字段必须正确输入",
  "never": "从不",
  "new": "新建",
  "new_alarm": "新建闹钟",
  "new_image": "新图片",
  "new_password": "新密码",
  "new_password_confirmation": "新密码确认",
  "new_playlist": "新建播放列表…",
  "new_releases": "最新发布",
  "new_releases_small": "最新发布",
  "new_scene": "新建场景",
  "new_schedule": "新建日程",
  "new_step": "添加功能",
  "next": "下一步",
  "next_song": "下一首",
  "no": "不",
  "no_active_rooms": "未有激活的房间",
  "no_all_off": "不，请不要关闭",
  "no_av": "无AV输入",
  "no_configuration": "无可用配置",
  "no_content_available": "无可用内容",
  "no_data": "データなし",
  "no_deezer_dis": "不，请不要断开Deezer",
  "no_devices_capabilities": "无可调设备",
  "no_doors_available": "无可使用的门",
  "no_favourites": "无收藏夹",
  "no_keep_it": "不，保持",
  "no_lights_capabilities": "无可调灯光",
  "no_linked_homes": "没有找到已连接的家。",
  "none": "没有",
  "no_network": "无网络链接",
  "no_preview_available": "预览不可用",
  "no_projects": "未发现项目。",
  "no_rooms": "无房间",
  "no_rooms_available": "无可用房间",
  "no_room_selected": "未选择房间",
  "no_rooms_listening": "无房间在听此音源。",
  "no_scene": "无场景",
  "no_scenes_favourites": "没有场景收藏",
  "no_scenes_txt": "选择场景在此处显示。",
  "no_search_results": "无搜索结果",
  "no_servers": "没有找到服务器",
  "no_shades_capabilities": "无可调百叶窗",
  "no_source": "—— 关 ——",
  "no_sources_available": "没有音源可用",
  "no_steps": "此场景不包含任何功能",
  "not_allowed": "不允许",
  "not_allowed_l1": "您没有权限操作",
  "notification": "通知",
  "notification_permission_missing": "巴萨智家无权限发送通知。您可以在设备的系统设置中启用通知功能。",
  "notifications": "通知",
  "not_linked": "未链接",
  "not_now": "不是现在",
  "not_set": "没有设置",
  "no_users_l1": "没有发现用户。",
  "no_users_l2": "请选择拥有用户的服务器。",
  "now_playing": "正在播放",
  "number_of_songs": "歌曲",
  "off": "关",
  "offline_server": "服务器脱机",
  "offset_minutes": "偏移（分钟）",
  "ok": "OK",
  "on": "开",
  "onix_black": "黑色",
  "online": "線上",
  "only_png_jpeg_supported": "仅支持PNG和JPEG图像",
  "open": "开",
  "open_close_door": "门",
  "open_close_gate": "大门",
  "open_close_window": "窗户",
  "open_settings": "打开设置",
  "open_spotify": "打开Spotify",
  "open_spotify_l1": "您即将打开Spotify应用程序。",
  "open_state": "开",
  "or": "或者",
  "order_cameras_by_holding_and_draging": "按需要的顺序拖动摄像机。",
  "other": "其他",
  "out": "向外",
  "page_order": "页面/窗口顺序",
  "pair": "配对",
  "password": "密码",
  "password_changed": "密码已修改",
  "password_confirmation": "密码确认",
  "password_requirements": "您的密码必须包含至少8个字符，包括至少1个大写字母、小写字母和1个数字。",
  "permission_denied": "拒绝访问",
  "permission_denied_go_to_settings_l1": "此功能需要权限才能正常使用。",
  "permission_denied_go_to_settings_l2": "在您的设备设置中允许此应用程序启用所有功能。",
  "photo_from_camera": "拍张照片",
  "photo_from_library": "从库中选择图像",
  "pick_room_function": "选择房间和功能",
  "play": "播放",
  "playback_gain": "循环播放",
  "playing_in": "正在播放",
  "playlist": "播放列表",
  "playlists": "播放列表",
  "playlists_itunes": "iTunes播放列表",
  "playlists_l": "播放列表",
  "playlists_local": "本地播放列表",
  "playlists_shared": "分享播放列表",
  "play_next": "播放下一首",
  "play_now": "当前播放",
  "please_try_a_different_image": "请尝试不同的图像",
  "please_try_again": "请再试一次",
  "popular": "流行",
  "position": "位置",
  "precipitation": "沉淀",
  "previous": "上一步",
  "problems_continue_integrator": "如果您持续遇到问题，请联系您的集成商。",
  "project": "项目",
  "project_image": "项目映像",
  "projects_network_error": "由于网络错误无法加载项目。",
  "proximity_activation": "接近激活",
  "proximity_far": "远",
  "proximity_medium": "中等",
  "proximity_near": "近",
  "queue": "播放队列",
  "radio": "收音机",
  "radio_error": "检索无线电台出错",
  "radio_stations": "无线电台",
  "radio_stations_other": "其他电台",
  "recently_played": "最近播放",
  "related_artists": "相关艺术家",
  "reload": "重载",
  "remember_me": "记住我",
  "remote": "远程",
  "remote_ellie_intercom_outdated": "远程控制Ellie已过时",
  "remote_ellie_intercom_outdated_l1": "您想连接的Ellie需要更新软件。",
  "remote_ellie_intercom_outdated_l2": "更新后请重试。",
  "remove": "移除",
  "remove_alarm": "移除闹钟",
  "remove_basalte_favourite": "移除Basalte收藏夹",
  "remove_deezer_favourite": "移除Deezer收藏夹",
  "remove_from_fav": "从收藏夹中移除",
  "remove_schedule": "移除日程",
  "remove_spotify_favourite": "移除Spotify收藏夹",
  "remove_spotify_preset": "移除Spotify预置",
  "remove_tidal_favourite": "移除TIDAL收藏夹",
  "remove_txt_l1": "您即将移除这个项目。",
  "remove_txt_l2": "您确定要继续吗?",
  "rename": "重命名",
  "repeat": "重复",
  "replace_queue": "替换队列",
  "resend_code": "重新发送密码",
  "resend_confirmation_code": "重新发送确认码",
  "reset_image": "重置图像",
  "reset_password": "重置密码",
  "reset_password_l1": "您确定要重置密码吗？",
  "restart": "重启",
  "restart_app": "重启应用",
  "restart_app_q": "重启应用",
  "restarting_server": "服务器重启中",
  "restart_music_server": "重启音乐服务器",
  "restart_music_server_txt_l1": "您即将重启音乐服务器。",
  "restart_server": "重启服务器",
  "restart_server_txt_l1": "您即将重启服务器。",
  "restart_server_txt_l2": "应用程序将自动重新连接。",
  "retrieving": "检索中…",
  "retrieving_users_stop_text": "检索用户失败，服务器是否正确?",
  "retrieving_users_text": "检索用户…",
  "right": "对",
  "room": "房间",
  "room_attic": "阁楼",
  "room_ball_room": "宴会厅",
  "room_bar": "酒吧",
  "room_basement": "地下室",
  "room_bathroom": "浴室",
  "room_bedroom": "卧室",
  "room_corridor": "走廊",
  "room_dining_room": "餐厅",
  "room_dressing_room": "衣帽间",
  "room_driveway": "私人车道",
  "room_entry": "入口",
  "room_family_room": "家庭室",
  "room_foyer": "门厅",
  "room_garage": "车库",
  "room_garden": "花园",
  "room_guest_room": "客房",
  "room_gym": "健身房",
  "room_hallway": "走廊",
  "room_hobby_room": "娱乐室",
  "room_home_cinema": "影音室",
  "room_home_office": "家庭办公室",
  "room_images": "房间图像",
  "room_intercom_lost": "连接中断",
  "room_intercom_lost_sub": "通话连接中断，请再试一次",
  "room_intercom_unavailable": "无可连接的房间",
  "room_intercom_unavailable_sub": "此房间目前无法连接",
  "room_intercom_unreachable": "房间无法接通",
  "room_intercom_unreachable_sub": "此房间目前无法接通",
  "room_kitchen": "厨房",
  "room_laundry_room": "洗衣房",
  "room_library": "书房",
  "room_living_room": "客厅",
  "room_master_bedroom": "主卧",
  "room_media_room": "多媒体室",
  "room_meeting_room": "会客室",
  "room_nook": "角落",
  "room_nursery": "育儿室",
  "room_pantry": "食品储藏室",
  "room_parking_place": "停车场",
  "room_patio": "庭院",
  "room_pool": "泳池",
  "room_pool_house": "台球厅",
  "room_rack": "设备架",
  "room_room": "房间",
  "rooms": "房间",
  "room_scene_no_functions": "此房间没有功能",
  "room_settings": "房间设置",
  "room_spa": "温泉",
  "room_stable": "稳定",
  "room_staircase": "楼梯",
  "room_terrace": "阳台",
  "room_toilet": "卫生间",
  "room_utility_room": "杂物间",
  "room_wine_cellar": "酒窖",
  "room_workshop": "工作室",
  "rotation": "循环",
  "save": "保存",
  "save_as": "另存为",
  "save_basalte_favourite": "保存Basalte收藏夹",
  "save_changes": "保存更改",
  "save_changes_l1": "有些更改还未保存",
  "save_changes_l2": "您想现在保存吗?",
  "save_deezer_favourite": "保存Deezer收藏夹",
  "save_spotify_favourite": "保存Spotify收藏夹",
  "save_spotify_preset": "保存Spotify预设",
  "save_tidal_favourite": "保存TIDAL收藏夹",
  "scene": "场景",
  "scene_activated": "场景启动",
  "scene_away": "离家",
  "scene_cooking": "烹饪",
  "scene_dinner": "晚餐",
  "scene_error": "场景错误",
  "scene_evening": "傍晚",
  "scene_good_morning": "早安",
  "scene_good_night": "晚安",
  "scene_guests": "宾客",
  "scene_home": "回家",
  "scene_image_error": "场景影像错误",
  "scene_movie": "观影",
  "scene_not_available": "当前无场景。",
  "scene_not_selected_l1": "未选择场景。",
  "scene_not_selected_l2": "请选择一个场景。",
  "scene_party": "派对",
  "scene_relax": "放松",
  "scenes": "场景",
  "scene_select_or_new": "请选择或创建一个场景。",
  "scene_vacation": "度假",
  "scene_welcome": "欢迎",
  "scene_workout": "健身",
  "schedule": "定时",
  "schedule_at": "日程在",
  "schedule_not_selected_l1": "未选择日程表。",
  "schedule_not_selected_l2": "请选择一个日程。",
  "scheduler": "日程",
  "schedules": "日程",
  "schedule_select_or_new": "请选择或创建一个场景。",
  "screen_orientation": "屏幕方向",
  "search": "搜索",
  "search_again": "再次搜索",
  "search_ph": "搜索艺术家，歌曲或专辑…",
  "search_result": "搜索",
  "security": "保卫",
  "select_admin": "选择管理员配置文件以继续更新过程",
  "select_home": "选择你的家",
  "selection": "选择",
  "selection_all": "全部添加到选择对象",
  "select_photo": "选择图像",
  "select_server": "请选择服务器",
  "select_user": "请选择用户登陆",
  "serial_number": "序号",
  "server": "服务器",
  "server_address": "服务器地址",
  "server_basalte_cloud_no_connection": "服务器无法链接Basalte Cloud",
  "server_exists_already": "服务器已经存在！",
  "server_mac": "服务器MAC地址",
  "servers": "服务器",
  "server_status": "服务器状态",
  "server_update_info_l2": "请将Basalte服务器更新到最新版本。",
  "server_update_required": "服务器需要更新",
  "server_version": "服务器版本",
  "set": "设置",
  "set_new_password": "设置新密码",
  "setpoint": "设定值",
  "settings": "设置",
  "shade_blind": "百叶窗",
  "shade_center": "中心",
  "shade_curtain": "窗帘",
  "shade_left": "左",
  "shade_pool_shutter": "泳池盖",
  "shade_right": "右",
  "shade_roman_blind": "罗马帘",
  "shades": "百叶窗",
  "shade_shade": "百叶窗",
  "shade_shutter": "遮阳百叶窗 (外)",
  "shade_solar_protection": "遮阳",
  "shade_venetian_blind": "横式百叶窗",
  "shade_vertical_blind": "竖式百叶窗",
  "share": "分享",
  "shared_playlist": "分享播放列表",
  "show_album": "显示专辑",
  "show_artist": "显示艺术家",
  "show_background_art": "显示背景图",
  "show_more": "显示更多…",
  "show_status_bar": "显示iPad状态栏",
  "show_status_bar_device": "显示状态栏",
  "sign_in": "登陆",
  "sign_in_info_tidal_l1": "输入您的TIDAL登录凭证",
  "sign_up": "注册",
  "single_room": "房间",
  "sleep": "睡眠",
  "software_update": "软件更新",
  "something_went_wrong": "出现问题",
  "songs": "歌曲",
  "sonos": "Sonos",
  "sonos_unreachable": "无法找到Sonos",
  "sonos_unreachable_txt_l1": "请确认您的设备已插入并连接。",
  "sound_settings": "變更使用者",
  "source": "音源",
  "sources": "音源",
  "spotify": "Spotify",
  "spotify_beta": "Spotify（beta）",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "请确保Core服务器和使用Spotify app的设备在同一个网络上。",
  "spotify_connect_instructions_2": "从Spotify app的设备选择器中选择Core服务器流媒体。",
  "spotify_connect_instructions_body": "將Spotify App 當成遙控器，在喇叭或電視上播放。",
  "spotify_connect_instructions_title": "準備來播些音樂了嗎？",
  "spotify_disconnect_txt": "您即将从播放器上断开Spotify帐号。",
  "spotify_free_user_txt": "需要订阅Spotify Premium",
  "spotify_no_link": "未有Spotify账户链接",
  "spotify_oauth_error": "Spotify验证错误",
  "spotify_preset_error": "Spotify错误",
  "spotify_preset_error_l1": "无法加载Spotify预置。",
  "spotify_preset_error_l2": "请确保有效的Spotify帐户已连接过此流媒体。",
  "spotify_presets": "Spotify预置",
  "start": "起始",
  "start_page": "开始页",
  "start_page_customisation": "开始页自定义",
  "start_time_date": "时间和日期",
  "startup_view": "启动视图",
  "startup_volume": "初始音量",
  "start_weather": "气象信息",
  "state_active": "有效的",
  "state_armed": "已装备",
  "state_closed": "已关闭",
  "state_disabled": "已禁用",
  "state_disarmed": "已解除",
  "state_down": "下",
  "state_enabled": "启用",
  "state_false": "错误",
  "state_free": "空闲",
  "state_high": "高",
  "state_in": "入",
  "state_inactive": "无效的",
  "state_left": "左",
  "state_low": "低",
  "state_occupied": "已占用",
  "state_off": "关",
  "state_on": "开",
  "state_opened": "已打开",
  "state_out": "出",
  "state_right": "右",
  "state_started": "已开始",
  "state_stopped": "已关闭",
  "state_true": "正确",
  "state_up": "上",
  "static": "静态",
  "stations": "电台",
  "status": "状态",
  "stop": "停",
  "stream": "流媒体",
  "streaming": "流媒体",
  "streaming_services": "流媒体服务",
  "streams": "流媒体",
  "streams_beta": "流媒体（beta）",
  "subnet_mask": "子网掩码",
  "sun": "太阳",
  "sunrise": "日出",
  "sunset": "日落",
  "swipe_to_pan": "滑动点击",
  "switch_server": "更换服务器",
  "synchronise": "立即扫描",
  "system": "系统",
  "system_setting": "系统设置",
  "temperature": "温度",
  "temperature_unit": "温度单位",
  "theme": "主题",
  "thermostat": "温度",
  "this_cannot_be_undone": "此操作无法撤消。",
  "this_week": "本周",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "您即将从播放器上断开TIDAL帐号。",
  "tidal_legacy_auth": "需要 TIDAL 登录",
  "tidal_legacy_auth_l1": "要继续使用 TIDAL，请重新登录。",
  "tidal_no_link": "未有TIDAL账户链接",
  "tidal_oauth_error": "TIDAL验证错误",
  "tidal_rising": "TIDAL Rising",
  "time": "时间",
  "time_format": "时间格式",
  "timer": "定时",
  "timers": "定时",
  "title": "标题",
  "today": "今天",
  "tomorrow": "明天",
  "top_tracks": "推荐曲",
  "treble": "高音",
  "try_again": "再试一次",
  "try_again_later": "稍后再试",
  "try_again_later_or_contact_support": "稍后再试或联络支持",
  "turned_off": "已关",
  "turned_on": "已开",
  "turn_off": "关",
  "turn_on": "开",
  "turn_on_default_rooms": "是否打开此音源的默认房间？",
  "turn_on_music": "打开音乐",
  "turn_on_tv": "打开电视",
  "tv": "电视",
  "tv_off": "关掉电视",
  "tv_off_current_room": "此房间关闭电视",
  "unavailable": "不可用",
  "unavailable_src": "音源不可用",
  "unavailable_src_txt": "当前源不可用。",
  "ungroup": "取消分组",
  "unknown": "未知",
  "unknown_account": "未知账户",
  "unknown_account_l1": "此电子邮箱不是Basalte帐户。",
  "unknown_av": "未知AV输入",
  "unknown_src": "未知源",
  "unknown_src_txt": "此服务器未知当前源。",
  "unlink": "未连接",
  "unlink_server": "未连接Basalte账号。",
  "unlink_server_full_sub": "是否从Basalte账户中删除连接此项目? 它将不能从您的本地网络以外访问。",
  "unreachable": "无法访问",
  "unshare": "独享",
  "unsupported_browser": "浏览器不支持",
  "unsupported_browser_l1": "有些功能可能无法正常工作。",
  "unsupported_browser_l2": "为了更好的用户体验，请使用Chrome或Safari浏览器。",
  "unsupported_browser_live": "此浏览器不支持Basalte账号。请尝试使用最新版Chrome或Safari。",
  "up": "向上",
  "update": "更新",
  "update_available": "更新",
  "update_available_full": "可更新",
  "update_info_l1": "为了确保最好的体验，",
  "update_library": "重新扫描音乐库",
  "update_music_server": "更新音乐服务器",
  "update_scene": "更新场景",
  "update_scene_l1": "将当前状态保存到此场景。",
  "update_server": "更新服务器",
  "updating_server": "服务器更新中",
  "updating_server_info": "不要关闭服务器",
  "up_to_date": "最新",
  "username": "用户名",
  "uv": "紫外的",
  "value_invalid": "指定的值无效。",
  "verify": "验证",
  "verifying": "验证中",
  "version": "版本",
  "video_background": "视频背景",
  "video_go_to": "到电视界面",
  "video_is_active": "电视已激活",
  "video_is_playing": "视频正在播放",
  "video_unavailable": "电视或音频系统不可用",
  "volume": "音量",
  "warning": "警报",
  "water": "水",
  "weather": "天气",
  "weather_daily_data_unavailable": "当前没有每日天气信息",
  "weather_hourly_data_unavailable": "目前没有每小时的天气信息",
  "weather_information": "气象信息",
  "week": "周",
  "weekly": "每周",
  "what": "什么",
  "what_music": "哪些音乐",
  "when": "何时",
  "where": "哪里",
  "who": "谁",
  "wind": "风",
  "wrong_password": "密码错误",
  "wrong_password_txt": "您输入的密码与用户密码不匹配",
  "wrong_username_and_or_password": "错误的用户名和/或密码",
  "year": "年",
  "yes": "是",
  "yes_all_off": "是，关闭所有",
  "yes_cur_off": "是，关闭当前",
  "yes_deezer_dis": "是，断开Deezer帐号",
  "yes_delete_it": "是，删除",
  "yesterday": "昨天",
  "zone": "房间",
  "zones": "房间"
}
