'use strict'

angular
  .module('basalteApp')
  .directive('basSelectActiveHighlight', basSelectActiveHighlight)

function basSelectActiveHighlight () {

  return {
    restrict: 'A',
    link: link
  }

  function link (_scope, element) {

    var timeout = null
    var DELAY = 500

    element[0].addEventListener('click', click)

    function click () {

      setCssActive()

      clearTimeout(timeout)
      timeout = setTimeout(removeCssActive, DELAY)
    }

    function setCssActive () {

      element[0].classList.toggle('active', true)
    }

    function removeCssActive () {

      element[0].classList.toggle('active', false)
    }
  }
}
