// source: enums.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.be.basalte.enums.AVInputType', null, global);
goog.exportSymbol('proto.be.basalte.enums.AVType', null, global);
goog.exportSymbol('proto.be.basalte.enums.AreaLevel', null, global);
goog.exportSymbol('proto.be.basalte.enums.AreaType', null, global);
goog.exportSymbol('proto.be.basalte.enums.DeviceMode', null, global);
goog.exportSymbol('proto.be.basalte.enums.Language', null, global);
goog.exportSymbol('proto.be.basalte.enums.ResetCause', null, global);
goog.exportSymbol('proto.be.basalte.enums.ScenePreset', null, global);
/**
 * @enum {number}
 */
proto.be.basalte.enums.DeviceMode = {
  DM_DEFAULT: 0,
  DM_DEBUG: 1,
  DM_TEST: 2,
  DM_DEMO: 4,
  DM_CHILD_ONLY: 8,
  DM_UPDATE_FORCED: 16
};

/**
 * @enum {number}
 */
proto.be.basalte.enums.Language = {
  LANG_EN_GB: 0,
  LANG_NL_NL: 1,
  LANG_FR_FR: 2,
  LANG_DE_DE: 3,
  LANG_ES_ES: 4,
  LANG_PL_PL: 5,
  LANG_RU_RU: 6
};

/**
 * @enum {number}
 */
proto.be.basalte.enums.ScenePreset = {
  SCENE_CUSTOM: 0,
  SCENE_HOME: 1,
  SCENE_AWAY: 2,
  SCENE_RELAX: 3,
  SCENE_PARTY: 4,
  SCENE_COOKING: 5,
  SCENE_DINING: 6,
  SCENE_WELCOME: 7,
  SCENE_GUESTS: 8,
  SCENE_EVENING: 9,
  SCENE_GOOD_MORNING: 10,
  SCENE_MOVIE: 11,
  SCENE_WORKOUT: 12,
  SCENE_GOOD_NIGHT: 13,
  SCENE_ALL_ON: 255,
  SCENE_ALL_OFF: 256
};

/**
 * @enum {number}
 */
proto.be.basalte.enums.AVType = {
  AV_TYPE_NONE: 0,
  AV_TYPE_VIDEO: 1,
  AV_TYPE_AUDIO: 2
};

/**
 * @enum {number}
 */
proto.be.basalte.enums.AVInputType = {
  AV_INPUT_TYPE_NONE: 0,
  AV_INPUT_TYPE_INTERNAL: 1,
  AV_INPUT_TYPE_AUDIO_ALERT: 2,
  AV_INPUT_TYPE_GENERIC: 3,
  AV_INPUT_TYPE_SETTOPBOX: 101,
  AV_INPUT_TYPE_GAMECONSOLE: 102,
  AV_INPUT_TYPE_APPLETV: 103,
  AV_INPUT_TYPE_TV_AUDIO: 201,
  AV_INPUT_TYPE_STREAM: 202,
  AV_INPUT_TYPE_SONOS: 203,
  AV_INPUT_TYPE_CD_PLAYER: 204,
  AV_INPUT_TYPE_RECORD_PLAYER: 205
};

/**
 * @enum {number}
 */
proto.be.basalte.enums.AreaLevel = {
  AREA_LVL_ROOM: 0,
  AREA_LVL_FLOOR: 1,
  AREA_LVL_BUILDING: 2,
  AREA_LVL_HOME: 3
};

/**
 * @enum {number}
 */
proto.be.basalte.enums.AreaType = {
  AREA_TYPE_CUSTOM: 0,
  AREA_TYPE_B_MAIN_HOUSE: 1,
  AREA_TYPE_B_POOL_HOUSE: 2,
  AREA_TYPE_B_STABLES: 3,
  AREA_TYPE_B_GARAGE: 4,
  AREA_TYPE_B_BUILDING: 5,
  AREA_TYPE_F_GROUND_FLOOR: 51,
  AREA_TYPE_F_BASEMENT: 52,
  AREA_TYPE_F_FIRST_FLOOR: 53,
  AREA_TYPE_F_SECOND_FLOOR: 54,
  AREA_TYPE_F_GARDEN: 55,
  AREA_TYPE_F_THIRD_FLOOR: 56,
  AREA_TYPE_F_FOURTH_FLOOR: 57,
  AREA_TYPE_F_FIFTH_FLOOR: 58,
  AREA_TYPE_F_FLOOR: 59,
  AREA_TYPE_F_ATTIC: 60,
  AREA_TYPE_F_LOFT: 61,
  AREA_TYPE_F_UPPER_FLOOR: 62,
  AREA_TYPE_F_LOWER_FLOOR: 63,
  AREA_TYPE_R_RACK: 101,
  AREA_TYPE_R_LIVING_ROOM: 102,
  AREA_TYPE_R_KITCHEN: 103,
  AREA_TYPE_R_BEDROOM: 104,
  AREA_TYPE_R_BATHROOM: 105,
  AREA_TYPE_R_GARAGE: 106,
  AREA_TYPE_R_GARDEN: 107,
  AREA_TYPE_R_PATIO: 108,
  AREA_TYPE_R_POOL: 109,
  AREA_TYPE_R_DRESSING_ROOM: 110,
  AREA_TYPE_R_HOME_OFFICE: 111,
  AREA_TYPE_R_SPA: 112,
  AREA_TYPE_R_TERRACE: 113,
  AREA_TYPE_R_BASEMENT: 114,
  AREA_TYPE_R_GYM: 115,
  AREA_TYPE_R_HOME_CINEMA: 116,
  AREA_TYPE_R_ATTIC: 117,
  AREA_TYPE_R_HOBBY_ROOM: 118,
  AREA_TYPE_R_WINE_CELLAR: 119,
  AREA_TYPE_R_BAR: 120,
  AREA_TYPE_R_DINING_ROOM: 121,
  AREA_TYPE_R_HALLWAY: 122,
  AREA_TYPE_R_TOILET: 123,
  AREA_TYPE_R_LAUNDRY_ROOM: 124,
  AREA_TYPE_R_PANTRY: 125,
  AREA_TYPE_R_FAMILY_ROOM: 126,
  AREA_TYPE_R_LIBRARY: 127,
  AREA_TYPE_R_BALL_ROOM: 128,
  AREA_TYPE_R_DRIVEWAY: 129,
  AREA_TYPE_R_WORKSHOP: 130,
  AREA_TYPE_R_GUEST_ROOM: 131,
  AREA_TYPE_R_MASTER_BEDROOM: 132,
  AREA_TYPE_R_CORRIDOR: 133,
  AREA_TYPE_R_MEETING_ROOM: 134,
  AREA_TYPE_R_NURSERY: 135,
  AREA_TYPE_R_PARKING_PLACE: 136,
  AREA_TYPE_R_STAIRCASE: 137,
  AREA_TYPE_R_UTILITY_ROOM: 138,
  AREA_TYPE_R_POOL_HOUSE: 139,
  AREA_TYPE_R_ENTRY: 140,
  AREA_TYPE_R_ROOM: 141,
  AREA_TYPE_R_FOYER: 142,
  AREA_TYPE_R_NOOK: 143,
  AREA_TYPE_R_MEDIA_ROOM: 144,
  AREA_TYPE_R_STABLE: 145,
  AREA_TYPE_R_LOCKER_ROOM: 146
};

/**
 * @enum {number}
 */
proto.be.basalte.enums.ResetCause = {
  RST_NONE: 0,
  RST_PIN: 1,
  RST_SOFTWARE: 2,
  RST_BROWNOUT: 4,
  RST_POR: 8,
  RST_WATCHDOG: 16,
  RST_DEBUG: 32,
  RST_SECURITY: 64,
  RST_LOW_POWER_WAKE: 128,
  RST_CPU_LOCKUP: 384,
  RST_PARITY: 512,
  RST_PLL: 1024,
  RST_CLOCK: 2048,
  RST_HARDWARE: 4096,
  RST_USER: 8192,
  RST_TEMPERATURE: 16384
};

goog.object.extend(exports, proto.be.basalte.enums);
