'use strict'

var BasUtil = require('@basalte/bas-util')

var Barp = require('./barp')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var BasTrack = require('./bas_track')

var log = require('./logger')

/**
 * The clientName tells if a Spotify Connect account is "ready".
 * There can be no token without a clientName.
 *
 * @constructor
 * @extends Barp
 * @param {Object} config
 * @param {BasCore} basCore
 * @since 1.9.0
 */
function SpotifyBarp (config, basCore) {

  Barp.call(this, config, basCore)

  this._random = false
  this._repeatMode = CONSTANTS.REPEAT_OFF

  /**
   * @private
   * @type {BasTrack}
   */
  this._nextSong = new BasTrack()

  this._token = ''
  this._linkUrl = ''

  this._presets = []
  this._presetsPromise = null
  this._presetsResolve = null
  this._presetsReject = null
  this._presetsListCount = 0
  this._presetsListTimeoutId = null

  this._presetListPromiseConstr =
    this._presetListPromiseConstructor.bind(this)
  this._handlePresetListTimeout = this._onPresetListTimeout.bind(this)

  this._spotifyDirty = true

  // LEGACY
  if (BasUtil.isObject(config[P.STATUS])) {

    this.parseSpotifyBarp(
      config[P.STATUS],
      {
        emit: false
      }
    )
  }
}

SpotifyBarp.prototype = Object.create(Barp.prototype)
SpotifyBarp.prototype.constructor = SpotifyBarp

// region Events

/**
 * @event SpotifyBarp#EVT_RANDOM
 * @param {boolean} random
 */

/**
 * @event SpotifyBarp#EVT_REPEAT
 * @param {boolean} repeat
 * @param {number} repeatMode
 */

/**
 * @event SpotifyBarp#EVT_NEXT_SONG
 * @param {BasTrack} nextSong
 */

/**
 * @event SpotifyBarp#EVT_PRESETS_CHANGED
 * @param {string} uri
 * @param {string} action
 */

/**
 * A preset with uri is loaded/started
 *
 * @event SpotifyBarp#EVT_PRESET_LOADED
 * @param {string} uri
 */

/**
 * Error when loading/starting a preset with uri
 *
 * @event SpotifyBarp#EVT_PRESET_LOAD_ERROR
 * @param {string} uri
 */

/**
 * @event SpotifyBarp#EVT_TOKEN_CHANGED
 * @param {string} token
 */

/**
 * @event SpotifyBarp#EVT_LINK_URL_CHANGED
 * @param {string} linkUrl
 */

/**
 * @event SpotifyBarp#EVT_LINK_FINISHED
 * @param {boolean} finished
 */

/**
 * @event SpotifyBarp#EVT_LINK_ERROR
 * @param {string} message
 */

/**
 * @constant {string}
 */
SpotifyBarp.EVT_RANDOM = 'evtBarpSpotifyRandom'

/**
 * @constant {string}
 */
SpotifyBarp.EVT_REPEAT = 'evtBarpSpotifyRepeat'

/**
 * @constant {string}
 */
SpotifyBarp.EVT_NEXT_SONG = 'evtBarpSpotifyNextSong'

/**
 * @constant {string}
 */
SpotifyBarp.EVT_PRESETS_CHANGED = 'evtBarpSpotifyPresetsChanged'

/**
 * @constant {string}
 */
SpotifyBarp.EVT_PRESET_LOADED = 'evtBarpSpotifyPresetLoaded'

/**
 * @constant {string}
 */
SpotifyBarp.EVT_PRESET_LOAD_ERROR = 'evtBarpSpotifyPresetLoadError'

/**
 * @constant {string}
 */
SpotifyBarp.EVT_TOKEN_CHANGED = 'evtBarpSpotifyTokenChanged'

/**
 * @constant {string}
 */
SpotifyBarp.EVT_LINK_URL_CHANGED = 'evtBarpSpotifyLinkUrlChanged'

/**
 * @constant {string}
 */
SpotifyBarp.EVT_LINK_FINISHED = 'evtBarpSpotifyLinkFinished'

/**
 * @constant {string}
 */
SpotifyBarp.EVT_LINK_ERROR = 'evtBarpSpotifyLinkError'

// endregion

/**
 * @constant {string}
 */
SpotifyBarp.PRESET_ACTION_LIST = P.LIST

/**
 * @constant {string}
 */
SpotifyBarp.PRESET_ACTION_SAVE = P.SAVE

/**
 * @constant {string}
 */
SpotifyBarp.PRESET_ACTION_LOAD = P.LOAD

/**
 * @constant {string}
 */
SpotifyBarp.PRESET_ACTION_CLEAR = P.CLEAR

/**
 * Maximum size for Spotify Preset list requests
 *
 * @constant {number}
 * @since 1.4.0
 */
SpotifyBarp.PRESET_LIST_MAX_SIZE = 25

/**
 * Maximum number of retries before giving up retrieving Spotify Presets
 *
 * @constant {number}
 * @since 1.4.0
 */
SpotifyBarp.PRESET_LIST_MAX_RETRIES = 4

/**
 * @constant {number}
 * @since 1.4.0
 */
SpotifyBarp.PRESET_LIST_TIMEOUT = 3000

// region Properties

/**
 * @name SpotifyBarp#coverArt
 * @deprecated
 * @type {string}
 * @readonly
 */
Object.defineProperty(SpotifyBarp.prototype, 'coverArt', {
  get: function () {

    if (BasUtil.isNEString(this._currentSong.coverart)) {

      return this._currentSong.coverart
    }

    // LEGACY
    return undefined
  }
})

/**
 * @name SpotifyBarp#thumbnail
 * @deprecated
 * @type {string}
 * @readonly
 */
Object.defineProperty(SpotifyBarp.prototype, 'thumbnail', {
  get: function () {

    if (BasUtil.isNEString(this._currentSong.coverart)) {

      return this._currentSong.coverart
    }

    // LEGACY
    return undefined
  }
})

/**
 * @name SpotifyBarp#random
 * @type {boolean}
 */
Object.defineProperty(SpotifyBarp.prototype, 'random', {
  get: function () {
    return this._random
  },
  set: function (v) {

    var obj

    obj = this._getBasCoreMessage()
    obj[P.BARP][P.RANDOM] = v === true

    this._basCore.send(obj)
  }
})

/**
 * @name SpotifyBarp#repeatMode
 * @type {number}
 * @since 2.1.0
 */
Object.defineProperty(SpotifyBarp.prototype, 'repeatMode', {
  get: function () {
    return this._repeatMode
  },
  set: function (v) {

    var obj

    obj = this._getBasCoreMessage()

    if (this._basCore.supportsRepeatMode) {

      obj[P.BARP][P.REPEAT_MODE] = v

    } else {

      obj[P.BARP][P.REPEAT] =
        v !== CONSTANTS.REPEAT_OFF
    }

    this._basCore.send(obj)
  }
})

/**
 * @name SpotifyBarp#position
 * @type {number}
 */
Object.defineProperty(SpotifyBarp.prototype, 'position', {
  get: function () {
    return this._position
  },
  set: function (p) {

    var obj

    if (BasUtil.isPNumber(p, true)) {

      obj = this._getBasCoreMessage()
      obj[P.BARP][P.ELAPSED] = p

      this._basCore.send(obj)
    }
  }
})

/**
 * @name SpotifyBarp#nextSong
 * @type {?BasTrack}
 * @readonly
 */
Object.defineProperty(SpotifyBarp.prototype, 'nextSong', {
  get: function () {
    return this._nextSong
  }
})

/**
 * Token to be used with the Spotify Web API
 *
 * @name SpotifyBarp#token
 * @type {string}
 * @readonly
 */
Object.defineProperty(SpotifyBarp.prototype, 'token', {
  get: function () {
    return this._token
  }
})

/**
 * Link URL path to request a Spotify OAuth token.
 * Empty if there is no Spotify library access.
 *
 * @name SpotifyBarp#linkUrlPath
 * @type {string}
 * @readonly
 * @since 2.10.1
 */
Object.defineProperty(SpotifyBarp.prototype, 'linkUrlPath', {
  get: function () {
    return this._linkUrl
  }
})

/**
 * Whether the first init message has been parsed or not.
 *
 * Not important for older BasCores (without Spotify Web API support)
 *
 * @name SpotifyBarp#spotifyDirty
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(SpotifyBarp.prototype, 'spotifyDirty', {
  get: function () {
    return this._spotifyDirty
  }
})

// endregion

// region Instance methods

/**
 * @param {Object} data
 * @param {BarpParseOptions} [options]
 */
SpotifyBarp.prototype.parse = function parse (data, options) {

  var emit

  emit = true

  if (options) {
    if (options.emit === false) emit = false
  }

  // Super
  Barp.prototype.parse.call(
    this,
    data,
    {
      emit: emit,
      parseSongs: false
    }
  )

  if (BasUtil.isObject(data[P.BARP]) &&
    data[P.BARP][P.ID] === this.id &&
    data[P.BARP][P.UUID] === this.uuid) {

    this.parseSpotifyBarp(data[P.BARP])
  }
}

/**
 * @param {Object} obj
 * @param {BarpParseOptions} [options]
 */
SpotifyBarp.prototype.parseSpotifyBarp = function (obj, options) {

  var emit
  var prevCurrent, prevNext

  emit = true

  if (options) {
    if (options.emit === false) emit = false
  }

  prevCurrent = this._currentSong.hash()
  prevNext = this._nextSong.hash()

  // Current song

  if (P.SONG in obj) {

    this._currentSong.parse(
      obj[P.SONG],
      {
        onlyChanges: true
      }
    )

    this._basCore.modifySongCoverArt(
      this._currentSong,
      {
        copy: true
      }
    )

    if (prevCurrent !== this._currentSong.hash()) {

      if (emit) this.emit(Barp.EVT_CURRENT_SONG, this._currentSong)
    }
  }

  // Next song

  if (P.NEXT in obj) {

    this._nextSong.parse(
      obj[P.NEXT],
      {
        onlyChanges: true
      }
    )

    if (prevNext !== this._nextSong.hash()) {

      if (emit) this.emit(SpotifyBarp.EVT_NEXT_SONG, this._nextSong)
    }
  }

  // Presets

  if (BasUtil.isObject(obj[P.PRESET])) {

    if (obj[P.PRESET][P.SUCCESS] === true) {

      switch (obj[P.PRESET][P.ACTION]) {
        case P.LIST:

          this.parsePresetList(obj)

          break
        case P.SAVE:
        case P.CLEAR:

          if (BasUtil.isNEString(obj[P.PRESET][P.URI])) {

            if (emit) {
              this.emit(
                SpotifyBarp.EVT_PRESETS_CHANGED,
                obj[P.PRESET][P.URI],
                obj[P.PRESET][P.ACTION]
              )
            }

          } else {

            log.warn('Spotify Barp' +
              ' - Parse' +
              ' - URI missing', obj)
          }

          break
        case P.LOAD:

          if (BasUtil.isNEString(obj[P.PRESET][P.URI])) {

            if (emit) {
              this.emit(
                SpotifyBarp.EVT_PRESET_LOADED,
                obj[P.PRESET][P.URI]
              )
            }

          } else {

            log.warn('Spotify Barp' +
              ' - Parse' +
              ' - URI missing', obj)
          }

          break
      }
    } else {

      log.warn('Spotify Barp' +
        ' - Parse' +
        ' - Preset unsuccessful', obj)

      if (obj[P.PRESET][P.ACTION] === P.LOAD) {

        if (emit) {

          this.emit(
            SpotifyBarp.EVT_PRESET_LOAD_ERROR,
            obj[P.PRESET][P.URI]
          )
        }
      }
    }
  }

  if (BasUtil.isObject(obj[P.STATUS])) {

    this.parseStatusObj(obj[P.STATUS], options)

  } else {

    // LEGACY
    this.parseStatusObj(obj, options)
  }
}

/**
 * @param {Object} status
 * @param {BarpParseOptions} [options]
 */
SpotifyBarp.prototype.parseStatusObj = function (
  status,
  options
) {
  var emit

  emit = true

  if (options) {
    if (options.emit === false) emit = false
  }

  // Random

  if (BasUtil.isBool(status[P.RANDOM]) &&
    this._random !== status[P.RANDOM]) {

    this._random = status[P.RANDOM]
    if (emit) this.emit(SpotifyBarp.EVT_RANDOM, this._random)
  }

  // Repeat (mode)

  if (P.REPEAT_MODE in status) {

    if (CONSTANTS.isValidRepeatMode(status[P.REPEAT_MODE]) &&
      this._repeatMode !== status[P.REPEAT_MODE]) {

      this._repeatMode = status[P.REPEAT_MODE]

      if (emit) this.emit(SpotifyBarp.EVT_REPEAT, this._repeatMode)
    }

  } else if (P.REPEAT in status) {

    if (BasUtil.isBool(status[P.REPEAT]) &&
      (this._repeatMode !== CONSTANTS.REPEAT_OFF) !==
      status[P.REPEAT]) {

      this._repeatMode = status[P.REPEAT]
        ? CONSTANTS.REPEAT_CURRENT_CONTEXT
        : CONSTANTS.REPEAT_OFF

      if (emit) this.emit(SpotifyBarp.EVT_REPEAT, this._repeatMode)
    }
  }

  // Web API token

  if (BasUtil.isString(status[P.TOKEN]) &&
    this._token !== status[P.TOKEN]) {

    this._token = status[P.TOKEN]
    if (emit) this.emit(SpotifyBarp.EVT_TOKEN_CHANGED, this._token)
  }

  // Web API OAuth link URL

  if (BasUtil.isString(status[P.LINK_URL]) &&
    this._linkUrl !== status[P.LINK_URL]) {

    this._linkUrl = status[P.LINK_URL]
    if (emit) this.emit(SpotifyBarp.EVT_LINK_URL_CHANGED, this._linkUrl)
  }

  // Web API Link finished

  if (BasUtil.isBool(status[P.LINK_FINISHED])) {
    if (emit) {
      this.emit(
        SpotifyBarp.EVT_LINK_FINISHED,
        status[P.LINK_FINISHED]
      )
    }
  }

  // Web API Link Error

  if (BasUtil.isString(status[P.LINK_ERROR])) {
    if (emit) {
      this.emit(
        SpotifyBarp.EVT_LINK_ERROR,
        status[P.LINK_ERROR]
      )
    }
  }

  // (Web API) Init message has been parsed

  if (P.LINK_URL in status) {

    this._spotifyDirty = false
  }
}

/**
 * Parses a Spotify Barp message with list preset information.
 *
 * This will eventually resolve a list promise.
 *
 * @param {Object} obj
 */
SpotifyBarp.prototype.parsePresetList = function parsePresetList (obj) {

  var i, length
  var preset, list
  var offset, newOffset, currentLength

  preset = obj[P.PRESET]
  list = preset[P.LIST]

  offset = preset[P.OFFSET]

  // Make sure presets is an array
  if (!Array.isArray(this._presets)) this._presets = []

  if (Array.isArray(list)) {

    length = list.length
    currentLength = this._presets.length

    if (length > 0) {

      if (offset === currentLength) {

        // Append new presets
        this._presets = this._presets.concat(list)

      } else if (offset < currentLength) {

        log.debug(
          'Spotify Barp - parsePresetList' + ' - Rewrite old entries',
          obj,
          this._presets
        )

        // Replace presets
        for (i = 0; i < length; i++) {

          // Overwrite preset
          this._presets[offset + i] = list[i]
        }

      } else {

        log.warn(
          'Spotify Barp - parsePresetList' +
          ' - Offset is bigger then currentLength',
          obj,
          this._presets
        )
      }

      // Check if more presets need to be requested
      if (length === SpotifyBarp.PRESET_LIST_MAX_SIZE) {

        log.info(
          'Spotify Barp - parsePresetList - Request next page',
          obj,
          this._presets
        )

        newOffset = offset + SpotifyBarp.PRESET_LIST_MAX_SIZE

        this._retrievePresetList(newOffset)

      } else {

        // Reached the end
        this._resolveListPreset()
      }

    } else {

      // Reached the end
      this._resolveListPreset()
    }

  } else {

    log.error('Spotify Barp - Parse - Preset list no array', obj)

    this._rejectListPreset(CONSTANTS.ERR_INVALID_RESPONSE)
  }
}

/**
 * Link URL to request a Spotify OAuth token
 *
 * Make sure Spotify link URL is available,
 * otherwise this will just point to the server
 *
 * @returns {string}
 * @since 3.0.0
 */
SpotifyBarp.prototype.getLinkUrl = function () {

  // TODO Handle WebRTC use-case

  return this._basCore
    ? this._basCore.getHTTPUrl(this._linkUrl)
    : ''
}

/**
 * Spotify preset commands
 * <ul>
 *     <li>list</li>
 *     <li>save</li>
 *     <li>load</li>
 *     <li>clear</li>
 * </ul>
 *
 * @param {string} action
 * @param {(string|number)} argument
 * @since 1.4.0
 */
SpotifyBarp.prototype.preset = function preset (action, argument) {

  var obj

  obj = this._getBasCoreMessage()
  obj[P.BARP][P.PRESET] = {}
  obj[P.BARP][P.PRESET][P.ACTION] = action

  switch (action) {
    case P.LIST:

      obj[P.BARP][P.PRESET][P.OFFSET] = argument

      break
    case P.LOAD:
    case P.CLEAR:

      obj[P.BARP][P.PRESET][P.URI] = argument

      break
  }

  if (this._basCore) this._basCore.send(obj)
}

/**
 * Retrieve all Spotify Connect presets
 *
 * @returns {Promise}
 * @since 1.4.0
 */
SpotifyBarp.prototype.getPresets = function getPresets () {

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (this._presetsPromise) return this._presetsPromise

  // Make sure timeout is cleared
  clearTimeout(this._presetsListTimeoutId)

  // Reset current presets
  this._presets = []

  // Set attempt #1
  this._presetsListCount = 1

  // Retrieve list, initial request starts at offset = 0
  this._retrievePresetList(0)

  return (
    this._presetsPromise = new Promise(this._presetListPromiseConstr)
  )
}

/**
 * Timeout callback for retrieving presets
 *
 * @private
 * @param {number} offset
 * @since 1.4.0
 */
SpotifyBarp.prototype._onPresetListTimeout = function (offset) {

  log.warn('Spotify Barp - Preset List Timeout reached', offset)

  clearTimeout(this._presetsListTimeoutId)

  if (this._basCore) {

    if (this._presetsListCount <= SpotifyBarp.PRESET_LIST_MAX_RETRIES) {

      // Increment attempt #
      this._presetsListCount++

      // Retry
      this._retrievePresetList(offset)

    } else {

      this._rejectListPreset(CONSTANTS.ERR_TIMEOUT)
    }

  } else {

    this._rejectListPreset(CONSTANTS.ERR_NO_CORE)
  }
}

/**
 * @private
 * @param {number} offset
 */
SpotifyBarp.prototype._retrievePresetList = function (offset) {

  this.preset(
    P.LIST,
    offset
  )

  this._presetsListTimeoutId = setTimeout(
    this._handlePresetListTimeout,
    SpotifyBarp.PRESET_LIST_TIMEOUT,
    offset
  )
}

/**
 * Resolves the list preset Promise
 *
 * @private
 */
SpotifyBarp.prototype._resolveListPreset = function resolveListPreset () {

  // Clear Promise variables
  clearTimeout(this._presetsListTimeoutId)
  this._presetsListCount = 0

  if (BasUtil.isFunction(this._presetsResolve)) {

    this._presetsResolve(this._presets)
  }

  // Clear Promise and Promise callbacks
  this._presetsPromise = null
  this._presetsResolve = null
  this._presetsReject = null
}

/**
 * Rejects the list preset Promise
 *
 * @private
 * @param {string} [reason]
 */
SpotifyBarp.prototype._rejectListPreset = function rejectListPreset (reason) {

  // Clear Promise variables
  clearTimeout(this._presetsListTimeoutId)
  this._presetsListCount = 0

  if (BasUtil.isFunction(this._presetsReject)) this._presetsReject(reason)

  // Clear Promise and Promise callbacks
  this._presetsPromise = null
  this._presetsResolve = null
  this._presetsReject = null
}

/**
 * @private
 * @param {Function} resolve
 * @param {Function} reject
 */
SpotifyBarp.prototype._presetListPromiseConstructor = function (
  resolve,
  reject
) {
  this._presetsResolve = resolve
  this._presetsReject = reject
}

/**
 * Clear Barp state information
 *
 * @private
 */
SpotifyBarp.prototype.clearInfo = function clearInfo () {

  // Super
  Barp.prototype.clearInfo.call(this)

  if (this._nextSong) this._nextSong.resetAll()
}

/**
 * Disconnects the Spotify account for this stream
 */
SpotifyBarp.prototype.unlink = function unlink () {

  var obj

  obj = this._getBasCoreMessage()
  obj[P.BARP][P.ACTION] = this._basCore.supportsSpotifyUnlink
    ? P.UNLINK
    : P.LINK_DISCONNECT

  this._basCore.send(obj)
}

/**
 * Remove connected Spotify Connect client
 */
SpotifyBarp.prototype.removeConnected = function removeConnected () {

  var obj

  if (this._basCore.supportsSpotifyUnlink) {

    obj = this._getBasCoreMessage()
    obj[P.BARP][P.ACTION] = P.LINK_RESTORE

    this._basCore.send(obj)

  } else {

    this.unlink()
  }
}

/**
 * Play, optionally pass a Spotify body object for extra parameters
 *
 * @see {@link https://developer.spotify.com/web-api/start-a-users-playback/
 *     Spotify documentation}
 *
 * @param {Object} [options]
 */
SpotifyBarp.prototype.play = function play (options) {

  var obj

  obj = this._getBasCoreMessage()
  obj[P.BARP][P.STATE] = P.PLAY

  if (BasUtil.isObject(options)) obj[P.BARP][P.OPTIONS] = options

  this._basCore.send(obj)
}

/**
 * @since 2.5.0
 * @param {number} seconds
 */
SpotifyBarp.prototype.relativeSeek = function relativeSeek (seconds) {

  var obj

  if (BasUtil.isVNumber(seconds)) {

    obj = this._getBasCoreMessage()
    obj[P.BARP][P.ACTION] = P.RELATIVE_SEEK
    obj[P.BARP][P.SECONDS] = seconds

    this._basCore.send(obj)
  }
}

/**
 * @since 2.0.0
 */
SpotifyBarp.prototype.destroy = function () {

  // Call super method
  Barp.prototype.destroy.call(this)

  this._rejectListPreset(CONSTANTS.ERR_NO_CORE)
}

// endregion

module.exports = SpotifyBarp
