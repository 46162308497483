'use strict'

angular
  .module('basalteApp')
  .service('BasInsomnia', [
    '$window',
    BasInsomnia
  ])

function BasInsomnia (
  $window
) {
  this.keepAwake = keepAwake
  this.allowSleepAgain = allowSleepAgain

  function keepAwake () {

    var insomnia = _getInsomnia()

    return insomnia ? insomnia.keepAwake() : null
  }

  function allowSleepAgain () {

    var insomnia = _getInsomnia()

    return insomnia ? insomnia.allowSleepAgain() : null
  }

  function _getInsomnia () {
    return $window['plugins']
      ? $window['plugins']['insomnia']
      : null
  }
}
