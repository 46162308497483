'use strict'

angular
  .module('basalteApp')
  .constant('BAS_APP_STORAGE', {
    K_KNOWN_SERVERS: 'KNOWN_SERVERS',
    K_LAST_KNOWN_SERVER: 'LAST_KNOWN_SERVER',
    K_KNOWN_SERVERS_V2: '_V2_KNOWN_SERVERS',
    K_LAST_KNOWN_SERVER_V2: '_V2_LAST_KNOWN_SERVER',
    K_KNOWN_SERVERS_V3: '_V3_KNOWN_SERVERS',
    K_SERVER_SETTINGS_V3: '_V3_SERVER_SETTINGS',
    K_LAST_KNOWN_SERVER_V3: '_V3_LAST_KNOWN_SERVER',
    K_STORED_SERVERS: 'STORED_SERVERS',
    K_MAX_DISCOVER_REQUESTS: '_MAX_DISCOVER_REQUESTS',
    K_TYPE: 'type',
    K_MAC_N: 'macN',
    K_MAC: 'mac',
    K_ADDRESS: 'address',
    K_CID: 'CID',
    K_USERS: 'users',
    K_SETTINGS: 'settings',
    K_LAST_USER: 'lastUser',
    K_DEFAULT_ROOMS_NOTIFICATION: 'defaultRoomNotification',
    K_CREDENTIAL_HASH: 'credentialHash',
    K_CREDENTIAL_TOKEN_HASH: 'credentialTokenHash',
    K_NAME: 'name',
    K_ROOM: 'room',
    K_HISTORY: 'history',
    K_MODAL_TIMESTAMP: 'modalTimeStamp',
    V_CID: 'cid',
    V_MAC: 'mac',
    V_ADDRESS: 'address',
    T_LOCAL: 'local',
    T_REMOTE: 'remote',
    T_DEMO: 'demo'
  })
