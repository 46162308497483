'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('multiScreenInputModalCtrl', [
    '$rootScope',
    '$scope',
    'BAS_INPUT_MODAL',
    'BAS_SPLASH',
    'BasAppDevice',
    'BasUtilities',
    'basModalConfig',
    'close',
    multiScreenInputModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasAppDevice} BasAppDevice
 * @param {BasUtilities} BasUtilities
 * @param {BasInputModalConfig} basInputModalConfig
 * @param close
 */
function multiScreenInputModalCtrl (
  $rootScope,
  $scope,
  BAS_INPUT_MODAL,
  BAS_SPLASH,
  BasAppDevice,
  BasUtilities,
  basInputModalConfig,
  close
) {
  var modal = this

  var EL_CLASS = 'bas-multi-modal-screen-container'

  var CSS_AUTO_ADVANCE = 'bas-multi-modal-auto-advance'
  var CSS_CAN_NEXT = 'bas-multi-modal-can-next'
  var CSS_CAN_PREVIOUS = 'bas-multi-modal-can-previous'
  var CSS_HAS_NEXT = 'bas-multi-modal-has-next'
  var CSS_HAS_PREVIOUS = 'bas-multi-modal-has-previous'
  var CSS_CAN_SAVE = 'bas-multi-modal-can-save'
  var CSS_HAS_SAVE = 'bas-multi-modal-has-save'
  var CSS_HAS_BOTTOM_BUTTONS = 'bas-multi-modal-has-bottom-buttons'
  var CSS_FULL_HEIGHT = 'bas-multi-modal-full-height'
  var CSS_USE_DYNAMIC_HEIGHT = 'bas-multi-modal-use-dynamic-height'
  var CSS_PRE_RENDER = 'bas-multi-modal-pre-render'

  var containerElement

  var _listeners = []

  modal.BAS_INPUT_MODAL = BAS_INPUT_MODAL

  modal.cfg = basInputModalConfig

  modal.isValid = false
  modal.isValidAtCurrent = false

  modal.currentScreen = 0

  modal.results = []
  modal.css = {}

  modal.backClicked = backClicked
  modal.closeModal = closeModal
  modal.next = next
  modal.previous = previous
  modal.save = save
  modal.isResult = isResult
  modal.setResult = setResult

  init()

  function init () {

    var i, length

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)

    // Set initial values in results
    length = modal.cfg.inputs.length

    for (i = 0; i < length; i++) {

      modal.results[i] = modal.cfg.inputs[i].initialValue
    }

    _syncIsValid()
    _updateCss()

    modal.css[CSS_PRE_RENDER] = true

    setTimeout(_onHtmlRendered, 0)
    BasUtilities.waitForFrames(2, _onHtmlRendered)
  }

  function _onHtmlRendered () {

    modal.css[CSS_PRE_RENDER] = false

    _syncContainerElement()
    _syncModalHeight()

    $scope.$applyAsync()
  }

  function next () {

    _setCurrentScreen(modal.currentScreen + 1)
  }

  function previous () {

    var length, i

    if (modal.cfg.allowGoBack) {

      if (modal.cfg.clearCurrentOnBack) {

        modal.results[modal.currentScreen] = undefined

        _syncIsValid()
        _updateCss()
      }

      if (modal.cfg.clearAllOnBack) {

        length = modal.cfg.inputs.length

        for (i = 0; i < length; i++) {

          modal.results[i] = undefined
        }

        _syncIsValid()
        _updateCss()
      }

      _setCurrentScreen(modal.currentScreen - 1)
      _syncIsValid()
      _updateCss()
    }
  }

  function isResult (input, value) {

    return (
      !BasUtil.isUndefined(value) &&
      modal.results[_getInputIndex(input)] === value
    )
  }

  function setResult (input, value) {

    var index

    if (value !== null && !BasUtil.isUndefined(value)) {

      index = _getInputIndex(input)
      modal.results[index] = value

      if (
        !modal.cfg.inputs[index].validCheck ||
        modal.cfg.inputs[index].validCheck(value)
      ) {

        _syncIsValid()

        if (modal.cfg.autoAdvance && !modal.isValidAtCurrent) {

          next()

        } else {

          if (modal.isValid && modal.cfg.autoSave) {

            save()
          }
        }
      }
    }

    _syncIsValid()
    _updateCss()
  }

  function _getInputIndex (input) {

    return modal.cfg.inputs.indexOf(input)
  }

  function _setCurrentScreen (index) {

    modal.currentScreen =
      Math.min(modal.cfg.inputs.length - 1, Math.max(0, index))

    _updateCss()
    _syncModalHeight()
  }

  function _syncContainerElement () {

    containerElement = document.getElementsByClassName(EL_CLASS)[0]
  }

  function _syncModalHeight () {

    var children, pageElement, inner, extraHeight, maxVh

    if (!modal.cfg.useDynamicHeight) return
    if (!containerElement) return

    children = containerElement.children

    if (children) {

      pageElement = children[modal.currentScreen]

      if (pageElement) {

        inner = pageElement.getElementsByClassName(
          'bas-multi-modal-screen-inner'
        )[0]

        if (!inner) return

        // Magic numbers:
        //  79px: +- space taken up by modal header
        //  52px: spaces taken up by extra 'save' button at bottom of modal
        //  75vh: complete modal should at max take up 75% of vertical viewport

        extraHeight = 79
        if (!modal.cfg.autoSave) extraHeight += 52

        maxVh = BasAppDevice.isLisa() ? '100vh' : '75vh'

        // Compatibility: use height and max-height, transition will not animate
        //  to the correct value but end result will be ok
        containerElement.style.height = inner.offsetHeight + 'px'
        containerElement.style.maxHeight =
          'calc(' + maxVh + ' - ' + extraHeight + 'px)'

        // If css min(...) function is available, override height
        // If the CSS engine does not know this function and sees it as an
        //  invalid property, the property will not be set and the previously
        //  set height property will remain.
        containerElement.style.height =
          'min(' +
          inner.offsetHeight +
          'px, calc(' + maxVh + ' - ' +
          extraHeight +
          'px))'
        pageElement.scrollTop = 0
      }
    }
  }

  function _syncIsValid () {

    var resultsCopy

    modal.isValid = modal.cfg.validCheck(modal.results)

    resultsCopy = modal.results.slice(0, modal.currentScreen + 1)

    modal.isValidAtCurrent = modal.cfg.validCheck(resultsCopy)
  }

  function _updateCss () {

    var displayNavigation
    displayNavigation = modal.cfg.inputs.length >= 2

    modal.css[CSS_AUTO_ADVANCE] = modal.cfg.autoAdvance
    modal.css[CSS_CAN_NEXT] =
      modal.currentScreen !== modal.cfg.inputs.length - 1
    modal.css[CSS_CAN_PREVIOUS] = modal.currentScreen !== 0
    modal.css[CSS_HAS_NEXT] =
      displayNavigation &&
      !modal.cfg.autoAdvance
    modal.css[CSS_HAS_PREVIOUS] =
      displayNavigation &&
      !modal.cfg.autoAdvance &&
      modal.cfg.allowGoBack
    modal.css[CSS_CAN_SAVE] = modal.isValidAtCurrent
    modal.css[CSS_HAS_SAVE] = !modal.cfg.autoSave
    modal.css[CSS_FULL_HEIGHT] = _areAllInputsSelectType()
    modal.css[CSS_USE_DYNAMIC_HEIGHT] =
      modal.cfg.useDynamicHeight
    modal.css[CSS_HAS_BOTTOM_BUTTONS] =
      modal.css[CSS_HAS_SAVE] ||
      modal.css[CSS_HAS_NEXT] ||
      modal.css[CSS_HAS_PREVIOUS]
  }

  function _areAllInputsSelectType () {

    var length, i

    length = modal.cfg.inputs.length
    for (i = 0; i < length; i++) {

      if (modal.cfg.inputs[i].type !== BAS_INPUT_MODAL.T_SELECT) {

        return false
      }
    }

    return true
  }

  function backClicked () {

    if (modal.currentScreen !== 0 &&
      modal.cfg.allowGoBack) {

      previous()

    } else {

      closeModal()
    }
  }

  function closeModal (reason) {

    if (!modal.cfg || modal.cfg.allowDismiss) close(reason)
  }

  function save () {

    _syncIsValid()

    if (modal.isValidAtCurrent) close(modal.results, 0)
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
