'use strict'

import * as BasUtil from '@basalte/bas-util'

var _idCounter = 0

function _getId () {

  return _idCounter++
}

angular
  .module('basalteApp')
  .service('BasSmartConnect', [
    '$rootScope',
    'BAS_CONNECT',
    'BAS_APP_STORAGE',
    'BAS_ERRORS',
    'BAS_DISCOVERY',
    'BasAppDevice',
    'BasConnectInfo',
    'BasDiscovery',
    'BasServerStorage',
    'BasLiveAccount',
    'BasConnect',
    'BasConnectHelper',
    'BasError',
    'Logger',
    BasSmartConnect
  ])

/**
 * @typedef {Object} TBasSmartConnect
 * @property {BasConnectInfo} [preferred]
 * @property {string} [preferredProjectId]
 * @property {(string|number)} [preferredMac]
 * @property {string} [preferredAddress]
 * @property {boolean} [allowOther = false]
 */

/**
 * @typedef {Object} TBasSmartConnectOptions
 * @property {number} [otherTimeout] Timout before checking others
 * @property {boolean} [noLogin] Only setup connection, do not perform login
 */

/**
 * @callback CBasSmartConnectCallback
 * @param {?BasError} error
 * @param {TBasSmartConnect} result
 */

/**
 * @callback CBasSmartConnectAbort
 */

/**
 * @callback CBasSmartConnectTakeBasServer
 * @returns {?BasServer}
 */

/**
 * @callback CBasOnDiscoveryEventCallback
 */

/**
 * @callback CBasOnDiscoveryTimeoutCallback
 */

/**
 * @typedef {Object} TBasSmartConnect
 * @property {CBasSmartConnectAbort} abort
 * @property {CBasSmartConnectTakeBasServer} takeBasServer
 * @property {TBasSmartConnect} target
 * @property {TBasSmartConnectOptions} options
 * @property {boolean} finished
 * @property {BasServer} [basServer]
 * @property {TBasConnectRetry} [basConnectRetry]
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_CONNECT} BAS_CONNECT
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BAS_DISCOVERY} BAS_DISCOVERY
 * @param {BasAppDevice} BasAppDevice
 * @param BasConnectInfo
 * @param {BasDiscovery} BasDiscovery
 * @param {BasServerStorage} BasServerStorage
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasConnect} BasConnect
 * @param {BasConnectHelper} BasConnectHelper
 * @param BasError
 * @param Logger
 */
function BasSmartConnect (
  $rootScope,
  BAS_CONNECT,
  BAS_APP_STORAGE,
  BAS_ERRORS,
  BAS_DISCOVERY,
  BasAppDevice,
  BasConnectInfo,
  BasDiscovery,
  BasServerStorage,
  BasLiveAccount,
  BasConnect,
  BasConnectHelper,
  BasError,
  Logger
) {
  var _WAIT_FOR_DISCOVERY_MS = 1000
  var CONN_LOCAL_PREFERRED_TIMEOUT_MS = 5000
  var CONN_LOCAL_TIMEOUT_MS = 4000
  var CONN_REMOTE_TIMEOUT_MS = 20000
  var CONN_DEMO_TIMEOUT_MS = 1000

  /**
   * @type {TBasLiveAccountState}
   */
  var basLiveAccountState = BasLiveAccount.get()

  this.connect = connect
  this.checkValidSmartConnectTarget = checkValidSmartConnectTarget

  /**
   * @param {TBasSmartConnect} options
   * @returns {?TBasSmartConnect}
   */
  function checkValidSmartConnectTarget (options) {

    var _allowOther, _preferred, _hasConnectionPreferences
    var _preferredProjectId, _preferredMac, _preferredAddress

    if (BasUtil.isObject(options)) {

      if (BasUtil.isObject(options.preferred)) {

        _preferred = options.preferred
      }

      if (BasUtil.isNEString(options.preferredProjectId)) {

        _preferredProjectId = options.preferredProjectId
      }

      if (BasUtil.isNEString(options.preferredMac) ||
        BasUtil.isPNumber(options.preferredMac)) {

        _preferredMac = options.preferredMac
      }

      if (BasUtil.isNEString(options.preferredAddress)) {

        _preferredAddress = options.preferredAddress
      }

      if (BasUtil.isBool(options.allowOther)) {

        _allowOther = options.allowOther
      }

      _hasConnectionPreferences = !!(
        _preferred ||
        _preferredProjectId ||
        _preferredMac ||
        _preferredAddress
      )

      if (_allowOther || _hasConnectionPreferences) return options
    }

    return null
  }

  /**
   * Find known credentials for a given BasConnectOptions
   *
   * @private
   * @param {BasConnectInfo} basConnectInfo
   * @returns {BasConnectInfo}
   */
  function _getBasConnectOptions (basConnectInfo) {

    var basConnectCredentials

    if (!basConnectInfo.credentials && basConnectInfo.server.cid) {

      basConnectCredentials = BasServerStorage
        .getBasConnectCredentialsLastUserForProjectId(
          basConnectInfo.server.cid
        )

      if (basConnectCredentials) {

        basConnectInfo.setCredentials(basConnectCredentials)
      }
    }

    return basConnectInfo
  }

  /**
   * For best results make sure discovery is running.
   *
   * AllowOther without a preferred connection and
   * without known cores (storage),
   * will look for a single discovered server (live or local)
   *
   * AllowOther without a preferred connection and with known cores,
   * will look the other known cores.
   *
   * AllowOther with a preferred connection will look for other known cores
   *
   * @param {TBasSmartConnect} target
   * @param {?TBasSmartConnectOptions} [options]
   * @param {CBasSmartConnectCallback} [callback]
   * @returns {TBasSmartConnect}
   */
  function connect (
    target,
    options,
    callback
  ) {
    var _target, _result, _aborted, _cbCalled, _willResolve
    var _allowOther, _otherTimeout
    var _preferred, _preferredProjectId, _preferredMac, _preferredAddress
    var _preferredCid
    var _projectId
    var _hasConnectionPreferences
    var _connections
    var _hasKnownCores
    var _shouldWaitForDiscovery
    var _shouldWaitForCoreClientCandidates
    var _waitDiscoveryTimeoutId, _waitListLiveProjectsTimeoutId
    var _discoveryEventListener
    var _shouldRetrieveLiveProjects, _retrievingLiveProjects
    var _checkForCoreClientServer
    var _tConnectStart
    var _connectStats
    var _noLogin

    var _id, tag

    _id = _getId()
    tag = 'BasSmartConnect (' + _id + ')'

    _aborted = false
    _cbCalled = false

    // Stop callbacks from connections that are being cleaned up.
    _willResolve = false

    _result = {
      abort: abort,
      takeBasServer: takeBasServer,
      target: target,
      options: options,
      finished: false
    }

    _allowOther = false
    _otherTimeout = 0

    /**
     * @type {?BasConnectInfo}
     */
    _preferred = null
    _preferredCid = ''

    _projectId = ''

    _hasConnectionPreferences = false

    _hasKnownCores = BasServerStorage.hasKnownCores()
    _shouldWaitForDiscovery = BasDiscovery.isRunning()
    _shouldWaitForCoreClientCandidates = BasAppDevice.isCoreClient()
    _shouldRetrieveLiveProjects = basLiveAccountState.isLoggedIn
    _retrievingLiveProjects = false

    /**
     * @private
     * @type {TBasConnectRetry[]}
     */
    _connections = []

    _waitDiscoveryTimeoutId = 0
    _waitListLiveProjectsTimeoutId = 0

    _tConnectStart = Date.now()

    /**
     * @type {TBasConnectStats}
     */
    _connectStats = {}
    _connectStats.totalTime = 0
    _connectStats.numberOfCandidates = 0

    if (BasUtil.isObject(options)) {

      if (BasUtil.isPNumber(options.otherTimeout)) {

        _otherTimeout = options.otherTimeout
      }

      if (BasUtil.isBool(options.noLogin)) {

        _noLogin = options.noLogin
      }
    }

    _target = checkValidSmartConnectTarget(target)

    if (BasUtil.isObject(_target)) {

      if (BasUtil.isObject(_target.preferred)) {

        _preferred = _target.preferred

        if (_preferred.server.cid) {

          _preferredCid = _preferred.server.cid
        }
      }

      if (BasUtil.isNEString(_target.preferredProjectId)) {

        _preferredProjectId = _target.preferredProjectId
      }

      if (BasUtil.isNEString(_target.preferredMac) ||
        BasUtil.isPNumber(_target.preferredMac)) {

        _preferredMac = _target.preferredMac
      }

      if (BasUtil.isNEString(_target.preferredAddress)) {

        _preferredAddress = _target.preferredAddress
      }

      if (BasUtil.isBool(_target.allowOther)) {

        _allowOther = _target.allowOther
      }

      _hasConnectionPreferences = !!(
        _preferred ||
        _preferredProjectId ||
        _preferredMac ||
        _preferredAddress
      )

      _projectId = _preferredCid || (_preferredProjectId || '')

      _start()

    } else {

      _cb(new BasError(
        BAS_ERRORS.T_INVALID_INPUT,
        options,
        BAS_ERRORS.M_INVALID_INPUT
      ))
    }

    return _result

    function _start () {

      Logger.info(
        tag + ' - Start',
        '\nallowOther:',
        _allowOther,
        '\npreferred:',
        _preferred,
        '\nhasKnownCores:',
        _hasKnownCores,
        '\nshouldWaitForDiscovery:',
        _shouldWaitForDiscovery,
        '\nshouldRetrieveLiveProjects:',
        _shouldRetrieveLiveProjects
      )

      if (_preferred) {

        _addConnection(
          _preferred,
          {
            retries: 2,
            totalTimeout: _preferred.type === BAS_CONNECT.T_REMOTE
              ? CONN_REMOTE_TIMEOUT_MS
              : CONN_LOCAL_PREFERRED_TIMEOUT_MS
          }
        )

        // To help browser client connect with local connection

        if (_projectId && !BasAppDevice.isLiveOnlyOrWebRTC()) {

          BasServerStorage.iterateKnownCores(
            _onKnownCoreForPreferred,
            [
              BAS_APP_STORAGE.T_LOCAL
            ]
          )
        }
      }

      if (_allowOther) {

        if (_hasKnownCores) {

          BasServerStorage.iterateKnownCores(
            _onKnownCore,
            BasAppDevice.isLiveOnlyOrWebRTC()
              ? [
                  BAS_APP_STORAGE.T_REMOTE
                ]
              : [
                  BAS_APP_STORAGE.T_REMOTE,
                  BAS_APP_STORAGE.T_LOCAL
                ]
          )
        }
      }

      if (_shouldRetrieveLiveProjects) {

        _checkLiveProjects()

        _retrievingLiveProjects = true

        BasLiveAccount.listProjects({
          multipleRequestOffsets: [
            500,
            1000,
            1500,
            2000,
            3000,
            4000
          ]
        })
          .then(_onLiveProjects, _onLiveProjectsError)
      }

      if (_shouldWaitForDiscovery) {

        _checkDiscoveredCore()

        _waitForDiscoveredCoresUpdated(
          _onDiscoveredCoresUpdated,
          _checkConnectStatus
        )
      }

      if (_shouldWaitForCoreClientCandidates) {

        _checkForCoreClientServer =
          BasConnectHelper.checkForCoreClientServer(
            {
              timeout: CONN_LOCAL_TIMEOUT_MS,
              allowOther: _allowOther,
              preferredProjectId: _projectId,
              preferredMac: _preferredMac,
              preferredAddress: _preferredAddress
            },
            _onCheckForCoreClientServer
          )
      }

      _checkConnectStatus()

      /**
       * @private
       * @param {BasStoredServer} storedServer
       */
      function _onKnownCoreForPreferred (storedServer) {

        var _basConnectInfo

        if (storedServer.cid === _projectId) {

          _basConnectInfo = BasConnectInfo.build(storedServer)

          _addConnection(
            _getBasConnectOptions(_basConnectInfo),
            _getStandardRetryOptions(_basConnectInfo)
          )
        }
      }

      /**
       * @private
       * @param {BasStoredServer} storedServer
       */
      function _onKnownCore (storedServer) {

        var service, _basConnectInfo

        service = BasDiscovery.searchServiceByCID(storedServer.cid)

        if (service) {

          _basConnectInfo = BasConnectInfo.build(service)

          _addConnection(
            _getBasConnectOptions(_basConnectInfo),
            _getStandardRetryOptions(_basConnectInfo),
            storedServer.cid !== _projectId
          )
        }
      }

      function _onDiscoveredCoresUpdated () {

        if (_aborted || _cbCalled) return

        _checkDiscoveredCore()

        _checkConnectStatus()
      }

      function _checkDiscoveredCore () {

        var service, _basConnectInfo

        // Core Clients: check for project with core client

        if (_checkForCoreClientServer) {

          _checkForCoreClientServer.checkDiscovered()
        }

        // Check for preferred project

        service = _getPreferredDiscoveredService()

        if (service) {

          _basConnectInfo = BasConnectInfo.build(service)

          _addConnection(
            _getBasConnectOptions(_basConnectInfo),
            _getStandardRetryOptions(_basConnectInfo)
          )
        }

        if (_allowOther) {

          if (_hasKnownCores) {

            // Check known servers against discovered projects
            // (IP address could have changed)

            BasServerStorage.iterateKnownCores(
              _onKnownCore,
              BasAppDevice.isLiveOnlyOrWebRTC()
                ? [
                    BAS_APP_STORAGE.T_REMOTE
                  ]
                : [
                    BAS_APP_STORAGE.T_REMOTE,
                    BAS_APP_STORAGE.T_LOCAL
                  ]
            )
          }

          if (!service) {

            service = BasDiscovery.getSingleService()

            if (service) {

              _basConnectInfo = BasConnectInfo.build(service)

              _addConnection(
                _getBasConnectOptions(_basConnectInfo),
                _getStandardRetryOptions(_basConnectInfo),
                true
              )
            }
          }
        }
      }

      /**
       * @private
       * @returns {?BasDiscoveredCore}
       */
      function _getPreferredDiscoveredService () {

        var service

        if (!service) {

          if (_preferredProjectId) {

            service = BasDiscovery.searchServiceByCID(
              _preferredProjectId
            )
          }
        }

        if (!service) {

          if (_preferredMac) {

            service = BasDiscovery.searchServiceByMACAddress(
              _preferredMac
            )
          }
        }

        if (!service) {

          if (_preferredAddress) {

            service = BasDiscovery.searchServiceByAddress(
              _preferredAddress
            )
          }
        }

        return service
      }

      /**
       * @private
       * @param {?BasError} error
       * @param {TCheckForCoreClientServer} result
       */
      function _onCheckForCoreClientServer (
        error,
        result
      ) {
        var _connection, _basConnectInfo

        if (_aborted || _cbCalled) return

        if (_checkForCoreClientServer === result) {

          if (error) {

            // Ignore

          } else {

            _connection = _checkForCoreClientServer
              .takeBasServerConnectionCandidate()

            if (_connection) {

              _basConnectInfo = BasConnectInfo.build(_connection.basServer)
              _basConnectInfo.basServer = _connection.basServer

              _basConnectInfo.setCredentialsFrom(
                _connection.coreClientInfo.username,
                _connection.coreClientInfo.password
              )

              _addConnection(
                _basConnectInfo,
                _getStandardRetryOptions(_basConnectInfo)
              )
            }
          }

          _checkConnectStatus()
        }
      }

      function _checkLiveProjects () {

        var length, basLiveProject, _basConnectInfo

        if (_projectId) {

          basLiveProject = BasLiveAccount.getOnlineProject(_projectId)

          if (basLiveProject) {

            _basConnectInfo = BasConnectInfo.build(basLiveProject)

            _addConnection(
              _getBasConnectOptions(_basConnectInfo),
              _getStandardRetryOptions(_basConnectInfo)
            )
          }
        }

        if (_allowOther) {

          length = basLiveAccountState.uiProjectsOnline.length

          if (length > 1) {

            // Too many options, user needs to choose

          } else if (length === 1) {

            basLiveProject = BasLiveAccount.getAnyOnlineProject()

            if (basLiveProject) {

              _basConnectInfo = BasConnectInfo.build(basLiveProject)

              _addConnection(
                _getBasConnectOptions(_basConnectInfo),
                _getStandardRetryOptions(_basConnectInfo),
                basLiveProject.uuid !== _projectId
              )
            }
          }
        }
      }

      function _onLiveProjects () {

        if (_aborted || _cbCalled) return

        _retrievingLiveProjects = false

        _checkLiveProjects()

        _checkConnectStatus()
      }

      function _onLiveProjectsError () {

        if (_aborted || _cbCalled) return

        _retrievingLiveProjects = false

        _checkConnectStatus()
      }

      function _checkConnectStatus () {

        if (_aborted || _cbCalled) return

        if (_connections.length) {

          if (_areAllConnectionsFinished()) {

            _checkAllConnections()

          } else {

            // Connection ongoing, _onConnect needs to handle it
          }

        } else {

          // No connection ongoing

          if (_waitDiscoveryTimeoutId || _retrievingLiveProjects) {

            // Wait for discover actions

          } else if (
            _checkForCoreClientServer &&
            !_checkForCoreClientServer.finished
          ) {

            // Wait for checking for Core Client server

          } else {

            // No candidates found

            _errorCb(new BasError(
              BAS_ERRORS.T_TIMEOUT,
              undefined,
              'No candidates found'
            ))
          }
        }
      }
    }

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasConnectRetry} result
     */
    function _onConnect (error, result) {

      var idx, _connectCid, _onConnectMatchesCid

      if (_aborted || _cbCalled || _willResolve) return

      // Check if event is still relevant
      idx = _connections.indexOf(result)
      if (idx < 0) return

      _onConnectMatchesCid = false

      Logger.log(
        tag + ' - onConnect',
        '\nerror:',
        error,
        '\nresult.basConnect:',
        result.basConnect,
        '\nresult.basConnect.basServer:',
        (result.basConnect ? result.basConnect.basServer : null),
        '\n_preferred:',
        _preferred,
        '\n_preferredCid:',
        _preferredCid,
        '\nresult.basConnect.options:',
        (result.basConnect ? result.basConnect.options : null),
        '\nconnectCid:',
        ((
          result.basConnect &&
          result.basConnect.options &&
          result.basConnect.options.server
        )
          ? result.basConnect.options.server.cid
          : null),
        '\nresult.basConnect.options === _preferred:',
        (
          result.basConnect
            ? result.basConnect.options === _preferred
            : null
        ),
        '\n_hasConnectionPreferences:',
        _hasConnectionPreferences,
        '\n_waitDiscoveryTimeoutId:',
        _waitDiscoveryTimeoutId,
        '\n_retrievingLiveProjects:',
        _retrievingLiveProjects,
        '\n_areAllConnectionsFinished():',
        _areAllConnectionsFinished(),
        '\n_connections.length:',
        _connections.length,
        '\nallowOther:',
        _allowOther,
        '\nhasKnownCores:',
        BasServerStorage.hasKnownCores(),
        '\nshouldWaitForDiscovery:',
        _shouldWaitForDiscovery,
        '\nshouldRetrieveLiveProjects:',
        _shouldRetrieveLiveProjects
      )

      if (result.basConnect) {

        if (result.basConnect.basServer) {

          // Reachable BasServer

          if (_preferred) {

            if (_preferredCid && result.basConnect.options) {

              _connectCid = result.basConnect.options.server.cid

              _onConnectMatchesCid =
                _preferredCid === _connectCid
            }

            if (result.basConnect.options === _preferred ||
              _onConnectMatchesCid) {

              // Preferred server is connected

              _result.basServer =
                result.basConnect.takeBasServer()
              _result.basConnectRetry = result

              _finishConnectStats()

              BasUtil.removeFromArray(_connections, result)
              _cleanup()

              _cb(null)

            } else {

              _checkAllConnections()
            }

          } else {

            _checkAllConnections()
          }

        } else {

          _checkAllConnections()
        }

      } else {

        _checkAllConnections()
      }
    }

    function _checkAllConnections () {

      var basConnectRetry

      if (_hasConnectionPreferences) {

        basConnectRetry = _getFinishedReachableConnection(true)

        if (basConnectRetry) {

          _result.basServer = basConnectRetry.basConnect.takeBasServer()
          _result.basConnectRetry = basConnectRetry

          _finishConnectStats()

          BasUtil.removeFromArray(_connections, basConnectRetry)
          _cleanup()

          _cb(null)

        } else {

          if (_waitDiscoveryTimeoutId ||
            _retrievingLiveProjects ||
            !_areAllConnectionsFinished()) {

            // Wait for other connection attempts

          } else {

            if (_allowOther) {

              basConnectRetry = _getFinishedReachableConnection()

              if (basConnectRetry) {

                _result.basServer = basConnectRetry.basConnect.takeBasServer()
                _result.basConnectRetry = basConnectRetry

                _finishConnectStats()

                BasUtil.removeFromArray(_connections, basConnectRetry)
                _cleanup()

                _cb(null)

              } else {

                _errorCb(new BasError(
                  BAS_ERRORS.T_TIMEOUT,
                  undefined,
                  'No reachable candidates found'
                ))
              }

            } else {

              _errorCb(
                new BasError(
                  BAS_ERRORS.T_TIMEOUT,
                  undefined,
                  'No reachable candidates found'
                ),
                true
              )
            }
          }
        }

      } else {

        basConnectRetry = _getFinishedReachableConnection()

        if (basConnectRetry) {

          _result.basServer = basConnectRetry.basConnect.takeBasServer()
          _result.basConnectRetry = basConnectRetry

          _finishConnectStats()

          BasUtil.removeFromArray(_connections, basConnectRetry)
          _cleanup()

          _cb(null)

        } else {

          if (_waitDiscoveryTimeoutId ||
            _retrievingLiveProjects ||
            !_areAllConnectionsFinished()) {

            // Wait for other connection attempts

          } else {

            _errorCb(new BasError(
              BAS_ERRORS.T_TIMEOUT,
              undefined,
              'No reachable candidates found'
            ))
          }
        }
      }
    }

    /**
     * @private
     * @param {boolean} [preferred = false]
     * @returns {?TBasConnectRetry}
     */
    function _getFinishedReachableConnection (preferred) {

      var length, i, basConnectRetry, _basServer, _options, host

      if (preferred && !_hasConnectionPreferences) {

        // No preferred criteria
        return null
      }

      length = _connections.length
      for (i = 0; i < length; i++) {

        basConnectRetry = _connections[i]

        // Iterate over finished, reachable connections
        if (
          basConnectRetry &&
          basConnectRetry.finished &&
          basConnectRetry.basConnect &&
          basConnectRetry.basConnect.basServer
        ) {
          if (preferred) {

            _basServer = basConnectRetry.basConnect.basServer
            _options = basConnectRetry.basConnect.options

            if (_preferred) {

              if (_options === _preferred) {

                // Preferred server is connected

                return basConnectRetry
              }
            }

            if (_preferredProjectId) {

              if (_options.server.cid === _preferredProjectId) {

                // Preferred server is connected

                return basConnectRetry
              }
            }

            if (_preferredMac) {

              if (_basServer.hasSameMac(_preferredMac)) {

                // Preferred server is connected

                return basConnectRetry
              }
            }

            if (_preferredAddress) {

              host = _basServer.host

              if (host && host.host === _preferredAddress) {

                // Preferred server is connected

                return basConnectRetry
              }
            }

          } else {

            return basConnectRetry
          }
        }
      }

      return null
    }

    /**
     * @private
     * @param {BasConnectInfo} connectInfo
     * @returns {?TBasConnectRetryOptions}
     */
    function _getStandardRetryOptions (connectInfo) {

      switch (connectInfo.type) {
        case BAS_CONNECT.T_LOCAL:

          return {
            retries: 1,
            totalTimeout: CONN_LOCAL_TIMEOUT_MS
          }

        case BAS_CONNECT.T_REMOTE:

          return {
            retries: 1,
            totalTimeout: CONN_REMOTE_TIMEOUT_MS
          }

        case BAS_CONNECT.T_DEMO:

          return {
            retries: 0,
            totalTimeout: CONN_DEMO_TIMEOUT_MS
          }
      }

      return null
    }

    /**
     * @private
     * @param {BasConnectInfo} basConnectInfo
     * @param {TBasConnectRetryOptions} retryOptions
     * @param {boolean} [isOther = false]
     */
    function _addConnection (
      basConnectInfo,
      retryOptions,
      isOther
    ) {
      var _isOther, id, basConnectRetry

      _isOther = false

      if (BasUtil.isBool(isOther)) _isOther = isOther

      id = basConnectInfo.id

      basConnectRetry = _getConnectionWithId(id)

      if (basConnectRetry) {

        // Connection attempt already exists for these options

      } else {

        _connectStats.numberOfCandidates++

        if (
          _otherTimeout &&
          _isOther &&
          !BasUtil.isNumber(retryOptions.initialTimeout)
        ) {
          retryOptions.initialTimeout = _otherTimeout
        }

        if (_noLogin) {

          if (!basConnectInfo.options) basConnectInfo.options = {}
          basConnectInfo.options.noLogin = _noLogin
        }

        _connections.push(BasConnect.connectRetry(
          basConnectInfo,
          retryOptions,
          _onConnect
        ))
      }

      Logger.log(
        tag + ' - addConnection',
        '\nid:',
        id,
        '\noptions:',
        basConnectInfo,
        '\nbasConnectRetry:',
        basConnectRetry,
        '\nAdded connection attempt:',
        !!basConnectRetry,
        '\nNumber of candidates:',
        _connectStats.numberOfCandidates,
        '\nAll connections:',
        _connections
      )
    }

    /**
     * @private
     * @param {string} id
     * @returns {?TBasConnectRetry}
     */
    function _getConnectionWithId (id) {

      var length, i, basConnectRetry

      if (id) {

        length = _connections.length
        for (i = 0; i < length; i++) {

          basConnectRetry = _connections[i]

          if (
            basConnectRetry &&
            basConnectRetry.basConnect &&
            basConnectRetry.basConnect.options &&
            basConnectRetry.basConnect.options.id === id
          ) {
            return basConnectRetry
          }
        }
      }

      return null
    }

    /**
     * @private
     * @returns {BasError[]}
     */
    function _getAllFinishedConnectionErrors () {

      var result, length, i, basConnectRetry

      result = []

      length = _connections.length
      for (i = 0; i < length; i++) {

        basConnectRetry = _connections[i]

        if (
          basConnectRetry &&
          basConnectRetry.finished === true &&
          basConnectRetry.error
        ) {
          result.push(basConnectRetry.error)
        }
      }

      return result
    }

    /**
     * Will return true if there are ongoing connections
     *
     * @private
     * @returns {boolean}
     */
    function _areAllConnectionsFinished () {

      var length, i, basConnectRetry

      length = _connections.length
      for (i = 0; i < length; i++) {

        basConnectRetry = _connections[i]

        if (basConnectRetry && basConnectRetry.finished !== true) {

          return false
        }
      }

      return true
    }

    function _finishConnectStats () {

      _connectStats.totalTime = Date.now() - _tConnectStart

      if (_result.basServer) {

        if (_result.basServer.basConnectStats) {

          _result.basServer.basConnectStats.totalTime =
            _connectStats.totalTime
          _result.basServer.basConnectStats.numberOfCandidates =
            _connectStats.numberOfCandidates

        } else {

          _result.basServer.basConnectStats = _connectStats
        }
      }
    }

    /**
     * Wait for BAS_DISCOVERY.EVT_DISCOVERED_CORES_UPDATED events during
     *  _WAIT_FOR_DISCOVERY_MS time. The callback will be triggered for each
     *  event.
     *
     * @private
     * @param {CBasOnDiscoveryEventCallback} discoveryCallback
     * @param {CBasOnDiscoveryTimeoutCallback} timeoutCallback
     */
    function _waitForDiscoveredCoresUpdated (
      discoveryCallback,
      timeoutCallback
    ) {
      _clearDiscoveryListener()
      _clearWaitForDiscoveryTimeout()

      _waitDiscoveryTimeoutId = setTimeout(
        onTimeout,
        _WAIT_FOR_DISCOVERY_MS
      )

      _discoveryEventListener = $rootScope.$on(
        BAS_DISCOVERY.EVT_DISCOVERED_CORES_UPDATED,
        discoveryCallback
      )

      function onTimeout () {

        _clearWaitForDiscoveryTimeout()
        _clearDiscoveryListener()

        BasUtil.exec(timeoutCallback)
      }
    }

    function _clearWaitForDiscoveryTimeout () {

      clearTimeout(_waitDiscoveryTimeoutId)
      _waitDiscoveryTimeoutId = 0
    }

    function _clearDiscoveryListener () {

      BasUtil.exec(_discoveryEventListener)
      _discoveryEventListener = null
    }

    function _clearWaitForListLiveProjectsTimeout () {

      clearTimeout(_waitListLiveProjectsTimeoutId)
      _waitListLiveProjectsTimeoutId = 0
    }

    function _destroyAllConnections () {

      var length, i, basConnect

      length = _connections.length
      for (i = 0; i < length; i++) {

        basConnect = _connections[i]
        _connections[i] = null

        if (basConnect) {

          basConnect.abort()
          basConnect.destroy()
        }
      }

      _connections = []
    }

    /**
     * When this function is called, future callbacks will be ignored
     * because of the _willResolve variable.
     *
     * @private
     * @param {boolean} [cleanupBasServer = false]
     */
    function _cleanup (cleanupBasServer) {

      var _checkForCoreClientServerToClear

      _willResolve = true

      _clearWaitForDiscoveryTimeout()
      _clearDiscoveryListener()
      _clearWaitForListLiveProjectsTimeout()

      if (_checkForCoreClientServer) {

        // Capture instance we want cleanup
        _checkForCoreClientServerToClear = _checkForCoreClientServer
        // Remove "global" reference for callback check
        _checkForCoreClientServer = null

        _checkForCoreClientServerToClear.abort()
        // Extra cleanup step in case of previous abort or callback
        _checkForCoreClientServerToClear.cleanup()
        _checkForCoreClientServerToClear = null
      }

      _destroyAllConnections()

      if (cleanupBasServer) {

        if (_result.basServer) _result.basServer.destroy()
        _result.basServer = null
      }
    }

    /**
     * @returns {?BasServer}
     */
    function takeBasServer () {

      var basServer

      basServer = _result.basServer
      _result.basServer = null

      return basServer
    }

    function abort () {

      _aborted = true

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_ABORT,
        undefined,
        BAS_ERRORS.M_ABORTED
      ))
    }

    /**
     * @private
     * @param {BasError} error
     * @param {boolean} [sendSpecificError = false]
     */
    function _errorCb (error, sendSpecificError) {

      var _sendSpecificError, errors, _error

      _sendSpecificError = BasUtil.isBool(sendSpecificError)
        ? sendSpecificError
        : false

      if (_sendSpecificError) {

        errors = _getAllFinishedConnectionErrors()
        _error = errors.length === 1 ? errors[0] : error

      } else {

        _error = error
      }

      _cleanup()
      _cb(_error)
    }

    /**
     * @private
     * @param {?BasError} error
     */
    function _cb (error) {

      if (!_cbCalled) {

        _cbCalled = true

        _result.finished = true

        if (BasUtil.isFunction(callback)) {

          Logger.log(
            tag + ' CB',
            '\nerror:',
            error,
            '\nresult:',
            _result
          )

          callback(error, _result)
        }
      }
    }
  }
}
