'use strict'

angular
  .module('basalteApp')
  .directive('basGenericListElement', [
    'BAS_HTML',
    basGenericListElementDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basGenericListElement
 */
function basGenericListElementDirective (
  BAS_HTML
) {
  return {
    restrict: 'EA',
    scope: {
      typeStr: '<',
      name: '<',
      subtitle: '<',
      percentage: '<',
      on: '<',
      disabled: '<',
      css: '<',
      value: '<',
      clickHandler: '&'
    },
    template: BAS_HTML.lisaListElement
  }
}
