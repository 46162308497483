'use strict'

angular
  .module('basalteApp')
  .controller('roomScenesCtrl', [
    'BAS_ROOMS',
    'CurrentRoom',
    roomScenesCtrl
  ])

/**
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 */
function roomScenesCtrl (
  BAS_ROOMS,
  CurrentRoom
) {
  var roomScenes = this

  /**
   * @type {BasRooms}
   */
  roomScenes.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  roomScenes.currentRoom = CurrentRoom.get()
}
