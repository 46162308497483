'use strict'

var BasCoreSocket = require('./bas_core_socket')

/**
 * Demo socket
 *
 * Use #onIncomingMessage to send messages from the demo server to the client.
 * Override handleServerSendInData to received message from the client.
 *
 * @constructor
 * @extends BasCoreSocket
 * @since 3.0.0
 */
function BasCoreSocketDemo () {

  BasCoreSocket.call(this)

  /**
   * @private
   * @type {boolean}
   */
  this._isConnected = false

  /**
   * @type {Function}
   */
  this.handleServerSendOutData = this.onIncomingMessage.bind(this)
}

BasCoreSocketDemo.prototype = Object.create(BasCoreSocket.prototype)
BasCoreSocketDemo.prototype.constructor = BasCoreSocketDemo

/**
 * Override this method with the "server" incoming messages handler
 *
 * @param {Object} data
 */
// eslint-disable-next-line no-unused-vars
BasCoreSocketDemo.prototype.handleServerSendInData = function (data) {

  // Empty
}

/**
 * Check if the socket is connected
 *
 * @returns {boolean}
 */
BasCoreSocketDemo.prototype.isConnected = function () {

  return this._isConnected
}

/**
 * @param {string} path
 * @param {TCoreCredentials} [credentials]
 * @returns {Promise}
 */
BasCoreSocketDemo.prototype.openConnection = function (
  // eslint-disable-next-line no-unused-vars
  path,
  // eslint-disable-next-line no-unused-vars
  credentials
) {

  this._isConnected = true

  this._emitConnected(this._isConnected)

  return Promise.resolve()
}

/**
 * @returns {Promise}
 */
BasCoreSocketDemo.prototype.closeConnection = function () {

  if (this._isConnected) {

    this._isConnected = false

    this._emitConnected(this._isConnected)
  }

  return Promise.resolve()
}

/**
 * Returns whether or not the data was sent.
 *
 * @param {Object} data
 * @returns {boolean}
 */
BasCoreSocketDemo.prototype.sendData = function (data) {

  if (this._isConnected) {

    this.handleServerSendInData(data)

    return true
  }

  return false
}

module.exports = BasCoreSocketDemo
