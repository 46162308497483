'use strict'

angular
  .module('basLogger', [])
  .provider('Logger', LoggerProvider)

function LoggerProvider () {

  var NONE = 0
  var ERROR = 1
  var WARN = 2
  var INFO = 3
  var LOG = 4
  var DEBUG = 5

  var logLevel = NONE

  var useTimestamp = false

  var useCustomTag = false
  var customTag = ''

  var useTypeTag = false

  var useStyle = false
  var style = ''

  // Log Level

  function getLevel () {
    return logLevel
  }

  function setLevel (newLevel) {
    if (newLevel === NONE ||
      newLevel === ERROR ||
      newLevel === WARN ||
      newLevel === INFO ||
      newLevel === LOG ||
      newLevel === DEBUG) {
      logLevel = newLevel
    }
  }

  // Timestamp

  function enableTimestamp (enable) {
    if (typeof enable === 'boolean') {
      useTimestamp = enable
    }
  }

  // Custom Tag

  function getCustomTag () {
    return customTag
  }

  function setCustomTag (newTag) {
    if (typeof newTag === 'string' && newTag.length) {
      customTag = newTag
      useCustomTag = true
    } else {
      useCustomTag = false
      customTag = ''
    }
  }

  // Type Tag

  function enableTypeTag (enable) {
    if (typeof enable === 'boolean') {
      useTypeTag = enable
    }
  }

  // Styling

  function getStyle () {
    return style
  }

  function setStyle (newStyle) {
    if (typeof newStyle === 'string' && newStyle.length) {
      style = newStyle
      useStyle = true
    } else {
      useStyle = false
      style = ''
    }
  }

  /**
   * Logger service for handling logging in the Angular way
   *
   * @constructor
   */
  function Logger () {

    this.getLevel = getLevel
    this.setLevel = setLevel
    this.enableTimestamp = enableTimestamp
    this.getCustomTag = getCustomTag
    this.setCustomTag = setCustomTag
    this.enableTypeTag = enableTypeTag
    this.getStyle = getStyle
    this.setStyle = setStyle

    this.NONE = NONE
    this.ERROR = ERROR
    this.WARN = WARN
    this.INFO = INFO
    this.LOG = LOG
    this.DEBUG = DEBUG

    this.error = error
    this.warn = warn
    this.info = info
    this.log = log
    this.debug = debug

    function processConsoleMessage (givenArgs, type) {
      var args = []
      var extras = ''

      // Style
      if (useStyle) {
        extras += '%c'
      }

      // Timestamp
      if (useTimestamp) {
        extras += new Date().toLocaleTimeString()
      }

      // Custom tag
      if (useCustomTag) {
        extras += ' [' + customTag + ']'
      }

      // Type tag
      if (useTypeTag) {
        extras += ' [' + type + ']'
      }

      // Create new arguments array
      args.push(extras)

      // Style
      if (useStyle) {
        args.push(style)
      }

      args.push.apply(args, givenArgs)

      return args
    }

    function error () {
      if (logLevel >= ERROR) {
        // eslint-disable-next-line no-console
        console.error.apply(
          console,
          processConsoleMessage(arguments, 'ERROR')
        )
      }
    }

    function warn () {
      if (logLevel >= WARN) {
        // eslint-disable-next-line no-console
        console.warn.apply(
          console,
          processConsoleMessage(arguments, 'WARN')
        )
      }
    }

    function info () {
      if (logLevel >= INFO) {
        // eslint-disable-next-line no-console
        console.info.apply(
          console,
          processConsoleMessage(arguments, 'INFO')
        )
      }
    }

    function log () {
      if (logLevel >= LOG) {
        // eslint-disable-next-line no-console
        console.log.apply(
          console,
          processConsoleMessage(arguments, 'LOG')
        )
      }
    }

    function debug () {
      if (logLevel >= DEBUG) {
        // eslint-disable-next-line no-console
        console.debug.apply(
          console,
          processConsoleMessage(arguments, 'DEBUG')
        )
      }
    }
  }

  // Logger Factory

  function LoggerFactory () {
    return new Logger()
  }

  // Provider PUBLIC

  this.NONE = NONE
  this.ERROR = ERROR
  this.WARN = WARN
  this.INFO = INFO
  this.LOG = LOG
  this.DEBUG = DEBUG

  this.getLevel = getLevel
  this.setLevel = setLevel
  this.enableTimestamp = enableTimestamp
  // noinspection JSUnusedGlobalSymbols
  this.getCustomTag = getCustomTag
  this.setCustomTag = setCustomTag
  this.enableTypeTag = enableTypeTag
  // noinspection JSUnusedGlobalSymbols
  this.getStyle = getStyle
  this.setStyle = setStyle

  this.$get = LoggerFactory
}
