'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var BasTrack = require('./bas_track')

/**
 * @typedef {Object} TBarpMessage
 * @property {Object} barp
 * @property {string} barp.uuid
 * @property {number} barp.id
 */

/**
 * Class representing a Basalte In-Home-Streaming object
 *
 * @constructor
 * @extends EventEmitter
 * @param {Object} config
 * @param {number} config.id CobraNET bundle number
 * @param {string} config.uuid
 * @param {string} config.subType
 * @param {string} config.name Name given by the basCore
 * @param {Object} [config.status] Current status of player
 * @param {boolean} config.status.connected If Barp is connected to client
 * @param {string} [config.status.clientName] Name of Spotify Connect client
 * @param {boolean} config.status.paused If Barp is paused or not
 * @param {number} [config.status.total] Barp song total in seconds
 * @param {number} [config.status.elapsed] Barp elapsed in seconds
 * @param {?Object} [config.status.song] Current song
 * @param {string} config.status.song.title Current song title
 * @param {string} config.status.song.artist Current song artist
 * @param {string} config.status.song.album Current song album
 * @param {string} config.status.song.coverart Current song coverart
 * @param {string} [config.status.song.context] Current song context name
 * @param {string} [config.status.song.contextUri] Current song context URI
 * @param {?Object} [config.status.next] Next song
 * @param {string} config.status.next.title Next song title
 * @param {string} config.status.next.artist Next song artist
 * @param {string} config.status.next.album Next song album
 * @param {string} config.status.next.coverart Next song coverart
 * @param {string} config.status.next.context Next song context name
 * @param {string} config.status.next.contextUri Next song context URI
 * @param {string} [config.status.coverart] Coverart url for current song
 * @param {BasCore} basCore
 */
function Barp (config, basCore) {

  EventEmitter.call(this)

  this._basCore = basCore

  this._id = config[P.ID]
  this._uuid = config[P.UUID]
  this._name = config[P.NAME]
  this._type = config[P.SUB_TYPE]

  this._connected = false
  this._clientName = ''
  this._paused = true
  this._position = 0

  /**
   * @protected
   * @type {BasTrack}
   */
  this._currentSong = new BasTrack()

  this._dirty = true

  this._handleStatus = this._onStatus.bind(this)

  if (BasUtil.isObject(config[P.STATUS])) {

    this._dirty = false

    this.parseBarp(config[P.STATUS], { emit: false })
  }
}

Barp.prototype = Object.create(EventEmitter.prototype)
Barp.prototype.constructor = Barp

// region Events

/**
 * Connection state has changed
 *
 * @event Barp#EVT_CONNECTED
 * @param {boolean} connected
 */

/**
 * The connected client name has changed
 *
 * @event Barp#EVT_CLIENT_CHANGED
 * @param {string} clientName
 */

/**
 * @event Barp#EVT_PAUSED
 * @param {boolean} paused
 */

/**
 * @event Barp#EVT_DURATION
 * @param {number} length Seconds
 */

/**
 *  @event Barp#EVT_POSITION
 *  @param {number} length Seconds
 */

/**
 * @event Barp#EVT_CURRENT_SONG
 * @param {BasTrack} currentSong
 */

/**
 * @event Barp#EVT_COVER_ART
 * @param {string} coverart Cover art filename
 */

// endregion

/**
 * @constant {string}
 */
Barp.EVT_CONNECTED = 'evtBarpConnected'

/**
 * @constant {string}
 */
Barp.EVT_CLIENT_CHANGED = 'evtBarpClientChanged'

/**
 * @constant {string}
 */
Barp.EVT_PAUSED = 'evtBarpPaused'

/**
 * @constant {string}
 */
Barp.EVT_DURATION = 'evtBarpDuration'

/**
 * @constant {string}
 */
Barp.EVT_POSITION = 'evtBarpPosition'

/**
 * @constant {string}
 */
Barp.EVT_CURRENT_SONG = 'evtBarpCurrentSong'

/**
 * @constant {string}
 */
Barp.EVT_COVER_ART = 'evtBarpCoverArt'

/**
 * @constant {string}
 */
Barp.TYPE_AAP = 'aap'

/**
 * @constant {string}
 */
Barp.TYPE_SPOTIFY = 'spotify'

// region Properties

/**
 * Checks if the given object is a Spotify Barp
 *
 * @param {Barp} barp
 * @returns {boolean}
 */
Barp.checkSpotifyBarp = function checkSpotifyBarp (barp) {
  return (
    BasUtil.isObject(barp) &&
    barp.type === Barp.TYPE_SPOTIFY
  )
}

/**
 * CobraNET bundle identifier
 *
 * @name Barp#id
 * @type {number}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'id', {
  get: function () {
    return this._id
  }
})

/**
 * @name Barp#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name Barp#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * @name Barp#type
 * @type {string}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'type', {
  get: function () {
    return this._type
  }
})

/**
 * The client device if any of the current Barp.
 *
 * @name Barp#clientName
 * @type {string}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'clientName', {
  get: function () {
    return this._clientName
  }
})

/**
 * @name Barp#paused
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'paused', {
  get: function () {
    return this._paused
  }
})

/**
 * @name Barp#title
 * @deprecated
 * @type {string}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'title', {
  get: function () {

    if (BasUtil.isNEString(this._currentSong.title)) {

      return this._currentSong.title
    }

    // LEGACY
    return undefined
  }
})

/**
 * @name Barp#artist
 * @deprecated
 * @type {string}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'artist', {
  get: function () {

    if (BasUtil.isNEString(this._currentSong.artist)) {

      return this._currentSong.artist
    }

    // LEGACY
    return undefined
  }
})

/**
 * @name Barp#album
 * @deprecated
 * @type {string}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'album', {
  get: function () {

    if (BasUtil.isNEString(this._currentSong.album)) {

      return this._currentSong.album
    }

    // LEGACY
    return undefined
  }
})

/**
 * @name Barp#coverArt
 * @deprecated
 * @type {string}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'coverArt', {
  get: function () {

    if (BasUtil.isNEString(this._currentSong[BasTrack.K_COVERART_URL])) {

      return this._currentSong[BasTrack.K_COVERART_URL]
    }

    // LEGACY
    return undefined
  }
})

/**
 * @name Barp#thumbnail
 * @type {string}
 * @readonly
 * @since 1.1.0
 */
Object.defineProperty(Barp.prototype, 'thumbnail', {
  get: function () {

    if (BasUtil.isNEString(this._currentSong[BasTrack.K_THUMBNAIL_URL])) {

      return this._currentSong[BasTrack.K_THUMBNAIL_URL]
    }

    // LEGACY
    return undefined
  }
})

/**
 * Will be removed when APP is compatible with just the Asano Track object
 *
 * @name Barp#duration
 * @deprecated
 * @type {number}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'duration', {
  get: function () {
    return this._currentSong.length
  }
})

/**
 * @name Barp#position
 * @type {number}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'position', {
  get: function () {
    return this._position
  }
})

/**
 * @name Barp#currentSong
 * @type {BasTrack}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'currentSong', {
  get: function () {
    return this._currentSong
  }
})

/**
 * @name Barp#connected
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Barp.prototype, 'connected', {
  get: function () {
    return this._connected
  }
})

/**
 * @name Barp#dirty
 * @type {boolean}
 * @readonly
 * @since 3.7.0
 */
Object.defineProperty(Barp.prototype, 'dirty', {
  get: function () {
    return this._dirty
  }
})

// endregion

// region Instance Methods

/**
 * @typedef {Object} BarpParseOptions
 * @property {boolean} [emit = true] Emit events on changes
 * @property {boolean} [parseSongs = true]
 */

/**
 * @param {Object} data
 * @param {BarpParseOptions} [options]
 */
Barp.prototype.parse = function parse (data, options) {

  if (BasUtil.isObject(data[P.BARP]) &&
    data[P.BARP][P.ID] === this._id &&
    data[P.BARP][P.UUID] === this._uuid) {

    this.parseBarp(data[P.BARP], options)
  }
}

/**
 * Parse a Barp (status) object
 *
 * @param {Object} obj
 * @param {BarpParseOptions} [options]
 * @since 1.9.0
 */
Barp.prototype.parseBarp = function parseStatus (
  obj,
  options
) {
  var emit, parseSongs
  var prevSong, prevCoverart, prevLength
  var hasNewCoverart, hasNewLength
  var clearCoverArt

  emit = true
  parseSongs = true
  clearCoverArt = false

  if (options) {
    if (options.emit === false) emit = false
    if (options.parseSongs === false) parseSongs = false
  }

  prevSong = this._currentSong.hash()
  prevCoverart = this._currentSong.coverart
  prevLength = this._currentSong.length

  hasNewCoverart = (
    BasUtil.isObject(obj[P.SONG]) &&
    P.COVERART in obj[P.SONG]
  )

  hasNewLength = (
    BasUtil.isObject(obj[P.SONG]) &&
    BasUtil.isPNumber(obj[P.SONG][P.LENGTH], true)
  )

  // Connected

  if (BasUtil.isBool(obj[P.CONNECTED])) {

    this._dirty = false

    if (obj[P.CONNECTED] === false) {

      this.clearInfo()
    }

    // Check for changes
    if (this._connected !== obj[P.CONNECTED]) {

      this._connected = obj[P.CONNECTED]
      if (emit) this.emit(Barp.EVT_CONNECTED, this._connected)

      this._basCore.notifyZones(this._id)
    }
  }

  // Client name

  if (BasUtil.isString(obj[P.CLIENT_NAME]) &&
    this._clientName !== obj[P.CLIENT_NAME]) {

    this._clientName = obj[P.CLIENT_NAME]
    if (emit) this.emit(Barp.EVT_CLIENT_CHANGED, this._clientName)
  }

  // Paused

  if (BasUtil.isBool(obj[P.PAUSED]) &&
    this._paused !== obj[P.PAUSED]) {

    this._paused = obj[P.PAUSED]
    if (emit) this.emit(Barp.EVT_PAUSED, this._paused)
  }

  // Position

  if (BasUtil.isPNumber(obj[P.ELAPSED], true) &&
    this._position !== obj[P.ELAPSED]) {

    this._position = obj[P.ELAPSED]
    if (emit) this.emit(Barp.EVT_POSITION, this._position)
  }

  // LEGACY coverart

  if (!hasNewCoverart &&
    P.COVERART in obj) {

    this._currentSong.setCoverart(
      BasUtil.isNEString(obj[P.COVERART])
        ? obj[P.COVERART]
        : ''
    )

    this._basCore.modifySongCoverArt(
      this._currentSong,
      {
        copy: this._isSpotifyBarp()
      }
    )
  }

  // LEGACY length

  if (!hasNewLength &&
    BasUtil.isPNumber(obj[P.TOTAL], true)) {

    this._currentSong.setLength(obj[P.TOTAL])

    if (prevLength !== this._currentSong.length) {

      if (emit) this.emit(Barp.EVT_DURATION, this._currentSong.length)
    }
  }

  // Current song

  if (parseSongs && P.SONG in obj) {

    this._currentSong.parse(
      obj[P.SONG],
      {
        onlyChanges: true
      }
    )

    // Hack fix for handling missing "coverart" key
    // on message for clearing cover art
    if (!(P.COVERART in obj) &&
      !(P.COVERART in obj[P.SONG]) &&
      obj[P.IMAGES]) {

      if (obj[P.IMAGES][P.COVER] === null ||
        obj[P.IMAGES][P.THUMB] === null) {

        this._currentSong.setCoverart('')
        clearCoverArt = true
      }
    }

    this._basCore.modifySongCoverArt(
      this._currentSong,
      {
        copy: this._isSpotifyBarp(),
        clear: clearCoverArt
      }
    )

    if (hasNewLength &&
      prevLength !== this._currentSong.length) {

      if (emit) this.emit(Barp.EVT_DURATION, this._currentSong.length)
    }

    if (prevSong !== this._currentSong.hash()) {

      if (emit) this.emit(Barp.EVT_CURRENT_SONG, this._currentSong)
    }
  }

  if (prevCoverart !== this._currentSong.coverart) {

    if (emit) this.emit(Barp.EVT_COVER_ART, this._currentSong.coverart)
  }
}

/**
 * Clear Barp state information
 */
Barp.prototype.clearInfo = function clearInfo () {

  this._paused = true
  this._position = 0

  this._currentSong.resetAll()
}

/**
 * Should result in a message that contains Barp status
 *
 * Only use with basCore 2.0.0 >=
 *
 * @param {boolean} [request=true] Return a Promise for the status request
 * @since 1.7.3
 * @returns {(Promise|undefined)}
 */
Barp.prototype.status = function status (request) {

  var obj

  obj = this._getBasCoreMessage()
  obj[P.BARP][P.ACTION] = P.STATUS

  if (request === false) {

    this._basCore.send(obj)

  } else {

    return this._basCore.requestRetry(obj).then(this._handleStatus)
  }
}

/**
 * @private
 * @param {Object} result
 * @returns {(Barp|Promise)}
 */
Barp.prototype._onStatus = function (result) {

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.BARP]) &&
    result[P.BARP][P.ID] === this._id &&
    result[P.BARP][P.UUID] === this._uuid) {

    this.parseBarp(result[P.BARP])

    return this
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * Send a state to Barp
 *
 * @param {string} state
 */
Barp.prototype.sendState = function sendState (state) {

  var obj

  obj = this._getBasCoreMessage()
  obj[P.BARP][P.STATE] = state

  this._basCore.send(obj)
}

/**
 * Next command
 */
Barp.prototype.next = function next () {
  this.sendState(P.NEXT)
}

/**
 * Previous command
 */
Barp.prototype.previous = function previous () {
  this.sendState(P.PREVIOUS)
}

/**
 * Toggle play/pause. This will trigger an {@link Barp#EVT_PAUSED} event.
 *
 * @param {boolean} [override] true = play, false = pause
 * @since 2.1.0
 */
Barp.prototype.togglePlayPause = function (override) {

  if (BasUtil.isBool(override)) {

    this.sendState(override ? P.PLAY : P.PAUSE)

  } else {

    this.sendState(P.PLAYPAUSE)
  }
}

/**
 * Stop
 */
Barp.prototype.stop = function stop () {
  this.sendState(P.STOP)
}

/**
 * Checks if this Barp is a Spotify Barp
 *
 * @private
 * @returns {boolean}
 */
Barp.prototype._isSpotifyBarp = function () {
  return Array.isArray(this._presets)
}

/**
 * Creates a template message for the Barp
 *
 * @protected
 * @returns {TBarpMessage}
 */
Barp.prototype._getBasCoreMessage = function () {

  var msg = {}
  msg[P.BARP] = {}
  msg[P.BARP][P.UUID] = this._uuid
  msg[P.BARP][P.ID] = this._id

  return msg
}

// endregion

/**
 * @since 2.0.0
 */
Barp.prototype.destroy = function () {

  this.removeAllListeners()
}

module.exports = Barp
