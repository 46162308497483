'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSceneSchedule', [
    'BAS_INTL',
    'BAS_API',
    'BasIntl',
    'CurrentBasCore',
    'BasUtilities',
    basSceneScheduleFactory
  ])

/**
 * @param {BAS_INTL} BAS_INTL
 * @param BAS_API
 * @param {BasIntl} BasIntl
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasUtilities} BasUtilities
 * @returns BasSceneSchedule
 */
function basSceneScheduleFactory (
  BAS_INTL,
  BAS_API,
  BasIntl,
  CurrentBasCore,
  BasUtilities
) {
  /**
   * @constructor
   * @param {string} uuid
   * @param {BasRoom} basRoom
   */
  function BasSceneSchedule (uuid, basRoom) {

    /**
     * @type {string}
     */
    this.uuid = BasUtil.isNEString(uuid) ? uuid : ''

    /**
     * @type {boolean}
     */
    this.enabled = false

    /**
     * @type {string}
     */
    this.name = BasUtilities.translate('new_schedule')

    /**
     * @type {string}
     */
    this.scene = ''

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * @type {number}
     */
    this.offset = 0

    /**
     * @type {boolean}
     */
    this.repeat = false

    /**
     * @type {Object<string, boolean>}
     */
    this.days = {}
    this._resetDays()

    /**
     * @type {Date}
     */
    this.date = new Date()

    this.date.setSeconds(0)
    this.date.setMilliseconds(0)

    /**
     * @type {string}
     */
    this.uiDateSummary = '-'

    /**
     * @type {string}
     */
    this.uiDateSummaryShort = '-'

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    /**
     * @type {string[]}
     */
    this.types = [
      BasSceneSchedule.T_SUNRISE,
      BasSceneSchedule.T_SUNSET,
      BasSceneSchedule.T_TIME
    ]

    this.css = {}
    this.resetCss()

    this.sync()
    this.updateDateSummary()
  }

  /**
   * @constant {string}
   */
  BasSceneSchedule.T_SUNSET = 'sunset'

  /**
   * @constant {string}
   */
  BasSceneSchedule.T_SUNRISE = 'sunrise'

  /**
   * @constant {string}
   */
  BasSceneSchedule.T_TIME = 'time'

  /**
   * @constant {string}
   */
  BasSceneSchedule.CSS_CAN_TOGGLE = 'bas-scheduler-can-toggle'

  /**
   * @constant {string}
   */
  BasSceneSchedule.CSS_CAN_REMOVE = 'bas-scheduler-can-remove'

  /**
   * @constant {string}
   */
  BasSceneSchedule.CSS_CAN_SCENE = 'bas-scheduler-can-scene'

  /**
   * @constant {string}
   */
  BasSceneSchedule.CSS_CAN_TIME = 'bas-scheduler-can-time'

  /**
   * @constant {string}
   */
  BasSceneSchedule.CSS_SHOW_TIMER = 'bas-scheduler-show-timer'

  /**
   * @constant {string}
   */
  BasSceneSchedule.CSS_SHOW_SUNRISE = 'bas-scheduler-show-sunrise'

  /**
   * @constant {string}
   */
  BasSceneSchedule.CSS_SHOW_SUNSET = 'bas-scheduler-show-sunset'

  /**
   * Sync with API Job
   *
   * @param {Job} [job]
   */
  BasSceneSchedule.prototype.sync = function (job) {

    var jobObj = BasUtil.isObject(job) ? job : this.getJob()

    if (jobObj) {

      this.resetCss()

      if (jobObj.allowsRead(BAS_API.Job.C_ENABLE)) {

        this.enabled = jobObj.enabled

        if (jobObj.allowsWrite(BAS_API.Job.C_ENABLE)) {

          this.css[BasSceneSchedule.CSS_CAN_TOGGLE] = true
        }
      }

      if (jobObj.allowsExecute(BAS_API.Job.C_REMOVE)) {

        this.css[BasSceneSchedule.CSS_CAN_REMOVE] = true
      }

      if (jobObj.allowsRead(BAS_API.Job.C_SCENE)) {

        this.scene = jobObj.scene
        this.syncName()

        if (jobObj.allowsWrite(BAS_API.Job.C_SCENE)) {

          this.css[BasSceneSchedule.CSS_CAN_SCENE] = true
        }
      }

      if (jobObj.allowsRead(BAS_API.Job.C_TIME)) {

        switch (jobObj.type) {

          case BAS_API.Job.T_REPEATED:

            this.date.setHours(jobObj.hour)
            this.date.setMinutes(jobObj.minutes)
            this.type = BasSceneSchedule.T_TIME
            this.repeat = true
            this.css[BasSceneSchedule.CSS_SHOW_TIMER] = true
            this.css[BasSceneSchedule.CSS_SHOW_SUNRISE] = false
            this.css[BasSceneSchedule.CSS_SHOW_SUNSET] = false

            break
          case BAS_API.Job.T_DATETIME:

            this.date.setTime(jobObj.getDatetime())
            this.type = BasSceneSchedule.T_TIME
            this.repeat = false
            this.css[BasSceneSchedule.CSS_SHOW_TIMER] = true
            this.css[BasSceneSchedule.CSS_SHOW_SUNRISE] = false
            this.css[BasSceneSchedule.CSS_SHOW_SUNSET] = false

            break
          case BAS_API.Job.T_SUNRISE:

            this.type = BasSceneSchedule.T_SUNRISE
            this.offset = jobObj.offset
            this.repeat = true
            this.css[BasSceneSchedule.CSS_SHOW_TIMER] = false
            this.css[BasSceneSchedule.CSS_SHOW_SUNRISE] = true
            this.css[BasSceneSchedule.CSS_SHOW_SUNSET] = false

            break
          case BAS_API.Job.T_SUNSET:

            this.type = BasSceneSchedule.T_SUNSET
            this.offset = jobObj.offset
            this.repeat = true
            this.css[BasSceneSchedule.CSS_SHOW_TIMER] = false
            this.css[BasSceneSchedule.CSS_SHOW_SUNRISE] = false
            this.css[BasSceneSchedule.CSS_SHOW_SUNSET] = true

            break
        }

        this.days[BAS_INTL.DAYS.SUNDAY] = jobObj.sunday
        this.days[BAS_INTL.DAYS.MONDAY] = jobObj.monday
        this.days[BAS_INTL.DAYS.TUESDAY] = jobObj.tuesday
        this.days[BAS_INTL.DAYS.WEDNESDAY] = jobObj.wednesday
        this.days[BAS_INTL.DAYS.THURSDAY] = jobObj.thursday
        this.days[BAS_INTL.DAYS.FRIDAY] = jobObj.friday
        this.days[BAS_INTL.DAYS.SATURDAY] = jobObj.saturday

        if (jobObj.allowsWrite(BAS_API.Job.C_TIME)) {

          this.css[BasSceneSchedule.CSS_CAN_TIME] = true
        }
      }

      this.updateDateSummary()
    }
  }

  /**
   * @returns {?Job}
   */
  BasSceneSchedule.prototype.getJob = function () {

    var sceneCtrl

    if (
      this._basRoom &&
      this._basRoom.room &&
      this._basRoom.room.sceneCtrl
    ) {
      sceneCtrl = CurrentBasCore.getDevice(this._basRoom.room.sceneCtrl)

      if (sceneCtrl) return sceneCtrl.getJob(this.uuid)
    }

    return null
  }

  /**
   * @returns {number}
   */
  BasSceneSchedule.prototype.getOrder = function () {
    var job = this.getJob()
    return job ? job.order : 0
  }

  BasSceneSchedule.prototype.toggle = function (force) {

    this.enabled = typeof force === 'boolean'
      ? force
      : !this.enabled
  }

  BasSceneSchedule.prototype.toggleRepeat = function (force) {

    this.repeat = typeof force === 'boolean'
      ? force
      : !this.repeat

    this.updateDateSummary()
  }

  /**
   * @param {number} hours
   * @param {number} minutes
   */
  BasSceneSchedule.prototype.setHoursMinutes = function (
    hours,
    minutes
  ) {
    this.date.setHours(hours)
    this.date.setMinutes(minutes)

    this.updateDateSummary()
  }

  /**
   * @param {number} time
   */
  BasSceneSchedule.prototype.setTime = function (time) {

    this.date.setTime(time)

    this.updateDateSummary()
  }

  /**
   * @param {string} type
   */
  BasSceneSchedule.prototype.setType = function (type) {

    if (type === BasSceneSchedule.T_SUNRISE ||
      type === BasSceneSchedule.T_SUNSET ||
      type === BasSceneSchedule.T_TIME) {

      this.type = type

      this.css[BasSceneSchedule.CSS_SHOW_TIMER] =
        type === BasSceneSchedule.T_TIME
      this.css[BasSceneSchedule.CSS_SHOW_SUNRISE] =
        type === BasSceneSchedule.T_SUNRISE
      this.css[BasSceneSchedule.CSS_SHOW_SUNSET] =
        type === BasSceneSchedule.T_SUNSET

      if (type !== BasSceneSchedule.T_TIME) this.repeat = true

      this.updateDateSummary()
    }
  }

  /**
   * @param {string} sceneUuid
   */
  BasSceneSchedule.prototype.setScene = function (sceneUuid) {

    this.scene = sceneUuid
    this.syncName()
  }

  BasSceneSchedule.prototype.syncName = function () {

    if (BasUtil.isNEString(this.scene) &&
      this._basRoom &&
      this._basRoom.scenes &&
      this._basRoom.scenes.scenes &&
      this._basRoom.scenes.scenes[this.scene]) {

      this.name = this._basRoom.scenes.scenes[this.scene].name

    } else {

      this.name = '- ' + BasUtilities.translate('no_scene') + ' -'
    }
  }

  /**
   * @param {string} day
   */
  BasSceneSchedule.prototype.toggleDay = function (day) {

    if (day && day in this.days) {

      this.days[day] = !this.days[day]

      this.updateDateSummary()
    }
  }

  /**
   * Write current schedule to underlying Job
   */
  BasSceneSchedule.prototype.commit = function () {

    var job = this.getJob()

    if (job) {

      job.enabled = this.enabled

      job.setType(this._getApiType())

      job.hour = this.date.getHours()
      job.minutes = this.date.getMinutes()

      job.sunday = this.days[BAS_INTL.DAYS.SUNDAY]
      job.monday = this.days[BAS_INTL.DAYS.MONDAY]
      job.tuesday = this.days[BAS_INTL.DAYS.TUESDAY]
      job.wednesday = this.days[BAS_INTL.DAYS.WEDNESDAY]
      job.thursday = this.days[BAS_INTL.DAYS.THURSDAY]
      job.friday = this.days[BAS_INTL.DAYS.FRIDAY]
      job.saturday = this.days[BAS_INTL.DAYS.SATURDAY]

      job.offset = this.offset

      job.setDateTime(this.date.getTime())

      job.scene = this.scene
    }
  }

  BasSceneSchedule.prototype.updateDateSummary = function () {

    var dateString = ''
    var type = ''

    if (this.type === BasSceneSchedule.T_TIME) {

      type = this.repeat
        ? BAS_INTL.LOCALE_OPTION_SHORT
        : BAS_INTL.LOCALE_OPTION_LONG

      // Don't take timezone into account, this date object is correctly
      //  represented in the current system timezone, since it was set using
      //  already timezone adjusted values
      dateString = BasIntl.dateToString(
        this.date,
        type,
        false
      )

      this._onDateString(dateString)

    } else {

      if (this.repeat) {

        this.uiDateSummary = this._getDaysString()
        this.uiDateSummaryShort = this._getDaysStringShort()

        if (!BasUtil.isNEString(this.uiDateSummary)) {
          this.uiDateSummary = '-'
        }

        if (!BasUtil.isNEString(this.uiDateSummaryShort)) {
          this.uiDateSummaryShort = '-'
        }
      }
    }
  }

  BasSceneSchedule.prototype.handleScenesUpdated = function () {

    this.syncName()
  }

  BasSceneSchedule.prototype._onDateString = function (result) {

    if (BasUtil.isNEString(result)) {

      this.uiDateSummary = result
      this.uiDateSummaryShort = result

      if (this.repeat) {

        this.uiDateSummary += ' ' + this._getDaysString()
        this.uiDateSummaryShort += ' ' + this._getDaysStringShort()
      }

    } else {

      this._onDateStringError()
    }
  }

  BasSceneSchedule.prototype._onDateStringError = function () {

    this.uiDateSummary = '-'
    this.uiDateSummaryShort = '-'
  }

  BasSceneSchedule.prototype._getDaysString = function () {

    var i, length, day, result

    result = ''

    length = BAS_INTL.DAYS_WEEK_INTL.length
    for (i = 0; i < length; i++) {

      day = BAS_INTL.DAYS_WEEK_INTL[i]

      if (this.days[day]) {

        result += BAS_INTL.DAYS_WEEK_TRANSLATED[day] + ' '
      }
    }

    return result
  }

  BasSceneSchedule.prototype._getDaysStringShort = function () {

    var i, length, day, result

    result = ''

    length = BAS_INTL.DAYS_WEEK_INTL.length
    for (i = 0; i < length; i++) {

      day = BAS_INTL.DAYS_WEEK_INTL[i]

      if (this.days[day]) {

        result += BAS_INTL.DAYS_WEEK_TRANSLATED_SHORT[day] + ' '
      }
    }

    return result
  }

  /**
   * @private
   * @returns {string}
   */
  BasSceneSchedule.prototype._getApiType = function () {

    switch (this.type) {
      case BasSceneSchedule.T_SUNSET:
        return BAS_API.Job.T_SUNSET
      case BasSceneSchedule.T_SUNRISE:
        return BAS_API.Job.T_SUNRISE
      case BasSceneSchedule.T_TIME:
        return this.repeat
          ? BAS_API.Job.T_REPEATED
          : BAS_API.Job.T_DATETIME
    }
  }

  BasSceneSchedule.prototype._resetDays = function () {

    this.days[BAS_INTL.DAYS.SUNDAY] = false
    this.days[BAS_INTL.DAYS.MONDAY] = false
    this.days[BAS_INTL.DAYS.TUESDAY] = false
    this.days[BAS_INTL.DAYS.WEDNESDAY] = false
    this.days[BAS_INTL.DAYS.THURSDAY] = false
    this.days[BAS_INTL.DAYS.FRIDAY] = false
    this.days[BAS_INTL.DAYS.SATURDAY] = false
  }

  BasSceneSchedule.prototype.resetCss = function () {

    this.css[BasSceneSchedule.CSS_CAN_REMOVE] = false
    this.css[BasSceneSchedule.CSS_CAN_TOGGLE] = false
    this.css[BasSceneSchedule.CSS_CAN_SCENE] = false
    this.css[BasSceneSchedule.CSS_CAN_TIME] = false
    this.css[BasSceneSchedule.CSS_SHOW_TIMER] = false
    this.css[BasSceneSchedule.CSS_SHOW_SUNRISE] = false
    this.css[BasSceneSchedule.CSS_SHOW_SUNSET] = false
  }

  BasSceneSchedule.prototype.updateTranslation = function () {

    this.updateDateSummary()
    this.syncName()
  }

  BasSceneSchedule.prototype.onTimeFormatChanged = function () {

    this.updateDateSummary()
  }

  return BasSceneSchedule
}
