'use strict'

angular
  .module('basIcons', [])
  .constant('ICONS', {
    ellipsis: require('../../icons/ellipsis.svg'),
    ellipsisNoMargin: require('../../icons/ellipsis-no-margin.svg'),
    crossNoMargin: require('../../icons/cross-no-margin.svg'),
    drawer: require('../../icons/drawer.svg'),
    onOff: require('../../icons/on-off.svg'),
    mixed: require('../../icons/mixed.svg'),
    disc: require('../../icons/disc.svg'),
    musicFull: require('../../icons/music-full.svg'),
    bluetooth: require('../../icons/bluetooth_1.svg'),
    barpNoMargin: require('../../icons/barp-no-margin.svg'),
    connectServer: require('../../icons/connect-server-512.svg'),
    deezer: require('../../icons/deezer-black.svg'),
    deezerLogo: require('../../icons/deezer-logo.svg'),
    deezerColor: require('../../icons/deezer-color.svg'),
    spotify: require('../../icons/spotify-icon.svg'),
    spotifyLogo: require('../../icons/spotify-logo.svg'),
    spotifyNoMargin: require('../../icons/spotify-icon-no-margin.svg'),
    spotifyNoMarginColor:
      require('../../icons/spotify-icon-no-margin-color.svg'),
    tidal: require('../../icons/tidal-icon.svg'),
    tidalLogo: require('../../icons/tidal-line-title.svg'),
    externalHifi: require('../../icons/external-hifi.svg'),
    externalTv: require('../../icons/external-tv.svg'),
    home: require('../../icons/home_10.svg'),
    arrowLeft: require('../../icons/arrow_left-56.svg'),
    arrowRight: require('../../icons/arrow_right-56.svg'),
    settingsFull: require('../../icons/settings-full.svg'),
    callLog: require('../../icons/call_log.svg'),
    callStart: require('../../icons/call_start.svg'),
    clearDay: require('../../icons/weather_state_clear-day.svg'),
    clearNight: require('../../icons/weather_state_clear-night.svg'),
    rain: require('../../icons/weather_state_rain.svg'),
    snow: require('../../icons/weather_state_snow.svg'),
    wind: require('../../icons/weather_state_wind.svg'),
    fog: require('../../icons/weather_state_fog.svg'),
    cloudy: require('../../icons/weather_state_cloudy.svg'),
    cloudyDay: require('../../icons/weather_state_partly-cloudy-day.svg'),
    cloudyNight: require('../../icons/weather_state_partly-cloudy-night.svg'),
    frost: require('../../icons/weather_state_frost.svg'),
    profile: require('../../icons/profile.svg'),
    light: require('../../icons/light.svg'),
    screen: require('../../icons/screen.svg'),
    music: require('../../icons/music.svg'),
    viewTogglesGrid: require('../../icons/view_toggles_grid.svg'),
    viewTogglesList: require('../../icons/view_toggles_list.svg'),
    camera: require('../../icons/camera.svg'),
    camera2: require('../../icons/camera-2.svg'),
    calender: require('../../icons/calender.svg'),
    clock: require('../../icons/clock_large.svg'),
    clock2: require('../../icons/clock_large_2.svg'),
    keyhole: require('../../icons/keyhole.svg'),
    timers: require('../../icons/timers_4.svg'),
    energy: require('../../icons/energy.svg'),
    settings: require('../../icons/settings_large.svg'),
    temperature: require('../../icons/temperature.svg'),
    squaresGrid: require('../../icons/squares_grid.svg'),
    queue: require('../../icons/queue.svg'),
    radio: require('../../icons/radio.svg'),
    plus: require('../../icons/plus.svg'),
    minus: require('../../icons/minus.svg'),
    playlist: require('../../icons/playlist.svg'),
    playingEmpty: require('../../icons/playing_empty.svg'),
    playingOutline: require('../../icons/playing_outline.svg'),
    playingPaused: require('../../icons/playing_paused.svg'),
    playingPausedQueue: require('../../icons/playing_paused_queue.svg'),
    playingQueueOutline: require('../../icons/playing_queue_outline.svg'),
    notification: require('../../icons/notification.svg'),
    defaultMusic: require('../../icons/default_cover_art_music.svg'),
    defaultTiles: require('../../icons/default_cover_art_squares.svg'),
    defaultTilesUp: require('../../icons/default_cover_art_squares_up.svg'),
    lock2: require('../../icons/lock_2.svg'),
    windDirectionArrow: require('../../icons/wind_direction_arrow.svg'),
    roomType_attic: require('../../icons/roomtype_attic.svg'),
    roomType_ballroom: require('../../icons/roomtype_ballroom.svg'),
    roomType_bar: require('../../icons/roomtype_bar.svg'),
    roomType_basement: require('../../icons/roomtype_basement.svg'),
    roomType_bathroom: require('../../icons/roomtype_bathroom.svg'),
    roomType_bedroom: require('../../icons/roomtype_bedroom.svg'),
    roomType_custom: require('../../icons/roomtype_custom.svg'),
    roomType_deviceRack: require('../../icons/roomtype_device_rack.svg'),
    roomType_diningRoom: require('../../icons/roomtype_dining_room.svg'),
    roomType_diningRoom2: require('../../icons/roomtype_dining_room_2.svg'),
    roomType_dressingRoom: require('../../icons/roomtype_dressing_room.svg'),
    roomType_driveway: require('../../icons/roomtype_driveway.svg'),
    roomType_familyRoom: require('../../icons/roomtype_family_room.svg'),
    roomType_garage: require('../../icons/roomtype_garage.svg'),
    roomType_garden: require('../../icons/roomtype_garden.svg'),
    roomType_gym: require('../../icons/roomtype_gym.svg'),
    roomType_hallway: require('../../icons/roomtype_hallway.svg'),
    roomType_hobbyRoom: require('../../icons/roomtype_hobbyroom.svg'),
    roomType_homeCinema: require('../../icons/roomtype_home_cinema.svg'),
    roomType_homeCinema2: require('../../icons/roomtype_home_cinema_2.svg'),
    roomType_homeOffice: require('../../icons/roomtype_home_office.svg'),
    roomType_kitchen: require('../../icons/roomtype_kitchen.svg'),
    roomType_kitchen2: require('../../icons/roomtype_kitchen_2.svg'),
    roomType_laundryRoom: require('../../icons/roomtype_laundry_room.svg'),
    roomType_library: require('../../icons/roomtype_library.svg'),
    roomType_library2: require('../../icons/roomtype_library_2.svg'),
    roomType_livingRoom: require('../../icons/roomtype_living_room.svg'),
    roomType_masterBedroom: require('../../icons/roomtype_master_bedroom.svg'),
    roomType_meetingRoom: require('../../icons/roomtype_meeting_room.svg'),
    roomType_nursery: require('../../icons/roomtype_nursery.svg'),
    roomType_pantry: require('../../icons/roomtype_pantry.svg'),
    roomType_parking: require('../../icons/roomtype_parking.svg'),
    roomType_patio: require('../../icons/roomtype_patio.svg'),
    roomType_spa: require('../../icons/roomtype_spa.svg'),
    roomType_staircase: require('../../icons/roomtype_staircase.svg'),
    roomType_swimmingPool: require('../../icons/roomtype_swimming_pool.svg'),
    roomType_swimmingPool2: require('../../icons/roomtype_swimming_pool_2.svg'),
    roomType_terrace: require('../../icons/roomtype_terrace.svg'),
    roomType_toilet: require('../../icons/roomtype_toilet.svg'),
    roomType_utilityRoom: require('../../icons/roomtype_utility_room.svg'),
    roomType_wineCellar: require('../../icons/roomtype_wine_cellar.svg'),
    roomType_workshop: require('../../icons/roomtype_workshop.svg'),
    rightArrow: require('../../icons/arrow_right-56.svg'),
    // eslint-disable-next-line max-len
    black_video_poster: 'data:image/png;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDQwIDQwIj4KICAgIDxwYXRoIGQ9Ik0gMCAwIEggOTAgViA5MCBIIDAgTCAwIDAiLz4KPC9zdmc+Cg==',
    empty_queue: require('../../icons/empty_queue.svg'),
    remote: require('../../icons/remote.svg'),
    stop: require('../../icons/stop.svg'),
    eject: require('../../icons/eject.svg'),
    search: require('../../icons/search_miro.svg'),
    microphone: require('../../icons/microphone.svg'),
    subtitles: require('../../icons/subtitle.svg'),
    netflix: require('../../icons/netflix.svg'),
    record: require('../../icons/record.svg'),
    skipNext: require('../../icons/skip-next_miro.svg'),
    skipPrevious: require('../../icons/skip-previous_miro.svg'),
    play: require('../../icons/play_miro.svg'),
    pause: require('../../icons/pause_miro.svg'),
    fastForward: require('../../icons/forward_miro.svg'),
    reverse: require('../../icons/reverse_miro.svg')
  })
  .service('BasIcons', [
    '$sce',
    'ICONS',
    BasIcons
  ])

/**
 * Inject the service as soon as possible
 * to make sure Icons are ready to be used.
 *
 * @constructor
 * @param $sce
 * @param {ICONS} ICONS
 */
function BasIcons (
  $sce,
  ICONS
) {
  var i, keys, length

  keys = Object.keys(ICONS)
  length = keys.length
  for (i = 0; i < length; i++) {

    ICONS[keys[i]] = $sce.trustAsHtml(ICONS[keys[i]])
  }
}
