'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('LocalHelper', [
    'BAS_API',
    'BAS_LOCAL_LIBRARY',
    'BAS_LIBRARY_ERRORS',
    'BAS_SOURCE',
    'LibraryService',
    'LibraryState',
    'BasSourceLibraryHelper',
    'Logger',
    LocalHelper
  ])

/**
 * Helper Service for getting Local Objects
 *
 * @constructor
 * @param BAS_API
 * @param {BAS_LOCAL_LIBRARY} BAS_LOCAL_LIBRARY
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {LibraryService} LibraryService
 * @param {LibraryState} LibraryState
 * @param {BasSourceLibraryHelper} BasSourceLibraryHelper
 * @param Logger
 */
function LocalHelper (
  BAS_API,
  BAS_LOCAL_LIBRARY,
  BAS_LIBRARY_ERRORS,
  BAS_SOURCE,
  LibraryService,
  LibraryState,
  BasSourceLibraryHelper,
  Logger
) {
  var serviceName = 'Local Helper'

  /**
   * @param {LocalElement} element
   * @returns {Promise}
   */
  this.getElementData = function (element) {

    // Get player instance
    var promise, album, artist

    if (BasUtil.isObject(element) &&
      BasUtil.isNEString(element.type)) {

      switch (element.type) {
        case BAS_LOCAL_LIBRARY.T_EL_ALBUM:

          // Set params
          album = element.title
          artist = element.artist

          promise = LibraryService.getAlbumSongs(album, artist)

          break
        case BAS_LOCAL_LIBRARY.T_EL_ARTIST:

          // Set params
          artist = element.title

          promise = LibraryService.getArtistSongs(artist)

          break
        case BAS_LOCAL_LIBRARY.T_EL_PLAYLIST:

          promise = Promise.resolve(element.id)

          break
        case BAS_LOCAL_LIBRARY.T_EL_TRACK:

          promise = Promise.resolve([element])

          break
        default:

          promise =
            Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ELEMENT)

          Logger.warn(serviceName +
                        ' - Play' +
                        ' - Unsupported type')
      }

      return promise
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ELEMENT)
  }

  /**
   * @param {LocalElement} element
   * @returns {boolean}
   */
  this.isPlaylist = function (element) {
    return element && element.type === BAS_LOCAL_LIBRARY.T_EL_PLAYLIST
  }

  /**
   * @returns {Promise<Object[]>}
   */
  this.getPlaylists = function () {

    var basSource = LibraryState.getCurrentSource()

    if (basSource && basSource.playlists) {

      return basSource.playlists.retrieve().then(onLocalLoaded)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_PLAYLISTS)

    function onLocalLoaded () {

      var _basSource = LibraryState.getCurrentSource()

      if (_basSource && _basSource.playlists) {

        return _basSource.playlists.editable
      }

      return Promise.reject(BAS_LIBRARY_ERRORS.NO_PLAYLISTS)
    }
  }

  /**
   * @param {string} id
   * @param {LocalElement} element
   * @returns {Promise}
   */
  this.addElementToPlaylist = function (
    id,
    element
  ) {
    var _this, basSource

    _this = this

    basSource = LibraryState.getCurrentSource()

    if (BasUtil.isNEString(id)) {

      return this.getElementData(element).then(onData)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ID)

    function onData (result) {

      if (Array.isArray(result)) {

        return _this.addToPlaylist(
          id,
          basSource && basSource.isAudioSource
            ? getUris(result)
            : getFiles(result)
        )

      } else if (BasUtil.isNEString(result)) {

        return BasSourceLibraryHelper.getAllPlaylistTracks(basSource, result)
          .then(onData)
      }

      return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ELEMENT)
    }
  }

  /**
   * @param {string} playlistName
   * @param {LocalElement} element
   * @returns {Promise}
   */
  this.addElementToNewPlaylist = function (
    playlistName,
    element
  ) {
    var _this, basSource

    _this = this

    basSource = LibraryState.getCurrentSource()

    if (BasUtil.isNEString(playlistName)) {

      return this.getElementData(element).then(onData)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_NAME)

    function onData (result) {

      if (Array.isArray(result)) {

        return _this.addToNewPlaylist(
          playlistName,
          basSource && basSource.isAudioSource
            ? getUris(result)
            : getFiles(result)
        )

      } else if (BasUtil.isNEString(result)) {

        if (basSource.isAudioSource) {

          return basSource.source.getAllPlaylistTracks(result)
            .then(onData)

        } else if (basSource && basSource.database) {

          return basSource.database.getPlaylistContent(result)
            .then(onData)
        }
      }

      return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ELEMENT)
    }
  }

  /**
   * @param {string} id
   * @param {string[]} items
   * @returns {Promise}
   */
  this.addToPlaylist = function (id, items) {

    return BasSourceLibraryHelper.addTracksToPlaylist(
      LibraryState.getCurrentSource(),
      id,
      items
    )
  }

  /**
   * @param {string} name
   * @param {string[]} items
   * @returns {Promise}
   */
  this.addToNewPlaylist = function (name, items) {

    return BasSourceLibraryHelper.addTracksToNewPlaylist(
      LibraryState.getCurrentSource(),
      name,
      items
    )
  }

  /**
   * @param {LocalElement} element
   * @param {string} [option]
   */
  this.play = function (element, option) {

    var basSource = LibraryState.getCurrentSource()

    if (
      basSource &&
      basSource.isAudioSource &&
      BasUtil.isNEString(element.uri)
    ) {
      onData(element.uri)
    } else {
      this.getElementData(element).then(onData)
    }

    function onData (result) {

      var queue

      if (
        basSource &&
        basSource.source
      ) {

        if (basSource.source.queue) {

          queue = basSource.source.queue

          if (Array.isArray(result)) {

            queue.addSongs(getFiles(result), option)

          } else {

            queue.addPlaylist(result, option)
          }

        } else if (basSource.isAudioSource) {

          if (
            basSource.queue &&
            BasUtil.isNEString(option) &&
            basSource.canAddToQueue
          ) {

            if (element.type === BAS_LOCAL_LIBRARY.T_EL_TRACK) {

              basSource.source.queueAddItems(
                (
                  Array.isArray(result)
                    ? getUris(result)
                    : [result]
                ),
                option,
                true
              )

            } else if (BasUtil.isNEString(result)) {

              basSource.source.queueAddUri(
                result,
                option,
                true
              )
            }

          } else {

            basSource.playUri(result)
          }
        }
      }
    }
  }

  /**
   * @param {string[]} items
   * @param {string} option
   */
  this.playSelection = function (items, option) {

    var basSource = LibraryState.getCurrentSource()

    if (basSource &&
      basSource.source) {
      if (basSource.source.queue) {

        basSource.source.queue.addSongs(items, option)

      } else if (
        basSource.isAudioSource &&
        basSource.queue
      ) {

        basSource.source.queueAddItems(
          items,
          option,
          true
        )
      }
    }
  }

  /**
   * @param {LocalElement} element
   * @param {LocalCollection} collection
   * @param {Object} can
   */
  this.checkContextCapabilities = function (
    element,
    collection,
    can
  ) {
    var canArtist, isArtistDetail, canAlbum, isAlbumDetail, hasDetail
    var basSource, onlyReplace, hasQueueCapabilities, canPlayUri

    onlyReplace = true
    hasQueueCapabilities = false
    canPlayUri = false

    // Check element
    if (
      BasUtil.isObject(element) &&
      BasUtil.isNEString(element.type)
    ) {

      basSource = LibraryState.getCurrentSource()

      if (basSource) {

        if (
          (
            basSource.source &&
            basSource.source.queue
          ) ||
          (
            basSource.isAudioSource &&
            basSource.queue
          )
        ) {

          onlyReplace = basSource.isAudioSource
            ? (
                basSource.type === BAS_SOURCE.T_ASANO &&
              basSource.subType === BAS_SOURCE.ST_DEEZER
              )
            : (
                basSource.source.queue.type ===
              BAS_API.Queue.TYPE_STREAM ||
              basSource.source.queue.type ===
              BAS_API.Queue.TYPE_DEEZER_FLOW ||
              basSource.source.queue.type ===
              BAS_API.Queue.TYPE_DEEZER_RADIO
              )
        }

        hasQueueCapabilities = basSource.isAudioSource
          ? basSource.canAddToQueue
          : true

        canPlayUri = basSource.isAudioSource
          ? basSource.source.allowsExecute(
            BAS_API.AudioSource.C_PLAY_URI
          )
          : true
      }

      // Set modal properties based on type
      switch (element.type) {
        case BAS_LOCAL_LIBRARY.T_EL_ALBUM:

          canArtist = basSource.isAudioSource
            ? BasUtil.isNEString(element.artistUri)
            : BasUtil.isNEString(element.artist)

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.addPlaylist = true
          can.goToArtist = canArtist

          break
        case BAS_LOCAL_LIBRARY.T_EL_PLAYLIST:

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.addPlaylist = true

          break
        case BAS_LOCAL_LIBRARY.T_EL_ARTIST:

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.addPlaylist = true

          break
        case BAS_LOCAL_LIBRARY.T_EL_TRACK:

          hasDetail = (
            BasUtil.isObject(collection) &&
            BasUtil.isObject(collection.detailElement)
          )
          canArtist = basSource.isAudioSource
            ? BasUtil.isNEString(element.artistUri)
            : BasUtil.isNEString(element.artist)
          isArtistDetail = (
            hasDetail &&
            collection.detailElement.type ===
            BAS_LOCAL_LIBRARY.T_EL_ARTIST
          )
          canAlbum = BasUtil.isNEString(element.albumUri)
          isAlbumDetail = (
            hasDetail &&
            collection.detailElement.type ===
            BAS_LOCAL_LIBRARY.T_EL_ALBUM
          )

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.addPlaylist = true
          can.goToAlbum = canAlbum && !isAlbumDetail
          can.goToArtist = canArtist && !isArtistDetail

          break
        default:
          Logger.warn(serviceName + ' - Unsupported type', element.type)
      }

    } else {
      Logger.warn(serviceName + ' - Unknown element', element)
    }
  }

  /**
   * @param {Object[]} array
   * @returns {string[]}
   */
  function getFiles (array) {

    var result, length, i, file
    result = []

    if (Array.isArray(array)) {

      length = array.length
      for (i = 0; i < length; i++) {

        file = array[i].file
        if (BasUtil.isNEString(file)) result.push(file)
      }
    }

    return result
  }

  /**
   * @param {(BasTrack|LocalElement)[]} elements
   * @returns {string[]}
   */
  function getUris (elements) {

    var i, length, result

    result = []

    length = elements.length
    for (i = 0; i < length; i++) {

      result.push(elements[i].uri)
    }

    return result
  }
}
