'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('TidalCollection', [
    '$rootScope',
    'BAS_TIDAL',
    'BAS_LIBRARY_ERRORS',
    'BasLibraryCollection',
    'TidalModel',
    'TidalElement',
    'LibraryState',
    'Logger',
    tidalCollectionFactory
  ])

/**
 * @param $rootScope
 * @param {BAS_TIDAL} BAS_TIDAL
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param BasLibraryCollection
 * @param {TidalModel} TidalModel
 * @param TidalElement
 * @param {LibraryState} LibraryState
 * @param Logger
 * @returns TidalCollection
 */
function tidalCollectionFactory (
  $rootScope,
  BAS_TIDAL,
  BAS_LIBRARY_ERRORS,
  BasLibraryCollection,
  TidalModel,
  TidalElement,
  LibraryState,
  Logger
) {
  var className = 'Tidal Collection'
  var SEARCH_THUMB_AMOUNT = 3

  /**
   * @constructor
   * @param {Function} handler
   */
  function TidalCollection (handler) {

    BasLibraryCollection.call(this, handler)

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * @type {string}
     */
    this.contentType = TidalCollection.CT_TRACK

    /**
     * @type {(string|number)}
     */
    this.id = ''

    /**
     * @type {number}
     */
    this.offset = 0

    /**
     * @type {number}
     */
    this.limit = 25

    /**
     * @type {Promise}
     */
    this.endReachedPromise = null

    /**
     * @type {?Promise}
     * @private
     */
    this._allElementsPromise = null

    this.handleTidalElements = this.processElements.bind(this)
    this.handleError = this.onRetrieveError.bind(this)
    this.handleConcatElements = this.concatItems.bind(this)
    this.handleReplaceElements = this.replaceItems.bind(this)
    this.handleCheckItems = this.checkItems.bind(this)
  }

  // Set new prototype from inherited object
  TidalCollection.prototype = Object.create(BasLibraryCollection.prototype)

  // Set correct constructor after setting new prototype
  TidalCollection.prototype.constructor = TidalCollection

  /**
   * @constant {string}
   */
  TidalCollection.TYPE_NEW = 'new'

  /**
   * @constant {string}
   */
  TidalCollection.TYPE_RISING = 'rising'

  /**
   * @constant {string}
   */
  TidalCollection.TYPE_USER = 'user'

  /**
   * @constant {string}
   */
  TidalCollection.TYPE_GENRE = 'genre'

  /**
   * @constant {string}
   */
  TidalCollection.TYPE_ALBUM = 'album'

  /**
   * @constant {string}
   */
  TidalCollection.TYPE_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  TidalCollection.TYPE_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  TidalCollection.TYPE_MOOD = 'mood'

  /**
   * @constant {string}
   */
  TidalCollection.TYPE_SEARCH = 'search'

  /**
   * @constant {string}
   */
  TidalCollection.CT_ALBUM = 'album'

  /**
   * @constant {string}
   */
  TidalCollection.CT_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  TidalCollection.CT_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  TidalCollection.CT_GENRE = 'genre'

  /**
   * @constant {string}
   */
  TidalCollection.CT_MOOD = 'mood'

  /**
   * @constant {string}
   */
  TidalCollection.CT_TRACK = 'track'

  /**
   * @returns {?Function}
   */
  TidalCollection.prototype.retrieveFunction = function () {

    var basSource = LibraryState.getTidalSource()
    var tidal, type

    if (!basSource) return null

    tidal = basSource.tidal

    switch (this.type) {
      case TidalCollection.TYPE_SEARCH:
        type = getSearchType(this.contentType)
        return tidal.searchLib.bind(tidal, type, this.id)
      case TidalCollection.TYPE_MOOD:
        return tidal.getMoodPlaylistsLib.bind(tidal, this.id)
      case TidalCollection.TYPE_ALBUM:
        return tidal.getAlbumTracksLib.bind(tidal, this.id)
      case TidalCollection.TYPE_PLAYLIST:
        return tidal.getPlaylistTracksLib.bind(tidal, this.id)
      case TidalCollection.TYPE_NEW:
        switch (this.contentType) {
          case TidalCollection.CT_TRACK:
            return tidal.getNewTracksLib.bind(tidal)
          case TidalCollection.CT_ALBUM:
            return tidal.getNewAlbumsLib.bind(tidal)
          case TidalCollection.CT_PLAYLIST:
            return tidal.getNewPlaylistsLib.bind(tidal)
        }
        break
      case TidalCollection.TYPE_GENRE:
        switch (this.contentType) {
          case TidalCollection.CT_TRACK:
            return tidal.getGenreTracksLib.bind(tidal, this.id)
          case TidalCollection.CT_ALBUM:
            return tidal.getGenreAlbumsLib.bind(tidal, this.id)
          case TidalCollection.CT_PLAYLIST:
            return tidal.getGenrePlaylistsLib.bind(tidal, this.id)
        }
        break
      case TidalCollection.TYPE_RISING:
        switch (this.contentType) {
          case TidalCollection.CT_TRACK:
            return tidal.getRisingTracksLib.bind(tidal)
          case TidalCollection.CT_ALBUM:
            return tidal.getRisingAlbumsLib.bind(tidal)
        }
        break
      case TidalCollection.TYPE_ARTIST:
        switch (this.contentType) {
          case TidalCollection.CT_TRACK:
            return tidal.getArtistTracksLib.bind(tidal, this.id)
          case TidalCollection.CT_ALBUM:
            return tidal.getArtistAlbumsLib.bind(tidal, this.id)
          case TidalCollection.CT_ARTIST:
            return tidal.getArtistArtistsLib.bind(tidal, this.id)
        }
        break
      case TidalCollection.TYPE_USER:
        switch (this.contentType) {
          case TidalCollection.CT_TRACK:
            return tidal.getUserTracksLib.bind(tidal, this.id)
          case TidalCollection.CT_ALBUM:
            return tidal.getUserAlbumsLib.bind(tidal, this.id)
          case TidalCollection.CT_ARTIST:
            return tidal.getUserArtistsLib.bind(tidal, this.id)
          case TidalCollection.CT_PLAYLIST:
            return tidal.getUserPlaylistsLib.bind(tidal, this.id)
        }
        break
      default:
        switch (this.contentType) {
          case TidalCollection.CT_GENRE:
            return tidal.getGenresLib.bind(tidal)
          case TidalCollection.CT_MOOD:
            return tidal.getMoodsLib.bind(tidal)
        }
    }

    return null

    function getSearchType (contentType) {
      switch (contentType) {
        case TidalCollection.CT_ARTIST:
          return 'artists'
        case TidalCollection.CT_ALBUM:
          return 'albums'
        case TidalCollection.CT_TRACK:
          return 'tracks'
        case TidalCollection.CT_PLAYLIST:
          return 'playlists'
      }
    }

  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   * @returns {Promise}
   */
  TidalCollection.prototype.itemReorder = function (
    spliceIndex,
    originalIndex
  ) {
    var uuid, basSource

    if (spliceIndex === originalIndex) return Promise.resolve()

    // Get playlist uuid
    if (BasUtil.isObject(this.detailElement)) {

      uuid = this.detailElement.id

    } else {

      return Promise.reject(BasLibraryCollection.NO_UUID)
    }

    basSource = LibraryState.getTidalSource()

    if (basSource && basSource.tidal) {

      return basSource.tidal.reorderPlaylistSong(
        uuid,
        originalIndex,
        spliceIndex
      )
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_TIDAL)
  }

  /**
   * @param {number} index
   * @returns {Promise}
   */
  TidalCollection.prototype.itemSwipe = function (index) {
    var uuid, basSource

    // Get playlist uuid
    if (BasUtil.isObject(this.detailElement)) {
      uuid = this.detailElement.id
    } else {
      return Promise.reject(BasLibraryCollection.NO_UUID)
    }

    basSource = LibraryState.getTidalSource()

    if (basSource && basSource.tidal) {

      return basSource.tidal.removePlaylistSong(uuid, index)
        .then(this.handlePlaylists)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_TIDAL)
  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   * @returns {Promise}
   */
  TidalCollection.prototype.listReorder = function (
    spliceIndex,
    originalIndex
  ) {
    var element

    if (this.isEditing) {

      element = this.elements[originalIndex]

      // Update local
      this.elements.splice(originalIndex, 1)
      this.elements.splice(spliceIndex, 0, element)

      return this.itemReorder(spliceIndex, originalIndex)
        .catch(this.onItemFail.bind(this))
    }

    return Promise.reject(BasLibraryCollection.NOT_EDITING)
  }

  /**
   * @param {number} index
   * @returns {Promise}
   */
  TidalCollection.prototype.listSwipe = function (index) {

    if (this.isEditing) {

      // Remove from list
      this.elements.splice(index, 1)

      return this.itemSwipe(index).catch(this.onItemFail.bind(this))
    }

    return Promise.reject(BasLibraryCollection.NOT_EDITING)
  }

  TidalCollection.prototype.onItemFail = function (err) {
    // Retrieve all data again since there is some data out of sync
    // Also gets the correct Etag
    if (err === BAS_TIDAL.ERR_OUTDATED_TAG) {

      return this.retrieveAll(true)
    }

    return Promise.reject(err)
  }

  TidalCollection.prototype.dataCheck = function () {

    var func, params

    func = this.retrieveFunction()

    if (func) {

      this.offset -= this.limit

      params = {}
      params.limit = this.limit
      params.offset = this.offset

      func(params).then(onDataDifferent, onDataSame)

      this.offset += this.limit
    }

    function onDataDifferent (result) {
      Logger.info('onDataDifferent', result)
    }

    function onDataSame (result) {
      Logger.info('onDataSame', result)
    }
  }

  TidalCollection.prototype.refresh = function () {

    var _this, func, offset, basSource

    _this = this

    if (this.type === TidalCollection.TYPE_USER &&
      this.contentType === TidalCollection.CT_PLAYLIST) {

      this.retrieveAll(true)

    } else {

      basSource = LibraryState.getTidalSource()
      offset = this.offset
      func = this.retrieveFunction()

      if (func && basSource && basSource.tidal.isLinked()) {

        basSource.tidal.getXElements(func, this.limit, 0, offset)
          .then(onSuccess)
          .then(this.handleTidalElements, this.handleError)
          .then(this.handleReplaceElements)
          .catch(onFail)
      }
    }

    function onSuccess (result) {

      if (result && result.items) {

        _this.offset = result.items.length
      }

      return result
    }

    function onFail () {
      _this.hasReachedEnd = false
      _this.isFetching = false
    }
  }

  /**
   * @returns {Promise}
   */
  TidalCollection.prototype.retrieve = function () {

    var params, func

    if (!this.isFetching) {

      func = this.retrieveFunction()
      if (func) {

        // Set fetching state
        this.isFetching = true

        params = {}
        params.limit = this.limit
        params.offset = this.offset

        return func(params)
          .then(this.handleTidalElements, this.handleError)
          .then(this.handleCheckItems)
          .then(this.handleConcatElements)
      }

      Logger.warn(className + ' - Retrieve - No retrieve function', func)

      return Promise.reject(BAS_LIBRARY_ERRORS.NO_RETRIEVE_FUNCTION)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.ALREADY_FETCHING_END)
  }

  /**
   * @param {boolean} force
   * @returns {Promise}
   */
  TidalCollection.prototype.retrieveAll = function (force) {

    var _this, func, basSource

    _this = this

    if (BasUtil.isObject(this.endReachedPromise)) {

      return this.endReachedPromise
        .then(this.retrieveAll.bind(this))
    }

    if (this._allElementsPromise) return this._allElementsPromise
    if (!force && this.hasReachedEnd) return Promise.resolve()

    if (!this.isFetching) {

      // Check if func is valid
      func = this.retrieveFunction()
      basSource = LibraryState.getTidalSource()
      if (func && basSource && basSource.tidal.isLinked()) {

        // Set fetching state
        this.isFetching = true
        this.hasReachedEnd = true

        // Reset params
        this.limit = BAS_TIDAL.TIDAL_MAX_COLLECTION_RETRIEVE_LENGTH
        this.offset = 0

        this._allElementsPromise =
          basSource.tidal.getXElements(func, this.limit, 0, -1)
            .then(onSuccess)
            .then(this.handleTidalElements, this.handleError)
            .then(this.handleCheckItems)
            .then(this.handleReplaceElements)
            .then(clearPromise, onFail)

        return this._allElementsPromise

      } else {

        Logger.warn(className +
                      ' - Retrieve' +
                      ' - No retrieve function')

        return Promise.reject(BAS_LIBRARY_ERRORS.NO_RETRIEVE_FUNCTION)
      }

    } else {

      return Promise.reject(BAS_LIBRARY_ERRORS.ALREADY_FETCHING_END)
    }

    function clearPromise () {
      _this._allElementsPromise = null
      _this.isFetching = false
    }

    function onFail () {
      _this._allElementsPromise = null
      _this.hasReachedEnd = false
      _this.isFetching = false
    }

    function onSuccess (result) {

      if (result && result.items) {

        _this.offset = result.items.length
      }

      return result
    }
  }

  /**
   * @param {(Array|Object)} result
   * @returns {(TidalElement[]|Promise)}
   */
  TidalCollection.prototype.processElements = function (result) {

    var processed, type, clean

    this.isFetching = false
    this.endReachedPromise = null

    // Check result
    if (BasUtil.isObject(result) || Array.isArray(result)) {

      // Hide the collection if there are no items
      if (result[BAS_TIDAL.TOT_NOI] === 0) {

        this.setTitle('')
        this.lastElement = null
        this.hasReachedEnd = true

        processed = []

      } else if (BasUtil.isNEArray(result)) {

        this.hasReachedEnd = true

        type = this.getElementType()
        clean = this.type === TidalCollection.TYPE_ALBUM

        // Process Tidal objects
        processed = TidalModel.processTidalObjects(result, type, clean)

      } else if (BasUtil.safeHasOwnProperty(result, 'items')) {

        this.offset += this.limit

        // Check if end is reached
        if (this.offset >= result[BAS_TIDAL.TOT_NOI]) {

          // End is reached
          this.hasReachedEnd = true
        }

        // Check if Show More is still needed
        if (result[BAS_TIDAL.TOT_NOI] < SEARCH_THUMB_AMOUNT) {
          this.lastElement = null
        }

        type = this.getElementType()
        clean = this.type === TidalCollection.TYPE_ALBUM

        // Process Tidal objects
        processed = TidalModel.processTidalObjects(
          result.items,
          type,
          clean
        )

      } else if (!Array.isArray(result)) {

        Logger.warn(className + ' - processElements - Invalid result', result)
      }

      $rootScope.$applyAsync()
      return processed
    }

    $rootScope.$applyAsync()
    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
  }

  TidalCollection.prototype.concatItems = function (items) {
    this.elements = this.elements.concat(items)
    $rootScope.$applyAsync()
  }

  TidalCollection.prototype.replaceItems = function (items) {
    this.elements = items
    $rootScope.$applyAsync()
  }

  TidalCollection.prototype.checkItems = function (items) {

    var _items

    _items = items

    if (this.type === TidalCollection.TYPE_USER &&
      this.contentType === TidalCollection.TYPE_PLAYLIST) {

      _items = checkDoubles(_items)
      _items.sort(timeSort)
    }

    return _items
  }

  TidalCollection.prototype.getElementType = function () {
    switch (this.contentType) {
      case TidalCollection.CT_ALBUM:
        return TidalElement.TYPE_ALBUM
      case TidalCollection.CT_ARTIST:
        return TidalElement.TYPE_ARTIST
      case TidalCollection.CT_PLAYLIST:
        return TidalElement.TYPE_PLAYLIST
      case TidalCollection.CT_GENRE:
        return TidalElement.TYPE_GENRE
      case TidalCollection.CT_MOOD:
        return TidalElement.TYPE_MOOD
      case TidalCollection.CT_TRACK:
        return TidalElement.TYPE_TRACK
    }
  }

  /**
   * @param {Object[]} original
   * @returns {Object[]}
   */
  function checkDoubles (original) {

    var items, length, i

    items = []
    length = original.length
    for (i = 0; i < length; i++) {
      if (!idCheck(items, original[i].id)) {
        items.push(original[i])
      }
    }

    return items
  }

  /**
   * @param {Array} array
   * @param {string} id
   * @returns {boolean}
   */
  function idCheck (array, id) {
    var length, i

    length = array.length
    for (i = 0; i < length; i++) {
      if (array[i].id === id) {
        return true
      }
    }

    return false
  }

  function timeSort (a, b) {

    var nameA = a.timeCreated
    var nameB = b.timeCreated

    if (nameA < nameB) {
      return 1
    }
    if (nameA > nameB) {
      return -1
    }

    // Names must be equal
    return 0
  }

  TidalCollection.prototype.onReachedEnd = function () {

    // Check if fetch is needed
    if (!this.isFetching &&
      !this.hasReachedEnd) {

      // Retrieve next
      return (this.endReachedPromise = this.retrieve())
    }

    return Promise.resolve(false)
  }

  /**
   * @param {string} type
   * @param {boolean} setTitle
   */
  TidalCollection.prototype.setContentType = function (
    type,
    setTitle
  ) {
    var _this = this

    // Set type
    this.contentType = type

    // Check type
    switch (type) {
      case TidalCollection.CT_ALBUM:

        setTitleChecked('albums')

        break
      case TidalCollection.CT_ARTIST:

        setTitleChecked('artists')

        break
      case TidalCollection.CT_GENRE:

        setTitleChecked('genres')

        break
      case TidalCollection.CT_PLAYLIST:

        setTitleChecked('playlists')

        break
      case TidalCollection.CT_MOOD:

        setTitleChecked('moods')

        break
      case TidalCollection.CT_TRACK:

        setTitleChecked('songs')

        break
      default:

        // Reset type
        this.contentType = TidalCollection.CT_TRACK

        Logger.warn(className + ' - setType - Unknown type', type)
    }

    function setTitleChecked (str) {

      if (setTitle === true) {
        _this.setTitleId(str)
      }
    }
  }

  TidalCollection.prototype.onRetrieveError = function (error) {

    Logger.warn(className + ' - Retrieve ERROR ', error)

    // Fetching is done
    this.isFetching = false

    return Promise.reject(error)
  }

  return TidalCollection

}
