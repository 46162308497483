'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('RadioCollection', [
    'RadioHelper',
    'BasLibraryCollection',
    'Logger',
    radioCollectionFactory
  ])

/**
 * @param {RadioHelper} RadioHelper
 * @param BasLibraryCollection
 * @param Logger
 * @returns RadioCollection
 */
function radioCollectionFactory (
  RadioHelper,
  BasLibraryCollection,
  Logger
) {
  var className = 'RadioCollection'

  /**
   * @constructor
   * @extends BasLibraryCollection
   */
  function RadioCollection () {

    // Call 'super' constructor
    BasLibraryCollection.call(this)

    /**
     * @instance
     * @type {RadioRequest}
     */
    this.request = null
  }

  // Set new prototype from inherited object
  RadioCollection.prototype =
    Object.create(BasLibraryCollection.prototype)

  // Set correct constructor after setting new prototype
  RadioCollection.prototype.constructor = RadioCollection

  // Instance methods

  /**
   * @param {RadioRequest} request
   */
  RadioCollection.prototype.setRequest = function (request) {

    // Check request
    if (BasUtil.isObject(request)) {

      // Clear current elements
      this.elements = []

      // Set request
      this.request = request

    } else {
      Logger.warn(className + ' - setRequest', request)

      // Clear current request
      this.request = null
    }
  }

  /**
   * Execute current request
   *
   * @returns {Promise}
   */
  RadioCollection.prototype.retrieve = function () {

    var _this = this

    // Check if not already fetching
    if (!this.isFetching) {

      // Update Collection state
      this.isFetching = true

      // Perform request for elements
      return RadioHelper.apiRequest(this.request)
        .then(onRetrieve, onError)
    }

    function onRetrieve (result) {
      Logger.debug(className + ' - onRetrieve', result)

      // Concatenate retrieved elements
      _this.elements = _this.elements.concat(result)

      // Update Collection state
      _this.isFetching = false

      // Set had reached end
      _this.hasReachedEnd = true

      // Return a resolved Promise with all collection elements
      return Promise.resolve(_this.elements)
    }

    function onError (error) {
      Logger.error(className + ' - retrieve onError', error)

      // Update Collection state
      _this.isFetching = false

      // Return a rejected Promise with given error
      return Promise.reject(error)
    }
  }

  return RadioCollection
}
