'use strict'

angular
  .module('basalteApp')
  .constant('BAS_INTL', {
    DAYS: {
      SUNDAY: 'sunday',
      MONDAY: 'monday',
      TUESDAY: 'tuesday',
      WEDNESDAY: 'wednesday',
      THURSDAY: 'thursday',
      FRIDAY: 'friday',
      SATURDAY: 'saturday'
    },
    DAYS_WEEK: [],
    DAYS_WEEK_TRANSLATED: {},
    DAYS_WEEK_TRANSLATED_SHORT: {},
    DAYS_WEEK_INTL: [],
    DAYS_TRANSLATE_DICT: {
      sunday: 'day_sunday',
      monday: 'day_monday',
      tuesday: 'day_tuesday',
      wednesday: 'day_wednesday',
      thursday: 'day_thursday',
      friday: 'day_friday',
      saturday: 'day_saturday'
    },
    DAYS_TRANSLATE_SHORT_DICT: {
      sunday: 'day_short2_sunday',
      monday: 'day_short2_monday',
      tuesday: 'day_short2_tuesday',
      wednesday: 'day_short2_wednesday',
      thursday: 'day_short2_thursday',
      friday: 'day_short2_friday',
      saturday: 'day_short2_saturday'
    },
    LOCALE_OPTION_SHORT: 'localeOptionShort',
    LOCALE_OPTION_LONG: 'localeOptionLong',
    LOCALE_OPTION_HOUR: 'localeOptionHour',
    LOCALE_OPTION_SHORT_WITHOUT_MERIDIEM: 'localeOptionHourShort',
    LOCALE_OPTION_MERIDIEM: 'localeOptionAMPM',
    LOCALE_OPTION_DAY_MONTH: 'dateTimeOptionDayMonth',
    LOCALES: {},
    UNITS: {
      TEMPERATURE: 'temperature',
      SPEED: 'speed',
      PRESSURE: 'pressure',
      RAIN: 'rain'
    },
    NL_NL: 'nl-nl',
    TWO_DIGIT: '2-digit',
    TIME_FORMAT_12: 'timeFormat12hr',
    TIME_FORMAT_24: 'timeFormat24hr',
    EVT_TIME_FORMAT_CHANGED: 'evtTimeFormatChanged'
  })
