'use strict'

angular
  .module('basalteApp')
  .service('ToMainState', [
    '$transitions',
    'STATES',
    'CurrentBasCore',
    'MainStartupState',
    ToMainState
  ])

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {CurrentBasCore} CurrentBasCore
 * @param {MainStartupState} MainStartupState
 */
function ToMainState (
  $transitions,
  STATES,
  CurrentBasCore,
  MainStartupState
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  init()

  function init () {

    $transitions.onStart(
      {
        to: STATES.MAIN
      },
      _onToMainState
    )
  }

  function _onToMainState () {

    return MainStartupState.determine(currentBasCoreState.core)
  }
}
