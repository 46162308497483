'use strict'

angular
  .module('basalteApp')
  .constant('BAS_INPUT_MODAL', {
    BASE: 'basInputModal',
    T_IP_ADDRESS: 'basInputModalIpAddress',
    T_MULTI_SCREEN: 'basInputModalMultiScreen',
    T_SELECT: 'basInputModalMultiScreenSelect',
    T_TIME: 'basInputModalTime',
    T_AVROOM_SELECT: 'basInputModalSourceSelect',
    T_FAVOURITE_CHANNEL: 'basInputModalChannel',
    T_NUMBER: 'basInputModalNumber'
  })
