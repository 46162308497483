'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('tidalSettingsCtrl', [
    '$rootScope',
    '$scope',
    'ModalService',
    'BAS_HTML',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_MODAL',
    'BAS_CORE',
    'BAS_API',
    'Sources',
    'BasModal',
    'CurrentBasCore',
    'SourcesHelper',
    'BasInAppBrowser',
    tidalSettingsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param ModalService
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_CORE} BAS_CORE
 * @param BAS_API
 * @param {Sources} Sources
 * @param {BasModal} BasModal
 * @param {CurrentBasCore} CurrentBasCore
 * @param {SourcesHelper} SourcesHelper
 * @param BasInAppBrowser
 */
function tidalSettingsCtrl (
  $rootScope,
  $scope,
  ModalService,
  BAS_HTML,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_MODAL,
  BAS_CORE,
  BAS_API,
  Sources,
  BasModal,
  CurrentBasCore,
  SourcesHelper,
  BasInAppBrowser
) {
  var tidal = this

  var _inAppBrowser = null

  var _tidalSourceId = -1
  var _tidalListeners = []

  var _listeners = []
  var _tidalAudioSourceListeners = []

  var currentBasCoreState = CurrentBasCore.get()

  tidal.BAS_SOURCES = BAS_SOURCES
  tidal.BAS_SOURCE = BAS_SOURCE

  tidal.currentBasCoreState = CurrentBasCore.get()

  tidal.serverOutdated = false

  tidal.openLinkModal = openLinkModal

  $scope.$on('$destroy', onDestroy)

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SOURCES.EVT_SOURCES_UPDATED,
      _onSourcesUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_TIDAL_LINK_CHANGED,
      _onLinkChanged
    ))
    _listeners.push($rootScope.$on(
      BAS_CORE.EVT_CORE_VERSION,
      _onCoreVersion
    ))

    _onCoreVersion()

    Sources.registerFor(BAS_SOURCE.COL_EVT_TIDAL)

    allTidalLinked()
  }

  /**
   * Checks all Tidal sources for their link status
   */
  function allTidalLinked () {

    var sources, keys, i, length, source

    sources = CurrentBasCore.hasAVFullSupport()
      ? BAS_SOURCES.SOURCES.audioSources
      : BAS_SOURCES.SOURCES.players
    keys = Object.keys(sources)
    length = keys.length
    for (i = 0; i < length; i++) {

      source = BAS_SOURCES.SOURCES.sources[sources[keys[i]]]

      if (
        source &&
        (
          source.type === BAS_SOURCE.T_PLAYER ||
          source.type === BAS_SOURCE.T_ASANO
        ) &&
        source.tidal
      ) {

        source.tidal.linked().then(_onLinkChanged)
      }
    }
  }

  /**
   * @param {string} sourceId
   */
  function openLinkModal (sourceId) {

    var basSource = _getBasSource()

    if (basSource) {
      basSource.tidal.linked()
        .then(_onTidalLinked, _onTidalLinkedError)
    }

    function _onTidalLinked (result) {

      return ModalService.showModal({
        template: BAS_HTML.streamingServiceSettingsModal,
        controller: 'streamingServiceSettingsModalCtrl',
        controllerAs: 'modal',
        inputs: {
          sourceName: basSource.name,
          streamingServiceTransId: 'tidal',
          linkedName: result ? basSource.tidal.longLink : '',
          unlink: unlink,
          link: link
        }
      })
    }

    function unlink () {

      var basSourceToLogout = _getBasSource()

      ModalService.closeModals()

      if (
        basSourceToLogout &&
        basSourceToLogout.tidal
      ) {

        basSourceToLogout.tidal.unlink()
      }
    }

    function link () {

      var basSourceToLink = _getBasSource()

      ModalService.closeModals()

      if (
        basSourceToLink &&
        basSourceToLink.tidal
      ) {

        if (CurrentBasCore.hasCore()) {

          if (
            (
              CurrentBasCore.hasAVPartialSupport() ||
              CurrentBasCore.hasAVFullSupport()
            ) ||
            currentBasCoreState.core.core.system.supportsTidalOAuth
          ) {

            if (
              basSourceToLink &&
              basSourceToLink.tidal
            ) {

              basSourceToLink.tidal.getLinkUrl().then(_onLinkUrl)
            }
          } else {

            ModalService.showModal({
              template: BAS_HTML.loginModal,
              controller: 'loginModalCtrl',
              controllerAs: 'modal',
              inputs: {
                playerId: basSourceToLink.id
              }
            })
          }
        }
      }

    }

    function _onTidalLinkedError () {

      BasModal.show(BAS_MODAL.T_TIDAL_OAUTH_ERROR)
    }

    function _onLinkUrl (url) {

      var _basSource

      if (BasUtil.isNEString(url)) {

        _basSource = _getBasSource()

        if (_basSource) _setTidalListenersFor(_basSource)

        _closeInAppBrowser()
        _inAppBrowser = new BasInAppBrowser(url)
      }
    }

    function _getBasSource () {

      var _basSource = BAS_SOURCES.SOURCES.sources[sourceId]

      if (
        _basSource &&
        (
          _basSource.type === BAS_SOURCE.T_PLAYER ||
          _basSource.type === BAS_SOURCE.T_ASANO
        ) &&
        _basSource.tidal) {

        return _basSource
      }

      return null
    }
  }

  function _onSourcesUpdated () {

    _clearTidalListeners()
    allTidalLinked()
  }

  function _onLinkChanged () {

    ModalService.closeModals()

    $scope.$applyAsync()
  }

  function _closeInAppBrowser () {

    if (_inAppBrowser && _inAppBrowser.closeInAppBrowser) {

      _inAppBrowser.closeInAppBrowser()
      _inAppBrowser = null
    }
  }

  /**
   * @private
   * @param {BasSource} basSource
   */
  function _setTidalListenersFor (basSource) {

    _tidalSourceId = basSource.getId()
    _setTidalListeners()
  }

  function _setTidalListeners () {

    var basSource

    basSource = SourcesHelper.getBasSource(_tidalSourceId)

    _removeTidalListeners()

    if (basSource) {

      if (basSource.isAudioSource) {

        _tidalAudioSourceListeners.push(BasUtil.setEventListener(
          basSource.source,
          BAS_API.AudioSource.EVT_STREAMING_SERVICE_LINK_FINISHED,
          _onAudioSourceLinkFinished
        ))

      } else {

        if (
          basSource.source &&
          basSource.source.tidal
        ) {

          _tidalListeners.push(BasUtil.setEventListener(
            basSource.source.tidal,
            BAS_API.Tidal.EVT_LINK_FINISHED,
            _onLinkFinished
          ))

          _tidalListeners.push(BasUtil.setEventListener(
            basSource.source.tidal,
            BAS_API.Tidal.EVT_LINK_ERROR,
            _onLinkError
          ))
        }
      }
    }
  }

  function _onAudioSourceLinkFinished (data) {

    if (data.streamingService === BAS_API.AudioSource.A_SS_TIDAL) {

      _removeTidalListeners()

      // Don't close inappbrowser, succes page will close itself after 5 seconds

      if (data.error) {

        _closeInAppBrowser()

        BasModal.show(BAS_MODAL.T_TIDAL_OAUTH_ERROR)
      }
    }
  }

  function _onLinkFinished () {

    _removeTidalListeners()

    // Don't close inappbrowser, succes page will close itself after 5 seconds
  }

  function _onLinkError (_error) {

    _removeTidalListeners()

    _closeInAppBrowser()

    BasModal.show(BAS_MODAL.T_TIDAL_OAUTH_ERROR)
  }

  function _removeTidalListeners () {

    _tidalSourceId = -1
    _clearTidalListeners()
  }

  function _clearTidalListeners () {

    BasUtil.executeArray(_tidalAudioSourceListeners)
    _tidalAudioSourceListeners = []

    BasUtil.executeArray(_tidalListeners)
    _tidalListeners = []
  }

  function _onCoreVersion () {

    tidal.serverOutdated = CurrentBasCore.hasCore()
      ? (
          !tidal.currentBasCoreState.core.core.supportsTidalOAuth &&
          !CurrentBasCore.hasAVFullSupport()
        )
      : true
  }

  function onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []

    Sources.unregisterFor(BAS_SOURCE.COL_EVT_TIDAL)

    ModalService.closeModals()
  }
}
