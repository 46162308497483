'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('roomsCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_HTML',
    'BAS_STATE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_SOURCE',
    'BAS_INPUT_MODAL',
    'BAS_MODAL',
    'BAS_API',
    'CurrentBasCore',
    'Rooms',
    'RoomsHelper',
    'Sources',
    'CurrentRoom',
    'BasRoomsHelper',
    'ModalService',
    'modalHelperService',
    'BasAppDevice',
    'BasSip',
    'BasInputModal',
    'BasModal',
    roomsCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param {BAS_MODAL} BAS_MODAL
 * @param BAS_API
 * @param {CurrentBasCore} CurrentBasCore
 * @param {Rooms} Rooms
 * @param {RoomsHelper} RoomsHelper
 * @param {Sources} Sources
 * @param {CurrentRoom} CurrentRoom
 * @param {BasRoomsHelper} BasRoomsHelper
 * @param ModalService
 * @param modalHelperService
 * @param {BasAppDevice} BasAppDevice
 * @param {BasSip} BasSip
 * @param {BasInputModal} BasInputModal
 * @param {BasModal} BasModal
 */
function roomsCtrl (
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_HTML,
  BAS_STATE,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_SOURCE,
  BAS_INPUT_MODAL,
  BAS_MODAL,
  BAS_API,
  CurrentBasCore,
  Rooms,
  RoomsHelper,
  Sources,
  CurrentRoom,
  BasRoomsHelper,
  ModalService,
  modalHelperService,
  BasAppDevice,
  BasSip,
  BasInputModal,
  BasModal
) {
  var rooms = this

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BAS_ROOMS}
   */
  rooms.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {BasRooms}
   */
  rooms.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasRoomControl}
   */
  rooms.roomsControl = Rooms.getControl()

  /**
   * Key for which BasRoomComponent
   *
   * @type {string}
   */
  rooms.brck = ''

  rooms.toggleRoomView = toggleRoomView
  rooms.showRoomMenu = showRoomMenu
  rooms.selectRoom = selectRoom
  rooms.longPressRoom = longPressRoom
  rooms.selectMusicZone = selectMusicZone
  rooms.selectVideo = selectVideo
  rooms.selectThermostat = selectThermostat
  rooms.selectIntercom = selectIntercom
  rooms.join = join
  rooms.openAvRoomSelect = openAvRoomSelect
  rooms.toggleRoomMusic = toggleRoomMusic
  rooms.toggleRoomVideo = toggleRoomVideo

  // noinspection JSUnusedGlobalSymbols
  /**
   * Angular UI-router callback
   */
  rooms.uiOnParamsChanged = onParamsChanged

  init()

  function init () {

    _syncListeners()
    _setRoomView($uiRouterGlobals.params)

    $scope.$on('$destroy', onDestroy)
  }

  function onParamsChanged (params) {

    _syncListeners()
    _setRoomView(params)
  }

  function _setRoomView (params) {

    if (params) {

      switch (params.view) {
        case BAS_STATE.S_ROOMS_VIEW_MUSIC:
          rooms.brck = BAS_ROOMS.BC_MUSIC
          break
        case BAS_STATE.S_ROOMS_VIEW_THERMOSTATS:
          rooms.brck = BAS_ROOMS.BC_THERMOSTATS
          break
        case BAS_STATE.S_ROOMS_VIEW_INTERCOM:
          rooms.brck = BAS_ROOMS.BC_INTERCOM
          break
        case BAS_STATE.S_ROOMS_VIEW_HOME:
        default:
          rooms.brck = BAS_ROOMS.BC_HOME
      }

    } else {

      rooms.brck = BAS_ROOMS.BC_HOME
    }
  }

  function showRoomMenu (event) {

    ModalService.showModal({
      template: BAS_HTML.roomTypesModal,
      controller: 'roomTypesModalCtrl',
      controllerAs: 'modal',
      inputs: {
        event: event
      }
    }).then(onMainModal)

    function onMainModal (modal) {

      // Modal is ready, DOM manipulation is allowed
      if (modal.controller &&
        modal.controller.onModalReady &&
        BasUtil.isFunction(modal.controller.onModalReady)) {

        modal.controller.onModalReady()
      }

      // Set Promises
      modal.close.then(onMainModalClose)
      modal.closed.then(onMainModalClosed)
    }

    function onMainModalClose (view) {

      if (BasUtil.isNEString(view)) {

        CurrentRoom.go(STATES.ROOMS, { view: view })
      }
    }

    function onMainModalClosed () {

      // Reset modal height
      modalHelperService.resetModalStyle()
    }
  }

  function toggleRoomView () {

    switch (rooms.brck) {
      case BAS_ROOMS.BC_MUSIC:
        CurrentRoom.go(
          STATES.ROOMS,
          {
            view: BAS_STATE.S_ROOMS_VIEW_HOME
          }
        )
        break
      default:
        CurrentRoom.go(
          STATES.ROOMS,
          {
            view: BAS_STATE.S_ROOMS_VIEW_MUSIC
          }
        )
    }
  }

  /**
   * Select a room
   *
   * @param {string} roomId
   */
  function selectRoom (roomId) {

    var basRoom

    basRoom = RoomsHelper.getRoomForId(roomId)

    if (basRoom) {

      CurrentRoom.go(
        STATES.ROOM,
        {
          room: roomId
        }
      )
    }
  }

  /**
   * Long press a room
   *
   * @param {string} roomId
   */
  function longPressRoom (roomId) {

    var basRoom

    if (
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.supportsCustomRoomImages &&
      currentBasCoreState.core.core.profile &&
      currentBasCoreState.core.core.profile.admin &&
      BasAppDevice.supportsCustomImageUpload()
    ) {
      basRoom = RoomsHelper.getRoomForId(roomId)

      if (basRoom) {

        ModalService.showModal({
          controller: 'roomImagePickerModalCtrl',
          controllerAs: 'modal',
          inputs: {
            roomId: basRoom.id
          },
          template: BAS_HTML.roomImagePickerModal
        })
      }

    } else {

      selectRoom(roomId)
    }
  }

  /**
   * Select a music zone
   *
   * @param {string} roomId
   */
  function selectMusicZone (roomId) {

    BasRoomsHelper.goToMusic(roomId)
  }

  /**
   * Select a video room
   *
   * @param {string} roomId
   */
  function selectVideo (roomId) {

    BasRoomsHelper.goToVideo(roomId)
  }

  /**
   * Select a thermostat
   *
   * @param {string} roomId
   * @param {string} thermostatId
   */
  function selectThermostat (roomId, thermostatId) {

    var basRoom

    basRoom = RoomsHelper.getRoomForId(roomId)

    if (basRoom) {
      if (basRoom.room?.thermostats?.includes(thermostatId)) {

        CurrentRoom.go(
          STATES.THERMOSTAT,
          {
            room: roomId,
            thermostat: thermostatId
          }
        )
      }
    }
  }

  /**
   * Select a intercom
   *
   * @param {string} roomId
   */
  function selectIntercom (roomId) {

    const basRoom = RoomsHelper.getRoomForId(roomId)

    if (basRoom) BasSip.callRoom(basRoom)
  }

  /**
   * Joins a room with the current playing source
   *
   * @param {string} roomId
   */
  function join (roomId) {

    var currentRoom, currentRoomMusic, basRoom, source

    currentRoom = CurrentRoom.getRoom()
    currentRoomMusic = CurrentRoom.getRoomMusic()
    basRoom = RoomsHelper.getRoomForId(roomId)

    if (
      currentRoom &&
      currentRoomMusic &&
      basRoom &&
      basRoom.music &&
      basRoom.music.volumeChange &&
      currentRoom.id !== basRoom.id
    ) {

      source = currentRoom.music.basSource

      if (
        source &&
        source.play && (
          BasUtil.isPNumber(source.id) ||
          BasUtil.isNEString(source.uuid)
        )
      ) {
        basRoom.music.setSource(source.getId())
      }
    }
  }

  /**
   * Joins a room with the current playing source
   * Opens a modal in which you can select av rooms to use the same source
   * as the current av source for this room.
   *
   * @param {string} roomId
   */
  function openAvRoomSelect (roomId) {

    var basRoom, basSource

    basRoom = RoomsHelper.getRoomForId(roomId)

    if (
      basRoom &&
      basRoom.hasAV() &&
      basRoom.music &&
      basRoom.music.basSource &&
      basRoom.music.basSource.compatibleRooms.length > 1
    ) {

      basSource = basRoom.music.basSource

      if (
        basSource &&
        basSource.groupable &&
        BasUtil.isNEString(basSource.uuid)
      ) {
        BasInputModal.show(
          BAS_INPUT_MODAL.T_AVROOM_SELECT,
          {
            roomId: roomId
          }
        )
      }
    }
  }

  /**
   * Toggles music for a given room
   *
   * @param {string} roomId
   */
  function toggleRoomMusic (roomId) {

    var room

    room = RoomsHelper.getRoomForId(roomId)

    if (room && room.music && room.music.toggle) {

      room.music.toggle().catch(onToggleError)
    }

    function onToggleError (error) {

      switch (error) {

        case BAS_API.CONSTANTS.ERR_QUEUE_EMPTY:

          BasModal.show(BAS_MODAL.T_EMPTY_QUEUE).then(onEmptyQueueModal)
          break

        case BAS_API.CONSTANTS.ERR_UNREACHABLE:

          if (room.music) {

            switch (room.music.type) {

              case BAS_ROOM.MUSIC_T_SONOS:
                BasModal.show(BAS_MODAL.T_SONOS_UNREACHABLE)
                break

              default:
                BasModal.show(BAS_MODAL.T_GENERIC_UNREACHABLE)
                break
            }

          } else {

            BasModal.show(BAS_MODAL.T_GENERIC_UNREACHABLE)
            break
          }

          break

        default:

          BasModal.show(BAS_MODAL.T_ERROR)
      }
    }

    function onEmptyQueueModal (modal) {

      modal.close.then(onEmptyQueueModalClose)
    }

    function onEmptyQueueModalClose (result) {

      room = RoomsHelper.getRoomForId(roomId)

      if (room && room.music && room.music.toggle) {

        if (result === BAS_MODAL.C_YES) {

          CurrentRoom.go(
            STATES.MUSIC_FAVOURITES,
            {
              room: roomId
            }
          )
        }
      }
    }
  }

  /**
   * Toggles video for a given room
   *
   * @param {string} roomId
   */
  function toggleRoomVideo (roomId) {

    var room

    room = RoomsHelper.getRoomForId(roomId)

    if (room && room.video && room.video.toggle) {

      room.video.toggle().catch(onToggleError)
    }

    function onToggleError () {

      // Empty
    }
  }

  function _syncListeners () {

    var viewParam

    viewParam = $uiRouterGlobals.params.view

    switch (viewParam) {
      case BAS_STATE.S_ROOMS_VIEW_MUSIC:
        Sources.registerFor(BAS_SOURCE.COL_EVT_SIMPLE)
        break
      case BAS_STATE.S_ROOMS_VIEW_THERMOSTATS:
      case BAS_STATE.S_ROOMS_VIEW_INTERCOM:
      case BAS_STATE.S_ROOMS_VIEW_HOME:
      default:
        Sources.unregisterFor(BAS_SOURCE.COL_EVT_SIMPLE)
    }
  }

  function onDestroy () {

    Sources.unregisterFor(BAS_SOURCE.COL_EVT_SIMPLE)
  }
}
