'use strict'

angular
  .module('basalteApp')
  .service('MainState', [
    '$rootScope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_CURRENT_CORE',
    'CurrentBasCore',
    'CurrentRoom',
    MainState
  ])

/**
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 */
function MainState (
  $rootScope,
  $uiRouterGlobals,
  STATES,
  BAS_CURRENT_CORE,
  CurrentBasCore,
  CurrentRoom
) {
  $rootScope.$on(
    BAS_CURRENT_CORE.EVT_CORE_PROFILE_CREATED,
    _onProfileCreated
  )

  function _onProfileCreated () {

    var currentState

    currentState = $uiRouterGlobals.current.name

    if (currentState === STATES.HOME) {

      if (!CurrentBasCore.hasHomePage()) {

        CurrentRoom.go(STATES.ROOMS)
      }
    }
  }
}
