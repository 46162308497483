'use strict'

angular
  .module('basalteApp')
  .controller('shadesCtrl', [
    'BAS_ROOMS',
    'CurrentRoom',
    shadesCtrl
  ])

/**
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 */
function shadesCtrl (
  BAS_ROOMS,
  CurrentRoom
) {
  var shades = this

  /**
   * @type {BasRooms}
   */
  shades.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  shades.currentRoom = CurrentRoom.get()
}
