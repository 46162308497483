'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basGenericDevice', [
    'BAS_HTML',
    'BAS_GENERIC_DEVICE',
    BasGenericDevice
  ])

function BasGenericDevice (BAS_HTML, BAS_GENERIC_DEVICE) {

  return {
    restrict: 'EA',
    bindToController: {
      device: '<'
    },
    template: BAS_HTML.genericDevice,
    controller: [
      'BasInputModal',
      'BAS_API',
      'BAS_INPUT_MODAL',
      genericDevicesCtrl
    ],
    controllerAs: 'genericDevice'
  }

  /**
   * @param {BasInputModal} BasInputModal
   * @param BAS_API
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   */
  function genericDevicesCtrl (
    BasInputModal,
    BAS_API,
    BAS_INPUT_MODAL
  ) {

    var genericDevice = this

    genericDevice.BAS_GENERIC_DEVICE = BAS_GENERIC_DEVICE

    genericDevice.editControlValue = editControlValue

    function editControlValue (device, control) {

      if (
        control &&
        control.type === BAS_API.GenericDeviceControl.T_NUMERIC_INPUT
      ) {

        BasInputModal.show(
          BAS_INPUT_MODAL.T_NUMBER,
          {
            title: control.name,
            subTitle: device.name,
            min: control.min,
            max: control.max,
            step: control.step,
            validCheck: _isValueValid
          }
        ).then(_onModal)
      }

      function _onModal (modal) {

        modal.close.then(_onModalClose)
      }

      function _onModalClose (result) {

        var number

        if (BasUtil.isNEString(result)) {

          number = parseFloat(result)

          if (!isNaN(number)) {

            control.setValue(number)
          }
        }
      }

      /**
       * @private
       * @param value
       * @returns {boolean}
       */
      function _isValueValid (value) {

        var number

        number = parseFloat(value)

        return (
          BasUtil.isVNumber(number) &&
          number >= control.min &&
          number <= control.max
        )
      }
    }
  }
}
