'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basDeviceCamera', ['basDevicePermissions'])
  .service('BasDeviceCamera', [
    '$window',
    'BasDevicePermissions',
    BasDeviceCamera
  ])

/**
 * @typedef {Object} TDeviceCameraOptions
 * @property {boolean} cameraSrc
 * @property {?Object} event
 */

/**
 * @constructor
 * @param $window
 * @param {BasDevicePermissions} BasDevicePermissions
 */
function BasDeviceCamera (
  $window,
  BasDevicePermissions
) {
  var ERR_NO_PLUGIN = 'No camera plugin'
  var ERR_NO_PERMISSION = 'No permission'
  var ERR_INVALID_RESULT = 'Invalid result'

  var HEIGHT_IOS_POPUP_OFFSET = 25

  var _latestClickElement = null

  this.getPicture = getPicture

  _setOrientationListener()

  /**
   * Get picture from camera or album
   *
   * @param callback
   * @param {string} platform
   * @param {TDeviceCameraOptions} [options]
   */
  function getPicture (callback, platform, options) {

    var basCamera, cameraSrc, deviceCameraPermissions

    deviceCameraPermissions = false
    cameraSrc = true

    if (BasUtil.isFunction(callback)) {

      basCamera = _getCameraPlugin()

      if (basCamera) {

        if (options &&
          BasUtil.isBool(options.cameraSrc)) {

          cameraSrc = options.cameraSrc
        }

        BasDevicePermissions.requestBasDeviceCameraPermissions(
          _permissionCheckCallback,
          cameraSrc,
          false,
          platform
        )

      } else {

        _callback(new Error(ERR_NO_PLUGIN))
      }
    }

    /**
     * This is the callback for checking permissions
     * Only checking, no user interaction
     *
     * @private
     * @param error
     * @param success
     */
    function _permissionCheckCallback (error, success) {

      if (BasUtil.isBool(success)) {

        deviceCameraPermissions = success

        if (!deviceCameraPermissions) {

          _requestPermissions()

        } else {

          _permissionCallback(null, true)
        }

      } else {

        _permissionCallback(error || new Error(ERR_INVALID_RESULT))
      }
    }

    function _requestPermissions () {

      BasDevicePermissions.requestBasDeviceCameraPermissions(
        _permissionCallback,
        cameraSrc,
        true,
        platform
      )
    }

    /**
     * This is the callback for requesting permissions
     * User interaction needed
     *
     * @private
     * @param error
     * @param success
     */
    function _permissionCallback (error, success) {

      if (BasUtil.isBool(success)) {

        deviceCameraPermissions = success

      } else {

        _callback(error || new Error(ERR_INVALID_RESULT))
      }

      if (deviceCameraPermissions) {

        _getPicture()

      } else {

        _callback(new Error(ERR_NO_PERMISSION))
      }
    }

    function _getPicture () {

      var rect

      if (deviceCameraPermissions) {

        if (options &&
          options.event &&
          options.event.target &&
          $window.CameraPopoverOptions) {

          _latestClickElement = options.event.target

          rect = _latestClickElement
            ? _latestClickElement.getBoundingClientRect()
            : null
        }

        basCamera.getPicture(
          _successCallback,
          _errorCallback,
          {
            destinationType: basCamera.DestinationType.DATA_URL,
            sourceType: cameraSrc
              ? basCamera.PictureSourceType.CAMERA
              : basCamera.PictureSourceType.PHOTOLIBRARY,
            direction: basCamera.Direction.BACK,
            mediaType: basCamera.MediaType.PICTURE,
            correctOrientation: true,
            popoverOptions: rect
              ? new $window.CameraPopoverOptions(
                rect.left,
                rect.top - rect.height - HEIGHT_IOS_POPUP_OFFSET,
                rect.width,
                rect.height,
                basCamera.PopoverArrowDirection.ARROW_UP
              )
              : {}
          }
        )

      } else {

        _callback(new Error(ERR_NO_PERMISSION))
      }
    }

    /**
     * @private
     * @param result
     */
    function _successCallback (result) {

      _latestClickElement = null

      if (BasUtil.isNEString(result)) {

        _callback(null, result)

      } else {

        _callback(new Error(ERR_INVALID_RESULT))
      }
    }

    /**
     * @private
     * @param error
     */
    function _errorCallback (error) {

      _latestClickElement = null

      _callback(error)
    }

    function _callback (error, result) {

      if (BasUtil.isFunction(callback)) {

        callback(error, result)
      }

      basCamera.cleanup()
    }
  }

  function _setOrientationListener () {

    $window.onorientationchange = function () {

      var cameraPopoverHandle, cameraPopoverOptions, rect, basCamera

      basCamera = _getCameraPlugin()

      if (basCamera &&
        _latestClickElement &&
        $window.CameraPopoverHandle &&
        $window.CameraPopoverOptions) {

        rect = _latestClickElement
          ? _latestClickElement.getBoundingClientRect()
          : null

        cameraPopoverHandle = new $window.CameraPopoverHandle()

        cameraPopoverOptions = new $window.CameraPopoverOptions(
          rect.left,
          rect.top - rect.height - HEIGHT_IOS_POPUP_OFFSET,
          rect.width,
          rect.height,
          basCamera.PopoverArrowDirection.ARROW_UP
        )

        cameraPopoverHandle.setPosition(cameraPopoverOptions)
      }
    }
  }

  function _getCameraPlugin () {

    if ($window['navigator'] &&
      $window['navigator']['camera']) {

      return $window['navigator']['camera']
    }

    return null
  }
}
