'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('basContextModalCtrl', [
    '$rootScope',
    '$scope',
    'BAS_SPLASH',
    'modalHelperService',
    'basModalConfig',
    'event',
    'close',
    basContextModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param modalHelperService
 * @param {TBasContextModalConfig} basModalConfig
 * @param event
 * @param close
 */
function basContextModalCtrl (
  $rootScope,
  $scope,
  BAS_SPLASH,
  modalHelperService,
  basModalConfig,
  event,
  close
) {
  var modal = this

  // Display variables - Button Target HTML element class
  var basButtonClass = 'modal-target'

  var _listeners = []

  // Set modal style
  modal.style = modalHelperService.getModalStyle()

  /**
   * @type {TBasContextModalConfig}
   */
  modal.cfg = basModalConfig

  modal.close = close
  modal.onModalReady = onModalReady

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)

    modalHelperService.calcPosition(
      event,
      basButtonClass,
      BasUtil.isVNumber(modal.cfg.positionType)
        ? modal.cfg.positionType
        : modalHelperService.type.verticalRight
    )
  }

  function onModalReady () {

    setTimeout(_onModalReadyTimeout, modalHelperService.modalReadyMs)
  }

  function _onModalReadyTimeout () {

    modalHelperService.calcHeight(1, modal.cfg.options.length)
    $scope.$applyAsync()
  }

  function _onSplashVisibility () {

    _close()
  }

  /**
   * @private
   * @param {*} [reason]
   * @param {number} [delayMs]
   */
  function _close (reason, delayMs) {

    if (BasUtil.isFunction(close)) close(reason, delayMs)
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
