'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basRemoteJs', [])
  .provider('BasRemoteJs', [
    '$windowProvider',
    BasRemoteJsProvider
  ])
  .constant('BAS_REMOTE_JS', {
    EVT_BAS_REMOTE_JS_STATE_CHANGED: 'evtBasRemoteJsStateChanged',
    BAS_REMOTE_JS_URL: 'https://l4gwglgejbvgcexl3c6ca2rqfm0nxxsg.lambda-url.eu-west-1.on.aws/',
    K_ATTRIBUTES: 'attributes',
    K_SRC: 'src',
    K_CODE: 'code'
  })

/**
 * @typedef {Object} TBasRemoteJsState
 * @property {?Promise} enableRemoteJsPromise
 * @property {string} code
 * @property {?Element} scriptEl
 */

/**
 * @param $windowProvider
 */
function BasRemoteJsProvider ($windowProvider) {

  var basWindow, state

  /**
   * @type {Window}
   */
  basWindow = $windowProvider.$get()

  /**
   * @type {TBasRemoteJsState}
   */
  state = {}
  state.enableRemoteJsPromise = null
  state.code = ''
  state.scriptEl = null

  this.$get = ['$injector', BasRemoteJsFactory]

  // Factory

  function BasRemoteJsFactory ($injector) {
    return $injector.instantiate([
      '$rootScope',
      '$http',
      'BAS_REMOTE_JS',
      BasRemoteJs
    ])
  }

  // Service

  /**
   * @constructor
   * @param $rootScope
   * @param $http
   * @param {BAS_REMOTE_JS} BAS_REMOTE_JS
   */
  function BasRemoteJs (
    $rootScope,
    $http,
    BAS_REMOTE_JS
  ) {
    this.get = get
    this.enableRemoteJs = enableRemoteJs
    this.forceEnableRemoteJs = forceEnableRemoteJs
    this.disableRemoteJs = disableRemoteJs

    /**
     * @returns {TBasRemoteJsState}
     */
    function get () {
      return state
    }

    function enableRemoteJs () {

      if (state.enableRemoteJsPromise) return state.enableRemoteJsPromise

      state.enableRemoteJsPromise = forceEnableRemoteJs()

      return state.enableRemoteJsPromise
    }

    /**
     * @returns {Promise}
     */
    function forceEnableRemoteJs () {

      return $http({
        method: 'GET',
        url: BAS_REMOTE_JS.BAS_REMOTE_JS_URL
      }).then(_onBasRemoteJsRequest)

      function _onBasRemoteJsRequest (result) {

        var obj, src, code, attrs

        if (result && result.data) {

          obj = result.data
          src = obj[BAS_REMOTE_JS.K_SRC]
          code = obj[BAS_REMOTE_JS.K_CODE]
          attrs = obj[BAS_REMOTE_JS.K_ATTRIBUTES]

          if (BasUtil.isNEString(src) && BasUtil.isNEString(code) && attrs) {

            _removeScript(state.scriptEl)

            state.code = code
            state.scriptEl = _addScript(src, attrs)

            $rootScope.$emit(
              BAS_REMOTE_JS.EVT_BAS_REMOTE_JS_STATE_CHANGED,
              state
            )
          }
        }
      }
    }

    function disableRemoteJs () {

      var length, i, sock, parsedUrl

      // Remove "agentId" from sessionStorage

      if (sessionStorage && sessionStorage.removeItem) {

        // RemoteJs key
        sessionStorage.removeItem('agentId')
      }

      // Remove script element from <head>

      _removeScript(state.scriptEl)
      state.scriptEl = null
      state.code = ''

      // Close RemoteJs WebSocket

      // TODO Find better way to stop RemoteJs
      // Closing the WebSocket does not work because RemoteJs will reconnect

      if (
        basWindow &&
        basWindow.basRemoteJs &&
        Array.isArray(basWindow.basRemoteJs.sockets)
      ) {
        length = basWindow.basRemoteJs.sockets.length
        for (i = 0; i < length; i++) {

          sock = basWindow.basRemoteJs.sockets[i]

          if (sock && sock.socket && sock.url) {

            parsedUrl = parseUrl(sock.url)

            if (parsedUrl && parsedUrl.host === 'remotejs.com') {

              try {
                sock.socket.close()
              } catch (_e) {
                // Ignored
              }
            }
          }
        }
      }
    }

    function _addScript (src, attrs) {
      var scriptEl, keys, length, i, attrName, attrValue

      scriptEl = document.createElement('script')
      scriptEl.src = src

      if (attrs) {

        keys = Object.keys(attrs)
        length = keys.length
        for (i = 0; i < length; i++) {

          attrName = keys[i]
          attrValue = attrs[attrName]

          scriptEl.setAttribute(attrName, attrValue)
        }
      }

      try {
        document.head.appendChild(scriptEl)
      } catch (_e) {
        return null
      }

      return scriptEl
    }

    function _removeScript (scriptEl) {

      try {
        document.head.removeChild(scriptEl)
      } catch (_e) {
        // Ignore
      }
    }

    /**
     * Properties
     *
     * parser.protocol; => 'http:'
     * parser.host;     => 'example.com:3000'
     * parser.pathname; => '/pathname/'
     * parser.search;   => '?search=test'
     *
     * All properties can be found here:
     * https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement
     *
     * @param {string} url
     * @returns {TParsedUrl}
     */
    function parseUrl (url) {

      var parser, parsed

      parser = document.createElement('a')
      parser.href = url

      parsed = {}

      parsed.protocol = parser.protocol
      parsed.host = parser.host
      parsed.pathname = parser.pathname
      parsed.search = parser.search

      return parsed
    }
  }
}
