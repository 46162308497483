'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceBasLive', [
    'BAS_HTML',
    deviceBasLiveDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns deviceBasLive
 */
function deviceBasLiveDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.basLiveSettings,
    controller: [
      '$rootScope',
      '$scope',
      'BAS_MODAL',
      'STATES',
      'BAS_CURRENT_CORE',
      'BAS_LIVE_ACCOUNT',
      'BasAppDevice',
      'CurrentBasCore',
      'BasModal',
      'BasLiveAccount',
      'BasState',
      controller
    ],
    controllerAs: 'basLive'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_MODAL} BAS_MODAL
   * @param {STATES} STATES
   * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
   * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
   * @param {BasAppDevice} BasAppDevice
   * @param {CurrentBasCore} CurrentBasCore
   * @param {BasModal} BasModal
   * @param {BasLiveAccount} BasLiveAccount
   * @param {BasState} BasState
   */
  function controller (
    $rootScope,
    $scope,
    BAS_MODAL,
    STATES,
    BAS_CURRENT_CORE,
    BAS_LIVE_ACCOUNT,
    BasAppDevice,
    CurrentBasCore,
    BasModal,
    BasLiveAccount,
    BasState
  ) {
    var basLive = this

    var _listeners = []

    /**
     * @type {STATES}
     */
    basLive.STATES = STATES

    /**
     * @type {BasAppDeviceState}
     */
    basLive.basAppDeviceState = BasAppDevice.get()

    /**
     * @type {TBasLiveAccountState}
     */
    basLive.basLiveAccountState = BasLiveAccount.get()

    /**
     * @type {TCurrentBasCoreState}
     */
    basLive.currentCoreState = CurrentBasCore.get()

    basLive.goToLiveState = goToLiveState

    basLive.logOut = logOut

    basLive.liveRedirectTitle = ''

    basLive.$onDestroy = _destroy

    init()

    function init () {

      _listeners.push($rootScope.$on(
        BAS_LIVE_ACCOUNT.EVT_AUTH_STATE_CHECKED,
        _syncScope
      ))
      _listeners.push($rootScope.$on(
        BAS_LIVE_ACCOUNT.EVT_ACCOUNT_NAME_UPDATED,
        _syncScope
      ))
      _listeners.push($rootScope.$on(
        BAS_LIVE_ACCOUNT.EVT_PROJECTS_UPDATED,
        _syncScope
      ))
      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
        _syncScope
      ))

      if (!basLive.basAppDeviceState.isProLiveHosted) {

        BasLiveAccount.registerForProjectStatus(basLive)
      }
    }

    function goToLiveState () {

      if (basLive.basLiveAccountState.isLoggedIn) {

        BasLiveAccount.openManageWindow()

      } else {

        BasModal.show(BAS_MODAL.T_ABOUT_TO_GO_TO_LIVE_LOGIN)
          .then(_onAboutToGoToLiveModal)
      }
    }

    function logOut () {

      if (basLive.basLiveAccountState.isLoggedIn) {

        BasLiveAccount.showLogoutModal()
      }
    }

    function _onAboutToGoToLiveModal (modal) {

      modal.closed.then(_onAboutToGoToLiveModalClosed)
    }

    function _onAboutToGoToLiveModalClosed (result) {

      if (result === BAS_MODAL.C_YES) {

        BasState.go(
          STATES.CONNECT_LIVE,
          null,
          {
            custom: {
              BasExitMainState: true
            }
          }
        )
      }
    }

    function _syncScope () {

      $scope.$applyAsync()
    }

    function _destroy () {

      BasUtil.executeArray(_listeners)
      _listeners = null

      BasLiveAccount.unregisterForProjectStatus(basLive)
    }
  }
}
