'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLiveProject', basLiveProjectFactory)

function basLiveProjectFactory () {

  var CSS_PROJECT_ONLINE = 'bas-project--is-online'
  var CSS_PROJECT_INTEGRATOR_ACCESS = 'bas-project--integrator-access-granted'

  /**
   * @constructor
   * @param {TBasRemoteCore} project
   */
  function BasLiveProject (project) {

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {?string}
     */
    this.name = ''

    /**
     * @type {boolean}
     */
    this.online = false

    /**
     * @type {boolean}
     */
    this.integratorAccess = false

    /**
     * @type {string}
     */
    this.uiName = '-'

    /**
     * @type {?string}
     */
    this.city = ''

    /**
     * @type {?string}
     */
    this.country = ''

    /**
     * @type {string}
     */
    this.uiLocation = '-'

    /**
     * @type {?Object}
     */
    this.images = null

    /**
     * @type {Object}
     */
    this.css = {}

    if (project) this.parse(project)
  }

  /**
   * @param {TBasRemoteCore} project
   */
  BasLiveProject.prototype.parse = function (project) {

    var length, i, image

    if (BasUtil.isObject(project)) {

      this.uuid = project.uuid
      this.name = project.name
      this.online = project.online
      this.integratorAccess = project.integratorAccess

      if (project.metadata &&
        project.metadata.location) {

        this.city =
          BasUtil.isNEString(project.metadata.location.city)
            ? project.metadata.location.city
            : ''

        this.country =
          BasUtil.isNEString(project.metadata.location.country)
            ? project.metadata.location.country
            : ''
      }

      if (BasUtil.isNEArray(project.images)) {

        this.images = {}

        length = project.images.length
        for (i = 0; i < length; i++) {

          image = project.images[i]

          this.images[image.variant] = image.url
        }
      }
    }

    this._generateUi()
  }

  BasLiveProject.prototype._generateUi = function () {

    this._resetCss()

    this.uiName = BasUtil.isNEString(this.name) ? this.name : '-'

    this.uiLocation = BasUtil.isNEString(this.city) ? this.city : '-'

    this.css[CSS_PROJECT_ONLINE] = this.online
    this.css[CSS_PROJECT_INTEGRATOR_ACCESS] = this.integratorAccess
  }

  BasLiveProject.prototype.setOnlineState = function (online) {

    if (!BasUtil.isBool(online)) return

    this.online = online

    this.css[CSS_PROJECT_ONLINE] = this.online
  }

  /**
   * @param {boolean} granted
   */
  BasLiveProject.prototype.setIntegratorAccessState = function (
    granted
  ) {
    if (!BasUtil.isBool(granted)) return
    this.integratorAccess = granted
    this.css[CSS_PROJECT_INTEGRATOR_ACCESS] = this.integratorAccess
  }

  BasLiveProject.prototype._resetCss = function () {

    this.css[CSS_PROJECT_ONLINE] = false
    this.css[CSS_PROJECT_INTEGRATOR_ACCESS] = false
  }

  return BasLiveProject
}
