'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasWeatherUi', [
    'ICONS',
    'BAS_API',
    'BAS_THERMOSTAT',
    'BAS_INTL',
    'BAS_UNITS',
    'BAS_WEATHER',
    'BasAppTemperature',
    'BasUnitValue',
    'BasIntl',
    'BasUtilities',
    'Logger',
    basWeatherUiFactory
  ])

/**
 * @typedef {Object} TForecast
 * @typedef {Object} THourlyForecast
 * @property {BasUnitValue} lowTemp
 * @property {BasUnitValue} highTemp
 */

/**
 * @typedef {Object} TStringForecast
 * @property {BasUnitValue} lowTemp
 * @property {BasUnitValue} highTemp
 * @property {?string} day
 * @property {?string} icon
 */

/**
 * @typedef {Object} TWeatherUi
 * @property {BasUnitValue} temperature
 * @property {BasUnitValue} wind
 * @property {BasUnitValue} humidity
 * @property {?string} icon
 * @property {?string} rainIcon
 * @property {?string} frostIcon
 * @property {?string} location
 * @property {?string} source
 * @property {?string} summary
 * @property {?(TStringForecast[])} forecasts
 */

/**
 * @param {ICONS} ICONS
 * @param BAS_API
 * @param {BAS_THERMOSTAT} BAS_THERMOSTAT
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_UNITS} BAS_UNITS
 * @param {BAS_WEATHER} BAS_WEATHER
 * @param {BasAppTemperature} BasAppTemperature
 * @param BasUnitValue
 * @param {BasIntl} BasIntl
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns BasWeatherUi
 */
function basWeatherUiFactory (
  ICONS,
  BAS_API,
  BAS_THERMOSTAT,
  BAS_INTL,
  BAS_UNITS,
  BAS_WEATHER,
  BasAppTemperature,
  BasUnitValue,
  BasIntl,
  BasUtilities,
  Logger
) {

  var NO_ICON = 'clearDay'

  function _getDayName (day) {

    var _day

    _day = day >= 7 ? (day - 7) : day

    return BAS_INTL.DAYS_WEEK_TRANSLATED[BAS_INTL.DAYS_WEEK[_day]]
  }

  /**
   * @constructor
   */
  function BasWeatherUi () {

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {Temperature}
     */
    this.temperature = new BAS_API.Temperature()

    /**
     * @type {Temperature}
     */
    this.apparentTemperature = new BAS_API.Temperature()

    /**
     * @type {TForecast[]}
     */
    this.forecasts = []

    /**
     * @type {THourlyForecast[]}
     */
    this.hourlyForecasts = []

    /**
     * @type {string}
     */
    this.icon = ''

    /**
     * @type {string}
     */
    this.rainIcon = ICONS[BAS_WEATHER.ICON_RAIN]

    /**
     * @type {string}
     */
    this.frostIcon = ICONS[BAS_WEATHER.ICON_FROST]
    /**
     * @type {string}
     */
    this.source = ''

    /**
     * @type {string}
     */
    this.summary = ''

    /**
     * @type {number}
     */
    this.brightness = 0

    /**
     * @type {boolean}
     */
    this.frost = false

    /**
     * @type {number}
     */
    this.humidity = 0

    /**
     * @type {boolean}
     */
    this.rain = false

    /**
     * @type {BasUnitValue}
     */
    this.precipitation = new BasUnitValue({
      value: '-',
      unit: BAS_UNITS.MM_H
    })

    /**
     * @type {number}
     */
    this.pressure = 0

    /**
     * @type {BasUnitValue}
     */
    this.wind = new BasUnitValue({
      value: '-',
      unit: BAS_UNITS.KM_H
    })

    /**
     * @type {number}
     */
    this.windDirection = 90

    /**
     * @type {number}
     */
    this.uv = 0

    /**
     * @type {string}
     */
    this.location = ''

    /**
     * @type {TWeatherUi}
     */
    this.ui = {}

    this.css = {}

    /**
     * @type {boolean}
     */
    this.dirty = true

    /**
     * @type {boolean}
     */
    this.loaded = false

    this.getDayName = _getDayName

    this.resetCss()

    this._makeDefaultUi()
  }

  BasWeatherUi.prototype.makeUi = function () {

    var i,
      length,
      forecast,
      uiForecast,
      hourlyForecast,
      uiHourlyForecast,
      temperatureUnit

    temperatureUnit = BasAppTemperature.getTemperatureUnit()

    this._makeDefaultUi()

    // Check for valid location
    if (BasUtil.isNEString(this.location)) {
      this.ui.location = this.location
    }

    // Check for valid source
    if (BasUtil.isNEString(this.source)) {
      this.ui.source = BasUtilities.translate(this.source)
    }

    // Check rain
    this.css[BAS_WEATHER.CSS_HAS_RAIN_ENABLED] = this.rain

    // Check frost
    this.css[BAS_WEATHER.CSS_HAS_FROST_ENABLED] = this.frost

    // Check for valid summary
    if (BasUtil.isNEString(this.summary)) {
      this.ui.summary = this.summary
    } else if (BasUtil.isNEString(this.name)) {
      this.ui.summary = this.name
    }

    // Check for valid temperature
    if (BasUtil.isObject(this.temperature)) {
      this.ui.temperature = new BasUnitValue({
        value: this.temperature.getTemperature(temperatureUnit),
        unit: BAS_UNITS.DEGREES,
        fixed: 0
      })
    }

    // Check for valid temperature
    if (BasUtil.isObject(this.apparentTemperature)) {
      this.ui.apparentTemperature = new BasUnitValue({
        value: this.apparentTemperature.getTemperature(temperatureUnit),
        unit: BAS_UNITS.DEGREES,
        fixed: 0
      })
    }

    // Check for valid UV
    if (BasUtil.isVNumber(this.brightness)) {
      this.ui.brightness = new BasUnitValue({
        value: this.brightness,
        unit: BAS_UNITS.LUX,
        fixed: 0
      })
    }

    // Check for valid humidity
    if (BasUtil.isVNumber(this.humidity)) {
      this.ui.humidity = new BasUnitValue({
        value: Math.round(this.humidity * 100),
        unit: BAS_UNITS.PERCENT,
        fixed: 0
      })
    }

    // Check for valid UV
    if (BasUtil.isVNumber(this.uv)) {
      this.ui.uv = this.uv
    }

    // Check for valid rain
    if (BasUtil.isObject(this.precipitation)) {
      this.ui.precipitation = this.precipitation
    }

    // Check for valid pressure
    if (BasUtil.isObject(this.pressure)) {
      this.ui.pressure = this.pressure
    }

    // Check for valid rain
    if (BasUtil.isObject(this.rain)) {
      this.ui.rain = this.rain
    }

    // Check for valid wind
    if (BasUtil.isObject(this.wind)) {
      this.ui.wind = this.wind
    }

    // Check for valid windDirection
    if (BasUtil.isVNumber(this.windDirection)) {
      this.ui.windDirection = this.windDirection
    }

    // Check for valid icon
    if (this.icon) {

      this.ui.icon = this.icon
    }

    // Check if forecasts is an array
    if (BasUtil.isNEArray(this.forecasts)) {

      this.ui.forecasts = []

      length = this.forecasts.length
      for (i = 0; i < length; i++) {
        forecast = this.forecasts[i]
        uiForecast = {}

        // Low temperature
        if (BasUtil.isObject(forecast.lowTemp)) {
          uiForecast.lowTemp = new BasUnitValue({
            value: forecast.lowTemp.getTemperature(temperatureUnit),
            unit: BAS_UNITS.DEGREES,
            fixed: 0
          })
        }

        // High temperature
        if (BasUtil.isObject(forecast.highTemp)) {
          uiForecast.highTemp = new BasUnitValue({
            value: forecast.highTemp.getTemperature(temperatureUnit),
            unit: BAS_UNITS.DEGREES,
            fixed: 0
          })
        }

        // Day
        if (BasUtil.isNEString(forecast.day)) {
          uiForecast.day = forecast.day
        }

        // Sunrise
        if (BasUtil.isObject(forecast.sunrise)) {

          uiForecast.sunrise =
            BasIntl.dateToString(
              forecast.sunrise,
              BAS_INTL.LOCALE_OPTION_SHORT
            )

          if (i === 0) this.ui.sunrise = uiForecast.sunrise
        }

        // Sunset
        if (BasUtil.isObject(forecast.sunset)) {

          uiForecast.sunset =
            BasIntl.dateToString(
              forecast.sunset,
              BAS_INTL.LOCALE_OPTION_SHORT
            )

          if (i === 0) this.ui.sunset = uiForecast.sunset
        }

        // Cloudiness
        if (BasUtil.isVNumber(forecast.cloudiness)) {
          uiForecast.cloudiness = forecast.cloudiness
        }

        // Pressure
        if (BasUtil.isObject(forecast.pressure) &&
          BasUtil.isVNumber(forecast.pressure.value)) {
          uiForecast.pressure = forecast.pressure
        }

        // Wind
        if (BasUtil.isObject(forecast.wind)) {
          uiForecast.wind = forecast.wind
        }

        // WindDirection
        if (BasUtil.isVNumber(forecast.windDirection)) {
          uiForecast.windDirection = forecast.windDirection
        }

        // Uv
        if (BasUtil.isVNumber(forecast.uv)) {
          uiForecast.uv = 'UV ' + forecast.uv
        }

        // Icon
        if (forecast.icon) {
          uiForecast.icon = forecast.icon
        }

        this.ui.forecasts.push(uiForecast)
      }

      this.ui.widgetForecasts = this.ui.forecasts.slice(0, 4)
    }

    // Check if hourlyForecasts is an array
    if (BasUtil.isNEArray(this.hourlyForecasts)) {

      this.ui.hourlyForecasts = []

      length = this.hourlyForecasts.length
      for (i = 0; i < length && i < 24; i++) {
        hourlyForecast = this.hourlyForecasts[i]
        uiHourlyForecast = {}

        // Temperature
        if (BasUtil.isObject(hourlyForecast.temp)) {
          uiHourlyForecast.temp = new BasUnitValue({
            value: hourlyForecast.temp.getTemperature(temperatureUnit),
            unit: BAS_UNITS.DEGREES,
            fixed: 0
          })
        }

        // Icon
        if (hourlyForecast.icon) {
          uiHourlyForecast.icon = hourlyForecast.icon
        }

        // Time
        if (BasUtil.isObject(hourlyForecast.time)) {
          uiHourlyForecast.time =
            BasIntl.dateToString(
              hourlyForecast.time,
              BAS_INTL.LOCALE_OPTION_HOUR
            )
        }

        this.ui.hourlyForecasts.push(uiHourlyForecast)
      }
    }
  }

  BasWeatherUi.prototype._makeDefaultUi = function () {

    var i, length, uiForecast, date
    length = 4

    this.ui = {}

    this.ui.location = '-'
    this.ui.summary = '-'
    this.ui.temperature = new BasUnitValue({
      value: '-',
      unit: BAS_THERMOSTAT.DEGREE
    })
    this.ui.apparentTemperature = new BasUnitValue({
      value: '-',
      unit: BAS_THERMOSTAT.DEGREE
    })
    this.ui.brightness = new BasUnitValue({
      value: '-',
      unit: BAS_UNITS.LUX
    })
    this.ui.humidity = new BasUnitValue({
      value: '-',
      unit: BAS_UNITS.PERCENT
    })
    this.ui.wind = new BasUnitValue({
      value: '-',
      unit: BAS_UNITS.KM_H
    })
    this.ui.uv = '-'
    this.ui.sunset = '--:--'
    this.ui.sunrise = '--:--'
    this.ui.rain = new BasUnitValue({
      value: '-',
      unit: BAS_UNITS.MM_H
    })
    this.ui.pressure = new BasUnitValue({
      value: '-',
      unit: BAS_UNITS.HPA
    })
    this.ui.icon = ICONS[NO_ICON]

    this.ui.forecasts = []
    this.ui.widgetForecasts = []
    this.ui.hourlyForecasts = []
    for (i = 0; i < length; i++) {

      uiForecast = {}
      uiForecast.highTemp = '- ' + BAS_THERMOSTAT.DEGREE
      uiForecast.lowTemp = '- ' + BAS_THERMOSTAT.DEGREE
      uiForecast.icon = ICONS[NO_ICON]

      if (i === 0) {

        uiForecast.day = BasUtilities.translate('today')

      } else if (i === 1) {

        uiForecast.day = BasUtilities.translate('tomorrow')

      } else if (i > 1) {

        date = new Date()
        uiForecast.day = _getDayName(date.getDay() + i)
      }

      this.ui.widgetForecasts.push(uiForecast)
    }
  }

  BasWeatherUi.prototype._onUpdateError = function () {

    if (this.loaded) {

      this.dirty = true

    } else {

      this._setErrorMessage()
    }
  }

  BasWeatherUi.prototype._setErrorMessage = function () {

    this._clearData()
    this._makeDefaultUi()

    this.ui.icon = ICONS[NO_ICON]
    this.summary = BasUtilities.translate('no_content_available')

    Logger.warn('Weather service was unable to retrieve new weather data')
  }

  BasWeatherUi.prototype.resetCss = function () {

    this.css[BAS_WEATHER.CSS_HAS_CURRENT_TOP_LEFT_TITLE] = false
    this.css[BAS_WEATHER.CSS_HAS_CURRENT_BOTTOM_LEFT_TITLE] = false
    this.css[BAS_WEATHER.CSS_HAS_CURRENT_BOTTOM_RIGHT_TITLE] = false

    this.css[BAS_WEATHER.CSS_HAS_APPARENT_TEMPERATURE] = false
    this.css[BAS_WEATHER.CSS_HAS_PRESSURE] = false
    this.css[BAS_WEATHER.CSS_HAS_BRIGHTNESS] = false
    this.css[BAS_WEATHER.CSS_HAS_FROST] = false
    this.css[BAS_WEATHER.CSS_HAS_HUMIDITY] = false
    this.css[BAS_WEATHER.CSS_HAS_ICON] = false
    this.css[BAS_WEATHER.CSS_HAS_LOCATION] = false
    this.css[BAS_WEATHER.CSS_HAS_PRECIPITATION] = false
    this.css[BAS_WEATHER.CSS_HAS_RAIN] = false
    this.css[BAS_WEATHER.CSS_HAS_SUN_RISE_SET] = false
    this.css[BAS_WEATHER.CSS_HAS_TEMPERATURE] = false
    this.css[BAS_WEATHER.CSS_HAS_UV] = false
    this.css[BAS_WEATHER.CSS_HAS_WIND_DIRECTION] = false
    this.css[BAS_WEATHER.CSS_HAS_WIND_SPEED] = false
  }

  BasWeatherUi.prototype._clearData = function () {

    this.temperature = null
    this.wind = null
    this.rain = null
    this.forecasts = []
    this.hourlyForecasts = []
    this.windDirection = 0
    this.uv = 0
    this.icon = ''
    this.summary = ''
    this.humidity = 0
    this.location = ''
  }

  BasWeatherUi.prototype.onTimeFormatChanged = function () {

    this.makeUi()
  }

  return BasWeatherUi
}
