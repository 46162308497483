'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('favouritesCtrl', [
    '$rootScope',
    '$scope',
    'ModalService',
    'BAS_HTML',
    'MEDIA_STATES',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_ROOM',
    'BAS_MODAL',
    'BAS_INPUT_MODAL',
    'BAS_FAVOURITE',
    'BAS_ERRORS',
    'CurrentBasCore',
    'CurrentRoom',
    'Sources',
    'LibraryState',
    'BasModal',
    'BasInputModal',
    'BasMediaStateHelper',
    'BasState',
    favouritesCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param ModalService
 * @param {BAS_HTML} BAS_HTML
 * @param {MEDIA_STATES} MEDIA_STATES
 * @param BAS_SOURCES
 * @param BAS_SOURCE
 * @param BAS_ROOM
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 * @param {Sources} Sources
 * @param {LibraryState} LibraryState
 * @param {BasModal} BasModal
 * @param {BasInputModal} BasInputModal
 * @param {BasMediaStateHelper} BasMediaStateHelper
 * @param {BasState} BasState
 */
function favouritesCtrl (
  $rootScope,
  $scope,
  ModalService,
  BAS_HTML,
  MEDIA_STATES,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_ROOM,
  BAS_MODAL,
  BAS_INPUT_MODAL,
  BAS_FAVOURITE,
  BAS_ERRORS,
  CurrentBasCore,
  CurrentRoom,
  Sources,
  LibraryState,
  BasModal,
  BasInputModal,
  BasMediaStateHelper,
  BasState
) {
  var favourites = this

  var lastRegisteredSource = null
  var listeners = []
  var ignorePlayResult = false

  /**
   * @type {TBasStateObj}
   */
  var basState = BasState.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  favourites.currentBasCoreState = CurrentBasCore.get()

  favourites.edit = false
  favourites.loadingFavouriteId = null

  favourites.BAS_SOURCES = BAS_SOURCES
  favourites.BAS_SOURCE = BAS_SOURCE

  /**
   * @type {TLibraryState}
   */
  favourites.libraryState = LibraryState.get()

  favourites.selectFilter = selectFilter
  favourites.selectFavourite = selectFavourite
  favourites.addFavourite = addFavourite
  favourites.removeFavourite = removeFavourite
  favourites.toggleMode = toggleMode
  favourites.openFavouriteOrderEditor = openFavouriteOrderEditor

  $scope.$on('$destroy', onDestroy)

  init()

  function init () {

    registerSource(favourites.libraryState.current.playerId)

    listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_FAVOURITES_UPDATED,
      _onFavouritesUpdated
    ))
  }

  /**
   * @param {BasFilter} filter
   */
  function selectFilter (filter) {

    var source = LibraryState.getCurrentSource()

    ignorePlayResult = true

    if (source && source.favourites) {

      source.favourites.setFilter(filter)
    }
  }

  /**
   * @param {string} uuid
   */
  function selectFavourite (uuid) {

    var source, music, compatibleSources

    source = LibraryState.getCurrentSource()

    ignorePlayResult = false

    if (source && source.favourites) {

      favourites.loadingFavouriteId = uuid

      if (basState.current.MUSIC) {

        music = CurrentRoom.getRoomMusic()

        if (
          music &&
          music.type === BAS_ROOM.MUSIC_T_SONOS &&
          music.basSource
        ) {

          compatibleSources = music.getCompatibleSources()

          if (compatibleSources.indexOf(music.basSource.getId()) === -1) {

            // Sonos room is not listening to any of its compatible sources,
            //  e.g. listening to an HDMI source via Sonos Beam. We must first
            //  set its source to the library's current source (Which is the
            //  Sonos default source) before playing a favourite.
            music.setSource(source.getId()).catch(_ignore)
          }
        }
      } else {

        // App should not set the source before triggering a favourite in video
        //  context (TRIAGE-156)
      }

      source.favourites.play(uuid, true).then(onPlay, onPlayError)
    }

    function onPlay () {

      var _state = BasMediaStateHelper.getHelper()

      favourites.loadingFavouriteId = null

      if (!ignorePlayResult) {

        CurrentRoom.go(_state.get(MEDIA_STATES.PLAYER))

      } else {

        $scope.$applyAsync()
      }
    }

    function onPlayError (err) {

      if (err !== BAS_ERRORS.T_ABORT) {

        favourites.loadingFavouriteId = null
        $scope.$applyAsync()
      }
    }

    function _ignore () {

      // Do nothing
    }
  }

  /**
   * @param {string} uuid
   */
  function removeFavourite (uuid) {

    var source = LibraryState.getCurrentSource()

    if (source && source.favourites) {

      source.favourites.remove(uuid)
        .catch(onRemoveError)
    }

    function onRemoveError () {
      BasModal.show(BAS_MODAL.T_ERROR)
    }
  }

  function addFavourite () {

    var source = LibraryState.getCurrentSource()

    BasInputModal.show(BAS_INPUT_MODAL.T_FAVOURITE_CHANNEL).then(_onModal)

    function _onModal (modal) {

      modal.close.then(_onModalClose)
    }

    function _onModalClose (_result) {

      if (source && source.favourites && Array.isArray(_result)) {

        source.favourites.add(
          BAS_FAVOURITE.T_VIDEO,
          parseInt(_result[1]),
          _result[0]
        ).catch(_onFavouriteAddError)
      }
    }

    function _onFavouriteAddError () {
      BasModal.show(BAS_MODAL.T_ERROR)
    }
  }

  /**
   * @param {boolean} [force]
   */
  function toggleMode (force) {

    ignorePlayResult = true

    favourites.edit = typeof force === 'boolean'
      ? force
      : !favourites.edit
  }

  /**
   * @param {(string|number)} sourceId
   */
  function registerSource (sourceId) {

    if (BasUtil.isNEString(sourceId) ||
      BasUtil.isPNumber(sourceId, false)) {

      Sources.registerFor(
        BAS_SOURCE.COL_EVT_FAVOURITES,
        sourceId
      )

      lastRegisteredSource = sourceId
    }
  }

  function openFavouriteOrderEditor () {

    ModalService.showModal({
      controller: 'favouritesPickerModalCtrl',
      controllerAs: 'modal',
      template: BAS_HTML.favouritesPickerModal
    })
  }

  function unregisterSource () {

    if (BasUtil.isNEString(lastRegisteredSource) ||
      BasUtil.isPNumber(lastRegisteredSource, false)) {

      Sources.unregisterFor(
        BAS_SOURCE.COL_EVT_FAVOURITES,
        lastRegisteredSource
      )
    }
  }

  function _onFavouritesUpdated () {

    $scope.$applyAsync()
  }

  function onDestroy () {

    unregisterSource()
    BasUtil.executeArray(listeners)
    listeners = []
  }
}
