'use strict'

angular
  .module('basalteApp')
  .constant('BAS_LIBRARY', {
    EVT_PLAYLIST_CHANGED: 'basLibrary-playlistChanged',
    SHORTCUT_ALBUM: 'shortcutAlbum',
    SHORTCUT_ARTIST: 'shortcutArtist',
    TYPE_BASE: 'base',
    TYPE_LOCAL: 'local',
    TYPE_TUNEIN: 'tunein',
    TYPE_DEEZER: 'deezer',
    TYPE_SPOTIFY: 'spotify',
    TYPE_TIDAL: 'tidal',
    MODAL_CLOSED: 'Modal closed'
  })
