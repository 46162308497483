'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('camerasSettingsCtrl', [
    '$scope',
    '$rootScope',
    'BAS_CAMERAS',
    'SETTINGS_STATES',
    'BAS_CURRENT_CORE',
    'STATES',
    'BasCameras',
    'CurrentBasCore',
    'BasState',
    camerasSettingsCtrl
  ])

/**
 * @param $scope
 * @param $rootScope
 * @param {BAS_CAMERAS} BAS_CAMERAS
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {STATES} STATES
 * @param {BasCameras} BasCameras
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasState} BasState
 */
function camerasSettingsCtrl (
  $scope,
  $rootScope,
  BAS_CAMERAS,
  SETTINGS_STATES,
  BAS_CURRENT_CORE,
  STATES,
  BasCameras,
  CurrentBasCore,
  BasState
) {
  var cameras = this

  var _listeners = []

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  cameras.SETTINGS_STATES = SETTINGS_STATES

  /**
   * @type {TCamerasState}
   */
  cameras.camerasState = BasCameras.get()

  cameras.reorder = reorder
  cameras.beforeSwipe = beforeSwipe
  cameras.beforeWait = beforeWait

  init()

  function init () {

    $scope.$on('$destroy', onDestroy)

    _listeners.push($rootScope.$on(
      BAS_CAMERAS.EVT_CAMERAS_UPDATED,
      _onCamerasSync
    ))

    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_PROFILE_CREATED,
      _onProfile
    ))
  }

  /**
   * @param {number} splice
   * @param {number} original
   */
  function reorder (splice, original) {

    var originalId

    originalId = cameras.camerasState.uiCameras[original]

    if (originalId) {

      cameras.camerasState.uiCameras.splice(
        original,
        1
      )
      cameras.camerasState.uiCameras.splice(
        splice,
        0,
        originalId
      )
    }

    BasCameras.saveNewCamerasOrder(cameras.camerasState.uiCameras)
  }

  function beforeSwipe (event) {

    if (event) {

      event.preventDefault()
    }
  }

  function _onCamerasSync () {

    $scope.$applyAsync()
  }

  function _onProfile () {

    if (
      CurrentBasCore.hasCore() && (
        !currentBasCoreState.core.core.profile ||
        !currentBasCoreState.core.core.profile.admin
      )
    ) {
      BasState.go(STATES.SETTINGS_GENERAL)
    }
  }

  function beforeWait (event) {

    if (
      event &&
      event.target &&
      event.target.classList &&
      event.target.classList.contains &&
      event.target.classList.contains('instant') &&
      event.preventDefault
    ) {

      event.preventDefault()
    }
  }

  function onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
