'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasCoreClient', [
    '$rootScope',
    '$window',
    'BAS_CORE_CLIENT',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_CURRENT_CORE',
    'BAS_APP',
    'CurrentBasCore',
    'BasAppDevice',
    'BasCoreClientHelper',
    'BasPreferences',
    'BasError',
    BasCoreClient
  ])

/**
 * @typedef {Object} BasCoreClientState
 * @property {string} username
 * @property {boolean} canMuteAreaOnCall
 */

/**
 * Service which handles core client
 *
 * @constructor
 * @param $rootScope
 * @param $window
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_APP} BAS_APP
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasAppDevice} BasAppDevice
 * @param {BasCoreClientHelper} BasCoreClientHelper
 * @param {BasPreferences} BasPreferences
 * @param {BasError} BasError
 */
function BasCoreClient (
  $rootScope,
  $window,
  BAS_CORE_CLIENT,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_CURRENT_CORE,
  BAS_APP,
  CurrentBasCore,
  BasAppDevice,
  BasCoreClientHelper,
  BasPreferences,
  BasError
) {

  const K_IS_ELLIE_V1 = 'isEllieV1'
  const K_IS_ELLIE_V2 = 'isEllieV2'

  const basTm = $window.basTModule

  const LISA_DEVICE_TIMEOUT = 3000

  /**
   * @type {TCurrentBasCoreState}
   */
  const currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasCoreClientState}
   */
  const state = {
    username: '',
    canMuteAreaOnCall: false
  }

  this.get = get
  this.getUsername = getUsername
  this.getBasCoreClientDevice = getBasCoreClientDevice
  this.restartApp = restartApp
  this.startScreenOnMonitor = startScreenOnMonitor
  this.stopScreenOnMonitor = stopScreenOnMonitor

  init()

  function init () {

    var coreClient

    if (BasAppDevice.isCoreClient()) {

      $rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
        _onEventConnected
      )

      $rootScope.$on(
        BAS_ROOMS.EVT_ROOMS_UPDATED,
        _onRoomsUpdated
      )

      $rootScope.$on(
        BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
        _onCoreClientsUpdated
      )

      $rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_VERSION,
        _onVersion
      )

      $rootScope.$on(
        BAS_APP.EVT_RESUME,
        _onResume
      )

      coreClient = _getCoreClient()

      if (coreClient) {

        coreClient.setCallback(_onCoreClientCallbackResult)
        coreClient.getMacAddress(_onCoreClientMacAddress)
        coreClient.getSerialNumber(_onCoreClientSerialNumber)
      }
    }

    if (BasAppDevice.isBrowser()) {

      BAS_CORE_CLIENT.STATE.mac = '000000000020'
      BAS_CORE_CLIENT.STATE.macNum = parseInt(BAS_CORE_CLIENT.STATE.mac, 16)
      BAS_CORE_CLIENT.STATE.macStr =
        BasUtil.hexToMacAddress(BAS_CORE_CLIENT.STATE.mac, ':')
      BAS_CORE_CLIENT.STATE.uiMac = BAS_CORE_CLIENT.STATE.macStr
    }
  }

  /**
   * @returns {BasCoreClientState}
   */
  function get () {

    return state
  }

  function getUsername () {

    return state.username
  }

  /**
   * @returns {Promise<BasCoreClientDevice>}
   */
  function getBasCoreClientDevice () {

    return new Promise(basCoreClientDevicePromiseConstructor)

    function basCoreClientDevicePromiseConstructor (resolve, reject) {

      var _finished, timeoutId
      var deviceListener, roomsListener

      _finished = false

      timeoutId = setTimeout(onTimeout, LISA_DEVICE_TIMEOUT)

      _findCoreClientDevice()

      function _findCoreClientDevice () {

        var basCoreClientInfo

        BasUtil.execute(roomsListener)
        roomsListener = null

        if (CurrentBasCore.hasCore() &&
          currentBasCoreState.core.core.roomsReceived) {

          basCoreClientInfo = BasCoreClientHelper.getBasCoreClientInfo()

          if (basCoreClientInfo && basCoreClientInfo.device) {

            _finish(basCoreClientInfo.device)

          } else {

            deviceListener = $rootScope.$on(
              BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
              _onCoreClientDevicesUpdated
            )

            roomsListener = $rootScope.$on(
              BAS_ROOMS.EVT_ROOMS_UPDATED,
              _findCoreClientDevice
            )
          }

        } else {

          roomsListener = $rootScope.$on(
            BAS_ROOMS.EVT_ROOMS_UPDATED,
            _findCoreClientDevice
          )
        }
      }

      function _onCoreClientDevicesUpdated () {

        var basCoreClientInfo = BasCoreClientHelper.getBasCoreClientInfo()

        if (basCoreClientInfo && basCoreClientInfo.device) {

          _finish(basCoreClientInfo.device)
        }
      }

      function onTimeout () {
        _finish(
          null,
          new BasError(
            'Get Core Client Device timeout',
            null,
            'Timed out waiting for Core Client Device'
          )
        )
      }

      /**
       * Finish the promise
       *
       * @private
       * @param {*} innerResolve
       * @param {*} [innerReject]
       */
      function _finish (innerResolve, innerReject) {

        clearTimeout(timeoutId)
        BasUtil.execute(roomsListener)
        roomsListener = null
        BasUtil.execute(deviceListener)
        deviceListener = null

        if (!_finished) {

          _finished = true

          if (innerResolve) {

            resolve(innerResolve)

          } else {

            reject(innerReject)
          }
        }
      }
    }
  }

  function restartApp () {

    var coreClient

    coreClient = _getCoreClient()
    if (coreClient && coreClient.restartApp) coreClient.restartApp()
  }

  function startScreenOnMonitor () {

    var coreClient

    coreClient = _getCoreClient()

    if (coreClient && coreClient.startScreenOnMonitor) {

      coreClient.startScreenOnMonitor()
    }
  }

  function stopScreenOnMonitor () {

    var coreClient

    coreClient = _getCoreClient()

    if (coreClient && coreClient.stopScreenOnMonitor) {

      coreClient.stopScreenOnMonitor()
    }
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasCoreContainer} _basCoreContainer
   * @param {boolean} isConnected
   */
  function _onEventConnected (
    _event,
    _basCoreContainer,
    isConnected
  ) {
    var _basServer, _deviceUuid

    if (isConnected) {

      // Set core client username from server via macNum
      if (CurrentBasCore.hasCore()) {

        _basServer = currentBasCoreState.core.core.server

        if (_basServer) {

          _deviceUuid =
            _basServer.coreClientDeviceInfo[BAS_CORE_CLIENT.STATE.macNum]
        }
      }

      if (BasUtil.isNEString(_deviceUuid)) {

        state.username = _deviceUuid
      }
    }
  }

  /**
   * @private
   * @param {Object} _evt
   * @param {string} _type
   */
  function _onRoomsUpdated (_evt, _type) {

    syncCoreClient()
  }

  /**
   * @private
   */
  function _onCoreClientsUpdated () {

    syncCoreClient()
  }

  /**
   * @private
   */
  function _onVersion () {

    syncCoreClient()
  }

  /**
   * @private
   */
  function _onResume () {

    BasPreferences.applySleepTime()
    BasPreferences.applyProximitySensitivity()
  }

  /**
   * @private
   * @param {*} _error
   * @param {?Object} result
   */
  function _onCoreClientCallbackResult (_error, result) {

    if (BasUtil.isObject(result)) {

      if (BasUtil.safeHasOwnProperty(result, K_IS_ELLIE_V1)) {

        BasAppDevice.setEllieV1(result[K_IS_ELLIE_V1])
      }

      if (BasUtil.safeHasOwnProperty(result, K_IS_ELLIE_V2)) {

        BasAppDevice.setEllieV2(result[K_IS_ELLIE_V2])
      }
    }
  }

  /**
   * @private
   * @param {*} _error
   * @param {string} result
   */
  function _onCoreClientMacAddress (_error, result) {

    if (BasUtil.isNEString(result) && BasUtil.isMACAddress(result)) {

      BAS_CORE_CLIENT.STATE.mac = result.split(':').join('')
      BAS_CORE_CLIENT.STATE.macNum =
        parseInt(BAS_CORE_CLIENT.STATE.mac, 16)
      BAS_CORE_CLIENT.STATE.macStr =
        BasUtil.hexToMacAddress(BAS_CORE_CLIENT.STATE.mac, ':')
      BAS_CORE_CLIENT.STATE.uiMac = BAS_CORE_CLIENT.STATE.macStr
        ? BAS_CORE_CLIENT.STATE.macStr
        : '-'

      // Set MAC address as Sentry user id
      const Sentry = window['Sentry']
      if (Sentry && BAS_CORE_CLIENT.STATE.macStr) {
        Sentry.setUser({ id: BAS_CORE_CLIENT.STATE.macStr })
      }

      $rootScope.$emit(BAS_CORE_CLIENT.EVT_CORE_CLIENT_MAC_ADDRESS)
    }
  }

  /**
   * @private
   * @param {*} _error
   * @param {string} result
   */
  function _onCoreClientSerialNumber (_error, result) {

    if (BasUtil.isNEString(result)) {

      BAS_CORE_CLIENT.STATE.uiSerial = result

      if (basTm) {

        basTm.logEvt({
          evtType: basTm.T_CORE_CLIENT_SERIAL,
          serial: result
        })
      }

      $rootScope.$emit(
        BAS_CORE_CLIENT.EVT_CORE_CLIENT_SERIAL_NUMBER,
        result
      )
    }
  }

  function syncCoreClient () {

    var basLisaInfo, lisaDevice, lisaRoom

    if (BAS_CORE_CLIENT.STATE.macNum) {

      basLisaInfo = BasCoreClientHelper.getBasCoreClientInfo()

      if (basLisaInfo) {

        lisaDevice = basLisaInfo.device
        lisaRoom = basLisaInfo.room

        if (CurrentBasCore.hasCore() &&
          lisaDevice &&
          lisaRoom) {

          state.canMuteAreaOnCall =
            currentBasCoreState.core.core.supportsMuteAreaOnCall &&
            lisaDevice.canMuteAreaOnCall &&
            !!lisaRoom.music

          $rootScope.$emit(
            BAS_CORE_CLIENT.EVT_CORE_CLIENT_SUPPORTS_MUTE_AREA_ON_CALL
          )
        }
      }
    }
  }

  /**
   * Get Core Client plugin instance
   *
   * @private
   * @returns {?CoreClient}
   */
  function _getCoreClient () {

    if (BasUtil.isObject($window['basalteCordova']) &&
      BasUtil.isObject($window['basalteCordova']['coreClient'])) {

      return $window['basalteCordova']['coreClient']
    }

    return null
  }
}
