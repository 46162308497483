'use strict'

angular
  .module('basMarquee')
  .factory('BasMarqueeSharedState', basMarqueeSharedState)

function basMarqueeSharedState () {

  /**
   * @constructor
   */
  function BasMarqueeSharedState () {

    /**
     * Marquee states
     *
     * @private
     * @type {BasMarqueeState[]}
     */
    this._states = []
  }

  /**
   * Adds to state to the shared states, returns the index
   *
   * @param {BasMarqueeState} state
   * @returns {number}
   */
  BasMarqueeSharedState.prototype.addMarqueeState = function (state) {

    state.setOnAnimationDone(this._onAnimationDone.bind(this, state))
    return this._states.push(state) - 1
  }

  /**
   * Removes the state instance from shared states
   *
   * @param {BasMarqueeState} state
   */
  BasMarqueeSharedState.prototype.removeMarqueeState = function (state) {

    var idx = this._indexOfState(state)

    if (idx > -1) this._states.splice(idx, 1)
  }

  /**
   * @private
   */
  BasMarqueeSharedState.prototype._onAnimationDone = function () {

    if (this._areAllStatesReady()) this._startAll()
  }

  /**
   * Starts all animations at once
   *
   * @private
   */
  BasMarqueeSharedState.prototype._startAll = function () {

    var length, i

    length = this._states.length
    for (i = 0; i < length; i++) {
      this._states[i].startDelayedAnimation()
    }
  }

  /**
   * Checks whether all shared states are not playing / should not be playing
   *
   * @private
   * @returns {boolean}
   */
  BasMarqueeSharedState.prototype._areAllStatesReady = function () {

    var length, i

    length = this._states.length
    for (i = 0; i < length; i++) {
      if (!this._states[i].isReady()) return false
    }

    return true
  }

  /**
   * @private
   * @param {BasMarqueeState} state
   * @returns {number}
   */
  BasMarqueeSharedState.prototype._indexOfState = function (state) {

    var length, i

    length = this._states.length
    for (i = 0; i < length; i++) {
      if (this._states[i] === state) return i
    }

    return -1
  }

  return BasMarqueeSharedState
}
