'use strict'

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var Device = require('./device')
var Temperature = require('./temperature')

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function WeatherStationDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._temperature = new Temperature()

  this._state = BasUtil.isObject(device[P.STATE])
    ? device[P.STATE]
    : {}

  this._checkTemperature()
}

WeatherStationDevice.prototype = Object.create(Device.prototype)
WeatherStationDevice.prototype.constructor = WeatherStationDevice

/**
 * Fires when state has changed.
 *
 * @event ThermostatDevice#EVT_STATE
 */

/**
 * @constant {string}
 */
WeatherStationDevice.EVT_STATE = 'evtThermostatState'

/**
 * @constant {string}
 */
WeatherStationDevice.C_AIR_PRESSURE = P.AIR_PRESSURE

/**
 * @constant {string}
 */
WeatherStationDevice.C_BRIGHTNESS = P.BRIGHTNESS

/**
 * @constant {string}
 */
WeatherStationDevice.C_FROST = P.FROST

/**
 * @constant {string}
 */
WeatherStationDevice.C_HUMIDITY = P.HUMIDITY

/**
 * @constant {string}
 */
WeatherStationDevice.C_PRECIPITATION = P.PRECIPITATION

/**
 * @constant {string}
 */
WeatherStationDevice.C_RAIN = P.RAIN

/**
 * @constant {string}
 */
WeatherStationDevice.C_TEMPERATURE = P.TEMPERATURE

/**
 * @constant {string}
 */
WeatherStationDevice.C_UV = P.UV

/**
 * @constant {string}
 */
WeatherStationDevice.C_WIND_DIRECTION = P.WIND_DIRECTION

/**
 * @constant {string}
 */
WeatherStationDevice.C_WIND_SPEED = P.WIND_SPEED

/**
 * Air pressure in Pa (not hPA)
 *
 * @name WeatherStationDevice#airPressure
 * @type {number}
 * @readonly
 */
Object.defineProperty(WeatherStationDevice.prototype, 'airPressure', {
  get: function () {

    return BasUtil.isPNumber(this._state[WeatherStationDevice.C_AIR_PRESSURE])
      ? this._state[WeatherStationDevice.C_AIR_PRESSURE]
      : 0
  }
})

/**
 * @name WeatherStationDevice#brightness
 * @type {number}
 * @readonly
 */
Object.defineProperty(WeatherStationDevice.prototype, 'brightness', {
  get: function () {

    return BasUtil.isPNumber(this._state[WeatherStationDevice.C_BRIGHTNESS])
      ? this._state[WeatherStationDevice.C_BRIGHTNESS]
      : 0
  }
})

/**
 * @name WeatherStationDevice#frost
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(WeatherStationDevice.prototype, 'frost', {
  get: function () {

    return BasUtil.isBool(this._state[WeatherStationDevice.C_FROST])
      ? this._state[WeatherStationDevice.C_FROST]
      : false
  }
})

/**
 * @name WeatherStationDevice#humidity
 * @type {number}
 * @readonly
 */
Object.defineProperty(WeatherStationDevice.prototype, 'humidity', {
  get: function () {

    return BasUtil.isPNumber(this._state[WeatherStationDevice.C_HUMIDITY])
      ? this._state[WeatherStationDevice.C_HUMIDITY]
      : 0
  }
})

/**
 * @name WeatherStationDevice#rain
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(WeatherStationDevice.prototype, 'rain', {
  get: function () {

    return BasUtil.isBool(this._state[WeatherStationDevice.C_RAIN])
      ? this._state[WeatherStationDevice.C_RAIN]
      : false
  }
})

/**
 * @name ThermostatDevice#temperature
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(WeatherStationDevice.prototype, 'temperature', {
  get: function () {

    return this._temperature
  }
})

/**
 * @name WeatherStationDevice#windSpeed
 * @type {number}
 * @readonly
 */
Object.defineProperty(WeatherStationDevice.prototype, 'windSpeed', {
  get: function () {

    return BasUtil.isPNumber(this._state[WeatherStationDevice.C_WIND_SPEED])
      ? this._state[WeatherStationDevice.C_WIND_SPEED]
      : 0
  }
})

/**
 * Parse a WeatherStationDevice message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
WeatherStationDevice.prototype.parse = function (msg, options) {

  var valid, emit

  emit = true
  valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    if (BasUtil.isObject(msg[P.STATE])) {

      if (!BasUtil.isEqualPartialObject(this._state, msg[P.STATE])) {

        BasUtil.mergeObjectsDeep(this._state, msg[P.STATE])

        this._checkTemperature()

        if (emit) this.emit(WeatherStationDevice.EVT_STATE)
      }
    }
  }

  return valid
}

WeatherStationDevice.prototype._checkTemperature = function () {

  var temp

  if (BasUtil.isObject(this._state[WeatherStationDevice.C_TEMPERATURE])) {

    temp = this._state[WeatherStationDevice.C_TEMPERATURE]

    if (BasUtil.isVNumber(temp[CONSTANTS.TU_CELSIUS])) {

      this._temperature.setCelsius(temp[CONSTANTS.TU_CELSIUS])

    } else if (BasUtil.isVNumber(temp[CONSTANTS.TU_FAHRENHEIT])) {

      this._temperature.setFahrenheit(temp[CONSTANTS.TU_FAHRENHEIT])
    }
  }
}

module.exports = WeatherStationDevice
