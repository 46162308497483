'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasWeatherStation', [
    '$rootScope',
    'BAS_API',
    'BAS_UNITS',
    'BAS_WEATHER',
    'BasUnitValue',
    'BasWeatherUi',
    basWeatherStationFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_UNITS} BAS_UNITS
 * @param {BAS_WEATHER} BAS_WEATHER
 * @param {BasUnitValue} BasUnitValue
 * @param {BasWeatherUi} BasWeatherUi
 * @returns BasWeatherStation
 */
function basWeatherStationFactory (
  $rootScope,
  BAS_API,
  BAS_UNITS,
  BAS_WEATHER,
  BasUnitValue,
  BasWeatherUi
) {
  /**
   * @constructor
   * @extends BasWeatherUi
   * @param {WeatherStationDevice} device
   * @param {BasRoom} room
   */
  function BasWeatherStation (device, room) {

    BasWeatherUi.call(this)

    /**
     * @type {?BasRoom}
     */
    this.room = BasUtil.isObject(room) ? room : null

    /**
     * @type {?WeatherStationDevice}
     */
    this.device = null

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.source = BAS_WEATHER.SOURCE_LOCAL

    this._handleDeviceStateChange = this._syncDeviceState.bind(this)

    if (device) this.parseWeatherStationDevice(device)
  }

  BasWeatherStation.prototype = Object.create(BasWeatherUi.prototype)

  BasWeatherStation.prototype.constructor = BasWeatherStation

  BasWeatherStation.prototype.parseWeatherStationDevice = function (device) {

    if (device instanceof BAS_API.WeatherStationDevice) {

      this.device = device
      this.uuid = this.device.uuid

      this.setDeviceListeners()
      this.syncDevice()
    }
  }

  BasWeatherStation.prototype._syncDeviceState = function () {

    this.syncDevice()

    $rootScope.$emit(BAS_WEATHER.EVT_WEATHER_STATION_STATE_UPDATED)
  }
  /**
   * @param {WeatherStationDevice} [device]
   */
  BasWeatherStation.prototype.syncDevice = function (device) {
    var weatherStationDevice = device || this.device

    this.resetCss()

    if (weatherStationDevice) {

      // Source
      this.css[BAS_WEATHER.CSS_HAS_CURRENT_BOTTOM_RIGHT_TITLE] = true

      if (BasUtil.isNEString(this.device.name)) {
        this.name = this.device.name
        this.css[BAS_WEATHER.CSS_HAS_CURRENT_BOTTOM_LEFT_TITLE] = true
      }

      if (this.room &&
        this.room._basRoom &&
        BasUtil.isNEString(this.room._basRoom.name)) {
        this.location = this.room._basRoom.name
        this.css[BAS_WEATHER.CSS_HAS_CURRENT_TOP_LEFT_TITLE] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_AIR_PRESSURE
      )) {
        this.pressure = new BasUnitValue({
          value: this.device.airPressure / 100,
          unit: BAS_UNITS.HPA,
          fixed: 1
        })
        this.css[BAS_WEATHER.CSS_HAS_PRESSURE] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_BRIGHTNESS
      )) {

        this.brightness = this.device.brightness
        this.css[BAS_WEATHER.CSS_HAS_BRIGHTNESS] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_FROST
      )) {

        this.frost = this.device.frost
        this.css[BAS_WEATHER.CSS_HAS_FROST] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_HUMIDITY
      )) {

        this.humidity = this.device.humidity
        this.css[BAS_WEATHER.CSS_HAS_HUMIDITY] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_PRECIPITATION
      )) {

        this.precipitation = this.device.precipitation
        this.css[BAS_WEATHER.CSS_HAS_PRECIPITATION] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_RAIN
      )) {

        this.rain = this.device.rain
        this.css[BAS_WEATHER.CSS_HAS_RAIN] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_TEMPERATURE
      )) {

        this.temperature = this.device.temperature
        this.css[BAS_WEATHER.CSS_HAS_TEMPERATURE] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_UV
      )) {

        this.uv = this.device.uv
        this.css[BAS_WEATHER.CSS_HAS_UV] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_WIND_DIRECTION
      )) {

        // TODO: Read wind direction from API object
        // this.windDirection = this.device.windDirection
        this.css[BAS_WEATHER.CSS_HAS_WIND_DIRECTION] = true
      }

      if (this.device.allowsRead(
        BAS_API.WeatherStationDevice.C_WIND_SPEED
      )) {

        this.wind = new BasUnitValue({
          value: (this.device.windSpeed / 1000 * 3600),
          unit: BAS_UNITS.KM_H,
          fixed: 1
        })
        this.css[BAS_WEATHER.CSS_HAS_WIND_SPEED] = true
      }
    }

    this.makeUi()
  }

  BasWeatherStation.prototype.setDeviceListeners = function () {

    this._clearDeviceListeners()

    if (this.device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.WeatherStationDevice.EVT_STATE,
        this._handleDeviceStateChange
      ))
    }
  }

  /**
   * Clears the API device listeners
   *
   * @private
   */
  BasWeatherStation.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  BasWeatherStation.prototype.clear = function () {

    this._clearDeviceListeners()
    this.device = null
    this.room = null
  }

  return BasWeatherStation
}
