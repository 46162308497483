'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

/**
 * @constructor
 * @since 3.0.0
 */
function BasCoreStatusError () {

  /**
   * @type {number}
   */
  this.cid = 0

  /**
   * @type {number}
   */
  this.lvl = 0

  /**
   * @type {string}
   */
  this.component = ''

  /**
   * @type {string}
   */
  this.error = ''
}

/**
 * @param {Object} obj
 * @returns {?BasCoreStatusError}
 */
BasCoreStatusError.parse = function (obj) {

  var result, _value

  if (BasUtil.isObject(obj)) {

    result = new BasCoreStatusError()

    // Cid

    _value = obj[P.CID]

    if (!BasUtil.isVNumber(_value)) return null

    result.cid = _value

    // Lvl

    _value = obj[P.LVL]

    if (!BasUtil.isPNumber(_value, true)) return null

    result.lvl = _value

    // Component

    _value = obj[P.COMPONENT]

    if (BasUtil.isNEString(_value)) result.component = _value

    // Error

    _value = obj[P.ERROR]

    if (BasUtil.isNEString(_value)) result.error = _value

    return result
  }

  return null
}

module.exports = BasCoreStatusError
