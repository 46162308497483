'use strict'

angular
  .module('basalteApp')
  .factory('PlayerLibraryState', [
    'STATES',
    'SourcesHelper',
    playerLibraryStateFactory
  ])

/**
 * @param {STATES} STATES
 * @param {SourcesHelper} SourcesHelper
 * @returns PlayerLibraryState
 */
function playerLibraryStateFactory (
  STATES,
  SourcesHelper
) {
  /**
   * Holds a player ID with that player's last library state
   *
   * @constructor
   * @param {number} [playerId]
   */
  function PlayerLibraryState (playerId) {

    /**
     * @type {(number|string)}
     */
    this.playerId = typeof playerId === 'number'
      ? playerId
      : typeof playerId === 'string' ? playerId : -1

    /**
     * @type {LocalManager}
     */
    this.localManager = null

    /**
     * @type {RadioManager}
     */
    this.radioManager = null

    /**
     * @type {DeezerManager}
     */
    this.deezerManager = null

    /**
     * @type {SpotifyManager}
     */
    this.spotifyManager = null

    /**
     * @type {TidalManager}
     */
    this.tidalManager = null

    /**
     * Initial Library state
     *
     * @type {string}
     */
    this.lastState = STATES.MUSIC_LIBRARY
  }

  /**
   * Audio or Player BasSource
   *
   * @returns {?BasSource}
   */
  PlayerLibraryState.prototype.getBasSource = function () {

    var source

    source = SourcesHelper.getAudioSource(this.playerId)

    if (source) return source

    source = SourcesHelper.getVideoSource(this.playerId)

    if (source) return source

    return SourcesHelper.getPlayer(this.playerId)
  }

  /**
   * @returns {?BasSource}
   */
  PlayerLibraryState.prototype.getSpotifySource = function () {

    var basSource

    basSource = SourcesHelper.getSpotifySource(this.playerId)

    if (basSource && basSource.spotify) return basSource

    return null
  }

  /**
   * @returns {?BasSource}
   */
  PlayerLibraryState.prototype.getTidalSource = function () {
    var basSource = this.getBasSource()

    if (basSource && basSource.tidal) return basSource

    return null
  }

  /**
   * @returns {?BasSource}
   */
  PlayerLibraryState.prototype.getDeezerSource = function () {
    var basSource = this.getBasSource()

    if (basSource && basSource.deezer) return basSource

    return null
  }

  return PlayerLibraryState
}
