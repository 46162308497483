'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasMediaStateHelper', [
    '$uiRouterGlobals',
    'STATES',
    'MEDIA_STATES',
    'BAS_STATE',
    'CurrentRoom',
    BasMediaStateHelper
  ])

/**
 * @constructor
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {MEDIA_STATES} MEDIA_STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {CurrentRoom} CurrentRoom
 */
function BasMediaStateHelper (
  $uiRouterGlobals,
  STATES,
  MEDIA_STATES,
  BAS_STATE,
  CurrentRoom
) {
  this.getPrefix = getPrefix
  this.getHelper = getHelper
  this.getMediaState = getMediaState
  this.isExact = isExact
  this.isCurrent = isCurrent
  this.isVideoState = isVideoState
  this.exitMedia = exitMedia

  /**
   * @param {string} [stateName] Default value is current state name
   * @returns {string}
   */
  function getPrefix (stateName) {

    var _stateName, idx, _statePrefix

    _stateName = BasUtil.isNEString(stateName)
      ? stateName
      : $uiRouterGlobals.current.name

    if (BasUtil.isNEString(_stateName)) {

      idx = _stateName.indexOf(MEDIA_STATES.MEDIA)

      if (idx !== -1) {

        _statePrefix = _stateName.substring(0, idx)

        if (_statePrefix) return _statePrefix
      }
    }

    return ''
  }

  /**
   * @param {string} [statePrefix]
   * @returns {Object}
   */
  function getHelper (statePrefix) {

    var _statePrefix

    _statePrefix = BasUtil.isNEString(statePrefix)
      ? statePrefix
      : getPrefix()

    return {
      get: _getMediaState.bind(null, _statePrefix),
      isExact: _isExact.bind(null, _statePrefix),
      isCurrent: isCurrent.bind(null, _statePrefix)
    }
  }

  /**
   * @private
   * @param {string} statePrefix
   * @param {string} mediaState
   * @returns {string}
   */
  function _getMediaState (statePrefix, mediaState) {

    return statePrefix + mediaState
  }

  /**
   * @param {string} mediaState
   * @param {string} [statePrefix]
   * @returns {string}
   */
  function getMediaState (mediaState, statePrefix) {

    var _mediaState, _statePrefix

    if (BasUtil.isNEString(mediaState) &&
      BasUtil.isNEString(statePrefix)) {

      _mediaState = mediaState
      _statePrefix = statePrefix

    } else if (BasUtil.isNEString(mediaState) &&
      typeof statePrefix === 'undefined') {

      _mediaState = mediaState
      _statePrefix = getPrefix()

    } else {

      return ''
    }

    return _getMediaState(_statePrefix, _mediaState)
  }

  /**
   * @param {string} statePrefix
   * @param {string} mediaState
   * @returns {boolean}
   */
  function _isExact (statePrefix, mediaState) {

    var _currentStateName, _stateName

    _currentStateName = $uiRouterGlobals.current.name

    if (_currentStateName) {

      _stateName = getMediaState(mediaState, statePrefix)

      return _currentStateName === _stateName
    }

    return false
  }

  /**
   * @param {string} mediaState
   * @returns {boolean}
   */
  function isExact (mediaState) {

    var _statePrefix

    _statePrefix = getPrefix()

    return _isExact(_statePrefix, mediaState)
  }

  /**
   * @param {string} statePrefix
   * @param {string} mediaState
   * @returns {boolean}
   */
  function isCurrent (statePrefix, mediaState) {

    var _currentStateName, _stateName

    _currentStateName = $uiRouterGlobals.current.name

    if (_currentStateName) {

      _stateName = getMediaState(mediaState, statePrefix)

      if (_stateName) {

        return _currentStateName.indexOf(_stateName) === 0
      }
    }

    return false
  }

  /**
   * @returns {boolean}
   */
  function isVideoState () {

    var prefix = getPrefix()

    return (
      BasUtil.isNEString(prefix) &&
      BasUtil.startsWith(STATES.VIDEO, prefix)
    )
  }

  /**
   * @param {string} [statePrefix]
   * @returns {Object}
   */
  function exitMedia (statePrefix) {

    var _statePrefix

    _statePrefix = BasUtil.isNEString(statePrefix)
      ? statePrefix
      : getPrefix()

    if (_statePrefix.indexOf(STATES.ROOM) === 0) {

      // Room media context

      return CurrentRoom.go(STATES.ROOM)

    } else if (_statePrefix.indexOf(STATES.MAIN) === 0) {

      // Home media context

      return CurrentRoom.go(
        STATES.HOME,
        {
          view: BAS_STATE.S_HOME_VIEW_OPTIONS
        }
      )
    }
  }
}
