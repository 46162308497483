'use strict'

angular
  .module('basCompass', [])
  .directive('basCompass', basCompass)

function basCompass () {

  return {
    restrict: 'AE',
    scope: {
      topText: '@',
      bottomText: '@',
      direction: '='
    },
    template: '<div class="bas-wc">' +
      '<svg viewBox="0 0 36 36">' +
      '<g>' +
      '<text x="18" y="4" class="bas-wc-direction">' +
      'N' +
      '</text>' +
      '<text x="34" y="20" class="bas-wc-direction">' +
      'E' +
      '</text>' +
      '<text x="18" y="36" class="bas-wc-direction">' +
      'S' +
      '</text>' +
      '<text x="2" y="20" class="bas-wc-direction">' +
      'W' +
      '</text>' +
      '<text x="18" ng-attr-y="{{secondaryText ? 16 : 18.5}}"' +
      'class="bas-wc-top"' +
      'ng-bind="topText">' +
      '</text>' +
      '<text x="18" y="23"' +
      'class="bas-wc-bottom"' +
      'ng-bind="bottomText">' +
      '</text>' +
      '</g>' +
      '<g class="wind-arrow"' +
      'ng-style="{\'transform\': \'rotate(\' + (direction) + \'deg)\'}">' +
      '<path ' +
      'd="' +
      'm 20.795513,28.389151' +
      ' c -1.116342,1.100997 -2.884127,2.883804 -2.884127,2.883804 0,0' +
      ' -2.113606,-2.175659 -2.802946,-2.86562 l 2.814969,0.88751 z' +
      '"/>' +
      '<!-- Rects are here to \'expand\' this group to take up the whole svg' +
      'This way the center it rotates around is always the center of' +
      'the svg and not the arrow itself -->' +
      '<rect ' +
      'class="bas-wc-hidden"' +
      'width="1"' +
      'height="1"' +
      'x="0"' +
      'y="0"/>' +
      '<rect ' +
      'class="bas-wc-hidden"' +
      'width="1"' +
      'height="1"' +
      'x="35"' +
      'y="35"/>' +
      '</g>' +
      '</svg>' +
      '</div>'
  }
}
