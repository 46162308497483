'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basLibraryHeader', [
    'BAS_HTML',
    basLibraryHeaderDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basLibraryHeader
 */
function basLibraryHeaderDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    bindToController: {
      api: '=basHeaderApi',
      selection: '=basSelection'
    },
    controller: [
      '$scope',
      '$element',
      '$attrs',
      'BasLibraryHeader',
      'Logger',
      controller
    ],
    controllerAs: 'basHeader',
    template: BAS_HTML.headerBasLibrary
  }

  function controller (
    $scope,
    $element,
    attr,
    BasLibraryHeader,
    Logger
  ) {
    var controllerName = 'Bas Library Header Ctrl'

    /**
     * @type {Object}
     * @property {?BasLibraryHeader} api
     */
    var basHeader = this

    Logger.debug(
      controllerName,
      $scope,
      $element,
      attr,
      basHeader
    )

    // Define methods
    basHeader.toggleSearch = toggleSearch
    basHeader.toggleView = toggleView
    basHeader.back = back
    basHeader.home = home
    basHeader.select = select

    function toggleSearch () {

      // Check search can be toggled
      if (BasUtil.isObject(basHeader.api) &&
        BasUtil.isObject(basHeader.api.class) &&
        basHeader.api.class[BasLibraryHeader.CLASS_CAN_SEARCH] === true) {

        // Toggle search
        basHeader.api.toggleSearchInput()

        executeFunction(BasLibraryHeader.CLK_SEARCH)
      }
    }

    function toggleView () {
      Logger.debug(controllerName + ' - ToggleView')

      executeFunction(BasLibraryHeader.CLK_VIEW)
    }

    function back () {
      Logger.debug(controllerName + ' - Back')

      executeFunction(BasLibraryHeader.CLK_BACK)
    }

    function home () {
      Logger.debug(controllerName + ' - Home')

      executeFunction(BasLibraryHeader.CLK_HOME)
    }

    function select ($event) {
      Logger.debug(controllerName + ' - Select')

      executeFunction(BasLibraryHeader.CLK_SELECT, $event)
    }

    /**
     * Checks whether the external function exists and executes it
     *
     * @param {string} name
     * @param argument
     */
    function executeFunction (name, argument) {

      // Check API function
      if (BasUtil.isObject(basHeader.api) &&
        BasUtil.isObject(basHeader.api.control) &&
        typeof basHeader.api.control[name] === 'function') {

        // Execute function
        basHeader.api.control[name](argument)
      }
    }
  }
}
