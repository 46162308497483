'use strict'

angular
  .module('basalteApp')
  .directive('basOpenCloseDevice', [
    'BAS_HTML',
    basOpenCloseDeviceDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basOpenCloseDevice
 */
function basOpenCloseDeviceDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    scope: {
      openCloseDevice: '<'
    },
    template: BAS_HTML.openCloseDevice
  }
}
