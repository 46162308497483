'use strict'

// HT = History Type

angular
  .module('basalteApp')
  .constant('BAS_APP_PROFILE', {
    K_LOCAL: 'local',
    K_RADIO: 'radio',
    K_DEEZER: 'deezer',
    K_SPOTIFY: 'spotify',
    K_TIDAL: 'tidal',
    EVT_INTERCOM_ROOM_SET: 'evtBasAppProfileIntercomRoomSet'
  })
