{
  "genres": {
    "categories": {
      "items": [
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/toplists.jpg",
              "width": 275
            }
          ],
          "id": "toplists",
          "name": "Top Lists"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/mood.jpg",
              "width": 275
            }
          ],
          "id": "mood",
          "name": "Mood"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/party.jpg",
              "width": 275
            }
          ],
          "id": "party",
          "name": "Party"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/pop.jpg",
              "width": 275
            }
          ],
          "id": "pop",
          "name": "Pop"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/decades.jpg",
              "width": 275
            }
          ],
          "id": "decades",
          "name": "Decades"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/electronic.jpg",
              "width": 275
            }
          ],
          "id": "electronic",
          "name": "Electronic/Dance"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/chill.jpg",
              "width": 275
            }
          ],
          "id": "chill",
          "name": "Chill"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/hip_hop.jpg",
              "width": 275
            }
          ],
          "id": "hip-hop",
          "name": "Hip-Hop"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/focus.jpg",
              "width": 275
            }
          ],
          "id": "focus",
          "name": "Focus"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/rock.jpg",
              "width": 275
            }
          ],
          "id": "rock",
          "name": "Rock"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/dinner.jpg",
              "width": 275
            }
          ],
          "id": "dinner",
          "name": "Dinner"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/indie.jpg",
              "width": 275
            }
          ],
          "id": "indie",
          "name": "Indie"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/sleep.jpg",
              "width": 275
            }
          ],
          "id": "sleep",
          "name": "Sleep"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/workout.jpg",
              "width": 275
            }
          ],
          "id": "workout",
          "name": "Workout"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/romance.jpg",
              "width": 275
            }
          ],
          "id": "romance",
          "name": "Romance"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/r_b.jpg",
              "width": 275
            }
          ],
          "id": "r-b",
          "name": "R&B"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/folk.jpg",
              "width": 275
            }
          ],
          "id": "folk",
          "name": "Folk & Acoustic"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/metal.jpg",
              "width": 275
            }
          ],
          "id": "metal",
          "name": "Metal"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/soul.jpg",
              "width": 275
            }
          ],
          "id": "soul",
          "name": "Soul"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/travel.jpg",
              "width": 275
            }
          ],
          "id": "travel",
          "name": "Travel"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/country.jpg",
              "width": 275
            }
          ],
          "id": "country",
          "name": "Country"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/jazz.jpg",
              "width": 275
            }
          ],
          "id": "jazz",
          "name": "Jazz"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/blues.jpg",
              "width": 275
            }
          ],
          "id": "blues",
          "name": "Blues"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/reggae.jpg",
              "width": 275
            }
          ],
          "id": "reggae",
          "name": "Reggae"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/latin.jpg",
              "width": 275
            }
          ],
          "id": "latin",
          "name": "Latin"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/punk.jpg",
              "width": 275
            }
          ],
          "id": "punk",
          "name": "Punk"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/funk.jpg",
              "width": 275
            }
          ],
          "id": "funk",
          "name": "Funk"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/classical.jpg",
              "width": 275
            }
          ],
          "id": "classical",
          "name": "Classical"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/comedy.jpg",
              "width": 275
            }
          ],
          "id": "comedy",
          "name": "Comedy"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/kids.jpg",
              "width": 275
            }
          ],
          "id": "kids",
          "name": "Kids"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/gaming.jpg",
              "width": 275
            }
          ],
          "id": "gaming",
          "name": "Gaming"
        },
        {
          "icons": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/trending.jpg",
              "width": 275
            }
          ],
          "id": "trending",
          "name": "Trending"
        }
      ],
      "next": ""
    }
  },
  "charts": {
    "playlists": {
      "items": [
        {
          "name": "Today's Top Hits",
          "tracks": {
            "total": 53
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/top_hits.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "RapCaviar",
          "tracks": {
            "total": 57
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/rap_caviar.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Rock This",
          "tracks": {
            "total": 51
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/rock_this.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "mint",
          "tracks": {
            "total": 63
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/mint.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Hot Country",
          "tracks": {
            "total": 66
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/hot_country.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Viva Latino",
          "tracks": {
            "total": 54
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/viva_latino.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Are & Be",
          "tracks": {
            "total": 46
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/are_be.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Viral Hits",
          "tracks": {
            "total": 25
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/viral_hits.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "United States Top 50",
          "tracks": {
            "total": 50
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/top_50_usa.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Global Top 50",
          "tracks": {
            "total": 50
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/top_50_global.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Global Viral 50",
          "tracks": {
            "total": 50
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/viral_50_global.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "United States Viral 50",
          "tracks": {
            "total": 50
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/viral_50_usa.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "New Music Friday",
          "tracks": {
            "total": 75
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/new_music_friday.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Hot Country - Top Tracks of 2018",
          "tracks": {
            "total": 57
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/hot_country_top_tracks.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        }
      ],
      "next": ""
    }
  },
  "featured": {
    "playlists": {
      "items": [
        {
          "name": "All Out 00s",
          "tracks": {
            "total": 51
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/all_out_00.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "All Out 90s",
          "tracks": {
            "total": 25
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/all_out_90.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "All Out 80s",
          "tracks": {
            "total": 46
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/all_out_80.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "All Out 70s",
          "tracks": {
            "total": 54
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/all_out_70.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "All Out 60s",
          "tracks": {
            "total": 66
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/all_out_60.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Get Home Happy",
          "tracks": {
            "total": 52
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/get_home_happy.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Daily Lift",
          "tracks": {
            "total": 55
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/daily_lift.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "All Out 50s",
          "tracks": {
            "total": 63
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/all_out_50.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "I Love My '90s Hip-Hop",
          "tracks": {
            "total": 57
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/90_hip_hop.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "80s Rock Anthems",
          "tracks": {
            "total": 53
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/80_rock.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Summer Throwbacks",
          "tracks": {
            "total": 75
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/summer_throwbacks.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "HIT Rewind",
          "tracks": {
            "total": 53
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/hit_rewind.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        },
        {
          "name": "Garage Rock Revival",
          "tracks": {
            "total": 48
          },
          "images": [
            {
              "height": 275,
              "url": "img/demo/spotify_library/garage_rock.jpg",
              "width": 275
            }
          ],
          "type": "playlist"
        }
      ],
      "next": ""
    }
  },
  "releases": {
    "albums": {
      "items": [
        {
          "name": "Spider-Man: Into the Spider-Verse (Soundtrack From & Inspired by the Motion Picture)",
          "artists": [
            {
              "name": "Various Artists"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/spider_man_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/spider_man_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/spider_man_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Imagine",
          "artists": [
            {
              "name": "Ariana Grande"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/imagine_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/imagine_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/imagine_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Dying To Live",
          "artists": [
            {
              "name": "Kodak Black"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/dying_to_live_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/dying_to_live_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/dying_to_live_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Solo de Mi",
          "artists": [
            {
              "name": "Bad Bunny"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/solo_de_mi_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/solo_de_mi_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/solo_de_mi_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Icarus Falls",
          "artists": [
            {
              "name": "ZAYN"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/icarus_falls_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/icarus_falls_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/icarus_falls_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Startender (feat. Offset and Tyga)",
          "artists": [
            {
              "name": "A Boogie Wit da Hoodie"
            },
            {
              "name": "Tyga"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/startender_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/startender_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/startender_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Sick Boy",
          "artists": [
            {
              "name": "The Chainsmokers"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/sick_boy_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/sick_boy_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/sick_boy_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "(Happy Xmas) War is Over (feat. Sean Ono Lennon)",
          "artists": [
            {
              "name": "Miley Cyrus"
            },
            {
              "name": "Mark Ronson"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/war_is_over_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/war_is_over_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/war_is_over_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Springsteen on Broadway",
          "artists": [
            {
              "name": "Bruce Springsteen"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/broadway_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/broadway_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/broadway_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Telling All My Secrets",
          "artists": [
            {
              "name": "Mitchell Tenpenny"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/telling_all_my_secrets_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/telling_all_my_secrets_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/telling_all_my_secrets_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Los Campeones del Pueblo \"The Big Leagues\"",
          "artists": [
            {
              "name": "Wisin & Yandel"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/big_leagues_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/big_leagues_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/big_leagues_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Missed Calls",
          "artists": [
            {
              "name": "Kid Ink"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/missed_calls_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/missed_calls_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/missed_calls_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Elevate",
          "artists": [
            {
              "name": "Papa Roach"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/elevate_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/elevate_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/elevate_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Platinum Fire (Deluxe)",
          "artists": [
            {
              "name": "Arin Ray"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/platinum_fire_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/platinum_fire_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/platinum_fire_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "7-Inches for Planned Parenthood, Vol. 2: Pt. 1",
          "artists": [
            {
              "name": "Various Artists"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/7_inches_for_planned_parenthood_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/7_inches_for_planned_parenthood_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/7_inches_for_planned_parenthood_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Sauce Like This",
          "artists": [
            {
              "name": "Smokepurpp"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/sauce_like_this_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/sauce_like_this_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/sauce_like_this_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Testify",
          "artists": [
            {
              "name": "Robin Thicke"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/testify_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/testify_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/testify_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Pep Talk + Quarter-Life Crisis",
          "artists": [
            {
              "name": "Judah & the Lion"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/pep_talk_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/pep_talk_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/pep_talk_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "Gravity Pt. 1",
          "artists": [
            {
              "name": "Gryffin"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/gravity_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/gravity_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/gravity_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        },
        {
          "name": "ALL I NEED",
          "artists": [
            {
              "name": "Switchfoot"
            }
          ],
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/all_i_need_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/all_i_need_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/all_i_need_640.jpg",
              "width": 640
            }
          ],
          "type": "album"
        }
      ],
      "next": ""
    }
  },
  "playlists": {
    "items": [
      {
        "name": "Legendary",
        "tracks": {
          "total": 70
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/legendary.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Chill Out Dinner",
        "tracks": {
          "total": 111
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/chill_out_dinner.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Jazzy Dinner",
        "tracks": {
          "total": 59
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/jazzy_dinner.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Dinner With Friends",
        "tracks": {
          "total": 100
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/dinner_with_friends.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Acoustic Hits",
        "tracks": {
          "total": 103
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/acoustic_hits.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "TGIF",
        "tracks": {
          "total": 76
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/tgif.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Mood Booster",
        "tracks": {
          "total": 79
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/mood_booster.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Behind The Lyrics: HITS",
        "tracks": {
          "total": 50
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/behind_the_lyrics.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Pop Rising",
        "tracks": {
          "total": 70
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/pop_rising.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Global Viral 50",
        "tracks": {
          "total": 50
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/viral_50_global.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Hot Acoustics",
        "tracks": {
          "total": 69
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/hot_acoustics.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "A Perfect Day",
        "tracks": {
          "total": 80
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/a_perfect_day.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Productive Morning",
        "tracks": {
          "total": 67
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/productive_morning.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "StuBru: De Hotlist",
        "tracks": {
          "total": 33
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/stubru.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      },
      {
        "name": "Belgium Top 50",
        "tracks": {
          "total": 50
        },
        "images": [
          {
            "height": 275,
            "url": "img/demo/spotify_library/top_50_belgium.jpg",
            "width": 275
          }
        ],
        "type": "playlist"
      }
    ],
    "next": ""
  },
  "albums": {
    "items": [
      {
        "name": "Speechless (feat. Erika Sirola)",
        "artists": [
          {
            "name": "Robin Schulz"
          },
          {
            "name": "Erika Sirola"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/speechless_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/speechless_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/speechless_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "AM",
        "artists": [
          {
            "name": "Arctic Monkeys"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/am_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/am_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/am_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Songbook",
        "artists": [
          {
            "name": "Benjamin Biolay"
          },
          {
            "name": "Melvil Poupaud"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/songbook_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/songbook_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/songbook_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Velvet Rope",
        "artists": [
          {
            "name": "Rita Ora"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/velvet_rope_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/velvet_rope_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/velvet_rope_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Abbey Road",
        "artists": [
          {
            "name": "The Beatles"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/abbey_road_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/abbey_road_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/abbey_road_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "x",
        "artists": [
          {
            "name": "A Boogie Wit da Hoodie"
          },
          {
            "name": "Ed Sheeran"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/x_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/x_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/x_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "÷ (Deluxe)",
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/divide_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/divide_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/divide_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Something Sweet",
        "artists": [
          {
            "name": "Madison Beer"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/something_sweet_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/something_sweet_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/something_sweet_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Blurryface",
        "artists": [
          {
            "name": "Twenty One Pilots"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/blurryface_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/blurryface_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/blurryface_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Vices & Virtues",
        "artists": [
          {
            "name": "Panic! At The Disco"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/vices_virtues_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/vices_virtues_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/vices_virtues_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Polaroid",
        "artists": [
          {
            "name": "Jonas Blue"
          },
          {
            "name": "Liam Payne"
          },
          {
            "name": "Lennon Stella"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/polaroid_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/polaroid_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/polaroid_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "V",
        "artists": [
          {
            "name": "Maroon 5"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/v_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/v_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/v_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Night Visions",
        "artists": [
          {
            "name": "Imagine Dragons"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/night_visions_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/night_visions_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/night_visions_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Say What You Wanna",
        "artists": [
          {
            "name": "Tujamo"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/say_what_you_wanna_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/say_what_you_wanna_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/say_what_you_wanna_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Mojo - EP",
        "artists": [
          {
            "name": "Claire Laffut"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/mojo_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/mojo_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/mojo_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "Effet miroir",
        "artists": [
          {
            "name": "Zaz"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/effet_miroir_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/effet_miroir_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/effet_miroir_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      },
      {
        "name": "All 4 corners",
        "artists": [
          {
            "name": "Krafty Kuts"
          },
          {
            "name": "Dynamite MC"
          }
        ],
        "images": [
          {
            "height": 64,
            "url": "img/demo/spotify_library/all_4_corners_64.jpg",
            "width": 64
          },
          {
            "height": 300,
            "url": "img/demo/spotify_library/all_4_corners_300.jpg",
            "width": 300
          },
          {
            "height": 640,
            "url": "img/demo/spotify_library/all_4_corners_640.jpg",
            "width": 640
          }
        ],
        "type": "album"
      }
    ],
    "next": ""
  },
  "artists": {
    "artists": {
      "items": [
        {
          "name": "Maroon 5",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/maroon_5_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/maroon_5_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/maroon_5_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Bruno Mars",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/bruno_mars_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/bruno_mars_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/bruno_mars_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Jonas Blue",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/jonas_blue_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/jonas_blue_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/jonas_blue_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Panic! At The Disco",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/panic_at_the_disco_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/panic_at_the_disco_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/panic_at_the_disco_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Madison Beer",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/madison_beer_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/madison_beer_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/madison_beer_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Alvaro Soler",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/alvaro_soler_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/alvaro_soler_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/alvaro_soler_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Sam Smith",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/sam_smith_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/sam_smith_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/sam_smith_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "George Ezra",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/george_ezra_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/george_ezra_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/george_ezra_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Angèle",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/angele_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/angele_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/angele_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "The Beatles",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/beatles_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/beatles_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/beatles_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Twenty One Pilots",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/21_pilots_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/21_pilots_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/21_pilots_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Bazart",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/bazart_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/bazart_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/bazart_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Bradley Cooper",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/bradley_cooper_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/bradley_cooper_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/bradley_cooper_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Imagine Dragons",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/imagine_dragons_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/imagine_dragons_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/imagine_dragons_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "5 Seconds Of Summer",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/5_seconds_of_summer_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/5_seconds_of_summer_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/5_seconds_of_summer_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Loïc Nottet",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/loic_nottet_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/loic_nottet_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/loic_nottet_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Marshmello",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/marshmello_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/marshmello_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/marshmello_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Ariana Grande",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/ariana_grande_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/ariana_grande_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/ariana_grande_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        },
        {
          "name": "Ed Sheeran",
          "images": [
            {
              "height": 160,
              "url": "img/demo/spotify_library/ed_sheeran_160.jpg",
              "width": 160
            },
            {
              "height": 320,
              "url": "img/demo/spotify_library/ed_sheeran_320.jpg",
              "width": 320
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/ed_sheeran_640.jpg",
              "width": 640
            }
          ],
          "type": "artist"
        }
      ],
      "next": ""
    }
  },
  "songs": {
    "items": [
      {
        "name": "Speechless (feat. Erika Sirola)",
        "duration_ms": 214941,
        "artists": [
          {
            "name": "Robin Schulz"
          },
          {
            "name": "Erika Sirola"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/speechless_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/speechless_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/speechless_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Do I Wanna Know?",
        "duration_ms": 272394,
        "artists": [
          {
            "name": "Arctic Monkeys"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/am_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/am_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/am_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "R U Mine",
        "duration_ms": 201726,
        "artists": [
          {
            "name": "Arctic Monkeys"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/am_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/am_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/am_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "A Cannes cet été",
        "duration_ms": 244200,
        "artists": [
          {
            "name": "Benjamin Biolay"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/songbook_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/songbook_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/songbook_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Jardin d'hiver",
        "duration_ms": 206173,
        "artists": [
          {
            "name": "Benjamin Biolay"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/songbook_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/songbook_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/songbook_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Velvet Rope",
        "duration_ms": 235386,
        "artists": [
          {
            "name": "Rita Ora"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/velvet_rope_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/velvet_rope_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/velvet_rope_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Something",
        "duration_ms": 182293,
        "artists": [
          {
            "name": "The Beatles"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/abbey_road_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/abbey_road_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/abbey_road_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Here Comes The Sun",
        "duration_ms": 185733,
        "artists": [
          {
            "name": "The Beatles"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/abbey_road_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/abbey_road_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/abbey_road_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Her Majesty",
        "duration_ms": 25986,
        "artists": [
          {
            "name": "The Beatles"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/abbey_road_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/abbey_road_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/abbey_road_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "One",
        "duration_ms": 252760,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/x_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/x_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/x_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Thinking Out Loud",
        "duration_ms": 281560,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/x_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/x_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/x_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Castle On The Hill",
        "duration_ms": 261153,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/divide_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/divide_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/divide_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Shape of You",
        "duration_ms": 233712,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/divide_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/divide_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/divide_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Galway Girl",
        "duration_ms": 170826,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/divide_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/divide_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/divide_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Something Sweet",
        "duration_ms": 195560,
        "artists": [
          {
            "name": "Madison Beer"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/something_sweet_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/something_sweet_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/something_sweet_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Stressed Out",
        "duration_ms": 202333,
        "artists": [
          {
            "name": "Twenty One Pilots"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/blurryface_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/blurryface_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/blurryface_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Ride",
        "duration_ms": 214506,
        "artists": [
          {
            "name": "Twenty One Pilots"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/blurryface_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/blurryface_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/blurryface_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Hurricane",
        "duration_ms": 265333,
        "artists": [
          {
            "name": "Panic! At The Disco"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/vices_virtues_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/vices_virtues_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/vices_virtues_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Polaroid",
        "duration_ms": 193377,
        "artists": [
          {
            "name": "Jonas Blue"
          },
          {
            "name": "Liam Payne"
          },
          {
            "name": "Lennon Stella"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/polaroid_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/polaroid_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/polaroid_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Animals",
        "duration_ms": 231013,
        "artists": [
          {
            "name": "Maroon 5"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/v_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/v_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/v_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Sugar",
        "duration_ms": 235493,
        "artists": [
          {
            "name": "Maroon 5"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/v_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/v_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/v_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Radioavtive",
        "duration_ms": 186813,
        "artists": [
          {
            "name": "Imagine Dragons"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/night_visions_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/night_visions_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/night_visions_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Amsterdam",
        "duration_ms": 241426,
        "artists": [
          {
            "name": "Imagine Dragons"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/night_visions_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/night_visions_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/night_visions_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Say What You Wanna",
        "duration_ms": 159896,
        "artists": [
          {
            "name": "Tujamo"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/say_what_you_wanna_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/say_what_you_wanna_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/say_what_you_wanna_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Gare Du Nord",
        "duration_ms": 238067,
        "artists": [
          {
            "name": "Claire Laffut"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/mojo_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/mojo_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/mojo_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Demain c'est toi",
        "duration_ms": 158800,
        "artists": [
          {
            "name": "Zaz"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/effet_miroir_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/effet_miroir_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/effet_miroir_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "J'aime j'aime",
        "duration_ms": 206213,
        "artists": [
          {
            "name": "Zaz"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/effet_miroir_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/effet_miroir_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/effet_miroir_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Saint-Valentin",
        "duration_ms": 197040,
        "artists": [
          {
            "name": "Zaz"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/effet_miroir_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/effet_miroir_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/effet_miroir_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Rev It Up (feat. Riya)",
        "duration_ms": 215765,
        "artists": [
          {
            "name": "Krafty Kuts"
          },
          {
            "name": "Dynamite MC"
          },
          {
            "name": "Riya"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/all_4_corners_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/all_4_corners_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/all_4_corners_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Rollercoaster",
        "duration_ms": 200751,
        "artists": [
          {
            "name": "Krafty Kuts"
          },
          {
            "name": "Dynamite MC"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/all_4_corners_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/all_4_corners_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/all_4_corners_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      }
    ],
    "next": ""
  },
  "recently": {
    "items": [
      {
        "name": "Speechless (feat. Erika Sirola)",
        "duration_ms": 214941,
        "artists": [
          {
            "name": "Robin Schulz"
          },
          {
            "name": "Erika Sirola"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/speechless_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/speechless_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/speechless_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Her Majesty",
        "duration_ms": 25986,
        "artists": [
          {
            "name": "The Beatles"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/abbey_road_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/abbey_road_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/abbey_road_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Thinking Out Loud",
        "duration_ms": 281560,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/x_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/x_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/x_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Rev It Up (feat. Riya)",
        "duration_ms": 215765,
        "artists": [
          {
            "name": "Krafty Kuts"
          },
          {
            "name": "Dynamite MC"
          },
          {
            "name": "Riya"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/all_4_corners_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/all_4_corners_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/all_4_corners_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Say What You Wanna",
        "duration_ms": 159896,
        "artists": [
          {
            "name": "Tujamo"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/say_what_you_wanna_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/say_what_you_wanna_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/say_what_you_wanna_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Something",
        "duration_ms": 182293,
        "artists": [
          {
            "name": "The Beatles"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/abbey_road_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/abbey_road_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/abbey_road_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Jardin d'hiver",
        "duration_ms": 206173,
        "artists": [
          {
            "name": "Benjamin Biolay"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/songbook_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/songbook_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/songbook_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Castle On The Hill",
        "duration_ms": 261153,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/divide_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/divide_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/divide_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Amsterdam",
        "duration_ms": 241426,
        "artists": [
          {
            "name": "Imagine Dragons"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/night_visions_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/night_visions_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/night_visions_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "R U Mine",
        "duration_ms": 201726,
        "artists": [
          {
            "name": "Arctic Monkeys"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/am_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/am_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/am_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Stressed Out",
        "duration_ms": 202333,
        "artists": [
          {
            "name": "Twenty One Pilots"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/blurryface_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/blurryface_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/blurryface_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Demain c'est toi",
        "duration_ms": 158800,
        "artists": [
          {
            "name": "Zaz"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/effet_miroir_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/effet_miroir_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/effet_miroir_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Do I Wanna Know?",
        "duration_ms": 272394,
        "artists": [
          {
            "name": "Arctic Monkeys"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/am_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/am_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/am_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Galway Girl",
        "duration_ms": 170826,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/divide_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/divide_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/divide_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Animals",
        "duration_ms": 231013,
        "artists": [
          {
            "name": "Maroon 5"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/v_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/v_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/v_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Something Sweet",
        "duration_ms": 195560,
        "artists": [
          {
            "name": "Madison Beer"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/something_sweet_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/something_sweet_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/something_sweet_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "One",
        "duration_ms": 252760,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/x_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/x_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/x_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Ride",
        "duration_ms": 214506,
        "artists": [
          {
            "name": "Twenty One Pilots"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/blurryface_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/blurryface_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/blurryface_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Hurricane",
        "duration_ms": 265333,
        "artists": [
          {
            "name": "Panic! At The Disco"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/vices_virtues_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/vices_virtues_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/vices_virtues_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Polaroid",
        "duration_ms": 193377,
        "artists": [
          {
            "name": "Jonas Blue"
          },
          {
            "name": "Liam Payne"
          },
          {
            "name": "Lennon Stella"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/polaroid_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/polaroid_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/polaroid_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Rollercoaster",
        "duration_ms": 200751,
        "artists": [
          {
            "name": "Krafty Kuts"
          },
          {
            "name": "Dynamite MC"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/all_4_corners_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/all_4_corners_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/all_4_corners_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Velvet Rope",
        "duration_ms": 235386,
        "artists": [
          {
            "name": "Rita Ora"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/velvet_rope_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/velvet_rope_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/velvet_rope_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "J'aime j'aime",
        "duration_ms": 206213,
        "artists": [
          {
            "name": "Zaz"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/effet_miroir_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/effet_miroir_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/effet_miroir_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Sugar",
        "duration_ms": 235493,
        "artists": [
          {
            "name": "Maroon 5"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/v_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/v_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/v_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "A Cannes cet été",
        "duration_ms": 244200,
        "artists": [
          {
            "name": "Benjamin Biolay"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/songbook_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/songbook_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/songbook_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Radioavtive",
        "duration_ms": 186813,
        "artists": [
          {
            "name": "Imagine Dragons"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/night_visions_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/night_visions_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/night_visions_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Gare Du Nord",
        "duration_ms": 238067,
        "artists": [
          {
            "name": "Claire Laffut"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/mojo_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/mojo_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/mojo_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Shape of You",
        "duration_ms": 233712,
        "artists": [
          {
            "name": "Ed Sheeran"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/divide_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/divide_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/divide_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      },
      {
        "name": "Saint-Valentin",
        "duration_ms": 197040,
        "artists": [
          {
            "name": "Zaz"
          }
        ],
        "album": {
          "images": [
            {
              "height": 64,
              "url": "img/demo/spotify_library/effet_miroir_64.jpg",
              "width": 64
            },
            {
              "height": 300,
              "url": "img/demo/spotify_library/effet_miroir_300.jpg",
              "width": 300
            },
            {
              "height": 640,
              "url": "img/demo/spotify_library/effet_miroir_640.jpg",
              "width": 640
            }
          ]
        },
        "type": "track"
      }
    ],
    "next": ""
  }
}
