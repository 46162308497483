'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basHomeSizer', basHomeSizer)

function basHomeSizer () {

  return {
    restrict: 'A',
    controller: [
      '$scope',
      '$element',
      '$rootScope',
      'UI_HELPER',
      'BAS_MESSAGES',
      'BAS_HOME_DASHBOARD',
      'BasUtilities',
      homeController
    ]
  }

  /**
   * @param $scope
   * @param $element
   * @param $rootScope
   * @param {UI_HELPER} UI_HELPER
   * @param {BAS_MESSAGES} BAS_MESSAGES
   * @param {BAS_HOME_DASHBOARD} BAS_HOME_DASHBOARD
   * @param {BasUtilities} BasUtilities
   */
  function homeController (
    $scope,
    $element,
    $rootScope,
    UI_HELPER,
    BAS_MESSAGES,
    BAS_HOME_DASHBOARD,
    BasUtilities
  ) {
    var CLASS_HIDE_WEATHER_WEEK = 'bas-home-hide-week-overview'
    var CLASS_HIDE_WEATHER_WEEK_TEMPERATURE =
      'bas-home-hide-temperature-overview'
    var WIDGET_MARGIN = 25
    var DETAILS_BUTTON_HEIGHT = 30
    var TEMPERATURE_FRACTION = 1 / 8

    var _listeners = []

    var _bannerElement = null
    var _weatherWidgetElement = null

    this.setBannerElement = setBannerElement
    this.setWeatherWidgetElement = setWeatherWidgetElement

    this.$postLink = onPostLink

    init()

    function init () {

      $scope.$on('$destroy', onDestroy)
    }

    function onPostLink () {

      $scope.onMessage = onMessage

      _listeners.push($rootScope.$on(
        UI_HELPER.EVT_RESIZE,
        _delayedCheckHeight
      ))
      _listeners.push($rootScope.$on(
        BAS_MESSAGES.EVT_MESSAGES_UPDATED,
        _delayedCheckHeight
      ))
      _listeners.push($rootScope.$on(
        BAS_HOME_DASHBOARD.EVT_HOME_DASHBOARD_UPDATED,
        _delayedCheckHeight
      ))

      BasUtilities.waitForFrames(5, _checkHeight)

      // For devices which have some issues with
      // the initial startup of the app
      BasUtilities.waitForFrames(15, _checkHeight)
    }

    function setBannerElement (element) {

      _bannerElement = element
    }

    function setWeatherWidgetElement (element) {

      _weatherWidgetElement = element
    }

    function onMessage () {

      BasUtilities.waitForFrames(5, _checkHeight)
    }

    function _delayedCheckHeight () {

      _checkHeight()
      BasUtilities.waitForFrames(5, _checkHeight)
      BasUtilities.waitForFrames(15, _checkHeight)
    }

    function _checkHeight () {

      var _homeElement, homeHeight
      var weatherWidgetHeight, weatherTemperatureHeight, bannerHeight
      var _hideWeatherWeek, _hideWeatherWeekTemperature

      _homeElement = $element[0]

      if (_homeElement) {

        homeHeight = _homeElement.offsetHeight

        if (_weatherWidgetElement && _bannerElement) {

          weatherWidgetHeight =
            _weatherWidgetElement.offsetHeight +
            WIDGET_MARGIN +
            DETAILS_BUTTON_HEIGHT
          weatherTemperatureHeight =
            weatherWidgetHeight * TEMPERATURE_FRACTION

          bannerHeight = _bannerElement.offsetHeight

          _hideWeatherWeek =
            homeHeight < (
              weatherWidgetHeight +
              bannerHeight -
              weatherTemperatureHeight
            )
          _hideWeatherWeekTemperature =
            homeHeight < (
              weatherWidgetHeight +
              bannerHeight
            )
        }

        _homeElement.classList.toggle(
          CLASS_HIDE_WEATHER_WEEK,
          !!_hideWeatherWeek
        )
        _homeElement.classList.toggle(
          CLASS_HIDE_WEATHER_WEEK_TEMPERATURE,
          !!_hideWeatherWeekTemperature
        )
      }
    }

    function onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
