'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomTimers', [
    '$rootScope',
    'BAS_API',
    'CurrentBasCore',
    'BasTimer',
    basRoomTimersFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasTimer
 * @returns BasRoomTimers
 */
function basRoomTimersFactory (
  $rootScope,
  BAS_API,
  CurrentBasCore,
  BasTimer
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomTimers (basRoom) {

    /**
     * @type {BasRoom}
     * @private
     */
    this._basRoom = basRoom

    /**
     * @type {Object<string, BasTimer>}
     */
    this.timers = {}

    /**
     * @type {string[]}
     */
    this.uiTimers = []

    this._deviceListeners = []

    this._handleTimersChanged = this._onTimersChanged.bind(this)
    this._handleTimerChanged = this._onTimerChanged.bind(this)

    this.css = {}
    this.resetCss()

    this.parseRoom()

    this._syncOrder()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomTimers.hasTimers = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEString(room.room.timerCtrl)
    )
  }

  /**
   * @private
   * @returns {?TimerCtrlDevice}
   */
  BasRoomTimers.prototype._getDevice = function () {

    if (CurrentBasCore.has() &&
      this._basRoom &&
      this._basRoom.room &&
      BasUtil.isNEString(this._basRoom.room.timerCtrl)) {

      return currentBasCoreState.core.getDevice(
        this._basRoom.room.timerCtrl
      )
    }

    return null
  }

  /**
   * Parse the raw room from the parent BasRoom
   */
  BasRoomTimers.prototype.parseRoom = function parseRoom () {

    var device = this._getDevice()

    if (device) {

      this.resetCss()
      this._setDeviceListeners()
      this.syncTimers()
    }
  }

  BasRoomTimers.prototype.syncTimers = function syncEnergy () {

    var timers, keys, i, length, uuid, timer
    var device = this._getDevice()

    this.timers = {}
    this.uiTimers = []

    if (device) {

      timers = device.timers

      keys = Object.keys(timers)
      length = keys.length
      for (i = 0; i < length; i++) {

        uuid = keys[i]

        if (BasUtil.isObject(timers[uuid])) {

          timer = new BasTimer(uuid, this._basRoom)
          this.timers[uuid] = timer
          this.uiTimers.push(uuid)
        }
      }
    }
  }

  BasRoomTimers.prototype._onTimersChanged = function () {

    this.syncTimers()

    $rootScope.$applyAsync()
  }

  BasRoomTimers.prototype._onTimerChanged = function (timer) {

    if (BasUtil.isObject(timer) &&
      BasUtil.isNEString(timer.uuid) &&
      BasUtil.isObject(this.timers[timer.uuid])) {

      this.timers[timer.uuid].parse(timer)
    }

    $rootScope.$applyAsync()
  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   */
  BasRoomTimers.prototype.uiReorder = function (
    spliceIndex,
    originalIndex
  ) {
    var original

    original = this.uiTimers[originalIndex]

    if (original) {

      this.uiTimers.splice(originalIndex, 1)
      this.uiTimers.splice(
        spliceIndex,
        0,
        original
      )

      this._saveOrder()
    }
  }

  BasRoomTimers.prototype._saveOrder = function () {

    if (this._basRoom &&
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.sharedServerStorage) {

      currentBasCoreState.core.core.sharedServerStorage.updateTimersOrder(
        this._basRoom.id,
        this.uiTimers
      )
    }
  }

  BasRoomTimers.prototype.onOrderUpdated = function (
    _event,
    orderData
  ) {
    var roomUuid, storageUuids, sortedUuids, keys, length, i, _uuid

    if (this._basRoom) {

      roomUuid = this._basRoom.id

      if (BasUtil.isObject(orderData) &&
        Array.isArray(orderData[roomUuid])) {

        storageUuids = orderData[roomUuid]
      }
    }

    if (storageUuids &&
      !BasUtil.isEqualArray(this.uiTimers, storageUuids)) {

      sortedUuids = []

      // Match devices from server storage

      length = storageUuids.length
      for (i = 0; i < length; i++) {

        _uuid = storageUuids[i]

        if (this.timers[_uuid]) {

          if (sortedUuids.indexOf(_uuid) < 0) {

            sortedUuids.push(_uuid)
          }
        }
      }

      // Match devices NOT in server storage

      keys = Object.keys(this.timers)
      length = keys.length
      for (i = 0; i < length; i++) {

        _uuid = keys[i]

        if (sortedUuids.indexOf(_uuid) < 0) {

          sortedUuids.push(_uuid)
        }
      }

      // Set timers

      this.uiTimers = sortedUuids
    }
  }

  BasRoomTimers.prototype._syncOrder = function () {

    var orderData

    if (CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.sharedServerStorage) {

      orderData =
        currentBasCoreState.core.core.sharedServerStorage.timersOrder

      if (BasUtil.isNEObject(orderData)) {

        this.onOrderUpdated(null, orderData)
      }
    }
  }

  BasRoomTimers.prototype._setDeviceListeners = function () {

    var device = this._getDevice()

    this._clearDeviceListeners()

    if (device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        device,
        BAS_API.TimerCtrlDevice.EVT_TIMERS_CHANGED,
        this._handleTimersChanged
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        device,
        BAS_API.TimerCtrlDevice.EVT_TIMER_CHANGED,
        this._handleTimerChanged
      ))
    }
  }

  BasRoomTimers.prototype.updateTranslation = function updateTranslation () {

    var length, i, uuid, timer

    length = this.uiTimers.length
    for (i = 0; i < length; i++) {

      uuid = this.uiTimers[i]
      timer = this.timers[uuid]

      if (timer) timer.updateTranslation()
    }
  }

  BasRoomTimers.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._listeners = []
  }

  BasRoomTimers.prototype.suspend = function () {

    this._clearDeviceListeners()
  }

  BasRoomTimers.prototype.clear = function () {

    this.suspend()
  }

  BasRoomTimers.prototype.resetCss = function () {

  }

  return BasRoomTimers
}
