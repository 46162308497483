'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

/**
 * @typedef {Object} TBasTrackOptions
 * @property {string} [coverArtPrefix]
 * @property {boolean} [resetBasic = false]
 * @property {boolean} [onlyChanges = true]
 * @property {boolean} [album = false]
 */

/**
 * Represents a song/track
 *
 * @constructor
 */
function BasTrack () {

  /**
   * @type {string}
   */
  this.title = undefined

  /**
   * @type {string}
   */
  this.artist = undefined

  /**
   * @type {string}
   */
  this.album = undefined

  /**
   * @type {string}
   * @since 3.4.0
   */
  this.albumArtist = undefined

  /**
   * @type {string}
   * @since 3.4.0
   */
  this.coverartImage = undefined

  /**
   * @type {Object}
   * @since 3.4.0
   */
  this.coverartImages = undefined

  /**
   * @type {string}
   */
  this.context = undefined

  /**
   * @type {string}
   */
  this.uri = undefined

  /**
   * @type {string}
   * @since 3.4.0
   */
  this.contextAlbumUri = undefined

  /**
   * @type {string}
   * @since 3.4.0
   */
  this.contextArtistUri = undefined

  /**
   * @type {string}
   * @since 3.4.0
   */
  this.contextUri = undefined

  /**
   * @type {number}
   * @since 3.4.0
   */
  this.lengthMs = 0

  /**
   * @type {Object}
   */
  this.spotify = undefined

  /**
   * @type {boolean}
   * @since 3.4.0
   */
  this.available = true

  /**
   * @type {number}
   * @since 3.4.0
   */
  this.trackNumber = undefined

  /**
   * @type {number}
   * @since 3.6.4
   */
  this.id = -1

  /**
   * @type {number}
   * @since 3.6.4
   */
  this.pos = -1

  /**
   * @type {string}
   * @since 3.6.4
   */
  this.type = BasTrack.TYPE_TRACK
}

/**
 * Name of property added by BasCore
 *
 * @constant {string}
 */
BasTrack.K_COVERART_URL = 'coverartUrl'

/**
 * Name of property added by BasCore
 *
 * @constant {string}
 */
BasTrack.K_THUMBNAIL_URL = 'thumbnailUrl'

/**
 * @constant {string}
 */
BasTrack.TYPE_TRACK = 'track'

/**
 * @constant {string}
 */
BasTrack.TYPE_ALBUM = 'album'

/**
 * Always returns a track if obj is an object
 *
 * @param {Object} obj
 * @param {TBasTrackOptions} [options]
 * @returns {?BasTrack}
 */
BasTrack.parse = function (obj, options) {

  var _track

  if (BasUtil.isObject(obj)) {

    _track = new BasTrack()
    _track.parse(obj, options)

    return _track
  }

  return null
}

/**
 * If current track is the same as the parsed track from given message,
 * the current track will be returned.
 * Otherwise the parsed track from given message is returned.
 *
 * @param {Object} incoming
 * @param {?BasTrack} existing
 * @param {TBasTrackOptions} [options]
 * @returns {?BasTrack}
 * @since 3.4.0
 */
BasTrack.parseIfSame = function (
  incoming,
  existing,
  options
) {
  var newTrack

  newTrack = BasTrack.parse(incoming, options)

  if (existing instanceof BasTrack) {

    return newTrack
      ? existing.equals(newTrack) ? existing : newTrack
      : null
  }

  return newTrack
}

Object.defineProperties(BasTrack.prototype, {

  /**
   * @name BasTrack#coverart
   * @type {?string}
   * @readonly
   */
  coverart: {
    get: function () {
      var keys
      if (BasUtil.isObject(this.coverartImages)) {

        keys = Object.keys(this.coverartImages)
        if (keys.length > 0) {

          return keys.indexOf(P.ORIG) === -1
            ? this.coverartImages[keys[0]]
            : this.coverartImages[P.ORIG]
        }
      }
      return this.coverartImage
    }
  },

  /**
   * @name BasTrack#length
   * @type {string}
   * @readonly
   */
  length: {
    get: function () {
      return BasUtil.isPNumber(this.lengthMs)
        ? Math.round(this.lengthMs / 1000)
        : 0
    }
  }
})

/**
 * Parse a song/track object coming from the server.
 *
 * @param {?Object} song
 * @param {TBasTrackOptions} [options]
 */
BasTrack.prototype.parse = function (song, options) {

  if (BasUtil.isObject(song)) {

    if (P.URI in song) {

      this.parseAv(song, options)

    } else {

      this.parseAsano(song, options)
    }
  }
}

/**
 * Parses a track according to a new Music API message
 *
 * @param {Object} track
 * @param {TBasTrackOptions} [options]
 * @since 3.4.0
 */
BasTrack.prototype.parseAv = function (
  track,
  options
) {
  var _coverArtPrefix, _isAlbum, value, extra

  _coverArtPrefix = ''
  _isAlbum = false

  if (BasUtil.isObject(options)) {

    if (BasUtil.isNEString(options.coverArtPrefix)) {

      _coverArtPrefix = options.coverArtPrefix
    }

    if (options.album === true) {

      _isAlbum = true
    }
  }

  // Uri

  value = track[P.URI]

  if (BasUtil.isNEString(value)) {

    this.setURI(value)
  }

  // Title

  value = track[P.TITLE]

  if (BasUtil.isNEString(value)) {

    this.setTitle(value)
  }

  // Artist

  value = track[P.ARTIST]

  if (BasUtil.isObject(value)) {

    if (BasUtil.isNEString(value[P.URI])) {

      this.setContextArtistUri(value[P.URI])
    }

    if (BasUtil.isNEString(value[P.NAME])) {

      this.setArtist(value[P.NAME])
    }
  }

  // Album

  value = track[P.ALBUM]

  if (BasUtil.isObject(value)) {

    // Uri

    extra = value[P.URI]
    if (BasUtil.isNEString(extra)) this.setContextAlbumURI(extra)

    // Name

    extra = value[P.NAME]
    if (BasUtil.isNEString(extra)) this.setAlbum(extra)

    // Artist

    extra = value[P.ARTIST]
    if (BasUtil.isObject(extra)) this.setAlbumArtist(extra)

    // Images

    extra = this._parseImages(value[P.IMAGES], _coverArtPrefix)
    if (extra) this.setCoverart(extra)
  }

  // Track number

  value = track[P.TRACK]

  if (BasUtil.isPNumber(value, false)) {

    this.setTrackNumber(value)
  }

  // Duration

  value = track[P.DURATION_MS]

  if (BasUtil.isPNumber(value, false)) {

    this.setLength(track[P.DURATION_MS], true)
  }

  // Available

  value = track[P.AVAILABLE]

  if (BasUtil.isBool(value)) {

    this.setAvailable(value)
  }

  // Id

  value = track[P.ID]

  if (BasUtil.isPNumber(value, true)) {

    this.setId(value)
  }

  // Pos

  value = track[P.POS]

  if (BasUtil.isPNumber(value, true)) {

    this.setPos(value)
  }

  if (_isAlbum) {

    this.type = BasTrack.TYPE_ALBUM

    value = this._parseImages(track[P.IMAGES], _coverArtPrefix)
    if (value) this.setCoverart(value)

    value = track[P.NAME]
    if (BasUtil.isNEString(value)) this.setTitle(value)
  }
}

/**
 * @private
 * @param {?Object} value
 * @param {?string} [coverArtPrefix]
 * @returns {?Object}
 */
BasTrack.prototype._parseImages = function (value, coverArtPrefix) {

  var extra, keys, length, i, _coverArtPrefix

  _coverArtPrefix = BasUtil.isString(coverArtPrefix)
    ? coverArtPrefix
    : ''

  if (BasUtil.isObject(value)) {

    extra = BasUtil.copyObject(value)

    if (_coverArtPrefix) {

      keys = Object.keys(extra)
      length = keys.length
      for (i = 0; i < length; i++) {

        if (BasUtil.hasValidKnownScheme(extra[keys[i]])) {

          extra[keys[i]] = _coverArtPrefix + extra[keys[i]]
        }
      }

    } else {

      extra = value
    }

    return extra
  }

  return null
}

/**
 * Parse a song/track object coming from the server.
 *
 * @param {?Object} song
 * @param {Object} [options]
 * @param {boolean} [options.resetBasic = false]
 * @param {boolean} [options.onlyChanges = true]
 * @since 3.4.0
 */
BasTrack.prototype.parseAsano = function (
  song,
  options
) {
  var resetBasic, onlyChanges

  resetBasic = false
  onlyChanges = true

  if (BasUtil.isObject(options)) {

    resetBasic = options.resetBasic === true
    if (options.onlyChanges === false) onlyChanges = false
  }

  if (BasUtil.isObject(song)) {

    if (onlyChanges) {

      if (P.TITLE in song) this.setTitle(song[P.TITLE])
      if (P.ARTIST in song) this.setArtist(song[P.ARTIST])
      if (P.ALBUM in song) this.setAlbum(song[P.ALBUM])
      if (P.COVERART in song) {
        this.setCoverart(song[P.COVERART])
      }
      if (P.CONTEXT in song) this.setContext(song[P.CONTEXT])
      if (P.CONTEXT_URI in song) {
        this.setContextURI(song[P.CONTEXT_URI])
      }
      if (P.LENGTH in song) this.setLength(song[P.LENGTH])
      if (P.SPOTIFY in song) this.spotify = song[P.SPOTIFY]

    } else {

      this.setTitle(song[P.TITLE])
      this.setArtist(song[P.ARTIST])
      this.setAlbum(song[P.ALBUM])
      this.setCoverart(song[P.COVERART])
      this.setContext(song[P.CONTEXT])
      this.setContextURI(song[P.CONTEXT_URI])
      this.setLength(song[P.LENGTH])
      this.spotify = P.SPOTIFY in song
        ? song[P.SPOTIFY]
        : undefined
    }

  } else if (!onlyChanges) {

    if (resetBasic) {
      this.resetBasic()
    } else {
      this.resetAll()
    }
  }
}

/**
 * Create a hash for the BasTrack to compare differences
 *
 * @returns {string}
 */
BasTrack.prototype.hash = function hash () {

  var str = ''

  if (BasUtil.isNEString(this.title)) str += this.title
  if (BasUtil.isNEString(this.artist)) str += this.artist
  if (BasUtil.isNEString(this.album)) str += this.album
  if (BasUtil.isNEString(this.coverart)) str += this.coverart
  if (BasUtil.isNEString(this.context)) str += this.context
  if (BasUtil.isNEString(this.contextUri)) str += this.contextUri
  if (BasUtil.isNEString(this.uri)) str += this.uri
  if (BasUtil.isObject(this.spotify) &&
    BasUtil.isObject(this.spotify.restrictions)) {

    str += Object.keys(this.spotify.restrictions).join('')
  }
  str += this.lengthMs

  return str
}

/**
 * @param {string} value
 */
BasTrack.prototype.setTitle = function setTitle (value) {

  this.title = BasUtil.isNEString(value)
    ? value
    : undefined
}

/**
 * @param {string} value
 */
BasTrack.prototype.setArtist = function setArtist (value) {

  this.artist = BasUtil.isNEString(value)
    ? value
    : undefined
}

/**
 * @param {string} value
 */
BasTrack.prototype.setAlbum = function setAlbum (value) {

  this.album = BasUtil.isNEString(value)
    ? value
    : undefined
}

/**
 * @param {(Object | string)} value
 */
BasTrack.prototype.setCoverart = function (value) {

  if (BasUtil.isNEString(value)) {

    this.coverartImage = value
    this.coverartImages = undefined

  } else if (BasUtil.isObject(value)) {

    this.coverartImage = undefined
    this.coverartImages = value

  } else {

    this.coverartImage = undefined
    this.coverartImages = undefined
  }
}

/**
 * @param {string} value
 */
BasTrack.prototype.setContext = function (value) {

  this.context = BasUtil.isNEString(value)
    ? value
    : undefined
}

/**
 * @param {string} value
 */
BasTrack.prototype.setContextURI = function (value) {

  this.contextUri = BasUtil.isNEString(value)
    ? value
    : undefined
}

/**
 * @param {string} value
 */
BasTrack.prototype.setURI = function (value) {

  this.uri = BasUtil.isNEString(value)
    ? value
    : undefined
}

/**
 * @param {string} value
 */
BasTrack.prototype.setContextAlbumURI = function (value) {

  this.contextAlbumUri = BasUtil.isNEString(value)
    ? value
    : undefined
}

/**
 * @param {string} value
 */
BasTrack.prototype.setContextArtistUri = function (value) {

  this.contextArtistUri = BasUtil.isNEString(value)
    ? value
    : undefined
}

/**
 * @param {string} value
 */
BasTrack.prototype.setAlbumArtist = function (value) {

  this.albumArtist = BasUtil.isNEString(value)
    ? value
    : undefined
}

/**
 * @param {number} value
 * @param {boolean} [useMs = false]
 */
BasTrack.prototype.setLength = function (value, useMs) {

  this.lengthMs = BasUtil.isPNumber(value, true)
    ? useMs ? value : value * 1000
    : 0
}

/**
 * @param {boolean} value
 */
BasTrack.prototype.setAvailable = function (value) {

  this.available = BasUtil.isBool(value)
    ? value
    : true
}

/**
 * @param {number} value
 */
BasTrack.prototype.setTrackNumber = function (value) {

  this.trackNumber = BasUtil.isPNumber(value, false)
    ? value
    : undefined
}

/**
 * @param {number} value
 */
BasTrack.prototype.setId = function (value) {

  this.id = BasUtil.isPNumber(value, true)
    ? value
    : -1
}

/**
 * @param {number} value
 */
BasTrack.prototype.setPos = function (value) {

  this.pos = BasUtil.isPNumber(value, true)
    ? value
    : -1
}

/**
 * @param {BasTrack} track
 * @returns {boolean}
 */
BasTrack.prototype.equals = function (track) {

  return (
    this.title === track.title &&
    this.artist === track.artist &&
    this.album === track.album &&
    this.lengthMs === track.lengthMs &&
    this.coverart === track.coverart
  )
}

/**
 * Resets the basic properties. (no cover art)
 * <ul>
 *     <li>title</li>
 *     <li>artist</li>
 *     <li>album</li>
 *     <li>context</li>
 *     <li>contextURI</li>
 *     <li>length</li>
 * </ul>
 */
BasTrack.prototype.resetBasic = function resetBasic () {

  this.title = undefined
  this.artist = undefined
  this.album = undefined
  this.context = undefined
  this.uri = undefined
  this.lengthMs = 0
}

/**
 * Resets all properties
 */
BasTrack.prototype.resetAll = function resetAll () {

  this.resetBasic()

  this.coverartImage = undefined
  this.coverartImages = undefined
  this.spotify = undefined
  this.available = true
  this.trackNumber = undefined
  this.contextAlbumUri = undefined
  this.contextArtistUri = undefined
}

module.exports = BasTrack
