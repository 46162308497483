'use strict'

/**
 * @constructor
 * @param {Function} resolve
 * @param {Function} reject
 */
function BasRequest (resolve, reject) {

  /**
   * @type {Function}
   */
  this.resolve = resolve

  /**
   * @type {Function}
   */
  this.reject = reject

  /**
   * @type {boolean}
   */
  this.resolved = false

  /**
   * @type {?Object}
   */
  this.data = null

  /**
   * @type {number}
   */
  this.start = 0

  /**
   * @type {number}
   */
  this.end = 0
}

module.exports = BasRequest
