'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

/**
 * @constructor
 * @since 3.0.0
 */
function BasCoreProjectInfo () {

  /**
   * @type {string}
   */
  this.uuid = ''

  /**
   * @type {string}
   */
  this.coreName = ''

  /**
   * @type {string}
   */
  this.name = ''

  /**
   * @type {boolean}
   */
  this.master = true

  /**
   * @type {boolean}
   */
  this.integratorAccess = false
}

/**
 * @param {Object} obj
 * @returns {?BasCoreProjectInfo}
 */
BasCoreProjectInfo.parse = function (obj) {

  var result, _value

  if (BasUtil.isObject(obj)) {

    result = new BasCoreProjectInfo()

    // Uuid

    _value = obj[P.UUID]

    if (!BasUtil.isNEString(_value)) return null

    result.uuid = _value

    // CoreName

    _value = obj[P.CORE_NAME]

    if (BasUtil.isNEString(_value)) result.coreName = _value

    // Name

    _value = obj[P.NAME]

    if (BasUtil.isNEString(_value)) result.name = _value

    // Master

    _value = obj[P.MASTER]

    if (BasUtil.isBool(_value)) result.master = _value

    // IntegratorAccess

    _value = obj[P.INTEGRATOR_ACCESS]

    if (BasUtil.isBool(_value)) result.integratorAccess = _value

    return result
  }

  return null
}

module.exports = BasCoreProjectInfo
