'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSceneSchedulerState', [
    '$transitions',
    'STATES',
    'RoomsHelper',
    'UiHelper',
    BasSceneSchedulerState
  ])

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {RoomsHelper} RoomsHelper
 * @param {UiHelper} UiHelper
 */
function BasSceneSchedulerState (
  $transitions,
  STATES,
  RoomsHelper,
  UiHelper
) {
  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  init()

  function init () {

    $transitions.onStart(
      {
        to: STATES.SCHEDULES
      },
      _onBasSceneSchedulerState
    )
  }

  function _onBasSceneSchedulerState (transition) {

    var target, toName, from, fromName, homeScheduler

    target = transition.targetState()
    toName = target.name()
    from = transition.from()
    fromName = from.name

    if (toName === STATES.SCHEDULES) {

      homeScheduler = _getHomeScheduler()

      if (homeScheduler) {

        if (
          basUi.prop.wMedium &&
          homeScheduler.uiSchedules &&
          homeScheduler.uiSchedules.length > 0
        ) {
          return target
            .withState(STATES.SCHEDULE)
            .withParams({
              schedule: homeScheduler.uiSchedules[0]
            })
        }

      } else {

        return BasUtil.startsWith(fromName, STATES.SCHEDULES)
          ? target.withState(STATES.HOME)
          : false
      }
    }
  }

  /**
   * @private
   * @returns {?BasRoomScheduler}
   */
  function _getHomeScheduler () {

    var home

    home = RoomsHelper.getHome()

    return (home && home.scheduler && home.scheduler.addNewSchedule)
      ? home.scheduler
      : null
  }
}
