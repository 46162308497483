'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('RoomsState', [
    '$rootScope',
    '$uiRouterGlobals',
    '$transitions',
    'STATES',
    'BAS_STATE',
    'BAS_ROOMS',
    'BasState',
    'CurrentBasCore',
    'BasIntercomHelper',
    RoomsState
  ])

/**
 * State conditions check for rooms
 * Checks if there are rooms for specific view via
 * BAS_ROOMS.ROOMS.info.has*
 *
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param $transitions
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasState} BasState
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasIntercomHelper} BasIntercomHelper
 */
function RoomsState (
  $rootScope,
  $uiRouterGlobals,
  $transitions,
  STATES,
  BAS_STATE,
  BAS_ROOMS,
  BasState,
  CurrentBasCore,
  BasIntercomHelper
) {
  init()

  function init () {

    $transitions.onStart(
      {
        to: STATES.ROOMS
      },
      _onRoomsState
    )

    $rootScope.$on(BAS_ROOMS.EVT_ROOMS_UPDATED, _onRoomsUpdated)
  }

  function _onRoomsUpdated () {

    var newState

    if (
      !$uiRouterGlobals.transition &&
      $uiRouterGlobals.current.name === STATES.ROOMS
    ) {

      newState = _checkView($uiRouterGlobals.params.view)

      if (newState) BasState.go(newState.$state())
    }
  }

  function _onRoomsState (transition) {

    var targetState

    targetState = transition.targetState()

    return _checkView(targetState.params().view, targetState)
  }

  /**
   * @private
   * @param {string} view
   * @param [targetState]
   * @returns {(undefined|Object)}
   */
  function _checkView (view, targetState) {

    var defaultRoomsParams, musicRoomsParams

    defaultRoomsParams = {
      view: BAS_STATE.S_ROOMS_VIEW_HOME
    }
    musicRoomsParams = {
      view: BAS_STATE.S_ROOMS_VIEW_MUSIC
    }

    if (
      CurrentBasCore.isAudioOnly() &&
      view !== BAS_STATE.S_ROOMS_VIEW_MUSIC
    ) {
      return targetState
        ? targetState.withParams(musicRoomsParams)
        : BasState.target(STATES.ROOMS, musicRoomsParams)
    }

    if (
      view === BAS_STATE.S_ROOMS_VIEW_MUSIC &&
      BasUtil.isObject(BAS_ROOMS.ROOMS.info) &&
      !BAS_ROOMS.ROOMS.info.hasMusicRooms
    ) {
      return targetState
        ? targetState.withParams(defaultRoomsParams)
        : BasState.target(STATES.ROOMS, defaultRoomsParams)
    }

    if (
      view === BAS_STATE.S_ROOMS_VIEW_THERMOSTATS &&
      BasUtil.isObject(BAS_ROOMS.ROOMS.info) &&
      !BAS_ROOMS.ROOMS.info.hasThermostatRooms
    ) {
      return targetState
        ? targetState.withParams(defaultRoomsParams)
        : BasState.target(STATES.ROOMS, defaultRoomsParams)
    }

    if (
      view === BAS_STATE.S_ROOMS_VIEW_INTERCOM &&
      BasUtil.isObject(BAS_ROOMS.ROOMS.info) &&
      !BasIntercomHelper.shouldShowIntercom()
    ) {
      return targetState
        ? targetState.withParams(defaultRoomsParams)
        : BasState.target(STATES.ROOMS, defaultRoomsParams)
    }
  }
}
