'use strict'

angular
  .module('basalteApp')
  .constant('BAS_SPOTIFY', {
    BASE_URL: 'https://api.spotify.com',
    PATH_VERSION: 'v1',
    H_AUTHORIZATION: 'Authorization',
    H_TOKEN: 'token',
    H_ACCEPT: 'Accept',
    K_ID: 'id',
    K_DISPLAY_NAME: 'display_name',
    PARAM_QUERY: 'q',
    PARAM_LIMIT: 'limit',
    PARAM_TYPE: 'type',
    PARAM_VALUE_ARTIST: 'artist',
    PARAM_LOCALE: 'locale',
    PARAM_COUNTRY: 'country',
    PARAM_MARKET: 'market',
    PARAM_VALUE_COUNTRY_US: 'US',
    ERR_INVALID_MARKET: 'Invalid market code',
    ERR_INVALID_COUNTRY: 'Invalid country code',
    ERR_NOT_LINKED: 'Not linked'
  })
