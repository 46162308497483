'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basCache', [])
  .provider('BasCache', [
    '$windowProvider',
    BasCacheProvider
  ])

function BasCacheProvider ($windowProvider) {

  var CACHE_INVALID_REQUEST = 'INVALID REQUEST'
  var CACHE_NO_MATCH = 'NO MATCH'
  var CACHE_HTTP_REQUEST_PREFIX = 'http://basalterequestprefix'

  var _cacheStorageSupported, _caches

  var globalPrefix = ''

  /**
   * @private
   * @type {Object<string, (Cache|Object<string, string>)>}
   */
  var _basCaches = {}

  /**
   * @private
   * @type {Object<string, ?Promise>}
   */
  var _basCacheOpenPromises = {}

  this.setPrefix = setPrefix

  init()

  this.$get = ['$injector', basCacheFactory]

  function init () {

    var _window

    _cacheStorageSupported = false

    _window = $windowProvider.$get()

    if (_window && _window.caches) {

      _cacheStorageSupported = true
      _caches = _window.caches
    }
  }

  /**
   * Set a prefix for cache name
   *
   * Passing no arguments or an invalid argument
   * results in the clearing the prefix
   *
   * @param {string} [newPrefix]
   */
  function setPrefix (newPrefix) {

    globalPrefix = BasUtil.isString(newPrefix) ? newPrefix : ''
  }

  // Cache service

  /**
   * Service that handles as a wrapper for
   * cacheStorage
   *
   * @constructor
   * @param $log
   */
  function BasCache ($log) {

    this.get = get
    this.set = set
    this.clear = clear

    /**
     * Get a value from the cache storage
     * Uses Cache Storage if available.
     * Rejects if no match is found.
     *
     * @param {string} cacheName
     * @param {string} key
     * @returns {Promise<string>}
     */
    function get (cacheName, key) {

      var _cacheName, _safeKey

      _cacheName = globalPrefix + cacheName
      _safeKey = getSafeKey(key)

      return BasUtil.isNEString(_safeKey)
        ? _getCache(_cacheName).then(_onCache)
        : Promise.reject(CACHE_INVALID_REQUEST)

      /**
       * @private
       * @param {(Cache|Object<string, string>)} cache
       * @returns {(Promise|string)}
       */
      function _onCache (cache) {

        return BasUtil.isFunction(cache.match)
          ? cache.match(new Request(_safeKey)).then(_onMatch)
          : BasUtil.safeHasOwnProperty(cache, key)
            ? cache[key]
            : Promise.reject(CACHE_NO_MATCH)
      }

      /**
       * @private
       * @param {Response} response
       * @returns {Promise<string>}
       */
      function _onMatch (response) {

        return response.text()
      }
    }

    /**
     * Set a key value pair
     *
     * @param {string} cacheName
     * @param {string} key
     * @param {string} value
     * @returns {Promise}
     */
    function set (cacheName, key, value) {

      var _cacheName
      var _safeKey = getSafeKey(key)

      _cacheName = globalPrefix + cacheName

      return BasUtil.isNEString(key)
        ? _getCache(_cacheName).then(_onCache)
        : Promise.reject(CACHE_INVALID_REQUEST)

      /**
       * @private
       * @param {(Cache|Object<string, string>)} cache
       * @returns {(Promise|string)}
       */
      function _onCache (cache) {

        return BasUtil.isFunction(cache.match)
          ? cache.put(new Request(_safeKey), new Response(value))
          : (cache[key] = value)
      }
    }

    /**
     * Clears cache.
     * Removes all keys from cache with prefix or with exact value
     * depending on exactMatch.
     * Uses globalPrefix if no prefix is given.
     *
     * @param {string} cacheName
     * @returns {Promise}
     */
    function clear (cacheName) {

      var _cacheName, _cache

      _cacheName = globalPrefix + cacheName

      if (!BasUtil.isNEString(_cacheName)) {

        return Promise.reject(CACHE_INVALID_REQUEST)
      }

      _cache = _basCaches[_cacheName]

      if (!BasUtil.isObject(_cache)) return Promise.resolve(false)

      return BasUtil.isFunction(_cache.match)
        ? _caches.delete(_cacheName).then(_onCacheDelete)
        : _onCacheDelete(true)

      /**
       * @private
       * @param {boolean} result
       * @returns {boolean}
       */
      function _onCacheDelete (result) {

        _basCaches[_cacheName] = null

        return result
      }
    }

    /**
     * Returns a promise with an object to save requests.
     * This is a cache object if the browser allows it.
     * A regular object otherwise.
     *
     * @param {string} cacheName Final cache name
     * @returns {Promise<(Cache|Object<string, string>)>}
     */
    function _getCache (cacheName) {

      var _cache

      _cache = _basCaches[cacheName]

      if (_cache) return Promise.resolve(_cache)

      if (!_cacheStorageSupported) {

        return Promise.resolve(_retrieveEmulatedCache(cacheName))
      }

      return _createCache(cacheName)
    }

    /**
     * @private
     * @param {string} cacheName Final cache name
     * @returns {Promise<(Cache|Object<string, string>)>}
     */
    function _createCache (cacheName) {

      var _cachePromise

      _cachePromise = _basCacheOpenPromises[cacheName]

      if (_cachePromise) return _cachePromise

      _cachePromise = _basCacheOpenPromises[cacheName] =
        _caches.open(cacheName)
          .then(_onCacheOpen, _onCacheOpenError)

      _cachePromise.then(_onCacheFinal)

      return _cachePromise

      /**
       * @private
       * @param {Cache} cache
       * @returns {Cache}
       */
      function _onCacheOpen (cache) {

        if (_basCaches[cacheName]) {

          $log.warn('Cache open, exists already', cacheName)
        }

        _basCaches[cacheName] = cache

        return cache
      }

      /**
       * @private
       * @returns {Object<string, string>}
       */
      function _onCacheOpenError () {

        return _retrieveEmulatedCache(cacheName)
      }

      function _onCacheFinal () {

        _basCacheOpenPromises[cacheName] = null
      }
    }

    /**
     * @private
     * @param {string} cacheName
     * @returns {Object<string, string>}
     */
    function _retrieveEmulatedCache (cacheName) {

      var _obj

      _obj = _basCaches[cacheName]

      return BasUtil.isObject(_obj)
        ? _obj
        : (_basCaches[cacheName] = {})
    }

    /**
     * Returns a safe key to use on all platforms
     * e.g. keys need to be prefixed with http of https on ios devices
     *
     * @param key
     * @returns {string}
     */
    function getSafeKey (key) {

      var _safeKey = key

      if (BasUtil.isNEString(key) &&
        !BasUtil.hasValidKnownScheme(key)) {

        _safeKey = CACHE_HTTP_REQUEST_PREFIX + key
      }

      return _safeKey
    }
  }

  // Factory

  function basCacheFactory ($injector) {

    return $injector.instantiate(['$log', BasCache])
  }
}
