'use strict'

angular
  .module('basalteApp')
  .directive('deviceStatusBar', deviceStatusBar)

function deviceStatusBar () {

  return {
    restrict: 'AE',
    template:
      '<div class="' +
      'bbs bbs-item bbs-centered' +
      ' bbs-flex bbs-active-select"' +
      '     bas-click="statusBar.toggleStatusBar()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      '       data-translate="show_status_bar_device"></div>' +
      '  <bas-toggle class="bbs-toggle bas-toggle-dark"' +
      '              data-bas-toggle="statusBar.toggleStatusBar()"' +
      '              data-bas-toggled="statusBar.showStatusBar"></bas-toggle>' +
      '</div>',
    controller: [
      'BasPreferences',
      controller
    ],
    controllerAs: 'statusBar'
  }

  /**
   * @param {BasPreferences} BasPreferences
   */
  function controller (BasPreferences) {

    var statusBar = this

    statusBar.showStatusBar = BasPreferences.getShowStatusBar()

    statusBar.toggleStatusBar = toggleStatusBar

    function toggleStatusBar () {

      statusBar.showStatusBar = !statusBar.showStatusBar
      BasPreferences.setShowStatusBar(statusBar.showStatusBar)
    }
  }
}
