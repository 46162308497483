{
  "61": {
    "playlists": [
      {
        "favourite": true,
        "id": "GeorgeTopHits",
        "locked": false,
        "owned": true,
        "owner": "George",
        "shared": true
      },
      {
        "favourite": true,
        "id": "GeorgeWeekend",
        "locked": false,
        "owned": true,
        "owner": "George",
        "shared": false
      },
      {
        "favourite": false,
        "id": "GeorgeWorkout",
        "locked": false,
        "owned": true,
        "owner": "George",
        "shared": true
      },
      {
        "favourite": false,
        "id": "GeorgeRelax",
        "locked": false,
        "owned": true,
        "owner": "George",
        "shared": false
      }
    ],
    "sharedPlaylists": [
      {
        "favourite": false,
        "id": "JaneRelax",
        "locked": false,
        "owner": "Jane",
        "shared": true
      },
      {
        "favourite": false,
        "id": "ElroyParty",
        "locked": false,
        "owner": "Elroy",
        "shared": true
      }
    ],
    "iTunesPlaylists": [
      {
        "favourite": false,
        "id": "iTunesTop25",
        "locked": false
      }
    ]
  },
  "62": {
    "playlists": [
      {
        "favourite": false,
        "id": "JaneRelax",
        "locked": false,
        "owned": true,
        "owner": "Jane",
        "shared": true
      },
      {
        "favourite": false,
        "id": "JaneCooking",
        "locked": false,
        "owned": true,
        "owner": "Jane",
        "shared": false
      },
      {
        "favourite": false,
        "id": "JaneWorkout",
        "locked": false,
        "owned": true,
        "owner": "Jane",
        "shared": false
      },
      {
        "favourite": false,
        "id": "JaneWeekend",
        "locked": false,
        "owned": true,
        "owner": "Jane",
        "shared": false
      },
      {
        "favourite": false,
        "id": "Lisa",
        "locked": false,
        "owned": true,
        "owner": "Jane",
        "shared": false
      }
    ],
    "sharedPlaylists": [
      {
        "favourite": false,
        "id": "ElroyParty",
        "locked": false,
        "owner": "Elroy",
        "shared": true
      },
      {
        "favourite": false,
        "id": "GeorgeTopHits",
        "locked": false,
        "owner": "George",
        "shared": true
      },
      {
        "favourite": false,
        "id": "GeorgeWorkout",
        "locked": false,
        "owner": "George",
        "shared": true
      }
    ],
    "iTunesPlaylists": [
      {
        "favourite": false,
        "id": "iTunesTop25",
        "locked": false
      }
    ]
  },
  "63": {
    "playlists": [
      {
        "favourite": false,
        "id": "JudyFitness",
        "locked": false,
        "owned": true,
        "owner": "Judy",
        "shared": false
      },
      {
        "favourite": false,
        "id": "JudyParty",
        "locked": false,
        "owned": true,
        "owner": "Judy",
        "shared": false
      },
      {
        "favourite": false,
        "id": "JudyRelax",
        "locked": false,
        "owned": true,
        "owner": "Judy",
        "shared": false
      },
      {
        "favourite": false,
        "id": "JudyStudy",
        "locked": false,
        "owned": true,
        "owner": "Judy",
        "shared": false
      }
    ],
    "sharedPlaylists": [
      {
        "favourite": false,
        "id": "JaneRelax",
        "locked": false,
        "owner": "Jane",
        "shared": true
      },
      {
        "favourite": false,
        "id": "ElroyParty",
        "locked": false,
        "owner": "Elroy",
        "shared": true
      },
      {
        "favourite": false,
        "id": "GeorgeTopHits",
        "locked": false,
        "owner": "George",
        "shared": true
      },
      {
        "favourite": false,
        "id": "GeorgeWorkout",
        "locked": false,
        "owner": "George",
        "shared": true
      }
    ],
    "iTunesPlaylists": [
      {
        "favourite": false,
        "id": "iTunesTop25",
        "locked": false
      }
    ]
  },
  "64": {
    "playlists": [
      {
        "favourite": false,
        "id": "ElroyParty",
        "locked": false,
        "owned": true,
        "owner": "Elroy",
        "shared": true
      },
      {
        "favourite": false,
        "id": "ElroyFitness",
        "locked": false,
        "owned": true,
        "owner": "Elroy",
        "shared": true
      },
      {
        "favourite": false,
        "id": "ElroyStudy",
        "locked": false,
        "owned": true,
        "owner": "Elroy",
        "shared": true
      },
      {
        "favourite": false,
        "id": "ElroyRelax",
        "locked": false,
        "owned": true,
        "owner": "Elroy",
        "shared": true
      }
    ],
    "sharedPlaylists": [
      {
        "favourite": false,
        "id": "JaneRelax",
        "locked": false,
        "owner": "Jane",
        "shared": true
      },
      {
        "favourite": false,
        "id": "GeorgeTopHits",
        "locked": false,
        "owner": "George",
        "shared": true
      },
      {
        "favourite": false,
        "id": "GeorgeWorkout",
        "locked": false,
        "owner": "George",
        "shared": true
      }
    ],
    "iTunesPlaylists": [
      {
        "favourite": false,
        "id": "iTunesTop25",
        "locked": false
      }
    ]
  }
}
