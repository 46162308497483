'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomDoorPhones', [
    'CurrentBasCore',
    'BasDoorPhone',
    basRoomDoorPhones
  ])

/**
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasDoorPhone
 * @returns BasRoomDoorPhones
 */
function basRoomDoorPhones (
  CurrentBasCore,
  BasDoorPhone
) {
  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomDoorPhones (basRoom) {

    /**
     * @type {BasDoorPhone[]}
     */
    this.doorPhones = []

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomDoorPhones.hasDoorPhones = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.doorPhones)
    )
  }

  BasRoomDoorPhones.prototype.parseRoom = function () {

    if (BasRoomDoorPhones.hasDoorPhones(this._basRoom)) {

      this.syncDoorPhones()
    }
  }

  BasRoomDoorPhones.prototype.syncDoorPhones = function () {

    var length, i, uuid, device

    this.resetDoorPhones()

    if (BasRoomDoorPhones.hasDoorPhones(this._basRoom)) {

      length = this._basRoom.room.doorPhones.length
      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.doorPhones[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) {

          this.doorPhones.push(new BasDoorPhone(device))
        }
      }
    }
  }

  /**
   * Clear all door phone gateways
   */
  BasRoomDoorPhones.prototype.clearDoorPhones = function () {

    var length, i

    length = this.doorPhones.length
    for (i = 0; i < length; i++) {

      if (this.doorPhones[i]) this.doorPhones[i].clear()
    }
  }

  BasRoomDoorPhones.prototype.resetDoorPhones = function () {

    this.clearDoorPhones()

    this.doorPhones = []
  }

  /**
   * Clears the BasRoom reference
   */
  BasRoomDoorPhones.prototype.destroy = function () {

    this._basRoom = null
  }

  return BasRoomDoorPhones
}
