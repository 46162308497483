'use strict'

angular
  .module('basalteApp')
  .service('BasApp', [
    '$window',
    '$rootScope',
    'APP_CONFIG',
    'GIT_INFO',
    'BAS_APP',
    'BasSupports',
    'BasAppDevice',
    'Logger',
    BasApp
  ])

/**
 * @typedef {Object} TBasAppState
 * @property {string} version
 * @property {string} versionFull
 * @property {boolean} isPaused
 * @property {string} networkConnectionType
 */

/**
 * Helper service for several App functions
 *
 * @constructor
 * @param $window
 * @param $rootScope
 * @param APP_CONFIG
 * @param GIT_INFO
 * @param {BAS_APP} BAS_APP
 * @param {BasSupports} BasSupports
 * @param {BasAppDevice} BasAppDevice
 * @param {Logger} Logger
 */
function BasApp (
  $window,
  $rootScope,
  APP_CONFIG,
  GIT_INFO,
  BAS_APP,
  BasSupports,
  BasAppDevice,
  Logger
) {
  var VERSION_RELEASE = APP_CONFIG.major + '.' +
    APP_CONFIG.minor + '.' +
    APP_CONFIG.patch
  var VERSION_FULL = APP_CONFIG.major + '.' +
    APP_CONFIG.minor + '.' +
    APP_CONFIG.patch + '.' +
    APP_CONFIG.build
  var VERSION_DEBUG = GIT_INFO.describeTags

  var _passiveCaptureEventListenerOptions = {
    capture: true,
    passive: true,
    once: false
  }

  let onNetworkTypeChangedTimeoutId = 0

  /**
   * @type {TBasSupports}
   */
  var basSupports = BasSupports.get()

  /**
   * @type {BasAppDeviceState}
   */
  var basAppDevice = BasAppDevice.get()

  /**
   * @type {TBasAppState}
   */
  var basAppState = {}
  basAppState.version = basAppDevice.debugBuild
    ? VERSION_DEBUG
    : VERSION_RELEASE
  basAppState.versionFull = VERSION_FULL
  basAppState.isPaused = false
  basAppState.networkConnectionType = 'none'

  this.get = get
  this.isPaused = isPaused

  init()

  function init () {

    if (
      $window.navigator &&
      $window.navigator.connection &&
      $window.navigator.connection.type
    ) {
      basAppState.networkConnectionType = $window.navigator.connection.type
    }

    _setAppListeners()
  }

  /**
   * @returns {TBasAppState}
   */
  function get () {
    return basAppState
  }

  /**
   * @returns {boolean}
   */
  function isPaused () {
    return basAppState.isPaused
  }

  /**
   * Set all app listeners (cordova and others)
   *
   * @private
   */
  function _setAppListeners () {

    // WKWebView pseudo-class 'active'
    $window.document.addEventListener(
      'touchstart',
      _empty,
      basSupports.passiveListeners
        ? _passiveCaptureEventListenerOptions
        : true
    )

    // Cordova events
    $window.document.addEventListener(
      'resume',
      _onResume,
      basSupports.passiveListeners
        ? _passiveCaptureEventListenerOptions
        : true
    )
    $window.document.addEventListener(
      'pause',
      _onPause,
      basSupports.passiveListeners
        ? _passiveCaptureEventListenerOptions
        : true
    )

    // Network connection events
    $window.document.addEventListener(
      'online',
      _onOnline,
      basSupports.passiveListeners
        ? _passiveCaptureEventListenerOptions
        : true
    )
    $window.document.addEventListener(
      'offline',
      _onOffline,
      basSupports.passiveListeners
        ? _passiveCaptureEventListenerOptions
        : true
    )

    // iOS only events
    $window.document.addEventListener(
      'resign',
      _onResign,
      basSupports.passiveListeners
        ? _passiveCaptureEventListenerOptions
        : true
    )
    $window.document.addEventListener(
      'active',
      _onActive,
      basSupports.passiveListeners
        ? _passiveCaptureEventListenerOptions
        : true
    )
  }

  /**
   * Cordova event - Resume
   */
  function _onResume () {

    Logger.info('App - Cordova RESUME')

    // Reset has paused
    basAppState.isPaused = false

    $rootScope.$emit(BAS_APP.EVT_RESUME)
  }

  /**
   * Cordova event - Pause
   */
  function _onPause () {

    // Set paused state
    basAppState.isPaused = true

    Logger.info('App - Cordova PAUSE')

    $rootScope.$emit(BAS_APP.EVT_PAUSE)
  }

  /**
   * Cordova event - network online
   */
  function _onOnline () {

    Logger.info('App - Cordova ONLINE', $window.navigator.connection.type)

    if (!onNetworkTypeChangedTimeoutId) {

      onNetworkTypeChangedTimeoutId = setTimeout(
        _onNetworkTypeChangedTimeout,
        1000
      )
    }
  }

  /**
   * Cordova event - network offline
   */
  function _onOffline () {

    Logger.info('App - Cordova OFFLINE')

    if (!onNetworkTypeChangedTimeoutId) {

      onNetworkTypeChangedTimeoutId = setTimeout(
        _onNetworkTypeChangedTimeout,
        1000
      )
    }
  }

  function _onNetworkTypeChangedTimeout () {

    onNetworkTypeChangedTimeoutId = 0

    const networkConnectionType = $window.navigator.connection.type

    if (basAppState.networkConnectionType !== networkConnectionType) {

      Logger.info(
        'App - Cordova network connection type changed to ',
        networkConnectionType
      )

      basAppState.networkConnectionType = networkConnectionType

      if (basAppState.networkConnectionType === 'none') {

        $rootScope.$emit(BAS_APP.EVT_NETWORK_CONNECTION_OFFLINE)

      } else {

        $rootScope.$emit(
          BAS_APP.EVT_NETWORK_CONNECTION_CHANGED,
          basAppState.networkConnectionType
        )
      }
    }
  }

  /**
   * iOS only event fires before onPause
   */
  function _onResign () {

    Logger.info('App - Cordova RESIGN')

    $rootScope.$emit(BAS_APP.EVT_RESIGN)
  }

  /**
   * iOS only event
   */
  function _onActive () {

    // Reset has paused
    basAppState.isPaused = false

    $rootScope.$emit(BAS_APP.EVT_ACTIVE)
  }

  function _empty () {}
}
