'use strict'

angular
  .module('basalteApp')
  .service('BasStateHistoryReset', [
    '$transitions',
    'STATES',
    'BasStateHistory',
    'BasStateHelper',
    BasStateHistoryReset
  ])

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {BasStateHistory} BasStateHistory
 * @param {BasStateHelper} BasStateHelper
 */
function BasStateHistoryReset (
  $transitions,
  STATES,
  BasStateHistory,
  BasStateHelper
) {
  $transitions.onStart(
    {},
    _onStart
  )

  function _onStart (transition) {

    var targetState, from, fromName, toName

    targetState = transition.targetState()
    from = transition.from()
    fromName = from.name
    toName = targetState.name()

    if (
      BasStateHelper.hasBaseState(toName, STATES.MAIN) &&
      !BasStateHelper.hasBaseState(fromName, STATES.MAIN)
    ) {
      BasStateHistory.clear()
    }
  }
}
