'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasOpenCloseDevices', [
    '$rootScope',
    'BAS_CURRENT_CORE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_OPEN_CLOSE_DEVICE',
    'BAS_OPEN_CLOSE_DEVICES',
    'CurrentBasCore',
    'RoomFilter',
    'Rooms',
    'RoomsHelper',
    BasOpenCloseDevices
  ])

/**
 * @typedef {Object} TOpenCloseDevicesState
 * @property {Object<string, BasOpenCloseDevice>} openCloseDevices
 * @property {string[]} uiAllOpenCloseDevices
 * @property {string[]} uiOpenCloseDevices
 * @property {boolean} hasOpenCloseDevices
 * @property {Object<string, boolean>} css
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_OPEN_CLOSE_DEVICE} BAS_OPEN_CLOSE_DEVICE
 * @param {BAS_OPEN_CLOSE_DEVICES} BAS_OPEN_CLOSE_DEVICES
 * @param {CurrentBasCore} CurrentBasCore
 * @param RoomFilter
 * @param {Rooms} Rooms
 * @param {RoomsHelper} RoomsHelper
 */
function BasOpenCloseDevices (
  $rootScope,
  BAS_CURRENT_CORE,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_OPEN_CLOSE_DEVICE,
  BAS_OPEN_CLOSE_DEVICES,
  CurrentBasCore,
  RoomFilter,
  Rooms,
  RoomsHelper
) {
  var _SYNC_DEBOUNCE = 500

  var CSS_BAS_OPEN_CLOSE_CAN_EDIT_ORDER =
    'bas-open-close-devices-can-edit-order'
  var CSS_BAS_OPEN_CLOSE_STATE_EDITING = 'bas-state-editing'
  var CSS_BAS_OPEN_CLOSE_HAS_DOORS = 'bas-object-message-hide'

  var _syncTimeoutId = 0

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasRoomControl}
   */
  var roomControl = Rooms.getControl()

  /**
   * @type {TOpenCloseDevicesState}
   */
  var state = {}
  state.openCloseDevices = {}
  state.uiAllOpenCloseDevices = []
  state.uiOpenCloseDevices = []
  state.hasOpenCloseDevices = false
  state.css = {}
  _resetCss()

  this.get = get
  this.uiReorder = uiReorder
  this.saveOrder = saveOrder
  this.filterControl = filterControl
  this.setEditing = setEditing
  this.getOpenCloseDeviceByUuid = getOpenCloseDeviceByUuid

  init()

  function init () {

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _setSyncTimeout
    )
    $rootScope.$on(
      BAS_ROOM.EVT_OPEN_CLOSE_DEVICES_INITIALIZED,
      _setSyncTimeout
    )
    $rootScope.$on(
      BAS_ROOM.EVT_OPEN_CLOSE_DEVICES_UPDATED,
      _setSyncTimeout
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_OPEN_CLOSE_DEVICES_ORDER,
      _setSyncTimeout
    )
    $rootScope.$on(
      BAS_OPEN_CLOSE_DEVICE.EVT_OPEN_CLOSE_DEVICE_UPDATED,
      _setSyncTimeout
    )

    _syncOpenCloseDevices()
  }

  /**
   * @returns {TOpenCloseDevicesState}
   */
  function get () {

    return state
  }

  function setEditing (editing) {

    state.css[CSS_BAS_OPEN_CLOSE_STATE_EDITING] = editing
  }

  /**
   * @param {string} openCloseDeviceUuid
   * @returns {?BasOpenCloseDevice}
   */
  function getOpenCloseDeviceByUuid (openCloseDeviceUuid) {

    var keys, length, i, roomId, room, openCloseDevice

    if (BasUtil.isNEString(openCloseDeviceUuid)) {

      keys = Object.keys(rooms.rooms)
      length = keys.length
      for (i = 0; i < length; i++) {

        roomId = keys[i]

        if (roomId) {

          room = rooms.rooms[roomId]

          if (
            room &&
            room.openCloseDevices &&
            room.openCloseDevices.getOpenCloseDevice
          ) {

            openCloseDevice =
              room.openCloseDevices.getOpenCloseDevice(openCloseDeviceUuid)

            if (openCloseDevice) return openCloseDevice
          }
        }
      }
    }

    return null
  }

  /**
   * Sets a new filter as current filter
   *
   * @param {string} filterId
   */
  function filterControl (filterId) {

    roomControl.selectCurrentFilter(
      BAS_ROOMS.BC_OPEN_CLOSE_DEVICES,
      filterId
    )

    _syncOpenCloseDevices()
  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   */
  function uiReorder (spliceIndex, originalIndex) {

    var original

    original = state.uiAllOpenCloseDevices[originalIndex]

    if (original) {

      state.uiAllOpenCloseDevices.splice(originalIndex, 1)
      state.uiAllOpenCloseDevices.splice(
        spliceIndex,
        0,
        original
      )
    }
  }

  function saveOrder () {

    if (CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.sharedServerStorage) {

      currentBasCoreState.core.core.sharedServerStorage
        .updateOpenCloseDevicesOrder(state.uiAllOpenCloseDevices)
    }
  }

  /**
   * @private
   * @param {boolean} [emit = true]
   */
  function _syncOpenCloseDevices (emit) {

    var _emit, length, i, _uuid
    var _sharedStorage, storageUuids, sortedUuids

    _emit = BasUtil.isBool(emit) ? emit : true

    _clear()
    _resetCss()

    RoomsHelper.forEachRoom(_syncOpenCloseDevicesFromRoom)

    if (CurrentBasCore.hasCore()) {

      _sharedStorage = currentBasCoreState.core.core.sharedServerStorage

      storageUuids = _sharedStorage
        ? _sharedStorage.openCloseDevicesOrder
        : null

      if (BasUtil.isNEArray(storageUuids)) {

        sortedUuids = []

        // Match devices from server storage

        length = storageUuids.length
        for (i = 0; i < length; i++) {

          _uuid = storageUuids[i]

          if (state.openCloseDevices[_uuid]) {

            if (sortedUuids.indexOf(_uuid) < 0) {

              sortedUuids.push(_uuid)
            }
          }
        }

        // Match devices NOT in server storage

        length = state.uiAllOpenCloseDevices.length
        for (i = 0; i < length; i++) {

          _uuid = state.uiAllOpenCloseDevices[i]

          if (sortedUuids.indexOf(_uuid) < 0) {

            sortedUuids.push(_uuid)
          }
        }

        // Set sorted open close devices

        state.uiAllOpenCloseDevices = sortedUuids
      }
    }

    state.hasOpenCloseDevices = state.uiAllOpenCloseDevices.length > 0

    // Filter sorted open close devices

    switch (rooms.openCloseDevices.uiCurrentFilter) {
      case RoomFilter.SRV_ALL:
        state.uiOpenCloseDevices = state.uiAllOpenCloseDevices
        state.css[CSS_BAS_OPEN_CLOSE_CAN_EDIT_ORDER] = true
        break
      case RoomFilter.SRV_ACT:
        state.uiOpenCloseDevices = state.uiAllOpenCloseDevices.slice()
        _filterActive()
        break
      default:
        state.uiOpenCloseDevices = state.uiAllOpenCloseDevices.slice()
        _filterRooms()
        break
    }

    state.css[CSS_BAS_OPEN_CLOSE_HAS_DOORS] =
      state.uiOpenCloseDevices.length > 0

    // Emit update event

    if (_emit) {

      $rootScope.$emit(
        BAS_OPEN_CLOSE_DEVICES.EVT_OPEN_CLOSE_DEVICES_UPDATED
      )
    }
  }

  function _filterActive () {

    var length, i, _uuid, _openCloseDevice

    length = state.uiOpenCloseDevices.length
    for (i = length; i >= 0; i--) {

      _uuid = state.uiOpenCloseDevices[i]
      _openCloseDevice = state.openCloseDevices[_uuid]

      if (_openCloseDevice &&
        !_openCloseDevice.device.opened) {

        state.uiOpenCloseDevices.splice(i, 1)
      }
    }
  }

  function _filterRooms () {

    state.uiOpenCloseDevices = BasUtil.filter(
      state.uiOpenCloseDevices,
      _partOfCurrentFilterCollection
    )
  }

  function _partOfCurrentFilterCollection (openCloseUuid) {

    var openCloseDevice, openCloseCollection, i, length
    var roomCollection, openCloseRoomUuid

    openCloseDevice = state.openCloseDevices[openCloseUuid]

    if (openCloseDevice &&
      openCloseDevice.room &&
      BasUtil.isNEString(openCloseDevice.room.id)) {

      openCloseRoomUuid = openCloseDevice.room.id
    }

    if (openCloseRoomUuid) {

      openCloseCollection = rooms.openCloseDevices.uiCurrentCollection

      length = openCloseCollection.length
      for (i = 0; i < length; i++) {

        roomCollection = openCloseCollection[i]

        if (
          roomCollection &&
          roomCollection.items.indexOf(openCloseRoomUuid) > -1
        ) {

          return true
        }
      }
    }

    return false
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _syncOpenCloseDevicesFromRoom (room) {

    var devices, keys, length, i, _uuid, _device

    if (room.hasOpenCloseDevices && room.hasOpenCloseDevices()) {

      devices = room.openCloseDevices.openCloseDevices

      keys = Object.keys(devices)
      length = keys.length
      for (i = 0; i < length; i++) {

        _uuid = keys[i]
        _device = devices[_uuid]

        if (BasUtil.isObject(_device) &&
          BasUtil.isNEString(_device.uuid) &&
          _uuid === _device.uuid) {

          if (!state.openCloseDevices[_uuid]) {

            state.uiAllOpenCloseDevices.push(_uuid)
          }

          state.openCloseDevices[_uuid] = _device
        }
      }
    }
  }

  function _clear () {

    state.openCloseDevices = {}
    state.uiAllOpenCloseDevices = []
    state.hasOpenCloseDevices = false
  }

  function _resetCss () {

    state.css[CSS_BAS_OPEN_CLOSE_CAN_EDIT_ORDER] = false
    state.css[CSS_BAS_OPEN_CLOSE_STATE_EDITING] = false
    state.css[CSS_BAS_OPEN_CLOSE_HAS_DOORS] = false
  }

  function _setSyncTimeout () {

    _clearSyncTimeout()
    _syncTimeoutId = setTimeout(_syncOpenCloseDevices, _SYNC_DEBOUNCE)
  }

  function _clearSyncTimeout () {

    clearTimeout(_syncTimeoutId)
  }
}
