'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomShades', [
    'CurrentBasCore',
    'BasShade',
    'BasCollection',
    basRoomShadesFactory
  ])

/**
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasShade
 * @param BasCollection
 * @returns BasRoomShades
 */
function basRoomShadesFactory (
  CurrentBasCore,
  BasShade,
  BasCollection
) {
  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomShades (basRoom) {

    /**
     * @type {Object<string, BasShade>}
     */
    this.shades = {}

    /**
     * Array of BasCollections that holds the shades UI
     *
     * @type {BasCollection[]}
     */
    this.uiShades = []

    /**
     * @private
     * @type {BasCollection}
     */
    this._colAll = null

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomShades.hasShades = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.shades)
    )
  }

  /**
   * Parse the raw room from the parent BasRoom
   */
  BasRoomShades.prototype.parseRoom = function parseRoom () {

    if (BasRoomShades.hasShades(this._basRoom)) {

      this.syncShades()
    }
  }

  BasRoomShades.prototype.syncShades = function syncShades () {

    var length, i, uuid, device, shade

    this.reset()

    if (BasRoomShades.hasShades(this._basRoom)) {

      length = this._basRoom.room.shades.length
      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.shades[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) {

          shade = new BasShade(device)
          this.shades[uuid] = shade

          if (!this._colAll) {

            this._colAll = new BasCollection()
            this._colAll.setId('colIdShades')
          }

          this._colAll.items.push(uuid)
        }
      }

      if (this._colAll) {

        this.uiShades = [
          this._colAll
        ]
      }
    }
  }

  /**
   * Gets a BasShade for the uuid
   *
   * @param {string} uuid
   * @returns {?BasShade}
   */
  BasRoomShades.prototype.getShade = function getShade (uuid) {

    var shade

    if (BasUtil.isNEString(uuid)) {

      shade = this.shades[uuid]

      if (shade && shade.getName) return shade
    }

    return null
  }

  BasRoomShades.prototype.updateTranslation = function updateTranslation () {

    var keys, i, length, shade

    keys = Object.keys(this.shades)
    length = keys.length
    for (i = 0; i < length; i++) {

      shade = this.shades[keys[i]]
      if (shade) shade.updateTranslation()
    }
  }

  BasRoomShades.prototype.suspendShades = function suspendShades () {

    var keys, i, length, shade

    keys = Object.keys(this.shades)
    length = keys.length
    for (i = 0; i < length; i++) {

      shade = this.shades[keys[i]]
      if (shade) shade.suspend()
    }
  }

  /**
   * Creates new BasShade instances from current real BasShade instances
   *
   * @returns {BasShade[]}
   */
  BasRoomShades.prototype.createEditableClones = function () {

    var length, i, shade, clone, result, keys

    result = []

    keys = Object.keys(this.shades)
    length = keys.length
    for (i = 0; i < length; i++) {

      shade = this.shades[keys[i]]

      if (shade.isEditable()) {

        clone = shade.clone()
        clone.fillInUuid()
        result.push(clone)
      }
    }

    return result
  }

  /**
   * Clears every shade
   */
  BasRoomShades.prototype.clear = function clear () {

    var keys, i, length, shade

    keys = Object.keys(this.shades)
    length = keys.length
    for (i = 0; i < length; i++) {

      shade = this.shades[keys[i]]
      if (shade) shade.clear()
    }
  }

  /**
   * Resets the shades, removing them
   */
  BasRoomShades.prototype.reset = function reset () {

    this.clear()

    this.shades = {}
    this.uiShades = []
    this._colAll = null
  }

  BasRoomShades.prototype.suspend = function suspend () {

    this.suspendShades()
  }

  BasRoomShades.prototype.destroy = function destroy () {

    this.reset()
  }

  return BasRoomShades
}
