'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLocalCore', [
    'BAS_API',
    'BasDiscoveredCore',
    basLocalCoreFactory
  ])

/**
 * @param BAS_API
 * @param BasDiscoveredCore
 * @returns BasLocalCore
 */
function basLocalCoreFactory (
  BAS_API,
  BasDiscoveredCore
) {
  var K_TXT_RECORDS = 'txtRecords'
  var K_MANUFACTURER = 'Manufacturer'
  var K_DID = 'DID'
  var K_NAME = 'name'
  var K_MAC = 'MAC'
  var K_MAS = 'MAS'
  var K_ADDRESSES = 'addresses'
  var K_FFN = 'FFN'
  var K_CID = 'CID'
  var K_PN = 'PN'

  var MANUFACTURER = 'Basalte'
  var CORE_DIDS = [
    BAS_API.Device.BT.ID_ASANO_S4 + '',
    BAS_API.Device.BT.ID_CORE_MINI + '',
    BAS_API.Device.BT.ID_CORE_PLUS + ''
  ]

  /**
   * @constructor
   * @extends BasDiscoveredCore
   * @param {Object} [service]
   */
  function BasLocalCore (service) {

    BasDiscoveredCore.call(this)

    /**
     * @type {boolean}
     */
    this.isLocallyDiscovered = false

    /**
     * @type {boolean}
     */
    this.isNetworkDiscovered = false

    /**
     * @type {boolean}
     */
    this.isMaster = true

    if (BasUtil.isObject(service)) this.initDiscoveredService(service)
  }

  BasLocalCore.prototype = Object.create(BasDiscoveredCore.prototype)
  BasLocalCore.prototype.constructor = BasLocalCore

  /**
   * Checks whether the service object represents a BasCore.
   *
   * @param {?Object} service
   * @returns {boolean}
   */
  BasLocalCore.isLocalDiscoveredCoreService = function (service) {
    return (
      BasUtil.isObject(service) &&
      BasUtil.isObject(service[K_TXT_RECORDS]) &&
      BasUtil.isNEString(service[K_TXT_RECORDS][K_MAC]) &&
      service[K_TXT_RECORDS][K_MANUFACTURER] === MANUFACTURER &&
      CORE_DIDS.indexOf(service[K_TXT_RECORDS][K_DID]) !== -1
    )
  }

  /**
   * Checks whether the network discovery object represents a BasCore.
   *
   * @param {?Object} service
   * @returns {boolean}
   */
  BasLocalCore.isNetworkDiscoveredCoreService = function (service) {
    return (
      BasUtil.isObject(service) &&
      BasUtil.isPNumber(service.macN) &&
      Array.isArray(service.addresses)
    )
  }

  /**
   * This inits a service.
   * These are all values that will not change over time
   *
   * @param {Object} service
   */
  BasLocalCore.prototype.initDiscoveredService = function (service) {

    var value, cid

    if (BasLocalCore.isLocalDiscoveredCoreService(service)) {

      cid = service[K_TXT_RECORDS][K_CID]

      this.serviceName = service[K_NAME]
      this.setId(
        BasUtil.isNEString(cid)
          ? cid
          : this.serviceName
      )

      // MAC address

      value = service[K_TXT_RECORDS][K_MAC]
      if (BasUtil.isNEString(value)) this.setMac(value)

    } else if (BasLocalCore.isNetworkDiscoveredCoreService(service)) {

      this.setId(service.cid)

      // MAC address

      value = service.macN
      if (BasUtil.isPNumber(value)) this.setMac(value)
    }

    this.parseDiscoveredService(service)
  }

  /**
   * This parses a service.
   * These values can change over time.
   *
   * @param {Object} service
   */
  BasLocalCore.prototype.parseDiscoveredService = function (service) {

    var length, i, keys

    if (BasLocalCore.isLocalDiscoveredCoreService(service)) {

      this.isLocallyDiscovered = true

      this.coreName =
        BasUtil.isNEString(service[K_TXT_RECORDS][K_FFN])
          ? service[K_TXT_RECORDS][K_FFN]
          : ''
      this.cid =
        BasUtil.isNEString(service[K_TXT_RECORDS][K_CID])
          ? service[K_TXT_RECORDS][K_CID]
          : ''
      this.projectName =
        BasUtil.isNEString(service[K_TXT_RECORDS][K_PN])
          ? service[K_TXT_RECORDS][K_PN]
          : ''
      this.addresses =
        Array.isArray(service[K_ADDRESSES])
          ? service[K_ADDRESSES].slice()
          : []
      this.isMaster = service[K_MAS] !== '0'

    } else if (BasLocalCore.isNetworkDiscoveredCoreService(service)) {

      this.isNetworkDiscovered = true

      this.cid =
        BasUtil.isNEString(service.cid)
          ? service.cid
          : ''
      this.projectName =
        BasUtil.isNEString(service.projectName)
          ? service.projectName
          : ''
      this.addresses =
        Array.isArray(service.addresses)
          ? service.addresses.slice()
          : []
      this.images =
        BasUtil.isNEObject(service.images)
          ? BasUtil.copyObject(service.images)
          : null
      this.city =
        BasUtil.isNEString(service.city)
          ? service.city
          : ''
      this.country =
        BasUtil.isNEString(service.country)
          ? service.country
          : ''
      this.isMaster = service.master !== false

      if (this.images) {

        keys = Object.keys(this.images)
        length = keys.length
        for (i = 0; i < length; i++) {

          this.images[keys[i]] =
            'http://' +
            this.addresses[0] +
            '/' +
            this.images[keys[i]]
        }
      }
    }

    this.generateUi()
  }

  return BasLocalCore
}
