'use strict'

angular
  .module('basalteApp')
  .directive('basThermostatWidget', [
    'BAS_HTML',
    basThermostatWidgetDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basThermostatWidget
 */
function basThermostatWidgetDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    scope: {
      thermostat: '<',
      multipleThermostats: '<'
    },
    template: BAS_HTML.widgetThermostat
  }
}
