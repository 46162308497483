'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('lightsSceneCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_CURRENT_CORE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BasSceneHelper',
    lightsSceneCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BasSceneHelper} BasSceneHelper
 */
function lightsSceneCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_CURRENT_CORE,
  BAS_ROOMS,
  BAS_ROOM,
  BasSceneHelper
) {
  var lights = this

  var _listeners = []
  var _oldState = BasSceneHelper.copyState($uiRouterGlobals)

  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  lights.$uiRouterGlobals = $uiRouterGlobals
  lights.liveState = false
  lights.lights = []

  lights.liveToggle = liveToggle
  lights.selectLight = selectLight

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_LIGHTS_INITIALIZED,
      _onLights
    ))
    // TODO Support config without music
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _clearLights
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_FUNCTION_INPUT,
      _onData
    ))

    _syncLights()
  }

  /**
   * Toggle live state on every light
   * Adds all lights to the step if live state is true
   */
  function liveToggle () {

    var length, i

    lights.liveState = !lights.liveState

    length = lights.lights.length
    for (i = 0; i < length; i++) {

      lights.lights[i].setSyncMode(lights.liveState)
      if (lights.liveState) _addToStep(lights.lights[i])
    }
  }

  function selectLight (light) {

    _toggleOnStep(light)
  }

  /**
   * @private
   * @param _evt
   * @param {string} roomId
   */
  function _onLights (_evt, roomId) {

    var _step, _room

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.room) _room = rooms.rooms[_step.room]

    if (_room && _room.id === roomId) _syncLights()
  }

  function _syncLights () {

    var _step, _room, i, length, light

    _clearLights()

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.room) _room = rooms.rooms[_step.room]

    if (_room && _room.lights && _room.lights.createEditableClones) {

      lights.lights = _room.lights.createEditableClones()
      length = lights.lights.length
      for (i = 0; i < length; i++) {

        light = lights.lights[i]
        light.setSyncMode(lights.liveState)
        light.syncBasLight(_step.lights[light.uuid])
      }
    }
  }

  function _clearLights () {

    var length, i

    length = lights.lights.length
    for (i = 0; i < length; i++) {

      if (lights.lights[i]) lights.lights[i].clear()
    }

    lights.lights = []
  }

  /**
   * @private
   * @param _evt
   * @param {BasLight} evtLight
   */
  function _onData (_evt, evtLight) {

    var i, length, light

    length = lights.lights.length
    for (i = 0; i < length; i++) {

      light = lights.lights[i]

      if (light === evtLight) {

        _addToStep(light)
        break
      }
    }
  }

  /**
   * @private
   * @param {BasLight} light
   */
  function _addToStep (light) {

    var _step

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step) _step.addLight(light)
  }

  /**
   * @private
   * @param {BasLight} light
   */
  function _toggleOnStep (light) {

    var _step

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step) _step.toggleLight(light)
  }

  function _onDestroy () {

    var _step

    _step = BasSceneHelper.getStep($scope, _oldState)
    if (_step) _step.updateSummary()

    BasUtil.executeArray(_listeners)
    _clearLights()
  }
}
