'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('LocalElement', [
    'BAS_LIBRARY',
    'BAS_LOCAL_LIBRARY',
    'BasLibraryElement',
    localElementFactory
  ])

/**
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BAS_LOCAL_LIBRARY} BAS_LOCAL_LIBRARY
 * @param BasLibraryElement
 * @returns LocalElement
 */
function localElementFactory (
  BAS_LIBRARY,
  BAS_LOCAL_LIBRARY,
  BasLibraryElement
) {
  var CSS_HAS_OWNER = 'bas-library-element-body-title-has-owner'

  /**
   * Local Ui element class
   * contains all information needed for HTML templates
   *
   * @constructor
   * @extends BasLibraryElement
   */
  function LocalElement () {

    // Call 'super' constructor
    BasLibraryElement.call(this)

    this.libraryType = BAS_LIBRARY.TYPE_LOCAL

    /**
     * @instance
     * @type {string}
     */
    this.type = BAS_LOCAL_LIBRARY.T_EL_TRACK

    /**
     * @instance
     * @type {string}
     */
    this.subType = ''

    /**
     * @instance
     * @type {string}
     */
    this.collectionType = ''

    /**
     * @instance
     * @type {string}
     */
    this.servicePrefix = ''

    /**
     * @instance
     * @type {string}
     */
    this.file = ''

    /**
     * @instance
     * @type {string}
     */
    this.owner = ''

    /**
     * @instance
     * @type {boolean}
     */
    this.iTunes = false

    /**
     * @instance
     * @type {string}
     */
    this.artist = ''

    /**
     * @instance
     * @type {string}
     */
    this.album = ''

    /**
     * @instance
     * @type {Array}
     */
    this.multiCovers = []

    /**
     * @instance
     * @type {boolean}
     */
    this.hasMultipleCovers = false

    /**
     * @instance
     * @type {boolean}
     */
    this.locked = false

    /**
     * @instance
     * @type {boolean}
     */
    this.editable = false

    /**
     * @instance
     * @type {boolean}
     */
    this.shareable = false

    /**
     * @instance
     * @type {string}
     */
    this.playlistId = ''

    /**
     * @instance
     * @type {boolean}
     */
    this.isSharing = false

    /**
     * @instance
     * @type {string}
     */
    this.uri = ''

    /**
     * @instance
     * @type {string}
     */
    this.albumUri = ''

    /**
     * @instance
     * @type {string}
     */
    this.artistUri = ''

    this.class[CSS_HAS_OWNER] = false
  }

  // Set new prototype from inherited object
  LocalElement.prototype = Object.create(BasLibraryElement.prototype)

  // Set correct constructor after setting new prototype
  LocalElement.prototype.constructor = LocalElement

  /**
   * @param {Array} array
   */
  LocalElement.prototype.setMultipleCovers = function (array) {

    var cover, length, i

    this.hasMultipleCovers = true
    this.multiCovers = array

    length = this.multiCovers.length
    for (i = 0; i < length; i++) {
      cover = this.multiCovers[i]

      if (cover) {
        cover.small = cover.thumbnail
        cover.medium = cover.thumbnail
        cover.large = cover.coverart
      }
    }
  }

  /**
   * @param {string} owner
   */
  LocalElement.prototype.setOwner = function (owner) {

    this.owner = BasUtil.isNEString(owner) ? owner : ''
    this.class[CSS_HAS_OWNER] = !!(this.owner || this.ownerUuid)
  }

  /**
   * @param {string} ownerUuid
   */
  LocalElement.prototype.setownerUuid = function (ownerUuid) {

    this.ownerUuid = BasUtil.isNEString(ownerUuid) ? ownerUuid : ''
    this.class[CSS_HAS_OWNER] = !!(this.ownerUuid && this.ownerUuid)
  }

  /**
   * Fills in 'asanoFavouriteId'
   *
   * @returns {string}
   */
  LocalElement.prototype.getSelectionKey = function () {

    return this.uri ? this.uri : this.file
  }

  return LocalElement
}
