'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var log = require('./logger')

/**
 * A class representing a logged in user for the basCore
 *
 * @constructor
 * @param {BasProfile} profile
 * @param {BasCore} basCore
 * @since 0.1.0
 */
function User (profile, basCore) {

  EventEmitter.call(this)

  this._basCore = basCore

  /**
   * @private
   * @type {BasProfile}
   */
  this._basProfile = profile || null

  /**
   * @private
   * @type {string}
   */
  this._username = ''

  /**
   * @private
   * @type {boolean}
   */
  this._admin = false

  /**
   * @private
   * @type {number}
   */
  this._defaultSource = 0

  /**
   * @private
   * @type {string}
   */
  this._defaultSourceUuid = ''

  /**
   * @private
   * @type {boolean}
   */
  this._alarmsDirty = true

  /**
   * @private
   * @type {*[]}
   */
  this._alarms = []

  this._parseProfile()
}

User.prototype = Object.create(EventEmitter.prototype)
User.prototype.constructor = User

// region Events

/**
 * When alarms have changed
 *
 * @event User#EVT_ALARMS_CHANGED
 * @since 0.2.0
 */

// endregion

/**
 * @constant {string}
 */
User.EVT_ALARMS_CHANGED = 'alarmsChanged'

/**
 * @constant {string}
 */
User.ERROR_INVALID_ALARM = 'Invalid Alarm'

/**
 * @name User#basProfile
 * @type {?BasProfile}
 * @readonly
 * @since 3.0.0
 */
Object.defineProperty(User.prototype, 'basProfile', {
  get: function () {
    return this._basProfile
  }
})

/**
 * @name User#username
 * @type {string}
 * @readonly
 * @since 0.1.0
 */
Object.defineProperty(User.prototype, 'username', {
  get: function () {
    return this._username
  }
})

/**
 * @name User#admin
 * @type {boolean}
 * @readonly
 * @since 1.7.9
 */
Object.defineProperty(User.prototype, 'admin', {
  get: function () {
    return this._admin
  }
})

/**
 * The default source of this user
 *
 * @name User#defaultSource
 * @type {number}
 * @readonly
 * @since 1.2.0
 */
Object.defineProperty(User.prototype, 'defaultSource', {
  get: function () {
    return this._defaultSource
  }
})

/**
 * The default source uuid of this user
 *
 * @name User#defaultSourceUuid
 * @type {string}
 * @readonly
 * @since 3.6.3
 */
Object.defineProperty(User.prototype, 'defaultSourceUuid', {
  get: function () {
    return this._defaultSourceUuid
  }
})

/**
 * @private
 */
User.prototype._parseProfile = function () {

  if (BasUtil.isObject(this._basProfile)) {

    this._username = this._basProfile.username
    this._admin = this._basProfile.isAdmin
    this._defaultSource = this._basProfile.defaultSource
  }
}

/**
 * Parse a message from the connected basCore
 *
 * @param {Object} obj The object which we received from the basCore
 * @since 0.1.0
 */
User.prototype.parse = function (obj) {

  if (BasUtil.isObject(obj) &&
    BasUtil.isObject(obj[P.USER]) &&
    obj[P.USER][P.NAME] === this._username) {

    if (BasUtil.isObject(obj[P.USER][P.ALARM]) &&
      obj[P.USER][P.ALARM][P.CHANGED] === true) {

      this._alarmsDirty = true
      this.emit(User.EVT_ALARMS_CHANGED)
    }
  }
}

/**
 * Get all alarms for this user
 *
 * @returns {Promise<Object[]>}
 * @since 1.2.4
 */
User.prototype.retrieveAlarms = function () {

  var _this, data

  if (this._alarmsDirty) {

    _this = this

    data = {}
    data[P.USER] = {}
    data[P.USER][P.NAME] = this._username
    data[P.USER][P.ALARM] = {}
    data[P.USER][P.ALARM][P.ACTION] = P.LIST

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(onAlarms)

  } else {

    log.debug('User - retrieveAlarms - Cached')

    return Promise.resolve(this._alarms)
  }

  function onAlarms (result) {

    var i, length, alarms, alarm, newAlarms

    // Check response
    if (BasUtil.isObject(result) &&
      BasUtil.isObject(result[P.USER]) &&
      result[P.USER][P.NAME] === _this._username &&
      Array.isArray(result[P.USER][P.ALARM])) {

      // Get a reference to the received alarms
      alarms = result[P.USER][P.ALARM]

      newAlarms = []

      // Iterate received alarms
      length = alarms.length
      for (i = 0; i < length; i++) {

        // Get an alarm reference
        alarm = alarms[i]

        if (BasUtil.isObject(alarm)) {

          // Correct volume
          if (BasUtil.isNumber(alarm[P.VOLUME])) {

            alarm.volume = CONSTANTS.convertFromServerVolume(
              alarm[P.VOLUME]
            )
          }

          // Check for datetime and convert to Date object
          if (BasUtil.isNumber(alarm[P.DATETIME])) {

            alarm.datetime = new Date(alarm[P.DATETIME] * 1000)
          }

          newAlarms.push(alarm)

        } else {

          log.error('User - retrieveAlarms - Invalid Alarm object', alarm)
        }
      }

      _this._alarms = newAlarms
      _this._alarmsDirty = false

      return Promise.resolve(_this._alarms)

    } else {

      log.error('User - retrieveAlarms - Invalid response', result)

      return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
    }
  }
}

/**
 * Update an alarm
 *
 * @param {Object} alarm
 * @returns {Promise}
 * @since 1.2.4
 */
User.prototype.updateAlarm = function (alarm) {

  var _this, data

  if (BasUtil.isObject(alarm)) {

    // Check alarm type
    if (alarm[P.TYPE] === P.DATETIME) {

      if (!(alarm[P.DATETIME] instanceof Date)) {

        log.error('User - updateAlarm - Invalid datetime', alarm)
        return Promise.reject(User.ERROR_INVALID_ALARM)
      }

    } else if (alarm[P.TYPE] === P.REPEATED) {

      if (BasUtil.isPNumber(alarm[P.HOUR], true) &&
        BasUtil.isPNumber(alarm[P.MIN], true)) {

        if (alarm[P.HOUR] > 23) {

          log.error('User - updateAlarm - Invalid hours', alarm)
          return Promise.reject(User.ERROR_INVALID_ALARM)
        }

        if (alarm[P.MIN] > 59) {

          log.error('User - updateAlarm - Invalid minutes', alarm)
          return Promise.reject(User.ERROR_INVALID_ALARM)
        }

      } else {

        log.error('User - updateAlarm' +
          ' - Invalid hour and/or minutes', alarm)
        return Promise.reject(User.ERROR_INVALID_ALARM)
      }

    } else {

      log.error('User - updateAlarm - Invalid alarm type', alarm)
      return Promise.reject(User.ERROR_INVALID_ALARM)
    }

    // Check enabled flag
    if (!BasUtil.isBool(alarm[P.ENABLED])) {

      log.error('User - updateAlarm - Invalid alarm type', alarm)
      return Promise.reject(User.ERROR_INVALID_ALARM)
    }

    // Check volume
    if (!(BasUtil.isPNumber(alarm[P.VOLUME]) &&
      alarm[P.VOLUME] <= 100)) {

      log.error('User - updateAlarm - Invalid alarm volume', alarm)
      return Promise.reject(User.ERROR_INVALID_ALARM)
    }

    // Check zones
    if (!Array.isArray(alarm[P.ZONES])) {

      log.error('User - updateAlarm - Invalid alarm zones', alarm)
      return Promise.reject(User.ERROR_INVALID_ALARM)
    }

    // Check player
    if (alarm[P.PLAYER] && !BasUtil.isNumber(alarm[P.PLAYER])) {

      log.error('User - updateAlarm - Invalid alarm Player', alarm)
      return Promise.reject(User.ERROR_INVALID_ALARM)
    }

    data = {}
    data[P.USER] = {}
    data[P.USER][P.NAME] = this._username
    data[P.USER][P.ALARM] = {}
    data[P.USER][P.ALARM][P.TYPE] = alarm.type
    data[P.USER][P.ALARM][P.ENABLED] =
      alarm.enabled
    data[P.USER][P.ALARM][P.VOLUME] =
      CONSTANTS.convertToServerVolume(alarm.volume)
    data[P.USER][P.ALARM][P.ZONES] = alarm.zones

    // Check for new alarm or existing one
    if (BasUtil.isNumber(alarm[P.ID])) {

      data[P.USER][P.ALARM][P.ACTION] =
        P.UPDATE
      data[P.USER][P.ALARM][P.ID] =
        alarm[P.ID]

    } else {

      data[P.USER][P.ALARM][P.ACTION] =
        P.ADD
    }

    // Alarm type
    switch (alarm.type) {
      case P.DATETIME:

        data[P.USER][P.ALARM][P.DATETIME] =
          Math.floor(alarm[P.DATETIME].getTime() / 1000)

        break
      case P.REPEATED:

        data[P.USER][P.ALARM][P.HOUR] =
          alarm[P.HOUR]
        data[P.USER][P.ALARM][P.MIN] =
          alarm[P.MIN]
        data[P.USER][P.ALARM][P.SUNDAY] =
          !!alarm[P.SUNDAY]
        data[P.USER][P.ALARM][P.MONDAY] =
          !!alarm[P.MONDAY]
        data[P.USER][P.ALARM][P.TUESDAY] =
          !!alarm[P.TUESDAY]
        data[P.USER][P.ALARM][P.WEDNESDAY] =
          !!alarm[P.WEDNESDAY]
        data[P.USER][P.ALARM][P.THURSDAY] =
          !!alarm[P.THURSDAY]
        data[P.USER][P.ALARM][P.FRIDAY] =
          !!alarm[P.FRIDAY]
        data[P.USER][P.ALARM][P.SATURDAY] =
          !!alarm[P.SATURDAY]
        break
    }

    // Player
    if (alarm[P.PLAYER]) {
      data[P.USER][P.ALARM][P.PLAYER] =
        alarm[P.PLAYER]
    }

    // Playlist or radio station
    if (BasUtil.isNEString(alarm[P.PLAYLIST])) {

      data[P.USER][P.ALARM][P.PLAYLIST] =
        alarm[P.PLAYLIST]

    } else if (BasUtil.isNEString(alarm[P.TUNEIN_GID])) {

      data[P.USER][P.ALARM][P.TUNEIN_GID] =
        alarm[P.TUNEIN_GID]

    } else if (BasUtil.isNEString(alarm[P.DEEZER__ID])) {

      data[P.USER][P.ALARM][P.DEEZER__ID] =
        alarm[P.DEEZER__ID]

    } else if (BasUtil.isNEString(alarm[P.TIDAL__ID])) {

      data[P.USER][P.ALARM][P.TIDAL__ID] =
        alarm[P.TIDAL__ID]

    } else if (BasUtil.isNEString(alarm[P.SPOTIFY_PRESET])) {

      data[P.USER][P.ALARM][P.SPOTIFY_PRESET] =
        alarm[P.SPOTIFY_PRESET]
    }

    _this = this

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(onUpdate)

  } else {

    log.error('User - updateAlarm - Invalid alarm', alarm)
    return Promise.reject(User.ERROR_INVALID_ALARM)
  }

  function onUpdate (result) {

    if (BasUtil.isObject(result) &&
      BasUtil.isObject(result[P.USER]) &&
      result[P.USER][P.NAME] === _this._username) {

      if (result[P.USER][P.RESULT] === P.OK) {

        return Promise.resolve()

      } else {

        log.error('User - updateAlarm - Alarm response ERROR', result)

        if (BasUtil.isObject(result) &&
          BasUtil.isObject(result[P.USER]) &&
          result[P.USER][P.RESULT] ===
          P.NO_PERMISSION) {

          return Promise.reject(CONSTANTS.ERR_NO_PERMISSION)
        }

        return Promise.reject(result)
      }

    } else {

      log.error('User - updateAlarm - Invalid alarm response', result)
      return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
    }
  }

}

/**
 * Remove an alarm with the specified id (generated by the basCore)
 *
 * @param {number} id Alarm ID
 * @since 0.2.0
 */
User.prototype.removeAlarm = function (id) {

  var requestObj

  if (BasUtil.isPNumber(id, true)) {

    requestObj = {}
    requestObj[P.USER] = {}
    requestObj[P.USER][P.NAME] = this._username
    requestObj[P.USER][P.ALARM] = {}
    requestObj[P.USER][P.ALARM][P.ACTION] = P.REMOVE
    requestObj[P.USER][P.ALARM][P.ID] = id

    this._basCore.send(requestObj)

  } else {

    log.error('User - removeAlarm - Invalid ID', id)
  }
}

/**
 * @since 2.0.0
 */
User.prototype.destroy = function () {

  this._basCore = null
  this.removeAllListeners()
}

module.exports = User
