'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomThermostats', [
    'BasThermostat',
    basRoomThermostats
  ])

/**
 * @param BasThermostat
 * @returns BasRoomThermostats
 */
function basRoomThermostats (
  BasThermostat
) {
  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomThermostats (basRoom) {

    /**
     * @type {BasThermostat[]}
     */
    this.thermostats = []

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomThermostats.hasThermostats = function (room) {
    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.thermostats)
    )
  }

  BasRoomThermostats.prototype.parseRoom = function () {
    if (BasRoomThermostats.hasThermostats(this._basRoom)) {

      this.syncThermostats()
    }
  }

  BasRoomThermostats.prototype.syncThermostats = function () {

    this.resetThermostats()

    if (BasRoomThermostats.hasThermostats(this._basRoom)) {

      const length = this._basRoom.room.thermostats.length
      for (let i = 0; i < length; i++) {
        const thermostat =
          new BasThermostat(this._basRoom, this._basRoom.room.thermostats[i])

        this.thermostats.push(thermostat)
      }
    }
  }

  /**
   * Gets a Thermostat for the uuid
   *
   * @param {string} uuid
   * @returns {?BasGenericDevice}
   */
  BasRoomThermostats.prototype.getThermostat = function getThermostat (uuid) {

    return this.thermostats.find(thermostat => thermostat?.uuid === uuid)
  }

  /**
   * Clear all thermostat gateways
   */
  BasRoomThermostats.prototype.clearThermostats = function () {

    this.thermostats.forEach(thermostat => thermostat?.clear())
  }

  BasRoomThermostats.prototype.resetThermostats = function () {

    this.clearThermostats()

    this.thermostats = []
  }

  /**
   * Suspend all thermostats
   */
  BasRoomThermostats.prototype.suspend = function () {

    this.thermostats.forEach(thermostat => thermostat?.suspend())
  }

  /**
   * Destroy all thermostats
   */
  BasRoomThermostats.prototype.destroyThermostats = function () {

    this.thermostats.forEach(thermostat => thermostat?.destroy())
  }

  /**
   * Update translations
   */
  BasRoomThermostats.prototype.updateTranslation = function () {

    this.thermostats.forEach(thermostat => thermostat?.updateTranslation())
  }

  /**
   * Update temperature unit
   */
  BasRoomThermostats.prototype.updateTemperatureUnit = function () {

    this.thermostats.forEach(thermostat => thermostat?.updateTemperatureUnit())
  }

  /**
   * Clears the BasRoom reference
   */
  BasRoomThermostats.prototype.destroy = function () {
    this.destroyThermostats()
    this._basRoom = null
  }

  return BasRoomThermostats
}
