'use strict'

angular
  .module('basalteApp')
  .directive('basWeatherTile', [
    'BAS_HTML',
    thermostatTileDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basWeatherTile
 */
function thermostatTileDirective (
  BAS_HTML
) {
  return {
    restrict: 'EA',
    template: BAS_HTML.weatherTile,
    controller: [
      'Weather',
      controller
    ],
    controllerAs: 'weather'
  }

  /**
   * @param {Weather} Weather
   */
  function controller (Weather) {

    var weather = this

    weather.weather = Weather.get()
  }
}
