'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasLisaUi', [
    '$rootScope',
    'BAS_CURRENT_CORE',
    'BAS_ROOMS',
    'BAS_LISA',
    'STATES',
    'BAS_STATE',
    'UiBasTiles',
    'CurrentBasCore',
    'CurrentRoom',
    'BasCameras',
    'BasIntercomHelper',
    'RoomsHelper',
    'BasState',
    BasLisaUi
  ])

/**
 * @typedef {Object} TBasLisaUi
 * @property {Object} css
 * @property {string[]} functions
 * @property {Object} tiles
 * @property {string[]} uiTiles
 * @property {Object<string, string>} pages
 * @property {string[]} uiPages
 * @property {Object<string, boolean>} uiHasPage
 * @property {Object[]} tileOrder
 * @property {boolean} showStart
 */

/**
 * Service which handles Lisa
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_LISA} BAS_LISA
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {UiBasTiles} UiBasTiles
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 * @param {BasCameras} BasCameras
 * @param {BasIntercomHelper} BasIntercomHelper
 * @param {RoomsHelper} RoomsHelper
 * @param {BasState} BasState
 */
function BasLisaUi (
  $rootScope,
  BAS_CURRENT_CORE,
  BAS_ROOMS,
  BAS_LISA,
  STATES,
  BAS_STATE,
  UiBasTiles,
  CurrentBasCore,
  CurrentRoom,
  BasCameras,
  BasIntercomHelper,
  RoomsHelper,
  BasState
) {
  var DEFAULT_TILE_ORDER = [
    {
      value: BAS_LISA.F_SCENES,
      showPage: true
    },
    {
      value: BAS_LISA.F_LIGHTS,
      showPage: true
    },
    {
      value: BAS_LISA.F_SHADES,
      showPage: true
    },
    {
      value: BAS_LISA.F_THERMOSTAT,
      showPage: true
    },
    {
      value: BAS_LISA.F_MUSIC,
      showPage: true
    },
    {
      value: BAS_LISA.F_CAMERA,
      showPage: false
    },
    {
      value: BAS_LISA.F_INTERCOM,
      showPage: false
    }
  ]

  var homePage = 'start'
  var scenePage = 'scenes'
  var thermostatPage = 'thermostat'
  var lightPage = 'lights'
  var musicPage = 'music'
  var shadePage = 'shades'
  var cameraPage = 'cameras'
  var intercomPage = 'intercom'

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TCurrentRoomState}
   */
  var currentRoom = CurrentRoom.get()

  /**
   * @type {TUiBasTiles}
   */
  var uiBasTiles = UiBasTiles.get()

  /**
   * @type {TBasLisaUi}
   */
  var state = {
    css: {},
    functions: [],
    tiles: [],
    pages: [],
    uiTiles: [],
    uiPages: [],
    uiHasPage: {},
    tileOrder: [],
    showStart: true
  }

  /**
   * @type {?Object<string, Array<string>>}
   */
  var lastKnownOrderData

  state.tiles[BAS_LISA.F_START] = uiBasTiles.lisa_start
  state.tiles[BAS_LISA.F_CAMERA] = uiBasTiles.lisa_cameras
  state.tiles[BAS_LISA.F_INTERCOM] = uiBasTiles.lisa_intercom
  state.tiles[BAS_LISA.F_LIGHTS] = uiBasTiles.lisa_lights
  state.tiles[BAS_LISA.F_MUSIC] = uiBasTiles.lisa_music
  state.tiles[BAS_LISA.F_SHADES] = uiBasTiles.lisa_shades
  state.tiles[BAS_LISA.F_THERMOSTAT] = uiBasTiles.lisa_thermostat
  state.tiles[BAS_LISA.F_SCENES] = uiBasTiles.lisa_scenes
  state.tiles[BAS_LISA.F_SETTINGS] = uiBasTiles.lisa_settings

  state.pages[BAS_LISA.F_START] = homePage
  state.pages[BAS_LISA.F_CAMERA] = cameraPage
  state.pages[BAS_LISA.F_INTERCOM] = intercomPage
  state.pages[BAS_LISA.F_LIGHTS] = lightPage
  state.pages[BAS_LISA.F_MUSIC] = musicPage
  state.pages[BAS_LISA.F_SHADES] = shadePage
  state.pages[BAS_LISA.F_THERMOSTAT] = thermostatPage
  state.pages[BAS_LISA.F_SCENES] = scenePage

  this.get = get
  this.saveTileOrder = saveTileOrder
  this.saveShowStart = saveShowStart
  this.getLisaStartupState = getLisaStartupState

  init()

  function init () {

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    )
    $rootScope.$on(
      BAS_ROOMS.EVT_CURRENT_ROOM_CHANGED,
      _onCurrentRoomChanged
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_LISA_TILES_ORDER,
      _onLisaTilesOrderUpdated
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_LISA_SHOW_START,
      _onLisaShowStartUpdated
    )

    _syncFunctions()
    _syncTileOrder()
    _syncTilesAndPages(false)
  }

  /**
   * @public
   * @returns {TBasLisaUi}
   */
  function get () {

    return state
  }

  function saveTileOrder () {

    if (CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.sharedServerStorage) {

      currentBasCoreState.core.core.sharedServerStorage
        .updateLisaTilesOrder(currentRoom.roomId, state.tileOrder)
        .then(_syncTileOrder)
        .then(_syncTilesAndPages)
    }
  }

  function saveShowStart () {

    if (CurrentBasCore.hasCore()) {

      const core = currentBasCoreState.core.core
      core.sharedServerStorage
        ?.updateLisaShowStart(core.profile.uuid, state.showStart)
        .then(_syncTilesAndPages)
    }
  }

  function getLisaStartupState () {

    if (!state.showStart) {
      const firstPage = state.uiPages[0]

      switch (firstPage) {
        case scenePage:
          return BasState.target(STATES.LISA_SCENES)
        case thermostatPage:
          return BasState.target(STATES.LISA_THERMOSTAT)
        case lightPage:
          return BasState.target(STATES.LISA_LIGHTS)
        case musicPage:
          return BasState.target(STATES.LISA_MUSIC)
        case shadePage:
          return BasState.target(STATES.LISA_SHADES)
        case cameraPage:
          // Cameras is a native view, which makes it hard to
          //  navigate to right on app startup. Instead, just go to
          //  tiles overview
          return BasState.target(STATES.LISA_TILES)
        case intercomPage:
          return BasState.target(STATES.LISA_INTERCOM)
        default:
          return BasState.target(STATES.LISA_TILES)
      }
    } else {
      return BasState.target(
        STATES.LISA,
        {
          view: BAS_STATE.S_ROOMS_VIEW_FIRST
        }
      )
    }
  }

  function _onRoomsUpdated () {

    _syncFunctions()
    _syncTileOrder()
    _syncTilesAndPages()
  }

  function _onCurrentRoomChanged () {

    _syncFunctions()
    _syncTileOrder()
    _syncTilesAndPages()
  }

  function _onLisaTilesOrderUpdated () {

    _syncTileOrder()
    _syncTilesAndPages()
  }

  function _onLisaShowStartUpdated () {
    const core = currentBasCoreState?.core?.core
    state.showStart = core
      ?.sharedServerStorage
      ?.lisaShowStart[core.profile.uuid] !== false
    _syncTilesAndPages()
  }

  function _syncTileOrder () {

    var sharedServerStorage

    if (CurrentBasCore.hasCore()) {

      sharedServerStorage = currentBasCoreState.core.core.sharedServerStorage

      if (
        sharedServerStorage &&
        sharedServerStorage.lisaTilesOrder
      ) {

        _setTileOrder(sharedServerStorage.lisaTilesOrder)
      }
    }
  }

  /**
   * @private
   * @param {boolean} [emit = true]
   */
  function _syncTilesAndPages (emit) {

    var _emit

    _emit = BasUtil.isBool(emit) ? emit : true

    _resetPages()
    _resetTiles()

    if (state.showStart) {
      _addLisaElement(BAS_LISA.F_START, true)
    }
    _processOrderAndFunctions()
    _addLisaElement(BAS_LISA.F_SETTINGS, true)

    if (_emit) $rootScope.$emit(BAS_LISA.EVT_LISA_UPDATED)
  }

  function _syncFunctions () {

    var room

    room = CurrentRoom.getRoom()

    _resetFunctions()

    if (room && room.room) {

      if (RoomsHelper.roomHasFunctionScenes(room)) {

        state.functions.push(BAS_LISA.F_SCENES)
      }

      if (RoomsHelper.roomHasFunctionThermostat(room)) {

        state.functions.push(BAS_LISA.F_THERMOSTAT)
      }

      if (RoomsHelper.roomHasFunctionLights(room)) {

        state.functions.push(BAS_LISA.F_LIGHTS)
      }

      if (RoomsHelper.hasAudio(room.room)) {

        state.functions.push(BAS_LISA.F_MUSIC)
      }

      if (RoomsHelper.roomHasFunctionWindowTreatments(room)) {

        state.functions.push(BAS_LISA.F_SHADES)
      }
    }

    if (BasCameras.shouldShowCameras()) {

      state.functions.push(BAS_LISA.F_CAMERA)
    }

    if (BasIntercomHelper.shouldShowIntercom()) {

      state.functions.push(BAS_LISA.F_INTERCOM)
    }
  }

  function _processOrderAndFunctions () {

    var i

    // Loop over tile order, if functions contains the lisa element add it.
    for (i = 0; i < state.tileOrder.length; i++) {

      if (state.functions.indexOf(state.tileOrder[i].value) !== -1) {

        _addLisaElement(
          state.tileOrder[i].value,
          state.tileOrder[i].showPage
        )
      }
    }
  }

  function _addLisaElement (elementValue, elementShowPage) {

    // For given element, if a page or tile exists for it, add it to uiTiles
    //  or uiPages list accordingly

    if (
      elementShowPage === true &&
      state.pages[elementValue]
    ) {

      state.uiPages.push(elementValue)
    }

    if (state.tiles[elementValue]) {

      state.uiTiles.push(elementValue)
    }

    _syncUiPages()
  }

  function _syncUiPages () {

    var length, i, page

    state.uiHasPage = {}

    length = state.uiPages.length
    for (i = 0; i < length; i++) {

      page = state.uiPages[i]
      if (page) state.uiHasPage[page] = true
    }
  }

  function _setTileOrder (orderData) {

    if (BasUtil.isNEObject(orderData)) lastKnownOrderData = orderData

    _processTilesOrder(
      (
        lastKnownOrderData &&
        BasUtil.isNEArray(lastKnownOrderData[currentRoom.roomId])
      )
        ? lastKnownOrderData[currentRoom.roomId]
        : DEFAULT_TILE_ORDER
    )
  }

  function _processTilesOrder (order) {

    var i, functions, index

    functions = state.functions.slice()
    // Use order parameter and functions in room to construct a filtered
    //  version of the order list, and store it in state.tilesOrder

    // Filter non applicable room functions
    // and add missing room functions with default value
    state.tileOrder = order

    for (i = 0; i < state.tileOrder.length; i++) {

      index = functions.indexOf(state.tileOrder[i].value)

      if (index === -1) {

        // Order element does not exist in functions for this room,
        // remove
        state.tileOrder.splice(i--, 1)

      } else {

        functions.splice(index, 1)
      }
    }

    for (i = 0; i < functions.length; i++) {

      state.tileOrder.push({
        value: functions[i],
        showPage: true
      })
    }
  }

  function _resetPages () {

    state.uiPages = []
    state.uiHasPage = {}
  }

  function _resetTiles () {

    state.uiTiles = []
  }

  function _resetFunctions () {

    state.functions = []
  }
}
