'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('DeezerManager', [
    '$rootScope',
    'BAS_HTML',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_FAVOURITE',
    'BAS_LIBRARY_ERRORS',
    'BAS_LIBRARY',
    'BAS_SOURCE',
    'BAS_MODAL',
    'BAS_APP_PROFILE',
    'ModalService',
    'modalHelperService',
    'Selection',
    'BasCurrentAppProfile',
    'BasModal',
    'LibraryState',
    'DeezerModel',
    'DeezerHelper',
    'DeezerElement',
    'DeezerCollection',
    'DeezerLibraryPage',
    'BasLibraryDetail',
    'BasLibraryManager',
    'BasLibraryBody',
    'BasLibraryPage',
    'BasUtilities',
    'Logger',
    deezerManagerFactory
  ])

/**
 *
 * @param $rootScope
 * @param {BAS_HTML} BAS_HTML
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param {ModalService} ModalService
 * @param modalHelperService
 * @param Selection
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param {BasModal} BasModal
 * @param {LibraryState} LibraryState
 * @param {DeezerModel} DeezerModel
 * @param {DeezerHelper} DeezerHelper
 * @param DeezerElement
 * @param DeezerCollection
 * @param DeezerLibraryPage
 * @param BasLibraryDetail
 * @param BasLibraryManager
 * @param BasLibraryBody
 * @param BasLibraryPage
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns DeezerManager
 */
function deezerManagerFactory (
  $rootScope,
  BAS_HTML,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_FAVOURITE,
  BAS_LIBRARY_ERRORS,
  BAS_LIBRARY,
  BAS_SOURCE,
  BAS_MODAL,
  BAS_APP_PROFILE,
  ModalService,
  modalHelperService,
  Selection,
  BasCurrentAppProfile,
  BasModal,
  LibraryState,
  DeezerModel,
  DeezerHelper,
  DeezerElement,
  DeezerCollection,
  DeezerLibraryPage,
  BasLibraryDetail,
  BasLibraryManager,
  BasLibraryBody,
  BasLibraryPage,
  BasUtilities,
  Logger
) {
  var className = 'Deezer Manager'
  var URI_DEEZER_FLOW = 'deezer:radio:flow'

  /**
   * @constructor
   * @extends BasLibraryManager
   *
   * @param {PlayerLibraryState} libraryState
   */
  function DeezerManager (libraryState) {

    // Call template constructor
    BasLibraryManager.call(this, libraryState)

    // Link the control functions
    this.linkControls()

    // Initialize header
    this.header.setTitle(BasUtilities.translate('deezer'))
    this.header.setSubtitle('')
    this.header.enableSearch(true)
    this.header.enableNavigation(true)

    // Selection
    this.selection = new Selection()
    this.selection.type = Selection.TYPE_DEEZER

    // Add the start state
    this.addPage(this.startPage)

    /**
     * @instance
     * @type {number}
     */
    this.deezerId = -1
  }

  // Set new prototype from inherited object
  DeezerManager.prototype =
    Object.create(BasLibraryManager.prototype)

  // Set correct constructor after setting new prototype
  DeezerManager.prototype.constructor = DeezerManager

  DeezerManager.prototype.linkControls = function () {

    // Body controls
    this.body.control[BasLibraryBody.CLK_ELEMENT] =
      this.selectElement.bind(this)
    this.body.control[BasLibraryBody.CLK_FAVOURITE] =
      this.clickFavourite.bind(this)
    this.body.control[BasLibraryBody.CLK_BROWSE] =
      this.selectElement.bind(this)
    this.body.control[BasLibraryBody.CLK_CONTEXT] =
      this.clickContext.bind(this)
    this.body.control[BasLibraryBody.CLK_REMOVE] =
      this.clickRemove.bind(this)
    this.body.control[BasLibraryBody.CLK_PLAY] =
      this.clickPlay.bind(this)
  }

  // region Control functions

  /**
   * @param {DeezerElement} element
   * @param {string} [shortcut]
   */
  DeezerManager.prototype.selectElement = function (
    element,
    shortcut
  ) {
    var page, retrieve, detailElement, detail
    var album, artist, basSource, collection

    Logger.debug(
      className + ' - Select',
      this.currentPage,
      element
    )

    // Check if a new page needs to be created
    if (isValidShortcut(shortcut) ||
      element.canBrowse) {

      // Create a new page
      page = new DeezerLibraryPage(this.handler)
      page.collections.push(new DeezerCollection(this.handler))
    }

    // Check for shortcut
    switch (shortcut) {
      case BAS_LIBRARY.SHORTCUT_ALBUM:

        // Check element for Deezer Album object
        if (BasUtil.isObject(element) &&
          BasUtil.isObject(element.deezer) &&
          BasUtil.isObject(element.deezer.album) &&
          typeof element.deezer.album.id === 'number') {

          album = element.deezer.album

          // Could be null so always check here
          artist = element.deezer.artist

          // Titles
          page.setTitleId('album')
          page.setSubtitle(album.title)

          // Create detail
          detail = new BasLibraryDetail()
          if (BasUtil.isNEString(album.title)) {
            detail.title = album.title
          }
          if (BasUtil.isObject(artist) &&
            BasUtil.isNEString(artist.name)) {
            detail.subtitle = artist.name
          }

          // Create new element for detail view
          detailElement = DeezerModel.processDeezerObject(album)

          basSource = this.libraryState.getDeezerSource()
          if (basSource) {

            basSource.deezer.getAlbum(album.id).then(onExtraAlbum)
          }

          // Detail specifics
          detail.setCanPlay(true)
          detail.setHasContext(true)
          detail.setCanSelect(true)

          collection = page.getCollection(0)
          if (collection) {

            collection.setContentType(DeezerCollection.CT_TRACK)
            collection.type = DeezerCollection.TYPE_ALBUM
            collection.id = album.id

            // Set detail
            collection.setDetail(detail)
            collection.setDetailElement(detailElement)
          }

          retrieve = true

        } else {
          Logger.warn(
            className + ' - Select - SHORTCUT album - Invalid album object',
            element
          )
        }

        break
      case BAS_LIBRARY.SHORTCUT_ARTIST:

        // Check element for Deezer Artist object
        if (BasUtil.isObject(element) &&
          BasUtil.isObject(element.deezer) &&
          BasUtil.isObject(element.deezer.artist) &&
          typeof element.deezer.artist.id === 'number') {

          artist = element.deezer.artist

          // Titles
          page.setTitleId('artist')
          page.setSubtitle(artist.name)
          page.lockSubtitle = true

          // Create detail
          detail = new BasLibraryDetail()
          if (BasUtil.isNEString(artist.name)) {
            detail.title = artist.name
          }
          detail.setHasContext(true)

          // Create new element for detail view
          detailElement = DeezerModel.processDeezerObject(artist)

          basSource = this.libraryState.getDeezerSource()
          if (basSource) {

            basSource.deezer.getArtist(artist.id)
              .then(onExtraArtist)
          }

          collection = page.getCollection(0)
          if (collection) {

            collection.setContentType(DeezerCollection.CT_TRACK)
            collection.type = DeezerCollection.TYPE_ARTIST

            // Set detail
            collection.setDetail(detail)
            collection.setDetailElement(detailElement)
          }

          // Detail specifics
          setPageCollections(
            page,
            artist.id,
            DeezerCollection.TYPE_ARTIST
          )

          retrieve = true

        } else {
          Logger.warn(
            className + ' - Select - SHORTCUT artist - Invalid artist object',
            element
          )
        }

        break
      default:

        // Process selected UI element
        retrieve = this.processElement(page, element)
    }

    // Check page
    if (BasUtil.isObject(page)) {

      // Check retrieve
      if (retrieve === true) {

        // Add page
        this.addPage(page, true)
      } else {

        // Add page
        this.addPage(page)
      }
    }

    function onExtraAlbum (result) {
      var _element, _collection

      _element = DeezerModel.processDeezerObject(result)
      _collection = page.getCollection(0)

      if (BasUtil.isObject(_element)) {

        if (_collection) _collection.setDetailElement(_element)

        // Check if it has coverArt
        detail.setHasCoverArt(_element.covers.large)
      }
    }

    function onExtraArtist (result) {
      var _element, collections, length, i, _collection

      _element = DeezerModel.processDeezerObject(result)

      if (BasUtil.isObject(_element)) {

        collections = page.collectionsData
        length = collections.length
        for (i = 0; i < length; i++) {
          _collection = collections[i]
          _collection.setDetailElement(_element)

          // Check if it has coverArt
          detail.setHasCoverArt(_element.covers.large)
        }

      }
    }
  }

  /**
   * @param {?DeezerLibraryPage} page used as output parameter
   * @param {DeezerElement} element
   * @returns {boolean}
   */
  DeezerManager.prototype.processElement = function (
    page,
    element
  ) {
    var retrieve, detail, searchQuery, basSource, collection

    retrieve = false

    switch (element.type) {
      case DeezerElement.TYPE_SECTION:

        // Check sub type for section
        switch (element.subType) {
          case DeezerModel.SECTION_FLOW:

            basSource = this.libraryState.getBasSource()

            // Start Deezer Flow
            if (
              basSource &&
              basSource.source
            ) {

              if (basSource.isAudioSource) {

                basSource.playUri(URI_DEEZER_FLOW)

              } if (basSource.source.queue) {

                basSource.source.queue.startDeezerFlow()
              }

            }

            break
          case DeezerModel.SECTION_MY_MUSIC:

            // Create "My Music" page
            page.setContent(DeezerLibraryPage.CONTENT_MY_MUSIC)
            page.setTitleId('my_music')
            setPageCollections(
              page,
              element.id,
              DeezerCollection.TYPE_USER
            )

            // Set retrieve for tab to true
            retrieve = true

            break
          case DeezerModel.SECTION_POPULAR:

            page.setContent(DeezerLibraryPage.CONTENT_GENRE)
            page.setTitleId('popular')
            setPageCollections(
              page,
              element.id,
              DeezerCollection.TYPE_GENRE
            )

            // Set retrieve for tab to true
            retrieve = true

            break
          case DeezerModel.SECTION_GENRES:

            // Title
            page.setTitleId('genres')

            // Content type
            page.setContent(DeezerLibraryPage.CONTENT_GENRES)

            collection = page.getCollection(0)
            if (collection) {

              collection.setContentType(
                DeezerCollection.CT_GENRE
              )
              collection.setCanGridView(true)
              collection.filter =
                DeezerCollection.FILTER_GENRE_ALL
            }

            retrieve = true

            break
          case DeezerModel.SECTION_MORE:

            // Check for query
            if (element.query) {

              // Set search query
              searchQuery = element.query

              // Check for extra title
              if (typeof searchQuery === 'string' &&
                searchQuery.length > 0) {

                // Title
                page.setTitleId('search')
                page.setSubtitle(searchQuery)

              } else {

                // Title
                page.setTitleId('search')
              }
            }

            collection = page.getCollection(0)
            if (collection) {

              collection.setCanGridView(true)
              collection.setContentType(element.contentType)
              collection.id = searchQuery
              collection.type = DeezerCollection.TYPE_SEARCH
            }

            retrieve = true

            break
          default:
            Logger.warn(className + ' - Select - Unknown subType', element)
        }

        break
      case DeezerElement.TYPE_ALBUM:

        // Title
        page.setTitleId('album')
        page.setSubtitle(element.title)

        // Create detail
        detail = new BasLibraryDetail()
        detail.title = element.title
        detail.subtitle = element.subtitle
        detail.setHasCoverArt(element.covers.large)
        detail.setCanPlay(true)
        detail.setHasContext(true)
        detail.setCanSelect(true)

        collection = page.getCollection(0)
        if (collection) {
          // Assign detail
          collection.setDetail(detail)
          collection.setDetailElement(element)
          collection.setContentType(DeezerCollection.CT_TRACK)
          collection.type = DeezerCollection.TYPE_ALBUM
          collection.id = element.id
        }

        retrieve = true

        break
      case DeezerElement.TYPE_ARTIST:

        // Title
        page.setTitleId('artist')
        page.setSubtitle(element.title)

        // Create detail
        detail = new BasLibraryDetail()
        detail.title = element.title
        detail.subtitle = element.subtitle
        detail.setHasCoverArt(element.covers.large)
        detail.setHasContext(true)

        // Assign detail
        page.lockSubtitle = true

        collection = page.getCollection(0)
        if (collection) {

          collection.setDetail(detail)
          collection.setDetailElement(element)
        }

        // Make page collections
        setPageCollections(
          page,
          element.id,
          DeezerCollection.TYPE_ARTIST
        )

        // TODO: Re-enable this when core adds support for this
        //  https://basalte.atlassian.net/browse/CORE-1149
        detail.setCanPlay(false)

        // Set retrieve for tab to true
        retrieve = true

        break
      case DeezerElement.TYPE_GENRE:

        // Get a new genre page
        page.setContent(DeezerLibraryPage.CONTENT_GENRE)
        page.setTitle(element.title)

        collection = page.getCollection(0)
        if (collection) collection.setCanGridView(true)

        setPageCollections(
          page,
          element.id,
          DeezerCollection.TYPE_GENRE
        )

        // Set retrieve for tab to true
        retrieve = true

        break
      case DeezerElement.TYPE_PLAYLIST:

        // Title
        page.setTitleId('playlist')
        page.setSubtitle(element.title)

        // Create detail
        detail = new BasLibraryDetail()

        collection = page.getCollection(0)
        if (collection) {

          collection.setCanEdit(element.creatorId === this.deezerId)

          // Assign detail
          collection.setDetail(detail)
          collection.setDetailElement(element)
          collection.setContentType(DeezerCollection.CT_TRACK)
          collection.type = DeezerCollection.TYPE_PLAYLIST
          collection.id = element.id
        }

        // Fill detail
        detail.title = element.title
        detail.subtitle = element.subtitle
        detail.setHasCoverArt(element.covers.large)
        detail.setCanPlay(true)
        detail.setCanAsanoFavourite(true)
        detail.setCanSelect(true)
        detail.setHasContext(true)
        if (collection) detail.setCanEdit(collection.canEdit)

        retrieve = true

        break
      case DeezerElement.TYPE_RADIO:

        // Play Deezer radio
        DeezerHelper.play(element)

        break
      case DeezerElement.TYPE_TRACK:

        // Play Deezer track
        DeezerHelper.play(element, BAS_API.Queue.ADD_OPTIONS.now)

        break
      default:
        Logger.warn(className + ' - Select - Unknown type', element)
    }

    // Return the retrieve
    return retrieve
  }

  /**
   * @param {DeezerElement} element
   */
  DeezerManager.prototype.clickPlay = function (element) {
    Logger.debug(className + ' - clickPlay', element)

    // Play Deezer track
    DeezerHelper.play(element, BAS_API.Queue.ADD_OPTIONS.replaceNow)
  }

  /**
   * @param {DeezerElement} element
   */
  DeezerManager.prototype.clickFavourite = function (element) {

    var basSource, identifier

    basSource = this.libraryState.getBasSource()

    identifier = (basSource && element)
      ? basSource.isAudioSource
        ? element.asanoFavouriteUri
        : element.asanoFavouriteId
      : ''

    // Check element
    if (
      BasUtil.isObject(element) &&
      BasUtil.isNEString(identifier) &&
      basSource &&
      basSource.favourites) {

      if (basSource.favourites.isFavourite(identifier)) {

        basSource.favourites.remove(identifier)

      } else {

        basSource.favourites.add(
          BAS_FAVOURITE.T_DEEZER,
          basSource.isAudioSource
            ? identifier
            : element.getFavouriteId()
        )
      }
    }
  }

  /**
   * @param {Object} data
   * @param {DeezerElement} data.element
   * @param {DeezerCollection} data.collection
   * @param data.event
   */
  DeezerManager.prototype.clickContext = function (data) {
    var _this

    _this = this

    Logger.debug(className + ' - clickContext', data.element, data.event)

    ModalService
      .showModal({
        template: BAS_HTML.templateContextModal,
        controller: 'basLibraryContextCtrl',
        controllerAs: 'modal',
        inputs: {
          event: data.event,
          element: data.element,
          collection: data.collection,
          libType: BAS_LIBRARY.TYPE_DEEZER,
          selection: null,
          handler: this.handler
        }
      })
      .then(onContextModal)

    // Set scroll lock
    this.body.setClass(BasLibraryBody.CLASS_LOCK_SCROLLING)

    function onContextModal (modal) {

      // Check modal
      if (BasUtil.isObject(modal) &&
        modal.controller &&
        typeof modal.controller.onModalReady === 'function') {

        // Execute modal ready function
        modal.controller.onModalReady()
      }

      // Set closed Promise
      modal.closed
        .then(onContextModalClosed)
    }

    function onContextModalClosed () {

      // Reset modal style
      modalHelperService.resetModalStyle()

      // Clear scroll lock
      _this.body.setClass(BasLibraryBody.CLASS_LOCK_SCROLLING, false)
    }
  }

  DeezerManager.prototype.selectMenu = function (event, selection, handler) {

    return ModalService
      .showModal({
        template: BAS_HTML.templateContextModal,
        controller: 'basLibraryContextCtrl',
        controllerAs: 'modal',
        inputs: {
          event: event,
          selection: selection,
          element: null,
          collection: null,
          libType: BAS_LIBRARY.TYPE_DEEZER,
          handler: handler
        }
      })
      .then(onModalShown)

    function onModalShown (modal) {
      Logger.debug(className +
                     ' - onModalShown', modal, modal.controller)

      // Modal is ready, DOM manipulation is allowed
      if (modal.controller &&
        modal.controller.onModalReady &&
        typeof modal.controller.onModalReady === 'function') {

        modal.controller.onModalReady()
      }

      // Set modal closed Promise callbacks
      return modal.closed.then(onModalClosed)

      function onModalClosed () {

        // Reset modal height
        modalHelperService.resetModalStyle()

        return Promise.resolve(BAS_LIBRARY.MODAL_CLOSED)
      }
    }
  }

  DeezerManager.prototype.clickRemove = function () {

    var _this = this
    var id

    // Show confirmation modal
    BasModal.show(BAS_MODAL.T_REMOVE).then(onPlaylistRemoveModal)

    function onPlaylistRemoveModal (modal) {

      // Set close Promise
      modal.close.then(onPlaylistRemoveModalClose)
    }

    function onPlaylistRemoveModalClose (result) {
      var collection, basSource

      if (result === BAS_MODAL.C_YES) {

        // Get player reference
        basSource = LibraryState.getDeezerSource()
        collection = _this.currentPage.getCollection(0)

        if (basSource &&
          basSource.deezer.isLinked() &&
          collection &&
          collection.detailElement) {

          id = collection.detailElement.id
          basSource.deezer.removePlaylist(id).then(onSuccess)
        }
      }

      function onSuccess () {

        // Set page dirty
        _this.handler(BAS_LIBRARY.EVT_PLAYLIST_CHANGED)

        // Go back to list
        _this.back()
      }
    }

  }

  DeezerManager.prototype.evtPlaylistChanged = function () {

    var pages, page, length, i, collection

    pages = this.pages
    length = pages.length
    for (i = 0; i < length; i++) {

      page = pages[i]
      collection = page.getCollection(0)

      if (BasUtil.isObject(collection) &&
        collection.contentType === DeezerCollection.CT_PLAYLIST &&
        !BasUtil.isObject(collection.detail)) {

        page.dirty = true
      }
    }
  }

  // endregion

  // region Header controls

  /**
   * @param {string} searchQuery
   */
  DeezerManager.prototype.search = function (searchQuery) {

    var searchPage

    if (BasUtil.isNEString(searchQuery)) {

      // Create new search page
      searchPage = new DeezerLibraryPage(this.handler)

      // Page content type
      searchPage.content = DeezerLibraryPage.CONTENT_SEARCH

      // Titles
      searchPage.setTitleId('search')
      searchPage.setSubtitle(searchQuery)

      // Set page search options
      searchPage.setQuery(searchQuery)

      // Add page
      this.addPage(searchPage, true)

      // Make sure to close the search
      this.header.toggleSearchInput(false)

      BasCurrentAppProfile.addSearchHistory(
        searchQuery,
        BAS_APP_PROFILE.K_DEEZER
      )

      // Clear searchQuery
      this.searchQuery = ''
    }
  }

  DeezerManager.prototype.toggleSearch = function () {

    if (this.currentPage &&
      this.currentPage.content === DeezerLibraryPage.CONTENT_SEARCH) {

      this.searchQuery = this.currentPage.subtitle
    }
  }

  // endregion

  // region Helper functions

  DeezerManager.prototype.createStartPage = function () {

    // Create new page
    this.startPage = new DeezerLibraryPage(this.handler)
    this.startPage.collections.push(new DeezerCollection(this.handler))
    this.startPage.setTitleId('deezer')

    // Set page content type
    this.startPage.setContent(BasLibraryPage.CONTENT_START)

    // Add Deezer flow element
    this.startPage.addElement(
      0,
      DeezerModel.createSection(DeezerModel.SECTION_FLOW)
    )

    // Add start menu elements
    this.startPage.addElement(
      0,
      DeezerModel.createSection(DeezerModel.SECTION_MY_MUSIC)
    )
    this.startPage.addElement(
      0,
      DeezerModel.createSection(DeezerModel.SECTION_POPULAR)
    )
    this.startPage.addElement(
      0,
      DeezerModel.createSection(DeezerModel.SECTION_GENRES)
    )
  }

  DeezerManager.prototype.changeTitleRequest = function (title, id) {

    var _this = this
    var basSource = LibraryState.getDeezerSource()

    // Check if player and Deezer is valid
    if (basSource &&
      basSource.deezer.isLinked()) {

      return basSource.deezer.changePlaylistTitle(id, title)
        .then(onSuccess)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_DEEZER)

    function onSuccess () {
      _this.handler(BAS_LIBRARY.EVT_PLAYLIST_CHANGED)
    }
  }

  /**
   * @param shortcut
   * @returns {boolean}
   */
  function isValidShortcut (shortcut) {
    return (
      shortcut === BAS_LIBRARY.SHORTCUT_ALBUM ||
      shortcut === BAS_LIBRARY.SHORTCUT_ARTIST
    )
  }

  /**
   *
   * @param {DeezerLibraryPage} page
   * @param {number} parentId
   * @param {string} type
   */
  function setPageCollections (page, parentId, type) {

    switch (type) {
      case DeezerCollection.TYPE_USER:
      case DeezerCollection.TYPE_GENRE:
        page.makeCollection({
          name: 'playlists_l',
          titleName: 'playlists',
          contentType: DeezerCollection.CT_PLAYLIST,
          type: type,
          id: parentId
        })
        page.makeCollection({
          name: 'mixes_small',
          titleName: 'mixes',
          contentType: DeezerCollection.CT_RADIO,
          type: type,
          id: parentId
        })
        page.makeCollection({
          name: 'albums',
          titleName: 'albums',
          contentType: DeezerCollection.CT_ALBUM,
          type: type,
          id: parentId
        })
        page.makeCollection({
          name: 'artists',
          titleName: 'artists',
          contentType: DeezerCollection.CT_ARTIST,
          type: type,
          id: parentId
        })
        page.makeCollection({
          name: 'songs',
          titleName: 'songs',
          contentType: DeezerCollection.CT_TRACK,
          type: type,
          id: parentId
        })
        break
      case DeezerCollection.TYPE_ARTIST:
        page.makeCollection({
          name: 'top_tracks',
          titleName: 'top_tracks',
          canGridView: false,
          contentType: DeezerCollection.CT_TRACK,
          type: type,
          id: parentId
        })
        page.makeCollection({
          name: 'albums',
          titleName: 'albums',
          canGridView: false,
          contentType: DeezerCollection.CT_ALBUM,
          type: type,
          id: parentId
        })
        page.makeCollection({
          name: 'playlists_l',
          titleName: 'playlists',
          canGridView: false,
          contentType: DeezerCollection.CT_PLAYLIST,
          type: type,
          id: parentId
        })
        break
    }

    page.makeTabs()
  }

  // endregion

  // region Resume & Suspend

  DeezerManager.prototype._onDeezerLinkChanged = function (_event, id) {

    if (id === this.libraryState.playerId) this.checkDeezerLink()
  }

  DeezerManager.prototype._onMusicConfig = function () {

    this.checkDeezerLink()
  }

  DeezerManager.prototype.checkDeezerLink = function () {

    var basSource = this.libraryState.getDeezerSource()

    if (basSource) {

      basSource.deezer.linked().then(this._checkDeezerLink.bind(this))
    }
  }

  /**
   * Checks whether the linked deezer is still valid
   */
  DeezerManager.prototype._checkDeezerLink = function () {

    var id
    var basSource = this.libraryState.getDeezerSource()

    if (basSource &&
      basSource.deezer.isLinked()) {

      id = basSource.deezer.getId()

      // Make sure NoDeezerPage is gone before you want to go home
      this.removeNotConnectedPage()

      if (id !== this.deezerId &&
        BasUtil.isPNumber(this.deezerId, true)) {

        // Clear selection
        this.selection.clear()

        // Go to homepage
        this.home()
      }

      // Set deezer after check
      this.deezerId = id

    } else {

      this.deezerId = -1

      // Clear selection
      this.selection.clear()

      // Go to homepage
      this.home()

      // Show "No Deezer" page
      this.addNotConnectedPage()
    }
  }

  /**
   * Resume listening to events
   *
   * @method
   */
  DeezerManager.prototype.resume = function () {

    Logger.debug(className + ' - Resume')

    // Wait one frame to set new scrollPos
    if (this.currentPage) {
      BasUtilities.waitFrames(2).then(
        this.setScrollPos.bind(
          this,
          this.currentPage.getScrollPercent()
        )
      )
    }

    // Set listeners
    this.listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_DEEZER_LINK_CHANGED,
      this._onDeezerLinkChanged.bind(this)
    ))
    this.listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      this._onMusicConfig.bind(this)
    ))

    // Check link
    this.checkDeezerLink()

    // Translate
    this.onTranslationChange()

    // Resume the current page
    if (BasUtil.isObject(this.currentPage)) {
      this.currentPage.resume()
    }
  }

  // endregion

  return DeezerManager

}
