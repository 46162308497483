'use strict'

angular.module('basImageTransition', [
  'ngSanitize'
])

require('./basimage.constant')
require('./basimage.factory')
require('./basimagetrans.factory')
require('./basimage.directive')
require('./basimageutil.service')
