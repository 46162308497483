'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basLibraryPage', [
    'BAS_HTML',
    basLibraryPageDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basLibraryPage
 */
function basLibraryPageDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    bindToController: {
      api: '=basPageApi',
      page: '=basPage',
      selection: '=basSelection'
    },
    controller: [
      '$scope',
      '$element',
      '$attrs',
      'BAS_SOURCES',
      'BasLibraryBody',
      'BasLibraryPage',
      'BasLibraryCollection',
      'LibraryState',
      'Logger',
      controller
    ],
    controllerAs: 'basPage',
    template: BAS_HTML.pageBasLibrary
  }

  /**
   *
   * @param $scope
   * @param $element
   * @param $attrs
   * @param BAS_SOURCES
   * @param BasLibraryBody
   * @param BasLibraryPage
   * @param BasLibraryCollection
   * @param {LibraryState} LibraryState
   * @param Logger
   */
  function controller (
    $scope,
    $element,
    $attrs,
    BAS_SOURCES,
    BasLibraryBody,
    BasLibraryPage,
    BasLibraryCollection,
    LibraryState,
    Logger
  ) {
    var controllerName = 'Bas Library Body Ctrl'

    /**
     * @type {Object}
     * @property {?BasLibraryPage} api
     */
    var basPage = this

    basPage.BAS_SOURCES = BAS_SOURCES

    basPage.BasLibraryPage = BasLibraryPage

    /**
     * @type {TLibraryState}
     */
    basPage.libraryState = LibraryState.get()

    // Register action functions
    basPage.clickElement = clickElement
    basPage.longPressElement = longPressElement
    basPage.clickFavourite = clickFavourite
    basPage.clickContext = clickContext
    basPage.clickBrowse = clickBrowse
    basPage.clickPlay = clickPlay
    basPage.clickRemove = clickRemove
    basPage.clickSelect = clickSelect
    basPage.clickEdit = clickEdit
    basPage.clickShare = clickShare
    basPage.titleChange = titleChange
    basPage.selectTab = selectTab

    Logger.debug(
      controllerName + ' - STATE',
      $scope,
      $element,
      $attrs,
      basPage
    )

    $scope.$on('$destroy', cleanUp)

    function selectTab (tab) {

      Logger.debug(controllerName + ' - SELECT tab', tab)

      executeFunction(BasLibraryBody.SELECT_TAB, tab)
    }

    function clickElement (element) {
      Logger.debug(controllerName + ' - CLICK Element', element)

      executeFunction(BasLibraryBody.CLK_ELEMENT, element)
    }

    function longPressElement (element, collection, event) {
      Logger.debug(controllerName + ' - LONG PRESS Element', element)

      if (
        basPage.page &&
        basPage.page.collections &&
        basPage.page.collections.length &&
        basPage.page.collections[0].canGridView &&
        basPage.page.collections[0].gridView === BasLibraryCollection.VIEWS
          .indexOf(BasLibraryCollection.VIEW_GRID)
      ) {

        executeFunction(
          BasLibraryBody.CLK_CONTEXT,
          {
            element: element,
            collection: collection,
            event: event
          }
        )
      } else {

        executeFunction(
          BasLibraryBody.CLK_ELEMENT,
          element
        )
      }
    }

    function clickFavourite (element) {
      Logger.debug(controllerName + ' - CLICK Favourite', element)

      executeFunction(BasLibraryBody.CLK_FAVOURITE, element)
    }

    function clickContext (element, collection, event) {
      Logger.debug(controllerName + ' - CLICK Context', element)

      executeFunction(
        BasLibraryBody.CLK_CONTEXT,
        {
          element: element,
          collection: collection,
          event: event
        }
      )
    }

    function titleChange () {
      Logger.debug(controllerName +
                     ' - CHANGE Title')

      executeFunction(BasLibraryBody.CHN_TITLE)
    }

    function clickSelect (element) {
      Logger.debug(controllerName + ' - CLICK Select', element)

      executeFunction(BasLibraryBody.CLK_SELECT, element)
    }

    function clickEdit () {
      Logger.debug(controllerName +
                     ' - CLICK Edit')

      executeFunction(BasLibraryBody.CLK_EDIT)
    }

    function clickShare (element) {
      Logger.debug(controllerName +
                     ' - CLICK Share')

      executeFunction(BasLibraryBody.CLK_SHARE, element)
    }

    function clickBrowse (element) {
      Logger.debug(controllerName + ' - CLICK Browse', element, basPage)

      executeFunction(BasLibraryBody.CLK_BROWSE, element)
    }

    function clickRemove (element) {
      Logger.debug(controllerName + ' - CLICK Remove')

      executeFunction(BasLibraryBody.CLK_REMOVE, element)
    }

    function clickPlay (element) {
      Logger.debug(controllerName + ' - CLICK Play', element)

      executeFunction(BasLibraryBody.CLK_PLAY, element)
    }

    function cleanUp () {
    }

    /**
     * Checks whether the external function exists and executes it
     *
     * @param {string} name
     * @param argument
     */
    function executeFunction (name, argument) {

      // Check API function
      if (BasUtil.isObject(basPage.api) &&
        BasUtil.isObject(basPage.api.control) &&
        typeof basPage.api.control[name] === 'function') {

        // Execute function
        basPage.api.control[name](argument)
      }
    }
  }
}
