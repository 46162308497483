'use strict'

angular
  .module('basalteApp')
  .constant('BAS_UNITS', {
    EVT_TEMPERATURE_UNIT_CHANGED: 'evtBasUnitTemperatureChanged',
    DEGREES_C: '°C',
    DEGREES: '°',
    KM_H: 'km/h',
    HPA: 'hPa',
    MM_H: 'mm/h',
    LUX: 'lx',
    PERCENT: '%'
  })
