'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

/**
 * @constructor
 * @param {Object} [capabilities]
 * @since 3.4.0
 */
function Capabilities (capabilities) {

  this.capabilities = {}

  if (BasUtil.isObject(capabilities)) this.parse(capabilities)
}

/**
 * Parse capabilities
 *
 * @param {Object} capabilities
 * @returns {boolean} Boolean indicating whether capabilities have changed
 */
Capabilities.prototype.parse = function (capabilities) {

  if (BasUtil.isObject(capabilities)) {

    if (!BasUtil.isEqualObject(this.capabilities, capabilities)) {

      this.capabilities = capabilities
      return true
    }
  }

  return false
}

/**
 * Checks if a capability allows read
 *
 * @param {string} capability
 * @param {string} [subType]
 * @returns {boolean}
 */
Capabilities.prototype.allowsRead = function (capability, subType) {

  return this.allows(capability, P.R, subType) === P.R
}

/**
 * Checks if a capability allows write
 *
 * @param {string} capability
 * @param {string} [subType]
 * @returns {boolean}
 */
Capabilities.prototype.allowsWrite = function (capability, subType) {

  return this.allows(capability, P.W, subType) === P.W
}

/**
 * Checks if a capability allows execute
 *
 * @param {string} capability
 * @param {string} [subType]
 * @returns {boolean}
 */
Capabilities.prototype.allowsExecute = function (capability, subType) {

  return this.allows(capability, P.X, subType) === P.X
}

/**
 * Checks certain functions on a capability
 *
 * @param {string} capability
 * @param {string} functions
 * @param {string} [subType]
 * @returns {string}
 */
Capabilities.prototype.allows = function (capability, functions, subType) {

  return Capabilities.allows(this.capabilities, capability, functions, subType)
}

/**
 * Checks certain functions on a capability
 *
 * @param {Object<string, string>} capabilities
 * @param {string} capability
 * @param {string} functions
 * @param {string} [category]
 * @returns {string}
 */
Capabilities.allows = function (
  capabilities,
  capability,
  functions,
  category
) {
  if (BasUtil.isObject(capabilities) &&
    BasUtil.isNEString(functions)) {

    if (BasUtil.isNEString(category)) {

      if (BasUtil.isObject(capabilities[category]) &&
        BasUtil.isNEString(capabilities[category][capability])) {

        return BasUtil.searchCharacters(
          capabilities[category][capability],
          functions
        )
      }

    } else {

      if (BasUtil.isNEString(capabilities[capability])) {

        return BasUtil.searchCharacters(
          capabilities[capability],
          functions
        )
      }
    }
  }

  return ''
}

/**
 * This provides methods used for capability checking. Required a 'Capabilities'
 * instance on the object with variable name CONSTANTS.CAPABILITIES.
 *
 * It's not meant to be used directly.
 *
 * @mixin
 */
Capabilities.mixin = {

  /**
   * Checks if a capability allows read
   *
   * @param {string} capability
   * @param {string} [subType]
   * @returns {boolean}
   */
  allowsRead: function (capability, subType) {
    return this[CONSTANTS.CAPABILITIES].allowsRead(capability, subType)
  },

  /**
   * Checks if a capability allows read
   *
   * @param {string} capability
   * @param {string} [subType]
   * @returns {boolean}
   */
  allowsWrite: function (capability, subType) {
    return this[CONSTANTS.CAPABILITIES].allowsWrite(capability, subType)
  },

  /**
   * Checks if a capability allows read
   *
   * @param {string} capability
   * @param {string} [subType]
   * @returns {boolean}
   */
  allowsExecute: function (capability, subType) {
    return this[CONSTANTS.CAPABILITIES].allowsExecute(capability, subType)
  }
}

module.exports = Capabilities
