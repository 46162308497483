'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('ScenesState', [
    '$rootScope',
    '$uiRouterGlobals',
    '$transitions',
    'STATES',
    'SCENE_STATES',
    'BAS_ROOMS',
    'BAS_SCENE',
    'BasStateHelper',
    'RoomsHelper',
    'CurrentRoom',
    'BasSceneStateHelper',
    'BasSceneHelper',
    'UiHelper',
    ScenesState
  ])

/**
 * Helper to check access to scene states based on scene info
 *
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param $transitions
 * @param {STATES} STATES
 * @param {SCENE_STATES} SCENE_STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SCENE} BAS_SCENE
 * @param {BasStateHelper} BasStateHelper
 * @param {RoomsHelper} RoomsHelper
 * @param {CurrentRoom} CurrentRoom
 * @param {BasSceneStateHelper} BasSceneStateHelper
 * @param {BasSceneHelper} BasSceneHelper
 * @param {UiHelper} UiHelper
 */
function ScenesState (
  $rootScope,
  $uiRouterGlobals,
  $transitions,
  STATES,
  SCENE_STATES,
  BAS_ROOMS,
  BAS_SCENE,
  BasStateHelper,
  RoomsHelper,
  CurrentRoom,
  BasSceneStateHelper,
  BasSceneHelper,
  UiHelper
) {
  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  init()

  function init () {

    $transitions.onStart(
      {
        to: _toMatchCriteria
      },
      _onStateChanged
    )

    $rootScope.$on(
      BAS_SCENE.EVT_SCENE_UPDATED,
      _onSceneUpdated
    )
  }

  function _toMatchCriteria (state) {

    var toName

    toName = state.name

    return (toName === STATES.SCENES || toName === STATES.ROOM_SCENES)
      ? true
      : toName.indexOf(SCENE_STATES.SCENE) !== -1
  }

  function _onStateChanged (transition) {

    var target, toName, toParams, from, fromName, scenes, firstSceneUuid, params

    target = transition.targetState()
    toName = target.name()
    toParams = target.params()
    from = transition.from()
    fromName = from.name

    if (toName === STATES.SCENES || toName === STATES.ROOM_SCENES) {

      scenes = _getScenes(
        toName === STATES.SCENES
          ? RoomsHelper.getHome()
          : RoomsHelper.getRoomForId(toParams.room)
      )

      if (scenes) {

        firstSceneUuid = scenes.getFirstSceneUuid()

        if (basUi.prop.wMedium && firstSceneUuid) {

          params = toParams
          params.scene = firstSceneUuid

          return toName === STATES.SCENES
            ? target
              .withState(STATES.SCENE)
              .withParams(params)
            : target
              .withState(STATES.ROOM_SCENE)
              .withParams(params)
        }

      } else {

        return toName === STATES.SCENES
          ? BasUtil.startsWith(fromName, STATES.SCENES)
            ? target.withState(STATES.HOME)
            : false
          : BasUtil.startsWith(fromName, STATES.ROOM_SCENES)
            ? target.withState(STATES.ROOM)
            : false
      }

    } else if (BasUtil.startsWith(toName, STATES.SCENE)) {

      // Home scenes

      return _handleSceneStateChange(
        transition,
        BasSceneStateHelper.getPrefix(STATES.SCENE),
        RoomsHelper.getHome()
      )

    } else if (BasUtil.startsWith(toName, STATES.ROOM_SCENE)) {

      // Room scenes

      return _handleSceneStateChange(
        transition,
        BasSceneStateHelper.getPrefix(STATES.ROOM_SCENE),
        CurrentRoom.getRoom()
      )
    }
  }

  /**
   * @private
   * @param transition
   * @param {string} prefix
   * @param {BasRoom} room
   * @returns {(undefined|Object)}
   */
  function _handleSceneStateChange (
    transition,
    prefix,
    room
  ) {
    var targetState, toName
    var _state, _stateLookALike, _scenes, _scene, _step
    var _ssScenes, _ssScene, _ssStep
    var _ssStepLights, _ssStepDevices, _ssStepShades
    var _ssStepThermostat, _ssStepScenes, _ssStepMusic, _ssStepVideo

    targetState = transition.targetState()
    toName = targetState.name()

    if (!room) return undefined

    _scenes = room.scenes

    if (!_scenes) return undefined

    _state = BasSceneStateHelper.getHelper(prefix)

    _ssScenes = _state.get(SCENE_STATES.SCENES)
    _ssScene = _state.get(SCENE_STATES.SCENE)
    _ssStep = _state.get(SCENE_STATES.SCENE_STEP)
    _ssStepLights = _state.get(SCENE_STATES.SCENE_STEP_LIGHTS)
    _ssStepDevices = _state.get(SCENE_STATES.SCENE_STEP_DEVICES)
    _ssStepShades = _state.get(SCENE_STATES.SCENE_STEP_SHADES)
    _ssStepThermostat = _state.get(SCENE_STATES.SCENE_STEP_THERMOSTAT_PICKER)
    _ssStepScenes = _state.get(SCENE_STATES.SCENE_STEP_SCENES)
    _ssStepMusic = _state.get(SCENE_STATES.SCENE_STEP_MUSIC)
    _ssStepVideo = _state.get(SCENE_STATES.SCENE_STEP_VIDEO)

    _stateLookALike = {
      params: transition.params()
    }

    _scene = BasSceneHelper.getScene(
      room,
      _stateLookALike
    )

    if (_scene) {

      if (toName.indexOf(_ssStep) === 0) {

        _step = BasSceneHelper.getStep(room, _stateLookALike)

        if (_step) {

          switch (toName) {
            case _ssStepLights:

              if (_step.function !==
                BAS_ROOMS.FUNCTIONS.LIGHTS) {

                return targetState.withState(_ssScene)
              }

              break
            case _ssStepDevices:

              if (_step.function !==
                BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES) {

                return targetState.withState(_ssScene)
              }

              break
            case _ssStepShades:

              if (_step.function !==
                BAS_ROOMS.FUNCTIONS.SHADES) {

                return targetState.withState(_ssScene)
              }

              break
            case _ssStepThermostat:

              if (_step.function !==
                BAS_ROOMS.FUNCTIONS.THERMOSTAT) {

                return targetState.withState(_ssScene)
              }

              break
            case _ssStepScenes:

              if (_step.function !==
                BAS_ROOMS.FUNCTIONS.SCENES) {

                return targetState.withState(_ssScene)
              }

              break
            case _ssStepMusic:

              if (_step.function !==
                BAS_ROOMS.FUNCTIONS.MUSIC) {

                return targetState.withState(_ssScene)
              }

              break
            case _ssStepVideo:

              if (_step.function !==
                BAS_ROOMS.FUNCTIONS.VIDEO) {

                return targetState.withState(_ssScene)
              }

              break
          }

        } else {

          return targetState.withState(_ssScenes)
        }
      }

    } else {

      return targetState.withState(_ssScenes)
    }
  }

  /**
   * @private
   * @param {Object} _evt
   * @param {?BasRoom} _room
   * @param {BasRoomScenes} _scenes
   * @param {string} sceneId
   */
  function _onSceneUpdated (
    _evt,
    _room,
    _scenes,
    sceneId
  ) {
    var _sceneId

    if (
      !$uiRouterGlobals.transition &&
      BasStateHelper.hasSubState(
        $uiRouterGlobals.current.name,
        SCENE_STATES.SCENE
      )
    ) {
      _sceneId = BasSceneHelper.getSceneId($uiRouterGlobals)

      if (_sceneId === sceneId) {

        CurrentRoom.go(BasSceneStateHelper.getSceneState(
          SCENE_STATES.SCENE
        ))
      }
    }
  }

  /**
   * @private
   * @param {BasRoom} room
   * @returns {?BasRoomScenes}
   */
  function _getScenes (room) {

    return (room && room.scenes && room.scenes.activateScene)
      ? room.scenes
      : null
  }
}
