'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourceDefaultRooms', [
    '$rootScope',
    'BAS_ROOMS',
    'BAS_SOURCE',
    basSourceDefaultRoomsFactory
  ])

/**
 * @param $rootScope
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SOURCE} BAS_SOURCE
 * @returns BasSourceDefaultRooms
 */
function basSourceDefaultRoomsFactory (
  $rootScope,
  BAS_ROOMS,
  BAS_SOURCE
) {

  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourceDefaultRooms (basSource) {

    /**
     * Local copy
     *
     * @type {string[]}
     */
    this.roomIds = []

    /**
     * @type {Object<string, boolean>}
     */
    this.uiRoomIds = {}

    /**
     * Local copy
     *
     * @type {string[]}
     */
    this.editableRoomIds = []

    /**
     * Local copy
     *
     * @type {string[]}
     */
    this.possibleRoomIds = []

    /**
     * Local copy
     *
     * @type {BasCollection[]}
     */
    this.basPossibleRooms = []

    /**
     * @type {string}
     */
    this.roomsString = ''

    this.handleDefaultRooms = this._onDefaultRooms.bind(this)
    this.handleAVDefaultRooms = this._onAVDefaultRooms.bind(this)
    this.handleSetDefaultRoomsError = this._onSetDefaultRoomsError.bind(this)
    this._filterPossibleRooms = this._isPossibleRoom.bind(this)

    /**
     * @private
     * @type {?BasSource}
     */
    this._basSource = basSource || null
  }

  BasSourceDefaultRooms.prototype.sync = function () {

    if (this._basSource && this._basSource.source) {

      if (this._basSource.isAudioSource) {

        this._basSource.source.listDefaultRooms()
          .then(this.handleAVDefaultRooms)

      } else {

        // Retrieve the default rooms
        this._basSource.source.retrieveDefaultZones()
          .then(this.handleDefaultRooms)
      }
    }
  }

  /**
   * @param {string} roomId
   */
  BasSourceDefaultRooms.prototype.toggleRoom = function (roomId) {

    var index = -1

    if (this._basSource && this._basSource.source) {

      if (this._basSource.isAudioSource) {

        index = this.roomIds.indexOf(roomId)

        // Reduces the "lag" effect
        if (index !== -1) {

          this.roomIds.splice(index, 1)

        } else {

          this.roomIds.push(roomId)
        }

        this._basSource.source.setDefaultRooms(this.roomIds)
          .catch(this.handleSetDefaultRoomsError)

      } else {

        if (this._basSource.source.isDefaultZone(roomId)) {

          index = this.roomIds.indexOf(roomId)

          // Reduces the "lag" effect
          if (index !== -1) {

            this.roomIds.splice(index, 1)
          }

          this._basSource.source.modifyDefaultZones(
            roomId,
            true
          )

        } else {

          // Reduces the "lag" effect
          this.roomIds.push(roomId)

          this._basSource.source.modifyDefaultZones(
            roomId,
            false
          )
        }
      }
    }

    this._syncUiRoomIds()
  }

  BasSourceDefaultRooms.prototype._onDefaultRooms = function (result) {

    var allRooms, length, i, lengthJ, j, floor

    this.roomIds = []

    if (Array.isArray(result)) {

      allRooms = []

      length = BAS_ROOMS.ROOMS.music.uiAllCollection.length
      for (i = 0; i < length; i++) {

        floor = BAS_ROOMS.ROOMS.music.uiAllCollection[i]

        lengthJ = floor.items.length
        for (j = 0; j < lengthJ; j++) {

          allRooms.push(floor.items[j])
        }
      }

      // Store lookup object on info
      this.roomIds = result.slice()
      this.editableRoomIds = allRooms.slice()
      this.possibleRoomIds = allRooms.slice()

      this._syncBasCollection()

      // Make default rooms string
      this.updateTranslation()
    }

    this._syncUiRoomIds()

    if (this._basSource) {

      $rootScope.$emit(
        BAS_SOURCE.EVT_DEFAULT_ROOMS_UPDATED,
        this._basSource.getId()
      )
    }
  }

  BasSourceDefaultRooms.prototype._onAVDefaultRooms = function (result) {

    var keys, length, i, value

    if (result && BasUtil.isObject(result.list)) {

      keys = Object.keys(result.list)

      this.roomIds = []
      this.possibleRoomIds = keys.slice()
      this.editableRoomIds = []

      length = keys.length
      for (i = 0; i < length; i++) {

        value = result.list[keys[i]]

        if (value.default) {

          this.roomIds.push(keys[i])
        }

        if (value.editable) {

          this.editableRoomIds.push(keys[i])
        }
      }

      this._syncBasCollection()

      // Make default rooms string
      this.updateTranslation()

      this._syncUiRoomIds()
    }

    if (this._basSource) {

      $rootScope.$emit(
        BAS_SOURCE.EVT_DEFAULT_ROOMS_UPDATED,
        this._basSource.getId()
      )
    }
  }

  BasSourceDefaultRooms.prototype._onSetDefaultRoomsError = function () {

    this.sync()
  }

  BasSourceDefaultRooms.prototype.updateTranslation = function () {

    var defaultRoomsString, rooms, i, length, roomId, basRoom

    rooms = BAS_ROOMS.ROOMS
    defaultRoomsString = ''

    // Iterate Ids
    length = this.roomIds.length
    for (i = 0; i < length; i++) {

      roomId = this.roomIds[i]

      // Make sure default zone ID is valid
      if (BasUtil.isNEString(roomId)) {

        // Get zone info object reference
        basRoom = rooms.rooms[roomId]

        // Check if zone is known
        if (
          BasUtil.isObject(basRoom) &&
          BasUtil.isString(basRoom.uiTitle)
        ) {
          if (BasUtil.isNEString(defaultRoomsString)) {
            defaultRoomsString += ' '
          }

          // Add name to default zones string
          defaultRoomsString += basRoom.uiTitle
        }
      }
    }

    // Set default zones string on Stream info object
    this.roomsString = defaultRoomsString
  }

  BasSourceDefaultRooms.prototype._syncBasCollection = function () {

    var length, i, collection

    this.basPossibleRooms = []

    length = BAS_ROOMS.ROOMS.music.uiAllCollection.length
    for (i = 0; i < length; i++) {

      collection = BAS_ROOMS.ROOMS.music.uiAllCollection[i].clone()

      collection.items = collection.items.filter(this._filterPossibleRooms)

      if (collection.items.length > 0) {

        this.basPossibleRooms.push(collection)
      }
    }
  }

  /**
   * @private
   * @param {string} roomId
   * @returns {boolean}
   */
  BasSourceDefaultRooms.prototype._isPossibleRoom = function (roomId) {
    return this.possibleRoomIds.indexOf(roomId) !== -1
  }

  BasSourceDefaultRooms.prototype._syncUiRoomIds = function () {

    var length, i, roomId

    this.uiRoomIds = {}

    length = this.roomIds.length
    for (i = 0; i < length; i++) {

      roomId = this.roomIds[i]

      if (roomId) this.uiRoomIds[roomId] = true
    }
  }

  BasSourceDefaultRooms.prototype.destroy = function () {

    this._basSource = null
  }

  return BasSourceDefaultRooms
}
