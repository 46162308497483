'use strict'

angular
  .module('basalteApp')
  .controller('audioAlertModalCtrl', [
    'title',
    'close',
    audioAlertModalCtrl
  ])

/**
 * @param {string} title
 * @param close
 */
function audioAlertModalCtrl (
  title,
  close
) {
  var modal = this

  modal.title = title
  modal.close = close
}
