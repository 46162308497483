'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('SpotifyLibraryPage', [
    'SpotifyCollection',
    'BasLibraryPage',
    spotifyLibraryPageFactory
  ])

/**
 * @typedef {Object} TSpotifyCollectionData
 * @property {string} name
 * @property {string} titleName
 * @property {boolean} [hasTab] default true
 * @property {boolean} [canGridView] default true
 * @property {string} contentType
 * @property {string} type
 * @property {string} id
 */

/**
 * @param SpotifyCollection
 * @param BasLibraryPage
 * @returns {SpotifyLibraryPage}
 */
function spotifyLibraryPageFactory (
  SpotifyCollection,
  BasLibraryPage
) {
  /**
   * Object that contains all info to create a library page
   *
   * @constructor
   * @extends BasLibraryPage
   */
  function SpotifyLibraryPage () {

    BasLibraryPage.call(this)
  }

  SpotifyLibraryPage.prototype = Object.create(BasLibraryPage.prototype)
  SpotifyLibraryPage.prototype.constructor = SpotifyLibraryPage

  /**
   * @constant {string}
   */
  SpotifyLibraryPage.CONTENT_GENERIC = 'generic'

  /**
   * @constant {string}
   */
  SpotifyLibraryPage.CONTENT_BROWSE = 'browse'

  /**
   * @constant {string}
   */
  SpotifyLibraryPage.CONTENT_SEARCH = 'search'

  /**
   * @constant {string}
   */
  SpotifyLibraryPage.CONTENT_MY_MUSIC = 'myMusic'

  /**
   * @constant {string}
   */
  SpotifyLibraryPage.CONTENT_GENRES = 'genres'

  /**
   * @constant {string}
   */
  SpotifyLibraryPage.CONTENT_GENRE = 'genre'

  /**
   * @param {string} content
   */
  SpotifyLibraryPage.prototype.setContent = function (content) {

    // Check input
    if (BasUtil.isNEString(content)) {
      this.content = content

      // Check content
      switch (this.content) {
        case BasLibraryPage.CONTENT_NOT_CONNECTED:

          // Show message
          this.setMessage('not_linked')
          // Add message CSS
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_OVERLAY,
            true
          )
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_MESSAGE,
            true
          )
          this.processClasses()

          break
      }
    } else {
      this.content = SpotifyLibraryPage.CONTENT_GENERIC
    }
  }

  /**
   * @param {TSpotifyCollectionData} data
   */
  SpotifyLibraryPage.prototype.makeCollection = function (
    data
  ) {
    var collection

    // Check defaults
    if (data.hasTab !== false) data.hasTab = true

    if (data.canGridView !== false) data.canGridView = true

    // Make collection
    collection = new SpotifyCollection(this.handler)

    // Set properties
    collection.setName(data.name, data.titleName)
    collection.setHasTab(data.hasTab)
    collection.setCanGridView(data.canGridView)
    collection.setContentType(data.contentType)
    collection.type = data.type
    collection.id = data.id

    // Set detail
    if (BasUtil.isObject(this.collections[0].detail)) {
      collection.detail = this.collections[0].detail
    }

    // Set detailElement
    if (BasUtil.isObject(this.collections[0].detailElement)) {
      collection.detailElement = this.collections[0].detailElement
    }

    // Push collection
    this.collectionsData.push(collection)
  }

  /**
   * @returns {Promise}
   */
  SpotifyLibraryPage.prototype.retrieve = function () {

    return this.hasTabs
      ? this.selectTab(this.tabs[0])
      : BasLibraryPage.prototype.retrieve.call(this)
  }

  return SpotifyLibraryPage
}
