[
    {
        "contact": {
            "name": "George",
            "uuid": "contact1"
        },
        "timestamp": "2024-04-08T15:11:22+0100",
        "state": "declined",
        "adelanteName": "Garage door",
        "images": [
            {
                "imgw400": "img/demo/call_history/chris_1.jpg",
                "orig": "img/demo/call_history/chris_1.jpg"
            }
        ]
    },
    {
        "contact": {
            "name": "Jane",
            "uuid": "contact2"
        },
        "timestamp": "2024-03-21T14:15:22+0100",
        "state": "accepted",
        "adelanteName": "Front Door",
        "images": [
            {
                "imgw400": "img/demo/call_history/sara_1.jpg",
                "orig": "img/demo/call_history/sara_1.jpg"
            },
            {
                "imgw400": "img/demo/call_history/sara_2.jpg",
                "orig": "img/demo/call_history/sara_2.jpg"
            }
        ]
    },
    {
        "contact": {
            "name": "Elroy",
            "uuid": "contact3"
        },
        "timestamp": "2024-02-22T10:32:22+0100",
        "state": "accepted",
        "adelanteName": "Pool House",
        "images": [
            {
                "imgw400": "img/demo/call_history/scott_1.jpg",
                "orig": "img/demo/call_history/scott_1.jpg"
            }
        ]
    },
    {
        "contact": {
            "name": "Jane",
            "uuid": "contact2"
        },
        "timestamp": "2024-01-19T10:03:22+0100",
        "state": "declined",
        "adelanteName": "Front Door",
        "images": [
            {
                "imgw400": "img/demo/call_history/claire_1.jpg",
                "orig": "img/demo/call_history/claire_1.jpg"
            }
        ]
    },
    {
        "contact": {
            "name": "George",
            "uuid": "contact1"
        },
        "timestamp": "2024-01-14T17:44:22+0100",
        "state": "accepted",
        "adelanteName": "Garage Door",
        "images": [
            {
                "imgw400": "img/demo/call_history/emma_1.jpg",
                "orig": "img/demo/call_history/emma_1.jpg"
            }
        ]
    },
    {
        "contact": {
            "name": "Jane",
            "uuid": "contact2"
        },
        "timestamp": "2023-03-25T13:22:22+0100",
        "state": "accepted",
        "adelanteName": "Front Door",
        "images": [
            {
                "imgw400": "img/demo/call_history/edward_1.jpg",
                "orig": "img/demo/call_history/edward_1.jpg"
            },
            {
                "imgw400": "img/demo/call_history/edward_2.jpg",
                "orig": "img/demo/call_history/edward_2.jpg"
            }
        ]
    }
]