'use strict'

angular
  .module('basalteApp')
  .component('thermostatDetailSettings', {
    template: [
      'BAS_HTML',
      thermostatDetailSettingsTemplate
    ],
    bindings: {
      thermostat: '<',
      onScrollUpRequested: '&'
    },
    controller: thermostatDetailSettingsCtrl,
    controllerAs: 'basThermostatDetailSettings'
  })

function thermostatDetailSettingsTemplate (BAS_HTML) {

  return BAS_HTML.thermostatDetailSettings
}

function thermostatDetailSettingsCtrl () {

  var basThermostatDetailSettings = this

  basThermostatDetailSettings.setMode = setMode
  basThermostatDetailSettings.setFanMode = setFanMode
  basThermostatDetailSettings.setLouverMode = setLouverMode

  /**
   * @param {string} mode
   */
  function setMode (mode) {

    if (basThermostatDetailSettings.thermostat &&
      basThermostatDetailSettings.thermostat.canSetMode() &&
      basThermostatDetailSettings.thermostat.setMode) {

      basThermostatDetailSettings.thermostat.setMode(mode)
    }
  }

  /**
   * @param {string} mode
   */
  function setFanMode (mode) {

    if (basThermostatDetailSettings.thermostat &&
      basThermostatDetailSettings.thermostat.canSetFanMode() &&
      basThermostatDetailSettings.thermostat.setFanMode) {

      basThermostatDetailSettings.thermostat.setFanMode(mode)
    }
  }

  /**
   * @param {string} mode
   */
  function setLouverMode (mode) {

    if (basThermostatDetailSettings.thermostat &&
      basThermostatDetailSettings.thermostat.canSetLouverMode() &&
      basThermostatDetailSettings.thermostat.setLouverMode) {

      basThermostatDetailSettings.thermostat.setLouverMode(mode)
    }
  }
}
