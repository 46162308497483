'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('thermostatWeekCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_ROOMS',
    'BAS_SCHEDULER',
    'CurrentRoom',
    'BasUtilities',
    thermostatWeekCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SCHEDULER} BAS_SCHEDULER
 * @param {CurrentRoom} CurrentRoom
 * @param {BasUtilities} BasUtilities
 */
function thermostatWeekCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_ROOMS,
  BAS_SCHEDULER,
  CurrentRoom,
  BasUtilities
) {
  var week = this

  var _POINTS_CAN_ANIMATE_TIMEOUT_MS = 400

  var _listeners = []

  var _pointsCanAnimateTimeoutId = 0

  week.$uiRouterGlobals = $uiRouterGlobals

  /**
   *
   * @type {BasRooms}
   */
  week.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasSchedulerLine[]}
   */
  week.lines = BAS_SCHEDULER.HOUR_LINES_13

  /**
   * @type {string}
   */
  week.title = ''

  /**
   * @type {boolean}
   */
  week.pointsCanAnimate = false

  /**
   * @type {TCurrentRoomState}
   */
  week.currentRoom = CurrentRoom.get()

  week.activeThermostat = BAS_ROOMS.ROOMS
    .rooms[week.currentRoom.roomId]
    ?.thermostats
    ?.getThermostat($uiRouterGlobals.params.thermostat)

  week.mainToggle = mainToggle
  week.selectEntry = selectEntry

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_CURRENT_ROOM_CHANGED,
      _onCurrentRoomChanged
    ))
    _listeners.push($rootScope.$on(
      '$translateChangeSuccess',
      _onLanguageChanged
    ))

    _updateTranslation()

    _checkMigration()
  }

  function mainToggle () {

    var scheduler

    scheduler = _getBasScheduler()

    _clearPointsCanAnimateTimeout()

    if (scheduler) {

      week.pointsCanAnimate = true

      scheduler.toggleScheduler()

      _pointsCanAnimateTimeoutId = setTimeout(
        _onPointsCanAnimateTimout,
        _POINTS_CAN_ANIMATE_TIMEOUT_MS
      )
    }
  }

  /**
   * @param {string} entryId
   */
  function selectEntry (entryId) {

    if (entryId) {

      CurrentRoom.go(
        STATES.THERMOSTAT_DAY_SCHEDULER,
        {
          day: entryId
        }
      )
    }
  }

  function _checkMigration () {

    var scheduler

    scheduler = _getBasScheduler()

    if (scheduler &&
      scheduler.needsMigration &&
      scheduler.canWriteSchedulerV2) {

      scheduler.migrate()
    }
  }

  function _updateTranslation () {

    if (BAS_ROOMS.ROOMS
      .rooms[week.currentRoom.roomId]
      ?.thermostats?.thermostats.length > 1) {

      week.title = `${week.activeThermostat.uiTitle} ${BasUtilities.translate('scheduler').toLowerCase()}`
    } else {
      week.title = BasUtilities.translate('scheduler')
    }
  }

  function _onPointsCanAnimateTimout () {

    week.pointsCanAnimate = false

    $scope.$applyAsync()
  }

  function _onRoomsUpdated () {

    _checkMigration()
  }

  function _onCurrentRoomChanged () {

    _checkMigration()
  }

  function _onLanguageChanged () {

    _updateTranslation()

    $scope.$applyAsync()
  }

  /**
   * @private
   * @returns {?BasThermostatScheduler}
   */
  function _getBasScheduler () {
    if (week.activeThermostat &&
      week.activeThermostat.thermostatScheduler) {

      return week.activeThermostat.thermostatScheduler
    }

    return null
  }

  function _clearPointsCanAnimateTimeout () {

    clearTimeout(_pointsCanAnimateTimeoutId)
    _pointsCanAnimateTimeoutId = 0
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []

    _clearPointsCanAnimateTimeout()
  }
}
