'use strict'

var BasUtil = require('@basalte/bas-util')

var Device = require('./device')

var P = require('./parser_constants')

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function CameraDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._username = ''
  this._password = ''
  this._rtsp = ''
  this._rtspRemote = ''
  this._mjpeg = ''
  this._mjpegRemote = ''
  this._openClose = ''
  this._light = ''

  this.parse(device, { emit: false })
}

CameraDevice.prototype = Object.create(Device.prototype)
CameraDevice.prototype.constructor = CameraDevice

// region Events

/**
 * @event CameraDevice#EVT_ATTRIBUTES_CHANGED
 */

// endregion

/**
 * @constant {string}
 */
CameraDevice.EVT_ATTRIBUTES_CHANGED = 'evtCameraDeviceAttributesChanged'

/**
 * @name CameraDevice#username
 * @type {string}
 * @readonly
 */
Object.defineProperty(CameraDevice.prototype, 'username', {
  get: function () {
    return this._username
  }
})

/**
 * @name CameraDevice#password
 * @type {string}
 * @readonly
 */
Object.defineProperty(CameraDevice.prototype, 'password', {
  get: function () {
    return this._password
  }
})

/**
 * @name CameraDevice#rtsp
 * @type {string}
 * @readonly
 */
Object.defineProperty(CameraDevice.prototype, 'rtsp', {
  get: function () {
    return this._rtsp
  }
})

/**
 * @name CameraDevice#rtspRemote
 * @type {string}
 * @since 3.3.0
 * @readonly
 */
Object.defineProperty(CameraDevice.prototype, 'rtspRemote', {
  get: function () {
    return this._rtspRemote
  }
})

/**
 * @name CameraDevice#mjpeg
 * @type {string}
 * @readonly
 */
Object.defineProperty(CameraDevice.prototype, 'mjpeg', {
  get: function () {
    return this._mjpeg
  }
})

/**
 * @name CameraDevice#mjpegRemote
 * @type {string}
 * @since 3.3.0
 * @readonly
 */
Object.defineProperty(CameraDevice.prototype, 'mjpegRemote', {
  get: function () {
    return this._mjpegRemote
  }
})

/**
 * @name CameraDevice#openClose
 * @type {string}
 * @readonly
 */
Object.defineProperty(CameraDevice.prototype, 'openClose', {
  get: function () {
    return this._openClose
  }
})

/**
 * @name CameraDevice#light
 * @type {string}
 * @readonly
 */
Object.defineProperty(CameraDevice.prototype, 'light', {
  get: function () {
    return this._light
  }
})

/**
 * Parse a CameraDevice message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
CameraDevice.prototype.parse = function (msg, options) {

  const valid = Device.prototype.parse.call(this, msg, options)
  let emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {
    if (BasUtil.isNEString(msg[P.USERNAME])) {
      this._username = msg[P.USERNAME]
    }
    if (BasUtil.isNEString(msg[P.PASSWORD])) {
      this._password = msg[P.PASSWORD]
    }
    if (BasUtil.isNEString(msg[P.RTSP])) {
      this._rtsp = msg[P.RTSP]
    }
    if (BasUtil.isNEString(msg[P.RTSP_REMOTE])) {
      this._rtspRemote = msg[P.RTSP_REMOTE]
    }
    if (BasUtil.isNEString(msg[P.MJPEG])) {
      this._mjpeg = msg[P.MJPEG]
    }
    if (BasUtil.isNEString(msg[P.MJPEG_REMOTE])) {
      this._mjpegRemote = msg[P.MJPEG_REMOTE]
    }
    if (BasUtil.isNEString(msg[P.OPEN_CLOSE])) {
      this._openClose = msg[P.OPEN_CLOSE]
    }
    if (BasUtil.isNEString(msg[P.LIGHT])) {
      this._light = msg[P.LIGHT]
    }

    if (emit) this.emit(CameraDevice.EVT_ATTRIBUTES_CHANGED)
  }

  return valid
}

module.exports = CameraDevice
