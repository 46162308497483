'use strict'

var BasUtil = require('@basalte/bas-util')
var EventEmitter = require('@gidw/event-emitter-js')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Capabilities = require('./capabilities')
var Device = require('./device')

/**
 * @typedef {Object} TRoomAVVideo
 * @property {string[]} compatibleSources
 * @property {Object} capabilities
 * @property {Object} state
 */

/**
 * @typedef {Object} TRoomAVOptions
 * @property {boolean} emit
 */

/**
 * Class representing AV Video
 *
 * @constructor
 * @extends EventEmitter
 * @mixes Capabilities.mixin
 * @param {BasCore} basCore
 * @param {string} roomUuid
 * @param {Object} [video]
 * @since 3.4.0
 */
function AVVideo (basCore, roomUuid, video) {

  EventEmitter.call(this)

  this._basCore = basCore

  this._roomUuid = roomUuid

  this._reachable = true

  this[CONSTANTS.CAPABILITIES] = new Capabilities(video[P.CAPABILITIES])
  this._attributes = {}
  this._state = {}

  this._handleUpdateState = this._onUpdateState.bind(this)

  if (BasUtil.isObject(video)) {

    this.parse(video, { emit: false })
  }
}

AVVideo.prototype = Object.create(EventEmitter.prototype)
AVVideo.prototype.constructor = AVVideo
BasUtil.mergeObjects(AVVideo.prototype, Capabilities.mixin)

/**
 * @constant {string}
 */
AVVideo.EVT_STATE_CHANGED = 'evtAVVideoStateChanged'

/**
 * @constant {string}
 */
AVVideo.EVT_ATTRIBUTES_CHANGED = 'evtAVVideoAttributesChanged'

/**
 * @constant {string}
 */
AVVideo.EVT_CAPABILITIES_CHANGED = 'evtAVVideoACapabilitiesChanged'

/**
 * @constant {string}
 */
AVVideo.EVT_REACHABLE_CHANGED = 'evtAVVideoReachableChanged'

/**
 * @constant {string}
 */
AVVideo.C_SOURCE = P.SOURCE

/**
 * @constant {string}
 */
AVVideo.C_ON = P.ON

/**
 * @constant {string}
 */
AVVideo.C_VOLUME = P.VOLUME

/**
 * @constant {string}
 */
AVVideo.C_VOLUME_UP = P.VOLUME_UP

/**
 * @constant {string}
 */
AVVideo.C_VOLUME_DOWN = P.VOLUME_DOWN

/**
 * @constant {string}
 */
AVVideo.C_MUTE = P.MUTE

/**
 * @constant {string}
 */
AVVideo.ACT_VOLUME_DOWN = P.VOLUME_DOWN

/**
 * @constant {string}
 */
AVVideo.ACT_VOLUME_UP = P.VOLUME_UP

Object.defineProperties(AVVideo.prototype, {

  /**
   * @name AVVideo#roomUuid
   * @type {string}
   * @readonly
   */
  roomUuid: {
    get: function () {
      return this._roomUuid
    }
  },

  /**
   * @name AVVideo#type
   * @type {string}
   * @readonly
   */
  type: {
    get: function () {
      return this._type
    }
  },

  /**
   * @name AVVideo#reachable
   * @type {boolean}
   * @readonly
   */
  reachable: {
    get: function () {
      return this._reachable
    }
  },

  /**
   * @name AVVideo#compatibleSources
   * @type {string[]}
   * @readonly
   */
  compatibleSources: {
    get: function () {

      var _source

      _source = this._attributes[P.SOURCE]

      return BasUtil.isObject(_source)
        ? Array.isArray(_source[P.VALUES])
          ? _source[P.VALUES]
          : []
        : []
    }
  },

  /**
   * @name AVVideo#source
   * @type {string}
   * @readonly
   */
  source: {
    get: function () {
      return BasUtil.isNEString(this._state[P.SOURCE])
        ? this._state[P.SOURCE]
        : ''
    }
  },

  /**
   * @name AVVideo#isOn
   * @type {boolean}
   * @readonly
   */
  isOn: {
    get: function () {
      return BasUtil.isBool(this._state[P.ON])
        ? this._state[P.ON]
        : false
    }
  },

  /**
   * @name AVVideo#mute
   * @type {boolean}
   * @readonly
   */
  mute: {
    get: function () {
      return BasUtil.isBool(this._state[P.MUTE])
        ? this._state[P.MUTE]
        : false
    }
  },

  /**
   * @name AVVideo#volume
   * @type {number}
   * @readonly
   */
  volume: {
    get: function () {
      return BasUtil.isPNumber(this._state[P.VOLUME], true)
        ? this._state[P.VOLUME]
        : 0
    }
  },

  /**
   * @name AVVideo#holdableActions
   * @type {string[]}
   * @readonly
   */
  holdableActions: {
    get: function () {
      return BasUtil.isNEArray(this._attributes[P.HOLDABLE_ACTIONS])
        ? this._attributes[P.HOLDABLE_ACTIONS]
        : []
    }
  }
})

/**
 * Parse an av message
 *
 * @param {Object} msg
 * @param {TRoomAVOptions} [options]
 */
AVVideo.prototype.parse = function (msg, options) {

  var value, emit
  var _emitReachable, _emitCapabilities, _emitAttributes, _emitState

  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  _emitReachable = false
  _emitAttributes = false
  _emitState = false

  // Reachable

  value = msg[P.REACHABLE]

  if (BasUtil.isBool(value)) {

    if (this._reachable !== value) {

      this._reachable = value

      _emitReachable = true
    }
  }

  // Capabilities

  _emitCapabilities =
    this[CONSTANTS.CAPABILITIES].parse(msg[P.CAPABILITIES])

  // Attributes

  value = msg[P.ATTRIBUTES]

  if (BasUtil.isObject(value)) {

    if (!BasUtil.isEqualPartialObject(this._attributes, value)) {

      BasUtil.mergeObjectsDeep(this._attributes, value)

      _emitAttributes = true
    }
  }

  // State

  value = msg[P.STATE]

  if (BasUtil.isObject(value)) {

    if (!BasUtil.isEqualPartialObject(this._state, value)) {

      BasUtil.mergeObjectsDeep(this._state, value)

      _emitState = true
    }
  }

  if (emit) {

    if (_emitReachable) this.emit(AVVideo.EVT_REACHABLE_CHANGED)
    if (_emitCapabilities) this.emit(AVVideo.EVT_CAPABILITIES_CHANGED)
    if (_emitAttributes) this.emit(AVVideo.EVT_ATTRIBUTES_CHANGED)
    if (_emitState) this.emit(AVVideo.EVT_STATE_CHANGED)
  }
}

/**
 * Update a single or multiple properties
 *
 * @param {Object} newState
 * @returns {Promise}
 */
AVVideo.prototype.updateState = function (newState) {

  var msg

  if (this._basCore) {

    msg = this._getBasCoreMessage()
    msg[P.ROOM][P.AV][P.VIDEO][P.STATE] = newState

    return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleUpdateState)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @param {string} action
 * @param {*} [data]
 * @returns {Promise}
 */
AVVideo.prototype.action = function (
  action,
  data
) {
  var msg

  if (this._basCore) {

    msg = this._getBasCoreMessage()
    msg[P.ROOM][P.AV][P.VIDEO][P.ACTION] = action

    if (!BasUtil.isUndefined(data)) msg[P.ROOM][P.AV][P.VIDEO][P.DATA] = data

    return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(Device.handleResponse)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @private
 * @param {Object} response
 * @returns {(string|Promise)}
 */
AVVideo.prototype._onUpdateState = function (response) {

  var result

  result = CONSTANTS.ERR_RESULT

  if (
    response &&
    response[P.ROOM] &&
    BasUtil.isNEString(response[P.ROOM][P.RESULT])
  ) {

    result = response[P.ROOM][P.RESULT]

    if (result === P.OK) {

      return result

    } else if (result === P.UNEXPECTED_ERROR) {

      return Promise.reject(response[P.ROOM][P.REASON])
    }
  }

  return Promise.reject(result)
}

/**
 * Creates a template basCore message for AVVideo
 *
 * @protected
 * @returns {TDeviceMessage}
 */
AVVideo.prototype._getBasCoreMessage = function () {

  var msg

  msg = {}
  msg[P.ROOM] = {}
  msg[P.ROOM][P.UUID] = this._roomUuid
  msg[P.ROOM][P.AV] = {}
  msg[P.ROOM][P.AV][P.VIDEO] = {}

  return msg
}

/**
 * Turn on or off the video
 *
 * @param {boolean} on
 * @returns {Promise}
 */
AVVideo.prototype.setOn = function (on) {

  var state

  if (BasUtil.isBool(on)) {

    state = {}
    state[P.ON] = on
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Mute or unmute the video
 *
 * @param {boolean} mute
 * @returns {Promise}
 */
AVVideo.prototype.setMute = function (mute) {

  var state

  if (BasUtil.isBool(mute)) {

    state = {}
    state[P.MUTE] = mute
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Change the video volume
 *
 * @param {number} volume
 * @returns {Promise}
 */
AVVideo.prototype.setVolume = function (volume) {

  var state

  if (BasUtil.isPNumber(volume, true)) {

    state = {}
    state[P.VOLUME] = volume
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Increase video volume
 *
 * @param {boolean} active
 * @returns {Promise}
 */
AVVideo.prototype.volumeUp = function (active) {

  var data

  if (BasUtil.isBool(active)) {

    data = {}
    data[P.ACTIVE] = active
  }

  return this.action(P.VOLUME_UP, data)
}

/**
 * Decrease video volume
 *
 * @param {boolean} active
 * @returns {Promise}
 */
AVVideo.prototype.volumeDown = function (active) {

  var data

  if (BasUtil.isBool(active)) {

    data = {}
    data[P.ACTIVE] = active
  }

  return this.action(P.VOLUME_DOWN, data)
}

/**
 * Change the video volume
 *
 * @param {string} sourceUuid
 * @returns {Promise}
 */
AVVideo.prototype.setSource = function (sourceUuid) {

  var state

  if (BasUtil.isString(sourceUuid)) {

    state = {}
    state[P.SOURCE] = sourceUuid
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

module.exports = AVVideo
