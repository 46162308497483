'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('knxPresetsCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BasState',
    'Sources',
    knxPresetsCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BasState} BasState
 * @param {Sources} Sources
 */
function knxPresetsCtrl (
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_SOURCES,
  BAS_SOURCE,
  BasState,
  Sources
) {
  var knxPresets = this

  knxPresets.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BAS_SOURCES}
   */
  knxPresets.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {TBasStateObj}
   */
  knxPresets.basState = BasState.get()

  knxPresets.back = back
  knxPresets.selectPlayer = selectPlayer

  $scope.$on('$destroy', onDestroy)

  init()

  function init () {

    Sources.registerFor(BAS_SOURCE.COL_EVT_KNX_PRESETS)
  }

  function back () {

    var streamUuid

    if (knxPresets.basState.current.SETTINGS_MUSIC_KNX_PRESETS_PRESET) {

      streamUuid = _getStreamUuid()

      if (BasUtil.isNEString(streamUuid)) {

        BasState.go(
          STATES.SETTINGS_MUSIC_KNX_PRESETS_PRESETS,
          {
            stream: streamUuid
          }
        )

      } else {

        BasState.go(STATES.SETTINGS_MUSIC_KNX_PRESETS)
      }

    } else if (knxPresets.basState.current.SETTINGS_MUSIC_KNX_PRESETS_PRESETS) {

      BasState.go(STATES.SETTINGS_MUSIC_KNX_PRESETS)
    }
  }

  /**
   * @param {string} sourceId
   */
  function selectPlayer (sourceId) {

    var source = BAS_SOURCES.SOURCES.sources[sourceId]

    if (
      source &&
      (
        source.isAudioSource ||
        source.type === BAS_SOURCE.T_PLAYER
      ) &&
      source.knxPresets
    ) {

      BasState.go(
        STATES.SETTINGS_MUSIC_KNX_PRESETS_PRESETS,
        {
          stream: sourceId
        }
      )
    }
  }

  function _getStreamUuid () {

    var streamId

    streamId = $uiRouterGlobals.params.stream

    return BasUtil.isNEString(streamId) ? streamId : ''
  }

  function onDestroy () {

    Sources.unregisterFor(BAS_SOURCE.COL_EVT_KNX_PRESETS)
  }
}
