'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('LibraryService', [
    '$rootScope',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_SOURCE',
    'CurrentBasCore',
    'LibraryState',
    LibraryService
  ])

/**
 * Service for handling library requests
 *
 * @constructor
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {CurrentBasCore} CurrentBasCore
 * @param {LibraryState} LibraryState
 */
function LibraryService (
  $rootScope,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_SOURCE,
  CurrentBasCore,
  LibraryState
) {
  var library = new Library()

  var currentSongPage = 0
  var currentArtistPage = 0
  var currentAlbumPage = 0
  var currentAlbumSongsPage = {}
  var currentArtistSongsPage = {}
  var PAGE_SIZE = 25
  var instanceListeners = []
  var songsPromise, albumsPromise, artistsPromise

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  this.getSongs = getSongs
  this.getAlbums = getAlbums
  this.getArtists = getArtists
  this.getAlbumSongs = getAlbumSongs
  this.getArtistSongs = getArtistSongs
  this.getSearchSongs = getSearchSongs
  this.getSearchAlbums = getSearchAlbums
  this.getSearchArtists = getSearchArtists
  this.getSearchPlaylists = getSearchPlaylists
  this.scan = scan

  this.clear = clear

  init()

  function init () {

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _onMusicConfig
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_LIBRARY_CHANGED,
      clear
    )

    if (CurrentBasCore.hasCore()) {

      if (
        !CurrentBasCore.hasAVFullSupport() &&
        currentBasCoreState.core.core.musicConfigReceived
      ) {

        _onMusicConfig()
      }
    }
  }

  /**
   * @constructor
   */
  function Library () {

    /**
     * @instance
     * @type {Array}
     */
    this.songs = []
    /**
     * @instance
     * @type {Array}
     */
    this.albums = []
    /**
     * @instance
     * @type {Array}
     */
    this.artists = []
    /**
     * @instance
     * @type {Object}
     */
    this.albumSongs = {}
    /**
     * @instance
     * @type {Object}
     */
    this.artistSongs = {}
    /**
     * @instance
     * @type {Object}
     */
    this.searchSongs = {}
    /**
     * @instance
     * @type {Object}
     */
    this.searchAlbums = {}
    /**
     * @instance
     * @type {Object}
     */
    this.searchArtists = {}
    /**
     * @instance
     * @type {Object}
     */
    this.searchPlaylists = {}

    this.reset = function () {
      this.songs = []
      this.albums = []
      this.artists = []
      this.albumSongs = {}
      this.artistSongs = {}
      this.searchSongs = {}
      this.searchAlbums = {}
      this.searchArtists = {}
    }
  }

  // region Songs

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  function getSongs (params) {

    var begin, end

    if (params) {
      begin = params.offset
      end = params.offset + params.limit
    }

    return checkAmount()

    /**
     * @returns {Promise}
     */
    function checkAmount () {

      return end > library.songs.length
        ? retrieveSongs().then(checkAmount, returnSongs)
        : returnSongs()
    }

    /**
     * @returns {Promise}
     */
    function returnSongs () {

      return (begin === end)
        ? Promise.resolve(library.songs)
        : Promise.resolve(library.songs.slice(begin, end))
    }
  }

  /**
   * @returns {Promise}
   */
  function retrieveSongs () {

    var basSource = getBasSource()

    if (basSource) {

      if (!songsPromise) {

        if (basSource.isAudioSource) {

          if (
            CurrentBasCore.hasCore() &&
            CurrentBasCore.hasAVFullSupport() &&
            currentBasCoreState.core.core.musicLibrary
          ) {

            songsPromise = currentBasCoreState.core.core.musicLibrary
              .listTracks(currentSongPage * PAGE_SIZE, PAGE_SIZE)
              .then(parseListResult)
              .then(checkNotEmpty)
              .then(onResult, onError)

          } else {

            return Promise.reject('No library')
          }

        } else {

          songsPromise = basSource.source.database
            .songOverview(currentSongPage, '', PAGE_SIZE)
            .then(checkNotEmpty)
            .then(onResult, onError)
        }
      }

      return songsPromise

    } else {

      return Promise.reject('No source')
    }

    function onResult (result) {

      library.songs = library.songs.concat(result)

      currentSongPage++

      clearPromise()

      return result
    }

    function clearPromise () {

      songsPromise = null
    }

    function onError (err) {

      // Clear promise, but pass on error

      clearPromise()

      return Promise.reject(err)
    }
  }

  // endregion

  // region Albums

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  function getAlbums (params) {

    var begin, end

    if (params) {
      begin = params.offset
      end = params.offset + params.limit
    }

    return checkAmount()

    /**
     * @returns {Promise}
     */
    function checkAmount () {
      return (end > library.albums.length)
        ? retrieveAlbums().then(checkAmount, returnAlbums)
        : returnAlbums()
    }

    /**
     * @returns {Promise}
     */
    function returnAlbums () {

      return (begin === end)
        ? Promise.resolve(library.albums)
        : Promise.resolve(library.albums.slice(begin, end))
    }
  }

  /**
   * @returns {Promise}
   */
  function retrieveAlbums () {

    var basSource = getBasSource()

    if (basSource) {

      if (!albumsPromise) {

        if (basSource.isAudioSource) {

          if (
            CurrentBasCore.hasCore() &&
            CurrentBasCore.hasAVFullSupport() &&
            currentBasCoreState.core.core.musicLibrary
          ) {

            albumsPromise = currentBasCoreState.core.core.musicLibrary
              .listAlbums(
                currentAlbumPage * PAGE_SIZE,
                PAGE_SIZE
              )
              .then(parseListResult)
              .then(checkNotEmpty)
              .then(onResult, onError)

          } else {

            return Promise.reject('No library')
          }

        } else {

          albumsPromise = basSource.source.database
            .albumOverview()
            .then(onResultLegacy, clearPromise)
        }
      }

      return albumsPromise
    }

    return Promise.reject('No source')

    function onResultLegacy (result) {

      library.albums = result.sort(compareObjectsAlphabetically)

      clearPromise()

      return result
    }

    function onResult (result) {

      library.albums = library.albums.concat(result)

      currentAlbumPage++

      clearPromise()

      return result
    }

    function clearPromise () {

      albumsPromise = null
    }

    function onError (err) {

      clearPromise()

      return Promise.reject(err)
    }
  }

  // endregion

  // region Artists

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  function getArtists (params) {

    var begin, end

    begin = params.offset
    end = params.offset + params.limit

    return checkAmount()

    /**
     * @returns {Promise}
     */
    function checkAmount () {

      return (end > library.artists.length)
        ? retrieveArtists().then(checkAmount, returnArtists)
        : returnArtists()
    }

    /**
     * @returns {Promise}
     */
    function returnArtists () {
      return Promise.resolve(library.artists.slice(begin, end))
    }
  }

  /**
   * @returns {Promise}
   */
  function retrieveArtists () {

    var basSource = getBasSource()

    if (basSource) {

      if (!artistsPromise) {

        if (basSource.isAudioSource) {

          if (
            CurrentBasCore.hasCore() &&
            CurrentBasCore.hasAVFullSupport() &&
            currentBasCoreState.core.core.musicLibrary
          ) {

            artistsPromise = currentBasCoreState.core.core
              .musicLibrary.listArtists(
                currentArtistPage * PAGE_SIZE,
                PAGE_SIZE
              )
              .then(parseListResult)
              .then(checkNotEmpty)
              .then(onResult, onError)

          } else {

            return Promise.reject('No library')
          }

        } else {

          artistsPromise = basSource.source.database
            .artistOverview()
            .then(onResultLegacy, clearPromise)
        }
      }

      return artistsPromise
    }

    return Promise.reject('No source')

    function onResultLegacy (result) {

      library.artists = result.sort(compareStringAlphabetically)

      clearPromise()

      return result
    }

    function onResult (result) {

      library.artists = library.artists.concat(result)

      currentArtistPage++

      clearPromise()

      return result
    }

    function clearPromise () {

      artistsPromise = null
    }

    function onError (err) {

      clearPromise()

      return Promise.reject(err)
    }
  }

  // endregion

  // region Album songs

  /**
   * @param {string} album
   * @param {string} artist
   * @param {Object} [params]
   * @returns {Promise}
   */
  function getAlbumSongs (album, artist, params) {

    var begin, end

    if (params) {
      begin = params.offset
      end = params.offset + params.limit
    }

    if (!(BasUtil.isNEString(album) || BasUtil.isNEString(artist))) {
      return Promise.reject('Params are invalid' + album + ',' + artist)
    }

    if (!BasUtil.isObject(library.albumSongs[artist])) {
      library.albumSongs[artist] = {}
    }

    return checkAmount()

    /**
     * @returns {Promise}
     */
    function checkAmount () {

      return (
        !library.albumSongs[artist][album] ||
        end > library.albumSongs[artist][album].length
      )
        ? retrieveAlbumSongs(album, artist).then(checkAmount, returnAlbumSongs)
        : returnAlbumSongs()
    }

    /**
     * @returns {Promise}
     */
    function returnAlbumSongs () {

      return Promise.resolve(
        begin === end
          ? library.albumSongs[artist][album]
          : library.albumSongs[artist][album].slice(begin, end)
      )
    }
  }

  /**
   * @param {string} album
   * @param {string} artist
   * @returns {Promise}
   */
  function retrieveAlbumSongs (album, artist) {

    var basSource, currentPage

    basSource = getBasSource()

    if (basSource) {

      if (basSource.isAudioSource) {

        if (
          CurrentBasCore.hasCore() &&
          CurrentBasCore.hasAVFullSupport() &&
          currentBasCoreState.core.core.musicLibrary
        ) {

          currentPage = BasUtil.isNumber(currentAlbumSongsPage[album])
            ? currentAlbumSongsPage[album]
            : 0

          currentAlbumSongsPage[album] = currentPage + 1

          return currentBasCoreState.core.core.musicLibrary.getAlbumDetails(
            album,
            currentPage * PAGE_SIZE,
            PAGE_SIZE
          )
            .then(parseListResult)
            .then(checkNotEmpty)
            .then(onPaginatedResult)
        }

        return Promise.reject('No library')

      } else {

        return basSource.source.database
          .albumSongs(album, artist)
          .then(checkNotEmpty)
          .then(onResult)
      }
    }

    return Promise.reject('No source')

    function onResult (result) {

      library.albumSongs[artist][album] = result

      return result
    }

    function onPaginatedResult (result) {

      if (!library.albumSongs[artist][album]) {
        library.albumSongs[artist][album] = []
      }

      library.albumSongs[artist][album].concat(result)

      // Add list to existing albumSongs array
      Array.prototype.push.apply(library.albumSongs[artist][album], result)

      return result
    }
  }

  // endregion

  // region Artist songs

  /**
   * @param {string} artist
   * @param {Object} [params]
   * @returns {Promise}
   */
  function getArtistSongs (artist, params) {

    var begin, end

    if (params) {
      begin = params.offset
      end = params.offset + params.limit
    }

    if (!BasUtil.isNEString(artist)) {
      return Promise.reject('Artist is invalid' + artist)
    }

    return checkAmount()

    /**
     * @returns {Promise}
     */
    function checkAmount () {

      return (
        !library.artistSongs[artist] ||
        end > library.artistSongs[artist].length
      )
        ? retrieveArtistSongs(artist).then(checkAmount, returnArtistSongs)
        : returnArtistSongs()
    }

    /**
     * @returns {Promise}
     */
    function returnArtistSongs () {

      return Promise.resolve(
        (begin === end)
          ? library.artistSongs[artist]
          : library.artistSongs[artist].slice(begin, end)
      )
    }
  }

  /**
   * @param {string} artist
   * @returns {Promise}
   */
  function retrieveArtistSongs (artist) {

    var basSource, currentPage

    basSource = getBasSource()

    if (basSource) {

      if (basSource.isAudioSource) {

        if (
          CurrentBasCore.hasCore() &&
          CurrentBasCore.hasAVFullSupport() &&
          currentBasCoreState.core.core.musicLibrary
        ) {

          currentPage = BasUtil.isNumber(currentArtistSongsPage[artist])
            ? currentArtistSongsPage[artist]
            : 0

          currentArtistSongsPage[artist] = currentPage + 1

          return currentBasCoreState.core.core.musicLibrary.getArtistDetails(
            artist,
            currentPage * PAGE_SIZE,
            PAGE_SIZE
          )
            .then(parseListResult)
            .then(checkNotEmpty)
            .then(onPaginatedResult)
        }

        return Promise.reject('No library')

      } else {

        return basSource.source.database
          .artistSongs(artist)
          .then(checkNotEmpty)
          .then(onResult)
      }
    }

    return Promise.reject('No source')

    function onResult (result) {

      library.artistSongs[artist] = result

      return result
    }

    function onPaginatedResult (result) {

      if (!library.artistSongs[artist]) library.artistSongs[artist] = []

      // Add list to existing artistSongs array
      Array.prototype.push.apply(library.artistSongs[artist], result)

      return result
    }
  }

  // endregion

  // region Search songs

  /**
   * @param {string} query
   * @param {Object} [params]
   * @returns {Promise}
   */
  function getSearchSongs (query, params) {

    var currentPage, begin, end, length

    currentPage = 0

    if (params) {
      begin = params.offset
      end = params.offset + params.limit
    }

    if (!BasUtil.isNEString(query)) {
      return Promise.reject('getSearchSongs - Params don\'t have a query param')
    }

    if (!BasUtil.isNEArray(library.searchSongs[query])) {
      library.searchSongs[query] = []
      return retrieveSearchSongs(0, query)
        .then(checkAmount, returnSearchSongs)
    }

    length = library.searchSongs[query].length
    currentPage = Math.ceil(length / PAGE_SIZE)

    return (end > length)
      ? retrieveSearchSongs(currentPage, query)
        .then(checkAmount, returnSearchSongs)
      : returnSearchSongs()

    /**
     * @param result
     * @returns {Promise}
     */
    function checkAmount (result) {
      if (
        end > library.searchSongs[query].length &&
        result.length === PAGE_SIZE
      ) {
        currentPage++
        return retrieveSearchSongs(currentPage, query)
          .then(checkAmount, returnSearchSongs)
      }

      return returnSearchSongs()
    }

    /**
     * @returns {Promise}
     */
    function returnSearchSongs () {
      return Promise.resolve(
        library.searchSongs[query].slice(begin, end)
      )
    }
  }

  /**
   * @param {number} currentPage
   * @param {string} query
   * @returns {Promise}
   */
  function retrieveSearchSongs (currentPage, query) {

    var basSource = getBasSource()

    if (basSource) {

      if (basSource.isAudioSource) {

        if (
          CurrentBasCore.hasCore() &&
          CurrentBasCore.hasAVFullSupport() &&
          currentBasCoreState.core.core.musicLibrary
        ) {

          return currentBasCoreState.core.core.musicLibrary.searchTracks(
            query,
            currentPage * PAGE_SIZE,
            PAGE_SIZE
          )
            .then(parseListResult)
            .then(checkNotEmpty)
            .then(onResult)
        }

        return Promise.reject('No library')

      } else {

        return basSource.source.database
          .songOverview(currentPage, query, PAGE_SIZE)
          .then(checkNotEmpty)
          .then(onResult)
      }
    }

    return Promise.reject('No source')

    function onResult (result) {

      library.searchSongs[query] =
        library.searchSongs[query].concat(result)

      return result
    }
  }

  // endregion

  // region Search albums

  /**
   * @param {string} query
   * @param {Object} [params]
   * @returns {Promise}
   */
  function getSearchAlbums (query, params) {

    var currentPage, begin, end, length

    currentPage = 0

    if (params) {
      begin = params.offset
      end = params.offset + params.limit
    }

    if (!BasUtil.isNEString(query)) {
      return Promise.reject(
        'getSearchAlbums - Params don\'t have a query param'
      )
    }

    if (!BasUtil.isNEArray(library.searchAlbums[query])) {
      library.searchAlbums[query] = []
      return retrieveSearchAlbums(0, query)
        .then(checkAmount, returnSearchAlbums)
    }

    length = library.searchAlbums[query].length
    currentPage = Math.ceil(length / PAGE_SIZE)

    return (end > length)
      ? retrieveSearchAlbums(currentPage, query)
        .then(checkAmount, returnSearchAlbums)
      : returnSearchAlbums()

    /**
     * @param result
     * @returns {Promise}
     */
    function checkAmount (result) {

      if (
        end > library.searchAlbums[query].length &&
        result.length === PAGE_SIZE
      ) {
        currentPage++
        return retrieveSearchAlbums(currentPage, query)
          .then(checkAmount, returnSearchAlbums)
      }

      return returnSearchAlbums()
    }

    /**
     * @returns {Promise}
     */
    function returnSearchAlbums () {
      return Promise.resolve(
        library.searchAlbums[query].slice(begin, end)
      )
    }
  }

  /**
   * @param {number} currentPage
   * @param {string} query
   * @returns {Promise}
   */
  function retrieveSearchAlbums (currentPage, query) {

    var basSource = getBasSource()

    if (basSource) {

      if (basSource.isAudioSource) {

        if (
          CurrentBasCore.hasCore() &&
          CurrentBasCore.hasAVFullSupport() &&
          currentBasCoreState.core.core.musicLibrary
        ) {

          return currentBasCoreState.core.core.musicLibrary.searchAlbums(
            query,
            currentPage * PAGE_SIZE,
            PAGE_SIZE
          )
            .then(parseListResult)
            .then(checkNotEmpty)
            .then(onResult)
        }

        return Promise.reject('No library')
      }

      return Promise.reject('Album search not available in legacy api')
    }

    return Promise.reject('No source')

    function onResult (result) {

      library.searchAlbums[query] =
        library.searchAlbums[query].concat(result)

      return result
    }
  }

  // endregion

  // region Search artists

  /**
   * @param {string} query
   * @param {Object} [params]
   * @returns {Promise}
   */
  function getSearchArtists (query, params) {

    var currentPage, begin, end, length

    currentPage = 0

    if (params) {
      begin = params.offset
      end = params.offset + params.limit
    }

    if (!BasUtil.isNEString(query)) {
      return Promise.reject(
        'getSearchArtists - Params don\'t have a query param'
      )
    }

    if (!BasUtil.isNEArray(library.searchArtists[query])) {
      library.searchArtists[query] = []
      return retrieveSearchArtists(0, query)
        .then(checkAmount, returnSearchArtists)
    }

    length = library.searchArtists[query].length
    currentPage = Math.ceil(length / PAGE_SIZE)

    return (end > length)
      ? retrieveSearchArtists(currentPage, query)
        .then(checkAmount, returnSearchArtists)
      : returnSearchArtists()

    /**
     * @param result
     * @returns {Promise}
     */
    function checkAmount (result) {
      if (
        end > library.searchArtists[query].length &&
        result.length === PAGE_SIZE
      ) {
        currentPage++
        return retrieveSearchArtists(currentPage, query)
          .then(checkAmount, returnSearchArtists)
      }

      return returnSearchArtists()
    }

    /**
     * @returns {Promise}
     */
    function returnSearchArtists () {
      return Promise.resolve(
        library.searchArtists[query].slice(begin, end)
      )
    }
  }

  /**
   * @param {number} currentPage
   * @param {string} query
   * @returns {Promise}
   */
  function retrieveSearchArtists (currentPage, query) {

    var basSource = getBasSource()

    if (basSource) {

      if (basSource.isAudioSource) {

        if (
          CurrentBasCore.hasCore() &&
          CurrentBasCore.hasAVFullSupport() &&
          currentBasCoreState.core.core.musicLibrary
        ) {

          return currentBasCoreState.core.core.musicLibrary.searchArtists(
            query,
            currentPage * PAGE_SIZE,
            PAGE_SIZE
          )
            .then(parseListResult)
            .then(checkNotEmpty)
            .then(onResult)
        }

        return Promise.reject('No library')
      }

      return Promise.reject('Artist search not available in legacy api')
    }

    return Promise.reject('No source')

    function onResult (result) {

      library.searchArtists[query] =
        library.searchArtists[query].concat(result)

      return result
    }
  }

  // endregion

  // region Search playlists

  /**
   * @param {string} query
   * @param {Object} [params]
   * @returns {Promise}
   */
  function getSearchPlaylists (query, params) {

    var currentPage, begin, end, length

    currentPage = 0

    if (params) {
      begin = params.offset
      end = params.offset + params.limit
    }

    if (!BasUtil.isNEString(query)) {
      return Promise.reject(
        'getSearchPlaylists - Params don\'t have a query param'
      )
    }

    if (!BasUtil.isNEArray(library.searchPlaylists[query])) {
      library.searchPlaylists[query] = []
      return retrieveSearchPlaylists(0, query)
        .then(checkAmount, returnSearchPlaylists)
    }

    length = library.searchPlaylists[query].length
    currentPage = Math.ceil(length / PAGE_SIZE)

    return (end > length)
      ? retrieveSearchPlaylists(currentPage, query)
        .then(checkAmount, returnSearchPlaylists)
      : returnSearchPlaylists()

    /**
     * @param result
     * @returns {Promise}
     */
    function checkAmount (result) {

      if (
        end > library.searchPlaylists[query].length &&
        result.length === PAGE_SIZE
      ) {
        currentPage++
        return retrieveSearchPlaylists(currentPage, query)
          .then(checkAmount, returnSearchPlaylists)
      }

      return returnSearchPlaylists()
    }

    /**
     * @returns {Promise}
     */
    function returnSearchPlaylists () {
      return Promise.resolve(
        library.searchPlaylists[query].slice(begin, end)
      )
    }
  }

  /**
   * @param {number} currentPage
   * @param {string} query
   * @returns {Promise}
   */
  function retrieveSearchPlaylists (currentPage, query) {

    var basSource = getBasSource()

    if (basSource) {

      if (basSource.isAudioSource && basSource.source) {

        basSource.source.searchPlaylists(
          query,
          currentPage * PAGE_SIZE,
          PAGE_SIZE
        )
          .then(parseListResult)
          .then(checkNotEmpty)
          .then(onResult)
      }

      return Promise.reject('Source is not an audioSource')
    }

    return Promise.reject('No source')

    function onResult (result) {

      library.searchPlaylists[query] =
        library.searchPlaylists[query].concat(result)

      return result
    }
  }

  /**
   * @returns {Promise}
   */
  function scan () {

    var players, player, keys, i, length

    if (CurrentBasCore.hasCore()) {

      if (
        CurrentBasCore.hasAVFullSupport() &&
        currentBasCoreState.core.core.musicLibrary
      ) {

        return currentBasCoreState.core.core.musicLibrary.scan()

      } else {

        players = currentBasCoreState.core.core.players

        keys = Object.keys(players)
        length = keys.length
        for (i = 0; i < length; i++) {

          player = players[keys[i]]

          if (
            BasUtil.isObject(player) &&
            BasUtil.isObject(player.database) &&
            !player.database.isUpdating
          ) {

            player.database.update()
          }
        }

        return Promise.resolve()
      }
    }

    return Promise.resolve()
  }

  // endregion

  function _onMusicConfig () {

    var players, keys

    clearListeners()

    if (CurrentBasCore.hasCore() && !CurrentBasCore.hasAVFullSupport()) {

      players = currentBasCoreState.core.core.players
      keys = Object.keys(players)

      // Only need these listeners for one player
      // since they all have the same database
      if (keys.length > 0) {

        setPlayerListener(players[keys[0]])
      }
    }
  }

  /**
   * @param {Player} player
   */
  function setPlayerListener (player) {

    if (player && player.database) {

      instanceListeners.push(BasUtil.setEventListener(
        player.database,
        BAS_API.Database.EVT_DATABASE_CHANGED,
        clear
      ))

      instanceListeners.push(BasUtil.setEventListener(
        player.database,
        BAS_API.Database.EVT_DATABASE_UPDATING,
        clear
      ))
    }
  }

  // region Helper functions

  function clearListeners () {
    BasUtil.executeArray(instanceListeners)
    instanceListeners = []
  }

  /**
   * @returns {?BasSource}
   */
  function getBasSource () {

    var basSource

    /**
     * @type {?BasSource}
     */
    basSource = LibraryState.getCurrentSource()

    return (
      basSource &&
      (
        basSource.isAudioSource ||
        (
          basSource.type === BAS_SOURCE.T_PLAYER &&
          basSource.source &&
          basSource.source.database
        )
      )
    )
      ? basSource
      : null
  }

  function parseListResult (msg) {

    return (msg && Array.isArray(msg.list))
      ? msg.list
      : Promise.reject('No list')
  }

  function checkNotEmpty (list) {

    return list.length === 0
      ? Promise.reject('No new content')
      : list
  }

  /**
   * @param {string} a
   * @param {string} b
   * @returns {number}
   */
  function compareStringAlphabetically (a, b) {

    if (!BasUtil.isNEString(a)) {

      if (!BasUtil.isNEString(b)) return 0

      return 1
    }

    if (!BasUtil.isNEString(b)) return -1

    return a.localeCompare(b)
  }

  /**
   * @param {Object} a
   * @param {string} a.name
   * @param {Object} b
   * @param {string} b.name
   * @returns {number}
   */
  function compareObjectsAlphabetically (a, b) {

    if (!(a && BasUtil.isNEString(a.name))) {

      if (!(b && BasUtil.isNEString(b.name))) {
        return 0
      }

      return 1
    }

    if (!(b && BasUtil.isNEString(b.name))) {
      return -1
    }

    return a.name.localeCompare(b.name)
  }

  function clear () {
    library.reset()
    currentSongPage = 0
    currentArtistPage = 0
    currentAlbumPage = 0
    currentAlbumSongsPage = {}
    currentArtistSongsPage = {}
  }

  // endregion
}
