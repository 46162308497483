'use strict'

angular
  .module('basalteApp')
  .constant('VIDEO_BUTTON', {
    BACK: 'back',
    CHANNEL_DOWN: 'channelDown',
    CHANNEL_UP: 'channelUp',
    VOLUME_DOWN: 'volumeDown',
    VOLUME_UP: 'volumeUp',
    DOWN: 'down',
    UP: 'up',
    LEFT: 'left',
    RIGHT: 'right',
    FAST_FORWARD: 'fastForward',
    REWIND: 'rewind',
    MENU: 'menu',
    ENTER: 'enter',
    PLAY_PAUSE: 'playPause',
    SKIP_PREVIOUS: 'skipPrevious',
    SKIP_NEXT: 'skipNext'
  })
