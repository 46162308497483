'use strict'

var BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .directive('deviceCloudEnvironment', [
    'BAS_HTML',
    deviceCloudEnvironment
  ])

function deviceCloudEnvironment (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.cloudEnvironmentSettings,
    controller: [
      '$rootScope',
      'BAS_PREFERENCES',
      'BAS_INPUT_MODAL',
      'BasPreferences',
      'BasInputModal',
      controller
    ],
    controllerAs: 'cloudEnvironment'
  }

  /**
   * @param $rootScope
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {BasPreferences} BasPreferences
   * @param {BasInputModal} BasInputModal
   */
  function controller (
    $rootScope,
    BAS_PREFERENCES,
    BAS_INPUT_MODAL,
    BasPreferences,
    BasInputModal
  ) {
    const cloudEnvironment = this

    const _listeners = []

    /**
     * @type {TBasModalInputSelectOption[]}
     */
    cloudEnvironment.VIEW_OPTIONS = [
      {
        value: BAS_PREFERENCES.CLOUD_ENVIRONMENT_AUTO,
        text: 'Auto',
        translate: false
      },
      {
        value: BAS_PREFERENCES.CLOUD_ENVIRONMENT_DEV,
        text: 'Dev',
        translate: false
      },
      {
        value: BAS_PREFERENCES.CLOUD_ENVIRONMENT_PROD,
        text: 'Prod',
        translate: false
      }

    ]

    /**
     * @type {number}
     */
    cloudEnvironment.cloudEnvironment = BasPreferences.getCloudEnvironment()

    /**
     * @type {string}
     */
    cloudEnvironment.uiCloudEnvironment = ''

    cloudEnvironment.showEditCloudEnvironment = showEditCloudEnvironment

    cloudEnvironment.$onDestroy = _onDestroy

    init()

    function init () {

      _listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        _onLanguageChanged
      ))

      _syncUiCloudEnvironment()
    }

    function showEditCloudEnvironment () {

      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'cloud_environment',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            options: cloudEnvironment.VIEW_OPTIONS,
            initialValue: cloudEnvironment.cloudEnvironment
          }
        ],
        autoAdvance: true,
        allowDismiss: true,
        autoSave: true,
        validCheck: BasUtil.isNEArray
      }).then((modal) => {

        modal.close.then((result) => {

          if (BasUtil.isNEArray(result)) {

            cloudEnvironment.cloudEnvironment = result[0]
            BasPreferences.setCloudEnvironment(
              cloudEnvironment.cloudEnvironment
            )
            _syncUiCloudEnvironment()
          }
        })
      })
    }

    function _syncUiCloudEnvironment () {

      switch (cloudEnvironment.cloudEnvironment) {
        case BAS_PREFERENCES.CLOUD_ENVIRONMENT_AUTO:
          cloudEnvironment.uiCloudEnvironment = 'Auto'
          break
        case BAS_PREFERENCES.CLOUD_ENVIRONMENT_PROD:
          cloudEnvironment.uiCloudEnvironment = 'Prod'
          break
        case BAS_PREFERENCES.CLOUD_ENVIRONMENT_DEV:
          cloudEnvironment.uiCloudEnvironment = 'Dev'
          break
      }
    }

    function _onLanguageChanged () {

      _syncUiCloudEnvironment()
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners.length = 0
    }
  }
}
