'use strict'

angular
  .module('basalteApp')
  .constant('LANGUAGES', [
    {
      code: 'en_GB',
      codeL: 'en_gb',
      langCode: 'en',
      name: 'English (United Kingdom)',
      aliases: ['en', 'en_*']
    },
    {
      code: 'de_DE',
      codeL: 'de_de',
      langCode: 'de',
      name: 'Deutsch',
      aliases: ['de', 'de_*']
    },
    {
      code: 'nl_NL',
      codeL: 'nl_nl',
      langCode: 'nl',
      name: 'Nederlands',
      aliases: ['nl', 'nl_*']
    },
    {
      code: 'fr_FR',
      codeL: 'fr_fr',
      langCode: 'fr',
      name: 'Français',
      aliases: ['fr', 'fr_*']
    },
    {
      code: 'es_ES',
      codeL: 'es_es',
      langCode: 'es',
      name: 'Español',
      aliases: ['es', 'es_*']
    },
    {
      code: 'it_IT',
      codeL: 'it_it',
      langCode: 'it',
      name: 'Italiano',
      aliases: ['it', 'it_*']
    },
    {
      code: 'ru_RU',
      codeL: 'ru_ru',
      langCode: 'ru',
      name: 'Pусский',
      aliases: ['ru', 'ru_*']
    },
    {
      code: 'pl_PL',
      codeL: 'pl_pl',
      langCode: 'pl',
      name: 'Polski',
      aliases: ['pl', 'pl_*']
    },
    {
      code: 'lt_LT',
      codeL: 'lt_lt',
      langCode: 'lt',
      name: 'Lietuvių',
      aliases: ['lt', 'lt_*']
    },
    {
      code: 'zh_CN',
      codeL: 'zh_cn',
      langCode: 'zh',
      name: '简体中文',
      aliases: ['zh', 'zh_*']
    },
    {
      code: 'ja_JP',
      codeL: 'ja_jp',
      langCode: 'ja',
      name: '日本語',
      aliases: ['ja', 'ja_*']
    },
    {
      code: 'uk_UA',
      codeL: 'uk_ua',
      langCode: 'uk',
      name: 'українська',
      aliases: ['uk', 'uk_*']
    }
  ])
