'use strict'

angular
  .module('basalteApp')
  .service('BasUiRemote', [
    BasUiRemote
  ])

/**
 * @typedef {Object} TBasUiRemote
 * @property {Object<string, boolean>} css
 */

/**
 * @constructor
 */
function BasUiRemote () {

  var CSS_SHOW_GRIDS = 'bvr-show-grids'

  /**
   * @type {TBasUiQueue}
   */
  var state = {}
  state.css = {}
  state.css[CSS_SHOW_GRIDS] = false

  this.get = get
  this.toggle = toggle

  /**
   * @returns {TBasUiQueue}
   */
  function get () {
    return state
  }

  /**
   * @param {boolean} [force]
   */
  function toggle (force) {

    state.css[CSS_SHOW_GRIDS] = typeof force === 'boolean'
      ? force
      : !state.css[CSS_SHOW_GRIDS]
  }
}
