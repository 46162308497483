'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSourceHelper', [
    'BAS_SOURCE',
    BasSourceHelper
  ])

/**
 * @constructor
 * @param {BAS_SOURCE} BAS_SOURCE
 */
function BasSourceHelper (
  BAS_SOURCE
) {
  this.checkSpotifyBarp = checkSpotifyBarp

  init()

  function init () {

    var keys, length, i

    keys = Object.keys(BAS_SOURCE)
    length = keys.length
    for (i = 0; i < length; i++) {

      if (keys[i].indexOf('COL_EVT_') === 0) {

        BAS_SOURCE.EVT_COLLECTIONS.push(BAS_SOURCE[keys[i]])
      }
    }
  }

  /**
   * @param {BasSource} basSource
   * @returns {boolean}
   */
  function checkSpotifyBarp (basSource) {
    return (
      BasUtil.isObject(basSource) &&
      basSource.type === BAS_SOURCE.T_BARP &&
      basSource.subType === BAS_SOURCE.ST_SPOTIFY
    )
  }
}
