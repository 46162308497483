'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('LocalLibraryPage', [
    '$rootScope',
    'BasLibraryPage',
    'BasLibraryTab',
    'LocalCollection',
    'LocalPlaylistCollection',
    'LibraryState',
    'BasUtilities',
    'Logger',
    LocalLibraryPageFactory
  ])

/**
 * @typedef {Object} TLocalCollectionData
 * @property {string} name
 * @property {string} titleName
 * @property {string} [inlineTitle]
 * @property {boolean} [hasTab] default true
 * @property {boolean} [canGridView] default true
 * @property {string} contentType
 * @property {string} [type]
 */

/**
 * @param $rootScope
 * @param BasLibraryPage
 * @param BasLibraryTab
 * @param LocalCollection
 * @param LocalPlaylistCollection
 * @param {LibraryState} LibraryState
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns LocalLibraryPage
 */
function LocalLibraryPageFactory (
  $rootScope,
  BasLibraryPage,
  BasLibraryTab,
  LocalCollection,
  LocalPlaylistCollection,
  LibraryState,
  BasUtilities,
  Logger
) {
  var className = 'LocalLibraryPage'
  Logger.debug(className)

  /**
   * Object that contains all info to create a library page
   *
   * @constructor
   * @extends BasLibraryPage
   */
  function LocalLibraryPage () {

    BasLibraryPage.call(this)

    /**
     * @instance
     * @type {string}
     */
    this.content = LocalLibraryPage.CONTENT_GENERIC
  }

  // Set new prototype from inherited object
  LocalLibraryPage.prototype = Object.create(BasLibraryPage.prototype)

  // Set correct constructor after setting new prototype
  LocalLibraryPage.prototype.constructor = LocalLibraryPage

  /**
   * @constant {string}
   */
  LocalLibraryPage.CONTENT_SCANNING = 'scanning'

  /**
   * @constant {string}
   */
  LocalLibraryPage.CONTENT_GENERIC = 'generic'

  /**
   * @param {TLocalCollectionData} data
   * @param {boolean} [playlistCollection]
   * @returns {LocalCollection}
   */
  LocalLibraryPage.prototype.fillCollection = function (
    data,
    playlistCollection
  ) {
    var collection
    // Make collection
    collection = playlistCollection === true
      ? new LocalPlaylistCollection()
      : new LocalCollection()

    // Set properties
    collection.setName(data.name, data.titleName)
    collection.setHasTab(data.hasTab)
    collection.setCanGridView(data.canGridView)
    collection.setContentType(data.contentType)
    collection.setTitle(data.inlineTitle)
    collection.type = data.type
    collection.id = data.query

    // Set detail
    if (BasUtil.isObject(this.collections[0].detail)) {
      collection.detail = this.collections[0].detail
    }

    // Set detailElement
    if (BasUtil.isObject(this.collections[0].detailElement)) {
      collection.detailElement = this.collections[0].detailElement
    }

    return collection
  }

  /**
   * @param {TLocalCollectionData} data
   */
  LocalLibraryPage.prototype.makeCollection = function (data) {
    var collection

    // Check defaults
    if (data.hasTab !== false) {
      data.hasTab = true
    }

    if (data.canGridView !== false) {
      data.canGridView = true
    }

    // Make collection
    collection = this.fillCollection(data)

    // Push collection
    this.collectionsData.push(collection)
  }

  /**
   * @param {TLocalCollectionData} data
   */
  LocalLibraryPage.prototype.makeArrayCollection = function (data) {
    var collectionArray

    // Check defaults
    if (data.hasTab !== false) {
      data.hasTab = true
    }

    if (data.canGridView !== false) {
      data.canGridView = true
    }

    // Make collection array
    collectionArray = []

    // Make collections
    data.inlineTitle = BasUtilities.translate('playlists_local')
    data.type = LocalPlaylistCollection.TYPE_LOCAL
    collectionArray.push(this.fillCollection(data, true))

    data.inlineTitle = BasUtilities.translate('playlists_shared')
    data.type = LocalPlaylistCollection.TYPE_SHARED
    collectionArray.push(this.fillCollection(data, true))

    data.inlineTitle = BasUtilities.translate('playlists_itunes')
    data.type = LocalPlaylistCollection.TYPE_ITUNES
    collectionArray.push(this.fillCollection(data, true))

    // Push collection array
    this.collectionsData.push(collectionArray)
  }

  LocalLibraryPage.prototype.makeTabs = function () {
    var length, i

    this.tabs = []
    this.hasTabs = false

    length = this.collectionsData.length
    for (i = 0; i < length; i++) {
      if (this.collectionsData[i].hasTab) {
        this.tabs.push(new BasLibraryTab(this.collectionsData[i]))
        this.hasTabs = true
      } else if (Array.isArray(this.collectionsData[i]) &&
        BasUtil.isObject(this.collectionsData[i][0]) &&
        this.collectionsData[i][0].hasTab) {

        this.tabs.push(new BasLibraryTab(this.collectionsData[i]))
        this.hasTabs = true
      }
    }

    this.setCSSClass(
      BasLibraryPage.CLASS_HAS_TABS,
      this.hasTabs
    )
  }

  /**
   * @returns {Promise}
   */
  LocalLibraryPage.prototype.retrieve = function () {

    var basSource

    basSource = LibraryState.getCurrentSource()

    // Check if source and database is valid

    if (
      basSource &&
      basSource.source && (
        basSource.source.database ||
        basSource.isAudioSource
      )) {

      // Check for tabs
      if (this.hasTabs) {

        return this.selectTab(this.tabs[0])

      } else {

        return BasLibraryPage.prototype.retrieve.call(this)
      }

    } else {

      Logger.warn(
        className + ' - Retrieve - Invalid source and/or Database',
        basSource
      )

      return Promise.reject('Invalid source and/or Database')
    }
  }

  /**
   * @param {string} content
   */
  LocalLibraryPage.prototype.setContent = function (content) {

    // Check input
    if (BasUtil.isNEString(content)) {
      this.content = content

      // Check content
      switch (this.content) {
        case BasLibraryPage.CONTENT_NOT_CONNECTED:

          // Show message
          this.setMessage('no_content_available')
          // Add message CSS
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_OVERLAY,
            true
          )
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_MESSAGE,
            true
          )
          this.processClasses()

          break
        case LocalLibraryPage.CONTENT_SCANNING:

          // Show message
          this.setMessage('library_scanning')
          // Add message CSS
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_OVERLAY,
            true
          )
          this.setCSSClass(
            BasLibraryPage.CLASS_MESSAGE_HAS_SPINNER,
            true
          )
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_MESSAGE,
            true
          )
          this.processClasses()

          break
      }
    } else {
      this.content = LocalLibraryPage.CONTENT_GENERIC
    }
  }

  /**
   * @param {Object} tab
   * @returns {Promise}
   */
  LocalLibraryPage.prototype.selectTab = function (tab) {

    var _this, promises, selectTab, collections, collection, length, i

    if (tab.class[BasLibraryPage.CLASS_TAB_ACTIVE]) return Promise.resolve()

    _this = this

    collections = tab.collection
    collection = null
    promises = []
    selectTab = false

    length = collections.length

    if (Array.isArray(collections)) {

      for (i = 0; i < length; i++) {
        collection = collections[i]

        if (collection.elements.length === 0 &&
          !collection.hasReachedEnd) {

          selectTab = true

          // Return promise after elements are received
          promises.push(collection.retrieve())
        }

        // No need to add Promise.resolve in other case since Promise.all
        // auto-resolves when having an empty array.
      }
    } else {

      promises.push(collections.retrieve())
    }

    if (selectTab) {
      // Select tab
      tab.setSelectedTab(true)

      $rootScope.$applyAsync()
    }

    return Promise.all(promises).then(onSuccess, onFail)

    function onSuccess () {

      var _length, _i

      // Disable active tab
      _this.clearTabsClass()

      // Activate this tab
      tab.setActiveTab(true)
      $rootScope.$applyAsync()

      // Reset collections
      _this.collections = []

      if (Array.isArray(collections)) {

        _length = collections.length
        for (_i = 0; _i < _length; _i++) {
          collection = collections[_i]

          // Set collection
          _this.collections.push(collection)
        }
      } else {

        _this.collections.push(collections)
        collection = collections
      }

      // Set subtitle
      if (!_this.lockSubtitle) {
        _this.setSubtitle(
          BasUtilities.translate(collection.titleNameId)
        )
      }
    }

    function onFail () {

      // Disable active tab
      _this.clearTabsClass()

      // Set subtitle
      if (!_this.lockSubtitle) {
        _this.setSubtitle(
          BasUtilities.translate(tab.collection.titleNameId)
        )
      }
    }
  }

  return LocalLibraryPage
}
