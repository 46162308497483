'use strict'

import * as BasUtil from '@basalte/bas-util'

// While it would be nice that directive uses the bas-click directive to detect
//  click events, currently it just uses plain 'click' events to keep this
//  directive simple. Using bas-click would require adding that directive
//  dynamically and recompiling.

angular
  .module('basalteApp')
  .directive('basMultiClick', basMultiClickDirective)

function basMultiClickDirective () {

  return {
    restrict: 'A',
    controller: [
      '$scope',
      '$element',
      basClickController
    ],
    bindToController: {
      count: '<',
      delay: '<',
      basMultiClick: '&'
    },
    controllerAs: 'multiClick'
  }

  function basClickController (
    $scope,
    $element
  ) {
    var multiClick = this

    var DEFAULT_COUNT = 5
    var DEFAULT_DELAY = 1000

    // Configurable properties
    var count = DEFAULT_COUNT
    var delay = DEFAULT_DELAY

    var counter = 0

    var clickListener = null
    var resetCounterTimeoutId = -1

    multiClick.$postLink = _onPostLink
    multiClick.$onDestroy = _onDestroy
    multiClick.$onChanges = _onChanges

    function _onPostLink () {

      init()
    }

    function _onChanges () {

      syncConfigParameters()
    }

    function init () {

      syncConfigParameters()

      clickListener = BasUtil.setDOMListener(
        $element[0],
        'click',
        handleClick
      )
    }

    function handleClick (event) {

      event.preventDefault()
      event.stopPropagation()

      if (++counter >= count) {

        clearResetCounterTimeout()
        resetCounter()

        $scope.$apply(executeMultiClick)

      } else {

        setResetCounterTimeout()
      }

      function executeMultiClick () {

        BasUtil.exec(multiClick.basMultiClick)
      }
    }

    function resetCounter () {

      counter = 0
    }

    function setResetCounterTimeout () {

      clearResetCounterTimeout()
      resetCounterTimeoutId = setTimeout(
        resetCounter,
        delay
      )
    }

    function clearResetCounterTimeout () {

      clearTimeout(resetCounterTimeoutId)
      resetCounterTimeoutId = -1
    }

    function syncConfigParameters () {

      count = BasUtil.isInteger(multiClick.count) && multiClick.count > 2
        ? multiClick.count
        : DEFAULT_COUNT

      delay = BasUtil.isPNumber(multiClick.delay, false)
        ? multiClick.delay
        : DEFAULT_DELAY
    }

    function _onDestroy () {

      clearResetCounterTimeout()
      BasUtil.exec(clickListener)
    }
  }
}
