'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasDeviceNetwork', [
    'BAS_API',
    'BasSmartConnect',
    'BasConnectInfo',
    'BasServerStorage',
    'BasCoreContainer',
    BasDeviceNetwork
  ])

/**
 * @constructor
 * @param BAS_API
 * @param {BasSmartConnect} BasSmartConnect
 * @param BasConnectInfo
 * @param {BasServerStorage} BasServerStorage
 * @param BasCoreContainer
 */
function BasDeviceNetwork (
  BAS_API,
  BasSmartConnect,
  BasConnectInfo,
  BasServerStorage,
  BasCoreContainer
) {
  var DEVICE_TIMEOUT = 3000

  this.updateAddresses = updateAddresses
  this.sendAddressesToCore = sendAddressesToCore

  // TODO: Use a more generic BasDevice for Lisa compatibility

  /**
   * Updated the addresses of a device
   *
   * @private
   * @param {BasCoreClientDevice} device
   * @param {string[]} addressesArray
   */
  function updateAddresses (device, addressesArray) {

    if (device && Array.isArray(addressesArray)) {

      // Setting addresses of device will auto update the connected core
      device.setAddresses(addressesArray)
    }
  }

  /**
   * Sends the current addresses of an ellie/lisa device to server with
   * specified serverAddress.
   * Only use this if no regular connection with the server is set up.
   *
   * @private
   * @param {string} serverAddress
   * @param {number} mac
   * @param {string[]} addressesArray
   */
  function sendAddressesToCore (
    serverAddress,
    mac,
    addressesArray
  ) {
    var _basServer, _basCore, _deviceUuid, _dedicatedCoreContainer

    initConnection()

    function initConnection () {
      var _server, _connectInfo

      if (BasUtil.isPNumber(mac)) {

        _server = BasUtil.isNEString(serverAddress)
          ? BasServerStorage.getServerByAddress(serverAddress)
          : BasServerStorage.getLastServer()

        if (_server) {

          _connectInfo = BasConnectInfo.build(_server)

          if (_connectInfo) {

            _connectToServer(_connectInfo)
              .then(_connectToCore)
              .then(_validateCoreConnected)
              .then(_getDevice)
              .then(_updateDeviceAddresses)
              .then(_endDedicatedConnection)
              .catch(_onError)
          }
        }
      }
    }

    /**
     * Connects to a server with given BasConnectInfo.
     * If the connection succeeds and the server contains 'deviceInfo', the
     * device uuid is saved for later.
     *
     * @private
     * @param {BasConnectInfo} connectInfo
     * @returns {Promise<BasServer>}
     */
    function _connectToServer (connectInfo) {

      if (connectInfo) {

        return new Promise(_connectToServerPromiseConstructor)
      }

      return Promise.reject('ConnectInfo is undefined')

      function _connectToServerPromiseConstructor (resolve, reject) {

        var _basSmartConnect, _basSmartConnectTarget

        _basSmartConnectTarget = {
          preferred: connectInfo,
          preferredProjectId: connectInfo.server.cid,
          preferredMac: connectInfo.server.macAddress,
          preferredAddress: connectInfo.server.address,
          allowOther: false
        }

        _basSmartConnect = BasSmartConnect.connect(
          _basSmartConnectTarget,
          null,
          basSmartConnectCallback
        )

        /**
         * @param {?BasError} error
         * @param {TBasSmartConnect} result
         */
        function basSmartConnectCallback (
          error,
          result
        ) {
          if (!_basSmartConnect) {

            reject(
              'BasSmartConnect callback called before ' +
              'initialization'
            )
          }

          if (error) {

            // Server is not reachable

            reject('Server is not reachable')

          } else if (result.basServer) {

            // Server connection succeeded

            if (result.basServer.coreClientDeviceInfo) {

              _deviceUuid = result.basServer.coreClientDeviceInfo[mac]
            }

            resolve(result.basServer)

          } else {

            reject(
              'No error was present but BasSmartConnect did' +
              'not contain a basServer instance.'
            )
          }
        }
      }
    }

    /**
     * Creates a dedication connection to a core with a given server.
     *
     * @private
     * @param {BasServer} server
     * @returns {Promise}
     */
    function _connectToCore (server) {

      _dedicatedCoreContainer = new BasCoreContainer(server)

      return _dedicatedCoreContainer.connectCore()
    }

    /**
     * Validates that the dedicated CoreContainer contains a valid connected
     * core and extracts it into _basCore for easy reuse.
     *
     * @private
     * @returns {Promise}
     */
    function _validateCoreConnected () {

      if (_dedicatedCoreContainer &&
        _dedicatedCoreContainer.core &&
        _dedicatedCoreContainer.core.server &&
        _dedicatedCoreContainer.core.server.isCoreConnected()) {

        _basCore = _dedicatedCoreContainer.core
        _basServer = _dedicatedCoreContainer.core.server

        return Promise.resolve()
      }

      return Promise.reject(
        'CoreContainer does not contain a core ' +
        'or it\'s core doesnt have a connected server'
      )
    }

    /**
     * Gets the device for the uuid we saved in the '_connectToServer'
     * step. If a device uuid is not available, it is requested from the
     * _basCore using the device's macAddress.
     *
     * @private
     * @returns {Promise}
     */
    function _getDevice () {

      return new Promise(_devicePromiseConstructor)

      function _devicePromiseConstructor (resolve, reject) {

        var _listeners, _finished, _timeoutId

        _finished = false

        _listeners = []

        _timeoutId = setTimeout(_onTimeout, DEVICE_TIMEOUT)

        if (BasUtil.isNEString(_deviceUuid)) {

          findDevice()

        } else {

          // Device UUID not yet known, request it
          _basServer.getCoreClientDeviceInfo(mac)
            .then(onDeviceInfo)
            .then(findDevice)
        }

        function onDeviceInfo (result) {

          if (
            result &&
            result.data
          ) {

            if (result.data.ellie) {
              _deviceUuid = result.data.ellie.uuid
            }

            if (result.data.device) {
              _deviceUuid = result.data.device.uuid
            }
          }

          if (!BasUtil.isNEString(_deviceUuid)) {

            _finish(null, 'Could not determine device uuid')
          }
        }

        function findDevice () {
          _findDevice()

          _listeners.push(BasUtil.setEventListener(
            _basCore,
            BAS_API.BasCore.EVT_ROOMS_UPDATED,
            _findDevice
          ))

          _listeners.push(BasUtil.setEventListener(
            _basCore,
            BAS_API.BasCore.EVT_DEVICES_UPDATED,
            _findDevice
          ))
        }

        function _findDevice () {

          if (_basCore.devices[_deviceUuid]) {

            _finish(_basCore.devices[_deviceUuid])
          }
        }

        function _onTimeout () {

          _finish(null, 'Device couldn\'t be ' +
            'determined within time frame.')
        }

        /**
         * Finish the promise
         *
         * @private
         * @param {*} innerResolve
         * @param {*} [innerReject]
         */
        function _finish (innerResolve, innerReject) {

          clearTimeout(_timeoutId)
          BasUtil.executeArray(_listeners)
          _listeners = null

          if (!_finished) {

            _finished = true

            if (innerResolve) {

              resolve(innerResolve)

            } else {

              reject(innerReject)
            }
          }
        }
      }
    }

    /**
     * Update the addresses for a given device.
     *
     * @private
     * @param {BasCoreClientDevice} device
     */
    function _updateDeviceAddresses (device) {

      updateAddresses(device, addressesArray)
    }

    /**
     * If a dedicated connection is open, close it.
     *
     * @private
     */
    function _endDedicatedConnection () {

      if (_dedicatedCoreContainer) {

        _dedicatedCoreContainer.logout().catch(_empty)
        _dedicatedCoreContainer = null
      }

      _basCore = null
      _basServer = null
    }

    /**
     * Error handler. Logs the error and end the dedicated connection.
     *
     * @private
     * @param {BasError} error
     */
    function _onError (error) {

      // eslint-disable-next-line no-console
      console.error(
        'Error while sending device addresses over dedicated ' +
        'connection, ending dedicated connection prematurely. Reason:',
        error
      )
      _endDedicatedConnection()
    }
  }

  function _empty () {
    // Empty
  }
}
