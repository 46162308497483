'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasConnectedDevices', [
    '$rootScope',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_HOME',
    'BAS_CONNECTED_DEVICES',
    'BAS_DEVICE',
    'CurrentBasCore',
    'BasDevice',
    'BasServerDevice',
    BasConnectedDevices
  ])

/**
 * @constructor
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_HOME} BAS_HOME
 * @param {BAS_CONNECTED_DEVICES} BAS_CONNECTED_DEVICES
 * @param {BAS_DEVICE} BAS_DEVICE
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasDevice
 * @param BasServerDevice
 */
function BasConnectedDevices (
  $rootScope,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_HOME,
  BAS_CONNECTED_DEVICES,
  BAS_DEVICE,
  CurrentBasCore,
  BasDevice,
  BasServerDevice
) {
  /**
   * @typedef {Object} TBasConnectedDevicesState
   * @property {Object<string, BasDevice>} devices
   * @property {string[]} uiDevices
   * @property {Object<string, BasServerDevice>} servers
   * @property {string[]} uiServers
   * @property {boolean} legacyServers
   * @property {boolean} hasUpdate
   */

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @private
   * @type {TBasConnectedDevicesState}
   */
  var _state = {}
  _clear()

  this.get = get
  this.getServer = getServer
  this.retrieveServerStatus = retrieveServerStatus
  this.uiToggleAllServerVersions = uiToggleAllServerVersions

  init()

  function init () {

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED,
      _onServerSet
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      _onServerConnected
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_VERSION,
      _onServerVersion
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _onMusicConfig
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CONNECTED_DEVICES_UPDATED,
      _onConnectedDevicesUpdated
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_SERVER_DEVICES_UPDATED,
      _onServersUpdated
    )
    $rootScope.$on(
      BAS_DEVICE.EVT_SERVER_DEVICE_UPDATE_STATE,
      _onServerDeviceUpdateState
    )

    _syncDevices()
    _syncServers()
  }

  /**
   * @returns {TBasConnectedDevicesState}
   */
  function get () {

    return _state
  }

  /**
   * @param {string} uuid
   * @returns {?BasServerDevice}
   */
  function getServer (uuid) {

    var _server

    if (BasUtil.isNEString(uuid) && BasUtil.isObject(_state.servers[uuid])) {

      _server = _state.servers[uuid]

      if (_server) return _server
    }

    return null
  }

  /**
   * Request status of all server devices
   *
   * @returns {(Promise[]|Promise)}
   */
  function retrieveServerStatus () {

    var promises, keys, length, i, _serverId, _server, _serverDevice

    promises = []

    keys = Object.keys(_state.servers)
    length = keys.length
    for (i = 0; i < length; i++) {

      _serverId = keys[i]
      _server = _state.servers[_serverId]

      if (_server) {

        _serverDevice = CurrentBasCore.getDevice(_server.uuid)

        if (_serverDevice &&
          _serverDevice instanceof BAS_API.ServerDevice) {

          promises.push(_serverDevice.status())
        }
      }
    }

    return promises.length > 0
      ? promises
      : Promise.reject(BAS_HOME.ERR_NO_SERVERS)
  }

  /**
   * @param {string} [force]
   */
  function uiToggleAllServerVersions (force) {

    var keys, length, i, _serverId, _server

    keys = Object.keys(_state.servers)
    length = keys.length
    for (i = 0; i < length; i++) {

      _serverId = keys[i]
      _server = _state.servers[_serverId]

      if (_server) _server.uiToggleVersion(force)
    }
  }

  function _onServerSet () {

    _clear()

    $rootScope.$emit(
      BAS_CONNECTED_DEVICES.EVT_BAS_CONNECTED_DEVICES_UPDATED
    )
    $rootScope.$emit(BAS_CONNECTED_DEVICES.EVT_BAS_SERVERS_UPDATED)
  }

  function _onServerConnected () {

    _setHasUpdate(false)
  }

  function _onServerVersion () {

    _legacyServerProcessVersion()
  }

  function _onMusicConfig () {

    _syncDevices()
    _syncServers()
  }

  function _onConnectedDevicesUpdated () {

    _syncDevices()
  }

  function _onServersUpdated () {

    _syncServers()
  }

  function _onServerDeviceUpdateState () {

    _checkForServerUpdates()
  }

  function _checkForServerUpdates () {

    var keys, i, length, server

    keys = Object.keys(_state.servers)
    length = keys.length
    for (i = 0; i < length; i++) {

      server = _state.servers[keys[i]]

      if (server && server.hasUpdate) {

        _setHasUpdate(true)
        return true
      }
    }

    _setHasUpdate(false)
    return false
  }

  /**
   * @private
   * @param {boolean} hasUpdate
   */
  function _setHasUpdate (hasUpdate) {

    var oldHasUpdate

    oldHasUpdate = _state.hasUpdate
    _state.hasUpdate = hasUpdate

    if (oldHasUpdate !== hasUpdate) {

      $rootScope.$emit(
        BAS_CONNECTED_DEVICES.EVT_BAS_SERVERS_UPDATE_STATE,
        _state.hasUpdate
      )
    }
  }

  function _syncDevices () {

    var _devices, _connectedDevices
    var length, i, device, newDevice

    _clearDevices()

    if (CurrentBasCore.hasCore()) {

      _devices = currentBasCoreState.core.core.devices
      _connectedDevices = currentBasCoreState.core.core.connectedDevices

      length = _connectedDevices.length
      for (i = 0; i < length; i++) {

        /**
         * @type {ConnectedDevice}
         */
        device = _devices[_connectedDevices[i]]

        // Only add non-server devices
        if (device instanceof BAS_API.ConnectedDevice &&
          !(device instanceof BAS_API.ServerDevice) &&
          _isViewableDevice(device)) {

          newDevice = new BasDevice(device)
          _state.devices[newDevice.uuid] = newDevice
          _state.uiDevices.push(newDevice.uuid)
        }
      }
    }

    $rootScope.$emit(
      BAS_CONNECTED_DEVICES.EVT_BAS_CONNECTED_DEVICES_UPDATED
    )
  }

  function _syncServers () {

    var _servers, _devices
    var length, i, server, newServer, _basServer

    _clearServers()

    if (CurrentBasCore.hasCore()) {

      _devices = currentBasCoreState.core.core.devices
      _servers = currentBasCoreState.core.core.servers

      length = _servers.length

      if (length > 0) {

        _state.legacyServers = false

        for (i = 0; i < length; i++) {

          /**
           * @type {ServerDevice}
           */
          server = _devices[_servers[i]]

          if (BasUtil.isObject(server)) {

            newServer = new BasServerDevice(server)
            _state.servers[newServer.uuid] = newServer
            _state.uiServers.push(newServer.uuid)
          }
        }

      } else {

        _state.legacyServers = true

        _basServer = currentBasCoreState.core.core.server

        newServer = new BasServerDevice()
        newServer.host = newServer.address =
          _basServer
            ? _basServer.host
              ? _basServer.host.hostname
              : ''
            : ''

        // MAC address
        newServer.mac = currentBasCoreState.core
          .legacyGetServerMacAddress()
        newServer.mac = BasUtil.isMACAddress(newServer.mac)
          ? newServer.mac
          : '-'
        newServer.uuid = newServer.mac

        _state.servers[newServer.uuid] = newServer
        _state.uiServers.push(newServer.uuid)

        _legacyServerProcessVersion()
        _legacyServerProcessStatus()
      }
    }

    $rootScope.$emit(BAS_CONNECTED_DEVICES.EVT_BAS_SERVERS_UPDATED)
  }

  /**
   * Checks whether the device should be visible in the list of devices.
   *
   * @private
   * @param {Device} device
   * @returns {boolean}
   */
  function _isViewableDevice (device) {

    return (
      BasUtil.isObject(device) &&
      (
        device.basType === BAS_API.Device.BT.ID_ASANO_S4 ||
        device.basType === BAS_API.Device.BT.ID_CORE_MINI ||
        device.basType === BAS_API.Device.BT.ID_CORE_PLUS ||
        device.basType === BAS_API.Device.BT.ID_ASANO_A4 ||
        device.basType === BAS_API.Device.BT.ID_ASANO_M4 ||
        device.basType === BAS_API.Device.BT.ID_ASANO_M3 ||
        device.basType === BAS_API.Device.BT.ID_ASANO_N3 ||
        device.basType === BAS_API.Device.BT.ID_ASANO_D3 ||
        device.basType === BAS_API.Device.BT.ID_ASANO_D4 ||
        device.basType === BAS_API.Device.BT.ID_ASANO_P4A ||
        device.basType === BAS_API.Device.BT.ID_ASANO_B2 ||
        device.basType === BAS_API.Device.BT.ID_ELLIE ||
        device.basType === BAS_API.Device.BT.ID_LISA
      )
    )
  }

  function _legacyServerProcessVersion () {

    var _version, server

    if (
      _state.legacyServers &&
      CurrentBasCore.hasCore() &&
      BasUtil.isObject(_state.servers) &&
      Array.isArray(_state.uiServers)
    ) {
      _version = currentBasCoreState.core.core.version
      server = _state.servers[_state.uiServers[0]]

      if (server) server.version = _version ? _version.text : '-'
    }
  }

  function _legacyServerProcessStatus () {

    var server, _basServer, _status, errors

    if (
      _state.legacyServers &&
      BasUtil.isObject(_state.servers) &&
      Array.isArray(_state.uiServers)
    ) {
      _basServer = currentBasCoreState.core.core.server

      if (_basServer) {

        _status = _basServer.status

        if (_status) {

          errors = _status.errors
        }
      }
    }

    if (errors) {

      server = _state.servers[_state.uiServers[0]]

      if (server) {

        server.device = {
          errors: errors
        }
      }
    }
  }

  function _clearDevices () {

    var keys, i, length, device

    if (BasUtil.isObject(_state.devices)) {

      keys = Object.keys(_state.devices)
      length = keys.length
      for (i = 0; i < length; i++) {

        device = _state.devices[keys[i]]
        if (device) device.destroy()
      }
    }

    _state.devices = {}
    _state.uiDevices = []
  }

  function _clearServers () {

    var keys, i, length, server

    if (BasUtil.isObject(_state.servers)) {

      keys = Object.keys(_state.servers)
      length = keys.length
      for (i = 0; i < length; i++) {

        server = _state.servers[keys[i]]
        if (server && server.destroy) server.destroy()
      }
    }

    _state.legacyServers = false
    _state.hasUpdate = false
    _state.servers = {}
    _state.uiServers = []
  }

  function _clear () {

    _clearDevices()
    _clearServers()
  }
}
