{
  "music": {
    "groups": [
      {
        "group": [
          "GroundFloorLivingRoom",
          "GroundFloorKitchen",
          "GroundFloorDiningRoom"
        ],
        "id": "GroupDownstairs",
        "muted": false,
        "name": "Downstairs",
        "source": -1,
        "volume": 48
      }
    ],
    "sources": [
      {
        "colour": "#00ff00",
        "id": 61,
        "name": "George",
        "seq": 0,
        "type": "player",
        "uuid": "sourceGeorge"
      },
      {
        "colour": "#ff0000",
        "id": 62,
        "name": "Jane",
        "seq": 1,
        "type": "player",
        "uuid": "sourceJane"
      },
      {
        "colour": "#ffff00",
        "id": 63,
        "name": "Judy",
        "seq": 2,
        "type": "player",
        "uuid": "sourceJudy"
      },
      {
        "colour": "#0000ff",
        "id": 64,
        "name": "Elroy",
        "seq": 3,
        "type": "player",
        "uuid": "sourceElroy"
      },
      {
        "id": 61,
        "name": "George",
        "status": {
          "clientName": "tokenSpotifyGeorge",
          "connected": false,
          "linkUrl": "",
          "token": "tokenSpotifyGeorge"
        },
        "subType": "spotify",
        "type": "barp",
        "uuid": "spotifyGeorge"
      },
      {
        "id": 62,
        "name": "Jane",
        "status": {
          "clientName": "tokenSpotifyJane",
          "connected": false,
          "linkUrl": "",
          "token": "tokenSpotifyJane"
        },
        "subType": "spotify",
        "type": "barp",
        "uuid": "spotifyJane"
      },
      {
        "id": 63,
        "name": "Judy",
        "status": {
          "clientName": "tokenSpotifyJudy",
          "connected": false,
          "linkUrl": "",
          "token": "tokenSpotifyJudy"
        },
        "subType": "spotify",
        "type": "barp",
        "uuid": "spotifyJudy"
      },
      {
        "id": 64,
        "name": "Elroy",
        "status": {
          "clientName": "tokenSpotifyElroy",
          "connected": false,
          "linkUrl": "",
          "token": "tokenSpotifyElroy"
        },
        "subType": "spotify",
        "type": "barp",
        "uuid": "spotifyElroy"
      },
      {
        "config": {
          "id": 65,
          "name": "Turntable Living Room",
          "type": 0,
          "uuid": "sourceTurntable"
        },
        "type": "external"
      },
      {
        "config": {
          "id": 66,
          "name": "iPod Dock Bedroom Judy",
          "type": 0,
          "uuid": "sourceIpodJudy"
        },
        "type": "external"
      },
      {
        "config": {
          "id": 67,
          "name": "iPod Dock Bedroom Elroy",
          "type": 0,
          "uuid": "sourceIpodElroy"
        },
        "type": "external"
      },
      {
        "config": {
          "id": 68,
          "name": "TV Family Room",
          "type": 2,
          "uuid": "SourceTVFamily"
        },
        "type": "external"
      },
      {
        "config": {
          "id": 69,
          "name": "TV Master Bedroom",
          "type": 2,
          "uuid": "SourceTVMaster"
        },
        "type": "external"
      }
    ],
    "zones": [
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "GroundFloorHall",
        "muted": false,
        "name": "",
        "order": 0,
        "source": 61,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 105
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "GroundFloorLivingRoom",
        "muted": false,
        "name": "",
        "order": 1,
        "source": 62,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 105
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "GroundFloorDiningRoom",
        "muted": false,
        "name": "",
        "order": 2,
        "source": 62,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 103
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "GroundFloorKitchen",
        "muted": false,
        "name": "",
        "order": 3,
        "source": 62,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 103
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "GroundFloorFamilyRoom",
        "muted": false,
        "name": "",
        "order": 4,
        "source": 63,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 120
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "GroundFloorBar",
        "muted": false,
        "name": "",
        "order": 5,
        "source": 61,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 40
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "FirstFloorHomeOffice",
        "muted": false,
        "name": "",
        "order": 6,
        "source": 61,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 40
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "FirstFloorMasterBedroom",
        "muted": false,
        "name": "",
        "order": 7,
        "source": 61,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 80
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "FirstFloorMasterBathroom",
        "muted": false,
        "name": "",
        "order": 8,
        "source": 62,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 90
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "FirstFloorBedroomJudy",
        "muted": false,
        "name": "",
        "order": 9,
        "source": 63,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 76
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "FirstFloorBedroomElroy",
        "muted": false,
        "name": "",
        "order": 10,
        "source": 64,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 94
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "FirstFloorBathroomChildren",
        "muted": false,
        "name": "",
        "order": 11,
        "source": 63,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 64
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "FirstFloorGuestRoom",
        "muted": false,
        "name": "",
        "order": 12,
        "source": 61,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 64
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "BasementHomeCinema",
        "muted": false,
        "name": "",
        "order": 13,
        "source": 64,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 64
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "BasementFitness",
        "muted": false,
        "name": "",
        "order": 14,
        "source": 63,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 127
      },
      {
        "bass": 0,
        "building": "",
        "floor": "",
        "id": "GardenTerrace",
        "muted": false,
        "name": "",
        "order": 15,
        "source": 63,
        "startupVolume": 64,
        "tags": [],
        "treble": 0,
        "volume": 64
      }
    ]
  },
  "version": "7.0.0"
}
