'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomCoreClientDevices', [
    'CurrentBasCore',
    'BasCoreClientDevice',
    basRoomCoreClientDevicesFactory
  ])

/**
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasCoreClientDevice
 * @returns BasRoomCoreClientDevices
 */
function basRoomCoreClientDevicesFactory (
  CurrentBasCore,
  BasCoreClientDevice
) {
  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomCoreClientDevices (basRoom) {

    /**
     * @type {BasCoreClientDevice[]}
     */
    this.coreClients = []

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomCoreClientDevices.hasEllies = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.ellies)
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomCoreClientDevices.hasLisas = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.lisas)
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomCoreClientDevices.hasLenas = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.lenas)
    )
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomCoreClientDevices.hasCoreClients = function (room) {

    return (
      this.hasLisas(room) ||
      this.hasEllies(room) ||
      this.hasLenas(room)
    )
  }

  BasRoomCoreClientDevices.prototype.parseRoom = function () {

    if (BasRoomCoreClientDevices.hasCoreClients(this._basRoom)) {

      this.syncCoreClients()
    }
  }

  BasRoomCoreClientDevices.prototype.syncCoreClients = function () {

    var length, i, uuid, device

    this.resetCoreClients()

    if (BasRoomCoreClientDevices.hasCoreClients(this._basRoom)) {

      length = this._basRoom.room.ellies.length
      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.ellies[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) this.coreClients.push(new BasCoreClientDevice(device))
      }

      length = this._basRoom.room.lisas.length
      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.lisas[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) this.coreClients.push(new BasCoreClientDevice(device))
      }

      length = this._basRoom.room.lenas.length
      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.lenas[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) this.coreClients.push(new BasCoreClientDevice(device))
      }
    }
  }

  /**
   * @param {string} mac
   * @returns {?BasCoreClientDevice}
   */
  BasRoomCoreClientDevices.prototype.getCoreClientByMac = function (mac) {

    var _mac, length, i, coreClient

    if (BasUtil.isNEString(mac)) {

      _mac = mac.toLowerCase()

      length = this.coreClients.length
      for (i = 0; i < length; i++) {

        coreClient = this.coreClients[i]

        if (
          coreClient &&
          BasUtil.isNEString(coreClient.macStr) &&
          _mac === coreClient.macStr.toLowerCase()
        ) {

          return coreClient
        }
      }
    }

    return null
  }

  /**
   * @param {string} uuid
   * @returns {?BasCoreClientDevice}
   */
  BasRoomCoreClientDevices.prototype.getCoreClientByUuid = function (uuid) {

    var length, i, coreClient

    if (BasUtil.isNEString(uuid)) {

      length = this.coreClients.length
      for (i = 0; i < length; i++) {

        /**
         * @type {BasCoreClientDevice}
         */
        coreClient = this.coreClients[i]

        if (coreClient &&
          uuid === coreClient.uuid) {

          return coreClient
        }
      }
    }

    return null
  }

  BasRoomCoreClientDevices.prototype.clearCoreClients = function () {

    var length, i

    length = this.coreClients.length
    for (i = 0; i < length; i++) {

      if (this.coreClients[i]) this.coreClients[i].clear()
    }
  }

  BasRoomCoreClientDevices.prototype.resetCoreClients = function () {

    this.clearCoreClients()

    this.coreClients = []
  }

  /**
   * Clears the BasRoom reference
   */
  BasRoomCoreClientDevices.prototype.destroy = function () {

    this._basRoom = null
  }

  return BasRoomCoreClientDevices
}
