'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basThermostatTile', [
    'BAS_HTML',
    thermostatTileDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basThermostatTile
 */
function thermostatTileDirective (
  BAS_HTML
) {
  return {
    restrict: 'EA',
    template: BAS_HTML.thermostatTile,
    controller: [
      '$rootScope',
      '$scope',
      'BAS_ROOMS',
      'CurrentRoom',
      controller
    ],
    controllerAs: 'thermostat'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_ROOMS} BAS_ROOMS
   * @param {CurrentRoom} CurrentRoom
   */
  function controller (
    $rootScope,
    $scope,
    BAS_ROOMS,
    CurrentRoom
  ) {
    let _listeners = []

    var thermostat = this

    /**
     * @type {BasRooms}
     */
    thermostat.rooms = BAS_ROOMS.ROOMS

    /**
     * @type {TCurrentRoomState}
     */
    thermostat.currentRoom = CurrentRoom.get()

    init()

    function init () {
      $scope.$on('$destroy', _destroy)

      _setThermostat()

      _listeners.push($rootScope.$on(
        BAS_ROOMS.EVT_CURRENT_ROOM_CHANGED,
        _onCurrentRoomChanged
      ))
    }

    function _setThermostat () {
      thermostat.thermostat =
        BAS_ROOMS.ROOMS.rooms[thermostat.currentRoom.roomId]
          .thermostats.thermostats[0]
    }

    function _onCurrentRoomChanged () {

      _setThermostat()
    }

    function _destroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
