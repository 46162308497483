'use strict'

const BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .service('BasCallHistory', [
    '$rootScope',
    'BAS_API',
    'BAS_SIP',
    'BAS_CALL_HISTORY',
    'BAS_CURRENT_CORE',
    'BasAppDevice',
    'BasCallHistoryEntry',
    'CurrentBasCore',
    'BasState',
    BasCallHistory
  ])

/**
 * @typedef {Object} TFormattedCallHistory
 * @property {string} timestamp
 * @property {BasCallHistoryEntry[]} entries
 */

/**
 * @typedef {Object} TCallHistoryState
 * @property {BasCallHistoryEntry[]} uiCallHistory
 * @property {TFormattedCallHistory} formattedUiCallHistory
 * @property {Array<string>} contactUuids
 * @property {boolean} reachedToLastEntry
 */

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_SIP} BAS_SIP
 * @param {BAS_CALL_HISTORY} BAS_CALL_HISTORY
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BasAppDevice} BasAppDevice
 * @param {BasCallHistoryEntry} BasCallHistoryEntry
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasState} BasState
 */

function BasCallHistory (
  $rootScope,
  BAS_API,
  BAS_SIP,
  BAS_CALL_HISTORY,
  BAS_CURRENT_CORE,
  BasAppDevice,
  BasCallHistoryEntry,
  CurrentBasCore,
  BasState
) {
  let _doorPhoneTopicListeners = []

  /**
   * @type {TCurrentBasCoreState}
   */
  const currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TCallHistoryState}
   */
  const state = {
    uiCallHistory: {},
    formattedUiCallHistory: {},
    contactUuids: [],
    reachedToLastEntry: false
  }

  this.get = get
  this.retrievePaginated = retrievePaginated
  this.getCallDetails = getCallDetails
  this.clearState = clearState

  init()
  function init () {
    state.uiCallHistory = {}
    state.reachedToLastEntry = false

    if (BasAppDevice.isCoreClient()) {

      // This service should always be initialized before BasSip service,
      // or we will miss this event.
      $rootScope.$on(
        BAS_SIP.EVT_SIP_ADDRESSES_INITALIZED,
        setContacts
      )

      $rootScope.$on(
        BAS_CALL_HISTORY.EVT_CALL_HISTORY_ENTRY_IMAGES_UPDATED,
        _onImagesUpdated
      )

      $rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_V2_CONNECTED,
        (_event, _core, isConnected) => {

          if (isConnected) {
            _getDoorphoneTopic()?.subscribe().then(_onDoorPhoneTopicAvailable)
          } else {
            BasUtil.executeArray(_doorPhoneTopicListeners)
            _doorPhoneTopicListeners = []
          }
        }
      )
    }
  }

  function _onImagesUpdated () {
    $rootScope.$applyAsync()
  }

  function _onDoorPhoneTopicAvailable () {
    const doorPhoneTopic = _getDoorphoneTopic()

    _doorPhoneTopicListeners.push(BasUtil.setEventListener(
      doorPhoneTopic,
      BAS_API.DoorPhoneTopic.EVT_CALL_HISTORY_CHANGED,
      () => {
        clearState()
        if (BasState.get().current.CALL_HISTORY) {
          retrievePaginated()
        }
      }
    ))
  }

  /**
   * @returns {TCallHistoryState}
   */
  function get () {

    return state
  }

  function setContacts (_event, sipAddresses) {

    if (BasUtil.isNEArray(sipAddresses)) {

      _setContactUuids(sipAddresses)
    }
  }

  function _setContactUuids (result) {

    const contactUuids = []

    if (BasUtil.isNEArray(result)) {

      const length = result.length

      for (let i = 0; i < length; i++) {

        contactUuids.push(
          result[i].contact.uuid
        )
      }

      state.contactUuids = contactUuids
    }
  }

  function retrievePaginated () {

    const doorPhoneTopic = _getDoorphoneTopic()

    if (doorPhoneTopic && !state.reachedToLastEntry) {
      return _setCallHistoryWithLimit(30)
    }
  }

  function clearState () {
    state.uiCallHistory = {}
    state.formattedUiCallHistory = {}

    state.reachedToLastEntry = false
  }

  /**
   * Set call history
   *
   * @param {int} limit
   * @returns {Promise}
   */
  async function _setCallHistoryWithLimit (limit) {

    const doorPhoneTopic = _getDoorphoneTopic()

    if (BasUtil.isNEArray(state.contactUuids)) {

      return doorPhoneTopic.getCallHistory(
        state.contactUuids,
        Object.values(state.uiCallHistory).length,
        limit
      ).then(results => {

        if (results.length) {

          for (const callHistoryEntry of results) {

            const entry = new BasCallHistoryEntry(callHistoryEntry)
            const uuid = `${entry.contact.uuid}-${entry.timestamp.toString()}`

            if (!Object.keys(state.formattedUiCallHistory).includes(uuid)) {
              entry.syncImages()
              state.uiCallHistory[uuid] = entry
              createKeyBasedOnEntryTimestamp(entry)
            }
          }

          $rootScope.$emit(BAS_CALL_HISTORY.EVT_CALL_HISTORY_UPDATED)
          $rootScope.$applyAsync()
        } else {
          // No more call history entries in result, we've reached the end
          state.reachedToLastEntry = true
        }
      })
    }

    return Promise.reject(
      'Unable to fetch call history. Invalid or empty contactUuids.'
    )
  }

  /**
   * Create key based on timestamp of call history entry
   *
   * @param entry
   */
  function createKeyBasedOnEntryTimestamp (entry) {
    let key = ''

    const date = new Date(entry.timestamp)
    const today = new Date()

    // Set today's date to midnight,
    // When comparing timestamps without setting hour to midnight it might
    // lead to unexpected results, as the comparison
    // would consider the time component as well.
    today.setHours(0, 0, 0, 0)

    const yesterday = new Date(0)
    // The -1 is indicating a subtraction of one day.
    yesterday.setHours(0, 0, 0, 0)
    yesterday.setMonth(today.getMonth(), today.getDate() - 1)
    yesterday.setFullYear(today.getFullYear())

    // Calculate the difference in days
    const dayDifference = Math.floor((today - date) / (24 * 60 * 60 * 1000))

    const currYear = new Date().getFullYear()

    // Get key for this entry
    if (date >= today) {
      key = 'today'

    } else if (date >= yesterday) {
      key = 'yesterday'

    } else if (dayDifference < 7) {
      key = 'this_week'

    } else if (date.getFullYear() === currYear) {
      key = 'month_' +
        date.toLocaleString('en-GB', { month: 'long' }).toLowerCase()

    } else {
      key = 'month_' +
        date.toLocaleString('en-GB', { month: 'long' }).toLowerCase() +
        '-year_' + date.getFullYear()

    }

    addEntryToFormattedUiCallHistory(key, entry)
  }

  /**
   * Create object that contains the call history entries sorted by the
   * matching timestamp key.
   *
   * @param {string} key
   * @param {BasCallHistoryEntry} entry
   */
  function addEntryToFormattedUiCallHistory (key, entry) {
    var formattedUiCallHistory = state.formattedUiCallHistory

    // 1. Add timestamp key if its not already added
    if (!formattedUiCallHistory[key]) {
      formattedUiCallHistory[key] = []
    }

    // 2. Add entry to the correct key if its not already added
    if (!formattedUiCallHistory[key].includes(entry)) {
      formattedUiCallHistory[key].push(entry)
    }

    // 3. Sort the entries with the same key based on timestamp
    Object.values(formattedUiCallHistory[key]).sort(
      function (a, b) {
        return new Date(b.timestamp) - new Date(a.timestamp)
      }
    )
  }

  /**
   *
   * @param contactUuid
   * @param timestamp
   * @param offset
   * @param limit
   * @returns {Promise<?TBasGetCallDetailsServerResponseData>}
   */
  function getCallDetails (contactUuid, timestamp, offset, limit) {
    const doorPhoneTopic = _getDoorphoneTopic()

    return doorPhoneTopic.getCallDetails(
      contactUuid,
      timestamp,
      offset,
      limit
    )
  }

  /**
   * @private
   * @returns {?DoorPhoneTopic}
   */
  function _getDoorphoneTopic () {

    return currentBasCoreState.core?.core?.doorPhoneTopic
  }
}
