'use strict'

/**
 * @callback BasFirebaseTokenRefreshCallback
 * @param {string} token
 */

/**
 * @callback BasFirebaseMessageReceivedCallback
 * @param {Object} message
 */

/**
 * @name ITokenRefreshListener
 * @param {BasFirebaseTokenRefreshCallback} listener
 */

/**
 * @name IMessageReceivedListener
 * @param {BasFirebaseMessageReceivedCallback} listener
 */

/**
 * @typedef {Object} TBasFirebase
 * @property {Object} [lastMessage]
 * @property {BasFirebaseTokenRefreshCallback[]} [tokenRefreshListeners]
 * @property {BasFirebaseMessageReceivedCallback[]} [messageListeners]
 * @method {ITokenRefreshListener} addTokenRefreshListener
 * @method {ITokenRefreshListener} removeTokenRefreshListener
 * @method {IMessageReceivedListener} addMessageReceivedListener
 * @method {IMessageReceivedListener} removeMessageReceivedListener
 */

module.exports.initBasFirebase = initBasFirebase

/**
 * @param {Window} window
 * @param [callback]
 */
function initBasFirebase (window, callback) {

  var firebasePlugin, _token

  firebasePlugin = _getFirebasePlugin(window)

  if (
    firebasePlugin &&
    firebasePlugin.onMessageReceived &&
    firebasePlugin.onTokenRefresh
  ) {
    if (!window.basFirebase) {

      /**
       * @type {TBasFirebase}
       */
      window.basFirebase = {}
    }

    window.basFirebase.addTokenRefreshListener =
      addTokenRefreshListener
    window.basFirebase.removeTokenRefreshListener =
      removeTokenRefreshListener

    window.basFirebase.addMessageReceivedListener =
      addMessageReceivedListener
    window.basFirebase.removeMessageReceivedListener =
      removeMessageReceivedListener

    firebasePlugin.onMessageReceived(
      _onMessageReceived,
      _onMessageReceivedError
    )

    firebasePlugin.onTokenRefresh(
      _onTokenRefresh,
      _onTokenRefreshError
    )
  }

  if (typeof callback === 'function') callback(null)

  function addTokenRefreshListener (listener) {

    if (_addListener('tokenRefreshListeners', listener)) {

      if (_token) setTimeout(_onTimeout)
    }

    function _onTimeout () {

      if (
        _token &&
        window.basFirebase &&
        Array.isArray(window.basFirebase.tokenRefreshListeners) &&
        window.basFirebase.tokenRefreshListeners.indexOf(listener) > -1
      ) {
        listener(_token)
      }
    }
  }

  function removeTokenRefreshListener (listener) {

    _removeListener('tokenRefreshListeners', listener)
  }

  function addMessageReceivedListener (listener) {

    if (_addListener('messageListeners', listener)) {

      if (window.basFirebase.lastMessage) setTimeout(_onTimeout)
    }

    function _onTimeout () {

      if (
        window.basFirebase.lastMessage &&
        window.basFirebase &&
        Array.isArray(window.basFirebase.messageListeners) &&
        window.basFirebase.messageListeners.indexOf(listener) > -1
      ) {
        listener(window.basFirebase.lastMessage)
      }
    }
  }

  function removeMessageReceivedListener (listener) {

    _removeListener('messageListeners', listener)
  }

  function _addListener (key, listener) {

    var idx

    if (
      typeof listener === 'function' &&
      window.basFirebase
    ) {
      if (Array.isArray(window.basFirebase[key])) {

        idx = window.basFirebase[key]
          .indexOf(listener)

        // Listener is already registered
        if (idx > -1) return false

      } else {

        window.basFirebase[key] = []
      }

      window.basFirebase[key].push(listener)

      return true
    }

    return false
  }

  function _removeListener (key, listener) {

    var idx

    if (
      typeof listener === 'function' &&
      window.basFirebase &&
      Array.isArray(window.basFirebase[key])
    ) {
      idx = window.basFirebase[key].indexOf(listener)

      if (idx > -1) window.basFirebase[key].splice(idx, 1)
    }
  }

  function _onMessageReceived (message) {

    if (window.basFirebase) {

      window.basFirebase.lastMessage = message

      _emitMessageReceived()
    }
  }

  function _onMessageReceivedError (error) {

    // eslint-disable-next-line no-console
    console.error('Firebase - Error onMessageReceived', error)
  }

  function _onTokenRefresh (token) {

    _token = token

    _emitTokenRefresh()
  }

  function _onTokenRefreshError (error) {

    // eslint-disable-next-line no-console
    console.error('Firebase - Error onTokenRefresh', error)
  }

  function _emitTokenRefresh () {

    var listeners, length, i, listener

    if (window.basFirebase) {

      listeners = window.basFirebase.tokenRefreshListeners

      if (Array.isArray(listeners)) {

        length = listeners.length
        for (i = 0; i < length; i++) {

          listener = listeners[i]

          if (typeof listener === 'function') listener(_token)
        }
      }
    }
  }

  function _emitMessageReceived () {

    var listeners, length, i, listener

    if (window.basFirebase) {

      listeners = window.basFirebase.messageListeners

      if (Array.isArray(listeners)) {

        length = listeners.length
        for (i = 0; i < length; i++) {

          listener = listeners[i]

          if (typeof listener === 'function') {

            listener(window.basFirebase.lastMessage)
          }
        }
      }
    }
  }
}

function _getFirebasePlugin (window) {

  if (
    window['basalteCordova'] &&
    window['basalteCordova']['firebase']
  ) {
    return window['basalteCordova']['firebase']
  }
}
