'use strict'

angular
  .module('basalteApp')
  .controller('thermostatSceneCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'BasSceneHelper',
    'BasAppTemperature',
    thermostatSceneCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasSceneHelper} BasSceneHelper
 * @param {BasAppTemperature} BasAppTemperature
 */
function thermostatSceneCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_ROOMS,
  BasSceneHelper,
  BasAppTemperature
) {
  var basThermostat = this

  var _oldState = BasSceneHelper.copyState($uiRouterGlobals)

  var _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

  basThermostat.thermostat =
    BAS_ROOMS.ROOMS.rooms[_step.room].thermostats
      .getThermostat($uiRouterGlobals.params.thermostat)

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _syncThermostatClone()
  }

  function _syncThermostatClone () {

    basThermostat.thermostat = _getThermostatClone()
  }

  /**
   * @private
   * @returns {BasThermostat}
   */
  function _getThermostatClone () {

    var i, length, control
    var step, clone

    step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (basThermostat.thermostat?.sceneClone) {
      clone = basThermostat.thermostat.sceneClone()

      if (step.thermostat) {

        if (step.thermostat.includeInScene.mode) {

          clone.setMode(step.thermostat.mode)
        }

        length = step.thermostat.controls.length
        for (i = 0; i < length; i++) {

          control = step.thermostat.controls[i]

          if (control.includeInScene) {

            clone.setControlIncludeInScene(control.uuid)
          }

          clone.setControlActive(control.uuid, control.active)
        }

        if (step.thermostat.includeInScene.fan) {

          clone.setFanMode(step.thermostat.fanSpeed)
        }

        if (step.thermostat.includeInScene.desired) {

          clone.setDesired(
            step.thermostat.desired.getTemperature(
              BasAppTemperature.getTemperatureUnit()
            )
          )
          clone.toggleIncludeDesired(true)
        }
      }

      step.setThermostat(clone)

      return clone
    }
  }

  function _onDestroy () {
    _step = BasSceneHelper.getStep($scope, _oldState)
    if (_step) _step.updateSummary()
  }
}
