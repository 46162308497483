'use strict'

module.exports.initBasGlobalError = initBasGlobalError

/**
 * @param {Window} window
 * @param [callback]
 */
function initBasGlobalError (window, callback) {

  // Set global error handler to catch uncaught JS exceptions
  window.addEventListener('error', _onGlobalError, true)

  if (typeof callback === 'function') callback(null)

  /**
   * @private
   * @param {ErrorEvent} errorEvent
   */
  function _onGlobalError (errorEvent) {

    var firebasePlugin, logMessage

    if (errorEvent) {

      logMessage = errorEvent.message

      logMessage += ' ; filename=' + errorEvent.filename
      logMessage += ' ; line=' + errorEvent.lineno
      logMessage += ' ; col=' + errorEvent.colno

      if (window.location && window.location.href) {

        logMessage += ' ; url=' + window.location.href
      }

      if (errorEvent.error && typeof errorEvent.error === 'object') {

        logMessage += ' ; error=' + JSON.stringify(errorEvent.error)

        if (errorEvent.error.stack) {

          logMessage += ' ; stack=' + errorEvent.error.stack
        }
      }

      firebasePlugin = _getFirebasePlugin(window)

      // Current firebase plugin does not contain the 'logError' method, but it
      //  could be added in the future
      if (firebasePlugin && firebasePlugin.logError) {

        firebasePlugin.logError(
          logMessage,
          _onFirebaseLogErrorSuccess,
          _onFirebaseLogErrorError
        )
      }
    }
  }

  function _onFirebaseLogErrorSuccess () {

    // Do nothing
  }

  function _onFirebaseLogErrorError (error) {

    // eslint-disable-next-line no-console
    console.error('Firebase - Error logError', error)
  }
}

function _getFirebasePlugin (window) {

  if (
    window['basalteCordova'] &&
    window['basalteCordova']['firebase']
  ) {
    return window['basalteCordova']['firebase']
  }
}
