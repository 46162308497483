'use strict'

angular
  .module('basalteApp')
  .directive('basShade', [
    'BAS_HTML',
    basShadeDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basShade
 */
function basShadeDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    bindToController: {
      shade: '<'
    },
    controllerAs: 'basShadeDir',
    controller: function () {},
    template: BAS_HTML.shade
  }
}
