'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('presetsKNXPresetsCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_SOURCE',
    'BAS_SOURCES',
    'BasState',
    'Sources',
    'SourcesHelper',
    presetsKNXPresetsCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BasState} BasState
 * @param {Sources} Sources
 * @param {SourcesHelper} SourcesHelper
 */
function presetsKNXPresetsCtrl (
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_SOURCE,
  BAS_SOURCES,
  BasState,
  Sources,
  SourcesHelper
) {
  var presets = this

  var registeredSourceId

  presets.BAS_SOURCES = BAS_SOURCES

  presets.$uiRouterGlobals = $uiRouterGlobals

  presets.selectPreset = selectPreset

  presets.uiOnParamsChanged = uiOnParamsChanged

  init()

  function init () {

    $scope.$on('$destroy', onDestroy)

    registerSource()
  }

  /**
   * @param {(string|number)} presetId
   */
  function selectPreset (presetId) {

    if (BasUtil.isNEString(presetId) ||
      BasUtil.isVNumber(presetId)) {

      BasState.go(
        STATES.SETTINGS_MUSIC_KNX_PRESETS_PRESET,
        {
          presetId: presetId
        }
      )
    }
  }

  function getSource () {

    return SourcesHelper.getBasSource($uiRouterGlobals.params.stream)
  }

  function uiOnParamsChanged () {

    registerSource()
  }

  function registerSource () {

    var source

    source = getSource()

    unRegisterSource()

    if (source) {

      registeredSourceId = source.getId()

      Sources.registerFor(BAS_SOURCE.COL_EVT_KNX_PRESETS, registeredSourceId)
    }
  }

  function unRegisterSource () {

    if (!BasUtil.isUndefined(registeredSourceId)) {

      Sources.unregisterFor(BAS_SOURCE.COL_EVT_KNX_PRESETS, registeredSourceId)
      registeredSourceId = undefined
    }
  }

  function onDestroy () {

    unRegisterSource()
  }
}
