'use strict'

angular
  .module('basalteApp')
  .constant('BAS_ERRORS', {
    T_TIMEOUT: 'Timeout',
    T_ABORT: 'Abort',
    T_INVALID_INPUT: 'Invalid input',
    T_INTERNAL: 'Internal',
    T_CONNECTION: 'Connection',
    T_LIVE_NOT_AUTHORIZED: 'LiveNotAuthorized',
    T_LIVE_NO_INTEGRATOR_ACCESS: 'LiveNoIntegratorAccess',
    T_LIVE_NO_ACTIVE_SERVERS_FOUND: 'LiveNoActiveServersFound',
    T_LIVE_WAF: 'LiveWAFException',
    T_LIVE_CONNECTION: 'LiveConnection',
    T_API_VERSION: 'ApiVersion',
    T_NO_LEADER: 'NoLeader',
    T_AUTHENTICATION: 'Authentication',
    T_LIVE_AUTHENTICATION: 'LiveAuthentication',
    T_USER_DECLINE: 'UserDecline',
    T_NOT_SUPPORTED: 'NotSupported',
    T_NO_CORE: 'NoCore',
    T_INVALID_RESULT: 'Invalid result',
    T_PROJECT_CID_MISMATCH: 'Project CID mismatch',
    M_NO_CORE: 'No core',
    M_NO_SERVER: 'No server',
    M_ABORTED: 'Aborted',
    M_TIMEOUT: 'Timeout',
    M_INVALID_INPUT: 'Invalid input'
  })
