{
  "tokenSpotifyGeorge": {
    "display_name": "George",
    "id" : "spotifyGeorge"
  },
  "tokenSpotifyJane": {
    "display_name": "Jane",
    "id" : "spotifyJane"
  },
  "tokenSpotifyJudy": {
    "display_name": "Judy",
    "id" : "spotifyGeorge"
  },
  "tokenSpotifyElroy": {
    "display_name": "Elroy",
    "id" : "spotifyElroy"
  }
}
