'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('remoteCtrl', [
    '$rootScope',
    '$scope',
    'VIDEO_BUTTON',
    'BAS_ROOM',
    'BAS_ROOMS',
    'CurrentRoom',
    'BasUiRemote',
    remoteCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {VIDEO_BUTTON} VIDEO_BUTTON
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 * @param {BasUiRemote} BasUiRemote
 */
function remoteCtrl (
  $rootScope,
  $scope,
  VIDEO_BUTTON,
  BAS_ROOM,
  BAS_ROOMS,
  CurrentRoom,
  BasUiRemote
) {

  var remote = this

  var _listeners = []

  /**
   * @type {BAS_ROOM}
   */
  remote.BAS_ROOM = BAS_ROOM

  /**
   * @type {VIDEO_BUTTON}
   */
  remote.VIDEO_BUTTON = VIDEO_BUTTON

  /**
   * @type {BasRooms}
   */
  remote.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TBasUiQueue}
   */
  remote.basUiRemote = BasUiRemote.get()

  /**
   * @type {TCurrentRoomState}
   */
  remote.currentRoom = CurrentRoom.get()

  remote.holdInterval = 200

  remote.onButtonClicked = onButtonClicked

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    BasUiRemote.toggle(false)

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SOURCE_CHANGED,
      _onSourceChanged
    ))
  }

  function onButtonClicked (button, active) {

    var basSource, basVideo

    if (
      button === VIDEO_BUTTON.VOLUME_DOWN ||
      button === VIDEO_BUTTON.VOLUME_UP
    ) {

      basVideo = CurrentRoom.getRoomVideo()

      if (basVideo) {

        if (button === VIDEO_BUTTON.VOLUME_DOWN) {

          basVideo.volumeDown(active)

        } else {

          basVideo.volumeUp(active)
        }
      }

    } else {

      basSource = CurrentRoom.getVideoBasSource()

      if (basSource) {

        basSource.handleVideoButton(button, active)
      }
    }
  }

  function _onSourceChanged (_event, roomId) {

    var room

    room = CurrentRoom.getRoom()

    // Check if source has changed for our current room
    if (room && room.id === roomId) {

      BasUiRemote.toggle(false)
    }
  }

  function _onDestroy () {
    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
