{
  "61":[
    "GroundFloorLivingRoom"
  ],
  "62":[
    "GroundFloorKitchen"
  ],
  "63":[
    "FirstFloorBedroomJudy"
  ],
  "64":[
    "FirstFloorBedroomElroy"
  ]
}
