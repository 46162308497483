'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSchedulerPointType', basSchedulerPointTypeFactory)

function basSchedulerPointTypeFactory () {

  /**
   * @constructor
   * @param {string} [id]
   * @param {string} [translationId]
   * @param {boolean} [off]
   */
  function BasSchedulerPointType (id, translationId, off) {

    /**
     * @type {string}
     */
    this.id = BasUtil.isNEString(id) ? id : ''

    /**
     * @type {string}
     */
    this.translationId = BasUtil.isNEString(translationId)
      ? translationId
      : ''

    /**
     * @type {boolean}
     */
    this.off = !!off

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
  }

  return BasSchedulerPointType
}
