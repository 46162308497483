'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .component('thermostatDetailSlider', {
    template: [
      'BAS_HTML',
      thermostatDetailSliderTemplate
    ],
    bindings: {
      thermostat: '<'
    },
    controller: [
      '$scope',
      '$window',
      'BasAppTemperature',
      'BasAppDevice',
      thermostatDetailSliderCtrl
    ],
    controllerAs: 'basThermostatDetailSlider'
  })

function thermostatDetailSliderTemplate (BAS_HTML) {

  return BAS_HTML.thermostatDetailSlider
}

/**
 * @param $scope
 * @param $window
 * @param {BasAppTemperature} BasAppTemperature
 * @param {BasAppDevice} BasAppDevice
 */

function thermostatDetailSliderCtrl (
  $scope,
  $window,
  BasAppTemperature,
  BasAppDevice
) {
  var basThermostatDetailSlider = this

  var changeIntervalId = null
  var holdTimeoutId = null

  var HOLD_INTERVAL = 300
  var HOLD_DELAY = 1000

  var globalListeners = []

  basThermostatDetailSlider.$onDestroy = _onDestroy

  basThermostatDetailSlider.desired = 20
  basThermostatDetailSlider.isLisa = BasAppDevice.isLisa()

  basThermostatDetailSlider.onDesiredChanges = onDesiredChanges
  basThermostatDetailSlider.onDesiredChangesNonDebounced =
    onDesiredChangesNonDebounced

  // Angular life-cycle methods
  basThermostatDetailSlider.$postLink = postLink

  basThermostatDetailSlider.onStart = onStart

  function getThermostatUiDesired () {

    return basThermostatDetailSlider.thermostat
      ? basThermostatDetailSlider.thermostat.desired.getTemperature(
        BasAppTemperature.getTemperatureUnit()
      )
      : null
  }

  function getThermostatMin () {

    return basThermostatDetailSlider.thermostat
      ? basThermostatDetailSlider.thermostat.setpointMin
      : 5
  }

  function getThermostatMax () {

    return basThermostatDetailSlider.thermostat
      ? basThermostatDetailSlider.thermostat.setpointMax
      : 30
  }

  function postLink () {

    $scope.$watch(getThermostatUiDesired, onDesiredChange)
  }

  function onDesiredChange () {

    sync()
  }

  function sync () {

    var desired

    if (basThermostatDetailSlider.thermostat) {

      desired = basThermostatDetailSlider.thermostat.desired.getTemperature(
        BasAppTemperature.getTemperatureUnit()
      )

      basThermostatDetailSlider.desired = desired
    }
  }

  /**
   * @param {number} desired
   */
  function onDesiredChanges (desired) {

    if (basThermostatDetailSlider.thermostat &&
      basThermostatDetailSlider.thermostat.canSetSetPoint() &&
      basThermostatDetailSlider.thermostat.onDesiredChanges) {

      basThermostatDetailSlider.thermostat.onDesiredChanges(desired)
    }
  }

  /**
   * @param {number} desired
   */
  function onDesiredChangesNonDebounced (desired) {
    if (
      basThermostatDetailSlider.thermostat &&
      basThermostatDetailSlider.thermostat.canSetSetPoint()
    ) {
      basThermostatDetailSlider.thermostat.setUiDesired(desired)
      basThermostatDetailSlider.thermostat.toggleIncludeDesired(true)
    }
  }

  function updateDesired () {

    basThermostatDetailSlider.onDesiredChanges(
      BasUtil.clamp(
        basThermostatDetailSlider.desired,
        getThermostatMin(),
        getThermostatMax()
      )
    )
  }

  function increase () {

    basThermostatDetailSlider.desired +=
      basThermostatDetailSlider.thermostat.precision
  }

  function decrease () {

    basThermostatDetailSlider.desired -=
      basThermostatDetailSlider.thermostat.precision
  }

  function onStart (doIncrease) {

    // Handle initial tap instantly
    if (doIncrease) {

      increase()

    } else {

      decrease()
    }

    holdTimeoutId = setTimeout(onHoldTimeout, HOLD_DELAY, doIncrease)
    updateDesired()

    setReleaseListeners()
  }

  function onHoldTimeout (doIncrease) {

    clearAll()

    changeIntervalId = setInterval(onChangeInterval, HOLD_INTERVAL, doIncrease)
  }

  function onChangeInterval (doIncrease) {

    if (doIncrease) {

      increase()

    } else {

      decrease()
    }

    updateDesired()
    $scope.$applyAsync()
  }

  function setReleaseListeners () {

    globalListeners.push(BasUtil.setDOMListener(
      $window.document,
      'mouseup',
      onRelease
    ))
    globalListeners.push(BasUtil.setDOMListener(
      $window.document,
      'touchend',
      onRelease
    ))
    globalListeners.push(BasUtil.setDOMListener(
      $window.document,
      'contextmenu',
      onContextMenu
    ))
  }

  function clearReleaseListeners () {

    BasUtil.executeArray(globalListeners)
    globalListeners = []
  }

  function onRelease (event) {

    if (event.cancelable) event.preventDefault()
    event.stopPropagation()

    clearReleaseListeners()
    clearAll()
  }

  function onContextMenu (event) {

    event.stopPropagation()
    event.preventDefault()
  }

  function clearAll () {
    clearTimeout(holdTimeoutId)
    clearInterval(changeIntervalId)

    holdTimeoutId = null
    changeIntervalId = null
  }

  function _onDestroy () {

    clearAll()
    clearReleaseListeners()
  }
}
