'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('RadioHelper', [
    '$rootScope',
    'Querystringify',
    'BAS_TUNE_IN',
    'BAS_FAVOURITE',
    'CurrentBasCore',
    'LibraryState',
    'RadioElement',
    'BasUtilities',
    'Logger',
    RadioHelper
  ])

/**
 * Helper class for creating library objects from API responses
 *
 * @constructor
 * @param $rootScope
 * @param Querystringify
 * @param {BAS_TUNE_IN} BAS_TUNE_IN
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {CurrentBasCore} CurrentBasCore
 * @param {LibraryState} LibraryState
 * @param RadioElement
 * @param {BasUtilities} BasUtilities
 * @param Logger
 */
function RadioHelper (
  $rootScope,
  Querystringify,
  BAS_TUNE_IN,
  BAS_FAVOURITE,
  CurrentBasCore,
  LibraryState,
  RadioElement,
  BasUtilities,
  Logger
) {
  var serviceName = 'RadioHelper'

  /**
   * @type {TCurrentBasCoreState}
   */
  const currentBasCoreState = CurrentBasCore.get()

  this.apiRequest = apiRequest
  this.checkCustomStationsRequest = checkCustomStationsRequest
  this.getRequestParameters = getRequestParameters

  /**
   * @param {RadioRequest} request
   * @returns {Promise<Array<RadioElement>>}
   */
  function apiRequest (request) {
    var basSource

    Logger.debug(serviceName + ' - apiRequest', request)

    basSource = LibraryState.getCurrentSource()

    // Check Source
    if (checkBasSource(basSource)) {

      if (BasUtil.isObject(request)) {

        switch (request.path) {
          case BAS_TUNE_IN.PATH_CUSTOM_STATIONS:

            if (currentBasCoreState.core?.core?.supportsAvAudioDsp) {

              return httpRequestCustomRadios(request)
                .then(result => basSource.tunein.browseCustomStations(
                  result,
                  true
                ))
                .then(onResult, onError)
            }

            return basSource.tunein
              .browseCustomStations(request.params, true)
              .then(onResult, onError)

          default:

            return BasUtil.promiseAll([
              basSource.tunein
                .request(request.path, request.params),
              basSource.tunein
                .browseCustomStations(request.params)
            ]).then(onResults, onError)
        }

      } else {
        Logger.warn(
          serviceName + ' - apiRequest - Invalid request object',
          request
        )

        return Promise.reject('Invalid request')
      }
    } else {
      Logger.warn(serviceName + ' - apiRequest- BasSource is invalid')

      return Promise.reject('No source')
    }

    function onResults (result) {

      var arr1, arr2

      arr1 = Array.isArray(result[0].result) ? result[0].result : []
      arr2 = Array.isArray(result[1].result) ? result[1].result : []

      return onResult(arr1.concat(arr2))
    }

    function onResult (result) {
      Logger.debug(serviceName + ' - apiRequest Result', result)

      return Array.isArray(result)
        ? Promise.resolve(processRadioElements(result))
        : Promise.reject(new Error('invalid result'))
    }

    function onError (error) {
      Logger.warn(serviceName + ' - apiRequest ERROR', error)

      return Promise.reject(error)
    }
  }
  /**
   * @param {RadioRequest} request
   * @returns {Promise<Object>}
   */
  function httpRequestCustomRadios (request) {
    const path = BAS_TUNE_IN.PATH_CUSTOM_RADIOS

    const config = {}
    config.method = 'GET'
    config.path = path

    if (config) {

      return currentBasCoreState.core?.basServer?.request(config)
        .then(BasUtilities.handleHttpResponse)
        .then((result) => {

          const params = request.params
          params.httpResponse = result

          return params
        })
    } else {
      Logger.error(
        serviceName + ' - unable to get custom radios'
      )
      return Promise.reject('Invalid request')
    }
  }

  /**
   * Extract useful parameters from URL
   *
   * @param {string} tuneInUrl
   * @returns {?Object}
   */
  function getRequestParameters (tuneInUrl) {

    var parsed = BasUtilities.parseUrl(tuneInUrl)

    var params = Querystringify.parse(parsed.search)

    // Check input
    if (BasUtil.isNEString(tuneInUrl)) {

      if (BasUtil.isObject(params)) {

        // Remove unnecessary parameters
        params = BasUtil.removeKeys(
          params,
          [
            BAS_TUNE_IN.PARAM_PARTNERID,
            BAS_TUNE_IN.PARAM_SERIAL,
            BAS_TUNE_IN.PARAM_LOCALE,
            BAS_TUNE_IN.PARAM_RENDER
          ]
        )
      }
    } else {
      Logger.warn(
        'TuneIn - getRequestParameters - Invalid input',
        tuneInUrl
      )
    }

    return params
  }

  /**
   * Process an API element to create a RadioElement
   *
   * @param {Object} element
   * @returns {RadioElement|null}
   */
  function processRadioElement (element) {
    var result = null

    // Check element
    if (BasUtil.isObject(element)) {

      result = new RadioElement()

      // ID
      result.id = element[BAS_TUNE_IN.EL_KEY_GUIDE_ID]

      result.setAsanoFavouriteId(BAS_FAVOURITE.T_RADIO, result.id)

      // Title
      if (element[BAS_TUNE_IN.EL_BAS_TRANSLATION] === true) {
        result.title =
          BasUtilities.translate(element[BAS_TUNE_IN.EL_KEY_KEY])
      } else if (BasUtil.safeHasOwnProperty(element, BAS_TUNE_IN.EL_KEY_TEXT)) {
        result.title = element[BAS_TUNE_IN.EL_KEY_TEXT]
      } else if (BasUtil.safeHasOwnProperty(element, BAS_TUNE_IN.CS_KEY_NAME)) {
        result.title = element[BAS_TUNE_IN.CS_KEY_NAME]
      }

      // Type
      if (BasUtil.isNEString(element[BAS_TUNE_IN.EL_KEY_TYPE])) {
        result.setType(element[BAS_TUNE_IN.EL_KEY_TYPE])
      }

      // Check type
      switch (element[BAS_TUNE_IN.EL_KEY_TYPE]) {
        case BAS_TUNE_IN.EL_TYPE_AUDIO:

          result.setCanAsanoFavourite(true)

          break
        case BAS_TUNE_IN.EL_TYPE_LINK:

          result.setCanBrowse(true)

          break
        default:

          // Custom station
          if (element[BAS_TUNE_IN.EL_ELEMENT_KEY] ===
            BAS_TUNE_IN.EL_VAL_STATION) {

            result.setCanAsanoFavourite(true)

          } else {
            Logger.error(
              serviceName + ' - processRadioElement - Unknown type',
              element
            )
          }
      }

      // URL
      if (BasUtil.isNEString(element[BAS_TUNE_IN.EL_KEY_URL])) {
        result.setUrl(element[BAS_TUNE_IN.EL_KEY_URL])
      }

      // Cover art
      if (BasUtil.isNEString(element[BAS_TUNE_IN.EL_KEY_IMAGE])) {

        result.setCoverArt({
          small: element[BAS_TUNE_IN.EL_KEY_IMAGE],
          medium: element[BAS_TUNE_IN.EL_KEY_IMAGE],
          large: element[BAS_TUNE_IN.EL_KEY_IMAGE]
        })
      }

      // Path
      result.setPath(element[BAS_TUNE_IN.EL_BAS_PATH])

    } else {
      Logger.warn(
        serviceName + ' - processRadioElement- Invalid input',
        element
      )
    }

    return result
  }

  /**
   * Process an array of elements
   *
   * @param {Array<Object>} elements
   * @returns {Array<RadioElement>}
   */
  function processRadioElements (elements) {
    var i, length
    var tempElement
    var result = []

    // Check for array
    if (Array.isArray(elements)) {

      // Iterate all elements
      length = elements.length
      for (i = 0; i < length; i += 1) {

        // Create radio element
        tempElement = processRadioElement(elements[i])

        // Check element
        if (BasUtil.isObject(tempElement)) {
          result.push(tempElement)
        }
      }
    } else {
      Logger.warn(
        serviceName + ' - process Radio Elements- Invalid input',
        elements
      )
    }

    Logger.debug(serviceName + ' - process Radio elements RESULT', result)

    $rootScope.$applyAsync()

    return result
  }

  // Helper functions

  /**
   * Adjust the request in case of custom stations entry
   *
   * @param {RadioElement} element
   * @param {RadioRequest} request
   */
  function checkCustomStationsRequest (element, request) {
    if (isCustomStationsEntry(element) &&
      BasUtil.isObject(request)) {
      request.setPath(BAS_TUNE_IN.PATH_CUSTOM_STATIONS)
    }
  }

  /**
   * Check whether the basSource is valid and has a valid TuneIn object
   *
   * @param {BasSource} basSource
   * @returns {boolean}
   */
  function checkBasSource (basSource) {
    return (
      BasUtil.isObject(basSource) &&
      BasUtil.isObject(basSource.tunein)
    )
  }

  /**
   * Checks whether the element is a custom station entry
   *
   * @param {RadioElement} element
   * @returns {boolean}
   */
  function isCustomStationsEntry (element) {
    return (
      BasUtil.isObject(element) &&
      element.path === BAS_TUNE_IN.PATH_CUSTOM_STATIONS
    )
  }
}
