'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('musicFavouritesSceneCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_SOURCES',
    'BasSceneHelper',
    musicFavouritesSceneCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BasSceneHelper} BasSceneHelper
 */
function musicFavouritesSceneCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_SOURCES,
  BasSceneHelper
) {
  var favourites = this

  favourites.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BAS_SOURCES}
   */
  favourites.BAS_SOURCES = BAS_SOURCES

  // TODO: Future improvement: keep this variable per collection
  favourites.isLoadingPage = false

  favourites.selectFavourite = selectFavourite

  /**
   * @param {(string|null)} favouriteId
   */
  function selectFavourite (favouriteId) {

    var _step, _source, _favourite

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (BasUtil.isNEString(favouriteId) &&
      _step &&
      _step.isValid()) {

      _source = _getSource()

      if (BasUtil.isObject(_source) && _source.favourites) {

        _favourite = _source.favourites.getFavourite(favouriteId)

        if (_favourite) {

          _step.setMusicFavourite(_favourite.pbId)
        }
      }

    } else if (favouriteId === null) {

      _step.setMusicFavourite('')
    }
  }

  /**
   * @returns {?BasSource}
   */
  function _getSource () {

    var _step, _sourceId

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.music) {

      _sourceId = _step.music.source

      return favourites.BAS_SOURCES.SOURCES.sources[_sourceId]
    }

    return null
  }
}
