'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSchedulerPoint', basSchedulerPointFactory)

function basSchedulerPointFactory () {

  /**
   * @constructor
   */
  function BasSchedulerPoint () {

    /**
     * @type {number}
     */
    this.x = 0

    /**
     * @type {number}
     */
    this.y = 0

    /**
     * @type {boolean}
     */
    this.new = false

    /**
     * @type {string}
     */
    this.uiValue = ''

    /**
     * @type {string}
     */
    this.uiSimpleValue = ''

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this._resetCss()
  }

  /**
   * Lower x value comes first
   * Invalid points get a lower index
   *
   * @param {BasSchedulerPoint} p1
   * @param {BasSchedulerPoint} p2
   * @returns {number}
   */
  BasSchedulerPoint.compareX = function (p1, p2) {

    if (BasUtil.isObject(p1) &&
      BasUtil.isVNumber(p1.x) &&
      BasUtil.isObject(p2) &&
      BasUtil.isVNumber(p2.x)) {

      return p1.x - p2.x

    } else if (BasUtil.isObject(p1) && BasUtil.isVNumber(p1.x)) {

      return 1

    } else if (BasUtil.isObject(p2) && BasUtil.isVNumber(p2.x)) {

      return -1
    }

    return 0
  }

  /**
   * @param {number} [x]
   * @param {number} [_step]
   * @returns {string}
   */
  BasSchedulerPoint.prototype.getUiValueXFor = function (
    x,
    _step
  ) {
    var _x

    _x = BasUtil.isVNumber(x) ? x : this.x

    return '' + _x
  }

  /**
   * @param {number} y
   * @returns {string}
   */
  BasSchedulerPoint.prototype.getUiValueYFor = function (y) {

    return '' + y
  }

  /**
   * @param {number} x
   */
  BasSchedulerPoint.prototype.setXValue = function (x) {

    this.x = x
  }

  /**
   * @param {number} y
   */
  BasSchedulerPoint.prototype.setYValue = function (y) {

    this.y = y
  }

  /**
   * @returns {BasSchedulerPoint}
   */
  BasSchedulerPoint.prototype.clone = function () {

    var result

    result = new BasSchedulerPoint()
    this.copyPropertiesTo(result)

    return result
  }

  /**
   * @param {Object} destination
   */
  BasSchedulerPoint.prototype.copyPropertiesTo = function (destination) {

    if (BasUtil.isObject(destination)) {

      destination.x = this.x
      destination.y = this.y
      destination.uiValue = this.uiValue
      destination.uiSimpleValue = this.uiSimpleValue
      destination.css = BasUtil.copyObjectShallow(this.css)
    }
  }

  BasSchedulerPoint.prototype.updateTranslation = function () {

    // Empty
  }

  BasSchedulerPoint.prototype._resetCss = function () {

    // Empty
  }

  return BasSchedulerPoint
}
