'use strict'

/**
 * @typedef {Object} TBasSupports
 * @property {boolean} requestAnimationFrame
 * @property {boolean} passiveListeners
 * @property {boolean} fetch
 * @property {boolean} mediaDevices
 * @property {boolean} rtcPeerConnection
 * @property {boolean} intl
 * @property {boolean} localeTimeStringLocalesOptions
 */

module.exports.initBasSupports = initBasSupports

function initBasSupports (basWindow) {

  var basSupportsState

  /**
   * @type {TBasSupports}
   */
  basWindow.basSupportResult = basSupportsState = {
    requestAnimationFrame: false,
    passiveListeners: false,
    fetch: false,
    mediaDevices: false,
    rtcPeerConnection: false,
    intl: false,
    localeTimeStringLocalesOptions: false
  }

  _checkSupport()

  function _checkSupport () {

    _checkRequestAnimationFrame()
    _checkPassiveListeners()
    _checkFetch()
    _checkMediaDevices()
    _checkRTCPeerConnection()
    _checkIntl()
    _checkLocalTimeStringLocalesOptions()
  }

  function _checkRequestAnimationFrame () {
    basSupportsState.requestAnimationFrame =
      typeof basWindow.requestAnimationFrame === 'function'
  }

  function _checkPassiveListeners () {
    var options

    options = {}

    Object.defineProperty(options, 'passive', {
      get: function () {

        basSupportsState.passiveListeners = true
        return false
      }
    })

    try {

      basWindow.addEventListener('test', null, options)
      basWindow.removeEventListener('test', null, options)

    } catch (e) {

      basSupportsState.passiveListeners = false
    }
  }

  function _checkFetch () {
    basSupportsState.fetch = (
      'fetch' in basWindow
    )
  }

  function _checkMediaDevices () {
    basSupportsState.mediaDevices = (
      'mediaDevices' in basWindow.navigator &&
      'getUserMedia' in basWindow.navigator.mediaDevices
    )
  }

  function _checkRTCPeerConnection () {
    basSupportsState.rtcPeerConnection = (
      'RTCPeerConnection' in basWindow
    )
  }

  function _checkIntl () {
    basSupportsState.intl =
      !!(typeof basWindow.Intl === 'object' && basWindow.Intl)
  }

  function _checkLocalTimeStringLocalesOptions () {
    try {
      new Date().toLocaleTimeString('i')
    } catch (e) {
      if (e && e.name === 'RangeError') {
        basSupportsState.localeTimeStringLocalesOptions = true
        return true
      }
    }
    basSupportsState.localeTimeStringLocalesOptions = false
  }
}
