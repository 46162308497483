'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasEq', [
    basEqFactory
  ])

/**
 * @returns BasEq
 */
function basEqFactory () {

  /**
   * @constructor
   * @param {TRoomEqualiser} equaliser
   */
  function BasEq (equaliser) {

    /**
     * @type {number}
     */
    this.id = 0

    /**
     * @type {number}
     */
    this.frequency = 0

    /**
     * @type {number}
     */
    this.gain = 0

    if (equaliser) this.parseEqualiser(equaliser)
  }

  /**
   * @param {BasEq} a
   * @param {BasEq} b
   * @returns {number}
   */
  BasEq.compareEqualisersByFrequencies = function (a, b) {

    var _a, _b

    _a = a ? a.frequency : undefined
    _b = b ? b.frequency : undefined

    if (BasUtil.isVNumber(_a) && BasUtil.isVNumber(_b)) {
      return _a - _b
    } else if (BasUtil.isVNumber(_a)) {
      return -1
    } else if (BasUtil.isVNumber(_b)) {
      return 1
    }
    return 0
  }

  /**
   * @param {BasEq[]} equalisers1
   * @param {BasEq[]} equalisers2
   * @returns {boolean}
   */
  BasEq.isEqualEqualisers = function (
    equalisers1,
    equalisers2
  ) {
    var i, length, eq1, eq2

    if (
      Array.isArray(equalisers1) &&
        Array.isArray(equalisers2) &&
        equalisers1.length === equalisers2.length
    ) {
      length = equalisers1.length
      for (i = 0; i < length; i++) {
        eq1 = equalisers1[i]

        if (eq1) {

          eq2 = this.getEqualiserById(equalisers2, eq1.id)

          if (eq2) {

            if (eq1.gain !== eq2.gain) return false

          } else {

            return false
          }
        }
      }

      return true
    }

    return false
  }

  /**
   * @param {BasEq[]} equalisers
   * @param {number} id
   * @returns {?BasEq}
   */
  BasEq.getEqualiserById = function (equalisers, id) {

    return equalisers.find(e => e.id === id)
  }

  /**
   * Parse the raw equaliser from api
   *
   * @param {TRoomEqualiser} equaliser
   */
  BasEq.prototype.parseEqualiser = function (equaliser) {

    if (equaliser) {

      if (BasUtil.isPNumber(equaliser.id, true)) {
        this.id = equaliser.id
      }

      if (BasUtil.isPNumber(equaliser.frequency)) {
        this.frequency = equaliser.frequency
      }

      if (BasUtil.isVNumber(equaliser.gain)) {
        this.gain = equaliser.gain
      }
    }
  }

  /**
   * @returns {TRoomEqualiser} obj
   */
  BasEq.prototype.getApiData = function () {
    return {
      id: this.id,
      gain: this.gain
    }
  }

  return BasEq
}
