'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceTemperature', [
    'BAS_HTML',
    deviceTemperature
  ])

function deviceTemperature (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.temperatureDeviceSettings,
    controller: [
      '$rootScope',
      '$scope',
      'BAS_API',
      'BAS_INPUT_MODAL',
      'BAS_PREFERENCES',
      'BasPreferences',
      'BasInputModal',
      'BasUtilities',
      controller
    ],
    controllerAs: 'temperature'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param BAS_API
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BasPreferences} BasPreferences
   * @param {BasInputModal} BasInputModal
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    $scope,
    BAS_API,
    BAS_INPUT_MODAL,
    BAS_PREFERENCES,
    BasPreferences,
    BasInputModal,
    BasUtilities
  ) {
    var temperature = this

    var listeners = []

    /**
     * @type {TBasModalInputSelectOption[]}
     */
    temperature.TEMPERATURE_UNIT_OPTIONS = [
      {
        value: BAS_PREFERENCES.DEF_TEMP_UNIT,
        text: 'server',
        translate: true
      },
      {
        value: BAS_API.CONSTANTS.TU_CELSIUS,
        text: 'celsius',
        translate: true
      },
      {
        value: BAS_API.CONSTANTS.TU_FAHRENHEIT,
        text: 'fahrenheit',
        translate: true
      }
    ]

    /**
     * @type {string}
     */
    temperature.temperatureUnit = BasPreferences.getTemperatureUnit()

    /**
     * @type {string}
     */
    temperature.uiTemperatureUnit = '-'

    temperature.showEditTemperatureUnit = showEditTemperatureUnit

    init()

    function init () {

      $scope.$on('$destroy', _onDestroy)

      _syncUiTemperatureUnit()

      listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        _onLanguageChanged
      ))
    }

    function showEditTemperatureUnit () {

      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'temperature',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            options: temperature.TEMPERATURE_UNIT_OPTIONS,
            initialValue: temperature.temperatureUnit
          }
        ],
        autoSave: true,
        autoAdvance: true,
        allowDismiss: true,
        validCheck: BasUtil.isNEArray
      }).then(onModal)

      function onModal (modal) {

        modal.close.then(function (result) {

          if (BasUtil.isNEArray(result)) {

            temperature.temperatureUnit = result[0]
            BasPreferences.setTemperatureUnit(temperature.temperatureUnit)
            _syncUiTemperatureUnit()
          }
        })
      }
    }

    function _syncUiTemperatureUnit () {

      switch (temperature.temperatureUnit) {
        case BAS_PREFERENCES.DEF_TEMP_UNIT:
          temperature.uiTemperatureUnit = BasUtilities.translate('server')
          break
        case BAS_API.CONSTANTS.TU_CELSIUS:
          temperature.uiTemperatureUnit = BasUtilities.translate('celsius')
          break
        case BAS_API.CONSTANTS.TU_FAHRENHEIT:
          temperature.uiTemperatureUnit =
            BasUtilities.translate('fahrenheit')
          break
        default:
          temperature.uiTemperatureUnit = '-'
      }
    }

    function _onLanguageChanged () {

      _syncUiTemperatureUnit()
    }

    function _onDestroy () {

      BasUtil.executeArray(listeners)
      listeners = []
    }
  }
}
