'use strict'

var EventEmitter = require('@gidw/event-emitter-js')
var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var BasRequest = require('./bas_request')

var log = require('./logger')

/**
 * @typedef {Object} TBasRequestOptions
 * @since 1.6.0
 * @property {number} [timeout]
 * @property {number} [retries]
 * @property {number} [multiplier] Multiplies previous timeout
 * @property {number} [totalTimeout] Total timeout (including retries)
 */

/**
 * @param {string} path
 * @constructor
 * @extends EventEmitter
 * @since 2.0.0
 */
function BasCoreSocket (path) {

  EventEmitter.call(this)

  this._timeout = BasCoreSocket.DEFAULT_TIMEOUT
  this._requestId = 0

  this._path = path

  /**
   * @private
   * @type {Object<string, ?BasRequest>}
   */
  this._requests = {}

  this._heartBeatEnabled = false
  this._heartbeatIntervalId = 0
  this._missedHeartbeatCount = 0

  this._doHeartbeat = this._heartbeat.bind(this)
  this._handleHeartbeat = this._onHeartbeat.bind(this)
  this._handlHeartbeatError = this._onHeartbeatError.bind(this)
}

BasCoreSocket.prototype = Object.create(EventEmitter.prototype)
BasCoreSocket.prototype.constructor = BasCoreSocket

/**
 * @event BasCoreSocket#EVT_CONNECTED
 * @param {boolean} isConnected
 */

/**
 * @event BasCoreSocket#EVT_MESSAGE
 * @param {Object} message
 */

/**
 * @constant {string}
 */
BasCoreSocket.EVT_CONNECTED = 'evtBasCoreSocketConnected'

/**
 * @constant {string}
 */
BasCoreSocket.EVT_MESSAGE = 'evtBasCoreSocketMessage'

/**
 * @constant {string}
 */
BasCoreSocket.EVT_JWT_REVOKED = 'evtBasCoreSocketJWTRevoked'

/**
 * @constant {string}
 */
BasCoreSocket.EVT_HEARTBEAT = 'evtBasCoreSocketHeartbeat'

/**
 * @constant {string}
 */
BasCoreSocket.EVT_HEARTBEAT_MISSED = 'evtBasCoreSocketHeartbeatMissed'

/**
 * @constant {number}
 */
BasCoreSocket.DEFAULT_TIMEOUT = 5000

/**
 * C++ limit for request IDs
 *
 * @constant {number}
 */
BasCoreSocket.MAX_REQUEST_ID = 65536

/**
 * Interval in ms for heartbeats
 *
 * @constant {number}
 */
BasCoreSocket.HEARTBEAT_INTERVAL = 5000

/**
 * Timeout for heartbeat requests
 *
 * @constant {number}
 */
BasCoreSocket.HEARTBEAT_TIMEOUT = 2000

/**
 * Maximum number of failed heartbeats before disconnecting
 *
 * @constant {number}
 */
BasCoreSocket.HEARTBEAT_MAX_LOSS = 3

/**
 * @constant {Object}
 */
BasCoreSocket._HEARTBEAT_MSG = {}
BasCoreSocket._HEARTBEAT_MSG[P.PING] = true

/**
 * @constant {number}
 */
BasCoreSocket.STATUSCODE_JWT_REVOKED = 4001

/**
 * Check if the socket is connected
 *
 * @abstract
 * @returns {boolean}
 */
BasCoreSocket.prototype.isConnected = function () {}

/**
 * @param {TCoreCredentials} [credentials]
 * @returns {Promise}
 */
BasCoreSocket.prototype.open = function (credentials) {

  return this.openConnection(this._path, credentials)
}

/**
 * @abstract
 * @param {string} path
 * @param {TCoreCredentials} [credentials]
 * @returns {Promise}
 */
BasCoreSocket.prototype.openConnection = function (
  // eslint-disable-next-line no-unused-vars
  path,
  // eslint-disable-next-line no-unused-vars
  credentials
) {

  return Promise.reject(CONSTANTS.ERR_NOT_IMPLEMENTED)
}

/**
 * @abstract
 * @returns {Promise}
 */
BasCoreSocket.prototype.closeConnection = function () {

  return Promise.reject(CONSTANTS.ERR_NOT_IMPLEMENTED)
}

/**
 * Close the socket, if open this will emit an event
 *
 * @returns {Promise}
 */
BasCoreSocket.prototype.close = function () {

  this.clearHeartbeat()

  this._rejectRequests()

  return this.closeConnection()
}

/**
 * @private
 * @param {boolean} isConnected
 */
BasCoreSocket.prototype._emitConnected = function (isConnected) {

  this.emit(BasCoreSocket.EVT_CONNECTED, isConnected)
}

/**
 * @private
 * @param {Object} message
 */
BasCoreSocket.prototype._emitMessage = function (message) {

  this.emit(BasCoreSocket.EVT_MESSAGE, message)
}

/**
 * @private
 */
BasCoreSocket.prototype._emitJWTRevoked = function () {

  this.emit(BasCoreSocket.EVT_JWT_REVOKED)
}

/**
 * Returns whether or not the data was sent.
 *
 * @abstract
 * @param {Object} data
 * @returns {boolean}
 */
// eslint-disable-next-line no-unused-vars
BasCoreSocket.prototype.sendData = function (data) {

  // Empty
}

/**
 * @param {Object} obj
 * @returns {boolean}
 */
// eslint-disable-next-line no-unused-vars
BasCoreSocket.prototype.isValidIncomingMessage = function (obj) {

  return true
}

/**
 * Process a (JSON parsed) message from a server.
 *
 * @param {Object} obj
 */
BasCoreSocket.prototype.onIncomingMessage = function (obj) {

  var request

  if (!obj[P.PONG]) log.debug('IN:', obj)

  if (this.isValidIncomingMessage(obj)) {

    if (BasUtil.isPNumber(obj[P.ID], true)) {

      request = this._requests[obj[P.ID]]

      if (request &&
        !request.resolved) {

        if (obj[P.PONG] === true) {

          // Heartbeat message

        } else {

          // Non-heartbeat message, server is still connected
          this.clearHeartbeat()
        }

        request.resolved = true
        request.resolve(obj)

        this._requests[obj[P.ID]] = null
      }

    } else {

      // Non-heartbeat message, server is still connected
      this.clearHeartbeat()

      this._emitMessage(obj)
    }

    if (this._heartBeatEnabled) {

      this._startHeartBeat()
    }
  }
}

/**
 * @param {Object} data
 * @returns {boolean}
 */
BasCoreSocket.prototype.send = function (data) {

  if (!data[P.PING]) log.debug('OUT:', data)

  return this.sendData(data)
}

/**
 * @param {Object} data
 * @param {number} [timeout] Default timeout applies
 * @returns {Promise}
 */
BasCoreSocket.prototype.request = function (data, timeout) {

  var _this, requestId, _timeout

  if (BasUtil.isObject(data)) {

    _this = this

    _timeout = BasUtil.isPNumber(timeout, true)
      ? timeout
      : this._timeout

    requestId = data[P.ID] = this._requestId

    this._requestId++

    if (this._requestId > BasCoreSocket.MAX_REQUEST_ID) this._requestId = 0

    return _timeout > 0
      ? Promise.race([
        new Promise(requestPromise),
        BasUtil.timeout(_timeout).then(onTimeout)
      ])
      : new Promise(requestPromise)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)

  function requestPromise (resolve, reject) {

    _this._requests[requestId] = new BasRequest(resolve, reject)

    if (!_this.send(data)) {

      // Remove request
      _this._requests[requestId] = null

      reject(CONSTANTS.ERR_CONNECTION)
    }
  }

  function onTimeout () {

    if (_this._requests[requestId]) {

      // Remove request
      _this._requests[requestId] = null

      return Promise.reject(CONSTANTS.ERR_TIMEOUT)
    }
  }
}

/**
 * @param {Object} data
 * @param {TBasRequestOptions} [options]
 * @returns {Promise}
 */
BasCoreSocket.prototype.requestRetry = function (data, options) {

  var _this, _timeout, _retries, _multiplier, _totalTimeout, _allowRequest

  if (this.isConnected()) {

    if (BasUtil.isObject(data)) {

      _this = this

      _timeout = CONSTANTS.RETRY_OPTS_DEFAULT.timeout
      _retries = CONSTANTS.RETRY_OPTS_DEFAULT.retries
      _multiplier = CONSTANTS.RETRY_OPTS_DEFAULT.multiplier
      _totalTimeout = CONSTANTS.RETRY_OPTS_DEFAULT.totalTimeout

      if (BasUtil.isObject(options)) {

        if (BasUtil.isPNumber(options.timeout)) {
          _timeout = options.timeout
        }

        if (BasUtil.isPNumber(options.retries, true)) {
          _retries = options.retries
        }

        if (BasUtil.isPNumber(options.multiplier)) {
          _multiplier = options.multiplier
        }

        if (BasUtil.isPNumber(options.totalTimeout)) {
          _totalTimeout = options.totalTimeout
        }
      }

      _allowRequest = true

      return _totalTimeout > 0
        ? Promise.race([
          new Promise(requestPromise),
          BasUtil.timeout(_totalTimeout).then(onTotalTimeout)
        ])
        : new Promise(requestPromise)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  return Promise.reject(CONSTANTS.ERR_CONNECTION)

  function requestPromise (resolve, reject) {

    var count, currentTimeout

    count = 0
    currentTimeout = _timeout

    _this.request(data, currentTimeout).then(resolve, onError)

    function onError (error) {

      if (_allowRequest) {

        if (error === CONSTANTS.ERR_TIMEOUT) {

          if (count < _retries) {

            count++

            currentTimeout *= _multiplier

            _this.request(data, currentTimeout)
              .then(resolve, onError)

          } else {

            // Maximum retries exceeded
            reject(CONSTANTS.ERR_TIMEOUT)
          }

        } else {

          // No timeout error
          reject(error)
        }

      } else {

        reject(CONSTANTS.ERR_TIMEOUT)
      }
    }
  }

  function onTotalTimeout () {

    _allowRequest = false

    return Promise.reject(CONSTANTS.ERR_TIMEOUT)
  }
}

/**
 * Perform heartbeat request
 *
 * @private
 */
BasCoreSocket.prototype._heartbeat = function () {

  if (this._missedHeartbeatCount < BasCoreSocket.HEARTBEAT_MAX_LOSS) {

    this.request(
      BasCoreSocket._HEARTBEAT_MSG,
      BasCoreSocket.HEARTBEAT_TIMEOUT
    ).then(this._handleHeartbeat, this._handlHeartbeatError)

  } else {

    this.close()
  }
}

/**
 * Resets the heartbeat count
 *
 * @private
 */
BasCoreSocket.prototype._onHeartbeat = function () {

  this._missedHeartbeatCount = 0

  this.emit(BasCoreSocket.EVT_HEARTBEAT)
}

/**
 * @private
 * @param {*} error
 */
BasCoreSocket.prototype._onHeartbeatError = function (error) {

  log.warn('Heartbeat missed', error)

  this._missedHeartbeatCount++

  this.emit(BasCoreSocket.EVT_HEARTBEAT_MISSED)

  if (this._missedHeartbeatCount >= BasCoreSocket.HEARTBEAT_MAX_LOSS) {

    this.close()
  }
}

/**
 * @param {boolean} start
 */
BasCoreSocket.prototype.heartbeat = function (start) {

  this._heartBeatEnabled = !!start

  if (start) {

    this._startHeartBeat()

  } else {

    this.clearHeartbeat()
  }
}

BasCoreSocket.prototype._startHeartBeat = function () {

  if (!this._heartbeatIntervalId) {

    this._heartbeatIntervalId = setInterval(
      this._doHeartbeat,
      BasCoreSocket.HEARTBEAT_INTERVAL
    )
  }
}

/**
 * Stop heartbeat and reset counter
 */
BasCoreSocket.prototype.clearHeartbeat = function () {

  clearInterval(this._heartbeatIntervalId)
  this._heartbeatIntervalId = 0
  this._missedHeartbeatCount = 0
}

/**
 * Reject all remaining requests
 *
 * @private
 */
BasCoreSocket.prototype._rejectRequests = function () {

  var keys, i, length, request

  keys = Object.keys(this._requests)
  length = keys.length
  for (i = 0; i < length; i++) {

    request = this._requests[keys[i]]

    if (request && !request.resolved) {

      request.reject(CONSTANTS.ERR_CONNECTION)
    }
  }

  this._requests = {}
}

BasCoreSocket.prototype.destroy = function () {

  this.close()
}

module.exports = BasCoreSocket
