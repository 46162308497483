'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasUnitValue', [
    'BAS_UNITS',
    basUnitValueFactory
  ])

/**
 * @param {BAS_UNITS} BAS_UNITS
 * @returns BasUnitValue
 */
function basUnitValueFactory (
  BAS_UNITS
) {
  /**
   * @constructor
   * @param {Object} [options]
   * @param {string|number} [options.value]
   * @param {string} [options.unit]
   * @param {number} [options.fixed]
   */
  function BasUnitValue (options) {

    /**
     * @type {string}
     */
    this.value = ''

    /**
     * @type {string}
     */
    this.unit = ''

    /**
     * @type {string}
     */
    this.stringValue = ''

    if (BasUtil.isObject(options)) {

      if (BasUtil.isNEString(options.value)) {

        this.value = options.value

      } else if (BasUtil.isNumber(options.value)) {

        if (BasUtil.isNumber(options.fixed)) {

          if (options.fixed === 0) {

            this.value = '' + Math.round(options.value)

          } else {

            this.value = options.value.toFixed(options.fixed)
          }

        } else {

          this.value = '' + options.value
        }
      }

      if (BasUtil.isNEString(options.unit)) {

        this.unit = options.unit
      }

      this.stringValue = this.value + BasUnitValue._addUnit(this.unit)
    }
  }

  /**
   * Add leading space if needed
   *
   * @param {string} unit
   * @returns {string}
   */
  BasUnitValue._addUnit = function (unit) {

    var stringValue
    stringValue = ''

    if (BasUtil.isNEString(unit)) {

      switch (unit) {
        case BAS_UNITS.KM_H:
        case BAS_UNITS.HPA:
        case BAS_UNITS.MM_H:
        case BAS_UNITS.LUX:

          stringValue = ' ' + unit
          break

        default:

          stringValue = '' + unit
          break
      }
    }

    return stringValue
  }

  return BasUnitValue
}
