'use strict'

angular
  .module('basalteApp')
  .service('BasNetwork', [
    '$window',
    '$rootScope',
    'BAS_APP',
    BasNetwork
  ])

/**
 * @constructor
 * @param $window
 * @param $rootScope
 * @param {BAS_APP} BAS_APP
 */
function BasNetwork (
  $window,
  $rootScope,
  BAS_APP
) {
  this.onLine = onLine

  init()

  function init () {

    $window.addEventListener('offline', _onOffline)
    $window.addEventListener('online', _onOnline)
  }

  function onLine () {

    return $window.navigator.onLine
  }

  function _onOffline () {

    $rootScope.$emit(BAS_APP.EVT_NETWORK_OFFLINE, onLine())
  }

  function _onOnline () {

    $rootScope.$emit(BAS_APP.EVT_NETWORK_ONLINE, onLine())
  }
}
