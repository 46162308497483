'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('DeezerCollection', [
    '$rootScope',
    'BAS_LIBRARY_ERRORS',
    'BAS_DEEZER',
    'LibraryState',
    'DeezerModel',
    'BasLibraryCollection',
    'BasUtilities',
    'Logger',
    DeezerCollectionFactory])

/**
 * @param $rootScope
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {BAS_DEEZER} BAS_DEEZER
 * @param {LibraryState} LibraryState
 * @param {DeezerModel} DeezerModel
 * @param BasLibraryCollection
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns DeezerCollection
 */
function DeezerCollectionFactory (
  $rootScope,
  BAS_LIBRARY_ERRORS,
  BAS_DEEZER,
  LibraryState,
  DeezerModel,
  BasLibraryCollection,
  BasUtilities,
  Logger
) {
  var className = 'Deezer Collection'

  /**
   * @constructor
   * @extends BasLibraryCollection
   * @param {Function} handler
   */
  function DeezerCollection (handler) {

    BasLibraryCollection.call(this, handler)

    /**
     * @instance
     * @type {string}
     */
    this.type = ''

    /**
     * @instance
     * @type {string}
     */
    this.contentType = DeezerCollection.CT_TRACK

    /**
     * @instance
     * @type {Object}
     */
    this.lastPagedResult = null

    /**
     * @type {number}
     */
    this.limit = BAS_DEEZER.LIMIT_DEFAULT

    /**
     * @type {(number|string)}
     */
    this.id = 0

    /**
     * @type {number}
     */
    this.offset = 0

    /**
     * @type {?Promise}
     * @private
     */
    this._allElementsPromise = null

    this.handleError = this.onRetrieveError.bind(this)
    this.handleDeezerItems = this.onDeezerItems.bind(this)
    this.handleConcatElements = this.concatElements.bind(this)
    this.handleReplaceElements = this.replaceElements.bind(this)
  }

  // Set new prototype from inherited object
  DeezerCollection.prototype =
    Object.create(BasLibraryCollection.prototype)

  // Set correct constructor after setting new prototype
  DeezerCollection.prototype.constructor = DeezerCollection

  // region Static constants

  /**
   * @constant {string}
   */
  DeezerCollection.TYPE_ALBUM = 'album'

  /**
   * @constant {string}
   */
  DeezerCollection.TYPE_USER = 'user'

  /**
   * @constant {string}
   */
  DeezerCollection.TYPE_GENRE = 'genre'

  /**
   * @constant {string}
   */
  DeezerCollection.TYPE_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  DeezerCollection.TYPE_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  DeezerCollection.TYPE_SEARCH = 'search'

  /**
   * @constant {string}
   */
  DeezerCollection.CT_ALBUM = 'album'

  /**
   * @constant {string}
   */
  DeezerCollection.CT_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  DeezerCollection.CT_GENRE = 'genre'

  /**
   * @constant {string}
   */
  DeezerCollection.CT_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  DeezerCollection.CT_RADIO = 'radio'

  /**
   * @constant {string}
   */
  DeezerCollection.CT_TRACK = 'track'

  /**
   * @constant {string}
   */
  DeezerCollection.FILTER_GENRE_ALL = 'genreAll'

  /**
   * @constant {string}
   */
  DeezerCollection.FILTER_PLAYLIST_LOVED_TRACKS = 'playlistLovedTracks'

  // endregion

  // Methods

  /**
   * @param {string} type
   * @param {boolean} [setTitle]
   */
  DeezerCollection.prototype.setContentType = function (
    type,
    setTitle
  ) {
    var _this = this

    // Set type
    this.contentType = type

    // Check type
    switch (type) {
      case DeezerCollection.CT_ALBUM:

        setTitleChecked('albums')

        break
      case DeezerCollection.CT_ARTIST:

        setTitleChecked('artists')

        break
      case DeezerCollection.CT_GENRE:

        setTitleChecked('genres')

        break
      case DeezerCollection.CT_PLAYLIST:

        setTitleChecked('playlists')

        break
      case DeezerCollection.CT_RADIO:

        setTitleChecked('mixes')

        break
      case DeezerCollection.CT_TRACK:

        setTitleChecked('songs')

        break
      default:

        // Reset type
        this.contentType = DeezerCollection.CT_TRACK

        Logger.warn(
          className +
          ' - setType' +
          ' - Unknown type',
          type
        )
    }

    function setTitleChecked (str) {

      if (setTitle === true) _this.setTitleId(str)
    }
  }

  /**
   * Set elements for this collection.
   *
   * @param {Array<BasLibraryElement>} elements
   */
  DeezerCollection.prototype.setElements = function (elements) {

    this.elements = Array.isArray(elements) ? elements : []
  }

  /**
   * @returns {?Function}
   */
  DeezerCollection.prototype.retrieveFunction = function () {

    var basSource = LibraryState.getDeezerSource()
    var deezer, params

    if (!basSource) return null

    deezer = basSource.deezer

    switch (this.contentType) {
      case DeezerCollection.CT_GENRE:
        return deezer.getGenresLib.bind(deezer)
      case DeezerCollection.CT_PLAYLIST:
        switch (this.type) {
          case DeezerCollection.TYPE_USER:
            return deezer.getUserPlaylistsLib.bind(deezer)
          case DeezerCollection.TYPE_GENRE:
            return deezer.getGenrePlaylistsLib
              .bind(deezer, this.id)
          case DeezerCollection.TYPE_ARTIST:
            return deezer.getArtistPlaylistsLib
              .bind(deezer, this.id)
          case DeezerCollection.TYPE_SEARCH:
            params = { q: this.id }
            return deezer.searchLib
              .bind(deezer, 'playlist', params)
        }
        break
      case DeezerCollection.CT_ARTIST:
        switch (this.type) {
          case DeezerCollection.TYPE_USER:
            return deezer.getUserArtistsLib.bind(deezer)
          case DeezerCollection.TYPE_GENRE:
            return deezer.getGenreArtistsLib.bind(deezer, this.id)
          case DeezerCollection.TYPE_SEARCH:
            params = { q: this.id }
            return deezer.searchLib.bind(deezer, 'artist', params)
        }
        break
      case DeezerCollection.CT_ALBUM:
        switch (this.type) {
          case DeezerCollection.TYPE_USER:
            return deezer.getUserAlbumsLib.bind(deezer)
          case DeezerCollection.TYPE_GENRE:
            return deezer.getGenreAlbumsLib.bind(deezer, this.id)
          case DeezerCollection.TYPE_ARTIST:
            return deezer.getArtistAlbumsLib.bind(deezer, this.id)
          case DeezerCollection.TYPE_SEARCH:
            params = { q: this.id }
            return deezer.searchLib.bind(deezer, 'album', params)
        }
        break
      case DeezerCollection.CT_RADIO:
        switch (this.type) {
          case DeezerCollection.TYPE_USER:
            return deezer.getUserRadiosLib.bind(deezer)
          case DeezerCollection.TYPE_GENRE:
            return deezer.getGenreRadiosLib.bind(deezer, this.id)
          case DeezerCollection.TYPE_SEARCH:
            params = { q: this.id }
            return deezer.searchLib.bind(deezer, 'radio', params)
        }
        break
      case DeezerCollection.CT_TRACK:
        switch (this.type) {
          case DeezerCollection.TYPE_ALBUM:
            return deezer.getAlbumSongsLib.bind(deezer, this.id)
          case DeezerCollection.TYPE_PLAYLIST:
            return deezer.getPlaylistSongsLib.bind(deezer, this.id)
          case DeezerCollection.TYPE_USER:
            return deezer.getUserTracksLib.bind(deezer)
          case DeezerCollection.TYPE_GENRE:
            return deezer.getGenreTracksLib.bind(deezer, this.id)
          case DeezerCollection.TYPE_ARTIST:
            return deezer.getArtistTracksLib.bind(deezer, this.id)
          case DeezerCollection.TYPE_SEARCH:
            params = { q: this.id }
            return deezer.searchLib.bind(deezer, 'track', params)
        }
        break
    }

    return null

  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   * @returns {Promise}
   */
  DeezerCollection.prototype.itemReorder = function (
    spliceIndex,
    originalIndex
  ) {
    var length, i, order, basSource, id

    // Indices are the same so basically a null operation
    if (spliceIndex === originalIndex) return Promise.resolve()

    // Get playlist uuid
    if (BasUtil.isObject(this.detailElement)) {

      id = this.detailElement.id

    } else {

      return Promise.reject(BasLibraryCollection.NO_UUID)
    }

    order = []

    length = this.elements.length
    for (i = 0; i < length; i++) {

      order.push(this.elements[i].id)
    }

    // Get player reference
    basSource = LibraryState.getDeezerSource()

    // Check if player and Deezer is valid
    if (BasUtil.isObject(basSource) &&
      basSource.deezer.isLinked()) {

      return basSource.deezer.reorderPlaylistSongs(id, order)
    }

    return Promise.reject(BasLibraryCollection.NO_PLAYER)
  }

  /**
   * @param {number} index
   * @returns {Promise}
   */
  DeezerCollection.prototype.itemSwipe = function (index) {
    var songs, basSource, id

    // Get playlist uuid
    if (BasUtil.isObject(this.detailElement)) {
      id = this.detailElement.id
    } else {
      return Promise.reject(BasLibraryCollection.NO_UUID)
    }

    if (this.elements[index]) songs = [this.elements[index].id]

    // Get player reference
    basSource = LibraryState.getDeezerSource()

    // Check if player and Deezer is valid
    if (BasUtil.isObject(basSource) &&
      basSource.deezer.isLinked()) {

      return basSource.deezer.removePlaylistSongs(id, songs)
        .then(this.handlePlaylists)
    }

    return Promise.reject(BasLibraryCollection.NO_PLAYER)
  }

  DeezerCollection.prototype.refresh = function () {

    var _this, basSource, func, offset

    _this = this

    basSource = LibraryState.getDeezerSource()
    func = this.retrieveFunction()
    offset = this.offset

    if (
      func &&
      basSource &&
      basSource.deezer &&
      basSource.deezer.isLinked &&
      basSource.deezer.isLinked()
    ) {
      this.isFetching = true
      this.hasReachedEnd = false

      return basSource.deezer.getXElements(func, this.limit, 0, offset)
        .then(onSuccess)
        .then(this.handleDeezerItems, this.handleError)
        .then(this.handleReplaceElements)
        .catch(onFail)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_RETRIEVE_FUNCTION)

    function onSuccess (result) {

      if (result && result.data) _this.offset = result.data.length

      return result
    }

    function onFail () {
      _this.hasReachedEnd = false
      _this.isFetching = false
    }
  }

  /**
   * @returns {Promise}
   */
  DeezerCollection.prototype.retrieveAll = function () {

    var _this, func, basSource

    _this = this

    if (this._allElementsPromise) return this._allElementsPromise
    if (this.hasReachedEnd) return Promise.resolve()

    if (!this.isFetching) {

      func = this.retrieveFunction()
      basSource = LibraryState.getDeezerSource()

      // Check if function is valid
      if (func) {

        // Set fetching state
        this.isFetching = true
        this.hasReachedEnd = true

        // Reset limit and offset
        this.limit = BAS_DEEZER.LIMIT_MAX
        this.offset = 0

        this._allElementsPromise =
          basSource.deezer.getXElements(func, this.limit, 0, -1)
            .then(onSuccess)
            .then(this.handleDeezerItems, this.handleError)
            .then(this.handleReplaceElements)
            .then(clearPromise, onFail)

        return this._allElementsPromise

      } else {

        Logger.warn(
          className + ' - Retrieve- No retrieve function',
          func
        )

        return Promise.reject(BAS_LIBRARY_ERRORS.NO_RETRIEVE_FUNCTION)
      }

    } else {

      return Promise.reject(BAS_LIBRARY_ERRORS.ALREADY_FETCHING)
    }

    function onSuccess (result) {

      if (result &&
        result.data) {
        _this.offset = result.data.length
      }

      return result
    }

    function clearPromise () {
      _this._allElementsPromise = null
      _this.isFetching = false
    }

    function onFail () {
      _this._allElementsPromise = null
      _this.hasReachedEnd = false
      _this.isFetching = false
    }
  }

  /**
   * @returns {Promise}
   */
  DeezerCollection.prototype.retrieve = function () {

    var func, params

    // Check if player and Deezer is valid
    if (!this.hasReachedEnd &&
      !this.isFetching) {

      func = this.retrieveFunction()

      // Check if function is valid
      if (func) {

        // Set fetching state
        this.isFetching = true

        params = {}
        params.limit = this.limit
        params.index = this.offset

        // Retrieve Deezer items
        return func(params)
          .then(this.handleDeezerItems, this.handleError)
          .then(this.handleConcatElements)

      } else {

        return Promise.reject(BAS_LIBRARY_ERRORS.NO_RETRIEVE_FUNCTION)
      }
    } else {

      return Promise.reject(BAS_LIBRARY_ERRORS.ALREADY_FETCHING_END)
    }
  }

  DeezerCollection.prototype.onReachedEnd = function () {

    // Get player reference
    var basSource = LibraryState.getDeezerSource()

    // Check if fetch is needed
    if (!this.isFetching &&
      !this.hasReachedEnd) {

      if (BasUtil.isObject(this.lastPagedResult) &&
        this.lastPagedResult.hasNext === true &&
        basSource) {

        // Set fetching state
        this.isFetching = true

        // Retrieve next
        return basSource.deezer
          .requestByUrl(this.lastPagedResult.nextUrl)
          .then(this.handleDeezerItems, this.handleError)
          .then(this.handleConcatElements)
      } else if (basSource) {

        return this.retrieve()
      }
    }

    return Promise.resolve(false)
  }

  /**
   * @param {DeezerResult} result
   * @returns {(Array|Promise)}
   */
  DeezerCollection.prototype.onDeezerItems = function (result) {

    var index, processed

    if (this.checkSameData(result)) {

      this.lastPagedResult = result
      this.hasReachedEnd = true
      return Promise.reject(BAS_LIBRARY_ERRORS.DOUBLE_DATA)
    }

    // Check if result is a pagedResult
    if (BasUtil.isObject(result)) {

      // Store last paged result
      this.lastPagedResult = result

      // Check if end is reached
      if (!result.hasTotal || result.dataLength === 0) {

        // End is reached
        this.hasReachedEnd = true

      } else {

        this.offset += result.dataLength
      }

      // Check for filters
      switch (this.filter) {
        case DeezerCollection.FILTER_GENRE_ALL:

          // Check for genre "All" (ID 0)
          index = result.data.findIndex(DeezerModel.isIdZero)

          break
        case DeezerCollection.FILTER_PLAYLIST_LOVED_TRACKS:

          // Check for "Loved tracks" playlist
          index = result.data.findIndex(DeezerModel.isLovedTrack)

          break
      }

      // Check if index to delete was found
      if (typeof index === 'number' && index > -1) {

        // Remove element
        result.data.splice(index, 1)
      }

      // Process Deezer objects
      processed = DeezerModel.processDeezerObjects(result.data)

      BasUtilities.waitFrames(2).then(this.resetFetching.bind(this))

      return processed

    } else {
      Logger.warn(
        className + ' - onDeezerItems - Invalid result',
        result
      )

      BasUtilities.waitFrames(2).then(this.resetFetching.bind(this))

      return Promise.reject(new Error('invalid result'))
    }
  }

  DeezerCollection.prototype.checkSameData = function (result) {

    if (!this.lastPagedResult ||
      !this.lastPagedResult.data ||
      !this.lastPagedResult.data[0] ||
      !result ||
      !result.data ||
      !result.data[0]) {

      return false
    }

    return result.data[0].id === this.lastPagedResult.data[0].id
  }

  DeezerCollection.prototype.concatElements = function (processed) {

    this.elements = this.elements.concat(processed)
    $rootScope.$applyAsync()
  }

  DeezerCollection.prototype.replaceElements = function (processed) {

    this.elements = processed
    $rootScope.$applyAsync()
  }

  DeezerCollection.prototype.resetFetching = function () {

    // Fetching is done
    this.isFetching = false
  }

  // Helper functions

  DeezerCollection.prototype.onRetrieveError = function (error) {
    Logger.warn(
      className + ' - Retrieve ERROR',
      error
    )

    // Wait for a frame
    BasUtilities.waitFrames(2).then(this.resetFetching.bind(this))

    return Promise.reject(error)
  }

  return DeezerCollection
}
