'use strict'

angular
  .module('basalteApp')
  .factory('BasUiSortableHelper', basUiSortableHelperFactory)

/**
 * @returns BasUiSortableHelper
 */
function basUiSortableHelperFactory () {

  var CSS_NO_SWIPE = 'bas--no-swipe'
  var CSS_NO_REORDER = 'bas--no-reorder'

  /**
   * @constructor
   */
  function BasUiSortableHelper () {

    /**
     * @type {boolean}
     */
    this.isReordening = false

    /**
     * @type {boolean}
     */
    this.allowSwiping = true

    /**
     * @type {boolean}
     */
    this.allowReordening = true
  }

  /**
   * @param event
   */
  BasUiSortableHelper.prototype.onBeforeSwipe = function (event) {

    if (this.allowSwiping) {

      if (event &&
        event.target &&
        event.target.classList.contains(CSS_NO_SWIPE)) {

        event.preventDefault()
      }

    } else {

      event.preventDefault()
    }
  }

  /**
   * @param event
   */
  BasUiSortableHelper.prototype.onBeforeReorder = function (event) {

    if (this.allowReordening) {

      if (event &&
        event.target &&
        event.target.classList.contains(CSS_NO_REORDER)) {

        event.preventDefault()

      } else {

        this.isReordening = true
      }

    } else {

      event.preventDefault()
    }
  }

  BasUiSortableHelper.prototype.onReorder = function () {

    this.isReordening = false
  }

  return BasUiSortableHelper
}
