'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Capabilities = require('./capabilities')

/**
 * @typedef {Object} TTimerPoint
 * @property {number} time seconds
 * @property {boolean} [armed]
 * @property {number} [percentage] 0-1
 */

/**
 * @typedef {Object} TTimerPointServer
 * @property {number} time seconds
 * @property {boolean} [armed]
 * @property {number} [percentage] 0-1
 */

/**
 * @typedef {Object} TTimerServer
 * @property {Object} capabilities
 * @property {string} name
 * @property {string} type
 * @property {string} uuid
 * @property {boolean} enabled
 * @property {TTimerPointServer[]} monday
 * @property {TTimerPointServer[]} tuesday
 * @property {TTimerPointServer[]} wednesday
 * @property {TTimerPointServer[]} thursday
 * @property {TTimerPointServer[]} friday
 * @property {TTimerPointServer[]} saturday
 * @property {TTimerPointServer[]} sunday
 */

/**
 * @constructor
 * @mixes Capabilities.mixin
 * @param {TTimerServer} timer
 * @param {TimerCtrlDevice} ctrl
 */
function Timer (timer, ctrl) {

  /**
   * @type {TimerCtrlDevice}
   */
  this._ctrl = ctrl

  /**
   * @type {string}
   */
  this._uuid = BasUtil.isNEString(timer[P.UUID])
    ? timer[P.UUID]
    : ''

  /**
   * @type {string}
   */
  this._name = BasUtil.isNEString(timer[P.NAME])
    ? timer[P.NAME]
    : ''

  /**
   * @type {string}
   */
  this._type = BasUtil.isNEString(timer[P.TYPE])
    ? timer[P.TYPE]
    : Timer.T_ON_OFF

  /**
   * @type {Capabilities}
   */
  this[CONSTANTS.CAPABILITIES] = new Capabilities(timer[P.CAPABILITIES])

  /**
   * @type {boolean}
   */
  this._enabled = false

  /**
   * @type {boolean}
   */
  this._serverEnabled = false

  /**
   * @type {Object<string, TTimerPoint[]>}
   */
  this._days = {}

  /**
   * @type {Object<string, TTimerPoint[]>}
   */
  this._serverDays = {}

  this.parse(timer, { emit: false })
}

BasUtil.mergeObjects(Timer.prototype, Capabilities.mixin)

/**
 * @constant {string}
 */
Timer.C_SCHEDULER = P.SCHEDULER

/**
 * @constant {string}
 */
Timer.T_ON_OFF = P.ON_OFF

/**
 * @constant {string}
 */
Timer.T_PERCENTAGE = P.PERCENTAGE

/**
 * @param {TTimerPoint[]} day1
 * @param {TTimerPoint[]} day2
 * @returns {boolean}
 */
Timer.compareDays = function (day1, day2) {

  var length1, length2, i, point1, point2, isDayArray

  isDayArray = Array.isArray(day1)

  if (!isDayArray) return false
  if (isDayArray !== Array.isArray(day2)) return false

  length1 = day1.length
  length2 = day2.length

  if (length1 !== length2) return false

  for (i = 0; i < length1; i++) {

    point1 = day1[i]
    point2 = day2[i]

    if (point1.time !== point2.time) return false
    if (point1.armed !== point2.armed) return false
    if (point1.percentage !== point2.percentage) return false
  }

  return true
}

/**
 * @param {TTimerPointServer[]} day
 * @returns {TTimerPoint[]}
 */
Timer._parseDay = function (day) {

  var result, length, i, point

  result = []

  length = day.length
  for (i = 0; i < length; i++) {

    point = {}
    point.time = BasUtil.isPNumber(day[i][P.TIME], true)
      ? day[i][P.TIME]
      : 0

    if (BasUtil.isBool(day[i][P.ARMED])) {

      point.armed = day[i][P.ARMED]
    }

    if (BasUtil.isVNumber(day[i][P.PERCENTAGE])) {

      point.percentage = day[i][P.PERCENTAGE]
    }

    result.push(point)
  }

  return result
}

/**
 * @name Timer#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Timer.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name Timer#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(Timer.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * @name Timer#type
 * @type {string}
 * @readonly
 */
Object.defineProperty(Timer.prototype, 'type', {
  get: function () {
    return this._type
  }
})

/**
 * @name Timer#capabilities
 * @type {Object<string, string>}
 * @readonly
 */
Object.defineProperty(Timer.prototype, 'capabilities', {
  get: function () {
    return this[CONSTANTS.CAPABILITIES]
  }
})

/**
 * @name Timer#enabled
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Timer.prototype, 'enabled', {
  get: function () {
    return this._enabled
  }
})

/**
 * @name Timer#days
 * @type {Object<string, TTimerPoint[]>}
 * @readonly
 */
Object.defineProperty(Timer.prototype, 'days', {
  get: function () {
    return this._days
  }
})

/**
 * @param {Object} msg
 * @param {Object} [options]
 */
Timer.prototype.parse = function (msg, options) {

  var emit = true
  var changed = false

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (BasUtil.isObject(msg)) {

    if (this[CONSTANTS.CAPABILITIES].parse(msg[P.CAPABILITIES])) {

      changed = true
    }

    if (BasUtil.isBool(msg[P.ENABLED]) &&
      (
        this._serverEnabled !== msg[P.ENABLED] ||
        this._enabled !== msg[P.ENABLED]
      )) {

      this._serverEnabled = msg[P.ENABLED]
      this._enabled = msg[P.ENABLED]
      changed = true
    }

    changed = this.parseDays(msg) || changed

    if (emit && changed) this.emitChange()
  }
}

/**
 * @param {Object} msg
 * @returns {boolean}
 */
Timer.prototype.parseDays = function (msg) {

  var i, day, key, dayKey
  var hasChanged = false

  for (i = 0; i < 7; i++) {

    key = CONSTANTS.DAYS[i]
    dayKey = CONSTANTS.PARSER_DAYS[i]

    if (Array.isArray(msg[dayKey])) {

      day = Timer._parseDay(msg[dayKey])

      if (!Timer.compareDays(this._days[key], day)) {

        this._days[key] = day
        hasChanged = true
      }

      this._serverDays[key] = day
    }
  }

  return hasChanged
}

Timer.prototype.emitChange = function () {

  if (this._ctrl) this._ctrl.emitTimerChanged(this)
}

/**
 * @param {boolean} enabled
 */
Timer.prototype.setEnabled = function (enabled) {

  if (BasUtil.isBool(enabled) && this.enabled !== enabled) {

    this._enabled = enabled
  }
}

/**
 * @param {Object<string, TTimerPoint[]>} days
 */
Timer.prototype.setDays = function (days) {

  var keys, i, length, key, day, deviceDay

  if (BasUtil.isObject(days)) {

    keys = Object.keys(days)
    length = keys.length

    for (i = 0; i < length; i++) {

      key = keys[i]
      day = days[key]
      deviceDay = this._days[key]

      if (Array.isArray(day) &&
        !Timer.compareDays(day, deviceDay)) {

        this._days[key] = day
      }
    }
  }
}

/**
 * @returns {Promise}
 */
Timer.prototype.updateTimer = function () {

  var msg, changes

  changes = false

  msg = {}
  msg[P.UUID] = this.uuid

  if (this._serverEnabled !== this._enabled) {

    msg[P.ENABLED] = this._enabled
    changes = true
  }

  changes = this.generateDays(msg) || changes

  return changes
    ? this._ctrl.updateTimer(msg)
    : Promise.resolve(CONSTANTS.NO_CHANGES)

}

/**
 * @param {Object} msg
 * @returns {boolean}
 */
Timer.prototype.generateDays = function (msg) {

  var keys, i, length, key, day, daysIndex, parserKey, serverDay, changes

  changes = false

  if (BasUtil.isObject(this._days)) {

    keys = Object.keys(this._days)
    length = keys.length

    for (i = 0; i < length; i++) {

      key = keys[i]
      day = this._days[key]
      serverDay = this._serverDays[key]
      daysIndex = CONSTANTS.DAYS.indexOf(key)

      if (Array.isArray(day) &&
        daysIndex !== -1 &&
        !Timer.compareDays(day, serverDay)) {

        parserKey = CONSTANTS.PARSER_DAYS[daysIndex]
        msg[parserKey] = this.generateDay(day)
        changes = true
      }
    }
  }

  return changes
}

/**
 * @param {TTimerPoint[]} day
 * @returns {TTimerPointServer[]}
 */
Timer.prototype.generateDay = function (day) {

  var result, i, length

  result = []

  if (Array.isArray(day)) {

    length = day.length
    for (i = 0; i < length; i++) {

      if (day[i]) {

        result[i] = {}

        result[i][P.TIME] = day[i].time

        if (BasUtil.isBool(day[i].armed)) {

          result[i][P.ARMED] = day[i].armed
        }

        if (BasUtil.isVNumber(day[i].percentage)) {

          result[i][P.PERCENTAGE] = day[i].percentage
        }
      }
    }
  }

  return result
}

module.exports = Timer
