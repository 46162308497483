'use strict'

var BasUtil = require('@basalte/bas-util')

var Device = require('./device')

var P = require('./parser_constants')

/**
 * @typedef {Object} TGenericDeviceItem
 * @property {string} uuid
 * @property {string} name
 * @property {string} type
 * @property {Object} capabilities
 * @property {Object} state
 */

/**
 * @constructor
 * @extends Device
 * @param {TGenericDeviceItem} device
 * @param {BasCore} basCore
 */
function GenericDeviceControl (device, basCore) {

  Device.call(this, device, basCore)

  this._state = BasUtil.isObject(device[P.STATE])
    ? device[P.STATE]
    : {}
}

GenericDeviceControl.prototype = Object.create(Device.prototype)
GenericDeviceControl.prototype.constructor = GenericDeviceControl

// region Events

/**
 * State has been updated
 *
 * @event GenericDeviceControl#EVT_STATE_CHANGED
 * @since 2.7.0
 */

// endregion

/**
 * @constant {string}
 * @since 2.7.0
 */
GenericDeviceControl.EVT_STATE_CHANGED = 'evtGenericDeviceControlStateChanged'

/**
 * @constant {string}
 */
GenericDeviceControl.T_TOGGLE = P.TOGGLE

/**
 * @constant {string}
 */
GenericDeviceControl.T_SLIDER = P.SLIDER

/**
 * @constant {string}
 */
GenericDeviceControl.T_TRIGGER = P.TRIGGER

/**
 * @constant {string}
 */
GenericDeviceControl.T_NUMBER = P.NUMBER

/**
 * @constant {string}
 */
GenericDeviceControl.T_PERCENTAGE = P.PERCENTAGE

/**
 * @constant {string}
 */
GenericDeviceControl.T_BOOLEAN = P.BOOLEAN

/**
 * @constant {string}
 */
GenericDeviceControl.T_STRING = P.STRING

/**
 * @constant {string}
 */
GenericDeviceControl.T_NUMERIC_INPUT = P.NUMERIC_INPUT

/**
 * @constant {string}
 */
GenericDeviceControl.A_IT_SLIDER = P.SLIDER

/**
 * @constant {string}
 */
GenericDeviceControl.A_IT_KEYPAD = P.KEYPAD

/**
 * @constant {string}
 */
GenericDeviceControl.VALUE = P.VALUE

/**
 * From proto.be.basalte.config.DeviceControl.BoolControlLabel
 *
 * @readonly
 * @enum {(number|string)}
 */
GenericDeviceControl.TYPES = {
  ON_OFF: 0,
  OPEN_CLOSED: 1,
  IN_OUT: 2,
  UP_DOWN: 3,
  LEFT_RIGHT: 4,
  TRUE_FALSE: 5,
  ARMED_DISARMED: 6,
  STARTED_STOPPED: 7,
  ENABLED_DISABLED: 8,
  HIGH_LOW: 9,
  ACTIVE_INACTIVE: 10,
  OCCUPIED_FREE: 11,
  CUSTOM: 100
}

/**
 * @name GenericDeviceControl#value
 * @type {(boolean|number|string)}
 * @readonly
 */
Object.defineProperty(GenericDeviceControl.prototype, 'value', {
  get: function () {

    var value = this._state[P.VALUE]

    switch (this.type) {

      case GenericDeviceControl.T_SLIDER:
      case GenericDeviceControl.T_NUMBER:
      case GenericDeviceControl.T_PERCENTAGE:
      case GenericDeviceControl.T_NUMERIC_INPUT:
        return BasUtil.isVNumber(value)
          ? value
          : 0

      case GenericDeviceControl.T_TOGGLE:
      case GenericDeviceControl.T_TRIGGER:
      case GenericDeviceControl.T_BOOLEAN:
        return value === true

      case GenericDeviceControl.T_STRING:
        return BasUtil.isNEString(value)
          ? value
          : ''

      default:
        return 0
    }
  }
})

/**
 * @name GenericDeviceControl#labelUnit
 * @type {string}
 * @readonly
 * @since 2.7.0
 */
Object.defineProperty(GenericDeviceControl.prototype, 'labelUnit', {
  get: function () {

    var labels

    labels = this.getLabels()

    if (labels && BasUtil.isNEString(labels[P.UNITS])) {

      return labels[P.UNITS]
    }

    return ''
  }
})

/**
 * @name GenericDeviceControl#labelActionText
 * @type {string}
 * @readonly
 * @since 2.7.0
 */
Object.defineProperty(GenericDeviceControl.prototype, 'labelActionText', {
  get: function () {

    var labels

    labels = this.getLabels()

    if (labels && BasUtil.isNEString(labels[P.ACTION])) {

      return labels[P.ACTION]
    }

    return ''
  }
})

/**
 * Used for rounding/normalization of a value
 *
 * @name GenericDeviceControl#step
 * @type {number}
 * @readonly
 * @since 2.9.0
 */
Object.defineProperty(GenericDeviceControl.prototype, 'step', {
  get: function () {

    var step

    if (BasUtil.isObject(this._attributes) &&
      BasUtil.isPNumber(this._attributes[P.PRECISION])) {

      step = this._attributes[P.PRECISION]

      if (step && BasUtil.isPNumber(step, true)) {

        return step
      }
    }
    return 1
  }
})

/**
 * Used to indicate lower bounds of generic numeric input
 *
 * @name GenericDeviceControl#min
 * @type {?number}
 * @readonly
 * @since 3.9.0
 */
Object.defineProperty(GenericDeviceControl.prototype, 'min', {
  get: function () {

    return (this._attributes && BasUtil.isVNumber(this._attributes[P.MIN]))
      ? this._attributes[P.MIN]
      : null
  }
})

/**
 * Used to indicate upper bounds of generic numeric input
 *
 * @name GenericDeviceControl#max
 * @type {?number}
 * @readonly
 * @since 3.9.0
 */
Object.defineProperty(GenericDeviceControl.prototype, 'max', {
  get: function () {

    return (this._attributes && BasUtil.isVNumber(this._attributes[P.MAX]))
      ? this._attributes[P.MAX]
      : null
  }
})

/**
 * Used to indicate which input types should be available for a generic numeric
 *  input. Defaults to only a keypad when no input types are given
 *
 * @name GenericDeviceControl#inputTypes
 * @type {string[]}
 * @readonly
 * @since 3.9.0
 */
Object.defineProperty(GenericDeviceControl.prototype, 'inputTypes', {
  get: function () {

    return (this._attributes && Array.isArray(this._attributes[P.INPUT_TYPES]))
      ? this._attributes[P.INPUT_TYPES]
      : [GenericDeviceControl.A_IT_KEYPAD]
  }
})

/**
 * If no valid type is found return ON/OFF type
 *
 * @returns {number}
 */
GenericDeviceControl.prototype.getLabelType = function () {

  var labels

  labels = this.getLabels()

  if (labels && BasUtil.isPNumber(labels[P.TYPE], true)) {

    return labels[P.TYPE]
  }

  return 0
}

/**
 * @returns {boolean}
 */
GenericDeviceControl.prototype.getLabelInverted = function () {

  var labels

  labels = this.getLabels()

  if (labels && BasUtil.isBool(labels[P.INVERTED])) {

    return labels[P.INVERTED]
  }

  return false
}

/**
 * @returns {string}
 */
GenericDeviceControl.prototype.getLabelCustomTrue = function () {

  var labels

  labels = this.getLabels()

  if (labels && BasUtil.isString(labels[P.TRUE])) {

    return labels[P.TRUE]
  }

  return ''
}

/**
 * @returns {string}
 */
GenericDeviceControl.prototype.getLabelCustomFalse = function () {

  var labels

  labels = this.getLabels()

  if (labels && BasUtil.isString(labels[P.FALSE])) {

    return labels[P.FALSE]
  }

  return ''
}

/**
 * @param {TGenericDeviceItem} msg
 * @param {TDeviceParseOptions} [options]
 * @returns {boolean}
 */
GenericDeviceControl.prototype.parse = function (msg, options) {

  var emit, valid

  emit = true
  valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    if (BasUtil.isObject(msg[P.STATE])) {

      this._state = msg[P.STATE]

      if (emit) this.emit(GenericDeviceControl.EVT_STATE_CHANGED)
    }
  }

  return valid
}

module.exports = GenericDeviceControl
