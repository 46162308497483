'use strict'

angular
  .module('basalteApp')
  .service('BasLisaState', [
    '$rootScope',
    '$uiRouterGlobals',
    'BAS_APP',
    'BasState',
    'BasAppDevice',
    'BasLisaUi',
    BasLisaState
  ])

/**
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {BAS_APP} BAS_APP
 * @param {BasState} BasState
 * @param {BasAppDevice} BasAppDevice
 * @param {BasLisaUi} BasLisaUi
 */
function BasLisaState (
  $rootScope,
  $uiRouterGlobals,
  BAS_APP,
  BasState,
  BasAppDevice,
  BasLisaUi
) {

  init()

  function init () {

    if (BasAppDevice.isLisa()) {

      $rootScope.$on(
        BAS_APP.EVT_PAUSE,
        _onPause
      )
    }
  }

  function _onPause () {

    if ($uiRouterGlobals.transition) {

      // Ongoing transition

    } else {

      BasState.go(BasLisaUi.getLisaStartupState().state())
    }
  }
}
