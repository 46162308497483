'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('roomTypesModalCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_STATE',
    'BAS_ROOMS',
    'BAS_SPLASH',
    'BasIntercomHelper',
    'modalHelperService',
    'event',
    'close',
    roomTypesModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasIntercomHelper} BasIntercomHelper
 * @param modalHelperService
 * @param event
 * @param close
 */
function roomTypesModalCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_STATE,
  BAS_ROOMS,
  BAS_SPLASH,
  BasIntercomHelper,
  modalHelperService,
  event,
  close
) {
  var modal = this

  // Display variables - Button Target HTML element class
  var basButtonClass = 'modal-target'

  var listeners = []

  /**
   * @type {BAS_STATE}
   */
  modal.BAS_STATE = BAS_STATE

  modal.$uiRouterGlobals = $uiRouterGlobals

  // Set modal style
  modal.style = modalHelperService.getModalStyle()

  // Modal properties
  modal.can = {
    rooms: false,
    music: false,
    thermostats: false
  }

  modal.close = close

  $scope.$on('$destroy', onDestroy)

  init()

  function init () {

    setProperties()

    listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      onRoomsUpdated
    ))
    listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    // Get modal position relative to button
    modalHelperService.calcPosition(
      event,
      basButtonClass,
      modalHelperService.type.verticalRight
    )

    $scope.$applyAsync()
  }

  function onRoomsUpdated () {

    setProperties()

    $scope.$applyAsync()
  }

  function _onSplashVisibility () {

    close()
  }

  function setProperties () {

    if (BasUtil.isObject(BAS_ROOMS.ROOMS.info)) {

      modal.can.rooms = BAS_ROOMS.ROOMS.info.hasHomeRooms
      modal.can.music = BAS_ROOMS.ROOMS.info.hasMusicRooms
      modal.can.thermostats = BAS_ROOMS.ROOMS.info.hasThermostatRooms
      modal.can.intercom = BasIntercomHelper.shouldShowIntercom()

    } else {

      modal.can.rooms = false
      modal.can.music = true
      modal.can.thermostats = false
      modal.can.intercom = false
    }
  }

  function onDestroy () {

    BasUtil.executeArray(listeners)
    listeners = []
  }
}
