'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basDeviceVolume', [])
  .service('BasDeviceVolume', [
    '$window',
    BasDeviceVolume
  ])

/**
 * @constructor
 * @param $window
 */
function BasDeviceVolume ($window) {

  /**
   * Documentation can be found here:
   * https://developer.android.com/reference/android/media/AudioAttributes
   *
   * On Android the following 'special' (non-runtime) permission is needed for
   * changing notification volume: ACCESS_NOTIFICATION_POLICY
   *
   * To avoid this the 'alarms' volume is use in this app.
   * This way the volume can be separated in multiple types like:
   * 'notification' and 'normal' volume,
   * but no additional permissions are needed.
   */

  var T_VOICE_CALL = 1
  var T_SYSTEM = 2
  var T_RINGER = 3
  var T_MUSIC = 4
  var T_ALARM = 5
  var T_NOTIFICATION = 6
  var T_DTMF = 7

  var ERR_NO_PLUGIN = 'No volume plugin'
  var ERR_INVALID_INPUT = 'Invalid input volume'
  var ERR_INVALID_RESULT = 'Invalid result'

  this.T_ALARM = [
    T_ALARM
  ]

  this.T_NOTIFICATION = [
    T_NOTIFICATION
  ]

  this.T_ALL_BUT_NOTIFICATION = [
    T_MUSIC,
    T_DTMF,
    T_RINGER,
    T_SYSTEM,
    T_VOICE_CALL,
    T_ALARM
  ]

  this.T_ALL_BUT_NOTIFICATION_AND_ALARM = [
    T_MUSIC,
    T_DTMF,
    T_RINGER,
    T_SYSTEM,
    T_VOICE_CALL
  ]

  this.setVolume = setVolume
  this.getVolume = getVolume

  /**
   * Set device volume. This will set all volume levels to the given value.
   *
   * @param {number} volume 0 - 100
   * @param {number[]} types
   * @param [callback]
   */
  function setVolume (volume, types, callback) {

    var basVolume, _volume

    basVolume = _getVolumePlugin()

    if (basVolume) {

      if (BasUtil.isVNumber(volume)) {

        _volume = Math.round(volume)
        if (_volume > 100) _volume = 100
        if (_volume < 0) _volume = 0

        basVolume.setVolume(
          types,
          _volume,
          _callback
        )

      } else {

        _cb(new Error(ERR_INVALID_INPUT))
      }

    } else {

      _cb(new Error(ERR_NO_PLUGIN))
    }

    /**
     * @private
     * @param error
     * @param [result]
     */
    function _callback (error, result) {

      var _volumeValue

      if (error) {

        _cb(error)

      } else {

        if (Array.isArray(result)) {

          _volumeValue = result[0]

          if (BasUtil.isVNumber(_volumeValue)) {

            _cb(null, _volumeValue)

          } else {

            _cb(new Error(ERR_INVALID_RESULT))
          }

        } else {

          _cb(new Error(ERR_INVALID_RESULT))
        }
      }
    }

    /**
     * @private
     * @param error
     * @param [result]
     */
    function _cb (error, result) {

      if (BasUtil.isFunction(callback)) callback(error, result)
    }
  }

  /**
   * Confirms volume via onVolumeCallback
   * Value between 0 and 100
   *
   * @param callback
   */
  function getVolume (callback) {

    var basVolume

    if (BasUtil.isFunction(callback)) {

      basVolume = _getVolumePlugin()

      if (basVolume) {

        basVolume.getVolume(
          [
            basVolume.T_MUSIC
          ],
          _callback
        )

      } else {

        callback(new Error(ERR_NO_PLUGIN))
      }
    }

    /**
     * @private
     * @param error
     * @param [result]
     */
    function _callback (error, result) {

      var volume

      if (error) {

        callback(error)

      } else {

        if (Array.isArray(result)) {

          volume = result[0]

          if (BasUtil.isVNumber(volume)) {

            callback(null, volume)

          } else {

            callback(new Error(ERR_INVALID_RESULT))
          }

        } else {

          callback(new Error(ERR_INVALID_RESULT))
        }
      }
    }
  }

  function _getVolumePlugin () {

    if ($window['basalteCordova'] &&
      $window['basalteCordova']['volume']) {

      return $window['basalteCordova']['volume']
    }

    return null
  }
}
