'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasGenericDevice', [
    '$rootScope',
    'BAS_API',
    'BasUtilities',
    basGenericDeviceFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BasUtilities} BasUtilities
 * @returns {BasGenericDevice}
 */
function basGenericDeviceFactory (
  $rootScope,
  BAS_API,
  BasUtilities
) {

  /**
   * @constructor
   */
  function BasGenericDevice () {
    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * @type {?GenericDevice}
     */
    this.device = null

    /**
     * @type {boolean}
     */
    this.canSync = true

    /**
     * @type {boolean}
     */
    this.onlyInputControls = false

    this._handleDeviceStateChange = this.onDeviceStateChange.bind(this)

    /**
     * @type {Object}
     */
    this.css = {}

    this._deviceListeners = []
  }

  BasGenericDevice.prototype.fillInUuid = function () {

    if (!BasUtil.isNEString(this.uuid)) {

      this.uuid = BasUtilities.getAppUniqueId()

    }
  }

  /**
   * @param {string} uuid
   * @param {*} value
   */
  BasGenericDevice.prototype.commit = function (uuid, value) {

    if (this.canSync && this.device) {

      this.device.setValue(uuid, value)
    }
  }

  BasGenericDevice.prototype.setDeviceListeners = function () {
    this.clearDeviceListeners()

    if (this.device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.GenericDevice.EVT_CONTROLS_UPDATED,
        this._handleDeviceStateChange
      ))
    }
  }

  /**
   * Clears the API device listeners
   */
  BasGenericDevice.prototype.clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []

  }

  BasGenericDevice.prototype.onDeviceStateChange = function () {

    if (this.canSync) {

      this.syncDevice()
      $rootScope.$applyAsync()
    }
  }

  BasGenericDevice.prototype.setSyncMode = function (mode) {

    this.canSync = mode === true

    if (this.canSync) {

      this.syncDevice()
    }
  }

  BasGenericDevice.prototype.suspend = function suspend () {

    this.clearDeviceListeners()
  }

  // Abstract methods that should be implemented by subclasses
  BasGenericDevice.prototype.isEditable = function () {}
  BasGenericDevice.prototype.updateTranslation = function () {}
  BasGenericDevice.prototype.setOnlyInputControls = function () {}
  BasGenericDevice.prototype.removeNonEditableControls = function () {}
  BasGenericDevice.prototype.clone = function () {}
  BasGenericDevice.prototype.areControlsActive = function () {}
  /**
   * @param {GenericDevice} device
   */
  // eslint-disable-next-line no-unused-vars
  BasGenericDevice.prototype.parseGenericDevice = function (device) {}
  /**
   * @param {GenericDevice} [device]
   */
  // eslint-disable-next-line no-unused-vars
  BasGenericDevice.prototype.syncDevice = function (device) {}
  /**
   * @param {string} uuid
   * @returns {?BasGenericDeviceControl}
   */
  // eslint-disable-next-line no-unused-vars
  BasGenericDevice.prototype.getControlById = function (uuid) {}
  BasGenericDevice.prototype.clear = function () {}
  BasGenericDevice.prototype.clearControls = function () {}

  return BasGenericDevice
}
