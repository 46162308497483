'use strict'

angular
  .module('basalteApp')
  .service('BasMusicHelper', [
    'ICONS',
    'BAS_IMAGE',
    'BasImageTrans',
    'BasImage',
    BasMusicHelper
  ])

/**
 * @constructor
 * @param {ICONS} ICONS
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param BasImageTrans
 * @param BasImage
 */
function BasMusicHelper (
  ICONS,
  BAS_IMAGE,
  BasImageTrans,
  BasImage
) {
  var defaultEmptyPlayerIcon = new BasImage(
    ICONS.playingEmpty,
    {
      customClass: [
        BAS_IMAGE.C_BG_CONTAIN,
        BAS_IMAGE.C_SIZE_50
      ]
    }
  )
  var defaultEmptyMusic = new BasImage(
    ICONS.defaultMusic,
    {
      customClass: [
        BAS_IMAGE.C_BG_CONTAIN,
        BAS_IMAGE.C_SIZE_100
      ]
    }
  )

  /**
   * @type {BasImageTrans}
   */
  this.musicPlayerIconBit = new BasImageTrans({
    transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
    defaultImage: defaultEmptyPlayerIcon
  })

  /**
   * @type {BasImageTrans}
   */
  this.musicMainBit = new BasImageTrans({
    transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
    defaultImage: defaultEmptyMusic
  })

  /**
   * @type {BasImageTrans}
   */
  this.musicWidgetBit = new BasImageTrans({
    transitionType: BasImageTrans.TRANSITION_TYPE_FADE_FAST,
    defaultImage: defaultEmptyMusic
  })
}
