import * as BasUtil from '@basalte/bas-util'

module.exports.initBasRemoteJs = initBasRemoteJs

/**
 * Hoist the global WebSocket constructor
 *
 * This is done to keep track of all WebSocket instances.
 * For example: RemoteJs creates a WebSocket but doesn't expose it anywhere.
 *
 * @param {Object} store
 */
function initBasRemoteJs (store) {

  if (!store) return

  if (!store.basRemoteJs) store.basRemoteJs = {}

  store.basRemoteJs.sockets = []
  store.basRemoteJs.nativeWebSocket = window.WebSocket

  window.WebSocket = function (url, protocols, arg2, arg3, arg4, arg5) {
    var entry, creationDateTime, socket, socketListeners

    creationDateTime = Date.now()

    // eslint-disable-next-line new-cap
    socket = new store.basRemoteJs.nativeWebSocket(
      url,
      protocols,
      arg2,
      arg3,
      arg4,
      arg5
    )

    socketListeners = []
    socketListeners.push(BasUtil.setDOMListener(socket, 'close', _onClose))

    entry = {
      creationDateTime: creationDateTime,
      socket: socket,
      socketListeners: socketListeners,
      url: url,
      protocols: protocols
    }

    store.basRemoteJs.sockets.push(entry)

    return socket

    function _onClose () {

      // Cleanup after socket is closed

      BasUtil.executeArray(socketListeners)
      socketListeners = null

      if (store && store.basRemoteJs) {

        BasUtil.removeFromArray(store.basRemoteJs.sockets, entry)
      }

      entry = null
    }
  }

  // Copy static properties
  window.WebSocket.CONNECTING = store.basRemoteJs.nativeWebSocket.CONNECTING
  window.WebSocket.OPEN = store.basRemoteJs.nativeWebSocket.OPEN
  window.WebSocket.CLOSING = store.basRemoteJs.nativeWebSocket.CLOSING
  window.WebSocket.CLOSED = store.basRemoteJs.nativeWebSocket.CLOSED
}
