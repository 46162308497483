'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasDiscovery', [
    '$rootScope',
    'BAS_DISCOVERY',
    'BAS_DISCOVERY_LOCAL',
    'BAS_DISCOVERY_CLOUD',
    'BasAppDevice',
    'BasDiscoveryLocal',
    'BasDiscoveryCloud',
    'BasDiscoveredCore',
    'BasLocalCore',
    BasDiscovery
  ])

/**
 * @typedef {Object} TBasDiscoveryState
 * @property {Object<string, BasDiscoveredCore>} services
 * @property {string[]} uiServices
 * @property {boolean} isDiscovering
 * @property {boolean} shouldBeDiscovering
 */

/**
 * Service for discovering basCores via Bonjour/mDNS
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_DISCOVERY} BAS_DISCOVERY
 * @param {BAS_DISCOVERY_LOCAL} BAS_DISCOVERY_LOCAL
 * @param {BAS_DISCOVERY_CLOUD} BAS_DISCOVERY_CLOUD
 * @param {BasAppDevice} BasAppDevice
 * @param {BasDiscoveryLocal} BasDiscoveryLocal
 * @param {BasDiscoveryCloud} BasDiscoveryCloud
 * @param BasDiscoveredCore
 * @param BasLocalCore
 */
function BasDiscovery (
  $rootScope,
  BAS_DISCOVERY,
  BAS_DISCOVERY_LOCAL,
  BAS_DISCOVERY_CLOUD,
  BasAppDevice,
  BasDiscoveryLocal,
  BasDiscoveryCloud,
  BasDiscoveredCore,
  BasLocalCore
) {
  var _startTimeoutId = 0

  /**
   * @type {TBasDiscoveryState}
   */
  var state = {}
  state.services = {}
  state.uiServices = []
  state.isDiscovering = false
  state.shouldBeDiscovering = false

  this.get = get
  this.stop = stop
  this.halt = halt
  this.resume = resume
  this.restart = restart
  this.setShouldBeDiscoveringState = setShouldBeDiscoveringState
  this.isRunning = isRunning
  this.getService = getService
  this.getSingleService = getSingleService
  this.hasMultipleServices = hasMultipleServices
  this.searchServices = searchServices
  this.searchServiceByMACAddress = searchServiceByMACAddress
  this.searchServiceByAddress = searchServiceByAddress
  this.searchServiceByCID = searchServiceByCID

  init()

  function init () {

    $rootScope.$on(
      BAS_DISCOVERY_LOCAL.EVT_DISCOVERY_LOCAL_START,
      _onBasDiscoveryLocalStart
    )
    $rootScope.$on(
      BAS_DISCOVERY_LOCAL.EVT_DISCOVERY_LOCAL_CORE_SERVICE_FOUND,
      _onBasDiscoveryLocalServiceFound
    )
    $rootScope.$on(
      BAS_DISCOVERY_LOCAL.EVT_DISCOVERY_LOCAL_CORE_SERVICE_LOST,
      _onBasDiscoveryLocalServiceLost
    )

    $rootScope.$on(
      BAS_DISCOVERY_CLOUD.EVT_DISCOVERY_CLOUD_CORE_SERVICE_FOUND,
      _onBasDiscoveryCloudServiceFound
    )
    $rootScope.$on(
      BAS_DISCOVERY_CLOUD.EVT_DISCOVERY_CLOUD_CORE_SERVICE_LOST,
      _onBasDiscoveryCloudServiceLost
    )
  }

  /**
   * @returns {TBasDiscoveryState}
   */
  function get () {

    return state
  }

  /**
   * (Re-)start Discovery
   * Can be executed more than once per second
   */
  function restart () {

    if (BasAppDevice.isLiveOnlyOrWebRTC()) return

    state.shouldBeDiscovering = true

    _restart()
  }

  /**
   * @param {boolean} value
   */
  function setShouldBeDiscoveringState (value) {

    if (BasAppDevice.isLiveOnlyOrWebRTC()) return

    state.shouldBeDiscovering = value
  }

  /**
   * Resume discovery
   */
  function resume () {

    if (BasAppDevice.isLiveOnlyOrWebRTC()) return

    if (state.shouldBeDiscovering) _restart()
  }

  /**
   * Halt discovery
   * Could be started by the next resume
   */
  function halt () {

    _clearStartTimeout()

    BasDiscoveryLocal.stop()

    BasDiscoveryCloud.stop()

    state.isDiscovering = false
  }

  /**
   * Stop discovery
   * Will not be started by the next resume
   */
  function stop () {

    state.shouldBeDiscovering = false

    halt()
  }

  function _restart () {

    if (BasAppDevice.isLiveOnlyOrWebRTC()) return

    BasDiscoveryLocal.restart()

    BasDiscoveryCloud.restart()

    state.isDiscovering = true
  }

  /**
   * Check if discovery service is discovering
   *
   * @returns {boolean}
   */
  function isRunning () {

    return state.shouldBeDiscovering
  }

  /**
   * @param {string} serviceName
   * @returns {?BasDiscoveredCore}
   */
  function getService (serviceName) {

    var service

    if (BasUtil.isNEString(serviceName)) {

      service = state.services[serviceName]

      if (BasUtil.isObject(service)) return service
    }

    return null
  }

  /**
   * Returns the single discovered service if only 1 service was found.
   *
   * @returns {?BasDiscoveredCore}
   */
  function getSingleService () {

    var length, service

    length = state.uiServices.length

    if (length === 1) {

      service = state.services[state.uiServices[0]]

      if (BasUtil.isObject(service)) return service
    }

    return null
  }

  /**
   * Whether there are multiple discovered services
   *
   * @returns {boolean}
   */
  function hasMultipleServices () {

    return state.uiServices.length > 1
  }

  /**
   * Search for a service with a matching MAC address.
   *
   * @param {(string|number)[]} macAddresses
   * @returns {BasDiscoveredCore[]}
   */
  function searchServices (macAddresses) {

    var result, i, length, service

    result = []

    if (Array.isArray(macAddresses)) {

      length = macAddresses.length
      for (i = 0; i < length; i++) {

        service = searchServiceByMACAddress(macAddresses[i])

        if (service) {

          result.push(service)
        }
      }
    }

    return result
  }

  /**
   * Search for service with specific MAC address.
   *
   * @param {(string|number)} macAddress
   * @returns {?BasDiscoveredCore}
   */
  function searchServiceByMACAddress (macAddress) {

    var i, length, keys, service

    // Check address type
    if (BasUtil.isPNumber(macAddress) ||
      BasUtil.isNEString(macAddress)) {

      // Iterate all discovered services
      keys = Object.keys(state.services)
      length = keys.length
      for (i = 0; i < length; i++) {

        service = state.services[keys[i]]

        if (service && service.hasSameMac) {

          if (service.hasSameMac(macAddress)) return service
        }
      }
    }

    return null
  }

  /**
   * Search for service with specific IP/DNS address.
   *
   * @param {string} address
   * @returns {?BasDiscoveredCore}
   */
  function searchServiceByAddress (address) {

    var i, length, keys, service

    // Check address type
    if (BasUtil.isNEString(address)) {

      // Iterate all discovered services
      keys = Object.keys(state.services)
      length = keys.length
      for (i = 0; i < length; i++) {

        service = state.services[keys[i]]

        if (service && service.hasAddress) {

          if (service.hasAddress(address)) return service
        }
      }
    }

    return null
  }

  /**
   * Search for service by CID. Return Master service if multiple are found.
   *
   * @param {string} cid
   * @returns {?BasDiscoveredCore}
   */
  function searchServiceByCID (cid) {

    var services

    if (BasUtil.isNEString(cid)) {

      services = _getServicesWithCID(cid)
      services.sort(BasDiscoveredCore.compareLowestMacFirst)

      if (services[0]) return services[0]
    }

    return null
  }

  /**
   * Callback for start of local service discovery
   *
   * @private
   */
  function _onBasDiscoveryLocalStart () {

    // Clear out previous services
    _clearServices()

    // Restart Cloud to get info about discovered cores asap
    BasDiscoveryCloud.restart()
  }

  /**
   * Callback for local discovery service on a service found event
   *
   * @private
   * @param {Object} _evt
   * @param {Object} service
   */
  function _onBasDiscoveryLocalServiceFound (
    _evt,
    service
  ) {
    if (service) _onServiceFound(service)
  }

  /**
   * Callback for cloud/network discovery service on a service lost event
   *
   * @private
   * @param {Object} _evt
   * @param {Object} service
   */
  function _onBasDiscoveryLocalServiceLost (
    _evt,
    service
  ) {
    if (service) _onServiceLost(service)
  }

  /**
   * Callback for cloud/network discovery service on a service found event
   *
   * @private
   * @param {Object} _evt
   * @param {?TBasDiscoveryCloudServer} service
   */
  function _onBasDiscoveryCloudServiceFound (
    _evt,
    service
  ) {
    if (service) _onServiceFound(service)
  }

  /**
   * Callback for cloud/network discovery service on a service lost event
   *
   * @private
   * @param {Object} _evt
   * @param {?TBasDiscoveryCloudServer} service
   */
  function _onBasDiscoveryCloudServiceLost (
    _evt,
    service
  ) {
    if (service) _onServiceLost(service)
  }

  /**
   * @private
   * @param {?Object} service
   */
  function _onServiceFound (service) {

    var newBasLocalCore, basLocalCore

    if (BasLocalCore.isLocalDiscoveredCoreService(service) ||
      BasLocalCore.isNetworkDiscoveredCoreService(service)) {

      newBasLocalCore = new BasLocalCore(service)

      if (newBasLocalCore.isMaster) {

        basLocalCore = _getLocalCore(newBasLocalCore)

        if (basLocalCore) {

          if (basLocalCore.isLocallyDiscovered &&
            newBasLocalCore.isLocallyDiscovered) {

            // eslint-disable-next-line no-console
            console.warn(
              'service found - service already exists',
              JSON.stringify(basLocalCore),
              service
            )
          }

          basLocalCore.parseDiscoveredService(service)

        } else {

          state.services[newBasLocalCore.id] = newBasLocalCore
        }

        _generateServicesUi()

        $rootScope.$emit(BAS_DISCOVERY.EVT_DISCOVERED_CORES_UPDATED)
      }
    }
  }

  /**
   * @private
   * @param {?Object} service
   */
  function _onServiceLost (service) {

    var newBasLocalCore, basLocalCore

    if (BasUtil.isObject(service)) {

      newBasLocalCore = new BasLocalCore(service)

      if (newBasLocalCore) {

        basLocalCore = _getLocalCore(newBasLocalCore)

        if (basLocalCore) {

          if (newBasLocalCore.isLocallyDiscovered) {

            basLocalCore.isLocallyDiscovered = false

          } else if (newBasLocalCore.isNetworkDiscovered) {

            basLocalCore.isNetworkDiscovered = false
          }

          if (!basLocalCore.isLocallyDiscovered &&
            !basLocalCore.isNetworkDiscovered) {

            state.services[basLocalCore.id] = null

            _generateServicesUi()

            $rootScope.$emit(
              BAS_DISCOVERY.EVT_DISCOVERED_CORES_UPDATED
            )
          }
        }
      }
    }
  }

  /**
   * Get saved BasLocalCore based on received BasLocalCore
   *
   * @private
   * @param {BasLocalCore} newBasLocalCore
   * @returns BasLocalCore
   */
  function _getLocalCore (newBasLocalCore) {

    var services, basLocalCore

    basLocalCore = null

    // Search for existing local service
    services =
      _getServicesWithServiceName(newBasLocalCore.serviceName)

    if (services.length === 1) {

      basLocalCore = services[0]

    } else if (services.length > 1) {

      // Should not occur
      // TODO Match service to delete

      // eslint-disable-next-line no-console
      console.error(
        'multiple services with same service name',
        newBasLocalCore.serviceName
      )
    }

    // Search for existing cloud service
    if (!basLocalCore) {

      basLocalCore =
        searchServiceByMACAddress(newBasLocalCore.macN)
    }

    return basLocalCore
  }

  function _generateServicesUi () {

    var keys, length, i, serviceName, service, cidIdx

    state.uiServices = []

    keys = Object.keys(state.services)
    length = keys.length
    for (i = 0; i < length; i++) {

      serviceName = keys[i]
      service = state.services[serviceName]

      if (service && state.uiServices.indexOf(serviceName) < 0) {

        cidIdx = _indexOfServiceWithCID(service.cid)

        // For multi server setups,
        // server with lowest MAC address should be shown
        // (is preferred master)

        if (cidIdx === -1) {

          state.uiServices.push(serviceName)

        } else {

          if (BasDiscoveredCore.compareLowestMacFirst(
            service,
            state.services[state.uiServices[cidIdx]]
          ) < 0) {

            state.uiServices[cidIdx] = serviceName
          }
        }
      }
    }
  }

  /**
   * @private
   * @param {string} cid
   * @returns {number}
   */
  function _indexOfServiceWithCID (cid) {

    var length, i, service

    if (BasUtil.isNEString(cid)) {

      length = state.uiServices.length
      for (i = 0; i < length; i++) {

        service = state.services[state.uiServices[i]]

        if (service && service.cid === cid) return i
      }
    }

    return -1
  }

  /**
   * Get all services with the specified CID
   *
   * @private
   * @param {string} cid
   * @returns {BasDiscoveredCore[]}
   */
  function _getServicesWithCID (cid) {

    var result, keys, length, i, name, service

    result = []

    keys = Object.keys(state.services)
    length = keys.length
    for (i = 0; i < length; i++) {

      name = keys[i]
      service = state.services[name]

      if (service && service.cid === cid) {

        result.push(service)
      }
    }

    return result
  }

  /**
   * Get all services with the specified serviceName
   *
   * @private
   * @param {string} serviceName
   * @returns {BasDiscoveredCore[]}
   */
  function _getServicesWithServiceName (serviceName) {

    var result, keys, length, i, name, service

    result = []

    if (BasUtil.isNEString(serviceName)) {

      keys = Object.keys(state.services)
      length = keys.length
      for (i = 0; i < length; i++) {

        name = keys[i]
        service = state.services[name]

        if (service && service.serviceName === serviceName) {

          result.push(service)
        }
      }
    }

    return result
  }

  function _clearStartTimeout () {

    clearTimeout(_startTimeoutId)

    _startTimeoutId = 0
  }

  /**
   * Clears the services
   *
   * @private
   */
  function _clearServices () {

    state.services = {}
    state.uiServices = []
  }
}
