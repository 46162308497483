'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basSchedulerEntry', [
    'BAS_HTML',
    basSchedulerEntryDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basSchedulerEntry
 */
function basSchedulerEntryDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.entryBasScheduler,
    controller: [
      '$scope',
      'BasUtilities',
      controller
    ],
    controllerAs: 'schedulerEntry',
    bindToController: {
      title: '@?',
      uiFilters: '<?',
      filters: '<?',
      activeFilterId: '@?',
      pointTypes: '<?',
      xMin: '<?',
      xMax: '<?',
      excludeXMax: '<?',
      xStep: '<?',
      yMin: '<?',
      yMax: '<?',
      yStep: '<?',
      verticalLines: '<?',
      horizontalLines: '<?',
      entry: '<',
      isXAllowed: '&?',
      selectFilter: '&?',
      addPoint: '&?',
      changePoint: '&?',
      deletePoint: '&?'
    }
  }

  /**
   * @param $scope
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $scope,
    BasUtilities
  ) {
    var schedulerEntry = this

    var CSS_SHOW_ADD_BTN_OVERLAY = 'bsche--show-add-btn-overlay'
    var CSS_SHOW_ADD_BTN_OPTIONS = 'bsche--show-add-bnt-options'

    var _deRegisterWaitForFrames = null

    schedulerEntry.css = {}

    schedulerEntry.$onDestroy = _onDestroy

    schedulerEntry.ctrlIsXAllowed = isXAllowed
    schedulerEntry.ctrlAddPoint = addPoint
    schedulerEntry.ctrlCloseAddPoint = closeAddPoint
    schedulerEntry.ctrlChangePoint = changePoint
    schedulerEntry.ctrlDeletePoint = deletePoint

    init()

    function init () {

      _resetCss()
    }

    /**
     * @param {BasSchedulerPoint} point
     * @param {number} x
     * @returns {boolean}
     */
    function isXAllowed (point, x) {

      if (BasUtil.isFunction(schedulerEntry.isXAllowed)) {

        return schedulerEntry.isXAllowed({
          point: point,
          x: x
        })
      }

      return true
    }

    /**
     * @param {BasSchedulerPointType} [type]
     */
    function addPoint (type) {

      var pointTypes, length

      pointTypes = schedulerEntry.pointTypes

      closeAddPoint()

      if (Array.isArray(pointTypes)) {

        length = pointTypes.length

        if (length > 1) {

          if (type) {

            if (BasUtil.isFunction(schedulerEntry.addPoint)) {

              schedulerEntry.addPoint({
                pointType: type
              })
            }

          } else {

            _toggleShowAddBtnOverlay(true)

            _deRegisterWaitForFrames =
              BasUtilities.waitForFrames(5, _onWaitForFrames)
          }

        } else {

          if (BasUtil.isFunction(schedulerEntry.addPoint)) {

            schedulerEntry.addPoint({
              pointType: pointTypes[0]
            })
          }
        }

      } else {

        if (BasUtil.isFunction(schedulerEntry.addPoint)) {

          schedulerEntry.addPoint()
        }
      }
    }

    function closeAddPoint () {

      BasUtil.execute(_deRegisterWaitForFrames)

      _toggleShowAddBtnOptions(false)
      _toggleShowAddBtnOverlay(false)
    }

    /**
     * @param {BasSchedulerPoint} point
     */
    function changePoint (point) {

      if (BasUtil.isFunction(schedulerEntry.changePoint)) {

        schedulerEntry.changePoint({
          point: point
        })
      }
    }

    /**
     * @param {BasSchedulerPoint} point
     */
    function deletePoint (point) {

      if (BasUtil.isFunction(schedulerEntry.deletePoint)) {

        schedulerEntry.deletePoint({
          point: point
        })
      }
    }

    function _onWaitForFrames () {

      _toggleShowAddBtnOptions(true)

      $scope.$applyAsync()
    }

    /**
     * @private
     * @param {boolean} [force]
     */
    function _toggleShowAddBtnOverlay (force) {

      schedulerEntry.css[CSS_SHOW_ADD_BTN_OVERLAY] =
        BasUtil.isBool(force)
          ? force
          : !schedulerEntry.css[CSS_SHOW_ADD_BTN_OVERLAY]
    }

    function _toggleShowAddBtnOptions (force) {

      schedulerEntry.css[CSS_SHOW_ADD_BTN_OPTIONS] =
        BasUtil.isBool(force)
          ? force
          : !schedulerEntry.css[CSS_SHOW_ADD_BTN_OPTIONS]
    }

    function _resetCss () {

      schedulerEntry.css[CSS_SHOW_ADD_BTN_OVERLAY] = false
      schedulerEntry.css[CSS_SHOW_ADD_BTN_OPTIONS] = false
    }

    function _onDestroy () {

      BasUtil.execute(_deRegisterWaitForFrames)
    }
  }
}
