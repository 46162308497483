'use strict'

angular
  .module('basalteApp')
  .directive('basMusicWidgetTitle', [
    'BAS_HTML',
    basMusicWidgetTitle
  ])

function basMusicWidgetTitle (BAS_HTML) {

  return {
    restrict: 'A',
    controller: [
      'BasMusicWidgetService',
      basMusicWidgetTitleCtrl
    ],
    controllerAs: 'widget',
    bindToController: true,
    template: BAS_HTML.musicWidgetTitle
  }
}

/**
 * @param {BasMusicWidgetService} BasMusicWidgetService
 */
function basMusicWidgetTitleCtrl (
  BasMusicWidgetService
) {
  var widget = this

  widget.toggle = toggle
  widget.musicWidgetState = BasMusicWidgetService.get()

  widget.toggle = toggle

  /**
   * "true" - Now playing
   * "false" - favourites
   *
   * @param {boolean} [force]
   */
  function toggle (force) {
    BasMusicWidgetService.toggleNowPlaying(force)
  }
}
