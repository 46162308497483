{
  "monitor": [
    {
      "date": "21/1/2019",
      "energy": [
        155,
        138,
        122,
        133,
        162,
        359,
        689,
        935,
        823,
        543,
        329,
        326,
        329,
        314,
        301,
        345,
        360,
        633,
        777,
        1278,
        1336,
        1287,
        1121,
        553
      ],
      "water": [
        3.75120186665921,
        4.41329945636418,
        9.50219153623844,
        7.77895274739327,
        3.45073903698796,
        13.0553568822622,
        21.852163392763,
        36.2317423107311,
        40.4053478514294,
        26.3434158513229,
        26.7537421200442,
        21.6287411298679,
        14.2583421073927,
        21.5529077810406,
        18.4401431004093,
        16.6099259674738,
        19.1109911343406,
        18.6164915463743,
        40.7736904619547,
        43.6056356185158,
        42.5562027413647,
        43.6792161707525,
        27.6309978116008,
        13.9058269266993
      ]
    },
    {
      "date": "22/1/2019",
      "energy": [
        163,
        170,
        138,
        192,
        195,
        370,
        695,
        853,
        869,
        518,
        332,
        373,
        367,
        345,
        385,
        330,
        420,
        664,
        825,
        1217,
        1361,
        1235,
        1180,
        535
      ],
      "water": [
        1.49522409294103,
        3.05505961686399,
        8.35733291650329,
        7.02894014626032,
        2.65872994546476,
        5.46509028175867,
        22.4707407008965,
        28.4730611952651,
        38.7179318651651,
        28.0515634405885,
        29.4317253601087,
        19.7554846199372,
        14.307479334383,
        16.0229295977958,
        21.7427877236634,
        16.4815413268615,
        20.7546481169862,
        15.9316991536178,
        33.1938895149112,
        38.0658955294012,
        40.0890231744817,
        45.9492595369099,
        20.1165771797786,
        5.95223014995442
      ]
    },
    {
      "date": "23/1/2019",
      "energy": [
        135,
        193,
        157,
        116,
        162,
        281,
        639,
        928,
        828,
        548,
        359,
        381,
        389,
        361,
        362,
        368,
        339,
        657,
        828,
        1271,
        1366,
        1265,
        1083,
        507
      ],
      "water": [
        7.51711843696444,
        7.41123388459616,
        6.2033763820806,
        4.5036829182081,
        6.10036061283438,
        6.93615406288651,
        18.7766759390703,
        32.747611962686,
        44.2027669585944,
        25.4932598038772,
        25.7857414416441,
        23.188965930278,
        23.6887343772594,
        20.5279086716574,
        24.8862879418637,
        18.6636746538844,
        19.5685382215162,
        23.3262194362199,
        37.6692067888735,
        38.6087353148114,
        48.6201466637049,
        43.8132356808301,
        29.807181409792,
        8.97638181583521
      ]
    },
    {
      "date": "24/1/2019",
      "energy": [
        210,
        138,
        185,
        159,
        159,
        280,
        630,
        856,
        829,
        552,
        293,
        301,
        304,
        352,
        322,
        330,
        405,
        682,
        848,
        1219,
        1346,
        1249,
        1174,
        561
      ],
      "water": [
        7.58178208053408,
        4.09938225859645,
        1.00164654263106,
        11.8758563046699,
        7.47281750925378,
        10.3056641164458,
        24.3288997433473,
        36.7255730242334,
        39.5079201587408,
        25.849249707066,
        25.5669681789014,
        17.8629957656375,
        17.8417535609238,
        13.1104110709114,
        13.5702487407461,
        17.3756202834391,
        20.4541958785219,
        16.5923069808307,
        32.7600398652602,
        42.3956620795611,
        42.2252998017518,
        38.0123327958094,
        29.6022810188087,
        13.3486666208678
      ]
    },
    {
      "date": "25/1/2019",
      "energy": [
        149,
        138,
        186,
        170,
        154,
        314,
        658,
        854,
        891,
        589,
        343,
        280,
        386,
        366,
        390,
        358,
        331,
        591,
        739,
        1266,
        1283,
        1252,
        1199,
        537
      ],
      "water": [
        9.57307495568702,
        1.15551377433437,
        5.41256103843397,
        9.59589765047409,
        6.92537144156793,
        9.13218353791379,
        24.6759344348499,
        29.6750369813091,
        36.6734586604773,
        31.2318826135598,
        21.119940405452,
        18.5467945053043,
        12.3241995721451,
        13.0077520385742,
        16.1907192200712,
        16.7950131109816,
        21.99481433442,
        13.5022120970125,
        34.3486863372284,
        38.2594191417666,
        45.628258530089,
        44.7199080710392,
        21.7526144509204,
        9.83724049349914
      ]
    },
    {
      "date": "26/1/2019",
      "energy": [
        971,
        779,
        102,
        114,
        183,
        367,
        438,
        679,
        803,
        739,
        770,
        777,
        1188,
        1547,
        1585,
        1361,
        1366,
        1820,
        1642,
        1536,
        1684,
        1279,
        1172,
        961
      ],
      "water": [
        2.59753858514708,
        3.76764763126822,
        2.28586242496779,
        7.05201937437273,
        2.77356789851415,
        4.1597382182493,
        19.6211099210447,
        35.2801135385526,
        45.6937338555969,
        30.4573040034191,
        24.0207044681078,
        25.1564066877245,
        24.8044202999632,
        20.1545014982115,
        28.8393319879986,
        16.5144058844482,
        22.0265965721201,
        19.8308826967301,
        33.5190441582521,
        38.862994705969,
        49.2128033963718,
        36.9695032567775,
        28.2999771338437,
        27.0795223366413
      ]
    },
    {
      "date": "27/1/2019",
      "energy": [
        913,
        792,
        117,
        164,
        131,
        299,
        395,
        665,
        867,
        698,
        752,
        808,
        1182,
        1575,
        1626,
        1314,
        1366,
        1841,
        1581,
        1480,
        1682,
        1232,
        1183,
        968
      ],
      "water": [
        0.7215643614922,
        7.28619871367628,
        2.51545839858063,
        5.59356407073908,
        5.28157294978477,
        13.456982493305,
        20.7333270260354,
        29.3139148220842,
        45.2887242721203,
        29.1539308189391,
        28.9725787439278,
        24.8191319476617,
        21.5652091771281,
        23.737729995476,
        24.7128326886767,
        18.9783784945855,
        20.3518645141633,
        22.2065249954165,
        38.621996663592,
        43.1834975184965,
        47.1420135561588,
        39.2961424509349,
        26.2759072573051,
        28.9461599616957
      ]
    },
    {
      "date": "28/1/2019",
      "energy": [
        116,
        182,
        119,
        134,
        108,
        363,
        640,
        946,
        784,
        574,
        289,
        317,
        410,
        395,
        311,
        366,
        348,
        647,
        772,
        1269,
        1392,
        1243,
        1102,
        564
      ],
      "water": [
        7.10406299128202,
        1.04406417094032,
        4.71390100530605,
        7.9883942225651,
        5.94245982404466,
        8.39437283392357,
        17.7004523699613,
        32.666657196046,
        45.5300366344215,
        28.4356137761431,
        26.0208977822923,
        17.8158841541424,
        18.681721109498,
        13.17555108166,
        16.2925588534854,
        18.6874436622458,
        17.8575132929679,
        18.4881244015921,
        35.4136883223284,
        45.9109539061574,
        40.6893064130902,
        37.4189738207377,
        23.8949187387316,
        10.9590585493757
      ]
    },
    {
      "date": "29/1/2019",
      "energy": [
        211,
        107,
        130,
        136,
        146,
        360,
        689,
        949,
        799,
        597,
        364,
        340,
        403,
        329,
        310,
        292,
        384,
        668,
        782,
        1281,
        1350,
        1340,
        1130,
        570
      ],
      "water": [
        8.61725880482256,
        6.13076089852402,
        6.90316492328085,
        10.567397148148,
        3.05498687548274,
        6.0500653558173,
        24.3060295306087,
        37.3730256912846,
        39.1234247852816,
        26.0106204580572,
        25.9532633706106,
        21.0362605564835,
        12.102313594669,
        12.1479519383892,
        17.4206526940478,
        12.5014115665423,
        15.1311167164922,
        20.4167448531609,
        35.4865633568053,
        36.7959612016238,
        49.882965756785,
        38.6138895493091,
        21.8135843845593,
        5.20196235063763
      ]
    },
    {
      "date": "30/1/2019",
      "energy": [
        209,
        150,
        144,
        124,
        185,
        335,
        695,
        836,
        805,
        520,
        294,
        400,
        407,
        326,
        365,
        274,
        367,
        679,
        793,
        1199,
        1316,
        1254,
        1161,
        507

      ],
      "water": [
        1.53423691605067,
        0.696019733655814,
        9.27309757214132,
        7.87700926441421,
        1.64305503784354,
        13.9158196964747,
        22.4622724356501,
        28.9037849225598,
        37.2414963936493,
        28.2518002103224,
        23.4753334244808,
        21.3000724862404,
        25.2921740346919,
        29.6958585076331,
        19.9787292169623,
        22.4983866939307,
        22.5610785190517,
        18.7836920960147,
        33.433438111075,
        37.8010500371702,
        45.5207315265859,
        40.0326313278256,
        20.1061079068312,
        4.86405025121172
      ]
    },
    {
      "date": "31/1/2019",
      "energy": [
        219,
        130,
        124,
        134,
        124,
        346,
        675,
        870,
        790,
        524,
        371,
        378,
        394,
        340,
        319,
        263,
        339,
        603,
        840,
        1215,
        1309,
        1331,
        1140,
        531
      ],
      "water": [
        0.663425040310918,
        5.70981054501728,
        5.79615334779278,
        11.7938287892302,
        1.27804920695153,
        6.68573122280808,
        24.7032377792139,
        34.93453000948,
        39.7188673774599,
        25.0681412704952,
        25.1566230442096,
        16.3293511192421,
        19.2684093307099,
        15.5182967328605,
        17.4491255178799,
        12.0343008232928,
        16.9502450470689,
        21.2916674817654,
        33.9329829898584,
        36.2495665946988,
        45.9699508700323,
        38.6871501596413,
        22.8683080034831,
        10.3882352074637
      ]
    },
    {
      "date": "1/2/2019",
      "energy": [
        167,
        140,
        128,
        208,
        166,
        294,
        587,
        950,
        820,
        594,
        317,
        376,
        401,
        345,
        314,
        348,
        437,
        632,
        750,
        1293,
        1317,
        1238,
        1138,
        481
      ],
      "water": [
        4.92594750338267,
        7.94083639419962,
        8.47671954402556,
        12.5861957457901,
        8.00842643944158,
        4.94687245898076,
        24.6197543666967,
        34.0128899471328,
        37.8501374199449,
        25.4542981216295,
        27.377857767761,
        21.453811853317,
        19.7087738471895,
        14.1190590908039,
        12.4946838179436,
        19.5455911343523,
        14.7647965795382,
        18.7075731379638,
        33.240154728198,
        41.2084234724812,
        43.8313363421708,
        37.9727678313426,
        21.5764784954061,
        10.6429291940788
      ]
    },
    {
      "date": "2/2/2019",
      "energy": [
        987,
        730,
        172,
        106,
        190,
        333,
        398,
        650,
        874,
        694,
        765,
        776,
        1167,
        1580,
        1658,
        1365,
        1362,
        1849,
        1596,
        1581,
        1662,
        1309,
        1083,
        957
      ],
      "water": [
        8.38059785686221,
        4.73899903538449,
        3.50034609466588,
        13.1881995016579,
        8.38275676486125,
        8.74642712894259,
        18.3615648751847,
        30.29151602747,
        43.4744823132548,
        26.1061550876938,
        29.8937130016797,
        19.961506039815,
        22.8908105310978,
        27.3093510130582,
        20.0300516908496,
        16.5162040072088,
        17.5705469115003,
        21.8041374224996,
        32.7701162524766,
        41.6325796268467,
        45.8041669173284,
        44.1109063938073,
        23.4927399646192,
        24.5314357677889
      ]
    },
    {
      "date": "3/2/2019",
      "energy": [
        890,
        793,
        184,
        168,
        182,
        394,
        342,
        688,
        817,
        778,
        769,
        850,
        1169,
        1589,
        1618,
        1293,
        1296,
        1789,
        1695,
        1590,
        1664,
        1262,
        1122,
        883
      ],
      "water": [
        4.7515643067152,
        6.81025445664328,
        7.18109855754329,
        11.0352081315388,
        8.85490461322681,
        12.311473218285,
        19.8546968535722,
        32.4939208899428,
        38.8242579626322,
        30.6497733838179,
        24.6435972705259,
        21.2047249663933,
        20.6430343061676,
        21.0222683230766,
        20.2889523296632,
        16.9912232542171,
        18.9383380792397,
        22.6051900859726,
        38.9631285249577,
        44.8223600323703,
        49.5777647676607,
        42.7889155366102,
        26.384231314934,
        22.3047715982236
      ]
    }
  ]
}
