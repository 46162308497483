'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomScenes', [
    '$rootScope',
    '$timeout',
    'BAS_API',
    'BAS_SCENE',
    'BAS_ROOM',
    'BAS_MODAL',
    'BAS_ERRORS',
    'CurrentBasCore',
    'BasSceneCtrl',
    'BasScene',
    'BasSharedServerStorage',
    'BasModal',
    'BasUtilities',
    basRoomScenesFactory
  ])

/**
 * @param $rootScope
 * @param $timeout
 * @param BAS_API
 * @param {BAS_SCENE} BAS_SCENE
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasSceneCtrl
 * @param BasScene
 * @param {BasSharedServerStorage} BasSharedServerStorage
 * @param {BasModal} BasModal
 * @param {BasUtilities} BasUtilities
 * @returns BasRoomScenes
 */
function basRoomScenesFactory (
  $rootScope,
  $timeout,
  BAS_API,
  BAS_SCENE,
  BAS_ROOM,
  BAS_MODAL,
  BAS_ERRORS,
  CurrentBasCore,
  BasSceneCtrl,
  BasScene,
  BasSharedServerStorage,
  BasModal,
  BasUtilities
) {
  var CSS_CAN_ADD = 'brs-can-add'
  var CSS_CAN_EDIT = 'brs-show-edit'
  var CSS_HAS_FAVOURITES = 'brs-has-favourites'
  var CSS_HAS_NO_FAVOURITES = 'brs-has-no-favourites'
  var CSS_HAS_NON_FAVOURITES = 'brs-has-non-favourites'
  var CSS_HAS_NO_NON_FAVOURITES = 'brs-has-no-non-favourites'
  var CSS_FAVOURITES_COUNT_AT_LEAST_4 = 'brs--favourites-count-at-least-4'
  var CSS_FAVOURITES_COUNT_AT_LEAST_5 = 'brs--favourites-count-at-least-5'
  var CSS_FAVOURITES_COUNT_AT_LEAST_6 = 'brs--favourites-count-at-least-6'
  var CSS_FAVOURITES_COUNT_AT_LEAST_7 = 'brs--favourites-count-at-least-7'
  var CSS_FAVOURITES_COUNT_AT_LEAST_8 = 'brs--favourites-count-at-least-8'
  var CSS_FAVOURITES_COUNT_AT_LEAST_9 = 'brs--favourites-count-at-least-9'
  var CSS_FAVOURITES_COUNT_AT_LEAST_10 = 'brs--favourites-count-at-least-10'

  var CSS_ADD_FAVOURITE = 'brs-add-favourite'
  var CSS_HAS_ON_OFF = 'brs-has-on-off'

  var _MIGRATION_TIMEOUT_MS = 1000
  var _MIGRATION_SYNC_TIMEOUT_MS = 300

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasSharedServerStorageState}
   */
  var basSharedServerStorage = BasSharedServerStorage.get()

  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomScenes (basRoom) {

    /**
     * @type {Object<string, BasScene>}
     */
    this.scenes = {}

    /**
     * All scenes (including ON/OFF scenes)
     *
     * @type {string[]}
     */
    this.uiAllScenes = []

    /**
     * All scenes (except ON/OFF scenes)
     *
     * @type {string[]}
     */
    this.uiScenes = []

    /**
     * Favourite scenes
     *
     * @type {string[]}
     */
    this.uiFavouriteScenes = []

    /**
     * All scenes not in uiFavouriteScenes
     *
     * @type {string[]}
     */
    this.uiNonFavouriteScenes = []

    /**
     * Favourite scenes, first 4 with gaps
     *
     * @type {string[]}
     */
    this.uiQuadFavouriteScenes = ['', '', '', '']

    /**
     * Favourite scenes in key-value format to check in template
     *
     * @type {Object<string, boolean>}
     */
    this.favouriteScenes = {}

    /**
     * @type {string}
     */
    this.uiSceneOn = ''

    /**
     * @type {string}
     */
    this.uiSceneOff = ''

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    /**
     * @private
     * @type {?BasSceneCtrl}
     */
    this.basSceneCtrl = null

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this._resetCss()

    /**
     * @private
     * @type {number}
     */
    this._migrationTimeoutId = 0

    /**
     * @private
     * @type {?Promise}
     */
    this._migrationSyncTimePromise = null

    this._handleNewScene = this._onNewScene.bind(this)

    this._doCompareScenesOnOrder = this._compareScenesOnOrder.bind(this)

    this._doMigrate = this._migrate.bind(this)
    this._doSyncScenes = this._syncScenes.bind(this)

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomScenes.hasScenes = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEString(room.room.sceneCtrl)
    )
  }

  /**
   * @param {string[]} favourites
   * @returns {boolean}
   */
  BasRoomScenes.isValidFavourites = function (favourites) {

    return Array.isArray(favourites) && favourites.length === 4
  }

  /**
   * Device UUID for the scene controller device.
   *
   * @returns {string}
   */
  BasRoomScenes.prototype.getSceneCtrlDeviceUuid = function () {

    if (this._basRoom &&
      this._basRoom.room &&
      BasUtil.isNEString(this._basRoom.room.sceneCtrl)) {

      return this._basRoom.room.sceneCtrl
    }

    return ''
  }

  /**
   * @private
   * @returns {?SceneCtrlDevice}
   */
  BasRoomScenes.prototype._getDeviceFromAPI = function () {

    var _deviceUuid

    _deviceUuid = this.getSceneCtrlDeviceUuid()

    return _deviceUuid ? CurrentBasCore.getDevice(_deviceUuid) : null
  }

  /**
   * @private
   * @returns {?SceneCtrlDevice}
   */
  BasRoomScenes.prototype._getSceneCtrlDevice = function () {

    return this.basSceneCtrl ? this.basSceneCtrl.getDevice() : null
  }

  BasRoomScenes.prototype.parseRoom = function () {

    var device

    this._clearSceneCtrls()

    if (BasRoomScenes.hasScenes(this._basRoom)) {

      device = CurrentBasCore.getDevice(this._basRoom.room.sceneCtrl)

      if (device) {

        this.basSceneCtrl = new BasSceneCtrl(device, this)
        this._syncCssSceneCtrl()

        this._handleMigration()
      }

      this._syncScenes()
    }
  }

  /**
   * Sync all scenes from all scene controllers
   *
   * @private
   */
  BasRoomScenes.prototype._syncScenes = function () {

    var device

    device = this._getSceneCtrlDevice()

    if (device) this._syncScenesFromSceneCtrlDevice(device)

    this._generateUiScenes()
  }

  /**
   * @private
   * @param {SceneCtrlDevice} device
   */
  BasRoomScenes.prototype._syncScenesFromSceneCtrlDevice = function (device) {

    const previousScenes = Object.keys(this.scenes)

    const scenes = device.scenes
    for (const uuid in scenes) {

      if (BasUtil.isObject(scenes[uuid])) {

        const scene = this.scenes[uuid]

        if (scene) {

          // Reparse existing scene to prevent loss of css (spinner)
          scene.updateBasRoom(this._basRoom)

        } else {

          // Create new scene
          this.scenes[uuid] = new BasScene(uuid, this._basRoom)
        }

        const previousIndex = previousScenes.indexOf(uuid)
        if (previousIndex > -1) previousScenes.splice(previousIndex, 1)
      }
    }

    // Remove scenes that were previously here but no longer are
    previousScenes.forEach(uuid => {
      delete this.scenes[uuid]
    })
  }

  /**
   * Generates all UI scene collections (string, string arrays)
   *
   * @private
   */
  BasRoomScenes.prototype._generateUiScenes = function () {

    var keys, length, i, key, scene

    this.uiScenes = []
    this.uiSceneOn = ''
    this.uiSceneOff = ''

    keys = Object.keys(this.scenes)
    length = keys.length
    for (i = 0; i < length; i++) {

      key = keys[i]
      scene = this.scenes[key]

      if (scene) {

        // Room scenes

        if (scene.isOnScene()) {

          this.uiSceneOn = key

        } else if (scene.isOffScene()) {

          this.uiSceneOff = key

        } else {

          this.uiScenes.push(key)
        }
      }
    }

    this._generateAndOrderScenes()

    this._checkEdit()
  }

  BasRoomScenes.prototype._generateAndOrderScenes = function () {

    this._orderScenes()
    this._generateFavouriteScenes()
    this._generateAllScenes()
  }

  /**
   * @private
   * @param {string[]} [favourites]
   */
  BasRoomScenes.prototype._generateFavouriteScenes = function (favourites) {

    var _favourites, length, i, uuid, scene

    this.uiFavouriteScenes = []
    this.uiNonFavouriteScenes = []
    this.uiQuadFavouriteScenes = ['', '', '', '']
    this.favouriteScenes = {}

    _favourites = Array.isArray(favourites)
      ? favourites
      : this._getScenesFavourites()

    if (Array.isArray(_favourites)) {

      length = this.uiScenes.length
      for (i = 0; i < length; i++) {

        uuid = this.uiScenes[i]

        if (_favourites.indexOf(uuid) !== -1) {

          this.uiFavouriteScenes.push(uuid)

        } else {

          this.uiNonFavouriteScenes.push(uuid)
        }
      }

      // Alternate uiFavouriteScenes format
      length = this.uiFavouriteScenes.length
      for (i = 0; i < length; i++) {

        this.favouriteScenes[this.uiFavouriteScenes[i]] = true
      }

      // Quad favourites

      for (i = 0; i < 4; i++) {

        uuid = _favourites[i]

        if (uuid && this.uiScenes.indexOf(uuid) !== -1) {

          this.uiQuadFavouriteScenes[i] = uuid

          scene = this.scenes[uuid]

          // Set scene position
          if (scene && scene.setPosition) scene.setPosition(i)

        } else {

          this.uiQuadFavouriteScenes[i] = ''
        }
      }

    } else {

      this.uiNonFavouriteScenes = this.uiScenes.slice()
    }

    this._syncCssScenes()
  }

  /**
   * Generates all UI scenes (including All ON / OFF scenes)
   *
   * @private
   */
  BasRoomScenes.prototype._generateAllScenes = function () {

    this.uiAllScenes = [...this.uiScenes]

    if (this.uiSceneOff) {
      this.uiAllScenes.unshift(this.uiSceneOff)
    }
    if (this.uiSceneOn) {
      this.uiAllScenes.unshift(this.uiSceneOn)
    }
  }

  /**
   * Get the scenes order for the scene controller.
   *
   * @private
   * @returns {?(string[])}
   */
  BasRoomScenes.prototype._getScenesOrder = function () {

    var roomUuid, data

    roomUuid = this._basRoom ? this._basRoom.id : ''

    if (roomUuid) {

      data = basSharedServerStorage.sceneOrder

      if (BasUtil.isObject(data) &&
        Array.isArray(data[roomUuid])) {

        return data[roomUuid]
      }
    }

    return null
  }

  /**
   * Get the scenes favourites for the scene controller.
   *
   * @private
   * @returns {?(string[])}
   */
  BasRoomScenes.prototype._getScenesFavourites = function () {

    var roomUuid, data

    roomUuid = this._basRoom ? this._basRoom.id : ''

    if (roomUuid) {

      data = basSharedServerStorage.sceneFavourites

      if (BasUtil.isObject(data) &&
        Array.isArray(data[roomUuid])) {

        return data[roomUuid]
      }
    }

    return null
  }

  BasRoomScenes.prototype._orderScenes = function () {

    var scenesOrder
    var sorted, unmatched, length, i, uuid

    scenesOrder = this._getScenesOrder()

    if (scenesOrder) {

      sorted = []

      // Match UUID's from server storage

      length = scenesOrder.length
      for (i = 0; i < length; i++) {

        uuid = scenesOrder[i]

        if (this.uiScenes.indexOf(uuid) !== -1) {

          sorted.push(uuid)
        }
      }

      // Match other UUID's

      unmatched = []

      length = this.uiScenes.length
      for (i = 0; i < length; i++) {

        uuid = this.uiScenes[i]

        if (sorted.indexOf(uuid) === -1) {

          unmatched.push(uuid)
        }
      }

      // Sort the unmatched scenes with their order property
      unmatched.sort(this._doCompareScenesOnOrder)

      if (unmatched.length > 0) {

        sorted = sorted.concat(unmatched)
      }

      this.uiScenes = sorted

    } else {

      this.uiScenes.sort(this._doCompareScenesOnOrder)
    }
  }

  BasRoomScenes.prototype._orderUiNonFavouriteScenes = function () {

    var sorted, length, i, uuid

    sorted = []

    length = this.uiScenes.length
    for (i = 0; i < length; i++) {

      uuid = this.uiScenes[i]

      if (this.uiNonFavouriteScenes.indexOf(uuid) !== -1) {

        sorted.push(uuid)
      }
    }

    this.uiNonFavouriteScenes = sorted
  }

  /**
   * Sync CSS classes for ability to add scenes.
   *
   * @private
   */
  BasRoomScenes.prototype._syncCssSceneCtrl = function () {

    this.css[CSS_CAN_ADD] = this.basSceneCtrl
      ? this.basSceneCtrl.canCreateScenes()
      : false
  }

  /**
   * Sync CSS classes for has favourites, has non favourites, ...
   *
   * @private
   */
  BasRoomScenes.prototype._syncCssScenes = function () {

    var count, has, hasOnOrOff

    // Favourites

    count = this.uiFavouriteScenes.length
    has = count > 0

    this.css[CSS_HAS_FAVOURITES] = has
    this.css[CSS_HAS_NO_FAVOURITES] = !has

    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_4] = count > 3
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_5] = count > 4
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_6] = count > 5
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_7] = count > 6
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_8] = count > 7
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_9] = count > 8
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_10] = count > 9

    hasOnOrOff = this.uiSceneOff || this.uiSceneOn

    this.css[CSS_ADD_FAVOURITE] = !has
    this.css[CSS_HAS_ON_OFF] = hasOnOrOff

    // Non-Favourites

    has = this.uiNonFavouriteScenes.length > 0

    this.css[CSS_HAS_NON_FAVOURITES] = has
    this.css[CSS_HAS_NO_NON_FAVOURITES] = !has
  }

  /**
   * Attempts to return the first scene UUID of the list.
   *
   * @returns {string}
   */
  BasRoomScenes.prototype.getFirstSceneUuid = function () {

    var length

    length = this.uiFavouriteScenes.length

    if (length > 0) return this.uiFavouriteScenes[0]

    length = this.uiNonFavouriteScenes.length

    if (length > 0) return this.uiNonFavouriteScenes[0]

    return ''
  }

  /**
   * @returns {?(string[])}
   */
  BasRoomScenes.prototype.getRawFavourites = function () {

    return this._getScenesFavourites()
  }

  /**
   * @param {string[]} scenes UUIDs
   */
  BasRoomScenes.prototype.setFavourites = function (scenes) {

    if (Array.isArray(scenes)) {

      this._generateFavouriteScenes(scenes)
      this._saveFavourites(scenes)
    }
  }

  /**
   * @returns {Promise<BasScene>}
   */
  BasRoomScenes.prototype.addNewScene = function () {

    var device

    if (this.basSceneCtrl &&
      this.basSceneCtrl.canCreateScenes()) {

      device = this.basSceneCtrl.getDevice()

      if (device) {

        return device.addScene(this.getNewSceneName())
          .then(this._handleNewScene)
      }
    }
    return Promise.reject('No scene ctrl found')
  }

  /**
   * @private
   * @param {Scene} result
   * @returns {(BasScene|Promise)}
   */
  BasRoomScenes.prototype._onNewScene = function (result) {

    var scene, uuid

    if (BasUtil.isObject(result) &&
      BasUtil.isNEString(result.uuid)) {

      uuid = result.uuid
      scene = new BasScene(uuid, this._basRoom)

      this.scenes[uuid] = scene

      this._generateUiScenes()

      return scene
    }

    return Promise.reject('Invalid result')
  }

  /**
   * @param {string} scene
   */
  BasRoomScenes.prototype.activateScene = function (scene) {

    const sceneObj = this.scenes[scene]

    if (sceneObj) this._activateScene(sceneObj)
  }

  /**
   * @param {string} sceneUuid
   */
  BasRoomScenes.prototype.activateOnOff = function (sceneUuid) {

    let scene

    if (this.uiSceneOn === sceneUuid) {

      scene = this.scenes[this.uiSceneOn]

    } else if (this.uiSceneOff === sceneUuid) {

      scene = this.scenes[this.uiSceneOff]
    }

    if (scene) this._activateScene(scene)
  }

  /**
   * @param {string} sceneId
   */
  BasRoomScenes.prototype.removeScene = function (sceneId) {

    var idx

    idx = this.uiScenes.indexOf(sceneId)

    if (idx !== -1) {

      this.uiScenes.splice(idx, 1)
      this.scenes[sceneId] = null

      this._generateUiScenes()
    }

    this._removeScene(sceneId)
  }

  /**
   * Activate the scene from the core
   *
   * @private
   * @param {BasScene} scene
   */
  BasRoomScenes.prototype._activateScene = function (scene) {

    const uuid = this.basSceneCtrl?.uuid

    if (BasUtil.isNEString(uuid)) {

      return scene.activate(uuid)
        .catch(error => {
          if (error !== BAS_ERRORS.T_ABORT) BasModal.show(BAS_MODAL.T_ERROR)
        })
    }

    return Promise.reject('No basSceneCtrl')
  }

  /**
   * Removes the scene from the core
   *
   * @private
   * @param {string} sceneUuid
   */
  BasRoomScenes.prototype._removeScene = function (sceneUuid) {

    var device

    if (BasUtil.isNEString(sceneUuid)) {

      device = this._getSceneCtrlDevice()

      if (device) device.removeScene(sceneUuid)
    }
  }

  /**
   * Learn/update the scene
   *
   * @param {string} sceneUuid
   */
  BasRoomScenes.prototype.learnScene = function (sceneUuid) {

    var device

    if (BasUtil.isNEString(sceneUuid)) {

      device = this._getSceneCtrlDevice()

      if (device) device.learnScene(sceneUuid)
    }
  }

  /**
   * @param {string} sceneUuid
   */
  BasRoomScenes.prototype.toggleSceneFavourite = function (sceneUuid) {

    var idx

    if (BasUtil.isNEString(sceneUuid) &&
      this.uiScenes.indexOf(sceneUuid) !== -1) {

      idx = this.uiFavouriteScenes.indexOf(sceneUuid)

      if (idx !== -1) {

        // Item is in uiFavouriteScenes

        this.uiFavouriteScenes.splice(idx, 1)
        this.favouriteScenes[sceneUuid] = false
        this.uiNonFavouriteScenes.push(sceneUuid)

        this._orderUiNonFavouriteScenes()
        this._saveFavourites()

      } else {

        // Item is not in uiFavouriteScenes

        idx = this.uiNonFavouriteScenes.indexOf(sceneUuid)

        if (idx !== -1) {

          this.uiNonFavouriteScenes.splice(idx, 1)
          this.uiFavouriteScenes.push(sceneUuid)
          this.favouriteScenes[sceneUuid] = true

          this._saveFavourites()

        } else {

          // This should not occur
        }
      }

      this._syncCssScenes()
    }
  }

  /**
   * @private
   * @param {string[]} [favourites]
   */
  BasRoomScenes.prototype._saveFavourites = function (favourites) {

    var _sharedStorage, _favourites

    if (this._basRoom &&
      CurrentBasCore.hasCore()) {

      _sharedStorage = currentBasCoreState.core.core.sharedServerStorage

      if (_sharedStorage) {

        _favourites = Array.isArray(favourites)
          ? favourites
          : this.uiFavouriteScenes

        if (Array.isArray(_favourites)) {

          _sharedStorage.updateScenesFavourites(
            this._basRoom.id,
            _favourites
          )
        }
      }
    }
  }

  /**
   * @param {number} spliceIdx
   * @param {number} originalIdx
   */
  BasRoomScenes.prototype.reorderUiScenes = function (
    spliceIdx,
    originalIdx
  ) {
    var original

    original = this.uiScenes[originalIdx]

    if (original) {

      this.uiScenes.splice(originalIdx, 1)
      this.uiScenes.splice(spliceIdx, 0, original)

      this._generateAndOrderScenes()

      this._saveOrder()
    }
  }

  /**
   * @param {number} spliceIdx
   * @param {number} originalIdx
   */
  BasRoomScenes.prototype.reorderUiFavourites = function (
    spliceIdx,
    originalIdx
  ) {
    var original

    original = this.uiFavouriteScenes[originalIdx]

    if (original) {

      this.uiFavouriteScenes.splice(originalIdx, 1)
      this.uiFavouriteScenes.splice(spliceIdx, 0, original)

      this._saveOrder()
    }
  }

  /**
   * @param {number} spliceIdx
   * @param {number} originalIdx
   */
  BasRoomScenes.prototype.reorderUiNonFavourites = function (
    spliceIdx,
    originalIdx
  ) {
    var original

    original = this.uiNonFavouriteScenes[originalIdx]

    if (original) {

      this.uiNonFavouriteScenes.splice(originalIdx, 1)
      this.uiNonFavouriteScenes.splice(spliceIdx, 0, original)

      this._saveOrder()
    }
  }

  /**
   * Save current scenes order to shared storage
   *
   * @private
   */
  BasRoomScenes.prototype._saveOrder = function () {

    var newOrder, length, i, uuid

    if (this._basRoom &&
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.sharedServerStorage) {

      newOrder = []

      length = this.uiFavouriteScenes.length
      for (i = 0; i < length; i++) {

        uuid = this.uiFavouriteScenes[i]

        if (uuid) newOrder.push(uuid)
      }

      length = this.uiNonFavouriteScenes.length
      for (i = 0; i < length; i++) {

        uuid = this.uiNonFavouriteScenes[i]

        if (uuid) newOrder.push(uuid)
      }

      currentBasCoreState.core.core.sharedServerStorage
        .updateScenesOrder(
          this._basRoom.id,
          newOrder
        )
    }
  }

  /**
   * @param {string} [title]
   * @returns {string}
   */
  BasRoomScenes.prototype.getNewSceneName = function (title) {

    var _this, name, number, length

    _this = this

    number = 1
    length = this.uiScenes.length

    name = BasUtil.isNEString(title)
      ? title
      : BasUtilities.translate('new_scene')

    while (_nameUsed(_getName())) {
      number++
    }

    return _getName()

    function _getName () {

      return name + ' ' + number
    }

    function _nameUsed (scene) {

      var i

      for (i = 0; i < length; i++) {

        if (_this.scenes[_this.uiScenes[i]].name === scene) return true
      }

      return false
    }
  }

  BasRoomScenes.prototype.updateTranslation = function updateTranslation () {

    var keys, length, i, key, scene

    keys = Object.keys(this.scenes)
    length = keys.length
    for (i = 0; i < length; i++) {

      key = keys[i]
      scene = this.scenes[key]

      if (scene) scene.updateTranslation()
    }
  }

  BasRoomScenes.prototype.updateTemperatureUnit = function () {

    var keys, length, i, key, scene

    keys = Object.keys(this.scenes)
    length = keys.length
    for (i = 0; i < length; i++) {

      key = keys[i]
      scene = this.scenes[key]

      if (scene) scene.updateTemperatureUnit()
    }
  }

  BasRoomScenes.prototype.handleScenesChanged = function () {

    this._syncScenes()

    $rootScope.$emit(BAS_ROOM.EVT_SCENES_UPDATED, this._basRoom, this)
  }

  BasRoomScenes.prototype.handleFavouritesChanged = function () {

    this._generateFavouriteScenes()

    $rootScope.$emit(BAS_ROOM.EVT_SCENES_UPDATED, this._basRoom, this)
  }

  /**
   * @param {Scene} scene
   */
  BasRoomScenes.prototype.handleSceneAdded = function (scene) {

    var uuid

    if (BasUtil.isObject(scene) &&
      BasUtil.isNEString(scene.uuid)) {

      uuid = scene.uuid

      this.scenes[uuid] = new BasScene(uuid, this._basRoom)

      this._generateUiScenes()

      $rootScope.$emit(BAS_ROOM.EVT_SCENES_UPDATED, this._basRoom, this)
    }
  }

  /**
   * @param {Scene} scene
   */
  BasRoomScenes.prototype.handleSceneChanged = function (scene) {

    if (BasUtil.isObject(scene) &&
      BasUtil.isNEString(scene.uuid) &&
      BasUtil.isObject(this.scenes[scene.uuid])) {

      this.scenes[scene.uuid].parseScene(scene)

      $rootScope.$emit(BAS_ROOM.EVT_SCENES_UPDATED, this._basRoom, this)
      $rootScope.$emit(
        BAS_SCENE.EVT_SCENE_UPDATED,
        this._basRoom,
        this,
        scene.uuid
      )
    }
  }

  /**
   * @param {Scene} scene
   */
  BasRoomScenes.prototype.handleSceneRemoved = function (scene) {

    if (BasUtil.isObject(scene) &&
      BasUtil.isNEString(scene.uuid)) {

      this.scenes[scene.uuid] = null

      this._generateUiScenes()

      $rootScope.$emit(BAS_ROOM.EVT_SCENES_UPDATED, this._basRoom, this)
      $rootScope.$emit(BAS_ROOM.EVT_BAS_SCENE_REMOVED, scene.uuid)
    }
  }

  /**
   * @param {Scene} scene
   */
  BasRoomScenes.prototype.handleSceneImagesUpdated = function (scene) {

    if (BasUtil.isObject(scene) &&
      BasUtil.isNEString(scene.uuid) &&
      BasUtil.isObject(this.scenes[scene.uuid])) {

      this.scenes[scene.uuid].parseScene(scene)

      $rootScope.$emit(
        BAS_SCENE.EVT_IMAGES_UPDATED,
        this._basRoom,
        this
      )
    }
  }

  BasRoomScenes.prototype.onOrderUpdated = function () {

    if (this._migrationTimeoutId) {

      this._clearMigrationTimeout()
      this._handleMigration()
    }

    this._generateAndOrderScenes()

    $rootScope.$emit(BAS_ROOM.EVT_SCENES_UPDATED, this._basRoom, this)
  }

  BasRoomScenes.prototype.onFavouritesUpdated = function () {

    if (this._migrationTimeoutId) {

      this._clearMigrationTimeout()
      this._handleMigration()
    }

    this.handleFavouritesChanged()
  }

  /**
   * @returns Promise
   */
  BasRoomScenes.prototype.updateFavourites = function () {

    var sceneCtrl

    sceneCtrl = CurrentBasCore.getDevice(this._basRoom.room.sceneCtrl)

    if (sceneCtrl) {

      return sceneCtrl.updateFavourites(this.uiFavouriteScenes)
    }

    return Promise.reject('No Device')
  }

  /**
   * @param {?BasScene} scene
   * @returns Promise
   */
  BasRoomScenes.prototype.updateScene = function (scene) {

    var device

    if (scene) {

      device = this._getSceneCtrlDevice()

      if (device) return device.updateScene(scene.scene)

      return Promise.reject('No Device')
    }

    return Promise.reject('No Scene')
  }

  /**
   * @param {string} uuid
   * @param {string} unsavedImage
   * @returns {Promise}
   */
  BasRoomScenes.prototype.updateImage = function (uuid, unsavedImage) {

    var device

    if (BasUtil.isNEString(uuid)) {

      device = this._getSceneCtrlDevice()

      if (device) return device.updateImage(uuid, unsavedImage)

      return Promise.reject('No Device')
    }

    return Promise.reject('No Scene')
  }

  /**
   * Handle scenes migration (App version 4.4.0 and higher)
   *
   * @private
   */
  BasRoomScenes.prototype._handleMigration = function () {

    var roomUuid

    roomUuid = this._basRoom ? this._basRoom.id : ''

    if (roomUuid &&
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.sharedServerStorage) {

      if (currentBasCoreState.core.core.sharedServerStorage.dirty) {

        this._migrationTimeoutId = setTimeout(
          this._doMigrate,
          _MIGRATION_TIMEOUT_MS
        )

      } else {

        this._migrate()
      }
    }
  }

  /**
   * Migrate pre 4.4.0 scenes (favourites) to new way of working with scenes
   * (shared server storage for both order and favourites)
   * The first home and away scenes are also added to favorites
   *
   * @private
   */
  BasRoomScenes.prototype._migrate = function () {

    var sharedStorage, roomUuid, data, newFavourites, newOrder, device
    var scenes, favourites, keys, length, i, uuid, scene, sorted
    var homeSceneUuid, awaySceneUuid

    this._clearMigrationTimeout()

    sharedStorage = null

    roomUuid = this._basRoom ? this._basRoom.id : ''

    if (roomUuid &&
      CurrentBasCore.hasCore()) {

      sharedStorage = currentBasCoreState.core.core.sharedServerStorage

      if (sharedStorage.dirty) sharedStorage = null
    }

    if (sharedStorage) {

      data = sharedStorage.scenesFavourites

      if (!Array.isArray(data[roomUuid])) {

        newFavourites = []
        newOrder = []

        device = this._getSceneCtrlDevice()

        if (device) {

          if (this._basRoom.isHome()) {

            scenes = device.scenes

            keys = Object.keys(scenes)
            length = keys.length
            for (i = 0; i < length; i++) {

              uuid = keys[i]
              scene = scenes[uuid]

              if (BasUtil.isObject(scene)) {

                if (scene.template ===
                  BAS_API.Scene.TEMPLATES.HOME &&
                  !homeSceneUuid) {

                  homeSceneUuid = uuid

                } else if (scene.template ===
                  BAS_API.Scene.TEMPLATES.AWAY &&
                  !awaySceneUuid) {

                  awaySceneUuid = uuid

                  // "scene.favourite" determined if a home
                  // scene should be visible on the dashboard
                } else if (scene.favourite) {

                  newFavourites.push(uuid)
                }
              }
            }

            sorted = []

            if (homeSceneUuid ||
              awaySceneUuid) {

              if (homeSceneUuid) {

                sorted.push(homeSceneUuid)
                newOrder.push(homeSceneUuid)
              }

              if (awaySceneUuid) {

                sorted.push(awaySceneUuid)
                newOrder.push(awaySceneUuid)
              }
            }

            length = newFavourites.length

            if (length > 0) {

              // Favourites was previously misused to save the
              // order of all home scenes
              favourites = device.favourites

              if (Array.isArray(favourites)) {

                length = favourites.length
                for (i = 0; i < length; i++) {

                  uuid = favourites[i]

                  if (newFavourites.indexOf(uuid) !== -1) {

                    sorted.push(uuid)
                  }

                  if (newOrder.indexOf(uuid) === -1) {

                    newOrder.push(uuid)
                  }
                }

                length = newFavourites.length
                for (i = 0; i < length; i++) {

                  uuid = newFavourites[i]

                  if (sorted.indexOf(uuid) === -1) {

                    sorted.push(uuid)
                  }
                }

                newFavourites = sorted
              }
            }

            sharedStorage.updateScenesOrder(
              roomUuid,
              newOrder
            )

          } else if (this._basRoom.isRoom()) {

            favourites = device.favourites

            if (Array.isArray(favourites)) {

              newFavourites = favourites
            }
          }
        }

        sharedStorage.updateScenesFavourites(
          roomUuid,
          newFavourites
        )

        this._migrationSyncTimePromise = $timeout(
          this._doSyncScenes,
          _MIGRATION_SYNC_TIMEOUT_MS
        )
      }
    }
  }

  BasRoomScenes.prototype._clearMigrationTimeout = function () {

    clearTimeout(this._migrationTimeoutId)
    this._migrationTimeoutId = 0
  }

  BasRoomScenes.prototype._clearMigrationSyncTimeout = function () {

    $timeout.cancel(this._migrationSyncTimePromise)
    this._migrationSyncTimePromise = null
  }

  /**
   * Compares Scenes on order.
   * Takes 2 Scenes UUID's
   *
   * @private
   * @param {string} sceneId1
   * @param {string} sceneId2
   * @returns {number}
   */
  BasRoomScenes.prototype._compareScenesOnOrder = function (
    sceneId1,
    sceneId2
  ) {
    var scene1, scene2

    scene1 = this.scenes[sceneId1]
    scene2 = this.scenes[sceneId2]

    // Order "All ON" scene on top
    if (sceneId1 === this.uiSceneOn) return -1
    // Order "All OFF" scene on top
    if (sceneId1 === this.uiSceneOff) return -1

    // Order on scenes order
    if (scene1 && scene2) return scene1.order - scene2.order
    // 1 comes first
    if (scene1) return -1
    // 2 comes first
    if (scene2) return 1

    return 0
  }

  BasRoomScenes.prototype._checkEdit = function () {

    var length, i, scene

    this.css[CSS_CAN_EDIT] = false

    length = this.uiScenes.length
    for (i = 0; i < length; i++) {

      scene = this.scenes[this.uiScenes[i]]

      if (scene && scene.isEditable && scene.isEditable()) {

        this.css[CSS_CAN_EDIT] = true
        return
      }
    }
  }

  BasRoomScenes.prototype._clearSceneCtrls = function () {

    if (this.basSceneCtrl) this.basSceneCtrl.clear()
    this.basSceneCtrl = null
  }

  BasRoomScenes.prototype._suspendSceneCtrls = function () {

    if (this.basSceneCtrl) this.basSceneCtrl.suspend()
  }

  BasRoomScenes.prototype.suspend = function () {

    this._clearMigrationTimeout()
    this._clearMigrationSyncTimeout()

    this._suspendSceneCtrls()
  }

  BasRoomScenes.prototype.clear = function () {

    this.suspend()
    this._clearSceneCtrls()
  }

  BasRoomScenes.prototype._resetCss = function () {

    this.css[CSS_CAN_ADD] = false
    this.css[CSS_CAN_EDIT] = false
    this.css[CSS_HAS_FAVOURITES] = false
    this.css[CSS_HAS_NO_FAVOURITES] = false
    this.css[CSS_HAS_NON_FAVOURITES] = false
    this.css[CSS_HAS_NO_NON_FAVOURITES] = false
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_4] = false
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_5] = false
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_6] = false
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_7] = false
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_8] = false
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_9] = false
    this.css[CSS_FAVOURITES_COUNT_AT_LEAST_10] = false
    this.css[CSS_ADD_FAVOURITE] = false
    this.css[CSS_HAS_ON_OFF] = false
  }

  BasRoomScenes.prototype.destroy = function () {

    this.clear()
  }

  return BasRoomScenes
}
