'use strict'

angular
  .module('basalteApp')
  .constant('BAS_LIBRARY_ERRORS', {
    INVALID_REQUEST: 'Invalid request object',
    INVALID_RESULT: 'Invalid result',
    INVALID_ID: 'Invalid id',
    INVALID_ELEMENT: 'Invalid element',
    INVALID_NAME: 'Invalid name',
    INVALID_TYPE: 'Invalid type',
    INVALID_PARAM: 'Invalid param',
    INVALID_BAS_SOURCE: 'Invalid BasSource',
    ALREADY_FETCHING: 'Already fetching',
    ALREADY_FETCHING_END: 'Already fetching / End reached',
    DOUBLE_DATA: 'Double data',
    NO_DEEZER: 'No Deezer',
    NO_TIDAL: 'No Tidal',
    NO_SPOTIFY: 'No Spotify',
    NO_PLAYLISTS: 'No Playlists',
    NO_DATABASE: 'No Database',
    NO_RESULT: 'No result',
    NO_RETRIEVE_FUNCTION: 'No retrieve function',
    NOT_LINKED: 'Not linked'
  })
