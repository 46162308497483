'use strict'

angular
  .module('basalteApp')
  .constant('BAS_SCENE_PRESETS', {
    /**
     * @type {Object<string, BasScenePreset>}
     */
    PRESETS: {},
    /**
     * @type {string[]}
     */
    UI_PRESETS: [],
    /**
     * @type {string[]}
     */
    UI_PRESETS_WITH_CUSTOM: [],
    K_CUSTOM: '',
    K_UNKNOWN: 'UNKNOWN',
    N_UNKNOWN: -1
  })
