'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasStoredUser', [
    'BAS_APP_STORAGE',
    basStoredUserFactory
  ])

/**
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @returns BasStoredUser
 */
function basStoredUserFactory (
  BAS_APP_STORAGE
) {
  /**
   * @constructor
   * @param {TCoreCredentials} [credentials]
   */
  function BasStoredUser (credentials) {

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.credentialHash = ''

    /**
     * @type {string}
     */
    this.room = ''

    /**
     * @type {Object}
     */
    this.settings = {}

    /**
     * @type {Object<string, string[]>}
     */
    this.history = {}

    if (credentials) this.parseCoreCredentials(credentials)
  }

  /**
   * @param {Object} obj
   * @returns {?BasStoredUser}
   */
  BasStoredUser.parse = function (obj) {

    var result

    result = null

    if (BasUtil.isObject(obj)) {

      result = new BasStoredUser()

      if (BasUtil.isNEString(obj[BAS_APP_STORAGE.K_NAME])) {

        result.name = obj[BAS_APP_STORAGE.K_NAME]

      } else {

        return null
      }

      if (BasUtil.isNEString(obj[BAS_APP_STORAGE.K_CREDENTIAL_HASH])) {

        result.credentialHash = obj[BAS_APP_STORAGE.K_CREDENTIAL_HASH]
      }

      if (BasUtil.isNEString(obj[BAS_APP_STORAGE.K_ROOM])) {

        result.room = obj[BAS_APP_STORAGE.K_ROOM]
      }

      if (BasUtil.isObject(obj[BAS_APP_STORAGE.K_SETTINGS])) {

        result.settings = obj[BAS_APP_STORAGE.K_SETTINGS]
      }

      if (BasUtil.isObject(obj[BAS_APP_STORAGE.K_HISTORY])) {

        result.history = obj[BAS_APP_STORAGE.K_HISTORY]
      }
    }

    return result
  }

  /**
   * Returns whether something has changed
   *
   * @param {TCoreCredentials} credentials
   * @returns {boolean}
   */
  BasStoredUser.prototype.parseCoreCredentials = function (credentials) {

    var value, changed

    changed = false

    if (BasUtil.isObject(credentials)) {

      // Username
      // This should only change when creating new BasStoredUser

      value = credentials.user
      if (this.name !== value) {

        this.name = value
        changed = true
      }

      // Credential hash

      value = credentials.credentialHash
      if (this.credentialHash !== value) {

        this.credentialHash = value
        changed = true
      }
    }

    return changed
  }

  /**
   * Returns whether something has changed
   *
   * @returns {boolean}
   */
  BasStoredUser.prototype.removeLoginCredentials = function () {

    var changed

    changed = BasUtil.isNEString(this.credentialHash)

    this.credentialHash = ''

    return changed
  }

  /**
   * @returns {TBasConnectCredentials}
   */
  BasStoredUser.prototype.getBasConnectCredentials = function () {

    var result

    result = {
      username: this.name
    }

    if (this.credentialHash) result.credentialHash = this.credentialHash

    return result
  }

  /**
   * Create object used to store as JSON.
   *
   * @returns {?Object}
   */
  BasStoredUser.prototype.toStorageDate = function () {

    var result

    result = null

    if (BasUtil.isNEString(this.name)) {

      result = {}

      result[BAS_APP_STORAGE.K_NAME] = this.name

      if (BasUtil.isNEString(this.credentialHash)) {

        result[BAS_APP_STORAGE.K_CREDENTIAL_HASH] =
          this.credentialHash
      }

      if (BasUtil.isNEString(this.room)) {

        result[BAS_APP_STORAGE.K_ROOM] = this.room
      }

      if (BasUtil.isNEObject(this.settings)) {

        result[BAS_APP_STORAGE.K_SETTINGS] = this.settings
      }

      if (BasUtil.isNEObject(this.history)) {

        result[BAS_APP_STORAGE.K_HISTORY] = this.history
      }
    }

    return result
  }

  return BasStoredUser
}
