'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasConnectHelper', [
    'BAS_API',
    'BAS_CORE_CLIENT',
    'BAS_ERRORS',
    'BasDiscovery',
    'BasConnectInfo',
    'BasCoreClientHelper',
    'BasAppDevice',
    'BasError',
    'BasUtilities',
    BasConnectHelper
  ])

/**
 * @typedef {Object} TBasConnectHelperOptions
 * @property {number} [timeout = 5000]
 * @property {boolean} [allowOther = true]
 * @property {string} [preferredProjectId]
 * @property {(string|number)} [preferredMac]
 * @property {string} [preferredAddress]
 */

/**
 * @callback CCheckForCoreClientServerCallback
 * @param {?BasError} error
 * @param {TCheckForCoreClientServer} result
 */

/**
 * @callback CCheckForCoreClientServerAbort
 */

/**
 * @callback CCheckForCoreClientServerCleanup
 */

/**
 * @callback CCheckCoreClientSrvTakeBasServer
 * @returns {?BasServer}
 */

/**
 * @typedef {Object} TCheckForCoreClientServer
 * @property {CCheckForCoreClientServerAbort} abort
 * @property {CCheckForCoreClientServerCleanup} cleanup
 * @property {CCheckCoreClientSrvTakeBasServer} takeBasServerConnectionCandidate
 * @property {boolean} finished
 * @property {?TBasServerConnectionCandidate} [basServerConnectionCandidate]
 */

/**
 * @typedef {Object} TBasServerConnectionCandidate
 * @property {string} id
 * @property {boolean} resolved
 * @property {BasLocalCore} service
 * @property {?BasServer} basServer
 * @property {?TBasCoreClientLoginInfo} [coreClientInfo]
 */

/**
 * @constructor
 * @param BAS_API
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BasDiscovery} BasDiscovery
 * @param BasConnectInfo
 * @param {BasCoreClientHelper} BasCoreClientHelper
 * @param {BasAppDevice} BasAppDevice
 * @param BasError
 * @param {BasUtilities} BasUtilities
 */
function BasConnectHelper (
  BAS_API,
  BAS_CORE_CLIENT,
  BAS_ERRORS,
  BasDiscovery,
  BasConnectInfo,
  BasCoreClientHelper,
  BasAppDevice,
  BasError,
  BasUtilities
) {
  /**
   * @type {TBasDiscoveryState}
   */
  var basDiscoveryState = BasDiscovery.get()

  this.checkForCoreClientServer = checkForCoreClientServer

  /**
   * Check all discovered servers if they have this Core Client
   *
   * @param {TBasConnectHelperOptions} options
   * @param {CCheckForCoreClientServerCallback} callback
   * @returns {TCheckForCoreClientServer}
   */
  function checkForCoreClientServer (
    options,
    callback
  ) {
    var _result, _aborted, _cbCalled, _connections, _timeoutId
    var _timeout, _allowOther
    var _preferredProjectId, _preferredMac, _preferredAddress

    _timeout = 5000
    _allowOther = true

    if (options) {

      if (BasUtil.isPNumber(options.timeout)) _timeout = options.timeout
      if (BasUtil.isBool(options.allowOther)) _allowOther = options.allowOther
      if (BasUtil.isNEString(options.preferredProjectId)) {
        _preferredProjectId = options.preferredProjectId
      }
      if (options.preferredMac) {
        _preferredMac = BasUtilities.getMacNumber(options.preferredMac)
      }
      if (BasUtil.isNEString(options.preferredAddress)) {
        _preferredAddress = options.preferredAddress
      }
    }

    _aborted = false
    _cbCalled = false

    _result = {
      abort: abort,
      checkDiscovered: checkDiscovered,
      takeBasServerConnectionCandidate: takeBasServerConnectionCandidate,
      cleanup: cleanup,
      finished: false
    }

    /**
     * @private
     * @type {TBasServerConnectionCandidate[]}
     */
    _connections = []

    _timeoutId = setTimeout(_onTimeout, _timeout)

    checkDiscovered()

    return _result

    function checkDiscovered () {

      var length, i, serviceKey, service

      if (_aborted || _cbCalled) return

      if (Array.isArray(basDiscoveryState.uiServices)) {

        length = basDiscoveryState.uiServices.length
        for (i = 0; i < length; i++) {

          serviceKey = basDiscoveryState.uiServices[i]
          service = basDiscoveryState.services[serviceKey]

          if (service) _addCandidate(service)
        }
      }
    }

    /**
     * @private
     * @param {TBasConnectServer} basConnectServer
     * @returns {boolean}
     */
    function _checkBasConnectServer (basConnectServer) {

      var value

      if (
        BAS_CORE_CLIENT.STATE.macNum &&
        basConnectServer &&
        basConnectServer.address
      ) {
        if (_allowOther) return true

        if (_preferredProjectId) {

          return basConnectServer.cid === _preferredProjectId
        }

        if (_preferredMac) {

          value = BasUtilities.getMacNumber(basConnectServer.macAddress)

          return value === _preferredMac
        }

        if (_preferredAddress) {

          return basConnectServer.address === _preferredAddress
        }
      }

      return false
    }

    /**
     * @private
     * @param {BasLocalCore} service
     */
    function _addCandidate (service) {

      var connection, basConnectServer
      var basConnectServerId, existingConnection

      basConnectServer = service.getBasConnectServer()

      if (_checkBasConnectServer(basConnectServer)) {

        basConnectServerId =
          BasConnectInfo.getBasConnectServerId(basConnectServer)

        existingConnection = _getConnectionById(basConnectServerId)

        if (existingConnection) {

          // Connection already exists

        } else {

          // Add new connection

          /**
           * @type {TBasServerConnectionCandidate}
           */
          connection = {
            id: basConnectServerId,
            resolved: false,
            service: service,
            basServer: new BAS_API.BasHttpServer(
              basConnectServer.address,
              {
                macAddress: basConnectServer.macAddress,
                cid: basConnectServer.cid,
                useSubscriptionSocket: BasAppDevice.isCoreClient()
              }
            )
          }

          _connections.push(connection)

          connection.basServer
            .getCoreClientDeviceInfo(BAS_CORE_CLIENT.STATE.macNum)
            .then(_onCoreClientDeviceInfo, _onCoreClientInfoError)
        }
      }

      /**
       * @private
       * @param {TBasServerDeviceInfoResponse} result
       */
      function _onCoreClientDeviceInfo (result) {

        if (_aborted || _cbCalled) return

        connection.resolved = true

        connection.coreClientInfo =
          BasCoreClientHelper.processCoreClientInfo(result.data)

        _checkConnections()
      }

      function _onCoreClientInfoError () {

        if (_aborted || _cbCalled) return

        connection.resolved = true

        if (connection.basServer) {

          connection.basServer.destroy()
          connection.basServer = null
        }

        _checkConnections()
      }
    }

    /**
     * @private
     * @param {string} id
     * @returns {?TBasServerConnectionCandidate}
     */
    function _getConnectionById (id) {

      var length, i, connection

      length = _connections.length
      for (i = 0; i < length; i++) {

        connection = _connections[i]
        if (connection && connection.id === id) return connection
      }

      return null
    }

    function _checkConnections () {

      var length, i, connection, allResolved, candidates

      allResolved = true
      candidates = []

      length = _connections.length
      for (i = 0; i < length; i++) {

        connection = _connections[i]

        if (connection) {

          if (connection.resolved) {

            if (connection.basServer) {

              candidates.push(connection)

              // Remove connection from connections
              _connections[i] = null

            } else {

              // Connection does not have Ellie in configuration
            }

          } else {

            // Connection is not resolved yet
            allResolved = false
          }
        }
      }

      length = candidates.length

      if (length === 1) {

        _result.basServerConnectionCandidate = candidates[0]
        candidates[0] = null

        _cleanConnections(candidates)
        candidates = []
        _cleanup()

        _cb(null)

      } else if (length > 1) {

        // TODO Return all candidates?

        // Take first one

        _result.basServerConnectionCandidate = candidates[0]
        candidates[0] = null

        _cleanConnections(candidates)
        candidates = []
        _cleanup()

        _cb(null)

      } else {

        if (allResolved) {

          _cleanup()

          _cb(null)

        } else {

          // Wait for other connections
        }
      }
    }

    function _onTimeout () {

      _cleanup()

      _cb(new BasError(
        BAS_ERRORS.T_TIMEOUT,
        undefined,
        BAS_ERRORS.M_TIMEOUT
      ))
    }

    /**
     * @private
     * @param {TBasServerConnectionCandidate[]} connections
     */
    function _cleanConnections (connections) {

      var length, i, connection

      length = connections.length
      for (i = 0; i < length; i++) {

        connection = connections[i]

        if (connection && connection.basServer) {

          connection.basServer.destroy()
          connection.basServer = null
        }
      }
    }

    /**
     * @private
     * @param {boolean} [cleanUpResult]
     */
    function _cleanup (cleanUpResult) {

      var connection

      clearTimeout(_timeoutId)
      _timeoutId = 0

      _cleanConnections(_connections)
      _connections = []

      if (cleanUpResult) {

        /**
         * @type {TBasServerConnectionCandidate}
         */
        connection = _result.basServerConnectionCandidate

        if (connection && connection.basServer) {

          connection.basServer.destroy()
        }

        _result.basServerConnectionCandidate = null
      }
    }

    /**
     * @returns {?TBasServerConnectionCandidate}
     */
    function takeBasServerConnectionCandidate () {

      var connection

      connection = _result.basServerConnectionCandidate
      _result.basServerConnectionCandidate = null

      return connection
    }

    function abort () {

      if (_aborted || _cbCalled) return

      _aborted = true

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_ABORT,
        undefined,
        BAS_ERRORS.M_ABORTED
      ))
    }

    function cleanup () {

      _cleanup(true)
    }

    /**
     * @private
     * @param {?BasError} error
     */
    function _cb (error) {

      if (!_cbCalled) {

        _cbCalled = true
        _result.finished = true

        if (BasUtil.isFunction(callback)) callback(error, _result)
      }
    }
  }
}
