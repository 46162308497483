'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasFullScreen', [
    '$window',
    '$rootScope',
    BasFullScreen
  ])

/**
 * @typedef {Object} TBasFullScreenState
 * @property {boolean} supportsFullScreen
 * @property {boolean} isFullScreen
 * @property {Object} css
 */

/**
 * Helper service for Browser full screen support
 *
 * @constructor
 * @param $window
 * @param $rootScope
 */
function BasFullScreen (
  $window,
  $rootScope
) {
  var CSS_SUPPORTS_FULL_SCREEN = 'bas-supports-full-screen'
  var CSS_IS_FULL_SCREEN = 'bas-is-full-screen'

  var EVT_FULL_SCREEN_CHANGED = 'basFullScreenChanged'

  var listenerOpts = {
    capture: false,
    passive: true,
    once: false
  }

  /**
   * @type {TBasFullScreenState}
   */
  var state = {}
  state.supportsFullScreen = false
  state.isFullScreen = false
  state.css = {}
  _resetCss()

  /**
   * @constant {string}
   */
  this.EVT_FULL_SCREEN_CHANGED = EVT_FULL_SCREEN_CHANGED

  this.get = get
  this.supportsFullScreen = supportsFullScreen
  this.isFullScreen = isFullScreen
  this.enableFullScreen = enableFullScreen
  this.disableFullScreen = disableFullScreen
  this.toggle = toggle

  init()

  function init () {

    _syncSupport()
    _syncState()

    document.addEventListener(
      'fullscreenchange',
      _onFullScreenChange,
      listenerOpts
    )
    document.addEventListener(
      'mozfullscreenchange',
      _onFullScreenChange,
      listenerOpts
    )
    document.addEventListener(
      'webkitfullscreenchange',
      _onFullScreenChange,
      listenerOpts
    )
    document.addEventListener(
      'MSFullscreenChange',
      _onFullScreenChange,
      listenerOpts
    )
  }

  /**
   * @returns {TBasFullScreenState}
   */
  function get () {
    return state
  }

  /**
   * @returns {boolean}
   */
  function supportsFullScreen () {

    var document = $window.document

    if (BasUtil.isBool(document.fullscreenEnabled)) {
      return document.fullscreenEnabled
    } else if (BasUtil.isBool(document.mozFullScreenEnabled)) {
      return document.mozFullScreenEnabled
    } else if (BasUtil.isBool(document.webkitFullscreenEnabled)) {
      return document.webkitFullscreenEnabled
    } else if (BasUtil.isBool(document.msFullscreenEnabled)) {
      return document.msFullscreenEnabled
    }

    return false
  }

  /**
   * @returns {boolean}
   */
  function isFullScreen () {

    var document = $window.document

    if ('fullscreenElement' in document) {
      return BasUtil.isObject(document.fullscreenElement)
    } else if ('mozFullScreenElement' in document) {
      return BasUtil.isObject(document.mozFullScreenElement)
    } else if ('webkitFullscreenElement' in document) {
      return BasUtil.isObject(document.webkitFullscreenElement)
    } else if ('msFullscreenElement' in document) {
      return BasUtil.isObject(document.msFullscreenElement)
    }

    return false
  }

  function enableFullScreen () {

    var document = $window.document
    var element = document.documentElement

    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }
  }

  function disableFullScreen () {

    var document = $window.document

    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
  }

  function toggle () {

    if (isFullScreen()) {

      disableFullScreen()

    } else {

      enableFullScreen()
    }
  }

  function _onFullScreenChange () {

    _syncState()

    $rootScope.$emit(EVT_FULL_SCREEN_CHANGED)

    $rootScope.$applyAsync()
  }

  function _syncState () {

    state.isFullScreen = isFullScreen()
    state.css[CSS_IS_FULL_SCREEN] = state.isFullScreen
  }

  function _syncSupport () {

    state.supportsFullScreen = supportsFullScreen()
    state.css[CSS_SUPPORTS_FULL_SCREEN] = state.supportsFullScreen
  }

  function _resetCss () {

    state.css[CSS_SUPPORTS_FULL_SCREEN] = false
    state.css[CSS_IS_FULL_SCREEN] = false
  }
}
