'use strict'

import * as BasUtil from '@basalte/bas-util'

var LABEL_KEYS = {
  open: 'open',
  close: 'close',
  up: 'up',
  down: 'down',
  in: 'act_in',
  out: 'act_out',
  left: 'left',
  right: 'right'
}

angular
  .module('basalteApp')
  .factory('BasShade', [
    '$rootScope',
    'ModalService',
    'BAS_API',
    'BAS_HTML',
    'BAS_ROOM',
    'BasUtilities',
    basShadeFactory
  ])

/**
 * @param $rootScope
 * @param ModalService
 * @param BAS_API
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BasUtilities} BasUtilities
 * @returns BasShade
 */
function basShadeFactory (
  $rootScope,
  ModalService,
  BAS_API,
  BAS_HTML,
  BAS_ROOM,
  BasUtilities
) {
  // "bsd" = Bas Shade Device

  var CSS_POSITION_HAS = 'bsd-position-has'
  var CSS_ROTATION_HAS = 'bsd-rotation-has'
  var CSS_OPEN_HAS = 'bsd-open-has'
  var CSS_CLOSE_HAS = 'bsd-close-has'
  var CSS_STOP_HAS = 'bsd-stop-has'
  var CSS_MODAL_HAS = 'bsd-modal-has'
  var CSS_AUTO_HAS = 'bsd-auto-has'
  var CSS_WARNING_HAS = 'bsd-warning-has'
  var CSS_SUBTITLE_HAS = 'brs-show-subtitle'
  var CSS_SEPARATOR_HAS = 'brs-show-separator'
  var CSS_OPENED = 'bsd-opened'
  var CSS_CLOSED = 'bsd-closed'
  var CSS_AUTO_ACTIVE = 'bsd-auto-active'

  var TS_OPENED = 'opened'
  var TS_AUTO = 'auto'
  var TS_POSITION = 'position'
  var TS_ROTATION = 'rotation'

  /**
   * @constructor
   * @param {(ShadeDevice|string)} [device]
   */
  function BasShade (device) {

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {number}
     */
    this.type = 0

    /**
     * @type {string}
     */
    this.typeStr = ''

    /**
     * @type {number}
     */
    this.location = 0

    /**
     * @type {string}
     */
    this.locationStr = ''

    /**
     * @type {string}
     */
    this.titleNameHtml = ''

    /**
     * @type {boolean}
     */
    this.opened = true

    /**
     * @type {string}
     */
    this.openClosedStr = ''

    /**
     * @type {number}
     */
    this.position = -1

    /**
     * @type {number}
     */
    this.rotation = -1

    /**
     * @type {boolean}
     */
    this.autoMode = false

    /**
     * @type {?ShadeDevice}
     */
    this.device = null

    /**
     * @type {Object<string, number>}
     */
    this.timeStamp = {}

    /**
     * @type {?Object<string, boolean>}
     */
    this.alarms = null

    /**
     * @type {boolean}
     */
    this.canSync = true

    /**
     * @type {boolean}
     */
    this.hasAlarm = true

    /**
     * @type {boolean}
     */
    this.isAlarmActive = true

    /**
     * @type {boolean}
     */
    this.canChangePosition = false

    /**
     * @type {boolean}
     */
    this.canChangeRotation = false

    /**
     * @type {boolean}
     */
    this.canOpen = false

    /**
     * @type {boolean}
     */
    this.canClose = false

    /**
     * @type {boolean}
     */
    this.canStop = false

    /**
     * @type {string}
     */
    this.closeKey = ''

    /**
     * @type {string}
     */
    this.uiCloseText = ''

    /**
     * @type {string}
     */
    this.openKey = ''

    /**
     * @type {string}
     */
    this.uiOpenText = ''

    /**
     * @type {Object}
     */
    this.css = {}
    this.resetCss()

    /**
     * @private
     * @type {Array}
     */
    this._deviceListeners = []

    this._handleCapabilitiesChange = this.onDeviceCapabilities.bind(this)
    this._handleDeviceStateChange = this.onDeviceState.bind(this)

    if (device) this.parseShadeDevice(device)
  }

  /**
   * Translate shade type
   *
   * @param {number} key
   * @returns {string}
   */
  BasShade.getTypeStr = function (key) {

    switch (key) {
      case BAS_API.ShadeDevice.TYPE.SHADES:
        return BasUtilities.translate('shade_shade')
      case BAS_API.ShadeDevice.TYPE.ROLLER_BLINDS:
        return BasUtilities.translate('shade_blind')
      case BAS_API.ShadeDevice.TYPE.ROMAN_BLINDS:
        return BasUtilities.translate('shade_roman_blind')
      case BAS_API.ShadeDevice.TYPE.VENETIAN_BLINDS:
        return BasUtilities.translate('shade_venetian_blind')
      case BAS_API.ShadeDevice.TYPE.VERTICAL_BLINDS:
        return BasUtilities.translate('shade_vertical_blind')
      case BAS_API.ShadeDevice.TYPE.CURTAIN:
        return BasUtilities.translate('shade_curtain')
      case BAS_API.ShadeDevice.TYPE.SOLAR_PROTECTION:
        return BasUtilities.translate('shade_solar_protection')
    }

    return ''
  }

  /**
   * Generates simple name string (taking into account type, subType, ...)
   * format: TYPE_NAME - CUSTOM_NAME (LOCATION)
   *
   * Needs to be retrieved again when translation changes!
   *
   * @returns {string}
   */
  BasShade.prototype.getName = function () {

    var result = ''

    if (this.typeStr) {

      result += this.typeStr

      if (this.name) result += ' - '
    }

    result += this.name

    if (this.locationStr) {

      result += ' (' + this.locationStr + ')'
    }

    return result
  }

  /**
   * Checks if current UUID is valid, if not, create one
   */
  BasShade.prototype.fillInUuid = function () {

    if (!BasUtil.isNEString(this.uuid)) {

      this.uuid = BasUtilities.getAppUniqueId()
    }
  }

  /**
   * @returns {BasShade}
   */
  BasShade.prototype.clone = function () {

    return new BasShade(this.device)
  }

  /**
   * @param {BasShade} basShade
   */
  BasShade.prototype.syncBasShade = function (basShade) {

    if (basShade instanceof BasShade) {

      this.opened = basShade.opened
      this.autoMode = basShade.autoMode
      this.position = basShade.position
      this.rotation = basShade.rotation
      this.timeStap = basShade.timeStamp
      this.css[CSS_OPENED] =
        basShade.css[CSS_OPENED]
      this.css[CSS_CLOSED] =
        basShade.css[CSS_CLOSED]
      this.css[CSS_AUTO_ACTIVE] =
        basShade.css[CSS_AUTO_ACTIVE]
    }
  }

  BasShade.prototype.updateTranslation = function () {

    this.typeStr = BasShade.getTypeStr(this.type)
    this.locationStr = BasUtilities.getDeviceLocationStr(this.location)
    this.openClosedStr = BasUtilities.translate(
      this.opened
        ? 'state_opened'
        : 'state_closed'
    )
    this.css[CSS_SUBTITLE_HAS] =
      BasUtil.isNEString(this.locationStr)
    this.css[CSS_SEPARATOR_HAS] =
      BasUtil.isNEString(this.typeStr) &&
      BasUtil.isNEString(this.name)

    if (!BasUtil.isNEString(this.name) &&
      !BasUtil.isNEString(this.typeStr) &&
      !BasUtil.isNEString(this.locationStr)) {

      this.name = '-'
    }

    this._makeTitleNameHtml()
    this._syncUiText()
  }

  /**
   * @private
   * @param {string} text
   */
  BasShade.prototype._setUiCloseText = function (text) {

    var key = LABEL_KEYS[text]

    this.closeKey = BasUtil.isNEString(key) ? key : ''
    this._syncUiText()
  }

  /**
   * @private
   * @param {string} text
   */
  BasShade.prototype._setUiOpenText = function (text) {

    var key = LABEL_KEYS[text]

    this.openKey = BasUtil.isNEString(key) ? key : ''
    this._syncUiText()
  }

  /**
   * @private
   */
  BasShade.prototype._syncUiText = function () {

    this.uiCloseText = BasUtil.isNEString(this.closeKey)
      ? BasUtilities.translate(this.closeKey)
      : BasUtilities.translate('close')

    this.uiOpenText = BasUtil.isNEString(this.openKey)
      ? BasUtilities.translate(this.openKey)
      : BasUtilities.translate('open')
  }

  /**
   * @returns {boolean}
   */
  BasShade.prototype.isEditable = function () {

    return BasUtil.isObject(this.device) &&
      (
        this.device.allowsExecute(BAS_API.ShadeDevice.C_OPEN) ||
        this.device.allowsExecute(BAS_API.ShadeDevice.C_CLOSE) ||
        this.device.allowsExecute(BAS_API.ShadeDevice.C_STOP) ||
        this.device.allowsWrite(BAS_API.ShadeDevice.C_OPEN_CLOSE) ||
        this.device.allowsWrite(BAS_API.ShadeDevice.C_POSITION) ||
        this.device.allowsWrite(BAS_API.ShadeDevice.C_ROTATION) ||
        this.device.allowsWrite(BAS_API.ShadeDevice.C_AUTO_MODE)
      )
  }

  /**
   * @param {string[]} keys
   * @returns {string} key of last edited
   */
  BasShade.prototype.getLastEdit = function (keys) {

    var time = 0
    var key = ''
    var i, length

    if (Array.isArray(keys)) {

      length = keys.length
      for (i = 0; i < length; i++) {

        if (BasUtil.isNumber(this.timeStamp[keys[i]]) &&
          this.timeStamp[keys[i]] > time) {

          time = this.timeStamp[keys[i]]
          key = keys[i]
        }
      }
    }

    return key
  }

  /**
   * @param {(ShadeDevice|string)} shadeDevice
   */
  BasShade.prototype.parseShadeDevice = function (
    shadeDevice
  ) {
    if (shadeDevice instanceof BAS_API.ShadeDevice) {

      this.device = shadeDevice
      this.uuid = shadeDevice.uuid

      this.setDeviceListeners()
      this.syncDevice()

    } else if (BasUtil.isNEString(shadeDevice)) {

      this.uuid = shadeDevice
    }
  }

  /**
   * @param {ShadeDevice} [device]
   */
  BasShade.prototype.syncDevice = function (device) {

    var shadeDevice = device || this.device

    if (shadeDevice) {

      this.resetCss()
      this.resetCapabilities()

      this.uuid = shadeDevice.uuid
      this.name = shadeDevice.name
      this.type = shadeDevice.subType
      this.location = shadeDevice.location
      this.isAlarmActive = false
      this.hasAlarm = false

      this._setUiOpenText(shadeDevice.labelOpenText)
      this._setUiCloseText(shadeDevice.labelCloseText)

      if (shadeDevice.allowsExecute(BAS_API.ShadeDevice.C_OPEN)) {

        this.canOpen = true
        this.setCssOpenHas(true)
      }

      if (shadeDevice.allowsExecute(BAS_API.ShadeDevice.C_CLOSE)) {

        this.canClose = true
        this.setCssCloseHas(true)
      }

      if (shadeDevice.allowsExecute(BAS_API.ShadeDevice.C_STOP)) {

        this.canStop = true
        this.setCssStopHas(true)
      }

      if (shadeDevice.allowsRead(BAS_API.ShadeDevice.C_OPEN_CLOSE)) {

        this.opened = shadeDevice.opened
        this.setCssOpened(shadeDevice.opened)
        this.setCssClosed(shadeDevice.closed)
      }

      if (shadeDevice.allowsRead(BAS_API.ShadeDevice.C_POSITION)) {

        this.position = Math.round(shadeDevice.position * 100)
        this.setCssPosition(true)

        if (shadeDevice.allowsWrite(BAS_API.ShadeDevice.C_POSITION)) {

          this.canChangePosition = true
        }

        if (!shadeDevice.allowsRead(BAS_API.ShadeDevice.C_OPEN_CLOSE)) {

          this.opened = this.position === 0
          this.setCssOpened(this.position === 0)
          this.setCssClosed(this.position === 100)
        }
      }

      if (shadeDevice.allowsRead(BAS_API.ShadeDevice.C_ROTATION)) {

        this.rotation = Math.round(shadeDevice.rotation * 100)
        this.setCssRotation(true)

        if (shadeDevice.allowsWrite(BAS_API.ShadeDevice.C_ROTATION)) {

          this.canChangeRotation = true
        }
      }

      if (shadeDevice.allowsRead(BAS_API.ShadeDevice.C_AUTO_MODE)) {

        this.autoMode = shadeDevice.autoMode
        this.setCssActive(this.autoMode)
      }

      if (shadeDevice.allowsRead(BAS_API.ShadeDevice.C_ALARMS)) {

        this.alarms = shadeDevice.alarms
        this.parseAlarms()
      }

    }

    this.updateTranslation()
  }

  BasShade.prototype.setCssOpenHas = function (value) {

    this.css[CSS_OPEN_HAS] = value
  }

  BasShade.prototype.setCssCloseHas = function (value) {

    this.css[CSS_CLOSE_HAS] = value
  }

  BasShade.prototype.setCssStopHas = function (value) {

    this.css[CSS_STOP_HAS] = value
  }

  BasShade.prototype.setCssPosition = function (value) {

    this.css[CSS_POSITION_HAS] = value
    this.setCssModal(true)
  }

  BasShade.prototype.setCssRotation = function (value) {

    this.css[CSS_ROTATION_HAS] = value
    this.setCssModal(true)
  }

  BasShade.prototype.setCssModal = function (value) {

    this.css[CSS_MODAL_HAS] = value
  }

  BasShade.prototype.setCssActive = function (value) {

    this.css[CSS_AUTO_HAS] = value
    this.setCssAuto(true)
  }

  BasShade.prototype.setCssAuto = function (value) {

    this.css[CSS_AUTO_HAS] = value
  }

  BasShade.prototype.setCssWarning = function (value) {

    this.css[CSS_WARNING_HAS] = value
  }

  BasShade.prototype.setCssOpened = function (value) {

    this.css[CSS_OPENED] = value
  }

  BasShade.prototype.setCssClosed = function (value) {

    this.css[CSS_CLOSED] = value
  }

  BasShade.prototype.openShadeModal = function () {

    ModalService.showModal({
      controller: 'shadeModalCtrl',
      controllerAs: 'modal',
      inputs: {
        shade: this
      },
      template: BAS_HTML.shadeModal
    })
  }

  BasShade.prototype.parseAlarms = function () {

    this.hasAlarm = false

    if (BasUtil.isObject(this.alarms)) {

      this.hasAlarm =
        this.alarms[BAS_API.ShadeDevice.T_ALARM_GENERIC] ||
        this.alarms[BAS_API.ShadeDevice.T_ALARM_WIND_1] ||
        this.alarms[BAS_API.ShadeDevice.T_ALARM_WIND_2] ||
        this.alarms[BAS_API.ShadeDevice.T_ALARM_WIND_3] ||
        this.alarms[BAS_API.ShadeDevice.T_ALARM_FROST] ||
        this.alarms[BAS_API.ShadeDevice.T_ALARM_RAIN]
    }

    this.setAlarmActive()
  }

  BasShade.prototype.setAlarmActive = function () {

    this.isAlarmActive = this.hasAlarm && this.canSync
    this.setCssWarning(this.isAlarmActive)
  }

  BasShade.prototype._makeTitleNameHtml = function () {

    this.titleNameHtml = '<div class="rs-modal-title">'

    if (BasUtil.isNEString(this.typeStr)) {

      this.titleNameHtml +=
        '<div class="rs-modal-title-type ellipsis-overflow">' +
        this.typeStr + '</div>'

      if (BasUtil.isNEString(this.name)) {

        this.titleNameHtml +=
          '<div class="rs-modal-title-divider"></div>'
        this.titleNameHtml +=
          '<div class="rs-modal-title-name ellipsis-overflow">' +
          this.name + '</div>'
      }

    } else if (BasUtil.isNEString(this.name)) {

      this.titleNameHtml +=
        '<div class="rs-modal-title-name ellipsis-overflow">' +
        this.name + '</div>'
    }

    if (BasUtil.isNEString(this.locationStr)) {

      this.titleNameHtml +=
        '<div class="rs-modal-title-location ellipsis-overflow">' +
        this.locationStr + '</div>'
    }

    this.titleNameHtml += '</div>'
  }

  /**
   * @param {TSceneShade} sceneShade
   * @param {number} [time] Time in milliseconds
   */
  BasShade.prototype.parseSceneShade = function (sceneShade, time) {

    var _time

    _time = BasUtil.isPNumber(time) ? time : Date.now()

    if (BasUtil.isBool(sceneShade.isClosed)) {

      this.opened = !sceneShade.isClosed
      this.timeStamp[TS_OPENED] = _time

      this.setCssOpened(this.opened)
      this.setCssClosed(!this.opened)
    }

    if (BasUtil.isBool(sceneShade.autoMode)) {

      this.autoMode = sceneShade.autoMode
      this.timeStamp[TS_AUTO] = _time
    }

    if (BasUtil.isPNumber(sceneShade.position, true)) {

      this.position = Math.round(sceneShade.position)
      this.timeStamp[TS_POSITION] = _time

      this.setCssOpened(this.position === 0)
      this.setCssClosed(this.position === 100)

    } else {

      this.position = 0
    }

    if (BasUtil.isPNumber(sceneShade.rotation, true)) {

      this.rotation = Math.round(sceneShade.rotation)
      this.timeStamp[TS_ROTATION] = _time

    } else {

      this.rotation = 0
    }
  }

  BasShade.prototype.open = function () {

    this.emitInput()
    this.timeStamp[TS_OPENED] = Date.now()

    if (this.canSync) {

      if (
        this.device &&
        this.device.allowsExecute(BAS_API.ShadeDevice.C_OPEN)
      ) {

        this.device.open()
      }

    } else {

      this.opened = true
      this.position = 0
      this.setCssOpened(this.opened)
      this.setCssClosed(!this.opened)
    }
  }

  BasShade.prototype.stop = function () {

    if (this.canSync &&
      this.device &&
      this.device.allowsExecute(BAS_API.ShadeDevice.C_STOP)) {

      this.device.stop()
    }
  }

  BasShade.prototype.close = function () {

    this.emitInput()
    this.timeStamp[TS_OPENED] = Date.now()

    if (this.canSync) {

      if (
        this.device &&
        this.device.allowsExecute(BAS_API.ShadeDevice.C_CLOSE)
      ) {

        this.device.close()
      }

    } else {

      this.opened = false
      this.position = 100
      this.setCssOpened(this.opened)
      this.setCssClosed(!this.opened)
    }
  }

  BasShade.prototype.changePosition = function () {

    this.emitInput()
    this.setPosition(this.position)

    if (!this.canSync) {

      this.setCssOpened(this.position === 0)
      this.setCssClosed(this.position === 100)
    }

    $rootScope.$applyAsync()
  }

  BasShade.prototype.changeRotation = function () {

    this.emitInput()
    this.setRotation(this.rotation)

    $rootScope.$applyAsync()
  }

  BasShade.prototype.emitInput = function () {

    $rootScope.$emit(BAS_ROOM.EVT_FUNCTION_INPUT, this)
  }

  /**
   * @param {number} value [0 - 1]
   */
  BasShade.prototype.setPosition = function (value) {

    this.timeStamp[TS_POSITION] = Date.now()

    if (
      this.canSync &&
      this.device &&
      this.device.allowsWrite(BAS_API.ShadeDevice.C_POSITION)
    ) {

      this.device.setPosition(value / 100)
    }
  }

  /**
   * @param {number} value [0 - 1]
   */
  BasShade.prototype.setRotation = function (value) {

    this.timeStamp[TS_ROTATION] = Date.now()

    if (
      this.canSync &&
      this.device &&
      this.device.allowsWrite(BAS_API.ShadeDevice.C_ROTATION)
    ) {

      this.device.setRotation(value / 100)
    }
  }

  /**
   * @param {boolean} [force]
   */
  BasShade.prototype.toggleAutoMode = function (force) {

    this.timeStamp[TS_AUTO] = Date.now()

    if (
      this.canSync &&
      this.device &&
      this.device.allowsWrite(BAS_API.ShadeDevice.C_AUTO_MODE)
    ) {

      this.device.toggleAuto(force)
    }
  }

  /**
   * @returns {TSceneShade}
   */
  BasShade.prototype.getSceneObj = function () {

    var result, lastEdit

    // TODO: We should no longer rely on the last edited property to selectively
    //  send properties or not. We should send all writeable properties that are
    //  compatible. In case of non compatible properties UI should allow to
    //  choose between those.

    result = {}
    lastEdit = this.getLastEdit([
      TS_OPENED,
      TS_ROTATION,
      TS_POSITION
    ])

    result.autoMode = this.autoMode

    switch (lastEdit) {

      case TS_POSITION:
      case TS_ROTATION:

        result.position = this.position
        result.rotation = this.rotation
        break

      case TS_OPENED:
      default:

        result.isClosed = !this.opened
        break

    }

    return result
  }

  /**
   * @param {boolean} mode
   */
  BasShade.prototype.setSyncMode = function (mode) {

    this.canSync = mode === true

    this.setAlarmActive()
    if (this.canSync) this.syncDevice()
  }

  BasShade.prototype.resetCss = function resetCss () {

    this.css[CSS_POSITION_HAS] = false
    this.css[CSS_ROTATION_HAS] = false
    this.css[CSS_MODAL_HAS] = false
    this.css[CSS_AUTO_HAS] = false
    this.css[CSS_OPENED] = false
    this.css[CSS_CLOSED] = false
    this.css[CSS_AUTO_ACTIVE] = false
    this.css[CSS_SUBTITLE_HAS] = false
    this.css[CSS_SEPARATOR_HAS] = false
    this.css[CSS_STOP_HAS] = false
    this.css[CSS_CLOSE_HAS] = false
    this.css[CSS_OPEN_HAS] = false
  }

  BasShade.prototype.setDeviceListeners = function () {

    this._clearDeviceListeners()

    if (this.device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.Device.EVT_CAPABILITIES,
        this._handleCapabilitiesChange
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.ShadeDevice.EVT_STATE,
        this._handleDeviceStateChange
      ))
    }
  }

  BasShade.prototype.onDeviceCapabilities = function () {

    if (this.canSync) this.syncDevice()

    $rootScope.$applyAsync()
  }

  BasShade.prototype.onDeviceState = function () {

    if (this.canSync) this.syncDevice()

    $rootScope.$applyAsync()
  }

  /**
   * Clears the API device listeners
   *
   * @private
   */
  BasShade.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  /**
   * Clears the device listeners and the device reference
   */
  BasShade.prototype.clear = function clear () {

    this._clearDeviceListeners()
    this.device = null
  }

  BasShade.prototype.resetCapabilities = function resetCapabilities () {

    this.canChangePosition = false
    this.canChangeRotation = false
    this.canOpen = false
    this.canClose = false
    this.canStop = false
  }

  BasShade.prototype.suspend = function suspend () {

    this._clearDeviceListeners()
  }

  return BasShade
}
