'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('basSceneSchedulesCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_STATE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'UI_HELPER',
    'BasState',
    'RoomsHelper',
    'BasUiSortableHelper',
    'UiHelper',
    basSceneSchedulesCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {UI_HELPER} UI_HELPER
 * @param {BasState} BasState
 * @param {RoomsHelper} RoomsHelper
 * @param BasUiSortableHelper
 * @param {UiHelper} UiHelper
 */
function basSceneSchedulesCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_STATE,
  BAS_ROOMS,
  BAS_ROOM,
  UI_HELPER,
  BasState,
  RoomsHelper,
  BasUiSortableHelper,
  UiHelper
) {
  var scheduler = this

  var _listeners = []

  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  /**
   * @type {BasRooms}
   */
  scheduler.rooms = BAS_ROOMS.ROOMS

  scheduler.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BasUiSortableHelper}
   */
  scheduler.basUiSortableHelper = new BasUiSortableHelper()
  scheduler.basUiSortableHelper.allowSwiping = false

  scheduler.newSchedule = newSchedule
  scheduler.toggleSchedule = toggleSchedule
  scheduler.selectSchedule = selectSchedule
  scheduler.onReorder = onReorder

  init()

  function init () {

    $scope.$on('$destroy', onDestroy)

    _listeners.push($rootScope.$on(
      UI_HELPER.EVT_RESIZE,
      _onUiSize
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_BAS_JOB_REMOVED,
      _onScheduleRemoved
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_HOME_JOBS_INITIALIZED,
      _onJobs
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SCENES_UPDATED,
      _onScenesUpdated
    ))
  }

  function newSchedule () {

    var _scheduler = _getHomeScheduler()

    if (_scheduler) {

      _scheduler.addNewSchedule().then(_onNewSchedule)
    }
  }

  /**
   * @param {string} scheduleUuid
   */
  function toggleSchedule (scheduleUuid) {

    var homeScheduler, schedule

    homeScheduler = _getHomeScheduler()
    schedule = _getSchedule(scheduleUuid)

    if (schedule) {

      schedule.toggle()
      schedule.commit()

      if (homeScheduler) homeScheduler.updateSchedule(schedule)
    }
  }

  /**
   * @param {string} scheduleUuid
   */
  function selectSchedule (scheduleUuid) {

    if (BasUtil.isNEString(scheduleUuid)) {

      BasState.go(
        STATES.SCHEDULE,
        {
          schedule: scheduleUuid
        }
      )
    }
  }

  function onReorder (spliceIndex, originalIndex) {

    var _scheduler

    scheduler.basUiSortableHelper.onReorder()

    _scheduler = _getHomeScheduler()

    if (_scheduler) _scheduler.uiReorder(spliceIndex, originalIndex)
  }

  /**
   * @private
   * @param {BasSceneSchedule} schedule
   */
  function _onNewSchedule (schedule) {

    if (schedule && BasUtil.isNEString(schedule.uuid)) {

      BasState.go(
        STATES.SCHEDULE,
        {
          schedule: schedule.uuid
        }
      )
    }
  }

  /**
   * @param {boolean} [goBack]
   */
  function _goToSchedules (goBack) {

    var homeScheduler = _getHomeScheduler()

    if (basUi.prop.wMedium &&
      homeScheduler &&
      homeScheduler.uiSchedules &&
      homeScheduler.uiSchedules.length > 0) {

      BasState.go(
        STATES.SCHEDULE,
        {
          schedule: homeScheduler.uiSchedules[0]
        }
      )

    } else if (goBack) {

      BasState.go(STATES.SCHEDULES)
    }
  }

  function _onUiSize () {

    if (!$uiRouterGlobals.transition) _goToSchedules()
  }

  /**
   * @private
   * @param _event
   * @param {string} roomsType
   */
  function _onRoomsUpdated (_event, roomsType) {

    if (roomsType === BAS_ROOMS.P_ROOMS) {

      if (_getHomeScheduler()) _checkCurrentJob()
    }
  }

  function _onJobs () {

    if (RoomsHelper.getHome()) _checkCurrentJob()
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasRoom} room
   */
  function _onScenesUpdated (_event, room) {

    var home

    home = RoomsHelper.getHome()

    if (home && room && home.id === room.id) {

      $scope.$applyAsync()
    }
  }

  function _checkCurrentJob () {

    var home, homeScheduler

    home = RoomsHelper.getHome()

    if (home && home.room) {

      if (RoomsHelper.roomHasFunctionScenes(home)) {

        homeScheduler = _getHomeScheduler()

        if (
          homeScheduler &&
          !(
            BasUtil.isNEString($uiRouterGlobals.params.schedule) &&
            homeScheduler.uiSchedules
              .indexOf($uiRouterGlobals.params.schedule) !== -1
          )
        ) {
          _goToSchedules(true)
        }

      } else {

        BasState.go(
          STATES.HOME,
          {
            view: BAS_STATE.S_HOME_VIEW_OPTIONS
          }
        )
      }
    }
  }

  function _onScheduleRemoved (_evt, uuid) {

    if (
      BasUtil.isNEString(uuid) &&
      uuid === $uiRouterGlobals.params.schedule
    ) {
      _goToSchedules(true)
    }
  }

  /**
   * @private
   * @returns {?BasRoomScheduler}
   */
  function _getHomeScheduler () {

    var home

    home = RoomsHelper.getHome()

    return (home && home.scheduler && home.scheduler.addNewSchedule)
      ? home.scheduler
      : null
  }

  /**
   * @private
   * @param {string} scheduleUuid
   * @returns {?BasSceneSchedule}
   */
  function _getSchedule (scheduleUuid) {

    var homeScheduler, schedule

    if (BasUtil.isNEString(scheduleUuid)) {

      homeScheduler = _getHomeScheduler()

      if (homeScheduler && homeScheduler.schedules) {

        schedule = homeScheduler.schedules[scheduleUuid]

        if (schedule && schedule.getJob) return schedule
      }
    }

    return null
  }

  function onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
