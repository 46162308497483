'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSound', [
    '$window',
    'BAS_SOUND',
    BasSound
  ])

/**
 * @callback CBNotificationEnded
 */

/**
 * @typedef {Object} BasNotificationOptions
 * @property {number} [repeat = 1]
 * @property {number} [volume] 0 - 1
 * @property {CBNotificationEnded} [endCallback]
 */

/**
 * @constructor
 * @param $window
 * @param {BAS_SOUND} BAS_SOUND
 */
function BasSound (
  $window,
  BAS_SOUND
) {

  var intercomNotification = null
  var volumeNotification = null
  var customNotification = null

  this.playIntercomNotification = playIntercomNotification
  this.pauseIntercomNotification = pauseIntercomNotification
  this.playVolumeNotification = playVolumeNotification
  this.pauseVolumeNotification = pauseVolumeNotification
  this.playCustomNotification = playCustomNotification
  this.pauseCustomNotification = pauseCustomNotification

  init()

  function init () {

    intercomNotification = _setNotification(BAS_SOUND.RESOURCE_SOUND_PHOBOS)
    volumeNotification = _setNotification(BAS_SOUND.RESOURCE_SOUND_PHOBOS)
  }

  /**
   * @private
   * @param {number} [volume] 0 - 100
   */
  function playVolumeNotification (volume) {

    _playNotification(
      volumeNotification,
      {
        repeat: 1,
        volume: volume
      }
    )
  }

  function pauseVolumeNotification () {
    _pauseNotification(
      volumeNotification
    )
  }

  /**
   * @private
   * @param {number} [volume] 0 - 100
   */
  function playIntercomNotification (volume) {

    _playNotification(
      intercomNotification,
      {
        repeat: 1,
        volume: volume
      }
    )
  }

  function pauseIntercomNotification () {
    _pauseNotification(
      intercomNotification
    )
  }

  /**
   * @private
   * @param {string} audioUri
   * @param {BasNotificationOptions} options
   */
  function playCustomNotification (audioUri, options) {

    _pauseNotification(customNotification)
    customNotification = _setNotification(audioUri)

    _playNotification(
      customNotification,
      options
    )
  }

  function pauseCustomNotification () {

    _pauseNotification(
      customNotification
    )
  }

  /**
   * @private
   * @param {?HTMLAudioElement} notification
   * @param {BasNotificationOptions} options
   */
  function _playNotification (notification, options) {

    var _repeat = BasUtil.isInteger(options.repeat) ? options.repeat : 1
    var _volume = _calcVolume(options.volume)
    var playCount = 0

    if (notification) {

      _pauseNotification(notification)

      notification.volume = _volume
      notification.currentTime = 0
      notification.play().catch(
        _onNotificationError
      )
      notification.onended = _onEnded
      notification.onerror = _onEnded
    }

    function _onNotificationError () {
      // Ignore
      // Pausing an AudioElement right after a play can lead to a DOM exception
      // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
    }

    function _onEnded () {

      playCount++

      if (_repeat < 1 || playCount < _repeat) {

        notification.play().catch(
          _onNotificationError
        )
      } else {

        BasUtil.exec(options.endCallback)
      }
    }
  }

  /**
   * @private
   * @param {?HTMLAudioElement} notification
   */
  function _pauseNotification (notification) {

    if (notification) {

      notification.pause()
    }
  }

  /**
   * @private
   * @param {number} [volume] 0 - 100
   * @returns {number} 0 - 1
   */
  function _calcVolume (volume) {

    return BasUtil.isPNumber(volume, true) && volume <= 100
      ? volume / 100
      : BAS_SOUND.DEFAULT_VOLUME
  }

  /**
   * @private
   * @param {string} resource
   * @returns {?HTMLAudioElement}
   */
  function _setNotification (resource) {

    var _notification

    if (typeof $window.Audio !== 'undefined') {

      _notification = new Audio(resource)
    }

    if (_notification) {

      _notification.load()
    }

    return _notification
  }
}
