'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var BasProfile = require('./bas_profile')

/**
 * Profile class which holds profile status
 *
 * @constructor
 * @extends EventEmitter
 * @since 2.7.0
 */
function Profile () {

  EventEmitter.call(this)

  /**
   * @private
   * @type {string}
   */
  this._uuid = ''

  /**
   * @private
   * @type {string}
   */
  this._username = ''

  /**
   * @private
   * @type {boolean}
   */
  this._admin = false

  /**
   * @private
   * @type {number}
   */
  this._defaultSource = 0

  /**
   * @private
   * @type {string}
   */
  this._defaultSourceUuid = ''

  /**
   * @private
   * @type {string}
   */
  this._avatar = undefined

  /**
   * @private
   * @type {boolean}
   */
  this._isEllie = false

  /**
   * @private
   * @type {boolean}
   */
  this._isCoreClientDevice = false

  /**
   * @private
   * @type {string}
   */
  this._coreClientUuid = ''

  /**
   * @private
   * @type {boolean}
   */
  this._alarmsAccess = true

  /**
   * @private
   * @type {boolean}
   */
  this._dashboardAccess = true

  /**
   * @private
   * @type {boolean}
   */
  this._intercomAccess = false

  /**
   * @private
   * @type {boolean}
   */
  this._propertiesDirty = true

  this.reset()
}

Profile.prototype = Object.create(EventEmitter.prototype)
Profile.prototype.constructor = Profile

/**
 * @event Profile#EVT_PROFILE_CREATED
 */

/**
 * @event Profile#EVT_IS_ADMIN
 * @param {boolean} isAdmin
 */

/**
 * @constant {string}
 */
Profile.EVT_PROFILE_CREATED = 'evtProfileCreated'

/**
 * @constant {string}
 */
Profile.EVT_IS_ADMIN = 'evtProfileIsAdmin'

/**
 * @name Profile#username
 * @type {string}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'username', {
  get: function () {
    return this._username
  }
})

/**
 * @name Profile#email
 * @type {string}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'email', {
  get: function () {
    return this._email
  }
})

/**
 * @name Profile#admin
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'admin', {
  get: function () {
    return this._admin
  }
})

/**
 * @name Profile#uuid
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name Profile#defaultSource
 * @type {number}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'defaultSource', {
  get: function () {
    return this._defaultSource
  }
})

/**
 * @name Profile#defaultSourceUuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'defaultSourceUuid', {
  get: function () {
    return this._defaultSourceUuid
  }
})

/**
 * @name Profile#avatar
 * @type {string}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'avatar', {
  get: function () {
    return this._avatar
  }
})

/**
 * @name Profile#isCoreClientDevice
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'isCoreClientDevice', {
  get: function () {
    return this._isCoreClientDevice
  }
})

/**
 * @name Profile#coreClientUuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'coreClientUuid', {
  get: function () {
    return this._coreClientUuid
  }
})

/**
 * @name Profile#alarmsAccess
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'alarmsAccess', {
  get: function () {
    return this._alarmsAccess
  }
})

/**
 * @name Profile#dashboardAccess
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'dashboardAccess', {
  get: function () {
    return this._dashboardAccess
  }
})

/**
 * @name Profile#intercomAccess
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'intercomAccess', {
  get: function () {
    return this._intercomAccess
  }
})

/**
 * @name Profile#propertiesDirty
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Profile.prototype, 'propertiesDirty', {
  get: function () {
    return this._propertiesDirty
  }
})

/**
 * Parse "profile" messages
 *
 * @param {Object} obj
 */
Profile.prototype.parse = function (obj) {

  var profile, coreClientDevice, _oldAdmin

  if (
    BasUtil.isObject(obj) &&
    BasUtil.isObject(obj[P.PROFILE])
  ) {

    _oldAdmin = this._admin

    profile = obj[P.PROFILE]

    // Username
    if (BasUtil.isNEString(profile[P.USERNAME])) {
      this._username = profile[P.USERNAME]
    }

    // Email
    if (BasUtil.isNEString(profile[P.EMAIL])) {
      this._email = profile[P.EMAIL]
    }

    // Admin
    if (BasUtil.isBool(profile[P.ADMIN])) {
      this._admin = profile[P.ADMIN]
    }

    // Uuid
    if (BasUtil.isNEString(profile[P.UUID])) {
      this._uuid = profile[P.UUID]
    }

    // Default
    if (BasUtil.isNumber(profile[P.DEFAULT])) {
      this._defaultSource = profile[P.DEFAULT]
    }

    // Default
    if (BasUtil.isString(profile[P.DEFAULT_SOURCE])) {
      this._defaultSourceUuid = profile[P.DEFAULT_SOURCE]
    }

    // Avatar
    if (BasUtil.isNEString(profile[P.AVATAR])) {
      this._avatar = profile[P.AVATAR]
    }

    if (BasUtil.isObject(profile[P.ELLIE])) {

      coreClientDevice = profile[P.ELLIE]

      // IsCoreClientDevice
      this._isCoreClientDevice = true

      // CoreClientUuid
      if (BasUtil.isNEString(coreClientDevice[P.UUID])) {
        this._coreClientUuid = coreClientDevice[P.UUID]
      }
    }

    if (BasUtil.isObject(profile[P.DEVICE])) {

      coreClientDevice = profile[P.DEVICE]

      // IsCoreClientDevice
      this._isCoreClientDevice = true

      // CoreClientUuid
      if (BasUtil.isNEString(coreClientDevice[P.UUID])) {
        this._coreClientUuid = coreClientDevice[P.UUID]
      }
    }

    if (BasUtil.isObject(profile[P.PERMISSIONS])) {

      // AlarmsAccess
      if (BasUtil.isBool(profile[P.PERMISSIONS][P.ALARMS])) {

        this._alarmsAccess =
          profile[P.PERMISSIONS][P.ALARMS]
      }

      // DashboardAccess
      if (BasUtil.isBool(profile[P.PERMISSIONS][P.DASHBOARD])) {

        this._dashboardAccess =
          profile[P.PERMISSIONS][P.DASHBOARD]
      }

      // IntercomAccess
      if (BasUtil.isBool(profile[P.PERMISSIONS][P.INTERCOM])) {

        this._intercomAccess =
          profile[P.PERMISSIONS][P.INTERCOM]
      }
    }

    this._propertiesDirty = false

    this.emit(Profile.EVT_PROFILE_CREATED)

    if (_oldAdmin !== this._admin) {

      this.emit(Profile.EVT_IS_ADMIN, this._admin)
    }
  }
}

/**
 * @returns {BasProfile}
 */
Profile.prototype.convertToBasProfile = function () {
  return new BasProfile(this)
}

/**
 * @param {BasProfile} basProfile
 */
Profile.prototype.parseBasProfile = function (basProfile) {

  var _oldAdmin

  if (basProfile) {

    _oldAdmin = this._admin

    this._username = basProfile.username
    this._admin = basProfile.isAdmin

    if (_oldAdmin !== this._admin) {

      this.emit(Profile.EVT_IS_ADMIN, this._admin)
    }
  }
}

/**
 * @private
 */
Profile.prototype._resetProperties = function () {

  this._username = ''
  this._email = ''
  this._admin = false
  this._uuid = ''
  this._defaultSource = 0
  this._avatar = undefined
  this._isCoreClientDevice = false
  this._coreClientUuid = ''
  this._alarmsAccess = false
  this._dashboardAccess = true
  this._intercomAccess = false
}

/**
 * Resets properties to their default values
 */
Profile.prototype.reset = function () {

  this._resetProperties()
  this._propertiesDirty = true
}

Profile.prototype.destroy = function () {

  this.removeAllListeners()
  this._propertiesDirty = true
}

module.exports = Profile
