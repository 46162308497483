'use strict'

angular
  .module('basalteApp')
  .directive('deviceBackground', deviceBackground)

function deviceBackground () {

  return {
    restrict: 'AE',
    template:
      '<div class="' +
      'bbs bbs-item bbs-centered' +
      ' bbs-flex bbs-active-select"' +
      ' bas-click="background.toggleBackground()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      ' data-translate="show_background_art"></div>' +
      '  <bas-toggle class="bbs-toggle bas-toggle-dark"' +
      ' data-bas-toggle="background.toggleBackground()"' +
      ' data-bas-toggled="background.background"></bas-toggle>' +
      '</div>',
    controller: [
      'CurrentBasCore',
      'BasPreferences',
      controller
    ],
    controllerAs: 'background'
  }

  /**
   * @param {CurrentBasCore} CurrentBasCore
   * @param {BasPreferences} BasPreferences
   */
  function controller (
    CurrentBasCore,
    BasPreferences
  ) {
    var background = this

    /**
     * @type {TCurrentBasCoreState}
     */
    var currentBasCoreState = CurrentBasCore.get()

    background.background = BasPreferences.getShowBackground()

    background.toggleBackground = toggleBackground

    function toggleBackground () {

      background.background = !background.background
      BasPreferences.setShowBackground(
        background.background,
        currentBasCoreState.core
      )
    }
  }
}
