'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasStoredServer', [
    'BAS_API',
    'BAS_APP_STORAGE',
    'BasStorageHelper',
    basStoredServerFactory
  ])

/**
 * @param BAS_API
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @param {BasStorageHelper} BasStorageHelper
 * @returns BasStoredServer
 */
function basStoredServerFactory (
  BAS_API,
  BAS_APP_STORAGE,
  BasStorageHelper
) {
  /**
   * @constructor
   * @param {BasServer} [basServer]
   */
  function BasStoredServer (basServer) {

    /**
     * @type {string}
     */
    this.type = BAS_APP_STORAGE.T_LOCAL

    /**
     * @type {number}
     */
    this.macN = 0

    /**
     * @type {string}
     */
    this.mac = ''

    /**
     * @type {string}
     */
    this.address = ''

    /**
     * Configuration identifier (Project ID)
     *
     * @type {string}
     */
    this.cid = ''

    /**
     * Key for local storage
     *
     * @type {string}
     */
    this.key = ''

    /**
     * Key for local storage, server settings
     *
     * @type {string}
     */
    this.serverSettingsKey = ''

    if (basServer) this.parseBasServer(basServer)
  }

  /**
   * @param {string} type
   * @param {string} cid
   * @param {(number|string)} mac
   * @param {string} address
   * @returns {string}
   */
  BasStoredServer.getKey = function (
    type,
    cid,
    mac,
    address
  ) {
    var result

    result = type

    if (type === BAS_APP_STORAGE.T_DEMO ||
      type === BAS_APP_STORAGE.T_REMOTE) {

      result += cid

    } else if (BAS_APP_STORAGE.T_LOCAL) {

      if (cid) {

        result += BAS_APP_STORAGE.V_CID + cid

      } else if (mac) {

        result += BAS_APP_STORAGE.V_MAC + mac

      } else if (address) {

        result += BAS_APP_STORAGE.V_ADDRESS + address
      }
    }

    return result
  }

  /**
   * @param {BasServer} basServer
   * @returns {string}
   */
  BasStoredServer.getKeyFromBasServer = function (basServer) {

    var _host

    if (BasUtil.isObject(basServer)) {

      _host = basServer.host

      return BasStoredServer.getKey(
        BasStorageHelper.getStoredServerType(basServer),
        basServer.cid,
        basServer.macN,
        _host ? _host.host : ''
      )
    }

    return ''
  }

  /**
   * @param {string} type
   * @param {string} cid
   * @param {(number|string)} mac
   * @param {string} address
   * @returns {string}
   */
  BasStoredServer.getServerSettingsKey = function (
    type,
    cid,
    mac,
    address
  ) {
    var result

    result = ''

    if (type === BAS_APP_STORAGE.T_DEMO ||
      type === BAS_APP_STORAGE.T_REMOTE) {

      result += BAS_APP_STORAGE.V_CID + cid

    } else if (BAS_APP_STORAGE.T_LOCAL) {

      if (cid) {

        result += BAS_APP_STORAGE.V_CID + cid

      } else if (mac) {

        result += BAS_APP_STORAGE.V_MAC + mac

      } else if (address) {

        result += BAS_APP_STORAGE.V_ADDRESS + address
      }
    }

    return result
  }

  /**
   * @param {BasServer} basServer
   * @returns {string}
   */
  BasStoredServer.getServerSettingsKeyFromBasServer =
    function (basServer) {

      var _host

      if (BasUtil.isObject(basServer)) {

        _host = basServer.host

        return BasStoredServer.getServerSettingsKey(
          BasStorageHelper.getStoredServerType(basServer),
          basServer.cid,
          basServer.macN,
          _host ? _host.host : ''
        )
      }

      return ''
    }

  /**
   * Create BasStoredServer instance from generic object data. (storage)
   *
   * @param {Object} obj
   * @returns {?BasStoredServer}
   */
  BasStoredServer.parse = function (obj) {

    var result, item

    if (BasUtil.isObject(obj)) {

      result = new BasStoredServer()

      item = obj[BAS_APP_STORAGE.K_TYPE]

      result.type = item || BAS_APP_STORAGE.T_LOCAL

      if (BasUtil.isPNumber(obj[BAS_APP_STORAGE.K_MAC_N])) {

        result.setMac(obj[BAS_APP_STORAGE.K_MAC])

      } else if (BasUtil.isNEString(obj[BAS_APP_STORAGE.K_MAC]) ||
        BasUtil.isPNumber(obj[BAS_APP_STORAGE.K_MAC])) {

        result.setMac(obj[BAS_APP_STORAGE.K_MAC])
      }

      if (BasUtil.isNEString(obj[BAS_APP_STORAGE.K_ADDRESS])) {

        result.address = obj[BAS_APP_STORAGE.K_ADDRESS]
      }

      if (BasUtil.isNEString(obj[BAS_APP_STORAGE.K_CID])) {

        result.cid = obj[BAS_APP_STORAGE.K_CID]
      }

      result._generateKeys()

      return result
    }

    return null
  }

  /**
   * Returns whether the BasStoredServer instance was changed or not.
   *
   * @param {BasServer} basServer
   * @returns {boolean}
   */
  BasStoredServer.prototype.parseBasServer = function (basServer) {

    var changed, type, value, _host

    changed = false

    type = BasStorageHelper.getStoredServerType(basServer)

    if (type) {

      // Type

      if (this.type !== type) {

        this.type = type
        changed = true
      }

      // Configuration/Project ID

      value = basServer.cid
      if (this.cid !== value) {

        this.cid = value
        changed = true
      }

      if (this.type === BAS_APP_STORAGE.T_LOCAL) {

        // MAC address
        // Should only change if basServer is used
        // for new BasStoredServer

        value = basServer.macN
        if (this.macN !== value) {

          this.macN = value
          changed = true
        }

        value = basServer.mac
        if (this.mac !== value) {

          this.mac = value
          changed = true
        }

        // Address/host

        _host = basServer.host

        if (_host) {

          value = _host.host
          if (this.address !== value) {

            this.address = value
            changed = true
          }
        }
      }

      this._generateKeys()
    }

    return changed
  }

  /**
   * @param {(string|number)} macAddress
   * @returns {boolean}
   */
  BasStoredServer.prototype.hasSameMac = function (macAddress) {

    if (BasUtil.isNEString(macAddress)) {

      return this.mac === macAddress

    } else if (BasUtil.isPNumber(macAddress)) {

      return this.macN === macAddress
    }

    return false
  }

  /**
   * @param {(string|number)} mac
   */
  BasStoredServer.prototype.setMac = function (mac) {

    var value

    if (BasUtil.isNEString(mac)) {

      value = BAS_API.MacAddressUtil.convertToNumber(mac)

      if (value) {

        this.macN = value
        this.mac = BAS_API.MacAddressUtil.convertToMac(value)
      }

    } else if (BasUtil.isPNumber(mac)) {

      value = BAS_API.MacAddressUtil.convertToMac(mac)

      if (value) {

        this.macN = mac
        this.mac = value
      }
    }
  }

  /**
   * Checks if this has a valid address
   *
   * @returns {boolean}
   */
  BasStoredServer.prototype.hasValidAddress = function () {

    return BasUtil.isValidHostname(this.address)
  }

  /**
   * @returns {string}
   */
  BasStoredServer.prototype.getKey = function () {

    return BasStoredServer.getKey(
      this.type,
      this.cid,
      this.macN,
      this.address
    )
  }

  /**
   * @returns {string}
   */
  BasStoredServer.prototype.getServerSettingsKey = function () {

    return BasStoredServer.getServerSettingsKey(
      this.type,
      this.cid,
      this.macN,
      this.address
    )
  }

  /**
   * @private
   */
  BasStoredServer.prototype._generateKeys = function () {

    this.key = this.getKey()
    this.serverSettingsKey = this.getServerSettingsKey()
  }

  /**
   * Checks if this stored server is Demo or not based on MAC address number
   *
   * @returns {boolean}
   */
  BasStoredServer.prototype.isDemo = function () {

    return this.type === BAS_APP_STORAGE.T_DEMO
  }

  /**
   * @returns {TBasConnectServer}
   */
  BasStoredServer.prototype.getBasConnectServer = function () {

    var result

    result = {}

    result.type = BasStorageHelper.getConnectServerType(this.type)

    if (this.macN) result.macAddress = this.macN
    if (this.address) result.address = this.address
    if (this.cid) result.cid = this.cid

    return result
  }

  /**
   * Create object used to store as JSON.
   *
   * @returns {?Object}
   */
  BasStoredServer.prototype.toStorageData = function () {

    var result

    result = {}

    result[BAS_APP_STORAGE.K_TYPE] = this.type

    if (BasUtil.isNEString(this.cid)) {

      result[BAS_APP_STORAGE.K_CID] = this.cid
    }

    if (BasUtil.isNEString(this.address)) {

      result[BAS_APP_STORAGE.K_ADDRESS] = this.address
    }

    if (BasUtil.isPNumber(this.macN)) {

      result[BAS_APP_STORAGE.K_MAC_N] = this.macN
    }

    return result
  }

  return BasStoredServer
}
