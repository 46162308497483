'use strict'

angular
  .module('basalteApp')
  .directive('basPressInput', basPressInput)

function basPressInput () {

  return {
    restrict: 'AE',
    scope: {
      tap: '&',
      tapTime: '<',
      startPress: '&',
      longPress: '&',
      longPressTime: '<',
      pressEnd: '&',
      panEnd: '&',
      panStart: '&'
    },
    controller: [
      '$window',
      '$scope',
      '$element',
      controller
    ]
  }

  function controller (
    $window,
    $scope,
    $element
  ) {
    var _hmElement, _recTap, _recLongPress, _recStartPress, _recPan

    $scope.$on('$destroy', onDestroy)
    this.$postLink = onPostLink

    function onPostLink () {

      _hmElement = new $window.Hammer.Manager($element[0])

      _recTap = new $window.Hammer.Tap({
        time: $scope.tapTime
      })
      _recStartPress = new $window.Hammer.Press({
        event: 'startPress',
        time: $scope.tapTime
      })
      _recLongPress = new $window.Hammer.Press({
        event: 'longPress',
        time: $scope.longPressTime
      })
      _recPan = new $window.Hammer.Pan()

      _hmElement.add(_recTap)
      _hmElement.add(_recStartPress)
      _hmElement.add(_recLongPress)
      _hmElement.add(_recPan)

      _hmElement.on('tap', handleTap)
      _hmElement.on('startPress', handleStartPress)
      _hmElement.on('longPress', handleLongPress)
      _hmElement.on('startPressup', handleEnd)
      _hmElement.on('panstart', handlePanStart)
      _hmElement.on('panend', handlePanEnd)
    }

    function handleTap () {

      if (typeof $scope.tap === 'function') $scope.tap()
    }

    function handleStartPress () {

      if (typeof $scope.startPress === 'function') $scope.startPress()
    }

    function handleLongPress () {

      if (typeof $scope.longPress === 'function') $scope.longPress()
    }

    function handleEnd () {

      if (typeof $scope.pressEnd === 'function') $scope.pressEnd()
    }

    function handlePanStart () {

      if (typeof $scope.panStart === 'function') $scope.panStart()
    }

    function handlePanEnd () {

      if (typeof $scope.panEnd === 'function') $scope.panEnd()
    }

    function onDestroy () {

      if (_hmElement) _hmElement.destroy()
    }
  }
}
