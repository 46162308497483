'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasWeatherExternal', [
    'ICONS',
    'BAS_API',
    'BAS_UNITS',
    'BAS_WEATHER',
    'CurrentBasCore',
    'BasUnitValue',
    'BasWeatherUi',
    'BasUtilities',
    basWeatherExternalFactory
  ])

/**
 * @param {ICONS} ICONS
 * @param BAS_API
 * @param {BAS_UNITS} BAS_UNITS
 * @param {BAS_WEATHER} BAS_WEATHER
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasUnitValue
 * @param BasWeatherUi
 * @param {BasUtilities} BasUtilities
 * @returns BasWeatherExternal
 */
function basWeatherExternalFactory (
  ICONS,
  BAS_API,
  BAS_UNITS,
  BAS_WEATHER,
  CurrentBasCore,
  BasUnitValue,
  BasWeatherUi,
  BasUtilities
) {

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @constant {string}
   */
  var RETRIEVE_WEATHER_DATA_RETRIES = 1

  /**
   * @constant {string}
   */
  var RETRIEVE_WEATHER_DATA_RETRY_DELAY = 1000

  BAS_WEATHER.EXTERNAL_MAPPING_ICON = {}
  BAS_WEATHER.EXTERNAL_MAPPING_ICON[BAS_WEATHER.K_CLEAR_DAY] =
    BAS_WEATHER.ICON_CLEAR_DAY
  BAS_WEATHER.EXTERNAL_MAPPING_ICON[BAS_WEATHER.K_CLEAR_NIGHT] =
    BAS_WEATHER.ICON_CLEAR_NIGHT
  BAS_WEATHER.EXTERNAL_MAPPING_ICON[BAS_WEATHER.K_CLOUDY] =
    BAS_WEATHER.ICON_CLOUDY
  BAS_WEATHER.EXTERNAL_MAPPING_ICON[BAS_WEATHER.K_FOG] =
    BAS_WEATHER.ICON_FOG
  BAS_WEATHER.EXTERNAL_MAPPING_ICON[BAS_WEATHER.K_PARTLY_CLOUDY_DAY] =
    BAS_WEATHER.ICON_PARTLY_CLOUDY_DAY
  BAS_WEATHER.EXTERNAL_MAPPING_ICON[BAS_WEATHER.K_PARTLY_CLOUDY_NIGHT] =
    BAS_WEATHER.ICON_PARTLY_CLOUDY_NIGHT
  BAS_WEATHER.EXTERNAL_MAPPING_ICON[BAS_WEATHER.K_RAIN] =
    BAS_WEATHER.ICON_RAIN
  BAS_WEATHER.EXTERNAL_MAPPING_ICON[BAS_WEATHER.K_SNOW] =
    BAS_WEATHER.ICON_SNOW
  BAS_WEATHER.EXTERNAL_MAPPING_ICON[BAS_WEATHER.K_WIND] =
    BAS_WEATHER.ICON_WIND

  /**
   * @constructor
   * @extends BasWeatherUi
   */
  function BasWeatherExternal () {

    BasWeatherUi.call(this)

    this.uuid = BAS_WEATHER.DARK_SKY_UUID
    this.source = BAS_WEATHER.SOURCE_ONLINE

    this._handleParse = this._parse.bind(this)
    this._handleError = this._onUpdateError.bind(this)
    this._handleWeatherData = this._onWeatherData.bind(this)

    this._makeDefaultUi()
  }

  BasWeatherExternal.prototype = Object.create(BasWeatherUi.prototype)
  BasWeatherExternal.prototype.constructor = BasWeatherExternal

  /**
   * @constant {string}
   */
  BasWeatherExternal.ERR_INVALID_RESPONSE = 'errWeatherInvalidResponse'

  /**
   * @constant {string}
   */
  BasWeatherExternal.ERR_REQUEST_FAILED = 'errRequestFailed'

  /**
   * @constant {string}
   */
  BasWeatherExternal.ERR_NO_CORE = 'errNoCore'

  /**
   * @param {string} [locale]
   * @returns {Promise}
   */
  BasWeatherExternal.prototype.update = function (locale) {

    return this.retrieveWeatherDataRetry(
      locale,
      RETRIEVE_WEATHER_DATA_RETRY_DELAY,
      RETRIEVE_WEATHER_DATA_RETRIES
    )
      .then(this._handleWeatherData, this._handleError)
  }

  /**
   * @param {string} [locale]
   * @param {number} retryDelay
   * @param {number} maxRetries
   * @returns {Promise}
   */
  BasWeatherExternal.prototype.retrieveWeatherDataRetry = function (
    locale,
    retryDelay,
    maxRetries
  ) {

    var retryCounter = 0

    if (CurrentBasCore.has()) {

      return retrieveWeatherData(currentBasCoreState, locale)
        .catch(handleError)
    }

    return Promise.reject(BasWeatherExternal.ERR_NO_CORE)

    function handleError (error) {

      retryCounter += 1

      if (retryCounter > maxRetries) return Promise.reject(error)

      return waitMs(retryDelay).then(retrieveWeatherData).catch(handleError)
    }

    function waitMs (ms) {
      return new Promise(function (resolve) {
        setTimeout(resolve, ms)
      })
    }

    function retrieveWeatherData () {

      if (CurrentBasCore.has()) {

        return currentBasCoreState.core.retrieveWeatherData(locale)
      }

      return Promise.reject(BasWeatherExternal.ERR_NO_CORE)
    }
  }

  /**
   * @private
   * @param {Object} result
   * @returns {(Promise|boolean)}
   */
  BasWeatherExternal.prototype._onWeatherData = function (result) {

    if (
      BasUtil.isObject(result) &&
      BasUtil.isObject(result.data) &&
      result.data.code === 400
    ) {

      // Code 400: given locale was invalid. Try without locale

      return this.retrieveWeatherDataRetry(
        '',
        RETRIEVE_WEATHER_DATA_RETRY_DELAY,
        RETRIEVE_WEATHER_DATA_RETRIES
      )
        .then(this._handleParse, this._handleError)
    }

    return this._parse(result)
  }

  /**
   * @private
   * @param {Object} result
   * @returns {(Promise|boolean)}
   */
  BasWeatherExternal.prototype._parse = function (result) {

    var data, currently, daily, forecasts, forecast
    var hourlyForecast, hourly, hourlyForecasts
    var i, length, day, date, hour, tempIcon, sunRise

    sunRise = false

    this._clearData()
    this.resetCss()

    if (
      BasUtil.isObject(result) &&
      BasUtil.isObject(result.data)
    ) {

      data = result.data

      currently = data.currently
      daily = data.daily
      hourly = data.hourly

      this.dirty = false
      this.loaded = true

      // Currently
      if (BasUtil.isObject(currently)) {

        // Source
        this.css[BAS_WEATHER.CSS_HAS_CURRENT_BOTTOM_RIGHT_TITLE] = true

        // Temperature
        if (BasUtil.isVNumber(currently.temperature)) {
          this.temperature = new BAS_API.Temperature()
          this.temperature.setTemperature(
            currently.temperature,
            BAS_API.CONSTANTS.TU_CELSIUS
          )

          this.css[BAS_WEATHER.CSS_HAS_TEMPERATURE] = true
        }

        // Apparent Temperature
        if (BasUtil.isVNumber(currently.apparentTemperature)) {

          this.apparentTemperature = new BAS_API.Temperature()
          this.apparentTemperature.setTemperature(
            currently.apparentTemperature,
            BAS_API.CONSTANTS.TU_CELSIUS
          )

          this.css[BAS_WEATHER.CSS_HAS_APPARENT_TEMPERATURE] = true
        }

        // Humidity
        if (BasUtil.isVNumber(currently.humidity)) {
          this.humidity = currently.humidity

          this.css[BAS_WEATHER.CSS_HAS_HUMIDITY] = true
        }

        // Wind (Convert m/s to km/h)
        if (BasUtil.isVNumber(currently.windSpeed)) {
          this.wind = new BasUnitValue({
            value: (currently.windSpeed / 1000 * 3600),
            unit: BAS_UNITS.KM_H,
            fixed: 1
          })

          this.css[BAS_WEATHER.CSS_HAS_WIND_SPEED] = true
        }

        // WindDirection
        if (BasUtil.isVNumber(currently.windBearing)) {
          this.windDirection = Math.round(currently.windBearing)

          this.css[BAS_WEATHER.CSS_HAS_WIND_DIRECTION] = true
        }

        // UV
        if (BasUtil.isVNumber(currently.uvIndex)) {
          this.uv = Math.round(currently.uvIndex)

          this.css[BAS_WEATHER.CSS_HAS_UV] = true
        }

        // Pressure
        if (BasUtil.isVNumber(currently.pressure)) {
          this.pressure = new BasUnitValue({
            value: currently.pressure,
            unit: BAS_UNITS.HPA,
            fixed: 1
          })

          this.css[BAS_WEATHER.CSS_HAS_PRESSURE] = true
        }

        // Precipitation
        if (BasUtil.isVNumber(currently.precipIntensity)) {
          this.precipitation = new BasUnitValue({
            value: currently.precipIntensity,
            unit: BAS_UNITS.MM_H
          })

          this.css[BAS_WEATHER.CSS_HAS_PRECIPITATION] = true
        }

        // Icon
        tempIcon = this.convertIconKey(currently.icon)

        if (tempIcon) {

          this.icon = tempIcon

          this.css[BAS_WEATHER.CSS_HAS_ICON] = true
        }

        // Summary
        if (BasUtil.isNEString(currently.summary)) {
          this.summary = currently.summary

          this.css[BAS_WEATHER.CSS_HAS_CURRENT_BOTTOM_LEFT_TITLE] =
            true
        }
      }

      // Check forecast array
      if (BasUtil.isObject(daily) &&
        BasUtil.isNEArray(daily.data)) {

        forecasts = daily.data
        length = forecasts.length
        this.forecasts = []

        // Parse through every day
        for (i = 0; i < length; i++) {
          forecast = forecasts[i]
          day = {}

          // Low temperature
          if (BasUtil.isVNumber(forecast.temperatureMin)) {
            day.lowTemp = new BAS_API.Temperature()
            day.lowTemp.setTemperature(
              forecast.temperatureMin,
              BAS_API.CONSTANTS.TU_CELSIUS
            )
          }

          // High temperature
          if (BasUtil.isVNumber(forecast.temperatureMax)) {
            day.highTemp = new BAS_API.Temperature()
            day.highTemp.setTemperature(
              Math.round(forecast.temperatureMax),
              BAS_API.CONSTANTS.TU_CELSIUS
            )
          }

          // Sunrise
          if (BasUtil.isVNumber(forecast.sunriseTime)) {
            day.sunrise =
              new Date(forecast.sunriseTime * 1000)

            if (i === 0) sunRise = true
          }

          // Sunset
          if (BasUtil.isVNumber(forecast.sunsetTime)) {
            day.sunset =
              new Date(forecast.sunsetTime * 1000)

            if (i === 0 && sunRise) {

              this.css[BAS_WEATHER.CSS_HAS_SUN_RISE_SET] = true
            }
          }

          // Cloudiness
          if (BasUtil.isVNumber(forecast.cloudCover)) {
            day.cloudiness = forecast.cloudCover
          }

          // Pressure
          if (BasUtil.isVNumber(forecast.pressure)) {
            day.pressure = new BasUnitValue({
              value: Math.round(forecast.pressure),
              unit: BAS_UNITS.HPA,
              fixed: 0
            })
          }

          // Wind (Convert m/s to km/h)
          if (BasUtil.isVNumber(forecast.windSpeed)) {
            day.wind = new BasUnitValue({
              value: forecast.windSpeed / 1000 * 3600,
              unit: BAS_UNITS.KM_H,
              fixed: 1
            })
          }

          // Wind
          if (BasUtil.isVNumber(forecast.windBearing)) {
            day.windDirection = forecast.windBearing
          }

          // Uv
          if (BasUtil.isVNumber(forecast.uvIndex)) {
            day.uv = forecast.uvIndex
          }

          // Icon
          tempIcon = this.convertIconKey(forecast.icon)

          if (tempIcon) {

            day.icon = tempIcon
          }

          if (i === 0) {

            day.day = BasUtilities.translate('today')

          } else if (i === 1) {

            day.day = BasUtilities.translate('tomorrow')

          } else if (i > 1) {

            date = new Date()
            day.day = this.getDayName(date.getDay() + i)
          }

          this.forecasts.push(day)
        }
      }

      // Check hourly forecast array
      if (BasUtil.isObject(hourly) &&
        Array.isArray(hourly.data)) {

        this.hourlyForecasts = []

        // Parse through every hour

        hourlyForecasts = hourly.data
        length = hourlyForecasts.length
        for (i = 0; i < length; i++) {

          hourlyForecast = hourlyForecasts[i]
          hour = {}

          // Temperature
          if (BasUtil.isVNumber(hourlyForecast.temperature)) {

            hour.temp = new BAS_API.Temperature()
            hour.temp.setTemperature(
              hourlyForecast.temperature,
              BAS_API.CONSTANTS.TU_CELSIUS
            )
          }

          // Icon
          tempIcon = this.convertIconKey(hourlyForecast.icon)

          if (tempIcon) {

            hour.icon = tempIcon
          }

          // Time
          if (BasUtil.isVNumber(hourlyForecast.time)) {

            hour.time = new Date(hourlyForecast.time * 1000)
          }

          this.hourlyForecasts.push(hour)
        }

      }

      // Check for valid city
      if (CurrentBasCore.hasCore() &&
        currentBasCoreState.core.core.system &&
        BasUtil.isNEString(currentBasCoreState.core.core.system.city)) {

        this.location = currentBasCoreState.core.core.system.city

        this.css[BAS_WEATHER.CSS_HAS_CURRENT_TOP_LEFT_TITLE] = true
      }

      this.makeUi()
      return true
    }

    this._setErrorMessage()
    return Promise.reject(BasWeatherExternal.ERR_INVALID_RESPONSE)
  }

  /**
   * Convert key from external weather service to an icon
   *
   * @param iconKey
   * @returns {?string}
   */
  BasWeatherExternal.prototype.convertIconKey = function (iconKey) {

    if (BasUtil.isNEString(iconKey) &&
      BasUtil.isNEString(BAS_WEATHER.EXTERNAL_MAPPING_ICON[iconKey])) {

      return ICONS[BAS_WEATHER.EXTERNAL_MAPPING_ICON[iconKey]]
    }

    return null
  }

  return BasWeatherExternal
}
