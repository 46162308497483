'use strict'

angular
  .module('basalteApp')
  .factory('TidalElement', [
    'BAS_LIBRARY',
    'BAS_SOURCE',
    'BasLibraryElement',
    tidalElementFactory
  ])

/**
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param BasLibraryElement
 * @returns TidalElement
 */
function tidalElementFactory (
  BAS_LIBRARY,
  BAS_SOURCE,
  BasLibraryElement
) {

  /**
   * Tidal Ui element class
   * contains all information needed for HTML templates
   *
   * @constructor
   * @extends BasLibraryElement
   */
  function TidalElement () {

    // Call 'super' constructor
    BasLibraryElement.call(this)

    this.libraryType = BAS_LIBRARY.TYPE_TIDAL

    /**
     * @instance
     * @type {string}
     */
    this.type = TidalElement.TYPE_TRACK

    /**
     * @instance
     * @type {string}
     */
    this.servicePrefix = BAS_SOURCE.URI_PREFIX_TIDAL

    /**
     * @instance
     * @type {string}
     */
    this.subType = ''

    /**
     * @instance
     * @type {string}
     */
    this.url = ''

    /**
     * @instance
     * @type {string}
     */
    this.collectionType = ''

    /**
     * @instance
     * @type {string}
     */
    this.path = ''

    /**
     * @instance
     * @type {string}
     */
    this.timeCreated = ''

    /**
     * @instance
     * @type {number}
     */
    this.creator = -1

    /**
     * @instance
     * @type {Object}
     */
    this.artist = null

    /**
     * @instance
     * @type {Object}
     */
    this.album = null

    /**
     * @instance
     * @type {string}
     */
    this.uri = ''
  }

  // Set new prototype from inherited object
  TidalElement.prototype = Object.create(BasLibraryElement.prototype)

  // Set correct constructor after setting new prototype
  TidalElement.prototype.constructor = TidalElement

  // region Tidal types

  /**
   * @constant {string}
   */
  TidalElement.TYPE_ALBUM = 'album'

  /**
   * @constant {string}
   */
  TidalElement.TYPE_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  TidalElement.TYPE_GENRE = 'genre'

  /**
   * @constant {string}
   */
  TidalElement.TYPE_MOOD = 'mood'

  /**
   * @constant {string}
   */
  TidalElement.TYPE_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  TidalElement.TYPE_TRACK = 'track'

  // Custom types

  /**
   * @constant {string}
   */
  TidalElement.TYPE_SECTION = 'section'

  TidalElement.prototype.getFavouriteId = function () {
    return this.type + ':' + this.id
  }

  // endregion

  return TidalElement
}
