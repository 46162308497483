[
    {
        "sipAddress": "garage@door",
        "password": "garagedoor>",
        "doorPhone": {
           "name": "Garage Door",
           "uuid": "garageDoor"
        },
        "contact": {
           "name": "George",
           "uuid": "contact1"
        },
        "doors": [
             {
              "uuid": "Door 1",
              "name": "door1"
             }
           ]
      }, {
        "sipAddress": "front@door",
        "password": "frontdoor",
        "doorPhone": {
           "name": "Front Door",
           "uuid": "frontDoor"
        },
        "contact": {
           "name": "Jane",
           "uuid": "contact2"
        },
        "doors": [
             {
              "uuid": "Door 2",
              "name": "door2"
             }
           ]
      }, {
        "sipAddress": "pool@house",
        "password": "poolhouse",
        "doorPhone": {
           "name": "Pool House",
           "uuid": "poolHouse"
        },
        "contact": {
           "name": "Elroy",
           "uuid": "contact3"
        },
        "doors": [
             {
              "uuid": "Door 3",
              "name": "door3"
             }
           ]
      }
]