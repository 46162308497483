'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasInAppBrowser', [
    '$window',
    'BasAppDevice',
    'BasStatusBar',
    'BasPreferences',
    'BasUtilities',
    'Logger',
    basInAppBrowserFactory
  ])

/**
 * @param $window
 * @param {BasAppDevice} BasAppDevice
 * @param {BasStatusBar} BasStatusBar
 * @param {BasPreferences} BasPreferences
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns BasInAppBrowser
 */
function basInAppBrowserFactory (
  $window,
  BasAppDevice,
  BasStatusBar,
  BasPreferences,
  BasUtilities,
  Logger
) {
  var className = 'BasInAppBrowser'

  var TARGET_BLANK = '_blank'
  var TARGET_SYSTEM = '_system'

  var EVT_LOADSTART = 'loadstart'
  var EVT_LOADSTOP = 'loadstop'
  var EVT_LOADERROR = 'loaderror'
  var EVT_EXIT = 'exit'
  var EVT_MESSAGE = 'message'

  var InAppBrowser = $window['cordova']?.['InAppBrowser']

  /**
   * @constructor
   * @param {string} linkUrl
   * @param {Object} [options]
   * @param {Function} [options.checkStatus]
   * @param {Function} [options.onLoadStop]
   * @param {Function} [options.onExit]
   */
  function BasInAppBrowser (linkUrl, options) {

    const checkStatus = options?.checkStatus
    const onLoadStop = options?.onLoadStop
    const onExit = options?.onExit

    /**
     * @instance
     * @type {?InAppBrowser}
     */
    this.inAppBrowser = null

    /**
     * @type {?Window}
     */
    this.browserWindow = null

    this.checkStatus = checkStatus
    this.onLoadStop = onLoadStop
    this.onExit = onExit

    this._handleLoadStart = this.onIABLoadStart.bind(this)
    this._handleLoadStop = this.onIABLoadStop.bind(this)
    this._handleLoadError = this.onIABLoadError.bind(this)
    this._handleExit = this.onIABExit.bind(this)
    this._handleMessage = this.onIABMessage.bind(this)

    // Create Cordova InAppBrowser options
    let iabOptions = ''

    if (BasAppDevice.isIos()) {

      iabOptions = 'location=no'
      iabOptions += ',usewkwebview=yes'
      iabOptions += ',closebuttoncaption=' +
        BasUtilities.translate('close')
      iabOptions += ',clearsessioncache=yes'
      iabOptions += ',clearcache=yes'

    } else if (BasAppDevice.isAndroid()) {

      iabOptions = 'location=no'
      iabOptions += ',hideurlbar=yes'
      iabOptions += ',closebuttoncaption=' +
        BasUtilities.translate('close')
      iabOptions += ',closebuttoncolor=#888888'
      iabOptions += ',footer=yes'
      iabOptions += ',footercolor=#000000'
      iabOptions += ',shouldPauseOnSuspend=yes'
      iabOptions += ',clearsessioncache=yes'
      iabOptions += ',clearcache=yes'
    }

    this.closeInAppBrowser()

    if (BasAppDevice.isBrowser() || !InAppBrowser) {

      if (!InAppBrowser) {
        Logger.warn('No in-app-browser, falling back to new tab')
      }

      // Open page in new tab/page
      this.browserWindow = window.open(
        linkUrl,
        TARGET_SYSTEM
      )

    } else if (InAppBrowser) {

      // Show status bar
      BasStatusBar.show()

      // Open the InAppBrowser
      this.inAppBrowser = InAppBrowser.open(
        linkUrl,
        TARGET_BLANK,
        iabOptions
      )
      this.setInAppBrowserEventListeners()
    }
  }

  BasInAppBrowser.prototype._checkStatus = function () {

    if (typeof this.checkStatus === 'function') this.checkStatus()
  }

  /**
   *
   * @param {string} code
   * @returns {Promise<void>}
   */
  BasInAppBrowser.prototype.executeScript = async function (code) {

    // Check for valid InAppBrowser and execute script
    if (this.inAppBrowser && this.inAppBrowser.executeScript) {

      return new Promise((resolve, _reject) => {
        this.inAppBrowser.executeScript({ code: code }, (result) => {
          resolve(result)
        })
      })
    }

    return Promise.reject('No in-app-browser')
  }

  BasInAppBrowser.prototype.closeInAppBrowser = function () {

    // 'exit' event is not triggered when manually closing the in-app-browser,
    //  trigger it manually
    if (BasUtil.isFunction(this.onExit)) {
      this.onExit()
    }

    // Make sure all event listeners are removed
    this.removeInAppBrowserEventListeners()

    // Check for valid InAppBrowser and close it
    if (this.inAppBrowser && this.inAppBrowser.close) {

      this.inAppBrowser.close()

      // Disable status bar if it used to be disabled
      if (!BasPreferences.getShowStatusBar()) {

        // [HACK] Wait for X frames for the inAppBrowser to close
        BasUtilities.waitFrames(60).then(hideStatusBar)
      }
    }

    // Check for valid Window and close it
    if (this.browserWindow && this.browserWindow.close) {

      this.browserWindow.close()
    }

    this.inAppBrowser = null
    this.browserWindow = null
  }

  BasInAppBrowser.prototype.setInAppBrowserEventListeners = function () {

    // Make sure all event listeners are removed
    this.removeInAppBrowserEventListeners()

    // Check for valid InAppBrowser
    if (BasUtil.isObject(this.inAppBrowser)) {

      this.inAppBrowser.addEventListener(
        EVT_LOADSTART,
        this._handleLoadStart
      )
      this.inAppBrowser.addEventListener(
        EVT_LOADSTOP,
        this._handleLoadStop
      )
      this.inAppBrowser.addEventListener(
        EVT_LOADERROR,
        this._handleLoadError
      )
      this.inAppBrowser.addEventListener(
        EVT_EXIT,
        this._handleExit
      )
      this.inAppBrowser.addEventListener(
        EVT_MESSAGE,
        this._handleMessage
      )
    }
  }

  BasInAppBrowser.prototype.removeInAppBrowserEventListeners = function () {

    // Check for valid InAppBrowser
    if (BasUtil.isObject(this.inAppBrowser)) {

      this.inAppBrowser.removeEventListener(
        EVT_LOADSTART,
        this._handleLoadStart
      )
      this.inAppBrowser.removeEventListener(
        EVT_LOADSTOP,
        this._handleLoadStop
      )
      this.inAppBrowser.removeEventListener(
        EVT_LOADERROR,
        this._handleLoadError
      )
      this.inAppBrowser.removeEventListener(
        EVT_EXIT,
        this._handleExit
      )
    }
  }

  BasInAppBrowser.prototype.onIABLoadStart = function (event) {
    Logger.debug(className + ' - IAB LoadStart', event)
  }

  BasInAppBrowser.prototype.onIABLoadStop = function (event) {
    Logger.debug(className + ' - IAB LoadStop', event)

    this._checkStatus()

    if (BasUtil.isFunction(this.onLoadStop)) {
      this.onLoadStop()
    }
  }

  BasInAppBrowser.prototype.onIABLoadError = function (event) {
    Logger.debug(className + ' - IAB LoadError', event)
  }

  BasInAppBrowser.prototype.onIABExit = function (event) {
    Logger.debug(className + ' - IAB LoadExit', event)

    this._checkStatus()

    this.closeInAppBrowser()

    if (BasUtil.isFunction(this.onExit)) {
      this.onExit()
    }
  }

  BasInAppBrowser.prototype.onIABMessage = function (message) {

    if (message.type !== 'message') return

    Logger.debug(className + ' - IAB message', message)

    switch (message?.data?.message) {
      case 'close':
        this.closeInAppBrowser()
        break
    }
  }

  return BasInAppBrowser

  function hideStatusBar () {

    BasStatusBar.hide()
  }
}
