'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basImageTransition')
  .factory('BasImage', [
    '$sce',
    basImageFactory
  ])

/**
 * @typedef {Object} TBasImageOptions
 * @property {string[]} [customClass]
 */

/**
 * @param $sce
 * @returns BasImage
 */
function basImageFactory (
  $sce
) {
  var CSS_BG = 'bas-image--bg'
  var CSS_HTML = 'bas-image--html'

  /**
   * Class to represent an image with specific properties (CSS classes)
   *
   * @constructor
   * @param {(string|Object|BasImage)} image
   * @param {TBasImageOptions} [options]
   */
  function BasImage (image, options) {

    /**
     * URL for image resource
     *
     * @type {string}
     */
    this.url = ''

    /**
     * Object consisting of image sizes paired with their url
     *
     * @type {?Object}
     */
    this.urlObj = null

    /**
     * Angular trusted content object containing SVG code
     *
     * @type {?Object}
     */
    this.html = null

    /**
     * 4 (or more) URLs for a quad composed image
     *
     * @type {string[]}
     */
    this.quadUrl = []

    /**
     * @type {string}
     */
    this.loadedUrl = ''

    /**
     * @type {string}
     */
    this.loadedColor = ''

    /**
     * @type {?Object}
     */
    this.loadedHtml = null

    /**
     * Holds custom classes
     *
     * @private
     * @type {string[]}
     */
    this._initialCustomCss = []

    /**
     * @type {Object}
     */
    this.css = {}
    this._syncCss()

    if (image) this.setSrc(image)

    if (options) {

      if (Array.isArray(options.customClass)) {

        this._initialCustomCss = options.customClass
        this.setInitialCss()
      }
    }
  }

  /**
   * Checks if 2 BasImages are the same
   * based on their image URL, html, quadUrl or urlObj.
   * Also compares the initial custom CSS.
   *
   * @param {?BasImage} img1
   * @param {?BasImage} img2
   * @param {Object} [options]
   * @param {boolean} [options.checkInitialCSS = true]
   * @param {boolean} [options.checkCustomCSS = false]
   * @returns {boolean}
   */
  BasImage.isEqual = function isEqual (
    img1,
    img2,
    options
  ) {
    var _checkInitialCSS, _checkCustomCSS
    var equal, equalInitialCSS, equalCustomCSS

    equal = false

    _checkInitialCSS = true
    _checkCustomCSS = false

    if (BasUtil.isObject(options)) {

      if (BasUtil.isBool(options.checkInitialCSS)) {

        _checkInitialCSS = options.checkInitialCSS
      }

      if (BasUtil.isBool(options.checkCustomCSS)) {

        _checkCustomCSS = options.checkCustomCSS
      }
    }

    if (BasUtil.isObject(img1) &&
      (
        BasUtil.isNEString(img1.url) ||
        BasUtil.isObject(img1.html) ||
        BasUtil.isNEArray(img1.quadUrl) ||
        BasUtil.isObject(img1.urlObj)
      ) &&
      BasUtil.isObject(img2) &&
      (
        BasUtil.isNEString(img2.url) ||
        BasUtil.isObject(img2.html) ||
        BasUtil.isNEArray(img2.quadUrl) ||
        BasUtil.isObject(img2.urlObj)
      )) {

      if (BasUtil.isNEString(img1.url)) {

        equal = img1.url === img2.url

      } else if (BasUtil.isObject(img1.html)) {

        equal = img1.html === img2.html

      } else if (BasUtil.isNEArray(img1.quadUrl)) {

        equal = BasUtil.isEqualArray(
          img1.quadUrl,
          img2.quadUrl
        )

      } else if (BasUtil.isObject(img1.urlObj)) {

        equal = BasUtil.compareObjects(
          img1.urlObj,
          img2.urlObj
        )
      }

      if (!equal) return false

      equalInitialCSS = _checkInitialCSS
        ? BasUtil.isEqualArrayUn(
          img1._initialCustomCss,
          img2._initialCustomCss
        )
        : true

      equalCustomCSS = _checkCustomCSS
        ? BasImage.isEqualCustomCSS(img1, img2)
        : true

      return equalInitialCSS && equalCustomCSS
    }

    return false
  }

  /**
   * Checks if 2 BasImages have equal Custom CSS.
   *
   * @param {?BasImage} img1
   * @param {?BasImage} img2
   * @returns {boolean}
   */
  BasImage.isEqualCustomCSS = function (img1, img2) {

    if (BasUtil.isObject(img1) &&
      img1.getCustomCSS &&
      BasUtil.isObject(img2) &&
      img2.getCustomCSS) {

      return BasUtil.isEqualArrayUn(
        img1.getCustomCSS(),
        img2.getCustomCSS()
      )
    }

    return false
  }

  /**
   * @private
   * @param {string[]} quad
   * @returns {?Object}
   */
  BasImage._createQuadImages = function (quad) {

    if (quad.length >= 4) {

      return $sce.trustAsHtml(
        '<div class="bas-image-img bii-tl" ' +
        'style="background-image: url(' + quad[0] + ')"></div>' +
        '<div class="bas-image-img bii-tr" ' +
        'style="background-image: url(' + quad[1] + ')"></div>' +
        '<div class="bas-image-img bii-bl" ' +
        'style="background-image: url(' + quad[2] + ')"></div>' +
        '<div class="bas-image-img bii-br" ' +
        'style="background-image: url(' + quad[3] + ')"></div>'
      )
    }

    return null
  }

  /**
   * Set a new image source
   *
   * @param {(string|Object|BasImage)} src
   */
  BasImage.prototype.setSrc = function setSrc (src) {

    if (src instanceof BasImage) {

      this.copy(src)

    } else {

      this.setImage(src)
    }
  }

  /**
   * @param {BasImage} image
   */
  BasImage.prototype.copy = function copy (image) {

    if (image instanceof BasImage) {

      this.url = image.url
      this.urlObj = BasUtil.copyObjectShallow(image.urlObj)
      this.html = image.html
      this.quadUrl = image.quadUrl.slice()

      this.loadedUrl = image.loadedUrl
      this.loadedColor = image.loadedColor
      this.loadedHtml = image.loadedHtml

      this._initialCustomCss = image._initialCustomCss.slice()
      this.css = BasUtil.copyObjectShallow(image.css)

      this._syncCss()
    }
  }

  /**
   * Show image
   *
   * @param {(string|Object)} image
   */
  BasImage.prototype.setImage = function (image) {

    this.url = ''
    this.html = null

    if (image) {

      if (typeof image === 'string') {

        this.url = image

      } else if (typeof image === 'object') {

        this.html = image
        this.loadedHtml = this.html
      }
    }

    this._syncCss()
  }

  /**
   * Set a quad, 4 images
   *
   * @param {string[]} quad
   */
  BasImage.prototype.setQuad = function setQuad (quad) {

    if (Array.isArray(quad)) {

      this.url = ''
      this.urlObj = null
      this.html = null
      this.quadUrl = quad

      if (this.quadUrl.length >= 4) {

        if (!BasUtil.isEqualArray(this.quadUrl, quad)) {

          this.loadedHtml = null
        }

      } else {

        this.setSrc(quad[0])
      }

    } else {

      this.html = null
      this.quadUrl = []
    }
  }

  /**
   * @returns {boolean}
   */
  BasImage.prototype.canBeLoaded = function () {

    return !!(
      this.url ||
      this.urlObj ||
      (Array.isArray(this.quadUrl) && this.quadUrl.length > 0)
    )
  }

  /**
   * @returns {boolean}
   */
  BasImage.prototype.isQuadProcessed = function () {

    return !!(this.quadUrl.length && this.loadedHtml)
  }

  /**
   * @param {string[]} quadUrls Original request URLs
   * @param {string[]} quad Processed
   */
  BasImage.prototype.setProcessedQuad = function (
    quadUrls,
    quad
  ) {
    if (BasUtil.isEqualArray(this.quadUrl, quadUrls)) {

      this.loadedHtml = BasImage._createQuadImages(quad)
    }
  }

  /**
   * Set an object of multiple urls
   *
   * @param {Object} urls
   */
  BasImage.prototype.setMultipleUrls = function setMultipleUrls (
    urls
  ) {
    if (BasUtil.isObject(urls)) {

      this.url = ''
      this.html = null
      this.quadUrl = []

      this.urlObj = urls

    } else {

      this.urlObj = null
    }
  }

  /**
   * @returns {string[]}
   */
  BasImage.prototype.getCustomCSS = function () {

    var keys, i, length, result, _class

    result = []

    keys = Object.keys(this.css)
    length = keys.length
    for (i = 0; i < length; i++) {

      _class = keys[i]

      if (_class !== CSS_BG &&
        _class !== CSS_HTML &&
        this._initialCustomCss.indexOf(_class) === -1) {

        result.push(_class)
      }
    }

    return result
  }

  /**
   * Sets the initial (constructor) custom classes,
   * clearing out any overrides or extra classes.
   */
  BasImage.prototype.setInitialCss = function () {

    var length, i

    this.css = {}
    this._syncCss()

    length = this._initialCustomCss.length
    for (i = 0; i < length; i++) {

      this.css[this._initialCustomCss[i]] = true
    }
  }

  /**
   * Set custom CSS class(es)
   *
   * @param {Object<string, boolean>} override
   */
  BasImage.prototype.overrideCss = function overrideCss (
    override
  ) {
    BasUtil.mergeObjects(this.css, override)
  }

  /**
   * Syncs the private CSS classes
   *
   * @private
   */
  BasImage.prototype._syncCss = function _syncCss () {

    this.css[CSS_BG] = BasUtil.isNEString(this.url)
    this.css[CSS_HTML] = !!this.html
  }

  return BasImage
}
