'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasUiCore', [
    'ICONS',
    'BAS_IMAGE',
    'BasAppDevice',
    'BasDiscoveredCore',
    'BasImage',
    'BasImageTrans',
    'BasLiveProject',
    basUiCoreFactory
  ])

/**
 * @param {ICONS} ICONS
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param {BasAppDevice} BasAppDevice
 * @param BasDiscoveredCore
 * @param BasImage
 * @param BasImageTrans
 * @param BasLiveProject
 * @returns BasUiCore
 */
function basUiCoreFactory (
  ICONS,
  BAS_IMAGE,
  BasAppDevice,
  BasDiscoveredCore,
  BasImage,
  BasImageTrans,
  BasLiveProject
) {
  var CSS_IS_REMOTE = 'discovered-core-is-remote'
  var CSS_IS_ONLINE = 'discovered-core-is-online'
  var CSS_INTEGRATOR_ACCESS_GRANTED =
    'discovered-core--integrator-access-granted'
  var CSS_IS_DELETABLE = 'discovered-core-is-deletable'
  var CSS_HAS_EXTRA_INFO = 'discovered-core-has-extra-info'
  var CSS_HAS_EXTRA_LOCATION = 'discovered-core-has-location'
  var CSS_SHOW_EXTRA_INFO = 'discovered-core-show-extra-info'
  var CSS_IS_SELECTED = 'discovered-core-is-selected'

  var EXTRA_INFO_TIMEOUT = 3000

  var biSvgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_COLOR_MUTED,
      BAS_IMAGE.C_SIZE_50
    ]
  }

  var biImgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_COVER
    ]
  }

  var defaultImage = new BasImage(ICONS.connectServer, biSvgOpts)

  /**
   * @constructor
   * @param {(
   * BasLiveProject |
   * TBasStoredServer |
   * BasDiscoveredCore
   * )} serverInfo
   */
  function BasUiCore (serverInfo) {

    /**
     * Used as identifier
     *
     * @type {string}
     */
    this.id = ''

    /**
     * @type {string}
     */
    this.uiName = ''

    /**
     * @type {string}
     */
    this.uiSubtitle = ''

    /**
     * @type {string}
     */
    this.uiAddress = ''

    /**
     * @type {string}
     */
    this.uiMac = ''

    /**
     * @type {?BasLiveProject}
     */
    this.liveProject = null

    /**
     * @type {?BasDiscoveredCore}
     */
    this.discoveredCore = null

    /**
     * @type {?TBasStoredServer}
     */
    this.storedServer = null

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this.resetCss()

    this._extraInfoTimeoutId = 0

    /**
     * @type {?Object}
     */
    this.images = null

    this.bitTile = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: defaultImage
    })

    this.setId(serverInfo)

    this.parse(serverInfo)
  }

  /**
   * @param {(
   * BasLiveProject |
   * TBasStoredServer |
   * BasDiscoveredCore
   * )} serverInfo
   */
  BasUiCore.prototype.parse = function (serverInfo) {

    if (serverInfo instanceof BasDiscoveredCore) {

      this.discoveredCore = serverInfo

    } else if (serverInfo instanceof BasLiveProject) {

      this.liveProject = serverInfo

    } else if (serverInfo.address) {

      this.storedServer = serverInfo
    }

    this.syncUi()
  }

  /**
   * @returns {(
   * BasLiveProject |
   * TBasStoredServer |
   * BasDiscoveredCore |
   * null
   * )}
   */
  BasUiCore.prototype.getBasCoreConnectionCore = function () {

    if (BasAppDevice.isLiveOnlyOrWebRTC() && this.liveProject) {

      return this.liveProject
    }

    if (this.storedServer &&
      BasUtil.isValidHostname(this.storedServer.address)) {

      return this.storedServer

    } else if (this.discoveredCore) {

      return this.discoveredCore

    } else if (this.liveProject) {

      return this.liveProject
    }

    return null
  }

  /**
   * Returns false if this is not the correct uicore of if uicore is left
   * empty after removal
   *
   * @param {string} id
   * @returns boolean
   */
  BasUiCore.prototype.remove = function (id) {

    if (!this.is(id)) return false

    if (this.discoveredCore &&
      this.discoveredCore.id === id) {

      this.discoveredCore = null

    } else if (this.liveProject &&
      this.liveProject.uuid === id) {

      this.liveProject = null

    } else if (this.storedServer &&
      (
        this.storedServer.name +
        this.storedServer.address === id
      )) {

      this.storedServer = null
    }

    this.syncUi()

    return !!(this.discoveredCore || this.liveProject || this.storedServer)
  }

  /**
   * @param {(
   * BasLiveProject |
   * TBasStoredServer |
   * BasDiscoveredCore |
   * string
   * )} serverInfo
   * @returns {boolean}
   */
  BasUiCore.prototype.is = function (serverInfo) {

    if (BasUtil.isNEString(serverInfo)) {

      if (this.discoveredCore) {

        if (this.discoveredCore.id === serverInfo) return true
      }

      if (this.liveProject) {

        if (this.liveProject.uuid === serverInfo) return true
      }

      if (this.storedServer) {

        if (this.storedServer.name +
          this.storedServer.address === serverInfo) {

          return true
        }
      }

    } else if (serverInfo instanceof BasDiscoveredCore) {

      if (this.discoveredCore) {

        if (this.discoveredCore.cid === serverInfo.cid) return true
      }

      if (this.liveProject) {

        if (this.liveProject.uuid === serverInfo.cid) return true
      }

    } else if (serverInfo instanceof BasLiveProject) {

      if (this.liveProject) {

        if (this.liveProject.uuid === serverInfo.uuid) return true
      }

      if (this.discoveredCore) {

        if (this.discoveredCore.cid === serverInfo.uuid) return true
      }

    } else if (serverInfo.address) {

      if (this.storedServer) {

        if (this.storedServer.name === serverInfo.name &&
          this.storedServer.address === serverInfo.address) {

          return true
        }
      }
    }

    return false
  }

  BasUiCore.prototype.syncUi = function () {

    this._clearUi()

    if (this.storedServer) {

      this.uiName = this.storedServer.name
      // UI is cleaner with IP address as subtitle
      // this.uiAddress = this.storedServer.address
      this.uiSubtitle = this.storedServer.address

    } else {

      if (this.discoveredCore) {

        this.uiName = this.discoveredCore.uiName
        this.uiSubtitle = this.discoveredCore.city
        this.cid = this.discoveredCore.cid
        this.uiAddress = this.discoveredCore.addresses[0]
        this.uiMac = this.discoveredCore.mac
        this.images = BasUtil.copyObject(this.discoveredCore.images)

      } else if (this.liveProject) {

        this.uiName = this.liveProject.uiName
        this.uiSubtitle = this.liveProject.city
        this.cid = this.liveProject.uuid
        this.images = BasUtil.copyObject(this.liveProject.images)
      }
    }

    this.css[CSS_HAS_EXTRA_INFO] = (
      BasUtil.isNEString(this.uiAddress) ||
      BasUtil.isNEString(this.uiMac)
    )

    this.css[CSS_HAS_EXTRA_LOCATION] = BasUtil.isNEString(this.uiSubtitle)

    this.css[CSS_IS_REMOTE] = !!this.liveProject

    this.css[CSS_IS_ONLINE] = this.liveProject
      ? this.liveProject.online
      : false

    this.css[CSS_INTEGRATOR_ACCESS_GRANTED] = this.liveProject
      ? this.liveProject.integratorAccess
      : false

    this.css[CSS_SHOW_EXTRA_INFO] = !!this.storedServer

    this.css[CSS_IS_DELETABLE] = !!this.storedServer

    this.syncImages()
  }

  BasUiCore.prototype.syncImages = function () {

    var _basImage

    if (this.images) {

      // Set tile image
      _basImage = new BasImage('', biImgOpts)
      _basImage.setMultipleUrls(this.images)
      this.bitTile.setImage(_basImage)
    }
  }

  /**
   * @param {(
   * BasLiveProject |
   * TBasStoredServer |
   * BasDiscoveredCore
   * )} serverInfo
   */
  BasUiCore.prototype.setId = function (serverInfo) {

    if (serverInfo instanceof BasDiscoveredCore) {

      this.id = serverInfo.id

    } else if (serverInfo instanceof BasLiveProject) {

      this.id = serverInfo.uuid

    } else if (serverInfo.address) {

      this.id = serverInfo.name + serverInfo.address
    }
  }

  BasUiCore.prototype.setSelected = function (selected) {

    this.css[CSS_IS_SELECTED] = selected
  }

  BasUiCore.prototype.toggleExtraInfo = function (override) {

    // Stored servers always display extra info
    if (this.storedServer) return

    // Check if there is extra info to display
    if (this.uiAddress || this.uiMac) {

      this._clearExtraInfoTimeout()

      this._extraInfoTimeoutId = setTimeout(
        this.toggleExtraInfo.bind(this, false),
        EXTRA_INFO_TIMEOUT
      )

      this.css[CSS_SHOW_EXTRA_INFO] = BasUtil.isBool(override)
        ? override
        : !this.css[CSS_SHOW_EXTRA_INFO]
    }
  }

  BasUiCore.prototype._clearExtraInfoTimeout = function () {

    clearTimeout(this._extraInfoTimeoutId)
    this._extraInfoTimeoutId = 0
  }

  BasUiCore.prototype._clearUi = function () {

    this.uiName = ''
    this.uiSubtitle = ''
    this.uiAddress = ''
    this.uiMac = ''
    this.images = null

    this.resetCss()
  }

  /**
   * Resets the general class object
   */
  BasUiCore.prototype.resetCss = function () {

    this.css[CSS_HAS_EXTRA_INFO] = false
    this.css[CSS_HAS_EXTRA_LOCATION] = false
    this.css[CSS_SHOW_EXTRA_INFO] = false
    this.css[CSS_IS_REMOTE] = false
    this.css[CSS_IS_ONLINE] = false
    this.css[CSS_INTEGRATOR_ACCESS_GRANTED] = false
    this.css[CSS_IS_DELETABLE] = false
  }

  return BasUiCore
}
