'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasNetworkConfig', [
    '$window',
    '$rootScope',
    'BAS_NETWORK',
    'BAS_CORE_CLIENT',
    'BAS_APP',
    'BAS_ROOMS',
    'BasCoreClient',
    'BasAppDevice',
    'BasServerStorage',
    'CurrentBasCore',
    'BasDeviceNetwork',
    BasNetwork
  ])

/**
 * @typedef {Object} TBasNetworkConfig
 * @property {string} configuration
 * @property {string} [ip]
 * @property {string} [subnetMask]
 * @property {string} [gateway]
 * @property {string} [dns]
 */

/**
 * @constructor
 * @param $window
 * @param $rootScope
 * @param {BAS_NETWORK} BAS_NETWORK
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 * @param {BAS_APP} BAS_APP
 * @param {BAS_APP} BAS_ROOMS
 * @param {BasCoreClient} BasCoreClient
 * @param {BasAppDevice} BasAppDevice
 * @param {BasServerStorage} BasServerStorage
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasDeviceNetwork} BasDeviceNetwork
 */
function BasNetwork (
  $window,
  $rootScope,
  BAS_NETWORK,
  BAS_CORE_CLIENT,
  BAS_APP,
  BAS_ROOMS,
  BasCoreClient,
  BasAppDevice,
  BasServerStorage,
  CurrentBasCore,
  BasDeviceNetwork
) {

  var LISA_NETWORK_ADDRESS_UPDATE_DEBOUNCE_DELAY_MS = 30000
  var LISA_NETWORK_ADDRESS_UPDATE_ON_EXISTING_DEBOUNCE_DELAY_MS = 3000

  var K_ADDRESSES = 'addresses'
  var K_ADDRESS = 'address'

  var _sendAddressTimeoutId = 0
  var _sendAddressOnExistingTimeoutId = 0

  /**
   * @typedef {Object} BasNetworkConfigState
   * @property {?Network} network Cordova plugin instance
   * @property {?Object} config
   * @property {?Object} networkState
   * @property {string} username
   * @property {string} uiSerial
   * @property {Object} css
   * @property {boolean} canMuteAreaOnCall
   */

  /**
   * @type {BasNetworkConfigState}
   */
  var state = {}
  state.config = null
  state.networkState = null

  this.get = get
  this.retrieveNetworkState = retrieveNetworkState
  this.getNetworkState = getNetworkState
  this.setNetworkConfig = setNetworkConfig

  init()

  function init () {

    var network

    if (BasAppDevice.isCoreClient()) {

      network = _getNetwork()

      if (network) {

        network.addListener(_onNetworkMessage)
        network.init()

        // Copy initial network state
        state.networkState = network.networkState

        retrieveNetworkState()
      }

      // Use non debounced function
      // This way an existing socket can be used
      $rootScope.$on(
        BAS_APP.EVT_PAUSE,
        _onPause
      )

      // Use debounced function
      // This way an existing socket can be used
      $rootScope.$on(
        BAS_APP.EVT_RESUME,
        _debouncedSendAddresses
      )

      $rootScope.$on(
        BAS_ROOMS.EVT_ROOMS_UPDATED,
        _onRoomsUpdated
      )
    }
  }

  /**
   * @private
   * @param {Object} _evt
   * @param {string} type
   */
  function _onRoomsUpdated (_evt, type) {

    if (type === BAS_ROOMS.P_ROOMS) {

      _debouncedSendAddresses()
    }
  }

  /**
   * @returns {BasNetworkConfigState}
   */
  function get () {

    return state
  }

  function retrieveNetworkState () {

    var network = _getNetwork()

    if (network) network.getNetworkState()
  }

  /**
   * @returns {?Object}
   */
  function getNetworkState () {

    var network = _getNetwork()

    return network ? network.networkState : null
  }

  /**
   * @param {TBasNetworkConfig} config
   */
  function setNetworkConfig (config) {

    var network, _config, _configuration

    network = _getNetwork()

    if (network) {

      _configuration = config.configuration.toLowerCase()

      _config = {}
      if (_configuration === 'dhcp') {
        _config[network.K_CONFIGURATION] = network.V_DHCP
      } else if (_configuration === 'static') {
        _config[network.K_CONFIGURATION] = network.V_STATIC
      }

      if (_config[network.K_CONFIGURATION]) {

        if (config.ip) _config[network.K_IP] = config.ip
        if (config.subnetMask) {
          _config[network.K_SUBNET_MASK] = config.subnetMask
        }
        if (config.gateway) _config[network.K_GATEWAY] = config.gateway
        if (config.dns) _config[network.K_DNS] = config.dns

        network.setNetworkConfig(_config)
      }
    }
  }

  /**
   * Fired when Network configuration has changed
   *
   * @private
   * @param {?Object} message
   * @param {string} message.type
   * @param {*} [message.data]
   */
  function _onNetworkMessage (message) {

    var network, type, data

    network = _getNetwork()

    if (
      network &&
      BasUtil.isObject(message)
    ) {

      type = message[network.K_TYPE]
      data = message[network.K_DATA]

      switch (type) {

        case network.T_NETWORK_INFO:

          if (BasUtil.isObject(data)) {

            state.networkState = data

            $rootScope.$emit(
              BAS_NETWORK.EVT_NETWORK_STATE_CHANGED,
              state.networkState
            )
          }

          break
      }
    }
  }

  function _onPause () {

    _sendAddresses()
  }

  function _debouncedSendAddresses () {

    var _server

    clearTimeout(_sendAddressTimeoutId)
    clearTimeout(_sendAddressOnExistingTimeoutId)

    _server = CurrentBasCore.getServer()

    if (_server && _server.isCoreConnected()) {

      _sendAddressOnExistingTimeoutId = setTimeout(
        _sendAddressesOnExistingConnection,
        LISA_NETWORK_ADDRESS_UPDATE_ON_EXISTING_DEBOUNCE_DELAY_MS
      )

    } else {

      _sendAddressTimeoutId =
        setTimeout(
          _sendAddresses,
          LISA_NETWORK_ADDRESS_UPDATE_DEBOUNCE_DELAY_MS
        )
    }
  }

  function _sendAddresses () {

    var _server

    clearTimeout(_sendAddressTimeoutId)

    _server = CurrentBasCore.getServer()

    if (_server && _server.isCoreConnected()) {

      _sendAddressesOnExistingConnection()

    } else {

      _sendAddressesOnDedicatedConnection()
    }
  }

  function _sendAddressesOnExistingConnection () {

    var _server

    clearTimeout(_sendAddressOnExistingTimeoutId)

    _server = CurrentBasCore.getServer()

    if (_server && _server.isCoreConnected()) {

      BasCoreClient.getBasCoreClientDevice()
        .then(_sendNetworkAddresses, _ignore)
    }
  }

  /**
   * @private
   * @param {?BasCoreClientDevice} lisaDevice
   */
  function _sendNetworkAddresses (lisaDevice) {

    var addressesArray

    if (
      lisaDevice &&
      state.networkState &&
      state.networkState[K_ADDRESSES]
    ) {

      addressesArray = _getAddressesArray()

      BasDeviceNetwork.updateAddresses(lisaDevice, addressesArray)
    }
  }

  /**
   * Sends lisa addresses via dedicated connection
   *
   * @private
   */
  function _sendAddressesOnDedicatedConnection () {

    var _lastKnownServer, _serverAddress, _macNum, _addresses

    _lastKnownServer = BasServerStorage.getLastServer()
    _macNum = BAS_CORE_CLIENT.STATE.macNum
    _addresses = _getAddressesArray()

    if (
      _lastKnownServer &&
      _lastKnownServer.hasValidAddress() &&
      _macNum &&
      _addresses
    ) {
      _serverAddress = _lastKnownServer.address

      BasDeviceNetwork.sendAddressesToCore(
        _serverAddress,
        _macNum,
        _addresses
      )
    }
  }

  /**
   * Returns an array of ip addresses based on the current network state
   *
   * @returns {string[]}
   */
  function _getAddressesArray () {

    var length, i, address, addresses, addressArray

    addressArray = []

    if (state.networkState) {

      addresses = state.networkState[K_ADDRESSES]

      if (BasUtil.isNEArray(addresses)) {

        length = addresses.length

        for (i = 0; i < length; i++) {

          address = addresses[i]
          addressArray[i] = address[K_ADDRESS]
        }
      }
    }

    return addressArray
  }

  /**
   * Get Network plugin instance
   *
   * @private
   * @returns {?Network}
   */
  function _getNetwork () {

    if (BasUtil.isObject($window['basalteCordova']) &&
      BasUtil.isObject($window['basalteCordova']['network'])) {

      return $window['basalteCordova']['network']
    }

    return null
  }

  function _ignore () {
    // Empty
  }
}
