'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('loginProfilesModalCtrl', [
    '$rootScope',
    '$scope',
    'BAS_SPLASH',
    'basServer',
    'profilesKey',
    'close',
    loginProfilesModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param basServer
 * @param {string} profilesKey
 * @param close
 */
function loginProfilesModalCtrl (
  $rootScope,
  $scope,
  BAS_SPLASH,
  basServer,
  profilesKey,
  close
) {
  var loginProfilesModal = this

  var _listeners = []

  /**
   * @type {BasServer}
   */
  loginProfilesModal.basServer = basServer

  /**
   * @type {string}
   */
  loginProfilesModal.profilesKey = profilesKey

  loginProfilesModal.close = close

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
