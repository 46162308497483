'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basLibraryScroll', BasLibraryScroll)

function BasLibraryScroll () {

  return {
    restrict: 'A',
    bindToController: {
      api: '=basScrollApi'
    },
    controller: [
      '$scope',
      '$element',
      'BasLibraryBody',
      controller
    ],
    controllerAs: 'basBody',
    link: link
  }

  function link (_scope, _element, _attrs, ctrl) {

    ctrl.onLinkReady()
  }

  /**
   * @param $scope
   * @param $element
   * @param BasLibraryBody
   */
  function controller (
    $scope,
    $element,
    BasLibraryBody
  ) {
    /**
     * @type {Object}
     * @property {?BasLibraryScroll} api
     */
    var basBody = this

    var SCROLL_MARGIN = 10

    var timeoutId = null

    var listenerOpts = {
      capture: false,
      passive: true
    }

    var element = $element[0]

    basBody.onLinkReady = onLinkReady

    $scope.$on('$destroy', cleanUp)

    if (element) {

      element.addEventListener(
        'scroll',
        onNativeScroll,
        listenerOpts
      )
    }

    function onLinkReady () {

      basBody.api.control[BasLibraryBody.FUNC_RESET_SCROLL_POS] =
        resetScrollPos
      basBody.api.control[BasLibraryBody.FUNC_SET_SCROLL_POS] =
        setScrollPos
      basBody.api.control[BasLibraryBody.FUNC_GET_SCROLL_PERCENT] =
        getScrollPercentage
      basBody.api.control[BasLibraryBody.FUNC_IS_PAGE_FILLED] =
        isPageFilled
    }

    /**
     * Vanilla JS scroll callback function
     * this = (scrollable) DOM element
     */
    function onNativeScroll () {

      var currentScrollHeight, scrollHeightThreshold

      // Calculate bottom view scroll position
      currentScrollHeight =
        element.scrollTop + element.clientHeight

      // Calculate scroll end threshold
      scrollHeightThreshold =
        element.scrollHeight - SCROLL_MARGIN

      // Check for end reached
      if (currentScrollHeight > scrollHeightThreshold) {

        clearTimeout(timeoutId)
        timeoutId = setTimeout(executeEndReached)
      }
    }

    function executeEndReached () {

      var endReachedPromise

      // Check end is reached function is available
      if (BasUtil.isObject(basBody.api) &&
        typeof basBody.api.endReached === 'function') {

        // Execute end is reached
        endReachedPromise = basBody.api.endReached()

        // Check for valid Promise
        if (endReachedPromise && endReachedPromise.then) {

          endReachedPromise.then(onEndReached)
        }
      }
    }

    function onEndReached (result) {

      if (result) $scope.$applyAsync()
    }

    function resetScrollPos () {

      if (element) element.scrollTop = 0
    }

    /**
     * Returns a boolean to check whether the
     * scrollPos was already the same as the element
     *
     * @param {number} percentage
     * @returns {boolean}
     */
    function setScrollPos (percentage) {

      var top

      if (element) {

        top = Math.round(element.scrollHeight * percentage)

        if (top === element.scrollTop) return true

        element.scrollTop = top
      }

      return false
    }

    function getScrollPercentage () {

      return element
        ? element.scrollTop / element.scrollHeight
        : undefined
    }

    function isPageFilled () {

      var currentScrollHeight, scrollHeightThreshold

      if (element) {

        // Calculate bottom view scroll position
        currentScrollHeight =
          element.scrollTop + element.clientHeight

        // Calculate scroll end threshold
        scrollHeightThreshold =
          element.scrollHeight - SCROLL_MARGIN

        return currentScrollHeight < scrollHeightThreshold
      }

      return false
    }

    function cleanUp () {

      if (element) {

        element.removeEventListener(
          'scroll',
          onNativeScroll,
          listenerOpts
        )
      }

      element = null
    }
  }
}
