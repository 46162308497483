'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basTimeTile', [
    'BAS_HTML',
    basTimeTileDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns BasTimeTile
 */
function basTimeTileDirective (
  BAS_HTML
) {
  return {
    restrict: 'EA',
    template: BAS_HTML.timeTile,
    controller: [
      '$rootScope',
      '$scope',
      'BAS_TIME',
      'BasTime',
      controller
    ],
    controllerAs: 'time'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_TIME} BAS_TIME
   * @param {BasTime} BasTime
   */
  function controller (
    $rootScope,
    $scope,
    BAS_TIME,
    BasTime
  ) {

    var time = this

    var _listeners = []

    this.$onDestroy = _onDestroy

    time.time = BasTime.get()

    init()

    function init () {

      _listeners.push(
        $rootScope.$on(
          BAS_TIME.EVT_TIME_UPDATED,
          _onTimeUpdated
        )
      )
    }

    function _onTimeUpdated () {

      $scope.$applyAsync()
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
