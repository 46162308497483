'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Stream = require('./stream')
var Database = require('./database')
var Queue = require('./queue')
var Deezer = require('./deezer')
var Tidal = require('./tidal')

var log = require('./logger')

/**
 * The class representing a Player.
 * Each Player has a unique name and id
 *
 * @constructor
 * @extends Stream
 * @param {Object} cfg The config for this player
 * @param {number} cfg.id Matches the CobraNet bundle number
 * @param {string} cfg.uuid
 * @param {string} cfg.name The name for this Player
 * @param {number} cfg.seq Sequence number for ordering
 * @param {string} [cfg.colour] The optional colour for this Player
 * @param {BasCore} basCore
 */
function Player (cfg, basCore) {

  Stream.call(this, cfg, basCore)

  // Parse UUID if available
  this._uuid = BasUtil.isNEString(cfg[P.UUID])
    ? cfg[P.UUID]
    : '' + this.id

  this._name = BasUtil.isNEString(cfg[P.NAME])
    ? cfg[P.NAME]
    : ''

  // Check for valid sequence number
  this._seq = BasUtil.isVNumber(cfg[P.SEQ])
    ? cfg[P.SEQ]
    : 0

  this._paused = true
  this._position = 0
  this._duration = 0
  this._consume = false
  this._random = false
  this._single = false
  this._repeatMode = CONSTANTS.REPEAT_OFF
  this._currentSong = null
  this._nextSong = null

  this._dirty = true

  this._favouritesDirty = true
  this._favourites = {
    playlists: [],
    radios: [],
    deezer: []
  }

  this._presetsDirty = true
  this._presets = []

  this._handleStatus = this._onStatus.bind(this)
  this._handleStatusError = this._onStatusError.bind(this)
  this._handleOtherRadios = this._onOtherRadios.bind(this)
  this._handleOtherRadiosError = this._onOtherRadiosError.bind(this)
  this._handleFavourites = this._onFavourites.bind(this)
  this._handleFavouriteAction = this._onFavouriteAction.bind(this)
  this._handlePresets = this._onPresets.bind(this)

  this._db = new Database(this._id, basCore)
  this._queue = new Queue(this._id, basCore)
  this._deezer = new Deezer(this._id, basCore)
  this._tidal = new Tidal(this._id, basCore)

  this._statusPromise = null
  this._otherRadiosPromise = null
}

Player.prototype = Object.create(Stream.prototype)
Player.prototype.constructor = Player

// region Events

/**
 * Track position changed
 *
 * @event Player#EVT_POSITION
 * @param {number} position Number of seconds passed
 */

/**
 * Track duration changed
 *
 * @event Player#EVT_DURATION
 * @param {number} duration Number of seconds in this track
 */

/**
 * Consume state changed
 *
 * @event Player#EVT_CONSUME
 * @param {boolean} state New state for consume
 */

/**
 * Single state changed
 *
 * @event Player#EVT_SINGLE
 * @param {boolean} state New state for single
 */

/**
 * Random state changed
 *
 * @event Player#EVT_RANDOM
 * @param {boolean} state New state for random
 */

/**
 * Repeat state changed
 *
 * @event Player#EVT_REPEAT
 * @param {boolean} state New state for repeat
 */

/**
 * Paused state changed
 *
 * @event Player#EVT_PAUSED
 * @param {boolean} state New state for paused
 */

/**
 * Current song changed
 *
 * @event Player#EVT_CURRENT_SONG
 * @param {?Object} song New song
 * @param {number} song.id
 * @param {number} [song.length] Duration
 * @param {number} [song.pos] Position in the queue
 * @param {number} [song.prio] Priority in the queue (used in random mode)
 * @param {string} [song.file] File location in the database
 * @param {string} [song.coverartUrl] Coverart url
 * @param {string} [song.thumbnailUrl] Thumbnail coverart url
 * @param {string} [song.artist]
 * @param {string} [song.title]
 * @param {string} [song.name] (used for webstreams)
 * @param {string} [song.track]
 * @param {string} [song.date]
 * @param {Object} [song.album]
 * @param {string} [song.album.artist]
 * @param {string} [song.album.name]
 * @param {string} [song.genre]
 * @param {string} [song.composer]
 * @param {string} [song.performer]
 * @param {string} [song.disc]
 * @param {string} [song.comment]
 */

/**
 * Next song changed
 *
 * @event Player#EVT_NEXT_SONG
 * @param {?Object} song
 */

/**
 * Favourites changed
 *
 * @event Player#EVT_FAVOURITES_CHANGED
 * @since 1.0.0
 */

/**
 * Presets changed (KNX presets)
 *
 * @event Player#EVT_PRESETS_CHANGED
 * @since 1.5.0
 */

/**
 * Dirty state has changed
 *
 * @event Player#EVT_DIRTY_CHANGED
 * @param {boolean} dirty
 * @since 2.0.0
 */

// endregion

/**
 * @constant {string}
 */
Player.EVT_POSITION = 'position'

/**
 * @constant {string}
 */
Player.EVT_DURATION = 'duration'

/**
 * @constant {string}
 */
Player.EVT_CONSUME = 'consume'

/**
 * @constant {string}
 */
Player.EVT_SINGLE = 'single'

/**
 * @constant {string}
 */
Player.EVT_RANDOM = 'random'

/**
 * @constant {string}
 */
Player.EVT_REPEAT = 'repeat'

/**
 * @constant {string}
 */
Player.EVT_PAUSED = 'paused'

/**
 * @constant {string}
 */
Player.EVT_CURRENT_SONG = 'currentSong'

/**
 * @constant {string}
 */
Player.EVT_NEXT_SONG = 'nextSong'

/**
 * @constant {string}
 */
Player.EVT_FAVOURITES_CHANGED = 'favouritesChanged'

/**
 * @constant {string}
 */
Player.EVT_PRESETS_CHANGED = 'presetsChanged'

/**
 * @constant {string}
 */
Player.EVT_DIRTY_CHANGED = 'dirtyStateChanged'

/**
 * @constant {string}
 */
Player.ERR_FAVOURITE_EXISTS = 'errFavouriteExists'

/**
 * @constant {string}
 */
Player.ERR_UNKNOWN_RESULT = 'errUnknownResult'

/**
 * @constant {string}
 */
Player.ERR_INVALID_INPUT = 'errInvalidInput'

/**
 * @constant {string}
 */
Player.ERR_INVALID_RESPONSE = 'errInvalidResponse'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_T_LOCAL = 'local'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_T_TUNEIN = 'tunein'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_T_DEEZER = 'deezer'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_T_SPOTIFY = 'spotify'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_T_TIDAL = 'tidal'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_ST_PLAYLIST = 'playlist'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_ST_RADIO = 'radio'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_ST_X_BASALTE = 'x-basalte'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_V_FLOW = 'flow'

/**
 * Used to make favourite uris
 *
 * @constant {string}
 */
Player.FAV_V_PLAY = 'play'

/**
 * Compares players based on sequence number or CobraNET ID.
 *
 * @param {Player} player1
 * @param {Player} player2
 * @returns {number}
 */
Player.compare = function (player1, player2) {

  // Make sure players are objects
  if (BasUtil.isObject(player1) &&
    BasUtil.isObject(player2)) {

    // Check for a sequence number
    if (BasUtil.isVNumber(player1.sequence) &&
      BasUtil.isVNumber(player2.sequence)) {

      // Sort by sequence number
      return player1.sequence - player2.sequence

    } else {

      // Fallback, sort by CobraNET ID
      return player1.id - player2.id
    }

  } else if (BasUtil.isObject(player1)) {

    return -1

  } else if (BasUtil.isObject(player2)) {

    return 1
  }

  return 0
}

/**
 * Player UUID
 *
 * @name Player#uuid
 * @type {string}
 * @readonly
 * @since 1.7.3
 */
Object.defineProperty(Player.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * Whether the player has been initialized
 *
 * @name Player#dirty
 * @type {boolean}
 * @readonly
 * @since 1.7.0
 */
Object.defineProperty(Player.prototype, 'dirty', {
  get: function () {
    return this._dirty
  }
})

/**
 * The name of the current Player.
 *
 * @name Player#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(Player.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * Sequence number of the player.
 * This is used to preserve the sorting of Players
 *
 * @name Player#sequence
 * @type {number}
 * @readonly
 * @since 1.5.0
 */
Object.defineProperty(Player.prototype, 'sequence', {
  get: function () {
    return this._seq
  }
})

/**
 * The database for this Player.
 *
 * @name Player#database
 * @type {Database}
 * @readonly
 */
Object.defineProperty(Player.prototype, 'database', {
  get: function () {
    return this._db
  }
})

/**
 * The queue for this Player.
 *
 * @name Player#queue
 * @type {Queue}
 * @readonly
 */
Object.defineProperty(Player.prototype, 'queue', {
  get: function () {
    return this._queue
  }
})

/**
 * Deezer handler for this Player.
 *
 * @name Player#deezer
 * @type {Deezer}
 * @readonly
 * @since 1.1.0
 */
Object.defineProperty(Player.prototype, 'deezer', {
  get: function () {
    return this._deezer
  }
})

/**
 * TIDAL handler for this player.
 *
 * @name Player#tidal
 * @type {Tidal}
 * @readonly
 * @since 1.9.0
 */
Object.defineProperty(Player.prototype, 'tidal', {
  get: function () {
    return this._tidal
  }
})

/**
 * Status of the player. Playing or paused
 *
 * @name Player#paused
 * @type {boolean}
 */
Object.defineProperty(Player.prototype, 'paused', {
  get: function () {
    return this._paused
  },
  set: function (p) {

    var data = this._getBasCoreMessage()
    data[P.PLAYER][P.STATE] = p === true
      ? P.PAUSE
      : P.PLAY

    this._basCore.send(data)
  }
})

/**
 * The position in seconds of the current track.
 *
 * @name Player#position
 * @type {number}
 */
Object.defineProperty(Player.prototype, 'position', {
  get: function () {
    return this._position
  },
  set: function (p) {

    var data

    if (BasUtil.isPNumber(p, true)) {

      data = this._getBasCoreMessage()
      data[P.PLAYER][P.ELAPSED] = p

      this._basCore.send(data)
    }
  }
})

/**
 * The total duration of the current track in seconds.
 *
 * @name Player#duration
 * @type {number}
 * @readonly
 */
Object.defineProperty(Player.prototype, 'duration', {
  get: function () {
    return this._duration
  }
})

/**
 * The random state of the player. Enable this to shuffle playback
 *
 * @name Player#random
 * @type {boolean}
 */
Object.defineProperty(Player.prototype, 'random', {
  get: function () {
    return this._random
  },
  set: function (r) {

    var data = this._getBasCoreMessage()
    data[P.PLAYER][P.RANDOM] = r === true

    this._basCore.send(data)
  }
})

/**
 * The repeat state of the player. Enable this to repeat playback
 *
 * @name Player#repeatMode
 * @type {number}
 * @since 2.1.0
 */
Object.defineProperty(Player.prototype, 'repeatMode', {
  get: function () {
    return this._repeatMode
  },
  set: function (r) {

    var data = this._getBasCoreMessage()
    data[P.PLAYER][P.REPEAT] = r !== CONSTANTS.REPEAT_OFF

    this._basCore.send(data)
  }
})

/**
 * The single state of the player. Enable this to stop playback after each track
 *
 * @name Player#single
 * @type {boolean}
 */
Object.defineProperty(Player.prototype, 'single', {
  get: function () {
    return this._single
  },
  set: function (s) {

    var data = this._getBasCoreMessage()
    data[P.PLAYER][P.SINGLE] = s === true

    this._basCore.send(data)
  }
})

/**
 * The consume state of the player. Enable this to remove played songs from the
 * queue
 *
 * @name Player#consume
 * @type {boolean}
 */
Object.defineProperty(Player.prototype, 'consume', {
  get: function () {
    return this._consume
  },
  set: function (c) {

    var data = this._getBasCoreMessage()
    data[P.PLAYER][P.CONSUME] = c === true

    this._basCore.send(data)
  }
})

/**
 * The current song object.
 *
 * @name Player#currentSong
 * @type {?Object}
 * @readonly
 */
Object.defineProperty(Player.prototype, 'currentSong', {
  get: function () {
    return this._currentSong
  }
})

/**
 * The next song object.
 *
 * @name Player#nextSong
 * @type {?Object}
 * @readonly
 */
Object.defineProperty(Player.prototype, 'nextSong', {
  get: function () {
    return this._nextSong
  }
})

/**
 * KNX presets dirty state
 *
 * @name Player#presetsDirty
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Player.prototype, 'presetsDirty', {
  get: function () {
    return this._presetsDirty
  }
})

/**
 * KNX presets
 *
 * @name Player#presets
 * @type {Object[]}
 * @readonly
 */
Object.defineProperty(Player.prototype, 'presets', {
  get: function () {
    return this._presets
  }
})

/**
 * Checks whether a basCore message is a valid player message
 *
 * @param {?Object} message
 * @returns {boolean}
 */
Player.prototype.isValidPlayerMessage = function (message) {
  return (
    BasUtil.isObject(message) &&
    BasUtil.isObject(message[P.PLAYER]) &&
    message[P.PLAYER][P.ID] === this.id
  )
}

/**
 * @returns {Object}
 */
Player.prototype._getBasCoreMessage = function () {

  var data

  data = {}
  data[P.PLAYER] = {}
  data[P.PLAYER][P.ID] = this.id

  return data
}

/**
 * @private
 */
Player.prototype._clearStatusPromise = function () {

  this._statusPromise = null
}

/**
 * Request the basCore to send the current status of the player.
 * This can result in several of the events.
 *
 * @returns {Promise}
 */
Player.prototype.status = function () {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)
  if (this._statusPromise) return this._statusPromise

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.ACTION] = P.STATUS

  return (
    this._statusPromise = this._basCore.requestRetry(data)
      .then(this._handleStatus, this._handleStatusError)
  )
}

/**
 * @private
 * @param {?Object} result
 * @returns {(Player|Promise)}
 */
Player.prototype._onStatus = function (result) {

  this._clearStatusPromise()

  if (this.isValidPlayerMessage(result)) {

    this._dirty = false
    this.parse(result)

    this.emit(Player.EVT_DIRTY_CHANGED, this._dirty)

    return this
  }

  return Promise.reject(Player.ERR_INVALID_RESPONSE)
}

/**
 * @private
 * @param {*} error
 * @returns {Promise}
 */
Player.prototype._onStatusError = function (error) {

  this._clearStatusPromise()

  return Promise.reject(error)
}

/**
 * @private
 */
Player.prototype._clearOtherRadiosPromise = function () {

  this._otherRadiosPromise = null
}

/**
 * @returns {Promise<?Object>}
 * @since 3.0.0
 */
Player.prototype.otherRadios = function () {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)
  if (this._otherRadiosPromise) return this._otherRadiosPromise

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.ACTION] = P.TUNEIN_OTHER_RADIOS

  return (
    this._otherRadiosPromise = this._basCore.requestRetry(
      data,
      CONSTANTS.RETRY_OPTS_ONE_SHOT
    )
      .then(this._handleOtherRadios, this._handleOtherRadiosError)
  )
}

/**
 * @private
 * @param {?Object} result
 * @returns {?Object}
 */
Player.prototype._onOtherRadios = function (result) {

  this._clearOtherRadiosPromise()

  return result
}

/**
 * @private
 * @param {*} error
 * @returns {Promise}
 */
Player.prototype._onOtherRadiosError = function (error) {

  this._clearOtherRadiosPromise()

  return Promise.reject(error)
}

/**
 * Get the player favourites
 *
 * @returns {Promise}
 * @since 1.0.0
 */
Player.prototype.getFavourites = function () {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  // Check if favourites need to be retrieved
  if (this._favouritesDirty) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.FAVOURITE] = {}
    data[P.PLAYER][P.FAVOURITE][P.ACTION] = P.LIST

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleFavourites)
  }

  log.debug('Player (' + this.id + ') favourites returned from cache')

  return Promise.resolve(this._favourites)
}

/**
 * @private
 * @param {?Object} result
 * @returns {(Object | Promise)}
 */
Player.prototype._onFavourites = function (result) {

  if (this.isValidPlayerMessage(result) &&
    BasUtil.isObject(result[P.PLAYER][P.FAVOURITE])) {

    this._favourites = result[P.PLAYER][P.FAVOURITE]
    this._favouritesDirty = false

    return this._favourites
  }

  return Promise.reject(Player.ERR_INVALID_RESPONSE)
}

/**
 * Add a new playlist favourite.
 * Promise will reject if name exists already.
 *
 * @param {string} playlist The player playlist id
 * @returns {Promise}
 * @since 1.0.0
 */
Player.prototype.addFavouritePlaylist = function (playlist) {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (BasUtil.isNEString(playlist)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.FAVOURITE] = {}
    data[P.PLAYER][P.FAVOURITE][P.ACTION] =
      P.ADD_PLAYLIST
    data[P.PLAYER][P.FAVOURITE][P.NAME] = playlist
    data[P.PLAYER][P.FAVOURITE][P.PLAYLIST] = playlist

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleFavouriteAction)
  }

  return Promise.reject(Player.ERR_INVALID_INPUT)
}

/**
 * Add a new radio station favourite
 *
 * @param {string} gid The guide id of the TuneIn radio station
 * @returns {Promise}
 * @since 1.0.0
 */
Player.prototype.addFavouriteRadioStation = function (gid) {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (BasUtil.isNEString(gid)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.FAVOURITE] = {}
    data[P.PLAYER][P.FAVOURITE][P.ACTION] =
      P.ADD_RADIO_STATION
    data[P.PLAYER][P.FAVOURITE][P.NAME] = gid
    data[P.PLAYER][P.FAVOURITE][P.GID] = gid

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleFavouriteAction)
  }

  return Promise.reject(Player.ERR_INVALID_INPUT)
}

/**
 * Add a new deezer item as favourite
 *
 * @param {string} deezerId (example:"radio:5" or "playlist:10")
 * @returns {Promise}
 * @since 1.5.0
 */
Player.prototype.addFavouriteDeezer = function (deezerId) {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (BasUtil.isNEString(deezerId)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.FAVOURITE] = {}
    data[P.PLAYER][P.FAVOURITE][P.ACTION] =
      P.ADD_DEEZER
    data[P.PLAYER][P.FAVOURITE][P.DEEZER_ID] = deezerId

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleFavouriteAction)
  }

  return Promise.reject(Player.ERR_INVALID_INPUT)
}

/**
 * Add a new Tidal item as favourite
 *
 * @param {string} tidalId (example:"playlist:c38eacd8-17a4...")
 * @returns {Promise}
 * @since 1.9.0
 */
Player.prototype.addFavouriteTidal = function (tidalId) {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (BasUtil.isNEString(tidalId)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.FAVOURITE] = {}
    data[P.PLAYER][P.FAVOURITE][P.ACTION] =
      P.ADD_TIDAL
    data[P.PLAYER][P.FAVOURITE][P.TIDAL_ID] = tidalId

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleFavouriteAction)
  }

  return Promise.reject(Player.ERR_INVALID_INPUT)
}

/**
 * Favourite action handler
 *
 * @private
 * @param {?Object} result
 * @returns {(Promise|boolean)}
 */
Player.prototype._onFavouriteAction = function (result) {

  if (this.isValidPlayerMessage(result) &&
    P.RESULT in result[P.PLAYER]) {

    switch (result[P.PLAYER][P.RESULT]) {
      case P.OK:

        return true

      case P.FAVOURITE_EXISTS:

        return Promise.reject(Player.ERR_FAVOURITE_EXISTS)

      default:

        return Promise.reject(Player.ERR_UNKNOWN_RESULT)
    }
  }

  return Promise.reject(Player.ERR_INVALID_RESPONSE)
}

/**
 * Remove a favourite
 *
 * @param {string} name The friendly name of the favourite
 * @returns {Promise}
 * @since 0.1.0
 */
Player.prototype.removeFavourite = function (name) {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (BasUtil.isNEString(name)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.FAVOURITE] = {}
    data[P.PLAYER][P.FAVOURITE][P.ACTION] =
      P.REMOVE
    data[P.PLAYER][P.FAVOURITE][P.NAME] = name

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleFavouriteAction)
  }

  return Promise.reject(Player.ERR_INVALID_INPUT)
}

/**
 * Get the player presets
 *
 * @returns {Promise}
 * @since 1.5.0
 */
Player.prototype.getPresets = function () {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  // Check if presets need to be retrieved
  if (this._presetsDirty) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.PRESET] = {}
    data[P.PLAYER][P.PRESET][P.ACTION] = P.LIST

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handlePresets)
  }

  log.debug('Player (' + this.id + ') presets returned from cache')

  return Promise.resolve(this._presets)
}

/**
 * @private
 * @param {?Object} result
 * @returns {(Array|Promise)}
 */
Player.prototype._onPresets = function (result) {

  if (this.isValidPlayerMessage(result) &&
    Array.isArray(result[P.PLAYER][P.PRESET])) {

    this._presets = result[P.PLAYER][P.PRESET]
    this._presetsDirty = false

    return this._presets
  }

  return Promise.reject(Player.ERR_INVALID_RESPONSE)
}

/**
 * Link new content to a KNX preset
 *
 * @param {number} id The id of the preset
 * @param {Object} preset The preset info
 * @param {string} preset.type The preset type
 * @param {string} [preset.subtype] The optional preset subtype
 * @param {string} [preset.value] The optional preset value
 * @returns {Promise}
 * @since 1.5.0
 */
Player.prototype.linkPreset = function (id, preset) {

  var data

  if (BasUtil.isObject(preset) &&
    BasUtil.isNEString(preset.type)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.PRESET] = {}
    data[P.PLAYER][P.PRESET][P.ACTION] = P.LINK
    data[P.PLAYER][P.PRESET][P.ID] = id
    data[P.PLAYER][P.PRESET][P.TYPE] = preset.type

    if (P.SUBTYPE in preset) {
      data[P.PLAYER][P.PRESET][P.SUBTYPE] =
        preset[P.SUBTYPE]
    }

    if (P.VALUE in preset) {
      data[P.PLAYER][P.PRESET][P.VALUE] =
        preset[P.VALUE]
    }

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleFavouriteAction)
  }

  return Promise.reject(Player.ERR_INVALID_INPUT)
}

/**
 * Parse a message from the connected basCore
 *
 * @param {Object} obj
 */
Player.prototype.parse = function (obj) {

  var p

  if (BasUtil.isObject(obj)) {

    // Error
    if (BasUtil.isObject(obj[P.ERROR])) {

      log.error('error ' + obj[P.ERROR][P.ID] + ': ' +
        obj[P.ERROR][P.MESSAGE])
    }

    if (
      BasUtil.isObject(obj[P.PLAYER]) &&
      this.id === obj[P.PLAYER][P.ID]
    ) {
      // Set player message object reference
      p = obj[P.PLAYER]

      // Elapsed
      if (BasUtil.isPNumber(p[P.ELAPSED]) &&
        p[P.ELAPSED] !== this._position) {

        this._position = p[P.ELAPSED]
        this.emit(Player.EVT_POSITION, this._position)
      }

      // Total
      if (BasUtil.isPNumber(p[P.TOTAL]) &&
        p[P.TOTAL] !== this._duration) {

        this._duration = p[P.TOTAL]
        this.emit(Player.EVT_DURATION, this._duration)
      }

      // Consume
      if (BasUtil.isBool(p[P.CONSUME]) &&
        p[P.CONSUME] !== this._consume) {

        this._consume = p[P.CONSUME]
        this.emit(Player.EVT_CONSUME, this._consume)
      }

      // Single
      if (BasUtil.isBool(p[P.SINGLE]) &&
        p[P.SINGLE] !== this._single) {

        this._single = p[P.SINGLE]
        this.emit(Player.EVT_SINGLE, this._single)
      }

      // Random
      if (BasUtil.isBool(p[P.RANDOM]) &&
        p[P.RANDOM] !== this._random) {

        this._random = p[P.RANDOM]
        this.emit(Player.EVT_RANDOM, this._random)
      }

      // Repeat
      if (BasUtil.isBool(p[P.REPEAT]) &&
        (this._repeatMode !== CONSTANTS.REPEAT_OFF) !==
        p[P.REPEAT]) {

        this._repeatMode = p[P.REPEAT]
          ? CONSTANTS.REPEAT_CURRENT_CONTEXT
          : CONSTANTS.REPEAT_OFF

        this.emit(Player.EVT_REPEAT, this._repeatMode)
      }

      // State
      if (BasUtil.safeHasOwnProperty(p, P.STATE)) {
        switch (p[P.STATE]) {

          case P.PLAY:

            if (this._paused) {
              this._paused = false
              this.emit(Player.EVT_PAUSED, false)
            }

            break

          case P.PAUSE:
          case P.STOP:

            if (!this._paused) {
              this._paused = true
              this.emit(Player.EVT_PAUSED, true)
            }

            break

          default:
            log.warn('Player (' + this.id + ')' +
              ' - Unknown state', p[P.STATE])
        }
      }

      // Current song
      if (BasUtil.safeHasOwnProperty(p, P.SONG)) {

        // Process cover art URLs
        this._currentSong = this._basCore.modifySongCoverArt(p[P.SONG])
        this.emit(Player.EVT_CURRENT_SONG, this._currentSong)
      }

      // Next song
      if (BasUtil.safeHasOwnProperty(p, P.NEXT)) {

        // Process cover art URLs
        this._nextSong = this._basCore.modifySongCoverArt(p[P.NEXT])
        this.emit(Player.EVT_NEXT_SONG, this._nextSong)
      }

      // Database
      if (BasUtil.isObject(p[P.DATABASE])) {

        this._db.parse(p[P.DATABASE])
      }

      // Playlist
      if (BasUtil.isObject(p[P.PLAYLIST])) {

        this._queue.parse(p[P.PLAYLIST])
      }

      // Favourites
      if (BasUtil.isObject(p[P.FAVOURITE]) &&
        p[P.FAVOURITE][P.CHANGED] === true) {

        this._favouritesDirty = true
        this.emit(Player.EVT_FAVOURITES_CHANGED)
      }

      // Presets
      if (BasUtil.isObject(p[P.PRESET]) &&
        p[P.PRESET][P.CHANGED] === true) {

        this._presetsDirty = true
        this.emit(Player.EVT_PRESETS_CHANGED)
      }

      // Deezer
      if (BasUtil.isObject(p[P.DEEZER])) {

        this._deezer.parse(p[P.DEEZER])
      }

      // Tidal
      if (BasUtil.isObject(p[P.TIDAL])) {

        this._tidal.parse(p[P.TIDAL])
      }
    }
  }
}

/**
 * Play the song with the specified id
 *
 * @param {number} id The id of the song
 */
Player.prototype.playId = function (id) {

  var data

  if (BasUtil.isPNumber(id, true)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.SONG] = {}
    data[P.PLAYER][P.SONG][P.ID] = id

    this._basCore.send(data)
  }
}

/**
 * Play the song at the specified position
 *
 * @deprecated
 * @param {number} pos Position of song in queue, range [0,queue.length]
 */
Player.prototype.playPos = function (pos) {

  var data

  if (BasUtil.isPNumber(pos, true) &&
    pos < this._queue.length) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.SONG] = {}
    data[P.PLAYER][P.SONG][P.POS] = pos

    this._basCore.send(data)
  }
}

/**
 * Play the next song in the queue. This will trigger an
 * {@link Player#EVT_CURRENT_SONG} event.
 */
Player.prototype.next = function () {
  this.setState(P.NEXT)
}

/**
 * Play the previous song in the queue. This will trigger an
 * {@link Player#EVT_CURRENT_SONG} event.
 */
Player.prototype.previous = function () {
  this.setState(P.PREVIOUS)
}

/**
 * Toggle play/pause. This will trigger an {@link Player#EVT_PAUSED} event.
 *
 * Always send an override to be compatible with some older server versions
 * (<=2.3.16, not sure about exact versions, should be fixed in 2.4+)
 *
 * @param {boolean} [override] true = play, false = pause
 * @since 2.1.0
 */
Player.prototype.togglePlayPause = function (override) {

  if (BasUtil.isBool(override)) {

    this.setState(override ? P.PLAY : P.PAUSE)

  } else {

    this.setState(P.PLAYPAUSE)
  }
}

/**
 * Stop playback. This could trigger an {@link Player#EVT_PAUSED} event.
 */
Player.prototype.stop = function () {
  this.setState(P.STOP)
}

/**
 * Send a single state command to the basCore
 *
 * @private
 * @param {string} state The new state for the player
 */
Player.prototype.setState = function (state) {

  var data = this._getBasCoreMessage()
  data[P.PLAYER][P.STATE] = state

  this._basCore.send(data)
}

/**
 * Start a radio web stream based on a TuneIn guide id
 *
 * @param {string} gid The guide id of the stream
 * @since 0.1.0
 */
Player.prototype.startTuneInStream = function (gid) {

  var data

  if (BasUtil.isNEString(gid)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.TUNEIN_GID] = gid

    this._basCore.send(data)
  }
}

/**
 * Start a web stream
 *
 * @param {string} url The url of the stream
 * @since 1.1.0
 */
Player.prototype.startStream = function (url) {

  var data

  if (BasUtil.isNEString(url)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.STREAM] = {}
    data[P.PLAYER][P.STREAM][P.URL] = url

    this._basCore.send(data)
  }
}

/**
 * Player destructor
 *
 * @since 1.9.0
 */
Player.prototype.destroy = function () {

  this._clearStatusPromise()
  this._clearOtherRadiosPromise()

  // Clean Database
  if (BasUtil.isObject(this._db)) {

    this._db.destroy()
    this._db = null
  }

  // Clean Queue
  if (BasUtil.isObject(this._queue)) {

    this._queue.destroy()
    this._queue = null
  }

  // Clean Deezer
  if (BasUtil.isObject(this._deezer)) {

    this._deezer.destroy()
    this._deezer = null
  }

  // Clean Tidal
  if (BasUtil.isObject(this._tidal)) {

    this._tidal.destroy()
    this._tidal = null
  }

  this.removeAllListeners()
}

module.exports = Player
