'use strict'

angular
  .module('basalteApp')
  .directive('basMiniPlayer', [
    'BAS_HTML',
    basMiniPlayerDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basMiniPlayer
 */
function basMiniPlayerDirective (
  BAS_HTML
) {
  return {
    restrict: 'A',
    scope: {},
    controller: [
      'BAS_SOURCE',
      'Sources',
      'BasMusicHelper',
      'BasMarqueeSharedState',
      controller
    ],
    controllerAs: 'mini',
    bindToController: {
      basSource: '<?',
      onPlayerClick: '&?'
    },
    template: BAS_HTML.miniPlayer
  }

  /**
   * @param {BAS_SOURCE} BAS_SOURCE
   * @param {Sources} Sources
   * @param {BasMusicHelper} BasMusicHelper
   * @param BasMarqueeSharedState
   */
  function controller (
    BAS_SOURCE,
    Sources,
    BasMusicHelper,
    BasMarqueeSharedState
  ) {
    var mini = this

    /**
     * @type {BasImageTrans}
     */
    mini.musicMainBit = BasMusicHelper.musicMainBit

    /**
     * @type {BasMarqueeSharedState}
     */
    mini.marqueeSharedState = new BasMarqueeSharedState()

    mini.$onChanges = _onChanges
    mini.$onDestroy = _onDestroy

    function _onChanges (changes) {

      var currBasSource, prevBasSource

      if (changes.basSource) {

        currBasSource = changes.basSource.currentValue
        prevBasSource = changes.basSource.previousValue

        if (prevBasSource && prevBasSource.play) {

          Sources.unregisterFor(
            BAS_SOURCE.COL_EVT_PLAYER,
            prevBasSource.getId()
          )
        }

        if (currBasSource && currBasSource.play) {

          Sources.registerFor(BAS_SOURCE.COL_EVT_PLAYER, currBasSource.getId())
        }
      }
    }

    /**
     * @private
     * @returns {?BasSource}
     */
    function _getBasSource () {
      return (mini.basSource && mini.basSource.play) ? mini.basSource : null
    }

    function _onDestroy () {

      var basSource

      basSource = _getBasSource()

      if (basSource) {

        Sources.unregisterFor(BAS_SOURCE.COL_EVT_PLAYER, basSource.getId())
      }
    }
  }
}
