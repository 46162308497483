'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basImageTransition')
  .factory('BasImageTrans', [
    '$timeout',
    'BAS_IMAGE',
    'BasImage',
    basImageTransFactory
  ])

/**
 * @typedef {Object} TBasImageTransTrack
 * @property {BasImageTrans} bit
 * @property {Object} [options]
 * @property {boolean} [options.useDefaultImage]
 * @property {Function} deRegister
 */

/**
 * @typedef {Object} TBasImageTransOptions
 * @property {(string|Object|BasImage)} [defaultImage]
 * @property {TBasImageOptions} [defaultImageOptions]
 * @property {string} [transitionType]
 * @property {number} [debounceMs]
 * @property {number} [debounceMsNull]
 * @property {string[]} [customCss] Custom classes for BasImageTrans
 * @property {Object<string, string>} [imageCss] Custom classes for images
 */

/**
 * @param $timeout
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param BasImage
 * @returns BasImageTrans
 */
function basImageTransFactory (
  $timeout,
  BAS_IMAGE,
  BasImage
) {
  var CSS_VALID_IMAGE = 'bas-image--valid'
  var CSS_INVALID_IMAGE = 'bas-image--invalid'
  var CSS_DEFAULT_IMAGE = 'bas-image--default'
  var CSS_TRANSITION_FADE = 'bas-image-transition--fade'
  var CSS_TRANSITION_FADE_3 = 'bas-image-transition--fade-3'

  /**
   * Class to handle transitioning between images with specific properties
   *
   * @constructor
   * @param {TBasImageTransOptions} [options]
   */
  function BasImageTrans (options) {

    /**
     * Current image
     *
     * @type {?BasImage}
     */
    this.image = null

    /**
     * Image to shown when there is no valid current image
     *
     * @type {?BasImage}
     */
    this.defaultImage = null

    /**
     * Debounce for changing the image
     *
     * @type {number}
     */
    this.debounceMs = 0

    /**
     * Debounce time for clearing the image
     *
     * @type {number}
     */
    this.debounceMsNull = 0

    /**
     * @type {Object<string, boolean>}
     */
    this.customImageStyle = {}

    /**
     * @type {?TBasImageTransTrack}
     * @private
     */
    this._tracker = null

    this._imageChangeHandler = this._onImage.bind(this)
    this._timeout = null

    this.imageErrorHandler = this._onImageError.bind(this)

    /**
     * Image change listeners
     *
     * @type {Array}
     * @private
     */
    this._listeners = []

    /**
     * Image css change listeners
     *
     * @type {Array}
     * @private
     */
    this._cssListeners = []

    /**
     * @type {Object}
     */
    this.css = {}
    this.resetCss()

    if (options) this.parseOptions(options)
  }

  /**
   * @constant {string}
   */
  BasImageTrans.TRANSITION_TYPE_FADE = 'fade'

  /**
   * @constant {string}
   */
  BasImageTrans.TRANSITION_TYPE_FADE_FAST = 'fadeFast'

  /**
   * Set an image, this will transition.
   * The image will only be set if it is not equal to the current image.
   *
   * @param {(string|Object|BasImage)} src
   * @param {TBasImageOptions} [options]
   */
  BasImageTrans.prototype.setImage = function (
    src,
    options
  ) {
    var _newImage

    if (!this._tracker) {

      if (src && (!Array.isArray(src) || BasUtil.isNEArray(src))) {

        _newImage = src instanceof BasImage
          ? src
          : new BasImage(src, options)

      } else {

        _newImage = this.defaultImage ? this.defaultImage : null
      }

      this._setImage(_newImage)
    }
  }

  /**
   * Track another instance of BasImageTrans for image changes
   *
   * @param {BasImageTrans} trans
   * @param {boolean} [useDefImage]
   */
  BasImageTrans.prototype.track = function (
    trans,
    useDefImage
  ) {
    var _this

    _this = this

    this.unTrack()

    if (trans instanceof BasImageTrans) {

      $timeout.cancel(this._timeout)

      this._tracker = {}
      this._tracker.bit = trans
      this._tracker.options = {}
      this._tracker.options.useDefaultImage = useDefImage
      this._tracker.deRegister = function () {
        trans.removeListener(_this._imageChangeHandler)
      }
      trans.setListener(this._imageChangeHandler)
      this._onImage(trans.image)
    }
  }

  /**
   * Stop tracking BasImageTrans image changes
   */
  BasImageTrans.prototype.unTrack = function unTrack () {

    if (this._tracker &&
      BasUtil.isFunction(this._tracker.deRegister)) {

      this._tracker.deRegister()
      this._tracker.deRegister = null
    }

    this._tracker = null
  }

  /**
   * @returns {?BasImageTrans}
   */
  BasImageTrans.prototype.getTrackedBit = function () {

    return (this._tracker && this._tracker.bit && this._tracker.bit.track)
      ? this._tracker.bit
      : null
  }

  /**
   * Change the style of the current image
   *
   * @param {Object<string, boolean>} css
   * @param {boolean} [delay = false]
   */
  BasImageTrans.prototype.changeImageStyle = function (
    css,
    delay
  ) {
    var newImageStyle

    newImageStyle = BasUtil.isObject(css) ? css : {}

    if (!BasUtil.compareObjects(
      this.customImageStyle,
      newImageStyle
    )) {

      this.customImageStyle = newImageStyle

      if (delay === true) {

        this._setImage(this.image)

      } else {

        this._changeImage(this.image)
      }
    }
  }

  /**
   * Image change handler
   *
   * @private
   * @param {?BasImage} img
   */
  BasImageTrans.prototype._onImage = function (img) {

    var image = img

    if (!image &&
      this._tracker &&
      this._tracker.options &&
      this._tracker.options.useDefaultImage) {

      image = this.defaultImage
    }

    this._changeImage(image)
  }

  /**
   * Image error handler
   *
   * @private
   */
  BasImageTrans.prototype._onImageError = function () {

    this._changeImage(this.defaultImage)
  }

  /**
   * @private
   * @param {?BasImage} img
   */
  BasImageTrans.prototype._setImage = function _setImage (img) {

    var debounce = 0

    $timeout.cancel(this._timeout)

    if (img) {

      if (this.debounceMs) debounce = this.debounceMs

    } else {

      if (this.debounceMsNull) debounce = this.debounceMsNull
    }

    if (debounce) {

      this._timeout = $timeout(
        this._changeImage.bind(this, img),
        debounce
      )

    } else {

      this._changeImage(img)
    }
  }

  /**
   * Changing the currently displayed image
   *
   * @private
   * @param {?BasImage} newImage
   */
  BasImageTrans.prototype._changeImage = function (newImage) {

    var _newImage, newImageSet

    newImageSet = false

    if (newImage instanceof BasImage) {

      _newImage = new BasImage(newImage)
      _newImage.setInitialCss()
      _newImage.overrideCss(this.customImageStyle)

    } else {

      _newImage = null
    }

    if ((this.image && !_newImage) ||
      (!this.image && _newImage) ||
      (
        this.image &&
        _newImage &&
        !BasImage.isEqual(
          this.image,
          _newImage,
          {
            checkInitialCSS: true,
            checkCustomCSS: true
          }
        )
      )) {

      this.image = _newImage

      newImageSet = true
    }

    this.checkImage()

    if (newImageSet) this._notifyListeners()
  }

  /**
   * Execute listener callbacks with current image
   *
   * @private
   */
  BasImageTrans.prototype._notifyListeners = function () {

    var i, length

    if (Array.isArray(this._listeners)) {

      length = this._listeners.length
      for (i = 0; i < length; i++) {

        if (BasUtil.isFunction(this._listeners[i])) {

          this._listeners[i](this.image)
        }
      }
    }
  }

  /**
   * Execute css listener callbacks with current css
   *
   * @private
   */
  BasImageTrans.prototype._notifyCssListeners = function () {

    var i, length

    if (Array.isArray(this._cssListeners)) {

      length = this._cssListeners.length
      for (i = 0; i < length; i++) {

        if (BasUtil.isFunction(this._cssListeners[i])) {

          this._cssListeners[i](this.image)
        }
      }
    }
  }

  /**
   * Check image to set correct CSS classes for valid/invalid images
   */
  BasImageTrans.prototype.checkImage = function () {

    this.css[CSS_VALID_IMAGE] = !!this.image
    this.css[CSS_INVALID_IMAGE] = !this.image
    this.css[CSS_DEFAULT_IMAGE] = this.isDefaultImage()

    // TODO: Maybe we should debounce this te minimize DOM manipulation
    this._notifyCssListeners()
  }

  /**
   * Checks whether the current image is the default image.
   * Return false if no default image is set.
   *
   * @returns {boolean}
   */
  BasImageTrans.prototype.isDefaultImage = function () {

    return this.defaultImage
      ? BasImage.isEqual(this.image, this.defaultImage)
      : false
  }

  /**
   * TODO: If this is used externally, 'this._notifyCssListeners()' should be
   * added.
   *
   * @param {TBasImageTransOptions} options
   */
  BasImageTrans.prototype.parseOptions = function (
    options
  ) {
    if (options.transitionType) this.resetCssTransition()

    switch (options.transitionType) {
      case BasImageTrans.TRANSITION_TYPE_FADE:

        this.css[CSS_TRANSITION_FADE] = true
        this.css[CSS_TRANSITION_FADE_3] = false

        break
      case BasImageTrans.TRANSITION_TYPE_FADE_FAST:

        this.css[CSS_TRANSITION_FADE] = true
        this.css[CSS_TRANSITION_FADE_3] = true

        break
    }

    if (options.defaultImage) {

      this.setDefaultImage(
        options.defaultImage,
        options.defaultImageOptions
      )
    }

    if (BasUtil.isPNumber(options.debounceMs)) {

      this.debounceMs = options.debounceMs
    }

    if (BasUtil.isPNumber(options.debounceMsNull)) {

      this.debounceMsNull = options.debounceMsNull
    }

    if (Array.isArray(options.customCss)) {

      this.setCustomCss(options.customCss)
    }
  }

  /**
   * Set custom CSS class(es)
   * TODO: If this is used externally, 'this._notifyCssListeners()' should be
   * added.
   *
   * @param {string[]} custom
   */
  BasImageTrans.prototype.setCustomCss = function (custom) {

    var i, length

    this.resetCustomCss()

    if (Array.isArray(custom)) {

      length = custom.length
      for (i = 0; i < length; i++) {

        if (BasUtil.isNEString(custom[i])) {

          this.css[custom[i]] = true
        }
      }
    }
  }

  /**
   * Set an image listener
   *
   * @param {Function} listener
   */
  BasImageTrans.prototype.setListener = function (listener) {

    if (BasUtil.isFunction(listener) &&
      this._listeners.indexOf(listener) < 0) {

      this._listeners.push(listener)
    }
  }

  /**
   * Set a css listener
   *
   * @param {Function} listener
   */
  BasImageTrans.prototype.setCssListener = function (listener) {

    if (
      BasUtil.isFunction(listener) &&
      this._cssListeners.indexOf(listener) < 0
    ) {

      this._cssListeners.push(listener)
    }
  }

  /**
   * Remove an image listener
   *
   * @param {Function} listener
   */
  BasImageTrans.prototype.removeListener = function (listener) {

    var listeners, length, i

    listeners = this._listeners

    this._listeners = []
    length = listeners.length
    for (i = 0; i < length; i++) {

      if (listeners[i] !== listener) {

        this._listeners.push(listeners[i])
      }
    }
  }

  /**
   * Remove a css listener
   *
   * @param {Function} listener
   */
  BasImageTrans.prototype.removeCssListener = function (listener) {

    var listeners, length, i

    listeners = this._cssListeners

    this._cssListeners = []
    length = listeners.length
    for (i = 0; i < length; i++) {

      if (listeners[i] !== listener) {

        this._cssListeners.push(listeners[i])
      }
    }
  }

  /**
   * Remove all image listeners
   */
  BasImageTrans.prototype.removeAllListeners = function () {

    this._listeners = []
    this._cssListeners = []
  }

  /**
   * Set a default image
   *
   * @param {string|Object|BasImage|null} img
   * @param {TBasImageOptions} [options]
   */
  BasImageTrans.prototype.setDefaultImage = function (
    img,
    options
  ) {
    var isShowingDefault = this.isDefaultImage()

    if (img) {

      this.defaultImage = img instanceof BasImage
        ? img
        : new BasImage(img, options)

      if (!this.image || isShowingDefault) {

        this._changeImage(this.defaultImage)
      }

    } else {

      this.defaultImage = null
      if (isShowingDefault) this._changeImage(null)
    }
  }

  /**
   * Resets all not known custom CSS classes
   * TODO: If this is used externally, 'this._notifyCssListeners()' should be
   * added.
   */
  BasImageTrans.prototype.resetCustomCss = function () {

    var newCss = {}

    newCss[BAS_IMAGE.BC_MAIN] = this.css[BAS_IMAGE.BC_MAIN]

    newCss[CSS_TRANSITION_FADE] =
      this.css[CSS_TRANSITION_FADE]
    newCss[CSS_TRANSITION_FADE_3] =
      this.css[CSS_TRANSITION_FADE_3]

    newCss[CSS_VALID_IMAGE] =
      this.css[CSS_VALID_IMAGE]
    newCss[CSS_INVALID_IMAGE] =
      this.css[CSS_INVALID_IMAGE]
    newCss[CSS_DEFAULT_IMAGE] =
      this.css[CSS_DEFAULT_IMAGE]

    this.css = newCss
  }

  /**
   * Resets the transition classes
   * TODO: If this is used externally, 'this._notifyCssListeners()' should be
   * added.
   */
  BasImageTrans.prototype.resetCssTransition = function () {

    this.css[CSS_TRANSITION_FADE] = false
    this.css[CSS_TRANSITION_FADE_3] = false
  }

  /**
   * Resets CSS classes
   * TODO: If this is used externally, 'this._notifyCssListeners()' should be
   * added.
   */
  BasImageTrans.prototype.resetCss = function () {

    this.css[BAS_IMAGE.BC_MAIN] = true

    this.css[CSS_VALID_IMAGE] = false
    this.css[CSS_INVALID_IMAGE] = false
    this.css[CSS_DEFAULT_IMAGE] = false

    this.resetCssTransition()
  }

  return BasImageTrans
}
