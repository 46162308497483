'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasDeviceSip', [
    '$window',
    'BAS_SIP',
    BasDeviceSip
  ])

/**
 * @typedef {Object} TBasSipDeviceParams
 * @property {number} [playbackGain] -15, 15
 * @property {number} [microphoneGain] -15, 15
 * @property {boolean} [echoCancellation]
 */

/**
 * @constructor
 * @param $window
 * @param {BAS_SIP} BAS_SIP
 */
function BasDeviceSip (
  $window,
  BAS_SIP
) {
  var K_ECHO_CANCELLATION = 'echoCancellation'
  var K_MICROPHONE_GAIN = 'microphoneGain'
  var K_PLAYBACK_GAIN = 'playbackGain'

  /**
   * Keep track of device params to send when SIP config is set
   *
   * @type {TBasSipDeviceParams}
   */
  var deviceParams = {
    playbackGain: 0,
    microphoneGain: 0,
    echoCancellation: false
  }

  this.setDeviceParams = setDeviceParams

  /**
   * Set all device params simultaneously
   *
   * @param {?TBasSipDeviceParams} [params]
   * @param [callback]
   */
  function setDeviceParams (params, callback) {

    var basSip, doUpdate, args

    doUpdate = false

    if (BasUtil.isObject(params)) {

      if (BasUtil.isVNumber(params.playbackGain) &&
        deviceParams.playbackGain !== params.playbackGain) {

        deviceParams.playbackGain = params.playbackGain
        doUpdate = true
      }

      if (BasUtil.isVNumber(params.microphoneGain) &&
        deviceParams.microphoneGain !== params.microphoneGain) {

        deviceParams.microphoneGain = params.microphoneGain
        doUpdate = true
      }

      if (BasUtil.isBool(params.echoCancellation) &&
        deviceParams.echoCancellation !== params.echoCancellation) {

        deviceParams.echoCancellation = params.echoCancellation
        doUpdate = true
      }

    } else {

      // Send current config
      doUpdate = true
    }

    basSip = _getBasSip()

    if (doUpdate && basSip && BAS_SIP.STATE.hasDoorPhone) {

      args = {}
      args[K_PLAYBACK_GAIN] = deviceParams.playbackGain
      args[K_MICROPHONE_GAIN] = deviceParams.microphoneGain
      args[K_ECHO_CANCELLATION] = deviceParams.echoCancellation

      basSip.setDeviceParams(args, callback)

    } else {

      if (BasUtil.isFunction(callback)) callback(null, 'No update')
    }
  }

  /**
   * Get the BasSip plugin instance
   *
   * @private
   * @returns {?BasSip}
   */
  function _getBasSip () {

    if (BasUtil.isObject($window['basalteCordova']) &&
      BasUtil.isObject($window['basalteCordova']['basSip'])) {

      return $window['basalteCordova']['basSip']
    }

    return null
  }
}
