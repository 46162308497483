'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasThermostatSchedulerDay', [
    'BasSchedulerDay',
    'BasThermostatSchedulerPoint',
    basThermostatSchedulerDayFactory
  ])

/**
 * @param BasSchedulerDay
 * @param BasThermostatSchedulerPoint
 * @returns BasThermostatSchedulerDay
 */
function basThermostatSchedulerDayFactory (
  BasSchedulerDay,
  BasThermostatSchedulerPoint
) {
  /**
   * @constructor
   * @extends BasSchedulerDay
   * @param {string} id
   * @param {number} precision
   */
  function BasThermostatSchedulerDay (id, precision) {

    BasSchedulerDay.call(this, id)

    /**
     * @type {number}
     */
    this.precision = precision
  }

  BasThermostatSchedulerDay.prototype =
    Object.create(BasSchedulerDay.prototype)
  BasThermostatSchedulerDay.prototype.constructor = BasThermostatSchedulerDay

  /**
   * @param {SetPoint[]} points
   */
  BasThermostatSchedulerDay.prototype.parse = function (
    points
  ) {
    var length, i, point, time

    this.points = []

    if (Array.isArray(points)) {

      length = points.length
      for (i = 0; i < length; i++) {

        point = points[i]

        if (BasUtil.isObject(point)) {

          time = point.time

          if (BasUtil.isVNumber(time)) {

            this.points.push(
              new BasThermostatSchedulerPoint(point, this.precision)
            )
          }
        }
      }

      this.points.sort(BasThermostatSchedulerPoint.compareX)
    }

    this.generateUiPoints()
  }

  /**
   * @param {BasThermostatSchedulerPoint} point
   * @param {number} x
   * @returns {boolean}
   */
  BasThermostatSchedulerDay.prototype.isXAllowed = function (
    point,
    x
  ) {
    var length, i, _point

    length = this.points.length
    for (i = 0; i < length; i++) {

      _point = this.points[i]

      if (point && _point && _point !== point && _point.x === x) {

        return point.mode !== _point.mode
      }
    }

    return true
  }

  /**
   * @param {BasSchedulerEntry} entry
   * @param {BasSchedulerFilter} [filter]
   */
  BasThermostatSchedulerDay.prototype.setPointsFromEntry = function (
    entry,
    filter
  ) {
    BasSchedulerDay.prototype.setPointsFromEntry.call(this, entry, filter)

    if (entry && filter) {

      this.points.sort(BasThermostatSchedulerPoint.compareX)
      this.generateUiPoints(filter)
    }
  }

  /**
   * @param {BasSchedulerPointType} [pointType]
   * @returns {?BasThermostatSchedulerPoint}
   */
  BasThermostatSchedulerDay.prototype.addNewPoint = function (
    pointType
  ) {
    var i, points, newPoint

    // Iterate over every quarter

    for (i = 0; i < 86400; i += 900) {

      points = this.getPointsWithX(i)

      if (!points.length) {

        newPoint = new BasThermostatSchedulerPoint(undefined, this.precision)
        newPoint.new = true
        newPoint.setXValue(i)
        newPoint.setYValue(20)

        if (pointType) {

          newPoint.setModeFromPointType(pointType)
        }

        this.points.push(newPoint)

        this.points.sort(BasThermostatSchedulerPoint.compareX)
        this.generateUiPoints()

        return newPoint
      }
    }

    return null
  }

  /**
   * @param {string} mode
   * @returns {boolean}
   */
  BasThermostatSchedulerDay.prototype.hasPointsWithMode = function (
    mode
  ) {
    var length, i, point

    length = this.points.length
    for (i = 0; i < length; i++) {

      point = this.points[i]

      if (point && point.mode === mode) return true
    }

    return false
  }

  /**
   * Convert "any" points to equal available mode(s) point(s)
   *
   * @param {string} fromMode
   * @param {string[]} toModes
   */
  BasThermostatSchedulerDay.prototype.migrate = function (
    fromMode,
    toModes
  ) {
    if (toModes.length) {
      this.points = this.points.reduce((acc, point) => {
        // IF migration is possible, migrate
        if (point?.mode === fromMode) {
          acc.push(...toModes.map(mode => {
            const newPoint = point.clone()
            newPoint.setMode(mode)
            return newPoint
          }))
        } else {
          // Keep original point if no migration is possible
          acc.push(point)
        }
        return acc
      }, []).sort(BasThermostatSchedulerPoint.compareX)
      this.generateUiPoints()
    }
  }

  /**
   * @returns {SetPoint[]}
   */
  BasThermostatSchedulerDay.prototype.generateApiDay = function () {

    var result, length, i, point

    result = []

    length = this.points.length
    for (i = 0; i < length; i++) {

      point = this.points[i]

      if (point) result.push(point.getApiSetPoint())
    }

    return result
  }

  BasThermostatSchedulerDay.prototype.updateTemperatureUnit = function () {

    var length, i, point

    length = this.points.length
    for (i = 0; i < length; i++) {

      point = this.points[i]

      if (point) point.updateTemperatureUnit()
    }
  }

  BasThermostatSchedulerDay.prototype.updateTranslation = function () {

    var length, i, point

    BasSchedulerDay.prototype.updateTranslation.call(this)

    length = this.points.length
    for (i = 0; i < length; i++) {

      point = this.points[i]

      if (point) point.updateTranslation()
    }
  }

  return BasThermostatSchedulerDay
}
