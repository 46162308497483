'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourcePlaylists', [
    'BAS_SOURCE',
    'BAS_API',
    'BasPlaylist',
    basSourcePlaylists
  ])

/**
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param BAS_API
 * @param BasPlaylist
 * @returns BasSourcePlaylists
 */
function basSourcePlaylists (
  BAS_SOURCE,
  BAS_API,
  BasPlaylist
) {
  // TODO Change structure, tracks should be part of a BasPlaylist instance
  // TODO Change structure, use BasCollections and map UUIDs to BasPlaylists

  // TODO Optimize requests, only request when dirty,
  // TODO not when a PLAYLIST_CHANGED event listener is active

  var K_PLAYLISTS = 'playlists'
  var K_SHARED_PLAYLISTS = 'sharedPlaylists'
  var K_ITUNES_PLAYLISTS = 'iTunesPlaylists'

  var K_LIST = 'list'
  var K_LOCAL = 'local'
  var K_SHARED = 'shared'
  var K_ITUNES = 'iTunes'

  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourcePlaylists (basSource) {

    /**
     * @type {BasPlaylist[]}
     */
    this.local = []

    /**
     * @type {BasPlaylist[]}
     */
    this.shared = []

    /**
     * @type {BasPlaylist[]}
     */
    this.iTunes = []

    /**
     * @type {BasPlaylist[]}
     */
    this.editable = []

    /**
     * Map that holds tracks for playlist IDs
     *
     * @type {Object<string, Array>}
     */
    this.tracks = {}

    this.handlePlaylistChanged = this._onPlaylistChanged.bind(this)

    this._handleDatabasePlaylists = this._onDatabasePlaylists.bind(this)
    this._handlePlaylists = this._onPlaylists.bind(this)
    this._handlePlaylistDetail = this._onPlaylistDetail.bind(this)

    /**
     * @private
     * @type {BasSource}
     */
    this._basSource = basSource || null

    /**
     * @private
     * @type {?Promise}
     */
    this._currentPlaylistsPromise = null
  }

  /**
   * @param {string} id
   * @returns {?BasPlaylist}
   */
  BasSourcePlaylists.prototype.get = function (id) {

    var result

    if (BasUtil.isNEString(id)) {

      result = BasPlaylist.get(this.local, id)
      if (result) return result

      result = BasPlaylist.get(this.shared, id)
      if (result) return result

      result = BasPlaylist.get(this.iTunes, id)
      if (result) return result
    }

    return null
  }

  /**
   * @returns {Promise}
   */
  BasSourcePlaylists.prototype.retrieve = function () {

    if (
      this._basSource &&
      this._basSource.source &&
      this._basSource.source.database
    ) {

      return this._basSource.source.database.getPlaylists()
        .then(this._handleDatabasePlaylists)

    } else if (
      this._basSource &&
      this._basSource.isAudioSource &&
      this._basSource.type === BAS_SOURCE.T_ASANO
    ) {

      if (this._currentPlaylistsPromise) return this._currentPlaylistsPromise

      this._currentPlaylistsPromise = BasUtil.promiseAll(
        [
          this._basSource.source.listPlaylists('local'),
          this._basSource.source.listPlaylists('shared'),
          this._basSource.source.listPlaylists('iTunes')
        ]
      ).then(onAllPlaylists.bind(this))

      this._currentPlaylistsPromise.then(clearCurrentPlaylistPromise.bind(this))

      return this._currentPlaylistsPromise
    }

    return Promise.reject('Invalid source')

    function onAllPlaylists (results) {

      var arr, i, length

      arr = []

      length = results.length
      for (i = 0; i < length; i++) {

        if (results[i].resolved) {

          if (Array.isArray(results[i].result.list)) {

            arr = arr.concat(results[i].result.list)
          }
        }
      }

      return this._handlePlaylists({ list: arr })
    }

    function clearCurrentPlaylistPromise () {

      this._currentPlaylistsPromise = null
    }
  }

  /**
   * @param {string} id Playlist UUID
   * @param {number} offset
   * @param {number} limit
   * @returns {Promise}
   */
  BasSourcePlaylists.prototype.retrieveTracks = function (
    id,
    offset,
    limit
  ) {

    var _this = this

    if (
      this._basSource &&
      this._basSource.source
    ) {

      if (this._basSource.source.database) {

        return this._basSource.source.database.getPlaylistContent(id)
          .then(_onPlaylistContent)
      } else if (this._basSource.isAudioSource) {

        return this._basSource.source.getPlaylistDetails(id, offset, limit)
          .then(_onAudioSourcePlaylistContent)
      }
    }

    return Promise.reject('Invalid source')

    function _onPlaylistContent (result) {

      _this.tracks[id] = result
    }

    function _onAudioSourcePlaylistContent (result) {

      var i, length

      if (result) {

        if (Array.isArray(result.list)) {

          if (!_this.tracks[id]) {
            _this.tracks[id] = []
          }

          length = result.list.length
          for (i = 0; i < length; i++) {
            _this.tracks[id][i + result.offset] = result.list[i]
          }
        }
      }
    }
  }

  /**
   * @param {string} id Playlist UUID
   * @returns {Promise}
   */
  BasSourcePlaylists.prototype.retrieveDetail = function (id) {

    if (BasUtil.isNEString(id)) {

      if (this._basSource) {

        // Retrieve detail info
        if (this._basSource.hasSourceDatabase()) {

          return this._basSource.source.database.getPlaylistDetail(id)
            .then(this._handlePlaylistDetail)

        } else if (this._basSource.isAudioSource) {

          return this._basSource.source.getPlaylistDetails(id)
            .then(this._handlePlaylistDetail)

        } else {

          return Promise.reject('retrieveDetail - no valid basSource')
        }

      }

      return Promise.reject('retrieveDetail - no basSource')
    }

    return Promise.reject('retrieveDetail - no id' + id)
  }

  /**
   * @private
   * @param {Object} detail
   * @returns {Object}
   */
  BasSourcePlaylists.prototype._onPlaylistDetail = function (detail) {

    if (BasUtil.isObject(detail)) return detail

    return {}
  }

  /**
   * @param {(BasPlaylist|string)} playlist
   * @returns {Promise}
   */
  BasSourcePlaylists.prototype.retrieveCoverArt = function (playlist) {

    var _playlist

    if (
      this._basSource &&
      this._basSource.source &&
      this._basSource.source.database
    ) {

      if (playlist instanceof BasPlaylist) {

        _playlist = playlist

      } else if (BasUtil.isNEString(playlist)) {

        _playlist = this.get(playlist)
      }

      if (_playlist) {

        return this._basSource.source.database.getCoverArt({
          playlist: _playlist.id
        }).then(_playlist.handleCoverArt)
      }
    } else if (this._basSource.isAudioSource) {

      // New source API, already have coverart
      return Promise.resolve()
    }

    return Promise.reject('Invalid source or playlist')
  }

  /**
   * @returns {Promise}
   */
  BasSourcePlaylists.prototype.retrieveCoverArts = function () {

    var _this, all

    _this = this

    if (this._basSource &&
      this._basSource.source &&
      this._basSource.source.database) {

      all = []

      _processArray(this.local)
      _processArray(this.shared)
      _processArray(this.iTunes)

      return Promise.all(all)
    }

    return Promise.reject('Invalid source')

    /**
     * @private
     * @param {BasPlaylist[]} arr
     */
    function _processArray (arr) {

      var i, length, playlist

      length = arr.length
      for (i = 0; i < length; i++) {

        playlist = arr[i]

        all.push(_this._basSource.source.database.getCoverArt({
          playlist: playlist.id
        })).then(playlist.handleCoverArt)
      }
    }
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourcePlaylists.prototype.getTracks = function (id, params) {

    var begin, end
    var _this = this

    if (params) {
      begin = params.offset
      end = params.offset + params.limit
    }

    if (!BasUtil.isNEString(id)) {

      return Promise.reject('Invalid playlist ID ' + id)
    }

    if (id in this.tracks && this.tracks[id].length > end) {

      return Promise.resolve(_getTracks())

    } else {

      return this.retrieveTracks(id, params.offset, params.limit)
        .then(_getTracks)
    }

    function _getTracks () {

      if (begin === end) return _this.tracks[id]

      return _this.tracks[id].slice(begin, end)
    }
  }

  BasSourcePlaylists.prototype._onDatabasePlaylists = function (result) {

    var name

    if (this._basSource) name = this._basSource.name

    this.clear()

    if (BasUtil.isObject(result)) {

      this.local = _processArray(result[K_PLAYLISTS])
      this.shared = _processArray(result[K_SHARED_PLAYLISTS])
      this.iTunes = _processArray(result[K_ITUNES_PLAYLISTS])
    }

    this.editable = this.local.concat(this.iTunes)

    /**
     * @private
     * @param {Object[]} arr
     * @returns {BasPlaylist[]}
     */
    function _processArray (arr) {

      var _result, i, length, playlist

      _result = []

      if (Array.isArray(arr)) {

        length = arr.length
        for (i = 0; i < length; i++) {

          playlist = new BasPlaylist(arr[i])
          playlist.check(name)

          _result.push(playlist)
        }
      }

      return _result
    }
  }

  BasSourcePlaylists.prototype._onPlaylists = function (result) {

    var uuid, _this

    _this = this

    if (this._basSource) uuid = this._basSource.uuid

    this.clear()

    if (BasUtil.isObject(result)) {

      this.local = _processArray(result[K_LIST], K_LOCAL)
      this.shared = _processArray(result[K_LIST], K_SHARED)
      this.iTunes = _processArray(result[K_LIST], K_ITUNES)
    }

    this.editable = this.local.concat(this.iTunes)

    /**
     * @private
     * @param {Object[]} arr
     * @param {string} type
     * @returns {BasPlaylist[]}
     */
    function _processArray (arr, type) {

      var _result, i, length, playlist

      _result = []

      if (Array.isArray(arr)) {

        length = arr.length
        for (i = 0; i < length; i++) {

          if (arr[i].type === type) {
            playlist = new BasPlaylist(arr[i])
            playlist.check(uuid)

            playlist.editable =
              playlist.editable && (
                _this._basSource.source.allowsExecute(
                  BAS_API.AudioSource.C_RENAME,
                  BAS_API.AudioSource.C_PLAYLISTS
                )
              )

            playlist.shareable =
              playlist.shareable && (
                _this._basSource.source.allowsExecute(
                  BAS_API.AudioSource.C_SHARE,
                  BAS_API.AudioSource.C_PLAYLISTS
                )
              )

            playlist.locked = false

            _result.push(playlist)
          }
        }
      }

      return _result
    }
  }

  /**
   * @private
   */
  BasSourcePlaylists.prototype._onPlaylistChanged = function () {

    this.clear()

    this.retrieve()
  }

  BasSourcePlaylists.prototype.clear = function () {

    this.local = []
    this.shared = []
    this.iTunes = []

    this.editable = []

    this.tracks = {}
  }

  BasSourcePlaylists.prototype.destroy = function () {

    this.clear()

    this._basSource = null
    this._currentPlaylistsPromise = null
  }

  return BasSourcePlaylists
}
