'use strict'

angular
  .module('basalteApp')
  .constant('BAS_CURRENT_CORE', {
    EVT_CURRENT_CORE_CHANGED: 'evtCurrentCoreChanged',
    EVT_CURRENT_CORE_PROJECT_INFO: 'evtCurrentCoreProjectInfo',

    EVT_CORE_CONNECTED: 'evtCurrentBasCoreContainerConnected',
    EVT_CORE_CORE_CONNECTED: 'evtCurrentBasCoreContainerCoreConnected',
    EVT_CORE_CORE_V2_CONNECTED:
      'evtCurrentBasCoreContainerSubscriptionCoreConnected',
    EVT_CORE_VERSION: 'evtCurrentBasCoreContainerVersion',
    EVT_CORE_USER_CREATED: 'evtCurrentBasCoreContainerUserCreated',
    EVT_CORE_PROFILE_CREATED: 'evtCurrentBasCoreContainerProfileCreated',
    EVT_CORE_IS_ADMIN: 'evtCurrentBasCoreContainerIsAdmin',
    EVT_CORE_LIVE_INFO: 'evtCurrentBasCoreContainerLiveInfo',
    EVT_CORE_AV_SOURCES_RECEIVED:
      'evtCurrentBasCoreContainerAvSourcesReceived',
    EVT_CORE_MUSIC_RECEIVED: 'evtCurrentBasCoreContainerMusicReceived',
    EVT_CORE_ROOMS_RECEIVED: 'evtCurrentBasCoreContainerRoomsReceived',
    EVT_CORE_DEVICES_UPDATED:
      'evtCurrentBasCoreContainerDevicesUpdated',
    EVT_CORE_CONNECTED_DEVICES_UPDATED:
      'evtCurrentBasCoreContainerConnectedDevicesUpdated',
    EVT_CORE_SERVER_DEVICES_UPDATED:
      'evtCurrentBasCoreContainerServerDevicesUpdated',
    EVT_CORE_SYSTEM: 'evtCurrentBasCoreContainerSystem',
    EVT_CORE_MESSAGES_UPDATED: 'evtCurrentBasCoreContainerMessagesUpdated',
    EVT_CORE_CUSTOM_RADIOS: 'evtCurrentBasCoreContainerCustomRadios',
    EVT_CORE_CAMERAS_ORDER: 'evtCurrentBasCoreContainerCamerasOrder',
    EVT_CORE_OPEN_CLOSE_DEVICES_ORDER:
      'evtCurrentBasCoreContainerOpenCloseDevicesOrder',
    EVT_CORE_SCHEDULES_ORDER: 'evtCurrentBasCoreContainerSchedulesOrder',
    EVT_CORE_TIMERS_ORDER: 'evtCurrentBasCoreContainerTimersOrder',
    EVT_CORE_SCENES_ORDER: 'evtCurrentBasCoreContainerScenesOrder',
    EVT_CORE_SCENES_FAVOURITES:
      'evtCurrentBasCoreContainerScenesFavourites',
    EVT_CORE_LIGHT_GROUP_ORDER: 'evtCurrentBasCoreContainerLightGroupOrder',
    EVT_CORE_LISA_TILES_ORDER: 'evtCurrentBasCoreContainerLisaTilesOrder',
    EVT_CORE_LISA_SHOW_START: 'evtCurrentBasCoreContainerLisaShowStart',
    EVT_CORE_TIDAL_LEGACY_AUTH_DONT_ASK:
      'evtCurrentBasCoreContainerTidalLegacyAuthDontAsk',
    EVT_CORE_MUSIC_LIBRARY_HAS_CONTENT:
      'evtCurrentBasCoreContainerMusicLibraryHasContent',
    EVT_CORE_MUSIC_LIBRARY_SCANNING_CHANGED:
      'evtCurrentBasCoreContainerMusicLibraryScanningChanged',
    EVT_CORE_MUSIC_LIBRARY_CHANGED:
      'evtCurrentBasCoreContainerMusicLibraryChanged',
    EVT_CORE_CONNECTION_JWT_REVOKED:
      'evtCurrentBasCoreContainerForbidden',

    /**
     * @deprecated
     */
    EVT_CORE_HAS_UPDATE: 'evtBasCoreContainerHasUpdate'
  })
