'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('musicSettingsCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'SETTINGS_STATES',
    'BAS_CURRENT_CORE',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_ROOMS',
    'BasState',
    'CurrentBasCore',
    'Sources',
    'BasAppHeader',
    musicSettingsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasState} BasState
 * @param {CurrentBasCore} CurrentBasCore
 * @param {Sources} Sources
 * @param {BasAppHeader} BasAppHeader
 */
function musicSettingsCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  SETTINGS_STATES,
  BAS_CURRENT_CORE,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_ROOMS,
  BasState,
  CurrentBasCore,
  Sources,
  BasAppHeader
) {
  var music = this

  var _listeners = []

  /**
   * @type {STATES}
   */
  music.STATES = STATES

  /**
   * @type {SETTINGS_STATES}
   */
  music.SETTINGS_STATES = SETTINGS_STATES

  /**
   * @type {BAS_ROOMS}
   */
  music.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {BAS_SOURCES}
   */
  music.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {TCurrentBasCoreState}
   */
  music.currentBasCoreState = CurrentBasCore.get()

  music.goToStreamingServices = goToStreamingServices
  music.goToKNXPresets = goToKNXPresets
  music.goToDefaultRooms = goToDefaultRooms

  init()

  function init () {

    $scope.$on('$destroy', onDestroy)

    // Needed to know if there are any knx presets: if yes, the 'KNX Presets'
    //  option should be shown
    Sources.registerFor(BAS_SOURCE.COL_EVT_KNX_PRESETS)

    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _onMusicConfig
    ))
    _listeners.push($rootScope.$on(
      BAS_SOURCES.EVT_SOURCES_UPDATED,
      _onSourcesUpdated
    ))

    syncHeader()
  }

  function _onMusicConfig () {

    checkServerStatus()
  }

  function _onSourcesUpdated () {

    _scopeSync()
  }

  function checkServerStatus () {

    var _basServer

    if (CurrentBasCore.hasCore()) {

      _basServer = music.currentBasCoreState.core.core.server

      if (_basServer) {

        _basServer.getStatus().then(_scopeSync)
      }
    }
  }

  function syncHeader () {

    music.headerTitle = BasAppHeader.determineSettingsHeaderTitle()
    music.headerCanNavigateBack = $uiRouterGlobals.current.name !==
      STATES.SETTINGS_MUSIC
  }

  function goToStreamingServices () {
    BasState.go(STATES.SETTINGS_MUSIC_STREAMING)
  }

  function goToKNXPresets () {
    BasState.go(STATES.SETTINGS_MUSIC_KNX_PRESETS)
  }

  function goToDefaultRooms () {
    BasState.go(STATES.SETTINGS_MUSIC_DEFAULT_ROOMS)
  }

  function _scopeSync () {

    $scope.$applyAsync()
  }

  function onDestroy () {

    Sources.unregisterFor(BAS_SOURCE.COL_EVT_KNX_PRESETS)

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
