'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasContextModal', [
    'ModalService',
    'BAS_HTML',
    'modalHelperService',
    BasModal
  ])

/**
 * @typedef {Object} TBasContextModalItem
 * @property {(string|number)} id Must be unique
 * @property {string} text
 * @property {boolean} [selected]
 * @property {?Object} [icon]
 */

/**
 * @typedef {Object} TBasContextModalConfig
 * @property {TBasContextModalItem[]} options
 * @property {(string|number)} [selectedId]
 * @property {number} [positionType]
 */

/**
 * @constructor
 * @param ModalService
 * @param {BAS_HTML} BAS_HTML
 * @param modalHelperService
 */
function BasModal (
  ModalService,
  BAS_HTML,
  modalHelperService
) {
  this.show = show

  /**
   * @param {*} event
   * @param {TBasContextModalConfig} config
   * @param {?Object} [modalOptions]
   * @returns {Promise}
   */
  function show (event, config, modalOptions) {

    var _modalOptions

    // Base options
    _modalOptions = {
      template: BAS_HTML.basContextModal,
      controller: 'basContextModalCtrl',
      controllerAs: 'modal',
      inputs: {
        event: event,
        basModalConfig: config
      }
    }

    // BasModal options
    if (modalOptions) {

      BasUtil.mergeObjects(
        _modalOptions,
        modalOptions
      )
    }

    return ModalService.showModal(_modalOptions).then(_onModal)
  }

  function _onModal (modal) {

    // Modal is ready, DOM manipulation is allowed
    if (modal.controller &&
      modal.controller.onModalReady &&
      BasUtil.isFunction(modal.controller.onModalReady)) {

      modal.controller.onModalReady()
    }

    modal.closed.then(_onModalClosed)

    return modal
  }

  function _onModalClosed () {

    // Reset modal height
    modalHelperService.resetModalStyle()
  }
}
