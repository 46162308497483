'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('StreamingSettingsState', [
    '$transitions',
    'STATES',
    'CurrentBasCore',
    StreamingSettingsState
  ])

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {CurrentBasCore} CurrentBasCore
 */
function StreamingSettingsState (
  $transitions,
  STATES,
  CurrentBasCore
) {

  init()

  function init () {

    $transitions.onStart(
      {
        to: STATES.SETTINGS_MUSIC_STREAMING + '.**'
      },
      _onState
    )
  }

  function _onState (transition) {

    var targetState, toName

    targetState = transition.targetState()
    toName = targetState.name()

    if (
      BasUtil.startsWith(
        toName,
        STATES.SETTINGS_MUSIC_STREAMING_SPOTIFY_WEB
      )
    ) {

      if (
        CurrentBasCore.hasCore() &&
        CurrentBasCore.hasSpotifyConnect()
      ) {

        // Allowed

      } else {

        return targetState.withState(STATES.SETTINGS_MUSIC_STREAMING)
      }
    }
  }
}
