'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourceDeezer', [
    '$http',
    '$rootScope',
    'Querystringify',
    'BAS_SOURCE',
    'BAS_LIBRARY_ERRORS',
    'BAS_DEEZER',
    'DeezerElement',
    'DeezerCollection',
    'BasUtilities',
    basSourceDeezer
  ])

/**
 * @param $http
 * @param $rootScope
 * @param Querystringify
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {BAS_DEEZER} BAS_DEEZER
 * @param DeezerElement
 * @param DeezerCollection
 * @param {BasUtilities} BasUtilities
 * @returns BasSourceDeezer
 */
function basSourceDeezer (
  $http,
  $rootScope,
  Querystringify,
  BAS_SOURCE,
  BAS_LIBRARY_ERRORS,
  BAS_DEEZER,
  DeezerElement,
  DeezerCollection,
  BasUtilities
) {
  var K_METHOD = 'request_method'
  var K_ALBUM_ID = 'album_id'
  var K_ARTIST_ID = 'artist_id'
  var K_PLAYLIST_ID = 'playlist_id'
  var K_RADIO_ID = 'radio_id'
  var K_TRACK_ID = 'track_id'
  var K_TITLE = 'title'
  var K_SONGS = 'songs'
  var K_ORDER = 'order'

  var K_DATA = 'data'
  var K_NEXT = 'next'
  var K_TOTAL = 'total'

  var K_CREATOR = 'creator'
  var K_ID = 'id'

  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourceDeezer (basSource) {

    /**
     * @type {string}
     */
    this.shortLink = ''

    /**
     * @type {string}
     */
    this.longLink = ''

    this.handleLinkChanged = this._onLinkChanged.bind(this)
    this.handleLinkFinished = this._onLinkFinished.bind(this)
    this.handleLinkError = this._onLinkError.bind(this)

    this.checkLinked = this.isLinked.bind(this)
    this.setUser = this._setUser.bind(this)
    this.resetLinkSpinner = this._resetLinkSpinner.bind(this)
    this.resetLinkSpinner = this._resetLinkSpinner.bind(this)
    this.handleDetailsError = this._onDetailsError.bind(this)
    this._handleLinkPromiseFinished =
      this._onLinkPromiseFinished.bind(this)
    this.setStreamingServiceProperty =
      this._setStreamingServiceProperty.bind(this)

    /**
     * @type {?Promise}
     * @private
     */
    this._linkPromise = null

    /**
     * @private
     * @type {BasSource}
     */
    this._basSource = basSource || null
  }

  // region Requests

  BasSourceDeezer.prototype._request = function (path, params) {

    var config, _params

    config = {}

    _params = params || {}

    _params.access_token = this._basSource.streamingServiceTokens.deezer
      ? this._basSource.streamingServiceTokens.deezer
      : (this._basSource.source && this._basSource.source.deezer)
          ? this._basSource.source.deezer.token
          : ''
    _params.output = 'jsonp'

    config.params = _params
    config.jsonpCallbackParam = 'callback'

    return $http.jsonp(path, config).then(BasUtilities.handleHttpResponse)
  }

  /**
   * Will always return a paged result
   *
   * @param {string} url
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.requestByUrl = function (url) {

    var baseUrl, parts, params

    parts = BasUtilities.parseUrl(url)

    baseUrl = parts.protocol + '//' + parts.host + parts.pathname
    params = Querystringify.parse(parts.search)
    params.callback = null

    return this._request(baseUrl, params).then(onPagedRequest)
  }

  /**
   * @param {string} url
   * @param {Object} params
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.requestAll = function (
    url,
    params
  ) {
    var _this = this

    function promiseRequestAllItemsConstructor (resolve, reject) {

      var deezerTotalRequest

      // Deezer Total Request object
      deezerTotalRequest = {}
      deezerTotalRequest.result = []
      deezerTotalRequest.resultIds = {}

      function processResults () {

        var i, length

        // Iterate all Deezer objects
        length = deezerTotalRequest.result.length
        for (i = 0; i < length; i++) {

          // Check if key is available already
          if (
            !BasUtil.safeHasOwnProperty(
              deezerTotalRequest.resultIds,
              deezerTotalRequest.result[i].id
            )
          ) {

            // Add Deezer ID key with value true
            deezerTotalRequest
              .resultIds[deezerTotalRequest.result[i].id.toString()] = true
          }
        }
      }

      /**
       * @param {Object} pagedResult
       */
      function onDeezerItems (pagedResult) {

        // Check result
        if (BasUtil.isObject(pagedResult) &&
          Array.isArray(pagedResult.data)) {

          // Add new results
          deezerTotalRequest.result = deezerTotalRequest.result
            .concat(pagedResult.data)

          // Check if end is reached
          if (pagedResult.hasNext) {

            // Request next items
            _this.requestByUrl(pagedResult.nextUrl)
              .then(onDeezerItems)
              .catch(reject)
          } else {

            // End is reached, get all Deezer ID's
            processResults()

            // Resolve with Deezer Total request
            resolve(deezerTotalRequest)
          }
        } else {

          Promise.reject(BAS_DEEZER.ERR_INVALID_RESPONSE)
        }
      }

      // Initial request
      _this._request(url, params)
        .then(onPagedRequest)
        .then(onDeezerItems, reject)
    }

    if (this.isLinked()) {

      // Create and return the Promise
      return new Promise(promiseRequestAllItemsConstructor)
    }

    return Promise.reject(BAS_DEEZER.ERR_DEEZER_NOT_LINKED)
  }

  // endregion

  // region Library Data

  /**
   * @param {string} type
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.searchLib = function (
    type,
    params
  ) {
    return this.search(type, params).then(onPagedRequest)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenresLib = function (params) {

    return this.getGenres(params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getAlbumSongsLib = function (
    id,
    params
  ) {
    return this.getAlbumSongs(id, params).then(onPagedRequest)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserPlaylistsLib = function (params) {

    return this.getUserPlaylists(params).then(onPagedRequest)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserRadiosLib = function (params) {

    return this.getUserRadios(params).then(onPagedRequest)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserAlbumsLib = function (params) {

    return this.getUserAlbums(params).then(onPagedRequest)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserArtistsLib = function (params) {

    return this.getUserArtists(params).then(onPagedRequest)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserTracksLib = function (params) {

    return this.getUserTracks(params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenrePlaylistsLib = function (
    id,
    params
  ) {
    return this.getGenrePlaylists(id, params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenreRadiosLib = function (
    id,
    params
  ) {
    return this.getGenreRadios(id, params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenreAlbumsLib = function (
    id,
    params
  ) {
    return this.getGenreAlbums(id, params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenreArtistsLib = function (
    id,
    params
  ) {
    return this.getGenreArtists(id, params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenreTracksLib = function (
    id,
    params
  ) {
    return this.getGenreTracks(id, params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getPlaylistSongsLib = function (
    id,
    params
  ) {
    return this.getPlaylistSongs(id, params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getArtistPlaylistsLib = function (
    id,
    params
  ) {
    return this.getArtistPlaylists(id, params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getArtistAlbumsLib = function (
    id,
    params
  ) {
    return this.getArtistAlbums(id, params).then(onPagedRequest)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getArtistTracksLib = function (
    id,
    params
  ) {
    return this.getArtistTracks(id, params).then(onPagedRequest)
  }

  // endregion

  // region Element Data

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getArtistTracks = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/artist/' + id + '/top'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getArtistAlbums = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/artist/' + id + '/albums'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getArtistPlaylists = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/artist/' + id + '/playlists'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getPlaylistSongs = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/playlist/' + id + '/tracks'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenreTracks = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/chart/' + id + '/tracks'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenreArtists = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/chart/' + id + '/artists'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenreAlbums = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/chart/' + id + '/albums'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenreRadios = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/genre/' + id + '/radios'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenrePlaylists = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/chart/' + id + '/playlists'
    return this._request(url, params)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserTracks = function (params) {

    var url = BAS_DEEZER.BASE_URL + '/user/me/tracks'
    return this._request(url, params)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserArtists = function (params) {

    var url = BAS_DEEZER.BASE_URL + '/user/me/artists'
    return this._request(url, params)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserAlbums = function (params) {

    var url = BAS_DEEZER.BASE_URL + '/user/me/albums'
    return this._request(url, params)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserRadios = function (params) {

    var url = BAS_DEEZER.BASE_URL + '/user/me/radios'
    return this._request(url, params)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getUserPlaylists = function (params) {

    var url = BAS_DEEZER.BASE_URL + '/user/me/playlists'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getAlbumSongs = function (
    id,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/album/' + id + '/tracks'
    return this._request(url, params)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getAlbum = function (id) {

    var url = BAS_DEEZER.BASE_URL + '/album/' + id
    return this._request(url)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getArtist = function (id) {

    var url = BAS_DEEZER.BASE_URL + '/artist/' + id
    return this._request(url)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getPlaylist = function (id) {

    var url = BAS_DEEZER.BASE_URL + '/playlist/' + id
    return this._request(url)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getSongInfo = function (id) {

    var url = BAS_DEEZER.BASE_URL + '/track/' + id
    return this._request(url)
  }

  /**
   * @param {number} id
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getRadio = function (id) {

    var url = BAS_DEEZER.BASE_URL + '/radio/' + id
    return this._request(url)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.getGenres = function (params) {

    var url = BAS_DEEZER.BASE_URL + '/genre/'
    return this._request(url, params)
  }

  /**
   * @param {string} type
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.search = function (
    type,
    params
  ) {
    var url = BAS_DEEZER.BASE_URL + '/search/' + type
    return this._request(url, params)
  }

  /**
   * @param {string} title
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.addPlaylist = function (
    title,
    params
  ) {
    var url, _params

    _params = params || {}
    _params[K_METHOD] = 'POST'
    _params[K_TITLE] = title

    url = BAS_DEEZER.BASE_URL + '/user/me/playlists/'
    return this._request(url, _params)
  }

  /**
   * @param {number} id
   * @param {number[]} songs
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.addSongsToPlaylist = function (
    id,
    songs,
    params
  ) {
    var url, _params

    _params = params || {}
    _params[K_METHOD] = 'POST'
    if (Array.isArray(songs)) _params[K_SONGS] = songs.join(',')

    url = BAS_DEEZER.BASE_URL + '/playlist/' + id + '/tracks/'
    return this._request(url, _params)
  }

  /**
   * @param {number} id
   * @param {number[]} order
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.reorderPlaylistSongs = function (
    id,
    order,
    params
  ) {
    var url, _params

    _params = params || {}
    _params[K_METHOD] = 'POST'
    if (Array.isArray(order)) _params[K_ORDER] = order.join(',')

    url = BAS_DEEZER.BASE_URL + '/playlist/' + id + '/tracks/'
    return this._request(url, _params)
  }

  /**
   * @param {number} id
   * @param {string} title
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.changePlaylistTitle = function (
    id,
    title,
    params
  ) {
    var url, _params

    _params = params || {}
    _params.title = title
    _params[K_METHOD] = 'POST'

    url = BAS_DEEZER.BASE_URL + '/playlist/' + id
    return this._request(url, _params)
  }

  /**
   * @param {number} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.removePlaylist = function (
    id,
    params
  ) {
    var url, _params

    _params = params || {}
    _params[K_METHOD] = 'DELETE'

    url = BAS_DEEZER.BASE_URL + '/playlist/' + id
    return this._request(url, _params)
  }

  /**
   * @param {number} id
   * @param {number[]} songs
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.removePlaylistSongs = function (
    id,
    songs,
    params
  ) {
    var url, _params

    _params = params || {}
    _params[K_METHOD] = 'DELETE'
    if (Array.isArray(songs)) _params[K_SONGS] = songs.join(',')

    url = BAS_DEEZER.BASE_URL + '/playlist/' + id + '/tracks/'
    return this._request(url, _params)
  }

  /**
   * @param {string} searchQuery
   * @param {Object} [searchOptions]
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.pageSearch = function (
    searchQuery,
    searchOptions
  ) {
    var limit = 2

    // Batch search
    var methods, methodsMap, params, options, url

    if (BasUtil.isObject(searchOptions)) {
      options = searchOptions
    } else {
      options = {
        limit: 2,
        track: true,
        artist: true,
        album: true,
        playlist: true,
        radio: true
      }
    }

    /**
     * @param {Object} result
     * @param {Object} [result.error]
     * @param {Array} [result.batch_result]
     * @returns {Promise<(Object|string)>}
     */
    function onBatchSearchResults (result) {
      var batchResult

      // Check result
      if (Array.isArray(result.batch_result)) {

        // Create batch results
        batchResult = {}

        // Assign batch results array
        batchResult.batchResults = result.batch_result

        // Include methods map
        batchResult.resultsMap = methodsMap

        // Resolve with batch results
        return Promise.resolve(batchResult)

      } else {

        return Promise.reject(BAS_DEEZER.ERR_INVALID_RESPONSE)
      }
    }

    if (this.isLinked()) {

      // First request parameters
      params = {
        limit: limit,
        index: 0,
        q: encodeURIComponent(searchQuery)
      }

      // Check for limit
      if (BasUtil.isPNumber(options.limit)) {

        // Set limit
        params.limit = options.limit
      }

      // Create batch array
      methods = []

      // Create mapping
      methodsMap = []

      // Artists
      if (options.artist === true) {

        // Add search artist command
        methods.push({
          request_method: 'GET',
          relative_url: 'search/artist',
          params: params
        })

        // Add mapping
        methodsMap.push(DeezerCollection.CT_ARTIST)
      }

      // Albums
      if (options.album === true) {

        // Add search artist command
        methods.push({
          request_method: 'GET',
          relative_url: 'search/album',
          params: params
        })

        // Add mapping
        methodsMap.push(DeezerCollection.CT_ALBUM)
      }

      // Tracks
      if (options.track === true) {

        // Add search artist command
        methods.push({
          request_method: 'GET',
          relative_url: 'search/track',
          params: params
        })

        // Add mapping
        methodsMap.push(DeezerCollection.CT_TRACK)
      }

      // Playlists
      if (options.playlist === true) {

        // Add search artist command
        methods.push({
          request_method: 'GET',
          relative_url: 'search/playlist',
          params: params
        })

        // Add mapping
        methodsMap.push(DeezerCollection.CT_PLAYLIST)
      }

      // Radios
      if (options.radio === true) {

        // Add search radio command
        methods.push({
          request_method: 'GET',
          relative_url: 'search/radio',
          params: params
        })

        // Add mapping
        methodsMap.push(DeezerCollection.CT_RADIO)
      }

      // Add methods parameter
      // noinspection NodeModulesDependencies
      params.methods = JSON.stringify(methods)

      url = BAS_DEEZER.BASE_URL + '/batch'

      // Return search Promise
      return this._request(url, params).then(onBatchSearchResults)
    }

    return Promise.reject(BAS_DEEZER.ERR_DEEZER_NOT_LINKED)
  }

  // endregion

  // region Deezer Favourite

  /**
   * Add or remove Deezer favourite ID from the Deezer account
   *
   * @param {number|string} deezerId
   * @param {string} deezerType
   * @param {boolean} isFavourite
   * @returns {Promise<(boolean|string)>}
   */
  BasSourceDeezer.prototype.addRemoveDeezerFavouriteId =
    function (deezerId, deezerType, isFavourite) {
      var url
      var params = {}

      // Check player
      if (!this.isLinked()) {
        return Promise.reject('Invalid player')
      }

      // Set request method
      if (isFavourite === true) {

        params[K_METHOD] = 'DELETE'

      } else if (isFavourite === false) {

        params[K_METHOD] = 'POST'

      } else {

        return Promise.reject('Invalid favourite state')
      }

      // Adjust request according to Deezer object type
      switch (deezerType) {
        case DeezerElement.TYPE_ALBUM:

          url = BAS_DEEZER.BASE_URL + '/user/me/albums'
          params[K_ALBUM_ID] = deezerId

          break
        case DeezerElement.TYPE_ARTIST:

          url = BAS_DEEZER.BASE_URL + '/user/me/artists'
          params[K_ARTIST_ID] = deezerId

          break
        case DeezerElement.TYPE_PLAYLIST:

          url = BAS_DEEZER.BASE_URL + '/user/me/playlists'
          params[K_PLAYLIST_ID] = deezerId

          break
        case DeezerElement.TYPE_RADIO:

          url = BAS_DEEZER.BASE_URL + '/user/me/radios'
          params[K_RADIO_ID] = deezerId

          break
        case DeezerElement.TYPE_TRACK:

          url = BAS_DEEZER.BASE_URL + '/user/me/tracks'
          params[K_TRACK_ID] = deezerId

          break
        default:

          return Promise.reject('Unknown type')
      }

      // Perform request
      return this._request(url, params)
    }

  /**
   * Checks whether a specified deezerId is a Deezer favourite.
   *
   * Type is needed to check for
   * tracks, albums, artists, playlist, radios
   *
   * @param {number|string} deezerId
   * @param {string} deezerType
   * @returns {Promise<(boolean|string)>}
   */
  BasSourceDeezer.prototype.checkFavouriteStatus = function (
    deezerId,
    deezerType
  ) {
    var url, params

    // Check input
    if (BasUtil.isVNumber(deezerId) &&
      BasUtil.isNEString(deezerType)) {

      params = {}
      params.limit = BAS_DEEZER.LIMIT_MAX

      // Check type
      switch (deezerType) {
        case DeezerElement.TYPE_ALBUM:

          url = BAS_DEEZER.BASE_URL + '/user/me/albums'
          break

        case DeezerElement.TYPE_ARTIST:

          url = BAS_DEEZER.BASE_URL + '/user/me/artists'
          break

        case DeezerElement.TYPE_PLAYLIST:

          url = BAS_DEEZER.BASE_URL + '/user/me/playlists'
          break

        case DeezerElement.TYPE_RADIO:

          url = BAS_DEEZER.BASE_URL + '/user/me/radios'
          break

        case DeezerElement.TYPE_TRACK:

          url = BAS_DEEZER.BASE_URL + '/user/me/tracks'
          break

        default:

          return Promise.reject('Invalid deezerElement type')
      }

      return this.retrieveFavouriteStatus(
        deezerId,
        url,
        params
      )

    } else {

      return Promise.reject('Invalid input')
    }
  }

  /**
   * @param {number} deezerId
   * @param {string} url
   * @param {Object} params
   * @returns {Promise<(boolean|string)>}
   */
  BasSourceDeezer.prototype.retrieveFavouriteStatus = function (
    deezerId,
    url,
    params
  ) {
    var _this = this

    // Check input
    if (BasUtil.isVNumber(deezerId) && url && params) {

      // Retrieve all items
      return this.requestAll(url, params).then(onDeezerItems)

    } else {

      return Promise.reject('Invalid input')
    }

    function onDeezerItems (result) {

      // Check result
      if (BasUtil.isObject(result) &&
        BasUtil.isObject(result[BAS_DEEZER.KEY_RESULT_IDS])) {

        // Check if item isn't made by the user
        if (BasUtil.isNEArray(result[BAS_DEEZER.KEY_RESULT])) {
          return onId()
        }

        // Resolve with Deezer favourite status for given ID
        return Promise.resolve(
          result[BAS_DEEZER.KEY_RESULT_IDS][deezerId] ===
          true
        )

      } else {

        return Promise.reject('Invalid result')
      }

      function onId () {

        var id = _this.getId()

        if (checkCreator(result[BAS_DEEZER.KEY_RESULT], id)) {

          return Promise.resolve('Playlist created by user')

        } else {

          return Promise.resolve(
            result[BAS_DEEZER.KEY_RESULT_IDS][deezerId] ===
            true
          )
        }
      }
    }

    function checkCreator (array, id) {

      var length, i, item

      if (Array.isArray(array)) {

        item = array[0]

        // Check whether playlist has a creator and id property
        if (!(BasUtil.isObject(item) &&
          BasUtil.isObject(item[K_CREATOR]) &&
          K_ID in item[K_CREATOR])) {

          return false
        }

        // Check if id is same as users
        length = array.length
        for (i = 0; i < length; i++) {

          item = array[i]

          if (BasUtil.isObject(item) &&
            item[K_ID] === deezerId &&
            BasUtil.isObject(item[K_CREATOR]) &&
            item[K_CREATOR][K_ID] === id) {

            return true
          }
        }
      }

      return false
    }
  }

  // endregion

  // region Data

  /**
   * Synchronous check if Deezer is linked or not
   *
   * @returns {boolean}
   */
  BasSourceDeezer.prototype.isLinked = function () {

    var result

    if (this._basSource) {

      if (this._basSource.isAudioSource) {

        result = !!this._basSource.streamingServiceTokens.deezer

      } else {

        result = (
          this.hasDeezer() &&
          this._basSource.source.deezer.isLinked === true
        )
      }

      this._basSource.cssSetDeezerLinked(result)

      return result
    }

    return false
  }

  BasSourceDeezer.prototype._resetLinkSpinner = function () {

    if (this._basSource) this._basSource.cssSetDeezerSpinner(false)
  }

  BasSourceDeezer.prototype._onDetailsError = function (error) {

    this._resetLinkSpinner()
    this.checkLinked()
    this._handleLinkPromiseFinished()

    return Promise.reject('Could not get streaming service details: ' + error)
  }

  /**
   * Checks if Deezer is linked or not.
   * If Deezer state has not been retrieved yet,
   * retrieve status and then perform the check.
   *
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.linked = function () {

    if (this._basSource) {

      if (this._basSource.isAudioSource) {

        this._basSource.cssSetDeezerSpinner(true)

        this._linkPromise = this._basSource.getStreamingServiceDetails('deezer')
          .then(this.resetLinkSpinner)
          .then(this.setUser)
          .then(this.checkLinked)
          .catch(this.handleDetailsError)

        return this._linkPromise

      } else if (this.hasDeezer()) {

        if (this._basSource.source.deezer.dirty) {

          if (this._linkPromise) {
            return this._linkPromise
          }

          this._basSource.cssSetDeezerSpinner(true)

          this._linkPromise = this._basSource.source.deezer.status()
            .then(this.setUser)
            .then(this.resetLinkSpinner)
            .then(this.checkLinked)

          this._linkPromise.then(
            this._handleLinkPromiseFinished,
            this._handleLinkPromiseFinished
          )

          return this._linkPromise

        } else {

          this._setUser()

          return Promise.resolve(this.isLinked())
        }
      }
    }

    this._setUser()
    this.isLinked()

    return Promise.reject(BAS_DEEZER.ERR_INVALID_SOURCE)
  }

  /**
   * Clear the link promise
   *
   * @private
   */
  BasSourceDeezer.prototype._onLinkPromiseFinished = function () {

    this._linkPromise = null
  }

  /**
   * Set custom streaming service property
   *
   * @param key
   * @param value
   * @private
   */
  BasSourceDeezer.prototype._setStreamingServiceProperty = function (
    key,
    value
  ) {

    this[key] = value
  }

  /**
   * @returns {number}
   */
  BasSourceDeezer.prototype.getId = function () {

    return this.isLinked()
      ? this._basSource.isAudioSource
        ? this._basSource.deezer.userId
        : this._basSource.source.deezer.userId
      : -1
  }

  /**
   * @returns {string}
   */
  BasSourceDeezer.prototype.getName = function () {

    return this.isLinked()
      ? this._basSource.source.deezer.username
      : ''
  }

  BasSourceDeezer.prototype._setUser = function () {

    if (this.isLinked()) {

      if (this._basSource.isAudioSource) {

        this.shortLink = this.username
        this.longLink = this.username

      } else if (this._basSource.source.deezer) {

        this.shortLink = this._basSource.source.deezer.username
        this.longLink = this._basSource.source.deezer.username
      }

    } else {

      this.shortLink = BasUtilities.translate('not_linked')
      this.longLink = BasUtilities.translate('deezer_no_link')
    }
  }

  BasSourceDeezer.prototype._onLinkChanged = function () {

    this._setUser()

    if (this._basSource) {

      $rootScope.$emit(
        BAS_SOURCE.EVT_DEEZER_LINK_CHANGED,
        this._basSource.getId()
      )
    }
  }

  BasSourceDeezer.prototype._onLinkFinished = function () {

    // Empty
  }

  BasSourceDeezer.prototype._onLinkError = function () {

    // Empty
  }

  /**
   * Get the value of linkUrl
   *
   * @returns {Promise<string>}
   */
  BasSourceDeezer.prototype.getLinkUrl = function () {

    if (
      this._basSource &&
      BasUtil.isObject(this._basSource.source)
    ) {

      if (this._basSource.isAudioSource) {

        return this._basSource.source.loginStreamingService('deezer')

      } else {

        return this._basSource.source.deezer.linkUrl()
      }
    }

    return Promise.reject('Invalid basSource')
  }

  /**
   * Unlink linked Deezer account
   *
   * @returns {Promise}
   */
  BasSourceDeezer.prototype.unlink = function () {

    if (
      this._basSource &&
      BasUtil.isObject(this._basSource.source)
    ) {

      if (this._basSource.isAudioSource) {

        return this._basSource.source.logoutStreamingService('deezer')

      } else {

        this._basSource.source.deezer.disconnect()

        return Promise.resolve()
      }
    }

    return Promise.reject('Invalid basSource')
  }

  // endregion

  // region Helper

  /**
   * @constructor
   */
  function DeezerResult () {

    this.data = []
    this.dataLength = 0
    this.nextUrl = ''
    this.hasNext = false
    this.total = 0
    this.hasTotal = false
  }

  /**
   * Keeps collecting elements until offset >== amount
   *
   * @param func
   * @param {number} limit Size of a request
   * @param {number} offset Starting position
   * @param {number} amount Put this on -1 to get total
   * @returns {Promise<DeezerResult>}
   */
  BasSourceDeezer.prototype.getXElements = function (
    func,
    limit,
    offset,
    amount
  ) {
    var elements, params, _offset, _amount

    elements = []
    params = {}
    params.limit = limit

    _offset = offset
    _amount = amount

    return this.isLinked()
      ? requestElements()
      : Promise.reject(BAS_LIBRARY_ERRORS.NOT_LINKED)

    function requestElements () {

      params.index = _offset

      return func(params).then(checkResult)

      /**
       * @param {DeezerResult} result
       * @returns {Promise<DeezerResult>}
       */
      function checkResult (result) {

        if (result) {

          elements = elements.concat(result.data)

          _offset += limit

          if (_amount === -1 && result.hasTotal) _amount = result.total

          if (_offset < _amount) return requestElements()
        }

        result.data = elements

        return Promise.resolve(result)
      }
    }
  }

  BasSourceDeezer.prototype.hasDeezer = function () {

    return (this._basSource && this._basSource.hasSourceDeezer())
  }

  // endregion

  BasSourceDeezer.prototype.destroy = function () {

    this._linkPromise = null
    this._basSource = null
  }

  return BasSourceDeezer

  function onPagedRequest (result) {

    // Return object
    var pagedResult = new DeezerResult()

    // Check next URL
    if (BasUtil.isNEString(result[K_NEXT])) {

      pagedResult.hasNext = true
      pagedResult.nextUrl = result[K_NEXT]
    }

    // Check total
    if (BasUtil.isVNumber(result[K_TOTAL])) {

      pagedResult.hasTotal = true
      pagedResult.total = result[K_TOTAL]
    }

    // Set data
    if (Array.isArray(result[K_DATA])) {
      pagedResult.data = result[K_DATA]
      pagedResult.dataLength = result[K_DATA].length
    }

    // Return paged result with extra information
    return Promise.resolve(pagedResult)
  }
}
