'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('MusicState', [
    '$transitions',
    'STATES',
    'BAS_ROOM',
    'CurrentBasCore',
    'CurrentRoom',
    'BasStateHelper',
    'LibraryState',
    'SourcesHelper',
    'UiHelper',
    MusicState
  ])

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {BAS_ROOM} BAS_ROOM
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 * @param {BasStateHelper} BasStateHelper
 * @param {LibraryState} LibraryState
 * @param {SourcesHelper} SourcesHelper
 * @param {UiHelper} UiHelper
 */
function MusicState (
  $transitions,
  STATES,
  BAS_ROOM,
  CurrentBasCore,
  CurrentRoom,
  BasStateHelper,
  LibraryState,
  SourcesHelper,
  UiHelper
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  init()

  function init () {

    $transitions.onStart(
      {
        to: STATES.MUSIC + '.**'
      },
      _onMusicState
    )
  }

  function _onMusicState (transition) {

    var target, toName, options, sourceId, music, basRoomMusic, defaultSource
    var hasSingleSource, basSource, currentBasSource, libraryCurrentState

    target = transition.targetState()
    options = transition.options()
    toName = target.name()

    hasSingleSource = !!(
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.hasSingleSource()
    )

    if (
      BasStateHelper.hasBaseState(toName, STATES.MUSIC_SOURCES)
    ) {

      if (hasSingleSource) return target.withState(STATES.MUSIC_PLAYER)

      music = CurrentRoom.getRoomMusic()
      if (
        music &&
        music.type === BAS_ROOM.MUSIC_T_SONOS
      ) {

        return target.withState(STATES.MUSIC_PLAYER)
      }

    } else if (
      BasStateHelper.hasBaseState(toName, STATES.MUSIC_FAVOURITES)
    ) {

      basRoomMusic = CurrentRoom.getRoomMusic()
      basSource = CurrentRoom.getBasSource()

      if (basRoomMusic && basRoomMusic.type === BAS_ROOM.MUSIC_T_SONOS) {

        defaultSource = basRoomMusic.getDefaultSource()

        if (defaultSource) {

          basSource = SourcesHelper.getAudioSource(defaultSource)
        }
      }

      if (basSource) basSource = SourcesHelper.getBaseSource(basSource.getId())

      if (!basSource || !basSource.favourites) {

        return target.withState(
          hasSingleSource ? STATES.MUSIC_PLAYER : STATES.MUSIC_SOURCES
        )

      } else {

        LibraryState.setPlayer(basSource.getId())
      }

    } else if (
      BasStateHelper.hasBaseState(toName, STATES.MUSIC_LIBRARY)
    ) {

      sourceId = options.custom.sourceId

      // Determine BasSource, can be specified with custom "sourceId" option

      if (BasUtil.isString(sourceId) || BasUtil.isNumber(sourceId)) {

        basSource = SourcesHelper.getBaseSource(sourceId)

      } else {

        basSource = CurrentRoom.getBasSource()

        if (basSource) {

          basSource = SourcesHelper.getBaseSource(basSource.getId())
        }
      }

      if (!basSource || !basSource.supportsLibrary) {

        return target.withState(
          hasSingleSource ? STATES.MUSIC_PLAYER : STATES.MUSIC_SOURCES
        )

      } else {

        LibraryState.setPlayer(basSource.getId())

        if (basUi.prop.wMedium) {

          if (options.custom.goToLastLibraryState) {

            libraryCurrentState = LibraryState.getCurrentState()

            if (
              libraryCurrentState &&
              libraryCurrentState.lastState !== toName
            ) {
              return target.withState(libraryCurrentState.lastState)
            }
          }

          options.custom.goToLastLibraryState = false

          if (toName === STATES.MUSIC_LIBRARY) {

            currentBasSource = LibraryState.getCurrentSource()

            return (currentBasSource && currentBasSource.hasLocalContent())
              ? target
                .withState(STATES.MUSIC_LIBRARY_LOCAL)
                .withOptions(options)
              : target
                .withState(STATES.MUSIC_LIBRARY_RADIO)
                .withOptions(options)
          }

        } else {

          // Do nothing special for phones
        }
      }
    }
  }
}
