
'use strict'

var BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .service('BasAudioAlert', [
    '$rootScope',
    'BAS_API',
    'BAS_HTML',
    'BAS_CURRENT_CORE',
    'CurrentBasCore',
    'BasSound',
    'BasScreen',
    'BasAppDevice',
    'ModalService',
    'BasUtilities',
    'BasVolume',
    'Logger',
    BasAudioAlert
  ])

/**
 * @constructor
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasSound} BasSound
 * @param {BasScreen} BasScreen
 * @param {BasAppDevice} BasAppDevice
 * @param ModalService
 * @param {BasUtilities} BasUtilities
 * @param {BasVolume} BasVolume
 * @param {Logger} Logger
 */
function BasAudioAlert (
  $rootScope,
  BAS_API,
  BAS_HTML,
  BAS_CURRENT_CORE,
  CurrentBasCore,
  BasSound,
  BasScreen,
  BasAppDevice,
  ModalService,
  BasUtilities,
  BasVolume,
  Logger
) {

  var MIN_MODAL_OPEN_DURATION_MS = 5000

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasVolumeState}
   */
  var basVolumeState = BasVolume.get()

  var _audioAlertTopicListeners = []

  var currentModals = []
  var currentAudioAlertUuid
  var isAudioAlertPlaying = false
  var minModalDurationTimeoutId
  var silentAudioTimeoutId

  init()

  function init () {

    if (BasAppDevice.isCoreClient()) {

      _onAudioAlertTopicAvailable()

      $rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_V2_CONNECTED,
        _onSubscriptionCoreConnected
      )
    }
  }

  /**
   *
   * @param _event
   * @param {BasCoreContainer} core
   * @param {boolean} isConnected
   * @private
   */
  function _onSubscriptionCoreConnected (_event, core, isConnected) {

    if (isConnected) {

      if (core.hasCore()) {
        core.core.audioAlertTopic.subscribe().catch(Logger.warn)
        _onAudioAlertTopicAvailable()
      }

    } else {

      // Disconnected, close alert modal and clear listeners
      _closeCurrentModal()

      BasUtil.executeArray(_audioAlertTopicListeners)
      _audioAlertTopicListeners = []
    }
  }

  /**
   * @private
   */
  function _onAudioAlertTopicAvailable () {

    const audioAlertTopic = _getAudioAlertTopic()

    if (!audioAlertTopic) return

    _audioAlertTopicListeners.push(BasUtil.setEventListener(
      audioAlertTopic,
      BAS_API.AudioAlertTopic.EVT_AUDIO_ALERT_PLAY,
      _onAudioAlertTopicAudioAlertPlay
    ))
    _audioAlertTopicListeners.push(BasUtil.setEventListener(
      audioAlertTopic,
      BAS_API.AudioAlertTopic.EVT_AUDIO_ALERT_STOP,
      _onAudioAlertTopicAudioAlertStop
    ))
  }

  /**
   * @param {TAudioAlertEventPlay} data
   * @private
   */
  function _onAudioAlertTopicAudioAlertPlay (data) {

    currentAudioAlertUuid = data.uuid
    _closeCurrentModal()

    // Sets the display timeout to last known timeout, triggers wakeup as
    //  side effect
    BasScreen.releaseKeepScreenOn(true)

    minModalDurationTimeoutId = setTimeout(
      _onMinModalDurationTimeout,
      MIN_MODAL_OPEN_DURATION_MS
    )

    ModalService.showModal({
      template: BAS_HTML.audioAlertModal,
      controller: 'audioAlertModalCtrl',
      controllerAs: 'modal',
      inputs: {
        title: data.name
      }
    }).then(_onModal)

    function _onModal (modal) {

      currentModals.push(modal)

      if (data.playAudio) {

        // Play audio and use endCallback to determine when audio playback has
        //  finished

        isAudioAlertPlaying = true
        BasSound.playCustomNotification(
          data.audio,
          {
            volume: basVolumeState.browserNotificationVolume,
            repeat: data.repeat,
            endCallback: _onAudioEnded
          }
        )
      } else if (
        BasUtil.isPNumber(data.duration) &&
        BasUtil.isPNumber(data.repeat)
      ) {

        // Don't play audio, use setTimeout with calculated duration to
        //  determine when audio playback (on other device) has finished

        silentAudioTimeoutId = setTimeout(
          onSilentAudioTimeout,
          data.duration * data.repeat
        )
      } else if (data.repeat === -1) {

        // "Until turned off" silent audio alert: set silentAudioTimeoutId
        //   to a dummy truthy value to simulate indefinite silent alert
        silentAudioTimeoutId = 1
      }

      modal.close.then(_onModalClose)
    }

    function _onModalClose () {

      _clearMinModalDurationTimeout()
      _clearSilentAudioTimeout()
      BasSound.pauseCustomNotification()
      isAudioAlertPlaying = false
    }

    function _onAudioEnded () {

      isAudioAlertPlaying = false
      // Audio has ended, only close modal if it has been at least 5 seconds
      //  since opening it
      if (!minModalDurationTimeoutId) _closeCurrentModal()
    }

    function onSilentAudioTimeout () {

      silentAudioTimeoutId = 0
      // Audio has ended, only close modal if it has been at least 5 seconds
      //  since opening it
      if (!minModalDurationTimeoutId) _closeCurrentModal()
    }

    function _onMinModalDurationTimeout () {

      minModalDurationTimeoutId = 0
      // 5 seconds have elapsed, only close modal if audio has ended
      if (!isAudioAlertPlaying && !silentAudioTimeoutId) _closeCurrentModal()
    }
  }

  /**
   * @param {string} alertUuid
   * @param interrupted
   * @private
   */
  function _onAudioAlertTopicAudioAlertStop (alertUuid, interrupted) {

    // Only handle when the audio alert was interrupted. If the audio has just
    //  finished playing, this will be handled on client side to avoid issues
    //  when timing of client vs server does not fully match.
    if (
      interrupted &&
      currentAudioAlertUuid === alertUuid
    ) {
      currentAudioAlertUuid = ''
      _closeCurrentModal()
    }
  }

  function _closeCurrentModal () {

    for (const currentModal of currentModals) {
      BasUtilities.closeModal(currentModal)
    }
    currentModals = []
  }

  function _clearMinModalDurationTimeout () {

    if (minModalDurationTimeoutId) {

      clearTimeout(minModalDurationTimeoutId)
      minModalDurationTimeoutId = 0
    }
  }

  function _clearSilentAudioTimeout () {

    if (silentAudioTimeoutId) {

      clearTimeout(silentAudioTimeoutId)
      silentAudioTimeoutId = 0
    }
  }

  /**
   * @private
   * @returns {?AudioAlertTopic}
   */
  function _getAudioAlertTopic () {

    if (
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.audioAlertTopic
    ) {
      return currentBasCoreState.core.core.audioAlertTopic
    }
    return null
  }
}
