'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasServerStorage', [
    'BAS_API',
    'BAS_APP_STORAGE',
    'BasStorage',
    'BasStoredServer',
    'BasStoredServerSettings',
    'Logger',
    BasServerStorage
  ])

/**
 * @typedef {Object} TBasServerStorageState
 * @property {Object<string, BasStoredServer>} knownServers
 * @property {string} lastServer
 * @property {Object<string, BasStoredServerSettings>} serverSettings
 */

/**
 * @callback CBasServerStorageIterate
 * @param {BasStoredServer} storedServer
 */

/**
 * @constructor
 * @param BAS_API
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @param BasStorage
 * @param BasStoredServer
 * @param BasStoredServerSettings
 * @param Logger
 */
function BasServerStorage (
  BAS_API,
  BAS_APP_STORAGE,
  BasStorage,
  BasStoredServer,
  BasStoredServerSettings,
  Logger
) {
  /**
   * @type {TBasServerStorageState}
   */
  var state = {}
  state.knownServers = {}
  state.lastServer = ''
  state.serverSettings = {}

  this.get = get
  this.getServer = getServer
  this.getServerByProjectId = getServerByProjectId
  this.getServerByMAC = getServerByMAC
  this.getServerByAddress = getServerByAddress
  this.getLastServer = getLastServer
  this.getServerSettings = getServerSettings
  this.getServerSettingsForBasServer = getServerSettingsForBasServer
  this.getServerSettingsForStoredServer = getServerSettingsForStoredServer
  this.getServerSettingsForAddress = getServerSettingsForAddress
  this.getBasConnectCredentialsLastUserForProjectId =
    getBasConnectCredentialsLastUserForProjectId
  this.getKnownMacAddresses = getKnownMacAddresses
  this.hasKnownCores = hasKnownCores
  this.iterateKnownCores = iterateKnownCores
  this.saveServer = saveServer
  this.saveLastServerKey = saveLastServerKey
  this.saveLastUserKey = saveLastUserKey
  this.saveLastRoomIdForLastUser = saveLastRoomIdForLastUser
  this.saveSettingsForLastUser = saveSettingsForLastUser
  this.saveHistoryForLastUser = saveHistoryForLastUser
  this.removeLastUserLoginCredentials = removeLastUserLoginCredentials
  this.removeLastUser = removeLastUser
  this.clear = clear

  init()

  function init () {

    _migrateStorage()
    _migrateStorage2()
    _syncFromStorage()
  }

  /**
   * @returns {TBasServerStorageState}
   */
  function get () {

    return state
  }

  /**
   * @param {string} key
   * @returns {?BasStoredServer}
   */
  function getServer (key) {

    var _server

    if (BasUtil.isNEString(key)) {

      _server = state.knownServers[key]

      if (_server) return _server
    }

    return null
  }

  /**
   * @param {string} projectId
   * @param {string} [type]
   * @returns {?BasStoredServer}
   */
  function getServerByProjectId (projectId, type) {

    var length, i, keys, key, _server

    if (BasUtil.isNEString(projectId)) {

      keys = Object.keys(state.knownServers)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]
        _server = state.knownServers[key]

        if (_server && _server.cid === projectId) {

          if (type) {

            if (_server.type === type) return _server

          } else {

            return _server
          }
        }
      }
    }

    return null
  }

  /**
   * Search stored server by MAC address
   *
   * @param {(string|number)} mac
   * @param {string} [type]
   * @returns {?BasStoredServer}
   */
  function getServerByMAC (mac, type) {

    var length, i, keys, key, _server

    if (BasUtil.isPNumber(mac) ||
      BasUtil.couldBeMACAddress(mac)) {

      keys = Object.keys(state.knownServers)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]
        _server = state.knownServers[key]

        if (_server && _server.hasSameMac(mac)) {

          if (type) {

            if (_server.type === type) return _server

          } else {

            return _server
          }
        }
      }
    }

    return null
  }

  /**
   * Search stored server by (hostname/IP) address
   *
   * @param {string} address
   * @param {string} [type]
   * @returns {?BasStoredServer}
   */
  function getServerByAddress (address, type) {

    var length, i, keys, key, _server

    if (BasUtil.isNEString(address)) {

      keys = Object.keys(state.knownServers)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]
        _server = state.knownServers[key]

        if (_server && _server.address === address) {

          if (type) {

            if (_server.type === type) return _server

          } else {

            return _server
          }
        }
      }
    }

    return null
  }

  /**
   * @returns {?BasStoredServer}
   */
  function getLastServer () {

    var _server

    _server = state.knownServers[state.lastServer]

    return _server || null
  }

  /**
   * @param {string} key
   * @returns {?BasStoredServerSettings}
   */
  function getServerSettings (key) {

    var value

    value = state.serverSettings[key]

    return value || null
  }

  /**
   * @param {BasServer} basServer
   * @returns {?BasStoredServerSettings}
   */
  function getServerSettingsForBasServer (basServer) {

    var key

    if (basServer) {

      key = BasStoredServer.getServerSettingsKeyFromBasServer(basServer)

      if (key) return getServerSettings(key)
    }

    return null
  }

  /**
   * @param {BasStoredServer} storedServer
   * @returns {?BasStoredServerSettings}
   */
  function getServerSettingsForStoredServer (storedServer) {

    return (storedServer && storedServer.serverSettingsKey)
      ? getServerSettings(storedServer.serverSettingsKey)
      : null
  }

  /**
   * @param {string} address
   * @returns {?BasStoredServerSettings}
   */
  function getServerSettingsForAddress (address) {

    const server = getServerByAddress(address)

    return server ? getServerSettingsForStoredServer(server) : null
  }

  /**
   * @param {string} projectId
   * @returns {?TBasConnectCredentials}
   */
  function getBasConnectCredentialsLastUserForProjectId (projectId) {

    var _storedServerSettings

    _storedServerSettings = getServerSettingsForStoredServer(
      getServerByProjectId(projectId)
    )

    return _storedServerSettings
      ? _storedServerSettings.getBasConnectCredentialsLastUser()
      : null
  }

  /**
   * Returns array with all MAc addresses of the known servers.
   *
   * @returns {number[]}
   */
  function getKnownMacAddresses () {

    var result, length, i, keys, key, _server

    result = []

    keys = Object.keys(state.knownServers)
    length = keys.length
    for (i = 0; i < length; i++) {

      key = keys[i]
      _server = state.knownServers[key]

      if (_server) result.push(_server.macN)
    }

    return result
  }

  /**
   * Whether there are valid known cores or not
   *
   * @returns {boolean}
   */
  function hasKnownCores () {

    var keys, length, i, key

    keys = Object.keys(state.knownServers)
    length = keys.length
    for (i = 0; i < length; i++) {

      key = keys[i]

      if (state.knownServers[key]) return true
    }

    return false
  }

  /**
   * @param {CBasServerStorageIterate} callback
   * @param {string[]} [type] Type filter
   */
  function iterateKnownCores (callback, type) {

    var keys, length, i, key, storedServer, doCheck

    if (BasUtil.isFunction(callback)) {

      doCheck = Array.isArray(type)

      keys = Object.keys(state.knownServers)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]

        storedServer = state.knownServers[key]

        if (storedServer) {

          if (doCheck) {

            if (type.indexOf(storedServer.type) > -1) {

              callback(storedServer)
            }

          } else {

            callback(storedServer)
          }
        }
      }
    }
  }

  /**
   * Returns the key of the new/modified BasStoredServer.
   *
   * @param {BasServer} basServer
   * @returns {string}
   */
  function saveServer (basServer) {

    var _changed, key, storedServer, newKey, storedSettings
    var storedKey

    _changed = false
    storedKey = ''

    if (BasUtil.isObject(basServer) &&
      basServer.getUsers) {

      key = BasStoredServer.getKeyFromBasServer(basServer)

      if (key) {

        _syncFromStorage()

        storedServer = state.knownServers[key]

        if (storedServer) {

          if (storedServer.parseBasServer(basServer)) {

            _changed = true
          }

          storedKey = key

        } else {

          storedServer = new BasStoredServer(basServer)
          newKey = storedServer.getKey()

          if (newKey) {

            state.knownServers[newKey] = storedServer
            _changed = true

            storedKey = newKey
          }
        }

        key = storedServer.serverSettingsKey

        if (key) {

          storedSettings = state.serverSettings[key]

          if (storedSettings) {

            if (storedSettings.parseBasServer(basServer)) {

              _changed = true
            }

          } else {

            storedSettings = new BasStoredServerSettings()
            storedSettings.parseBasServer(basServer)
            state.serverSettings[storedServer.serverSettingsKey] =
              storedSettings

            _changed = true
          }
        }

        if (_changed) _syncToStorage()
      }
    }

    return storedKey
  }

  /**
   * @param {string} key
   */
  function saveLastServerKey (key) {

    if (BasUtil.isString(key)) {

      if (state.knownServers[key]) {

        if (state.lastServer !== key) {

          state.lastServer = key
          _syncToStorage()
        }

      } else {

        Logger.warn(
          'Save last server key - ' +
          'Key is not found in known servers',
          key
        )
      }
    }
  }

  /**
   * @param {string} serverKey
   * @param {string} userKey
   */
  function saveLastUserKey (serverKey, userKey) {

    var _server, _serverSettings

    _server = getServer(serverKey)

    if (_server && _server.serverSettingsKey) {

      _serverSettings = getServerSettings(_server.serverSettingsKey)

      if (_serverSettings) {

        if (_serverSettings.saveLastUserKey(userKey)) {

          _syncToStorage()
        }

      } else {

        _serverSettings = new BasStoredServerSettings()
        _serverSettings.cid = _server.cid

        _serverSettings.saveLastUserKey(userKey)

        state.serverSettings[_server.serverSettingsKey] =
          _serverSettings

        _syncToStorage()
      }
    }
  }

  /**
   * @param {string} serverKey
   * @param {string} roomId
   */
  function saveLastRoomIdForLastUser (serverKey, roomId) {

    var _server, _serverSettings

    _server = getServer(serverKey)

    if (_server && _server.serverSettingsKey) {

      _serverSettings = getServerSettings(_server.serverSettingsKey)

      if (_serverSettings) {

        if (_serverSettings.saveLastRoomIdForLastUser(roomId)) {

          _syncToStorage()
        }

      } else {

        _serverSettings = new BasStoredServerSettings()
        _serverSettings.cid = _server.cid

        _serverSettings.saveLastRoomIdForLastUser(roomId)

        state.serverSettings[_server.serverSettingsKey] =
          _serverSettings

        _syncToStorage()
      }
    }
  }

  /**
   * @param {string} serverKey
   * @param {?Object} settings
   */
  function saveSettingsForLastUser (serverKey, settings) {

    var _server, _serverSettings

    _server = getServer(serverKey)

    if (_server && _server.serverSettingsKey) {

      _serverSettings = getServerSettings(_server.serverSettingsKey)

      if (_serverSettings) {

        if (_serverSettings.saveSettingsForLastUser(settings)) {

          _syncToStorage()
        }

      } else {

        _serverSettings = new BasStoredServerSettings()
        _serverSettings.cid = _server.cid

        _serverSettings.saveSettingsForLastUser(settings)

        state.serverSettings[_server.serverSettingsKey] =
          _serverSettings

        _syncToStorage()
      }
    }
  }

  /**
   * @param {string} serverKey
   * @param {?Object} history
   */
  function saveHistoryForLastUser (serverKey, history) {

    var _server, _serverSettings

    _server = getServer(serverKey)

    if (_server && _server.serverSettingsKey) {

      _serverSettings = getServerSettings(_server.serverSettingsKey)

      if (_serverSettings) {

        if (_serverSettings.saveHistoryForLastUser(history)) {

          _syncToStorage()
        }

      } else {

        _serverSettings = new BasStoredServerSettings()
        _serverSettings.cid = _server.cid

        _serverSettings.saveHistoryForLastUser(history)

        state.serverSettings[_server.serverSettingsKey] =
          _serverSettings

        _syncToStorage()
      }
    }
  }

  /**
   * @param {string} serverKey
   */
  function removeLastUserLoginCredentials (serverKey) {

    var _server, _serverSettings

    _server = getServer(serverKey)

    if (_server && _server.serverSettingsKey) {

      _serverSettings = getServerSettings(_server.serverSettingsKey)

      if (_serverSettings) {

        if (_serverSettings.removeLoginCredentialsForLastUser()) {

          _syncToStorage()
        }
      }
    }
  }

  /**
   * @param {string} serverKey
   */
  function removeLastUser (serverKey) {

    var _server

    _server = getServer(serverKey)

    if (_server) {

      if (_server.lastUser) {

        _server.lastUser = ''

        _syncToStorage()
      }
    }
  }

  function _syncFromStorage () {

    var _knownServers, _lastKnownServer, _serverSettings
    var keys, length, i, key, entry
    var _storedServer, _newKnownServers, _newKey
    var _newServerSettings, _storedServerSettings

    _reset()

    _knownServers = BasStorage.get(BAS_APP_STORAGE.K_KNOWN_SERVERS_V3)
    _lastKnownServer = BasStorage.get(
      BAS_APP_STORAGE.K_LAST_KNOWN_SERVER_V3,
      {
        json: false
      }
    )
    _serverSettings = BasStorage.get(BAS_APP_STORAGE.K_SERVER_SETTINGS_V3)

    if (BasUtil.isObject(_knownServers)) {

      _newKnownServers = {}

      keys = Object.keys(_knownServers)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]
        entry = _knownServers[key]

        /**
         * @type {?BasStoredServer}
         */
        _storedServer = BasStoredServer.parse(entry)

        if (_storedServer) {

          _newKey = _storedServer.getKey()
          _newKnownServers[_newKey] = _storedServer
        }
      }

      state.knownServers = _newKnownServers

      if (BasUtil.isNEString(_lastKnownServer)) {

        if (state.knownServers[_lastKnownServer]) {

          state.lastServer = _lastKnownServer

        } else {

          Logger.warn(
            'Sync from storage - ' +
            'Last known server not found in known servers!',
            _lastKnownServer
          )
        }
      }
    }

    if (BasUtil.isObject(_serverSettings)) {

      _newServerSettings = {}

      keys = Object.keys(_serverSettings)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]
        entry = _serverSettings[key]

        _storedServerSettings =
          BasStoredServerSettings.parse(key, entry)

        if (_storedServerSettings) {

          _newServerSettings[key] = _storedServerSettings
        }
      }

      state.serverSettings = _newServerSettings
    }
  }

  function _syncToStorage () {

    var _newKnownServers, _hasKnownServers
    var _newServerSettings, _hasServerSettings
    var length, i, keys, key, item, data

    // Known servers

    _hasKnownServers = false
    _newKnownServers = {}

    keys = Object.keys(state.knownServers)
    length = keys.length

    if (length > 0) {

      for (i = 0; i < length; i++) {

        key = keys[i]
        item = state.knownServers[key]

        if (item) {

          data = item.toStorageData()
          if (data) {

            _newKnownServers[item.getKey()] = data
            _hasKnownServers = true
          }
        }
      }
    }

    if (_hasKnownServers) {

      BasStorage.set(BAS_APP_STORAGE.K_KNOWN_SERVERS_V3, _newKnownServers)

    } else {

      BasStorage.remove(BAS_APP_STORAGE.K_KNOWN_SERVERS_V3)
    }

    // Last known server

    if (BasUtil.isNEString(state.lastServer) &&
      state.knownServers[state.lastServer]) {

      BasStorage.set(
        BAS_APP_STORAGE.K_LAST_KNOWN_SERVER_V3,
        state.lastServer,
        {
          json: false
        }
      )

    } else {

      BasStorage.remove(BAS_APP_STORAGE.K_LAST_KNOWN_SERVER_V3)
    }

    // Server settings

    _hasServerSettings = false
    _newServerSettings = {}

    keys = Object.keys(state.serverSettings)
    length = keys.length

    if (length > 0) {

      for (i = 0; i < length; i++) {

        key = keys[i]
        item = state.serverSettings[key]

        if (item) {

          data = item.toStorageDate()

          if (data) {

            _newServerSettings[key] = data
            _hasServerSettings = true
          }
        }
      }
    }

    if (_hasServerSettings) {

      BasStorage.set(
        BAS_APP_STORAGE.K_SERVER_SETTINGS_V3,
        _newServerSettings
      )

    } else {

      BasStorage.remove(BAS_APP_STORAGE.K_SERVER_SETTINGS_V3)
    }
  }

  /**
   * Clears all items managed by server storage
   */
  function clear () {

    BasStorage.remove(BAS_APP_STORAGE.K_KNOWN_SERVERS_V3)
    BasStorage.remove(BAS_APP_STORAGE.K_LAST_KNOWN_SERVER_V3)
  }

  /**
   * Migrate app storage to new format.
   * Only affects data stored under KNOWN_SERVERS and LAST_SERVER
   * Migrates to V2_KNOWN_SERVERS and/or V2_LAST_KNOWN_SERVER
   *
   * @private
   */
  function _migrateStorage () {

    var _knownServers, _lastKnownServer
    var length, i, keys, key, lastKey, newKey, server, item
    var _newLastKnownServer, _newKnownServers
    var _NENewKnownServers
    var _updateKnownServers

    _newKnownServers = {}
    _updateKnownServers = false

    _NENewKnownServers = false

    // Last known server

    _lastKnownServer = BasStorage.get(BAS_APP_STORAGE.K_LAST_KNOWN_SERVER)

    if (BasUtil.isObject(_lastKnownServer)) {

      key = _getServerKey(_lastKnownServer)

      if (BasUtil.isNEString(key)) {

        if (key === 'DEMO') {

          _newLastKnownServer =
            BAS_APP_STORAGE.T_DEMO + BAS_API.Demo.DEMO_CID

        } else {

          lastKey = key
        }
      }

    } else if (_lastKnownServer === 'DEMO') {

      _newLastKnownServer =
        BAS_APP_STORAGE.T_DEMO + BAS_API.Demo.DEMO_CID
    }

    // Known servers

    _knownServers = BasStorage.get(BAS_APP_STORAGE.K_KNOWN_SERVERS)

    if (BasUtil.isObject(_knownServers)) {

      // Correct users for each server

      keys = Object.keys(_knownServers)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]

        if (BasUtil.isNEString(key)) {

          server = _knownServers[key]

          if (BasUtil.isObject(server)) {

            if (key === 'DEMO' &&
              server[BAS_APP_STORAGE.K_ADDRESS] === 'DEMO') {

              // Migrate "Demo" entry

              _updateKnownServers = true

              server[BAS_APP_STORAGE.K_TYPE] =
                BAS_APP_STORAGE.T_DEMO
              delete server[BAS_APP_STORAGE.K_MAC_N]
              delete server[BAS_APP_STORAGE.K_MAC]
              delete server[BAS_APP_STORAGE.K_ADDRESS]
              server[BAS_APP_STORAGE.K_CID] =
                BAS_API.Demo.DEMO_CID

              newKey =
                server[BAS_APP_STORAGE.K_TYPE] +
                server[BAS_APP_STORAGE.K_CID]

              // Save "new" demo entry
              _newKnownServers[newKey] = server
              _NENewKnownServers = true

            } else {

              // If "type" is not a property
              // of a stored server object
              // => migration needed

              if (!BasUtil.safeHasOwnProperty(
                server,
                BAS_APP_STORAGE.K_TYPE
              )) {

                _updateKnownServers = true
              }

              // If "lastUser" is a property and not a string
              // => migration needed

              if (
                BasUtil.safeHasOwnProperty(
                  server,
                  BAS_APP_STORAGE.K_LAST_USER
                ) &&
                !BasUtil.isNEString(
                  server[BAS_APP_STORAGE.K_LAST_USER]
                )
              ) {

                _updateKnownServers = true
              }

              // Migrate "lastUser" to string key

              if (BasUtil.isObject(
                server[BAS_APP_STORAGE.K_LAST_USER]
              )) {

                item = server[BAS_APP_STORAGE.K_LAST_USER]

                if (BasUtil.isNEString(
                  item[BAS_APP_STORAGE.K_NAME]
                )) {

                  server[BAS_APP_STORAGE.K_LAST_USER] =
                    item[BAS_APP_STORAGE.K_NAME]
                }
              }

              // Migrate MAC address notation
              // to have number representation

              if (
                BasUtil.isNEString(
                  server[BAS_APP_STORAGE.K_MAC]
                ) &&
                (
                  !BasUtil.safeHasOwnProperty(
                    server,
                    BAS_APP_STORAGE.K_MAC_N
                  ) ||
                  !BasUtil.isPNumber(
                    server[BAS_APP_STORAGE.K_MAC_N],
                    true
                  )
                )
              ) {

                _updateKnownServers = true

                item = server[BAS_APP_STORAGE.K_MAC_N] =
                  BAS_API.MacAddressUtil.convertToNumber(
                    server[BAS_APP_STORAGE.K_MAC]
                  )
              }

              delete server[BAS_APP_STORAGE.K_MAC]

              // Add default "local" type
              server[BAS_APP_STORAGE.K_TYPE] =
                BAS_APP_STORAGE.T_LOCAL

              newKey = BasStoredServer.getKey(
                server[BAS_APP_STORAGE.K_TYPE],
                server[BAS_APP_STORAGE.K_CID],
                server[BAS_APP_STORAGE.K_MAC_N],
                server[BAS_APP_STORAGE.K_ADDRESS]
              )

              // Update last known server key

              if (lastKey === key) {

                _newLastKnownServer = newKey
              }

              _newKnownServers[newKey] = server
              _NENewKnownServers = true
            }

          } else {

            _updateKnownServers = true
          }

        } else {

          _updateKnownServers = true
        }
      }
    }

    if (_updateKnownServers) {

      if (_NENewKnownServers) {

        BasStorage.set(
          BAS_APP_STORAGE.K_KNOWN_SERVERS_V2,
          _newKnownServers
        )
      }
    }

    if (_newLastKnownServer) {

      BasStorage.set(
        BAS_APP_STORAGE.K_LAST_KNOWN_SERVER_V2,
        _newLastKnownServer,
        {
          json: false
        }
      )
    }

    BasStorage.remove(BAS_APP_STORAGE.K_LAST_KNOWN_SERVER)
    BasStorage.remove(BAS_APP_STORAGE.K_KNOWN_SERVERS)

    function _getServerKey (storedServerObject) {

      var _macAddress, _address

      if (BasUtil.isObject(storedServerObject)) {

        _macAddress = storedServerObject[BAS_APP_STORAGE.K_MAC]
        _address = storedServerObject[BAS_APP_STORAGE.K_ADDRESS]

        return BasUtil.couldBeMACAddress(_macAddress)
          ? _macAddress
          : _address
      }
    }
  }

  /**
   * Migrate app storage to new format.
   * Only affects data stored under V2_KNOWN_SERVERS and V2_LAST_KNOWN_SERVER
   * Migrates to V3_KNOWN_SERVERS and/or V3_LAST_KNOWN_SERVER and/or
   * V3_SERVER_SETTINGS
   *
   * @private
   */
  function _migrateStorage2 () {

    var _lastKnownServer, _newLastKnownServer
    var _knownServers, _newKnownServers, _hasNewKnownServers
    var _storedServer, _newStoredServer
    var _newServerSettings, _hasNewServerSettings
    var _serverSettingsKey, _serverSettings
    var keys, length, i, key

    _hasNewKnownServers = false
    _hasNewServerSettings = false

    // V2 Last known server

    _lastKnownServer = BasStorage.get(
      BAS_APP_STORAGE.K_LAST_KNOWN_SERVER_V2,
      {
        json: false
      }
    )

    if (BasUtil.isNEString(_lastKnownServer)) {

      _newLastKnownServer = _lastKnownServer
    }

    // V2 known servers

    _knownServers = BasStorage.get(BAS_APP_STORAGE.K_KNOWN_SERVERS_V2)

    if (BasUtil.isObject(_knownServers)) {

      _newKnownServers = {}
      _newServerSettings = {}

      keys = Object.keys(_knownServers)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]

        if (BasUtil.isNEString(key)) {

          _storedServer = _knownServers[key]

          if (BasUtil.isObject(_storedServer)) {

            // Migrate known server

            _newStoredServer = _parseStoredServer(_storedServer)

            if (_newStoredServer) {

              _newKnownServers[key] = _newStoredServer
              _hasNewKnownServers = true
            }

            // Extract server settings and
            // store in separate collection

            _serverSettings = _parseServerSettings(_storedServer)

            if (_serverSettings) {

              _serverSettingsKey =
                _getServerSettingsKey(_storedServer)

              if (_serverSettingsKey) {

                _newServerSettings[_serverSettingsKey] =
                  _serverSettings
                _hasNewServerSettings = true

              } else {

                // Should not occur
              }
            }
          }
        }
      }
    }

    if (_newLastKnownServer) {

      BasStorage.set(
        BAS_APP_STORAGE.K_LAST_KNOWN_SERVER_V3,
        _newLastKnownServer,
        {
          json: false
        }
      )
    }

    if (_hasNewKnownServers) {

      BasStorage.set(
        BAS_APP_STORAGE.K_KNOWN_SERVERS_V3,
        _newKnownServers
      )
    }

    if (_hasNewServerSettings) {

      BasStorage.set(
        BAS_APP_STORAGE.K_SERVER_SETTINGS_V3,
        _newServerSettings
      )
    }

    BasStorage.remove(BAS_APP_STORAGE.K_KNOWN_SERVERS_V2)
    BasStorage.remove(BAS_APP_STORAGE.K_LAST_KNOWN_SERVER_V2)

    /**
     * @private
     * @param {Object} storedServer
     * @returns {?Object}
     */
    function _parseStoredServer (storedServer) {

      var result, valid, value

      valid = false
      result = null

      if (BasUtil.isObject(storedServer)) {

        result = {}

        value = storedServer[BAS_APP_STORAGE.K_MAC_N]
        if (BasUtil.isPNumber(value)) {

          valid = true

          result[BAS_APP_STORAGE.K_MAC_N] = value
        }

        value = storedServer[BAS_APP_STORAGE.K_ADDRESS]
        if (BasUtil.isNEString(value)) {

          valid = true

          result[BAS_APP_STORAGE.K_ADDRESS] = value
        }

        value = storedServer[BAS_APP_STORAGE.K_CID]
        if (BasUtil.isNEString(value)) {

          valid = true

          result[BAS_APP_STORAGE.K_CID] = value
        }
      }

      return valid ? result : null
    }

    /**
     * @private
     * @param {Object} storedServer
     * @returns {string}
     */
    function _getServerSettingsKey (storedServer) {

      var result, type, cid, mac, address

      result = ''

      if (BasUtil.isObject(storedServer)) {

        type = storedServer[BAS_APP_STORAGE.K_TYPE]

        type = BasUtil.isNEString(type)
          ? type
          : BAS_APP_STORAGE.T_LOCAL

        cid = storedServer[BAS_APP_STORAGE.K_CID]
        mac = storedServer[BAS_APP_STORAGE.K_MAC_N]
        address = storedServer[BAS_APP_STORAGE.K_ADDRESS]

        if (type === BAS_APP_STORAGE.T_DEMO ||
          type === BAS_APP_STORAGE.T_REMOTE) {

          result += BAS_APP_STORAGE.V_CID + cid

        } else if (BAS_APP_STORAGE.T_LOCAL) {

          if (cid) {

            result += BAS_APP_STORAGE.V_CID + cid

          } else if (mac) {

            result += BAS_APP_STORAGE.V_MAC + mac

          } else if (address) {

            result += BAS_APP_STORAGE.V_ADDRESS + address
          }
        }
      }

      return result
    }

    /**
     * @private
     * @param {Object} storedServer
     * @returns {?Object}
     */
    function _parseServerSettings (storedServer) {

      var result, value, valid

      result = null
      valid = false

      if (BasUtil.isObject(storedServer)) {

        result = {}

        // Default rooms notification

        value =
          storedServer[BAS_APP_STORAGE.K_DEFAULT_ROOMS_NOTIFICATION]

        if (BasUtil.isBool(value)) {

          valid = true
          result[BAS_APP_STORAGE.K_DEFAULT_ROOMS_NOTIFICATION] =
            value
        }

        // Users

        value = storedServer[BAS_APP_STORAGE.K_USERS]

        if (BasUtil.isObject(value)) {

          valid = true
          result[BAS_APP_STORAGE.K_USERS] = value
        }

        // Last user

        value = storedServer[BAS_APP_STORAGE.K_LAST_USER]

        if (BasUtil.isNEString(value)) {

          valid = true
          result[BAS_APP_STORAGE.K_LAST_USER] = value
        }

        if (!valid) result = null
      }

      return result
    }
  }

  function _reset () {

    state.knownServers = {}
    state.lastServer = ''
  }
}
