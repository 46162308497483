'use strict'

var EventEmitter = require('@gidw/event-emitter-js')
var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

/**
 * The class representing a Stream.
 *
 * A Stream has a CobraNet number and (optionally) default rooms.
 *
 * Cobranet IDs
 *
 * "source"   Actual CobraNet ID
 *    0               0
 *    1              301
 *
 * Actual CobraNet IDs [1 - 555] All
 * Actual CobraNet IDs [250 - 255] Noise testing channels
 *
 * @constructor
 * @extends EventEmitter
 * @param {Object} cfg Configuration for this stream
 * @param {number} cfg.id CobraNet bundle number
 * @param {string} [cfg.colour] Optional colour for stream
 * @param {BasCore} basCore
 * @since 1.6.0
 */
function Stream (cfg, basCore) {

  EventEmitter.call(this)

  this._basCore = basCore

  this._id = BasUtil.isVNumber(cfg[P.ID])
    ? cfg[P.ID]
    : 0
  this._colour = BasUtil.isNEString(cfg[P.COLOUR])
    ? cfg[P.COLOUR]
    : ''

  this._streamDefaultZones = []
  this._streamDefaultZonesDirty = true

  this._handleDefaultZones = this._onDefaultZones.bind(this)
}

Stream.prototype = Object.create(EventEmitter.prototype)
Stream.prototype.constructor = Stream

/**
 * Fired when the default rooms have changed on the basCore.
 * The Stream instance will need to retrieve the new default rooms.
 *
 * @event Stream#EVT_DEFAULT_ROOMS_CHANGED
 */

/**
 * @constant {string}
 */
Stream.EVT_DEFAULT_ROOMS_CHANGED = 'defaultRoomsChanged'

/**
 * CobraNet bundle number
 *
 * @name Stream#id
 * @type {number}
 * @readonly
 */
Object.defineProperty(Stream.prototype, 'id', {
  get: function () {
    return this._id
  }
})

/**
 * Stream colour in hex notation (#ffffff) or undefined
 *
 * @name Stream#colour
 * @type {string}
 * @readonly
 */
Object.defineProperty(Stream.prototype, 'colour', {
  get: function () {
    return this._colour
  }
})

/**
 * Parse a received stream object message
 *
 * @param {Object} object
 */
Stream.prototype.parseStream = function (object) {

  if (BasUtil.isObject(object)) {

    if (object[P.EVENT] === P.DEFAULT_ROOMS_CHANGED) {

      this._streamDefaultZonesDirty = true

      if (this._basCore &&
        this._basCore.supportsDefaultRooms) {

        // Emit event
        this.emit(Stream.EVT_DEFAULT_ROOMS_CHANGED)
      }
    }
  }
}

/**
 * Retrieve default zones from the basCore
 *
 * @returns {Promise}
 */
Stream.prototype.retrieveDefaultZones = function () {

  var data

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (!this._basCore.supportsDefaultRooms) {

    return Promise.reject(CONSTANTS.ERR_UNSUPPORTED)
  }

  data = this._getBasCoreStreamMessage()
  data[P.STREAM][P.ACTION] = P.LIST_DEFAULT_ROOMS

  return this._basCore.requestRetry(data)
    .then(this._handleDefaultZones)
}

/**
 * @private
 * @param {?Object} result
 * @returns {(string[]|Promise)}
 */
Stream.prototype._onDefaultZones = function (result) {

  // Check result
  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.STREAM]) &&
    Array.isArray(result[P.STREAM][P.DEFAULT_ROOMS])) {

    // Update default rooms
    this._streamDefaultZones = result[P.STREAM][P.DEFAULT_ROOMS]

    // Unset dirty flag
    this._streamDefaultZonesDirty = false

    return this._streamDefaultZones
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * Check if given zone UUID is a default zone for this Stream
 *
 * @param {string} zoneId
 * @returns {boolean}
 */
Stream.prototype.isDefaultZone = function (zoneId) {

  return this._streamDefaultZones.indexOf(zoneId) !== -1
}

/**
 * Modify default zone(s).
 * This will trigger an event EVT_DEFAULT_ROOMS_CHANGED
 *
 * If the default zones have never been retrieved before,
 * this will retrieve the default zones.
 *
 * @param {(string|string[])} zones
 * @param {boolean} [remove = false]
 * @returns {Promise}
 */
Stream.prototype.modifyDefaultZones = function (zones, remove) {

  var _this

  _this = this

  if (!(BasUtil.isNEString(zones) || Array.isArray(zones))) {

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (!this._basCore.supportsDefaultRooms) {

    return Promise.reject(CONSTANTS.ERR_UNSUPPORTED)
  }

  return this._streamDefaultZonesDirty
    ? this.retrieveDefaultZones().then(_modifyDefaultZones)
    : _modifyDefaultZones()

  function _modifyDefaultZones () {

    return _this.setDefaultZones(_this._calculateNewZones(
      zones,
      BasUtil.isBool(remove) ? remove : false
    ))
  }
}

/**
 * @param {string[]} zones
 * @returns {Promise}
 */
Stream.prototype.setDefaultZones = function (zones) {

  var data

  if (!Array.isArray(zones)) {

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (!this._basCore.supportsDefaultRooms) {

    return Promise.reject(CONSTANTS.ERR_UNSUPPORTED)
  }

  data = this._getBasCoreStreamMessage()
  data[P.STREAM][P.DEFAULT_ROOMS] = zones

  return this._basCore.requestRetry(data)
}

/**
 * Calculate new array. Add by default.
 * Returns null on invalid input.
 *
 * @private
 * @param {(string|string[])} zones
 * @param {boolean} [remove = false]
 * @returns {?(string[])}
 */
Stream.prototype._calculateNewZones = function (zones, remove) {

  var result, _remove, _newZones, isArray, length, i, idx, _zone

  isArray = Array.isArray(zones)

  if (!(BasUtil.isNEString(zones) || isArray)) return null

  _remove = BasUtil.isBool(remove) ? remove : false

  _newZones = this._streamDefaultZones.slice()

  if (_remove) {

    result = []

    length = _newZones.length
    for (i = 0; i < length; i++) {

      _zone = _newZones[i]

      if (_zone) {

        if (isArray) {

          idx = zones.indexOf(_zone)

          // Add zone if not found in the zones to remove
          if (idx === -1) result.push(_zone)

        } else {

          // Add zone if not equal to zone to remove
          if (_zone !== zones) result.push(_zone)
        }
      }
    }

  } else {

    result = _newZones

    if (isArray) {

      result = result.concat(zones)

    } else {

      result.push(zones)
    }
  }

  return result
}

/**
 * @private
 * @returns {Object}
 * @since 3.0.0
 */
Stream.prototype._getBasCoreStreamMessage = function () {

  var data

  data = {}
  data[P.STREAM] = {}
  data[P.STREAM][P.ID] = this._id

  return data
}

module.exports = Stream
