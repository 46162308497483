'use strict'

angular
  .module('basalteApp')
  .controller('verifyLiveCtrl', [
    '$scope',
    'STATES',
    'BAS_LIVE_ACCOUNT',
    'BAS_MODAL',
    'BAS_UTILITIES',
    'BasState',
    'BasLiveAccount',
    'BasModal',
    verifyLiveCtrl
  ])

/**
 * @param $scope
 * @param {STATES} STATES
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_UTILITIES} BAS_UTILITIES
 * @param {BasState} BasState
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasModal} BasModal
 */
function verifyLiveCtrl (
  $scope,
  STATES,
  BAS_LIVE_ACCOUNT,
  BAS_MODAL,
  BAS_UTILITIES,
  BasState,
  BasLiveAccount,
  BasModal
) {
  var verify = this

  /**
   * @type {TBasLiveAccountState}
   */
  verify.basLiveAccountState = BasLiveAccount.get()

  /**
   * @type {string}
   */
  verify.email = ''

  /**
   * @type {string}
   */
  verify.code = ''

  /**
   * @type {boolean}
   */
  verify.hasCognitoUser = false

  verify.performVerify = performVerify
  verify.resendConfirmation = resendConfirmation
  verify.alreadyVerified = alreadyVerified

  init()

  function init () {

    verify.hasCognitoUser = !!_getCognitoUserFromLive()
  }

  function performVerify (form) {

    var _cognitoUser

    if (form.$valid) {

      _cognitoUser = _getCognitoUser()

      if (_cognitoUser) {

        BasLiveAccount.confirmRegistration(
          _cognitoUser,
          verify.code,
          _onRegistrationConfirmed
        )

      } else {

        verify.hasCognitoUser = false

        BasModal.show(BAS_MODAL.T_ERROR)
      }

    } else {

      BasModal.show(BAS_MODAL.T_ERROR)
    }
  }

  function resendConfirmation () {

    var _cognitoUser, _username

    _cognitoUser = _getCognitoUser()

    if (_cognitoUser) {

      // TODO Show modal?

      _username = _cognitoUser.getUsername()

      BasLiveAccount.resendConfirmation(
        _cognitoUser,
        _onResendConfirmation
      )

    } else {

      verify.hasCognitoUser = false

      BasModal.show(BAS_MODAL.T_ERROR)
    }

    function _onResendConfirmation (error) {

      if (error) {

        if (error.code === BAS_LIVE_ACCOUNT.A_ERR_USER_NOT_FOUND) {

          BasModal.show(BAS_MODAL.T_ERROR_UNKNOWN_USER)

        } else {

          BasModal.show(BAS_MODAL.T_ERROR)
        }

      } else {

        BasModal.show(
          BAS_MODAL.T_CONFIRMATION_CODE_SENT,
          {
            email: _username
          }
        )
      }
    }
  }

  function alreadyVerified () {

    if (_getCognitoUserFromLive()) {

      // Login with saved credentials

      $scope.live.loginToVerifyUser()

    } else {

      // Go to login

      BasState.go(STATES.CONNECT_LIVE_LOGIN)
    }
  }

  function _onRegistrationConfirmed (error) {

    if (error) {

      BasModal.show(BAS_MODAL.T_ERROR_INVALID_CODE)

    } else {

      if (_getCognitoUserFromLive()) {

        // Login with saved credentials

        $scope.live.loginToVerifyUser()

      } else {

        // Go to login

        BasState.go(STATES.CONNECT_LIVE_LOGIN)
      }
    }
  }

  /**
   * @private
   * @returns {?CognitoUser}
   */
  function _getCognitoUser () {

    var _cognitoUser

    if (verify.email &&
      verify.email.length > 3 &&
      BAS_UTILITIES.REGEX_EMAIL.test(verify.email)) {

      _cognitoUser = BasLiveAccount.getCognitoUser(
        verify.email.toLowerCase()
      )
    }

    if (!_cognitoUser) {

      _cognitoUser = _getCognitoUserFromLive()
    }

    return _cognitoUser || null
  }

  /**
   * @private
   * @returns {?CognitoUser}
   */
  function _getCognitoUserFromLive () {

    return $scope.live
      ? $scope.live.getToVerifyUser()
      : null
  }
}
