'use strict'

angular
  .module('basalteApp')
  .service('Lights', [
    '$rootScope',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_LIGHT',
    'BAS_SHARED_SERVER_STORAGE',
    'BasSharedServerStorage',
    'CurrentRoom',
    'RoomsHelper',
    Lights
  ])

/**
 * Keeps basRoomLights in sync
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_LIGHT} BAS_LIGHT
 * @param {BAS_SHARED_SERVER_STORAGE} BAS_SHARED_SERVER_STORAGE
 * @param {BasSharedServerStorage} BasSharedServerStorage
 * @param {CurrentRoom} CurrentRoom
 * @param {RoomsHelper} RoomsHelper
 */
function Lights (
  $rootScope,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_LIGHT,
  BAS_SHARED_SERVER_STORAGE,
  BasSharedServerStorage,
  CurrentRoom,
  RoomsHelper
) {

  /**
   * @type {BasRooms}
   */
  const rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  const currentRoom = CurrentRoom.get()

  /**
   * @type {TBasSharedServerStorageState}
   */
  const basSharedServerStorage = BasSharedServerStorage.get()

  init()

  function init () {

    if (basSharedServerStorage.lightGroupOrder) {
      _syncLightGroupOrderStorage()
    }

    $rootScope.$on(
      BAS_SHARED_SERVER_STORAGE.EVT_LIGHT_GROUP_ORDER_UPDATED,
      _syncLightGroupOrderStorage
    )

    $rootScope.$on(
      BAS_ROOM.EVT_LIGHTS_INITIALIZED,
      _syncLightGroupOrderStorage
    )

    $rootScope.$on(
      BAS_LIGHT.EVT_LIGHT_DEVICE_TOGGLE_UPDATED,
      _syncGroupToggleState
    )

    $rootScope.$on(
      BAS_LIGHT.EVT_LIGHT_DEVICE_WHITE_UPDATED,
      _syncGroupBrightnessSlider
    )

    $rootScope.$on(
      BAS_LIGHT.EVT_LIGHT_DEVICE_BRIGHTNESS_UPDATED,
      _syncGroupBrightnessSlider
    )
  }

  function _syncLightGroupOrderStorage () {

    RoomsHelper.forEachRoom(_roomOnLightGroupOrderUpdated)
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _roomOnLightGroupOrderUpdated (room) {
    if (room && room.lights && room.lights.syncLightGroupOrderFromStorage) {

      room.lights.syncLightGroupOrderFromStorage(room)
    }
  }

  function _syncGroupToggleState () {
    const basRoomLights = rooms.rooms[currentRoom.roomId]?.lights

    if (basRoomLights?.uiLightGroups) {

      for (const uiLightGroup of basRoomLights.uiLightGroups) {

        basRoomLights.updateGroupToggleStateWithWhiteSupport(uiLightGroup)
      }
    }
  }

  function _syncGroupBrightnessSlider () {
    rooms.rooms[currentRoom.roomId]?.lights?.syncGroupBrightnessSlider()
  }
}
