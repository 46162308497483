'use strict'

angular
  .module('basalteApp')
  .constant('BAS_SHARED_SERVER_STORAGE', {
    EVT_SCENE_ORDER_UPDATED:
            'evtBasSharedServerStorageSceneOrderUpdated',
    EVT_SCENE_FAVOURITES_UPDATED:
            'evtBasSharedServerStorageSceneFavouritesUpdated',
    EVT_LIGHT_GROUP_ORDER_UPDATED:
            'evtBasSharedServerStorageLightGroupOrderUpdated'
  })
