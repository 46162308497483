'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('scenesCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'ModalService',
    'BAS_API',
    'BAS_HTML',
    'UI_HELPER',
    'SCENE_STATES',
    'BAS_ROOM',
    'BAS_SCENE',
    'CurrentRoom',
    'BasSceneStateHelper',
    'BasUiSortableHelper',
    'UiHelper',
    scenesCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param ModalService
 * @param BAS_API
 * @param {BAS_HTML} BAS_HTML
 * @param {UI_HELPER} UI_HELPER
 * @param {SCENE_STATES} SCENE_STATES
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SCENE} BAS_SCENE
 * @param {CurrentRoom} CurrentRoom
 * @param {BasSceneStateHelper} BasSceneStateHelper
 * @param BasUiSortableHelper
 * @param {UiHelper} UiHelper
 */
function scenesCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  ModalService,
  BAS_API,
  BAS_HTML,
  UI_HELPER,
  SCENE_STATES,
  BAS_ROOM,
  BAS_SCENE,
  CurrentRoom,
  BasSceneStateHelper,
  BasUiSortableHelper,
  UiHelper
) {
  var scenes = this

  var CSS_IS_ROOM = 'brs-is-room'

  var _listeners = []

  /**
   * @type {BasUi}
   */
  var _basUi = UiHelper.get()

  scenes.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BasUiSortableHelper}
   */
  scenes.basUiSortableFavourites = new BasUiSortableHelper()
  scenes.basUiSortableFavourites.allowSwiping = false
  scenes.basUiSortableFavourites.allowReordening = false

  /**
   * @type {BasUiSortableHelper}
   */
  scenes.basUiSortableNonFavourites = new BasUiSortableHelper()
  scenes.basUiSortableNonFavourites.allowSwiping = false
  scenes.basUiSortableNonFavourites.allowReordening = true

  /**
   * @type {string}
   */
  scenes.uiFunctionsProperty = BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES

  /**
   * @type {Object<string, boolean>}
   */
  scenes.css = {}
  _resetCss()

  scenes.$onChanges = _onChanges
  scenes.$postLink = init

  scenes.newScene = newScene
  scenes.selectScene = selectScene
  scenes.reorderFavourites = reorderFavourites
  scenes.reorderNonFavourites = reorderNonFavourites
  scenes.openFavouriteEditor = openFavouriteEditor

  function init () {

    var room

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      UI_HELPER.EVT_RESIZE,
      _onUiSize
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_BAS_SCENE_REMOVED,
      _onSceneRemoved
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SCENES_INITIALIZED,
      _onScenes
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SCENES_UPDATED,
      _onScenesUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_SCENE.EVT_IMAGES_UPDATED,
      _onSceneImageUpdated
    ))

    _syncRoomProperties()

    room = _getRoom()

    if ($uiRouterGlobals.params.openModal === true) {

      if (room && room.isRoom()) openFavouriteEditor()
    }
  }

  function newScene () {

    var _scenes = _getScenes()

    if (_scenes) _scenes.addNewScene().then(_onNewScene)
  }

  /**
   * @param {string} sceneId
   */
  function selectScene (sceneId) {

    _stateGoScene(sceneId)
  }

  /**
   * @param {number} spliceIdx
   * @param {number} originalIdx
   */
  function reorderFavourites (spliceIdx, originalIdx) {

    var _scenes

    scenes.basUiSortableFavourites.onReorder()

    _scenes = _getScenes()

    if (_scenes) _scenes.reorderUiFavourites(spliceIdx, originalIdx)
  }

  /**
   * @param {number} spliceIdx
   * @param {number} originalIdx
   */
  function reorderNonFavourites (spliceIdx, originalIdx) {

    var _scenes

    scenes.basUiSortableNonFavourites.onReorder()

    _scenes = _getScenes()

    if (_scenes) _scenes.reorderUiNonFavourites(spliceIdx, originalIdx)
  }

  function openFavouriteEditor () {

    if (scenes.room && scenes.room.id) {

      ModalService.showModal({
        controller: 'scenePickerModalCtrl',
        controllerAs: 'modal',
        inputs: {
          room: scenes.room.id
        },
        template: BAS_HTML.scenePickerModal
      })
    }
  }

  function _onChanges () {

    if (_getScenes()) _checkCurrentScene()

    _syncRoomProperties()
  }

  function _onNewScene (scene) {

    if (scene) _stateGoScene(scene.uuid)
  }

  function _goToScenes (goBack) {

    var _scenes, _firstSceneUuid

    _scenes = _getScenes()

    if (_scenes) {

      _firstSceneUuid = _scenes.getFirstSceneUuid()
    }

    if (_basUi.prop.wMedium &&
      _firstSceneUuid) {

      _stateGoScene(_firstSceneUuid)

    } else if (goBack) {

      CurrentRoom.go(BasSceneStateHelper.getSceneState(SCENE_STATES.SCENES))
    }
  }

  function _onUiSize () {

    if (!BasUtil.isNEString($uiRouterGlobals.params.scene)) {

      _goToScenes()
    }
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasRoom} room
   */
  function _onScenes (_event, room) {

    var _room

    _room = _getRoom()

    if (room && _room && _room.id === room.id) _checkCurrentScene()
  }

  function _checkCurrentScene () {

    var _room, _scenes

    _room = _getRoom()

    if (
      _room &&
      _room.room &&
      _room.room.functions &&
      !_room.room.functions[BAS_API.Room.FUNCTIONS.SCENES]
    ) {
      BasSceneStateHelper.exitScenes()
    }

    _scenes = _getScenes()

    if (_scenes &&
      !(
        BasUtil.isNEString($uiRouterGlobals.params.scene) &&
        _scenes.uiScenes.indexOf($uiRouterGlobals.params.scene) !== -1
      )
    ) {

      _goToScenes(true)
    }
  }

  /**
   * @private
   * @param {Object} _evt
   * @param {string} uuid
   */
  function _onSceneRemoved (_evt, uuid) {

    if (BasUtil.isNEString(uuid) &&
      uuid === $uiRouterGlobals.params.scene) {

      _goToScenes(true)
    }
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasRoom} room
   */
  function _onScenesUpdated (_event, room) {

    var _room

    _room = _getRoom()

    if (room && _room && _room.id === room.id) $scope.$applyAsync()
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasRoom} room
   */
  function _onSceneImageUpdated (_event, room) {

    var _room

    _room = _getRoom()

    if (room && _room && _room.id === room.id) $scope.$applyAsync()
  }

  /**
   * Manages differences in behavior between room scenes and home scenes.
   *
   * @private
   */
  function _syncRoomProperties () {

    var room

    room = _getRoom()

    scenes.uiFunctionsProperty = BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES
    scenes.css[CSS_IS_ROOM] = false

    if (room) {

      if (scenes.room.isRoom()) {

        scenes.css[CSS_IS_ROOM] = true
        scenes.basUiSortableFavourites.allowReordening = false

      } else {

        scenes.uiFunctionsProperty = BAS_ROOM.K_PROPERTY_TILES
        scenes.basUiSortableFavourites.allowReordening = true
      }
    }
  }

  /**
   * @private
   * @param {string} sceneId
   */
  function _stateGoScene (sceneId) {

    CurrentRoom.go(
      BasSceneStateHelper.getSceneState(SCENE_STATES.SCENE),
      {
        scene: sceneId
      }
    )
  }

  /**
   * @private
   * @returns {?BasRoomScenes}
   */
  function _getScenes () {

    var room

    room = _getRoom()

    return (room && room.scenes && room.scenes.activateScene)
      ? room.scenes
      : null
  }

  /**
   * @private
   * @returns {?BasRoom}
   */
  function _getRoom () {

    return (scenes.room && scenes.room.isRoom) ? scenes.room : null
  }

  function _resetCss () {

    scenes.css[CSS_IS_ROOM] = false
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
