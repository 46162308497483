'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLibraryManager', [
    '$rootScope',
    'BasLibraryHeader',
    'BasLibraryBody',
    'BasLibraryPage',
    'BasLibraryTab',
    'BasLibraryCollection',
    'BAS_LIBRARY',
    'BasUtilities',
    'Logger',
    basLibraryManagerFactory
  ])

/**
 * @param $rootScope
 * @param BasLibraryHeader
 * @param BasLibraryBody
 * @param BasLibraryPage
 * @param BasLibraryTab
 * @param BasLibraryCollection
 * @param BAS_LIBRARY
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns BasLibraryManager
 */
function basLibraryManagerFactory (
  $rootScope,
  BasLibraryHeader,
  BasLibraryBody,
  BasLibraryPage,
  BasLibraryTab,
  BasLibraryCollection,
  BAS_LIBRARY,
  BasUtilities,
  Logger
) {
  var className = 'Bas library manager'

  /**
   * @param {PlayerLibraryState} libraryState
   * @constructor
   */
  function BasLibraryManager (libraryState) {

    /**
     * @instance
     * @type {BasLibraryHeader}
     */
    this.header = new BasLibraryHeader()

    /**
     * @instance
     * @type {BasLibraryBody}
     */
    this.body = new BasLibraryBody()

    /**
     * @instance
     * @type {PlayerLibraryState}
     */
    this.libraryState = libraryState

    /**
     * @instance
     * @type {Array<BasLibraryPage>}
     */
    this.pages = []

    /**
     * @instance
     * @type {?BasLibraryPage}
     */
    this.currentPage = null

    /**
     * @instance
     * @type {number}
     */
    this.currentPageIndex = 0

    /**
     * @instance
     * @type {string}
     */
    this.searchQuery = ''

    /**
     * @instance
     * @type {number}
     */
    this.gridView = BasLibraryCollection.VIEWS
      .indexOf(BasLibraryCollection.VIEW_GRID)

    /**
     * @instance
     * @type {BasLibraryPage}
     */
    this.notConnectedPage = null

    /**
     * @instance
     * @type {BasLibraryPage}
     */
    this.startPage = null

    /**
     * @instance
     * @type {?Selection}
     */
    this.selection = null

    /**
     * @instance
     * @type {Function}
     */
    this.handler = this.handlerFunc.bind(this)

    /**
     * @instance
     * @type {Array<Function>}
     */
    this.listeners = []

    // Set correct gridview icon
    this.header.setIconView(this.gridView)

    this.createStartPage()
    this.createNotConnectedPage()
    this.linkControlsBasic()
  }

  BasLibraryManager.prototype.linkControlsBasic = function () {

    // Header controls
    this.header.control[BasLibraryHeader.CLK_BACK] =
      this.back.bind(this)
    this.header.control[BasLibraryHeader.CLK_HOME] =
      this.home.bind(this)
    this.header.control[BasLibraryHeader.CLK_VIEW] =
      this.toggleView.bind(this)
    this.header.control[BasLibraryHeader.CLK_SEARCH] =
      this.toggleSearch.bind(this)
    this.header.control[BasLibraryHeader.CLK_SELECT] =
      this.selectionSelect.bind(this)

    // Body controls
    this.body.control[BasLibraryBody.ACTION_END_REACHED] =
      this.onReachedEnd.bind(this)
    this.body.control[BasLibraryBody.SELECT_TAB] =
      this.selectTab.bind(this)
    this.body.control[BasLibraryBody.CLK_EDIT] =
      this.toggleEdit.bind(this)
    this.body.control[BasLibraryBody.CHN_TITLE] =
      this.changeTitle.bind(this)
    this.body.control[BasLibraryBody.CLK_SELECT] =
      this.clickSelection.bind(this)

  }

  // region Control functions

  BasLibraryManager.prototype.selectTab = function (tab) {

    var _this, collection, page

    if (tab.class[BasLibraryTab.CLASS_TAB_ACTIVE]) return

    _this = this

    collection = tab.collection
    page = this.currentPage

    if (collection.elements.length === 0) {

      // Select tab
      tab.setSelectedTab(true)

      $rootScope.$applyAsync()

      // Return promise after elements are received
      collection.retrieve().then(onSuccess, onFail)

    } else {

      // Set properties
      setProperties()

      // Wait one frame to set new scrollPos
      BasUtilities.waitFrames(2)
        .then(this.pageChecks.bind(this))
    }

    function onSuccess () {

      // Set properties
      setProperties()

      // Reset scroll position after waiting one frame
      BasUtilities.waitFrames(2)
        .then(_this.resetScrollPercentage.bind(_this))

      // Make sure new content is loaded
      BasUtilities.waitFrames(10)
        .then(_this.checkPageFilled.bind(_this))
    }

    function onFail () {

      // Deselect this tab
      tab.setSelectedTab(false)

      // Check page for no content
      page.checkContent()
      $rootScope.$applyAsync()
    }

    /**
     * Will trigger a $rootScope.$applyAsync()
     */
    function setProperties () {

      // Disable active tab
      page.clearTabsClass()

      // Set scroll percentage
      page.setScrollPercent(_this.getScrollPercentage())

      // Deselect this tab
      tab.setSelectedTab(false)

      // Activate this tab
      tab.setActiveTab(true)

      // Set grid view property
      collection.setGridView(page.collections[0].gridView)

      // Set collection
      page.collections[0] = collection

      // Set title
      if (!page.lockSubtitle) {
        page.setSubtitle(BasUtilities.translate(tab.collection.titleNameId))
      }

      // Check page for content
      page.checkContent()

      // Sync gridview header
      _this.syncPagesUi()
    }
  }

  /**
   * @param {BasLibraryPage} page
   * @param {boolean} [retrieve]
   */
  BasLibraryManager.prototype.addPage = function (
    page,
    retrieve
  ) {
    var _this = this

    if (!this.isNotConnectedPageShowing()) {

      if (BasUtil.isObject(page)) {

        // Set scroll percentage of current page
        if (BasUtil.isObject(this.currentPage)) {
          this.currentPage.setScrollPercent(
            this.getScrollPercentage()
          )
        }

        // Set body classes
        this.addPageClass()

        if (this.pages.length > 0) {

          // Suspend the previous page
          this.pages[this.pages.length - 1].suspend()
        }

        // Add new page
        this.pages.push(page)

        if (retrieve) {

          // Check current page
          if (BasUtil.isObject(this.currentPage)) {

            // Set loading state
            this.currentPage
              .setState(BasLibraryPage.STATE_OVERLAY_LOADING)
          }

          // Start retrieve for new page
          page.retrieve().then(onPage, onPageError)

        } else {
          this.syncPagesUi()
        }
      }
    } else {
      Logger.warn(className + ' - Add page - Not connected page is showing')
    }

    function onPage () {

      // Check current page
      if (BasUtil.isObject(_this.currentPage)) {

        // Sync state for the "old" currentPage
        _this.currentPage.syncState()
      }

      // Set new page
      _this.syncPagesUi()

      // Set grid view
      page.setCollectionGridView(_this.gridView)

      BasUtilities.waitFrames(10)
        .then(_this.checkPageFilled.bind(_this))

      // Sync state for the new page
      page.syncState()

      // Check selected
      _this.checkAllSelected()
    }

    function onPageError (error) {

      Logger.warn(className + ' - addPage- onPage ERROR', error)

      // Check current page
      if (BasUtil.isObject(_this.currentPage)) {

        // Sync state for the "old" currentPage
        _this.currentPage.syncState()
      }

      // Show error message
      page.setState(BasLibraryPage.STATE_ERROR)

      // Set the new page as current page
      _this.syncPagesUi()
    }
  }

  BasLibraryManager.prototype.toggleEdit = function () {

    // Get collection
    var collection = this.currentPage.getCollection(0)

    // Get promise of retrieveAll if you want to edit
    var promise = collection.toggleEdit()

    // Check promise
    if (BasUtil.isObject(promise)) {

      // Update buttons after all data has been retrieved
      promise.then(_syncScope)

    } else {

      // Check for title change
      this.changeTitle()
    }
  }

  BasLibraryManager.prototype.changeTitle = function () {

    var _this, collection, title, id, detail, element

    _this = this

    collection = this.currentPage.getCollection(0)

    // Check if collection exists
    if (BasUtil.isObject(collection) &&
      collection.isEditing) {
      detail = collection.detail
      element = collection.detailElement

      // Check if detail & element exist
      if (BasUtil.isObject(detail) &&
        BasUtil.isObject(element) &&
        collection.editTitle !== detail.title) {

        // Set title and id
        title = detail.title
        id = element.uri ? element.uri : element.id

        // Push title changes
        this.changeTitleRequest(title, id).then(onSuccess)
      } else {
        collection.setIsEditing(false)
      }
    } else {
      collection.setIsEditing(false)
    }

    function onSuccess () {

      // No longer editing
      collection.setIsEditing(false)

      // Change title of local element after a successful request
      element.title = title

      // Set title of current page
      _this.currentPage.setSubtitle(title)

      // Set title in header
      _this.syncPagesUi()
    }
  }

  // noinspection JSUnusedLocalSymbols
  /**
   * @abstract
   * @param {string} _title
   * @param {string} _id
   * @returns Promise
   */
  BasLibraryManager.prototype.changeTitleRequest = function (
    _title,
    _id
  ) {
    Logger.warn(className + ' - changeTitleRequest NOT IMPLEMENTED')
    return Promise.reject('NOT IMPLEMENTED')
  }

  /**
   * @param {TidalElement} element
   */
  BasLibraryManager.prototype.clickSelection = function (element) {
    var _this, selections, length, i, elements

    if (!BasUtil.isObject(this.selection)) return

    _this = this
    selections = this.selection.selections

    if (BasUtil.isObject(element)) {

      // (De)select element
      this.selection.select(element)

      // Check all selection state
      if (selections.collection &&
        !selections.elements[element.getSelectionKey()]) {

        selections.collection = false

      } else {

        this.checkAllSelected()

      }
    } else {

      this.currentPage.collections[0].setLoadSelectionEditing(true)
      this.currentPage.collections[0].retrieveAll()
        .then(onAllRetrieved, onAllRetrieved)
    }

    function onAllRetrieved () {

      // Inverse all selected
      selections.collection = !selections.collection
      _this.currentPage.collections[0].setLoadSelectionEditing(false)

      // Get all elements of collection
      elements = _this.currentPage.collections[0].elements
      for (i = 0, length = elements.length; i < length; i += 1) {

        // Give all elements the selection value of the collection
        _this.selection.select(elements[i], selections.collection)
      }

      $rootScope.$applyAsync()
    }
  }

  BasLibraryManager.prototype.checkAllSelected = function () {

    var selections, length, i, elements

    if (BasUtil.isObject(this.selection)) {

      selections = this.selection.selections

      // Set all selected false
      selections.collection = false

      if (BasUtil.isObject(this.currentPage.collections[0])) {
        elements = this.currentPage.collections[0].elements
        for (i = 0, length = elements.length; i < length; i += 1) {

          // Check whether element is not selected
          if (!BasUtil.isObject(
            selections.elements[elements[i].getSelectionKey()]
          )) {
            return
          }
        }

        // Check whether collection had elements
        if (length > 0) {
          // Set all selected true
          selections.collection = true
        }
      }
    }
  }

  /**
   * Gets called when the library scroll reached the end
   *
   * @returns {Promise | undefined}
   */
  BasLibraryManager.prototype.onReachedEnd = function () {

    if (BasUtil.isObject(this.currentPage)) {

      return this.currentPage.onReachedEnd()
    }

    return Promise.reject('No current page')
  }

  // endregion

  // region Header controls

  /**
   *
   * @param {string} event
   * @param {Object} [_params]
   */
  BasLibraryManager.prototype.handlerFunc = function (
    event,
    _params
  ) {
    switch (event) {
      case BAS_LIBRARY.EVT_PLAYLIST_CHANGED:
        this.evtPlaylistChanged()
        break
      default:
        Logger.warn(className + ' - handlerFunc - Event unknown: ' + event)
    }
  }

  BasLibraryManager.prototype.evtPlaylistChanged = function () {
    Logger.warn(className + ' - evtPlaylistChanged NOT IMPLEMENTED')
  }

  /**
   * @abstract
   */
  BasLibraryManager.prototype.search = function () {
    Logger.warn(className + ' - Search NOT IMPLEMENTED')
  }

  BasLibraryManager.prototype.selectionSelect = function (event) {

    this.selectMenu(
      event,
      this.selection,
      this.handler
    )
  }

  /**
   * @abstract
   * @param _event
   * @param _selection
   * @param _handler
   */
  BasLibraryManager.prototype.selectMenu = function (
    _event,
    _selection,
    _handler
  ) {
    Logger.warn(className + ' - selectMenu NOT IMPLEMENTED')
  }

  /**
   * @abstract
   */
  BasLibraryManager.prototype.toggleSearch = function () {
    Logger.warn(className + ' - Toggle search NOT IMPLEMENTED')
  }

  /**
   * Will trigger a $rootScope.$applyAsync()
   */
  BasLibraryManager.prototype.toggleView = function () {

    var length
    var page = this.currentPage

    if (BasUtil.isObject(page) &&
      BasUtil.isObject(page.collections[0])) {

      // Save current scroll percentage
      page.setScrollPercent(this.getScrollPercentage())

      // Flip gridview
      length = BasLibraryCollection.VIEWS.length
      this.gridView = (this.gridView + 1) % length

      // Set the new gridview
      page.setCollectionGridView(this.gridView)

      // Set correct gridview icon
      this.header.setIconView(this.gridView)

      // Wait for new elements to load and set proper scroll height
      // Which apparently takes 2 frames
      BasUtilities.waitFrames(2)
        .then(this.pageChecks.bind(this))
    }
  }

  /**
   * Checks scroll height and page content
   */
  BasLibraryManager.prototype.pageChecks = function () {

    var _this = this

    $rootScope.$applyAsync(function () {
      _this.setScrollPos(_this.currentPage.getScrollPercent())
      BasUtilities.waitFrames(10)
        .then(_this.checkPageFilled.bind(_this))
    })
  }

  BasLibraryManager.prototype.checkPageFilled = function () {

    var _this = this
    var collection = this.currentPage.getCollection(0)

    if (BasUtil.isObject(collection) &&
      !collection.hasReachedEnd &&
      !this.isPageFilled()) {

      collection.onReachedEnd().then(onRetrieved, _ignore)
    }

    function onRetrieved () {
      $rootScope.$applyAsync()
      BasUtilities.waitFrames(5)
        .then(_this.checkPageFilled.bind(_this))
    }
  }

  /**
   * Discard all pages and go to start page
   */
  BasLibraryManager.prototype.home = function () {

    // Make sure to close the search
    this.header.toggleSearchInput(false)

    // Go to start
    this.goToStart()
  }

  BasLibraryManager.prototype.goToStart = function () {
    var lastIndex

    if (!this.isNotConnectedPageShowing()) {

      // Get the last index
      lastIndex = this.pages.length - 1

      // Set body classes
      this.removePageClass()

      while (lastIndex > 0) {

        // Call destructor of current page
        this.pages[lastIndex].destroy()

        // Remove page
        this.pages.pop()

        // Get new last index
        lastIndex = this.pages.length - 1
      }

      this.syncPagesUi()

    } else {
      Logger.warn(className + ' - Go to start - Not connected page is showing')
    }
  }

  /**
   * Go back one page
   */
  BasLibraryManager.prototype.back = function () {

    // Make sure to close the search
    this.header.toggleSearchInput(false)

    // Go back one page
    this.returnPage()
  }

  /**
   * Remove a page
   */
  BasLibraryManager.prototype.returnPage = function () {

    var lastIndex

    if (!this.isNotConnectedPageShowing()) {

      lastIndex = this.pages.length - 1

      // Check if page can go back
      if (lastIndex > 0) {

        this.removePageClass()

        // Call destructor of current page
        this.pages[lastIndex].destroy()

        // Remove page
        this.pages.pop()
      }

      // Set correct currentPage
      this.syncPagesUi()

      // Resume current page
      this.currentPage.resume()

      // Check for full page selection
      this.checkAllSelected()

      // Set the grid view
      this.currentPage.setCollectionGridView(this.gridView)

      // Check translation changes
      if (BasUtil.isObject(this.currentPage)) {
        this.currentPage.onTranslate()
      }

      // Sync possible translation changes to header
      this.syncPagesUi()

      // Update scrollPos after waiting for elements to load,
      // but try once anyways
      this.pageChecks()

      // Check whether page content doesn't need an update
      this.refreshPage()

    } else {
      Logger.warn(className + ' - Return page - Not connected page is showing')
    }
  }

  BasLibraryManager.prototype.refreshPage = function () {
    if (this.currentPage.dirty) {
      this.currentPage.refresh()
    }
  }

  // endregion

  // region Helper functions

  BasLibraryManager.prototype.getScrollPercentage = function () {

    if (this.body.control[BasLibraryBody.FUNC_GET_SCROLL_PERCENT]) {
      return this.body.control[BasLibraryBody.FUNC_GET_SCROLL_PERCENT]()
    }

    return 0
  }

  BasLibraryManager.prototype.resetScrollPercentage = function () {

    if (this.body.control[BasLibraryBody.FUNC_RESET_SCROLL_POS]) {
      return this.body.control[BasLibraryBody.FUNC_RESET_SCROLL_POS]()
    }
  }

  /**
   * @param {number} percentage
   * @returns {boolean}
   */
  BasLibraryManager.prototype.setScrollPercentage = function (percentage) {

    if (this.body.control[BasLibraryBody.FUNC_SET_SCROLL_POS]) {
      return this.body
        .control[BasLibraryBody.FUNC_SET_SCROLL_POS](percentage)
    }

    return false
  }

  /**
   * @returns {boolean}
   */
  BasLibraryManager.prototype.isPageFilled = function () {

    if (this.body.control[BasLibraryBody.FUNC_IS_PAGE_FILLED]) {
      return this.body.control[BasLibraryBody.FUNC_IS_PAGE_FILLED]()
    }

    return false
  }

  BasLibraryManager.prototype.createNotConnectedPage = function () {

    // Create new page
    this.notConnectedPage = new BasLibraryPage(this.handler)

    // Page content
    this.notConnectedPage.setContent(BasLibraryPage.CONTENT_NOT_CONNECTED)
  }

  BasLibraryManager.prototype.createStartPage = function () {
    this.startPage = new BasLibraryPage(this.handler)
  }

  /**
   * Syncs the header and current page with UI
   * Will trigger a $rootScope.$applyAsync()
   */
  BasLibraryManager.prototype.syncPagesUi = function () {
    this.syncCurrentPage()
    this.syncHeaderPage()
    $rootScope.$applyAsync()
  }

  BasLibraryManager.prototype.syncHeaderPage = function () {
    if (BasUtil.isObject(this.currentPage)) {

      // Set header title and subtitle
      this.header.setTitle(this.currentPage.title)
      this.header.setSubtitle(this.currentPage.subtitle)

      // Set header grid view property
      if (BasUtil.isObject(this.currentPage.collections[0])) {
        this.header.enableGridView(
          this.currentPage.collections[0].canGridView
        )
      } else {
        this.header.enableGridView(false)
      }
    }

    // Enable/disable navigation buttons
    this.header.toggleNavigation(this.pages.length > 1)
  }

  BasLibraryManager.prototype.syncCurrentPage = function () {
    if (this.pages.length > 0) {
      this.currentPageIndex = this.pages.length - 1
      this.currentPage = this.pages[this.currentPageIndex]
    } else {
      this.currentPageIndex = 0
      this.currentPage = null
    }
  }

  BasLibraryManager.prototype.addPageClass = function () {
    this.body.setClass(BasLibraryBody.CLASS_ADD, true)
    this.body.setClass(BasLibraryBody.CLASS_REMOVE, false)
  }

  BasLibraryManager.prototype.removePageClass = function () {
    this.body.setClass(BasLibraryBody.CLASS_ADD, false)
    this.body.setClass(BasLibraryBody.CLASS_REMOVE, true)
  }

  BasLibraryManager.prototype.isNotConnectedPageShowing = function () {
    return (
      this.pages.length > 0 &&
      BasUtil.isObject(this.pages[this.pages.length - 1]) &&
      this.pages[this.pages.length - 1].content ===
      BasLibraryPage.CONTENT_NOT_CONNECTED
    )
  }

  BasLibraryManager.prototype.addNotConnectedPage = function () {
    if (!this.isNotConnectedPageShowing()) {

      this.header.enableNavigation(false)
      this.header.enableSearch(false)

      this.home()

      this.removePageClass()

      this.pages.push(this.notConnectedPage)

      // Sync with new page
      this.syncPagesUi()
    }
  }

  BasLibraryManager.prototype.removeNotConnectedPage = function () {
    if (this.isNotConnectedPageShowing()) {

      this.header.enableNavigation(true)
      this.header.enableSearch(true)

      this.addPageClass()

      // Call destructor of current page
      this.pages[this.pages.length - 1].destroy()

      // Remove page
      this.pages.pop()

      // Sync with new page
      this.syncPagesUi()

      // Check new page's state
      if (this.currentPage) this.currentPage.syncState()
    }
  }

  /**
   * Sets the scrollPos of the current page and keeps executing itself until
   * the position is the same twice in a row
   *
   * Will trigger a $rootScope.$applyAsync()
   *
   * @param {number} percentage
   */
  BasLibraryManager.prototype.setScrollPos = function (percentage) {

    var _this, count

    _this = this
    count = 0

    // Execute once
    onScrollPos(count)

    function onScrollPos () {
      // Set scroll position
      if (count < 5 && !_this.setScrollPercentage(percentage)) {

        count++
        BasUtilities.waitFrames(2)
          .then(onScrollPos.bind(_this, percentage))
      }
      $rootScope.$applyAsync()
    }

  }

  // endregion

  // region Resume & Suspend

  BasLibraryManager.prototype.suspend = function () {
    var collection

    Logger.debug(className + ' - Suspend')

    // Set scroll percentage
    if (BasUtil.isObject(this.currentPage) &&
      BasUtil.isObject(this.currentPage.getCollection(0))) {
      this.currentPage.setScrollPercent(this.getScrollPercentage())
    }

    // Clear listeners
    BasUtil.executeArray(this.listeners)
    this.listeners = []

    // Suspend the current page
    if (BasUtil.isObject(this.currentPage)) {

      this.currentPage.suspend()
      collection = this.currentPage.getCollection(0)
      if (BasUtil.isObject(collection) && collection.isEditing) {
        this.toggleEdit()
      }
    }
  }

  /**
   * @abstract
   */
  BasLibraryManager.prototype.resume = function () {
    Logger.warn(className + ' - resume NOT IMPLEMENTED')
  }

  BasLibraryManager.prototype.onTranslationChange = function () {

    var i, length

    this.createStartPage()
    this.pages[0] = this.startPage

    length = this.pages.length
    for (i = 0; i < length; i++) {
      this.pages[i].onTranslate()
    }

    this.syncHeaderPage()
    $rootScope.$applyAsync()
  }

  // endregion

  return BasLibraryManager

  function _syncScope () {

    $rootScope.$applyAsync()
  }

  function _ignore () {
    // Empty
  }
}
