'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceVolume', deviceVolume)

function deviceVolume () {

  return {
    restrict: 'AE',
    template: '<div class="' +
      'bbs bbs-item bbs-centered bbs-flex bbs-active-select"' +
      ' bas-click="volume.toggleMute()">' +
      '<div class="bbs-name ellipsis-overflow"' +
      ' data-translate="do_not_disturb"></div>' +
      '<bas-toggle class="bbs-toggle bas-toggle-dark"' +
      ' data-bas-toggle="volume.toggleMute()"' +
      ' data-bas-toggled="volume.volumeState.uiMuted"></bas-toggle>' +
      '</div>' +
      '<div class="' +
      'bbs bbs-item bbs-centered">' +
      '<div class="bbs-name" data-translate="device_volume"></div>' +
      '<div class="bbs-slider-wrapper">' +
      '<bas-slider class="bbs-slider bas-slider-dark"' +
      ' data-slider-model-object="volume"' +
      ' data-key="volume"' +
      ' data-slider-changed="volume.volumeChange()"></bas-slider>' +
      '<div class="bbs-slider-value" ng-bind="volume.volume"></div>' +
      '<div/>' +
      '</div>',
    controller: [
      '$rootScope',
      '$scope',
      'BAS_VOLUME',
      'BasVolume',
      controller
    ],
    controllerAs: 'volume'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_VOLUME} BAS_VOLUME
   * @param {BasVolume} BasVolume
   */
  function controller (
    $rootScope,
    $scope,
    BAS_VOLUME,
    BasVolume
  ) {

    var volume = this

    var _listeners = []

    /**
     * @type {TBasVolumeState}
     */
    volume.volumeState = BasVolume.get()

    volume.volume = 0

    volume.volumeChange = volumeChange
    volume.toggleMute = toggleMute

    volume.$onDestroy = _destroy

    init()

    function init () {

      _listeners.push($rootScope.$on(
        BAS_VOLUME.EVT_VOLUME_CHANGED,
        _onVolumeChanged
      ))

      _syncVolume()
    }

    function volumeChange () {

      var _volume

      _volume = volume.volume

      BasVolume.setVolume(
        _volume,
        {
          audioFeedback: true
        }
      )
    }

    function toggleMute () {

      BasVolume.toggleMute()
    }

    function _syncVolume () {

      volume.volume = volume.volumeState.uiVolume
    }

    function _onVolumeChanged () {

      _syncVolume()
      _syncScope()
    }

    function _syncScope () {

      $scope.$applyAsync()
    }

    function _destroy () {

      BasUtil.executeArray(_listeners)
      _listeners = null
    }
  }
}
