'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('Sources', [
    '$rootScope',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_ROOMS',
    'CurrentBasCore',
    'SourcesHelper',
    'BasSourceHelper',
    'BasSource',
    'BasCollection',
    Sources
  ])

/**
 * @constructor
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentBasCore} CurrentBasCore
 * @param {SourcesHelper} SourcesHelper
 * @param {BasSourceHelper} BasSourceHelper
 * @param BasSource
 * @param BasCollection
 */
function Sources (
  $rootScope,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_ROOMS,
  CurrentBasCore,
  SourcesHelper,
  BasSourceHelper,
  BasSource,
  BasCollection
) {

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  this.clear = clear
  this.registerFor = registerFor
  this.unregisterFor = unregisterFor
  this.getBasSource = getBasSource

  init()

  function init () {

    _clear()

    // Current Core events
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_AV_SOURCES_RECEIVED,
      _onAVSourcesReceived
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _onMusicReceived
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_ROOMS_RECEIVED,
      _onRoomsReceived
    )

    // Source events
    $rootScope.$on(
      BAS_SOURCE.EVT_CONNECTED,
      _onSourceConnected
    )
    $rootScope.$on(
      BAS_SOURCE.EVT_SPOTIFY_LINK_CHANGED,
      _onLinkUrlChanged
    )

    // Room events
    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    )

    // App config changes
    $rootScope.$on(
      '$translateChangeSuccess',
      _onTranslate
    )
  }

  /**
   * Clears all sources but does not remove angular events
   */
  function clear () {

    _clearData()
  }

  /**
   * Returns a BasSource for the specific ID.
   * Will return a Barp BasSource if the Barp is active for the given ID.
   * Will return a BasSource off type "unknown" if not found.
   *
   * @param {(number|string)} id
   * @returns {BasSource}
   */
  function getBasSource (id) {

    var source = SourcesHelper.getBasSource(id)

    if (source) return source

    if (BasUtil.isVNumber(id)) return new BasSource(id)

    return new BasSource()
  }

  /**
   * Look up the sources based on their UUID
   *
   * @param {string} a
   * @param {string} b
   *
   * @returns {number}
   */
  function compareSources (a, b) {

    return BasSource.compare(
      BAS_SOURCES.SOURCES.sources[a],
      BAS_SOURCES.SOURCES.sources[b]
    )
  }

  /**
   * Look up the Player source that goes with the Spotify Source,
   * then compare based on sequence
   *
   * @param {string} a
   * @param {string} b
   * @returns {number}
   */
  function compareSpotifySources (a, b) {

    var aB, bB, aP, bP

    aB = BAS_SOURCES.SOURCES.sources[a]
    bB = BAS_SOURCES.SOURCES.sources[b]

    if (aB && bB) {

      aP = SourcesHelper.getPlayer(aB.id)
      bP = SourcesHelper.getPlayer(bB.id)

      if (aP && bP) {

        return BasSource.compare(aP, bP)
      }
    }

    return 0
  }

  /**
   * Checks all players for KNX presets
   *
   * @returns {Promise}
   */
  function allKNXPresets () {

    var sources, keys, i, length, source, promises

    promises = []

    sources = BAS_SOURCES.SOURCES

    if (CurrentBasCore.hasAVFullSupport()) {

      keys = Object.keys(sources.audioSources)
      length = keys.length
      for (i = 0; i < length; i++) {

        source = sources.sources[sources.audioSources[keys[i]]]

        if (
          source &&
          source.knxPresets
        ) {
          promises.push(source.knxPresets.retrievePresets())
        }
      }
    } else {

      keys = Object.keys(sources.players)
      length = keys.length
      for (i = 0; i < length; i++) {

        source = sources.sources[sources.players[keys[i]]]

        if (
          source &&
          source.type === BAS_SOURCE.T_PLAYER &&
          source.knxPresets
        ) {
          promises.push(source.knxPresets.retrievePresets())
        }
      }
    }

    return BasUtil.promiseAll(promises)
  }

  /**
   * Register interest in an event collection
   *
   * @param {(string|string[])} key
   * @param {(number|string|number[]|string[])} [sourceId]
   */
  function registerFor (key, sourceId) {

    var events, length, i

    events = BAS_SOURCES.SOURCES.events

    if (BasUtil.isNEString(key)) {

      _registerFor(key)

      _setEventCollections()

    } else if (Array.isArray(key)) {

      length = key.length
      for (i = 0; i < length; i++) {

        _registerFor(key[i])
      }

      _setEventCollections()
    }

    function _registerFor (eventKey) {

      var _length, _i, prevKnxPresets, _sourceId

      if (eventKey in events.all) {

        if (Array.isArray(sourceId)) {

          _length = sourceId.length
          for (_i = 0; _i < _length; _i++) {

            _sourceId = sourceId[_i]

            if (
              BasUtil.isNEString(_sourceId) ||
              BasUtil.isPNumber(_sourceId)
            ) {
              _registerForSource(_sourceId, eventKey)
            }
          }

        } else if (
          BasUtil.isNEString(sourceId) ||
          BasUtil.isPNumber(sourceId)
        ) {

          _registerForSource(sourceId, eventKey)

        } else {

          // Store current KNX presets events count
          prevKnxPresets = events.all[BAS_SOURCE.COL_EVT_KNX_PRESETS]

          events.all[eventKey]++

          // Check if all KNX presets need to be retrieved
          if (
            prevKnxPresets === 0 &&
            events.all[BAS_SOURCE.COL_EVT_KNX_PRESETS] > 0
          ) {
            loadKNXPresetsSources()
          }
        }
      }
    }

    function _registerForSource (id, eventKey) {

      if (!events.sources[id]) {

        events.sources[id] = {}
        BasUtil.setProperties(
          events.sources[id],
          Object.keys(events.all),
          0
        )
      }

      events.sources[id][eventKey]++
    }
  }

  /**
   * Unregister interest in an event collection
   *
   * @param {(string|string[])} key
   * @param {(number|string|number[]|string[])} [sourceId]
   */
  function unregisterFor (key, sourceId) {

    var events, length, i

    events = BAS_SOURCES.SOURCES.events

    if (BasUtil.isNEString(key)) {

      _unregisterFor(key)

      _setEventCollections()

    } else if (Array.isArray(key)) {

      length = key.length
      for (i = 0; i < length; i++) {

        _unregisterFor(key[i])
      }

      _setEventCollections()
    }

    function _unregisterFor (eventKey) {

      var _length, _i, _sourceId

      if (eventKey in events.all) {

        if (Array.isArray(sourceId)) {

          _length = sourceId.length
          for (_i = 0; _i < _length; _i++) {

            _sourceId = sourceId[_i]

            if (
              BasUtil.isNEString(_sourceId) ||
              BasUtil.isPNumber(_sourceId)
            ) {
              _unregisterForSource(_sourceId, eventKey)
            }
          }

        } else if (
          BasUtil.isString(sourceId) ||
          BasUtil.isNumber(sourceId)
        ) {

          _unregisterForSource(sourceId, eventKey)

        } else {

          events.all[eventKey]--

          if (events.all[eventKey] < 0) events.all[eventKey] = 0
        }

        _setEventCollections()
      }
    }

    function _unregisterForSource (id, eventKey) {

      if (
        events.sources[id] &&
        BasUtil.isPNumber(events.sources[id][eventKey])
      ) {
        events.sources[id][eventKey]--
      }
    }
  }

  /**
   * Sets the Sources event collections for all sources
   *
   * @private
   */
  function _setEventCollections () {

    if (BAS_SOURCES.SOURCES.events) {

      const events = BAS_SOURCES.SOURCES.events

      for (const uuid of Object.keys(BAS_SOURCES.SOURCES.sources)) {

        const source = BAS_SOURCES.SOURCES.sources[uuid]

        if (source) {

          const sourceId = source.getId()

          if (events.sources[sourceId]) {

            // Merge all events with source events
            source.setEventCollections(BasUtil.mergeCounterObjects(
              events.all,
              events.sources[sourceId]
            ))

          } else {

            source.setEventCollections(events.all)
          }
        }
      }
    }
  }

  function _syncApi () {

    var _changed, sources, keys, i, length, source, basSource, parseResult
    var j, lengthJ, sourceJ, cobraNetId
    var sourceUuidsToKeep, uuidsToKeep, uuidsToKeep2, hasAVPartialSupport
    var spotifyUuidsToKeep

    _changed = false

    if (CurrentBasCore.hasCore()) {

      sourceUuidsToKeep = [
        BAS_SOURCE.ID_EMPTY,
        BAS_SOURCE.ID_MIXED,
        BAS_SOURCE.ID_AV_INPUT_NONE,
        BAS_SOURCE.ID_AV_INPUT_UNKNOWN
      ]

      hasAVPartialSupport = CurrentBasCore.hasAVPartialSupport()

      if (
        CurrentBasCore.hasAVPartialSupport() ||
        CurrentBasCore.hasAVFullSupport()
      ) {

        // Audio sources

        sources = currentBasCoreState.core.core.audioSources

        uuidsToKeep = []
        spotifyUuidsToKeep = []

        keys = Object.keys(sources)
        length = keys.length
        for (i = 0; i < length; i++) {

          source = sources[keys[i]]

          if (BasUtil.isObject(source)) {

            if (
              source instanceof BAS_API.AudioSource &&
              source.type !== BAS_API.AudioSource.T_SONOS &&
              hasAVPartialSupport
            ) {

              // Only Sonos AV is supported, and this source is not Sonos
              //  Ignore!!!
              continue
            }

            parseResult = BasSource.parseOrDestroy(
              BAS_SOURCES.SOURCES.sources[source.uuid],
              source
            )

            basSource = parseResult.basSource
            if (parseResult.changed) _changed = true

            cobraNetId = _getCobraNetIDForUuid(source.uuid)
            if (cobraNetId > 0) basSource.setCobraNetId(cobraNetId)

            BAS_SOURCES.SOURCES.sources[source.uuid] = basSource
            BAS_SOURCES.SOURCES.audioSources[source.uuid] = source.uuid

            if (basSource.spotify) {

              BAS_SOURCES.SOURCES.spotifyAudioSources[source.uuid] = source.uuid
              spotifyUuidsToKeep.push(source.uuid)
            }

            if (CurrentBasCore.hasAVFullSupport()) {

              if (cobraNetId > 0) {

                BAS_SOURCES.SOURCES.players[cobraNetId] = source.uuid
              }
            }

            uuidsToKeep.push(source.uuid)
            sourceUuidsToKeep.push(source.uuid)
          }
        }

        BAS_SOURCES.SOURCES.audioSources = BasUtil.keepKeys(
          BAS_SOURCES.SOURCES.audioSources,
          uuidsToKeep
        )

        BAS_SOURCES.SOURCES.spotifyAudioSources = BasUtil.keepKeys(
          BAS_SOURCES.SOURCES.spotifyAudioSources,
          spotifyUuidsToKeep
        )
      }

      if (CurrentBasCore.hasAVFullSupport()) {

        // Video Sources

        sources = currentBasCoreState.core.core.videoSources

        uuidsToKeep = []

        keys = Object.keys(sources)
        length = keys.length
        for (i = 0; i < length; i++) {

          source = sources[keys[i]]

          if (BasUtil.isObject(source)) {

            parseResult = BasSource.parseOrDestroy(
              BAS_SOURCES.SOURCES.sources[source.uuid],
              source
            )

            basSource = parseResult.basSource
            if (parseResult.changed) _changed = true

            BAS_SOURCES.SOURCES.sources[source.uuid] = basSource
            BAS_SOURCES.SOURCES.videoSources[source.uuid] = source.uuid

            uuidsToKeep.push(source.uuid)
            sourceUuidsToKeep.push(source.uuid)
          }
        }

        BAS_SOURCES.SOURCES.videoSources = BasUtil.keepKeys(
          BAS_SOURCES.SOURCES.videoSources,
          uuidsToKeep
        )

      } else {

        // No or partial (Sonos) AV support, still parse legacy sources

        // Players

        sources = currentBasCoreState.core.core.players

        uuidsToKeep = []

        keys = Object.keys(sources)
        length = keys.length
        for (i = 0; i < length; i++) {

          source = sources[keys[i]]

          if (BasUtil.isObject(source)) {

            // Legacy
            if (source.database && !source.database.contentChecked) {

              source.database.checkContent().then(_ignore, _ignore)
            }

            parseResult = BasSource.parseOrDestroy(
              BAS_SOURCES.SOURCES.sources[source.uuid],
              source
            )

            basSource = parseResult.basSource
            if (parseResult.changed) _changed = true

            BAS_SOURCES.SOURCES.sources[source.uuid] = basSource
            BAS_SOURCES.SOURCES.players[source.id] = source.uuid

            uuidsToKeep.push('' + source.id)
            sourceUuidsToKeep.push(source.uuid)
          }
        }

        BAS_SOURCES.SOURCES.players = BasUtil.keepKeys(
          BAS_SOURCES.SOURCES.players,
          uuidsToKeep
        )

        // Barps

        sources = currentBasCoreState.core.core.barps

        uuidsToKeep = []
        uuidsToKeep2 = []

        keys = Object.keys(sources)
        length = keys.length
        for (i = 0; i < length; i++) {

          source = sources[keys[i]]

          if (Array.isArray(source)) {

            // Create array for CobraNet ID
            BAS_SOURCES.SOURCES.barps[keys[i]] = []

            lengthJ = source.length
            for (j = 0; j < lengthJ; j++) {

              sourceJ = source[j]

              if (BasUtil.isObject(sourceJ)) {

                // Legacy
                if (sourceJ && sourceJ.dirty && sourceJ.status) {

                  sourceJ.status.then(_ignore, _ignore)
                }

                parseResult = BasSource.parseOrDestroy(
                  BAS_SOURCES.SOURCES.sources[sourceJ.uuid],
                  sourceJ
                )

                basSource = parseResult.basSource
                if (parseResult.changed) _changed = true

                BAS_SOURCES.SOURCES.sources[sourceJ.uuid] =
                  basSource
                BAS_SOURCES.SOURCES.barps[keys[i]]
                  .push(sourceJ.uuid)

                if (basSource.subType === BAS_SOURCE.ST_SPOTIFY) {

                  BAS_SOURCES.SOURCES.spotify[keys[i]] =
                    sourceJ.uuid

                  uuidsToKeep2.push('' + keys[i])
                }

                uuidsToKeep.push('' + keys[i])
                sourceUuidsToKeep.push(sourceJ.uuid)
              }
            }

          } else if (BasUtil.isObject(source)) {

            // Legacy
            if (source && source.dirty && source.status) {

              source.status.then(_ignore, _ignore)
            }

            parseResult = BasSource.parseOrDestroy(
              BAS_SOURCES.SOURCES.sources[source.uuid],
              source
            )

            basSource = parseResult.basSource
            if (parseResult.changed) _changed = true

            BAS_SOURCES.SOURCES.sources[source.uuid] = basSource
            BAS_SOURCES.SOURCES.barps[source.id] = source.uuid

            if (basSource.subType === BAS_SOURCE.ST_SPOTIFY) {

              BAS_SOURCES.SOURCES.spotify[source.id] = source.uuid
              uuidsToKeep2.push('' + source.id)
            }

            uuidsToKeep.push('' + source.id)
            sourceUuidsToKeep.push(source.uuid)
          }
        }

        BAS_SOURCES.SOURCES.barps = BasUtil.keepKeys(
          BAS_SOURCES.SOURCES.barps,
          uuidsToKeep
        )
        BAS_SOURCES.SOURCES.spotify = BasUtil.keepKeys(
          BAS_SOURCES.SOURCES.spotify,
          uuidsToKeep2
        )

        // Externals

        sources = currentBasCoreState.core.core.externals

        uuidsToKeep = []

        keys = Object.keys(sources)
        length = keys.length
        for (i = 0; i < length; i++) {

          source = sources[keys[i]]

          if (BasUtil.isObject(source)) {

            parseResult = BasSource.parseOrDestroy(
              BAS_SOURCES.SOURCES.sources[source.uuid],
              source
            )

            basSource = parseResult.basSource
            if (parseResult.changed) _changed = true

            BAS_SOURCES.SOURCES.sources[source.uuid] = basSource
            BAS_SOURCES.SOURCES.externals[source.id] = source.uuid

            uuidsToKeep.push('' + source.id)
            sourceUuidsToKeep.push(source.uuid)
          }
        }

        BAS_SOURCES.SOURCES.externals = BasUtil.keepKeys(
          BAS_SOURCES.SOURCES.externals,
          uuidsToKeep
        )

        // Bluetooths

        sources = currentBasCoreState.core.core.bluetooths

        uuidsToKeep = []

        keys = Object.keys(sources)
        length = keys.length
        for (i = 0; i < length; i++) {

          source = sources[keys[i]]

          if (BasUtil.isObject(source)) {

            parseResult = BasSource.parseOrDestroy(
              BAS_SOURCES.SOURCES.sources[source.uuid],
              source
            )

            basSource = parseResult.basSource
            if (parseResult.changed) _changed = true

            BAS_SOURCES.SOURCES.sources[source.uuid] = basSource
            BAS_SOURCES.SOURCES.bluetooths[source.id] = source.uuid

            uuidsToKeep.push('' + source.id)
            sourceUuidsToKeep.push(source.uuid)
          }
        }

        BAS_SOURCES.SOURCES.bluetooths = BasUtil.keepKeys(
          BAS_SOURCES.SOURCES.bluetooths,
          uuidsToKeep
        )

        // Notifications

        sources = currentBasCoreState.core.core.notifications

        uuidsToKeep = []

        keys = Object.keys(sources)
        length = keys.length
        for (i = 0; i < length; i++) {

          source = sources[keys[i]]

          if (BasUtil.isObject(source)) {

            parseResult = BasSource.parseOrDestroy(
              BAS_SOURCES.SOURCES.sources[source.uuid],
              source
            )

            basSource = parseResult.basSource
            if (parseResult.changed) _changed = true

            BAS_SOURCES.SOURCES.sources[source.uuid] = basSource
            BAS_SOURCES.SOURCES.notifications[source.id] = source.uuid

            uuidsToKeep.push('' + source.id)
            sourceUuidsToKeep.push(source.uuid)
          }
        }

        BAS_SOURCES.SOURCES.notifications = BasUtil.keepKeys(
          BAS_SOURCES.SOURCES.notifications,
          uuidsToKeep
        )
      }

      // Combine players and av sources

      BAS_SOURCES.SOURCES.playersOrAVSources = {}

      BasUtil.mergeObjects(
        BAS_SOURCES.SOURCES.playersOrAVSources,
        BAS_SOURCES.SOURCES.players
      )

      BasUtil.mergeObjects(
        BAS_SOURCES.SOURCES.playersOrAVSources,
        BAS_SOURCES.SOURCES.audioSources
      )

      BasUtil.mergeObjects(
        BAS_SOURCES.SOURCES.playersOrAVSources,
        BAS_SOURCES.SOURCES.videoSources
      )

      // Keep audio alert sources

      keys = Object.keys(BAS_SOURCES.SOURCES.audioAlerts)
      length = keys.length
      for (i = 0; i < length; i++) {

        sourceUuidsToKeep.push(keys[i])
      }

      _keepSources(sourceUuidsToKeep, false)
    }

    _checkFinalSources()

    _updateSourcesWithCobraNetIds()

    _processSources()

    _setEventCollections()

    if (_changed) {

      $rootScope.$emit(
        BAS_SOURCES.EVT_SOURCES_UPDATED,
        BAS_SOURCES.T_EVT_SOURCES
      )
    }
  }

  /**
   * Generate the UI collections for sources pages
   *
   * @private
   */
  function _processSources () {

    var ids, i, length, collection, clone, uuids, j, lengthJ, source
    var numBase, webCollection, uuid

    BAS_SOURCES.SOURCES.uiBase = []
    BAS_SOURCES.SOURCES.uiActive = []
    BAS_SOURCES.SOURCES.uiStreams = []
    BAS_SOURCES.SOURCES.uiPlayers = []
    BAS_SOURCES.SOURCES.uiSpotify = []
    BAS_SOURCES.SOURCES.uiSpotifyWeb = []
    BAS_SOURCES.SOURCES.uiAudioSources = []
    BAS_SOURCES.SOURCES.uiSpotifyAudioSources = []

    BAS_SOURCES.SOURCES.numBase = 0
    BAS_SOURCES.SOURCES.numPlayers = 0

    numBase = 0

    if (
      CurrentBasCore.hasAVPartialSupport() ||
      CurrentBasCore.hasAVFullSupport()
    ) {

      // Audio Sources

      ids = Object.keys(BAS_SOURCES.SOURCES.audioSources)
      BAS_SOURCES.SOURCES.numPlayers = length = ids.length
      numBase += length

      BAS_SOURCES.SOURCES.numSourcesWithDefaultRooms = 0

      if (length > 0) {

        collection = new BasCollection()
        collection.setId(BAS_SOURCES.COL_ID_AUDIO_SOURCES)
        collection.setTitleTranslationId(BAS_SOURCES.TRANS_ID_SOURCES)
      }

      for (i = 0; i < length; i++) {

        uuid = BAS_SOURCES.SOURCES.audioSources[ids[i]]
        source = BAS_SOURCES.SOURCES.sources[uuid]

        if (source) {

          if (source.canListDefaultRooms) {

            BAS_SOURCES.SOURCES.numSourcesWithDefaultRooms++
          }
        }

        collection.items.push(uuid)
      }

      if (collection) {

        // Sort based on sequence
        collection.items.sort(compareSources)

        BAS_SOURCES.SOURCES.uiAudioSources.push(collection)
      }
    }

    if (CurrentBasCore.hasAVFullSupport()) {

      // Spotify audio Sources

      ids = Object.keys(BAS_SOURCES.SOURCES.spotifyAudioSources)

      length = ids.length
      if (length > 0) {

        collection = new BasCollection()
        collection.setId(BAS_SOURCES.COL_ID_SPOTIFY_AUDIO_SOURCES)
        collection.setTitleTranslationId(BAS_SOURCES.TRANS_ID_SOURCES)

      } else {

        collection = null
      }

      for (i = 0; i < length; i++) {

        collection.items.push(BAS_SOURCES.SOURCES.spotifyAudioSources[ids[i]])
      }

      if (collection) {

        // Sort based on sequence
        collection.items.sort(compareSources)

        BAS_SOURCES.SOURCES.uiSpotifyAudioSources.push(collection)
      }
    } else {

      // No or partial (Sonos) AV support, still parse legacy sources

      // Players

      ids = Object.keys(BAS_SOURCES.SOURCES.players)
      BAS_SOURCES.SOURCES.numPlayers = length = ids.length
      numBase += BAS_SOURCES.SOURCES.numPlayers

      if (length > 0) {

        collection = new BasCollection()
        collection.setId(BAS_SOURCES.COL_ID_STREAMS)
        collection.setTitleTranslationId(BAS_SOURCES.TRANS_ID_STREAMS)

        for (i = 0; i < length; i++) {

          collection.items.push(BAS_SOURCES.SOURCES.players[ids[i]])
        }

        // Sort based on sequence
        collection.items.sort(compareSources)

        BAS_SOURCES.SOURCES.uiBase.push(collection)

        clone = collection.clone()
        clone.setId(BAS_SOURCES.COL_ID_STREAMS_ACTIVE)
        BAS_SOURCES.SOURCES.uiActive.push(clone)

        clone = collection.clone()
        clone.setId(BAS_SOURCES.COL_ID_PLAYERS)
        BAS_SOURCES.SOURCES.uiPlayers.push(clone)
      }

      // Spotify Barps

      ids = Object.keys(BAS_SOURCES.SOURCES.barps)
      length = ids.length

      if (length > 0) {

        webCollection = new BasCollection()
        webCollection.setId(BAS_SOURCES.COL_ID_SPOTIFY)
        webCollection.setTitleTranslationId(BAS_SOURCES.TRANS_ID_STREAMS)

        collection = new BasCollection()
        collection.setId(BAS_SOURCES.COL_ID_SPOTIFY_WEB)
        collection.setTitleTranslationId(BAS_SOURCES.TRANS_ID_STREAMS)

        for (i = 0; i < length; i++) {

          uuids = BAS_SOURCES.SOURCES.barps[ids[i]]

          if (Array.isArray(uuids)) {

            lengthJ = uuids.length
            for (j = 0; j < lengthJ; j++) {

              source = BAS_SOURCES.SOURCES.sources[uuids[j]]

              if (BasSourceHelper.checkSpotifyBarp(source)) {

                collection.items.push(uuids[j])

                if (BasUtil.isObject(source.spotify)) {

                  if (source.spotify.getLinkUrlPath()) {

                    webCollection.items.push(uuids[j])
                  }
                }
              }
            }

          } else if (BasUtil.isNEString(uuids)) {

            source = BAS_SOURCES.SOURCES.sources[uuids]

            if (BasSourceHelper.checkSpotifyBarp(source)) {

              collection.items.push(uuids)

              if (BasUtil.isObject(source.spotify)) {

                if (source.spotify.getLinkUrlPath()) {

                  webCollection.items.push(uuids)
                }
              }
            }
          }
        }

        collection.items.sort(compareSpotifySources)

        if (collection.items.length > 0) {

          BAS_SOURCES.SOURCES.uiSpotify.push(collection)
        }

        if (webCollection.items.length > 0) {

          BAS_SOURCES.SOURCES.uiSpotifyWeb.push(webCollection)
        }
      }

      // Externals

      ids = Object.keys(BAS_SOURCES.SOURCES.externals)
      length = ids.length
      numBase += length

      if (length > 0) {

        collection = new BasCollection()
        collection.setId(BAS_SOURCES.COL_ID_EXTERNALS)
        collection.setTitleTranslationId(BAS_SOURCES.TRANS_ID_EXTERNAL)

        for (i = 0; i < length; i++) {

          collection.items.push(BAS_SOURCES.SOURCES.externals[ids[i]])
        }

        BAS_SOURCES.SOURCES.uiBase.push(collection)

        clone = collection.clone()
        clone.setId(BAS_SOURCES.COL_ID_EXTERNALS_ACTIVE)
        BAS_SOURCES.SOURCES.uiActive.push(clone)
      }

      // Bluetooths

      ids = Object.keys(BAS_SOURCES.SOURCES.bluetooths)
      length = ids.length
      numBase += length

      if (length > 0) {

        collection = new BasCollection()
        collection.setId(BAS_SOURCES.COL_ID_BLUETOOTHS)
        collection.setTitleTranslationId(BAS_SOURCES.TRANS_ID_BLUETOOTH)

        for (i = 0; i < length; i++) {

          collection.items.push(BAS_SOURCES.SOURCES.bluetooths[ids[i]])
        }

        BAS_SOURCES.SOURCES.uiBase.push(collection)

        clone = collection.clone()
        clone.setId(BAS_SOURCES.COL_ID_BLUETOOTHS_ACTIVE)
        BAS_SOURCES.SOURCES.uiActive.push(clone)
      }
    }

    // UiStreams

    ids = Object.keys(BAS_SOURCES.SOURCES.sources)
    length = ids.length

    collection = new BasCollection()
    collection.setId(BAS_SOURCES.COL_ID_STREAMS)
    collection.setTitleTranslationId(BAS_SOURCES.TRANS_ID_STREAMS)

    for (i = 0; i < length; i++) {

      uuid = ids[i]
      source = BAS_SOURCES.SOURCES.sources[uuid]

      if (
        source &&
        (
          (
            source.type === BAS_SOURCE.T_ASANO &&
            source.subType === BAS_SOURCE.ST_STREAM
          ) ||
          source.type === BAS_SOURCE.T_PLAYER
        )
      ) {
        collection.items.push(uuid)
      }
    }

    if (collection.items.length > 0) {
      BAS_SOURCES.SOURCES.uiStreams.push(collection)
    }

    BAS_SOURCES.SOURCES.numBase = numBase

    _uiCheckActiveBarps()
  }

  function _updateSourcesWithCobraNetIds () {

    var keys, length, i, sourceUuid, source, cobraNetId

    keys = Object.keys(BAS_SOURCES.SOURCES.sources)
    length = keys.length
    for (i = 0; i < length; i++) {

      sourceUuid = keys[i]
      source = BAS_SOURCES.SOURCES.sources[sourceUuid]

      if (source && source.setCobraNetId) {

        cobraNetId = _getCobraNetIDForUuid(sourceUuid)

        if (cobraNetId > 0) source.setCobraNetId(cobraNetId)
      }
    }
  }

  /**
   * @private
   * @param {string} uuid
   * @returns {number}
   */
  function _getCobraNetIDForUuid (uuid) {

    var res

    if (CurrentBasCore.hasCore() && uuid) {

      // Players

      res = _getCobraNetIDForUuidInSources(
        currentBasCoreState.core.core.players,
        uuid
      )
      if (BasUtil.isVNumber(res)) return res

      // Barps

      res = _getCobraNetIDForUuidInSources(
        currentBasCoreState.core.core.barps,
        uuid
      )
      if (BasUtil.isVNumber(res)) return res

      // Externals

      res = _getCobraNetIDForUuidInSources(
        currentBasCoreState.core.core.externals,
        uuid
      )
      if (BasUtil.isVNumber(res)) return res

      // Bluetooths

      res = _getCobraNetIDForUuidInSources(
        currentBasCoreState.core.core.bluetooths,
        uuid
      )
      if (BasUtil.isVNumber(res)) return res

      // Notifications

      res = _getCobraNetIDForUuidInSources(
        currentBasCoreState.core.core.notifications,
        uuid
      )
      if (BasUtil.isVNumber(res)) return res
    }

    return 0
  }

  /**
   * @private
   * @param {Object<
   * string,
   * (Player|Barp|(Barp[])|External|Bluetooth|Notification)
   * >} sources
   * @param {string} uuid
   * @returns {(number|undefined)}
   */
  function _getCobraNetIDForUuidInSources (sources, uuid) {

    var keys, length, i, source, lengthJ, j, sourceJ

    keys = Object.keys(sources)
    length = keys.length
    for (i = 0; i < length; i++) {

      source = sources[keys[i]]

      // Check for array, example: multiple Barps for a single Stream
      if (Array.isArray(source)) {

        lengthJ = source.length
        for (j = 0; j < lengthJ; j++) {

          sourceJ = source[j]

          if (BasUtil.isObject(sourceJ) && sourceJ.uuid === uuid) {
            return BasUtil.isVNumber(sourceJ.id) ? sourceJ.id : 0
          }
        }

      } else if (BasUtil.isObject(source)) {

        if (source.uuid === uuid) {
          return BasUtil.isVNumber(source.id) ? source.id : 0
        }
      }
    }
  }

  function _onLinkUrlChanged (_event, uuid, link) {

    var webCollection

    if (BasUtil.isNEString(link)) {

      if (!BasUtil.isObject(BAS_SOURCES.SOURCES.uiSpotifyWeb[0])) {

        webCollection = new BasCollection()
        webCollection.setId(BAS_SOURCES.COL_ID_SPOTIFY_WEB)
        webCollection.setTitleTranslationId(
          BAS_SOURCES.TRANS_ID_STREAMS
        )

        BAS_SOURCES.SOURCES.uiSpotifyWeb[0] = webCollection
      }

      webCollection = BAS_SOURCES.SOURCES.uiSpotifyWeb[0]

      if (webCollection.items.indexOf(uuid) === -1) {

        webCollection.items.push(uuid)
        webCollection.items.sort(compareSpotifySources)
      }
    }
  }

  function loadKNXPresetsSources () {

    BAS_SOURCES.SOURCES.css[BAS_SOURCES.CSS_KNX_PRESETS_LOADING] = true

    allKNXPresets().then(_onAllKNXPresets)
  }

  function _onAllKNXPresets () {

    _processKNXPresetsSources()

    BAS_SOURCES.SOURCES.css[BAS_SOURCES.CSS_KNX_PRESETS_LOADING] = false
    BAS_SOURCES.SOURCES.css[BAS_SOURCES.CSS_KNX_PRESETS_HAS] =
      BAS_SOURCES.SOURCES.uiKNXPresets.length > 0

    $rootScope.$applyAsync()
  }

  function _processKNXPresetsSources () {

    var ids, length, i, uuid, source, collection

    BAS_SOURCES.SOURCES.uiKNXPresets = []

    if (CurrentBasCore.hasAVFullSupport()) {

      ids = Object.keys(BAS_SOURCES.SOURCES.audioSources)
      length = ids.length
      for (i = 0; i < length; i++) {

        uuid = BAS_SOURCES.SOURCES.audioSources[ids[i]]
        source = BAS_SOURCES.SOURCES.sources[uuid]

        if (
          source &&
          source.type === BAS_SOURCE.T_ASANO &&
          source.knxPresets && source.knxPresets.hasPresets()
        ) {

          if (!collection) {

            collection = new BasCollection()
            collection.setId(BAS_SOURCES.COL_ID_STREAMS_KNX_PRESETS)
            collection.setTitleTranslationId(
              BAS_SOURCES.TRANS_ID_STREAMS
            )
          }

          collection.items.push(uuid)
        }
      }

    } else {

      ids = Object.keys(BAS_SOURCES.SOURCES.players)
      length = ids.length
      for (i = 0; i < length; i++) {

        uuid = BAS_SOURCES.SOURCES.players[ids[i]]
        source = BAS_SOURCES.SOURCES.sources[uuid]

        if (source &&
          source.type === BAS_SOURCE.T_PLAYER &&
          source.knxPresets && source.knxPresets.hasPresets()) {

          if (!collection) {

            collection = new BasCollection()
            collection.setId(BAS_SOURCES.COL_ID_STREAMS_KNX_PRESETS)
            collection.setTitleTranslationId(
              BAS_SOURCES.TRANS_ID_STREAMS
            )
          }

          collection.items.push(uuid)
        }
      }
    }

    if (collection && collection.items.length > 0) {

      collection.items.sort(compareSources)
      BAS_SOURCES.SOURCES.uiKNXPresets.push(collection)
    }
  }

  /**
   * @private
   * @returns {?BasCollection}
   */
  function _getUiActiveStreamsCollection () {

    var i, length, collection

    length = BAS_SOURCES.SOURCES.uiActive.length
    for (i = 0; i < length; i++) {

      collection = BAS_SOURCES.SOURCES.uiActive[i]

      if (collection &&
        collection.id === BAS_SOURCES.COL_ID_STREAMS_ACTIVE) {

        return collection
      }
    }

    return null
  }

  function _uiCheckActiveBarps () {

    var i, length, collection, source, actualSource

    collection = _getUiActiveStreamsCollection()

    if (collection) {

      length = collection.items.length
      for (i = 0; i < length; i++) {

        source = BAS_SOURCES.SOURCES.sources[collection.items[i]]

        actualSource = SourcesHelper.getActiveSource(source.id)

        if (actualSource) {

          collection.items[i] = actualSource.uuid
        }
      }
    }
  }

  function _uiClearKNXPresets () {

    BAS_SOURCES.SOURCES.css[BAS_SOURCES.CSS_KNX_PRESETS_LOADING] = false
    BAS_SOURCES.SOURCES.css[BAS_SOURCES.CSS_KNX_PRESETS_HAS] = false
  }

  function _onAVSourcesReceived () {

    if (
      CurrentBasCore.hasAVPartialSupport() ||
      CurrentBasCore.hasAVFullSupport()
    ) {

      _syncApi()

      if (BAS_SOURCES.SOURCES.events.all[BAS_SOURCE.COL_EVT_KNX_PRESETS] > 0) {

        _uiClearKNXPresets()

        loadKNXPresetsSources()
      }

      $rootScope.$applyAsync()
    }
  }

  function _onMusicReceived () {

    var events

    // We assume that music config message comes after the system properties,
    //  and that CurrentBasCore.hasAVFullSupport() result will be correct.
    if (!CurrentBasCore.hasAVFullSupport()) {

      events = BAS_SOURCES.SOURCES.events

      // On servers with partial AV support (Sonos + legacy), calling syncApi
      //  here will destroy the currently loaded AV BasSources, so they will be
      //  recreated instead of updated.

      _syncApi()

      if (events.all[BAS_SOURCE.COL_EVT_KNX_PRESETS] > 0) {

        _uiClearKNXPresets()

        loadKNXPresetsSources()
      }

      $rootScope.$applyAsync()
    }
  }

  function _onRoomsReceived () {

    var _changed, rooms, uuidsToKeep
    var keys, length, i, room, basSource, parseResult

    // Alerts (virtual source per room-av-audio)

    _changed = false

    if (
      CurrentBasCore.hasAVPartialSupport() ||
      CurrentBasCore.hasAVFullSupport()
    ) {

      rooms = currentBasCoreState.core.core.areas

      uuidsToKeep = []

      keys = Object.keys(rooms)
      length = keys.length
      for (i = 0; i < length; i++) {

        room = rooms[keys[i]]

        if (
          room &&
          room.uuid &&
          room.av &&
          room.av.audio
        ) {

          parseResult = BasSource.parseOrDestroy(
            BAS_SOURCES.SOURCES.sources[room.uuid],
            room.av.audio
          )

          basSource = parseResult.basSource
          if (parseResult.changed) _changed = true

          BAS_SOURCES.SOURCES.sources[basSource.uuid] = basSource
          BAS_SOURCES.SOURCES.audioAlerts[basSource.uuid] = basSource.uuid

          uuidsToKeep.push(basSource.uuid)
        }
      }

      BAS_SOURCES.SOURCES.audioAlerts = BasUtil.keepKeys(
        BAS_SOURCES.SOURCES.audioAlerts,
        uuidsToKeep
      )

      _checkFinalSources()
    }

    if (_changed) {

      $rootScope.$emit(
        BAS_SOURCES.EVT_SOURCES_UPDATED,
        BAS_SOURCES.T_EVT_AUDIO_ALERTS
      )
    }
  }

  function _checkFinalSources () {

    var _core

    if (CurrentBasCore.hasCore()) {

      _core = currentBasCoreState.core.core

      if (_core.supportsSystemProperties) {

        if (_core.system && !_core.system.propertiesDirty) {

          if (CurrentBasCore.hasAVFullSupport()) {

            if (
              _core.roomsReceived &&
              _core.avSourcesReceived
            ) {

              // Audio sources and rooms received, all sources known

              _keepSources(
                _getSourceUuidsToKeep(),
                true
              )
            }

          } else if (CurrentBasCore.hasAVPartialSupport()) {

            if (
              _core.avSourcesReceived &&
              _core.roomsReceived &&
              _core.musicConfigReceived
            ) {

              // Audio sources, rooms and music config received,
              //  all sources known

              _keepSources(
                _getSourceUuidsToKeep(),
                true
              )
            }

          } else {

            if (_core.musicConfigReceived) {

              // Music config received, all sources known

              _keepSources(
                _getSourceUuidsToKeep(),
                true
              )
            }
          }
        }

      } else {

        if (_core.musicConfigReceived) {

          // Music config is received, all sources are known at this point

          _keepSources(
            _getSourceUuidsToKeep(),
            true
          )
        }
      }
    }
  }

  /**
   * @private
   * @param _event
   * @param {number} _id
   */
  function _onSourceConnected (_event, _id) {

    _uiCheckActiveBarps()

    $rootScope.$applyAsync()
  }

  /**
   * Update translations for all sources
   *
   * @private
   */
  function _onTranslate () {

    var keys, i, length, value

    keys = Object.keys(BAS_SOURCES.SOURCES.sources)
    length = keys.length
    for (i = 0; i < length; i++) {

      value = BAS_SOURCES.SOURCES.sources[keys[i]]
      if (value && value.updateTranslation) value.updateTranslation()
    }

    _collectionUpdateTranslations(BAS_SOURCES.SOURCES.uiBase)
    _collectionUpdateTranslations(BAS_SOURCES.SOURCES.uiActive)
    _collectionUpdateTranslations(BAS_SOURCES.SOURCES.uiStreams)
    _collectionUpdateTranslations(BAS_SOURCES.SOURCES.uiPlayers)
    _collectionUpdateTranslations(BAS_SOURCES.SOURCES.uiSpotify)
    _collectionUpdateTranslations(BAS_SOURCES.SOURCES.uiSpotifyWeb)
    _collectionUpdateTranslations(BAS_SOURCES.SOURCES.uiKNXPresets)
    _collectionUpdateTranslations(BAS_SOURCES.SOURCES.uiAudioSources)

    /**
     * @private
     * @param {BasCollection[]} collections
     */
    function _collectionUpdateTranslations (collections) {

      var _length, _i, col

      _length = collections.length
      for (_i = 0; _i < _length; _i++) {

        col = collections[_i]
        if (col && col.updateTranslation) col.updateTranslation()
      }
    }
  }

  /**
   * Creates all (known) constant sources
   *
   * @private
   */
  function _generateConstantSources () {

    _addConstantSource(
      new BasSource(BAS_SOURCE.V_EMPTY)
    )

    _addConstantSource(
      new BasSource(BAS_SOURCE.V_MIXED)
    )

    _addConstantSource(
      new BasSource(BAS_SOURCE.V_AV_INPUT_NONE)
    )

    _addConstantSource(
      new BasSource(BAS_SOURCE.V_AV_INPUT_UNKNOWN)
    )
  }

  /**
   * @private
   * @param {BasSource} source
   */
  function _addConstantSource (source) {

    BAS_SOURCES.SOURCES.constants[source.id] = source.uuid
    BAS_SOURCES.SOURCES.sources[source.uuid] = source
  }

  /**
   * Call "destroy" on all sources and clear sources.
   *
   * @private
   */
  function _destroySources () {

    var uuids, i, length, source

    if (BAS_SOURCES.SOURCES.sources) {

      uuids = Object.keys(BAS_SOURCES.SOURCES.sources)
      length = uuids.length
      for (i = 0; i < length; i++) {

        source = BAS_SOURCES.SOURCES.sources[uuids[i]]

        if (BasUtil.isObject(source) &&
          BasUtil.isFunction(source.destroy)) {

          source.destroy()
        }
      }
    }

    BAS_SOURCES.SOURCES.sources = {}
  }

  /**
   * @private
   * @returns {string[]}
   */
  function _getSourceUuidsToKeep () {

    var sourceUuidsToKeep

    sourceUuidsToKeep = [
      BAS_SOURCE.ID_EMPTY,
      BAS_SOURCE.ID_MIXED,
      BAS_SOURCE.ID_AV_INPUT_NONE,
      BAS_SOURCE.ID_AV_INPUT_UNKNOWN
    ]

    if (CurrentBasCore.hasCore()) {

      _sourcesToKeep(
        sourceUuidsToKeep,
        currentBasCoreState.core.core.audioSources
      )
      _sourcesToKeep(
        sourceUuidsToKeep,
        currentBasCoreState.core.core.videoSources
      )
      _sourcesToKeep(
        sourceUuidsToKeep,
        currentBasCoreState.core.core.players
      )
      _sourcesToKeep(
        sourceUuidsToKeep,
        currentBasCoreState.core.core.barps
      )
      _sourcesToKeep(
        sourceUuidsToKeep,
        currentBasCoreState.core.core.externals
      )
      _sourcesToKeep(
        sourceUuidsToKeep,
        currentBasCoreState.core.core.bluetooths
      )
      _sourcesToKeep(
        sourceUuidsToKeep,
        currentBasCoreState.core.core.notifications
      )
      _sourcesToKeepAudioAlerts(
        sourceUuidsToKeep,
        currentBasCoreState.core.core.areas
      )
    }

    return sourceUuidsToKeep
  }

  /**
   * Helper function for checking source UUIDs to keep
   *
   * @private
   * @param {string[]} sourcesToKeep
   * @param {(Object|(Object[]))} sources
   */
  function _sourcesToKeep (
    sourcesToKeep,
    sources
  ) {
    var keys, length, i, source

    if (
      Array.isArray(sourcesToKeep) &&
      BasUtil.isObject(sources)
    ) {
      keys = Object.keys(sources)
      length = keys.length
      for (i = 0; i < length; i++) {

        source = sources[keys[i]]

        if (Array.isArray(source)) {

          _sourcesToKeepArray(sourcesToKeep, source)

        } else if (
          BasUtil.isObject(source) &&
          BasUtil.isNEString(source.uuid)
        ) {
          sourcesToKeep.push(source.uuid)
        }
      }
    }
  }

  /**
   * Helper function for checking source UUIDs to keep
   *
   * @private
   * @param {string[]} sourcesToKeep
   * @param {Object[]} sources
   */
  function _sourcesToKeepArray (
    sourcesToKeep,
    sources
  ) {
    var length, i, source

    if (
      Array.isArray(sourcesToKeep) &&
      Array.isArray(sources)
    ) {
      length = sources.length
      for (i = 0; i < length; i++) {

        source = sources[i]

        if (
          BasUtil.isObject(source) &&
          BasUtil.isNEString(source.uuid)
        ) {
          sourcesToKeep.push(source.uuid)
        }
      }
    }
  }

  /**
   * Helper function for checking source UUIDs to keep
   *
   * @private
   * @param {string[]} sourcesToKeep
   * @param {Object} areas
   */
  function _sourcesToKeepAudioAlerts (
    sourcesToKeep,
    areas
  ) {
    var keys, length, i, area

    if (
      Array.isArray(sourcesToKeep) &&
      BasUtil.isObject(areas)
    ) {
      keys = Object.keys(areas)
      length = keys.length
      for (i = 0; i < length; i++) {

        area = areas[keys[i]]

        if (
          BasUtil.isObject(area) &&
          BasUtil.isNEString(area.uuid) &&
          area.av &&
          BasUtil.isObject(area.av.audio)
        ) {
          sourcesToKeep.push(area.uuid)
        }
      }
    }
  }

  /**
   * Call "destroy" on all sources not part of sourcesToKeep.
   * Remove all sources except sourcesToKeep.
   *
   * @private
   * @param {string[]} sourcesToKeep
   * @param {boolean} [destroy = true]
   */
  function _keepSources (sourcesToKeep, destroy) {

    var _destroy, uuids, length, i, uuid, source, newSources

    _destroy = true

    if (BasUtil.isBool(destroy)) _destroy = destroy

    if (BAS_SOURCES.SOURCES.sources && Array.isArray(sourcesToKeep)) {

      newSources = {}

      uuids = Object.keys(BAS_SOURCES.SOURCES.sources)
      length = uuids.length
      for (i = 0; i < length; i++) {

        uuid = uuids[i]
        source = BAS_SOURCES.SOURCES.sources[uuid]

        if (sourcesToKeep.indexOf(uuid) > -1) {

          // Keep source

          if (source instanceof BasSource) {

            newSources[uuid] = source
          }

        } else {

          if (BasUtil.isObject(source)) {

            if (_destroy) {

              // Destroy source

              if (BasUtil.isFunction(source.destroy)) {

                source.destroy()
              }

            } else {

              // Suspend source

              if (BasUtil.isFunction(source.suspend)) {

                source.suspend()
              }
            }
          }
        }
      }

      BAS_SOURCES.SOURCES.sources = newSources
    }
  }

  function _onRoomsUpdated (_, data) {

    var source, keys, i, length

    if (data === BAS_ROOMS.P_ROOMS) {

      keys = Object.keys(BAS_SOURCES.SOURCES.audioSources)
      length = keys.length
      for (i = 0; i < length; i++) {

        source = BAS_SOURCES.SOURCES.sources[
          BAS_SOURCES.SOURCES.audioSources[keys[i]]
        ]

        if (source) source.onRoomsUpdated()
      }
    }
  }

  /**
   * Resets all event listener collections
   *
   * @private
   */
  function _clearEvents () {

    var keys, i, length

    if (!BAS_SOURCES.SOURCES.events) {

      BAS_SOURCES.SOURCES.events = {}
      BAS_SOURCES.SOURCES.events.sources = {}
    }

    BAS_SOURCES.SOURCES.events.all = {}

    // Initialize all known event collections
    keys = Object.keys(BAS_SOURCE)
    length = keys.length
    for (i = 0; i < length; i++) {

      if (keys[i].indexOf('COL_EVT_') === 0) {

        BAS_SOURCES.SOURCES.events.all[BAS_SOURCE[keys[i]]] = 0
      }
    }

    BAS_SOURCES.SOURCES.events.sources = {}

    _setEventCollections()
  }

  function _clearCss () {

    BAS_SOURCES.SOURCES.css[BAS_SOURCES.CSS_KNX_PRESETS_LOADING] = false
    BAS_SOURCES.SOURCES.css[BAS_SOURCES.CSS_KNX_PRESETS_HAS] = false
  }

  function _clearData () {

    _destroySources()

    BAS_SOURCES.SOURCES.audioSources = {}
    BAS_SOURCES.SOURCES.spotifyAudioSources = {}
    BAS_SOURCES.SOURCES.videoSources = {}
    BAS_SOURCES.SOURCES.players = {}
    BAS_SOURCES.SOURCES.playersOrAVSources = {}
    BAS_SOURCES.SOURCES.barps = {}
    BAS_SOURCES.SOURCES.externals = {}
    BAS_SOURCES.SOURCES.bluetooths = {}
    BAS_SOURCES.SOURCES.notifications = {}
    BAS_SOURCES.SOURCES.audioAlerts = {}
    BAS_SOURCES.SOURCES.constants = {}
    BAS_SOURCES.SOURCES.spotify = {}

    BAS_SOURCES.SOURCES.uiBase = []
    BAS_SOURCES.SOURCES.uiActive = []
    BAS_SOURCES.SOURCES.uiStreams = []
    BAS_SOURCES.SOURCES.uiPlayers = []
    BAS_SOURCES.SOURCES.uiSpotify = []
    BAS_SOURCES.SOURCES.uiSpotifyWeb = []
    BAS_SOURCES.SOURCES.uiKNXPresets = []
    BAS_SOURCES.SOURCES.uiAudioSources = []

    BAS_SOURCES.SOURCES.numBase = 0
    BAS_SOURCES.SOURCES.numPlayers = 0

    _generateConstantSources()

    BAS_SOURCES.SOURCES.css = {}
    _clearCss()
  }

  /**
   * Resets the BAS_SOURCES. Clears sources and events.
   */
  function _clear () {

    _clearData()
    _clearEvents()
  }

  function _ignore () {
    // Empty
  }
}
