'use strict'

/**
 * @constant {string}
 */
exports.ME = 'me'

/**
 * @constant {string}
 */
exports.USERS = 'users'

/**
 * @constant {string}
 */
exports.BROWSE = 'browse'

/**
 * @constant {string}
 */
exports.CATEGORIES = 'categories'

/**
 * @constant {string}
 */
exports.TOPLISTS = 'toplists'

/**
 * @constant {string}
 */
exports.RECENTLY_PLAYED = 'recently-played'

/**
 * @constant {string}
 */
exports.HEADERS = 'headers'

/**
 * @constant {string}
 */
exports.PLAYLISTS = 'playlists'

/**
 * @constant {string}
 */
exports.FEATURED_PLAYLISTS = 'featured-playlists'

/**
 * @constant {string}
 */
exports.NEW_RELEASES = 'new-releases'

/**
 * @constant {string}
 */
exports.FOLLOWING = 'following'

/**
 * @constant {string}
 */
exports.ALBUMS = 'albums'
