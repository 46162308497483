'use strict'

import * as BasUtil from '@basalte/bas-util'
// TODO refactor to deviceCoreClientMuteAreaOnCall?
angular
  .module('basalteApp')
  .directive('deviceEllieMuteAreaOnCall', deviceEllieMuteAreaOnCall)

function deviceEllieMuteAreaOnCall () {

  return {
    restrict: 'AE',
    template:
      '<div class="bbs bbs-item bbs-centered bbs-flex bbs-active-select"' +
      ' bas-click="muteAreaOnCall.toggleMuteAreaOnCall()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      ' data-translate="mute_area_on_call"></div>' +
      '  <bas-toggle class="bbs-toggle bas-toggle-dark"' +
      ' data-bas-toggle="muteAreaOnCall.toggleMuteAreaOnCall()"' +
      ' data-bas-toggled="' +
      'muteAreaOnCall.basCoreClientInfo.device.muteAreaOnCall' +
      '"></bas-toggle>' +
      '</div>',
    controller: [
      '$rootScope',
      '$scope',
      'BAS_ROOM',
      'BAS_ROOMS',
      'BasCoreClientHelper',
      controller
    ],
    controllerAs: 'muteAreaOnCall'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_ROOM} BAS_ROOM
   * @param {BAS_ROOMS} BAS_ROOMS
   * @param {BasCoreClientHelper} BasCoreClientHelper
   */
  function controller (
    $rootScope,
    $scope,
    BAS_ROOM,
    BAS_ROOMS,
    BasCoreClientHelper
  ) {
    var muteAreaOnCall = this

    var _listeners = []

    /**
     * @type {?TBasCoreClientInfo}
     */
    muteAreaOnCall.basCoreClientInfo = null

    muteAreaOnCall.toggleMuteAreaOnCall = toggleMuteAreaOnCall

    init()

    function init () {

      muteAreaOnCall.$onDestroy = _destroy

      _listeners.push($rootScope.$on(
        BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
        _onSyncEllieInfo
      ))
      _listeners.push($rootScope.$on(
        BAS_ROOMS.EVT_ROOMS_UPDATED,
        _onSyncEllieInfo
      ))

      _syncEllieInfo()
    }

    function toggleMuteAreaOnCall () {

      if (muteAreaOnCall.basCoreClientInfo &&
        muteAreaOnCall.basCoreClientInfo.device) {

        muteAreaOnCall.basCoreClientInfo.device.toggleMuteAreaOnCall()
      }
    }

    function _onSyncEllieInfo () {

      _syncEllieInfo()
      _syncScope()
    }

    function _syncEllieInfo () {

      muteAreaOnCall.basCoreClientInfo =
        BasCoreClientHelper.getBasCoreClientInfo()
    }

    function _syncScope () {

      $scope.$applyAsync()
    }

    function _destroy () {

      BasUtil.executeArray(_listeners)
      _listeners = null
    }
  }
}
