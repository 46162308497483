'use strict'

angular
  .module('basalteApp')
  .service('BasScenePresetsHelper', [
    'BAS_SCENE_PRESETS',
    BasScenePresetsHelper
  ])

/**
 * @constructor
 * @param {BAS_SCENE_PRESETS} BAS_SCENE_PRESETS
 */
function BasScenePresetsHelper (BAS_SCENE_PRESETS) {

  this.getPreset = getPreset

  /**
   * @param {(string|number)} preset
   * @returns {BasScenePreset}
   */
  function getPreset (preset) {

    var keys, i, length, _preset

    if (typeof preset === 'string') {

      _preset = BAS_SCENE_PRESETS.PRESETS[preset]
      if (_preset) return _preset

    } else if (typeof preset === 'number') {

      keys = Object.keys(BAS_SCENE_PRESETS.PRESETS)
      length = keys.length
      for (i = 0; i < length; i++) {

        _preset = BAS_SCENE_PRESETS.PRESETS[keys[i]]
        if (_preset.number === preset) return _preset
      }
    }

    return BAS_SCENE_PRESETS.PRESETS[BAS_SCENE_PRESETS.K_UNKNOWN]
  }
}
