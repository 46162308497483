'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasTidalHelper', [
    'BAS_TIDAL',
    BasTidalHelper
  ])

/**
 * @constructor
 * @param {BAS_TIDAL} BAS_TIDAL
 */
function BasTidalHelper (
  BAS_TIDAL
) {
  var _IMG_REGEX = /-/g

  /**
   * @type {getImageUrl}
   */
  this.getImageUrl = getImageUrl

  /**
   * @param {string} id
   * @param {string} size
   * @returns {string}
   */
  function getImageUrl (id, size) {

    if (BasUtil.isNEString(id) &&
      BasUtil.isNEString(size)) {

      return BAS_TIDAL.IMG_URL + id.replace(_IMG_REGEX, '/') +
        '/' + size + '.jpg'
    }

    return ''
  }
}
