'use strict'

angular
  .module('basalteApp')
  .controller('mainCtrl', [
    '$rootScope',
    '$uiRouterGlobals',
    'UI_HELPER',
    'STATES',
    'BAS_STATE',
    'BAS_MAIN',
    'MainDrawer',
    'BasAppDevice',
    'BasBackground',
    'CurrentRoom',
    'BasVolume',
    mainCtrl
  ])

/**
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {UI_HELPER} UI_HELPER
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_MAIN} BAS_MAIN
 * @param {MainDrawer} MainDrawer
 * @param {BasAppDevice} BasAppDevice
 * @param {BasBackground} BasBackground
 * @param {CurrentRoom} CurrentRoom
 * @param {BasVolume} BasVolume
 */
function mainCtrl (
  $rootScope,
  $uiRouterGlobals,
  UI_HELPER,
  STATES,
  BAS_STATE,
  BAS_MAIN,
  MainDrawer,
  BasAppDevice,
  BasBackground,
  CurrentRoom,
  BasVolume
) {
  var main = this

  /**
   * @type {UI_HELPER}
   */
  main.UI_HELPER = UI_HELPER

  /**
   * @type {MainDrawerState}
   */
  main.mainDrawer = MainDrawer.get()

  /**
   * @type {TBackgroundState}
   */
  main.background = BasBackground.get()

  /**
   * @type {TBasVolumeState}
   */
  main.basVolumeState = BasVolume.get()

  /**
   * @type {number}
   */
  main.dpi = BasAppDevice.getDpi()

  main.swipeLeft = swipeLeft
  main.swipeRight = swipeRight
  main.swipeUp = swipeUp
  main.swipeDown = swipeDown

  function swipeLeft () {

    var currentState

    currentState = $uiRouterGlobals.current.name

    if (currentState === STATES.HOME) {

      CurrentRoom.go(STATES.ROOMS)

    } else if (currentState === STATES.ROOMS) {

      if (CurrentRoom.getRoom()) CurrentRoom.go(STATES.ROOM)
    }
  }

  function swipeRight () {

    var currentState

    currentState = $uiRouterGlobals.current.name

    if (currentState === STATES.ROOMS) {

      CurrentRoom.go(STATES.HOME)

    } else if (currentState === STATES.ROOM) {

      CurrentRoom.go(
        STATES.ROOMS,
        {
          view: BAS_STATE.S_ROOMS_VIEW_HOME
        }
      )
    }
  }

  function swipeUp () {

    $rootScope.$emit(BAS_MAIN.EVT_MAIN_SWIPE_UP)
  }

  function swipeDown () {

    $rootScope.$emit(BAS_MAIN.EVT_MAIN_SWIPE_DOWN)
  }
}
