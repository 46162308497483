'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('LocalCollection', [
    'BAS_LOCAL_LIBRARY',
    'BAS_LIBRARY_ERRORS',
    'LocalParser',
    'LibraryService',
    'BasLibraryCollection',
    'BasUtilities',
    'Logger',
    localCollectionFactory
  ])

/**
 * @param {BAS_LOCAL_LIBRARY} BAS_LOCAL_LIBRARY
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {LocalParser} LocalParser
 * @param LibraryService
 * @param BasLibraryCollection
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns LocalCollection
 */
function localCollectionFactory (
  BAS_LOCAL_LIBRARY,
  BAS_LIBRARY_ERRORS,
  LocalParser,
  LibraryService,
  BasLibraryCollection,
  BasUtilities,
  Logger
) {
  var className = 'Local Collection'

  /**
   * @constructor
   * @extends BasLibraryCollection
   */
  function LocalCollection () {

    BasLibraryCollection.call(this)

    /**
     * @type {string}
     */
    this.contentType = LocalCollection.CT_TRACK

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * @type {boolean}
     */
    this.locked = false

    /**
     * @type {number}
     */
    this.offset = 0

    /**
     * @type {number}
     */
    this.limit = 25

    /**
     * @type {string}
     */
    this.id = ''

    /**
     * @type {string}
     */
    this.specifier = ''

    /**
     * @type {?Promise}
     * @private
     */
    this._allElementsPromise = null

    this._clearPromise = this.clearPromise.bind(this)

    this.class[LocalCollection.CLASS_LOCKED] = false
  }

  // Set new prototype from inherited object
  LocalCollection.prototype = Object.create(BasLibraryCollection.prototype)

  // Set correct constructor after setting new prototype
  LocalCollection.prototype.constructor = LocalCollection

  // region Static constants

  /**
   * @constant {string}
   */
  LocalCollection.CT_ALBUM = 'album'

  /**
   * @constant {string}
   */
  LocalCollection.CT_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  LocalCollection.CT_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  LocalCollection.CT_ALL = 'all'

  /**
   * @constant {string}
   */
  LocalCollection.CT_TRACK = 'track'

  /**
   * @constant {string}
   */
  LocalCollection.TYPE_ALBUM = 'album'

  /**
   * @constant {string}
   */
  LocalCollection.TYPE_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  LocalCollection.TYPE_SEARCH = 'search'

  /**
   * @constant {string}
   */
  LocalCollection.CLASS_LOCKED = 'bas-collection-detail-is-locked'

  // endregion

  LocalCollection.prototype.retrieveFunction = function () {

    switch (this.type) {
      case LocalCollection.TYPE_ARTIST:
        return LibraryService.getArtistSongs
          .bind(null, this.id)
      case LocalCollection.TYPE_ALBUM:
        return LibraryService.getAlbumSongs
          .bind(null, this.id, this.specifier)
      case LocalCollection.TYPE_SEARCH:
        switch (this.contentType) {
          case LocalCollection.CT_ALBUM:
            return LibraryService.getSearchAlbums
              .bind(null, this.id)
          case LocalCollection.CT_ARTIST:
            return LibraryService.getSearchArtists
              .bind(null, this.id)
          case LocalCollection.CT_PLAYLIST:
            return LibraryService.getSearchPlaylists
              .bind(null, this.id)
          case LocalCollection.CT_TRACK:
          default:
            return LibraryService.getSearchSongs
              .bind(null, this.id)
        }
      default:
        switch (this.contentType) {
          case LocalCollection.CT_TRACK:
            return LibraryService.getSongs
          case LocalCollection.CT_ALBUM:
            return LibraryService.getAlbums
          case LocalCollection.CT_ARTIST:
            return LibraryService.getArtists
        }
    }
    return null
  }

  /**
   * @returns {Promise}
   */
  LocalCollection.prototype.retrieve = function () {

    var func = this.retrieveFunction()

    var params = {
      offset: this.offset,
      limit: this.limit
    }

    if (func) {

      // Set fetching state
      this.isFetching = true

      return func(params).then(
        this.processElements.bind(this),
        this.onRetrieveError.bind(this)
      )
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_RETRIEVE_FUNCTION)
  }

  LocalCollection.prototype.onReachedEnd = function () {

    // Check if fetch is needed
    if (
      !this.isFetching &&
      !this.hasReachedEnd
    ) {

      return this.retrieve()
    }

    return Promise.resolve(false)
  }

  /**
   * Promise will resolve with a boolean,
   * indicating whether a $digest is needed or not.
   *
   * @param {(?Object|string|Playlist)[]} elements
   * @returns {Promise}
   */
  LocalCollection.prototype.processElements = function (elements) {

    var processedElements, type

    if (elements.length === 0) {

      this.hasReachedEnd = true

      // If collection is empty, hide it
      if (this.elements.length === 0) {

        this.setTitle('')
        this.lastElement = null
      }

      return Promise.resolve(false)
    }

    type = this.getElementType()
    processedElements = LocalParser.processObjects(elements, type)

    this.elements = this.elements.concat(processedElements)

    this.offset += this.limit

    // Set fetching state
    this.isFetching = false

    return Promise.resolve(true)
  }

  LocalCollection.prototype.getElementType = function () {
    switch (this.contentType) {
      case LocalCollection.CT_TRACK:
        return BAS_LOCAL_LIBRARY.T_EL_TRACK
      case LocalCollection.CT_ARTIST:
        return BAS_LOCAL_LIBRARY.T_EL_ARTIST
      case LocalCollection.CT_ALBUM:
        return BAS_LOCAL_LIBRARY.T_EL_ALBUM
      default:
        return BAS_LOCAL_LIBRARY.T_EL_TRACK
    }
  }

  /**
   * @param {string} contentType
   * @param {boolean} [setTitle]
   */
  LocalCollection.prototype.setContentType = function (
    contentType,
    setTitle
  ) {
    var _this = this

    // Reset watch favourite
    this.watchFavourite = false

    // Set type
    this.contentType = contentType

    // Check type
    switch (contentType) {
      case LocalCollection.CT_ALBUM:

        setTitleChecked('albums')

        break
      case LocalCollection.CT_ARTIST:

        setTitleChecked('artists')

        break
      case LocalCollection.CT_PLAYLIST:

        // Watch Asano favourites
        this.watchFavourite = true

        setTitleChecked('playlists')

        break
      case LocalCollection.CT_TRACK:

        setTitleChecked('songs')

        break
      default:

        // Reset type
        this.contentType = LocalCollection.CT_TRACK

        Logger.warn(className + ' - setType - Unknown type', contentType)
    }

    function setTitleChecked (str) {

      if (setTitle === true) {
        _this.setTitle(BasUtilities.translate(str))
      }
    }
  }

  LocalCollection.prototype.processAllElements = function (elements) {

    this.elements =
      LocalParser.processObjects(elements, this.getElementType())

    // Set fetching state
    this.isFetching = false
    this.hasReachedEnd = true
  }

  /**
   * @returns {Promise}
   */
  LocalCollection.prototype.retrieveAll = function () {

    if (BasUtil.isObject(this.endReachedPromise)) {
      return this.endReachedPromise.then(this.retrieveAll.bind(this))
    }

    if (this._allElementsPromise) return this._allElementsPromise
    if (this.hasReachedEnd) return Promise.resolve()

    if (!this.isFetching) {

      // Set fetching state
      this.isFetching = true

      // Reset limit and offset
      this.limit = 100
      this.offset = 0

      this._allElementsPromise =
        this.getAllElements()
          .then(
            this.processAllElements.bind(this),
            this.onRetrieveError.bind(this)
          )
          .then(this._clearPromise, this._clearPromise)

      return this._allElementsPromise

    } else {
      return Promise.reject('Already fetching')
    }
  }

  LocalCollection.prototype.clearPromise = function () {
    this._allElementsPromise = null
  }

  /**
   * @returns {Promise}
   */
  LocalCollection.prototype.getAllElements = function () {

    var _this, func, elements, params

    _this = this

    func = this.retrieveFunction()
    elements = []

    return func
      ? requestElements()
      : Promise.reject(BAS_LIBRARY_ERRORS.NO_RETRIEVE_FUNCTION)

    function requestElements () {

      params = {
        offset: _this.offset,
        limit: _this.limit
      }

      return func(params).then(checkResult)

      function checkResult (result) {
        // Add items
        elements = elements.concat(result)

        // Increase offset
        _this.offset += _this.limit

        return (result.length > 0)
          ? requestElements()
          : Promise.resolve(elements)
      }
    }
  }

  LocalCollection.prototype.onRetrieveError = function (error) {

    Logger.warn(className + ' - Retrieve ERROR ', error)

    // Fetching is done
    this.isFetching = false

    return Promise.reject(error)
  }

  LocalCollection.prototype.destroy = function () {
    Logger.debug(className + ' - Destroy')
  }

  LocalCollection.prototype.suspend = function () {
    Logger.debug(className + ' - Suspend')
  }

  LocalCollection.prototype.resume = function () {
    Logger.debug(className + ' - Resume')
  }

  /**
   * @param {boolean} bool
   */
  LocalCollection.prototype.setLocked = function (bool) {
    this.locked = bool === true
    this.class[LocalCollection.CLASS_LOCKED] = bool === true
  }

  return LocalCollection
}
