'use strict'

angular
  .module('basalteApp')
  .constant('BAS_LIVE_ACCOUNT', {

    // PROD

    APP_SYNC_REGION: 'eu-west-1',
    APP_SYNC_LINK_ID: 'k2atqy7izrfkrlrjhma7r7mqdy',

    HOME_POOL_ID: 'eu-west-1_NgGdiiOVg',
    HOME_CLIENT_ID: '71mji2s3l5ceg7pc4s1gh57fb4',

    PRO_POOL_ID: 'eu-west-1_NlEjD0LQq',
    PRO_CLIENT_ID: '7u3q1dmngkmop678tho86soboe',

    // DEV

    DEV_APP_SYNC_REGION: 'eu-west-1',
    DEV_APP_SYNC_LINK_ID: 'ywl34zbferculpwzivgyeklizm',

    DEV_HOME_POOL_ID: 'eu-west-1_i2Bl0s8k3',
    DEV_HOME_CLIENT_ID: '1i8c6og7e2rqoi3skjq5v50m7d',

    DEV_PRO_POOL_ID: 'eu-west-1_aEfYuNPAr',
    DEV_PRO_CLIENT_ID: '3erkvqi339jk38fh1vvb0u3gkd',

    EVT_AUTH_STATE_CHECKED: 'evtBasLiveAccountAuthStateChecked',
    EVT_ACCOUNT_NAME_UPDATED: 'evtBasLiveAccountAccountNameUpdated',
    EVT_PROJECTS_UPDATED: 'evtBasLiveAccountProjectsUpdated',
    EVT_LIVE_UI_STATE: 'evtBasLiveAccountUiState',
    EVT_SUBSCRIPTION_STARTED: 'evtBasLiveSubscriptionStarted',
    EVT_SUBSCRIPTION_STOPPED: 'evtBasLiveSubscriptionStopped',
    EVT_NOT_AUTHORIZED: 'evtBasLiveNotAuthorized',

    Q_LIST_MY_PROJECTS: 'listMyProjects',
    Q_LINK_REQUEST: 'linkRequest',
    M_UNLINK_PROJECT: 'unlinkProject',
    Q_ICE_SERVERS: 'getIceServers',
    S_PROJECT_STATUS: 'subscribeToProjectStatus',
    S_LIST_MY_PROJECTS: 'subscribeToMyProjects',
    S_ANSWERS: 'subscribeToAnswers',
    S_ICE_CANDIDATES: 'subscribeToIceCandidatesFromServer',
    M_SEND_OFFER: 'sendOffer',
    M_SEND_ICE_CANDIDATE: 'sendIceCandidateToServer',
    M_REGISTER_FCM_TOKEN: 'registerFcmToken',
    M_UNREGISTER_FCM_TOKEN: 'unregisterFcmToken',
    M_DELETE_ACCOUNT: 'deleteAccount',

    GRAPH_ERR_NOT_AUTHORIZED: 'BasLiveNotAuthorized',
    GRAPH_ERR_NO_INTEGRATOR_ACCESS: 'BasLiveNoIntegratorAccess',

    ERR_CREDENTIALS: 'errBasLiveAccountCredentials',

    A_ERR_USER_NOT_FOUND: 'UserNotFoundException',
    A_ERR_USER_NOT_CONFIRMED: 'UserNotConfirmedException',
    A_ERR_USER_EXISTS: 'UsernameExistsException',
    A_ERR_USER_NOT_AUTHORIZED: 'NotAuthorizedException',
    A_ERR_TOO_MANY_REQUESTS: 'TooManyRequestsException',
    A_ERR_EXPIRED_CODE: 'ExpiredCodeException',

    STORAGE_KEY_BAS_LIVE_STATE: 'LIVE_STATE',
    STORAGE_KEY_BAS_LIVE_LAST_JWT: 'LIVE_LAST_JWT'
  })
