'use strict'

angular
  .module('basalteApp')
  .service('BasDiscoveryLocal', [
    '$window',
    '$rootScope',
    'BAS_DISCOVERY_LOCAL',
    BasDiscoveryLocal
  ])

/**
 * @typedef {Object} TBasDiscoveryLocalState
 * @property {boolean} shouldBeDiscovering
 * @property {boolean} isDiscovering
 * @property {number} startDiscoveryTime
 */

/**
 * Service for discovering basCores via api.basalte.net
 *
 * @constructor
 * @param $window
 * @param $rootScope
 * @param {BAS_DISCOVERY_LOCAL} BAS_DISCOVERY_LOCAL
 */
function BasDiscoveryLocal (
  $window,
  $rootScope,
  BAS_DISCOVERY_LOCAL
) {
  var NATIVE_NSD = true
  var TYPE = '_http._tcp'
  var DOMAIN = 'local.'

  var FILTER_ASANO = 'AsanoS4'
  var FILTER_CORE = 'Core'

  /**
   * @type {string[]}
   */
  var SERVER_FILTERS = [
    FILTER_CORE,
    FILTER_ASANO
  ]

  var _startTimeoutId = 0

  /**
   * @type {TBasDiscoveryLocalState}
   */
  var state = {}
  state.shouldBeDiscovering = false
  state.isDiscovering = false
  state.startDiscoveryTime = 0

  this.get = get
  this.restart = restart
  this.stop = stop

  /**
   * @returns {TBasDiscoveryLocalState}
   */
  function get () {

    return state
  }

  /**
   * (Re-)start discovery
   */
  function restart () {

    var now, diff

    state.shouldBeDiscovering = true

    _clearStartTimeout()

    if (state.isDiscovering) {

      now = Date.now()
      diff = now - state.startDiscoveryTime

      if (diff > BAS_DISCOVERY_LOCAL.MIN_TIMEOUT_BETWEEN_START_MS) {

        _stop()

        _startTimeoutId = setTimeout(
          _start,
          BAS_DISCOVERY_LOCAL.MIN_TIMEOUT_BETWEEN_START_MS
        )
      }

    } else {

      _start()
    }
  }

  /**
   * Stop discovery
   */
  function stop () {

    state.shouldBeDiscovering = false

    _clearStartTimeout()

    _stop()
  }

  function _start () {

    var nsd

    _clearStartTimeout()

    $rootScope.$emit(BAS_DISCOVERY_LOCAL.EVT_DISCOVERY_LOCAL_START)

    nsd = _getBasalteCordovaNsd()

    if (nsd) {

      nsd.watch(
        TYPE,
        DOMAIN,
        SERVER_FILTERS,
        NATIVE_NSD,
        _onServiceFound,
        _onServiceLost
      )
    }

    state.isDiscovering = true
  }

  function _stop () {

    var nsd

    nsd = _getBasalteCordovaNsd()

    if (nsd) nsd.unwatch('')

    state.isDiscovering = false
  }

  function _clearStartTimeout () {

    clearTimeout(_startTimeoutId)
    _startTimeoutId = 0
  }

  function _onServiceFound (service) {

    $rootScope.$emit(
      BAS_DISCOVERY_LOCAL.EVT_DISCOVERY_LOCAL_CORE_SERVICE_FOUND,
      service
    )
  }

  function _onServiceLost (service) {

    $rootScope.$emit(
      BAS_DISCOVERY_LOCAL.EVT_DISCOVERY_LOCAL_CORE_SERVICE_LOST,
      service
    )
  }

  /**
   * @private
   * @returns {?NetworkServiceDiscovery}
   */
  function _getBasalteCordovaNsd () {

    if ($window['basalteCordova'] &&
      $window['basalteCordova']['nsd']) {

      return $window['basalteCordova']['nsd']
    }

    return null
  }
}
