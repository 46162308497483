'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceScreenOrientation', ['BAS_HTML', deviceScreenOrientation])

/**
 *
 * @param BAS_HTML
 */
function deviceScreenOrientation (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.screenOrientation,
    controller: [
      'BasPreferences',
      'BasInputModal',
      'BAS_INPUT_MODAL',
      controller
    ],
    controllerAs: 'orientation'
  }

  /**
   * @param {BasPreferences} BasPreferences
   * @param {BasInputModal} BasInputModal
   * @param BAS_INPUT_MODAL
   */
  function controller (BasPreferences, BasInputModal, BAS_INPUT_MODAL) {

    const orientation = this

    orientation.ORIENTATION_OPTIONS = [
      {
        text: 'portrait',
        translate: true,
        value: 1
      },
      {
        text: 'landscape',
        translate: true,
        value: 2
      },
      {
        text: 'portrait_reverse',
        translate: true,
        value: 3
      },
      {
        text: 'landscape_reverse',
        translate: true,
        value: 0
      }
    ]

    orientation.showEditOrientation = showEditOrientation

    init()

    function init () {
      orientation.orientation = BasPreferences.getScreenOrientation()
      syncUiScreenOrientation()
    }

    function showEditOrientation () {
      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'orientation',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            options: orientation.ORIENTATION_OPTIONS,
            initialValue: orientation.orientation
          }
        ],
        autoAdvance: true,
        allowDismiss: true,
        autoSave: true,
        validCheck: BasUtil.isNEArray
      }).then(onModal)

      function onModal (modal) {

        modal.close.then(function (result) {

          if (BasUtil.isNEArray(result)) {

            orientation.orientation = result[0]
            BasPreferences.setScreenOrientation(orientation.orientation)
            syncUiScreenOrientation()
          }
        })
      }
    }

    function syncUiScreenOrientation () {
      orientation.uiOrientation = orientation.ORIENTATION_OPTIONS
        .find(el => el.value === orientation.orientation)?.text ?? 'not_set'
    }
  }
}
