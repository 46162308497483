'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basImageTransition')
  .directive('basImage', basImageDirective)

function basImageDirective () {

  return {
    restrict: 'AE',
    scope: {},
    template: '<div class="bas-image" ' +
      'ng-style="{' +
      '\'background-image\':\'url({{basImageDir.basImage.loadedUrl}})\',' +
      '\'background-color\':\'{{basImageDir.basImage.loadedColor}}\'' +
      '}" ' +
      'ng-class="basImageDir.basImage.css" ' +
      'ng-bind-html="basImageDir.basImage.loadedHtml" ' +
      'ng-animate-swap="basImageDir.basImage"></div>',
    controller: [
      '$scope',
      '$element',
      '$window',
      'BasImage',
      'BasImageUtil',
      'BasResource',
      'BasUtilities',
      controller
    ],
    controllerAs: 'basImageDir',
    bindToController: {
      basDefaultBasImageTrans: '<?',
      basImageTrans: '<?',
      log: '<?',
      dpi: '<?',
      type: '@?'
    }
  }

  /**
   * @param $scope
   * @param $element
   * @param $window
   * @param BasImage
   * @param {BasImageUtil} BasImageUtil
   * @param {BasResource} BasResource
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $scope,
    $element,
    $window,
    BasImage,
    BasImageUtil,
    BasResource,
    BasUtilities
  ) {
    var basImageDir = this

    var CSS_PARENT = 'bas-image-parent'

    // TODO Handle image gradients and other effects

    var RESIZE_DEBOUNCE_TIME_MS = 200
    var eventOptions = {
      capture: true,
      passive: true,
      once: false
    }
    var _resizeTimeoutId = 0
    var _resizeListener = null

    var IMAGE_LOAD_TIMEOUT_MS = 300
    var _loadImageTimeoutId = 0

    /**
     * @type {?BasImage}
     */
    var basImageToLoad = null

    /**
     * @type {?BasImageTrans}
     */
    basImageDir.bit = null

    /**
     * @type {?BasImage}
     */
    basImageDir.basImage = null

    basImageDir.$onChanges = _onChanges
    basImageDir.$postLink = _onPostLink
    basImageDir.$onDestroy = _onDestroy

    function _onChanges (changes) {

      var ctrlBit, trackedBit
      var prevDefaultBit, currDefaultBit
      var prevBit, currBit
      var syncCss

      ctrlBit = basImageDir.bit
      syncCss = false

      if (changes.basDefaultBasImageTrans) {

        prevDefaultBit = changes.basDefaultBasImageTrans.previousValue
        currDefaultBit = changes.basDefaultBasImageTrans.currentValue

        if (
          prevDefaultBit &&
          prevDefaultBit.removeCssListener
        ) {

          prevDefaultBit.unTrack()
          prevDefaultBit.removeListener(_onBasImageChange)
          prevDefaultBit.removeCssListener(_onBasImageCssChange)
        }

        syncCss = true

      } else {

        currDefaultBit = basImageDir.basDefaultBasImageTrans
      }

      if (changes.basImageTrans) {

        // Remove listener on previous BasImageTrans

        prevBit = changes.basImageTrans.previousValue

        if (
          prevBit &&
          prevBit.removeCssListener
        ) {

          // Un-track the previous bit if it was being tracked
          if (ctrlBit && ctrlBit.getTrackedBit) {

            trackedBit = ctrlBit.getTrackedBit()

            if (trackedBit && trackedBit === prevBit) {

              ctrlBit.unTrack(trackedBit)
            }
          }

          prevBit.removeListener(_onBasImageChange)
          prevBit.removeCssListener(_onBasImageCssChange)
        }

        // Set listener on current BasImageTrans

        currBit = changes.basImageTrans.currentValue

        syncCss = true
      }

      ctrlBit = basImageDir.bit = (currDefaultBit && currDefaultBit.track)
        ? currDefaultBit
        : (currBit && currBit.track) ? currBit : null

      if (ctrlBit && ctrlBit.setCssListener) {

        ctrlBit.setListener(_onBasImageChange)
        ctrlBit.setCssListener(_onBasImageCssChange)

        if (ctrlBit !== currBit) {

          if (currBit && currBit.track) {

            ctrlBit.track(currBit)

          } else {

            ctrlBit.unTrack()
            ctrlBit.changeImageStyle(null)
            ctrlBit.setImage(null)
          }
        }
      }

      if (ctrlBit) {

        if (ctrlBit.image) {

          if (ctrlBit.image !== basImageToLoad) {

            _onBasImageChange(ctrlBit.image)
          }

        } else {

          basImageToLoad = null
          _clearLoadImageTimeoutId()
          _setBasImage(null)
        }

      } else {

        basImageToLoad = null
        _clearLoadImageTimeoutId()
        _setBasImage(null)
      }

      if (syncCss) _syncCss()
    }

    function _onPostLink () {

      _resizeListener = BasUtil.setDOMListener(
        $window,
        'resize',
        _onResize,
        eventOptions
      )
    }

    /**
     * Fires on each resize event. Needs to be debounced
     */
    function _onResize () {

      // Make sure previous timeout is cleared
      clearTimeout(_resizeTimeoutId)

      // Set a new timeout
      _resizeTimeoutId = setTimeout(
        _onDebouncedResize,
        RESIZE_DEBOUNCE_TIME_MS
      )
    }

    function _onDebouncedResize () {

      if (basImageToLoad) {

        if (basImageToLoad.canBeLoaded()) {

          _loadImage(basImageToLoad).then(_onImageLoaded, _onImageLoadedError)
        }
      }

      /**
       * @private
       * @param {BasImage} loadedBasImage
       */
      function _onImageLoaded (loadedBasImage) {

        var currBasImage, currUrl, currHtml, currColor

        if (BasImage.isEqual(basImageToLoad, loadedBasImage)) {

          currBasImage = basImageDir.basImage

          if (currBasImage) {

            currUrl = currBasImage.loadedUrl
            currHtml = currBasImage.loadedHtml
            currColor = currBasImage.loadedColor

            currBasImage.loadedUrl = loadedBasImage.loadedUrl
            currBasImage.loadedHtml = loadedBasImage.loadedHtml
            currBasImage.loadedHtml = loadedBasImage.loadedColor

            if (
              currBasImage.loadedUrl !== currUrl ||
              currBasImage.loadedHtml !== currHtml ||
              currBasImage.loadedColor !== currColor
            ) {
              $scope.$applyAsync()
            }

          } else {

            _clearLoadImageTimeoutId()
            _setBasImage(loadedBasImage)
            $scope.$applyAsync()
          }
        }
      }

      function _onImageLoadedError () {

        // TODO Handle this case?
      }
    }

    /**
     * @private
     * @param {?BasImage} image
     * @param {boolean} [scopeSync = true]
     */
    function _onBasImageChange (image, scopeSync) {

      var _scopeSync, currentBasImageToLoad

      _scopeSync = BasUtil.isBool(scopeSync) ? scopeSync : true

      currentBasImageToLoad = basImageToLoad = image

      _clearLoadImageTimeoutId()

      if (basImageToLoad) {

        if (basImageToLoad.canBeLoaded()) {

          _loadImageTimeoutId = setTimeout(
            _onImageLoadTimeout,
            IMAGE_LOAD_TIMEOUT_MS
          )

          _loadImage(basImageToLoad).then(_onImageLoaded, _onImageLoadedError)

        } else {

          _setBasImage(new BasImage(basImageToLoad))
          if (_scopeSync) $scope.$applyAsync()
        }

      } else {

        _setBasImage(null)
        if (_scopeSync) $scope.$applyAsync()
      }

      /**
       * @private
       * @param {BasImage} loadedBasImage
       */
      function _onImageLoaded (loadedBasImage) {

        if (BasImage.isEqual(basImageToLoad, loadedBasImage)) {

          _clearLoadImageTimeoutId()
          _setBasImage(loadedBasImage)
          $scope.$applyAsync()
        }
      }

      function _onImageLoadedError () {

        if (currentBasImageToLoad === basImageToLoad) {

          _clearLoadImageTimeoutId()
          _setBasImage(null)
          $scope.$applyAsync()
        }
      }

      function _onImageLoadTimeout () {

        if (currentBasImageToLoad === basImageToLoad) {

          _setBasImage(null)
          $scope.$applyAsync()
        }
      }
    }

    function _onBasImageCssChange () {

      _syncCss()
    }

    /**
     * @private
     * @param {?BasImage} image
     */
    function _setBasImage (image) {

      var currImage

      if (image) {

        currImage = basImageDir.basImage

        if (
          currImage &&
          BasImage.isEqual(currImage, image) &&
          BasUtil.compareObjects(currImage.css, image.css) &&
          currImage.loadedUrl === image.loadedUrl &&
          currImage.loadedHtml === image.loadedHtml &&
          currImage.loadedColor === image.loadedColor
        ) {

          // Do nothing

        } else {

          basImageDir.basImage = image
        }

      } else {

        basImageDir.basImage = _getDefaultImage()
      }
    }

    /**
     * @private
     * @param {BasImage} image
     * @returns {Promise<BasImage>}
     */
    function _loadImage (image) {

      var loadedBasImage, ratio, elementSize, sizedImage, quadUrls

      loadedBasImage = new BasImage(image)

      if (image.urlObj) {

        ratio = _getDpi()
        elementSize = _getWidth() * ratio

        sizedImage = BasImageUtil.getSizedImage(
          image.urlObj,
          basImageDir.type,
          elementSize
        )

        return sizedImage
          ? BasResource.getImage(sizedImage)
            .then(_onImageResource)
            .then(_returnLoadedBasImage)
          : Promise.reject(new Error('no sizedImage'))

      } else if (image.url) {

        return BasResource.getImage(image.url)
          .then(_onImageResource)
          .then(_returnLoadedBasImage)

      } else if (image.quadUrl.length >= 4) {

        quadUrls = image.quadUrl.slice()
        return _loadQuads(quadUrls)
          .then(_onImageQuads)
          .then(_returnLoadedBasImage)
      }

      return Promise.reject(new Error('not a valid BasImage'))

      /**
       * @private
       * @param {string} result
       */
      function _onImageResource (result) {

        loadedBasImage.loadedUrl = result
        loadedBasImage.loadedColor = ''
      }

      /**
       * @private
       * @param {string[]} result
       */
      function _onImageQuads (result) {

        loadedBasImage.setProcessedQuad(quadUrls, result)
      }

      /**
       * @private
       * @returns {BasImage}
       */
      function _returnLoadedBasImage () {

        return loadedBasImage
      }
    }

    /**
     * @private
     * @param {string[]} quads
     * @returns {Promise<string[]>}
     */
    function _loadQuads (quads) {

      return new Promise(_loadQuadsPromiseConstructor)

      function _loadQuadsPromiseConstructor (resolve) {

        var length, result, i

        length = 4

        result = new Array(length)
        for (i = 0; i < length; i++) _loadEntry(i)

        /**
         * @private
         * @param {number} index
         */
        function _loadEntry (index) {

          BasResource.getImage(quads[index])
            .then(_onEntryImage, _onEntryImageError)

          function _onEntryImage (entryResult) {

            result[index] = entryResult
            _checkEntries()
          }

          function _onEntryImageError () {

            result[index] = ''
            _checkEntries()
          }
        }

        function _checkEntries () {

          if (_areAllEntriesFinished()) resolve(result)
        }

        /**
         * @private
         * @returns {boolean}
         */
        function _areAllEntriesFinished () {

          var j

          for (j = 0; j < length; j++) {

            if (!BasUtil.isString(result[j])) return false
          }

          return true
        }
      }
    }

    /**
     * @private
     * @returns {?BasImage}
     */
    function _getDefaultImage () {

      var ctrlBit, defaultImage

      ctrlBit = basImageDir.bit

      if (ctrlBit && ctrlBit.setDefaultImage) {

        defaultImage = ctrlBit.defaultImage

        if (defaultImage && defaultImage.setSrc) return defaultImage
      }

      return null
    }

    /**
     * @private
     * @returns {number}
     */
    function _getWidth () {

      var _element

      _element = $element[0]

      return _element ? _element.clientWidth : 0
    }

    /**
     * @private
     * @returns {number}
     */
    function _getDpi () {

      var _ratio

      if (BasUtil.isPNumber(basImageDir.dpi)) return basImageDir.dpi

      _ratio = $window.devicePixelRatio

      return BasUtil.isPNumber(_ratio) ? _ratio : 1
    }

    function _syncCss () {

      var el, parentEl, css

      el = $element[0]

      css = basImageDir.bit
        ? basImageDir.bit.css
        : {}

      if (el) {
        el.className = BasUtilities.generateClassName(el.className, css)

        parentEl = el.parentNode

        if (
          parentEl &&
          BasUtilities.hasClassInClassName(parentEl.className, CSS_PARENT)
        ) {
          parentEl.className =
            BasUtilities.generateClassName(parentEl.className, css)
        }
      }
    }

    function _clearResizeTimeout () {

      clearTimeout(_resizeTimeoutId)
      _resizeTimeoutId = 0
    }

    function _clearLoadImageTimeoutId () {

      clearTimeout(_loadImageTimeoutId)
      _loadImageTimeoutId = 0
    }

    function _clearCurrentBitListener () {

      if (
        basImageDir.basImageTrans &&
        basImageDir.basImageTrans.removeCssListener
      ) {
        basImageDir.basImageTrans.removeListener(_onBasImageChange)
        basImageDir.basImageTrans.removeCssListener(_onBasImageCssChange)
      }
    }

    function _onDestroy () {

      BasUtil.execute(_resizeListener)
      _resizeListener = null

      _clearCurrentBitListener()

      _clearResizeTimeout()
      _clearLoadImageTimeoutId()
    }
  }
}
