'use strict'

var BasUtil = require('@basalte/bas-util')

var Temperature = require('./temperature')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

/**
 * An object representing a thermostat set point
 *
 * @constructor
 */
function SetPoint () {

  /**
   * Time in seconds
   *
   * @type {number}
   */
  this.time = 0

  /**
   * @type {Temperature?}
   */
  this.temperature = new Temperature()

  /**
   * @type {string}
   */
  this.mode = SetPoint.M_ANY

  /**
   * @type {boolean}
   */
  this.off = false
}

/**
 * @constant {string}
 */
SetPoint.M_ANY = P.ANY

/**
 * @constant {string}
 */
SetPoint.M_HEATING = P.HEATING

/**
 * @constant {string}
 */
SetPoint.M_COOLING = P.COOLING

/**
 * @constant {string}
 */
SetPoint.M_AUTO = P.AUTO

/**
 * @param {string} mode
 * @returns {boolean}
 */
SetPoint.isValidMode = function (mode) {

  switch (mode) {
    case SetPoint.M_ANY:
    case SetPoint.M_HEATING:
    case SetPoint.M_COOLING:
    case SetPoint.M_AUTO:
      return true
  }

  return false
}

/**
 * @param {TSetPoint} obj
 * @param {number} index
 * @returns {?SetPoint}
 */
SetPoint.parse = function (obj, index) {

  var result, value

  if (BasUtil.isObject(obj)) {

    result = new SetPoint()

    // Time

    value = obj[P.TIME]
    result.time = BasUtil.isVNumber(value) ? value : index * 3600

    // Temperature

    if (BasUtil.isVNumber(obj[P.CELSIUS])) {

      result.temperature.setCelsius(obj[P.CELSIUS])

    } else if (BasUtil.isVNumber(obj[P.FAHRENHEIT])) {

      result.temperature.setFahrenheit(obj[P.FAHRENHEIT])

    } else {

      result.temperature = null
    }

    // Mode

    result.setMode(obj[P.MODE])

    // Off

    result.off = BasUtil.isBool(obj[P.OFF]) ? obj[P.OFF] : false

    return result
  }

  return null
}

/**
 * Only sets the mode if the mode is valid.
 *
 * @param {string} mode
 */
SetPoint.prototype.setMode = function (mode) {

  if (SetPoint.isValidMode(mode)) this.mode = mode
}

/**
 * @returns {TSetPoint}
 */
SetPoint.prototype.getApiSetPoint = function () {

  var result

  result = {}
  result[P.TIME] = this.time
  result[P.MODE] = this.mode
  if (this.off) {
    result[P.OFF] = true
  } else {
    result[P.CELSIUS] = this.temperature.getTemperature(
      CONSTANTS.TU_CELSIUS
    )
  }

  return result
}

module.exports = SetPoint
