'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasCloudCore', [
    'BAS_API',
    'BasDiscoveredCore',
    'BasAppDevice',
    basCloudCoreFactory
  ])

/**
 * @typedef {Object} TBasCloudCore
 * @property {BasServer} basServer
 * @property {Promise<TBasCloudCore>} promise
 * @property {?BasCloudCore} basCloudCore
 */

/**
 * @param BAS_API
 * @param BasDiscoveredCore
 * @param {BasAppDevice} BasAppDevice
 * @returns BasCloudCore
 */
function basCloudCoreFactory (
  BAS_API,
  BasDiscoveredCore,
  BasAppDevice
) {
  var K_TYPE_ID = 'typeId'
  var K_LOCAL_IP = 'localIp'
  var K_MAC = 'mac'

  var CORE_S4_TYPE_ID = BAS_API.Device.BT.ID_ASANO_S4
  var CORE_MINI_TYPE_ID = BAS_API.Device.BT.ID_CORE_MINI
  var CORE_PLUS_TYPE_ID = BAS_API.Device.BT.ID_CORE_PLUS

  /**
   * @constructor
   * @extends BasDiscoveredCore
   * @param {Object} [data]
   */
  function BasCloudCore (data) {

    BasDiscoveredCore.call(this)

    if (BasUtil.isObject(data)) this.parseCloudCandidate(data)
  }

  BasCloudCore.prototype = Object.create(BasDiscoveredCore.prototype)
  BasCloudCore.prototype.constructor = BasCloudCore

  /**
   * Checks whether the given data is cloud core candidate
   *
   * @param {Object} data
   * @returns {boolean}
   */
  BasCloudCore.isBasCloudCoreCandidate = function (data) {

    var typeId

    typeId = data ? data[K_TYPE_ID] : ''

    return (
      BasUtil.isObject(data) &&
      (
        typeId === CORE_S4_TYPE_ID ||
        typeId === CORE_MINI_TYPE_ID ||
        typeId === CORE_PLUS_TYPE_ID
      ) &&
      BasUtil.isPNumber(data[K_MAC]) &&
      BasUtil.isNEString(data[K_LOCAL_IP])
    )
  }

  /**
   * @param {Object} data
   * @returns {?TBasCloudCore}
   */
  BasCloudCore.createBasCloudCore = function (data) {

    var _result

    if (BasCloudCore.isBasCloudCoreCandidate(data)) {

      _result = {}

      _result.basCloudCore = new BasCloudCore()

      _result.basServer = new BAS_API.BasServer(
        data[K_LOCAL_IP],
        {
          macAddress: data[K_MAC],
          useSubscriptionSocket: BasAppDevice.isCoreClient()
        }
      )

      _result.promise = _result.basServer.getProjectInfo()
        .then(_onProjectInfo)

      return _result
    }

    return null

    /**
     * @private
     * @returns {TBasCloudCore}
     */
    function _onProjectInfo () {

      var _projectInfo

      // TODO project info type
      _projectInfo = _result.basServer.projectInfo

      if (_projectInfo) {

        // TODO project info type
        _result.basCloudCore.coreName =
          _result.basServer.projectCoreName

        _result.basCloudCore.cid =
          _result.basServer.cid

        // TODO project info type
        _result.basCloudCore.projectName =
          _result.basServer.projectName

        _result.basCloudCore.generateUi()
      }

      return _result
    }
  }

  /**
   * @param {Object} data
   */
  BasCloudCore.prototype.parseCloudCandidate = function (data) {

    if (BasCloudCore.isBasCloudCoreCandidate(data)) {

      this.setMac(data[K_MAC])

      if (BasUtil.isNEString(data[K_LOCAL_IP])) {

        this.addresses[0] = data[K_LOCAL_IP]
      }

      this.generateUi()
    }
  }

  return BasCloudCore
}
