'use strict'

angular
  .module('basalteApp')
  .service('BasCoreApiHelper', [
    'BAS_API',
    'STATES',
    'BAS_CONNECTION',
    'BAS_MODAL',
    'BAS_CORE',
    'BasState',
    'BasCoreConnection',
    'CurrentBasCore',
    'BasConnectedDevices',
    'BasModal',
    BasCoreApiHelper
  ])

/**
 * @constructor
 * @param BAS_API
 * @param {STATES} STATES
 * @param {BAS_CONNECTION} BAS_CONNECTION
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_CORE} BAS_CORE
 * @param {BasState} BasState
 * @param {BasCoreConnection} BasCoreConnection
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasConnectedDevices} BasConnectedDevices
 * @param {BasModal} BasModal
 */
function BasCoreApiHelper (
  BAS_API,
  STATES,
  BAS_CONNECTION,
  BAS_MODAL,
  BAS_CORE,
  BasState,
  BasCoreConnection,
  CurrentBasCore,
  BasConnectedDevices,
  BasModal
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasConnectedDevicesState}
   */
  var connectedDevices = BasConnectedDevices.get()

  this.hasUpdate = hasUpdate
  this.getUpdateState = getUpdateState
  this.restartServer = restartServer

  function restartServer () {

    // Show modal
    BasModal.show(BAS_MODAL.T_RESTART_SERVER)
      .then(_onRestartModal)
  }

  function _onRestartModal (modal) {

    modal.close.then(_onRestartModalClose)
  }

  function _onRestartModalClose (result) {

    var _reason

    if (result === BAS_MODAL.C_YES) {

      if (CurrentBasCore.has()) {

        _reason = hasUpdate()
          ? BAS_CONNECTION.REBOOT_REASON_UPDATE
          : BAS_CONNECTION.REBOOT_REASON_RESTART

        BasCoreConnection.prepareRestartServer(_reason)

        BasState.go(
          STATES.CONNECT_DISCOVERY,
          {
            reason: _reason
          }
        )

        BasCoreConnection.restartServer(_reason)
      }
    }
  }

  /**
   * @returns {boolean}
   */
  function hasUpdate () {

    var _basServer, _status, _statusHasUpdate

    _statusHasUpdate = false

    if (CurrentBasCore.hasCore()) {

      _basServer = currentBasCoreState.core.core.server

      if (_basServer) {

        _status = _basServer.status

        _statusHasUpdate = _status ? _status.hasUpdate : false
      }
    }

    return connectedDevices.hasUpdate || _statusHasUpdate
  }

  /**
   * @returns {boolean}
   */
  function getUpdateState () {

    var _basServer, _status, serverDevice

    if (CurrentBasCore.hasCore()) {

      // Use server device

      serverDevice = _getConnectedServerDevice()

      if (serverDevice && serverDevice.device) {

        switch (serverDevice.device.updateStatus) {

          case BAS_API.ServerDevice.UPDATE_STATUS.CHECKING:
            return BAS_CORE.CORE_UPDATE_S_CHECKING
          case BAS_API.ServerDevice.UPDATE_STATUS.DOWNLOADING:
            return BAS_CORE.CORE_UPDATE_S_DOWNLOADING
          case BAS_API.ServerDevice.UPDATE_STATUS.READY:
            return BAS_CORE.CORE_UPDATE_S_READY
          case BAS_API.ServerDevice.UPDATE_STATUS.NONE:
          default:
            return BAS_CORE.CORE_UPDATE_S_NONE
        }
      }

      // Fallback to deprecated update status on basServer

      _basServer = currentBasCoreState.core.core.server

      if (_basServer) {

        _status = _basServer.status

        if (_status && _status.hasUpdate) return BAS_CORE.CORE_UPDATE_S_READY
      }
    }
    return BAS_CORE.CORE_UPDATE_S_NONE
  }

  /**
   * Gets the server device that matches with the connected server
   *
   * @returns {?BasServerDevice|null}
   * @private
   */
  function _getConnectedServerDevice () {

    var server, i, length, checkServer

    if (CurrentBasCore.hasCore()) {

      server = CurrentBasCore.getServer()

      if (server && server.host) {

        length = currentBasCoreState.core.core.servers.length
        for (i = 0; i < length; i++) {

          checkServer = BasConnectedDevices.getServer(
            currentBasCoreState.core.core.servers[i]
          )

          if (
            checkServer &&
            checkServer.device &&
            server.host.host === checkServer.device.ip
          ) {
            return checkServer
          }
        }
      }
    }

    return null
  }
}
