'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('genericDevicesCtrl', [
    '$rootScope',
    '$uiRouterGlobals',
    'BAS_ROOM',
    'BAS_ROOMS',
    'CurrentRoom',
    'BasGenericDeviceV1',
    genericDevicesCtrl
  ])

/**
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 * @param {BasGenericDeviceV1} BasGenericDeviceV1
 */
function genericDevicesCtrl (
  $rootScope,
  $uiRouterGlobals,
  BAS_ROOM,
  BAS_ROOMS,
  CurrentRoom,
  BasGenericDeviceV1
) {
  const devices = this
  let _listeners = []

  /**
   * @type {BasRooms}
   */
  devices.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  devices.currentRoom = CurrentRoom.get()

  init()
  function init () {
    $rootScope.$on('destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_GENERIC_DEVICES_INITIALIZED,
      initializeGenericDevices
    ))

    initializeGenericDevices()
  }

  function initializeGenericDevices () {
    devices.genericDevices = devices.rooms.rooms[devices.currentRoom.roomId]
      ?.genericDevices?.devices ?? []

    if ($uiRouterGlobals.params.genericDeviceUuid) {

      devices.genericDevices = devices.genericDevices.filter(device =>
        device.uuid === $uiRouterGlobals.params.genericDeviceUuid)

      $rootScope.$applyAsync()

    } else {

      devices.genericDevices = devices.genericDevices
        .filter(basGenericDevice => {
          return basGenericDevice instanceof BasGenericDeviceV1
        })

      $rootScope.$applyAsync()
    }
  }

  function _onDestroy () {
    BasUtil.execute(_listeners)
    _listeners = []
  }
}
