'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('timerWeekCtrl', [
    '$scope',
    '$uiRouterGlobals',
    '$transitions',
    'STATES',
    'BAS_ROOMS',
    'BAS_SCHEDULER',
    'BAS_MODAL',
    'BasState',
    'RoomsHelper',
    'BasModal',
    timerWeekCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param $transitions
 * @param {STATES} STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SCHEDULER} BAS_SCHEDULER
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasState} BasState
 * @param {RoomsHelper} RoomsHelper
 * @param {BasModal} BasModal
 */
function timerWeekCtrl (
  $scope,
  $uiRouterGlobals,
  $transitions,
  STATES,
  BAS_ROOMS,
  BAS_SCHEDULER,
  BAS_MODAL,
  BasState,
  RoomsHelper,
  BasModal
) {
  var timer = this

  var _listeners = []

  timer.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BasRooms}
   */
  timer.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TBasStateObj}
   */
  timer.basState = BasState.get()

  /**
   * @type {BasSchedulerLine[]}
   */
  timer.lines = BAS_SCHEDULER.HOUR_LINES_13

  timer.selectEntry = selectEntry
  timer.back = back

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    // Check for unsaved changes
    _listeners.push($transitions.onStart(
      {},
      _onStateChange
    ))
  }

  /**
   * @param {string} entryId
   */
  function selectEntry (entryId) {

    if (entryId) {

      BasState.go(
        STATES.TIMER_DAY,
        {
          day: entryId
        }
      )
    }
  }

  function back () {

    BasState.go(STATES.TIMER_WEEK)
  }

  function _onStateChange (transition) {

    var _timer, _timerId, targetState, transTo, params

    _timerId = _getTimerId()
    _timer = _getTimer()

    if (_timerId && _timer) {

      targetState = transition.targetState()
      transTo = targetState.name()
      params = transition.params()

      if (_timer.dataString !== _timer.makeDataString() &&
        (
          transTo.indexOf(STATES.TIMER_WEEK) === -1 ||
          (
            transTo === STATES.TIMER_WEEK &&
            params.timer !== _timerId
          )
        )
      ) {

        return BasModal.show(BAS_MODAL.T_UNSAVED_CHANGES)
          .then(_onSaveModal)
      }
    }

    function _onSaveModal (modal) {

      return modal.close.then(_onSaveModalClose)
    }

    function _onSaveModalClose (result) {

      var timerObj

      if (result === BAS_MODAL.C_YES) {

        timerObj = _getTimer()

        if (timerObj) {

          timerObj.commit()
          timerObj.updateTimer()
        }

      } else if (result === BAS_MODAL.C_NO) {

        timerObj = _getTimer()
        if (timerObj) timerObj.parse()

      } else {

        return Promise.reject(new Error('invalid modal result'))
      }
    }
  }

  /**
   * @private
   * @returns {?BasTimer}
   */
  function _getTimer () {

    var _timerId, room, _timer

    _timerId = _getTimerId()

    if (_timerId) {

      room = RoomsHelper.getHome()

      if (room && room.timers && room.timers.timers) {

        _timer = room.timers.timers[_timerId]

        if (_timer) return _timer
      }
    }

    return null
  }

  /**
   * @private
   * @returns {string}
   */
  function _getTimerId () {

    var timerId

    timerId = $uiRouterGlobals.params.timer

    return BasUtil.isNEString(timerId) ? timerId : ''
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
