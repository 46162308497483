'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var BasTrack = require('./bas_track')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

/**
 * @typedef {Object} TLibraryTracksResult
 * @property {BasTrack[]} list
 * @property {number} offset
 * @property {number} total
 */

/**
 * @typedef {Object} TLibraryArtistsResult
 * @property {Object[]} list
 * @property {number} offset
 * @property {number} total
 */

/**
 * @typedef {Object} TLibraryAlbumsResult
 * @property {BasTrack[]} list
 * @property {number} offset
 * @property {number} total
 */

/**
 * @typedef {Object} TLibraryAlbumDetailsResult
 * @property {BasTrack} album
 * @property {BasTrack[]} list
 * @property {number} offset
 * @property {number} total
 */

/**
 * @typedef {Object} TLibraryArtistDetailsResult
 * @property {Object} artist
 * @property {BasTrack[]} list
 * @property {number} offset
 * @property {number} total
 */

/**
 * @constant {string}
 */
MusicLibrary.EVT_SCANNING_CHANGED = 'evtMusicLibraryScanningChanged'

/**
 * @constant {string}
 */
MusicLibrary.EVT_LIBRARY_CHANGED = 'evtMusicLibraryChanged'

/**
 * Class representing the library
 *
 * @constructor
 * @param {BasCore} basCore
 */
function MusicLibrary (basCore) {

  EventEmitter.call(this)

  /**
   * @type {BasCore}
   */
  this._basCore = basCore

  this._state = {}

  this._handleTracks = this._onTracks.bind(this)
  this._handleArtists = this._onArtists.bind(this)
  this._handleAlbums = this._onAlbums.bind(this)
  this._handleAlbumDetails = this._onAlbumDetails.bind(this)
  this._handleArtistDetails = this._onArtistDetails.bind(this)
}

MusicLibrary.prototype = Object.create(EventEmitter.prototype)
MusicLibrary.prototype.constructor = MusicLibrary

Object.defineProperties(MusicLibrary.prototype, {

  /**
   * Whether the available property has been received from the server
   *
   * @name MusicLibrary#availableReady
   * @type {boolean}
   * @readonly
   */
  availableReady: {
    get: function () {
      return P.AVAILABLE in this._state
    }
  },

  /**
   * @name MusicLibrary#available
   * @type {boolean}
   * @readonly
   */
  available: {
    get: function () {
      return BasUtil.isBool(this._state[P.AVAILABLE])
        ? this._state[P.AVAILABLE]
        : false
    }
  },

  /**
   * @name MusicLibrary#isScanning
   * @type {boolean}
   * @readonly
   */
  isScanning: {
    get: function () {
      return BasUtil.isBool(this._state[P.SCANNING])
        ? this._state[P.SCANNING]
        : false
    }
  }
})

/**
 * Parse "library" messages
 *
 * @param {Object} msg
 */
MusicLibrary.prototype.parse = function (msg) {

  var oldIsScanning, scanningChanged, libraryChanged

  if (BasUtil.isObject(msg)) {

    // State

    if (BasUtil.isObject(msg[P.STATE])) {

      if (!BasUtil.isEqualObject(this._state, msg[P.STATE])) {

        oldIsScanning = this.isScanning

        BasUtil.mergeObjectsDeep(this._state, msg[P.STATE])

        if (oldIsScanning !== this.isScanning) scanningChanged = true
      }
    }

    // Event

    if (BasUtil.isNEString(msg[P.EVENT])) {

      switch (msg[P.EVENT]) {

        case P.CHANGED:
          libraryChanged = true
          break
      }
    }
  }

  if (scanningChanged) {

    this.emit(MusicLibrary.EVT_SCANNING_CHANGED, this.isScanning)
  }

  if (libraryChanged) {

    this.emit(MusicLibrary.EVT_LIBRARY_CHANGED)
  }
}

/**
 * @param {string} type
 * @param {string} action
 * @param {?Object} [data]
 * @returns {Promise}
 */
MusicLibrary.prototype.action = function (
  type,
  action,
  data
) {
  var msg

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (
    BasUtil.isNEString(type) &&
    BasUtil.isNEString(action)
  ) {

    msg = {}
    msg[P.LIBRARY] = {}
    msg[P.LIBRARY][type] = {}
    msg[P.LIBRARY][type][P.ACTION] = action

    if (BasUtil.isObject(data)) {
      msg[P.LIBRARY][type][P.DATA] = data
    }

    return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT_LONG)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<Object>}
 */
MusicLibrary.prototype.listTracks = function (
  offset,
  limit
) {
  var data

  if (
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, true)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit

    return this.action(
      P.TRACKS,
      P.LIST,
      data
    ).then(this._handleTracks)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {string} query
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<Object>}
 */
MusicLibrary.prototype.searchTracks = function (
  query,
  offset,
  limit
) {
  var data

  if (
    BasUtil.isNEString(query) &&
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, true)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit
    data[P.QUERY] = query

    return this.action(
      P.TRACKS,
      P.SEARCH,
      data
    ).then(this._handleTracks)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

MusicLibrary.prototype._onTracks = function (msg) {

  var length, i, list, result

  /**
   * @type {TLibraryTracksResult}
   */
  result = {
    list: [],
    offset: 0,
    total: 0
  }

  if (
    BasUtil.isObject(msg[P.LIBRARY]) &&
    BasUtil.isObject(msg[P.LIBRARY][P.TRACKS]) &&
    Array.isArray(msg[P.LIBRARY][P.TRACKS][P.LIST])
  ) {

    list = msg[P.LIBRARY][P.TRACKS][P.LIST]

    length = list.length
    for (i = 0; i < length; i++) result.list.push(BasTrack.parse(list[i]))

    this._basCore.processCoverArts(result.list, false)

    if (BasUtil.isPNumber(msg[P.LIBRARY][P.TRACKS][P.TOTAL])) {

      result.total = msg[P.LIBRARY][P.TRACKS][P.TOTAL]
    }

    if (BasUtil.isPNumber(msg[P.LIBRARY][P.TRACKS][P.OFFSET])) {

      result.offset = msg[P.LIBRARY][P.TRACKS][P.TOTAL]
    }

    return result
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise}
 */
MusicLibrary.prototype.listAlbums = function (
  offset,
  limit
) {
  var data

  if (
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, true)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit

    return this.action(
      P.ALBUMS,
      P.LIST,
      data
    ).then(this._handleAlbums)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

MusicLibrary.prototype._onAlbums = function (msg) {

  var length, i, list, result, albums

  /**
   * @type {TLibraryAlbumsResult}
   */
  result = {
    list: [],
    offset: 0,
    total: 0
  }

  if (
    BasUtil.isObject(msg[P.LIBRARY]) &&
    BasUtil.isObject(msg[P.LIBRARY][P.ALBUMS])
  ) {

    albums = msg[P.LIBRARY][P.ALBUMS]

    if (Array.isArray(albums[P.LIST])) {

      list = albums[P.LIST]

      length = list.length

      for (i = 0; i < length; i++) {
        result.list.push(BasTrack.parse(list[i], { album: true }))
      }
    }

    if (BasUtil.isPNumber(albums[P.TOTAL], true)) {

      result.total = albums[P.TOTAL]
    }

    if (BasUtil.isPNumber(albums[P.OFFSET], true)) {

      result.offset = albums[P.OFFSET]
    }

    return result
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * @param {string} query
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<Object>}
 */
MusicLibrary.prototype.searchAlbums = function (
  query,
  offset,
  limit
) {
  var data

  if (
    BasUtil.isNEString(query) &&
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, true)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit
    data[P.QUERY] = query

    return this.action(
      P.ALBUMS,
      P.SEARCH,
      data
    ).then(this._handleAlbums)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {string} uri
 * @param {?number} [offset]
 * @param {?number} [limit]
 * @returns {Promise}
 */
MusicLibrary.prototype.getAlbumDetails = function (
  uri,
  offset,
  limit
) {
  var data

  if (BasUtil.isNEString(uri)) {

    data = {}
    data[P.URI] = uri

    if (BasUtil.isPNumber(offset, true)) {

      data[P.OFFSET] = offset
    }

    if (BasUtil.isPNumber(limit, true)) {

      data[P.LIMIT] = limit
    }

    return this.action(
      P.ALBUMS,
      P.DETAIL,
      data
    ).then(this._handleAlbumDetails)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

MusicLibrary.prototype._onAlbumDetails = function (msg) {

  var length, i, tracks, result, detail

  /**
   * @type {TLibraryAlbumDetailsResult}
   */
  result = {
    list: [],
    offset: 0,
    total: 0,
    album: null
  }

  if (
    BasUtil.isObject(msg[P.LIBRARY]) &&
    BasUtil.isObject(msg[P.LIBRARY][P.ALBUMS]) &&
    BasUtil.isObject(msg[P.LIBRARY][P.ALBUMS][P.DETAIL])
  ) {

    detail = msg[P.LIBRARY][P.ALBUMS][P.DETAIL]

    if (Array.isArray(detail[P.TRACKS])) {

      tracks = detail[P.TRACKS]

      length = tracks.length

      for (i = 0; i < length; i++) {
        result.list.push(BasTrack.parse(tracks[i]))
      }

      this._basCore.processCoverArts(result.list, false)
    }

    if (BasUtil.isPNumber(detail[P.TOTAL], true)) {

      result.total = detail[P.TOTAL]
    }

    if (BasUtil.isPNumber(detail[P.OFFSET], true)) {

      result.offset = detail[P.OFFSET]
    }

    result.album = BasTrack.parse(detail, { album: true })

    return result
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise}
 */
MusicLibrary.prototype.listArtists = function (
  offset,
  limit
) {
  var data

  if (
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, true)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit

    return this.action(
      P.ARTISTS,
      P.LIST,
      data
    ).then(this._handleArtists)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {string} uri
 * @param {?number} [offset]
 * @param {?number} [limit]
 * @returns {Promise}
 */
MusicLibrary.prototype.getArtistDetails = function (
  uri,
  offset,
  limit
) {
  var data

  if (BasUtil.isNEString(uri)) {

    data = {}
    data[P.URI] = uri

    if (BasUtil.isPNumber(offset, true)) {

      data[P.OFFSET] = offset
    }

    if (BasUtil.isPNumber(limit, true)) {

      data[P.LIMIT] = limit
    }

    return this.action(
      P.ARTISTS,
      P.DETAIL,
      data
    ).then(this._handleArtistDetails)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

MusicLibrary.prototype._onArtistDetails = function (msg) {

  var length, i, tracks, result, detail

  /**
   * @type {TLibraryArtistDetailsResult}
   */
  result = {
    list: [],
    offset: 0,
    total: 0,
    artist: null
  }

  if (
    BasUtil.isObject(msg[P.LIBRARY]) &&
    BasUtil.isObject(msg[P.LIBRARY][P.ARTISTS]) &&
    BasUtil.isObject(msg[P.LIBRARY][P.ARTISTS][P.DETAIL])
  ) {

    detail = msg[P.LIBRARY][P.ARTISTS][P.DETAIL]

    if (Array.isArray(detail[P.TRACKS])) {

      tracks = detail[P.TRACKS]

      length = tracks.length

      for (i = 0; i < length; i++) {
        result.list.push(BasTrack.parse(tracks[i]))
      }

      this._basCore.processCoverArts(result.list, false)
    }

    if (BasUtil.isPNumber(detail[P.TOTAL], true)) {

      result.total = detail[P.TOTAL]
    }

    if (BasUtil.isPNumber(detail[P.OFFSET], true)) {

      result.offset = detail[P.OFFSET]
    }

    if (detail[P.ARTIST]) {

      result.artist = detail[P.ARTIST]
    }

    return result
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * @param {string} query
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise}
 */
MusicLibrary.prototype.searchArtists = function (
  query,
  offset,
  limit
) {
  var data

  if (
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, true) &&
    BasUtil.isNEString(query)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit
    data[P.QUERY] = query

    return this.action(
      P.ARTISTS,
      P.SEARCH,
      data
    ).then(this._handleArtists)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

MusicLibrary.prototype._onArtists = function (msg) {

  var length, i, list, result

  /**
   * @type {TLibraryArtistsResult}
   */
  result = {
    list: [],
    offset: 0,
    total: 0
  }

  if (
    BasUtil.isObject(msg[P.LIBRARY]) &&
    BasUtil.isObject(msg[P.LIBRARY][P.ARTISTS]) &&
    Array.isArray(msg[P.LIBRARY][P.ARTISTS][P.LIST])
  ) {

    list = msg[P.LIBRARY][P.ARTISTS][P.LIST]

    // TODO: processing needed?
    length = list.length
    for (i = 0; i < length; i++) result.list.push(list[i])

    this._basCore.processCoverArts(result.list, false)

    return result
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

MusicLibrary.prototype.scan = function () {

  var msg

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  msg = {}
  msg[P.LIBRARY] = {}
  msg[P.LIBRARY][P.ACTION] = P.SCAN

  return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT_LONG)
}

MusicLibrary.prototype.reset = function () {

  this._state = {}
  this.hasContent = false
}

MusicLibrary.prototype.destroy = function () {

  this._basCore = null
}

module.exports = MusicLibrary
