'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basWeatherCarousel', [
    'BAS_HTML',
    basWeatherCarouselDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basWeatherCarousel
 */
function basWeatherCarouselDirective (
  BAS_HTML
) {
  return {
    restrict: 'A',
    template: BAS_HTML.weatherCarousel,
    controller: [
      '$scope',
      '$element',
      '$rootScope',
      'Swiper',
      'BAS_ROOM',
      'BAS_WEATHER',
      'BasUtilities',
      'Weather',
      controller
    ]
  }

  /**
   * @param $scope
   * @param $element
   * @param $rootScope
   * @param Swiper
   * @param {BAS_ROOM} BAS_ROOM
   * @param {BAS_WEATHER} BAS_WEATHER
   * @param {BasUtilities} BasUtilities
   * @param {Weather} Weather
   */
  function controller (
    $scope,
    $element,
    $rootScope,
    Swiper,
    BAS_ROOM,
    BAS_WEATHER,
    BasUtilities,
    Weather
  ) {
    var element, paginationElement, swiper, listeners

    listeners = []

    init()

    function init () {

      $scope.$on('$destroy', _onDestroy)

      listeners.push($rootScope.$on(
        BAS_ROOM.EVT_WEATHER_STATIONS_UPDATED,
        _onWeatherStationsUpdated
      ))

      // If we immediately sync the swiper, the page css is not yet rendered.
      //  Since swiper uses the visibility of the element to check if it should
      //  be a swipe element, doing this too soon wil result in an incorrect
      //  number of pages. Syncing the swiper after 2 frames will make sure it
      //  is called after css changes were rendered, resulting in a correct
      //  swiper page count.
      BasUtilities.waitForFrames(2, initSwiper)

      function initSwiper () {

        element = $element[0]
        paginationElement =
          element.getElementsByClassName('swiper-pagination')[0]

        swiper = new Swiper(
          element,
          {
            pagination: {
              el: paginationElement,
              renderBullet: function (_index, className) {
                return '<span class="' + className + ' bas-page-bullet">' +
                  '<div class="bas-page-bullet-color"></div>' +
                  '</span>'
              },
              dynamicBullets: true,
              dynamicMainBullets: 5
            },
            watchOverflow: true,
            initialSlide: Weather.getIndexOfDefault(),
            speed: 500
          }
        )

        swiper.on('slideChange', _onSlideChange)
      }
    }

    function _onWeatherStationsUpdated () {

      BasUtilities.waitFrames(2).then(_updateSwiper)
    }

    function _updateSwiper () {

      if (swiper) swiper.update()
    }

    function _onSlideChange () {

      if (swiper) {

        $rootScope.$emit(
          BAS_WEATHER.EVT_WEATHER_DEFAULT_CHANGED,
          swiper.activeIndex
        )
      }
    }

    function _onDestroy () {

      if (swiper) swiper.destroy(true, false)
      swiper = null

      BasUtil.executeArray(listeners)
      listeners = []
    }
  }
}
