'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('deezerSettingsCtrl', [
    '$rootScope',
    '$scope',
    'BAS_API',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_MODAL',
    'BAS_HTML',
    'Sources',
    'BasInAppBrowser',
    'CurrentBasCore',
    'BasModal',
    'Logger',
    'ModalService',
    deezerSettingsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param BAS_API
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_HTML} BAS_HTML
 * @param {Sources} Sources
 * @param BasInAppBrowser
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasModal} BasModal
 * @param Logger
 * @param ModalService
 */
function deezerSettingsCtrl (
  $rootScope,
  $scope,
  BAS_API,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_MODAL,
  BAS_HTML,
  Sources,
  BasInAppBrowser,
  CurrentBasCore,
  BasModal,
  Logger,
  ModalService
) {
  var deezer = this

  var _inAppBrowser = null

  var _deezerId = -1
  var _deezerListeners = []

  var _listeners = []

  deezer.BAS_SOURCES = BAS_SOURCES
  deezer.BAS_SOURCE = BAS_SOURCE
  deezer.currentBasCoreState = CurrentBasCore.get()

  deezer.toggleLink = toggleLink

  $scope.$on('$destroy', onDestroy)

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SOURCES.EVT_SOURCES_UPDATED,
      _onSourcesUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_DEEZER_LINK_CHANGED,
      _onLinkChanged
    ))

    Sources.registerFor(BAS_SOURCE.COL_EVT_DEEZER)

    allDeezerLinked()
  }

  /**
   * Checks all Deezer sources for their link status
   */
  function allDeezerLinked () {

    var sources, keys, i, length, source

    sources = CurrentBasCore.hasAVFullSupport()
      ? BAS_SOURCES.SOURCES.audioSources
      : BAS_SOURCES.SOURCES.players
    keys = Object.keys(sources)
    length = keys.length
    for (i = 0; i < length; i++) {

      source = BAS_SOURCES.SOURCES.sources[sources[keys[i]]]

      if (
        source &&
        (
          source.type === BAS_SOURCE.T_PLAYER ||
          source.type === BAS_SOURCE.T_ASANO
        ) &&
        source.deezer
      ) {

        source.deezer.linked().then(_onLinkChanged)
      }
    }
  }

  /**
   * @param {string} sourceId
   */
  function toggleLink (sourceId) {

    var basSource = _getBasSource()

    _removeDeezerListeners()

    if (basSource) basSource.deezer.linked().then(_onDeezerLinked)

    function _onDeezerLinked (result) {

      return ModalService.showModal({
        template: BAS_HTML.streamingServiceSettingsModal,
        controller: 'streamingServiceSettingsModalCtrl',
        controllerAs: 'modal',
        inputs: {
          sourceName: basSource.name,
          streamingServiceTransId: 'deezer',
          linkedName: result ? basSource.deezer.longLink : '',
          unlink: unlink,
          link: link
        }
      })
    }

    function unlink () {

      var basSourceToDisconnect = _getBasSource()

      ModalService.closeModals()

      if (
        basSourceToDisconnect &&
        basSourceToDisconnect.deezer
      ) {

        basSourceToDisconnect.deezer.unlink()
      }
    }

    function link () {

      var basSourceToLink = _getBasSource()

      ModalService.closeModals()

      if (
        basSourceToLink &&
        basSourceToLink.deezer
      ) {

        basSourceToLink.deezer.getLinkUrl().then(_onLinkUrl)
      }
    }

    function _onLinkUrl (url) {

      var basSourceToListenFor

      Logger.info('Deezer Link URL', url)

      if (BasUtil.isNEString(url)) {

        basSourceToListenFor = _getBasSource()

        if (basSourceToListenFor) _setDeezerListenersFor(basSourceToListenFor)

        _closeInAppBrowser()
        _inAppBrowser = new BasInAppBrowser(url)
      }
    }

    /**
     * @private
     * @returns {?BasSource}
     */
    function _getBasSource () {

      var _basSource = BAS_SOURCES.SOURCES.sources[sourceId]

      if (
        _basSource &&
        (
          _basSource.type === BAS_SOURCE.T_PLAYER ||
          _basSource.type === BAS_SOURCE.T_ASANO
        ) &&
        _basSource.deezer
      ) {

        return _basSource
      }

      return null
    }
  }

  function _onSourcesUpdated () {

    allDeezerLinked()
    _setDeezerListeners()
  }

  function _onLinkChanged () {

    ModalService.closeModals()

    $scope.$applyAsync()
  }

  function _closeInAppBrowser () {

    if (BasUtil.isObject(_inAppBrowser)) {

      _inAppBrowser.closeInAppBrowser()
      _inAppBrowser = null
    }
  }

  /**
   * @private
   * @param {BasSource} basSource
   */
  function _setDeezerListenersFor (basSource) {

    _deezerId = basSource.getId()
    _setDeezerListeners()
  }

  function _setDeezerListeners () {

    var basSource = BAS_SOURCES.SOURCES.sources[_deezerId]

    _clearDeezerListeners()

    if (
      basSource &&
      basSource.source
    ) {

      if (basSource.isAudioSource) {

        _deezerListeners.push(BasUtil.setEventListener(
          basSource.source,
          BAS_API.AudioSource.EVT_STREAMING_SERVICE_LINK_FINISHED,
          _onAudioSourceLinkFinished
        ))

      } else if (basSource.source.deezer) {

        _deezerListeners.push(BasUtil.setEventListener(
          basSource.source.deezer,
          BAS_API.Deezer.EVT_LINK_FINISHED,
          _onLinkFinished
        ))

        _deezerListeners.push(BasUtil.setEventListener(
          basSource.source.deezer,
          BAS_API.Deezer.EVT_LINK_ERROR,
          _onLinkError
        ))
      }
    }
  }

  function _onLinkFinished () {

    _removeDeezerListeners()

    _closeInAppBrowser()
  }

  function _onLinkError () {

    _removeDeezerListeners()

    _closeInAppBrowser()

    BasModal.show(BAS_MODAL.T_DEEZER_OAUTH_ERROR)
  }

  function _onAudioSourceLinkFinished (data) {

    if (data.streamingService === BAS_API.AudioSource.A_SS_DEEZER) {

      _removeDeezerListeners()

      // Don't close inappbrowser, succes page will close itself after 5 seconds

      if (data.error) {

        _closeInAppBrowser()
        BasModal.show(BAS_MODAL.T_DEEZER_OAUTH_ERROR)
      }
    }
  }

  function _removeDeezerListeners () {

    _deezerId = -1
    _clearDeezerListeners()
  }

  function _clearDeezerListeners () {

    BasUtil.executeArray(_deezerListeners)
    _deezerListeners = []
  }

  function onDestroy () {

    _removeDeezerListeners()

    BasUtil.executeArray(_listeners)
    _listeners = []

    Sources.unregisterFor(BAS_SOURCE.COL_EVT_DEEZER)
  }
}
