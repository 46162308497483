'use strict'

angular
  .module('basalteApp')
  .directive('basVideoButtonGrids', [
    'BAS_HTML',
    basVideoButtonGridsDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basVideoButtonGridsDirective
 */
function basVideoButtonGridsDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.basVideoButtonGrids,
    controller: [
      'ICONS',
      controller
    ],
    controllerAs: 'basVideoButtonGridsCtrl',
    bindToController: {
      basSource: '<?'
    }
  }

  /**
   * @param {ICONS} ICONS
   */
  function controller (ICONS) {

    var basVideoButtonGridsCtrl = this

    basVideoButtonGridsCtrl.iconMap = {}
    basVideoButtonGridsCtrl.iconMap[1] = ICONS.stop
    basVideoButtonGridsCtrl.iconMap[2] = ICONS.eject
    basVideoButtonGridsCtrl.iconMap[3] = ICONS.search
    basVideoButtonGridsCtrl.iconMap[4] = ICONS.microphone
    basVideoButtonGridsCtrl.iconMap[5] = ICONS.subtitles
    basVideoButtonGridsCtrl.iconMap[6] = ICONS.netflix
    basVideoButtonGridsCtrl.iconMap[7] = ICONS.skipNext
    basVideoButtonGridsCtrl.iconMap[8] = ICONS.skipPrevious
    basVideoButtonGridsCtrl.iconMap[9] = ICONS.play
    basVideoButtonGridsCtrl.iconMap[10] = ICONS.pause
    basVideoButtonGridsCtrl.iconMap[11] = ICONS.fastForward
    basVideoButtonGridsCtrl.iconMap[12] = ICONS.reverse
    basVideoButtonGridsCtrl.iconMap[13] = ICONS.record
  }
}
