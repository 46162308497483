'use strict'

var DSP_PROFILE_FLAT_ID = 'dsp_profile_flat'
var DSP_PROFILE_POP_ID = 'dsp_profile_pop'
var DSP_PROFILE_JAZZ_ID = 'dsp_profile_jazz'
var DSP_PROFILE_ROCK_ID = 'dsp_profile_rock'
var DSP_PROFILE_CLUB_ID = 'dsp_profile_club'
var DSP_PROFILE_LIVE_ID = 'dsp_profile_live'
var DSP_PROFILE_CUSTOM_ID = 'dsp_profile_custom'

var DSP_PROFILE_PRESETS = {}

DSP_PROFILE_PRESETS[DSP_PROFILE_POP_ID] = {
  id: DSP_PROFILE_POP_ID,
  equalisers: [
    {
      id: 0,
      gain: 0.9,
      frequency: 64
    },
    {
      id: 1,
      gain: 2.7,
      frequency: 250
    },
    {
      id: 2,
      gain: -0.9,
      frequency: 1000
    },
    {
      id: 3,
      gain: -1.6,
      frequency: 4000
    },
    {
      id: 4,
      gain: -1.6,
      frequency: 16000
    }
  ]
}

DSP_PROFILE_PRESETS[DSP_PROFILE_JAZZ_ID] = {
  id: DSP_PROFILE_JAZZ_ID,
  equalisers: [
    {
      id: 0,
      gain: 2.4,
      frequency: 64
    },
    {
      id: 1,
      gain: 0,
      frequency: 250
    },
    {
      id: 2,
      gain: 3.5,
      frequency: 1000
    },
    {
      id: 3,
      gain: 0,
      frequency: 4000
    },
    {
      id: 4,
      gain: 0,
      frequency: 16000
    }
  ]
}

DSP_PROFILE_PRESETS[DSP_PROFILE_ROCK_ID] = {
  id: DSP_PROFILE_ROCK_ID,
  equalisers: [
    {
      id: 0,
      gain: 0.9,
      frequency: 64
    },
    {
      id: 1,
      gain: -1.1,
      frequency: 250
    },
    {
      id: 2,
      gain: 1.1,
      frequency: 1000
    },
    {
      id: 3,
      gain: 3.1,
      frequency: 4000
    },
    {
      id: 4,
      gain: 3.1,
      frequency: 16000
    }
  ]
}

DSP_PROFILE_PRESETS[DSP_PROFILE_CLUB_ID] = {
  id: DSP_PROFILE_CLUB_ID,
  equalisers: [
    {
      id: 0,
      gain: 0,
      frequency: 64
    },
    {
      id: 1,
      gain: 0.9,
      frequency: 250
    },
    {
      id: 2,
      gain: 2.4,
      frequency: 1000
    },
    {
      id: 3,
      gain: 0,
      frequency: 4000
    },
    {
      id: 4,
      gain: 0,
      frequency: 16000
    }
  ]
}

DSP_PROFILE_PRESETS[DSP_PROFILE_LIVE_ID] = {
  id: DSP_PROFILE_LIVE_ID,
  equalisers: [
    {
      id: 0,
      gain: 0,
      frequency: 64
    },
    {
      id: 1,
      gain: 2.5,
      frequency: 250
    },
    {
      id: 2,
      gain: 2.5,
      frequency: 1000
    },
    {
      id: 3,
      gain: 1,
      frequency: 4000
    },
    {
      id: 4,
      gain: 0.2,
      frequency: 16000
    }
  ]
}

DSP_PROFILE_PRESETS[DSP_PROFILE_FLAT_ID] = {
  id: DSP_PROFILE_FLAT_ID,
  equalisers: [
    {
      id: 0,
      gain: 0,
      frequency: 64
    },
    {
      id: 1,
      gain: 0,
      frequency: 250
    },
    {
      id: 2,
      gain: 0,
      frequency: 1000
    },
    {
      id: 3,
      gain: 0,
      frequency: 4000
    },
    {
      id: 4,
      gain: 0,
      frequency: 16000
    }
  ]
}

angular
  .module('basalteApp')
  .constant('BAS_DSP', {
    DSP_PROFILE_FLAT_ID: DSP_PROFILE_FLAT_ID,
    DSP_PROFILE_POP_ID: DSP_PROFILE_POP_ID,
    DSP_PROFILE_JAZZ_ID: DSP_PROFILE_JAZZ_ID,
    DSP_PROFILE_ROCK_ID: DSP_PROFILE_ROCK_ID,
    DSP_PROFILE_CLUB_ID: DSP_PROFILE_CLUB_ID,
    DSP_PROFILE_LIVE_ID: DSP_PROFILE_LIVE_ID,
    DSP_PROFILE_CUSTOM_ID: DSP_PROFILE_CUSTOM_ID,
    DSP_PROFILES: [
      DSP_PROFILE_FLAT_ID,
      DSP_PROFILE_POP_ID,
      DSP_PROFILE_JAZZ_ID,
      DSP_PROFILE_ROCK_ID,
      DSP_PROFILE_CLUB_ID,
      DSP_PROFILE_LIVE_ID
    ],
    DSP_PROFILE_PRESETS: DSP_PROFILE_PRESETS
  })
