'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('connectModalCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_SPLASH',
    'BAS_MODAL',
    'BasAppDevice',
    'BasLiveAccount',
    'BasState',
    'BasSplash',
    'modalHelperService',
    'basAppHeaderActionOptions',
    'close',
    'event',
    connectModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasAppDevice} BasAppDevice
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasState} BasState
 * @param {BasSplash} BasSplash
 * @param {modalHelperService} modalHelperService
 * @param basAppHeaderActionOptions
 * @param close
 * @param event
 */
function connectModalCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_SPLASH,
  BAS_MODAL,
  BasAppDevice,
  BasLiveAccount,
  BasState,
  BasSplash,
  modalHelperService,
  basAppHeaderActionOptions,
  close,
  event
) {
  var modal = this

  var basButtonClass = 'modal-target'

  var _listeners = []

  /**
   * @type {TBasStateObj}
   */
  var basState = BasState.get()

  /**
   * @type {BAS_MODAL}
   */
  modal.BAS_MODAL = BAS_MODAL

  /**
   * @type {TBasLiveAccountState}
   */
  modal.basLive = BasLiveAccount.get()

  modal.style = modalHelperService.getModalStyle()

  modal.showDisovery = false
  modal.showLive = false
  modal.showAddServer = false
  modal.showDemo = false

  modal.close = close

  init()

  function init () {

    var isBasSplashContext

    if (basAppHeaderActionOptions) {

      isBasSplashContext = basAppHeaderActionOptions.basSplashContext
    }

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    if (isBasSplashContext) {

      modal.showDisovery = BasSplash.canGoHome()
      modal.showDemo = true

    } else {

      if (
        !basState.current.CONNECT_DISCOVERY &&
        basState.current.CONNECT_LIVE &&
        modal.basLive.isLoggedIn
      ) {

        modal.showDisovery = true
      }

      if (basState.current.CONNECT_DISCOVERY) {

        modal.showAddServer = true
      }

      if (
        BasAppDevice.supportsBasalteLive() &&
        !basState.current.CONNECT_DISCOVERY &&
        $uiRouterGlobals.current.name !== STATES.CONNECT_LIVE
      ) {

        modal.showLive = true
      }

      if (
        basState.current.CONNECT_LIVE ||
        basState.current.CONNECT_DISCOVERY
      ) {

        modal.showDemo = true
      }
    }

    modalHelperService.calcPosition(
      event,
      basButtonClass,
      modalHelperService.type.verticalRight
    )
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
