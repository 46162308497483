'use strict'

angular
  .module('basalteApp')
  .constant('BAS_PREFERENCES', {
    K_DEVICE: 'DEVICE',
    K_ELLIE: 'ELLIE',
    DEF_LANGUAGE: '',
    DEF_TEMP_UNIT: '',
    GAIN_MAX: 4,
    GAIN_MIN: -15,
    SLEEP_10: 10,
    SLEEP_30: 30,
    SLEEP_60: 60,
    SLEEP_120: 120,
    SLEEP_300: 300,
    SLEEP_600: 600,
    SLEEP_NEVER: 999999999,
    VIEW_LAST_VIEW: 0,
    VIEW_HOME: 1,
    VIEW_CCD_ROOM: 2,
    VIEW_ROOMS: 3,
    VIEW_SINGLE_ROOM: 4,
    VIEW_LAST_ROOM: 5,
    PROXIMITY_OFF: 0,
    PROXIMITY_NEAR: 1,
    PROXIMITY_MEDIUM: 2,
    PROXIMITY_FAR: 3,
    DEF_PAUSE_DISCONNECT_TIMEOUT_MS: 120000,
    DEF_ENABLE_NOTIFICATIONS: true,
    TIME_FORMAT_LOCALE_DEFAULT: 'locale_default',
    TIME_FORMAT_12h: '12h',
    TIME_FORMAT_24h: '24h',
    START_PAGE_WIDGET_TIME: 0,
    START_PAGE_WIDGET_TIME_DATE: 1,
    START_PAGE_WIDGET_WEATHER: 2,
    CLOUD_ENVIRONMENT_AUTO: 0,
    CLOUD_ENVIRONMENT_PROD: 1,
    CLOUD_ENVIRONMENT_DEV: 2,
    EVT_TIME_FORMAT_PREFERENCE_CHANGED: 'evtTimeFormatPreferenceChanged',
    EVT_ENABLE_NOTIFICATIONS_CHANGED:
      'evtPreferencesEnableNotificationsChanged'
  })
