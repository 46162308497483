'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('LocalManager', [
    '$rootScope',
    'BAS_FAVOURITE',
    'BAS_HTML',
    'BAS_CURRENT_CORE',
    'BAS_LIBRARY',
    'BAS_API',
    'BAS_MODAL',
    'BAS_APP_PROFILE',
    'BAS_LOCAL_LIBRARY',
    'BasModal',
    'ModalService',
    'modalHelperService',
    'Selection',
    'LibraryState',
    'BasCurrentAppProfile',
    'BasLibraryManager',
    'BasLibraryTab',
    'BasLibraryPage',
    'BasLibraryBody',
    'BasLibraryDetail',
    'LocalLibraryPage',
    'LocalPlaylistCollection',
    'LocalCollection',
    'LocalParser',
    'LocalModel',
    'CurrentBasCore',
    'BasSourceLibraryHelper',
    'BasUtilities',
    'Logger',
    LocalManagerFactory
  ])

/**
 * @param $rootScope
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param BAS_API
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param {BAS_LOCAL_LIBRARY} BAS_LOCAL_LIBRARY
 * @param {BasModal} BasModal
 * @param {ModalService} ModalService
 * @param modalHelperService
 * @param Selection
 * @param {LibraryState} LibraryState
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param BasLibraryManager
 * @param BasLibraryTab
 * @param BasLibraryPage
 * @param BasLibraryBody
 * @param BasLibraryDetail
 * @param LocalLibraryPage
 * @param LocalPlaylistCollection
 * @param LocalCollection
 * @param {LocalParser} LocalParser
 * @param {LocalModel} LocalModel
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasSourceLibraryHelper} BasSourceLibraryHelper
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns LocalManager
 */
function LocalManagerFactory (
  $rootScope,
  BAS_FAVOURITE,
  BAS_HTML,
  BAS_CURRENT_CORE,
  BAS_LIBRARY,
  BAS_API,
  BAS_MODAL,
  BAS_APP_PROFILE,
  BAS_LOCAL_LIBRARY,
  BasModal,
  ModalService,
  modalHelperService,
  Selection,
  LibraryState,
  BasCurrentAppProfile,
  BasLibraryManager,
  BasLibraryTab,
  BasLibraryPage,
  BasLibraryBody,
  BasLibraryDetail,
  LocalLibraryPage,
  LocalPlaylistCollection,
  LocalCollection,
  LocalParser,
  LocalModel,
  CurrentBasCore,
  BasSourceLibraryHelper,
  BasUtilities,
  Logger
) {
  var className = 'Local Manager'

  var COLLECTION_MENU = 'collectionMenu'

  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @constructor
   * @extends BasLibraryManager
   * @param {PlayerLibraryState} libraryState
   */
  function LocalManager (libraryState) {

    // Call template constructor
    BasLibraryManager.call(this, libraryState)

    // Link the control functions
    this.linkControls()

    // Initialize header
    this.header.setTitle(BasUtilities.translate('my_music'))
    this.header.setSubtitle('')
    this.header.enableSearch(true)
    this.header.enableNavigation(true)

    // Selection type
    this.selection = new Selection()
    this.selection.type = Selection.TYPE_LOCAL

    /**
     * @instance
     * @type {Array<Function>}
     */
    this.playerListeners = []

    // Library scanning page
    this.createScanningPage()

    this._handleMusicConfig = this._onMusicConfig.bind(this)
    this._handleavSourcesReceived = this._onavSourcesReceived.bind(this)
  }

  // Set new prototype from inherited object
  LocalManager.prototype = Object.create(BasLibraryManager.prototype)

  // Set correct constructor after setting new prototype
  LocalManager.prototype.constructor = LocalManager

  LocalManager.prototype.linkControls = function () {

    // Body controls
    this.body.control[BasLibraryBody.CLK_ELEMENT] =
      this.selectElement.bind(this)
    this.body.control[BasLibraryBody.CLK_FAVOURITE] =
      this.toggleFavourite.bind(this)
    this.body.control[BasLibraryBody.CLK_BROWSE] =
      this.selectElement.bind(this)
    this.body.control[BasLibraryBody.CLK_CONTEXT] =
      this.clickContext.bind(this)
    this.body.control[BasLibraryBody.CLK_PLAY] =
      this.clickPlay.bind(this)
    this.body.control[BasLibraryBody.CLK_REMOVE] =
      this.clickRemove.bind(this)
    this.body.control[BasLibraryBody.CLK_SHARE] =
      this.toggleShare.bind(this)
  }

  // region Control functions

  /**
   * @param {LocalElement} element
   * @param {string} [shortcut]
   */
  LocalManager.prototype.selectElement = function (
    element,
    shortcut
  ) {
    var page, detail, detailElement, basSource, collection
    var request = false

    // Check if a new page needs to be created
    if (element.canBrowse ||
      isValidShortcut(shortcut)) {

      // Create a new page
      page = new LocalLibraryPage()
      page.collections.push(new LocalCollection())
    }

    switch (shortcut) {
      case BAS_LIBRARY.SHORTCUT_ARTIST:

        if (
          BasUtil.isObject(element) &&
          BasUtil.isNEString(element.artistUri)
        ) {

          // Title
          page.setTitleId('artist')
          page.setSubtitle(element.artist)

          // Create new element for detail view
          detailElement = LocalParser
            .processObject(
              element.artist,
              BAS_LOCAL_LIBRARY.T_EL_ARTIST
            )

          // Create detail
          detail = new BasLibraryDetail()
          detail.title = element.artist
          detail.setHasCoverArt(element.covers.large)
          detail.setCanPlay(true)
          detail.setHasContext(true)
          detail.setCanSelect(true)

          if (element.artistUri) {

            detailElement.uri = element.artistUri
          }

          collection = page.getCollection(0)

          if (collection) {

            collection.setDetail(detail)
            collection.setDetailElement(detailElement)
            collection.id = element.artistUri
              ? element.artistUri
              : element.artist
            collection.type = LocalCollection.TYPE_ARTIST
            collection.setContentType(LocalCollection.CT_TRACK)
          }

          request = true
        }

        break
      case BAS_LIBRARY.SHORTCUT_ALBUM:

        if (
          BasUtil.isObject(element) &&
          BasUtil.isNEString(element.albumUri)
        ) {

          // Title
          page.setTitleId('album')
          page.setSubtitle(element.album)

          // Create new element for detail view
          detailElement = LocalParser
            .processObject(
              {
                name: element.album,
                artist: element.artist,
                coverartUrl: element.covers.large,
                thumbnailUrl: element.covers.small
              },
              BAS_LOCAL_LIBRARY.T_EL_ALBUM
            )

          // Create detail
          detail = new BasLibraryDetail()
          detail.title = element.album
          detail.subtitle = element.artist
          detail.setHasCoverArt(element.covers.large)
          detail.setCanPlay(true)
          detail.setHasContext(true)
          detail.setCanSelect(true)

          if (element.albumUri) {

            detailElement.uri = element.albumUri
          }

          collection = page.getCollection(0)
          if (collection) {

            collection.setDetail(detail)
            collection.setDetailElement(detailElement)
            collection.id = element.albumUri ? element.albumUri : element.album
            collection.specifier = element.artist
            collection.type = LocalCollection.TYPE_ALBUM
            collection.setContentType(LocalCollection.CT_TRACK)
          }

          request = true
        }
        break
      default:
        switch (element.type) {
          case BAS_LOCAL_LIBRARY.T_EL_ALBUM:

            // Title
            page.setTitleId('album')
            page.setSubtitle(element.title)

            // Create detail
            detail = new BasLibraryDetail()
            detail.title = element.title
            detail.subtitle = element.subtitle
            detail.uri = element.uri
            detail.setHasCoverArt(element.covers.large)
            detail.setCanPlay(true)
            detail.setHasContext(true)
            detail.setCanSelect(true)

            collection = page.getCollection(0)
            if (collection) {

              collection.setDetail(detail)
              collection.setDetailElement(element)
              collection.id = element.uri ? element.uri : element.title
              collection.specifier = element.artist
              collection.type = LocalCollection.TYPE_ALBUM
              collection.setContentType(LocalCollection.CT_TRACK)
            }

            request = true
            break
          case BAS_LOCAL_LIBRARY.T_EL_PLAYLIST:

            page.collections = []
            page.collections.push(new LocalPlaylistCollection())

            // Title
            page.setTitleId('playlist')
            page.setSubtitle(element.title)

            // Create detail
            detail = new BasLibraryDetail()

            collection = page.getCollection(0)

            if (collection) {

              collection.setDetail(detail)
              collection.setDetailElement(element)
              collection.setCanEdit(element.editable)
              collection.setLocked(element.locked)
              collection.setContentType(
                LocalPlaylistCollection.CT_TRACK
              )
              collection.type =
                LocalPlaylistCollection.TYPE_PLAYLIST
              collection.id = element.uri ? element.uri : element.id
            }

            // Retrieve detail info
            basSource = this.libraryState.getBasSource()

            if (
              basSource &&
              basSource.playlists
            ) {

              basSource.playlists.retrieveDetail(
                element.playlistId
              )
                .then(handlePlaylistDetail)
            }

            // Fill detail
            detail.title = element.title
            detail.subtitle = BasUtilities.translate('playlist')
            detail.setHasCoverArt(element.covers.large)
            detail.setCanPlay(true)
            detail.setCanAsanoFavourite(true)
            detail.setCanSelect(true)
            detail.setHasContext(true)
            detail.setCanShare(element.shareable)
            detail.setNumberOfItems(element.numberOfItems)
            detail.setHasSubtitleExtra(element.owner)
            if (collection) detail.setCanEdit(collection.canEdit)
            if (element.iTunes) detail.setTag('iTunes')

            request = true
            break
          case BAS_LOCAL_LIBRARY.T_EL_ARTIST:

            // Title
            page.setTitleId('artist')
            page.setSubtitle(element.title)

            // Create detail
            detail = new BasLibraryDetail()
            detail.title = element.title
            detail.subtitle = element.subtitle
            detail.uri = element.uri
            detail.setHasCoverArt(element.covers.large)
            detail.setCanPlay(true)
            detail.setHasContext(true)
            detail.setCanSelect(true)

            collection = page.getCollection(0)
            if (collection) {

              collection.setDetail(detail)
              collection.setDetailElement(element)
              collection.id = element.uri ? element.uri : element.title
              collection.type = LocalCollection.TYPE_ARTIST
              collection.setContentType(LocalCollection.CT_TRACK)
            }

            request = true
            break
          case BAS_LOCAL_LIBRARY.T_EL_TRACK:

            BasSourceLibraryHelper.playNowItem(
              this.libraryState.getBasSource(),
              BasUtil.isNEString(element.uri)
                ? element.uri
                : BasUtil.isNEString(element.file)
                  ? element.file
                  : null
            )

            break
          case BAS_LOCAL_LIBRARY.T_EL_SECTION:

            switch (element.subType) {

              case LocalModel.SECTION_MORE:

                // Title
                page.setTitleId('search')
                page.setSubtitle(element.searchData.query)

                // Content type
                page.setContent(LocalLibraryPage.CONTENT_GENERIC)

                collection = page.getCollection(0)

                if (collection) {

                  collection.setCanGridView(true)
                  collection.setContentType(element.searchData.type)
                  collection.type = LocalCollection.TYPE_SEARCH
                  collection.id = element.searchData.query
                }

                request = true
            }

        }
        break
    }

    this.addPage(page, request)

    function handlePlaylistDetail (result) {

      if (result) {
        if (BasUtil.isBool(result.shared)) {

          element.isSharing = result.shared
        }
        $rootScope.$applyAsync()
      }
    }
  }

  LocalManager.prototype.clickPlay = function () {
    var basSource, collection, length, i, el, items

    basSource = this.libraryState.getBasSource()
    collection = this.currentPage && this.currentPage.getCollection
      ? this.currentPage.getCollection(0)
      : null

    if (
      basSource &&
      basSource.source &&
      collection &&
      collection.detailElement
    ) {
      if (basSource.isAudioSource) {

        if (basSource.canAddToQueue) {
          basSource.source.queueAddUri(
            collection.detailElement.uri,
            BAS_API.AudioSource.A_QO_REPLACE_NOW
          )
        } else {
          basSource.playUri(collection.detailElement.uri)
        }

      } else if (basSource.source.queue) {

        // Legacy API

        if (collection.detailElement.type === BAS_LOCAL_LIBRARY.T_EL_PLAYLIST) {

          basSource.source.queue.addPlaylist(
            collection.detailElement.id,
            BAS_API.Queue.ADD_OPTIONS.replaceNow
          )

        } else if (Array.isArray(collection.elements)) {

          items = []

          length = collection.elements.length
          for (i = 0; i < length; i++) {
            el = collection.elements[i]
            if (el && BasUtil.isNEString(el.file)) items.push(el.file)
          }

          basSource.source.queue.addSongs(
            items,
            BAS_API.Queue.ADD_OPTIONS.replaceNow
          )
        }
      }
    }
  }

  /**
   * @param {Object} data
   * @param {LocalElement} data.element
   * @param {LocalCollection} data.collection
   * @param data.event
   */
  LocalManager.prototype.clickContext = function (data) {
    var _this = this

    Logger.debug(className + ' - clickContext', data.element, data.event)

    ModalService
      .showModal({
        template: BAS_HTML.templateContextModal,
        controller: 'basLibraryContextCtrl',
        controllerAs: 'modal',
        inputs: {
          event: data.event,
          element: data.element,
          collection: data.collection,
          libType: BAS_LIBRARY.TYPE_LOCAL,
          selection: null,
          handler: this.handler
        }
      })
      .then(onContextModal)

    // Set scroll lock
    this.body.setClass(BasLibraryBody.CLASS_LOCK_SCROLLING)

    function onContextModal (modal) {

      // Check modal
      if (BasUtil.isObject(modal) &&
        modal.controller &&
        typeof modal.controller.onModalReady === 'function') {

        // Execute modal ready function
        modal.controller.onModalReady()
      }

      // Set closed Promise
      modal.closed
        .then(onContextModalClosed)
    }

    function onContextModalClosed () {

      // Reset modal style
      modalHelperService.resetModalStyle()

      // Clear scroll lock
      _this.body.setClass(BasLibraryBody.CLASS_LOCK_SCROLLING, false)
    }
  }

  LocalManager.prototype.selectMenu = function (
    event,
    selection,
    handler
  ) {
    return ModalService
      .showModal({
        template: BAS_HTML.templateContextModal,
        controller: 'basLibraryContextCtrl',
        controllerAs: 'modal',
        inputs: {
          event: event,
          selection: selection,
          element: null,
          collection: null,
          libType: BAS_LIBRARY.TYPE_LOCAL,
          handler: handler
        }
      })
      .then(onModalShown)

    function onModalShown (modal) {
      Logger.debug(className + ' - onModalShown', modal, modal.controller)

      // Modal is ready, DOM manipulation is allowed
      if (modal.controller &&
        modal.controller.onModalReady &&
        typeof modal.controller.onModalReady === 'function') {

        modal.controller.onModalReady()
      }

      // Set modal closed Promise callbacks
      return modal.closed.then(onModalClosed)

      function onModalClosed () {

        // Reset modal height
        modalHelperService.resetModalStyle()

        return Promise.resolve(BAS_LIBRARY.MODAL_CLOSED)
      }
    }
  }

  /**
   * @param {LocalElement} element
   */
  LocalManager.prototype.toggleFavourite = function (element) {

    var basSource, identifier

    basSource = this.libraryState.getBasSource()

    identifier = (basSource && element)
      ? basSource.isAudioSource
        ? element.asanoFavouriteUri
        : element.asanoFavouriteId
      : ''

    // Check element
    if (
      BasUtil.isObject(element) &&
      BasUtil.isNEString(element.id) &&
      BasUtil.isNEString(identifier) &&
      basSource &&
      basSource.favourites
    ) {

      if (basSource.favourites.isFavourite(identifier)) {

        basSource.favourites.remove(identifier)

      } else {

        basSource.favourites.add(
          BAS_FAVOURITE.T_LOCAL_PLAYLIST,
          basSource.isAudioSource
            ? identifier
            : element.id
        )
      }
    }
  }

  /**
   * @param {LocalElement} element
   */
  LocalManager.prototype.clickRemove = function (element) {
    var _this

    // Make sure you can remove the element
    if (element.locked) return

    _this = this

    // Show confirmation modal
    BasModal.show(BAS_MODAL.T_REMOVE)
      .then(onRemoveModal)

    function onRemoveModal (modal) {

      // Set close Promise
      modal.close
        .then(onRemoveModalClose)
    }

    function onRemoveModalClose (result) {

      var id, basSource

      if (result === BAS_MODAL.C_YES) {

        if (BasUtil.isObject(element)) {

          id = element.uri
            ? element.uri
            : element.id

          basSource = LibraryState.getCurrentSource()

          return BasSourceLibraryHelper.removePlaylist(
            basSource,
            id
          ).then(onSuccess)
        }
      }

      function onSuccess () {

        var collection, playlists, i

        // Go back to list
        _this.back()

        if (basSource && !basSource.isAudioSource) {

          // Legacy behavior

          // Set new collection
          collection = _this.currentPage.collections[0]

          // Get all playlists
          playlists = collection.elements

          // Delete removed playlist
          for (i = 0; i < playlists.length; i++) {
            if (playlists[i].id === id) {
              playlists.splice(i, 1)
              break
            }
          }
          $rootScope.$applyAsync()

          _this.currentPage.syncState()
        }
      }
    }
  }

  /**
   * @param {string} title
   * @param {string} id
   * @returns {Promise}
   */
  LocalManager.prototype.changeTitleRequest = function (
    title,
    id
  ) {
    return BasSourceLibraryHelper.renamePlaylist(
      LibraryState.getCurrentSource(),
      id,
      title
    )
  }

  /**
   * @param {LocalElement} element
   * @returns {Promise}
   */
  LocalManager.prototype.toggleShare = function (element) {

    var oldIsSharing

    oldIsSharing = element.isSharing
    element.isSharing = !element.isSharing

    return BasSourceLibraryHelper.sharePlaylist(
      LibraryState.getCurrentSource(),
      element.id,
      element.isSharing
    ).catch(onSharePlaylistError)

    function onSharePlaylistError () {

      element.isSharing = oldIsSharing
      $rootScope.$applyAsync()
    }
  }

  LocalManager.prototype.evtPlaylistChanged = function () {
    // Local library takes care of this automatically
  }

  // endregion

  // region Tab functions

  LocalManager.prototype.selectTab = function (tab) {

    if (tab.class[BasLibraryTab.CLASS_TAB_ACTIVE]) {
      return
    }

    if (Array.isArray(tab.collection)) {
      this.selectTabMultiple(tab)
    } else {
      this.selectTabSingle(tab)
    }
  }

  LocalManager.prototype.selectTabSingle = function (tab) {
    var _this, collection, page

    _this = this
    collection = tab.collection
    page = this.currentPage

    if (collection.elements.length === 0) {

      // Disable active tab
      page.clearTabsClass()

      // Select tab
      tab.setActiveTab(true)
      tab.setSelectedTab(true)

      page.setState(BasLibraryPage.STATE_OVERLAY_LOADING)

      $rootScope.$applyAsync()

      // Return promise after elements are received
      collection.retrieve().then(onSuccess, onFail)

    } else {

      // Set properties
      setProperties()

      // Wait one frame to set new scrollPos
      BasUtilities.waitFrames(2).then(this.pageChecks.bind(this))
    }

    function onSuccess () {

      // Set class
      page.setState(BasLibraryPage.STATE_NORMAL)

      // Set properties
      setProperties()

      // Reset scroll position after waiting one frame
      BasUtilities.waitFrames(2).then(_this.resetScrollPercentage.bind(_this))

      // Make sure new content is loaded
      BasUtilities.waitFrames(10).then(_this.checkPageFilled.bind(_this))
    }

    function onFail () {

      // Set class
      page.setState(BasLibraryPage.STATE_ERROR)

      // Deselect this tab
      tab.setSelectedTab(false)

      // Check page for no content
      page.checkContent()

      $rootScope.$applyAsync()
    }

    /**
     * Will trigger a $rootScope.$applyAsync()
     */
    function setProperties () {

      // Disable active tab
      page.clearTabsClass()

      // Set scroll percentage
      page.setScrollPercent(_this.getScrollPercentage())

      // Deselect this tab
      tab.setSelectedTab(false)

      // Activate this tab
      tab.setActiveTab(true)

      // Set grid view property
      collection.setGridView(
        page.collections[0]
          ? page.collections[0].gridView
          : 0
      )

      // Reset collections
      page.collections = []

      // Set collection
      page.collections.push(collection)

      // Set title
      if (!page.lockSubtitle) {
        page.setSubtitle(
          BasUtilities.translate(tab.collection.titleNameId)
        )
      }

      // Check page for content
      page.checkContent()

      // Sync gridview header
      _this.syncPagesUi()
    }
  }

  LocalManager.prototype.selectTabMultiple = function (tab) {
    var _this, collections, collection, page, promises, selectTab, length, i

    _this = this
    collections = tab.collection
    collection = null
    page = this.currentPage
    promises = []
    selectTab = false

    length = collections.length
    for (i = 0; i < length; i++) {
      collection = collections[i]

      if (collection.elements.length === 0 &&
        !collection.hasReachedEnd) {

        selectTab = true

        // Return promise after elements are received
        promises.push(collection.retrieve())
      }

      // No need to add Promise.resolve in other case since Promise.all
      // auto-resolves when having an empty array.
    }

    if (selectTab) {
      // Select tab
      tab.setSelectedTab(true)

      $rootScope.$applyAsync()
    }

    Promise.all(promises).then(onSuccess, onFail)

    function onSuccess () {

      // Set properties
      setProperties()

      // Apply a digest cycle so the new content can be loaded
      $rootScope.$applyAsync(function () {

        // Wait one frame to set new scrollPos
        BasUtilities.waitFrames(2)
          .then(_this.setScrollPos.bind(_this, page.getScrollPercent()))
      })

    }

    function onFail () {

      // Deselect this tab
      tab.setSelectedTab(false)

      // Check page for no content
      page.checkContent()

      $rootScope.$applyAsync()
    }

    /**
     * Will trigger a $rootScope.$applyAsync()
     */
    function setProperties () {

      var gridview, _length, _i

      // Disable active tab
      page.clearTabsClass()

      // Set scroll percentage
      page.setScrollPercent(_this.getScrollPercentage())

      // Deselect this tab
      tab.setSelectedTab(false)

      // Activate this tab
      tab.setActiveTab(true)

      // Save gridview
      gridview = page.collections[0].gridView

      // Reset collections
      page.collections = []

      _length = collections.length
      for (_i = 0; _i < _length; _i++) {
        collection = collections[_i]

        // Set collection
        page.collections.push(collection)

        // Set grid view property
        collection.setGridView(gridview)
      }

      // Set title
      if (!page.lockSubtitle) {
        page.setSubtitle(BasUtilities.translate(collection.titleNameId))
      }

      // Check page for content
      page.checkContent()

      // Sync gridview header
      _this.syncPagesUi()
    }
  }

  // endregion

  // region Header controls

  LocalManager.prototype.search = function (searchQuery) {

    var page, collection

    if (BasUtil.isNEString(searchQuery)) {

      // Create a new page
      page = new LocalLibraryPage()
      page.setTitleId('search')
      page.setSubtitle(searchQuery)

      if (
        CurrentBasCore.hasCore() &&
        CurrentBasCore.hasAVFullSupport()
      ) {

        // Create new search page
        page = new LocalLibraryPage(this.handler)

        // Page content type
        page.content = LocalLibraryPage.CONTENT_SEARCH

        // Titles
        page.setTitleId('search')
        page.setSubtitle(searchQuery)

        // Make pages
        page.addCollection(this.makeSearchCollection(
          searchQuery,
          LocalCollection.CT_ALBUM
        ))
        page.addCollection(this.makeSearchCollection(
          searchQuery,
          LocalCollection.CT_ARTIST
        ))
        page.addCollection(this.makeSearchCollection(
          searchQuery,
          LocalCollection.CT_TRACK
        ))
        // TODO: Enable when server supports playlist search
        // page.addCollection(this.makeSearchCollection(
        //   searchQuery,
        //   LocalCollection.CT_PLAYLIST
        // ))

      } else {

        collection = new LocalCollection()
        page.collections.push(collection)

        collection.type = LocalCollection.TYPE_SEARCH
        collection.setContentType(LocalCollection.CT_TRACK)
        collection.id = searchQuery
      }

      // Add page
      this.addPage(page, true)

      // Make sure to close the search
      this.header.toggleSearchInput(false)

      BasCurrentAppProfile.addSearchHistory(
        searchQuery,
        BAS_APP_PROFILE.K_LOCAL
      )

      // Clear searchQuery
      this.searchQuery = ''
    }
  }

  LocalManager.prototype.toggleSearch = function () {

    if (this.currentPage &&
      this.currentPage.getCollection(0) &&
      this.currentPage.getCollection(0).type ===
      LocalCollection.TYPE_SEARCH) {

      this.searchQuery = this.currentPage.subtitle
    }
  }

  // endregion

  // region Helper functions

  LocalManager.prototype.isScanningPageShowing = function () {
    return (
      this.pages.length > 0 &&
      BasUtil.isObject(this.pages[this.pages.length - 1]) &&
      this.pages[this.pages.length - 1].content ===
      LocalLibraryPage.CONTENT_SCANNING
    )
  }

  LocalManager.prototype.addScanningPage = function () {
    if (!this.isScanningPageShowing()) {

      this.header.enableNavigation(false)
      this.header.enableSearch(false)

      this.home()

      this.removePageClass()

      this.pages.push(this.scanningPage)

      // Sync with new page
      this.syncPagesUi()

      // Clear selection
      this.selection.clear()
    }
  }

  LocalManager.prototype.removeScanningPage = function () {
    if (this.isScanningPageShowing()) {

      this.header.enableNavigation(true)
      this.header.enableSearch(true)

      this.addPageClass()

      // Call destructor of current page
      this.pages[this.pages.length - 1].destroy()

      // Remove page
      this.pages.pop()

      // Sync with new page
      this.syncPagesUi()

      // Reset pages
      this.resetPages()
    }
  }

  LocalManager.prototype.createScanningPage = function () {

    // Create new page
    this.scanningPage = new LocalLibraryPage()

    // Page content
    this.scanningPage.setContent(LocalLibraryPage.CONTENT_SCANNING)
  }

  LocalManager.prototype.createStartPage = function () {

    // Create new page
    this.startPage = new LocalLibraryPage()
    this.startPage.collections.push(new LocalCollection())
    this.startPage.setTitleId('my_music')

    // Set page content type
    this.startPage.setContent(BasLibraryPage.CONTENT_START)

    setPageCollections(this.startPage, COLLECTION_MENU)
  }

  LocalManager.prototype.makeSearchCollection = function (
    query,
    contentType
  ) {
    var searchCollection, showMoreElement

    searchCollection = new LocalCollection(this.handler)
    searchCollection.setTitleId(getCollectionTitle(contentType))
    searchCollection.setContentType(contentType)
    searchCollection.hasReachedEnd = true
    searchCollection.type = LocalCollection.TYPE_SEARCH
    searchCollection.limit = 2
    searchCollection.id = query

    showMoreElement = LocalModel.createSection(
      LocalModel.SECTION_MORE,
      contentType,
      query
    )

    searchCollection.setLastElement(showMoreElement)

    return searchCollection
  }

  function getCollectionTitle (contentType) {

    switch (contentType) {
      case LocalCollection.CT_ALBUM:
        return 'albums'
      case LocalCollection.CT_ARTIST:
        return 'artists'
      case LocalCollection.CT_TRACK:
        return 'songs'
      case LocalCollection.CT_PLAYLIST:
        return 'playlists'
    }
    return BasUtilities.translate('songs')
  }

  /**
   *
   * @param {LocalLibraryPage} page
   * @param {string} type
   */
  function setPageCollections (page, type) {

    switch (type) {
      case COLLECTION_MENU:
        page.makeArrayCollection({
          name: 'playlists_l',
          titleName: 'playlists',
          contentType: LocalPlaylistCollection.CT_PLAYLIST
        })
        page.makeCollection({
          name: 'albums',
          titleName: 'albums',
          contentType: LocalCollection.CT_ALBUM
        })
        page.makeCollection({
          name: 'artists',
          titleName: 'artists',
          canGridView: false,
          contentType: LocalCollection.CT_ARTIST
        })
        page.makeCollection({
          name: 'songs',
          titleName: 'songs',
          contentType: LocalCollection.CT_TRACK
        })
        break
    }

    page.makeTabs()
  }

  /**
   * @param shortcut
   * @returns {boolean}
   */
  function isValidShortcut (shortcut) {
    return (
      shortcut === BAS_LIBRARY.SHORTCUT_ALBUM ||
      shortcut === BAS_LIBRARY.SHORTCUT_ARTIST
    )
  }

  // endregion

  // region Resume & Suspend

  LocalManager.prototype.onTranslationChange = function () {

    if (BasUtil.isObject(this.currentPage)) {
      this.currentPage.onTranslate()
    }

    this.syncHeaderPage()
    $rootScope.$applyAsync()
  }

  LocalManager.prototype._onMusicConfig = function () {

    this.onMusicReady()
  }

  LocalManager.prototype._onavSourcesReceived = function () {

    this.onMusicReady()
  }

  LocalManager.prototype.onMusicReady = function () {

    var basSource

    if (CurrentBasCore.hasAVFullSupport()) {

      if (!currentBasCoreState.core.core.avSourcesReceived) {

        return
      }
    } else if (!currentBasCoreState.core.core.musicConfigReceived) {

      return
    }

    // Clear listeners
    BasUtil.executeArray(this.playerListeners)
    this.playerListeners = []

    basSource = LibraryState.getCurrentSource()

    if (
      basSource &&
      basSource.source
    ) {

      if (basSource.source.database) {

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source.database,
          BAS_API.Database.EVT_PLAYLIST_CHANGED,
          this.onPlaylistChanged.bind(this)
        ))

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source.database,
          BAS_API.Database.EVT_DATABASE_CHANGED,
          this.onDataChanged.bind(this)
        ))

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source.database,
          BAS_API.Database.EVT_DATABASE_UPDATING,
          this.onDataUpdating.bind(this)
        ))

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source.database,
          BAS_API.Database.EVT_HAS_CONTENT,
          this.checkContent.bind(this)
        ))

      } else if (basSource.isAudioSource) {

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source,
          BAS_API.AudioSource.EVT_PLAYLISTS_RESET,
          this.onPlaylistChanged.bind(this)
        ))

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source,
          BAS_API.AudioSource.EVT_PLAYLIST_REMOVED,
          this.onPlaylistChanged.bind(this)
        ))

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source,
          BAS_API.AudioSource.EVT_PLAYLIST_ADDED,
          this.onPlaylistChanged.bind(this)
        ))

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source,
          BAS_API.AudioSource.EVT_PLAYLIST_TRACK_ADDED,
          this.onPlaylistChanged.bind(this)
        ))

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source,
          BAS_API.AudioSource.EVT_PLAYLIST_RENAMED,
          this.onPlaylistChanged.bind(this)
        ))

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source,
          BAS_API.AudioSource.EVT_PLAYLIST_TRACK_MOVED,
          this.onPlaylistChanged.bind(this)
        ))

        this.playerListeners.push(BasUtil.setEventListener(
          basSource.source,
          BAS_API.AudioSource.EVT_PLAYLIST_TYPE_CHANGED,
          this.onPlaylistChanged.bind(this)
        ))
      }
    }

    // Clean data
    this.resetPages()
  }

  LocalManager.prototype.onPlaylistChanged = function () {

    var promises, tab, collections, length, i, collection

    promises = []

    if (BasUtil.isObject(this.pages[0])) {
      tab = this.pages[0].getTab('playlists_l')
    }

    if (
      BasUtil.isObject(tab) &&
      BasUtil.isObject(tab.collection)
    ) {

      collections = tab.collection
      length = collections.length
      for (i = 0; i < length; i++) {
        collection = collections[i]

        if (BasUtil.isObject(collection)) {
          collection.elements = []
          collection.hasReachedEnd = false
          promises.push(collection.retrieve())
        }
      }

      Promise.all(promises).then(onRetrieve)
    }

    this.updateCollection()

    function onRetrieve () {
      $rootScope.$applyAsync()
    }
  }

  LocalManager.prototype.updateCollection = function () {

    var basSource, collection, element

    if (this.currentPage) {

      collection = this.currentPage.collections[0]

      if (collection instanceof LocalPlaylistCollection &&
        collection.detailElement) {

        element = collection.detailElement

        // Retrieve detail info
        basSource = this.libraryState.getBasSource()

        if (basSource &&
          basSource.playlists) {

          basSource.playlists.retrieveDetail(
            element.playlistId
          )
            .then(handlePlaylistDetail)
        }
      }
    }

    function handlePlaylistDetail (result) {

      if (result) {

        if (BasUtil.isBool(result.shared)) {

          element.isSharing = result.shared === true
        }

        if (Array.isArray(result.list)) {
          // Clear elements and process new list, reset offset

          collection.hasReachedEnd = false
          collection.offset = 0

          collection.setElements([])
          collection.processElements(result.list)
        }

        $rootScope.$applyAsync()
      }
    }
  }

  LocalManager.prototype.onDataChanged = function () {

    this.resetPages()
  }

  LocalManager.prototype.onDataUpdating = function (updating) {

    if (
      (
        BasUtil.isBool(updating) &&
        updating
      ) ||
      (
        CurrentBasCore.hasCore() &&
        currentBasCoreState.core.core.musicLibrary &&
        currentBasCoreState.core.core.musicLibrary.isScanning
      )
    ) {
      this.addScanningPage()
    } else {
      this.removeScanningPage()
    }
  }

  LocalManager.prototype.checkContent = function () {

    var basSource = LibraryState.getCurrentSource()

    if (!basSource || !basSource.hasLocalContent()) {

      // Show "No Content" page
      this.addNotConnectedPage()

    } else {

      this.removeNotConnectedPage()
    }

    if (
      (
        CurrentBasCore.hasCore() &&
        CurrentBasCore.hasAVFullSupport() &&
        currentBasCoreState.core.core.musicLibrary &&
        currentBasCoreState.core.core.musicLibrary.isScanning
      ) ||
      (
        basSource &&
        basSource.source &&
        basSource.source.database &&
        basSource.source.database.isUpdating === true
      )
    ) {

      // Show "Scanning" page
      this.addScanningPage()

    } else {

      this.removeScanningPage()
    }

    if (!this.currentPage) {

      this.resetPages()
    }
  }

  LocalManager.prototype.resetPages = function () {

    // Make sure no page overlays are showing
    if (this.isScanningPageShowing() ||
      this.isNotConnectedPageShowing()) {

      return
    }

    // Reset pages
    this.pages = []

    // Show loading screen
    this.startPage.setState(BasLibraryPage.STATE_OVERLAY_LOADING)

    // Make empty start page
    this.createStartPage()

    // Add the start state
    this.addPage(this.startPage, true)

    // Set class
    this.removePageClass()

    // Clear selection
    this.selection.clear()
  }

  /**
   * Resume listening to events
   *
   * @method
   */
  LocalManager.prototype.resume = function () {

    Logger.debug(className + ' - Resume')

    // Check for content
    this.checkContent()

    this.listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      this._handleMusicConfig
    ))

    this.listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_AV_SOURCES_RECEIVED,
      this._handleavSourcesReceived
    ))
    this.listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_LIBRARY_HAS_CONTENT,
      this.checkContent.bind(this)
    ))
    this.listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_LIBRARY_SCANNING_CHANGED,
      this.onDataUpdating.bind(this)
    ))
    this.listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_LIBRARY_CHANGED,
      this.onDataChanged.bind(this)
    ))

    // Set listeners
    this._onMusicConfig()

    // Translate
    this.onTranslationChange()

    // Resume the current page
    if (BasUtil.isObject(this.currentPage)) {
      this.currentPage.resume()
    }
  }

  LocalManager.prototype.suspend = function () {

    BasLibraryManager.prototype.suspend.call(this)

    // Clear player listeners
    BasUtil.executeArray(this.playerListeners)
    this.playerListeners = []
  }

  // endregion

  return LocalManager

}
