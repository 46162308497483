'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basSwiper', [])
  .directive('basSwiper', basSwiper)

function basSwiper () {

  return {
    restrict: 'A',
    controller: [
      '$scope',
      '$element',
      '$attrs',
      '$window',
      '$parse',
      controller
    ]
  }

  /**
   * @param $scope
   * @param $element
   * @param $attrs
   * @param $window
   * @param $parse
   */
  function controller (
    $scope,
    $element,
    $attrs,
    $window,
    $parse
  ) {
    var basMainSwiper = this

    var MIN_SWIPE_DISTANCE = 25
    var MAX_TIME_DIFF_MS = 448
    var MAX_DEGREE_DIFFERENCE = 30
    var MIN_AVERAGE_SPEED = 0.4

    var _passiveListenerOpts = {
      capture: false,
      passive: true
    }

    var _listeners = []

    var _elementListeners = []
    var _elementListenersSet = false

    var _elementEndListeners = []

    var isTouch = false

    /**
     * @private
     * @type {(Touch|MouseEvent|null)}
     */
    var startEvent = null

    /**
     * @private
     * @type {(TouchEvent|null)}
     */
    var backupStartEvent = null

    var startTime = -1

    basMainSwiper.$postLink = _onPostLink
    basMainSwiper.$onDestroy = _onDestroy

    function _onPostLink () {

      _setElementListeners()
    }

    function _executeSwipeLeft () {

      _executeAttributeFunction('basSwipeLeft')
    }

    function _executeSwipeRight () {

      _executeAttributeFunction('basSwipeRight')
    }

    function _executeSwipeUp () {

      _executeAttributeFunction('basSwipeUp')
    }

    function _executeSwipeDown () {

      _executeAttributeFunction('basSwipeDown')
    }

    /**
     * @private
     * @param {string} attributeName
     * @param {Object} [params]
     */
    function _executeAttributeFunction (
      attributeName,
      params
    ) {
      var _attribute, expressionHandler

      _attribute = $attrs[attributeName]

      if (_attribute) {

        expressionHandler = $parse(_attribute)

        if (BasUtil.isFunction(expressionHandler)) {

          expressionHandler($scope, params)
        }
      }
    }

    /**
     * @private
     * @param {boolean} touch
     * @param {(Touch|MouseEvent)} event
     * @param {TouchEvent} [backupEvent]
     */
    function _setStartEvent (
      touch,
      event,
      backupEvent
    ) {
      if (event) {

        isTouch = touch

        startEvent = event

        if (backupEvent) backupStartEvent = backupEvent

        startTime = Date.now()
      }
    }

    function _clearStartEvent () {

      startEvent = null
      backupStartEvent = null
      startTime = -1
    }

    /**
     * @private
     * @param {(Touch|MouseEvent)} endEvent
     * @param {TouchEvent} [backupEndEvent]
     */
    function _handleEnd (
      endEvent,
      backupEndEvent
    ) {
      var startX, startY, endX, endY
      var diffX, diffY, timeDiff, angle, distance, averageSpeed

      if (startEvent &&
        endEvent &&
        BasUtil.isPNumber(startTime)) {

        timeDiff = Date.now() - startTime

        if (timeDiff < MAX_TIME_DIFF_MS) {

          // iOS 9 workaround
          if (isTouch &&
            startEvent.pageX === endEvent.pageX &&
            startEvent.pageY === endEvent.pageY &&
            backupStartEvent &&
            BasUtil.isVNumber(backupStartEvent.pageX) &&
            BasUtil.isVNumber(backupStartEvent.pageY) &&
            BasUtil.isVNumber(backupEndEvent.pageX) &&
            BasUtil.isVNumber(backupEndEvent.pageY)) {

            startX = backupStartEvent.pageX
            startY = backupStartEvent.pageY
            endX = backupEndEvent.pageX
            endY = backupEndEvent.pageY

          } else {

            startX = startEvent.pageX
            startY = startEvent.pageY
            endX = endEvent.pageX
            endY = endEvent.pageY
          }

          diffX = endX - startX
          diffY = endY - startY

          angle = Math.atan2(diffY, diffX) * 180 / Math.PI
          distance = Math.sqrt(
            Math.pow(diffX, 2) +
            Math.pow(diffY, 2)
          )
          averageSpeed = distance / timeDiff

          if (averageSpeed > MIN_AVERAGE_SPEED &&
            distance > MIN_SWIPE_DISTANCE) {

            if (BasUtil.isWithinLoopingBounds(
              angle,
              -90,
              -180,
              180,
              MAX_DEGREE_DIFFERENCE
            )) {

              // Angle is within the maximum amount of play,
              // of -90° (Straight up)
              //  -> Swipe up
              _executeSwipeUp()

            } else if (BasUtil.isWithinLoopingBounds(
              angle,
              0,
              -180,
              180,
              MAX_DEGREE_DIFFERENCE
            )) {

              // Angle is within the maximum amount of play,
              // of 0° (Straight right)
              //  -> Swipe right
              _executeSwipeRight()

            } else if (BasUtil.isWithinLoopingBounds(
              angle,
              90,
              -180,
              180,
              MAX_DEGREE_DIFFERENCE
            )) {

              // Angle is within the maximum amount of play,
              // of 90° (Straight down)
              //  -> Swipe down
              _executeSwipeDown()

            } else if (BasUtil.isWithinLoopingBounds(
              angle,
              180,
              -180,
              180,
              MAX_DEGREE_DIFFERENCE
            )) {

              // Angle is within the maximum amount of play,
              // of 180° (Straight left)
              //  -> Swipe left
              _executeSwipeLeft()

            }
          }
        }
      }

      _clearStartEvent()
    }

    /**
     * @private
     * @param {TouchEvent} event
     */
    function _onTouchStart (event) {

      _setStartEvent(
        true,
        event.changedTouches[0],
        event
      )
    }

    /**
     * @private
     * @param {TouchEvent} event
     */
    function _onTouchEnd (event) {

      var touch

      if (startEvent &&
        BasUtil.isVNumber(startEvent.identifier)) {

        touch = _getTouch(
          event.changedTouches,
          startEvent.identifier
        )

        if (touch) _handleEnd(touch, event)
      }
    }

    /**
     * @private
     * @param {TouchEvent} _event
     */
    function _onTouchCancel (_event) {

      _clearStartEvent()
    }

    /**
     * @private
     * @param {MouseEvent} event
     */
    function _onMouseDown (event) {

      _setStartEvent(false, event)
    }

    /**
     * @private
     * @param {MouseEvent} event
     */
    function _onMouseUp (event) {

      _handleEnd(event)
    }

    /**
     * @private
     * @param {MouseEvent} _event
     */
    function _onMouseOut (_event) {

      _clearStartEvent()
    }

    function _setElementListeners () {

      var _element

      if ($element && $element[0]) {

        _element = $element[0]

        if (!_elementListenersSet) {

          _elementListenersSet = true

          _elementListeners.push(BasUtil.setDOMListener(
            _element,
            'touchstart',
            _onTouchStart,
            _passiveListenerOpts
          ))
          _elementEndListeners.push(BasUtil.setDOMListener(
            _element,
            'touchend',
            _onTouchEnd,
            _passiveListenerOpts
          ))
          _elementEndListeners.push(BasUtil.setDOMListener(
            _element,
            'touchcancel',
            _onTouchCancel,
            _passiveListenerOpts
          ))

          _elementListeners.push(BasUtil.setDOMListener(
            _element,
            'mousedown',
            _onMouseDown,
            _passiveListenerOpts
          ))
          _elementEndListeners.push(BasUtil.setDOMListener(
            $window.document,
            'mouseup',
            _onMouseUp,
            _passiveListenerOpts
          ))
          _elementEndListeners.push(BasUtil.setDOMListener(
            $window.document,
            'mouseout',
            _onMouseOut,
            _passiveListenerOpts
          ))
        }
      }
    }

    /**
     * @private
     * @param {TouchList} touches
     * @param {number} identifier
     * @returns {?Touch}
     */
    function _getTouch (touches, identifier) {

      var length, i

      length = touches.length
      for (i = 0; i < length; i++) {

        if (touches[i].identifier === identifier) return touches[i]
      }

      return null
    }

    function _clearElementEndListeners () {

      BasUtil.executeArray(_elementEndListeners)
      _elementEndListeners = []
    }

    function _clearElementListeners () {

      _clearElementEndListeners()

      BasUtil.executeArray(_elementListeners)
      _elementListeners = []
      _elementListenersSet = false
    }

    function _onDestroy () {

      _clearElementListeners()
      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
