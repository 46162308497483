'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('videoSceneCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'SCENE_STATES',
    'BAS_HOME',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'CurrentRoom',
    'BasSceneStateHelper',
    'Sources',
    'BasSceneHelper',
    videoSceneCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {SCENE_STATES} SCENE_STATES
 * @param {BAS_HOME} BAS_HOME
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {CurrentRoom} CurrentRoom
 * @param {BasSceneStateHelper} BasSceneStateHelper
 * @param {Sources} Sources
 * @param {BasSceneHelper} BasSceneHelper
 */
function videoSceneCtrl (
  $scope,
  $uiRouterGlobals,
  SCENE_STATES,
  BAS_HOME,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_SOURCES,
  BAS_SOURCE,
  CurrentRoom,
  BasSceneStateHelper,
  Sources,
  BasSceneHelper
) {
  var video = this

  let registeredSource = BAS_HOME.NO_SOURCE

  var _DEFAULT_VOLUME = 50

  /**
   * @type {BAS_HOME}
   */
  video.BAS_HOME = BAS_HOME

  /**
   * @type {BAS_SOURCES}
   */
  video.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {BAS_SOURCE}
   */
  video.BAS_SOURCE = BAS_SOURCE

  /**
   * @type {BAS_ROOMS}
   */
  video.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {BAS_ROOM}
   */
  video.BAS_ROOM = BAS_ROOM

  video.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {number}
   */
  video.volume = _DEFAULT_VOLUME

  // noinspection JSUnusedGlobalSymbols
  /**
   * Angular UI-router callback
   */
  this.uiOnParamsChanged = _onParamsChanged

  video.selectVolume = selectVolume
  video.selectSource = selectSource
  video.selectSourceDetail = selectSourceDetail
  video.volumeChange = volumeChange

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _syncVolumeFromStep()

    _setSourcesInterest()
  }

  function _onParamsChanged () {

    _syncVolumeFromStep()

    _setSourcesInterest()
  }

  /**
   * @param {boolean} [force]
   */
  function selectVolume (force) {

    var _step

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step) {

      _step.toggleVideoVolume(video.volume, force)
    }
  }

  /**
   * @param {string} sourceUuid
   */
  function selectSource (sourceUuid) {

    var _step

    // Always update volume
    _saveVolumeIfEnabled()

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.video) {

      if (_step.video.source === sourceUuid) {

        // Change source to off
        _step.setVideoSource('')

        _setSourcesInterest()

      } else if (sourceUuid && _step.video.source !== sourceUuid) {

        // Change source to this uuid
        _step.setVideoSource(sourceUuid)

        _setSourcesInterest()

      } else {

        // No source changes
        _step.setVideoSource('')

        _setSourcesInterest()
      }
    }
  }

  /**
   * @param {string} sourceUuid
   */
  function selectSourceDetail (sourceUuid) {

    var _step, _source

    // Always update volume
    _saveVolumeIfEnabled()

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.video) {

      _source = BAS_SOURCES.SOURCES.sources[sourceUuid]

      if (
        _source &&
        (
          _source.type === BAS_SOURCE.T_VIDEO
        )
      ) {

        _step.setVideoSource(sourceUuid)

        _setSourcesInterest()

        CurrentRoom.go(BasSceneStateHelper.getSceneState(
          SCENE_STATES.SCENE_STEP_VIDEO_FAVOURITES
        ))

      } else {

        selectSource(sourceUuid)
      }
    }
  }

  function volumeChange () {

    var _step

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step) {

      _step.setVideoVolume(video.volume)
    }
  }

  function _saveVolumeIfEnabled () {

    var _step

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.video) {

      if (_step.video.volume !== -1) _step.setVideoVolume(video.volume)
    }
  }

  function _syncVolumeFromStep () {

    var _step, _room

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    video.volume = _DEFAULT_VOLUME

    if (_step) {

      if (_step.video &&
        BasUtil.isPNumber(_step.video.volume, true)) {

        video.volume = _step.video.volume

      } else {

        if (_step.room) {

          _room = BAS_ROOMS.ROOMS.rooms[_step.room]

          if (_room && _room.video && _room.video.toggle) {

            video.volume = _room.video.startupVolume
          }
        }
      }
    }
  }

  function _setSourcesInterest () {

    const step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (step && step.video) {

      const source = BAS_SOURCES.SOURCES.sources[step.video.source]

      if (source) {

        const newSourceId = source.getId()

        if (registeredSource !== newSourceId) {

          Sources.unregisterFor(
            BAS_SOURCE.COL_EVT_FAVOURITES,
            registeredSource
          )

          registeredSource = newSourceId

          Sources.registerFor(
            BAS_SOURCE.COL_EVT_FAVOURITES,
            registeredSource
          )
        }

      } else {

        Sources.unregisterFor(
          BAS_SOURCE.COL_EVT_FAVOURITES,
          registeredSource
        )
        registeredSource = BAS_HOME.NO_SOURCE
      }

    } else {

      Sources.unregisterFor(
        BAS_SOURCE.COL_EVT_FAVOURITES,
        registeredSource
      )
      registeredSource = BAS_HOME.NO_SOURCE
    }
  }

  function _onDestroy () {

    Sources.unregisterFor(
      BAS_SOURCE.COL_EVT_FAVOURITES,
      registeredSource
    )
  }
}
