'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('SpotifyHelper', [
    'BAS_LIBRARY_ERRORS',
    'SpotifyElement',
    'LibraryState',
    'Logger',
    SpotifyHelper
  ])

/**
 * Helper Service for getting Spotify Objects
 *
 * @constructor
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param SpotifyElement
 * @param {LibraryState} LibraryState
 * @param Logger
 */
function SpotifyHelper (
  BAS_LIBRARY_ERRORS,
  SpotifyElement,
  LibraryState,
  Logger
) {
  var serviceName = 'Spotify Helper'

  /**
   * @param {SpotifyElement} element
   */
  this.play = function (element) {

    var manager = LibraryState.getCurrentState().spotifyManager

    if (manager) manager.playElement(element)
  }

  /**
   * @param {string[]} items
   */
  this.playSelection = function (items) {

    var basSource = LibraryState.getSpotifySource()
    var songs = { uris: items }

    if (basSource) {

      if (basSource.isAudioSource) {

        basSource.playUri(items)

      } else {

        basSource.source.play(songs)
      }
    }
  }

  /**
   * @param {SpotifyElement} element
   * @param {SpotifyCollection} collection
   * @param {Object} can
   */
  this.checkContextCapabilities = function (element, collection, can) {

    var canArtist, isArtistDetail, canAlbum, isAlbumDetail, hasDetail
    var basSource, isAudioSource

    basSource = LibraryState.getCurrentSource()
    isAudioSource = false

    if (basSource) {

      isAudioSource = basSource.isAudioSource
    }

    // Check element
    if (BasUtil.isObject(element) &&
      BasUtil.isNEString(element.type)) {

      // Set modal properties based on type
      switch (element.type) {
        case SpotifyElement.TYPE_ALBUM:

          canArtist = (
            BasUtil.isObject(element.spotify) &&
            BasUtil.isNEArray(element.spotify.artists)
          )
          isArtistDetail = (
            BasUtil.isObject(collection) &&
            BasUtil.isObject(collection.detailElement) &&
            collection.detailElement.type ===
            SpotifyElement.TYPE_ARTIST
          )

          can.playNow = true
          can.spotify = true
          can.addPlaylist = true
          can.goToArtist = !isArtistDetail && canArtist

          break
        case SpotifyElement.TYPE_PLAYLIST:

          can.playNow = true
          can.spotify = true
          can.addPlaylist = true

          break
        case SpotifyElement.TYPE_ARTIST:

          can.playNow = isAudioSource
          can.spotify = true

          break
        case SpotifyElement.TYPE_RADIO:

          can.playNow = true

          break
        case SpotifyElement.TYPE_TRACK:

          hasDetail = (
            BasUtil.isObject(collection) &&
            BasUtil.isObject(collection.detailElement)
          )
          canArtist = (
            BasUtil.isObject(element.spotify) &&
            BasUtil.isNEArray(element.spotify.artists)
          )
          isArtistDetail = (
            hasDetail &&
            collection.detailElement.type ===
            SpotifyElement.TYPE_ARTIST
          )
          canAlbum = (
            BasUtil.isObject(element.spotify) &&
            BasUtil.isObject(element.spotify.album)
          )
          isAlbumDetail = (
            hasDetail &&
            collection.detailElement.type ===
            SpotifyElement.TYPE_ALBUM
          )

          can.playNow = true
          can.spotify = true
          can.addPlaylist = true
          can.goToAlbum = canAlbum && !isAlbumDetail
          can.goToArtist = canArtist && !isArtistDetail

          break
        default:
          Logger.warn(serviceName + ' - Unsupported type', element.type)
      }

    } else {
      Logger.warn(serviceName + ' - Unknown element', element)
    }
  }

  /**
   * @param {SpotifyElement} element
   * @returns {Promise}
   */
  this.getElementData = function (element) {

    var basSource, spotify, func

    if (BasUtil.isObject(element) &&
      BasUtil.isNEString(element.type)) {

      switch (element.type) {

        case SpotifyElement.TYPE_TRACK:
          return Promise.resolve([element.uri])

        case SpotifyElement.TYPE_ALBUM:

          basSource = LibraryState.getSpotifySource()

          if (basSource && basSource.spotify.isLinked()) {

            spotify = basSource.spotify
            func = spotify.getAlbumSongsLib
              .bind(spotify, element.id)
            return basSource.spotify.getXElements(func, 100, 0, -1)
              .then(onData)
          }

          return Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)

        case SpotifyElement.TYPE_PLAYLIST:

          basSource = LibraryState.getSpotifySource()

          if (basSource && basSource.spotify.isLinked()) {

            spotify = basSource.spotify
            func = spotify.getPlaylistSongsLib
              .bind(spotify, element.id)
            return basSource.spotify.getXElements(func, 100, 0, -1)
              .then(onData)
          }

          return Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ELEMENT)

    function onData (result) {

      var i, length
      var array = []

      if (BasUtil.isObject(result) &&
        Array.isArray(result.items)) {

        length = result.items.length

        for (i = 0; i < length; i++) {

          if (BasUtil.isNEString(result.items[i].uri)) {

            array.push(result.items[i].uri)

          } else if (BasUtil.isObject(result.items[i].track) &&
            BasUtil.isNEString(result.items[i].track.uri)) {

            array.push(result.items[i].track.uri)
          }
        }
      }

      return array
    }
  }

  /**
   * @param {SpotifyElement} element
   * @returns {boolean}
   */
  this.isPlaylist = function (element) {

    var basSource = LibraryState.getSpotifySource()

    return (
      element &&
      element.type === SpotifyElement.TYPE_PLAYLIST &&
      basSource &&
      basSource.spotify.spotifyId === element.owner
    )
  }

  /**
   * @param {string} id
   * @param {SpotifyElement} element
   * @returns {Promise}
   */
  this.addElementToPlaylist = function (id, element) {

    var _this = this
    if (BasUtil.isNEString(id)) {

      return this.getElementData(element).then(onData)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ID)

    function onData (result) {

      if (Array.isArray(result)) {

        return _this.addToPlaylist(id, result)
      }

      return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
    }
  }

  /**
   * @param {string} playlistName
   * @param {SpotifyElement} element
   * @returns {Promise}
   */
  this.addElementToNewPlaylist = function (playlistName, element) {

    var _this

    _this = this

    return BasUtil.isNEString(playlistName)
      ? this.getElementData(element).then(onData)
      : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_NAME)

    function onData (result) {

      return Array.isArray(result)
        ? _this.addToNewPlaylist(playlistName, result)
        : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
    }
  }

  /**
   * @param {string} id
   * @param {string[]} items
   * @returns {Promise}
   */
  this.addToPlaylist = function (id, items) {

    var basSource

    basSource = LibraryState.getSpotifySource()

    return (
      basSource &&
      basSource.spotify &&
      basSource.spotify.addSongsToPlaylist
    )
      ? basSource.spotify.addSongsToPlaylist(id, { uris: items })
      : Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)
  }

  /**
   * @param {string} name
   * @param {string[]} items
   * @returns {Promise}
   */
  this.addToNewPlaylist = function (name, items) {

    var _this, basSource

    _this = this

    basSource = LibraryState.getSpotifySource()

    return (
      basSource &&
      basSource.spotify &&
      basSource.spotify.addNewPersonalPlaylist
    )
      ? basSource.spotify.addNewPersonalPlaylist({ name: name })
        .then(onSuccess)
      : Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)

    function onSuccess (result) {

      return (result && BasUtil.isNEString(result.id))
        ? _this.addToPlaylist(result.id, items)
        : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ID)
    }
  }

  /**
   * @returns {Promise<Object[]>}
   */
  this.getPlaylists = function () {

    var basSource = LibraryState.getSpotifySource()
    var func

    if (basSource && basSource.spotify) {

      func = basSource.spotify.getUserPlaylists
        .bind(basSource.spotify)

      return basSource.spotify.getXElements(func, 100, 0, -1)
        .then(processSpotifyPlaylists)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)

    function processSpotifyPlaylists (result) {

      var i, length, obj, array, spotifySource

      array = []
      spotifySource = LibraryState.getSpotifySource()

      if (spotifySource &&
        BasUtil.isObject(result) &&
        Array.isArray(result.items)) {

        length = result.items.length
        for (i = 0; i < length; i++) {
          obj = result.items[i]

          if (BasUtil.isObject(obj) &&
            BasUtil.isObject(obj.owner) &&
            spotifySource.spotify.spotifyId === obj.owner.id &&
            BasUtil.isNEString(obj.id) &&
            BasUtil.isNEString(obj.name)) {

            array.push({
              id: obj.id,
              title: obj.name
            })
          }
        }
      }

      return array
    }
  }

  /**
   * @param {string} type
   * @param {string} id
   * @returns Promise
   */
  this.checkFavourite = function (type, id) {

    var basSource = LibraryState.getSpotifySource()

    if (!basSource || !basSource.spotify.isLinked()) {

      return Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)
    }

    switch (type) {
      case SpotifyElement.TYPE_TRACK:
        return basSource.spotify.isTrackFavourite(id)
          .then(onFavourite)
      case SpotifyElement.TYPE_ALBUM:
        return basSource.spotify.isAlbumFavourite(id)
          .then(onFavourite)
      case SpotifyElement.TYPE_ARTIST:
        return basSource.spotify.isArtistFavourite(id)
          .then(onFavourite)
      case SpotifyElement.TYPE_PLAYLIST:
        return basSource.spotify.isPlaylistFavourite(id)
          .then(onFavourite)
      default:
        return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_TYPE)
    }

    function onFavourite (result) {

      if (BasUtil.isNEArray(result)) return result[0]

      return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
    }
  }

  /**
   * @param {string} id
   * @param {string} type
   * @param {boolean} isFavourite
   * @returns Promise
   */
  this.addRemoveFavouriteId = function (id, type, isFavourite) {

    var basSource = LibraryState.getSpotifySource()

    if (!basSource || !basSource.spotify.isLinked()) {

      return Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)
    }

    if (isFavourite) {

      switch (type) {
        case SpotifyElement.TYPE_TRACK:
          return basSource.spotify.removeTrackFavourite(id)
            .then(onFavourite)
        case SpotifyElement.TYPE_ALBUM:
          return basSource.spotify.removeAlbumFavourite(id)
            .then(onFavourite)
        case SpotifyElement.TYPE_ARTIST:
          return basSource.spotify.removeArtistFavourite(id)
            .then(onFavourite)
        case SpotifyElement.TYPE_PLAYLIST:
          return basSource.spotify.removePlaylistFavourite(id)
            .then(onFavourite)
        default:
          return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_TYPE)
      }

    } else {

      switch (type) {
        case SpotifyElement.TYPE_TRACK:
          return basSource.spotify.saveTrackFavourite(id)
            .then(onFavourite)
        case SpotifyElement.TYPE_ALBUM:
          return basSource.spotify.saveAlbumFavourite(id)
            .then(onFavourite)
        case SpotifyElement.TYPE_ARTIST:
          return basSource.spotify.saveArtistFavourite(id)
            .then(onFavourite)
        case SpotifyElement.TYPE_PLAYLIST:
          return basSource.spotify.savePlaylistFavourite(id)
            .then(onFavourite)
        default:
          return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_TYPE)
      }
    }

    function onFavourite () {
      return !isFavourite
    }
  }
}
