'use strict'

/**
 * @typedef {Object} TBasSipState
 * @property {boolean} hasDoorPhone
 * @property {string} uiSipStateDomain
 * @property {string[]} uiSipStateUsernames
 * @property {string} uiSipStateRegistrationSimple
 * @property {string} uiSipStateRegistrationDetailed
 * @property {boolean} uiShowSipState
 */

angular
  .module('basalteApp')
  .constant('BAS_UPDATER', {
    EVT_UPDATER_UPDATE_INFO: 'evtUpdaterUpdateInfo',

    CSS_CORE_CLIENT_HAS_NEW_VERSION: 'bas-core-client-has-new-version',
    CSS_CORE_CLIENT_UPDATE_PROGRESS: 'bas-core-client-update-progress',

    S_UPDATE_NONE: 'updaterUpdateNone',
    S_UPDATE_READY: 'updaterUpdateReady',
    S_UPDATE_CHECKING: 'updaterUpdateChecking',
    S_UPDATE_DOWNLOADING: 'updaterUpdateDownloading',
    S_UPDATE_VERIFYING: 'updaterUpdateVerifying',
    S_UPDATE_INSTALLING: 'updaterUpdateInstalling',

    APP_URI_UPDATER_LEGACY: 'be.basalte.ellie.updater',
    APP_URI_UPDATER: 'be.basalte.core.client.updater',

    K_CURRENT_TIMESTAMP: 'timestamp',
    K_NEW_TIMESTAMP: 'newTimestamp',
    K_VERSION: 'version',
    K_CURRENT_VERSION: 'currentVersion',
    K_NEW_VERSION: 'newVersion',
    K_HAS_UPDATE: 'hasUpdate',
    K_STATUS: 'status',
    K_PROGRESS: 'progress',

    S_CHECKING: 'checking',
    S_DOWNLOADING: 'downloading',
    S_VERIFYING: 'verifying',
    S_READY: 'ready',
    S_INSTALLING: 'installing',
    S_DONE: 'done'
  })
