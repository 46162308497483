'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasCallHistoryEntry', [
    '$rootScope',
    'BAS_CALL_HISTORY',
    'BasPreferences',
    'BasUtilities',
    'CurrentBasCore',
    basCallHistoryEntryFactory
  ])

/**
 * @param $rootScope
 * @param {BAS_CALL_HISTORY} BAS_CALL_HISTORY
 * @param {BasPreferences} BasPreferences
 * @param {BasUtilities} BasUtilities
 * @param {CurrentBasCore} CurrentBasCore
 * @returns BasFavourite
 */
function basCallHistoryEntryFactory (
  $rootScope,
  BAS_CALL_HISTORY,
  BasPreferences,
  BasUtilities,
  CurrentBasCore
) {

  /**
   * @type {TCurrentBasCoreState}
   */
  const currentBasCoreState = CurrentBasCore.get()

  /**
   * @constructor
   * @param {TBasCallHistoryEntry} callHistoryEntry
   */
  function BasCallHistoryEntry (callHistoryEntry) {
    /**
     *
     * @type {string}
     */
    this.timestamp = ''

    /**
     *
     * @type {string}
     */
    this.fullTimestamp = ''

    /**
     *
     * @type {string}
     */
    this.formattedTimestamp = ''

    /**
     * @type {?TContact}
     */
    this.contact = null

    /**
     * @type {string}
     */
    this.state = ''

    /**
     * @type {string}
     */
    this.adelanteName = ''

    /**
     * @type {TCallDetails[]}
     */
    this.images = []

    if (callHistoryEntry) this.parse(callHistoryEntry)
  }

  BasCallHistoryEntry.prototype.parse = function (callHistoryEntry) {

    if (BasUtil.isNEString(callHistoryEntry.state)) {
      this.state = callHistoryEntry.state
    }

    if (BasUtil.isNEString(callHistoryEntry.timestamp)) {
      this.timestamp = callHistoryEntry.timestamp
      this.setFormattedTimeStamp()
    }

    if (BasUtil.isNEString(callHistoryEntry.adelanteName)) {
      this.adelanteName = callHistoryEntry.adelanteName
    }

    if (
      callHistoryEntry.contact != null &&
      BasUtil.isObject(callHistoryEntry.contact)
    ) {
      this.contact = callHistoryEntry.contact
    }
  }

  BasCallHistoryEntry.prototype.syncImages = function () {
    const doorPhoneTopic = currentBasCoreState.core?.core?.doorPhoneTopic

    if (this.contact != null) {

      doorPhoneTopic.getCallDetails(
        this.contact.uuid,
        this.timestamp,
        0,
        1
      ).then(result => {
        this.images = []

        if (BasUtil.isNEArray(result.images)) {

          this.images = result.images

          $rootScope.$emit(
            BAS_CALL_HISTORY.EVT_CALL_HISTORY_ENTRY_IMAGES_UPDATED
          )
        }
      })
    }
  }

  function getTimezone () {
    return currentBasCoreState.core?.core?.system?.timezone
  }

  BasCallHistoryEntry.prototype.setFormattedTimeStamp = function () {
    const systemLanguage = BasUtilities.getLocaleParameter(
      BasUtil.isNEString(BasPreferences.getLanguage())
        ? BasPreferences.getLanguage()
        : BasUtilities.getSystemLanguage()
    )

    const date = new Date(this.timestamp)

    this.formattedTimestamp =
        formatTimestamp(this.timestamp, systemLanguage)
    this.fullTimestamp =
        new Intl.DateTimeFormat(systemLanguage, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          // Core will never be null when this function is called
          // since we just received the call history from it.
          timeZone: getTimezone()
        }).format(date)
  }

  function formatTimestamp (timestamp, systemLanguage) {
    const date = new Date(timestamp)
    const today = new Date()

    // Set today's date to midnight,
    // When comparing timestamps without setting hour to midnight it might
    // lead to unexpected results, as the comparison
    // would consider the time component as well.
    today.setHours(0, 0, 0, 0)

    // Calculate the difference in days
    const dayDifference =
      Math.floor((today - date) / (24 * 60 * 60 * 1000))

    const yesterday = new Date(0)
    // The -1 is indicating a subtraction of one day.
    yesterday.setHours(0, 0, 0, 0)
    yesterday.setMonth(today.getMonth(), today.getDate() - 1)
    yesterday.setFullYear(today.getFullYear())

    // Core will never be null when this function is called
    // since we just received the call history from it.
    const timezone = getTimezone()

    if (date >= yesterday) {

      // Today: show only hour and minute
      return new Intl.DateTimeFormat(systemLanguage, {
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone
      }).format(date)

    } else if (dayDifference < 7) {
      // Same week: show the day of the week
      return new Intl.DateTimeFormat(systemLanguage, {
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: timezone
      }).format(date)

    } else {
      // Show day, month and time
      return new Intl.DateTimeFormat(systemLanguage, {
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timezone: timezone
      }).format(date)
    }
  }

  return BasCallHistoryEntry
}
