'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasFavourite', [
    'ICONS',
    'BAS_FAVOURITE',
    'BAS_SOURCE',
    'BAS_IMAGE',
    'BAS_API',
    'BAS_TUNE_IN',
    'BAS_TIDAL',
    'BAS_DEEZER',
    'BasFavouritesHelper',
    'BasSpotifyUri',
    'BasTidalHelper',
    'BasImage',
    'BasImageTrans',
    'BasImageUtil',
    'BasUtilities',
    basFavouriteFactory
  ])

/**
 * @typedef {Object} TBasFavouriteParseOptions
 * @property {?boolean} [isAudioSourceFavourite]
 * @property {?boolean} [isVideoSourceFavourite]
 * @property {?boolean} [update]
 */

/**
 *
 * @param {ICONS} ICONS
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param BAS_API
 * @param {BAS_TUNE_IN} BAS_TUNE_IN
 * @param {BAS_TIDAL} BAS_TIDAL
 * @param {BAS_DEEZER} BAS_DEEZER
 * @param {BasFavouritesHelper} BasFavouritesHelper
 * @param BasSpotifyUri
 * @param {BasTidalHelper} BasTidalHelper
 * @param BasImage
 * @param BasImageTrans
 * @param {BasImageUtil} BasImageUtil
 * @param {BasUtilities} BasUtilities
 * @returns BasFavourite
 */
function basFavouriteFactory (
  ICONS,
  BAS_FAVOURITE,
  BAS_SOURCE,
  BAS_IMAGE,
  BAS_API,
  BAS_TUNE_IN,
  BAS_TIDAL,
  BAS_DEEZER,
  BasFavouritesHelper,
  BasSpotifyUri,
  BasTidalHelper,
  BasImage,
  BasImageTrans,
  BasImageUtil,
  BasUtilities
) {
  var K_URL = 'url'

  var biImgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_COVER
    ]
  }

  var biSvgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_COLOR_MUTED,
      BAS_IMAGE.C_SIZE_50
    ]
  }

  var biSvgBgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN
    ]
  }

  var biPlaylist = new BasImage(ICONS.queue, biSvgOpts)
  var biRadio = new BasImage(ICONS.radio, biSvgOpts)
  var biDeezer = new BasImage(ICONS.deezer, biSvgOpts)
  var biTidal = new BasImage(ICONS.tidal, biSvgOpts)
  var biSpotify = new BasImage(ICONS.spotifyNoMargin, biSvgOpts)
  var biTv = new BasImage(ICONS.externalTv, biSvgOpts)

  var biDeezerColor = new BasImage(ICONS.deezerColor, biSvgOpts)
  var biSpotifyColor = new BasImage(ICONS.spotifyNoMarginColor, biSvgOpts)

  /**
   * @constructor
   * @param {(Object|string)} favourite
   * @param parseOptions
   */
  function BasFavourite (favourite, parseOptions) {

    /**
     * Service ID, specific to the service
     *
     * @type {(string|number)}
     */
    this.sid = ''

    /**
     * ID used in alarm service
     *
     * @type {(string|number)}
     */
    this.alarmId = ''

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {number}
     */
    this.type = BAS_FAVOURITE.T_UNKNOWN

    /**
     * @type {number}
     */
    this.size = -1

    /**
     * Extra service ID info (playlist, radios, album, ...)
     *
     * @type {string}
     */
    this.contentType = ''

    /**
     * Protocol buffer ID
     *
     * @type {string}
     */
    this.pbId = ''

    /**
     * @type {BasImageTrans}
     */
    this.bitIcon = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: biPlaylist
    })

    /**
     * @type {Object}
     */
    this.ui = {
      title: '',
      subtitle: ''
    }

    /**
     * Name of the favourite, serves as favourite ID for the core
     *
     * @private
     * @type {string}
     */
    this._name = ''

    this.css = {}
    this._resetCss()

    this.handleTuneInInfo = this._onTuneInInfo.bind(this)
    this.handleDeezer = this._onDeezer.bind(this)
    this.handleTidal = this._onTidal.bind(this)
    this.handleSpotifyInfo = this._onSpotifyInfo.bind(this)

    if (favourite) this.parse(favourite, parseOptions)
  }

  /**
   * This is to provide legacy alarms functionality
   *
   * @private
   * @param {BasFavourite} favourite
   * @returns {string}
   * @deprecated
   */
  BasFavourite._getAlarmId = function (favourite) {

    var idx

    if (favourite && favourite.uuid) {
      switch (favourite.type) {
        case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
        case BAS_FAVOURITE.T_RADIO:
          // Extract UUID (last part of URI)
          // Considering this is legacy and
          // no new functionality should be added anymore
          idx = favourite.uuid.lastIndexOf(':')
          return idx > -1 ? favourite.uuid.substring(idx + 1) : favourite.uuid
        case BAS_FAVOURITE.T_DEEZER:
          return favourite.uuid.substring(BAS_SOURCE.URI_PREFIX_DEEZER.length)
        case BAS_FAVOURITE.T_TIDAL:
          return favourite.uuid.substring(BAS_SOURCE.URI_PREFIX_TIDAL.length)
        case BAS_FAVOURITE.T_SPOTIFY:
          return favourite.uuid.substring(BAS_SOURCE.URI_PREFIX_SPOTIFY.length)
      }
    }
    return ''
  }

  Object.defineProperty(BasFavourite.prototype, 'name', {
    get: function () {
      return this._name
    }
  })

  /**
   * @param {(TAudioSourceFavourite|TVideoSourceFavourite|Object)} favourite
   * @param {?TBasFavouriteParseOptions} [options]
   */
  BasFavourite.prototype.parse = function (
    favourite,
    options
  ) {

    var idx, isAudioSourceFavourite, isVideoSourceFavourite, update

    if (BasUtil.isObject(options)) {

      isAudioSourceFavourite = options.isAudioSourceFavourite === true
      isVideoSourceFavourite = options.isVideoSourceFavourite === true
      update = options.update === true
    }

    if (BasUtil.isObject(favourite)) {

      this.cssToggleShowDelete(true)

      if (isAudioSourceFavourite) {

        this.sid = favourite.uri

        // Only update service specific stuff when NOT updating

        if (!update) {

          if (BasUtil.isNEString(favourite.service)) {

            this.uuid = favourite.uri

            switch (favourite.service) {
              case 'tidal':
                this.type = BAS_FAVOURITE.T_TIDAL
                this.bitIcon.setDefaultImage(biTidal)
                break
              case 'deezer':
                this.type = BAS_FAVOURITE.T_DEEZER
                this.bitIcon.setDefaultImage(biDeezerColor)
                break
              case 'local':
                this.type = BAS_FAVOURITE.T_LOCAL_PLAYLIST
                this.bitIcon.setDefaultImage(biPlaylist)
                break
              case 'tunein':
                this.type = BAS_FAVOURITE.T_RADIO
                this.bitIcon.setDefaultImage(biRadio)
                break
              case 'spotify':
                this.type = BAS_FAVOURITE.T_SPOTIFY
                this.bitIcon.setDefaultImage(biSpotifyColor)
                break
              default:
                this.bitIcon.setDefaultImage(biPlaylist)
            }

          } else {

            // 'Legacy' Sonos favourites

            this.uuid = BasFavouritesHelper.getUuid(
              BAS_FAVOURITE.T_SONOS,
              favourite.uri
            )
            this.type = BAS_FAVOURITE.T_SONOS
            this.bitIcon.setDefaultImage(biPlaylist)
            if (!update || BasUtil.isNEString(favourite.type)) {

              this.ui.subtitle = favourite.type
            }
          }
        }

        // "this.sid" is already right format:
        //  e.g. 'sonos:favourites:47'
        //  e.g. 'sonos:playlists:1'
        this.pbId = this.sid

        // Legacy Alarm ID
        this.alarmId = BasFavourite._getAlarmId(this)

        if (!update || BasUtil.isNEString(favourite.name)) {

          this._name = favourite.name
        }

        if (!BasUtil.isNEString(this._name)) {

          this._name = BasUtilities.translate('unknown')
        }

        this.ui.title = this._name

        if (BasUtil.isPNumber(favourite.size, true)) {

          this.size = favourite.size
        }

        this._generateSizeSubtitle()

        if (BasUtil.isNEString(favourite.caption)) {

          this.ui.subtitle = favourite.caption
        }

        if (!update || BasUtil.isObject(favourite.images)) {

          this.bitIcon.setImage(
            BasImageUtil.getSizedImage(
              favourite.images,
              'img',
              120 // TODO not hardcoded
            )
          )
        }

        this.cssToggleShowDelete(favourite.removable)

      } else if (isVideoSourceFavourite) {

        this.sid = favourite.uri

        this.uuid = favourite.uri
        this.type = BAS_FAVOURITE.T_VIDEO
        this.bitIcon.setDefaultImage(biTv)
        this.pbId = this.sid
        this._name = BasUtil.isNEString(favourite.name)
          ? favourite.name
          : BasUtilities.translate('unknown')

        this.ui.title = this._name

        this.cssToggleShowDelete(favourite.removable)

      } else {

        // Legacy API (Object) favourites

        this._name = favourite[BAS_FAVOURITE._K_NAME]

        if (BAS_FAVOURITE._K_PLAYLIST in favourite) {

          // Local playlist

          this.sid = favourite[BAS_FAVOURITE._K_PLAYLIST]
          this.alarmId = this.sid

          this.uuid = BasFavouritesHelper.getUuid(
            BAS_FAVOURITE.T_LOCAL_PLAYLIST,
            favourite[BAS_FAVOURITE._K_PLAYLIST]
          )

          this.pbId = BAS_API.Player.FAV_T_LOCAL + ':' +
            BAS_API.Player.FAV_ST_PLAYLIST + ':' +
            favourite[BAS_FAVOURITE._K_PLAYLIST]

          this.type = BAS_FAVOURITE.T_LOCAL_PLAYLIST
          this.bitIcon.setDefaultImage(biPlaylist)

        } else if (BAS_FAVOURITE._K_GID in favourite) {

          // Radio

          this.sid = favourite[BAS_FAVOURITE._K_GID]
          this.alarmId = this.sid

          this.uuid = BasFavouritesHelper.getUuid(
            BAS_FAVOURITE.T_RADIO,
            favourite[BAS_FAVOURITE._K_GID]
          )

          this.pbId = BAS_API.Player.FAV_T_TUNEIN + ':' +
            BAS_API.Player.FAV_ST_RADIO + ':' +
            favourite[BAS_FAVOURITE._K_GID]

          this.type = BAS_FAVOURITE.T_RADIO
          this.bitIcon.setDefaultImage(biRadio)

        } else if (BAS_FAVOURITE._K_DEEZER_ID in favourite) {

          // Deezer

          idx = favourite[BAS_FAVOURITE._K_DEEZER_ID]
            .indexOf(BAS_FAVOURITE.SEP_DEEZER)

          if (idx > 0) {

            this.sid = favourite[BAS_FAVOURITE._K_DEEZER_ID]
              .substring(idx + 1)
            this.contentType = favourite[BAS_FAVOURITE._K_DEEZER_ID]
              .substring(0, idx)

          } else {

            this.sid = favourite[BAS_FAVOURITE._K_DEEZER_ID]
          }

          this.alarmId = this.contentType + ':' + this.sid

          this.uuid = BasFavouritesHelper.getUuid(
            BAS_FAVOURITE.T_DEEZER,
            favourite[BAS_FAVOURITE._K_DEEZER_ID]
          )

          this.pbId = BAS_API.Player.FAV_T_DEEZER
          this.pbId = BasUtil.addToString(
            this.pbId,
            this.contentType,
            ':'
          )
          this.pbId = BasUtil.addToString(
            this.pbId,
            this.sid,
            ':'
          )

          this.type = BAS_FAVOURITE.T_DEEZER
          this.bitIcon.setDefaultImage(biDeezer)

        } else if (BAS_FAVOURITE._K_TIDAL_ID in favourite) {

          // TIDAL

          idx = favourite[BAS_FAVOURITE._K_TIDAL_ID]
            .lastIndexOf(BAS_FAVOURITE.SEP_TIDAL)

          if (idx > 0) {

            this.sid = favourite[BAS_FAVOURITE._K_TIDAL_ID]
              .substring(idx + 1)
            this.contentType = favourite[BAS_FAVOURITE._K_TIDAL_ID]
              .substring(0, idx)

          } else {

            this.sid = favourite[BAS_FAVOURITE._K_TIDAL_ID]
          }

          this.alarmId = this.contentType + ':' + this.sid

          this.uuid = BasFavouritesHelper.getUuid(
            BAS_FAVOURITE.T_TIDAL,
            favourite[BAS_FAVOURITE._K_TIDAL_ID]
          )

          this.pbId = BAS_API.Player.FAV_T_TIDAL
          this.pbId = BasUtil.addToString(
            this.pbId,
            this.contentType,
            ':'
          )
          this.pbId = BasUtil.addToString(
            this.pbId,
            this.sid,
            ':'
          )

          this.type = BAS_FAVOURITE.T_TIDAL
          this.bitIcon.setDefaultImage(biTidal)

        } else if (BAS_FAVOURITE._K_URI in favourite) {

          // Spotify

          this.sid = favourite[BAS_FAVOURITE._K_URI]
          this.alarmId = this.sid

          this.uuid = BasFavouritesHelper.getUuid(
            BAS_FAVOURITE.T_SPOTIFY_CONNECT,
            this.sid
          )

          this.pbId = this.sid

          this.type = BAS_FAVOURITE.T_SPOTIFY_CONNECT
          this.bitIcon.setDefaultImage(biSpotify)

          this._generateSpotifyName()
          this._generateSpotifyTitle()
        }
      }

    } else if (favourite === BAS_FAVOURITE.UUID_DEEZER_FLOW) {

      this.uuid = this.sid = BAS_FAVOURITE.UUID_DEEZER_FLOW
      this.type = BAS_FAVOURITE.T_DEEZER
      this.bitIcon.setDefaultImage(biDeezer)
      this.bitIcon.setImage(biDeezerColor)
      this.alarmId = 'flow'
      this._generateTitle()
      this.pbId = BAS_API.Player.FAV_T_DEEZER + ':' +
        BAS_API.Player.FAV_ST_RADIO + ':' +
        BAS_API.Player.FAV_V_FLOW

    } else if (favourite === BAS_FAVOURITE.UUID_SPOTIFY_CONNECT_CURRENT) {

      this.uuid = this.sid = BAS_FAVOURITE.UUID_SPOTIFY_CONNECT_CURRENT
      this.type = BAS_FAVOURITE.T_SPOTIFY_CONNECT
      this.bitIcon.setDefaultImage(biSpotify)
      this.bitIcon.setImage(biSpotifyColor)
      this._generateTitle()
      this.pbId = BAS_API.Player.FAV_T_SPOTIFY + ':' +
        BAS_API.Player.FAV_ST_X_BASALTE + ':' +
        BAS_API.Player.FAV_V_PLAY
    }
  }

  /**
   * @param {BasSourcePlaylists} playlists
   * @returns {Promise}
   */
  BasFavourite.prototype.processLocalPlaylists = function (
    playlists
  ) {
    var playlist

    if (this.type === BAS_FAVOURITE.T_LOCAL_PLAYLIST) {

      /**
       * @type {BasPlaylist}
       */
      playlist = playlists.get(this.sid)

      if (playlist) {

        this.ui.title = playlist.title

        this._setSize(playlist.numberOfSongs)

        this.bitIcon.track(playlist.bitIcon)

        return playlists.retrieveCoverArt(playlist)

      } else {

        this.bitIcon.unTrack()
        this.bitIcon.setImage(null)

        return Promise.reject(BAS_FAVOURITE.ERR_NO_PLAYLIST)
      }
    }
    return Promise.reject(BAS_FAVOURITE.ERR_WRONG_TYPE)
  }

  /**
   * @private
   * @param {Object} result
   */
  BasFavourite.prototype._onTuneInInfo = function (result) {

    if (BasUtil.isObject(result)) {

      this.ui.title = result[BAS_TUNE_IN.K_NAME]
      this.ui.subtitle = result[BAS_TUNE_IN.K_GENRE_NAME]

      this.bitIcon.setImage(result[BAS_TUNE_IN.K_LOGO], biSvgBgOpts)
    }
  }

  /**
   * @private
   * @param result
   */
  BasFavourite.prototype._onDeezer = function (result) {

    if (BasUtil.isNEString(result[BAS_DEEZER.K_TITLE])) {

      this.ui.title = result[BAS_DEEZER.K_TITLE]
    }

    if (BasUtil.isPNumber(result[BAS_DEEZER.K_NUM_OF_TRACKS], true)) {

      this._setSize(result[BAS_DEEZER.K_NUM_OF_TRACKS])
    }

    if (BasUtil.isNEString(result[BAS_DEEZER.K_PICTURE_MEDIUM])) {

      this.bitIcon.setImage(result[BAS_DEEZER.K_PICTURE_MEDIUM])
    }
  }

  /**
   * @private
   * @param {Object} result
   */
  BasFavourite.prototype._onTidal = function (result) {

    var value

    if (BasUtil.isObject(result)) {

      value = result[BAS_TIDAL.K_TITLE]
      if (BasUtil.isNEString(value)) this.ui.title = value

      value = result[BAS_TIDAL.K_NUMBER_OF_TRACKS]
      if (BasUtil.isPNumber(value, true)) this._setSize(value)

      if (BasUtil.isNEString(result[BAS_TIDAL.K_SQUARE_IMAGE])) {

        this.bitIcon.setImage(
          BasTidalHelper.getImageUrl(
            result[BAS_TIDAL.K_SQUARE_IMAGE],
            BAS_TIDAL.IMG_SQUARE_SIZE_PLAYLIST_SMALL
          ),
          biImgOpts
        )

      } else if (BasUtil.isNEString(result[BAS_TIDAL.K_IMAGE])) {

        this.bitIcon.setImage(
          BasTidalHelper.getImageUrl(
            result[BAS_TIDAL.K_IMAGE],
            BAS_TIDAL.IMG_SIZE_PLAYLIST_SMALL
          ),
          biImgOpts
        )
      }
    }
  }

  /**
   * @private
   * @param {TSpotifyRetrievedInfo} info
   */
  BasFavourite.prototype._onSpotifyInfo = function (
    info
  ) {
    var basSpotifyUri, result, search

    /**
     * @type {BasSpotifyUri}
     */
    basSpotifyUri = info.uri

    result = info.result
    search = ''

    if (BasUtil.isNEString(basSpotifyUri.artist) ||
      BasUtil.isNEString(basSpotifyUri.album) ||
      BasUtil.isNEString(basSpotifyUri.playlist) ||
      BasUtil.isNEString(basSpotifyUri.track)) {

      if (BasUtil.isObject(result) &&
        BasUtil.isNEString(result.name)) {

        this.ui.title = result.name
      }

    } else if (basSpotifyUri.collection) {

      this.ui.title = BasUtilities.translate('favourite_songs')

    } else if (BasUtil.isNEString(basSpotifyUri.search)) {

      search = decodeURIComponent(basSpotifyUri.search)

      if (search.length >= 4 &&
        search.substring(search.length - 4) === '-all') {

        search = search.substring(0, search.length - 4)
      }

      this.ui.title = search
    }

    if (result) {

      if (Array.isArray(result.images)) {

        this._setImage(result.images)

      } else if (BasUtil.isObject(result.album)) {

        if (Array.isArray(result.album.images)) {

          this._setImage(result.album.images)
        }
      }
    }
  }

  /**
   * @param {Object[]} images Biggest 1st, smallest last
   * @private
   */
  BasFavourite.prototype._setImage = function (images) {

    var length

    if (Array.isArray(images)) {

      length = images.length

      if (length > 1) {

        // Choose second largest
        this.bitIcon.setImage(images[1][K_URL])

      } else if (length === 1) {

        this.bitIcon.setImage(images[0][K_URL])
      }
    }
  }

  /**
   * Sets the size en the subtitle
   *
   * @private
   * @param {number} size
   */
  BasFavourite.prototype._setSize = function (size) {

    this.size = size
    this._generateSizeSubtitle()
  }

  /**
   * Generate the _name for Spotify Connect presets
   *
   * If no valid _name is available
   * Take Spotify URI and remove the beginning (spotify:) and
   * concatenate with spaces instead of ':'.
   *
   * @private
   */
  BasFavourite.prototype._generateSpotifyName = function () {

    var split, length

    if (this.type === BAS_FAVOURITE.T_SPOTIFY_CONNECT &&
      !BasUtil.isNEString(this._name) &&
      BasUtil.isNEString(this.sid)) {

      split = this.sid.split(BAS_FAVOURITE.SEP_SPOTIFY)

      if (Array.isArray(split)) {

        length = split.length

        if (length > 2) {

          // Remove first entry (spotify)
          split.splice(0, 1)

          this._name = split.join(' ')
        }
      }
    }
  }

  /**
   * Generates a title for the favourite based on the Spotify URI.
   *
   * @private
   */
  BasFavourite.prototype._generateSpotifyTitle = function () {

    var basSpotifyUri, result, search

    result = ''

    if (this.type === BAS_FAVOURITE.T_SPOTIFY_CONNECT &&
      !BasUtil.isNEString(this._name) &&
      BasUtil.isNEString(this.sid)) {

      basSpotifyUri = new BasSpotifyUri(this.sid)

      if (basSpotifyUri.collection) {

        result = BasUtilities.translate('favourite_songs')

      } else if (basSpotifyUri.search) {

        search = decodeURIComponent(basSpotifyUri.search)

        if (search.length >= 4 &&
          search.substring(search.length - 4) === '-all') {

          search = search.substring(0, search.length - 4)
        }

        result = search
      }
    }

    this.ui.title = BasUtil.isNEString(result)
      ? result
      : BasUtil.isNEString(this._name)
        ? this._name
        : '-'
  }

  /**
   * Generate the title for certain BasFavourite instances
   *
   * @private
   */
  BasFavourite.prototype._generateTitle = function () {

    if (this.uuid === BAS_FAVOURITE.UUID_DEEZER_FLOW) {

      this.ui.title = BasUtilities.translate('deezer_flow_full')

    } else if (this.uuid === BAS_FAVOURITE.UUID_SPOTIFY_CONNECT_CURRENT) {

      this.ui.title = BasUtilities.translate('spotify_connect')
    }
  }

  /**
   * Generate the size subtitle "x song(s)"
   *
   * @private
   */
  BasFavourite.prototype._generateSizeSubtitle = function () {

    if (this.size !== -1) {

      this.ui.subtitle = BasUtilities.translate(
        'mf_song',
        {
          count: this.size
        },
        'messageformat'
      )
    }
  }

  /**
   * @param {boolean} [override]
   */
  BasFavourite.prototype.cssToggleShowDelete = function (override) {

    this.css[BAS_FAVOURITE.CSS_SHOW_DELETE] = BasUtil.isBool(override)
      ? override
      : !this.css[BAS_FAVOURITE.CSS_SHOW_DELETE]
  }

  BasFavourite.prototype._resetCss = function () {

    this.css[BAS_FAVOURITE.CSS_SHOW_DELETE] = false
  }

  /**
   * Updates the translation
   */
  BasFavourite.prototype.updateTranslation = function () {

    this._generateTitle()
    this._generateSizeSubtitle()
  }

  return BasFavourite
}
