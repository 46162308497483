'use strict'

const BasUtil = require('@basalte/bas-util')

const GenericDevice = require('./generic_device')
const GenericDeviceControl = require('./generic_device_control')

const P = require('./parser_constants')

// TODO: Add single controls
/**
 * @typedef {Object} TGenericDeviceCategory
 * @property {string} name
 * @property {GenericDeviceControl[]} controls
 */

/**
 * @constructor
 * @extends GenericDevice
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function GenericDeviceV2 (device, basCore) {
  GenericDevice.call(this, device, basCore)

  /**
   * @type {TGenericDeviceCategory[]}
   * @private
   */
  this._categories = []

  /**
   * @type {string}
   * @private
   */
  this._icon = ''

  /**
   * @type {string}
   * @private
   */
  this._nameType = ''

  this.parse(device, { emit: false })
}

GenericDeviceV2.prototype = Object.create(GenericDevice.prototype)
GenericDeviceV2.prototype.constructor = GenericDeviceV2

/**
 * @name GenericDeviceV2#category
 * @type {TGenericDeviceCategory[]}
 * @readonly
 */
Object.defineProperty(GenericDeviceV2.prototype, 'categories', {
  get: function () {
    return Array.isArray(this._categories)
      ? this._categories
      : []
  }
})

/**
 * @name GenericDeviceV2#nameType
 * @type {string}
 * @readonly
 */
Object.defineProperty(GenericDeviceV2.prototype, 'nameType', {
  get: function () {
    return this._nameType
  }
})

/**
 * @name GenericDeviceV2#icon
 * @type {string}
 * @readonly
 */
Object.defineProperty(GenericDeviceV2.prototype, 'icon', {
  get: function () {
    return BasUtil.isNEString(this._icon)
      ? this._icon
      : ''
  }
})

/**
 * Parse a GenericDeviceV2 message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
GenericDeviceV2.prototype.parse = function (msg, options) {
  const _this = this
  let emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (BasUtil.isNEString(msg[P.ICON])) {
    this._icon = msg[P.ICON]
  }

  const valid = GenericDevice.prototype.parse.call(this, msg, options)

  if (valid) {
    parseNameType(msg)
    parseCategoriesOrControls(msg)
  }

  return valid

  /**
   * @param {Object} message
   */
  function parseNameType (message) {
    const nameType = message[P.NAME_TYPE]

    if (BasUtil.isNEString(nameType)) {
      _this._nameType = nameType
    }
  }

  /**
   * @param {Object} message
   */
  function parseCategoriesOrControls (message) {
    const categories = message[P.CATEGORIES]

    if (!Array.isArray(_this._categories)) _this._categories = []

    // Gets triggered only once to parse categories
    if (BasUtil.isNEArray(categories)) {

      for (let i = 0; i < categories.length; i++) {
        const category = categories[i]

        const controls = category[P.CONTROLS]
        const parsedControls = []

        for (const control of controls) {
          if (BasUtil.isObject(control) && BasUtil.isNEString(control.uuid)) {

            parsedControls.push(new GenericDeviceControl(control, null))
          }
        }

        if (
          BasUtil.isNEArray(parsedControls) &&
          !_this._categories[i]?.controls?.length
        ) {

          _this._categories[i] = {
            name: category[P.NAME],
            controls: parsedControls
          }
        }
      }
    } else {
      // Gets triggered when changing values of a control
      if (BasUtil.isNEArray(message[P.CONTROLS])) {

        for (let i = 0; i < _this.categories.length; i++) {
          const updatedControl = message[P.CONTROLS][0]

          if (
            BasUtil.isObject(updatedControl) &&
            BasUtil.isNEString(updatedControl.uuid)
          ) {

            const index = _this.indexOfGenericDeviceControl(
              _this.categories[i].controls,
              updatedControl
            )

            if (index !== -1) {
              _this.categories[i].controls[index].parse(updatedControl)
            }
          }

          if (emit) {

            _this.emit(
              GenericDevice.EVT_CONTROLS_UPDATED,
              _this._categories[i].controls
            )
          }
        }
      }
    }
  }
}

/**
 * Custom destroy to destroy all child generic device controls.
 */
GenericDeviceV2.prototype.destroy = function () {

  var length, i

  if (Array.isArray(this._categories)) {

    length = this._categories.length
    for (i = 0; i < length; i++) {

      BasUtil.executeFunction(this._categories[i], 'destroy')
    }
  }

  GenericDevice.prototype.destroy.call(this)
}

module.exports = GenericDeviceV2
