'use strict'

angular
  .module('basalteApp')
  .constant('BAS_UTILITIES', {
    REGEX_EMAIL: /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/,
    REGEX_LOWER_CASE: /[a-z]/,
    REGEX_UPPER_CASE: /[A-Z]/,
    REGEX_NUMBER: /\d/,
    REGEX_SPACES: / /g,
    REGEX_WHITESPACES: /\s/g,
    REGEX_PLUS_CHARS: /\+/g,
    T_1H_MS: 3600000, // 1 * 60 * 60 * 1000
    T_1D_MS: 86400000, // 1 * 24 * 60 * 60 * 1000
    T_1W_MS: 604800000 // 7 * 24 * 60 * 60 * 1000
  })
