'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasInputModal', [
    'ModalService',
    'BAS_HTML',
    'BAS_INPUT_MODAL',
    'BasInputModalConfig',
    BasInputModal
  ])

/**
 * Service for keeping modal types
 *
 * @constructor
 * @param ModalService
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param BasInputModalConfig
 */
function BasInputModal (
  ModalService,
  BAS_HTML,
  BAS_INPUT_MODAL,
  BasInputModalConfig
) {
  this.show = show

  /**
   * @param {string} type
   * @param {Object} [options]
   * @param {string} [options.title]
   * @param {string} [options.fieldName]
   * @param {(string|number)} [options.initialValue]
   * @param {number} [options.step]
   * @param {TBasModalInput[]} [options.inputs]
   * @param {Date} [options.time]
   * @param {string} [options.roomId]
   * @param {string} [options.sourceUuid]
   *
   * @returns {Promise}
   */
  function show (type, options) {

    var modalOptions, basInputModalConfig

    basInputModalConfig = getBasInputModalConfig(type, options)

    // Base options
    modalOptions = {
      template: basInputModalConfig.HTMLTemplate,
      controller: basInputModalConfig.controller,
      controllerAs: 'modal',
      inputs: {
        basModalConfig: basInputModalConfig
      }
    }

    // BasModal options
    if (basInputModalConfig.modalOptions) {

      BasUtil.mergeObjects(
        modalOptions,
        basInputModalConfig.modalOptions
      )
    }

    // User options
    if (options && options.modalOptions) {

      BasUtil.mergeObjects(
        modalOptions,
        options.modalOptions
      )
    }

    return ModalService.showModal(modalOptions)
  }

  /**
   * Create the correct BasInputModalConfig based on the given type.
   *
   * @param {string} type
   * @param {Object} [options]
   * @returns {BasInputModalConfig}
   */
  function getBasInputModalConfig (type, options) {

    /**
     * @type {BasInputModalConfig}
     */
    var basInputModalConfig = new BasInputModalConfig()

    switch (type) {

      case BAS_INPUT_MODAL.T_IP_ADDRESS:

        basInputModalConfig.HTMLTemplate = BAS_HTML.textInputModal
        basInputModalConfig.controller = 'textInputModalCtrl'
        basInputModalConfig.errorTextId = 'value_invalid'
        basInputModalConfig.saveTextId = 'save'

        basInputModalConfig.inputs = [{}]
        basInputModalConfig.inputs[0].validCheck = BasUtil.isValidIp
        basInputModalConfig.inputs[0].placeholder = '255.255.255.255'

        if (BasUtil.isObject(options)) {

          if (BasUtil.isString(options.title)) {
            basInputModalConfig.title = options.title
          }

          if (BasUtil.isString(options.fieldName)) {
            basInputModalConfig.inputs[0].fieldName = options.fieldName
          }

          if (BasUtil.isString(options.initialValue)) {
            basInputModalConfig.inputs[0].initialValue = options.initialValue
          }
        }

        break

      case BAS_INPUT_MODAL.T_FAVOURITE_CHANNEL:

        basInputModalConfig.HTMLTemplate = BAS_HTML.textInputModal
        basInputModalConfig.controller = 'textInputModalCtrl'
        basInputModalConfig.errorTextId = 'value_invalid'
        basInputModalConfig.saveTextId = 'save'
        basInputModalConfig.title = 'add_favourite'

        basInputModalConfig.inputs = [
          {
            validCheck: BasUtil.isNEString,
            placeholder: 'National Geographic',
            fieldName: 'name'
          },
          {
            validCheck: function (value) {
              return /^\d+$/.test(value)
            },
            placeholder: '123',
            fieldName: 'channel_number'
          }
        ]

        break

      case BAS_INPUT_MODAL.T_MULTI_SCREEN:

        basInputModalConfig.HTMLTemplate = BAS_HTML.multiScreenModal
        basInputModalConfig.controller = 'multiScreenInputModalCtrl'
        basInputModalConfig.errorTextId = 'value_invalid'
        basInputModalConfig.saveTextId = 'save'
        basInputModalConfig.inputs = []

        if (BasUtil.isObject(options)) {

          if (BasUtil.isString(options.title)) {
            basInputModalConfig.title = options.title
          }

          if (BasUtil.isNEArray(options.inputs)) {
            basInputModalConfig.inputs = options.inputs
          }

          if (BasUtil.isBool(options.autoAdvance)) {
            basInputModalConfig.autoAdvance = options.autoAdvance
          }

          if (BasUtil.isFunction(options.validCheck)) {
            basInputModalConfig.validCheck = options.validCheck
          }

          if (BasUtil.isBool(options.allowGoBack)) {
            basInputModalConfig.allowGoBack = options.allowGoBack
          }

          if (BasUtil.isBool(options.autoSave)) {
            basInputModalConfig.autoSave = options.autoSave
          }

          if (BasUtil.isBool(options.clearCurrentOnBack)) {
            basInputModalConfig.clearCurrentOnBack =
              options.clearCurrentOnBack
          }

          if (BasUtil.isBool(options.clearAllOnBack)) {
            basInputModalConfig.clearAllOnBack =
              options.clearAllOnBack
          }

          if (BasUtil.isBool(options.useDynamicHeight)) {
            basInputModalConfig.useDynamicHeight =
              options.useDynamicHeight
          }
        }

        break

      case BAS_INPUT_MODAL.T_TIME:

        basInputModalConfig.HTMLTemplate = BAS_HTML.timeInputModal
        basInputModalConfig.controller = 'timeInputModalCtrl'
        basInputModalConfig.saveTextId = 'save'

        if (BasUtil.isObject(options)) {

          if (BasUtil.isString(options.title)) {
            basInputModalConfig.title = options.title
          }

          if (BasUtil.isObject(options.time)) {
            basInputModalConfig.time = options.time
          }
        }

        break

      case BAS_INPUT_MODAL.T_AVROOM_SELECT:

        basInputModalConfig.HTMLTemplate = BAS_HTML.avRoomSelectModal
        basInputModalConfig.controller = 'avRoomSelectModalController'

        if (BasUtil.isObject(options)) {

          if (BasUtil.isNEString(options.roomId)) {

            basInputModalConfig.roomId = options.roomId

          } else if (BasUtil.isNEString(options.sourceUuid)) {

            basInputModalConfig.sourceUuid = options.sourceUuid
          }
        }

        break

      case BAS_INPUT_MODAL.T_NUMBER:

        basInputModalConfig.HTMLTemplate = BAS_HTML.numberInputModal
        basInputModalConfig.controller = 'numberInputModalCtrl'
        basInputModalConfig.saveTextId = 'save'

        basInputModalConfig.inputs = [{}]
        basInputModalConfig.inputs[0].initialValue = ''
        basInputModalConfig.inputs[0].step = 1
        basInputModalConfig.title = 'number'

        if (BasUtil.isObject(options)) {

          if (BasUtil.isString(options.title)) {
            basInputModalConfig.title = options.title
          }

          if (BasUtil.isString(options.subTitle)) {
            basInputModalConfig.subTitle = options.subTitle
          }

          if (BasUtil.isString(options.initialValue)) {
            basInputModalConfig.inputs[0].initialValue = options.initialValue
          }

          if (BasUtil.isVNumber(options.min)) {
            basInputModalConfig.inputs[0].min = options.min
          }

          if (BasUtil.isVNumber(options.max)) {
            basInputModalConfig.inputs[0].max = options.max
          }

          if (BasUtil.isVNumber(options.step)) {
            basInputModalConfig.inputs[0].step = options.step
          }

          if (BasUtil.isFunction(options.validCheck)) {
            basInputModalConfig.inputs[0].validCheck = options.validCheck
          }
        }

        break
    }

    return basInputModalConfig
  }
}
