'use strict'

angular
  .module('basalteApp')
  .constant('BAS_GENERIC_DEVICE', {
    TYPE_V1: 'generic',
    TYPE_V2: 'generic_v2',
    NAME_TYPE_CUSTOM: 'NAME_TYPE_CUSTOM',
    NAME_TYPE_OTHER: 'NAME_TYPE_OTHER',
    NAME_TYPE_APPLIANCES: 'NAME_TYPE_APPLIANCES',
    NAME_TYPE_ARTWORK: 'NAME_TYPE_ARTWORK',
    NAME_TYPE_ENERGY: 'NAME_TYPE_ENERGY',
    NAME_TYPE_FIRE_PLACE: 'NAME_TYPE_FIRE_PLACE',
    NAME_TYPE_FOUNTAIN: 'NAME_TYPE_FOUNTAIN',
    NAME_TYPE_GARAGE: 'NAME_TYPE_GARAGE',
    NAME_TYPE_GARDEN: 'NAME_TYPE_GARDEN',
    NAME_TYPE_IRRIGATION: 'NAME_TYPE_IRRIGATION',
    NAME_TYPE_POOL: 'NAME_TYPE_POOL',
    NAME_TYPE_SECURITY: 'NAME_TYPE_SECURITY',
    NAME_TYPE_SOCKET: 'NAME_TYPE_SOCKET',
    NAME_TYPE_VENTILATION: 'NAME_TYPE_VENTILATION',
    NAME_TYPE_WELLNESS_SPA: 'NAME_TYPE_WELLNESS_SPA'
  })
