'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSceneHelper', [
    'RoomsHelper',
    'BasUtilities',
    BasSceneHelper
  ])

/**
 * @constructor
 * @param {RoomsHelper} RoomsHelper
 * @param {BasUtilities} BasUtilities
 */
function BasSceneHelper (
  RoomsHelper,
  BasUtilities
) {
  this.getRoom = getRoom
  this.getScenes = getScenes
  this.getScene = getScene
  this.getStep = getStep
  this.getStepRoom = getStepRoom
  this.getSceneId = getSceneId
  this.copyState = copyState

  /**
   * @param scope
   * @returns {?BasRoom}
   */
  function getRoom (scope) {

    // Also check if method isRoom is available on room instance
    if (BasUtil.isObject(scope) &&
      BasUtil.isObject(scope.scenes) &&
      BasUtil.isObject(scope.scenes.room) &&
      scope.scenes.room.isRoom) {

      return scope.scenes.room
    }

    return null
  }

  /**
   * @param scope
   * @returns {?BasRoomScenes}
   */
  function getScenes (scope) {

    var _room

    _room = getRoom(scope)

    return _room ? _room.scenes : null
  }

  /**
   * @param {?(BasRoom|Object)} room Or scope
   * @param state
   * @returns {?BasScene}
   */
  function getScene (room, state) {

    var _room, _scenes, _sceneId

    _room = BasUtilities.isScope(room) ? getRoom(room) : room

    _scenes = _room ? _room.scenes : null

    if (_scenes) {

      _sceneId = getSceneId(state)

      if (_sceneId) {

        return _scenes.scenes[_sceneId]
      }
    }

    return null
  }

  /**
   * @param {?(BasRoom|Object)} room Or scope
   * @param state
   * @returns {?BasStep}
   */
  function getStep (room, state) {

    var _scene, _stepId

    _stepId = getStepId(state)

    if (_stepId) {

      _scene = getScene(room, state)

      if (_scene) return _scene.steps[_stepId]
    }

    return null
  }

  /**
   * Extracts step from state parameters
   *
   * @param {Object} state
   * @param {Object} state.params
   * @param {string} state.params.step
   * @returns {string}
   */
  function getStepId (state) {

    var _stepId

    if (state && state.params) _stepId = state.params.step

    return BasUtil.isNEString(_stepId) ? _stepId : ''
  }

  /**
   * @param {BasStep} step
   * @returns {?BasRoom}
   */
  function getStepRoom (step) {

    return step ? RoomsHelper.getRoomForId(step.room) : null
  }

  /**
   * Extracts scene from state parameters
   *
   * @param {Object} state
   * @param {Object} state.params
   * @param {string} state.params.scene
   * @returns {string}
   */
  function getSceneId (state) {

    var _sceneId

    if (state && state.params) _sceneId = state.params.scene

    return BasUtil.isNEString(_sceneId) ? _sceneId : ''
  }

  /**
   * @param {?Object} state
   * @returns {?Object}
   */
  function copyState (state) {

    if (state && state.params) {

      return {
        params: {
          scene: state.params.scene,
          step: state.params.step
        }
      }
    }

    return {}
  }
}
