'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasState', [
    '$rootScope',
    '$state',
    '$transitions',
    '$uiRouterGlobals',
    'STATES',
    'BAS_STATE',
    'BasStateHelper',
    'Logger',
    BasState
  ])

/**
 * @typedef {STATES} BasStateBool
 * @type {Object<string, boolean>}
 */

/**
 * @typedef {STATES} BasStateMap
 * @type {Object<string, string>}
 */

/**
 * @typedef {BasStateBool} BasStateCurrent
 * @property {boolean} drawerButton
 * @property {boolean} miniPlayer
 */

/**
 * @typedef {Object} TBasStateObj
 * @property {BasStateBool} current
 * @property {string[]} keys All state keys
 * @property {string[]} names All state names
 */

/**
 * Will emit ($rootScope) event on library state change
 *
 * @constructor
 * @param $rootScope
 * @param $state
 * @param $transitions
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BasStateHelper} BasStateHelper
 * @param Logger
 */
function BasState (
  $rootScope,
  $state,
  $transitions,
  $uiRouterGlobals,
  STATES,
  BAS_STATE,
  BasStateHelper,
  Logger
) {
  /**
   * @type {TBasStateObj}
   */
  var basState = {}
  basState.current = {}
  basState.keys = Object.keys(STATES)
  basState.names = BasUtil.objectToArray(STATES)

  // Initialize dictionaries
  BasUtil.setProperties(basState.current, basState.keys, false)

  this.get = get
  this.go = go
  this.target = target

  init()

  function init () {

    $transitions.onError({}, onError)
    $transitions.onStart({}, onStart)
    $transitions.onFinish({}, onFinish)
    $transitions.onSuccess({}, onSuccess)
  }

  /**
   * @returns {TBasStateObj}
   */
  function get () {
    return basState
  }

  // Events

  function onError (transition) {

    transition.promise.catch(onTransitionError)

    function onTransitionError (error) {

      // Reject types are found here https://github.com/ui-router/
      // core/blob/0b1e9ed40e12b99d2a658d429203ba9b0a0d78e3/
      // src/transition/rejectFactory.ts
      //
      // 2 SUPERSEDED
      // 5 IGNORED transitions targets same state and params
      if (error.type !== 2 && error.type !== 5) {

        Logger.error('BasState - onError', error)
      }
    }
  }

  function onStart (transition) {
    Logger.debug('BasState - onStart', transition)
  }

  // BEFORE transition finishes
  function onFinish (transition) {
    Logger.debug('BasState - onFinish', transition)
  }

  // AFTER successful transition completed
  function onSuccess (transition) {
    Logger.debug('BasState - onSuccess', transition)

    processCurrentState()

    $rootScope.$emit(BAS_STATE.EVT_STATE_SUCCESS, transition)
  }

  /**
   * Process the current state name to set parent states
   */
  function processCurrentState () {

    var currentState, length, i, key, stateName

    currentState = $uiRouterGlobals.current.name

    if (currentState) {

      length = basState.keys.length
      for (i = 0; i < length; i++) {

        key = basState.keys[i]
        stateName = STATES[key]

        basState.current[key] =
          BasStateHelper.hasBaseState(currentState, stateName)
      }

    } else {

      BasUtil.setProperties(
        basState.current,
        basState.keys,
        false
      )
    }
  }

  /**
   * Go to state (checks if state is valid/known)
   *
   * @param {StateOrName} state
   * @param {Object} [params]
   * @param {Object} [options]
   * @returns {Promise}
   */
  function go (state, params, options) {

    // This is the only place where we can use '$state.go(...)' plainly,
    //  all other code occurrences should use 'BasState.go(...)' instead.
    // eslint-disable-next-line no-restricted-syntax
    return $state.go(
      state,
      params,
      _updateTransitionOptions(options)
    )
  }

  /**
   * Returns a TargetState
   *
   * @param {string} state
   * @param {Object} [params]
   * @param {Object} [options]
   * @returns {TargetState}
   */
  function target (state, params, options) {

    // This is the only place where we can use '$state.target(...)' plainly,
    //  all other code occurrences should use 'BasState.target(...)' instead.
    // eslint-disable-next-line no-restricted-syntax
    return $state.target(
      state,
      params,
      _updateTransitionOptions(options)
    )
  }

  /**
   * @private
   * @param {Object?} options
   * @returns {Object}
   */
  function _updateTransitionOptions (options) {

    var _options

    // State transition is initiated by us, so exiting the main state should
    //  always be allowed (unless BasExitMainState is already false of course).
    _options = BasUtil.isObject(options) ? options : {}
    if (!_options.custom) _options.custom = {}
    if (!BasUtil.isBool(_options.custom.BasExitMainState)) {
      _options.custom.BasExitMainState = true
    }

    return _options
  }
}
