{
  "s16933":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF VivaCitÃ© Mons",
      "description":"VivaCitÃ© est le chaÃ®ne de proximitÃ© de la RTBF, fiÃ¨re de ses attaches rÃ©gionales, elle est aussi la chaÃ®ne du sport.",
      "element":"station",
      "frequency":"101.8",
      "guide_id":"s16933",
      "language":"French",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-vivacite.png",
      "name":"RTBF VivaCitÃ© Mons",
      "slogan":"En Toute Complicite",
      "url":"http://www.vivacite.be/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/vivacite/m3u/vivahnt-aac.pls"
    ]
  },
  "s20277":{
    "country":"UK",
    "describe":{
      "band":"DAB",
      "call_sign":"BBC R1X",
      "element":"station",
      "frequency":"225.648",
      "guide_id":"s20277",
      "location":"UK",
      "logo":"https://basalte.s3-eu-west-1.amazonaws.com/img/bbc_1xtra_colour.svg",
      "name":"BBC Radio 1Xtra",
      "slogan":"Home of new black music from the BBC",
      "twitter_id":"1Xtra",
      "url":"http://www.bbc.co.uk/1xtra/"
    },
    "streams":[
      "http://open.live.bbc.co.uk/mediaselector/5/select/version/2.0/mediaset/http-icy-mp3-a/vpid/bbc_1xtra/format/pls.pls"
    ]
  },
  "s2409":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF Classic 21",
      "description":"Le meilleur du Rock et de la Pop des 40 derniÃ¨res annÃ©es",
      "element":"station",
      "frequency":"93.2",
      "genre_name":"Classic Rock",
      "guide_id":"s2409",
      "language":"French",
      "location":"Brussels",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-classic21.png",
      "name":"RTBF Classic 21",
      "slogan":"La radio Rock'n'Pop",
      "twitter_id":"Classic21",
      "url":"http://www.rtbf.be/classic21/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/classic21/m3u/classic21-aac.pls"
    ]
  },
  "s24939":{
    "country":"UK",
    "describe":{
      "band":"FM",
      "call_sign":"BBC R1",
      "element":"station",
      "frequency":"98.8",
      "guide_id":"s24939",
      "location":"London, UK",
      "logo":"https://basalte.s3-eu-west-1.amazonaws.com/img/bbc_radio_one_colour.svg",
      "name":"BBC Radio 1",
      "slogan":"The best new music",
      "twitter_id":"BBCR1",
      "url":"http://www.bbc.co.uk/radio1/"
    },
    "streams":[
      "http://open.live.bbc.co.uk/mediaselector/5/select/version/2.0/mediaset/http-icy-mp3-a/vpid/bbc_radio_one/format/pls.pls"
    ]
  },
  "s24940":{
    "country":"UK",
    "describe":{
      "band":"FM",
      "call_sign":"BBC R2",
      "element":"station",
      "frequency":"89.1",
      "guide_id":"s24940",
      "location":"London, UK",
      "logo":"https://basalte.s3-eu-west-1.amazonaws.com/img/bbc_radio_two_colour.svg",
      "name":"BBC Radio 2",
      "slogan":"Amazing music. Played by an amazing line up.",
      "twitter_id":"BBCRadio2",
      "url":"http://www.bbc.co.uk/radio2/"
    },
    "streams":[
      "http://open.live.bbc.co.uk/mediaselector/5/select/version/2.0/mediaset/http-icy-mp3-a/vpid/bbc_radio_two/format/pls.pls"
    ]
  },
  "s24941":{
    "country":"UK",
    "describe":{
      "band":"FM",
      "call_sign":"BBC R3",
      "element":"station",
      "frequency":"91.3",
      "guide_id":"s24941",
      "location":"London, UK",
      "logo":"https://basalte.s3-eu-west-1.amazonaws.com/img/bbc_radio_three_colour.svg",
      "name":"BBC Radio 3",
      "slogan":"Live music and arts",
      "twitter_id":"bbcradio3",
      "url":"http://www.bbc.co.uk/radio3/"
    },
    "streams":[
      "http://open.live.bbc.co.uk/mediaselector/5/select/mediaset/http-icy-mp3-a/vpid/bbc_radio_three/format/pls.pls"
    ]
  },
  "s24943":{
    "country":"UK",
    "describe":{
      "band":"AM",
      "call_sign":"BBC R5L",
      "element":"station",
      "frequency":"909",
      "guide_id":"s24943",
      "location":"London, UK",
      "logo":"https://basalte.s3-eu-west-1.amazonaws.com/img/bbc_radio_five_live_colour.svg",
      "name":"BBC Radio 5 live",
      "slogan":"Live news - live sport",
      "twitter_id":"bbc5live",
      "url":"http://www.bbc.co.uk/5live/"
    },
    "streams":[
      "http://open.live.bbc.co.uk/mediaselector/5/select/version/2.0/mediaset/http-icy-mp3-a/vpid/bbc_radio_five_live/format/pls.pls"
    ]
  },
  "s25222":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF VivaCitÃ© Bruxelles",
      "description":"VivaCitÃ© est le chaÃ®ne de proximitÃ© de la RTBF, fiÃ¨re de ses attaches rÃ©gionales, elle est aussi la chaÃ®ne du sport.",
      "element":"station",
      "email":"vivacite@rtbf.be",
      "frequency":"99.3",
      "guide_id":"s25222",
      "language":"French",
      "location":"Brussels",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-vivacite.png",
      "name":"RTBF VivaCitÃ© Bruxelles",
      "slogan":"En Toute Complicite",
      "url":"http://www.vivacite.be/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/vivacite/m3u/vivabxl-aac.pls"
    ]
  },
  "s25419":{
    "country":"UK",
    "describe":{
      "band":"FM",
      "call_sign":"BBC R4",
      "element":"station",
      "frequency":"93.5",
      "guide_id":"s25419",
      "location":"London, UK",
      "logo":"https://basalte.s3-eu-west-1.amazonaws.com/img/bbc_radio_fourfm_colour.svg",
      "name":"BBC Radio 4",
      "slogan":"Intelligent speech",
      "twitter_id":"BBCRadio4",
      "url":"http://www.bbc.co.uk/radio4/"
    },
    "streams":[
      "http://open.live.bbc.co.uk/mediaselector/5/select/mediaset/http-icy-mp3-a/vpid/bbc_radio_fourfm/format/pls.pls"
    ]
  },
  "s25658":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF La PremiÃ¨re",
      "description":"La PremiÃ¨re, c'est la radio de rÃ©fÃ©rence en matiÃ¨re d'information et de Culture en Belgique francophone.\r\nUne radio moderne, dans un esprit d'ouverture et de curiositÃ©.",
      "element":"station",
      "email":"info@rtbf.be",
      "frequency":"92.5",
      "guide_id":"s25658",
      "language":"French",
      "location":"Brussels",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-la-premiere.png",
      "name":"RTBF La PremiÃ¨re",
      "slogan":"Restons curieux",
      "twitter_id":"lapremiere",
      "url":"http://www.rtbf.be/lapremiere/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/lapremiere/m3u/lapremiere-aac.pls"
    ]
  },
  "s25660":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF VivaCitÃ© Liege",
      "description":"VivaCitÃ© est le chaÃ®ne de proximitÃ© de la RTBF, fiÃ¨re de ses attaches rÃ©gionales, elle est aussi la chaÃ®ne du sport.",
      "element":"station",
      "email":"vivacite@rtbf.be",
      "frequency":"90.5",
      "guide_id":"s25660",
      "language":"French",
      "location":"LiÃ¨ge",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-vivacite.png",
      "name":"RTBF VivaCitÃ© Liege",
      "slogan":"En Toute Complicite",
      "url":"http://www.vivacite.be/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/vivacite/m3u/vivaliege-aac.pls"
    ]
  },
  "s25662":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF Musiq 3",
      "description":"Classical music, but also jazz, world music and French chansons.",
      "element":"station",
      "frequency":"91.2",
      "genre_name":"Classical",
      "guide_id":"s25662",
      "language":"French",
      "location":"Brussels",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-musiq3.png",
      "name":"RTBF Musiq 3",
      "slogan":"",
      "twitter_id":"musiq_3",
      "url":"http://www.musiq3.be/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/musiq3/m3u/musiq3-aac.pls"
    ]
  },
  "s25702":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT MNM",
      "element":"station",
      "email":"info@mnm.be",
      "genre_id":"g61",
      "genre_name":"Top 40/Pop",
      "guide_id":"s25702",
      "language":"Dutch",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtmnm.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtmnm.png",
      "mailing_address":"Auguste Reyerslaan 52\r\n1043 Brussel",
      "name":"MNM",
      "phone":"070 244 444",
      "slogan":"Music and More",
      "twitter_id":"MNMbe",
      "url":"http://www.mnm.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/mnm-high.mp3",
      "http://icecast.vrtcdn.be/mnm.aac"
    ]
  },
  "s25704":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT Radio 1",
      "element":"station",
      "email":"info@radio1.be",
      "genre_id":"g2",
      "genre_name":"Talk",
      "guide_id":"s25704",
      "language":"Dutch",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtradio1.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtradio1.png",
      "name":"Radio 1",
      "slogan":"Meteen Mee",
      "twitter_id":"radio1be",
      "url":"http://www.radio1.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/radio1-high.mp3",
      "http://icecast.vrtcdn.be/radio1.aac"
    ]
  },
  "s25705":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT R2 Antwerp",
      "element":"station",
      "genre_id":"g61",
      "genre_name":"Top 40/Pop",
      "guide_id":"s25705",
      "language":"Dutch",
      "location":"Antwerp",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtradio2.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtradio2.png",
      "mailing_address":"Auguste Reyerslaan 52\r\n1043 Brussel",
      "name":"Radio 2 Antwerpen",
      "phone":"02 - 741 42 53",
      "slogan":"Altijd Dicht Bij Jou",
      "url":"http://www.radio2.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/ra2ant-high.mp3",
      "http://icecast.vrtcdn.be/ra2ant.aac"
    ]
  },
  "s25706":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT R2 Limburg",
      "element":"station",
      "genre_id":"g3",
      "genre_name":"Adult Contemporary",
      "guide_id":"s25706",
      "language":"Dutch",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtradio2.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtradio2.png",
      "mailing_address":"Auguste Reyerslaan 52\r\n1043 Brussel",
      "name":"Radio 2 Limburg",
      "phone":"02 - 741 42 53",
      "slogan":"Altijd Dicht Bij Jou",
      "url":"http://www.radio2.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/ra2lim-high.mp3",
      "http://icecast.vrtcdn.be/ra2lim.aac"
    ]
  },
  "s25707":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT R2 OV",
      "element":"station",
      "email":"info@radio2.be",
      "genre_id":"g61",
      "genre_name":"Top 40/Pop",
      "guide_id":"s25707",
      "language":"Dutch",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtradio2.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtradio2.png",
      "mailing_address":"Auguste Reyerslaan 52\r\n1043 Brussel",
      "name":"Radio 2 Oost Vlaanderen",
      "phone":"02 - 741 42 53",
      "slogan":"Altijd Dicht Bij Jou",
      "url":"http://www.radio2.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/ra2ovl-high.mp3",
      "http://icecast.vrtcdn.be/ra2ovl.aac"
    ]
  },
  "s25708":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT R2 VB",
      "description":"altijd dicht bij jou",
      "element":"station",
      "genre_id":"g3",
      "genre_name":"Adult Contemporary",
      "guide_id":"s25708",
      "language":"Dutch",
      "location":"Brussels",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtradio2.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtradio2.png",
      "mailing_address":"VRT\r\nAuguste Reyerslaan 52\r\n1043 Brussel",
      "name":"Radio 2 Vlaams Brabant",
      "phone":"02 741.31.11",
      "url":"http://www.radio2.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/ra2vlb-mid.mp3",
      "http://icecast.vrtcdn.be/ra2vlb.aac"
    ]
  },
  "s25709":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT R2 WV",
      "element":"station",
      "email":"info@radio2.be",
      "genre_id":"g3",
      "genre_name":"Adult Contemporary",
      "guide_id":"s25709",
      "language":"Dutch",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtradio2.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtradio2.png",
      "mailing_address":"Auguste Reyerslaan 52\r\n1043 Brussel",
      "name":"Radio 2 West Vlaanderen",
      "phone":"02 - 741 42 53",
      "slogan":"Altijd Dicht Bij Jou",
      "url":"http://www.radio2.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/ra2wvl-high.mp3",
      "http://icecast.vrtcdn.be/ra2wvl.aac"
    ]
  },
  "s25710":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT Studio Brussel",
      "element":"station",
      "email":"info@studiobrussel.be",
      "genre_id":"g141",
      "genre_name":"Euro Hits",
      "guide_id":"s25710",
      "language":"Dutch",
      "location":"Brussels",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtstubru.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtstubru.png",
      "mailing_address":"Auguste Reyerslaan 52",
      "name":"Studio Brussel",
      "phone":"34552564",
      "slogan":"Life is Music",
      "twitter_id":"stubru",
      "url":"http://www.stubru.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/stubru-high.mp3",
      "http://icecast.vrtcdn.be/stubru.aac"
    ]
  },
  "s44244":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF Pure FM",
      "description":"Pure FM est la chaÃ®ne tendance de la RTBF, celle oÃ¹ on n'attend pas qu'un groupe ait rempli les stades pour l'Ã©couter et le partager...",
      "element":"station",
      "frequency":"88.8",
      "genre_name":"Top 40/Pop",
      "guide_id":"s44244",
      "language":"French",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-purefm.png",
      "name":"RTBF Pure FM",
      "slogan":"Good Music Makes Good People",
      "twitter_id":"purefmlaradio",
      "url":"http://www.purefm.be/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/purefm/m3u/pure-aac.pls"
    ]
  },
  "s44245":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF VivaCitÃ© Luxembourg",
      "description":"VivaCitÃ© est le chaÃ®ne de proximitÃ© de la RTBF, fiÃ¨re de ses attaches rÃ©gionales, elle est aussi la chaÃ®ne du sport.",
      "element":"station",
      "email":"vivacite@rtbf.be",
      "frequency":"91.5",
      "guide_id":"s44245",
      "language":"French",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-vivacite.png",
      "name":"RTBF VivaCitÃ© Luxembourg",
      "slogan":"En Toute Complicite",
      "url":"http://www.vivacite.be/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/vivacite/m3u/vivalux-aac.pls"
    ]
  },
  "s44491":{
    "country":"UK",
    "describe":{
      "band":"DAB",
      "call_sign":"BBC 6Music",
      "element":"station",
      "frequency":"225.648",
      "guide_id":"s44491",
      "location":"UK",
      "logo":"https://basalte.s3-eu-west-1.amazonaws.com/img/bbc_6music_colour.svg",
      "name":"BBC Radio 6 Music",
      "slogan":"Playing what we like",
      "twitter_id":"onradio6now",
      "url":"http://www.bbc.co.uk/6music/"
    },
    "streams":[
      "http://open.live.bbc.co.uk/mediaselector/5/select/version/2.0/mediaset/http-icy-mp3-a/vpid/bbc_6music/format/pls.pls"
    ]
  },
  "s50459":{
    "country":"UK",
    "describe":{
      "band":"DAB",
      "call_sign":"BBC R5SX",
      "element":"station",
      "frequency":"225.648",
      "guide_id":"s50459",
      "location":"UK",
      "logo":"https://basalte.s3-eu-west-1.amazonaws.com/img/bbc_radio_five_live_sports_extra_colour.svg",
      "name":"BBC Radio 5 live sports extra",
      "slogan":"Live news - live sport",
      "url":"http://www.bbc.co.uk/5livesportsextra/"
    },
    "streams":[
      "http://open.live.bbc.co.uk/mediaselector/5/select/version/2.0/mediaset/http-icy-mp3-a/vpid/bbc_radio_five_live_sports_extra/format/pls.pls"
    ]
  },
  "s55196":{
    "country":"BE",
    "describe":{
      "band":"DAB",
      "call_sign":"VRT Klara Continuo",
      "element":"station",
      "genre_id":"g5",
      "genre_name":"Classical",
      "guide_id":"s55196",
      "language":"Dutch",
      "location":"Belgium",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtklaracontinuo.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtklaracontinuo.png",
      "mailing_address":"        Auguste Reyerslaan 52         1043 Brussels, Belgium\r\n",
      "name":"Klara Continuo",
      "url":"http://www.klara.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/klaracontinuo-high.mp3",
      "http://icecast.vrtcdn.be/klaracontinuo.aac"
    ]
  },
  "s59472":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF VivaCitÃ© Charleroi",
      "description":"VivaCitÃ© est le chaÃ®ne de proximitÃ© de la RTBF, fiÃ¨re de ses attaches rÃ©gionales, elle est aussi la chaÃ®ne du sport.",
      "element":"station",
      "email":"vivacite@rtbf.be",
      "frequency":"95.4",
      "guide_id":"s59472",
      "language":"French",
      "location":"Chimay",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-vivacite.png",
      "name":"RTBF VivaCitÃ© Charleroi",
      "slogan":"En Toute Complicite",
      "url":"http://www.vivacite.be/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/vivacite/m3u/vivacha-aac.pls"
    ]
  },
  "s59475":{
    "country":"BE",
    "describe":{
      "band":"FM",
      "call_sign":"RTBF VivaCitÃ© Brabant wallon / Namur",
      "description":"VivaCitÃ© est le chaÃ®ne de proximitÃ© de la RTBF, fiÃ¨re de ses attaches rÃ©gionales, elle est aussi la chaÃ®ne du sport.",
      "element":"station",
      "frequency":"97.3",
      "guide_id":"s59475",
      "language":"French",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/rtbf-vivacite.png",
      "name":"RTBF VivaCitÃ© Brabant wallon / Namur",
      "slogan":"En Toute Complicite",
      "url":"http://www.vivacite.be/"
    },
    "streams":[
      "https://www.static.rtbf.be/radio/vivacite/m3u/vivanamurbw-aac.pls"
    ]
  },
  "s6839":{
    "country":"UK",
    "describe":{
      "band":"DAB",
      "call_sign":"BBC R4Ex",
      "element":"station",
      "frequency":"225.648",
      "guide_id":"s6839",
      "location":"UK",
      "logo":"https://basalte.s3-eu-west-1.amazonaws.com/img/bbc_radio_four_extra_colour.svg",
      "name":"BBC Radio 4 Extra",
      "slogan":"Comedy and drama entertainment.",
      "twitter_id":"bbcradio4extra",
      "url":"http://www.bbc.co.uk/radio4extra/"
    },
    "streams":[
      "http://open.live.bbc.co.uk/mediaselector/5/select/mediaset/http-icy-mp3-a/vpid/bbc_radio_four_extra/format/pls.pls"
    ]
  },
  "s69137":{
    "country":"BE",
    "describe":{
      "band":"DAB",
      "call_sign":"VRT MNM Hits",
      "element":"station",
      "genre_id":"g61",
      "genre_name":"Top 40/Pop",
      "guide_id":"s69137",
      "language":"Dutch",
      "location":"Belgium",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtmnm.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtmnm.png",
      "mailing_address":"Auguste Reyerslaan 52 1043 Brussel\r\n",
      "name":"MNM Hits",
      "phone":"02 741.31.11",
      "twitter_id":"mnmbe",
      "url":"http://www.mnm.be/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/mnm_hits-high.mp3",
      "http://icecast.vrtcdn.be/mnm_hits.aac"
    ]
  },
  "s69286":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT Radio Klara",
      "element":"station",
      "email":"info@klara.be",
      "genre_id":"g5",
      "genre_name":"Classical",
      "guide_id":"s69286",
      "language":"Dutch",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtklara.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtklara.png",
      "mailing_address":"Klara, Vlaamse Radio- en Televisieomroep, Kamer 2B29, Auguste Reyerslaan 52, 1043 Brussel",
      "name":"Klara",
      "phone":"02/741 3730",
      "url":"http://radio.klara.be/radio/"
    },
    "streams":[
      "http://icecast.vrtcdn.be/klara-high.mp3",
      "http://icecast.vrtcdn.be/klara.aac"
    ]
  },
  "s97407":{
    "country":"BE",
    "describe":{
      "call_sign":"VRT Ketnet Hits",
      "element":"station",
      "genre_id":"g76",
      "genre_name":"Children's Music",
      "guide_id":"s97407",
      "language":"Dutch",
      "location":"Belgium",
      "logo":"https://s3-eu-west-1.amazonaws.com/basalte/img/vrtketnet.svg",
      "logo_png":"https://s3-eu-west-1.amazonaws.com/basalte/img/png/vrtketnet.png",
      "mailing_address":"Kamer 4B1\r\n1043 Brussel",
      "name":"Ketnet Hits",
      "twitter_id":"Ketnet"
    },
    "streams":[
      "http://icecast.vrtcdn.be/ketnetradio-high.mp3",
      "http://icecast.vrtcdn.be/ketnetradio.aac"
    ]
  },
  "version":"2.0.1"
}
