'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSceneStateHelper', [
    '$uiRouterGlobals',
    'STATES',
    'SCENE_STATES',
    'BAS_STATE',
    'CurrentRoom',
    BasSceneStateHelper
  ])

/**
 * @constructor
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {SCENE_STATES} SCENE_STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {CurrentRoom} CurrentRoom
 */
function BasSceneStateHelper (
  $uiRouterGlobals,
  STATES,
  SCENE_STATES,
  BAS_STATE,
  CurrentRoom
) {
  this.getPrefix = getPrefix
  this.getHelper = getHelper
  this.getSceneState = getSceneState
  this.isExact = isExact
  this.isCurrent = isCurrent
  this.exitScenes = exitScenes

  /**
   * @param {string} [stateName] Default value is current state name
   * @returns {string}
   */
  function getPrefix (stateName) {

    var _stateName, idx, _statePrefix

    _stateName = BasUtil.isNEString(stateName)
      ? stateName
      : $uiRouterGlobals.current.name

    if (BasUtil.isNEString(_stateName)) {

      idx = _stateName.indexOf(SCENE_STATES.SCENES)

      if (idx !== -1) {

        _statePrefix = _stateName.substring(0, idx)

        if (_statePrefix) return _statePrefix
      }
    }

    return ''
  }

  /**
   * @param {string} [statePrefix]
   * @returns {Object}
   */
  function getHelper (statePrefix) {

    var _statePrefix

    _statePrefix = BasUtil.isNEString(statePrefix)
      ? statePrefix
      : getPrefix()

    return {
      get: _getSceneState.bind(null, _statePrefix),
      isExact: _isExact.bind(null, _statePrefix),
      isCurrent: isCurrent.bind(null, _statePrefix)
    }
  }

  /**
   * @private
   * @param {string} statePrefix
   * @param {string} sceneState
   * @returns {string}
   */
  function _getSceneState (statePrefix, sceneState) {

    return statePrefix + sceneState
  }

  /**
   * @param {string} sceneState
   * @param {string} [statePrefix]
   * @returns {string}
   */
  function getSceneState (sceneState, statePrefix) {

    var _sceneState, _statePrefix

    if (BasUtil.isNEString(sceneState) &&
      BasUtil.isNEString(statePrefix)) {

      _sceneState = sceneState
      _statePrefix = statePrefix

    } else if (BasUtil.isNEString(sceneState) &&
      typeof statePrefix === 'undefined') {

      _sceneState = sceneState
      _statePrefix = getPrefix()

    } else {

      return ''
    }

    return _getSceneState(_statePrefix, _sceneState)
  }

  /**
   * @param {string} statePrefix
   * @param {string} sceneState
   * @returns {boolean}
   */
  function _isExact (statePrefix, sceneState) {

    var _currentStateName, _stateName

    _currentStateName = $uiRouterGlobals.current.name

    if (_currentStateName) {

      _stateName = getSceneState(sceneState, statePrefix)

      return _currentStateName === _stateName
    }

    return false
  }

  /**
   * @param {string} sceneState
   * @returns {boolean}
   */
  function isExact (sceneState) {

    var _statePrefix

    _statePrefix = getPrefix()

    return _isExact(_statePrefix, sceneState)
  }

  /**
   * @param {string} statePrefix
   * @param {string} sceneState
   * @returns {boolean}
   */
  function isCurrent (statePrefix, sceneState) {

    var _currentStateName, _stateName

    _currentStateName = $uiRouterGlobals.current.name

    if (_currentStateName) {

      _stateName = getSceneState(sceneState, statePrefix)

      if (_stateName) {

        return _currentStateName.indexOf(_stateName) === 0
      }
    }

    return false
  }

  /**
   * @param {string} [statePrefix]
   * @returns {Object}
   */
  function exitScenes (statePrefix) {

    var _statePrefix

    _statePrefix = BasUtil.isNEString(statePrefix)
      ? statePrefix
      : getPrefix()

    if (_statePrefix.indexOf(STATES.ROOM) === 0) {

      // Room scenes context

      return CurrentRoom.go(STATES.ROOM)

    } else if (_statePrefix.indexOf(STATES.MAIN) === 0) {

      // Home scenes context

      return CurrentRoom.go(
        STATES.HOME,
        {
          view: BAS_STATE.S_HOME_VIEW_OPTIONS
        }
      )
    }
  }
}
