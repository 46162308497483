'use strict'

angular
  .module('basalteApp')
  .directive('basLiveNotifications', basLiveNotificationsDirective)

function basLiveNotificationsDirective () {

  return {
    restrict: 'AE',
    template:
      '<div class="bas-live"' +
      ' ng-class="basLiveNotifications.basLiveAccountState.css">' +
      '  <div class="' +
      'bbs bbs-item bbs-centered' +
      ' bbs-text bbs-text-greyed-out bbs-active-select' +
      ' bas-live-no-notification-permission' +
      '"' +
      ' bas-click="basLiveNotifications.openSettings()">' +
      '    <p class="bbs-name"' +
      ' data-translate="notification_permission_missing"></p>' +
      '    <p class="bbs-text-link"' +
      ' data-translate="open_settings"></p>' +
      '  </div>' +
      '  <div class="' +
      'bbs bbs-item bbs-centered' +
      ' bbs-flex bbs-active-select bas-live-notification-toggle' +
      '"' +
      ' ng-class="{' +
      '   \'bbs-disabled\':' +
      '      !basLiveNotifications.basLiveAccountState' +
      '      .hasNotificationPermission' +
      ' }"' +
      ' bas-click="basLiveNotifications.toggle()">' +
      '    <div class="bbs-name ellipsis-overflow"' +
      ' data-translate="notifications">' +
      '    </div>' +
      '    <bas-toggle class="bbs-toggle bas-toggle-dark"' +
      ' data-bas-toggle="basLiveNotifications.toggle()"' +
      ' data-bas-toggled="basLiveNotifications.enabled"' +
      ' data-bas-disabled="' +
      '!basLiveNotifications.basLiveAccountState.hasNotificationPermission' +
      '"></bas-toggle>' +
      '  </div>' +
      '</div>',
    controller: [
      'BasNativeSettings',
      'BasPreferences',
      'BasLiveAccount',
      controller
    ],
    controllerAs: 'basLiveNotifications'
  }

  /**
   * @param {BasNativeSettings} BasNativeSettings
   * @param {BasPreferences} BasPreferences
   * @param {BasLiveAccount} BasLiveAccount
   */
  function controller (
    BasNativeSettings,
    BasPreferences,
    BasLiveAccount
  ) {

    var basLiveNotifications = this

    basLiveNotifications.basLiveAccountState = BasLiveAccount.get()

    basLiveNotifications.enabled = BasPreferences.getEnableNotifications()

    basLiveNotifications.toggle = toggle
    basLiveNotifications.openSettings = openSettings

    function toggle () {

      basLiveNotifications.enabled = !basLiveNotifications.enabled
      BasPreferences.setEnableNotifications(
        basLiveNotifications.enabled
      )
    }

    function openSettings () {

      BasNativeSettings.openNotificationSettings()
    }
  }
}
