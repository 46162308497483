'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasCoreClientDevice', [
    '$rootScope',
    'BAS_API',
    'BAS_CORE_CLIENT',
    basCoreClientDeviceFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 * @returns BasCoreClientDevice
 */
function basCoreClientDeviceFactory (
  $rootScope,
  BAS_API,
  BAS_CORE_CLIENT
) {
  /**
   * @constructor
   * @param {CoreClientDevice} device
   */
  function BasCoreClientDevice (device) {

    /**
     * @type {?CoreClientDevice}
     */
    this.device = null

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {number}
     */
    this.mac = 0

    /**
     * @type {string}
     */
    this.macStr = ''

    /**
     * @type {string}
     */
    this.ip = ''

    /**
     * @type {string}
     */
    this.discoveryHttpURI = ''

    /**
     * @type {string}
     */
    this.homeHttpURI = ''

    /**
     * @type {boolean}
     */
    this.canMuteAreaOnCall = false

    /**
     * @type {boolean}
     */
    this.muteAreaOnCall = false

    /**
     * @type {boolean}
     */
    this.receiveIntercom = false

    this._deviceListeners = []

    this._handleMuteAreaOnCallChange = this._onMuteAreaOnCallChange.bind(this)
    this._handleAddressesChange = this._onAddressesChange.bind(this)

    if (device) this.parseCoreClientDevice(device)
  }

  /**
   * @returns {?TCoreClientDoorPhoneConfig}
   */
  BasCoreClientDevice.prototype.getDoorPhoneConfig = function () {

    return this.device ? this.device.coreClientDoorPhoneConfig : null
  }

  BasCoreClientDevice.prototype._onMuteAreaOnCallChange = function () {

    if (this.device) {

      this.muteAreaOnCall = this.device.muteAreaOnCallState
    }

    $rootScope.$applyAsync()
  }

  BasCoreClientDevice.prototype._onAddressesChange = function () {

    if (this.device) {

      this._convertAddresses(this.device.addresses)
    }
  }

  BasCoreClientDevice.prototype._convertAddresses = function (addresses) {

    var length, i, ip, finalIp

    ip = ''

    if (addresses &&
      BasUtil.isNEArray(addresses)) {

      length = addresses.length
      for (i = 0; i < length; i++) {

        ip = addresses[i]

        if (BasUtil.isValidIp(ip)) {
          finalIp = ip
          break
        }
      }
    }

    this.ip = finalIp

    this.discoveryHttpURI = this._convertToCoreClientURI(
      finalIp,
      BAS_API.CoreClientDevice.HTTP_PORT_DISCOVERY
    )

    this.homeHttpURI = this._convertToCoreClientURI(
      finalIp,
      BAS_API.CoreClientDevice.HTTP_PORT_HOME
    )

    $rootScope.$emit(BAS_CORE_CLIENT.EVT_CORE_CLIENT_URI)
  }

  BasCoreClientDevice.prototype._convertToCoreClientURI = function (ip, port) {

    return (BasUtil.isNEString(ip) && BasUtil.isPNumber(port))
      ? 'http://' + ip + ':' + port
      : ''
  }

  BasCoreClientDevice.prototype.parseCoreClientDevice = function (device) {

    if (device instanceof BAS_API.CoreClientDevice) {

      this.device = device
      this.uuid = this.device.uuid

      this.setDeviceListeners()
      this.syncDevice()
    }
  }

  BasCoreClientDevice.prototype.updateMuteAreaOnCallState = function (value) {

    this.muteAreaOnCall = value
  }

  BasCoreClientDevice.prototype.updateReceiveIntercom = function (value) {

    this.receiveIntercom = value
  }

  /**
   * @param {CoreClientDevice} [device]
   */
  BasCoreClientDevice.prototype.syncDevice = function (device) {

    var coreClientDevice = device || this.device

    this.resetCapabilities()

    if (coreClientDevice) {

      this.name = coreClientDevice.name
      this.mac = coreClientDevice.mac
      this.macStr = coreClientDevice.macStr

      this._convertAddresses(coreClientDevice.addresses)

      if (coreClientDevice.allowsRead(
        BAS_API.CoreClientDevice.C_MUTE_AREA_ON_CALL
      )) {

        this.updateMuteAreaOnCallState(
          coreClientDevice.muteAreaOnCallState
        )

        if (coreClientDevice.allowsWrite(
          BAS_API.CoreClientDevice.C_MUTE_AREA_ON_CALL
        )) {

          this.canMuteAreaOnCall = true
        }
      } else {

        this.updateMuteAreaOnCallState(
          false
        )
      }

      this.updateReceiveIntercom(
        coreClientDevice.allowsExecute(
          BAS_API.CoreClientDevice.C_INTERCOM
        )
      )
    }
  }

  /**
   * Set call state of core client to idle
   * and UUID of calling door phone.
   *
   * @param {string} caller
   */
  BasCoreClientDevice.prototype.setCallStateToIdle = function (caller) {

    if (this.device) this.device.setCallStateToIdle(caller)
  }

  /**
   * Set call state of core client to incoming
   * and UUID of calling door phone.
   *
   * @param {string} caller
   */
  BasCoreClientDevice.prototype.setCallStateToIncoming = function (caller) {

    if (this.device) this.device.setCallStateToIncoming(caller)
  }

  /**
   * Set call state of core client to ongoing
   * and UUID of calling door phone.
   *
   * @param {string} caller
   */
  BasCoreClientDevice.prototype.setCallStateToOngoing = function (caller) {

    if (this.device) this.device.setCallStateToOngoing(caller)
  }

  /**
   * Toggle muteAreaOnCall of core client
   */
  BasCoreClientDevice.prototype.toggleMuteAreaOnCall = function () {

    if (this.device && this.canMuteAreaOnCall) {

      this.muteAreaOnCall = !this.muteAreaOnCall

      this.device.setMuteAreaOnCall(this.muteAreaOnCall)
    }
  }

  BasCoreClientDevice.prototype.resetCapabilities = function resetCss () {

    this.canMuteAreaOnCall = false
  }

  BasCoreClientDevice.prototype.setDeviceListeners = function () {

    this._clearDeviceListeners()

    if (this.device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.CoreClientDevice.EVT_MUTE_AREA_ON_CALL,
        this._handleMuteAreaOnCallChange
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.CoreClientDevice.EVT_CORE_CLIENT_ADDRESSES,
        this._handleAddressesChange
      ))
    }
  }

  /**
   * Set ip addresses
   *
   * @param {string[]} addresses
   */
  BasCoreClientDevice.prototype.setAddresses = function (addresses) {

    if (this.device) this.device.setAddresses(addresses)
  }

  /**
   * Clears the API device listeners
   *
   * @private
   */
  BasCoreClientDevice.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  BasCoreClientDevice.prototype.clear = function () {

    this._clearDeviceListeners()
    this.device = null
  }

  return BasCoreClientDevice
}
