'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSource', [
    '$rootScope',
    '$timeout',
    '$window',
    'BAS_IMAGE',
    'ICONS',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_SOURCE',
    'VIDEO_BUTTON',
    'BasAppDevice',
    'BasResource',
    'CurrentBasCore',
    'SourcesHelper',
    'BasSourceQueue',
    'BasSourceState',
    'BasSourcePlaylists',
    'BasSourceTuneIn',
    'BasSourceDeezer',
    'BasSourceTidal',
    'BasSourceSpotify',
    'BasSourceFavourites',
    'BasSourceDefaultRooms',
    'BasSourceKNXPresets',
    'BasSourceDatabase',
    'BasImageTrans',
    'BasBlur',
    'BasImage',
    'RoomsHelper',
    'CoverartHelper',
    'PlayUriHelper',
    'BasCommandQueue',
    'BasUtilities',
    'Logger',
    basSourceFactory
  ])

/**
 * @typedef {Object} TBasSourceParseOrDestroy
 * @property {?BasSource} basSource
 * @property {boolean} changed
 */

/**
 * @typedef {Object} TBasSourceUi
 * @property {string} songTitle
 * @property {string} songArtist
 * @property {string} songTitleArtist
 * @property {?Object} barpIcon
 */

/**
 * @typedef {Object} TBasStreamingServiceTokens
 * @property {string} spotify
 * @property {string} deezer
 * @property {string} tidal
 */

/**
 * @typedef {Object} TSetCoverArtThumbnailOptions
 * @property {boolean} [noBackground = false]
 * @property {boolean} [radioStyling = false]
 */

/**
 * @typedef {Object} TPlayUriOptions
 * @property {boolean} [showModalOnError]
 * @property {string} [contextUri]
 * @property {number} [contextOffset]
 */

/**
 * @param $rootScope
 * @param $timeout
 * @param $window
 * @param BAS_IMAGE
 * @param {ICONS} ICONS
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {VIDEO_BUTTON} VIDEO_BUTTON
 * @param {BasAppDevice} BasAppDevice
 * @param {BasResource} BasResource
 * @param {CurrentBasCore} CurrentBasCore
 * @param {SourcesHelper} SourcesHelper
 * @param BasSourceQueue
 * @param BasSourceState
 * @param BasSourcePlaylists
 * @param BasSourceTuneIn
 * @param BasSourceDeezer
 * @param BasSourceTidal
 * @param BasSourceSpotify
 * @param BasSourceFavourites
 * @param BasSourceDefaultRooms
 * @param BasSourceKNXPresets
 * @param BasSourceDatabase
 * @param BasImageTrans
 * @param {BasBlur} BasBlur
 * @param BasImage
 * @param {RoomsHelper} RoomsHelper
 * @param CoverartHelper
 * @param PlayUriHelper
 * @param {BasCommandQueue} BasCommandQueue
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns BasSource
 */
function basSourceFactory (
  $rootScope,
  $timeout,
  $window,
  BAS_IMAGE,
  ICONS,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_SOURCE,
  VIDEO_BUTTON,
  BasAppDevice,
  BasResource,
  CurrentBasCore,
  SourcesHelper,
  BasSourceQueue,
  BasSourceState,
  BasSourcePlaylists,
  BasSourceTuneIn,
  BasSourceDeezer,
  BasSourceTidal,
  BasSourceSpotify,
  BasSourceFavourites,
  BasSourceDefaultRooms,
  BasSourceKNXPresets,
  BasSourceDatabase,
  BasImageTrans,
  BasBlur,
  BasImage,
  RoomsHelper,
  CoverartHelper,
  PlayUriHelper,
  BasCommandQueue,
  BasUtilities,
  Logger
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasAppDeviceState}
   */
  var basAppDevice = BasAppDevice.get()

  var METHOD_DESTROY = 'destroy'

  var K_TUNEIN_GID = 'tunein_gid'
  var K_CONTENT_SRC = 'content_src'
  var V_DEEZER = 'deezer'
  var V_TIDAL = 'tidal'
  var K_LOGO = 'logo'

  var SRC_TIMEOUT = 2000

  var BLUR_RADIUS = 4

  var APPLY_ASYNC_DEBOUNCE = 20

  var biSvgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_COLOR_MUTED,
      BAS_IMAGE.C_SIZE_50
    ]
  }
  var biSvgBigOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_COLOR_MUTED,
      BAS_IMAGE.C_SIZE_70
    ]
  }
  var biSvgMediumOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_COLOR_MUTED,
      BAS_IMAGE.C_SIZE_60
    ]
  }
  var biSvgFooterOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_SIZE_50
    ]
  }
  var biSvgFooterOptsBig = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_HEIGHT_90
    ]
  }

  var biMusic = new BasImage(ICONS.musicFull, biSvgOpts)
  var biBluetooth = new BasImage(ICONS.bluetooth, biSvgOpts)
  var biNotification = new BasImage(ICONS.notification, biSvgOpts)
  var biDisc = new BasImage(ICONS.disc, biSvgBigOpts)
  var biOnOff = new BasImage(ICONS.onOff, biSvgOpts)
  var biMixed = new BasImage(ICONS.mixed, biSvgOpts)
  var biTV = new BasImage(ICONS.externalTv, biSvgBigOpts)

  var biPlayingEmpty = new BasImage(ICONS.playingEmpty, biSvgFooterOpts)
  var biPlayingPaused = new BasImage(ICONS.playingPaused, biSvgFooterOpts)
  var biPlayingPlaying = new BasImage(ICONS.playingOutline, biSvgFooterOpts)
  var biPlayingQPaused =
    new BasImage(ICONS.playingPausedQueue, biSvgFooterOpts)
  var biPlayingQPlaying =
    new BasImage(ICONS.playingQueueOutline, biSvgFooterOpts)
  var biVideo =
    new BasImage(ICONS.externalTv, biSvgFooterOptsBig)

  var biPlayingQEmpty = new BasImage(ICONS.empty_queue, biSvgMediumOpts)

  var coverArtOpts = {
    customClass: [
      BAS_IMAGE.C_BG_COVER
    ]
  }

  var biSvgBgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_SIZE_100
    ]
  }

  var biCoverBoxOpts = {
    customClass: [
      BAS_IMAGE.C_BG_COVER,
      BAS_IMAGE.C_BOX_SHADOW
    ]
  }

  var biCoverIconOpts = {
    customClass: [
      BAS_IMAGE.C_BG_COVER,
      BAS_IMAGE.C_DROP_SHADOW,
      BAS_IMAGE.C_SIZE_50
    ]
  }

  var biCoverIconOptsBig = {
    customClass: [
      BAS_IMAGE.C_BG_COVER,
      BAS_IMAGE.C_DROP_SHADOW,
      BAS_IMAGE.C_SIZE_80
    ]
  }

  var biBgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_COVER,
      BAS_IMAGE.C_BRIGHTNESS_30
    ]
  }

  var biBlurBgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_COVER,
      BAS_IMAGE.C_BLUR_BRIGHTNESS_30
    ]
  }

  var biCoverDefaultCoverArt = new BasImage(
    ICONS.defaultMusic,
    biSvgBgOpts
  )

  var videoButtonMap = {}

  videoButtonMap[VIDEO_BUTTON.BACK] = BAS_API.VideoSource.ACT_BACK
  videoButtonMap[VIDEO_BUTTON.UP] = BAS_API.VideoSource.ACT_UP
  videoButtonMap[VIDEO_BUTTON.DOWN] = BAS_API.VideoSource.ACT_DOWN
  videoButtonMap[VIDEO_BUTTON.LEFT] = BAS_API.VideoSource.ACT_LEFT
  videoButtonMap[VIDEO_BUTTON.RIGHT] = BAS_API.VideoSource.ACT_RIGHT
  videoButtonMap[VIDEO_BUTTON.MENU] = BAS_API.VideoSource.ACT_MENU
  videoButtonMap[VIDEO_BUTTON.ENTER] = BAS_API.VideoSource.ACT_ENTER
  videoButtonMap[VIDEO_BUTTON.CHANNEL_DOWN] =
    BAS_API.VideoSource.ACT_CHANNEL_DOWN
  videoButtonMap[VIDEO_BUTTON.CHANNEL_UP] = BAS_API.VideoSource.ACT_CHANNEL_UP
  videoButtonMap[VIDEO_BUTTON.FAST_FORWARD] =
    BAS_API.VideoSource.ACT_FAST_FORWARD
  videoButtonMap[VIDEO_BUTTON.REWIND] =
    BAS_API.VideoSource.ACT_REWIND
  videoButtonMap[VIDEO_BUTTON.SKIP_NEXT] = BAS_API.VideoSource.ACT_SKIP_NEXT
  videoButtonMap[VIDEO_BUTTON.SKIP_PREVIOUS] =
    BAS_API.VideoSource.ACT_SKIP_PREVIOUS
  videoButtonMap[VIDEO_BUTTON.PLAY_PAUSE] = BAS_API.VideoSource.ACT_PLAY_PAUSE

  /**
   * @constructor
   * @param {(Stream|number|AVAudio)} [source]
   */
  function BasSource (source) {

    /**
     * @type {number}
     */
    this.id = 0

    /**
     * @type {number}
     */
    this.cobraNetId = 0

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {number}
     */
    this.sequence = 0

    /**
     * @type {boolean}
     */
    this.paused = true

    /**
     * @type {string}
     */
    this.type = BAS_SOURCE.T_UNKNOWN_SOURCE

    /**
     * Can be a number in case of T_EXTERNAL
     *
     * @type {(string|number)}
     */
    this.subType = BAS_SOURCE.ST_UNKNOWN

    /**
     * @type {boolean}
     */
    this.available = false

    /**
     * @type {?(string[])}
     */
    this.listeningRooms = null

    /**
     * @type {boolean}
     */
    this.canSetDefaultRooms = true

    /**
     * @type {boolean}
     */
    this.canListDefaultRooms = true

    /**
     * @type {boolean}
     */
    this.canListQueue = true

    /**
     * @type {boolean}
     */
    this.canAddToQueue = true

    /**
     * @type {boolean}
     */
    this.canListFavourites = true

    /**
     * @type {boolean}
     */
    this.canAddFavourites = true

    /**
     * @type {boolean}
     */
    this.canRemoveFavourites = true

    /**
     * @type {boolean}
     */
    this.canToggleOn = true

    /**
     * @type {Object<string, boolean>}
     */
    this.canVideoButton = {}

    /**
     * @type {Object<string, boolean>}
     */
    this.videoButtonHoldable = {}

    /**
     * @type {string[]}
     */
    this.compatibleRooms = []

    /**
     * @type {boolean}
     */
    this.groupable = false

    /**
     * @type {boolean}
     */
    this.isPlayingSpotify = false

    /**
     * @type {boolean}
     */
    this.isPlayingAirplay = false

    /**
     * @type {boolean}
     */
    this.isPlayingTidal = false

    /**
     * @type {boolean}
     */
    this.isPlayingDeezer = false

    /**
     * @type {boolean}
     */
    this.isPlayingLocal = false

    /**
     * @type {boolean}
     */
    this.isPlayingTunein = false

    /**
     * @type {boolean}
     */
    this.isBluetooth = false

    /**
     * @type {Array<Object>}
     */
    this.numericButtons = []

    /**
     * @type {Array<Object>}
     */
    this.customButtons = []

    /**
     * @type {BasImageTrans}
     */
    this.bitIcon = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: biMusic
    })

    /**
     * @type {BasImageTrans}
     */
    this.bitMain = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: biCoverDefaultCoverArt
    })

    /**
     * @type {BasImageTrans}
     */
    this.bitBg = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      debounceMs: 1000,
      debounceMsNull: 200
    })

    /**
     * @type {BasImageTrans}
     */
    this.bitNowPlayingIcon = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: biPlayingEmpty
    })

    this.supportsLibrary = false

    /**
     * @private
     * @type {string}
     */
    this._currentBlurImageUrl = ''

    /**
     * @private
     * @type {string}
     */
    this._currentBlurredImageUrl = ''

    /**
     * @private
     */
    this._currentBlurCancellation = null

    /**
     * Used to track TuneIn GID
     * Allows to clear old TuneIn cover art before new one is loaded
     *
     * @private
     * @type {string}
     */
    this._tuneInGid = ''

    /**
     * Holds UI info
     *
     * @type {TBasSourceUi}
     */
    this.ui = {
      songTitle: '',
      songArtist: '',
      songTitleArtist: '',
      barpIcon: null
    }

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this._resetCss()

    /**
     * @type {BasSourceState}
     */
    this.state = new BasSourceState(this)

    /**
     * @type {?BasSourceTuneIn}
     */
    this.tunein = null

    /**
     * @type {?BasSourceDatabase}
     */
    this.database = null

    /**
     * @type {?BasSourcePlaylists}
     */
    this.playlists = null

    /**
     * @type {?BasSourceDeezer}
     */
    this.deezer = null

    /**
     * @type {?BasSourceTidal}
     */
    this.tidal = null

    /**
     * @type {?BasSourceDefaultRooms}
     */
    this.defaultRooms = null

    /**
     * @type {?BasSourceKNXPresets}
     */
    this.knxPresets = null

    /**
     * @type {?BasSourceFavourites}
     */
    this.favourites = null

    /**
     * @type {?BasSourceQueue}
     */
    this.queue = null

    /**
     * @type {?BasSourceSpotify}
     */
    this.spotify = null

    /**
     * @type {(
     * AudioSource|
     * VideoSource|
     * AVAudio|
     * Stream|
     * Player|
     * Barp|
     * External|
     * Bluetooth|
     * Notification)}
     */
    this.source = null

    /**
     * UI helper flag to indicate whether
     * this source is suitable to be joined by another room.
     * Links with "type"
     *
     * @type {boolean}
     */
    this.uiJoinable = false

    /**
     * @type {boolean}
     */
    this.pairing = false

    /**
     * @type {TBasStreamingServiceTokens}
     */
    this.streamingServiceTokens = {
      spotify: '',
      deezer: '',
      tidal: ''
    }

    /**
     * Flag that indicates that this source is an AudioSource from the new
     * Source API
     *
     * @type {boolean}
     */
    this.isAudioSource = false

    /**
     * Flag that indicates that this source is a VideoSource from the new
     * Source API
     *
     * @type {boolean}
     */
    this.isVideoSource = false

    /**
     * This acts as a counter for interested parties.
     * This data is used to create a lists of all events that are necessary
     *
     * An event collection with > 0
     * will make sure that the events in that collection are "activated"
     *
     * @private
     * @type {Object<string, number>}
     */
    this._eventCollections = {}

    // Initialize all event collections with count 0
    BasUtil.setProperties(
      this._eventCollections,
      BAS_SOURCE.EVT_COLLECTIONS,
      0
    )

    /**
     * @private
     * @type {Array}
     */
    this._sourceListeners = []

    /**
     * @private
     * @type {?Promise}
     */
    this._syncSourceInfoTimeout = null

    /**
     * @private
     * @type {?number}
     */
    this._applyAsyncTimeoutId = undefined

    this._handleReachable = this._onReachable.bind(this)
    this._handleName = this._onName.bind(this)
    this._handleCapabilities = this._onCapabilities.bind(this)
    this._handleAttributes = this._onAttributes.bind(this)
    this._handleAudioDefaultNameChanged =
      this._onAudioDefaultNameChanged.bind(this)
    this._handleAVFavouritesReset =
      this._onAVFavouritesReset.bind(this)
    this._handleAVQuickFavouritesReset =
      this._onAVQuickFavouritesReset.bind(this)
    this._handleAVFavouriteAdded =
      this._onAVFavouriteAdded.bind(this)
    this._handleAVFavouriteRemoved =
      this._onAVFavouriteRemoved.bind(this)
    this._handleAVFavouriteUpdated =
      this._onAVFavouriteUpdated.bind(this)
    this._handleAudioListeningRoomsChanged =
      this._onAudioListeningRoomsChanged.bind(this)
    this._handleAudioStatusChanged =
      this._onAudioStatusChanged.bind(this)

    this._handleStreamingServiceDetails =
      this._onStreamingServiceDetails.bind(this)
    this._handleStreamingServiceTokenChanged =
      this._onStreamingServiceTokenChanged.bind(this)
    this._handleStreamingServiceValueUpdated =
      this._onStreamingServiceValueUpdated.bind(this)
    this._handlePresetLinked =
      this._onPresetLinked.bind(this)
    this._handleAVAudioState = this._onAVAudioState.bind(this)

    this._handleConnected = this._onConnected.bind(this)
    this._handlePaused = this._onPaused.bind(this)
    this._handleClientName = this._onClientName.bind(this)
    this._handlePairing = this._onPairing.bind(this)
    this._handleCustomRadioUpdate = this._onCustomRadioUpdate.bind(this)
    this._handleCurrentSong = this._onCurrentSong.bind(this)
    this._handleDirtyChanged = this._onDirtyChanged.bind(this)
    this._handleNextSong = this._onNextSong.bind(this)
    this._handleRepeat = this._onRepeat.bind(this)
    this._handleRandom = this._onRandom.bind(this)
    this._handlePosition = this._onPosition.bind(this)
    this._handleDuration = this._onDuration.bind(this)
    this._handleCoverArt = this._onCoverArt.bind(this)
    this._handleDbContentChanged = this._onDbContentChanged.bind(this)
    this._handlePlaylistChanged = this._onPlaylistChanged.bind(this)
    this._handleFavouritesChanged = this._onFavouritesChanged.bind(this)
    this._handleQueueChanged = this._onQueueChanged.bind(this)
    this._handleQueueMoved = this._onQueueMoved.bind(this)
    this._handleQueueRemoved = this._onQueueRemoved.bind(this)
    this._handleQueueAdded = this._onQueueAdded.bind(this)
    this._handleDefaultRoomsChanged =
      this._onDefaultRoomsChanged.bind(this)
    this._handlePlayback = this._onPlayback.bind(this)
    this._handleDeezerLinked = this._onDeezerLinked.bind(this)
    this._handleDeezerLinkFinished = this._onDeezerLinkFinished.bind(this)
    this._handleDeezerLinkError = this._onDeezerLinkError.bind(this)
    this._handleTidalLinked = this._onTidalLinked.bind(this)
    this._handleSpotifyTokenChanged =
      this._onSpotifyTokenChanged.bind(this)
    this._handleSpotifyLinkChanged = this._onSpotifyLinkChanged.bind(this)
    this._handleSpotifyPresetsChanged =
      this._onSpotifyPresetsChanged.bind(this)
    this._handleClientChanged = this._onClientChanged.bind(this)
    this._handleKNXPresetsChanged = this._onKNXPresetsChanged.bind(this)

    this._thisSyncSourceInfo = this._syncSourceInfo.bind(this)

    this.parseSource(source)

    this._syncCompatibleRooms()
  }

  // region Static

  /**
   * Sorts based on sequence
   *
   * @param {BasSource} a
   * @param {BasSource} b
   * @returns {number}
   */
  BasSource.compare = function (a, b) {

    return a.sequence - b.sequence
  }

  /**
   * Checks if given source can be parsed by the BasSource.
   * Return new BasSource if source can't be parsed.
   * If BasSource can't parse the source, the BasSource will be destroyed.
   *
   * @param {?BasSource} basSource
   * @param {?(Stream|number|AVAudio)} source
   * @returns {TBasSourceParseOrDestroy}
   */
  BasSource.parseOrDestroy = function (
    basSource,
    source
  ) {
    var result

    /**
     * @type {TBasSourceParseOrDestroy}
     */
    result = {
      basSource: null,
      changed: false
    }

    if (basSource instanceof BasSource) {

      if (basSource.isSameSource(source)) {

        result.basSource = basSource
        result.changed = basSource.parseSource(source)

      } else {

        basSource.destroy()

        result.basSource = new BasSource(source)
        result.changed = true
      }

    } else {

      result.basSource = new BasSource(source)
      result.changed = true
    }

    return result
  }

  /**
   * @param {(
   * AudioSource|
   * Stream|
   * Player|
   * Barp|
   * External|
   * Bluetooth|
   * Notification|
   * number)} source
   * @returns {string}
   */
  BasSource.getType = function (source) {

    if (BasUtil.isObject(source)) {

      if (source instanceof BAS_API.AudioSource) {

        if (BasUtil.startsWith(
          source.type,
          BAS_API.AudioSource.T_ASANO
        )) {

          return BAS_SOURCE.T_ASANO

        } else if (
          BasUtil.startsWith(
            source.type,
            BAS_API.AudioSource.T_SONOS
          )
        ) {

          return BAS_SOURCE.T_SONOS
        }

      } else if (source instanceof BAS_API.VideoSource) {

        return BAS_SOURCE.T_VIDEO

      } else if (source instanceof BAS_API.Player) {

        return BAS_SOURCE.T_PLAYER

      } else if (source instanceof BAS_API.Barp) {

        return BAS_SOURCE.T_BARP

      } else if (source instanceof BAS_API.ExternalSource) {

        return BAS_SOURCE.T_EXTERNAL

      } else if (source instanceof BAS_API.BluetoothSource) {

        return BAS_SOURCE.T_BLUETOOTH

      } else if (source instanceof BAS_API.NotificationSource) {

        return BAS_SOURCE.T_NOTIFICATION

      } else if (source instanceof BAS_API.AVAudio) {

        return BAS_SOURCE.T_AUDIO_ALERT

      } else {

        // This situation should not occur
        return BAS_SOURCE.T_UNKNOWN_SOURCE
      }

    } else if (BasUtil.isVNumber(source)) {

      switch (source) {
        case BAS_SOURCE.V_EMPTY:

          return BAS_SOURCE.T_EMPTY

        case BAS_SOURCE.V_MIXED:

          return BAS_SOURCE.T_MIXED

        case BAS_SOURCE.V_AV_INPUT_UNKNOWN:

          return BAS_SOURCE.T_AV_INPUT_UNKNOWN

        case BAS_SOURCE.V_AV_INPUT_NONE:

          return BAS_SOURCE.T_AV_INPUT_NONE

        default:

          return source > 0
            ? BAS_SOURCE.T_UNKNOWN_ID
            : BAS_SOURCE.T_UNKNOWN_SOURCE
      }
    }

    return BAS_SOURCE.T_UNKNOWN_SOURCE
  }

  /**
   * @param {(
   * AudioSource|
   * Stream|
   * Player|
   * Barp|
   * External|
   * Bluetooth|
   * Notification|
   * number)} source
   * @param {string} [type]
   * @returns {string}
   */
  BasSource.getSubType = function (source, type) {

    var _type, song

    _type = BasUtil.isNEString(type)
      ? type
      : BasSource.getType(source)

    switch (_type) {
      case BAS_SOURCE.T_PLAYER:

        song = BasUtil.isObject(source)
          ? source.currentSong
          : null

        if (BasUtil.isObject(song)) {

          if (BasUtil.isNEString(song[K_TUNEIN_GID])) {

            return BAS_SOURCE.ST_TUNEIN
          }
          if (song[K_CONTENT_SRC] === V_DEEZER) {

            return BAS_SOURCE.ST_DEEZER
          }

          if (song[K_CONTENT_SRC] === V_TIDAL) {

            return BAS_SOURCE.ST_TIDAL
          }

          return BAS_SOURCE.ST_LOCAL
        }

        return BAS_SOURCE.ST_UNKNOWN

      case BAS_SOURCE.T_BARP:

        if (BasUtil.isObject(source)) {

          if (source.type === BAS_SOURCE.ST_AAP) {

            return BAS_SOURCE.ST_AAP
          }

          if (source.type === BAS_SOURCE.ST_SPOTIFY) {

            return BAS_SOURCE.ST_SPOTIFY
          }

          return BAS_SOURCE.ST_UNKNOWN
        }

        return BAS_SOURCE.ST_UNKNOWN

      case BAS_SOURCE.T_EXTERNAL:

        return BasUtil.isObject(source)
          ? source.type
          : BAS_API.ExternalSource.TYPE_GENERIC

      case BAS_SOURCE.T_SONOS:
        return source.subType

      case BAS_SOURCE.T_ASANO:
        return source.subType
    }

    return BAS_SOURCE.ST_UNKNOWN
  }

  /**
   * Get the corresponding SVG icon for external source type.
   *
   * @param {number} type External source type
   * @returns {*} icon Angular TrustedValueHolderType
   */
  BasSource._getSvgForExternalSourceType = function (type) {

    switch (type) {
      case BAS_API.ExternalSource.TYPE_HIFI:
        return ICONS.externalHifi
      case BAS_API.ExternalSource.TYPE_TV:
        return ICONS.externalTv
      case BAS_API.ExternalSource.TYPE_GENERIC:
      default:
        return ICONS.disc
    }
  }

  /**
   * Get the corresponding SVG icon for an audioSource subtype.
   *
   * @param {string} subtype AudioSource subtype
   * @returns {?*} icon Angular TrustedValueHolderType
   */
  BasSource._getSvgForAudioSourceSubType = function (subtype) {

    switch (subtype) {
      case BAS_SOURCE.ST_BLUETOOTH:
        return ICONS.bluetooth
      case BAS_SOURCE.ST_EXTERNAL:
        return ICONS.disc
      case BAS_SOURCE.ST_RECORD:
        return ICONS.externalHifi
      case BAS_SOURCE.ST_TV:
        return ICONS.externalTv
      default:
        return null
    }
  }

  // endregion

  // region Instance methods

  /**
   * Get tag with some info, useful for prints
   *
   * @private
   * @returns {string}
   */
  BasSource.prototype._getTag = function () {

    var result = 'BasSource (' + this.id

    switch (this.type) {
      case BAS_SOURCE.T_SONOS:
        result += ', audio, sonos'
        break
      case BAS_SOURCE.T_ASANO:
        result += ', audio, asano'
        break
      case BAS_SOURCE.T_PLAYER:
        result += ', player'
        break
      case BAS_SOURCE.T_BARP:
        result += ', '
        switch (this.subType) {
          case BAS_SOURCE.ST_AAP:
            result += '(AAP) '
            break
          case BAS_SOURCE.ST_SPOTIFY:
            result += '(Spotify) '
            break
        }
        result += ' barp'
        break
      case BAS_SOURCE.T_EXTERNAL:
        result += ', external'
        break
      case BAS_SOURCE.T_BLUETOOTH:
        result += ', Bluetooth'
        break
      case BAS_SOURCE.T_NOTIFICATION:
        result += ', Notification'
        break
      case BAS_SOURCE.T_AUDIO_ALERT:
        result += ', Audio Alert'
        break
      case BAS_SOURCE.T_AV_INPUT_NONE:
        result += ', AV input none'
        break
      case BAS_SOURCE.T_AV_INPUT_UNKNOWN:
        result += ', AV input unknown'
        break
      case BAS_SOURCE.T_MIXED:
        result += ', Mixed'
        break
      case BAS_SOURCE.T_EMPTY:
        result += ', Empty'
        break
      case BAS_SOURCE.T_UNKNOWN_SOURCE:
        result += ', Unknown source'
        break
      case BAS_SOURCE.T_UNKNOWN_ID:
        result += ', Unknown ID'
        break
    }

    result += ')'
    return result
  }

  /**
   * CobraNet ID for Asano sources, UUID for Sources from new Source API
   *
   * @returns {(string|number)}
   */
  BasSource.prototype.getId = function () {
    return (this.isAudioSource || this.isVideoSource) ? this.uuid : this.id
  }

  /**
   * Checks this instance against other object
   * to see if they are the same based on:
   * id, uuid, type and subtype.
   *
   * @param {?Object} other
   * @returns {boolean}
   */
  BasSource.prototype.isSame = function (other) {
    return (
      BasUtil.isObject(other) &&
      this.id === other.id &&
      this.uuid === other.uuid &&
      this.type === other.type &&
      this.subType === other.subType
    )
  }

  /**
   * Checks if the source matches the internal source based on uuid and type.
   *
   * @param {(
   * AudioSource |
   * Stream |
   * Player |
   * Barp |
   * External |
   * Bluetooth |
   * Notification |
   * number
   * )} source
   * @returns {boolean}
   */
  BasSource.prototype.isSameSource = function (source) {

    var type, subType

    if (BasUtil.isObject(source)) {

      type = BasSource.getType(source)

      if (this.uuid === source.uuid &&
        this.type === type) {

        if (type === BAS_SOURCE.T_BARP) {

          subType = BasSource.getSubType(source, type)

          return this.subType === subType
        }

        return true
      }

    } else if (BasUtil.isVNumber(source)) {

      return this.id === source
    }

    return false
  }

  /**
   * @private
   * @returns {boolean}
   */
  BasSource.prototype._isDirtySource = function () {

    if (this.type === BAS_SOURCE.T_PLAYER) {

      if (!BasUtil.isObject(this.source)) return true

      return this.source.dirty === true
    }

    return false
  }

  /**
   * @returns {boolean}
   */
  BasSource.prototype.isEmpty = function () {
    return this.type === BAS_SOURCE.T_EMPTY
  }

  /**
   * @returns {boolean}
   */
  BasSource.prototype.isPlayerOrBarp = function () {
    return (
      this.isAudioSource ||
      this.type === BAS_SOURCE.T_PLAYER ||
      this.type === BAS_SOURCE.T_BARP
    )
  }

  BasSource.prototype.hasSourceDeezer = function () {

    return (
      BasUtil.isObject(this.source) &&
      BasUtil.isObject(this.source.deezer)
    )
  }

  BasSource.prototype.hasSourceQueue = function () {

    return (
      BasUtil.isObject(this.source) &&
      BasUtil.isObject(this.source.queue)
    )
  }

  BasSource.prototype.hasSourceDatabase = function () {

    return (
      BasUtil.isObject(this.source) &&
      BasUtil.isObject(this.source.database)
    )
  }

  /**
   * @param {number} id
   */
  BasSource.prototype.setCobraNetId = function (id) {

    if (BasUtil.isVNumber(id)) {

      this.cobraNetId = id
      this.id = id
      this._syncIds()
      this._syncName()
    }
  }

  /**
   * Parse a source, returns if something has changed
   *
   * @param {(
   * AudioSource|
   * VideoSource|
   * Stream|
   * Player|
   * Barp|
   * External|
   * Bluetooth|
   * Notification|
   * AVAudio|
   * number)} source
   * @returns {boolean}
   */
  BasSource.prototype.parseSource = function (
    source
  ) {
    var isOverwrite, streamingServices

    // Check if it is necessary to parse

    if (BasUtil.isObject(source)) {

      // Same source instance, no need to parse again
      if (source === this.source) return false

    } else if (BasUtil.isVNumber(source)) {

      if (source === BAS_SOURCE.V_EMPTY) {

        // Exception for Empty source, because default id is "0"
        if (this.type === BAS_SOURCE.T_EMPTY) return false

      } else {

        // Same source, no need to parse again
        if (source === this.id) return false
      }
    }

    this._clearSourceInfoTimer()

    isOverwrite = !!this.source

    this.clearSource()

    if (BasUtil.isObject(source)) this.source = source
    if (BasUtil.isVNumber(source)) this.id = source

    this.type = BasSource.getType(source)
    this.isAudioSource = source instanceof BAS_API.AudioSource
    this.isVideoSource = source instanceof BAS_API.VideoSource
    this.groupable = (
      this.type === BAS_SOURCE.T_SONOS ||
      this.type === BAS_SOURCE.T_ASANO
    )

    this._syncUiJoinable()
    this._syncIds()
    this._syncBitDefaults()
    this._syncSubType()
    this._syncCapabilities()
    this._syncVideoButtons()
    this._syncHoldableVideoButtons()

    switch (this.type) {
      case BAS_SOURCE.T_SONOS:

        this._clearAllComponents()

        if (!this.favourites) {
          this.favourites = new BasSourceFavourites(this)
        }

        break

      case BAS_SOURCE.T_ASANO:

        this._clearAllComponents()

        streamingServices = this.getStreamingServices()

        if (this.subType === BAS_SOURCE.ST_STREAM) {

          if (!this.favourites) {
            this.favourites = new BasSourceFavourites(this)
          }
          if (!this.defaultRooms) {
            this.defaultRooms = new BasSourceDefaultRooms(this)
          }
          if (!this.playlists) {
            this.playlists = new BasSourcePlaylists(this)
          }
          if (
            !this.tidal &&
            streamingServices.indexOf(BAS_API.AudioSource.A_SS_TIDAL) !== -1
          ) {
            this.tidal = new BasSourceTidal(this)
          }
          if (
            !this.deezer &&
            streamingServices.indexOf(BAS_API.AudioSource.A_SS_DEEZER) !== -1
          ) {
            this.deezer = new BasSourceDeezer(this)
          }
          if (
            !this.spotify &&
            streamingServices.indexOf(BAS_API.AudioSource.A_SS_SPOTIFY) !== -1
          ) {
            this.spotify = new BasSourceSpotify(this)
          }
          if (!this.tunein) {
            this.tunein = new BasSourceTuneIn()
          }
          if (!this.queue) {
            this.queue = new BasSourceQueue(this)
          }
          if (!this.knxPresets) {
            this.knxPresets = new BasSourceKNXPresets(this)
          }
        }
        break

      case BAS_SOURCE.T_VIDEO:

        this._clearAllComponents()

        if (!this.favourites) {
          this.favourites = new BasSourceFavourites(this)
        }
        break

      case BAS_SOURCE.T_PLAYER:

        this._clearSpotifyComponents()

        if (!this.tunein) {
          this.tunein = new BasSourceTuneIn()
        }
        if (!this.database) {
          this.database = new BasSourceDatabase(this)
        }
        if (!this.playlists) {
          this.playlists = new BasSourcePlaylists(this)
        }
        if (!this.deezer) {
          this.deezer = new BasSourceDeezer(this)
        }
        if (!this.tidal) {
          this.tidal = new BasSourceTidal(this)
        }
        if (!this.defaultRooms) {
          this.defaultRooms = new BasSourceDefaultRooms(this)
        }
        if (!this.knxPresets) {
          this.knxPresets = new BasSourceKNXPresets(this)
        }
        if (!this.favourites) {
          this.favourites = new BasSourceFavourites(this)
        }
        if (!this.queue) {
          this.queue = new BasSourceQueue(this)
        }

        break
      case BAS_SOURCE.T_BARP:

        if (this.subType === BAS_SOURCE.ST_SPOTIFY) {

          this._clearPlayerComponents()

          if (!this.spotify) {
            this.spotify = new BasSourceSpotify(this)
          }

        } else {

          this._clearAllComponents()
        }

        break
      default:

        this._clearAllComponents()

        break
    }

    this._syncName()

    if (isOverwrite) {

      this._setSourceInfoTimer()

    } else {

      this._syncSourceInfo()
    }

    this._setSourceListeners()

    if (this.queue) {

      if (this.type === BAS_SOURCE.T_PLAYER) {

        // Legacy, needed to get state of player
        this.queue.syncQueue()

      } else {

        if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

          this.queue.syncQueue()
        }
      }

    }

    if (this.favourites) this.favourites.resume()

    if (
      (
        this.isAudioSource ||
        this.isVideoSource
      ) &&
      this.favourites &&
      this.active(BAS_SOURCE.COL_EVT_FAVOURITES)
    ) {

      // If favourites were destroyed and recreated, and the COL_EVT_FAVOURITES
      //  event is active, we should retrieve the favourites
      this.favourites.retrieve().catch(_ignoreButLog)
      this.favourites.retrieveQuickFavourites().catch(_ignoreButLog)
    }

    return true
  }

  /**
   * @private
   * @param {TBasEmitterOptions} [options]
   */
  BasSource.prototype._syncSourceInfo = function (options) {

    this._clearSourceInfoTimer()

    this._syncSongInfo()
    this._syncCoverArt()

    this._syncSourceProperties()
    this._syncNowPlayingIcon()
    this._syncAvailable()

    this.state.sync(options)
  }

  /**
   * @private
   */
  BasSource.prototype._setSourceInfoTimer = function () {

    this._clearSourceInfoTimer()

    this._syncSourceInfoTimeout = $timeout(
      this._thisSyncSourceInfo,
      SRC_TIMEOUT
    )
  }

  /**
   * @private
   */
  BasSource.prototype._syncUiJoinable = function () {

    this.uiJoinable =
      this.type !== BAS_SOURCE.T_EMPTY &&
      this.type !== BAS_SOURCE.T_MIXED &&
      this.type !== BAS_SOURCE.T_AUDIO_ALERT &&
      this.type !== BAS_SOURCE.T_SONOS
  }

  /**
   * Update the ID and UUID (and sequence for Players)
   *
   * @private
   */
  BasSource.prototype._syncIds = function () {

    var value, oldId

    oldId = this.id

    switch (this.type) {
      case BAS_SOURCE.T_SONOS:
      case BAS_SOURCE.T_ASANO:
      case BAS_SOURCE.T_VIDEO:

        this.id = 0
        this.uuid = ''

        if (BasUtil.isObject(this.source)) {

          value = this.source.uuid
          if (BasUtil.isNEString(value)) this.uuid = value

          value = this.source.sequence
          if (BasUtil.isNumber(value)) this.sequence = value
        }

        break
      case BAS_SOURCE.T_PLAYER:
      case BAS_SOURCE.T_BARP:
      case BAS_SOURCE.T_EXTERNAL:
      case BAS_SOURCE.T_BLUETOOTH:
      case BAS_SOURCE.T_NOTIFICATION:

        this.id = 0
        this.uuid = ''

        if (BasUtil.isObject(this.source)) {

          value = this.source.id
          if (BasUtil.isVNumber(value)) this.id = value

          value = this.source.uuid
          if (BasUtil.isNEString(value)) this.uuid = value
        }

        break
      case BAS_SOURCE.T_AUDIO_ALERT:

        this.id = 0
        this.uuid = ''

        if (BasUtil.isObject(this.source)) {

          value = this.source.roomUuid
          if (BasUtil.isNEString(value)) this.uuid = value
        }

        break
      case BAS_SOURCE.T_EMPTY:

        this.uuid = BAS_SOURCE.ID_EMPTY

        break
      case BAS_SOURCE.T_MIXED:

        this.uuid = BAS_SOURCE.ID_MIXED

        break
      case BAS_SOURCE.T_AV_INPUT_NONE:

        this.uuid = BAS_SOURCE.ID_AV_INPUT_NONE

        break
      case BAS_SOURCE.T_AV_INPUT_UNKNOWN:

        this.uuid = BAS_SOURCE.ID_AV_INPUT_UNKNOWN

        break
    }

    if (this.type === BAS_SOURCE.T_PLAYER) {

      this.sequence = 0

      if (BasUtil.isObject(this.source)) {

        value = this.source.sequence
        if (BasUtil.isVNumber(value)) this.sequence = value
      }
    }

    // Restore CobraNet ID if reset to default "0"
    if (oldId !== 0 && this.id === 0 && this.cobraNetId !== 0) {

      this.id = this.cobraNetId
    }
  }

  /**
   * Update name depending on type
   *
   * @private
   * @param {TBasEmitterOptions} [options]
   */
  BasSource.prototype._syncName = function (options) {

    var _emit, currentName, nameChanged, room

    _emit = true

    currentName = this.name
    nameChanged = false

    if (options) {

      if (BasUtil.isBool(options.emit)) _emit = options.emit
    }

    switch (this.type) {
      case BAS_SOURCE.T_EMPTY:

        this.name = ''

        break
      case BAS_SOURCE.T_UNKNOWN_ID:

        this.name = BasUtilities.translate('unknown_src') +
          ' (' + this.id + ')'

        break
      case BAS_SOURCE.T_UNKNOWN_SOURCE:
      case BAS_SOURCE.T_AV_INPUT_NONE:
      case BAS_SOURCE.T_AV_INPUT_UNKNOWN:

        this.name = BasUtilities.translate('unknown_src')

        break
      case BAS_SOURCE.T_MIXED:

        this.name = BasUtilities.translate('mixed_sources')

        break
      case BAS_SOURCE.T_SONOS:
      case BAS_SOURCE.T_ASANO:
      case BAS_SOURCE.T_PLAYER:
      case BAS_SOURCE.T_BARP:
      case BAS_SOURCE.T_EXTERNAL:
      case BAS_SOURCE.T_VIDEO:

        this.name = '-'

        if (this.source) {

          if (this.source.followRoomNameUuid) {

            room = RoomsHelper.getRoomForId(this.source.followRoomNameUuid)

            if (room) {

              room.basTitle.updateTranslation()
              this.name = room.basTitle.value

            } else {

              this.name = BasUtilities.translate('room')
            }

            this.syncRoomSourceSubtitle()

          } else if (BasUtil.isString(this.source.name)) {

            this.name = this.source.name
          }
        }

        nameChanged = currentName !== this.name

        break
      case BAS_SOURCE.T_NOTIFICATION:

        this.name = (
          BasUtil.isObject(this.source) &&
          BasUtil.isString(this.source.name)
        )
          ? this.source.name
          : BasUtilities.translate('notification')
        nameChanged = currentName !== this.name

        break
      case BAS_SOURCE.T_BLUETOOTH:

        this.name = this._getBluetoothName()
        nameChanged = currentName !== this.name

        break
    }

    if (nameChanged) {

      if (_emit) $rootScope.$emit(BAS_SOURCE.EVT_NAME_UPDATED, this.uuid)
    }
  }

  BasSource.prototype._getBluetoothName = function () {

    if (BasUtil.isObject(this.source) &&
      this.type === BAS_SOURCE.T_BLUETOOTH) {

      if (BasUtil.isNEString(this.source.clientName)) {

        return this.source.name + ' - ' + this.source.clientName
      }

      return this.source.name
    }

    return '-'
  }

  /**
   * @private
   */
  BasSource.prototype._syncBitDefaults = function () {

    var newDefaultImg

    newDefaultImg = this.isVideoSource
      ? biTV
      : biCoverDefaultCoverArt

    if (newDefaultImg !== this.bitMain.defaultImage) {

      this.bitMain.setDefaultImage(biTV)
    }
  }

  /**
   * Sets the correct subtype.
   * For Barp subtypes, will set the icon
   *
   * @private
   */
  BasSource.prototype._syncSubType = function () {

    this.subType = BasSource.getSubType(this.source, this.type)
    this.isBluetooth = (
      this.type === BAS_SOURCE.T_BLUETOOTH ||
      this.subType === BAS_SOURCE.ST_BLUETOOTH
    )
    this._syncIsPlaying()

    this.ui.barpIcon = null

    if (this.isPlayingAirplay) {

      this.ui.barpIcon = ICONS.barpNoMargin

    } else if (this.isPlayingSpotify) {

      this.ui.barpIcon = ICONS.spotifyNoMargin
    }

    this.state.syncSubType()
  }

  /**
   * Update the ui song information
   *
   * @private
   */
  BasSource.prototype._syncSongInfo = function () {

    var song

    song = this._getCurrentSong()

    this._resetSongInfo()
    this.css[BAS_SOURCE.CSS_HAS_SONG_TITLE] = false

    switch (this.type) {
      case BAS_SOURCE.T_SONOS:
      case BAS_SOURCE.T_ASANO:

        if (this.syncRoomSourceSubtitle()) break

        if (song) {

          if (BasUtil.isNEString(song.title)) {

            this.ui.songTitle = song.title
          }

          if (BasUtil.isNEString(song.artist)) {

            this.ui.songArtist = song.artist
          }

        } else if (this.isAudioSource) {

          if (this.source) {

            if (this.source.statusIsQueueEmpty) {

              this.ui.songTitle = BasUtilities.translate('empty_queue')

            } else if (this.source.statusIsEndOfQueue) {

              this.ui.songTitle = BasUtilities.translate('empty_song')

            } else {

              this.ui.songTitle = ''
            }
          }

        } else if (this.queue) {

          if (this.queue.length === 0) {

            this.ui.songTitle = BasUtilities.translate('empty_queue')

          } else {

            this.ui.songTitle = BasUtilities.translate('empty_song')
          }

        } else {

          this.ui.songTitle = this.subType === BAS_SOURCE.ST_STREAM
            ? BasUtilities.translate('empty_song')
            : ''
        }

        this._generateUiSongTitleArtist()

        break
      case BAS_SOURCE.T_PLAYER:
      case BAS_SOURCE.T_BARP:
      case BAS_SOURCE.T_BLUETOOTH:

        if (BasUtil.isObject(song)) {

          if (BasUtil.isNEString(song.artist)) {

            this.ui.songArtist = song.artist

          } else if (BasUtil.isNEString(song.name)) {

            this.ui.songArtist = song.name
          }

          if (BasUtil.isNEString(song.title)) {

            this.ui.songTitle = song.title
          }

        } else if (this.queue) {

          if (this.queue.length === 0) {

            this.ui.songTitle =
              BasUtilities.translate('empty_queue')

          } else if (!BasUtil.isObject(song)) {

            this.ui.songTitle =
              BasUtilities.translate('empty_song')
          }
        }

        this._generateUiSongTitleArtist()

        break
      case BAS_SOURCE.T_AUDIO_ALERT:

        this.ui.songTitle = BasUtil.isObject(this.source)
          ? this.source.alert
          : ''
        this.ui.songArtist = BasUtilities.translate('alert')
        this._generateUiSongTitleArtist()

        this.state.sync()

        break
    }

    this.css[BAS_SOURCE.CSS_HAS_SONG_TITLE] =
      BasUtil.isNEString(this.ui.songArtist) ||
      BasUtil.isNEString(this.ui.songTitle)
  }

  /**
   * Update the cover art
   *
   * @private
   */
  BasSource.prototype._syncCoverArt = function () {

    var song, tuneInGid, icon, covers, typeImage, queueEmpty

    song = this._getCurrentSong()

    switch (this.type) {
      case BAS_SOURCE.T_SONOS:
      case BAS_SOURCE.T_ASANO:

        typeImage = BasSource._getSvgForAudioSourceSubType(this.subType)

        switch (this.subType) {
          case BAS_SOURCE.ST_BLUETOOTH:
          case BAS_SOURCE.ST_EXTERNAL:
          case BAS_SOURCE.ST_RECORD:
          case BAS_SOURCE.ST_TV:

            this.bitMain.setImage('')
            this._setBitBgImage('')

            if (typeImage) {
              this.bitIcon.setImage(typeImage, biSvgBigOpts)
              this.bitMain.setImage(typeImage, biCoverIconOptsBig)
            } else {
              this.bitIcon.setImage(biMusic)
            }

            break

          default:

            if (song) {

              covers = CoverartHelper.parseCoverArtImages(song.coverartImages)

              this._setCoverArtThumbnail(
                covers.coverArt,
                covers.thumbnail,
                {
                  // Since spotify does not allow any manipulation of
                  //  cover-arts, we don't want to blur the cover-art when there
                  //  is no spotify library (this is disabled in their config)
                  noBackground: (
                    this.isPlayingTunein ||
                    (
                      this.isPlayingSpotify &&
                      !this.spotify
                    )
                  ),
                  radioStyling: this.isPlayingTunein
                }
              )

            } else {

              this.bitMain.setImage('')
              this._setBitBgImage('')

              queueEmpty = this.isAudioSource
                ? (
                    this.source &&
                    this.source.statusIsQueueEmpty
                  )
                : (
                    this.queue &&
                    this.queue.length === 0 &&
                    !this.queue.dirty
                  )

              if (queueEmpty) {

                this.bitIcon.setImage(biPlayingQEmpty)

              } else if (typeImage) {

                this.bitIcon.setImage(typeImage, biSvgBigOpts)
                this.bitMain.setImage(typeImage, biCoverIconOptsBig)

              } else {

                this.bitIcon.setImage(biMusic)
              }
            }
        }

        break
      case BAS_SOURCE.T_VIDEO:

        this.bitIcon.setImage(biVideo, biSvgBigOpts)

        break

      case BAS_SOURCE.T_PLAYER:
      case BAS_SOURCE.T_BARP:

        if (BasUtil.isObject(song)) {

          switch (this.subType) {
            case BAS_SOURCE.ST_TUNEIN:

              tuneInGid = song[K_TUNEIN_GID]

              if (tuneInGid !== this._tuneInGid) {

                this._resetSourceCoverArt()
              }

              this._tuneInGid = tuneInGid

              if (this.tunein) {

                if (BasUtil.isNEString(song.coverart)) {

                  this._setSvgCoverArt(song.coverart)

                } else {

                  this.tunein.stationInfo(tuneInGid).then(
                    this._onTuneInfo.bind(this, tuneInGid)
                  )
                }
              }

              break
            case BAS_SOURCE.ST_SPOTIFY:

              this._setBitBgImage('')

              this._setCoverArtThumbnail(
                song.coverartUrl,
                song.thumbnailUrl,
                {
                  noBackground: true
                }
              )

              break
            default:

              this._setCoverArtThumbnail(
                song.coverartUrl,
                song.thumbnailUrl
              )
          }

        } else {

          this.bitMain.setImage('')
          this._setBitBgImage('')
          this.bitIcon.setImage(
            this.queue &&
            this.queue.length === 0
              ? biPlayingQEmpty
              : ''
          )
        }

        break
      case BAS_SOURCE.T_EXTERNAL:

        this.bitIcon.setDefaultImage(biDisc)

        icon = BasSource._getSvgForExternalSourceType(this.subType)

        this.bitIcon.setImage(icon, biSvgBigOpts)
        this.bitMain.setImage(icon, biCoverIconOptsBig)
        this._setBitBgImage('')

        break
      case BAS_SOURCE.T_BLUETOOTH:

        this.bitIcon.setDefaultImage(biBluetooth)

        this.bitIcon.setImage(ICONS.bluetooth, biSvgBigOpts)
        this.bitMain.setImage(ICONS.bluetooth, biCoverIconOptsBig)
        this._setBitBgImage('')

        break
      case BAS_SOURCE.T_NOTIFICATION:

        this.bitIcon.setDefaultImage(biNotification)

        this.bitIcon.setImage(ICONS.notification, biSvgBigOpts)
        this.bitMain.setImage(ICONS.notification, biCoverIconOptsBig)
        this._setBitBgImage('')

        break
      case BAS_SOURCE.T_AUDIO_ALERT:

        this.bitIcon.setDefaultImage(biNotification)

        this.bitIcon.setImage(ICONS.notification, biSvgBigOpts)
        this.bitMain.setImage(ICONS.notification, biCoverIconOptsBig)
        this._setBitBgImage('')

        break
      case BAS_SOURCE.T_EMPTY:

        this.bitIcon.setDefaultImage(biOnOff)

        break
      case BAS_SOURCE.T_MIXED:

        this.bitMain.setImage(ICONS.mixed, biCoverIconOpts)
        this.bitIcon.setDefaultImage(biMixed)

        break
      case BAS_SOURCE.T_UNKNOWN_ID:

        this.bitMain.setImage(ICONS.musicFull, biCoverIconOpts)
        this.bitIcon.setDefaultImage(biMusic)

        break
      case BAS_SOURCE.T_UNKNOWN_SOURCE:
      case BAS_SOURCE.T_AV_INPUT_UNKNOWN:
      case BAS_SOURCE.T_AV_INPUT_NONE:
      default:

        this._resetSourceCoverArt()
    }
  }

  /**
   * @param {string[]} uuids
   * @returns {Promise}
   */
  BasSource.prototype.setQuickFavourites = function (uuids) {

    if (this.isAudioSource && this.source) {

      return this.source.setQuickFavourites(uuids)
    }

    return Promise.reject('Not supported for this BasSource')
  }

  /**
   * Update source properties
   * * paused
   *
   * @private
   */
  BasSource.prototype._syncSourceProperties = function () {

    this.supportsLibrary = false

    switch (this.type) {
      case BAS_SOURCE.T_ASANO:

        this.supportsLibrary = this.subType === BAS_SOURCE.ST_STREAM

      // noinspection FallThroughInSwitchStatementJS
      case BAS_SOURCE.T_SONOS:

        if (this.source) {

          this.listeningRooms = this.source.listeningRooms
          this.paused = this.source.paused
        }
        break

      case BAS_SOURCE.T_PLAYER:
      case BAS_SOURCE.T_BARP:

        this.supportsLibrary = true
        if (this.source) this.paused = this.source.paused

        break
      case BAS_SOURCE.T_BLUETOOTH:

        if (this.source) this.paused = this.source.paused

        break
      case BAS_SOURCE.T_EXTERNAL:
      case BAS_SOURCE.T_NOTIFICATION:
      case BAS_SOURCE.T_AUDIO_ALERT:

        this.paused = false
        break
    }
  }

  BasSource.prototype._syncNowPlayingIcon = function () {

    if (this.type === BAS_SOURCE.T_EMPTY) {

      // Empty source
      this.bitNowPlayingIcon.setImage(biPlayingEmpty)

    } else if (
      (
        this.isAudioSource &&
        this.state.hasQueue
      ) ||
      (
        this.type === BAS_SOURCE.T_PLAYER &&
        this.subType !== BAS_SOURCE.ST_TUNEIN
      )
    ) {

      if (this.paused) {

        // Has Queue and paused
        this.bitNowPlayingIcon.setImage(biPlayingQPaused)

      } else {

        // Has Queue and playing
        this.bitNowPlayingIcon.setImage(biPlayingQPlaying)
      }

    } else if (this.isVideoSource) {

      this.bitNowPlayingIcon.setImage(biVideo)

    } else {

      // Legacy

      if (this.paused) {

        // Has no Queue and paused
        this.bitNowPlayingIcon.setImage(biPlayingPaused)

      } else {

        // Has no Queue and playing
        this.bitNowPlayingIcon.setImage(biPlayingPlaying)
      }
    }
  }

  /**
   * @private
   * @returns {boolean}
   */
  BasSource.prototype._syncAvailable = function () {

    var oldAvailable

    oldAvailable = this.available

    this.available = (
      this.isAudioSource ||
      this.isVideoSource
    )
      ? (
          (
            this.source &&
            this.source.reachable
          )
        )
      : !this._isDirtySource()

    return this.available !== oldAvailable
  }

  /**
   * @private
   */
  BasSource.prototype._syncCapabilities = function () {

    var i, length, keys

    if (this.isAudioSource) {

      this.canListDefaultRooms = this.source.allowsExecute(
        BAS_API.AudioSource.C_LIST,
        BAS_API.AudioSource.C_DEFAULT_ROOMS
      )

      this.canSetDefaultRooms = this.source.allowsExecute(
        BAS_API.AudioSource.C_SET,
        BAS_API.AudioSource.C_DEFAULT_ROOMS
      )

      this.canListQueue = this.source.allowsExecute(
        BAS_API.AudioSource.C_LIST,
        BAS_API.AudioSource.C_QUEUE
      )

      this.canAddToQueue = this.source.allowsExecute(
        BAS_API.AudioSource.C_ADD,
        BAS_API.AudioSource.C_QUEUE
      )

      this.canListFavourites = this.source.allowsExecute(
        BAS_API.AudioSource.C_LIST,
        BAS_API.AudioSource.C_FAVOURITES
      )

      this.canAddFavourites = this.source.allowsExecute(
        BAS_API.AudioSource.C_ADD,
        BAS_API.AudioSource.C_FAVOURITES
      )

      this.canRemoveFavourites = this.source.allowsExecute(
        BAS_API.AudioSource.C_REMOVE,
        BAS_API.AudioSource.C_FAVOURITES
      )

      this.canToggleOn = this.source.allowsWrite(BAS_API.AudioSource.C_ON)

      this._syncNowPlayingIcon()

    } else if (this.isVideoSource) {

      this.canListFavourites = this.source.allowsExecute(
        BAS_API.VideoSource.C_LIST,
        BAS_API.VideoSource.C_FAVOURITES
      )

      this.canAddFavourites = this.source.allowsExecute(
        BAS_API.VideoSource.C_ADD,
        BAS_API.VideoSource.C_FAVOURITES
      )

      this.canRemoveFavourites = this.source.allowsExecute(
        BAS_API.VideoSource.C_REMOVE,
        BAS_API.VideoSource.C_FAVOURITES
      )

      keys = Object.keys(VIDEO_BUTTON)
      length = keys.length
      for (i = 0; i < length; i++) {

        this.canVideoButton[VIDEO_BUTTON[keys[i]]] =
          this.source.allowsExecute(
            this._getButtonAction(VIDEO_BUTTON[keys[i]])
          )
      }
    }
  }

  /**
   * @private
   */
  BasSource.prototype._syncHoldableVideoButtons = function () {

    var i, length, keys

    if (this.isVideoSource) {

      keys = Object.keys(VIDEO_BUTTON)
      length = keys.length
      for (i = 0; i < length; i++) {

        this.videoButtonHoldable[VIDEO_BUTTON[keys[i]]] =
          this.source.holdableActions.indexOf(
            this._getButtonAction(VIDEO_BUTTON[keys[i]])
          ) !== -1
      }
    }
  }

  /**
   * @private
   */
  BasSource.prototype._retrieveStatus = function () {

    var _this, _source

    _this = this
    _source = this.source

    if (
      (
        this.type === BAS_SOURCE.T_PLAYER ||
        this.type === BAS_SOURCE.T_BARP
      ) &&
      _source &&
      _source.dirty === true &&
      _source.status
    ) {
      _source.status().catch(_onStatusError)
    }

    function _onStatusError () {

      if (_source === _this.source) {

        _this._syncSubType()
        _this._syncSongInfo()
        _this._syncCoverArt()
      }
    }
  }

  /**
   * Handle TuneIn info
   *
   * @private
   * @param {string} tuneInGid
   * @param {*} result
   */
  BasSource.prototype._onTuneInfo = function (tuneInGid, result) {

    if (BasUtil.isObject(result) &&
      BasUtil.isNEString(result[K_LOGO]) &&
      this.type === BAS_SOURCE.T_PLAYER &&
      this.subType === BAS_SOURCE.ST_TUNEIN &&
      this._tuneInGid === tuneInGid) {

      this._setSvgCoverArt(result[K_LOGO])

      this._debouncedApplyAsync()
    }
  }

  /**
   * Checks if the source is connected.
   * This is only meaningful for Barp types.
   *
   * @returns {boolean}
   */
  BasSource.prototype.isConnected = function () {

    if (this.type === BAS_SOURCE.T_BARP) {

      return (
        (this.css[BAS_SOURCE.CSS_CONNECTED] = BasUtil.isObject(this.source))
      )
        ? this.source.connected
        : false
    }

    return (this.css[BAS_SOURCE.CSS_CONNECTED] = true)
  }

  /**
   * @returns {boolean}
   */
  BasSource.prototype.isSpotifyWebLinked = function () {

    return (
      this.css[BAS_SOURCE.CSS_SPOTIFY_WEB_LINKED] = (
        this.type === BAS_SOURCE.T_BARP &&
        this.subType === BAS_SOURCE.ST_SPOTIFY &&
        BasUtil.isObject(this.source) &&
        BasUtil.isNEString(this.source.token)
      )
    )
  }

  /**
   * Checks whether there is local content
   *
   * @returns {boolean}
   */
  BasSource.prototype.hasLocalContent = function () {

    return (
      this.css[BAS_SOURCE.CSS_HAS_CONTENT] = (
        (
          this.type === BAS_SOURCE.T_ASANO &&
          CurrentBasCore.hasCore() &&
          currentBasCoreState.core.core.musicLibrary &&
          currentBasCoreState.core.core.musicLibrary.available
        ) ||
        (
          this.type === BAS_SOURCE.T_PLAYER &&
          this.source &&
          this.source.database &&
          this.source.database.hasContent === true
        )
      )
    )
  }

  /**
   * @param {string} service
   * @returns {Promise}
   */
  // eslint-disable-next-line no-unused-vars
  BasSource.prototype.getStreamingServiceDetails = function (service) {

    if (this.type === BAS_SOURCE.T_ASANO) {

      return this.source.getStreamingServiceDetails(service)
        .then(this._handleStreamingServiceDetails)
    }

    return Promise.reject('Not supported for this BasSource')
  }

  /**
   * @private
   * @param {?string} coverArt URL
   * @param {?string} thumbnail URL
   * @param {?TSetCoverArtThumbnailOptions} [options]
   */
  BasSource.prototype._setCoverArtThumbnail = function (
    coverArt,
    thumbnail,
    options
  ) {
    // TODO Handle Object with different image formats

    var _this, imageToBlur, _iconOpts, _mainOpts
    var _noBackground, _radioStyling

    _this = this

    if (options) {

      _noBackground = BasUtil.isBool(options.noBackground)
        ? options.noBackground
        : false

      _radioStyling = BasUtil.isBool(options.radioStyling)
        ? options.radioStyling
        : false
    }

    _iconOpts = _radioStyling
      ? biSvgBgOpts
      : coverArtOpts
    _mainOpts = _radioStyling
      ? biCoverIconOptsBig
      : biCoverBoxOpts

    if (BasUtil.isNEString(coverArt)) {

      this.bitMain.setImage(coverArt, _mainOpts)

      if (BasUtil.isNEString(thumbnail)) {

        this.bitIcon.setImage(thumbnail, _iconOpts)
        imageToBlur = thumbnail

      } else {

        this.bitIcon.setImage(coverArt, _iconOpts)
        imageToBlur = coverArt
      }

    } else if (BasUtil.isNEString(thumbnail)) {

      this.bitMain.setImage(thumbnail, _mainOpts)
      this.bitIcon.setImage(thumbnail, _iconOpts)
      imageToBlur = thumbnail

    } else {

      this._resetSourceCoverArt()
    }

    if (BasUtil.isFunction(this._currentBlurCancellation) &&
      imageToBlur !== this._currentBlurImageUrl
    ) {

      this._currentBlurCancellation()
      this._currentBlurCancellation = null
    }

    if (!_noBackground) {

      if (basAppDevice.platforms.ios) {

        if (imageToBlur !== undefined) {

          this._setBitBgImage(imageToBlur, biBlurBgOpts)
        }

      } else {

        if (imageToBlur &&
          imageToBlur !== this._currentBlurredImageUrl &&
          imageToBlur !== this._currentBlurImageUrl) {

          this._currentBlurImageUrl = imageToBlur

          BasResource.getDomLoadedImage(imageToBlur, '')
            .then(onElement, onElementError)
        }
      }
    } else {

      this._setBitBgImage('')
    }

    /**
     * @param {HTMLImageElement} elementToBlur
     */
    function onElement (elementToBlur) {

      // Check if current BlurImage is the same of this received element
      if (_this._currentBlurImageUrl === imageToBlur) {

        _this._currentBlurCancellation = BasBlur.getBlurredImage(
          elementToBlur,
          BLUR_RADIUS,
          _onBgBlur
        )
      }
    }

    function onElementError () {

      // Do nothing
      // Blur failed because of CORS
    }

    function _onBgBlur (error, result) {

      if (_this._currentBlurImageUrl === imageToBlur) {

        if (error) {

          if (error !== BasBlur.ERR_CANCELED) {

            _this._setBitBgImage('')
          }

        } else {

          _this._setBitBgImage(result, biBgOpts, imageToBlur)
        }
      }
    }
  }

  /**
   * @private
   * @param {(string|Object)} svg URL or object
   */
  BasSource.prototype._setSvgCoverArt = function (svg) {

    this.bitIcon.setImage(svg, biSvgBgOpts)
    this.bitMain.setImage(svg, biCoverIconOptsBig)
    this._setBitBgImage('')
  }

  /**
   * @private
   * @param image
   * @param [options]
   * @param [blurredImageUrl]
   */
  BasSource.prototype._setBitBgImage = function (
    image,
    options,
    blurredImageUrl
  ) {
    this._currentBlurredImageUrl = blurredImageUrl !== undefined
      ? blurredImageUrl
      : ''
    this.bitBg.setImage(image, options)
  }

  /**
   * Resets the cover art to its default
   *
   * @private
   */
  BasSource.prototype._resetSourceCoverArt = function () {

    this.bitIcon.setImage('')
    this.bitMain.setImage('')
    this._setBitBgImage('')
  }

  /**
   * Clears the UI song information
   */
  BasSource.prototype._resetSongInfo = function () {

    this.ui.songTitle = ''
    this.ui.songArtist = ''
    this.ui.songTitleArtist = ''
  }

  /**
   * Gets the current song
   *
   * @returns {?(BasTrack|Object)}
   */
  BasSource.prototype._getCurrentSong = function () {

    if (this.source) {
      switch (this.type) {
        case BAS_SOURCE.T_SONOS:
        case BAS_SOURCE.T_ASANO:

          return this.source.nowPlaying.current

        case BAS_SOURCE.T_PLAYER:
        case BAS_SOURCE.T_BARP:
        case BAS_SOURCE.T_BLUETOOTH:

          return this.source.currentSong
      }
    }

    return null
  }

  // region Controls

  BasSource.prototype.play = function () {

    if (this.source) {
      // AudioSource uses play action without parameter, while other sources
      //  (legacy, video-sources), use the togglePlayPause method which takes
      //  an optional parameter. AudioSources cannot do this as it's
      //  'togglePlayPause' action does not take parameters.
      if (this.isAudioSource) {
        this.source.play()
      } else {
        this.source.togglePlayPause(true)
      }
    }
  }

  BasSource.prototype.activatePairing = function () {

    if (
      this.type === BAS_SOURCE.T_BLUETOOTH &&
      this.source
    ) {

      this.source.activatePairing()

    } else if (
      this.isAudioSource &&
      this.subType === BAS_SOURCE.ST_BLUETOOTH
    ) {

      this.source.setPairing(true)
    }
  }

  BasSource.prototype.disablePairing = function () {

    if (
      this.type === BAS_SOURCE.T_BLUETOOTH &&
      this.source
    ) {

      this.source.disablePairing()

    } else if (
      this.isAudioSource &&
      this.subType === BAS_SOURCE.ST_BLUETOOTH
    ) {

      this.source.setPairing(false)
    }
  }

  /**
   * @param {boolean} [force]
   */
  BasSource.prototype.togglePlay = function (force) {

    var newPausedState

    if (this.source && !this.state.isStream() && this.state.canPlayPause) {

      newPausedState = BasUtil.isBool(force)
        ? !force
        : !this.state.paused

      if (this.isAudioSource) {

        BasCommandQueue.audioSourceTogglePlayPause(this.uuid, !newPausedState)

      } else {

        this.source.togglePlayPause(!newPausedState)
      }

      this.state.setPaused(newPausedState)
      this.state.setPauseTimeout()
    }
  }

  BasSource.prototype.previous = function () {

    if (this.source && !this.state.isStream() && this.state.canPrevious) {

      if (this.isVideoSource) {

        this.source.action(BAS_API.VideoSource.ACT_SKIP_PREVIOUS)

      } else if (this.isAudioSource) {

        BasCommandQueue.audioSourcePrevious(this.uuid)

      } else {

        this.source.previous()
      }
    }
  }

  BasSource.prototype.next = function () {

    if (this.source && !this.state.isStream() && this.state.canNext) {

      if (this.isVideoSource) {

        this.source.action(BAS_API.VideoSource.ACT_SKIP_NEXT)

      } else if (this.isAudioSource) {

        BasCommandQueue.audioSourceNext(this.uuid)

      } else {

        this.source.next()
      }
    }
  }

  BasSource.prototype.toggleRepeat = function () {

    var repeatModes, idx, newRepeatMode

    if (this.source && this.state.canRepeat) {

      if (this.isAudioSource) {

        repeatModes = this.source.possibleRepeatModes
        repeatModes.sort(BasUtilities.compareAscending)

        idx = this.state.repeatMode

        if (idx < 0) {

          // Unknown repeat mode => turn repeat off
          newRepeatMode = BAS_API.CONSTANTS.REPEAT_OFF

        } else {

          idx++
          idx %= repeatModes.length

          newRepeatMode = idx
        }

        this.state.setRepeatMode(newRepeatMode)

        this.source.setRepeated(this.state.repeatMode)

      } else if (this.type === BAS_SOURCE.T_BARP &&
        this.subType === BAS_SOURCE.ST_SPOTIFY &&
        CurrentBasCore.hasCore() &&
        currentBasCoreState.core.core.supportsRepeatMode) {

        this._toggleRepeatExtended()

      } else {

        this._toggleRepeat()
      }
    }
  }

  /**
   * Toggle repeat between off and repeat.
   *
   * @private
   */
  BasSource.prototype._toggleRepeat = function () {

    this.state.setRepeatMode(
      this.state.repeatMode !== BAS_API.CONSTANTS.REPEAT_OFF
        ? BAS_API.CONSTANTS.REPEAT_OFF
        : BAS_API.CONSTANTS.REPEAT_CURRENT_CONTEXT
    )

    if (this.source) this.source.repeatMode = this.state.repeatMode
  }

  /**
   * Toggle repeat between off, repeat and repeat once.
   *
   * @private
   */
  BasSource.prototype._toggleRepeatExtended = function () {

    var idx

    idx = BAS_SOURCE.REPEAT_MODES.indexOf(this.state.repeatMode)
    idx++
    idx %= BAS_SOURCE.REPEAT_MODES.length

    this.state.setRepeatMode(BAS_SOURCE.REPEAT_MODES[idx])

    if (this.source) this.source.repeatMode = this.state.repeatMode
  }

  BasSource.prototype.toggleRandom = function () {

    if (this.source && this.state.canShuffle) {

      this.state.setRandom(!this.state.random)

      if (this.isAudioSource) {

        this.source.setRandom(this.state.random)

      } else {

        this.source.random = this.state.random
      }
    }
  }

  BasSource.prototype.replay15 = function () {

    if (this.type === BAS_SOURCE.T_BARP &&
      this.subType === BAS_SOURCE.ST_SPOTIFY &&
      this.source) {

      this.source.relativeSeek(-15)
    }
  }

  BasSource.prototype.forward15 = function () {

    if (this.type === BAS_SOURCE.T_BARP &&
      this.subType === BAS_SOURCE.ST_SPOTIFY &&
      this.source) {

      this.source.relativeSeek(15)
    }
  }

  /**
   * @param {number} percentage
   */
  BasSource.prototype.updatePosition = function (percentage) {

    var newPosition

    if (this.source &&
      (
        this.isAudioSource ||
        this.type === BAS_SOURCE.T_PLAYER ||
        (
          this.type === BAS_SOURCE.T_BARP &&
          this.subType === BAS_SOURCE.ST_SPOTIFY
        )
      )) {

      // Check if need to skip
      if (percentage >= 100) {

        // Skip to the next song
        this.source.next()

      } else {

        newPosition =
          Math.floor(percentage * this.state.duration / 100)

        if (this.isAudioSource) {

          this.source.setPositionMs(newPosition)

        } else {

          // Calculate new position
          this.source.position = newPosition
        }
      }
    }
  }

  /**
   * @see PlayUriHelper#playUri
   * @param {(string|string[])} uri
   * @param {TPlayUriOptions} [options]
   * @returns {Promise}
   */
  BasSource.prototype.playUri = function (
    uri,
    options
  ) {

    var _showModalOnError, _contextUri, _contextOffset

    if (options) {

      _showModalOnError = options.showModalOnError
      _contextUri = options.contextUri
      _contextOffset = options.contextOffset
    }

    return _showModalOnError === true
      ? PlayUriHelper.playUriWithErrorModal(
        this,
        uri,
        {
          contextUri: _contextUri,
          contextOffset: _contextOffset
        }
      )
      : PlayUriHelper.playUri(
        this,
        uri,
        {
          contextUri: _contextUri,
          contextOffset: _contextOffset
        }
      )
  }

  // endregion

  // region CSS

  /**
   * Resets all CSS classes to false
   *
   * @private
   */
  BasSource.prototype._resetCss = function () {

    this.css[BAS_SOURCE.CSS_CONNECTED] = false
    this.css[BAS_SOURCE.CSS_HAS_CONTENT] = false
    this.css[BAS_SOURCE.CSS_DEEZER_LINKED] = false
    this.css[BAS_SOURCE.CSS_TIDAL_LINKED] = false
    this.css[BAS_SOURCE.CSS_SPOTIFY_WEB_LINKED] = false
    this.css[BAS_SOURCE.CSS_HAS_SONG_TITLE] = false
  }

  /**
   * @param {boolean} value
   */
  BasSource.prototype.cssSetDeezerLinked = function (value) {

    this.css[BAS_SOURCE.CSS_DEEZER_LINKED] = value
  }

  /**
   * @param {boolean} value
   */
  BasSource.prototype.cssSetTidalLinked = function (value) {

    this.css[BAS_SOURCE.CSS_TIDAL_LINKED] = value
  }

  /**
   * @param {boolean} value
   */
  BasSource.prototype.cssSetSpotifyWebLinked = function (value) {

    this.css[BAS_SOURCE.CSS_SPOTIFY_WEB_LINKED] = value
  }

  /**
   * @param {boolean} value
   */
  BasSource.prototype.cssSetSpotifySpinner = function (value) {

    this.css[BAS_SOURCE.CSS_SPOTIFY_SPINNER] = value
  }

  /**
   * @param {boolean} value
   */
  BasSource.prototype.cssSetDeezerSpinner = function (value) {

    this.css[BAS_SOURCE.CSS_DEEZER_SPINNER] = value
  }

  /**
   * @param {boolean} value
   */
  BasSource.prototype.cssSetTidalSpinner = function (value) {

    this.css[BAS_SOURCE.CSS_TIDAL_SPINNER] = value
  }

  // endregion

  // region Event registration

  /**
   * Register interest in an event collection
   *
   * @param {string} key
   */
  BasSource.prototype.registerFor = function (key) {

    var current

    if (key in this._eventCollections) {

      current = BasUtil.copyObj(this._eventCollections)

      this._eventCollections[key]++

      this._processEventCollections(current)
      this._setSourceListeners()
    }
  }

  /**
   * Unregister interest in an event collection
   *
   * @param {string} key
   */
  BasSource.prototype.unregisterFor = function (key) {

    if (key in this._eventCollections) {

      this._eventCollections[key]--

      this._setSourceListeners()
    }
  }

  /**
   * Set an event collection object
   *
   * @param {Object<string, number>} eventCollections
   */
  BasSource.prototype.setEventCollections = function (
    eventCollections
  ) {
    var current

    if (BasUtil.isObject(eventCollections)) {

      current = BasUtil.copyObject(this._eventCollections)

      this._eventCollections = BasUtil.copyObject(eventCollections)

      this._processEventCollections(current)
      this._setSourceListeners()
    }
  }

  /**
   * Processes the event collections.
   * Depending on which events are turned on,
   * execute some sync functions to make sure that is in sync from now on
   *
   * @private
   * @param {Object<string, number>} current
   */
  BasSource.prototype._processEventCollections = function (
    current
  ) {
    var _this = this

    if (this._eventCollections[BAS_SOURCE.COL_EVT_PLAYER] > 0 ||
      this._eventCollections[BAS_SOURCE.COL_EVT_SIMPLE] > 0) {

      this._retrieveStatus()
    }

    if (_eventStarted(BAS_SOURCE.COL_EVT_PLAYER) ||
      _eventStarted(BAS_SOURCE.COL_EVT_SIMPLE)) {

      if (!this._isDirtySource()) {

        this._syncSubType()
        this._syncSongInfo()
        this._syncCoverArt()
      }
    }

    if (_eventStarted(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      if (!CurrentBasCore.hasAVFullSupport()) {

        if (this.deezer) this.deezer.linked().catch(_ignoreButLog)
        if (this.tidal) this.tidal.linked().catch(_ignoreButLog)
      }

      if (this.favourites) {
        this.favourites.retrieve().catch(_ignoreButLog)
        this.favourites.retrieveQuickFavourites().catch(_ignoreButLog)

      }
    }

    if (_eventStarted(BAS_SOURCE.COL_EVT_LIBRARY)) {

      this.hasLocalContent()
      if (this.deezer) this.deezer.linked().catch(_ignoreButLog)
      if (this.tidal) this.tidal.linked().catch(_ignoreButLog)
      if (this.spotify) this.spotify.linked().catch(_ignoreButLog)
    }

    if (_eventStarted(BAS_SOURCE.COL_EVT_DEEZER)) {

      if (this.deezer) this.deezer.linked().catch(_ignoreButLog)
    }

    if (_eventStarted(BAS_SOURCE.COL_EVT_TIDAL)) {

      if (this.tidal) this.tidal.updateUsername()
    }

    if (_eventStarted(BAS_SOURCE.COL_EVT_SPOTIFY) ||
      _eventStarted(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      if (this.spotify) {
        this.spotify.updateUsername()
        this.spotify.updateConnectedName()
      }
    }

    if (_eventStarted(BAS_SOURCE.COL_EVT_DEFAULT_ROOMS)) {

      if (this.defaultRooms) this.defaultRooms.sync()
    }

    if (_eventStarted(BAS_SOURCE.COL_EVT_KNX_PRESETS)) {

      if (this.knxPresets) {

        this.knxPresets.retrievePresets().catch(_ignoreButLog)
      }
    }

    if (_eventStarted(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.sync()
      if (this.queue) this.queue.syncQueue()
    }

    /**
     * Started to track a specific event collection
     *
     * @private
     * @param {string} key
     * @returns {boolean}
     */
    function _eventStarted (key) {

      return (
        current[key] === 0 &&
        _this._eventCollections[key] > 0
      )
    }
  }

  /**
   * Check if specified event collection is active
   *
   * @param {string} key
   * @returns {boolean}
   */
  BasSource.prototype.active = function (key) {

    return this._eventCollections[key] > 0
  }

  // endregion

  // region Set event listeners

  /**
   * Set necessary source listeners
   */
  BasSource.prototype._setSourceListeners = function () {

    this._clearSourceListeners()

    if (BasUtil.isObject(this.source)) {

      switch (this.type) {
        case BAS_SOURCE.T_ASANO:
        case BAS_SOURCE.T_SONOS:

          this._setAudioListeners()

          break
        case BAS_SOURCE.T_VIDEO:

          this._setVideoListeners()

          break
        case BAS_SOURCE.T_PLAYER:

          this._setPlayerListeners()

          break
        case BAS_SOURCE.T_BARP:

          this._setBarpListeners()

          break
        case BAS_SOURCE.T_BLUETOOTH:

          this._setBluetoothListeners()

          break
        case BAS_SOURCE.T_AUDIO_ALERT:

          this._setAVAudioListeners()

          break
      }
    }
  }

  /**
   * Sets the necessary Audio Source listeners
   *
   * @private
   */
  BasSource.prototype._setAudioListeners = function () {

    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Device.EVT_REACHABLE,
      this._handleReachable
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Device.EVT_NAME,
      this._handleName
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Device.EVT_CAPABILITIES,
      this._handleCapabilities
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Device.EVT_ATTRIBUTES,
      this._handleAttributes
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_DEFAULT_NAME_CHANGED,
      this._handleAudioDefaultNameChanged
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_FAVOURITES_RESET,
      this._handleAVFavouritesReset
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_QUICK_FAVOURITE_RESET,
      this._handleAVQuickFavouritesReset
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_FAVOURITE_ADDED,
      this._handleAVFavouriteAdded
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_FAVOURITE_REMOVED,
      this._handleAVFavouriteRemoved
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_FAVOURITE_UPDATED,
      this._handleAVFavouriteUpdated
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_CURRENT_SONG_CHANGED,
      this._handleCurrentSong
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_LISTENING_ROOMS_CHANGED,
      this._handleAudioListeningRoomsChanged
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_DEFAULT_ROOMS_RESET,
      this._handleDefaultRoomsChanged
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_STREAMING_SERVICE_TOKEN_CHANGED,
      this._handleStreamingServiceTokenChanged
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_STREAMING_SERVICE_VALUE_UPDATED,
      this._handleStreamingServiceValueUpdated
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_PRESET_LINKED,
      this._handlePresetLinked
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AudioSource.EVT_STATUS_CHANGED,
      this._handleAudioStatusChanged
    ))

    // State

    if (this.active(BAS_SOURCE.COL_EVT_SIMPLE) ||
      this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.AudioSource.EVT_PLAYBACK_CHANGED,
        this._handlePlayback
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.AudioSource.EVT_QUEUE_RESET,
        this._handleQueueChanged
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.AudioSource.EVT_QUEUE_MOVED,
        this._handleQueueMoved
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.AudioSource.EVT_QUEUE_REMOVED,
        this._handleQueueRemoved
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.AudioSource.EVT_QUEUE_ADDED,
        this._handleQueueAdded
      ))
    }

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.AudioSource.EVT_NEXT_SONG_CHANGED,
        this._handleNextSong
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.AudioSource.EVT_POSITION_CHANGED,
        this._handlePosition
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.AudioSource.EVT_REPEATED_CHANGED,
        this._handleRepeat
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.AudioSource.EVT_RANDOM_CHANGED,
        this._handleRandom
      ))
    }
  }

  /**
   * Sets the necessary Video Source listeners
   *
   * @private
   */
  BasSource.prototype._setVideoListeners = function () {

    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Device.EVT_REACHABLE,
      this._handleReachable
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Device.EVT_NAME,
      this._handleName
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Device.EVT_CAPABILITIES,
      this._handleCapabilities
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Device.EVT_ATTRIBUTES,
      this._handleAttributes
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.VideoSource.EVT_FAVOURITES_RESET,
      this._handleAVFavouritesReset
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.VideoSource.EVT_FAVOURITE_ADDED,
      this._handleAVFavouriteAdded
    ))
    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.VideoSource.EVT_FAVOURITE_REMOVED,
      this._handleAVFavouriteRemoved
    ))

    // State

    if (this.active(BAS_SOURCE.COL_EVT_SIMPLE) ||
      this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.VideoSource.EVT_PLAYBACK_CHANGED,
        this._handlePlayback
      ))
    }
  }

  /**
   * Sets the necessary Audio Source listeners
   *
   * @private
   */
  BasSource.prototype._setAVAudioListeners = function () {

    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.AVAudio.EVT_STATE_CHANGED,
      this._handleAVAudioState
    ))
  }

  /**
   * Sets the necessary Player listeners depending on the event collections
   *
   * @private
   */
  BasSource.prototype._setPlayerListeners = function () {

    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Player.EVT_CURRENT_SONG,
      this._handleCurrentSong
    ))

    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Player.EVT_DIRTY_CHANGED,
      this._handleDirtyChanged
    ))

    if (this.active(BAS_SOURCE.COL_EVT_SIMPLE) ||
      this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Player.EVT_PAUSED,
        this._handlePaused
      ))
    }

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Player.EVT_NEXT_SONG,
        this._handleNextSong
      ))

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Player.EVT_REPEAT,
        this._handleRepeat
      ))

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Player.EVT_RANDOM,
        this._handleRandom
      ))

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Player.EVT_POSITION,
        this._handlePosition
      ))

      this._sourceListeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CUSTOM_RADIOS,
        this._handleCustomRadioUpdate
      ))
    }

    if (this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Player.EVT_FAVOURITES_CHANGED,
        this._handleFavouritesChanged
      ))
    }

    if (this.active(BAS_SOURCE.COL_EVT_DEFAULT_ROOMS)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Stream.EVT_DEFAULT_ROOMS_CHANGED,
        this._handleDefaultRoomsChanged
      ))
    }

    if (this.active(BAS_SOURCE.COL_EVT_KNX_PRESETS)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Player.EVT_PRESETS_CHANGED,
        this._handleKNXPresetsChanged
      ))
    }

    if (this.source.database) {

      if (this.active(BAS_SOURCE.COL_EVT_DATABASE_CONTENT) ||
        this.active(BAS_SOURCE.COL_EVT_LIBRARY)) {

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source.database,
          BAS_API.Database.EVT_HAS_CONTENT,
          this._handleDbContentChanged
        ))
      }

      if (this.active(BAS_SOURCE.COL_EVT_LOCAL_PLAYLISTS) ||
        this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source.database,
          BAS_API.Database.EVT_PLAYLIST_CHANGED,
          this._handlePlaylistChanged
        ))
      }
    }

    if (this.source.deezer) {

      if (this.active(BAS_SOURCE.COL_EVT_DEEZER) ||
        this.active(BAS_SOURCE.COL_EVT_FAVOURITES) ||
        this.active(BAS_SOURCE.COL_EVT_LIBRARY)) {

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source.deezer,
          BAS_API.Deezer.EVT_DEEZER_LINK_CHANGED,
          this._handleDeezerLinked
        ))
      }

      if (this.active(BAS_SOURCE.COL_EVT_DEEZER)) {

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source.deezer,
          BAS_API.Deezer.EVT_LINK_FINISHED,
          this._handleDeezerLinkFinished
        ))

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source.deezer,
          BAS_API.Deezer.EVT_LINK_ERROR,
          this._handleDeezerLinkError
        ))
      }
    }

    if (this.source.tidal) {

      if (this.active(BAS_SOURCE.COL_EVT_TIDAL) ||
        this.active(BAS_SOURCE.COL_EVT_FAVOURITES) ||
        this.active(BAS_SOURCE.COL_EVT_LIBRARY)) {

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source.tidal,
          BAS_API.Tidal.EVT_LINK_CHANGED,
          this._handleTidalLinked
        ))
      }
    }

    if (this.source.queue) {

      if (this.active(BAS_SOURCE.COL_EVT_PLAYER) ||
        this.active(BAS_SOURCE.COL_EVT_SIMPLE)) {

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source.queue,
          BAS_API.Queue.EVT_CHANGED,
          this._handleQueueChanged
        ))
      }
    }
  }

  /**
   * Sets the necessary Barp listeners depending on the event collections
   *
   * @private
   */
  BasSource.prototype._setBarpListeners = function () {

    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Barp.EVT_CONNECTED,
      this._handleConnected
    ))

    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.Barp.EVT_CURRENT_SONG,
      this._handleCurrentSong
    ))

    if (this.subType === BAS_SOURCE.ST_SPOTIFY) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.SpotifyBarp.EVT_LINK_URL_CHANGED,
        this._handleSpotifyLinkChanged
      ))
    }

    if (this.active(BAS_SOURCE.COL_EVT_SIMPLE)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Barp.EVT_COVER_ART,
        this._handleCoverArt
      ))
    }

    if (this.active(BAS_SOURCE.COL_EVT_SIMPLE) ||
      this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Barp.EVT_PAUSED,
        this._handlePaused
      ))
    }

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Barp.EVT_DURATION,
        this._handleDuration
      ))

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Barp.EVT_POSITION,
        this._handlePosition
      ))

      if (this.subType === BAS_SOURCE.ST_SPOTIFY) {

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source,
          BAS_API.SpotifyBarp.EVT_NEXT_SONG,
          this._handleNextSong
        ))

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source,
          BAS_API.SpotifyBarp.EVT_RANDOM,
          this._handleRandom
        ))

        this._sourceListeners.push(BasUtil.setEventListener(
          this.source,
          BAS_API.SpotifyBarp.EVT_REPEAT,
          this._handleRepeat
        ))
      }
    }

    if (this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.SpotifyBarp.EVT_PRESETS_CHANGED,
        this._handleSpotifyPresetsChanged
      ))

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.Barp.EVT_CLIENT_CHANGED,
        this._handleClientChanged
      ))
    }

    if (this.active(BAS_SOURCE.COL_EVT_SPOTIFY) ||
      this.active(BAS_SOURCE.COL_EVT_FAVOURITES) ||
      this.active(BAS_SOURCE.COL_EVT_LIBRARY)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.SpotifyBarp.EVT_TOKEN_CHANGED,
        this._handleSpotifyTokenChanged
      ))
    }
  }

  /**
   * Sets the necessary Barp listeners depending on the event collections
   *
   * @private
   */
  BasSource.prototype._setBluetoothListeners = function () {

    this._sourceListeners.push(BasUtil.setEventListener(
      this.source,
      BAS_API.BluetoothSource.EVT_CURRENT_SONG,
      this._handleCurrentSong
    ))

    if (this.active(BAS_SOURCE.COL_EVT_SIMPLE)) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.BluetoothSource.EVT_CLIENT_NAME,
        this._handleClientName
      ))

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.BluetoothSource.EVT_PAIRING,
        this._handlePairing
      ))

      this._sourceListeners.push(BasUtil.setEventListener(
        this.source,
        BAS_API.BluetoothSource.EVT_PAUSED,
        this._handlePaused
      ))
    }
  }

  // endregion

  // region Event listeners

  /**
   * @private
   */
  BasSource.prototype._onReachable = function () {

    if (this._syncAvailable()) {

      $rootScope.$emit(
        BAS_SOURCE.EVT_AVAILABLE_CHANGE,
        this.uuid
      )
    }

    this._debouncedApplyAsync()
  }

  /**
   * @private
   */
  BasSource.prototype._onName = function () {

    this._syncName()
    this._debouncedApplyAsync()
  }

  /**
   * @private
   */
  BasSource.prototype._onCapabilities = function () {

    var oldHasQueue

    Logger.debug(this._getTag() + ' CAPABILITIES')

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER) ||
      this.active(BAS_SOURCE.COL_EVT_SIMPLE)) {

      oldHasQueue = this.state.hasQueue

      if (this.state) this.state.handleCapabilities()

      if (oldHasQueue !== this.state.hasQueue) {

        this._handleQueueChanged()
      }
    }

    this._syncCapabilities()
    this.state.sync()

    $rootScope.$emit(
      BAS_SOURCE.EVT_CAPABILITIES_CHANGED,
      this.uuid
    )

    this._debouncedApplyAsync()
  }

  /**
   * @private
   */
  BasSource.prototype._onAttributes = function () {

    this._syncVideoButtons()
    this._syncHoldableVideoButtons()
  }

  /**
   * @private
   */
  BasSource.prototype._syncVideoButtons = function () {

    var numericButtons, customButtons
    var i, length

    Logger.debug(this._getTag() + ' ATTRIBUTES')

    if (this.isVideoSource) {

      if (this.source.customButtons) {

        customButtons = []

        length = this.source.customButtons.length
        for (i = 0; i < length; i++) {

          if (BasUtil.isPNumber(this.source.customButtons[i].index, true)) {

            customButtons[this.source.customButtons[i].index] =
              this.source.customButtons[i]
          }
        }
      }

      if (this.source.numericButtons) {

        numericButtons = []

        length = this.source.numericButtons.length
        for (i = 0; i < length; i++) {

          if (BasUtil.isPNumber(this.source.numericButtons[i].index, true)) {

            numericButtons[this.source.numericButtons[i].index] =
              this.source.numericButtons[i]
          }
        }
      }
    }

    this.numericButtons = numericButtons
    this.customButtons = customButtons
  }

  BasSource.prototype._onConnected = function () {

    Logger.debug(this._getTag() + ' CONNECTED')

    this.isConnected()

    if (this.subType === BAS_SOURCE.ST_AAP) {

      this._resetSourceCoverArt()
      this._resetSongInfo()
    }

    $rootScope.$emit(
      BAS_SOURCE.EVT_CONNECTED,
      this.id
    )
  }

  BasSource.prototype._onSpotifyLinkChanged = function (link) {

    Logger.debug(this._getTag() + ' SPOTIFY LINK CHANGED')

    $rootScope.$emit(
      BAS_SOURCE.EVT_SPOTIFY_LINK_CHANGED,
      this.uuid,
      link
    )
  }

  BasSource.prototype._onPaused = function (isPaused) {

    Logger.debug(this._getTag() + ' PAUSED')

    this.paused = isPaused

    this._syncNowPlayingIcon()

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handlePaused(isPaused)
    }

    this._debouncedApplyAsync()
  }

  BasSource.prototype._onClientName = function () {

    Logger.debug(this._getTag() + ' CLIENT NAME')

    this._syncName()

    this._debouncedApplyAsync()
  }

  BasSource.prototype._onPairing = function (pairing) {

    Logger.debug(this._getTag() + ' PAIRING')

    this.pairing = pairing === true

    this._debouncedApplyAsync()
  }

  BasSource.prototype._onCustomRadioUpdate = function () {

    this._syncCoverArt()

    this._debouncedApplyAsync()
  }

  BasSource.prototype._onCurrentSong = function () {

    Logger.debug(this._getTag() + ' CURRENT SONG')

    this._syncSubType()
    this._syncSongInfo()
    this._syncCoverArt()
    this._syncSourceProperties()
    this._syncNowPlayingIcon()

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handleCurrentSong()
    }

    $rootScope.$emit(BAS_SOURCE.EVT_CURRENT_SONG, this)

    this._debouncedApplyAsync()
  }

  BasSource.prototype._onNextSong = function () {

    Logger.debug(this._getTag() + ' NEXT SONG')

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handleNextSong()

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onRepeat = function (repeatMode) {

    Logger.debug(this._getTag() + ' REPEAT')

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handleRepeat(repeatMode)

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onRandom = function (isRandom) {

    Logger.debug(this._getTag() + ' RANDOM')

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handleRandom(isRandom)

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onPosition = function (position) {

    Logger.debug(this._getTag() + ' POSITION')

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handlePosition(position)

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onDuration = function (duration) {

    Logger.debug(this._getTag() + ' DURATION')

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handleDuration(duration)

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onCoverArt = function () {

    Logger.debug(this._getTag() + ' COVERART')

    this._syncCoverArt()

    this._debouncedApplyAsync()
  }

  BasSource.prototype._onDirtyChanged = function () {

    Logger.debug(this._getTag() + ' DIRTY')

    if (this._syncAvailable()) {

      $rootScope.$emit(
        BAS_SOURCE.EVT_AVAILABLE_CHANGE,
        this.uuid
      )
    }

    this._debouncedApplyAsync()
  }

  BasSource.prototype._onDbContentChanged = function () {

    this.hasLocalContent()

    this._debouncedApplyAsync()
  }

  BasSource.prototype._onAudioDefaultNameChanged = function () {

    // TODO: We don't really need to use sonos names, we use our room names
  }

  BasSource.prototype._onAVFavouritesReset = function () {

    if (this.favourites) this.favourites.clearAVFavourites()

    if (this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      if (this.favourites) this.favourites.retrieveAllPaginated()
    }
  }

  BasSource.prototype._onAVQuickFavouritesReset = function () {

    if (this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      if (this.favourites) {
        this.favourites.retrieveQuickFavourites().catch(_ignoreButLog)
      }
    }
  }

  BasSource.prototype._onAVFavouriteAdded = function (favourite) {

    if (this.favourites) {

      this.favourites.handleFavouriteAdded(favourite)

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onAVFavouriteRemoved = function (favourite) {

    if (this.favourites) {

      this.favourites.handleFavouriteRemoved(favourite)

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onAVFavouriteUpdated = function (favourite) {

    if (this.favourites) {

      this.favourites.handleFavouriteUpdated(favourite)

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onAudioListeningRoomsChanged = function (
    listeningRooms
  ) {
    if (Array.isArray(listeningRooms)) {

      this.listeningRooms = listeningRooms
      $rootScope.$emit(
        BAS_SOURCE.EVT_LISTENING_ROOMS_CHANGE,
        this.getId()
      )
    }
  }

  /**
   * @param {TAudioSourceStreamingServiceDetailsResult} streamingServiceDetails
   * @private
   */
  BasSource.prototype._onStreamingServiceDetails = function (
    streamingServiceDetails
  ) {

    if (streamingServiceDetails) {

      this.streamingServiceTokens[streamingServiceDetails.streamingService] =
        streamingServiceDetails.token
    }
  }

  /**
   * @private
   * @param {Object} streamingServiceTokenData
   * @returns {*}
   */
  BasSource.prototype._onStreamingServiceTokenChanged = function (
    streamingServiceTokenData
  ) {

    if (
      BasUtil.isNEString(streamingServiceTokenData.streamingService) &&
      BasUtil.isString(streamingServiceTokenData.token)
    ) {

      this.streamingServiceTokens[streamingServiceTokenData.streamingService] =
        streamingServiceTokenData.token

      switch (streamingServiceTokenData.streamingService) {
        case 'spotify':
          return this._handleSpotifyTokenChanged()
        case 'tidal':
          return this._handleTidalLinked()
        case 'deezer':
          return this._handleDeezerLinked()
      }
    }
  }

  /**
   * @private
   * @param {Object} streamingServiceValueData
   */
  BasSource.prototype._onStreamingServiceValueUpdated = function (
    streamingServiceValueData
  ) {

    if (
      BasUtil.isNEString(streamingServiceValueData.streamingService) &&
      BasUtil.isNEString(streamingServiceValueData.key)
    ) {

      switch (streamingServiceValueData.streamingService) {
        case 'tidal':
          this.tidal.setStreamingServiceProperty(
            streamingServiceValueData.key,
            streamingServiceValueData.value
          )
          break
        case 'deezer':
          this.deezer.setStreamingServiceProperty(
            streamingServiceValueData.key,
            streamingServiceValueData.value
          )
      }
    }
  }

  /**
   * @private
   * @param {TAudioSourcePresetLinkData} linkData
   */
  BasSource.prototype._onPresetLinked = function (linkData) {

    if (this.knxPresets) {

      this.knxPresets.handlePresetLinked(linkData.id, linkData.uri)
      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onAudioStatusChanged = function () {

    this._syncSongInfo()
    this._syncCoverArt()

    this._debouncedApplyAsync()

    $rootScope.$emit(
      BAS_SOURCE.EVT_STATUS_UPDATED,
      this.uuid
    )
  }

  BasSource.prototype._onAVAudioState = function () {

    if (
      this.type === BAS_SOURCE.T_AUDIO_ALERT &&
      BasUtil.isString(this.source.alert)
    ) {

      this._syncSongInfo()
      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onPlaylistChanged = function () {

    if (this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      if (this.playlists) this.playlists.handlePlaylistChanged()
      if (this.favourites) this.favourites.handleLocalPlaylistsChanged()

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onFavouritesChanged = function () {

    if (this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      if (this.favourites) this.favourites.handleFavouritesChanged()

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onQueueChanged = function () {

    if (this.queue) {

      if (this.isAudioSource) {

        // Always handle queue events to keep queue up to date, no need to sync
        //  song info or cover-art since that is based on 'status' property now.

        this.queue.handleQueueChanged()
        this._debouncedApplyAsync()

      } else if (
        this.active(BAS_SOURCE.COL_EVT_SIMPLE) ||
        this.active(BAS_SOURCE.COL_EVT_PLAYER)
      ) {

        // Legacy

        this.queue.handleQueueChanged()

        this._syncSongInfo()
        this._syncCoverArt()

        this._debouncedApplyAsync()
      }
    }

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handleQueueChanged()

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onQueueMoved = function (data) {

    if (this.queue) {

      if (
        this.isAudioSource ||
        (
          this.active(BAS_SOURCE.COL_EVT_SIMPLE) ||
          this.active(BAS_SOURCE.COL_EVT_PLAYER)
        )
      ) {
        // If isAudioSource: always handle queue events to keep queue up-to-date
        //  else: respect active events
        this.queue.handleQueueMoved(data)
        this._debouncedApplyAsync()
      }
    }

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handleQueueChanged()

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onQueueRemoved = function (data) {

    if (this.queue) {

      if (
        this.isAudioSource ||
        (
          this.active(BAS_SOURCE.COL_EVT_SIMPLE) ||
          this.active(BAS_SOURCE.COL_EVT_PLAYER)
        )
      ) {
        // If isAudioSource: always handle queue events to keep queue up-to-date
        //  else: respect active events
        this.queue.handleQueueRemoved(data)
        this._debouncedApplyAsync()
      }
    }

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handleQueueChanged()

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onQueueAdded = function (data) {

    if (this.queue) {

      if (this.isAudioSource) {

        // Always handle queue events to keep queue up to date, no need to sync
        //  song info or cover-art since that is based on 'status' property now.

        this.queue.handleQueueAdded(data)
        this._debouncedApplyAsync()

      } else if (
        this.active(BAS_SOURCE.COL_EVT_SIMPLE) ||
        this.active(BAS_SOURCE.COL_EVT_PLAYER)
      ) {

        // Legacy

        this.queue.handleQueueAdded(data)

        this._syncSourceInfo()
        this._syncCoverArt()

        this._debouncedApplyAsync()
      }
    }

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) this.state.handleQueueChanged()

      this._debouncedApplyAsync()
    }
  }

  BasSource.prototype._onDefaultRoomsChanged = function () {

    if (this.active(BAS_SOURCE.COL_EVT_DEFAULT_ROOMS)) {

      if (this.defaultRooms) this.defaultRooms.sync()
    }
  }

  BasSource.prototype._onPlayback = function () {

    this.paused = this.source.paused

    this._syncNowPlayingIcon()

    // For now just use the paused state of source, maybe later we can
    //  visualize more playback states such as idle, buffering or unknown.

    if (this.active(BAS_SOURCE.COL_EVT_PLAYER)) {

      if (this.state) {
        this.state.handlePaused(this.source.paused)
        this.state.handlePlayback(this.source.playbackMode)
      }
    }

    this._debouncedApplyAsync()
  }

  BasSource.prototype._onDeezerLinked = function () {

    if (this.active(BAS_SOURCE.COL_EVT_DEEZER) ||
      this.active(BAS_SOURCE.COL_EVT_LIBRARY)) {

      if (this.deezer) this.deezer.handleLinkChanged()
    }

    if (this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      if (this.favourites) this.favourites.handleDeezerLinked()
    }
  }

  BasSource.prototype._onDeezerLinkFinished = function () {

    if (this.deezer) this.deezer.handleLinkFinished()
  }

  BasSource.prototype._onDeezerLinkError = function () {

    if (this.deezer) this.deezer.handleLinkError()
  }

  BasSource.prototype._onTidalLinked = function () {

    if (this.active(BAS_SOURCE.COL_EVT_TIDAL) ||
      this.active(BAS_SOURCE.COL_EVT_LIBRARY)) {

      if (this.tidal) this.tidal.handleLinkChanged()
    }

    if (this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      if (this.favourites) this.favourites.handleTidalLinked()
    }
  }

  BasSource.prototype._onSpotifyTokenChanged = function () {

    if (this.active(BAS_SOURCE.COL_EVT_SPOTIFY) ||
      this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      if (this.spotify) this.spotify.handleTokenChanged()
    }
  }

  BasSource.prototype._onSpotifyPresetsChanged = function () {

    var basSource

    if (this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      /**
       * @type {BasSource}
       */
      basSource = SourcesHelper.getPlayer(this.id)

      if (basSource && basSource.favourites) {

        basSource.favourites.handleSpotifyPresetsChanged()
      }
    }
  }

  BasSource.prototype._onClientChanged = function () {

    var basSource

    if (this.active(BAS_SOURCE.COL_EVT_SPOTIFY) ||
      this.active(BAS_SOURCE.COL_EVT_FAVOURITES)) {

      /**
       * @type {BasSource}
       */
      basSource = SourcesHelper.getPlayer(this.id)

      if (this.spotify) this.spotify.handleClientName()

      if (basSource && basSource.favourites) {

        basSource.favourites.handleSpotifyClientChanged()
      }
    }
  }

  BasSource.prototype._onKNXPresetsChanged = function () {

    if (this.active(BAS_SOURCE.COL_EVT_KNX_PRESETS)) {

      if (this.knxPresets) this.knxPresets.handlePresetsChanged()
    }
  }

  // endregion

  /**
   * @private
   */
  BasSource.prototype._debouncedApplyAsync = function () {

    clearTimeout(this._applyAsyncTimeoutId)

    this._applyAsyncTimeoutId = setTimeout(
      $rootScope.$applyAsync,
      APPLY_ASYNC_DEBOUNCE
    )
  }

  BasSource.prototype.updateTranslation = function () {

    this._syncName()
    if (this.tunein) this.tunein.updateTranslation()
    if (this.defaultRooms) this.defaultRooms.updateTranslation()
    if (this.favourites) this.favourites.updateTranslation()
    if (this.queue) this.queue.updateTranslation()
  }

  BasSource.prototype.onRoomsUpdated = function () {

    this._syncCompatibleRooms()
    this._syncName()
  }

  BasSource.prototype.handleVideoButton = function (button, active) {

    var action

    if (this.isVideoSource) {

      action = this._getButtonAction(button)

      if (BasUtil.isNEString(action)) {

        return this.source.pressGenericButton(
          action,
          active
        )
      }

      return Promise.reject('Unknown button ' + button)
    }

    return Promise.reject('Source is not a video source')
  }

  BasSource.prototype.handleCustomVideoButton = function (buttonUri, active) {

    if (this.isVideoSource) {

      if (BasUtil.isNEString(buttonUri)) {

        return this.source.pressCustomButton(
          buttonUri,
          active
        )
      }

      return Promise.reject('Invalid button uri ' + buttonUri)
    }

    return Promise.reject('Source is not a video source')
  }

  BasSource.prototype._getButtonAction = function (button) {

    var action = videoButtonMap[button]

    if (BasUtil.isNEString(action)) return action

    return ''
  }

  /**
   * @returns {string[]}
   */
  BasSource.prototype.getStreamingServices = function () {

    return this.isAudioSource
      ? this.source.possibleStreamingServices
      : []
  }

  BasSource.prototype._isPlayingWithPrefixOrSubtype = function (
    uriPrefix,
    subtype
  ) {

    var currentSong = this._getCurrentSong()

    return (
      currentSong &&
      BasUtil.startsWith(
        currentSong.uri,
        uriPrefix
      )
    ) || this.subType === subtype
  }

  BasSource.prototype._syncIsPlaying = function () {

    this.isPlayingDeezer = this._isPlayingWithPrefixOrSubtype(
      BAS_SOURCE.URI_PREFIX_DEEZER,
      BAS_SOURCE.ST_DEEZER
    )
    this.isPlayingLocal = this._isPlayingWithPrefixOrSubtype(
      BAS_SOURCE.URI_PREFIX_LOCAL,
      BAS_SOURCE.ST_LOCAL
    )
    this.isPlayingAirplay = this._isPlayingWithPrefixOrSubtype(
      BAS_SOURCE.URI_PREFIX_AAP,
      BAS_SOURCE.ST_AAP
    )
    this.isPlayingSpotify = this._isPlayingWithPrefixOrSubtype(
      BAS_SOURCE.URI_PREFIX_SPOTIFY,
      BAS_SOURCE.ST_SPOTIFY
    )
    this.isPlayingTidal = this._isPlayingWithPrefixOrSubtype(
      BAS_SOURCE.URI_PREFIX_TIDAL,
      BAS_SOURCE.ST_TIDAL
    )
    this.isPlayingTunein = this._isPlayingWithPrefixOrSubtype(
      BAS_SOURCE.URI_PREFIX_TUNEIN,
      BAS_SOURCE.ST_TUNEIN
    )
  }

  /**
   * @private
   */
  BasSource.prototype._syncCompatibleRooms = function () {

    var compatibleRooms, roomUuid

    compatibleRooms = []
    roomUuid = this.uuid

    RoomsHelper.forEachRoom(_onRoomCheckCompatibleSources)

    if (!BasUtil.isEqualArray(this.compatibleRooms, compatibleRooms)) {

      this.compatibleRooms = compatibleRooms

      $rootScope.$emit(
        BAS_SOURCE.EVT_COMPATIBLE_ROOMS_CHANGE,
        this.uuid
      )
    }

    /**
     * @param {BasRoom} room
     */
    function _onRoomCheckCompatibleSources (room) {

      if (
        room.hasAVMusic() &&
        room.music &&
        room.music.getCompatibleSources().indexOf(roomUuid) !== -1
      ) {

        compatibleRooms.push(room.id)
      }
    }
  }

  /**
   * Fills in 'this.ui.songTitleArtist' based on this.ui.songArtist and
   *  this.ui.songTitle
   *
   * @private
   */
  BasSource.prototype._generateUiSongTitleArtist = function () {

    var elements = []

    if (this.ui.songArtist) elements.push(this.ui.songArtist)
    if (this.ui.songTitle) elements.push(this.ui.songTitle)

    this.ui.songTitleArtist = elements.join(' - ')
  }

  /**
   * @param {string} roomId
   */
  BasSource.prototype.onRoomSourceChanged = function (roomId) {

    if (
      this.isAudioSource &&
      this.source &&
      this.source.followRoomNameUuid === roomId
    ) {

      this._syncName()
    }
  }

  /**
   * Sync song info subtitle based on the roomUuid of an audio-source.
   *
   * @private
   * @returns {boolean}
   */
  BasSource.prototype.syncRoomSourceSubtitle = function () {

    var room

    if (
      this.isAudioSource &&
      this.source &&
      this.source.followRoomNameUuid
    ) {

      this._resetSongInfo()

      room = RoomsHelper.getRoomForId(this.source.followRoomNameUuid)

      if (room) {

        if (
          room.video &&
          room.video.basSource
        ) {

          this.ui.songTitle = room.video.basSource.name
        }
      }

      this._generateUiSongTitleArtist()

      return true
    }

    return false
  }

  /**
   * Simple clone, does not clone event collections or listeners
   *
   * @returns {BasSource}
   */
  BasSource.prototype.clone = function () {

    var constructorSource

    if (this.source) {

      constructorSource = this.source

    } else if (BasUtil.isVNumber(this.id)) {

      constructorSource = this.id
    }

    return new BasSource(constructorSource)
  }

  BasSource.prototype.suspend = function () {

    this._clearSourceListeners()
    this._clearSourceInfoTimer()

    if (this.favourites && this.favourites.suspend) {

      this.favourites.suspend()
    }
  }

  /**
   * @private
   */
  BasSource.prototype._clearSourceInfoTimer = function () {

    $timeout.cancel(this._syncSourceInfoTimeout)
  }

  /**
   * @private
   */
  BasSource.prototype._destroyPlayerComponents = function () {

    BasUtil.executeFunction(this.tunein, METHOD_DESTROY)
    BasUtil.executeFunction(this.deezer, METHOD_DESTROY)
    BasUtil.executeFunction(this.tidal, METHOD_DESTROY)
    BasUtil.executeFunction(this.queue, METHOD_DESTROY)
    BasUtil.executeFunction(this.favourites, METHOD_DESTROY)
    BasUtil.executeFunction(this.playlists, METHOD_DESTROY)
    BasUtil.executeFunction(this.database, METHOD_DESTROY)
    BasUtil.executeFunction(this.defaultRooms, METHOD_DESTROY)
    BasUtil.executeFunction(this.knxPresets, METHOD_DESTROY)
  }

  /**
   * @private
   */
  BasSource.prototype._destroySpotifyComponents = function () {

    BasUtil.executeFunction(this.spotify, METHOD_DESTROY)
  }

  /**
   * @private
   */
  BasSource.prototype._destroyAllComponents = function () {

    this._destroyPlayerComponents()
    this._destroySpotifyComponents()
  }

  /**
   * @private
   */
  BasSource.prototype._clearPlayerComponents = function () {

    this._destroyPlayerComponents()
    this.tunein = null
    this.deezer = null
    this.tidal = null
    this.queue = null
    this.favourites = null
    this.playlists = null
    this.database = null
    this.defaultRooms = null
    this.knxPresets = null
  }

  /**
   * @private
   */
  BasSource.prototype._clearSpotifyComponents = function () {

    this._destroySpotifyComponents()
    this.spotify = null
  }

  /**
   * Destroy and clear all subcomponents
   *
   * @private
   */
  BasSource.prototype._clearAllComponents = function () {

    this._destroyAllComponents()
    this._clearPlayerComponents()
    this._clearSpotifyComponents()
  }

  /**
   * Clear all source listeners
   */
  BasSource.prototype._clearSourceListeners = function () {

    BasUtil.executeArray(this._sourceListeners)

    this._sourceListeners = []
  }

  /**
   * Clear the source and its listeners
   */
  BasSource.prototype.clearSource = function () {

    this._clearSourceListeners()

    this.source = null
  }

  /**
   * Makes the BasSource instance ready for garbage collection
   */
  BasSource.prototype.destroy = function () {

    this._clearSourceInfoTimer()
    this._destroyAllComponents()

    this.clearSource()
  }

  // endregion

  return BasSource

  function _ignoreButLog (error) {

    $window['Sentry']?.captureException(error)
  }
}
