'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('favouritesPickerModalCtrl', [
    '$scope',
    'BAS_SOURCES',
    'LibraryState',
    'close',
    favouritesPickerModalCtrl
  ])

/**
 * @param $scope
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {LibraryState} LibraryState
 * @param close
 */
function favouritesPickerModalCtrl (
  $scope,
  BAS_SOURCES,
  LibraryState,
  close
) {

  var modal = this

  var _listeners = []

  /**
   * @type {TLibraryState}
   */
  modal.libraryState = LibraryState.get()

  /**
   * @type {BAS_SOURCES}
   */
  modal.BAS_SOURCES = BAS_SOURCES

  modal.favourites = ['', '', '', '']
  modal.currentFavourite = 0

  modal.close = close

  modal.chooseSpot = chooseSpot
  modal.select = select
  modal.remove = remove

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _syncUiFavourites()
  }

  /**
   * @param {number} index
   */
  function chooseSpot (index) {

    modal.currentFavourite = index
  }

  /**
   * @param {string} uuid
   */
  function select (uuid) {

    var i, length

    // Remove doubles

    length = modal.favourites.length
    for (i = 0; i < length; i++) {

      if (modal.favourites[i] === uuid) modal.favourites[i] = ''
    }

    // Set favourite

    modal.favourites[modal.currentFavourite] = uuid

    _saveFavourites()
  }

  function remove () {

    modal.favourites[modal.currentFavourite] = ''

    _saveFavourites()
  }

  function _syncUiFavourites () {

    var source, i, favourites, favouriteUuid

    _resetModalFavourites()

    source = _getSource()

    if (source && source.favourites) {

      favourites = source.favourites.savedQuickFavourites

      if (Array.isArray(favourites)) {

        for (i = 0; i < 4; i++) {

          favouriteUuid = favourites[i]

          if (favouriteUuid) modal.favourites[i] = favouriteUuid
        }
      }
    }
  }

  function _saveFavourites () {

    var source

    source = _getSource()

    if (source) {

      if (
        !BasUtil.isEqualArray(
          modal.favourites,
          source.favourites.savedQuickFavourites
        )
      ) {
        source.setQuickFavourites(modal.favourites)
      }
    }
  }

  /**
   * @private
   * @returns {null|BasSource}
   */
  function _getSource () {

    var source

    source = LibraryState.getCurrentSource()

    if (source && source.isAudioSource) {

      return source
    }

    return null
  }

  function _resetModalFavourites () {

    modal.favourites = ['', '', '', '']
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
