'use strict'

angular
  .module('basalteApp')
  .constant('BAS_ROOM', {
    ID_SEPARATOR: '|',
    UI_SEPARATOR: ' - ',
    EVT_SOURCE_CHANGED: 'evtRoomSourceChanged',
    EVT_ACTIVITY_CHANGED: 'evtRoomActivityChanged',
    EVT_ACTIVITY_LIGHTS: 'evtRoomActivityLights',
    EVT_AVAILABLE_CHANGED: 'evtRoomAvailable',
    K_PROPERTY_TILES: 'propertyTiles',
    K_PROPERTY_TILES_ROOM_SCENES: 'propertyTilesRoomScenes',
    VAR_AREA: 'area',
    VAR_ROOM: 'room',
    VAR_MUSIC_GROUP: 'musicGroup',
    VAR_AV_GROUP: 'avGroup',
    MUSIC_T_ASANO: 'asano',
    MUSIC_T_SONOS: 'sonos',
    MUSIC_T_AVR: 'avr',
    MUSIC_T_OTHER: 'other',
    VIDEO_T_ASANO: 'video',
    F_AV_AUDIO: 'avAudio',
    F_AV_VIDEO: 'avVideo',
    K_AUDIO: 'audio',
    K_VIDEO: 'video',
    DSP_GAIN: 'gain',
    DSP_ID: 'id',
    EVT_BAS_SCENE_REMOVED: 'basSceneRemoved',
    EVT_SCENES_INITIALIZED: 'homeScenesInit',
    EVT_SCENES_UPDATED: 'evtBasRoomScenesScenesUpdated',
    EVT_BAS_JOB_UPDATED: 'evtBasRoomBasJobUpdated',
    EVT_BAS_JOB_REMOVED: 'evtBasRoomBasJobRemoved',
    EVT_HOME_JOBS_INITIALIZED: 'homeJobsInit',
    EVT_HOME_TIMERS_INITIALIZED: 'homeTimersInit',
    EVT_SCENE_STEP_DEVICES_ADDED: 'sceneStepDevicesAdded',
    EVT_ENERGY_ADDED: 'energyAdded',
    EVT_LIGHTS_INITIALIZED: 'lightsInit',
    EVT_THERMOSTATS_INITIALIZED: 'thermostatsInit',
    EVT_SHADES_INITIALIZED: 'shadesInit',
    EVT_GENERIC_DEVICES_INITIALIZED: 'genericDevicesInit',
    EVT_OPEN_CLOSE_DEVICES_INITIALIZED: 'openCloseDevicesInit',
    EVT_ENERGY_INITIALIZED: 'energyInit',
    EVT_OPEN_CLOSE_DEVICES_UPDATED: 'openCloseDevicesUpdated',
    EVT_CORE_CLIENTS_UPDATED: 'roomCoreClientsUpdated',
    EVT_SECURITY_UPDATED: 'roomSecurityUpdated',
    EVT_DOOR_PHONE_GATEWAYS_UPDATED: 'roomDoorPhoneGatewaysUpdated',
    EVT_DOOR_PHONES_UPDATED: 'roomDoorPhonesUpdated',
    EVT_WEATHER_STATIONS_UPDATED: 'roomWeatherStationsUpdated',
    EVT_IMAGES_UPDATED: 'roomImagesUpdated',
    EVT_FUNCTION_INPUT: 'roomFunctionInput'
  })
