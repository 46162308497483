'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasMessages', [
    '$rootScope',
    'APP_CONFIG',
    'BAS_CURRENT_CORE',
    'BAS_MESSAGES',
    'CurrentBasCore',
    'BasMessage',
    BasMessages
  ])

/**
 * @typedef {Object} TMessagesState
 * @property {Object} css
 * @property {BasMessage[]} messages
 */

/**
 * Service which handles messages from the server
 *
 * @constructor
 * @param $rootScope
 * @param APP_CONFIG
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_MESSAGES} BAS_MESSAGES
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasMessage
 */
function BasMessages (
  $rootScope,
  APP_CONFIG,
  BAS_CURRENT_CORE,
  BAS_MESSAGES,
  CurrentBasCore,
  BasMessage
) {
  var CSS_MESSAGE_SHOW = 'bas-message-show'

  var VERSION = APP_CONFIG.major + '.' +
    APP_CONFIG.minor + '.' +
    APP_CONFIG.patch

  var currentBuild = new BasUtil.BasVersion(VERSION)

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TMessagesState}
   */
  var state = {}

  _resetState()

  this.removeMessage = removeMessage
  this.getMessage = getMessage
  this.get = get
  this.clear = clear

  init()

  function init () {

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MESSAGES_UPDATED,
      _onMessagesUpdated
    )
    $rootScope.$on(
      '$translateChangeSuccess',
      _updateTranslations
    )

    syncMessages()
  }

  function _onMessagesUpdated () {

    syncMessages()
  }

  function _updateTranslations () {

    var length, i

    length = state.messages.length
    for (i = 0; i < length; i++) {

      state.messages[i].updateTranslations()
    }
  }

  function syncMessages () {

    var i, length, messages, message

    _resetState()

    if (CurrentBasCore.hasCore()) {

      messages = currentBasCoreState.core.core.system.messages
      length = messages.length

      for (i = 0; i < length; i++) {

        message = messages[i]

        if (compareVersion(message.version) &&
          BasUtil.isNEString(message.uuid)) {

          addMessage(message)
        }
      }
    }

    $rootScope.$applyAsync()
    $rootScope.$emit(BAS_MESSAGES.EVT_MESSAGES_UPDATED)
  }

  /**
   * @param {Object} version
   * @param {BasUtil.BasVersion} version.min
   * @param {BasUtil.BasVersion} version.max
   * @returns {boolean}
   */
  function compareVersion (version) {

    var valid = true

    if (BasUtil.isObject(version.min) &&
      BasUtil.BasVersion.compare(currentBuild, version.min) === -1) {

      valid = false
    }

    if (BasUtil.isObject(version.max) &&
      BasUtil.BasVersion.compare(currentBuild, version.max) === 1) {

      valid = false
    }

    return valid
  }

  function removeMessage (uuid) {

    var index
    var length = state.messages.length

    if (length === 0) return

    if (length === 1) {

      _resetState()
      // TODO send server a message he can delete this message

    } else {

      index = _getMessageIndex(uuid)
      if (index !== -1) state.messages.splice(index, 1)
      // TODO send server a message he can delete this message
    }
  }

  /**
   * @param {Message} message
   */
  function addMessage (message) {

    /**
     * @type {BasMessage}
     */
    var object

    if (BasUtil.isObject(message) &&
      BasUtil.isNEString(message.uuid)) {

      object = new BasMessage(message)

      state.messages.push(object)
      state.css[CSS_MESSAGE_SHOW] = true
    }
  }

  function _resetState () {

    state.messages = []
    state.css = {}
    state.css[CSS_MESSAGE_SHOW] = false
  }

  /**
   * @param {string} uuid
   * @returns {?BasMessage}
   */
  function getMessage (uuid) {

    var i, length

    length = state.messages.length
    for (i = 0; i < length; i++) {

      if (state.messages[i].uuid === uuid) return state.messages[i]
    }

    return null
  }

  /**
   * @param {string} uuid
   * @returns {number}
   */
  function _getMessageIndex (uuid) {

    var i, length

    length = state.messages.length
    for (i = 0; i < length; i++) {

      if (state.messages[i].uuid === uuid) return i
    }

    return -1
  }

  /**
   * @returns {TMessagesState}
   */
  function get () {
    return state
  }

  function clear () {

    _resetState()
  }
}
