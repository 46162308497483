'use strict'

angular
  .module('basalteApp')
  .service('PlayerStateHelper', [
    'BAS_ROOM',
    'BAS_SOURCE',
    'PLAYER_STATE',
    PlayerStateHelper
  ])

/**
 * @constructor
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {PLAYER_STATE} PLAYER_STATE
 */
function PlayerStateHelper (
  BAS_ROOM,
  BAS_SOURCE,
  PLAYER_STATE
) {

  this.getPlayerState = getPlayerState

  /**
   * @private
   * @param {?BasRoom} basRoom
   * @param {boolean} isVideoContext
   * @returns {number}
   */
  function getPlayerState (basRoom, isVideoContext) {

    var ctxBasSource, music, video

    if (basRoom && basRoom.getCurrentBasSource) {

      music = basRoom && basRoom.hasMusic() ? basRoom.music : null
      video = basRoom && basRoom.hasVideo() ? basRoom.video : null

      ctxBasSource = isVideoContext
        ? video ? video.basSource : null
        : music ? music.basSource : null
      ctxBasSource = ctxBasSource && ctxBasSource.play ? ctxBasSource : null

      if (isVideoContext) {

        if (video && !video.isAvailable) {

          return PLAYER_STATE.VIDEO_UNAVAILABLE
        }

        // Video off -> consider as empty source, even if a source is selected,
        //  since that is not a valid state
        if (video && !video.on) {

          return PLAYER_STATE.SOURCE_EMPTY
        }

      } else {

        if (music && !music.isAvailable) {

          return music.type === BAS_ROOM.MUSIC_T_SONOS
            ? PLAYER_STATE.SONOS_UNREACHABLE
            : PLAYER_STATE.MUSIC_UNAVAILABLE
        }

        // If Sonos music is off and source is not empty, return playerState OFF
        if (
          music &&
          music.type === BAS_ROOM.MUSIC_T_SONOS &&
          !music.on &&
          (
            !music.basSource ||
            music.basSource.type !== BAS_SOURCE.T_EMPTY
          )
        ) {

          return PLAYER_STATE.OFF
        }
      }

      if (ctxBasSource) {

        switch (ctxBasSource.type) {
          case BAS_SOURCE.T_PLAYER:
            if (
              ctxBasSource.source &&
              ctxBasSource.source.queue &&
              ctxBasSource.source.queue.dirty
            ) {
              // Queue is not retrieved yet
              return PLAYER_STATE.NORMAL
            }
            if (
              ctxBasSource.queue &&
              ctxBasSource.queue.length > 0
            ) {
              return ctxBasSource.state
                ? ctxBasSource.state.hasSong
                  ? PLAYER_STATE.NORMAL
                  : PLAYER_STATE.QUEUE_END
                // Should not occur
                : PLAYER_STATE.NORMAL
            }
            return PLAYER_STATE.QUEUE_EMPTY
          case BAS_SOURCE.T_SONOS:
          case BAS_SOURCE.T_ASANO:

            if (!ctxBasSource.available) {
              return PLAYER_STATE.SOURCE_UNAVAILABLE
            }
            if (ctxBasSource.queue) {
              return ctxBasSource.state
                ? ctxBasSource.state.hasSong
                  ? PLAYER_STATE.NORMAL
                  : (
                      ctxBasSource.source &&
                      ctxBasSource.source.statusIsEndOfQueue
                    )
                      ? PLAYER_STATE.QUEUE_END
                      : PLAYER_STATE.QUEUE_EMPTY
                // Should not occur
                : PLAYER_STATE.NORMAL
            }
            return PLAYER_STATE.NORMAL
          case BAS_SOURCE.T_VIDEO:
            return PLAYER_STATE.NORMAL
          case BAS_SOURCE.T_BARP:
          case BAS_SOURCE.T_EXTERNAL:
          case BAS_SOURCE.T_BLUETOOTH:
          case BAS_SOURCE.T_NOTIFICATION:
          case BAS_SOURCE.T_AUDIO_ALERT:
          case BAS_SOURCE.T_UNKNOWN_ID:
          case BAS_SOURCE.T_MIXED:
            return PLAYER_STATE.NORMAL
          case BAS_SOURCE.T_EMPTY:
            if (
              !isVideoContext &&
              music &&
              music.type === BAS_ROOM.MUSIC_T_SONOS
            ) {
              return PLAYER_STATE.SONOS_UNREACHABLE
            }
            return PLAYER_STATE.SOURCE_EMPTY
          case BAS_SOURCE.T_AV_INPUT_UNKNOWN:
            return PLAYER_STATE.UNKNOWN_AV
          case BAS_SOURCE.T_AV_INPUT_NONE:
            return PLAYER_STATE.NO_AV
          default:
            if (
              !isVideoContext &&
              music &&
              music.type === BAS_ROOM.MUSIC_T_SONOS
            ) {
              return PLAYER_STATE.SOURCE_UNAVAILABLE
            }
            return PLAYER_STATE.SOURCE_UNKNOWN
        }

      } else {

        return isVideoContext
          // Video: source is null instead of a source with type T_EMPTY
          ? PLAYER_STATE.SOURCE_EMPTY
          // Otherwise: null source is considered as unknown
          : PLAYER_STATE.SOURCE_UNKNOWN
      }

    } else {

      return PLAYER_STATE.ZONE_EMPTY
    }
  }
}
