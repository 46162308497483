'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basSceneImagePicker', [
    'BAS_HTML',
    basSceneImagePicker
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basSceneImagePicker
 */
function basSceneImagePicker (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.sceneImagePicker,
    controller: [
      '$window',
      '$scope',
      '$uiRouterGlobals',
      'ICONS',
      'BAS_IMAGE',
      'BasImage',
      'BasImageTrans',
      'BasSceneHelper',
      controller
    ],
    controllerAs: 'sceneImagePicker',
    bindToController: {
      sceneId: '<',
      close: '&?'
    }
  }

  /**
   * @param $window
   * @param $scope
   * @param $uiRouterGlobals
   * @param {ICONS} ICONS
   * @param {BAS_IMAGE} BAS_IMAGE
   * @param BasImage
   * @param BasImageTrans
   * @param {BasSceneHelper} BasSceneHelper
   */
  function controller (
    $window,
    $scope,
    $uiRouterGlobals,
    ICONS,
    BAS_IMAGE,
    BasImage,
    BasImageTrans,
    BasSceneHelper
  ) {

    var sceneImagePicker = this

    var basTm = $window.basTModule

    /**
     * @type {number}
     */
    var _closeTimeoutId = 0

    sceneImagePicker.getImage = getImage
    sceneImagePicker.getClearImage = getClearImage
    sceneImagePicker.onImageApproved = onImageApproved
    sceneImagePicker.onRevertApproved = onRevertApproved
    sceneImagePicker.close = _close

    sceneImagePicker.biImgOpts = {
      customClass: [
        BAS_IMAGE.C_BG_COVER
      ]
    }

    sceneImagePicker.biSvgOpts = {
      customClass: [
        BAS_IMAGE.C_BG_CONTAIN,
        BAS_IMAGE.C_COLOR_LIGHT_CONTROL,
        BAS_IMAGE.C_SIZE_100
      ]
    }

    sceneImagePicker.defaultIcon = ICONS.defaultTiles

    sceneImagePicker.oldBitTile = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: getClearImage()
    })

    sceneImagePicker.clearOption = false

    init()

    function init () {

      var scene, _basImage

      scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

      if (scene) {

        if (scene.customImages) {

          sceneImagePicker.clearOption = true

          _basImage = new BasImage('', sceneImagePicker.biImgOpts)
          _basImage.setMultipleUrls(
            scene.customImages
          )
          sceneImagePicker.oldBitTile.setImage(_basImage)

        } else {

          sceneImagePicker.clearOption = false

          sceneImagePicker.oldBitTile.setImage(getClearImage())
        }
      }

      $scope.$on('$destroy', _onDestroy)
    }

    /**
     * Format image to your liking.
     * A basImage is returned to the picker
     *
     * @param {string} imageDataURI
     * @returns {BasImage}
     */
    function getImage (imageDataURI) {

      return new BasImage(imageDataURI, sceneImagePicker.biImgOpts)
    }

    /**
     * Function is called when user approved the selected image
     * Save in unsavedImage prop
     * Send to server when scene is saved
     *
     * @param {string} image
     * @param {string} imageDataURI
     */
    function onImageApproved (image, imageDataURI) {

      var scene

      scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

      if (scene) {

        if (basTm) {

          basTm.logEvt({
            evtType: basTm.T_APP_IMAGE_PICKER,
            evtSubType: 'scene'
          })
        }

        scene.setUnsavedImage(image, imageDataURI)
      }

      _closeTimeoutId = setTimeout(_close, 500)
    }

    /**
     * Returns image that would be shown is no images are set
     *
     * @returns {?BasImage}
     */
    function getClearImage () {

      var scene

      scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

      if (scene) {

        return new BasImage(
          sceneImagePicker.defaultIcon,
          sceneImagePicker.biSvgOpts
        )
      }

      return null
    }

    /**
     * Function is called when user approved the 'clear' image
     * Save in unsavedImage prop
     * Send to server when scene is saved
     */
    function onRevertApproved () {

      var scene

      scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

      if (scene) {

        scene.setUnsavedImage(null, null)
      }

      _closeTimeoutId = setTimeout(_close, 500)
    }

    function _close () {

      clearTimeout(_closeTimeoutId)

      if (BasUtil.isFunction(sceneImagePicker.close)) {

        sceneImagePicker.close()
      }
    }

    function _onDestroy () {

      clearTimeout(_closeTimeoutId)
    }
  }
}
