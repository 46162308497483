'use strict'

angular
  .module('basalteApp')
  .factory('BasDoorPhoneGateway', [
    'BAS_API',
    basDoorPhoneGatewayFactory
  ])

function basDoorPhoneGatewayFactory (BAS_API) {

  /**
   * @constructor
   * @param {DoorPhoneGatewayDevice} device
   */
  function BasDoorPhoneGateway (device) {

    /**
     * @type {?DoorPhoneGatewayDevice}
     */
    this.device = null

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.ip = ''

    /**
     * @type {string}
     */
    this.host = ''

    /**
     * @type {number}
     */
    this.port = 5060

    /**
     * @type {string}
     */
    this.dtmfDoor = ''

    /**
     * @type {string}
     */
    this.dtmfLight = ''

    if (device) this.parseDoorPhoneGatewayDevice(device)
  }

  BasDoorPhoneGateway.prototype.parseDoorPhoneGatewayDevice = function (
    device
  ) {
    if (device instanceof BAS_API.DoorPhoneGatewayDevice) {

      this.device = device
      this.uuid = this.device.uuid

      this.syncDevice()
    }
  }

  /**
   * @param {DoorPhoneGatewayDevice} [device]
   */
  BasDoorPhoneGateway.prototype.syncDevice = function (device) {

    var _device = device || this.device

    if (_device) {

      this.ip = _device.ip
      this.host = _device.host
      this.port = _device.port
      this.dtmfDoor = _device.dtmfDoor
      this.dtmfLight = _device.dtmfLight
    }
  }

  /**
   * Clears the device reference
   */
  BasDoorPhoneGateway.prototype.clear = function () {

    this.device = null
  }

  return BasDoorPhoneGateway
}
