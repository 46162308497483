'use strict'

angular
  .module('basalteApp')
  .constant('BAS_TIDAL', {
    BASE_URL: 'https://api.tidal.com',
    IMG_URL: 'https://resources.tidal.com/images/',
    PATH_VERSION: 'v1',
    PATH_USERS: 'users',
    PATH_PLAYLISTS: 'playlists',
    H_AUTHORIZATION: 'Authorization',
    H_SESSION_ID: 'X-Tidal-SessionId',
    P_COUNTRY_CODE: 'countryCode',
    IMG_SIZE_PLAYLIST_SMALL: '160x107',
    IMG_SQUARE_SIZE_PLAYLIST_SMALL: '160x160',
    K_TITLE: 'title',
    K_NUMBER_OF_TRACKS: 'numberOfTracks',
    K_IMAGE: 'image',
    K_ORDER: 'order',
    K_DIRECTION: 'orderDirection',
    DIREC_DESC: 'DESC',
    ORDER_DATE: 'DATE',
    TOT_NOI: 'totalNumberOfItems',
    K_ITEMS: 'items',
    HEADER_POST: 'application/x-www-form-urlencoded',
    CONTENT_TYPE: 'Content-Type',
    ETAG: 'etag',
    IF_NONE_MATCH: 'If-None-Match',
    OUTDATED_CODE: 412,
    ERR_OUTDATED_TAG: 'Outdated eTag',
    FAV_ALBUMS: 'albums',
    FAV_ARTISTS: 'artists',
    FAV_TRACKS: 'tracks',
    FAV_PLAYLISTS: 'playlists',
    K_SQUARE_IMAGE: 'squareImage',
    TIDAL_MAX_COLLECTION_RETRIEVE_LENGTH: 50
  })
