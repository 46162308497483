import ResizeObserver from 'resize-observer-polyfill'

angular
  .module('basalteApp')
  .directive('basCollapsible', basCollapsibleDirective)

function basCollapsibleDirective () {
  return {
    restrict: 'A',
    controller: [
      '$element',
      'BasUtilities',
      basCollapsibleController
    ],
    bindToController: {
      collapsed: '<',
      horizontal: '<'
    },
    controllerAs: 'basCollapsible'
  }

  function basCollapsibleController (
    $element,
    BasUtilities
  ) {
    const basCollapsible = this
    const resizeObserverList = []

    basCollapsible.$onChanges = _onChanges
    basCollapsible.$postLink = _onPostLink
    basCollapsible.$onDestroy = _onDestroy

    function _onChanges () {
      BasUtilities.waitForFrames(5, _onWaitForFrames)
    }

    function _onPostLink () {
      _onWaitForFrames()

      // Important: add 'util-collapsible' class to use this util
      // 1. Use $element to find its children
      // 2. Get the classNames of the children
      // 3. Get string that contains 'util-collapsible'
      const elementChildren = $element[0].children

      for (const item of elementChildren) {
        const resizeObserver = _createNewResizeObserver()

        resizeObserver.observe(item)
        resizeObserverList.push(resizeObserver)
      }
    }

    function _onDestroy () {
      for (const resizeObserver of resizeObserverList) {
        resizeObserver.disconnect()
      }
    }

    function _onWaitForFrames () {
      if (basCollapsible.collapsed) {

        basCollapsible.horizontal
          ? $element[0].style.width = 0 + 'px'
          : $element[0].style.height = 0 + 'px'

        $element[0].style.overflow = 'hidden'
      } else {

        _setWidthOrHeightOfElement()
      }
    }

    function _createNewResizeObserver () {
      return new ResizeObserver(_entries => {

        if (!basCollapsible.collapsed) {
          _setWidthOrHeightOfElement()
        }
      })
    }

    function _setWidthOrHeightOfElement () {
      basCollapsible.horizontal
        ? $element[0].style.width =
            $element[0].children[0].clientWidth + 'px'
        : $element[0].style.height =
            $element[0].children[0].clientHeight + 'px'
    }
  }
}
