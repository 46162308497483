'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('streamingSettingsCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_STATE',
    'BAS_CURRENT_CORE',
    'BAS_STREAMING',
    'BAS_SOURCES',
    'BasState',
    'CurrentBasCore',
    streamingSettingsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_STREAMING} BAS_STREAMING
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BasState} BasState
 * @param {CurrentBasCore} CurrentBasCore
 */
function streamingSettingsCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_STATE,
  BAS_CURRENT_CORE,
  BAS_STREAMING,
  BAS_SOURCES,
  BasState,
  CurrentBasCore
) {
  var streaming = this

  var _listeners = []

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  streaming.uiCanNavigate = false
  streaming.uiHasSub = false
  streaming.uiSubtitle = ''

  streaming.streamingServices = []

  streaming.selectStreamingService = selectStreamingService
  streaming.back = back

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_STATE.EVT_STATE_SUCCESS,
      _onStateSuccess
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _onVariables
    ))
    _listeners.push($rootScope.$on(
      BAS_SOURCES.EVT_SOURCES_UPDATED,
      _onSourcesUpdated
    ))

    _generateStreamingServiceOptions()
    _syncUi()
  }

  /**
   * @param {string} stream
   */
  function selectStreamingService (stream) {

    switch (stream) {
      case BAS_STREAMING.T_ID_DEEZER:
        BasState.go(STATES.SETTINGS_MUSIC_STREAMING_DEEZER)
        break
      case BAS_STREAMING.T_ID_TIDAL:
        BasState.go(STATES.SETTINGS_MUSIC_STREAMING_TIDAL)
        break
      case BAS_STREAMING.T_ID_SPOTIFY:
        BasState.go(STATES.SETTINGS_MUSIC_STREAMING_SPOTIFY_WEB)
        break
    }
  }

  function back () {

    BasState.go(STATES.SETTINGS_MUSIC_STREAMING)
  }

  function _onStateSuccess () {

    _syncUi()
  }

  function _onVariables () {

    _handleServerProperties()
  }

  function _onSourcesUpdated () {

    _generateStreamingServiceOptions()
    $scope.$applyAsync()
  }

  function _syncUi () {

    var currentStateName

    currentStateName = $uiRouterGlobals.current.name

    streaming.uiCanNavigate = false
    streaming.uiHasSub = false
    streaming.uiSubtitle = ''

    if (BasUtil.startsWith(currentStateName, STATES.SETTINGS_MUSIC_STREAMING)) {

      streaming.uiCanNavigate =
        currentStateName.length > STATES.SETTINGS_MUSIC_STREAMING.length
      streaming.uiHasSub = (
        currentStateName === STATES.SETTINGS_MUSIC_STREAMING_DEEZER ||
        currentStateName === STATES.SETTINGS_MUSIC_STREAMING_TIDAL ||
        currentStateName === STATES.SETTINGS_MUSIC_STREAMING_SPOTIFY_WEB
      )

      if (BasUtil.startsWith(
        currentStateName,
        STATES.SETTINGS_MUSIC_STREAMING_DEEZER
      )) {
        streaming.uiSubtitle = BAS_STREAMING.T_ID_DEEZER
      } else if (BasUtil.startsWith(
        currentStateName,
        STATES.SETTINGS_MUSIC_STREAMING_TIDAL
      )) {
        streaming.uiSubtitle = BAS_STREAMING.T_ID_TIDAL
      } else if (BasUtil.startsWith(
        currentStateName,
        STATES.SETTINGS_MUSIC_STREAMING_SPOTIFY_WEB
      )) {
        streaming.uiSubtitle = BAS_STREAMING.T_ID_SPOTIFY
      }
    }
  }

  function _generateStreamingServiceOptions () {

    var i, length, keys, source
    var addSpotify, addDeezer, addTidal

    if (CurrentBasCore.hasAVFullSupport()) {

      streaming.streamingServices = []

      // Loop over all audioSources and check their possible streaming services
      keys = Object.keys(BAS_SOURCES.SOURCES.audioSources)
      length = keys.length

      for (i = 0; i < length; i++) {

        source = BAS_SOURCES.SOURCES.sources[keys[i]]

        if (source.spotify) addSpotify = true
        if (source.tidal) addTidal = true
        if (source.deezer) addDeezer = true
      }

      if (CurrentBasCore.hasSpotifyConnect()) addSpotify = true

      if (addDeezer) {

        streaming.streamingServices.push(BAS_STREAMING.T_ID_DEEZER)
      }

      if (addTidal) {

        streaming.streamingServices.push(BAS_STREAMING.T_ID_TIDAL)
      }

      if (addSpotify) {

        streaming.streamingServices.push(BAS_STREAMING.T_ID_SPOTIFY)
      }

    } else {

      streaming.streamingServices = [
        BAS_STREAMING.T_ID_DEEZER,
        BAS_STREAMING.T_ID_TIDAL
      ]

      if (_hasSpotifyBarps()) {

        streaming.streamingServices.push(BAS_STREAMING.T_ID_SPOTIFY)
      }
    }
  }

  function _handleServerProperties () {

    _generateStreamingServiceOptions()

    if (
      !$uiRouterGlobals.transition &&
      $uiRouterGlobals.current.name ===
        STATES.SETTINGS_MUSIC_STREAMING_SPOTIFY_WEB &&
      !_hasSpotifyBarps()
    ) {
      BasState.go(STATES.SETTINGS_MUSIC_STREAMING)
    }
  }

  /**
   * @private
   * @returns {boolean}
   */
  function _hasSpotifyBarps () {

    return (
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.hasSpotifyConnect
    )
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
