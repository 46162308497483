'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basFileSelector', [])
  .directive('basFileSelector', basFileSelectorDirective)

function basFileSelectorDirective () {

  // Add space to 'title' to prevent tooltip
  return {
    restrict: 'EA',
    template: '<input class="bas-file-input"' +
      ' type="file"' +
      ' title=" "' +
      ' accept="image/png, image/jpeg"' +
      ' />',
    bindToController: {
      onfile: '&',
      platform: '<'
    },
    controller: [
      '$element',
      'BasDeviceCamera',
      'BasDevicePermissions',
      controller
    ],
    controllerAs: 'basFileSelector'
  }

  /**
   * @param $element
   * @param {BasDeviceCamera} BasDeviceCamera
   * @param {BasDevicePermissions} BasDevicePermissions
   */
  function controller (
    $element,
    BasDeviceCamera,
    BasDevicePermissions
  ) {
    var basFileSelector = this

    var inputElement
    var CSS_FILE_ELEMENT = 'bas-file-input'

    var _listeners = []
    var _loadListener = null

    basFileSelector.$postLink = _onPostLink
    basFileSelector.$onDestroy = _onDestroy

    function _onPostLink () {

      init()
    }

    function init () {

      getDOMElements()
    }

    // Use photo library if Android
    // else use input element
    function _onClick (event) {

      if (basFileSelector.platform ===
        BasDevicePermissions.PLATFORM_ANDROID) {

        event.preventDefault()

        BasDeviceCamera.getPicture(
          _onFile,
          basFileSelector.platform,
          {
            cameraSrc: false,
            event: event
          }
        )
      }
    }

    function _onChange (inputEvent) {

      var reader

      BasUtil.execute(_loadListener)

      reader = new FileReader()

      _loadListener = BasUtil.setDOMListener(
        reader,
        'loadend',
        _onLoadend
      )

      reader.readAsDataURL(inputEvent.target.files[0])
    }

    function _onLoadend (readerEvent) {

      var imageData, prefix, body, idx

      if (readerEvent && readerEvent.target) {

        imageData = readerEvent.target.result
      }

      if (imageData) {

        idx = imageData.indexOf(',')
        prefix = imageData.substring(0, idx + 1)
        body = imageData.substring(idx + 1)

        _onFile(null, body, prefix)

      } else {

        _onFile(new Error('Invalid event'), null, null)
      }
    }

    function _onFile (error, body, prefix) {

      if (BasUtil.isFunction(basFileSelector.onfile)) {

        basFileSelector.onfile({
          error: error,
          body: body,
          prefix: prefix
        })
      }
    }

    function setListeners () {

      _clearListeners()

      if (inputElement && basFileSelector.onfile) {

        _listeners.push(BasUtil.setDOMListener(
          inputElement,
          'change',
          _onChange
        ))

        _listeners.push(BasUtil.setDOMListener(
          inputElement,
          'click',
          _onClick
        ))
      }
    }

    function getDOMElements () {

      inputElement = $element[0]
        .getElementsByClassName(CSS_FILE_ELEMENT)[0]

      setListeners()
    }

    function _clearDOMElements () {

      inputElement = null
    }

    function _clearListeners () {

      BasUtil.execute(_loadListener)

      _loadListener = null

      BasUtil.executeArray(_listeners)

      _listeners = []
    }

    function _onDestroy () {

      _clearListeners()
      _clearDOMElements()
    }
  }
}
