'use strict'

angular
  .module('basalteApp')
  .controller('lisaLightsCtrl', [
    'STATES',
    'BAS_ROOMS',
    'BasState',
    'CurrentRoom',
    lisaLightsCtrl
  ])

/**
 * @param {STATES} STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasState} BasState
 * @param {CurrentRoom} CurrentRoom
 */
function lisaLightsCtrl (
  STATES,
  BAS_ROOMS,
  BasState,
  CurrentRoom
) {
  const lisa = this

  /**
   * @type {BasRooms}
   */
  lisa.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  lisa.currentRoom = CurrentRoom.get()

  lisa.handleSelect = handleSelect
  lisa.getWhiteOrBrightnessValue = getWhiteOrBrightnessValue

  function getWhiteOrBrightnessValue (light) {
    return canOnlyToggle(light) ? '' : light.getWhiteOrBrightness() + '%'
  }

  function handleSelect (light) {
    canOnlyToggle(light)
      ? light.toggle()
      : BasState.go(
        STATES.LISA_LIGHT_DETAIL,
        {
          light: light.uuid
        }
      )

  }

  function canOnlyToggle (light) {
    return light.canToggleOn &&
      !light.canChangeBrightness &&
      !light.canChangeColor &&
      !light.canChangeColorTemp &&
      !light.canChangeWhite
  }
}
