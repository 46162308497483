'use strict'

angular
  .module('basalteApp')
  .service('BasStorageHelper', [
    'BAS_API',
    'BAS_APP_STORAGE',
    'BAS_CONNECT',
    BasStorageHelper
  ])

/**
 * @constructor
 * @param BAS_API
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @param {BAS_CONNECT} BAS_CONNECT
 */
function BasStorageHelper (
  BAS_API,
  BAS_APP_STORAGE,
  BAS_CONNECT
) {
  this.getStoredServerType = getStoredServerType
  this.getConnectServerType = getConnectServerType

  /**
   * @param {BasServer} basServer
   * @returns {string}
   */
  function getStoredServerType (basServer) {

    if (basServer instanceof BAS_API.BasDemoServer) {

      return BAS_APP_STORAGE.T_DEMO
    }

    if (basServer instanceof BAS_API.BasHttpServer) {

      return BAS_APP_STORAGE.T_LOCAL
    }

    if (basServer instanceof BAS_API.BasRemoteServer) {

      return BAS_APP_STORAGE.T_REMOTE
    }

    return ''
  }

  /**
   * @param {string} storageType
   * @returns {string}
   */
  function getConnectServerType (storageType) {

    switch (storageType) {
      case BAS_APP_STORAGE.T_LOCAL:
        return BAS_CONNECT.T_LOCAL
      case BAS_APP_STORAGE.T_REMOTE:
        return BAS_CONNECT.T_REMOTE
      case BAS_APP_STORAGE.T_DEMO:
        return BAS_CONNECT.T_DEMO
    }

    return ''
  }
}
