'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basInput', basInput)

function basInput () {

  return {
    restrict: 'A',
    controller: [
      '$element',
      '$window',
      'BasSupports',
      'BasAppDevice',
      'BasStatusBar',
      'BasPreferences',
      'BasUtilities',
      controller
    ]
  }

  /**
   * @param $element
   * @param $window
   * @param {BasSupports} BasSupports
   * @param {BasAppDevice} BasAppDevice
   * @param {BasStatusBar} BasStatusBar
   * @param {BasPreferences} BasPreferences
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $element,
    $window,
    BasSupports,
    BasAppDevice,
    BasStatusBar,
    BasPreferences,
    BasUtilities
  ) {
    var LISTENER_OPTS = {
      capture: false,
      passive: true
    }

    var _listeners = []

    /**
     * @type {TBasSupports}
     */
    var basSupports = BasSupports.get()

    this.$postLink = _onPostLink
    this.$onDestroy = _onDestroy

    function _onPostLink () {

      var element

      if (BasAppDevice.isIos() ||
        (
          BasAppDevice.isAndroid() &&
          BasPreferences.getShowStatusBar() === false
        )) {

        element = $element[0]

        if (element) {

          _listeners.push(BasUtil.setDOMListener(
            element,
            'focus',
            _onFocus,
            basSupports.passiveListeners
              ? LISTENER_OPTS
              : undefined
          ))
          _listeners.push(BasUtil.setDOMListener(
            element,
            'blur',
            _onBlur,
            basSupports.passiveListeners
              ? LISTENER_OPTS
              : undefined
          ))
        }
      }
    }

    function _onFocus () {

      if (BasAppDevice.isAndroid() &&
        BasPreferences.getShowStatusBar() === false) {

        BasStatusBar.show()
      }
    }

    function _onBlur () {

      if (BasAppDevice.isAndroid() &&
        BasPreferences.getShowStatusBar() === false) {

        BasStatusBar.hide()
      }

      if (BasAppDevice.isIos()) {

        _fixWebKitViewPortBug()
        BasUtilities.waitFrames(5).then(_fixWebKitViewPortBug)
      }
    }

    function _fixWebKitViewPortBug () {

      $window.scrollBy(0, 0)
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
