'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('thermostatDayCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'BAS_UNITS',
    'BAS_SCHEDULER',
    'BAS_THERMOSTAT',
    'CurrentRoom',
    thermostatDayCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_UNITS} BAS_UNITS
 * @param {BAS_SCHEDULER} BAS_SCHEDULER
 * @param {BAS_THERMOSTAT} BAS_THERMOSTAT
 * @param {CurrentRoom} CurrentRoom
 */
function thermostatDayCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_ROOMS,
  BAS_UNITS,
  BAS_SCHEDULER,
  BAS_THERMOSTAT,
  CurrentRoom
) {
  var day = this

  var _listeners = []

  day.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BasRooms}
   */
  day.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasSchedulerLine[]}
   */
  day.verticalLines = BAS_SCHEDULER.HOUR_LINES_13

  /**
   * @type {BasSchedulerLine[]}
   */
  day.horizontalLines = BAS_SCHEDULER.VALUE_LINES_6

  /**
   * @type {number}
   */
  day.yMin = 0

  /**
   * @type {number}
   */
  day.yMax = 0

  /**
   * @type {TCurrentRoomState}
   */
  day.currentRoom = CurrentRoom.get()

  _setActiveThermostat()

  this.uiOnParamsChanged = _onParamsChanged

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_UNITS.EVT_TEMPERATURE_UNIT_CHANGED,
      _onTemperatureUnitChanged
    ))

    _updateLimits()
  }

  function _onParamsChanged () {
    _setActiveThermostat()
  }

  function _setActiveThermostat () {
    day.activeThermostat = BAS_ROOMS.ROOMS
      .rooms[day.currentRoom.roomId]
      .thermostats
      .getThermostat($uiRouterGlobals.params.thermostat)
  }

  function _updateLimits () {

    if (day.activeThermostat) {

      day.yMin = day.activeThermostat.setpointMin
      day.yMax = day.activeThermostat.setpointMax

    } else {

      day.yMin = BAS_THERMOSTAT.MIN_C
      day.yMax = BAS_THERMOSTAT.MAX_C
    }
  }

  function _onTemperatureUnitChanged () {

    // TODO
  }

  /**
   * @private
   * @returns {?BasThermostatScheduler}
   */
  function _getBasScheduler () {

    if (day.activeThermostat &&
      day.activeThermostat.thermostatScheduler) {

      return day.activeThermostat.thermostatScheduler
    }

    return null
  }

  function _onDestroy () {

    var scheduler

    BasUtil.executeArray(_listeners)
    _listeners = []

    scheduler = _getBasScheduler()

    if (scheduler) {

      scheduler.commit()
      scheduler.updateSchedule()
    }
  }
}
