'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSceneCtrl', [
    'BAS_API',
    basSceneCtrlFactory
  ])

/**
 * @param BAS_API
 * @returns BasSceneCtrl
 */
function basSceneCtrlFactory (
  BAS_API
) {
  var CSS_CAN_ADD = 'brs-show-add'

  /**
   * @constructor
   * @param {SceneCtrlDevice} device
   * @param {BasRoomScenes} basRoomScenes
   */
  function BasSceneCtrl (device, basRoomScenes) {

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @private
     * @type {?BasRoomScenes}
     */
    this._basRoomScenes = basRoomScenes || null

    /**
     * @private
     * @type {?SceneCtrlDevice}
     */
    this._device = null

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this._resetCss()

    this._deviceListeners = []

    this._handleScenesChanged = this._onScenesChanged.bind(this)
    this._handleSceneAdded = this._onSceneAdded.bind(this)
    this._handleSceneChanged = this._onSceneChanged.bind(this)
    this._handleSceneRemoved = this._onSceneRemoved.bind(this)
    this._handleSceneImagesUpdated = this._onSceneImagesUpdated.bind(this)

    this._parseSceneCtrlDevice(device)
  }

  /**
   * Checks for "add" capability to create new scenes on given device
   *
   * @param {?SceneCtrlDevice} device
   * @returns {boolean}
   */
  BasSceneCtrl.canCreateScenes = function (device) {

    if (BasUtil.isObject(device) &&
      BasUtil.isFunction(device.allowsExecute)) {

      return device.allowsExecute(
        BAS_API.SceneCtrlDevice.C_ADD,
        BAS_API.SceneCtrlDevice.ST_SCENE
      )
    }

    return false
  }

  /**
   * @private
   * @param {SceneCtrlDevice} device
   */
  BasSceneCtrl.prototype._parseSceneCtrlDevice = function (device) {

    this._clearDeviceListeners()
    this._resetCss()

    if (device instanceof BAS_API.SceneCtrlDevice) {

      this._device = device
      this.uuid = device.uuid

      this.css[CSS_CAN_ADD] = this.canCreateScenes()

      this._setDeviceListeners()
    }
  }

  /**
   * @returns {?SceneCtrlDevice}
   */
  BasSceneCtrl.prototype.getDevice = function () {

    return this._device
  }

  /**
   * Checks for "add" capability to create new scenes
   *
   * @returns {boolean}
   */
  BasSceneCtrl.prototype.canCreateScenes = function () {

    return BasSceneCtrl.canCreateScenes(this._device)
  }

  /**
   * @param {string} sceneUuid
   * @returns {boolean}
   */
  BasSceneCtrl.prototype.hasScene = function (sceneUuid) {

    if (this._device) {

      return !!this._device.getScene(sceneUuid)
    }

    return false
  }

  /**
   * @private
   */
  BasSceneCtrl.prototype._onScenesChanged = function () {

    if (this._basRoomScenes) this._basRoomScenes.handleScenesChanged()
  }

  /**
   * @private
   * @param {Scene} scene
   */
  BasSceneCtrl.prototype._onSceneAdded = function (scene) {

    if (this._basRoomScenes) this._basRoomScenes.handleSceneAdded(scene)
  }

  /**
   * @private
   * @param {Scene} scene
   */
  BasSceneCtrl.prototype._onSceneChanged = function (scene) {

    if (this._basRoomScenes) this._basRoomScenes.handleSceneChanged(scene)
  }

  /**
   * @private
   * @param {Scene} scene
   */
  BasSceneCtrl.prototype._onSceneRemoved = function (scene) {

    if (this._basRoomScenes) this._basRoomScenes.handleSceneRemoved(scene)
  }

  /**
   * @private
   * @param {Scene} scene
   */
  BasSceneCtrl.prototype._onSceneImagesUpdated = function (scene) {

    if (this._basRoomScenes) this._basRoomScenes.handleSceneImagesUpdated(scene)
  }

  /**
   * @private
   */
  BasSceneCtrl.prototype._setDeviceListeners = function () {

    this._clearDeviceListeners()

    if (this._device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this._device,
        BAS_API.SceneCtrlDevice.EVT_SCENES_CHANGED,
        this._handleScenesChanged
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        this._device,
        BAS_API.SceneCtrlDevice.EVT_SCENE_ADDED,
        this._handleSceneAdded
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        this._device,
        BAS_API.SceneCtrlDevice.EVT_SCENE_CHANGED,
        this._handleSceneChanged
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        this._device,
        BAS_API.SceneCtrlDevice.EVT_SCENE_REMOVED,
        this._handleSceneRemoved
      ))

      this._deviceListeners.push(BasUtil.setEventListener(
        this._device,
        BAS_API.SceneCtrlDevice.EVT_SCENE_IMAGES_UPDATED,
        this._handleSceneImagesUpdated
      ))
    }
  }

  /**
   * @private
   */
  BasSceneCtrl.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  /**
   * @private
   */
  BasSceneCtrl.prototype._resetCss = function () {

    this.css[CSS_CAN_ADD] = false
  }

  BasSceneCtrl.prototype.suspend = function () {

    this._clearDeviceListeners()
  }

  BasSceneCtrl.prototype.clear = function () {

    this.suspend()
    this._device = null
  }

  return BasSceneCtrl
}
