'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceEllieIp', [
    'BAS_HTML',
    deviceEllieIpDirective
  ])
/**
 * @param {BAS_HTML} BAS_HTML
 * @returns deviceEllieIp
 */
function deviceEllieIpDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.ellieIpSettings,
    controller: [
      'BAS_CORE_CLIENT',
      'BAS_INPUT_MODAL',
      'EllieIp',
      'BasPreferences',
      'BasNetworkConfig',
      'BasInputModal',
      controller
    ],
    controllerAs: 'network'
  }

  /**
   * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {EllieIp} EllieIp
   * @param {BasPreferences} BasPreferences
   * @param {BasNetworkConfig} BasNetworkConfig
   * @param {BasInputModal} BasInputModal
   */
  function controller (
    BAS_CORE_CLIENT,
    BAS_INPUT_MODAL,
    EllieIp,
    BasPreferences,
    BasNetworkConfig,
    BasInputModal
  ) {
    var network = this

    var DHCP = 'dhcp'
    var STATIC = 'static'

    /**
     * @type {BAS_CORE_CLIENT}
     */
    network.BAS_CORE_CLIENT = BAS_CORE_CLIENT

    network.currentIp = ''

    network.ip = ''
    network.subnet = ''
    network.gateway = ''
    network.dns = ''
    network.types = [
      DHCP,
      STATIC
    ]
    network.currentType = ''
    network.css = EllieIp.getCss()
    network.isSaving = EllieIp.isButtonDisabled

    network.setType = setType
    network.setConfig = setConfig
    network.editIp = editIp
    network.editSubnetMask = editSubnetMask
    network.editDNS = editDNS
    network.editGateway = editGateway

    init()

    function init () {

      setType(DHCP)
      initData()
    }

    function initData () {

      var preference = BasPreferences.getNetworkSetting()

      network.ip = preference.ip
      network.subnet = preference.subnet
      network.gateway = preference.gateway
      network.dns = preference.dns
      network.inputValid = checkInput()

      setType(preference.dhcp ? DHCP : STATIC)
    }

    /**
     * @param {string} type
     */
    function setType (type) {

      if (network.isSaving()) return

      network.currentType = type

      switch (type) {

        case DHCP:
          EllieIp.setInput(true)
          break

        case STATIC:
          EllieIp.setInput(false)
          break
      }
    }

    /**
     * @private
     * @param {Object} parameters
     * @param {string} parameters.title
     * @param {string} parameters.fieldName
     * @param {string} parameters.initialValue
     * @param parameters.handler
     */
    function _showIPInputModal (parameters) {

      if (network.isSaving() || network.currentType === DHCP) return

      BasInputModal.show(
        BAS_INPUT_MODAL.T_IP_ADDRESS,
        {
          title: parameters.title,
          fieldName: parameters.fieldName,
          initialValue: parameters.initialValue
        }
      ).then(_onModal)

      function _onModal (modal) {

        modal.close.then(_onModalClose)
      }

      function _onModalClose (result) {

        if (Array.isArray(result) && BasUtil.isNEString(result[0])) {

          if (BasUtil.isFunction(parameters.handler)) {

            parameters.handler(result[0])
          }
        }
      }
    }

    function editIp () {

      _showIPInputModal({
        title: 'edit_ip_address',
        fieldName: 'ip_address',
        initialValue: network.ip,
        handler: _onIpResult
      })
    }

    function editSubnetMask () {

      _showIPInputModal({
        title: 'edit_subnet_mask',
        fieldName: 'subnet_mask',
        initialValue: network.subnet,
        handler: _onSubnetMaskResult
      })
    }

    function editGateway () {

      _showIPInputModal({
        validCheck: BasUtil.isValidIp,
        title: 'edit_gateway',
        fieldName: 'gateway',
        placeholder: '255.255.255.255',
        initialValue: network.gateway,
        handler: _onGatewayResult
      })
    }

    function editDNS () {

      _showIPInputModal({
        title: 'edit_dns',
        fieldName: 'dns',
        initialValue: network.dns,
        handler: _onDNSResult
      })
    }

    /**
     * @private
     * @param {string} result
     */
    function _onIpResult (result) {

      network.ip = result
      _syncInputValid()
    }

    /**
     * @private
     * @param {string} result
     */
    function _onGatewayResult (result) {

      network.gateway = result
      _syncInputValid()
    }

    /**
     * @private
     * @param {string} result
     */
    function _onDNSResult (result) {

      network.dns = result
      _syncInputValid()
    }

    /**
     * @private
     * @param {string} result
     */
    function _onSubnetMaskResult (result) {

      network.subnet = result
      _syncInputValid()
    }

    /**
     * @private
     */
    function _syncInputValid () {

      network.inputValid = checkInput()
    }

    function setConfig () {

      if (!EllieIp.isButtonDisabled()) {

        switch (network.currentType) {
          case DHCP:

            _setNetworkConfig(true)

            break
          case STATIC:

            _setNetworkConfig(false)

            break
        }
      }
    }

    /**
     * @private
     * @param {boolean} dhcp
     */
    function _setNetworkConfig (dhcp) {

      var _config

      if (dhcp) {

        _config = {}
        _config.configuration = DHCP

      } else if (checkInput()) {

        _config = {}
        _config.configuration = STATIC
        _config.ip = network.ip
        _config.subnetMask = network.subnet
        _config.gateway = network.gateway
        _config.dns = network.dns
      }

      if (_config) {

        BasNetworkConfig.setNetworkConfig(_config)

        EllieIp.setButton(true)

        setNetworkSetting(dhcp)
      }
    }

    /**
     * @param {boolean} dhcp
     */
    function setNetworkSetting (dhcp) {

      BasPreferences.setNetworkSetting(
        network.ip,
        network.subnet,
        network.gateway,
        network.dns,
        dhcp
      )
    }

    function checkInput () {

      return (
        BasUtil.isValidIp(network.ip) &&
        BasUtil.isValidIp(network.subnet) &&
        BasUtil.isValidIp(network.gateway) &&
        BasUtil.isValidIp(network.dns)
      )
    }
  }
}
