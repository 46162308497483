'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLibraryHeader', [
    '$timeout',
    'ICONS',
    'BasLibraryCollection',
    'BasImageTrans',
    basLibraryHeaderFactory
  ])

/**
 * @param $timeout
 * @param {ICONS} ICONS
 * @param BasLibraryCollection
 * @param BasImageTrans
 * @returns BasLibraryHeader
 */
function basLibraryHeaderFactory (
  $timeout,
  ICONS,
  BasLibraryCollection,
  BasImageTrans
) {
  /**
   * @constructor
   */
  function BasLibraryHeader () {

    /**
     * @instance
     * @type {string}
     */
    this.title = ''

    /**
     * @instance
     * @type {string}
     */
    this.subtitle = ''

    /**
     * @type {BasImageTrans}
     */
    this.viewBit = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE_FAST
    })

    /**
     * @instance
     * @type {Object}
     */
    this.control = {}

    /**
     * Holds all objects with CSS classes
     * * Header
     * * Search form
     * * Body
     *
     * @instance
     * @type {Object}
     */
    this.class = {}

    // Create generic variable CSS classes
    this.class[BasLibraryHeader.CLASS_SHOW_BACK] = false
    this.class[BasLibraryHeader.CLASS_SHOW_HOME] = false
    this.class[BasLibraryHeader.CLASS_HAS_SUBTITLE] = false
    this.class[BasLibraryHeader.CLASS_CAN_SEARCH] = false
    this.class[BasLibraryHeader.CLASS_ACTIVE_SEARCH] = false
    this.class[BasLibraryHeader.CLASS_SHOW_HISTORY] = false
    this.class[BasLibraryHeader.CLASS_ACTIVE_NAVIGATION] = false
    this.class[BasLibraryHeader.CLASS_CAN_VIEW] = false

    // Create CSS class objects
    this.class[BasLibraryHeader.COL_HEADER] = {}
    this.class[BasLibraryHeader.COL_SEARCH_FORM] = {}
    this.class[BasLibraryHeader.COL_TABS] = {}
    this.class[BasLibraryHeader.COL_BODY] = {}

    // Initialize CSS class objects
    this.processClasses()
  }

  /**
   * @constant {string}
   */
  BasLibraryHeader.COL_HEADER = 'collectionHeader'

  /**
   * @constant {string}
   */
  BasLibraryHeader.COL_SEARCH_FORM = 'collectionSearchForm'

  /**
   * @constant {string}
   */
  BasLibraryHeader.COL_TABS = 'collectionTabs'

  /**
   * @constant {string}
   */
  BasLibraryHeader.COL_BODY = 'collectionBody'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLASS_SHOW_BACK = 'bas-library-header-show-back'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLASS_SHOW_HOME = 'bas-library-header-show-home'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLASS_HAS_SUBTITLE = 'baht-has-sub'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLASS_CAN_SEARCH = 'bah-can-search'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLASS_CAN_VIEW = 'bah-can-view'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLASS_ACTIVE_SEARCH = 'bah-active-search'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLASS_SHOW_HISTORY = 'bah-show-history'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLASS_ACTIVE_NAVIGATION = 'bah-can-navigate'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLASS_HAS_TABS = 'bas-library-header-has-tabs'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLK_BACK = 'clickBack'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLK_HOME = 'clickHome'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLK_VIEW = 'clickView'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLK_SEARCH = 'clickSearch'

  /**
   * @constant {string}
   */
  BasLibraryHeader.CLK_SELECT = 'clickSelection'

  /**
   * Process the generic CSS classes
   * and update the specific CSS collections
   */
  BasLibraryHeader.prototype.processClasses = function () {
    var _this = this

    // Header
    setCSSClass(
      BasLibraryHeader.COL_HEADER,
      BasLibraryHeader.CLASS_SHOW_BACK
    )
    setCSSClass(
      BasLibraryHeader.COL_HEADER,
      BasLibraryHeader.CLASS_SHOW_HOME
    )
    setCSSClass(
      BasLibraryHeader.COL_HEADER,
      BasLibraryHeader.CLASS_HAS_SUBTITLE
    )
    setCSSClass(
      BasLibraryHeader.COL_HEADER,
      BasLibraryHeader.CLASS_CAN_SEARCH
    )
    setCSSClass(
      BasLibraryHeader.COL_HEADER,
      BasLibraryHeader.CLASS_CAN_VIEW
    )
    setCSSClass(
      BasLibraryHeader.COL_HEADER,
      BasLibraryHeader.CLASS_ACTIVE_SEARCH
    )
    setCSSClass(
      BasLibraryHeader.COL_HEADER,
      BasLibraryHeader.CLASS_ACTIVE_NAVIGATION
    )

    // Search form
    setCSSClass(
      BasLibraryHeader.COL_SEARCH_FORM,
      BasLibraryHeader.CLASS_CAN_SEARCH
    )
    setCSSClass(
      BasLibraryHeader.COL_SEARCH_FORM,
      BasLibraryHeader.CLASS_ACTIVE_SEARCH
    )
    setCSSClass(
      BasLibraryHeader.COL_SEARCH_FORM,
      BasLibraryHeader.CLASS_SHOW_HISTORY
    )

    // Body
    setCSSClass(
      BasLibraryHeader.COL_BODY,
      BasLibraryHeader.CLASS_ACTIVE_SEARCH
    )

    /**
     * Sets the value for the className in the collection,
     * derived from the general class values
     *
     * @param {string} collection
     * @param {string} className
     */
    function setCSSClass (collection, className) {
      _this.class[collection][className] = _this.class[className]
    }
  }

  /**
   * @param {string} value
   */
  BasLibraryHeader.prototype.setTitle = function (value) {
    this.title = BasUtil.isNEString(value) ? value : ''
  }

  /**
   * @param {string} value
   */
  BasLibraryHeader.prototype.setSubtitle = function (value) {

    this.class[BasLibraryHeader.CLASS_HAS_SUBTITLE] =
      BasUtil.isNEString(value)

    if (this.class[BasLibraryHeader.CLASS_HAS_SUBTITLE]) {
      this.subtitle = value
    } else {
      this.subtitle = ''
    }

    // Update class objects
    this.processClasses()
  }

  BasLibraryHeader.prototype.enableBack = function (value) {

    // Set CSS classes
    this.class[BasLibraryHeader.CLASS_SHOW_BACK] = value === true

    // Update class objects
    this.processClasses()
  }

  BasLibraryHeader.prototype.enableHome = function (value) {

    // Set CSS classes
    this.class[BasLibraryHeader.CLASS_SHOW_HOME] = value === true

    // Update class objects
    this.processClasses()
  }

  /**
   * Enables/disables the back and home buttons
   *
   * @param {boolean} value
   */
  BasLibraryHeader.prototype.enableNavigation = function (value) {
    var b = value === true

    // Set CSS classes
    this.class[BasLibraryHeader.CLASS_SHOW_BACK] = b
    this.class[BasLibraryHeader.CLASS_SHOW_HOME] = b

    // Update class objects
    this.processClasses()
  }

  /**
   * Enable a view button on the header
   *
   * @param {boolean} value
   */
  BasLibraryHeader.prototype.enableGridView = function (value) {
    this.class[BasLibraryHeader.CLASS_CAN_VIEW] = value === true

    // Update class objects
    this.processClasses()
  }

  /**
   * Switches layout view button on the header
   *
   * @param {number} view
   */
  BasLibraryHeader.prototype.setIconView = function (view) {

    switch (view) {
      case BasLibraryCollection.INDEX_GRID:
        this.viewBit.setImage(ICONS.viewTogglesList)
        break
      case BasLibraryCollection.INDEX_LIST:
        this.viewBit.setImage(ICONS.viewTogglesGrid)
        break
      default:
        this.viewBit.setImage(null)
        break
    }

    // Update class objects
    this.processClasses()
  }

  /**
   * Enable a search button on the header
   *
   * @param {boolean} value
   */
  BasLibraryHeader.prototype.enableSearch = function (value) {
    this.class[BasLibraryHeader.CLASS_CAN_SEARCH] = value === true

    // Update class objects
    this.processClasses()
  }

  /**
   * Toggle the search input
   *
   * @param {boolean} [override]
   */
  BasLibraryHeader.prototype.toggleSearchInput = function (override) {

    var value = BasUtil.isBool(override)
      ? override
      : !this.class[BasLibraryHeader.CLASS_ACTIVE_SEARCH]

    this.class[BasLibraryHeader.CLASS_ACTIVE_SEARCH] = value
    if (BasUtil.isFunction(this.control.setFocus)) {

      this.control.setFocus(value)
    }

    this.toggleHistory(value)

    // Update class objects
    this.processClasses()
  }

  /**
   * Toggle the history list
   *
   * @param {boolean} [value]
   */
  BasLibraryHeader.prototype.toggleHistoryDelay = function (value) {

    $timeout(this.toggleHistory.bind(this, value), 100)
  }

  /**
   * Toggle the history list
   *
   * @param {boolean} [value]
   */
  BasLibraryHeader.prototype.toggleHistory = function (value) {

    this.class[BasLibraryHeader.CLASS_SHOW_HISTORY] =
      typeof value === 'boolean'
        ? value
        : !this.class[BasLibraryHeader.CLASS_SHOW_HISTORY]

    // Update class objects
    this.processClasses()
  }

  /**
   * Toggle the navigation buttons (animation)
   *
   * @param {boolean} value
   */
  BasLibraryHeader.prototype.toggleNavigation = function (value) {
    this.class[BasLibraryHeader.CLASS_ACTIVE_NAVIGATION] =
      value === true

    // Update class objects
    this.processClasses()
  }

  return BasLibraryHeader
}
