'use strict'

angular
  .module('basalteApp')
  .constant('BAS_WEATHER', {
    DARK_SKY_UUID: 'dark-sky-uuid',
    DEF_WEATHER: 'dark-sky-uuid',
    SOURCE_LOCAL: 'local',
    SOURCE_ONLINE: 'online',
    EVT_WEATHER_STATION_STATE_UPDATED: 'evtBasWeatherStationUpdated',
    EVT_WEATHER_DEFAULT_CHANGED: 'evtBasWeatherDefaultChanged',
    ICON_CLEAR_DAY: 'clearDay',
    ICON_CLEAR_NIGHT: 'clearNight',
    ICON_CLOUDY: 'cloudy',
    ICON_FOG: 'fog',
    ICON_FROST: 'frost',
    ICON_PARTLY_CLOUDY_DAY: 'cloudyDay',
    ICON_PARTLY_CLOUDY_NIGHT: 'cloudyNight',
    ICON_RAIN: 'rain',
    ICON_SNOW: 'snow',
    ICON_WIND: 'wind',
    K_CLEAR_DAY: 'clear-day',
    K_CLEAR_NIGHT: 'clear-night',
    K_CLOUDY: 'cloudy',
    K_FOG: 'fog',
    K_PARTLY_CLOUDY_DAY: 'partly-cloudy-day',
    K_PARTLY_CLOUDY_NIGHT: 'partly-cloudy-night',
    K_RAIN: 'rain',
    K_SNOW: 'snow',
    K_WIND: 'wind',
    EXTERNAL_MAPPING_ICON: {},
    CSS_HAS_PRESSURE: 'bw-has-pressure',
    CSS_HAS_APPARENT_TEMPERATURE: 'bw-has-apparent-temperature',
    CSS_HAS_BRIGHTNESS: 'bw-has-brightness',
    CSS_HAS_FROST: 'bw-has-frost',
    CSS_HAS_FROST_ENABLED: 'bw-has-frost-enabled',
    CSS_HAS_HUMIDITY: 'bw-has-humidity',
    CSS_HAS_ICON: 'bw-has-icon',
    CSS_HAS_LOCATION: 'bw-has-location',
    CSS_HAS_PRECIPITATION: 'bw-has-precipitation',
    CSS_HAS_RAIN: 'bw-has-rain',
    CSS_HAS_RAIN_ENABLED: 'bw-has-rain-enabled',
    CSS_HAS_SUN_RISE_SET: 'bw-has-sun-rise-set',
    CSS_HAS_TEMPERATURE: 'bw-has-temperature',
    CSS_HAS_UV: 'bw-has-uv',
    CSS_HAS_WIND_DIRECTION: 'bw-has-wind-direction',
    CSS_HAS_WIND_SPEED: 'bw-has-wind-speed',
    CSS_HAS_CURRENT_TOP_LEFT_TITLE:
            'bw-has-current-info-top-left-title',
    CSS_SHOW_HUMIDITY_WIDGET:
            'bw-show-humidity-widget',
    CSS_SHOW_APPARENT_TEMPERATURE:
            'bw-show-apparent-temperature',
    CSS_HAS_CURRENT_BOTTOM_LEFT_TITLE:
            'bw-has-current-info-bottom-left-title',
    CSS_HAS_CURRENT_BOTTOM_RIGHT_TITLE:
            'bw-has-current-info-bottom-right-title',
    CSS_HAS_MULTIPLE_SERVICES:
            'bw-has-multiple-services'
  })
