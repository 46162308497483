'use strict'

import * as detectBrowser from 'detect-browser'
import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasAppDevice', [
    '$window',
    'Querystringify',
    'BAS_BUILD',
    'BAS_PREFERENCES',
    'BasSupports',
    BasAppDevice
  ])

/**
 * @typedef {Object} BasAppDeviceState
 * @property {Object} platforms
 * @property {boolean} platforms.android
 * @property {boolean} platforms.browser
 * @property {boolean} platforms.ios
 * @property {?string} androidVersion
 * @property {?string} iosVersion
 * @property {?string} safariVersion
 * @property {boolean} safari
 * @property {boolean} mobileSafari
 * @property {Object<string, boolean>} css
 * @property {string} protocol Includes ":"
 * @property {string} host Domain + port
 * @property {string} hostname No port
 * @property {string} port
 * @property {string} search
 * @property {boolean} usesDefaultPort
 * @property {boolean} isHttpProtocol Not HTTPS
 * @property {boolean} isHttpsProtocol
 * @property {boolean} isLocalHosted 127.0.0.1; 0.0.0.0; localhost
 * @property {boolean} isCloudHosted
 * @property {boolean} isProLiveHosted
 * @property {boolean} isEllieV1
 * @property {boolean} isEllieV2
 * @property {boolean} supportedBrowser
 * @property {boolean} supportsUnsecuredWebSocket
 * @property {boolean} supportsWebRTC
 * @property {boolean} supportsBasalteLive
 * @property {boolean} supportsNotifications
 * @property {boolean} supportsCustomImageUpload
 * @property {boolean} supportsVolumeControl
 * @property {boolean} debug
 * @property {boolean} onlyWebRTC
 * @property {boolean} webRTCOnlyRelay
 * @property {boolean} devLiveEnvironment
 * @property {boolean} preferDemo
 * @property {string?} preferredServer
 * @property {number} pauseDisconnectTimeoutMs
 * @property {number} devicePixelRatio
 * @property {boolean} debugBuild
 */

/**
 * @constructor
 * @param $window
 * @param Querystringify
 * @param {BAS_BUILD} BAS_BUILD
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BasSupports} BasSupports
 */
function BasAppDevice (
  $window,
  Querystringify,
  BAS_BUILD,
  BAS_PREFERENCES,
  BasSupports
) {

  var K_DEVICE = 'device'
  var K_CORDOVA = 'cordova'
  var K_MODEL = 'model'
  var K_PLATFORM = 'platform'
  var K_VERSION = 'version'
  var K_MANUFACTURER = 'manufacturer'
  var K_SERIAL = 'serial'

  var URL_P_DEMO = 'demo'
  var URL_P_REDIRECT_URI = 'redirectUri'
  var URL_P_PROJECT_ID = 'p'
  var URL_P_SERVER = 'server'

  var CSS_DEVICE_BROWSER = 'bas-device-browser'
  var CSS_DEVICE_IOS = 'bas-device-ios'
  var CSS_DEVICE_IOS_15_OR_HIGHER = 'bas-device-ios-15-or-higher'
  var CSS_DEVICE_ANDROID = 'bas-device-android'
  var CSS_DEVICE_NORMAL = 'bas-device-normal'
  var CSS_DEVICE_ELLIE = 'bas-device-ellie'
  var CSS_DEVICE_ELLIE_V1 = 'bas-device-ellie-v1'
  var CSS_DEVICE_LISA = 'bas-device-lisa'
  var CSS_DEVICE_LENA = 'bas-device-lena'
  var CSS_DEVICE_CORE_CLIENT = 'bas-device-core-client'
  var CSS_DEBUG = 'bas-device--debug'
  var CSS_LIVE_ONLY = 'bas-device--live-only'
  var CSS_SUPPORTS_LIVE = 'bas-device--supports-live'
  var CSS_PRO_LIVE = 'bas-device--pro-live'
  var CSS_SUPPORTS_NOTIFICATIONS = 'bas-device--supports-notifications'
  var CSS_ONLY_WEB_RTC = 'bas-device--only-web-rtc'
  var CSS_SUPPORTS_VOLUME_CONTROL = 'bas-device--supports-volume-control'

  /**
   * @type {TBasSupports}
   */
  var basSupports = BasSupports.get()

  /**
   * @type {BasAppDeviceState}
   */
  var _state = {
    platforms: {
      android: false,
      browser: false,
      ios: false
    },
    androidVersion: null,
    iosVersion: null,
    safariVersion: null,
    safari: false,
    mobileSafari: false,
    css: {},
    protocol: '',
    host: '',
    hostname: '',
    port: '',
    usesDefaultPort: false,
    isHttpProtocol: false,
    isHttpsProtocol: false,
    isLocalHosted: false,
    isCloudHosted: false,
    isProLiveHosted: false,
    isEllieV1: false,
    isEllieV2: false,
    supportedBrowser: false,
    supportsUnsecuredWebSocket: false,
    supportsWebRTC: false,
    supportsBasalteLive: false,
    supportsNotifications: false,
    supportsCustomImageUpload: false,
    supportsVolumeControl: false,
    debug: false,
    onlyWebRTC: false,
    webRTCOnlyRelay: false,
    devLiveEnvironment: false,
    preferDemo: false,
    pauseDisconnectTimeoutMs: BAS_PREFERENCES.DEF_PAUSE_DISCONNECT_TIMEOUT_MS,
    devicePixelRatio: 1,
    debugBuild: false
  }
  _resetCss()

  this.get = get
  this.getDpi = getDpi
  this.isBrowser = isBrowser
  this.isIos = isIos
  this.isAndroid = isAndroid
  this.isSafari = isSafari
  this.isMobileSafari = isMobileSafari
  this.isEllie = isEllie
  this.isEllieV1 = isEllieV1
  this.isEllieV2 = isEllieV2
  this.isLisa = isLisa
  this.isLena = isLena
  this.isCoreClient = isCoreClient
  this.hasCamera = hasCamera
  this.hasOLEDScreen = hasOLEDScreen
  this.isLiveOnly = isLiveOnly
  this.isLiveOnlyOrWebRTC = isLiveOnlyOrWebRTC
  this.isProLive = isProLive
  this.isSupportedBrowser = isSupportedBrowser
  this.isPossiblyCoreHosted = isPossiblyCoreHosted
  this.isDevLiveEnvironment = isDevLiveEnvironment
  this.getPreferDemo = getPreferDemo
  this.getPreferredServer = getPreferredServer
  this.getHost = getHost
  this.setEllieV1 = setEllieV1
  this.setEllieV2 = setEllieV2
  this.supportsUnsecuredWebSocket = supportsUnsecuredWebSocket
  this.supportsWebRTC = supportsWebRTC
  this.supportsBasalteLive = supportsBasalteLive
  this.supportsNotifications = supportsNotifications
  this.supportsCustomImageUpload = supportsCustomImageUpload
  this.supportsVolumeControl = supportsVolumeControl
  this.toggleDebug = toggleDebug
  this.toggleOnlyWebRTC = toggleOnlyWebRTC
  this.isOnlyWebRTC = isOnlyWebRTC
  this.toggleWebRTCOnlyRelay = toggleWebRTCOnlyRelay
  this.isWebRTCOnlyRelay = isWebRTCOnlyRelay
  this.getLocationSearch = getLocationSearch
  this.getProLiveLoginUrl = getProLiveLoginUrl
  this.getProLiveAccountSettingsUrl = getProLiveAccountSettingsUrl
  this.getPreferredProjectID = getPreferredProjectID
  this.setPauseDisconnectTimeoutMs = setPauseDisconnectTimeoutMs
  this.getPauseDisconnectTimeoutMs = getPauseDisconnectTimeoutMs
  this.reloadPage = reloadPage

  init()

  function init () {

    var cdvDevice, platform, version, browser, basVersion, browserBasVersion

    cdvDevice = getCdvDevice()

    if (cdvDevice) {

      platform = cdvDevice[K_PLATFORM].toLowerCase()
      version = cdvDevice[K_VERSION]
    }

    _checkLocation()

    browser = detectBrowser.detect()

    if (browser) {

      browserBasVersion = new BasUtil.BasVersion(browser.version)
    }

    _state.css[CSS_DEVICE_NORMAL] = true
    _state.supportsCustomImageUpload = true

    switch (platform) {
      case 'browser':

        _state.platforms.browser = true
        _state.css[CSS_DEVICE_BROWSER] = !isCoreClient()

        if (browser) {

          switch (browser.name) {
            case 'android':
            case 'chrome':
            case 'edge-chromium':

              _state.supportedBrowser = true

              // Arbitrary Chrome version
              // has WebRTC 1.0 support and fixes

              if (BasUtil.BasVersion.compare(
                browserBasVersion,
                '77.0.0'
              ) >= 0) {

                _checkWebRTCSupport()
              }

              break

            case 'firefox':

              _state.supportedBrowser = true

              // Arbitrary Firefox version
              // Contains WebRTC fixes and enhancements

              if (BasUtil.BasVersion.compare(
                browserBasVersion,
                '70.0.0'
              ) >= 0) {

                _checkWebRTCSupport()
              }

              break

            case 'safari':
            case 'crios':
            case 'ios':

              // iOS supports WebRTC from iOS 11 and higher
              // iOS 12 contains some WebRTC fixes
              // Safari should be the same

              _state.safari = true
              if (browser.name === 'ios' || browser.name === 'crios') {
                _state.mobileSafari = true
              }

              basVersion = new BasUtil.BasVersion(version)
              _state.safariVersion = basVersion.textShort

              if (BasUtil.BasVersion.compare(
                browserBasVersion,
                '12.0.0'
              ) >= 0) {

                _checkWebRTCSupport()
              }

              if (_state.safariVersion) {

                if (browser.name === 'safari') {

                  _state.supportedBrowser = BasUtil.BasVersion.compare(
                    basVersion,
                    '10.1.0'
                  ) >= 0

                } else {

                  // "browser.name" === 'ios' OR 'crios'

                  _state.supportedBrowser = BasUtil.BasVersion.compare(
                    basVersion,
                    '10.3.0'
                  ) >= 0
                }

                // Version and browser support checked
                break
              }

              // Version could not be determined,
              // browser not supported
              _state.supportedBrowser = false
          }
        }

        _checkSecureContext()

        break
      case 'android':

        _state.platforms.android = true
        _state.supportedBrowser = true
        _state.supportsNotifications = true
        _state.css[CSS_DEVICE_ANDROID] = true
        basVersion = new BasUtil.BasVersion(version)
        _state.androidVersion = basVersion.textShort

        // Arbitrary Chrome version
        // has WebRTC 1.0 support and fixes

        if (BasUtil.BasVersion.compare(
          browserBasVersion,
          '77.0.0'
        ) >= 0) {

          _checkWebRTCSupport()
        }

        break
      case 'ios':

        _state.platforms.ios = true
        _state.supportedBrowser = true
        _state.supportsNotifications = true
        _state.css[CSS_DEVICE_IOS] = true
        basVersion = new BasUtil.BasVersion(version)
        _state.iosVersion = basVersion.textShort

        if (_state.iosVersion) {

          _state.css[CSS_DEVICE_IOS_15_OR_HIGHER] = BasUtil.BasVersion.compare(
            basVersion,
            '15.0.0'
          ) >= 0

          // iOS supports WebRTC from iOS 11 and higher
          // iOS 12 contains some WebRTC fixes

          if (BasUtil.BasVersion.compare(
            basVersion,
            '12.0.0'
          ) >= 0) {

            _checkWebRTCSupport()
          }
        }

        break
    }

    _state.devicePixelRatio =
      BasUtil.isPNumber($window.devicePixelRatio)
        ? $window.devicePixelRatio
        : 1

    if (isCoreClient()) {

      _state.css[CSS_DEVICE_NORMAL] = false
      _state.css[CSS_SUPPORTS_VOLUME_CONTROL] = true

      _state.supportsBasalteLive = false
      _state.supportsNotifications = false
      _state.supportsCustomImageUpload = false
      _state.supportsVolumeControl = true
    }

    if (isEllie()) {

      _state.devicePixelRatio = 1

      _state.css[CSS_DEVICE_ELLIE] = true
    }

    if (isEllieV1()) {

      _state.css[CSS_DEVICE_ELLIE_V1] = true
    }

    if (isLisa()) {

      _state.devicePixelRatio = 1

      _state.css[CSS_DEVICE_LISA] = true

    }

    if (isLena()) {

      _state.css[CSS_DEVICE_LENA] = true
    }

    if (BAS_BUILD.liveOnly) {

      _state.css[CSS_LIVE_ONLY] = true

      _state.onlyWebRTC = true
    }

    if (BAS_BUILD.debug) {

      _state.debugBuild = true
    }

    _state.css[CSS_DEVICE_CORE_CLIENT] = isCoreClient()

    _state.css[CSS_SUPPORTS_LIVE] = _state.supportsBasalteLive
    _state.css[CSS_PRO_LIVE] = _state.isProLiveHosted
    _state.css[CSS_ONLY_WEB_RTC] = _state.onlyWebRTC
    _state.css[CSS_SUPPORTS_NOTIFICATIONS] = _state.supportsNotifications
  }

  function _checkWebRTCSupport () {

    if (basSupports.rtcPeerConnection) {

      // Cognito library uses "fetch"
      if (basSupports.fetch) {

        _state.supportsBasalteLive = true
      }

      if (basSupports.mediaDevices) {

        _state.supportsWebRTC = true
      }
    }
  }

  function _checkSecureContext () {

    var _protocol, _hostname

    _state.supportsUnsecuredWebSocket = true

    if ('isSecureContext' in $window) {

      if ($window.isSecureContext) {

        _state.supportsUnsecuredWebSocket = false

        if ($window.location) {

          _protocol = $window.location.protocol
          _hostname = $window.location.hostname

          if (BasUtil.isString(_protocol)) {

            if (_protocol === 'file:') {

              _state.supportsUnsecuredWebSocket = true

            } else if (_protocol === 'http:') {

              if (BasUtil.isString(_hostname)) {

                _hostname = _hostname.toLowerCase()

                if (_hostname === 'localhost' ||
                  _hostname === '127.0.0.1') {

                  _state.supportsUnsecuredWebSocket = true
                }
              }
            }
          }
        }
      }
    }
  }

  function _checkLocation () {

    var value, params

    _state.protocol = ''
    _state.host = ''
    _state.hostname = ''
    _state.port = ''
    _state.search = ''

    if ($window.location) {

      value = $window.location.protocol
      if (BasUtil.isString(value)) _state.protocol = value.toLowerCase()

      value = $window.location.host
      if (BasUtil.isString(value)) _state.host = value.toLowerCase()

      value = $window.location.hostname
      if (BasUtil.isString(value)) _state.hostname = value.toLowerCase()

      value = $window.location.port
      if (BasUtil.isString(value)) _state.port = value.toLowerCase()

      value = $window.location.search
      if (BasUtil.isString(value)) _state.search = value.toLowerCase()
    }

    _state.usesDefaultPort = _state.host === _state.hostname
    _state.isHttpProtocol = _state.protocol === 'http:'
    _state.isHttpsProtocol = _state.protocol === 'https:'
    _state.isLocalHosted = (
      _state.hostname === '0.0.0.0' ||
      _state.hostname === '127.0.0.1' ||
      _state.hostname === 'localhost'
    )
    _state.isCloudHosted = _state.hostname === 'musicapp.basalte.be'
    _state.isProLiveHosted =
      BasUtil.endsWith(_state.hostname, 'pro.basalte.live')
    _state.devLiveEnvironment = (
      _state.hostname === 'dev-pro.basalte.live' ||
      _state.hostname === 'dev.basalte.live'
    )

    if (_state.search) params = Querystringify.parse(_state.search)

    value = false
    if (params) value = params[URL_P_DEMO]
    _state.preferDemo = (
      value &&
      !(
        value === '0' ||
        value === 'n' ||
        value === 'no' ||
        value === 'null' ||
        value === 'false'
      )
    )

    value = ''
    if (params) value = params[URL_P_SERVER]
    _state.preferredServer = BasUtil.isNEString(value) ? value : null
  }

  /**
   * @returns {BasAppDeviceState}
   */
  function get () {
    return _state
  }

  /**
   * @returns {number}
   */
  function getDpi () {
    return _state.devicePixelRatio
  }

  /**
   * @returns {boolean}
   */
  function isBrowser () {
    return _state.platforms.browser
  }

  /**
   * @returns {boolean}
   */
  function isIos () {
    return _state.platforms.ios
  }

  /**
   * @returns {boolean}
   */
  function isAndroid () {
    return _state.platforms.android
  }

  /**
   * @returns {boolean}
   */
  function isSafari () {
    return _state.safari
  }

  /**
   * @returns {boolean}
   */
  function isMobileSafari () {
    return _state.mobileSafari
  }

  /**
   * @returns {boolean}
   */
  function isEllie () {
    return BAS_BUILD.ellie
  }

  /**
   * @returns {boolean}
   */
  function isEllieV1 () {
    return _state.isEllieV1
  }

  /**
   * @returns {boolean}
   */
  function isEllieV2 () {
    return _state.isEllieV2
  }

  /**
   * @returns {boolean}
   */
  function isLisa () {
    return BAS_BUILD.lisa
  }

  /**
   * @returns {boolean}
   */
  function isLena () {
    return BAS_BUILD.lena
  }

  /**
   * @returns {boolean}
   */
  function isCoreClient () {
    return isEllie() || isLisa() || isLena()
  }

  /**
   * @returns {boolean}
   */
  function hasCamera () {
    return isEllie() || isLena()
  }

  /**
   * @returns {boolean}
   */
  function hasOLEDScreen () {
    return isEllie()
  }

  /**
   * @returns {boolean}
   */
  function isLiveOnly () {
    return BAS_BUILD.liveOnly
  }

  /**
   * @returns {boolean}
   */
  function isLiveOnlyOrWebRTC () {
    return isLiveOnly() || isOnlyWebRTC()
  }

  /**
   * @returns {boolean}
   */
  function isProLive () {
    return _state.isProLiveHosted
  }

  /**
   * @returns {boolean}
   */
  function isSupportedBrowser () {
    return _state.supportedBrowser
  }

  /**
   * This app could be hosted on a Basalte Core
   *
   * @returns {boolean}
   */
  function isPossiblyCoreHosted () {
    return !!(
      isBrowser() &&
      !isCoreClient() &&
      !isLiveOnlyOrWebRTC() &&
      _state.isHttpProtocol &&
      !_state.isLocalHosted &&
      !_state.isCloudHosted &&
      _state.host
    )
  }

  /**
   * @returns {boolean}
   */
  function isDevLiveEnvironment () {
    return _state.devLiveEnvironment
  }

  /**
   * @returns {boolean}
   */
  function getPreferDemo () {
    return _state.preferDemo
  }

  /**
   * @returns {string?}
   */
  function getPreferredServer () {
    return _state.preferredServer
  }

  /**
   * @returns {string}
   */
  function getHost () {
    return _state.host
  }

  /**
   * @param {boolean} result
   */
  function setEllieV1 (result) {
    _state.isEllieV1 = result

    if (_state.isEllieV1) {
      _state.css[CSS_DEVICE_ELLIE_V1] = true
    }
  }

  /**
   * @param {boolean} result
   */
  function setEllieV2 (result) {
    _state.isEllieV2 = result
  }

  /**
   * @returns {boolean}
   */
  function supportsUnsecuredWebSocket () {
    return _state.supportsUnsecuredWebSocket
  }

  /**
   * @returns {boolean}
   */
  function supportsWebRTC () {
    return _state.supportsWebRTC
  }

  /**
   * @returns {boolean}
   */
  function supportsBasalteLive () {
    return _state.supportsBasalteLive
  }

  /**
   * @returns {boolean}
   */
  function supportsNotifications () {
    return _state.supportsNotifications
  }

  /**
   * @returns {boolean}
   */
  function supportsCustomImageUpload () {
    return _state.supportsCustomImageUpload
  }

  /**
   * @returns {boolean}
   */
  function supportsVolumeControl () {
    return _state.supportsVolumeControl
  }

  /**
   * @param {boolean} [value]
   */
  function toggleDebug (value) {

    _state.debug = BasUtil.isBool(value) ? value : !_state.debug
    _state.css[CSS_DEBUG] = _state.debug
  }

  /**
   * @param {boolean} [value]
   */
  function toggleOnlyWebRTC (value) {

    _state.onlyWebRTC = BasUtil.isBool(value) ? value : !_state.onlyWebRTC
    _state.css[CSS_ONLY_WEB_RTC] = _state.onlyWebRTC
  }

  /**
   * @returns {boolean}
   */
  function isOnlyWebRTC () {
    return (
      _state.supportsBasalteLive &&
      _state.onlyWebRTC
    )
  }

  /**
   * @param {boolean} [value]
   */
  function toggleWebRTCOnlyRelay (value) {

    _state.webRTCOnlyRelay = BasUtil.isBool(value)
      ? value
      : !_state.webRTCOnlyRelay
  }

  /**
   * @returns {boolean}
   */
  function isWebRTCOnlyRelay () {
    return (
      _state.supportsBasalteLive &&
      _state.webRTCOnlyRelay
    )
  }

  /**
   * @returns {string}
   */
  function getLocationSearch () {

    var location, search

    location = $window.location

    if (location) {

      search = location.search

      if (BasUtil.isNEString(search)) {

        return search
      }
    }

    return ''
  }

  /**
   * @returns {string}
   */
  function getProLiveLoginUrl () {

    var search, params, newParams

    search = getLocationSearch()

    if (search) params = Querystringify.parse(search.toLowerCase())

    newParams = {}
    newParams[URL_P_REDIRECT_URI] = '/app/index.html' +
      (
        (params && params[URL_P_PROJECT_ID])
          ? ('?' + URL_P_PROJECT_ID + '=' + params[URL_P_PROJECT_ID])
          : ''
      )

    return _state.protocol +
      '//' +
      _state.host +
      '/login' +
      '?' +
      Querystringify.stringify(newParams)
  }

  /**
   * @returns {string}
   */
  function getProLiveAccountSettingsUrl () {

    return _state.protocol + '//' + _state.host + '/account-settings'
  }

  /**
   * Return project ID if one is defined with URL params
   *
   * @returns {string}
   */
  function getPreferredProjectID () {

    var search, params

    search = getLocationSearch()

    if (search) {

      params = Querystringify.parse(search.toLowerCase())

      if (
        BasUtil.isObject(params) &&
        BasUtil.isNEString(params[URL_P_PROJECT_ID])
      ) {
        return params[URL_P_PROJECT_ID]
      }
    }

    return ''
  }

  /**
   * @param {number} [value]
   */
  function setPauseDisconnectTimeoutMs (value) {

    if (BasUtil.isPNumber(value, true)) {

      _state.pauseDisconnectTimeoutMs = value
    }
  }

  /**
   * @returns {number}
   */
  function getPauseDisconnectTimeoutMs () {
    return _state.pauseDisconnectTimeoutMs
  }

  function reloadPage () {

    var location

    location = $window.location

    if (location && BasUtil.isFunction(location.reload)) {

      location.reload()
    }
  }

  function _resetCss () {
    _state.css[CSS_DEVICE_BROWSER] = false
    _state.css[CSS_DEVICE_IOS] = false
    _state.css[CSS_DEVICE_ANDROID] = false
    _state.css[CSS_DEVICE_NORMAL] = false
    _state.css[CSS_DEVICE_ELLIE] = false
    _state.css[CSS_DEVICE_ELLIE_V1] = false
    _state.css[CSS_DEVICE_LISA] = false
    _state.css[CSS_DEVICE_CORE_CLIENT] = false
    _state.css[CSS_DEBUG] = false
    _state.css[CSS_LIVE_ONLY] = false
    _state.css[CSS_SUPPORTS_LIVE] = false
    _state.css[CSS_PRO_LIVE] = false
    _state.css[CSS_ONLY_WEB_RTC] = false
    _state.css[CSS_SUPPORTS_NOTIFICATIONS] = false
    _state.css[CSS_SUPPORTS_VOLUME_CONTROL] = false
  }

  function getCdvDevice () {

    var _cdvDevice

    _cdvDevice = $window[K_DEVICE]

    return (
      BasUtil.isObject(_cdvDevice) &&
      K_CORDOVA in _cdvDevice &&
      K_MODEL in _cdvDevice &&
      K_PLATFORM in _cdvDevice &&
      K_VERSION in _cdvDevice &&
      K_MANUFACTURER in _cdvDevice &&
      K_SERIAL in _cdvDevice
    )
      ? _cdvDevice
      : null
  }
}
