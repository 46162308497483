'use strict'

angular
  .module('basalteApp')
  .factory('BasSourceDatabase', [
    'BAS_LIBRARY_ERRORS',
    basSourceDatabaseFactory
  ])

/**
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @returns BasSourceDatabase
 */
function basSourceDatabaseFactory (
  BAS_LIBRARY_ERRORS
) {
  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourceDatabase (basSource) {

    /**
     * @private
     * @type {BasSource}
     */
    this._basSource = basSource
  }

  BasSourceDatabase.prototype.addSongsToPlaylist = function (id, songs) {

    if (this._basSource &&
      this._basSource.source &&
      this._basSource.source.database) {

      this._basSource.source.database.addSongsToPlaylist(id, songs)

      return Promise.resolve()
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_DATABASE)
  }

  BasSourceDatabase.prototype.getPlaylistContent = function (id) {

    if (this._basSource &&
      this._basSource.source &&
      this._basSource.source.database) {

      return this._basSource.source.database
        .getPlaylistContent(id)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_DATABASE)
  }

  BasSourceDatabase.prototype.addSongsToNewPlaylist = function (name, songs) {

    var _this = this

    if (this._basSource &&
      this._basSource.source &&
      this._basSource.source.database) {

      return this._basSource.source.database.addPlaylist(name)
        .then(onPlaylist)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_DATABASE)

    function onPlaylist (id) {

      _this.addSongsToPlaylist(id, songs)
    }
  }

  BasSourceDatabase.prototype.destroy = function () {

    this._basSource = null
  }

  return BasSourceDatabase
}
