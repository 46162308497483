'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceEllieDefaultView', [
    'BAS_HTML',
    deviceEllieDefaultView
  ])

function deviceEllieDefaultView (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.ellieDefaultViewDeviceSettings,
    controller: [
      '$rootScope',
      'BAS_CURRENT_CORE',
      'BAS_PREFERENCES',
      'BAS_ROOMS',
      'BAS_ROOM',
      'BAS_INPUT_MODAL',
      'BasPreferences',
      'BasCoreClientHelper',
      'CurrentBasCore',
      'BasInputModal',
      'BasUtilities',
      controller
    ],
    controllerAs: 'defaultView'
  }

  /**
   * @param $rootScope
   * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BAS_ROOMS} BAS_ROOMS
   * @param {BAS_ROOM} BAS_ROOM
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {BasPreferences} BasPreferences
   * @param {BasCoreClientHelper} BasCoreClientHelper
   * @param {CurrentBasCore} CurrentBasCore
   * @param {BasInputModal} BasInputModal
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    BAS_CURRENT_CORE,
    BAS_PREFERENCES,
    BAS_ROOMS,
    BAS_ROOM,
    BAS_INPUT_MODAL,
    BasPreferences,
    BasCoreClientHelper,
    CurrentBasCore,
    BasInputModal,
    BasUtilities
  ) {
    var defaultView = this

    var _listeners = []

    /**
     * @type {TBasModalInputSelectOption[]}
     */
    defaultView.VIEW_OPTIONS = []

    /**
     * @type {number}
     */
    defaultView.defaultView = BasPreferences.getDefaultView()

    /**
     * @type {string}
     */
    defaultView.uiDefaultView = ''

    /**
     * @type {?TBasCoreClientInfo}
     */
    defaultView.basCoreClientInfo = null

    defaultView.showEditDefaultView = showEditDefaultView

    defaultView.$onDestroy = _onDestroy

    init()

    function init () {

      _listeners.push($rootScope.$on(
        BAS_ROOMS.EVT_ROOMS_UPDATED,
        _onEllie
      ))

      _listeners.push($rootScope.$on(
        BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
        _onEllie
      ))

      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED,
        _generateDefaultViewOptions
      ))

      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_PROFILE_CREATED,
        _generateDefaultViewOptions
      ))

      _listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        _onLanguageChanged
      ))

      _syncUiDefaultView()
      _generateDefaultViewOptions()
      _onEllie()
    }

    function _onEllie () {

      defaultView.basCoreClientInfo = BasCoreClientHelper.getBasCoreClientInfo()
      _generateDefaultViewOptions()
    }

    function _generateDefaultViewOptions () {

      defaultView.VIEW_OPTIONS = []

      defaultView.VIEW_OPTIONS.push({
        value: BAS_PREFERENCES.VIEW_LAST_VIEW,
        text: 'last_view',
        translate: true
      })

      if (CurrentBasCore.hasHomePage()) {

        defaultView.VIEW_OPTIONS.push({
          value: BAS_PREFERENCES.VIEW_HOME,
          text: 'home',
          translate: true
        })
      }

      if (defaultView.basCoreClientInfo) {

        defaultView.VIEW_OPTIONS.push({
          value: BAS_PREFERENCES.VIEW_CCD_ROOM,
          text: defaultView.basCoreClientInfo.room.uiTitle
        })
      }
    }

    function showEditDefaultView () {

      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'default_view',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            options: defaultView.VIEW_OPTIONS,
            initialValue: defaultView.defaultView
          }
        ],
        autoAdvance: true,
        allowDismiss: true,
        autoSave: true,
        validCheck: BasUtil.isNEArray
      }).then(onModal)

      function onModal (modal) {

        modal.close.then(function (result) {

          if (BasUtil.isNEArray(result)) {

            defaultView.defaultView = result[0]
            BasPreferences.setDefaultView(defaultView.defaultView)
            _syncUiDefaultView()
          }
        })
      }
    }

    function _syncUiDefaultView () {

      switch (defaultView.defaultView) {
        case BAS_PREFERENCES.VIEW_CCD_ROOM:
          defaultView.uiDefaultView = BasUtilities.translate('ellie_room')
          break
        case BAS_PREFERENCES.VIEW_LAST_VIEW:
          defaultView.uiDefaultView = defaultView.basCoreClientInfo
            ? defaultView.basCoreClientInfo.room.uiTitle
            : BasUtilities.translate('last_view')
          break
        case BAS_PREFERENCES.VIEW_HOME:
          defaultView.uiDefaultView = BasUtilities.translate('home')
          break
      }
    }

    function _onLanguageChanged () {

      _syncUiDefaultView()
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = null
    }
  }
}
