[
  {
    "elapsed": 0,
    "id": 61,
    "random": false,
    "repeat": true,
    "state": "play",
    "total": 0
  },
  {
    "elapsed": 0,
    "id": 62,
    "random": true,
    "repeat": false,
    "state": "play",
    "total": 0
  },
  {
    "elapsed": 0,
    "id": 63,
    "random": false,
    "repeat": false,
    "state": "play",
    "total": 0
  },
  {
    "elapsed": 0,
    "id": 64,
    "random": false,
    "repeat": true,
    "state": "play",
    "total": 0
  }
]
