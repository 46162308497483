'use strict'

angular
  .module('basalteApp')
  .directive('basMessage', basMessage)

function basMessage () {

  return {
    restrict: 'AE',
    template: '<div class="bas-tile-message-img margin-horizontal-auto"' +
      '><div class="bas-tile-message-img-wrapper"' +
      ' ng-bind-html="basMessageData.img"></div></div>' +
      '<div class="bas-tile-message-title"' +
      ' ng-bind="basMessageData.txtMsgTitle"></div>' +
      '<div class="bas-tile-message-line"' +
      ' ng-bind="basMessageData.txtMsgLine1"></div>' +
      '<div class="bas-tile-message-line"' +
      ' ng-bind="basMessageData.txtMsgLine2"></div>' +
      '<div class="bas-tile-message-line bas-tile-message-line-padding-top' +
      ' bas-tile-message-line-fixed-height"' +
      ' ng-bind="basMessageData.txtMsgLine3"' +
      ' ng-if="basMessageData.hasThirdMsgLine"></div>' +
      '<div class="bas-tile-message-action bs-btn-base bs-btn-light"' +
      ' ng-bind="basMessageData.txtAction"' +
      ' bas-click="basMessageAction()"></div>' +
      '<div class="bas-tile-message-line bas-tile-message-line-spaced"' +
      ' ng-bind="basMessageData.txtActionOr"></div>' +
      '<div class="bas-tile-message-action bs-btn-base bs-btn-light"' +
      ' ng-bind="basMessageData.txtAction2"' +
      ' bas-click="basMessageActionAlt()"></div>',
    scope: {
      basMessageData: '<?',
      basMessageAction: '&?',
      basMessageActionAlt: '&?'
    }
  }
}
