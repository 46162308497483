'use strict'

angular
  .module('basalteApp')
  .controller('changeLiveCtrl', [
    'STATES',
    'BAS_MODAL',
    'BasState',
    'BasLiveAccount',
    'BasCoreConnection',
    'BasModal',
    changeLiveCtrl
  ])

/**
 * @param {STATES} STATES
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasState} BasState
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasCoreConnection} BasCoreConnection
 * @param {BasModal} BasModal
 */
function changeLiveCtrl (
  STATES,
  BAS_MODAL,
  BasState,
  BasLiveAccount,
  BasCoreConnection,
  BasModal
) {
  var change = this

  /**
   * @type {TBasLiveAccountState}
   */
  change.basLiveAccountState = BasLiveAccount.get()

  /**
   * @type {string}
   */
  change.currentPassword = ''

  /**
   * @type {string}
   */
  change.newPassword = ''

  /**
   * @type {string}
   */
  change.newPasswordConfirmation = ''

  change.setNewPasword = setNewPassword

  function setNewPassword (form) {

    if (form.$valid) {

      BasLiveAccount.changePassword(
        change.currentPassword,
        change.newPassword,
        _onConfirmNewPassword
      )

    } else {

      BasModal.show(BAS_MODAL.T_ERROR)
    }

    function _onConfirmNewPassword (error) {

      if (error) {

        BasModal.show(BAS_MODAL.T_WRONG_PASSWORD)

      } else {

        BasModal.show(BAS_MODAL.T_PASSWORD_CHANGED)
          .then(_onPasswordChangedModal)
      }
    }
  }

  function _onPasswordChangedModal (modal) {

    modal.closed.then(_onPasswordChangedClosed)
  }

  function _onPasswordChangedClosed () {

    BasCoreConnection.prepareChangeBasCore()

    BasState.go(STATES.CONNECT_DISCOVERY)
  }
}
