'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('MainStartupState', [
    '$rootScope',
    'STATES',
    'BAS_API',
    'BAS_STATE',
    'BAS_PREFERENCES',
    'BAS_ERRORS',
    'BAS_CURRENT_CORE',
    'BasState',
    'BasAppDevice',
    'BasPreferences',
    'CurrentRoom',
    'BasError',
    'BasConditionsHelper',
    'CurrentBasCore',
    'BasLisaUi',
    MainStartupState
  ])

/**
 * @constructor
 * @param $rootScope
 * @param {STATES} STATES
 * @param BAS_API
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BasState} BasState
 * @param {BasAppDevice} BasAppDevice
 * @param {BasPreferences} BasPreferences
 * @param {CurrentRoom} CurrentRoom
 * @param BasError
 * @param {BasConditionsHelper} BasConditionsHelper
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasLisaUi} BasLisaUi
 */
function MainStartupState (
  $rootScope,
  STATES,
  BAS_API,
  BAS_STATE,
  BAS_PREFERENCES,
  BAS_ERRORS,
  BAS_CURRENT_CORE,
  BasState,
  BasAppDevice,
  BasPreferences,
  CurrentRoom,
  BasError,
  BasConditionsHelper,
  CurrentBasCore,
  BasLisaUi
) {

  var STATE_FALLBACK_WAIT_MS = 5000

  var stateRoomsMusic = BasState.target(
    STATES.ROOMS,
    {
      view: BAS_STATE.S_ROOMS_VIEW_MUSIC
    }
  )

  this.determine = determine

  /**
   * Basically just a wrapper around determineRawState to easily use with
   * state transitions
   *
   * @param {BasCoreContainer} basCoreContainer
   * @returns {Promise} A BasState.target promise
   */
  function determine (basCoreContainer) {

    return (
      basCoreContainer &&
      basCoreContainer.hasCore &&
      basCoreContainer.hasCore()
    )
      ? new Promise(mainPromiseConstructor)
      : Promise.resolve(BasState.target(STATES.CONNECT_DISCOVERY))

    /**
     * Promise constructor for entering the main app state.
     * This promise will check with the server and localStorage
     * to determine the start state.
     *
     * @param resolve
     * @param reject
     */
    function mainPromiseConstructor (resolve, reject) {

      var _finished, timeoutId, versionWait, systemProfileWait, roomsWait,
        roomSelectedWait, musicConfigWait

      // Set timeout fallback
      timeoutId = setTimeout(_onTimeout, STATE_FALLBACK_WAIT_MS)

      const connectedListener = $rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
        _onCoreConnected
      )

      // Start waiting for version
      versionWait = BasConditionsHelper.waitForVersion(
        {
          basCoreContainer: basCoreContainer
        },
        _onVersion
      )

      /**
       * @private
       * @param {?BasError} error
       */
      function _onVersion (error) {

        versionWait = undefined

        if (error) {

          if (_onDefaultError(error)) return

          _onUncaughtError()

        } else {

          systemProfileWait = BasConditionsHelper.waitForSystemAndProfile(
            {
              basCoreContainer: basCoreContainer
            },
            _onSystemProperties
          )
        }
      }

      /**
       * Default error handler for waiters
       *
       * @private
       * @param {?BasError} error
       * @returns {boolean} errorHandled
       */
      function _onDefaultError (error) {

        if (error instanceof BasError) {

          if (error.basType === BAS_ERRORS.M_TIMEOUT) {

            _onTimeout()
            return true

          } else if (error.basType === BAS_ERRORS.T_NO_CORE) {

            _onNoCore()
            return true

          } else if (error.basType === BAS_ERRORS.T_ABORT) {

            _onAborted()
            return true
          }
        }

        return false
      }

      /**
       * Only for >2.0.0 servers
       *
       * @private
       * @param {?BasError} error
       */
      function _onSystemProperties (error) {

        systemProfileWait = undefined

        if (error) {

          if (_onDefaultError(error)) return
          if (_onSystemPropertiesError(error)) return

          _onUncaughtError()

        } else {

          roomsWait = BasConditionsHelper.waitForRooms(
            {
              basCoreContainer: basCoreContainer
            },
            _onRoomsUpdated
          )
        }
      }

      /**
       * Error handler for system properties waiter
       *
       * @private
       * @param {?BasError} error
       * @returns {boolean} errorHandled
       */
      function _onSystemPropertiesError (error) {

        if (error instanceof BasError &&
          error.basType === BAS_ERRORS.T_NOT_SUPPORTED) {

          musicConfigWait = BasConditionsHelper.waitForMusicConfig(
            {
              basCoreContainer: basCoreContainer
            },
            _onMusicConfig
          )
          return true

        }

        return false
      }

      /**
       * Only for >2.0.0 servers
       *
       * @private
       * @param {?BasError} error
       */
      function _onRoomsUpdated (error) {

        var startupView, defaultView, waitForSelectedRoom
        roomsWait = undefined

        if (error) {

          if (_onDefaultError(error)) return

          _onUncaughtError()

        } else {

          waitForSelectedRoom = false

          if (BasAppDevice.isLisa()) {

            return BasConditionsHelper.waitForSharedStorageLisaShowStart(
              null,
              () => {
                _finish(
                  BasLisaUi.getLisaStartupState()
                )
              }
            )

          } else if (BasAppDevice.isEllie()) {

            defaultView = BasPreferences.getDefaultView()

            switch (defaultView) {
              case BAS_PREFERENCES.VIEW_CCD_ROOM:
                waitForSelectedRoom = true
                break
              case BAS_PREFERENCES.VIEW_LAST_VIEW:
                // Nothing special to be done
                break
              case BAS_PREFERENCES.VIEW_HOME:
                _finish(BasState.target(
                  basCoreContainer.hasHomePage()
                    ? STATES.HOME
                    : STATES.ROOMS
                ))
                return
            }

          } else {

            // Not Lisa or Ellie: Use startupView setting

            startupView = BasPreferences.getStartupView()

            switch (startupView.view) {
              case BAS_PREFERENCES.VIEW_LAST_ROOM:
                if (
                  BasUtil.isNEString(CurrentBasCore.getLastRoomId()) ||
                  (
                    BasUtil.isNEString(
                      basCoreContainer.core.singleAudioRoomId
                    ) &&
                    basCoreContainer.core.system.audioOnly
                  )
                ) {
                  waitForSelectedRoom = true
                }
                break
              case BAS_PREFERENCES.VIEW_SINGLE_ROOM:
                waitForSelectedRoom = true
                break
              case BAS_PREFERENCES.VIEW_ROOMS:
                _finish(BasState.target(STATES.ROOMS))
                return
              case BAS_PREFERENCES.VIEW_HOME:
              default:
                _finish(
                  BasState.target(
                    basCoreContainer.hasHomePage()
                      ? STATES.HOME
                      : STATES.ROOMS
                  )
                )
                return
            }
          }

          if (waitForSelectedRoom) {

            roomSelectedWait = BasConditionsHelper.waitForCurrentRoom(
              null,
              _onRoomSelected
            )

          } else {

            _finish(BasState.target(STATES.ROOMS))
          }
        }
      }

      /**
       * @private
       * @param {?BasError} error
       */
      function _onRoomSelected (error) {

        var room

        roomSelectedWait = undefined

        if (error) {

          if (_onDefaultError(error)) return
          if (_onRoomSelectedError(error)) return

          _onUncaughtError()

        } else {

          room = CurrentRoom.getRoom()

          if (room) {

            if (basCoreContainer.core.system.audioOnly) {

              if (BAS_API.Room.isLvlRoom(room.room) &&
                room.room.functions.audio) {

                _finish(BasState.target(
                  STATES.MUSIC_PLAYER,
                  {
                    room: room.id
                  }
                ))

              } else {

                _finish(stateRoomsMusic)
              }

            } else {

              _finish(BasState.target(
                STATES.ROOM,
                {
                  room: room.id
                }
              ))
            }

          } else {

            if (basCoreContainer.core.system.audioOnly) {

              _finish(stateRoomsMusic)

            } else {

              _finish(BasState.target(STATES.ROOMS))
            }
          }
        }
      }

      /**
       * @private
       * @param {?BasError} _error
       * @returns {boolean} errorHandled
       */
      function _onRoomSelectedError (_error) {

        _finish(BasState.target(
          basCoreContainer.hasHomePage()
            ? STATES.HOME
            : STATES.ROOMS
        ))
        return true
      }

      /**
       * Room should exist
       * for <2.0.0 servers
       *
       * @private
       * @param {?BasError} error
       */
      function _onMusicConfig (error) {

        var _lastRoomId, _lastAudioZone, _roomId

        musicConfigWait = undefined

        if (error) {

          if (_onDefaultError(error)) return

          _onUncaughtError()

        } else {

          _lastRoomId = basCoreContainer.getLastRoomIdForLastUser()

          _lastAudioZone = _lastRoomId
            ? basCoreContainer.core.zoneForId(_lastRoomId)
            : null

          _roomId = (_lastAudioZone && _lastAudioZone.id)
            ? _lastAudioZone.id
            : basCoreContainer.core.singleAudioRoomId

          if (_roomId) {

            _finish(BasState.target(
              STATES.MUSIC_PLAYER,
              {
                room: _roomId
              }
            ))

          } else {

            _finish(stateRoomsMusic)
          }
        }
      }

      /**
       * @private
       */
      function _onTimeout () {

        // Finish with state rooms music first
        _finish(stateRoomsMusic)

        abortAll()
      }

      /**
       * @param _event
       * @param _core
       * @param isConnected
       * @private
       */
      function _onCoreConnected (_event, _core, isConnected) {

        if (!isConnected) {
          // Disconnected, back to discovery
          _finish(BasState.target(STATES.CONNECT_DISCOVERY))
          abortAll()
        }
      }

      function abortAll () {
        // Following aborts can trigger error handlers here, but promise
        //  is already finished so that doesn't matter anymore.
        if (musicConfigWait) BasUtil.execute(musicConfigWait.abort)
        if (roomSelectedWait) BasUtil.execute(roomSelectedWait.abort)
        if (roomsWait) BasUtil.execute(roomsWait.abort)
        if (systemProfileWait) BasUtil.execute(systemProfileWait.abort)
        if (versionWait) BasUtil.execute(versionWait.abort)
      }

      /**
       * @private
       */
      function _onNoCore () {

        _finish(BasState.target(STATES.CONNECT_DISCOVERY))
      }

      /**
       * @private
       */
      function _onAborted () {

        _finish(BasState.target(STATES.CONNECT_DISCOVERY))
      }

      /**
       * @private
       */
      function _onUncaughtError () {

        _finish(BasState.target(STATES.CONNECT_DISCOVERY))
      }

      /**
       * Finish the promise
       *
       * @private
       * @param {*} innerResolve
       * @param {*} [innerReject]
       */
      function _finish (innerResolve, innerReject) {

        if (!_finished) {

          _finished = true
          clearTimeout(timeoutId)
          connectedListener()

          if (innerResolve) {

            resolve(innerResolve)

          } else {

            reject(innerReject)
          }
        }
      }
    }
  }
}
