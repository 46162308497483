'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('aboutSettingsCtrl', [
    '$rootScope',
    '$scope',
    'ModalService',
    'STATES',
    'SETTINGS_STATES',
    'BAS_HTML',
    'BAS_CURRENT_CORE',
    'BAS_CONNECTED_DEVICES',
    'BAS_MODAL',
    'BAS_INPUT_MODAL',
    'BAS_DEVICE',
    'BAS_CORE_CLIENT',
    'BAS_UPDATER',
    'BAS_CORE',
    'BAS_REMOTE_JS',
    'BasState',
    'BasCoreConnection',
    'CurrentBasCore',
    'BasCoreApiHelper',
    'BasUpdater',
    'BasConnectedDevices',
    'BasRemoteJs',
    'BasModal',
    'BasInputModal',
    'BasApp',
    'BasAppDevice',
    'BasPreferences',
    'AppLink',
    'BasCoreClient',
    'BasTime',
    'BasUtilities',
    aboutSettingsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param ModalService
 * @param {STATES} STATES
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_CONNECTED_DEVICES} BAS_CONNECTED_DEVICES
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param {BAS_DEVICE} BAS_DEVICE
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 * @param {BAS_UPDATER} BAS_UPDATER
 * @param {BAS_CORE} BAS_CORE
 * @param {BAS_REMOTE_JS} BAS_REMOTE_JS
 * @param {BasState} BasState
 * @param {BasCoreConnection} BasCoreConnection
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasCoreApiHelper} BasCoreApiHelper
 * @param {BasUpdater} BasUpdater
 * @param {BasConnectedDevices} BasConnectedDevices
 * @param {BasRemoteJs} BasRemoteJs
 * @param {BasModal} BasModal
 * @param {BasInputModal} BasInputModal
 * @param {BasApp} BasApp
 * @param {BasAppDevice} BasAppDevice
 * @param {BasPreferences} BasPreferences
 * @param {AppLink} AppLink
 * @param {BasCoreClient} BasCoreClient
 * @param {BasTime} BasTime
 * @param {BasUtilities} BasUtilities
 */
function aboutSettingsCtrl (
  $rootScope,
  $scope,
  ModalService,
  STATES,
  SETTINGS_STATES,
  BAS_HTML,
  BAS_CURRENT_CORE,
  BAS_CONNECTED_DEVICES,
  BAS_MODAL,
  BAS_INPUT_MODAL,
  BAS_DEVICE,
  BAS_CORE_CLIENT,
  BAS_UPDATER,
  BAS_CORE,
  BAS_REMOTE_JS,
  BasState,
  BasCoreConnection,
  CurrentBasCore,
  BasCoreApiHelper,
  BasUpdater,
  BasConnectedDevices,
  BasRemoteJs,
  BasModal,
  BasInputModal,
  BasApp,
  BasAppDevice,
  BasPreferences,
  AppLink,
  BasCoreClient,
  BasTime,
  BasUtilities
) {
  var about = this

  var VERSION_TIMEOUT = 1000

  var versionTimeoutId = null

  var _servers = {}

  var _listeners = []
  var longPressResetTimeout

  /**
   * @type {TBasAppState}
   */
  var basAppState = BasApp.get()

  /**
   * @type {BAS_CORE_CLIENT}
   */
  about.BAS_CORE_CLIENT = BAS_CORE_CLIENT

  /**
   * @type {SETTINGS_STATES}
   */
  about.SETTINGS_STATES = SETTINGS_STATES

  /**
   * @type {TCurrentBasCoreState}
   */
  about.currentCoreState = CurrentBasCore.get()

  /**
   * @type {TBasConnectedDevicesState}
   */
  about.connectedDevices = BasConnectedDevices.get()

  /**
   * @type {BasUpdaterState}
   */
  about.updaterState = BasUpdater.get()

  /**
   * @type {TBasRemoteJsState}
   */
  about.basRemoteJsState = BasRemoteJs.get()

  /**
   * @type {TBasTime}
   */
  about.basTimeState = BasTime.get()

  about.deviceName = ''
  about.isCoreClient = BasAppDevice.isCoreClient()

  about.version = basAppState.version

  about.txtUpdateBtn = ''
  about.txtUpdateBtnSub = ''

  about.txtCoreClientUpdateBtn = ''
  about.txtCoreClientUpdateBtnSub = ''
  about.txtCoreClientUpdateBtnProgress = ''

  about.serverUpdateInProgress = false
  about.coreClientUpdateInProgress = false

  /**
   * @type {boolean}
   */
  about.initialHasDebugEnabled = BasPreferences.getDebug()
  about.showDebug = false

  about.updateBtnAction = updateBtnAction
  about.clickVersion = clickVersion
  about.showStatus = showStatus
  about.coreClientUpdateAction = coreClientUpdateAction
  about.switchServerAction = switchServerAction
  about.clickServerVersion = clickServerVersion
  about.multiClickApplication = multiClickApplication
  about.multiClickCoreClientDevice = multiClickCoreClientDevice
  about.multiClickAuthor = multiClickAuthor
  about.toggleFreezeTime = toggleFreezeTime

  init()

  function init () {

    if (BasAppDevice.isCoreClient()) {

      if (BasAppDevice.isEllie()) {

        about.deviceName = 'Ellie'

      } else if (BasAppDevice.isLisa()) {

        about.deviceName = 'Lisa'

      } else if (BasAppDevice.isLena()) {

        about.deviceName = 'Lena'

      } else {

        about.deviceName = 'Unknown'
      }
    }

    _syncUpdateButton()
    setNormalVersion()

    _syncUpdaterUpdateBtn()

    BasUpdater.getUpdateInfo()

    $scope.$on('$destroy', destroy)

    _listeners.push($rootScope.$on(
      BAS_CONNECTED_DEVICES.EVT_BAS_CONNECTED_DEVICES_UPDATED,
      onDevicesUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_CONNECTED_DEVICES.EVT_BAS_SERVERS_UPDATED,
      onServersUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_DEVICE.EVT_SERVER_DEVICE_UPDATED,
      onServerUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_CONNECTED_DEVICES.EVT_BAS_SERVERS_UPDATE_STATE,
      onServerUpdateState
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_HAS_UPDATE,
      onServerUpdateState
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_IS_ADMIN,
      onAdminSet
    ))
    _listeners.push($rootScope.$on(
      BAS_CORE_CLIENT.EVT_CORE_CLIENT_SERIAL_NUMBER,
      _onCoreClientSerialNumber
    ))
    _listeners.push($rootScope.$on(
      BAS_CORE_CLIENT.EVT_CORE_CLIENT_MAC_ADDRESS,
      _onCoreClientMacAddress
    ))
    _listeners.push($rootScope.$on(
      BAS_UPDATER.EVT_UPDATER_UPDATE_INFO,
      _onUpdaterUpdateInfo
    ))
    _listeners.push($rootScope.$on(
      BAS_DEVICE.EVT_SERVER_DEVICE_UPDATE_STATE,
      _onServerDeviceUpdateState
    ))
    _listeners.push($rootScope.$on(
      BAS_REMOTE_JS.EVT_BAS_REMOTE_JS_STATE_CHANGED,
      _onBasRemoteJsStateChanged
    ))
    _listeners.push($rootScope.$on(
      '$translateChangeSuccess',
      onLanguageChanged
    ))

    if (CurrentBasCore.hasCore()) {

      updateServerStatus()
    }
  }

  function updateBtnAction () {

    if (CurrentBasCore.has()) {

      if (BasCoreApiHelper.hasUpdate()) {

        BasCoreApiHelper.restartServer()

      } else {

        about.currentCoreState.core.checkForUpdates()
      }
    }
  }

  function switchServerAction () {

    BasCoreConnection.prepareChangeBasCore()

    BasState.go(
      STATES.CONNECT_DISCOVERY,
      null,
      {
        custom:
          {
            BasExitMainState: true
          }
      }
    )
  }

  function setNormalVersion () {

    clearVersionTimeout()

    about.version = basAppState.version

    _scopeSync()
  }

  function clickVersion () {

    clearVersionTimeout()
    about.version = basAppState.versionFull
    versionTimeoutId = setTimeout(setNormalVersion, VERSION_TIMEOUT)
  }

  function clearVersionTimeout () {

    clearTimeout(versionTimeoutId)
  }

  function multiClickApplication () {

    about.showDebug = true
  }

  function multiClickCoreClientDevice () {

    if (BasAppDevice.isCoreClient()) {

      AppLink.openAppUri(BAS_UPDATER.APP_URI_UPDATER_LEGACY)
      AppLink.openAppUri(BAS_UPDATER.APP_URI_UPDATER)
    }
  }

  function multiClickAuthor () {

    if (BasAppDevice.isCoreClient()) {

      BasModal.show(BAS_MODAL.T_APP_RESTART)
        .then(_onRestartAppModal)
    }

    function _onRestartAppModal (modal) {

      modal.close.then(_onRestartAppModalClose)
    }

    function _onRestartAppModalClose (result) {

      if (result === BAS_MODAL.C_YES) {

        BasCoreClient.restartApp()
      }
    }
  }

  /**
   * @param {string} serverUuid
   */
  function clickServerVersion (serverUuid) {

    var _serverDevice

    _clearResetAllServerVersions()

    _serverDevice = BasConnectedDevices.getServer(serverUuid)

    if (_serverDevice) {

      _serverDevice.uiToggleVersion(BAS_DEVICE.T_VERSION_LONG)

      _servers[serverUuid] = setTimeout(
        _resetServerUiVersion,
        VERSION_TIMEOUT
      )
    }

    function _resetServerUiVersion () {

      var _serverDeviceToUIReset

      _serverDeviceToUIReset = BasConnectedDevices.getServer(serverUuid)

      if (_serverDeviceToUIReset) {

        _serverDeviceToUIReset.uiToggleVersion(BAS_DEVICE.T_VERSION_SHORT)

        _scopeSync()
      }
    }
  }

  function toggleFreezeTime () {
    if (about.basTimeState.frozen) {
      BasTime.unfreezeTime()
    } else {
      BasInputModal.show(
        BAS_INPUT_MODAL.T_TIME,
        {
          title: 'time',
          time: new Date()
        }
      ).then(_onModal)
    }
  }
  function _onModal (modal) {

    modal.close.then(_onModalClose)
  }

  function _onModalClose (result) {
    if (result instanceof Date) BasTime.freezeTime(result)
  }

  function _clearResetAllServerVersions () {

    var keys, length, i, _serverId, _server

    keys = Object.keys(_servers)
    length = keys.length
    for (i = 0; i < length; i++) {

      _serverId = keys[i]
      _server = _servers[_serverId]

      clearTimeout(_server)
    }

    _servers = {}

    BasConnectedDevices.uiToggleAllServerVersions(
      BAS_DEVICE.T_VERSION_SHORT
    )
  }

  function coreClientUpdateAction () {

    switch (about.updaterState.uiUpdateState) {
      case BAS_UPDATER.S_UPDATE_READY:
        BasModal.show(BAS_MODAL.T_CORE_CLIENT_UPDATE)
          .then(_onCoreClientUpdateModal)
        break
      case BAS_UPDATER.S_UPDATE_CHECKING:
      case BAS_UPDATER.S_UPDATE_DOWNLOADING:
      case BAS_UPDATER.S_UPDATE_VERIFYING:
      case BAS_UPDATER.S_UPDATE_INSTALLING:
        // Do nothing
        break
      case BAS_UPDATER.S_UPDATE_NONE:
      default:
        BasUpdater.checkForUpdates()
        break
    }
  }

  function _onCoreClientUpdateModal (modal) {

    modal.close.then(_onCoreClientUpdateModalClose)
  }

  function _onCoreClientUpdateModalClose (result) {

    if (result === BAS_MODAL.C_YES) {

      BasUpdater.performInstall()
    }
  }

  function onAdminSet () {

    _scopeSync()
  }

  function onDevicesUpdated () {

    _scopeSync()
  }

  function onServersUpdated () {

    _syncUpdateButton()
    updateServerStatus()

    _scopeSync()
  }

  /**
   * Fired when a BasServerDevice has been updated
   *
   * @param {*} _event
   * @param {BasServerDevice} _basServer
   */
  function onServerUpdated (_event, _basServer) {

    _scopeSync()
  }

  function onServerUpdateState () {

    _syncUpdateButton()

    _scopeSync()
  }

  function onLanguageChanged () {

    _syncUpdateButton()
    _syncUpdaterUpdateBtn()

    _scopeSync()
  }

  function updateServerStatus () {

    var promises, i, length, promise

    promises = BasConnectedDevices.retrieveServerStatus()

    if (!Array.isArray(promises)) promises = [promises]

    length = promises.length
    for (i = 0; i < length; i++) {

      promise = promises[i]

      if (BasUtil.isObject(promise) &&
        BasUtil.isFunction(promise.then)) {

        promise.then(_scopeSync, _ignore)
      }
    }

    function _ignore () {
      // Empty
    }
  }

  /**
   * @param {string} serverUuid
   */
  function showStatus (serverUuid) {

    var _serverDevice

    _serverDevice = BasConnectedDevices.getServer(serverUuid)

    if (
      _serverDevice &&
      _serverDevice.hasErrors
    ) {
      ModalService.showModal({
        template: BAS_HTML.serverStatusModal,
        controller: 'serverStatusCtrl',
        controllerAs: 'modal',
        inputs: {
          serverUuid: serverUuid
        }
      })
    }
  }

  function _onCoreClientSerialNumber () {

    _scopeSync()
  }

  function _onCoreClientMacAddress () {

    _scopeSync()
  }

  function _onUpdaterUpdateInfo () {

    _syncUpdaterUpdateBtn()

    _scopeSync()
  }

  function _onServerDeviceUpdateState () {

    _syncUpdateButton()
  }

  function _onBasRemoteJsStateChanged () {

    _scopeSync()
  }

  function _syncUpdaterUpdateBtn () {

    var progress

    about.txtCoreClientUpdateBtn = ''
    about.txtCoreClientUpdateBtnSub = ''
    about.txtCoreClientUpdateBtnProgress = ''
    about.coreClientUpdateInProgress = false

    switch (about.updaterState.uiUpdateState) {
      case BAS_UPDATER.S_UPDATE_READY:

        about.txtCoreClientUpdateBtn =
          BasUtilities.translate('install_update')

        break
      case BAS_UPDATER.S_UPDATE_CHECKING:

        about.txtCoreClientUpdateBtn =
          BasUtilities.translate('checking_for_updates')
        about.coreClientUpdateInProgress = true

        break
      case BAS_UPDATER.S_UPDATE_DOWNLOADING:
      case BAS_UPDATER.S_UPDATE_INSTALLING:

        about.txtCoreClientUpdateBtn =
          BasUtilities.translate('update_in_progress')
        about.txtCoreClientUpdateBtnSub =
          about.updaterState.uiUpdateState === BAS_UPDATER.S_UPDATE_INSTALLING
            ? BasUtilities.translate('installing') + '...'
            : BasUtilities.translate('downloading') + '...'
        about.coreClientUpdateInProgress = true

        progress = BasUpdater.getSystemProgress()

        if (progress > -1) {

          about.txtCoreClientUpdateBtnProgress = progress + '%'
        }

        break
      case BAS_UPDATER.S_UPDATE_VERIFYING:

        about.txtCoreClientUpdateBtn =
          BasUtilities.translate('update_in_progress')
        about.txtCoreClientUpdateBtnSub =
          BasUtilities.translate('verifying')
        about.coreClientUpdateInProgress = true

        break
      case BAS_UPDATER.S_UPDATE_NONE:
      default:

        about.txtCoreClientUpdateBtn =
          BasUtilities.translate('check_for_updates')

        break
    }
  }

  function _syncUpdateButton () {

    switch (BasCoreApiHelper.getUpdateState()) {

      case BAS_CORE.CORE_UPDATE_S_CHECKING:

        about.txtUpdateBtn =
          BasUtilities.translate('checking_for_updates')
        about.txtUpdateBtnSub = ''
        about.serverUpdateInProgress = true

        break

      case BAS_CORE.CORE_UPDATE_S_DOWNLOADING:

        about.txtUpdateBtn = BasUtilities.translate('update_in_progress')
        about.txtUpdateBtnSub = BasUtilities.translate('downloading') +
          '...'
        about.serverUpdateInProgress = true

        break

      case BAS_CORE.CORE_UPDATE_S_READY:

        about.txtUpdateBtn = BasUtilities.translate('install_update')
        about.txtUpdateBtnSub = ''
        about.serverUpdateInProgress = false

        break

      default:

        about.txtUpdateBtn = BasUtilities.translate('check_for_updates')
        about.txtUpdateBtnSub = ''
        about.serverUpdateInProgress = false

        break
    }
  }

  function _scopeSync () {

    $scope.$applyAsync()
  }

  function destroy () {

    clearVersionTimeout()
    _clearResetAllServerVersions()

    clearTimeout(longPressResetTimeout)

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
