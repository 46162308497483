'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('CurrentRoom', [
    '$rootScope',
    '$transitions',
    'STATES',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_PREFERENCES',
    'BasState',
    'BasStateHelper',
    'CurrentBasCore',
    'RoomsHelper',
    'BasAppDevice',
    'BasCoreClientHelper',
    'BasPreferences',
    CurrentRoom
  ])

/**
 * @typedef {Object} TCurrentRoomState
 * @property {string} roomId
 */

/**
 * Helper service to keep track of the current selected room
 *
 * @constructor
 * @param $rootScope
 * @param $transitions
 * @param {STATES} STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BasState} BasState
 * @param {BasStateHelper} BasStateHelper
 * @param {CurrentBasCore} CurrentBasCore
 * @param {RoomsHelper} RoomsHelper
 * @param {BasAppDevice} BasAppDevice
 * @param {BasCoreClientHelper} BasCoreClientHelper
 * @param {BasPreferences} BasPreferences
 */
function CurrentRoom (
  $rootScope,
  $transitions,
  STATES,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_PREFERENCES,
  BasState,
  BasStateHelper,
  CurrentBasCore,
  RoomsHelper,
  BasAppDevice,
  BasCoreClientHelper,
  BasPreferences
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TCurrentRoomState}
   */
  var state = {
    roomId: ''
  }

  this.get = get
  this.clear = clear
  this.set = set
  this.go = go
  this.getRoom = getRoom
  this.getRoomMusic = getRoomMusic
  this.getRoomVideo = getRoomVideo
  this.getBasSource = getBasSource
  this.getVideoBasSource = getVideoBasSource

  init()

  function init () {

    $transitions.onStart(
      {},
      _onStateStart,
      {
        priority: 10
      }
    )

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    )
    $rootScope.$on(
      BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
      _onCoreClientsUpdated
    )
  }

  /**
   * @returns {TCurrentRoomState}
   */
  function get () {
    return state
  }

  /**
   * Set the current Room
   *
   * @param {?BasRoom} room
   */
  function set (room) {

    var _core

    if (CurrentBasCore.hasCore()) {

      _core = currentBasCoreState.core.core

      if (_core.supportsSystemProperties) {

        if (_core.roomsReceived) _set(room)

      } else {

        if (_core.musicConfigReceived) _set(room)
      }
    }
  }

  /**
   * @private
   * @param {?BasRoom} room
   */
  function _set (room) {

    var oldId

    oldId = state.roomId

    if (room && room.isRoom && BasUtil.isNEString(room.id)) {

      state.roomId = room.id

    } else {

      clear()
    }

    if (oldId !== state.roomId) {

      $rootScope.$emit(BAS_ROOMS.EVT_CURRENT_ROOM_CHANGED, state.roomId)
    }
  }

  function _onRoomsUpdated () {

    if (CurrentBasCore.hasCore()) {

      _syncCurrentRoom()
    }
  }

  function _onCoreClientsUpdated () {

    if (CurrentBasCore.hasCore()) {

      _syncCurrentRoom()
    }
  }

  function _syncCurrentRoom () {

    var basDeviceInfo, newRoom, lastRoomId, startupView, defaultView

    newRoom = null

    // Lisa device: select room from Lisa info
    if (BasAppDevice.isLisa()) {

      basDeviceInfo = BasCoreClientHelper.getBasCoreClientInfo()

      if (basDeviceInfo && basDeviceInfo.room) {

        newRoom = RoomsHelper.getRoomForId(basDeviceInfo.room.id)
      }

      // Hack: prefer 'GroundFloorLivingRoom' in Lisa demo mode
      if (
        !newRoom &&
        CurrentBasCore.has() &&
        currentBasCoreState.core.isDemo()
      ) {

        newRoom = RoomsHelper.getRoomForId('GroundFloorLivingRoom')
      }
    }

    // Ellie device: select room from ellie info if default view is set
    //  to ELLIE_ROOM
    if (BasAppDevice.isEllie()) {

      defaultView = BasPreferences.getDefaultView()

      if (defaultView === BAS_PREFERENCES.VIEW_CCD_ROOM) {

        basDeviceInfo = BasCoreClientHelper.getBasCoreClientInfo()

        if (basDeviceInfo && basDeviceInfo.room) {

          newRoom = RoomsHelper.getRoomForId(basDeviceInfo.room.id)
        }
      }
    }

    // No room selected in previous step, check state.roomId
    if (
      !newRoom &&
      BasUtil.isNEString(state.roomId)
    ) {

      newRoom = RoomsHelper.getRoomForId(state.roomId)
    }

    // Try to select room according to startupView preference.
    if (!newRoom) {

      startupView = BasPreferences.getStartupView()

      if (startupView.view === BAS_PREFERENCES.VIEW_SINGLE_ROOM) {

        newRoom = RoomsHelper.getRoomForId(startupView.room)
      }
    }

    // Audio only and single room: select singleAudioRoomId
    if (!newRoom &&
      currentBasCoreState.core.core.system.audioOnly &&
      BasUtil.isNEString(
        currentBasCoreState.core.core.singleAudioRoomId
      )) {

      newRoom = RoomsHelper.getRoomForId(
        currentBasCoreState.core.core.singleAudioRoomId
      )
    }

    // No room selected in previous step, try to select last selected room
    if (!newRoom) {

      lastRoomId = CurrentBasCore.getLastRoomId()

      if (BasUtil.isNEString(lastRoomId)) {

        newRoom = RoomsHelper.getRoomForId(lastRoomId)
      }
    }

    // No room selected in previous step, if Lisa device make sure a room
    //  is selected.
    if (!newRoom && BasAppDevice.isLisa()) {

      newRoom = RoomsHelper.getFirstRoom()
    }

    set(newRoom)
  }

  function _onStateStart (transition) {

    var previousRoomId, target, roomIdParam, newRoom

    previousRoomId = state.roomId
    target = transition.targetState()
    roomIdParam = transition.params('to').room

    if (BasUtil.isString(roomIdParam) && roomIdParam !== previousRoomId) {

      newRoom = RoomsHelper.getRoomForId(roomIdParam)

      if (!newRoom) return target.withState(STATES.ROOMS)

      _set(newRoom)

      transition.promise.then(_onTransition, _onTransitionError)
    }

    function _onTransition () {

      if (roomIdParam) CurrentBasCore.setLastRoomId(roomIdParam)
    }

    function _onTransitionError () {

      _set(RoomsHelper.getRoomForId(previousRoomId))
    }
  }

  /**
   * This can be used to go to all states.
   * For "room" states, the room param will be filled in.
   * Will still navigate to room states even if there is no current room!
   *
   * @param {string} newState
   * @param {Object} [params]
   * @param {Object} [options]
   * @returns {Promise}
   */
  function go (
    newState,
    params,
    options
  ) {
    var _params

    if (!BasUtil.isNEString(newState)) return Promise.reject('Invalid state')

    if (BasStateHelper.hasBaseState(newState, STATES.ROOM)) {

      _params = BasUtil.isObject(params) ? params : {}
      if (!BasUtil.isString(_params.room)) _params.room = state.roomId

    } else {

      // State is NOT a room state

      _params = params
    }

    return BasState.go(newState, _params, options)
  }

  /**
   * @returns {?BasRoom}
   */
  function getRoom () {

    return RoomsHelper.getRoomForId(state.roomId)
  }

  /**
   * @returns {?BasRoomMusic}
   */
  function getRoomMusic () {

    var room

    room = getRoom()

    return (room && room.music && room.music.volumeChange) ? room.music : null
  }

  /**
   * @returns {?BasRoomMusic}
   */
  function getRoomVideo () {

    var room

    room = getRoom()

    return (room && room.video && room.video.volumeChange) ? room.video : null
  }

  /**
   * @returns {?BasSource}
   */
  function getBasSource () {

    var roomMusic

    roomMusic = getRoomMusic()

    return (roomMusic && roomMusic.basSource && roomMusic.basSource.play)
      ? roomMusic.basSource
      : null
  }

  /**
   * @returns {?BasSource}
   */
  function getVideoBasSource () {

    var roomVideo

    roomVideo = getRoomVideo()

    return (roomVideo && roomVideo.basSource && roomVideo.basSource.play)
      ? roomVideo.basSource
      : null
  }

  function clear () {

    state.roomId = ''
  }
}
