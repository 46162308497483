'use strict'

angular
  .module('basalteApp')
  .constant('UI_HELPER', {
    D_W_MEDIUM: 667,
    EVT_RESIZE: 'basResize',
    D_T_PHONE: 0,
    D_T_TABLET: 1,
    K_DOM_MAIN_SECTION: 'domMainSection',
    K_DOM_NOW_PLAYING: 'domNowPlaying',
    K_DOM_COVER_CONTAINER: 'domCoverContainer'
  })
