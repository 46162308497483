'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasCurrentAppProfile', [
    '$rootScope',
    'BAS_CURRENT_CORE',
    'BAS_ROOM',
    'BAS_ROOMS',
    'BAS_APP_PROFILE',
    'CurrentBasCore',
    'BasAppProfile',
    'BasPreferences',
    BasCurrentAppProfile
  ])

/**
 * @typedef {Object} BasCurrentAppProfileState
 * @property {BasAppProfile} currentProfile
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasAppProfile
 * @param {BasPreferences} BasPreferences
 */
function BasCurrentAppProfile (
  $rootScope,
  BAS_CURRENT_CORE,
  BAS_ROOM,
  BAS_ROOMS,
  BAS_APP_PROFILE,
  CurrentBasCore,
  BasAppProfile,
  BasPreferences
) {

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasCurrentAppProfileState}
   */
  var state = {
    currentProfile: new BasAppProfile()
  }

  this.get = get
  this.addSearchHistory = addSearchHistory
  this.syncUserAndProfileInfo = syncUserAndProfileInfo
  this.isCurrentIntercomRoomUuid = isCurrentIntercomRoomUuid
  this.clearCurrentProfile = clearCurrentProfile

  init()

  function init () {

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_USER_CREATED,
      _syncAppProfile
    )

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_PROFILE_CREATED,
      _syncAppProfile
    )

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _syncAppProfile
    )

    $rootScope.$on(
      BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
      _syncAppProfile
    )

    if (CurrentBasCore.has() &&
      currentBasCoreState.core.hasCredentials()) {

      _syncAppProfile()
    }
  }

  /**
   * @returns {BasCurrentAppProfileState}
   */
  function get () {

    return state
  }

  function addSearchHistory (value, type) {

    if (state.currentProfile.addSearchHistory(value, type)) setProfileSettings()
  }

  function syncUserAndProfileInfo () {

    var _user, _profile, _name, _avatarPath, _ellieUuid, _cloudAccount

    _name = ''
    _avatarPath = ''
    _ellieUuid = ''
    _cloudAccount = false

    if (CurrentBasCore.has()) {

      _user = currentBasCoreState.core.getUser()
      _profile = currentBasCoreState.core.getProfile()

      if (_profile) {

        const basProfile = _profile.convertToBasProfile()

        _ellieUuid = _profile.coreClientUuid
        _name = _profile.username || _profile.email
        _avatarPath = basProfile.avatarPath
        _cloudAccount =
          currentBasCoreState.core.basServer.connectedUsingCloudAccount

      } else if (_user) {

        if (_user.basProfile) {

          _name = _user.basProfile.username
          _avatarPath = _user.basProfile.avatarPath
        }
      }
    }

    state.currentProfile.setServerInfo(
      _name,
      _avatarPath,
      _cloudAccount
    )

    if (BasUtil.isNEString(_ellieUuid)) {

      if (state.currentProfile.setBasalteDeviceInfo(_ellieUuid)) {
        $rootScope.$emit(
          BAS_APP_PROFILE.EVT_INTERCOM_ROOM_SET,
          state.currentProfile
        )
      }
    }
  }

  /**
   * @param {string} roomId
   * @returns {boolean}
   */
  function isCurrentIntercomRoomUuid (roomId) {

    return (state.currentProfile && BasUtil.isNEString(roomId))
      ? roomId === state.currentProfile.receiveIntercomRoom
      : false
  }

  function setProfileSettings () {

    if (CurrentBasCore.has()) {

      currentBasCoreState.core.saveHistoryForLastUser(
        state.currentProfile.getProfileHistory()
      )
    }
  }

  function clearCurrentProfile () {

    BasPreferences.clearProfileSettings()
  }

  function _syncAppProfile () {

    var _user

    // Apply profile setting if they exist in local storage
    BasPreferences.getProfileSettings(currentBasCoreState.core)

    if (CurrentBasCore.has()) {

      // Set name and avatar path via basProfile in user on core
      // TODO Improve?
      syncUserAndProfileInfo()

      _user = currentBasCoreState.core.getStoredLastUser()
    }

    // Sync history
    state.currentProfile.syncCurrentProfile(_user)
  }
}
