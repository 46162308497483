'use strict'

angular
  .module('basalteApp')
  .constant('SCENE_STATES', {
    SCENES: 'scenes',
    SCENE: 'scenes.scene',
    SCENE_PRESETS: 'scenes.scene.presets',
    SCENE_PICTURE: 'scenes.scene.presets.picture',
    SCENE_ROOMS: 'scenes.scene.rooms',
    SCENE_ROOM_FUNCTIONS: 'scenes.scene.rooms.functions',
    SCENE_FUNCTIONS: 'scenes.scene.functions',
    SCENE_STEP: 'scenes.scene.step',
    SCENE_STEP_LIGHTS: 'scenes.scene.step.lights',
    SCENE_STEP_DEVICES: 'scenes.scene.step.devices',
    SCENE_STEP_SHADES: 'scenes.scene.step.shades',
    SCENE_STEP_SCENES: 'scenes.scene.step.scenes',
    SCENE_STEP_THERMOSTAT_PICKER: 'scenes.scene.step.thermostat',
    SCENE_STEP_THERMOSTAT: 'scenes.scene.step.thermostat.thermostat',
    SCENE_STEP_MUSIC: 'scenes.scene.step.music',
    SCENE_STEP_MUSIC_FAVOURITES: 'scenes.scene.step.music.favourites',
    SCENE_STEP_VIDEO: 'scenes.scene.step.video',
    SCENE_STEP_VIDEO_FAVOURITES: 'scenes.scene.step.video.favourites'
  })
