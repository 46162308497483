'use strict'

/**
 * @copyright Basalte BV All rights reserved
 */

module.exports = {
  CONSTANTS: require('./src/constants'),
  BasServer: require('./src/bas_server'),
  BasDemoServer: require('./src/bas_demo_server'),
  BasHttpServer: require('./src/bas_http_server'),
  BasRemoteServer: require('./src/bas_remote_server'),
  Demo: require('./src/demo'),
  BasCore: require('./src/bas_core'),
  System: require('./src/system'),
  Live: require('./src/live'),
  SharedServerStorage: require('./src/shared_server_storage'),
  User: require('./src/user'),
  Room: require('./src/room'),
  Device: require('./src/device'),
  ConnectedDevice: require('./src/connected_device'),
  ServerDevice: require('./src/server_device'),
  CoreClientDevice: require('./src/core_client_device'),
  AVAudio: require('./src/av_audio'),
  AVVideo: require('./src/av_video'),
  AudioSource: require('./src/audio_source'),
  VideoSource: require('./src/video_source'),
  MusicLibrary: require('./src/music_library'),
  CallTopic: require('./src/call_topic'),
  AudioAlertTopic: require('./src/audio_alert_topic'),
  DoorPhoneTopic: require('./src/door_phone_topic'),
  LightDevice: require('./src/light_device'),
  ShadeDevice: require('./src/shade_device'),
  SceneCtrlDevice: require('./src/scene_ctrl_device'),
  TimerCtrlDevice: require('./src/timer_ctrl_device'),
  ThermostatDevice: require('./src/thermostat_device'),
  ThermostatControl: require('./src/thermostat_control'),
  CameraDevice: require('./src/camera_device'),
  EnergyDevice: require('./src/energy_device'),
  GenericDevice: require('./src/generic_device'),
  GenericDeviceV1: require('./src/generic_device_v1'),
  GenericDeviceV2: require('./src/generic_device_v2'),
  GenericDeviceControl: require('./src/generic_device_control'),
  OpenCloseDevice: require('./src/open_close_device'),
  DoorPhoneDevice: require('./src/door_phone_device'),
  DoorPhoneGatewayDevice: require('./src/door_phone_gateway_device'),
  WeatherStationDevice: require('./src/weather_station_device'),
  Temperature: require('./src/temperature'),
  SetPoint: require('./src/set_point'),
  Scene: require('./src/scene'),
  Timer: require('./src/timer'),
  Message: require('./src/message'),
  Job: require('./src/job'),
  Zone: require('./src/zone'),
  Stream: require('./src/stream'),
  Player: require('./src/player'),
  Profile: require('./src/profile'),
  Database: require('./src/database'),
  Queue: require('./src/queue'),
  Deezer: require('./src/deezer'),
  Tidal: require('./src/tidal'),
  Barp: require('./src/barp'),
  SpotifyBarp: require('./src/spotify_barp'),
  ExternalSource: require('./src/external'),
  BluetoothSource: require('./src/bluetooth'),
  NotificationSource: require('./src/notification'),
  TuneIn: require('./src/tunein'),
  CobraAudioDevice: require('./src/cobra_audio_device'),
  DspModule: require('./src/dsp'),
  DeviceOutput: require('./src/device_output'),
  BasHost: require('./src/bas_host'),
  BasProfile: require('./src/bas_profile'),
  BasTrack: require('./src/bas_track'),
  BasCrypto: require('./src/bas_crypto'),
  MacAddressUtil: require('@gidw/mac-address-util'),
  RequestJs: require('@gidw/request-js'),
  Logger: require('./src/logger')
}
