'use strict'

import { SplashScreen as CapacitorSplashScreen } from '@capacitor/splash-screen'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSplashScreen', [
    '$window',
    'BasUtilities',
    BasSplashScreen
  ])

/**
 * Helper service for managing the native splash screen.
 *
 * There are 2 implementations:
 * - Basalte (only Android)
 * - Cordova (only iOS, comes from cordova-ios)
 *
 * The official Cordova splash screen plugin is not used.
 * cordova-ios still exposes splash screen controls on the same points
 * as the official cordova plugin,
 * hence 2 implementations need to be called
 *
 * @constructor
 * @param $window
 * @param {BasUtilities} BasUtilities
 */
function BasSplashScreen (
  $window,
  BasUtilities
) {
  this.hide = hide

  /**
   * @param {number} [numberOfFrames = 2]
   */
  function hide (numberOfFrames) {

    var basSplashScreen, cdvSplashScreen

    basSplashScreen = _getBasSplashScreen()
    cdvSplashScreen = _getCordovaSplashScreen()

    if (basSplashScreen || cdvSplashScreen) {

      BasUtilities.waitForFrames(
        BasUtil.isPNumber(numberOfFrames) ? numberOfFrames : 2,
        _onWait
      )
    }
  }

  function _onWait () {

    var basSplashScreen, cdvSplashScreen

    basSplashScreen = _getBasSplashScreen()
    cdvSplashScreen = _getCordovaSplashScreen()

    if (basSplashScreen) basSplashScreen.hide()
    if (cdvSplashScreen) cdvSplashScreen.hide()
  }

  function _getBasSplashScreen () {

    return (
      $window['basalteCordova'] &&
      $window['basalteCordova']['BasSplashScreen']
    )
      ? $window['basalteCordova']['BasSplashScreen']
      : null
  }

  function _getCordovaSplashScreen () {

    // First try the cordova splashscreen plugin, if that one's not found,
    // try the capacitor one
    return ($window.navigator &&
      $window.navigator['splashscreen'] &&
      $window.navigator['splashscreen'].hide)
      ? $window.navigator['splashscreen']
      : CapacitorSplashScreen
  }
}
