'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourceTidal', [
    '$rootScope',
    '$http',
    'BAS_LIBRARY_ERRORS',
    'BAS_SOURCE',
    'BAS_TIDAL',
    'BasUtilities',
    basSourceTidalFactory
  ])

/**
 * @param $rootScope
 * @param $http
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_TIDAL} BAS_TIDAL
 * @param {BasUtilities} BasUtilities
 * @returns BasSourceTidal
 */
function basSourceTidalFactory (
  $rootScope,
  $http,
  BAS_LIBRARY_ERRORS,
  BAS_SOURCE,
  BAS_TIDAL,
  BasUtilities
) {
  var _API_URL = BAS_TIDAL.BASE_URL + '/' + BAS_TIDAL.PATH_VERSION + '/'

  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourceTidal (basSource) {

    /**
     * @type {string}
     */
    this.username = ''

    /**
     * @type {string}
     */
    this.longLink = ''

    /**
     * @type {string}
     */
    this.shortLink = ''

    /**
     * @type {string}
     */
    this.eTag = ''

    /**
     * @type {number}
     */
    this.userId = -1

    /**
     * @type {string}
     */
    this.accessToken = ''

    /**
     * @type {string}
     */
    this.countryCode = ''

    this.handleLinkChanged = this._onLinkChanged.bind(this)
    this.resetLinkSpinner = this._resetLinkSpinner.bind(this)
    this.handleDetailsError = this._onDetailsError.bind(this)

    this.checkLinked = this.isLinked.bind(this)
    this.checkLegacyAuth = this.isLegacyAuthLoggedIn.bind(this)

    this.handleUser = this._onUser.bind(this)
    this.handleETag = this._setETag.bind(this)
    this.clearUsername = this._clearUsername.bind(this)
    this.setStreamingServiceProperty =
      this._setStreamingServiceProperty.bind(this)

    /**
     * @private
     * @type {BasSource}
     */
    this._basSource = basSource || null

    this._clearUsername()
  }

  /**
   * @returns {number}
   */
  BasSourceTidal.prototype.getId = function () {

    if (this.isLinked()) {
      return this._basSource.source.tidal
        ? this._basSource.source.tidal.userId
        : this.userId
    }

    return -1
  }

  // region Linking

  /**
   * Synchronous check if TIDAL is linked or not
   *
   * @returns {boolean}
   */
  BasSourceTidal.prototype.isLinked = function () {

    var result

    if (this._basSource) {

      if (this._basSource.isAudioSource) {

        result = BasUtil.isNEString(
          this._basSource.streamingServiceTokens.tidal
        )

      } else {

        result = (
          BasUtil.isObject(this._basSource.source) &&
          BasUtil.isObject(this._basSource.source.tidal) &&
          this._basSource.source.tidal.linked === true
        )
      }

      this._basSource.cssSetTidalLinked(result)

      return result
    }

    return false
  }

  /**
   * Synchronous check if TIDAL is logged in trough legacy auth or not
   *
   * @returns {boolean}
   */
  BasSourceTidal.prototype.isLegacyAuthLoggedIn = function () {

    return this._basSource
      ? (
          this._basSource.source &&
          this._basSource.source.tidal &&
          this._basSource.source.tidal.hasLegacyAuth === true
        )
      : false
  }

  BasSourceTidal.prototype._resetLinkSpinner = function () {
    if (this._basSource) this._basSource.cssSetTidalSpinner(false)
  }

  BasSourceTidal.prototype._onDetailsError = function (error) {

    this._resetLinkSpinner()
    this.checkLinked()

    return Promise.reject('Could not get streaming service details: ' + error)
  }

  /**
   * Checks if TIDAL is linked or not.
   * If TIDAL state has not been retrieved yet,
   * retrieve status and then perform the check.
   *
   * @returns {Promise}
   */
  BasSourceTidal.prototype.linked = function () {

    if (this._basSource) {

      if (this._basSource.isAudioSource) {

        this._basSource.cssSetTidalSpinner(true)

        return this._basSource.getStreamingServiceDetails('tidal')
          .then(this.resetLinkSpinner)
          .then(this.updateUsername.bind(this))
          .then(this.checkLinked)
          .catch(this.handleDetailsError)

      } else {

        if (
          BasUtil.isObject(this._basSource.source) &&
          BasUtil.isObject(this._basSource.source.tidal)
        ) {

          if (this._basSource.source.tidal.dirty) {

            this._basSource.cssSetTidalSpinner(true)
            return this._basSource.source.tidal.status()
              .then(this.resetLinkSpinner)
              .then(this.checkLinked)

          } else {

            return Promise.resolve(this.isLinked())
          }
        }
      }
    }

    this.isLinked()
    return Promise.reject('Invalid basSource')
  }

  /**
   * Checks if TIDAL is logged in with legacy user/pass method or not.
   * If TIDAL state has not been retrieved yet,
   * retrieve status and then perform the check.
   *
   * @returns {Promise<boolean>}
   */
  BasSourceTidal.prototype.getLegacyAuth = function () {

    if (
      this._basSource &&
      this._basSource.source &&
      this._basSource.source.tidal
    ) {

      if (this._basSource.source.tidal.dirty) {

        return this._basSource.source.tidal.status()
          .then(this.checkLegacyAuth)

      } else {

        return Promise.resolve(this.isLegacyAuthLoggedIn())
      }
    }

    return Promise.reject('Invalid basSource')
  }

  BasSourceTidal.prototype._onLinkChanged = function () {

    this.updateUsername()

    if (this._basSource) {

      $rootScope.$emit(
        BAS_SOURCE.EVT_TIDAL_LINK_CHANGED,
        this._basSource.getId()
      )
    }
  }

  BasSourceTidal.prototype.updateUsername = function () {

    if (this.isLinked()) {

      this._basSource.cssSetTidalSpinner(true)
      this.getUser().then(this.handleUser, this.clearUsername)

    } else {

      this._clearUsername()
    }
  }

  /**
   * @private
   * @param {Object} result
   */
  BasSourceTidal.prototype._onUser = function (result) {

    if (this._basSource) {
      this._basSource.cssSetTidalSpinner(false)
    }

    if (BasUtil.isObject(result)) {

      this.username = result.username
      this.longLink = this.username
      this.shortLink = this.username

    } else {

      this._clearUsername()
    }
  }

  /**
   * Clears the username information
   *
   * @private
   */
  BasSourceTidal.prototype._clearUsername = function () {

    this.username = ''
    this.longLink = BasUtilities.translate('tidal_no_link')
    this.shortLink = BasUtilities.translate('not_linked')

    if (this._basSource) {
      this._basSource.cssSetTidalSpinner(false)
    }
  }

  /**
   * Set custom streaming service property
   *
   * @param key
   * @param value
   * @private
   */
  BasSourceTidal.prototype._setStreamingServiceProperty = function (
    key,
    value
  ) {

    this[key] = value
  }

  /**
   * Get the value of linkUrl
   *
   * @returns {Promise<string>}
   */
  BasSourceTidal.prototype.getLinkUrl = function () {

    if (
      this._basSource &&
      BasUtil.isObject(this._basSource.source)
    ) {

      if (this._basSource.isAudioSource) {

        return this._basSource.source.loginStreamingService('tidal')

      } else if (
        this._basSource.source &&
        this._basSource.source.tidal &&
        this._basSource.source.tidal.linkUrl
      ) {

        return this._basSource.source.tidal.linkUrl()
      }
    }

    return Promise.reject('Invalid basSource')
  }

  /**
   * Unlink linked tidal account
   *
   * @returns {Promise}
   */
  BasSourceTidal.prototype.unlink = function () {

    if (
      this._basSource &&
      BasUtil.isObject(this._basSource.source)
    ) {

      if (this._basSource.isAudioSource) {

        return this._basSource.source.logoutStreamingService('tidal')

      } else if (
        this._basSource.source &&
        this._basSource.source.tidal &&
        this._basSource.source.tidal.linkUrl
      ) {

        this._basSource.source.tidal.logout()
        return Promise.resolve()
      }
    }

    return Promise.reject('Invalid basSource')
  }

  // endregion

  // region Library Requests

  /**
   * @param {string} type
   * @param {string} query
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.searchLib = function (
    type,
    query,
    params
  ) {
    return this.search(type, query, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserPersPlaylistsLib = function (
    id,
    params
  ) {
    return this.getUserPlaylists(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserPlaylistsLib = function (
    id,
    params
  ) {
    var favourite = this.getUserFavPlaylists(id, params)
      .then(parseResponse)
    var playlists = this.getUserPlaylists(id, params).then(parseResponse)

    return Promise.all([favourite, playlists]).then(this.processPlaylists)
  }

  BasSourceTidal.prototype.processPlaylists = function (result) {

    var favourite, playlists

    if (Array.isArray(result)) {

      favourite = result[0]
      playlists = result[1]

      if (BasUtil.isObject(favourite) &&
        BasUtil.isObject(playlists)) {

        // Total Number Of Items (TOT_NOI)
        if (BasUtil.isPNumber(playlists[BAS_TIDAL.TOT_NOI], true) &&
          BasUtil.isPNumber(favourite[BAS_TIDAL.TOT_NOI], true)) {

          playlists[BAS_TIDAL.TOT_NOI] =
            playlists[BAS_TIDAL.TOT_NOI] +
            favourite[BAS_TIDAL.TOT_NOI]

        } else if (
          BasUtil.isPNumber(favourite[BAS_TIDAL.TOT_NOI], true)) {

          playlists[BAS_TIDAL.TOT_NOI] = favourite[BAS_TIDAL.TOT_NOI]
        }

        // Items
        if (Array.isArray(playlists[BAS_TIDAL.K_ITEMS]) &&
          Array.isArray(favourite[BAS_TIDAL.K_ITEMS])) {

          playlists[BAS_TIDAL.K_ITEMS] =
            playlists[BAS_TIDAL.K_ITEMS]
              .concat(favourite[BAS_TIDAL.K_ITEMS])

        } else if (Array.isArray(favourite[BAS_TIDAL.K_ITEMS])) {

          playlists[BAS_TIDAL.K_ITEMS] = favourite[BAS_TIDAL.K_ITEMS]
        }

        return playlists

      } else if (BasUtil.isObject(favourite)) {

        return favourite

      } else if (BasUtil.isObject(playlists)) {

        return playlists
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserArtistsLib = function (id, params) {

    return this.getUserArtists(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserAlbumsLib = function (id, params) {

    return this.getUserAlbums(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserTracksLib = function (id, params) {

    return this.getUserTracks(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getArtistArtistsLib = function (id, params) {

    return this.getArtistArtists(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getArtistAlbumsLib = function (id, params) {

    return this.getArtistAlbums(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getArtistTracksLib = function (id, params) {

    return this.getArtistTracks(id, params).then(parseResponse)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getRisingTracksLib = function (params) {

    return this.getRisingTracks(params).then(parseResponse)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getRisingAlbumsLib = function (params) {

    return this.getRisingAlbums(params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getGenrePlaylistsLib = function (id, params) {

    return this.getGenrePlaylists(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getGenreAlbumsLib = function (id, params) {

    return this.getGenreAlbums(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getGenreTracksLib = function (id, params) {

    return this.getGenreTracks(id, params).then(parseResponse)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getNewPlaylistsLib = function (params) {

    return this.getNewPlaylists(params).then(parseResponse)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getNewAlbumsLib = function (params) {

    return this.getNewAlbums(params).then(parseResponse)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getNewTracksLib = function (params) {

    return this.getNewTracks(params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getAlbumTracksLib = function (id, params) {

    return this.getAlbumTracks(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getPlaylistTracksLib = function (id, params) {

    return this.getPlaylistTracks(id, params).then(parseResponse)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getMoodPlaylistsLib = function (id, params) {

    return this.getMoodPlaylists(id, params).then(parseResponse)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getGenresLib = function (params) {

    return this.getGenres(params).then(parseResponse)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getMoodsLib = function (params) {

    return this.getMoods(params).then(parseResponse)
  }

  function parseResponse (response) {

    if (
      BasUtil.safeHasOwnProperty(response, 'items') ||
      Array.isArray(response.items)
    ) {

      return Promise.resolve(response)
    }

    if (response.albums && BasUtil.isNEArray(response.albums.items)) {

      return Promise.resolve(response.albums)
    }

    if (response.artists && BasUtil.isNEArray(response.artists.items)) {

      return Promise.resolve(response.artists)
    }

    if (response.playlists && BasUtil.isNEArray(response.playlists.items)) {

      return Promise.resolve(response.playlists)
    }

    if (response.tracks && BasUtil.isNEArray(response.tracks.items)) {

      return Promise.resolve(response.tracks)
    }

    return Promise.resolve(response)
  }

  // endregion

  // region Requests

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUser = function (params) {

    if (this.isLinked()) {

      return this.request({
        url: _API_URL + 'users/' + this.getId(),
        params: params
      })
    }

    return Promise.reject('TIDAL invalid or not linked')
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getPlaylist = function (id, params) {

    return this.request({
      url: _API_URL + 'playlists/' + id,
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getSongInfo = function (id, params) {

    return this.request({
      url: _API_URL + 'tracks/' + id,
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getAlbum = function (id, params) {

    return this.request({
      url: _API_URL + 'albums/' + id,
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getArtist = function (id, params) {

    return this.request({
      url: _API_URL + 'artists/' + id,
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getGenres = function (params) {

    return this.request({
      url: _API_URL + 'genres',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getMoods = function (params) {

    return this.request({
      url: _API_URL + 'moods',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getMoodPlaylists = function (id, params) {

    return this.request({
      url: _API_URL + 'moods/' + id + '/playlists',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getPlaylistTracks = function (id, params) {

    return this.request({
      url: _API_URL + 'playlists/' + id + '/tracks',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getAlbumTracks = function (id, params) {

    return this.request({
      url: _API_URL + 'albums/' + id + '/tracks',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getNewTracks = function (params) {

    return this.request({
      url: _API_URL + 'featured/new/tracks',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getNewAlbums = function (params) {

    return this.request({
      url: _API_URL + 'featured/new/albums',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getNewPlaylists = function (params) {

    return this.request({
      url: _API_URL + 'featured/new/playlists',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getGenreTracks = function (id, params) {

    return this.request({
      url: _API_URL + 'genres/' + id + '/tracks',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getGenreAlbums = function (id, params) {

    return this.request({
      url: _API_URL + 'genres/' + id + '/albums',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getGenrePlaylists = function (id, params) {

    return this.request({
      url: _API_URL + 'genres/' + id + '/playlists',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getRisingAlbums = function (params) {

    return this.request({
      url: _API_URL + 'rising/new/albums',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getRisingTracks = function (params) {

    return this.request({
      url: _API_URL + 'rising/new/tracks',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getArtistTracks = function (
    id,
    params
  ) {
    return this.request({
      url: _API_URL + 'artists/' + id + '/toptracks',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getArtistAlbums = function (
    id,
    params
  ) {
    return this.request({
      url: _API_URL + 'artists/' + id + '/albums',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getArtistArtists = function (
    id,
    params
  ) {
    return this.request({
      url: _API_URL + 'artists/' + id + '/related',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserTracks = function (
    id,
    params
  ) {
    var _params

    _params = params || {}

    _params[BAS_TIDAL.K_DIRECTION] = BAS_TIDAL.DIREC_DESC
    _params[BAS_TIDAL.K_ORDER] = BAS_TIDAL.ORDER_DATE

    return this.request({
      url: _API_URL + 'users/' + id + '/favorites/tracks',
      params: _params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserAlbums = function (
    id,
    params
  ) {
    var _params

    _params = params || {}

    _params[BAS_TIDAL.K_DIRECTION] = BAS_TIDAL.DIREC_DESC
    _params[BAS_TIDAL.K_ORDER] = BAS_TIDAL.ORDER_DATE

    return this.request({
      url: _API_URL + 'users/' + id + '/favorites/albums',
      params: _params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserArtists = function (
    id,
    params
  ) {
    var _params

    _params = params || {}

    _params[BAS_TIDAL.K_DIRECTION] = BAS_TIDAL.DIREC_DESC
    _params[BAS_TIDAL.K_ORDER] = BAS_TIDAL.ORDER_DATE

    return this.request({
      url: _API_URL + 'users/' + id + '/favorites/artists',
      params: _params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserFavPlaylists = function (
    id,
    params
  ) {
    var _params

    _params = params || {}

    _params[BAS_TIDAL.K_DIRECTION] = BAS_TIDAL.DIREC_DESC
    _params[BAS_TIDAL.K_ORDER] = BAS_TIDAL.ORDER_DATE

    return this.request({
      url: _API_URL + 'users/' + id + '/favorites/playlists',
      params: _params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getUserPlaylists = function (
    id,
    params
  ) {
    var _params

    _params = params || {}

    _params[BAS_TIDAL.K_DIRECTION] = BAS_TIDAL.DIREC_DESC
    _params[BAS_TIDAL.K_ORDER] = BAS_TIDAL.ORDER_DATE

    return this.request({
      url: _API_URL + 'users/' + id + '/playlists',
      params: _params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.getFavorites = function (params) {

    var id = this.getId()

    return this.request({
      url: _API_URL + 'users/' + id + '/favorites/ids',
      params: params
    })
  }

  /**
   * @param {string} type
   * @param {string} query
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.search = function (
    type,
    query,
    params
  ) {
    var _params

    _params = params || {}

    _params.types = type
    _params.query = query

    return this.request({
      url: _API_URL + 'search/',
      params: _params
    })
  }

  /**
   * @param {string} id
   * @param {number} item
   * @param {number} index
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.reorderPlaylistSong = function (
    id,
    item,
    index,
    params
  ) {
    var data = 'toIndex=' + index
    var headers = {}
    headers[BAS_TIDAL.CONTENT_TYPE] = BAS_TIDAL.HEADER_POST
    headers[BAS_TIDAL.IF_NONE_MATCH] = this.eTag

    return this.request({
      method: 'POST',
      url: _API_URL + 'playlists/' + id + '/items/' + item,
      params: params,
      headers: headers,
      data: data
    }).catch(checkTidalETagError)
  }

  /**
   * @param {string} id
   * @param {string[]} selection
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.addSongsToPlaylist = function (
    id,
    selection,
    params
  ) {
    var data = 'itemIds=' + selection.join(',')
    var headers = {}
    headers[BAS_TIDAL.CONTENT_TYPE] = BAS_TIDAL.HEADER_POST
    headers[BAS_TIDAL.IF_NONE_MATCH] = this.eTag

    return this.request({
      method: 'POST',
      url: _API_URL + 'playlists/' + id + '/items/',
      params: params,
      headers: headers,
      data: data
    }).catch(checkTidalETagError)
  }

  /**
   * @param {string} id
   * @param {string} name
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.addNewPlaylist = function (
    id,
    name,
    params
  ) {
    var data = 'title=' + name
    var headers = {}
    headers[BAS_TIDAL.CONTENT_TYPE] = BAS_TIDAL.HEADER_POST
    headers[BAS_TIDAL.IF_NONE_MATCH] = this.eTag

    return this.request({
      method: 'POST',
      url: _API_URL + 'users/' + id + '/playlists',
      params: params,
      headers: headers,
      data: data
    }).catch(checkTidalETagError)
  }

  /**
   * @param {string} id
   * @param {string} name
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.changePlaylistTitle = function (
    id,
    name,
    params
  ) {
    var data = 'title=' + name
    var headers = {}
    headers[BAS_TIDAL.CONTENT_TYPE] = BAS_TIDAL.HEADER_POST
    headers[BAS_TIDAL.IF_NONE_MATCH] = this.eTag

    return this.request({
      method: 'POST',
      url: _API_URL + 'playlists/' + id,
      params: params,
      headers: headers,
      data: data
    }).catch(checkTidalETagError)
  }

  /**
   * @param {string} type
   * @param {string} data
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.addFavourite = function (
    type,
    data,
    params
  ) {
    var id = this.getId()
    var headers = {}
    headers[BAS_TIDAL.CONTENT_TYPE] = BAS_TIDAL.HEADER_POST
    headers[BAS_TIDAL.IF_NONE_MATCH] = this.eTag

    return this.request({
      method: 'POST',
      url: _API_URL + 'users/' + id + '/favorites/' + type,
      params: params,
      headers: headers,
      data: data
    }).catch(checkTidalETagError)
  }

  /**
   * @param {string} type
   * @param {string} item
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.removeFavourite = function (
    type,
    item,
    params
  ) {
    var id = this.getId()
    var headers = {}
    headers[BAS_TIDAL.IF_NONE_MATCH] = this.eTag

    return this.request({
      method: 'DELETE',
      url: _API_URL + 'users/' + id + '/favorites/' + type + '/' + item,
      params: params,
      headers: headers
    }).catch(checkTidalETagError)
  }

  /**
   * @param {string} id
   * @param {number} item
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.removePlaylistSong = function (
    id,
    item,
    params
  ) {
    var headers = {}
    headers[BAS_TIDAL.IF_NONE_MATCH] = this.eTag

    return this.request({
      method: 'DELETE',
      url: _API_URL + 'playlists/' + id + '/items/' + item,
      params: params,
      headers: headers
    }).catch(checkTidalETagError)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceTidal.prototype.removePlaylist = function (
    id,
    params
  ) {
    var headers = {}
    headers[BAS_TIDAL.IF_NONE_MATCH] = this.eTag

    return this.request({
      method: 'DELETE',
      url: _API_URL + 'playlists/' + id,
      params: params,
      headers: headers
    }).catch(checkTidalETagError)
  }

  function checkTidalETagError (err) {
    if (BasUtil.isObject(err) &&
      BasUtil.isObject(err.data) &&
      err.data.status === BAS_TIDAL.OUTDATED_CODE) {

      return Promise.reject(BAS_TIDAL.ERR_OUTDATED_TAG)
    }

    return Promise.reject(err)
  }

  /**
   * @param {Object} config
   * @returns {Promise}
   */
  BasSourceTidal.prototype.request = function (config) {

    var tidal, basTidal

    if (
      this.isLinked() &&
      BasUtil.isObject(config)
    ) {

      tidal = this._basSource.source.tidal
      basTidal = this._basSource.tidal

      if (!BasUtil.isObject(config.headers)) config.headers = {}

      if (tidal && BasUtil.isNEString(tidal.accessToken)) {

        config.headers[BAS_TIDAL.H_AUTHORIZATION] =
          'Bearer ' + tidal.accessToken

      } else if (
        BasUtil.isString(this._basSource.streamingServiceTokens.tidal)
      ) {

        config.headers[BAS_TIDAL.H_AUTHORIZATION] =
          'Bearer ' + this._basSource.streamingServiceTokens.tidal

      } else {

        // Fallback to sessionId
        config.headers[BAS_TIDAL.H_SESSION_ID] = tidal.sessionId
      }

      if (!BasUtil.isObject(config.params)) config.params = {}

      config.params[BAS_TIDAL.P_COUNTRY_CODE] = basTidal.countryCode
        ? basTidal.countryCode
        : tidal.countryCode

      return $http(config)
        .then(this.handleETag)
        .then(BasUtilities.handleHttpResponse)
    }

    return Promise.reject('Invalid source or config')
  }

  BasSourceTidal.prototype._setETag = function (response) {

    this.eTag = response.headers(BAS_TIDAL.ETAG)
    return response
  }

  // endregion

  /**
   * Keeps collecting elements until offset >== amount
   *
   * @param {Function} func
   * @param {number} limit Size of a request
   * @param {number} offset Starting position
   * @param {number} amount Put this on -1 to get total
   * @returns {Promise<Object>}
   */
  BasSourceTidal.prototype.getXElements = function (
    func,
    limit,
    offset,
    amount
  ) {
    var elements, params, _offset, _amount

    elements = []
    params = {}
    params.limit = limit

    _offset = offset
    _amount = amount

    return this.isLinked()
      ? requestElements()
      : Promise.reject(BAS_LIBRARY_ERRORS.NOT_LINKED)

    function requestElements () {

      params.offset = _offset

      return func(params).then(checkResult)

      function checkResult (result) {

        if (result) {

          if (result.items) elements = elements.concat(result.items)

          _offset += limit

          if (_amount === -1) _amount = result[BAS_TIDAL.TOT_NOI]

          if (_offset < _amount) return requestElements()
        }

        result.items = elements
        return Promise.resolve(result)
      }
    }
  }

  BasSourceTidal.prototype.destroy = function () {

    this._basSource = null
  }

  return BasSourceTidal
}
