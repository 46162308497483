'use strict'

angular
  .module('basalteApp')
  .factory('UiBasRoom', [
    'BAS_ROOM',
    'BAS_ROOMS',
    'ICONS',
    'BasTile',
    'CurrentBasCore',
    'UiBasTiles',
    'RoomsHelper',
    'BasGenericDeviceV2',
    'BasGenericDeviceV1',
    uiBasRoomFactory
  ])

/**
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {ICONS} ICONS
 * @param BasTile
 * @param {CurrentBasCore} CurrentBasCore
 * @param {UiBasTiles} UiBasTiles
 * @param {RoomsHelper} RoomsHelper
 * @param {BasGenericDeviceV2} BasGenericDeviceV2
 * @param {BasGenericDeviceV1} BasGenericDeviceV1
 * @returns UiBasRoom
 */
function uiBasRoomFactory (
  BAS_ROOM,
  BAS_ROOMS,
  ICONS,
  BasTile,
  CurrentBasCore,
  UiBasTiles,
  RoomsHelper,
  BasGenericDeviceV2,
  BasGenericDeviceV1
) {
  /**
   * @type {TUiBasTiles}
   */
  var uiBasTiles = UiBasTiles.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function UiBasRoom (basRoom) {

    /**
     * All properties (primarily for scenes configuration)
     * For a "Home" scene.
     *
     * @type {BasTile[]}
     */
    this[BAS_ROOM.K_PROPERTY_TILES] = []

    /**
     * All properties (primarily for scenes configuration)
     * For a "Room" scene.
     *
     * @type {BasTile[]}
     */
    this[BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES] = []

    /**
     * All properties which are shown in roomDash
     *
     * @type {BasTile[]}
     */
    this.comfortTiles = []

    /**
     * Uuids of scenes displayed in roomDash
     *
     * @type {string[]}
     */
    this.sceneTiles = []

    /**
     * @type {boolean}
     */
    this.hasThermostat = false

    /**
     * @type {BasRoom}
     * @private
     */
    this._basRoom = basRoom
  }

  /**
   * Sync the UI components with the actual data model
   */
  UiBasRoom.prototype.sync = function sync () {

    this.reset()
    this.syncScenes()
    this.syncComfort()
  }

  UiBasRoom.prototype.syncScenes = function syncScenes () {

    if (this._basRoom &&
      this._basRoom.scenes) {

      this.sceneTiles = this._basRoom.scenes.uiFavouriteScenes
    }
  }

  UiBasRoom.prototype.syncComfort = function syncComfort () {

    if (this._basRoom) {

      // Lights

      if (RoomsHelper.roomHasFunctionLights(this._basRoom)) {

        this[BAS_ROOM.K_PROPERTY_TILES]
          .push(uiBasTiles.lights)
        this[BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES]
          .push(uiBasTiles.lights)
        this.comfortTiles.push(uiBasTiles.lights)
      }

      // Shades

      if (RoomsHelper.roomHasFunctionWindowTreatments(this._basRoom)) {

        this[BAS_ROOM.K_PROPERTY_TILES]
          .push(uiBasTiles.shades)
        this[BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES]
          .push(uiBasTiles.shades)
        this.comfortTiles.push(uiBasTiles.shades)
      }

      // Music

      if (
        RoomsHelper.roomHasFunctionAudio(this._basRoom) ||
        this._basRoom.hasAVMusic()
      ) {

        this[BAS_ROOM.K_PROPERTY_TILES]
          .push(uiBasTiles.music)
        this[BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES]
          .push(uiBasTiles.music)
        this.comfortTiles.push(uiBasTiles.music)
      }

      // Video

      if (this._basRoom.hasAVVideo()) {

        this[BAS_ROOM.K_PROPERTY_TILES]
          .push(uiBasTiles.tv)
        this[BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES]
          .push(uiBasTiles.tv)
        this.comfortTiles.push(uiBasTiles.tv)
      }

      // Scenes

      if (RoomsHelper.roomHasFunctionScenes(this._basRoom)) {

        this[BAS_ROOM.K_PROPERTY_TILES]
          .push(uiBasTiles.scenes)
        this.comfortTiles.push(uiBasTiles.scenes)
      }

      // Devices

      if (RoomsHelper.roomHasFunctionGeneric(this._basRoom)) {

        if (this._basRoom.genericDevices?.devices?.length) {

          const genericDevicesV2 =
            this._basRoom.genericDevices?.devices.filter(
              basGenericDevice =>
                basGenericDevice instanceof BasGenericDeviceV2
            )

          genericDevicesV2.forEach(device => {
            const basTile = new BasTile(BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES)
            basTile.name = device.name
            basTile.uuid = device.uuid
            basTile.subtitle = device.subtitle
            basTile.icon = this.syncIconV2(device.icon)
            basTile.css[BasTile.CSS_TILE_SVG_50] = true

            this.comfortTiles.push(basTile)
          })

          if (this._basRoom.genericDevices?.devices.filter(
            basGenericDevice =>
              basGenericDevice instanceof BasGenericDeviceV1
          ).length) {

            this.comfortTiles.push(uiBasTiles.genericDevices)
          }
        }

        if (CurrentBasCore.hasCore() &&
          currentBasCoreState.core.core.supportsDevicesScenes) {

          this[BAS_ROOM.K_PROPERTY_TILES]
            .push(uiBasTiles.genericDevices)
          this[BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES]
            .push(uiBasTiles.genericDevices)
        }
      }

      // Thermostat
      if (RoomsHelper.roomHasFunctionThermostat(this._basRoom)) {

        this[BAS_ROOM.K_PROPERTY_TILES]
          .push(uiBasTiles.thermostat)
        this[BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES]
          .push(uiBasTiles.thermostat)
        this.hasThermostat = true
      }
    }
  }

  UiBasRoom.prototype.updateTranslations = function () {

    var i, length, tile

    length = this.sceneTiles
      ? this.sceneTiles.length
      : 0
    for (i = 0; i < length; i++) {

      tile = this.sceneTiles[i]
      if (tile instanceof BasTile) tile.updateTranslation()
    }
  }

  UiBasRoom.prototype.reset = function () {

    this[BAS_ROOM.K_PROPERTY_TILES] = []
    this[BAS_ROOM.K_PROPERTY_TILES_ROOM_SCENES] = []
    this.comfortTiles = []
    this.sceneTiles = []
    this.hasThermostat = false
  }

  /**
   * TODO: Wait for marketing to supply us the right icons
   *
   * @param {string} icon
   * @returns {Object}
   */
  UiBasRoom.prototype.syncIconV2 = function (icon) {

    switch (icon) {

      case 'NONE':

        return ICONS.mixed

      case 'CONTROL':

        return ICONS.mixed

      case 'COOLING':

        return ICONS.mixed

      case 'ENERGY':

        return ICONS.mixed

      case 'GARDEN':

        return ICONS.mixed

      case 'POOL':

        return ICONS.mixed

      case 'SECURITY':

        return ICONS.mixed

      case 'SOLAR':

        return ICONS.mixed

      case 'DEMO_SPRINKLER':

        return ICONS.mixed

      case 'DEMO_FOUNTAIN':

        return ICONS.mixed
    }

    return null
  }

  return UiBasRoom
}
