'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

// TODO Check for new custom stations after checking for updates

/**
 * TuneIn helper for custom stations
 *
 * @constructor
 * @extends EventEmitter
 * @param {BasCore} basCore
 * @since 1.6.0
 */
function TuneIn (basCore) {

  EventEmitter.call(this)

  this._CACHE_TIME_MS = 60000

  this._basCore = basCore

  this._partnerId = 1
  this._serial = Math.floor(1000000 * Math.random())

  this._customStationsDate = null
  this._customStations = {
    hasStations: false,
    stations: {},
    countries: {},
    searchArray: []
  }
}

TuneIn.prototype = Object.create(EventEmitter.prototype)
TuneIn.prototype.constructor = TuneIn

// region Events

/**
 * Custom radios have been retrieved
 *
 * @event TuneIn#EVT_CUSTOM_UPDATED
 * @since 2.1.0
 */

// endregion

/**
 * @constant {string}
 */
TuneIn.EVT_CUSTOM_UPDATED = 'evtCustomUpdated'

/**
 * @constant {string}
 */
TuneIn.ERR_NO_PLAYER = 'errTuneInNoPlayer'

/**
 * @constant {string}
 */
TuneIn.ERR_INVALID_RESPONSE = 'errTuneInInvalidResponse'

/**
 * @constant {string}
 */
TuneIn.ERR_INVALID_INPUT = 'errTuneInInvalidInput'

/**
 * @constant {string}
 */
TuneIn.ERR_NO_STATION = 'errTuneInNoStation'

/**
 * @constant {string}
 */
TuneIn.CS_REF = 'ref'

/**
 * @constant {string}
 */
TuneIn.CS_DESCRIBE = 'describe'

/**
 * @constant {string}
 */
TuneIn.CS_COUNTRY = 'country'

/**
 * @name TuneIn#partnerId
 * @type {number}
 * @readonly
 */
Object.defineProperty(TuneIn.prototype, 'partnerId', {
  get: function () {
    return this._partnerId
  }
})

/**
 * @name TuneIn#serial
 * @type {number}
 * @readonly
 */
Object.defineProperty(TuneIn.prototype, 'serial', {
  get: function () {
    return this._serial
  }
})

/**
 * Checks whether the object is a valid custom stations entry
 * (reference or station)
 *
 * @param {?Object} object
 * @returns {boolean}
 */
TuneIn.isCustomStationEntry = function (object) {
  return (
    BasUtil.isObject(object) &&
    (
      TuneIn.CS_REF in object ||
      TuneIn.CS_DESCRIBE in object
    )
  )
}

/**
 * Checks whether the object is a valid custom station object
 * (station)
 *
 * @param {?Object} object
 * @returns {boolean}
 */
TuneIn.isCustomStation = function (object) {
  return (
    BasUtil.isObject(object) &&
    BasUtil.isObject(object[TuneIn.CS_DESCRIBE])
  )
}

/**
 * Retrieve the custom stations
 * A local copy will be returned if no retrieve is needed
 * Optionally takes a http response that contains all custom stations,
 * which will be preferred over getting the radios via player
 *
 * @param {Object} [httpResponse]
 * @returns {Promise}
 */
TuneIn.prototype.getCustomStations = function (httpResponse) {

  var currentDate, difference

  if (this._customStations.hasStations &&
    this._basCore.supportsCheckingUpdates) {

    return Promise.resolve(this._customStations)
  }

  if (httpResponse) {
    this._customStations = this._retrieveCustomStations(httpResponse)
    return Promise.resolve(this._customStations)
  }

  if (this._customStationsDate) {

    currentDate = new Date()

    difference = currentDate.getTime() - this._customStationsDate.getTime()

    if (difference > this._CACHE_TIME_MS) {

      return this._retrieveCustomStations()

    } else {

      return Promise.resolve(this._customStations)
    }

  } else {

    return this._retrieveCustomStations()
  }
}

/**
 * Retrieve the custom stations
 *
 * @private
 * @param {Object} [httpResponse]
 * @returns {Promise}
 */
TuneIn.prototype._retrieveCustomStations = function (httpResponse) {

  var _this, player

  _this = this

  player = this._basCore.getFirstPlayer()

  if (httpResponse) {

    return _onCustomStations(httpResponse)
  } else {

    return player
      ? player.otherRadios().then(_onCustomStations)
      : Promise.reject(TuneIn.ERR_NO_PLAYER)
  }

  function _onCustomStations (result) {

    var keys, i, length, key
    var newStations, stations, station
    var countries, country, searchArray, searchString

    _this._clear()

    if (BasUtil.isObject(result)) {

      if (
        BasUtil.isObject(result[P.PLAYER]) &&
        result[P.PLAYER][P.ID] === player.id &&
        BasUtil.isObject(result[P.PLAYER][P.RADIOS])
      ) {
        newStations = result[P.PLAYER][P.RADIOS]
      } else {
        newStations = result
      }

      _this._customStationsDate = new Date()

      stations = _this._customStations.stations
      countries = _this._customStations.countries
      searchArray = _this._customStations.searchArray

      keys = Object.keys(newStations)
      length = keys.length

      _this._customStations.hasStations = length > 0

      for (i = 0; i < length; i++) {

        key = keys[i]
        station = newStations[key]

        if (TuneIn.isCustomStationEntry(station)) {

          _this._customStations.hasStations = true
          stations[key] = station

          if (TuneIn.isCustomStation(station)) {

            searchArray.push(station)

            // Check for country
            if (BasUtil.isNEString(station[TuneIn.CS_COUNTRY])) {

              country = station[TuneIn.CS_COUNTRY]

              if (!BasUtil.isObject(countries[country])) {

                countries[country] = {}
              }

              countries[country][key] = station
            }

            // Set search string
            searchString = ''

            if (BasUtil.isNEString(station.describe.name)) {

              searchString += station.describe.name.toLowerCase()
            }

            if (BasUtil.isNEString(station.describe.genre_name)) {

              searchString += station.describe.genre_name.toLowerCase()
            }

            if (BasUtil.isNEString(station.describe.slogan)) {

              searchString += station.describe.slogan.toLowerCase()
            }

            station.searchString = searchString
          }
        }
      }

      _this.emit(TuneIn.EVT_CUSTOM_UPDATED)

      return _this._customStations
    }

    return Promise.reject(TuneIn.ERR_INVALID_RESPONSE)
  }
}

/**
 * Get custom station info
 *
 * @param {string} gid
 * @returns {Promise}
 */
TuneIn.prototype.stationInfo = function (gid) {

  var _this

  _this = this

  return BasUtil.isNEString(gid)
    ? this.getCustomStations().then(_onCustomStations)
    : Promise.reject(TuneIn.ERR_INVALID_INPUT)

  function _onCustomStations () {

    var stations, station

    if (_this._customStations.hasStations) {

      stations = _this._customStations.stations

      station = stations[gid]

      if (TuneIn.isCustomStation(station)) {

        return station[TuneIn.CS_DESCRIBE]

      } else if (TuneIn.isCustomStationEntry(station)) {

        station = stations[station[TuneIn.CS_REF]]

        if (TuneIn.isCustomStation(station)) {

          return station[TuneIn.CS_DESCRIBE]
        }

        return Promise.reject(TuneIn.ERR_NO_STATION)

      } else {

        return Promise.reject(TuneIn.ERR_NO_STATION)
      }

    } else {

      return Promise.reject(TuneIn.ERR_NO_STATION)
    }
  }
}

/**
 * Clears the custom stations
 *
 * @private
 */
TuneIn.prototype._clear = function () {

  this._customStationsDate = new Date()

  this._customStations.hasStations = false
  this._customStations.stations = {}
  this._customStations.countries = {}
  this._customStations.searchArray = []
}

/**
 * Destructor
 *
 * @since 2.0.0
 */
TuneIn.prototype.destroy = function () {

  // Empty
}

module.exports = TuneIn
