'use strict'

angular
  .module('basalteApp')
  .factory('BasInputModalConfig', basInputModalConfigFactory)

/**
 * @typedef {Object} TBasModalInputSelectOption
 * @property {(string|number)} [value]
 * @property {string} [text]
 * @property {boolean} [translate]
 * @property {boolean} [title]
 */

/**
 * @typedef {Object} TBasModalInput
 * @property {number} [type]
 * @property {string} [title]
 * @property {?Function} [validCheck]
 *
 * @property {?(TBasModalInputSelectOption[])} [inputOptions]
 * @property {?string} [initialValue]
 * @property {?boolean} [autoSave]
 * @property {?boolean} [autoAdvance]
 * @property {?boolean} [allowGoBack]
 * @property {?boolean} [clearCurrentOnBack]
 * @property {?boolean} [clearAllOnBack]
 *
 * @property {?string} [fieldName]
 * @property {?string} [placeHolder]
 */

function basInputModalConfigFactory () {

  /**
   * A configuration object for a BasInputModal
   *
   * @constructor
   */
  function BasInputModalConfig () {

    this.controller = ''

    /**
     * @type {string}
     */
    this.HTMLTemplate = ''

    /**
     * @type {string}
     */
    this.title = ''

    /**
     * @type {string}
     */
    this.subTitle = ''

    /**
     * @type {?TBasModalInput}
     */
    this.inputs = null

    /**
     * @type {string}
     */
    this.errorTextId = ''

    /**
     * @type {string}
     */
    this.saveTextId = ''

    /**
     * @type {boolean}
     */
    this.allowDismiss = true

    /**
     * @type {boolean}
     */
    this.useDynamicHeight = true

    /**
     * @type {?Function}
     */
    this.validCheck = null

    /**
     * @type {?Date}
     */
    this.time = null

    /**
     * @type {string}
     */
    this.roomId = ''

    /**
     * @type {string}
     */
    this.sourceUuid = ''

    /**
     * Options for the ModalService
     *
     * @instance
     * @type {Object}
     */
    this.modalOptions = null

    // Multi-screen input modal specific
    /**
     * @type {(?TBasModalInput)[]}
     */
    this.inputs = []

    /**
     * @type {boolean}
     */
    this.autoAdvance = false

    /**
     * @type {boolean}
     */
    this.allowGoBack = false

    /**
     * @type {boolean}
     */
    this.clearCurrentOnBack = false

    /**
     * @type {boolean}
     */
    this.clearAllOnBack = false

    /**
     * @type {?string}
     */
    this.previousText = ''

    /**
     * @type {?string}
     */
    this.nextText = ''

    /**
     * @type {boolean}
     */
    this.autoSave = false
  }

  return BasInputModalConfig
}
