'use strict'

angular
  .module('basalteApp')
  .constant('BAS_ELLIE', {
    K_PLUGIN_UUID: 'uuid',
    K_PLUGIN_BUTTON_TYPE: 'buttonType',
    K_PLUGIN_BUTTONS: 'buttons',
    K_PLUGIN_LABEL: 'label',
    K_PLUGIN_LABELS: 'labels',
    K_PLUGIN_TITLE: 'title',
    K_PLUGIN_SUBTITLE: 'subtitle',
    K_PLUGIN_STATE: 'state',
    K_PLUGIN_TRIGGER: 'trigger',
    K_PLUGIN_TOP_BUTTON_MODELS: 'topButtonModels',
    K_PLUGIN_BOTTOM_BUTTON_MODELS: 'bottomButtonModels'
  })
