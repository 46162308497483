'use strict'

var BasUtil = require('@basalte/bas-util')
var EventEmitter = require('@gidw/event-emitter-js')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Capabilities = require('./capabilities')

/**
 * @typedef {Object} TRoomAVAudio
 * @property {string[]} compatibleSources
 * @property {Object} capabilities
 * @property {Object} state
 */

/**
 * @typedef {Object} TRoomAVOptions
 * @property {boolean} emit
 */

/**
 * @typedef {Object} TRoomEqualiser
 * @property {number} id
 * @property {number} frequency
 * @property {number} gain
 */

/**
 * Class representing AV Audio
 *
 * @constructor
 * @extends EventEmitter
 * @mixes Capabilities.mixin
 * @param {BasCore} basCore
 * @param {string} roomUuid
 * @param {Object} [audio]
 * @since 3.4.0
 */
function AVAudio (basCore, roomUuid, audio) {

  EventEmitter.call(this)

  this._basCore = basCore

  this._roomUuid = roomUuid

  this._type =
    (BasUtil.isObject(audio) && BasUtil.isNEString(audio[P.TYPE]))
      ? audio[P.TYPE]
      : AVAudio.T_ASANO

  this._reachable = true

  this[CONSTANTS.CAPABILITIES] = new Capabilities(audio[P.CAPABILITIES])
  this._attributes = {}
  this._state = {}

  this._handleUpdateState = this._onUpdateState.bind(this)

  if (BasUtil.isObject(audio)) {

    this.parse(audio, { emit: false })
  }
}

AVAudio.prototype = Object.create(EventEmitter.prototype)
AVAudio.prototype.constructor = AVAudio
BasUtil.mergeObjects(AVAudio.prototype, Capabilities.mixin)

/**
 * @constant {string}
 */
AVAudio.EVT_STATE_CHANGED = 'evtAVAudioStateChanged'

/**
 * @constant {string}
 */
AVAudio.EVT_ATTRIBUTES_CHANGED = 'evtAVAudioAttributesChanged'

/**
 * @constant {string}
 */
AVAudio.EVT_CAPABILITIES_CHANGED = 'evtAVAudioACapabilitiesChanged'

/**
 * @constant {string}
 */
AVAudio.EVT_REACHABLE_CHANGED = 'evtAVAudioReachableChanged'

/**
 * @constant {string}
 */
AVAudio.T_ASANO = P.ASANO

/**
 * @constant {string}
 */
AVAudio.T_SONOS = P.SONOS

/**
 * @constant {string}
 */
AVAudio.T_AVR = P.AVR

/**
 * @constant {string}
 */
AVAudio.C_SOURCE = P.SOURCE

/**
 * @constant {string}
 */
AVAudio.C_ON = P.ON

/**
 * @constant {string}
 */
AVAudio.C_VOLUME = P.VOLUME

/**
 * @constant {string}
 */
AVAudio.C_MUTE = P.MUTE

/**
 * @constant {string}
 */
AVAudio.C_STARTUP_VOLUME = P.STARTUP_VOLUME

/**
 * @constant {string}
 */
AVAudio.C_BASS = P.BASS

/**
 * @constant {string}
 */
AVAudio.C_TREBLE = P.TREBLE

/**
 * @constant {string}
 */
AVAudio.C_RESET = P.RESET

/**
 * @constant {string}
 */
AVAudio.C_STEREO_WIDENING = P.STEREO_WIDENING

Object.defineProperties(AVAudio.prototype, {

  /**
   * @name AVAudio#roomUuid
   * @type {string}
   * @readonly
   */
  roomUuid: {
    get: function () {
      return this._roomUuid
    }
  },

  /**
   * @name AVAudio#type
   * @type {string}
   * @readonly
   */
  type: {
    get: function () {
      return this._type
    }
  },

  /**
   * @name AVAudio#reachable
   * @type {boolean}
   * @readonly
   */
  reachable: {
    get: function () {
      return this._reachable
    }
  },

  /**
   * @name AVAudio#compatibleSources
   * @type {string[]}
   * @readonly
   */
  compatibleSources: {
    get: function () {

      var _source

      _source = this._attributes[P.SOURCE]

      return BasUtil.isObject(_source)
        ? Array.isArray(_source[P.VALUES])
          ? _source[P.VALUES]
          : []
        : []
    }
  },

  /**
   * @name AVAudio#audioOutputs
   * @type {string[]}
   * @readonly
   */
  audioOutputs: {
    get: function () {

      return Array.isArray(this._attributes[P.AUDIO_OUTPUTS])
        ? this._attributes[P.AUDIO_OUTPUTS]
        : []
    }
  },

  /**
   * @name AVAudio#defaultSource
   * @type {string}
   * @readonly
   */
  defaultSource: {
    get: function () {

      var _source

      _source = this._attributes[P.SOURCE]

      return BasUtil.isObject(_source)
        ? BasUtil.isNEString(_source[P.DEFAULT])
          ? _source[P.DEFAULT]
          : ''
        : ''
    }
  },

  /**
   * @name AVAudio#source
   * @type {string}
   * @readonly
   */
  source: {
    get: function () {
      return BasUtil.isNEString(this._state[P.SOURCE])
        ? this._state[P.SOURCE]
        : ''
    }
  },

  /**
   * @name AVAudio#isOn
   * @type {boolean}
   * @readonly
   */
  isOn: {
    get: function () {
      return BasUtil.isBool(this._state[P.ON])
        ? this._state[P.ON]
        : false
    }
  },

  /**
   * @name AVAudio#mute
   * @type {boolean}
   * @readonly
   */
  mute: {
    get: function () {
      return BasUtil.isBool(this._state[P.MUTE])
        ? this._state[P.MUTE]
        : false
    }
  },

  /**
   * @name AVAudio#volume
   * @type {number}
   * @readonly
   */
  volume: {
    get: function () {
      return BasUtil.isPNumber(this._state[P.VOLUME], true)
        ? this._state[P.VOLUME]
        : 0
    }
  },

  /**
   * @name AVAudio#alert
   * @type {?string}
   * @readonly
   */
  alert: {
    get: function () {
      return BasUtil.isString(this._state[P.ALERT])
        ? this._state[P.ALERT]
        : null
    }
  },

  /**
   * @name AVAudio#startupVolume
   * @type {number}
   * @readonly
   */
  startupVolume: {
    get: function () {
      return BasUtil.isNumber(this._state[P.STARTUP_VOLUME])
        ? this._state[P.STARTUP_VOLUME]
        : 0
    }
  },

  /**
   * @name AVAudio#bass
   * @type {number}
   * @readonly
   */
  bass: {
    get: function () {
      return BasUtil.isNumber(this._state[P.BASS])
        ? this._state[P.BASS]
        : 0
    }
  },

  /**
   * @name AVAudio#treble
   * @type {number}
   * @readonly
   */
  treble: {
    get: function () {
      return BasUtil.isNumber(this._state[P.TREBLE])
        ? this._state[P.TREBLE]
        : 0
    }
  },

  /**
   * @name AVAudio#stereoWidening
   * @type {boolean}
   * @readonly
   */
  stereoWidening: {
    get: function () {
      return BasUtil.isBool(this._state[P.STEREO_WIDENING])
        ? this._state[P.STEREO_WIDENING]
        : false
    }
  }
})

/**
 * Parse an av message
 *
 * @param {Object} msg
 * @param {TRoomAVOptions} [options]
 */
AVAudio.prototype.parse = function (msg, options) {

  var value, emit
  var _emitReachable, _emitCapabilities, _emitAttributes, _emitState

  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  _emitReachable = false
  _emitAttributes = false
  _emitState = false

  // Reachable

  value = msg[P.REACHABLE]

  if (BasUtil.isBool(value)) {

    if (this._reachable !== value) {

      this._reachable = value

      _emitReachable = true
    }
  }

  // Capabilities

  _emitCapabilities =
    this[CONSTANTS.CAPABILITIES].parse(msg[P.CAPABILITIES])

  // Attributes

  value = msg[P.ATTRIBUTES]

  if (BasUtil.isObject(value)) {

    if (!BasUtil.isEqualPartialObject(this._attributes, value)) {

      BasUtil.mergeObjectsDeep(this._attributes, value)

      _emitAttributes = true
    }
  }

  // State

  value = msg[P.STATE]

  if (BasUtil.isObject(value)) {

    if (!BasUtil.isEqualPartialObject(this._state, value)) {

      BasUtil.mergeObjectsDeep(this._state, value)

      _emitState = true
    }
  }

  if (emit) {

    if (_emitReachable) this.emit(AVAudio.EVT_REACHABLE_CHANGED)
    if (_emitCapabilities) this.emit(AVAudio.EVT_CAPABILITIES_CHANGED)
    if (_emitAttributes) this.emit(AVAudio.EVT_ATTRIBUTES_CHANGED)
    if (_emitState) this.emit(AVAudio.EVT_STATE_CHANGED)
  }
}

/**
 * @returns {TRoomEqualiser[]}
 */
AVAudio.prototype.getEqualisers = function () {
  var i, obj, userEqualisers, keys, length, equaliser

  obj = this._state[P.DSP]
  userEqualisers = []

  if (obj && BasUtil.isObject(obj[P.EQUALISERS])) {

    keys = Object.keys(obj[P.EQUALISERS])
    length = keys.length

    for (i = 0; i < length; i++) {

      equaliser = obj[P.EQUALISERS][keys[i]]

      if (
        equaliser &&
        BasUtil.isPNumber(equaliser[P.ID], true) &&
        BasUtil.isVNumber(equaliser[P.GAIN]) &&
        BasUtil.isPNumber(equaliser[P.FREQUENCY])
      ) {

        userEqualisers.push(equaliser)
      }
    }
  }

  return userEqualisers
}

/**
 * Update a single or multiple properties
 *
 * @param {Object} newState
 * @returns {Promise}
 */
AVAudio.prototype.updateState = function (newState) {

  var msg

  if (this._basCore) {

    msg = this._getBasCoreMessage()
    msg[P.ROOM][P.AV][P.AUDIO][P.STATE] = newState

    return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleUpdateState)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @private
 * @param {Object} response
 * @returns {(string|Promise)}
 */
AVAudio.prototype._onUpdateState = function (response) {

  var result

  result = CONSTANTS.ERR_RESULT

  if (
    response &&
    response[P.ROOM] &&
    BasUtil.isNEString(response[P.ROOM][P.RESULT])
  ) {

    result = response[P.ROOM][P.RESULT]

    if (result === P.OK) {

      return result

    } else if (result === P.UNEXPECTED_ERROR) {

      return Promise.reject(
        this._reachable
          ? CONSTANTS.ERR_QUEUE_EMPTY
          : CONSTANTS.ERR_UNREACHABLE
      )
    }
  }

  return Promise.reject(result)
}

/**
 * Creates a template basCore message for AVAudio
 *
 * @protected
 * @returns {TDeviceMessage}
 */
AVAudio.prototype._getBasCoreMessage = function () {

  var msg

  msg = {}
  msg[P.ROOM] = {}
  msg[P.ROOM][P.UUID] = this._roomUuid
  msg[P.ROOM][P.AV] = {}
  msg[P.ROOM][P.AV][P.AUDIO] = {}

  return msg
}

/**
 * Turn on or off the audio
 *
 * @param {boolean} on
 * @returns {Promise}
 */
AVAudio.prototype.setOn = function (on) {

  var state

  if (BasUtil.isBool(on)) {

    state = {}
    state[P.ON] = on
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Mute or unmute the audio
 *
 * @param {boolean} mute
 * @returns {Promise}
 */
AVAudio.prototype.setMute = function (mute) {

  var state

  if (BasUtil.isBool(mute)) {

    state = {}
    state[P.MUTE] = mute
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Change the audio volume
 *
 * @param {number} volume
 * @returns {Promise}
 */
AVAudio.prototype.setVolume = function (volume) {

  var state

  if (BasUtil.isPNumber(volume, true)) {

    state = {}
    state[P.VOLUME] = volume
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Change the audio treble
 *
 * @param {number} treble
 * @returns {Promise}
 */
AVAudio.prototype.setTreble = function (treble) {

  var state

  if (BasUtil.isNumber(treble)) {

    state = {}
    state[P.TREBLE] = treble
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Change the audio bass
 *
 * @param {number} bass
 * @returns {Promise}
 */
AVAudio.prototype.setBass = function (bass) {

  var state

  if (BasUtil.isNumber(bass)) {

    state = {}
    state[P.BASS] = bass
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Change the audio startup volume
 *
 * @param {number} startupVolume
 * @returns {Promise}
 */
AVAudio.prototype.setStartupVolume = function (startupVolume) {

  var state

  if (BasUtil.isNumber(startupVolume)) {

    state = {}
    state[P.STARTUP_VOLUME] = startupVolume
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Change the stereo widening
 *
 * @param {boolean} stereoWidening
 * @returns {Promise}
 */
AVAudio.prototype.setStereoWidening = function (stereoWidening) {

  let state

  if (BasUtil.isBool(stereoWidening)) {

    state = {}
    state[P.STEREO_WIDENING] = stereoWidening
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Change the audio volume
 *
 * @param {string} sourceUuid
 * @returns {Promise}
 */
AVAudio.prototype.setSource = function (sourceUuid) {

  var state

  if (BasUtil.isString(sourceUuid)) {

    state = {}
    state[P.SOURCE] = sourceUuid
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Change DSP equalisers
 *
 * @param {Array<TRoomEqualiser>} equalisers
 * @returns {Promise}
 */
AVAudio.prototype.setDsp = function (equalisers) {

  var state

  if (BasUtil.isNEArray(equalisers)) {

    state = {}
    state[P.DSP] = {}
    state[P.DSP][P.EQUALISERS] = equalisers

    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @returns {Promise}
 */
AVAudio.prototype.reset = function () {

  return this.action(P.RESET)
}

/**
 * @param {string} action
 * @returns {Promise}
 */
AVAudio.prototype.action = function (
  action
) {
  var msg

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  msg = this._getBasCoreMessage()
  msg[P.ROOM][P.AV][P.AUDIO][P.STATE] = {}
  msg[P.ROOM][P.AV][P.AUDIO][P.STATE][P.DSP] = {}
  msg[P.ROOM][P.AV][P.AUDIO][P.STATE][P.DSP][P.ACTION] = action

  return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
}

/**
 * Creates a template basCore message for AVAudio
 *
 * @protected
 * @param {string} roomUuid
 * @returns {TDeviceMessage}
 */
AVAudio.getBasCoreMessage = function (roomUuid) {

  var msg

  msg = {}
  msg[P.ROOM] = {}
  msg[P.ROOM][P.UUID] = roomUuid
  msg[P.ROOM][P.AV] = {}
  msg[P.ROOM][P.AV][P.AUDIO] = {}

  return msg
}

/**
 * Gets the websocket message to update a single or multiple properties
 *
 * @param {string} audioSourceUuid
 * @param {Object} newState
 * @returns {Object}
 */
AVAudio.getUpdateStateMessage = function (audioSourceUuid, newState) {

  var msg = AVAudio.getBasCoreMessage(audioSourceUuid)
  msg[P.ROOM][P.AV][P.AUDIO][P.STATE] = newState

  return msg
}

/**
 * @param {string} audioSourceUuid
 * @param {number} volume
 * @returns {Object}
 */
AVAudio.getSetVolumeMessage = function (audioSourceUuid, volume) {

  var state

  if (BasUtil.isPNumber(volume, true)) {

    state = {}
    state[P.VOLUME] = volume

    return AVAudio.getUpdateStateMessage(audioSourceUuid, state)
  }
}

module.exports = AVAudio
