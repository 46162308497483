'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasNativeSettings', [
    '$window',
    'Logger',
    BasNativeSettings
  ])

/**
 * Service which handles native settings links
 *
 * @constructor
 * @param $window
 * @param Logger
 */
function BasNativeSettings (
  $window,
  Logger
) {

  this.openNotificationSettings = openNotificationSettings

  function openNotificationSettings () {

    var settings = _getSettings()

    if (settings) {

      settings.switchToSettings(
        _onOpenSettings,
        _onOpenSettingsError
      )
    }

    function _onOpenSettings () {

      Logger.info('_onOpenSettings')
    }

    function _onOpenSettingsError (error) {

      Logger.info('_onOpenSettingsError', error)
    }
  }

  /**
   * Get native settings plugin instance
   *
   * @private
   * @returns {?BasNativeSettings}
   */
  function _getSettings () {

    if (
      BasUtil.isObject($window['cordova']) &&
      BasUtil.isObject($window['cordova']['plugins']) &&
      $window['cordova']['plugins']['diagnostic']
    ) {

      return $window['cordova']['plugins']['diagnostic']
    }

    return null
  }
}
