'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('basSceneScheduleCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_INTL',
    'BAS_MODAL',
    'BAS_CURRENT_CORE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_INPUT_MODAL',
    'BasState',
    'CurrentBasCore',
    'RoomsHelper',
    'BasModal',
    'BasSceneSchedule',
    'BasIntl',
    'BasInputModal',
    'Weather',
    'BasUtilities',
    basSceneScheduleCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param {BasState} BasState
 * @param {CurrentBasCore} CurrentBasCore
 * @param {RoomsHelper} RoomsHelper
 * @param {BasModal} BasModal
 * @param BasSceneSchedule
 * @param {BasIntl} BasIntl
 * @param {BasInputModal} BasInputModal
 * @param {Weather} Weather
 * @param {BasUtilities} BasUtilities
 */
function basSceneScheduleCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_INTL,
  BAS_MODAL,
  BAS_CURRENT_CORE,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_INPUT_MODAL,
  BasState,
  CurrentBasCore,
  RoomsHelper,
  BasModal,
  BasSceneSchedule,
  BasIntl,
  BasInputModal,
  Weather,
  BasUtilities
) {
  var scheduleCtrl = this

  var _listeners = []

  var updateScheduleTimeoutId = 0

  var SCHEDULER_UPDATE_DEBOUNCE = 750

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentCoreState = CurrentBasCore.get()

  scheduleCtrl.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BAS_INTL}
   */
  scheduleCtrl.BAS_INTL = BAS_INTL

  /**
   * @type {BasRooms}
   */
  scheduleCtrl.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TBasStateObj}
   */
  scheduleCtrl.basState = BasState.get()

  /**
   * @type {TWeatherState}
   */
  scheduleCtrl.weather = Weather.get()

  scheduleCtrl.supportSunTimer = false

  scheduleCtrl.date = _getNewDate()
  scheduleCtrl.uiDate = ''

  scheduleCtrl.offset = 0

  scheduleCtrl.dateSummaryText = '-'
  scheduleCtrl.sceneNoneText = ''

  scheduleCtrl.removeSchedule = removeSchedule
  scheduleCtrl.selectWhen = selectWhen
  scheduleCtrl.toggleRepeat = toggleRepeat
  scheduleCtrl.toggle = toggle
  scheduleCtrl.selectScenes = selectScenes
  scheduleCtrl.selectScene = selectScene
  scheduleCtrl.selectDay = selectDay
  scheduleCtrl.setType = setType
  scheduleCtrl.back = back
  scheduleCtrl.offsetChanged = offsetChanged
  scheduleCtrl.onTimeClicked = onTimeClicked
  scheduleCtrl.dateChange = dateChange

  // noinspection JSUnusedGlobalSymbols
  /**
   * Angular UI-router callback
   */
  this.uiOnParamsChanged = _onParamsChanged

  init()

  function init () {

    $scope.$on('$destroy', onDestroy)

    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_VERSION,
      _onServerVersion
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_HOME_JOBS_INITIALIZED,
      _onHomeJobsInitialized
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_BAS_JOB_UPDATED,
      _onJobUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    ))
    _listeners.push($rootScope.$on(
      '$translateChangeSuccess',
      _onTranslationChange
    ))

    _syncSupportsSunTimer()
    _updateSceneNoneText()

    _syncWithSchedule()
  }

  /**
   * @param {string} scheduleUuid
   */
  function removeSchedule (scheduleUuid) {

    var basSceneSchedule

    basSceneSchedule = _getBasSceneSchedule()

    if (basSceneSchedule) {

      BasModal.show(BAS_MODAL.T_REMOVE).then(_onRemoveScheduleModal)
    }

    function _onRemoveScheduleModal (modal) {

      modal.close.then(_onRemoveScheduleModalClosed)
    }

    function _onRemoveScheduleModalClosed (result) {

      var basSceneScheduler

      if (result === BAS_MODAL.C_YES) {

        basSceneScheduler = _getScheduler()

        if (basSceneScheduler) basSceneScheduler.removeSchedule(scheduleUuid)
      }
    }
  }

  function dateChange () {

    var basSceneSchedule

    basSceneSchedule = _getBasSceneSchedule()

    if (basSceneSchedule &&
      basSceneSchedule.css[BasSceneSchedule.CSS_CAN_TIME]) {

      if (basSceneSchedule.repeat) {

        basSceneSchedule.setHoursMinutes(
          scheduleCtrl.date.getHours(),
          scheduleCtrl.date.getMinutes()
        )

      } else {

        basSceneSchedule.setTime(scheduleCtrl.date.getTime())
      }

      basSceneSchedule.commit()

      updateSchedulerDebounced()
    }
  }

  function selectWhen () {

    var scheduleId, basSceneSchedule

    scheduleId = _getScheduleId()
    basSceneSchedule = _getBasSceneSchedule()

    if (
      scheduleId &&
      basSceneSchedule &&
      basSceneSchedule.css[BasSceneSchedule.CSS_CAN_TIME]
    ) {
      BasState.go(STATES.SCHEDULE_TIMING)
    }
  }

  function selectScenes () {

    var scheduleId, basSceneSchedule

    scheduleId = _getScheduleId()
    basSceneSchedule = _getBasSceneSchedule()

    if (
      scheduleId &&
      basSceneSchedule &&
      basSceneSchedule.css[BasSceneSchedule.CSS_CAN_SCENE]
    ) {
      BasState.go(STATES.SCHEDULE_SCENES)
    }
  }

  function toggleRepeat () {

    var basSceneSchedule

    basSceneSchedule = _getBasSceneSchedule()

    if (basSceneSchedule) {

      basSceneSchedule.toggleRepeat()
      basSceneSchedule.commit()

      updateSchedulerDebounced()
    }
  }

  function toggle () {

    var basSceneSchedule

    basSceneSchedule = _getBasSceneSchedule()

    if (basSceneSchedule) {

      basSceneSchedule.toggle()
      basSceneSchedule.commit()

      updateSchedulerDebounced()
    }
  }

  function setType (type) {

    var basSceneSchedule

    basSceneSchedule = _getBasSceneSchedule()

    if (basSceneSchedule) {

      basSceneSchedule.setType(type)
      basSceneSchedule.commit()

      updateSchedulerDebounced()
    }
  }

  function updateSchedulerDebounced () {

    clearTimeout(updateScheduleTimeoutId)

    updateScheduleTimeoutId = setTimeout(
      _updateScheduler,
      SCHEDULER_UPDATE_DEBOUNCE
    )
  }

  function _updateScheduler () {

    var scheduler, basSceneSchedule

    scheduler = _getScheduler()
    basSceneSchedule = _getBasSceneSchedule()

    if (scheduler) scheduler.updateSchedule(basSceneSchedule)

    updateScheduleTimeoutId = 0
  }

  function offsetChanged () {

    var basSceneSchedule

    basSceneSchedule = _getBasSceneSchedule()

    if (basSceneSchedule) {

      basSceneSchedule.offset = scheduleCtrl.offset
      basSceneSchedule.commit()

      updateSchedulerDebounced()
    }
  }

  function onTimeClicked () {
    BasInputModal.show(
      BAS_INPUT_MODAL.T_TIME,
      {
        title: 'time',
        time: scheduleCtrl.date
      }
    ).then(_onModal)

    function _onModal (modal) {

      modal.close.then(_onModalClose)
    }

    function _onModalClose (result) {

      if (result instanceof Date) {

        scheduleCtrl.date = result
        _syncTimeUi()
        dateChange()
      }
    }
  }

  function selectDay (day) {

    var basSceneSchedule

    basSceneSchedule = _getBasSceneSchedule()

    if (basSceneSchedule && BasUtil.isNEString(day)) {

      basSceneSchedule.toggleDay(day)

      basSceneSchedule.commit()

      updateSchedulerDebounced()
    }
  }

  function back () {

    var scheduleId

    scheduleId = _getScheduleId()

    if (scheduleId) {

      BasState.go(
        STATES.SCHEDULE,
        {
          schedule: scheduleId
        }
      )
    }
  }

  function selectScene (scene) {

    var basSceneSchedule

    basSceneSchedule = _getBasSceneSchedule()

    if (basSceneSchedule && BasUtil.isNEString(scene)) {

      if (basSceneSchedule.scene === scene) {

        basSceneSchedule.setScene('')

      } else {

        basSceneSchedule.setScene(scene)
      }

      basSceneSchedule.commit()

      updateSchedulerDebounced()
    }
  }

  function _onParamsChanged () {

    _syncWithSchedule()

    $scope.$applyAsync()
  }

  function _onServerVersion () {

    _syncSupportsSunTimer()

    $scope.$applyAsync()
  }

  function _onHomeJobsInitialized () {

    _syncWithSchedule()

    $scope.$applyAsync()
  }

  /**
   * @private
   * @param _event
   * @param {string} roomsType
   */
  function _onRoomsUpdated (_event, roomsType) {

    if (roomsType === BAS_ROOMS.P_ROOMS) {

      _syncWithSchedule()

      $scope.$applyAsync()
    }
  }

  /**
   * @private
   * @param _event
   * @param {string} jobUuid
   */
  function _onJobUpdated (_event, jobUuid) {

    if (jobUuid === _getScheduleId()) {

      _syncWithSchedule()

      // Cancelled debounced update if planned
      if (updateScheduleTimeoutId) {

        clearTimeout(updateScheduleTimeoutId)
        updateScheduleTimeoutId = 0
      }

      $scope.$applyAsync()
    }
  }

  function _onTranslationChange () {

    _updateSceneNoneText()
  }

  function _syncWithSchedule () {

    var basSceneSchedule, newDate

    basSceneSchedule = _getBasSceneSchedule()

    if (basSceneSchedule) {

      newDate = _getNewDate()

      if (basSceneSchedule.repeat) {

        newDate.setHours(basSceneSchedule.date.getHours())
        newDate.setMinutes(basSceneSchedule.date.getMinutes())

      } else {

        newDate.setTime(basSceneSchedule.date.getTime())
      }

      // Set new date
      // Angular ng-model with Date objects works only by reference
      scheduleCtrl.date = newDate

      _syncTimeUi()

      scheduleCtrl.offset = basSceneSchedule.offset
    }
  }

  function _syncTimeUi () {
    // Don't take timezone into account, this date object is correctly
    //  represented in the current system timezone, since it was set using
    //  already timezone adjusted values
    scheduleCtrl.uiDate = BasIntl.dateToString(
      scheduleCtrl.date,
      BAS_INTL.LOCALE_OPTION_SHORT,
      false
    )
  }

  function _syncSupportsSunTimer () {

    scheduleCtrl.supportSunTimer = false

    if (CurrentBasCore.hasCore()) {

      scheduleCtrl.supportSunTimer =
        currentCoreState.core.core.supportsSunriseSet
    }
  }

  /**
   * @private
   * @returns {?BasRoomScheduler}
   */
  function _getScheduler () {

    var home

    home = RoomsHelper.getHome()

    return (home && home.scheduler && home.scheduler.updateSchedule)
      ? home.scheduler
      : null
  }

  /**
   * @private
   * @returns {?BasSceneSchedule}
   */
  function _getBasSceneSchedule () {

    var home, basSceneSchedule, scheduleId

    home = RoomsHelper.getHome()

    if (home && home.scheduler && home.scheduler.schedules) {

      scheduleId = _getScheduleId()

      if (scheduleId) {

        basSceneSchedule = home.scheduler.schedules[scheduleId]

        if (basSceneSchedule && basSceneSchedule.setScene) {

          return basSceneSchedule
        }
      }
    }

    return null
  }

  /**
   * @private
   * @returns {string}
   */
  function _getScheduleId () {

    var scheduleId

    scheduleId = $uiRouterGlobals.params.schedule

    return BasUtil.isNEString(scheduleId) ? scheduleId : ''
  }

  /**
   * @private
   * @returns {Date}
   */
  function _getNewDate () {

    var date = new Date()

    date.setSeconds(0)
    date.setMilliseconds(0)

    return date
  }

  function _updateSceneNoneText () {

    scheduleCtrl.sceneNoneText =
      '- ' + BasUtilities.translate('none').toLowerCase() + ' -'
  }

  function onDestroy () {

    if (updateScheduleTimeoutId) {

      clearTimeout(updateScheduleTimeoutId)
      updateScheduleTimeoutId = 0

      _updateScheduler()
    }

    BasUtil.executeArray(_listeners)
  }
}
