'use strict'

angular
  .module('basalteApp')
  .directive('basLight', [
    'BAS_HTML',
    basLightDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basLight
 */
function basLightDirective (
  BAS_HTML
) {
  return {
    restrict: 'EA',
    bindToController: {
      light: '<',
      disabled: '<'
    },
    controllerAs: 'basLightDir',
    controller: function () {},
    template: BAS_HTML.light
  }
}
