'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('presetKNXPresetsCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'Sources',
    'SourcesHelper',
    presetKNXPresetsCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {Sources} Sources
 * @param {SourcesHelper} SourcesHelper
 */
function presetKNXPresetsCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_SOURCES,
  BAS_SOURCE,
  Sources,
  SourcesHelper
) {
  var preset = this

  let currentSourceId = ''

  preset.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BAS_SOURCES}
   */
  preset.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {boolean}
   */
  preset.isLoadingPage = false

  preset.selectFavourite = selectFavourite

  // noinspection JSUnusedGlobalSymbols
  /**
   * Angular UI-router callback
   */
  this.uiOnParamsChanged = onParamsChanged

  $scope.$on('$destroy', onDestroy)

  init()

  function init () {

    _syncEventRegistration()
  }

  function onParamsChanged () {

    _syncEventRegistration()
  }

  /**
   * @param {string} favouriteId
   */
  function selectFavourite (favouriteId) {

    var presetId, player, _preset

    presetId = _getPresetId()
    player = _getPlayerOrAudioSource()

    if (
      BasUtil.isNEString(presetId) &&
      player &&
      player.knxPresets
    ) {

      _preset = player.knxPresets.presets[parseInt(presetId)]

      player.knxPresets.link(
        presetId,
        _preset && _preset.favouriteId === favouriteId
          ? ''
          : favouriteId
      )
    }
  }

  function _syncEventRegistration () {

    const player = _getPlayerOrAudioSource()

    _unregister()

    if (player) {

      const id = player.getId()

      Sources.registerFor(
        BAS_SOURCE.COL_EVT_FAVOURITES,
        id
      )

      currentSourceId = id
    }
  }

  function _unregister () {

    if (currentSourceId) {

      Sources.unregisterFor(
        BAS_SOURCE.COL_EVT_FAVOURITES,
        currentSourceId
      )

      currentSourceId = ''
    }
  }

  /**
   * @private
   * @returns {?BasSource}
   */
  function _getPlayerOrAudioSource () {

    var source

    source = SourcesHelper.getBasSource(_getPlayerUuid())

    if (
      source && (
        source.isAudioSource ||
        source.type === BAS_SOURCE.T_PLAYER
      )
    ) {

      return source
    }

    return null
  }

  /**
   * @private
   * @returns {string}
   */
  function _getPlayerUuid () {

    var streamId

    streamId = $uiRouterGlobals.params.stream

    return BasUtil.isNEString(streamId) ? streamId : ''
  }

  /**
   * @private
   * @returns {string}
   */
  function _getPresetId () {

    var presetId

    presetId = $uiRouterGlobals.params.presetId

    return BasUtil.isNEString(presetId) ? presetId : ''
  }

  function onDestroy () {

    _unregister()
  }
}
