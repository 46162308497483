'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basColorPicker', [
    'Iro',
    basColorPickerDirective
  ])

/**
 * @param Iro
 * @returns basColorPicker
 */
function basColorPickerDirective (
  Iro
) {
  return {
    restrict: 'AE',
    scope: {
      light: '=',
      width: '<',
      height: '<',
      debounce: '<',
      colorChange: '&'
    },
    link: link
  }

  function link (
    scope,
    element
  ) {
    var CSS_COLOR_PICKER = 'bas-color-picker'
    var CSS_COLOR_DISABLED = 'bas-color-disabled'

    var width = 250
    var height = 250
    var debounce = 0

    var colorChangeTO = null
    var originalColor = {
      h: 0,
      s: 0,
      v: 100
    }

    var colorElement = element[0]

    var colorPicker

    init()

    function init () {

      if (scope.width) width = scope.width
      if (scope.height) height = scope.height
      if (scope.debounce) debounce = scope.debounce

      if (colorElement) {
        colorElement.classList.add(CSS_COLOR_PICKER)

        if (scope.light &&
          !scope.light.canChangeColor) {

          colorElement.classList.add(CSS_COLOR_DISABLED)
        }
      }

      if (scope.light) {
        originalColor = {
          h: scope.light.hue,
          s: scope.light.saturation,
          v: 100
        }
      }

      colorPicker = new Iro.ColorPicker(
        colorElement,
        {
          width: width,
          height: height,
          display: 'inline-block',
          padding: 0,
          layout: [
            {
              component: Iro.ui.Wheel,
              options: {
                handleRadius: 10
              }
            }
          ],
          color: originalColor
        }
      )

      scope.$on('$destroy', onDestroy)

      colorPicker.on('color:change', onColorChange)

      // Set model object watcher, can be undefined in parent template
      scope.$watch('light', updateColor)
      scope.$watch(getSaturation, updateColor)
      scope.$watch(getHue, updateColor)

      updateColor()
    }

    function getSaturation () {

      return scope.light
        ? scope.light.saturation
        : null
    }

    function getHue () {

      return scope.light
        ? scope.light.hue
        : null
    }

    function updateColor () {
      if (BasUtil.isObject(scope.light)) {

        colorPicker.color.hsv = {
          h: scope.light.hue,
          s: scope.light.saturation,
          v: 100
        }
      }
    }

    function onColorChange (color) {

      var hue, saturation

      hue = -1
      saturation = -1

      if (BasUtil.isObject(color.hsv)) {
        hue = color.hsv.h
        saturation = color.hsv.s
      }

      if (scope.light &&
        scope.light.canChangeColor &&
        hue >= 0 &&
        saturation >= 0 &&
        compareColor(hue, saturation)) {

        // Set new colors
        scope.light.hue = hue
        scope.light.saturation = saturation

        // Make color sync
        executeColorChange()
      }
    }

    function executeColorChange () {

      // Clear timeout
      clearTimeout(colorChangeTO)

      if (typeof scope.colorChange === 'function') {

        // Set new timeout if necessary
        if (debounce > 0) {
          colorChangeTO = setTimeout(scope.colorChange, debounce)
        } else {
          scope.colorChange()
        }
      }
    }

    function onDestroy () {
      colorPicker.off('color:change', onColorChange)
    }

    function compareColor (hue, saturation) {

      if (BasUtil.isObject(scope.light)) {
        return (
          scope.light.hue !== hue ||
          scope.light.saturation !== saturation
        )
      }

      return false
    }
  }
}
