'use strict'

angular
  .module('basalteApp')
  .controller('thermostatPickerSceneCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'SCENE_STATES',
    'BasSceneHelper',
    'BasSceneStateHelper',
    'CurrentRoom',
    thermostatPickerSceneCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param SCENE_STATES
 * @param BasSceneHelper
 * @param {BasSceneStateHelper} BasSceneStateHelper
 * @param {CurrentRoom} CurrentRoom
 */
function thermostatPickerSceneCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_ROOMS,
  SCENE_STATES,
  BasSceneHelper,
  BasSceneStateHelper,
  CurrentRoom
) {
  var room = this
  var _step

  room.selectThermostat = selectThermostat

  _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)
  room.thermostats =
    BAS_ROOMS.ROOMS.rooms[_step.room].thermostats.thermostats

  /**
   * @param {string} thermostatId
   */
  function selectThermostat (thermostatId) {

    CurrentRoom.go(
      BasSceneStateHelper.getSceneState(SCENE_STATES.SCENE_STEP_THERMOSTAT),
      {
        thermostat: thermostatId
      }
    )
  }
}
