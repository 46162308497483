'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('Selection', [
    'Logger',
    SelectionFactory
  ])

/**
 * @typedef {Object} TSelections
 * @property {Object} elements
 * @property {boolean} collection
 */

function SelectionFactory (Logger) {
  var serviceName = 'Selection Service'

  Logger.debug(serviceName)

  /**
   * @constructor
   */
  function Selection () {

    /**
     * @instance
     * @type {TSelections}
     */
    this.selections = {}
    this.selections.elements = {}
    this.selections.collection = false

    /**
     * @type {number}
     */
    this.counter = 0

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * @instance
     * @type {Array<string | number>}
     */
    this.selectedItems = []
  }

  /**
   * @constant {string}
   */
  Selection.TYPE_LOCAL = 'local'

  /**
   * @constant {string}
   */
  Selection.TYPE_DEEZER = 'deezer'

  /**
   * @constant {string}
   */
  Selection.TYPE_TIDAL = 'tidal'

  /**
   * @constant {string}
   */
  Selection.TYPE_SPOTIFY = 'spotify'

  /**
   * @param order
   * @param key
   * @constructor
   */
  function SelectionItem (order, key) {
    this.order = order
    this.key = key
  }

  Selection.prototype._sync = function () {

    var keys, elements, length, i, orderObj

    // Clear array
    this.selectedItems = []

    // Get all keys
    keys = Object.keys(this.selections.elements)
    elements = this.selections.elements

    // Store key if not false
    length = keys.length
    for (i = 0; i < length; i++) {

      orderObj = elements[keys[i]]

      if (orderObj !== false &&
        BasUtil.isNEString(keys[i])) {

        this.selectedItems.push(orderObj.key)
      }
    }

    // Sort them
    this.selectedItems.sort(itemSort)

    function itemSort (a, b) {
      var aOrder = elements[a].order
      var bOrder = elements[b].order

      return (aOrder - bOrder)
    }
  }

  // Exposed functionality

  /**
   * Select or deselect an item
   *
   * @param {BasLibraryElement} element
   * @param {boolean} [override]
   */
  Selection.prototype.select = function (element, override) {

    var key = element.getSelectionKey()

    if (BasUtil.isObject(element)) {

      if (override === false) {
        this.selections.elements[key] = false
      } else if (override === true || !this.selections.elements[key]) {

        // Keep the selectionKey as a value,
        // Otherwise it'll be typecast to a string if only kept as key
        this.selections.elements[key] =
          new SelectionItem(this.counter, key)
        this.counter++

      } else {

        this.selections.elements[key] = false
      }
    }

    this._sync()
  }

  /**
   * Clear the selection
   */
  Selection.prototype.clear = function () {

    this.selections.elements = {}
    this.selections.collection = false
    this.selectedItems = []
    this.counter = 0
  }

  return Selection
}
