'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('TidalLibraryPage', [
    'TidalCollection',
    'BasLibraryPage',
    TidalLibraryPageFactory
  ])

/**
 * @typedef {Object} TTidalCollectionData
 * @property {string} name
 * @property {string} titleName
 * @property {boolean} [hasTab = true]
 * @property {boolean} [canGridView = true]
 * @property {string} contentType
 * @property {string} type
 * @property {string} [id]
 */

function TidalLibraryPageFactory (
  TidalCollection,
  BasLibraryPage
) {
  /**
   * Object that contains all info to create a library page
   *
   * @constructor
   * @extends BasLibraryPage
   * @param {Function} handler
   */
  function TidalLibraryPage (handler) {

    BasLibraryPage.call(this, handler)
  }

  // Set new prototype from inherited object
  TidalLibraryPage.prototype = Object.create(BasLibraryPage.prototype)

  // Set correct constructor after setting new prototype
  TidalLibraryPage.prototype.constructor = TidalLibraryPage

  /**
   * @constant {string}
   */
  TidalLibraryPage.CONTENT_GENERIC = 'generic'

  /**
   * @constant {string}
   */
  TidalLibraryPage.CONTENT_NEW = 'new'

  /**
   * @constant {string}
   */
  TidalLibraryPage.CONTENT_SEARCH = 'search'

  /**
   * @constant {string}
   */
  TidalLibraryPage.CONTENT_TIDAL_RISING = 'tidalRising'

  /**
   * @constant {string}
   */
  TidalLibraryPage.CONTENT_PLAYLISTS = 'playlists'

  /**
   * @constant {string}
   */
  TidalLibraryPage.CONTENT_GENRES = 'genres'

  /**
   * @constant {string}
   */
  TidalLibraryPage.CONTENT_GENRE = 'genre'

  /**
   * @constant {string}
   */
  TidalLibraryPage.CONTENT_MY_MUSIC = 'myMusic'

  /**
   * @param {string} content
   */
  TidalLibraryPage.prototype.setContent = function (content) {

    // Check input
    if (BasUtil.isNEString(content)) {
      this.content = content

      // Check content
      switch (this.content) {
        case BasLibraryPage.CONTENT_NOT_CONNECTED:

          // Show message
          this.setMessage('not_linked')
          // Add message CSS
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_OVERLAY,
            true
          )
          this.setCSSClass(
            BasLibraryPage.CLASS_HAS_MESSAGE,
            true
          )
          this.processClasses()

          break
      }
    } else {
      this.content = TidalLibraryPage.CONTENT_GENERIC
    }
  }

  /**
   * @param {TTidalCollectionData} data
   */
  TidalLibraryPage.prototype.makeCollection = function (data) {
    var collection

    // Check defaults
    if (data.hasTab !== false) data.hasTab = true

    if (data.canGridView !== false) data.canGridView = true

    // Make new collection
    collection = new TidalCollection(this.handler)

    // Set properties
    collection.setName(data.name, data.titleName)
    collection.setHasTab(data.hasTab)
    collection.setCanGridView(data.canGridView)
    collection.setContentType(data.contentType)
    collection.type = data.type
    collection.id = data.id

    // Pass detail information in case of artist
    if (BasUtil.isObject(this.collections[0].detail)) {
      collection.detail = this.collections[0].detail
    }

    // Pass detailElement information in case of artist
    if (BasUtil.isObject(this.collections[0].detailElement)) {
      collection.detailElement = this.collections[0].detailElement
    }

    this.collectionsData.push(collection)
  }

  TidalLibraryPage.prototype.dataCheck = function () {
    var length, i

    length = this.collections.length
    for (i = 0; i < length; i++) this.collections[i].dataCheck()
  }

  /**
   * @returns {Promise}
   */
  TidalLibraryPage.prototype.retrieve = function () {
    if (this.hasTabs) {
      return this.selectTab(this.tabs[0])
    } else {
      return BasLibraryPage.prototype.retrieve.call(this)
    }
  }

  return TidalLibraryPage

}
