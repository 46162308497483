'use strict'

angular
  .module('basalteApp')
  .factory('SpotifyElement', [
    'BAS_LIBRARY',
    'BAS_SOURCE',
    'BasLibraryElement',
    spotifyElementFactory
  ])

/**
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param BasLibraryElement
 * @returns SpotifyElement
 */
function spotifyElementFactory (
  BAS_LIBRARY,
  BAS_SOURCE,
  BasLibraryElement
) {

  /**
   * Spotify Ui element class
   * contains all information needed for HTML templates
   *
   * @constructor
   * @extends BasLibraryElement
   * @param {Object} [spotifyObject]
   */
  function SpotifyElement (spotifyObject) {

    // Call 'super' constructor
    BasLibraryElement.call(this)

    this.libraryType = BAS_LIBRARY.TYPE_SPOTIFY

    /**
     * Store reference to original Spotify object
     *
     * @instance
     * @type {?Object}
     */
    this.spotify = spotifyObject

    /**
     * @instance
     * @type {string}
     */
    this.type = SpotifyElement.TYPE_TRACK

    /**
     * @instance
     * @type {string}
     */
    this.subType = ''

    /**
     * @instance
     * @type {string}
     */
    this.uri = ''

    /**
     * @instance
     * @type {string}
     */
    this.linkedUri = ''

    /**
     * @instance
     * @type {string}
     */
    this.owner = ''

    /**
     * @instance
     * @type {string}
     */
    this.tracksLink = ''

    /**
     * @instance
     * @type {string}
     */
    this.collectionType = ''

    /**
     * @instance
     * @type {string}
     */
    this.servicePrefix = BAS_SOURCE.URI_PREFIX_SPOTIFY
  }

  // Set new prototype from inherited object
  SpotifyElement.prototype = Object.create(BasLibraryElement.prototype)

  // Set correct constructor after setting new prototype
  SpotifyElement.prototype.constructor = SpotifyElement

  // region Spotify types

  /**
   * @constant {string}
   */
  SpotifyElement.TYPE_ALBUM = 'album'

  /**
   * @constant {string}
   */
  SpotifyElement.TYPE_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  SpotifyElement.TYPE_GENRE = 'genre'

  /**
   * @constant {string}
   */
  SpotifyElement.TYPE_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  SpotifyElement.TYPE_RADIO = 'radio'

  /**
   * @constant {string}
   */
  SpotifyElement.TYPE_TRACK = 'track'

  // Custom types

  /**
   * @constant {string}
   */
  SpotifyElement.TYPE_SECTION = 'section'

  // endregion

  /**
   * @returns {(number|string)}
   */
  SpotifyElement.prototype.getSelectionKey = function () {
    return this.uri
  }

  return SpotifyElement
}
