'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasLocalisation', [
    '$rootScope',
    'BAS_CURRENT_CORE',
    'CurrentBasCore',
    'BasPreferences',
    'BasUtilities',
    BasLocalisation
  ])

/**
 * @typedef {Object} TLocalisation
 * @property {string} country Coming from server or device locale
 * @property {string} language Coming from app language settings
 * @property {string} locale language_country
 * @property {string} spotifyCountry Either same as country or empty
 *                    depending whether spotify supports the country
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasPreferences} BasPreferences
 * @param {BasUtilities} BasUtilities
 */
function BasLocalisation (
  $rootScope,
  BAS_CURRENT_CORE,
  CurrentBasCore,
  BasPreferences,
  BasUtilities
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TLocalisation}
   */
  var state = {}

  state.country = ''
  state.language = ''
  state.locale = ''
  state.spotifyCountry = ''

  $rootScope.$on(BAS_CURRENT_CORE.EVT_CORE_ROOMS_RECEIVED, _onRoomsReceived)
  $rootScope.$on(BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED, _onMusicConfig)
  $rootScope.$on('$translateChangeSuccess', updateLanguage)

  this.get = function () {
    return state
  }

  this.clearSpotifyCountry = function () {
    state.spotifyCountry = ''
  }

  if (CurrentBasCore.hasCore()) {

    if (
      currentBasCoreState.core.core.roomsReceived ||
      currentBasCoreState.core.core.musicConfigReceived
    ) {
      setVariables()
    }
  }

  function _onMusicConfig () {

    setVariables()
  }

  function _onRoomsReceived () {

    setVariables()
  }

  function updateLanguage () {
    setLanguage()
  }

  function setVariables () {

    var country = BasUtilities.getCountry()

    if (country !== state.country) state.spotifyCountry = country
    state.country = country

    setLanguage()
  }

  function setLanguage () {

    var languageLocale = BasPreferences.getLanguage()

    if (BasUtil.isNEString(languageLocale)) {

      state.language = languageLocale.split('_')[0]
      makeLocale()
    }
  }

  function makeLocale () {
    state.locale = state.language + '_' + state.country
  }
}
