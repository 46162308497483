'use strict'

angular
  .module('basalteApp')
  .factory('BasMonitorWeek', basMonitorWeekFactory)

function basMonitorWeekFactory () {

  /**
   * @constructor
   * @param {BasMonitorDay[]} days
   */
  function BasMonitorWeek (days) {

    /**
     * @type {Object<string,BasMonitorDay>}
     */
    this.days = {}

    /**
     * @type {string[]}
     */
    this.uiDays = []

    /**
     * @type {string}
     */
    this.listName = ''
    this.uuid = ''

    this.parse(days)
  }

  /**
   * @param {BasMonitorDay[]} days
   */
  BasMonitorWeek.prototype.parse = function (days) {

    var i, length, day

    length = days.length
    for (i = 0; i < length; i++) {

      day = days[i]

      if (day) {

        this.uiDays.push(day.uuid)
        this.days[day.uuid] = day
      }
    }

    this.listName = this.makeListName(this.days)
    this.uuid = this.listName
  }

  BasMonitorWeek.prototype.makeListName = function () {

    var length = this.uiDays.length

    if (length === 0) return ''
    if (length === 1) return this.days[this.uiDays[0]].listName
    if (length > 1) {

      return this.days[this.uiDays[0]].listName + ' - ' +
        this.days[this.uiDays[length - 1]].listName
    }
  }

  BasMonitorWeek.prototype.updateTranslation = function () {

    this.listName = this.makeListName(this.days)
  }

  return BasMonitorWeek
}
