'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('restartServer', restartServer)

function restartServer () {

  return {
    restrict: 'A',
    template:
      '<div class="' +
      'bbs bbs-item bbs-centered' +
      ' bbs-flex bbs-active-select bbs-action"' +
      ' bas-click="restart.restartServer()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      ' data-translate="{{restart.restartTxt}}"></div>' +
      '</div>',
    controller: [
      '$scope',
      '$rootScope',
      'BAS_CURRENT_CORE',
      'CurrentBasCore',
      'BasCoreApiHelper',
      controller
    ],
    controllerAs: 'restart'
  }

  /**
   * @param $scope
   * @param $rootScope
   * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
   * @param {CurrentBasCore} CurrentBasCore
   * @param {BasCoreApiHelper} BasCoreApiHelper
   */
  function controller (
    $scope,
    $rootScope,
    BAS_CURRENT_CORE,
    CurrentBasCore,
    BasCoreApiHelper
  ) {
    var restart = this
    var _listeners = []

    /**
     * @type {TCurrentBasCoreState}
     */
    restart.currentBasCoreState = CurrentBasCore.get()

    restart.restartTxt = ''

    restart.restartServer = BasCoreApiHelper.restartServer

    init()

    function init () {

      $scope.$on('$destroy', destroy)

      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_HAS_UPDATE,
        onStatusHasUpdate
      ))

      syncRestartTxt()
    }

    function syncRestartTxt () {

      restart.restartTxt = BasCoreApiHelper.hasUpdate()
        ? 'update_server'
        : 'restart_server'
    }

    function onStatusHasUpdate () {

      syncRestartTxt()
      $scope.$applyAsync()
    }

    function destroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
