'use strict'

/**
 * @typedef {Object} TBasSipState
 * @property {boolean} hasDoorPhone
 * @property {string} uiSipStateDomain
 * @property {string[]} uiSipStateUsernames
 * @property {string} uiSipStateRegistrationSimple
 * @property {string} uiSipStateRegistrationDetailed
 * @property {boolean} uiShowSipState
 */

/**
 * @typedef {Object} TBasAdelanteState
 * @property {boolean} hasAdelante
 * @property {TAdelanteRegistrationState[]} states
 */

angular
  .module('basalteApp')
  .constant('BAS_SIP', {
    SIP_CLEARED: 'cleared',
    SIP_FAILED: 'failed',
    SIP_NO_CONFIGURATION: 'no_configuration',
    SIP_CONNECTED: 'connected',
    SIP_CONNECTING: 'connecting_text',
    EVT_SIP_CALL: 'evtEllieSipCall',
    EVT_SIP_CONFIG_CHANGED: 'evtEllieSipConfigChanged',
    EVT_SIP_ADDRESSES_INITALIZED: 'evtSipAddressesInitialized',
    EVT_ADELANTE_STATES_CHANGED: 'evtAdelanteStatesChanged',

    /**
     * @type {TBasSipState}
     */
    STATE: {
      hasDoorPhone: false,
      uiSipStateDomain: '-',
      uiSipStateUsernames: ['-'],
      uiSipStateRegistrationSimple: '-',
      uiSipStateRegistrationDetailed: '-',
      uiShowSipState: true
    },

    /**
     * @type {TBasAdelanteState}
     */
    ADELANTE_STATE: {
      hasAdelante: false,
      states: [],
      globalState: {
        hasError: false,
        domain: '-',
        status: '-'
      }
    }
  })
