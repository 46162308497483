'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('libraryCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'UI_HELPER',
    'BAS_ROOMS',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BasState',
    'UiHelper',
    'Sources',
    'CurrentRoom',
    'CurrentBasCore',
    'LibraryState',
    libraryCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {UI_HELPER} UI_HELPER
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BasState} BasState
 * @param {UiHelper} UiHelper
 * @param {Sources} Sources
 * @param {CurrentRoom} CurrentRoom
 * @param {CurrentBasCore} CurrentBasCore
 * @param {LibraryState} LibraryState
 */
function libraryCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  UI_HELPER,
  BAS_ROOMS,
  BAS_SOURCES,
  BAS_SOURCE,
  BasState,
  UiHelper,
  Sources,
  CurrentRoom,
  CurrentBasCore,
  LibraryState
) {
  var mainLibrary = this
  var listeners = []

  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  mainLibrary.I_LOCAL = 'local'
  mainLibrary.I_RADIO = 'radio'
  mainLibrary.I_DEEZER = 'deezer'
  mainLibrary.I_SPOTIFY = 'spotify'
  mainLibrary.I_TIDAL = 'tidal'

  /**
   * @type {BasRooms}
   */
  mainLibrary.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BAS_SOURCES}
   */
  mainLibrary.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {BAS_SOURCE}
   */
  mainLibrary.BAS_SOURCE = BAS_SOURCE

  /**
   * @type {TBasStateObj}
   */
  mainLibrary.basState = BasState.get()

  /**
   * @type {TCurrentRoomState}
   */
  mainLibrary.currentRoom = CurrentRoom.get()

  /**
   * @type {TLibraryState}
   */
  mainLibrary.libraryState = LibraryState.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  mainLibrary.currentBasCoreState = CurrentBasCore.get()

  mainLibrary.selectItem = selectItem
  mainLibrary.onPlayerClick = onPlayerClick

  init()

  function init () {

    $scope.$on('$destroy', onDestroy)

    if (mainLibrary.libraryState.current) {

      Sources.registerFor(
        [
          BAS_SOURCE.COL_EVT_LIBRARY,
          BAS_SOURCE.COL_EVT_FAVOURITES
        ],
        mainLibrary.libraryState.current.playerId
      )
    }

    listeners.push(
      $rootScope.$on(
        UI_HELPER.EVT_RESIZE,
        onUiSize
      )
    )
    listeners.push(
      $rootScope.$on(
        BAS_SOURCE.EVT_FAVOURITES_UPDATED,
        onFavouritesUpdated
      )
    )
  }

  function checkScreenWidth () {

    var source

    if (
      basUi.prop.wMedium &&
      $uiRouterGlobals.current.name === STATES.MUSIC_LIBRARY
    ) {
      source = LibraryState.getCurrentSource()

      if (source && source.hasLocalContent()) {

        CurrentRoom.go(STATES.MUSIC_LIBRARY_LOCAL)

      } else {

        CurrentRoom.go(STATES.MUSIC_LIBRARY_RADIO)
      }
    }
  }

  function onUiSize () {

    if (!$uiRouterGlobals.transition) checkScreenWidth()
  }

  function onFavouritesUpdated (_evt, basSource) {

    var currentBasSource

    currentBasSource = LibraryState.getCurrentSource()

    if (
      currentBasSource &&
      basSource &&
      currentBasSource.getId() === basSource.getId()
    ) {

      $scope.$applyAsync()
    }
  }

  /**
   * @param {string} item
   */
  function selectItem (item) {

    switch (item) {
      case mainLibrary.I_LOCAL:

        CurrentRoom.go(STATES.MUSIC_LIBRARY_LOCAL)

        break
      case mainLibrary.I_RADIO:

        CurrentRoom.go(STATES.MUSIC_LIBRARY_RADIO)

        break
      case mainLibrary.I_DEEZER:

        CurrentRoom.go(STATES.MUSIC_LIBRARY_DEEZER)

        break
      case mainLibrary.I_SPOTIFY:

        CurrentRoom.go(STATES.MUSIC_LIBRARY_SPOTIFY)

        break
      case mainLibrary.I_TIDAL:

        CurrentRoom.go(STATES.MUSIC_LIBRARY_TIDAL)

        break
    }
  }

  function onPlayerClick () {

    CurrentRoom.go(STATES.MUSIC_PLAYER)
  }

  function onDestroy () {

    if (mainLibrary.libraryState.current) {

      Sources.unregisterFor(
        [
          BAS_SOURCE.COL_EVT_LIBRARY,
          BAS_SOURCE.COL_EVT_FAVOURITES
        ],
        mainLibrary.libraryState.current.playerId
      )
    }

    BasUtil.executeArray(listeners)
    listeners = []
  }
}
