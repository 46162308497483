'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSchedulerLine', basSchedulerLineFactory)

function basSchedulerLineFactory () {

  /**
   * @constructor
   * @param {string} [text]
   * @param {string} [subText]
   * @param {string|string[]} [cssClasses]
   */
  function BasSchedulerLine (text, subText, cssClasses) {

    var i

    /**
     * @type {string}
     */
    this.text = BasUtil.isNEString(text) ? text : ''

    /**
     * @type {string}
     */
    this.subText = BasUtil.isNEString(subText) ? subText : ''

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}

    if (BasUtil.isNEString(cssClasses)) {

      this.css[cssClasses] = true

    } else if (BasUtil.isNEArray(cssClasses)) {

      for (i = 0; i < cssClasses.length; i++) {

        if (BasUtil.isNEString(cssClasses[i])) {

          this.css[cssClasses[i]] = true
        }
      }
    }
  }

  return BasSchedulerLine
}
