'use strict'

const BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .controller('callHistoryDetailCtrl', [
    '$scope',
    '$rootScope',
    '$uiRouterGlobals',
    'BAS_CALL_HISTORY',
    'BasCallHistory',
    'BasUtilities',
    'Swiper',
    callHistoryDetailCtrl
  ])

/**
 * @param $scope
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {BAS_CALL_HISTORY} BAS_CALL_HISTORY
 * @param {BasCallHistory} BasCallHistory
 * @param {BasUtilities} BasUtilities
 * @param Swiper
 */
function callHistoryDetailCtrl (
  $scope,
  $rootScope,
  $uiRouterGlobals,
  BAS_CALL_HISTORY,
  BasCallHistory,
  BasUtilities,
  Swiper
) {
  const callHistoryDetail = this

  let _swiper
  let _listeners = []

  const basCallHistory = BasCallHistory.get()

  callHistoryDetail.images = []

  this.uiOnParamsChanged = uiOnParamsChanged
  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    if (Object.values(basCallHistory.formattedUiCallHistory).length) {
      syncCurrentEntry()
    }

    _listeners.push(
      $rootScope.$on(
        BAS_CALL_HISTORY.EVT_CALL_HISTORY_UPDATED,
        syncCurrentEntry
      )
    )
  }

  function _initSwiper () {

    clearSwiper()

    if (_swiper == null) {
      const _config = {}
      _config.pagination = {
        el: '.swiper-pagination',
        renderBullet: _renderBullet
      }

      _swiper = new Swiper(
        '.callhistory-detail-images',
        _config
      )
    }

    // Reset translate
    _swiper.setTranslate(0)

    function _renderBullet (_index, className) {

      return '<span class="' + className + ' bas-page-bullet">' +
        '<div class="bas-page-bullet-color"></div>' +
        '</span>'
    }
  }

  function _onDestroy () {
    BasUtil.executeArray(_listeners)
    _listeners = []
    clearSwiper()
  }
  function clearSwiper () {

    if (_swiper) _swiper.destroy(true, true)
    _swiper = null
  }

  function syncCurrentEntry () {
    const params = $uiRouterGlobals.params

    callHistoryDetail.currentEntry =
      basCallHistory.uiCallHistory[`${params.contactUuid}-${params.timeStamp}`]

    if (Object.keys(callHistoryDetail.currentEntry)) {

      BasCallHistory.getCallDetails(
        callHistoryDetail.currentEntry.contact.uuid,
        callHistoryDetail.currentEntry.timestamp,
        0,
        4
      ).then(result => {
        callHistoryDetail.images = result.images
        $rootScope.$applyAsync()
        BasUtilities.waitForFrames(2, _initSwiper)
      })
    }
  }

  function uiOnParamsChanged () {

    clearSwiper()
    syncCurrentEntry()
  }
}
