'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLibraryBody', [
    'Logger',
    basLibraryBodyFactory
  ])

/**
 * @param Logger
 * @returns BasLibraryBody
 */
function basLibraryBodyFactory (
  Logger
) {
  var className = 'Bas Library Body'

  /**
   * @constructor
   */
  function BasLibraryBody () {

    /**
     * @instance
     * @type {number}
     */
    this.favouriteWatchType = -1

    /**
     * @instance
     * @type {Object}
     */
    this.control = {}

    /**
     * @instance
     * @type {Object}
     */
    this.class = {}
  }

  /**
   * @constant {string}
   */
  BasLibraryBody.SELECT_TAB = 'selectTab'

  /**
   * @constant {string}
   */
  BasLibraryBody.FUNC_RESET_SCROLL_POS = 'resetScrollPos'

  /**
   * @constant {string}
   */
  BasLibraryBody.FUNC_SET_SCROLL_POS = 'setScrollPos'

  /**
   * @constant {string}
   */
  BasLibraryBody.FUNC_GET_SCROLL_PERCENT = 'getScrollPercentage'

  /**
   * @constant {string}
   */
  BasLibraryBody.FUNC_IS_PAGE_FILLED = 'isPageFilled'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLK_ELEMENT = 'clickElement'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLK_PLAY = 'clickPlay'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLK_REMOVE = 'clickRemove'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLK_SELECT = 'clickSelect'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLK_EDIT = 'clickEdit'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLK_SHARE = 'clickShare'

  /**
   * @constant {string}
   */
  BasLibraryBody.CHN_TITLE = 'changeTitle'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLK_FAVOURITE = 'clickFavourite'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLK_CONTEXT = 'clickContext'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLK_BROWSE = 'clickBrowse'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLASS_SCROLLING = 'bas-library-body-scrolling'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLASS_LOCK_SCROLLING = 'bas-library-body-lock-scrolling'

  /**
   * @constant {string}
   */
  BasLibraryBody.ACTION_END_REACHED = 'endReached'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLASS_ADD = 'bas-library-body-add'

  /**
   * @constant {string}
   */
  BasLibraryBody.CLASS_REMOVE = 'bas-library-body-remove'

  // Static methods

  /**
   * @param {string} cssClass
   * @returns {boolean}
   */
  BasLibraryBody.checkCSSClass = function (cssClass) {
    return (
      cssClass === BasLibraryBody.CLASS_ADD ||
      cssClass === BasLibraryBody.CLASS_REMOVE ||
      cssClass === BasLibraryBody.CLASS_LOCK_SCROLLING ||
      cssClass === BasLibraryBody.CLASS_SCROLLING
    )
  }

  // Instance methods

  /**
   * Function that gets called when the end is reached while scrolling
   *
   * @returns {(Promise|undefined)}
   */
  BasLibraryBody.prototype.endReached = function () {

    return this.executeControl(BasLibraryBody.ACTION_END_REACHED)
  }

  /**
   * Set type of favourite that is being watched
   *
   * @param {number} value
   */
  BasLibraryBody.prototype.setFavouriteWatchType = function (value) {

    if (BasUtil.isPNumber(value, true)) {
      this.favouriteWatchType = value
    } else {
      this.favouriteWatchType = -1
    }
  }

  /**
   * Set CSS class
   *
   * @param {string} cssClass
   * @param {boolean} [value]
   */
  BasLibraryBody.prototype.setClass = function (cssClass, value) {

    // Check input
    if (BasLibraryBody.checkCSSClass(cssClass)) {

      // Check optional value
      if (typeof value === 'boolean') {
        this.class[cssClass] = value
      } else {
        this.class[cssClass] = true
      }

    } else {
      Logger.warn(className + ' - setClass - Invalid CSS class', cssClass)
    }
  }

  /**
   * Remove a certain CSS class
   *
   * @param {string} cssClass
   */
  BasLibraryBody.prototype.removeClass = function (cssClass) {

    // Check input
    if (BasLibraryBody.checkCSSClass(cssClass)) {
      this.setClass(cssClass, false)
    } else {
      Logger.warn(className + ' - removeClass - Invalid CSS class', cssClass)
    }
  }

  /**
   * Execute a certain control method with an optional parameter
   *
   * @param {string} method
   * @param {*} [argument]
   * @returns {(Promise|undefined)}
   */
  BasLibraryBody.prototype.executeControl = function (method, argument) {

    // Check control method
    if (BasUtil.isObject(this.control) &&
      typeof this.control[method] === 'function') {

      // Execute function
      return this.control[method](argument)

    } else {

      Logger.warn(className + ' - Invalid control method', method, argument)

      return Promise.reject('Invalid control method')
    }
  }

  return BasLibraryBody
}
