'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('radioLibraryCtrl', [
    '$scope',
    'BAS_APP_PROFILE',
    'LibraryState',
    'RadioManager',
    'BasCurrentAppProfile',
    'BasLibraryHeader',
    'BasLibraryPage',
    radioLibraryCtrl
  ])

/**
 * @param $scope
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param {LibraryState} LibraryState
 * @param RadioManager
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param BasLibraryHeader
 * @param BasLibraryPage
 */
function radioLibraryCtrl (
  $scope,
  BAS_APP_PROFILE,
  LibraryState,
  RadioManager,
  BasCurrentAppProfile,
  BasLibraryHeader,
  BasLibraryPage
) {
  var root = this

  /**
   * @type {?PlayerLibraryState}
   */
  var libraryState = LibraryState.getCurrentState()

  root.BasLibraryHeader = BasLibraryHeader
  root.BasLibraryPage = BasLibraryPage
  root.historyType = BAS_APP_PROFILE.K_RADIO

  /**
   * @type {BasCurrentAppProfileState}
   */
  root.profileSettings = BasCurrentAppProfile.get()

  /**
   * @type {RadioManager}
   */
  root.manager = libraryState.radioManager

  root.searchQuery = ''

  init()

  function init () {

    $scope.$on('$destroy', cleanup)

    if (!BasUtil.isObject(root.manager)) {

      root.manager = new RadioManager(libraryState)
      libraryState.radioManager = root.manager
    }

    // Make sure radio manager is resumed
    root.manager.resume()
  }

  function cleanup () {

    // Suspend the radio manager
    root.manager.suspend()
  }
}
