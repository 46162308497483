'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('messageModalCtrl', [
    '$rootScope',
    '$scope',
    'BAS_SPLASH',
    'BasMessages',
    'messageUuid',
    'close',
    messageModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasMessages} BasMessages
 * @param {string} messageUuid
 * @param close
 */
function messageModalCtrl (
  $rootScope,
  $scope,
  BAS_SPLASH,
  BasMessages,
  messageUuid,
  close
) {
  var modal = this

  var _listeners = []

  modal.close = close

  init()

  function init () {

    modal.message = BasMessages.getMessage(messageUuid)

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
