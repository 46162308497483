'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('CoverartHelper', [
    CoverartHelper
  ])

/**
 * @constructor
 */
function CoverartHelper () {

  /**
   * @typedef {Object} TCoverArtResult
   * @property {string} thumbnail
   * @property {string} coverArt
   */

  var K_ORIG = 'orig'
  var K_IMGW = 'imgw'

  var MIN_THUMBNAIL_SIZE = 100
  var MIN_PREFERRED_COVERART_SIZE = 250
  var MAX_COVERART_SIZE = 640

  this.parseCoverArtImages = parseCoverArtImages

  /**
   * @param {Object} coverArtImages
   * @returns {TCoverArtResult}
   */
  function parseCoverArtImages (coverArtImages) {

    var keys, result, i, length, width, largestWidth, largestWidthKey
    var preferredWidthKey

    result = {
      coverArt: '',
      thumbnail: ''
    }

    largestWidth = -1

    if (coverArtImages) {

      keys = Object.keys(coverArtImages)

      length = keys.length
      for (i = 0; i < length; i++) {

        width = _getWidth(keys[i])

        if (BasUtil.isPNumber(width)) {

          if (
            !BasUtil.isNEString(result.thumbnail) &&
            width >= MIN_THUMBNAIL_SIZE
          ) {

            result.thumbnail = coverArtImages[keys[i]]
          }

          if (
            largestWidth < width &&
            width <= MAX_COVERART_SIZE
          ) {

            largestWidth = width
            largestWidthKey = keys[i]

            if (width > MIN_PREFERRED_COVERART_SIZE) {

              preferredWidthKey = largestWidthKey
            }
          }
        }
      }

      if (BasUtil.isNEString(preferredWidthKey)) {

        result.coverArt = coverArtImages[preferredWidthKey]

      } else if (BasUtil.isNEString(coverArtImages[K_ORIG])) {

        result.coverArt = coverArtImages[K_ORIG]

      } else if (BasUtil.isNEString(largestWidthKey)) {

        result.coverArt = largestWidthKey
      }
    }

    return result
  }

  /**
   *
   * @param {string} key
   * @returns {number}
   * @private
   */
  function _getWidth (key) {

    var widthStr, width

    if (BasUtil.isString(key)) {

      if (BasUtil.startsWith(key, K_IMGW)) {

        widthStr = key.slice(K_IMGW.length)

        width = parseInt(widthStr)

        if (BasUtil.isNumber(width)) return width
      }
    }

    return -1
  }
}
