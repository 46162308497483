'use strict'

angular
  .module('basalteApp')
  .directive('deviceDebugSetting', deviceDebugSetting)

function deviceDebugSetting () {

  return {
    restrict: 'AE',
    template:
      '<div class="' +
      'bbs bbs-item bbs-centered' +
      ' bbs-flex bbs-active-select"' +
      ' bas-click="debugSetting.toggleDebug()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      ' data-translate="debug"></div>' +
      '  <bas-toggle class="bbs-toggle bas-toggle-dark"' +
      ' data-bas-toggle="debugSetting.toggleDebug()"' +
      ' data-bas-toggled="debugSetting.debug"></bas-toggle>' +
      '</div>',
    controller: [
      'BasPreferences',
      controller
    ],
    controllerAs: 'debugSetting'
  }

  /**
   * @param {BasPreferences} BasPreferences
   */
  function controller (BasPreferences) {

    var debugSetting = this

    debugSetting.debug = BasPreferences.getDebug()

    debugSetting.toggleDebug = toggleDebug

    function toggleDebug () {

      debugSetting.debug = !debugSetting.debug
      BasPreferences.setDebug(debugSetting.debug)
    }
  }
}
