'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceMicrophoneGain', deviceMicrophoneGain)

function deviceMicrophoneGain () {

  return {
    restrict: 'AE',
    template: '<div class="bbs-name" data-translate="microphone_gain">' +
      '</div>' +
      '<div class="bbs-slider-wrapper">' +
      '<bas-slider class="bbs-slider bas-slider-dark"' +
      ' data-slider-model-object="microphoneGain"' +
      ' data-key="microphoneGainUi"' +
      ' data-min="microphoneGain.microphoneGainMin"' +
      ' data-max="microphoneGain.microphoneGainMax"' +
      ' data-slider-change="microphoneGain.microphoneGainChange()" ' +
      ' data-slider-changed="microphoneGain.microphoneGainChange()">' +
      '</bas-slider>' +
      '<div class="bbs-slider-value"' +
      ' ng-bind="microphoneGain.microphoneGainUi"></div>' +
      '<div class="bbs-slider-unit">dB</div>' +
      '</div>',
    controller: [
      'BAS_PREFERENCES',
      'BasPreferences',
      controller
    ],
    controllerAs: 'microphoneGain'
  }

  /**
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BasPreferences} BasPreferences
   */
  function controller (
    BAS_PREFERENCES,
    BasPreferences
  ) {
    var microphoneGain = this

    microphoneGain.microphoneGainMax = BAS_PREFERENCES.GAIN_MAX

    microphoneGain.microphoneGainMin = BAS_PREFERENCES.GAIN_MIN

    microphoneGain.microphoneGainChange = microphoneGainChange

    initUiValue()

    /**
     * Set ui value for gain.
     * Used for keeping the current set gain while changing the limits
     * of the slider.
     */
    function initUiValue () {

      microphoneGain.microphoneGain = BasPreferences.getMicrophoneGain()

      microphoneGain.microphoneGainUi = BasUtil.getCorrectedValue(
        microphoneGain.microphoneGain,
        {
          min: microphoneGain.microphoneGainMin,
          max: microphoneGain.microphoneGainMax,
          step: 1
        }
      )
    }

    function microphoneGainChange () {

      BasPreferences.setMicrophoneGain(microphoneGain.microphoneGainUi)
    }
  }
}
