'use strict'

angular
  .module('basalteApp')
  .directive('deviceDsp', [
    'BAS_HTML',
    deviceDspDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns deviceDsp
 */
function deviceDspDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.dspDeviceSettings,
    controller: [
      'AppLink',
      'BAS_MODAL',
      'BasModal',
      controller
    ],
    controllerAs: 'dsp'
  }

  /**
   * @param {AppLink} AppLink
   * @param {BAS_MODAL} BAS_MODAL
   * @param {BasModal} BasModal
   */
  function controller (
    AppLink,
    BAS_MODAL,
    BasModal
  ) {
    var dsp = this

    dsp.goToDsp = goToDsp

    function goToDsp () {

      BasModal.show(BAS_MODAL.T_DSP_SHORTCUT).then(_onSaveModal)
    }

    function _onSaveModal (modal) {

      return modal.close.then(_onSaveModalClose)
    }

    function _onSaveModalClose (result) {

      if (result === BAS_MODAL.C_YES) AppLink.openDspPage()
    }
  }
}
