'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasPreferences', [
    '$rootScope',
    '$translate',
    'BAS_API',
    'BAS_LANGUAGES',
    'BAS_UNITS',
    'BAS_PREFERENCES',
    'BAS_WEATHER',
    'BasDevicePreferences',
    'BasProfilePreferences',
    'BasStatusBar',
    'BasInsomnia',
    'BasScreen',
    'BasDeviceSip',
    'BasAppDevice',
    'BasUtilities',
    BasPreferences
  ])

/**
 * @typedef {Object} TPreferenceState
 * @property {boolean} showBackground
 * @property {boolean} showStatusBar
 * @property {Object<string, boolean>} css
 */

/**
 * @typedef {Object} TPrefStartUpView
 * @property {number} view
 * @property {string} room
 */

/**
 * Manage App/User preferences
 *
 * Dependencies should be kept to a minimum to avoid circular dependencies.
 * For some settings, use the BasPreferencesHelper service instead.
 *
 * @constructor
 * @param $rootScope
 * @param $translate
 * @param BAS_API
 * @param {BAS_LANGUAGES} BAS_LANGUAGES
 * @param {BAS_UNITS} BAS_UNITS
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BAS_WEATHER} BAS_WEATHER
 * @param {BasDevicePreferences} BasDevicePreferences
 * @param {BasProfilePreferences} BasProfilePreferences
 * @param {BasStatusBar} BasStatusBar
 * @param {BasInsomnia} BasInsomnia
 * @param {BasScreen} BasScreen
 * @param {BasDeviceSip} BasDeviceSip
 * @param {BasAppDevice} BasAppDevice
 * @param {BasUtilities} BasUtilities
 */
function BasPreferences (
  $rootScope,
  $translate,
  BAS_API,
  BAS_LANGUAGES,
  BAS_UNITS,
  BAS_PREFERENCES,
  BAS_WEATHER,
  BasDevicePreferences,
  BasProfilePreferences,
  BasStatusBar,
  BasInsomnia,
  BasScreen,
  BasDeviceSip,
  BasAppDevice,
  BasUtilities
) {
  var CSS_SHOW_BACKGROUND_ENABLED = 'bas-pref-show-background-enabled'
  var CSS_SHOW_BACKGROUND_DISABLED = 'bas-pref-show-background-disabled'
  var CSS_SHOW_STATUSBAR_ENABLED = 'bas-pref-show-statusbar-enabled'
  var CSS_SHOW_STATUSBAR_DISABLED = 'bas-pref-show-statusbar-disabled'

  /**
   * @type {TPreferenceState}
   */
  var state = {}
  state.css = {}

  this.getProfileSettings = getProfileSettings
  this.clearProfileSettings = clearProfileSettings

  this.get = get
  this.getLanguage = getLanguage
  this.getKeepAlive = getKeepAlive
  this.getShowStatusBar = getShowStatusBar
  this.getDebug = getDebug
  this.getOnlyWebRTC = getOnlyWebRTC
  this.getWebRTCOnlyRelay = getWebRTCOnlyRelay
  this.getPauseDisconnectTimeoutMs = getPauseDisconnectTimeoutMs
  this.getEnableNotifications = getEnableNotifications
  this.getTemperatureUnit = getTemperatureUnit
  this.getSleepTime = getSleepTime
  this.getBrightness = getBrightness
  this.getVolume = getVolume
  this.getMute = getMute
  this.getPlaybackGain = getPlaybackGain
  this.getMicrophoneGain = getMicrophoneGain
  this.getEchoCancellation = getEchoCancellation
  this.getNetworkSetting = getNetworkSetting
  this.getShowBackground = getShowBackground
  this.getDefaultWeather = getDefaultWeather
  this.getProximitySensitivity = getProximitySensitivity
  this.getDefaultView = getDefaultView
  this.getStartupView = getStartupView
  this.getTimeFormat = getTimeFormat
  this.getStartPageWidget = getStartPageWidget
  this.getCloudEnvironment = getCloudEnvironment
  this.getScreenOrientation = getScreenOrientation

  this.setLanguage = setLanguage
  this.setKeepAlive = setKeepAlive
  this.setShowStatusBar = setShowStatusBar
  this.setDebug = setDebug
  this.setOnlyWebRTC = setOnlyWebRTC
  this.setWebRTCOnlyRelay = setWebRTCOnlyRelay
  this.setPauseDisconnectTimeoutMs = setPauseDisconnectTimeoutMs
  this.setEnableNotifications = setEnableNotifications
  this.setTemperatureUnit = setTemperatureUnit
  this.setSleepTime = setSleepTime
  this.setBrightness = setBrightness
  this.setVolume = setVolume
  this.setMute = setMute
  this.setPlaybackGain = setPlaybackGain
  this.setMicrophoneGain = setMicrophoneGain
  this.setEchoCancellation = setEchoCancellation
  this.setNetworkSetting = setNetworkSetting
  this.setShowBackground = setShowBackground
  this.setDefaultWeather = setDefaultWeather
  this.setProximitySensitivity = setProximitySensitivity
  this.setDefaultView = setDefaultView
  this.setTimeFormat = setTimeFormat
  this.setStartupView = setStartupView
  this.setStartPageWidget = setStartPageWidget
  this.setCloudEnvironment = setCloudEnvironment
  this.setScreenOrientation = setScreenOrientation

  this.applySleepTime = applySleepTime
  this.applyBrightness = applyBrightness
  this.applyProximitySensitivity = applyProximitySensitivity

  init()

  function init () {

    _syncState()
    _getDeviceSettings()
  }

  // region Getters

  /**
   * @returns {TPreferenceState}
   */
  function get () {

    return state
  }

  /**
   * @returns {string}
   */
  function getLanguage () {

    var lang = BasDevicePreferences.getLanguage()

    return BasUtilities.isKLanguage(lang)
      ? lang
      : BasAppDevice.isCoreClient()
        ? BAS_LANGUAGES.DEF_LANGUAGE
        : BAS_PREFERENCES.DEF_LANGUAGE
  }

  /**
   * @returns {boolean}
   */
  function getKeepAlive () {

    return BasDevicePreferences.getKeepAlive() === true
  }

  /**
   * @returns {boolean}
   */
  function getShowStatusBar () {

    const showStatusBar = BasDevicePreferences.getShowStatusBar()

    if (BasUtil.isBool(showStatusBar)) return showStatusBar

    return true
  }

  /**
   * @returns {boolean}
   */
  function getDebug () {

    return BasDevicePreferences.getDebug() === true
  }

  /**
   * @returns {boolean}
   */
  function getOnlyWebRTC () {

    return BasDevicePreferences.getOnlyWebRTC() === true
  }

  /**
   * @returns {boolean}
   */
  function getWebRTCOnlyRelay () {

    return BasDevicePreferences.getWebRTCOnlyRelay() === true
  }

  /**
   * @returns {number}
   */
  function getPauseDisconnectTimeoutMs () {

    var value

    value = BasDevicePreferences.getPauseDisconnectTimeoutMs()

    return BasUtil.isPNumber(value, true)
      ? value
      : BAS_PREFERENCES.DEF_PAUSE_DISCONNECT_TIMEOUT_MS
  }

  /**
   * @returns {boolean}
   */
  function getEnableNotifications () {

    var value

    value = BasDevicePreferences.getEnableNotifications()

    return BasUtil.isBool(value)
      ? value
      : BAS_PREFERENCES.DEF_ENABLE_NOTIFICATIONS
  }

  /**
   * @returns {string}
   */
  function getTemperatureUnit () {

    var unit = BasDevicePreferences.getTemperatureUnit()

    return BAS_API.CONSTANTS.isTemperatureUnit(unit)
      ? unit
      : BAS_PREFERENCES.DEF_TEMP_UNIT
  }

  /**
   * @returns {number}
   */
  function getSleepTime () {

    var time = BasDevicePreferences.getSleepTime()

    return BasUtil.isVNumber(time)
      ? BasAppDevice.hasOLEDScreen()
        ? time > BAS_PREFERENCES.SLEEP_120 ? BAS_PREFERENCES.SLEEP_120 : time
        : time
      : BasAppDevice.hasOLEDScreen()
        ? BAS_PREFERENCES.SLEEP_60
        : BAS_PREFERENCES.SLEEP_120
  }

  /**
   * @returns {number}
   */
  function getBrightness () {

    var brightness = BasDevicePreferences.getBrightness()

    if (BasUtil.isVNumber(brightness)) return brightness

    if (BasAppDevice.isCoreClient()) {

      return BasAppDevice.hasOLEDScreen() ? 60 : 100
    }
    return -1
  }

  /**
   * @returns {number}
   */
  function getVolume () {

    var volume = BasDevicePreferences.getVolume()

    return BasUtil.isPNumber(volume, true) ? volume : -1
  }

  /**
   * @returns {boolean}
   */
  function getMute () {

    var mute = BasDevicePreferences.getMute()

    return BasUtil.isBool(mute) ? mute : false
  }

  /**
   * @returns {number}
   */
  function getPlaybackGain () {

    var gain = BasDevicePreferences.getPlaybackGain()

    return BasUtil.isVNumber(gain) ? gain : 0
  }

  /**
   * @returns {number}
   */
  function getMicrophoneGain () {

    var gain = BasDevicePreferences.getMicrophoneGain()

    return BasUtil.isVNumber(gain) ? gain : 0
  }

  /**
   * @returns {boolean}
   */
  function getEchoCancellation () {

    return BasDevicePreferences.getEchoCancellation() === true
  }

  /**
   * @returns {Object}
   * @property {string} ip
   * @property {string} subnet
   * @property {string} gateway
   * @property {string} dns
   * @property {boolean} dhcp
   */
  function getNetworkSetting () {

    var network = BasDevicePreferences.getNetwork()

    return BasUtil.isObject(network)
      ? network
      : {
          ip: '',
          subnet: '',
          gateway: '',
          dns: '',
          dhcp: true
        }
  }

  /**
   * @returns {boolean}
   */
  function getShowBackground () {

    var deviceBg

    deviceBg = BasProfilePreferences.getShowBackground()

    // Check profile first
    if (BasUtil.isBool(deviceBg)) return deviceBg

    deviceBg = BasDevicePreferences.getShowBackground()

    // Check device
    if (BasUtil.isBool(deviceBg)) return deviceBg

    // Default true except for ellie builds
    return !BasAppDevice.isEllie()
  }

  /**
   * @returns {string}
   */
  function getDefaultWeather () {

    var defaultWeather

    defaultWeather = BasProfilePreferences.getDefaultWeather()

    if (BasUtil.isString(defaultWeather)) return defaultWeather

    return BAS_WEATHER.DEF_WEATHER
  }

  /**
   * @returns {number}
   */
  function getProximitySensitivity () {

    var sensitivity = BasDevicePreferences.getProximitySensitivity()

    return BasUtil.isVNumber(sensitivity)
      ? sensitivity
      : BAS_PREFERENCES.PROXIMITY_MEDIUM
  }

  /**
   * @returns {number}
   */
  function getDefaultView () {

    var view = BasDevicePreferences.getDefaultView()

    return BasUtil.isVNumber(view)
      ? view
      : BAS_PREFERENCES.VIEW_LAST_VIEW
  }

  /**
   * @returns {TPrefStartUpView}
   */
  function getStartupView () {

    var view = BasProfilePreferences.getStartupView()
    return BasUtil.isObject(view) && BasUtil.isVNumber(view.view)
      ? view
      : {
          view: BasAppDevice.isBrowser()
            ? BAS_PREFERENCES.VIEW_LAST_ROOM
            : BAS_PREFERENCES.VIEW_HOME
        }
  }

  /**
   * @returns {string}
   */
  function getTimeFormat () {

    var format, deviceHasLocale

    format = BasDevicePreferences.getTimeFormat()
    deviceHasLocale = !BasAppDevice.isCoreClient()

    if (BasUtil.isNEString(format)) {

      if (
        format !== BAS_PREFERENCES.TIME_FORMAT_LOCALE_DEFAULT ||
        deviceHasLocale
      ) {
        return format
      }
    }

    // Return default value, based on if device has system locale
    return deviceHasLocale
      ? BAS_PREFERENCES.TIME_FORMAT_LOCALE_DEFAULT
      : BAS_PREFERENCES.TIME_FORMAT_24h
  }

  /**
   * @returns {number}
   */
  function getStartPageWidget () {

    var startPageWidget = BasDevicePreferences.getStartPageWidget()
    return BasUtil.isNumber(startPageWidget)
      ? startPageWidget
      : BAS_PREFERENCES.START_PAGE_WIDGET_TIME
  }

  /**
   * @returns {number}
   */
  function getCloudEnvironment () {

    const cloudEnvironment = BasDevicePreferences.getCloudEnvironment()
    return BasUtil.isNumber(cloudEnvironment)
      ? cloudEnvironment
      : BAS_PREFERENCES.CLOUD_ENVIRONMENT_AUTO
  }

  /**
   * @returns {number}
   */
  function getScreenOrientation () {

    const screenOrientation = BasDevicePreferences.getScreenOrientation()
    return BasUtil.isPNumber(screenOrientation, true)
      ? screenOrientation
      : -1
  }

  // endregion

  // region Setters

  function setLanguage (value) {

    if (BasUtilities.isKLanguage(value)) {

      BasDevicePreferences.setLanguage(value)
      $translate.use(value)

    } else {

      if (BasAppDevice.isEllie()) {

        BasDevicePreferences.setLanguage(BAS_LANGUAGES.DEF_LANGUAGE)
        $translate.use(BAS_LANGUAGES.DEF_LANGUAGE)

      } else {

        BasDevicePreferences.setLanguage(BAS_PREFERENCES.DEF_LANGUAGE)
        $translate.use(BasUtilities.getSystemLanguage())
      }
    }
  }

  function setKeepAlive (value) {

    if (BasUtil.isBool(value)) {

      BasDevicePreferences.setKeepAlive(value)

      if (value) {

        BasInsomnia.keepAwake()

      } else {

        BasInsomnia.allowSleepAgain()
      }
    }
  }

  function setShowStatusBar (value) {

    if (BasUtil.isBool(value)) {

      BasDevicePreferences.setShowStatusBar(value)

      if (value) {

        BasStatusBar.show()

      } else {

        BasStatusBar.hide()
      }
    }

    _syncState()
  }

  function setDebug (value) {

    if (BasUtil.isBool(value)) {

      BasDevicePreferences.setDebug(value)

      BasAppDevice.toggleDebug(value)
    }
  }

  function setOnlyWebRTC (value) {

    if (BasUtil.isBool(value)) {

      BasDevicePreferences.setOnlyWebRTC(value)

      BasAppDevice.toggleOnlyWebRTC(value)
    }
  }

  function setWebRTCOnlyRelay (value) {

    if (BasUtil.isBool(value)) {

      BasDevicePreferences.setWebRTCOnlyRelay(value)

      BasAppDevice.toggleWebRTCOnlyRelay(value)
    }
  }

  function setPauseDisconnectTimeoutMs (value) {

    if (BasUtil.isPNumber(value, true)) {

      BasDevicePreferences.setPauseDisconnectTimeoutMs(value)

      BasAppDevice.setPauseDisconnectTimeoutMs(value)
    }
  }

  function setEnableNotifications (value) {

    if (BasUtil.isBool(value)) {

      BasDevicePreferences.setEnableNotifications(value)

      $rootScope.$emit(
        BAS_PREFERENCES.EVT_ENABLE_NOTIFICATIONS_CHANGED,
        value
      )
    }
  }

  function setTemperatureUnit (value) {

    var _value, oldTemperatureUnit

    oldTemperatureUnit = getTemperatureUnit()

    _value = BAS_API.CONSTANTS.isTemperatureUnit(value)
      ? value
      : BAS_PREFERENCES.DEF_TEMP_UNIT

    BasDevicePreferences.setTemperatureUnit(_value)

    if (oldTemperatureUnit !== _value) {

      _emitTemperatureUnitChanged()
    }
  }

  function setSleepTime (value) {

    if (BasUtil.isPNumber(value)) {

      BasDevicePreferences.setSleepTime(value)
      BasScreen.setDisplayTimeout(value)
    }
  }

  function setBrightness (value) {

    if (BasUtil.isVNumber(value)) {

      BasDevicePreferences.setBrightness(value)
      BasScreen.setAutoBrightness(value)
    }
  }

  function setVolume (value) {

    if (BasUtil.isVNumber(value)) {

      BasDevicePreferences.setVolume(value)
    }
  }

  function setMute (value) {

    if (BasUtil.isBool(value)) {

      BasDevicePreferences.setMute(value)
    }
  }

  function setPlaybackGain (value) {

    if (BasUtil.isVNumber(value)) {

      BasDevicePreferences.setPlaybackGain(value)
      BasDeviceSip.setDeviceParams(
        {
          playbackGain: value
        }
      )
    }
  }

  function setMicrophoneGain (value) {

    if (BasUtil.isVNumber(value)) {

      BasDevicePreferences.setMicrophoneGain(value)
      BasDeviceSip.setDeviceParams(
        {
          microphoneGain: value
        }
      )
    }
  }

  function setEchoCancellation (value) {

    if (BasUtil.isBool(value)) {

      BasDevicePreferences.setEchoCancellation(value)
      BasDeviceSip.setDeviceParams(
        {
          echoCancellation: value
        }
      )
    }
  }

  /**
   * @param {string} ip
   * @param {string} subnet
   * @param {string} gateway
   * @param {string} dns
   * @param {boolean} dhcp
   */
  function setNetworkSetting (
    ip,
    subnet,
    gateway,
    dns,
    dhcp
  ) {
    var network

    if (BasUtil.isString(ip) &&
      BasUtil.isString(subnet) &&
      BasUtil.isString(gateway) &&
      BasUtil.isString(dns) &&
      BasUtil.isBool(dhcp)) {

      network = {}
      network.ip = ip
      network.subnet = subnet
      network.gateway = gateway
      network.dns = dns
      network.dhcp = dhcp

      BasDevicePreferences.setNetwork(network)
    }
  }

  /**
   * @param {boolean} value
   * @param {BasCoreContainer} [core]
   */
  function setShowBackground (value, core) {

    if (BasUtil.isBool(value)) {

      if (core && core.hasCredentials && core.hasCredentials()) {

        BasProfilePreferences.setShowBackground(value, core)

      } else {

        BasDevicePreferences.setShowBackground(value)
      }
    }

    _syncState()
  }

  /**
   * @param {string} value
   * @param {BasCoreContainer} [core]
   */
  function setDefaultWeather (value, core) {

    if (BasUtil.isString(value)) {

      if (core && core.hasCredentials && core.hasCredentials()) {

        BasProfilePreferences.setDefaultWeather(value, core)
      }
    }

    _syncState()
  }

  function setProximitySensitivity (value) {

    if (BasUtil.isVNumber(value)) {

      BasDevicePreferences.setProximitySensitivity(value)
      applyProximitySensitivity()
    }
  }

  function setDefaultView (value) {

    if (BasUtil.isVNumber(value)) {

      BasDevicePreferences.setDefaultView(value)
    }
  }

  /**
   * @param {TStartupPreference} value
   * @param {BasCoreContainer} [core]
   */
  function setStartupView (value, core) {

    if (BasUtil.isObject(value) &&
      BasUtil.isVNumber(value.view)) {

      BasProfilePreferences.setStartupView(
        {
          view: value.view,
          room: value.room
        },
        core
      )
    }
  }

  function setTimeFormat (value) {

    var oldValue

    oldValue = BasDevicePreferences.getTimeFormat()

    if (BasUtil.isNEString(value)) {

      BasDevicePreferences.setTimeFormat(value)

      if (oldValue !== value) {

        _emitTimeFormatChanged()
      }
    }
  }

  function setStartPageWidget (value) {

    if (BasUtil.isNumber(value)) {

      BasDevicePreferences.setStartPageWidget(value)
    }
  }

  function setCloudEnvironment (value) {

    if (BasUtil.isNumber(value)) {

      BasDevicePreferences.setCloudEnvironment(value)
    }
  }

  function setScreenOrientation (value) {

    if (BasUtil.isPNumber(value, true)) {

      BasDevicePreferences.setScreenOrientation(value)
      BasScreen.setOrientation(value)
    }
  }

  // endregion

  // region Appliers

  function applySleepTime () {

    BasScreen.setDisplayTimeout(getSleepTime())
  }

  function applyBrightness () {

    BasScreen.setAutoBrightness(getBrightness())
  }

  function applyScreenOrientation () {

    BasScreen.setOrientation(getScreenOrientation())
  }

  function applyProximitySensitivity () {

    switch (getProximitySensitivity()) {

      case BAS_PREFERENCES.PROXIMITY_OFF:
        BasScreen.setProximityThreshold(0)
        break

      case BAS_PREFERENCES.PROXIMITY_NEAR:
        BasScreen.setProximityThreshold(30)
        break

      case BAS_PREFERENCES.PROXIMITY_MEDIUM:
        BasScreen.setProximityThreshold(90)
        break

      case BAS_PREFERENCES.PROXIMITY_FAR:
        BasScreen.setProximityThreshold(150)
        break
    }
  }

  // endregion

  function _getDeviceSettings () {

    BasDevicePreferences.syncSettingsFromStorage()
    _applyDeviceSettings()
  }

  /**
   * @param {BasCoreContainer} [core]
   */
  function getProfileSettings (core) {

    BasProfilePreferences.syncSettings(core)
    _applyProfileSettings()
  }

  function clearProfileSettings () {

    BasProfilePreferences.clearSettings()
  }

  function _applyDeviceSettings () {

    var lang

    _syncState()

    // Language
    lang = getLanguage()
    if (BasUtil.isNEString(lang)) {

      $translate.use(lang)

    } else {

      if (BasAppDevice.isCoreClient()) {

        $translate.use(BAS_LANGUAGES.DEF_LANGUAGE)

      } else {

        $translate.use(BasUtilities.getSystemLanguage())
      }
    }

    // Keep alive
    if (getKeepAlive() === true) {

      BasInsomnia.keepAwake()

    } else {

      BasInsomnia.allowSleepAgain()
    }

    // Status bar
    if (BasAppDevice.isCoreClient()) {

      BasStatusBar.hide()

    } else {

      if (getShowStatusBar() === true) {

        BasStatusBar.show()

      } else {

        BasStatusBar.hide()
      }
    }

    // Temperature unit
    _emitTemperatureUnitChanged()

    // Time format
    _emitTimeFormatChanged()

    if (BasAppDevice.isCoreClient()) {

      // Sleep time
      applySleepTime()

      // Sensitivity
      applyProximitySensitivity()

      // Brightness
      applyBrightness()

      // Screen orientation
      applyScreenOrientation()
    }

    // SIP
    if (BasAppDevice.isCoreClient()) {

      BasDeviceSip.setDeviceParams({
        playbackGain: getPlaybackGain(),
        microphoneGain: getMicrophoneGain(),
        echoCancellation: getEchoCancellation()
      })
    }

    // Debug
    if (getDebug() === true) {

      BasAppDevice.toggleDebug(true)
    }
  }

  function _applyProfileSettings () {

    _syncState()
  }

  function _emitTemperatureUnitChanged () {

    $rootScope.$emit(BAS_UNITS.EVT_TEMPERATURE_UNIT_CHANGED)
  }

  function _emitTimeFormatChanged () {

    $rootScope.$emit(BAS_PREFERENCES.EVT_TIME_FORMAT_PREFERENCE_CHANGED)
  }

  /**
   * Sync all state properties
   *
   * @private
   */
  function _syncState () {

    state.showBackground =
      (BasAppDevice.isEllie() || BasAppDevice.isLisa())
        ? false
        : getShowBackground()
    state.showStatusBar =
      BasAppDevice.isAndroid() || BasAppDevice.isIos()
        ? getShowStatusBar()
        : false

    state.css[CSS_SHOW_BACKGROUND_ENABLED] = state.showBackground
    state.css[CSS_SHOW_BACKGROUND_DISABLED] = !state.showBackground
    state.css[CSS_SHOW_STATUSBAR_ENABLED] = state.showStatusBar
    state.css[CSS_SHOW_STATUSBAR_DISABLED] = !state.showStatusBar
  }
}
