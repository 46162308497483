'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basImageSize', basImageSize)

function basImageSize () {

  return {
    restrict: 'A',
    scope: {
      dpi: '<',
      basImageSizeInput: '<',
      basImageSizeType: '@?',
      basImageSizeExtern: '@?',
      basImageSizeClearOnEmptyInput: '<?'
    },
    controller: [
      '$scope',
      '$element',
      '$window',
      '$rootScope',
      'UI_HELPER',
      'BasImageUtil',
      'BasResource',
      controller
    ]
  }

  /**
   * @param $scope
   * @param $element
   * @param $window
   * @param $rootScope
   * @param {UI_HELPER} UI_HELPER
   * @param {BasImageUtil} BasImageUtil
   * @param {BasResource} BasResource
   */
  function controller (
    $scope,
    $element,
    $window,
    $rootScope,
    UI_HELPER,
    BasImageUtil,
    BasResource
  ) {
    var CSS_VALID_IMAGE = 'bas-image--valid'

    var _exists
    var _lastExternalInput
    var _listener

    this.$postLink = _onPostLink

    init()

    function init () {

      _exists = true

      $scope.$on('$destroy', _onDestroy)
    }

    function _onPostLink () {

      $scope.$watch('basImageSizeInput', _onChanges)
      $scope.$watch('basImageSizeType', _onChanges)
      $scope.$watch('basImageSizeExtern', _onChanges)

      _listener = $rootScope.$on(UI_HELPER.EVT_RESIZE, _checkImage)
      _checkImage()
    }

    function _onChanges () {

      _checkImage()
    }

    function _checkImage () {

      var _input, _type, _extern, _elWidth, _finalImage, ratio

      _lastExternalInput = ''

      _input = $scope.basImageSizeInput
      _type = $scope.basImageSizeType
      _type = BasUtil.isNEString(_type) ? _type : 'img'
      _extern = $scope.basImageSizeExtern
      _extern = _extern === 'true'

      if (BasUtil.isObject(_input)) {

        ratio = _getDpi()

        _elWidth = ($element[0] ? $element[0].clientWidth : 0) * ratio

        _finalImage = BasImageUtil.getSizedImage(
          _input,
          _type,
          _elWidth
        )

        if (_finalImage && _extern) {

          _lastExternalInput = _finalImage
          _retrieveImage(_finalImage)

        } else {

          _setBackgroundImage(_finalImage)
        }

      } else if (BasUtil.isNEString(_input)) {

        if (_extern) {

          _lastExternalInput = _input
          _retrieveImage(_input)

        } else {

          _setBackgroundImage(_input)
        }

      } else if ($scope.basImageSizeClearOnEmptyInput === true) {

        _setBackgroundImage('')
      }
    }

    /**
     * @private
     * @param {string} input
     */
    function _retrieveImage (input) {

      BasResource.getImage(input).then(_onImage, _onImageError)

      /**
       * @private
       * @param {string} result
       */
      function _onImage (result) {

        if (_lastExternalInput === input) {

          _setBackgroundImage(result)

          $scope.$applyAsync()
        }
      }

      /**
       * @private
       */
      function _onImageError () {

        if (_lastExternalInput === input) {

          _setBackgroundImage()

          $scope.$applyAsync()
        }
      }
    }

    /**
     * @private
     * @param {string} [image]
     */
    function _setBackgroundImage (image) {

      if (_exists && $element) {

        if (BasUtil.isNEString(image)) {

          $element.css({ 'background-image': 'url(' + image + ')' })
          $element[0].classList.add(CSS_VALID_IMAGE)

        } else {

          $element.css({ 'background-image': 'none' })
          $element[0].classList.remove(CSS_VALID_IMAGE)
        }
      }
    }

    /**
     * @private
     * @returns {number}
     */
    function _getDpi () {

      var _ratio

      if (BasUtil.isPNumber($scope.dpi)) return $scope.dpi

      _ratio = $window.devicePixelRatio

      return BasUtil.isPNumber(_ratio) ? _ratio : 1
    }

    function _onDestroy () {

      _exists = false

      BasUtil.execute(_listener)
      _listener = null
    }
  }
}
