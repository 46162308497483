'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceLanguage', [
    'BAS_HTML',
    deviceLanguage
  ])

function deviceLanguage (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.languageDeviceSettings,
    controller: [
      '$rootScope',
      '$scope',
      'LANGUAGES',
      'BAS_PREFERENCES',
      'BAS_INPUT_MODAL',
      'BasPreferences',
      'BasAppDevice',
      'BasInputModal',
      'BasUtilities',
      controller
    ],
    controllerAs: 'language'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {LANGUAGES} LANGUAGES
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {BasPreferences} BasPreferences
   * @param {BasAppDevice} BasAppDevice
   * @param {BasInputModal} BasInputModal
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    $scope,
    LANGUAGES,
    BAS_PREFERENCES,
    BAS_INPUT_MODAL,
    BasPreferences,
    BasAppDevice,
    BasInputModal,
    BasUtilities
  ) {
    var language = this

    var _listeners = []

    /**
     * @type {TBasModalInputSelectOption[]}
     */
    language.LANGUAGE_OPTIONS = []

    /**
     * @type {string}
     */
    language.language = BasPreferences.getLanguage()

    /**
     * @type {string}
     */
    language.uiLanguage = '-'

    language.showEditLanguage = showEditLanguage

    init()

    function init () {

      $scope.$on('$destroy', _onDestroy)

      _listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        _onLanguageChanged
      ))

      _syncUiLanguage()
      _generateLanguageOptions()
    }

    function _generateLanguageOptions () {
      var length, i, _language

      language.LANGUAGE_OPTIONS = []

      if (!BasAppDevice.isCoreClient()) {
        language.LANGUAGE_OPTIONS.push({
          text: BasUtilities.translate('language_system'),
          value: BAS_PREFERENCES.DEF_LANGUAGE
        })
      }

      length = LANGUAGES.length
      for (i = 0; i < length; i++) {

        _language = LANGUAGES[i]

        language.LANGUAGE_OPTIONS.push({
          value: _language.code,
          text: _language.name
        })
      }
    }

    function updateLanguage () {

      BasPreferences.setLanguage(language.language)
    }

    function showEditLanguage () {

      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'language',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            options: language.LANGUAGE_OPTIONS,
            initialValue: language.language
          }
        ],
        autoAdvance: true,
        allowDismiss: true,
        autoSave: true,
        validCheck: BasUtil.isNEArray
      }).then(onModal)

      function onModal (modal) {

        modal.close.then(function (result) {

          if (BasUtil.isNEArray(result)) {

            language.language = result[0]
            updateLanguage()
            _syncUiLanguage()
          }
        })
      }
    }

    function _syncUiLanguage () {

      var length, i, _language

      if (language.language === BAS_PREFERENCES.DEF_LANGUAGE) {
        if (BasAppDevice.isCoreClient()) {
          language.uiLanguage = LANGUAGES[0].name
        } else {
          language.uiLanguage = BasUtilities.translate('system')
        }
      } else {
        language.uiLanguage = BasUtilities.translate('system')
      }

      length = LANGUAGES.length
      for (i = 0; i < length; i++) {

        _language = LANGUAGES[i]

        if (language.language === _language.code) {

          language.uiLanguage = _language.name
          break
        }
      }
    }

    function _onLanguageChanged () {

      _syncUiLanguage()
      _generateLanguageOptions()
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
