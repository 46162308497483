'use strict'

angular
  .module('basalteApp')
  .controller('lisaCamerasCtrl', [
    '$scope',
    'BasCameras',
    'BasUtilities',
    'Swiper',
    lisaCamerasCtrl
  ])

/**
 * @param $scope
 * @param {BasCameras} BasCameras
 * @param {BasUtilities} BasUtilities
 * @param Swiper
 */
function lisaCamerasCtrl (
  $scope,
  BasCameras,
  BasUtilities,
  Swiper
) {
  var lisa = this

  var _swiper

  /**
   * @type {TCamerasState}
   */
  lisa.cameras = BasCameras.get()

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    BasUtilities.waitForFrames(2, _initSwiper)
  }

  function _initSwiper () {

    var _config

    _config = {}

    _config.pagination = {
      el: '.swiper-pagination',
      renderBullet: _renderBullet
    }

    _swiper = new Swiper(
      '.lisa-cameras',
      _config
    )

    function _renderBullet (_index, className) {
      return '<span class="' + className + ' bas-page-bullet">' +
        '<div class="bas-page-bullet-color"></div>' +
        '</span>'
    }
  }

  function _onDestroy () {

    if (_swiper) _swiper.destroy(true, false)
    _swiper = null
  }
}
