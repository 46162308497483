'use strict'

angular
  .module('basalteApp')
  .constant('BAS_OPEN_CLOSE_DEVICE', {
    EVT_OPEN_CLOSE_DEVICE_UPDATED: 'evtBasOpenCloseDeviceUpdated',
    DEF_CLOSE_UUID: 'closeUuid',
    DEF_STOP_UUID: 'stopUuid',
    DEF_OPEN_UUID: 'openUuid',
    DEF_TRIGGER_UUID: 'triggerUuid'
  })
