'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('lisaThermostatCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_STATE',
    'STATES',
    'BAS_ROOMS',
    'BasAppHeader',
    'BasState',
    'BasUtilities',
    'CurrentRoom',
    'Swiper',
    lisaThermostatCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_STATE} BAS_STATE
 * @param {STATES} STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param BasAppHeader
 * @param {BasState} BasState
 * @param BasUtilities
 * @param {CurrentRoom} CurrentRoom
 * @param Swiper
 */
function lisaThermostatCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_STATE,
  STATES,
  BAS_ROOMS,
  BasAppHeader,
  BasState,
  BasUtilities,
  CurrentRoom,
  Swiper
) {
  var lisa = this

  var _swiper
  var CSS_BTD_SHOW_SETTINGS = 'bas-thermostat-detail-rotate-show-settings'

  var CSS_BTD_SHOW_SETTINGS_TOGGLE =
    'bas-thermostat-detail-rotate-show-settings-toggle'

  /**
   * @type {BasRooms}
   */
  lisa.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  lisa.currentRoom = CurrentRoom.get()

  lisa.css = {}
  lisa.thermostat = BAS_ROOMS.ROOMS.rooms[lisa.currentRoom.roomId]
    .thermostats.thermostats[0]
  lisa.thermostats = BAS_ROOMS.ROOMS.rooms[lisa.currentRoom.roomId]
    .thermostats.thermostats

  lisa.toggleSettings = toggleSettings
  lisa.toggleScheduler = toggleScheduler

  this.uiOnParamsChanged = uiOnParamsChanged

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    // Set initial state
    uiOnParamsChanged($uiRouterGlobals.params)

    _syncToggleButton()
    _setHeaderTitle(lisa?.thermostat?.uiTitle)

    BasUtilities.waitForFrames(2, _initSwiper)
  }

  /**
   *
   * @param {?string} title
   */
  function _setHeaderTitle (title) {
    if (lisa.thermostats.length > 1) {
      BasAppHeader.setTitle(title)
    }
  }

  function _initSwiper () {
    const basRoom = CurrentRoom.getRoom()

    _swiper = new Swiper(
      '.lisa-thermostats',
      {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        touchStartPreventDefault: true,
        touchMoveStopPropagation: true,
        touchStartForcePreventDefault: true,
        preventInteractionOnTransition: true,
        enabled: basRoom && basRoom.room.thermostats.length > 1,
        spaceBetween: 12,
        on: {
          beforeInit: function () {
            _setHeaderTitle(lisa.thermostats[0].uiTitle)
          },
          activeIndexChange: function () {
            _setHeaderTitle(lisa.thermostats[_swiper.activeIndex].uiTitle)
          },
          slideChange: function () {
            lisa.thermostat = BAS_ROOMS.ROOMS.rooms[lisa.currentRoom.roomId]
              .thermostats.thermostats[_swiper.activeIndex]

            $scope.$applyAsync()
          }
        }
      }
    )
  }

  function toggleSettings (force) {
    var view

    if (_hasSetPoint()) {

      view = BasUtil.isBool(force)
        ? force
          ? BAS_STATE.S_LISA_THERMOSTAT_VIEW_SETTINGS
          : ''
        : $uiRouterGlobals.params.view ===
        BAS_STATE.S_LISA_THERMOSTAT_VIEW_SETTINGS
          ? ''
          : BAS_STATE.S_LISA_THERMOSTAT_VIEW_SETTINGS

      BasState.go(
        STATES.LISA_THERMOSTAT,
        {
          view: view
        }
      )
    }
  }

  function toggleScheduler () {

    if (
      lisa.thermostat?.thermostatScheduler?.toggleScheduler) {
      lisa.thermostat.thermostatScheduler.toggleScheduler()
    }
  }

  function uiOnParamsChanged (params) {

    if (params.view === BAS_STATE.S_LISA_THERMOSTAT_VIEW_SLIDER &&
      !_hasSetPoint()) {

      BasState.go(
        STATES.LISA_THERMOSTAT,
        {
          view: BAS_STATE.S_LISA_THERMOSTAT_VIEW_SETTINGS
        }
      )

    } else {

      lisa.css[CSS_BTD_SHOW_SETTINGS] =
        params.view === BAS_STATE.S_LISA_THERMOSTAT_VIEW_SETTINGS
    }

    _setHeaderTitle(lisa?.thermostat?.uiTitle)
  }

  function _syncToggleButton () {

    lisa.css[CSS_BTD_SHOW_SETTINGS_TOGGLE] = _hasSetPoint()
  }

  function _hasSetPoint () {

    return lisa.thermostat?.hasSetPoint?.() ?? false
  }

  function _onDestroy () {

    _swiper?.destroy(true, false)
    _swiper = null
  }
}
