'use strict'

var BasCoreSocket = require('./bas_core_socket')

/**
 * @constructor
 * @extends BasCoreSocket
 * @param {BasRemoteServer} basRemoteServer
 * @param {string} path
 * @since 3.0.0
 */
function BasCoreSocketRtcProxy (basRemoteServer, path) {

  BasCoreSocket.call(this, path)

  /**
   * @private
   * @type {BasRemoteServer}
   */
  this._basRemoteServer = basRemoteServer
}

BasCoreSocketRtcProxy.prototype = Object.create(BasCoreSocket.prototype)
BasCoreSocketRtcProxy.prototype.constructor = BasCoreSocketRtcProxy

/**
 * @returns {boolean}
 */
BasCoreSocketRtcProxy.prototype.isConnected = function () {

  return this._basRemoteServer.isRemoteProxyConnected()
}

/**
 * @param {string} path
 * @param {TCoreCredentials} credentials
 * @returns {Promise}
 */
BasCoreSocketRtcProxy.prototype.openConnection = function (
  // eslint-disable-next-line no-unused-vars
  path,
  // eslint-disable-next-line no-unused-vars
  credentials
) {

  return Promise.resolve()
}

/**
 * @returns {Promise}
 */
BasCoreSocketRtcProxy.prototype.closeConnection = function () {

  return Promise.resolve()
}

/**
 * @param {Object} data
 * @returns {boolean}
 */
BasCoreSocketRtcProxy.prototype.sendData = function (data) {

  return this._basRemoteServer.sendProxyData(data)
}

module.exports = BasCoreSocketRtcProxy
