{
  "12_hr": "12 ore",
  "24_hr": "24 ore",
  "24_hr_time": "Orario 24 ore",
  "about": "Info",
  "account": "Account",
  "account_exists": "Account esistente",
  "account_exists_l1": "Questo account è già esistente.",
  "account_exists_l2": "Prego usare un indirizzo mail diverso oppure fare il login con questo indirizzo mail.",
  "account_not_confirmed": "Account non confermato",
  "account_not_confirmed_l1": "Questo account non è stato ancora confermato.",
  "account_not_confirmed_l2": "Prego controllare la vostra casella mail per una mail di conferma.",
  "account_not_confirmed_l3": "Verificare questo account?",
  "act_in": "In",
  "activate": "Attiva",
  "active": "Attivo",
  "act_out": "Esterno",
  "act_push": "PREMI",
  "adaptive_brightness": "Luminosità adattiva",
  "add": "Aggiungere",
  "add_favourite": "Aggiungi preferito",
  "add_group": "Aggiungi gruppo",
  "add_home": "Aggiungi casa",
  "address": "Indirizzo",
  "add_to_fav": "Aggiungi ai preferiti",
  "add_to_playlist": "Aggiungi alla playlist",
  "add_to_queue": "Aggiungi alla coda",
  "air_conditioning": "Condizionamento d'aria",
  "air_pressure": "Pressione dell'aria",
  "alarm_at": "Allarme con",
  "alarm_missing_txt": "Mancano uno o più campi necessari",
  "alarm_not_selected_l1": "Nessun allarme selezionato",
  "alarm_not_selected_l2": "Prego selezionare un allarme",
  "alarms": "Allarmi",
  "album": "Album",
  "albums": "Album",
  "alert": "Avvertimento",
  "all": "Tutto",
  "all_off": "Spegni tutto",
  "all_off_txt_l1": "Stai per spegnere tutte le stanze.",
  "all_off_txt_l2": "Sei sicuro di volere continuare?",
  "all_on": "Accendi tutto",
  "almost_done": "Hai quasi finito.",
  "almost_done_txt_l1": "Non hai ancora playlist o radio preferite.",
  "almost_done_txt_l2": "Prosegui e aggiungi prima qualche preferito.",
  "already_verified": "Già verificato",
  "alt_power": "Accensione",
  "alt_user": "Utente",
  "application": "Applicazione",
  "application_version": "Versione applicazione",
  "app_update_required": "È necessario un aggiornamento",
  "app_update_required_android_l2": "prego aggiornare la app nel Play Store.",
  "app_update_required_ios_l2": "prego aggiornare la app nell'App Store.",
  "app_update_required_l2": "prego aggiornare la app nello store.",
  "artist": "Artista",
  "artists": "Artisti",
  "attention": "Attenzione!",
  "audio_zones": "Zone audio",
  "author": "Autore",
  "back": "indietro",
  "basalte_account": "Account Basalte",
  "basalte_info_l1": "Con un account Basalte puoi collegarti alla tua casa e controllarla da remoto.",
  "basalte_link_server_l1": "Collegare questa casa al tuo account Basalte?",
  "basalte_link_server_l2": "Sarai in grado di controllare questa casa da remoto.",
  "basalte_unlink_server_l1": "Scollegare questa casa dall'account Basalte?",
  "basalte_unlink_server_l2": "Non sarai più in grado di accedere a questa casa da remoto.",
  "bass": "Basso",
  "BE": "Belgio",
  "beta": "beta",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "Ora puoi collegare il tuo dispositivo via bluetooth.",
  "brightness": "Luminosità",
  "browse": "Naviga",
  "browse_deezer": "Preferiti Deezer",
  "browse_library": "Libreria musicale",
  "browser_app_update_required_l2": "prego ricaricare la pagina o aggiornare il server.",
  "browse_stations": "Stazioni radio",
  "buffering": "buffering",
  "building_building": "Edificio",
  "building_garage": "Garage",
  "building_main_house": "Casa principale",
  "building_pool_house": "Edificio piscina",
  "building_stables": "Stalle",
  "cameras": "Telecamere",
  "cancel": "Annulla",
  "categories": "Categorie",
  "category": "Categoria",
  "celsius": "Celsius",
  "change_account": "Cambia account",
  "change_home": "Cambia casa",
  "change_image": "Cambia immagine",
  "change_password": "Cambia password",
  "change_password_l1": "Stai per cambiare la tua password.",
  "change_user": "Cambia profilo",
  "channel_number": "Numero del canale",
  "charts": "Grafici",
  "check_for_updates": "Verifica aggiornamenti",
  "checking": "verifica in corso",
  "checking_for_updates": "Verifica aggiornametni in corso...",
  "choose_favourite": "Vuoi scegliere un preferito?",
  "choose_home": "Seleziona casa",
  "choose_music": "Vuoi scegliere un preferito?",
  "choose_preset": "Seleziona un preset",
  "choose_source": "Vuoi scegliere una sorgente?",
  "clear": "Svuota",
  "cleared": "Svuotato",
  "clear_queue_txt_l1": "Stai per svuotare la tua coda di esecuzione.",
  "clear_queue_txt_l2": "Sei sicuro di volere continuare?",
  "clear_selection": "Annulla selezione",
  "clear_selection_txt_l1": "Stai per annullare la tua selezione.",
  "clear_selection_txt_l2": "Sei sicuro di volere continuare?",
  "close": "Chiudi",
  "closed": "Chiuso",
  "code": "Codice",
  "color": "Colore",
  "color_temperature": "Temperatura colore",
  "color_theme": "Tema colori",
  "color_white": "Bianco",
  "comfort": "Comfort",
  "configure": "Configura",
  "confirmation_code_sent": "Codice di conferma inviato",
  "confirmation_code_sent_l1": "Un codice di conferma è stato inviato al seguente indirizzo:",
  "confirm_continue": "Sei sicuro di voler continuare?",
  "confirm_overwrite": "Sei sicuro di voler sovrascrivere i dati esistenti?",
  "connect": "Connetti",
  "connected": "Connesso",
  "connecting": "In connessione",
  "connecting_small": "in connessione",
  "connecting_stop_and_retry_text": "Connessione al server non riuscita. Nuovo tentativo tra 10 secondi.",
  "connecting_stop_text": "Connessione al server non riuscita.",
  "connecting_text": "In connessione…",
  "connecting_text_small": "in connessione…",
  "connection_lost": "Nessuna connessione",
  "connection_lost_text": "Riconnessione con il server",
  "connection_unable": "Impossibile connettersi al server specificato.",
  "connect_offline_server_l1": "Questa home è collegata ma sembra offline.",
  "connect_offline_server_l2": "Provo a collegarmi comunque?",
  "connect_server": "Connetti a server",
  "consumption": "Consumo",
  "continue_anyway": "Prosegui comunque",
  "continue_without_basalte_account": "Prosegui senza account Basalte",
  "cover_art_background": "Sfondo per cover art",
  "current_password": "Password attuale",
  "current_temperature": "Corrente",
  "custom": "Personalizzato",
  "date": "Data",
  "day": "Giorno",
  "day_friday": "Venerdì",
  "day_monday": "Lunedì",
  "day_saturday": "Sabato",
  "day_short2_friday": "Ve",
  "day_short2_monday": "Lu",
  "day_short2_saturday": "Sa",
  "day_short2_sunday": "Do",
  "day_short2_thursday": "Gi",
  "day_short2_tuesday": "Ma",
  "day_short2_wednesday": "Me",
  "day_sunday": "Domenica",
  "day_thursday": "Giovedì",
  "day_tuesday": "Martedì",
  "day_wednesday": "Mercoledì",
  "debug": "Debug",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "Stai per disconnettere l'account Deezer da questo player.",
  "deezer_favourite": "Preferito Deezer",
  "deezer_flow": "Flow",
  "deezer_flow_description": "La tua colonna sonora personale",
  "deezer_flow_full": "Deezer Flow",
  "deezer_no_link": "Nessun account Deezer connesso",
  "deezer_oauth_error": "Errore autenticazione Deezer",
  "default_rooms": "Stanze di default",
  "default_rooms_hint": "Assicurati che almeno 1 stanza è selezionata come default per garantire il corretto funzionamento dello streaming.",
  "default_rooms_info_l1": "Stanze di default per stream audio di terze parti (p.e. Spotify Connect)",
  "default_rooms_info_l2": "Quando nessuna stanza è collegata allo stream, attiva le stanze specificate.",
  "default_rooms_spotify_hint": "Assicurati che almeno 1 stanza è selezionata come default per garantire il corretto funzionamento di Spotify Connect.",
  "default_rooms_spotify_warning_l1": "Imposta almeno una stanza di default per ogni stream",
  "default_rooms_spotify_warning_l2": "per completare la configurazione",
  "default_rooms_spotify_warning_title": "Spotify Connect è ora supportato",
  "default_view": "Vista di default",
  "delay": "Ritardo",
  "delay_seconds_b": "Ritardo (secondi)",
  "delete": "Cancella",
  "delete_account": "Elimina account",
  "demo_enter": "Modalità demo",
  "demo_functionality_warning_txt": "Questa funzionalità non è supportata in modalità demo.",
  "demo_location": "Ghent",
  "demo_server_name": "Casa Demo",
  "demo_title": "Demo",
  "demo_warning_txt": "Stai entrando in modalità demo.",
  "detail": "dettaglio",
  "device": "Dispositivo",
  "devices": "Dispositivi",
  "device_settings": "Impostazioni dispositivo",
  "device_volume": "Volume dispositivo",
  "dhcp": "DHCP",
  "direction_east": "Est",
  "direction_east-northeast": "Est-nordest",
  "direction_east-southeast": "Est-sudest",
  "direction_north": "Nord",
  "direction_northeast": "Nordest",
  "direction_north-northeast": "Nord-nordest",
  "direction_north-northwest": "Nord-nordovest",
  "direction_northwest": "Nordovest",
  "direction_short_east": "E",
  "direction_short_east-northeast": "ENE",
  "direction_short_east-southeast": "ESE",
  "direction_short_north": "N",
  "direction_short_northeast": "NE",
  "direction_short_north-northeast": "NNE",
  "direction_short_north-northwest": "NNO",
  "direction_short_northwest": "NO",
  "direction_short_south": "S",
  "direction_short_southeast": "SE",
  "direction_short_south-southeast": "SSE",
  "direction_short_south-southwest": "SSO",
  "direction_short_southwest": "SO",
  "direction_short_west": "O",
  "direction_short_west-northwest": "ONO",
  "direction_short_west-southwest": "OSO",
  "direction_south": "Sud",
  "direction_southeast": "Sudest",
  "direction_south-southeast": "Sud-sudest",
  "direction_south-southwest": "Sud-sudovest",
  "direction_southwest": "Sudovest",
  "direction_west": "Ovest",
  "direction_west-northwest": "Ovest-nordovest",
  "direction_west-southwest": "Ovest-sudovest",
  "disband_group": "Sciogli il gruppo",
  "dns": "DNS",
  "domain": "Dominio",
  "done": "Fatto",
  "do_not_disturb": "Non disturbare",
  "do_not_disturb_off_l1": "Tutte le notifiche sonore sono attualmente attive.",
  "do_not_disturb_on_l1": "Tutte le notifiche sonore sono attualmente disattivate.",
  "do_not_show_again": "Non visualizzare più",
  "door_phone": "Citofono",
  "doors": "Porte",
  "down": "Giu",
  "downloading": "scaricamento",
  "downloading_updates": "Scaricamento aggiornamenti",
  "do_you_want_to_continue": "Continuare?",
  "drag_and_drop_images": "Seleziona immagine o trascinala qui",
  "drag_and_drop_lights": "Trascinare e rilasciare le luci qui",
  "drag_to_reorder": "Trascina per riordinare",
  "dsp": "DSP",
  "dsp_configuration": "Configurazione DSP",
  "dsp_profile_club": "Club",
  "dsp_profile_custom": "Personalizzato",
  "dsp_profile_flat": "Piatto",
  "dsp_profile_jazz": "Jazz",
  "dsp_profile_live": "Live",
  "dsp_profile_pop": "Pop",
  "dsp_profile_rock": "Rock",
  "dsp_shortcut_l1": "Stai per aprire le impostazioni DSP.",
  "echo_cancellation": "Cancellazione eco",
  "echo_limit": "Limiti eco",
  "edit": "Imposta",
  "edit_dns": "Imposta DNS",
  "edit_gateway": "Imposta gateway",
  "edit_ip_address": "Imposta indirizzo IP",
  "edit_subnet_mask": "Imposta maschera di sottorete",
  "ellie_room": "Stanza Ellie",
  "ellie_system_update": "Aggiornamento di sistema Ellie",
  "ellie_system_update_l1": "Ellie deve essere riavviato per installare l'aggiornamento.",
  "ellie_update": "Aggiornamento Ellie",
  "email": "Indirizzo mail",
  "empty": "vuoto",
  "empty_queue": "La coda è vuota",
  "empty_queue_txt_l1": "Sembra che non hai canzoni nella tua coda.",
  "empty_queue_txt_l2": "Prosegui e aggiungi delle canzoni.",
  "empty_song": "Il player si è fermato",
  "empty_song_txt_l1": "Hai raggiunto la fine della tua coda di riproduzione.",
  "empty_song_txt_l2": "Premi play per ripartire",
  "empty_src": "Nessuna sorgente selezionata",
  "empty_src_txt_l1": "Sembra che non hai selezionato nessuna sorgente.",
  "empty_src_txt_l2": "Prosegui e seleziona una sorgente.",
  "empty_zone": "Nessuna stanza selezionata.",
  "empty_zone_txt_l1": "Sembra che non hai selezionato nessuna stanza.",
  "empty_zone_txt_l2": "Prosegui e seleziona una stanza.",
  "energy": "Energia",
  "eq": "EQ",
  "error": "Errore",
  "external": "Esterno",
  "extra_permissions_required": "Alcune funzioni potrebbero richiedere dei permessi aggiuntivi.",
  "ext_src": "Sorgente esterna",
  "fahrenheit": "Fahrenheit",
  "failed": "Fallito",
  "fan": "Ventilazione",
  "fan_auto": "Auto",
  "fan_high": "Alto",
  "fan_low": "Basso",
  "fan_medium": "Medio",
  "fan_off": "Off",
  "favourite": "Preferito",
  "favourites": "Preferiti",
  "favourite_scenes_full": "Scenari preferiti sono pieni",
  "favourite_scenes_full_sub": "Puoi impostare un massimo di 4 scenari preferiti per una stanza",
  "favourite_songs": "Canzoni preferite",
  "featured_playlists": "Playlist preferite",
  "floor_attic": "Attico",
  "floor_basement": "Piano interrato",
  "floor_floor": "Piano",
  "floor_garden": "Giardino",
  "floor_heating": "Riscaldamento a pavimento",
  "floor_loft": "Soppalco",
  "floor_lower": "Piano inferiore",
  "floor_lvl_0": "Piano terra",
  "floor_lvl_1": "Primo piano",
  "floor_lvl_2": "Secondo piano",
  "floor_lvl_3": "Terzo piano",
  "floor_lvl_4": "Quarto piano",
  "floor_lvl_5": "Quinto piano",
  "floor_lvl_6": "Sesto piano",
  "floor_lvl_7": "Settimo piano",
  "floor_lvl_8": "Ottavo piano",
  "floor_lvl_9": "Nono piano",
  "floor_lvl_10": "Decimo piano",
  "floor_lvl_11": "Undicesimo piano",
  "floor_lvl_12": "Dodicesimo piano",
  "floor_lvl_13": "Tredicesimo piano",
  "floor_lvl_14": "Quattordicesimo piano",
  "floor_lvl_15": "Quindicesimo piano",
  "floor_lvl_16": "Sedicesimo piano",
  "floor_lvl_17": "Diciassettesimo piano",
  "floor_lvl_18": "Diciottesimo piano",
  "floor_lvl_19": "Diciannovesimo piano",
  "floor_lvl_20": "Ventesimo piano",
  "floor_upper": "Piano superiore",
  "forgot_password": "Password dimenticata",
  "frequency": "Frequenza",
  "full_screen": "Pieno schermo",
  "functions": "Funzioni",
  "gateway": "Gateway",
  "general": "Generale",
  "generic_devices": "Dispositivi",
  "generic_devices_appliances": "Elettrodomestici",
  "generic_devices_artwork": "Opera d'arte",
  "generic_devices_energy": "Energia",
  "generic_devices_fire_place": "Camino",
  "generic_devices_fountain": "Fontana",
  "generic_devices_garage": "Garage",
  "generic_devices_garden": "Giardino",
  "generic_devices_irrigation": "Irrigazione",
  "generic_devices_pool": "Piscina",
  "generic_devices_security": "Sicurezza",
  "generic_devices_socket": "Presa",
  "generic_devices_ventilation": "Ventilazione",
  "generic_devices_wellness_spa": "Benessere e Spa",
  "genre": "Genere",
  "genre_moods": "Genere e atmosfera",
  "genres": "Generi",
  "go_to_login_l1": "Accedi o iscriviti con un account Basalte",
  "go_to_manage_l1": "Gestisci il tuo account Basalte.",
  "group": "Gruppo",
  "groups": "Gruppi",
  "home": "Casa",
  "humidity": "Umidità",
  "hz": "Hz",
  "images": "immagini",
  "image_smaller_than_5mb": "L'immagine deve essere inferiore a 5MB",
  "in": "In",
  "incorrect_album": "Album non trovato",
  "incorrect_artist": "Artista non trovato",
  "install": "Insalla",
  "installing": "installazione in corso",
  "install_update": "Installa aggiornamento",
  "install_updates": "Installa aggiornamenti",
  "integrator": "Integratore",
  "integrator_access_allow": "Permetti configurazione remota",
  "intercom": "Citofonia",
  "internal": "Interno",
  "invalid_address": "Indirizzo non valido",
  "invalid_code": "Codice non valido",
  "invalid_code_l1": "Il codice inserito non è corretto.",
  "invalid_code_l2": "Accertati che il codice sia corretto o richiedine uno nuovo.",
  "invalid_input": "Inserimento non valido",
  "invalid_user": "Utente non valido",
  "invalid_user_l1": "Accertati che l'indirizzo mail sia valido.",
  "ios_plist_string_NSCameraUsageDescription": "Puoi usare la fotocamera per fare foto per le stanze",
  "ios_plist_string_NSLocalNetworkUsageDescription": "L'accesso alla rete locale può essere utilizzato per trovare server, telecamere IP, ...",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "La libreria fotografica può essere usata per salvare foto per le stanze",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "La libreria fotografica può essere usata per selezionare foto per le stanze",
  "ip": "IP",
  "ip_address": "Indirizzo IP",
  "join": "+ Unisci",
  "join_alt": "+ Unisci",
  "keep_awake": "Mantieni iPad attivo",
  "keep_awake_device": "Mantieni dispositivo attivo",
  "kelvin": "Kelvin",
  "Key": "it_IT",
  "knx_presets": "Preset KNX",
  "knx_presets_info": "Modifica preset KNX esistenti per riprodurre qualche cosa di diverso.",
  "language": "Lingua",
  "language_system": "Linga di sistema",
  "last-7-days": "Ultimi 7 giorni",
  "last_hour": "Ultima ora",
  "last_room": "Ultima stanza",
  "last_view": "Ultima vista",
  "learn_more": "Ulteriori informazioni",
  "leave_group": "Abbandona gruppo",
  "left": "Sinistra",
  "lena_system_update": "Aggiornamento di sistema Lena",
  "lena_system_update_l1": "Lena deve essere riavviato per installare l'aggiornamento.",
  "library": "Libreria",
  "library_empty": "Libreria vuota",
  "library_scanning": "Scansione libreria",
  "light_accent_light": "Luce d'arte",
  "light_art_light": "Luce d'accento",
  "light_cabinet": "Quadro elettrico",
  "light_ceiling_mounted_fixture": "Luce a soffitto",
  "light_chandelier": "Candeliere",
  "light_desk": "Scrivania",
  "light_down": "Downlight",
  "light_floor_lamp": "Luce a pavimento",
  "light_lampshade": "Paralume",
  "light_led": "Luce a LED",
  "light_led_strip": "Strip LED",
  "light_mirror": "Specchio",
  "light_mood_light": "Luce di ambiente",
  "light_pendant": "Pendente",
  "light_reading_light": "Luce di lettura",
  "lights": "Luci",
  "lights_off_all": "Spegni tutte le luci",
  "light_spot": "Spot",
  "light_table_lamp": "Luce da tavolo",
  "light_track": "Traccia",
  "light_via": "Via",
  "light_wall_mounted_fixture": "Luce a parete",
  "link": "Link",
  "link_deezer_info_l1": "Collega un account Deezer con uno stream.",
  "link_deezer_info_l2": "Questo permette di selezionare e suonare media Deezer sullo stream.",
  "linked_homes": "Case collegate",
  "linked_projects": "Progetti collegati",
  "linked_with": "Connesso all",
  "link_home": "Apri la app Basalte su un dispositivo locale e collega la tua home nelle impostazioni.",
  "link_server": "Link all'account Basalte",
  "link_server_full_sub": "Collegare questo progetto al seguente account Basalte?",
  "link_spotify_info_l1": "Collega un account Spotify con uno stream.",
  "link_spotify_info_l2": "Questo permette di selezionare e suonare media Spotify sullo stream.",
  "link_tidal_info_l1": "Collega un account TIDAL con uno stream.",
  "link_tidal_info_l2": "Questo permette di selezionare e suonare media TIDAL sullo stream.",
  "lisa_system_update": "Aggiornamento di sistema Lisa",
  "lisa_system_update_l1": "Lisa deve essere riavviato per installare l'aggiornamento.",
  "listen_in": "Ascolta in",
  "liters": "Litri",
  "live_adjustments": "Aggiustamenti in diretta",
  "loading": "Caricamento…",
  "local": "locale",
  "location": "Posizione",
  "location_back": "Indietro",
  "location_bottom": "Basso",
  "location_center": "Centro",
  "location_chair": "Sedia",
  "location_corner": "Angolo",
  "location_desk": "Scrivania",
  "location_east": "Est",
  "location_front": "Fronte",
  "location_high": "Alto",
  "location_left": "Sinistra",
  "location_low": "Giu",
  "location_mid": "Metà",
  "location_north": "Nordovest",
  "location_right": "Destra",
  "location_south": "Sud",
  "location_stove": "Stufa",
  "location_table": "Tavolo",
  "location_top": "Alto",
  "location_west": "Ovest",
  "logged_in_as": "Collegato come",
  "log_out": "Disconnettersi",
  "log_out_everywhere": "Disconnettersi dappertutto",
  "long-press-save-scene": "Tieni premuto uno scenario per aggiornarlo",
  "louver_30": "30 gradi",
  "louver_45": "45 gradi",
  "louver_60": "60 gradi",
  "louver_horizontal": "Orizzontale",
  "louver_mode": "Modalità feritoia",
  "louver_off": "Spento",
  "louver_swing": "Oscillazione",
  "louver_vertical": "Verticale",
  "mac_address": "Indirizzo MAC",
  "manage": "Gestisci",
  "manage_account": "Gestisci account",
  "manual_conn": "Connessione manuale",
  "manual_text": "Prego inserisci l'indirizzo del server desiderato.",
  "max": "Max",
  "menu": "Menu",
  "mf_device": "{count, plural, =1 {Dispositivo} other {Dispositivi}}",
  "mf_feels_like": "Temperatura percepita {value}",
  "mf_minute": "{count} {count, plural, =1 {minuto} other {minuti}}",
  "mf_powered_by": "Powered by {value}",
  "mf_second": "{count} {count, plural, =1 {secondo} other {secondi}}",
  "mf_server": "{count, plural, =1 {Server} other {Server}}",
  "mf_song": "{count} {count, plural, =1 {canzone} other {canzoni}}",
  "mf_source": "Sorgente: {value}",
  "microphone_gain": "Amplificazione microfono",
  "min": "Min",
  "mix": "Mix",
  "mixed_sources": "Sorgenti multiple",
  "mixed_sources_txt_l1": "Questo gruppo contiene stanze con sorgenti multiple.",
  "mixed_sources_txt_l2": "Prosegui e seleziona una sorgente.",
  "mixes": "Mix",
  "mixes_small": "mix",
  "mode": "Modo",
  "mode_auto": "Auto",
  "mode_cooling": "Raffrescamento",
  "mode_heating": "Riscaldamento",
  "mode_off": "Off",
  "month": "Mese",
  "month_april": "Aprile",
  "month_august": "Agosto",
  "month_december": "Dicembre",
  "month_february": "Febbraio",
  "month_january": "Gennaio",
  "month_july": "Luglio",
  "month_june": "Giugno",
  "month_march": "Marzo",
  "month_may": "Maggio",
  "month_november": "Novembre",
  "month_october": "Ottobre",
  "month_september": "Settembre",
  "month_short_april": "Apr",
  "month_short_august": "Ag",
  "month_short_december": "Dic",
  "month_short_february": "Febbr",
  "month_short_january": "Genn",
  "month_short_july": "Luglio",
  "month_short_june": "Giugno",
  "month_short_march": "Mar",
  "month_short_may": "Magg",
  "month_short_november": "Nov",
  "month_short_october": "Ott",
  "month_short_september": "Sett",
  "mood": "Atmosfera",
  "moods": "Atmosfere",
  "music": "Musica",
  "music_choice": "Scelta musica",
  "music_off": "musica spenta",
  "music_off_all": "Spegni tutte le stanze",
  "music_off_all_rooms": "Musica spenta, tutte le stanze",
  "music_off_current": "Spegni la stanza corrente",
  "music_off_current_room": "Musica spenta, questa stanza",
  "music_off_txt_l1": "Stai per spegnere una o tutte le stanze.",
  "music_off_txt_l2": "Sei sicuro di volere continuare?",
  "music_on_current": "Accendi la stanza corrente",
  "music_server": "Server musicale",
  "mute_area_on_call": "Tacita la musica durante la chiamata",
  "my_favorites": "Miei preferiti",
  "my_music": "Mia musica",
  "name": "Nome",
  "nds": "Ricerca server",
  "nds_music": "Ricerca server musicali",
  "nds_none": "Nessun server trovato!",
  "nds_none_music": "Nessun server musicale trovato!",
  "nds_text_l1": "Questo potrebbe richiedere qualche secondo…",
  "nds_text_l2": "Assicurati che il tuo dispositivo sia connesso alla rete Wi-Fi di casa tua.",
  "network": "Rete",
  "network_input_invalid": "Tutti i campi devono essere correttamente compilati",
  "never": "Mai",
  "new": "Nuovo",
  "new_alarm": "Nuovo allarme",
  "new_image": "Nuova immagine",
  "new_password": "Nuova password",
  "new_password_confirmation": "Conferma nuova password",
  "new_playlist": "nuova playlist…",
  "new_releases": "Nuove release",
  "new_releases_small": "nuove release",
  "new_scene": "Nuovo scenario",
  "new_schedule": "Nuova schedulazione",
  "new_step": "Nuova azione",
  "next": "Prossimo",
  "next_song": "prossima canzone",
  "no": "No",
  "no_active_rooms": "Nessuna stanza attiva",
  "no_all_off": "No, non spegnere",
  "no_av": "Nessun ingresso AV",
  "no_configuration": "Nessuna configurazione disponibile",
  "no_content_available": "Nessun contenuto disponibile",
  "no_data": "Nessun dato",
  "no_deezer_dis": "No, non scollegare Deezer",
  "no_devices_capabilities": "Nessun dispositivo configurabile",
  "no_doors_available": "Nessuna porta disponibile",
  "no_favourites": "Nessun preferito",
  "no_keep_it": "No, mantieni",
  "no_lights_capabilities": "Nessuna luce configurabile",
  "no_linked_homes": "Non ho trovato nessuna home collegata.",
  "none": "Nessuno",
  "no_network": "Nessuna connessione di rete",
  "no_preview_available": "Nessuna anteprima disponibile",
  "no_rooms_available": "Nessuna stanza disponibile",
  "no_room_selected": "nessuna stanza selezionata",
  "no_scene": "Nessuno scenario",
  "no_scenes_favourites": "Nessuno scenario preferito",
  "no_scenes_txt": "Seleziona scenari da visualizzare qui.",
  "no_search_results": "Nessun risultato di ricerca",
  "no_servers": "Nessun server trovato",
  "no_shades_capabilities": "Nessun oscurante configurabile",
  "no_source": "- off -",
  "no_sources_available": "Nessuna sorgente disponibile",
  "no_steps": "Questo scenario non contiene azioni",
  "not_allowed": "Non permesso",
  "not_allowed_l1": "Non hai i permessi per fare questo",
  "notification": "Notifica",
  "notifications": "Notifiche",
  "not_linked": "Non connesso",
  "not_now": "Non adesso",
  "not_set": "Non impostato",
  "no_users_l1": "Non sono stati trovati utenti.",
  "no_users_l2": "Seleziona un server con utenti.",
  "number_of_songs": "canzone(i)",
  "off": "Off",
  "offline_server": "Server offline",
  "offset_minutes": "Offset (minuti)",
  "ok": "OK",
  "on": "On",
  "onix_black": "Nero",
  "online": "online",
  "only_png_jpeg_supported": "Solo immagini PNG e JPEG sono supportate",
  "open": "Apri",
  "open_close_door": "Porta",
  "open_close_gate": "Cancello",
  "open_close_window": "Finestra",
  "open_settings": "Apri impostazioni",
  "open_spotify": "Apri Spotify",
  "open_spotify_l1": "Stai per aprire la app Spotify.",
  "open_state": "Aperto",
  "or": "O",
  "order_cameras_by_holding_and_draging": "Trascina le telecamere nell'ordine desiderato.",
  "other": "Altro",
  "out": "Esterno",
  "pair": "Accoppia",
  "password": "Password",
  "password_changed": "Password modificata",
  "password_confirmation": "Conferma password",
  "password_requirements": "La password deve contenere almeno 8 caratteri e includere almeno una lettera maiusca, una lettera minuscola ed un numero.",
  "permission_denied": "Permesso negato",
  "permission_denied_go_to_settings_l1": "Questa funzione richiede permessi per funzionare correttamente.",
  "permission_denied_go_to_settings_l2": "Dai i permessi a questa app nelle impostazioni del tuo dispositivo per abilitare tutte le funzioni.",
  "photo_from_camera": "Fai una foto",
  "photo_from_library": "Seleziona immagine da libreria",
  "pick_room_function": "Seleziona una stanza e una funzione",
  "play": "Play",
  "playback_gain": "Amplificazione playback",
  "playing_in": "sta suonando in",
  "playlist": "Playlist",
  "playlists": "Playlist",
  "playlists_itunes": "Playlist iTunes",
  "playlists_l": "playlist",
  "playlists_local": "Playlist locali",
  "playlists_shared": "Playlist condivise",
  "play_next": "Suona come prossimo",
  "play_now": "Suona ora",
  "please_try_a_different_image": "Prego provare una immagine differente",
  "please_try_again": "Prego riprovare",
  "popular": "Popolare",
  "position": "Posizione",
  "precipitation": "Precipitazioni",
  "previous": "Precedente",
  "project": "Progetto",
  "project_image": "Immagine del progetto",
  "proximity_activation": "Attivazione prossimità",
  "proximity_far": "Lontano",
  "proximity_medium": "Medio",
  "proximity_near": "Vicino",
  "queue": "Coda",
  "radio": "Radio",
  "radio_error": "C'è stato un errore scaricando le stazioni radio",
  "radio_stations": "Stazioni radio",
  "radio_stations_other": "Altre stazioni",
  "recently_played": "Suonato di recente",
  "related_artists": "Artisti correlati",
  "reload": "Ricarica",
  "remember_me": "Ricordami",
  "remote": "Remoto",
  "remote_ellie_intercom_outdated": "Ellie remoto scaduto",
  "remote_ellie_intercom_outdated_l1": "L'Ellie che stai tentando di chiamare necessita di un aggiornamento software.",
  "remote_ellie_intercom_outdated_l2": "Riprova dopo l'aggiornamento.",
  "remove": "Rimuovi",
  "remove_alarm": "Rimuovi allarme",
  "remove_basalte_favourite": "Rimuovi preferito Basalte",
  "remove_deezer_favourite": "Rimuovi preferito Deezer",
  "remove_from_fav": "rimuovi dai preferiti",
  "remove_schedule": "Rimuovi schedulazione",
  "remove_spotify_favourite": "Rimuovi preferito Spotify",
  "remove_spotify_preset": "Rimuovi preset Spotify",
  "remove_tidal_favourite": "Rimuovi preferito TIDAL",
  "remove_txt_l1": "Stai per riumovere questo elemento.",
  "remove_txt_l2": "Sei sicuro di volere continuare?",
  "rename": "rinomina",
  "repeat": "Ripeti",
  "replace_queue": "Sostituisci coda",
  "resend_code": "Rimanda codice",
  "resend_confirmation_code": "Rimanda codice di conferma",
  "reset_image": "Reset immagine",
  "reset_password": "Reset password",
  "reset_password_l1": "Sei sicuro di volere resettare la tua password?",
  "restart": "Riavvia",
  "restart_app": "Riavvia app",
  "restart_app_q": "Riavvia app?",
  "restarting_server": "Riavvio server in corso",
  "restart_music_server": "Riavvia server musicale",
  "restart_music_server_txt_l1": "Stai per riavviare il server musicale.",
  "restart_server": "Riavvia il server",
  "restart_server_txt_l1": "Stai per riavviare il server.",
  "restart_server_txt_l2": "L'applicazione si riconnetterà automaticamente.",
  "retrieving": "Recupero in corso…",
  "retrieving_users_stop_text": "Recuper utenti non riuscito, server corretto?",
  "retrieving_users_text": "Recuper utenti in corso…",
  "right": "Destra",
  "room": "Stanza",
  "room_attic": "Attico",
  "room_ball_room": "Stanza da ballo",
  "room_bar": "Bar",
  "room_basement": "Taverna",
  "room_bathroom": "Bagno",
  "room_bedroom": "Camera da letto",
  "room_corridor": "Corridoio",
  "room_dining_room": "Sala da pranzo",
  "room_dressing_room": "Spogliatoio",
  "room_driveway": "Vialetto",
  "room_entry": "Ingresso",
  "room_family_room": "Stanza famigliare",
  "room_foyer": "Foyer",
  "room_garage": "Garage",
  "room_garden": "Giardino",
  "room_guest_room": "Camera degli ospiti",
  "room_gym": "Palestra",
  "room_hallway": "Corridoio",
  "room_hobby_room": "Camera hobby",
  "room_home_cinema": "Home Cinema",
  "room_home_office": "Ufficio",
  "room_images": "Immagini stanze",
  "room_intercom_lost": "Connession persa",
  "room_intercom_lost_sub": "Perso connnessione chiamata, prego riprovare",
  "room_intercom_unavailable": "Stanza non disponibile",
  "room_intercom_unavailable_sub": "La stanza al momento non è disponibile",
  "room_intercom_unreachable": "Stanza non raggiungibile",
  "room_intercom_unreachable_sub": "La stanza al momento non è raggiungibile",
  "room_kitchen": "Cucina",
  "room_laundry_room": "Lavanderia",
  "room_library": "Biblioteca",
  "room_living_room": "Salotto",
  "room_master_bedroom": "Camera padronale",
  "room_media_room": "Sala multimediale",
  "room_meeting_room": "Sala meeting",
  "room_nook": "Angolo",
  "room_nursery": "Nursery",
  "room_pantry": "Dispensa",
  "room_parking_place": "Parcheggio",
  "room_patio": "Patio",
  "room_pool": "Piscina",
  "room_pool_house": "Edificio piscina",
  "room_rack": "Rack",
  "room_room": "Stanza",
  "rooms": "Stanze",
  "room_scene_no_functions": "Questa stanza non ha funzioni",
  "room_settings": "Impostazioni stanza",
  "room_spa": "Spa",
  "room_stable": "Stalla",
  "room_staircase": "Scale",
  "room_terrace": "Terrazza",
  "room_toilet": "Toilette",
  "room_utility_room": "Ripostiglio",
  "room_wine_cellar": "Cantina vini",
  "room_workshop": "Laboratorio",
  "rotation": "Rotazione",
  "save": "Salva",
  "save_as": "Salva con nome",
  "save_basalte_favourite": "Salva preferito Basalte",
  "save_changes": "Salva cambiamenti",
  "save_changes_l1": "Alcuni cambiamenti non sono stati ancora salvati",
  "save_changes_l2": "Li vuoi salvare adesso?",
  "save_deezer_favourite": "Salva preferito Deezer",
  "save_spotify_favourite": "Salva preferito Spotify",
  "save_spotify_preset": "Salva preset Spotify",
  "save_tidal_favourite": "Salva preferito TIDAL",
  "scene": "Scenario",
  "scene_activated": "Scena attivata",
  "scene_away": "Uscita",
  "scene_cooking": "Cucina",
  "scene_dinner": "Cena",
  "scene_error": "Errore scenario",
  "scene_evening": "Sera",
  "scene_good_morning": "Buon giorno",
  "scene_good_night": "Buona notte",
  "scene_guests": "Ospiti",
  "scene_home": "A casa",
  "scene_image_error": "Errore dell'immagine della scena",
  "scene_movie": "Film",
  "scene_not_available": "Nessuno scenario presente.",
  "scene_not_selected_l1": "Nessuno scenario selezionato.",
  "scene_not_selected_l2": "Prego selezionare uno scenario.",
  "scene_party": "Party",
  "scene_relax": "Relax",
  "scenes": "Scenari",
  "scene_select_or_new": "Prego selezionare uno scenario o crearne uno nuovo.",
  "scene_vacation": "Vacanza",
  "scene_welcome": "Benvenuto",
  "scene_workout": "Allenamento",
  "schedule": "Timer",
  "schedule_at": "Programma alle",
  "schedule_not_selected_l1": "Nessuna schedulazione selezionata.",
  "schedule_not_selected_l2": "Prego selezionare una schedulazione.",
  "scheduler": "Schedulatore",
  "schedules": "Schedulazioni",
  "schedule_select_or_new": "Prego selezionare una schedulazione o crearne una nuova.",
  "screen_orientation": "Orientamento schermo",
  "search": "Cerca",
  "search_again": "Cerca ancora",
  "search_ph": "cerca artista, canzone o album…",
  "search_result": "Risultati ricerca per",
  "security": "Sicurezza",
  "select_admin": "Seleziona un profilo amministratore per continuare il processo di aggiornamento",
  "select_home": "Seleziona la tua casa",
  "selection": "Selezione",
  "selection_all": "aggiungi tutto alla selezione",
  "select_photo": "Seleziona immagine",
  "select_server": "Seleziona un server",
  "select_user": "Seleziona un profilo per accedere",
  "serial_number": "Numero seriale",
  "server": "Server",
  "server_address": "Indirizzo server",
  "server_basalte_cloud_no_connection": "Non è possibile collegare il server al Basalte Cloud",
  "server_exists_already": "Server già esistente!",
  "server_mac": "Indirzzo MAC del server",
  "servers": "Server",
  "server_status": "Stato server",
  "server_update_info_l2": "prego aggiornare il server Basalte all'ultima versione.",
  "server_update_required": "Richiesto aggiornamento del server",
  "server_version": "Versione server",
  "set": "Imposta",
  "set_new_password": "Imposta nuova password",
  "setpoint": "Setpoint",
  "settings": "Impostazioni",
  "shade_blind": "Oscurante",
  "shade_center": "Centro",
  "shade_curtain": "Tenda",
  "shade_left": "Sinistra",
  "shade_pool_shutter": "Copertura piscina",
  "shade_right": "Destra",
  "shade_roman_blind": "Tenda a pacchetto",
  "shades": "Ombreggianti",
  "shade_shade": "Ombreggiante",
  "shade_shutter": "Tapparella",
  "shade_solar_protection": "Protezione solare",
  "shade_venetian_blind": "Veneziana",
  "shade_vertical_blind": "Oscurante verticale",
  "share": "condividi",
  "shared_playlist": "Playlist condivisa",
  "show_album": "Mostra album",
  "show_artist": "Mostra artista",
  "show_background_art": "Mostra sfondo artistico",
  "show_more": "Mostra di più…",
  "show_status_bar": "Mostra barra di stato iPad",
  "show_status_bar_device": "Mostra barra di stato",
  "sign_in": "Accedi",
  "sign_in_info_tidal_l1": "Inserisci le tue credenziali TIDAL",
  "sign_up": "Iscriviti",
  "single_room": "Stanza",
  "sleep": "Standby",
  "software_update": "Aggiornamento software",
  "something_went_wrong": "Qualcosa è andato storto",
  "songs": "Canzoni",
  "sonos": "Sonos",
  "sound_settings": "Impostazioni audio",
  "source": "Sorgente",
  "sources": "Sorgenti",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Accertati che Core server e il dispositivo con la app Spotify siano sulla stessa rete.",
  "spotify_connect_instructions_2": "Seleziona lo stream Core server dall'elenco dispositivi nella app Spotify.",
  "spotify_connect_instructions_body": "Ascolta dalle tue casse o TV usando la app Spotify come telecomando.",
  "spotify_connect_instructions_title": "Sei pronto ad ascoltare un po' di musica?",
  "spotify_disconnect_txt": "Stai per scollegare l'account Spotify da questo player.",
  "spotify_free_user_txt": "É richiesto un account Spotify Premium",
  "spotify_no_link": "Nessun account Spotify connesso",
  "spotify_oauth_error": "Errore autenticazione Spotify",
  "spotify_preset_error": "Errore Spotify",
  "spotify_preset_error_l1": "Caricamento preset Spotify fallito.",
  "spotify_preset_error_l2": "Accertati che un account Spotify valido sia connesso con questo stream.",
  "spotify_presets": "Preset Spotify",
  "start": "Inizio",
  "startup_view": "Vista all'avvio",
  "startup_volume": "Volume iniziale",
  "state_active": "Attivo",
  "state_armed": "Armato",
  "state_closed": "Chiuso",
  "state_disabled": "Disabilitato",
  "state_disarmed": "Disarmato",
  "state_down": "Giu",
  "state_enabled": "Abilitato",
  "state_false": "Falso",
  "state_free": "Libero",
  "state_high": "Alto",
  "state_in": "Dentro",
  "state_inactive": "Non attivo",
  "state_left": "Sinistra",
  "state_low": "Basso",
  "state_occupied": "Occupato",
  "state_off": "Off",
  "state_on": "On",
  "state_opened": "Aperto",
  "state_out": "Fuori",
  "state_right": "Destra",
  "state_started": "Avviato",
  "state_stopped": "Fermato",
  "state_true": "Vero",
  "state_up": "Su",
  "static": "Statico",
  "stations": "Stazioni",
  "status": "Stato",
  "stop": "Stop",
  "stream": "Stream",
  "streaming": "Streaming",
  "streaming_services": "Servizi streaming",
  "streams": "Stream",
  "streams_beta": "Stream (beta)",
  "subnet_mask": "Maschera di sottorete",
  "sun": "Sole",
  "sunrise": "Alba",
  "sunset": "Tramonto",
  "swipe_to_pan": "Scorri per la panoramica",
  "switch_server": "Cambia server",
  "synchronise": "Scansiona ora",
  "system": "Sistema",
  "system_setting": "Impostazione di sistema",
  "temperature": "Temperatura",
  "temperature_unit": "Unità temperatura",
  "theme": "Tema",
  "thermostat": "Termostato",
  "this_cannot_be_undone": "L'operazione non può essere annullata.",
  "this_week": "Questa settimana",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "Stai per disconnettere l'account TIDAL da questo player.",
  "tidal_legacy_auth": "È richiesto l'accesso a TIDAL",
  "tidal_legacy_auth_l1": "Per continuare a utilizzare TIDAL, effettua nuovamente il login.",
  "tidal_no_link": "Nessun account TIDAL connesso",
  "tidal_oauth_error": "Errore autenticazione TIDAL",
  "tidal_rising": "TIDAL Rising",
  "time": "Ora",
  "time_format": "Formato ora",
  "timer": "Timer",
  "timers": "Timer",
  "title": "Titolo",
  "today": "Oggi",
  "tomorrow": "Domani",
  "top_tracks": "Tracce top",
  "treble": "Alti",
  "try_again": "Riprova",
  "turned_off": "Spento",
  "turned_on": "Acceso",
  "turn_off": "Spegni",
  "turn_on": "Accendi",
  "turn_on_music": "Accendi la musica",
  "turn_on_tv": "Accendi la tv",
  "tv": "TV",
  "unavailable": "Non disponibile",
  "unavailable_src": "Sorgente non disponibile",
  "unavailable_src_txt": "La sorgente corrente non è disponibile.",
  "ungroup": "Separa",
  "unknown": "Sconosciuto",
  "unknown_account": "Account sconosciuto",
  "unknown_account_l1": "Questo indirzzo mail non è un account Basalte.",
  "unknown_av": "Ingresso AV sconosciuto",
  "unknown_src": "Sorgente sconosciuta",
  "unknown_src_txt": "La sorgente corrente non è conosciuta su questo server.",
  "unlink": "Scollega",
  "unlink_server": "Scollega dall'account Basalte",
  "unlink_server_full_sub": "Scollegare questo progetto dall'account Basalte? Non sarà più accessibile da remoto.",
  "unreachable": "Non raggiungibile",
  "unshare": "non condividere",
  "unsupported_browser": "Browser non supportato",
  "unsupported_browser_l1": "Alcune funzionalità potrebbero non funzionare correttamente.",
  "unsupported_browser_l2": "Per una migliore esperienza usa Chrome o Safari.",
  "unsupported_browser_live": "Questo browser non supporta l'account Basalte. Prova ad usare una versione recente di Chrome o Safari.",
  "up": "Su",
  "update": "Aggiornamento",
  "update_available": "Aggiornamento",
  "update_available_full": "Aggiornamento disponibile",
  "update_info_l1": "Per assicurare il miglior possibile funzionamento,",
  "update_library": "Riscansiona libreria musicale",
  "update_music_server": "Aggiorna il server musicale",
  "update_scene": "Aggiorna lo scenario",
  "update_scene_l1": "Salva lo stato attuale in questo scenario.",
  "update_server": "Aggiorna il server",
  "updating_server": "Aggiornamento server in corso",
  "updating_server_info": "Non spegnere il server",
  "up_to_date": "Aggiornato",
  "username": "Nome utente",
  "uv": "UV",
  "value_invalid": "Il valore inserito non è valido",
  "verify": "Verifica",
  "verifying": "verifica in corso",
  "version": "Versione",
  "video_background": "Sfondo video",
  "volume": "Volume",
  "warning": "Avviso",
  "water": "Acqua",
  "weather": "Meteo",
  "weather_daily_data_unavailable": "Al momento non sono disponibili dati meteo giornalieri",
  "weather_hourly_data_unavailable": "Al momento non sono disponibili dati meteo orari",
  "weather_information": "Informazioni meteo",
  "week": "Settimana",
  "weekly": "Settimanale",
  "what": "Quale",
  "what_music": "Quale musica",
  "when": "Quando",
  "where": "Dove",
  "who": "Chi",
  "wind": "Vento",
  "wrong_password": "Password errata",
  "wrong_password_txt": "La password inserita non corrisponde alla password utente",
  "wrong_username_and_or_password": "Nome utente e/o password non corretti",
  "year": "Anno",
  "yes": "Si",
  "yes_all_off": "Si, spegni tutto",
  "yes_cur_off": "Si, spegni",
  "yes_deezer_dis": "Si, disconnetti l'account Deezer",
  "yes_delete_it": "Si, cancella",
  "yesterday": "ieri",
  "zone": "Stanza",
  "zones": "Stanze"
}
