import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('numberInputModalCtrl', [
    '$rootScope',
    '$scope',
    'BAS_SPLASH',
    'BasUtilities',
    'close',
    'basModalConfig',
    numberInputModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasUtilities} BasUtilities
 * @param close
 * @param {BasInputModalConfig} basInputModalConfig
 * @param {string} basInputModalConfig.title
 * @param {TBasModalInput[]} basInputModalConfig.inputs
 * @param {string} basInputModalConfig.saveTextId
 * @param {string} basInputModalConfig.errorTextId
 */
function numberInputModalCtrl (
  $rootScope,
  $scope,
  BAS_SPLASH,
  BasUtilities,
  close,
  basInputModalConfig
) {
  var modal = this

  var _listeners = []

  var updateErrorHaloTimeoutId = null

  var CSS_SHOW_SIGN = 'im-show-sign'
  var CSS_SHOW_COMMA = 'im-show-comma'
  var CSS_INPUT_INVALID = 'im-input-invalid'
  var CSS_SHOW_BOUNDS = 'im-show-bounds'

  var ERROR_HALO_DEBOUNCE_MS = 750

  modal.closeModal = closeModal
  modal.save = save
  modal.enterCharacter = enterCharacter
  modal.backSpace = backSpace
  modal.clear = clear
  modal.toggleNegative = toggleNegative

  modal.basInputModalConfig = basInputModalConfig

  modal.title = basInputModalConfig.title
  modal.subTitle = basInputModalConfig.subTitle
  modal.inputValue = ''
  modal.saveTextId = basInputModalConfig.saveTextId
  modal.validCheck = null
  modal.min = NaN
  modal.max = NaN

  modal.uiMin = ''
  modal.uiMax = ''

  modal.negativeToggleOperator = '-'
  modal.showErrorHalo = false
  modal.css = {}

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)

    _resetCss()

    _initInput()
    _onInputChanged()
  }

  function _initInput () {

    var input, precision, minPrecision, maxPrecision

    if (
      Array.isArray(basInputModalConfig.inputs) &&
      basInputModalConfig.inputs[0]
    ) {

      input = basInputModalConfig.inputs[0]

      modal.inputValue = BasUtil.isNEString(input.initialValue)
        ? input.initialValue
        : ''

      _syncNegativeOperator()

      if (BasUtil.isFunction(input.validCheck)) {

        modal.validCheck = input.validCheck
      }

      if (
        BasUtil.isVNumber(input.step) &&
        BasUtil.getPrecision(input.step) > 0
      ) {
        modal.css[CSS_SHOW_COMMA] = true
      }

      // Get the smallest precision to use for displaying min and max
      //  consistently
      minPrecision = BasUtil.getPrecision(input.min)
      maxPrecision = BasUtil.getPrecision(input.max)
      precision = Math.max(minPrecision, maxPrecision)

      if (BasUtil.isVNumber(input.min)) {

        modal.css[CSS_SHOW_SIGN] = input.min < 0
        if (minPrecision > 0) modal.css[CSS_SHOW_COMMA] = true

        modal.min = input.min
        modal.uiMin = input.min.toFixed(precision)
      }

      if (BasUtil.isVNumber(input.max)) {

        modal.max = input.max
        modal.uiMax = input.max.toFixed(precision)
      }

      modal.css[CSS_SHOW_BOUNDS] = modal.uiMin && modal.uiMax
    }
  }

  function _onInputChanged () {

    _syncValid()
    _syncNegativeOperator()
    _syncErrorHalo()
  }

  function _syncValid () {

    modal.css[CSS_INPUT_INVALID] = !(
      BasUtil.isFunction(modal.validCheck)
        ? modal.validCheck(modal.inputValue)
        : modal.inputValue.length && modal.inputValue !== '-'
    )
  }

  /**
   * Show or hide error halo, either instantly or debounced based on whether you
   *  are going from invalid to valid or valid to invalid
   *
   * @private
   */
  function _syncErrorHalo () {

    if (_shouldShowErrorHalo() && _incompleteValueCanStillBeCorrect()) {

      _updateErrorHaloDebounced()

    } else {

      _updateErrorHalo()
    }
  }

  /**
   * Update showErrorHalo variable instantly
   *
   * @private
   */
  function _updateErrorHalo () {

    _clearErrorHaloTimeout()

    modal.showErrorHalo = _shouldShowErrorHalo()
    _syncErrorText()

    $scope.$applyAsync()
  }

  function _syncErrorText () {

    var value

    value = parseFloat(modal.inputValue)

    if (BasUtil.isVNumber(value)) {

      if (BasUtil.isVNumber(modal.min) && value < modal.min) {

        modal.uiError = BasUtilities.translate('min') + ': ' + modal.min

      } else if (BasUtil.isVNumber(modal.max) && value > modal.max) {

        modal.uiError = BasUtilities.translate('max') + ': ' + modal.max
      }
    }
  }

  /**
   * Returns whether the error halo should be shown, but does not do anything
   *
   * @private
   */
  function _shouldShowErrorHalo () {

    return (
      modal.inputValue &&
      modal.inputValue !== '-' &&
      modal.css[CSS_INPUT_INVALID]
    )
  }

  /**
   * Returns whether an incomplete value can still be correct after adding
   *  new numbers, based on given min and max values
   *
   * @private
   */
  function _incompleteValueCanStillBeCorrect () {

    var parsedInput, hasPoint

    parsedInput = parseFloat(modal.inputValue)

    if (
      BasUtil.isVNumber(modal.min) &&
      BasUtil.isVNumber(modal.max) &&
      BasUtil.isVNumber(parsedInput)
    ) {

      hasPoint = modal.inputValue.indexOf('.') > -1

      if (
        (
          parsedInput < 0
            // Value is negative -> we can still go lower
            ? parsedInput > modal.max
            // Value is positive -> we can still go higher
            : parsedInput < modal.min
        ) &&
        !hasPoint
      ) {
        return true
      }
    }

    return false
  }

  /**
   * Update showErrorHalo variable debounced
   *
   * @private
   */
  function _updateErrorHaloDebounced () {

    _clearErrorHaloTimeout()

    updateErrorHaloTimeoutId = setTimeout(
      _updateErrorHalo,
      ERROR_HALO_DEBOUNCE_MS
    )
  }

  function _clearErrorHaloTimeout () {

    clearTimeout(updateErrorHaloTimeoutId)
    updateErrorHaloTimeoutId = null
  }

  function closeModal (reason) {

    if (
      !modal.basInputModalConfig ||
      modal.basInputModalConfig.allowDismiss
    ) {

      close(reason)
    }
  }

  function save () {

    if (!modal.css[CSS_INPUT_INVALID]) close(modal.inputValue, 0)
  }

  function enterCharacter (character) {

    var newInputValue, newInputValueLength, minus

    newInputValue = BasUtil.trimNumber(
      modal.inputValue + character,
      true
    )
    minus = 0

    if (_isNegative(newInputValue)) minus++
    if (newInputValue.indexOf('.') !== -1) minus++

    newInputValueLength = newInputValue.length - minus

    // After a character was entered, we should have a valid number with a max
    //  length of 7, excluding minus sign or comma/point.
    // If not: ignore
    if (!isNaN(newInputValue) && newInputValueLength < 8) {

      modal.inputValue = newInputValue
      _onInputChanged()
    }
  }

  function backSpace () {

    modal.inputValue = modal.inputValue.slice(0, modal.inputValue.length - 1)
    _onInputChanged()
  }

  function clear () {

    modal.inputValue = ''
    _onInputChanged()
  }

  function toggleNegative () {

    modal.inputValue = _isNegative(modal.inputValue)
      ? modal.inputValue.slice(1)
      : '-' + modal.inputValue
    _onInputChanged()
  }

  function _syncNegativeOperator () {

    modal.negativeToggleOperator = _isNegative(modal.inputValue)
      ? '+'
      : '-'
  }

  function _isNegative (numberString) {

    return BasUtil.startsWith(numberString, '-')
  }

  function _onSplashVisibility () {

    close()
  }

  function _resetCss () {

    modal.css[CSS_SHOW_SIGN] = false
    modal.css[CSS_SHOW_COMMA] = false
    modal.css[CSS_INPUT_INVALID] = false
  }

  function _onDestroy () {

    _clearErrorHaloTimeout()

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
