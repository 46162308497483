'use strict'

angular
  .module('basalteApp')
  .constant('BAS_THERMOSTAT', {
    DEGREE: '°',
    MIN_C: 5,
    MAX_C: 30,
    MODE_HEATING: 'mode_heating',
    MODE_COOLING: 'mode_cooling',
    MODE_AUTO: 'mode_auto',
    MODE_OFF: 'mode_off',
    MODE_DRYING: 'mode_drying',
    MODE_FAN_ONLY: 'mode_fan_only',
    EXTRA_AIRCO: 'extra_airco',
    EXTRA_FLOOR_HEATING: 'extra_floor_heating',
    ACT_IDLE: 'actIdle',
    ACT_HEATING: 'actHeating',
    ACT_COOLING: 'actCooling',
    ACT_MODE_OFF: 'activeModeOff',
    ACT_MODE_HEATING: 'activeModeHeating',
    ACT_MODE_COOLING: 'activeModeCooling',
    FAN_SPEED_OFF: 'fan_off',
    FAN_SPEED_AUTO: 'fan_auto',
    FAN_SPEED_LOW: 'fan_low',
    FAN_SPEED_MEDIUM: 'fan_medium',
    FAN_SPEED_HIGH: 'fan_high',
    LOUVER_SWING: 'louver_swing',
    LOUVER_30_DEG: 'louver_30',
    LOUVER_45_DEG: 'louver_45',
    LOUVER_60_DEG: 'louver_60',
    LOUVER_HORIZONTAL: 'louver_horizontal',
    LOUVER_VERTICAL: 'louver_vertical',
    LOUVER_OFF: 'louver_off',
    ID_MODE_FILTER_ALL: 'modeFilterAll',
    ID_MODE_FILTER_ALL_NON_ANY: 'modeFilterAllNonAny',
    ID_MODE_FILTER_HEATING: 'modeFilterHeating',
    ID_MODE_FILTER_COOLING: 'modeFilterCooling',
    ID_MODE_FILTER_AUTO: 'modeFilterAuto',
    ID_MODE_FILTER_ANY: 'modeFilterAny',

    /**
     * @constant {Object<string, BasSchedulerFilter>}
     */
    MODE_FILTERS: {}
  })
