'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('timersCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'UI_HELPER',
    'BAS_ROOMS',
    'BasState',
    'RoomsHelper',
    'BasUiSortableHelper',
    'UiHelper',
    timersCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {UI_HELPER} UI_HELPER
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasState} BasState
 * @param {RoomsHelper} RoomsHelper
 * @param BasUiSortableHelper
 * @param {UiHelper} UiHelper
 */
function timersCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  UI_HELPER,
  BAS_ROOMS,
  BasState,
  RoomsHelper,
  BasUiSortableHelper,
  UiHelper
) {
  var timers = this

  var _listeners = []

  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  timers.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BasRooms}
   */
  timers.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasUiSortableHelper}
   */
  timers.basUiSortableHelper = new BasUiSortableHelper()
  timers.basUiSortableHelper.allowSwiping = false

  timers.selectItem = selectItem
  timers.onReorder = onReorder

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      UI_HELPER.EVT_RESIZE,
      _onUiSize
    ))
  }

  /**
   * @param {string} uuid
   */
  function selectItem (uuid) {

    BasState.go(
      STATES.TIMER_WEEK,
      {
        timer: uuid
      }
    )
  }

  function onReorder (spliceIndex, originalIndex) {

    var _timers

    timers.basUiSortableHelper.onReorder()

    _timers = _getHomeTimers()

    if (_timers) _timers.uiReorder(spliceIndex, originalIndex)
  }

  function _checkScreenWidth () {

    var currentTimerId

    currentTimerId = _getCurrentTimerId()

    if (!currentTimerId) _goToTimers()
  }

  /**
   * @private
   * @param {boolean} [goBack = false]
   */
  function _goToTimers (goBack) {

    var _timers

    _timers = _getHomeTimers()

    if (basUi.prop.wMedium &&
      _timers &&
      _timers.uiTimers &&
      _timers.uiTimers.length > 0) {

      BasState.go(
        STATES.TIMER_WEEK,
        {
          timer: _timers.uiTimers[0]
        }
      )

    } else if (goBack) {

      BasState.go(STATES.TIMERS)
    }
  }

  function _onUiSize () {

    _checkScreenWidth()
  }

  /**
   * @private
   * @returns {string}
   */
  function _getCurrentTimerId () {

    var timerId

    timerId = $uiRouterGlobals.params.timer

    return BasUtil.isNEString(timerId) ? timerId : ''
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }

  /**
   * @private
   * @returns {?BasRoomTimers}
   */
  function _getHomeTimers () {

    var room

    room = RoomsHelper.getHome()

    if (room && room.timers && room.timers.onOrderUpdated) {

      return room.timers
    }

    return null
  }
}
