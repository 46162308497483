'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasStoredServerSettings', [
    'BAS_APP_STORAGE',
    'BasStoredUser',
    'BasStorageHelper',
    basStoredServerSettingsFactory
  ])

/**
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @param BasStoredUser
 * @param {BasStorageHelper} BasStorageHelper
 * @returns BasStoredServerSettings
 */
function basStoredServerSettingsFactory (
  BAS_APP_STORAGE,
  BasStoredUser,
  BasStorageHelper
) {
  /**
   * @constructor
   */
  function BasStoredServerSettings () {

    /**
     * @type {string}
     */
    this.cid = ''

    /**
     * @type {Object<string, BasStoredUser>}
     */
    this.users = null

    /**
     * @type {string}
     */
    this.lastUser = ''

    /**
     * @type {boolean}
     */
    this.defaultRoomNotification = false

    /**
     * @private
     * @type {boolean}
     */
    this._defaultRoomNotificationWasPresent = false
  }

  /**
   * @param {string} idKey
   * @param {Object} obj
   * @returns {?BasStoredServerSettings}
   */
  BasStoredServerSettings.parse = function (idKey, obj) {

    var result, length, i, items, item, keys, key, parsed

    result = null

    if (BasUtil.isObject(obj)) {

      result = new BasStoredServerSettings()

      if (idKey.indexOf(BAS_APP_STORAGE.V_CID) === 0) {

        result.cid = idKey.substring(BAS_APP_STORAGE.V_CID.length)
      }

      if (BasUtil.isBool(
        obj[BAS_APP_STORAGE.K_DEFAULT_ROOMS_NOTIFICATION]
      )) {

        result.defaultRoomNotification =
          obj[BAS_APP_STORAGE.K_DEFAULT_ROOMS_NOTIFICATION]
        result._defaultRoomNotificationWasPresent = true
      }

      if (BasUtil.isObject(obj[BAS_APP_STORAGE.K_USERS])) {

        items = obj[BAS_APP_STORAGE.K_USERS]

        keys = Object.keys(items)
        length = keys.length
        for (i = 0; i < length; i++) {

          key = keys[i]
          item = items[key]

          if (BasUtil.isObject(item)) {

            /**
             * @type {?BasStoredUser}
             */
            parsed = BasStoredUser.parse(item)

            if (parsed) {

              // Make sure "users" exist
              if (!BasUtil.isObject(result.users)) {

                result.users = {}
              }

              result.users[parsed.name] = parsed
            }
          }
        }
      }

      if (BasUtil.isNEString(obj[BAS_APP_STORAGE.K_LAST_USER])) {

        result.lastUser = obj[BAS_APP_STORAGE.K_LAST_USER]
      }
    }

    return result
  }

  /**
   * @param {BasServer} basServer
   * @returns {boolean}
   */
  BasStoredServerSettings.prototype.parseBasServer = function (basServer) {

    var changed, type, matches, value, _user

    changed = false
    matches = false

    type = BasStorageHelper.getStoredServerType(basServer)

    if (type) {

      if (this.cid && basServer.cid && this.cid === basServer.cid) {

        // Matching CID

        matches = true

      } else if (!this.cid && !basServer.cid) {

        // Both have no CID, assume it is the same

        matches = true

      } else if (!this.cid && basServer.cid) {

        // This instance has no CID yet

        this.cid = basServer.cid

        matches = true
      }
    }

    if (matches) {

      value = basServer.credentials ? basServer.credentials[0] : null

      if (value) {

        if (this.users) {

          _user = this.users[value.user]

          if (_user) {

            if (_user.parseCoreCredentials(value)) {

              changed = true
            }

          } else {

            _user = new BasStoredUser(value)

            this.users[_user.name] = _user

            changed = true
          }

        } else {

          this.users = {}

          _user = new BasStoredUser(value)

          this.users[_user.name] = _user

          changed = true
        }
      }
    }

    return changed
  }

  /**
   * Returns whether the lastUser was changed or not.
   *
   * @param {string} key
   * @returns {boolean}
   */
  BasStoredServerSettings.prototype.saveLastUserKey = function (key) {

    if (BasUtil.isString(key) &&
      BasUtil.isObject(this.users) &&
      this.users[key]) {

      if (this.lastUser !== key) {

        this.lastUser = key

        return true
      }
    }

    return false
  }

  /**
   * @returns {?BasStoredUser}
   */
  BasStoredServerSettings.prototype.getLastUser = function () {

    var _lastUser

    if (BasUtil.isObject(this.users) &&
      BasUtil.isNEString(this.lastUser)) {

      _lastUser = this.users[this.lastUser]

      if (_lastUser) return _lastUser
    }

    return null
  }

  /**
   * @returns {?TBasConnectCredentials}
   */
  BasStoredServerSettings.prototype.getBasConnectCredentialsLastUser =
    function () {

      var _lastUser

      _lastUser = this.getLastUser()

      return _lastUser ? _lastUser.getBasConnectCredentials() : null
    }

  /**
   * Returns whether something has changed
   *
   * @returns {boolean}
   */
  BasStoredServerSettings.prototype.removeLoginCredentialsForLastUser =
    function () {

      var _lastUser

      _lastUser = this.getLastUser()

      return _lastUser ? _lastUser.removeLoginCredentials() : false
    }

  /**
   * Returns whether something has changed
   *
   * @param {string} roomId
   * @returns {boolean}
   */
  BasStoredServerSettings.prototype.saveLastRoomIdForLastUser =
    function (roomId) {

      var _changed, _lastUser

      _changed = false

      if (BasUtil.isString(roomId)) {

        _lastUser = this.getLastUser()

        if (_lastUser) {

          if (_lastUser.room !== roomId) {

            _lastUser.room = roomId
            _changed = true
          }
        }
      }

      return _changed
    }

  /**
   * @param {?Object} settings
   * @returns {boolean}
   */
  BasStoredServerSettings.prototype.saveSettingsForLastUser =
    function (settings) {

      var _changed, _lastUser

      _changed = false

      if (BasUtil.isObject(settings)) {

        _lastUser = this.getLastUser()

        if (_lastUser) {

          if (!BasUtil.isEqualObject(_lastUser.settings, settings)) {

            _lastUser.settings = BasUtil.copyObject(settings)
            _changed = true
          }
        }
      }

      return _changed
    }

  /**
   * @param {?Object} history
   * @returns {boolean}
   */
  BasStoredServerSettings.prototype.saveHistoryForLastUser =
    function (history) {

      var _changed, _lastUser

      _changed = false

      if (BasUtil.isObject(history)) {

        _lastUser = this.getLastUser()

        if (_lastUser) {

          if (!BasUtil.isEqualObject(_lastUser.history, history)) {

            _lastUser.history = history
            _changed = true
          }
        }
      }

      return _changed
    }

  /**
   * Create object used to store as JSON.
   *
   * @returns {?Object}
   */
  BasStoredServerSettings.prototype.toStorageDate = function () {

    var result, valid, items

    valid = false
    result = {}

    if (this._defaultRoomNotificationWasPresent ||
      this.defaultRoomNotification) {

      result[BAS_APP_STORAGE.K_DEFAULT_ROOMS_NOTIFICATION] =
        this.defaultRoomNotification

      valid = true
    }

    items = this._usersToStorageData()

    if (items) {

      result[BAS_APP_STORAGE.K_USERS] = items

      if (BasUtil.isNEString(this.lastUser) &&
        items[this.lastUser]) {

        result[BAS_APP_STORAGE.K_LAST_USER] = this.lastUser
      }

      valid = true
    }

    return valid ? result : null
  }

  /**
   * Get "storable" object variant for users.
   *
   * @private
   * @returns {?Object}
   */
  BasStoredServerSettings.prototype._usersToStorageData = function () {

    var result, length, i, keys, key, item, parsed

    result = null

    if (BasUtil.isObject(this.users)) {

      result = {}

      keys = Object.keys(this.users)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]
        item = this.users[key]

        if (item) {

          parsed = item.toStorageDate()
          if (parsed) result[key] = parsed
        }
      }
    }

    return result
  }

  return BasStoredServerSettings
}
