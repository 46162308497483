'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomEnergy', [
    'BAS_ENERGY',
    'CurrentBasCore',
    'BasEnergyMeter',
    basRoomEnergyFactory
  ])

/**
 * @param {BAS_ENERGY} BAS_ENERGY
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasEnergyMeter
 * @returns {BasRoomEnergy}
 */
function basRoomEnergyFactory (
  BAS_ENERGY,
  CurrentBasCore,
  BasEnergyMeter
) {

  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomEnergy (basRoom) {

    /**
     * @type {Object<string, BasEnergyMeter>}
     */
    this.energyMeters = {}

    /**
     * @type {string[]}
     */
    this.uiEnergyMeters = []

    /**
     * @type {string[]}
     */
    this.uiTypes = []

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomEnergy.hasEnergy = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.energy)
    )
  }

  BasRoomEnergy.prototype.uiHasEnergy = function () {

    return this.uiEnergyMeters.length > 0
  }

  /**
   * Parse the raw room from the parent BasRoom
   */
  BasRoomEnergy.prototype.parseRoom = function parseRoom () {

    if (BasRoomEnergy.hasEnergy(this._basRoom)) {

      this.syncEnergy()
    }
  }

  BasRoomEnergy.prototype.syncEnergy = function syncEnergy () {

    var length, i, uuid, device, meter, types

    this.clearEnergyMeters()
    this.uiEnergyMeters = []
    this.uiTypes = []

    types = {}

    if (BasRoomEnergy.hasEnergy(this._basRoom)) {

      length = this._basRoom.room.energy.length
      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.energy[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) {

          meter = new BasEnergyMeter(device)
          this.energyMeters[uuid] = meter
          this.uiEnergyMeters.push(uuid)

          types[meter.type] = true
        }
      }
    }

    // Specific order: energy, gas, water

    device = types[BAS_ENERGY.T_ID_ENERGY]
    if (device) this.uiTypes.push(BAS_ENERGY.T_ID_ENERGY)

    device = types[BAS_ENERGY.T_ID_GAS]
    if (device) this.uiTypes.push(BAS_ENERGY.T_ID_GAS)

    device = types[BAS_ENERGY.T_ID_WATER]
    if (device) this.uiTypes.push(BAS_ENERGY.T_ID_WATER)
  }

  BasRoomEnergy.prototype.updateTranslation = function () {

    var keys, length, i, uuid, dev

    keys = Object.keys(this.energyMeters)
    length = keys.length
    for (i = 0; i < length; i++) {
      uuid = keys[i]
      dev = this.energyMeters[uuid]

      if (dev && dev.updateTranslation) dev.updateTranslation()
    }
  }

  BasRoomEnergy.prototype.clearEnergyMeters = function () {

    var keys, length, i, uuid, dev

    keys = Object.keys(this.energyMeters)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      dev = this.energyMeters[uuid]

      if (dev && dev.clear) dev.clear()
    }

    this.energyMeters = {}
  }

  BasRoomEnergy.prototype.suspend = function suspend () {

  }

  BasRoomEnergy.prototype.destroy = function destroy () {

    this.clearEnergyMeters()
  }

  return BasRoomEnergy
}
