'use strict'

angular
  .module('basalteApp')
  .factory('DeezerElement', [
    'BAS_LIBRARY',
    'BAS_SOURCE',
    'BasLibraryElement',
    deezerElementFactory
  ])

/**
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param BasLibraryElement
 * @returns DeezerElement
 */
function deezerElementFactory (
  BAS_LIBRARY,
  BAS_SOURCE,
  BasLibraryElement
) {

  /**
   * Deezer Ui element class
   * contains all information needed for HTML templates
   *
   * @constructor
   * @extends BasLibraryElement
   * @param {Object} [deezerObject]
   */
  function DeezerElement (deezerObject) {

    // Call 'super' constructor
    BasLibraryElement.call(this)

    this.libraryType = BAS_LIBRARY.TYPE_DEEZER

    /**
     * Store reference to original Deezer object
     *
     * @instance
     * @type {?Object}
     */
    this.deezer = deezerObject

    // Type

    /**
     * @instance
     * @type {string}
     */
    this.type = DeezerElement.TYPE_TRACK

    /**
     * @instance
     * @type {string}
     */
    this.subType = ''

    /**
     * @instance
     * @type {number}
     */
    this.creatorId = -1

    /**
     * @instance
     * @type {string}
     */
    this.servicePrefix = BAS_SOURCE.URI_PREFIX_DEEZER
  }

  // Set new prototype from inherited object
  DeezerElement.prototype = Object.create(BasLibraryElement.prototype)

  // Set correct constructor after setting new prototype
  DeezerElement.prototype.constructor = DeezerElement

  // region Deezer types

  /**
   * @constant {string}
   */
  DeezerElement.TYPE_ALBUM = 'album'

  /**
   * @constant {string}
   */
  DeezerElement.TYPE_ARTIST = 'artist'

  /**
   * @constant {string}
   */
  DeezerElement.TYPE_EDITORIAL = 'editorial'

  /**
   * @constant {string}
   */
  DeezerElement.TYPE_GENRE = 'genre'

  /**
   * @constant {string}
   */
  DeezerElement.TYPE_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  DeezerElement.TYPE_RADIO = 'radio'

  /**
   * @constant {string}
   */
  DeezerElement.TYPE_TRACK = 'track'

  // Custom types

  /**
   * @constant {string}
   */
  DeezerElement.TYPE_SECTION = 'section'

  DeezerElement.prototype.getFavouriteId = function () {
    return this.type + ':' + this.id
  }

  // endregion

  return DeezerElement
}
