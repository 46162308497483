'use strict'

angular
  .module('basalteApp')
  .factory('BasMonitorDay', [
    'BAS_INTL',
    basMonitorDayFactory
  ])

/**
 * @param {BAS_INTL} BAS_INTL
 * @returns BasMonitorDay
 */
function basMonitorDayFactory (
  BAS_INTL
) {
  /**
   * @constructor
   * @param {Object} data
   */
  function BasMonitorDay (data) {

    /**
     * @type {number[]}
     */
    this.energy = []

    /**
     * @type {number[]}
     */
    this.water = []

    /**
     * Also uuid
     *
     * @type {?Date}
     */
    this.date = null

    /**
     * @type {?string}
     */
    this.day = ''

    /**
     * @type {?string}
     */
    this.month = ''

    /**
     * @type {?string}
     */
    this.year = ''

    /**
     * @type {Object<string, number>}
     */
    this.daily = {
      energy: 0,
      water: 0
    }

    /**
     * @type {Object<string, number>}
     */
    this.current = {
      energy: 0,
      water: 0
    }

    /**
     * @type {Object<string, string>}
     */
    this.unit = {
      energy: 'kWh',
      water: 'liters'
    }

    /**
     * @type {string}
     */
    this.dayName = ''

    /**
     * @type {string}
     */
    this.dayNameShort = ''

    /**
     * @type {string}
     */
    this.listName = ''

    this.uuid = ''

    this.parse(data)
  }

  /**
   * @constant {string}
   */
  BasMonitorDay.TYPE_ALL = 'all'

  /**
   * @constant {string}
   */
  BasMonitorDay.TYPE_ENERGY = 'energy'

  /**
   * @constant {string}
   */
  BasMonitorDay.TYPE_WATER = 'water'

  /**
   * @param {number[]} data
   * @returns {number}
   */
  BasMonitorDay.calculateAverage = function (data) {

    var average = 0
    var total = 0
    var i, length

    if (Array.isArray(data)) {

      length = data.length

      for (i = 0; i < length; i++) {

        total += data[i]
      }

      average = total / length
    }

    return average
  }

  /**
   * @param {number[]} data
   * @returns {number}
   */
  BasMonitorDay.calculateTotal = function (data) {

    var total = 0
    var i, length

    if (Array.isArray(data)) {

      length = data.length

      for (i = 0; i < length; i++) {

        total += data[i]
      }
    }

    return total
  }

  /**
   * @param {number[]} data
   * @returns {number}
   */
  BasMonitorDay.getCurrent = function (data) {

    var current = 0
    var length

    if (Array.isArray(data)) {

      length = data.length

      current = data[length - 1]
      current = Math.round(current * 100) / 100
    }

    return current
  }

  /**
   * @param {number} day
   * @returns {string}
   */
  BasMonitorDay.getDayName = function (day) {

    var _day

    _day = day >= 7 ? (day - 7) : day

    return BAS_INTL.DAYS_WEEK_TRANSLATED[BAS_INTL.DAYS_WEEK[_day]]
  }

  /**
   * @param {number} day
   * @returns {string}
   */
  BasMonitorDay.getDayNameShort = function (day) {

    var _day

    _day = day >= 7 ? (day - 7) : day

    return BAS_INTL.DAYS_WEEK_TRANSLATED_SHORT[BAS_INTL.DAYS_WEEK[_day]]
  }

  BasMonitorDay.makeListName = function (short, day, month) {

    return short + ' ' + day + '/' + month
  }

  BasMonitorDay.prototype.parse = function (data) {

    if (data.energy) this.energy = data.energy
    if (data.water) this.water = data.water
    if (data.date) {

      this.uuid = data.date
      this.parseDate(data.date)
    }

    this.daily.energy =
      Math.round(BasMonitorDay.calculateTotal(this.energy))
    this.daily.water =
      Math.round(BasMonitorDay.calculateTotal(this.water))

    this.updateTranslation()

    this.current.energy = BasMonitorDay.getCurrent(this.energy)
    this.current.water = BasMonitorDay.getCurrent(this.water)
  }

  /**
   * @param {string} date
   * @returns {?Date}
   */
  BasMonitorDay.prototype.parseDate = function (date) {

    var split = date.split('/')

    if (split.length !== 3) return null

    this.day = split[0]
    this.month = split[1]
    this.year = split[2]
    this.date = new Date(this.year + '/' + this.month + '/' + this.day)
  }

  BasMonitorDay.prototype.updateTranslation = function () {

    this.dayName = BasMonitorDay.getDayName(this.date.getDay())
    this.dayNameShort = BasMonitorDay.getDayNameShort(this.date.getDay())

    this.listName =
      BasMonitorDay.makeListName(this.dayNameShort, this.day, this.month)
  }

  return BasMonitorDay
}
