'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('nowPlayingCtrl', [
    '$rootScope',
    '$scope',
    '$timeout',
    '$uiRouterGlobals',
    'ModalService',
    'UI_HELPER',
    'BAS_SOURCE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_HTML',
    'BAS_MODAL',
    'BAS_INPUT_MODAL',
    'BAS_PLAYER_DRAWER',
    'Sources',
    'CurrentRoom',
    'BasMusicHelper',
    'BasUiQueue',
    'UiHelper',
    'modalHelperService',
    'AppLink',
    'BasModal',
    'BasInputModal',
    'BasAppDevice',
    'BasUtilities',
    playerCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $timeout
 * @param $uiRouterGlobals
 * @param ModalService
 * @param {UI_HELPER} UI_HELPER
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param {BAS_PLAYER_DRAWER} BAS_PLAYER_DRAWER
 * @param {Sources} Sources
 * @param {CurrentRoom} CurrentRoom
 * @param {BasMusicHelper} BasMusicHelper
 * @param {BasUiQueue} BasUiQueue
 * @param {UiHelper} UiHelper
 * @param modalHelperService
 * @param {AppLink} AppLink
 * @param {BasModal} BasModal
 * @param {BasInputModal} BasInputModal
 * @param {BasAppDevice} BasAppDevice
 * @param {BasUtilities} BasUtilities
 */
function playerCtrl (
  $rootScope,
  $scope,
  $timeout,
  $uiRouterGlobals,
  ModalService,
  UI_HELPER,
  BAS_SOURCE,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_HTML,
  BAS_MODAL,
  BAS_INPUT_MODAL,
  BAS_PLAYER_DRAWER,
  Sources,
  CurrentRoom,
  BasMusicHelper,
  BasUiQueue,
  UiHelper,
  modalHelperService,
  AppLink,
  BasModal,
  BasInputModal,
  BasAppDevice,
  BasUtilities
) {
  var player = this

  var OPEN_MODAL_TIMEOUT = 200

  var currentSourceId = -1
  var timeoutPromise
  var openModalTimeout = 0
  var listeners = []

  /**
   * @type {UI_HELPER}
   */
  player.UI_HELPER = UI_HELPER

  /**
   * @type {BAS_ROOM}
   */
  player.BAS_ROOM = BAS_ROOM

  /**
   * @type {BasRooms}
   */
  player.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasUi}
   */
  player.basUi = UiHelper.get()

  /**
   * @type {TBasUiQueue}
   */
  player.basUiQueue = BasUiQueue.get()

  /**
   * @type {TCurrentRoomState}
   */
  player.currentRoom = CurrentRoom.get()

  /**
   * @type {BasImageTrans}
   */
  player.musicMainBit = BasMusicHelper.musicMainBit

  // Scrub variables
  player.enableScrub = false
  player.scrubOffset = 0
  player.scrubWidth = 0
  player.scrubPercentage = 0

  player.allowTransition = false

  player.coverArtClick = coverArtClick
  player.coverArtLeftSwipe = coverArtLeftSwipe
  player.coverArtRightSwipe = coverArtRightSwipe
  player.showContextModal = showContextModal
  player.showGroupModal = showGroupModal
  player.openContextApp = openContextApp
  player.scrubChange = scrubChange

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SOURCE_CHANGED,
      _onSourceChanged
    ))

    listeners.push($rootScope.$on(
      BAS_PLAYER_DRAWER.EVT_VISIBILITY_CHANGED,
      _onQueueVisibilityChanged
    ))

    listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_STATE_CHANGE,
      _onBasSourceStateUpdated
    ))

    timeoutPromise = $timeout(onTimeout, 200)
    _handleSourceChanged()
  }

  /**
   * @private
   * @param _event
   * @param {string} arg Room UUID
   */
  function _onSourceChanged (_event, arg) {

    // TODO Get room via directive binding?

    if (arg === $uiRouterGlobals.params.room) {

      _handleSourceChanged()
    }
  }

  function _handleSourceChanged () {

    var basSource

    UiHelper.determineMainSectionDimensions()
    BasUtilities.waitForFrames(
      10,
      _onWaitFrames
    )

    _unregister()

    basSource = CurrentRoom.getBasSource()

    if (basSource) {

      currentSourceId = basSource.getId()

      Sources.registerFor(BAS_SOURCE.COL_EVT_PLAYER, currentSourceId)
    }
  }

  function _onWaitFrames () {

    UiHelper.determineMainSectionDimensions()
  }

  function scrubChange (percentage) {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (basSource && basSource.state) {

      basSource.updatePosition(percentage)
      basSource.state.positionPercentage = percentage
    }
  }

  function onTimeout () {

    player.allowTransition = true

    focusCurrentQueueTrack()
  }

  function showContextModal (event) {

    ModalService.showModal({
      template: BAS_HTML.contextModal,
      controller: 'contextModalCtrl',
      controllerAs: 'modal',
      inputs: {
        event: event
      }
    }).then(_onContextModal)
  }

  function showGroupModal () {

    BasInputModal.show(
      BAS_INPUT_MODAL.T_AVROOM_SELECT,
      {
        roomId: player.currentRoom.roomId
      }
    )
  }

  function _onContextModal (modal) {

    // Modal is ready execute onModalReady
    if (modal && modal.controller &&
      BasUtil.isFunction(modal.controller.onModalReady)) {

      modal.controller.onModalReady()
    }

    modal.closed.then(_onContextModalClosed)
  }

  function _onContextModalClosed () {

    // Reset modal height
    modalHelperService.resetModalStyle()
  }

  function coverArtClick () {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (basSource) {

      if (basSource.isPlayingSpotify) {

        if (!BasAppDevice.isCoreClient()) {

          clearTimeout(openModalTimeout)
          openModalTimeout = setTimeout(
            _onOpenModalTimeout,
            OPEN_MODAL_TIMEOUT
          )
        }

      } else {

        if (basSource.togglePlay) basSource.togglePlay()
      }
    }
  }

  function _onOpenModalTimeout () {

    BasModal.show(BAS_MODAL.T_OPEN_SPOTIFY).then(_onOpenModalShown)
  }

  function _onOpenModalShown (modal) {

    modal.close.then(_onOpenModalClose)
  }

  function _onOpenModalClose (result) {

    if (result === BAS_MODAL.C_YES) {

      AppLink.openApp(AppLink.APP_SPOTIFY)
    }
  }

  function coverArtLeftSwipe () {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (basSource && basSource.next) basSource.next()
  }

  function coverArtRightSwipe () {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (basSource && basSource.previous) basSource.previous()
  }

  /**
   * @param {string} [appName]
   */
  function openContextApp (appName) {

    var basSource

    if (BasUtil.isNEString(appName)) {

      AppLink.openApp(appName)

    } else {

      basSource = CurrentRoom.getBasSource()

      if (basSource) {

        if (basSource.type === BAS_SOURCE.T_BARP &&
          basSource.subType === BAS_SOURCE.ST_SPOTIFY) {

          AppLink.openApp(AppLink.APP_SPOTIFY)
        }
      }
    }
  }

  function _onQueueVisibilityChanged (_evt, visible) {

    if (visible) {

      focusCurrentQueueTrack()
    }
  }

  function _onBasSourceStateUpdated (_evt, basSourceId) {

    var basSource

    basSource = CurrentRoom.getBasSource()

    if (basSource && basSource.getId() === basSourceId) {

      $scope.$applyAsync()
    }
  }

  /**
   * @returns {Promise}
   */
  function focusCurrentQueueTrack () {

    var basSource
    basSource = CurrentRoom.getBasSource()

    return findIdOrRetrieveMore(true)

    /**
     * @param {boolean} retrieveMoreIfNeeded
     * @returns {Promise}
     */
    function findIdOrRetrieveMore (retrieveMoreIfNeeded) {

      var i, length

      if (
        basSource &&
        basSource.queue &&
        basSource.queue.queue &&
        BasUtil.isPNumber(basSource.state.currentId, true)
      ) {

        length = basSource.queue.queue.length

        for (i = 0; i < length; i++) {

          if (basSource.queue.queue[i].id === basSource.state.currentId) {

            // Current queue element found, make sure UI is updated so the
            //  element is visible, then scroll the that element.
            $scope.$applyAsync()
            return BasUtilities.waitFrames(2)
              .then(scrollToCurrentTrack)
          }
        }

        if (retrieveMoreIfNeeded) {

          // 'retrievePaginated' promise will return a value based on whether
          //  new tracks were loaded into the queue or not. This value is then
          //  passed as 'retrieveMoreIfNeeded' parameter to the
          //  'findIdOrRetrieveMore' function -> If no more tracks are loaded
          //  no further retrievePaginated calls will happen (no infinite loop)
          return basSource.queue.retrievePaginated()
            .then(findIdOrRetrieveMore, _empty)
        }
      }

      return Promise.resolve()
    }

    function scrollToCurrentTrack () {

      var elements = document.getElementsByClassName('bmsdi-playing')

      if (elements[0]) {

        elements[0].scrollIntoView(true)
      }
    }

    function _empty () {
      // Empty
    }
  }

  function _unregister () {

    if (
      BasUtil.isPNumber(currentSourceId) ||
      BasUtil.isNEString(currentSourceId)
    ) {

      Sources.unregisterFor(BAS_SOURCE.COL_EVT_PLAYER, currentSourceId)
      currentSourceId = -1
    }
  }

  function _onDestroy () {

    var basSource

    basSource = CurrentRoom.getBasSource()

    clearTimeout(openModalTimeout)

    if (timeoutPromise) $timeout.cancel(timeoutPromise)

    BasUtil.executeArray(listeners)
    listeners = []

    _unregister()

    if (basSource && basSource.state) {

      basSource.state.setPlayPauseShow(false)
    }
  }
}
