'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('basScenesSceneCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    '$transitions',
    'SCENE_STATES',
    'BAS_MODAL',
    'BAS_ROOM',
    'BAS_SCENE',
    'BAS_ROOMS',
    'BAS_SCENE_PRESETS',
    'CurrentBasCore',
    'BasAppDevice',
    'BasState',
    'BasSceneStateHelper',
    'BasSceneHeader',
    'CurrentRoom',
    'BasModal',
    'BasSceneHelper',
    'BasScenePresetsHelper',
    'BasString',
    basScenesSceneCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param $transitions
 * @param {SCENE_STATES} SCENE_STATES
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SCENE} BAS_SCENE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SCENE_PRESETS} BAS_SCENE_PRESETS
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasAppDevice} BasAppDevice
 * @param {BasState} BasState
 * @param {BasSceneStateHelper} BasSceneStateHelper
 * @param BasSceneHeader
 * @param {CurrentRoom} CurrentRoom
 * @param {BasModal} BasModal
 * @param {BasSceneHelper} BasSceneHelper
 * @param {BasScenePresetsHelper} BasScenePresetsHelper
 * @param BasString
 */
function basScenesSceneCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  $transitions,
  SCENE_STATES,
  BAS_MODAL,
  BAS_ROOM,
  BAS_SCENE,
  BAS_ROOMS,
  BAS_SCENE_PRESETS,
  CurrentBasCore,
  BasAppDevice,
  BasState,
  BasSceneStateHelper,
  BasSceneHeader,
  CurrentRoom,
  BasModal,
  BasSceneHelper,
  BasScenePresetsHelper,
  BasString
) {
  var scene = this

  var _SYNC_UI_DEBOUNCE_MS = 50

  var _listeners = []
  var _sceneDataString
  var _syncUiTimeoutId = 0

  /**
   * @type {BAS_SCENE_PRESETS}
   */
  scene.BAS_SCENE_PRESETS = BAS_SCENE_PRESETS

  /**
   * @type {BAS_SCENE}
   */
  scene.BAS_SCENE = BAS_SCENE

  /**
   * @type {BasRooms}
   */
  scene.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentBasCoreState}
   */
  scene.currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasAppDeviceState}
   */
  scene.basAppDeviceState = BasAppDevice.get()

  /**
   * @type {TBasStateObj}
   */
  scene.basState = BasState.get()

  /**
   * @type {BasSceneHeader}
   */
  scene.header = new BasSceneHeader($scope)

  scene.editState = false
  scene.timeState = false

  /**
   * @type {string}
   */
  scene.name = ''

  /**
   * @type {string}
   */
  scene.selectedRoom = ''

  /**
   * @type {string}
   */
  scene.selectedProperty = ''

  /**
   * @type {string}
   */
  scene.currentStepId = ''

  scene.stepDelay = 0

  scene.$uiRouterGlobals = $uiRouterGlobals

  // noinspection JSUnusedGlobalSymbols
  /**
   * Angular UI-router callback
   */
  this.uiOnParamsChanged = _onParamsChanged

  scene.selectHeader = selectHeader
  scene.selectChangeCustomImage = selectChangeCustomImage
  scene.newScene = newScene
  scene.selectScenePreset = selectScenePreset
  scene.toggleEdit = toggleEdit
  scene.favourite = favourite
  scene.titleChange = titleChange
  scene.activate = activate
  scene.remove = remove
  scene.learn = learn
  scene.newStep = newStep
  scene.newStepEdit = newStepEdit
  scene.newTimer = newTimer
  scene.reorderSteps = reorderSteps
  scene.removeStep = removeStep
  scene.beforeWait = beforeWait
  scene.selectStep = selectStep
  scene.selectRoom = selectRoom
  scene.selectFunction = selectFunction
  scene.setTimeState = setTimeState

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _setName()
    _syncUi()
    _updateSceneDataString()

    // TODO request favorites for step summary, waiting on API changes

    scene.header.resume()

    // Check for unsaved changes
    _listeners.push($transitions.onStart(
      {},
      _onStateChange
    ))
    // TODO Listen for room changes, go to scene start state
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SCENES_INITIALIZED,
      _onScenesInitialized
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SCENE_STEP_DEVICES_ADDED,
      _onSceneStepDevicesAdded
    ))
    _listeners.push($rootScope.$on(
      BAS_SCENE.EVT_SCENE_UPDATED,
      _onScenesUpdated
    ))
  }

  function selectHeader () {

    CurrentRoom.go(BasSceneStateHelper.getSceneState(
      SCENE_STATES.SCENE_PRESETS
    ))
  }

  function newScene () {

    var _scenes

    _scenes = BasSceneHelper.getScenes($scope)

    if (_scenes) _scenes.addNewScene()
  }

  /**
   * Handles selecting a scene preset.
   * In case of the custom preset being selected,
   * reroute the user to the preset image selector.
   *
   * @param {string} scenePreset
   */
  function selectScenePreset (scenePreset) {

    var _scene, _preset

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene) {

      _preset = BasScenePresetsHelper.getPreset(scenePreset)

      if (_preset) _scene.setScenePreset(_preset)

      CurrentRoom.go(
        BasSceneStateHelper.getSceneState(SCENE_STATES.SCENE),
        {
          scene: _scene.uuid
        }
      )
    }
  }

  /**
   * Handles selecting the change custom preset image.
   */
  function selectChangeCustomImage () {

    var _scene

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene) {

      CurrentRoom.go(
        BasSceneStateHelper.getSceneState(SCENE_STATES.SCENE_PICTURE)
      )
    }
  }

  /**
   * @param {boolean} [force]
   */
  function toggleEdit (force) {

    scene.editState = BasUtil.isBool(force) ? force : !scene.editState

    if (scene.editState) {

      _setName()
      _updateSceneDataString()

    } else {

      titleChange()
      _sendSteps()
    }
  }

  function favourite () {

    var _scenes, _sceneId, _room

    _room = BasSceneHelper.getRoom($scope)

    if (_room) {

      _scenes = _room.scenes

      if (_scenes) {

        _sceneId = BasSceneHelper.getSceneId($uiRouterGlobals)

        if (_sceneId) {

          _scenes.toggleSceneFavourite(_sceneId)
        }
      }
    }
  }

  function titleChange () {

    var _scene

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene && _scene.name !== scene.name) {

      if (_scene.template.isCustom()) {

        _scene.setName(scene.name)
      }
    }
  }

  function activate () {

    var _scenes, _scene

    _scenes = BasSceneHelper.getScenes($scope)

    if (_scenes) {

      _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

      if (_scene) _scenes.activateScene(_scene.uuid)
    }
  }

  function remove () {

    var _scene

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene) {

      // Show confirmation modal
      BasModal.show(BAS_MODAL.T_REMOVE).then(_onSceneRemoveModal)
    }

    function _onSceneRemoveModal (modal) {

      // Set close Promise
      modal.close.then(_onSceneRemoveModalClose)
    }

    function _onSceneRemoveModalClose (result) {

      var _scenes

      if (result === BAS_MODAL.C_YES) {

        if (_scene) {

          _scenes = BasSceneHelper.getScenes($scope)

          if (_scenes) {

            _scenes.removeScene(_scene.uuid)
          }
        }

        scene.editState = false
      }
    }
  }

  function learn () {

    var _scene

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene && _scene.canLearn()) {

      _learnScene(_scene)
    }
  }

  /**
   * Opens a confirmation modal to learn/update the scene state
   *
   * @param {BasScene} basScene
   */
  function _learnScene (basScene) {

    if (BasUtil.isObject(basScene)) {

      showModal()
    }

    function showModal () {
      BasModal.show(
        BAS_MODAL.T_SAVE_LEARN_SCENE,
        {
          subtitle: new BasString({ literal: basScene.name })
        }
      ).then(_onLearnSceneModal)
    }

    function _onLearnSceneModal (modal) {

      modal.close.then(_onLearnSceneModalClose)
    }

    function _onLearnSceneModalClose (result) {

      var _room

      if (result === BAS_MODAL.C_YES &&
        BasUtil.isNEString(basScene.uuid)) {

        _room = BasSceneHelper.getRoom($scope)

        if (_room && _room.scenes) {

          _room.scenes.learnScene(basScene.uuid)
        }
      }
    }
  }

  function newStep () {

    _newStep()
  }

  function newStepEdit () {

    this.toggleEdit(true)
    _newStep()
  }

  function _newStep () {

    var _room, _scene, _step

    _room = BasSceneHelper.getRoom($scope)

    if (_room) {

      _scene = BasSceneHelper.getScene(_room, $uiRouterGlobals)

      if (_scene) {

        _step = _scene.createNewStep()

        if (_step) {

          // Room scene
          // Add the current room as the room for the step
          if (_room.isRoom()) _step.room = _room.id

          selectStep(_step.uuid)
        }
      }
    }
  }

  function newTimer () {

    var _scene

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene) _scene.createNewTimer()
  }

  /**
   * @param {number} spliceIdx
   * @param {number} originalIdx
   */
  function reorderSteps (spliceIdx, originalIdx) {

    var _scene

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene) _scene.reorderSteps(spliceIdx, originalIdx)
  }

  /**
   * @param {(BasStep|number|string)} step
   */
  function removeStep (step) {

    var _scene, _step, _stepId

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene) {

      if (BasUtil.isObject(step) && step.makeDataString) {

        _step = step

      } else if (BasUtil.isPNumber(step, true)) {

        _stepId = _scene.uiSteps[step]
        _step = _scene.getBasStep(_stepId)

      } else if (BasUtil.isNEString(step)) {

        _step = _scene.getBasStep(step)
      }

      if (_step) _scene.deleteStep(_step.uuid)
    }
  }

  function beforeWait (event) {
    if (
      event &&
      event.target &&
      event.target.classList &&
      event.target.classList.contains &&
      event.target.classList.contains('instant') &&
      event.preventDefault
    ) {
      event.preventDefault()
    }
  }

  /**
   * @param {string} stepId
   */
  function selectStep (stepId) {

    var _room, _scene, _step, stateOpts

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (scene.editState &&
      _scene &&
      _scene.canWriteContent()) {

      titleChange()
      _step = _scene.getBasStep(stepId)

      if (_step) {

        if (_step.isValid()) {

          stateOpts = {
            step: _step.uuid
          }

          switch (_step.function) {
            case BAS_ROOMS.FUNCTIONS.LIGHTS:

              CurrentRoom.go(
                BasSceneStateHelper.getSceneState(
                  SCENE_STATES.SCENE_STEP_LIGHTS
                ),
                stateOpts
              )

              break
            case BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES:

              CurrentRoom.go(
                BasSceneStateHelper.getSceneState(
                  SCENE_STATES.SCENE_STEP_DEVICES
                ),
                stateOpts
              )

              break
            case BAS_ROOMS.FUNCTIONS.SHADES:

              CurrentRoom.go(
                BasSceneStateHelper.getSceneState(
                  SCENE_STATES.SCENE_STEP_SHADES
                ),
                stateOpts
              )

              break
            case BAS_ROOMS.FUNCTIONS.THERMOSTAT:
              if (_step.thermostat?.uuid) {
                stateOpts.thermostat = _step.thermostat.uuid
                CurrentRoom.go(
                  BasSceneStateHelper.getSceneState(
                    SCENE_STATES.SCENE_STEP_THERMOSTAT
                  ),
                  stateOpts
                )
              } else {
                CurrentRoom.go(
                  BasSceneStateHelper.getSceneState(
                    SCENE_STATES.SCENE_STEP_THERMOSTAT_PICKER
                  ),
                  stateOpts
                )
              }

              break
            case BAS_ROOMS.FUNCTIONS.MUSIC:

              CurrentRoom.go(
                BasSceneStateHelper.getSceneState(
                  SCENE_STATES.SCENE_STEP_MUSIC
                ),
                stateOpts
              )

              break
            case BAS_ROOMS.FUNCTIONS.VIDEO:

              CurrentRoom.go(
                BasSceneStateHelper.getSceneState(
                  SCENE_STATES.SCENE_STEP_VIDEO
                ),
                stateOpts
              )

              break
            case BAS_ROOMS.FUNCTIONS.SCENES:

              CurrentRoom.go(
                BasSceneStateHelper.getSceneState(
                  SCENE_STATES.SCENE_STEP_SCENES
                ),
                stateOpts
              )

              break
          }

        } else if (_step.delay < 0) {

          scene.currentStepId = _step.uuid

          _room = BasSceneHelper.getRoom($scope)

          if (_room) {

            if (_room.isHome()) {

              if (_step.room) {

                CurrentRoom.go(BasSceneStateHelper.getSceneState(
                  SCENE_STATES.SCENE_ROOM_FUNCTIONS
                ))

              } else {

                CurrentRoom.go(BasSceneStateHelper.getSceneState(
                  SCENE_STATES.SCENE_ROOMS
                ))
              }

            } else if (_room.isRoom()) {

              if (_step.room) {

                CurrentRoom.go(BasSceneStateHelper.getSceneState(
                  SCENE_STATES.SCENE_FUNCTIONS
                ))
              }
            }
          }
        }
      }
    }
  }

  /**
   * @param {string} roomId
   */
  function selectRoom (roomId) {

    var _room, _scene, _step

    _room = BasSceneHelper.getRoom($scope)

    if (_room) {

      _scene = BasSceneHelper.getScene(_room, $uiRouterGlobals)

      if (_scene) {

        _step = _getCurrentStep()

        if (BasUtil.isObject(scene.rooms.rooms[roomId]) && _step) {

          _step.room = roomId

          CurrentRoom.go(BasSceneStateHelper.getSceneState(
            SCENE_STATES.SCENE_ROOM_FUNCTIONS
          ))

        } else {

          scene.selectedRoom = ''
        }
      }
    }
  }

  /**
   * @param {BasTile} item
   */
  function selectFunction (item) {

    var _step, _thermostats

    _step = _getCurrentStep()

    if (BasUtil.isObject(item) && _step) {

      switch (item.id) {
        case BAS_ROOMS.FUNCTIONS.LIGHTS:

          _step.setFunction(BAS_ROOMS.FUNCTIONS.LIGHTS)

          CurrentRoom.go(
            BasSceneStateHelper.getSceneState(
              SCENE_STATES.SCENE_STEP_LIGHTS
            ),
            {
              step: _step.uuid
            }
          )

          break
        case BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES:

          _step.setFunction(BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES)

          CurrentRoom.go(
            BasSceneStateHelper.getSceneState(
              SCENE_STATES.SCENE_STEP_DEVICES
            ),
            {
              step: _step.uuid
            }
          )

          break
        case BAS_ROOMS.FUNCTIONS.SHADES:

          _step.setFunction(BAS_ROOMS.FUNCTIONS.SHADES)

          CurrentRoom.go(
            BasSceneStateHelper.getSceneState(
              SCENE_STATES.SCENE_STEP_SHADES
            ),
            {
              step: _step.uuid
            }
          )

          break
        case BAS_ROOMS.FUNCTIONS.THERMOSTAT:
          _thermostats =
            BAS_ROOMS.ROOMS.rooms[_step.room].thermostats.thermostats

          _step.setFunction(BAS_ROOMS.FUNCTIONS.THERMOSTAT)

          if (_thermostats && _thermostats.length === 1) {
            CurrentRoom.go(
              BasSceneStateHelper.getSceneState(
                SCENE_STATES.SCENE_STEP_THERMOSTAT
              ),
              {
                step: _step.uuid,
                thermostat: _thermostats[0].uuid
              }
            )
          } else {
            CurrentRoom.go(
              BasSceneStateHelper.getSceneState(
                SCENE_STATES.SCENE_STEP_THERMOSTAT_PICKER
              ),
              {
                step: _step.uuid
              }
            )
          }

          break
        case BAS_ROOMS.FUNCTIONS.MUSIC:

          _step.setFunction(BAS_ROOMS.FUNCTIONS.MUSIC)

          CurrentRoom.go(
            BasSceneStateHelper.getSceneState(
              SCENE_STATES.SCENE_STEP_MUSIC
            ),
            {
              step: _step.uuid
            }
          )

          break
        case BAS_ROOMS.FUNCTIONS.VIDEO:

          _step.setFunction(BAS_ROOMS.FUNCTIONS.VIDEO)

          CurrentRoom.go(
            BasSceneStateHelper.getSceneState(
              SCENE_STATES.SCENE_STEP_VIDEO
            ),
            {
              step: _step.uuid
            }
          )

          break
        case BAS_ROOMS.FUNCTIONS.SCENES:

          _step.setFunction(BAS_ROOMS.FUNCTIONS.SCENES)

          CurrentRoom.go(
            BasSceneStateHelper.getSceneState(
              SCENE_STATES.SCENE_STEP_SCENES
            ),
            {
              step: _step.uuid
            }
          )

          break
      }
    }
  }

  /**
   * @param {boolean} bool
   */
  function setTimeState (bool) {

    scene.timeState = bool
    $scope.$applyAsync()
  }

  function _onParamsChanged () {

    _syncUi()
    _setName()
    _updateSceneDataString()
  }

  function _onStateChange (transition) {

    var to, toName, params, _scene, _ssScene

    to = transition.to()
    toName = to.name
    params = transition.params()

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    _ssScene = BasSceneStateHelper.getSceneState(SCENE_STATES.SCENE)

    titleChange()

    if (scene.editState &&
      (
        toName.indexOf(_ssScene) === -1 ||
        (
          toName === _ssScene &&
          params.scene !== $uiRouterGlobals.params.scene
        )
      )) {

      if (_scene && _scene.makeDataString() !== _sceneDataString) {

        return BasModal.show(BAS_MODAL.T_UNSAVED_CHANGES)
          .then(_onSaveModal)

      } else {

        toggleEdit(false)
      }
    }
  }

  function _onSaveModal (modal) {

    return modal.close.then(_onSaveModalClose)
  }

  function _onSaveModalClose (result) {

    var _scene

    if (result === BAS_MODAL.C_YES) {

      toggleEdit(false)

    } else if (result === BAS_MODAL.C_NO) {

      _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

      if (_scene) {

        _scene.resetScene()
        _setName()
      }

      toggleEdit(false)

    } else {

      return Promise.reject(new Error('invalid modal result'))
    }
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasRoom} room
   */
  function _onScenesInitialized (_event, room) {

    var _room

    _room = BasSceneHelper.getRoom($scope)

    if (_room && _room.id === room.id) {

      _setName()
      _goToSceneStartState()
    }
  }

  function _onSceneStepDevicesAdded () {

    _debounceSyncUi()
  }

  function _onScenesUpdated () {

    _debounceSyncUi()
  }

  function _debounceSyncUi () {

    clearTimeout(_syncUiTimeoutId)

    _syncUiTimeoutId = setTimeout(
      _syncUiWithScopeSync,
      _SYNC_UI_DEBOUNCE_MS
    )
  }

  function _syncUiWithScopeSync () {

    _syncUi()
    $scope.$applyAsync()
  }

  function _syncUi () {

    var _scene

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)
    if (_scene) _scene.syncStepsUi()
  }

  function _setName () {

    var _scene

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)
    if (_scene) scene.name = _scene.name
  }

  function _updateSceneDataString () {

    var _scene

    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene) _sceneDataString = _scene.makeDataString()
  }

  function _sendSteps () {

    var _scene, _scenes, _imageChanged, _image, _oldState

    _oldState = BasSceneHelper.copyState($uiRouterGlobals)
    _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

    if (_scene) {

      _scene.removeUselessSteps()
      _scene.mergeDelays()
      _scene.commit()

      _scenes = BasSceneHelper.getScenes($scope)

      if (_scenes) {

        _imageChanged = BasUtil.isString(_scene.unsavedImage)
        _image = _scene.unsavedImage

        _scenes.updateScene(_scene)
          .then(_onUpdateSuccess, _onUpdateSceneError)
      }
    }

    function _onUpdateSuccess () {

      _scene = BasSceneHelper.getScene($scope, _oldState)

      if (_scene && _imageChanged) {

        _scenes.updateImage(_scene.uuid, _image)
          .catch(_onUpdateSceneImageError)
      }
    }

    function _onUpdateSceneImageError () {

      BasModal.show(BAS_MODAL.T_SCENE_IMAGE_ERROR)

      _scene = BasSceneHelper.getScene($scope, _oldState)

      if (_scene) _scene.resetSceneServer()
    }

    function _onUpdateSceneError () {

      BasModal.show(BAS_MODAL.T_SCENE_ERROR)

      _scene = BasSceneHelper.getScene($scope, _oldState)

      if (_scene) _scene.resetSceneServer()
    }
  }

  function _goToSceneStartState () {

    CurrentRoom.go(BasSceneStateHelper.getSceneState(
      SCENE_STATES.SCENE
    ))
  }

  /**
   * @returns {?BasStep}
   */
  function _getCurrentStep () {

    var _scene, _step

    if (BasUtil.isNEString(scene.currentStepId)) {

      _scene = BasSceneHelper.getScene($scope, $uiRouterGlobals)

      if (_scene) {

        _step = _scene.steps[scene.currentStepId]

        return _step || null
      }
    }

    return null
  }

  function _onDestroy () {

    clearTimeout(_syncUiTimeoutId)

    BasUtil.executeArray(_listeners)
    _listeners = []

    scene.header.destroy()
  }
}
