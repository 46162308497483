'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basRoomName', basRoomNameDirective)

function basRoomNameDirective () {

  return {
    restrict: 'AE',
    scope: {},
    controller: [
      '$rootScope',
      '$element',
      'BasUtilities',
      controller
    ],
    controllerAs: 'basRoomNameCtrl',
    bindToController: {
      basRoomName: '<?',
      basRoomNameAlt: '@?'
    }
  }

  /**
   * @param $rootScope
   * @param $element
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    $element,
    BasUtilities
  ) {
    var basRoomNameCtrl = this

    var _listeners = []

    basRoomNameCtrl.$onInit = _onInit
    basRoomNameCtrl.$onChanges = _onChanges
    basRoomNameCtrl.$onDestroy = _onDestroy

    function _onInit () {

      _listeners.push($rootScope.$on('$translateChangeSuccess', _onTranslate))
    }

    function _onChanges () {

      _syncCurrentRoomName()
    }

    function _onTranslate () {

      _syncCurrentRoomName()
    }

    function _syncCurrentRoomName () {

      var room

      room = _getRoom()

      $element[0].textContent = room
        ? room.uiTitle
        : BasUtilities.translate(
          BasUtil.isString(basRoomNameCtrl.basRoomNameAlt)
            ? basRoomNameCtrl.basRoomNameAlt
            : '-'
        )
    }

    /**
     * @private
     * @returns {?BasRoom}
     */
    function _getRoom () {
      return (basRoomNameCtrl.basRoomName && basRoomNameCtrl.basRoomName.isRoom)
        ? basRoomNameCtrl.basRoomName
        : null
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
