'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasMusicWidgetService', [
    '$rootScope',
    'BAS_MUSIC_WIDGET',
    BasMusicWidgetService
  ])

/**
 * @typedef {Object} TBasMusicWidgetState
 * @property {string} backContextTitleId
 * @property {Object<string, boolean>} css
 */

/**
 * Service for keeping state of bas music widget
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_MUSIC_WIDGET} BAS_MUSIC_WIDGET
 */
function BasMusicWidgetService (
  $rootScope,
  BAS_MUSIC_WIDGET
) {

  /**
   * @constant {boolean}
   */
  var CSS_BTM_NOW_PLAYING = 'btm-state-now-playing'

  /**
   * @constant {boolean}
   */
  var CSS_BTM_SHOW_BUTTON = 'btm-show-button'

  /**
   * @constant {boolean}
   */
  var CSS_SHOW_STREAMS = 'btm-streams--show'

  /**
   * @private
   * @type {TBasMusicWidgetState}
   */
  var state = {
    css: {},
    backContextTitleId: ''
  }

  this.get = get
  this.toggleNowPlaying = toggleNowPlaying
  this.isShowingNowPlaying = isShowingNowPlaying
  this.toggleShowButton = toggleShowButton
  this.toggleAdvancedFlipsideShowStream = toggleAdvancedFlipsideShowStream
  this.reset = reset

  init()

  function init () {

    reset()
  }

  /**
   * @returns {TBasMusicWidgetState}
   */
  function get () {
    return state
  }

  /**
   * True - Now playing
   * false - favourites
   *
   * @param {boolean} [force]
   * @param {boolean} [emit = true]
   */
  function toggleNowPlaying (force, emit) {

    var before = state.css[CSS_BTM_NOW_PLAYING]

    state.css[CSS_BTM_NOW_PLAYING] =
      BasUtil.isBool(force)
        ? force
        : !state.css[CSS_BTM_NOW_PLAYING]

    if (before !== state.css[CSS_BTM_NOW_PLAYING] && emit !== false) {

      $rootScope.$emit(BAS_MUSIC_WIDGET.EVT_VIEW_CHANGED)
    }
  }

  function isShowingNowPlaying () {

    return state.css[CSS_BTM_NOW_PLAYING]
  }

  /**
   * True - Show
   * false - Don't show
   *
   * @param {boolean} [force]
   */
  function toggleShowButton (force) {

    state.css[CSS_BTM_SHOW_BUTTON] =
      BasUtil.isBool(force)
        ? force
        : !state.css[CSS_BTM_SHOW_BUTTON]
  }

  /**
   * True - Show streams view
   * false - Show favourites view
   *
   * @param {boolean} [force]
   */
  function toggleAdvancedFlipsideShowStream (force) {

    state.css[CSS_SHOW_STREAMS] =
      BasUtil.isBool(force)
        ? force
        : !state.css[CSS_SHOW_STREAMS]
    _syncBackContextTitleId()
  }

  function reset () {

    state.css[CSS_BTM_SHOW_BUTTON] = true
    state.css[CSS_BTM_NOW_PLAYING] = true
    state.css[CSS_SHOW_STREAMS] = false
    _syncBackContextTitleId()
  }

  function _syncBackContextTitleId () {

    state.backContextTitleId =
      state.css[CSS_SHOW_STREAMS]
        ? 'streams'
        : 'favourites'
  }
}
