'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('SpotifyManager', [
    '$rootScope',
    'BAS_SOURCE',
    'BAS_LIBRARY_ERRORS',
    'BAS_MODAL',
    'BAS_LIBRARY',
    'BAS_HTML',
    'BAS_APP_PROFILE',
    'ModalService',
    'modalHelperService',
    'SourcesHelper',
    'BasLibraryManager',
    'BasLibraryBody',
    'BasLibraryPage',
    'BasLibraryDetail',
    'SpotifyLibraryPage',
    'SpotifyCollection',
    'SpotifyModel',
    'SpotifyElement',
    'Selection',
    'BasCurrentAppProfile',
    'BasModal',
    'BasUtilities',
    'Logger',
    spotifyManagerFactory
  ])

/**
 * @param $rootScope
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param {ModalService} ModalService
 * @param {modalHelperService} modalHelperService
 * @param {SourcesHelper} SourcesHelper
 * @param BasLibraryManager
 * @param BasLibraryBody
 * @param BasLibraryPage
 * @param BasLibraryDetail
 * @param SpotifyLibraryPage
 * @param SpotifyCollection
 * @param {SpotifyModel} SpotifyModel
 * @param SpotifyElement
 * @param Selection
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param {BasModal} BasModal
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns SpotifyManager
 */
function spotifyManagerFactory (
  $rootScope,
  BAS_SOURCE,
  BAS_LIBRARY_ERRORS,
  BAS_MODAL,
  BAS_LIBRARY,
  BAS_HTML,
  BAS_APP_PROFILE,
  ModalService,
  modalHelperService,
  SourcesHelper,
  BasLibraryManager,
  BasLibraryBody,
  BasLibraryPage,
  BasLibraryDetail,
  SpotifyLibraryPage,
  SpotifyCollection,
  SpotifyModel,
  SpotifyElement,
  Selection,
  BasCurrentAppProfile,
  BasModal,
  BasUtilities,
  Logger
) {
  var className = 'Spotify Library Manager'

  var COLLECTION_MENU = 'collectionMenu'
  var COLLECTION_BROWSE = 'collectionBrowse'
  var COLLECTION_ARTIST = 'collectionArtist'

  /**
   * @constructor
   * @extends BasLibraryManager
   *
   * @param {PlayerLibraryState} libraryState
   */
  function SpotifyManager (libraryState) {

    // Call template constructor
    BasLibraryManager.call(this, libraryState)

    // Link the control functions
    this.linkControls()

    // Initialize header
    this.header.setTitle(BasUtilities.translate('spotify'))
    this.header.setSubtitle('')
    this.header.enableSearch(true)
    this.header.enableNavigation(true)

    // Selection
    this.selection = new Selection()
    this.selection.type = Selection.TYPE_SPOTIFY

    /**
     * @instance
     * @type {string}
     */
    this.spotifyUserId = ''

    // Add the start state
    this.addPage(this.startPage)
  }

  // Set new prototype from inherited object
  SpotifyManager.prototype = Object.create(BasLibraryManager.prototype)

  // Set correct constructor after setting new prototype
  SpotifyManager.prototype.constructor = SpotifyManager

  SpotifyManager.prototype.linkControls = function () {

    // Body controls
    this.body.control[BasLibraryBody.CLK_ELEMENT] =
      this.selectElement.bind(this)
    this.body.control[BasLibraryBody.CLK_BROWSE] =
      this.selectElement.bind(this)
    this.body.control[BasLibraryBody.CLK_PLAY] =
      this.clickPlay.bind(this)
    this.body.control[BasLibraryBody.CLK_REMOVE] =
      this.clickRemove.bind(this)
    this.body.control[BasLibraryBody.CLK_CONTEXT] =
      this.clickContext.bind(this)
  }

  // region Control functions

  /**
   * @param {SpotifyElement} element
   * @param {string} [shortcut]
   */
  SpotifyManager.prototype.selectElement = function (
    element,
    shortcut
  ) {
    var page, retrieve, detail, collection, album, artist, basSource

    Logger.debug(className + ' - Select', this.currentPage, element)

    // Check if a new page needs to be created
    if (isValidShortcut(shortcut) ||
      element.canBrowse) {

      // Create a new page
      page = new SpotifyLibraryPage(this.handler)
      page.addCollection(new SpotifyCollection(this.handler))
    }

    switch (shortcut) {
      case BAS_LIBRARY.SHORTCUT_ALBUM:

        if (element &&
          element.spotify &&
          element.spotify.album) {

          album = new SpotifyElement()
          SpotifyModel.processSpotifyAlbum(
            album,
            element.spotify.album
          )

          // Title
          page.setTitleId('album')
          page.setSubtitle(album.title)

          // Content type
          page.setContent(SpotifyLibraryPage.CONTENT_GENERIC)

          // Create detail
          detail = new BasLibraryDetail()
          detail.title = album.title
          detail.subtitle = album.subtitle
          detail.setHasCoverArt(album.covers.large)
          detail.setCanSelect(true)
          detail.setCanPlay(true)
          detail.setHasContext(true)

          collection = page.getCollection(0)

          if (collection) {

            collection.setDetail(detail)
            collection.setDetailElement(album)
            collection.setContentType(SpotifyCollection.CT_TRACK)
            collection.id = album.id
            collection.type = SpotifyCollection.TYPE_ALBUM
          }

          retrieve = true
        }

        break
      case BAS_LIBRARY.SHORTCUT_ARTIST:

        if (element &&
          element.spotify &&
          element.spotify.artists &&
          element.spotify.artists[0]) {

          artist = element.spotify.artists[0]

          // Title
          page.setTitleId('artist')
          page.setSubtitle(artist.name)

          // Create detail
          detail = new BasLibraryDetail()
          detail.title = artist.name
          detail.setCanPlay(true)
          detail.setHasContext(true)

          // Set lockSubtitle
          page.lockSubtitle = true

          // Content type
          page.setContent(SpotifyLibraryPage.CONTENT_GENERIC)

          collection = page.getCollection(0)

          if (collection) {

            collection.setDetail(detail)
          }

          basSource = this.libraryState.getSpotifySource()

          if (basSource) {

            basSource.spotify.getArtist(artist.id)
              .then(onExtraArtist)
          }

          // Make tabs
          setPageCollections(page, COLLECTION_ARTIST, artist.id)

          retrieve = true
        }

        break
      default:
        // Process selected UI element
        retrieve = this.processElement(page, element)
        break
    }

    // Check page
    if (BasUtil.isObject(page)) {

      // Add page
      this.addPage(page, retrieve)
    }

    function onExtraArtist (result) {

      var length, i, collections, _artist

      _artist = new SpotifyElement()

      SpotifyModel.processSpotifyArtist(_artist, result)

      // Set type
      _artist.type = SpotifyElement.TYPE_ARTIST

      if (_artist) {
        collections = page.collectionsData

        length = collections.length
        for (i = 0; i < length; i++) {

          collection = collections[i]
          collection.setDetailElement(_artist)

          // Check if it has coverArt
          detail.setHasCoverArt(_artist.covers.large)
        }
      }
    }
  }

  /**
   * @param page
   * @param {SpotifyElement} element
   * @returns {boolean}
   */
  SpotifyManager.prototype.processElement = function (
    page,
    element
  ) {
    var detail, collection

    switch (element.type) {
      case SpotifyElement.TYPE_SECTION:

        switch (element.subType) {
          case SpotifyModel.SECTION_MY_MUSIC:

            // Title
            page.setTitleId('my_music')

            // Set content
            page.setContent(SpotifyLibraryPage.CONTENT_MY_MUSIC)

            // Make collections
            setPageCollections(page, COLLECTION_MENU, element.id)

            return true
          case SpotifyModel.SECTION_BROWSE:

            // Title
            page.setTitleId('browse')

            // Set content
            page.setContent(SpotifyLibraryPage.CONTENT_BROWSE)

            // Make collections
            setPageCollections(page, COLLECTION_BROWSE, element.id)

            return true
          case SpotifyModel.SECTION_GENRES_MOODS:

            // Title
            page.setTitleId('genres')

            // Content type
            page.setContent(SpotifyLibraryPage.CONTENT_GENRES)

            collection = page.getCollection(0)
            if (collection) {

              collection.setContentType(
                SpotifyCollection.CT_GENRE
              )
              collection.setCanGridView(true)
            }

            return true
          case SpotifyModel.SECTION_MORE:

            // Title
            page.setTitleId('search')
            page.setSubtitle(element.searchQuery)

            // Content type
            page.setContent(SpotifyLibraryPage.CONTENT_GENERIC)

            collection = page.getCollection(0)
            if (collection) {

              collection.setCanGridView(true)
              collection.setContentType(element.searchType)
              collection.type = SpotifyCollection.TYPE_SEARCH
              collection.query = element.searchQuery
            }

            return true
        }
        break
      case SpotifyElement.TYPE_PLAYLIST:

        // Title
        page.setTitleId('playlist')
        page.setSubtitle(element.title)

        // Create detail
        detail = new BasLibraryDetail()

        collection = page.getCollection(0)
        if (collection) {

          collection.setCanEdit(element.owner === this.spotifyUserId)
          collection.setContentType(SpotifyCollection.CT_TRACK)
          collection.setDetail(detail)
          collection.setDetailElement(element)

          collection.id = element.id
          collection.type = SpotifyCollection.TYPE_PLAYLIST
        }

        // Fill detail
        detail.title = element.title
        detail.subtitle = element.subtitle
        detail.setHasCoverArt(element.covers.large)
        detail.setCanPlay(true)
        detail.setCanSelect(true)
        detail.setHasContext(true)
        if (collection) detail.setCanEdit(collection.canEdit)

        // Content type
        page.setContent(SpotifyLibraryPage.CONTENT_GENERIC)

        return true
      case SpotifyElement.TYPE_ALBUM:

        // Title
        page.setTitleId('album')
        page.setSubtitle(element.title)

        // Content type
        page.setContent(SpotifyLibraryPage.CONTENT_GENERIC)

        // Create detail
        detail = new BasLibraryDetail()
        detail.title = element.title
        detail.subtitle = element.subtitle
        detail.setHasCoverArt(element.covers.large)
        detail.setCanSelect(true)
        detail.setCanPlay(true)
        detail.setHasContext(true)

        collection = page.getCollection(0)
        if (collection) {

          collection.setDetail(detail)
          collection.setDetailElement(element)
          collection.setContentType(SpotifyCollection.CT_TRACK)
          collection.id = element.id
          collection.type = SpotifyCollection.TYPE_ALBUM
        }

        return true
      case SpotifyElement.TYPE_ARTIST:

        // Title
        page.setTitleId('artist')
        page.setSubtitle(element.title)

        // Create detail
        detail = new BasLibraryDetail()
        detail.title = element.title
        detail.subtitle = element.subtitle
        detail.setHasCoverArt(element.covers.large)
        detail.setCanPlay(true)
        detail.setHasContext(true)

        // Set lockSubtitle
        page.lockSubtitle = true

        // Content type
        page.setContent(SpotifyLibraryPage.CONTENT_GENERIC)

        collection = page.getCollection(0)
        if (collection) {

          collection.setDetail(detail)
          collection.setDetailElement(element)
        }

        // Make tabs
        setPageCollections(page, COLLECTION_ARTIST, element.id)

        return true
      case SpotifyElement.TYPE_GENRE:

        // Title
        page.setTitleId('genre')
        page.setSubtitle(element.title)

        // Content type
        page.setContent(SpotifyLibraryPage.CONTENT_GENERIC)

        collection = page.getCollection(0)
        if (collection) {

          // Set collection type
          collection.setContentType(SpotifyCollection.CT_PLAYLIST)
          collection.setCanGridView(true)
          collection.id = element.id
          collection.type = SpotifyCollection.TYPE_GENRE
        }

        return true
      case SpotifyElement.TYPE_TRACK:

        this.playElement(element)

        break
      default:
        Logger.warn(className + ' - ProcessElement - ' +
                      'element type unknown', element)
        break
    }

    return false
  }

  SpotifyManager.prototype.clickPlay = function (element) {

    this.play({ context_uri: element.uri })
  }

  /**
   * @param {SpotifyElement} element
   */
  SpotifyManager.prototype.playElement = function (element) {

    var options, collection, contextUri, uris

    if (BasUtil.isObject(element)) {

      options = {}

      if (this.currentPage &&
        this.currentPage.content ===
        SpotifyLibraryPage.CONTENT_SEARCH) {

        options['uris'] = [element.uri]

      } else {

        options['offset'] = {
          uri: element.uri
        }

        if (this.currentPage &&
          this.currentPage.collections[0]) {

          collection = this.currentPage.collections[0]

          switch (collection.type) {
            case SpotifyCollection.TYPE_ALBUM:
            case SpotifyCollection.TYPE_PLAYLIST:
            case SpotifyCollection.TYPE_ARTIST:

              if (collection.detailElement) {

                contextUri = collection.detailElement.uri
              }

              break
          }
        }

        if (BasUtil.isNEString(contextUri)) {

          options['context_uri'] = contextUri

          if (collection && Array.isArray(collection.elements)) {

            uris = collection.getUris()

            if (Array.isArray(uris)) {

              options['context_position'] = uris.indexOf(element.uri)
            }
          }

        } else {

          if (collection && Array.isArray(collection.elements)) {

            uris = collection.getUris()

            if (Array.isArray(uris)) {

              options['offset'] = {
                position: uris.indexOf(element.uri)
              }
            }
          }

          options['uris'] = Array.isArray(uris)
            ? uris
            : [element.uri]
        }
      }

      this.play(options)
    }
  }

  /**
   *
   * @param {Object} options
   */
  SpotifyManager.prototype.play = function (options) {

    var basSource = SourcesHelper.getSpotifySource(
      this.libraryState.playerId
    )

    if (basSource) {

      if (basSource.isAudioSource) {

        if (options.offset) {

          if (BasUtil.isNEString(options.offset.uri)) {

            basSource.playUri(
              options.offset.uri,
              {
                showModalOnError: false,
                contextUri: options.context_uri,
                contextOffset: options.context_position
              }
            )

          } else if (
            BasUtil.isNEArray(options.uris) &&
            BasUtil.isNEString(options.uris[options.offset.position])
          ) {

            basSource.playUri(
              options.uris[options.offset.position],
              {
                showModalOnError: false,
                contextUri: options.context_uri,
                contextOffset: options.context_position
              }
            )
          }

        } else if (BasUtil.isNEString(options.context_uri)) {

          basSource.playUri(options.context_uri)

        } else if (BasUtil.isNEArray(options.uris)) {

          basSource.playUri(
            options.uris.length === 1
              ? options.uris[0]
              : options.uris
          )
        }

      } else if (basSource.play) {

        basSource.source.play(options)
      }
    }
  }

  /**
   * @param {Object} data
   * @param {DeezerElement} data.element
   * @param {DeezerCollection} data.collection
   * @param data.event
   */
  SpotifyManager.prototype.clickContext = function (data) {

    var _this = this

    Logger.debug(className + ' - clickContext', data.element, data.event)

    ModalService
      .showModal({
        template: BAS_HTML.templateContextModal,
        controller: 'basLibraryContextCtrl',
        controllerAs: 'modal',
        inputs: {
          event: data.event,
          element: data.element,
          collection: data.collection,
          libType: BAS_LIBRARY.TYPE_SPOTIFY,
          selection: null,
          handler: this.handler
        }
      })
      .then(onContextModal)

    // Set scroll lock
    this.body.setClass(BasLibraryBody.CLASS_LOCK_SCROLLING)

    function onContextModal (modal) {

      // Check modal
      if (BasUtil.isObject(modal) &&
        modal.controller &&
        typeof modal.controller.onModalReady === 'function') {

        // Execute modal ready function
        modal.controller.onModalReady()
      }

      // Set closed Promise
      modal.closed
        .then(onContextModalClosed)
    }

    function onContextModalClosed () {

      // Reset modal style
      modalHelperService.resetModalStyle()

      // Clear scroll lock
      _this.body.setClass(BasLibraryBody.CLASS_LOCK_SCROLLING, false)
    }
  }

  SpotifyManager.prototype.selectMenu = function (event, selection, handler) {

    return ModalService
      .showModal({
        template: BAS_HTML.templateContextModal,
        controller: 'basLibraryContextCtrl',
        controllerAs: 'modal',
        inputs: {
          event: event,
          selection: selection,
          element: null,
          collection: null,
          libType: BAS_LIBRARY.TYPE_SPOTIFY,
          handler: handler
        }
      })
      .then(onModalShown)

    function onModalShown (modal) {

      Logger.debug(className +
                     ' - onModalShown', modal, modal.controller)

      // Modal is ready, DOM manipulation is allowed
      if (modal.controller &&
        modal.controller.onModalReady &&
        typeof modal.controller.onModalReady === 'function') {

        modal.controller.onModalReady()
      }

      // Set modal closed Promise callbacks
      return modal.closed.then(onModalClosed)

      function onModalClosed () {

        // Reset modal height
        modalHelperService.resetModalStyle()

        return Promise.resolve(BAS_LIBRARY.MODAL_CLOSED)
      }
    }
  }

  /**
   * @param {string} title
   * @param {string} id
   * @returns {Promise}
   */
  SpotifyManager.prototype.changeTitleRequest = function (title, id) {

    var _this = this

    var basSource = SourcesHelper.getSpotifySource(
      this.libraryState.playerId
    )

    if (basSource && basSource.spotify) {

      return basSource.spotify.changePlaylistDetails(
        id,
        { name: title }
      ).then(onSuccess)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_SPOTIFY)

    function onSuccess () {

      // Set page dirty
      _this.handler(BAS_LIBRARY.EVT_PLAYLIST_CHANGED)
    }
  }

  SpotifyManager.prototype.clickRemove = function () {

    var _this = this

    // Show confirmation modal
    BasModal.show(BAS_MODAL.T_REMOVE).then(onPlaylistRemoveModal)

    function onPlaylistRemoveModal (modal) {

      // Set close Promise
      modal.close.then(onPlaylistRemoveModalClose)
    }

    function onPlaylistRemoveModalClose (result) {

      var collection, basSource

      if (result === BAS_MODAL.C_YES) {

        basSource = SourcesHelper.getSpotifySource(
          _this.libraryState.playerId
        )

        if (basSource &&
          basSource.spotify) {

          collection = _this.currentPage.collections[0]

          if (BasUtil.isObject(collection.detailElement)) {

            basSource.spotify.unFollowPlaylist(
              collection.detailElement.id
            ).then(onSuccess)
          }
        }
      }

      function onSuccess () {

        // Set page dirty
        _this.handler(BAS_LIBRARY.EVT_PLAYLIST_CHANGED)

        // Go back to list
        _this.back()
      }
    }

  }

  SpotifyManager.prototype.evtPlaylistChanged = function () {

    var pages, page, collection, length, i

    pages = this.pages
    length = pages.length
    for (i = 0; i < length; i++) {

      page = pages[i]
      collection = page.getCollection(0)

      if (BasUtil.isObject(collection) &&
        collection.contentType === SpotifyCollection.CT_PLAYLIST) {

        page.dirty = true
      }
    }
  }

  // endregion

  // region Header controls

  SpotifyManager.prototype.search = function (searchQuery) {

    var searchPage

    if (BasUtil.isNEString(searchQuery)) {

      searchPage = new SpotifyLibraryPage(this.handler)

      // Page content type
      searchPage.content = SpotifyLibraryPage.CONTENT_SEARCH

      // Titles
      searchPage.setTitleId('search')
      searchPage.setSubtitle(searchQuery)

      // Make pages
      searchPage.addCollection(this.makeSearchCollection(
        searchQuery,
        SpotifyCollection.CT_ALBUM
      ))
      searchPage.addCollection(this.makeSearchCollection(
        searchQuery,
        SpotifyCollection.CT_ARTIST
      ))
      searchPage.addCollection(this.makeSearchCollection(
        searchQuery,
        SpotifyCollection.CT_TRACK
      ))
      searchPage.addCollection(this.makeSearchCollection(
        searchQuery,
        SpotifyCollection.CT_PLAYLIST
      ))

      // Add page
      this.addPage(searchPage, true)

      // Make sure to close the search
      this.header.toggleSearchInput(false)

      BasCurrentAppProfile.addSearchHistory(
        searchQuery,
        BAS_APP_PROFILE.K_SPOTIFY
      )

      // Clear searchQuery
      this.searchQuery = ''
    }
  }

  SpotifyManager.prototype.toggleSearch = function () {

    if (this.currentPage &&
      this.currentPage.content === SpotifyLibraryPage.CONTENT_SEARCH) {

      this.searchQuery = this.currentPage.subtitle
    }
  }

  // endregion

  // region Helper functions

  SpotifyManager.prototype.createStartPage = function () {

    // Create new page
    this.startPage = new SpotifyLibraryPage(this.handler)
    this.startPage.addCollection(new SpotifyCollection(this.handler))
    this.startPage.setTitleId('spotify')

    // Set page content type
    this.startPage.setContent(BasLibraryPage.CONTENT_START)

    // Add start elements
    this.startPage.addElement(
      0,
      SpotifyModel.createSection(SpotifyModel.SECTION_MY_MUSIC)
    )
    this.startPage.addElement(
      0,
      SpotifyModel.createSection(SpotifyModel.SECTION_BROWSE)
    )
    this.startPage.addElement(
      0,
      SpotifyModel.createSection(SpotifyModel.SECTION_GENRES_MOODS)
    )
  }

  /**
   *
   * @param {SpotifyLibraryPage} page
   * @param {string} type
   * @param {string} id
   */
  function setPageCollections (page, type, id) {

    switch (type) {
      case COLLECTION_MENU:
        page.makeCollection({
          name: 'playlists_l',
          titleName: 'playlists',
          contentType: SpotifyCollection.CT_PLAYLIST,
          type: SpotifyCollection.TYPE_ME
        })
        page.makeCollection({
          name: 'albums',
          titleName: 'albums',
          contentType: SpotifyCollection.CT_ALBUM,
          type: SpotifyCollection.TYPE_ME
        })
        page.makeCollection({
          name: 'artists',
          titleName: 'artists',
          contentType: SpotifyCollection.CT_ARTIST,
          type: SpotifyCollection.TYPE_ME
        })
        page.makeCollection({
          name: 'songs',
          titleName: 'songs',
          contentType: SpotifyCollection.CT_TRACK,
          type: SpotifyCollection.TYPE_ME
        })
        page.makeCollection({
          name: 'recently_played',
          titleName: 'recently_played',
          contentType: SpotifyCollection.CT_TRACK,
          type: SpotifyCollection.TYPE_RECENT
        })
        break
      case COLLECTION_BROWSE:
        page.makeCollection({
          name: 'new_releases_small',
          titleName: 'new_releases',
          contentType: SpotifyCollection.CT_ALBUM,
          type: SpotifyCollection.TYPE_NEW_RELEASES
        })
        page.makeCollection({
          name: 'featured_playlists',
          titleName: 'featured_playlists',
          contentType: SpotifyCollection.CT_PLAYLIST,
          type: SpotifyCollection.TYPE_FEATURED
        })
        page.makeCollection({
          name: 'charts',
          titleName: 'charts',
          contentType: SpotifyCollection.CT_PLAYLIST,
          type: SpotifyCollection.TYPE_CHARTS
        })
        break
      case COLLECTION_ARTIST:
        page.makeCollection({
          name: 'top_tracks',
          titleName: 'top_tracks',
          canGridView: false,
          contentType: SpotifyCollection.CT_TRACK,
          type: SpotifyCollection.TYPE_ARTIST,
          id: id
        })
        page.makeCollection({
          name: 'albums',
          titleName: 'albums',
          canGridView: false,
          contentType: SpotifyCollection.CT_ALBUM,
          type: SpotifyCollection.TYPE_ARTIST,
          id: id
        })
        page.makeCollection({
          name: 'related_artists',
          titleName: 'related_artists',
          canGridView: false,
          contentType: SpotifyCollection.CT_ARTIST,
          type: SpotifyCollection.TYPE_ARTIST,
          id: id
        })
        break
    }

    page.makeTabs()
  }

  SpotifyManager.prototype.makeSearchCollection = function (
    query,
    contentType
  ) {
    var searchCollection, showMoreElement

    searchCollection = new SpotifyCollection(this.handler)
    searchCollection.hasReachedEnd = true

    searchCollection.setTitleId(getCollectionTitle(contentType))
    searchCollection.setContentType(contentType)
    searchCollection.type = SpotifyCollection.TYPE_SEARCH
    searchCollection.query = query
    searchCollection.limit = 2

    showMoreElement = SpotifyModel
      .createSection(SpotifyModel.SECTION_MORE)

    showMoreElement.searchType = contentType
    showMoreElement.searchQuery = query

    searchCollection.setLastElement(showMoreElement)

    return searchCollection
  }

  function getCollectionTitle (type) {

    switch (type) {
      case SpotifyCollection.CT_ALBUM:
        return 'albums'
      case SpotifyCollection.CT_ARTIST:
        return 'artists'
      case SpotifyCollection.CT_TRACK:
        return 'songs'
      case SpotifyCollection.CT_PLAYLIST:
        return 'playlists'
    }
  }

  /**
   * @param shortcut
   * @returns {boolean}
   */
  function isValidShortcut (shortcut) {
    return (
      shortcut === BAS_LIBRARY.SHORTCUT_ALBUM ||
      shortcut === BAS_LIBRARY.SHORTCUT_ARTIST
    )
  }

  // endregion

  // region Resume & Suspend

  SpotifyManager.prototype.onIdChanged = function (
    _event,
    id
  ) {
    if (id === this.libraryState.playerId) this.checkspotifyUserId()
  }

  SpotifyManager.prototype.checkspotifyUserId = function () {

    var basSource = this.libraryState.getSpotifySource()

    if (basSource) {

      basSource.spotify.linked()
        .then(this._checkspotifyUserId.bind(this))
    }
  }

  SpotifyManager.prototype._checkspotifyUserId = function () {

    var basSource, id

    basSource = this.libraryState.getSpotifySource()

    if (basSource &&
      basSource.spotify.isLinked()) {

      id = basSource.spotify.spotifyId

      // Make sure NoSpotifyPage is gone before you want to go home
      this.removeNotConnectedPage()

      if (id !== this.spotifyUserId &&
        BasUtil.isNEString(this.spotifyUserId)) {

        // Clear selection
        this.selection.clear()

        // Go to homepage
        this.home()
      }

      // Set spotify after check
      this.spotifyUserId = id

    } else {

      // Clear spotify id
      this.spotifyUserId = ''

      // Clear selection
      this.selection.clear()

      // Go to homepage
      this.home()

      // Show "No Spotify" page
      this.addNotConnectedPage()
    }
  }

  /**
   * Resume listening to events
   *
   * @method
   */
  SpotifyManager.prototype.resume = function () {

    // Wait one frame to set new scrollPos
    BasUtilities.waitFrames(2).then(
      this.setScrollPos.bind(
        this,
        this.currentPage.getScrollPercent()
      )
    )

    // Set listeners

    this.listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_SPOTIFY_USER_UPDATED,
      this.onIdChanged.bind(this)
    ))

    // Check token
    this.checkspotifyUserId()

    // Translate
    this.onTranslationChange()
  }

  // endregion

  return SpotifyManager
}
