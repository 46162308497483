'use strict'

angular
  .module('basalteApp')
  .constant('BAS_LOCAL_LIBRARY', {
    T_EL_ALBUM: 'album',
    T_EL_ARTIST: 'artist',
    T_EL_PLAYLIST: 'playlist',
    T_EL_TRACK: 'track',
    T_EL_SECTION: 'section'
  })
