'use strict'

angular
  .module('basalteApp')
  .service('BasSchedulerInit', [
    '$rootScope',
    'BAS_SCHEDULER',
    'BAS_INTL',
    'BasSchedulerLine',
    'BasIntl',
    BasSchedulerInit
  ])

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_SCHEDULER} BAS_SCHEDULER
 * @param {BAS_INTL} BAS_INTL
 * @param BasSchedulerLine
 * @param {BasIntl} BasIntl
 */
function BasSchedulerInit (
  $rootScope,
  BAS_SCHEDULER,
  BAS_INTL,
  BasSchedulerLine,
  BasIntl
) {
  var basIntlState = BasIntl.get()

  init()

  function init () {

    updateHourLines()
    updateValueLines()

    $rootScope.$on(BAS_INTL.EVT_TIME_FORMAT_CHANGED, onTimeFormat)
    $rootScope.$on('$translateChangeSuccess', onLanguageChanged)
  }

  function updateHourLines () {

    var minorCss = [BAS_SCHEDULER.CSS_MINOR]
    var majorCss = []

    if (basIntlState.timeFormat === BAS_INTL.TIME_FORMAT_12) {

      if (basIntlState.uiAMPMBefore) {
        minorCss.push(BAS_SCHEDULER.CSS_SUBTEXT_BEFORE)
        majorCss.push(BAS_SCHEDULER.CSS_SUBTEXT_BEFORE)
      }

      BAS_SCHEDULER.HOUR_LINES_13 = [
        new BasSchedulerLine('12', basIntlState.uiAM, majorCss),
        new BasSchedulerLine('2', basIntlState.uiAM, minorCss),
        new BasSchedulerLine('4', basIntlState.uiAM, majorCss),
        new BasSchedulerLine('6', basIntlState.uiAM, minorCss),
        new BasSchedulerLine('8', basIntlState.uiAM, majorCss),
        new BasSchedulerLine('10', basIntlState.uiAM, minorCss),
        new BasSchedulerLine('12', basIntlState.uiPM, majorCss),
        new BasSchedulerLine('2', basIntlState.uiPM, minorCss),
        new BasSchedulerLine('4', basIntlState.uiPM, majorCss),
        new BasSchedulerLine('6', basIntlState.uiPM, minorCss),
        new BasSchedulerLine('8', basIntlState.uiPM, majorCss),
        new BasSchedulerLine('10', basIntlState.uiPM, minorCss),
        new BasSchedulerLine('12', basIntlState.uiPM, majorCss)
      ]

    } else {

      BAS_SCHEDULER.HOUR_LINES_13 = [
        new BasSchedulerLine('0', undefined, majorCss),
        new BasSchedulerLine('2', undefined, minorCss),
        new BasSchedulerLine('4', undefined, majorCss),
        new BasSchedulerLine('6', undefined, minorCss),
        new BasSchedulerLine('8', undefined, majorCss),
        new BasSchedulerLine('10', undefined, minorCss),
        new BasSchedulerLine('12', undefined, majorCss),
        new BasSchedulerLine('14', undefined, minorCss),
        new BasSchedulerLine('16', undefined, majorCss),
        new BasSchedulerLine('18', undefined, minorCss),
        new BasSchedulerLine('20', undefined, majorCss),
        new BasSchedulerLine('22', undefined, minorCss),
        new BasSchedulerLine('24', undefined, majorCss)
      ]
    }
  }

  function updateValueLines () {

    BAS_SCHEDULER.VALUE_LINES_5 = [
      new BasSchedulerLine(),
      new BasSchedulerLine(undefined, BAS_SCHEDULER.CSS_MINOR),
      new BasSchedulerLine(),
      new BasSchedulerLine(undefined, BAS_SCHEDULER.CSS_MINOR),
      new BasSchedulerLine()
    ]

    BAS_SCHEDULER.VALUE_LINES_6 = [
      new BasSchedulerLine(),
      new BasSchedulerLine(),
      new BasSchedulerLine(),
      new BasSchedulerLine(),
      new BasSchedulerLine(),
      new BasSchedulerLine()
    ]

    BAS_SCHEDULER.VALUE_LINES_2 = [
      new BasSchedulerLine(),
      new BasSchedulerLine()
    ]
  }

  function onTimeFormat () {

    updateHourLines()
  }

  function onLanguageChanged () {

    updateHourLines()
  }
}
