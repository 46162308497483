'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSplash', [
    '$rootScope',
    'BAS_SPLASH',
    'BasUtilities',
    BasSplash
  ])

/**
 * @typedef {Object} TBasSplashState
 * @property {string} state
 * @property {boolean} uiShow
 * @property {string} uiTIDTitle
 * @property {string} uiTIDSubtitle
 * @property {string} uiTIDAction
 * @property {*} actionParam
 * @property {Object<string, boolean>} css
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasUtilities} BasUtilities
 */
function BasSplash (
  $rootScope,
  BAS_SPLASH,
  BasUtilities
) {

  var CSS_SHOW_ELLIPSIS_THREE_DOTS = 'bas-show-ellipsis-three-dots'
  var CSS_SHOW_ELLIPSIS_TWO_DOTS = 'bas-show-ellipsis-two-dots'

  var CSS_SHOW = 'bas-splash-show'

  var CSS_SHOW_TITLE = 'bas-splash-show-title'
  var CSS_SHOW_TITLE_SPINNER = 'bas-splash-show-title-spinner'
  var CSS_SHOW_SUBTITLE = 'bas-splash-show-subtitle'
  var CSS_SHOW_ACTION = 'bas-splash-show-action'
  var CSS_SHOW_HOME_BUTTON = 'bas-splash-show-home-button'

  var _visibilityDelayTimeoutId = 0
  var _visibilityDelayWaitFramesAbort = null

  /**
   * @type {TBasSplashState}
   */
  var state = {}
  state.state = ''
  state.uiShow = false
  state.uiTIDTitle = ''
  state.uiTIDSubtitle = ''
  state.uiTIDAction = ''
  state.actionParam = undefined
  state.css = {}
  _resetVisibilityCss()
  _resetStateCss()

  this.get = get
  this.isType = isType
  this.setType = setType
  this.show = show
  this.showAfterFrames = showAfterFrames
  this.hide = hide
  this.hideAfterFrames = hideAfterFrames
  this.setActionParam = setActionParam
  this.canGoHome = canGoHome

  /**
   * @returns {TBasSplashState}
   */
  function get () {
    return state
  }

  /**
   * @param {string} type
   * @returns {boolean}
   */
  function isType (type) {

    return state.state === type
  }

  /**
   * @param {string} type
   */
  function setType (type) {

    var _oldState

    _oldState = state.state

    _setType(type)

    if (_oldState !== state.state) {

      $rootScope.$emit(BAS_SPLASH.EVT_SPLASH_STATE_CHANGED)
    }
  }

  /**
   * @private
   * @param {string} type
   */
  function _setType (type) {

    state.state = type
    _syncState()
  }

  /**
   * @param {(number|string)} [option1] Delay in ms or state/type
   * @param {(number|string)} [option2] Delay in ms or state/type
   */
  function show (option1, option2) {

    var _state, _delay

    _clearOngoingVisibilityChange()

    setActionParam(undefined)

    if (BasUtil.isNEString(option1)) {

      _state = option1

      if (BasUtil.isPNumber(option2)) {

        _delay = option2
      }

    } else if (BasUtil.isPNumber(option1)) {

      _delay = option1

      if (BasUtil.isNEString(option2)) {

        _state = option2
      }
    }

    if (BasUtil.isNEString(_state)) setType(_state)

    if (BasUtil.isPNumber(_delay)) {

      _visibilityDelayTimeoutId = setTimeout(_show, _delay)

    } else {

      _show()
    }
  }

  /**
   * @param {(number|string)} [option1] Number of frames or state/type
   * @param {(number|string)} [option2] Number of frames or state/type
   */
  function showAfterFrames (option1, option2) {

    var _state, _numberOfFrames

    _clearOngoingVisibilityChange()

    setActionParam(undefined)

    if (BasUtil.isNEString(option1)) {

      _state = option1

      if (BasUtil.isPNumber(option2)) {

        _numberOfFrames = option2
      }

    } else if (BasUtil.isPNumber(option1)) {

      _numberOfFrames = option1

      if (BasUtil.isNEString(option2)) {

        _state = option2
      }
    }

    if (BasUtil.isNEString(_state)) _setType(_state)

    if (BasUtil.isPNumber(_numberOfFrames)) {

      _visibilityDelayWaitFramesAbort = BasUtilities.waitForFrames(
        _numberOfFrames,
        _show
      )

    } else {

      _show()
    }
  }

  function _show () {

    _setShow(true)
  }

  /**
   * @param {number} [delay] Delay in ms
   */
  function hide (delay) {

    _clearOngoingVisibilityChange()

    if (BasUtil.isPNumber(delay)) {

      _visibilityDelayTimeoutId = setTimeout(_hide, delay)

    } else {

      _hide()
    }
  }

  /**
   * @param {number} numberOfFrames
   */
  function hideAfterFrames (numberOfFrames) {

    _clearOngoingVisibilityChange()

    if (BasUtil.isPNumber(numberOfFrames)) {

      _visibilityDelayWaitFramesAbort = BasUtilities.waitForFrames(
        numberOfFrames,
        _hide
      )

    } else {

      _hide()
    }
  }

  /**
   * @returns {boolean}
   */
  function canGoHome () {
    return state.css[CSS_SHOW_HOME_BUTTON]
  }

  /**
   * @param {*} param
   */
  function setActionParam (param) {

    state.actionParam = param
  }

  function _hide () {

    _setShow(false)
  }

  /**
   * @private
   * @param {boolean} showValue
   */
  function _setShow (showValue) {

    var _oldCssShow

    _oldCssShow = state.uiShow

    state.uiShow = showValue
    state.css[CSS_SHOW] = showValue

    if (_oldCssShow !== state.uiShow) {

      $rootScope.$emit(
        BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
        state.uiShow
      )
    }
  }

  function _syncState () {

    _resetStateCss()
    _resetUi()

    switch (state.state) {
      case BAS_SPLASH.S_CONNECTING:

        state.css[CSS_SHOW_TITLE_SPINNER] = true

        break
      case BAS_SPLASH.S_CONNECTING_CORES:

        state.css[CSS_SHOW_TITLE_SPINNER] = true
        state.css[CSS_SHOW_HOME_BUTTON] = true

        break
      case BAS_SPLASH.S_CONNECTING_RESTARTING:

        state.css[CSS_SHOW_ELLIPSIS_THREE_DOTS] = true
        state.css[CSS_SHOW_TITLE_SPINNER] = true
        state.uiTIDTitle = 'restarting_server'

        break
      case BAS_SPLASH.S_CONNECTING_UPDATING:

        state.css[CSS_SHOW_ELLIPSIS_THREE_DOTS] = true
        state.css[CSS_SHOW_TITLE_SPINNER] = true
        state.uiTIDTitle = 'updating_server'
        state.uiTIDSubtitle = 'updating_server_info'

        break
      case BAS_SPLASH.S_CONNECTING_FAILED:

        state.css[CSS_SHOW_ELLIPSIS_TWO_DOTS] = true
        state.css[CSS_SHOW_HOME_BUTTON] = true
        state.uiTIDTitle = 'connecting_stop_and_retry_text'
        state.uiTIDAction = 'try_again'

        break
      case BAS_SPLASH.S_CONNECTING_FAILED_NO_RETRY:

        state.css[CSS_SHOW_HOME_BUTTON] = true
        state.uiTIDTitle = 'connecting_stop_text'

        break
      case BAS_SPLASH.S_CONNECTING_FAILED_NOT_AUTHORIZED:

        state.css[CSS_SHOW_HOME_BUTTON] = true
        state.uiTIDTitle = 'connecting_not_authorized'

        break
      case BAS_SPLASH.S_CONNECTING_FAILED_NO_INTEGRATOR_ACCESS:

        state.css[CSS_SHOW_HOME_BUTTON] = true
        state.uiTIDTitle = 'connecting_no_integrator_access'
        state.uiTIDAction = 'try_again'

        break
      case BAS_SPLASH.S_CONNECTING_FAILED_NO_ACTIVE_SERVERS:

        state.css[CSS_SHOW_HOME_BUTTON] = true
        state.uiTIDTitle = 'connecting_no_active_servers'
        state.uiTIDAction = 'try_again'

        break
      case BAS_SPLASH.S_CONNECTION_LOST:

        state.css[CSS_SHOW_TITLE_SPINNER] = true
        state.css[CSS_SHOW_HOME_BUTTON] = true
        state.uiTIDTitle = 'connecting_text'

        break
      case BAS_SPLASH.S_NO_CONNECTION:

        state.uiTIDTitle = 'no_network'

        break
    }
    state.css[CSS_SHOW_TITLE] = !!state.uiTIDTitle
    state.css[CSS_SHOW_SUBTITLE] = !!state.uiTIDSubtitle
    state.css[CSS_SHOW_ACTION] = !!state.uiTIDAction
  }

  function _clearOngoingVisibilityChange () {

    _clearVisibilityTimeout()
    _clearVisibilityWaitForFrames()
  }

  function _clearVisibilityTimeout () {

    clearTimeout(_visibilityDelayTimeoutId)
    _visibilityDelayTimeoutId = 0
  }

  function _clearVisibilityWaitForFrames () {

    BasUtil.execute(_visibilityDelayWaitFramesAbort)
    _visibilityDelayWaitFramesAbort = null
  }

  function _resetStateCss () {

    state.css[CSS_SHOW_ELLIPSIS_THREE_DOTS] = false
    state.css[CSS_SHOW_ELLIPSIS_TWO_DOTS] = false
    state.css[CSS_SHOW_TITLE] = false
    state.css[CSS_SHOW_TITLE_SPINNER] = false
    state.css[CSS_SHOW_SUBTITLE] = false
    state.css[CSS_SHOW_ACTION] = false
    state.css[CSS_SHOW_HOME_BUTTON] = false
  }

  function _resetVisibilityCss () {

    state.css[CSS_SHOW] = false
  }

  function _resetUi () {

    state.uiTIDTitle = ''
    state.uiTIDSubtitle = ''
    state.uiTIDAction = ''
  }
}
