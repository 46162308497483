'use strict'

angular
  .module('basalteApp')
  .controller('thermostatCtrl', [
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'CurrentRoom',
    thermostatCtrl
  ])

/**
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 */
function thermostatCtrl (
  $uiRouterGlobals,
  BAS_ROOMS,
  CurrentRoom
) {

  const basThermostat = this

  basThermostat.currentRoom = CurrentRoom.get()
  basThermostat.$uiRouterGlobals = $uiRouterGlobals
  basThermostat.rooms = BAS_ROOMS.ROOMS
}
