'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var DeviceOutput = require('./device_output')

/**
 * Represents a Cobra Audio Device which can have Audio Outputs
 *
 * @constructor
 * @param {Object} cfg
 * @param {string} cfg.uuid
 * @param {number} cfg.mac
 * @param {string} cfg.name
 * @param {string} cfg.ctrlType
 * @param {string} cfg.deviceType
 * @param {Object} cfg.audioOutputs
 * @param {BasCore} basCore
 * @since 1.7.0
 */
function CobraAudioDevice (cfg, basCore) {

  this._basCore = basCore

  this._uuid = cfg[P.UUID]
  this._mac = cfg[P.MAC]
  this._name = cfg[P.NAME]
  this._ctrlType = cfg[P.CTRL_TYPE]
  this._type = cfg[P.DEVICE_TYPE]

  this._audioOutputs = {}

  // Iterate all given audio outputs and create DspModule objects
  for (const outputUuid of Object.keys(cfg[P.AUDIO_OUTPUTS])) {

    // Create new DspModule
    const devOutput = new DeviceOutput(
      cfg[P.AUDIO_OUTPUTS][outputUuid],
      basCore,
      this
    )
    if (devOutput.dspSupport) {
      this._audioOutputs[outputUuid] = devOutput
    }

  }
}

// Constants

/**
 * @constant {string}
 */
CobraAudioDevice.TYPE_ASANO_M4 = 'AsanoM4'

/**
 * @constant {string}
 */
CobraAudioDevice.TYPE_ASANO_SPK = 'AsanoSpk'

/**
 * @constant {string}
 */
CobraAudioDevice.CTRL_COBRA = 'CobraNet'

// Properties

/**
 * @name CobraAudioDevice#uuid
 * @type {string}
 */
Object.defineProperty(CobraAudioDevice.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name CobraAudioDevice#mac
 * @type {number}
 */
Object.defineProperty(CobraAudioDevice.prototype, 'mac', {
  get: function () {
    return this._mac
  }
})

/**
 * @name CobraAudioDevice#name
 * @type {string}
 */
Object.defineProperty(CobraAudioDevice.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * @name CobraAudioDevice#type
 * @type {string}
 */
Object.defineProperty(CobraAudioDevice.prototype, 'type', {
  get: function () {
    return this._type
  }
})

/**
 * @name CobraAudioDevice#ctrlType
 * @type {string}
 */
Object.defineProperty(CobraAudioDevice.prototype, 'ctrlType', {
  get: function () {
    return this._ctrlType
  }
})

/**
 * @name CobraAudioDevice#audioOutputs
 * @type {Object}
 */
Object.defineProperty(CobraAudioDevice.prototype, 'audioOutputs', {
  get: function () {
    return this._audioOutputs
  }
})

/**
 * @name CobraAudioDevice#dspSupport
 * @type {boolean}
 */
Object.defineProperty(CobraAudioDevice.prototype, 'dspSupport', {
  get: function () {
    return this._ctrlType === CobraAudioDevice.CTRL_COBRA
  }
})

// Instance methods

/**
 * Parse CobraAudioDevice message
 *
 * @param {Object} obj
 */
CobraAudioDevice.prototype.parse = function (obj) {
  var keys, i, length, audioOutput

  // Check for valid message
  if (BasUtil.isObject(obj) &&
    BasUtil.isObject(obj[P.AUDIO_OUTPUT])) {

    // Iterate over audio outputs
    keys = Object.keys(this._audioOutputs)
    length = keys.length
    for (i = 0; i < length; i++) {

      // Set audio output reference
      audioOutput = this._audioOutputs[keys[i]]

      // Check for valid audio output
      if (BasUtil.isObject(audioOutput) &&
        BasUtil.isFunction(audioOutput.parse)) {

        // Delegate message to audio output
        audioOutput.parse(obj)
      }
    }
  }
}

/**
 * @since 2.0.0
 */
CobraAudioDevice.prototype.destroy = function () {

  var keys, i, length, output

  if (BasUtil.isObject(this._audioOutputs)) {

    keys = Object.keys(this._audioOutputs)
    length = keys.length
    for (i = 0; i < length; i++) {

      output = this._audioOutputs[keys[i]]

      if (BasUtil.isObject(output)) output.destroy()
    }
  }

  this._basCore = null
  this._audioOutputs = null
}

module.exports = CobraAudioDevice
