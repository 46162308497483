'use strict'

angular
  .module('basalteApp')
  .constant('BAS_API_CORE', {
    K_ELLIE: 'ellie',
    K_DEVICE: 'device',
    K_UUID: 'uuid',
    K_PASSWORD: 'password',
    K_HAS_UPDATE: 'hasUpdate',
    K_ID: 'id'
  })
