'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('generalSettingsCtrl', [
    '$rootScope',
    '$scope',
    'BAS_CURRENT_CORE',
    'STATES',
    'SETTINGS_STATES',
    'BasAppDevice',
    'BasState',
    'CurrentBasCore',
    generalSettingsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {STATES} STATES
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {BasAppDevice} BasAppDevice
 * @param {BasState} BasState
 * @param {CurrentBasCore} CurrentBasCore
 */
function generalSettingsCtrl (
  $rootScope,
  $scope,
  BAS_CURRENT_CORE,
  STATES,
  SETTINGS_STATES,
  BasAppDevice,
  BasState,
  CurrentBasCore
) {
  var general = this

  var _listeners = []

  /**
   * @type {BasAppDeviceState}
   */
  general.basAppDeviceState = BasAppDevice.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  general.currentBasCoreState = CurrentBasCore.get()

  general.isEllieV1 = BasAppDevice.isEllieV1()

  general.SETTINGS_STATES = SETTINGS_STATES

  general.goToLisaOrderSettings = goToLisaOrderSettings

  init()

  function init () {

    $scope.$on('$destroy', destroy)

    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_IS_ADMIN,
      onAdminSet
    ))
  }

  function onAdminSet () {

    _scopeSync()
  }

  function goToLisaOrderSettings () {

    BasState.go(STATES.SETTINGS_GENERAL_LISA_ORDER)
  }

  function _scopeSync () {

    $scope.$applyAsync()
  }

  function destroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
