'use strict'

var Url = require('url-parse')

var EventEmitter = require('@gidw/event-emitter-js')
var macAddressUtil = require('@gidw/mac-address-util')
var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var Demo = require('./demo')
var BasProfile = require('./bas_profile')
var BasCoreSocket = require('./bas_core_socket')
var BasCoreStatus = require('./bas_core_status')
var BasCoreProjectInfo = require('./bas_core_project_info')

var requestUtil = require('./request_util')
var BasCrypto = require('./bas_crypto')

var log = require('./logger')

/**
 * @typedef {Object} TBasServerRequestConfig
 * @property {string} [scheme]
 * @property {string} [method]
 * @property {string} [url]
 * @property {string} [host]
 * @property {number} [port]
 * @property {string} [path]
 * @property {Object<string, (string | number | boolean)>} [params]
 * @property {Object<string, (string | number | boolean)>} [headers]
 * @property {*} [data]
 * @property {string} [encoding]
 * @property {number} [timeout]
 */

/**
 * @typedef {Object} TBasServerSpotifyRequestConfig
 * @property {string} path
 * @property {string} [method]
 * @property {Object<string, (string | number)>} [headers]
 * @property {Object<string, (string | number)>} [params]
 * @property {(Object | string)} [data]
 */

/**
 * @typedef {Object} TBasServerResponse
 * @property {number} status
 * @property {?Object<string, string>} headers
 * @property {number} apiVersion
 * @property {*} data
 */

/**
 * @typedef {Object} TBasServerUsersResponse
 * @property {number} status
 * @property {?Object<string, string>} headers
 * @property {number} apiVersion
 * @property {?(BasProfile[])} data
 */

/**
 * @typedef {Object} TBasServerCredentialsResponse
 * @property {number} status
 * @property {?Object<string, string>} headers
 * @property {number} apiVersion
 * @property {TCoreCredentials} data
 */

/**
 * @typedef {Object} TBasServerDeviceInfoResponse
 * @property {number} status
 * @property {?Object<string, string>} headers
 * @property {number} apiVersion
 * @property {?TCoreClientDeviceInfo} data
 */

/**
 * @typedef {Object} TCoreLegacyCredentials
 * @property {string} user
 * @property {string} credentialHash
 * @property {string} token
 * @property {string} credentialTokenHash
 */
/**
 * @typedef {Object} TCoreLiveCredentials
 * @property {string} [jwt]
 */

/**
 * @typedef {(TCoreLiveCredentials|TCoreLegacyCredentials)} TCoreCredentials
 */

/**
 * @typedef {Object} TCoreClientDeviceInfo
 * @property {Object} ellie
 * @property {string} ellie.uuid
 * @property {string} ellie.password
 * @property {Object} project
 * @property {string} project.uuid
 * @property {string} project.name
 * @property {Object[]} servers
 */

/**
 * @typedef {Object} TCoreRaw
 * @property {BasHost} host
 * @property {Object} httpResponse
 * @property {number} apiVersion
 * @property {?Object} data
 */

/**
 * @typedef {Object} TBasServerOptions
 * @property {(string|number)} [macAddress]
 * @property {string} [cid] Configuration/Project identifier
 * @property {string} [name] Configuration/Project name (ex. from discovery)
 * @property {boolean} [useSubscriptionSocket = false]
 */

/**
 * @constructor
 * @extends EventEmitter
 * @param {TBasServerOptions} [options]
 * @since 3.0.0
 */
function BasServer (options) {

  EventEmitter.call(this)

  /**
   * @private
   * @type {number}
   */
  this._macN = 0

  /**
   * @private
   * @type {string}
   */
  this._mac = ''

  /**
   * @private
   * @type {string}
   */
  this._cid = ''

  /**
   * @private
   * @type {string}
   */
  this._derivedName = ''

  /**
   * @private
   * @type {boolean}
   */
  this._useSubscriptionSocket = false

  /**
   * @private
   * @type {?Demo}
   */
  this._demo = null

  /**
   * @private
   * @type {?BasHost}
   */
  this._host = null

  /**
   * @private
   * @type {number}
   */
  this._apiVersion = CONSTANTS.DEFAULT_API_VERSION

  /**
   * @private
   * @type {boolean}
   */
  this._apiVersionKnown = false

  /**
   * @private
   * @type {number}
   */
  this._unsupportedApi = NaN

  /**
   * @private
   * @type {?Object}
   */
  this._version = null

  /**
   * @private
   * @type {?Object}
   */
  this._supports = null

  /**
   * @private
   * @type {?BasCoreStatus}
   */
  this._status = null

  /**
   * @private
   * @type {?BasCoreProjectInfo}
   */
  this._projectInfo = null

  /**
   * @private
   * @type {BasProfile[]}
   */
  this._users = []

  /**
   * @private
   * @type {BasProfile[]}
   */
  this._admins = []

  /**
   * Cached mapping of numeric MAC address to device uuid
   * Result of getting device info via REST call
   *
   * @private
   * @type {Object<string, string>}
   */
  this._coreClientDeviceInfo = {}

  /**
   * @private
   * @type {TCoreCredentials[]}
   */
  this._credentials = []

  /**
   * @private
   * @type {?string}
   */
  this._jwt = null

  /**
   * @private
   * @type {TCoreCredentials[]}
   */
  this._connectedCredentials = []

  /**
   * @private
   * @type {?BasCoreSocket}
   */
  this._socket = null

  /**
   * @private
   * @type {?BasCoreSocket}
   */
  this._v2Socket = null

  /**
   * @private
   * @type {Array}
   */
  this._socketListeners = []

  /**
   * @private
   * @type {Array}
   */
  this._v2SocketListeners = []

  this._handleResponse = this._onResponse.bind(this)
  this._handleVersion = this._onVersion.bind(this)
  this._handleSupports = this._onSupports.bind(this)
  this._handleStatus = this._onStatus.bind(this)
  this._handleProjectInfo = this._onProjectInfo.bind(this)
  this._handleUsers = this._onUsers.bind(this)
  this._handleLogin = this._onLogin.bind(this)
  this._handleImageSourceRequestProxy =
    this._onImageSourceRequestProxy.bind(this)
  this._handleSocketConnected = this._onSocketConnected.bind(this)
  this._handleSocketMessage = this._onSocketMessage.bind(this)
  this._handleSocketHeartbeat = this._onSocketHeartbeat.bind(this)
  this._handleSocketHeartbeatMissed =
    this._onSocketHeartbeatMissed.bind(this)
  this._handleSocketJWTRevoked =
    this._onSocketJWTRevoked.bind(this)

  this._handleV2SocketConnected =
    this._onV2SocketConnected.bind(this)
  this._handleV2SocketMessage =
    this._onV2SocketMessage.bind(this)

  this._parseOptions(options)
}

BasServer.prototype = Object.create(EventEmitter.prototype)
BasServer.prototype.constructor = BasServer

/**
 * @event BasServer#EVT_API_VERSION
 * @param {number} apiVersion
 */

/**
 * @event BasServer#hasUpdate
 * @param {boolean} hasUpdate
 * @deprecated
 */

/**
 * @event BasServer#EVT_CONNECTED
 * @param {boolean} isConnected
 */

/**
 * @event BasServer#EVT_CORE_CONNECTED
 * @param {boolean} isConnected
 */

/**
 * @event BasServer#EVT_MESSAGE
 * @param {Object} message
 */

/**
 * @constant {string}
 */
BasServer.EVT_API_VERSION = 'evtBasServerApiVersion'

/**
 * @constant {string}
 * @deprecated
 */
BasServer.EVT_HAS_UPDATE = 'evtBasServerHasUpdate'

/**
 * @constant {string}
 */
BasServer.EVT_CONNECTED = 'evtBasServerConnected'

/**
 * @constant {string}
 */
BasServer.EVT_CORE_CONNECTED = 'evtBasServerCoreConnected'

/**
 * @constant {string}
 */
BasServer.EVT_MESSAGE = 'evtBasServerMessage'

/**
 * @constant {string}
 */
BasServer.EVT_HEARTBEAT = 'evtBasServerHeartbeat'

/**
 * @constant {string}
 */
BasServer.EVT_HEARTBEAT_MISSED = 'evtBasServerHeartbeatMissed'

/**
 * @constant {string}
 */
BasServer.EVT_JWT_REVOKED = 'evtBasServerConnectionJWTRevoked'

/**
 * @constant {string}
 */
BasServer.EVT_CORE_V2_CONNECTED =
  'evtBasServerCoreV2Connected'

/**
 * @constant {string}
 */
BasServer.EVT_V2_MESSAGE =
  'evtBasServerV2Message'

/**
 * @constant {string}
 */
BasServer.REQUEST_ENCODING_JSON = P.JSON

/**
 * @constant {string}
 */
BasServer.REQUEST_ENCODING_BASE64 = P.BASE64

/**
 * @constant {number}
 */
BasServer.BASE64_IMAGE_TIMEOUT_MS = 5000

/**
 * @constant {number}
 */
BasServer.PROXY_REQUEST_TIMEOUT_MS = 5000

/**
 * @private
 * @constant {number}
 */
BasServer._HTTP_TIMEOUT_MS = 3000

/**
 * Parse TBasServerRequestConfig to format for server (send over socket)
 *
 * @param {TBasServerRequestConfig} config
 * @returns {?Object}
 */
BasServer.parseBasServerRequestConfig = function (config) {

  var result

  if (BasUtil.isObject(config)) {

    result = {}

    if (BasUtil.isNEString(config.scheme)) {

      result[P.SCHEME] = config.scheme
    }

    if (BasUtil.isNEString(config.method)) {

      result[P.METHOD] = config.method
    }

    if (BasUtil.isNEString(config.url)) {

      result[P.URL] = config.url
    }

    if (BasUtil.isNEString(config.host)) {

      result[P.HOST] = config.host
    }

    if (BasUtil.isPNumber(config.port)) {

      result[P.PORT] = config.port
    }

    if (BasUtil.isNEString(config.path)) {

      result[P.PATH] = config.path
    }

    if (BasUtil.isObject(config.params)) {

      result[P.PARAMS] = config.params
    }

    if (BasUtil.isObject(config.headers)) {

      result[P.HEADERS] = config.headers
    }

    if (BasUtil.isNEString(config.encoding)) {

      result[P.ENCODING] = config.encoding
    }

    if (BasUtil.isPNumber(config.timeout)) {

      result[P.TIMEOUT] = config.timeout
    }

    if ('data' in config && !BasUtil.isFunction(config.data)) {

      result[P.BODY] = config.data
    }

    return result
  }

  return null
}

/**
 * Config will not contain URI encoded parameters.
 *
 * @param {Url} parsedUrl
 * @returns {?TBasServerRequestConfig}
 */
BasServer.parseUrlToBasServerRequestConfig = function (parsedUrl) {

  var result, value, length

  if (parsedUrl instanceof Url) {

    /**
     * @type {TBasServerRequestConfig}
     */
    result = {}

    // Scheme / protocol

    value = parsedUrl.protocol

    if (value) {

      length = value.length

      if (value.charAt(length - 1) === ':') {

        value = value.substring(0, length - 1)
      }

      result.scheme = value
    }

    // Host

    value = parsedUrl.hostname
    if (value) result.host = value

    // Port

    value = parsedUrl.port

    if (value) {

      value = parseInt(value, 10)

      if (BasUtil.isPNumber(value)) result.port = value
    }

    // Path

    value = parsedUrl.pathname
    if (value) result.path = value

    // Query parameters

    value = parsedUrl.query

    if (BasUtil.isObject(value)) {

      result.params = value
    }

    return result
  }

  return null
}

/**
 * @param {Object} result
 * @returns {(TBasServerResponse|Promise)}
 */
BasServer.handleRequestProxy = function (result) {

  var _httpResponse, _msg

  _httpResponse = {}

  _httpResponse.status = 0
  _httpResponse.headers = null
  _httpResponse.data = undefined

  if (BasUtil.isObject(result)) {

    _httpResponse.status = result[P.STATUS]
    _httpResponse.headers =
      requestUtil.parseHeaders(result[P.HEADERS])
    _httpResponse.apiVersion = _httpResponse.headers
      ? parseInt(
        _httpResponse.headers[P.H_X_BASALTE_API],
        10
      )
      : CONSTANTS.DEFAULT_API_VERSION
    _httpResponse.data = result[P.BODY]
  }

  if (BasUtil.isPNumber(_httpResponse.status, true)) {

    if (_httpResponse.status > 199 && _httpResponse.status < 300) {

      return _httpResponse
    }

    _msg = requestUtil.parseErrorStatus(_httpResponse.status)

    if (_msg) return Promise.reject(_msg)
  }

  return Promise.reject(_httpResponse)
}

/**
 * @param {TBasServerResponse} result
 * @returns {(string|Promise)}
 */
BasServer.handleBasServerResponseBase64Image = function (result) {

  var _contentType

  if (BasUtil.isNEString(result.data)) {

    _contentType = result.headers[P.H_CONTENT_TYPE]
    _contentType = _contentType || P.V_IMAGE_JPEG

    return 'data:' + _contentType + ';base64,' + result.data
  }

  return Promise.reject(CONSTANTS.ERR_RESULT)
}

/**
 * MAC address for the server to which this BasServer will/is connect(ed).
 * This should not be used to show the MAC address of the server.
 * Use the information from connected devices for that.
 *
 * @name BasServer#macN
 * @type {number}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'macN', {
  get: function () {
    return this._macN
  }
})

/**
 * MAC address for the server to which this BasServer will/is connect(ed).
 * This should not be used to show the MAC address of the server.
 * Use the information from connected devices for that.
 *
 * @name BasServer#mac
 * @type {string}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'mac', {
  get: function () {
    return this._mac
  }
})

/**
 * BasHost
 *
 * @name BasServer#host
 * @type {?BasHost}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'host', {
  get: function () {
    return this._host
  }
})

/**
 * Configuration/Project identifier
 *
 * @name BasServer#cid
 * @type {string}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'cid', {
  get: function () {
    return this._cid
  }
})

/**
 * Demo
 *
 * @name BasServer#demo
 * @type {?Demo}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'demo', {
  get: function () {
    return this._demo
  }
})

/**
 * Basalte protocol version
 *
 * @name BasServer#apiVersion
 * @type {number}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'apiVersion', {
  get: function () {
    return this._apiVersion
  }
})

/**
 * Is Basalte protocol version known?
 *
 * @name BasServer#apiVersionKnown
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'apiVersionKnown', {
  get: function () {
    return this._apiVersionKnown
  }
})

/**
 * Support login with JSON Web token
 *
 * @name BasCore#supportsJwtLogin
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'supportsJwtLogin', {
  get: function () {
    return this._supports ? !!this._supports[P.JWT_LOGIN] : false
  }
})

/**
 * Unsupported API.
 * NaN means not yet determined, wait for API version known.
 *
 * @name BasServer#unsupportedApi
 * @type {number}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'unsupportedApi', {
  get: function () {
    return this._unsupportedApi
  }
})

/**
 * Cached version
 *
 * @name BasServer#version
 * @type {?Object}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'version', {
  get: function () {
    return this._version
  }
})

/**
 * Cached server status.
 * This is legacy. Look at the server device(s) for status info.
 *
 * @name BasServer#status
 * @type {?BasCoreStatus}
 * @readonly
 * @deprecated
 */
Object.defineProperty(BasServer.prototype, 'status', {
  get: function () {
    return this._status
  }
})

/**
 * Cached project info
 *
 * @name BasServer#projectInfo
 * @type {?BasCoreProjectInfo}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'projectInfo', {
  get: function () {
    return this._projectInfo
  }
})

/**
 * Cached users
 *
 * @name BasServer#users
 * @type {BasProfile[]}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'users', {
  get: function () {
    return this._users.sort(
      function (a, b) {
        return b.isCloudAccount - a.isCloudAccount
      }
    )
  }
})

/**
 * Cached admins
 *
 * @name BasServer#admins
 * @type {BasProfile[]}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'admins', {
  get: function () {
    return this._admins
  }
})

/**
 * Cached mapping of numeric MAC address to Core client uuid
 * Result of getting CoreClientInfo via REST call
 *
 * @name BasServer#coreClientDeviceInfo
 * @type {Object<string, string>}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'coreClientDeviceInfo', {
  get: function () {
    return this._coreClientDeviceInfo
  }
})

/**
 * Cached credentials from login.
 *
 * @name BasServer#credentials
 * @type {TCoreCredentials[]}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'credentials', {
  get: function () {
    return this._credentials
  }
})

/**
 * Cached credentials used for connecting.
 *
 * @name BasServer#connectedCredentials
 * @type {TCoreCredentials[]}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'connectedCredentials', {
  get: function () {
    return this._connectedCredentials
  }
})

/**
 * Whether JWT was used to connect to the server
 *
 * @name BasServer#connectedCredentials
 * @type {TCoreCredentials[]}
 * @readonly
 */
Object.defineProperty(BasServer.prototype, 'connectedUsingCloudAccount', {
  get: function () {
    return (
      !!this._connectedCredentials[0] &&
      !!this._connectedCredentials[0].jwt &&
      !this._connectedCredentials[0].user
    )
  }
})

/**
 * @private
 * @param {TBasServerOptions} [options]
 */
BasServer.prototype._parseOptions = function (options) {

  if (BasUtil.isObject(options)) {

    // Will only set/update MAC address if valid
    this._setMac(options.macAddress)

    this.setProjectId(options.cid)

    this.setDerivedName(options.name)

    this.setUseSubscriptionSocket(options.useSubscriptionSocket)
  }
}

/**
 * @private
 * @param {(number|string)} mac
 */
BasServer.prototype._setMac = function (mac) {

  var value

  if (BasUtil.isNEString(mac)) {

    value = macAddressUtil.convertToNumber(mac)

    if (value) {

      this._macN = value
      this._mac = macAddressUtil.convertToMac(value)
    }

  } else if (BasUtil.isPNumber(mac)) {

    value = macAddressUtil.convertToMac(mac)

    if (value) {

      this._macN = mac
      this._mac = value
    }
  }
}

/**
 * Checks if given MAC address is the same.
 * Always returns false if there is no MAC address.
 *
 * @param {(number|string)} mac
 * @returns {boolean}
 */
BasServer.prototype.hasSameMac = function (mac) {

  var value

  if (!this._macN) return false

  if (BasUtil.isNEString(mac)) {

    value = macAddressUtil.convertToNumber(mac)

    return this._macN === value

  } else if (BasUtil.isNumber(mac)) {

    return this._macN === mac
  }

  return false
}

/**
 * Checks whether this BasServer instance has a Demo MAC address.
 *
 * @returns {boolean}
 */
BasServer.prototype.hasDemoMac = function () {

  return this._macN === Demo.DEMO_MAC_N
}

/**
 * @returns {boolean}
 */
BasServer.prototype.isDemo = function () {

  return BasUtil.isObject(this._demo)
}

/**
 * @returns {boolean}
 */
BasServer.prototype.isRemote = function () {

  return false
}

/**
 * Set the Project/Configuration ID
 *
 * @param {string} projectId
 */
BasServer.prototype.setProjectId = function (projectId) {

  if (BasUtil.isNEString(projectId) && this._cid !== projectId) {

    this._cid = projectId
  }
}

/**
 * Set the Project/Configuration name (ex. from discovery)
 *
 * @param {string} derivedName
 */
BasServer.prototype.setDerivedName = function (derivedName) {

  if (BasUtil.isNEString(derivedName)) this._derivedName = derivedName
}

/**
 * Set wheter server should use subscription websocket
 *
 * @param {boolean} value
 */
BasServer.prototype.setUseSubscriptionSocket = function (value) {

  if (BasUtil.isBool(value)) this._useSubscriptionSocket = value
}

/**
 * Configuration/Project name (from project info or derived ex. from discovery)
 *
 * @returns {string}
 */
BasServer.prototype.getName = function () {

  return (this._projectInfo && BasUtil.isString(this._projectInfo.name))
    ? this._projectInfo.name
    : this._derivedName
}

/**
 * Get the project info "master" property.
 * "undefined" can mean project info is not available or
 * project info does not contain the "master" property.
 *
 * @returns {(boolean|undefined)}
 */
BasServer.prototype.getMasterState = function () {

  var value

  if (this._projectInfo) {

    value = this._projectInfo[P.MASTER]
    if (BasUtil.isBool(value)) return value
  }
}

/**
 * "HTTP" request, could be emulated HTTP.
 *
 * @abstract
 * @param {TBasServerRequestConfig} config
 * @returns {Promise<TBasServerResponse>}
 */
// eslint-disable-next-line no-unused-vars
BasServer.prototype.request = function (config) {

  return Promise.reject(CONSTANTS.ERR_NOT_IMPLEMENTED)
}

/**
 * @private
 * @param {TBasServerResponse} result
 * @returns {TBasServerResponse}
 */
BasServer.prototype._onResponse = function (result) {

  var _oldApiVersionKnown, _oldApiVersion

  if (BasUtil.isObject(result)) {

    _oldApiVersionKnown = this._apiVersionKnown
    _oldApiVersion = this._apiVersion

    this._apiVersionKnown = true
    this._apiVersion = isNaN(result.apiVersion)
      ? CONSTANTS.DEFAULT_API_VERSION
      : result.apiVersion
    this._unsupportedApi = CONSTANTS.unsupportedApi(this._apiVersion)

    if (_oldApiVersionKnown !== this._apiVersionKnown ||
      _oldApiVersion !== this._apiVersion) {

      this.emit(BasServer.EVT_API_VERSION, this._apiVersion)
    }
  }

  return result
}

/**
 * @abstract
 * @param {TBasServerRequestConfig} config
 * @returns {Promise}
 */
// eslint-disable-next-line no-unused-vars
BasServer.prototype.requestProxy = function (config) {

  return Promise.reject(CONSTANTS.ERR_NOT_IMPLEMENTED)
}

/**
 * @abstract
 * @param {string} uri
 * @returns {Promise<string>}
 */
// eslint-disable-next-line no-unused-vars
BasServer.prototype.requestImageSource = function (uri) {

  return Promise.reject(CONSTANTS.ERR_NOT_IMPLEMENTED)
}

/**
 * @param {string} uri
 * @returns {Promise<string>}
 */
BasServer.prototype.retrieveImageSource = function (uri) {

  var _url, _parsedUrl, _config

  if (BasUtil.isNEString(uri)) {

    if (BasUtil.hasValidKnownScheme(uri)) return Promise.resolve(uri)

    if (BasUtil.startsWith(uri, CONSTANTS.CORE_PROXY_SCHEME)) {

      _url = BasUtil.stripStart(uri, CONSTANTS.CORE_PROXY_SCHEME)

      if (this.isDemo()) return Promise.resolve(_url)

      if (this.isConnected()) {

        if (BasUtil.hasValidKnownScheme(_url)) {

          _config = {}
          _config.url = _url

        } else {

          _parsedUrl = new Url(_url, true)

          _config =
            BasServer.parseUrlToBasServerRequestConfig(_parsedUrl)
        }

        if (_config) {

          _config.headers = {}
          _config.headers[P.H_ACCEPT] = '*/*'
          _config.encoding = BasServer.REQUEST_ENCODING_BASE64

          return this.requestProxy(_config)
            .then(BasServer.handleBasServerResponseBase64Image)
        }
      }

      return Promise.resolve(_url)
    }

    return this.requestImageSource(uri)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @private
 * @param {TBasServerResponse} result
 * @returns {string}
 */
BasServer.prototype._onImageSourceRequestProxy = function (
  result
) {
  return BasUtil.isNEString(result.data) ? result.data : ''
}

/**
 * HTTP path:
 *
 * All requests should go to the BasCore on address:
 * http://<server_address>/api/spotify/
 *
 * All request should be POST request.
 * The request should have a header 'X-Basalte-Method'
 * which defines the HTTP request type for the actual request
 * to the Spotify API.
 * For example: X-Basalte-Method: GET
 *
 * The Spotify API url should be appended to the BasCore Spotify API URL
 * For example:
 * GET https://api.spotify.com/v1/audio-analysis/3iYiV6IDYj8S9GKJPGNleq
 * Becomes:
 * POST http://<SERVER_IP>/api/spotify/v1/audio-analysis/3iYiV6IDYj8S9GKJPGNleq
 * with header:
 * X-Basalte-Method: GET
 *
 * @param {TBasServerSpotifyRequestConfig} config
 * @returns {Promise}
 */
BasServer.prototype.requestSpotifyProxy = function (config) {

  var _config

  if (BasUtil.isObject(config) && BasUtil.isNEString(config.path)) {

    _config = {}
    _config.path = '/api/spotify'
    _config.path += (config.path.charAt(0) === '/'
      ? config.path
      : ('/' + config.path)
    )
    _config.method = P.C_POST

    _config.headers = BasUtil.isObject(config.headers)
      ? config.headers
      : {}

    _config.headers[P.H_X_BASALTE_METHOD] = config.method
      ? config.method
      : P.C_GET

    if (BasUtil.isObject(config.params)) {

      _config.params = config.params
    }

    if ('data' in config) _config.data = config.data

    return this.requestProxy(_config)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Returns an array with all versions.
 * This is a HTTP GET request.
 *
 * @returns {Promise<TBasServerResponse>}
 */
BasServer.prototype.getVersion = function () {

  return this.request({
    path: CONSTANTS.PATH_API_VERSION,
    timeout: BasServer._HTTP_TIMEOUT_MS
  })
    .then(this._handleResponse)
    .then(this._handleVersion)
}

/**
 * Returns a key-value object with supported features
 * This is a HTTP GET request.
 *
 * @returns {Promise<TBasServerResponse>}
 */
BasServer.prototype.getSupports = function () {

  return this.request({
    path: CONSTANTS.PATH_API_SUPPORTS,
    timeout: BasServer._HTTP_TIMEOUT_MS
  })
    .then(this._handleResponse)
    .then(this._handleSupports)
}

/**
 * @private
 * @param {TBasServerResponse} result
 * @returns {(TBasServerResponse|Promise)}
 */
BasServer.prototype._onVersion = function (result) {

  if (BasUtil.isObject(result)) {

    this._version = BasUtil.isObject(result.data) ? result.data : null

    return result
  }

  return Promise.reject(CONSTANTS.ERR_RESULT)
}

/**
 * @private
 * @param {TBasServerResponse} result
 * @returns {(TBasServerResponse|Promise)}
 */
BasServer.prototype._onSupports = function (result) {

  if (BasUtil.isObject(result)) {

    this._supports = BasUtil.isObject(result.data) ? result.data : null

    return result
  }

  return Promise.reject(CONSTANTS.ERR_RESULT)
}

/**
 * Returns status.
 * This is a HTTP GET request.
 *
 * @returns {Promise<TBasServerResponse>}
 */
BasServer.prototype.getStatus = function () {

  return this.request({
    path: CONSTANTS.PATH_API_STATUS,
    timeout: BasServer._HTTP_TIMEOUT_MS
  })
    .then(this._handleResponse)
    .then(this._handleStatus)
}

/**
 * @private
 * @param {TBasServerResponse} result
 * @returns {(TBasServerResponse|Promise)}
 */
BasServer.prototype._onStatus = function (result) {

  var _oldHasUpdate, _newHasUpdate

  if (BasUtil.isObject(result)) {

    _oldHasUpdate = this._status ? this._status.hasUpdate : false

    this._status = BasCoreStatus.parse(result.data)

    _newHasUpdate = this._status ? this._status.hasUpdate : false

    if (_oldHasUpdate !== _newHasUpdate) {

      this.emit(BasServer.EVT_HAS_UPDATE, _newHasUpdate)
    }

    return result
  }

  return Promise.reject(CONSTANTS.ERR_RESULT)
}

/**
 * @returns {Promise<TBasServerResponse>}
 */
BasServer.prototype.getProjectInfo = function () {

  return this.request({
    path: CONSTANTS.PATH_API_PROJECT,
    timeout: BasServer._HTTP_TIMEOUT_MS
  })
    .then(this._handleResponse)
    .then(this._handleProjectInfo)
}

/**
 * @private
 * @param {TBasServerResponse} result
 * @returns {(TBasServerResponse|Promise)}
 */
BasServer.prototype._onProjectInfo = function (result) {

  var _projectInfo

  if (BasUtil.isObject(result)) {

    _projectInfo = BasCoreProjectInfo.parse(result.data)

    if (_projectInfo) {

      if (!this._cid) {

        this._cid = _projectInfo.uuid
        this._projectInfo = _projectInfo

      } else {

        if (_projectInfo.uuid === this._cid) {

          this._projectInfo = _projectInfo

        } else {

          log.warn(
            'BasServer - Project info' +
            ' - Project UUID does not match',
            this._cid,
            _projectInfo
          )
        }
      }
    }

    return result
  }

  return Promise.reject(CONSTANTS.ERR_RESULT)
}

/**
 * @param {number} mac MAC address in number form (radix 10)
 * @returns {Promise<TBasServerDeviceInfoResponse>}
 */
BasServer.prototype.getEllieInfo = function (mac) {

  var _this

  _this = this

  return this.request({
    path: CONSTANTS.PATH_API_ELLIE + '/' + mac,
    timeout: BasServer._HTTP_TIMEOUT_MS
  })
    .then(this._handleResponse)
    .then(_onEllieInfo)

  /**
   * @param {TBasServerResponse} result
   * @returns {TBasServerResponse}
   */
  function _onEllieInfo (result) {

    var _ellie

    if (BasUtil.isObject(result[P.DATA])) {

      _ellie = result[P.DATA][P.ELLIE]
    }

    if (BasUtil.isObject(_ellie) &&
      BasUtil.isNEString(_ellie[P.UUID])) {

      _this._coreClientDeviceInfo[mac] = _ellie[P.UUID]
    }

    return result
  }
}

/**
 * @param {number} mac MAC address in number form (radix 10)
 * @returns {Promise<TBasServerDeviceInfoResponse>}
 */
BasServer.prototype.getCoreClientDeviceInfo = function (mac) {

  var _this

  _this = this

  return BasUtil.promiseAny([
    this.request({
      path: CONSTANTS.PATH_API_DEVICE + '/' + mac,
      timeout: BasServer._HTTP_TIMEOUT_MS
    })
      .then(this._handleResponse)
      .then(_onDeviceInfo),
    this.getEllieInfo(mac)
  ])

  /**
   * @param {TBasServerResponse} result
   * @returns {TBasServerResponse}
   */
  function _onDeviceInfo (result) {

    var _device

    if (BasUtil.isObject(result[P.DATA])) {

      _device = result[P.DATA][P.DEVICE]
    }

    if (BasUtil.isObject(_device) &&
      BasUtil.isNEString(_device[P.UUID])) {

      _this._coreClientDeviceInfo[mac] = _device[P.UUID]
    }

    return result
  }
}

/**
 * @returns {Promise<TBasServerUsersResponse>}
 */
BasServer.prototype.getUsers = function () {

  return this.request({
    path: CONSTANTS.PATH_API_USERS,
    timeout: BasServer._HTTP_TIMEOUT_MS
  })
    .then(this._handleResponse)
    .then(this._handleUsers)
}

/**
 * @private
 * @param {TBasServerResponse} result
 * @returns {(TBasServerUsersResponse|Promise)}
 */
BasServer.prototype._onUsers = function (result) {

  var _users, _profiles, length, i, entry, profile

  _users = result.data

  if (Array.isArray(_users)) {

    _profiles = []

    length = _users.length
    for (i = 0; i < length; i++) {

      entry = _users[i]

      if (BasProfile.isUserObject(entry)) {

        profile = new BasProfile(entry)
        _profiles.push(profile)
      }
    }

    this._users = _profiles

    this._admins = []
    length = this._users.length
    for (i = 0; i < length; i++) {

      profile = this._users[i]

      if (profile.isAdmin) this._admins.push(profile)
    }

    result.data = this._users

    return result
  }

  return Promise.reject(CONSTANTS.ERR_RESULT)
}

/**
 * @param {BasProfile} basProfile
 */
BasServer.prototype.addProfileToUsers = function (basProfile) {
  var profileExists = this._users.some(
    function (currentProfile) {
      return currentProfile.uuid === basProfile.uuid
    }
  )

  basProfile.isCloudAccount = this.connectedUsingCloudAccount
  if (!profileExists) this._users.push(basProfile)

}

/**
 * @param {string} user Username
 * @param {string} [password] Password
 * @param {string} [hash] Credential hash
 * @returns {Promise<Array<TBasServerCredentialsResponse>>}
 */
BasServer.prototype.login = function (
  user,
  password,
  hash
) {

  var promises = [this._login(user, password, hash)]
  this._credentials = []

  // Login twice so we can connect to 2 websockets
  // Talked to Tibault for future improvement of reusing same credentials for
  //  connecting to 2 websockets, since this does not work currently
  if (this._useSubscriptionSocket) {

    promises.push(this._login(user, password, hash))
  }
  return Promise.all(promises)
}

BasServer.prototype.clearCredentials = function () {
  this._credentials = []
}

/**
 * @param {string} jwt
 */
BasServer.prototype.setJWT = function (jwt) {
  this._jwt = jwt
}

/**
 * @param {string} user Username
 * @param {string} [password] Password
 * @param {string} [hash] Credential hash
 * @returns {Promise<TBasServerCredentialsResponse>}
 */
BasServer.prototype._login = function (
  user,
  password,
  hash
) {
  var _this, _credentials, _params, _password

  _this = this

  _credentials = {
    user: user,
    credentialHash: '',
    token: '',
    credentialTokenHash: ''
  }

  _password = BasUtil.isNEString(password) ? password : ''

  _params = {}
  _params[P.U] = user
  // To avoid cached responses of this GET request, a cache buster query
  //  parameter was added. This caching behaviour was seen on iOS when the login
  //  process is executed twice simultaneously (CAP-918), and possibly in other
  //  cases as well (CAP-835).
  _params[P.CACHE_BUSTER] = Date.now() + '-' + (Math.random() + '').slice(2, 12)

  return this.request({
    path: CONSTANTS.PATH_API_LOGIN,
    params: _params,
    timeout: BasServer._HTTP_TIMEOUT_MS
  })
    .then(this._handleResponse)
    .then(_onChallenge, _onChallengeError)
    .then(this._handleLogin)

  /**
   * @private
   * @param {TBasServerResponse} result
   * @returns {Promise<TBasServerResponse>}
   */
  function _onChallenge (result) {

    var _data, _challenge, _cNonce, _hash

    if (BasUtil.isObject(result.data) &&
      result.data[P.USER] === _credentials.user &&
      BasUtil.isNEString(result.data[P.CHALLENGE])) {

      _challenge = result.data[P.CHALLENGE]

      // Check login type - username/password OR credential Hash
      _credentials.credentialHash = BasUtil.isNEString(hash)
        ? hash
        : BasCrypto.sha256(
          _credentials.user +
          BasCrypto.sha256(
            result.data[P.SALT] +
            _password
          )
        )

      _cNonce = BasCrypto.getCNonce()

      _hash = BasCrypto.sha256(
        _credentials.credentialHash + _challenge + _cNonce
      )

      _data = {}
      _data[P.USER] = _credentials.user
      _data[P.CHALLENGE] = _challenge
      _data[P.CNONCE] = _cNonce
      _data[P.HASH] = _hash

      return _this.request({
        method: P.C_POST,
        path: CONSTANTS.PATH_API_LOGIN,
        data: _data,
        timeout: BasServer._HTTP_TIMEOUT_MS
      })
        .then(_this._handleResponse)
        .then(_onToken, _onTokenError)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
  }

  /**
   * @private
   * @param {*} error
   * @returns {Promise}
   */
  function _onChallengeError (error) {

    if (error === CONSTANTS.ERR_FORBIDDEN) {

      // User does not exist
      return Promise.reject(CONSTANTS.ERR_CREDENTIALS)
    }

    return Promise.reject(CONSTANTS.ERR_NETWORK)
  }

  /**
   * @private
   * @param {TBasServerResponse} result
   * @returns {Promise<TBasServerCredentialsResponse>}
   */
  function _onToken (result) {

    if (BasUtil.isObject(result.data) &&
      result.data[P.USER] === _credentials.user &&
      BasUtil.isNEString(result.data[P.TOKEN])) {

      _credentials.token = result.data[P.TOKEN]

      // Create credential token hash H( CREDENTIAL_HASH + TOKEN )
      _credentials.credentialTokenHash = BasCrypto.sha256(
        _credentials.credentialHash + _credentials.token
      )

      result.data = _credentials

      return result
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
  }

  /**
   * @private
   * @param {*} error
   * @returns {Promise}
   */
  function _onTokenError (error) {

    if (error === CONSTANTS.ERR_FORBIDDEN) {

      // Password incorrect
      return Promise.reject(CONSTANTS.ERR_CREDENTIALS)
    }

    return Promise.reject(CONSTANTS.ERR_NETWORK)
  }
}

/**
 * @private
 * @param {TBasServerCredentialsResponse} result
 * @returns {TBasServerCredentialsResponse}
 */
BasServer.prototype._onLogin = function (result) {

  this._credentials.push(result.data)

  return result
}

/**
 * Starts the update checker on the server.
 * This is a HTTP GET request.
 *
 * @returns {Promise<TBasServerResponse>}
 */
BasServer.prototype.startUpdateCheck = function () {

  return this.request({
    path: CONSTANTS.PATH_API_UPDATE,
    timeout: BasServer._HTTP_TIMEOUT_MS
  }).then(this._handleResponse)
}

/**
 * Disconnect from the server and remove all stored credential
 * information.
 * This will also invalidate the current session token.
 *
 * Important! Disconnect before executing!
 *
 * @param {TCoreCredentials} [credentials]
 * @returns {Promise<TBasServerResponse>}
 */
BasServer.prototype.logout = function (credentials) {

  var _credentials, _cNonce, _data
  var length, i, promises, iCredentials

  _credentials = credentials || (this._connectedCredentials.length
    ? this._connectedCredentials
    : this._credentials)

  if (!Array.isArray(_credentials)) _credentials = [_credentials]

  if (_credentials.length) {

    promises = []
    length = _credentials.length
    for (i = 0; i < length; i++) {

      iCredentials = _credentials[i]

      if (iCredentials) {

        _cNonce = BasCrypto.getCNonce()

        _data = {}
        _data[P.USER] = iCredentials.user
        _data[P.TOKEN] = iCredentials.token
        _data[P.CNONCE] = _cNonce
        _data[P.HASH] = BasCrypto.sha256(
          iCredentials.credentialTokenHash + _cNonce
        )

        promises.push(
          this.request({
            method: P.C_POST,
            path: CONSTANTS.PATH_API_LOGOUT,
            data: _data,
            timeout: BasServer._HTTP_TIMEOUT_MS
          }).then(this._handleResponse)
        )
      }
    }

    return Promise.all(promises)
  }

  return Promise.reject(CONSTANTS.ERR_CREDENTIALS)
}

/**
 * ONLY FOR ADMINS! Reboots the server (eg to install updates).
 * This will indirectly invalidate all session tokens.
 *
 * @param {TCoreCredentials} [credentials]
 * @returns {Promise<TBasServerResponse>}
 */
BasServer.prototype.restart = function (credentials) {

  var _credentials, _cNonce, _data

  _credentials = credentials || (this._connectedCredentials[0]
    ? this._connectedCredentials[0]
    : this._credentials[0])

  if (_credentials) {

    _cNonce = BasCrypto.getCNonce()

    _data = {}
    _data[P.USER] = _credentials.user
    _data[P.TOKEN] = _credentials.token
    _data[P.CNONCE] = _cNonce
    _data[P.HASH] = BasCrypto.sha256(
      _credentials.credentialTokenHash +
      _cNonce +
      CONSTANTS.H_RESTART_PATH
    )

    return this.request({
      method: P.C_POST,
      path: CONSTANTS.PATH_API_RESTART,
      data: _data,
      timeout: BasServer._HTTP_TIMEOUT_MS
    }).then(this._handleResponse)
  }

  return Promise.reject(CONSTANTS.ERR_CREDENTIALS)
}

/**
 * @param {Object<string, (string | number)>} [params]
 * @returns {Promise<TBasServerResponse>}
 */
BasServer.prototype.getWeatherData = function (params) {

  return this.request({
    path: CONSTANTS.PATH_API_FORECAST,
    params: params,
    timeout: BasServer._HTTP_TIMEOUT_MS
  }).then(this._handleResponse)
}

/**
 * @abstract
 * @returns {boolean}
 */
BasServer.prototype.isConnected = function () {

  return false
}

/**
 * @abstract
 * @returns {boolean}
 */
BasServer.prototype.isCoreConnected = function () {

  return false
}

/**
 * Do not use this directly, use connectCore
 *
 * @abstract
 * @param {(TCoreCredentials|Array<TCoreCredentials>)} credentials
 * @returns {Promise}
 */
// eslint-disable-next-line no-unused-vars
BasServer.prototype.connectToCore = function (credentials) {

  return Promise.reject(CONSTANTS.ERR_NOT_IMPLEMENTED)
}

/**
 * Do not use this directly, use disconnectCore
 *
 * @abstract
 * @returns {Promise}
 */
BasServer.prototype.disconnectFromCore = function () {

  return Promise.reject(CONSTANTS.ERR_NOT_IMPLEMENTED)
}

/**
 * Connects/opens the Core channel to the server.
 *
 * Uses the internal stored credentials.
 *
 * @returns {Promise}
 */
BasServer.prototype.connectCore = function () {

  var _this, _credentials

  _this = this

  // Need 2 credentials, 1 for 'old' ws and 1 for new ws
  if (
    this._credentials.length < (this._useSubscriptionSocket ? 2 : 1)
  ) {

    if (!this._jwt) return Promise.reject(CONSTANTS.ERR_CREDENTIALS)

    _credentials = [{}, {}]
  } else {
    _credentials = this._credentials
  }

  // Copy credentials and add JWT
  _credentials = _credentials.map(function (value) {
    var obj = BasUtil.copyObject(value)
    obj.jwt = _this._jwt
    return obj
  })

  this._setV2Listeners(this._socket)
  this._setV2SocketListeners(this._v2Socket)

  _this._connectedCredentials = _credentials.slice()

  return this.connectToCore(_credentials)
}

/**
 * Disconnects/closes the Core channel to the server.
 *
 * @returns {Promise}
 */
BasServer.prototype.disconnectCore = function () {

  return this.disconnectFromCore()
}

/**
 * Terminates all connections
 */
BasServer.prototype.disconnect = function () {

  this.disconnectCore().catch(_empty)
}

/**
 * Send a message to the server, returns true if successful
 *
 * @param {Object} data
 * @returns {boolean}
 */
BasServer.prototype.send = function (data) {

  return this._socket ? this._socket.send(data) : false
}

/**
 * Sends a request to the server.
 * The returned Promise will resolve with the answer.
 *
 * @param {Object} data
 * @param {TBasRequestOptions} [options]
 * @returns {Promise}
 */
BasServer.prototype.requestRetry = function (
  data,
  options
) {

  return this._socket
    ? this._socket.requestRetry(data, options)
    : Promise.reject(CONSTANTS.ERR_CONNECTION)
}

/**
 * Sends a request to the server via the subscription (v2) socket.
 * The returned Promise will resolve with the answer.
 *
 * @param {Object} data
 * @param {number} [timeout]
 * @returns {Promise}
 */
BasServer.prototype.requestV2 = function (
  data,
  timeout
) {

  return this._v2Socket
    ? this._v2Socket.request(data, timeout)
    : Promise.reject(CONSTANTS.ERR_CONNECTION)
}

/**
 * Look in cached users for profile
 *
 * @param {string} name
 * @returns {?BasProfile}
 */
BasServer.prototype.getProfileForName = function (name) {

  var length, i, profile

  length = this._users.length
  for (i = 0; i < length; i++) {

    profile = this._users[i]

    if (profile && profile.username === name) return profile
  }

  return null
}

/**
 * Look in cached users for profile
 * which matches the current connected credentials.
 *
 * @returns {?BasProfile}
 */
BasServer.prototype.getConnectedProfile = function () {

  var _name, uuid, _cloudProfile

  if (this._connectedCredentials[0]) {

    // Local account
    _name = this._connectedCredentials[0].user

    if (_name) return this.getProfileForName(_name)

    // Cloud account
    if (this.connectedUsingCloudAccount) {

      uuid = this._connectedCredentials[0][P.UUID]

      if (BasUtil.isNEString(uuid)) {

        _cloudProfile = this._users.filter(
          function (user) {
            return user.uuid === uuid
          }
        )

        if (_cloudProfile.length) return _cloudProfile[0]
      }
    }
  }

  return null
}

/**
 * Creates a clone of this BasServer
 *
 * @returns {BasServer}
 */
BasServer.prototype.clone = function () {

  var _options

  _options = {}

  if (this._macN) _options.macAddress = this._macN
  if (this._cid) _options.cid = this._cid
  if (BasUtil.isBool(this._useSubscriptionSocket)) {

    _options.useSubscriptionSocket = this._useSubscriptionSocket
  }

  if (this.isDemo()) _options.demo = BasUtil.copyObject(this._demo.data)

  return new BasServer(_options)
}

/**
 * @private
 * @param {boolean} isConnected
 */
BasServer.prototype._onSocketConnected = function (isConnected) {

  this.emit(BasServer.EVT_CORE_CONNECTED, isConnected)
}

/**
 * @private
 * @param {Object} message
 */
BasServer.prototype._onSocketMessage = function (message) {

  this.emit(BasServer.EVT_MESSAGE, message)
}

/**
 * @private
 */
BasServer.prototype._onSocketHeartbeat = function () {

  this.emit(BasServer.EVT_HEARTBEAT)
}

/**
 * @private
 */
BasServer.prototype._onSocketHeartbeatMissed = function () {

  this.emit(BasServer.EVT_HEARTBEAT_MISSED)
}

/**
 * @private
 */
BasServer.prototype._onSocketJWTRevoked = function () {

  this.emit(BasServer.EVT_JWT_REVOKED)
}

/**
 * @private
 * @param {BasCoreSocket} socket
 */
BasServer.prototype._setV2Listeners = function (socket) {

  this._clearSocketListeners()

  if (socket) {

    this._socketListeners.push(BasUtil.setEventListener(
      socket,
      BasCoreSocket.EVT_CONNECTED,
      this._handleSocketConnected
    ))
    this._socketListeners.push(BasUtil.setEventListener(
      socket,
      BasCoreSocket.EVT_MESSAGE,
      this._handleSocketMessage
    ))
    this._socketListeners.push(BasUtil.setEventListener(
      socket,
      BasCoreSocket.EVT_HEARTBEAT,
      this._handleSocketHeartbeat
    ))
    this._socketListeners.push(BasUtil.setEventListener(
      socket,
      BasCoreSocket.EVT_HEARTBEAT_MISSED,
      this._handleSocketHeartbeatMissed
    ))
    this._socketListeners.push(BasUtil.setEventListener(
      socket,
      BasCoreSocket.EVT_JWT_REVOKED,
      this._handleSocketJWTRevoked
    ))
  }
}

BasServer.prototype._clearSocketListeners = function () {

  BasUtil.executeArray(this._socketListeners)
  this._socketListeners = []
}

BasServer.prototype._onV2SocketConnected = function (isConnected) {

  this.emit(BasServer.EVT_CORE_V2_CONNECTED, isConnected)
}

/**
 * @private
 * @param {Object} message
 */
BasServer.prototype._onV2SocketMessage = function (message) {

  this.emit(BasServer.EVT_V2_MESSAGE, message)
}

/**
 * @private
 * @param {BasCoreSocket} socket
 */
BasServer.prototype._setV2SocketListeners = function (socket) {

  this._clearV2SocketListeners()

  if (socket) {

    this._v2SocketListeners.push(BasUtil.setEventListener(
      socket,
      BasCoreSocket.EVT_CONNECTED,
      this._handleV2SocketConnected
    ))
    this._v2SocketListeners.push(BasUtil.setEventListener(
      socket,
      BasCoreSocket.EVT_MESSAGE,
      this._handleV2SocketMessage
    ))
  }
}

BasServer.prototype._clearV2SocketListeners = function () {

  BasUtil.executeArray(this._v2SocketListeners)
  this._v2SocketListeners = []
}

BasServer.prototype.destroy = function () {

  this.disconnectCore().catch(_empty)

  this._clearSocketListeners()
  if (this._socket) this._socket.destroy()
  this._socket = null
  if (this._v2Socket) this._v2Socket.destroy()
  this._v2Socket = null

  this._demo = null
  this._coreClientDeviceInfo = {}
  this._users = []
  this._admins = []
  this._credentials = []
  this._connectedCredentials = []
}

module.exports = BasServer

function _empty () {

  // Empty
}
