'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Device = require('./device')
var Timer = require('./timer')

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {Object} device.state
 * @param {Object} device.limit
 * @param {number} device.extraType
 * @param {BasCore} basCore
 */
function TimerCtrlDevice (device, basCore) {

  Device.call(this, device, basCore)

  /**
   * @type {Timer[]}
   */
  this._timers = []

  this._handleUpdate = this._onUpdateResult.bind(this)

  this.parse(device, { emit: false })
}

TimerCtrlDevice.prototype = Object.create(Device.prototype)
TimerCtrlDevice.prototype.constructor = TimerCtrlDevice

/**
 * @constant {string}
 */
TimerCtrlDevice.EVT_TIMERS_CHANGED = 'evtTimersChanged'

/**
 * @constant {string}
 */
TimerCtrlDevice.EVT_TIMER_CHANGED = 'evtTimerChanged'

/**
 * @name TimerCtrlDevice#timers
 * @type {Timer[]}
 * @readonly
 */
Object.defineProperty(TimerCtrlDevice.prototype, 'timers', {
  get: function () {
    return this._timers
  }
})

/**
 * Emits the TimerCtrlDevice.EVT_TIMER_CHANGED event with given timer
 *
 * @param {Timer} timer
 */
TimerCtrlDevice.prototype.emitTimerChanged = function (timer) {

  this.emit(TimerCtrlDevice.EVT_TIMER_CHANGED, timer)
}

/**
 * Parse a Timer Controller message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} [options]
 * @returns {boolean}
 */
TimerCtrlDevice.prototype.parse = function (
  msg,
  options
) {
  var valid, emit, length, i, timer, key, newTimersFound

  valid = Device.prototype.parse.call(this, msg, options)
  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (Array.isArray(msg[P.TIMERS])) {

    length = msg[P.TIMERS].length
    for (i = 0; i < length; i++) {

      timer = msg[P.TIMERS][i]

      if (BasUtil.isObject(timer)) {

        key = timer[P.UUID]

        if (BasUtil.isNEString(key)) {

          if (BasUtil.isObject(this._timers[key])) {

            this._timers[key].parse(timer, options)

          } else {

            this._timers[key] = new Timer(timer, this)
            newTimersFound = true
          }
        }
      }
    }
  }

  if (newTimersFound && emit) this.emit(TimerCtrlDevice.EVT_TIMERS_CHANGED)

  return valid
}

/**
 * Returns Promise.resolved with the device if result is ok
 * returns Promise.rejected with a matching error message
 *
 * @param {Object} result
 * @returns {(Promise|TimerCtrlDevice)}
 */
TimerCtrlDevice.prototype._onUpdateResult = function (result) {

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.DEVICE]) &&
    result[P.DEVICE][P.RESULT] === P.OK) {

    return this
  }

  return Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)
}

/**
 * @param {Object} timer
 * @returns {Promise}
 */
TimerCtrlDevice.prototype.updateTimer = function (timer) {

  var msg

  if (BasUtil.isObject(timer)) {

    msg = this._getBasCoreMessage()
    msg[P.DEVICE][P.ACTION] = P.UPDATE
    msg[P.DEVICE][P.TIMER] = timer

    return this._basCore.requestRetry(msg).then(this._handleUpdate)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {string} uuid
 * @returns {?Timer}
 */
TimerCtrlDevice.prototype.getTimer = function (uuid) {

  if (BasUtil.isNEString(uuid) &&
    this._timers[uuid] instanceof Timer) {

    return this._timers[uuid]
  }

  return null
}

module.exports = TimerCtrlDevice
