'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasDiscoveredServices', [
    '$rootScope',
    'BAS_DISCOVERED_SERVICES',
    'BAS_DISCOVERY',
    'BAS_LIVE_ACCOUNT',
    'BAS_STORED_SERVERS',
    'BasAppDevice',
    'BasDiscovery',
    'BasStoredServers',
    'BasLiveAccount',
    'BasUiCore',
    BasDiscoveredServices
  ])

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_DISCOVERED_SERVICES} BAS_DISCOVERED_SERVICES
 * @param {BAS_DISCOVERY} BAS_DISCOVERY
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param {BAS_STORED_SERVERS} BAS_STORED_SERVERS
 * @param {BasAppDevice} BasAppDevice
 * @param {BasDiscovery} BasDiscovery
 * @param {BasStoredServers} BasStoredServers
 * @param {BasLiveAccount} BasLiveAccount
 * @param BasUiCore
 */
function BasDiscoveredServices (
  $rootScope,
  BAS_DISCOVERED_SERVICES,
  BAS_DISCOVERY,
  BAS_LIVE_ACCOUNT,
  BAS_STORED_SERVERS,
  BasAppDevice,
  BasDiscovery,
  BasStoredServers,
  BasLiveAccount,
  BasUiCore
) {
  /**
   * @type {TBasStoredServersState}
   */
  var basStoredServersState = BasStoredServers.get()

  /**
   * @type {TBasDiscoveryState}
   */
  var basDiscoveryState = BasDiscovery.get()

  /**
   * @type {TBasLiveAccountState}
   */
  var basLiveAccountState = BasLiveAccount.get()

  /**
   * @typedef {Object} TBasDiscoveredServicesState
   * @property {Object<string, BasUiCore>} services
   * @property {string[]} allServices contains all unmerged service keys
   * @property {string[]} uiServices
   */

  /**
   * @type {TBasDiscoveredServicesState}
   */
  var state = {}
  state.allServices = []
  state.services = {}
  state.uiServices = []

  this.get = get
  this.setAllServicesSelected = setAllServicesSelected

  init()

  function init () {

    $rootScope.$on(
      BAS_DISCOVERY.EVT_DISCOVERED_CORES_UPDATED,
      _syncServices
    )

    $rootScope.$on(
      BAS_LIVE_ACCOUNT.EVT_PROJECTS_UPDATED,
      _syncServices
    )

    $rootScope.$on(
      BAS_STORED_SERVERS.EVT_STORED_CORES_UPDATED,
      _syncServices
    )

    _syncServices()
  }

  /**
   * @returns {TBasDiscoveredServicesState}
   */
  function get () {

    return state
  }

  function setAllServicesSelected (selected) {

    var keys, length, uiCore, i

    keys = Object.keys(state.services)
    length = keys.length

    for (i = 0; i < length; i++) {

      uiCore = state.services[keys[i]]

      if (uiCore) {

        uiCore.setSelected(selected)
      }
    }
  }

  function _syncServices () {

    var i, key, length, oldServices, liveProjectsPool

    oldServices = BasUtil.copyObject(state.allServices)
    state.allServices = []

    liveProjectsPool = BasAppDevice.isLiveOnly()
      ? basLiveAccountState.uiProjects
      : basLiveAccountState.uiProjectsOnline

    _cleanupLiveProjectReferences()

    // Add live projects
    length = liveProjectsPool.length
    for (i = 0; i < length; i++) {

      key = liveProjectsPool[i]
      _add(basLiveAccountState.projects[key])
    }

    // Add discovered services
    length = basDiscoveryState.uiServices.length
    for (i = 0; i < length; i++) {

      key = basDiscoveryState.uiServices[i]
      _add(basDiscoveryState.services[key])
    }

    // Add stored servers
    length = basStoredServersState.uiServers.length
    for (i = 0; i < length; i++) {

      key = basStoredServersState.uiServers[i]
      _add(basStoredServersState.servers[key])
    }

    // Remove lost services
    length = oldServices.length
    for (i = 0; i < length; i++) {

      key = oldServices[i]

      if (state.allServices.indexOf(key) < 0) {

        _delayedRemove(key)
      }
    }

    _sort()

    $rootScope.$emit(
      BAS_DISCOVERED_SERVICES.EVT_DISCOVERED_SERVICES_UPDATED
    )
  }

  /**
   * Adds or merges a new uiCore
   *
   * @private
   * @param {(
   * BasLiveProject |
   * TBasStoredServer |
   * BasDiscoveredCore
   * )} serverInfo
   * @returns {undefined}
   */
  function _add (serverInfo) {

    var length, i, keys, key, uiCore, match, newUiCore

    if (!serverInfo) return

    newUiCore = new BasUiCore(serverInfo)
    state.allServices.push(newUiCore.id)

    match = false

    keys = Object.keys(state.services)
    length = keys.length
    for (i = 0; i < length; i++) {

      key = keys[i]
      uiCore = state.services[key]

      if (uiCore) {

        // Check if uiCore already exists
        if (uiCore.is(serverInfo)) {

          match = true
          uiCore.parse(serverInfo)
          break
        }
      }
    }

    // No match found, add uiCore
    if (!match) {

      state.uiServices.push(newUiCore.id)
      state.services[newUiCore.id] = newUiCore
    }
  }

  function _delayedRemove (id) {

    setTimeout(
      _remove.bind(null, id),
      BAS_DISCOVERED_SERVICES.REMOVE_DEBOUNCE_TIMEOUT
    )
  }

  function _cleanupLiveProjectReferences () {
    var length, i, service, keys

    keys = Object.keys(state.services)

    length = keys.length
    for (i = 0; i < length; i++) {

      service = state.services[keys[i]]

      if (service && service.liveProject) {

        // Reset property 'liveProject' from a specific service
        // if current account isn't linked to this project
        if (!basLiveAccountState.projects[service.cid]) {

          service.liveProject = null
        }
      }
    }
  }

  /**
   * Splits and/or removes a existing uiCore
   *
   * @private
   * @param {string} id
   */
  function _remove (id) {

    var index, keys, length, i, key, splitCore, remove

    if (!BasUtil.isNEString(id) ||
      state.allServices.indexOf(id) > -1) {

      return
    }

    remove = true

    keys = Object.keys(state.services)
    length = keys.length
    for (i = 0; i < length; i++) {

      key = keys[i]
      splitCore = state.services[key]

      // Check if uiCore is merged in other core
      if (splitCore &&
        splitCore.is(id)) {

        remove = !splitCore.remove(id)

        break
      }
    }

    if (splitCore) {

      index = state.uiServices.indexOf(splitCore.id)

      if (remove && index > -1) {

        state.uiServices.splice(index, 1)
        state.services[splitCore.id] = null
      }
    }

    _sort()

    $rootScope.$emit(
      BAS_DISCOVERED_SERVICES.EVT_DISCOVERED_SERVICES_UPDATED
    )
  }

  function _sort () {

    var length, i, key, uiCore, sortedArray

    sortedArray = []

    // Add all stored uiCores
    length = state.uiServices.length
    for (i = 0; i < length; i++) {

      key = state.uiServices[i]
      uiCore = state.services[key]

      if (uiCore &&
        uiCore.storedServer &&
        sortedArray.indexOf(uiCore.id) < 0) {

        sortedArray.push(uiCore.id)
      }
    }

    // Add all remote uiCores
    for (i = 0; i < length; i++) {

      key = state.uiServices[i]
      uiCore = state.services[key]

      if (uiCore &&
        uiCore.liveProject &&
        sortedArray.indexOf(uiCore.id) < 0) {

        sortedArray.push(uiCore.id)
      }
    }

    // Add rest of uiCores
    for (i = 0; i < length; i++) {

      key = state.uiServices[i]
      uiCore = state.services[key]

      if (uiCore &&
        sortedArray.indexOf(uiCore.id) < 0) {

        sortedArray.push(uiCore.id)
      }
    }

    state.uiServices = sortedArray
  }
}
