'use strict'

var BasUtil = require('@basalte/bas-util')

var Device = require('./device')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

/**
 * @typedef {Object} TVideoSourceState
 * @property {string[]} [watchingRooms]
 * @property {string} [playback]
 */

/**
 * @typedef {Object} TVideoSourceFavouritesResult
 * @property {TVideoSourceFavourite[]} list
 * @property {number} offset
 * @property {number} total
 * @property {string} service
 */

/**
 * @typedef {Object} TVideoSourceFavouritesServicesResult
 * @property {string[]} services
 * @property {number} offset
 * @property {number} total
 */

/**
 * @typedef {Object} TVideoSourceFavourite
 * @property {string} uri
 * @property {string} name
 * @property {boolean} removable
 * @property {number} index
 */

/**
 * Class representing a video-source
 *
 * @constructor
 * @extends Device
 * @param {BasCore} basCore
 * @param {Object} device
 * @since 3.4.0
 */
function VideoSource (
  basCore,
  device
) {
  Device.call(this, device, basCore)

  /**
   * @private
   * @type {TVideoSourceState}
   */
  this._state = {}

  this._handleFavourites = this._onFavourites.bind(this)
  this._handleFavouriteActionResult = this._onFavouriteActionResult.bind(this)

  this.parse(device, { emit: false })
}

VideoSource.prototype = Object.create(Device.prototype)
VideoSource.prototype.constructor = VideoSource

// region Constants

/**
 * @constant {string}
 */
VideoSource.C_WATCHING_ROOMS = P.WATCHING_ROOMS

/**
 * @constant {string}
 */
VideoSource.C_FAVOURITES = P.FAVOURITES

/**
 * @constant {string}
 */
VideoSource.C_ADD = P.ADD

/**
 * @constant {string}
 */
VideoSource.C_LIST = P.LIST

/**
 * @constant {string}
 */
VideoSource.C_REMOVE = P.REMOVE

/**
 * @constant {string}
 */
VideoSource.C_PLAYBACK = P.PLAYBACK

/**
 * @constant {string}
 */
VideoSource.A_PM_PLAYING = P.PLAYING

/**
 * @constant {string}
 */
VideoSource.A_PM_PAUSED = P.PAUSED

/**
 * @constant {string}
 */
VideoSource.A_PM_BUFFERING = P.BUFFERING

/**
 * @constant {string}
 */
VideoSource.A_PM_IDLE = P.IDLE

/**
 * @constant {string}
 */
VideoSource.A_PM_UNKNOWN = P.UNKNOWN

/**
 * @constant {string}
 */
VideoSource.EVT_STATE_CHANGED = 'evtVideoSourceStateChanged'

/**
 * @constant {string}
 */
VideoSource.EVT_PLAYBACK_CHANGED = 'evtVideoSourcePlaybackChanged'

/**
 * @constant {string}
 */
VideoSource.ACT_BACK = P.BACK

/**
 * @constant {string}
 */
VideoSource.ACT_UP = P.UP

/**
 * @constant {string}
 */
VideoSource.ACT_DOWN = P.DOWN

/**
 * @constant {string}
 */
VideoSource.ACT_LEFT = P.LEFT

/**
 * @constant {string}
 */
VideoSource.ACT_RIGHT = P.RIGHT

/**
 * @constant {string}
 */
VideoSource.ACT_MENU = P.MENU

/**
 * @constant {string}
 */
VideoSource.ACT_ENTER = P.ENTER

/**
 * @constant {string}
 */
VideoSource.ACT_CHANNEL_UP = P.CHANNEL_UP

/**
 * @constant {string}
 */
VideoSource.ACT_CHANNEL_DOWN = P.CHANNEL_DOWN

/**
 * @constant {string}
 */
VideoSource.ACT_SKIP_NEXT = P.SKIP_NEXT

/**
 * @constant {string}
 */
VideoSource.ACT_SKIP_PREVIOUS = P.SKIP_PREVIOUS

/**
 * @constant {string}
 */
VideoSource.ACT_REWIND = P.REWIND

/**
 * @constant {string}
 */
VideoSource.ACT_FAST_FORWARD = P.FAST_FORWARD

/**
 * @constant {string}
 */
VideoSource.ACT_PLAY_PAUSE = P.PLAY_PAUSE

/**
 * @constant {string}
 */
VideoSource.ACT_PLAY_PAUSE = P.PLAY_PAUSE

/**
 * @constant {string}
 */
VideoSource.EVT_WATCHING_ROOMS_CHANGED = 'evtVideoSourceWatchingRoomsChanged'

/**
 * @constant {string}
 */
VideoSource.EVT_FAVOURITES_RESET = 'evtVideoSourceFavouritesReset'

/**
 * @constant {string}
 */
VideoSource.EVT_FAVOURITE_ADDED = 'evtVideoSourceFavouriteAdded'

/**
 * @constant {string}
 */
VideoSource.EVT_FAVOURITE_REMOVED = 'evtVideoSourceFavouriteRemoved'

// endregion

/**
 * Tells if a value is a possible value for 'playback' property
 *
 * @param {string} playback
 * @param {boolean} [outgoing = false] Distinguish correct incoming values from
 * correct outgoing values
 * @returns {boolean}
 */
VideoSource.isPlaybackMode = function (playback, outgoing) {
  return (
    playback === VideoSource.A_PM_PLAYING ||
    playback === VideoSource.A_PM_PAUSED ||
    playback === VideoSource.A_PM_IDLE ||
    (
      !outgoing && (
        playback === VideoSource.A_PM_BUFFERING ||
        playback === VideoSource.A_PM_UNKNOWN
      )
    )
  )
}

/**
 * @private
 * @param {Object} obj
 * @returns {TVideoSourceFavourite}
 */
VideoSource._parseFavourite = function (obj) {

  var result, value

  /**
   * @type {TVideoSourceFavourite}
   */
  result = {
    uri: '',
    name: '',
    removable: false,
    index: -1
  }

  // Uri

  value = obj[P.URI]
  if (BasUtil.isNEString(value)) result.uri = value

  // Name

  value = obj[P.NAME]
  if (BasUtil.isNEString(value)) result.name = value

  // Removable

  value = obj[P.REMOVABLE]
  if (BasUtil.isBool(value)) result.removable = value

  // Index

  value = obj[P.INDEX]
  if (BasUtil.isPNumber(value, true)) result.index = value

  return result
}

Object.defineProperties(VideoSource.prototype, {

  /**
   * @name VideoSource#watchingRooms
   * @type {string[]}
   * @readonly
   */
  watchingRooms: {
    get: function () {
      return Array.isArray(this._state.watchingRooms)
        ? this._state.watchingRooms
        : []
    }
  },

  /**
   * @name AudioSource#holdableActions
   * @type {string[]}
   * @readonly
   */
  holdableActions: {
    get: function () {
      return BasUtil.isNEArray(this._attributes[P.HOLDABLE_ACTIONS])
        ? this._attributes[P.HOLDABLE_ACTIONS]
        : []
    }
  },

  /**
   * @name AudioSource#customButtons
   * @type {string[]}
   * @readonly
   */
  customButtons: {
    get: function () {
      return BasUtil.isNEArray(this._attributes[P.CUSTOM_GRID])
        ? this._attributes[P.CUSTOM_GRID]
        : []
    }
  },

  /**
   * @name AudioSource#customButtons
   * @type {string[]}
   * @readonly
   */
  numericButtons: {
    get: function () {
      return BasUtil.isNEArray(this._attributes[P.NUMERIC_GRID])
        ? this._attributes[P.NUMERIC_GRID]
        : []
    }
  },

  /**
   * @name VideoSource#playbackMode
   * @type {string}
   * @readonly
   */
  playbackMode: {
    get: function () {
      return this._state.playback
    }
  },

  /**
   * @name VideoSource#paused
   * @type {boolean}
   * @readonly
   */
  paused: {
    get: function () {
      return !(
        this._state.playback !== VideoSource.A_PM_PAUSED &&
        this._state.playback !== VideoSource.A_PM_IDLE &&
        this._state.playback !== VideoSource.A_PM_UNKNOWN
      )
    }
  }
})

/**
 * Parse an AV source message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} [options]
 * @returns {boolean}
 */
VideoSource.prototype.parse = function (
  msg,
  options
) {
  var emit, valid, state, value, oldValue, event

  var stateChanged, watchingRoomsChanged, playbackChanged

  var favouritesReset, favouriteAdded, favouriteRemoved
  var favouriteData

  emit = true

  stateChanged = false

  favouritesReset = false

  valid = Device.prototype.parse.call(this, msg, options)

  if (valid) {

    if (BasUtil.isObject(options)) {

      if (BasUtil.isBool(options.emit)) emit = options.emit
    }

    // State

    state = msg[P.STATE]

    if (BasUtil.isObject(state)) {

      // Watching Rooms

      value = state[P.WATCHING_ROOMS]
      oldValue = this._state.watchingRooms

      if (P.WATCHING_ROOMS in state) {

        this._state.watchingRooms = Array.isArray(value)
          ? value.filter(BasUtil.isNEString)
          : null

        if (!BasUtil.isEqualArrayUn(oldValue, this._state.watchingRooms)) {

          watchingRoomsChanged = true
          stateChanged = true
        }
      }

      // Playback

      value = state[P.PLAYBACK]
      oldValue = this._state.playback

      if (P.PLAYBACK in state) {

        this._state.playback = VideoSource.isPlaybackMode(value)
          ? value
          : null

        if (oldValue !== this._state.playback) {

          playbackChanged = true
          stateChanged = true
        }
      }
    }

    // Favourites

    value = msg[P.FAVOURITES]

    if (BasUtil.isObject(value)) {

      event = value[P.EVENT]

      switch (event) {
        case P.RESET:
          favouritesReset = true
          break
        case P.ADDED:
          favouriteAdded = true
          favouriteData = VideoSource._parseFavourite(value[P.FAVOURITE])
          break
        case P.REMOVED:
          favouriteRemoved = true
          favouriteData = value[P.FAVOURITE]
          break
      }
    }
  }

  if (emit) {

    // State

    if (watchingRoomsChanged) {

      this.emit(VideoSource.EVT_WATCHING_ROOMS_CHANGED)
    }

    if (playbackChanged) {

      this.emit(VideoSource.EVT_PLAYBACK_CHANGED)
    }

    if (stateChanged) {

      this.emit(VideoSource.EVT_STATE_CHANGED)
    }

    // Favourites

    if (favouritesReset) {

      this.emit(VideoSource.EVT_FAVOURITES_RESET)
    }

    if (favouriteAdded) {

      this.emit(VideoSource.EVT_FAVOURITE_ADDED, favouriteData)
    }

    if (favouriteRemoved) {

      this.emit(VideoSource.EVT_FAVOURITE_REMOVED, favouriteData)
    }
  }

  return valid
}

/**
 * Update a single or multiple properties
 *
 * @param {Object} newState
 * @returns {Promise}
 */
VideoSource.prototype.updateState = function (newState) {

  var msg

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  msg = this._getBasCoreMessage()
  msg[P.AV_SOURCE][P.STATE] = newState

  return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(Device.handleResponse)
}

/**
 * @param {string} action
 * @param {*} [data]
 * @returns {Promise}
 */
VideoSource.prototype.action = function (
  action,
  data
) {
  var msg

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  msg = this._getBasCoreMessage()
  msg[P.AV_SOURCE][P.ACTION] = action
  if (!BasUtil.isUndefined(data)) msg[P.AV_SOURCE][P.DATA] = data

  return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(Device.handleResponse)
}

/**
 * @param {string} category
 * @param {string} action
 * @param {*} [data]
 * @returns {Promise}
 */
VideoSource.prototype.nestedAction = function (
  category,
  action,
  data
) {
  var msg

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  msg = this._getBasCoreMessage()
  msg[P.AV_SOURCE][category] = {}
  msg[P.AV_SOURCE][category][P.ACTION] = action
  if (!BasUtil.isUndefined(data)) {

    msg[P.AV_SOURCE][category][P.DATA] = data
  }

  return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(Device.handleResponse)
}

// Favourites

/**
 * Requests favourites for a service
 *
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<TVideoSourceFavouritesResult>}
 */
VideoSource.prototype.listFavourites = function (
  offset,
  limit
) {
  var data

  if (
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, false)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit
    return this.nestedAction(P.FAVOURITES, P.LIST, data)
      .then(this._handleFavourites)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Add favourite by uri
 *
 * @param {string} channelName
 * @param {number} channelNumber
 * @returns {Promise<TVideoSourceFavouritesResult>}
 */
VideoSource.prototype.addFavourite = function (
  channelName,
  channelNumber
) {
  var data

  if (
    BasUtil.isNEString(channelName) &&
    BasUtil.isPNumber(channelNumber, false)
  ) {

    data = {}
    data[P.NAME] = channelName
    data[P.CHANNEL] = channelNumber
    return this.nestedAction(
      P.FAVOURITES,
      P.ADD,
      data
    )
      .then(this._handleFavouriteActionResult)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Remove favourite by uri
 *
 * @param {string} uri
 * @returns {Promise<TVideoSourceFavouritesResult>}
 */
VideoSource.prototype.removeFavourite = function (uri) {
  var data

  if (BasUtil.isNEString(uri)) {

    data = {}
    data[P.URI] = uri
    return this.nestedAction(
      P.FAVOURITES,
      P.REMOVE,
      data
    )
      .then(this._handleFavouriteActionResult)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @private
 * @param {Object} msg
 * @returns {TVideoSourceFavouritesResult}
 */
VideoSource.prototype._onFavourites = function (msg) {

  var result, favourites, value, length, i

  /**
   * @type {TVideoSourceFavouritesResult}
   */
  result = {
    list: [],
    offset: 0,
    total: 0
  }

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.FAVOURITES]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid
  ) {

    favourites = msg[P.AV_SOURCE][P.FAVOURITES]

    // List

    value = favourites[P.LIST]

    if (Array.isArray(value)) {

      // For some reason Object.values sorts the values

      length = value.length
      for (i = 0; i < length; i++) {

        if (BasUtil.isObject(value[i])) {

          result.list.push(VideoSource._parseFavourite(value[i]))
        }
      }
    }

    // Offset

    value = favourites[P.OFFSET]
    if (BasUtil.isPNumber(value, true)) result.offset = value

    // Total

    value = favourites[P.TOTAL]
    if (BasUtil.isPNumber(value, true)) result.total = value

    // Service

    value = favourites[P.SERVICE]
    if (BasUtil.isNEString(value)) result.service = value
  }

  return result
}

/**
 * @private
 * @param {Object} msg
 */
VideoSource.prototype._onFavouriteActionResult = function (msg) {

  var success

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.FAVOURITES]) &&
    BasUtil.isBool(msg[P.AV_SOURCE][P.FAVOURITES][P.SUCCESS]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid
  ) {

    success = msg[P.AV_SOURCE][P.FAVOURITES][P.SUCCESS]

    return success
      ? Promise.resolve()
      : Promise.reject(msg[P.ERROR])
  }
}

/**
 * Press generic button
 *
 * @param {string} button
 * @param {?boolean} active
 * @returns {Promise}
 */
VideoSource.prototype.pressGenericButton = function (
  button,
  active
) {
  var data

  if (BasUtil.isNEString(button)) {

    if (BasUtil.isBool(active)) {

      data = {}
      data[P.ACTIVE] = active
    }

    return this.action(
      button,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Press custom button
 *
 * @param {string} buttonUri
 * @param {?boolean} active
 * @returns {Promise}
 */
VideoSource.prototype.pressCustomButton = function (
  buttonUri,
  active
) {
  var data

  if (BasUtil.isNEString(buttonUri)) {

    data = {}
    data[P.URI] = buttonUri

    if (BasUtil.isBool(active)) {
      data[P.ACTIVE] = active
    }

    return this.action(
      P.BUTTON,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {boolean} [force]
 * @returns {Promise}
 */
VideoSource.prototype.togglePlayPause = function (force) {

  var _setPlaying

  _setPlaying = BasUtil.isBool(force) ? force : this.paused

  return BasUtil.isBool(force)
    ? this.setPlayback(
      _setPlaying
        ? VideoSource.A_PM_PLAYING
        : VideoSource.A_PM_PAUSED
    )
    : this.action(VideoSource.ACT_PLAY_PAUSE)
}
/**
 * @param {string} playback
 * @returns {Promise}
 */
VideoSource.prototype.setPlayback = function (playback) {

  var state

  if (VideoSource.isPlaybackMode(playback)) {

    state = {}
    state[P.PLAYBACK] = playback
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Plays track with given uri
 *
 * @param {string} uri
 * @returns {Promise}
 */
VideoSource.prototype.playUri = function (uri) {

  var data

  if (BasUtil.isNEString(uri) || Array.isArray(uri)) {

    data = {}
    data[P.URI] = uri

    return this.action(P.PLAY_URI, data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Creates a template basCore message for this video source
 *
 * @protected
 * @returns {TDeviceMessage}
 */
VideoSource.prototype._getBasCoreMessage = function () {

  var msg = {}

  msg[P.AV_SOURCE] = {}
  msg[P.AV_SOURCE][P.UUID] = this.uuid

  return msg
}

/**
 * Creates a template basCore message for this audio source
 *
 * @protected
 * @param {string} videoSourceUuid
 * @returns {Object}
 */
VideoSource.getBasCoreMessage = function (videoSourceUuid) {

  var msg = {}

  msg[P.AV_SOURCE] = {}
  msg[P.AV_SOURCE][P.UUID] = videoSourceUuid

  return msg
}

/**
 * @param {string} videoSourceUuid
 * @param {string} action
 * @param {*} [data]
 * @returns {Object}
 */
VideoSource.getActionMessage = function (
  videoSourceUuid,
  action,
  data
) {
  var msg = VideoSource.getBasCoreMessage(videoSourceUuid)
  msg[P.AV_SOURCE][P.ACTION] = action
  if (!BasUtil.isUndefined(data)) msg[P.AV_SOURCE][P.DATA] = data

  return msg
}

/**
 * Gets websocket message to send for playing an item with given uri
 *
 * @param {string} videoSourceUuid
 * @param {string} uri
 * @returns {?Object}
 */
VideoSource.getPlayUriMessage = function (videoSourceUuid, uri) {

  var data

  if (BasUtil.isNEString(uri) || Array.isArray(uri)) {

    data = {}
    data[P.URI] = uri

    return VideoSource.getActionMessage(videoSourceUuid, P.PLAY_URI, data)
  }

  return null
}

module.exports = VideoSource
