'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasScreen', [
    '$window',
    BasScreen
  ])

/**
 * Service which handles screen related functions
 *
 * @constructor
 * @param $window
 */
function BasScreen (
  $window
) {
  var lastDisplayTimeoutSeconds = 0
  var keepingDisplayOn = false

  this.setProximityThreshold = setProximityThreshold
  this.setProximitySensitivity = setProximitySensitivity
  this.setAutoBrightness = setAutoBrightness
  this.setDisplayTimeout = setDisplayTimeout
  this.keepScreenOn = keepScreenOn
  this.releaseKeepScreenOn = releaseKeepScreenOn
  this.setOrientation = setOrientation

  /**
   * @param {number} centimeters
   */
  function setProximityThreshold (centimeters) {

    var screen = _getScreen()

    if (screen && BasUtil.isVNumber(centimeters)) {

      screen.setProximityThreshold(centimeters)
    }
  }

  /**
   * @param {number} sensitivity 0 high; 1 mediate; 2 low
   */
  function setProximitySensitivity (sensitivity) {

    var screen = _getScreen()

    if (screen && BasUtil.isVNumber(sensitivity)) {

      screen.setProximitySensitivity(sensitivity)
    }
  }

  /**
   * @param {number} brightness -1 auto 0-100 manual
   */
  function setAutoBrightness (brightness) {

    var screen = _getScreen()

    if (screen && BasUtil.isVNumber(brightness)) {

      screen.setAutoBrightness(brightness === -1)
      screen.setBrightness(brightness / 100)
    }
  }

  /**
   * @param {number} orientation 0 to 3
   */
  function setOrientation (orientation) {

    const screen = _getScreen()

    if (screen && BasUtil.isPNumber(orientation, true)) {

      screen.setOrientation(orientation)
    }
  }

  /**
   * @param {number} seconds
   * @param {Function} [callback]
   */
  function setDisplayTimeout (seconds, callback) {

    lastDisplayTimeoutSeconds = seconds

    if (!keepingDisplayOn) {

      _setDisplayTimeout(seconds, callback)
    }
  }

  /**
   * @param {Function} [callback]
   */
  function keepScreenOn (callback) {

    keepingDisplayOn = true
    _setDisplayTimeout(0, callback)
  }

  /**
   * @private
   * @param {number} seconds
   * @param [callback]
   */
  function _setDisplayTimeout (seconds, callback) {

    var screen = _getScreen()

    if (screen && BasUtil.isVNumber(seconds)) {

      screen.setDisplayTimeout(seconds, callback)
    }
  }

  /**
   * Get Screen plugin instance
   *
   * @private
   * @returns {?Screen}
   */
  function _getScreen () {

    if (BasUtil.isObject($window['basalteCordova']) &&
      BasUtil.isObject($window['basalteCordova']['screen'])) {

      return $window['basalteCordova']['screen']
    }

    return null
  }

  /**
   * @param {boolean} [force]
   * @param {Function} [callback]
   */
  function releaseKeepScreenOn (force, callback) {

    if (keepingDisplayOn || force) {

      _setDisplayTimeout(lastDisplayTimeoutSeconds, callback)
      keepingDisplayOn = false
    }
  }
}
