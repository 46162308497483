'use strict'

var macAddressUtil = require('@gidw/mac-address-util')
var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var Device = require('./device')

/**
 * @typedef {Object} TCoreClientDoorPhoneConfig
 * @property {string} gateway
 * @property {string} type
 * @property {string} mjpeg
 * @property {string} username
 * @property {string} password
 * @property {string[]} buttons
 */

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function CoreClientDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._mac = BasUtil.isPNumber(device[P.MAC])
    ? device[P.MAC]
    : 0

  this._macStr = BasUtil.isPNumber(this._mac)
    ? macAddressUtil.convertToMac(this._mac)
    : ''

  this._state = BasUtil.isObject(device[P.STATE])
    ? device[P.STATE]
    : {}

  /**
   * @private
   * @type {?TCoreClientDoorPhoneConfig}
   */
  this._coreClientDoorPhoneConfig = null

  this._parseCoreClientDoorPhoneConfig(device)
}

CoreClientDevice.prototype = Object.create(Device.prototype)
CoreClientDevice.prototype.constructor = CoreClientDevice

/**
 * @event CoreClientDevice#EVT_MUTE_AREA_ON_CALL
 * @param {boolean} muteAreaOnCall
 */

/**
 * Port of http server running in discovery app
 *
 * @constant {number}
 */
CoreClientDevice.HTTP_PORT_DISCOVERY = 9090

/**
 * Port of http server running in home app (http server plugin) on core client
 *
 * @constant {number}
 */
CoreClientDevice.HTTP_PORT_HOME = 9091

/**
 * @constant {string}
 */
CoreClientDevice.C_CALL_STATE = P.CALL_STATE

/**
 * @constant {string}
 */
CoreClientDevice.C_CALLER = P.CALLER

/**
 * @constant {string}
 */
CoreClientDevice.C_MUTE_AREA_ON_CALL = P.MUTE_AREA_ON_CALL

/**
 * @constant {string}
 */
CoreClientDevice.C_INTERCOM = P.INTERCOM

/**
 * @constant {string}
 */
CoreClientDevice.C_ADDRESSES = P.ADDRESSES

/**
 * @constant {string}
 */
CoreClientDevice.EVT_MUTE_AREA_ON_CALL = 'evtCoreClientMuteAreaOnCall'

/**
 * @constant {string}
 */
CoreClientDevice.EVT_CORE_CLIENT_ADDRESSES = 'evtCoreClientAddresses'

/**
 * @name CoreClientDevice#addresses
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(CoreClientDevice.prototype, 'addresses', {
  get: function () {
    return this._state[CoreClientDevice.C_ADDRESSES]
  }
})

/**
 * @name CoreClientDevice#mac
 * @type {number}
 * @readonly
 */
Object.defineProperty(CoreClientDevice.prototype, 'mac', {
  get: function () {
    return this._mac
  }
})

/**
 * @name CoreClientDevice#macStr
 * @type {string}
 * @readonly
 */
Object.defineProperty(CoreClientDevice.prototype, 'macStr', {
  get: function () {
    return this._macStr
  }
})

/**
 * @name CoreClientDevice#coreClientDoorPhoneConfig
 * @type {?TCoreClientDoorPhoneConfig}
 * @readonly
 */
Object.defineProperty(CoreClientDevice.prototype, 'coreClientDoorPhoneConfig', {
  get: function () {
    return this._coreClientDoorPhoneConfig
  }
})

/**
 * @name CoreClientDevice#muteAreaOnCallState
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(CoreClientDevice.prototype, 'muteAreaOnCallState', {
  get: function () {
    return this._state[CoreClientDevice.C_MUTE_AREA_ON_CALL] === true
  }
})

/**
 * Parse a CoreClientDevice message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
CoreClientDevice.prototype.parse = function (msg, options) {

  var state, valid
  var emit

  emit = true
  valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    if (BasUtil.isObject(msg[P.STATE])) {

      state = msg[P.STATE]

      // Mute area on call
      if (BasUtil.safeHasOwnProperty(state, P.MUTE_AREA_ON_CALL) &&
        state[P.MUTE_AREA_ON_CALL] !==
        this._state[P.MUTE_AREA_ON_CALL]) {

        this._state[P.MUTE_AREA_ON_CALL] =
          state[P.MUTE_AREA_ON_CALL]

        if (emit) {

          this.emit(
            CoreClientDevice.EVT_MUTE_AREA_ON_CALL,
            this.muteAreaOnCallState
          )
        }
      }

      // Addresses
      if (BasUtil.safeHasOwnProperty(state, P.ADDRESSES) &&
        !BasUtil.isEqualArray(
          state[P.ADDRESSES],
          this._state[P.ADDRESSES]
        )) {

        this._state[P.ADDRESSES] =
          state[P.ADDRESSES]

        if (emit) {

          this.emit(
            CoreClientDevice.EVT_CORE_CLIENT_ADDRESSES,
            this.addresses
          )
        }
      }
    }
  }

  return valid
}

/**
 * Extract Door Phone configuration information from given Object.
 * Returns true if config has changed
 *
 * Check for Siedle key to ensure backwards compatibility
 *
 * @private
 * @param {?Object} device
 * @returns {boolean}
 */
CoreClientDevice.prototype._parseCoreClientDoorPhoneConfig = function (device) {

  var _oldCoreClientDoorPhoneConfig, gatewayTypeKey, obj

  _oldCoreClientDoorPhoneConfig = this._coreClientDoorPhoneConfig

  this._coreClientDoorPhoneConfig = null

  if (BasUtil.isObject(device)) {

    if (BasUtil.isObject(device[P.SIEDLE])) {

      gatewayTypeKey = P.SIEDLE

    } else if (BasUtil.isObject(device[P.DOOR_PHONE])) {

      gatewayTypeKey = P.DOOR_PHONE
    }

    if (BasUtil.isObject(device[gatewayTypeKey]) &&
      (
        BasUtil.isNEString(device[gatewayTypeKey][P.GATEWAY]) ||
        BasUtil.isObject(device[gatewayTypeKey][P.DOORBIRD]) ||
        BasUtil.isObject(device[gatewayTypeKey][P.DOOR_PHONE_2N]) ||
        BasUtil.isObject(device[gatewayTypeKey][P.DOOR_PHONE_SCHUCO])
      )) {

      this._coreClientDoorPhoneConfig = {}

      this._coreClientDoorPhoneConfig.gateway =
        BasUtil.isNEString(device[gatewayTypeKey][P.GATEWAY])
          ? device[gatewayTypeKey][P.GATEWAY]
          : ''

      this._coreClientDoorPhoneConfig.mjpeg =
        BasUtil.isNEString(device[gatewayTypeKey][P.MJPEG])
          ? device[gatewayTypeKey][P.MJPEG]
          : ''

      this._coreClientDoorPhoneConfig.username =
        BasUtil.isNEString(device[gatewayTypeKey][P.USERNAME])
          ? device[gatewayTypeKey][P.USERNAME]
          : ''

      this._coreClientDoorPhoneConfig.password =
        BasUtil.isNEString(device[gatewayTypeKey][P.PASSWORD])
          ? device[gatewayTypeKey][P.PASSWORD]
          : ''

      if (BasUtil.isObject(device[gatewayTypeKey][P.DOORBIRD])) {

        obj = device[gatewayTypeKey][P.DOORBIRD]

        this._coreClientDoorPhoneConfig.buttons =
          BasUtil.isNEArray(obj[P.BUTTONS])
            ? obj[P.BUTTONS]
            : []

        this._coreClientDoorPhoneConfig.type = P.DOORBIRD

      } else if (BasUtil.isObject(
        device[gatewayTypeKey][P.DOOR_PHONE_2N]
      )) {

        this._coreClientDoorPhoneConfig.type = P.DOOR_PHONE_2N

      } else if (BasUtil.isObject(
        device[gatewayTypeKey][P.DOOR_PHONE_SCHUCO]
      )) {

        this._coreClientDoorPhoneConfig.type = P.DOOR_PHONE_SCHUCO
      }
    }
  }

  return BasUtil.compareObjects(
    _oldCoreClientDoorPhoneConfig,
    this._coreClientDoorPhoneConfig
  )
}

/**
 * Update call state to idle
 *
 * @param {string} callerUuid
 */
CoreClientDevice.prototype.setCallStateToIdle = function (callerUuid) {

  var state = {}

  state[CoreClientDevice.C_CALL_STATE] = P.IDLE
  state[CoreClientDevice.C_CALLER] = callerUuid
  this.updateState(state)
}

/**
 * Update call state to incoming
 *
 * @param {string} callerUuid
 */
CoreClientDevice.prototype.setCallStateToIncoming = function (callerUuid) {

  var state = {}

  state[CoreClientDevice.C_CALL_STATE] = P.INCOMING
  state[CoreClientDevice.C_CALLER] = callerUuid
  this.updateState(state)
}

/**
 * Update call state to ongoing
 *
 * @param {string} callerUuid
 */
CoreClientDevice.prototype.setCallStateToOngoing = function (callerUuid) {

  var state = {}

  state[CoreClientDevice.C_CALL_STATE] = P.ONGOING
  state[CoreClientDevice.C_CALLER] = callerUuid
  this.updateState(state)
}

/**
 * Update muteAreaOnCall state
 *
 * @param {boolean} muteAreaOnCall
 */
CoreClientDevice.prototype.setMuteAreaOnCall = function (muteAreaOnCall) {

  var state = {}

  state[CoreClientDevice.C_MUTE_AREA_ON_CALL] = muteAreaOnCall
  this.updateState(state)
}

/**
 * Update ip addresses
 *
 * @param {string[]} addresses
 */
CoreClientDevice.prototype.setAddresses = function (addresses) {

  var state = {}

  state[CoreClientDevice.C_ADDRESSES] = addresses
  this.updateState(state)
}

module.exports = CoreClientDevice
