'use strict'

angular
  .module('basalteApp')
  .service('AlarmsState', [
    '$transitions',
    'STATES',
    'UiHelper',
    AlarmsState
  ])

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {UiHelper} UiHelper
 */
function AlarmsState (
  $transitions,
  STATES,
  UiHelper
) {
  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  init()

  function init () {

    $transitions.onStart(
      {
        to: STATES.ALARMS
      },
      _onAlarmsState
    )
  }

  function _onAlarmsState (transition) {

    var target, toName

    target = transition.targetState()
    toName = target.name()

    if (toName === STATES.ALARMS) {

      if (basUi.prop.wMedium) return target.withState(STATES.ALARMS_ALARM)
    }
  }
}
