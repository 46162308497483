{
  "61":[
    {
      "name": "Top Hits",
      "type": "local",
      "subtype": "playlist",
      "value": "GeorgeTopHits"
    },
    {
      "name": "Radio",
      "type": "tunein",
      "value": "s25710"
    }
  ],
  "62":[
    {
      "name": "Relax",
      "type": "local",
      "subtype": "playlist",
      "value": "JaneRelax"
    }
  ],
  "63":[
    {
      "name": "Radio",
      "type": "tunein",
      "value": "s2398"
    }
  ],
  "64":[]
}
