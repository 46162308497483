'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('RadioManager', [
    '$rootScope',
    'BAS_TUNE_IN',
    'BAS_FAVOURITE',
    'BAS_APP_PROFILE',
    'BasLibraryManager',
    'BasLibraryBody',
    'BasLibraryPage',
    'RadioCollection',
    'RadioRequest',
    'RadioHelper',
    'BasCurrentAppProfile',
    'BasUtilities',
    'Logger',
    radioManagerFactory
  ])

/**
 * @param $rootScope
 * @param {BAS_TUNE_IN} BAS_TUNE_IN
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param BasLibraryManager
 * @param BasLibraryBody
 * @param BasLibraryPage
 * @param RadioCollection
 * @param RadioRequest
 * @param {RadioHelper} RadioHelper
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns RadioManager
 */
function radioManagerFactory (
  $rootScope,
  BAS_TUNE_IN,
  BAS_FAVOURITE,
  BAS_APP_PROFILE,
  BasLibraryManager,
  BasLibraryBody,
  BasLibraryPage,
  RadioCollection,
  RadioRequest,
  RadioHelper,
  BasCurrentAppProfile,
  BasUtilities,
  Logger
) {
  var className = 'Radio manager'

  /**
   * @constructor
   * @extends BasLibraryManager
   * @param {PlayerLibraryState} libraryState
   */
  function RadioManager (libraryState) {

    // Call template constructor
    BasLibraryManager.call(this, libraryState)

    // Link the control functions
    this.linkControls()

    // Initialize header
    this.header.setTitle(BasUtilities.translate('radio'))
    this.header.setSubtitle('')
    this.header.enableSearch(true)
    this.header.enableNavigation(true)

    /**
     * Set current language key
     *
     * @instance
     * @type {string}
     */
    this.language = BasUtilities.getTranslateKey()

    /**
     * @instance
     * @type {Function}
     */
    this.translationChangeDeregister = null

    this.loadStart()
  }

  // Set new prototype from inherited object
  RadioManager.prototype = Object.create(BasLibraryManager.prototype)

  // Set correct constructor after setting new prototype
  RadioManager.prototype.constructor = RadioManager

  RadioManager.prototype.linkControls = function () {

    // Body controls
    if (BasUtil.isObject(this.body.control)) {

      this.body.control[BasLibraryBody.CLK_ELEMENT] =
        this.clickElement.bind(this)
      this.body.control[BasLibraryBody.CLK_FAVOURITE] =
        this.clickFavourite.bind(this)
      this.body.control[BasLibraryBody.CLK_BROWSE] =
        this.clickBrowse.bind(this)
    }
  }

  /**
   * Search TuneIn for query
   *
   * @param {string} query
   */
  RadioManager.prototype.search = function (query) {

    var page, collection, request

    if (BasUtil.isNEString(query)) {

      request = new RadioRequest()
      request.setPath(BAS_TUNE_IN.PATH_SEARCH)
      request.setParam(BAS_TUNE_IN.PARAM_QUERY, query)

      collection = new RadioCollection()
      collection.setRequest(request)

      page = new BasLibraryPage()
      page.setType(BasLibraryPage.TYPE_SEARCH)
      page.setTitleId('search_result')
      page.setSubtitle(query)
      page.addCollection(collection)

      // Add page to pages
      this.addPage(page, true)

      // Hide search
      this.header.toggleSearchInput(false)

      BasCurrentAppProfile.addSearchHistory(
        query,
        BAS_APP_PROFILE.K_RADIO
      )

      // Clear query
      this.searchQuery = ''
    }
  }

  RadioManager.prototype.toggleSearch = function () {

    if (this.currentPage &&
      this.currentPage.type === BasLibraryPage.TYPE_SEARCH) {

      this.searchQuery = this.currentPage.subtitle
    }
  }

  /**
   * @param {RadioElement} element
   */
  RadioManager.prototype.clickElement = function (element) {

    // Check type
    switch (element.type) {
      case BAS_TUNE_IN.EL_TYPE_LINK:

        if (this.currentPage.state !==
          BasLibraryPage.STATE_OVERLAY_LOADING) {
          this.navigateLink(element)
        }

        break
      case BAS_TUNE_IN.EL_TYPE_AUDIO:

        this.playStation(element)

        break
      default:
        Logger.warn(className + ' - clickElement - Unknown type', element)
    }
  }

  /**
   * Navigates the TuneIn link element
   *
   * @param {RadioElement} element
   */
  RadioManager.prototype.navigateLink = function (element) {

    var page, collection, request, params

    if (element.type === BAS_TUNE_IN.EL_TYPE_LINK) {

      // Hide search
      this.header.toggleSearchInput(false)

      // Get useful request parameters
      params = BasUtil.isNEString(element.URL)
        ? RadioHelper.getRequestParameters(element.URL)
        : {}

      request = new RadioRequest()
      request.setParams(params)

      // Check for custom stations entry
      RadioHelper.checkCustomStationsRequest(element, request)

      collection = new RadioCollection()
      collection.setRequest(request)

      page = new BasLibraryPage()
      page.setTitle(element.title)
      page.addCollection(collection)

      this.addPage(page, true)

    } else {
      Logger.error(className +
                     ' - navigateLink' +
                     ' - No link element', element)
    }
  }

  /**
   * Plays the current station
   *
   * @param {RadioElement} element
   */
  RadioManager.prototype.playStation = function (element) {

    var basSource

    if (element.type === BAS_TUNE_IN.EL_TYPE_AUDIO) {

      basSource = this.libraryState.getBasSource()

      // Check player is valid
      if (basSource &&
        basSource.source) {

        if (basSource.isAudioSource) {

          basSource.playUri(element.servicePrefix + element.id)

        } else {

          basSource.source.startTuneInStream(
            element.id
          )
        }

      } else {
        Logger.warn(className + ' - playStation - Invalid player')
      }
    } else {
      Logger.error(className + ' - playStation - Invalid element', element)
    }
  }

  /**
   * @param {RadioElement} element
   */
  RadioManager.prototype.clickBrowse = function (element) {

    if (this.currentPage.state !==
      BasLibraryPage.STATE_OVERLAY_LOADING) {

      this.navigateLink(element)
    }
  }

  /**
   * Toggle the Asano favourite status
   *
   * @param {RadioElement} element
   */
  RadioManager.prototype.clickFavourite = function (element) {

    var basSource, identifier

    basSource = this.libraryState.getBasSource()

    identifier = (basSource && element)
      ? basSource.isAudioSource
        ? element.asanoFavouriteUri
        : element.asanoFavouriteId
      : ''

    // Check element
    if (
      BasUtil.isObject(element) &&
      BasUtil.isNEString(element.id) &&
      BasUtil.isNEString(identifier) &&
      basSource &&
      basSource.favourites
    ) {

      if (basSource.favourites.isFavourite(identifier)) {

        basSource.favourites.remove(identifier)

      } else {

        basSource.favourites.add(
          BAS_FAVOURITE.T_RADIO,
          basSource.isAudioSource
            ? identifier
            : element.id
        )
      }
    }
  }

  RadioManager.prototype.loadStart = function () {

    var startPage

    // Check for start page
    if (BasUtil.isObject(this.pages[0])) {

      // Destroy the current start page
      this.pages[0].destroy()
    }

    // Create new start page
    startPage = createStart()

    // Insert the start page
    this.pages[0] = startPage

    // Show loading screen
    startPage.setState(BasLibraryPage.STATE_OVERLAY_LOADING)

    // Start the retrieve
    startPage.retrieve()
      .then(onPage, onPageError)

    this.syncPagesUi()

    function createStart () {

      var page, collection, request

      // Create root request
      request = new RadioRequest()

      // Create a collection
      collection = new RadioCollection()
      collection.setRequest(request)

      // Create a new page
      page = new BasLibraryPage()
      page.setTitle(BasUtilities.translate('radio'))
      page.addCollection(collection)

      return page
    }

    function onPage () {

      startPage.syncState()

      $rootScope.$applyAsync()
    }

    function onPageError (error) {
      Logger.error(className +
                     ' - loadStart' +
                     ' - onPage ERROR', error)

      startPage.setState(BasLibraryPage.STATE_ERROR)

      $rootScope.$applyAsync()
    }
  }

  /**
   * Suspend the service
   * Remove all favourite watches
   */
  RadioManager.prototype.suspend = function () {

    // Set scroll percentage
    this.currentPage.setScrollPercent(this.getScrollPercentage())

    // Clear language change listener
    this.clearTranslationListener()

    // Suspend the current page
    if (BasUtil.isObject(this.currentPage)) {
      this.currentPage.suspend()
    }
  }

  /**
   * Register all watches
   */
  RadioManager.prototype.resume = function () {

    // Wait one frame to set new scrollPos
    BasUtilities.waitFrames(2).then(
      this.setScrollPos.bind(this, this.currentPage.getScrollPercent())
    )

    // Listen language changes
    this.setTranslationListener()

    // Check if language was changed
    this.checkLanguage()

    // Resume the current page
    if (BasUtil.isObject(this.currentPage)) {
      this.currentPage.resume()
    }
  }

  RadioManager.prototype.setTranslationListener = function () {

    // Make sure previous listener is cleared
    this.clearTranslationListener()

    // Set listener
    this.translationChangeDeregister =
      $rootScope.$on('$translateChangeSuccess', this.checkLanguage)
  }

  RadioManager.prototype.clearTranslationListener = function () {

    // Check deregister
    if (typeof this.translationChangeDeregister === 'function') {

      // Execute deregister
      this.translationChangeDeregister()

      // Clear deregister
      this.translationChangeDeregister = null
    }
  }

  RadioManager.prototype.onReachedEnd = function () {

    // Do nothing
  }

  /**
   * Checks the previous language key with the current language.
   *
   * If the language keys do not match,
   * the manager returns to the start page and
   * the start page will be reloaded.
   */
  RadioManager.prototype.checkLanguage = function () {

    var languageKey

    // Get current language key
    languageKey = BasUtilities.getTranslateKey()

    // Check language key and compare
    if (this.language !== languageKey) {

      // Go home (discarding all pages)
      this.home()

      // Replace start page
      this.loadStart()

      // Update language key
      this.language = languageKey
    }
  }

  return RadioManager
}
