'use strict'

angular
  .module('basalteApp')
  .directive('basTile', basTileDirective)

function basTileDirective () {

  return {
    restrict: 'EA',
    template: '' +
      '<div class="bas-tile-svg"></div>' +
      '<div class="bas-tile-title">' +
      '  <span ng-if="basTile.tile.subtitle" ' +
      '        class="ellipsis-overflow"' +
      // If no custom name is set then we would like to show our
      // subtitle in the same styling as our title.
      '        ng-class="basTile.tile.name ' +
      '         ? \'bas-tile-subtitle-txt\' ' +
      '         : \'bas-tile-title-txt\'"' +
      '        ng-bind="basTile.tile.subtitle"></span>' +
      '  <span ng-if="basTile.tile.name" ' +
      '        class="bas-tile-title-txt ellipsis-overflow"' +
      '        ng-bind="basTile.tile.name"></span>' +
      '</div>',
    bindToController: {
      tile: '<'
    },
    transclude: true,
    controller: [
      '$scope',
      '$element',
      '$compile',
      controller
    ],
    controllerAs: 'basTile'
  }

  /**
   * @param $scope
   * @param $element
   * @param $compile
   */
  function controller ($scope, $element, $compile) {

    var basTile = this

    var container = $element[0].getElementsByClassName('bas-tile-svg')[0]

    basTile.$onChanges = onChanges

    function syncIcon () {

      if (basTile.tile) {

        container.innerHTML = basTile.tile.icon

        if (basTile.tile.compileIcon) {

          $compile(container)($scope)
        }
      } else {

        container.innerHTML = ''
      }
    }

    function onChanges () {

      syncIcon()
    }
  }
}
