'use strict'

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')

/**
 * An object representing a temperature
 *
 * @constructor
 */
function Temperature () {

  /**
   * @type {number}
   */
  this._celsius = Temperature.kelvinToCelsius(0)

  /**
   * @type {number}
   */
  this._fahrenheit = Temperature.kelvinToFahrenheit(0)

  /**
   * @type {number}
   */
  this._kelvin = 0
}
/**
 * @param {Temperature} temperature1
 * @param {Temperature} temperature2
 * @returns {boolean}
 */
Temperature.equals = function (
  temperature1,
  temperature2
) {
  return Math.abs(temperature1._kelvin - temperature2._kelvin) < 0.01
}

/**
 * @param {number} celsius
 * @returns {number}
 */
Temperature.celsiusToFahrenheit = function (celsius) {

  return BasUtil.isVNumber(celsius) ? (celsius * 1.8) + 32 : 0
}

/**
 * @param {number} celsius
 * @returns {number}
 */
Temperature.celsiusToKelvin = function (celsius) {

  return BasUtil.isVNumber(celsius) ? celsius + 273.15 : 0
}

/**
 * @param {number} fahrenheit
 * @returns {number}
 */
Temperature.fahrenheitToCelsius = function (fahrenheit) {

  return BasUtil.isVNumber(fahrenheit) ? (fahrenheit - 32) / 1.8 : 0
}

/**
 * @param {number} fahrenheit
 * @returns {number}
 */
Temperature.fahrenheitToKelvin = function (fahrenheit) {

  return BasUtil.isVNumber(fahrenheit)
    ? (fahrenheit + 459.67) * 5.0 / 9.0
    : 0
}

/**
 * @param {number} kelvin
 * @returns {number}
 */
Temperature.kelvinToCelsius = function (kelvin) {

  return BasUtil.isVNumber(kelvin) ? kelvin - 273.15 : 0
}

/**
 * @param {number} kelvin
 * @returns {number}
 */
Temperature.kelvinToFahrenheit = function (kelvin) {

  return BasUtil.isVNumber(kelvin) ? (kelvin * 9.0 / 5.0) - 459.67 : 0
}

/**
 * @param {number} celsius
 */
Temperature.prototype.setCelsius = function (celsius) {

  if (BasUtil.isVNumber(celsius)) {

    this._celsius = celsius
    this._fahrenheit = Temperature.celsiusToFahrenheit(this._celsius)
    this._kelvin = Temperature.celsiusToKelvin(this._celsius)
  }
}

/**
 * @param {number} fahrenheit
 */
Temperature.prototype.setFahrenheit = function (fahrenheit) {

  if (BasUtil.isVNumber(fahrenheit)) {

    this._fahrenheit = fahrenheit
    this._celsius = Temperature.fahrenheitToCelsius(this._fahrenheit)
    this._kelvin = Temperature.fahrenheitToKelvin(this._fahrenheit)
  }
}

/**
 * @param {number} kelvin
 */
Temperature.prototype.setKelvin = function (kelvin) {

  if (BasUtil.isVNumber(kelvin)) {

    this._kelvin = kelvin
    this._celsius = Temperature.kelvinToCelsius(this._kelvin)
    this._fahrenheit = Temperature.kelvinToFahrenheit(this._kelvin)
  }
}

/**
 * @param {(Temperature|number)} temperature
 * @param {string} [unit]
 */
Temperature.prototype.setTemperature = function (
  temperature,
  unit
) {
  if (BasUtil.isVNumber(temperature)) {

    switch (unit) {
      case CONSTANTS.TU_CELSIUS:

        this.setCelsius(temperature)

        break
      case CONSTANTS.TU_FAHRENHEIT:

        this.setFahrenheit(temperature)

        break
      case CONSTANTS.TU_KELVIN:

        this.setKelvin(temperature)

        break
    }

  } else if (temperature instanceof Temperature) {

    this._celsius = temperature._celsius
    this._fahrenheit = temperature._fahrenheit
    this._kelvin = temperature._kelvin
  }
}

/**
 * Returns kelvin value if no unit is given.
 *
 * @param {string} unit
 * @returns {number}
 */
Temperature.prototype.getTemperature = function (unit) {

  switch (unit) {
    case CONSTANTS.TU_CELSIUS:

      return this._celsius

    case CONSTANTS.TU_FAHRENHEIT:

      return this._fahrenheit

    case CONSTANTS.TU_KELVIN:

      return this._kelvin

    default:

      return this._kelvin
  }
}

/**
 * @returns {Temperature}
 */
Temperature.prototype.clone = function () {

  var result

  result = new Temperature()
  result._celsius = this._celsius
  result._fahrenheit = this._fahrenheit
  result._kelvin = this._kelvin

  return result
}

module.exports = Temperature
