'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('discoveryCtrl', [
    '$rootScope',
    '$scope',
    'ModalService',
    'Swiper',
    'STATES',
    'BAS_HTML',
    'BAS_APP',
    'BAS_DISCOVERED_SERVICES',
    'BAS_MODAL',
    'BAS_SPLASH',
    'BAS_ERRORS',
    'BasApp',
    'BasAppDevice',
    'BasCoreConnection',
    'CurrentBasCore',
    'BasDiscoveredServices',
    'BasDiscovery',
    'BasStoredServers',
    'BasLiveAccount',
    'BasModal',
    'BasSplash',
    'BasState',
    'BasUtilities',
    discoveryCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param ModalService
 * @param Swiper
 * @param {STATES} STATES
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_APP} BAS_APP
 * @param {BAS_DISCOVERED_SERVICES} BAS_DISCOVERED_SERVICES
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BasApp} BasApp
 * @param {BasAppDevice} BasAppDevice
 * @param {BasCoreConnection} BasCoreConnection
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasDiscoveredServices} BasDiscoveredServices
 * @param {BasDiscovery} BasDiscovery
 * @param {BasStoredServers} BasStoredServers
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasModal} BasModal
 * @param {BasSplash} BasSplash
 * @param {BasState} BasState
 * @param {BasUtilities} BasUtilities
 */
function discoveryCtrl (
  $rootScope,
  $scope,
  ModalService,
  Swiper,
  STATES,
  BAS_HTML,
  BAS_APP,
  BAS_DISCOVERED_SERVICES,
  BAS_MODAL,
  BAS_SPLASH,
  BAS_ERRORS,
  BasApp,
  BasAppDevice,
  BasCoreConnection,
  CurrentBasCore,
  BasDiscoveredServices,
  BasDiscovery,
  BasStoredServers,
  BasLiveAccount,
  BasModal,
  BasSplash,
  BasState,
  BasUtilities
) {
  var discovery = this

  var _listeners = []
  var _interactListeners = []
  var _destroyed = false

  var _inactivityTimeout

  var _swiper

  var isModalOpen = false

  var CSS_SHOW_TITLE = 'bas-discovery--show-title'
  var CSS_SHOW_PLACEHOLDER = 'bas-discovery--show-placeholder'

  var LISTENER_OPTS = {
    capture: false,
    passive: true
  }
  // Starting auto reconnect already includes a 10 seconds timeout, so
  // reconnect starts after 70 seconds of inactivity.
  var INACTIVITY_AUTO_RECONNECT_TIMEOUT = 60000

  // Reconnect for Core Client devices wilt start after 20 seconds.
  var CC_INACTIVITY_AUTO_RECONNECT_TIMEOUT = 10000

  /**
   * @type {TBasStateObj}
   */
  var basState = BasState.get()

  discovery.css = {}
  discovery.title = ''
  discovery.placeholder_l1 = ''
  discovery.placeholder_l2 = ''

  /**
   * @type {STATES}
   */
  discovery.STATES = STATES

  /**
   * @type {BasAppDeviceState}
   */
  discovery.basAppDeviceState = BasAppDevice.get()

  /**
   * @type {TBasDiscoveredServicesState}
   */
  discovery.basDiscoveredServicesState = BasDiscoveredServices.get()

  /**
   * @type {TBasLiveAccountState}
   */
  discovery.basLiveAccountState = BasLiveAccount.get()

  /**
   * @type {TBasSplashState}
   */
  discovery.basSplashState = BasSplash.get()

  discovery.selectServer = selectServer
  discovery.addServer = addServer
  discovery.removeServer = removeServer
  discovery.back = back

  init()

  function init () {
    $scope.$on('$destroy', _onDestroy)

    _resetCss()

    _listeners.push($rootScope.$on(
      BAS_APP.EVT_PAUSE,
      _onAppPause
    ))
    _listeners.push($rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onAppResume
    ))
    _listeners.push($rootScope.$on(
      BAS_DISCOVERED_SERVICES.EVT_DISCOVERED_SERVICES_UPDATED,
      _onDiscoveredServicesUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibilityChanged
    ))
    _listeners.push($rootScope.$on(
      BAS_MODAL.EVT_ADD_SERVER_MODAL_VISIBILITY_CHANGED,
      _onAddServerModalVisibilityChanged
    ))
    _listeners.push($rootScope.$on(
      '$translateChangeSuccess',
      _onTranslationChanged
    ))

    if (BasApp.isPaused()) {

      BasDiscovery.setShouldBeDiscoveringState(true)

    } else {

      if (!BasDiscovery.isRunning()) BasDiscovery.restart()
    }

    // Make sure there is no current BasCoreContainer
    CurrentBasCore.set(null)

    if (!BasApp.isPaused() && discovery.basLiveAccountState.isLoggedIn) {

      BasLiveAccount.getLastListProjects().then(
        _onLastListProjects,
        _onLastListProjectsError
      )
    }

    _updateServices()
    _cleanupSelectedCss()

    if (!BasApp.isPaused() && !discovery.basSplashState.uiShow) {

      _enableInactivityTimeout()
    }

    BasCoreConnection.addResumeCallback(_onBasCoreConnectionResumeCallback)

    BasLiveAccount.registerForProjectStatus(discovery)

    BasUtilities.waitForFrames(2, _onInitialFrames)
  }

  /**
   * @param {string} id
   */
  function selectServer (id) {

    var uiCore, coreConnectionCore

    uiCore = discovery.basDiscoveredServicesState.services[id]

    if (uiCore) {

      _disableInactivityTimeout()

      if (uiCore.liveProject) {

        if (uiCore.liveProject.online) {

          if (
            BasAppDevice.isProLive() &&
            !uiCore.liveProject.integratorAccess
          ) {

            isModalOpen = true
            BasModal.show(
              BAS_MODAL.T_CONNECT_TO_INTEGRATOR_BLOCKED,
              {
                isSplashModal: true
              }
            ).then(_onIntegratorBlockedModal)

          } else {

            _connect()
          }

        } else {

          isModalOpen = true
          BasModal.show(
            BAS_MODAL.T_CONNECT_TO_OFFLINE_SERVER,
            {
              isSplashModal: true
            }
          ).then(_onOfflineServerModal)
        }

      } else {

        _connect()
      }
    }

    function _onOfflineServerModal (modal) {

      modal.close.then(_onOfflineServerModalClosed)
    }

    function _onOfflineServerModalClosed (result) {

      if (result === BAS_MODAL.C_YES) {

        _connect()
      }

      _enableInactivityTimeout()
      isModalOpen = false
    }

    function _onIntegratorBlockedModal (modal) {

      modal.close.then(_onIntegratorBlockedModalClosed)
    }

    function _onIntegratorBlockedModalClosed (result) {

      if (result === BAS_MODAL.C_YES) {

        _connect()
      }

      _enableInactivityTimeout()
      isModalOpen = false
    }

    function _connect () {
      coreConnectionCore = uiCore.getBasCoreConnectionCore()

      if (coreConnectionCore) {

        BasCoreConnection.selectCore(coreConnectionCore)

      } else {

        BasModal.show(BAS_MODAL.T_UNABLE_TO_CONNECT)
      }

      uiCore.setSelected(!!coreConnectionCore)
    }
  }

  function addServer () {

    _disableInactivityTimeout()
    isModalOpen = true

    ModalService.showModal({
      template: BAS_HTML.addServerModal,
      controller: 'addServerModalCtrl',
      controllerAs: 'modal'
    }).then(_onAddServerModal)

    function _onAddServerModal (modal) {

      modal.close.then(_onAddServerModalClosed)
    }

    function _onAddServerModalClosed () {

      _enableInactivityTimeout()
      isModalOpen = false
    }
  }

  /**
   * @param {string} serverId
   */
  function removeServer (serverId) {

    _disableInactivityTimeout()
    isModalOpen = true

    BasModal.show(
      BAS_MODAL.T_REMOVE,
      {
        isSplashModal: true
      }
    ).then(_onRemoveServerModal)

    function _onRemoveServerModal (modal) {

      modal.close.then(_onRemoveServerModalClosed)
    }

    function _onRemoveServerModalClosed (result) {

      if (result === BAS_MODAL.C_YES) {

        BasStoredServers.removeServerById(serverId)
      }

      _enableInactivityTimeout()
      isModalOpen = false
    }
  }

  function _onAppPause () {

    _disableInactivityTimeout()
  }

  function _onAppResume () {

    if (discovery.basLiveAccountState.isLoggedIn) {

      BasLiveAccount.getLastListProjects().then(
        _onLastListProjects,
        _onLastListProjectsError
      )
    }
  }

  function _onDiscoveredServicesUpdated () {
    _updateServices()
    _syncScope()
    BasUtilities.waitForFrames(2, _updateSwiper)
  }

  /**
   * @private
   * @param _event
   * @param {boolean} isVisible
   */
  function _onSplashVisibilityChanged (_event, isVisible) {
    _handleModalSplashVisibility(isVisible)
    _syncScope()
  }

  /**
   * @private
   * @param _event
   * @param {boolean} isVisible
   */
  function _onAddServerModalVisibilityChanged (_event, isVisible) {
    _handleModalSplashVisibility(isVisible)
    _syncScope()
  }

  function _onTranslationChanged () {
    _updateServices()
    _syncScope()
  }

  function _onInitialFrames () {
    if (_destroyed) return
    _setSwiper()
    _syncScope()
  }

  function _onLastListProjects () {
    if (_destroyed) return
    _updateServices()
    _syncScope()
  }

  /**
   * @private
   * @param {?BasError} error
   */
  function _onLastListProjectsError (error) {
    _updateServices(error)
  }

  /**
   * @private
   * @returns {boolean}
   */
  function _onBasCoreConnectionResumeCallback () {
    return !isModalOpen
  }

  /**
   * @private
   * @param {?BasError} [error]
   */
  function _updateServices (error) {

    var noServers

    noServers = discovery.basDiscoveredServicesState.uiServices.length === 0
    _reset()

    if (noServers) {

      discovery.placeholder_l1 = BasUtilities.translate('no_servers')
      discovery.css[CSS_SHOW_PLACEHOLDER] = true
    }

    // Live only
    if (discovery.basAppDeviceState.onlyWebRTC) {

      // Live supported
      if (discovery.basAppDeviceState.supportsBasalteLive) {

        // Logged in
        if (discovery.basLiveAccountState.isLoggedIn) {

          // No servers found
          if (noServers) {

            discovery.css[CSS_SHOW_PLACEHOLDER] = true

            if (discovery.basAppDeviceState.isProLiveHosted) {

              discovery.title = BasUtilities.translate('no_projects')
              discovery.css[CSS_SHOW_TITLE] = true

              if (error && error.basType === BAS_ERRORS.T_LIVE_WAF) {

                discovery.placeholder_l1 =
                  BasUtilities.translate('projects_network_error')
                discovery.placeholder_l2 =
                  BasUtilities.translate(
                    'try_again_later_or_contact_support'
                  )
              }

            } else {

              discovery.title = BasUtilities.translate('no_linked_homes')
              discovery.placeholder_l1 = BasUtilities.translate('link_home')
              discovery.css[CSS_SHOW_TITLE] = true
            }
          }

        } else {

          // This state should be prevented
        }

      } else {

        discovery.title = BasUtilities.translate('unsupported_browser')
        discovery.placeholder_l1 =
          BasUtilities.translate('unsupported_browser_live')
        discovery.css[CSS_SHOW_PLACEHOLDER] = true
      }
    }

    _updateSwiper()
  }

  /**
   * @private
   * @param {boolean} isVisible
   */
  function _handleModalSplashVisibility (isVisible) {

    if (isVisible) {

      _disableInactivityTimeout()

    } else {

      _cleanupSelectedCss()
      _enableInactivityTimeout()
    }
  }

  function _setSwiper () {

    _clearSwiper()

    _swiper = new Swiper(
      '.bd-swiper-container',
      {
        // Enable mouse events
        touchStartPreventDefault: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // iOS Safari is the worst
        // When cssMode is disabled (default), text disappears while scrolling
        cssMode: BasAppDevice.isIos() || BasAppDevice.isSafari()
      }
    )
  }

  function _updateSwiper () {

    if (_swiper && _swiper.update) _swiper.update()
  }

  function _clearSwiper () {

    if (_swiper && _swiper.destroy) _swiper.destroy(true, false)
    _swiper = null
  }

  function back () {

    if (basState.current.CONNECT_DISCOVERY) BasState.go(STATES.CONNECT_LIVE)
  }

  function _setInteractionListeners () {

    if (_destroyed) return

    // Mouse listeners
    _interactListeners.push(BasUtil.setDOMListener(
      document,
      'mousedown',
      _onUserInteraction,
      LISTENER_OPTS
    ))
    _interactListeners.push(BasUtil.setDOMListener(
      document,
      'mouseup',
      _onUserInteraction,
      LISTENER_OPTS
    ))
    _interactListeners.push(BasUtil.setDOMListener(
      document,
      'mousemove',
      _onUserInteraction,
      LISTENER_OPTS
    ))
    _interactListeners.push(BasUtil.setDOMListener(
      document,
      'wheel',
      _onUserInteraction,
      LISTENER_OPTS
    ))

    // Touch listeners
    _interactListeners.push(BasUtil.setDOMListener(
      document,
      'touchstart',
      _onUserInteraction,
      LISTENER_OPTS
    ))
    _interactListeners.push(BasUtil.setDOMListener(
      document,
      'touchend',
      _onUserInteraction,
      LISTENER_OPTS
    ))
    _interactListeners.push(BasUtil.setDOMListener(
      document,
      'touchmove',
      _onUserInteraction,
      LISTENER_OPTS
    ))
    _interactListeners.push(BasUtil.setDOMListener(
      document,
      'touchcancel',
      _onUserInteraction,
      LISTENER_OPTS
    ))
  }

  function _clearInteractionListeners () {

    BasUtil.executeArray(_interactListeners)
    _interactListeners = []
  }

  function _setInactivityTimeout () {

    _clearInactivityTimeout()

    if (_destroyed) return

    if (
      !(BasAppDevice.isLiveOnly() && BasAppDevice.isProLive()) &&
      !BasAppDevice.isBrowser()
    ) {
      _inactivityTimeout = setTimeout(
        _onInactivityTimeout,
        BasAppDevice.isCoreClient()
          ? CC_INACTIVITY_AUTO_RECONNECT_TIMEOUT
          : INACTIVITY_AUTO_RECONNECT_TIMEOUT
      )
    }
  }

  function _clearInactivityTimeout () {

    clearTimeout(_inactivityTimeout)
  }

  function _enableInactivityTimeout () {

    _setInactivityTimeout()
    _setInteractionListeners()
  }

  function _disableInactivityTimeout () {

    _clearInactivityTimeout()
    _clearInteractionListeners()
  }

  function _onInactivityTimeout () {

    // TODO: Ideally, we should not start periodic auto reconnect while a user
    //  is holding down his finger/mouse. It is however unlikely that a user
    //  will hold down for 20 seconds without triggering a 'touchmove' or
    //  'mousemove' event.

    BasCoreConnection.startPeriodicAutoReconnect(
      {
        showModalOnError: false
      }
    )
  }

  function _onUserInteraction () {

    _setInactivityTimeout()
    BasCoreConnection.stopAutoReconnect()
  }

  function _cleanupSelectedCss () {

    BasDiscoveredServices.setAllServicesSelected(false)
  }

  function _syncScope () {

    $scope.$applyAsync()
  }

  function _resetCss () {

    discovery.css[CSS_SHOW_TITLE] = true
    discovery.css[CSS_SHOW_PLACEHOLDER] = false
  }

  function _reset () {

    discovery.title = ''
    discovery.placeholder_l1 = ''
    discovery.placeholder_l2 = ''

    _resetCss()
  }

  function _onDestroy () {

    _destroyed = true

    BasUtil.executeArray(_listeners)
    _listeners = []

    _disableInactivityTimeout()

    BasCoreConnection.removeResumeCallback(_onBasCoreConnectionResumeCallback)

    BasLiveAccount.unregisterForProjectStatus(discovery)

    _clearSwiper()

    // Discovery should usually be stopped when exiting the discovery state
    BasDiscovery.stop()
  }
}
