'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSchedulerDay', [
    'BAS_INTL',
    'BasSchedulerEntry',
    'BasUtilities',
    basSchedulerDayFactory
  ])

/**
 * @param {BAS_INTL} BAS_INTL
 * @param BasSchedulerEntry
 * @param {BasUtilities} BasUtilities
 * @returns BasSchedulerDay
 */
function basSchedulerDayFactory (
  BAS_INTL,
  BasSchedulerEntry,
  BasUtilities
) {
  /**
   * @constructor
   * @extends BasSchedulerEntry
   * @param {string} id
   */
  function BasSchedulerDay (id) {

    BasSchedulerEntry.call(this)

    /**
     * @type {string}
     */
    this.id = id

    this.updateTranslation()
  }

  BasSchedulerDay.prototype = Object.create(BasSchedulerEntry.prototype)
  BasSchedulerDay.prototype.constructor = BasSchedulerDay

  BasSchedulerDay.prototype.updateTranslation = function () {

    BasSchedulerEntry.prototype.updateTranslation.call(this)

    if (BasUtil.isNEString(this.id)) {
      if (BAS_INTL.DAYS_WEEK.indexOf(this.id) > -1) {
        this.title = BAS_INTL.DAYS_WEEK_TRANSLATED[this.id]
      } else {
        this.title = BasUtilities.translate(this.id)
      }
    } else {
      this.title = '-'
    }
  }

  /**
   * @returns {BasSchedulerEntry}
   */
  BasSchedulerDay.prototype.clone = function () {
    const clone = BasSchedulerEntry.prototype.clone.call(this)
    clone.id = this.id

    return clone
  }

  return BasSchedulerDay
}
