'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasServerDevice', [
    '$rootScope',
    'BAS_API',
    'BAS_DEVICE',
    'BasDevice',
    basServerDeviceFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_DEVICE} BAS_DEVICE
 * @param BasDevice
 * @returns BasServerDevice
 */
function basServerDeviceFactory (
  $rootScope,
  BAS_API,
  BAS_DEVICE,
  BasDevice
) {
  var CSS_CHECKING_IS_FOR_UPDATE = 'bas-server-is-checking-for-update'
  var CSS_DOWNLOADING_UPDATE = 'bas-server-is-downloading-update'
  var CSS_HAS_UPDATE = 'bas-server-has-update'
  var CSS_HAS_ERRORS = 'bas-server-has-errors'

  /**
   * @constructor
   * @extends BasDevice
   * @param {ServerDevice} server
   */
  function BasServerDevice (server) {

    this.name = BasServerDevice._DEF_NAME
    this.ip = ''
    this.host = ''
    this.address = ''
    this.hasUpdate = false
    this.versionShort = BasDevice._DEF_VERSION
    this.versionFull = BasDevice._DEF_VERSION

    this.uiVersion = this.versionShort

    this._listeners = []

    this._handleErrors = this._onErrors.bind(this)
    this._handleIp = this._onIp.bind(this)
    this._handleVersion = this._onVersion.bind(this)
    this._handleUpdateStatus = this._onUpdateStatus.bind(this)

    BasDevice.call(this, server)
  }

  BasServerDevice.prototype = Object.create(BasDevice.prototype)
  BasServerDevice.prototype.constructor = BasServerDevice

  /**
   * @type {ServerDevice.UPDATE_STATUS}
   */
  BasServerDevice.UPDATE_STATE = BAS_API.ServerDevice.UPDATE_STATUS

  /**
   * @private
   * @constant {string}
   */
  BasServerDevice._DEF_NAME = 'server'

  /**
   * @param {ConnectedDevice} server
   */
  BasServerDevice.prototype.parse = function (server) {

    BasDevice.prototype.parse.call(this, server)

    if (this.device instanceof BAS_API.ServerDevice) {

      this.name = this.device.name
        ? this.device.name
        : BasServerDevice._DEF_NAME
      this.ip = this.device.ip
      this.address = this.ip
      this.typeStr = BAS_API.Device.getDeviceType(this.device.basType)

      if (this.device.version) {

        this.versionShort = this.device.version.textShort
        this.versionFull = this.device.version.textFull

        this.uiVersion = this.versionShort
      }

      this._syncUpdateState()
      this._syncErrorState()

      this._setListeners()
    }

    this._setDefaults()
  }

  /**
   * Toggle uiVersion between short and full representation
   *
   * @param {string} [force]
   */
  BasServerDevice.prototype.uiToggleVersion = function (force) {

    if (force === BAS_DEVICE.T_VERSION_SHORT) {

      this.uiVersion = this.versionShort

    } else if (force === BAS_DEVICE.T_VERSION_LONG) {

      this.uiVersion = this.versionFull

    } else {

      if (this.uiVersion === this.versionShort) {

        this.uiVersion = this.versionFull

      } else if (this.uiVersion === this.versionFull) {

        this.uiVersion = this.versionShort
      }
    }
  }

  BasServerDevice.prototype._setDefaults = function () {

    if (this.name === BasDevice._DEF_NAME) {

      this.name = BasServerDevice._DEF_NAME
    }
  }

  /**
   * @private
   */
  BasServerDevice.prototype._syncUpdateState = function () {

    if (this.device) {

      this.hasUpdate =
        this.device.updateStatus ===
        BasServerDevice.UPDATE_STATE.READY
      this.css[CSS_CHECKING_IS_FOR_UPDATE] =
        this.device.updateStatus ===
        BasServerDevice.UPDATE_STATE.CHECKING
      this.css[CSS_DOWNLOADING_UPDATE] =
        this.device.updateStatus ===
        BasServerDevice.UPDATE_STATE.DOWNLOADING

    } else {

      this.hasUpdate = false
      this.css[CSS_CHECKING_IS_FOR_UPDATE] = false
      this.css[CSS_DOWNLOADING_UPDATE] = false
    }

    this.css[CSS_HAS_UPDATE] = this.hasUpdate
  }

  /**
   * @private
   */
  BasServerDevice.prototype._syncErrorState = function () {

    this.hasErrors = this.device
      ? this.device.errors.length > 0
      : false

    this.css[CSS_HAS_ERRORS] = this.hasErrors
  }

  /**
   * @private
   */
  BasServerDevice.prototype._onErrors = function () {

    this._syncErrorState()

    $rootScope.$emit(BAS_DEVICE.EVT_SERVER_DEVICE_UPDATED, this)
  }

  /**
   * @private
   */
  BasServerDevice.prototype._onIp = function () {

    if (this.device instanceof BAS_API.ServerDevice) {

      this.ip = this.address = this.device.ip

      $rootScope.$emit(BAS_DEVICE.EVT_SERVER_DEVICE_UPDATED, this)
    }
  }

  /**
   * @private
   */
  BasServerDevice.prototype._onVersion = function () {

    if (this.device instanceof BAS_API.ServerDevice) {

      this.version = this.device.version
        ? this.device.version.text
        : BasDevice._DEF_VERSION

      $rootScope.$emit(BAS_DEVICE.EVT_SERVER_DEVICE_UPDATED, this)
    }
  }

  /**
   * @private
   */
  BasServerDevice.prototype._onUpdateStatus = function () {

    this._syncUpdateState()

    $rootScope.$emit(BAS_DEVICE.EVT_SERVER_DEVICE_UPDATED, this)
    $rootScope.$emit(BAS_DEVICE.EVT_SERVER_DEVICE_UPDATE_STATE, this)
  }

  /**
   * @private
   */
  BasServerDevice.prototype._resetCss = function () {

    BasDevice.prototype._resetCss.call(this)

    this.css[CSS_CHECKING_IS_FOR_UPDATE] = false
    this.css[CSS_DOWNLOADING_UPDATE] = false
    this.css[CSS_HAS_UPDATE] = false
    this.css[CSS_HAS_ERRORS] = false
  }

  /**
   * Sets the necessary listeners on the ServerDevice instance
   *
   * @private
   */
  BasServerDevice.prototype._setListeners = function () {

    if (this.device instanceof BAS_API.ServerDevice) {

      this._listeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.ServerDevice.EVT_ERRORS,
        this._handleErrors
      ))
      this._listeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.ServerDevice.EVT_IP,
        this._handleIp
      ))
      this._listeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.ServerDevice.EVT_VERSION,
        this._handleVersion
      ))
      this._listeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.ServerDevice.EVT_UPDATE_STATUS,
        this._handleUpdateStatus
      ))
    }
  }

  /**
   * @private
   */
  BasServerDevice.prototype._clearListeners = function () {

    BasUtil.executeArray(this._listeners)
    this._listeners = []
  }

  /**
   * Removes all listeners and ServerDevice references
   */
  BasServerDevice.prototype.destroy = function () {

    this._clearListeners()
    BasDevice.prototype.destroy.call(this)
  }

  return BasServerDevice
}
