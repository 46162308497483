'use strict'

angular
  .module('basalteApp')
  .factory('BasRoomFloor', basRoomFloorFactory)

function basRoomFloorFactory () {

  /**
   * @constructor
   * @param {string} id
   * @param {number} order
   */
  function BasRoomFloor (id, order) {
    /**
     * @type {string}
     */
    this.id = id

    /**
     * @type {number}
     */
    this.order = order
  }

  return BasRoomFloor
}
