'use strict'

angular
  .module('basalteApp')
  .constant('BAS_LISA', {
    F_START: 'start',
    F_SCENES: 'scenes',
    F_THERMOSTAT: 'thermostat',
    F_LIGHTS: 'lights',
    F_MUSIC: 'music',
    F_SHADES: 'shades',
    F_CAMERA: 'cameras',
    F_INTERCOM: 'intercom',
    F_SETTINGS: 'settings',
    EVT_LISA_UPDATED: 'evtLisaUpdated'
  })
