'use strict'

var Topic = require('./topic')
var Device = require('./device')
var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

/**
 * @typedef {Object} TAudioAlertEventPlay
 * @property {string} name
 * @property {string} uuid
 * @property {number} repeat
 * @property {boolean} playAudio
 * @property {number} [duration]
 * @property {string} [audio]
 */

/**
 * @typedef {Object} TBasDoorphoneServerResponse
 * @property {string} [id]
 * @property {boolean} [success]
 * @property {
 * TBasGetSipAddressesServerResponseData |
 * TBasCallHistoryServerResponseData |
 * TBasGetCallDetailsServerResponseData
 * } [data]
 */

/**
 * @typedef {Object} TBasCallHistoryServerResponseData
 * @property {Array<TBasCallHistoryEntry>} [callHistory]
 */

/**
 * @typedef {Object} TBasGetSipAddressesServerResponseData
 * @property {Array<TBasSipAddress>} [sipAddresses]
 */

/**
 * @typedef {Object} TBasGetCallDetailsServerResponseData
 * @property {Array<TCallDetails>} [images]
 */

/**
 * @typedef {Object} TBasCallHistoryEntry
 * @property {string} [timestamp]
 * @property {TNameAndUUID} [contact]
 * @property {string} [state]
 * @property {string} [adelanteName]
 */

/**
 * @typedef {Object} TBasSipAddress
 * @property {string} [sipAddress]
 * @property {string} [password]
 * @property {string} [doorphone]
 * @property {string} [contact]
 */

/**
 * @typedef {Object} TNameAndUUID
 * @property {string} [name]
 * @property {string} [uuid]
 */

/**
 * @typedef {Object} TCallDetails
 * @property {string} [imgw400]
 * @property {string} [orig]
 */

/**
 * Class representing audio alert topic
 *
 * @constructor
 * @extends Topic
 * @param {BasCore} basCore
 */
function DoorPhoneTopic (basCore) {

  Topic.call(this)

  this.topic = P.DOOR_PHONE

  this._basCore = basCore
}

DoorPhoneTopic.prototype = Object.create(Topic.prototype)
DoorPhoneTopic.prototype.constructor = DoorPhoneTopic

DoorPhoneTopic.EVT_CALL_HISTORY_CHANGED = 'evtCallHistoryChanged'

DoorPhoneTopic.EVT_SIP_ADDRESSES_CHANGED = 'evtSipAddressesChanged'

/**
 * @typedef {Object} TSipInfo
 * @property {string} sipAddress
 * @property {string} password
 * @property {TNameAndUUID} doorPhone
 * @property {TNameAndUUID} contact
 * @property {TNameAndUUID[]} doors
 */

/**
 * Parse a door phone topic message
 *
 * @param {Object} msg
 */
DoorPhoneTopic.prototype.parse = function (msg) {

  var data

  if (
    msg &&
    msg[P.DATA] &&
    BasUtil.isNEString(msg[P.DATA][P.ACTION])
  ) {

    data = msg[P.DATA]

    switch (data[P.ACTION]) {

      case P.CALL_HISTORY_CHANGED:

        this.emit(DoorPhoneTopic.EVT_CALL_HISTORY_CHANGED)
        break

      case P.SIP_ADDRESSES_CHANGED:

        this.emit(DoorPhoneTopic.EVT_SIP_ADDRESSES_CHANGED)
        break
    }
  }
}

/**
 * @param {string} uuid
 * @param {boolean} active
 *
 * @returns {Promise}
 */
DoorPhoneTopic.prototype.openDoor = function (uuid, active) {

  var obj = {}
  obj[P.TOPIC] = P.DOOR_PHONE
  obj[P.DATA] = {}
  obj[P.DATA][P.ACTION] = P.OPEN_DOOR
  obj[P.DATA][P.UUID] = uuid
  obj[P.DATA][P.EVENT] = active ? P.PRESS : P.RELEASE

  return this._basCore.requestV2(obj)
    .then(Device.handleResponse)
}

/**
 * @returns {Promise<TSipInfo[]>}
 */
DoorPhoneTopic.prototype.getSipAddresses = function () {

  var obj = {}
  obj[P.TOPIC] = P.DOOR_PHONE
  obj[P.DATA] = {}
  obj[P.DATA][P.ACTION] = P.GET_SIP_ADDRESSES

  return this._basCore.requestV2(obj)
    .then(Device.handleResponse)
    .then(this._onDoorPhoneTopicSipAddresses.bind(this))
}

/**
 * @param doorPhoneUuid
 * @returns {Promise<TSipInfo[]>}
 */
DoorPhoneTopic.prototype.getExtraCameras = function (doorPhoneUuid) {

  var obj = {}
  obj[P.TOPIC] = P.DOOR_PHONE
  obj[P.DATA] = {}
  obj[P.DATA][P.ACTION] = P.GET_EXTRA_CAMERAS
  obj[P.DATA][P.DOOR_PHONE] = doorPhoneUuid

  return this._basCore.requestV2(obj)
    .then(Device.handleResponse)
    .then(this._onDoorPhoneTopicExtraCameras.bind(this))
}

/** Get call history
 *
 * @param {string[]} contactUuids
 * @param {int} offset
 * @param {int} limit
 * @returns {Promise<Array<TBasCallHistoryEntry>>}
 */
DoorPhoneTopic.prototype.getCallHistory = function (
  contactUuids,
  offset,
  limit
) {
  var data

  data = {}
  data[P.ACTION] = P.GET_CALL_HISTORY
  data[P.UUIDS] = contactUuids
  data[P.OFFSET] = offset
  data[P.LIMIT] = limit

  if (BasUtil.isNEArray(contactUuids)) {
    return this._request(data).then(this._parseCallHistory)
  }
}

/**
 * Parse call history
 *
 * @param {TBasDoorphoneServerResponse} response
 * @returns {Array<TBasCallHistoryEntry>}
 */
DoorPhoneTopic.prototype._parseCallHistory = function (response) {
  /**
   * @type {TBasCallHistoryEntry[]}
   */
  const parsedCallHistory = []

  if (
    BasUtil.isObject(response.data) &&
    BasUtil.isNEArray(response.data.callHistory)
  ) {

    for (const entry of response.data.callHistory) {
      if (
        BasUtil.isNEString(entry[P.TIMESTAMP]) &&
        BasUtil.isObject(entry[P.CONTACT]) &&
        BasUtil.isNEString(entry[P.CONTACT][P.NAME]) &&
        BasUtil.isNEString(entry[P.CONTACT][P.UUID])
      ) {
        parsedCallHistory.push(entry)
      }
    }
  }

  return parsedCallHistory
}

/** Get call details
 *
 * @param {string} contactUuid
 * @param {string} timestamp
 * @param {int} offset
 * @param {int} limit
 * @returns {Promise<?TBasGetCallDetailsServerResponseData>}
 */
DoorPhoneTopic.prototype.getCallDetails = function (
  contactUuid,
  timestamp,
  offset,
  limit
) {
  var data

  data = {}
  data[P.ACTION] = P.GET_CALL_DETAILS
  data[P.UUID] = contactUuid
  data[P.TIMESTAMP] = timestamp
  data[P.OFFSET] = offset
  data[P.LIMIT] = limit

  if (BasUtil.isNEString(contactUuid) && BasUtil.isNEString(timestamp)) {
    return this._request(data).then(this._parseCallDetails)
  }
}

/**
 * Parse call history
 *
 * @param {TBasDoorphoneServerResponse} response
 * @returns {?TBasGetCallDetailsServerResponseData}
 */
DoorPhoneTopic.prototype._parseCallDetails = function (response) {

  if (BasUtil.isObject(response.data)) {
    const data = response.data

    if (
      Array.isArray(data.images) &&
      BasUtil.isPNumber(data.limit) &&
      BasUtil.isVNumber(data.offset)
    ) {

      return response.data
    }
  }

  return null
}

/**
 * @private
 * @param message
 * @returns {TSipInfo[]}
 */
DoorPhoneTopic.prototype._onDoorPhoneTopicSipAddresses = function (message) {

  if (message[P.DATA] && Array.isArray(message[P.DATA][P.SIP_ADDRESSES])) {
    return message[P.DATA][P.SIP_ADDRESSES]
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * @typedef {Object} TExtraCamera
 * @property {string} [username]
 * @property {string} [password]
 * @property {string} [rtsp]
 * @property {string} [mjpeg]
 */

/**
 * @private
 * @param message
 * @returns {TExtraCamera[]}
 */
DoorPhoneTopic.prototype._onDoorPhoneTopicExtraCameras = function (message) {

  if (message[P.DATA] && Array.isArray(message[P.DATA][P.CAMERAS])) {
    return message[P.DATA][P.CAMERAS]
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * Send audio alert api message
 *
 * @param {Object} data
 * @protected
 * @returns {Promise}
 */
DoorPhoneTopic.prototype._request = function (data) {

  var msg

  msg = {}
  msg[P.TOPIC] = P.DOOR_PHONE
  msg[P.DATA] = data

  return this._basCore.requestV2(msg)
    .then(Device.handleResponse)
}

module.exports = DoorPhoneTopic
