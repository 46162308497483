'use strict'

import * as BasUtil from '@basalte/bas-util'

angular.module('basStopSwipePropagation', [])
  .directive('basStopSwipePropagation', basStopSwipePropagation)

function basStopSwipePropagation () {

  return {
    restrict: 'A',
    controller: [
      '$scope',
      '$element',
      '$attrs',
      '$parse',
      controller
    ]
  }

  /**
   * @param $scope
   * @param $element
   * @param $attrs
   * @param $parse
   */
  function controller (
    $scope,
    $element,
    $attrs,
    $parse
  ) {
    var basStopProp = this
    var controllerName = 'basStopSwipePropagation'

    var _passiveListenerOpts = {
      capture: false,
      passive: true
    }

    var _listeners = []

    basStopProp.$postLink = _onPostLink
    basStopProp.$onDestroy = _onDestroy

    function _onPostLink () {

      _setElementListeners()
    }

    /**
     * @private
     * @param event
     */
    function _onInput (event) {

      event.stopPropagation()
    }

    function _setElementListeners () {

      var _element, parsedFunction, parsedData, length, i, event

      if ($element && $element[0]) {

        _element = $element[0]
        parsedFunction = $parse($attrs[controllerName])

        if (BasUtil.isFunction(parsedFunction)) {

          parsedData = parsedFunction($scope)
        }

        if (BasUtil.isNEArray(parsedData)) {

          length = parsedData.length
          for (i = 0; i < length; i++) {

            event = parsedData[i]

            if (BasUtil.isNEString(event)) {

              _listeners.push(BasUtil.setDOMListener(
                _element,
                event,
                _onInput,
                _passiveListenerOpts
              ))
            }
          }
        }
      }
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
