'use strict'

angular
  .module('basalteApp')
  .directive('deviceEchoCancellation', deviceEchoCancellation)

function deviceEchoCancellation () {

  return {
    restrict: 'AE',
    template:
      '<div class="bbs bbs-item bbs-centered bbs-flex ' +
      'bbs-active-select"' +
      ' bas-click="echoCancellation.toggleEchoCancellation()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      ' data-translate="echo_cancellation"></div>' +
      '  <bas-toggle class="bbs-toggle bas-toggle-dark"' +
      ' data-bas-toggle="echoCancellation.toggleEchoCancellation()"' +
      ' data-bas-toggled="echoCancellation.echoCancellation"></bas-toggle>' +
      '</div>',
    controller: [
      'BasPreferences',
      controller
    ],
    controllerAs: 'echoCancellation'
  }

  /**
   * @param {BasPreferences} BasPreferences
   */
  function controller (BasPreferences) {

    var echoCancellation = this

    echoCancellation.echoCancellation =
      BasPreferences.getEchoCancellation()

    echoCancellation.toggleEchoCancellation = toggleEchoCancellation

    function toggleEchoCancellation () {

      echoCancellation.echoCancellation =
        !echoCancellation.echoCancellation

      BasPreferences.setEchoCancellation(
        echoCancellation.echoCancellation
      )
    }
  }
}
