'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('musicCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_SOURCE',
    'CurrentBasCore',
    'Sources',
    'CurrentRoom',
    'BasStateHelper',
    'RoomsHelper',
    musicCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {CurrentBasCore} CurrentBasCore
 * @param {Sources} Sources
 * @param {CurrentRoom} CurrentRoom
 * @param {BasStateHelper} BasStateHelper
 * @param {RoomsHelper} RoomsHelper
 */
function musicCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_SOURCE,
  CurrentBasCore,
  Sources,
  CurrentRoom,
  BasStateHelper,
  RoomsHelper
) {
  var music = this

  var _listeners = []

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  music.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BasRooms}
   */
  music.rooms = BAS_ROOMS.ROOMS

  music.BAS_ROOM = BAS_ROOM

  init()

  function init () {

    Sources.registerFor(BAS_SOURCE.COL_EVT_SIMPLE)

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SOURCE_CHANGED,
      _onSourceChanged
    ))
    _listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_LISTENING_ROOMS_CHANGE,
      _onListeningRoomsChanged
    ))
  }

  /**
   * @private
   * @param {Object} _event
   * @param {string} arg Room UUID
   */
  function _onSourceChanged (_event, arg) {

    // TODO Limit dependency on CurrentRoom room param

    if (arg === $uiRouterGlobals.params.room) {

      _handleCurrentSourceChanged()
    }
  }

  /**
   * @private
   * @param {Object} _event
   * @param {string} arg Room UUID
   */
  function _onListeningRoomsChanged (_event, arg) {

    if (arg === $uiRouterGlobals.params.room) {

      _handleListeningRoomsChanged()
    }
  }

  function _handleCurrentSourceChanged () {

    var currentStateName, basSource, hasSingleSource

    if ($uiRouterGlobals.transition) return

    currentStateName = $uiRouterGlobals.current.name
    basSource = CurrentRoom.getBasSource()

    hasSingleSource = !!(
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.hasSingleSource()
    )

    if (
      BasStateHelper.hasBaseState(currentStateName, STATES.MUSIC_SOURCES)
    ) {

      if (hasSingleSource) {

        CurrentRoom.go(STATES.MUSIC_PLAYER)
      }

    } else if (
      BasStateHelper.hasBaseState(currentStateName, STATES.MUSIC_FAVOURITES)
    ) {

      if (!basSource || !basSource.favourites) {

        CurrentRoom.go(
          hasSingleSource ? STATES.MUSIC_PLAYER : STATES.MUSIC_SOURCES
        )
      }

    } else if (
      BasStateHelper.hasBaseState(currentStateName, STATES.MUSIC_LIBRARY)
    ) {

      if (!basSource || !basSource.supportsLibrary) {

        CurrentRoom.go(
          hasSingleSource ? STATES.MUSIC_PLAYER : STATES.MUSIC_SOURCES
        )
      }
    }
  }

  function _handleListeningRoomsChanged () {

    var room

    room = RoomsHelper.getRoomForId($uiRouterGlobals.params.room)

    if (room && room.isInactiveSourceGroup()) {

      CurrentRoom.go(STATES.PLAYER)
    }
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []

    Sources.unregisterFor(BAS_SOURCE.COL_EVT_SIMPLE)
  }
}
