'use strict'

/**
 * Simple in-memory LocalStorage
 * This does not implement the key method.
 *
 * @constructor
 */
function BasMemoryStorage () {

  this._data = {}
  this._length = 0
}

Object.defineProperty(BasMemoryStorage.prototype, 'length', {
  get: function () {
    return this._length
  }
})

BasMemoryStorage.prototype.setItem = function (key, value) {

  this._data[key] = String(value)
  this._length++
}

BasMemoryStorage.prototype.getItem = function (key) {

  var result

  result = this._data[key]

  return typeof result === 'string' ? result : null
}

BasMemoryStorage.prototype.removeItem = function (key) {

  var original

  original = this._data[key]

  this._data[key] = null

  if (typeof original === 'string') this._length--
}

BasMemoryStorage.prototype.clear = function () {

  this._data = {}
  this._length = 0
}

BasMemoryStorage.prototype.keys = function () {

  return Object.keys(this._data)
}

export default BasMemoryStorage
