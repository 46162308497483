'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceEllieSip', deviceEllieSip)

function deviceEllieSip () {

  return {
    restrict: 'A',
    template: '<div class="bbs-ellie">' +
      '<div class="bbsi bbsi-no-top-margin bbsi-small-font bbsi-flex">' +
      '<div class="bbsi-name ellipsis-overflow" ' +
      'data-translate="server"></div>' +
      '<div class="bbsi-value" ' +
      'ng-bind="sipConfig.state.uiSipStateDomain"></div>' +
      '</div>' +
      '<div ng-repeat="name in ' +
      'sipConfig.state.uiSipStateUsernames track by $index">' +
      '<div class="bbsi bbsi-small-font bbsi-flex">' +
      '<div class="bbsi-name ellipsis-overflow" ' +
      'data-translate="username"></div>' +
      '<div class="bbsi-value" ng-bind="name"></div>' +
      '</div>' +
      '</div>' +
      '<div ng-show="sipConfig.state.uiShowSipState" ' +
      'class="bbsi bbsi-small-font bbsi-flex">' +
      '<div class="bbsi-name ellipsis-overflow" ' +
      'data-translate="connected"></div>' +
      '<div class="bbsi-value" ' +
      'ng-bind="sipConfig.showExtra ? ' +
      'sipConfig.state.uiSipStateRegistrationDetailed : ' +
      'sipConfig.state.uiSipStateRegistrationSimple" ' +
      'bas-click="sipConfig.displayExtraInfo()"></div>' +
      '</div>',
    controller: [
      '$rootScope',
      '$scope',
      'BAS_SIP',
      controller
    ],
    controllerAs: 'sipConfig'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_SIP} BAS_SIP
   */
  function controller (
    $rootScope,
    $scope,
    BAS_SIP
  ) {
    var sipConfig = this
    var _listeners = []

    /**
     * @type {TBasSipState}
     */
    sipConfig.state = BAS_SIP.STATE

    sipConfig.showExtra = false

    sipConfig.displayExtraInfo = displayExtraInfo

    init()

    function init () {

      $scope.$on('$destroy', destroy)

      _listeners.push($rootScope.$on(
        BAS_SIP.EVT_SIP_CONFIG_CHANGED,
        update
      ))
    }

    function displayExtraInfo () {

      sipConfig.showExtra = !sipConfig.showExtra
    }

    function update () {

      $scope.$applyAsync()
    }

    function destroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
