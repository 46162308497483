'use strict'

angular
  .module('basalteApp')
  .controller('lisaLightDetailsCtrl', [
    '$uiRouterGlobals',
    'STATES',
    'BAS_STATE',
    'BAS_LIGHT',
    'BasState',
    'CurrentRoom',
    lisaLightDetailsCtrl
  ])

/**
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_LIGHT} BAS_LIGHT
 * @param {BasState} BasState
 * @param {CurrentRoom} CurrentRoom
 */
function lisaLightDetailsCtrl (
  $uiRouterGlobals,
  STATES,
  BAS_STATE,
  BAS_LIGHT,
  BasState,
  CurrentRoom
) {
  var lisa = this
  var CSS_SHOW_SETTINGS = 'lisa-light-show-settings'
  var CSS_SHOW_HEIGHT_BALANCE = 'lisa-light-show-height-balance'

  lisa.BAS_LIGHT = BAS_LIGHT

  lisa.setBrightnessOrWhite = setBrightnessOrWhite
  lisa.setColorTemperature = setColorTemperature
  lisa.setWhite = setWhite
  lisa.toggleSettings = toggleSettings
  lisa.toggleBalanceAndHeight = () => {
    const view = BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_BALANCE_HEIGHT
    BasState.go(
      STATES.LISA_LIGHT_DETAIL,
      {
        view: view
      }
    )

    syncView(view)
  }
  lisa.$uiRouterGlobals = $uiRouterGlobals

  lisa.css = {}
  lisa.css[CSS_SHOW_SETTINGS] =
    $uiRouterGlobals.params.view ===
    BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS
  lisa.css[CSS_SHOW_HEIGHT_BALANCE] =
    $uiRouterGlobals.params.view ===
    BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_BALANCE_HEIGHT

  this.uiOnParamsChanged = uiOnParamsChanged

  init()

  function init () {

    _getLight()
  }

  function setBrightnessOrWhite (value) {

    if (lisa.light) {

      lisa.light.updateBrightness(value)

      if (
        lisa.light._supportsWhiteViaBrightness &&
        lisa.light.mode === BAS_LIGHT.MODE_WHITE
      ) {

        lisa.light.white = lisa.light.brightnessSlider
      }

      lisa.light.changeWhiteOrBrightness()
    }
  }

  function setColorTemperature (value) {
    if (lisa.light) {

      lisa.light.relTemperature = value
      lisa.light.colorTemperatureChange()
    }
  }

  function setWhite (value) {
    if (lisa.light) {

      lisa.light.white = value
      lisa.light.whiteChange()
    }
  }

  function toggleSettings () {

    const view = $uiRouterGlobals.params.view ===
    ''
      ? (
          !lisa.light.canChangeMode &&
        !lisa.light.canChangeColor &&
        !lisa.light.canChangeColorTemp
        )
          ? BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_BALANCE_HEIGHT
          : BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS
      : ''

    BasState.go(
      STATES.LISA_LIGHT_DETAIL,
      {
        view: view
      }
    )

    syncView(view)
  }

  function uiOnParamsChanged (params) {

    syncView(params.view)
  }

  function syncView (view) {
    lisa.css[CSS_SHOW_SETTINGS] =
      view === BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_SETTINGS
    lisa.css[CSS_SHOW_HEIGHT_BALANCE] =
      view === BAS_STATE.S_LISA_LIGHT_DETAILS_VIEW_BALANCE_HEIGHT
  }

  function _getLight () {

    var room

    room = CurrentRoom.getRoom()

    lisa.light = (room && room.lights && room.lights.getLight)
      ? room.lights.getLight($uiRouterGlobals.params.light)
      : null
  }
}
