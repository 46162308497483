'use strict'

angular
  .module('basalteApp')
  .controller('lisaScenesCtrl', [
    'BAS_ROOMS',
    'CurrentRoom',
    lisaScenesCtrl
  ])

/**
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 */
function lisaScenesCtrl (
  BAS_ROOMS,
  CurrentRoom
) {
  var lisa = this

  /**
   * @type {BasRooms}
   */
  lisa.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  lisa.currentRoom = CurrentRoom.get()
}
