'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('DeezerHelper', [
    'BAS_API',
    'BAS_LIBRARY_ERRORS',
    'BAS_SOURCE',
    'DeezerElement',
    'LibraryState',
    'Logger',
    DeezerHelper
  ])

/**
 * Helper Service for getting Deezer Objects
 *
 * @constructor
 * @param BAS_API
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param DeezerElement
 * @param {LibraryState} LibraryState
 * @param Logger
 */
function DeezerHelper (
  BAS_API,
  BAS_LIBRARY_ERRORS,
  BAS_SOURCE,
  DeezerElement,
  LibraryState,
  Logger
) {
  var serviceName = 'Deezer Helper'

  var PREFIX_RADIO = 'radio:'
  var PREFIX_ARTIST = 'artist:'
  var PREFIX_ALBUM = 'album:'
  var PREFIX_PLAYLIST = 'playlist:'
  var PREFIX_TRACK = 'track:'

  /**
   * @param {DeezerElement} element
   * @param {string} [option]
   */
  this.play = function (element, option) {

    var basSource, uri

    basSource = LibraryState.getCurrentSource()

    if (
      basSource &&
      basSource.source
    ) {

      if (basSource.isAudioSource) {

        uri = this.getDeezerUri(element.id, element.type)

        if (basSource.canAddToQueue) {

          if (element.type === DeezerElement.TYPE_TRACK) {

            basSource.source.queueAddItems(
              [uri],
              option,
              true
            )

          } else {

            basSource.source.queueAddUri(
              uri,
              option,
              true
            )
          }

        } else {

          basSource.playUri(uri)
        }

      } else if (basSource.source.queue) {

        switch (element.type) {
          case DeezerElement.TYPE_ALBUM:

            basSource.source.queue.addDeezerAlbum(
              element.id,
              option
            )

            break
          case DeezerElement.TYPE_PLAYLIST:

            basSource.source.queue.addDeezerPlaylist(
              element.id,
              option
            )

            break
          case DeezerElement.TYPE_RADIO:

            basSource.source.queue.startDeezerRadio(
              element.id
            )

            break
          case DeezerElement.TYPE_TRACK:

            basSource.source.queue.addDeezerTrack(
              element.id,
              option
            )

            break
          default:
            Logger.warn(serviceName +
                          ' - Play' +
                          ' - Unsupported type')
        }
      }
    }
  }

  /**
   * @param {number[]} items
   * @param {string} option
   */
  this.playSelection = function (items, option) {

    var basSource, uris, i, length

    basSource = LibraryState.getCurrentSource()

    if (
      basSource &&
      basSource.source
    ) {

      if (basSource.isAudioSource) {

        uris = []

        length = items.length
        for (i = 0; i < length; i++) {

          uris.push(this.getDeezerUri(items[i], DeezerElement.TYPE_TRACK))
        }

        basSource.source.queueAddItems(uris)

      } else if (basSource.source.queue) {

        basSource.source.queue.addDeezerTrackList(items, option)
      }
    }
  }

  /**
   * @param {string} type
   * @param {number} id
   * @returns Promise
   */
  this.checkFavourite = function (type, id) {

    var basSource = LibraryState.getDeezerSource()

    if (basSource) {

      return basSource.deezer.checkFavouriteStatus(id, type)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_DEEZER)
  }

  /**
   * @param {number} id
   * @param {string} type
   * @param {boolean} isFavourite
   * @returns Promise
   */
  this.addRemoveFavouriteId = function (id, type, isFavourite) {

    var basSource = LibraryState.getDeezerSource()

    if (basSource) {

      return basSource.deezer
        .addRemoveDeezerFavouriteId(id, type, isFavourite)
        .then(onFavouriteId)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_DEEZER)

    function onFavouriteId (result) {

      if (result === true) {

        return Promise.resolve(!isFavourite)

      } else {

        return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
      }
    }
  }

  /**
   * @param {DeezerElement} element
   * @param {DeezerCollection} collection
   * @param {Object} can
   */
  this.checkContextCapabilities = function (element, collection, can) {

    var canArtist, isArtistDetail, canAlbum, isAlbumDetail, hasDetail
    var basSource, onlyReplace, hasQueueCapabilities, canPlayUri, isAudioSource

    onlyReplace = false
    hasQueueCapabilities = false
    canPlayUri = false
    isAudioSource = false

    // Check element
    if (BasUtil.isObject(element) &&
      BasUtil.isNEString(element.type)) {

      basSource = LibraryState.getCurrentSource()

      if (basSource) {

        if (
          basSource.source &&
          basSource.source.queue) {

          onlyReplace = (
            basSource.source.queue.type ===
            BAS_API.Queue.TYPE_STREAM ||
            basSource.source.queue.type ===
            BAS_API.Queue.TYPE_DEEZER_FLOW ||
            basSource.source.queue.type ===
            BAS_API.Queue.TYPE_DEEZER_RADIO
          )
        }

        hasQueueCapabilities = basSource.isAudioSource
          ? basSource.canAddToQueue
          : true

        canPlayUri = basSource.isAudioSource
          ? basSource.source.allowsExecute(
            BAS_API.AudioSource.C_PLAY_URI
          )
          : true

        // eslint-disable-next-line
        isAudioSource = basSource.isAudioSource
      }

      // Set modal properties based on type
      switch (element.type) {
        case DeezerElement.TYPE_ALBUM:

          canArtist = (
            BasUtil.isObject(element.deezer) &&
            BasUtil.isObject(element.deezer.artist)
          )
          isArtistDetail = (
            BasUtil.isObject(collection) &&
            BasUtil.isObject(collection.detailElement) &&
            collection.detailElement.type ===
            DeezerElement.TYPE_ARTIST
          )

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.deezer = true
          can.addPlaylist = true
          can.goToArtist = !isArtistDetail && canArtist

          break
        case DeezerElement.TYPE_PLAYLIST:

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.deezer = true
          can.addPlaylist = true

          break
        case DeezerElement.TYPE_ARTIST:

          // TODO: Re-enable this when core adds support for this
          //  https://basalte.atlassian.net/browse/CORE-1149
          //  was previously 'isAudioSource'
          can.playNow = false

          break
        case DeezerElement.TYPE_RADIO:

          can.playNow = canPlayUri

          break
        case DeezerElement.TYPE_TRACK:

          hasDetail = (
            BasUtil.isObject(collection) &&
            BasUtil.isObject(collection.detailElement)
          )
          canArtist = (
            BasUtil.isObject(element.deezer) &&
            BasUtil.isObject(element.deezer.artist)
          )
          isArtistDetail = (
            hasDetail &&
            collection.detailElement.type ===
            DeezerElement.TYPE_ARTIST
          )
          canAlbum = (
            BasUtil.isObject(element.deezer) &&
            BasUtil.isObject(element.deezer.album)
          )
          isAlbumDetail = (
            hasDetail &&
            collection.detailElement.type ===
            DeezerElement.TYPE_ALBUM
          )

          can.playNow = canPlayUri || hasQueueCapabilities
          can.playNext = !onlyReplace && hasQueueCapabilities
          can.addQueue = !onlyReplace && hasQueueCapabilities
          can.replaceQueue = !onlyReplace && hasQueueCapabilities

          can.deezer = true
          can.addPlaylist = true
          can.goToAlbum = canAlbum && !isAlbumDetail
          can.goToArtist = canArtist && !isArtistDetail

          break
        default:
          Logger.warn(serviceName + ' - Unsupported type', element.type)
      }

    } else {
      Logger.warn(serviceName + ' - Unknown element', element)
    }
  }

  /**
   * @param {DeezerElement} element
   * @returns {Promise}
   */
  this.getElementData = function (element) {

    var basSource, deezer, func

    if (BasUtil.isObject(element) &&
      BasUtil.isNEString(element.type)) {

      switch (element.type) {

        case DeezerElement.TYPE_TRACK:
          return Promise.resolve([element.id])

        case DeezerElement.TYPE_ALBUM:

          basSource = LibraryState.getDeezerSource()

          if (basSource && basSource.deezer.isLinked()) {

            deezer = basSource.deezer
            func = deezer.getAlbumSongsLib.bind(deezer, element.id)

            return basSource.deezer.getXElements(func, 100, 0, -1)
              .then(onData)
          }

          return Promise.reject(BAS_LIBRARY_ERRORS.NO_DEEZER)

        case DeezerElement.TYPE_PLAYLIST:

          basSource = LibraryState.getDeezerSource()

          if (basSource && basSource.deezer.isLinked()) {

            deezer = basSource.deezer
            func = deezer.getPlaylistSongsLib
              .bind(deezer, element.id)
            return basSource.deezer.getXElements(func, 100, 0, -1)
              .then(onData)
          }

          return Promise.reject(BAS_LIBRARY_ERRORS.NO_DEEZER)
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ELEMENT)

    function onData (result) {

      var i, length
      var array = []

      if (BasUtil.isObject(result) &&
        Array.isArray(result.data)) {

        length = result.data.length

        for (i = 0; i < length; i++) {

          if (BasUtil.isPNumber(result.data[i].id)) {

            array.push(result.data[i].id)
          }
        }
      }

      return array
    }
  }

  /**
   * @param {number} id
   * @param {DeezerElement} element
   * @returns {Promise}
   */
  this.addElementToPlaylist = function (id, element) {

    var _this = this

    return BasUtil.isPNumber(id)
      ? this.getElementData(element).then(onData)
      : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ID)

    function onData (result) {

      return Array.isArray(result)
        ? _this.addToPlaylist(id, result)
        : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
    }
  }

  /**
   * @param {string} playlistName
   * @param {DeezerElement} element
   * @returns {Promise}
   */
  this.addElementToNewPlaylist = function (playlistName, element) {

    var _this = this

    return (BasUtil.isNEString(playlistName))
      ? this.getElementData(element).then(onData)
      : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_NAME)

    function onData (result) {

      return Array.isArray(result)
        ? _this.addToNewPlaylist(playlistName, result)
        : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_RESULT)
    }
  }

  /**
   * @param {DeezerElement} element
   * @returns {boolean}
   */
  this.isPlaylist = function (element) {

    var basSource = LibraryState.getDeezerSource()

    return (
      element &&
      element.type === DeezerElement.TYPE_PLAYLIST &&
      basSource &&
      basSource.deezer.getId() === element.creatorId
    )
  }

  /**
   * @param {number} id
   * @param {Array} items
   * @returns {Promise}
   */
  this.addToPlaylist = function (id, items) {

    var basSource = LibraryState.getDeezerSource()

    return (
      basSource &&
      basSource.deezer &&
      basSource.deezer.isLinked &&
      basSource.deezer.isLinked()
    )
      ? basSource.deezer.addSongsToPlaylist(id, items)
      : Promise.reject(BAS_LIBRARY_ERRORS.NO_DEEZER)
  }

  /**
   * @param {string} name
   * @param {number[]} items
   * @returns {Promise}
   */
  this.addToNewPlaylist = function (name, items) {

    var _this, basSource

    _this = this

    basSource = LibraryState.getDeezerSource()

    return (
      basSource &&
      basSource.deezer &&
      basSource.deezer.isLinked &&
      basSource.deezer.isLinked()
    )
      ? basSource.deezer.addPlaylist(name).then(onSuccess)
      : Promise.reject(BAS_LIBRARY_ERRORS.NO_DEEZER)

    function onSuccess (result) {

      return (result && BasUtil.isPNumber(result.id))
        ? _this.addToPlaylist(result.id, items)
        : Promise.reject(BAS_LIBRARY_ERRORS.INVALID_ID)
    }
  }

  /**
   * @returns {Promise<Object[]>}
   */
  this.getPlaylists = function () {

    var basSource = LibraryState.getDeezerSource()
    var func

    // Check if player and Deezer is valid
    if (BasUtil.isObject(basSource) &&
      basSource.deezer.isLinked()) {

      func = basSource.deezer.getUserPlaylists
        .bind(basSource.deezer)

      return basSource.deezer.getXElements(func, 100, 0, -1)
        .then(processDeezerPlaylists)
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.NO_DEEZER)

    function processDeezerPlaylists (result) {

      var i, length, obj, id, array, _basSource

      array = []
      _basSource = LibraryState.getDeezerSource()

      if (_basSource &&
        BasUtil.isObject(result) &&
        Array.isArray(result.data)) {

        id = _basSource.deezer.getId()

        length = result.data.length
        for (i = 0; i < length; i++) {
          obj = result.data[i]

          if (BasUtil.isObject(obj) &&
            BasUtil.isObject(obj.creator) &&
            id === obj.creator.id &&
            BasUtil.isPNumber(obj.id) &&
            BasUtil.isNEString(obj.title)) {

            array.push({
              id: obj.id,
              title: obj.title
            })
          }
        }
      }

      return array
    }
  }

  this._getDeezerUriType = function (type) {

    switch (type) {
      case DeezerElement.TYPE_RADIO:
        return PREFIX_RADIO
      case DeezerElement.TYPE_TRACK:
        return PREFIX_TRACK
      case DeezerElement.TYPE_ALBUM:
        return PREFIX_ALBUM
      case DeezerElement.TYPE_ARTIST:
        return PREFIX_ARTIST
      case DeezerElement.TYPE_PLAYLIST:
        return PREFIX_PLAYLIST
      default:
        return ''
    }
  }

  /**
   * @param {(number|string)} id
   * @param {string} type
   * @returns {string}
   */
  this.getDeezerUri = function (id, type) {

    return BAS_SOURCE.URI_PREFIX_DEEZER + this._getDeezerUriType(type) + id
  }
}
