'use strict'

var RequestJs = require('@gidw/request-js')

var CONSTANTS = require('./constants')

module.exports.request = request
module.exports.parseHeaders = RequestJs.parseHeaders
module.exports.parseErrorStatus = parseErrorStatus

/**
 * Helper function for requests.
 *
 * @since 2.1.0
 * @param {RequestJs.RequestJsConfig} config
 * @returns {Promise<RequestJs.RequestJsResultType>}
 */
function request (config) {

  return _request(config)
}

/**
 * @param {number} status
 * @returns {string}
 */
function parseErrorStatus (status) {

  switch (status) {
    case 0:
      return CONSTANTS.ERR_NETWORK
    case 400:
      return CONSTANTS.ERR_BAD_REQUEST
    case 401:
      return CONSTANTS.ERR_UNAUTHORIZED
    case 403:
      return CONSTANTS.ERR_FORBIDDEN
    case 404:
      return CONSTANTS.ERR_NOT_FOUND
  }

  return ''
}

/**
 * Wrap a request in a Promise.
 *
 * In case of errors, reject with known Error objects.
 *
 * @private
 * @param {RequestJs.RequestJsConfig} options
 * @returns {Promise<RequestJs.RequestJsResultType>}
 */
function _request (options) {

  return new Promise(promiseConstructor)

  function promiseConstructor (resolve, reject) {

    RequestJs(options, callback)

    function callback (error, result) {

      var _msg

      if (error) {

        _msg = parseErrorStatus(error.status)

        reject(_msg || error)

      } else {

        resolve(result)
      }
    }
  }
}
