'use strict'

import BasNativeStorage from './basnativestorage'
import BasMemoryStorage from './basmemorystorage'
import basStorageUtil from './util.basstorage'

module.exports.initBasStorageCordova = initBasStorageCordova

/**
 * @typedef {Object} TBasStorageInit
 * @property {boolean} finished
 * @property {boolean} isApp
 * @property {boolean} html5LocalStorageSupported
 * @property {(BasNativeStorage|BasMemoryStorage|Storage)} basStorage
 */

/**
 * @callback CBasStorageInitCallback
 * @param {*} error
 * @param {TBasStorageInit} result
 */

/**
 * @param {Window} window
 * @param {CBasStorageInitCallback} callback
 * @returns {TBasStorageInit}
 */
function initBasStorageCordova (window, callback) {

  var _result, _nativeStorage

  _result = {
    finished: false,
    isApp: false,
    html5LocalStorageSupported: !!window.localStorage,
    basStorage: null,
    storageHandle: null
  }

  _nativeStorage = basStorageUtil.getNativeStoragePlugin(window)

  if (window) {

    if (window.cordova) {

      if (window.cordova.platformId === 'android' ||
        window.cordova.platformId === 'ios') {

        _result.isApp = true
      }
    }
  }

  if (_result.isApp && _nativeStorage) {

    _result.basStorage = new BasNativeStorage(_nativeStorage)

    // Wrapped in a microTask to make sure that the callback is executed
    // in a separate function execution context
    // (after the caller's function execution)
    // In some cases (input errors),
    // the callback would be executed immediately
    // in the same function execution context
    Promise.resolve().then(_onNativeStorageTimeout)

  } else if (_result.html5LocalStorageSupported) {

    _result.basStorage = window.localStorage

    // Wrapped in a microTask to make sure that the callback is executed
    // in a separate function execution context
    // (after the caller's function execution)
    Promise.resolve().then(_cb.bind(null, null))

  } else {

    _result.basStorage = new BasMemoryStorage()

    // Wrapped in a microTask to make sure that the callback is executed
    // in a separate function execution context
    // (after the caller's function execution)
    Promise.resolve().then(_cb.bind(null, null))
  }

  return (window.basStorageInitResult = _result)

  function _onNativeStorageTimeout () {

    _result.basStorage.init(_onNativeStorageInit)
  }

  function _onNativeStorageInit (error) {

    if (!error) _migrate()

    _cb(error)
  }

  /**
   * Migrate all known localStorage to NativeStorage
   *
   * @private
   */
  function _migrate () {

    var length, i, key

    if (_result.html5LocalStorageSupported) {

      length = window.localStorage.length
      for (i = 0; i < length; i++) {

        key = window.localStorage.key(i)

        if (typeof key === 'string') {

          // Known key schemes
          if (
            key.indexOf('BASALTE') === 0 ||
            key.indexOf('CognitoIdentityServiceProvider') === 0
          ) {
            _result.basStorage.setItem(
              key,
              window.localStorage.getItem(key)
            )
            window.localStorage.removeItem(key)
          }
        }
      }
    }
  }

  function _cb (error) {

    _result.finished = true

    if (typeof callback === 'function') callback(error, _result)
  }
}
