'use strict'

var BasUtil = require('@basalte/bas-util')

/**
 * Regex for basalte host parsing
 *
 * $1 hostname
 * $2 :PORT_1
 * $3 PORT_1
 * $4 :PORT_2
 * $5 PORT_2
 *
 * @type {RegExp}
 */
var regex = /([a-zA-Z0-9._-]+)(:([0-9]+))?(:([0-9]+))?/

var HTTP_PORT = 80
var HTTPS_PORT = 443

/**
 * @constructor
 * @param {string} host
 * @param {boolean} [secure = false]
 */
function BasHost (host, secure) {

  /**
   * Unprocessed host string
   *
   * @instance
   * @type {string}
   */
  this.host = BasUtil.isNEString(host) ? host : ''

  /**
   * @instance
   * @type {boolean}
   */
  this.secure = secure === true

  /**
   * @instance
   * @type {string}
   */
  this.hostname = ''

  /**
   * @instance
   * @type {number}
   */
  this.httpPort = BasHost.getHttpPort(secure)

  /**
   * @instance
   * @type {number}
   */
  this.wsPort = BasHost.getWsPort(secure)

  /**
   * @instance
   * @type {string}
   */
  this.httpURI = ''

  /**
   * @instance
   * @type {string}
   */
  this.wsURI = ''

  this._parse()
}

/**
 * @constant {string}
 */
BasHost.HTTP_PROTOCOL = 'http:'

/**
 * @constant {string}
 */
BasHost.HTTPS_PROTOCOL = 'https:'

/**
 * @constant {string}
 */
BasHost.WS_PROTOCOL = 'ws:'

/**
 * @constant {string}
 */
BasHost.WSS_PROTOCOL = 'wss:'

/**
 * Default port for HTTP (Basalte server)
 *
 * @constant {number}
 */
BasHost.HTTP_PORT = 80

/**
 * Default port for HTTPS (Basalte server)
 *
 * @constant {number}
 */
BasHost.HTTPS_PORT = 443

/**
 * Default port for WebSocket (Basalte server)
 *
 * @constant {number}
 */
BasHost.WS_PORT = 9000

/**
 * Default port for secure WebSocket (Basalte server)
 *
 * @constant {number}
 */
BasHost.WSS_PORT = 9001

/**
 * @param {boolean} [secure]
 * @returns {number}
 */
BasHost.getHttpPort = function (secure) {
  return secure ? BasHost.HTTPS_PORT : BasHost.HTTP_PORT
}

/**
 * @param {boolean} [secure]
 * @returns {number}
 */
BasHost.getWsPort = function (secure) {
  return secure ? BasHost.WSS_PORT : BasHost.WS_PORT
}

/**
 * @param {boolean} [secure]
 * @returns {string}
 */
BasHost.getHttpProtocol = function (secure) {
  return secure ? BasHost.HTTPS_PROTOCOL : BasHost.HTTP_PROTOCOL
}

/**
 * @param {boolean} [secure]
 * @returns {string}
 */
BasHost.getWsProtocol = function (secure) {
  return secure ? BasHost.WSS_PROTOCOL : BasHost.WS_PROTOCOL
}

/**
 * Parse the host
 *
 * @private
 */
BasHost.prototype._parse = function () {

  var match, parsedInt

  if (BasUtil.isNEString(this.host)) {

    match = regex.exec(this.host)

    this.hostname = match[1] ? match[1] : ''

    // HTTP port

    if (match[3]) {

      parsedInt = parseInt(match[3], 10)

      this.httpPort = isNaN(parsedInt)
        ? BasHost.getHttpPort(this.secure)
        : parsedInt

    } else {

      this.httpPort = BasHost.getHttpPort(this.secure)
    }

    // WebSocket port

    if (match[5]) {

      parsedInt = parseInt(match[5], 10)

      this.wsPort = isNaN(parsedInt)
        ? BasHost.getWsPort(this.secure)
        : parsedInt

    } else {

      this.wsPort = BasHost.getWsPort(this.secure)
    }

    // HTTP URI

    this.httpURI = BasHost.getHttpProtocol(this.secure) + '//' +
      this.hostname

    // Check for non-standard port
    if ((this.secure && this.httpPort !== HTTPS_PORT) ||
      (!this.secure && this.httpPort !== HTTP_PORT)) {

      this.httpURI += ':' + this.httpPort
    }

    // WebSocket URI

    this.wsURI = BasHost.getWsProtocol(this.secure) + '//' +
      this.hostname + ':' + this.wsPort
  }
}

/**
 * Get the full url for a room art file on the basCore
 *
 * @param {string} path relative resource path
 * @returns {string} The full url for the file
 * @since 2.1.0
 */
BasHost.prototype.getHTTPUrl = function (path) {

  if (BasUtil.isNEString(path)) {

    if (path.charAt(0) === '/') {

      return this.httpURI + path

    } else {

      return this.httpURI + '/' + path
    }
  }

  return this.httpURI
}

/**
 * @returns {BasHost}
 */
BasHost.prototype.clone = function () {

  return new BasHost(this.host, this.secure)
}

module.exports = BasHost
