{
  "61": {
    "playlists": [
      {
        "name": "GeorgeTopHits",
        "playlist": "GeorgeTopHits"
      },
      {
        "name": "GeorgeWeekend",
        "playlist": "GeorgeWeekend"
      },
      {
        "name": "ElroyParty",
        "playlist": "ElroyParty"
      }
    ],
    "radios": [
      {
        "gid": "s25710",
        "name": "Studio Brussel"
      },
      {
        "gid": "s25709",
        "name": "Radio 2 West Vlaanderen"
      }
    ]
  },
  "62": {
    "playlists": [
      {
        "name": "JaneRelax",
        "playlist": "JaneRelax"
      },
      {
        "name": "GeorgeWorkout",
        "playlist": "GeorgeWorkout"
      },
      {
        "name": "ElroyParty",
        "playlist": "ElroyParty"
      },
      {
        "name": "Lisa",
        "playlist": "Lisa"
      }
    ],
    "radios": [
      {
        "gid": "s25709",
        "name": "Radio 2 West Vlaanderen"
      }
    ]
  },
  "63": {
    "playlists": [
      {
        "name": "JudyFitness",
        "playlist": "JudyFitness"
      },
      {
        "name": "JaneRelax",
        "playlist": "JaneRelax"
      }
    ],
    "radios": [
      {
        "gid": "s97407",
        "name": "Ketnet Hits"
      }
    ]
  },
  "64": {
    "playlists": [
      {
        "name": "iTunesTop25",
        "playlist": "iTunesTop25"
      },
      {
        "name": "ElroyParty",
        "playlist": "ElroyParty"
      }
    ],
    "radios": [
      {
        "gid": "s69137",
        "name": "MNM Hits"
      }
    ]
  },
  "sourceHomeCinemaSetTopBox": {
    "video": [
      {
        "name": "Discovery Channel",
        "uri": "tv:channel:1",
        "removable": false
      },
      {
        "name": "BBC",
        "uri": "tv:channel:2",
        "removable": false
      },
      {
        "name": "Fox news",
        "uri": "tv:channel:3",
        "removable": false
      },
      {
        "name": "CBS",
        "uri": "tv:channel:4",
        "removable": false
      },
      {
        "name": "National Geographic",
        "uri": "tv:channel:5",
        "removable": false
      },
      {
        "name": "Animal Planet",
        "uri": "tv:channel:6",
        "removable": false
      },
      {
        "name": "AMC",
        "uri": "tv:channel:7",
        "removable": false
      },
      {
        "name": "Disney Channel",
        "uri": "tv:channel:8",
        "removable": false
      },
      {
        "name": "Cartoon Network",
        "uri": "tv:channel:9",
        "removable": false
      }
    ]
  }
}
