'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSpotifyUri', basSpotifyUriFactory)

function basSpotifyUriFactory () {

  /**
   * @constructor
   * @param {string} [uri]
   */
  function BasSpotifyUri (uri) {

    /**
     * @instance
     * @type {string}
     */
    this.track = ''

    /**
     * @instance
     * @type {string}
     */
    this.artist = ''

    /**
     * @instance
     * @type {string}
     */
    this.album = ''

    /**
     * @instance
     * @type {string}
     */
    this.genre = ''

    /**
     * @instance
     * @type {string}
     */
    this.playlist = ''

    /**
     * @instance
     * @type {string}
     */
    this.episode = ''

    /**
     * @instance
     * @type {string}
     */
    this.show = ''

    /**
     * @instance
     * @type {string}
     */
    this.search = ''

    /**
     * @instance
     * @type {string}
     */
    this.user = ''

    /**
     * @instance
     * @type {boolean}
     */
    this.station = false

    /**
     * @instance
     * @type {boolean}
     */
    this.internal = false

    /**
     * @instance
     * @type {boolean}
     */
    this.localFiles = false

    /**
     * @instance
     * @type {boolean}
     */
    this.collection = false

    /**
     * @instance
     * @type {boolean}
     */
    this.cluster = false

    /**
     * @instance
     * @type {string}
     */
    this.uri = ''

    this._parse(uri)
  }

  /**
   * Parse a Spotify Preset URI
   *
   * Known URI structures
   *
   * spotify:artist:<uri_key>
   * spotify:album:<uri_key>
   * spotify:track:<uri_key>
   * spotify:show:<uri_key>
   * spotify:episode:<uri_key>
   * spotify:station:artist:<uri_key>
   * spotify:station:album:<uri_key>
   * spotify:station:playlist:<uri_key>
   * spotify:station:track:<uri_key>
   * spotify:station:genre:<spotify_genre>
   * spotify:station:user:<spotify_user>:playlist:<uri_key>
   * spotify:station:user:<spotify_user>:cluster:<uri_key>
   * spotify:user:<spotify_user>:collection
   * spotify:user:<spotify_user>:collection:album:<uri_key>
   * spotify:user:<spotify_user>:album:<uri_key>
   * spotify:user:<spotify_user>:playlist:<uri_key>
   * spotify:internal:local-files
   * spotify:search:<search_query>
   * spotify:internal:search:tracklist:<search_query>
   *
   * A search query with ':' will have replaced this character with %3A
   *
   * @private
   * @param {string} [uri]
   */
  BasSpotifyUri.prototype._parse = function (uri) {

    var split, length, i

    if (BasUtil.isNEString(uri)) {

      this.uri = uri

      split = this.uri.split(':')

      if (Array.isArray(split) && split[0] === 'spotify') {

        length = split.length
        for (i = 1; i < length; i++) {

          switch (split[i]) {

            case 'station':
              this.station = true
              continue

            case 'internal':
              this.internal = true
              continue

            case 'local-files':
              this.localFiles = true
              continue

            case 'collection':
              this.collection = true
              continue

            case 'cluster':
              this.cluster = split[++i]
              continue

            case 'user':
              this.user = split[++i]
              continue

            case 'genre':
              this.genre = split[++i]
              continue

            case 'search':

              // If next string === 'tracklist'
              // go to the one thereafter,
              // else take this one.

              this.search =
                split[++i] === 'tracklist'
                  ? split[++i]
                  : split[i]
              continue

            case 'artist':
              this.artist = split[++i]
              continue

            case 'album':
              this.album = split[++i]
              continue

            case 'show':
              this.show = split[++i]
              continue

            case 'episode':
              this.episode = split[++i]
              continue

            case 'playlist':
              this.playlist = split[++i]
              continue

            case 'track':
              this.track = split[++i]
              continue
          }
        }
      }
    }
  }

  /**
   * Returns ID for an item (track, artist, album, episode, ...)
   *
   * @returns {string}
   */
  BasSpotifyUri.prototype.getItemId = function () {

    if (this.track) return this.track
    if (this.artist) return this.artist
    if (this.album) return this.album
    if (this.genre) return this.genre
    if (this.playlist) return this.playlist
    if (this.episode) return this.episode
    if (this.show) return this.show
    if (this.search) return this.search
    if (this.localFiles) return 'local-files'

    return ''
  }

  /**
   * @returns {boolean}
   */
  BasSpotifyUri.prototype.isPodcast = function () {

    return !!(
      this.episode ||
      this.show
    )
  }

  return BasSpotifyUri
}
