'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasHeartbeat', [
    '$window',
    BasHeartbeat
  ])

/**
 * @constructor
 * @param $window
 */
function BasHeartbeat ($window) {

  this.start = start

  function start () {

    var heartbeat

    heartbeat = getHeartbeatPlugin()

    if (heartbeat) {

      heartbeat.addListener(_onHeartbeat)
      heartbeat.init()
    }
  }

  function _onHeartbeat () {

    var heartbeat

    heartbeat = getHeartbeatPlugin()

    if (heartbeat) {

      heartbeat.respondToHeartbeat()
    }
  }

  /**
   * Get webview heartbeat plugin instance
   *
   * @private
   * @returns {?HttpServer}
   */
  function getHeartbeatPlugin () {

    if (BasUtil.isObject($window['basalteCordova']) &&
      BasUtil.isObject($window['basalteCordova']['heartbeat'])) {

      return $window['basalteCordova']['heartbeat']
    }

    return null
  }
}
