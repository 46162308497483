'use strict'

angular
  .module('basalteApp')
  .directive('basWeatherTopWidget', [
    'BAS_HTML',
    basWeatherTopWidgetDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basWeatherTopWidget
 */
function basWeatherTopWidgetDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    scope: {
      weather: '<'
    },
    template: BAS_HTML.weatherTopWidget
  }
}
