'use strict'

import * as BasUtil from '@basalte/bas-util'

/**
 * @typedef {Object} TBasSpotifyModelProcessOptions
 * @property {number} [offset]
 * @property {Object[]} [images]
 */

angular
  .module('basalteApp')
  .service('SpotifyModel', [
    'BAS_FAVOURITE',
    'SpotifyElement',
    'BasUtilities',
    'Logger',
    SpotifyModel
  ])

/**
 * Helper class for creating SpotifyElements from Spotify Objects
 *
 * @constructor
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param SpotifyElement
 * @param {BasUtilities} BasUtilities
 * @param Logger
 */
function SpotifyModel (
  BAS_FAVOURITE,
  SpotifyElement,
  BasUtilities,
  Logger
) {
  var serviceName = 'Spotify model'

  // Main sections
  var SECTION_MY_MUSIC = 'myMusic'
  var SECTION_BROWSE = 'browse'
  var SECTION_MORE = 'more'
  var SECTION_GENRES_MOODS = 'GenresMoods'

  // Spotify keys
  var K_ID = 'id'
  var K_URI = 'uri'
  var K_HREF = 'href'
  var K_NAME = 'name'
  var K_ARTISTS = 'artists'
  var K_ALBUM = 'album'
  var K_TRACK = 'track'
  var K_PLAYLIST = 'playlist'
  var K_ARTIST = 'artist'
  var K_GENRE = 'genre'
  var K_DURATION = 'duration_ms'
  var K_TOTAL = 'total'
  var K_TRACKS = 'tracks'
  var K_IMAGES = 'images'
  var K_ICONS = 'icons'
  var K_PLAYABLE = 'is_playable'
  var K_LOCAL = 'is_local'
  var K_OWNER = 'owner'
  var K_LINKED_FROM = 'linked_from'

  /**
   * @constant {string}
   */
  this.SECTION_MY_MUSIC = SECTION_MY_MUSIC

  /**
   * @constant {string}
   */
  this.SECTION_BROWSE = SECTION_BROWSE

  /**
   * @constant {string}
   */
  this.SECTION_GENRES_MOODS = SECTION_GENRES_MOODS

  /**
   * @constant {string}
   */
  this.SECTION_MORE = SECTION_MORE

  this.processSpotifyObjects = processSpotifyObjects

  this.createSection = createSection
  this.processSpotifyAlbum = processSpotifyAlbum
  this.processSpotifyArtist = processSpotifyArtist
  this.getSpotifyImages = getSpotifyImages

  /**
   * Can take an optional offset
   * to start processing items at index offset
   *
   * @param {Array<?Object>} array
   * @param {TBasSpotifyModelProcessOptions} [options]
   * @returns {Array<SpotifyElement>}
   */
  function processSpotifyObjects (
    array,
    options
  ) {
    var result, finalOffset, offset, i, length, element

    result = []
    finalOffset = 0

    if (BasUtil.isObject(options)) {

      if (BasUtil.isPNumber(options.offset, true)) {
        offset = options.offset
      }
    }

    // Check offset
    if (BasUtil.isPNumber(offset, true)) finalOffset = offset

    // Make sure argument is array
    if (Array.isArray(array)) {

      // Iterate array
      length = array.length
      for (i = finalOffset; i < length; i += 1) {

        element = processSpotifyObject(array[i], options)

        // Add Spotify Ui Element to results
        if (BasUtil.isNEString(element.title)) result.push(element)
      }
    } else {
      Logger.warn(
        serviceName + ' - processSpotifyObjects - Parameter is NO ARRAY',
        array
      )
    }

    return result
  }

  /**
   * @param {?Object} object
   * @param {TBasSpotifyModelProcessOptions} [options]
   * @returns {SpotifyElement}
   */
  function processSpotifyObject (
    object,
    options
  ) {
    var element

    element = new SpotifyElement()

    // Check if object is valid
    if (BasUtil.isObject(object)) {

      // Check Spotify object type
      if (object.type === K_TRACK) {

        // Process track
        processSpotifyTrack(element, object, options)

        // Set type
        element.type = SpotifyElement.TYPE_TRACK

        // UI properties
        element.setHasContext(true)
        element.setCanSelect(true)

      } else if (object.type === K_ALBUM) {

        // Process album
        processSpotifyAlbum(element, object)

        // Set type
        element.type = SpotifyElement.TYPE_ALBUM

        // UI properties
        element.setCanBrowse(true)
        element.setHasContext(true)

      } else if (object.type === K_ARTIST) {

        // Process artist
        processSpotifyArtist(element, object)

        // Set type
        element.type = SpotifyElement.TYPE_ARTIST

        // UI properties
        element.setCanBrowse(true)
        element.setHasContext(true)

      } else if (object.type === K_PLAYLIST) {

        // Process playlist
        processSpotifyPlaylist(element, object)

        // Set type
        element.type = SpotifyElement.TYPE_PLAYLIST

        // UI properties
        element.setCanBrowse(true)
        element.setHasContext(true)

      } else if (object.type === K_GENRE) {

        // Process playlist
        processSpotifyGenre(element, object)

        // Set type
        element.type = SpotifyElement.TYPE_GENRE

        // UI properties
        element.setCanBrowse(true)

      } else if (BasUtil.isObject(object[K_TRACK])) {

        // Process track
        processSpotifyTrack(
          element,
          object[K_TRACK],
          options
        )

        // Set type
        element.type = SpotifyElement.TYPE_TRACK

        // UI properties
        element.setCanSelect(true)
        element.setHasContext(true)

      } else if (BasUtil.isObject(object[K_ALBUM])) {

        // Process album
        processSpotifyAlbum(element, object[K_ALBUM])

        // Set type
        element.type = SpotifyElement.TYPE_ALBUM

        // UI properties
        element.setCanBrowse(true)
        element.setHasContext(true)

      } else {
        Logger.warn(serviceName + ' - Unknown Spotify object', object)
      }

      // Make sure the Asano favourite Id is created
      element.createAsanoFavouriteId(BAS_FAVOURITE.T_SPOTIFY)
    }

    return element
  }

  /**
   * @param {Array} array Images going from big to small
   * @returns {TCoversObj}
   */
  function getImages (array) {

    var result, length, i, object, width, url, smallW, mediumW, largeW

    smallW = 0
    mediumW = 0
    largeW = 0

    result = {
      small: '',
      medium: '',
      large: ''
    }

    if (Array.isArray(array)) {

      length = array.length
      for (i = 0; i < length; i++) {

        object = array[i]
        url = object.url
        width = object.width

        if (BasUtil.isPNumber(width)) {

          // Small
          if (width >= 64 && width < 150) {

            if (width > smallW) {
              result.small = url
              smallW = width
            }
          }

          // Medium
          if (width >= 150 && width <= 300) {

            if (width > mediumW) {
              result.medium = url
              mediumW = width
            }
          }

          // Large
          if (width >= 300) {

            if (width > largeW) {
              result.large = url
              largeW = width
            }
          }
        }
      }
    }

    // Fill larger variants with small if needed
    if (!result.medium && result.small) result.medium = result.small
    if (!result.large && result.medium) result.large = result.medium

    // Fill smaller variants with large if needed
    if (!result.medium && result.large) result.medium = result.large
    if (!result.small && result.medium) result.small = result.medium

    // If no suitable cover art is found
    // Biggest width being smaller than min
    if (
      !result.small &&
      !result.medium &&
      !result.large &&
      array &&
      array[0] &&
      BasUtil.isNEString(array[0].url)
    ) {
      result.small = array[0].url
      result.medium = array[0].url
      result.large = array[0].url
    }

    return result

  }

  /**
   * @param {SpotifyElement} element
   * @param {?Object} object
   */
  function processSpotifyAlbum (element, object) {
    var length, i

    element.spotify = object

    // Title
    if (BasUtil.isNEString(object[K_NAME])) {
      element.title = object[K_NAME]
    }

    // Check if artist is valid
    if (Array.isArray(object[K_ARTISTS])) {

      length = object[K_ARTISTS].length

      element.subtitle = ''
      for (i = 0; i < length; i++) {
        element.subtitle += object[K_ARTISTS][i][K_NAME] + ', '
      }

      if (length > 0) {
        element.subtitle =
          element.subtitle.substring(0, element.subtitle.length - 2)
      }
    }

    // Set ID
    element.setId(
      BAS_FAVOURITE.T_SPOTIFY,
      object[K_ID]
    )

    if (BasUtil.isObject(object[K_TRACKS]) &&
      BasUtil.isNEString(object[K_TRACKS][K_HREF])) {
      element.tracksLink = object[K_TRACKS][K_HREF]
    } else {
      element.tracksLink = 'https://api.spotify.com/v1/albums/' +
        element.id + '/tracks?offset=0&limit=50'
    }

    // Set URI
    if (BasUtil.isNEString(object[K_URI])) {
      element.uri = object[K_URI]
    }

    element.setCoverArt(getImages(getSpotifyImages(object)))
  }

  /**
   * @param {SpotifyElement} element
   * @param {?Object} object
   */
  function processSpotifyArtist (element, object) {

    element.spotify = object

    // Title
    if (BasUtil.isNEString(object[K_NAME])) {
      element.title = object[K_NAME]
    }

    // Set ID
    element.setId(
      BAS_FAVOURITE.T_SPOTIFY,
      object[K_ID]
    )

    // Set URI
    if (BasUtil.isNEString(object[K_URI])) {
      element.uri = object[K_URI]
    }

    element.setCoverArt(getImages(getSpotifyImages(object)))
  }

  /**
   * @param {SpotifyElement} element
   * @param {?Object} object
   */
  function processSpotifyPlaylist (element, object) {

    element.spotify = object

    // Title
    if (BasUtil.isNEString(object[K_NAME])) {
      element.title = object[K_NAME]
    }

    if (BasUtil.isObject(object[K_TRACKS]) &&
      BasUtil.isPNumber(object[K_TRACKS][K_TOTAL], true)) {
      element.setNumberOfSongs(object[K_TRACKS][K_TOTAL])
    }

    if (BasUtil.isObject(object[K_TRACKS]) &&
      BasUtil.isNEString(object[K_TRACKS][K_HREF])) {
      element.tracksLink = object[K_TRACKS][K_HREF]
    }

    // Set ID
    element.setId(
      BAS_FAVOURITE.T_SPOTIFY,
      object[K_ID]
    )

    if (BasUtil.isObject(object[K_OWNER]) &&
      BasUtil.isNEString(object[K_OWNER][K_ID])) {
      element.owner = object[K_OWNER][K_ID]
    }

    // Set URI
    if (BasUtil.isNEString(object[K_URI])) {
      element.uri = object[K_URI]
    }

    element.setCoverArt(getImages(getSpotifyImages(object)))
  }

  /**
   * @param {SpotifyElement} element
   * @param {?Object} object
   */
  function processSpotifyGenre (element, object) {

    element.spotify = object

    // Title
    if (BasUtil.isNEString(object[K_NAME])) {
      element.title = object[K_NAME]
    }

    // Set URI
    if (BasUtil.isNEString(object[K_HREF])) {
      element.uri = object[K_HREF]
    }

    element.setCoverArt(
      BasUtil.isNEArray(object[K_ICONS])
        ? getImages(object[K_ICONS])
        : {
            small: '',
            medium: '',
            large: ''
          }
    )

    // Set ID
    element.setId(
      BAS_FAVOURITE.T_SPOTIFY,
      object[K_ID]
    )
  }

  /**
   * @param {SpotifyElement} element
   * @param {?Object} object
   * @param {TBasSpotifyModelProcessOptions} [options]
   */
  function processSpotifyTrack (
    element,
    object,
    options
  ) {
    var length, i, value, _images

    if (options) {

      if (BasUtil.isObject(options.images)) _images = options.images
    }

    element.spotify = object

    // Title
    if (BasUtil.isNEString(object[K_NAME])) {
      element.title = object[K_NAME]
    }

    // Check duration
    if (BasUtil.isPNumber(object[K_DURATION])) {
      element.setDuration(Math.floor(object[K_DURATION] / 1000))
    }

    // Check if artist is valid
    if (Array.isArray(object[K_ARTISTS])) {

      length = object[K_ARTISTS].length

      element.subtitle = ''
      for (i = 0; i < length; i++) {
        element.subtitle += object[K_ARTISTS][i][K_NAME] + ', '
      }

      if (length > 0) {
        element.subtitle =
          element.subtitle.substring(0, element.subtitle.length - 2)
      }
    }

    if (object[K_LOCAL] === true ||
      object[K_PLAYABLE] === false) {

      element.setGreyedOut(true)
    }

    if (BasUtil.isObject(object[K_LINKED_FROM]) &&
      BasUtil.isNEString(object[K_LINKED_FROM][K_URI])) {

      element.linkedUri = object[K_LINKED_FROM][K_URI]
    }

    // Set ID
    element.setId(
      BAS_FAVOURITE.T_SPOTIFY,
      object[K_ID]
    )

    // Set URI
    if (BasUtil.isNEString(object[K_URI])) {
      element.uri = object[K_URI]
    }

    value = getSpotifyImages(object)
    element.setCoverArt(getImages(
      value.length
        ? value
        : _images
    ))
  }

  /**
   * @param {string} sectionType
   * @returns {SpotifyElement}
   */
  function createSection (sectionType) {

    // Create new UI element for type
    var element = new SpotifyElement()

    // Set ID
    element.id = sectionType

    // Type
    element.type = SpotifyElement.TYPE_SECTION

    // Sub type
    element.subType = sectionType

    // Customize according to section type
    switch (sectionType) {
      case SECTION_MY_MUSIC:

        // Titles
        element.title = BasUtilities.translate('my_music')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_BROWSE:

        // Titles
        element.title = BasUtilities.translate('browse')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_MORE:

        // Titles
        element.title = BasUtilities.translate('show_more')

        // Has more content
        element.setCanBrowse(true)

        break
      case SECTION_GENRES_MOODS:

        // Titles
        element.title = BasUtilities.translate('genre_moods')

        // Has more content
        element.setCanBrowse(true)

        break
      default:
        Logger.warn(serviceName + '- Invalid sectionType', sectionType)
    }

    return element
  }

  /**
   * @param {Object} object
   * @returns {Object[]}
   */
  function getSpotifyImages (object) {

    var result

    result = []

    if (BasUtil.isObject(object)) {

      if (Array.isArray(object[K_IMAGES])) {

        return object[K_IMAGES]

      } else if (
        BasUtil.isObject(object[K_ALBUM]) &&
        Array.isArray(object[K_ALBUM][K_IMAGES])
      ) {

        return object[K_ALBUM][K_IMAGES]
      }
    }

    return result
  }
}
