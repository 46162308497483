'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var log = require('./logger')

var _id = 0

/**
 * @private
 * @returns {number}
 */
function _getId () {

  if (_id > 1000000) _id = 0

  return _id++
}

/**
 * @typedef {Object} TBasDataChunk
 * @property {number} chunkId Unique ID for this message
 * @property {number} chunkSeq Sequence number
 * @property {number} chunkSeqMax Max sequence number for this message
 * @property {string} chunk Data for this chunk
 */

/**
 * @constructor
 * @extends EventEmitter
 * @param {RTCDataChannel} dataChannel
 * @param {string} [tag] For debugging purposes
 * @since 3.0.0
 */
function BasDataChannel (dataChannel, tag) {

  EventEmitter.call(this)

  /**
   * @private
   * @type {string}
   */
  this._tag = BasUtil.isString(tag) ? tag : ''

  /**
   * @private
   * @type {RTCDataChannel}
   */
  this._dataChannel = dataChannel

  /**
   * @private
   * @type {Array}
   */
  this._dataChannelListeners = []

  /**
   * @private
   * @type {Object<string, string[]>}
   */
  this._chunks = {}

  this._handleError = this._onError.bind(this)
  this._handleClose = this._onClose.bind(this)
  this._handleOpen = this._onOpen.bind(this)
  this._handleMessage = this._onMessage.bind(this)

  this._setDataChannelListeners()
}

BasDataChannel.prototype = Object.create(EventEmitter.prototype)
BasDataChannel.prototype.constructor = BasDataChannel

/**
 * @event BasDataChannel#EVT_CONNECTED
 * @param {boolean} isConnected
 */

/**
 * @event BasDataChannel#EVT_MESSAGE
 * @param {Object} message
 */

/**
 * @constant {string}
 */
BasDataChannel.EVT_CONNECTED = 'evtBasDataChannelConnected'

/**
 * @constant {string}
 */
BasDataChannel.EVT_MESSAGE = 'evtBasDataChannelMessage'

/**
 * Do not go above 16KB for maximum compatibility.
 * https://developer.mozilla.org
 * /en-US/docs/Web/API/WebRTC_API/Using_data_channels
 *
 * @constant {number}
 */
BasDataChannel.MAX_MSG_SIZE = 15000

/**
 * @returns {boolean}
 */
BasDataChannel.prototype.isConnected = function () {

  return (
    this._dataChannel &&
    this._dataChannel.readyState === 'open'
  )
}

/**
 * @param {string} data
 * @returns {boolean}
 */
BasDataChannel.prototype.send = function (data) {

  var length, _chunkId, _chunkSeqMax, _chunkSeq, _data, result

  if (this.isConnected()) {

    length = data.length

    if (length > BasDataChannel.MAX_MSG_SIZE) {

      _chunkId = _getId()
      _chunkSeqMax = Math.floor(length / BasDataChannel.MAX_MSG_SIZE)

      for (_chunkSeq = 0; _chunkSeq <= _chunkSeqMax; _chunkSeq++) {

        _data = {}
        _data[P.CHUNK_ID] = _chunkId
        _data[P.CHUNK_SEQ_MAX] = _chunkSeqMax
        _data[P.CHUNK_SEQ] = _chunkSeq
        _data[P.CHUNK] = data.substring(
          _chunkSeq * BasDataChannel.MAX_MSG_SIZE,
          (_chunkSeq + 1) * BasDataChannel.MAX_MSG_SIZE
        )

        // TODO Listen for bufferedamountlow event?

        result = this._send(JSON.stringify(_data))

        if (!result) return result
      }

      return result

    } else {

      return this._send(data)
    }
  }

  return false
}

/**
 * @private
 * @param {string} data
 * @returns {boolean}
 */
BasDataChannel.prototype._send = function (data) {

  if (this.isConnected()) {

    try {

      this._dataChannel.send(data)

      return true

    } catch (error) {

      log.error(
        'DataChannel ' + this._tag + ' Send ERROR',
        error,
        data
      )

      return false
    }
  }

  return false
}

/**
 * @private
 * @param {Event} event
 */
BasDataChannel.prototype._onError = function (event) {

  log.error(
    'DataChannel ' + this._tag + ' ERROR',
    this._dataChannel,
    event
  )
}

/**
 * @private
 * @param {Event} event
 */
BasDataChannel.prototype._onClose = function (event) {

  log.info('DataChannel ' + this._tag + ' closed', event)

  this._emitConnected(false)
}

/**
 * @private
 */
BasDataChannel.prototype._onOpen = function () {

  log.info('DataChannel ' + this._tag + ' opened')

  this._emitConnected(true)
}

/**
 * @private
 * @param {MessageEvent} event
 */
BasDataChannel.prototype._onMessage = function (event) {

  var chunk, obj, _chunkId, _chunkSeq, _chunkSeqMax, _chunk
  var _chunks, _joinedChunks, complete, length, i

  try {

    chunk = JSON.parse(event.data)

  } catch (error) {

    log.error(
      'DataChannel ' + this._tag + ' - Error parsing message',
      event,
      error
    )

    return
  }

  if (BasUtil.isObject(chunk)) {

    if (BasUtil.safeHasOwnProperty(chunk, P.CHUNK_ID) &&
      BasUtil.safeHasOwnProperty(chunk, P.CHUNK)) {

      _chunkId = chunk[P.CHUNK_ID]
      _chunkSeq = chunk[P.CHUNK_SEQ]
      _chunkSeqMax = chunk[P.CHUNK_SEQ_MAX]
      _chunk = chunk[P.CHUNK]

      if (_chunkSeq === 0 &&
        _chunkSeqMax === 0) {

        try {

          obj = JSON.parse(_chunk)

        } catch (error) {

          log.error(
            'DataChannel ' + this._tag + ' - Error parsing chunk',
            chunk,
            error
          )

          return
        }

      } else if (BasUtil.isPNumber(_chunkId, true) &&
        BasUtil.isPNumber(_chunkSeq, true) &&
        BasUtil.isPNumber(_chunkSeqMax, true)) {

        // Make sure array exists
        if (!this._chunks[_chunkId]) this._chunks[_chunkId] = []

        _chunks = this._chunks[_chunkId]

        _chunks[_chunkSeq] = _chunk

        complete = true

        length = _chunkSeqMax + 1
        for (i = 0; i < length; i++) {

          if (!_chunks[i]) {

            complete = false
            break
          }
        }

        if (complete) {

          _joinedChunks = _chunks.join('')

          try {

            obj = JSON.parse(_joinedChunks)
            this._chunks[_chunkId] = null

          } catch (error) {

            log.error(
              'DataChannel ' + this._tag +
              ' - Invalid complete chunk',
              chunk,
              _chunks
            )
          }
        }

      } else {

        log.error(
          'DataChannel ' + this._tag + ' - Invalid chunk',
          chunk
        )

        return
      }

    } else {

      obj = chunk
    }
  }

  if (BasUtil.isObject(obj)) this._emitMessage(obj)
}

/**
 * @private
 * @param {boolean} isConnected
 */
BasDataChannel.prototype._emitConnected = function (isConnected) {

  this.emit(BasDataChannel.EVT_CONNECTED, isConnected)
}

/**
 * @private
 * @param {Object} message
 */
BasDataChannel.prototype._emitMessage = function (message) {

  this.emit(BasDataChannel.EVT_MESSAGE, message)
}

/**
 * @private
 */
BasDataChannel.prototype._setDataChannelListeners = function () {

  this._clearDataChannelListeners()

  if (this._dataChannel) {

    this._dataChannelListeners.push(BasUtil.setDOMListener(
      this._dataChannel,
      'error',
      this._handleError
    ))
    this._dataChannelListeners.push(BasUtil.setDOMListener(
      this._dataChannel,
      'close',
      this._handleClose
    ))
    this._dataChannelListeners.push(BasUtil.setDOMListener(
      this._dataChannel,
      'open',
      this._handleOpen
    ))
    this._dataChannelListeners.push(BasUtil.setDOMListener(
      this._dataChannel,
      'message',
      this._handleMessage
    ))
  }
}

/**
 * @private
 */
BasDataChannel.prototype._clearDataChannelListeners = function () {

  BasUtil.executeArray(this._dataChannelListeners)
  this._dataChannelListeners = []
}

BasDataChannel.prototype.closeConnection = function () {

  let closedPromise = Promise.resolve()

  if (this._dataChannel) {

    switch (this._dataChannel.readyState) {
      case 'open':
      case 'closing':

        this._emitConnected(false)

        break
    }

    // We need to be able to wait until the dc is actually closed
    closedPromise = new Promise((resolve, reject) => {
      // Add a timeout for the unlikely case that the dc never closes
      const timeout = setTimeout(reject, 1000)

      this._dataChannel.addEventListener('close', () => {
        clearTimeout(timeout)
        resolve()
      })
    })

    this._dataChannel.close()
  }

  this._clearDataChannelListeners()
  this._dataChannel = null

  return closedPromise
}

BasDataChannel.prototype.destroy = function () {

  this._clearDataChannelListeners()
  this.closeConnection()
}

module.exports = BasDataChannel
