'use strict'

angular
  .module('basalteApp')
  .controller('liveSettingsCtrl', [
    'BAS_MODAL',
    'BasAppDevice',
    'BasLiveAccount',
    'CurrentBasCore',
    'BasModal',
    liveSettingsCtrl
  ])

/**
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasAppDevice} BasAppDevice
 * @param {BasLiveAccount} BasLiveAccount
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasModal} BasModal
 */
function liveSettingsCtrl (
  BAS_MODAL,
  BasAppDevice,
  BasLiveAccount,
  CurrentBasCore,
  BasModal
) {
  var live = this

  /**
   * @type {BasAppDeviceState}
   */
  live.basAppDeviceState = BasAppDevice.get()

  /**
   * @type {TBasLiveAccountState}
   */
  live.basLiveAccountState = BasLiveAccount.get()

  /**
   * @type {TBasLiveServerState}
   */
  live.currentBasLiveServerState = BasLiveAccount.getServerState()

  /**
   * @type {TCurrentBasCoreState}
   */
  live.currentBasCoreState = CurrentBasCore.get()

  live.linkServer = BasLiveAccount.linkServer
  live.unlinkServer = _unlinkServer

  function _unlinkServer () {

    if (CurrentBasCore.hasCore() &&
      live.currentBasCoreState.core.core.server &&
      live.currentBasCoreState.core.core.server.demo) {

      BasModal.show(BAS_MODAL.T_DEMO_FUNCTIONALITY_WARNING)

    } else {

      BasLiveAccount.unlinkServer()
    }
  }
}
