'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('updateLibrary', updateLibraryDirective)

function updateLibraryDirective () {

  return {
    restrict: 'A',
    template:
      '<div class="' +
      'bbs bbs-item bbs-centered' +
      ' bbs-flex bbs-active-select bbs-action"' +
      'ng-class="{\'bbs-disabled\': updateLibrary.dbUpdateStarted}"' +
      'bas-click="updateLibrary.scanLibrary()">' +
      '<div class="bbs-name ellipsis-overflow"' +
      'data-translate="' +
      '{{ ' +
      ' updateLibrary.dbUpdateStarted ' +
      '   ? \'updating_library\' ' +
      '   : \'update_library\'' +
      '}}"></div>' +
      '<div class="bbs-spinner bas-spinner-small-highlight" ' +
      'bas-spinner \n' +
      'ng-if="updateLibrary.dbUpdateStarted"></div>' +
      '</div>',
    controller: [
      '$scope',
      '$rootScope',
      'BAS_CURRENT_CORE',
      'BAS_MODAL',
      'BAS_API',
      'CurrentBasCore',
      'LibraryService',
      'BasModal',
      controller
    ],
    controllerAs: 'updateLibrary'
  }

  /**
   * @param $scope
   * @param $rootScope
   * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
   * @param {BAS_MODAL} BAS_MODAL
   * @param BAS_API
   * @param {CurrentBasCore} CurrentBasCore
   * @param {LibraryService} LibraryService
   * @param {BasModal} BasModal
   */
  function controller (
    $scope,
    $rootScope,
    BAS_CURRENT_CORE,
    BAS_MODAL,
    BAS_API,
    CurrentBasCore,
    LibraryService,
    BasModal
  ) {
    var updateLibrary = this
    var _listeners = []

    /**
     * @type {TCurrentBasCoreState}
     */
    updateLibrary.currentBasCoreState = CurrentBasCore.get()

    updateLibrary.dbUpdateStarted = false

    updateLibrary.scanLibrary = scanLibrary

    init()

    function init () {

      $scope.$on('$destroy', destroy)

      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_MUSIC_LIBRARY_SCANNING_CHANGED,
        onDatabaseUpdate
      ))
      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
        onServerVariablesLoaded
      ))
      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
        _onCoreCoreConnected
      ))

      if (CurrentBasCore.hasCore()) {

        if (CurrentBasCore.hasAVFullSupport()) {

          onDatabaseUpdate()

        } else if (
          updateLibrary.currentBasCoreState.core.core.musicConfigReceived
        ) {

          onServerVariablesLoaded()
          onDatabaseUpdate()
        }
      }
    }

    function scanLibrary () {

      if (!updateLibrary.dbUpdateStarted) {

        BasModal.show(BAS_MODAL.T_RESCAN_MUSIC_LIBRARY).then(onModal)
      }

      function onModal (modal) {

        modal.close.then(onModalClose)
      }

      function onModalClose (result) {

        if (result === BAS_MODAL.C_YES) {

          LibraryService.scan()
        }
      }
    }

    /**
     * @param {Object} _event
     * @param {BasCoreContainer} _basCoreContainer
     * @param {boolean} isConnected
     */
    function _onCoreCoreConnected (
      _event,
      _basCoreContainer,
      isConnected
    ) {
      if (isConnected) {

        onDatabaseUpdate()
      }
    }

    function onServerVariablesLoaded () {

      if (
        CurrentBasCore.hasCore() &&
        !CurrentBasCore.hasAVFullSupport()
      ) {

        togglePlayerDatabaseListeners(true)
      }
    }

    /**
     * @param {boolean} [on]
     */
    function togglePlayerDatabaseListeners (on) {

      var players, player, keys, i, length

      if (CurrentBasCore.hasCore()) {

        players = updateLibrary.currentBasCoreState.core.core.players

        keys = Object.keys(players)
        length = keys.length
        for (i = 0; i < length; i++) {

          player = players[keys[i]]

          if (BasUtil.isObject(player)) {

            if (on) {

              player.database.on(
                BAS_API.Database.EVT_DATABASE_UPDATING,
                onDatabaseUpdate
              )

            } else {

              player.database.removeListener(
                BAS_API.Database.EVT_DATABASE_UPDATING,
                onDatabaseUpdate
              )
            }
          }
        }
      }
      onDatabaseUpdate()
    }

    function onDatabaseUpdate () {

      var players, player, keys, i, length, isUpdating

      isUpdating = false

      if (CurrentBasCore.hasCore()) {

        if (
          CurrentBasCore.hasAVFullSupport() &&
          updateLibrary.currentBasCoreState.core.core.musicLibrary
        ) {

          isUpdating =
            updateLibrary.currentBasCoreState.core.core.musicLibrary.isScanning

        } else {

          players = updateLibrary.currentBasCoreState.core.core.players

          keys = Object.keys(players)
          length = keys.length
          for (i = 0; i < length; i++) {

            player = players[keys[i]]

            if (
              BasUtil.isObject(player) &&
              BasUtil.isObject(player.database) &&
              !isUpdating
            ) {

              isUpdating = player.database.isUpdating
            }
          }
        }
      }

      updateLibrary.dbUpdateStarted = isUpdating

      $scope.$applyAsync()
    }

    function destroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []

      togglePlayerDatabaseListeners(false)
    }
  }
}
