'use strict'

import * as BasUtil from '@basalte/bas-util'

// Note: due to issues with the kelvin picker in Iro, we are currently not using
//  this directive. (minimum temperature 2000k, some jumpiness of the thumb)
//
// https://github.com/jaames/iro.js/issues/138

angular
  .module('basalteApp')
  .directive('basColorTemperatureSlider', [
    'Iro',
    basColorTemperatureSlider
  ])

/**
 * @param Iro
 * @returns basColorPicker
 */
function basColorTemperatureSlider (
  Iro
) {
  return {
    restrict: 'AE',
    bindToController: {
      width: '<',
      debounce: '<',
      key: '@',
      min: '<',
      max: '<',
      sliderModelObject: '<',
      sliderChange: '&',
      sliderChanged: '&'
    },
    controllerAs: 'temperatureSlider',
    controller: [
      '$scope',
      '$element',
      basColorTemperatureSliderController
    ]
  }

  /**
   * @param $scope
   * @param $element
   */
  function basColorTemperatureSliderController (
    $scope,
    $element
  ) {

    var DEF_WIDTH = 250
    var DEF_MIN = 2000
    var DEF_MAX = 10000
    var DEF_COLOR_TEMPERATURE = 6000

    var iroPicker
    var debounceTimeoutId = null

    var temperatureSlider = this

    temperatureSlider.$onChanges = _onChanges

    init()

    function init () {

      $scope.$watch(_getSliderModel, _onSliderModelChange)
      $scope.$on('$destroy', _onDestroy)
    }

    function _getSliderModel () {

      return BasUtil.isObject(temperatureSlider.sliderModelObject)
        ? temperatureSlider.sliderModelObject[temperatureSlider.key]
        : null
    }

    function _onSliderModelChange () {

      var value = _getSliderModel()

      if (iroPicker && BasUtil.isVNumber(value)) iroPicker.color.kelvin = value
    }

    function _onChanges () {

      var sliderElement, min, max, colorTemperature, width, sliderModel

      sliderElement = $element[0]

      min = BasUtil.isVNumber(temperatureSlider.min)
        ? temperatureSlider.min
        : DEF_MIN

      max = BasUtil.isVNumber(temperatureSlider.max)
        ? temperatureSlider.max
        : DEF_MAX

      sliderModel = _getSliderModel()
      colorTemperature = BasUtil.isVNumber(sliderModel)
        ? sliderModel
        : DEF_COLOR_TEMPERATURE

      width = BasUtil.isVNumber(temperatureSlider.width)
        ? temperatureSlider.width
        : DEF_WIDTH

      sliderElement.innerHTML = ''
      iroPicker = new Iro.ColorPicker(
        sliderElement,
        {
          width: width,
          color: {
            kelvin: colorTemperature
          },
          padding: 0,
          display: 'inline-block',
          layout: [
            {
              component: Iro.ui.Slider,
              options: {
                sliderType: 'kelvin',
                sliderSize: 40,
                sliderShape: 'circle',
                minTemperature: min,
                maxTemperature: max
              }
            }
          ]
        }
      )

      iroPicker.on('input:change', _onColorChange)
    }

    function _onColorChange (color) {

      var old

      if (color && BasUtil.isVNumber(color.kelvin)) {

        if (temperatureSlider.sliderModelObject) {

          old = temperatureSlider.sliderModelObject[temperatureSlider.key]

          temperatureSlider.sliderModelObject[temperatureSlider.key] =
            Math.round(color.kelvin)

          if (old !== color.kelvin) _executeColorTemperatureChange()
        }
      }
    }

    function _executeColorTemperatureChange () {

      var debounce = BasUtil.isVNumber(temperatureSlider.debounce)
        ? temperatureSlider.debounce
        : 0

      // Clear timeout
      clearTimeout(debounceTimeoutId)

      if (BasUtil.isFunction(temperatureSlider.sliderChange)) {

        // Set new timeout if necessary
        if (debounce > 0) {

          debounceTimeoutId = setTimeout(
            temperatureSlider.sliderChange,
            debounce
          )

        } else {
          temperatureSlider.sliderChange()
        }
      }
    }

    function _onDestroy () {
      if (iroPicker) iroPicker.off('input:change', _onColorChange)
    }
  }
}
