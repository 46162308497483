'use strict'

angular
  .module('basalteApp')
  .directive('deviceBrightness', deviceBrightness)

function deviceBrightness () {

  return {
    restrict: 'AE',
    template:
      '<div class="' +
      'bbs bbs-item bbs-centered' +
      ' bbs-flex bbs-active-select"' +
      ' bas-click="brightness.toggleAdaptive()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      ' data-translate="adaptive_brightness"></div>' +
      '  <bas-toggle class="bbs-toggle bas-toggle-dark"' +
      ' data-bas-toggle="brightness.toggleAdaptive()"' +
      ' data-bas-toggled="brightness.adaptive"></bas-toggle>' +
      '</div>' +
      '<div class="' +
      'bbs bbs-item bbs-centered' +
      '"' +
      ' ng-class="{\'bbs-disabled\': brightness.adaptive}">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      ' data-translate="brightness"></div>' +
      '  <div class="bbs-slider-wrapper">' +
      '    <bas-slider class="bbs-slider bas-slider-dark"' +
      ' data-slider-model-object="brightness"' +
      ' data-key="brightness"' +
      ' data-disabled="brightness.adaptive"' +
      ' data-slider-change="brightness.brightnessChange()" ' +
      ' data-slider-changed="brightness.brightnessChange()"></bas-slider>' +
      '    <div class="bbs-slider-value" ' +
      ' ng-bind="brightness.brightness"></div>' +
      '  </div>' +
      '</div>',
    controller: [
      'BasPreferences',
      controller
    ],
    controllerAs: 'brightness'
  }

  /**
   * @param {BasPreferences} BasPreferences
   */
  function controller (BasPreferences) {

    const brightness = this
    const value = BasPreferences.getBrightness()

    if (value === -1) {

      brightness.brightness = 50
      brightness.adaptive = true

    } else {

      brightness.brightness = value
      brightness.adaptive = false
    }

    brightness.brightnessChange = brightnessChange
    brightness.toggleAdaptive = toggleAdaptive

    function brightnessChange () {

      setBrightness()
    }

    function toggleAdaptive () {

      brightness.adaptive = !brightness.adaptive
      setBrightness()
    }

    function setBrightness () {

      if (brightness.adaptive) {

        BasPreferences.setBrightness(-1)

      } else {

        BasPreferences.setBrightness(brightness.brightness)
      }
    }
  }
}
