'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var Device = require('./device')

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {Object} device.state
 * @param {number} device.extraType
 * @param {BasCore} basCore
 */
function ShadeDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._state = BasUtil.isObject(device[P.STATE])
    ? device[P.STATE]
    : {}
}

ShadeDevice.prototype = Object.create(Device.prototype)
ShadeDevice.prototype.constructor = ShadeDevice

// region Events

/**
 * Fires when a state property has changed.
 *
 * @event ShadeDevice#EVT_STATE
 */

// endregion

/**
 * @constant {string}
 */
ShadeDevice.EVT_STATE = 'evtWindowTreatmentState'

/**
 * Shade types
 * From proto.be.basalte.config.WindowTreatmentDevice.WindowTreatmentType
 *
 * @readonly
 * @enum {number}
 */
ShadeDevice.TYPE = {
  CUSTOM: 0,
  SHADES: 1,
  ROLLER_BLINDS: 2,
  ROMAN_BLINDS: 3,
  VENETIAN_BLINDS: 4,
  VERTICAL_BLINDS: 5,
  CURTAIN: 6,
  SOLAR_PROTECTION: 7
}

/**
 * Reverse enum
 *
 * @readonly
 * @enum {string}
 */
ShadeDevice.TYPE_R = BasUtil.switchObjectKeyValue(ShadeDevice.TYPE)

/**
 * @constant {string}
 */
ShadeDevice.C_OPEN = P.OPEN

/**
 * @constant {string}
 */
ShadeDevice.C_STOP = P.STOP

/**
 * @constant {string}
 */
ShadeDevice.C_CLOSE = P.CLOSE

/**
 * @constant {string}
 */
ShadeDevice.C_OPEN_CLOSE = P.OPEN_CLOSE

/**
 * @constant {string}
 */
ShadeDevice.C_AUTO_MODE = P.AUTO_MODE

/**
 * @constant {string}
 */
ShadeDevice.C_POSITION = P.POSITION

/**
 * @constant {string}
 */
ShadeDevice.C_ROTATION = P.ROTATION

/**
 * @constant {string}
 */
ShadeDevice.C_ALARMS = P.ALARMS

/**
 * @constant {string}
 */
ShadeDevice.T_ALARM_GENERIC = P.GENERIC

/**
 * @constant {string}
 */
ShadeDevice.T_ALARM_WIND_1 = P.WIND + '1'

/**
 * @constant {string}
 */
ShadeDevice.T_ALARM_WIND_2 = P.WIND + '2'

/**
 * @constant {string}
 */
ShadeDevice.T_ALARM_WIND_3 = P.WIND + '3'

/**
 * @constant {string}
 */
ShadeDevice.T_ALARM_RAIN = P.RAIN

/**
 * @constant {string}
 */
ShadeDevice.T_ALARM_FROST = P.FROST

/**
 * Check capabilities (openClose) to see if this property is supported.
 *
 * @name ShadeDevice#opened
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(ShadeDevice.prototype, 'opened', {
  get: function () {

    return this._state[ShadeDevice.C_OPEN_CLOSE] === P.OPEN
  }
})

/**
 * Check capabilities (openClose) to see if this property is supported.
 *
 * @name ShadeDevice#closed
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(ShadeDevice.prototype, 'closed', {
  get: function () {

    return this._state[ShadeDevice.C_OPEN_CLOSE] === P.CLOSE
  }
})

/**
 * Check capabilities before using result
 *
 * @name ShadeDevice#position
 * @type {number}
 * @readonly
 */
Object.defineProperty(ShadeDevice.prototype, 'position', {
  get: function () {
    return BasUtil.isVNumber(this._state[ShadeDevice.C_POSITION])
      ? this._state[ShadeDevice.C_POSITION]
      : 0
  }
})

/**
 * Check capabilities before using result
 *
 * @name ShadeDevice#rotation
 * @type {number}
 * @readonly
 */
Object.defineProperty(ShadeDevice.prototype, 'rotation', {
  get: function () {
    return BasUtil.isVNumber(this._state[ShadeDevice.C_ROTATION])
      ? this._state[ShadeDevice.C_ROTATION]
      : 0
  }
})

/**
 * Check capabilities before using result
 *
 * @name ShadeDevice#autoMode
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(ShadeDevice.prototype, 'autoMode', {
  get: function () {
    return BasUtil.isBool(this._state[ShadeDevice.C_AUTO_MODE])
      ? this._state[ShadeDevice.C_AUTO_MODE]
      : false
  }
})

/**
 * Check capabilities before using result
 *
 * @name ShadeDevice#alarms
 * @type {?Object<string, boolean>}
 * @readonly
 */
Object.defineProperty(ShadeDevice.prototype, 'alarms', {
  get: function () {
    return BasUtil.isObject(this._state[ShadeDevice.C_ALARMS])
      ? this._state[ShadeDevice.C_ALARMS]
      : null
  }
})

/**
 * @name ShadeDevice#labelCloseText
 * @type {string}
 * @readonly
 * @since 2.7.0
 */
Object.defineProperty(ShadeDevice.prototype, 'labelCloseText', {
  get: function () {

    var labels = this.getLabels()

    if (labels && BasUtil.isNEString(labels[P.CLOSE])) {

      return labels[P.CLOSE]
    }

    return ''
  }
})

/**
 * @name ShadeDevice#labelOpenText
 * @type {string}
 * @readonly
 * @since 2.7.0
 */
Object.defineProperty(ShadeDevice.prototype, 'labelOpenText', {
  get: function () {

    var labels = this.getLabels()

    if (labels && BasUtil.isNEString(labels[P.OPEN])) {

      return labels[P.OPEN]
    }

    return ''
  }
})

/**
 * Parse a ShadeDevice message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
ShadeDevice.prototype.parse = function (msg, options) {

  var valid, emit

  emit = true
  valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    if (BasUtil.isObject(msg[P.STATE])) {

      if (!BasUtil.isEqualPartialObject(this._state, msg[P.STATE])) {

        BasUtil.mergeObjectsDeep(this._state, msg[P.STATE])

        if (emit) this.emit(ShadeDevice.EVT_STATE)
      }
    }
  }

  return valid
}

ShadeDevice.prototype.open = function () {

  this.performAction(ShadeDevice.C_OPEN)
}

ShadeDevice.prototype.stop = function () {

  this.performAction(ShadeDevice.C_STOP)
}

ShadeDevice.prototype.close = function () {

  this.performAction(ShadeDevice.C_CLOSE)
}

/**
 * @param {number} position [0 - 1]
 */
ShadeDevice.prototype.setPosition = function (position) {

  var state = {}

  state[ShadeDevice.C_POSITION] = position
  this.updateState(state)
}

/**
 * @param {number} rotation [0 - 1]
 */
ShadeDevice.prototype.setRotation = function (rotation) {

  var state = {}

  state[ShadeDevice.C_ROTATION] = rotation
  this.updateState(state)
}

/**
 * @param {boolean} [force]
 */
ShadeDevice.prototype.toggleAuto = function (force) {

  var state = {}

  state[ShadeDevice.C_AUTO_MODE] = BasUtil.isBool(force)
    ? force
    : !this._state[ShadeDevice.C_AUTO_MODE]
  this.updateState(state)
}

module.exports = ShadeDevice
