'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('serverStatusCtrl', [
    '$rootScope',
    '$scope',
    'BAS_DEVICE',
    'BAS_CONNECTED_DEVICES',
    'BAS_SPLASH',
    'BasConnectedDevices',
    'close',
    'serverUuid',
    serverStatusCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_DEVICE} BAS_DEVICE
 * @param {BAS_CONNECTED_DEVICES} BAS_CONNECTED_DEVICES
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasConnectedDevices} BasConnectedDevices
 * @param close
 * @param serverUuid
 */
function serverStatusCtrl (
  $rootScope,
  $scope,
  BAS_DEVICE,
  BAS_CONNECTED_DEVICES,
  BAS_SPLASH,
  BasConnectedDevices,
  close,
  serverUuid
) {
  var modal = this

  var _listeners = []

  /**
   * @type {TBasConnectedDevicesState}
   */
  modal.connectedDevices = BasConnectedDevices.get()

  modal.serverUuid = serverUuid

  modal.close = close

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_CONNECTED_DEVICES.EVT_BAS_SERVERS_UPDATED,
      _onServersUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_DEVICE.EVT_SERVER_DEVICE_UPDATED,
      _onServerUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))
  }

  function _onServersUpdated () {

    var _server

    _server = BasConnectedDevices.getServer(serverUuid)

    if (_server) {

      $scope.$applyAsync()

    } else {

      modal.close()
    }
  }

  /**
   * @private
   * @param _event
   * @param {BasServerDevice} basServerDevice
   */
  function _onServerUpdated (_event, basServerDevice) {

    if (
      basServerDevice &&
      basServerDevice.uuid === modal.serverUuid
    ) {
      $scope.$applyAsync()
    }
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
