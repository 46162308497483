'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasOpenCloseDevice', [
    '$rootScope',
    'BAS_API',
    'BAS_OPEN_CLOSE_DEVICE',
    'BAS_ELLIE',
    'BasUtilities',
    basOpenCloseDeviceFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_OPEN_CLOSE_DEVICE} BAS_OPEN_CLOSE_DEVICE
 * @param {BAS_ELLIE} BAS_ELLIE
 * @param {BasUtilities} BasUtilities
 * @returns BasOpenCloseDevice
 */
function basOpenCloseDeviceFactory (
  $rootScope,
  BAS_API,
  BAS_OPEN_CLOSE_DEVICE,
  BAS_ELLIE,
  BasUtilities
) {
  var _CSS_PREFIX = 'bas-open-close--'

  var CSS_HAS_SUBTITLE = _CSS_PREFIX + 'has-subtitle'
  var CSS_HAS_TRIGGER = _CSS_PREFIX + 'has-trigger'
  var CSS_HAS_OPEN = _CSS_PREFIX + 'has-open'
  var CSS_HAS_CLOSE = _CSS_PREFIX + 'has-close'
  var CSS_HAS_STOP = _CSS_PREFIX + 'has-stop'
  var CSS_HAS_STATE = _CSS_PREFIX + 'has-state'
  var CSS_IS_OPENED = _CSS_PREFIX + 'is-opened'
  var CSS_IS_CLOSED = _CSS_PREFIX + 'is-closed'

  /**
   * @constructor
   * @param {OpenCloseDevice} [device]
   * @param {BasRoom} [room]
   */
  function BasOpenCloseDevice (device, room) {

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {number}
     */
    this.type = 0

    /**
     * @type {string}
     */
    this.typeStr = ''

    /**
     * @type {string}
     */
    this.location = ''

    /**
     * @type {boolean}
     */
    this.opened = false

    /**
     * @type {boolean}
     */
    this.closed = false

    /**
     * @type {string}
     */
    this.uiOpenState = ''

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this._resetCss()

    /**
     * @private
     * @type {Array}
     */
    this._deviceListeners = []

    /**
     * @type {?OpenCloseDevice}
     */
    this.device = null

    /**
     * @type {?BasRoom}
     */
    this.room = BasUtil.isObject(room) ? room : null

    this._handleDeviceState = this._onDeviceState.bind(this)

    if (device) this.parse(device)
  }

  /**
   * @param {number} type
   * @returns {string}
   */
  BasOpenCloseDevice.getTypeStr = function (type) {

    switch (type) {
      case BAS_API.OpenCloseDevice.TYPE.DOOR:
        return BasUtilities.translate('open_close_door')
      case BAS_API.OpenCloseDevice.TYPE.WINDOW:
        return BasUtilities.translate('open_close_window')
      case BAS_API.OpenCloseDevice.TYPE.GATE:
        return BasUtilities.translate('open_close_gate')
    }

    return ''
  }

  /**
   * @param {OpenCloseDevice} device
   */
  BasOpenCloseDevice.prototype.parse = function (device) {

    if (device instanceof BAS_API.OpenCloseDevice) {

      this.device = device
      this.uuid = this.device.uuid

      this._setDeviceListeners()
      this.syncDevice()
    }
  }

  /**
   * @param {OpenCloseDevice} [device]
   */
  BasOpenCloseDevice.prototype.syncDevice = function (device) {

    var _device

    /**
     * @type {OpenCloseDevice}
     */
    _device = device || this.device

    if (_device) {

      this._resetCss()

      this.name = _device.name
      this._setType(_device.type)
      this.opened = _device.opened
      this.closed = _device.closed

      if (_device.allowsExecute(BAS_API.OpenCloseDevice.C_TOGGLE)) {

        this.css[CSS_HAS_TRIGGER] = true
      }

      if (_device.allowsExecute(BAS_API.OpenCloseDevice.C_OPEN)) {

        this.css[CSS_HAS_OPEN] = true
      }

      if (_device.allowsExecute(BAS_API.OpenCloseDevice.C_CLOSE)) {

        this.css[CSS_HAS_CLOSE] = true
      }

      if (_device.allowsExecute(BAS_API.OpenCloseDevice.C_STOP)) {

        this.css[CSS_HAS_STOP] = true
      }

      if (_device.allowsRead(BAS_API.OpenCloseDevice.C_OPEN_CLOSE)) {

        this.css[CSS_HAS_STATE] = true
      }

      this._syncOpenState()

      this.updateTranslation()
    }
  }

  BasOpenCloseDevice.prototype.updateTranslation = function () {

    this._syncTypeStr()
    this._syncLocation()
    this._syncUiOpenState()
  }

  /**
   * @private
   * @param {number} type
   */
  BasOpenCloseDevice.prototype._setType = function (type) {

    this.type = type
    this._syncTypeStr()
  }

  BasOpenCloseDevice.prototype._syncTypeStr = function () {

    this.typeStr = BasOpenCloseDevice.getTypeStr(this.type)
  }

  BasOpenCloseDevice.prototype._syncLocation = function () {

    this.location =
      (
        BasUtil.isObject(this.room) &&
        BasUtil.isNEString(this.room.uiTitle)
      )
        ? this.room.uiTitle
        : ''

    this.css[CSS_HAS_SUBTITLE] = !!this.location
  }

  BasOpenCloseDevice.prototype._syncOpenState = function () {

    this.css[CSS_IS_OPENED] = this.opened
    this.css[CSS_IS_CLOSED] = this.closed

    this._syncUiOpenState()
  }

  BasOpenCloseDevice.prototype._syncUiOpenState = function () {

    this.uiOpenState = ' '

    if (this.opened) {

      this.uiOpenState = BasUtilities.translate('open_state')

    } else if (this.closed) {

      this.uiOpenState = BasUtilities.translate('closed')
    }
  }

  /**
   * Creates plugin compatible object of this BasOpenCloseDevice instance.
   *
   * @returns {TBottomWidget}
   */
  BasOpenCloseDevice.prototype.getCordovaPluginObject = function () {

    var pluginObj, button

    pluginObj = {}

    pluginObj[BAS_ELLIE.K_PLUGIN_UUID] = this.uuid
    pluginObj[BAS_ELLIE.K_PLUGIN_TITLE] = this.name

    if (this.css[CSS_HAS_SUBTITLE]) {

      pluginObj[BAS_ELLIE.K_PLUGIN_SUBTITLE] = this.location
    }

    if (this.css[CSS_HAS_STATE]) {

      pluginObj[BAS_ELLIE.K_PLUGIN_STATE] = this.uiOpenState
    }

    if (this.css[CSS_HAS_TRIGGER]) {

      button = {}
      button[BAS_ELLIE.K_PLUGIN_UUID] =
        BAS_OPEN_CLOSE_DEVICE.DEF_TRIGGER_UUID
      button[BAS_ELLIE.K_PLUGIN_BUTTON_TYPE] = 0
      button[BAS_ELLIE.K_PLUGIN_TRIGGER] = 500
      button[BAS_ELLIE.K_PLUGIN_LABELS] = [
        BasUtilities.translate('activate')
      ]

      pluginObj[BAS_ELLIE.K_PLUGIN_TOP_BUTTON_MODELS] = []
      pluginObj[BAS_ELLIE.K_PLUGIN_TOP_BUTTON_MODELS].push(button)
    }

    pluginObj[BAS_ELLIE.K_PLUGIN_BOTTOM_BUTTON_MODELS] = []

    if (this.css[CSS_HAS_CLOSE]) {

      button = {}
      button[BAS_ELLIE.K_PLUGIN_UUID] =
        BAS_OPEN_CLOSE_DEVICE.DEF_CLOSE_UUID
      button[BAS_ELLIE.K_PLUGIN_BUTTON_TYPE] = 0
      button[BAS_ELLIE.K_PLUGIN_LABELS] = [
        BasUtilities.translate('close')
      ]

      pluginObj[BAS_ELLIE.K_PLUGIN_BOTTOM_BUTTON_MODELS].push(button)
    }

    if (this.css[CSS_HAS_STOP]) {

      button = {}
      button[BAS_ELLIE.K_PLUGIN_UUID] =
        BAS_OPEN_CLOSE_DEVICE.DEF_STOP_UUID
      button[BAS_ELLIE.K_PLUGIN_BUTTON_TYPE] = 0
      button[BAS_ELLIE.K_PLUGIN_LABELS] = [
        BasUtilities.translate('stop')
      ]

      pluginObj[BAS_ELLIE.K_PLUGIN_BOTTOM_BUTTON_MODELS].push(button)
    }

    if (this.css[CSS_HAS_OPEN]) {

      button = {}
      button[BAS_ELLIE.K_PLUGIN_UUID] =
        BAS_OPEN_CLOSE_DEVICE.DEF_OPEN_UUID
      button[BAS_ELLIE.K_PLUGIN_BUTTON_TYPE] = 0
      button[BAS_ELLIE.K_PLUGIN_LABELS] = [
        BasUtilities.translate('open')
      ]

      pluginObj[BAS_ELLIE.K_PLUGIN_BOTTOM_BUTTON_MODELS].push(button)
    }

    return pluginObj
  }

  /**
   * Creates plugin compatible object of this BasOpenCloseDevice state.
   *
   * @returns {TBottomWidgetState}
   */
  BasOpenCloseDevice.prototype.getCordovaPluginStateObject = function () {

    var stateObj, button

    stateObj = {}

    stateObj[BAS_ELLIE.K_PLUGIN_UUID] = this.uuid
    stateObj[BAS_ELLIE.K_PLUGIN_BUTTONS] = []

    if (this.css[CSS_HAS_OPEN]) {

      button = {}
      button[BAS_ELLIE.K_PLUGIN_UUID] =
        BAS_OPEN_CLOSE_DEVICE.DEF_OPEN_UUID
      button[BAS_ELLIE.K_PLUGIN_STATE] = this.opened

      stateObj[BAS_ELLIE.K_PLUGIN_BUTTONS].push(button)
    }

    if (this.css[CSS_HAS_CLOSE]) {

      button = {}
      button[BAS_ELLIE.K_PLUGIN_UUID] =
        BAS_OPEN_CLOSE_DEVICE.DEF_CLOSE_UUID
      button[BAS_ELLIE.K_PLUGIN_STATE] = this.closed

      stateObj[BAS_ELLIE.K_PLUGIN_BUTTONS].push(button)
    }

    if (this.css[CSS_HAS_STATE]) {

      stateObj[BAS_ELLIE.K_PLUGIN_LABEL] = this.uiOpenState
    }

    return stateObj
  }

  BasOpenCloseDevice.prototype.onTriggerActive = function () {

    this.toggle(true)
  }

  BasOpenCloseDevice.prototype.onTriggerRelease = function () {

    this.toggle(false)
  }

  BasOpenCloseDevice.prototype.toggle = function (active) {

    if (this.device) this.device.toggle(active)
  }

  BasOpenCloseDevice.prototype.trigger = function (active) {

    if (this.device) this.device.trigger(active)
  }

  BasOpenCloseDevice.prototype.canTrigger = function () {
    return this.device.allowsExecute(BAS_API.OpenCloseDevice.C_TRIGGER)
  }

  BasOpenCloseDevice.prototype.open = function () {

    if (this.device) this.device.open()
  }

  BasOpenCloseDevice.prototype.close = function () {

    if (this.device) this.device.close()
  }

  BasOpenCloseDevice.prototype.stop = function () {

    if (this.device) this.device.stop()
  }

  BasOpenCloseDevice.prototype.hasState = function () {

    return this.css[CSS_HAS_STATE]
  }

  BasOpenCloseDevice.prototype._resetCss = function () {

    this.css[CSS_HAS_SUBTITLE] = false
    this.css[CSS_HAS_TRIGGER] = false
    this.css[CSS_HAS_OPEN] = false
    this.css[CSS_HAS_CLOSE] = false
    this.css[CSS_HAS_STOP] = false
    this.css[CSS_HAS_STATE] = false
    this.css[CSS_IS_OPENED] = false
    this.css[CSS_IS_CLOSED] = false
  }

  BasOpenCloseDevice.prototype._setDeviceListeners = function () {

    this._clearDeviceListeners()

    if (this.device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.OpenCloseDevice.EVT_STATE,
        this._handleDeviceState
      ))
    }
  }

  BasOpenCloseDevice.prototype._onDeviceState = function () {

    this.syncDevice()

    $rootScope.$emit(BAS_OPEN_CLOSE_DEVICE.EVT_OPEN_CLOSE_DEVICE_UPDATED)
  }

  BasOpenCloseDevice.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  BasOpenCloseDevice.prototype.clear = function () {

    this._clearDeviceListeners()
    this.device = null
    this.room = null
  }

  BasOpenCloseDevice.prototype.suspend = function () {

    this._clearDeviceListeners()
  }

  BasOpenCloseDevice.prototype.destroy = function () {

    this.suspend()
    this.clear()
  }

  return BasOpenCloseDevice
}
