'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('deezerLibraryCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_APP_PROFILE',
    'BasLibraryHeader',
    'BasLibraryPage',
    'DeezerManager',
    'BasCurrentAppProfile',
    'BasLibraryDetail',
    'LibraryState',
    deezerLibraryCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param BasLibraryHeader
 * @param BasLibraryPage
 * @param DeezerManager
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param BasLibraryDetail
 * @param {LibraryState} LibraryState
 */
function deezerLibraryCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_APP_PROFILE,
  BasLibraryHeader,
  BasLibraryPage,
  DeezerManager,
  BasCurrentAppProfile,
  BasLibraryDetail,
  LibraryState
) {
  var root = this

  var libraryState = LibraryState.getCurrentState()

  root.BasLibraryPage = BasLibraryPage
  root.BasLibraryHeader = BasLibraryHeader
  root.BasLibraryDetail = BasLibraryDetail
  root.historyType = BAS_APP_PROFILE.K_DEEZER

  /**
   * @type {BasCurrentAppProfileState}
   */
  root.profileSettings = BasCurrentAppProfile.get()

  /**
   * @type {BasLibraryManager}
   */
  root.manager = libraryState.deezerManager

  init()

  function init () {

    var basSource

    $scope.$on('$destroy', onDestroy)

    if (!BasUtil.isObject(root.manager)) {

      root.manager = new DeezerManager(libraryState)
      libraryState.deezerManager = root.manager
    }

    // Initialize service
    root.manager.resume()

    if (
      $uiRouterGlobals.params.id &&
      $uiRouterGlobals.params.type
    ) {
      basSource = LibraryState.getDeezerSource()

      if (basSource) {

        basSource.deezer.getSongInfo($uiRouterGlobals.params.id)
          .then(onSongInfo)
      }

      $uiRouterGlobals.params.id = ''
    }
  }

  function onSongInfo (result) {

    root.manager.selectElement(
      {
        deezer: result
      },
      $uiRouterGlobals.params.type
    )

    $uiRouterGlobals.params.type = ''
  }

  function onDestroy () {

    root.manager.suspend()
  }
}
