'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasDoorPhone', [
    'BAS_API',
    basDoorPhoneFactory
  ])

/**
 * @param BAS_API
 * @returns BasDoorPhone
 */
function basDoorPhoneFactory (
  BAS_API
) {
  /**
   * @constructor
   * @param {DoorPhoneDevice} device
   */
  function BasDoorPhone (device) {

    /**
     * @type {?DoorPhoneDevice}
     */
    this.device = null

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.doorPhoneGateway = ''

    /**
     * SIP flavor
     *
     * @type {string}
     */
    this.subType = ''

    /**
     * @type {string}
     */
    this.directDial = ''

    /**
     * @type {string}
     */
    this.doorPhoneIdentifier = ''

    /**
     * @type {TButton[]}
     */
    this.buttons = []

    /**
     * @type {string[]}
     */
    this.cameras = []

    /**
     * @type {boolean}
     */
    this.enableDoor = false

    if (device) this.parseDoorPhoneDevice(device)
  }

  BasDoorPhone.prototype.parseDoorPhoneDevice = function (device) {

    var length, i

    if (device instanceof BAS_API.DoorPhoneDevice) {

      this.device = device
      this.uuid = this.device.uuid

      this.syncDevice()

      if (BasUtil.isNEArray(this.buttons)) {
        length = this.buttons.length

        for (i = 0; i < length; i++) {
          if (BasUtil.isNumber(this.buttons[i].relay)) {
            this.enableDoor = true
            break
          }
        }
      }
    }
  }

  /**
   * @param {DoorPhoneDevice} [device]
   */
  BasDoorPhone.prototype.syncDevice = function (device) {

    var _device = device || this.device

    if (_device) {

      this.username = _device.username

      this.password = _device.password

      this.enableDoor = _device.enableDoor

      this.directDial = _device.directDial

      this.doorPhoneGateway = _device.doorPhoneGateway

      this.doorPhoneIdentifier = _device.doorPhoneIdentifier

      this.subType = _device.subType

      this.buttons = _device.buttons

      this.cameras = _device.cameras
    }
  }

  /**
   * Clears the device reference
   */
  BasDoorPhone.prototype.clear = function () {

    this.device = null
  }

  return BasDoorPhone
}
