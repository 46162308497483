'use strict'

angular
  .module('basalteApp')
  .controller('lisaIntercomCtrl', [
    'BAS_ROOMS',
    'RoomsHelper',
    'BasSip',
    lisaIntercomCtrl
  ])

/**
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {RoomsHelper} RoomsHelper
 * @param {BasSip} BasSip
 */
function lisaIntercomCtrl (
  BAS_ROOMS,
  RoomsHelper,
  BasSip
) {
  var lisa = this

  lisa.BAS_ROOMS = BAS_ROOMS

  lisa.selectRoom = selectRoom

  /**
   * @param {string} room
   */
  function selectRoom (room) {

    var _basRoom = RoomsHelper.getRoomForId(room)

    if (_basRoom) BasSip.callRoom(_basRoom)
  }
}
