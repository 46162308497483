'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('muteDeviceModalCtrl', [
    '$rootScope',
    '$scope',
    'BAS_VOLUME',
    'BasVolume',
    'close',
    muteDeviceModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_VOLUME} BAS_VOLUME
 * @param {BasVolume} BasVolume
 * @param close
 */
function muteDeviceModalCtrl (
  $rootScope,
  $scope,
  BAS_VOLUME,
  BasVolume,
  close
) {
  var modal = this

  var _listeners = []

  /**
   * @type {TBasVolumeState}
   */
  modal.basVolumeState = BasVolume.get()

  modal.toggleMute = BasVolume.toggleMute
  modal.close = close

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_VOLUME.EVT_VOLUME_CHANGED,
      _syncScope
    ))
  }

  function _syncScope () {

    $scope.$applyAsync()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
