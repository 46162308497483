{
  "12_hr": "12 heures",
  "24_hr": "24 heures",
  "24_hr_time": "horloge 24 heures",
  "about": "Infos",
  "account": "Compte",
  "account_exists": "Compte existant",
  "account_exists_l1": "Ce compte existe déjà.",
  "account_exists_l2": "Veuillez choisir une autre adresse e-mail ou connectez-vous avec celle-ci.",
  "account_not_confirmed": "Compte non confirmé",
  "account_not_confirmed_l1": "Ce compte n'a pas encore été confirmé.",
  "account_not_confirmed_l2": "Veuillez vérifier votre boîte e-mail pour obtenir un code de confirmation.",
  "account_not_confirmed_l3": "Confirmer ce compte?",
  "act_in": "Dans",
  "activate": "Activez",
  "active": "Actif",
  "act_out": "En dehors",
  "act_push": "POUSSEZ",
  "adaptive_brightness": "Luminosité adaptative",
  "add": "Ajouter",
  "add_favourite": "Ajouter un favori",
  "add_group": "Ajouter un groupe",
  "add_home": "Nouvelle maison",
  "address": "Adresse",
  "add_to_fav": "Ajouter aux favoris",
  "add_to_playlist": "Ajouter à la playlist",
  "add_to_queue": "Ajouter à la file d'attente",
  "air_conditioning": "Climatisation",
  "air_pressure": "Pression de l'air",
  "alarm_at": "Réveil à",
  "alarm_missing_txt": "Quelques champs obligatoires manquent.",
  "alarm_not_selected_l1": "Il n'y a pas d'alarme selectionnée.",
  "alarm_not_selected_l2": "Veuillez sélectionner une alarme.",
  "alarms": "Réveils",
  "album": "Album",
  "albums": "Albums",
  "alert": "Attention",
  "all": "Tout",
  "all_off": "Tout éteint",
  "all_off_txt_l1": "Toutes les pièces seront éteintes.",
  "all_off_txt_l2": "Vous êtes sûr de vouloir continuer?",
  "all_on": "Tout allumé",
  "almost_done": "Presque fini",
  "almost_done_txt_l1": "Vous n'avez pas encore de playlist ou de stations radio favori.",
  "almost_done_txt_l2": "Veuillez d'abord ajouter quelques favoris.",
  "already_linked": "Connecté au compte Basalte",
  "already_linked_to_me": "Lié à votre compte Basalte",
  "already_verified": "Déjà vérifié",
  "alt_power": "Allumé/Eteint",
  "alt_user": "Utilisateur",
  "application": "Application",
  "application_version": "Version d'application",
  "app_update_required": "Mise à jour de l'app nécessaire",
  "app_update_required_android_l2": "Veuillez actualiser l'app dans le Play Store.",
  "app_update_required_ios_l2": "Veuillez actualiser l'app dans l'App Store.",
  "app_update_required_l2": "Veuillez actualiser l'app dans le Store.",
  "artist": "Artiste",
  "artists": "Artistes",
  "attention": "Attention!",
  "audio_unavailable": "Système Audio indisponible",
  "audio_zones": "Zones audio",
  "author": "Auteur",
  "back": "retour",
  "basalte_account": "Compte Basalte",
  "basalte_info_l1": "Un compte Basalte vous permet de lier votre maison et de la contrôler en dehors de votre réseau local.",
  "basalte_link_server_l1": "Lier cette maison à votre compte Basalte?",
  "basalte_link_server_l2": "Vous pourrez contrôler cette maison en dehors de votre réseau local.",
  "basalte_unlink_server_l1": "Déconnecter cette maison de votre compte Basalte?",
  "basalte_unlink_server_l2": "Vous ne pourrez plus accéder cette maison en dehors de votre réseau local.",
  "bass": "Graves",
  "BE": "Belgique",
  "beta": "beta",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "Vous pouvez maintenant connecter votre appareil par Bluetooth.",
  "brightness": "Luminosité",
  "browse": "Naviguer",
  "browse_deezer": "Favoris Deezer",
  "browse_library": "Bibliothèque musique",
  "browser_app_update_required_l2": "veuillez recharger la page ou actualiser le serveur.",
  "browse_stations": "Stations radio",
  "buffering": "mise en mémoire tampon",
  "building_building": "Bâtiment",
  "building_garage": "Garage",
  "building_main_house": "Maison principale",
  "building_pool_house": "Poolhouse",
  "building_stables": "Écuries",
  "call_accepted": "Appel accepté",
  "call_declined": "Appel refusé",
  "call_history": "Historique des appels",
  "cameras": "Caméras",
  "cancel": "Annuler",
  "categories": "Catégories",
  "category": "Catégorie",
  "celsius": "Celsius",
  "change_account": "Changer de compte",
  "change_home": "Changer de maison",
  "change_image": "Modifiez l'image",
  "change_password": "Modifiez le mot de passe",
  "change_password_l1": "Vous êtes sur le point de changer votre mot de passe.",
  "change_user": "Changer de profil",
  "channel_number": "Numéro de canal",
  "charts": "Listes de hits",
  "check_for_updates": "Recherchez des mises à jour",
  "checking": "contrôler",
  "checking_for_updates": "À la recherche des mises à jour...",
  "choose_favourite": "Voulez-vous choisir un favori?",
  "choose_home": "Choisissez maison",
  "choose_music": "Voulez-vous choisir un favori?",
  "choose_preset": "Choisissez un type d'ambiance",
  "choose_source": "Voulez-vous choisir une source?",
  "clear": "Effacer",
  "cleared": "Effacé",
  "clear_queue_txt_l1": "La file d'attente sera effacée.",
  "clear_queue_txt_l2": "Voulez-vous continuer?",
  "clear_selection": "Effacer sélection",
  "clear_selection_txt_l1": "La sélection sera effacée.",
  "clear_selection_txt_l2": "Voulez-vous continuer?",
  "close": "Fermer",
  "closed": "Fermée",
  "code": "Code",
  "color": "Couleur",
  "color_temperature": "Température de couleur",
  "color_theme": "Thème couleur",
  "color_white": "Blanc",
  "comfort": "Confort",
  "configure": "Configurer",
  "confirmation_code_sent": "Code de confirmation envoyé",
  "confirmation_code_sent_l1": "Un code de confirmation a été envoyé à l'adresse suivante :",
  "confirm_continue": "Voulez-vous vraiment continuer ?",
  "confirm_overwrite": "Voulez-vous vraiment écraser les données existantes ?",
  "connect": "Se connecter",
  "connected": "Connecté",
  "connecting": "Connecter",
  "connecting_no_active_servers": "Serveur n'est pas en ligne.",
  "connecting_no_integrator_access": "Configuration à distance est désactivée sur ce serveur.",
  "connecting_not_authorized": "Non autorisé à se connecter à ce serveur.",
  "connecting_small": "connecter",
  "connecting_stop_and_retry_text": "Echec de la connexion au serveur. Réessayer dans 10 secondes.",
  "connecting_stop_text": "Echec de la connexion au serveur.",
  "connecting_text": "Connecter...",
  "connecting_text_small": "connecter...",
  "connection_lost": "Pas de connexion",
  "connection_lost_text": "Se reconnecter avec le serveur",
  "connection_unable": "Impossible de connecter avec ce serveur.",
  "connect_offline_server_l1": "Cette maison est connectée, mais semble être hors ligne.",
  "connect_offline_server_l2": "Essayer de connecter quand-même?",
  "connect_server": "Se connecter au serveur",
  "consumption": "Consommation",
  "contact_support": "Contactez support",
  "continue_anyway": "Continuer malgré tout",
  "continue_without_basalte_account": "Continuez sans compte Basalte",
  "cover_art_background": "Couverture de l'Art au fond",
  "current_password": "Mot de passe actuel",
  "current_temperature": "Actuel",
  "custom": "Personnalisé",
  "date": "Date",
  "day": "Jour",
  "day_friday": "Vendredi",
  "day_monday": "Lundi",
  "day_saturday": "Samedi",
  "day_short2_friday": "Ve",
  "day_short2_monday": "Lu",
  "day_short2_saturday": "Sa",
  "day_short2_sunday": "Di",
  "day_short2_thursday": "Je",
  "day_short2_tuesday": "Ma",
  "day_short2_wednesday": "Me",
  "day_sunday": "Dimanche",
  "day_thursday": "Jeudi",
  "day_tuesday": "Mardi",
  "day_wednesday": "Mercredi",
  "debug": "Débug",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "La connexion avec ce profil Deezer sera coupée pour ce lecteur.",
  "deezer_favourite": "Deezer favori",
  "deezer_flow": "Flow",
  "deezer_flow_description": "Votre bande son personelle.",
  "deezer_flow_full": "Deezer Flow",
  "deezer_no_link": "Pas de profil Deezer connecté",
  "deezer_oauth_error": "Erreur d'authentification de Deezer",
  "default_rooms": "Pièces par défaut",
  "default_rooms_hint": "Choisissez au moins une pièce comme pièce par défaut pour le bon fonctionnement du 'in-home streaming'.",
  "default_rooms_info_l1": "Pièces par défaut pour des flux audio des tiers (comme Spotify Connect).",
  "default_rooms_info_l2": "S'il n'y a pas de pièces connectées au flux, activez les pièces nécessaires",
  "default_rooms_spotify_hint": "Pour que Spotify Connect fonctionne correctement, assurez-vous qu'au moins une pièce est sélectionnée comme pièce par défaut.",
  "default_rooms_spotify_warning_l1": "Définissez au moins une pièce par défaut pour tous les flux",
  "default_rooms_spotify_warning_l2": "afin de compléter la configuration",
  "default_rooms_spotify_warning_title": "Spotify Connect est maintenant supporté",
  "default_view": "Page standard",
  "delay": "Retard",
  "delay_seconds_b": "Retard (secondes)",
  "delete": "Effacer",
  "delete_account": "Supprimer le compte",
  "demo_enter": "Mode démo",
  "demo_functionality_warning_txt": "Cette fonctionnalité n'est pas prise en charge en mode démo.",
  "demo_location": "Ghent",
  "demo_server_name": "Démo maison",
  "demo_title": "Démo",
  "demo_warning_txt": "Vous entrez dans le mode de démonstration.",
  "detail": "détail",
  "device": "Appareil",
  "devices": "Appareils",
  "device_settings": "Réglages de l'appareil",
  "device_volume": "Volume de l'appareil",
  "dhcp": "DHCP",
  "direction_east": "Est",
  "direction_east-northeast": "Est-nordest",
  "direction_east-southeast": "Est-sudest",
  "direction_north": "Nord",
  "direction_northeast": "Nordest",
  "direction_north-northeast": "Nord-nordouest",
  "direction_north-northwest": "Nord-nordouest",
  "direction_northwest": "Nordouest",
  "direction_short_east": "E",
  "direction_short_east-northeast": "ENE",
  "direction_short_east-southeast": "ESE",
  "direction_short_north": "N",
  "direction_short_northeast": "NE",
  "direction_short_north-northeast": "NNE",
  "direction_short_north-northwest": "NO",
  "direction_short_northwest": "NW",
  "direction_short_south": "S",
  "direction_short_southeast": "SE",
  "direction_short_south-southeast": "SSE",
  "direction_short_south-southwest": "SSO",
  "direction_short_southwest": "SO",
  "direction_short_west": "O",
  "direction_short_west-northwest": "ONO",
  "direction_short_west-southwest": "OSO",
  "direction_south": "S",
  "direction_southeast": "ZE",
  "direction_south-southeast": "SSE",
  "direction_south-southwest": "SSO",
  "direction_southwest": "SO",
  "direction_west": "O",
  "direction_west-northwest": "ONO",
  "direction_west-southwest": "OSO",
  "disband_group": "Dissoudre le groupe",
  "dns": "DNS",
  "domain": "Domaine",
  "done": "Fini",
  "do_not_disturb": "Ne pas déranger",
  "do_not_disturb_off_l1": "Toutes les notifications sonores sont actuellement activées.",
  "do_not_disturb_on_l1": "Toutes les notifications sonores sont actuellement désactivées.",
  "do_not_show_again": "Ne plus montrer",
  "door_phone": "Interphone",
  "doors": "Ouvrants",
  "down": "En bas",
  "downloading": "télécharger",
  "downloading_updates": "Télécharger des mises à jour",
  "do_you_want_to_continue": "Voulez-vous continuer ?",
  "drag_and_drop_images": "Sélectionnez une image ou déposez-la ici",
  "drag_and_drop_lights": "Faites glisser et déposez les lumières ici",
  "drag_to_reorder": "Glissez pour réorganiser",
  "dsp": "DSP",
  "dsp_configuration": "Configuration DSP",
  "dsp_profile_club": "Club",
  "dsp_profile_custom": "Personnalisé",
  "dsp_profile_flat": "Plat",
  "dsp_profile_jazz": "Jazz",
  "dsp_profile_live": "Live",
  "dsp_profile_pop": "Pop",
  "dsp_profile_rock": "Rock",
  "dsp_shortcut_l1": "Vous êtes sur le point d'ouvrir les paramètres DSP.",
  "echo_cancellation": "Annulation d'écho",
  "echo_limit": "Limite d'écho",
  "edit": "Modifier",
  "edit_dns": "Modifier DNS",
  "edit_gateway": "Modifier passerelle",
  "edit_ip_address": "Adresse IP",
  "edit_subnet_mask": "Modifier masque de sous-réseau",
  "ellie_room": "Pièce Ellie",
  "ellie_system_update": "Update de système Ellie",
  "ellie_system_update_l1": "Ellie doit étré redémarré pour installer l'update de système.",
  "ellie_update": "Ellie update",
  "email": "Adresse e-mail",
  "empty": "vide",
  "empty_queue": "File d'attente est vide",
  "empty_queue_txt_l1": "Pas de chansons dans la file d'attente.",
  "empty_queue_txt_l2": "Veuillez d'abord en ajouter.",
  "empty_song": "Le lecteur s'est arrêté",
  "empty_song_txt_l1": "Fin de la file d'attente.",
  "empty_song_txt_l2": "Cliquez sur \"play\" pour recommencer.",
  "empty_src": "Pas de source selectionnée",
  "empty_src_txt_l1": "Pas de source selectionnée.",
  "empty_src_txt_l2": "Choississez une source.",
  "empty_zone": "Pas de pièce selectionnée",
  "empty_zone_txt_l1": "Pas de pièce selectionnée.",
  "empty_zone_txt_l2": "Choississez une pièce.",
  "energy": "Énergie",
  "energy_meter": "Compteur d'énergie",
  "eq": "EQ",
  "error": "Erreur",
  "external": "Externe",
  "extra_permissions_required": "Certaines fonctionnalités peuvent nécessiter d'autorisations supplémentaires.",
  "ext_src": "Source externe",
  "fahrenheit": "Fahrenheit",
  "failed": "Echoué",
  "fan": "Ventilateur",
  "fan_auto": "Auto",
  "fan_high": "Haut",
  "fan_low": "Bas",
  "fan_medium": "Moyen",
  "fan_off": "Éteint",
  "favourite": "Favori",
  "favourites": "Favoris",
  "favourite_scenes_full": "Les scènes favorites sont complètes",
  "favourite_scenes_full_sub": "Vous pouvez sélectionner au maximum 4 scènes favorites dans une pièce.",
  "favourite_songs": "Chansons favorites",
  "featured_playlists": "Playlists recommandées",
  "floor_attic": "Grenier",
  "floor_basement": "Sous-sol",
  "floor_floor": "Étage",
  "floor_garden": "Jardin",
  "floor_heating": "Plancher chauffant",
  "floor_loft": "Loft",
  "floor_lower": "Etage inférieur",
  "floor_lvl_0": "Rez-de-chaussée",
  "floor_lvl_1": "Premier étage",
  "floor_lvl_2": "Deuxième étage",
  "floor_lvl_3": "Troisième étage",
  "floor_lvl_4": "Quatrième étage",
  "floor_lvl_5": "Cinquième étage",
  "floor_lvl_6": "Sixième étage",
  "floor_lvl_7": "Septième étage",
  "floor_lvl_8": "Huitième étage",
  "floor_lvl_9": "Neuvième étage",
  "floor_lvl_10": "Dixième étage",
  "floor_lvl_11": "Onzième étage",
  "floor_lvl_12": "Douzième étage",
  "floor_lvl_13": "Treizième étage",
  "floor_lvl_14": "Quatorzième étage",
  "floor_lvl_15": "Quinzième étage",
  "floor_lvl_16": "Seizième étage",
  "floor_lvl_17": "Dix-septième étage",
  "floor_lvl_18": "Dix-huitième étage",
  "floor_lvl_19": "Dix-neuvième étage",
  "floor_lvl_20": "Vingtième étage",
  "floor_upper": "Etage supérieur",
  "forgot_password": "Oublié votre mot de passe ?",
  "frequency": "Fréquence",
  "full_screen": "Ecran complet",
  "functions": "Fonctions",
  "gas": "Gaz",
  "gateway": "Passerelle",
  "general": "Général",
  "generic_devices": "Appareils",
  "generic_devices_appliances": "Appareils",
  "generic_devices_artwork": "Œuvre d'art",
  "generic_devices_energy": "Énergie",
  "generic_devices_fire_place": "Cheminée",
  "generic_devices_fountain": "Fontaine",
  "generic_devices_garage": "Garage",
  "generic_devices_garden": "Jardin",
  "generic_devices_irrigation": "Irrigation",
  "generic_devices_pool": "Piscine",
  "generic_devices_security": "Sécurité",
  "generic_devices_socket": "Prise",
  "generic_devices_ventilation": "Ventilation",
  "generic_devices_wellness_spa": "Bien-être & Spa",
  "genre": "Genre",
  "genre_moods": "Genre et ambiances",
  "genres": "Genres",
  "go_to_login_l1": "Connectez-vous ou inscrivez-vous avec un compte Basalte.",
  "go_to_manage_l1": "Gérez votre compte sur Basalte.",
  "group": "Groupe",
  "groups": "Groupes",
  "home": "Home",
  "humidity": "Humidité",
  "hz": "Hz",
  "images": "Images",
  "image_smaller_than_5mb": "Les images doivent être inférieures à 5MB",
  "in": "Dans",
  "incorrect_album": "L'album n'a pas été trouvé",
  "incorrect_artist": "L'artiste n'a pas été trouvé",
  "install": "Installer",
  "installing": "installer",
  "install_update": "Installer la mise à jour",
  "install_updates": "Installer les mises à jour",
  "integrator": "Intégrateur",
  "integrator_access_allow": "Permettez la configuration à distance",
  "integrator_access_ask": "Demandez au propriétaire d'activer la configuration à distance.",
  "integrator_access_disabled": "Configuration à distance est désactivée",
  "intercom": "Interphonie",
  "internal": "Interne",
  "invalid_address": "Adresse non valable",
  "invalid_code": "Code non valable",
  "invalid_code_l1": "Le code de confirmation n'est pas correct.",
  "invalid_code_l2": "Vérifiez si le code est correct ou demandez un nouveau code.",
  "invalid_input": "Entrée non valable",
  "invalid_user": "Utilisateur non valable",
  "invalid_user_l1": "Vérifiez si l'adresse e-mail est valable.",
  "ios_plist_string_NSCameraUsageDescription": "Le caméra peut être utilisé pour prendre des photos des pièces",
  "ios_plist_string_NSLocalNetworkUsageDescription": "L'accès au réseau local peut être utilisé pour trouver des serveurs, des caméras IP, ...",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "La photothèque peut être utilisée pour enregistrer des photos des pièces",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "La photothèque peut être utilisée pour sélectionner des photos des pièces",
  "ip": "IP",
  "ip_address": "Modifier adresse IP",
  "join": "+ Joindre",
  "join_alt": "+ Joindre",
  "keep_awake": "Tenir l'iPad allumé",
  "keep_awake_device": "Tenir l'appareil allumé",
  "kelvin": "Kelvin",
  "Key": "fr_FR",
  "knx_presets": "Préselections KNX",
  "knx_presets_info": "Modifiez les présélections KNX existantes pour jouer autre chose.",
  "language": "Langue",
  "language_system": "Langue du système",
  "last-7-days": "7 derniers jours",
  "last_hour": "Dernière heure",
  "last_room": "Pièce précédente",
  "last_view": "Dernière page",
  "learn_more": "En savoir plus",
  "leave_group": "Sortir du groupe",
  "left": "Gauche",
  "lena_system_update": "Update de système Lena",
  "lena_system_update_l1": "Lena doit étré redémarré pour installer l'update de système.",
  "library": "Bibliothèque",
  "library_empty": "Bibliothèque vide",
  "library_scanning": "Parcourir la bibliothèque",
  "light_accent_light": "Lumière d'accent",
  "light_art_light": "Lumière d'accent",
  "light_cabinet": "Armoire",
  "light_ceiling_mounted_fixture": "Plafonnier",
  "light_chandelier": "Lustre",
  "light_desk": "Bureau",
  "light_down": "Downlight",
  "light_floor_lamp": "Lampadaire",
  "light_lampshade": "Abat-jour",
  "light_led": "Lampe LED",
  "light_led_strip": "Ruban LED",
  "light_mirror": "Miroir",
  "light_mood_light": "Lumière ambiante",
  "light_pendant": "Lampe à suspension",
  "light_reading_light": "Lampe de lecture",
  "lights": "Lumières",
  "lights_off_all": "Éteindre toutes les lumières",
  "light_spot": "Spot",
  "light_table_lamp": "Lampe de table",
  "light_track": "Titre",
  "light_via": "Via",
  "light_wall_mounted_fixture": "Applique murale",
  "link": "Lier",
  "link_deezer_info_l1": "Liez un account Deezer à un flux.",
  "link_deezer_info_l2": "Ceci permet de naviguer dans, et de jouer la bibliothèque Deezer.",
  "linked_homes": "Maisons connectées",
  "linked_projects": "Projets connectés",
  "linked_with": "Connecté au",
  "link_home": "Ouvrir l'app de Basalte sur un appareil local et connectez votre maison dans les paramètres.",
  "link_server": "Se connecter avec un compte Basalte",
  "link_server_full_sub": "Connecter ce projet au compte Basalte suivant ?",
  "link_spotify_info_l1": "Liez un account Spotify à un flux.",
  "link_spotify_info_l2": "Ceci permet de naviguer dans, et de jouer la bibliothèque Spotify.",
  "link_tidal_info_l1": "Liez un account TIDAL à un flux.",
  "link_tidal_info_l2": "Ceci permet de naviguer dans, et de jouer la bibliothèque TIDAL.",
  "lisa_order_hint": "Configurez les pages et les raccourcis.",
  "lisa_system_update": "Update de système Lisa",
  "lisa_system_update_l1": "Lisa doit étré redémarré pour installer l'update de système.",
  "listen_in": "Écouter",
  "liters": "Litres",
  "live_adjustments": "Ajustements en direct",
  "loading": "Chargement en cours…",
  "local": "locale",
  "location": "Emplacement",
  "location_back": "Retour",
  "location_bottom": "Dessous",
  "location_center": "Centre",
  "location_chair": "Chaise",
  "location_corner": "Coin",
  "location_desk": "Bureau",
  "location_east": "Est",
  "location_front": "Devant",
  "location_high": "Haut",
  "location_island": "Île",
  "location_left": "Gauche",
  "location_low": "Bas",
  "location_mid": "Médium",
  "location_niche": "Niche",
  "location_north": "Nord",
  "location_right": "A droite",
  "location_shelves": "Tablettes",
  "location_south": "Sud",
  "location_stove": "Cuisinière",
  "location_table": "Table",
  "location_top": "Haut",
  "location_west": "Ouest",
  "logged_in_as": "Connecté en tant que",
  "log_out": "Déconnecter",
  "log_out_everywhere": "Se déconnecter partout",
  "log_out_everywhere_l1": "Vous êtes sur le point de vous déconnecter de toutes les applications et de tous les appareils.",
  "long-press-save-scene": "Appuyez longuement sur une scène pour la mettre à jour",
  "louver_30": "30 degrés",
  "louver_45": "45 degrés",
  "louver_60": "60 degrés",
  "louver_horizontal": "Horizontal",
  "louver_mode": "Mode persienne",
  "louver_off": "Eteint",
  "louver_swing": "Incliner",
  "louver_vertical": "Vertical",
  "mac_address": "Adresse MAC",
  "manage": "Gérez",
  "manage_account": "Gérer le compte",
  "manual_conn": "Connexion manuelle",
  "manual_text": "Ajoutez l'adresse du serveur souhaité.",
  "max": "Max",
  "menu": "Menu",
  "mf_device": "{count, plural, =0 {Appareil} =1 {Appareil} other {Appareils}}",
  "mf_feels_like": "Se sent comme {value}",
  "mf_minute": "{count} {count, plural, =0 {minute} =1 {minute} other {minutes}}",
  "mf_powered_by": "Alimenté par",
  "mf_second": "{count} {count, plural, =0 {seconde} =1 {seconde} other {secondes}}",
  "mf_server": "{count, plural, =0 {Serveur} =1 {Serveur} other {Serveurs}}",
  "mf_song": "{count} {count, plural, =0 {chanson} =1 {chanson} other {chansons}}",
  "mf_source": "Source: {value}",
  "microphone_gain": "Amplification microphone",
  "min": "Min",
  "mix": "Mix",
  "mixed_sources": "Plusieures sources",
  "mixed_sources_txt_l1": "Ce groupe contient des pièces avec des plusieures sources.",
  "mixed_sources_txt_l2": "Veuillez selectionner une source.",
  "mixes": "Mixes",
  "mixes_small": "mixes",
  "mode": "Mode",
  "mode_auto": "Auto",
  "mode_cooling": "Refroidir",
  "mode_drying": "Sécher",
  "mode_fan_only": "Ventiler",
  "mode_heating": "Chauffer",
  "mode_off": "Eteint",
  "month": "Mois",
  "month_april": "Avril",
  "month_august": "Aout",
  "month_december": "Décembre",
  "month_february": "Février",
  "month_january": "Janvier",
  "month_july": "Juillet",
  "month_june": "Juin",
  "month_march": "Mars",
  "month_may": "Mai",
  "month_november": "Novembre",
  "month_october": "Octobre",
  "month_september": "Septembre",
  "month_short_april": "Avr",
  "month_short_august": "Aout",
  "month_short_december": "Déc",
  "month_short_february": "Févr",
  "month_short_january": "Janv",
  "month_short_july": "Juil",
  "month_short_june": "Juin",
  "month_short_march": "Mars",
  "month_short_may": "Mai",
  "month_short_november": "Nov",
  "month_short_october": "Oct",
  "month_short_september": "Sept",
  "mood": "Ambiance",
  "moods": "Ambiances",
  "music": "Musique",
  "music_choice": "Choix de musique",
  "music_go_to": "Allez à la musique",
  "music_is_active": "Musique en cours",
  "music_is_playing": "Musique en cours",
  "music_off": "éteint musique",
  "music_off_all": "Eteindez toutes les pièces",
  "music_off_all_rooms": "Musique éteinte, toutes les pièces",
  "music_off_current": "Eteindez la pièce actuelle",
  "music_off_current_room": "Musique éteinte, cette pièce",
  "music_off_txt_l1": "Vous allez éteindre une ou toutes les pièces.",
  "music_off_txt_l2": "Vous êtes sûr de voulir continuer?",
  "music_on_current": "Activez la pièce actuelle",
  "music_server": "Serveur de musique",
  "mute_area_on_call": "Couper la musique pendant l'appel",
  "my_favorites": "Mes favoris",
  "my_music": "Ma musique",
  "name": "Nom",
  "nds": "Recherche de serveur",
  "nds_music": "Chercher un serveur de musique",
  "nds_none": "Aucun serveur n'a été trouvé!",
  "nds_none_music": "Pas trouvé de serveurs de musique!",
  "nds_text_l1": "Ceci pourrait prendre quelques secondes...",
  "nds_text_l2": "Assurez-vous que l'appareil soit connecté au réseau Wi-Fi.",
  "network": "Réseau",
  "network_input_invalid": "Tous les champs de saisie doivent être entrés correctement",
  "never": "Jamais",
  "new": "Nouveau",
  "new_alarm": "Nouveau réveil",
  "new_image": "Nouvelle image",
  "new_password": "Nouveau mot de passe",
  "new_password_confirmation": "Confirmez nouveau mot de passe",
  "new_playlist": "Nouvelle playlist...",
  "new_releases": "Nouvelles versions",
  "new_releases_small": "nouvelles versions",
  "new_scene": "Nouvelle ambiance",
  "new_schedule": "Nouveau programme",
  "new_step": "Nouvelle étape",
  "next": "Suivant",
  "next_song": "chanson suivante",
  "no": "Non",
  "no_active_rooms": "Aucune pièce active",
  "no_all_off": "Non, ne pas couper",
  "no_av": "Pas d'entrée AV",
  "no_call_entries": "Pas de données d'appel disponibles",
  "no_configuration": "Pas de configuration disponiblle",
  "no_content_available": "Pas de contenu disponible",
  "no_data": "Pas de données",
  "no_deezer_dis": "Non, ne pas déconnecter profil Deezer",
  "no_devices_capabilities": "Pas d'appareils d'adaptables",
  "no_doors_available": "Pas de portes disponibles",
  "no_favourites": "Pas de favoris",
  "no_keep_it": "Non, gardez ceci",
  "no_lights_capabilities": "Pas de lumières adaptables",
  "no_linked_homes": "Aucune maison connectée n'a été trouvée.",
  "none": "Aucun",
  "no_network": "Pas de connexion réseau",
  "no_preview_available": "Aucun aperçu disponible",
  "no_projects": "Aucun projet trouvé.",
  "no_rooms": "Aucune pièce",
  "no_rooms_available": "Aucune pièce disponible",
  "no_room_selected": "pas de pièce sélectionnée",
  "no_rooms_listening": "Aucune pièce n'écoute cette source",
  "no_scene": "Pas de scène",
  "no_scenes_favourites": "Pas de scènes favorites",
  "no_scenes_txt": "Sélectionnez les scènes pour montrer ici.",
  "no_search_results": "Aucun résultat trouvé",
  "no_servers": "Aucun serveur n'a été trouvé",
  "no_shades_capabilities": "Pas de stores à commander",
  "no_source": "- éteint -",
  "no_sources_available": "Aucune source audio disponible",
  "no_steps": "Cette scène ne contient aucune étape",
  "not_allowed": "Non-autorisé",
  "not_allowed_l1": "Vous n'avez pas la permission",
  "notification": "Notification",
  "notification_permission_missing": "Basalte Home n'a pas d'autorisation d'envoyer des notifications. Vous pouvez activer les notifications dans les paramètres système de votre appareil.",
  "notifications": "Notifications",
  "not_linked": "Ne pas connecté",
  "not_now": "Pas maintenant",
  "not_set": "Pas encore défini",
  "no_users_l1": "Les utilisateurs sont introuvables.",
  "no_users_l2": "Sélectionnez un serveur avec des utilisateurs.",
  "now_playing": "En cours de lecture",
  "number_of_songs": "chanson(s)",
  "off": "Éteint",
  "offline_server": "Le serveur est déconnecté.",
  "offset_minutes": "Décalage (minutes)",
  "ok": "OK",
  "on": "Allumé",
  "onix_black": "Noir",
  "online": "en ligne",
  "only_png_jpeg_supported": "Seules les images PNG et JPEG sont supportées",
  "open": "Ouvrir",
  "open_close_door": "Porte",
  "open_close_gate": "Porte",
  "open_close_window": "Fenêtre",
  "open_settings": "Ouvrir les paramètres",
  "open_spotify": "Ouvrir Spotify",
  "open_spotify_l1": "Vous êtes sur le point d'ouvrir l'app Spotify.",
  "open_state": "Ouvert",
  "or": "Ou",
  "order_cameras_by_holding_and_draging": "Entraînez les caméras dans l'ordre souhaité.",
  "other": "Autres",
  "out": "En dehors",
  "page_order": "Ordre de la page/des tuiles",
  "pair": "Coupler",
  "password": "Mot de passe",
  "password_changed": "Mot de passe modifié",
  "password_confirmation": "Confirmation mot de passe",
  "password_requirements": "Votre mot de passe doit comporter au moins 8 caractères et au moins une lettre majuscule, une lettre minuscule et un chiffre.",
  "permission_denied": "Autorisation refusée",
  "permission_denied_go_to_settings_l1": "Cette fonction nécessite une (des) autorisation(s) pour fonctionner correctement.",
  "permission_denied_go_to_settings_l2": "Donnez autorisation(s) pour cette application dans les paramètres de votre appareil afin d'activer toutes les fonctionnalités.",
  "photo_from_camera": "Prenez une photo",
  "photo_from_library": "Sélectionnez une image dans la bibliothèque",
  "pick_room_function": "Sélectionnez une pièce et une fonction",
  "play": "Play",
  "playback_gain": "Amplification de lecture",
  "playing_in": "joue maintenant dans",
  "playlist": "Playlist",
  "playlists": "Playlists",
  "playlists_itunes": "Playlists iTunes",
  "playlists_l": "playlist",
  "playlists_local": "Playlists locales",
  "playlists_shared": "Playlists partagées",
  "play_next": "Jouer comme suivante",
  "play_now": "Joue maintenant",
  "please_try_a_different_image": "Veuillez essayer une autre image",
  "please_try_again": "Veuillez réessayer",
  "popular": "Populaire",
  "position": "Position",
  "precipitation": "Précipitation",
  "previous": "Précédent",
  "problems_continue_integrator": "Si vous continuez à rencontrer des problèmes, veuillez contacter votre installateur.",
  "project": "Projet",
  "project_image": "Image de projet",
  "projects_network_error": "Impossible de charger les projets en raison d'une erreur de réseau.",
  "proximity_activation": "Activation de proximité",
  "proximity_far": "Loin",
  "proximity_medium": "Moyen",
  "proximity_near": "Près",
  "queue": "File d'attente",
  "radio": "Radio",
  "radio_error": "Erreur suite à la récupération des stations de radio",
  "radio_stations": "Stations radio",
  "radio_stations_other": "Autres Stations",
  "recently_played": "Récemment joué",
  "related_artists": "Artistes liés",
  "reload": "Recharger",
  "remember_me": "Rappelez-moi",
  "remote": "À distance",
  "remote_ellie_intercom_outdated": "L'Ellie à distance  à besoin d'une mise à jour.",
  "remote_ellie_intercom_outdated_l1": "L'Ellie que vous essayez d' appeler à besoin d'une mise à jour.",
  "remote_ellie_intercom_outdated_l2": "Essayez de nouveau après la mise en jour.",
  "remove": "Effacer",
  "remove_alarm": "Effacer alarme",
  "remove_basalte_favourite": "Supprimer favori Basalte",
  "remove_deezer_favourite": "Supprimer favori Deezer",
  "remove_from_fav": "Effacer des favoris",
  "remove_schedule": "Enlever le programme",
  "remove_spotify_favourite": "Supprimer favori Spotify",
  "remove_spotify_preset": "Supprimer paramètres Spotify",
  "remove_tidal_favourite": "Supprimer favori TIDAL",
  "remove_txt_l1": "Cet article est effacé.",
  "remove_txt_l2": "Voulez-vous continuer?",
  "rename": "renommer",
  "repeat": "Répéter",
  "replace_queue": "Remplacer file d'attente",
  "resend_code": "Renvoyer le code",
  "resend_confirmation_code": "Renvoyer le code de confirmation",
  "reset_image": "Réinitialiser l'image",
  "reset_password": "Réinitialiser le mot de passe",
  "reset_password_l1": "Êtes-vous sûr de vouloir réinitialiser votre mot de passe ?",
  "restart": "Redémarrer",
  "restart_app": "Redémarrer l’appli",
  "restart_app_q": "Redémarrer l’appli?",
  "restarting_server": "Redémarrage du serveur",
  "restart_music_server": "Redémarrez le serveur de musique",
  "restart_music_server_txt_l1": "Vous êtes sur le point de redémarrer le serveur de musique.",
  "restart_server": "Redémarrer le serveur",
  "restart_server_txt_l1": "Vous êtes sur le point de redémarrer le serveur.",
  "restart_server_txt_l2": "L'application rétablira la connexion automatiquement.",
  "retrieving": "Récupération en cours…",
  "retrieving_users_stop_text": "Recherche utilisateurs échouée, serveur correct?",
  "retrieving_users_text": "Recherche utilisateurs...",
  "right": "Droit",
  "room": "Pièce",
  "room_attic": "Grenier",
  "room_ball_room": "Salle de bal",
  "room_bar": "Bar",
  "room_basement": "Sous-sol",
  "room_bathroom": "Salle de bain",
  "room_bedroom": "Chambre",
  "room_corridor": "Couloir",
  "room_dining_room": "Salle à manger",
  "room_dressing_room": "Dressing",
  "room_driveway": "Allée",
  "room_entry": "Entrée",
  "room_family_room": "Chambre familiale",
  "room_foyer": "Foyer",
  "room_garage": "Garage",
  "room_garden": "Jardin",
  "room_guest_room": "Chambre d'hôtes",
  "room_gym": "Salle de gym",
  "room_hallway": "Hall d'entrée",
  "room_hobby_room": "Salle de loisirs",
  "room_home_cinema": "Home cinéma",
  "room_home_office": "Bureau",
  "room_images": "Images des pièces",
  "room_intercom_lost": "Connexion interrompue",
  "room_intercom_lost_sub": "La connexion est coupée, essayez à nouveau",
  "room_intercom_unavailable": "Pièce non disponible",
  "room_intercom_unavailable_sub": "Cette salle est actuellement indisponible",
  "room_intercom_unreachable": "Pièce injoignable",
  "room_intercom_unreachable_sub": "Cette salle est actuellement injoignable",
  "room_kitchen": "Cuisine",
  "room_laundry_room": "Buanderie",
  "room_library": "Bibliothèque",
  "room_living_room": "Salon",
  "room_master_bedroom": "Chambre maître",
  "room_media_room": "Salle des médias",
  "room_meeting_room": "Salle de réunion",
  "room_nook": "Coin",
  "room_nursery": "Chambre d'enfants",
  "room_pantry": "Garde-manger",
  "room_parking_place": "Place de parking",
  "room_patio": "Patio",
  "room_pool": "Piscine",
  "room_pool_house": "Pool house",
  "room_rack": "Rack",
  "room_room": "Pièce",
  "rooms": "Pièces",
  "room_scene_no_functions": "Cette pièce n'a pas de fonctions",
  "room_settings": "Paramètres de la pièce",
  "room_spa": "Spa",
  "room_stable": "Stable",
  "room_staircase": "Escalier",
  "room_terrace": "Terrasse",
  "room_toilet": "Toilettes",
  "room_utility_room": "Buanderie",
  "room_wine_cellar": "Cave à vin",
  "room_workshop": "Atelier",
  "rotation": "Rotation",
  "save": "Enregistrer",
  "save_as": "enregistrer comme",
  "save_basalte_favourite": "Enregistrer favori Basalte",
  "save_changes": "Enregistrer les modifications",
  "save_changes_l1": "Certains changements n'ont pas été pris en compte",
  "save_changes_l2": "Voulez-vous les enregistrer maintenant?",
  "save_deezer_favourite": "Enregistrer favori Deezer",
  "save_spotify_favourite": "Enregistrer favori Spotify",
  "save_spotify_preset": "Enregistrer paramètres Spotify",
  "save_tidal_favourite": "Enregistrer favori TIDAL",
  "scene": "Ambiance",
  "scene_activated": "Scène activée",
  "scene_away": "Absent",
  "scene_cooking": "Cuisiner",
  "scene_dinner": "Diner",
  "scene_error": "Erreur de scène",
  "scene_evening": "Soirée",
  "scene_good_morning": "Bonjour",
  "scene_good_night": "Bonne nuit",
  "scene_guests": "Hôtes",
  "scene_home": "À la maison",
  "scene_image_error": "Erreur d'image de scène",
  "scene_movie": "Film",
  "scene_not_available": "Pas de scènes disponibles.",
  "scene_not_selected_l1": "Aucune ambiance sélectionnée.",
  "scene_not_selected_l2": "Merci de sélectionner une ambiance.",
  "scene_party": "Fête",
  "scene_relax": "Relax",
  "scenes": "Ambiances",
  "scene_select_or_new": "Veuillez sélectionner une ambiance ou créez une nouvelle.",
  "scene_vacation": "Vacances",
  "scene_welcome": "Bienvenue",
  "scene_workout": "Entraînement",
  "schedule": "Minuterie",
  "schedule_at": "Programmer à",
  "schedule_not_selected_l1": "Aucun programme sélectionné.",
  "schedule_not_selected_l2": "Veuillez sélectionner un programme.",
  "scheduler": "Planificateur",
  "schedules": "Programmes",
  "schedule_select_or_new": "Veuillez sélectionner un programme ou créez un nouveau.",
  "screen_orientation": "Orientation de l'écran",
  "search": "Chercher",
  "search_again": "Chercher de nouveau",
  "search_ph": "chercher artiste, chanson ou album...",
  "search_result": "Chercher les résultats pour",
  "security": "Sécurité",
  "select_admin": "Sélectionnez un profil d'administrateur pour continuer le processus de mise à jour",
  "select_home": "Choisissez votre maison",
  "selection": "Sélection",
  "selection_all": "sélectionner tout",
  "select_photo": "Sélectionnez image",
  "select_server": "Sélectionnez un serveur",
  "select_user": "Sélectionnez un profil et connectez-vous",
  "serial_number": "Numéro de série",
  "server": "Serveur",
  "server_address": "Adresse du serveur",
  "server_basalte_cloud_no_connection": "Le serveur ne peut pas se connecter avec le Basalte Cloud.",
  "server_exists_already": "Le serveur existe déjà!",
  "server_mac": "Adresse MAC du serveur",
  "servers": "Serveurs",
  "server_status": "état du serveur",
  "server_update_info_l2": "veuillez actualiser le serveur Basalte à la dernière version.",
  "server_update_required": "Mise à jour du serveur nécessitée",
  "server_version": "Version du serveur",
  "set": "Régler",
  "set_new_password": "Réinitialisez le mot de passe",
  "setpoint": "Valeur de consigne",
  "settings": "Paramètres",
  "shade_blind": "Rideau",
  "shade_center": "Centre",
  "shade_curtain": "Rideau",
  "shade_left": "Gauche",
  "shade_pool_shutter": "Couverture piscine",
  "shade_right": "Droite",
  "shade_roman_blind": "Rideau pliable",
  "shades": "Stores",
  "shade_shade": "Store",
  "shade_shutter": "Persienne",
  "shade_solar_protection": "Stores",
  "shade_venetian_blind": "Stores venetiens",
  "shade_vertical_blind": "Volets roulants",
  "share": "partager",
  "shared_playlist": "Playlist partagée",
  "show_album": "Montrer album",
  "show_artist": "Montrer artiste",
  "show_background_art": "Afficher les images d'arrière-plan",
  "show_more": "Montrer plus…",
  "show_status_bar": "Montrer barre d'état de l'iPad",
  "show_status_bar_device": "Montrer barre d'état",
  "sign_in": "Connectez-vous",
  "sign_in_info_tidal_l1": "Entrez vos justificatifs TIDAL",
  "sign_up": "Enrégistrez",
  "single_room": "Pièce",
  "sleep": "Mise en veille de l'écran",
  "software_update": "Actualisation du logiciel",
  "something_went_wrong": "Il y a dû avoir un problème",
  "songs": "Chansons",
  "sonos": "Sonos",
  "sonos_unreachable": "Impossible de trouver Sonos",
  "sonos_unreachable_txt_l1": "Vérifiez que votre appareil est branché et connecté.",
  "sound_settings": "Réglages sonores",
  "source": "Source",
  "sources": "Sources",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Assurez-vous que le Core serveur et l'appareil avec l'application Spotify sont sur le même réseau.",
  "spotify_connect_instructions_2": "Sélectionnez le flux du Core serveur du sélecteur d'appareilage dans l'application Spotify.",
  "spotify_connect_instructions_body": "Écoutez sur vos enceintes ou votre téléviseur, et utilisez l'appli Spotify comme une télécommande.",
  "spotify_connect_instructions_title": "Prêt à monter le son?",
  "spotify_disconnect_txt": "Le compte Spotify sera déconnecté de cet appareil.",
  "spotify_free_user_txt": "Un abonnement Spotify Premium est requis",
  "spotify_no_link": "Aucun compte Spotify n'est déjà lié",
  "spotify_oauth_error": "Erreur d'authentification de Spotify",
  "spotify_preset_error": "Erreur Spotify",
  "spotify_preset_error_l1": "Impossible de charger les paramètres Spotify.",
  "spotify_preset_error_l2": "Assurez-vous qu'un compte Spotify est déjà connecté à ce flux.",
  "spotify_presets": "Paramètres Spotify",
  "start": "Début",
  "start_page": "Page d'accueil",
  "start_page_customisation": "Personnalisation de la page d'accueil",
  "start_time_date": "Heure et date",
  "startup_view": "Page d'acceuil",
  "startup_volume": "Volume de démarrage",
  "start_weather": "Informations météo",
  "state_active": "Actif",
  "state_armed": "Armé",
  "state_closed": "Fermé",
  "state_disabled": "Désactivé",
  "state_disarmed": "Désarmé",
  "state_down": "En bas",
  "state_enabled": "Activé",
  "state_false": "Faux",
  "state_free": "Libre",
  "state_high": "Haut",
  "state_in": "Dedans",
  "state_inactive": "Inactif",
  "state_left": "Gauche",
  "state_low": "Bas",
  "state_occupied": "Occupé",
  "state_off": "Éteint",
  "state_on": "Allumé",
  "state_opened": "Ouvert",
  "state_out": "Dehors",
  "state_right": "Droit",
  "state_started": "Démarré",
  "state_stopped": "Arrêté",
  "state_true": "Vrai",
  "state_up": "en haut",
  "static": "Statique",
  "stations": "Chaînes",
  "status": "Statut",
  "stop": "Arrêter",
  "stream": "Flux",
  "streaming": "Streaming",
  "streaming_services": "Services de streaming",
  "streams": "Flux",
  "streams_beta": "Flux (beta)",
  "subnet_mask": "Masque de sous-réseau",
  "sun": "Soleil",
  "sunrise": "Aube",
  "sunset": "Crépuscule",
  "swipe_to_pan": "Glissez pour dérouler",
  "switch_server": "Changer de serveur",
  "synchronise": "Scan maintenant",
  "system": "Système",
  "system_setting": "Paramétrage du système",
  "temperature": "Température",
  "temperature_unit": "L'unité de température",
  "theme": "Thème",
  "thermostat": "Thermostat",
  "this_cannot_be_undone": "Elle est irréversible.",
  "this_week": "Cette semaine",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "Cet account TIDAL est déconnecté pour ce joueur.",
  "tidal_legacy_auth": "Connexion TIDAL requise",
  "tidal_legacy_auth_l1": "Pour continuer à utiliser TIDAL, veuillez vous reconnecter.",
  "tidal_no_link": "Aucun compte TIDAL n'est déjà lié",
  "tidal_oauth_error": "Erreur d'authentification de TIDAL",
  "tidal_rising": "TIDAL Rising",
  "time": "Temps",
  "time_format": "Format de l'heure",
  "timer": "Minuterie",
  "timers": "Minuteries",
  "title": "Titre",
  "today": "Aujourd'hui",
  "tomorrow": "Demain",
  "top_tracks": "Chansons favories",
  "treble": "Aigus",
  "try_again": "Re-essayer",
  "try_again_later": "Réessayez plus tard",
  "try_again_later_or_contact_support": "Veuillez réessayer plus tard ou contactez le support",
  "turned_off": "Éteint",
  "turned_on": "Allumé",
  "turn_off": "Éteindre",
  "turn_on": "Lancer",
  "turn_on_default_rooms": "Activer les pièces par défaut pour cette source ?",
  "turn_on_music": "Activer la musique",
  "turn_on_tv": "Allume la télé",
  "tv": "TV",
  "tv_off": "TV éteinte",
  "tv_off_current_room": "TV éteinte, cette pièce",
  "unavailable": "Indisponible",
  "unavailable_src": "Source non disponible",
  "unavailable_src_txt": "La source actuelle n'est pas disponible.",
  "ungroup": "Dissocier",
  "unknown": "Inconnu",
  "unknown_account": "Compte inconnu",
  "unknown_account_l1": "Cette adresse e-mail n'est pas lié à un compte Basalte.",
  "unknown_av": "Entrée AV inconnue",
  "unknown_src": "Source inconnue",
  "unknown_src_txt": "Source inconnue pour ce serveur.",
  "unlink": "Déconnectez",
  "unlink_server": "Déconnectez du compte Basalte",
  "unlink_server_full_sub": "Déconnectez ce projet du compte Basalte? Il ne sera plus disponible en dehors de votre réseau local.",
  "unreachable": "Inaccessible",
  "unshare": "ne plus partager",
  "unsupported_browser": "Le navigateur n'est pas supporté",
  "unsupported_browser_l1": "Du comportement inattendu est possible.",
  "unsupported_browser_l2": "Installez Chrome ou Safari pour une meilleure expérience d'utilisateur.",
  "unsupported_browser_live": "Ce navigateur ne prend pas en charge le compte Basalte. Essayez une nouvelle version de Chrome ou Safari.",
  "up": "Vers le haut",
  "update": "Mise à jour",
  "update_available": "Mise à jour",
  "update_available_full": "Mise à jour disponible",
  "update_info_l1": "Afin de garantir la meilleure expérience,",
  "update_library": "Scan la bibliothèque de musique de nouveau",
  "update_music_server": "Mettre à jour le serveur de musique",
  "update_scene": "Mettre à jour la scène",
  "update_scene_l1": "Enregistrer l'état actuel dans cette scène.",
  "update_server": "Mise à jour du serveur",
  "updating_server": "Mise à jour du serveur",
  "updating_server_info": "Ne pas éteindre le serveur",
  "up_to_date": "À jour",
  "username": "Nom d'utilisateur",
  "uv": "UV",
  "value_invalid": "La valeur spécifiée n'est pas valide",
  "verify": "Vérifiez",
  "verifying": "vérifier",
  "version": "Version",
  "video_background": "Arrière-plan vidéo",
  "video_go_to": "Allez à la TV",
  "video_is_active": "TV en cours",
  "video_is_playing": "Vidéo en cours",
  "video_unavailable": "TV ou audio indisponible",
  "volume": "Volume",
  "warning": "Alerte",
  "water": "l'Eau",
  "weather": "La météo",
  "weather_daily_data_unavailable": "Il n'y a actuellement aucune information quotidienne météorologique disponible",
  "weather_hourly_data_unavailable": "Il n'y a actuellement aucune information horaire météorologique disponible",
  "weather_information": "Information météorologique",
  "week": "Semaine",
  "weekly": "Hebdomadaire",
  "what": "Qu'est-ce que",
  "what_music": "Quelle musique",
  "when": "Quand",
  "where": "Où",
  "who": "Qui",
  "wind": "Vent",
  "wrong_password": "Mot de passe incorrect",
  "wrong_password_txt": "Le mot de passe entré ne correspond pas au mot de passe utilisateur",
  "wrong_username_and_or_password": "Nom d'utilisateur et/ou mot de passe inconnus",
  "year": "Année",
  "yes": "Oui",
  "yes_all_off": "Oui, éteindre tout",
  "yes_cur_off": "Oui, éteindre",
  "yes_deezer_dis": "Oui, déconnecter profil Deezer",
  "yes_delete_it": "Oui, effacer",
  "yesterday": "Hier",
  "zone": "Pièce",
  "zones": "Pièces"
}
