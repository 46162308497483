'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasMessageHelper', [
    'STATES',
    'BAS_MESSAGE',
    'BAS_ROOM',
    'BAS_SOURCE',
    'PLAYER_STATE',
    'CurrentBasCore',
    'CurrentRoom',
    'SourcesHelper',
    'PlayerStateHelper',
    BasMessageHelper
  ])

/**
 * @typedef {Object} TBasMessageData
 * @property {?Object} img
 * @property {string} txtMsgTitle
 * @property {string} txtMsgLine1
 * @property {string} txtMsgLine2
 * @property {string} txtMsgLine3
 * @property {string} txtAction
 * @property {string} txtAction2
 * @property {string} txtActionOr
 * @property {boolean} hasThirdMsgLine
 * @property {Object<string, boolean>} css
 */

/**
 * @constructor
 * @param {STATES} STATES
 * @param {BAS_MESSAGE} BAS_MESSAGE
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {PLAYER_STATE} PLAYER_STATE
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 * @param {SourcesHelper} SourcesHelper
 * @param {PlayerStateHelper} PlayerStateHelper
 */
function BasMessageHelper (
  STATES,
  BAS_MESSAGE,
  BAS_ROOM,
  BAS_SOURCE,
  PLAYER_STATE,
  CurrentBasCore,
  CurrentRoom,
  SourcesHelper,
  PlayerStateHelper
) {

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  this.getMessageType = getMessageType
  this.handleMessageAction = handleMessageAction

  /**
   * @param {?BasRoom} basRoom
   * @param {number} messageType
   * @param {boolean} isVideoContext
   * @param {boolean} [isSecondAction = false]
   */
  function handleMessageAction (
    basRoom,
    messageType,
    isVideoContext,
    isSecondAction
  ) {
    var music, video, basSource, _singleAudioRoom, _compatibleSources
    var _singleSourceId, _hasSingleSource, _isSecondAction

    _isSecondAction = BasUtil.isBool(isSecondAction)
      ? isSecondAction
      : false

    _singleAudioRoom = ''
    _hasSingleSource = false

    music = _getBasRoomMusic(basRoom)
    video = _getBasRoomVideo(basRoom)

    basSource = _getMusicBasSource(basRoom)

    if (
      basRoom &&
      (
        basRoom.hasAVMusic() ||
        basRoom.hasAVVideo()
      )
    ) {

      // Get compatible sources of OTHER context
      _compatibleSources = isVideoContext
        ? video
          ? video.getCompatibleSources()
          : null
        : music
          ? music.getCompatibleSources()
          : null

      if (Array.isArray(_compatibleSources)) {

        _hasSingleSource = _compatibleSources.length === 1
        _singleSourceId = _hasSingleSource
          ? _compatibleSources[0]
          : null
      }

    } else if (CurrentBasCore.hasCore()) {

      _singleAudioRoom = currentBasCoreState.core.core.singleAudioRoomId
      _hasSingleSource = currentBasCoreState.core.core.hasSingleSource()

      if (_hasSingleSource) {

        _singleSourceId = currentBasCoreState.core.core.singleSourceId
      }
    }

    switch (messageType) {
      case BAS_MESSAGE.MESSAGE_QUEUE_END:

        if (basSource) basSource.play()

        break
      case BAS_MESSAGE.MESSAGE_QUEUE_EMPTY:

        if (music) {

          if (music.type === BAS_ROOM.MUSIC_T_SONOS) {

            CurrentRoom.go(STATES.MUSIC_FAVOURITES)

          } else {

            CurrentRoom.go(STATES.MUSIC_LIBRARY)
          }
        }

        break
      case BAS_MESSAGE.MESSAGE_FAVOURITES_EMPTY:

        if (music) {

          if (basSource) {

            if (basSource.type === BAS_SOURCE.T_SONOS) {

              // Do nothing

            } else if (
              basSource.type === BAS_SOURCE.T_ASANO ||
              basSource.type === BAS_SOURCE.T_PLAYER ||
              basSource.type === BAS_SOURCE.T_BARP
            ) {

              CurrentRoom.go(STATES.MUSIC_LIBRARY)

            } else {

              _setDefaultSourceAndGoToLibrary()
            }

          } else {

            _setDefaultSourceAndGoToLibrary()
          }
        }

        break
      case BAS_MESSAGE.MESSAGE_SOURCE_EMPTY:
      case BAS_MESSAGE.MESSAGE_NO_AV:
      case BAS_MESSAGE.MESSAGE_UNKNOWN_AV:
      case BAS_MESSAGE.MESSAGE_SOURCE_UNKNOWN:
      case BAS_MESSAGE.MESSAGE_SOURCE_UNAVAILABLE:

        CurrentRoom.go(
          isVideoContext
            ? STATES.VIDEO_SOURCES
            : STATES.MUSIC_SOURCES
        )

        break
      case BAS_MESSAGE.MESSAGE_ZONE_EMPTY:

        // Check for single room
        if (_singleAudioRoom) {

          CurrentBasCore.setLastRoomId(_singleAudioRoom)

          _selectSingleSource()

        } else {

          CurrentRoom.go(STATES.ROOMS)
        }

        break
      case BAS_MESSAGE.MESSAGE_TURN_ON:

        _selectSingleSource()

        break
      case BAS_MESSAGE.MESSAGE_VIDEO_IN_NON_VIDEO_CTX_TURN_ON:
      case BAS_MESSAGE.MESSAGE_AUDIO_IN_NON_AUDIO_CTX_TURN_ON:

        if (_isSecondAction) {

          _selectSingleSource()

        } else {

          CurrentRoom.go(
            isVideoContext
              ? STATES.MUSIC_PLAYER
              : STATES.VIDEO_PLAYER
          )
        }

        break
      case BAS_MESSAGE.MESSAGE_VIDEO_IN_NON_VIDEO_CTX:

        CurrentRoom.go(STATES.VIDEO_PLAYER)

        break
      case BAS_MESSAGE.MESSAGE_AUDIO_IN_NON_AUDIO_CTX:

        CurrentRoom.go(STATES.MUSIC_PLAYER)

        break

      case BAS_MESSAGE.MESSAGE_NO_ROOMS_LISTENING:

        if (basSource && basSource.canToggleOn) {

          if (basRoom && basRoom.music) {

            basRoom.music.toggle(true)
          }

        } else {

          CurrentRoom.go(STATES.ROOMS)
        }

        break
    }

    function _setDefaultSourceAndGoToLibrary () {

      var newBasSource, newBasSourceId

      // Default source
      newBasSource = currentBasCoreState.core.getDefaultSource()

      if (newBasSource) {

        newBasSourceId = newBasSource.getId()

        if (!isVideoContext && music) {

          music.setSource(newBasSourceId)
        }
        CurrentRoom.go(STATES.MUSIC_LIBRARY)

      } else {

        // First source
        newBasSource = SourcesHelper.getFirstAudioPlayer(basRoom)

        if (newBasSource) {

          newBasSourceId = newBasSource.getId()

          if (!isVideoContext && music) {

            music.setSource(newBasSourceId)
          }
          CurrentRoom.go(STATES.MUSIC_LIBRARY)
        }
      }
    }

    function _selectSingleSource () {

      if (_hasSingleSource) {

        if (isVideoContext) {

          if (video) video.setSource(_singleSourceId)

        } else {

          if (music) music.setSource(_singleSourceId)
        }
      }
    }
  }

  /**
   * @param {?BasRoom} basRoom
   * @param {boolean} isVideoContext
   * @returns {number}
   */
  function getMessageType (
    basRoom,
    isVideoContext
  ) {
    var currentPlayerState, otherPlayerState
    var curBasSource, music, video, compatibleSources, hasSingleSource

    music = _getBasRoomMusic(basRoom)
    video = _getBasRoomVideo(basRoom)

    if (
      basRoom &&
      (
        basRoom.hasAVMusic() ||
        basRoom.hasAVVideo()
      )
    ) {

      compatibleSources = isVideoContext
        ? video && video.getCompatibleSources
          ? video.getCompatibleSources()
          : null
        : music && music.getCompatibleSources
          ? music.getCompatibleSources()
          : null

      hasSingleSource = (
        Array.isArray(compatibleSources) &&
        compatibleSources.length === 1
      )

    } else if (CurrentBasCore.hasCore()) {

      hasSingleSource = currentBasCoreState.core.core.hasSingleSource()
    }

    if (basRoom && basRoom.getCurrentBasSource) {

      curBasSource = basRoom.getCurrentBasSource()

      // Get player state for current context
      currentPlayerState = PlayerStateHelper.getPlayerState(
        basRoom,
        isVideoContext
      )

      // If a source is selected (either video or audio) and the room has both
      //  music and video, check context/sourceType mismatch
      if (basRoom.hasMusic() && basRoom.hasVideo() && curBasSource) {

        // Get player state from the other context
        otherPlayerState = PlayerStateHelper.getPlayerState(
          basRoom,
          !isVideoContext
        )

        if (
          // Context does not match source type
          isVideoContext !== curBasSource.isVideoSource &&
          // The other context is not off, does not have an empty or
          //  unknown source
          otherPlayerState !== PLAYER_STATE.OFF &&
          otherPlayerState !== PLAYER_STATE.SOURCE_EMPTY &&
          otherPlayerState !== PLAYER_STATE.SOURCE_UNKNOWN &&
          // The current context is available
          currentPlayerState !== PLAYER_STATE.VIDEO_UNAVAILABLE &&
          currentPlayerState !== PLAYER_STATE.MUSIC_UNAVAILABLE
        ) {

          return isVideoContext
            ? hasSingleSource
              ? BAS_MESSAGE.MESSAGE_AUDIO_IN_NON_AUDIO_CTX_TURN_ON
              : BAS_MESSAGE.MESSAGE_AUDIO_IN_NON_AUDIO_CTX
            : hasSingleSource
              ? BAS_MESSAGE.MESSAGE_VIDEO_IN_NON_VIDEO_CTX_TURN_ON
              : BAS_MESSAGE.MESSAGE_VIDEO_IN_NON_VIDEO_CTX
        }
      }

      if (basRoom.isInactiveSourceGroup()) {

        return BAS_MESSAGE.MESSAGE_NO_ROOMS_LISTENING
      }

      if (
        hasSingleSource && (
          currentPlayerState === PLAYER_STATE.SOURCE_EMPTY ||
          currentPlayerState === PLAYER_STATE.SOURCE_UNKNOWN
        )
      ) {
        return BAS_MESSAGE.MESSAGE_TURN_ON
      }

      return _getMessageTypeForPlayerState(currentPlayerState)
    }

    return BAS_MESSAGE.MESSAGE_ZONE_EMPTY
  }

  /**
   * @private
   * @param {string} playerState
   * @returns {number}
   */
  function _getMessageTypeForPlayerState (playerState) {

    switch (playerState) {
      case PLAYER_STATE.SOURCE_UNKNOWN:
        return BAS_MESSAGE.MESSAGE_SOURCE_UNKNOWN
      case PLAYER_STATE.SOURCE_UNAVAILABLE:
        return BAS_MESSAGE.MESSAGE_SOURCE_UNAVAILABLE
      case PLAYER_STATE.SOURCE_EMPTY:
        return BAS_MESSAGE.MESSAGE_SOURCE_EMPTY
      case PLAYER_STATE.SONOS_UNREACHABLE:
        return BAS_MESSAGE.MESSAGE_SONOS_UNREACHABLE
      case PLAYER_STATE.VIDEO_UNAVAILABLE:
        return BAS_MESSAGE.MESSAGE_VIDEO_UNAVAILABLE
      case PLAYER_STATE.MUSIC_UNAVAILABLE:
        return BAS_MESSAGE.MESSAGE_AUDIO_UNAVAILABLE
      case PLAYER_STATE.NO_AV:
        return BAS_MESSAGE.MESSAGE_NO_AV
      case PLAYER_STATE.UNKNOWN_AV:
        return BAS_MESSAGE.MESSAGE_UNKNOWN_AV
      case PLAYER_STATE.ZONE_EMPTY:
        return BAS_MESSAGE.MESSAGE_ZONE_EMPTY
      case PLAYER_STATE.QUEUE_EMPTY:
        return BAS_MESSAGE.MESSAGE_QUEUE_EMPTY
      case PLAYER_STATE.QUEUE_END:
        return BAS_MESSAGE.MESSAGE_QUEUE_END
      case PLAYER_STATE.OFF:
      default:
        return BAS_MESSAGE.MESSAGE_NONE
    }
  }

  /**
   * @private
   * @param {?BasRoom} basRoom
   * @returns {?BasSource}
   */
  function _getMusicBasSource (basRoom) {
    return (basRoom && basRoom.getMusicBasSource)
      ? basRoom.getMusicBasSource()
      : null
  }

  /**
   * @private
   * @param {?BasRoom} basRoom
   * @returns {?BasRoomMusic}
   */
  function _getBasRoomMusic (basRoom) {
    return (
      basRoom &&
      basRoom.hasMusic()
    )
      ? basRoom.music
      : null
  }

  /**
   * @private
   * @param {?BasRoom} basRoom
   * @returns {?BasRoomVideo}
   */
  function _getBasRoomVideo (basRoom) {
    return (
      basRoom &&
      basRoom.hasVideo()
    )
      ? basRoom.video
      : null
  }
}
