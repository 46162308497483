'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourceSpotify', [
    '$rootScope',
    '$http',
    'BAS_SOURCE',
    'BAS_SPOTIFY',
    'BasLocalisation',
    'CurrentBasCore',
    'BasSpotifyUri',
    'BasUtilities',
    basSourceSpotifyFactory
  ])

/**
 * @typedef {Object} TSpotifyRetrievedInfo
 * @property {BasSpotifyUri} uri
 * @property {Object} [result]
 */

/**
 * @param $rootScope
 * @param $http
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_SPOTIFY} BAS_SPOTIFY
 * @param {BasLocalisation} BasLocalisation
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasSpotifyUri
 * @param {BasUtilities} BasUtilities
 * @returns BasSourceSpotify
 */
function basSourceSpotifyFactory (
  $rootScope,
  $http,
  BAS_SOURCE,
  BAS_SPOTIFY,
  BasLocalisation,
  CurrentBasCore,
  BasSpotifyUri,
  BasUtilities
) {

  /**
   * @type {TLocalisation}
   */
  var localisation = BasLocalisation.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  var K_ITEMS = 'items'
  var K_NEXT = 'next'

  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourceSpotify (basSource) {

    /**
     * @type {string}
     */
    this.spotifyId = ''

    /**
     * @type {string}
     */
    this.username = ''

    /**
     * @type {string}
     */
    this.connectedName = ''

    /**
     * @type {Promise}
     */
    this.connectedNamePromise = Promise.resolve()

    /**
     * @type {string}
     */
    this.longLink = ''

    /**
     * @type {string}
     */
    this.shortLink = ''

    this.handleTokenChanged = this._onTokenChanged.bind(this)
    this.handleClientName = this._onClientName.bind(this)

    this.checkLinked = this.isLinked.bind(this)

    this.handleUser = this._onUser.bind(this)
    this.handleConnectedUser = this._onConnectedUser.bind(this)
    this.clearUsername = this._clearUsername.bind(this)
    this.clearConnected = this._clearConnected.bind(this)
    this.resetLinkSpinner = this._resetLinkSpinner.bind(this)
    this.handleDetailsError = this._onDetailsError.bind(this)
    this._handleRequestProxy = this._onRequestProxy.bind(this)

    /**
     * @private
     * @type {BasSource}
     */
    this._basSource = basSource || null

    this._clearUsername()
    this._clearConnected()
  }

  /**
   * Returns true if error is invalid market/country
   *
   * @param {Object} error
   * @returns {boolean}
   */
  BasSourceSpotify.checkLocalisationError = function (error) {
    var check = (
      error &&
      error.data &&
      error.data.error && (
        error.data.error.message === BAS_SPOTIFY.ERR_INVALID_MARKET ||
        error.data.error.message === BAS_SPOTIFY.ERR_INVALID_COUNTRY
      )
    )

    if (check) BasLocalisation.clearSpotifyCountry()

    return check
  }

  // region Linking

  /**
   * Synchronous check if Spotify Web API is linked or not
   *
   * @returns {boolean}
   */
  BasSourceSpotify.prototype.isLinked = function () {

    var result

    if (this._basSource) {

      if (this._basSource.isAudioSource) {

        result = BasUtil.isNEString(
          this._basSource.streamingServiceTokens.spotify
        )

      } else {

        result = (
          BasUtil.isObject(this._basSource.source) &&
          BasUtil.isNEString(this._basSource.source.token)
        )
      }

      this._basSource.cssSetSpotifyWebLinked(result)

      return result
    }

    return false
  }

  /**
   * Checks if Spotify Web API is linked or not.
   * If Spotify Web API state has not been retrieved yet,
   * retrieve status and then perform the check.
   *
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.linked = function () {

    if (this._basSource) {

      if (BasUtil.isObject(this._basSource.source)) {

        if (this._basSource.isAudioSource) {

          this._basSource.cssSetSpotifySpinner(true)

          return this._basSource.getStreamingServiceDetails('spotify')
            .then(this.resetLinkSpinner)
            .then(this.updateUsername.bind(this))
            .then(this.checkLinked)
            .catch(this.handleDetailsError)

        } else if (this._basSource.source.spotifyDirty) {

          this._basSource.cssSetSpotifySpinner(true)

          return this._basSource.source.status(true)
            .then(this.resetLinkSpinner)
            .then(this.checkLinked)

        } else {

          return Promise.resolve(this.isLinked())
        }
      }
    }

    this.isLinked()
    return Promise.reject('Invalid basSource')
  }

  BasSourceSpotify.prototype._resetLinkSpinner = function () {
    if (this._basSource) this._basSource.cssSetSpotifySpinner(false)
  }

  BasSourceSpotify.prototype._onDetailsError = function (error) {

    this._resetLinkSpinner()
    this.checkLinked()

    return Promise.reject('Could not get streaming service details: ' + error)
  }

  BasSourceSpotify.prototype._onTokenChanged = function () {

    this.updateUsername()

    if (this._basSource) {

      $rootScope.$emit(
        BAS_SOURCE.EVT_SPOTIFY_TOKEN_CHANGED,
        this._basSource.getId()
      )
    }
  }

  BasSourceSpotify.prototype._onClientName = function () {

    this.updateConnectedName()
  }

  BasSourceSpotify.prototype.updateConnectedName = function () {

    if (this._basSource &&
      this._basSource.source &&
      this._basSource.source.clientName) {

      if (this.getLinkUrlPath()) {

        this.connectedNamePromise =
          this.getConnectedUser(this._basSource.source.clientName)
            .then(this.handleConnectedUser, this.clearConnected)

      } else {

        this.connectedName = this._basSource.source.clientName
        this.connectedNamePromise = Promise.resolve()
      }

    } else {

      this.clearConnected()
    }
  }

  BasSourceSpotify.prototype.updateUsername = function () {

    if (this.isLinked()) {

      this._basSource.cssSetSpotifySpinner(true)

      this.getUser().then(this.handleUser, this.clearUsername)

    } else {

      this._clearUsername()

      if (this._basSource) {

        $rootScope.$emit(
          BAS_SOURCE.EVT_SPOTIFY_USER_UPDATED,
          this._basSource.getId()
        )
      }
    }
  }

  BasSourceSpotify.prototype._onConnectedUser = function (result) {

    if (BasUtil.isObject(result)) {

      this.connectedName =
        BasUtil.isNEString(result[BAS_SPOTIFY.K_DISPLAY_NAME])
          ? result[BAS_SPOTIFY.K_DISPLAY_NAME]
          : result[BAS_SPOTIFY.K_ID]

    } else {

      this.clearConnected()
    }
  }

  /**
   * @private
   * @param {Object} result
   */
  BasSourceSpotify.prototype._onUser = function (result) {

    var oldSpotifyId

    oldSpotifyId = this.spotifyId

    if (BasUtil.isObject(result)) {

      this.spotifyId = result[BAS_SPOTIFY.K_ID]

      this.username =
        BasUtil.isNEString(result[BAS_SPOTIFY.K_DISPLAY_NAME])
          ? result[BAS_SPOTIFY.K_DISPLAY_NAME]
          : this.spotifyId

      this.longLink = this.username
      this.shortLink = this.username

    } else {

      this._clearUsername()
    }

    if (this._basSource) {

      this._basSource.cssSetSpotifySpinner(false)

      if (oldSpotifyId !== this.spotifyId) {

        $rootScope.$emit(
          BAS_SOURCE.EVT_SPOTIFY_USER_UPDATED,
          this._basSource.getId()
        )
      }
    }
  }

  // endregion

  // region Library Requests

  /**
   * @param {Object} config
   * @param {string} config.url
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.requestLib = function (config) {

    var index

    if (BasUtil.isObject(config) &&
      BasUtil.isNEString(config.url)) {

      index = config.url.indexOf(BAS_SPOTIFY.PATH_VERSION) +
        BAS_SPOTIFY.PATH_VERSION.length + 1
      config.path = config.url.substring(index)

      return this.request(config).then(parseLib)
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getUserAlbumsLib = function (params) {
    return this.getUserAlbums(addParamMarket(params))
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getUserSongsLib = function (params) {
    return this.getUserSongs(addParamMarket(params))
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getArtistAlbumsLib = function (
    id,
    params
  ) {
    return this.getArtistAlbums(id, addParamMarket(params))
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getAlbumSongsLib = function (
    id,
    params
  ) {
    return this.getAlbumSongs(id, addParamMarket(params))
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getPlaylistSongsLib = function (
    id,
    params
  ) {
    return this.getPlaylistSongs(id, addParamMarket(params))
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getChartsLib = function (params) {
    return this.getCharts(addParamCountry(params)).then(parseLib)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getNewReleasesLib = function (params) {

    return this.getNewReleases(params).then(parseLib)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getFeaturedPlaylistsLib = function (params) {

    return this.getFeaturedPlaylists(params).then(parseLib)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getGenresLib = function (params) {
    return this.getGenres(addParamLocale(params)).then(parseLib)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getUserArtistsLib = function (params) {

    return this.getUserArtists(params).then(parseLib)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getArtistTopTracksLib = function (
    id,
    params
  ) {
    return this.getArtistTopTracks(id, params).then(parseLib)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getArtistRelatedLib = function (
    id,
    params
  ) {
    return this.getArtistRelated(id, params).then(parseLib)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getGenrePlaylistsLib = function (
    id,
    params
  ) {
    return this.getGenrePlaylists(id, params).then(parseLib)
  }

  /**
   * @param {Object} params
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.searchLib = function (params) {

    return this.search(params).then(parseLib)
  }

  function parseLib (result) {
    if (BasUtil.safeHasOwnProperty(result, 'tracks')) {
      if (Array.isArray(result.tracks)) {
        return { items: result.tracks }
      } else {
        return result.tracks
      }
    }
    if (BasUtil.safeHasOwnProperty(result, 'artists')) {
      if (Array.isArray(result.artists)) {
        return { items: result.artists }
      } else {
        return result.artists
      }
    }
    if (BasUtil.safeHasOwnProperty(result, 'categories')) {
      if (Array.isArray(result.categories.items)) {
        return processCategories(result.categories)
      }
    }
    if (BasUtil.safeHasOwnProperty(result, 'albums')) {
      return result.albums
    }
    if (BasUtil.safeHasOwnProperty(result, 'playlists')) {
      return result.playlists
    }

    return result

    function processCategories (categories) {
      var length, i

      length = categories.items.length
      for (i = 0; i < length; i++) categories.items[i].type = 'genre'

      return categories
    }
  }

  // endregion

  // region Requests

  /**
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getUser = function () {
    return this.request({
      path: 'me'
    })
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getArtist = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'artists/' + id
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getAlbum = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'albums/' + id
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getPlaylist = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'playlists/' + id
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getSongInfo = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'tracks/' + id
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getShowInfo = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'shows/' + id
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getEpisodeInfo = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'episodes/' + id
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getConnectedUser = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'users/' + id
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getUserPlaylists = function (params) {

    return this.request({
      path: 'me/playlists',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getUserAlbums = function (params) {

    return this.request({
      path: 'me/albums',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getUserArtists = function (params) {

    var _params = params || {}

    if (!_params[BAS_SPOTIFY.PARAM_TYPE]) {
      _params[BAS_SPOTIFY.PARAM_TYPE] = BAS_SPOTIFY.PARAM_VALUE_ARTIST
    }

    return this.request({
      path: 'me/following',
      params: _params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getUserSongs = function (params) {

    return this.request({
      path: 'me/tracks',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getUserRecentSongs = function (params) {

    return this.request({
      path: 'me/player/recently-played',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getNewReleases = function (params) {

    return this.request({
      path: 'browse/new-releases',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getFeaturedPlaylists = function (params) {

    return this.request({
      path: 'browse/featured-playlists',
      params: params
    })
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getCharts = function (params) {

    return this.request({
      path: 'browse/categories/toplists/playlists',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getArtistTopTracks = function (
    id,
    params
  ) {
    var location
    var _params

    if (BasUtil.isNEString(id)) {

      _params = params || {}

      if (!_params[BAS_SPOTIFY.PARAM_COUNTRY]) {

        location = localisation.spotifyCountry
          ? localisation.spotifyCountry
          : BAS_SPOTIFY.PARAM_VALUE_COUNTRY_US

        _params[BAS_SPOTIFY.PARAM_COUNTRY] = location
      }

      return this.request({
        path: 'artists/' + id + '/top-tracks',
        params: _params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getArtistAlbums = function (id, params) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'artists/' + id + '/albums',
        params: params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getArtistRelated = function (id, params) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'artists/' + id + '/related-artists',
        params: params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getGenres = function (params) {

    return this.request({
      path: 'browse/categories',
      params: params
    })
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getGenrePlaylists = function (id, params) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'browse/categories/' + id + '/playlists',
        params: params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getAlbumSongs = function (id, params) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'albums/' + id + '/tracks',
        params: params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @param {Object} [params]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.getPlaylistSongs = function (id, params) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        path: 'playlists/' + id + '/tracks',
        params: params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @param {Object} [data]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.removeSongFromPlaylist = function (id, data) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        method: 'DELETE',
        path: 'playlists/' + id + '/tracks',
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.unFollowPlaylist = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        method: 'DELETE',
        path: 'playlists/' + id + '/followers'
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @param {Object} [data]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.reorderSongs = function (id, data) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        method: 'PUT',
        path: 'playlists/' + id + '/tracks',
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @param {Object} [data]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.changePlaylistDetails = function (id, data) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        method: 'PUT',
        path: 'playlists/' + id,
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @param {Object} [data]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.addSongsToPlaylist = function (id, data) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        method: 'POST',
        path: 'playlists/' + id + '/tracks',
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} owner
   * @param {Object} [data]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.addNewPlaylist = function (owner, data) {

    if (BasUtil.isNEString(owner)) {

      return this.request({
        method: 'POST',
        path: 'users/' + owner + '/playlists',
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {Object} [data]
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.addNewPersonalPlaylist = function (data) {

    return this.request({
      method: 'POST',
      path: 'me/playlists',
      data: data
    })
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.isTrackFavourite = function (id) {

    var params

    if (BasUtil.isNEString(id)) {

      params = {
        ids: [id]
      }

      return this.request({
        method: 'GET',
        path: 'me/tracks/contains',
        params: params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.isAlbumFavourite = function (id) {

    var params

    if (BasUtil.isNEString(id)) {

      params = {
        ids: [id]
      }

      return this.request({
        method: 'GET',
        path: 'me/albums/contains',
        params: params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.isArtistFavourite = function (id) {

    var params

    if (BasUtil.isNEString(id)) {

      params = {
        type: 'artist',
        ids: [id]
      }

      return this.request({
        method: 'GET',
        path: 'me/following/contains',
        params: params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.isPlaylistFavourite = function (id) {

    var params

    if (BasUtil.isNEString(id) && BasUtil.isNEString(this.spotifyId)) {

      params = {
        ids: [this.spotifyId]
      }

      return this.request({
        method: 'GET',
        path: 'playlists/' + id + '/followers/contains',
        params: params
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.saveTrackFavourite = function (id) {

    var data

    if (BasUtil.isNEString(id)) {

      data = {
        ids: [id]
      }

      return this.request({
        method: 'PUT',
        path: 'me/tracks',
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.saveAlbumFavourite = function (id) {

    var data

    if (BasUtil.isNEString(id)) {

      data = {
        ids: [id]
      }

      return this.request({
        method: 'PUT',
        path: 'me/albums',
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.saveArtistFavourite = function (id) {

    var params, data

    if (BasUtil.isNEString(id)) {

      params = {
        type: 'artist'
      }

      data = {
        ids: [id]
      }

      return this.request({
        method: 'PUT',
        path: 'me/following',
        params: params,
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.savePlaylistFavourite = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        method: 'PUT',
        path: 'playlists/' + id + '/followers'
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.removeTrackFavourite = function (id) {

    var data

    if (BasUtil.isNEString(id)) {

      data = {
        ids: [id]
      }

      return this.request({
        method: 'DELETE',
        path: 'me/tracks',
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.removeAlbumFavourite = function (id) {

    var data

    if (BasUtil.isNEString(id)) {

      data = {
        ids: [id]
      }

      return this.request({
        method: 'DELETE',
        path: 'me/albums',
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.removeArtistFavourite = function (id) {

    var params, data

    if (BasUtil.isNEString(id)) {

      params = {
        type: 'artist'
      }

      data = {
        ids: [id]
      }

      return this.request({
        method: 'DELETE',
        path: 'me/following',
        params: params,
        data: data
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {string} id
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.removePlaylistFavourite = function (id) {

    if (BasUtil.isNEString(id)) {

      return this.request({
        method: 'DELETE',
        path: 'playlists/' + id + '/followers'
      })
    }

    return Promise.reject(BAS_SOURCE.ERR_INVALID_PARAM)
  }

  /**
   * @param {Object} params
   * @param {string} params.type
   * @param {string} params.query
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.search = function (params) {

    return this.request({
      path: 'search',
      params: params
    })
  }

  /**
   * @param {Object} config
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.requestDemo = function (config) {

    var _basServer, _demo

    if (CurrentBasCore.has() &&
      currentBasCoreState.core.isDemo()) {

      _basServer = currentBasCoreState.core.basServer

      if (_basServer && _basServer.isDemo()) {

        _demo = _basServer.demo
      }
    }

    if (_demo) {

      if (!BasUtil.isObject(config.headers)) config.headers = {}
      config.headers[BAS_SPOTIFY.H_TOKEN] = this._basSource.source.token

      return _demo.handleSpotifyHTTP(config)
    }

    return Promise.reject('No basCore')
  }

  /**
   * @param {Object} config
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.requestProxy = function (config) {

    var _basServer

    if (CurrentBasCore.hasCore()) {

      _basServer = currentBasCoreState.core.core.server

      if (_basServer) {

        return _basServer.requestSpotifyProxy(config)
          .then(this._handleRequestProxy)
      }
    }

    return Promise.reject('No basCore')
  }

  BasSourceSpotify.prototype._onRequestProxy = function (result) {

    return (BasUtil.isObject(result) && BasUtil.isObject(result.data))
      ? result.data
      : Promise.reject(result)
  }

  /**
   * @param {Object} config
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.requestHttp = function (config) {

    if (BasUtil.isObject(config)) {

      if (!BasUtil.isObject(config.headers)) config.headers = {}

      config.headers[BAS_SPOTIFY.H_AUTHORIZATION] =
        'Bearer ' + (
          this._basSource.isAudioSource
            ? this._basSource.streamingServiceTokens.spotify
            : this._basSource.source.token
        )

      return $http(config).then(BasUtilities.handleHttpResponse)
    }

    return Promise.reject('Invalid source or config')
  }

  /**
   * @param {Object} config
   * @param {string} config.path
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.request = function (config) {

    if (config && BasUtil.isNEString(config.path)) {

      if (CurrentBasCore.hasCore() &&
        currentBasCoreState.core.isDemo()) {

        return this.requestDemo(config)
      }

      if (this.isLinked()) {

        config.url = BAS_SPOTIFY.BASE_URL + '/' +
          BAS_SPOTIFY.PATH_VERSION + '/' +
          config.path

        return this.requestHttp(config)

      } else {

        if (this.getLinkUrlPath()) {

          config.path = BAS_SPOTIFY.PATH_VERSION + '/' + config.path
          return this.requestProxy(config)

        } else {

          return Promise.reject('Not allowed')
        }
      }
    }

    return Promise.reject('Invalid config')
  }

  // endregion

  // region Info Requests

  /**
   * @param {string} uri
   * @returns {Promise<TSpotifyRetrievedInfo>}
   */
  BasSourceSpotify.prototype.getFavouriteInfo = function (uri) {

    return this.retrieveInfo(new BasSpotifyUri(uri))
  }

  /**
   * @param {BasSpotifyUri} basSpotifyUri
   * @returns {Promise<TSpotifyRetrievedInfo>}
   */
  BasSourceSpotify.prototype.retrieveInfo = function (basSpotifyUri) {

    var infoPromise

    if (BasUtil.isNEString(basSpotifyUri.artist)) {

      infoPromise = this.getArtist(basSpotifyUri.artist)
        .then(onInfoReceived, onInfoReceived)

    } else if (BasUtil.isNEString(basSpotifyUri.album)) {

      infoPromise = this.getAlbum(basSpotifyUri.album)
        .then(onInfoReceived, onInfoReceived)

    } else if (BasUtil.isNEString(basSpotifyUri.playlist)) {

      infoPromise = this.getPlaylist(basSpotifyUri.playlist)
        .then(onInfoReceived, onInfoReceived)

    } else if (BasUtil.isNEString(basSpotifyUri.track)) {

      infoPromise = this.getSongInfo(basSpotifyUri.track)
        .then(onInfoReceived, onInfoReceived)

    } else if (BasUtil.isNEString(basSpotifyUri.show)) {

      infoPromise = this.getShowInfo(basSpotifyUri.show)
        .then(onInfoReceived, onInfoReceived)

    } else if (BasUtil.isNEString(basSpotifyUri.episode)) {

      infoPromise = this.getEpisodeInfo(basSpotifyUri.episode)
        .then(onInfoReceived, onInfoReceived)
    }

    return infoPromise || Promise.resolve(onInfoReceived())

    /**
     * @param [result]
     * @returns {TSpotifyRetrievedInfo}
     */
    function onInfoReceived (result) {

      /**
       * @type {TSpotifyRetrievedInfo}
       */
      var _result = {}

      _result.uri = basSpotifyUri
      _result.result = result

      return _result
    }
  }

  // endregion

  // region Helper functions

  /**
   * Get the private value of linkUrl
   *
   * @returns {string}
   */
  BasSourceSpotify.prototype.getLinkUrlPath = function () {

    return (this._basSource && BasUtil.isObject(this._basSource.source))
      ? this._basSource.source.linkUrlPath
      : ''
  }

  /**
   * Get the value of linkUrl
   *
   * @returns {Promise<string>}
   */
  BasSourceSpotify.prototype.getLinkUrl = function () {

    if (
      this._basSource &&
      BasUtil.isObject(this._basSource.source)
    ) {

      if (this._basSource.isAudioSource) {

        return this._basSource.source.loginStreamingService('spotify')

      } else {

        return Promise.resolve(this._basSource.source.getLinkUrl())
      }
    }

    return Promise.reject('Invalid basSource')
  }

  /**
   * Unlink linked spotify account
   *
   * @returns {Promise}
   */
  BasSourceSpotify.prototype.unlink = function () {

    if (this._basSource) {

      if (this._basSource.isAudioSource) {

        return this._basSource.source.logoutStreamingService('spotify')

      } else {

        this._basSource.source.unlink()
        return Promise.resolve()
      }
    }

    return Promise.reject('Invalid basSource')
  }

  /**
   * Keeps collecting elements until offset >== amount
   *
   * @param {Function} func
   * @param {number} limit Size of a request
   * @param {number} amount Put this on -1 to get total
   * @returns {Promise<Object>}
   */
  BasSourceSpotify.prototype.getXElements = function (
    func,
    limit,
    amount
  ) {
    var _this, elements, params

    _this = this
    elements = []
    params = {}

    params.limit = limit

    return this.isLinked()
      ? func().then(onRetrieve)
      : Promise.reject(BAS_SPOTIFY.ERR_NOT_LINKED)

    function onRetrieve (result) {

      var config

      if (BasUtil.isObject(result) &&
        Array.isArray(result[K_ITEMS])) {

        elements = elements.concat(result[K_ITEMS])

        if (!BasUtil.isNEString(result[K_NEXT]) ||
          (
            amount !== -1 &&
            amount <= elements.length
          )) {

          // End is reached
          result[K_ITEMS] = elements
          return result

        } else {

          config = {
            url: result[K_NEXT]
          }

          return _this.requestLib(config).then(onRetrieve)
        }
      }

      return Promise.reject(BAS_SOURCE.ERR_NO_RESULT)
    }
  }

  /**
   * Clears the username information
   *
   * @private
   */
  BasSourceSpotify.prototype._clearUsername = function () {

    this.spotifyId = ''
    this.username = ''
    this.longLink = BasUtilities.translate('spotify_no_link')
    this.shortLink = BasUtilities.translate('not_linked')
  }

  BasSourceSpotify.prototype._clearConnected = function () {

    this.connectedName = ''
  }

  BasSourceSpotify.prototype.destroy = function () {

    this.connectedNamePromise = null

    this._basSource = null
  }

  // endregion

  return BasSourceSpotify

  function addParamMarket (params) {

    var _params = params || {}

    if (!_params[BAS_SPOTIFY.PARAM_MARKET] &&
      localisation.spotifyCountry) {

      _params[BAS_SPOTIFY.PARAM_MARKET] = localisation.spotifyCountry
    }

    return _params
  }

  function addParamLocale (params) {

    var _params = params || {}

    if (!_params[BAS_SPOTIFY.PARAM_LOCALE] &&
      localisation.language) {

      _params[BAS_SPOTIFY.PARAM_LOCALE] = localisation.language
    }

    return _params
  }

  function addParamCountry (params) {

    var _params = params || {}

    if (!_params[BAS_SPOTIFY.PARAM_COUNTRY] &&
      localisation.spotifyCountry) {

      _params[BAS_SPOTIFY.PARAM_COUNTRY] = localisation.spotifyCountry
    }

    return _params
  }
}
