'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('RoomFilter', [
    'BasUtilities',
    RoomFilterFactory
  ])

/**
 * @param {BasUtilities} BasUtilities
 * @returns RoomFilter
 */
function RoomFilterFactory (
  BasUtilities
) {
  /**
   * Room Filter
   *
   * A filter could easily be extended
   * to support a variety of filter properties in one filter.
   * Only the prefix and type will have to be adjusted
   * to support custom filters.
   *
   * @constructor
   * @param {string} [property]
   * @param {string} [value]
   */
  function RoomFilter (property, value) {

    /**
     * @type {string}
     */
    this.id = ''

    /**
     * @type {number}
     */
    this.order = 0

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.property = BasUtil.isNEString(property) ? property : ''

    /**
     * @type {string}
     */
    this.value = BasUtil.isNEString(value) ? value : ''

    /**
     * Keep room ID for translation updates
     *
     * @type {string}
     */
    this.roomId = ''

    /**
     * Store a nameId to catch duplicate names in filters.
     * The filters could still have separate content.
     *
     * @type {string}
     */
    this.nameId = ''

    /**
     * @type {Function[]}
     */
    this.checkFunctions = []

    this._parseValue()
    this._createId()
  }

  // Static constants

  /**
   * @constant {string}
   */
  RoomFilter.SEPARATOR = '-'

  /**
   * @constant {string}
   */
  RoomFilter.SRV_ALL = 'all'

  /**
   * @constant {string}
   */
  RoomFilter.SRV_GRP = 'groups'

  /**
   * @constant {string}
   */
  RoomFilter.SRV_AV_GRP = 'avGroups'

  /**
   * @constant {string}
   */
  RoomFilter.SRV_ACT = 'active'

  /**
   * @constant {string}
   */
  RoomFilter.P_SERVICE = 'service'

  /**
   * @constant {string}
   */
  RoomFilter.P_BUILDING = 'building'

  /**
   * @constant {string}
   */
  RoomFilter.P_FLOOR = 'floor'

  /**
   * @constant {string}
   */
  RoomFilter.P_TAG = 'tag'

  // Static methods

  /**
   * Check whether the given filter property is valid
   *
   * @param {string} property
   * @returns {boolean}
   */
  RoomFilter.isValidProperty = function (property) {
    return (
      property === RoomFilter.P_SERVICE ||
      property === RoomFilter.P_BUILDING ||
      property === RoomFilter.P_FLOOR ||
      property === RoomFilter.P_TAG
    )
  }

  /**
   * Compares 2 RoomFilter objects by order. (ascending)
   *
   * @param {RoomFilter} a
   * @param {RoomFilter} b
   * @returns {number}
   */
  RoomFilter.compareOrder = function compareOrder (
    a,
    b
  ) {
    return a.order - b.order
  }

  // Instance methods

  /**
   * @param {BasRoom} room
   * @param {*} [param]
   * @returns {boolean}
   */
  RoomFilter.prototype.checkFunction = function (room, param) {

    var i, length
    length = this.checkFunctions.length

    for (i = 0; i < length; i++) {

      if (!this.checkFunctions[i](room, param)) return false
    }

    return true
  }

  RoomFilter.prototype.addFunction = function (func) {

    if (BasUtil.isFunction(func)) {

      this.checkFunctions.push(func)
    }
  }

  RoomFilter.prototype._parseValue = function () {

    if (RoomFilter.isValidProperty(this.property) &&
      BasUtil.isNEString(this.value)) {

      switch (this.property) {
        case RoomFilter.P_SERVICE:

          switch (this.value) {
            case RoomFilter.SRV_ALL:

              this.setName(BasUtilities.translate('all'))

              break
            case RoomFilter.SRV_GRP:

              this.setName(BasUtilities.translate('groups'))

              break
            case RoomFilter.SRV_ACT:

              this.setName(BasUtilities.translate('active'))

              break
          }

          break
        case RoomFilter.P_BUILDING:
        case RoomFilter.P_FLOOR:

          this.roomId = this.value

          break
        case RoomFilter.P_TAG:

          this.setName(this.value)

          break
      }
    }
  }

  /**
   * Set a name for this filter.
   * This will not change the ID of the filter.
   *
   * @param {string} name
   */
  RoomFilter.prototype.setName = function (name) {

    if (BasUtil.isNEString(name)) this.name = name
  }

  /**
   * Set a different order for this filter.
   * This will not change the ID of the filter.
   *
   * @param {number} order
   */
  RoomFilter.prototype.setOrder = function (order) {

    if (BasUtil.isPNumber(order, true)) this.order = order
  }

  /**
   * Generates an ID based on the current filter
   *
   * @private
   */
  RoomFilter.prototype._createId = function () {

    this.id = this.property + RoomFilter.SEPARATOR + this.value
  }

  return RoomFilter
}
