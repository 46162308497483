'use strict'

angular
  .module('basalteApp')
  .constant('SETTINGS_STATES', {
    SETTINGS: 'settings',
    GENERAL: 'settings.general',
    LIVE: 'settings.live',
    PROJECT: 'settings.project',
    MUSIC: 'settings.music',
    CAMERAS: 'settings.cameras',
    DOOR_PHONE: 'settings.doorphone',
    NETWORK: 'settings.network',
    ABOUT: 'settings.about'
  })
