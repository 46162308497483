'use strict'

var gidwCrypto = require('@gidw/crypto-js')

module.exports = {

  /**
   * Returns a SHA256 hash in hex a string of the provided string
   *
   * @param {string} data
   * @returns {string}
   */
  sha256: function sha256 (data) {

    if (typeof data === 'string') {

      return gidwCrypto.createHash()
        .update(data)
        .digest()
    }

    return ''
  },

  /**
   * Generate a client nonce
   *
   * @returns {string}
   */
  getCNonce: function () {

    return this.sha256(
      Math.random().toString() +
      Date.now().toString() +
      Math.random().toString()
    )
  }
}
