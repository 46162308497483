{
  "system": {
    "city": "Merelbeke",
    "country": "Belgium",
    "latitude": 51,
    "longitude": 3.75,
    "locale": "en_US",
    "timezone": "Europe/Brussels",
    "units": {
      "temperature": "celsius"
    },
    "version": "7.0.0",
    "audioOnly": false,
    "singleRoom": false,
    "singleSource": false,
    "avSupport": true,
    "asanoAvSupport": true
  }
}
