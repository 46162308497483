'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasAppProfile', [
    'BAS_API',
    'BAS_APP_PROFILE',
    'BasAppDevice',
    'BasCoreClientHelper',
    basAppProfileFactory
  ])

/**
 * @param BAS_API
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param {BasAppDevice} BasAppDevice
 * @param {BasCoreClientHelper} BasCoreClientHelper
 * @returns BasAppProfile
 */
function basAppProfileFactory (
  BAS_API,
  BAS_APP_PROFILE,
  BasAppDevice,
  BasCoreClientHelper
) {

  var MAX_HISTORY_LENGTH = 3

  /**
   * @constructor
   */
  function BasAppProfile () {

    /**
     * @type {Object<string, string[]>}
     */
    this.history = {}

    /**
     * @type {string[]}
     */
    this.history[BAS_APP_PROFILE.K_LOCAL] = []

    /**
     * @type {string[]}
     */
    this.history[BAS_APP_PROFILE.K_RADIO] = []

    /**
     * @type {string[]}
     */
    this.history[BAS_APP_PROFILE.K_DEEZER] = []

    /**
     * @type {string[]}
     */
    this.history[BAS_APP_PROFILE.K_SPOTIFY] = []

    /**
     * @type {string[]}
     */
    this.history[BAS_APP_PROFILE.K_TIDAL] = []

    /**
     * @type {string}
     */
    this.username = ''

    /**
     * @type {boolean}
     */
    this.isCloudAccount = false

    /**
     * @type {string}
     */
    this.avatarPath = ''

    /**
     * @type {string}
     */
    this.uiUsername = ''

    /**
     * @type {string}
     */
    this.basalteDeviceUuid = ''

    /**
     * @type {string}
     */
    this.receiveIntercomRoom = ''

    this.clearServerInfo()
    this.clearBasalteDeviceInfo()
  }

  /**
   * @param {string} value
   * @param {string} type
   * @returns {boolean}
   */
  BasAppProfile.prototype.addSearchHistory = function (value, type) {

    var length

    if (BasUtil.isNEString(value) &&
      _checkType(type) &&
      !this.checkDuplicate(value, type)) {

      this.history[type].unshift(value)

      length = this.history[type].length

      if (length > MAX_HISTORY_LENGTH) {

        this.history[type].pop()
      }

      return true
    }

    return false
  }

  /**
   * Get current profile in order to save them in local storage
   *
   * @returns {Object}
   */
  BasAppProfile.prototype.getProfileHistory = function () {

    var history = {}

    history[BAS_APP_PROFILE.K_LOCAL] =
      this.history[BAS_APP_PROFILE.K_LOCAL]
    history[BAS_APP_PROFILE.K_RADIO] =
      this.history[BAS_APP_PROFILE.K_RADIO]
    history[BAS_APP_PROFILE.K_DEEZER] =
      this.history[BAS_APP_PROFILE.K_DEEZER]
    history[BAS_APP_PROFILE.K_SPOTIFY] =
      this.history[BAS_APP_PROFILE.K_SPOTIFY]
    history[BAS_APP_PROFILE.K_TIDAL] =
      this.history[BAS_APP_PROFILE.K_TIDAL]

    return history
  }

  /**
   * Parses a local storage object and fill in the gaps where needed.
   * If the local data object is modified, the function will return true.
   *
   * @param {BasStoredUser} user
   */
  BasAppProfile.prototype.syncCurrentProfile = function (user) {

    var _history

    _history = {}

    if (user && BasUtil.isObject(user.history)) {

      _history = user.history
    }

    this.history[BAS_APP_PROFILE.K_LOCAL] =
      _getHistoryForKey(BAS_APP_PROFILE.K_LOCAL)
    this.history[BAS_APP_PROFILE.K_RADIO] =
      _getHistoryForKey(BAS_APP_PROFILE.K_RADIO)
    this.history[BAS_APP_PROFILE.K_DEEZER] =
      _getHistoryForKey(BAS_APP_PROFILE.K_DEEZER)
    this.history[BAS_APP_PROFILE.K_SPOTIFY] =
      _getHistoryForKey(BAS_APP_PROFILE.K_SPOTIFY)
    this.history[BAS_APP_PROFILE.K_TIDAL] =
      _getHistoryForKey(BAS_APP_PROFILE.K_TIDAL)

    /**
     * @private
     * @param {string} key
     * @returns {string[]}
     */
    function _getHistoryForKey (key) {

      var data

      data = _history[key]

      return Array.isArray(data) ? data : []
    }
  }

  /**
   * Removes name and photo
   */
  BasAppProfile.prototype.clearServerInfo = function () {

    this._setUsername('')
    this.isCloudAccount = false
    this.avatarPath = ''
  }

  /**
   * Removes basalteDeviceUuid
   */
  BasAppProfile.prototype.clearBasalteDeviceInfo = function () {

    this.basalteDeviceUuid = ''
    this.receiveIntercomRoom = ''
  }

  /**
   * @param {string} name
   * @param {string} avatarPath
   * @param {boolean} isCloudAccount
   */
  BasAppProfile.prototype.setServerInfo = function (
    name,
    avatarPath,
    isCloudAccount
  ) {
    // Make sure current profile is cleared
    this.clearServerInfo()

    // Check if core has user
    if (BasUtil.isNEString(name)) this._setUsername(name)
    if (BasUtil.isNEString(avatarPath)) this.avatarPath = avatarPath
    this.isCloudAccount = isCloudAccount
  }

  /**
   * @param {string} [basalteDeviceUuid]
   * @returns {boolean} Boolean indicating if handling the basalteDevice with
   * given uuid resulted in a 'receiveIntercomRoom' property change
   */
  BasAppProfile.prototype.setBasalteDeviceInfo = function (
    basalteDeviceUuid
  ) {
    var _basDeviceInfo

    // Make sure current profile is cleared
    this.clearBasalteDeviceInfo()

    // Check if core has user
    if (BasUtil.isNEString(basalteDeviceUuid)) {
      this.basalteDeviceUuid = basalteDeviceUuid
    }

    if (BasUtil.isNEString(this.basalteDeviceUuid)) {

      if (BasAppDevice.isCoreClient()) {

        if (BasAppDevice.isEllie()) {
          this.uiUsername = 'Ellie'
        } else if (BasAppDevice.isLisa()) {
          this.uiUsername = 'Lisa'
        } else if (BasAppDevice.isLena()) {
          this.uiUsername = 'Lena'
        } else {
          this.uiUsername = 'Client'
        }

        _basDeviceInfo = BasCoreClientHelper.getBasCoreClientInfo()

        return this._handleDeviceInfo(_basDeviceInfo)
      }
    }

    return false
  }

  /**
   * @param {Object} [basalteDeviceInfo]
   * @returns {boolean} Boolean indicating if handling the basalteDeviceInfo
   * resulted in a 'receiveIntercomRoom' property change
   */
  BasAppProfile.prototype._handleDeviceInfo = function (
    basalteDeviceInfo
  ) {
    var _room

    if (basalteDeviceInfo) {

      if (basalteDeviceInfo.device &&
        BasUtil.isNEString(basalteDeviceInfo.device.name)) {

        this.uiUsername = basalteDeviceInfo.device.name
      }

      _room = basalteDeviceInfo.room

      if (_room &&
        _room.room &&
        _room.room.functions &&
        _room.room.functions[BAS_API.Room.FUNCTIONS.INTERCOM]) {

        this.receiveIntercomRoom = basalteDeviceInfo.room.id

        return true
      }
    }

    return false
  }

  // region Helper functions

  BasAppProfile.prototype.checkDuplicate = function (value, type) {

    var length, i

    length = this.history[type].length
    for (i = 0; i < length; i++) {

      if (this.history[type][i] === value) return true
    }

    return false
  }

  /**
   * @private
   * @param {string} username
   */
  BasAppProfile.prototype._setUsername = function (username) {

    this.username = username
    this.uiUsername = this.username
  }

  /**
   * @private
   * @param {string} type
   * @returns {boolean}
   */
  function _checkType (type) {

    return (
      type === BAS_APP_PROFILE.K_LOCAL ||
      type === BAS_APP_PROFILE.K_RADIO ||
      type === BAS_APP_PROFILE.K_DEEZER ||
      type === BAS_APP_PROFILE.K_SPOTIFY ||
      type === BAS_APP_PROFILE.K_TIDAL
    )
  }

  // endregion

  return BasAppProfile
}
