'use strict'

module.exports.getNativeStoragePlugin = getNativeStoragePlugin
module.exports.getStorageHandle = getStorageHandle

function getNativeStoragePlugin (window) {

  return window.NativeStorage ? window.NativeStorage : null
}

function getStorageHandle (window) {
  return (window.basStorageInitResult && window.basStorageInitResult.basStorage)
    ? window.basStorageInitResult.basStorage
    : window.localStorage
      ? window.localStorage
      : null
}
