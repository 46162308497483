'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLibraryPage', [
    'BasLibraryTab',
    'BasUtilities',
    'Logger',
    basLibraryPageFactory
  ])

/**
 * @param BasLibraryTab
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns BasLibraryPage
 */
function basLibraryPageFactory (
  BasLibraryTab,
  BasUtilities,
  Logger
) {
  var className = 'BasLibraryPage'

  /**
   * Object that represents a page in the music library
   *
   * @constructor
   * @param {Function} [handler]
   */
  function BasLibraryPage (handler) {

    /**
     * @instance
     * @type {number}
     */
    this.type = BasLibraryPage.TYPE_NORMAL

    /**
     * @instance
     * @type {string}
     */
    this.title = ''

    /**
     * Translate id for the title
     *
     * @instance
     * @type {string}
     */
    this.titleId = ''

    /**
     * @instance
     * @type {string}
     */
    this.subtitle = ''

    /**
     * Boolean which prevents a tabTitle from
     * overwriting a header Subtitle.
     * In case of artist name as subtitle for example.
     *
     * @instance
     * @type {boolean}
     */
    this.lockSubtitle = false

    /**
     * Message to show in case of no content, loading, ...
     *
     * @instance
     * @type {string}
     */
    this.message = ''

    /**
     * @instance
     * @type {number}
     */
    this.state = BasLibraryPage.STATE_NORMAL

    /**
     * @instance
     * @type {boolean}
     */
    this.hasTabs = false

    /**
     * @instance
     * @type {Array<BasLibraryTab>}
     */
    this.tabs = []

    /**
     * Scrollbar location
     *
     * @instance
     * @type {number}
     */
    this.scrollPercent = 0

    /**
     * @type {?Function}
     */
    this.handler = handler || null

    /**
     * Collections visible on the page
     *
     * @instance
     * @type {Array<BasLibraryCollection>}
     */
    this.collections = []

    /**
     * All collections
     *
     * @instance
     * @type {Array<BasLibraryCollection>}
     */
    this.collectionsData = []

    /**
     * @instance
     * @type {boolean}
     */
    this.isFetching = false

    /**
     * @instance
     * @type {boolean}
     */
    this.dirty = false

    /**
     * CSS classes
     *
     * @instance
     * @type {Object}
     */
    this.class = {}

    // Create generic CSS class variables
    this.class[BasLibraryPage.CLASS_HAS_DETAIL] = false
    this.class[BasLibraryPage.CLASS_HAS_OVERLAY] = false
    this.class[BasLibraryPage.CLASS_HAS_MESSAGE] = false
    this.class[BasLibraryPage.CLASS_HAS_TABS] = false
    this.class[BasLibraryPage.CLASS_MESSAGE_HAS_SPINNER] = false

    // Create CSS class objects
    this.class[BasLibraryPage.COL_BODY] = {}
    this.class[BasLibraryPage.COL_PAGE] = {}

    // Initialize CSS class objects
    this.processClasses()
  }

  // region Static constants

  /**
   * @constant {number}
   */
  BasLibraryPage.TYPE_NORMAL = 0

  /**
   * @constant {number}
   */
  BasLibraryPage.TYPE_SEARCH = 1

  /**
   * @constant {string}
   */
  BasLibraryPage.COL_PAGE = 'page'

  /**
   * @constant {string}
   */
  BasLibraryPage.COL_BODY = 'body'

  /**
   * @constant {string}
   */
  BasLibraryPage.CLASS_HAS_DETAIL = 'bas-library-page-has-detail'

  /**
   * @constant {string}
   */
  BasLibraryPage.CLASS_HAS_OVERLAY = 'bas-library-page-has-overlay'

  /**
   * @constant {string}
   */
  BasLibraryPage.CLASS_HAS_MESSAGE = 'bas-library-page-has-message'

  /**
   * @constant {string}
   */
  BasLibraryPage.CLASS_HAS_TABS = 'bas-library-page-has-tabs'

  /**
   * @constant {string}
   */
  BasLibraryPage.CLASS_MESSAGE_HAS_SPINNER =
    'bas-library-page-message-has-spinner'

  /**
   * @constant {number}
   */
  BasLibraryPage.STATE_NORMAL = 0

  /**
   * @constant {number}
   */
  BasLibraryPage.STATE_LOADING = 1

  /**
   * @constant {number}
   */
  BasLibraryPage.STATE_NO_CONTENT = 2

  /**
   * @constant {number}
   */
  BasLibraryPage.STATE_NO_SEARCH_RESULTS = 3

  /**
   * @constant {number}
   */
  BasLibraryPage.STATE_OVERLAY_LOADING = 4

  /**
   * @constant {number}
   */
  BasLibraryPage.STATE_ERROR = 5

  /**
   * @constant {string}
   */
  BasLibraryPage.MESSAGE_ERROR = 'something_went_wrong'

  /**
   * @constant {string}
   */
  BasLibraryPage.CONTENT_NOT_CONNECTED = 'not_connected'

  /**
   * @constant {string}
   */
  BasLibraryPage.CONTENT_START = 'start'

  // endregion

  // Static methods

  /**
   * Checks if the value is a valid page type
   *
   * @param value
   * @returns {boolean}
   */
  BasLibraryPage.isValidType = function (value) {
    return (
      value === BasLibraryPage.TYPE_NORMAL ||
      value === BasLibraryPage.TYPE_SEARCH
    )
  }

  /**
   * Translates the page
   */
  BasLibraryPage.prototype.onTranslate = function () {
    var subtitle, length, i

    subtitle = ''

    length = this.tabs.length
    for (i = 0; i < length; i++) {
      this.tabs[i].name =
        BasUtilities.translate(this.tabs[i].translateName)
      if (this.tabs[i].class[BasLibraryTab.CLASS_TAB_ACTIVE]) {
        subtitle = this.tabs[i].collection.titleNameId
      }
    }

    length = this.collections.length
    for (i = 0; i < length; i++) {
      this.collections[i].translate()
    }

    if (!this.lockSubtitle && BasUtil.isNEString(subtitle)) {
      this.setSubtitle(BasUtilities.translate(subtitle))
    }

    if (BasUtil.isNEString(this.titleId)) {
      this.setTitle(BasUtilities.translate(this.titleId))
    }
  }

  /**
   * Checks if value is a supported CSS class
   *
   * @param {string} value
   * @returns {boolean}
   */
  BasLibraryPage.isValidClass = function (value) {
    return (
      value === BasLibraryPage.CLASS_HAS_DETAIL ||
      value === BasLibraryPage.CLASS_HAS_OVERLAY ||
      value === BasLibraryPage.CLASS_HAS_MESSAGE ||
      value === BasLibraryPage.CLASS_HAS_TABS ||
      value === BasLibraryPage.CLASS_MESSAGE_HAS_SPINNER
    )
  }

  /**
   * Checks if value is a supported page state
   *
   * @param {number} value
   * @returns {boolean}
   */
  BasLibraryPage.isValidState = function (value) {
    return (
      value === BasLibraryPage.STATE_NORMAL ||
      value === BasLibraryPage.STATE_LOADING ||
      value === BasLibraryPage.STATE_NO_CONTENT ||
      value === BasLibraryPage.STATE_NO_SEARCH_RESULTS ||
      value === BasLibraryPage.STATE_OVERLAY_LOADING ||
      value === BasLibraryPage.STATE_ERROR
    )
  }

  // Instance methods

  BasLibraryPage.prototype.setType = function (value) {

    // Check input
    if (BasLibraryPage.isValidType(value)) {
      this.type = value
    } else {
      Logger.warn(className + ' - setType - Invalid type', value)
    }
  }

  BasLibraryPage.prototype.makeTabs = function () {

    var length, i

    this.tabs = []
    this.hasTabs = false

    length = this.collectionsData.length
    for (i = 0; i < length; i++) {
      if (this.collectionsData[i].hasTab) {
        this.tabs.push(new BasLibraryTab(this.collectionsData[i]))
        this.hasTabs = true
      }
    }

    this.setCSSClass(
      BasLibraryPage.CLASS_HAS_TABS,
      this.hasTabs
    )
  }

  /**
   * @param {Object} tab
   * @returns {Promise}
   */
  BasLibraryPage.prototype.selectTab = function (tab) {

    var _this, collection

    _this = this

    if (tab.class[BasLibraryPage.CLASS_TAB_ACTIVE]) return Promise.resolve()

    collection = tab.collection

    if (collection.elements.length === 0) {

      // Return promise after elements are received
      return collection.retrieve().then(onSuccess, onFail)
    }

    return Promise.resolve(false)

    function onSuccess (result) {

      // Deselect this tab
      tab.setSelectedTab(false)

      // Activate this tab
      tab.setActiveTab(true)

      // Set collection
      _this.collections[0] = collection

      // Set subtitle
      if (!_this.lockSubtitle) {

        _this.setSubtitle(
          BasUtilities.translate(tab.collection.titleNameId)
        )
      }

      // Make sure to return something "true" for $digest
      return result || true
    }

    function onFail (error) {

      // Set subtitle
      if (!_this.lockSubtitle) {

        _this.setSubtitle(
          BasUtilities.translate(tab.collection.titleNameId)
        )
      }

      return error
    }
  }

  BasLibraryPage.prototype.clearTabsClass = function () {

    var length, i

    length = this.tabs.length
    for (i = 0; i < length; i++) {
      this.tabs[i].setActiveTab(false)
      this.tabs[i].setSelectedTab(false)
    }
  }

  /**
   * @param {string} title
   */
  BasLibraryPage.prototype.setTitle = function (title) {

    // Set title
    this.title = BasUtil.isNEString(title) ? title : ''
  }

  /**
   * @param {string} titleId
   */
  BasLibraryPage.prototype.setTitleId = function (titleId) {

    if (BasUtil.isNEString(titleId)) {
      this.titleId = titleId
      this.setTitle(BasUtilities.translate(titleId))
    } else {
      this.titleId = ''
    }
  }

  /**
   * @param {string} subtitle
   */
  BasLibraryPage.prototype.setSubtitle = function (subtitle) {

    // Set subtitle
    this.subtitle = BasUtil.isNEString(subtitle) ? subtitle : ''
  }

  /**
   * @returns {Promise | undefined}
   */
  BasLibraryPage.prototype.onReachedEnd = function () {

    // Check if collection exists
    if (BasUtil.isObject(this.collections[0])) {

      // Call "onReachedEnd" of first collection
      return this.collections[0].onReachedEnd()
    }

    return Promise.reject('No collection')
  }

  /**
   * @param {number} collectionId
   * @param {BasLibraryElement} element
   */
  BasLibraryPage.prototype.addElement = function (
    collectionId,
    element
  ) {
    // Check if collection exists
    if (BasUtil.isObject(this.collections[collectionId])) {

      // Add element to collection
      this.collections[collectionId].elements
        .push(element)
    }
  }

  /**
   * @param {number} view
   */
  BasLibraryPage.prototype.setCollectionGridView = function (view) {

    var collections, length, i

    collections = this.collections
    length = collections.length
    for (i = 0; i < length; i++) collections[i].setGridView(view)
  }

  /**
   * Set status message for page.
   * The value will be used as translation id.
   *
   * @param {string} value
   */
  BasLibraryPage.prototype.setMessage = function (value) {
    var translated

    // Check input
    if (BasUtil.isNEString(value)) {

      // Translate
      translated = BasUtilities.translate(value)

      // Check translation
      if (BasUtil.isNEString(translated)) {
        this.message = translated
      } else {
        Logger.warn(className + ' - setMessage - Invalid translation', value)
        this.message = ''
      }
    } else {
      this.message = ''
    }
  }

  /**
   * Set page in a certain state.
   *
   * @param {number} value
   */
  BasLibraryPage.prototype.setState = function (value) {

    // Check input
    if (BasLibraryPage.isValidState(value)) {
      this.state = value
      this.processState()
    } else {
      Logger.warn(className + ' - setState - Invalid state', value)
    }
  }

  BasLibraryPage.prototype.processState = function () {

    // Set defaults
    this.setMessage('')
    this.setCSSClass(
      BasLibraryPage.CLASS_HAS_OVERLAY,
      false
    )
    this.setCSSClass(
      BasLibraryPage.CLASS_HAS_MESSAGE,
      false
    )
    this.setCSSClass(
      BasLibraryPage.CLASS_MESSAGE_HAS_SPINNER,
      false
    )

    switch (this.state) {
      case BasLibraryPage.STATE_NORMAL:

        // Defaults

        break
      case BasLibraryPage.STATE_LOADING:

        this.setMessage('loading')

        // Add message and spinner CSS
        this.setCSSClass(
          BasLibraryPage.CLASS_HAS_MESSAGE,
          true
        )
        this.setCSSClass(
          BasLibraryPage.CLASS_MESSAGE_HAS_SPINNER,
          true
        )

        break
      case BasLibraryPage.STATE_NO_CONTENT:

        this.setMessage('no_content_available')

        // Add message CSS
        this.setCSSClass(
          BasLibraryPage.CLASS_HAS_MESSAGE,
          true
        )

        break
      case BasLibraryPage.STATE_NO_SEARCH_RESULTS:

        this.setMessage('no_search_results')

        // Add message CSS
        this.setCSSClass(
          BasLibraryPage.CLASS_HAS_MESSAGE,
          true
        )

        break
      case BasLibraryPage.STATE_OVERLAY_LOADING:

        this.setMessage('loading')

        // Process overlay, show message and spinner
        this.setCSSClass(
          BasLibraryPage.CLASS_HAS_OVERLAY,
          true
        )
        this.setCSSClass(
          BasLibraryPage.CLASS_HAS_MESSAGE,
          true
        )
        this.setCSSClass(
          BasLibraryPage.CLASS_MESSAGE_HAS_SPINNER,
          true
        )

        break
      case BasLibraryPage.STATE_ERROR:

        this.setMessage(BasLibraryPage.MESSAGE_ERROR)

        this.setCSSClass(
          BasLibraryPage.CLASS_HAS_OVERLAY,
          true
        )
        this.setCSSClass(
          BasLibraryPage.CLASS_HAS_MESSAGE,
          true
        )

        break
      default:
        Logger.error(className + ' - processState - Unknown state', this.state)
    }

    this.processClasses()
  }

  /**
   * Set a CSS class to add or remove
   *
   * @param {string} cssClass
   * @param {boolean} value
   */
  BasLibraryPage.prototype.setCSSClass = function (cssClass, value) {

    // Check input
    if (BasLibraryPage.isValidClass(cssClass)) {

      this.class[cssClass] = value === true

    } else {

      Logger.warn(className + ' - setCSSClass - Invalid CSS class', cssClass)
    }
  }

  /**
   * @param {BasLibraryCollection} collection
   */
  BasLibraryPage.prototype.addCollection = function (collection) {

    // Check collection
    if (BasUtil.isObject(collection)) {

      // Add collection
      this.collections.push(collection)

      // Check if collection is still retrieving
      if (this.state === BasLibraryPage.STATE_LOADING) {

        // Check if collection is still fetching
        if (collection.isFetching !== true) {

          // Collection has already retrieved items
          this.state = BasLibraryPage.STATE_NORMAL
        }
      }

    } else {
      Logger.warn(
        className + ' - addCollection - Invalid collection',
        collection
      )
    }
  }

  BasLibraryPage.prototype.refresh = function () {

    var length, i

    length = this.collections.length
    for (i = 0; i < length; i++) this.collections[i].refresh()
  }

  /**
   * Do both an retrieveUi and a retrieveAll.
   * (To update internal parameters)
   *
   * Returns the "any" Promise
   *
   * @returns {Promise}
   */
  BasLibraryPage.prototype.retrieve = function () {
    var _this, length, i, collectionRetrievePromises, returnPromise

    _this = this

    // Initialize Array that will hold all retrieve promises
    collectionRetrievePromises = []

    // Iterate all collections
    length = this.collections.length
    for (i = 0; i < length; i++) {

      // Add retrieve Promise
      collectionRetrievePromises
        .push(this.collections[i].retrieve())
    }

    // Call Promise Any first to make sure it always resolves before ALL

    // Resolves as soon as on retrieve resolves
    returnPromise = BasUtil.promiseAny(collectionRetrievePromises)
      .then(
        onPromiseAny.bind(null, true),
        onPromiseAny.bind(null, false)
      )

    // Wait for all retrieves to finish
    BasUtil.promiseAll(collectionRetrievePromises)
      .then(
        onPromiseAll.bind(null, true),
        onPromiseAll.bind(null, false)
      )

    // Return the any Promise
    return returnPromise

    /**
     * All initial retrieves are finished
     *
     * @param {boolean} resolved
     * @param {*} result
     * @returns {Promise}
     */
    function onPromiseAll (resolved, result) {

      // Update page state
      _this.isFetching = false

      // Check result type
      if (resolved) {

        // Check if there is content
        _this.checkContent()

        return Promise.resolve(result)
      } else {
        return Promise.reject(result)
      }
    }

    /**
     * First retrieve has finished
     *
     * @param {boolean} resolved
     * @param {*} result
     * @returns {Promise}
     */
    function onPromiseAny (resolved, result) {

      // Update page state
      _this.isFetching = false

      // Check result type
      if (resolved) {
        return Promise.resolve(result)
      } else {
        return Promise.reject(result)
      }
    }
  }

  /**
   * Helper function to check if current state is a no content state
   *
   * @returns {boolean}
   */
  BasLibraryPage.prototype.isStateNoContent = function () {
    return (
      this.state === BasLibraryPage.STATE_NO_CONTENT ||
      this.state === BasLibraryPage.STATE_NO_SEARCH_RESULTS
    )
  }

  BasLibraryPage.prototype.setContent = function (content) {
    // Check input
    if (BasUtil.isNEString(content)) {
      this.content = content

      // Check content
      if (this.content === BasLibraryPage.CONTENT_NOT_CONNECTED) {

        // Show message
        this.setMessage('not_linked')

        // Add message CSS
        this.setCSSClass(
          BasLibraryPage.CLASS_HAS_OVERLAY,
          true
        )
        this.setCSSClass(
          BasLibraryPage.CLASS_HAS_MESSAGE,
          true
        )
        this.processClasses()
      }
    }
  }

  /**
   * Helper function to set the no content state based on the page type
   */
  BasLibraryPage.prototype.setStateNoContent = function () {

    switch (this.type) {

      case BasLibraryPage.TYPE_NORMAL:

        this.setState(BasLibraryPage.STATE_NO_CONTENT)

        break
      case BasLibraryPage.TYPE_SEARCH:

        this.setState(BasLibraryPage.STATE_NO_SEARCH_RESULTS)

        break
      default:

        Logger.warn(
          className + ' - setStateNoContent - Unknown page type',
          this.type
        )
    }
  }

  /**
   * Checks for content and sets the correct state
   */
  BasLibraryPage.prototype.checkContent = function () {

    // Check for content
    if (!this.isNotEmpty()) {

      this.setStateNoContent()

    } else {

      // Change to normal only if previous state was empty
      if (this.isStateNoContent()) {

        this.setState(BasLibraryPage.STATE_NORMAL)
      }
    }
  }

  /**
   * @returns {number}
   */
  BasLibraryPage.prototype.getScrollPercent = function () {

    if (this.collections &&
      this.collections[0]) {

      return this.collections[0].scrollPercent
    }

    return 0
  }

  BasLibraryPage.prototype.getTab = function (translateName) {

    var i, length, tab

    if (this.hasTabs) {

      length = this.tabs.length
      for (i = 0; i < length; i++) {

        tab = this.tabs[i]

        if (tab && tab.translateName === translateName) {

          return tab
        }
      }
    }

    return null
  }

  /**
   * @param {number} index
   * @returns {BasLibraryCollection}
   */
  BasLibraryPage.prototype.getCollection = function (index) {
    return (this.collections[index] && this.collections[index].setTitle)
      ? this.collections[index]
      : null
  }

  /**
   *
   * @param {number} percent
   */
  BasLibraryPage.prototype.setScrollPercent = function (percent) {
    if (BasUtil.isObject(this.collections[0])) {
      this.collections[0].setScrollPercent(percent)
    }
  }

  /**
   * Checks the current state of the page,
   * if it is still valid and change the state if it is not valid anymore
   */
  BasLibraryPage.prototype.syncState = function () {

    // Check if page is still fetching
    if (!this.isFetching) {

      // Check current state
      switch (this.state) {
        case BasLibraryPage.STATE_LOADING:
        case BasLibraryPage.STATE_OVERLAY_LOADING:
        case BasLibraryPage.STATE_NORMAL:

          // If empty show the "no content"
          if (!this.isNotEmpty()) {
            this.setStateNoContent()
          } else {
            this.setState(BasLibraryPage.STATE_NORMAL)
          }

          break
      }
    }
  }

  /**
   * Checks whether at least one of the collections has elements
   *
   * @returns {boolean}
   */
  BasLibraryPage.prototype.isNotEmpty = function () {
    var i

    // Iterate all collections
    i = this.collections.length
    while (i--) {
      if (this.collections[i].elements.length > 0) {
        return true
      }
    }
    return false
  }

  /**
   * Suspend the page.
   *
   * Calls the "suspend" function for all collections.
   */
  BasLibraryPage.prototype.suspend = function () {
    Logger.debug(className + ' - suspend')

    this.callCollectionsMethod('suspend')
  }

  /**
   * Resume the page
   *
   * Calls the "resume" function for all collections.
   */
  BasLibraryPage.prototype.resume = function () {
    Logger.debug(className + ' - resume')

    this.callCollectionsMethod('resume')
  }

  /**
   * Destroy the page.
   *
   * Calls the "destroy" function for all collections.
   */
  BasLibraryPage.prototype.destroy = function () {
    Logger.debug(className + ' - destroy')

    this.callCollectionsMethod('destroy')
  }

  /**
   * Execute method for all collections
   *
   * @param {string} method
   */
  BasLibraryPage.prototype.callCollectionsMethod = function (method) {
    var i

    if (BasUtil.isNEString(method)) {

      // Initialize iterator
      i = this.collections.length

      while (i--) {

        // Check collection
        if (BasUtil.isObject(this.collections[i]) &&
          typeof this.collections[i][method] === 'function') {

          // Execute destroy
          this.collections[i][method]()
        }
      }
    }
  }

  /**
   * Process the generic CSS classes
   * and update the specific CSS collections
   */
  BasLibraryPage.prototype.processClasses = function () {
    var _this = this

    // Body
    setCSSClass(
      BasLibraryPage.COL_BODY,
      BasLibraryPage.CLASS_HAS_MESSAGE
    )
    setCSSClass(
      BasLibraryPage.COL_BODY,
      BasLibraryPage.CLASS_MESSAGE_HAS_SPINNER
    )

    // Page
    setCSSClass(
      BasLibraryPage.COL_PAGE,
      BasLibraryPage.CLASS_HAS_OVERLAY
    )
    setCSSClass(
      BasLibraryPage.COL_PAGE,
      BasLibraryPage.CLASS_HAS_DETAIL
    )

    /**
     * Sets the value for the className in the collection,
     * derived from the general class values
     *
     * @param {string} collection
     * @param {string} cssClass
     */
    function setCSSClass (collection, cssClass) {
      _this.class[collection][cssClass] = _this.class[cssClass]
    }
  }

  return BasLibraryPage
}
