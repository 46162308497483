'use strict'

import { registerPlugin, Capacitor } from '@capacitor/core'

let BasWidgetsPluginCapacitor = null
const BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .service('BasWidgets', [
    '$rootScope',
    '$transitions',
    '$uiRouterGlobals',
    '$window',
    'Querystringify',
    'BAS_API',
    'STATES',
    'BAS_APP',
    'BAS_CURRENT_CORE',
    'BAS_MODAL',
    'BAS_ROOM',
    'BAS_ROOMS',
    'BAS_CONNECT',
    'BAS_SCENE',
    'BAS_SPLASH',
    'BasConnectInfo',
    'BasServerStorage',
    'BasSplash',
    'BasModal',
    'RoomsHelper',
    'BasScenePresetsHelper',
    'BasStateHelper',
    'BasUtilities',
    'CurrentBasCore',
    BasWidgets
  ])

/**
 * @typedef {Object} TBasWidgetsState
 * @property {?TBasSmartConnect} smartConnectTarget
 * @property {string} name
 * @property {?Object} sceneCtrlMessage
 * @property {number} timeStamp
 */

/**
 * Helper service for managing widgets.
 *
 * @constructor
 * @param $rootScope
 * @param $transitions
 * @param $uiRouterGlobals
 * @param $window
 * @param Querystringify
 * @param BAS_API
 * @param {STATES} STATES
 * @param {BAS_APP} BAS_APP
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_CONNECT} BAS_CONNECT
 * @param {BAS_SCENE} BAS_SCENE
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param BasConnectInfo
 * @param {BasServerStorage} BasServerStorage
 * @param {BasSplash} BasSplash
 * @param {BasModal} BasModal
 * @param {RoomsHelper} RoomsHelper
 * @param {BasScenePresetsHelper} BasScenePresetsHelper
 * @param {BasStateHelper} BasStateHelper
 * @param {BasUtilities} BasUtilities
 * @param {CurrentBasCore} CurrentBasCore
 */
function BasWidgets (
  $rootScope,
  $transitions,
  $uiRouterGlobals,
  $window,
  Querystringify,
  BAS_API,
  STATES,
  BAS_APP,
  BAS_CURRENT_CORE,
  BAS_MODAL,
  BAS_ROOM,
  BAS_ROOMS,
  BAS_CONNECT,
  BAS_SCENE,
  BAS_SPLASH,
  BasConnectInfo,
  BasServerStorage,
  BasSplash,
  BasModal,
  RoomsHelper,
  BasScenePresetsHelper,
  BasStateHelper,
  BasUtilities,
  CurrentBasCore
) {
  const K_FAVOURITE_SCENE_UUID = 'favouriteSceneUuid'
  const K_FAVOURITE_SCENE_NAME = 'favouriteSceneName'
  const K_PROJECT_UUID = 'projectUuid'
  const K_SCENE_CTRL_UUID = 'sceneCtrlUuid'
  const K_USER = 'user'
  const K_CREDENTIAL_HASH = 'credentialHash'
  const K_ACTION_ACTIVATE = 'activate'

  const MAX_TIMESTAMP_TIMEOUT = 30000
  const BAS_WIDGETS_URL_HOST = 'musicapp.basalte.be'
  const BAS_WIDGETS_URL_PATH = '/widgetHandler'

  // Capacitor uses "public" as web dir, cordova uses "www"
  const PROJECT_SCENE_IMG_PATH = `${BasWidgetsPluginCapacitor ? 'public' : 'www'}/img/scenes/200/`

  let basWidgetsDataArray = []

  /**
   * @type {TCurrentBasCoreState}
   */
  const currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasWidgetsState}
   */
  const state = {}
  state.smartConnectTarget = null
  state.sceneCtrlMessage = null
  state.name = ''
  state.timeStamp = 0

  this.getTarget = getTarget

  init()

  function init () {
    const basUniversalLinksPlugin = _getBasUniversalLinksPlugin()

    if (basUniversalLinksPlugin) {

      basUniversalLinksPlugin.init()
      basUniversalLinksPlugin.addCallback(_handleUniversalLink)
    }

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    )

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_CLEARED,
      _onRoomsCleared
    )

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      _onCoreConnected
    )

    $rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onResume
    )

    $rootScope.$on(
      BAS_ROOM.EVT_SCENES_UPDATED,
      _onScenesUpdated
    )

    $rootScope.$on(
      BAS_SCENE.EVT_IMAGES_UPDATED,
      _onSceneImageUpdated
    )

    $rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    )

    $transitions.onSuccess(
      {},
      _onStateSuccess
    )
  }
  function _handleUniversalLink (error, result) {

    if (error) return
    if (result && result.query) {
      _onWidgetMessage(result.query)
    }
  }

  /**
   * @private
   * @returns {?TBasSmartConnect}
   */
  function getTarget () {
    return (
      state.smartConnectTarget &&
      state.smartConnectTarget.preferredProjectId &&
      state.sceneCtrlMessage &&
      (Date.now() - state.timeStamp) < MAX_TIMESTAMP_TIMEOUT
    )
      ? state.smartConnectTarget
      : null
  }

  // Triggered when splashscreen gets shown or when disconnected from app
  function _onSplashVisibility (_event, isVisible) {
    const _currentState = $uiRouterGlobals.current.name

    if (!isVisible) {

      if (
        BasStateHelper.hasBaseState(_currentState, STATES.MAIN) &&
        state.name
      ) {
        BasModal.show(
          BAS_MODAL.T_SCENE_ACTIVATED,
          {
            subtitle: state.name
          }
        )
        state.name = ''
      }
    }
  }

  function _onRoomsUpdated (_evt, type) {
    if (type === BAS_ROOMS.P_ROOMS) _syncScenes()
  }

  function _onRoomsCleared (_evt) {
    _syncScenes()
  }

  function _onCoreConnected (
    _event,
    _basCoreContainer,
    isConnected
  ) {

    if (isConnected) {

      _syncScenes()

      if (_connectedToTargetServer() && state.sceneCtrlMessage) {

        currentBasCoreState.core.core.send(state.sceneCtrlMessage)
        clearState()
      }
    }
  }

  // Triggered when staying in main state and going out of app
  // then selecting a scene from widget
  function _onResume () {
    const currentStateName = $uiRouterGlobals.current.name

    if (_connectedToTargetServer() && state.sceneCtrlMessage) {

      currentBasCoreState.core.core.send(state.sceneCtrlMessage)
      clearState()

      if (
        BasStateHelper.hasBaseState(currentStateName, STATES.MAIN) &&
        state.name &&
        !BasSplash.get().uiShow
      ) {

        BasModal.show(
          BAS_MODAL.T_SCENE_ACTIVATED,
          {
            subtitle: state.name
          }
        )

        state.name = ''
      }
    }
  }

  /**
   * @private
   * @returns {boolean}
   */
  function _connectedToTargetServer () {

    if (getTarget() && CurrentBasCore.hasCore()) {

      const _projectId = state.smartConnectTarget.preferredProjectId
      const _basServer = CurrentBasCore.getServer()

      return (
        _basServer &&
        _basServer.isCoreConnected() &&
        _basServer.cid === _projectId &&
        _basServer.getProfileForName(
          state.smartConnectTarget.preferred.credentials.username
        )
      )
    }

    return false
  }

  // Triggered on app startup and when selecting a scene
  // from a non-main state
  function _onStateSuccess (transition) {

    const to = transition.targetState()
    const from = transition.from()

    if (
      !BasStateHelper.hasBaseState(from.name, STATES.MAIN) &&
      BasStateHelper.hasBaseState(to.name(), STATES.MAIN) &&
      state.name &&
      !BasSplash.get().uiShow
    ) {

      BasModal.show(
        BAS_MODAL.T_SCENE_ACTIVATED,
        {
          subtitle: state.name
        }
      )
      state.name = ''
    }
  }

  function _onScenesUpdated (_event, basRoom) {

    if (basRoom) {
      const home = RoomsHelper.getHome()
      if (home && home.id === basRoom.id) {
        _syncScenes()
      }
    }
  }

  function _onSceneImageUpdated (_event, basRoom) {

    if (basRoom) {
      const home = RoomsHelper.getHome()
      if (home && home.id === basRoom.id) _syncScenes()
    }
  }

  function _syncScenes () {

    let length = 0
    let favouriteScenes = []
    basWidgetsDataArray = []

    const basWidgetsPlugin = _getBasWidgetsPlugin()

    if (basWidgetsPlugin && CurrentBasCore.hasCore()) {
      const home = RoomsHelper.getHome()
      const basServer = CurrentBasCore.getServer()

      if (home?.scenes?.basSceneCtrl) {

        const favouriteSceneKeysObj = home.scenes.favouriteScenes

        favouriteScenes = _getFavouriteScenes(
          favouriteSceneKeysObj,
          home.scenes.scenes
        )

        length = favouriteScenes.length
      }

      const lastServer = BasServerStorage.getLastServer()

      if (basServer && lastServer) {

        const standardData = {
          projectUuid: lastServer.cid,
          sceneCtrlUuid: home?.scenes?.basSceneCtrl?.uuid ?? '',
          user: basServer.connectedCredentials[0].user,
          credentialHash: basServer.connectedCredentials[0].credentialHash,
          basWidgetsServerAddress:
            BasServerStorage.getServerByProjectId(lastServer.cid)
              ? BasServerStorage.getServerByProjectId(lastServer.cid).address
              : '',
          basWidgetsServerName: basServer.getName(),
          basWidgetsDisplayTitle: BasUtilities.translate(
            'widget_favourite_scenes_title'
          ),
          basWidgetsDisplayDescription: BasUtilities.translate(
            'widget_favourite_scenes_description'
          ),
          placeHolder: BasUtilities.translate(
            'no_scenes_favourites'
          )
        }

        for (let i = 0; i < length; i++) {

          const favouriteScene = favouriteScenes[i]

          const sceneImageName = getImageNameOfScene(
            favouriteScene.favouriteSceneTemplateKey
          )

          const sceneImageFileNameSplit = sceneImageName.split('.')

          const project = {
            favouriteSceneName: favouriteScene.favouriteSceneName,
            favouriteSceneUuid: favouriteScene.favouriteSceneUuid,
            basWidgetsImageName: sceneImageName
              ? sceneImageFileNameSplit.slice(0, -1).join('.')
              : '',
            basWidgetsImageExtension: sceneImageName
              ? sceneImageFileNameSplit.pop()
              : '',
            basWidgetsLocalImagePath: PROJECT_SCENE_IMG_PATH,
            basWidgetsCustomImagePath:
              favouriteScene.favouriteSceneCustomImagePath,
            basWidgetsUrlPath: BAS_WIDGETS_URL_PATH,
            basWidgetsUrlHost: BAS_WIDGETS_URL_HOST
          }

          basWidgetsDataArray.push(project)
        }

        _setWidget(basWidgetsDataArray, standardData)
      }
    }
  }

  /**
   * @private
   * @param {string} sceneTemplateKey
   * @returns {string}
   */
  function getImageNameOfScene (sceneTemplateKey) {
    const scenePreset = BasScenePresetsHelper.getPreset(sceneTemplateKey)

    if (scenePreset.images && scenePreset.images.imgw200) {

      // Assets/www/img/scenes/200/...
      const sceneImagePath = scenePreset.images.imgw200

      const sceneImageNamePath = sceneImagePath.lastIndexOf('/')
      if (sceneImageNamePath > -1) {

        // Get scene image
        const sceneImageName = sceneImagePath.substring(
          sceneImageNamePath + 1
        )

        if (sceneImageName.lastIndexOf('.') > -1) {
          return sceneImageName
        }
      }
    }

    return ''
  }

  /**
   * @private
   * @param {Object} scenes
   * @param {Object} homeScenes
   * @returns {Object[]}
   */
  function _getFavouriteScenes (scenes, homeScenes) {
    const keys = Object.keys(scenes)
    const favouriteScenes = []

    const length = keys.length
    for (let i = 0; i < length; i++) {

      const sceneId = keys[i]

      if (scenes[sceneId]) {

        const favouriteScene = homeScenes[sceneId]
        if (favouriteScene != null) {
          favouriteScenes.push(
            {
              favouriteSceneUuid: favouriteScene.uuid,
              favouriteSceneName: favouriteScene.name,
              favouriteSceneTemplateKey: favouriteScene.template.key,
              favouriteSceneCustomImagePath: favouriteScene.unsavedImages
                ? favouriteScene.unsavedImages.imgw400
                : favouriteScene.customImages
                  ? favouriteScene.customImages.imgw400
                  : ''
            }
          )
        }
      }
    }

    return favouriteScenes
  }

  function _setWidget (dataArray, standardData) {

    const basWidgetsPlugin = _getBasWidgetsPlugin()

    if (
      basWidgetsPlugin &&
      BasUtil.isFunction(basWidgetsPlugin.setWidget)
    ) {

      // API is subtly different in the Capacitor version
      if (BasWidgetsPluginCapacitor) {
        basWidgetsPlugin.setWidget({ dataArray, standardData })
      } else {
        basWidgetsPlugin.setWidget(dataArray, standardData)
      }
    }
  }

  function clearState () {
    state.smartConnectTarget = null
    state.sceneCtrlMessage = null
    state.timeStamp = 0
  }

  /**
   * Fired when basWidget plugin sends messages
   *
   * @private
   * @param {string} message
   */
  function _onWidgetMessage (message) {

    if (message) {
      const parsedJSONData = _getParsedData(message)
      if (parsedJSONData) {

        const timeStamp = Date.now()
        const favouriteSceneUuid = parsedJSONData[K_FAVOURITE_SCENE_UUID]
        const name = parsedJSONData[K_FAVOURITE_SCENE_NAME]
        const projectUuid = parsedJSONData[K_PROJECT_UUID]
        const sceneCtrlUuid = parsedJSONData[K_SCENE_CTRL_UUID]

        if (favouriteSceneUuid && projectUuid && sceneCtrlUuid) {

          const sceneCtrlMessage =
            BAS_API.SceneCtrlDevice.createSceneCrlMessage(
              sceneCtrlUuid,
              favouriteSceneUuid,
              K_ACTION_ACTIVATE
            )

          const _basConnectInfo = BasConnectInfo.build(
            BAS_CONNECT.PREFIX_LIVE_PROJECT + projectUuid
          )

          _basConnectInfo.setCredentials(
            {
              username: parsedJSONData[K_USER],
              credentialHash: parsedJSONData[K_CREDENTIAL_HASH]
            }
          )

          const _smartConnect = {
            preferred: _basConnectInfo,
            preferredProjectId: projectUuid
          }

          state.smartConnectTarget = _smartConnect
          state.name = name
          state.sceneCtrlMessage = sceneCtrlMessage
          state.timeStamp = timeStamp
        }
      }
    }
  }

  /**
   * @private
   * @param {string} data
   * @returns {*}
   */
  function _getParsedData (data) {

    try {
      const decodedData = Querystringify.parse(data)
      if (decodedData) return decodedData
    } catch (e) {
      return null
    }

    return null
  }

  function _getBasWidgetsPlugin () {

    // Try the Capacitor plugin first
    if (!BasWidgetsPluginCapacitor &&
          Capacitor.isPluginAvailable('BasWidgets')) {
      BasWidgetsPluginCapacitor = registerPlugin('BasWidgets')
    }
    if (BasWidgetsPluginCapacitor) {
      return BasWidgetsPluginCapacitor
    }

    // Otherwise try the cordova plugin
    return (
      $window['basalteCordova'] &&
      $window['basalteCordova']['BasWidgets']
    )
      ? $window['basalteCordova']['BasWidgets']
      : null
  }

  function _getBasUniversalLinksPlugin () {

    return (
      $window['basalteCordova'] &&
      $window['basalteCordova']['BasUniversalLinks']
    )
      ? $window['basalteCordova']['BasUniversalLinks']
      : null
  }
}
