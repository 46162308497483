'use strict'

angular
  .module('basalteApp')
  .service('BasSharedServerStorage', [
    '$rootScope',
    'BAS_CURRENT_CORE',
    'BAS_SHARED_SERVER_STORAGE',
    'CurrentBasCore',
    BasSharedServerStorage
  ])

/**
 * @typedef {Object} TBasSharedServerStorageState
 * @property {Object<string, string[]>} sceneOrder
 * @property {Object<string, string[]>} sceneFavourites
 * @property {Object<string, Object>} lightGroupOrder
 */

/**
 * Cache for shared server storage. Only updates on events.
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_SHARED_SERVER_STORAGE} BAS_SHARED_SERVER_STORAGE
 * @param {CurrentBasCore} CurrentBasCore
 */
function BasSharedServerStorage (
  $rootScope,
  BAS_CURRENT_CORE,
  BAS_SHARED_SERVER_STORAGE,
  CurrentBasCore
) {
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasSharedServerStorageState}
   */
  var state = {
    sceneOrder: {},
    sceneFavourites: {},
    lightGroupOrder: {}
  }

  this.get = get

  init()

  function init () {

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_SCENES_ORDER,
      _onSceneOrder
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_SCENES_FAVOURITES,
      _onSceneFavourites
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_LIGHT_GROUP_ORDER,
      _onLightGroupOrder
    )
  }

  /**
   * @returns {TBasSharedServerStorageState}
   */
  function get () {

    return state
  }

  function _onSceneOrder () {

    var _sharedStorage

    _sharedStorage = _getSharedStorage()

    if (_sharedStorage) {

      state.sceneOrder = _sharedStorage.scenesOrder

      $rootScope.$emit(
        BAS_SHARED_SERVER_STORAGE.EVT_SCENE_ORDER_UPDATED,
        state.sceneOrder
      )
    }
  }

  function _onSceneFavourites () {

    var _sharedStorage

    _sharedStorage = _getSharedStorage()

    if (_sharedStorage) {

      state.sceneFavourites = _sharedStorage.scenesFavourites

      $rootScope.$emit(
        BAS_SHARED_SERVER_STORAGE.EVT_SCENE_FAVOURITES_UPDATED,
        state.sceneFavourites
      )
    }
  }

  function _onLightGroupOrder () {

    const _sharedStorage = _getSharedStorage()

    if (_sharedStorage) {

      state.lightGroupOrder = _sharedStorage.lightGroupOrder

      $rootScope.$emit(
        BAS_SHARED_SERVER_STORAGE.EVT_LIGHT_GROUP_ORDER_UPDATED,
        state.lightGroupOrder
      )
    }
  }

  /**
   * @private
   * @returns {?SharedServerStorage}
   */
  function _getSharedStorage () {

    return CurrentBasCore.hasCore()
      ? currentBasCoreState.core.core.sharedServerStorage
      : null
  }
}
