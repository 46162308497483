'use strict'

angular
  .module('basalteApp')
  .directive('basSplash', [
    'BAS_HTML',
    basSplashDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basSplash
 */
function basSplashDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.splash,
    controller: [
      'STATES',
      'BAS_SPLASH',
      'BasState',
      'BasCoreConnection',
      'BasSplash',
      controller
    ],
    controllerAs: 'splash'
  }

  /**
   * @param {STATES} STATES
   * @param {BAS_SPLASH} BAS_SPLASH
   * @param {BasState} BasState
   * @param {BasCoreConnection} BasCoreConnection
   * @param {BasSplash} BasSplash
   */
  function controller (
    STATES,
    BAS_SPLASH,
    BasState,
    BasCoreConnection,
    BasSplash
  ) {
    var splash = this

    /**
     * @type {TBasSplashState}
     */
    splash.basSplashState = BasSplash.get()

    splash.handleActionClick = handleActionClick
    splash.handleServersClick = handleServersClick

    function handleActionClick () {

      var s, param

      param = splash.basSplashState.actionParam
      s = splash.basSplashState.state

      if (
        s === BAS_SPLASH.S_CONNECTING_FAILED ||
        s === BAS_SPLASH.S_CONNECTING_FAILED_NOT_AUTHORIZED ||
        s === BAS_SPLASH.S_CONNECTING_FAILED_NO_INTEGRATOR_ACCESS ||
        s === BAS_SPLASH.S_CONNECTING_FAILED_NO_ACTIVE_SERVERS
      ) {
        if (Array.isArray(param)) {
          BasCoreConnection.retry.apply(null, param)
        }
      }
    }

    function handleServersClick () {

      BasCoreConnection.prepareChangeBasCore({
        stopDiscovery: false,
        stopAutoReconnect: true
      })

      BasState.go(STATES.CONNECT_DISCOVERY)

      BasSplash.hide()
    }
  }
}
