'use strict'

var EventEmitter = require('@gidw/event-emitter-js')
var Device = require('./device')

var P = require('./parser_constants')

var log = require('./logger')

/**
 * @typedef {Object} TAudioAlertEventPlay
 * @property {string} name
 * @property {string} uuid
 * @property {number} repeat
 * @property {boolean} playAudio
 * @property {number} [duration]
 * @property {string} [audio]
 */

/**
 * Class representing a subscribable topic
 *
 * @constructor
 * @extends EventEmitter
 * @param {BasCore} basCore
 */
function Topic (basCore) {

  EventEmitter.call(this)

  this._basCore = basCore

  this._subscribed = false

  this.topic = ''
}

Topic.prototype = Object.create(EventEmitter.prototype)
Topic.prototype.constructor = Topic

Topic.EVT_SUBSCRIBED = 'topicSubscribed'

Topic.prototype.subscribe = function () {

  var obj = {}
  obj[P.DATA] = {}
  obj[P.DATA][P.ACTION] = P.SUBSCRIBE
  obj[P.DATA][P.TOPIC] = this.topic

  return this._basCore.requestV2(obj)
    .then(Device.handleResponse)
    .then(
      this._onTopicSubscribed.bind(this),
      this._onTopicSubscribeError.bind(this)
    )
}

Topic.prototype.parse = function () {
  // Empty
}

/**
 * @private
 */
Topic.prototype._onTopicSubscribed = function () {

  this._subscribed = true

  this.emit(Topic.EVT_SUBSCRIBED, this.topic)
}

/**
 * @param error
 * @private
 */
Topic.prototype._onTopicSubscribeError = function (error) {

  log.warn(
    'Topic - Failed to subscribe to ' + this.topic + ' topic:' +
    error
  )
}

/**
 * @name Topic#subscribed
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Topic.prototype, 'subscribed', {
  get: function () {
    return this._subscribed
  }
})

module.exports = Topic
