'use strict'

angular
  .module('basalteApp')
  .service('MainDrawer', MainDrawer)

/**
 * @typedef {Object} MainDrawerState
 * @property {Object} css
 */

/**
 * Helper service for managing drawer state in some parts of the app
 *
 * @constructor
 */
function MainDrawer () {

  var CSS_DRAWER_HIDE = 'bmsd-hide'

  /**
   * @type {MainDrawerState}
   */
  var _state = {
    css: {}
  }
  _resetCss()

  this.get = get
  this.toggle = toggle

  /**
   * @returns {MainDrawerState}
   */
  function get () {

    return _state
  }

  /**
   * Show drawer (true or false)
   *
   * @param {boolean} [force]
   */
  function toggle (force) {

    _state.css[CSS_DRAWER_HIDE] = typeof force === 'boolean'
      ? !force
      : !_state.css[CSS_DRAWER_HIDE]
  }

  function _resetCss () {
    _state.css[CSS_DRAWER_HIDE] = false
  }
}
