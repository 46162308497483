'use strict'

angular
  .module('basalteApp')
  .factory('BasRoomsComponent', [
    'RoomFilter',
    basRoomsComponentFactory
  ])

/**
 * @param RoomFilter
 * @returns BasRoomsComponent
 */
function basRoomsComponentFactory (
  RoomFilter
) {
  /**
   * @constructor
   */
  function BasRoomsComponent () {

    /**
     * @type {Object<string, RoomFilter>}
     */
    this.filters = {}

    /**
     * @type {Array<string[]>}
     */
    this.uiFilters = []

    /**
     * @type {string}
     */
    this.uiCurrentFilter = RoomFilter.SRV_ALL

    /**
     * @type {string}
     */
    this.uiCurrentFilterBuilding = ''

    /**
     * @type {BasCollection[]}
     */
    this.uiAllCollection = []

    /**
     * @type {BasCollection[]}
     */
    this.uiCurrentCollection = []

    /**
     * @type {boolean}
     */
    this.hasMultipleBuildings = false
  }

  /**
   * @param {BasCollection[]} collection
   */
  BasRoomsComponent.prototype.setUiCurrentCollections =
    function (collection) {
      this.uiCurrentCollection = collection
    }

  /**
   * @param {string} _roomId
   */
  BasRoomsComponent.prototype.getUniqueNameForRoom = function (_roomId) {
    // TODO
  }

  BasRoomsComponent.prototype.updateTranslation = function () {

    if (this.uiAllCollection) {

      _collectionUpdateTranslation(this.uiAllCollection)
    }

    if (this.uiCurrentCollection) {

      _collectionUpdateTranslation(this.uiCurrentCollection)
    }

    /**
     * @private
     * @param {BasCollection[]} collections
     */
    function _collectionUpdateTranslation (collections) {

      var length, i, collection

      length = collections.length
      for (i = 0; i < length; i++) {

        collection = collections[i]

        if (collection && collection.updateTranslation) {

          collection.updateTranslation()
        }
      }
    }
  }

  BasRoomsComponent.prototype.clearRoomVariables = function () {

    this.clearUiFilters()

    this.uiAllCollection = []
    this.uiCurrentCollection = []
  }

  BasRoomsComponent.prototype.clearUiFilters = function () {

    this.filters = {}
    this.uiFilters = []
  }

  return BasRoomsComponent
}
