'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasTimerDay', [
    'BAS_API',
    'BasSchedulerDay',
    'BasSchedulerPoint',
    'BasTimerPoint',
    basTimerDayFactory
  ])

/**
 * @param BAS_API
 * @param BasSchedulerDay
 * @param BasSchedulerPoint
 * @param BasTimerPoint
 * @returns BasTimerDay
 */
function basTimerDayFactory (
  BAS_API,
  BasSchedulerDay,
  BasSchedulerPoint,
  BasTimerPoint
) {
  /**
   * @constructor
   * @extends BasSchedulerDay
   * @param {string} id
   * @param {string} [type]
   */
  function BasTimerDay (id, type) {

    BasSchedulerDay.call(this, id)

    /**
     * @type {string}
     */
    this.type = BasUtil.isNEString(type) ? type : BAS_API.Timer.T_ON_OFF
  }

  BasTimerDay.prototype = Object.create(BasSchedulerDay.prototype)
  BasTimerDay.prototype.constructor = BasTimerDay

  /**
   * @param points
   * @param type
   */
  BasTimerDay.prototype.parse = function (points, type) {

    var length, i, point, time

    this.points = []

    if (Array.isArray(points)) {

      length = points.length
      for (i = 0; i < length; i++) {

        point = points[i]

        if (BasUtil.isObject(point)) {

          time = point.time

          if (BasUtil.isVNumber(time)) {

            this.points.push(new BasTimerPoint(point, type))
          }
        }
      }

      this.points.sort(BasSchedulerPoint.compareX)
    }

    this.generateUiPoints()
  }

  /**
   * @param {BasSchedulerPoint} point
   * @param {number} x
   * @returns {boolean}
   */
  BasTimerDay.prototype.isXAllowed = function (
    point,
    x
  ) {
    var length, i, _point

    length = this.points.length
    for (i = 0; i < length; i++) {

      _point = this.points[i]

      if (_point !== point && _point && _point.x === x) {

        return false
      }
    }

    return true
  }

  /**
   * @param {string} [type]
   * @returns {?BasTimerPoint}
   */
  BasTimerDay.prototype.addNewPoint = function (type) {

    var _type, i, points, newPoint

    _type = BasUtil.isNEString(type) ? type : this.type

    // Iterate over every 15 min

    for (i = 0; i < 86400; i += 900) {

      points = this.getPointsWithX(i)

      if (!points.length) {

        newPoint = new BasTimerPoint(null, _type)
        newPoint.new = true
        newPoint.setXValue(i)

        switch (_type) {
          case BAS_API.Timer.T_ON_OFF:

            newPoint.setYValue(0)

            break
          case BAS_API.Timer.T_PERCENTAGE:

            newPoint.setYValue(0.5)

            break
        }

        this.points.push(newPoint)

        this.points.sort(BasSchedulerPoint.compareX)
        this.generateUiPoints()

        return newPoint
      }
    }

    return null
  }

  /**
   * @returns {TTimerPoint[]}
   */
  BasTimerDay.prototype.generateApiDay = function () {

    var result, length, i, point

    result = []

    length = this.points.length
    for (i = 0; i < length; i++) {

      point = this.points[i]

      if (point) result.push(point.getApiTimerPoint())
    }

    return result
  }

  /**
   * @returns {string}
   */
  BasTimerDay.prototype.makeDataString = function () {

    var result, length, i, point

    result = ''

    length = this.points.length
    for (i = 0; i < length; i++) {

      point = this.points[i]

      if (point) {

        result += point.time
        result += point.uiSimpleValue
      }
    }

    return result
  }

  return BasTimerDay
}
