'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('DefaultRoomsSettingsState', [
    '$transitions',
    'STATES',
    'SourcesHelper',
    DefaultRoomsSettingsState
  ])

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {SourcesHelper} SourcesHelper
 */
function DefaultRoomsSettingsState (
  $transitions,
  STATES,
  SourcesHelper
) {
  init()

  function init () {

    $transitions.onStart(
      {
        to: STATES.SETTINGS_MUSIC_DEFAULT_ROOMS + '.**'
      },
      _onState
    )
  }

  function _onState (transition) {

    var targetState, toName, params, basSource

    targetState = transition.targetState()
    toName = targetState.name()

    if (BasUtil.startsWith(toName, STATES.SETTINGS_MUSIC_DEFAULT_ROOMS_ROOMS)) {

      params = targetState.params()
      basSource = SourcesHelper.getBasSource(params.stream)

      if (basSource && basSource.defaultRooms) {

        // Allowed

      } else {

        return targetState.withState(STATES.SETTINGS_MUSIC_DEFAULT_ROOMS)
      }
    }
  }
}
