'use strict'

angular
  .module('basalteApp')
  .directive('deviceKeepAlive', deviceKeepAlive)

function deviceKeepAlive () {

  return {
    restrict: 'AE',
    template:
      '<div class="bbs bbs-item bbs-centered bbs-flex bbs-active-select"' +
      '     bas-click="keepAlive.toggleKeepAlive()">' +
      '  <div class="bbs-name ellipsis-overflow"' +
      '       data-translate="keep_awake_device"></div>' +
      '  <bas-toggle class="bbs-toggle bas-toggle-dark"' +
      '              data-bas-toggle="keepAlive.toggleKeepAlive()"' +
      '              data-bas-toggled="keepAlive.keepAlive"></bas-toggle>' +
      '</div>',
    controller: [
      'BasPreferences',
      controller
    ],
    controllerAs: 'keepAlive'
  }

  /**
   * @param {BasPreferences} BasPreferences
   */
  function controller (BasPreferences) {

    var keepAlive = this

    keepAlive.keepAlive = BasPreferences.getKeepAlive()

    keepAlive.toggleKeepAlive = toggleKeepAlive

    function toggleKeepAlive () {

      keepAlive.keepAlive = !keepAlive.keepAlive
      BasPreferences.setKeepAlive(keepAlive.keepAlive)
    }
  }
}
