'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasStep', [
    'BAS_API',
    'BAS_HOME',
    'BAS_ROOMS',
    'BAS_SOURCE',
    'BAS_SOURCES',
    'BasAppTemperature',
    'BasLight',
    'BasShade',
    'BasGenericDeviceControl',
    'BasThermostat',
    'BasThermostatControl',
    'BasUtilities',
    basStepFactory
  ])

/**
 * @param BAS_API
 * @param {BAS_HOME} BAS_HOME
 * @param BAS_ROOMS
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BasAppTemperature} BasAppTemperature
 * @param BasLight
 * @param BasShade
 * @param BasGenericDeviceControl
 * @param BasThermostat
 * @param BasThermostatControl
 * @param {BasUtilities} BasUtilities
 * @returns BasStep
 */
function basStepFactory (
  BAS_API,
  BAS_HOME,
  BAS_ROOMS,
  BAS_SOURCE,
  BAS_SOURCES,
  BasAppTemperature,
  BasLight,
  BasShade,
  BasGenericDeviceControl,
  BasThermostat,
  BasThermostatControl,
  BasUtilities
) {
  var CSS_REGULAR = 'bas-step-regular'
  var CSS_TIMER = 'bas-step-timer'
  var CSS_SUBTITLE = 'bas-step-subtitle'
  var CSS_ARROW_SHOW = 'bas-step-arrow-button-show'
  var CSS_REMOVE_SHOW = 'bas-step-remove-button-show'
  var CSS_HAS_TITLE_DIVIDER = 'bas-step-has-title-divider'

  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  var DEF_TEMPERATURE = 20

  /**
   * Music and video step contain the same properties hence StepAV
   *
   * @constructor
   */
  function StepAV () {

    /**
     * @type {string}
     */
    this.source = ''

    /**
     * @type {number}
     */
    this.volume = -1

    /**
     * @type {string}
     */
    this.favourite = ''
  }

  /**
   * @constructor
   */
  function StepScene () {

    /**
     * @type {string}
     */
    this.controllerUuid = ''

    /**
     * @type {string}
     */
    this.scene = ''
  }

  /**
   * @constructor
   * @param {string} uuid
   * @param {TStep} [step]
   */
  function BasStep (uuid, step) {

    /**
     * @type {string}
     */
    this.uuid = uuid

    /**
     * @type {string}
     */
    this.room = ''

    /**
     * @type {string}
     */
    this.function = ''

    /**
     * Delay in seconds
     *
     * @type {number}
     */
    this.delay = -1

    /**
     * @type {string}
     */
    this.uiTitle = ''

    /**
     * @type {string}
     */
    this.uiSubtitle = ''

    /**
     * Controlled devices
     *
     * @type {string}
     */
    this.uiSummary = ''

    /**
     * Room for the step (can be left empty, ex. delay step)
     *
     * @type {string}
     */
    this.uiRoom = ''

    /**
     * The function of the step
     *
     * @type {string}
     */
    this.uiFunction = ''

    /**
     * @type {?StepAV}
     */
    this.music = null

    /**
     * @type {?StepAV}
     */
    this.video = null

    /**
     * @type {?StepScene}
     */
    this.scene = null

    /**
     * @type {?BasThermostat}
     */
    this.thermostat = null

    /**
     * @type {Object<string, ?BasShade>}
     */
    this.shades = null

    /**
     * @type {Object<string, ?BasGenericDeviceControl>}
     */
    this.controls = null

    /**
     * @type {Object<string, ?BasLight>}
     */
    this.lights = null

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this.resetCss()

    // Regular step is the default
    this.css[CSS_REGULAR] = true

    if (BasUtil.isObject(step)) this._parse(step)
  }

  /**
   * Checks whether there is a valid delay on the object present
   *
   * @param {Object} step
   * @returns {boolean}
   */
  BasStep.isDelayStep = function (step) {
    return (
      BasUtil.isObject(step) &&
      BasUtil.isPNumber(step.delay, true)
    )
  }

  /**
   * Checks whether there is a valid delay on the object present
   *
   * @param {Object} step
   * @returns {boolean}
   */
  BasStep.isFunctionStep = function (step) {
    return (
      BasUtil.isObject(step) &&
      BasUtil.isNEString(step.room) &&
      BasUtil.isNEString(step.function)
    )
  }

  /**
   * Parse API lights
   *
   * @private
   * @param {Object<string, TSceneLight>} lights
   * @returns {Object<string, BasLight>}
   */
  BasStep._parseLights = function (lights) {

    var result, _time, keys, i, length, uuid, light, basLight

    result = {}

    _time = Date.now()

    keys = Object.keys(lights)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      light = lights[uuid]

      if (BasUtil.isObject(light)) {

        basLight = new BasLight(uuid)
        basLight.parseSceneLight(light, _time)

        result[keys[i]] = basLight
      }
    }

    return result
  }

  /**
   * Parse API shades
   *
   * @private
   * @param {Object<string, TSceneShade>} shades
   * @returns {Object<string, BasShade>}
   */
  BasStep._parseShades = function (shades) {

    var result, _time, keys, i, length, uuid, shade, basShade

    result = {}

    _time = Date.now()

    keys = Object.keys(shades)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      shade = shades[uuid]

      if (BasUtil.isObject(shade)) {

        basShade = new BasShade(uuid)
        basShade.parseSceneShade(shade, _time)

        result[keys[i]] = basShade
      }
    }

    return result
  }

  /**
   * Parse API devices controls
   *
   * @private
   * @param {Object<string, TSceneDeviceControl>} controls
   * @returns {Object<string, BasGenericDeviceControl>}
   */
  BasStep._parseControls = function (controls) {

    const result = {}

    const keys = Object.keys(controls)
    const length = keys.length

    for (let i = 0; i < length; i++) {

      const uuid = keys[i]
      const control = controls[uuid]

      if (BasUtil.isObject(control)) {

        const basControl = new BasGenericDeviceControl()
        basControl.uuid = uuid

        // Before parsing the scene control, we first need to determine the
        //  type of the control, so we can use that to set the value in the
        //  scene object in the correct format.
        // E.g. Percentage slider has values of [0-100], but is saved in scene
        //  as [0-1], while a generic number input is saved as is.

        BasStep.syncControlType(basControl)
        basControl.parseSceneControl(control)

        result[uuid] = basControl
      }
    }

    return result
  }

  /**
   * Sync control type with real device control type
   *
   * @private
   * @param {BasGenericDeviceControl} basControl
   */
  BasStep.syncControlType = function (basControl) {

    let changed = false

    if (basControl) {

      for (const basRoom of Object.values(BAS_ROOMS.ROOMS.rooms)) {

        const devices = basRoom?.genericDevices?.devices

        if (devices) {
          for (const device of devices) {
            const realControl = device.getControlById(basControl.uuid)
            if (!realControl) continue

            if (realControl.type !== basControl.type) {
              basControl.type = realControl.type

              if (
                basControl.type === BAS_API.GenericDeviceControl.T_NUMERIC_INPUT
              ) {
                // Control value was incorrectly parsed before knowing the
                //  actual control type, correct it.
                basControl.value /= 100

                changed = true
              }
            }
            break
          }
        }
      }
    }
    return changed
  }

  /**
   * Sync all control types
   */
  BasStep.prototype.syncAllControlTypes = function () {

    if (this.controls) {

      for (const control of Object.values(this.controls)) {

        if (BasStep.syncControlType(control)) {

          // Value has changed, we need to update the summary
          this.updateSummary()
        }
      }
    }
  }

  /**
   * Parse API thermostat
   *
   * @private
   * @param {TSceneThermostat} thermostat
   * @returns {BasThermostat}
   */
  BasStep._parseThermostat = function (thermostat) {

    var keys, i, length, controls, uuid, active, basControl
    var result

    result = new BasThermostat()

    if (BasUtil.isNEString(thermostat.uuid)) result.uuid = thermostat.uuid
    if (BasUtil.isVNumber(thermostat.thermostatMode)) {

      result.setMode(BasThermostat.getMode(thermostat.thermostatMode))
    }

    if (BasUtil.isVNumber(thermostat.fanMode)) {

      result.setFanMode(BasThermostat.getFanMode(thermostat.fanMode))
    }

    if (BasUtil.isObject(thermostat.setPoint)) {

      result.desired = thermostat.setPoint
      result.toggleIncludeDesired(true)

    } else {

      result.desired = new BAS_API.Temperature()
      result.desired.setTemperature(
        DEF_TEMPERATURE,
        BAS_API.CONSTANTS.TU_CELSIUS
      )
    }

    if (BasUtil.isNEObject(thermostat.controls)) {

      controls = thermostat.controls

      keys = Object.keys(thermostat.controls)
      length = keys.length
      for (i = 0; i < length; i++) {

        uuid = keys[i]
        active = controls[uuid]

        basControl = new BasThermostatControl()
        basControl.uuid = uuid
        basControl.parseSceneControl(active)

        result.controls.push(basControl)
      }
    }

    return result
  }

  /**
   * Parse API subScene
   *
   * @private
   * @param {TSceneSubscene} subScene
   * @returns {StepScene}
   */
  BasStep._parseSubScene = function (subScene) {

    var obj = new StepScene()

    obj.controllerUuid = subScene.uuid
    obj.scene = subScene.scene

    return obj
  }

  /**
   * Parse API AV
   * Music and video step contain the same properties
   *
   * @private
   * @param {TSceneAV} av
   * @returns {StepAV}
   */
  BasStep._parseAV = function (av) {

    var obj = new StepAV()

    if (BasUtil.isString(av.sourceUuid)) {

      obj.source = av.sourceUuid
        ? av.sourceUuid
        : BAS_HOME.NO_SOURCE_UUID
    }

    obj.volume = BasUtil.isPNumber(av.volume, true)
      ? av.volume
      : -1

    if (BasUtil.isNEString(av.content)) {

      obj.favourite = av.content
    }

    return obj
  }

  /**
   * @private
   * @param {Object<string, BasLight>} lights
   * @returns {Object<string, TSceneLight>}
   */
  BasStep._generateLights = function (lights) {

    var result, i, keys, length, uuid, light

    result = {}

    keys = Object.keys(lights)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      light = lights[uuid]

      if (BasUtil.isObject(light)) result[uuid] = light.getSceneObj()
    }

    return result
  }

  /**
   * @private
   * @param {Object<string, BasLight>} lights
   * @returns {string}
   */
  BasStep._generateLightsString = function (lights) {

    var result, i, keys, length, uuid, light
    result = ''

    keys = Object.keys(lights)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      light = lights[uuid]

      if (BasUtil.isObject(light)) result += light.getString()
    }

    return result
  }

  /**
   * @private
   * @param {Object<string, BasShade>} shades
   * @returns {Object<string, TSceneShade>}
   */
  BasStep._generateShades = function (shades) {

    var result, i, keys, length, uuid, shade
    result = {}

    keys = Object.keys(shades)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      shade = shades[uuid]

      if (BasUtil.isObject(shade)) result[uuid] = shade.getSceneObj()
    }

    return result
  }

  /**
   * @private
   * @param {Object<string, BasGenericDeviceControl>} controls
   * @returns {Object<string, TSceneDeviceControl>}
   */
  BasStep._generateControls = function (controls) {

    var result, i, keys, length, uuid, control
    result = {}

    keys = Object.keys(controls)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      control = controls[uuid]

      if (BasUtil.isObject(control)) result[uuid] = control.getSceneObj()
    }

    return result
  }

  /**
   * @private
   * @param {Object<string, BasShade>} shades
   * @returns {string}
   */
  BasStep._generateShadesString = function (shades) {

    var result, i, keys, length, uuid, shade
    result = ''

    keys = Object.keys(shades)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      shade = shades[uuid]

      if (BasUtil.isObject(shade)) {

        result += 'openClose' + shade.opened
        result += 'autoMode' + shade.autoMode
        result += 'position' + shade.position
        result += 'rotation' + shade.rotation
      }
    }

    return result
  }

  /**
   * @private
   * @param {Object<string, BasGenericDeviceControl>} controls
   * @returns {string}
   */
  BasStep._generateControlsString = function (controls) {

    var result, i, keys, length, uuid, control
    result = ''

    keys = Object.keys(controls)
    length = keys.length

    for (i = 0; i < length; i++) {

      uuid = keys[i]
      control = controls[uuid]

      if (BasUtil.isObject(control)) {

        result += control.name

        if (control.canEdit) result += control.uiValue
      }
    }

    return result
  }

  /**
   * @private
   * @param {BasThermostat} thermostat
   * @returns {TSceneThermostat}
   */
  BasStep._generateThermostat = function (thermostat) {

    var i, control
    var result = {}

    result.uuid = thermostat.uuid

    if (thermostat.includeInScene.mode) {

      result.thermostatMode = thermostat.getPBMode()
    }

    if (thermostat.includeInScene.fan) {

      result.fanMode = thermostat.getPBFanMode()
    }

    if (thermostat.includeInScene.desired) {

      result.setPoint = thermostat.desired
    }

    if (thermostat.controls.length > 0) {

      result.controls = {}

      for (i = 0; i < thermostat.controls.length; i++) {

        control = thermostat.controls[i]

        if (BasUtil.isObject(control) &&
          control.canEdit &&
          control.includeInScene) {

          result.controls[control.uuid] = control.getSceneValue()
        }
      }
    }

    return result
  }

  /**
   * @private
   * @param {BasThermostat} thermostat
   * @returns {string}
   */
  BasStep._generateThermostatString = function (thermostat) {

    var result, i, length, control

    result = 'uuid' + thermostat.uuid +
      'thermostatMode' + thermostat.getPBMode() +
      'fanMode' + thermostat.getPBFanMode() +
      'setPoint' +
      thermostat.desired.getTemperature(BAS_API.CONSTANTS.TU_KELVIN)

    length = thermostat.controls.length
    for (i = 0; i < length; i++) {

      control = thermostat.controls[i]

      if (BasUtil.isObject(control)) {

        result += control.getName()

        if (control.canEdit) result += control.value
      }
    }

    return result
  }

  /**
   * @private
   * @param {StepScene} scene
   * @returns {TSceneSubscene}
   */
  BasStep._generateSubScene = function (scene) {

    return {
      uuid: scene.controllerUuid,
      scene: scene.scene
    }
  }

  /**
   * @private
   * @param {StepScene} scene
   * @returns {string}
   */
  BasStep._generateSubSceneString = function (scene) {

    return 'uuid' + scene.controllerUuid + 'scene' + scene.scene
  }

  /**
   * @private
   * @param {StepAV} av
   * @returns {TSceneAV}
   */
  BasStep._generateAV = function (av) {

    var result

    result = {}

    if (av.source) {

      result.sourceUuid = av.source !== BAS_HOME.NO_SOURCE_UUID
        ? av.source
        : ''
    }

    if (BasUtil.isPNumber(av.volume, true)) {

      result.volume = av.volume
    }

    if (BasUtil.isNEString(av.favourite)) {

      result.content = av.favourite
    }

    return result
  }

  /**
   * @private
   * @param {StepAV} av
   * @returns {string}
   */
  BasStep._generateAVString = function (av) {

    return 'sourceUuid' + av.source +
      'content' + av.favourite +
      'volume' + av.volume
  }

  /**
   * @private
   * @param {string} roomFunction
   * @returns {string}
   */
  BasStep._generateRoomFunctionName = function (roomFunction) {

    if (BAS_ROOMS.FUNCTIONS.VIDEO === roomFunction) {
      return 'tv'
    }

    return roomFunction
  }

  /**
   * @private
   * @param {TStep} step
   */
  BasStep.prototype._parse = function (step) {

    if (BasStep.isDelayStep(step)) {

      this.setDelay(step.delay)

    } else if (BasUtil.isObject(step.target)) {

      this.room = step.target.areaUuid

      if (BasUtil.isObject(step.target.lights)) {

        this.setFunction(BAS_ROOMS.FUNCTIONS.LIGHTS)
        this.lights = BasStep._parseLights(step.target.lights)

      } else if (BasUtil.isObject(step.target.shades)) {

        this.setFunction(BAS_ROOMS.FUNCTIONS.SHADES)
        this.shades = BasStep._parseShades(step.target.shades)

      } else if (BasUtil.isObject(step.target.thermostat)) {

        this.setFunction(BAS_ROOMS.FUNCTIONS.THERMOSTAT)
        this.thermostat =
          BasStep._parseThermostat(step.target.thermostat)

      } else if (BasUtil.isObject(step.target.subscene)) {

        this.setFunction(BAS_ROOMS.FUNCTIONS.SCENES)
        this.scene = BasStep._parseSubScene(step.target.subscene)

      } else if (BasUtil.isObject(step.target.audio)) {

        this.setFunction(BAS_ROOMS.FUNCTIONS.MUSIC)
        this.music = BasStep._parseAV(step.target.audio)

      } else if (BasUtil.isObject(step.target.video)) {

        this.setFunction(BAS_ROOMS.FUNCTIONS.VIDEO)
        this.video = BasStep._parseAV(step.target.video)

      } else if (BasUtil.isObject(step.target.controls)) {

        this.setFunction(BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES)
        this.controls = BasStep._parseControls(step.target.controls)
      }

      this.syncUi()
    }
  }

  /**
   * @returns {TStep}
   */
  BasStep.prototype.getApiStep = function () {

    var result

    result = {}

    if (this.isDelayStep()) {

      result.delay = this.delay

    } else {

      result.target = {}
      result.target.areaUuid = this.room

      if (this.function === BAS_ROOMS.FUNCTIONS.LIGHTS &&
        this.lights) {

        result.target.lights = BasStep._generateLights(this.lights)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.SHADES &&
        this.shades) {

        result.target.shades = BasStep._generateShades(this.shades)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.THERMOSTAT &&
        this.thermostat) {

        result.target.thermostat =
          BasStep._generateThermostat(this.thermostat)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.SCENES &&
        this.scene) {

        result.target.subscene = BasStep._generateSubScene(this.scene)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.MUSIC &&
        this.music) {

        result.target.audio = BasStep._generateAV(this.music)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.VIDEO &&
        this.video) {

        result.target.video = BasStep._generateAV(this.video)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES &&
        this.controls) {

        result.target.controls =
          BasStep._generateControls(this.controls)
      }
    }

    return result
  }

  /**
   * @returns {string}
   */
  BasStep.prototype.makeDataString = function () {

    var result

    result = ''

    if (this.isDelayStep()) {

      result = 'delay:' + this.delay

    } else {

      if (this.function === BAS_ROOMS.FUNCTIONS.LIGHTS &&
        this.lights) {

        result = BasStep._generateLightsString(this.lights)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.SHADES &&
        this.shades) {

        result = BasStep._generateShadesString(this.shades)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.THERMOSTAT &&
        this.thermostat) {

        result = BasStep._generateThermostatString(this.thermostat)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.SCENES &&
        this.scene) {

        result = BasStep._generateSubSceneString(this.scene)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.MUSIC &&
        this.music) {

        result = BasStep._generateAVString(this.music)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.VIDEO &&
        this.video) {

        result = BasStep._generateAVString(this.video)

      } else if (this.function === BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES &&
        this.controls) {

        result = BasStep._generateControlsString(this.controls)
      }
    }

    return result
  }

  /**
   * Checks if the step has a valid room and function assigned
   *
   * @returns {boolean}
   */
  BasStep.prototype.isValid = function () {
    return (
      BasUtil.isNEString(this.room) &&
      BasUtil.isNEString(this.function)
    )
  }

  /**
   * @returns {boolean}
   */
  BasStep.prototype.isDelayStep = function () {
    return BasStep.isDelayStep(this)
  }

  /**
   * @returns {boolean}
   */
  BasStep.prototype.isFunctionStep = function () {
    return BasStep.isFunctionStep(this)
  }

  /**
   * @param {number} delay
   */
  BasStep.prototype.setDelay = function (delay) {

    if (BasUtil.isPNumber(delay, true)) {

      this.delay = delay
      this.css[CSS_REGULAR] = false
      this.css[CSS_TIMER] = true

      this.updateTitle()
    }
  }

  BasStep.prototype.syncUi = function () {

    if (this.isDelayStep()) {

      this.updateTitle()

    } else {

      this.updateSummary()
      this.updateTitle()
    }
  }

  /**
   * @param {string} stepFunction
   */
  BasStep.prototype.setFunction = function (stepFunction) {

    if (BasUtil.isNEString(stepFunction)) {

      this.function = stepFunction

      switch (this.function) {
        case BAS_ROOMS.FUNCTIONS.MUSIC:

          this.music = new StepAV()

          break

        case BAS_ROOMS.FUNCTIONS.VIDEO:

          this.video = new StepAV()

          break

        case BAS_ROOMS.FUNCTIONS.SHADES:

          this.shades = {}

          break

        case BAS_ROOMS.FUNCTIONS.SCENES:

          this.scene = new StepScene()

          break

        case BAS_ROOMS.FUNCTIONS.LIGHTS:

          this.lights = {}

          break

        case BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES:

          this.controls = {}

          break
      }
    }

    this.updateSummary()
    this.updateTitle()
  }

  /**
   * Toggle the inclusion of music volume in the step.
   *
   * @param {number} volume volume
   * @param {boolean} [force]
   */
  BasStep.prototype.toggleMusicVolume = function (
    volume,
    force
  ) {
    this.toggleVolume(volume, true, force)
  }

  /**
   * Toggle the inclusion of video volume in the step.
   *
   * @param {number} volume volume
   * @param {boolean} [force]
   */
  BasStep.prototype.toggleVideoVolume = function (
    volume,
    force
  ) {
    this.toggleVolume(volume, false, force)
  }

  /**
   * Toggle the inclusion of volume in the step.
   *
   * @param {number} volume volume
   * @param {boolean} isAudio determines music or video object
   * @param {boolean} [force]
   */
  BasStep.prototype.toggleVolume = function (
    volume,
    isAudio,
    force
  ) {

    var avObject =
      isAudio
        ? this.music
        : this.video

    if (BasUtil.isObject(avObject)) {

      if (force === true) {

        avObject.volume = volume

      } else {

        avObject.volume = avObject.volume === -1 ? volume : -1
      }

      this.updateSummary()
    }
  }

  /**
   * @param {number} volume
   */
  BasStep.prototype.setMusicVolume = function (
    volume
  ) {
    this.setVolume(volume, true)
  }

  /**
   * @param {number} volume
   */
  BasStep.prototype.setVideoVolume = function (
    volume
  ) {
    this.setVolume(volume, false)
  }

  /**
   * @param {number} volume
   * @param {boolean} isAudio determines music or video object
   */
  BasStep.prototype.setVolume = function (volume, isAudio) {

    var avObject =
      isAudio
        ? this.music
        : this.video

    if (BasUtil.isObject(avObject) &&
      BasUtil.isPNumber(volume, true)) {

      avObject.volume = volume

      this.updateSummary()
    }
  }

  /**
   * @param {string} sourceUuid
   */
  BasStep.prototype.setMusicSource = function (
    sourceUuid
  ) {
    this.setSource(sourceUuid, true)
  }

  /**
   * @param {string} sourceUuid
   */
  BasStep.prototype.setVideoSource = function (
    sourceUuid
  ) {
    this.setSource(sourceUuid, false)
  }

  /**
   * @param {string} sourceUuid
   * @param {boolean} isAudio determines music or video object
   */
  BasStep.prototype.setSource = function (sourceUuid, isAudio) {

    var currentSource, avObject

    avObject =
      isAudio
        ? this.music
        : this.video

    if (BasUtil.isObject(avObject)) {

      currentSource = avObject.source
      avObject.source = sourceUuid

      if (currentSource !== sourceUuid) {

        avObject.favourite = ''
      }

      this.updateSummary()
    }
  }

  /**
   * @param {string} favourite
   */
  BasStep.prototype.setMusicFavourite = function (
    favourite
  ) {
    this.setFavourite(favourite, true)
  }

  /**
   * @param {string} favourite
   */
  BasStep.prototype.setVideoFavourite = function (
    favourite
  ) {
    this.setFavourite(favourite, false)
  }

  /**
   * @param {string} favourite
   * @param {boolean} isAudio determines music or video object
   */
  BasStep.prototype.setFavourite = function (favourite, isAudio) {

    var avObject =
      isAudio
        ? this.music
        : this.video

    if (BasUtil.isObject(avObject)) {

      avObject.favourite = favourite

      this.updateSummary()
    }
  }

  /**
   * @param {BasShade} shade
   */
  BasStep.prototype.addShade = function (shade) {

    if (BasUtil.isObject(this.shades) &&
      BasUtil.isObject(shade) &&
      BasUtil.isNEString(shade.uuid)) {

      this.shades[shade.uuid] = shade

      this.updateSummary()
    }
  }

  /**
   * @param {BasShade} shade
   */
  BasStep.prototype.toggleShade = function (shade) {

    if (BasUtil.isObject(this.shades) &&
      BasUtil.isObject(shade) &&
      BasUtil.isNEString(shade.uuid)) {

      this.shades[shade.uuid] = this.shades[shade.uuid] ? null : shade

      this.updateSummary()
    }
  }

  /**
   * Always updates the summary if the step contains a "scene"
   *
   * @param {string} sceneUuid
   * @param {string} ctrlUuid
   */
  BasStep.prototype.setScene = function (sceneUuid, ctrlUuid) {

    if (BasUtil.isObject(this.scene)) {

      if (BasUtil.isNEString(sceneUuid) &&
        BasUtil.isNEString(ctrlUuid)) {

        this.scene.scene = sceneUuid
        this.scene.controllerUuid = ctrlUuid

      } else {

        this.scene.scene = ''
        this.scene.controllerUuid = ''
      }

      this.updateSummary()
    }
  }

  /**
   * @param {BasThermostat} thermostat
   */
  BasStep.prototype.setThermostat = function (thermostat) {

    if (BasUtil.isObject(thermostat)) {

      this.thermostat = thermostat

      this.updateSummary()
    }
  }

  /**
   * @param {BasLight} light
   */
  BasStep.prototype.addLight = function (light) {

    if (BasUtil.isObject(this.lights) &&
      BasUtil.isObject(light) &&
      BasUtil.isNEString(light.uuid)) {

      this.lights[light.uuid] = light

      this.updateSummary()
    }
  }

  /**
   * @param {BasLight} light
   */
  BasStep.prototype.toggleLight = function (light) {

    if (BasUtil.isObject(this.lights) &&
      BasUtil.isObject(light) &&
      BasUtil.isNEString(light.uuid)) {

      this.lights[light.uuid] = this.lights[light.uuid] ? null : light

      this.updateSummary()
    }
  }

  /**
   * @param {BasGenericDeviceControl} control
   */
  BasStep.prototype.addDeviceControl = function (control) {

    if (BasUtil.isObject(this.controls) &&
      BasUtil.isObject(control) &&
      BasUtil.isNEString(control.uuid)) {

      this.controls[control.uuid] = control

      this.updateSummary()
    }
  }

  /**
   * @param {BasGenericDeviceControl} control
   */
  BasStep.prototype.toggleDeviceControl = function (control) {

    if (BasUtil.isObject(this.controls) &&
      BasUtil.isObject(control) &&
      BasUtil.isNEString(control.uuid)) {

      this.controls[control.uuid] =
        this.controls[control.uuid] ? null : control

      this.updateSummary()
    }
  }

  /**
   * Updates the UI title (room - function)
   */
  BasStep.prototype.updateTitle = function () {

    var room

    this.uiTitle = ''
    this.uiRoom = ''
    this.uiFunction = ''

    if (this.delay >= 0) {

      this.setUiStepTiming(this.delay)

    } else {

      if (BasUtil.isNEString(this.room)) {

        room = rooms.rooms[this.room]

        if (room && room.isRoom) {

          this.uiTitle = BasUtil.addToString(
            this.uiTitle,
            room.uiTitle
          )
          this.uiRoom = room.uiTitle
        }
      }

      if (BasUtil.isNEString(this.function)) {

        this.uiTitle = BasUtil.addToString(
          this.uiTitle,
          BasUtilities.translate(
            BasStep._generateRoomFunctionName(this.function)
          )
        )
        this.uiFunction = BasUtilities.translate(
          BasStep._generateRoomFunctionName(this.function)
        )
      }

      if (!BasUtil.isNEString(this.uiTitle)) {

        this.uiTitle = BasUtilities.translate('pick_room_function')
        this.uiFunction = this.uiTitle
      }
    }

    this.css[CSS_HAS_TITLE_DIVIDER] = !!(this.uiRoom && this.uiFunction)
  }

  /**
   * @param {number} delay in seconds
   */
  BasStep.prototype.setUiStepTiming = function (delay) {

    this.uiTitle = '+ ' + BasUtil.secondsToString(delay)
    this.uiFunction = this.uiTitle
  }

  /**
   * Updates the UI subtitle (function)
   */
  BasStep.prototype.updateSubtitle = function () {

    this.uiSubtitle = ''

    if (BasUtil.isNEString(this.function)) {

      this.uiSubtitle = BasUtilities.translate(
        BasStep._generateRoomFunctionName(this.function)
      )
    }
  }

  /**
   * Updates the UI summary (music, lights, shades, ...)
   */
  BasStep.prototype.updateSummary = function () {

    this.uiSummary = ''

    switch (this.function) {

      case BAS_ROOMS.FUNCTIONS.MUSIC:

        if (this.music) {

          this.uiSummary += this.getMusicSummary()
        }

        break

      case BAS_ROOMS.FUNCTIONS.VIDEO:

        if (this.video) {

          this.uiSummary += this.getVideoSummary()
        }

        break

      case BAS_ROOMS.FUNCTIONS.LIGHTS:

        if (this.lights) {

          this.uiSummary += this.getLightsSummary()
        }

        break

      case BAS_ROOMS.FUNCTIONS.SHADES:

        if (this.shades) {

          this.uiSummary += this.getShadesSummary()
        }

        break

      case BAS_ROOMS.FUNCTIONS.THERMOSTAT:

        if (this.thermostat) {

          this.uiSummary += this.getThermostatSummary()
        }

        break

      case BAS_ROOMS.FUNCTIONS.SCENES:

        if (this.scene) {

          this.uiSummary += this.getSceneSummary()
        }

        break

      case BAS_ROOMS.FUNCTIONS.GENERIC_DEVICES:

        if (this.controls) {

          this.uiSummary += this.getControlsSummary()
        }

        break
    }

    if (this.isDelayStep()) {

      this.uiSummary = ''
      this.css[CSS_SUBTITLE] = false
      this.css[CSS_ARROW_SHOW] = false

    } else {

      if (!this.uiSummary) this.uiSummary = '-'

      this.css[CSS_SUBTITLE] = true
      this.css[CSS_ARROW_SHOW] = true
    }
  }

  /**
   * @returns {string}
   */
  BasStep.prototype.getMusicSummary = function () {
    return this.getAVSummary(true)
  }

  /**
   * @returns {string}
   */
  BasStep.prototype.getVideoSummary = function () {
    return this.getAVSummary(false)
  }

  /**
   * @param {boolean} isAudio determines music or video object
   * @returns {string}
   */
  BasStep.prototype.getAVSummary = function (isAudio) {

    var summary, source, avObject

    avObject =
      isAudio
        ? this.music
        : this.video

    if (avObject && BasUtil.isString(avObject.source)) {

      summary = ''

      if (avObject.source === BAS_HOME.NO_SOURCE_UUID) {

        summary += BasUtilities.translate('off')

      } else {

        /**
         * Player or external
         *
         * @type {?BasSource}
         */
        source = BAS_SOURCES.SOURCES.sources[avObject.source]

        if (source) {

          summary += isAudio
            ? BasUtilities.translate('stream')
            : BasUtilities.translate('source')
          summary += ': ' + source.name

          if (BasUtil.isNEString(avObject.favourite) &&
            source.favourites &&
            source.favourites.favourites[avObject.favourite] &&
            (
              source.type === BAS_SOURCE.T_PLAYER ||
              source.type === BAS_SOURCE.T_VIDEO
            )
          ) {

            summary += ', ' + source.favourites
              .favourites[avObject.favourite].ui.title
          }
        }
      }

      if (BasUtil.isPNumber(avObject.volume, true)) {

        if (summary) summary += ', '

        summary += BasUtilities.translate('volume')
        summary += ': ' + avObject.volume
      }

      return summary
    }

    return ''
  }

  /**
   * @returns {string}
   */
  BasStep.prototype.getLightsSummary = function () {

    var room, summary, keys, i, length, light, state, stepLight

    if (BasUtil.isNEString(this.room) && this.lights) {

      room = rooms.rooms[this.room]

      if (room && room.lights && room.lights.getLight) {

        summary = ''

        keys = Object.keys(this.lights)
        length = keys.length
        for (i = 0; i < length; i++) {

          stepLight = this.lights[keys[i]]
          light = room.lights.getLight(keys[i])

          if (stepLight && light) {

            state = stepLight.onState
              ? BasUtilities.translate('on')
              : BasUtilities.translate('off')

            summary += light.getName() + ': ' + state + ', '
          }
        }

        if (BasUtil.isNEString(summary)) {

          summary = summary.slice(0, -2)
        }

        return summary
      }
    }

    return ''
  }

  /**
   * @returns {string}
   */
  BasStep.prototype.getShadesSummary = function () {

    var room, summary, keys, shade, state, stepShade, length, i, shadeUuid

    if (BasUtil.isNEString(this.room) && this.shades) {

      room = rooms.rooms[this.room]

      if (room && room.shades && room.shades.getShade) {

        summary = ''

        keys = Object.keys(this.shades)
        length = keys.length
        for (i = 0; i < length; i++) {

          shadeUuid = keys[i]
          stepShade = this.shades[shadeUuid]
          shade = room.shades.getShade(shadeUuid)

          if (stepShade && shade) {

            state = stepShade.opened
              ? BasUtilities.translate('open_state')
              : BasUtilities.translate('closed')

            summary += shade.getName() + ': ' + state + ', '
          }
        }

        if (BasUtil.isNEString(summary)) {

          summary = summary.slice(0, -2)
        }

        return summary
      }
    }

    return ''
  }

  /**
   * @returns {string}
   */
  BasStep.prototype.getControlsSummary = function () {

    var room, summary, keys, control, state, stepControl, i, length
    var d, dLength, device, devices

    if (BasUtil.isNEString(this.room) && this.controls) {

      room = rooms.rooms[this.room]

      if (room && room.genericDevices && room.genericDevices.hasDevices) {

        summary = ''

        devices = room.genericDevices.devices
        dLength = devices.length

        keys = Object.keys(this.controls)
        length = keys.length

        for (i = 0; i < length; i++) {

          stepControl = this.controls[keys[i]]

          for (d = 0; d < dLength; d++) {

            device = devices[d]

            if (device && device.getControlById) {

              control = device.getControlById(keys[i])

              if (stepControl && control) {

                if (control.canEdit) {

                  if (BasUtil.isVNumber(stepControl.value)) {

                    state = stepControl.value

                  } else {

                    state = stepControl.value
                      ? BasUtilities.translate('on')
                      : BasUtilities.translate('off')
                  }
                }

                summary += control.name + ': ' + state + ', '
              }
            }
          }
        }

        if (BasUtil.isNEString(summary)) {

          summary = summary.slice(0, -2)
        }

        return summary
      }
    }

    return ''
  }

  /**
   * @returns {string}
   */
  BasStep.prototype.getThermostatSummary = function () {

    let summary = ''

    if (this.thermostat) {

      if (this.thermostat.includeInScene.mode) {

        summary = BasUtilities.translate(this.thermostat.getModeKey())
      }

      if (this.thermostat.includeInScene.desired) {

        const temperature = this.thermostat.desired.getTemperature(
          BasAppTemperature.getTemperatureUnit()
        )
        const formatted = BasUtil.formatTemperature(
          temperature,
          { precision: this.thermostat.precision }
        ) + 'º'

        if (BasUtil.isNEString(summary)) {

          summary += ': ' + formatted

        } else {

          summary = BasUtilities.translate('temperature') +
            ': ' + formatted
        }
      }

      if (this.thermostat.includeInScene.fan) {

        if (BasUtil.isNEString(summary)) summary += ', '

        summary += BasUtilities.translate('fan') + ': ' +
          BasUtilities.translate(this.thermostat.getCaptialFanKey())
      }

      const controlString = this.getThermostatControlsSummary()

      if (BasUtil.isNEString(controlString)) {

        if (BasUtil.isNEString(summary)) summary += ', '

        summary += controlString
      }

      return summary
    }

    return ''
  }

  /**
   * @returns {string}
   */
  BasStep.prototype.getThermostatControlsSummary = function () {

    var room, summary, controls, control, state, stepControl, i, length
    var c, cLength

    if (BasUtil.isNEString(this.room) && this.thermostat) {

      room = rooms.rooms[this.room]

      if (room && room.thermostat && room.thermostat.setDesired) {

        summary = ''

        controls = room.thermostat.controls
        cLength = controls.length

        length = this.thermostat.controls.length
        for (i = 0; i < length; i++) {

          stepControl = this.thermostat.controls[i]

          for (c = 0; c < cLength; c++) {

            control = controls[c]

            if (
              control &&
              control.getName &&
              stepControl &&
              stepControl.includeInScene &&
              control.uuid === stepControl.uuid
            ) {

              if (control.canEdit) {

                state = stepControl.active
                  ? BasUtilities.translate('on')
                  : BasUtilities.translate('off')

                summary += control.getName() + ': ' + state + ', '
              }
            }
          }
        }

        if (BasUtil.isNEString(summary)) {

          summary = summary.slice(0, -2)
        }

        return summary
      }
    }

    return ''
  }

  /**
   * @returns {string}
   */
  BasStep.prototype.getSceneSummary = function () {

    var room, summary, scene

    // Check id
    if (BasUtil.isNEString(this.room) && this.scene && this.scene.scene) {

      room = rooms.rooms[this.room]

      if (room && room.scenes && room.scenes.activateScene) {

        scene = room.scenes.scenes[this.scene.scene]

        // Check if scene exists
        if (scene) summary = scene.name

        return summary
      }
    }

    return ''
  }

  BasStep.prototype.updateTranslation = function () {

    this.updateTitle()
    this.updateSubtitle()
    this.updateSummary()
  }

  BasStep.prototype.updateTemperatureUnit = function () {

    this.updateSummary()
  }

  BasStep.prototype.resetCss = function () {

    this.css[CSS_REGULAR] = false
    this.css[CSS_TIMER] = false
    this.css[CSS_SUBTITLE] = false
    this.css[CSS_ARROW_SHOW] = false
    this.css[CSS_REMOVE_SHOW] = true
    this.css[CSS_HAS_TITLE_DIVIDER] = false
  }

  return BasStep
}
