'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasMonitor', [
    '$rootScope',
    'BasMonitorDay',
    'BasMonitorWeek',
    BasMonitor
  ])

/**
 * @typedef {Object} TBasMonitor
 * @property {string} currentDay
 * @property {string} currentWeek
 * @property {Object<string, BasMonitorDay>} days
 * @property {Object<string, BasMonitorWeek>} weeks
 * @property {string[]} uiDays soonest -> latest
 * @property {string[]} uiWeeks soonest -> latest
 * @property {string[]} lastWeek latest -> soonest most recent 7 days
 * @property {string[]} types
 */

/**
 * @constructor
 * @param $rootScope
 * @param BasMonitorDay
 * @param BasMonitorWeek
 */
function BasMonitor (
  $rootScope,
  BasMonitorDay,
  BasMonitorWeek
) {
  var data = require('../../demo/monitor.json').monitor

  /**
   * @type {TBasMonitor}
   */
  var state = {
    currentDay: '',
    currentWeek: '',
    days: {},
    weeks: {},
    uiDays: [],
    uiWeeks: [],
    lastWeek: [],
    types: []
  }

  init()

  function init () {

    $rootScope.$on('$translateChangeSuccess', _onTranslationChange)

    resetState()
    parseData()
    makeLastDays()
    makeWeeks()
    getCurrentDay()
    getCurrentWeek()
  }

  function parseData () {

    var i, length, day

    if (Array.isArray(data)) {

      length = data.length
      for (i = length; i >= 0; i--) {

        if (BasUtil.isObject(data[i]) &&
          BasUtil.isNEString(data[i].date)) {

          day = new BasMonitorDay(data[i])

          state.days[data[i].date] = day
          state.uiDays.push(data[i].date)
        }
      }
    }

    state.types.push(BasMonitorDay.TYPE_ALL)
    state.types.push(BasMonitorDay.TYPE_ENERGY)
    state.types.push(BasMonitorDay.TYPE_WATER)
  }

  function makeLastDays () {

    var i, length, _lastDaysReverse

    _lastDaysReverse = []
    length = state.uiDays.length

    for (i = 0; i < 7 && i < length; i++) {

      _lastDaysReverse.push(state.uiDays[i])
    }

    state.lastWeek = reverseArray(_lastDaysReverse)
  }

  function makeWeeks () {

    var i, length, day, dayId, _weekReverse
    length = state.uiDays.length
    _weekReverse = []

    for (i = 0; i < length; i++) {

      dayId = state.uiDays[i]
      day = state.days[dayId]

      _weekReverse.push(day)

      if (_weekReverse.length === 7) {

        addWeek(reverseArray(_weekReverse))
        _weekReverse = []
      }
    }

    if (_weekReverse.length !== 0) addWeek(reverseArray(_weekReverse))
  }

  function addWeek (array) {

    var weekObj = new BasMonitorWeek(array)
    state.weeks[weekObj.uuid] = weekObj
    state.uiWeeks.push(weekObj.uuid)
  }

  function getCurrentDay () {

    if (BasUtil.isNEArray(state.uiDays) &&
      BasUtil.isNEString(state.uiDays[0])) {

      state.currentDay = state.uiDays[0]
    }
  }

  function getCurrentWeek () {

    if (BasUtil.isNEArray(state.uiWeeks) &&
      BasUtil.isNEString(state.uiWeeks[0])) {

      state.currentWeek = state.uiWeeks[0]
    }
  }

  function resetState () {

    state.currentDay = ''
    state.currentWeek = ''
    state.days = {}
    state.weeks = {}
    state.uiDays = []
    state.uiWeeks = []
    state.lastWeek = []
    state.types = []
  }

  function reverseArray (arr) {

    var i, length
    var result = []

    if (Array.isArray(arr)) {

      length = arr.length
      for (i = 0; i < length; i++) {

        result[i] = arr[length - 1 - i]
      }
    }

    return result
  }

  function _onTranslationChange () {

    var i, length, keys, _day, _week

    keys = Object.keys(state.days)
    length = keys.length

    for (i = 0; i < length; i++) {

      _day = state.days[keys[i]]

      if (_day) {

        _day.updateTranslation()
      }
    }

    keys = Object.keys(state.weeks)
    length = keys.length

    for (i = 0; i < length; i++) {

      _week = state.weeks[keys[i]]

      if (_day) {

        _week.updateTranslation()
      }
    }
  }

  /**
   * @returns {TBasMonitor}
   */
  this.get = function () {

    return state
  }
}
