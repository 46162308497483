{
  "messages": [
    {
      "conditions": [
        {
          "deviceUuid": "TerraceGarden",
          "device": {
            "state": {
              "on": true
            }
          }
        }
      ],
      "message": {
        "app":{
          "version":{
            "min":"4.0.0"
          }
        },
        "content":{
          "title":{
            "*":"Garden lights are on",
            "nl":"Tuinverlichting staat aan"
          },
          "body":{
            "*":"The lights will be switched off automatically after sunrise.",
            "nl":"De lichten worden automatisch uitgezet na zonsondergang."
          }
        },
        "type":"default",
        "uuid":"gardenLightsOnMessage"
      }
    },
    {
      "conditions": [
        {
          "deviceUuid": "TerraceSprinklers",
          "device": {
            "controls": [
              {},
              {
                "state": {
                  "value": true
                }
              }
            ]
          }
        },
        {
          "deviceUuid": "TerraceSprinklers",
          "device": {
            "controls": [
              {
                "state": {
                  "value": true
                }
              }
            ]
          }
        }
      ],
      "message": {
        "app":{
          "version":{
            "min":"4.0.0"
          }
        },
        "content":{
          "title":{
            "*":"Sprinklers are on",
            "nl":"Sproeiers staan aan"
          },
          "body":{
            "*":"Sprinklers can be deactivated in the Timers section.",
            "nl":"Sproeiers kunnen worden uitgezet in de timers pagina."
          }
        },
        "type":"default",
        "uuid":"sprinklersOnMessage"
      }
    },
    {
      "conditions": [
        {
          "deviceUuid": "PoolCover",
          "device": {
            "controls": [
              {
                "state": {
                  "value": false
                }
              }
            ]
          }
        }
      ],
      "message": {
        "app":{
          "version":{
            "min":"4.0.0"
          }
        },
        "content":{
          "title":{
            "*":"Pool cover is open",
            "nl":"Zwembadafdekking is open"
          },
          "body":{
            "*":"Don't forget to close it after swimming",
            "nl":"Vergeet deze niet te sluiten na het zwemmen."
          }
        },
        "type":"default",
        "uuid":"poolCoverOpenMessage"
      }
    }
  ]
}
