'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basRoundSlider', [])
  .directive('basRoundSlider', basRoundSlider)

function basRoundSlider () {

  return {
    restrict: 'AE',
    template: '<svg class="btd-slider-svg" viewbox="0 0 400 400">' +
      '<path class="btd-slider-path"></path>' +
      '<path class="btd-slider-path-bg"></path>' +
      '</svg>' +
      '<div class="btd-control-buttons">' +
      '<div class="btd-icon"' +
      ' bas-click="roundSlider.decrease()"' +
      ' ng-bind-html="roundSlider.minus"></div>' +
      '<div class="btd-icon"' +
      ' bas-click="roundSlider.increase()"' +
      ' ng-bind-html="roundSlider.plus"></div>' +
      '</div>',
    scope: {
      value: '<?',
      min: '<?',
      max: '<?',
      step: '<?',
      disabled: '<?',
      callback: '&?',
      nonDebouncedCallback: '&?',
      debounce: '<?',
      strokeTouchSurfaceWidthOverride: '<?'
    },
    controller: [
      '$scope',
      '$element',
      '$window',
      'ICONS',
      controller
    ],
    controllerAs: 'roundSlider'
  }

  function controller ($scope, $element, $window, ICONS) {

    const roundSlider = this

    const GAP_SIZE = 70
    const GAP_MIN = 270 - GAP_SIZE / 2
    const GAP_MAX = 270 + GAP_SIZE / 2

    const DEF_STEP = 1
    const DEF_MIN = 0
    const DEF_MAX = 100
    const DEF_VALUE = (DEF_MAX - DEF_MIN) / 2

    let step, min, max, value
    let strokeTouchSurfaceWidthOverride

    let elSlider, elSliderPath, elSliderPathBg
    let elMidX, elMidY
    let startX, startY
    let startDate, legitStart

    const CLICK_TIME = 250
    const MAX_DISTANCE = 400

    const CSS_SLIDER = 'btd-slider-svg'
    const CSS_SLIDER_PATH = 'btd-slider-path'
    const CSS_SLIDER_PATH_BG = 'btd-slider-path-bg'

    let _globalListeners = []
    let _listeners = []

    const _activeListenerOptions = {
      capture: false,
      active: true
    }
    const _passiveListenerOptions = {
      capture: false,
      active: false
    }

    let _timeoutId = 0

    roundSlider.plus = ICONS.plus
    roundSlider.minus = ICONS.minus

    roundSlider.increase = increase
    roundSlider.decrease = decrease

    // Angular life-cycle methods
    roundSlider.$postLink = postLink
    roundSlider.$onDestroy = onDestroy

    function postLink () {

      init()
    }

    function onStepChange () {

      step = BasUtil.isVNumber($scope.step) ? $scope.step : DEF_STEP
    }

    function onMinChange () {

      min = BasUtil.isVNumber($scope.min) ? $scope.min : DEF_MIN
    }

    function onMaxChange () {

      max = BasUtil.isVNumber($scope.max) ? $scope.max : DEF_MAX
    }

    function onValueChange () {

      value = BasUtil.isVNumber($scope.value) ? $scope.value : DEF_VALUE
      setSliderPath(BasUtil.clamp(value, min, max))
    }

    function sync () {

      step = BasUtil.isVNumber($scope.step) ? $scope.step : DEF_STEP
      min = BasUtil.isVNumber($scope.min) ? $scope.min : DEF_MIN
      max = BasUtil.isVNumber($scope.max) ? $scope.max : DEF_MAX
      value = BasUtil.isVNumber($scope.value) ? $scope.value : DEF_VALUE
      strokeTouchSurfaceWidthOverride =
        BasUtil.isVNumber($scope.strokeTouchSurfaceWidthOverride)
          ? $scope.strokeTouchSurfaceWidthOverride
          : undefined

      setSliderPath(value)
    }

    function init () {

      legitStart = false

      sync()
      getDOMElements()

      $scope.$watch('step', onStepChange)
      $scope.$watch('min', onMinChange)
      $scope.$watch('max', onMaxChange)
      $scope.$watch('value', onValueChange)

      // Touch events
      _listeners.push(BasUtil.setDOMListener(
        elSlider,
        'touchstart',
        handleStart,
        _activeListenerOptions
      ))
      _listeners.push(BasUtil.setDOMListener(
        elSlider,
        'touchmove',
        handleMove,
        _activeListenerOptions
      ))
      _listeners.push(BasUtil.setDOMListener(
        elSlider,
        'touchend',
        handleEnd,
        _passiveListenerOptions
      ))
      _listeners.push(BasUtil.setDOMListener(
        elSlider,
        'touchcancel',
        handleEnd,
        _passiveListenerOptions
      ))

      // Mouse events
      _listeners.push(BasUtil.setDOMListener(
        elSlider,
        'mousedown',
        handleStart,
        _activeListenerOptions
      ))
    }

    function getDOMElements () {

      _clearDOMElements()

      elSlider = $element[0]
        .getElementsByClassName(CSS_SLIDER)[0]
      elSliderPath = elSlider
        .getElementsByClassName(CSS_SLIDER_PATH)[0]
      elSliderPathBg = elSlider
        .getElementsByClassName(CSS_SLIDER_PATH_BG)[0]
    }

    /**
     * @param {(TouchEvent|MouseEvent)} event
     * @returns {Object}
     */
    function handleInput (event) {

      if ($scope.disabled) return

      const result = {}

      if (BasUtil.isObject(event.changedTouches)) {

        const touch = event.changedTouches[0]

        if (BasUtil.isObject(touch)) {

          result.x = touch.pageX
          result.y = touch.pageY

          return result
        }

        return null

      } else if (BasUtil.isVNumber(event.pageX) &&
        BasUtil.isVNumber(event.pageY)) {

        result.x = event.pageX
        result.y = event.pageY

        return result
      }

      return null
    }

    /**
     * @param {(TouchEvent|MouseEvent)} event
     */
    function handleStart (event) {

      // Get middle
      const elementRect = elSlider.getBoundingClientRect()
      elMidX = (elementRect.left + elementRect.right) / 2
      elMidY = (elementRect.top + elementRect.bottom) / 2

      // Reset values
      legitStart = false
      startX = elMidX
      startY = elMidY
      startDate = new Date()

      const result = handleInput(event)

      if (BasUtil.isObject(result)) {

        startX = result.x
        startY = result.y
        const degrees = posToDegrees(startX, startY)
        legitStart = validDistance(startX, startY) &&
          (degrees < GAP_MIN || degrees > GAP_MAX)
      }

      if (legitStart) {

        event.preventDefault()
        setGlobalMouseListeners()
      }
    }

    /**
     * @param {(TouchEvent|MouseEvent)} event
     */
    function handleMove (event) {

      if (legitStart) {

        const result = handleInput(event)

        if (BasUtil.isObject(result)) {

          const degrees = posToDegrees(result.x, result.y)

          // Stay within bounds
          if (degrees < GAP_MIN || degrees > GAP_MAX) {

            const newValue = calculateValue(degrees)
            setValue(newValue)

            $scope.$applyAsync()
          }
        }
      }
    }

    function handleEnd (event) {

      if (legitStart) {

        const result = handleInput(event)
        const endDate = new Date()

        if (endDate - startDate < CLICK_TIME &&
          BasUtil.isObject(result)) {

          const distance =
            BasUtil.distanceSquared(result.x, result.y, startX, startY)

          if (distance < MAX_DISTANCE) {

            const degrees = posToDegrees(result.x, result.y)
            const newValue = calculateValue(degrees)

            setValue(newValue)

            $scope.$applyAsync()
          }
        }
      }

      removeGlobalMouseListeners()
    }

    function increase () {

      let current = value
      current += step
      current = BasUtil.clamp(current, min, max)

      setValue(current)
    }

    function decrease () {

      let current = value
      current -= step
      current = BasUtil.clamp(current, min, max)

      setValue(current)
    }

    /**
     * @param {number} newValue
     */
    function setValue (newValue) {

      const debounce = BasUtil.isPNumber($scope.debounce)
        ? $scope.debounce
        : 0

      clearTimeout(_timeoutId)

      if (BasUtil.isVNumber(newValue)) {

        value = newValue
        setSliderPath(newValue)

        if (BasUtil.isFunction($scope.nonDebouncedCallback)) {

          $scope.nonDebouncedCallback({ value: newValue })
        }

        if (BasUtil.isFunction($scope.callback) && debounce > 0) {

          _timeoutId = setTimeout(
            () => {
              $scope.callback({ value: newValue })
            },
            debounce
          )
        }
      }
    }

    function validDistance (x, y) {

      const elementRect = elSlider.getBoundingClientRect()
      const width = elementRect.width

      // Stroke width scales with the container
      // 300 = 22.5, 400 = 30
      const stroke = BasUtil.isVNumber(strokeTouchSurfaceWidthOverride)
        ? strokeTouchSurfaceWidthOverride
        : width < 350 ? 22.5 : 30

      const maxDist = width / 2
      const minDist = width / 2 - stroke

      const maxDist2 = maxDist * maxDist
      const minDist2 = minDist * minDist

      const distance = BasUtil.distanceSquared(x, y, elMidX, elMidY)

      return distance < maxDist2 && distance > minDist2
    }

    function calculateValue (deg) {

      let relDeg = deg

      const diff = max - min
      const degStep = (360 - GAP_SIZE) / diff
      const start = GAP_MIN

      if (relDeg > start) relDeg -= 360

      const exact = -(relDeg - start) / degStep + min

      const stepDivide = 1 / step

      return Math.round(exact * stepDivide) / stepDivide
    }

    /**
     * @param {number} x
     * @param {number} y
     * @returns {number} angle in degrees
     */
    function posToDegrees (x, y) {

      let angle

      const relX = x - elMidX
      const relY = y - elMidY

      const radius2 = BasUtil.distanceSquared(0, 0, relX, relY)
      const radius = Math.sqrt(radius2)

      const xNorm = relX / radius

      // [0, PI]
      const xAngle = Math.acos(xNorm)

      if (relY <= 0) {

        // [0, 360]
        angle = xAngle / Math.PI * 180

      } else {

        // [0, 360]
        angle = 360 - (xAngle / Math.PI * 180)
      }

      return angle
    }

    function _clearDOMElements () {

      elSlider = null
      elSliderPath = null
      elSliderPathBg = null
    }

    function setSliderPath (sliderValue) {

      let path

      const startAngle = 90 + GAP_SIZE / 2
      const endAngle = 90 - GAP_SIZE / 2
      const totalArc = 360 - GAP_SIZE
      const diff = max - min
      const offset = sliderValue - min
      const segment = totalArc / diff
      const valueAngle = startAngle + segment * offset

      if (elSliderPath) {

        path = describeArc(
          200,
          200,
          185,
          startAngle,
          valueAngle
        )
        elSliderPath.setAttribute('d', path)
      }

      if (elSliderPathBg) {

        path = describeArc(
          200,
          200,
          185,
          valueAngle,
          endAngle
        )
        elSliderPathBg.setAttribute('d', path)
      }
    }

    function describeArc (x, y, radius, startAngle, endAngle) {

      const start = BasUtil.polarToCartesian(x, y, radius, endAngle)
      const end = BasUtil.polarToCartesian(x, y, radius, startAngle)

      const diff = BasUtil.clampDegrees(endAngle - startAngle)
      const largeArcFlag = diff <= 180 ? '0' : '1'

      return [
        'M', start.x, start.y,
        'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
      ].join(' ')
    }

    function setGlobalMouseListeners () {

      removeGlobalMouseListeners()

      _globalListeners.push(BasUtil.setDOMListener(
        $window.document,
        'mousemove',
        handleMove,
        _activeListenerOptions
      ))
      _globalListeners.push(BasUtil.setDOMListener(
        $window.document,
        'mouseup',
        handleEnd,
        _passiveListenerOptions
      ))
    }

    function removeGlobalMouseListeners () {

      BasUtil.executeArray(_globalListeners)
      _globalListeners = []
    }

    function removeListeners () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }

    function onDestroy () {

      removeListeners()
      removeGlobalMouseListeners()

      _clearDOMElements()
    }
  }
}
