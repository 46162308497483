'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basFileDropZone', [])
  .directive('basFileDropZone', basFileDropZoneDirective)

function basFileDropZoneDirective () {

  return {
    restrict: 'EA',
    bindToController: {
      onfile: '&',
      isEnabled: '<'
    },
    controller: [
      '$element',
      controller
    ],
    controllerAs: 'basFileDropZone'
  }

  /**
   * @param $element
   */
  function controller ($element) {

    var basFileDropZone = this

    var inputElement

    var CSS_FILE_DROP_ZONE_ACTIVE = 'bas-file-drop-zone-active'

    var _listeners = []
    var _loadListener = null

    basFileDropZone.$postLink = _onPostLink
    basFileDropZone.$onDestroy = _onDestroy

    function _onPostLink () {

      init()
    }

    function init () {

      getDOMElements()
    }

    function setListeners () {

      _clearListeners()

      if (inputElement && basFileDropZone.isEnabled === true) {

        _listeners.push(BasUtil.setDOMListener(
          inputElement,
          'dragenter',
          _showDrag
        ))

        _listeners.push(BasUtil.setDOMListener(
          inputElement,
          'dragover',
          _showDrag
        ))

        _listeners.push(BasUtil.setDOMListener(
          inputElement,
          'dragleave',
          _hideDrag
        ))

        _listeners.push(BasUtil.setDOMListener(
          inputElement,
          'dragend',
          _hideDrag
        ))

        _listeners.push(BasUtil.setDOMListener(
          inputElement,
          'drop',
          _drop
        ))
      }
    }

    function _showDrag (event) {

      _preventDefault(event)

      inputElement.classList.add(CSS_FILE_DROP_ZONE_ACTIVE)
    }

    function _hideDrag (event) {

      _preventDefault(event)

      inputElement.classList.remove(CSS_FILE_DROP_ZONE_ACTIVE)
    }

    function _drop (event) {

      var files, file

      _hideDrag(event)

      _preventDefault(event)

      if (event &&
        event.dataTransfer &&
        event.dataTransfer.files) {

        files = event.dataTransfer.files

        if (BasUtil.isFunction(basFileDropZone.onfile) &&
          files.length > 0) {

          file = files[0]

          _onFile(file)
        }
      }
    }

    function _onFile (file) {

      var reader

      BasUtil.execute(_loadListener)

      reader = new FileReader()

      _loadListener = BasUtil.setDOMListener(
        reader,
        'loadend',
        _onLoadend
      )

      reader.readAsDataURL(file)
    }

    function _onLoadend (readerEvent) {

      var imageData, prefix, body, idx

      if (readerEvent && readerEvent.target) {

        imageData = readerEvent.target.result
      }

      if (imageData) {

        idx = imageData.indexOf(',')
        prefix = imageData.substring(0, idx + 1)
        body = imageData.substring(idx + 1)

        _onResult(null, body, prefix)

      } else {

        _onResult(new Error('Invalid event'), null, null)
      }
    }

    function _onResult (error, body, prefix) {

      if (BasUtil.isFunction(basFileDropZone.onfile)) {

        basFileDropZone.onfile({
          error: error,
          body: body,
          prefix: prefix
        })
      }
    }

    function _preventDefault (event) {

      if (event) {

        event.preventDefault()
        event.stopPropagation()
      }
    }

    function getDOMElements () {

      inputElement = $element[0]

      setListeners()
    }

    function _clearDOMElements () {

      inputElement = null
    }

    function _clearListeners () {

      BasUtil.execute(_loadListener)

      _loadListener = null

      BasUtil.executeArray(_listeners)

      _listeners = []
    }

    function _onDestroy () {

      _clearListeners()
      _clearDOMElements()
    }
  }
}
