'use strict'

var EventEmitter = require('@gidw/event-emitter-js')
var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var BasServer = require('./bas_server')
var System = require('./system')
var Live = require('./live')
var Profile = require('./profile')
var User = require('./user')
var SharedServerStorage = require('./shared_server_storage')
var Room = require('./room')
var Zone = require('./zone')
var Barp = require('./barp')
var SpotifyBarp = require('./spotify_barp')
var Player = require('./player')
var TuneIn = require('./tunein')
var External = require('./external')
var Notification = require('./notification')
var Bluetooth = require('./bluetooth')
var CobraAudioDevice = require('./cobra_audio_device')
var Device = require('./device')
var ConnectedDevice = require('./connected_device')
var ServerDevice = require('./server_device')
var CoreClientDevice = require('./core_client_device')
var LightDevice = require('./light_device')
var CameraDevice = require('./camera_device')
var EnergyDevice = require('./energy_device')
var GenericDeviceV1 = require('./generic_device_v1')
var GenericDeviceV2 = require('./generic_device_v2')
var OpenCloseDevice = require('./open_close_device')
var ShadeDevice = require('./shade_device')
var SceneCtrlDevice = require('./scene_ctrl_device')
var TimerCtrlDevice = require('./timer_ctrl_device')
var ThermostatDevice = require('./thermostat_device')
var PoolDevice = require('./pool_device')
var DoorPhoneGatewayDevice = require('./door_phone_gateway_device')
var DoorPhoneDevice = require('./door_phone_device')
var WeatherStationDevice = require('./weather_station_device')
var BasTrack = require('./bas_track')
var AudioSource = require('./audio_source')
var VideoSource = require('./video_source')
var MusicLibrary = require('./music_library')
var AudioAlertTopic = require('./audio_alert_topic')
var DoorPhoneTopic = require('./door_phone_topic')

var log = require('./logger')

/**
 * Basalte Core
 *
 * @constructor
 * @extends EventEmitter
 * @param {BasServer} server asCore
 * @since 0.0.1
 */
function BasCore (server) {

  log.info('API - BasCore')

  EventEmitter.call(this)

  /**
   * @private
   * @type {?BasServer}
   */
  this._server = null

  /**
   * @private
   * @type {?BasUtil.BasVersion}
   */
  this._version = null

  /**
   * @private
   * @type {Profile}
   */
  this._profile = new Profile()

  /**
   * @private
   * @type {System}
   */
  this._system = new System(this)

  /**
   * @private
   * @type {?Live}
   */
  this._live = new Live(this)

  /**
   * @private
   * @type {SharedServerStorage}
   */
  this._sharedServerStorage = new SharedServerStorage(this)

  /**
   * @private
   * @type {?User}
   */
  this._user = null

  /**
   * @private
   * @type {Object<string, Room>}
   */
  this._areas = {}

  /**
   * @private
   * @type {Object<string, Device>}
   */
  this._devices = {}

  /**
   * @private
   * @type {Object<string, AudioSource>}
   */
  this._audioSources = {}

  /**
   * @private
   * @type {Object<string, VideoSource>}
   */
  this._videoSources = {}

  /**
   * Connected device UUIDs in no specific order
   *
   * @private
   * @type {string[]}
   */
  this._connectedDevices = []

  /**
   * Server device UUIDs in no specific order
   *
   * @private
   * @type {string[]}
   */
  this._servers = []

  /**
   * @private
   * @type {Object<string, Zone>}
   */
  this._zones = {}

  /**
   * @private
   * @type {Object<string, Zone>}
   */
  this._groups = {}

  /**
   * @private
   * @type {Object<string, Player>}
   */
  this._players = {}

  /**
   * @private
   * @type {Object<string, (Barp | (Barp[]))>}
   */
  this._barps = {}

  /**
   * @private
   * @type {Object<string, External>}
   */
  this._externals = {}

  /**
   * @private
   * @type {Object<string, Notification>}
   */
  this._notifications = {}

  /**
   * @private
   * @type {Object<string, Bluetooth>}
   */
  this._bluetooths = {}

  /**
   * @private
   * @type {Object<string, CobraAudioDevice>}
   */
  this._audioDevices = {}

  /**
   * @private
   * @type {TuneIn}
   */
  this._tunein = new TuneIn(this)

  /**
   * @private
   * @type {boolean}
   */
  this._avSourcesReceived = false

  /**
   * @private
   * @type {boolean}
   */
  this._musicConfigReceived = false

  /**
   * @private
   * @type {boolean}
   */
  this._roomsReceived = false

  /**
   * @private
   * @type {boolean}
   */
  this._hasSpotifyConnect = false

  /**
   * @private
   * @type {string}
   */
  this._singleRoomId = ''

  /**
   * @private
   * @type {string}
   */
  this._singleAudioRoomId = ''

  /**
   * @private
   * @type {number}
   */
  this._singleSourceId = CONSTANTS.NO_SOURCE_ID

  /**
   * @private
   * @type {number}
   */
  this._singlePlayerId = CONSTANTS.NO_SOURCE_ID

  /**
   * @private
   * @type {MusicLibrary}
   */
  this._musicLibrary = new MusicLibrary(this)

  this._audioAlertTopic = new AudioAlertTopic(this)

  this._doorPhoneTopic = new DoorPhoneTopic(this)

  this._handleServerApiVersion =
    this._onServerApiVersion.bind(this)
  this._handleServerHasUpdate =
    this._onServerHasUpdate.bind(this)
  this._handleServerConnected = this._onServerConnected.bind(this)
  this._handleServerSocketConnected =
    this._onServerSocketConnected.bind(this)
  this._handleServerSocketMessage =
    this._onServerSocketMessage.bind(this)
  this._handleServerConnectionJWTRevoked =
    this._onServerConnectionJWTRevoked.bind(this)
  this._handleCoreConnected = this._onCoreConnected.bind(this)

  this._handleServerV2SocketConnected =
    this._onServerV2SocketConnected.bind(this)
  this._handleServerV2SocketMessage =
    this._onServerV2SubscriptionSocketMessage.bind(this)

  /**
   * @private
   * @type {Array}
   * @since 3.0.0
   */
  this._serverListeners = []

  this._clearSupportFlags()

  this.setBasServer(server)
}

BasCore.prototype = Object.create(EventEmitter.prototype)
BasCore.prototype.constructor = BasCore

// region Events

/**
 * Server protocol version is known
 *
 * @event BasCore#EVT_API_VERSION
 * @param {number} apiVersion
 * @since 2.0.0
 */

/**
 * @event BasCore#EVT_HAS_UPDATE
 * @param {boolean} hasUpdate
 * @since 3.0.0
 * @deprecated
 */

/**
 * BasCore users (profiles) updated
 *
 * @event BasCore#EVT_USERS
 * @param {BasProfile[]} users
 * @since 3.0.0
 */

/**
 * BasCore connection state has changed
 *
 * @event BasCore#EVT_CONNECTED
 * @param {boolean} connected
 */

/**
 * BasCore core connection state has changed
 *
 * @event BasCore#EVT_CORE_CONNECTED
 * @param {boolean} connected
 * @since 3.0.0
 */

/**
 * Server version is known
 *
 * @event BasCore#EVT_VERSION
 * @param {?BasUtil.BasVersion} version
 * @since 2.0.0
 */

/**
 * User is created
 *
 * @event BasCore#EVT_USER_CREATED
 * @since 1.7.9
 */

/**
 * Audio Sources have been initialised
 *
 * @event BasCore#EVT_AUDIO_SOURCES_UPDATED
 * @since 3.4.0
 */

/**
 * Music configuration has been initialised
 * (zones, groups and sources)
 *
 * @event BasCore#EVT_MUSIC_CONFIG_UPDATED
 * @since 2.0.0
 */

/**
 * Rooms have been received
 *
 * @event BasCore#EVT_ROOMS_UPDATED
 * @since 2.0.0
 */

/**
 * Device(s) have been added
 *
 * @event BasCore#EVT_DEVICES_UPDATED
 * @since 2.0.0
 */

/**
 * Connected device(s) have been updated
 *
 * @event BasCore#EVT_CONNECTED_DEVICES_UPDATED
 * @param {string[]} connectedDevices
 */

/**
 * Server(s) have been added
 *
 * @event BasCore#EVT_SERVERS_UPDATED
 * @param {string[]} servers
 * @since 2.0.0
 */

/**
 * Audio devices have been added
 * This should only fire once after connection
 *
 * @event BasCore#EVT_AUDIO_DEVICES_UPDATED
 * @since 2.6.0
 */

/**
 * Audio Sources have been initialised
 *
 * @event BasCore#EVT_AUDIO_SOURCES_UPDATED
 * @since 3.4.0
 */

// endregion

/**
 * @constant {string}
 */
BasCore.EVT_API_VERSION = 'evtApiVersion'

/**
 * @constant {string}
 * @deprecated
 */
BasCore.EVT_HAS_UPDATE = 'evtHasUpdate'

/**
 * @constant {string}
 */
BasCore.EVT_USERS = 'evtUsers'

/**
 * @constant {string}
 */
BasCore.EVT_CONNECTED = 'evtConnected'

/**
 * @constant {string}
 */
BasCore.EVT_CONNECTION_JWT_REVOKED = 'evtConnectionJWTRevoked'

/**
 * @constant {string}
 */
BasCore.EVT_CORE_CONNECTED = 'evtCoreConnected'

/**
 * @constant {string}
 */
BasCore.EVT_CORE_V2_CONNECTED = 'evtCoreV2Connected'

/**
 * @constant {string}
 */
BasCore.EVT_VERSION = 'evtVersion'

/**
 * @constant {string}
 */
BasCore.EVT_USER_CREATED = 'evtUserCreated'

/**
 * @constant {string}
 */
BasCore.EVT_AV_SOURCES_UPDATED = 'evtAudioSourcesUpdated'

/**
 * @constant {string}
 */
BasCore.EVT_MUSIC_CONFIG_UPDATED = 'evtMusicConfigUpdated'

/**
 * @constant {string}
 */
BasCore.EVT_ROOMS_UPDATED = 'evtRoomsUpdated'

/**
 * @constant {string}
 */
BasCore.EVT_DEVICES_UPDATED = 'evtDevicesUpdated'

/**
 * @constant {string}
 */
BasCore.EVT_CONNECTED_DEVICES_UPDATED = 'evtConnectedDevicesUpdated'

/**
 * @constant {string}
 */
BasCore.EVT_SERVERS_UPDATED = 'evtServersUpdated'

/**
 * @constant {string}
 */
BasCore.EVT_AUDIO_DEVICES_UPDATED = 'evtAudioDevicesUpdated'

/**
 * @name BasCore#server
 * @type {?BasServer}
 * @readonly
 * @since 3.0.0
 */
Object.defineProperty(BasCore.prototype, 'server', {
  get: function () {
    return this._server
  }
})

/**
 * BasCore software version
 *
 * @name BasCore#version
 * @type {BasUtil.BasVersion}
 * @readonly
 * @since 1.5.0
 */
Object.defineProperty(BasCore.prototype, 'version', {
  get: function () {
    return this._version
  }
})

/**
 * Support for (dynamic) default room configuration for this BasCore.
 *
 * @name BasCore#supportsDefaultRooms
 * @type {boolean}
 * @readonly
 * @since 1.6.0
 */
Object.defineProperty(BasCore.prototype, 'supportsDefaultRooms', {
  get: function () {
    return this._supportsDefaultRooms
  }
})

/**
 * Supports the option "replaceNow" for queue operations (replace + play)
 *
 * @name BasCore#supportsReplaceNow
 * @type {boolean}
 * @readonly
 * @since 1.9.0
 */
Object.defineProperty(BasCore.prototype, 'supportsReplaceNow', {
  get: function () {
    return this._supportsReplaceNow
  }
})

/**
 * Support for an output to get its linked zone.
 *
 * @name BasCore#supportsDescribeZone
 * @type {boolean}
 * @readonly
 * @since 1.9.0
 */
Object.defineProperty(BasCore.prototype, 'supportsDescribeZone', {
  get: function () {
    return this._supportsDescribeZone
  }
})

/**
 * Support for basic zone DSP settings: bass, treble, startup volume
 *
 * @name BasCore#supportsBasicZoneDSP
 * @type {boolean}
 * @readonly
 * @since 1.9.0
 */
Object.defineProperty(BasCore.prototype, 'supportsBasicZoneDSP', {
  get: function () {
    return this._supportsBasicZoneDSP
  }
})

/**
 * Support for Spotify Web API: Spotify library browsing and playing, ...
 *
 * @name BasCore#supportsSpotifyWebAPI
 * @type {boolean}
 * @readonly
 * @since 1.9.0
 */
Object.defineProperty(BasCore.prototype, 'supportsSpotifyWebAPI', {
  get: function () {
    return this._supportsSpotifyWebAPI
  }
})

/**
 * Support for system audio only message
 *
 * @name BasCore#supportsSystemProperties
 * @type {boolean}
 * @readonly
 * @since 2.0.0
 */
Object.defineProperty(BasCore.prototype, 'supportsSystemProperties', {
  get: function () {
    return this._supportsSystemProperties
  }
})

/**
 * Support scheduler tile and detail scene
 *
 * @name BasCore#supportsSchedulerAndDetailScene
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(BasCore.prototype, 'supportsSchedulerAndDetailScene', {
  get: function () {
    return this._supportsSchedulerAndDetailScene
  }
})

/**
 * Support for the Spotify actions ‘unlink’ and ‘linkRestore’
 *
 * @name BasCore#supportsSpotifyUnlink
 * @type {boolean}
 * @readonly
 * @since 2.1.0
 */
Object.defineProperty(BasCore.prototype, 'supportsSpotifyUnlink', {
  get: function () {
    return this._supportsSpotifyUnlink
  }
})

/**
 * Support for the repeatMode
 *
 * @name BasCore#supportsRepeatMode
 * @type {boolean}
 * @readonly
 * @since 2.1.0
 */
Object.defineProperty(BasCore.prototype, 'supportsRepeatMode', {
  get: function () {
    return this._supportsRepeatMode
  }
})

/**
 * Support for reliable "checking for updates" event
 *
 * @name BasCore#supportsCheckingUpdates
 * @type {boolean}
 * @readonly
 * @since 2.1.0
 */
Object.defineProperty(BasCore.prototype, 'supportsCheckingUpdates', {
  get: function () {
    return this._supportsCheckingUpdates
  }
})

/**
 * Support for a timer to use sunrise/set as a time
 *
 * @name BasCore#supportsSunriseSet
 * @type {boolean}
 * @readonly
 * @since 2.1.0
 */
Object.defineProperty(BasCore.prototype, 'supportsSunriseSet', {
  get: function () {
    return this._supportsSunriseSet
  }
})

/**
 * Support for a generic devices in scenes
 *
 * @name BasCore#supportsDevicesScenes
 * @type {boolean}
 * @readonly
 * @since 2.6.0
 */
Object.defineProperty(BasCore.prototype, 'supportsDevicesScenes', {
  get: function () {
    return this._supportsDevicesScenes
  }
})

/**
 * Support for profile properties
 *
 * @name BasCore#supportsProfile
 * @type {boolean}
 * @readonly
 * @since 2.7.0
 */
Object.defineProperty(BasCore.prototype, 'supportsProfile', {
  get: function () {
    return this._supportsProfile
  }
})

/**
 * Support for alarms access
 *
 * @name BasCore#supportsAlarmsAccess
 * @type {boolean}
 * @readonly
 * @since 2.10.0
 */
Object.defineProperty(BasCore.prototype, 'supportsAlarmsAccess', {
  get: function () {
    return this._supportsAlarmsAccess
  }
})

/**
 * Support for mute area on call
 *
 * @name BasCore#supportsMuteAreaOnCall
 * @type {boolean}
 * @readonly
 * @since 2.8.4
 */
Object.defineProperty(BasCore.prototype, 'supportsMuteAreaOnCall', {
  get: function () {
    return this._supportsMuteAreaOnCall
  }
})

/**
 * Support Basalte live
 *
 * @name BasCore#supportsLive
 * @type {boolean}
 * @readonly
 * @since 3.0.0
 */
Object.defineProperty(BasCore.prototype, 'supportsLive', {
  get: function () {
    return this._supportsLive
  }
})

/**
 * Support for integrator access
 *
 * @name BasCore#supportsIntegratorAccess
 * @type {boolean}
 * @readonly
 * @since 2.9.2
 */
Object.defineProperty(BasCore.prototype, 'supportsIntegratorAccess', {
  get: function () {
    return this._supportsIntegratorAccess
  }
})

/**
 * Support for custom room images
 *
 * @name BasCore#supportsCustomRoomImages
 * @type {boolean}
 * @readonly
 * @since 2.11.0
 */
Object.defineProperty(BasCore.prototype, 'supportsCustomRoomImages', {
  get: function () {
    return this._supportsCustomRoomImages
  }
})

/**
 * Support for custom project image
 *
 * @name BasCore#supportsCustomProjectImage
 * @type {boolean}
 * @readonly
 * @since 3.3.0
 */
Object.defineProperty(BasCore.prototype, 'supportsCustomProjectImage', {
  get: function () {
    return this._supportsCustomProjectImage
  }
})

/**
 * Support for custom scene image
 *
 * @name BasCore#supportsCustomSceneImage
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(BasCore.prototype, 'supportsCustomSceneImage', {
  get: function () {
    return this._supportsCustomSceneImage
  }
})

/**
 * Support for Tidal OAuth
 *
 * @name BasCore#supportsTidalOAuth
 * @type {boolean}
 * @readonly
 * @since 3.6.1
 */
Object.defineProperty(BasCore.prototype, 'supportsTidalOAuth', {
  get: function () {
    return this._supportsTidalOAuth
  }
})

/**
 * Support for Asano AV API
 *
 * @name BasCore#supportsAsanoAV
 * @type {boolean}
 * @readonly
 * @since 3.7.0
 */
Object.defineProperty(BasCore.prototype, 'supportsAsanoAV', {
  get: function () {
    return this._supportsAsanoAV
  }
})

/**
 * Support for quick favourites
 *
 * @name BasCore#supportsQuickFavourites
 * @type {boolean}
 * @readonly
 * @since 3.9.0
 */
Object.defineProperty(BasCore.prototype, 'supportsQuickFavourites', {
  get: function () {
    return this._supportsQuickFavourites
  }
})

/**
 * Support for AV Audio DSP
 *
 * @name BasCore#supportsAsanoAV
 * @type {boolean}
 * @readonly
 * @since 3.9.0
 */
Object.defineProperty(BasCore.prototype, 'supportsAvAudioDsp', {
  get: function () {
    return this._supportsAvAudioDsp
  }
})

/**
 * @name BasCore#system
 * @type {System}
 * @readonly
 * @since 1.7.8
 */
Object.defineProperty(BasCore.prototype, 'system', {
  get: function () {
    return this._system
  }
})

/**
 * @name BasCore#system
 * @type {System}
 * @readonly
 * @since 3.0.0
 */
Object.defineProperty(BasCore.prototype, 'live', {
  get: function () {
    return this._live
  }
})

/**
 * @name BasCore#system
 * @type {SharedServerStorage}
 * @readonly
 * @since 2.6.0
 */
Object.defineProperty(BasCore.prototype, 'sharedServerStorage', {
  get: function () {
    return this._sharedServerStorage
  }
})

/**
 * The logged in user or null otherwise
 *
 * @deprecated Use Profile
 * @name BasCore#user
 * @type {?User}
 * @readonly
 * @since 0.1.0
 */
Object.defineProperty(BasCore.prototype, 'user', {
  get: function () {
    return this._user
  }
})

/**
 * The current profile of the server
 *
 * @name BasCore#profile
 * @type {Profile}
 * @readonly
 * @since 2.7.0
 */
Object.defineProperty(BasCore.prototype, 'profile', {
  get: function () {
    return this._profile
  }
})

/**
 * All areas known to this basCore
 *
 * @name BasCore#areas
 * @type {Object<string, Room>}
 * @readonly
 * @since 2.0.0
 */
Object.defineProperty(BasCore.prototype, 'areas', {
  get: function () {
    return this._areas
  }
})

/**
 * Audio sources
 *
 * @name BasCore#audioSources
 * @type {Object<string, AudioSource>}
 * @readonly
 * @since 3.4.0
 */
Object.defineProperty(BasCore.prototype, 'audioSources', {
  get: function () {
    return this._audioSources
  }
})

/**
 * Audio sources
 *
 * @name BasCore#videoSources
 * @type {Object<string, AudioSource>}
 * @readonly
 * @since 3.4.0
 */
Object.defineProperty(BasCore.prototype, 'videoSources', {
  get: function () {
    return this._videoSources
  }
})

/**
 * UUIDs of all connected devices
 *
 * @name BasCore#connectedDevices
 * @type {string[]}
 * @readonly
 * @since 2.2.0
 */
Object.defineProperty(BasCore.prototype, 'connectedDevices', {
  get: function () {
    return this._connectedDevices
  }
})

/**
 * UUIDs of all known servers
 *
 * @name BasCore#servers
 * @type {string[]}
 * @readonly
 * @since 2.0.0
 */
Object.defineProperty(BasCore.prototype, 'servers', {
  get: function () {
    return this._servers
  }
})

/**
 * The zones configured in the connected basCore
 *
 * @name BasCore#zones
 * @type {Object<string, Zone>}
 * @readonly
 * @since 1.2.4
 */
Object.defineProperty(BasCore.prototype, 'zones', {
  get: function () {
    return this._zones
  }
})

/**
 * The groups configured in the connected basCore
 *
 * @name BasCore#groups
 * @type {Object<string, Zone>}
 * @readonly
 * @since 1.2.4
 */
Object.defineProperty(BasCore.prototype, 'groups', {
  get: function () {
    return this._groups
  }
})

/**
 * The players configured in the connected basCore
 *
 * @name BasCore#players
 * @type {Object<string, Player>}
 * @readonly
 * @since 1.2.4
 */
Object.defineProperty(BasCore.prototype, 'players', {
  get: function () {
    return this._players
  }
})

/**
 * The Basalte Barps configured in the connected basCore
 *
 * @name BasCore#barps
 * @type {Object<string, (Barp | (Barp[]))>}
 * @readonly
 * @since 1.2.4
 */
Object.defineProperty(BasCore.prototype, 'barps', {
  get: function () {
    return this._barps
  }
})

/**
 * The external sources configured in the connected basCore
 *
 * @name BasCore#externals
 * @type {Object<string, External>}
 * @readonly
 * @since 1.2.4
 */
Object.defineProperty(BasCore.prototype, 'externals', {
  get: function () {
    return this._externals
  }
})

/**
 * The notification sources configured in the connected basCore
 *
 * @name BasCore#notifications
 * @type {Object<string, Notification>}
 * @readonly
 * @since 2.1.0
 */
Object.defineProperty(BasCore.prototype, 'notifications', {
  get: function () {
    return this._notifications
  }
})

/**
 * The bluetooth sources configured in the connected basCore
 *
 * @name BasCore#bluetooths
 * @type {Object<string, Bluetooth>}
 * @readonly
 * @since 2.1.0
 */
Object.defineProperty(BasCore.prototype, 'bluetooths', {
  get: function () {
    return this._bluetooths
  }
})

/**
 * The devices configured in the connected basCore
 *
 * @name BasCore#audioDevices
 * @type {Object<string, CobraAudioDevice>}
 * @readonly
 */
Object.defineProperty(BasCore.prototype, 'audioDevices', {
  get: function () {
    return this._audioDevices
  }
})

/**
 * Devices that are known to the basCore
 *
 * @name BasCore#devices
 * @type {Object<string, Device>}
 * @readonly
 * @since 2.0.0
 */
Object.defineProperty(BasCore.prototype, 'devices', {
  get: function () {
    return this._devices
  }
})

/**
 * Music Library
 *
 * @name BasCore#musicLibrary
 * @type {MusicLibrary}
 * @readonly
 * @since 4.0.0
 */
Object.defineProperty(BasCore.prototype, 'musicLibrary', {
  get: function () {
    return this._musicLibrary
  }
})

/**
 * Call Topic
 *
 * @name BasCore#callTopic
 * @type {CallTopic}
 * @readonly
 */
Object.defineProperty(BasCore.prototype, 'callTopic', {
  get: function () {
    return this._callTopic
  }
})

/**
 * All subscription topics
 *
 * @name BasCore#topics
 * @type {Topic[]}
 * @readonly
 * @private
 */
Object.defineProperty(BasCore.prototype, 'topics', {
  get: function () {
    return [this._audioAlertTopic, this._doorPhoneTopic]
  }
})

/**
 * Audio Alert Topic
 *
 * @name BasCore#audioAlertTopic
 * @type {AudioAlertTopic}
 * @readonly
 */
Object.defineProperty(BasCore.prototype, 'audioAlertTopic', {
  get: function () {
    return this._audioAlertTopic
  }
})

/**
 * Door phone topic
 *
 * @name BasCore#doorPhoneTopic
 * @type {DoorPhoneTopic}
 * @readonly
 */
Object.defineProperty(BasCore.prototype, 'doorPhoneTopic', {
  get: function () {
    return this._doorPhoneTopic
  }
})

/**
 * The TuneIn handler for the connected basCore
 *
 * @name BasCore#tunein
 * @type {TuneIn}
 * @readonly
 * @since 0.1.0
 */
Object.defineProperty(BasCore.prototype, 'tunein', {
  get: function () {
    return this._tunein
  }
})

/**
 * If the AV sources have been received yet or not
 *
 * @name BasCore#avSourcesReceived
 * @type {boolean}
 * @readonly
 * @since 3.4.0
 */
Object.defineProperty(BasCore.prototype, 'avSourcesReceived', {
  get: function () {
    return this._avSourcesReceived
  }
})

/**
 * If the music zones and sources have been received yet or not
 *
 * @name BasCore#musicConfigReceived
 * @type {boolean}
 * @readonly
 * @since 1.2.0
 */
Object.defineProperty(BasCore.prototype, 'musicConfigReceived', {
  get: function () {
    return this._musicConfigReceived
  }
})

/**
 * If the rooms have been received yet or not
 *
 * @name BasCore#roomsReceived
 * @type {boolean}
 * @readonly
 * @since 2.0.0
 */
Object.defineProperty(BasCore.prototype, 'roomsReceived', {
  get: function () {
    return this._roomsReceived
  }
})

/**
 * Whether the basCore has at least one Spotify Connect instance.
 *
 * @name BasCore#hasSpotifyConnect
 * @type {boolean}
 * @readonly
 * @since 2.0.0
 */
Object.defineProperty(BasCore.prototype, 'hasSpotifyConnect', {
  get: function () {
    return this._hasSpotifyConnect
  }
})

/**
 * Single room ID, empty if there are more or no rooms.
 *
 * @name BasCore#singleRoomId
 * @type {string}
 * @readonly
 * @since 3.0.0
 */
Object.defineProperty(BasCore.prototype, 'singleRoomId', {
  get: function () {
    return this._singleRoomId
  }
})

/**
 * Single audio room ID, empty if there are more or no audio rooms.
 *
 * @name BasCore#singleAudioRoomId
 * @type {string}
 * @readonly
 * @since 3.0.0
 */
Object.defineProperty(BasCore.prototype, 'singleAudioRoomId', {
  get: function () {
    return this._singleAudioRoomId
  }
})

/**
 * Single Source ID, empty if there are more or no sources.
 *
 * @name BasCore#singleSourceId
 * @type {number}
 * @readonly
 * @since 3.0.0
 */
Object.defineProperty(BasCore.prototype, 'singleSourceId', {
  get: function () {
    return this._singleSourceId
  }
})

/**
 * Single Player ID, empty if there are more or no players.
 *
 * @name BasCore#singlePlayerId
 * @type {number}
 * @readonly
 * @since 3.0.0
 */
Object.defineProperty(BasCore.prototype, 'singlePlayerId', {
  get: function () {
    return this._singlePlayerId
  }
})

/**
 * This will cause an API version check.
 *
 * @param {BasServer} basServer
 * @since 3.0.0
 */
BasCore.prototype.setBasServer = function (basServer) {

  if (this._server !== basServer) {

    this._clearServerListeners()

    this._server = basServer instanceof BasServer ? basServer : null

    this._setServerListeners()
  }

  if (this._server && this._server.apiVersionKnown) {

    this._parseApiVersion(this._server.apiVersion)
  }
}

/**
 * Connect to the server with a socket for bi-directional communication.
 * This will trigger a EVT_CORE_CONNECTED event.
 *
 * @returns {Promise}
 * @since 2.0.2
 */
BasCore.prototype.connectCore = function () {

  return this._server
    ? this._server.connectCore().then(this._handleCoreConnected)
    : Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @private
 */
BasCore.prototype._onCoreConnected = function () {

  var _profile

  // Parse API version again because support flags could have been cleared

  if (this._server && this._server.apiVersionKnown) {

    this._parseApiVersion(this._server.apiVersion)
  }

  _profile = this._server
    ? this._server.getConnectedProfile()
    : null

  this._profile.parseBasProfile(_profile)

  // Local account
  if (_profile) this.setNewLoggedInUser(_profile)
}

/**
 * Disconnect from the server and remove all stored credential
 * information.
 * This will also invalidate the current session token.
 *
 * This will trigger a 'connected' event, see
 * This will trigger a EVT_CORE_CONNECTED event.
 *
 * @param {TCoreCredentials} [credentials]
 * @returns {Promise}
 */
BasCore.prototype.logout = function (credentials) {

  if (this._server) this._server.disconnectCore()

  this.clearAllCoreInfo()

  return this._server
    ? this._server.logout(credentials)
    : Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * ONLY FOR ADMINS! Reboots the server (eg to install updates).
 * Restarts via BasServer
 *
 * @param {TCoreCredentials} [credentials]
 * @returns {Promise<TBasServerResponse>}
 * @since 3.0.0
 */
BasCore.prototype.restartServer = function (credentials) {

  return this._server
    ? this._server.restart(credentials)
    : Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * Restart using a system message.
 *
 * @since 3.0.0
 */
BasCore.prototype.restartSystem = function () {

  this._system.restart()
}

/**
 * Terminates all connections and clears core info.
 */
BasCore.prototype.disconnect = function () {

  if (this._server) this._server.disconnect()

  this.clearAllCoreInfo()
}

/**
 * Send a message to the connected basCore, returns true if successful
 *
 * @param {Object} data
 * @returns {boolean}
 */
BasCore.prototype.send = function (data) {

  return this._server ? this._server.send(data) : false
}

/**
 * Sends a request to the server.
 * The returned Promise will resolve with the answer.
 *
 * @param {Object} data
 * @param {TBasRequestOptions} [options]
 * @returns {Promise}
 */
BasCore.prototype.requestRetry = function (data, options) {

  return this._server
    ? this._server.requestRetry(data, options)
    : Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * Sends a request to the server via the subscription socket.
 * The returned Promise will resolve with the answer.
 *
 * @param {Object} data
 * @param {number} [timeout]
 * @returns {Promise}
 */
BasCore.prototype.requestV2 = function (data, timeout) {

  return this._server
    ? this._server.requestV2(data, timeout)
    : Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @private
 * @param {boolean} isConnected
 * @since 3.0.0
 */
BasCore.prototype._onServerConnected = function (isConnected) {

  this.emit(BasCore.EVT_CONNECTED, isConnected)
}

/**
 * @private
 */
BasCore.prototype._onServerConnectionJWTRevoked = function () {

  this.emit(BasCore.EVT_CONNECTION_JWT_REVOKED)
}

/**
 * @private
 * @param {boolean} isConnected
 * @since 3.0.0
 */
BasCore.prototype._onServerSocketConnected = function (isConnected) {

  this.emit(BasCore.EVT_CORE_CONNECTED, isConnected)
}

/**
 * @private
 * @param {Object} message
 * @since 3.0.0
 */
BasCore.prototype._onServerSocketMessage = function (message) {

  this._parse(message)
}

/**
 * @private
 * @param {boolean} isConnected
 */
BasCore.prototype._onServerV2SocketConnected = function (
  isConnected
) {
  this.emit(BasCore.EVT_CORE_V2_CONNECTED, isConnected)
}

/**
 * @private
 * @param {Object} message
 */
BasCore.prototype._onServerV2SubscriptionSocketMessage = function (message) {

  this._parseV2(message)
}

/**
 * @private
 * @param {number} apiVersion
 * @since 3.0.0
 */
BasCore.prototype._onServerApiVersion = function (apiVersion) {

  this._parseApiVersion(apiVersion)

  if (this._server) {

    this.emit(BasCore.EVT_API_VERSION, this._server.apiVersion)
  }
}

/**
 * @typedef {Object} TSipInfo
 * @property {string} sipAddress
 * @property {string} password
 * @property {string} doorPhone
 * @property {string} contact
 */

/**
 * @private
 * @param {number} apiVersion
 * @since 3.0.0
 */
BasCore.prototype._parseApiVersion = function (apiVersion) {

  if (BasUtil.isPNumber(apiVersion)) {

    this._supportsDefaultRooms =
      this._supportsReplaceNow =
        this._supportsDescribeZone =
          this._supportsBasicZoneDSP =
            this._supportsSpotifyWebAPI =
              this._supportsSystemProperties =
                apiVersion >= 1
  }
}

/**
 * Enable/disable certain features based on basCore version number.
 *
 * @private
 * @param {string} versionString
 * @since 1.6.0
 */
BasCore.prototype._setVersion = function (versionString) {

  var _apiVersion

  if (!this._version || !this._version.isSame(versionString)) {

    this._version = new BasUtil.BasVersion(versionString)

    _apiVersion = this._server
      ? this._server.apiVersion
      : CONSTANTS.DEFAULT_API_VERSION

    // Only parse these specific options when there is no API version
    if (_apiVersion === 0) {

      this._supportsDefaultRooms = BasUtil.BasVersion.compare(
        this._version,
        CONSTANTS.VERSION_DEFAULT_ROOMS
      ) >= 0
      this._supportsReplaceNow = BasUtil.BasVersion.compare(
        this._version,
        CONSTANTS.VERSION_REPLACE_NOW
      ) >= 0
      this._supportsDescribeZone = BasUtil.BasVersion.compare(
        this._version,
        CONSTANTS.VERSION_DESCRIBE_ZONE
      ) >= 0
      this._supportsBasicZoneDSP = BasUtil.BasVersion.compare(
        this._version,
        CONSTANTS.VERSION_BASIC_ZONE_DSP
      ) >= 0
      this._supportsSpotifyWebAPI = BasUtil.BasVersion.compare(
        this._version,
        CONSTANTS.VERSION_SPOTIFY_WEB_API
      ) >= 0
      this._supportsSystemProperties = BasUtil.BasVersion.compare(
        this._version,
        CONSTANTS.VERSION_SYSTEM_PROPERTIES
      ) >= 0
    }

    this._supportsSchedulerAndDetailScene = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_SCHEDULER_DETAIL_SCENE
    ) >= 0

    this._supportsSpotifyUnlink = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_SPOTIFY_UNLINK
    ) >= 0

    this._supportsRepeatMode = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_REPEAT_MODE
    ) >= 0

    this._supportsCheckingUpdates = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_CHECKING_UPDATES
    ) >= 0

    this._supportsSunriseSet = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_SUNRISE_SET
    ) >= 0

    this._supportsDevicesScenes = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_GENERIC_DEVICES_SCENE
    ) >= 0

    this._supportsProfile = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_PROFILE
    ) >= 0

    this._supportsMuteAreaOnCall = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_MUTE_AREA_ON_CALL
    ) >= 0

    this._supportsLive = this._supportsIntegratorAccess =
      BasUtil.BasVersion.compare(
        this._version,
        CONSTANTS.VERSION_BASALTE_LIVE
      ) >= 0

    this._supportsAlarmsAccess = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_ALARMS_ACCESS
    ) >= 0

    this._supportsCustomRoomImages = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_CUSTOM_ROOM_IMAGES
    ) >= 0

    this._supportsCustomProjectImage = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_CUSTOM_PROJECT_IMAGE
    ) >= 0

    this._supportsCustomSceneImage = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_CUSTOM_SCENE_IMAGE
    ) >= 0

    this._supportsTidalOAuth = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_TIDAL_OAUTH
    ) >= 0

    this._supportsAsanoAV = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_ASANO_AV
    ) >= 0

    this._supportsQuickFavourites = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_QUICK_FAVOURITES
    ) >= 0

    this._supportsAvAudioDsp = BasUtil.BasVersion.compare(
      this._version,
      CONSTANTS.VERSION_AV_AUDIO_DSP
    ) >= 0

    this.emit(BasCore.EVT_VERSION, this._version)
  }
}

/**
 * @private
 * @param {boolean} hasUpdate
 */
BasCore.prototype._onServerHasUpdate = function (hasUpdate) {

  this.emit(BasCore.EVT_HAS_UPDATE, hasUpdate)
}

/**
 * @param {string} versionString
 * @since 3.0.0
 */
BasCore.prototype.setVersionFromSystem = function (versionString) {

  this._setVersion(versionString)
}

/**
 * @param {string} projectId
 * @since 3.0.0
 */
BasCore.prototype.setProjectId = function (projectId) {

  if (this._server) this._server.setProjectId(projectId)
}

/**
 * Get the full url
 *
 * @param {string} [path] relative resource path
 * @returns {string}
 * @since 2.1.0
 */
BasCore.prototype.getHTTPUrl = function (path) {

  var _host

  if (this._server) {

    if (this._server.isDemo()) return path

    _host = this._server.host

    if (_host) return _host.getHTTPUrl(path)
  }

  return ''
}

/**
 * Get the weather forecast
 *
 * @param {string} [params]
 * @returns {Promise<TBasServerResponse>}
 */
BasCore.prototype.retrieveWeatherData = function (params) {

  return this._server
    ? this._server.getWeatherData(params)
    : Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * Get the full url for a cover art file on the basCore
 *
 * @param {string} file Referring to image on basCore
 * @param {boolean} [thumb = false] Require thumbnail
 * @returns {string} The full url for the file
 * @since 0.1.0
 */
BasCore.prototype.getCoverArtUrl = function (file, thumb) {

  var url

  if (BasUtil.isNEString(file)) {

    if (this._server && this._server.isDemo()) {

      return this._server.demo.getCoverArtUrl(file, thumb)
    }

    // Create basic basCore cover path URL
    url = thumb
      ? CONSTANTS.PATH_WEB_COVER_THUMB
      : CONSTANTS.PATH_WEB_COVER

    // Append the cover art filename
    url += ('/' + file)

    return url
  }

  return ''
}

/**
 * Modify an object to contain cover art and thumbnail cover art URLs
 *
 * @param {(Object | BasTrack)} song
 * @param {string} song.coverart BasCore local cover art location
 * @param {Object} [options]
 * @param {boolean} [options.copy = false]
 * @param {boolean} [options.clear = false] Only when "coverart" is empty
 * @param {boolean} [options.adjust = true]
 * @returns {Object} The modified song Object
 * @since 0.1.0
 */
BasCore.prototype.modifySongCoverArt = function (
  song,
  options
) {
  var _copy, _clear, _adjust

  _copy = false
  _clear = false
  _adjust = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.copy)) _copy = options.copy
    if (BasUtil.isBool(options.clear)) _clear = options.clear
    if (BasUtil.isBool(options.adjust)) _adjust = options.adjust
  }

  // Check if song is valid
  if (BasUtil.isObject(song)) {

    // Check for valid cover art property
    if (BasUtil.isNEString(song[P.COVERART])) {

      if (_copy) {

        // Copy the cover art string to the different URL strings
        song[BasTrack.K_COVERART_URL] = song[P.COVERART]
        song[BasTrack.K_THUMBNAIL_URL] = song[P.COVERART]

      } else {

        // Generate thumbnail and normal cover art URL's
        song[BasTrack.K_THUMBNAIL_URL] = _adjust
          ? this.getCoverArtUrl(song[P.COVERART], true)
          : song[P.COVERART]
        song[BasTrack.K_COVERART_URL] = _adjust
          ? this.getCoverArtUrl(song[P.COVERART])
          : song[P.COVERART]
      }

    } else {

      if (song instanceof BasTrack) {

        song.setCoverart('')

      } else {

        // Make sure properties exist
        song[P.COVERART] = ''
      }

      if (_clear) {

        song[BasTrack.K_COVERART_URL] = ''
        song[BasTrack.K_THUMBNAIL_URL] = ''

      } else {

        if (!BasUtil.isString(song[BasTrack.K_COVERART_URL])) {

          song[BasTrack.K_COVERART_URL] = ''
        }

        if (!BasUtil.isString(song[BasTrack.K_THUMBNAIL_URL])) {

          song[BasTrack.K_THUMBNAIL_URL] = ''
        }
      }
    }
  }

  return song
}

/**
 * @param {BasProfile} basProfile
 */
BasCore.prototype.setNewLoggedInUser = function (basProfile) {
  this._clearUser()
  this._user = new User(basProfile, this)
  this.emit(BasCore.EVT_USER_CREATED)
}

/**
 * Process an array of objects to add the correct coverart URL
 *
 * @param {Object[]} arr
 * @param {boolean} [adjust = true]
 */
BasCore.prototype.processCoverArts = function (arr, adjust) {

  var i, length

  if (Array.isArray(arr)) {

    length = arr.length
    for (i = 0; i < length; i++) {

      this.modifySongCoverArt(arr[i], {
        adjust: adjust
      })
    }
  }
}

/**
 * Parse message from basCore
 *
 * @private
 * @param {Object} obj
 */
BasCore.prototype._parse = function (obj) {

  var arr, i, length, basProfile

  if (BasUtil.isObject(obj)) {

    // Version (backwards compatibility)
    if (BasUtil.isNEString(obj[P.VERSION])) {

      this._setVersion(obj[P.VERSION])
    }

    // System
    if (BasUtil.isObject(obj[P.SYSTEM])) {

      this._system.parse(obj)
    }

    // Profile
    if (BasUtil.isObject(obj[P.PROFILE])) {

      this._profile.parse(obj)

      basProfile = this._profile.convertToBasProfile()

      if (this._server.connectedUsingCloudAccount && basProfile) {

        basProfile.jwt = this._server.connectedCredentials[0][P.JWT]

        this.setNewLoggedInUser(basProfile)

        this._server.addProfileToUsers(basProfile)
      }
    }

    // SharedServerStorage
    if (BasUtil.isObject(obj[P.STORAGE])) {

      this._sharedServerStorage.parse(obj)
    }

    // Rooms
    if (P.ROOMS in obj) {

      this._parseRooms(obj[P.ROOMS])
    }

    // Room
    if (BasUtil.isObject(obj[P.ROOM])) {

      this._parseRoom(obj[P.ROOM])
    }

    // Device
    if (BasUtil.isObject(obj[P.DEVICE])) {

      this._parseDevice(obj[P.DEVICE])
    }

    // AV-Sources
    if (BasUtil.isObject(obj[P.AV_SOURCES])) {

      this._parseAVSources(obj[P.AV_SOURCES])
    }

    // AV-Source update
    if (BasUtil.isObject(obj[P.AV_SOURCE])) {

      this._parseAVSource(obj[P.AV_SOURCE])
    }

    // Library
    if (BasUtil.isObject(obj[P.LIBRARY])) {

      this.musicLibrary.parse(obj[P.LIBRARY])
    }

    // Music
    if (P.MUSIC in obj) {

      this._parseMusic(obj[P.MUSIC])
    }

    // Devices
    if (BasUtil.isObject(obj[P.DEVICES])) {

      this._parseCobraAudioDevices(obj[P.DEVICES])
    }

    // User
    if (BasUtil.isObject(obj[P.USER])) {

      if (this._user) this._user.parse(obj)

    }

    // Zone
    if (BasUtil.isObject(obj[P.ZONE]) &&
      BasUtil.isObject(this._zones[obj[P.ZONE][P.ID]])) {

      this._zones[obj[P.ZONE][P.ID]].parse(obj)
    }

    // Group
    if (BasUtil.isObject(obj[P.GROUP]) &&
      BasUtil.isObject(this._groups[obj[P.GROUP][P.ID]])) {

      this._groups[obj[P.GROUP][P.ID]].parse(obj)
    }

    // Stream
    if (BasUtil.isObject(obj[P.STREAM]) &&
      BasUtil.isObject(this._players[obj[P.STREAM][P.ID]])) {

      this._players[obj[P.STREAM][P.ID]]
        .parseStream(obj[P.STREAM])
    }

    // Player
    if (BasUtil.isObject(obj[P.PLAYER]) &&
      BasUtil.isObject(this._players[obj[P.PLAYER][P.ID]])) {

      this._players[obj[P.PLAYER][P.ID]].parse(obj)
    }

    // Barp
    if (BasUtil.isObject(obj[P.BARP]) &&
      BasUtil.isObject(this._barps[obj[P.BARP][P.ID]]) &&
      BasUtil.isNEString(obj[P.BARP][P.UUID])) {

      arr = this._barps[obj[P.BARP][P.ID]]

      if (Array.isArray(arr)) {

        // Iterate Barps for ID
        length = arr.length
        for (i = 0; i < length; i++) {

          if (BasUtil.isObject(arr[i]) &&
            arr[i].uuid === obj[P.BARP][P.UUID]) {

            arr[i].parse(obj)
            break
          }
        }

      } else if (arr.uuid === obj[P.BARP][P.UUID]) {

        arr.parse(obj)
      }
    }

    // Bluetooth
    if (BasUtil.isObject(obj[P.SOURCE]) &&
      BasUtil.isObject(this._bluetooths[obj[P.SOURCE][P.ID]])) {

      this._bluetooths[obj[P.SOURCE][P.ID]]
        .parse(obj[P.SOURCE])
    }

    // AudioOutput
    if (BasUtil.isObject(obj[P.AUDIO_OUTPUT])) {

      // Parse audioOutput by forwarding to all devices
      this._parseAudioOutput(obj)
    }

    // Live
    if (BasUtil.isObject(obj[P.LIVE])) {

      this._live.parse(obj)
    }
  }
}

/**
 * Parse message from basCore subscription socket
 *
 * @private
 * @param {Object} obj
 */
BasCore.prototype._parseV2 = function (obj) {

  var i, length

  if (obj && BasUtil.isNEString(obj[P.TOPIC])) {
    i = 0
    length = this.topics.length
    for (i = 0; i < length; i++) {
      if (this.topics[i].topic === obj[P.TOPIC]) {
        this.topics[i].parse(obj)
        break
      }
    }
  }
}

/**
 * Register to topic on subscription socket
 *
 * @private
 * @param {string} topic
 * @returns {Promise}
 */
BasCore.prototype.subscribeTo = function (topic) {

  var obj = {}
  obj[P.DATA] = {}
  obj[P.DATA][P.ACTION] = P.SUBSCRIBE
  obj[P.DATA][P.TOPIC] = topic

  return this.requestV2(obj)
    .then(Device.handleResponse)
}

/**
 * Parses an AV sources message
 *
 * @private
 * @param {Object} msg
 * @since 3.4.0
 */
BasCore.prototype._parseAVSources = function (msg) {

  var value, keys, i, key

  value = msg[P.AUDIO]

  if (BasUtil.isObject(value)) {

    this._clearAudioSources()

    keys = Object.keys(value)

    for (i = 0; i < keys.length; i++) {

      key = keys[i]

      this._audioSources[key] = new AudioSource(this, value[key])
    }
  }

  value = msg[P.VIDEO]

  if (BasUtil.isObject(value)) {

    this._clearVideoSources()

    keys = Object.keys(value)

    for (i = 0; i < keys.length; i++) {

      key = keys[i]

      this._videoSources[key] = new VideoSource(this, value[key])
    }
  }

  this._avSourcesReceived = true
  this.emit(BasCore.EVT_AV_SOURCES_UPDATED)
}

/**
 * Parses an AV source update
 *
 * @private
 * @param {Object} msg
 * @since 3.4.0
 */
BasCore.prototype._parseAVSource = function (msg) {

  var uuid, source

  if (BasUtil.isObject(msg)) {

    uuid = msg[P.UUID]

    if (BasUtil.isNEString(uuid)) {

      source = this._audioSources[uuid]

      if (source) source.parse(msg)

      source = this._videoSources[uuid]

      if (source) source.parse(msg)
    }
  }
}

/**
 * Parse a "music" message.
 *
 * This message contains all the basCore variables.
 *
 * @private
 * @param {?Object} music
 */
BasCore.prototype._parseMusic = function (music) {

  var i, length, item

  this._clearMusicVariables()

  if (BasUtil.isObject(music)) {

    // Zones
    if (Array.isArray(music[P.ZONES])) {

      // Iterate Sources
      length = music[P.ZONES].length
      for (i = 0; i < length; i++) {

        // Set Zone config reference
        item = music[P.ZONES][i]

        // Add new Zone
        this._zones[item[P.ID]] = new Zone(item, this)
      }
    }

    // Groups
    if (Array.isArray(music[P.GROUPS])) {

      // Iterate Groups
      length = music[P.GROUPS].length
      for (i = 0; i < length; i++) {

        // Set Group config reference
        item = music[P.GROUPS][i]

        // Add new Group
        this._groups[item[P.ID]] = new Zone(item, this)
      }
    }

    // Sources
    if (Array.isArray(music[P.SOURCES])) {

      this._parseSources(music[P.SOURCES])
    }
  }

  // BasCore variables have been created
  this._musicConfigReceived = true

  this._determineSingleRooms()

  this.emit(BasCore.EVT_MUSIC_CONFIG_UPDATED)
}

/**
 * Parse sources of "music" message
 *
 * @private
 * @param {Object[]} sources
 * @since 2.1.0
 */
BasCore.prototype._parseSources = function (sources) {

  var i, length, item, obj1, obj2

  // Iterate Sources
  length = sources.length
  for (i = 0; i < length; i++) {

    // Set Source config reference
    item = sources[i]

    // Check Source config
    switch (item[P.TYPE]) {

      case P.PLAYER:

        if (_checkSourceID(item)) {

          this._players[item[P.ID]] = new Player(item, this)
        }

        break

      case P.BARP:

        if (_checkSourceID(item)) {

          // Create correct Barp instance
          if (item[P.SUB_TYPE] === Barp.TYPE_SPOTIFY) {

            this._hasSpotifyConnect = true

            obj1 = new SpotifyBarp(item, this)

          } else {

            obj1 = new Barp(item, this)
          }

          // Check current Barp(s) for ID
          if (Array.isArray(this._barps[obj1.id])) {

            // Add Barp to Array
            this._barps[item[P.ID]].push(obj1)

          } else if (BasUtil.isObject(this._barps[obj1.id])) {

            // Store existing Barp
            obj2 = this._barps[obj1.id]

            // Create Array for old and new Barp
            this._barps[obj1.id] = [
              obj2,
              obj1
            ]

          } else {

            // Add Barp
            this._barps[obj1.id] = obj1
          }
        }

        break

      case P.EXTERNAL:

        if (_checkSourceID(item[P.CONFIG])) {

          // Create External source
          this._externals[item[P.CONFIG][P.ID]] =
            new External(item[P.CONFIG])
        }

        break

      case P.NOTIFICATION:

        if (_checkSourceID(item[P.CONFIG])) {

          // Create Notification source
          this._notifications[item[P.CONFIG][P.ID]] =
            new Notification(item[P.CONFIG])
        }

        break

      case P.BLUETOOTH:

        if (_checkSourceID(item[P.CONFIG])) {

          // Create Bluetooth source
          this._bluetooths[item[P.CONFIG][P.ID]] =
            new Bluetooth(item[P.CONFIG], this)
        }

        break

      default:
        log.warn('BasCore - Unknown source type', item)
    }
  }

  this._determineSingleSources()

  /**
   * Checks whether the ID on the object is a positive non-zero number.
   *
   * @private
   * @param {?Object} obj
   * @param {number} obj.id
   * @returns {boolean}
   * @since 2.1.0
   */
  function _checkSourceID (obj) {

    return BasUtil.isObject(obj) && BasUtil.isPNumber(obj[P.ID])
  }
}

/**
 * Parse a "devices" message
 *
 * This message contains all devices.
 *
 * @private
 * @param {Object} devices
 */
BasCore.prototype._parseCobraAudioDevices = function (devices) {

  var keys, length, i, device, newDevicesReceived

  newDevicesReceived = false

  // Iterate over all devices
  keys = Object.keys(devices)
  length = keys.length
  for (i = 0; i < length; i++) {

    // Set device reference
    device = devices[keys[i]]

    // Check device is valid
    if (BasUtil.isObject(device) &&
      BasUtil.isNEString(device[P.UUID]) &&
      keys[i] === device[P.UUID]) {

      if (BasUtil.safeHasOwnProperty(this._audioDevices, keys[i])) {

        log.warn(
          'BasCore - Parse' +
          ' - CobraAudioDevice exists already'
        )

      } else {

        // Create new device
        this._audioDevices[keys[i]] =
          new CobraAudioDevice(device, this)

        newDevicesReceived = true
      }
    }
  }

  if (newDevicesReceived) {

    this.emit(BasCore.EVT_AUDIO_DEVICES_UPDATED)
  }
}

/**
 * Parse an "audioOutput" message
 *
 * This message wil be forwarded to all devices
 *
 * @private
 * @param {Object} audioOutput
 */
BasCore.prototype._parseAudioOutput = function (audioOutput) {

  var keys, i, length, device

  // Iterate over devices
  keys = Object.keys(this._audioDevices)
  length = keys.length
  for (i = 0; i < length; i++) {

    // Set device reference
    device = this._audioDevices[keys[i]]

    if (BasUtil.isObject(device) &&
      BasUtil.isFunction(device.parse)) {

      // Let device parse this message
      device.parse(audioOutput)
    }
  }
}

/**
 * Parse "rooms"
 *
 * @private
 * @param {?Object} rooms
 */
BasCore.prototype._parseRooms = function (rooms) {

  var keys, i, length, uuid, roomObj, room

  this._clearAreas()

  if (BasUtil.isObject(rooms)) {

    // Create Rooms

    keys = Object.keys(rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      roomObj = rooms[uuid]

      room = new Room(this, roomObj)

      this._areas[room.uuid] = room
    }
  }

  // Resolve tree

  keys = Object.keys(this._areas)
  length = keys.length
  for (i = 0; i < length; i++) {

    room = this._areas[keys[i]]

    this._processRoom(room)

    room.syncDevices({
      emit: false
    })
  }

  this._roomsReceived = true

  this._determineSingleRooms()

  this.emit(BasCore.EVT_ROOMS_UPDATED)
}

/**
 * Processes the room by resolving the tree based on levels
 *
 * @private
 * @param {Room} room
 * @returns {boolean}
 */
BasCore.prototype._processRoom = function (room) {

  /**
   * @type {Room}
   */
  var parent

  parent = this._areas[room.parent]

  while (parent) {

    switch (parent.level) {
      case Room.LEVELS.LVL_FLOOR:

        room._floorId = parent.uuid

        break
      case Room.LEVELS.LVL_BUILDING:

        room._buildingId = parent.uuid

        break
      case Room.LEVELS.LVL_HOME:
        return true
    }

    parent = this._areas[parent.parent]
  }

  return false
}

/**
 * Parse "room"
 *
 * @private
 * @param {Object} msg
 */
BasCore.prototype._parseRoom = function (msg) {

  var uuid, area

  if (BasUtil.isObject(msg)) {

    uuid = msg[P.UUID]

    if (BasUtil.isNEString(uuid)) {

      area = this._areas[uuid]

      if (area) area.parse(msg)
    }
  }
}

/**
 * Parse "device"
 *
 * @private
 * @param {Object} device
 */
BasCore.prototype._parseDevice = function (device) {

  var uuid, newDevice

  if (BasUtil.isObject(device) && BasUtil.isNEString(device[P.UUID])) {

    uuid = device[P.UUID]

    if (BasUtil.safeHasOwnProperty(this._devices, uuid)) {

      this._devices[uuid].parse(device)

    } else {

      switch (device[P.TYPE]) {
        case Device.T_SERVER:

          newDevice = new ServerDevice(device, this)

          break
        case Device.T_ELLIE:
        case Device.T_LISA:
        case Device.T_LENA:

          newDevice = new CoreClientDevice(device, this)

          break
        case Device.T_LIGHT:

          newDevice = new LightDevice(device, this)

          break
        case Device.T_SHADE:

          newDevice = new ShadeDevice(device, this)

          break
        case Device.T_SCENE_CONTROLLER:

          newDevice = new SceneCtrlDevice(device, this)

          break
        case Device.T_TIMER_CONTROLLER:

          newDevice = new TimerCtrlDevice(device, this)

          break
        case Device.T_THERMOSTAT:

          newDevice = new ThermostatDevice(device, this)

          break
        case Device.T_POOL:

          newDevice = new PoolDevice(device, this)

          break
        case Device.T_CAMERA:

          newDevice = new CameraDevice(device, this)

          break
        case Device.T_ENERGY:

          newDevice = new EnergyDevice(device, this)

          break
        case Device.T_DOOR_PHONE_GATEWAY:

          newDevice = new DoorPhoneGatewayDevice(device, this)

          break
        case Device.T_DOOR_PHONE:

          newDevice = new DoorPhoneDevice(device, this)

          break
        case Device.T_GENERIC:

          newDevice = new GenericDeviceV1(device, this)

          break
        case Device.T_GENERIC_V2:

          newDevice = new GenericDeviceV2(device, this)

          break
        case Device.T_OPEN_CLOSE:

          newDevice = new OpenCloseDevice(device, this)

          break
        case Device.T_WEATHER_STATION:

          newDevice = new WeatherStationDevice(device, this)

          break
        case Device.T_ENERGY_METER:

          newDevice = new EnergyDevice(device, this)

          break
        default:

          if (BasUtil.isPNumber(device[P.BAS_TYPE])) {

            newDevice = new ConnectedDevice(device, this)

          } else {

            // Do not create device
            // Only create device if a type is known
          }
      }

      if (newDevice) {

        this._devices[uuid] = newDevice

        this._syncRoomDevices()

        this.emit(BasCore.EVT_DEVICES_UPDATED)

        // Check for new connected device
        if (newDevice instanceof ConnectedDevice &&
          this._connectedDevices.indexOf(newDevice.uuid) === -1) {

          this._connectedDevices.push(newDevice.uuid)

          this.emit(
            BasCore.EVT_CONNECTED_DEVICES_UPDATED,
            this._connectedDevices
          )
        }

        // Check for new server device
        if (newDevice instanceof ServerDevice &&
          this._servers.indexOf(newDevice.uuid) === -1) {

          this._servers.push(newDevice.uuid)

          this.emit(BasCore.EVT_SERVERS_UPDATED, this._servers)
        }
      }
    }
  }
}

/**
 * Let all rooms match their devices with the current available devices
 *
 * @private
 */
BasCore.prototype._syncRoomDevices = function () {

  var keys, i, length, room

  keys = Object.keys(this._areas)
  length = keys.length
  for (i = 0; i < length; i++) {

    room = this._areas[keys[i]]

    if (room) room.syncDevices()
  }
}

/**
 * Returns the zone associated with the id, undefined otherwise
 *
 * @param {string} id UUID
 * @returns {?Zone}
 */
BasCore.prototype.zoneForId = function (id) {

  if (id in this._zones) {

    return this._zones[id]

  } else if (id in this._groups) {

    return this._groups[id]
  }

  return null
}

/**
 * Notifies all zones, with the specified sourceId,
 * that a source change has occurred.
 *
 * @param {number} id Source ID
 * @since 1.9.0
 */
BasCore.prototype.notifyZones = function notifyZones (id) {

  var keys, i, length, zone

  keys = Object.keys(this._zones)
  length = keys.length
  for (i = 0; i < length; i++) {

    zone = this._zones[keys[i]]

    // Check Zone is listening for CobraNet ID
    if (BasUtil.isObject(zone) &&
      zone.sourceID === id) {

      zone.notifySourceChange(id)
    }
  }

  keys = Object.keys(this._groups)
  length = keys.length
  for (i = 0; i < length; i++) {

    zone = this._groups[keys[i]]

    // Check Zone is listening for CobraNet ID
    if (BasUtil.isObject(zone) &&
      zone.sourceID === id) {

      zone.notifySourceChange(id)
    }
  }
}

/**
 * Whether there is a single source (ID).
 *
 * @returns {boolean}
 * @since 3.0.0
 */
BasCore.prototype.hasSingleSource = function () {

  return this._singleSourceId !== CONSTANTS.NO_SOURCE_ID
}

/**
 * Whether there is a single player (ID).
 *
 * @returns {boolean}
 * @since 3.0.0
 */
BasCore.prototype.hasSinglePlayer = function () {

  return this._singlePlayerId !== CONSTANTS.NO_SOURCE_ID
}

/**
 * Returns the source associated with the id, undefined otherwise
 *
 * @param {number} id (CobraNet) ID of the source
 * @returns {(
 * Barp|
 * Player|
 * External|
 * Bluetooth|
 * Notification|
 * number|
 * null)}
 */
BasCore.prototype.sourceForId = function (id) {

  var i, length

  if (id <= 0) return id

  // Barp
  if (BasUtil.isObject(this._barps[id])) {

    if (Array.isArray(this._barps[id])) {

      length = this._barps[id].length
      for (i = 0; i < length; i++) {

        if (BasUtil.isObject(this._barps[id][i]) &&
          this._barps[id][i].connected) {

          return this._barps[id][i]
        }
      }

    } else if (this._barps[id].connected) {

      return this._barps[id]
    }
  }

  return this.playerForId(id)
}

/**
 * Returns the source associated with the id, undefined otherwise.
 * This method will not return a Barp if a Barp is playing.
 *
 * @param {number} id The id of the source
 * @returns {(Player|External|Bluetooth|Notification|number|null)}
 * @since 1.2.0
 */
BasCore.prototype.playerForId = function (id) {

  if (id <= 0) return id

  // Player
  if (BasUtil.isObject(this._players[id])) return this._players[id]

  // External
  if (BasUtil.isObject(this._externals[id])) return this._externals[id]

  // Bluetooth
  if (BasUtil.isObject(this._bluetooths[id])) return this._bluetooths[id]

  // Notifications
  if (BasUtil.isObject(this._notifications[id])) {

    return this._notifications[id]
  }

  return null
}

/**
 * Returns a (random) player instance.
 *
 * @returns {?Player}
 * @since 1.5.0
 */
BasCore.prototype.getFirstPlayer = function () {

  var keys, i, length, player

  if (BasUtil.isObject(this._players)) {

    keys = Object.keys(this._players)
    length = keys.length
    for (i = 0; i < length; i++) {

      player = this._players[keys[i]]
      if (BasUtil.isObject(player)) return this._players[keys[i]]
    }
  }

  return null
}

/**
 * @returns {Player[]}
 * @since 3.0.0
 */
BasCore.prototype.getSortedPlayers = function () {

  var result

  result = BasUtil.objectToArray(this._players)
  result.sort(Player.compare)

  return result
}

/**
 * Returns the Spotify Connect Barp associated with the Cobranet ID.
 * Returns null if no Spotify Connect Barp is found for the Cobranet ID.
 *
 * @param {(number|string)} id Cobranet ID
 * @returns {?Barp} Spotify Barp or null
 * @since 1.4.0
 */
BasCore.prototype.spotifyBarpForId = function (id) {

  var _id, i, length, barp

  // Check for string input
  if (BasUtil.isNEString(id)) {

    // Convert string to number
    _id = parseInt(id, 10)

  } else {

    _id = id
  }

  // Check input
  if (BasUtil.isPNumber(_id)) {

    // Set reference to found Barp
    barp = this._barps[_id]

    // Check if Barp for CobraNet ID is an Array or Barp instance
    if (Array.isArray(barp)) {

      // Iterate over Barps
      length = barp.length
      for (i = 0; i < length; i++) {

        // Check if Barp is Spotify Barp
        if (Barp.checkSpotifyBarp(barp[i])) return barp[i]
      }

    } else if (Barp.checkSpotifyBarp(barp)) {

      return barp
    }
  }

  return null
}

/**
 * Checks whether there are Streams that have no default rooms configured.
 *
 * This check will only work when the current user is an admin.
 *
 * @returns {Promise}
 */
BasCore.prototype.checkDefaultRooms = function () {

  var promises, keys, i, length

  if (BasUtil.isObject(this._user) &&
    this._user.admin) {

    // Create Array that will hold all Promises
    promises = []

    // Iterate all Streams
    keys = Object.keys(this._players)
    length = keys.length
    for (i = 0; i < length; i++) {

      // Retrieve the default zones and add promise
      promises.push(
        this._players[keys[i]]
          .retrieveDefaultZones()
          .then(_onDefaultZones)
      )
    }

    return Promise.all(promises)
      .then(_onAllDefaultZones)

  } else {

    return Promise.reject('Admin rights required')
  }

  /**
   * Returns a resolved Promise
   * with a boolean indicating the stream has default rooms or not
   *
   * @private
   * @param {*} result
   * @returns {Promise<boolean>}
   */
  function _onDefaultZones (result) {

    return Promise.resolve(
      Array.isArray(result) &&
      result.length > 0
    )
  }

  /**
   * Returns a resolved Promise
   * with a boolean which represents a big AND for all results of the promises
   *
   * @private
   * @param {Array} result
   * @returns {boolean}
   */
  function _onAllDefaultZones (result) {
    var _length, _i

    if (Array.isArray(result)) {

      // Iterate results
      _length = result.length
      for (_i = 0; _i < _length; _i++) {

        // Check if Stream had default rooms
        if (result[_i] !== true) return false
      }
    }

    return true
  }
}

/**
 * Get all door phones for a specific gateway
 *
 * @param {string} uuid Gateway UUID
 * @returns {DoorPhoneDevice[]}
 * @since 3.0.0
 */
BasCore.prototype.getDoorPhonesByGateway = function (uuid) {

  var result, _device, keys, length, i

  result = []

  keys = Object.keys(this._devices)
  length = keys.length
  for (i = 0; i < length; i++) {

    _device = this._devices[keys[i]]

    if (_device &&
      _device.type === Device.T_DOOR_PHONE &&
      _device.doorPhoneGateway === uuid) {

      result.push(_device)
    }
  }

  return result
}

/**
 * Get all door phones of a specific type
 *
 * @param {string} type
 * @returns {DoorPhoneDevice[]}
 * @since 3.0.0
 */
BasCore.prototype.getDoorPhonesByType = function (type) {

  var result, _device, keys, length, i

  result = []

  keys = Object.keys(this._devices)
  length = keys.length
  for (i = 0; i < length; i++) {

    _device = this._devices[keys[i]]

    if (_device &&
      _device.type === Device.T_DOOR_PHONE &&
      _device.subType === type) {

      result.push(_device)
    }
  }

  return result
}

/**
 * Determines whether there is a single room or single audio room.
 *
 * @private
 */
BasCore.prototype._determineSingleRooms = function () {

  var _rooms, _filtered, keys, length, i, _uuid

  if (this._supportsSystemProperties) {

    if (this._roomsReceived) {

      this._singleRoomId = ''

      _filtered = []

      _rooms = this._areas
      keys = Object.keys(_rooms)
      length = keys.length
      for (i = 0; i < length; i++) {

        _uuid = keys[i]

        if (Room.isLvlRoomWithFunctions(_rooms[_uuid])) {

          _filtered.push(_uuid)
        }
      }

      length = _filtered.length

      if (length === 1) {

        this._singleRoomId = _filtered[0]
      }

      if (this._musicConfigReceived) {

        this._singleAudioRoomId = ''

        _rooms = this._zones
        keys = Object.keys(_rooms)
        length = keys.length

        if (length === 1) {

          this._singleAudioRoomId = _rooms[keys[0]].id
        }
      }
    }

  } else {

    if (this._musicConfigReceived) {

      this._singleRoomId = ''
      this._singleAudioRoomId = ''

      _rooms = this._zones
      keys = Object.keys(_rooms)
      length = keys.length

      if (length === 1) {

        this._singleRoomId = this._singleAudioRoomId =
          _rooms[keys[0]].id
      }
    }
  }
}

/**
 * Determines whether there is a single source or single player.
 *
 * @private
 */
BasCore.prototype._determineSingleSources = function () {

  var _sources, keys, length, _sourceId

  this._singleSourceId = CONSTANTS.NO_SOURCE_ID
  this._singlePlayerId = CONSTANTS.NO_SOURCE_ID

  // Check for single Player

  _sources = this._players
  keys = Object.keys(_sources)
  length = keys.length

  if (length === 1) {

    this._singlePlayerId = _sources[keys[0]].id

  } else if (length > 1) {

    return
  }

  // Check for single Source

  _sources = this._externals
  keys = Object.keys(_sources)
  length = keys.length

  if (length === 0) {

    // No Externals

    _sources = this._bluetooths
    keys = Object.keys(_sources)
    length = keys.length

    if (length === 0) {

      // No Bluetooth sources

      if (this._singlePlayerId !== CONSTANTS.NO_SOURCE_ID) {

        // Single Player

        this._singleSourceId = this._singlePlayerId
      }

    } else if (length === 1) {

      // Single Bluetooth source

      if (this._singlePlayerId === CONSTANTS.NO_SOURCE_ID) {

        // No Player sources

        this._singleSourceId = _sources[keys[0]].id
      }
    }

  } else if (length === 1) {

    // Single External

    _sourceId = _sources[keys[0]].id

    if (this._singlePlayerId === CONSTANTS.NO_SOURCE_ID) {

      // No Player sources

      _sources = this._bluetooths
      keys = Object.keys(_sources)
      length = keys.length

      if (length === 0) {

        // No Bluetooth sources

        this._singleSourceId = _sourceId
      }
    }
  }
}

/**
 * @private
 * @since 3.0.0
 */
BasCore.prototype._setServerListeners = function () {

  this._clearServerListeners()

  if (this._server) {

    this._serverListeners.push(BasUtil.setEventListener(
      this._server,
      BasServer.EVT_API_VERSION,
      this._handleServerApiVersion
    ))
    this._serverListeners.push(BasUtil.setEventListener(
      this._server,
      BasServer.EVT_HAS_UPDATE,
      this._handleServerHasUpdate
    ))
    this._serverListeners.push(BasUtil.setEventListener(
      this._server,
      BasServer.EVT_CONNECTED,
      this._handleServerConnected
    ))
    this._serverListeners.push(BasUtil.setEventListener(
      this._server,
      BasServer.EVT_CORE_CONNECTED,
      this._handleServerSocketConnected
    ))
    this._serverListeners.push(BasUtil.setEventListener(
      this._server,
      BasServer.EVT_MESSAGE,
      this._handleServerSocketMessage
    ))
    this._serverListeners.push(BasUtil.setEventListener(
      this._server,
      BasServer.EVT_JWT_REVOKED,
      this._handleServerConnectionJWTRevoked
    ))
    this._serverListeners.push(BasUtil.setEventListener(
      this._server,
      BasServer.EVT_CORE_V2_CONNECTED,
      this._handleServerV2SocketConnected
    ))
    this._serverListeners.push(BasUtil.setEventListener(
      this._server,
      BasServer.EVT_V2_MESSAGE,
      this._handleServerV2SocketMessage
    ))
  }
}

/**
 * @private
 * @since 3.0.0
 */
BasCore.prototype._clearServerListeners = function () {

  BasUtil.executeArray(this._serverListeners)
  this._serverListeners = []
}

/**
 * Clears all info that came from a core connection.
 *
 * @since 3.0.0
 */
BasCore.prototype.clearAllCoreInfo = function () {

  this._clearAll()
  this._clearUser()
  this._setDirtyFlags()
}

/**
 * Sets dirty flags true
 * for objects that are not created when the 'music' object arrives
 *
 * @private
 */
BasCore.prototype._setDirtyFlags = function () {

  // Currently no objects need to set their dirty flag true.
}

/**
 * Clears all information received from connected server(s)
 *
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearAll = function () {

  this._clearVersion()
  this._clearSupportFlags()
  this._clearAVSources()
  this._clearMusicVariables()
  this._clearConfigProperties()
  this._clearDevices()
  this._clearAreas()
  this._system.reset()
  this._live.reset()
  this._profile.reset()
  this._sharedServerStorage.reset()
  this._musicLibrary.reset()
}

/**
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearVersion = function () {

  this._version = null
}

/**
 * @private
 * @since 2.0.2
 */
BasCore.prototype._clearSupportFlags = function () {

  this._supportsDefaultRooms = false
  this._supportsReplaceNow = false
  this._supportsDescribeZone = false
  this._supportsSpotifyWebAPI = false
  this._supportsBasicZoneDSP = false
  this._supportsSystemProperties = false
  this._supportsSpotifyUnlink = false
  this._supportsRepeatMode = false
  this._supportsCheckingUpdates = false
  this._supportsSunriseSet = false
  this._supportsDevicesScenes = false
  this._supportsProfile = false
  this._supportsMuteAreaOnCall = false
  this._supportsIntegratorAccess = false
  this._supportsLive = false
  this._supportsAlarmsAccess = false
  this._supportsCustomRoomImages = false
  this._supportsCustomProjectImage = false
  this._supportsCustomSceneImage = false
  this._supportsTidalOAuth = false
  this._supportsAsanoAV = false
  this._supportsQuickFavourites = false
  this._supportsAvAudioDsp = false
  this._supportsSchedulerAndDetailScene = false
}

/**
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearAreas = function () {

  BasUtil.executeFunctionAll(this._areas, 'destroy')
  this._areas = {}
  this._roomsReceived = false
}

/**
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearDevices = function () {

  this._clearConnectedDevices()
  this._clearServers()
  BasUtil.executeFunctionAll(this._devices, 'destroy')
  this._devices = {}
}

/**
 * Clears the Connected Devices array
 *
 * @private
 */
BasCore.prototype._clearConnectedDevices = function () {

  this._connectedDevices = []
}

/**
 * Clears the servers array
 *
 * @private
 */
BasCore.prototype._clearServers = function () {

  this._servers = []
}

/**
 * Clears configuration properties, derived from incoming data.
 *
 * @private
 * @since 3.0.0
 */
BasCore.prototype._clearConfigProperties = function () {

  this._singleRoomId = ''
  this._singleAudioRoomId = ''
  this._singleSourceId = CONSTANTS.NO_SOURCE_ID
  this._singlePlayerId = CONSTANTS.NO_SOURCE_ID
}

/**
 * Clears the av sources
 *
 * @private
 * @since 3.7.0
 */
BasCore.prototype._clearAVSources = function () {

  this._clearAudioSources()
  this._clearVideoSources()
  this._avSourcesReceived = false
}

/**
 * Clears the audio sources
 *
 * @private
 * @since 3.4.0
 */
BasCore.prototype._clearAudioSources = function () {

  BasUtil.executeFunctionAll(this._audioSources, 'destroy')
  this._audioSources = {}
}

/**
 * Clears the video sources
 *
 * @private
 * @since 3.7.0
 */
BasCore.prototype._clearVideoSources = function () {

  BasUtil.executeFunctionAll(this._videoSources, 'destroy')
  this._videoSources = {}
}

/**
 * Clears all objects received via the music packet.
 *
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearMusicVariables = function () {

  this._clearZones()
  this._clearGroups()
  this._clearPlayers()
  this._clearBarps()
  this._clearExternals()
  this._clearBluetooths()
  this._clearNotifications()
  this._clearAudioDevices()

  this._musicConfigReceived = false
  this._hasSpotifyConnect = false
}

/**
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearZones = function () {

  BasUtil.executeFunctionAll(this._zones, 'destroy')
  this._zones = {}
}

/**
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearGroups = function () {

  BasUtil.executeFunctionAll(this._groups, 'destroy')
  this._groups = {}
}

/**
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearBarps = function () {

  var keys, i, length, item

  keys = Object.keys(this._barps)
  length = keys.length
  for (i = 0; i < length; i++) {

    item = this._barps[keys[i]]

    if (Array.isArray(item)) {

      BasUtil.executeFunctionAll(item, 'destroy')

    } else if (BasUtil.isObject(item)) {

      item.destroy()
    }
  }

  this._barps = {}
}

/**
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearPlayers = function () {

  BasUtil.executeFunctionAll(this._players, 'destroy')
  this._players = {}
}

/**
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearExternals = function () {

  this._externals = {}
}

/**
 * @private
 * @since 2.1.0
 */
BasCore.prototype._clearBluetooths = function () {

  this._bluetooths = {}
}

/**
 * @private
 * @since 2.1.0
 */
BasCore.prototype._clearNotifications = function () {

  this._notifications = {}
}

/**
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearAudioDevices = function () {

  BasUtil.executeFunctionAll(this._audioDevices, 'destroy')
  this._audioDevices = {}
}

/**
 * Clears the user
 *
 * @private
 * @since 2.0.0
 */
BasCore.prototype._clearUser = function () {

  if (this._user) this._user.destroy()
  this._user = null
}

/**
 * Makes a clone of the current basCore with same host and options
 *
 * @returns {BasCore}
 * @since 2.0.0
 */
BasCore.prototype.clone = function () {

  return new BasCore(this._server ? this._server.clone() : null)
}

/**
 * Cleanup up BasCore instance.
 * This wil also disconnect the BasServer
 *
 * @since 1.2.0
 */
BasCore.prototype.destroy = function () {

  if (this._server) this._server.disconnectCore()

  this.clearAllCoreInfo()

  this._system.destroy()
  this._profile.destroy()
  this._live.destroy()
  this._sharedServerStorage.destroy()
  this._tunein.destroy()
  this._musicLibrary.destroy()

  this.removeAllListeners()
}

module.exports = BasCore
