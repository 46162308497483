'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('roomImagesSettingCtrl', [
    '$uiRouterGlobals',
    'STATES',
    'BAS_ROOMS',
    'CurrentBasCore',
    'BasAppDevice',
    'BasState',
    roomImagesSettingCtrl
  ])

/**
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasAppDevice} BasAppDevice
 * @param {BasState} BasState
 */
function roomImagesSettingCtrl (
  $uiRouterGlobals,
  STATES,
  BAS_ROOMS,
  CurrentBasCore,
  BasAppDevice,
  BasState
) {
  var roomImages = this

  roomImages.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BAS_ROOMS}
   */
  roomImages.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {BasRooms}
   */
  roomImages.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TBasStateObj}
   */
  roomImages.basState = BasState.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  roomImages.currentBasCoreState = CurrentBasCore.get()

  roomImages.selectRoom = selectRoom

  /**
   * Select a new room image
   *
   * @param {string} roomId
   */
  function selectRoom (roomId) {

    if (
      BasUtil.isNEString(roomId) &&
      CurrentBasCore.hasCore() &&
      roomImages.currentBasCoreState.core.core.supportsCustomRoomImages &&
      roomImages.currentBasCoreState.core.core.profile &&
      roomImages.currentBasCoreState.core.core.profile.admin &&
      !BasAppDevice.isCoreClient()
    ) {
      BasState.go(
        STATES.SETTINGS_PROJECT_ROOM_IMAGES_ROOM,
        {
          roomUuid: roomId
        }
      )
    }
  }
}
