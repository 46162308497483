'use strict'

var BasUtil = require('@basalte/bas-util')

var Device = require('./device')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Scene = require('./scene')
var Job = require('./job')

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {Object} device.scenes
 * @param {BasCore} basCore
 */
function SceneCtrlDevice (device, basCore) {

  Device.call(this, device, basCore)

  /**
   * @type {Object<string, Scene>}
   */
  this._scenes = {}

  /**
   * @type {Object<string, ?Job>}
   */
  this._jobs = {}

  this._handleUpdate = this._onUpdateResult.bind(this)
  this._handleNewJob = this._onNewJob.bind(this)
  this._handleNewScene = this._onNewScene.bind(this)

  /**
   * @type {?(string[])}
   */
  this._favourites =
    Array.isArray(device[P.FAVOURITES])
      ? device[P.FAVOURITES]
      : null

  if (BasUtil.isNEArray(device[P.SCENES])) {

    this.parseScenes(device[P.SCENES], true)
  }

  this.parseJobs(device[P.JOBS], true)
}

SceneCtrlDevice.prototype = Object.create(Device.prototype)
SceneCtrlDevice.prototype.constructor = SceneCtrlDevice

// region Events

/**
 * @event SceneCtrlDevice#EVT_SCENES_CHANGED
 */

/**
 * @event SceneCtrlDevice#EVT_SCENE_CHANGED
 * @param {Scene} scene
 */

/**
 * @event SceneCtrlDevice#EVT_SCENE_REMOVED
 * @param {Scene} scene
 */

/**
 * @event SceneCtrlDevice#EVT_JOBS_CHANGED
 */

/**
 * @event SceneCtrlDevice#EVT_JOB_CHANGED
 * @param {Job} job
 */

/**
 * @event SceneCtrlDevice#EVT_JOB_REMOVED
 * @param {Job} job
 */

// endregion

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_SCENES_CHANGED = 'evtScenesChanged'

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_SCENE_CHANGED = 'evtSceneChanged'

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_SCENE_REMOVED = 'evtSceneRemoved'

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_SCENE_ADDED = 'evtSceneAdded'

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_SCENE_IMAGES_UPDATED = 'evtSceneImagesUpdated'

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_JOBS_CHANGED = 'evtJobsChanged'

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_JOB_CHANGED = 'evtJobChanged'

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_JOB_REMOVED = 'evtJobRemoved'

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_JOB_ADDED = 'evtJobAdded'

/**
 * @constant {string}
 */
SceneCtrlDevice.EVT_FAVOURITES_CHANGED = 'evtFavouritesChanged'

/**
 * @constant {string}
 */
SceneCtrlDevice.C_ADD = P.ADD

/**
 * @constant {string}
 */
SceneCtrlDevice.ST_SCENE = P.SCENE

/**
 * @param {string} sceneCtrlUuid
 * @param {string} sceneUuid
 * @param {string} action
 * @returns {Object}
 */
SceneCtrlDevice.createSceneCrlMessage = function (
  sceneCtrlUuid,
  sceneUuid,
  action
) {
  var msg = {}

  msg[P.DEVICE] = {}
  msg[P.DEVICE][P.UUID] = sceneCtrlUuid
  msg[P.DEVICE][P.ACTION] = action
  msg[P.DEVICE][P.SCENE] = {}
  msg[P.DEVICE][P.SCENE][P.UUID] = sceneUuid

  return msg
}

/**
 * @name SceneCtrlDevice#scenes
 * @type {Object<string, Scene>}
 * @readonly
 */
Object.defineProperty(SceneCtrlDevice.prototype, 'scenes', {
  get: function () {
    return this._scenes
  }
})

/**
 * @name SceneCtrlDevice#jobs
 * @type {Object<string, Job>}
 * @readonly
 */
Object.defineProperty(SceneCtrlDevice.prototype, 'jobs', {
  get: function () {
    return this._jobs
  }
})

/**
 * @name SceneCtrlDevice#favourites
 * @type {?(string[])}
 * @readonly
 */
Object.defineProperty(SceneCtrlDevice.prototype, 'favourites', {
  get: function () {
    return this._favourites
  }
})

/**
 * Parse a Scene Controller message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} [options]
 * @returns {boolean}
 */
SceneCtrlDevice.prototype.parse = function (msg, options) {

  var _this, valid, emit, scene, job

  _this = this

  valid = Device.prototype.parse.call(this, msg, options)
  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (BasUtil.safeHasOwnProperty(msg, P.ACTION)) {

    if (msg[P.ACTION] === P.REMOVE) {

      if (BasUtil.isObject(msg[P.SCENE]) &&
        BasUtil.isNEString(msg[P.SCENE][P.UUID])) {

        scene = this._scenes[msg[P.SCENE][P.UUID]]
        this._scenes[msg[P.SCENE][P.UUID]] = null

        if (emit) this.emit(SceneCtrlDevice.EVT_SCENE_REMOVED, scene)
      }

      if (BasUtil.isObject(msg[P.JOB]) &&
        BasUtil.isNEString(msg[P.JOB][P.UUID]) &&
        this._jobs[msg[P.JOB][P.UUID]]) {

        job = this._jobs[msg[P.JOB][P.UUID]]
        this._jobs[msg[P.JOB][P.UUID]] = null

        if (emit) this.emit(SceneCtrlDevice.EVT_JOB_REMOVED, job)
      }
    }

    if (msg[P.ACTION] === P.ADD) {

      if (BasUtil.isObject(msg[P.SCENE]) &&
        BasUtil.isNEString(msg[P.SCENE][P.UUID])) {

        scene = msg[P.SCENE][P.UUID]
        this.getStatusScene(scene)
          .then(this._handleNewScene)
          .then(emitScene)
      }

      if (BasUtil.isObject(msg[P.JOB]) &&
        BasUtil.isNEString(msg[P.JOB][P.UUID])) {

        job = msg[P.JOB][P.UUID]
        this.getStatusJob(job)
          .then(this._handleNewJob)
          .then(emitJob)
      }
    }
  }

  const fullUpdate = msg[P.SCENE_UPDATE] === P.FULL

  if (BasUtil.isObject(msg[P.SCENES])) {

    if (this.parseScenes(msg[P.SCENES], fullUpdate)) {

      if (emit) this.emit(SceneCtrlDevice.EVT_SCENES_CHANGED)
    }
  }

  if (Array.isArray(msg[P.JOBS]) &&
    this.parseJobs(msg[P.JOBS], fullUpdate)) {

    if (emit) this.emit(SceneCtrlDevice.EVT_JOBS_CHANGED)
  }

  if (Array.isArray(msg[P.FAVOURITES])) {

    this._favourites = msg[P.FAVOURITES]
    if (emit) this.emit(SceneCtrlDevice.EVT_FAVOURITES_CHANGED)
  }

  return valid

  /**
   * @param {?Scene} result
   */
  function emitScene (result) {

    // Scene is null indicates that the scene came from the user
    // calling addScene and that the Promise result, of that call,
    // will give the necessary update to the caller.
    if (emit && BasUtil.isObject(result)) {

      _this.emit(SceneCtrlDevice.EVT_SCENE_ADDED, result)
    }
  }

  /**
   * @param {?Job} result
   */
  function emitJob (result) {

    // Job is null indicates that the job came from the user
    // calling addJob and that the Promise result, of that call,
    // will give the necessary update to the caller.
    if (emit && BasUtil.isObject(result)) {

      _this.emit(SceneCtrlDevice.EVT_JOB_ADDED, result)
    }
  }
}

/**
 * Parse all scenes
 *
 * @param {Array} scenes
 * @param {boolean} fullUpdate
 * @returns {boolean} true if a new scene is found or a scene was removed
 */
SceneCtrlDevice.prototype.parseScenes = function (scenes, fullUpdate) {

  var i, length, key, sceneObj
  var scenesChanged = false

  if (Array.isArray(scenes)) {

    length = scenes.length
    for (i = 0; i < length; i++) {

      sceneObj = scenes[i]

      if (BasUtil.isObject(sceneObj)) {

        key = sceneObj[P.UUID]

        if (BasUtil.isNEString(key)) {

          if (BasUtil.isObject(this._scenes[key])) {

            this._scenes[key].parse(sceneObj)

          } else {

            this._scenes[key] = new Scene(sceneObj, this)
            scenesChanged = true
          }
        }
      }
    }

    if (fullUpdate) {
      for (const existingScene of Object.keys(this._scenes)) {
        // If existing scene no longer exists in new scenes, remove it
        if (!scenes.filter(el => el[P.UUID] === existingScene).length) {
          delete this._scenes[existingScene]
          scenesChanged = true
        }
      }
    }
  }

  return scenesChanged
}

/**
 * Returns whether a new Job(s) was added or not.
 *
 * @param {TJob[]} jobs
 * @param {boolean} fullUpdate
 * @returns {boolean}
 */
SceneCtrlDevice.prototype.parseJobs = function (jobs, fullUpdate) {

  var i, length, jobObj, key

  let jobsChanged = false

  if (Array.isArray(jobs)) {

    length = jobs.length
    for (i = 0; i < length; i++) {

      jobObj = jobs[i]

      if (BasUtil.isObject(jobObj)) {

        key = jobObj[P.UUID]

        if (BasUtil.isNEString(key)) {

          if (this._jobs[key]) {

            this._jobs[key].parse(jobObj)

          } else {

            this._jobs[key] = new Job(jobObj, this)
            jobsChanged = true
          }

          // Update order
          this._jobs[key].setOrder(i)
        }
      }
    }

    if (fullUpdate) {
      for (const existingJob of Object.keys(this._jobs)) {
        // If existing job no longer exists in new jobs, remove it
        if (!jobs.filter(el => el[P.UUID] === existingJob).length) {
          delete this._jobs[existingJob]
          jobsChanged = true
        }
      }
    }
  }

  return jobsChanged
}

/**
 * Emits the SceneCtrlDevice.EVT_SCENE_CHANGED event with given uuid
 *
 * @param {Scene} scene
 */
SceneCtrlDevice.prototype.emitScene = function (scene) {

  this.emit(SceneCtrlDevice.EVT_SCENE_CHANGED, scene)
}

/**
 * Emits the SceneCtrlDevice.EVT_IMAGES_UPDATED event with given uuid
 *
 * @param {Scene} scene
 */
SceneCtrlDevice.prototype.emitSceneImagesUpdated = function (scene) {

  this.emit(SceneCtrlDevice.EVT_SCENE_IMAGES_UPDATED, scene)
}

/**
 * Emits the SceneCtrlDevice.EVT_JOB_CHANGED event with given job
 *
 * @param {Job} job
 */
SceneCtrlDevice.prototype.emitJobChanged = function (job) {

  this.emit(SceneCtrlDevice.EVT_JOB_CHANGED, job)
}

/**
 * @param {string} uuid
 * @returns {?Scene}
 */
SceneCtrlDevice.prototype.getScene = function (uuid) {

  if (BasUtil.isNEString(uuid) &&
    this._scenes[uuid] instanceof Scene) {

    return this._scenes[uuid]
  }

  return null
}

/**
 * @param {string} uuid
 * @returns {?Job}
 */
SceneCtrlDevice.prototype.getJob = function (uuid) {

  if (BasUtil.isNEString(uuid) && this._jobs[uuid]) return this._jobs[uuid]

  return null
}

/**
 * Activate a scene
 *
 * @param {string} uuid
 */
SceneCtrlDevice.prototype.activateScene = function (uuid) {

  var msg = SceneCtrlDevice.getActivateSceneMessage(this._uuid, uuid)

  if (msg) this._basCore.send(msg)
}

/**
 * @param {string} uuid
 */
SceneCtrlDevice.prototype.learnScene = function (uuid) {

  var msg

  if (BasUtil.isNEString(uuid)) {

    msg = this._getBasCoreMessage()

    msg[P.DEVICE][P.ACTION] = P.LEARN
    msg[P.DEVICE][P.SCENE] = {}
    msg[P.DEVICE][P.SCENE][P.UUID] = uuid

    this._basCore.send(msg)
  }
}

/**
 * Add a new scene
 *
 * @param {string} name
 * @returns {Promise}
 */
SceneCtrlDevice.prototype.addScene = function (name) {

  var msg

  if (BasUtil.isNEString(name)) {

    msg = this._getBasCoreMessage()

    msg[P.DEVICE][P.ACTION] = P.ADD
    msg[P.DEVICE][P.SCENE] = {}
    msg[P.DEVICE][P.SCENE][P.NAME] = name

    return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleNewScene)
  }

  return Promise.reject(CONSTANTS.ERR_NAME)
}

/**
 * @param {string} uuid
 * @returns {Promise}
 */
SceneCtrlDevice.prototype.getStatusScene = function (uuid) {

  var msg

  if (BasUtil.isNEString(uuid)) {

    msg = this._getBasCoreMessage()

    msg[P.DEVICE][P.ACTION] = P.STATUS
    msg[P.DEVICE][P.SCENE] = {}
    msg[P.DEVICE][P.SCENE][P.UUID] = uuid

    return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
  }

  return Promise.reject(CONSTANTS.ERR_UUID)
}

/**
 * @private
 * @param {Object} result
 * @returns {(?Scene|Promise)}
 */
SceneCtrlDevice.prototype._onNewScene = function (result) {

  var key, scene, sceneObj

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.DEVICE]) &&
    BasUtil.isNEArray(result[P.DEVICE][P.SCENES]) &&
    BasUtil.isObject(result[P.DEVICE][P.SCENES][0])) {

    sceneObj = result[P.DEVICE][P.SCENES][0]

    if (BasUtil.isNEString(sceneObj[P.UUID])) {

      key = sceneObj[P.UUID]

      if (!BasUtil.isObject(this._scenes[key])) {

        scene = new Scene(sceneObj, this)
        this._scenes[key] = scene

        return scene
      }

      return null
    }

    return Promise.reject(CONSTANTS.ERR_UUID)
  }

  return Promise.reject(CONSTANTS.ERR_RESULT)
}

/**
 * @param {Scene} scene
 * @returns {Promise}
 */
SceneCtrlDevice.prototype.updateScene = function (scene) {

  var msg

  if (this._basCore) {

    if (BasUtil.isObject(scene) && scene.getBasCoreMessage) {

      msg = this._getBasCoreMessage()

      msg[P.DEVICE][P.ACTION] = P.UPDATE
      msg[P.DEVICE][P.SCENE] = scene.getBasCoreMessage()

      return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
        .then(this._handleUpdate)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @param {string[]} favourites
 * @returns {Promise}
 */
SceneCtrlDevice.prototype.updateFavourites = function (favourites) {

  var msg

  if (this._basCore) {

    if (Array.isArray(favourites)) {

      msg = this._getBasCoreMessage()

      msg[P.DEVICE][P.ACTION] = P.UPDATE
      msg[P.DEVICE][P.FAVOURITES] = favourites

      return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
        .then(this._handleUpdate)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * Send new image to server of clear action if body is an empty string
 *
 * @param {string} sceneUuid
 * @param {string} body BASE64 encoded image
 * @returns {Promise}
 */
SceneCtrlDevice.prototype.updateImage = function (sceneUuid, body) {

  var msg

  if (this._basCore) {

    if (BasUtil.isNEString(sceneUuid) && BasUtil.isString(body)) {

      msg = this._getBasCoreMessage()
      msg[P.DEVICE][P.SCENE] = {}
      msg[P.DEVICE][P.SCENE][P.UUID] = sceneUuid

      if (body) {

        msg[P.DEVICE][P.ACTION] = P.SET_IMAGE
        msg[P.DEVICE][P.SCENE][P.BODY] = body

      } else {

        msg[P.DEVICE][P.ACTION] = P.CLEAR_IMAGE
      }

      return this._basCore.requestRetry(
        msg,
        CONSTANTS.RETRY_OPTS_ONE_SHOT_LONG
      ).then(_processImageActionResponse)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

function _processImageActionResponse (response) {

  var result, reason

  reason = CONSTANTS.ERR_RESULT

  if (response &&
    response.device &&
    BasUtil.isNEString(response.device.result)) {

    result = response.device.result

    if (result === P.OK) {

      return result

    } else if (BasUtil.isNEString(response.device.reason)) {

      reason = response.device.reason
    }
  }

  return Promise.reject(reason)
}

/**
 * @param {string} uuid
 */
SceneCtrlDevice.prototype.removeScene = function (uuid) {

  var msg

  if (BasUtil.isNEString(uuid)) {

    msg = this._getBasCoreMessage()

    msg[P.DEVICE][P.ACTION] = P.REMOVE
    msg[P.DEVICE][P.SCENE] = {}
    msg[P.DEVICE][P.SCENE][P.UUID] = uuid

    this._basCore.send(msg)
  }
}

/**
 * Creates a new Job
 *
 * @returns {Promise}
 */
SceneCtrlDevice.prototype.addNewJob = function () {

  var msg

  if (this._basCore) {

    msg = this._getBasCoreMessage()

    msg[P.DEVICE][P.ACTION] = P.ADD
    msg[P.DEVICE][P.JOB] = {}

    return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(this._handleNewJob)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @param {string} uuid
 * @returns {Promise}
 */
SceneCtrlDevice.prototype.getStatusJob = function (uuid) {

  var msg

  if (BasUtil.isNEString(uuid)) {

    msg = this._getBasCoreMessage()

    msg[P.DEVICE][P.ACTION] = P.STATUS
    msg[P.DEVICE][P.JOB] = {}
    msg[P.DEVICE][P.JOB][P.UUID] = uuid

    return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
  }

  return Promise.reject(CONSTANTS.ERR_UUID)
}

/**
 * @private
 * @param {Object} result
 * @returns {(?Job|Promise)}
 */
SceneCtrlDevice.prototype._onNewJob = function (result) {

  var key, job, jobObj

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.DEVICE]) &&
    BasUtil.isNEArray(result[P.DEVICE][P.JOBS]) &&
    BasUtil.isObject(result[P.DEVICE][P.JOBS][0])) {

    jobObj = result[P.DEVICE][P.JOBS][0]

    if (BasUtil.isNEString(jobObj[P.UUID])) {

      key = jobObj[P.UUID]

      if (!BasUtil.isObject(this._jobs[key])) {

        job = new Job(jobObj, this)
        this._jobs[key] = job

        return job
      }

      return null
    }

    return Promise.reject(CONSTANTS.ERR_UUID)
  }

  return Promise.reject(CONSTANTS.ERR_RESULT)
}

/**
 * Send updated Job to the server
 *
 * @param {Job} job
 * @returns {Promise}
 */
SceneCtrlDevice.prototype.updateJob = function (job) {

  var msg

  if (this._basCore) {

    if (BasUtil.isObject(job) && job.getBasCoreMessage) {

      msg = this._getBasCoreMessage()

      msg[P.DEVICE][P.ACTION] = P.UPDATE
      msg[P.DEVICE][P.JOB] = job.getBasCoreMessage()

      return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
        .then(this._handleUpdate)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

/**
 * @param {string} jobUuid
 */
SceneCtrlDevice.prototype.removeJob = function (jobUuid) {

  var msg

  if (BasUtil.isNEString(jobUuid)) {

    msg = this._getBasCoreMessage()

    msg[P.DEVICE][P.ACTION] = P.REMOVE
    msg[P.DEVICE][P.JOB] = {}
    msg[P.DEVICE][P.JOB][P.UUID] = jobUuid

    this._basCore.send(msg)
  }
}

/**
 * @param {Object} result
 * @returns {Promise} returns Promise.resolved with the device if result is ok
 *                    returns Promise.rejected with a matching error message
 */
SceneCtrlDevice.prototype._onUpdateResult = function (result) {

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.DEVICE]) &&
    result[P.DEVICE][P.RESULT] === P.OK) {

    return this
  }

  return Promise.reject(CONSTANTS.ERR_UNEXPECTED_ERROR)
}

/**
 * Creates a template basCore message for this device
 *
 * @protected
 * @returns {Object}
 */
SceneCtrlDevice.prototype._getBasCoreMessage = function () {

  return SceneCtrlDevice.getBasCoreMessage(this._uuid)
}

/**
 * Creates a template basCore message for this device
 *
 * @param sceneControllerUuid
 * @protected
 * @returns {Object}
 */
SceneCtrlDevice.getBasCoreMessage = function (sceneControllerUuid) {

  var msg = {}
  msg[P.DEVICE] = {}
  msg[P.DEVICE][P.UUID] = sceneControllerUuid

  return msg
}

/**
 * Activate a scene
 *
 * @param {string} sceneControllerUuid
 * @param {string} sceneUuid
 * @returns {?Object}
 */
SceneCtrlDevice.getActivateSceneMessage = function (
  sceneControllerUuid,
  sceneUuid
) {

  var msg

  if (
    BasUtil.isNEString(sceneControllerUuid) &&
    BasUtil.isNEString(sceneUuid)
  ) {

    msg = SceneCtrlDevice.getBasCoreMessage(sceneControllerUuid)

    msg[P.DEVICE][P.ACTION] = P.ACTIVATE
    msg[P.DEVICE][P.SCENE] = {}
    msg[P.DEVICE][P.SCENE][P.UUID] = sceneUuid

    return msg
  }

  return null
}

module.exports = SceneCtrlDevice
