'use strict'

angular
  .module('basalteApp')
  .service('BasUiQueue', [
    '$rootScope',
    'BAS_PLAYER_DRAWER',
    BasUiQueue
  ])

/**
 * @typedef {Object} TBasUiQueue
 * @property {Object<string, boolean>} css
 */

/**
 * @param $rootScope
 * @param {BAS_PLAYER_DRAWER} BAS_PLAYER_DRAWER
 * @constructor
 */
function BasUiQueue (
  $rootScope,
  BAS_PLAYER_DRAWER
) {

  var CSS_SHOW_QUEUE = 'bpy-show-queue'

  /**
   * @type {TBasUiQueue}
   */
  var state = {}
  state.css = {}
  state.css[CSS_SHOW_QUEUE] = false

  this.get = get
  this.toggle = toggle

  /**
   * @returns {TBasUiQueue}
   */
  function get () {
    return state
  }

  /**
   * @param {boolean} [force]
   */
  function toggle (force) {

    var old = state.css[CSS_SHOW_QUEUE]

    state.css[CSS_SHOW_QUEUE] = typeof force === 'boolean'
      ? force
      : !state.css[CSS_SHOW_QUEUE]

    if (old !== state.css[CSS_SHOW_QUEUE]) {

      $rootScope.$emit(
        BAS_PLAYER_DRAWER.EVT_VISIBILITY_CHANGED,
        state.css[CSS_SHOW_QUEUE]
      )
    }
  }
}
