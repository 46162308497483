'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasMessage', [
    'BasUtilities',
    basMessage
  ])

/**
 * @param {BasUtilities} BasUtilities
 * @returns {BasMessage}
 */
function basMessage (BasUtilities) {

  var MAX_LENGTH = 50
  var WILDCARD = '*'

  /**
   * @constructor
   * @param {Message} message
   */
  function BasMessage (message) {

    /**
     * @type {Object}
     */
    this.messages = BasUtil.isObject(message.body) ? message.body : {}

    /**
     * @type {Object}
     */
    this.titles = BasUtil.isObject(message.title) ? message.title : {}

    /**
     * @type {string}
     */
    this.uuid = BasUtil.isNEString(message.uuid) ? message.uuid : ''

    /**
     * @type {string}
     */
    this.message = ''

    /**
     * @type {string}
     */
    this.title = ''

    /**
     * @type {string}
     */
    this.uiShortMessage = ''

    this.syncUi()
  }

  BasMessage.prototype.syncUi = function () {

    var transKey = BasUtilities.getTranslateKey()
    this.resetUi()

    if (BasUtil.isNEString(transKey)) {

      this.message = BasUtil.isNEString(this.messages[transKey])
        ? this.messages[transKey]
        : BasUtil.isNEString(this.messages[WILDCARD])
          ? this.messages[WILDCARD]
          : ''

      this.title = BasUtil.isNEString(this.titles[transKey])
        ? this.titles[transKey]
        : BasUtil.isNEString(this.titles[WILDCARD])
          ? this.titles[WILDCARD]
          : ''

      this.uiShortMessage = this.message.length > MAX_LENGTH
        ? this.message.substring(0, MAX_LENGTH - 3) + '...'
        : this.message
    }
  }

  BasMessage.prototype.resetUi = function () {

    this.message = ''
    this.title = ''
    this.uiShortMessage = ''
  }

  BasMessage.prototype.updateTranslations = function () {

    this.syncUi()
  }

  return BasMessage
}
