'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSourceState', [
    '$rootScope',
    '$timeout',
    'BAS_API',
    'ICONS',
    'BAS_SOURCE',
    'BasImageTrans',
    'BasSpotifyUri',
    'BasResource',
    'BasUtilities',
    basSourceStateFactory
  ])

/**
 * @param $rootScope
 * @param $timeout
 * @param BAS_API
 * @param {ICONS} ICONS
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param BasImageTrans
 * @param BasSpotifyUri
 * @param {BasResource} BasResource
 * @param {BasUtilities} BasUtilities
 * @returns BasSourceState
 */
function basSourceStateFactory (
  $rootScope,
  $timeout,
  BAS_API,
  ICONS,
  BAS_SOURCE,
  BasImageTrans,
  BasSpotifyUri,
  BasResource,
  BasUtilities
) {
  var KEY_TUNEIN = 'tunein_gid'
  var KEY_CONTENT = 'content_src'
  var KEY_DEEZER_ID = 'deezer_id'
  var KEY_TIDAL_ID = 'tidal_id'
  var KEY_SKIP_PREV = 'skip_prev'
  var KEY_SKIP_NEXT = 'skip_next'
  var KEY_PEEK_NEXT = 'peek_next'
  var KEY_SEEK = 'seek'
  var KEY_PAUSE = 'pause'
  var KEY_RESUME = 'resume'
  var KEY_SHUFFLE = 'shuffle'
  var KEY_REPEAT_TRACK = 'repeat_track'
  var KEY_REPEAT_CONTEXT = 'repeat_context'

  var VAL_DEEZER = 'deezer'
  var VAL_TIDAL = 'tidal'

  var CSS_PAUSED = 'bas-paused'
  var CSS_PLAYING = 'bas-playing'
  var CSS_PLAY_PAUSE_SHOW = 'bas-play-pause-show'
  var CSS_CAN_PLAY_PAUSE = 'bas-can-play-pause'

  var CSS_HAS_QUEUE = 'bpy-has-queue'
  var CSS_HAS_NEXT_SONG = 'bpy-has-next-song'
  var CSS_HAS_SONG = 'bpy-has-song'
  var CSS_HAS_CONTEXT = 'bpy-has-context'
  var CSS_HAS_NSTB = 'bpy-has-next-song-title-big'
  var CSS_HAS_NSTS = 'bpy-has-next-song-title-small'
  var CSS_SAVE_PLAYLIST = 'bpy-save-playlist-show'
  var CSS_CAN_PREVIOUS = 'bpy-can-previous'
  var CSS_CAN_NEXT = 'bpy-can-next'
  var CSS_CAN_SCRUB = 'bpy-can-scrub'
  var CSS_CAN_REPEAT = 'bpy-can-repeat'
  var CSS_CAN_SHUFFLE = 'bpy-can-shuffle'
  var CSS_CAN_REPLAY_15 = 'bpy-can-replay-15'
  var CSS_CAN_FORWARD_15 = 'bpy-can-forward-15'
  var CSS_LOGO_DEEZER = 'bpy-logo-deezer'
  var CSS_LOGO_TIDAL = 'bpy-logo-tidal'
  var CSS_LOGO_AAP = 'bpy-logo-aap'
  var CSS_LOGO_SPOTIFY = 'bpy-logo-spotify'
  var CSS_POS_DURATION = 'bpy-positive-duration'
  var CSS_IS_REPEAT_CONTEXT = 'bpy-is-repeat-context'
  var CSS_IS_REPEAT_TRACK = 'bpy-is-repeat-track'
  var CSS_IS_RANDOM = 'bpy-is-random'
  var CSS_IS_SPOTIFY = 'bpy-is-spotify'
  var CSS_IS_BUFFERING = 'bpy-is-buffering'

  var CSS_CAN_FAST_FORWARD = 'bpy-can-fast-forward'
  var CSS_CAN_REWIND = 'bpy-can-rewind'
  var CSS_CAN_BACK = 'bpy-can-back'
  var CSS_CAN_MENU = 'bpy-can-menu'
  var CSS_CAN_LEFT = 'bpy-can-left'
  var CSS_CAN_RIGHT = 'bpy-can-right'
  var CSS_CAN_UP = 'bpy-can-up'
  var CSS_CAN_DOWN = 'bpy-can-down'
  var CSS_CAN_ENTER = 'bpy-can-enter'
  var CSS_CAN_CHANNEL_UP = 'bpy-can-channel-up'
  var CSS_CAN_CHANNEL_DOWN = 'bpy-can-channel-down'
  var CSS_HAS_NUMBER_GRID = 'bpy-has-number-grid'
  var CSS_HAS_CUSTOM_GRID = 'bpy-has-custom-grid'

  /**
   * @constructor
   * @param {BasSource} basSource
   */
  function BasSourceState (basSource) {

    this.phoneLogoTrans = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE
    })

    this.tabletLogoTrans = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE
    })

    /**
     * Id of the Local library song which is currently playing
     *
     * @instance
     * @type {(string|number)}
     */
    this.currentId = -1

    /**
     * @instance
     * @type {boolean}
     */
    this.hasContext = false

    /**
     * @instance
     * @type {boolean}
     */
    this.paused = false

    /**
     * @instance
     * @type {boolean}
     */
    this.uiPaused = false

    /**
     * @instance
     * @type {number}
     */
    this.repeatMode = BAS_API.CONSTANTS.REPEAT_OFF

    /**
     * @instance
     * @type {boolean}
     */
    this.random = false

    /**
     * @instance
     * @type {number}
     */
    this.duration = 0

    /**
     * @instance
     * @type {number}
     */
    this.position = 0

    /**
     * @instance
     * @type {(string|number)}
     */
    this.positionPercentage = 0

    /**
     * @instance
     * @type {boolean}
     */
    this.hasSong = false

    /**
     * @instance
     * @type {boolean}
     */
    this.hasNextSong = false

    /**
     * @instance
     * @type {boolean}
     */
    this.hasQueue = false

    /**
     * @instance
     * @type {boolean}
     */
    this.canPrevious = false

    /**
     * @instance
     * @type {boolean}
     */
    this.canNext = false

    /**
     * @instance
     * @type {boolean}
     */
    this.canRepeat = false

    /**
     * @instance
     * @type {boolean}
     */
    this.canShuffle = false

    /**
     * @instance
     * @type {boolean}
     */
    this.canPlayPause = false

    /**
     * @instance
     * @type {boolean}
     */
    this.canScrub = false

    /**
     * @instance
     * @type {boolean}
     */
    this.canReplay15 = false

    /**
     * @instance
     * @type {boolean}
     */
    this.canForward15 = false

    /**
     * @instance
     * @type {string}
     */
    this.currentSongId = ''

    /**
     * Currently only used by Spotify
     *
     * @instance
     * @type {string}
     */
    this.currentSongUri = ''

    /**
     * @instance
     * @type {string}
     */
    this.currentSongTitleSmall = ''

    /**
     * @instance
     * @type {string}
     */
    this.currentSongTitleBig = ''

    /**
     * @instance
     * @type {string}
     */
    this.currentSongContext = ''

    /**
     * @instance
     * @type {string}
     */
    this.currentSongContextUri = ''

    /**
     * @instance
     * @type {string}
     */
    this.nextSongTitleSmall = ''

    /**
     * @instance
     * @type {string}
     */
    this.nextSongTitleBig = ''

    /**
     * @instance
     * @type {boolean}
     */
    this.hasMessage = false

    /**
     * @instance
     * @type {Object}
     */
    this.css = {}
    this._resetCss()

    // Events
    this.handleCapabilities = this._onCapabilities.bind(this)
    this.handleAttributes = this._onAttributes.bind(this)
    this.handleQueueChanged = this._onQueueChanged.bind(this)
    this.handleCurrentSong = this._onCurrentSong.bind(this)
    this.handleNextSong = this._onNextSong.bind(this)
    this.handlePaused = this._onPaused.bind(this)
    this.handlePlayback = this._onPlayback.bind(this)
    this.handleRepeat = this._onRepeat.bind(this)
    this.handleRandom = this._onRandom.bind(this)
    this.handleDuration = this._onDuration.bind(this)
    this.handlePosition = this._onPosition.bind(this)
    this.handleBufferingDebounceTimeout =
      this._onBufferingDebounceTimeout.bind(this)

    this.setPlayPauseShow = this._setPlayPauseShow.bind(this)
    this.setPauseTimeout = this._setPauseTimeout.bind(this)

    this.onPauseShowTimeout = null
    this.onPauseTimeout = null
    this.onBufferingDebounceTimeoutId = null

    /**
     * @private
     * @type {BasSource}
     */
    this._basSource = basSource
  }

  BasSourceState.prototype._hasSource = function () {

    return this._basSource && this._basSource.source
  }

  /**
   * @returns {boolean}
   */
  BasSourceState.prototype.isStream = function () {

    return !!(
      this._basSource &&
      (
        this._basSource.subType === BAS_SOURCE.ST_TUNEIN ||
        (
          this._basSource.type !== BAS_SOURCE.T_SONOS &&
          this._basSource.type !== BAS_SOURCE.T_ASANO &&
          this._basSource.type !== BAS_SOURCE.T_VIDEO &&
          this._basSource.type !== BAS_SOURCE.T_PLAYER &&
          this._basSource.type !== BAS_SOURCE.T_BARP
        )
      )
    )
  }

  /**
   * @param {TBasEmitterOptions} [options]
   */
  BasSourceState.prototype.sync = function (options) {

    var _emit, source

    _emit = true

    if (options) {

      if (BasUtil.isBool(options.emit)) _emit = options.emit
    }

    this._setDefaultPlayerState()

    if (this._basSource) {

      if (this._basSource.source) source = this._basSource.source

      switch (this._basSource.type) {
        case BAS_SOURCE.T_SONOS:
        case BAS_SOURCE.T_ASANO:

          this.position = source.positionMs

          this.setPaused(source.paused)
          this.setRepeatMode(source.repeatMode)
          this.setRandom(source.random)
          this._setHasSong(!!source.nowPlaying.current)
          this._setHasNextSong(!!source.nowPlaying.next)

          this._syncSongInfo()

          this._syncLogo()
          this._syncHasQueue()

          break
        case BAS_SOURCE.T_VIDEO:

          this.setPaused(source.paused)
          this.setRepeatMode(source.repeatMode)
          this.setRandom(source.random)

          this._onCapabilities()
          this._onAttributes()

          break
        case BAS_SOURCE.T_PLAYER:

          this.position = source.position

          // Player properties
          this.setPaused(source.paused)
          this.setRepeatMode(source.repeatMode)
          this.setRandom(source.random)

          // Set player type and song titles
          this._syncSongInfo()

          this._syncLogo()
          this._syncHasQueue()

          break

        case BAS_SOURCE.T_BARP:

          this._setCanPrevious(true)
          this._setCanNext(true)
          this._setCanPlayPause(true)
          this.setPaused(source.paused)
          this._setHasSong(true)

          // Title
          this._syncSongInfo()

          this._syncLogo()

          break

        case BAS_SOURCE.T_EXTERNAL:

          this.setPaused(false)
          this._setHasSong(true)

          // Set title
          this._setSongTitleBig(
            BasUtilities.translate('ext_src')
          )

          if (source.name) {

            this._setSongTitleSmall(source.name)
          }

          break

        case BAS_SOURCE.T_NOTIFICATION:

          this.setPaused(false)
          this._setHasSong(true)

          // Set title
          this._setSongTitleBig(
            BasUtilities.translate('notification')
          )

          if (source.name) {

            this._setSongTitleSmall(source.name)
          }

          break

        case BAS_SOURCE.T_AUDIO_ALERT:

          this.setPaused(false)
          this._setHasSong(true)

          this._setSongTitle(
            this._basSource.source ? this._basSource.source.alert : '',
            BasUtilities.translate('alert')
          )

          break

        case BAS_SOURCE.T_BLUETOOTH:

          this._setCanPrevious(true)
          this._setCanNext(true)
          this._setCanPlayPause(true)
          this.setPaused(source.paused)

          // Title
          this._syncSongInfo()

          break

        case BAS_SOURCE.T_MIXED:

          this.setPaused(false)
          this._setHasSong(true)

          // Set title
          this._setSongTitleBig(
            BasUtilities.translate('mixed_sources')
          )

          break

        case BAS_SOURCE.T_AV_INPUT_NONE:

          this.setPaused(false)
          this._setHasSong(true)

          // Set title
          this._setSongTitleBig(
            BasUtilities.translate('no_av')
          )

          break

        case BAS_SOURCE.T_AV_INPUT_UNKNOWN:

          this.setPaused(false)
          this._setHasSong(true)

          // Set title
          this._setSongTitleBig(
            BasUtilities.translate('unknown_av')
          )

          break

        case BAS_SOURCE.T_UNKNOWN_ID:

          this.setPaused(false)
          this._setHasSong(true)

          // Set title
          this._setSongTitleBig(
            BasUtilities.translate('unknown_src')
          )

          break
      }
    }

    // Player queue properties
    this._onQueueChanged()

    if (_emit) {

      $rootScope.$emit(BAS_SOURCE.EVT_STATE_CHANGE, this._basSource.getId())
    }
  }

  BasSourceState.prototype.syncSubType = function () {

    this._syncHasQueue()
    this._syncLogo()
  }

  /**
   * @private
   */
  BasSourceState.prototype._syncSongInfo = function () {

    if (this._basSource) {

      switch (this._basSource.type) {
        case BAS_SOURCE.T_SONOS:
        case BAS_SOURCE.T_ASANO:
          this._syncAudioType()
          break
        case BAS_SOURCE.T_VIDEO:
          this._syncVideoType()
          break
        case BAS_SOURCE.T_PLAYER:
          this._syncPlayerType()
          break
        case BAS_SOURCE.T_BARP:
          this._syncBarpType()
          break
        case BAS_SOURCE.T_BLUETOOTH:
          this._syncBluetoothType()
          break
      }
    }
  }

  /**
   * @private
   */
  BasSourceState.prototype._syncAudioType = function () {

    var source, currentSong, nextSong, context

    if (this._hasSource()) {

      source = this._basSource.source
      currentSong = this._getCurrentSong()
      nextSong = this._getNextSong()
      context = this._getContext()
    }

    this._setDefaultSongTitleCurrent()
    this._setDefaultSongTitleNext()
    this._setHasSong(false)
    this.currentId = -1

    if (source) {

      this._onCapabilities()

      this._syncIsBuffering()
    }

    if (currentSong) {

      this._setHasSong(true)
      this._setDuration(currentSong.lengthMs)

      // Song titles
      this._syncCurrentSongTitles()

      this.currentId = currentSong.id
      this.currentSongId = currentSong.uri
        ? currentSong.uri.split(':').pop()
        : ''
      this.currentSongUri = currentSong.uri

      // Set position percentage
      this._syncPositionPercentage()

      this._setIsSpotify(this._basSource.isPlayingSpotify)
    }

    if (nextSong) {

      this._setHasNextSong(true)

      // Song titles
      this._syncNextSongTitles()
    }

    if (context) {

      this.currentSongContextUri = context.uri
      this.currentSongContext = context.name
    }

    this._syncHasContext()
  }

  /**
   * @private
   */
  BasSourceState.prototype._syncVideoType = function () {

    var source

    if (this._hasSource()) {

      source = this._basSource.source
    }

    if (source) {

      this._onCapabilities()
    }
  }

  /**
   * @private
   */
  BasSourceState.prototype._syncPlayerType = function () {

    var song

    // Get current song reference
    song = this._getCurrentSong()

    // Clear Song titles
    this._setDefaultSongTitleCurrent()
    this._setDefaultSongTitleNext()
    this._setHasSong(false)
    this.currentId = -1

    if (BasUtil.isObject(song)) {

      this._setHasSong(true)
      this.currentId = song.id

      if (song[KEY_TUNEIN]) {

        // TuneIn station
        this._setCanPlayPause(false)

        this._setCanScrub(false)
        this._setDuration(0)

      } else if (song[KEY_CONTENT] &&
        song[KEY_CONTENT] === VAL_DEEZER) {

        // Deezer
        this._setCanPlayPause(true)

        this._setCanScrub(true)
        this._setDuration(song.length || 0)

        // Set next song titles
        this._syncNextSongTitles()

      } else if (song[KEY_CONTENT] &&
        song[KEY_CONTENT] === VAL_TIDAL) {

        // Tidal
        this._setCanPlayPause(true)
        this._setCanScrub(true)
        this._setDuration(song.length || 0)

        // Set next song titles
        this._syncNextSongTitles()

      } else {

        // Local
        this._setCanPlayPause(true)

        this._setCanScrub(true)
        this._setDuration(song.length || 0)

        // Set next song titles
        this._syncNextSongTitles()
      }

      // Set position percentage
      this._syncPositionPercentage()

      // Set title
      this._syncCurrentSongTitles()
    }

    this._syncHasContext()
  }

  /**
   * @private
   */
  BasSourceState.prototype._syncBarpType = function () {

    var source, song

    this._setHasSong(false)

    if (this._hasSource()) {

      // Set source
      source = this._basSource.source

      // Clear Song titles
      this._setDefaultSongTitleCurrent()
      this._setDefaultSongTitleNext()

      this._setDefaultPosition()

      // Get duration
      this._setDuration(source.duration)

      // Get position
      this.position = source.position

      // Set position percentage
      this._syncPositionPercentage()

      this.currentId = -1
      this._setHasSong(true)

      song = this._getCurrentSong()

      // Check of current song object
      if (BasUtil.isObject(song)) {

        // Song titles
        this._syncCurrentSongTitles()

      } else {

        // Legacy Barp names
        this._setSongTitle(source.title, source.artist)
      }

      song = this._getNextSong()

      // Check for next song object
      if (BasUtil.isObject(song)) {

        this._setHasNextSong(true)

        // Get names from Barp
        this._syncNextSongTitles()

      } else {

        this._setHasNextSong(false)
      }

      this._syncBarpSubType()
    }
  }

  /**
   * @private
   */
  BasSourceState.prototype._syncBluetoothType = function () {

    var currentSong

    currentSong = this._getCurrentSong()

    // Check for title and artist
    if (currentSong) {

      // Song titles
      this._syncCurrentSongTitles()
    }
  }

  BasSourceState.prototype._syncBarpSubType = function () {

    if (this._basSource) {

      switch (this._basSource.subType) {
        case BAS_SOURCE.ST_AAP:

          this._setCanScrub(false)

          break
        case BAS_SOURCE.ST_SPOTIFY:

          this._setIsSpotify(true)
          this._setCanScrub(true)

          // Check random and repeatMode state
          if (this._hasSource()) {

            this.setRepeatMode(this._basSource.source.repeatMode)
            this.setRandom(this._basSource.source.random)
          }

          this._syncSpotifyInfo()
          this._syncSpotifyRestrictions()

          break
        default:
          break
      }
    }

    this._syncHasContext()
  }

  BasSourceState.prototype._syncSpotifyInfo = function () {

    var currentSong, basSpotifyUri

    if (this._hasSource() &&
      this._basSource.subType === BAS_SOURCE.ST_SPOTIFY) {

      currentSong = this._getCurrentSong()

      if (BasUtil.isObject(currentSong)) {

        if (currentSong.spotify &&
          BasUtil.isString(currentSong.spotify.uri)) {

          basSpotifyUri = new BasSpotifyUri(currentSong.spotify.uri)

          this.currentSongUri = currentSong.spotify.uri
          this.currentSongId = basSpotifyUri.getItemId()

          if (basSpotifyUri.isPodcast()) {

            this._setCanReplay15(true)
            this._setCanForward15(true)
          }
        }

        // Check for context URI
        if (BasUtil.isString(currentSong.contextUri)) {

          // Set context URI
          this.currentSongContextUri =
            currentSong.contextUri
        }

        // Check for context
        if (BasUtil.isString(currentSong.context)) {

          // Set context
          this.currentSongContext = currentSong.context
        }
      }
    }
  }

  BasSourceState.prototype._syncSpotifyRestrictions = function () {

    var currentSong, restrictions

    if (this._hasSource() &&
      this._basSource.subType === BAS_SOURCE.ST_SPOTIFY) {

      currentSong = this._getCurrentSong()

      if (currentSong &&
        BasUtil.isObject(currentSong.spotify) &&
        BasUtil.isObject(currentSong.spotify.restrictions)) {

        restrictions = currentSong.spotify.restrictions

        this._setCanPrevious(!restrictions[KEY_SKIP_PREV])
        this._setCanNext(!restrictions[KEY_SKIP_NEXT])
        this._setHasNextSong(!restrictions[KEY_PEEK_NEXT])
        this._setCanScrub(!restrictions[KEY_SEEK])
        this._setCanPlayPause(
          !restrictions[KEY_PAUSE] ||
          !restrictions[KEY_RESUME]
        )
        this._setCanShuffle(!restrictions[KEY_SHUFFLE])
        this._setCanRepeat(
          !restrictions[KEY_REPEAT_CONTEXT] ||
          !restrictions[KEY_REPEAT_TRACK]
        )
      }
    }
  }

  BasSourceState.prototype._syncLogo = function () {

    this._resetLogo()

    if (this._basSource) {

      if (this._basSource.isPlayingDeezer) {

        this.tabletLogoTrans.setImage(ICONS.deezerLogo)
        this.phoneLogoTrans.setImage(ICONS.deezerColor)
        this.css[CSS_LOGO_DEEZER] = true

      } else if (this._basSource.isPlayingTidal) {

        this.tabletLogoTrans.setImage(ICONS.tidalLogo)
        this.phoneLogoTrans.setImage(ICONS.tidal)
        this.css[CSS_LOGO_TIDAL] = true

      } else if (this._basSource.isPlayingSpotify) {

        this.tabletLogoTrans.setImage(ICONS.spotifyLogo)
        this.phoneLogoTrans.setImage(ICONS.spotifyNoMargin)
        this.css[CSS_LOGO_SPOTIFY] = true

      } else if (this._basSource.isPlayingAirplay) {

        this.tabletLogoTrans.setImage(ICONS.barpNoMargin)
        this.phoneLogoTrans.setImage(ICONS.barpNoMargin)
        this.css[CSS_LOGO_AAP] = true

      }

    } else {

      this._resetLogo()
    }
  }

  /**
   * Uses 'artist', 'name' and 'title' properties on current song to set
   + the big and small titles.
   *
   * @private
   */
  BasSourceState.prototype._syncCurrentSongTitles = function () {

    var song, hasBig

    song = this._getCurrentSong()

    this._setDefaultSongTitleCurrent()

    if (BasUtil.isObject(song)) {

      // Set current song artist or name
      if (song.artist) {

        this._setSongTitleBig(song.artist)
        hasBig = true

      } else if (song.name) {

        this._setSongTitleBig(song.name)
        hasBig = true
      }

      // Set current song title
      if (song.title) {

        const title = [song.title]
        if (
          this._basSource.isPlayingSpotify &&
          BasUtil.isNEString(song.album)
        ) {
          title.push(song.album)
        }
        const titleStr = title.join(' • ')

        // If big title has already been set, set as small title,
        //  otherwise set as big title.
        if (hasBig) {

          this._setSongTitleSmall(titleStr)

        } else {

          this._setSongTitleBig(titleStr)
        }
      }

      // Check for ID
      if (BasUtil.isVNumber(song[KEY_DEEZER_ID])) {

        // Set current song ID
        this.currentSongId = song[KEY_DEEZER_ID].toString()
      }

      if (BasUtil.isVNumber(song[KEY_TIDAL_ID])) {

        // Set current song ID
        this.currentSongId = song[KEY_TIDAL_ID].toString()
      }
    }
  }

  /**
   * Uses 'artist', 'name' and 'title' properties on current song to set
   * the big and small next song titles.
   *
   * @private
   */
  BasSourceState.prototype._syncNextSongTitles = function () {

    var nextSong, hasBig

    nextSong = this._getNextSong()

    this._setDefaultSongTitleNext()

    if (BasUtil.isObject(nextSong)) {

      this._setHasNextSong(!nextSong[KEY_TUNEIN])

      // Set next song artist or name
      if (nextSong.artist) {

        this._setNextSongTitleBig(nextSong.artist)
        hasBig = true

      } else if (nextSong.name) {

        this._setNextSongTitleBig(nextSong.name)
        hasBig = true
      }

      // Set next song title
      if (nextSong.title) {

        // If big title has already been set, set as small title,
        //  otherwise set as big title.
        if (hasBig) {

          this._setNextSongTitleSmall(nextSong.title)

        } else {

          this._setNextSongTitleBig(nextSong.title)
        }
      }

    } else {

      this._setHasNextSong(false)
    }
  }

  BasSourceState.prototype._syncHasContext = function () {

    this._setHasContext(
      this._basSource.isPlayingDeezer ||
      this._basSource.isPlayingTidal ||
      this._basSource.isPlayingLocal ||
      this._basSource.isPlayingSpotify
    )
  }

  BasSourceState.prototype._syncPositionPercentage = function () {

    this.positionPercentage = this.duration === 0
      ? 0
      : (this.position * 100 / this.duration).toFixed(2)
  }

  BasSourceState.prototype._syncHasQueue = function () {

    this.hasQueue = (
      this._basSource.type === BAS_SOURCE.T_PLAYER &&
      this._basSource.subType !== BAS_SOURCE.ST_TUNEIN
    ) || (
      this._basSource.isAudioSource &&
      this._basSource.source.allowsExecute(
        BAS_API.AudioSource.C_LIST,
        BAS_API.AudioSource.C_QUEUE
      )
    )

    this.css[CSS_HAS_QUEUE] = this.hasQueue
  }

  BasSourceState.prototype.syncAudioQueueType = function () {

    this.css[CSS_SAVE_PLAYLIST] = (
      this._basSource.queue &&
      this._basSource.queue.contentType === BAS_API.Queue.CONTENT_SRC_LOCAL
    )
  }

  BasSourceState.prototype._syncIsBuffering = function () {

    if (
      this._basSource.isAudioSource &&
      this._basSource.source.playbackMode ===
        BAS_API.AudioSource.A_PM_BUFFERING &&
      !this._basSource.isPlayingSpotify
    ) {

      if (
        !this.onBufferingDebounceTimeoutId &&
        !this.css[CSS_IS_BUFFERING]
      ) {

        this._setOnBufferingDebounceTimeout()
      }

    } else {

      this._clearOnBufferingDebounceTimeout()
      this._setIsBuffering(false)
    }
  }

  BasSourceState.prototype._clearOnBufferingDebounceTimeout = function () {

    if (this.onBufferingDebounceTimeoutId) {

      clearTimeout(this.onBufferingDebounceTimeoutId)
      this.onBufferingDebounceTimeoutId = null
    }
  }

  BasSourceState.prototype._setOnBufferingDebounceTimeout = function () {

    this._clearOnBufferingDebounceTimeout()

    this.onBufferingDebounceTimeoutId = setTimeout(
      this.handleBufferingDebounceTimeout,
      1500
    )
  }

  BasSourceState.prototype._onBufferingDebounceTimeout = function () {

    this._clearOnBufferingDebounceTimeout()

    this._setIsBuffering(true)

    if (this._basSource) {

      $rootScope.$emit(BAS_SOURCE.EVT_STATE_CHANGE, this._basSource.getId())
    }
  }

  /**
   * @returns {?(BasTrack|Object)}
   * @private
   */
  BasSourceState.prototype._getCurrentSong = function () {

    if (this._hasSource()) return this._basSource.source.currentSong

    return null
  }

  /**
   * @returns {?(BasTrack|Object)}
   * @private
   */
  BasSourceState.prototype._getNextSong = function () {

    if (this._hasSource()) return this._basSource.source.nextSong

    return null
  }

  /**
   * @returns {?(BasTrack|Object)}
   * @private
   */
  BasSourceState.prototype._getContext = function () {

    return this._hasSource()
      ? this._basSource.source.nowPlaying.context
      : null
  }

  // region Events

  /**
   * AudioSource device event
   *
   * @private
   */
  BasSourceState.prototype._onCapabilities = function () {

    var _source

    if (this._basSource) {

      _source = this._basSource.source

      if (_source) {

        if (this._basSource.isAudioSource) {

          this._setCanPlayPause(_source.allowsWrite(
            BAS_API.AudioSource.C_PLAYBACK
          ))
          this._setCanScrub(_source.allowsWrite(
            BAS_API.AudioSource.C_POSITION_MS
          ))
          this._setCanPrevious(_source.allowsExecute(
            BAS_API.AudioSource.C_SKIP_PREVIOUS
          ))
          this._setCanNext(_source.allowsExecute(
            BAS_API.AudioSource.C_SKIP_NEXT
          ))
          this._setCanScrub(_source.allowsWrite(
            BAS_API.AudioSource.C_POSITION_MS
          ))
          this._setCanRepeat(_source.allowsWrite(
            BAS_API.AudioSource.C_REPEAT
          ))
          this._setCanShuffle(_source.allowsWrite(
            BAS_API.AudioSource.C_SHUFFLE
          ))
          this._syncHasContext()
          this._syncHasQueue()

        } else if (this._basSource.isVideoSource) {

          this._setCanPlayPause(_source.allowsExecute(
            BAS_API.VideoSource.ACT_PLAY_PAUSE
          ))
          this._setCanUp(_source.allowsExecute(
            BAS_API.VideoSource.ACT_UP
          ))
          this._setCanDown(_source.allowsExecute(
            BAS_API.VideoSource.ACT_DOWN
          ))
          this._setCanLeft(_source.allowsExecute(
            BAS_API.VideoSource.ACT_LEFT
          ))
          this._setCanRight(_source.allowsExecute(
            BAS_API.VideoSource.ACT_RIGHT
          ))
          this._setCanEnter(_source.allowsExecute(
            BAS_API.VideoSource.ACT_ENTER
          ))
          this._setCanMenu(_source.allowsExecute(
            BAS_API.VideoSource.ACT_MENU
          ))
          this._setCanBack(_source.allowsExecute(
            BAS_API.VideoSource.ACT_BACK
          ))
          this._setCanChannelUp(_source.allowsExecute(
            BAS_API.VideoSource.ACT_CHANNEL_DOWN
          ))
          this._setCanChannelDown(_source.allowsExecute(
            BAS_API.VideoSource.ACT_CHANNEL_DOWN
          ))
          this._setCanFastForward(_source.allowsExecute(
            BAS_API.VideoSource.ACT_FAST_FORWARD
          ))
          this._setCanRewind(_source.allowsExecute(
            BAS_API.VideoSource.ACT_REWIND
          ))
          this._setCanNext(_source.allowsExecute(
            BAS_API.VideoSource.ACT_SKIP_NEXT
          ))
          this._setCanPrevious(_source.allowsExecute(
            BAS_API.VideoSource.ACT_SKIP_PREVIOUS
          ))
        }
      }
    }
  }

  /**
   * AudioSource device event
   *
   * @private
   */
  BasSourceState.prototype._onAttributes = function () {

    var _source

    if (this._basSource) {

      _source = this._basSource.source

      if (_source) {

        if (this._basSource.isVideoSource) {

          this._setHasNumberGrid(BasUtil.isNEArray(_source.numericButtons))
          this._setHasCustomGrid(BasUtil.isNEArray(_source.customButtons))
        }
      }
    }
  }

  BasSourceState.prototype._onQueueChanged = function () {

    this._syncCanMediaControls()
  }

  BasSourceState.prototype._syncCanMediaControls = function () {

    var queue, currentSong

    this._setDefaultQueueState()

    currentSong = this._getCurrentSong()

    if (this._basSource) {

      switch (this._basSource.type) {
        case BAS_SOURCE.T_SONOS:

          this._onCapabilities()

          break
        case BAS_SOURCE.T_ASANO:

          this.syncAudioQueueType()

          this._onCapabilities()

          break
        case BAS_SOURCE.T_VIDEO:

          this._onCapabilities()

          break
        case BAS_SOURCE.T_PLAYER:

          if (this._basSource.subType !== BAS_SOURCE.ST_TUNEIN &&
            this._basSource.source &&
            this._basSource.source.queue) {

            queue = this._basSource.source.queue

            switch (queue.type) {
              case BAS_API.Queue.TYPE_QUEUE:

                this.css[CSS_SAVE_PLAYLIST] =
                  queue.contentSource ===
                  BAS_API.Queue.CONTENT_SRC_LOCAL
                this._setCanPrevious(true)
                this._setCanNext(true)
                this._setCanRepeat(true)
                this._setCanShuffle(true)

                break
              case BAS_API.Queue.TYPE_DEEZER_RADIO:
              case BAS_API.Queue.TYPE_DEEZER_FLOW:

                this._setCanPrevious(true)
                this._setCanNext(true)

                break
            }
          }
          break

        case BAS_SOURCE.T_BARP:

          switch (this._basSource.subType) {
            case BAS_SOURCE.ST_AAP:

              this._setCanPrevious(true)
              this._setCanNext(true)

              break
            case BAS_SOURCE.ST_SPOTIFY:

              if (currentSong &&
                currentSong.spotify &&
                currentSong.spotify.restrictions) {

                this._syncSpotifyRestrictions()

              } else {

                this._setCanPrevious(true)
                this._setCanNext(true)
                this._setCanRepeat(true)
                this._setCanShuffle(true)
              }
              break
          }
          break
      }
    }
  }

  BasSourceState.prototype._onCurrentSong = function () {

    this._syncSongInfo()
    this._syncCanMediaControls()
  }

  BasSourceState.prototype._onNextSong = function () {

    var nextSong

    this._syncNextSongTitles()

    if (this._hasSource()) {

      nextSong = this._getNextSong()
    }

    if (nextSong &&
      nextSong.coverartUrl) {

      BasResource.getDomLoadedImage(nextSong.coverartUrl)
    }
  }

  BasSourceState.prototype._onPaused = function (isPaused) {

    this.setPaused(isPaused)
    this._setPauseTimeout()
  }

  BasSourceState.prototype._onPlayback = function () {

    this._syncIsBuffering()
  }

  BasSourceState.prototype._onRepeat = function (repeatMode) {

    this.setRepeatMode(repeatMode)
  }

  BasSourceState.prototype._onRandom = function (isRandom) {

    this.setRandom(isRandom)
  }

  BasSourceState.prototype._onDuration = function (duration) {

    this._setDuration(duration)

    // Set position percentage
    this._syncPositionPercentage()
  }

  BasSourceState.prototype._onPosition = function (position) {

    this.position = position

    // Set position percentage
    this._syncPositionPercentage()
  }

  // endregion

  // region Helper functions

  BasSourceState.prototype._setDefaultQueueState = function () {

    this._setCanPrevious(false)
    this._setCanNext(false)
    this._setCanRepeat(false)
    this._setCanShuffle(false)
    this.css[CSS_SAVE_PLAYLIST] = false
  }

  BasSourceState.prototype._setDefaultPlayerState = function () {

    this.currentId = -1
    this._setHasContext(false)
    this._setCanScrub(false)
    this.hasMessage = false

    this._setCanPlayPause(false)
    this._setHasSong(false)
    this.hasQueue = false

    this.setPaused(true)
    this.uiPaused = undefined
    this.setRepeatMode(BAS_API.CONSTANTS.REPEAT_OFF)
    this.setRandom(false)
    this._setCanReplay15(false)
    this._setCanForward15(false)

    this._resetPlayerStateCss()

    this._setDefaultSongTitleCurrent()

    this._setDefaultSongTitleNext()

    this._setDefaultPosition()

    this._setDefaultQueueState()
  }

  BasSourceState.prototype._setDefaultSongTitleCurrent = function () {

    this.currentSongTitleSmall = ''
    this.currentSongTitleBig = ''
    this.currentSongContext = ''
    this.currentSongContextUri = ''
    this.currentSongId = ''
    this.currentSongUri = ''
  }

  BasSourceState.prototype._setDefaultSongTitleNext = function () {

    this._setNextSongTitleSmall('')
    this._setNextSongTitleBig('')
  }

  BasSourceState.prototype._setDefaultPosition = function () {

    this.position = 0
    this.positionPercentage = 0
    this._setDuration(0)
  }

  BasSourceState.prototype._resetLogo = function () {

    this.tabletLogoTrans.setImage('')
    this.phoneLogoTrans.setImage('')

    this.css[CSS_LOGO_AAP] = false
    this.css[CSS_LOGO_DEEZER] = false
    this.css[CSS_LOGO_SPOTIFY] = false
    this.css[CSS_LOGO_TIDAL] = false
  }

  // endregion

  // region Setters

  /**
   * @private
   * @param {string} title
   */
  BasSourceState.prototype._setSongTitleSmall = function (title) {

    this.currentSongTitleSmall = title
  }

  /**
   * @private
   * @param {string} title
   */
  BasSourceState.prototype._setSongTitleBig = function (title) {

    this.currentSongTitleBig = title
  }

  /**
   * @private
   * @param {string} title
   * @param {string} artist
   */
  BasSourceState.prototype._setSongTitle = function (title, artist) {

    this._setDefaultSongTitleCurrent()

    if (BasUtil.isNEString(artist)) {

      this._setSongTitleBig(artist)

      if (BasUtil.isNEString(title)) {

        this._setSongTitleSmall(title)
      }

    } else if (BasUtil.isNEString(title)) {

      // No artist, show song title as big title
      this._setSongTitleBig(title)
    }
  }

  /**
   * @param {boolean} value
   */
  BasSourceState.prototype.setHasMessage = function (value) {

    this.hasMessage = value === true
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanPrevious = function (value) {

    this.canPrevious = value === true
    this.css[CSS_CAN_PREVIOUS] = this.canPrevious
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanNext = function (value) {

    this.canNext = value === true
    this.css[CSS_CAN_NEXT] = this.canNext
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanScrub = function (value) {

    this.canScrub = value === true
    this.css[CSS_CAN_SCRUB] = this.canScrub
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanRepeat = function (value) {

    this.canRepeat = value === true
    this.css[CSS_CAN_REPEAT] = this.canRepeat
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanShuffle = function (value) {

    this.canShuffle = value === true
    this.css[CSS_CAN_SHUFFLE] = this.canShuffle
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setHasNextSong = function (value) {

    this.hasNextSong = value === true
    this.css[CSS_HAS_NEXT_SONG] = this.hasNextSong
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setHasSong = function (value) {

    this.hasSong = value === true
    this.css[CSS_HAS_SONG] = this.hasSong
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setHasContext = function (value) {

    this.hasContext = value === true
    this.css[CSS_HAS_CONTEXT] = this.hasContext
  }

  /**
   * @private
   * @param {number} value
   */
  BasSourceState.prototype._setDuration = function (value) {

    this.duration = value
    this.css[CSS_POS_DURATION] = this.duration > 0
  }

  /**
   * @param {number} value
   */
  BasSourceState.prototype.setRepeatMode = function (value) {

    this.repeatMode = value
    this.css[CSS_IS_REPEAT_CONTEXT] =
      this.repeatMode === BAS_API.CONSTANTS.REPEAT_CURRENT_CONTEXT
    this.css[CSS_IS_REPEAT_TRACK] =
      this.repeatMode === BAS_API.CONSTANTS.REPEAT_CURRENT_TRACK
  }

  /**
   * @param {boolean} value
   */
  BasSourceState.prototype.setRandom = function (value) {

    this.random = value === true
    this.css[CSS_IS_RANDOM] = this.random
  }

  /**
   * @param {boolean} value
   */
  BasSourceState.prototype.setPaused = function (value) {

    this.paused = value === true
    this.css[CSS_PAUSED] = this.paused
    this.css[CSS_PLAYING] = !this.paused
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanPlayPause = function (value) {

    this.canPlayPause = value === true
    this.css[CSS_CAN_PLAY_PAUSE] = this.canPlayPause
  }

  /**
   * @private
   * @param {boolean} bool
   */
  BasSourceState.prototype._setPlayPauseShow = function (bool) {

    this.css[CSS_PLAY_PAUSE_SHOW] = (
      bool === true &&
      !this.hasMessage &&
      this._basSource &&
      !this._basSource.isPlayingSpotify &&
      !this._basSource.isPlayingTunein
    )
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanReplay15 = function (value) {

    this.canReplay15 = value === true
    this.css[CSS_CAN_REPLAY_15] = this.canReplay15
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanForward15 = function (value) {

    this.canForward15 = value === true
    this.css[CSS_CAN_FORWARD_15] = this.canForward15
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanFastForward = function (value) {

    this.canFastForward = value === true
    this.css[CSS_CAN_FAST_FORWARD] = this.canFastForward
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanRewind = function (value) {

    this.canRewind = value === true
    this.css[CSS_CAN_REWIND] = this.canRewind
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanBack = function (value) {

    this.canBack = value === true
    this.css[CSS_CAN_BACK] = this.canBack
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanMenu = function (value) {

    this.canMenu = value === true
    this.css[CSS_CAN_MENU] = this.canMenu
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanMenu = function (value) {

    this.canMenu = value === true
    this.css[CSS_CAN_MENU] = this.canMenu
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanUp = function (value) {

    this.canUp = value === true
    this.css[CSS_CAN_UP] = this.canUp
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanDown = function (value) {

    this.canDown = value === true
    this.css[CSS_CAN_DOWN] = this.canDown
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanLeft = function (value) {

    this.canLeft = value === true
    this.css[CSS_CAN_LEFT] = this.canLeft
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanRight = function (value) {

    this.canRight = value === true
    this.css[CSS_CAN_RIGHT] = this.canRight
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanEnter = function (value) {

    this.canEnter = value === true
    this.css[CSS_CAN_ENTER] = this.canEnter
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanChannelUp = function (value) {

    this.canChannelUp = value === true
    this.css[CSS_CAN_CHANNEL_UP] = this.canChannelUp
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setCanChannelDown = function (value) {

    this.canChannelDown = value === true
    this.css[CSS_CAN_CHANNEL_DOWN] = this.canChannelDown
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setHasNumberGrid = function (value) {

    this.hasNumberGrid = value === true
    this.css[CSS_HAS_NUMBER_GRID] = this.hasNumberGrid
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setHasCustomGrid = function (value) {

    this.hasCustomGrid = value === true
    this.css[CSS_HAS_CUSTOM_GRID] = this.hasCustomGrid
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setIsSpotify = function (value) {

    this.css[CSS_IS_SPOTIFY] = value === true
  }

  /**
   * @private
   * @param {string} value
   */
  BasSourceState.prototype._setNextSongTitleSmall = function (value) {

    this.nextSongTitleSmall = value
    this.css[CSS_HAS_NSTS] = BasUtil.isNEString(this.nextSongTitleSmall)
  }

  /**
   * @private
   * @param {string} value
   */
  BasSourceState.prototype._setNextSongTitleBig = function (value) {

    this.nextSongTitleBig = value
    this.css[CSS_HAS_NSTB] = BasUtil.isNEString(this.nextSongTitleBig)
  }

  /**
   * @private
   * @param {boolean} value
   */
  BasSourceState.prototype._setIsBuffering = function (value) {

    this.css[CSS_IS_BUFFERING] = value === true
  }

  /**
   * @private
   * @param {string} title
   * @param {string} artist
   */
  BasSourceState.prototype._setNextSongTitle = function (title, artist) {

    this._setDefaultSongTitleNext()

    if (BasUtil.isNEString(artist)) {

      this._setNextSongTitleBig(artist)

      if (BasUtil.isNEString(title)) {

        this._setNextSongTitleSmall(title)
      }

    } else if (BasUtil.isNEString(title)) {

      // No artist, show song title as big title
      this._setNextSongTitleBig(title)
    }
  }

  BasSourceState.prototype._setPauseTimeout = function () {

    if (this.paused !== this.uiPaused) {

      $timeout.cancel(this.onPauseShowTimeout)
      this.onPauseShowTimeout =
        $timeout(this.setPlayPauseShow, 50, true, true)

      this.uiPaused = this.paused

      $timeout.cancel(this.onPauseTimeout)
      this.onPauseTimeout =
        $timeout(this.setPlayPauseShow, 2000, true, false)
    }
  }

  // endregion

  /**
   * @private
   */
  BasSourceState.prototype._resetPlayerStateCss = function () {

    this.css[CSS_PAUSED] = false
    this.css[CSS_PLAYING] = false
    this.css[CSS_HAS_QUEUE] = false
    this.css[CSS_HAS_NEXT_SONG] = false
    this.css[CSS_HAS_CONTEXT] = false
    this.css[CSS_CAN_PREVIOUS] = false
    this.css[CSS_CAN_NEXT] = false
    this.css[CSS_CAN_SCRUB] = false
    this.css[CSS_CAN_REPEAT] = false
    this.css[CSS_CAN_SHUFFLE] = false
    this.css[CSS_CAN_REPLAY_15] = false
    this.css[CSS_CAN_FORWARD_15] = false
    this.css[CSS_LOGO_AAP] = false
    this.css[CSS_LOGO_DEEZER] = false
    this.css[CSS_LOGO_SPOTIFY] = false
    this.css[CSS_LOGO_TIDAL] = false
    this.css[CSS_POS_DURATION] = false
    this.css[CSS_IS_RANDOM] = false
    this.css[CSS_IS_REPEAT_TRACK] = false
    this.css[CSS_IS_REPEAT_CONTEXT] = false
    this.css[CSS_IS_SPOTIFY] = false
  }

  /**
   * @private
   */
  BasSourceState.prototype._resetCss = function () {

    this._resetPlayerStateCss()
    this.css[CSS_PLAY_PAUSE_SHOW] = false
  }

  return BasSourceState
}
