'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('RadioRequest', [
    'BAS_TUNE_IN',
    'BasUtilities',
    'Logger',
    radioRequestFactory
  ])

/**
 * @param {BAS_TUNE_IN} BAS_TUNE_IN
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns RadioRequest
 */
function radioRequestFactory (
  BAS_TUNE_IN,
  BasUtilities,
  Logger
) {
  var className = 'Library Radio Request'

  /**
   * Object that represents a TuneIn request made for the API
   *
   * @constructor
   */
  function RadioRequest () {

    /**
     * @instance
     * @type {string}
     */
    this.path = BAS_TUNE_IN.PATH_BROWSE

    /**
     * Request parameters
     *
     * @instance
     * @type {Object}
     */
    this.params = {}

    // Create "locale" parameter for language
    this.setParam(BAS_TUNE_IN.PARAM_LOCALE, BasUtilities.getTranslateKey())
  }

  // Static methods

  /**
   * Checks whether the path is valid
   *
   * @param {string} value
   * @returns {boolean}
   */
  RadioRequest.checkPath = function (value) {
    return (
      value === BAS_TUNE_IN.PATH_BROWSE ||
      value === BAS_TUNE_IN.PATH_DESCRIBE ||
      value === BAS_TUNE_IN.PATH_SEARCH ||
      value === BAS_TUNE_IN.PATH_CUSTOM_STATIONS
    )
  }

  // Instance methods

  /**
   * Set path for request
   *
   * @param {string} value
   */
  RadioRequest.prototype.setPath = function (value) {

    // Check input
    if (RadioRequest.checkPath(value)) {
      this.path = value
    } else {
      Logger.warn(className + ' - Invalid path', value)
    }
  }

  /**
   * Add a request parameter
   *
   * @param {string} key
   * @param {string|number|boolean} value
   */
  RadioRequest.prototype.setParam = function (key, value) {

    // Check input
    if (BasUtil.isNEString(key) &&
      (
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean'
      )) {

      // Add parameter
      this.params[key] = value

    } else {
      Logger.warn(className +
                    ' - setParam' +
                    ' - Invalid input', key, value)
    }
  }

  /**
   * Add request parameters from object
   *
   * @param {Object} obj
   */
  RadioRequest.prototype.setParams = function (obj) {

    // Check input
    if (BasUtil.isObject(obj)) {

      // Add keys from obj
      BasUtil.mergeObjects(this.params, obj)

    } else {
      Logger.warn(className +
                    ' - setParams' +
                    ' - Invalid input', obj)
    }
  }

  return RadioRequest
}
