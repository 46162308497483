'use strict'

var BasUtil = require('@basalte/bas-util')

var Device = require('./device')

var P = require('./parser_constants')

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function GenericDevice (device, basCore) {

  Device.call(this, device, basCore)

  this.parse(device, { emit: false })
}

GenericDevice.prototype = Object.create(Device.prototype)
GenericDevice.prototype.constructor = GenericDevice

// region Events

/**
 * @event GenericDeviceV1#EVT_CONTROLS
 * @param {GenericDeviceControl[]} controls
 */

// endregion

/**
 * @constant {string}
 */
GenericDevice.EVT_CONTROLS_UPDATED = 'evtGenericDeviceControlsUpdated'

/**
 * Set the control value
 *
 * @param {string} controlUuid
 * @param {(number|boolean)} value Range: 0 - 1
 */
GenericDevice.prototype.setValue = function (controlUuid, value) {

  var msg

  msg = this._getBasCoreMessage()
  msg[P.DEVICE][P.ACTION] = P.UPDATE
  msg[P.DEVICE][P.CONTROL] = {}
  msg[P.DEVICE][P.CONTROL][P.UUID] = controlUuid
  msg[P.DEVICE][P.CONTROL][P.STATE] = {}
  msg[P.DEVICE][P.CONTROL][P.STATE][P.VALUE] = value

  this._basCore.send(msg)
}

/**
 * @param {GenericDeviceControl[]} controls
 * @param {(string|GenericDeviceControl)} control
 * @returns {number} index of the control
 */
GenericDevice.prototype.indexOfGenericDeviceControl = function (
  controls,
  control
) {

  var uuid, i, length

  if (Array.isArray(controls)) {

    if (BasUtil.isObject(control)) {

      uuid = control.uuid

    } else if (BasUtil.isNEString(control)) {

      uuid = control
    }

    if (BasUtil.isNEString(uuid)) {

      length = controls.length
      for (i = 0; i < length; i++) {

        if (uuid === controls[i].uuid) return i
      }
    }
  }

  return -1
}

module.exports = GenericDevice
