'use strict'

var BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .service('BasStateHelper', BasStateHelper)

/**
 * @constructor
 */
function BasStateHelper () {
  this.hasBaseState = hasBaseState
  this.hasSubState = hasSubState
  this.isSubState = isSubState
  this.getStateWithDepth = getStateWithDepth

  /**
   * State   base     result
   * a.b     a.b      true
   * a.b     b        false
   * a.b.c   a.b      true
   * a.b.c   a.d      false
   *
   * @param {string} state
   * @param {string} base
   * @returns {boolean}
   */
  function hasBaseState (state, base) {

    const baseSplit = base.split('.')
    const stateSplit = state.split('.')

    return BasUtil.isEqualArray(
      baseSplit,
      stateSplit.slice(0, baseSplit.length)
    )
  }

  /**
   * Exact sub state match
   *
   * state   sub      result
   * a.b     a.b      false
   * a.b     b        true
   * a.b.c   b.c      true
   *
   * state.sub.sub       !== state.sub
   * state.state.sub.sub === state.sub.sub
   * sub.sub             === sub.sub
   *
   * @param {string} state
   * @param {string} sub
   * @returns {boolean}
   */
  function hasSubState (state, sub) {

    const subSplit = sub.split('.')
    const stateSplit = state.split('.')

    return (
      subSplit.length < stateSplit.length &&
      BasUtil.isEqualArray(
        stateSplit.slice(-subSplit.length),
        subSplit
      )
    )
  }

  /**
   * Is strict sub state
   *
   * base    state    result
   * a.b     a.b      false
   * a.b     a.b.c    true
   *
   * @param {string} base
   * @param {string} state
   * @returns {boolean}
   */
  function isSubState (base, state) {

    return (
      hasBaseState(state, base) &&
      state.length > base.length
    )
  }

  /**
   * @param {string} state Full state name
   * @param {string} base Base state name
   * @param {number} depth Depth from base state
   * @returns {string}
   */
  function getStateWithDepth (state, base, depth) {

    var part, split

    if (hasBaseState(state, base)) {

      part = state.substring(base.length + 1)

      if (part) {

        split = part.split('.', depth)

        return base + '.' + split.join('.')
      }
    }

    return ''
  }
}
