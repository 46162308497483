'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var log = require('./logger')

var K_COVERART = 'coverart'
var K_THUMBNAIL = 'thumbnail'

/**
 * @typedef {Object} DatabaseCoverArt
 * @property {string} coverart
 * @property {string} thumbnail
 */

/**
 * The class representing a Player database.
 *
 * @constructor
 * @extends EventEmitter
 * @param {number} playerID The id of the associated player
 * @param {BasCore} basCore
 * @since 0.0.1
 */
function Database (playerID, basCore) {

  EventEmitter.call(this)

  this._basCore = basCore

  this._playerID = playerID

  this._contentChecked = false
  this._hasContent = false
  this._updating = false

  this._contentCheckPromise = null

  this._handleDatabaseOverview = this._onDatabaseOverview.bind(this)
  this._handleDatabaseDetails = this._onDatabaseDetails.bind(this)
  this._handleBrowse = this._onBrowse.bind(this)
  this._handleArtistOverview = this._onArtistOverviewRequest.bind(this)
  this._handleSongOverviewForContentCheck =
    this._onSongOverviewForContentCheck.bind(this)
  this._handleSongOverviewForContentCheckError =
    this._onSongOverviewForContentCheckError.bind(this)
  this._handleCoverArtRequest = this._onCoverArtRequest.bind(this)
}

Database.prototype = Object.create(EventEmitter.prototype)
Database.prototype.constructor = Database

// region Events

/**
 * Playlist(s) has changed (not the queue).
 * This event has no parameters as it is up to the client to determine if
 * a view needs to be updated.
 *
 * This event could indicate a change in the list of playlists (add, remove,
 * rename) or a change inside a playlist (not the queue).
 *
 * @event Database#EVT_PLAYLIST_CHANGED
 */

/**
 * Database has been modified.
 * This event has no parameters as it is up to the client
 * to determine if a view needs to be updated.
 *
 * @event Database#EVT_DATABASE_CHANGED
 */

/**
 * Database has started updating it's content. The
 * {@link Database#EVT_DATABASE_CHANGED} event will be fired if something has
 * changed.
 *
 * @event Database#EVT_DATABASE_UPDATING
 * @param {boolean} updating True: update started, false: update finished
 */

/**
 * "hasContent" property has been updated
 *
 * @event Database#EVT_HAS_CONTENT
 * @param {boolean} hasContent
 * @since 1.7.0
 */

// endregion

/**
 * @constant {string}
 */
Database.EVT_PLAYLIST_CHANGED = 'playlistChanged'

/**
 * @constant {string}
 */
Database.EVT_DATABASE_CHANGED = 'databaseChanged'

/**
 * @constant {string}
 */
Database.EVT_DATABASE_UPDATING = 'databaseUpdating'

/**
 * @constant {string}
 */
Database.EVT_HAS_CONTENT = 'databaseHasContent'

/**
 * @constant {string}
 */
Database.ERR_PLAYLIST_EXISTS = 'errPlaylistExists'

/**
 * @constant {string}
 */
Database.ERR_INVALID_PLAYLIST = 'errInvalidPlaylist'

/**
 * @constant {string}
 */
Database.ERR_PERMISSION_DENIED = 'errPermissionDenied'

/**
 * @constant {string}
 */
Database.ERR_UNKNOWN_RESULT = 'errUnknownResult'

/**
 * @constant {string}
 */
Database.ERR_INVALID_INPUT = 'errInvalidInput'

/**
 * @constant {string}
 */
Database.ERR_INVALID_RESPONSE = 'errInvalidResponse'

Object.defineProperties(Database.prototype, {

  /**
   * The update status of the database
   *
   * @name Database#isUpdating
   * @type {boolean}
   * @readonly
   * @since 1.1.0
   */
  isUpdating: {
    get: function () {
      return this._updating
    }
  },

  /**
   * Whether content has been checked or not
   *
   * @name Database#contentChecked
   * @type {boolean}
   * @readonly
   * @since 3.7.0
   */
  contentChecked: {
    get: function () {
      return this._contentChecked
    }
  },

  /**
   * Whether there is any local music found
   *
   * @name Database#hasContent
   * @type {boolean}
   * @readonly
   * @since 1.7.0
   */
  hasContent: {
    get: function () {
      return this._hasContent
    }
  }
})

/**
 * Checks whether a basCore message is a valid database message
 *
 * @param {?Object} message
 * @returns {boolean}
 */
Database.prototype.isValidDatabaseMessage = function (message) {
  return (
    BasUtil.isObject(message) &&
    BasUtil.isObject(message[P.PLAYER]) &&
    message[P.PLAYER][P.ID] === this._playerID
  )
}

/**
 * Checks the basCore message and returns the database object if available
 *
 * @param {?Object} message
 * @returns {?Object}
 */
Database.prototype.getDatabaseFromMessage = function (message) {

  if (this.isValidDatabaseMessage(message) &&
    BasUtil.isObject(message[P.PLAYER][P.DATABASE])) {

    return message[P.PLAYER][P.DATABASE]
  }

  return null
}

/**
 * Set hasContent property. This will emit an event EVT_HAS_CONTENT
 *
 * @private
 * @param {boolean} value
 * @param {boolean} [emitEvent = true]
 * @since 1.7.0
 */
Database.prototype.setHasContent = function (value, emitEvent) {

  this._hasContent = value === true

  if (emitEvent !== false) {

    this.emit(Database.EVT_HAS_CONTENT, this._hasContent)
  }
}

/**
 * Parse a message from the connected basCore
 *
 * @param {Object} data
 */
Database.prototype.parse = function (data) {

  if (BasUtil.isObject(data)) {

    // Updating
    if (BasUtil.isBool(data[P.UPDATING])) {

      this._updating = data[P.UPDATING]
      this.emit(
        Database.EVT_DATABASE_UPDATING,
        data[P.UPDATING]
      )
    }

    // Changed
    if (data[P.CHANGED] === true) {

      this._contentChecked = false

      // Reset hasContent
      this.setHasContent(false)

      this.emit(Database.EVT_DATABASE_CHANGED)
    }

    // Playlist changed
    if (BasUtil.isObject(data[P.PLAYLIST]) &&
      data[P.PLAYLIST][P.CHANGED] === true) {

      this.emit(Database.EVT_PLAYLIST_CHANGED)
    }
  }
}

/**
 * Method to manually start a scan of the database content for a specified path.
 * This will trigger
 * {@link Database#EVT_DATABASE_UPDATING} and
 * {@link Database#EVT_DATABASE_CHANGED} events.
 *
 * @param {string} [path] path to scan, otherwise the whole database
 */
Database.prototype.update = function (path) {

  var data

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DATABASE][P.UPDATE] =
    BasUtil.isNEString(path) ? path : ''

  this._basCore.send(data)
}

/**
 * Asynchronous method to retrieve all playlist names (sorted).
 *
 * @returns {Promise}
 */
Database.prototype.getPlaylists = function () {

  var _this, data

  _this = this

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
  data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
    P.LIST

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(onRequest)

  function onRequest (result) {

    var db

    db = _this.getDatabaseFromMessage(result)

    return db || Promise.reject(Database.ERR_INVALID_RESPONSE)
  }
}

/**
 * Get detailed information of a playlist
 *
 * @param {string} id The id of the playlist
 * @returns {Promise}
 * @since 1.1.0
 */
Database.prototype.getPlaylistDetail = function (id) {

  var _this, data

  if (BasUtil.isNEString(id)) {

    _this = this

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
      P.DETAIL
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ID] = id

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(onRequest)

  } else {

    return Promise.reject(Database.ERR_INVALID_INPUT)
  }

  function onRequest (result) {

    var player

    if (_this.isValidDatabaseMessage(result) &&
      P.RESULT in result[P.PLAYER]) {

      player = result[P.PLAYER]

      switch (result[P.PLAYER][P.RESULT]) {
        case P.OK:

          if (BasUtil.isObject(player[P.DATABASE]) &&
            P.PLAYLIST in player[P.DATABASE]) {

            return player[P.DATABASE][P.PLAYLIST]
          }

          return Promise.reject(Database.ERR_INVALID_RESPONSE)

        case P.INVALID_PLAYLIST:

          return Promise.reject(Database.ERR_INVALID_PLAYLIST)

        case P.PERMISSION_DENIED:

          return Promise.reject(Database.ERR_PERMISSION_DENIED)

        default:

          return Promise.reject(Database.ERR_UNKNOWN_RESULT)
      }
    }

    return Promise.reject(Database.ERR_INVALID_RESPONSE)
  }
}

/**
 * Asynchronous method to retrieve the content of a playlist
 *
 * @param {string} id The id of the playlist
 * @returns {Promise}
 */
Database.prototype.getPlaylistContent = function (id) {

  var _this, data

  if (BasUtil.isNEString(id)) {

    _this = this

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
      P.CONTENT
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ID] = id

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(onRequest)

  } else {

    return Promise.reject(Database.ERR_INVALID_INPUT)
  }

  function onRequest (result) {

    var db

    if (!_this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

    db = _this.getDatabaseFromMessage(result)

    if (db &&
      BasUtil.isObject(db[P.PLAYLIST]) &&
      id === db[P.PLAYLIST][P.ID] &&
      Array.isArray(db[P.PLAYLIST][P.SONGS])) {

      _this._basCore.processCoverArts(db[P.PLAYLIST][P.SONGS])

      return db[P.PLAYLIST][P.SONGS]
    }

    return Promise.reject(Database.ERR_INVALID_RESPONSE)
  }
}

/**
 * Method to add a new (empty) playlist
 *
 * This will result in a {@link Database#EVT_PLAYLIST_CHANGED} event.
 *
 * @param {string} playlist The name of the new playlist
 * @returns {Promise}
 */
Database.prototype.addPlaylist = function (playlist) {

  var _this, data

  if (BasUtil.isNEString(playlist)) {

    _this = this

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
      P.NEW
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.NAME] =
      playlist

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(onRequest)

  } else {

    return Promise.reject(Database.ERR_INVALID_INPUT)
  }

  function onRequest (result) {

    var player

    if (_this.isValidDatabaseMessage(result) &&
      P.RESULT in result[P.PLAYER]) {

      player = result[P.PLAYER]

      switch (player[P.RESULT]) {
        case P.OK:

          if (BasUtil.isObject(player[P.PLAYLIST])) {

            return player[P.PLAYLIST][P.ID]
          }

          break
        case P.PLAYLIST_EXISTS:

          return Promise.reject(Database.ERR_PLAYLIST_EXISTS)

        default:

          return Promise.reject(Database.ERR_UNKNOWN_RESULT)
      }
    }

    return Promise.reject(Database.ERR_INVALID_RESPONSE)
  }
}

/**
 * Method to rename a playlist
 *
 * This will result in a {@link Database#EVT_PLAYLIST_CHANGED} event.
 *
 * @param {string} id The id of the playlist
 * @param {string} playlistNewName The new name of the playlist
 * @returns {Promise}
 */
Database.prototype.renamePlaylist = function (
  id,
  playlistNewName
) {
  var _this, data

  if (BasUtil.isNEString(id) &&
    BasUtil.isNEString(playlistNewName)) {

    _this = this

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
      P.RENAME
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ID] = id
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.NAME] =
      playlistNewName

    return this._basCore.requestRetry(data).then(onRequest)

  } else {

    return Promise.reject(Database.ERR_INVALID_INPUT)
  }

  function onRequest (result) {

    if (_this.isValidDatabaseMessage(result) &&
      P.RESULT in result[P.PLAYER]) {

      switch (result[P.PLAYER][P.RESULT]) {
        case P.OK:

          return true

        case P.PLAYLIST_EXISTS:

          return Promise.reject(Database.ERR_PLAYLIST_EXISTS)

        default:

          return Promise.reject(Database.ERR_UNKNOWN_RESULT)
      }
    }

    return Promise.reject(Database.ERR_INVALID_RESPONSE)
  }
}

/**
 * Method to (un)share a playlist
 *
 * @param {string} id The id of the playlist to clear
 * @param {boolean} share Boolean indicating if we want to share the playlist
 * @since 1.0.0
 */
Database.prototype.sharePlaylist = function (id, share) {

  var data

  if (BasUtil.isNEString(id) &&
    BasUtil.isBool(share)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
      P.SHARE
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ID] = id
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.SHARED] =
      share

    this._basCore.send(data)
  }
}

/**
 * Method to remove a playlist
 *
 * @param {string} id The id of the playlist to remove
 */
Database.prototype.removePlaylist = function (id) {

  var data

  if (BasUtil.isNEString(id)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
      P.DELETE
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ID] = id

    this._basCore.send(data)
  }
}

/**
 * Method to clear a playlist
 *
 * @param {string} id The id of the playlist to clear
 */
Database.prototype.clearPlaylist = function (id) {

  var data

  if (BasUtil.isNEString(id)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
      P.CLEAR
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ID] = id

    this._basCore.send(data)
  }
}

/**
 * Add song(s) to a playlist
 *
 * @param {string} id The id of the playlist
 * @param {(string | string[])} songs Filepath(s) for song(s) to add
 */
Database.prototype.addSongsToPlaylist = function (
  id,
  songs
) {
  var playlistObj, data

  if (BasUtil.isNEString(id)) {

    if (Array.isArray(songs)) {

      playlistObj = {}
      playlistObj[P.ACTION] = P.ADD
      playlistObj[P.ID] = id
      playlistObj[P.SONGS] = songs

    } else if (BasUtil.isNEString(songs)) {

      playlistObj = {}
      playlistObj[P.ACTION] = P.ADD
      playlistObj[P.ID] = id
      playlistObj[P.PATH] = songs

    } else {

      log.error('Database - addSongsToPlaylist - Invalid song(s)', songs)
    }

    if (playlistObj) {

      data = this._getBasCoreMessage()
      data[P.PLAYER][P.DATABASE][P.PLAYLIST] = playlistObj

      this._basCore.send(data)
    }

  } else {

    log.error('Database - addSongsToPlaylist - Invalid id', id)
  }
}

/**
 * Method to remove a song from a playlist based on the position
 *
 * @param {string} id The id of the playlist
 * @param {number} position The position of the song to remove
 */
Database.prototype.removeSongFromPlaylist = function (
  id,
  position
) {
  var data

  if (BasUtil.isNEString(id) &&
    BasUtil.isPNumber(position, true)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
      P.DELETE_SONG_POS
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ID] = id
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.POS] =
      position

    this._basCore.send(data)
  }
}

/**
 * Method to move a song in a playlist based on the position
 *
 * @param {string} id The id of the playlist
 * @param {number} from The position of the song to move
 * @param {number} to The new position of the song
 * @since 1.1.0
 */
Database.prototype.moveSongInPlaylist = function (
  id,
  from,
  to
) {
  var data

  if (BasUtil.isNEString(id) &&
    BasUtil.isPNumber(from, true) &&
    BasUtil.isPNumber(to, true) &&
    from !== to) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DATABASE][P.PLAYLIST] = {}
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ACTION] =
      P.MOVE_SONG_POS
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.ID] = id
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.FROM] =
      from
    data[P.PLAYER][P.DATABASE][P.PLAYLIST][P.TO] =
      to

    this._basCore.send(data)

  } else {

    log.error('Database.moveSongInPlaylist: Invalid parameter')
  }
}

/**
 * Request the files for a specified path. This is not recursive.
 *
 * @param {string} [path] Path of directory, leave empty for root
 * @returns {Promise}
 */
Database.prototype.browse = function (path) {

  var finalPath, data

  finalPath = BasUtil.isNEString(path) ? path : ''

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DATABASE][P.DIRECTORY] = {}
  data[P.PLAYER][P.DATABASE][P.DIRECTORY][P.PATH] =
    finalPath

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(this._handleBrowse)
}

/**
 * @private
 * @param {Object} result
 * @returns {(Array|Promise)}
 */
Database.prototype._onBrowse = function (result) {

  var db

  db = this.getDatabaseFromMessage(result)

  return (db && Array.isArray(db[P.DIRECTORY]))
    ? db[P.DIRECTORY]
    : Promise.reject(Database.ERR_INVALID_RESPONSE)
}

/**
 * Checks for local music and sets the hasContent property.
 *
 * @returns {Promise<boolean>}
 * @since 1.7.0
 */
Database.prototype.checkContent = function () {

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)
  if (this._contentCheckPromise) return this._contentCheckPromise

  return (
    this._contentCheckPromise = this.songOverview(0, '', 1)
      .then(
        this._handleSongOverviewForContentCheck,
        this._handleSongOverviewForContentCheckError
      )
  )
}

/**
 * @private
 * @param {Object[]} result
 * @returns {boolean}
 * @since 3.0.0
 */
Database.prototype._onSongOverviewForContentCheck = function (result) {

  this._contentChecked = true
  this._contentCheckPromise = null

  this.setHasContent(
    Array.isArray(result) &&
    BasUtil.isObject(result[0])
  )

  return this._hasContent
}

/**
 * @private
 * @param error
 * @since 3.0.0
 */
Database.prototype._onSongOverviewForContentCheckError = function (error) {

  this._contentChecked = true
  this._contentCheckPromise = null

  this.setHasContent(false)

  return Promise.reject(error)
}

/**
 * Get an overview of the database songs
 *
 * @param {number} [page] The sequence number of the page (starting from 0)
 * @param {string} [filter] An optional search argument
 * @param {number} [limit] Request limit (since basCore version 1.4.0)
 * @returns {Promise}
 * @since 0.2.0
 */
Database.prototype.songOverview = function (
  page,
  filter,
  limit
) {
  var internalPage, internalLimit, data

  // Defaults
  internalPage = 0
  internalLimit = 50

  // Check for page parameter
  if (BasUtil.isPNumber(page)) internalPage = page

  // Check for limit parameter
  if (BasUtil.isPNumber(limit)) internalLimit = limit

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DATABASE][P.OVERVIEW] = {}
  data[P.PLAYER][P.DATABASE][P.OVERVIEW][P.TYPE] =
    P.SONG
  data[P.PLAYER][P.DATABASE][P.OVERVIEW][P.PAGE] =
    internalPage
  data[P.PLAYER][P.DATABASE][P.OVERVIEW][P.FILTER] =
    filter
  data[P.PLAYER][P.DATABASE][P.OVERVIEW][P.LIMIT] =
    internalLimit

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(this._handleDatabaseOverview)
}

/**
 * Get the songs for an album
 *
 * @param {string} albumName The name of the album
 * @param {string} albumArtist The artist of the album
 * @returns {Promise}
 * @since 0.2.0
 */
Database.prototype.albumSongs = function (
  albumName,
  albumArtist
) {
  var data

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DATABASE][P.DETAIL] = {}
  data[P.PLAYER][P.DATABASE][P.DETAIL][P.TYPE] =
    P.ALBUM
  data[P.PLAYER][P.DATABASE][P.DETAIL][P.NAME] =
    albumName
  data[P.PLAYER][P.DATABASE][P.DETAIL][P.ARTIST] =
    albumArtist

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(this._handleDatabaseDetails)
}

/**
 * Get the songs for an artist
 *
 * @param {string} artist The name of the artist
 * @returns {Promise}
 * @since 0.2.0
 */
Database.prototype.artistSongs = function (artist) {

  var data

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DATABASE][P.DETAIL] = {}
  data[P.PLAYER][P.DATABASE][P.DETAIL][P.TYPE] =
    P.ARTIST
  data[P.PLAYER][P.DATABASE][P.DETAIL][P.NAME] =
    artist

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(this._handleDatabaseDetails)
}

/**
 * Get an overview of the database albums
 *
 * @param {string} [filter] An optional search argument
 * @returns {Promise}
 * @since 0.2.0
 */
Database.prototype.albumOverview = function (filter) {

  var data

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DATABASE][P.OVERVIEW] = {}
  data[P.PLAYER][P.DATABASE][P.OVERVIEW][P.TYPE] =
    P.ALBUM
  data[P.PLAYER][P.DATABASE][P.OVERVIEW][P.FILTER] =
    filter

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(this._handleDatabaseOverview)
}

/**
 * Get an overview of the database artists
 *
 * @param {string} [filter] An optional search argument
 * @returns {Promise}
 * @since 0.2.0
 */
Database.prototype.artistOverview = function (filter) {

  var data

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DATABASE][P.OVERVIEW] = {}
  data[P.PLAYER][P.DATABASE][P.OVERVIEW][P.TYPE] =
    P.ARTIST
  data[P.PLAYER][P.DATABASE][P.OVERVIEW][P.FILTER] =
    filter

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(this._handleArtistOverview)
}

/**
 * @private
 * @param result
 * @returns {(Array|Promise)}
 */
Database.prototype._onArtistOverviewRequest = function (result) {

  var db

  db = this.getDatabaseFromMessage(result)

  return (db && Array.isArray(db[P.OVERVIEW]))
    ? db[P.OVERVIEW]
    : Promise.reject(Database.ERR_INVALID_RESPONSE)
}

/**
 * Database overview handler
 *
 * @private
 * @param {?Object} result
 * @returns {(Promise|Array)}
 */
Database.prototype._onDatabaseOverview = function (result) {

  var db

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  db = this.getDatabaseFromMessage(result)

  if (db && Array.isArray(db[P.OVERVIEW])) {

    this._basCore.processCoverArts(db[P.OVERVIEW])
    return db[P.OVERVIEW]
  }

  return Promise.reject(Database.ERR_INVALID_RESPONSE)
}

/**
 * Database detail handler
 *
 * @private
 * @param {?Object} result
 * @returns {(Promise|Array)}
 */
Database.prototype._onDatabaseDetails = function (result) {

  var db

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  db = this.getDatabaseFromMessage(result)

  if (db && Array.isArray(db[P.DETAIL])) {

    this._basCore.processCoverArts(db[P.DETAIL])
    return db[P.DETAIL]
  }

  return Promise.reject(Database.ERR_INVALID_RESPONSE)
}

/**
 * Get the cover art for the specified options.
 *
 * NOTE: Using different options results in different return values, use one of
 *     the following four:
 * * Provide both album and artist, which results in Null or a single url for
 *     the cover art.
 * * Provide album or artist, which results in a list of urls (zero or more)
 * * Provide the file name, which results in Null or a single url for the cover
 *     art.
 * * Provide the playlist name, which results in Null or a single url for the
 *     cover art.
 *
 * @param {Object} options
 * @param {string} [options.artist]
 * @param {string} [options.album]
 * @param {string} [options.file]
 * @param {string} [options.playlist]
 * @returns {Promise<(DatabaseCoverArt|(DatabaseCoverArt[]))>}
 */
Database.prototype.getCoverArt = function (options) {

  var data

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.DATABASE][P.COVERART] = options

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_LONG)
    .then(this._handleCoverArtRequest)
}

/**
 * @private
 * @param {Object} result
 * @returns {?((DatabaseCoverArt|(DatabaseCoverArt[]))|Promise)}
 */
Database.prototype._onCoverArtRequest = function (result) {

  var db, i, length, items, item, obj

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  db = this.getDatabaseFromMessage(result)

  if (db && db[P.COVERART]) {

    if (BasUtil.isNEString(db[P.COVERART])) {

      obj = {}

      obj[K_COVERART] = this._basCore.getCoverArtUrl(
        db[P.COVERART]
      )
      obj[K_THUMBNAIL] = this._basCore.getCoverArtUrl(
        db[P.COVERART],
        true
      )

      return obj

    } else if (Array.isArray(db[P.COVERART])) {

      items = []

      length = db[P.COVERART].length
      for (i = 0; i < length; i++) {

        item = db[P.COVERART][i]

        obj = {}

        obj[K_COVERART] = this._basCore.getCoverArtUrl(item)
        obj[K_THUMBNAIL] = this._basCore.getCoverArtUrl(item, true)

        items.push(obj)
      }

      return items
    }
  }

  return null
}

/**
 * @returns {Object}
 */
Database.prototype._getBasCoreMessage = function () {

  var data

  data = {}
  data[P.PLAYER] = {}
  data[P.PLAYER][P.ID] = this._playerID
  data[P.PLAYER][P.DATABASE] = {}

  return data
}

/**
 * Destructor
 *
 * @since 1.9.0
 */
Database.prototype.destroy = function destroy () {

  this._basCore = null
  this._contentCheckPromise = null

  this.removeAllListeners()
}

module.exports = Database
