'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasEnergy', [
    '$rootScope',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_ENERGY',
    'RoomsHelper',
    BasEnergy
  ])

/**
 * @typedef {Object} TBasEnergyState
 * @property {string[]} uiTypes
 * @property {Object<string, BasEnergyMeter>} energyMeters
 * @property {string[]} uiEnergyMeters
 * @property {string} uiTypeCurrent
 * @property {string[]} uiEnergyMetersCurrent
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ENERGY} BAS_ENERGY
 * @param {RoomsHelper} RoomsHelper
 */
function BasEnergy (
  $rootScope,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_ENERGY,
  RoomsHelper
) {
  var _state

  /**
   * @private
   * @type {TBasEnergyState}
   */
  _state = {
    energyMeters: {},
    uiTypes: [],
    uiEnergyMeters: [],
    uiTypeCurrent: BAS_ENERGY.T_ID_ENERGY,
    uiEnergyMetersCurrent: []
  }

  this.get = get
  this.selectType = selectType
  this.clear = clear

  init()

  function init () {

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    )
    $rootScope.$on(
      BAS_ROOM.EVT_ENERGY_ADDED,
      _onEnergyAdded
    )
    $rootScope.$on(
      BAS_ENERGY.EVT_STATE_UPDATED,
      _onEnergyStateUpdated
    )

    _syncEnergy()
  }

  function get () {
    return _state
  }

  /**
   * @param {string} type
   */
  function selectType (type) {

    if (_state.uiTypes.indexOf(type) > -1) {

      _state.uiTypeCurrent = type
      _syncCurrentType()
    }
  }

  function clear () {

    _state.energyMeters = {}
    _state.uiTypes = []
    _state.uiEnergyMeters = []
    _state.uiTypeCurrent = BAS_ENERGY.T_ID_ENERGY
    _state.uiEnergyMetersCurrent = []
  }

  function _syncEnergy () {

    var types, length, i, rooms, room, value

    _state.energyMeters = {}
    _state.uiTypes = []
    _state.uiEnergyMeters = []
    _state.uiEnergyMetersCurrent = []

    types = {}

    rooms = RoomsHelper.getEnergyRooms()

    if (rooms) {

      rooms.sort(RoomsHelper.basRoomUuidCompare)

      length = rooms.length
      for (i = 0; i < length; i++) {

        room = RoomsHelper.getRoomForId(rooms[i])

        if (room && room.energy && room.energy.syncEnergy) {

          BasUtil.mergeObjects(_state.energyMeters, room.energy.energyMeters)
          _state.uiEnergyMeters.push.apply(
            _state.uiEnergyMeters,
            room.energy.uiEnergyMeters
          )

          value = BAS_ENERGY.T_ID_ENERGY
          if (room.energy.uiTypes.indexOf(value) > -1) types[value] = true

          value = BAS_ENERGY.T_ID_GAS
          if (room.energy.uiTypes.indexOf(value) > -1) types[value] = true

          value = BAS_ENERGY.T_ID_WATER
          if (room.energy.uiTypes.indexOf(value) > -1) types[value] = true
        }
      }

      // Specific order: energy, gas, water

      value = types[BAS_ENERGY.T_ID_ENERGY]
      if (value) _state.uiTypes.push(BAS_ENERGY.T_ID_ENERGY)

      value = types[BAS_ENERGY.T_ID_GAS]
      if (value) _state.uiTypes.push(BAS_ENERGY.T_ID_GAS)

      value = types[BAS_ENERGY.T_ID_WATER]
      if (value) _state.uiTypes.push(BAS_ENERGY.T_ID_WATER)

      // TODO Check current type after ALL energy meters have been received
      //  or all types are available

      _syncCurrentType()
    }
  }

  function _syncCurrentType () {

    var keys, length, i, uuid, meter

    _state.uiEnergyMetersCurrent = []

    if (_state.uiTypeCurrent) {

      keys = Object.keys(_state.energyMeters)
      length = keys.length
      for (i = 0; i < length; i++) {

        uuid = keys[i]

        meter = _state.energyMeters[uuid]

        if (meter && meter.type === _state.uiTypeCurrent) {

          _state.uiEnergyMetersCurrent.push(uuid)
        }
      }
    }
  }

  function _syncAllEnergy () {

    _state.energyMeters = {}
    _state.uiEnergyMeters = []
    _state.hasMultipleBuildings = false

    _syncEnergy()
  }

  function _onRoomsUpdated () {

    _syncAllEnergy()

    _emitUiUpdate()
  }

  function _onEnergyAdded () {

    _syncAllEnergy()

    _emitUiUpdate()
  }

  function _onEnergyStateUpdated () {

    _emitUiUpdate()
  }

  function _emitUiUpdate () {

    $rootScope.$emit(BAS_ENERGY.EVT_UI_UPDATE)
  }
}
