'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasScene', [
    '$rootScope',
    'BAS_API',
    'BAS_SCENE',
    'BAS_SCENE_PRESETS',
    'CurrentBasCore',
    'BasStep',
    'BasScenePresetsHelper',
    'BasCommandQueue',
    'BasUtilities',
    basSceneFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_SCENE} BAS_SCENE
 * @param {BAS_SCENE_PRESETS} BAS_SCENE_PRESETS
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasStep
 * @param {BasScenePresetsHelper} BasScenePresetsHelper
 * @param {BasCommandQueue} BasCommandQueue
 * @param {BasUtilities} BasUtilities
 * @returns BasScene
 */
function basSceneFactory (
  $rootScope,
  BAS_API,
  BAS_SCENE,
  BAS_SCENE_PRESETS,
  CurrentBasCore,
  BasStep,
  BasScenePresetsHelper,
  BasCommandQueue,
  BasUtilities
) {
  var DEFAULT_TIMER = 5
  var MAX_INDEX = 9999

  var UI_ACTIVE_REMOVE_SPINNER_DELAY_MS = 1000

  var P_BACKGROUND_COLOR = 'background-color'

  var CSS_QUAD_POS_TL = 'bsqp--is-tl'
  var CSS_QUAD_POS_TR = 'bsqp--is-tr'
  var CSS_QUAD_POS_BL = 'bsqp--is-bl'
  var CSS_QUAD_POS_BR = 'bsqp--is-br'

  var CSS_CAN_ACTIVATE = 'bmc-can-activate'
  var CSS_CAN_LEARN = 'bmc-can-learn'
  var CSS_CAN_REMOVE = 'bmc-can-remove'
  var CSS_CAN_NAME = 'bmc-can-name'
  var CSS_CAN_IMAGE = 'bmc-can-image'
  var CSS_CAN_TEMPLATE = 'bmc-can-template'
  var CSS_CAN_EDIT = 'bmc-can-edit'
  var CSS_CAN_FAVOURITE = 'bmc-can-favourite'
  var CSS_CAN_CONTENT = 'bmc-can-content'
  var CSS_SPINNER_VISIBLE = 'bas-tile-spinner-visible'
  var CSS_HAS_TYPE_BADGE = 'bmc-has-badge'
  var CSS_HAS_CUSTOM_SERVER_IMAGES = 'bmc-has-custom-images'

  /**
   * @constructor
   * @param {string} uuid
   * @param {BasRoom} basRoom
   */
  function BasScene (uuid, basRoom) {

    /**
     * @type {string}
     */
    this.uuid = BasUtil.isNEString(uuid) ? uuid : ''

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * Relevant for quad favourites
     *
     * @type {number}
     */
    this.position = -1

    /**
     * @type {string}
     */
    this.name = '-'

    /**
     * @type {number}
     */
    this.order = 0

    /**
     * @type {string}
     */
    this.translationId = ''

    /**
     * @type {BasScenePreset}
     */
    this.template = BAS_SCENE_PRESETS.PRESETS[BAS_SCENE_PRESETS.K_UNKNOWN]

    /**
     * @type {boolean}
     */
    this.usesTemplateImage = true

    /**
     * @type {string}
     */
    this.colour = ''

    /**
     * This maps to the "favourite" property of Scene instance.
     *
     * @private
     * @type {boolean}
     */
    this._favourite = false

    /**
     * These images are used for displaying in memory images
     *
     * @type {Object}
     */
    this.images = {}

    /**
     * These images are used for displaying the custom preset images
     * These will contain serverImages or unsavedImages
     * Depending if there are unsavedImages
     *
     * @type {Object}
     */
    this.customImages = {}

    /**
     * This boolean keeps track if the custom preset is currently using images
     * Or using its default icon
     *
     * @type {boolean}
     */
    this.usesTemplateImageForCustomPreset = true

    /**
     * These images are the last known images for the custom preset
     * received from the API
     *
     * @type {?Object}
     */
    this.serverImages = null

    /**
     * This image is used for updating the custom images for the custom preset
     * This is only the body of the base64 image
     * If this is an empty string, then the current image will be cleared
     *
     * @type {?string} body of base64 image
     */
    this.unsavedImage = null

    /**
     * These images are used for displaying the unsavedImage
     * It is the same content but in a image object
     *
     * @type {?Object} image object with base64 images
     */
    this.unsavedImages = null

    /**
     * @type {string}
     */
    this.icon = ''

    /**
     * @type {string}
     */
    this.iconWText = ''

    /**
     * @type {string}
     */
    this.uiType = ''

    /**
     * @type {Object<string, BasStep>}
     */
    this.steps = {}

    /**
     * @type {string[]}
     */
    this.uiSteps = []

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this.resetCss()

    /**
     * @type {Object<string, string>}
     */
    this.borderBackgroundStyle = {}
    this.resetBorderBackgroundStyle()

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this._lastSceneActive = 0

    this.parseScene(this._getScene())
  }

  /**
   * @constant {number}
   */
  BasScene.MIN_KNX_NUMBER = 0

  /**
   * @constant {number}
   */
  BasScene.MAX_KNX_NUMBER = 63

  /**
   * @name BasScene#scene
   * @type {?Scene}
   * @readonly
   */
  Object.defineProperty(BasScene.prototype, 'scene', {
    get: function () {
      return this._getScene()
    }
  })

  /**
   * @name BasScene#favourite
   * @type {boolean}
   * @readonly
   */
  Object.defineProperty(BasScene.prototype, 'favourite', {
    get: function () {
      return this._favourite
    }
  })

  /**
   * @private
   * @returns {?Scene}
   */
  BasScene.prototype._getScene = function () {

    var sceneCtrl

    if (this._basRoom &&
      this._basRoom.room &&
      this._basRoom.room.sceneCtrl) {

      sceneCtrl = CurrentBasCore.getDevice(this._basRoom.room.sceneCtrl)

      if (sceneCtrl) return sceneCtrl.getScene(this.uuid)
    }

    return null
  }

  /**
   * @param {?string} image
   * @param {?string} imageDataURI
   */
  BasScene.prototype.setUnsavedImage = function (image, imageDataURI) {

    var images

    if (BasUtil.isNEString(imageDataURI) &&
      BasUtil.isNEString(image)) {

      images = {}
      images.imgw400 = imageDataURI

      this.unsavedImage = image
      this.unsavedImages = images

    } else {

      this.unsavedImage = ''
      this.unsavedImages = null
    }

    this.setScenePreset(
      BAS_SCENE_PRESETS.PRESETS[BAS_SCENE_PRESETS.K_CUSTOM]
    )
  }

  /**
   * @param {string} name
   */
  BasScene.prototype.setName = function (name) {

    this.name = BasUtil.isNEString(name) ? name : '-'
  }

  /**
   * @param {number} position
   */
  BasScene.prototype.setPosition = function (position) {

    if (BasUtil.isVNumber(position)) {

      this.position = position
      this._syncPositionCss()
    }
  }

  BasScene.prototype._syncPositionCss = function () {

    this.css[CSS_QUAD_POS_TL] = this.position === 0
    this.css[CSS_QUAD_POS_TR] = this.position === 1
    this.css[CSS_QUAD_POS_BL] = this.position === 2
    this.css[CSS_QUAD_POS_BR] = this.position === 3
  }

  /**
   * @private
   * @param {string} colour
   */
  BasScene.prototype._setColour = function (colour) {

    this.colour = BasUtil.isNEString(colour) ? colour : ''

    this.borderBackgroundStyle[P_BACKGROUND_COLOR] =
      BasUtil.isNEString(this.colour)
        ? colour
        : 'transparent'
  }

  /**
   * @returns {boolean}
   */
  BasScene.prototype.isEditable = function () {

    return this.css[CSS_CAN_EDIT]
  }

  /**
   * @returns {boolean}
   */
  BasScene.prototype.isRemoveable = function () {

    return this.css[CSS_CAN_REMOVE]
  }

  /**
   * @returns {boolean}
   */
  BasScene.prototype.canLearn = function () {

    return this.css[CSS_CAN_LEARN]
  }

  /**
   * @returns {boolean}
   */
  BasScene.prototype.isOnScene = function () {

    return this.template.isOn()
  }

  /**
   * @returns {boolean}
   */
  BasScene.prototype.isOffScene = function () {

    return this.template.isOff()
  }

  /**
   * @returns {boolean}
   */
  BasScene.prototype.isHomeScene = function () {

    return this.template.isHome()
  }

  /**
   * @returns {boolean}
   */
  BasScene.prototype.isAwayScene = function () {

    return this.template.isAway()
  }

  /**
   * @param {BasRoom} basRoom
   */
  BasScene.prototype.updateBasRoom = function (basRoom) {

    this._basRoom = basRoom
    this.parseScene(this._getScene())
  }

  /**
   * @param {Scene} scene
   */
  BasScene.prototype.parseScene = function (scene) {

    this._resetType()

    if (BasUtil.isObject(scene)) {

      let _isInternal = false
      let _canLearn = false
      let _canBeRemoved = false

      this.resetCss()

      this._syncPositionCss()

      this.uuid = scene.uuid

      if (BasUtil.isVNumber(scene.order)) {

        this.order = scene.order
      }

      if (scene.allowsRead(BAS_API.Scene.C_NAME)) {

        this.setName(scene.name)
      }

      // Check if serverImages are changed
      // Changing from null to an empty object does not count
      if (
        !BasUtil.compareObjects(this.serverImages, scene.serverImages) &&
        (
          BasUtil.isNEObject(this.serverImages) ||
          BasUtil.isNEObject(scene.serverImages)
        )
      ) {

        // Clear all unsaved images
        this.unsavedImage = null
        this.unsavedImages = null

        // Save new serverImages
        this.serverImages =
          BasUtil.isNEObject(scene.serverImages)
            ? scene.serverImages
            : null
      }

      // Check if custom images may be used
      if (
        this.unsavedImages === null &&
        BasUtil.isNEObject(this.serverImages)) {

        this.css[CSS_HAS_CUSTOM_SERVER_IMAGES] = true
      }

      if (scene.allowsRead(BAS_API.Scene.C_COLOUR)) {

        this._setColour(scene.colour)

      } else {

        this._setColour('')
      }

      if (scene.allowsRead(BAS_API.Scene.C_TEMPLATE)) {

        this.syncScenePreset(
          BasScenePresetsHelper.getPreset(scene.template)
        )

        if (scene.allowsWrite(BAS_API.Scene.C_TEMPLATE)) {

          this.css[CSS_CAN_TEMPLATE] = true
          this.css[CSS_CAN_EDIT] = true
        }
      } else {

        this.template = BAS_SCENE_PRESETS.PRESETS[BAS_SCENE_PRESETS.K_UNKNOWN]
        this.translationId = ''
        this.customImages = {}
        this.usesTemplateImageForCustomPreset = true

        this.icon = ''
        this.iconWText = ''
        this.images = {}
        this.usesTemplateImage = true
      }

      if (scene.allowsRead(BAS_API.Scene.C_FAVOURITE)) {

        if (scene.allowsWrite(BAS_API.Scene.C_FAVOURITE)) {

          this.css[CSS_CAN_FAVOURITE] = true
        }
      }

      if (scene.allowsRead(BAS_API.Scene.C_CONTENT)) {

        this.parseSteps(scene.steps)

        if (scene.allowsWrite(BAS_API.Scene.C_CONTENT)) {

          _isInternal = true

          this.css[CSS_CAN_CONTENT] = true
        }
      } else {

        this.steps = {}
      }

      if (scene.allowsExecute(BAS_API.Scene.C_ACTIVATE)) {

        this.css[CSS_CAN_ACTIVATE] = true
      }

      if (scene.allowsExecute(BAS_API.Scene.C_LEARN)) {

        _canLearn = true

        this.css[CSS_CAN_LEARN] = true
      }

      if (scene.allowsExecute(BAS_API.Scene.C_REMOVE)) {

        _canBeRemoved = true

        this.css[CSS_CAN_REMOVE] = true
      }

      if (_isInternal) {

        this._setType(
          _canBeRemoved
            ? BAS_SCENE.T_INTERNAL
            : BAS_SCENE.T_INTERNAL_KNX
        )

      } else if (_canLearn) {

        this._setType(BAS_SCENE.T_EXTERNAL)
      }
    }

    this._syncUiTypeBadge()
  }

  BasScene.prototype.canWriteFavourite = function () {

    var _scene = this._getScene()

    return _scene && _scene.allowsWrite(BAS_API.Scene.C_FAVOURITE)
  }

  BasScene.prototype.canWriteContent = function () {

    var _scene = this._getScene()

    return _scene && _scene.allowsWrite(BAS_API.Scene.C_CONTENT)
  }

  BasScene.prototype.canRemove = function () {

    var _scene = this._getScene()

    return _scene && _scene.allowsExecute(BAS_API.Scene.C_REMOVE)
  }

  BasScene.prototype.canWriteName = function () {

    var _scene = this._getScene()

    return _scene && _scene.allowsWrite(BAS_API.Scene.C_NAME)
  }

  BasScene.prototype.canWriteTemplate = function () {

    var _scene = this._getScene()

    return _scene && _scene.allowsWrite(BAS_API.Scene.C_TEMPLATE)
  }

  BasScene.prototype.setTranslationId = function (transId) {

    this.translationId = BasUtil.isNEString(transId)
      ? transId
      : ''

    this.updateTranslation()
  }

  BasScene.prototype.updateTranslation = function () {

    var i, length, step

    if (!this.template.isCustom() &&
      BasUtil.isNEString(this.translationId)) {

      this.setName(BasUtilities.translate(this.translationId))
    }

    this._syncUiTypeBadge()

    length = this.uiSteps.length
    for (i = 0; i < length; i++) {

      step = this.steps[this.uiSteps[i]]
      if (step) step.updateTranslation()
    }
  }

  BasScene.prototype.updateTemperatureUnit = function () {

    var i, length, step

    length = this.uiSteps.length
    for (i = 0; i < length; i++) {

      step = this.steps[this.uiSteps[i]]
      if (step) step.updateTemperatureUnit()
    }
  }

  /**
   * @param {BasScenePreset} scenePreset
   */
  BasScene.prototype.setScenePreset = function (scenePreset) {

    if (BasUtil.isObject(scenePreset)) {

      this.syncScenePreset(scenePreset)
    }
  }

  /**
   * Write current scene to underlying Scene
   */
  BasScene.prototype.commit = function () {

    var scene = this._getScene()

    if (scene) {

      scene.setFavourite(this.favourite)
      scene.setTemplate(this.template.number)
      scene.setName(this.name)
      scene.setSteps(this._makeApiSteps())
    }
  }

  /**
   * @param {BasScenePreset} scenePreset
   */
  BasScene.prototype.syncScenePreset = function (scenePreset) {

    if (BasUtil.isObject(scenePreset)) {

      this.template = scenePreset
      this.setTranslationId(scenePreset.translationId)

      // Determinate images for custom preset
      this.customImages = null
      this.usesTemplateImageForCustomPreset = true

      if (this.css[CSS_HAS_CUSTOM_SERVER_IMAGES] &&
        !BasUtil.isString(this.unsavedImage)) {

        this.customImages = this.serverImages
        this.usesTemplateImageForCustomPreset = false

      } else if (this.unsavedImages) {

        this.customImages = this.unsavedImages
        this.usesTemplateImageForCustomPreset = false
      }

      // Determinate images for current preset
      if (scenePreset.isCustom() && !this.usesTemplateImageForCustomPreset) {

        this.icon = ''
        this.iconWText = ''
        this.images = this.customImages
        this.usesTemplateImage = false

      } else {

        this.icon = scenePreset.icon
        this.iconWText = scenePreset.iconWText
        this.images = scenePreset.images
        this.usesTemplateImage = true
      }

      this.css[CSS_CAN_NAME] = scenePreset.isCustom()
      this.css[CSS_CAN_IMAGE] = scenePreset.isCustom()
    }
  }

  /**
   * @param {string} stepId
   * @returns {?BasStep}
   */
  BasScene.prototype.getBasStep = function (stepId) {

    var keys, i, length, step

    keys = Object.keys(this.steps)
    length = keys.length
    for (i = 0; i < length; i++) {

      step = this.steps[keys[i]]

      if (step && step.uuid === stepId) return step
    }

    return null
  }

  BasScene.prototype.syncStepsUi = function () {

    var i, length, step

    length = this.uiSteps.length
    for (i = 0; i < length; i++) {

      step = this.steps[this.uiSteps[i]]
      if (step) step.syncUi()
    }
  }

  /**
   * This resets to the last known API config
   */
  BasScene.prototype.resetScene = function () {

    this.unsavedImage = null
    this.unsavedImages = null

    this.parseScene(this._getScene())
  }

  /**
   * This resets to the last known API config
   */
  BasScene.prototype.resetSceneServer = function () {

    var scene = this._getScene()

    if (scene) {

      scene.resetToServerValues()
      this.parseScene(scene)
    }
  }

  /**
   * @param {TStep[]} steps
   */
  BasScene.prototype.parseSteps = function (steps) {

    var i, length

    if (Array.isArray(steps)) {

      this.steps = {}
      this.uiSteps = []

      length = steps.length
      for (i = 0; i < length; i++) {

        this.addStep(new BasStep('' + i, steps[i]))
      }
    }
  }

  BasScene.prototype.mergeDelays = function () {

    var i, length, step, currentStep, delay, lastIndex

    length = this.uiSteps.length
    for (i = 0; i < length; i++) {

      step = this.steps[this.uiSteps[i]]

      if (step && step.isDelayStep()) {

        if (currentStep) {

          delay = currentStep.delay + step.delay
          currentStep.setDelay(delay)
          this.deleteStep(this.uiSteps[i])

          length--
          i--

        } else {

          currentStep = step
        }

      } else {

        currentStep = null
      }
    }

    lastIndex = length - 1

    if (this.steps[this.uiSteps[lastIndex]]) {
      step = this.steps[this.uiSteps[lastIndex]]

      if (step.isDelayStep()) {
        this.deleteStep(this.uiSteps[lastIndex])
      }
    }
  }

  BasScene.prototype.removeUselessSteps = function () {

    var i, length, step, steps

    length = this.uiSteps.length
    steps = []

    for (i = 0; i < length; i++) {

      step = this.steps[this.uiSteps[i]]

      if (step && (step.isFunctionStep() || step.isDelayStep())) {

        steps.push(this.uiSteps[i])

      } else {

        this.steps[this.uiSteps[i]] = null
      }
    }

    this.uiSteps = steps
  }

  /**
   * @private
   * @returns {TStep[]}
   */
  BasScene.prototype._makeApiSteps = function () {

    var sceneSteps, i, length, step

    sceneSteps = []

    length = this.uiSteps.length
    for (i = 0; i < length; i++) {

      step = this.steps[this.uiSteps[i]]

      if (BasUtil.isObject(step)) sceneSteps.push(step.getApiStep())
    }

    return sceneSteps
  }

  BasScene.prototype.makeDataString = function () {

    var dataString, i, length, step

    dataString = ''

    if (this.template.isCustom()) {

      dataString += this.name

    } else {

      dataString += this.template.number
    }

    length = this.uiSteps.length
    for (i = 0; i < length; i++) {

      step = this.steps[this.uiSteps[i]]

      if (BasUtil.isObject(step)) dataString += step.makeDataString()
    }

    if (BasUtil.isString(this.unsavedImage)) {
      dataString += this.unsavedImage.length
    }

    return dataString
  }

  BasScene.prototype.activate = function (sceneCtrlUuid) {

    const _this = this

    this.css[CSS_SPINNER_VISIBLE] = true

    const now = Date.now()
    this._lastSceneActivate = now

    // Show spinner for at least 1 second, keep it visible until scene
    //  has been activated
    return Promise.all([
      BasCommandQueue.sceneActivate(sceneCtrlUuid, this.uuid),
      new Promise(function (resolve) {
        setTimeout(resolve, UI_ACTIVE_REMOVE_SPINNER_DELAY_MS)
      })
    ])
      .catch(reason => {
        // Rethrow for handling in UI
        return Promise.reject(reason)
      })
      .finally(() => {
        // Hide spinner
        if (now !== _this._lastSceneActivate) return
        _this.css[CSS_SPINNER_VISIBLE] = false
        $rootScope.$applyAsync()
      })
  }

  /**
   * Creates a new step
   *
   * @returns {BasStep}
   */
  BasScene.prototype.createNewStep = function () {

    var step = this.addNewStep()

    step.updateTitle()
    step.updateSummary()

    return step
  }

  BasScene.prototype.createNewTimer = function () {

    var timer = this.addNewStep()

    timer.setDelay(DEFAULT_TIMER)
  }

  BasScene.prototype.listSwipe = function (index) {

    if (this.uiSteps[index]) this.deleteStep(this.uiSteps[index])
  }

  /**
   * @param {number} spliceIdx
   * @param {number} originalIdx
   */
  BasScene.prototype.reorderSteps = function (spliceIdx, originalIdx) {

    var originalId

    originalId = this.uiSteps[originalIdx]

    if (originalId) {

      this.uiSteps.splice(originalIdx, 1)
      this.uiSteps.splice(spliceIdx, 0, originalId)
    }
  }

  /**
   * Deletes a step (and from the UI steps if added there)
   *
   * @param {string} stepId
   */
  BasScene.prototype.deleteStep = function (stepId) {

    var step

    // Remove step reference
    step = this.steps[stepId]
    if (step) this.steps[stepId] = null

    // Remove step ID from UI array
    step = this.uiSteps.indexOf(stepId)
    if (step !== -1) this.uiSteps.splice(step, 1)
  }

  /**
   * Adds a new step and returns the new step instance
   *
   * @returns {BasStep}
   */
  BasScene.prototype.addNewStep = function () {

    var newStep

    newStep = new BasStep(this._findNewIndex() + '', null)

    this.addStep(newStep)

    return newStep
  }

  /**
   * @private
   * @returns {number}
   */
  BasScene.prototype._findNewIndex = function () {

    var i = 0

    while (i < MAX_INDEX) {

      if (this.uiSteps.indexOf(i + '') === -1) return i
      i++
    }
  }

  /**
   * @param {BasStep} step
   */
  BasScene.prototype.addStep = function (step) {

    if (step instanceof BasStep) {

      this.steps[step.uuid] = step
      this.addUiStep(step.uuid)
    }
  }

  BasScene.prototype.syncSteps = function () {

    for (const step of Object.values(this.steps)) {
      step.syncAllControlTypes()
    }
  }

  /**
   * Adds a step UUID to ui steps if the step exists
   *
   * @param {string} stepId
   */
  BasScene.prototype.addUiStep = function (stepId) {

    var step = this.steps[stepId]

    if (step instanceof BasStep &&
      this.uiSteps.indexOf(stepId) === -1) {

      this.uiSteps.push(stepId)
    }
  }

  BasScene.prototype._syncUiTypeBadge = function () {

    this.uiType = ''

    switch (this.type) {
      case BAS_SCENE.T_INTERNAL_KNX:
        this.uiType = BasUtilities.translate('internal')
        break
      case BAS_SCENE.T_EXTERNAL:
        this.uiType = BasUtilities.translate('external')
        break
      default:
        this.uiType = ''
    }
  }

  /**
   * @private
   * @param {string} type
   */
  BasScene.prototype._setType = function (type) {

    this._resetType()

    switch (type) {
      case BAS_SCENE.T_INTERNAL:
      case BAS_SCENE.T_INTERNAL_KNX:
      case BAS_SCENE.T_EXTERNAL:
        this.type = type
    }

    this.css[CSS_HAS_TYPE_BADGE] =
      this.type === BAS_SCENE.T_INTERNAL_KNX ||
      this.type === BAS_SCENE.T_EXTERNAL

    this._syncUiTypeBadge()
  }

  BasScene.prototype._resetType = function () {

    this.type = ''

    this.css[CSS_HAS_TYPE_BADGE] = false
  }

  BasScene.prototype.resetBorderBackgroundStyle = function () {

    this.borderBackgroundStyle[P_BACKGROUND_COLOR] = 'transparent'
  }

  BasScene.prototype.resetCss = function resetCss () {

    // Don't reset CSS_SPINNER_VISIBLE, this should survive server reconnects

    this.css[CSS_QUAD_POS_TL] = false
    this.css[CSS_QUAD_POS_TR] = false
    this.css[CSS_QUAD_POS_BL] = false
    this.css[CSS_QUAD_POS_BR] = false
    this.css[CSS_CAN_ACTIVATE] = false
    this.css[CSS_CAN_LEARN] = false
    this.css[CSS_CAN_REMOVE] = false
    this.css[CSS_CAN_NAME] = false
    this.css[CSS_CAN_IMAGE] = false
    this.css[CSS_CAN_TEMPLATE] = false
    this.css[CSS_CAN_EDIT] = false
    this.css[CSS_CAN_FAVOURITE] = false
    this.css[CSS_CAN_CONTENT] = false
    this.css[CSS_HAS_TYPE_BADGE] = false
    this.css[CSS_HAS_CUSTOM_SERVER_IMAGES] = false
  }

  return BasScene
}
