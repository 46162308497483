'use strict'

import BasMemoryStorage from './basmemorystorage'
import basStorageUtil from './util.basstorage'
import * as BasUtil from '@basalte/bas-util'

angular
  .module('basStorage', [])
  .provider('BasStorage', [
    '$windowProvider',
    BasStorageProvider
  ])

/**
 * @typedef {Object} TStorageOptions
 * @property {boolean} [prefix = true]
 * @property {boolean} [json = true]
 */

/**
 * @typedef {Object} TTStorageOptions
 * @property {boolean} prefix
 * @property {boolean} json
 */

/**
 * @param $windowProvider
 */
function BasStorageProvider ($windowProvider) {

  var _window, _storageHandle, _prefix

  _prefix = ''

  _window = $windowProvider.$get()

  _storageHandle = basStorageUtil.getStorageHandle(_window)
  if (!_storageHandle) _storageHandle = new BasMemoryStorage()

  /**
   * Returns the current configured prefix
   *
   * @returns {string} prefix
   */
  this.getPrefix = function getPrefix () {
    return _prefix
  }

  /**
   * Set a prefix for every storage key
   *
   * Passing no arguments or an invalid argument
   * results in the clearing the prefix
   *
   * @param {string} [newPrefix]
   */
  this.setPrefix = function setPrefix (newPrefix) {
    _prefix = BasUtil.isString(newPrefix) ? newPrefix : ''
  }

  this.$get = ['$injector', BasStorageFactory]

  // Storage factory

  function BasStorageFactory ($injector) {
    return $injector.instantiate(BasStorage)
  }

  /**
   * Service that handles as a wrapper for
   * HTML5 localStorage and SessionStorage
   *
   * @constructor
   */
  function BasStorage () {

    this.clear = clear
    this.set = set
    this.get = get
    this.remove = remove
    this.getStorageHandle = getStorageHandle

    /**
     * Set a key value pair
     *
     * @param {string} key
     * @param value
     * @param {TStorageOptions} [options]
     */
    function set (key, value, options) {
      var _opts
      _opts = _parseOptions(options)
      _storageHandle.setItem(
        _opts.prefix ? _prefix + key : key,
        _opts.json ? _serializer(value) : value
      )
    }

    /**
     * Get a value from the localStorage
     *
     * @param {string} key
     * @param {TStorageOptions} [options]
     * @returns {any}
     */
    function get (key, options) {
      var _value, _opts
      _opts = _parseOptions(options)
      _value = _storageHandle.getItem(
        _opts.prefix ? _prefix + key : key
      )
      return _opts.json ? _deserializer(_value) : _value
    }

    /**
     * Remove an item based on key
     *
     * @param {string} key
     * @param {TStorageOptions} [options]
     */
    function remove (key, options) {
      var _opts
      _opts = _parseOptions(options)
      _storageHandle.removeItem(
        _opts.prefix ? _prefix + key : key
      )
    }

    /**
     * Clears the storage, removing all keys
     */
    function clear () {
      _storageHandle.clear()
    }

    /**
     * @returns {(Storage|BasNativeStorage|BasMemoryStorage)}
     */
    function getStorageHandle () {
      return _storageHandle
    }

    /**
     * @private
     * @param value
     * @returns {string}
     */
    function _serializer (value) {
      return BasUtil.isObject(value)
        ? JSON.stringify(value)
        : value
    }

    /**
     * @private
     * @param value
     * @returns {any}
     */
    function _deserializer (value) {
      if (BasUtil.isNEString(value)) {
        try {
          return JSON.parse(value)
        } catch (error) {
          return value
        }
      }
      return value
    }

    /**
     * @private
     * @param {TStorageOptions} options
     * @returns {TTStorageOptions}
     */
    function _parseOptions (options) {
      var result
      result = {
        prefix: true,
        json: true
      }
      if (BasUtil.isObject(options)) {
        if (BasUtil.isBool(options.prefix)) {
          result.prefix = options.prefix
        }
        if (BasUtil.isBool(options.json)) {
          result.json = options.json
        }
      }
      return result
    }
  }
}
