'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceStartupView', [
    'BAS_HTML',
    startUpView
  ])

function startUpView (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.startupViewDeviceSettings,
    controller: [
      '$rootScope',
      'BAS_CORE',
      'BAS_PREFERENCES',
      'BAS_ROOMS',
      'BAS_INPUT_MODAL',
      'ICONS',
      'BasPreferences',
      'BasInputModal',
      'CurrentBasCore',
      'BasUtilities',
      controller
    ],
    controllerAs: 'startupView'
  }

  /**
   * @param $rootScope
   * @param {BAS_CORE} BAS_CORE
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BAS_ROOMS} BAS_ROOMS
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {ICONS} ICONS
   * @param {BasPreferences} BasPreferences
   * @param {BasInputModal} BasInputModal
   * @param {CurrentBasCore} CurrentBasCore
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    BAS_CORE,
    BAS_PREFERENCES,
    BAS_ROOMS,
    BAS_INPUT_MODAL,
    ICONS,
    BasPreferences,
    BasInputModal,
    CurrentBasCore,
    BasUtilities
  ) {

    var startupView = this

    var _listeners = []

    var roomOptions = []

    /**
     * @type {TCurrentBasCoreState}
     */
    var currentBasCoreState = CurrentBasCore.get()

    /**
     * @type {BasRooms}
     */
    startupView.rooms = BAS_ROOMS.ROOMS

    /**
     * @type {BAS_PREFERENCES}
     */
    startupView.BAS_PREFERENCES = BAS_PREFERENCES

    startupView.VIEWS = []
    startupView.ROOM_OPTIONS = []
    startupView.startupView = ''

    startupView.updateStartupView = updateStartupView
    startupView.showEditStartupView = showEditStartupView
    startupView.$onDestroy = _destroy

    init()

    function init () {

      _listeners.push($rootScope.$on(
        BAS_CORE.EVT_CORE_CORE_CONNECTED,
        _onCoreConnected
      ))

      _listeners.push($rootScope.$on(
        BAS_CORE.EVT_CORE_PROFILE_CREATED,
        _onProfileCreated
      ))

      _listeners.push($rootScope.$on(
        BAS_ROOMS.EVT_ROOMS_UPDATED,
        _onRoomsUpdated
      ))

      _listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        _onLanguageChanged
      ))

      _onRoomsUpdated()
      _generateStartupViewOptions()
      _syncCurrentStartupViewOption()
    }

    function updateStartupView () {

      BasPreferences.setStartupView(
        startupView.startupView,
        currentBasCoreState.core
      )

      _syncCurrentStartupViewOption()
    }

    function _syncCurrentStartupViewOption () {

      startupView.startupView = BasPreferences.getStartupView()
      _syncCurrentStartupViewOptionUi()
    }

    function _syncCurrentStartupViewOptionUi () {

      startupView.uiStartupView = _textForViewOption(startupView.startupView)
    }

    function _generateStartupViewOptions () {

      startupView.VIEWS = []
      startupView.ROOM_OPTIONS = []

      if (CurrentBasCore.has()) {

        if (CurrentBasCore.hasHomePage() && !CurrentBasCore.isAudioOnly()) {

          startupView.VIEWS.push({
            value: BAS_PREFERENCES.VIEW_HOME,
            text: BasUtilities.translate('home')
          })
        }

        startupView.VIEWS.push({
          value: BAS_PREFERENCES.VIEW_ROOMS,
          text: BasUtilities.translate('rooms')
        })

        startupView.VIEWS.push({
          value: BAS_PREFERENCES.VIEW_LAST_ROOM,
          text: BasUtilities.translate('last_room')
        })

        startupView.VIEWS.push({
          value: BAS_PREFERENCES.VIEW_SINGLE_ROOM,
          text: BasUtilities.translate('single_room'),
          icon: ICONS.rightArrow
        })

        startupView.ROOM_OPTIONS = roomOptions
      }
    }

    function _updateRoomList () {

      var uiAllCollection, floorLength, length, floor, uuid, room, k, i

      roomOptions = []

      uiAllCollection = startupView.rooms.home.uiAllCollection
      floorLength = uiAllCollection.length
      for (k = 0; k < floorLength; k++) {
        floor = uiAllCollection[k]

        roomOptions.push({
          text: floor.basTitle.value,
          title: true
        })

        length = floor.items.length
        for (i = 0; i < length; i++) {

          uuid = floor.items[i]

          if (uuid) {

            room = startupView.rooms.rooms[uuid]

            if (room && room.isRoom) {

              roomOptions.push({
                value: uuid,
                text: room.uiTitle
              })
            }
          }
        }
      }
    }

    function _onLanguageChanged () {

      _generateStartupViewOptions()
      _syncCurrentStartupViewOptionUi()
    }

    function _onCoreConnected () {

      _generateStartupViewOptions()
    }

    function _onProfileCreated () {

      _syncCurrentStartupViewOption()
      _generateStartupViewOptions()
    }

    function _onRoomsUpdated () {

      _syncCurrentStartupViewOptionUi()
      _updateRoomList()
      _generateStartupViewOptions()
    }

    function showEditStartupView () {

      var autoSave

      autoSave = true

      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'startup_view',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            title: 'startup_view',
            options: startupView.VIEWS,
            initialValue: startupView.startupView.view
          },
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            title: 'room',
            options: startupView.ROOM_OPTIONS,
            initialValue: startupView.startupView.room
          }
        ],
        autoAdvance: true,
        allowDismiss: true,
        allowGoBack: true,
        clearAllOnBack: autoSave,
        autoSave: autoSave,
        useDynamicHeight: true,
        validCheck: _inputIsValid
      }).then(_onModalEditStartupView)
    }

    function _onModalEditStartupView (modal) {

      modal.close.then(_onModalEditStartupViewClose)
    }

    function _onModalEditStartupViewClose (result) {

      if (BasUtil.isNEObject(result)) {

        startupView.startupView = {
          view: result[0]
        }

        if (result[0] === BAS_PREFERENCES.VIEW_SINGLE_ROOM) {

          startupView.startupView.room = result[1]
        }

        updateStartupView()
      }
    }

    function _textForViewOption (setting) {

      var result, room

      switch (setting.view) {
        case BAS_PREFERENCES.VIEW_HOME:
          result = BasUtilities.translate('home')
          break
        case BAS_PREFERENCES.VIEW_ROOMS:
          result = BasUtilities.translate('rooms')
          break
        case BAS_PREFERENCES.VIEW_SINGLE_ROOM:
          result = BasUtilities.translate('single_room')

          if (setting.room) {

            room = startupView.rooms.rooms[setting.room]

            if (room && room.isRoom) {

              result += ': ' + room.uiTitle
            }
          }
          break
        case BAS_PREFERENCES.VIEW_LAST_ROOM:
          result = BasUtilities.translate('last_room')
          break
        default:
          result = '-'
      }

      return result
    }

    function _inputIsValid (values) {

      if (BasUtil.isObject(values) && BasUtil.isPNumber(values[0])) {

        switch (values[0]) {
          case BAS_PREFERENCES.VIEW_SINGLE_ROOM:
            return BasUtil.isNEString(values[1])
          case BAS_PREFERENCES.VIEW_LAST_ROOM:
          case BAS_PREFERENCES.VIEW_ROOMS:
          case BAS_PREFERENCES.VIEW_HOME:
            return true
        }
      }

      return false
    }

    function _destroy () {

      BasUtil.executeArray(_listeners)
      _listeners = null
    }
  }
}
