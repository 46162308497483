'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basHomeScroller', basHomeScroller)

function basHomeScroller () {
  return {
    restrict: 'A',
    controller: [
      '$element',
      'BAS_STATE',
      'BasAppDevice',
      'BasUtilities',
      controller
    ],
    bindToController: {
      basHomeScroller: '<',
      onBasViewChange: '&'
    }
  }

  /**
   * @param $element
   * @param {BAS_STATE} BAS_STATE
   * @param {BasAppDevice} BasAppDevice
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $element,
    BAS_STATE,
    BasAppDevice,
    BasUtilities
  ) {
    var basHomeScrollerCtrl = this

    /**
     * @type {BasAppDeviceState}
     */
    var basAppDeviceState = BasAppDevice.get()

    var CSS_DISABLE_SCROLL_SNAP = 'disable-scroll-snap'
    var CSS_POINTER_EVENTS_NONE = 'pointer-events-none'

    var DISABLE_SCROLL_SNAP_DURING_ANIMATION = (
      (
        basAppDeviceState.safariVersion &&
        BasUtil.BasVersion.compare(
          basAppDeviceState.safariVersion,
          '15.0.0'
        ) >= 0
      ) ||
      (
        basAppDeviceState.iosVersion &&
        BasUtil.BasVersion.compare(
          basAppDeviceState.iosVersion,
          '15.0.0'
        ) >= 0
      )
    )

    var _isDestroyed = false

    var useRafAnimation = (
      BasAppDevice.isIos() ||
      BasAppDevice.isSafari()
    )

    /**
     * @private
     * @type {number[]}
     */
    var _ongoingTouches = []

    var _waitDomTimeoutId = 0
    var _debounceScrollTimeoutId = 0

    /**
     * @private
     * @type {?TBasSmoothScrollVerticalOperation}
     */
    var _scrollOperation
    var _listeners = []

    basHomeScrollerCtrl.$onDestroy = _onDestroy
    basHomeScrollerCtrl.$onChanges = _onChanges
    basHomeScrollerCtrl.$postLink = _onPostLink

    function _onChanges (changes) {

      if (changes.basHomeScroller) {

        if (changes.basHomeScroller.isFirstChange()) {
          _elementScroll(
            changes.basHomeScroller.currentValue,
            changes.basHomeScroller.isFirstChange()
          )
        } else {

          if (
            _ongoingTouches.length === 0 &&
            _debounceScrollTimeoutId === 0
          ) {
            _elementScroll(
              changes.basHomeScroller.currentValue,
              changes.basHomeScroller.isFirstChange()
            )
          }
        }
      }
    }

    function _onPostLink () {

      _waitDomTimeoutId = setTimeout(_onDomTimeout)

      if (BasUtil.isString(basHomeScrollerCtrl.basHomeScroller)) {
        if (_ongoingTouches.length === 0) {
          _elementScroll(basHomeScrollerCtrl.basHomeScroller, true)
        }
      }
    }

    function _onDomTimeout () {

      if (_isDestroyed) return

      BasUtilities.waitForFrames(2, _onDomFrames)

      if (BasUtil.isString(basHomeScrollerCtrl.basHomeScroller)) {
        if (_ongoingTouches.length === 0) {
          _elementScroll(basHomeScrollerCtrl.basHomeScroller, true)
        }
      }
    }

    function _onDomFrames () {

      if (_isDestroyed) return

      if (BasUtil.isString(basHomeScrollerCtrl.basHomeScroller)) {
        if (_ongoingTouches.length === 0) {
          _elementScroll(basHomeScrollerCtrl.basHomeScroller, true)
        }
      }
      _setListeners()
    }

    /**
     * @private
     * @param {TouchEvent} event
     */
    function _onTouchStart (event) {

      var touches, length, i, touch

      if (_scrollOperation) _scrollOperation.abort()

      touches = event.changedTouches
      length = touches.length
      for (i = 0; i < length; i++) {
        touch = touches[i]
        if (_ongoingTouches.indexOf(touch.identifier) < 0) {
          _ongoingTouches.push(touch.identifier)
        }
      }
    }

    /**
     * @private
     * @param {TouchEvent} event
     */
    function _onTouchEnd (event) {
      _doTouchEnd(event)
    }

    /**
     * @private
     * @param {TouchEvent} event
     */
    function _onTouchCancel (event) {
      _doTouchEnd(event)
    }

    /**
     * @private
     * @param {TouchEvent} event
     */
    function _doTouchEnd (event) {

      var touches, length, i, idx, el

      touches = event.changedTouches
      length = touches.length
      for (i = 0; i < length; i++) {

        idx = _getOngoingTouchesIndexById(touches[i].identifier)

        if (idx > -1) {

          _ongoingTouches.splice(idx, 1)
        }
      }

      el = $element[0]

      // Re-enable pointer events and scroll-snap
      if (el) clearAnimationCss()
    }

    function _onScroll () {

      clearTimeout(_debounceScrollTimeoutId)
      _debounceScrollTimeoutId = setTimeout(_onScrollTimeout, 200)
    }

    function _onScrollTimeout () {

      if (_isDestroyed) return

      _debounceScrollTimeoutId = 0

      _afterScroll()
    }

    function _afterScroll () {

      var elem, height, scrollTop

      if (
        _debounceScrollTimeoutId === 0 &&
        _ongoingTouches.length === 0
      ) {
        elem = $element[0]

        if (elem) {

          height = elem.offsetHeight
          scrollTop = elem.scrollTop

          // Also count as scroll if movement was more than 10% of height
          if (scrollTop === 0 || scrollTop > (0.1 * height)) {

            if (BasUtil.isFunction(basHomeScrollerCtrl.onBasViewChange)) {

              basHomeScrollerCtrl.onBasViewChange({
                view: (scrollTop < (height / 2))
                  ? BAS_STATE.S_HOME_VIEW_DASHBOARD
                  : BAS_STATE.S_HOME_VIEW_OPTIONS
              })
            }
          }
        }
      }
    }

    /**
     * @private
     * @param {string} view
     * @param {boolean} [doInstant = false]
     */
    function _elementScroll (view, doInstant) {

      var _doInstant, elem, height

      if (_scrollOperation) _scrollOperation.abort()

      _doInstant = BasUtil.isBool(doInstant) ? doInstant : false
      elem = $element[0]

      if (elem) {

        height = elem.offsetHeight

        switch (view) {
          case BAS_STATE.S_HOME_VIEW_OPTIONS:
            scrollTo(elem, height, _doInstant)
            break
          case BAS_STATE.S_HOME_VIEW_DASHBOARD:
          default:
            scrollTo(elem, 0, _doInstant)
            break
        }
      }
    }

    function scrollTo (element, top, _doInstant) {

      if (top === element.scrollTop) return

      if (_doInstant) {

        if (BasUtil.isFunction(element.scroll)) {

          element.scroll(0, top)

        } else {

          element.scrollTop = top
        }
      } else {

        if (useRafAnimation) {

          setAnimationCss()

          _scrollOperation =
            BasUtilities.smoothScrollVertical(
              element,
              top,
              onSmoothScrollVerticalCallback
            )

        } else {

          if (BasUtil.isFunction(element.scroll)) {
            element.scroll({
              top: top,
              left: 0,
              behavior: 'smooth'
            })

          } else {

            setAnimationCss()

            _scrollOperation = BasUtilities.smoothScrollVertical(
              element,
              top,
              onSmoothScrollVerticalCallback
            )
          }
        }
      }

      function onSmoothScrollVerticalCallback (operation) {

        // If scroll operation was aborted by user interaction, wait until
        //  touch end to remove css class (currently it will never be aborted
        //  since pointer events are disabled during SmoothScrollVertical
        //  animations)
        if (operation && !operation.aborted) {

          clearAnimationCss()
        }
      }
    }

    /**
     * @param {number} id
     * @returns {number}
     */
    function _getOngoingTouchesIndexById (id) {

      var length, i

      length = _ongoingTouches.length
      for (i = 0; i < length; i++) {
        if (_ongoingTouches[i] === id) return i
      }
      return -1
    }

    function _setListeners () {

      var elem

      elem = $element[0]

      if (elem) {

        _listeners.push(BasUtil.setDOMListener(
          elem,
          'touchstart',
          _onTouchStart,
          {
            passive: true
          }
        ))
        _listeners.push(BasUtil.setDOMListener(
          elem,
          'touchend',
          _onTouchEnd,
          {
            passive: true
          }
        ))
        _listeners.push(BasUtil.setDOMListener(
          elem,
          'touchcancel',
          _onTouchCancel,
          {
            passive: true
          }
        ))
        _listeners.push(BasUtil.setDOMListener(
          elem,
          'scroll',
          _onScroll,
          {
            passive: true
          }
        ))
      }
    }

    function setAnimationCss () {

      var element

      element = $element[0]

      if (element) {

        // Disable pointer events so user cannot mess with the animation
        element.classList.add(CSS_POINTER_EVENTS_NONE)

        // If iOS or Safari 15, disable scroll snap during animation. We don't
        //  do this on lower iOS versions since they incorrectly reset the
        //  scrollTop to 0 when re-enabling this, instead of snapping to the
        //  closest element.

        if (DISABLE_SCROLL_SNAP_DURING_ANIMATION) {

          element.classList.add(CSS_DISABLE_SCROLL_SNAP)
        }
      }
    }

    function clearAnimationCss () {

      var element

      element = $element[0]

      if (element) {

        element.classList.remove(
          CSS_POINTER_EVENTS_NONE,
          CSS_DISABLE_SCROLL_SNAP
        )
      }
    }

    function _onDestroy () {

      _isDestroyed = true

      if (_scrollOperation) _scrollOperation.abort()
      clearTimeout(_waitDomTimeoutId)
      clearTimeout(_debounceScrollTimeoutId)
      BasUtil.execute(_listeners)
      _listeners = []
    }
  }
}
