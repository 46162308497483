'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasHomeDashboard', [
    '$rootScope',
    'BAS_HOME_DASHBOARD',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_OPEN_CLOSE_DEVICES',
    'BAS_SIP',
    'UiBasTiles',
    'RoomsHelper',
    'BasOpenCloseDevices',
    'BasCameras',
    'BasIntercomHelper',
    'CurrentBasCore',
    BasHomeDashboard
  ])

/**
 * @typedef {Object} TBasHomeDashboard
 * @property {BasTile[]} top
 * @property {BasTile[]} bottom
 */

/**
 * Service which handles the home dashboard
 *
 * @constructor
 * @param $rootScope
 * @param {BAS_HOME_DASHBOARD} BAS_HOME_DASHBOARD
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_OPEN_CLOSE_DEVICES} BAS_OPEN_CLOSE_DEVICES
 * @param {BAS_SIP} BAS_SIP
 * @param {UiBasTiles} UiBasTiles
 * @param {RoomsHelper} RoomsHelper
 * @param {BasOpenCloseDevices} BasOpenCloseDevices
 * @param {BasCameras} BasCameras
 * @param {BasIntercomHelper} BasIntercomHelper
 * @param {CurrentBasCore} CurrentBasCore
 */
function BasHomeDashboard (
  $rootScope,
  BAS_HOME_DASHBOARD,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_OPEN_CLOSE_DEVICES,
  BAS_SIP,
  UiBasTiles,
  RoomsHelper,
  BasOpenCloseDevices,
  BasCameras,
  BasIntercomHelper,
  CurrentBasCore
) {
  /**
   * @type {TBasHomeDashboard}
   */
  var state = {
    top: [],
    bottom: []
  }

  /**
   * @type {TUiBasTiles}
   */
  var uiBasTiles = UiBasTiles.get()

  /**
   * @type {TOpenCloseDevicesState}
   */
  var basOpenCloseDevices = BasOpenCloseDevices.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  const currentBasCoreState = CurrentBasCore.get()

  this.get = get

  init()

  function init () {

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _syncTiles
    )
    $rootScope.$on(
      BAS_ROOM.EVT_SCENES_INITIALIZED,
      _onSceneInitialized
    )
    $rootScope.$on(
      BAS_ROOM.EVT_HOME_JOBS_INITIALIZED,
      _syncTiles
    )
    $rootScope.$on(
      BAS_ROOM.EVT_ENERGY_INITIALIZED,
      _syncTiles
    )

    $rootScope.$on(
      BAS_ROOM.EVT_HOME_TIMERS_INITIALIZED,
      _syncTiles
    )
    $rootScope.$on(
      BAS_OPEN_CLOSE_DEVICES.EVT_OPEN_CLOSE_DEVICES_UPDATED,
      _syncTiles
    )

    $rootScope.$on(
      BAS_SIP.EVT_ADELANTE_STATES_CHANGED,
      _syncTiles
    )

    $rootScope.$on(
      BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
      _syncTiles
    )

    _syncTiles(false)
  }

  /**
   * @returns {TBasHomeDashboard}
   */
  function get () {
    return state
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasRoom} room
   */
  function _onSceneInitialized (_event, room) {

    if (BasUtil.isObject(room) && room.isHome()) {

      _syncTiles()
    }
  }

  /**
   * @private
   * @param {boolean} [emit = true]
   */
  function _syncTiles (emit) {

    var _emit, room

    const admin = currentBasCoreState.core.core.profile.admin
    const supportsSchedulerAndDetailScene =
      currentBasCoreState.core.core.supportsSchedulerAndDetailScene

    _emit = BasUtil.isBool(emit) ? emit : true

    _resetTiles()

    if (BasCameras.shouldShowCameras()) {

      state.top.push(uiBasTiles.cameras)
    }

    if (basOpenCloseDevices.hasOpenCloseDevices) {

      state.top.push(uiBasTiles.doors)
    }

    if (BAS_SIP.ADELANTE_STATE.hasAdelante) {

      state.bottom.push(uiBasTiles.callHistory)
    }

    room = RoomsHelper.getHome()

    if (room) {

      if (room.hasScenes()) {

        if (!supportsSchedulerAndDetailScene || admin) {

          state.top.push(uiBasTiles.scenes)
          state.bottom.push(uiBasTiles.scheduler)
        }
      }

      if (BAS_ROOMS.ROOMS.info.hasEnergyRooms) {

        state.bottom.push(uiBasTiles.energy)
      }

      if (room.hasTimers()) {

        state.bottom.push(uiBasTiles.timers)
      }
    }

    if (BasUtil.isObject(BAS_ROOMS.ROOMS.info)) {

      if (BAS_ROOMS.ROOMS.info.hasMusicRooms) {

        state.top.push(uiBasTiles.music)
      }

      if (BAS_ROOMS.ROOMS.info.hasThermostatRooms) {

        state.top.push(uiBasTiles.thermostat)
      }

      if (BasIntercomHelper.shouldShowIntercom()) {

        state.top.push(uiBasTiles.intercom)
      }
    }

    state.bottom.push(uiBasTiles.weather)
    state.bottom.push(uiBasTiles.settings)

    if (_emit) {

      $rootScope.$emit(BAS_HOME_DASHBOARD.EVT_HOME_DASHBOARD_UPDATED)
    }
  }

  function _resetTiles () {

    state.top = []
    state.bottom = []
  }
}
