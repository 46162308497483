'use strict'

angular
  .module('basalteApp')
  .constant('BAS_SPLASH', {
    EVT_SPLASH_VISIBILITY_CHANGED: 'evtBasSplashVisibilityChanged',
    EVT_SPLASH_STATE_CHANGED: 'evtBasSplashStateChanged',
    S_CONNECTING: 'basSplashStateConnecting',
    S_CONNECTING_CORES: 'basSplashStateConnectingCores',
    S_CONNECTING_RESTARTING: 'basSplashStateConnectingRestarting',
    S_CONNECTING_UPDATING: 'basSplashStateConnectingUpdating',
    S_CONNECTING_FAILED: 'basSplashStateConnectingFailed',
    S_CONNECTING_FAILED_NO_RETRY: 'basSplashStateConnectingFailedNoRetry',
    S_CONNECTING_FAILED_NOT_AUTHORIZED:
      'basSplashStateConnectingFailedNotAuthorized',
    S_CONNECTING_FAILED_NO_INTEGRATOR_ACCESS:
      'basSplashStateConnectingFailedNoIntegratorAccess',
    S_CONNECTING_FAILED_NO_ACTIVE_SERVERS:
      'basSplashStateConnectingFailedNoActiveServers',
    S_CONNECTION_LOST: 'basSplashStateConnectionLost',
    S_NO_CONNECTION: 'basSplashStateNoConnection'
  })
