'use strict'

angular
  .module('basalteApp')
  .controller('projectImageSettingCtrl', [
    'BAS_ROOMS',
    roomImagesRoomSettingCtrl
  ])

/**
 * @param BAS_ROOMS
 */
function roomImagesRoomSettingCtrl (
  BAS_ROOMS
) {
  var projectImage = this

  projectImage.ROOMS = BAS_ROOMS.ROOMS
}
