'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('settingsHeader', [
    'BAS_HTML',
    settingsHeaderDirective
  ])

function settingsHeaderDirective (BAS_HTML) {

  return {
    restrict: 'A',
    template: BAS_HTML.settingsHeader,
    controller: [
      '$scope',
      '$rootScope',
      'BAS_STATE',
      'CurrentBasCore',
      'BasAppHeader',
      'BasAppHeaderControlsBack',
      'BasSettingsStateHelper',
      controller
    ],
    bindToController: {
      baseState: '<'
    },
    controllerAs: 'settingsHeader'
  }

  /**
   * @param $scope
   * @param $rootScope
   * @param {BAS_STATE} BAS_STATE
   * @param {CurrentBasCore} CurrentBasCore
   * @param {BasAppHeader} BasAppHeader
   * @param {BasAppHeaderControlsBack} BasAppHeaderControlsBack
   * @param {BasSettingsStateHelper} BasSettingsStateHelper
   */
  function controller (
    $scope,
    $rootScope,
    BAS_STATE,
    CurrentBasCore,
    BasAppHeader,
    BasAppHeaderControlsBack,
    BasSettingsStateHelper
  ) {
    var settingsHeader = this
    var _listeners = []

    /**
     * @type {TCurrentBasCoreState}
     */
    settingsHeader.currentBasCoreState = CurrentBasCore.get()

    settingsHeader.headerTitle = ''
    settingsHeader.headerCanNavigateBack = ''
    settingsHeader.headerNavigatedBack = false

    settingsHeader.back = BasAppHeaderControlsBack.goToParentState

    init()

    function init () {

      $scope.$on('$destroy', destroy)

      _listeners.push($rootScope.$on(
        BAS_STATE.EVT_STATE_SUCCESS,
        _onStateChanged
      ))

      _listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        _onLanguageChanged
      ))

      syncHeader()
    }

    function _onStateChanged () {

      syncHeader()
    }

    function _onLanguageChanged () {

      syncHeader()
    }

    function syncHeader () {

      var _stateHelper = BasSettingsStateHelper.getHelper()

      settingsHeader.headerTitle = BasAppHeader.determineSettingsHeaderTitle()
      settingsHeader.headerCanNavigateBack = (
        settingsHeader.baseState &&
        !_stateHelper.isExact(settingsHeader.baseState)
      )
    }

    function destroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
