'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var log = require('./logger')

/**
 * The class representing a Player queue.
 *
 * @constructor
 * @extends EventEmitter
 * @param {number} playerID The id of the associated player
 * @param {BasCore} basCore
 * @since 0.0.1
 */
function Queue (playerID, basCore) {

  EventEmitter.call(this)

  this._playerID = playerID
  this._basCore = basCore
  this._songs = []
  this._id = -1
  this._type = Queue.TYPE_QUEUE
  this._contentSource = Queue.CONTENT_SRC_UNKNOWN

  this._dirty = true

  this._handleUpdate = this._onUpdate.bind(this)
  this._handleSave = this._onSave.bind(this)

  this._availableOptions = [
    Queue.ADD_OPTIONS.now,
    Queue.ADD_OPTIONS.next,
    Queue.ADD_OPTIONS.replace,
    Queue.ADD_OPTIONS.end
  ]

  if (basCore.supportsReplaceNow) {

    this._availableOptions.push(
      Queue.ADD_OPTIONS.replaceNow
    )
  }
}

Queue.prototype = Object.create(EventEmitter.prototype)
Queue.prototype.constructor = Queue

// region Events

/**
 * Queue content has changed
 *
 * @event Queue#EVT_CHANGED
 */

// endregion

/**
 * @constant {string}
 */
Queue.EVT_CHANGED = 'changed'

/**
 * Normal queue
 *
 * @constant {string}
 */
Queue.TYPE_QUEUE = 'queue'

/**
 * Radio stream
 *
 * @constant {string}
 */
Queue.TYPE_STREAM = 'stream'

/**
 * @constant {string}
 */
Queue.TYPE_DEEZER_FLOW = 'deezer_flow'

/**
 * @constant {string}
 */
Queue.TYPE_DEEZER_RADIO = 'deezer_radio'

/**
 * @constant {string}
 */
Queue.CONTENT_SRC_LOCAL = 'local'

/**
 * @constant {string}
 */
Queue.CONTENT_SRC_DEEZER = 'deezer'

/**
 * @constant {string}
 */
Queue.CONTENT_SRC_TIDAL = 'tidal'

/**
 * @constant {string}
 */
Queue.CONTENT_SRC_MIXED = 'mixed'

/**
 * @constant {string}
 */
Queue.CONTENT_SRC_UNKNOWN = 'unknown'

/**
 * Add to queue options
 *
 * @readonly
 * @enum {string}
 */
Queue.ADD_OPTIONS = {

  /**
   * Add song(s) to queue and start playback of new song(s)
   */
  now: P.NOW,

  /**
   * Add song(s) to queue and start playback after current song
   */
  next: P.NEXT,

  /**
   * Clear queue and add song(s)
   */
  replace: P.REPLACE,

  /**
   * Clear queue, add song(s) and start playback
   */
  replaceNow: P.REPLACE_NOW,

  /**
   * Append song(s) to queue
   */
  end: P.END
}

/**
 * @constant {string}
 */
Queue.ERR_INVALID_ARGUMENTS = 'errInvalidArguments'

/**
 * @constant {string}
 */
Queue.ERR_PLAYLIST_EXISTS = 'errPlaylistExists'

/**
 * @constant {string}
 */
Queue.ERR_UNKNOWN_RESULT = 'errUnknownResult'

/**
 * @constant {string}
 */
Queue.ERR_INVALID_RESPONSE = 'errQueueInvalidResponse'

/**
 * The length of the queue
 *
 * @name Queue#length
 * @type {number}
 * @readonly
 */
Object.defineProperty(Queue.prototype, 'length', {
  get: function () {
    return this._songs.length
  }
})

/**
 * The songs in the queue
 *
 * @name Queue#songs
 * @type {Object[]}
 * @readonly
 */
Object.defineProperty(Queue.prototype, 'songs', {
  get: function () {
    return this._songs
  }
})

/**
 * The queue type
 *
 * Depending on the type of media that is playing, the queue can behave
 * differently
 *
 * @name Queue#type
 * @type {string}
 * @readonly
 * @since 1.2.0
 */
Object.defineProperty(Queue.prototype, 'type', {
  get: function () {
    return this._type
  }
})

/**
 * Content source for the current queue.
 *
 * @name Queue#contentSource
 * @type {string}
 * @readonly
 * @since 2.0.0
 */
Object.defineProperty(Queue.prototype, 'contentSource', {
  get: function () {
    return this._contentSource
  }
})

/**
 * The queue dirty flag
 *
 * If the flag is true, clients should request an update with the update
 * promise.
 *
 * @name Queue#dirty
 * @type {boolean}
 * @readonly
 * @since 1.2.0
 */
Object.defineProperty(Queue.prototype, 'dirty', {
  get: function () {
    return this._dirty
  }
})

/**
 * Parse a message from the connected basCore
 *
 * @param {Object} obj
 */
Queue.prototype.parse = function (obj) {

  if (BasUtil.isObject(obj)) {

    // Update queue when there are changes
    if (obj[P.CHANGED] === true) this.update()

    if (BasUtil.isNEString(obj[P.QUEUE_TYPE])) {

      this._type = obj[P.QUEUE_TYPE]
    }
  }
}

/**
 * @returns {Object}
 */
Queue.prototype._getBasCoreMessage = function () {

  var data = {}
  data[P.PLAYER] = {}
  data[P.PLAYER][P.ID] = this._playerID

  return data
}

/**
 * In the event that the queue has changed remotely, the current object will
 * first fetch the changes and afterwards an {@link Queue#EVT_CHANGED} event
 * will be emitted.
 *
 * @returns {Promise}
 */
Queue.prototype.update = function () {

  var data = this._getBasCoreMessage()
  data[P.PLAYER][P.PLAYLIST] = {}
  data[P.PLAYER][P.PLAYLIST][P.ACTION] = P.CHANGES
  data[P.PLAYER][P.PLAYLIST][P.ID] = this._id

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_LONG)
    .then(this._handleUpdate)
}

/**
 * @private
 * @param {Object} result
 * @returns {(Queue|Promise)}
 */
Queue.prototype._onUpdate = function (result) {

  var playlist, newSongs, i, length, song, emit
  var contentSource, newContentSource

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  emit = false

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.PLAYER]) &&
    result[P.PLAYER][P.ID] === this._playerID &&
    BasUtil.isObject(result[P.PLAYER][P.PLAYLIST])) {

    playlist = result[P.PLAYER][P.PLAYLIST]

    if (BasUtil.isPNumber(playlist[P.ID], true) &&
      BasUtil.isPNumber(playlist[P.LENGTH], true) &&
      Array.isArray(playlist[P.SONGS])) {

      // Queue type
      this._type = BasUtil.isNEString(playlist[P.QUEUE_TYPE])
        ? playlist[P.QUEUE_TYPE]
        : Queue.TYPE_QUEUE

      if (this._id !== playlist[P.ID]) {

        emit = true

        newSongs = playlist[P.SONGS]

        // Truncate or extend the array
        this._songs.length = playlist[P.LENGTH]

        // Prepare new songs (coverart) and add them to queue
        length = newSongs.length
        for (i = 0; i < length; i++) {

          song = newSongs[i]

          if (BasUtil.isObject(song) &&
            BasUtil.isPNumber(song[P.POS], true)) {

            this._basCore.modifySongCoverArt(song)
            this._songs[song[P.POS]] = song
          }
        }

        // Init content source keeper
        contentSource = ''

        // Check content source of queue
        length = this._songs.length
        for (i = 0; i < length; i++) {

          newContentSource = this._getContentSource(this._songs[i])

          if (contentSource) {

            if (contentSource !== Queue.CONTENT_SRC_MIXED &&
              contentSource !== newContentSource) {

              contentSource = Queue.CONTENT_SRC_MIXED
            }

          } else {

            contentSource = newContentSource
          }
        }

        // Set content source
        this._contentSource = contentSource || Queue.CONTENT_SRC_UNKNOWN

        this._id = playlist[P.ID]
      }

      this._dirty = false

      if (emit) this.emit(Queue.EVT_CHANGED)

      return this
    }
  }

  return Promise.reject(Queue.ERR_INVALID_RESPONSE)
}

/**
 * Returns the content source for the given song
 *
 * @private
 * @param {Object} song
 * @returns {string}
 * @since 2.0.0
 */
Queue.prototype._getContentSource = function (song) {

  if (BasUtil.isObject(song) &&
    BasUtil.isNEString(song[P.CONTENT_SRC])) {

    switch (song[P.CONTENT_SRC]) {
      case P.CONTENT_SRC_LOCAL:
        return Queue.CONTENT_SRC_LOCAL
      case P.CONTENT_SRC_DEEZER:
        return Queue.CONTENT_SRC_DEEZER
      case P.CONTENT_SRC_TIDAL:
        return Queue.CONTENT_SRC_TIDAL
      default:
        return Queue.CONTENT_SRC_UNKNOWN
    }
  }

  return Queue.CONTENT_SRC_UNKNOWN
}

/**
 * Save the current queue as a playlist
 *
 * @param {string} playlist The name of the playlist
 * @returns {Promise}
 * @since 1.1.0
 */
Queue.prototype.saveAsPlaylist = function (playlist) {

  var data

  if (BasUtil.isNEString(playlist)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.PLAYLIST] = {}
    data[P.PLAYER][P.PLAYLIST][P.ACTION] = P.SAVE
    data[P.PLAYER][P.PLAYLIST][P.NAME] = playlist

    return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_LONG)
      .then(this._handleSave)
  }

  return Promise.reject(Queue.ERR_INVALID_ARGUMENTS)
}

/**
 * @private
 * @param {Object} result
 * @returns {(string|Promise)}
 */
Queue.prototype._onSave = function (result) {

  if (BasUtil.isObject(result) &&
    BasUtil.isObject(result[P.PLAYER]) &&
    result[P.PLAYER][P.ID] === this._playerID &&
    P.RESULT in result[P.PLAYER]) {

    switch (result[P.PLAYER][P.RESULT]) {
      case P.OK:

        if (BasUtil.isObject(result[P.PLAYER][P.PLAYLIST])) {

          return result[P.PLAYER][P.PLAYLIST][P.ID]
        }

        break
      case P.PLAYLIST_EXISTS:

        return Promise.reject(Queue.ERR_PLAYLIST_EXISTS)

      default:

        return Promise.reject(Queue.ERR_UNKNOWN_RESULT)
    }
  }

  return Promise.reject(Queue.ERR_INVALID_RESPONSE)
}

/**
 * Remove a single song from the queue based on the id
 *
 * @param {number} id The id of the song to remove
 */
Queue.prototype.removeSongId = function (id) {

  var data

  if (BasUtil.isPNumber(id, true)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.PLAYLIST] = {}
    data[P.PLAYER][P.PLAYLIST][P.ACTION] = P.DELETE
    data[P.PLAYER][P.PLAYLIST][P.ID] = id

    this._basCore.send(data)
  }
}

/**
 * Move a single song in the queue based on the id
 *
 * @param {number} id The id of the song to move
 * @param {number} to The index of the queue
 * @since 1.1.0
 */
Queue.prototype.moveSongId = function (id, to) {

  var data

  if (BasUtil.isPNumber(id, true) &&
    BasUtil.isPNumber(to, true)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.PLAYLIST] = {}
    data[P.PLAYER][P.PLAYLIST][P.ACTION] = P.MOVEID
    data[P.PLAYER][P.PLAYLIST][P.ID] = id
    data[P.PLAYER][P.PLAYLIST][P.TO] = to

    this._basCore.send(data)
  }
}

/**
 * Remove a single song from the queue based on the position
 *
 * @deprecated
 * @param {number} pos The position of the song to remove
 */
Queue.prototype.removeSongPos = function (pos) {

  var data

  if (BasUtil.isPNumber(pos, true)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.PLAYLIST] = {}
    data[P.PLAYER][P.PLAYLIST][P.ACTION] = P.DELETE
    data[P.PLAYER][P.PLAYLIST][P.POS] = pos

    this._basCore.send(data)
  }
}

/**
 * Add song(s) to the queue
 *
 * @param {(string|string[])} songs Filepath(s) to song(s)
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 */
Queue.prototype.addSongs = function (
  songs,
  option
) {
  var _option, playlistObj, data

  _option = this._availableOptions.indexOf(option) < 0
    ? Queue.ADD_OPTIONS.end
    : option

  if (Array.isArray(songs)) {

    playlistObj = {}
    playlistObj[P.ACTION] = P.ADD
    playlistObj[P.SONGS] = songs
    playlistObj[P.OPTION] = _option

  } else if (BasUtil.isNEString(songs)) {

    playlistObj = {}
    playlistObj[P.ACTION] = P.ADD
    playlistObj[P.PATH] = songs
    playlistObj[P.OPTION] = _option

  } else {

    log.error('Queue  - addSongs - Invalid song(s)', songs)
  }

  if (playlistObj) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.PLAYLIST] = playlistObj

    this._basCore.send(data)
  }
}

/**
 * Load a playlist and add it to the end of the queue
 *
 * @param {string} playlist The ID of the playlist
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 */
Queue.prototype.addPlaylist = function (playlist, option) {

  var data

  if (BasUtil.isNEString(playlist)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.PLAYLIST] = {}
    data[P.PLAYER][P.PLAYLIST][P.ACTION] = P.ADD
    data[P.PLAYER][P.PLAYLIST][P.PLAYLIST] = playlist
    data[P.PLAYER][P.PLAYLIST][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Add a single deezer track to the queue
 *
 * @param {number} trackId The id of the track to add
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 * @since 1.1.0
 */
Queue.prototype.addDeezerTrack = function (trackId, option) {

  var data

  if (BasUtil.isVNumber(trackId)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DEEZER] = {}
    data[P.PLAYER][P.DEEZER][P.TYPE] = P.TRACK
    data[P.PLAYER][P.DEEZER][P.ID] = trackId
    data[P.PLAYER][P.DEEZER][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Add multiple deezer tracks to the queue
 *
 * @param {number[]} trackList A list of track IDs to add
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 * @since 1.1.0
 */
Queue.prototype.addDeezerTrackList = function (trackList, option) {

  var data

  if (Array.isArray(trackList)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DEEZER] = {}
    data[P.PLAYER][P.DEEZER][P.TYPE] = P.TRACK_LIST
    data[P.PLAYER][P.DEEZER][P.LIST] = trackList
    data[P.PLAYER][P.DEEZER][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Add a deezer album to the queue
 *
 * @param {number} albumId The deezer id of the album to add
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 * @since 1.1.0
 */
Queue.prototype.addDeezerAlbum = function (albumId, option) {

  var data

  if (BasUtil.isVNumber(albumId)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DEEZER] = {}
    data[P.PLAYER][P.DEEZER][P.TYPE] = P.ALBUM
    data[P.PLAYER][P.DEEZER][P.ID] = albumId
    data[P.PLAYER][P.DEEZER][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Add a deezer playlist to the queue
 *
 * @param {number} playlistId The deezer id of the playlist to add
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 * @since 1.1.0
 */
Queue.prototype.addDeezerPlaylist = function (playlistId, option) {

  var data

  if (BasUtil.isVNumber(playlistId)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DEEZER] = {}
    data[P.PLAYER][P.DEEZER][P.TYPE] = P.PLAYLIST
    data[P.PLAYER][P.DEEZER][P.ID] = playlistId
    data[P.PLAYER][P.DEEZER][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Start a deezer radio/mix (replaces current queue)
 *
 * @param {number} radioId The deezer id of the radio to start
 * @since 1.1.0
 */
Queue.prototype.startDeezerRadio = function (radioId) {

  var data

  if (BasUtil.isVNumber(radioId)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.DEEZER] = {}
    data[P.PLAYER][P.DEEZER][P.TYPE] = P.RADIO
    data[P.PLAYER][P.DEEZER][P.ID] = radioId

    this._basCore.send(data)
  }
}

/**
 * Start a deezer flow (replaces current queue)
 *
 * @since 1.1.0
 */
Queue.prototype.startDeezerFlow = function () {

  var data = this._getBasCoreMessage()
  data[P.PLAYER][P.DEEZER] = {}
  data[P.PLAYER][P.DEEZER][P.TYPE] = P.FLOW

  this._basCore.send(data)
}

/**
 * Add a single Tidal track to the queue
 *
 * @param {number} trackId The id of the track to add
 * @param {Queue.ADD_OPTIONS | string} [option]
 * @since 1.3.0
 */
Queue.prototype.addTidalTrack = function (trackId, option) {

  var data

  if (BasUtil.isVNumber(trackId)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.TIDAL] = {}
    data[P.PLAYER][P.TIDAL][P.TYPE] = P.TRACK
    data[P.PLAYER][P.TIDAL][P.ID] = trackId
    data[P.PLAYER][P.TIDAL][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Add multiple Tidal tracks to the queue
 *
 * @param {number[]} trackList A list of track IDs to add
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 * @since 1.9.0
 */
Queue.prototype.addTidalTrackList = function (trackList, option) {

  var data

  if (Array.isArray(trackList)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.TIDAL] = {}
    data[P.PLAYER][P.TIDAL][P.TYPE] = P.TRACK_LIST
    data[P.PLAYER][P.TIDAL][P.LIST] = trackList
    data[P.PLAYER][P.TIDAL][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Add a full Tidal album to the queue
 *
 * @param {number} albumId The id of the album to add
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 * @since 1.3.0
 */
Queue.prototype.addTidalAlbum = function (albumId, option) {

  var data

  if (BasUtil.isVNumber(albumId)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.TIDAL] = {}
    data[P.PLAYER][P.TIDAL][P.TYPE] = P.ALBUM
    data[P.PLAYER][P.TIDAL][P.ID] = albumId
    data[P.PLAYER][P.TIDAL][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Add a full Tidal artist to the queue
 *
 * @param {number} artistId The id of the album to add
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 * @since 1.9.0
 */
Queue.prototype.addTidalArtist = function (artistId, option) {

  var data

  if (BasUtil.isVNumber(artistId)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.TIDAL] = {}
    data[P.PLAYER][P.TIDAL][P.TYPE] = P.ARTIST
    data[P.PLAYER][P.TIDAL][P.ID] = artistId
    data[P.PLAYER][P.TIDAL][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Add a full Tidal playlist to the queue
 *
 * @param {string} playlistUuid The uuid of the playlist to add
 * @param {(Queue.ADD_OPTIONS|string)} [option]
 * @since 1.3.0
 */
Queue.prototype.addTidalPlaylist = function (playlistUuid, option) {

  var data

  if (BasUtil.isNEString(playlistUuid)) {

    data = this._getBasCoreMessage()
    data[P.PLAYER][P.TIDAL] = {}
    data[P.PLAYER][P.TIDAL][P.TYPE] = P.PLAYLIST
    data[P.PLAYER][P.TIDAL][P.UUID] = playlistUuid
    data[P.PLAYER][P.TIDAL][P.OPTION] =
      this._availableOptions.indexOf(option) === -1
        ? Queue.ADD_OPTIONS.end
        : option

    this._basCore.send(data)
  }
}

/**
 * Empty the queue
 */
Queue.prototype.clear = function () {

  var data = this._getBasCoreMessage()
  data[P.PLAYER][P.PLAYLIST] = {}
  data[P.PLAYER][P.PLAYLIST][P.ACTION] = P.CLEAR

  this._basCore.send(data)
}

/**
 * Destructor
 *
 * @since 1.9.0
 */
Queue.prototype.destroy = function destroy () {

  this.removeAllListeners()
}

module.exports = Queue
