'use strict'

angular
  .module('basalteApp')
  .constant('BAS_SCENE', {
    EVT_SCENE_UPDATED: 'evtSceneUpdated',
    EVT_IMAGES_UPDATED: 'roomImagesUpdated',
    T_INTERNAL: 'BasSceneTypeInternal',
    T_INTERNAL_KNX: 'BasSceneTypeInternalKNX',
    T_EXTERNAL: 'BasSceneTypeExternal'
  })
