'use strict'

import Pica from 'pica'

angular
  .module('basImageResize', [])
  .service('BasImageResize', [
    '$window',
    BasImageResize
  ])

/**
 * @constructor
 * @param $window
 */
function BasImageResize ($window) {

  var pica = Pica()

  /**
   * @type {HTMLCanvasElement}
   */
  var originalCanvas = $window.document.createElement('canvas')

  /**
   * @type {HTMLCanvasElement}
   */
  var resizedCanvas = $window.document.createElement('canvas')

  /**
   * @type {CanvasRenderingContext2D}
   */
  var context = originalCanvas.getContext(
    '2d'
  )

  this.getResizedImage = getResizedImage

  /**
   * @param {string} imageUrl
   * @param {number} maxLength
   * @param {number} maxSize in KB
   * @param callback
   */
  function getResizedImage (
    imageUrl,
    maxLength,
    maxSize,
    callback
  ) {
    var cbResolved, imgWidth, imgHeight, resizeWidth, resizeHeight

    cbResolved = false

    if (typeof imageUrl === 'string' && imageUrl) {

      getImage(imageUrl, '', onImage)
    }

    /**
     * @param {*} error
     * @param {HTMLImageElement} result
     */
    function onImage (error, result) {

      var index, body, fileSize
      var fileSizeRatio, sizeRatio, finalRatio

      if (error) {

        _cb(error)

      } else {

        imgWidth = result.naturalWidth
        imgHeight = result.naturalHeight

        index = imageUrl.indexOf(',')
        body = imageUrl.substring(index + 1)
        fileSize = atob(body).length

        fileSizeRatio = fileSize / maxSize

        if (imgWidth < maxLength &&
          imgHeight < maxLength &&
          fileSizeRatio < 1) {

          _cb(error, imageUrl)

        } else {

          if (imgWidth > imgHeight) {

            sizeRatio = imgWidth / maxLength

            finalRatio = sizeRatio > fileSizeRatio
              ? sizeRatio
              : fileSizeRatio

          } else {

            sizeRatio = imgHeight / maxLength

            finalRatio = sizeRatio > fileSizeRatio
              ? sizeRatio
              : fileSizeRatio
          }

          resizeWidth = imgWidth / finalRatio
          resizeHeight = imgHeight / finalRatio

          prepareCanvas(originalCanvas, imgWidth, imgHeight)

          prepareCanvas(resizedCanvas, resizeWidth, resizeHeight)

          // Copy the image contents to the canvas
          context.drawImage(result, 0, 0)

          pica.resize(originalCanvas, resizedCanvas)
            .then(resolveWithCanvasDataUrl, _cb)
        }
      }
    }

    function resolveWithCanvasDataUrl () {

      var dataUrl

      try {

        dataUrl = resizedCanvas.toDataURL('image/jpeg', 0.9)

      } catch (error) {

        _cb(error)
      }

      if (dataUrl) _cb(null, dataUrl)
    }

    function prepareCanvas (canvas, width, height) {

      canvas.width = width
      canvas.height = height
      context.clearRect(0, 0, width, height)
    }

    function _cb (error, result) {

      if (!cbResolved) {

        cbResolved = true
        if (typeof callback === 'function') callback(error, result)
      }
    }
  }

  /**
   * @param {string} imageUrl
   * @param {?string} crossOrigin
   * @param callback
   */
  function getImage (imageUrl, crossOrigin, callback) {

    var img

    img = new Image()

    img.onerror = onError
    img.onload = onLoad

    if (typeof crossOrigin === 'string') img.crossOrigin = crossOrigin

    img.src = imageUrl

    function onLoad () {

      if (typeof callback === 'function') callback(null, img)
    }

    function onError (error) {

      if (typeof callback === 'function') callback(error)
    }
  }
}
