'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasWebServer', [
    '$window',
    '$rootScope',
    'BAS_WEB_SERVER',
    'BasScreen',
    BasWebServer
  ])

/**
 * Service which handles http server
 *
 * @constructor
 * @param $window
 * @param $rootScope
 * @param {BAS_WEB_SERVER} BAS_WEB_SERVER
 * @param {BasScreen} BasScreen
 */
function BasWebServer (
  $window,
  $rootScope,
  BAS_WEB_SERVER,
  BasScreen
) {

  var K_BODY = 'body'
  var K_ACTION = 'action'

  var K_WAKEUP = 'wakeup'

  init()

  function init () {

    var httpServer

    httpServer = _getHttpServer()

    if (httpServer) {

      httpServer.addListener(_onHttpMessage)
      httpServer.init()
    }
  }

  /**
   * Fired when http server plugin sends a message
   *
   * @private
   * @param {?Object} message
   * @param {string} message.type
   * @param {*} [message.data]
   */
  function _onHttpMessage (message) {

    var httpServer, type, data

    httpServer = _getHttpServer()

    if (httpServer &&
      BasUtil.isObject(message)) {

      type = message[httpServer.K_TYPE]
      data = message[httpServer.K_DATA]

      switch (type) {
        case httpServer.T_HTTP_REQUEST:

          if (BasUtil.isObject(data)) {

            if (!handleHttpRequest(data)) {

              $rootScope.$emit(
                BAS_WEB_SERVER.EVT_INCOMING_REQUEST,
                data
              )
            }
          }

          break
      }
    }

    function handleHttpRequest (_data) {

      // Some default http request handling

      if (
        _data[K_BODY] &&
        _data[K_BODY][K_ACTION] === K_WAKEUP
      ) {

        // Sets the display timeout to last known timeout, triggers wakeup as
        //  side-effect
        BasScreen.releaseKeepScreenOn(true)
        return true
      }

      return false
    }
  }

  /**
   * Get http server plugin instance
   *
   * @private
   * @returns {?HttpServer}
   */
  function _getHttpServer () {

    if (BasUtil.isObject($window['basalteCordova']) &&
      BasUtil.isObject($window['basalteCordova']['httpServer'])) {

      return $window['basalteCordova']['httpServer']
    }

    return null
  }
}
