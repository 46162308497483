'use strict'

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var BasCoreSocket = require('./bas_core_socket')

var BasCrypto = require('./bas_crypto')

var log = require('./logger')

/**
 * WebSocket
 *
 * @constructor
 * @extends BasCoreSocket
 * @param {BasHost} host
 * @param {string} path
 * @since 3.0.0
 */
function BasCoreSocketWeb (host, path) {

  BasCoreSocket.call(this, path)

  /**
   * @private
   * @type {BasHost}
   */
  this._host = host

  /**
   * @private
   * @type {?WebSocket}
   */
  this._socket = null

  /**
   * @private
   * @type {Array}
   */
  this._socketMessageListeners = []

  /**
   * @private
   * @type {Array}
   */
  this._socketErrorCloseListeners = []

  this._handleError = this._onError.bind(this)
  this._handleOpen = this._onOpen.bind(this)
  this._handleClose = this._onClose.bind(this)
  this._handleMessage = this._onMessage.bind(this)
}

BasCoreSocketWeb.prototype = Object.create(BasCoreSocket.prototype)
BasCoreSocketWeb.prototype.constructor = BasCoreSocketWeb

/**
 * @constant {number}
 */
BasCoreSocketWeb.WS_TIMEOUT_MS = 5000

/**
 * Check if the socket is connected
 *
 * @returns {boolean}
 */
BasCoreSocketWeb.prototype.isConnected = function () {

  return (
    BasUtil.isObject(this._socket) &&
    this._socket.readyState === WebSocket.OPEN
  )
}

/**
 * @param {string} path
 * @param {TCoreCredentials} [credentials] WebSocket URL
 * @returns {Promise}
 */
BasCoreSocketWeb.prototype.openConnection = function (
  path,
  credentials
) {

  var _this, url, cNonce, hash, queryParams

  _this = this

  cNonce = BasCrypto.getCNonce()

  hash = BasCrypto.sha256(
    credentials.credentialTokenHash +
    cNonce +
    BasCrypto.sha256(path)
  )

  // Don't use URLSearchParams! It encodes a space as '+', which is not
  //  correctly handled by CLogics

  queryParams = []

  if (credentials.user) {
    queryParams.push(P.U + '=' + encodeURIComponent(credentials.user))
    queryParams.push(P.T + '=' + encodeURIComponent(credentials.token))
    queryParams.push(P.CN + '=' + cNonce)
    queryParams.push(P.H + '=' + hash)
  }

  if (credentials.jwt) {
    queryParams.push(P.JWT + '=' + credentials.jwt)
  }

  url = this._host.wsURI + path +
    '?' + queryParams.join('&')

  if (this._create(url)) {

    this._setSocketMessageListeners()

    return new Promise(_openSocketPromise)
  }

  return Promise.reject(CONSTANTS.ERR_SOCKET_CREATION)

  function _openSocketPromise (resolve, reject) {

    var _listeners, _timeoutId

    _listeners = []
    _listeners.push(BasUtil.setDOMListener(
      _this._socket,
      'error',
      _onError
    ))
    _listeners.push(BasUtil.setDOMListener(
      _this._socket,
      'close',
      _onClose
    ))
    _listeners.push(BasUtil.setDOMListener(
      _this._socket,
      'open',
      _onOpen
    ))

    _timeoutId = setTimeout(
      _onTimeout,
      BasCoreSocketWeb.WS_TIMEOUT_MS
    )

    function _onError (err) {

      _cleanUp(true)

      reject(err)
    }

    function _onClose () {

      _cleanUp(true)

      reject()
    }

    function _onOpen () {

      _this._setSocketErrorCloseListeners()

      _cleanUp()

      resolve()
    }

    function _onTimeout () {

      _cleanUp(true)

      reject()
    }

    /**
     * @private
     * @param {boolean} [cleanupSocket = false]
     */
    function _cleanUp (cleanupSocket) {

      clearTimeout(_timeoutId)
      _timeoutId = 0

      BasUtil.executeArray(_listeners)
      _listeners = []

      if (cleanupSocket) {

        _this._removeSocketMessageListeners()
        _this._removeSocketErrorCloseListeners()

        if (_this._socket) _this._socket.close()

        _this._socket = null
      }
    }
  }
}

/**
 * @returns {Promise}
 */
BasCoreSocketWeb.prototype.closeConnection = function () {

  if (this._socket) {

    if (this._socket.readyState === WebSocket.OPEN) {

      this._emitConnected(false)
    }

    this._socket.close()
  }

  this._removeSocketMessageListeners()
  this._removeSocketErrorCloseListeners()

  this._socket = null

  return Promise.resolve()
}

/**
 * Returns whether or not the data was sent.
 *
 * @param {Object} data
 * @returns {boolean}
 */
BasCoreSocketWeb.prototype.sendData = function (data) {

  if (this._socket &&
    this._socket.readyState === WebSocket.OPEN) {

    try {

      this._socket.send(JSON.stringify(data))

      return true

    } catch (error) {

      log.error('Send ERROR', error, data)

      return false
    }
  }

  return false
}

/**
 * Create the socket with the given URL
 *
 * @private
 * @param {string} url
 * @returns {boolean}
 */
BasCoreSocketWeb.prototype._create = function (url) {

  try {

    this._socket = new WebSocket(url)

    log.info('Socket created')

    return true

  } catch (error) {

    log.error('New Socket ERROR', error)

    return false
  }
}

/**
 * No events here, 'close' event will follow
 *
 * @private
 * @param {*} event
 */
BasCoreSocketWeb.prototype._onError = function (event) {

  log.error(
    'Socket ERROR',
    this._socket ? this._socket.readyState : 'Socket does not exist!',
    event
  )
}

/**
 * @private
 */
BasCoreSocketWeb.prototype._onOpen = function () {

  log.info('Socket opened')

  this.heartbeat(true)

  this._emitConnected(true)
}

/**
 * @private
 * @param {Object} event
 */
BasCoreSocketWeb.prototype._onClose = function (event) {

  log.info('Socket closed', event)

  this.clearHeartbeat()

  // Status-code range 4000 - 4999 is reserved for private use
  //  4001: JWT revoked
  if (event.code === BasCoreSocket.STATUSCODE_JWT_REVOKED) {
    this._emitJWTRevoked()
  }

  this._emitConnected(false)
}

/**
 * @private
 * @param {Object} event
 * @param {string} event.data
 */
BasCoreSocketWeb.prototype._onMessage = function (event) {

  var obj

  try {

    obj = JSON.parse(event.data)

  } catch (error) {

    log.error('Error parsing message', event, error)
  }

  if (BasUtil.isObject(obj)) this.onIncomingMessage(obj)
}

/**
 * @private
 */
BasCoreSocketWeb.prototype._setSocketMessageListeners = function () {

  this._removeSocketMessageListeners()

  if (this._socket) {

    this._socketMessageListeners.push(BasUtil.setDOMListener(
      this._socket,
      'open',
      this._handleOpen
    ))
    this._socketMessageListeners.push(BasUtil.setDOMListener(
      this._socket,
      'message',
      this._handleMessage
    ))
  }
}

/**
 * @private
 */
BasCoreSocketWeb.prototype._removeSocketMessageListeners = function () {

  BasUtil.executeArray(this._socketMessageListeners)
  this._socketMessageListeners = []
}

/**
 * @private
 */
BasCoreSocketWeb.prototype._setSocketErrorCloseListeners = function () {

  this._removeSocketErrorCloseListeners()

  if (this._socket) {

    this._socketErrorCloseListeners.push(BasUtil.setDOMListener(
      this._socket,
      'error',
      this._handleError
    ))
    this._socketErrorCloseListeners.push(BasUtil.setDOMListener(
      this._socket,
      'close',
      this._handleClose
    ))
  }
}

/**
 * @private
 */
BasCoreSocketWeb.prototype._removeSocketErrorCloseListeners = function () {

  BasUtil.executeArray(this._socketErrorCloseListeners)
  this._socketErrorCloseListeners = []
}

module.exports = BasCoreSocketWeb
