'use strict'

import * as detectBrowser from 'detect-browser'
import RequestJs from '@gidw/request-js'
import * as BasUtil from '@basalte/bas-util'
import basStorageUtil from '../bas-storage/util.basstorage'

var STR_K_UUID = 'BASALTE_TM_UUID'

// Dev
// var TELEMETRY_URL =
//   'https://u8sabqh2rf.execute-api.eu-west-1.amazonaws.com/telemetry'

// Prod
var TELEMETRY_URL = 'https://api.basalte.net/coreapp/telemetry'

var TELEMETRY_SEND_TIMEOUT_MS = 5000

/**
 * @typedef {Object} TBasTelemetryEvent
 * @property {number} reportTime
 * @property {string} evtType
 * @property {string} [evtSubType]
 */

/**
 * @typedef {Object} TBasFullTelemetryEvent
 * @property {string} uuid
 * @property {number} reportTime
 * @property {string} evtType
 */

module.exports.initBasTelemetry = initBasTelemetry

function initBasTelemetry (
  window,
  dataBasalteApp,
  appConfig
) {
  var basTm, _storageHandle, uuid, appVariant, cdvDevice, browser, platform
  var appVersion
  var telemetryPlugin

  basTm = window.basTModule

  _storageHandle = basStorageUtil.getStorageHandle(window)

  if (_storageHandle) {

    uuid = _storageHandle.getItem(STR_K_UUID)

    if (!BasUtil.isNEString(uuid)) {

      uuid = BasUtil.uuidv4()

      try {
        _storageHandle.setItem(STR_K_UUID, uuid)
      } catch (_storageError) {
        uuid = undefined
      }
    }
  }

  // Telemetry UUID

  basTm.uuid = BasUtil.isNEString(uuid) ? uuid : 'brokenStorage'
  basTm.logEvt = logEvt

  uuid = basTm.uuid

  // App version

  appVersion = appConfig.major + '.' +
    appConfig.minor + '.' +
    appConfig.patch + '.' +
    appConfig.build

  cdvDevice = window.device

  // Determine app variant

  if (dataBasalteApp) {

    if (dataBasalteApp.ellie) {
      appVariant = 'ellie'
    } else if (dataBasalteApp.lisa) {
      appVariant = 'lisa'
    } else if (dataBasalteApp.liveOnly) {
      appVariant = 'web-live'
    } else if (dataBasalteApp.web) {
      appVariant = 'web'
    }
  }

  platform = cdvDevice ? cdvDevice.platform : ''
  platform = BasUtil.isNEString(platform) ? platform.toLowerCase() : ''

  if (!appVariant) appVariant = platform

  // Browser detection

  browser = detectBrowser.detect()

  // Set Uuid in native telemetry plugin
  if (appConfig.config === 'release') {

    telemetryPlugin = _getBasTelemetry()

    if (telemetryPlugin) telemetryPlugin.setUuid(uuid)
  }

  window.addEventListener('unhandledrejection', onUnhandledRejection)
  window.addEventListener('error', _onError)

  function onUnhandledRejection (rejectionEvent) {

    var message, stack

    if (rejectionEvent.reason) {

      message = rejectionEvent.reason.message
      stack = rejectionEvent.reason.stack
    }

    basTm.logEvt({
      evtType: basTm.T_APP_UNHANDLED_REJECTION,
      error: JSON.stringify({ message: message, stack: stack })
    })
  }

  function _onError (errorEvent) {

    var message, stack

    if (errorEvent.error) {

      message = errorEvent.error.message
      stack = errorEvent.error.stack
    }

    basTm.logEvt({
      evtType: basTm.T_APP_UNHANDLED_REJECTION,
      error: JSON.stringify({
        message: message,
        stack: stack
      })
    })
  }

  /**
   * @param {(TBasTelemetryEvent[]|TBasTelemetryEvent)} events
   */
  function logEvt (
    events
  ) {
    var _events

    _events = Array.isArray(events)
      ? events
      : BasUtil.isObject(events)
        ? [events]
        : null

    if (_events) _sendEvents(_events)
  }

  /**
   * @private
   * @param {TBasTelemetryEvent[]} events
   * @param [callback]
   * @returns {{abort: Function}}
   */
  function _sendEvents (events, callback) {

    var length, i, evt

    uuid = basTm.uuid
    length = events.length
    for (i = 0; i < length; i++) {

      evt = events[i]

      // Make sure each event has UUID
      if (BasUtil.isObject(evt)) {

        evt.uuid = uuid
        evt.reportTime = Date.now()

        evt.tJsStart = basTm.tJsStart
        evt.tDeviceReady = basTm.tDeviceReady
        evt.tStorageReady = basTm.tStorageReady
        evt.tAppCtrl = basTm.tAppCtrl
        evt.tBeforeStart = basTm.tBeforeStart
        evt.tBeforeBasLiveCheck = basTm.tBeforeBasLiveCheck
        evt.tBasLiveSessionCheckDone = basTm.tBasLiveSessionCheckDone
        evt.tBasLiveCheckDone = basTm.tBasLiveCheckDone
        evt.tConnectWithServer = basTm.tConnectWithServer
        evt.tConnectWithServerDone = basTm.tConnectWithServerDone
        evt.tConnectCore = basTm.tConnectCore
        evt.tConnectCoreDone = basTm.tConnectCoreDone
        evt.tAppStarted = basTm.tAppStarted

        if (evt.evtType === basTm.T_APP_START) {

          evt.appVariant = appVariant
          evt.appVersion = appVersion

          if (cdvDevice) {

            evt.deviceModel = cdvDevice.model
            evt.devicePlatform = cdvDevice.platform
            evt.deviceUuid = cdvDevice.uuid
            evt.deviceVersion = cdvDevice.version
            evt.deviceManufacturer = cdvDevice.manufacturer
            evt.isVirtual = cdvDevice.isVirtual
            evt.deviceSerial = cdvDevice.serial
          }

          if (browser) {

            evt.browserType = browser.type
            evt.browserName = browser.name
            evt.browserVersion = browser.version
            evt.browserOs = browser.os
          }

          if (window.location) {

            evt.windowLocProtocol = window.location.protocol
            evt.windowLocHostname = window.location.hostname
            evt.windowLocHost = window.location.host
            evt.windowLocPort = window.location.port
          }

          evt.windowSecureContext = window.isSecureContext

          if (window.navigator) {

            evt.windowNavLang = window.navigator.language
          }
        }
      }
    }

    return _sendTelemetryEvents(events, callback)
  }

  /**
   * @private
   * @param {TBasFullTelemetryEvent[]} events
   * @param [callback]
   * @returns {{abort: Function}}
   */
  function _sendTelemetryEvents (events, callback) {

    if (appConfig.config === 'release') {

      return RequestJs(
        {
          url: TELEMETRY_URL,
          method: 'POST',
          timeout: TELEMETRY_SEND_TIMEOUT_MS,
          data: JSON.stringify(events)
        },
        callback
      )
    }

    Promise.resolve()
      .then(BasUtil.exec.bind(null, callback, 'Invalid config'))

    return {
      abort: function () {}
    }
  }

  /**
   * Get the BasTelemetry plugin instance
   *
   * @private
   * @returns {?BasSip}
   */
  function _getBasTelemetry () {

    if (BasUtil.isObject(window['basalteCordova']) &&
      BasUtil.isObject(window['basalteCordova']['bastelemetry'])) {

      return window['basalteCordova']['bastelemetry']
    }

    return null
  }
}
