'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomDoorPhoneGateways', [
    'CurrentBasCore',
    'BasDoorPhoneGateway',
    basRoomDoorPhoneGateways
  ])

/**
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasDoorPhoneGateway
 * @returns BasRoomDoorPhoneGateways
 */
function basRoomDoorPhoneGateways (
  CurrentBasCore,
  BasDoorPhoneGateway
) {
  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomDoorPhoneGateways (basRoom) {

    /**
     * @type {BasDoorPhoneGateway[]}
     */
    this.doorPhoneGateways = []

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomDoorPhoneGateways.hasDoorPhoneGateways = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.doorPhoneGateways)
    )
  }

  BasRoomDoorPhoneGateways.prototype.parseRoom = function () {

    if (BasRoomDoorPhoneGateways.hasDoorPhoneGateways(this._basRoom)) {

      this.syncDoorPhoneGateways()
    }
  }

  BasRoomDoorPhoneGateways.prototype.syncDoorPhoneGateways = function () {

    var length, i, uuid, device

    this.resetDoorPhones()

    if (BasRoomDoorPhoneGateways.hasDoorPhoneGateways(this._basRoom)) {

      length = this._basRoom.room.doorPhoneGateways.length
      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.doorPhoneGateways[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) {

          this.doorPhoneGateways.push(
            new BasDoorPhoneGateway(device)
          )
        }
      }
    }
  }

  /**
   * @param {string} uuid
   * @returns {?BasDoorPhoneGateway}
   */
  BasRoomDoorPhoneGateways.prototype.getDoorPhoneGateway = function (uuid) {

    var length, i, gateway

    length = this.doorPhoneGateways.length
    for (i = 0; i < length; i++) {

      gateway = this.doorPhoneGateways[i]

      if (gateway && gateway.uuid === uuid) return gateway
    }

    return null
  }

  /**
   * Clear all door phone gateways
   */
  BasRoomDoorPhoneGateways.prototype.clearDoorPhoneGateways = function () {

    var length, i

    length = this.doorPhoneGateways.length
    for (i = 0; i < length; i++) {

      if (this.doorPhoneGateways[i]) this.doorPhoneGateways[i].clear()
    }
  }

  BasRoomDoorPhoneGateways.prototype.resetDoorPhones = function () {

    this.clearDoorPhoneGateways()

    this.doorPhoneGateways = []
  }

  /**
   * Clears the BasRoom reference
   */
  BasRoomDoorPhoneGateways.prototype.destroy = function () {

    this._basRoom = null
  }

  return BasRoomDoorPhoneGateways
}
