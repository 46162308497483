'use strict'

/**
 * Creates a new Logger utility object
 *
 * @constructor
 */
function Logger () {

  /**
   * @type {number}
   * @default Logger#NONE
   */
  this.level = Logger.NONE

  /**
   * @type {boolean}
   * @default false
   */
  this.useTimestamp = false

  /**
   * @type {string}
   */
  this.customTag = ''

  /**
   * @type {boolean}
   * @default false
   */
  this.useTypeTag = false

  /**
   * @type {boolean}
   * @default false
   */
  this.useColor = false
}

/**
 * Process console message by
 *
 * @param {*} givenArgs
 * @param {string} type
 * @returns {*}
 */
Logger.prototype.processConsoleMessage = function (givenArgs, type) {
  var args = []
  var extras = ''

  // Color
  if (this.useColor) {
    extras += '%c'
  }

  // Timestamp
  if (this.useTimestamp) {
    extras += new Date().toLocaleTimeString()
  }

  // Custom tag
  if (this.customTag.length > 0) {
    extras += ' [' + this.customTag + ']'
  }

  // Type tag
  if (this.useTypeTag) {
    extras += ' [' + type + ']'
  }

  // Create new arguments array
  args.push(extras)

  // Color
  if (this.useColor) {
    args.push('color: green')
  }

  // Concat arrays
  args.push.apply(args, givenArgs)

  return args
}

/**
 * Log error
 */
Logger.prototype.error = function () {
  if (this.level >= Logger.ERROR) {

    // eslint-disable-next-line no-console
    console.error.apply(console, this.processConsoleMessage(arguments, 'ERROR'))
  }
}

/**
 * Log warn
 */
Logger.prototype.warn = function () {
  if (this.level >= Logger.WARN) {

    // eslint-disable-next-line no-console
    console.warn.apply(console, this.processConsoleMessage(arguments, 'WARN'))
  }
}

/**
 * Log info
 */
Logger.prototype.info = function () {
  if (this.level >= Logger.INFO) {

    // eslint-disable-next-line no-console
    console.info.apply(console, this.processConsoleMessage(arguments, 'INFO'))
  }
}

/**
 * Log log
 */
Logger.prototype.log = function () {
  if (this.level >= Logger.LOG) {

    // eslint-disable-next-line no-console
    console.log.apply(console, this.processConsoleMessage(arguments, 'LOG'))
  }
}

/**
 * Log debug
 */
Logger.prototype.debug = function () {
  if (this.level >= Logger.DEBUG) {

    // eslint-disable-next-line no-console
    console.debug.apply(console, this.processConsoleMessage(arguments, 'DEBUG'))
  }
}

/**
 * Provide a Logger instance with the Logger function object
 * Primarily to access the static members
 *
 * @type {Logger}
 */
Logger.prototype.Logger = Logger

/**
 * Logger level NONE
 *
 * @constant {number}
 */
Logger.NONE = 0

/**
 * Logger level ERROR
 *
 * @constant {number}
 */
Logger.ERROR = 1

/**
 * Logger level WARN
 *
 * @constant {number}
 */
Logger.WARN = 2

/**
 * Logger level INFO
 *
 * @constant {number}
 */
Logger.INFO = 3

/**
 * Logger level LOG
 *
 * @constant {number}
 */
Logger.LOG = 4

/**
 * Logger level DEBUG
 *
 * @constant {number}
 */
Logger.DEBUG = 5

module.exports = new Logger()
