{
  "SCENES_ORDER": {
    "Root": [
      "InternalHome",
      "InternalGoodNight",
      "InternalDinner",
      "InternalWorkout"
    ]
  },
  "SCENES_FAVOURITES": {
    "Root": [
      "InternalHome",
      "InternalGoodNight",
      "InternalDinner",
      "InternalWorkout"
    ]
  }
}
