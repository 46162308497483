'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasAppTemperature', [
    'BAS_API',
    'CurrentBasCore',
    'BasPreferences',
    BasAppTemperature
  ])

/**
 * @constructor
 * @param BAS_API
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasPreferences} BasPreferences
 */
function BasAppTemperature (
  BAS_API,
  CurrentBasCore,
  BasPreferences
) {
  var DEF_TEMPERATURE = 20

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  var defaultTemp = new BAS_API.Temperature()

  this.getTemperatureUnit = getTemperatureUnit
  this.getDefaultTemperature = getDefaultTemperature

  defaultTemp.setCelsius(DEF_TEMPERATURE)

  /**
   * @returns {string}
   */
  function getTemperatureUnit () {

    var unit = BasPreferences.getTemperatureUnit()

    if (BasUtil.isNEString(unit)) return unit

    if (CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.system &&
      BasUtil.isNEString(
        currentBasCoreState.core.core.system.temperatureUnit
      )) {

      return currentBasCoreState.core.core.system.temperatureUnit
    }

    return BAS_API.CONSTANTS.TU_CELSIUS
  }

  function getDefaultTemperature () {
    return defaultTemp.getTemperature(getTemperatureUnit())
  }
}
