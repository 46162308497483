'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('basTemplateModalCtrl', [
    '$window',
    '$rootScope',
    '$scope',
    'BAS_MODAL',
    'BAS_SPLASH',
    'BasUtilities',
    'basModalConfig',
    'close',
    basTemplateModalCtrl
  ])

/**
 * @param $window
 * @param $rootScope
 * @param $scope
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasUtilities} BasUtilities
 * @param {BasModalConfig} basModalConfig
 * @param close
 */
function basTemplateModalCtrl (
  $window,
  $rootScope,
  $scope,
  BAS_MODAL,
  BAS_SPLASH,
  BasUtilities,
  basModalConfig,
  close
) {
  var modal = this

  var _okButtonIsActive = false
  var _okButtonTimeoutId = null

  var _listeners = []
  var _destroyed = false

  /**
   * @type {BAS_MODAL}
   */
  modal.BAS_MODAL = BAS_MODAL

  /**
   * @type {BasModalConfig}
   */
  modal.basModalConfig = basModalConfig

  modal.subtitle = basModalConfig.subtitle.getString()

  modal.close = doClose
  modal.closeModal = closeModal

  init()

  function init () {

    _listeners.push($rootScope.$on(
      '$translateChangeSuccess',
      _updateTranslations
    ))
    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)

    BasUtilities.waitForFrames(5, _setKeyboardListener)

    if (
      modal.basModalConfig &&
      BasUtil.isPNumber(modal.basModalConfig.okButtonDelay)
    ) {
      _okButtonTimeoutId = setTimeout(
        _onOkButtonDelay,
        modal.basModalConfig.okButtonDelay
      )

    } else {

      _okButtonIsActive = true
    }
  }

  /**
   * @param {string} [reason]
   */
  function doClose (reason) {

    if (reason === BAS_MODAL.C_YES && !_okButtonIsActive) {

      // Do nothing, wait until button is active

    } else {

      close(reason)
    }
  }

  /**
   * @param {string} [reason]
   */
  function closeModal (reason) {

    if (
      !modal.basModalConfig ||
      modal.basModalConfig.allowDismiss
    ) {

      doClose(reason)
    }
  }

  function _setKeyboardListener () {

    if (!_destroyed) {

      _listeners.push(BasUtil.setDOMListener(
        $window.document,
        'keyup',
        _onKeyUp
      ))
    }
  }

  /**
   * @private
   * @param {KeyboardEvent} event
   */
  function _onKeyUp (event) {

    var _key

    if (event) {

      _key = event.key

      if (_key) {

        if (_key === 'Enter') {

          closeModal(BAS_MODAL.C_YES)
        }

      } else if (event.keyCode === 13 || event.which === 13) {

        closeModal(BAS_MODAL.C_YES)
      }
    }
  }

  function _onOkButtonDelay () {

    _okButtonIsActive = true

    if (modal.basModalConfig && modal.basModalConfig.setOkButtonDisabled) {

      modal.basModalConfig.setOkButtonDisabled(false)
    }

    $scope.$applyAsync()
  }

  function _updateTranslations () {

    modal.subtitle = basModalConfig.subtitle.getString()

    $scope.$applyAsync()
  }

  function _onSplashVisibility () {

    if (modal.basModalConfig.closeOnSplash) close()
  }

  function _onDestroy () {

    _destroyed = true

    clearTimeout(_okButtonTimeoutId)

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
