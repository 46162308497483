'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('scenePickerModalCtrl', [
    '$scope',
    '$rootScope',
    'BAS_CURRENT_CORE',
    'BAS_ROOMS',
    'BAS_SPLASH',
    'close',
    'room',
    scenePickerModalCtrl
  ])

/**
 * @param $scope
 * @param $rootScope
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param close
 * @param {string} room UUID
 */
function scenePickerModalCtrl (
  $scope,
  $rootScope,
  BAS_CURRENT_CORE,
  BAS_ROOMS,
  BAS_SPLASH,
  close,
  room
) {
  var modal = this

  var isUpdated = false
  var _listeners = []

  /**
   * @type {BAS_ROOMS}
   */
  modal.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {string}
   */
  modal.room = room

  modal.favourites = ['', '', '', '']
  modal.currentFavourite = 0

  modal.close = close

  modal.chooseSpot = chooseSpot
  modal.selectScene = selectScene
  modal.removeScene = removeScene

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_ROOMS_RECEIVED,
      _onRoomsUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    _syncUiFavourites()
  }

  /**
   * @param {number} index
   */
  function chooseSpot (index) {

    modal.currentFavourite = index
  }

  /**
   * @param {string} uuid
   */
  function selectScene (uuid) {

    var i, length

    // Remove doubles

    length = modal.favourites.length
    for (i = 0; i < length; i++) {

      if (modal.favourites[i] === uuid) modal.favourites[i] = ''
    }

    // Set favourite

    modal.favourites[modal.currentFavourite] = uuid

    isUpdated = true
  }

  function removeScene () {

    modal.favourites[modal.currentFavourite] = ''

    isUpdated = true
  }

  function _syncUiFavourites () {

    var scenes, favourites, i, sceneUuid

    _resetModalFavourites()

    scenes = _getScenes()

    if (scenes) {

      favourites = scenes.getRawFavourites()

      if (Array.isArray(favourites)) {

        for (i = 0; i < 4; i++) {

          sceneUuid = favourites[i]

          if (sceneUuid) modal.favourites[i] = sceneUuid
        }
      }
    }
  }

  function _saveFavourites () {

    var scenes, favourites

    scenes = _getScenes()

    if (scenes) {

      favourites = scenes.getRawFavourites()

      if (!BasUtil.isEqualArray(modal.favourites, favourites)) {

        scenes.setFavourites(modal.favourites)
      }
    }
  }

  /**
   * @returns {?BasRoomScenes}
   */
  function _getScenes () {

    var basRoom

    if (modal.room) {

      basRoom = BAS_ROOMS.ROOMS.rooms[modal.room]

      if (basRoom && basRoom.scenes && basRoom.scenes.getSceneCtrlDeviceUuid) {

        return basRoom.scenes
      }
    }

    return null
  }

  function _onRoomsUpdated () {

    var basRoom

    basRoom = BAS_ROOMS.ROOMS.rooms[modal.room]

    if (!basRoom || !basRoom.isRoom) close()
  }

  function _resetModalFavourites () {

    modal.favourites = ['', '', '', '']
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []

    if (isUpdated) {

      _saveFavourites()
    }
  }
}
