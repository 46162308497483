'use strict'

angular
  .module('basalteApp')
  .constant('PLAYER_STATE', {
    NORMAL: 'playerStateNormal',
    SOURCE_UNKNOWN: 'playerStateSourceUnknown',
    SOURCE_UNAVAILABLE: 'playerStateSourceUnavailable',
    SOURCE_EMPTY: 'playerStateSourceEmpty',
    OFF: 'playerStateOff',
    QUEUE_END: 'playerStateQueueEnd',
    QUEUE_EMPTY: 'playerStateQueueEmpty',
    ZONE_EMPTY: 'playerStateZoneEmpty',
    UNKNOWN_AV: 'playerStateUnknownAv',
    NO_AV: 'playerStateNoAv',
    SONOS_UNREACHABLE: 'playerStateSonosUnreachable',
    VIDEO_UNAVAILABLE: 'playerStateVideoUnavailable',
    MUSIC_UNAVAILABLE: 'playerStateMusicUnavailable'
  })
