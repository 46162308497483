'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basAppHeader', [
    'BAS_HTML',
    'BasAppDevice',
    basAppHeader
  ])

/**
 * @typedef {Object} TBasAppHeaderActionOptions
 * @property {boolean} [basSplashContext]
 */

/**
 * @param {BAS_HTML} BAS_HTML
 * @param {BasAppDevice} BasAppDevice
 * @returns basAppHeader
 */
function basAppHeader (
  BAS_HTML,
  BasAppDevice
) {
  return {
    restrict: 'AE',
    template: BasAppDevice.isLisa()
      ? BAS_HTML.appHeaderLisa
      : BAS_HTML.appHeader,
    controller: [
      '$scope',
      '$rootScope',
      '$element',
      'BAS_ROOMS',
      'BAS_APP_HEADER',
      'BAS_VOLUME',
      'BasAppHeader',
      'CurrentRoom',
      'BasVolume',
      'BasAppHeaderControls',
      'BasUtilities',
      controller
    ],
    controllerAs: 'header',
    bindToController: {
      basSplashContext: '<?'
    }
  }

  /**
   * @param $scope
   * @param $rootScope
   * @param $element
   * @param {BAS_ROOMS} BAS_ROOMS
   * @param {BAS_APP_HEADER} BAS_APP_HEADER
   * @param {BAS_VOLUME} BAS_VOLUME
   * @param {BasAppHeader} BasAppHeader
   * @param {CurrentRoom} CurrentRoom
   * @param {BasVolume} BasVolume
   * @param {BasAppHeaderControls} BasAppHeaderControls
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $scope,
    $rootScope,
    $element,
    BAS_ROOMS,
    BAS_APP_HEADER,
    BAS_VOLUME,
    BasAppHeader,
    CurrentRoom,
    BasVolume,
    BasAppHeaderControls,
    BasUtilities
  ) {
    var header = this

    var _listeners = []

    /**
     * @type {TBasVolumeState}
     */
    var basVolumeState = BasVolume.get()

    /**
     * @type {BasRooms}
     */
    header.rooms = BAS_ROOMS.ROOMS

    /**
     * @type {BasAppHeaderState}
     */
    header.state = BasAppHeader.get()

    /**
     * @type {TCurrentRoomState}
     */
    header.currentRoom = CurrentRoom.get()

    /**
     * @type {BasAppHeaderControls}
     */
    header.BasAppHeaderControls = BasAppHeaderControls

    header.$postLink = _onPostLink
    header.$onDestroy = _onDestroy

    header.getBasAppHeaderActionOptions = getBasAppHeaderActionOptions

    init()

    function init () {

      _listeners.push($rootScope.$on(
        BAS_APP_HEADER.EVT_STATE_CHANGED,
        _onAppHeaderStateChanged
      ))
      _listeners.push($rootScope.$on(
        BAS_VOLUME.EVT_VOLUME_CHANGED,
        _onVolumeChanged
      ))
    }

    function getBasAppHeaderActionOptions () {
      return {
        basSplashContext: header.basSplashContext
      }
    }

    function _onPostLink () {

      _syncCss()
    }

    function _onAppHeaderStateChanged () {

      _syncCss()

      $scope.$applyAsync()
    }

    function _onVolumeChanged () {

      _syncCss()

      $scope.$applyAsync()
    }

    function _syncCss () {

      var el

      el = $element[0]

      if (el) {

        el.className = BasUtilities.generateClassName(
          el.className,
          [
            header.state.css,
            basVolumeState.css
          ]
        )
      }
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
