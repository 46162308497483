'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceTimeFormat', [
    'BAS_HTML',
    deviceTimeFormat
  ])

function deviceTimeFormat (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.timeFormatDeviceSettings,
    controller: [
      '$rootScope',
      '$scope',
      'BAS_PREFERENCES',
      'BAS_INTL',
      'BAS_INPUT_MODAL',
      'BasPreferences',
      'BasInputModal',
      'BasAppDevice',
      'BasUtilities',
      controller
    ],
    controllerAs: 'timeFormat'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BAS_INTL} BAS_INTL
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {BasPreferences} BasPreferences
   * @param {BasInputModal} BasInputModal
   * @param {BasAppDevice} BasAppDevice
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    $scope,
    BAS_PREFERENCES,
    BAS_INTL,
    BAS_INPUT_MODAL,
    BasPreferences,
    BasInputModal,
    BasAppDevice,
    BasUtilities
  ) {
    var timeFormat = this

    var listeners = []

    timeFormat.showEditTimeFormat = showEditTimeFormat

    timeFormat.BAS_PREFERENCES = BAS_PREFERENCES

    timeFormat.TIME_FORMAT_OPTIONS = [
      {
        text: '12_hr',
        translate: true,
        value: BAS_PREFERENCES.TIME_FORMAT_12h
      },
      {
        text: '24_hr',
        translate: true,
        value: BAS_PREFERENCES.TIME_FORMAT_24h
      }
    ]

    timeFormat.format = ''
    timeFormat.uiFormat = '-'

    init()

    function init () {

      listeners.push($rootScope.$on(
        BAS_INTL.EVT_TIME_FORMAT_CHANGED,
        _onTimeFormatChanged
      ))

      listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        _onLanguageChanged
      ))

      $scope.$on('$destroy', _onDestroy)

      _syncTimeFormat()

      if (!BasAppDevice.isCoreClient()) {

        timeFormat.TIME_FORMAT_OPTIONS.unshift({
          text: 'system',
          translate: true,
          value: BAS_PREFERENCES.TIME_FORMAT_LOCALE_DEFAULT
        })
      }
    }

    function showEditTimeFormat () {

      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'time_format',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            options: timeFormat.TIME_FORMAT_OPTIONS,
            initialValue: timeFormat.format
          }
        ],
        autoAdvance: true,
        allowDismiss: true,
        autoSave: true,
        validCheck: BasUtil.isNEArray
      }).then(onModal)

      function onModal (modal) {

        modal.close.then(function (result) {

          if (BasUtil.isNEArray(result)) {

            timeFormat.format = result[0]
            BasPreferences.setTimeFormat(timeFormat.format)
            _syncUiTimeFormat()
          }
        })
      }
    }

    function _syncTimeFormat () {

      timeFormat.format = BasPreferences.getTimeFormat()
      _syncUiTimeFormat()
    }

    function _syncUiTimeFormat () {

      switch (timeFormat.format) {
        case BAS_PREFERENCES.TIME_FORMAT_12h:
          timeFormat.uiFormat = BasUtilities.translate('12_hr')
          break
        case BAS_PREFERENCES.TIME_FORMAT_24h:
          timeFormat.uiFormat = BasUtilities.translate('24_hr')
          break
        case BAS_PREFERENCES.TIME_FORMAT_LOCALE_DEFAULT:
          timeFormat.uiFormat = BasUtilities.translate('system')
          break
        default:
          timeFormat.uiFormat = '-'
      }
    }

    function _onTimeFormatChanged () {

      _syncTimeFormat()
    }

    function _onLanguageChanged () {

      _syncUiTimeFormat()
    }

    function _onDestroy () {

      BasUtil.executeArray(listeners)
      listeners = []
    }
  }
}
