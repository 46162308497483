'use strict'

angular
  .module('basScrub', [])
  .directive('basScrub', basScrub)

function basScrub () {

  return {
    restrict: 'AE',
    scope: {
      canScrub: '<',
      percentage: '<',
      onChange: '&'
    },
    template:
      '<div class="bas-scrubber"' +
      ' ng-class="scrubber.css"' +
      ' data-hm-press="scrubber.panStart($event)"' +
      ' data-hm-panstart="scrubber.panStart($event)"' +
      ' data-hm-panmove="scrubber.panMove($event)"' +
      ' data-hm-panend="scrubber.panEnd()"' +
      ' data-hm-recognizer-options="' +
      '{' +
      '  \'threshold\': 0,' +
      '  \'directions\': \'DIRECTION_HORIZONTAL\',' +
      '  \'preventDefault\': true,' +
      '  \'stopPropagation\': true' +
      '}' +
      '"' +
      ' data-hm-manager-options="' +
      '{' +
      '  \'enable\': true,' +
      '  \'domEvents\': false,' +
      '  \'preventGhosts\': true' +
      '}' +
      '">' +
      ' <div class="bas-scrubber-wrapper">' +
      '   <div class="bas-scrubber-background"></div>' +
      '   <div class="bas-scrubber-progress"' +
      ' ng-style="' +
      'scrubber.enableScrub &&' +
      ' scrubber.scrubProgress ||' +
      ' { ' +
      '  \'transform\': \'scale3d(\' + percentage/100 + \', 1, 1)\'' +
      ' }' +
      '"></div>' +
      ' </div>' +
      '</div>',
    controller: [
      '$scope',
      '$element',
      '$timeout',
      controller
    ],
    controllerAs: 'scrubber'
  }

  function controller (scope, element, $timeout) {

    var CLASS_SCRUBBER = 'bas-scrubber'
    var CSS_SCRUB_ENLARGE = 'bas-scrubber-enlarge'
    var CSS_TRANSFORM = 'transform'
    var RESET_TIMEOUT = 1000

    var scrubber = this

    var scrubOffset = 0
    var scrubWidth = 0
    var timer = null

    var elScrubber = element[0].getElementsByClassName(CLASS_SCRUBBER)[0]

    scrubber.scrubPercentage = 0
    scrubber.enableScrub = false
    scrubber.css = {}
    scrubber.css[CSS_SCRUB_ENLARGE] = false
    scrubber.scrubProgress = {}
    updateProgress()

    scrubber.panStart = function (event) {

      var boundingRect

      if (scope.canScrub &&
        !scrubber.enableScrub) {

        boundingRect = elScrubber.getBoundingClientRect()

        scrubOffset = boundingRect.left
        scrubWidth = boundingRect.width

        $timeout.cancel(timer)
        scrubber.enableScrub = true
        scrubber.css[CSS_SCRUB_ENLARGE] = true
        scrubber.scrubPercentage =
          (event.center.x - scrubOffset) * 100 / scrubWidth
        updateProgress()

        scope.$applyAsync()
      }
    }

    scrubber.panMove = function (event) {

      if (scrubber.enableScrub) {

        scrubber.scrubPercentage =
          (event.center.x - scrubOffset) * 100 / scrubWidth
        scrubber.scrubPercentage =
          Math.min(100, Math.max(0, scrubber.scrubPercentage))
        updateProgress()

        scope.$applyAsync()
      }
    }

    scrubber.panEnd = function () {

      if (scrubber.enableScrub) {

        if (typeof scope.onChange === 'function') {
          scope.onChange({ percentage: scrubber.scrubPercentage })
        }

        scrubber.css = {}
        scrubber.css[CSS_SCRUB_ENLARGE] = false
        scope.$applyAsync()

        timer = $timeout(resetScrubbing, RESET_TIMEOUT)
      }
    }

    function resetScrubbing () {
      scrubber.enableScrub = false
    }

    function updateProgress () {
      scrubber.scrubProgress[CSS_TRANSFORM] =
        'scale3d(' + scrubber.scrubPercentage / 100 + ', 1, 1)'
    }
  }
}
