'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('roomsDefaultRoomsSettingCtrl', [
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'BAS_SOURCE',
    'BAS_SOURCES',
    'Sources',
    roomsDefaultRoomsSettingCtrl
  ])

/**
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {Sources} Sources
 */
function roomsDefaultRoomsSettingCtrl (
  $uiRouterGlobals,
  BAS_ROOMS,
  BAS_SOURCE,
  BAS_SOURCES,
  Sources
) {
  var defaultRoomsRooms = this

  /**
   * @type {BAS_ROOMS}
   */
  defaultRoomsRooms.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {BAS_SOURCES}
   */
  defaultRoomsRooms.BAS_SOURCES = BAS_SOURCES

  defaultRoomsRooms.$uiRouterGlobals = $uiRouterGlobals

  defaultRoomsRooms.selectRoom = selectRoom

  init()

  function init () {

    Sources.registerFor(BAS_SOURCE.COL_EVT_DEFAULT_ROOMS)
  }

  /**
   * @param {string} roomId
   */
  function selectRoom (roomId) {

    var basSource = BAS_SOURCES.SOURCES.sources[_getStreamUuid()]

    if (basSource &&
      basSource.defaultRooms) {

      basSource.defaultRooms.toggleRoom(roomId)
    }
  }

  /**
   * @private
   * @returns {string}
   */
  function _getStreamUuid () {

    var streamId

    streamId = $uiRouterGlobals.params.stream

    return BasUtil.isNEString(streamId) ? streamId : ''
  }
}
