'use strict'

angular
  .module('basalteApp')
  .controller('profilesCtrl', [
    'BAS_MODAL',
    'BasCoreConnection',
    'CurrentBasCore',
    'BasModal',
    profilesCtrl
  ])

/**
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasCoreConnection} BasCoreConnection
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasModal} BasModal
 */
function profilesCtrl (
  BAS_MODAL,
  BasCoreConnection,
  CurrentBasCore,
  BasModal
) {

  var profiles = this

  var CSS_LOADING = 'bas-profiles--loading'

  /**
   * @type {TCurrentBasCoreState}
   */
  profiles.currentBasCoreState = CurrentBasCore.get()

  profiles.css = {}
  profiles.css[CSS_LOADING] = false

  profiles.onLoginStarted = onLoginStarted
  profiles.onLoginError = onLoginError
  profiles.onLogin = onLogin

  init()

  function init () {

    // Get updated project name
    CurrentBasCore.retrieveProjectInfo()
      .then(_onProjectInfo, _onProjectInfoError)
  }

  function onLoginStarted () {

    profiles.css[CSS_LOADING] = true
  }

  function onLoginError () {

    profiles.css[CSS_LOADING] = false
  }

  /**
   * @param {BasServer} basServer
   */
  function onLogin (basServer) {

    // TODO: Check WebRTC Timeout?

    if (basServer) {

      // No need to handle "then", should change state

      BasCoreConnection.handleLogin(basServer).catch(_onLoginError)
    }

    function _onLoginError () {

      profiles.css[CSS_LOADING] = false

      BasModal.show(BAS_MODAL.T_UNABLE_TO_CONNECT)
    }
  }

  function _onProjectInfo () {
    // Empty
  }

  function _onProjectInfoError () {
    // Ignore
  }
}
