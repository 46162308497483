'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basSlider', [])
  .directive('basSlider', basSlider)

function basSlider () {

  return {
    restrict: 'E',
    scope: {
      key: '@',
      min: '<',
      max: '<',
      step: '<',
      debounce: '<',
      maxIncreaseDiff: '<',
      progress: '<',
      ticks: '<',
      orientation: '@',
      reverse: '<',
      switchTicksPosition: '<',
      sliderModelObject: '<',
      sliderChange: '&',
      sliderChanged: '&',
      nonDebouncedSliderChange: '&',
      disabled: '<'
    },
    controller: [
      '$scope',
      '$element',
      'BasAppDevice',
      'RangeSlider',
      controller
    ]
  }

  /**
   * @param $scope
   * @param $element
   * @param BasAppDevice
   * @param RangeSlider
   */
  function controller ($scope, $element, BasAppDevice, RangeSlider) {

    var rangeSlider, rangeSliderOptions, lastValue, debounce
    var maxIncreaseDiff, _timeoutId, sliderChange, sliderChanged
    var nonDebouncedSliderChange

    /**
     * @type {BasAppDeviceState}
     */
    var basAppDeviceState = BasAppDevice.get()

    _timeoutId = 0

    this.$onDestroy = _onDestroy

    init()

    function init () {

      // Values used in this directive
      lastValue = BasUtil.isObject($scope.sliderModelObject)
        ? $scope.sliderModelObject[$scope.key]
        : 0
      debounce = BasUtil.isPNumber($scope.debounce)
        ? $scope.debounce
        : 0
      sliderChange = BasUtil.isFunction($scope.sliderChange)
        ? $scope.sliderChange
        : null
      sliderChanged = BasUtil.isFunction($scope.sliderChanged)
        ? $scope.sliderChanged
        : null
      nonDebouncedSliderChange =
        BasUtil.isFunction($scope.nonDebouncedSliderChange)
          ? $scope.nonDebouncedSliderChange
          : null

      // Building options for external slider dependency
      rangeSliderOptions = {}

      rangeSliderOptions.min = BasUtil.isVNumber($scope.min)
        ? $scope.min
        : 0
      rangeSliderOptions.max = BasUtil.isVNumber($scope.max)
        ? $scope.max
        : 100
      rangeSliderOptions.step = BasUtil.isPNumber($scope.step)
        ? $scope.step
        : 1
      rangeSliderOptions.progress = BasUtil.isBool($scope.progress)
        ? $scope.progress
        : true
      rangeSliderOptions.disabled = BasUtil.isBool($scope.disabled)
        ? $scope.disabled
        : false
      rangeSliderOptions.ticks = BasUtil.isNEArray($scope.ticks)
        ? $scope.ticks
        : []
      rangeSliderOptions.switchTicksPosition =
        BasUtil.isBool($scope.switchTicksPosition)
          ? $scope.switchTicksPosition
          : false
      rangeSliderOptions.orientation =
        BasUtil.isNEString($scope.orientation)
          ? $scope.orientation
          : 'horizontal'
      rangeSliderOptions.reverse =
          BasUtil.isBool($scope.reverse)
            ? $scope.reverse
            : false

      rangeSliderOptions.element = $element[0]
      rangeSliderOptions.onChange = _onChange
      rangeSliderOptions.onChanged = _onChanged
      rangeSliderOptions.transform3d = true
      rangeSliderOptions.ignoreWhileSliding = true
      // TODO: Check if behavior is correct with current ios version check
      rangeSliderOptions.passiveEventListening =
        basAppDeviceState.iosVersion &&
        (new BasUtil.BasVersion(basAppDeviceState.iosVersion)).major !== 13
      rangeSliderOptions.value = lastValue

      // Max increase difference
      if (BasUtil.isPNumber($scope.maxIncreaseDiff)) {

        maxIncreaseDiff = $scope.maxIncreaseDiff

        rangeSliderOptions.setValueFromWrapperHook =
          valueFromWrapperHook
      }

      rangeSlider = new RangeSlider(rangeSliderOptions)

      $scope.$watch('disabled', _onDisabledChange)
      $scope.$watch('min', _onMinChange)
      $scope.$watch('max', _onMaxChange)

      $scope.$watch(_getSliderModel, _onSliderModelChange)
    }

    function _onChange (value) {

      lastValue = value
      _syncSliderToModel()

      BasUtil.exec(nonDebouncedSliderChange)

      clearTimeout(_timeoutId)

      if (debounce > 0) {

        _timeoutId = setTimeout(_executeSliderChange, debounce)

      } else {

        _executeSliderChange()
      }
    }

    function _onChanged () {

      clearTimeout(_timeoutId)

      _executeSliderChanged()
    }

    function _executeSliderChange () {

      BasUtil.execute(sliderChange)
    }

    function _executeSliderChanged () {

      BasUtil.execute(sliderChanged)
    }

    function _getSliderModel () {

      return BasUtil.isObject($scope.sliderModelObject)
        ? $scope.sliderModelObject[$scope.key]
        : null
    }

    function _onDisabledChange (newValue) {

      if (BasUtil.isObject(rangeSlider)) {

        rangeSlider.disabled = newValue
      }
    }

    function _onMinChange (newValue) {

      if (BasUtil.isNumber(newValue)) {

        rangeSliderOptions.min = newValue
        rangeSlider.config({
          min: rangeSliderOptions.min
        })
      }
    }

    function _onMaxChange (newValue) {

      if (BasUtil.isNumber(newValue)) {

        rangeSliderOptions.max = newValue
        rangeSlider.config({
          max: rangeSliderOptions.max
        })
      }
    }

    function _onSliderModelChange (newValue) {

      if (BasUtil.isNumber(newValue)) {

        if (rangeSlider.isSliding()) {

          // Slider is sliding, revert model to slider value
          //  (purely changing the model value does not trigger anything, actual
          //  behaviour is only triggered by 'sliderChange' or 'sliderChanged'
          //  handlers)

          lastValue = rangeSlider.getValue()
          _syncSliderToModel()

        } else {

          // Slider not sliding: set incoming value on slider

          lastValue = newValue
          _syncModelToSlider()
        }
      }
    }

    function _syncModelToSlider () {

      if (rangeSlider) rangeSlider.setValue(lastValue, false)
    }

    function _syncSliderToModel () {

      if (BasUtil.isObject($scope.sliderModelObject)) {

        $scope.sliderModelObject[$scope.key] = lastValue
        $scope.$applyAsync()
      }
    }

    function valueFromWrapperHook (oldValue, newValue) {

      var overMaxDiffIncrease

      overMaxDiffIncrease = (newValue - oldValue) > maxIncreaseDiff
      return {
        value: overMaxDiffIncrease
          ? oldValue + maxIncreaseDiff
          : newValue,
        cancelCurrentPointerEvent: overMaxDiffIncrease
      }
    }

    function _destroySlider () {

      rangeSlider.detach()
      rangeSlider = null
    }

    function _onDestroy () {

      if (BasUtil.isObject(rangeSlider)) {

        _destroySlider()
      }
    }
  }
}
