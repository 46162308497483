'use strict'

angular
  .module('basalteApp')
  .service('BasLiveTokenRevoked', [
    '$rootScope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_CURRENT_CORE',
    'BAS_LIVE_ACCOUNT',
    'BAS_MODAL',
    'BasState',
    'BasSplash',
    'BasCoreConnection',
    'BasLiveAccount',
    'BasModal',
    BasLiveTokenRevoked
  ])

/**
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasState} BasState
 * @param {BasSplash} BasSplash
 * @param {BasCoreConnection} BasCoreConnection
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasModal} BasModal
 */
function BasLiveTokenRevoked (
  $rootScope,
  $uiRouterGlobals,
  STATES,
  BAS_CURRENT_CORE,
  BAS_LIVE_ACCOUNT,
  BAS_MODAL,
  BasState,
  BasSplash,
  BasCoreConnection,
  BasLiveAccount,
  BasModal
) {

  init()

  function init () {

    // Event emitted when we know fore sure that our JWT has been revoked
    //  through BasLiveAccount.check()
    $rootScope.$on(BAS_LIVE_ACCOUNT.EVT_NOT_AUTHORIZED, logout)

    // Event emitted when we know for sure that our JWT has been revoked
    //  through an error sent by the core
    $rootScope.$on(BAS_CURRENT_CORE.EVT_CORE_CONNECTION_JWT_REVOKED, () => {
      // Event can be emitted either after we are connected
      //  or during the connection process
      // WebSocket -> we will be disconnected, set disconnect expected to avoid
      //  auto-reconnect being started
      BasCoreConnection.setDisconnectExpected(true)
      // WebRTC -> not connected yet, stop auto-reconnect
      BasCoreConnection.stopAutoReconnect()
      BasSplash.hideAfterFrames(2)
      logout()
    })

    function logout () {

      // Force log out to clear everything and stuff
      BasLiveAccount.logout()

      // If not transitioning, go to live state
      const statePromise =
        $uiRouterGlobals.transition?.promise ?? BasState.go(STATES.CONNECT_LIVE)

      // When state change is finished, show 'logged out' modal
      const onStateChanged = () => BasModal.show(BAS_MODAL.T_LOGGED_OUT)

      statePromise.then(onStateChanged, onStateChanged)

    }
  }
}
