'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasLiveConnect', [
    '$rootScope',
    'BAS_API',
    'BAS_LIVE_ACCOUNT',
    'BAS_ERRORS',
    'BasLiveAccount',
    'BasError',
    'Logger',
    BasLiveConnect
  ])

/**
 * @callback CBasLiveConnectCallback
 * @param {?BasError} error
 * @param {TBasLiveConnect} result
 */

/**
 * @typedef {Object} TBasLiveConnectOptions
 * @property {boolean} relayOnly
 */

/**
 * @callback CBasLiveConnectAbort
 */

/**
 * @callback CBasLiveConnectTakeBasServer
 * @returns {?BasRemoteServer}
 */

/**
 * @typedef {Object} TBasLiveConnect
 * @property {CBasLiveConnectAbort} abort
 * @property {CBasLiveConnectTakeBasServer} takeBasServer
 * @property {boolean} finished
 * @property {BasRemoteServer} [basServer]
 * @property {BasError} [error]
 */

/**
 * @constructor
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BasLiveAccount} BasLiveAccount
 * @param BasError
 * @param Logger
 */
function BasLiveConnect (
  $rootScope,
  BAS_API,
  BAS_LIVE_ACCOUNT,
  BAS_ERRORS,
  BasLiveAccount,
  BasError,
  Logger
) {
  // Chrome can stall requests for 10s
  var _WAIT_FOR_SUBSCRIPTIONS_AND_ICE_SERVERS_MS = 12000

  var _WAIT_FOR_WEB_RTC_MS = 10000

  var _WAIT_FOR_DATA_CHANNEL_CONNECTIONS_MS = 10000
  var _WAIT_FOR_CONNECTION_FAIL_TIMEOUT_MS =
    _WAIT_FOR_SUBSCRIPTIONS_AND_ICE_SERVERS_MS +
    _WAIT_FOR_WEB_RTC_MS

  /**
   * @type {TBasLiveAccountState}
   */
  var basLiveAccountState = BasLiveAccount.get()

  this.connect = connect

  /**
   * Possible BasError types
   * - T_INVALID_INPUT
   * - T_LIVE_AUTHENTICATION
   * - T_LIVE_NOT_AUTHORIZED
   * - T_LIVE_NO_INTEGRATOR_ACCESS
   * - T_LIVE_NO_ACTIVE_SERVERS_FOUND
   * - T_LIVE_CONNECTION
   * - T_CONNECTION
   * - T_INTERNAL
   * - T_TIMEOUT
   * - T_ABORT
   *
   * @param {string} projectId
   * @param {CBasLiveConnectCallback} callback
   * @param {TBasLiveConnectOptions} [options]
   * @returns {TBasLiveConnect}
   */
  function connect (
    projectId,
    callback,
    options
  ) {
    var _result, _aborted, _cbCalled
    var _relayOnly
    var _iceServers, _uuid
    var _appSyncClient, _subscriptionOptions
    var _answerObservable, _remoteIceCandidatesObservable
    var _appSyncEventListener
    var _answerSubscriber, _remoteIceCandidatesSubscriber
    var _answerIdContext, _remoteIceCandidatesIdContext
    var _answerSubscription, _remoteIceCandidatesSubscription
    var _answerSubscriptionReady, _remoteIceCandidatesSubscriptionReady
    var _subscriptionsReady
    var _localDescription
    var _offerSending, _answer
    var _remoteIceCandidates, _basRemoteServer
    var _basRemoteServerListeners
    var _connectionTimeoutId, _secondIceServersRequestTimeoutId
    var _subscriptionsAndIceServersTimeoutId, _webRTCTimeoutId
    var _remoteProxyConnectTimeoutId, _remoteCoreConnectTimeoutId
    var _tConnectionStart, _tIceServersReceived, _tServerCreated
    var _tSubscriptionsReady, _tSubscriptionsAndServerReady, _tConnected
    var _tOfferSend, _tAnswerReceived
    var _connectStats

    _aborted = false
    _cbCalled = false

    _relayOnly = false

    _iceServers = null

    _localDescription = null

    _basRemoteServerListeners = []

    _offerSending = false
    _answer = null

    _remoteIceCandidates = []

    _answerSubscriptionReady = false
    _remoteIceCandidatesSubscriptionReady = false
    _subscriptionsReady = false

    _answerIdContext = {}
    _remoteIceCandidatesIdContext = {}

    _connectionTimeoutId = 0
    _secondIceServersRequestTimeoutId = 0
    _subscriptionsAndIceServersTimeoutId = 0
    _webRTCTimeoutId = 0
    _remoteProxyConnectTimeoutId = 0
    _remoteCoreConnectTimeoutId = 0

    /**
     * @type {TBasConnectStats}
     */
    _connectStats = {}
    _connectStats.totalTime = 0
    _connectStats.liveLocalIceCandidates = []
    _connectStats.liveRemoteIceCandidates = []

    _uuid = BasUtil.uuidv4()

    Logger.debug('LIVE CONNECT', projectId)

    _result = {
      abort: abort,
      takeBasServer: takeBasServer,
      finished: false
    }

    _tConnectionStart = Date.now()

    if (BasUtil.isNEString(projectId)) {

      _appSyncClient = BasLiveAccount.getAppSyncClient()

      if (basLiveAccountState.isLoggedIn && _appSyncClient) {

        if (BasUtil.isObject(options)) {

          if (BasUtil.isBool(options.relayOnly)) {

            _relayOnly = options.relayOnly
          }
        }

        _connectionTimeoutId = setTimeout(
          _onConnectionTimeout,
          _WAIT_FOR_CONNECTION_FAIL_TIMEOUT_MS
        )

        _subscriptionsAndIceServersTimeoutId = setTimeout(
          _onSubscriptionsAndServerReadyTimeout,
          _WAIT_FOR_SUBSCRIPTIONS_AND_ICE_SERVERS_MS
        )

        // Get ICE servers

        BasLiveAccount.getIceServers({
          multipleRequestOffsets: [
            500,
            1000,
            1500,
            2000,
            3000,
            4000
          ]
        })
          .then(_onIceServers, _onIceServersError)

        // GraphQL subscriptions

        _subscriptionOptions = {
          multipleSubscriptionLinkOffsets: [
            500,
            1000,
            2000,
            3000,
            5000
          ]
        }

        _answerObservable =
          BasLiveAccount.subscribeToAnswers(
            _uuid,
            _subscriptionOptions,
            _answerIdContext
          )
        _remoteIceCandidatesObservable =
          BasLiveAccount.subscribeToIceCandidates(
            _uuid,
            _subscriptionOptions,
            _remoteIceCandidatesIdContext
          )

        if (_answerObservable && _remoteIceCandidatesObservable) {

          _appSyncEventListener = $rootScope.$on(
            BAS_LIVE_ACCOUNT.EVT_SUBSCRIPTION_STARTED,
            _onAppSyncSubscriptionStarted
          )

          _answerSubscriber = {
            next: _onAnswerNext,
            error: _onAnswerError,
            complete: _onAnswerComplete
          }

          _answerSubscription = _answerObservable
            .subscribe(_answerSubscriber)

          _remoteIceCandidatesSubscriber = {
            next: _onRemoteIceCandidateNext,
            error: _onRemoteIceCandidateError,
            complete: _onRemoteIceCandidateComplete
          }

          _remoteIceCandidatesSubscription = _remoteIceCandidatesObservable
            .subscribe(_remoteIceCandidatesSubscriber)

        } else {

          _cleanup(true)

          _cb(new BasError(
            BAS_ERRORS.T_LIVE_CONNECTION,
            undefined,
            'Observable subscription errors'
          ))
        }

      } else {

        _cb(new BasError(
          BAS_ERRORS.T_LIVE_AUTHENTICATION,
          _appSyncClient,
          'Basalte Live is not logged in or client is invalid'
        ))
      }

    } else {

      _cb(new BasError(
        BAS_ERRORS.T_INVALID_INPUT,
        projectId,
        'Invalid project ID: ' + projectId
      ))
    }

    return _result

    function _onIceServers (result) {

      if (_aborted || _cbCalled || _iceServers) return

      _tIceServersReceived = Date.now()

      _iceServers = result

      Logger.debug(
        'ICE server received',
        (_tIceServersReceived - _tConnectionStart),
        result
      )

      _connectStats.liveIceServersReceived =
        _tIceServersReceived - _tConnectionStart

      /**
       * @type {RTCConfiguration}
       */
      const _rtcConfig = {
        iceServers: _iceServers
      }

      if (_relayOnly) {

        _rtcConfig.iceTransportPolicy = 'relay'
      }

      BAS_API.BasRemoteServer.build(
        projectId,
        _rtcConfig
      ).then(_onBasRemoteServer, _onBasRemoteServerError)
    }

    function _onIceServersError (error) {

      if (_aborted || _cbCalled || _iceServers) return

      _cleanup(true)

      _cb(_checkError(
        new BasError(
          BAS_ERRORS.T_LIVE_CONNECTION,
          error,
          'ICE servers error'
        ),
        error
      ))
    }

    /**
     * @private
     * @param {Object} _event
     * @param {Object} subscriptionEvent
     */
    function _onAppSyncSubscriptionStarted (
      _event,
      subscriptionEvent
    ) {
      var _check

      if (_aborted || _cbCalled) return

      if (subscriptionEvent) {

        if (
          subscriptionEvent.name === BAS_LIVE_ACCOUNT.S_ANSWERS &&
          subscriptionEvent.id === _answerIdContext.id
        ) {

          _answerSubscriptionReady = true
          _check = true
        }

        if (
          subscriptionEvent.name === BAS_LIVE_ACCOUNT.S_ICE_CANDIDATES &&
          subscriptionEvent.id === _remoteIceCandidatesIdContext.id
        ) {

          _remoteIceCandidatesSubscriptionReady = true
          _check = true
        }
      }

      if (_check) _checkSubscriptions()
    }

    function _checkSubscriptions () {

      if (!_subscriptionsReady &&
        _answerSubscriptionReady &&
        _remoteIceCandidatesSubscriptionReady) {

        _clearAppSyncEventsListener()

        _subscriptionsReady = true

        _onSubscriptionsReady()
      }
    }

    function _onSubscriptionsReady () {

      _tSubscriptionsReady = Date.now()

      Logger.debug(
        'Subscriptions ready',
        (_tSubscriptionsReady - _tConnectionStart)
      )

      _connectStats.liveSubscriptionsReady =
        _tSubscriptionsReady - _tConnectionStart

      _checkSubscriptionsAndServerReady()
    }

    function _onBasRemoteServer (result) {

      if (_aborted || _cbCalled) return

      _basRemoteServer = result

      if (_basRemoteServer) {

        _tServerCreated = Date.now()

        Logger.debug(
          'BasRemoteServerCreated',
          (_tServerCreated - _tIceServersReceived)
        )

        _localDescription = _basRemoteServer.getLocalDescription()

        if (_localDescription) {

          _checkSubscriptionsAndServerReady()

        } else {

          // Should not occur

          _cleanup(true)

          _cb(new BasError(
            BAS_ERRORS.T_CONNECTION,
            undefined,
            'onBasRemoteServer - No valid local description'
          ))
        }

      } else {

        // Should not occur

        _cleanup(true)

        _cb(new BasError(
          BAS_ERRORS.T_INTERNAL,
          undefined,
          'onBasRemoteServer - No valid BasRemoteServer'
        ))
      }
    }

    function _onBasRemoteServerError (error) {

      if (_aborted || _cbCalled) return

      // Local WebRTC error

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_CONNECTION,
        error,
        'BasRemoteServer.build (createOffer) error'
      ))
    }

    function _checkSubscriptionsAndServerReady () {

      if (_subscriptionsReady && _basRemoteServer) {

        _onSubscriptionsAndServerReady()
      }
    }

    function _onSubscriptionsAndServerReady () {

      var __localIceCandidates, length, i

      // Offer shouldn't be sent multiple times

      if (!_offerSending) {

        _offerSending = true

        _tSubscriptionsAndServerReady = Date.now()

        Logger.debug(
          'Subscriptions and server ready',
          (_tSubscriptionsAndServerReady - _tConnectionStart),
          _uuid
        )

        _connectStats.liveSubscriptionsAndIceServer =
          _tSubscriptionsAndServerReady - _tConnectionStart

        _clearSubscriptionsAndIceServersTimeout()

        _webRTCTimeoutId = setTimeout(
          _onWebRTCTimeout,
          _WAIT_FOR_WEB_RTC_MS
        )

        // Send local description

        BasLiveAccount.sendOffer(
          _uuid,
          projectId,
          _localDescription,
          _iceServers
        ).then(_onSendOurOffer, _onSendOurOfferError)

        // Send local ICE candidates (if any)

        __localIceCandidates = _basRemoteServer.getCurrentIceCandidates()

        length = __localIceCandidates.length
        for (i = 0; i < length; i++) {

          _sendOurIceCandidate(__localIceCandidates[i])
        }

        _connectStats.liveLocalIceCandidatesCached = length

        _setBasRemoteServerListeners()

        // Check if answer or remote ICE candidates were received already

        if (_answer) {

          _basRemoteServer.setAnswer(_answer)
            .then(_onSetAnswer, _onSetAnswerError)

          length = _remoteIceCandidates.length
          for (i = 0; i < length; i++) {

            _basRemoteServer.addIceCandidate(
              _remoteIceCandidates[i]
            )
          }
        }

      } else {

        Logger.error('Attempted to send double offer')
      }
    }

    function _onSubscriptionsAndServerReadyTimeout () {

      if (_aborted || _cbCalled) return

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_TIMEOUT,
        undefined,
        'Subscriptions and server ready timeout'
      ))
    }

    function _onSendOurOffer () {

      if (_aborted || _cbCalled) return

      _tOfferSend = Date.now()

      Logger.debug(
        'Offer send',
        (_tOfferSend - _tSubscriptionsAndServerReady)
      )

      if (_basRemoteServer) {

        // Empty

      } else {

        // Should not occur

        _cleanup(true)

        _cb(new BasError(
          BAS_ERRORS.T_INTERNAL,
          undefined,
          'On SendOurOffer no BaRemoteServer'
        ))
      }
    }

    function _onSendOurOfferError (error) {

      if (_aborted || _cbCalled) return

      _cleanup(true)

      _cb(_checkError(
        new BasError(
          BAS_ERRORS.T_LIVE_CONNECTION,
          error,
          'Send our offer'
        ),
        error
      ))
    }

    function _onAnswerNext (answer) {

      var length, i

      if (_aborted || _cbCalled) return

      _tAnswerReceived = Date.now()

      if (!_answer) {

        _answer = answer

        _clearAnswerSubscription()

        Logger.debug(
          'Answer received',
          (_tAnswerReceived - _tSubscriptionsAndServerReady)
        )

        _connectStats.liveAnswer =
          _tAnswerReceived - _tSubscriptionsAndServerReady

        if (_basRemoteServer && _answer) {

          _basRemoteServer.setAnswer(_answer)
            .then(_onSetAnswer, _onSetAnswerError)

          length = _remoteIceCandidates.length
          for (i = 0; i < length; i++) {

            _basRemoteServer.addIceCandidate(
              _remoteIceCandidates[i]
            )
          }
        }
      } else {

        _cleanup(true)

        _cb(new BasError(
          BAS_ERRORS.T_LIVE_CONNECTION,
          undefined,
          'Received 2 answers'
        ))
      }
    }

    function _onSetAnswer () {

      // Do nothing
    }

    function _onSetAnswerError () {

      if (_aborted || _cbCalled) return

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_LIVE_CONNECTION,
        undefined,
        'Bad answer'
      ))
    }

    function _onAnswerError (error) {

      if (_aborted || _cbCalled) return

      _onSubscriptionError(error, '_onAnswerError')

      Logger.error('onAnswer - Error', error)
    }

    function _onAnswerComplete () {

      if (_aborted || _cbCalled || !_answerSubscriptionReady) return

      _onSubscriptionError(null, '_onAnswerComplete')

      Logger.debug('onAnswer - Complete')
    }

    /**
     * @private
     * @param {RTCIceCandidate|RTCIceCandidateInit} iceCandidate
     */
    function _onRemoteIceCandidateNext (iceCandidate) {

      var _candidate, split, idx, _type

      if (_aborted || _cbCalled) return

      Logger.debug(
        'ICE candidate received',
        (Date.now() - _tSubscriptionsAndServerReady)
      )

      if (iceCandidate) {

        if (iceCandidate.type) {

          _type = iceCandidate.type

        } else {

          _candidate = iceCandidate.candidate

          split = _candidate.split(' ')

          idx = split.indexOf('typ')

          if (idx > -1) {

            _type = split[idx + 1]
          }
        }
      }

      _connectStats.liveRemoteIceCandidates.push({
        time: Date.now() - _tSubscriptionsAndServerReady,
        iceCandidate: iceCandidate,
        type: _type || '-'
      })

      if (_answer && _basRemoteServer) {

        _basRemoteServer.addIceCandidate(iceCandidate)

      } else {

        _remoteIceCandidates.push(iceCandidate)
      }
    }

    function _onRemoteIceCandidateError (error) {

      if (_aborted || _cbCalled) return

      _onSubscriptionError(error, '_onRemoteIceCandidateError')

      Logger.error('onRemoteIceCandidate - Error', error)
    }

    function _onRemoteIceCandidateComplete () {

      if (_aborted || _cbCalled) return

      _onSubscriptionError(null, '_onRemoteIceCandidateComplete')

      Logger.debug('onRemoteIceCandidate - complete')
    }

    /**
     * @private
     * @param {?RTCIceCandidate} iceCandidate
     */
    function _onIceCandidate (iceCandidate) {

      var _candidate, split, idx, _type

      if (_aborted || _cbCalled) return

      if (_subscriptionsReady && iceCandidate && iceCandidate.candidate) {

        if (iceCandidate.type) {

          _type = iceCandidate.type

        } else {

          _candidate = iceCandidate.candidate

          split = _candidate.split(' ')

          idx = split.indexOf('typ')

          if (idx > -1) {

            _type = split[idx + 1]
          }
        }

        _connectStats.liveLocalIceCandidates.push({
          time: Date.now() - _tSubscriptionsAndServerReady,
          iceCandidate: iceCandidate,
          type: _type || '-'
        })

        _sendOurIceCandidate(iceCandidate)
      }
    }

    /**
     * @private
     * @param {boolean} connected
     */
    function _onRemoteProxyConnected (connected) {

      if (_aborted || _cbCalled) return

      if (_basRemoteServer.isRemoteProxyConnected() &&
        _basRemoteServer.isRemoteCoreConnected()) {

        _onRemoteConnected(true)

      } else {

        if (connected) {

          _clearRemoteCoreConnectTimeout()
          _remoteCoreConnectTimeoutId = setTimeout(
            _onRemoteCoreTimeout,
            _WAIT_FOR_DATA_CHANNEL_CONNECTIONS_MS
          )

        } else {

          _onRemoteConnected(false)
        }
      }
    }

    /**
     * @private
     * @param {boolean} connected
     */
    function _onRemoteCoreConnected (connected) {

      if (_aborted || _cbCalled) return

      if (_basRemoteServer.isRemoteProxyConnected() &&
        _basRemoteServer.isRemoteCoreConnected()) {

        _onRemoteConnected(true)

      } else {

        if (connected) {

          _clearRemoteProxyConnectTimeout()
          _remoteProxyConnectTimeoutId = setTimeout(
            _onRemoteProxyTimeout,
            _WAIT_FOR_DATA_CHANNEL_CONNECTIONS_MS
          )

        } else {

          _onRemoteConnected(false)
        }
      }
    }

    function _onRemoteProxyTimeout () {

      if (_aborted || _cbCalled) return

      _onRemoteConnected(false)
    }

    function _onRemoteCoreTimeout () {

      if (_aborted || _cbCalled) return

      _onRemoteConnected(false)
    }

    /**
     * @private
     * @param {boolean} connected
     */
    function _onRemoteConnected (connected) {

      _cleanup()

      if (connected) {

        _tConnected = Date.now()

        Logger.debug(
          'BasRemote connected',
          (_tConnected - _tConnectionStart),
          (_tConnected - _tSubscriptionsAndServerReady)
        )

        _connectStats.liveTotal =
          _tConnected - _tConnectionStart
        _connectStats.liveWebRTCTime =
          _tConnected - _tSubscriptionsAndServerReady

        _result.basServer = _basRemoteServer
        _result.basServer.basConnectStats = _connectStats

        // Clear reference
        _basRemoteServer = null

        _cb(null)

      } else {

        _clearBasRemoteServer()

        _cb(new BasError(
          BAS_ERRORS.T_CONNECTION,
          undefined,
          'Connection error'
        ))
      }
    }

    /**
     * @private
     * @param {RTCIceCandidate} iceCandidate
     */
    function _sendOurIceCandidate (iceCandidate) {

      var _tStart

      _tStart = Date.now()

      BasLiveAccount.sendIceCandidate(_uuid, projectId, iceCandidate)
        .then(_onSendOurIceCandidate, _onSendOurIceCandidateError)

      function _onSendOurIceCandidate () {

        Logger.debug(
          'Send ICE candidate',
          (Date.now() - _tStart)
        )
      }

      function _onSendOurIceCandidateError () {

        // TODO Handle T_LIVE_NOT_AUTHORIZED and T_LIVE_NO_INTEGRATOR_ACCESS?
        // Handling on sendOurOffer should be sufficient
      }
    }

    function _onSubscriptionError (error, msg) {

      if (_aborted || _cbCalled) return

      _cleanup(true)

      _cb(_checkError(
        new BasError(
          BAS_ERRORS.T_LIVE_CONNECTION,
          error,
          'Bad subscription: ' + msg
        ),
        error
      ))
    }

    function _onWebRTCTimeout () {

      if (_aborted || _cbCalled) return

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_TIMEOUT,
        undefined,
        'WebRTC timeout'
      ))
    }

    function _onConnectionTimeout () {

      if (_aborted || _cbCalled) return

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_TIMEOUT,
        undefined,
        BAS_ERRORS.M_TIMEOUT
      ))
    }

    /**
     * @private
     * @param {BasError} defaultError
     * @param {*} actualError
     * @returns {BasError}
     */
    function _checkError (defaultError, actualError) {

      if (actualError) {
        switch (actualError.basType) {
          case BAS_ERRORS.T_LIVE_NOT_AUTHORIZED:
          case BAS_ERRORS.T_LIVE_NO_INTEGRATOR_ACCESS:
          case BAS_ERRORS.T_LIVE_NO_ACTIVE_SERVERS_FOUND:
          case BAS_ERRORS.T_LIVE_WAF:
            return actualError
        }
      }

      return defaultError
    }

    function _setBasRemoteServerListeners () {

      _clearBasRemoteServerListeners()

      if (_basRemoteServer) {

        _basRemoteServerListeners.push(BasUtil.setEventListener(
          _basRemoteServer,
          BAS_API.BasRemoteServer.EVT_ICE_CANDIDATE,
          _onIceCandidate
        ))
        _basRemoteServerListeners.push(BasUtil.setEventListener(
          _basRemoteServer,
          BAS_API.BasRemoteServer.EVT_REMOTE_PROXY_CONNECTED,
          _onRemoteProxyConnected
        ))
        _basRemoteServerListeners.push(BasUtil.setEventListener(
          _basRemoteServer,
          BAS_API.BasRemoteServer.EVT_REMOTE_CORE_CONNECTED,
          _onRemoteCoreConnected
        ))
      }
    }

    function _clearBasRemoteServer () {

      _clearBasRemoteServerListeners()
      if (_basRemoteServer) _basRemoteServer.destroy()
      _basRemoteServer = null
    }

    function _clearConnectionTimeout () {

      clearTimeout(_connectionTimeoutId)
      _connectionTimeoutId = 0
    }

    function _clearSecondIceServersTimeout () {

      clearTimeout(_secondIceServersRequestTimeoutId)
      _secondIceServersRequestTimeoutId = 0
    }

    function _clearSubscriptionsAndIceServersTimeout () {

      clearTimeout(_subscriptionsAndIceServersTimeoutId)
      _subscriptionsAndIceServersTimeoutId = 0
    }

    function _clearWebRTCTimeout () {

      clearTimeout(_webRTCTimeoutId)
      _webRTCTimeoutId = 0
    }

    function _clearRemoteProxyConnectTimeout () {

      clearTimeout(_remoteProxyConnectTimeoutId)
      _remoteProxyConnectTimeoutId = 0
    }

    function _clearRemoteCoreConnectTimeout () {

      clearTimeout(_remoteCoreConnectTimeoutId)
      _remoteCoreConnectTimeoutId = 0
    }

    function _clearBasRemoteServerListeners () {

      BasUtil.executeArray(_basRemoteServerListeners)
      _basRemoteServerListeners = []
    }

    function _clearAppSyncEventsListener () {

      BasUtil.execute(_appSyncEventListener)
      _appSyncEventListener = null
    }

    function _clearAnswerSubscription () {

      _answerSubscriptionReady = false

      if (_answerSubscription) {

        _answerSubscription.unsubscribe()

        _answerSubscription = null
      }
    }

    function _clearRemoteIceCandidatesSubscription () {

      _remoteIceCandidatesSubscriptionReady = false

      if (_remoteIceCandidatesSubscription) {

        _remoteIceCandidatesSubscription.unsubscribe()
      }
    }

    function _clearSubscriptions () {

      _clearAppSyncEventsListener()
      _clearAnswerSubscription()
      _clearRemoteIceCandidatesSubscription()
    }

    /**
     * @private
     * @param {boolean} [clearBasServer = false]
     */
    function _cleanup (clearBasServer) {

      _clearConnectionTimeout()
      _clearSecondIceServersTimeout()
      _clearSubscriptionsAndIceServersTimeout()
      _clearWebRTCTimeout()
      _clearRemoteProxyConnectTimeout()
      _clearRemoteCoreConnectTimeout()
      _clearSubscriptions()
      _clearBasRemoteServerListeners()

      if (clearBasServer) {

        _clearBasRemoteServer()
      }
    }

    /**
     * @returns {?BasRemoteServer}
     */
    function takeBasServer () {

      var basServer

      basServer = _result.basServer
      _result.basServer = null

      return basServer
    }

    function abort () {

      _aborted = true

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_ABORT,
        undefined,
        BAS_ERRORS.M_ABORTED
      ))
    }

    /**
     * @private
     * @param {?BasError} error
     */
    function _cb (error) {

      if (!_cbCalled) {

        _cbCalled = true

        _result.finished = true

        if (error) _result.error = error

        if (BasUtil.isFunction(callback)) {

          callback(error, _result)
        }
      }
    }
  }
}
