'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basMessagesCarousel', [
    'BAS_HTML',
    basMessagesCarouselDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basMessagesCarouselDirective
 */
function basMessagesCarouselDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.messagesCarousel,
    controller: [
      '$scope',
      '$rootScope',
      '$element',
      'Swiper',
      'BAS_MESSAGES',
      'BasMessages',
      'ModalService',
      'BasUtilities',
      basMessagesCarouselController
    ],
    controllerAs: 'basMessagesCarousel'
  }

  /**
   * @param $scope
   * @param $rootScope
   * @param $element
   * @param Swiper
   * @param {BAS_MESSAGES} BAS_MESSAGES
   * @param {BasMessages} BasMessages
   * @param {ModalService} ModalService
   * @param {BasUtilities} BasUtilities
   */
  function basMessagesCarouselController (
    $scope,
    $rootScope,
    $element,
    Swiper,
    BAS_MESSAGES,
    BasMessages,
    ModalService,
    BasUtilities
  ) {

    var basMessagesCarousel = this
    var element, nextElement, previousElement, swiper, listener

    basMessagesCarousel.messages = BasMessages.get()

    basMessagesCarousel.$postLink = _onPostLink

    function _onPostLink () {

      $scope.$on('$destroy', cleanUp)

      listener = $rootScope.$on(
        BAS_MESSAGES.EVT_MESSAGES_UPDATED,
        onMessagesChanged
      )

      BasUtilities.waitForFrames(2, initSwiper)

      function initSwiper () {

        element = $element[0]
        nextElement =
          element.getElementsByClassName('swiper-button-next')[0]
        previousElement =
          element.getElementsByClassName('swiper-button-prev')[0]

        swiper = new Swiper(
          element,
          {
            navigation: {
              nextEl: nextElement,
              prevEl: previousElement
            },
            watchOverflow: true,
            speed: 500,
            passiveListeners: false
          }
        )

        swiper.on('click', onSwiperClick)
      }
    }

    function onMessagesChanged () {

      BasUtilities.waitFrames(2).then(updateSwiper)
    }

    function updateSwiper () {

      if (swiper) swiper.update()
    }

    function onSwiperClick (_swiper, _event) {

      var uuid

      if (Array.isArray(basMessagesCarousel.messages.messages)) {

        if (basMessagesCarousel.messages.messages[swiper.clickedIndex]) {

          uuid = basMessagesCarousel.messages.messages[swiper.clickedIndex].uuid
        }
      }

      if (BasUtil.isNEString(uuid)) {

        ModalService.showModal({
          template: BAS_HTML.messageModal,
          controller: 'messageModalCtrl',
          controllerAs: 'modal',
          inputs: {
            messageUuid: uuid
          }
        })

        if (_event.cancelable) _event.preventDefault()
      }
    }

    function cleanUp () {

      if (swiper) swiper.destroy(true, false)
      swiper = null

      BasUtil.execute(listener)
      listener = null
    }
  }
}
