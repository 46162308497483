'use strict'

// Languages
import LANG_EN_GB from '../../lang/en_GB.json'

import LANG_DE_DE from '../../lang/de_DE.json'
import LANG_NL_NL from '../../lang/nl_NL.json'
import LANG_FR_FR from '../../lang/fr_FR.json'
import LANG_ES_ES from '../../lang/es_ES.json'
import LANG_IT_IT from '../../lang/it_IT.json'
import LANG_RU_RU from '../../lang/ru_RU.json'
import LANG_PL_PL from '../../lang/pl_PL.json'
import LANG_LT_LT from '../../lang/lt_LT.json'
import LANG_ZH_CN from '../../lang/zh_CN.json'
import LANG_JA_JP from '../../lang/ja_JP.json'
import LANG_UK_UA from '../../lang/uk_UA.json'

angular
  .module('basalteApp')
  .config([
    '$windowProvider',
    '$translateProvider',
    'LANGUAGES',
    'BAS_LANGUAGES',
    'LoggerProvider',
    languageConfig
  ])

/**
 * @param $windowProvider
 * @param $translateProvider
 * @param {LANGUAGES} LANGUAGES
 * @param {BAS_LANGUAGES} BAS_LANGUAGES
 * @param LoggerProvider
 */
function languageConfig (
  $windowProvider,
  $translateProvider,
  LANGUAGES,
  BAS_LANGUAGES,
  LoggerProvider
) {
  var $window, locale, i, length, lang

  $window = $windowProvider.$get()

  $translateProvider.addInterpolation(
    '$translateMessageFormatInterpolation'
  )

  if (LoggerProvider.getLevel() > LoggerProvider.NONE) {

    $translateProvider.useMissingTranslationHandlerLog()
  }

  // Languages
  $translateProvider.translations('en_GB', LANG_EN_GB)
  $translateProvider.translations('nl_NL', LANG_NL_NL)
  $translateProvider.translations('de_DE', LANG_DE_DE)
  $translateProvider.translations('fr_FR', LANG_FR_FR)
  $translateProvider.translations('es_ES', LANG_ES_ES)
  $translateProvider.translations('it_IT', LANG_IT_IT)
  $translateProvider.translations('ru_RU', LANG_RU_RU)
  $translateProvider.translations('pl_PL', LANG_PL_PL)
  $translateProvider.translations('lt_LT', LANG_LT_LT)
  $translateProvider.translations('zh_CN', LANG_ZH_CN)
  $translateProvider.translations('ja_JP', LANG_JA_JP)
  $translateProvider.translations('uk_UA', LANG_UK_UA)

  $translateProvider.useMissingTranslationHandler(
    'basMissingTranslationHandler'
  )

  locale = $window.navigator.language

  if (locale) {

    length = LANGUAGES.length
    for (i = 0; i < length; i++) {

      lang = LANGUAGES[i]

      if (locale.indexOf(lang.langCode) === 0) {

        $translateProvider.preferredLanguage(lang.code)

        break
      }
    }

  } else {

    $translateProvider.preferredLanguage(BAS_LANGUAGES.DEF_LANGUAGE)
  }

  // Set preferred language and sanitize strategy
  $translateProvider.fallbackLanguage(BAS_LANGUAGES.DEF_LANGUAGE)
  $translateProvider.preferredLanguage(BAS_LANGUAGES.DEF_LANGUAGE)
  $translateProvider.useSanitizeValueStrategy(null)
  $translateProvider.allowNamespaces(false)
}
