'use strict'

angular
  .module('basalteApp')
  .service('VideoState', [
    '$transitions',
    'STATES',
    'CurrentRoom',
    'BasStateHelper',
    'LibraryState',
    VideoState
  ])

/**
 * @constructor
 * @param $transitions
 * @param {STATES} STATES
 * @param {CurrentRoom} CurrentRoom
 * @param {BasStateHelper} BasStateHelper
 * @param {LibraryState} LibraryState
 */
function VideoState (
  $transitions,
  STATES,
  CurrentRoom,
  BasStateHelper,
  LibraryState
) {

  init()

  function init () {

    $transitions.onStart(
      {
        to: STATES.VIDEO + '.**'
      },
      _onVideoState
    )
  }

  function _onVideoState (transition) {

    var target, toName, video
    var basSource

    target = transition.targetState()
    toName = target.name()

    if (BasStateHelper.hasBaseState(toName, STATES.VIDEO_SOURCES)) {

      video = CurrentRoom.getRoomVideo()

      if (
        !video ||
        video.getCompatibleSources().length < 2
      ) {

        return target.withState(STATES.VIDEO_PLAYER)
      }

    } else if (BasStateHelper.hasBaseState(toName, STATES.VIDEO_FAVOURITES)) {

      basSource = CurrentRoom.getVideoBasSource()

      if (!basSource || !basSource.favourites) {

        return target.withState(STATES.VIDEO_PLAYER)

      } else {

        LibraryState.setPlayer(basSource.getId())
      }
    }
  }
}
