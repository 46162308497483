'use strict'

const Device = require('./device')
const GenericDeviceV1 = require('./generic_device_v1')
const LightDevice = require('./light_device')
var P = require('./parser_constants')
const ThermostatDevice = require('./thermostat_device')

/**
 * @typedef {Object} TGenericDeviceCategory
 * @property {string} name
 * @property {GenericDeviceV1[]} genericDevices
 */

/**
 * @constructor
 * @extends GenericDevice
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function PoolDevice (device, basCore) {
  Device.call(this, device, basCore)

  /**
   * @type {ThermostatDevice[]}
   * @private
   */
  this._thermostats = device[P.THERMOSTATS]?.map(function (d) {
    return new ThermostatDevice(d, basCore)
  }) ?? []

  /**
   * @type {GenericDeviceV1[]}
   * @private
   */
  this._genericDevices = device[P.GENERIC_DEVICES]?.map(function (d) {
    return new GenericDeviceV1(d, basCore)
  }) ?? []

  /**
   * @type {LightDevice[]}
   * @private
   */
  this._lights = device[P.LIGHTS]?.map(function (d) {
    return new LightDevice(d, basCore)
  }) ?? []

  /**
   * @type {Object}
   * @private
   */
  this._state = device[P.STATE] ?? null

  this.parse(device, { emit: false })
}

PoolDevice.prototype = Object.create(Device.prototype)
PoolDevice.prototype.constructor = PoolDevice

/**
 * @name Device#thermostats
 * @type {number}
 * @readonly
 */
Object.defineProperty(PoolDevice.prototype, 'thermostats', {
  get: function () {
    return this._thermostats
  }
})

/**
 * @name Device#genericDevices
 * @type {number}
 * @readonly
 */
Object.defineProperty(PoolDevice.prototype, 'genericDevices', {
  get: function () {
    return this._genericDevices
  }
})

/**
 * @name Device#lights
 * @type {number}
 * @readonly
 */
Object.defineProperty(PoolDevice.prototype, 'lights', {
  get: function () {
    return this._lights
  }
})

/**
 * @name Device#state
 * @type {Object}
 * @readonly
 */
Object.defineProperty(PoolDevice.prototype, 'ph', {
  get: function () {
    return this._state?.[P.PH]
  }
})

module.exports = PoolDevice
