'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasCoreClientHelper', [
    'BAS_API_CORE',
    'BAS_ROOMS',
    'BAS_CORE_CLIENT',
    BasCoreClientHelper
  ])

/**
 * @typedef {Object} TBasCoreClientLoginInfo
 * @property {string} username
 * @property {string} password
 */

/**
 * @typedef {Object} TBasCoreClientInfo
 * @property {BasRoom} room
 * @property {BasCoreClientDevice} device
 */

/**
 * @constructor
 * @param {BAS_API_CORE} BAS_API_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 */
function BasCoreClientHelper (
  BAS_API_CORE,
  BAS_ROOMS,
  BAS_CORE_CLIENT
) {
  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  this.getBasCoreClientInfo = getBasCoreClientInfo
  this.canReceiveIntercomCall = canReceiveIntercomCall
  this.getDiscoveryHttpUri = getDiscoveryHttpUri
  this.getHomeHttpUri = getHomeHttpUri
  this.processCoreClientInfo = processCoreClientInfo

  /**
   * Core client info by uuid
   * The macStr of the current core client is used if no uuid is provided.
   *
   * @param {string} [coreClientUuid]
   * @returns {?TBasCoreClientInfo}
   */
  function getBasCoreClientInfo (coreClientUuid) {

    var keys, length, i, roomId, room, coreClientDevice

    if (
      !BAS_CORE_CLIENT.STATE.macNum &&
      !BasUtil.isNEString(coreClientUuid)
    ) {

      return null
    }

    keys = Object.keys(rooms.rooms)
    length = keys.length
    for (i = 0; i < length; i++) {

      roomId = keys[i]

      if (roomId) {

        room = rooms.rooms[roomId]

        if (
          room && room.coreClientDevices &&
          room.coreClientDevices.getCoreClientByUuid
        ) {

          coreClientDevice = BasUtil.isNEString(coreClientUuid)
            ? room.coreClientDevices.getCoreClientByUuid(coreClientUuid)
            : room.coreClientDevices.getCoreClientByMac(
              BAS_CORE_CLIENT.STATE.macStr
            )

          if (coreClientDevice) {

            return {
              room: room,
              device: coreClientDevice
            }
          }
        }
      }
    }

    return null
  }

  /**
   * Can THIS Core Client device receive intercom calls
   *
   * @returns {boolean}
   */
  function canReceiveIntercomCall () {

    var coreClientInfo

    if (BAS_CORE_CLIENT.STATE.macNum) {

      coreClientInfo = getBasCoreClientInfo()

      if (coreClientInfo && coreClientInfo.device) {

        return coreClientInfo.device.receiveIntercom
      }
    }

    return false
  }

  /**
   * @param {string} [coreClientDeviceUuid]
   * @returns {string}
   */
  function getDiscoveryHttpUri (coreClientDeviceUuid) {

    var basCoreClientInfo

    if (
      BAS_CORE_CLIENT.STATE.macNum ||
      BasUtil.isNEString(coreClientDeviceUuid)
    ) {

      basCoreClientInfo = getBasCoreClientInfo(coreClientDeviceUuid)

      if (basCoreClientInfo && basCoreClientInfo.device) {

        return basCoreClientInfo.device.discoveryHttpURI
      }
    }

    return ''
  }

  /**
   * @param {string} [coreClientDeviceUuid]
   * @returns {string}
   */
  function getHomeHttpUri (coreClientDeviceUuid) {

    var basCoreClientInfo

    if (
      BAS_CORE_CLIENT.STATE.macNum ||
      BasUtil.isNEString(coreClientDeviceUuid)
    ) {

      basCoreClientInfo = getBasCoreClientInfo(coreClientDeviceUuid)

      if (basCoreClientInfo && basCoreClientInfo.device) {

        return basCoreClientInfo.device.homeHttpURI
      }
    }

    return ''
  }

  /**
   * @param {?TCoreClientDeviceInfo} result
   * @returns {?TBasCoreClientLoginInfo}
   */
  function processCoreClientInfo (result) {

    var coreClientObj, username, password

    if (BasUtil.isObject(result)) {

      if (BasUtil.isObject(result[BAS_API_CORE.K_DEVICE])) {

        coreClientObj = result[BAS_API_CORE.K_DEVICE]

      } else if (BasUtil.isObject(result[BAS_API_CORE.K_ELLIE])) {

        coreClientObj = result[BAS_API_CORE.K_ELLIE]
      }
    }

    if (coreClientObj) {

      username = coreClientObj[BAS_API_CORE.K_UUID]
      password = coreClientObj[BAS_API_CORE.K_PASSWORD]

      if (BasUtil.isNEString(username)) {

        return {
          username: username,
          password: BasUtil.isNEString(password) ? password : ''
        }
      }
    }

    return null
  }
}
