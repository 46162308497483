'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('PlayUriHelper', [
    'BAS_ERRORS',
    'BAS_MODAL',
    'BasModal',
    'CurrentRoom',
    'BasCommandQueue',
    PlayUriHelper
  ])

/**
 * @callback CBasPlayUriPromiseAbort
 */

/**
 * @typedef {Object} TPlayUriWithErrorModalOptions
 * @property {number} [timeout]
 * @property {boolean} [showModalOnError]
 * @property {string} [contextUri]
 * @property {number} [contextOffset]
 */

/**
 * @typedef {Object} TPlayUriOptions
 * @property {string} [contextUri]
 * @property {number} [contextOffset]
 */

/**
 * @constructor
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasModal} BasModal
 * @param {CurrentRoom} CurrentRoom
 * @param {BasCommandQueue} BasCommandQueue
 */
function PlayUriHelper (
  BAS_ERRORS,
  BAS_MODAL,
  BasModal,
  CurrentRoom,
  BasCommandQueue
) {

  var RESOLVE_MAX_DELAY_MS = 700

  /**
   * @type {?CBasPlayUriPromiseAbort}
   */
  var lastPlayUriPromiseAbort = null

  this.playUri = playUri
  this.playUriWithErrorModal = playUriWithErrorModal

  /**
   * Wrapping function around 'basSource.playUri' when 'this.isAudioSource' is
   * true. When the result of a previous 'playUri' action is not yet known and
   * a new 'playUri' action is executed, the previous 'playUri' promise will be
   * rejected with the error BAS_ERRORS.T_ABORT. Based on this error the UI can
   * then choose to not visualize this error.
   *
   * @param {BasSource} basSource
   * @param {string|string[]} uri
   * @param {TPlayUriOptions} [options]
   * @returns {Promise}
   */
  function playUri (
    basSource,
    uri,
    options
  ) {

    var _contextUri, _contextOffset

    if (options) {

      _contextUri = options.contextUri
      _contextOffset = options.contextOffset
    }

    if (
      basSource && (
        basSource.isAudioSource ||
        basSource.isVideoSource
      )
    ) {

      BasUtil.exec(lastPlayUriPromiseAbort, BAS_ERRORS.T_ABORT)

      return new Promise(playUriPromiseConstructor)
    }

    return Promise.reject('Invalid source')

    function playUriPromiseConstructor (resolve, reject) {

      var aborted = false

      lastPlayUriPromiseAbort = abort

      if (basSource.isAudioSource) {

        BasCommandQueue.audioSourcePlayUri(
          basSource.uuid,
          uri,
          {
            contextUri: _contextUri,
            contextOffset: _contextOffset
          }
        )
          .then(doResolve, doReject)
      } else {

        BasCommandQueue.videoSourcePlayUri(basSource.uuid, uri)
          .then(doResolve, doReject)
      }

      function doResolve () {

        if (!aborted) clearPlayUriPromiseAbort()
        resolve.apply(null, arguments)
      }

      function doReject () {

        if (!aborted) clearPlayUriPromiseAbort()
        reject.apply(null, arguments)
      }

      function abort () {

        aborted = true
        clearPlayUriPromiseAbort()
        reject.apply(null, arguments)
      }
    }

    function clearPlayUriPromiseAbort () {

      lastPlayUriPromiseAbort = null
    }
  }

  /**
   * Wrapping function around 'this.source.playUri' when 'this.isAudioSource' is
   *  true. When the result of a previous 'playUri' action is not yet known and
   *  a new 'playUri' action is executed, the previous 'playUri' promise will be
   *  ignored. If a result is a received within 700ms (or given timeout), the
   *  promise will be resolved/rejected based on that result. Else, the promise
   *  is resolved. In both cases, an error is handled by showing a modal if the
   *  source has not changed since calling this function (Avoid error messages
   *  for no longer relevant actions)
   *
   * @param {BasSource} basSource
   * @param {string} uri
   * @param {TPlayUriWithErrorModalOptions} [options]
   * @returns {Promise}
   */
  function playUriWithErrorModal (
    basSource,
    uri,
    options
  ) {

    var _this, _timeout, _contextUri, _contextOffset

    _timeout = RESOLVE_MAX_DELAY_MS

    if (options) {

      if (BasUtil.isPNumber(options.timeout)) _timeout = options.timeout

      _contextUri = options.contextUri
      _contextOffset = options.contextOffset
    }

    _this = this

    return new Promise(playUriWithErrorModalPromiseConstructor)

    function playUriWithErrorModalPromiseConstructor (resolve, reject) {

      setTimeout(onTimeout, _timeout)

      _this.playUri(
        basSource,
        uri,
        {
          contextUri: _contextUri,
          contextOffset: _contextOffset
        }
      )
        .then(onPlayUri, onPlayUriError)

      function onPlayUri () {

        resolve()
      }

      function onPlayUriError (err) {

        var currentBasSource

        currentBasSource = CurrentRoom.getBasSource()

        // If source is the same as the source on which the playUri action was
        //  executed and the error is not abort-related, show an error
        if (
          currentBasSource &&
          currentBasSource.getId() === basSource.getId() &&
          err !== BAS_ERRORS.T_ABORT
        ) {

          BasModal.show(BAS_MODAL.T_ERROR)
        }

        reject(err)
      }

      function onTimeout () {

        resolve()
      }
    }
  }
}
