{
  "GeorgeTopHits": {
    "name": "Top Hits",
    "mtime": 1508769598,
    "songs": [
      22, 19, 54, 14, 11, 6, 21, 7, 16, 2, 47, 48
    ]
  },
  "GeorgeWeekend": {
    "name": "Weekend",
    "mtime": 1508769598,
    "songs": [
      2, 40, 16, 37, 35, 20, 9, 13, 6, 34, 36, 42, 43, 1, 17
    ]
  },
  "GeorgeWorkout": {
    "name": "Workout",
    "mtime": 1508769598,
    "songs": [
      13, 14, 5, 25, 41, 42, 37, 7, 4, 36, 39, 11, 38
    ]
  },
  "GeorgeRelax": {
    "name": "Relax",
    "mtime": 1508769598,
    "songs": [
      14, 24, 2, 44, 29, 10, 37, 22, 16, 28, 38, 36, 12, 15, 42, 35, 13
    ]
  },
  "JaneRelax": {
    "mtime": 1512652401,
    "name": "Relax",
    "songs": [
      9, 8, 5, 25, 10, 26, 7, 37, 19, 44, 34, 27, 16, 21, 13, 18
    ]
  },
  "JaneCooking": {
    "mtime": 1512652401,
    "name": "Cooking",
    "songs": [
      2, 12, 16, 3, 26, 29, 39, 23, 10, 31, 24, 34, 9, 45
    ]
  },
  "JaneWorkout": {
    "mtime": 1512652401,
    "name": "Workout",
    "songs": [
      18, 36, 16, 24, 1, 9, 42, 46, 27, 14, 5, 37
    ]
  },
  "JaneWeekend": {
    "mtime": 1512652401,
    "name": "Weekend",
    "songs": [
      23, 21, 16, 12, 33, 31, 3, 42, 17, 38, 29, 11, 30, 7, 2, 41, 8, 4, 27, 9, 34
    ]
  },
  "JudyFitness": {
    "mtime": 1512652500,
    "name": "Fitness",
    "songs": [
      43, 20, 31, 2, 1, 5, 26, 22, 25, 27, 32
    ]
  },
  "JudyParty": {
    "mtime": 1512652500,
    "name": "Party",
    "songs": [
      35, 46, 32, 13, 36, 34, 28, 17, 26, 42, 11, 5, 41, 23, 2
    ]
  },
  "JudyRelax": {
    "mtime": 1512652500,
    "name": "Relax",
    "songs": [
      18, 45, 27, 22, 19, 8, 30, 29, 31, 9, 13, 40, 46, 42, 35, 32, 28, 10
    ]
  },
  "JudyStudy": {
    "mtime": 1512652500,
    "name": "Study",
    "songs": [
      6, 8, 19, 10, 22, 3, 20, 16, 36, 9, 23, 25, 39
    ]
  },
  "ElroyParty": {
    "mtime": 1512652455,
    "name": "Party",
    "songs": [
      36, 29, 43, 15, 21, 37, 14, 12, 28, 41, 33, 22, 45, 16, 46, 25, 24, 38
    ]
  },
  "ElroyFitness": {
    "mtime": 1512652455,
    "name": "Fitness",
    "songs": [
      39, 26, 3, 46, 33, 2, 7, 22, 40, 13, 19, 36, 10, 20, 29, 15, 42, 38
    ]
  },
  "ElroyStudy": {
    "mtime": 1512652455,
    "name": "Study",
    "songs": [
      16, 41, 5, 21, 38, 4, 19, 26, 3
    ]
  },
  "ElroyRelax": {
    "mtime": 1512652455,
    "name": "Relax",
    "songs": [
      14, 15, 8, 18, 11, 10, 26, 34, 39, 31, 17, 29, 45, 24, 19, 42, 16,
      33, 12, 32
    ]
  },
  "iTunesTop25": {
    "iTunes": true,
    "mtime": 1512652113,
    "name": "Top 25 Most Played",
    "songs": [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
      33, 34, 38, 39, 40
    ]
  },
  "Lisa": {
    "mtime": 1512652455,
    "name": "This is Lisa Stansfield",
    "songs": [
      49, 50, 51, 52
    ]
  }
}
