'use strict'

angular
  .module('basalteApp')
  .service('BasFavouritesHelper', [
    'BAS_FAVOURITE',
    BasFavouritesHelper
  ])

/**
 * @constructor
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 */
function BasFavouritesHelper (
  BAS_FAVOURITE
) {
  this.getUuid = getUuid
  this.getKey = getKey

  /**
   * Generates the favourite UUID that will match with a real favourite
   *
   * @param {number} type
   * @param {(string|number)} id
   * @returns {string}
   */
  function getUuid (type, id) {

    var uuid = ''

    switch (type) {
      case BAS_FAVOURITE.T_LOCAL_PLAYLIST:

        uuid = BAS_FAVOURITE._K_PLAYLIST + id

        break
      case BAS_FAVOURITE.T_RADIO:

        uuid = BAS_FAVOURITE._K_GID + id

        break
      case BAS_FAVOURITE.T_DEEZER:

        uuid = BAS_FAVOURITE._K_DEEZER_ID + id

        break
      case BAS_FAVOURITE.T_TIDAL:

        uuid = BAS_FAVOURITE._K_TIDAL_ID + id

        break
      case BAS_FAVOURITE.T_SPOTIFY_CONNECT:

        uuid = BAS_FAVOURITE._K_URI + id

        break
      case BAS_FAVOURITE.T_SONOS:

        uuid = BAS_FAVOURITE._K_AUDIO + id

        break
    }

    return uuid
  }

  /**
   * Returns the key for the type given.
   * This key can be used to query the favourites of that type
   * on a BasSourceFavourites instance.
   *
   * @param {number} type
   * @returns {string}
   */
  function getKey (type) {

    switch (type) {
      case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
        return 'local'
      case BAS_FAVOURITE.T_RADIO:
        return 'radio'
      case BAS_FAVOURITE.T_DEEZER:
        return 'deezer'
      case BAS_FAVOURITE.T_TIDAL:
        return 'tidal'
      case BAS_FAVOURITE.T_SPOTIFY:
        return 'spotify'
      case BAS_FAVOURITE.T_SPOTIFY_CONNECT:
        return 'spotifyConnect'
      default:
        return ''
    }
  }
}
