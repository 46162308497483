'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLibraryDetail', [
    basLibraryDetailFactory
  ])

function basLibraryDetailFactory () {

  /**
   * Object that represents a detail header
   *
   * @constructor
   */
  function BasLibraryDetail () {

    /**
     * @instance
     * @type {string}
     */
    this.title = ''

    /**
     * @instance
     * @type {string}
     */
    this.subtitle = ''

    /**
     * @instance
     * @type {string}
     */
    this.subtitleExtra = ''

    /**
     * @instance
     * @type {number}
     */
    this.numberOfItems = 0

    /**
     * @instance
     * @type {string}
     */
    this.coverArt = ''

    /**
     * @instance
     * @type {string}
     */
    this.tag = ''

    // UI properties

    /**
     * @instance
     * @type {Object}
     */
    this.class = {}

    // Initialize class object
    this.class[BasLibraryDetail.CLASS_COVER] = false
    this.class[BasLibraryDetail.CLASS_NUMBER] = false
    this.class[BasLibraryDetail.CLASS_CONTEXT] = false
    this.class[BasLibraryDetail.CLASS_PLAY] = false
    this.class[BasLibraryDetail.CLASS_ASANO_FAVOURITE] = false
    this.class[BasLibraryDetail.CLASS_SELECT] = false
    this.class[BasLibraryDetail.CLASS_EDIT] = false
    this.class[BasLibraryDetail.CLASS_SHARE] = false
    this.class[BasLibraryDetail.CLASS_HAS_TAG] = false
    this.class[BasLibraryDetail.CLASS_SHOW_SPINNER] = false
  }

  // region CSS classes

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_COVER = 'bas-collection-detail-has-cover'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_NUMBER = 'bas-collection-detail-has-number'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_CONTEXT = 'bas-collection-detail-has-context'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_SELECT =
    'bas-collection-detail-has-select'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_EDIT =
    'bas-collection-detail-has-edit'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_SHARE =
    'bas-collection-detail-has-share'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_PLAY = 'bas-collection-detail-has-play'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_ASANO_FAVOURITE =
    'bas-collection-detail-has-asano-favourite'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_HAS_TAG = 'bas-collection-detail-has-tag'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_HAS_SUBTITLE_EXTRA =
    'bas-collection-detail-subtitle-has-extra'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_SHOW_SPINNER =
    'bas-collection-detail-show-spinner'

  /**
   * @constant {string}
   */
  BasLibraryDetail.CLASS_SHOW_SELECTION_SPINNER =
    'bas-collection-detail-show-selection-spinner'

  // endregion

  // Methods

  /**
   * @param {number} value
   */
  BasLibraryDetail.prototype.setNumberOfItems = function (value) {

    // Check input
    if (BasUtil.isPNumber(value, true)) {
      this.numberOfItems = value
      this.class[BasLibraryDetail.CLASS_NUMBER] = true
    } else {
      this.numberOfItems = 0
      this.class[BasLibraryDetail.CLASS_NUMBER] = false
    }
  }

  /**
   * @param {?string} url
   */
  BasLibraryDetail.prototype.setHasCoverArt = function (url) {

    // Check input
    this.class[BasLibraryDetail.CLASS_COVER] =
      typeof url === 'string'
  }

  /**
   * @param {boolean} value
   */
  BasLibraryDetail.prototype.setCanAsanoFavourite = function (value) {
    this.class[BasLibraryDetail.CLASS_ASANO_FAVOURITE] = value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryDetail.prototype.setCanShare = function (value) {
    this.class[BasLibraryDetail.CLASS_SHARE] = value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryDetail.prototype.setCanSelect = function (value) {
    this.class[BasLibraryDetail.CLASS_SELECT] = value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryDetail.prototype.setCanEdit = function (value) {
    this.class[BasLibraryDetail.CLASS_EDIT] = value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryDetail.prototype.setCanPlay = function (value) {
    this.class[BasLibraryDetail.CLASS_PLAY] = value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryDetail.prototype.setHasContext = function (value) {
    this.class[BasLibraryDetail.CLASS_CONTEXT] = value === true
  }

  /**
   * @param {string} tag
   */
  BasLibraryDetail.prototype.setTag = function (tag) {
    if (BasUtil.isNEString(tag)) {
      this.tag = tag
      this.class[BasLibraryDetail.CLASS_HAS_TAG] = true
    } else {
      this.tag = ''
      this.class[BasLibraryDetail.CLASS_HAS_TAG] = false
    }
  }

  /**
   * @param {boolean} value
   */
  BasLibraryDetail.prototype.setShowSpinner = function (value) {
    this.class[BasLibraryDetail.CLASS_SHOW_SPINNER] = value === true
  }

  /**
   * @param {boolean} value
   */
  BasLibraryDetail.prototype.setShowSelectionSpinner = function (value) {
    this.class[BasLibraryDetail.CLASS_SHOW_SELECTION_SPINNER] =
      value === true
  }

  /**
   * @param {string} value
   */
  BasLibraryDetail.prototype.setHasSubtitleExtra = function (value) {

    if (BasUtil.isNEString(value)) {
      this.subtitleExtra = value
      this.class[BasLibraryDetail.CLASS_HAS_SUBTITLE_EXTRA] = true
    } else {
      this.subtitleExtra = ''
      this.class[BasLibraryDetail.CLASS_HAS_SUBTITLE_EXTRA] = false
    }
  }

  return BasLibraryDetail
}
