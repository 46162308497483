'use strict'

import * as BasUtil from '@basalte/bas-util'
var dirName = 'basUi'

angular
  .module('basalteApp')
  .directive(dirName, [
    'UiHelper',
    'BasUtilities',
    basUiDirective
  ])

/**
 * @param UiHelper
 * @param {BasUtilities} BasUtilities
 * @returns basUi
 */
function basUiDirective (
  UiHelper,
  BasUtilities
) {
  return {
    restrict: 'A',
    link: link
  }

  function link (
    scope,
    element,
    attrs
  ) {
    var domKey

    // Listen for destroy event
    scope.$on('$destroy', onDestroy)

    // Check for valid DOM element key
    if (BasUtil.isNEString(attrs[dirName])) {

      // Store DOM element Key
      domKey = attrs[dirName]

      // Add DOM element
      UiHelper.setDomElement(domKey, element)

      BasUtilities.waitFrames(2).then(setDomElement)
      BasUtilities.waitFrames(5).then(setDomElement)
      BasUtilities.waitFrames(7).then(setDomElement)
      BasUtilities.waitFrames(10).then(setDomElement)
    }

    function setDomElement () {

      // Add DOM element
      UiHelper.setDomElement(domKey, element)
    }

    function onDestroy () {

      // Make sure DOM key is valid
      if (BasUtil.isNEString(domKey)) {

        // Clear DOM element
        UiHelper.clearDomElement(domKey)
      }
    }
  }
}
