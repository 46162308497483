'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasDiscoveryCloud', [
    '$rootScope',
    '$http',
    'BAS_DISCOVERY_CLOUD',
    'BAS_API',
    'BAS_APP_STORAGE',
    'BasStorage',
    'BasUtilities',
    BasDiscoveryCloud
  ])

/**
 * @typedef {Object} TBasDiscoveryCloudServer
 * @property {string[]} addresses
 * @property {number} macN
 * @property {string} [cid]
 * @property {string} [projectName]
 * @property {Object<string, string>} [images]
 * @property {string} [city]
 * @property {string} [country]
 * @property {boolean} [master]
 */

/**
 * @typedef {Object} TBasDiscoveryCloudState
 * @property {Object<string, ?TBasDiscoveryCloudServer>} servers
 * @property {boolean} shouldBeDiscovering
 * @property {boolean} isDiscovering
 * @property {number} restartTime
 * @property {number} currentIntervalTimeoutMs
 * @property {number} lastUpdateTime
 * @property {number} numberOfRequests
 * @property {Object[]} lastDiscoveryResult
 */

/**
 * Service for discovering basCores via api.basalte.net
 *
 * @constructor
 * @param $rootScope
 * @param $http
 * @param {BAS_DISCOVERY_CLOUD} BAS_DISCOVERY_CLOUD
 * @param BAS_API
 * @param {BAS_APP_STORAGE} BAS_APP_STORAGE
 * @param {BasStorage} BasStorage
 * @param {BasUtilities} BasUtilities
 */
function BasDiscoveryCloud (
  $rootScope,
  $http,
  BAS_DISCOVERY_CLOUD,
  BAS_API,
  BAS_APP_STORAGE,
  BasStorage,
  BasUtilities
) {
  /**
   * @type {number[]}
   */
  var FILTER_CORE_IDS = [
    BAS_API.Device.BT.ID_ASANO_S4,
    BAS_API.Device.BT.ID_CORE_MINI,
    BAS_API.Device.BT.ID_CORE_PLUS
  ]

  var MAX_NUM_REQUESTS = Infinity

  var _updateIntervalId = 0

  /**
   * @type {TBasDiscoveryCloudState}
   */
  var state = {}
  state.servers = {}
  state.shouldBeDiscovering = false
  state.isDiscovering = false
  state.restartTime = 0
  state.currentIntervalTimeoutMs = BAS_DISCOVERY_CLOUD.UPDATE_INTERVAL_MS
  state.lastUpdateTime = 0
  state.numberOfRequests = 0
  state.lastDiscoveryResult = []

  this.get = get
  this.restart = restart
  this.stop = stop

  /**
   * @returns {TBasDiscoveryCloudState}
   */
  function get () {

    return state
  }

  /**
   * Start discovery
   */
  function restart () {

    state.shouldBeDiscovering = true

    _clearUpdateInterval()

    state.restartTime = Date.now()
    state.currentIntervalTimeoutMs =
      BAS_DISCOVERY_CLOUD.UPDATE_INTERVAL_MS

    _update()

    _updateIntervalId = setInterval(
      _onUpdateInterval,
      BAS_DISCOVERY_CLOUD.UPDATE_INTERVAL_MS
    )

    state.isDiscovering = true
  }

  /**
   * Stop discovery
   */
  function stop () {

    state.shouldBeDiscovering = false

    _clearUpdateInterval()

    state.isDiscovering = false
  }

  function _onUpdateInterval () {

    var now, diff, maxDiff, nextIntervalTimeoutMs

    if (state.shouldBeDiscovering) {

      if (state.restartTime > 0) {

        now = Date.now()
        diff = now - state.restartTime

        switch (state.currentIntervalTimeoutMs) {
          case BAS_DISCOVERY_CLOUD.UPDATE_INTERVAL_MS:
            // 28s => increase interval to LONG
            maxDiff = 28000
            nextIntervalTimeoutMs =
              BAS_DISCOVERY_CLOUD.UPDATE_INTERVAL_LONG_MS
            break
          case BAS_DISCOVERY_CLOUD.UPDATE_INTERVAL_LONG_MS:
            // 56s => increase interval to LONGER
            maxDiff = 56000
            nextIntervalTimeoutMs =
              BAS_DISCOVERY_CLOUD.UPDATE_INTERVAL_LONGER_MS
            break
          case BAS_DISCOVERY_CLOUD.UPDATE_INTERVAL_LONGER_MS:
            // 112s => stop cloud discovery interval
            maxDiff = 112000
            nextIntervalTimeoutMs = -1
            break
        }

        if (diff > maxDiff) {

          _clearUpdateInterval()

          state.currentIntervalTimeoutMs = nextIntervalTimeoutMs

          if (nextIntervalTimeoutMs > 0) {

            _updateIntervalId = setInterval(
              _onUpdateInterval,
              nextIntervalTimeoutMs
            )
          }
        }

      } else {

        // Should not occur
      }

      _update()
    }
  }

  /**
   * Update discovery state
   * Enforced by update interval even after restarting discovery
   *
   * @private
   */
  function _update () {

    _cachedCloudDiscovery()
      .then(_onCloudDiscovery, _onCloudDiscoveryError)
  }

  /**
   * Only performs an actual update when timing is right
   *
   * @private
   * @returns {Promise<Object[]>}
   */
  function _cachedCloudDiscovery () {

    var now, diff, maxRequests

    now = Date.now()
    diff = now - state.lastUpdateTime

    maxRequests = _getMaxNumOfRequests()

    if (
      diff > BAS_DISCOVERY_CLOUD.MIN_TIME_DIFF_MS &&
      state.numberOfRequests < maxRequests
    ) {

      state.lastUpdateTime = Date.now()
      return _cloudDiscovery()
    }

    return Promise.resolve(state.lastDiscoveryResult)
  }

  function _onCloudDiscovery (result) {

    var length, i, keys, server, servers

    servers = {}

    if (Array.isArray(result)) {

      state.lastDiscoveryResult = result

      // Parse servers
      length = result.length
      for (i = 0; i < length; i++) {

        server = _parseNetworkDiscoveredServer(result[i])

        if (server && BasUtil.isNEArray(server.addresses)) {

          servers[server.macN] = server

          _fetchProjectInfo(server.macN, server)
        }
      }

      // Check if servers are lost
      keys = Object.keys(state.servers)
      length = keys.length
      for (i = 0; i < length; i++) {

        server = state.servers[keys[i]]

        if (server) {
          if (!servers[server.macN]) {

            $rootScope.$emit(
              BAS_DISCOVERY_CLOUD
                .EVT_DISCOVERY_CLOUD_CORE_SERVICE_LOST,
              server
            )
          }
        }
      }

      // Replace servers state
      state.servers = servers

    } else {

      state.lastDiscoveryResult = []
      _removeServers()
    }
  }

  /**
   * @private
   * @param {?Object} server
   * @returns {?TBasDiscoveryCloudServer}
   */
  function _parseNetworkDiscoveredServer (server) {

    var result, value

    result = null

    value = server ? server[BAS_DISCOVERY_CLOUD.K_TYPE_ID] : null

    if (FILTER_CORE_IDS.indexOf(value) !== -1) {
      result = {}

      value = server[BAS_DISCOVERY_CLOUD.K_LOCAL_IP]
      if (value) result.addresses = [value]

      value = server[BAS_DISCOVERY_CLOUD.K_MAC]
      if (value) result.macN = value
    }

    return result
  }

  function _onCloudDiscoveryError () {

    state.lastDiscoveryResult = []

    _removeServers()
  }

  /**
   * @private
   * @returns {Promise<Object[]>}
   */
  function _cloudDiscovery () {

    // TODO keep track of requests and abort on service stop

    var config

    config = {}
    config.timeout = BAS_DISCOVERY_CLOUD.CLOUD_DISCOVERY_TIMEOUT_MS
    config.method = 'GET'
    config.url = BAS_DISCOVERY_CLOUD.API_PATH_DISCOVER

    return $http(config)
      .then(BasUtilities.handleHttpResponse)
      .then(_onCloudDiscoveryResponse)
  }

  function _onCloudDiscoveryResponse (result) {
    state.numberOfRequests++
    return result
  }

  /**
   * @private
   * @param {string} key
   * @param {TBasDiscoveryCloudServer} server
   * @returns {Promise}
   */
  function _fetchProjectInfo (key, server) {

    // TODO keep track of requests and abort on service stop
    // TODO Do project requests via BasServer instances adn store these

    var config

    if (
      server &&
      BasUtil.isNEArray(server.addresses)
    ) {
      config = {}
      config.timeout = BAS_DISCOVERY_CLOUD.PROJECT_INFO_TIMEOUT_MS
      config.method = 'GET'
      config.url =
        'http://' +
        server.addresses[0] +
        '/' +
        BAS_DISCOVERY_CLOUD.API_SERVER_PATH_PROJECT
      config.responseType = 'json'

      return $http(config)
        .then(BasUtilities.handleHttpResponse, _onError)
        .then(_onProject, _onError)
    }

    return Promise.resolve('no local ip')

    function _onProject (result) {

      var location

      if (result) {

        if (result[BAS_DISCOVERY_CLOUD.K_UUID]) {

          server.cid = result[BAS_DISCOVERY_CLOUD.K_UUID]
        }

        if (result[BAS_DISCOVERY_CLOUD.K_NAME]) {

          server.projectName = result[BAS_DISCOVERY_CLOUD.K_NAME]
        }

        if (result[BAS_DISCOVERY_CLOUD.K_IMAGE]) {

          server.images = result[BAS_DISCOVERY_CLOUD.K_IMAGE]
        }

        if (BasUtil.isBool(result[BAS_DISCOVERY_CLOUD.K_MASTER])) {

          server.master = result[BAS_DISCOVERY_CLOUD.K_MASTER]
        }

        if (result[BAS_DISCOVERY_CLOUD.K_LOCATION]) {

          location = result[BAS_DISCOVERY_CLOUD.K_LOCATION]

          if (location[BAS_DISCOVERY_CLOUD.K_CITY]) {

            server.city = location[BAS_DISCOVERY_CLOUD.K_CITY]
          }

          if (location[BAS_DISCOVERY_CLOUD.K_COUNTRY]) {

            server.country =
              location[BAS_DISCOVERY_CLOUD.K_COUNTRY]
          }
        }

        $rootScope.$emit(
          BAS_DISCOVERY_CLOUD.EVT_DISCOVERY_CLOUD_CORE_SERVICE_FOUND,
          server
        )

      } else {

        _onError()
      }
    }

    function _onError () {

      _removeServer(key, server)
    }
  }

  function _removeServers () {

    var length, keys, i, key

    keys = Object.keys(state.servers)
    length = keys.length
    for (i = 0; i < length; i++) {

      key = keys[i]
      _removeServer(key, state.servers[key])
    }
  }

  function _removeServer (key, server) {

    state.servers[key] = null

    $rootScope.$emit(
      BAS_DISCOVERY_CLOUD.EVT_DISCOVERY_CLOUD_CORE_SERVICE_LOST,
      server
    )
  }

  function _clearUpdateInterval () {

    clearInterval(_updateIntervalId)
    _updateIntervalId = 0
  }

  /**
   * @private
   * @returns {number}
   */
  function _getMaxNumOfRequests () {

    var raw, parsed

    raw = BasStorage.get(BAS_APP_STORAGE.K_MAX_DISCOVER_REQUESTS)
    parsed = parseInt(raw, 10)

    return BasUtil.isPNumber(parsed, true) ? parsed : MAX_NUM_REQUESTS
  }
}
