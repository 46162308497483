'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('UiHelper', [
    '$window',
    '$rootScope',
    'BAS_APP',
    'UI_HELPER',
    'BasSupports',
    'BasUtilities',
    UiHelper
  ])

/**
 * @typedef {Object} BasUi
 * @property {Object} prop
 * @property {boolean} prop.wMedium
 * @property {number} prop.minAspectRatioPhone
 * @property {number} prop.deviceType
 * @property {boolean} prop.isLongColumn
 * @property {number} prop.vw
 * @property {number} prop.vh
 * @property {number} prop.aspectRatio
 * @property {number} prop.mainSectionWidth
 * @property {number} prop.mainSectionHeight
 * @property {number} prop.nowPlayingWidth
 * @property {number} prop.nowPlayingHeight
 * @property {number} prop.coverContainerWidth
 * @property {number} prop.coverContainerHeight
 * @property {number} prop.coverSize
 * @property {Object} modalTemplate
 * @property {Object} modalTemplate.style Computed styles
 * @property {number} modalTemplate.height in px
 * @property {number} modalTemplate.width in px
 * @property {Object} domElements
 * @property {?HTMLElement} domElements.domModalTemplate
 * @property {?HTMLElement} domElements.domPanel
 * @property {?HTMLElement} domElements.domNowPlaying
 * @property {?HTMLElement} domElements.domCoverContainer
 * @property {Object} styles
 * @property {Object} styles.queue
 * @property {string} styles.queue.height
 * @property {Object} styles.coverSection
 * @property {string} styles.coverSection.height
 * @property {Object} styles.coverSideLeft
 * @property {string} styles.coverSideLeft.bottom
 * @property {Object} styles.coverSideRight
 * @property {string} styles.coverSideRight.bottom
 * @property {Object} styles.cover
 * @property {string} styles.cover.width
 * @property {string} styles.cover.height
 * @property {boolean} isResumed
 * @property {number} debounceTime in ms
 */

/**
 * @typedef {Object} BasDomProperties
 * @property {HTMLElement} domElement
 * @property {number} clientWidth
 * @property {number} clientHeight
 */

/**
 * @constructor
 * @param $window
 * @param $rootScope
 * @param {BAS_APP} BAS_APP
 * @param {UI_HELPER} UI_HELPER
 * @param {BasSupports} BasSupports
 * @param {BasUtilities} BasUtilities
 */
function UiHelper (
  $window,
  $rootScope,
  BAS_APP,
  UI_HELPER,
  BasSupports,
  BasUtilities
) {
  var SPACE_BETWEEN_QUEUE_NOW_PLAYING_PX = 5

  var WAIT_FRAMES_COVER_CONTAINER = 3

  // CSS properties
  var WIDTH = 'width'
  var HEIGHT = 'height'
  var BOTTOM = 'bottom'
  var PADDING_BOTTOM = 'padding-bottom'

  var _resizeListener = null
  var _resizeTimeoutId = 0

  /**
   * @type {TBasSupports}
   */
  var basSupports = BasSupports.get()

  /**
   * @type {BasUi}
   */
  var basUi = {}

  // Initialize properties
  basUi.prop = {}
  basUi.prop.wMedium = false
  // Iphone X ratio is 0,4618
  basUi.prop.minAspectRatioPhone = 0.45
  basUi.prop.deviceType = UI_HELPER.D_T_PHONE
  basUi.prop.isLongColumn = false
  basUi.prop.vw = 0
  basUi.prop.vh = 0
  basUi.prop.aspectRatio = basUi.prop.minAspectRatioPhone
  basUi.prop.mainSectionWidth = 0
  basUi.prop.mainSectionHeight = 0
  basUi.prop.nowPlayingWidth = 0
  basUi.prop.nowPlayingHeight = 0
  basUi.prop.coverContainerWidth = 0
  basUi.prop.coverContainerHeight = 0
  basUi.prop.coverSize = 0

  // Initialize modal template
  basUi.modalTemplate = {}
  basUi.modalTemplate.style = null
  basUi.modalTemplate.height = 44.63
  basUi.modalTemplate.width = 224.8

  // Initialize DOM elements
  basUi.domElements = {}

  // Initialize styles
  basUi.styles = {}
  basUi.styles.queue = {}
  basUi.styles.coverSection = {}
  basUi.styles.cover = {}
  basUi.styles.cover[WIDTH] = '0'
  basUi.styles.cover[HEIGHT] = '0'
  basUi.styles.cover[PADDING_BOTTOM] = '0'
  basUi.styles.coverSideLeft = {}
  basUi.styles.coverSideLeft[BOTTOM] = '20px'
  basUi.styles.coverSideRight = {}
  basUi.styles.coverSideRight[BOTTOM] = '20px'

  // Initialize service variables
  basUi.isResumed = false
  basUi.debounceTime = 200

  this.get = get
  this.resume = resume
  this.suspend = suspend
  this.setDomElement = setDomElement
  this.clearDomElement = clearDomElement
  this.determineMainSectionDimensions = determineMainSectionDimensions
  this.getTargetParentWithClassName = getTargetParentWithClassName
  this.getElementParentWithClassName = getElementParentWithClassName

  init()

  function init () {

    $rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onAppResume
    )
    $rootScope.$on(
      BAS_APP.EVT_PAUSE,
      _onAppPause
    )
  }

  function _onAppResume () {

    resume()
  }

  function _onAppPause () {

    suspend()
  }

  /**
   * Starts the resize listener
   */
  function resume () {

    // Set state
    basUi.isResumed = true

    determineViewport()

    BasUtilities.waitForFrames(5, determineViewport)
    BasUtilities.waitForFrames(10, determineViewport)
    BasUtilities.waitForFrames(20, determineViewport)

    _setResizeListener()
  }

  /**
   * Suspends the service by removing the resize listener
   */
  function suspend () {

    _clearResizeListener()
    _clearResizeTimeout()

    // Set state
    basUi.isResumed = false
  }

  /**
   * Fires on each resize event. Needs to be debounced
   */
  function onResize () {

    _clearResizeTimeout()

    _resizeTimeoutId = $window.setTimeout(
      onDebouncedResize,
      basUi.debounceTime
    )
  }

  function onDebouncedResize () {

    determineViewport()
    determineMainSectionDimensions()

    $rootScope.$emit(UI_HELPER.EVT_RESIZE)
  }

  /**
   * Add a DOM element to the domElements collection
   *
   * @param {string} key
   * @param {Object} element
   */
  function setDomElement (key, element) {

    // Check input
    if (isValidDomKey(key) &&
      BasUtil.isObject(element)) {

      // Set element
      basUi.domElements[key] = element

      // Check for actions tied to specific elements
      switch (key) {
        case UI_HELPER.K_DOM_MAIN_SECTION:

          determineMainSectionDimensions()

          break
        case UI_HELPER.K_DOM_COVER_CONTAINER:

          determineCoverContainerDimensions()

          break
      }
    }
  }

  /**
   * Clears a DOM element (making it null)
   *
   * @param {string} key
   */
  function clearDomElement (key) {

    // Check input
    if (isValidDomKey(key)) {

      // Clear element
      basUi.domElements[key] = null
    }
  }

  /**
   * Determines the viewport size and checks against phone/tablet breakpoint
   */
  function determineViewport () {

    // Get viewport width
    basUi.prop.vw = Math.max(
      $window.document.documentElement.clientWidth,
      $window.innerWidth || 0
    )

    // Get viewport height
    basUi.prop.vh = Math.max(
      $window.document.documentElement.clientHeight,
      $window.innerHeight || 0
    )

    if (BasUtil.isPNumber(basUi.prop.vh)) {

      // Calculate aspect ratio
      basUi.prop.aspectRatio = basUi.prop.vw / basUi.prop.vh

    } else {

      basUi.prop.aspectRatio = 0
    }

    basUi.prop.wMedium = basUi.prop.vw > UI_HELPER.D_W_MEDIUM
    basUi.prop.isLongColumn = false

    // Determine device type
    if (basUi.prop.vw > UI_HELPER.D_W_MEDIUM) {

      basUi.prop.deviceType = UI_HELPER.D_T_TABLET

    } else {

      if (basUi.prop.aspectRatio >= basUi.prop.minAspectRatioPhone) {

        basUi.prop.deviceType = UI_HELPER.D_T_PHONE

      } else {

        basUi.prop.deviceType = UI_HELPER.D_T_TABLET
        basUi.prop.isLongColumn = true
      }
    }
  }

  /**
   * Read the main panel dimensions
   */
  function determineMainSectionDimensions () {

    var domProperties =
      getDomElementProperties(UI_HELPER.K_DOM_MAIN_SECTION)

    if (BasUtil.isObject(domProperties)) {

      // Set properties
      basUi.prop.mainSectionWidth = domProperties.clientWidth
      basUi.prop.mainSectionHeight = domProperties.clientHeight

      determineCoverContainerDimensions()
    }
  }

  /**
   * Gets the first parent of the events target that matches given classname
   *
   * @param {Event} event
   * @param {string} className
   * @returns {?HTMLElement}
   */
  function getTargetParentWithClassName (event, className) {

    if (event) {

      // Select 'currentTarget' if available: this is the event on which the
      //  event handler was attached, while 'target' can be a descendant.

      return getElementParentWithClassName(
        event.currentTarget
          ? event.currentTarget
          : event.target,
        className
      )
    }

    return null
  }

  function getElementParentWithClassName (element, className) {

    var tempElement

    if (element) {

      tempElement = element

      while (tempElement) {

        if (tempElement.className.search(className) !== -1) {
          return tempElement
        }

        tempElement = tempElement.parentElement
      }
    }

    return null
  }

  /**
   * Read the now playing dimensions
   */
  function determineNowPlayingDimensions () {

    var domProperties =
      getDomElementProperties(UI_HELPER.K_DOM_NOW_PLAYING)

    if (BasUtil.isObject(domProperties)) {

      // Set properties
      basUi.prop.nowPlayingWidth = domProperties.clientWidth
      basUi.prop.nowPlayingHeight = domProperties.clientHeight

      if (basUi.prop.mainSectionWidth > 0 &&
        basUi.prop.mainSectionHeight > 0 &&
        basUi.prop.nowPlayingWidth > 0 &&
        basUi.prop.nowPlayingHeight > 0) {

        calculateQueueSize()

      } else {

        // Clear queue styles
        basUi.styles.queue = {}
      }
    }
  }

  /**
   * Read the cover container dimensions
   */
  function determineCoverContainerDimensions () {

    var domProperties

    if (basUi.prop.mainSectionWidth > 0 &&
      basUi.prop.mainSectionHeight > 0) {

      domProperties =
        getDomElementProperties(UI_HELPER.K_DOM_COVER_CONTAINER)

      if (BasUtil.isObject(domProperties)) {

        // Set properties
        basUi.prop.coverContainerWidth = domProperties.clientWidth
        basUi.prop.coverContainerHeight = domProperties.clientHeight

        // Calculate cover size
        calculateCoverSize()
      }
    }
  }

  /**
   * Calculates the cover size based on the dimensions of the cover container
   */
  function calculateCoverSize () {
    var bottomOffset

    if (basUi.prop.isLongColumn) {

      // Determine cover size
      basUi.prop.coverSize = basUi.prop.mainSectionWidth

    } else {

      // Determine cover size
      if (basUi.prop.coverContainerHeight >=
        basUi.prop.coverContainerWidth) {

        basUi.prop.coverSize = basUi.prop.coverContainerWidth

      } else {

        basUi.prop.coverSize = basUi.prop.coverContainerHeight
      }
    }

    // Set cover style
    basUi.styles.cover[WIDTH] = basUi.prop.coverSize + 'px'
    basUi.styles.cover[HEIGHT] = basUi.prop.coverSize + 'px'

    /**
     * Calculate bottom offset for context items inside cover sides
     *
     * @type {string}
     */
    bottomOffset = 'calc((100% - ' + basUi.prop.coverSize + 'px)/2)'

    basUi.styles.coverSideLeft[BOTTOM] = bottomOffset
    basUi.styles.coverSideRight[BOTTOM] = bottomOffset

    // Check for special long column UI
    if (basUi.prop.isLongColumn) {

      // Set height for cover section of now-playing block
      basUi.styles.coverSection.height = basUi.prop.coverSize + 'px'

      BasUtilities.waitFrames(WAIT_FRAMES_COVER_CONTAINER)
        .then(determineNowPlayingDimensions)

    } else {

      // Clear cover section styles
      basUi.styles.coverSection = {}

      // Clear Queue style
      basUi.styles.queue = {}
    }

    $rootScope.$applyAsync()
  }

  /**
   * Calculates the queue size in special case of long column view
   */
  function calculateQueueSize () {

    if (basUi.prop.isLongColumn) {

      basUi.styles.queue.top = (
        basUi.prop.nowPlayingHeight +
        SPACE_BETWEEN_QUEUE_NOW_PLAYING_PX
      ) + 'px'

    } else {

      // Clear queue styles
      basUi.styles.queue = {}
    }

    $rootScope.$applyAsync()
  }

  function _setResizeListener () {

    _clearResizeListener()

    _resizeListener = BasUtil.setDOMListener(
      $window,
      'resize',
      onResize,
      basSupports.passiveListeners
        ? {
            capture: true,
            passive: true,
            once: false
          }
        : true
    )
  }

  function _clearResizeListener () {

    BasUtil.execute(_resizeListener)
    _resizeListener = null
  }

  function _clearResizeTimeout () {

    clearTimeout(_resizeTimeoutId)
    _resizeTimeoutId = 0
  }

  /**
   * Reads several DOM element properties
   *
   * @param {string} domKey
   * @returns {BasDomProperties}
   */
  function getDomElementProperties (domKey) {

    var domElement
    var result = null

    if (BasUtil.isObject(basUi.domElements[domKey]) &&
      BasUtil.isObject(basUi.domElements[domKey][0])) {

      // Store DOM element reference
      domElement = basUi.domElements[domKey][0]

      result = {
        domElement: domElement
      }

      if (typeof domElement.clientWidth === 'number' &&
        typeof domElement.clientHeight === 'number') {

        // Set properties
        result.clientWidth = domElement.clientWidth
        result.clientHeight = domElement.clientHeight

      }
    }

    return result
  }

  /**
   * @returns {BasUi}
   */
  function get () {
    return basUi
  }

  /**
   * Checks whether a given DOM element key is known and valid
   *
   * @param {string} key
   * @returns {boolean}
   */
  function isValidDomKey (key) {
    return (
      key === UI_HELPER.K_DOM_MAIN_SECTION ||
      key === UI_HELPER.K_DOM_NOW_PLAYING ||
      key === UI_HELPER.K_DOM_COVER_CONTAINER
    )
  }
}
