'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('spotifyLibraryCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_APP_PROFILE',
    'BasLibraryHeader',
    'BasLibraryPage',
    'SpotifyManager',
    'BasCurrentAppProfile',
    'LibraryState',
    spotifyLibraryCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param BasLibraryHeader
 * @param BasLibraryPage
 * @param SpotifyManager
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param {LibraryState} LibraryState
 */
function spotifyLibraryCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_APP_PROFILE,
  BasLibraryHeader,
  BasLibraryPage,
  SpotifyManager,
  BasCurrentAppProfile,
  LibraryState
) {
  var root = this

  /**
   * @type {?PlayerLibraryState}
   */
  var libraryState = LibraryState.getCurrentState()

  root.BasLibraryPage = BasLibraryPage
  root.BasLibraryHeader = BasLibraryHeader
  root.historyType = BAS_APP_PROFILE.K_SPOTIFY

  /**
   * @type {BasCurrentAppProfileState}
   */
  root.profileSettings = BasCurrentAppProfile.get()

  /**
   * @type {SpotifyManager}
   */
  root.manager = libraryState.spotifyManager

  init()

  function init () {

    var basSource

    $scope.$on('$destroy', onDestroy)

    if (!BasUtil.isObject(root.manager)) {
      root.manager = new SpotifyManager(libraryState)
      libraryState.spotifyManager = root.manager
    }

    // Initialize service
    root.manager.resume()

    if (
      $uiRouterGlobals.params.id &&
      $uiRouterGlobals.params.type
    ) {
      basSource = LibraryState.getSpotifySource()

      if (basSource) {

        basSource.spotify.getSongInfo($uiRouterGlobals.params.id)
          .then(onSongInfo)
      }

      $uiRouterGlobals.params.id = ''
    }
  }

  function onSongInfo (result) {

    root.manager.selectElement(
      {
        spotify: result
      },
      $uiRouterGlobals.params.type
    )

    $uiRouterGlobals.params.type = ''
  }

  function onDestroy () {

    root.manager.suspend()
  }
}
