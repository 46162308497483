'use strict'

angular
  .module('basalteApp')
  .constant('BAS_MESSAGE', {
    MESSAGE_NONE: 0,
    MESSAGE_QUEUE_END: 1,
    MESSAGE_QUEUE_EMPTY: 2,
    MESSAGE_SOURCE_EMPTY: 3,
    MESSAGE_ZONE_EMPTY: 4,
    MESSAGE_SOURCE_UNKNOWN: 5,
    MESSAGE_FAVOURITES_EMPTY: 6,
    MESSAGE_SOURCE_UNAVAILABLE: 7,
    MESSAGE_UNKNOWN_AV: 8,
    MESSAGE_NO_AV: 9,
    MESSAGE_SONOS_UNREACHABLE: 10,
    MESSAGE_TURN_ON: 11,
    MESSAGE_VIDEO_IN_NON_VIDEO_CTX: 12,
    MESSAGE_AUDIO_IN_NON_AUDIO_CTX: 13,
    MESSAGE_VIDEO_IN_NON_VIDEO_CTX_TURN_ON: 14,
    MESSAGE_AUDIO_IN_NON_AUDIO_CTX_TURN_ON: 15,
    MESSAGE_AUDIO_UNAVAILABLE: 16,
    MESSAGE_VIDEO_UNAVAILABLE: 17,
    MESSAGE_NO_ROOMS_LISTENING: 18
  })
