'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var log = require('./logger')

/**
 * Equaliser info object
 *
 * @typedef {Object} EqualiserInfo
 * @property {number} id [0-14]
 * @property {boolean} [bypass] Disables the equaliser
 * @property {number} frequency Hz [20-20000]
 * @property {number} gain [-18.0-12.0]
 * @property {number} bandwidth [0.1-3.0]
 * @since 1.7.0
 */

/**
 * Shelf filter info object
 *
 * @typedef {Object} ShelfFilterInfo
 * @property {string} channel ("left"|"right")
 * @property {boolean} high High or Low
 * @property {number} frequency Hz [20-20000]
 * @property {number} gain [-18.0-12.0]
 * @since 1.7.0
 */

/**
 * Pass filter info object
 *
 * @typedef {Object} PassFilterInfo
 * @property {string} channel ("left"|"right")
 * @property {boolean} high High or Low
 * @property {boolean} bypass Disables the current pass filter
 * @property {number} type (0:VariableQ, 1:Butterworth, 2:Linkwitz-Riley,
 *     3:Bessel)
 * @property {number} besselNorm Bessel normalization
 * @property {number} q Q factor [0.1-10.0]
 * @property {number} frequency Hz [20-20000]
 * @property {number} gain [-100 0]
 * @property {number} slope [1-4]
 * @since 1.7.0
 */

/**
 * @typedef {Object} DSPInfo
 * @property {Object<string, EqualiserInfo>} equalisers
 * @property {Object<string, ShelfFilterInfo>} shelfFilters
 * @property {Object<string, PassFilterInfo>} passFilters
 * @since 1.7.0
 */

/**
 * Class to represent a configurable DSP
 *
 * @constructor
 * @extends EventEmitter
 * @param {string} type To indicate a Zone or AudioOutput
 * @param {BasCore} basCore
 * @since 1.7.0
 */
function DspModule (type, basCore) {

  EventEmitter.call(this)

  this._dspType = type
  this._basCore = basCore

  /**
   * @type {DSPInfo}
   * @private
   */
  this._dsp = {}
  this._dsp[DspModule.KEY_EQUALISERS] = {}
  this._dsp[DspModule.KEY_SHELF_FILTERS] = {}
  this._dsp[DspModule.KEY_PASS_FILTERS] = {}

  this._dirtyDsp = true

  this._handleAudioOutput = this._onAudioOutput.bind(this)
}

DspModule.prototype = Object.create(EventEmitter.prototype)
DspModule.prototype.constructor = DspModule

// region Events

/**
 * DSP configuration has been changed
 *
 * @event DspModule#EVT_DSP_CHANGED
 */

/**
 * @constant {string}
 */
DspModule.EVT_DSP_CHANGED = 'dspChanged'

// endregion

// Static constants

/**
 * @constant {string}
 */
DspModule.INSTANCE_TYPE_ZONE = 'zone'

/**
 * @constant {string}
 */
DspModule.INSTANCE_TYPE_DEVICE_OUTPUT = 'deviceOutput'

/**
 * @constant {string}
 */
DspModule.TYPE_EQUALISER = 'equaliser'

/**
 * @constant {string}
 */
DspModule.TYPE_SHELF_FILTER = 'shelfFilter'

/**
 * @constant {string}
 */
DspModule.TYPE_PASS_FILTER = 'passFilter'

/**
 * @constant {string}
 */
DspModule.KEY_EQUALISERS = P.EQUALISERS

/**
 * @constant {string}
 */
DspModule.KEY_SHELF_FILTERS = P.SHELF_FILTERS

/**
 * @constant {string}
 */
DspModule.KEY_PASS_FILTERS = P.PASS_FILTERS

/**
 * @constant {string}
 */
DspModule.ERROR_INVALID_INSTANCE_TYPE = 'Invalid instance type'

/**
 * Generates a filter id (returns -1 for invalid input)
 *
 * @param {string} channel The channel ("left"|"right")
 * @param {boolean} high High or low
 * @returns {number}
 */
DspModule.filterId = function (channel, high) {

  if (BasUtil.isNEString(channel) &&
    BasUtil.isBool(high)) {

    if (channel === 'left') {

      return high ? 1 : 0

    } else if (channel === 'right') {

      return high ? 3 : 2
    }
  }
  return -1
}

/**
 * Audio output name
 *
 * @name DspModule#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(DspModule.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * Parse DSP message object
 *
 * @param {Object} dsp
 * @param {Object} [dsp.equalisers]
 * @param {Object} [dsp.shelfFilters]
 * @param {Object} [dsp.passFilters]
 * @param {boolean} [emitEvent=true]
 */
DspModule.prototype.parseDsp = function (dsp, emitEvent) {

  var _this = this
  var changed

  // Check state is not dirty and DSP is valid object
  if (!this._dirtyDsp &&
    BasUtil.isObject(dsp)) {

    // Track changes
    changed = {}
    changed[DspModule.KEY_EQUALISERS] = false
    changed[DspModule.KEY_SHELF_FILTERS] = false
    changed[DspModule.KEY_PASS_FILTERS] = false

    // Check for changed equalisers
    if (BasUtil.isObject(dsp.equalisers)) {

      iterate(DspModule.KEY_EQUALISERS)
    }

    // Check for changed shelf filters
    if (BasUtil.isObject(dsp.shelfFilters)) {

      iterate(DspModule.KEY_SHELF_FILTERS)
    }

    // Check for changed pass filters
    if (BasUtil.isObject(dsp.passFilters)) {

      iterate(DspModule.KEY_PASS_FILTERS)
    }

    if (emitEvent !== false &&
      (
        changed[DspModule.KEY_EQUALISERS] ||
        changed[DspModule.KEY_SHELF_FILTERS] ||
        changed[DspModule.KEY_PASS_FILTERS]
      )) {

      this.emit(DspModule.EVT_DSP_CHANGED)
    }
  }

  function iterate (key) {

    var i, keys, length

    switch (key) {
      case DspModule.KEY_EQUALISERS:
      case DspModule.KEY_SHELF_FILTERS:
      case DspModule.KEY_PASS_FILTERS:

        // Iterate all new dsp properties for specific key
        keys = Object.keys(dsp[key])
        length = keys.length
        for (i = 0; i < length; i++) {

          // Store new DSP info objects
          _this._dsp[key][keys[i]] = dsp[key][keys[i]]
        }

        // Set changed state
        changed[key] = length > 0

        break
      default:
        log.warn('DspModule - Parse - Unknown key')
    }
  }
}

/**
 * Retrieves the current DSP information.
 *
 * For Zone: contains 5 equalisers and 4 shelf filters
 *
 * @returns {Promise<DSPInfo>}
 */
DspModule.prototype.getDsp = function () {

  var obj

  if (this._dirtyDsp) {

    switch (this._dspType) {
      case DspModule.INSTANCE_TYPE_DEVICE_OUTPUT:

        // Create request object
        obj = {}
        obj[P.AUDIO_OUTPUT] = {}
        obj[P.AUDIO_OUTPUT][P.UUID] = this._uuid
        obj[P.AUDIO_OUTPUT][P.DESCRIBE] = true

        break
      case DspModule.INSTANCE_TYPE_ZONE:

        // Create request object
        obj = {}
        obj[P.ZONE] = {}
        obj[P.ZONE][P.ID] = this._uuid
        obj[P.ZONE][P.DESCRIBE_DSP] = true

        break
      default:
        log.error('DspModule - getDsp - Invalid DSP type', this._dspType)
    }

    if (BasUtil.isObject(obj)) {

      // Retrieve DSP information from the basCore
      return this._basCore.requestRetry(obj)
        .then(this._handleAudioOutput)

    } else {

      // Return a rejected Promise
      return Promise.reject(DspModule.ERROR_INVALID_INSTANCE_TYPE)
    }

  } else {

    // Return a resolved Promise with the current DSP information
    return Promise.resolve(this._dsp)
  }
}

/**
 * @private
 * @param {Object} result
 * @returns {(DSPInfo|Promise)}
 */
DspModule.prototype._onAudioOutput = function (result) {

  var dspObj

  if (BasUtil.isObject(result)) {

    switch (this._dspType) {
      case DspModule.INSTANCE_TYPE_DEVICE_OUTPUT:

        // Retrieve DSP object from result
        if (BasUtil.isObject(result[P.AUDIO_OUTPUT]) &&
          BasUtil.isObject(
            result[P.AUDIO_OUTPUT][P.DSP]
          )) {

          dspObj = result[P.AUDIO_OUTPUT][P.DSP]
        }

        break
      case DspModule.INSTANCE_TYPE_ZONE:

        // Retrieve DSP object from result
        if (BasUtil.isObject(result[P.ZONE]) &&
          BasUtil.isObject(result[P.ZONE][P.DSP])) {

          dspObj = result[P.ZONE][P.DSP]
        }

        break
    }

    if (BasUtil.isObject(dspObj)) {

      this._dirtyDsp = false
      this.parseDsp(dspObj, false)

      // Return a resolved Promise with DSP info
      return this._dsp

    } else {
      log.error('DspModule - getDsp - Invalid result', result)
      return Promise.reject(CONSTANTS.ERR_RESULT)
    }

  } else {
    log.error('DspModule - getDsp - Invalid result', result)
    return Promise.reject(CONSTANTS.ERR_RESULT)
  }
}

/**
 * Set info for the specified equaliser/filter
 *
 * Multiple equalisers/filters can be set by adding them to the info array.
 * The Array has to contain items of the same type.
 *
 * @param {string} type
 * @param {Array<(EqualiserInfo|ShelfFilterInfo|PassFilterInfo)>} info
 */
DspModule.prototype.setInfo = function (type, info) {
  var obj
  var apiKey = ''
  var config

  if (this.dspSupport) {

    switch (type) {
      case DspModule.TYPE_EQUALISER:

        apiKey = DspModule.KEY_EQUALISERS

        break
      case DspModule.TYPE_SHELF_FILTER:

        apiKey = DspModule.KEY_SHELF_FILTERS

        break
      case DspModule.TYPE_PASS_FILTER:

        apiKey = DspModule.KEY_PASS_FILTERS

        break
      default:
        log.warn('DspModule - setInfo - Unknown type', type)
    }

    // Check for valid API key update
    if (BasUtil.isNEString(apiKey)) {

      switch (this._dspType) {
        case DspModule.INSTANCE_TYPE_DEVICE_OUTPUT:

          // Create message object
          obj = {}
          obj[P.AUDIO_OUTPUT] = {}
          obj[P.AUDIO_OUTPUT][P.UUID] = this._uuid
          config = obj[P.AUDIO_OUTPUT][P.CONFIG] = {}
          config[P.DSP] = {}
          config[P.DSP][apiKey] = info

          break
        case DspModule.INSTANCE_TYPE_ZONE:

          // Create request object
          obj = {}
          obj[P.ZONE] = {}
          obj[P.ZONE][P.DSP] = {}
          obj[P.ZONE][P.DSP][apiKey] = info

          break
        default:
          log.error('DspModule - setInfo - Invalid DSP type', this._dspType)
      }

      if (BasUtil.isObject(obj)) {

        // Send message
        this._basCore.send(obj)
      }
    }
  } else {
    log.warn('DspModule - setInfo - No DSP support')
  }
}

/**
 * @since 2.0.0
 */
DspModule.prototype.destroy = function () {

  this._basCore = null
  this.removeAllListeners()
}

module.exports = DspModule
