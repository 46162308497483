'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasEnergyMeter', [
    '$rootScope',
    'BAS_API',
    'BAS_ENERGY',
    'BAS_UTILITIES',
    'BasEnergyHelper',
    'BasUtilities',
    basEnergyMeterFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_ENERGY} BAS_ENERGY
 * @param {BAS_UTILITIES} BAS_UTILITIES
 * @param {BasEnergyHelper} BasEnergyHelper
 * @param {BasUtilities} BasUtilities
 * @returns {BasEnergyMeter}
 */
function basEnergyMeterFactory (
  $rootScope,
  BAS_API,
  BAS_ENERGY,
  BAS_UTILITIES,
  BasEnergyHelper,
  BasUtilities
) {
  var CSS_TYPE_ENERGY = 'bas-energy-meter--energy'
  var CSS_TYPE_GAS = 'bas-energy-meter--gas'
  var CSS_TYPE_WATER = 'bas-energy-meter--water'
  var CSS_FLOW_UP = 'bas-energy-meter--flow-up'
  var CSS_FLOW_DOWN = 'bas-energy-meter--flow-down'

  var LAST_7_DAYS_MAX_DIFF_MS = 60 * 1000

  /**
   * @constructor
   * @param {EnergyDevice} device
   */
  function BasEnergyMeter (device) {

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.type = BAS_ENERGY.T_ID_ENERGY

    /**
     * @type {boolean}
     */
    this.virtual = false

    /**
     * @type {boolean}
     */
    this.consuming = true

    /**
     * @type {string}
     */
    this.uiRealTimeUnit = ''

    /**
     * @type {string}
     */
    this.uiTotalUnit = ''

    /**
     * @type {string}
     */
    this.oldestTimestamp = ''

    /**
     * @type {string}
     */
    this.uiCurrent = ''

    /**
     * @type {string[]}
     */
    this.uiHistoryTypes = []

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}

    this.promiseLast7DaysTime = 0

    /**
     * @type {?Promise<TBasEnergyMeterResult>}
     */
    this.promiseLast7Days = null

    /**
     * @private
     * @type {EnergyDevice}
     */
    this._device = device

    this._deviceListeners = []

    this._handleState = this._onState.bind(this)

    this.parseEnergyDevice(device)
  }

  /**
   * @returns {string}
   */
  BasEnergyMeter.prototype.getOldestTimestamp = function () {

    var value

    if (this._device && this._device.getData) {

      value = this._device.stateOldestTimestamp
      if (BasUtil.isNEString(value)) return value

      value = this._device.oldestTimestamp
      if (BasUtil.isNEString(value)) return value
    }

    return ''
  }

  BasEnergyMeter.prototype.retrieveLast7Days = function () {

    var _this, dNow, d7Ago, tNow, tPreviousRequest

    _this = this

    dNow = new Date()
    tNow = dNow.getTime()
    d7Ago = new Date(tNow - BAS_UTILITIES.T_1W_MS)
    tPreviousRequest = this.promiseLast7DaysTime

    if (
      this.promiseLast7Days &&
      (dNow.getTime() - this.promiseLast7DaysTime) < LAST_7_DAYS_MAX_DIFF_MS
    ) {
      return this.promiseLast7Days
    }

    if (this._device && this._device.getData) {

      this.promiseLast7DaysTime = tNow
      this.promiseLast7Days = BasEnergyHelper.requestAll(
        this._device,
        {
          start: BasUtil.dateFormat(d7Ago, 'yyyy-mm-dd'),
          step: BAS_UTILITIES.T_1D_MS
        }
      ).catch(_onRequestError)
      return this.promiseLast7Days
    }

    return Promise.reject(new Error('getData not available'))

    function _onRequestError (error) {

      // Reset last promise time to previous time

      if (_this.promiseLast7DaysTime === tNow) {
        _this.promiseLast7DaysTime = tPreviousRequest
      }

      return Promise.reject(error)
    }
  }

  /**
   * @param {TBasEnergyMeterGetAllDataOptions} options
   * @returns {Promise<TBasEnergyMeterResultAll>}
   */
  BasEnergyMeter.prototype.retrieveData = function (
    options
  ) {
    return BasEnergyHelper.requestAll(
      this._device,
      options
    )
  }

  /**
   * @param {EnergyDevice} device
   */
  BasEnergyMeter.prototype.parseEnergyDevice = function (device) {

    this.clearDeviceListeners()
    this._resetCss()

    if (device && device.getData) {

      this._device = device

      this.uuid = device.uuid
      this.name = device.name

      this.virtual = device.virtual
      this.consuming =
        !(device.resourceType === BAS_API.EnergyDevice.RT_GENERATOR)
      this.uiRealTimeUnit = device.realTimeUnit
      this.uiTotalUnit = device.totalUnit

      switch (device.subType) {
        case BAS_API.EnergyDevice.T_GAS:
          this.type = BAS_ENERGY.T_ID_GAS
          this.css[CSS_TYPE_GAS] = true
          break
        case BAS_API.EnergyDevice.T_WATER:
          this.type = BAS_ENERGY.T_ID_WATER
          this.css[CSS_TYPE_WATER] = true
          break
        default:
          this.type = BAS_ENERGY.T_ID_ENERGY
          this.css[CSS_TYPE_ENERGY] = true
      }

      this._setDeviceListeners()
    }

    this._syncName()
    this._syncState()
    this._syncHistoryState()
  }

  BasEnergyMeter.prototype._syncName = function () {

    this.name = (this._device && BasUtil.isNEString(this._device.name))
      ? this._device.name
      : BasUtilities.basTranslate('energy_meter')
  }

  BasEnergyMeter.prototype._syncState = function () {

    var value, length, absValue

    if (this._device) {

      // Oldest timestamp
      value = this._device.stateOldestTimestamp
      if (this.oldestTimestamp !== value) {
        // TODO update range options
      }
      this.oldestTimestamp = value

      // Current value
      value = this._device.stateValue
      absValue = Math.abs(value)
      this.uiCurrent = '' + absValue

      this.css[CSS_FLOW_UP] = value < 0
      this.css[CSS_FLOW_DOWN] = value > 0

      // "Correct" JavaScript number rounding and handle very "large" numbers

      length = this.uiCurrent.length
      if (length > 5) {

        this.uiCurrent = absValue < 1
          ? '0'
          : BasUtil.numberToString(absValue, 6)
      }
    }
  }

  BasEnergyMeter.prototype._syncHistoryState = function () {

    if (this._device) {

      this.uiHistoryTypes = [
        // BAS_ENERGY.T_ID_HIST_WEEK,
        BAS_ENERGY.T_ID_HIST_MONTH,
        BAS_ENERGY.T_ID_HIST_YEAR
      ]
    }
  }

  BasEnergyMeter.prototype._onState = function () {

    this._syncState()

    $rootScope.$emit(BAS_ENERGY.EVT_STATE_UPDATED, this)
  }

  BasEnergyMeter.prototype.updateTranslation = function () {

    this._syncName()
  }

  BasEnergyMeter.prototype._setDeviceListeners = function () {

    this.clearDeviceListeners()

    if (this._device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this._device,
        BAS_API.EnergyDevice.EVT_STATE,
        this._handleState
      ))
    }
  }

  BasEnergyMeter.prototype._resetCss = function () {

    this.css[CSS_TYPE_ENERGY] = false
    this.css[CSS_TYPE_GAS] = false
    this.css[CSS_TYPE_WATER] = false
    this.css[CSS_FLOW_UP] = false
    this.css[CSS_FLOW_DOWN] = false
  }

  BasEnergyMeter.prototype.clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  BasEnergyMeter.prototype.clear = function () {

    this.clearDeviceListeners()

    this._device = null
  }

  return BasEnergyMeter
}
