'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomOpenCloseDevices', [
    'CurrentBasCore',
    'BasOpenCloseDevice',
    basRoomOpenCloseDevicesFactory
  ])

/**
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasOpenCloseDevice
 * @returns BasRoomOpenCloseDevices
 */
function basRoomOpenCloseDevicesFactory (
  CurrentBasCore,
  BasOpenCloseDevice
) {
  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomOpenCloseDevices (basRoom) {

    /**
     * @type {Object<string, BasOpenCloseDevice>}
     */
    this.openCloseDevices = {}

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomOpenCloseDevices.hasOpenCloseDevices = function (room) {
    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.openCloseDevices)
    )
  }

  /**
   * @returns {boolean}
   */
  BasRoomOpenCloseDevices.prototype.hasOpenCloseDevicesWithFeedback =
    function () {
      var keys, length, i, device

      keys = Object.keys(this.openCloseDevices)
      length = keys.length
      for (i = 0; i < length; i++) {

        device = this.openCloseDevices[keys[i]]

        if (device.hasState()) return true
      }

      return false
    }

  BasRoomOpenCloseDevices.prototype.parseRoom = function () {

    this.syncOpenCloseDevices()
  }

  BasRoomOpenCloseDevices.prototype.syncOpenCloseDevices = function () {

    var devices, length, i, uuid, device

    this.resetOpenCloseDevices()

    if (BasRoomOpenCloseDevices.hasOpenCloseDevices(this._basRoom)) {

      devices = this._basRoom.room.openCloseDevices
      length = devices.length
      for (i = 0; i < length; i++) {

        uuid = devices[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) {

          this.openCloseDevices[uuid] =
            new BasOpenCloseDevice(device, this._basRoom)
        }
      }
    }
  }

  BasRoomOpenCloseDevices.prototype.updateTranslation = function () {

    var keys, length, i, device

    keys = Object.keys(this.openCloseDevices)
    length = keys.length
    for (i = 0; i < length; i++) {

      device = this.openCloseDevices[keys[i]]

      if (BasUtil.isObject(device) &&
        BasUtil.isFunction(device.updateTranslation)) {

        device.updateTranslation()
      }
    }
  }

  /**
   * @param {string} uuid
   * @returns {?BasOpenCloseDevice}
   */
  BasRoomOpenCloseDevices.prototype.getOpenCloseDevice = function (uuid) {

    var keys, length, i, device

    keys = Object.keys(this.openCloseDevices)
    length = keys.length
    for (i = 0; i < length; i++) {

      device = this.openCloseDevices[keys[i]]

      if (device && device.uuid === uuid) return device
    }

    return null
  }

  BasRoomOpenCloseDevices.prototype.clearOpenCloseDevices = function () {

    var keys, length, i, device

    keys = Object.keys(this.openCloseDevices)
    length = keys.length
    for (i = 0; i < length; i++) {

      device = this.openCloseDevices[keys[i]]

      if (BasUtil.isObject(device) &&
        BasUtil.isFunction(device.clear)) {

        device.clear()
      }
    }
  }

  BasRoomOpenCloseDevices.prototype.resetOpenCloseDevices = function () {

    this.clearOpenCloseDevices()

    this.openCloseDevices = {}
  }

  BasRoomOpenCloseDevices.prototype.suspend = function () {

    // Empty
  }

  BasRoomOpenCloseDevices.prototype.destroy = function () {

    this._basRoom = null
  }

  return BasRoomOpenCloseDevices
}
