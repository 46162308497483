'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('infiniteScroll', infiniteScroll)

function infiniteScroll () {

  return {
    restrict: 'AE',
    bindToController: {
      bottomOffset: '<',
      scrollDebounce: '<',
      triggerDebounce: '<',
      disabled: '<',
      bottomTriggered: '&',
      watchParameter: '<',
      watchDelay: '<'
    },
    controller: [
      '$rootScope',
      '$element',
      'BasUtilities',
      'UI_HELPER',
      controller
    ],
    controllerAs: 'scroll'
  }

  /**
   * @constructor
   * @param $rootScope
   * @param $element
   * @param {BasUtilities} BasUtilities
   * @param {UI_HELPER} UI_HELPER
   */
  function controller (
    $rootScope,
    $element,
    BasUtilities,
    UI_HELPER
  ) {
    var scroll = this

    /**
     * Time in ms at which rate the scroll position is evaluated on 'scroll'
     * or 'resize' events. During scrolling or resizing, the scroll position
     * will be handled at this interval.
     *
     * Default is 50 ms.
     *
     * @type {number}
     */
    var scrollDebounceMs = 50

    /**
     * Debounce time in ms for executing the 'bottom reached' trigger.
     * This only affects reaching the same bottom multiple times. If the
     * element has changed size this is seen as a different bottom.
     * Scrolling to the bottom (defined by 'bottomOffset') multiple times
     * within this timeframe will only call the 'bottomTrigger' once. This
     * debounce does not apply if the scrollHeight of the element has
     * changed in between bottoming out.
     *
     * Default is 1000 ms.
     *
     * @type {number}
     */
    var triggerDebounceMs = 1000

    /**
     * Delay time in ms for handling a watch parameter change.
     *
     * Default is 5 ms.
     *
     * @type {number}
     */
    var watchDelayMs = 5

    /**
     * Offset from the bottom to use as limit value for triggering
     * 'bottom reached'.
     *
     * Default will be clientHeight of scrollable element.
     *
     * @type {number}
     */
    var bottomOffset = 0

    var debounceTimeoutId
    var watchTimeoutId

    var listeners = []
    var _passiveListenerOptions = {
      capture: false,
      passive: true
    }

    var lastTrigger = 0
    var lastTriggerHeight = 0
    var doAgain = false
    var lastDisabledValue = false
    var lastWatchValue

    scroll.$postLink = _onPostLink
    scroll.$onDestroy = _onDestroy
    scroll.$onChanges = _onChanges

    function _onPostLink () {

      init()
    }

    function init () {

      syncConfigParameters()

      // Set default bottomOffset in waitForFrames call to make sure css height
      //  is loaded.
      BasUtilities.waitForFrames(2, onWaitForFrames)

      if (BasUtil.isBool(scroll.disabled)) {

        lastDisabledValue = scroll.disabled
      }

      if (lastDisabledValue !== true) {

        setListeners()
      }

      function onWaitForFrames () {

        // Only set if it is still the initial value
        if ($element && $element[0] && bottomOffset === 0) {

          bottomOffset = $element[0].clientHeight
        }
      }
    }

    function syncConfigParameters () {

      if (BasUtil.isPNumber(scroll.scrollDebounce, true)) {

        scrollDebounceMs = scroll.scrollDebounce
      }

      if (BasUtil.isPNumber(scroll.triggerDebounce, true)) {

        triggerDebounceMs = scroll.triggerDebounce
      }

      if (BasUtil.isPNumber(scroll.bottomOffset, true)) {

        bottomOffset = scroll.bottomOffset
      }

      if (BasUtil.isPNumber(scroll.watchDelay, true)) {

        watchDelayMs = scroll.watchDelay
      }
    }

    function _onScroll () {

      // We want to run our scroll handler on a specific interval while
      //  the user is scrolling. If a scroll happens while the handling
      //  of a previous scroll is already scheduled, we set 'doAgain' to
      //  true: after our current scheduled handling is done it will
      //  reschedule another handle. This makes sure that the last scroll
      //  position is always handled too.

      if (debounceTimeoutId) {

        doAgain = true

      } else {

        scheduleHandleScroll()
      }
    }

    function _onResize () {

      if (BasUtil.isPNumber(scroll.bottomOffset, true)) {

        bottomOffset = scroll.bottomOffset

      } else {

        bottomOffset = $element[0].clientHeight
      }

      _onScroll()
    }

    function setListeners () {

      listeners.push(BasUtil.setDOMListener(
        $element[0],
        'scroll',
        _onScroll,
        _passiveListenerOptions
      ))
      listeners.push($rootScope.$on(
        UI_HELPER.EVT_RESIZE,
        _onResize
      ))

      BasUtilities.waitForFrames(2, handleScrollPosition)
    }

    function removeListeners () {

      BasUtil.executeArray(listeners)
      listeners = []
    }

    function handleScrollPosition (isWatchParameterChange) {

      var el, minScrollHeight

      clearTimeout(debounceTimeoutId)
      debounceTimeoutId = undefined

      if (scroll.disabled === true) return

      el = $element[0]
      minScrollHeight = el.scrollHeight - bottomOffset

      if (el.scrollTop + el.clientHeight >= minScrollHeight) {

        handleBottomTrigger(isWatchParameterChange)
      }

      if (doAgain) {

        scheduleHandleScroll(isWatchParameterChange)
      }
    }

    function scheduleHandleScroll (isWatchParameterChange) {

      doAgain = false
      debounceTimeoutId = setTimeout(
        handleScrollPosition,
        scrollDebounceMs,
        isWatchParameterChange
      )
    }

    function handleBottomTrigger (isWatchParameterChange) {

      var timestamp, timeDiff, triggerHeight

      timestamp = Date.now()
      timeDiff = timestamp - lastTrigger
      triggerHeight = $element[0].scrollHeight

      // Debounce based on timestamp and 'triggerDebounceMs' variable, so
      //  the client will not be spammed with 'bottomTriggered' calls.
      //  If the scrollHeight has changed since last trigger, a different
      //  bottom has been reached (content in scrollable element has
      //  changed, so the previous bottom trigger has already been handled
      //  successfully. In this case we ignore the debounce and call the
      // 'bottomTriggered' function too.
      if (
        timeDiff > triggerDebounceMs ||
        triggerHeight !== lastTriggerHeight ||
        isWatchParameterChange === true
      ) {

        if (BasUtil.isFunction(scroll.bottomTriggered)) {

          scroll.bottomTriggered()
        }

        lastTrigger = timestamp
        lastTriggerHeight = triggerHeight
      }
    }

    function _onDestroy () {

      removeListeners()
    }

    function _onChanges () {

      if (BasUtil.isBool(scroll.disabled) &&
        scroll.disabled !== lastDisabledValue) {

        lastDisabledValue = scroll.disabled
        _onDisabledChanged()
      }

      if (scroll.watchParameter !== lastWatchValue) {

        _onWatchParameterChangeDelayed()
      }

      syncConfigParameters()
    }

    function _onDisabledChanged () {

      if (lastDisabledValue) {

        removeListeners()

      } else {

        setListeners()
      }
    }

    function _onWatchParameterChangeDelayed () {

      clearTimeout(watchTimeoutId)
      watchTimeoutId = setTimeout(_onWatchParameterChange, watchDelayMs)
    }

    function _onWatchParameterChange () {

      if (
        !BasUtil.isUndefined(scroll.watchParameter) &&
        scroll.watchParameter !== lastWatchValue
      ) {

        lastWatchValue = scroll.watchParameter
        handleScrollPosition(true)
      }
    }
  }
}
