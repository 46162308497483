'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var Device = require('./device')
var AVAudio = require('./av_audio')
var AVVideo = require('./av_video')

var CONSTANTS = require('./constants')

/**
 * @typedef {Object} TRoom
 * @property {string} uuid
 * @property {number} level
 * @property {string} parent
 * @property {string} name
 * @property {string[]} tags
 * @property {number} order
 * @property {number} type
 * @property {string[]} [functions]
 * @property {TFunctionData} [functionData]
 * @property {string[]} devices
 * @property {TRoomAV} av
 */

/**
 * @typedef {Object} TRoomFunctions
 * @property {boolean} audio
 * @property {boolean} scenes
 * @property {boolean} lights
 * @property {boolean} windowTreatments
 * @property {boolean} thermostats
 * @property {boolean} generic
 * @property {boolean} energy
 * @property {boolean} security
 * @property {boolean} timers
 * @property {boolean} openclose
 * @property {boolean} intercom
 * @property {boolean} av
 */

/**
 * @typedef {Object} TFunctionData
 * @property {?TDevices} [generic]
 * @property {?TDevices} [thermostat]
 */

/**
 * @typedef {Object} TDevices
 * @property {string[]} devices
 */

/**
 * @typedef {Object} TRoomMessage
 * @property {Object} room
 * @property {string} room.uuid
 */

/**
 * @typedef {Object} TRoomAV
 * @property {AVAudio} audio
 * @property {AVVideo} video
 */

/**
 * Room with devices
 *
 * @constructor
 * @extends EventEmitter
 * @param {BasCore} basCore
 * @param {TRoom} room
 */
function Room (basCore, room) {

  EventEmitter.call(this)

  this._basCore = basCore

  this._uuid = room[P.UUID]

  this._type = BasUtil.isVNumber(room[P.TYPE])
    ? room[P.TYPE]
    : Room.TYPES.CUSTOM
  this._name = BasUtil.isNEString(room[P.NAME])
    ? room[P.NAME]
    : ''
  this._order = BasUtil.isPNumber(room[P.ORDER], true)
    ? room[P.ORDER]
    : Room.DEFAULT_ORDER

  this._level = BasUtil.isVNumber(room[P.LEVEL])
    ? room[P.LEVEL]
    : Room.LEVELS.LVL_ROOM
  this._parent = BasUtil.isNEString(room[P.PARENT])
    ? room[P.PARENT]
    : ''

  this._tags = Array.isArray(room[P.TAGS])
    ? room[P.TAGS]
    : []

  this._images = BasUtil.isNEObject(room[P.IMAGES])
    ? room[P.IMAGES]
    : null

  this._devices = Array.isArray(room[P.DEVICES])
    ? room[P.DEVICES]
    : []

  this._av = BasUtil.isObject(room[P.AV])
    ? {
        audio: BasUtil.isObject(room[P.AV][P.AUDIO])
          ? new AVAudio(
            this._basCore,
            this._uuid,
            room[P.AV][P.AUDIO]
          )
          : null,
        video: BasUtil.isObject(room[P.AV][P.VIDEO])
          ? new AVVideo(
            this._basCore,
            this._uuid,
            room[P.AV][P.VIDEO]
          )
          : null
      }
    : null

  /**
   * UUID for building area
   *
   * @type {string}
   */
  this._buildingId = ''

  /**
   * UUID for floor area
   *
   * @type {string}
   */
  this._floorId = ''

  /**
   * @type {TRoomFunctions}
   */
  this._functions = {}
  BasUtil.setProperties(
    this._functions,
    BasUtil.objectToArray(Room.FUNCTIONS),
    false
  )

  /**
   * @type {TFunctionData}
   */
  this._functionData = {}

  this._sceneCtrl = ''
  this._timerCtrl = ''

  this._ellies = []
  this._lisas = []
  this._lenas = []
  this._lights = []
  this._shades = []
  this._genericDevices = []
  this._cameras = []
  this._doorPhoneGateways = []
  this._doorPhones = []
  this._openCloseDevices = []
  this._weatherStations = []
  this._energy = []
  this._thermostats = []
  this._pools = []

  /**
   * @type {boolean}
   */
  this._isDestroyed = false

  if (Array.isArray(room[P.FUNCTIONS])) {

    this._parseFunctions(room[P.FUNCTIONS])
  }

  this._parseFunctionData(room[P.FUNCTION_DATA])
}

Room.prototype = Object.create(EventEmitter.prototype)
Room.prototype.constructor = Room

// region Events

/**
 * Images have been updated
 *
 * @event Room#EVT_IMAGES
 * @param {string[]} images
 */

/**
 * Ellie devices have been updated
 *
 * @event Room#EVT_ELLIES_UPDATED
 * @param {string[]} ellies
 */

/**
 * Scene Controller device has been updated
 *
 * @event Room#EVT_SCENE_CTRL_UPDATED
 * @param {string} sceneCtrl
 */

/**
 * Thermostat has been updated
 *
 * @event Room#EVT_THERMOSTATS_UPDATED
 * @param {string[]} thermostats
 */

/**
 * Pool has been updated
 *
 * @event Room#EVT_POOLS_UPDATED
 * @param {string[]} pools
 */

/**
 * Energy device has been updated
 *
 * @event Room#EVT_ENERGY_UPDATED
 * @param {string} energy
 */

/**
 * Timer Controller device has been updated
 *
 * @event Room#EVT_TIMER_CTRL_UPDATED
 * @param {string} timerCtrl
 */

/**
 * Lights have been updated
 *
 * @event Room#EVT_LIGHTS_UPDATED
 * @param {string[]} lights
 */

/**
 * Shades have been updated
 *
 * @event Room#EVT_SHADES_UPDATED
 * @param {string[]} shades
 */

/**
 * Generic devices have been updated
 *
 * @event Room#EVT_GENERIC_DEVICES_UPDATED
 * @param {string[]} genericDevices
 */

/**
 * Open/Close devices have been updated
 *
 * @event Room#EVT_OPEN_CLOSE_DEVICES_UPDATED
 * @param {string[]} openCloseDevices
 */

/**
 * Cameras have been updated
 *
 * @event Room#EVT_SECURITY_UPDATED
 * @param {string[]} cameras
 */

/**
 * Door phone gateways have been updated
 *
 * @event Room#EVT_DOOR_PHONE_GATEWAYS_UPDATED
 * @param {string[]} doorPhoneGateways
 */

/**
 * Door phones have been updated
 *
 * @event Room#EVT_DOOR_PHONES_UPDATED
 * @param {string[]} doorPhones
 */

/**
 * Weather Stations have been updated
 *
 * @event Room#EVT_WEATHER_STATIONS_UPDATED
 * @param {string[]} weatherStations
 */

// endregion

/**
 * @constant {string}
 */
Room.EVT_IMAGES_UPDATED = 'evtRoomImagesUpdated'

/**
 * @constant {string}
 */
Room.EVT_ELLIES_UPDATED = 'evtElliesUpdated'

/**
 * @constant {string}
 */
Room.EVT_LISAS_UPDATED = 'evtLisasUpdated'

/**
 * @constant {string}
 */
Room.EVT_LENAS_UPDATED = 'evtLenasUpdated'

/**
 * @constant {string}
 */
Room.EVT_SCENE_CTRL_UPDATED = 'evtSceneCtrlUpdated'

/**
 * @constant {string}
 */
Room.EVT_THERMOSTATS_UPDATED = 'evtThermostatsUpdated'

/**
 * @constant {string}
 */
Room.EVT_POOLS_UPDATED = 'evtPoolsUpdated'

/**
 * @constant {string}
 */
Room.EVT_ENERGY_UPDATED = 'evtEnergyUpdated'

/**
 * @constant {string}
 */
Room.EVT_TIMER_CTRL_UPDATED = 'evtTimerCtrlUpdated'

/**
 * @constant {string}
 */
Room.EVT_LIGHTS_UPDATED = 'evtLightsUpdated'

/**
 * @constant {string}
 */
Room.EVT_SHADES_UPDATED = 'evtShadesUpdated'

/**
 * @constant {string}
 */
Room.EVT_GENERIC_DEVICES_UPDATED = 'evtGenericDevicesUpdated'

/**
 * @constant {string}
 */
Room.EVT_OPEN_CLOSE_DEVICES_UPDATED = 'evtOpenCloseDevicesUpdated'

/**
 * @constant {string}
 */
Room.EVT_SECURITY_UPDATED = 'evtSecurityUpdated'

/**
 * @constant {string}
 */
Room.EVT_DOOR_PHONE_GATEWAYS_UPDATED = 'evtDoorPhoneGatewaysUpdated'

/**
 * @constant {string}
 */
Room.EVT_DOOR_PHONES_UPDATED = 'evtDoorPhonesUpdated'

/**
 * @constant {string}
 */
Room.EVT_WEATHER_STATIONS_UPDATED = 'evtWeatherStationsUpdated'

/**
 * From proto.be.basalte.config.Area.AreaLevel
 *
 * @readonly
 * @enum {number}
 */
Room.LEVELS = {
  LVL_ROOM: 0,
  LVL_FLOOR: 1,
  LVL_BUILDING: 2,
  LVL_HOME: 3
}

/**
 * @readonly
 * @enum {string}
 */
Room.FUNCTIONS = {
  AUDIO: P.AUDIO,
  SCENES: P.SCENES,
  LIGHTS: P.LIGHTS,
  WINDOW_TREATMENTS: P.WINDOW_TREATMENTS,
  THERMOSTAT: P.THERMOSTAT,
  POOL: P.POOL,
  GENERIC: P.GENERIC,
  ENERGY_METER: P.ENERGY_METER,
  SECURITY: P.SECURITY,
  TIMERS: P.TIMERS,
  OPEN_CLOSE: P.OPEN_CLOSE,
  INTERCOM: P.INTERCOM,
  WEATHER_STATION: P.WEATHER_STATION,
  AV: P.AV
}

/**
 * From proto.be.basalte.config.Area.AreaType
 *
 * @readonly
 * @enum {(number|string)}
 */
Room.TYPES = {
  CUSTOM: 0,
  B_MAIN_HOUSE: 1,
  B_POOL_HOUSE: 2,
  B_STABLES: 3,
  B_GARAGE: 4,
  B_BUILDING: 5,
  F_GROUND_FLOOR: 51,
  F_BASEMENT: 52,
  F_FIRST_FLOOR: 53,
  F_SECOND_FLOOR: 54,
  F_GARDEN: 55,
  F_THIRD_FLOOR: 56,
  F_FOURTH_FLOOR: 57,
  F_FIFTH_FLOOR: 58,
  F_FLOOR: 59,
  F_ATTIC: 60,
  F_LOFT: 61,
  F_UPPER: 62,
  F_LOWER: 63,
  R_RACK: 101,
  R_LIVING_ROOM: 102,
  R_KITCHEN: 103,
  R_BEDROOM: 104,
  R_BATHROOM: 105,
  R_GARAGE: 106,
  R_GARDEN: 107,
  R_PATIO: 108,
  R_POOL: 109,
  R_DRESSING_ROOM: 110,
  R_HOME_OFFICE: 111,
  R_SPA: 112,
  R_TERRACE: 113,
  R_BASEMENT: 114,
  R_GYM: 115,
  R_HOME_CINEMA: 116,
  R_ATTIC: 117,
  R_HOBBY_ROOM: 118,
  R_WINE_CELLAR: 119,
  R_BAR: 120,
  R_DINING_ROOM: 121,
  R_HALLWAY: 122,
  R_TOILET: 123,
  R_LAUNDRY_ROOM: 124,
  R_PANTRY: 125,
  R_FAMILY_ROOM: 126,
  R_LIBRARY: 127,
  R_BALL_ROOM: 128,
  R_DRIVEWAY: 129,
  R_WORKSHOP: 130,
  R_GUEST_ROOM: 131,
  R_MASTER_BEDROOM: 132,
  R_CORRIDOR: 133,
  R_MEETING_ROOM: 134,
  R_NURSERY: 135,
  R_PARKING_PLACE: 136,
  R_STAIRCASE: 137,
  R_UTILITY_ROOM: 138,
  R_POOL_HOUSE: 139,
  R_ENTRY: 140,
  R_ROOM: 141,
  R_FOYER: 142,
  R_NOOK: 143,
  R_MEDIA_ROOM: 144,
  R_STABLE: 145
}

/**
 * String version of types
 *
 * @readonly
 * @enum {(string|string)}
 */
Room.TYPES_S = {
  CUSTOM: 'custom',
  B_MAIN_HOUSE: 'building_main_house',
  B_POOL_HOUSE: 'building_pool_house',
  B_STABLES: 'building_stables',
  B_GARAGE: 'building_garage',
  B_BUILDING: 'building_building',
  F_GROUND_FLOOR: 'floor_ground_floor',
  F_BASEMENT: 'floor_basement',
  F_FIRST_FLOOR: 'floor_first_floor',
  F_SECOND_FLOOR: 'floor_second_floor',
  F_GARDEN: 'floor_garden',
  F_THIRD_FLOOR: 'floor_third_floor',
  F_FOURTH_FLOOR: 'floor_fourth_floor',
  F_FIFTH_FLOOR: 'floor_fifth_floor',
  F_FLOOR: 'floor_floor',
  F_ATTIC: 'floor_attic',
  F_LOFT: 'floor_loft',
  F_UPPER: 'floor_lower',
  F_LOWER: 'floor_lower',
  R_ATTIC: 'room_attic',
  R_BALL_ROOM: 'room_ball_room',
  R_BAR: 'room_bar',
  R_BASEMENT: 'room_basement',
  R_BATHROOM: 'room_bathroom',
  R_BEDROOM: 'room_bedroom',
  R_CORRIDOR: 'room_corridor',
  R_DINING_ROOM: 'room_dining_room',
  R_DRESSING_ROOM: 'room_dressing_room',
  R_DRIVEWAY: 'room_driveway',
  R_ENTRY: 'room_entry',
  R_FAMILY_ROOM: 'room_family_room',
  R_FOYER: 'room_foyer',
  R_GARAGE: 'room_garage',
  R_GARDEN: 'room_garden',
  R_GUEST_ROOM: 'room_guest_room',
  R_GYM: 'room_gym',
  R_HALLWAY: 'room_hallway',
  R_HOBBY_ROOM: 'room_hobby_room',
  R_HOME_CINEMA: 'room_cinema',
  R_HOME_OFFICE: 'room_home_office',
  R_KITCHEN: 'room_kitchen',
  R_LAUNDRY_ROOM: 'room_laundry_room',
  R_LIBRARY: 'room_library',
  R_LIVING_ROOM: 'room_living_room',
  R_MASTER_BEDROOM: 'room_master_bedroom',
  R_MEDIA_ROOM: 'room_media_room',
  R_MEETING_ROOM: 'room_meeting_room',
  R_NOOK: 'room_nook',
  R_NURSERY: 'room_nursery',
  R_PANTRY: 'room_pantry',
  R_PARKING_PLACE: 'room_parking_place',
  R_PATIO: 'room_patio',
  R_POOL_HOUSE: 'room_pool_house',
  R_POOL: 'room_pool',
  R_RACK: 'room_rack',
  R_ROOM: 'room_room',
  R_SPA: 'room_spa',
  R_STABLE: 'room_stable',
  R_STAIRCASE: 'room_staircase',
  R_TERRACE: 'room_terrace',
  R_TOILET: 'room_toilet',
  R_UTILITY_ROOM: 'room_utility_rom',
  R_WINE_CELLAR: 'room_wine_cellar',
  R_WORKSHOP: 'room_workshop'
}

/**
 * Reverse enum
 *
 * @readonly
 * @enum {string}
 */
Room.TYPES_R = BasUtil.switchObjectKeyValue(Room.TYPES)

/**
 * @constant {number}
 */
Room.DEFAULT_ORDER = 4294967290

/**
 * Checks if an instance of "Room" is an actual room (LEVEL 0)
 *
 * @param {Room} room
 * @returns {boolean}
 * @since 3.0.0
 */
Room.isLvlRoom = function (room) {

  return room instanceof Room && room.level === Room.LEVELS.LVL_ROOM
}

/**
 * Checks if an instance of "Room" is an actual room (LEVEL 0)
 * and has at least 1 function
 *
 * @param {Room} room
 * @returns {boolean}
 * @since 3.0.0
 */
Room.isLvlRoomWithFunctions = function (room) {

  var keys, length, i

  if (Room.isLvlRoom(room)) {

    keys = Object.keys(room.functions)
    length = keys.length
    for (i = 0; i < length; i++) {

      if (room.functions[keys[i]] === true) return true
    }
  }

  return false
}

/**
 * @name Room#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name Room#type
 * @type {number}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'type', {
  get: function () {
    return this._type
  }
})

/**
 * @name Room#typeName
 * @type {string}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'typeName', {
  get: function () {
    return Room.TYPES_R[this._type]
  }
})

/**
 * @name Room#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * @name Room#level
 * @type {number}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'level', {
  get: function () {
    return this._level
  }
})

/**
 * @name Room#parent
 * @type {string}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'parent', {
  get: function () {
    return this._parent
  }
})

/**
 * @name Room#buildingId
 * @type {string}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'buildingId', {
  get: function () {
    return this._buildingId
  }
})

/**
 * @name Room#floorId
 * @type {string}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'floorId', {
  get: function () {
    return this._floorId
  }
})

/**
 * @name Room#tags
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'tags', {
  get: function () {
    return this._tags
  }
})

/**
 * @name Room#order
 * @type {number}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'order', {
  get: function () {
    return this._order
  }
})

/**
 * @name Room#functions
 * @type {TRoomFunctions}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'functions', {
  get: function () {
    return this._functions
  }
})

/**
 * @name Room#functionData
 * @type {TFunctionData}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'functionData', {
  get: function () {
    return this._functionData
  }
})

/**
 * @name Room#images
 * @type {?Object<string, string>}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'images', {
  get: function () {
    return this._images
  }
})

/**
 * @name Room#hasDevices
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'hasDevices', {
  get: function () {
    return this._devices.length > 0
  }
})

/**
 * @name Room#ellies
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'ellies', {
  get: function () {
    return this._ellies
  }
})

/**
 * @name Room#lisas
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'lisas', {
  get: function () {
    return this._lisas
  }
})

/**
 * @name Room#lenas
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'lenas', {
  get: function () {
    return this._lenas
  }
})

/**
 * @name Room#sceneCtrl
 * @type {string}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'sceneCtrl', {
  get: function () {
    return this._sceneCtrl
  }
})

/**
 * @name Room#thermostats
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'thermostats', {
  get: function () {
    return this._thermostats
  }
})

/**
 * @name Room#pools
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'pools', {
  get: function () {
    return this._pools
  }
})

/**
 * @name Room#timerCtrl
 * @type {string}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'timerCtrl', {
  get: function () {
    return this._timerCtrl
  }
})

/**
 * @name Room#lights
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'lights', {
  get: function () {
    return this._lights
  }
})

/**
 * @name Room#shades
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'shades', {
  get: function () {
    return this._shades
  }
})

/**
 * @name Room#genericDevices
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'genericDevices', {
  get: function () {
    return this._genericDevices
  }
})

/**
 * @name Room#openCloseDevices
 * @type {string[]}
 * @readonly
 * @since 2.7.0
 */
Object.defineProperty(Room.prototype, 'openCloseDevices', {
  get: function () {
    return this._openCloseDevices
  }
})

/**
 * @name Room#cameras
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'cameras', {
  get: function () {
    return this._cameras
  }
})

/**
 * @name Room#doorPhoneGateways
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'doorPhoneGateways', {
  get: function () {
    return this._doorPhoneGateways
  }
})

/**
 * @name Room#doorPhones
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'doorPhones', {
  get: function () {
    return this._doorPhones
  }
})

/**
 * @name Room#weatherStations
 * @type {string[]}
 * @readonly
 * @since 2.10.0
 */
Object.defineProperty(Room.prototype, 'weatherStations', {
  get: function () {
    return this._weatherStations
  }
})

/**
 * @name Room#energy
 * @type {string[]}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'energy', {
  get: function () {
    return this._energy
  }
})

/**
 * @name Room#av
 * @type {TRoomAV}
 * @readonly
 * @since 3.4.0
 */
Object.defineProperty(Room.prototype, 'av', {
  get: function () {
    return this._av
  }
})

/**
 * @name Room#isDestroyed
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Room.prototype, 'isDestroyed', {
  get: function () {
    return this._isDestroyed
  }
})

/**
 * @param {Object} room
 */
Room.prototype.parse = function (room) {

  if (BasUtil.isObject(room[P.IMAGES])) {

    this._images = room[P.IMAGES]

    this.emit(Room.EVT_IMAGES_UPDATED, this.images)
  }

  if (BasUtil.isObject(room[P.AV])) {

    this._parseAV(room[P.AV])
  }
}

/**
 * @returns {?Room}
 */
Room.prototype.getBuilding = function () {

  var building

  if (this._basCore) {

    building = this._basCore.areas[this._buildingId]

    return building || null
  }

  return null
}

/**
 * @returns {?Room}
 */
Room.prototype.getFloor = function () {

  var floor

  if (this._basCore) {

    floor = this._basCore.areas[this._floorId]

    return floor || null
  }

  return null
}

/**
 * Parse the Room functions
 *
 * @private
 * @param {string[]} functions
 */
Room.prototype._parseFunctions = function (functions) {

  var i, length

  length = functions.length
  for (i = 0; i < length; i++) {

    switch (functions[i]) {
      case P.AUDIO:
        this._functions[Room.FUNCTIONS.AUDIO] = true
        break
      case P.SCENES:
        this._functions[Room.FUNCTIONS.SCENES] = true
        break
      case P.LIGHTS:
        this._functions[Room.FUNCTIONS.LIGHTS] = true
        break
      case P.WINDOW_TREATMENTS:
        this._functions[Room.FUNCTIONS.WINDOW_TREATMENTS] = true
        break
      case P.THERMOSTAT:
        this._functions[Room.FUNCTIONS.THERMOSTAT] = true
        break
      case P.POOL:
        this._functions[Room.FUNCTIONS.POOL] = true
        break
      case P.GENERIC:
        this._functions[Room.FUNCTIONS.GENERIC] = true
        break
      case P.SECURITY:
        this._functions[Room.FUNCTIONS.SECURITY] = true
        break
      case P.TIMERS:
        this._functions[Room.FUNCTIONS.TIMERS] = true
        break
      case P.OPEN_CLOSE:
        this._functions[Room.FUNCTIONS.OPEN_CLOSE] = true
        break
      case P.INTERCOM:
        this._functions[Room.FUNCTIONS.INTERCOM] = true
        break
      case P.WEATHER_STATION:
        this._functions[Room.FUNCTIONS.WEATHER_STATION] = true
        break
      case P.AV:
        this._functions[Room.FUNCTIONS.AV] = true
        break
      case P.ENERGY_METER:
        this._functions[Room.FUNCTIONS.ENERGY_METER] = true
        break
    }
  }
}

/**
 * Parse the Room functionData
 *
 * @private
 * @param {?TFunctionData} functionData
 */
Room.prototype._parseFunctionData = function (functionData) {

  if (BasUtil.isObject(functionData)) {

    if (BasUtil.isObject(functionData.thermostat)) {

      this._functionData.thermostat = functionData.thermostat
    }

    if (BasUtil.isObject(functionData.generic)) {

      this._functionData.generic = functionData.generic
    }
  }
}

/**
 * Process devices for this room
 *
 * @param {Object} [options]
 * @param {boolean} [options.emit = true]
 */
Room.prototype.syncDevices = function (options) {

  var emit, devices, device, i, length, uuid
  var newSceneCtrl, newThermostats, newPools, newLights, newShades, newCameras
  var newEnergy, newTimerCtrl, newDoorPhoneGateways, newDoorPhones, newLisas
  var newEllies, newGenericDevices, newOpenCloseDevices, newWeatherStations
  var newLenas

  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  newSceneCtrl = ''
  newTimerCtrl = ''

  newEllies = []
  newLisas = []
  newLenas = []
  newLights = []
  newShades = []
  newGenericDevices = []
  newOpenCloseDevices = []
  newCameras = []
  newDoorPhoneGateways = []
  newDoorPhones = []
  newWeatherStations = []
  newEnergy = []
  newThermostats = []
  newPools = []

  devices = this._basCore.devices

  length = this._devices.length
  for (i = 0; i < length; i++) {

    uuid = this._devices[i]
    device = devices[uuid]

    if (device) {

      if (device.type === Device.T_ELLIE) {

        newEllies.push(uuid)

      } else if (device.type === Device.T_LISA) {

        newLisas.push(uuid)

      } else if (device.type === Device.T_LENA) {

        newLenas.push(uuid)

      } else if (device.type === Device.T_SCENE_CONTROLLER) {

        newSceneCtrl = uuid

      } else if (device.type === Device.T_THERMOSTAT) {

        newThermostats.push(uuid)

      } else if (device.type === Device.T_POOL) {
        newPools.push(uuid)

      } else if (device.type === Device.T_LIGHT) {

        newLights.push(uuid)

      } else if (device.type === Device.T_SHADE) {

        newShades.push(uuid)

      } else if (
        device.type === Device.T_GENERIC ||
        device.type === Device.T_GENERIC_V2
      ) {

        newGenericDevices.push(uuid)

      } else if (device.type === Device.T_OPEN_CLOSE) {

        newOpenCloseDevices.push(uuid)

      } else if (device.type === Device.T_CAMERA) {

        newCameras.push(uuid)

      } else if (device.type === Device.T_ENERGY) {

        newEnergy = uuid

      } else if (device.type === Device.T_TIMER_CONTROLLER) {

        newTimerCtrl = uuid

      } else if (device.type === Device.T_DOOR_PHONE_GATEWAY) {

        newDoorPhoneGateways.push(uuid)

      } else if (device.type === Device.T_DOOR_PHONE) {

        newDoorPhones.push(uuid)

      } else if (device.type === Device.T_WEATHER_STATION) {

        newWeatherStations.push(uuid)

      } else if (device.type === Device.T_ENERGY_METER) {

        newEnergy.push(uuid)
      }
    }
  }

  if (!BasUtil.isEqualArray(this._ellies, newEllies)) {

    this._ellies = newEllies

    if (emit) this.emit(Room.EVT_ELLIES_UPDATED, this.ellies)
  }

  if (!BasUtil.isEqualArray(this._lisas, newLisas)) {

    this._lisas = newLisas

    if (emit) this.emit(Room.EVT_LISAS_UPDATED, this.lisas)
  }

  if (!BasUtil.isEqualArray(this._lenas, newLenas)) {

    this._lenas = newLenas

    if (emit) this.emit(Room.EVT_LENAS_UPDATED, this.lenas)
  }

  if (this._sceneCtrl !== newSceneCtrl) {

    this._sceneCtrl = newSceneCtrl

    if (emit) this.emit(Room.EVT_SCENE_CTRL_UPDATED, this.sceneCtrl)
  }

  if (this._timerCtrl !== newTimerCtrl) {

    this._timerCtrl = newTimerCtrl

    if (emit) this.emit(Room.EVT_TIMER_CTRL_UPDATED, this.timerCtrl)
  }

  if (!BasUtil.isEqualArray(this._thermostats, newThermostats)) {

    this._thermostats = newThermostats

    if (emit) this.emit(Room.EVT_THERMOSTATS_UPDATED, this.thermostats)
  }

  if (!BasUtil.isEqualArray(this._pools, newPools)) {

    this._pools = newPools

    if (emit) this.emit(Room.EVT_POOLS_UPDATED, this.pools)
  }

  if (!BasUtil.isEqualArray(this._lights, newLights)) {

    this._lights = newLights

    if (emit) this.emit(Room.EVT_LIGHTS_UPDATED, this.lights)
  }

  if (!BasUtil.isEqualArray(this._shades, newShades)) {

    this._shades = newShades

    if (emit) this.emit(Room.EVT_SHADES_UPDATED, this.shades)
  }

  if (!BasUtil.isEqualArray(this._genericDevices, newGenericDevices)) {

    this._genericDevices = newGenericDevices

    if (emit) {

      this.emit(Room.EVT_GENERIC_DEVICES_UPDATED, this.genericDevices)
    }
  }

  if (!BasUtil.isEqualArray(this._openCloseDevices, newOpenCloseDevices)) {

    this._openCloseDevices = newOpenCloseDevices

    if (emit) {

      this.emit(
        Room.EVT_OPEN_CLOSE_DEVICES_UPDATED,
        this.openCloseDevices
      )
    }
  }

  if (!BasUtil.isEqualArray(this._cameras, newCameras)) {

    this._cameras = newCameras

    if (emit) this.emit(Room.EVT_SECURITY_UPDATED, this.cameras)
  }

  if (!BasUtil.isEqualArray(this._doorPhoneGateways, newDoorPhoneGateways)) {

    this._doorPhoneGateways = newDoorPhoneGateways

    if (emit) {

      this.emit(
        Room.EVT_DOOR_PHONE_GATEWAYS_UPDATED,
        this.doorPhoneGateways
      )
    }
  }

  if (!BasUtil.isEqualArray(this._doorPhones, newDoorPhones)) {

    this._doorPhones = newDoorPhones

    if (emit) this.emit(Room.EVT_DOOR_PHONES_UPDATED, this.doorPhones)
  }

  if (!BasUtil.isEqualArray(this._weatherStations, newWeatherStations)) {

    this._weatherStations = newWeatherStations

    if (emit) {

      this.emit(Room.EVT_WEATHER_STATIONS_UPDATED, this.weatherStations)
    }
  }

  if (!BasUtil.isEqualArray(this._energy, newEnergy)) {

    this._energy = newEnergy

    if (emit) this.emit(Room.EVT_ENERGY_UPDATED, this.energy)
  }
}

/**
 * Set images
 *
 * @param {?Object} images
 * @since 2.11.0
 */
Room.prototype.setImages = function (images) {

  this._images = images
}

/**
 * Send new image to server of clear action if body is an empty string
 *
 * @param {string} body BASE64 encoded image
 * @returns {Promise}
 * @since 2.11.0
 */
Room.prototype.updateImage = function (body) {

  if (this._basCore) {

    if (BasUtil.isString(body)) {

      return this._performAction(
        body
          ? P.SET_IMAGE
          : P.CLEAR_IMAGE,
        body,
        CONSTANTS.RETRY_OPTS_ONE_SHOT_LONG
      ).then(_processImageActionResponse)
    }

    return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
  }

  return Promise.reject(CONSTANTS.ERR_NO_CORE)
}

function _processImageActionResponse (response) {

  var result, reason

  reason = CONSTANTS.ERR_RESULT

  if (response &&
    response.room &&
    BasUtil.isNEString(response.room.result)) {

    result = response.room.result

    if (result === P.OK) {

      return result

    } else if (BasUtil.isNEString(response.room.reason)) {

      reason = response.room.reason
    }
  }

  return Promise.reject(reason)
}

/**
 * Send action
 *
 * @param {string} action
 * @param {string} [body]
 * @param {TBasRequestOptions} [options]
 * @returns {Promise}
 * @since 2.11.0
 */
Room.prototype._performAction = function (
  action,
  body,
  options
) {
  var msg

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (BasUtil.isNEString(action)) {

    msg = this._getBasCoreMessage()
    msg[P.ROOM][P.ACTION] = action

    if (BasUtil.isNEString(body)) {

      msg[P.ROOM][P.BODY] = body
    }

    return this._basCore.requestRetry(msg, options)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Parse AV
 *
 * @private
 * @param {Object} av
 * @since 3.4.0
 */
Room.prototype._parseAV = function (av) {

  if (
    this._av &&
    this._av.audio instanceof AVAudio &&
    BasUtil.isObject(av[P.AUDIO])
  ) {

    this._av.audio.parse(av[P.AUDIO])
  }

  if (
    this._av &&
    this._av.video instanceof AVVideo &&
    BasUtil.isObject(av[P.VIDEO])
  ) {

    this._av.video.parse(av[P.VIDEO])
  }
}

/**
 * Creates a template basCore message for this room
 *
 * @protected
 * @returns {TRoomMessage}
 * @since 2.11.0
 */
Room.prototype._getBasCoreMessage = function () {

  var msg = {}
  msg[P.ROOM] = {}
  msg[P.ROOM][P.UUID] = this._uuid

  return msg
}

/**
 * @protected
 */
Room.prototype.destroy = function () {

  this._basCore = null
  this.removeAllListeners()
  this._isDestroyed = true
}

module.exports = Room
