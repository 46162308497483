[
  {
    "uuid": "userDemo",
    "username": "Demo",
    "admin": true,
    "avatarUrl": "img/demo/avatars/parents.jpg",
    "default": 63,
    "permissions": {
      "alarms": false,
      "dashboard": true
    }
  }
]
