'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var BasCoreStatusError = require('./bas_core_status_error')

var log = require('./logger')

/**
 * @constructor
 * @since 3.0.0
 */
function BasCoreStatus () {

  /**
   * @type {boolean}
   */
  this.hasUpdate = false

  /**
   * @type {string}
   */
  this.update = BasCoreStatus.S_NONE

  /**
   * @type {BasCoreStatusError[]}
   */
  this.errors = []
}

/**
 * @constant {string}
 */
BasCoreStatus.S_NONE = P.NONE

/**
 * @constant {string}
 */
BasCoreStatus.S_CHECKING = P.CHECKING

/**
 * @constant {string}
 */
BasCoreStatus.S_DOWNLOADING = P.DOWNLOADING

/**
 * @constant {string}
 */
BasCoreStatus.S_READY = P.READY

/**
 * @constant {string}
 */
BasCoreStatus.S_ERROR = P.ERROR

/**
 * @param {Object} obj
 * @returns {?BasCoreStatus}
 */
BasCoreStatus.parse = function (obj) {

  var result, _value, length, i, processed

  if (BasUtil.isObject(obj)) {

    result = new BasCoreStatus()

    // HasUpdate

    _value = obj[P.HAS_UPDATE]

    if (!BasUtil.isBool(_value)) return null

    result.hasUpdate = _value

    // Update

    _value = obj[P.UPDATE]

    if (!BasUtil.isNEString(_value)) return null

    if (_value === BasCoreStatus.S_NONE ||
      _value === BasCoreStatus.S_CHECKING ||
      _value === BasCoreStatus.S_DOWNLOADING ||
      _value === BasCoreStatus.S_READY ||
      _value === BasCoreStatus.S_ERROR) {

      result.update = _value

    } else {

      log.warn('BasCoreStatus - Unknown update state', obj)
    }

    // Errors

    _value = obj[P.ERRORS]

    if (!Array.isArray(_value)) return null

    length = _value.length
    for (i = 0; i < length; i++) {

      processed = BasCoreStatusError.parse(_value[i])

      if (processed) {

        result.errors.push(processed)
      }
    }

    return result
  }

  return null
}

module.exports = BasCoreStatus
