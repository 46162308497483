'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('addServerModalCtrl', [
    '$scope',
    '$rootScope',
    'BAS_SPLASH',
    'BasStoredServers',
    'BasUtilities',
    'close',
    addServerModalCtrl
  ])

/**
 * @param $scope
 * @param $rootScope
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasStoredServers} BasStoredServers
 * @param {BasUtilities} BasUtilities
 * @param close
 */
function addServerModalCtrl (
  $scope,
  $rootScope,
  BAS_SPLASH,
  BasStoredServers,
  BasUtilities,
  close
) {
  var modal = this

  var _listeners = []

  modal.close = close

  modal.vars = {}
  modal.vars.name = ''
  modal.vars.address = ''

  modal.errorReason = ''

  modal.save = save

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)
  }

  function save () {

    var result

    modal.errorReason = ''

    if (BasUtil.isNEString(modal.vars.name) &&
      BasUtil.isNEString(modal.vars.address)) {

      result = BasStoredServers.addServer(
        modal.vars.name,
        modal.vars.address
      )

      if (result === BasStoredServers.OK) {

        modal.close()

      } else if (result === BasStoredServers.ERR_SERVER_EXISTS) {

        modal.errorReason =
          BasUtilities.translate('server_exists_already')

      } else if (result === BasStoredServers.ERR_INVALID_ADDRESS) {

        modal.errorReason =
          BasUtilities.translate('invalid_address')
      }

    } else {

      modal.errorReason = BasUtilities.translate('invalid_input')
    }
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
