'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basImageTransition')
  .service('BasImageUtil', BasImageUtil)

/**
 * @constructor
 */
function BasImageUtil () {

  var WIDTH_REGEX = /w\d+/

  this.getSizedImage = getSizedImage

  /**
   * @param {Object<string,string>}images
   * @param {string} type
   * @param {number} elementSize
   * @returns {string}
   */
  function getSizedImage (
    images,
    type,
    elementSize
  ) {
    var finalImage, keys, length, i, key, keySize
    var matches, finalSize, numberStr

    finalImage = ''

    if (BasUtil.isObject(images) && BasUtil.isNEString(type)) {

      keys = Object.keys(images)
      length = keys.length

      for (i = 0; i < length; i++) {

        key = keys[i]

        if (key.indexOf(type) !== -1) {

          matches = key.match(WIDTH_REGEX)
          if (BasUtil.isNEArray(matches)) {

            numberStr = matches[0].substring(1)
            keySize = numberStr * 1

            if (!finalSize ||
              (
                keySize <= elementSize &&
                keySize > finalSize
              ) ||
              (
                keySize >= elementSize &&
                (
                  keySize < finalSize ||
                  finalSize < elementSize
                )
              )) {

              finalSize = keySize
              finalImage = images[key]
            }
          }
        }
      }

      // If finalImage is not set and there is at least one value present,
      //  then set finalImage to the first value
      if (finalImage === '' && length > 0) {

        finalImage = images[keys[0]]
      }
    }

    return finalImage
  }
}
