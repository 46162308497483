'use strict'

import * as BasUtil from '@basalte/bas-util'

// This directive aims to offer a single solution to handle all click events,
//  where ng-click would nog register longer clicks on touch devices.
//
// This directive's default behaviour is to use these events instead of the
//  'click' event that ng-click uses:
//  - Touch: touchstart, touchmove, touchend
//  - Mouse: mousedown, mousemove, mouseup
//
// The touchend/mousedown events will always be prevented (see comment line
//  446), which results in the 'click event no longer being emitted too.
//  Consequently, this directive should NOT BE USED on or around elements that
//  have behaviour tied to the 'click' event. This includes, but is not limited
//  to 'input' elements. The best way to prevent situations like this is to just
//  change up your html structure so such nesting is no longer taking place.
// Another solution is to set 'data-enable-click-mode' to true, which will then
//  fall back to the original 'click' handling (as it would be when using the
//  ng-click directive).
//
// When nesting bas-click elements within each other, you should not mix
//  'data-enable-click-mode' true and false. Inner nested bas-click elements
//  should set 'data-stop-propagation' to true to prevent the event being
//  propagated to the outer bas-click element.
//
// Directive parameters:
//  - data-enable-click-mode:
//      directive will use 'click' events instead of touch and mouse events
//  - data-prevent-default:
//      directive will call 'preventDefault()' on the touchstart/mousedown
//      event, or on the 'click' event when 'data-enable-click-mode' is set to
//      true
//  - data-stop-propagation:
//      directive will call 'stopPropagation()' on the touchstart/mousedown
//      event, or on the 'click' event when 'data-enable-click-mode' is set to
//      true
//
// Because of iOS shenanigans (iOS webview still sends out emulated mouse
//  events, evan after preventDefaulting the touchend event, iOS safari works
//  fine), we just ignore all mouse events on iOS builds until 2 seconds after
//  touch end events. We don't disable mouse events completely as to not break
//  iPad + mouse support.
angular
  .module('basalteApp')
  .directive('basClick', basClickDirective)

function basClickDirective () {

  return {
    restrict: 'A',
    controller: [
      '$scope',
      '$element',
      'BasSupports',
      'UiHelper',
      'BasAppDevice',
      basClickController
    ],
    bindToController: {
      preventDefault: '<?',
      stopPropagation: '<?',
      maxDistance: '<?',
      enableClickMode: '<?',
      enableHoldMode: '<?',
      longClickThreshold: '<?',
      basClick: '&',
      basClickLong: '&?',
      onPress: '&?',
      onHold: '&?',
      onRelease: '&?',
      holdInterval: '<?',
      scrollParentClass: '<?',
      holdDelay: '<?'
    },
    controllerAs: 'basClick'
  }

  function basClickController (
    $scope,
    $element,
    BasSupports,
    UiHelper,
    BasAppDevice
  ) {
    var basClick = this

    var DEFAULT_MAX_DISTANCE = 25
    var DEFAULT_STOP_PROPAGATION = false
    var DEFAULT_PREVENT_DEFAULT = false
    var DEFAULT_ENABLE_CLICK_MODE = false
    var DEFAULT_ENABLE_HOLD_MODE = false
    var DEFAULT_LONG_CLICK_THRESHOLD_MS = 400
    var DEFAULT_HOLD_INTERVAL = 500
    var DEFAULT_HOLD_DELAY = 200
    var DEFAULT_SCROLL_PARENT_CLASS = 'bas-trigger-scroll-parent'

    var IOS_MOUSE_DISABLE_TIMEOUT_MS = 2000

    var CSS_ACTIVE = 'bas-btn-active'
    var CSS_ACTIVE_FILL = 'bs-btn-active-highlight-fill-transparent'
    var CSS_DISABLE_SCROLL = 'disable-scroll'

    /**
     * @type {TBasSupports}
     */
    var basSupports = BasSupports.get()

    var element = $element[0]

    // Configurable properties
    var preventDefault = DEFAULT_PREVENT_DEFAULT
    var stopPropagation = DEFAULT_STOP_PROPAGATION
    var maxDistance = DEFAULT_MAX_DISTANCE
    var enableClickMode = DEFAULT_ENABLE_CLICK_MODE
    var longClickThresholdMs = DEFAULT_LONG_CLICK_THRESHOLD_MS
    var enableHoldMode = DEFAULT_ENABLE_HOLD_MODE

    var maxDistanceSquared = maxDistance * maxDistance

    var origPos = null
    var touchId = -1
    // Array that keeps track of destructors of listeners that were
    //  dynamically set after a touchstart or mousedown event.
    var dynamicListeners = []
    // Array that keeps track of destructors of listeners that are
    //  always set.
    var permanentListeners = []
    var outlivingPermanentListeners = []
    var lastTouchEndTimestamp = -1
    var longClickTimeoutId = -1
    var lastStartEvent = null
    var waitAfterLongClick = false

    var _holdStartEvent = null
    var _holdDelayTimeoutId = 0
    var _holdIntervalId = 0
    var _holdHighlightResetTimeoutId = 0

    var destroyed = false

    var _activeListenerOptions = basSupports.passiveListeners
      ? {
          capture: false,
          passive: false
        }
      : undefined
    var _passiveListenerOptions = basSupports.passiveListeners
      ? {
          capture: false,
          passive: true
        }
      : undefined

    basClick.$postLink = _onPostLink
    basClick.$onChanges = _onChanges
    basClick.$onDestroy = _onDestroy

    function _onPostLink () {

      init()
    }

    function _onChanges () {

      syncConfigParameters()
      setPermanentListeners()
    }

    function init () {

      syncConfigParameters()
      setPermanentListeners()
    }

    function handleStart (event, isMouseEvent) {

      if (preventDefault && event.cancelable) event.preventDefault()
      if (stopPropagation) event.stopPropagation()

      if (
        isMouseEvent &&
        BasAppDevice.isIos() &&
        (Date.now() - lastTouchEndTimestamp) < IOS_MOUSE_DISABLE_TIMEOUT_MS
      ) {

        // If 'last touchend' was less than 2 seconds ago, and the platform is
        //  iOS (!!!), ignore mouse events.
        return
      }

      if (isMouseEvent) {

        // Only allow left mouse button
        if (event.button === 0) {

          origPos = handleInput(event)

          if (origPos) {

            lastStartEvent = event

            setMouseListeners()

            if (enableHoldMode) {

              _holdStartEvent = event

              clearHoldDelayTimeout()
              _startHoldInterval()

            } else {

              startLongClickTimeout()
            }
          }
        }

      } else if (touchId === -1) {

        origPos = handleInput(event)

        if (origPos) {

          lastStartEvent = event

          setTouchListeners()

          if (enableHoldMode) {

            _holdStartEvent = event

            clearHoldDelayTimeout()
            _holdDelayTimeoutId = setTimeout(
              _startHoldInterval,
              BasUtil.isPNumber(basClick.holdDelay, true)
                ? basClick.holdDelay
                : DEFAULT_HOLD_DELAY
            )
          } else {

            startLongClickTimeout()
          }
        }
      }

      function startLongClickTimeout () {

        if (basClick.basClickLong) {

          setLongClickTimeout()
        }
      }
    }

    function handleEnd (event, isMouseEvent) {

      var eventPos, distanceSquared

      if (isMouseEvent || getTouchById(event, touchId)) {

        clearLongClickTimeout()

        if (enableHoldMode) {

          holdRelease()

        } else {

          if (origPos) {

            eventPos = handleInput(event)

            if (eventPos) {

              distanceSquared = BasUtil.distanceSquared(
                eventPos.x,
                eventPos.y,
                origPos.x,
                origPos.y
              )

              if (distanceSquared < maxDistanceSquared) {

                _scopeApply(executeHandler)
              }
            }
          }
        }

        touchId = -1
        origPos = null
      }

      function executeHandler () {

        BasUtil.exec(basClick.basClick, { $event: event })
      }
    }

    function handleCancel (event, isMouseEvent) {

      if (isMouseEvent || getTouchById(event, touchId)) {

        touchId = -1
        origPos = null
        clearDynamicListeners()
        clearLongClickTimeout()

        if (enableHoldMode) {

          holdRelease()
        }
      }
    }

    function handleMove (event, isMouseEvent) {

      var distanceSquared, eventPos

      if (isMouseEvent || getTouchById(event, touchId)) {

        if (origPos) {

          if (enableHoldMode && _holdIntervalId) {

            _preventDefault(event)
            return
          }

          eventPos = handleInput(event)

          if (eventPos) {

            distanceSquared = BasUtil.distanceSquared(
              eventPos.x,
              eventPos.y,
              origPos.x,
              origPos.y
            )

            if (distanceSquared >= maxDistanceSquared) {

              clearLongClickTimeout()
              clearHoldDelayTimeout()

              if (!(enableHoldMode && _holdIntervalId)) {

                touchId = -1
                origPos = null

                clearDynamicListeners()
              }
            }
          }
        }
      }
    }

    function handleClick (event) {

      if (preventDefault && event.cancelable) event.preventDefault()
      if (stopPropagation) event.stopPropagation()

      _scopeApply(executeHandler)

      function executeHandler () {
        BasUtil.exec(basClick.basClick, { $event: event })
      }
    }

    function handlePermanentEndHandler (event, isMouseEvent) {

      if (!isMouseEvent || event.button === 0) {

        _preventDefault(event)

        if (waitAfterLongClick && destroyed) {

          clearOutlivingPermanentListeners()
        }

        waitAfterLongClick = false
      }
    }

    function setLongClickTimeout () {

      clearLongClickTimeout()

      longClickTimeoutId = setTimeout(
        onLongClickTimeout,
        longClickThresholdMs
      )

      function onLongClickTimeout () {

        waitAfterLongClick = true

        _scopeApply(executeHandler)

        touchId = -1
        origPos = null

        clearDynamicListeners()

        function executeHandler () {

          BasUtil.exec(basClick.basClickLong, { $event: lastStartEvent })
        }
      }
    }

    function clearLongClickTimeout () {

      clearTimeout(longClickTimeoutId)
      longClickTimeoutId = -1
    }

    function getTouchById (event, inputTouchId) {

      var i, length, touch

      if (BasUtil.isObject(event.changedTouches)) {

        length = event.changedTouches.length

        for (i = 0; i < length; i++) {

          touch = event.changedTouches[i]

          if (touch.identifier === inputTouchId) return touch
        }
      }

      return null
    }

    /**
     * @param {(TouchEvent|MouseEvent)} event
     * @returns {Object}
     */
    function handleInput (event) {

      var result, touch

      if (BasUtil.isObject(event.changedTouches)) {

        if (touchId !== -1) {

          touch = getTouchById(event, touchId)

        } else {

          touch = event.changedTouches[0]
        }

        if (BasUtil.isObject(touch)) {

          result = {}
          result.x = touch.clientX
          result.y = touch.clientY

          touchId = touch.identifier

          return result
        }

        return null

      } else if (
        BasUtil.isVNumber(event.clientX) &&
        BasUtil.isVNumber(event.clientY)
      ) {

        result = {}
        result.x = event.clientX
        result.y = event.clientY

        return result
      }

      return null
    }

    function setTouchListeners () {

      clearDynamicListeners()

      dynamicListeners.push(BasUtil.setDOMListener(
        element,
        'touchend',
        handleEnd,
        _passiveListenerOptions
      ))
      dynamicListeners.push(BasUtil.setDOMListener(
        element,
        'touchcancel',
        handleCancel,
        _passiveListenerOptions
      ))
      dynamicListeners.push(BasUtil.setDOMListener(
        element,
        'touchmove',
        handleMove,
        enableHoldMode
          ? _activeListenerOptions
          : _passiveListenerOptions
      ))
      // No need to set document listeners since these
      // events are still fired even though
      // the touch goes outside the element
    }

    function setMouseListeners () {

      clearDynamicListeners()

      dynamicListeners.push(BasUtil.setDOMListener(
        element,
        'mouseup',
        handleMouseEnd,
        _activeListenerOptions
      ))
      dynamicListeners.push(BasUtil.setDOMListener(
        document,
        'mouseup',
        handleMouseCancel,
        _activeListenerOptions
      ))
      dynamicListeners.push(BasUtil.setDOMListener(
        document,
        'mousemove',
        handleMouseMove,
        _passiveListenerOptions
      ))

      function handleMouseEnd (event) {

        handleEnd(event, true)
      }

      function handleMouseMove (event) {

        handleMove(event, true)
      }

      function handleMouseCancel (event) {

        handleCancel(event, true)
      }
    }

    function clearDynamicListeners () {

      BasUtil.executeArray(dynamicListeners)
      dynamicListeners = []
    }

    function _startHoldInterval () {

      holdCssToggleActive(true)

      _executePress()
      _holdIntervalId = setInterval(
        _executeHold,
        BasUtil.isPNumber(basClick.holdInterval, true)
          ? basClick.holdInterval
          : DEFAULT_HOLD_INTERVAL
      )
    }

    function _executePress () {

      if (BasUtil.isFunction(basClick.onPress)) {

        _scopeApply(basClick.onPress)
      }
    }

    function _executeHold () {

      if (BasUtil.isFunction(basClick.onHold)) {

        _scopeApply(basClick.onHold)
      }
    }

    function _executeRelease () {

      if (BasUtil.isFunction(basClick.onRelease)) {

        _scopeApply(basClick.onRelease)
      }
    }

    /**
     * @private
     * @param {boolean} [force]
     */
    function holdCssToggleActive (force) {

      var _element

      clearHighlightResetTimeout()

      if ($element && $element[0]) {

        /**
         * @type {HTMLElement}
         */
        _element = $element[0]

        _element.classList.toggle(CSS_ACTIVE, force)
        _element.classList.toggle(CSS_ACTIVE_FILL, force)

        _element = _getScrollingElement(_element)

        if (_element) {

          _element.classList.toggle(CSS_DISABLE_SCROLL, force)
        }
      }
    }

    function holdRelease () {

      if (_holdStartEvent) {

        if (!_holdIntervalId) {

          // Short press-release where interval was not started yet:
          //  first execute active
          _executePress()

          // Show active highlight for 200ms (standard animation time)
          holdCssToggleActive(true)
          _holdHighlightResetTimeoutId = setTimeout(
            holCssToggleActiveFalse,
            200
          )

        } else {

          holdCssToggleActive(false)
        }

        _executeRelease()

        _holdStartEvent = null
      }

      clearHoldInterval()
      clearHoldDelayTimeout()
      clearDynamicListeners()

      function holCssToggleActiveFalse () {
        holdCssToggleActive(false)
      }
    }

    function _getScrollingElement (_element) {

      return UiHelper.getElementParentWithClassName(
        _element,
        BasUtil.isNEString(basClick.scrollParentClass)
          ? basClick.scrollParentClass
          : DEFAULT_SCROLL_PARENT_CLASS
      )
    }

    function clearCurrentHold () {

      clearHoldInterval()
      clearHoldDelayTimeout()
      clearDynamicListeners()
    }

    function clearHoldInterval () {

      clearInterval(_holdIntervalId)
      _holdIntervalId = 0
    }

    function clearHoldDelayTimeout () {

      clearTimeout(_holdDelayTimeoutId)
      _holdDelayTimeoutId = 0
    }

    function clearHighlightResetTimeout () {

      clearTimeout(_holdHighlightResetTimeoutId)
      _holdHighlightResetTimeoutId = 0
    }

    function setPermanentListeners () {

      clearPermanentListeners(true)

      // Click mode is not combinable with hold mode
      if (enableClickMode && !enableHoldMode) {

        permanentListeners.push(BasUtil.setDOMListener(
          element,
          'click',
          handleClick,
          _activeListenerOptions
        ))

      } else {

        // Touch events
        permanentListeners.push(BasUtil.setDOMListener(
          element,
          'touchstart',
          handleStart,
          _activeListenerOptions
        ))

        if (BasAppDevice.isIos()) {

          permanentListeners.push(BasUtil.setDOMListener(
            document,
            'touchend',
            setLastTouchEndTimestamp,
            _passiveListenerOptions
          ))
        }

        // Mouse events
        permanentListeners.push(BasUtil.setDOMListener(
          element,
          'mousedown',
          handleMouseStart,
          _activeListenerOptions
        ))

        // Block context menu (easier for development)
        permanentListeners.push(BasUtil.setDOMListener(
          element,
          'contextmenu',
          _preventDefault,
          _activeListenerOptions
        ))

        // We need to 'preventDefault' the 'touchend' action, as to prevent
        //  that the browser will send out 'mouse' or 'click' emulation events.
        // https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Supporting_both_TouchEvent_and_MouseEvent#event_order
        //
        // There are ways to somehow detect that mouse events are actually
        //  emulated, but they are unreliable on some platforms (like checking
        //  the event.timeStamp property on iOS) or introduce delays. Until
        //  a reliable way is found to detect emulated mouse events on all
        //  platforms, we will preventDefault the touchend event to disable
        //  emulated mouse events. For consistency and easier debugging, the
        //  'mouseup' event is also prevented (when button is left click).
        //
        // We put this in a separate listener so we can keep the temporary
        //  'touchend' listeners passive, and to fix iOS issue where calling
        //  preventDefault does not always prevent mouseEvents when the listener
        //  is dynamically added (after touchstart was triggered).
        // The 'touchend' and 'mouseup' listeners are not destroyed when the
        //  directive is destroyed, like the other listeners. This is to make
        //  sure that the touchend/mouseup events are prevented and no emulated
        //  mouse or click events are emitted when the touchstart-touchend or
        //  mousedown-mouseup lifecycle outlives the directive (e.g. when long
        //  click triggers a state change).
        outlivingPermanentListeners.push(BasUtil.setDOMListener(
          element,
          'touchend',
          handlePermanentEndHandler,
          _activeListenerOptions
        ))
        outlivingPermanentListeners.push(BasUtil.setDOMListener(
          element,
          'mouseup',
          handleMousePermanentEndHandler,
          _activeListenerOptions
        ))
      }

      function handleMouseStart (event) {

        handleStart(event, true)
      }

      function setLastTouchEndTimestamp () {

        lastTouchEndTimestamp = Date.now()
      }

      function handleMousePermanentEndHandler (event) {

        handlePermanentEndHandler(event, true)
      }
    }

    function clearPermanentListeners (clearOutliving) {

      BasUtil.executeArray(permanentListeners)
      permanentListeners = []

      if (clearOutliving) {

        clearOutlivingPermanentListeners()
      }
    }

    function clearOutlivingPermanentListeners () {

      BasUtil.executeArray(outlivingPermanentListeners)
      outlivingPermanentListeners = []
    }

    function syncConfigParameters () {

      preventDefault = BasUtil.isBool(basClick.preventDefault)
        ? basClick.preventDefault
        : DEFAULT_PREVENT_DEFAULT

      stopPropagation = BasUtil.isBool(basClick.stopPropagation)
        ? basClick.stopPropagation
        : DEFAULT_STOP_PROPAGATION

      enableClickMode = BasUtil.isBool(basClick.enableClickMode)
        ? basClick.enableClickMode
        : DEFAULT_ENABLE_CLICK_MODE

      enableHoldMode = BasUtil.isBool(basClick.enableHoldMode)
        ? basClick.enableHoldMode
        : DEFAULT_ENABLE_HOLD_MODE

      longClickThresholdMs = BasUtil.isPNumber(basClick.longClickThreshold)
        ? basClick.longClickThreshold
        : DEFAULT_LONG_CLICK_THRESHOLD_MS

      maxDistance = BasUtil.isPNumber(basClick.maxDistance, true)
        ? basClick.maxDistance
        : DEFAULT_MAX_DISTANCE
      maxDistanceSquared = maxDistance * maxDistance
    }

    function _preventDefault (event) {

      if (
        event &&
        event.cancelable &&
        !event.defaultPrevented &&
        BasUtil.isFunction(event.preventDefault)
      ) {

        event.preventDefault()
      }
    }

    function _scopeApply (handler) {

      try {

        $scope.$apply(handler)

      } catch (e) {

        $scope.$applyAsync(handler)
      }
    }

    function _onDestroy () {

      destroyed = true

      clearCurrentHold()
      clearHighlightResetTimeout()

      clearLongClickTimeout()
      clearPermanentListeners(!waitAfterLongClick)
      clearDynamicListeners()
    }
  }
}
