'use strict'

angular
  .module('basalteApp')
  .controller('deviceSettingsCtrl', [
    'MainDrawer',
    deviceSettingsCtrl
  ])

/**
 * @param {MainDrawer} MainDrawer
 */
function deviceSettingsCtrl (
  MainDrawer
) {
  var devicesettings = this

  /**
   * @type {MainDrawerState}
   */
  devicesettings.mainDrawer = MainDrawer.get()
}
