'use strict'

angular
  .module('basalteApp')
  .controller('roomImagesRoomSettingCtrl', [
    '$uiRouterGlobals',
    roomImagesRoomSettingCtrl
  ])

/**
 * @param $uiRouterGlobals
 */
function roomImagesRoomSettingCtrl (
  $uiRouterGlobals
) {
  var roomImagesRoom = this

  roomImagesRoom.$uiRouterGlobals = $uiRouterGlobals
}
