'use strict'

import blurWorker from 'inline-worker:../../workers/blurworker'

angular
  .module('basBlur', [])
  .service('BasBlur', [
    '$window',
    BasBlur
  ])

/**
 * @constructor
 * @param $window
 */
function BasBlur ($window) {

  var K_IMAGE_DATA = 'imageData'
  var K_WIDTH = 'width'
  var K_HEIGHT = 'height'
  var K_BLUR_RADIUS = 'blurRadius'

  var ERR_CANCELED = 'errCanceled'
  var ERR_WORKER = 'errWorker'
  var ERR_ELEMENT = 'errNoElement'

  /**
   * @type {HTMLCanvasElement}
   */
  var canvas = $window.document.createElement('canvas')

  /**
   * @type {CanvasRenderingContext2D}
   */
  var context = canvas.getContext(
    '2d',
    {
      alpha: false
    }
  )

  /**
   * @constant {string}
   */
  this.ERR_CANCELED = ERR_CANCELED

  /**
   * @constant {string}
   */
  this.ERR_WORKER = ERR_WORKER

  this.getBlurredImage = getBlurredImage

  /**
   * Returns a cancellation function
   *
   * @param {HTMLImageElement} imageElement
   * @param {number} blurRadius
   * @param callback
   * @returns {Function}
   */
  function getBlurredImage (imageElement, blurRadius, callback) {

    var cbResolved, worker

    cbResolved = false

    if (imageElement) {

      onElement(imageElement)

    } else {

      // This ensures async behaviour
      setTimeout(
        _errorElementCallback,
        0
      )
    }

    return cancel

    function _errorElementCallback () {

      _cb(ERR_ELEMENT)
    }

    /**
     * @param {HTMLImageElement} result
     */
    function onElement (result) {

      var imgWidth, imgHeight, imgData, msg

      imgWidth = result.naturalWidth
      imgHeight = result.naturalHeight

      prepareCanvas(imgWidth, imgHeight)

      context.drawImage(result, 0, 0)

      if (typeof blurRadius === 'number' &&
          isFinite(blurRadius) &&
          blurRadius >= 0) {

        try {

          imgData = context.getImageData(
            0,
            0,
            imgWidth,
            imgHeight
          )

        } catch (imageDataError) {

          _cb(imageDataError)
        }

        if (imgData) {

          worker = new Worker(blurWorker)

          if (worker) {

            worker.onmessage = onMessage

            msg = {}
            msg[K_IMAGE_DATA] = imgData
            msg[K_WIDTH] = imgWidth
            msg[K_HEIGHT] = imgHeight
            msg[K_BLUR_RADIUS] = blurRadius

            worker.postMessage(msg)

          } else {

            _cb(ERR_WORKER)
          }
        }

      } else {

        resolveWithCanvasDataUrl()
      }
    }

    /**
     * @param {MessageEvent} msg
     */
    function onMessage (msg) {

      var imgWidth, imgHeight

      worker.terminate()

      if (typeof msg.data === 'object' && msg.data) {

        imgWidth = msg.data[K_WIDTH]
        imgHeight = msg.data[K_HEIGHT]

        prepareCanvas(imgWidth, imgHeight)

        context.putImageData(msg.data[K_IMAGE_DATA], 0, 0)

        resolveWithCanvasDataUrl()

      } else {

        _cb(ERR_WORKER)
      }
    }

    function resolveWithCanvasDataUrl () {

      var dataUrl

      try {

        dataUrl = canvas.toDataURL()

      } catch (error) {

        _cb(error)
      }

      if (dataUrl) _cb(null, dataUrl)
    }

    function prepareCanvas (width, height) {

      canvas.width = width
      canvas.height = height
      context.clearRect(0, 0, width, height)
    }

    function cancel () {

      if (worker) worker.terminate()
      _cb(ERR_CANCELED)
    }

    function _cb (error, result) {

      if (!cbResolved) {

        cbResolved = true
        if (typeof callback === 'function') callback(error, result)
      }
    }
  }
}
