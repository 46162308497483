'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('StatesBasCoreConnection', [
    '$window',
    '$rootScope',
    '$transitions',
    'STATES',
    'BAS_CONNECTION',
    'BasState',
    'BasAppDevice',
    'BasCoreConnection',
    'BasLiveAccount',
    'CurrentBasCore',
    'BasCleanup',
    'BasSplash',
    'BasSplashScreen',
    'BasUtilities',
    StatesBasCoreConnection
  ])

/**
 * @constructor
 * @param $window
 * @param $rootScope
 * @param $transitions
 * @param {STATES} STATES
 * @param {BAS_CONNECTION} BAS_CONNECTION
 * @param {BasState} BasState
 * @param {BasAppDevice} BasAppDevice
 * @param {BasCoreConnection} BasCoreConnection
 * @param {BasLiveAccount} BasLiveAccount
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasCleanup} BasCleanup
 * @param {BasSplash} BasSplash
 * @param {BasSplashScreen} BasSplashScreen
 * @param {BasUtilities} BasUtilities
 */
function StatesBasCoreConnection (
  $window,
  $rootScope,
  $transitions,
  STATES,
  BAS_CONNECTION,
  BasState,
  BasAppDevice,
  BasCoreConnection,
  BasLiveAccount,
  CurrentBasCore,
  BasCleanup,
  BasSplash,
  BasSplashScreen,
  BasUtilities
) {
  /**
   * @type {TBasLiveAccountState}
   */
  var basLiveAccountState = BasLiveAccount.get()

  init()

  function init () {

    $transitions.onStart({}, _onStateChange)
  }

  /**
   * @private
   * @param transition
   * @returns {(boolean|Object|void)}
   */
  function _onStateChange (transition) {

    var to, from, toName, fromName, params, hasRebootReason, server

    to = transition.to()
    from = transition.from()
    params = transition.params()

    toName = to.name
    fromName = from.name

    hasRebootReason = false

    if (params) {

      if (params.reason === BAS_CONNECTION.REBOOT_REASON_RESTART ||
        params.reason === BAS_CONNECTION.REBOOT_REASON_UPDATE) {

        hasRebootReason = true
      }
    }

    // Leaving discovery => stop reconnect and clear splash
    if (fromName && fromName === STATES.CONNECT_DISCOVERY) {

      BasCoreConnection.stopAutoReconnect()

      if (!BasUtil.startsWith(toName, STATES.MAIN) &&
        toName !== STATES.CONNECT_PROFILES) {

        transition.promise.then(
          _onFromDiscoveryTransition,
          _onFromDiscoveryTransitionError
        )
      }
    }

    // Out of main => logout
    if (BasUtil.startsWith(fromName, STATES.MAIN) &&
      !BasUtil.startsWith(toName, STATES.MAIN)) {

      BasCleanup.onExit()

      if (!hasRebootReason) {

        BasCoreConnection.prepareLogout()
        BasCoreConnection.logout()
      }
    }

    // Pro Live

    if (BasAppDevice.isLiveOnly() && BasAppDevice.isProLive()) {

      // Do not allow account management, Pro Live handles account management

      if (basLiveAccountState.isLoggedIn || BasAppDevice.getPreferDemo()) {

        if (BasUtil.startsWith(toName, STATES.CONNECT_LIVE)) {

          return BasState.target(STATES.CONNECT_DISCOVERY)
        }

      } else {

        return BasLiveAccount.check()
          .then(_onProLiveBasLiveCheck, _onProLiveBasLiveCheck)
      }
    }

    if (basLiveAccountState.isLoggedIn) {

      // Prevent unlogical transitions while logged in

      if (BasUtil.startsWith(toName, STATES.CONNECT_LIVE_LOGIN) ||
        BasUtil.startsWith(toName, STATES.CONNECT_LIVE_FORGOT) ||
        BasUtil.startsWith(toName, STATES.CONNECT_LIVE_REGISTER)) {

        return BasState.target(STATES.CONNECT_LIVE)
      }

    } else if (BasAppDevice.isLiveOnly()) {

      // If not logged in => live

      if (!BasUtil.startsWith(toName, STATES.CONNECT_LIVE) &&
        !BasUtil.startsWith(toName, STATES.DEVICE_SETTINGS) &&
        !BasUtil.startsWith(toName, STATES.CONNECT_UNSUPPORTED)) {

        server = CurrentBasCore.getServer()

        // This makes it possible to go to demo
        if (!server ||
          !server.isDemo() ||
          !BasUtil.startsWith(toName, STATES.MAIN)) {

          BasUtilities.waitForFrames(
            2,
            _onShouldClearSplashFramesWaited
          )

          return BasAppDevice.supportsBasalteLive()
            ? BasState.target(STATES.CONNECT_LIVE)
            : BasState.target(STATES.CONNECT_UNSUPPORTED)
        }
      }
    }

    // Going to Lisa state but not in a Lisa build
    if (BasUtil.startsWith(toName, STATES.LISA)) {

      if (!BasAppDevice.isLisa()) {

        return BasState.target(STATES.MAIN)
      }
    }

    // Going to main
    if (BasUtil.startsWith(toName, STATES.MAIN)) {

      // From non-main to main state
      // Make sure splash is hidden

      if (!BasUtil.startsWith(fromName, STATES.MAIN)) {

        transition.promise.then(
          _onToMainFromNonMainTransition,
          _onToMainFromNonMainTransitionError
        )
      }

      // No core => discovery
      if (!CurrentBasCore.hasCore()) {

        BasCleanup.onExit()

        BasCoreConnection.prepareChangeBasCore()

        return BasState.target(STATES.CONNECT_DISCOVERY)
      }

      // No user => login
      // TODO Check for no user?
    }

    // Going to login but no core => discovery
    if (toName === STATES.CONNECT_PROFILES) {

      if (CurrentBasCore.has()) {

        server = CurrentBasCore.getServer()

        if (server) {

          if (server.isConnected()) {

            // Profiles is allowed

            transition.promise.then(
              _onToProfilesTransition,
              _onToProfilesTransitionError
            )

          } else {

            BasCleanup.onExit()

            BasCoreConnection.prepareChangeBasCore()

            return BasState.target(STATES.CONNECT_DISCOVERY)
          }

        } else {

          // Should not occur

          BasCleanup.onExit()

          BasCoreConnection.prepareChangeBasCore()

          return BasState.target(STATES.CONNECT_DISCOVERY)
        }

      } else {

        BasCleanup.onExit()

        BasCoreConnection.prepareChangeBasCore()

        return BasState.target(STATES.CONNECT_DISCOVERY)
      }
    }

    // Going to discovery => change core
    if (toName === STATES.CONNECT_DISCOVERY &&
      fromName &&
      fromName !== STATES.CONNECT_DISCOVERY) {

      BasCleanup.onExit()

      if (!hasRebootReason) {

        // Setting stopAutoReconnect to true would break autoReconnect
        BasCoreConnection.prepareChangeBasCore({ stopAutoReconnect: false })
      }
    }

    // Going to home dashboard but no home dashboard access => rooms
    if (toName === STATES.HOME) {

      if (!CurrentBasCore.hasHomePage()) {

        return BasState.target(STATES.ROOMS)
      }
    }

    function _onProLiveBasLiveCheck () {

      if (basLiveAccountState.isLoggedIn) {

        if (BasUtil.startsWith(toName, STATES.CONNECT_LIVE)) {

          return BasState.target(STATES.CONNECT_DISCOVERY)
        }

      } else {

        if ($window.location) {

          $window.location.href = BasAppDevice.getProLiveLoginUrl()
        }
      }
    }

    function _onFromDiscoveryTransition () {

      BasUtilities.waitForFrames(
        2,
        _onShouldClearSplashFramesWaited
      )
    }

    function _onFromDiscoveryTransitionError (error) {

      if (error && error.type === 2) {

        // Do nothing

      } else {

        BasUtilities.waitForFrames(
          2,
          _onShouldClearSplashFramesWaited
        )
      }
    }

    function _onToProfilesTransition () {

      BasUtilities.waitForFrames(
        2,
        _onShouldClearSplashFramesWaited
      )
    }

    function _onToProfilesTransitionError (error) {

      if (error && error.type === 2) {

        // Do nothing

      } else {

        BasUtilities.waitForFrames(
          2,
          _onShouldClearSplashFramesWaited
        )
      }
    }

    function _onToMainFromNonMainTransition () {

      BasUtilities.waitForFrames(
        10,
        _onShouldClearSplashFramesWaited
      )
    }

    function _onToMainFromNonMainTransitionError (error) {

      // Check if transition is superseded
      if (error && error.type === 2) {

        // Do nothing

      } else {

        BasUtilities.waitForFrames(
          2,
          _onShouldClearSplashFramesWaited
        )
      }
    }

    function _onShouldClearSplashFramesWaited () {

      BasSplash.hide()
      BasSplashScreen.hide()

      $rootScope.$applyAsync()
    }
  }
}
