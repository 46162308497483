'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('LibraryState', [
    '$rootScope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_STATE',
    'BasStateHelper',
    'PlayerLibraryState',
    LibraryState
  ])

/**
 * @typedef {Object} TLibraryState
 * @property {PlayerLibraryState} current
 * @property {Object<string, PlayerLibraryState>} map
 */

/**
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BasStateHelper} BasStateHelper
 * @param PlayerLibraryState
 */
function LibraryState (
  $rootScope,
  $uiRouterGlobals,
  STATES,
  BAS_STATE,
  BasStateHelper,
  PlayerLibraryState
) {
  /**
   * @type {TLibraryState}
   */
  var root = {}
  root.current = null
  root.map = {}

  this.get = get
  this.reset = reset
  this.getCurrentState = getCurrentState
  this.setPlayer = setPlayer
  this.getCurrentSource = getCurrentSource
  this.getDeezerSource = getDeezerSource
  this.getTidalSource = getTidalSource
  this.getSpotifySource = getSpotifySource

  init()

  function init () {

    $rootScope.$on(
      BAS_STATE.EVT_STATE_SUCCESS,
      _onStateChanged
    )
  }

  /**
   * @returns {TLibraryState}
   */
  function get () {
    return root
  }

  /**
   * @returns {?PlayerLibraryState}
   */
  function getCurrentState () {
    return root.current
  }

  /**
   * @param {(number|string)} playerId
   */
  function setPlayer (playerId) {

    if (
      BasUtil.isPNumber(playerId) ||
      BasUtil.isNEString(playerId)
    ) {
      if (root.map[playerId]) {

        root.current = root.map[playerId]

      } else {

        root.current = root.map[playerId] =
          new PlayerLibraryState(playerId)
      }
    }
  }

  /**
   * @returns {?BasSource}
   */
  function getCurrentSource () {

    if (root.current) return root.current.getBasSource()

    return null
  }

  /**
   * @returns {?BasSource}
   */
  function getDeezerSource () {

    if (root.current) return root.current.getDeezerSource()

    return null
  }

  /**
   * @returns {?BasSource}
   */
  function getTidalSource () {

    if (root.current) return root.current.getTidalSource()

    return null
  }

  /**
   * @returns {?BasSource}
   */
  function getSpotifySource () {

    if (root.current) return root.current.getSpotifySource()

    return null
  }

  function _onStateChanged () {

    var currentState

    currentState = $uiRouterGlobals.current.name

    if (BasStateHelper.hasBaseState(currentState, STATES.MUSIC_LIBRARY)) {

      if (
        BasStateHelper.hasBaseState(currentState, STATES.MUSIC_LIBRARY_LOCAL)
      ) {
        _updateState(STATES.MUSIC_LIBRARY_LOCAL)
      } else if (
        BasStateHelper.hasBaseState(currentState, STATES.MUSIC_LIBRARY_RADIO)
      ) {
        _updateState(STATES.MUSIC_LIBRARY_RADIO)
      } else if (
        BasStateHelper.hasBaseState(currentState, STATES.MUSIC_LIBRARY_DEEZER)
      ) {
        _updateState(STATES.MUSIC_LIBRARY_DEEZER)
      } else if (
        BasStateHelper.hasBaseState(currentState, STATES.MUSIC_LIBRARY_SPOTIFY)
      ) {
        _updateState(STATES.MUSIC_LIBRARY_SPOTIFY)
      } else if (
        BasStateHelper.hasBaseState(currentState, STATES.MUSIC_LIBRARY_TIDAL)
      ) {
        _updateState(STATES.MUSIC_LIBRARY_TIDAL)
      }
    }
  }

  /**
   * Store the last Library App state
   *
   * @private
   * @param {string} state UI router state name
   */
  function _updateState (state) {

    if (root.current) root.current.lastState = state
  }

  function reset () {

    root.current = null
    root.map = {}
  }
}
