'use strict'

angular
  .module('basalteApp')
  .controller('camerasCtrl', [
    'STATES',
    'BasCameras',
    'BasState',
    camerasCtrl
  ])

/**
 * @param {STATES} STATES
 * @param {BasCameras} BasCameras
 * @param {BasState} BasState
 */
function camerasCtrl (
  STATES,
  BasCameras,
  BasState
) {
  var cameras = this

  /**
   * @type {TCamerasState}
   */
  cameras.cameras = BasCameras.get()

  cameras.clickCamera = onCameraClick

  BasCameras.syncCameras()

  function onCameraClick (uuid) {

    BasState.go(
      STATES.CAMERAS_DETAIL,
      {
        camera: uuid
      }
    )
  }
}
