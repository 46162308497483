'use strict'

/**
 * The only place to get a BasSource instance is BAS_SOURCES.SOURCES.sources.
 *
 * Each BasCollection contains UUIDs as items.
 * These UUIDs can be mapped to a BasSource using BAS_SOURCES.SOURCES.sources.
 *
 * @typedef {Object} BasSources
 * @property {Object<string, BasSource>} sources
 * @property {Object<string, string>} audioSources UUID => UUID
 * @property {Object<string, string>} spotifyAudioSources UUID => UUID
 * @property {Object<string, string>} videoSources UUID => UUID
 * @property {Object<string, string>} players CobraNet ID => UUID
 * @property {Object<string, string>} playersOrAVSources
 * CobraNet ID / UUID => UUID
 * @property {Object<string, (string|string[])>} barps CobraNet ID => UUID
 * @property {Object<string, string>} externals CobraNet ID => UUID
 * @property {Object<string, string>} bluetooths CobraNet ID => UUID
 * @property {Object<string, string>} notifications CobraNet ID => UUID
 * @property {Object<string, string>} audioAlerts Room ID => UUID
 * @property {Object<string, string>} spotify CobraNet ID => UUID
 * @property {Object<string, string>} constants CobraNet ID => UUID
 * @property {BasCollection[]} uiBase Players, Externals, Bluetooths
 * @property {BasCollection[]} uiActive
 * @property {BasCollection[]} uiStreams Streams (Players)
 * Players (or Barps), Externals, Bluetooths
 * Seperate collection because derived from AudioSources (+ CobraNet IDs)
 * instead of legacy sources
 * @property {BasCollection[]} uiPlayers Players
 * @property {BasCollection[]} uiSpotify Spotify Barps
 * @property {BasCollection[]} uiSpotifyWeb Spotify Web Barps
 * @property {BasCollection[]} uiAudioSources Audio sources
 * @property {BasCollection[]} uiKNXPresets
 * @property {number} numBase
 * Number of base streams (Players, Externals, Bluetooths)
 * @property {number} numPlayers Number of Players
 * @property {number} numSourcesWithDefaultRooms Number of sources with default
 * rooms
 * @property {Object} events
 * @property {Object<string, number>} events.all
 * @property {Object<string, Object<string, number>>} events.sources
 * @property {Object<string, boolean>} css
 */

angular
  .module('basalteApp')
  .constant('BAS_SOURCES', {
    TRANS_ID_STREAMS: 'streams',
    TRANS_ID_EXTERNAL: 'external',
    TRANS_ID_BLUETOOTH: 'bluetooth',
    TRANS_ID_SOURCES: 'sources',
    TRANS_ID_TV: 'tv',
    EVT_SOURCES_UPDATED: 'evtSourcesUpdated',
    EVT_BARP_CONNECTED: 'evtSourcesBarpConnected',
    T_EVT_SOURCES: 'sourceEventSources',
    T_EVT_AUDIO_ALERTS: 'sourceEventAudioAlerts',

    CSS_KNX_PRESETS_LOADING: 'bas-sources-knx-presets-loaded',
    CSS_KNX_PRESETS_HAS: 'bas-sources-knx-presets-has',

    COL_ID_STREAMS: 'colIdSourcesStreams',
    COL_ID_STREAMS_ACTIVE: 'colIdSourcesStreamsActive',
    COL_ID_STREAMS_KNX_PRESETS: 'colIdSourcesStreamsKNXPresets',
    COL_ID_PLAYERS: 'colIdSourcesPlayers',
    COL_ID_SPOTIFY: 'colIdSourcesSpotify',
    COL_ID_SPOTIFY_WEB: 'colIdSourcesSpotifyWeb',
    COL_ID_EXTERNALS: 'colIdSourcesExternals',
    COL_ID_EXTERNALS_ACTIVE: 'colIdSourcesExternalsActive',
    COL_ID_BLUETOOTHS: 'colIdSourcesBluetooths',
    COL_ID_BLUETOOTHS_ACTIVE: 'colIdSourcesBluetoothsActive',
    COL_ID_VIDEO_SOURCES: 'colIdSourcesVideo',
    COL_ID_AUDIO_SOURCES: 'colIdSourcesAudio',
    COL_ID_SPOTIFY_AUDIO_SOURCES: 'colIdSourcesSpotifyAudio',

    KEY_PLAYERS: 'players',
    KEY_AUDIO_SOURCES: 'audioSources',
    KEY_VIDEO_SOURCES: 'videoSources',

    /**
     * @type {BasSources}
     */
    SOURCES: {}
  })
