'use strict'

angular
  .module('basalteApp')
  .controller('basScenesSceneScenesCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'BasSceneHelper',
    basScenesSceneScenesCtrl
  ])

/**
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasSceneHelper} BasSceneHelper
 */
function basScenesSceneScenesCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_ROOMS,
  BasSceneHelper
) {
  var stepScenes = this

  /**
   * @type {BasRooms}
   */
  stepScenes.rooms = BAS_ROOMS.ROOMS

  stepScenes.$uiRouterGlobals = $uiRouterGlobals

  // noinspection JSUnusedGlobalSymbols
  /**
   * Angular UI-router callback
   */
  this.uiOnParamsChanged = _onParamsChanged

  stepScenes.selectScene = selectScene

  function _onParamsChanged () {

    // TODO handle params changed
  }

  /**
   * @param {string} sceneUuid
   */
  function selectScene (sceneUuid) {

    var _step, _room

    _step = BasSceneHelper.getStep($scope, $uiRouterGlobals)

    if (_step && _step.room) {

      _room = stepScenes.rooms.rooms[_step.room]

      if (
        _step.scene &&
        sceneUuid !== _step.scene.scene &&
        _room &&
        _room.scenes &&
        _room.scenes.getSceneCtrlDeviceUuid
      ) {

        _step.setScene(
          sceneUuid,
          _room.scenes.getSceneCtrlDeviceUuid()
        )

      } else {

        _step.setScene('', '')
      }
    }
  }
}
