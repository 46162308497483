'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasCamera', [
    'BAS_API',
    'urlParse',
    'BasUtilities',
    basCameraFactory
  ])

/**
 * @typedef {Object} TBasCameraPluginObject
 * @property {string} uuid
 * @property {string} name
 * @property {string} locationString
 * @property {string} username
 * @property {string} password
 * @property {string} mjpegUrl
 * @property {string} rtspUrl
 */

/**
 * @param BAS_API
 * @param urlParse
 * @param {BasUtilities} BasUtilities
 * @returns BasCamera
 */
function basCameraFactory (
  BAS_API,
  urlParse,
  BasUtilities
) {
  var K_UUID = 'uuid'
  var K_NAME = 'name'
  var K_LOCATION = 'location'
  var K_USER = 'user'
  var K_PASSWORD = 'psw'
  var K_MJPEG_URL = 'mjpegUrl'
  var K_RTSP_URL = 'rtspUrl'
  var K_OPEN_CLOSE = 'openClose'
  var K_LIGHT = 'light'

  /**
   * @constructor
   * @param {CameraDevice} device
   */
  function BasCamera (device) {

    /**
     * @type {?CameraDevice}
     */
    this.device = null

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {number}
     */
    this.location = 0

    /**
     * @type {string}
     */
    this.locationString = ''

    /**
     * @type {string}
     */
    this.username = ''

    /**
     * @type {string}
     */
    this.password = ''

    /**
     * @type {?URL}
     */
    this.mjpeg = null

    /**
     * @type {?URL}
     */
    this.mjpegRemote = null

    /**
     * @type {?URL}
     */
    this.rtsp = null

    /**
     * @type {?URL}
     */
    this.rtspRemote = null

    /**
     * @type {string}
     */
    this.openClose = ''

    /**
     * @type {string}
     */
    this.light = ''

    /**
     * Guaranteed to be a non-empty string
     *
     * @type {string}
     */
    this.uiLocationString = '-'

    this._deviceListeners = []

    this._handleCameraDeviceUpdate = this._onCameraDeviceUpdate.bind(this)

    if (device) this.parseCameraDevice(device)
  }

  /**
   * @returns {string}
   */
  BasCamera.prototype.getLocationString = function () {

    return BasUtilities.getDeviceLocationStr(this.location)
  }

  /**
   * @returns {boolean}
   */
  BasCamera.prototype.hasRtsp = function () {

    return !!(this.rtsp && BasUtil.isNEString(this.rtsp.toString()))
  }

  /**
   * @returns {boolean}
   */
  BasCamera.prototype.hasMjpeg = function () {

    return !!(this.mjpeg && BasUtil.isNEString(this.mjpeg.toString()))
  }

  /**
   * @private
   * @param {boolean} mjpeg MJPEG or RTSP
   * @param {boolean} auth Include auth info in URL
   * @param {boolean} remote Use remote or local url
   * @returns {string}
   */
  BasCamera.prototype._getStreamUrl = function (
    mjpeg,
    auth,
    remote
  ) {
    if (remote) {

      if (mjpeg) {

        if (this.mjpegRemote) {

          return auth
            ? this.mjpegRemote.toString()
            : this.mjpegRemote.href
        }

      } else if (this.rtspRemote) {

        return auth
          ? this.rtspRemote.toString()
          : this.rtspRemote.href
      }

    } else {

      if (mjpeg) {

        if (this.mjpeg) {

          return auth
            ? this.mjpeg.toString()
            : this.mjpeg.href
        }

      } else if (this.rtsp) {

        return auth
          ? this.rtsp.toString()
          : this.rtsp.href
      }
    }

    return ''
  }

  /**
   * @param {CameraDevice} cameraDevice
   */
  BasCamera.prototype.parseCameraDevice = function (cameraDevice) {

    if (cameraDevice) {

      this.device = cameraDevice
      this.uuid = cameraDevice.uuid

      // HACK: only setup device listeners when this.device is an EventEmitter
      if (this.device.on) {
        this.setDeviceListeners()
      }

      this.syncDevice()
    }
  }

  /**
   * @param {CameraDevice} [device]
   */
  BasCamera.prototype.syncDevice = function (device) {

    var cameraDevice = device || this.device

    if (cameraDevice) {

      this.name = cameraDevice.name
      this.location = cameraDevice.location
      this._setLocationString(this.getLocationString())
      this.username = cameraDevice.username
      this.password = cameraDevice.password

      if (cameraDevice.mjpeg) {

        this.mjpeg = urlParse(cameraDevice.mjpeg, true)

        if (cameraDevice.username && cameraDevice.password) {

          this.mjpeg.username = this.username
          this.mjpeg.password = this.password
        }
      }

      if (cameraDevice.mjpegRemote) {

        this.mjpegRemote = urlParse(cameraDevice.mjpegRemote, true)

        if (cameraDevice.username && cameraDevice.password) {

          this.mjpegRemote.username = this.username
          this.mjpegRemote.password = this.password
        }
      } else {

        this.mjpegRemote = this.mjpeg
      }

      if (cameraDevice.rtsp) {

        this.rtsp = urlParse(cameraDevice.rtsp, true)

        if (cameraDevice.username && cameraDevice.password) {

          this.rtsp.username = this.username
          this.rtsp.password = this.password
        }
      }

      if (cameraDevice.rtspRemote) {

        this.rtspRemote = urlParse(cameraDevice.rtspRemote, true)

        if (cameraDevice.username && cameraDevice.password) {

          this.rtspRemote.username = this.username
          this.rtspRemote.password = this.password
        }
      } else {

        this.rtspRemote = this.rtsp
      }

      this.openClose = cameraDevice.openClose
      this.light = cameraDevice.light
    }
  }

  /**
   * Creates plugin compatible object of this Camera instance.
   *
   * @param {boolean} remote
   * @returns {TBasCameraPluginObject}
   */
  BasCamera.prototype.getPluginObj = function (remote) {

    var result

    result = {}

    result[K_UUID] = this.uuid
    result[K_NAME] = this.name
    result[K_LOCATION] = this.locationString
    result[K_USER] = this.username
    result[K_PASSWORD] = this.password
    result[K_MJPEG_URL] = this._getStreamUrl(
      true,
      false,
      remote
    )
    result[K_RTSP_URL] = this._getStreamUrl(
      false,
      true,
      remote
    )
    result[K_OPEN_CLOSE] = this.openClose
    result[K_LIGHT] = this.light

    return result
  }

  /**
   * @private
   * @param {string} value
   */
  BasCamera.prototype._setLocationString = function (value) {

    this.locationString = BasUtil.isNEString(value) ? value : ''
    this.uiLocationString = BasUtil.isNEString(this.locationString)
      ? this.locationString
      : '-'
  }

  BasCamera.prototype.setDeviceListeners = function () {

    this._clearDeviceListeners()

    if (this.device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this.device,
        BAS_API.CameraDevice.EVT_ATTRIBUTES_CHANGED,
        this._handleCameraDeviceUpdate
      ))
    }
  }

  BasCamera.prototype._onCameraDeviceUpdate = function () {

    this.syncDevice()
  }

  /**
   * Clears the API device listeners
   *
   * @private
   */
  BasCamera.prototype._clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  /**
   * Update translation
   */
  BasCamera.prototype.updateTranslation = function () {

    this._setLocationString(this.getLocationString())
  }

  /**
   * Clears the device reference
   */
  BasCamera.prototype.clear = function clear () {

    this._clearDeviceListeners()
    this.device = null
  }

  return BasCamera
}
