'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('energyCtrl', [
    '$rootScope',
    '$scope',
    'BAS_ENERGY',
    'STATES',
    'BAS_MONITOR',
    'BasState',
    'BasEnergy',
    energyCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_ENERGY} BAS_ENERGY
 * @param {STATES} STATES
 * @param {BAS_MONITOR} BAS_MONITOR
 * @param {BasState} BasState
 * @param {BasEnergy} BasEnergy
 */
function energyCtrl (
  $rootScope,
  $scope,
  BAS_ENERGY,
  STATES,
  BAS_MONITOR,
  BasState,
  BasEnergy
) {
  var energy = this

  var _listeners = []

  energy.BAS_MONITOR = BAS_MONITOR

  /**
   * @type {TBasEnergyState}
   */
  energy.basEnergyState = BasEnergy.get()

  energy.currentFilter = ''

  energy.types = []

  energy.selectType = selectType
  energy.goDetail = goDetail

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ENERGY.EVT_UI_UPDATE,
      _onEnergyUiUpdate
    ))

    _retrieveUsageForCurrentMeters()
  }

  /**
   * @param {string} type
   */
  function selectType (type) {

    BasEnergy.selectType(type)

    _retrieveUsageForCurrentMeters()
  }

  function goDetail (uuid) {
    var dev

    dev = energy.basEnergyState.energyMeters[uuid]

    if (dev) {

      BasState.go(
        STATES.ENERGY_DETAIL,
        {
          energydeviceid: uuid
        }
      )
    }
  }

  function _retrieveUsageForCurrentMeters () {

    var length, i, uuid, energyMeter

    length = energy.basEnergyState.uiEnergyMetersCurrent.length
    for (i = 0; i < length; i++) {

      uuid = energy.basEnergyState.uiEnergyMetersCurrent[i]
      energyMeter = energy.basEnergyState.energyMeters[uuid]

      if (energyMeter && energyMeter.retrieveLast7Days) {

        energyMeter.retrieveLast7Days().catch(_empty)
      }
    }
  }

  function _onEnergyUiUpdate () {

    _retrieveUsageForCurrentMeters()

    $scope.$applyAsync()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }

  function _empty () {}
}
