'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Capabilities = require('./capabilities')

/**
 * @typedef {Object} TJob
 * @property {string} uuid
 * @property {Object} [capabilities]
 * @property {string} [capabilities.remove]
 * @property {string} [capabilities.enable]
 * @property {string} [capabilities.scene]
 * @property {string} [capabilities.time]
 * @property {boolean} enabled
 * @property {string} scene UUID
 * @property {string} type
 * @property {number} [hour]
 * @property {number} [min] Minutes
 * @property {number} [offset] seconds
 * @property {boolean} [sunday]
 * @property {boolean} [monday]
 * @property {boolean} [tuesday]
 * @property {boolean} [wednesday]
 * @property {boolean} [thursday]
 * @property {boolean} [friday]
 * @property {boolean} [saturday]
 * @property {number} [datetime]
 */

/**
 * @constructor
 * @mixes Capabilities.mixin
 * @param {TJob} job
 * @param {SceneCtrlDevice} ctrl
 * @since 2.1.0
 */
function Job (job, ctrl) {

  /**
   * @private
   * @type {SceneCtrlDevice}
   */
  this._ctrl = ctrl

  /**
   * @type {string}
   */
  this._uuid = BasUtil.isNEString(job[P.UUID])
    ? job[P.UUID]
    : ''

  /**
   * @type {number}
   */
  this._order = 0

  /**
   * @type {Object}
   */
  this[CONSTANTS.CAPABILITIES] = new Capabilities(job[P.CAPABILITIES])

  /**
   * @type {boolean}
   */
  this.enabled = false

  /**
   * @type {string}
   */
  this.scene = ''

  this._type = Job.DATETIME

  /**
   * @type {number}
   */
  this.hour = 0

  /**
   * @type {number}
   */
  this.minutes = 0

  /**
   * @type {boolean}
   */
  this.sunday = false

  /**
   * @type {boolean}
   */
  this.monday = false

  /**
   * @type {boolean}
   */
  this.tuesday = false

  /**
   * @type {boolean}
   */
  this.wednesday = false

  /**
   * @type {boolean}
   */
  this.thursday = false

  /**
   * @type {boolean}
   */
  this.friday = false

  /**
   * @type {boolean}
   */
  this.saturday = false

  /**
   * Minutes to offset sunrise/set
   *
   * @type {number}
   */
  this.offset = 0

  /**
   * Seconds since january 1970 00:00:00
   *
   * @type {number}
   */
  this.datetime = 0

  this.parse(
    job,
    {
      emit: false
    }
  )
}

BasUtil.mergeObjects(Job.prototype, Capabilities.mixin)

/**
 * @constant {string}
 */
Job.T_REPEATED = P.REPEATED

/**
 * @constant {string}
 */
Job.T_DATETIME = P.DATETIME

/**
 * @constant {string}
 */
Job.T_SUNRISE = P.SUNRISE

/**
 * @constant {string}
 */
Job.T_SUNSET = P.SUNSET

/**
 * @constant {string}
 */
Job.C_ENABLE = P.ENABLE

/**
 * @constant {string}
 */
Job.C_REMOVE = P.REMOVE

/**
 * @constant {string}
 */
Job.C_SCENE = P.SCENE

/**
 * @constant {string}
 */
Job.C_TIME = P.TIME

/**
 * @param {string} type
 * @returns {boolean}
 */
Job.isValidType = function (type) {
  return (
    type === Job.T_REPEATED ||
    type === Job.T_SUNRISE ||
    type === Job.T_SUNSET ||
    type === Job.T_DATETIME
  )
}

/**
 * @name Job#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Job.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name Job#order
 * @type {number}
 * @readonly
 */
Object.defineProperty(Job.prototype, 'order', {
  get: function () {
    return this._order
  }
})

/**
 * @name Job#type
 * @type {string}
 * @readonly
 */
Object.defineProperty(Job.prototype, 'type', {
  get: function () {
    return this._type
  }
})

/**
 * @param {TJob} job
 * @param {TDeviceParseOptions} [options]
 */
Job.prototype.parse = function (job, options) {

  var currentState, newState, emit

  emit = true

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  currentState = this._getState()

  if (BasUtil.isBool(job[P.ENABLED])) {

    this.enabled = job[P.ENABLED]
  }

  if (BasUtil.isString(job[P.SCENE])) {

    this.scene = job[P.SCENE]
  }

  if (Job.isValidType(job[P.TYPE])) {

    this._type = job[P.TYPE]
  }

  if (BasUtil.isPNumber(job[P.HOUR], true)) {

    this.hour = job[P.HOUR]
  }

  if (BasUtil.isPNumber(job[P.MIN], true)) {

    this.minutes = job[P.MIN]
  }

  if (BasUtil.isVNumber(job[P.OFFSET])) {

    this.offset = Math.round(job[P.OFFSET] / 60)
  }

  if (BasUtil.isBool(job[P.SUNDAY])) {

    this.sunday = job[P.SUNDAY]
  }

  if (BasUtil.isBool(job[P.MONDAY])) {

    this.monday = job[P.MONDAY]
  }

  if (BasUtil.isBool(job[P.TUESDAY])) {

    this.tuesday = job[P.TUESDAY]
  }

  if (BasUtil.isBool(job[P.WEDNESDAY])) {

    this.wednesday = job[P.WEDNESDAY]
  }

  if (BasUtil.isBool(job[P.THURSDAY])) {

    this.thursday = job[P.THURSDAY]
  }

  if (BasUtil.isBool(job[P.FRIDAY])) {

    this.friday = job[P.FRIDAY]
  }

  if (BasUtil.isBool(job[P.SATURDAY])) {

    this.saturday = job[P.SATURDAY]
  }

  if (BasUtil.isPNumber(job[P.DATETIME], true)) {

    this.datetime = job[P.DATETIME]
  }

  this[CONSTANTS.CAPABILITIES].parse(job[P.CAPABILITIES])

  newState = this._getState()

  if (this._ctrl &&
    !BasUtil.isEqualObject(currentState, newState)) {

    if (emit) this._ctrl.emitJobChanged(this)
  }
}

/**
 * Returns milliseconds since January 1 1970 00:00:00
 *
 * @returns {number}
 */
Job.prototype.getDatetime = function () {

  return this.datetime * 1000
}

/**
 * @param {number} order
 */
Job.prototype.setOrder = function (order) {

  if (BasUtil.isPNumber(order, true)) this._order = order
}

/**
 * @param {string} type
 */
Job.prototype.setType = function (type) {

  if (Job.isValidType(type)) this._type = type
}

/**
 * @param {number} time in milliseconds
 */
Job.prototype.setDateTime = function (time) {

  if (BasUtil.isPNumber(time)) this.datetime = Math.floor(time / 1000)
}

/**
 * Returns an object representing the current state of the Job
 *
 * @returns {Object}
 */
Job.prototype.getBasCoreMessage = function () {

  var result

  result = {}
  result[P.UUID] = this._uuid
  result[P.ENABLED] = this.enabled
  result[P.SCENE] = this.scene
  result[P.TYPE] = this._type

  if (this._type === Job.T_REPEATED) {

    result[P.HOUR] = this.hour
    result[P.MIN] = this.minutes
    this._fillInDays(result)

  } else if (this._type === Job.T_DATETIME) {

    result[P.DATETIME] = this.datetime

  } else if (this._type === Job.T_SUNSET ||
    this._type === Job.T_SUNRISE) {

    result[P.OFFSET] = this.offset * 60
    this._fillInDays(result)
  }

  return result
}

/**
 * Get object with current state properties
 *
 * @private
 * @returns {Object}
 */
Job.prototype._getState = function () {

  var result

  result = {}

  result[P.ENABLED] = this.enabled
  result[P.SCENE] = this.scene
  result[P.TYPE] = this._type
  result[P.HOUR] = this.hour
  result[P.MIN] = this.minutes
  result[P.OFFSET] = this.offset
  this._fillInDays(result)
  result[P.DATETIME] = this.datetime

  return result
}

/**
 * Fill object with day states
 *
 * @private
 * @param {Object} obj
 */
Job.prototype._fillInDays = function (obj) {

  if (BasUtil.isObject(obj)) {

    obj[P.SUNDAY] = this.sunday
    obj[P.MONDAY] = this.monday
    obj[P.TUESDAY] = this.tuesday
    obj[P.WEDNESDAY] = this.wednesday
    obj[P.THURSDAY] = this.thursday
    obj[P.FRIDAY] = this.friday
    obj[P.SATURDAY] = this.saturday
  }
}

module.exports = Job
