'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

/**
 * Live class which holds Basalte Live information
 *
 * @constructor
 * @extends EventEmitter
 * @param {BasCore} basCore
 * @since 3.0.0
 */
function Live (basCore) {

  EventEmitter.call(this)

  /**
   * @private
   * @type {?BasCore}
   */
  this._basCore = basCore

  /**
   * @private
   * @type {boolean}
   */
  this._available = false

  /**
   * @private
   * @type {string}
   */
  this._ownerId = ''

  /**
   * @private
   * @type {string}
   */
  this._ownerEmail = ''

  /**
   * @private
   * @type {string}
   */
  this._projectId = ''

  /**
   * @private
   * @type {boolean}
   */
  this._dirty = true

  this._handleLiveResponse = this._onLiveResponse.bind(this)
}

Live.prototype = Object.create(EventEmitter.prototype)
Live.prototype.constructor = Live

/**
 * @event Live#EVT_LIVE_INFO_UPDATED
 */

/**
 * @constant {string}
 */
Live.EVT_LIVE_INFO_UPDATED = 'evtLiveInfoUpdated'

/**
 * @name Live#available
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Live.prototype, 'available', {
  get: function () {
    return this._available
  }
})

/**
 * @name Live#ownerId
 * @type {string}
 * @readonly
 */
Object.defineProperty(Live.prototype, 'ownerId', {
  get: function () {
    return this._ownerId
  }
})

/**
 * @name Live#ownerEmail
 * @type {string}
 * @readonly
 */
Object.defineProperty(Live.prototype, 'ownerEmail', {
  get: function () {
    return this._ownerEmail
  }
})

/**
 * @name Live#projectId
 * @type {string}
 * @readonly
 */
Object.defineProperty(Live.prototype, 'projectId', {
  get: function () {
    return this._projectId
  }
})

/**
 * @name Live#dirty
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Live.prototype, 'dirty', {
  get: function () {
    return this._dirty
  }
})

/**
 * @param {Object} obj
 */
Live.prototype.parse = function (obj) {

  var _live, _value

  if (BasUtil.isObject(obj) &&
    BasUtil.isObject(obj[P.LIVE])) {

    _live = obj[P.LIVE]

    this._available = !_live[P.ERROR]

    // Owner
    _value = _live[P.OWNER]
    if (BasUtil.isString(_value)) this._ownerId = _value

    // Owner email
    _value = _live[P.OWNER_EMAIL]
    if (BasUtil.isString(_value)) this._ownerEmail = _value

    // Project UUID
    _value = _live[P.PROJECT_UUID]
    if (BasUtil.isString(_value)) {

      this._projectId = _value
      if (this._basCore) this._basCore.setProjectId(this._projectId)
    }

    this._dirty = false

    this.emit(Live.EVT_LIVE_INFO_UPDATED)
  }
}

/**
 * @param {string} linkRequest
 * @returns {Promise<Live>}
 */
Live.prototype.link = function (linkRequest) {

  var data

  data = this._getLiveMessage()
  data[P.LIVE][P.LINK_REQUEST] = linkRequest

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_LONGER)
    .then(this._handleLiveResponse)
}

/**
 * @private
 * @param {Object} result
 * @returns {Live}
 */
Live.prototype._onLiveResponse = function (result) {

  this.parse(result)

  return this
}

/**
 * @private
 * @returns {Object}
 */
Live.prototype._getLiveMessage = function () {

  var result

  result = {}
  result[P.LIVE] = {}

  return result
}

Live.prototype.reset = function () {

  this._available = false
  this._ownerId = ''
  this._ownerEmail = ''
  this._projectId = ''

  this._dirty = true
}

Live.prototype.destroy = function () {

  this._basCore = null
  this._available = false
  this._dirty = true
}

module.exports = Live
