'use strict'

import BasError from './baserror'

angular.module('basError', [])
  .factory('BasError', basErrorFactory)

function basErrorFactory () {

  return BasError
}
