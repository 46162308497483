'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomWeatherStations', [
    'CurrentBasCore',
    'BasWeatherStation',
    basRoomWeatherStationsFactory
  ])

/**
 * @param {CurrentBasCore} CurrentBasCore
 * @param BasWeatherStation
 * @returns BasRoomWeatherStations
 */
function basRoomWeatherStationsFactory (
  CurrentBasCore,
  BasWeatherStation
) {
  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomWeatherStations (basRoom) {

    /**
     * @type {BasWeatherStation[]}
     */
    this.weatherStations = []

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomWeatherStations.hasWeatherStations = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.weatherStations)
    )
  }

  /**
   * Parse the raw room from the parent BasRoom
   */
  BasRoomWeatherStations.prototype.parseRoom = function parseRoom () {

    if (BasRoomWeatherStations.hasWeatherStations(this._basRoom)) {

      this.syncWeatherStations()
    }
  }

  BasRoomWeatherStations.prototype.syncWeatherStations = function () {

    var length, i, uuid, device, weatherStation

    this.resetWeatherStations()

    if (BasRoomWeatherStations.hasWeatherStations(this._basRoom)) {

      length = this._basRoom.room.weatherStations.length
      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.weatherStations[i]
        device = CurrentBasCore.getDevice(uuid)

        if (device) {

          weatherStation = new BasWeatherStation(device, this)

          this.weatherStations.push(weatherStation)
        }
      }
    }
  }

  /**
   * Clears all weather stations
   */
  BasRoomWeatherStations.prototype.clearWeatherStations = function () {

    var i, length

    length = this.weatherStations.length
    for (i = 0; i < length; i++) {

      if (this.weatherStations[i]) this.weatherStations[i].clear()
    }
  }

  BasRoomWeatherStations.prototype.suspendWeatherStations = function () {

    var i, length

    length = this.weatherStations.length
    for (i = 0; i < length; i++) {

      if (this.weatherStations[i]) this.weatherStations[i].suspend()
    }
  }

  /**
   * Clears the weather stations, removing them
   */
  BasRoomWeatherStations.prototype.resetWeatherStations = function () {

    this.clearWeatherStations()

    this.weatherStations = []
  }

  BasRoomWeatherStations.prototype.suspend = function suspend () {

    this.suspendWeatherStations()
  }

  BasRoomWeatherStations.prototype.destroy = function destroy () {

    this.resetWeatherStations()
  }

  return BasRoomWeatherStations
}
