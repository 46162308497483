'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasCoreClientDefaultState', [
    '$rootScope',
    '$uiRouterGlobals',
    'BAS_APP',
    'BAS_CURRENT_CORE',
    'BAS_PREFERENCES',
    'STATES',
    'BAS_STATE',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_CORE_CLIENT',
    'CurrentBasCore',
    'CurrentRoom',
    'BasPreferences',
    'BasCoreClientHelper',
    'BasState',
    'BasAppDevice',
    BasCoreClientDefaultState
  ])

/**
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {BAS_APP} BAS_APP
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_CORE_CLIENT} BAS_CORE_CLIENT
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 * @param {BasPreferences} BasPreferences
 * @param {BasCoreClientHelper} BasCoreClientHelper
 * @param {BasState} BasState
 * @param {BasAppDevice} BasAppDevice
 */
function BasCoreClientDefaultState (
  $rootScope,
  $uiRouterGlobals,
  BAS_APP,
  BAS_CURRENT_CORE,
  BAS_PREFERENCES,
  STATES,
  BAS_STATE,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_CORE_CLIENT,
  CurrentBasCore,
  CurrentRoom,
  BasPreferences,
  BasCoreClientHelper,
  BasState,
  BasAppDevice
) {

  var allowPreferredStateChange = false
  var allowPreferredStateChangeTimeoutId = 0

  var basState = BasState.get()

  init()

  function init () {

    if (BasAppDevice.isCoreClient() && !BasAppDevice.isLisa()) {

      $rootScope.$on(
        BAS_CORE_CLIENT.EVT_CORE_CLIENT_MAC_ADDRESS,
        _onMacUpdated
      )

      $rootScope.$on(
        BAS_APP.EVT_PAUSE,
        _onPause
      )

      $rootScope.$on(
        BAS_APP.EVT_RESUME,
        _onResume
      )

      $rootScope.$on(
        BAS_ROOMS.EVT_ROOMS_UPDATED,
        _onRoomsUpdated
      )

      $rootScope.$on(
        BAS_ROOM.EVT_CORE_CLIENTS_UPDATED,
        _onCoreClientsUpdated
      )

      $rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
        _onCoreCoreConnected
      )
    }
  }

  function _onMacUpdated () {

    _goCCDPreferredState()
  }

  function _onPause () {

    _clearAllowPreferredStateChangeTimeout()
    allowPreferredStateChange = false

    if (basState.current.MAIN) _goCCDPreferredState(true)
  }

  function _onResume () {

    _clearAllowPreferredStateChangeTimeout()
    allowPreferredStateChange = true

    allowPreferredStateChangeTimeoutId = setTimeout(
      _onResumeTimeout,
      BAS_CORE_CLIENT.ALLOW_PREFERRED_STATE_TIMEOUT_MS
    )
  }

  function _onResumeTimeout () {

    _clearAllowPreferredStateChangeTimeout()
    allowPreferredStateChange = false
  }

  function _onRoomsUpdated (_evt, areas) {

    if (areas === BAS_ROOMS.P_ZONES || areas === BAS_ROOMS.P_ROOMS) {

      _goCCDPreferredState()
    }
  }

  function _onCoreClientsUpdated () {

    _goCCDPreferredState()
  }

  /**
   * @param {Object} _event
   * @param {BasCoreContainer} _basCoreContainer
   * @param {boolean} isConnected
   */
  function _onCoreCoreConnected (
    _event,
    _basCoreContainer,
    isConnected
  ) {
    if (!isConnected) {

      allowPreferredStateChange = false
    }
  }

  function _goCCDPreferredState (force) {

    const _allowPreferredStateChange =
      BasUtil.isBool(force)
        ? force
        : allowPreferredStateChange

    if (_allowPreferredStateChange) {

      const startupViewSetting = BasPreferences.getDefaultView()

      switch (startupViewSetting) {

        case BAS_PREFERENCES.VIEW_HOME:

          _goHome()

          break

        case BAS_PREFERENCES.VIEW_CCD_ROOM:

          _goCCDRoom()

          break
      }
    }
  }

  function _goHome () {

    if ($uiRouterGlobals.transition) {

      // Ongoing transition

    } else {

      if (CurrentBasCore.hasHomePage()) {

        _goTo(
          STATES.HOME,
          {
            view: BAS_STATE.S_HOME_VIEW_DASHBOARD
          },
          {
            supercede: false
          }
        )

      } else {

        // Fallback
        _goTo(
          STATES.ROOMS,
          undefined,
          {
            supercede: false
          }
        )
      }
    }
  }

  /**
   * Only set current room if core client device info is found
   *
   * @private
   */
  function _goCCDRoom () {

    var basCoreClientInfo, room

    if ($uiRouterGlobals.transition) {

      // Ongoing transition

    } else {

      basCoreClientInfo = BasCoreClientHelper.getBasCoreClientInfo()

      room = CurrentRoom.getRoom()

      if (basCoreClientInfo && basCoreClientInfo.room) {

        // Check if room needs to be set
        if (!room || room.id !== basCoreClientInfo.room.id) {

          CurrentRoom.set(basCoreClientInfo.room)
          room = CurrentRoom.getRoom()
        }
      }

      if (BAS_CORE_CLIENT.STATE.mac && room) {

        _goTo(
          STATES.ROOM,
          undefined,
          {
            supercede: false
          }
        )
      }
    }
  }

  /**
   * @private
   * @param {string} state
   * @param {Object} [params]
   * @param {Object} [options]
   * @returns {Promise}
   */
  function _goTo (state, params, options) {

    allowPreferredStateChange = false

    return CurrentRoom.go(state, params, options)
  }

  function _clearAllowPreferredStateChangeTimeout () {

    clearTimeout(allowPreferredStateChangeTimeoutId)
    allowPreferredStateChangeTimeoutId = 0
  }
}
