'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasTimerPoint', [
    'BAS_API',
    'BAS_INTL',
    'BasSchedulerPoint',
    'BasIntl',
    basTimerPointFactory
  ])

/**
 * @param BAS_API
 * @param {BAS_INTL} BAS_INTL
 * @param BasSchedulerPoint
 * @param {BasIntl} BasIntl
 * @returns BasTimerPoint
 */
function basTimerPointFactory (
  BAS_API,
  BAS_INTL,
  BasSchedulerPoint,
  BasIntl
) {
  /**
   * @constructor
   * @extends BasSchedulerPoint
   * @param {TTimerPoint} [timerPoint]
   * @param {string} [type]
   */
  function BasTimerPoint (timerPoint, type) {

    BasSchedulerPoint.call(this)

    /**
     * Number of seconds
     *
     * @type {number}
     */
    this.time = 0

    /**
     * @type {string}
     */
    this.type = type || ''

    /**
     * @type {(number|boolean)}
     */
    this.actualValue = false

    /**
     * @type {?TTimerPoint}
     */
    this.timerPoint = timerPoint || null

    this.parse(this.timerPoint, this.type)
  }

  BasTimerPoint.prototype = Object.create(BasSchedulerPoint.prototype)
  BasTimerPoint.prototype.constructor = BasTimerPoint

  /**
   * @param {number} [x]
   * @param {number} [step]
   * @returns {string}
   */
  BasTimerPoint.prototype.getUiValueXFor = function (
    x,
    step
  ) {
    var _x, cleanValue, date

    _x = BasUtil.isVNumber(x) ? x : this.x

    cleanValue = BasUtil.isVNumber(step)
      ? Math.round(_x / step) * step
      : Math.round(_x)
    date = new Date(cleanValue * 1000)

    return BasIntl.dateToString(date, BAS_INTL.LOCALE_OPTION_SHORT, 'GMT')
  }

  /**
   * @param {number} y 0 - 1
   * @returns {string}
   */
  BasTimerPoint.prototype.getUiValueYFor = function (y) {

    var cleanValue

    switch (this.type) {
      case BAS_API.Timer.T_ON_OFF:
        return '' + Math.round(y)
      case BAS_API.Timer.T_PERCENTAGE:
        cleanValue = Math.round(y / 0.01) * 0.01
        return '' + Math.round(cleanValue * 100)
    }

    return '-'
  }

  /**
   * @param {number} x
   */
  BasTimerPoint.prototype.setXValue = function (x) {

    this.time = this.x = x
  }

  /**
   * @param {number} y
   */
  BasTimerPoint.prototype.setYValue = function (y) {

    this.y = y

    switch (this.type) {
      case BAS_API.Timer.T_ON_OFF:
        this.actualValue = this.y === 1
        break
      case BAS_API.Timer.T_PERCENTAGE:
        this.actualValue = this.y
        break
    }

    this._syncUiValue()
  }

  /**
   * @param {TTimerPoint} timerPoint
   * @param {string} type
   */
  BasTimerPoint.prototype.parse = function (timerPoint, type) {

    if (BasUtil.isObject(timerPoint)) {

      this.time = timerPoint.time

      if (type) this.type = type
      if (timerPoint) this.timerPoint = timerPoint

      if (this.timerPoint) {

        switch (this.type) {
          case BAS_API.Timer.T_ON_OFF:
            this.actualValue = this.timerPoint.armed === true
            break
          case BAS_API.Timer.T_PERCENTAGE:
            this.actualValue =
              BasUtil.isPNumber(
                this.timerPoint.percentage,
                true
              )
                ? this.timerPoint.percentage
                : 0
            break
        }
      }

      this._syncX()
      this._syncY()
      this._syncUiValue()
    }
  }

  /**
   * @returns {TTimerPoint}
   */
  BasTimerPoint.prototype.getApiTimerPoint = function () {

    var result

    result = {}
    result.time = this.time

    switch (this.type) {
      case BAS_API.Timer.T_ON_OFF:

        result.armed = this.actualValue

        break
      case BAS_API.Timer.T_PERCENTAGE:

        result.percentage = this.actualValue

        break
    }

    return result
  }

  /**
   * @private
   */
  BasTimerPoint.prototype._syncX = function () {

    this.x = this.time
  }

  /**
   * @private
   */
  BasTimerPoint.prototype._syncY = function () {

    if (BasUtil.isBool(this.actualValue)) {

      this.y = this.actualValue ? 1 : 0

    } else if (BasUtil.isVNumber(this.actualValue)) {

      this.y = this.actualValue

    } else {

      this.y = 0
    }
  }

  /**
   * @private
   */
  BasTimerPoint.prototype._syncUiValue = function () {

    switch (this.type) {
      case BAS_API.Timer.T_ON_OFF:

        this.uiValue = this.uiSimpleValue =
          '' + (this.actualValue === true ? 1 : 0)

        break
      case BAS_API.Timer.T_PERCENTAGE:

        this.uiValue = this.uiSimpleValue =
          this.getUiValueYFor(this.actualValue)

        break
    }
  }

  BasTimerPoint.prototype.clone = function () {

    var result

    result = new BasTimerPoint()
    this.copyPropertiesTo(result)

    return result
  }

  /**
   * @param {Object} destination
   */
  BasTimerPoint.prototype.copyPropertiesTo = function (destination) {

    BasSchedulerPoint.prototype.copyPropertiesTo.call(this, destination)

    if (BasUtil.isObject(destination)) {

      destination.time = this.time
      destination.type = this.type
      destination.actualValue = this.actualValue
      destination.timerPoint = BasUtil.copyObject(this.timerPoint)
    }
  }

  return BasTimerPoint
}
