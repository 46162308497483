'use strict'

angular
  .module('basalteApp')
  .constant('BAS_APP', {
    EVT_RESUME: 'evtBasAppResume',
    EVT_PAUSE: 'evtBasAppPause',
    EVT_RESIGN: 'evtBasAppResign',
    EVT_ACTIVE: 'evtBasAppActive',
    EVT_BACK_BUTTON: 'evtBasAppBackButton',
    EVT_NETWORK_ONLINE: 'evtBasAppNetworkOnline',
    EVT_NETWORK_OFFLINE: 'evtBasAppNetworkOffline',
    EVT_NETWORK_CONNECTION_OFFLINE: 'evtBasAppNetworkConnectionOffline',
    EVT_NETWORK_CONNECTION_CHANGED: 'evtBasAppNetworkConnectionChanged'
  })
