'use strict'

/**
 * @constructor
 * @extends Error
 * @param {string} [type]
 * @param {*} [extra]
 * @param {string} [message]
 * @param {string} [fileName]
 * @param {number} [lineNumber]
 */
function BasError (type, extra, message, fileName, lineNumber) {

  var instance

  instance = new Error(message, fileName, lineNumber)

  instance.name = 'BasError'
  instance.basType = typeof type === 'string' ? type : ''
  instance.basExtra = extra
  instance.basMessage = instance.message

  if (instance.basType) {

    instance.message = instance.basType + ': ' + instance.message
  }

  Object.setPrototypeOf(instance, Object.getPrototypeOf(this))

  if (typeof Error.captureStackTrace === 'function') {

    Error.captureStackTrace(instance, BasError)
  }

  return instance
}

BasError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true
  }
})

Object.setPrototypeOf(BasError, Error)

export default BasError
