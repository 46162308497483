'use strict'

import { registerPlugin, Capacitor } from '@capacitor/core'

let BasAppShortcutsPluginCapacitor = null
const BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .service('BasAppShortcuts', [
    '$rootScope',
    '$window',
    'BAS_APP',
    'BAS_CONNECT',
    'BAS_LIVE_ACCOUNT',
    'BasConnectInfo',
    'BasAppDevice',
    'BasCoreConnection',
    'BasLiveAccount',
    'CurrentBasCore',
    BasAppShortcuts
  ])

/**
 * @typedef {Object} TBasShortcutState
 * @property {?TBasSmartConnect} smartConnectTarget
 */
/**
 * Helper service for managing app icon shortcuts to connect to linked homes.
 *
 * @constructor
 * @param $rootScope
 * @param $window
 * @param {BAS_APP} BAS_APP
 * @param {BAS_CONNECT} BAS_CONNECT
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param BasConnectInfo
 * @param {BasAppDevice} BasAppDevice
 * @param {BasCoreConnection} BasCoreConnection
 * @param {BasLiveAccount} BasLiveAccount
 * @param {CurrentBasCore} CurrentBasCore
 */
function BasAppShortcuts (
  $rootScope,
  $window,
  BAS_APP,
  BAS_CONNECT,
  BAS_LIVE_ACCOUNT,
  BasConnectInfo,
  BasAppDevice,
  BasCoreConnection,
  BasLiveAccount,
  CurrentBasCore
) {

  let linkedHomes = []

  /**
   * @type {TBasShortcutState}
   */
  const state = {}
  state.smartConnectTarget = null

  const K_BAS_APP_SHORTCUTS_UUID = 'basAppShortcutsUuid'

  /**
   * @type {TBasLiveAccountState}
   */
  const basLiveAccount = BasLiveAccount.get()

  this.getTarget = getTarget
  this.clearState = clearState

  init()

  function init () {
    const basIconShortcutsPlugin = _getBasAppShortcutsPlugin()
    const basUniversalLinksPlugin = _getBasUniversalLinksPlugin()

    if (!BasAppShortcutsPluginCapacitor && basIconShortcutsPlugin) {
      basIconShortcutsPlugin.start(_onAppShortcutsMessage)

      // TODO: Only use basAppShortcutsPlugin for iOS
      if (BasAppDevice.isIos()) {
        // BasUniversalLinksPlugin is now mainly used when launching our app
        // from an app shortcut
        basUniversalLinksPlugin.init()
        basUniversalLinksPlugin.addCallback(_handleUniversalLink)
      }
    }

    $rootScope.$on(
      BAS_LIVE_ACCOUNT.EVT_PROJECTS_UPDATED,
      _syncShortcuts
    )

    $rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onResume
    )

    function _handleUniversalLink (error, result) {
      if (error) return
      if (result) _onAppShortcutsMessage(result)
    }
  }

  function _onResume () {
    _connectToServerIfNeeded()
  }

  /**
   * @private
   * @returns {?TBasSmartConnect}
   */
  function getTarget () {
    return (
      state.smartConnectTarget &&
      state.smartConnectTarget.preferredProjectId
    )
      ? state.smartConnectTarget
      : null
  }

  function clearState () {
    state.smartConnectTarget = null
  }

  function _syncShortcuts () {
    const basAppShortcutsArray = []

    if (!BasUtil.isEqualArray(linkedHomes, basLiveAccount.uiProjects)) {

      const basIconShortcutsPlugin = _getBasAppShortcutsPlugin()

      if (basIconShortcutsPlugin) {

        linkedHomes = basLiveAccount.uiProjects

        const length = linkedHomes.length
        for (let i = 0; i < length; i++) {

          const onlineProject =
            BasLiveAccount.getOnlineProject(linkedHomes[i])

          if (onlineProject) {
            const linkedHome =
              {
                basAppShortcutsUuid: onlineProject.uuid,
                basAppShortcutsName: onlineProject.name
              }

            basAppShortcutsArray.push(linkedHome)
          }
        }
        _setShortcuts(basAppShortcutsArray)
      }
    }

    if (!basLiveAccount.isLoggedIn) {

      _setShortcuts([])
    }
  }

  function _connectToServerIfNeeded () {
    if (getTarget()) {

      const _projectId = state.smartConnectTarget.preferredProjectId
      const _basServer = CurrentBasCore.getServer()
      // Prevent connecting to the same server
      if (!_basServer?.isCoreConnected() || _basServer?.cid !== _projectId) {
        _startConnection()
      }
    }
  }

  function _startConnection () {

    BasCoreConnection.start(
      state.smartConnectTarget,
      {
        allowOther: false
      },
      {
        noSplashDelay: true
      },
      false
    )

    clearState()
  }

  function _setShortcuts (dataArray) {
    const basIconShortcutsPlugin = _getBasAppShortcutsPlugin()

    if (
      basIconShortcutsPlugin &&
      BasUtil.isFunction(basIconShortcutsPlugin.setShortcuts)
    ) {

      // API is subtly different in the Capacitor version
      if (BasAppShortcutsPluginCapacitor) {
        basIconShortcutsPlugin.setShortcuts({ dataArray })
      } else {
        basIconShortcutsPlugin.setShortcuts(dataArray)
      }
    }
  }

  /**
   * Fired when basShortCut plugin sends messages
   *
   * @private
   * @param {string} message
   */
  function _onAppShortcutsMessage (message) {
    const basAppShortcutsPlugin = _getBasAppShortcutsPlugin()

    if (basAppShortcutsPlugin && message) {

      const parsedJSONData = _getParsedData(message)

      if (parsedJSONData) {

        const _basAppShortcutsUuid = parsedJSONData[K_BAS_APP_SHORTCUTS_UUID]

        if (_basAppShortcutsUuid) {

          state.smartConnectTarget = {
            preferred: BasConnectInfo.build(
              BAS_CONNECT.PREFIX_LIVE_PROJECT + _basAppShortcutsUuid
            ),
            preferredProjectId: _basAppShortcutsUuid
          }
        }
      }
    }
  }

  /**
   * @private
   * @param {string} data
   * @returns {*}
   */
  function _getParsedData (data) {
    let decodedData

    try {
      decodedData = decodeURIComponent(data)
    } catch (e) {
      return null
    }

    if (decodedData) {
      try {
        return JSON.parse(decodedData)
      } catch (e) {
        return null
      }
    }

    return null
  }

  function _getBasAppShortcutsPlugin () {
    // Try the Capacitor plugin first
    if (!BasAppShortcutsPluginCapacitor &&
          Capacitor.isPluginAvailable('BasAppShortcuts')) {
      BasAppShortcutsPluginCapacitor = registerPlugin('BasAppShortcuts')
    }
    if (BasAppShortcutsPluginCapacitor) {
      return BasAppShortcutsPluginCapacitor
    }

    // Otherwise try the cordova plugin
    return (
      $window['basalteCordova'] &&
      $window['basalteCordova']['BasAppShortcuts']
    )
      ? $window['basalteCordova']['BasAppShortcuts']
      : null
  }

  function _getBasUniversalLinksPlugin () {

    return (
      $window['basalteCordova'] &&
      $window['basalteCordova']['BasUniversalLinks']
    )
      ? $window['basalteCordova']['BasUniversalLinks']
      : null
  }
}
