'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basVideo', [])
  .directive('basVideo', basVideoDirective)

function basVideoDirective () {

  return {
    restrict: 'EA',
    template: '<video class="bas-video-element"' +
      ' playsinline' +
      ' autoplay' +
      ' ></video>',
    scope: {
      src: '<',
      mute: '<',
      onplaying: '&'
    },
    controller: [
      '$scope',
      '$element',
      'BasVolume',
      'ICONS',
      controller
    ],
    controllerAs: 'video'
  }

  /**
   * @param $scope
   * @param $element
   * @param {BasVolume} BasVolume
   * @param {ICONS} ICONS
   */
  function controller (
    $scope,
    $element,
    BasVolume,
    ICONS
  ) {

    var basVideo = this

    /**
     * @type {TBasVolumeState}
     */
    var volumeState = BasVolume.get()

    var videoElement

    var CSS_VIDEO_ELEMENT = 'bas-video-element'

    var _listeners = []

    basVideo.$postLink = _onPostLink
    basVideo.$onDestroy = _onDestroy
    basVideo.$doCheck = _doCheck
    basVideo.$onChanges = _onChanges

    function _onPostLink () {

      init()
    }

    function init () {

      getDOMElements()
    }

    function _doCheck () {

      setMute()
      setSrcObject()
    }

    function _onChanges () {

      setListeners()
    }

    function setListeners () {

      _clearListeners()

      if (videoElement && $scope.onplaying) {

        _listeners.push(BasUtil.setDOMListener(
          videoElement,
          'playing',
          $scope.onplaying
        ))
      }
    }

    /**
     * Set src object of video element
     * Checks if video element is not already set by the same srcObject,
     * otherwise the video element will be momentarily interrupted.
     * This will also play the video
     */
    function setSrcObject () {

      var newValue = $scope.src

      if (videoElement &&
        videoElement.srcObject !== newValue) {

        videoElement.srcObject = newValue
        videoElement.play()
      }
    }

    function setMute () {

      var newValue = $scope.mute

      if (BasUtil.isBool(newValue) &&
        videoElement) {

        videoElement.muted = newValue
      }
    }

    function setVolume () {

      if (videoElement) {

        videoElement.volume = volumeState.browserMusicVolume / 100
      }
    }

    function setPoster () {

      if (videoElement) {

        videoElement.poster = ICONS.black_video_poster
      }
    }

    function getDOMElements () {

      videoElement = $element[0]
        .getElementsByClassName(CSS_VIDEO_ELEMENT)[0]

      setListeners()
      setPoster()
      setMute()
      setSrcObject()
      setVolume()
    }

    function _clearDOMElements () {

      videoElement = null
    }

    function _clearListeners () {

      BasUtil.executeArray(_listeners)

      _listeners = []
    }

    function _onDestroy () {

      _cleanupVideoElement(videoElement)
      _clearListeners()
      _clearDOMElements()
    }

    function _cleanupVideoElement (element) {

      var tracks, i, length

      if (element &&
        element.srcObject) {

        element.pause()

        tracks = element.srcObject.getTracks()
        length = tracks.length

        for (i = 0; i < length; i++) {
          tracks[i].stop()
        }
      }
    }
  }
}
