'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('deviceLisaStartPage', [
    'BAS_HTML',
    deviceLisaStartPage
  ])

function deviceLisaStartPage (BAS_HTML) {

  return {
    restrict: 'AE',
    template: BAS_HTML.lisaStartPageSettings,
    controller: [
      '$rootScope',
      '$scope',
      'BAS_PREFERENCES',
      'BAS_INPUT_MODAL',
      'BasPreferences',
      'BasInputModal',
      'BasUtilities',
      controller
    ],
    controllerAs: 'lisaStartPage'
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_PREFERENCES} BAS_PREFERENCES
   * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
   * @param {BasPreferences} BasPreferences
   * @param {BasInputModal} BasInputModal
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    $scope,
    BAS_PREFERENCES,
    BAS_INPUT_MODAL,
    BasPreferences,
    BasInputModal,
    BasUtilities
  ) {

    var lisaStartPage = this

    var _listeners = []

    /**
     * @type {TBasModalInputSelectOption[]}
     */
    lisaStartPage.STARTPAGE_OPTIONS = [
      {
        value: BAS_PREFERENCES.START_PAGE_WIDGET_TIME,
        text: 'time',
        translate: true
      },
      {
        value: BAS_PREFERENCES.START_PAGE_WIDGET_WEATHER,
        text: 'start_weather',
        translate: true
      },
      {
        value: BAS_PREFERENCES.START_PAGE_WIDGET_TIME_DATE,
        text: 'start_time_date',
        translate: true
      }
    ]

    /**
     * @type {number}
     */
    lisaStartPage.startPage = BasPreferences.getStartPageWidget()

    /**
     * @type {string}
     */
    lisaStartPage.uiStartPage = '-'

    lisaStartPage.showEditStartPage = showEditStartPage

    init()

    function init () {

      _syncUiStartPage()

      _listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        _syncUiStartPage
      ))

      $scope.$on('$destroy', _onDestroy)
    }

    function updateStartPage () {

      BasPreferences.setStartPageWidget(lisaStartPage.startPage)
    }

    function showEditStartPage () {

      BasInputModal.show(BAS_INPUT_MODAL.T_MULTI_SCREEN, {
        title: 'start_page',
        inputs: [
          {
            type: BAS_INPUT_MODAL.T_SELECT,
            options: lisaStartPage.STARTPAGE_OPTIONS,
            initialValue: lisaStartPage.startPage
          }
        ],
        autoAdvance: true,
        allowDismiss: true,
        autoSave: true,
        validCheck: BasUtil.isNEArray
      }).then(onModal)

      function onModal (modal) {

        modal.close.then(function (result) {

          if (BasUtil.isNEArray(result)) {

            lisaStartPage.startPage = result[0]
            updateStartPage()
            _syncUiStartPage()
          }
        })
      }
    }

    function _syncUiStartPage () {

      lisaStartPage.uiStartPage = _getUiForSetting(lisaStartPage.startPage)
    }

    function _getUiForSetting (preference) {

      switch (preference) {
        case BAS_PREFERENCES.START_PAGE_WIDGET_TIME:
          return BasUtilities.translate('time')
        case BAS_PREFERENCES.START_PAGE_WIDGET_TIME_DATE:
          return BasUtilities.translate('start_time_date')
        case BAS_PREFERENCES.START_PAGE_WIDGET_WEATHER:
          return BasUtilities.translate('start_weather')
        default:
          return '-'
      }
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []
    }
  }
}
