'use strict'

angular
  .module('basalteApp')
  .factory('BasFilter', basFilter)

function basFilter () {

  /**
   * @constructor
   */
  function BasFilter () {

    /**
     * @type {(number|string)}
     */
    this.id = ''

    /**
     * @type {string}
     */
    this.translationId = ''

    /**
     * @type {number}
     */
    this.order = 0
  }

  return BasFilter
}
