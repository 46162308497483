'use strict'

angular
  .module('basalteApp')
  .constant('BAS_KNXPRESETS', {
    T_LOCAL: 'local',
    T_TUNEIN: 'tunein',
    T_DEEZER: 'deezer',
    T_TIDAL: 'tidal',
    T_SPOTIFY: 'spotify',
    ST_PLAYLIST: 'playlist',
    ST_RADIO: 'radio',
    ST_FLOW: 'flow',
    V_SPOTIFY_CONNECT_CLIENT: 'spotifyClient'
  })
