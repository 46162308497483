'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basStep', [
    'BAS_HTML',
    basStepDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basStep
 */
function basStepDirective (BAS_HTML) {

  return {
    restrict: 'A',
    controller: [
      '$scope',
      controller
    ],
    template: BAS_HTML.basStep,
    controllerAs: 'basStep',
    bindToController: {
      basStep: '<',
      onDelete: '&',
      onTimeState: '='
    }
  }

  function controller ($scope) {

    var basStep = this

    var PUSH_INTERVAL = 300
    var STEP_INCREASE_TIME = PUSH_INTERVAL * 10

    var _pushTimerId, _increaseTimerId
    var delay = 0

    basStep.decreaseClick = decreaseClick
    basStep.decreaseStart = decreaseStart
    basStep.decreaseEnd = decreaseEnd
    basStep.increaseClick = increaseClick
    basStep.increaseStart = increaseStart
    basStep.increaseEnd = increaseEnd
    basStep.remove = remove

    basStep.$postLink = _postLink

    function decreaseClick () {

      var _step

      _step = _getStep()

      _clearTimers()

      if (_step && delay > 0) {

        _changeDelay(delay - 1)
        _onChange()
      }
    }

    function decreaseStart () {

      var amount = 1

      _clearTimers()

      _setTimeChange(true)
      _setPushTimer()
      _increaseTimerId = setTimeout(
        _onIncreaseAmount,
        STEP_INCREASE_TIME
      )

      function _setPushTimer () {

        _changeDelay(
          delay - amount >= 0
            ? delay - amount
            : 0
        )

        _pushTimerId = setTimeout(_setPushTimer, PUSH_INTERVAL)

        $scope.$applyAsync()
      }

      function _onIncreaseAmount () {

        amount = 10
      }
    }

    function decreaseEnd () {

      _clearTimers()
      _onChange()
    }

    function increaseClick () {

      var _step

      _step = _getStep()

      _clearTimers()

      if (_step) {

        _changeDelay(delay + 1)
        _onChange()
      }
    }

    function increaseStart () {

      var amount = 1

      _clearTimers()

      _setTimeChange(true)
      _setPushTimer()
      _increaseTimerId = setTimeout(
        _onIncreaseAmount,
        STEP_INCREASE_TIME
      )

      function _setPushTimer () {

        _changeDelay(delay + amount)

        _pushTimerId = setTimeout(_setPushTimer, PUSH_INTERVAL)

        $scope.$applyAsync()
      }

      function _onIncreaseAmount () {

        amount = 10
      }
    }

    function increaseEnd () {

      _clearTimers()
      _onChange()
    }

    function remove () {

      BasUtil.execute(basStep.onDelete)
    }

    function _postLink () {

      _syncWithStep()
      $scope.$watch(_getStepDelay, _onDelayChange)
    }

    function _getStepDelay () {

      var _step = _getStep()

      return _step ? _step.delay : null
    }

    function _onDelayChange () {

      _syncWithStep()
    }

    function _syncWithStep () {

      var _step = _getStep()

      if (_step && !_pushTimerId) {

        delay = _step.delay
      }
    }

    function _clearTimers () {

      clearTimeout(_pushTimerId)
      clearTimeout(_increaseTimerId)
      _setTimeChange(false)

      _pushTimerId = 0
      _increaseTimerId = 0
    }

    function _changeDelay (time) {

      var _step

      // Update internal value

      delay = time

      // Update UI

      _step = _getStep()
      if (_step) _step.setUiStepTiming(delay)
    }

    function _onChange () {

      var step = _getStep()

      if (step) step.setDelay(delay)
    }

    /**
     * @private
     * @param {boolean} bool
     */
    function _setTimeChange (bool) {

      if (BasUtil.isFunction(basStep.onTimeState)) {

        basStep.onTimeState(bool)
      }
    }

    /**
     * @private
     * @returns {?BasStep}
     */
    function _getStep () {

      var _step

      _step = basStep.basStep

      return (BasUtil.isObject(_step) && _step.getApiStep)
        ? _step
        : null
    }
  }
}
