'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('openCloseDevicesCtrl', [
    '$rootScope',
    '$scope',
    'BAS_ROOMS',
    'BAS_OPEN_CLOSE_DEVICES',
    'BAS_OPEN_CLOSE_DEVICE',
    'BasOpenCloseDevices',
    openCloseDevicesCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_OPEN_CLOSE_DEVICES} BAS_OPEN_CLOSE_DEVICES
 * @param {BAS_OPEN_CLOSE_DEVICE} BAS_OPEN_CLOSE_DEVICE
 * @param {BasOpenCloseDevices} BasOpenCloseDevices
 */
function openCloseDevicesCtrl (
  $rootScope,
  $scope,
  BAS_ROOMS,
  BAS_OPEN_CLOSE_DEVICES,
  BAS_OPEN_CLOSE_DEVICE,
  BasOpenCloseDevices
) {
  var openCloseDevices = this
  var _listeners = []

  var _currentUiOpenCloseDevices

  openCloseDevices.editing = false

  /**
   * @type {BAS_ROOMS}
   */
  openCloseDevices.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {TOpenCloseDevicesState}
   */
  openCloseDevices.basOpenCloseDevices = BasOpenCloseDevices.get()

  openCloseDevices.filterControl = filterControl
  openCloseDevices.toggleEdit = toggleEdit
  openCloseDevices.beforeSwipe = beforeSwipe
  openCloseDevices.reorder = reorder

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_OPEN_CLOSE_DEVICES.EVT_OPEN_CLOSE_DEVICES_UPDATED,
      _onOpenCloseDevicesUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_OPEN_CLOSE_DEVICE.EVT_OPEN_CLOSE_DEVICE_UPDATED,
      _onOpenCloseDeviceUpdated
    ))
  }

  function filterControl (filterId) {

    if (openCloseDevices.editing) {
      toggleEdit()
    }

    BasOpenCloseDevices.filterControl(filterId)
  }

  function toggleEdit () {

    openCloseDevices.editing = !openCloseDevices.editing
    BasOpenCloseDevices.setEditing(openCloseDevices.editing)

    if (openCloseDevices.editing) {

      // (shallow) Copy UI open close devices
      _currentUiOpenCloseDevices =
        openCloseDevices.basOpenCloseDevices.uiOpenCloseDevices.slice()

    } else {

      // Check if order is changed
      if (Array.isArray(_currentUiOpenCloseDevices) &&
        !BasUtil.isEqualArray(
          _currentUiOpenCloseDevices,
          openCloseDevices.basOpenCloseDevices.uiOpenCloseDevices
        )) {

        BasOpenCloseDevices.saveOrder()
      }
    }
  }

  function beforeSwipe (event) {

    if (event && event.preventDefault) event.preventDefault()
  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   */
  function reorder (spliceIndex, originalIndex) {

    BasOpenCloseDevices.uiReorder(spliceIndex, originalIndex)
  }

  function _onOpenCloseDevicesUpdated () {

    $scope.$applyAsync()
  }

  function _onOpenCloseDeviceUpdated () {

    $scope.$applyAsync()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
