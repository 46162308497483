'use strict'

var BasUtil = require('@basalte/bas-util')

var Device = require('./device')

var P = require('./parser_constants')

/**
 * @typedef {Object} TThermostatControl
 * @property {string} uuid
 * @property {string} name
 * @property {string} type
 * @property {number} subType
 * @property {Object} capabilities
 * @property {Object} state
 */

/**
 * @constructor
 * @extends Device
 * @param {TThermostatControl} device
 * @param {BasCore} basCore
 */
function ThermostatControl (device, basCore) {

  Device.call(this, device, basCore)

  this._state = BasUtil.isObject(device[P.STATE])
    ? device[P.STATE]
    : {}
}

ThermostatControl.prototype = Object.create(Device.prototype)
ThermostatControl.prototype.constructor = ThermostatControl

// region Events

/**
 * State has been updated
 *
 * @event ThermostatControl#EVT_STATE_CHANGED
 * @since 2.11.0
 */

// endregion

/**
 * @constant {string}
 * @since 2.11.0
 */
ThermostatControl.EVT_STATE_CHANGED = 'evtThermostatControlStateChanged'

/**
 * @constant {string}
 */
ThermostatControl.ACTIVE = P.ACTIVE

/**
 * From proto.be.basalte.config.ThermostatControl.ControlType
 *
 * @readonly
 * @enum {(number|string)}
 */
ThermostatControl.TYPES = {
  CUSTOM: 0,
  FLOOR_HEATING: 1,
  AIR_CONDITIONING: 2
}

/**
 * Reverse enum
 *
 * @readonly
 * @enum {string}
 */
ThermostatControl.TYPES_R =
  BasUtil.switchObjectKeyValue(ThermostatControl.TYPES)

/**
 * @name ThermostatControl#active
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(ThermostatControl.prototype, 'active', {
  get: function () {
    return this._state[P.ACTIVE] === true
  }
})

/**
 * @name ThermostatControl#subTypeName
 * @type {string}
 * @readonly
 */
Object.defineProperty(ThermostatControl.prototype, 'subTypeName', {
  get: function () {
    return ThermostatControl.TYPES_R[this._subType]
  }
})

/**
 * @param {TThermostatControl} msg
 * @param {TDeviceParseOptions} [options]
 * @returns {boolean}
 */
ThermostatControl.prototype.parse = function (
  msg,
  options
) {
  var emit, valid

  emit = true
  valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    if (BasUtil.isObject(msg[P.STATE])) {

      this._state = msg[P.STATE]

      if (emit) this.emit(ThermostatControl.EVT_STATE_CHANGED)
    }
  }

  return valid
}

module.exports = ThermostatControl
