'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('alarmsCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'STATES',
    'UI_HELPER',
    'BAS_CURRENT_CORE',
    'BAS_ROOMS',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_FAVOURITE',
    'BAS_INPUT_MODAL',
    'BasState',
    'CurrentBasCore',
    'alarmService',
    'UiHelper',
    'Sources',
    'SourcesHelper',
    'BasInputModal',
    'BasIntl',
    'Logger',
    alarmsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {UI_HELPER} UI_HELPER
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param {BasState} BasState
 * @param {CurrentBasCore} CurrentBasCore
 * @param alarmService
 * @param {UiHelper} UiHelper
 * @param {Sources} Sources
 * @param {SourcesHelper} SourcesHelper
 * @param {BasInputModal} BasInputModal
 * @param {BasIntl} BasIntl
 * @param Logger
 */
function alarmsCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  STATES,
  UI_HELPER,
  BAS_CURRENT_CORE,
  BAS_ROOMS,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_FAVOURITE,
  BAS_INPUT_MODAL,
  BasState,
  CurrentBasCore,
  alarmService,
  UiHelper,
  Sources,
  SourcesHelper,
  BasInputModal,
  BasIntl,
  Logger
) {

  var controllerName = 'Alarms controller'

  var CLASS_OPTIONS = 'alarm-main-options-show'

  var listeners = []

  var ATTR_NONE = 0
  var ATTR_TYPE = 1
  var ATTR_ZONES = 2
  var ATTR_SOURCE = 3
  var ATTR_MUSIC = 4

  var ID_SOURCE_INFO = 0
  var ID_SOURCE_UI = 1

  // Helps to check for changes in alarm configuration
  var zonesObj = null

  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  var newAlarm = false

  /**
   * @type {BAS_SOURCES}
   */
  $scope.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {BasRooms}
   */
  $scope.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {number}
   */
  $scope.ATTR_MUSIC = ATTR_MUSIC

  // Alarm service
  $scope.alarmService = alarmService
  $scope.alarmWrapper = alarmService.getAlarmWrapper()
  $scope.alarmControl = alarmService.getAlarmControl()
  $scope.daysWeekInfo = alarmService.getDaysWeek()
  $scope.daysWeek = alarmService.getLocalDaysWeek()
  $scope.today = BasIntl.format(new Date(), 'YYYY-MM-DD')

  // Constants
  $scope.ATTR_NONE = ATTR_NONE
  $scope.ATTR_TYPE = ATTR_TYPE
  $scope.ATTR_ZONES = ATTR_ZONES
  $scope.ATTR_SOURCE = ATTR_SOURCE
  $scope.ATTR_MUSIC = ATTR_MUSIC

  $scope.ID_SOURCE_INFO = ID_SOURCE_INFO
  $scope.ID_SOURCE_UI = ID_SOURCE_UI

  // Selected alarm
  $scope.selectedAlarmId = null

  $scope.currentAttr = $scope.ATTR_NONE
  $scope.optionsShown = false
  $scope.optionsClass = {}
  $scope.optionsClass[CLASS_OPTIONS] = false

  // Current option
  $scope.currentOption = null

  // Options objects
  $scope.uiZonesWrapper = null
  $scope.uiZoneInfoWrapper = null

  $scope.canSelectMultiple = false

  // UI conditions
  $scope.can = {}
  $scope.can.selectZone = false
  $scope.can.selectSource = false

  $scope.selectAlarm = selectAlarm
  $scope.selectAttribute = selectAttribute
  $scope.selectOption = selectOption
  $scope.back = back
  $scope.onTimeClicked = onTimeClicked

  init()

  function init () {

    $scope.$on('$destroy', onDestroy)

    clearItems()

    listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      onMusicConfig
    ))
    listeners.push($rootScope.$on(
      UI_HELPER.EVT_RESIZE,
      onUiSize
    ))

    _syncSingleProperties()

    Sources.registerFor(BAS_SOURCE.COL_EVT_FAVOURITES)

    alarmService.setAlarmsLoadedCallback(onAlarmUpdated)
    alarmService.setNewAlarmCallback(onNewAlarm)
    alarmService.resume()
  }

  function onMusicConfig () {

    _syncSingleProperties()
  }

  function checkScreenWidth () {

    if (
      basUi.prop.wMedium &&
      $uiRouterGlobals.current.name === STATES.ALARMS
    ) {
      BasState.go(STATES.ALARMS_ALARM)
    }
  }

  function onUiSize () {
    checkScreenWidth()
  }

  // Alarms loaded callback function
  function onAlarmUpdated () {

    var alarms = $scope.alarmWrapper[alarmService.ID_ALARMS]

    Logger.debug(
      controllerName + ' - onAlarmUpdated',
      $scope.selectedAlarmId,
      alarms
    )

    // Check if the selectedAlarmId still represents a valid alarm
    if (Array.isArray(alarms) &&
      alarmService.getAlarmForId($scope.selectedAlarmId) === -1 &&
      alarms[0]) {

      // Set the first alarm as selected alarm
      $scope.selectedAlarmId = alarms[0].id
    }

    if (newAlarm) {

      newAlarm = false

      if (BasUtil.isNEArray(alarms) && alarms[alarms.length - 1]) {

        $scope.selectedAlarmId = alarms[alarms.length - 1].id
      }
    }
  }

  function onNewAlarm () {

    newAlarm = true
  }

  function toggleAttributeUI (attributeType, state) {

    // Set UI Attribute variables
    if (state) {

      // Check attributeType is valid
      if (attributeType === ATTR_TYPE ||
        attributeType === ATTR_ZONES ||
        attributeType === ATTR_SOURCE ||
        attributeType === ATTR_MUSIC) {

        // Enable attribute UI
        $scope.currentAttr = attributeType
        $scope.optionsShown = true
        $scope.optionsClass[CLASS_OPTIONS] = true

      } else {
        Logger.warn(
          controllerName + ' - activateAttribute - invalid parameter',
          attributeType
        )
      }

    } else {

      // Disable attribute UI
      $scope.optionsShown = false
      $scope.optionsClass[CLASS_OPTIONS] = false
      $scope.currentOption = null

    }
  }

  function selectAlarm (alarmId) {

    Logger.debug(
      controllerName + ' - Alarm selected',
      alarmId,
      $scope.alarmWrapper[alarmService.ID_ALARM_INFO][alarmId]
    )

    back($scope.selectedAlarmId)

    // Set selectedAlarmId
    $scope.selectedAlarmId = alarmId

    // Set UI - Hide
    toggleAttributeUI(ATTR_NONE, false)

    BasState.go(STATES.ALARMS_ALARM)
  }

  function back (alarmId) {

    // Get alarm reference
    var alarm = $scope.alarmWrapper[alarmService.ID_ALARM_INFO][alarmId]

    // Set zones
    switch ($scope.currentAttr) {
      case ATTR_TYPE:

        // Check if alarm needs to be updated
        if (alarm.dirty) {

          // Update alarm
          $scope.alarmControl.updateInfo(alarmId)
        }

        break
      case ATTR_ZONES:

        // Check if update is necessary
        if (!alarmService.compareZones(zonesObj, alarm.zonesObj)) {

          // Update zones
          $scope.alarmControl.setZones(alarmId)

        } else {
          Logger.debug(controllerName +
                         ' - BACK - no need to setZones')
        }

        break
    }

    // Set UI - hide
    toggleAttributeUI(ATTR_NONE, false)
  }

  function onTimeClicked () {

    var alarm, selectedAlarmId

    selectedAlarmId = $scope.selectedAlarmId

    alarm = $scope.alarmWrapper[alarmService.ID_ALARM_INFO][selectedAlarmId]

    if (!alarm || !(alarm.date instanceof Date)) return

    BasInputModal.show(
      BAS_INPUT_MODAL.T_TIME,
      {
        title: 'time',
        time: alarm.date
      }
    ).then(_onModal)

    function _onModal (modal) {

      modal.close.then(_onModalClose)
    }

    function _onModalClose (result) {

      if (result instanceof Date) {

        alarm.date = result
        $scope.alarmControl.updateTime(selectedAlarmId)
      }
    }
  }

  function selectAttribute (alarmId, attributeType) {

    var alarm

    Logger.debug(
      controllerName + ' - Attribute selected',
      alarmId,
      attributeType
    )

    // Make sure previous collections have been cleared
    clearOptions()

    // Get a reference for current alarm
    alarm = $scope.alarmWrapper[alarmService.ID_ALARM_INFO][alarmId]

    switch (attributeType) {
      case ATTR_TYPE:

        // Set UI
        toggleAttributeUI(attributeType, true)

        break
      case ATTR_ZONES:

        // Set UI
        toggleAttributeUI(attributeType, true)

        // Check the zones hash
        zonesObj = alarmService.getCopyZones(alarmId)

        break
      case ATTR_SOURCE:

        // Set UI
        toggleAttributeUI(attributeType, true)

        // Set currentOption
        $scope.currentOption = alarm.player

        break
      case ATTR_MUSIC:

        if (alarm.player) {

          // Set UI
          toggleAttributeUI(attributeType, true)

          // Set currentOption
          if (alarm.playlistId) {
            $scope.currentOption = alarm.playlistId
          } else if (alarm.tuneinId) {
            $scope.currentOption = alarm.tuneinId
          } else if (alarm.deezerId) {
            $scope.currentOption = alarm.deezerId
          } else if (alarm.tidalId) {
            $scope.currentOption = alarm.tidalId
          } else if (alarm.spotifyPreset) {
            $scope.currentOption = alarm.spotifyPreset
          }
        }
        break
    }
  }

  function selectOption (alarmId, optionId) {

    var alarm, basSource, favourite

    Logger.debug(controllerName + ' - selectOption', alarmId, optionId)

    // Get reference for current alarmInfo
    alarm = $scope.alarmWrapper[alarmService.ID_ALARM_INFO][alarmId]

    switch ($scope.currentAttr) {
      case ATTR_ZONES:

        // Toggle the selected zone
        alarm.zonesObj[optionId] = !alarm.zonesObj[optionId]

        break
      case ATTR_SOURCE:

        // Only update when selected player is different
        if (alarm.player !== optionId) {

          // Clear previous music choice
          alarm.playlistId = null
          alarm.tuneinId = null

          basSource = BAS_SOURCES.SOURCES.sources[optionId]

          if (BasUtil.isObject(basSource)) {

            alarm.playerName = basSource.name

            $scope.alarmControl.setPlayer(alarmId, basSource.id)

          } else {

            alarm.playerName = ''
          }
        }

        // Set UI - hide
        toggleAttributeUI(ATTR_NONE, false)

        break
      case ATTR_MUSIC:

        basSource = SourcesHelper.getPlayer(alarm.player)

        if (basSource && basSource.favourites) {

          favourite = basSource.favourites.getFavourite(optionId)
        }

        if (favourite) {

          // Update sourceName
          alarm.sourceName = favourite.ui.title

          switch (favourite.type) {
            case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
              Logger.debug(controllerName + ' - select - PLAYLIST')
              $scope.alarmControl.setMusic(
                alarmId,
                favourite.alarmId,
                alarmService.ID_MUSIC_PLAYLISTS
              )
              break
            case BAS_FAVOURITE.T_RADIO:
              Logger.debug(controllerName + ' - select - RADIO')
              $scope.alarmControl.setMusic(
                alarmId,
                favourite.alarmId,
                alarmService.ID_MUSIC_RADIOS
              )
              break
            case BAS_FAVOURITE.T_DEEZER:
              Logger.debug(controllerName + ' - select - DEEZER')
              $scope.alarmControl.setMusic(
                alarmId,
                favourite.alarmId,
                alarmService.ID_MUSIC_DEEZER
              )
              break
            case BAS_FAVOURITE.T_TIDAL:
              Logger.debug(controllerName + ' - select - TIDAL')
              $scope.alarmControl.setMusic(
                alarmId,
                favourite.alarmId,
                alarmService.ID_MUSIC_TIDAL
              )
              break
            case BAS_FAVOURITE.T_SPOTIFY_CONNECT:
              Logger.debug(controllerName + ' - select - SPOTIFY')
              $scope.alarmControl.setMusic(
                alarmId,
                favourite.alarmId,
                alarmService.ID_MUSIC_SPOTIFY
              )
              break
          }

          // Set UI - hide
          toggleAttributeUI(ATTR_NONE, false)
        }

        break
      default:
        Logger.warn(
          controllerName + ' - selectOption - invalid ATTR state',
          $scope.currentAttr
        )
    }
  }

  function _syncSingleProperties () {

    $scope.can.selectZone = false
    $scope.can.selectSource = false

    if (CurrentBasCore.hasCore()) {

      $scope.can.selectZone =
        !currentBasCoreState.core.core.singleAudioRoomId
      $scope.can.selectSource =
        !currentBasCoreState.core.core.hasSingleSource()
    }
  }

  function clearOptions (type) {
    switch (type) {
      case ATTR_ZONES:
        $scope.uiZonesWrapper = null
        $scope.uiZoneInfoWrapper = null
        break
      case ATTR_SOURCE:
      case ATTR_MUSIC:
        clearItems()
        break
      default:
        $scope.uiZonesWrapper = null
        $scope.uiZoneInfoWrapper = null
        clearItems()
    }
  }

  function clearItems () {
    $scope.items = {}
    $scope.itemInfo = {}
  }

  function onDestroy () {
    alarmService.suspend()
    Sources.unregisterFor(BAS_SOURCE.COL_EVT_FAVOURITES)

    BasUtil.executeArray(listeners)
    listeners = []
  }
}
