'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('dspModalCtrl', [
    '$rootScope',
    '$scope',
    'BAS_DSP',
    'BAS_SPLASH',
    'BAS_ROOMS',
    'CurrentRoom',
    'close',
    'roomId',
    'isAdmin',
    dspModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_DSP} BAS_DSP
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {CurrentRoom} CurrentRoom
 * @param close
 * @param roomId
 * @param isAdmin
 */
function dspModalCtrl (
  $rootScope,
  $scope,
  BAS_DSP,
  BAS_SPLASH,
  BAS_ROOMS,
  CurrentRoom,
  close,
  roomId,
  isAdmin
) {
  var modal = this

  var _listeners = []

  var CSS_CAN_NEXT = 'dsp-modal-can-next'
  var CSS_CAN_PREVIOUS = 'dsp-modal-can-previous'

  var PAGE_COUNT = 3

  /**
   * @type {BasRooms}
   */
  modal.rooms = BAS_ROOMS.ROOMS

  modal.roomId = roomId

  modal.isAdmin = isAdmin

  modal.close = close

  modal.pageIndex = 0

  modal.BAS_DSP = BAS_DSP

  modal.css = {}

  modal.next = next
  modal.previous = previous
  modal.selectCustomProfile = selectCustomProfile

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    _updateCss()

    $scope.$on('$destroy', _onDestroy)
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }

  function next () {

    _setCurrentPageIndex(modal.pageIndex + 1)
  }

  function previous () {

    if (modal.pageIndex !== 0) {

      _setCurrentPageIndex(modal.pageIndex - 1)
      _updateCss()

    } else {

      modal.close()
    }
  }

  function _setCurrentPageIndex (index) {
    modal.pageIndex = Math.min(PAGE_COUNT - 1, Math.max(0, index))

    _updateCss()
  }

  function _updateCss () {
    modal.css[CSS_CAN_NEXT] = modal.pageIndex < PAGE_COUNT - 1
    modal.css[CSS_CAN_PREVIOUS] = modal.pageIndex !== 0
  }

  function selectCustomProfile () {
    var currentRoomMusic = CurrentRoom.getRoomMusic()

    if (currentRoomMusic) {

      previous()
      return currentRoomMusic.selectDspProfile(
        BAS_DSP.DSP_PROFILE_CUSTOM
      )
    }
  }
}
