'use strict'

var BasUtil = require('@basalte/bas-util')
var EventEmitter = require('@gidw/event-emitter-js')
var Device = require('./device')
var Capabilities = require('./capabilities')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var log = require('./logger')

/**
 * @typedef {Object} TCallTopicContact
 * @property {string} name
 * @property {string} uuid
 * @property {string} type
 * @property {string} roomType
 * TODO room images, not sure if needed if we can directly link with room
 */

/**
 * @typedef {Object} TCallTopicCallResult
 * @property {string} callUuid
 * @property {number} pcId
 */

/**
 * @typedef {Object} TCallTopicPublishStreamResult
 * @property {string} callUuid
 * @property {number} pcId
 * @property {Object} jsep
 */

/**
 * @typedef {Object} TCallTopicCaller
 * @property {string} sessionId
 * @property {string} user
 * @property {string} device
 * @property {string} name
 * @property {string} basType
 */

/**
 * @typedef {Object} TCallTopicStream
 * @property {bool} active
 * @property {string} codec
 * @property {string} feeds_display
 * @property {string} feed_id
 * @property {string} feed_mid
 * @property {string} md
 * @property {number} mindex
 * @property {bool} ready
 * @property {bool} send
 * @property {string} type
 */

/**
 * @typedef {Object} TCallTopicCallParticipant
 * @property {string} sessionId
 * @property {string} user
 * @property {number} [pcId]
 * @property {Array<TCallTopicStream>} streams
 */

/**
 * @typedef {Object} TCallTopicCallCandidate
 * @property {string} candidate
 * @property {number} sdpMLineIndex
 * @property {string} sdpMid
 */

/**
 * @typedef {Object} TCallTopicEventCalled
 * @property {string} callUuid
 * @property {number} pcId
 * @property {TCallTopicCaller} caller
 * @property {?Array<string>} [doors]
 * @property {?Array} [cameras]
 * @property {?Capabilities} [capabilities]
 */

/**
 * @typedef {Object} TCallTopicEventNewStream
 * @property {string} callUuid
 * @property {number} pcId
 * @property {Object} jsep
 * @property {Array<TCallTopicCallParticipant>} participants
 */

/**
 * @typedef {Object} TCallTopicEventIceCandidate
 * @property {string} callUuid
 * @property {number} pcId
 * @property {?Array<TCallTopicCallCandidate>} candidates
 * @property {?boolean} completed
 */

/**
 * @typedef {Object} TCallTopicEventJoinedCall
 * @property {string} callUuid
 * @property {TCallTopicCallParticipant} participant
 * @property {boolean} completed
 */

/**
 * @typedef {Object} TCallTopicEventLeftCall
 * @property {string} callUuid
 * @property {Array<TCallTopicCallParticipant>} participants
 * @property {boolean} completed
 */

/**
 * @typedef {Object} TCallTopicEventCallEnded
 * @property {string} callUuid
 * @property {string} reason
 */

/**
 * Class representing call API
 *
 * @constructor
 * @extends EventEmitter
 * @param {BasCore} basCore
 */
function CallTopic (basCore) {

  EventEmitter.call(this)

  this._basCore = basCore

  this._handleContacts = this._onContacts.bind(this)
  this._handleCall = this._onCall.bind(this)
  this._handlePublishStream = this._onPublishStream.bind(this)
}

CallTopic.prototype = Object.create(EventEmitter.prototype)
CallTopic.prototype.constructor = CallTopic

/**
 * @constant {string}
 */
CallTopic.T_ELLIE = P.ELLIE

/**
 * @constant {string}
 */
CallTopic.T_LISA = P.LISA

/**
 * @constant {string}
 */
CallTopic.T_LENA = P.LENA

/**
 * @constant {string}
 */
CallTopic.T_ROOM = P.ROOM

/**
 * @constant {string}
 */
CallTopic.JSEP_TYPE_OFFER = P.OFFER

/**
 * @constant {string}
 */
CallTopic.JSEP_TYPE_ANSWER = P.ANSWER

/**
 * @constant {string}
 */
CallTopic.CER_HUNG_UP = P.HUNG_UP

/**
 * @constant {string}
 */
CallTopic.CER_CONTACT_UNREACHABLE = P.CONTACT_UNREACHABLE

/**
 * @constant {string}
 */
CallTopic.EVT_CALLED = 'evtCallTopicCalled'

/**
 * @constant {string}
 */
CallTopic.EVT_NEW_STREAM = 'evtCallTopicNewStream'

/**
 * @constant {string}
 */
CallTopic.EVT_ICE_CANDIDATE = 'evtCallTopicIceCandidate'

/**
 * @constant {string}
 */
CallTopic.EVT_JOINED_CALL = 'evtCallTopicJoinedCall'

/**
 * @constant {string}
 */
CallTopic.EVT_LEFT_CALL = 'evtCallTopicLeftCall'

/**
 * @constant {string}
 */
CallTopic.EVT_CALL_ENDED = 'evtCallTopicCallEnded'

/**
 * @constant {string}
 */
CallTopic.C_OPEN_DOOR = P.OPEN_DOOR

/**
 * Parse a call topic message
 *
 * @param {Object} msg
 */
CallTopic.prototype.parse = function (msg) {

  var data, _this

  _this = this

  if (
    msg &&
    msg[P.DATA] &&
    BasUtil.isNEString(msg[P.DATA][P.ACTION])
  ) {

    data = msg[P.DATA]

    switch (data[P.ACTION]) {

      case P.CALLED:

        _processEventCalled()
        break

      case P.NEW_STREAM:

        _processNewStream()
        break

      case P.CANDIDATE:

        _processIceCandidate()
        break

      case P.JOINED_CALL:

        _processJoinedCall()
        break

      case P.LEFT_CALL:

        _processLeftCall()
        break

      case P.CALL_ENDED:

        _processCallEnded()
        break

      default:

        log.warn('Unknown call event: ', msg)
        break
    }
  }

  function _processEventCalled () {

    var caller
    /**
     * @type {TCallTopicEventCalled}
     */
    var evt

    if (
      BasUtil.isNEString(data[P.CALL_UUID]) &&
      BasUtil.isVNumber(data[P.PC_ID]) &&
      data[P.CALLER]
    ) {

      caller = data[P.CALLER]

      if (
        BasUtil.isNEString(caller[P.SESSION_ID]) &&
        BasUtil.isNEString(caller[P.DEVICE]) &&
        BasUtil.isNEString(caller[P.USER]) &&
        BasUtil.isNEString(caller[P.BAS_TYPE])
      ) {

        // 'caller' is required

        evt = {
          callUuid: data[P.CALL_UUID],
          pcId: data[P.PC_ID],
          caller: {
            sessionId: caller[P.SESSION_ID],
            user: caller[P.USER],
            device: caller[P.DEVICE],
            name: caller[P.NAME],
            basType: caller[P.BAS_TYPE]
          }
        }

        if (data[P.ATTRIBUTES]) {

          if (BasUtil.isNEArray(data[P.ATTRIBUTES][P.DOORS])) {

            evt.doors = data[P.ATTRIBUTES][P.DOORS]
          }

          if (BasUtil.isNEArray(data[P.ATTRIBUTES][P.CAMERAS])) {

            evt.doors = data[P.ATTRIBUTES][P.CAMERAS]
          }
        }

        if (data[P.CAPABILITIES]) {

          evt.capabilities = new Capabilities(data[P.CAPABILITIES])
        }

        _this.emit(CallTopic.EVT_CALLED, evt)
      }

    } else {

      log.warn('Invalid \'called\' event:', data)
    }
  }

  function _processNewStream () {

    /**
     * @type {TCallTopicEventNewStream}
     */
    var evt

    if (
      BasUtil.isNEString(data[P.CALL_UUID]) &&
      BasUtil.isVNumber(data[P.PC_ID]) &&
      data[P.JSEP] &&
      Array.isArray(data[P.PARTICIPANTS])
    ) {

      evt = {
        callUuid: data[P.CALL_UUID],
        pcId: data[P.PC_ID],
        jsep: data[P.JSEP],
        participants: data[P.PARTICIPANTS]
      }
      _this.emit(CallTopic.EVT_NEW_STREAM, evt)

    } else {

      log.warn('Invalid \'newStream\' event:', data)
    }
  }

  function _processIceCandidate () {

    /**
     * @type {TCallTopicEventIceCandidate}
     */
    var evt

    if (
      BasUtil.isNEString(data[P.CALL_UUID]) &&
      BasUtil.isVNumber(data[P.PC_ID])
    ) {

      evt = {
        callUuid: data[P.CALL_UUID],
        pcId: data[P.PC_ID],
        candidates: data[P.CANDIDATES],
        completed: data[P.COMPLETED]
      }
      _this.emit(CallTopic.EVT_ICE_CANDIDATE, evt)

    } else {

      log.warn('Invalid \'candidate\' event:', data)
    }
  }

  function _processJoinedCall () {

    /**
     * @type {TCallTopicEventJoinedCall}
     */
    var evt

    if (
      BasUtil.isNEString(data[P.CALL_UUID]) &&
      (
        data[P.PARTICIPANT] ||
        BasUtil.isBool(data[P.COMPLETED])
      )
    ) {

      evt = {
        callUuid: data[P.CALL_UUID],
        participant: data[P.PARTICIPANT],
        completed: data[P.COMPLETED]
      }
      _this.emit(CallTopic.EVT_JOINED_CALL, evt)

    } else {

      log.warn('Invalid \'joinedCall\' event:', data)
    }
  }

  function _processLeftCall () {

    /**
     * @type {TCallTopicEventLeftCall}
     */
    var evt

    if (
      BasUtil.isNEString(data[P.CALL_UUID]) &&
      Array.isArray(data[P.PARTICIPANTS])
    ) {

      evt = {
        callUuid: data[P.CALL_UUID],
        participants: data[P.PARTICIPANTS]
      }
      _this.emit(CallTopic.EVT_LEFT_CALL, evt)

    } else {

      log.warn('Invalid \'leftCall\' event:', data)
    }
  }

  function _processCallEnded () {

    /**
     * @type {TCallTopicEventCallEnded}
     */
    var evt

    if (BasUtil.isNEString(data[P.CALL_UUID])) {

      evt = {
        callUuid: data[P.CALL_UUID],
        reason: data[P.REASON]
      }
      _this.emit(CallTopic.EVT_CALL_ENDED, evt)

    } else {

      log.warn('Invalid \'callEnded\' event:', data)
    }
  }
}

/**
 * Gets contacts
 *
 * @returns {Promise<Array<TCallTopicContact>>}
 */
CallTopic.prototype.listContacts = function () {

  var data

  data = {}
  data[P.ACTION] = P.LIST_CONTACTS

  return this._request(data)
    .then(this._handleContacts)
}

/**
 * @param {Object} msg
 * @returns {Array<TCallTopicContact>}
 */
CallTopic.prototype._onContacts = function (msg) {

  var contacts, i, length, contact

  /**
   * @type {Array<TCallTopicContact>}
   */
  var result = []

  if (
    msg &&
    msg[P.DATA] &&
    msg[P.DATA][P.ACTION] === P.LIST_CONTACTS &&
    Array.isArray(msg[P.DATA][P.CONTACTS])
  ) {
    contacts = msg[P.DATA][P.CONTACTS]

    length = contacts.length
    for (i = 0; i < length; i++) {

      contact = contacts[i]

      if (
        contact &&
        BasUtil.isNEString(contact[P.UUID]) &&
        BasUtil.isNEString(contact[P.CONTACT_TYPE]) &&
        (
          BasUtil.isNEString(contact[P.NAME]) ||
          BasUtil.isNEString(contact[P.TYPE])
        )
      ) {
        result.push({
          name: contact[P.NAME],
          type: contact[P.CONTACT_TYPE],
          roomType: contact[P.TYPE],
          uuid: contact[P.UUID]
          // TODO: parse images so we are no longer reliant on old API
        })
      }
    }
  }
  return result
}

/**
 * Initiate a call with a contact
 *
 * @param {string} contactUuid
 * @param {string} [callUuid]
 * @returns {Promise<Array<TCallTopicContact>>}
 */
CallTopic.prototype.call = function (contactUuid, callUuid) {

  var data

  if (BasUtil.isNEString(contactUuid)) {

    data = {}
    data[P.ACTION] = P.CALL
    data[P.CONTACT] = contactUuid

    if (BasUtil.isNEString(callUuid)) {

      data[P.CALL_UUID] = callUuid
    }

    return this._request(data)
      .then(this._handleCall)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {Object} msg
 * @protected
 * @returns {TCallTopicCallResult}
 */
CallTopic.prototype._onCall = function (msg) {

  if (
    msg &&
    msg[P.DATA] &&
    msg[P.DATA][P.ACTION] === P.CALL &&
    BasUtil.isNEString(msg[P.DATA][P.CALL_UUID]) &&
    BasUtil.isVNumber(msg[P.DATA][P.PC_ID])
  ) {

    return {
      callUuid: msg[P.DATA][P.CALL_UUID],
      pcId: msg[P.DATA][P.PC_ID]
    }
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * Join an existing call
 *
 * @param {string} callUuid
 * @returns {Promise}
 */
CallTopic.prototype.joinCall = function (callUuid) {

  var data

  if (BasUtil.isNEString(callUuid)) {

    data = {}
    data[P.ACTION] = P.JOIN_CALL
    data[P.CALL_UUID] = callUuid

    // TODO: parse response if important
    return this._request(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Hangup a call
 *
 * @param {string} callUuid
 * @returns {Promise}
 */
CallTopic.prototype.hangUp = function (callUuid) {

  var data

  if (BasUtil.isNEString(callUuid)) {

    data = {}
    data[P.ACTION] = P.HANGUP
    data[P.CALL_UUID] = callUuid

    return this._request(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Open door
 *
 * @param {string} callUuid
 * @param {string} [_doorUuid]
 * @returns {Promise}
 */
CallTopic.prototype.openDoor = function (callUuid, _doorUuid) {
  var data

  if (BasUtil.isNEString(callUuid)) {

    data = {}
    data[P.ACTION] = P.OPEN_DOOR
    data[P.CALL_UUID] = callUuid

    // Currently only 1 door can be paired, so we don't need to pass the door
    //  uuid (server has not implemented this either)

    return this._request(data)
  }
  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Start watching a stream
 *
 * @param {string} callUuid
 * @param {number} [pcId]
 * @param {Object} jsep
 * @returns {Promise}
 */
CallTopic.prototype.startWatching = function (callUuid, pcId, jsep) {

  var data

  if (
    BasUtil.isNEString(callUuid) &&
    BasUtil.isVNumber(pcId) &&
    BasUtil.isObject(jsep)
  ) {

    data = {}
    data[P.ACTION] = P.START_WATCHING
    data[P.CALL_UUID] = callUuid
    data[P.PC_ID] = pcId
    data[P.JSEP] = jsep

    return this._request(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Publish a stream
 *
 * @param {string} callUuid
 * @param {number} pcId
 * @param {Object} jsep
 * @returns {Promise<TCallTopicPublishStreamResult>}
 */
CallTopic.prototype.publishStream = function (callUuid, pcId, jsep) {

  var data

  if (
    BasUtil.isNEString(callUuid) &&
    BasUtil.isVNumber(pcId) &&
    BasUtil.isObject(jsep)
  ) {

    data = {}
    data[P.ACTION] = P.PUBLISH_STREAM
    data[P.CALL_UUID] = callUuid
    data[P.PC_ID] = pcId
    data[P.JSEP] = jsep

    return this._request(data)
      .then(this._handlePublishStream)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {Object} msg
 * @returns {TCallTopicPublishStreamResult}
 */
CallTopic.prototype._onPublishStream = function (msg) {

  if (
    msg &&
    msg[P.DATA] &&
    msg[P.DATA][P.ACTION] === P.PUBLISH_STREAM &&
    BasUtil.isNEString(msg[P.DATA][P.CALL_UUID]) &&
    BasUtil.isVNumber(msg[P.DATA][P.PC_ID]) &&
    msg[P.DATA][P.JSEP]
  ) {

    return {
      callUuid: msg[P.DATA][P.CALL_UUID],
      pcId: msg[P.DATA][P.PC_ID],
      jsep: msg[P.DATA][P.JSEP]
    }
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * Publish a stream
 *
 * @param {string} callUuid
 * @param {number} [pcId]
 * @returns {Promise<Array<TCallTopicContact>>}
 */
CallTopic.prototype.addPublisher = function (callUuid, pcId) {

  var data

  if (BasUtil.isNEString(callUuid)) {

    data = {}
    data[P.ACTION] = P.START_WATCHING
    data[P.CALL_UUID] = callUuid

    if (BasUtil.isPNumber(pcId)) {

      data[P.PC_ID] = pcId
    }

    return this._request(data)
    // TODO: pcId in result should be parsed
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Publish a stream
 *
 * @param {string} callUuid
 * @param {number} pcId
 * @param {TCallTopicCallCandidate} candidate
 * @returns {Promise<Array<TCallTopicContact>>}
 */
CallTopic.prototype.addCandidate = function (
  callUuid,
  pcId,
  candidate
) {

  var data

  if (
    BasUtil.isNEString(callUuid) &&
    BasUtil.isPNumber(pcId) &&
    candidate
  ) {

    data = {}
    data[P.ACTION] = P.CANDIDATE
    data[P.CALL_UUID] = callUuid
    data[P.PC_ID] = pcId
    data[P.CANDIDATES] = [candidate]

    return this._request(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Send call api message
 *
 * @param {Object} data
 * @protected
 * @returns {Promise}
 */
CallTopic.prototype._request = function (data) {

  var msg

  msg = {}
  msg[P.TOPIC] = P.CALL
  msg[P.DATA] = data

  return this._basCore.requestV2(msg)
    .then(Device.handleResponse)
}

module.exports = CallTopic
