'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasKNXPreset', [
    'BAS_KNXPRESETS',
    'BAS_FAVOURITE',
    'BasFavouritesHelper',
    basKNXPresetFactory
  ])

/**
 * @param {BAS_KNXPRESETS} BAS_KNXPRESETS
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BasFavouritesHelper} BasFavouritesHelper
 * @returns BasKNXPreset
 */
function basKNXPresetFactory (
  BAS_KNXPRESETS,
  BAS_FAVOURITE,
  BasFavouritesHelper
) {
  /**
   * @constructor
   * @param {Object} preset
   */
  function BasKNXPreset (preset) {

    /**
     * @type {number}
     */
    this.id = 0

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.favouriteId = ''

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * @type {string}
     */
    this.subtype = ''

    /**
     * @type {string}
     */
    this.value = ''

    if (preset) this.parse(preset)
  }

  /**
   * @param {Object} preset
   */
  BasKNXPreset.prototype.parse = function (preset) {

    if (BasUtil.isObject(preset)) {

      if (BasUtil.isVNumber(preset.id)) this.id = preset.id
      if (BasUtil.isNEString(preset.name)) this.name = preset.name
      if (BasUtil.isNEString(preset.type)) this.type = preset.type
      if (BasUtil.isNEString(preset.subtype)) {
        this.subtype = preset.subtype
      }
      if (BasUtil.isNEString(preset.value)) this.value = preset.value

      if (preset.uri) {

        this.favouriteId = preset.uri
        this.value = preset.uri

      } else {

        this._generateFavouriteId()
      }
    }
  }

  /**
   * @private
   */
  BasKNXPreset.prototype._generateFavouriteId = function () {

    switch (this.type) {
      case BAS_KNXPRESETS.T_LOCAL:

        if (this.subtype === BAS_KNXPRESETS.ST_PLAYLIST) {

          this.favouriteId = BasFavouritesHelper.getUuid(
            BAS_FAVOURITE.T_LOCAL_PLAYLIST,
            this.value
          )

        } else {

          // TODO Unsupported KNX presets
        }

        break
      case BAS_KNXPRESETS.T_TUNEIN:

        this.favouriteId = BasFavouritesHelper.getUuid(
          BAS_FAVOURITE.T_RADIO,
          this.value
        )

        break
      case BAS_KNXPRESETS.T_DEEZER:

        if (this.subtype === BAS_KNXPRESETS.ST_FLOW) {

          this.favouriteId = BAS_FAVOURITE.UUID_DEEZER_FLOW

        } else {

          this.favouriteId = BasFavouritesHelper.getUuid(
            BAS_FAVOURITE.T_DEEZER,
            this.subtype + BAS_FAVOURITE.SEP_DEEZER + this.value
          )
        }

        break
      case BAS_KNXPRESETS.T_TIDAL:

        this.favouriteId = BasFavouritesHelper.getUuid(
          BAS_FAVOURITE.T_TIDAL,
          this.subtype + BAS_FAVOURITE.SEP_TIDAL + this.value
        )

        break
      case BAS_KNXPRESETS.T_SPOTIFY:

        if (this.value === BAS_KNXPRESETS.V_SPOTIFY_CONNECT_CLIENT) {

          this.favouriteId =
            BAS_FAVOURITE.UUID_SPOTIFY_CONNECT_CURRENT

        } else {

          this.favouriteId = BasFavouritesHelper.getUuid(
            BAS_FAVOURITE.T_SPOTIFY_CONNECT,
            this.value
          )
        }

        break
    }
  }

  return BasKNXPreset
}
