'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basProfileLogin', [
    'BAS_HTML',
    basProfileLoginDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 */
function basProfileLoginDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.basProfileLogin,
    controller: [
      '$scope',
      '$rootScope',
      '$element',
      'BAS_API',
      'BAS_MODAL',
      'BAS_APP',
      'UI_HELPER',
      'BasResource',
      'BasModal',
      'BasLiveAccount',
      'BasUtilities',
      'UiHelper',
      'Swiper',
      controller
    ],
    controllerAs: 'basProfileLogin',
    bindToController: {
      basServer: '<',
      profilesKey: '<?',
      onLoginStarted: '&?',
      onLoginError: '&?',
      onLogin: '&',
      showCancel: '<?',
      header: '@'
    }
  }

  /**
   * @param $scope
   * @param $rootScope
   * @param $element
   * @param BAS_API
   * @param {BAS_MODAL} BAS_MODAL
   * @param {BAS_APP} BAS_APP
   * @param {UI_HELPER} UI_HELPER
   * @param {BasResource} BasResource
   * @param {BasModal} BasModal
   * @param {BasLiveAccount} BasLiveAccount
   * @param {BasUtilities} BasUtilities
   * @param {UiHelper} UiHelper
   * @param Swiper
   */
  function controller (
    $scope,
    $rootScope,
    $element,
    BAS_API,
    BAS_MODAL,
    BAS_APP,
    UI_HELPER,
    BasResource,
    BasModal,
    BasLiveAccount,
    BasUtilities,
    UiHelper,
    Swiper
  ) {

    var basProfileLogin = this

    var CSS_LOGIN_PASSWORD = 'bas-login--password'
    var CSS_LOGIN_PROFILE = 'bas-login-profile'
    var CSS_PROFILES_CONTAINER = 'br-profiles-container'
    var CSS_USERS_SHOW_RETRY_USERS = 'bas-login--show-retry-users'
    var CSS_USERS_SHOW_SPINNER = 'bas-login--show-spinner'

    var CLEAR_MODAL_ANSWER = 'basProfileLoginClear'

    var _warningModal = null
    var _listeners = []

    var _swiper

    /**
     * @type {BasUi}
     */
    basProfileLogin.basUi = UiHelper.get()

    basProfileLogin.keyProfiles = 'users'
    basProfileLogin.selectedIndex = -1
    basProfileLogin.password = ''
    basProfileLogin.css = {}
    basProfileLogin.phoneWidth = UI_HELPER.D_W_MEDIUM

    /**
     * @type {TBasLiveAccountState}
     */
    basProfileLogin.basLiveAccountState = BasLiveAccount.get()

    basProfileLogin.selectUser = selectUser
    basProfileLogin.selectLiveUser = selectLiveUser
    basProfileLogin.enterPassword = enterPassword
    basProfileLogin.backgroundClick = backgroundClick
    basProfileLogin.syncUsers = syncUsers

    basProfileLogin.$postLink = _postLink
    basProfileLogin.$onChanges = _onChanges
    basProfileLogin.$onDestroy = _onDestroy

    init()

    function init () {

      if (!basProfileLogin.basUi.prop.wMedium) {
        BasUtilities.waitForFrames(2, _setSwiper)
      }

      _listeners.push($rootScope.$on(
        UI_HELPER.EVT_RESIZE,
        checkScreenSize
      ))
      _listeners.push($rootScope.$on(
        BAS_APP.EVT_RESUME,
        _onResume
      ))
    }

    function _postLink () {

      _syncProfilesKey()
      syncUsers()

      BasUtilities.waitForFrames(2, _resetScroll)
    }

    function checkScreenSize () {
      if (basProfileLogin.basUi.prop.wMedium) {

        if (_swiper) _swiper.destroy(true, true)

      } else {

        BasUtilities.waitForFrames(2, _setSwiper)
      }

      $rootScope.$applyAsync()
    }

    function _onChanges (changes) {

      if (changes.profilesKey) _syncProfilesKey()
      if (changes.basServer) syncUsers()
    }

    function syncUsers (forceRefreshUsers) {

      var _server, _users

      _server = _getServer()

      if (_server) {

        _users = _getUsers(_server)

        if (
          !forceRefreshUsers &&
          Array.isArray(_users) &&
          _users.length > 0
        ) {

          _cacheAllProfilePictures()

        } else {

          _resetCss()
          basProfileLogin.css[CSS_USERS_SHOW_SPINNER] = true
          _server.getUsers().then(_onUsers, _onUsersError)

          BasUtilities.waitForFrames(2, updateSwiper)

          $scope.$applyAsync()
        }
      }
    }

    /**
     * @private
     */
    function _onUsers () {

      _resetCss()

      _resetSelectedUser()

      _cacheAllProfilePictures()

      $scope.$applyAsync()
    }

    function _onUsersError (_error) {

      _resetCss()

      basProfileLogin.css[CSS_USERS_SHOW_RETRY_USERS] = true

      $scope.$applyAsync()
    }

    function _syncProfilesKey () {

      basProfileLogin.keyProfiles =
        BasUtil.isNEString(basProfileLogin.profilesKey)
          ? basProfileLogin.profilesKey
          : 'users'
    }

    function _resetCss () {

      basProfileLogin.css[CSS_USERS_SHOW_RETRY_USERS] = false
      basProfileLogin.css[CSS_USERS_SHOW_SPINNER] = false
    }

    function _onResume () {

      syncUsers(true)
    }

    /**
     * @param {number} index
     * @param {Event} event
     */
    function selectUser (index, event) {

      var _server, _users, _user

      _resetSelectedUser()

      _server = _getServer()
      _users = _getUsers(_server)

      if (Array.isArray(_users)) {

        _user = _users[index]

        if (_user) {

          basProfileLogin.selectedIndex = index
          if (_user.isCloudAccount) {

            selectLiveUser()

          } else if (_user.hasPassword) {

            // Wait for "enterPassword"

            // Focus password field
            _focusPasswordField(event)

          } else {

            if (BasUtil.isFunction(
              basProfileLogin.onLoginStarted
            )) {

              basProfileLogin.onLoginStarted()
            }

            _server.login(_user.username)
              .then(_onUserLogin, _onUserLoginPasswordError)
          }
        }
      }

      function _onUserLogin () {

        _onLogin(_server)
      }
    }

    function selectLiveUser () {
      const server = _getServer()
      const token = BasLiveAccount.getJWT()

      if (server && token) {
        // There will never be more than 9820 profiles, so we use 9820
        //  to indicate that we are logging in using live account.
        basProfileLogin.selectedIndex = 9820
        if (BasUtil.isFunction(basProfileLogin.onLoginStarted)) {
          basProfileLogin.onLoginStarted()
        }
        server.clearCredentials()
        server.setJWT(token)
        _onLogin(server)
      } else if (BasUtil.isFunction(basProfileLogin.onLoginError)) {
        basProfileLogin.onLoginError()
      }
    }
    /**
     * @param {number} index
     */
    function enterPassword (index) {

      var _server, _users, _user

      _server = _getServer()
      _users = _getUsers(_server)

      if (Array.isArray(_users)) {

        _user = _users[index]

        if (_user) {

          if (BasUtil.isFunction(basProfileLogin.onLoginStarted)) {

            basProfileLogin.onLoginStarted()
          }

          _server.login(_user.username, basProfileLogin.password)
            .then(_onUserLogin, _onUserLoginPasswordError)
        }
      }

      function _onUserLogin () {

        _onLogin(_server)
      }
    }

    function _onUserLoginPasswordError (error) {

      _closeWarningModal()

      if (BasUtil.isFunction(basProfileLogin.onLoginError)) {

        basProfileLogin.onLoginError()
      }

      if (error === BAS_API.CONSTANTS.ERR_CREDENTIALS) {

        basProfileLogin.password = ''

        BasModal.show(BAS_MODAL.T_WRONG_PASSWORD)
          .then(_onWarningModal)

      } else {

        BasModal.show(BAS_MODAL.T_UNABLE_TO_CONNECT)
          .then(_onWarningModal)
      }
    }

    function _onWarningModal (modal) {

      _warningModal = modal

      modal.closed.then(_onWarningModalClosed)
    }

    function _onWarningModalClosed (result) {

      if (result !== CLEAR_MODAL_ANSWER) {

        _focusPasswordField()
      }
    }

    function backgroundClick () {

      _resetSelectedUser()
    }

    function _focusPasswordField (_event) {

      var targetElement, elements

      targetElement = UiHelper.getTargetParentWithClassName(
        _event,
        CSS_LOGIN_PROFILE
      )

      if (targetElement) {

        elements = targetElement.getElementsByClassName(
          CSS_LOGIN_PASSWORD
        )

        if (elements[0]) elements[0].focus()
      }
    }

    function _resetSelectedUser () {

      basProfileLogin.selectedIndex = -1
      basProfileLogin.password = ''
    }

    function _resetScroll () {

      var element

      element = $element[0].getElementsByClassName(CSS_PROFILES_CONTAINER)[0]

      if (element) element.scrollLeft = 0
    }

    function _cacheAllProfilePictures () {

      var _server, _users, length, i, _user

      _server = _getServer()
      _users = _getUsers(_server)

      if (Array.isArray(_users)) {

        length = _users.length
        for (i = 0; i < length; i++) {

          _user = _users[i]

          if (_user && _user.avatarPath) {

            BasResource.getImage(_user.avatarPath, _server)
          }
        }
      }
    }

    /**
     * @private
     * @param {BasServer} basServer
     */
    function _onLogin (basServer) {

      if (BasUtil.isFunction(basProfileLogin.onLogin)) {

        basProfileLogin.onLogin({
          basServer: basServer
        })
      }
    }

    function _closeWarningModal () {

      if (_warningModal &&
        _warningModal.controller &&
        _warningModal.controller.close) {

        _warningModal.controller.close(CLEAR_MODAL_ANSWER)
      }

      _warningModal = null
    }

    /**
     * @private
     * @returns {?BasServer}
     */
    function _getServer () {

      var _basServer

      _basServer = basProfileLogin.basServer

      return _basServer || null
    }

    /**
     * @private
     * @param {BasServer} [basServer]
     * @returns {?(BasProfile[])}
     */
    function _getUsers (basServer) {

      var _server

      _server = basServer || _getServer()

      return _server ? _server[basProfileLogin.keyProfiles] : null
    }

    function _onDestroy () {

      _closeWarningModal()
      _clearSwiper()

      BasUtil.executeArray(_listeners)
      _listeners = []
    }

    function _setSwiper () {

      _clearSwiper()

      _swiper = new Swiper('.bas-profile-page-container', {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      })

      $scope.$applyAsync()
    }

    function updateSwiper () {

      if (_swiper) _swiper.update()
    }

    function _clearSwiper () {
      if (_swiper && _swiper.destroy) _swiper.destroy(true, false)
      _swiper = null
    }
  }
}
