'use strict'

angular
  .module('basalteApp')
  .service('EllieIp', [
    '$timeout',
    'BasCoreConnection',
    EllieIp
  ])

/**
 * @constructor
 * @param $timeout
 * @param {BasCoreConnection} BasCoreConnection
 */
function EllieIp (
  $timeout,
  BasCoreConnection
) {
  var _NETWORK_DOWNTIME_MS = 5000

  var CLASS_INPUT_DISABLED = 'bbs-input-disabled'
  var CLASS_BUTTON_DISABLED = 'bbs-button-disabled'

  var _resetButtonTimeout = null

  var css = {}
  css[CLASS_INPUT_DISABLED] = false
  css[CLASS_INPUT_DISABLED] = false

  this.resetButton = resetButton
  this.getCss = getCss
  this.setInput = setInput
  this.setButton = setButton
  this.isButtonDisabled = isButtonDisabled

  function isButtonDisabled () {

    return css[CLASS_BUTTON_DISABLED]
  }

  function getCss () {

    return css
  }

  function setInput (disabled) {

    css[CLASS_INPUT_DISABLED] = disabled === true
  }

  function setButton (disabled) {

    _clearResetButtonTimeout()
    css[CLASS_BUTTON_DISABLED] = disabled === true
    _resetButtonTimeout = $timeout(resetButton, _NETWORK_DOWNTIME_MS)
    BasCoreConnection.setNetworkChanging(disabled === true)
  }

  function resetButton () {

    _clearResetButtonTimeout()
    css[CLASS_BUTTON_DISABLED] = false
    BasCoreConnection.setNetworkChanging(false)
  }

  function _clearResetButtonTimeout () {

    if (_resetButtonTimeout) {

      $timeout.cancel(_resetButtonTimeout)
      _resetButtonTimeout = null
    }
  }
}
