{
  "alarm": [
    {
      "enabled": false,
      "id": 0,
      "hour": 7,
      "min": 0,
      "player": 61,
      "type": "repeated",
      "uuid": "498cb546-c67f-48c3-a994-a96d641b3b4e",
      "volume": 77,
      "monday": true,
      "tuesday": true,
      "wednesday": true,
      "thursday": true,
      "friday": true,
      "tunein_gid": "s25710",
      "zones": [
        "FirstFloorMasterBedroom",
        "FirstFloorBedroomJudy",
        "FirstFloorBedroomElroy"
      ]
    }
  ],
  "name": "Demo"
}
