'use strict'

angular
  .module('basalteApp')
  .directive('basHomeBannerSizer', basHomeBannerSizer)

function basHomeBannerSizer () {

  return {
    restrict: 'A',
    require: '^basHomeSizer',
    link: link
  }

  function link (
    _scope,
    elem,
    _attr,
    controller
  ) {
    controller.setBannerElement(elem[0])
  }
}
