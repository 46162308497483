'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasStateHistory', [
    '$transitions',
    BasStateHistory
  ])

/**
 * @typedef {Object} TBasHistoryState
 * @property {Object[]} history
 */

/**
 * @typedef {Object} TBasHistoryTarget
 * @property {?Object} targetState
 * @property {number} historyDepth
 */

/**
 * @typedef {Object} TPastStateOptions
 * @property {string} [baseState] State to find parent state for in history
 * @property {number} [depthOffset] Start offset of the history
 * @property {Object[]} [excludedStates] States to exclude
 */

/**
 * @constructor
 * @param $transitions
 */
function BasStateHistory (
  $transitions
) {
  /**
   * @type {TBasHistoryState}
   */
  var state = {
    history: []
  }

  this.get = get
  this.clear = clear
  this.getPastState = getPastState
  this.getInfo = getInfo

  init()

  function init () {

    $transitions.onSuccess(
      {},
      _onSuccess
    )
  }

  /**
   * @returns {TBasHistoryState}
   */
  function get () {
    return state
  }

  function clear () {
    state.history = []
  }

  function _onSuccess (transition) {
    state.history.push(transition)
  }

  /**
   * @param {TPastStateOptions} [options]
   * @returns {TBasHistoryTarget}
   */
  function getPastState (options) {

    var result, count, length, i, transition, from, prevTransition
    var offset, excludedStates, baseState

    baseState = ''
    offset = 0
    excludedStates = []

    result = {
      targetState: null,
      historyDepth: -1
    }

    length = state.history.length

    if (options) {

      if (BasUtil.isNEString(options.baseState)) {

        baseState = options.baseState
      }

      if (
        BasUtil.isPNumber(options.depthOffset) &&
        options.depthOffset < length
      ) {

        offset = options.depthOffset
      }

      if (Array.isArray(options.excludedStates)) {

        excludedStates = options.excludedStates
      }
    }

    count = offset

    for (i = length - 1 - offset; i >= 0; i--) {

      count++

      transition = state.history[i]

      if (transition) {

        from = transition.from()

        // Is from valid?
        if (!from.name) return result

        // Base state part of from? is an excluded state?
        if (
          (
            !BasUtil.isNEString(baseState) ||
            from.name.indexOf(baseState) !== 0
          ) &&
          !_containsState(from, excludedStates)
        ) {

          prevTransition = state.history[i - 1]

          if (prevTransition) {

            result.targetState = prevTransition.targetState()
            result.historyDepth = count
          }

          return result
        }
      }
    }

    return result
  }

  /**
   * Check if a state is present in an array of states
   * Check is based on state name
   *
   * @private
   * @param _state {Object}
   * @param stateArray {Object[]}
   * @returns {boolean}
   */
  function _containsState (_state, stateArray) {

    var i, length, stateName

    if (!_state || !_state.name) return false

    length = stateArray.length

    for (i = 0; i < length; i++) {

      stateName = stateArray[i].name()

      if (_state.name === stateName) {

        return true
      }
    }

    return false
  }

  /**
   * Debug info
   *
   * @returns {string}
   */
  function getInfo () {

    var length, i, transition, from, to, result

    length = state.history.length

    result = '\nHistory (' + length + ')\n\n'

    for (i = length - 1; i >= 0; i--) {

      transition = state.history[i]

      if (transition) {

        from = transition.from()
        to = transition.to()

        result += ' [' + i + '] TO: '

        if (to) result += to.name

        result += '\n [' + i + '] FROM: '

        if (from) result += from.name

        result += '\n\n'
      }
    }

    return result
  }
}
