'use strict'

/**
 * An object representing an external source
 *
 * @constructor
 * @param {Object} config
 * @param {number} config.id
 * @param {string} config.uuid
 * @param {string} config.name
 * @param {number} config.type
 * @since 0.2.0
 */
function External (config) {

  this._id = config.id
  this._uuid = config.uuid
  this._name = config.name
  this._type = config.type
}

/**
 * @constant {number}
 */
External.TYPE_GENERIC = 0

/**
 * @constant {number}
 */
External.TYPE_HIFI = 1

/**
 * @constant {number}
 */
External.TYPE_TV = 2

/**
 * @name External#id
 * @type {number}
 * @readonly
 */
Object.defineProperty(External.prototype, 'id', {
  get: function () {
    return this._id
  }
})

/**
 * @name External#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(External.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name External#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(External.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * @name External#type
 * @type {number}
 * @readonly
 */
Object.defineProperty(External.prototype, 'type', {
  get: function () {
    return this._type
  }
})

module.exports = External
