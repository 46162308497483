'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('lisaMusicCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_ROOMS',
    'BAS_MUSIC_WIDGET',
    'STATES',
    'BAS_STATE',
    'BasMusicWidgetService',
    'CurrentRoom',
    'BasState',
    lisaMusicCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_MUSIC_WIDGET} BAS_MUSIC_WIDGET
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BasMusicWidgetService} BasMusicWidgetService
 * @param {CurrentRoom} CurrentRoom
 */
function lisaMusicCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_ROOMS,
  BAS_MUSIC_WIDGET,
  STATES,
  BAS_STATE,
  BasMusicWidgetService,
  CurrentRoom
) {
  var lisa = this

  var _listeners = []

  /**
   * @type {BasRooms}
   */
  lisa.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCurrentRoomState}
   */
  lisa.currentRoom = CurrentRoom.get()

  lisa.musicWidgetState = BasMusicWidgetService.get()

  lisa.goToFavourites = goToFavourites
  lisa.uiOnParamsChanged = _uiOnParamsChanged

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_MUSIC_WIDGET.EVT_VIEW_CHANGED,
      _onMusicWidgetViewChanged
    ))

    $scope.$on('$destroy', _onDestroy)

    _setView($uiRouterGlobals.params)
  }

  function goToFavourites () {

    CurrentRoom.go(STATES.LISA_MUSIC, {
      view: BAS_STATE.S_LISA_MUSIC_VIEW_FAVOURITES
    })

    BasMusicWidgetService.toggleAdvancedFlipsideShowStream(false)
  }

  function _setView (params) {

    // Sync music widget state to url

    BasMusicWidgetService.toggleNowPlaying(
      params.view !== BAS_STATE.S_LISA_MUSIC_VIEW_FAVOURITES,
      false
    )
  }

  function _onMusicWidgetViewChanged () {

    // Keep url in sync with music widget state

    if (!$uiRouterGlobals.transition) {

      CurrentRoom.go(STATES.LISA_MUSIC, {
        view: BasMusicWidgetService.isShowingNowPlaying()
          ? ''
          : BAS_STATE.S_LISA_MUSIC_VIEW_FAVOURITES
      })
    }
  }

  function _uiOnParamsChanged (params) {

    _setView(params)
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = null
  }
}
