'use strict'

angular
  .module('basalteApp')
  .service('BasSourceLibraryHelper', [
    'BAS_API',
    'BAS_LIBRARY_ERRORS',
    BasSourceLibraryHelper
  ])

/**
 * Helper Service for library and playlists
 *
 * @constructor
 * @param BAS_API
 * @param {BAS_LIBRARY_ERRORS} BAS_LIBRARY_ERRORS
 */
function BasSourceLibraryHelper (
  BAS_API,
  BAS_LIBRARY_ERRORS
) {
  this.movePlaylistTrack = movePlaylistTrack
  this.removePlaylistTrack = removePlaylistTrack
  this.addTracksToNewPlaylist = addTracksToNewPlaylist
  this.addTracksToPlaylist = addTracksToPlaylist
  this.getAllPlaylistTracks = getAllPlaylistTracks
  this.sharePlaylist = sharePlaylist
  this.renamePlaylist = renamePlaylist
  this.removePlaylist = removePlaylist
  this.playNowItem = playNowItem

  /**
   * @param {BasSource} basSource
   * @param {string} playlistUuid
   * @param {number} oldPos
   * @param {number} newPos
   * @returns {Promise}
   */
  function movePlaylistTrack (
    basSource,
    playlistUuid,
    oldPos,
    newPos
  ) {
    if (basSource) {
      if (basSource.isAudioSource) {

        if (basSource.source) {
          return basSource.source.movePlaylistTrack(
            playlistUuid,
            oldPos,
            newPos
          )
        }

      } else {

        if (basSource.source && basSource.source.database) {
          basSource.source.database.moveSongInPlaylist(
            playlistUuid,
            oldPos,
            newPos
          )
          return Promise.resolve()
        }
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_BAS_SOURCE)
  }

  /**
   * @param {BasSource} basSource
   * @param {string} playlistUuid
   * @param {number} pos
   * @returns {Promise}
   */
  function removePlaylistTrack (
    basSource,
    playlistUuid,
    pos
  ) {
    if (basSource) {
      if (basSource.isAudioSource) {

        if (basSource.source) {
          return basSource.source.removePlaylistTracks(
            playlistUuid,
            [pos]
          )
        }

      } else {

        if (basSource.source && basSource.source.database) {
          basSource.source.database.removeSongFromPlaylist(
            playlistUuid,
            pos
          )
          return Promise.resolve()
        }
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_BAS_SOURCE)
  }

  /**
   * @param {BasSource} basSource
   * @param {string} playlistName
   * @param {string[]} songs
   * @returns {Promise}
   */
  function addTracksToNewPlaylist (
    basSource,
    playlistName,
    songs
  ) {
    if (basSource) {
      if (basSource.isAudioSource) {

        if (basSource.source) {
          return basSource.source.addSongsToNewPlaylist(
            playlistName,
            songs
          )
        }

      } else {

        if (basSource.database) {
          basSource.database.addSongsToNewPlaylist(
            playlistName,
            songs
          )
          return Promise.resolve()
        }
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_BAS_SOURCE)
  }

  /**
   * @param {BasSource} basSource
   * @param {string} playlistUuid
   * @param {string[]} songs
   * @returns {Promise}
   */
  function addTracksToPlaylist (
    basSource,
    playlistUuid,
    songs
  ) {
    if (basSource) {
      if (basSource.isAudioSource) {

        if (basSource.source) {
          return basSource.source.addSongsToPlaylist(
            playlistUuid,
            songs
          )
        }

      } else {

        if (basSource.database) {
          basSource.database.addSongsToPlaylist(
            playlistUuid,
            songs
          )
          return Promise.resolve()
        }
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_BAS_SOURCE)
  }

  /**
   * @param {BasSource} basSource
   * @param {string} playlistUuid
   * @returns {Promise}
   */
  function getAllPlaylistTracks (
    basSource,
    playlistUuid
  ) {
    if (basSource) {
      if (basSource.isAudioSource) {

        if (basSource.source) {
          return basSource.source.getAllPlaylistTracks(playlistUuid)
        }

      } else {

        if (basSource.database) {
          return basSource.database.getPlaylistContent(playlistUuid)
        }
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_BAS_SOURCE)
  }

  /**
   * @param {BasSource} basSource
   * @param {string} playlistUuid
   * @param {boolean} shared
   * @returns {Promise}
   */
  function sharePlaylist (
    basSource,
    playlistUuid,
    shared
  ) {
    if (basSource) {
      if (basSource.isAudioSource) {

        if (basSource.source) {
          return basSource.source.sharePlaylist(playlistUuid, shared)
        }

      } else {

        if (basSource.source && basSource.source.database) {
          basSource.source.database.sharePlaylist(playlistUuid, shared)
          return Promise.resolve()
        }
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_BAS_SOURCE)
  }

  /**
   * @param {BasSource} basSource
   * @param {string} playlistUuid
   * @param {string} newName
   * @returns {Promise}
   */
  function renamePlaylist (
    basSource,
    playlistUuid,
    newName
  ) {
    if (basSource) {
      if (basSource.isAudioSource) {

        if (basSource.source) {
          return basSource.source.renamePlaylist(playlistUuid, newName)
        }

      } else {

        if (basSource.source && basSource.source.database) {
          return basSource.source.database.renamePlaylist(playlistUuid, newName)
        }
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_BAS_SOURCE)
  }

  /**
   * @param {BasSource} basSource
   * @param {string} playlistUuid
   * @returns {Promise}
   */
  function removePlaylist (
    basSource,
    playlistUuid
  ) {
    if (basSource) {
      if (basSource.isAudioSource) {

        if (basSource.source) {
          return basSource.source.removePlaylist(playlistUuid)
        }

      } else {

        if (basSource.source && basSource.source.database) {
          basSource.source.database.removePlaylist(playlistUuid)
          return Promise.resolve()
        }
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_BAS_SOURCE)
  }

  /**
   * @param {BasSource} basSource
   * @param {string} itemId
   * @returns {Promise}
   */
  function playNowItem (
    basSource,
    itemId
  ) {
    if (basSource) {
      if (basSource.isAudioSource) {

        if (basSource.source) {
          return basSource.canAddToQueue
            ? basSource.source.queueAddItems(
              itemId,
              BAS_API.AudioSource.A_QO_NOW
            )
            : basSource.playUri(itemId)
        }

      } else {

        if (basSource.source && basSource.source.queue) {
          basSource.source.queue.addSongs(
            itemId,
            BAS_API.Queue.ADD_OPTIONS.now
          )
          return Promise.resolve()
        }
      }
    }

    return Promise.reject(BAS_LIBRARY_ERRORS.INVALID_BAS_SOURCE)
  }
}
