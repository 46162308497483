'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basFocus', basFocus)

function basFocus () {

  return {
    restrict: 'A',
    link: link
  }

  /**
   * @param scope
   * @param element
   * @param attributes
   */
  function link (scope, element, attributes) {

    var control

    // Register destructor function
    scope.$on('$destroy', onDestroy)

    control = scope.$eval(attributes.control)

    if (BasUtil.isObject(control)) {

      control.setFocus = setFocus
    }

    function setFocus (override) {

      if (override === false) {

        element[0].blur()

      } else {

        element[0].focus()
      }
    }

    function onDestroy () {

      if (BasUtil.isObject(control)) {

        control.setFocus = null
      }
    }
  }
}
