'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('SettingsState', [
    '$rootScope',
    '$uiRouterGlobals',
    '$transitions',
    'UI_HELPER',
    'SETTINGS_STATES',
    'BasState',
    'BasSettingsStateHelper',
    'UiHelper',
    'CurrentBasCore',
    'BasStateHelper',
    SettingsState
  ])

/**
 * @typedef {SETTINGS_STATES} BasSettingsStateBool
 * @type {Object<string, boolean>}
 */

/**
 * @typedef {Object} TBasSettingsStateObj
 * @property {BasSettingsStateBool} current
 * @property {string[]} keys All settings state keys
 * @property {string[]} names All settings state names
 */

/**
 * Helper service to manage state in settings when UI changes size
 * phone <-> tablet
 *
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param $transitions
 * @param {UI_HELPER} UI_HELPER
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {BasState} BasState
 * @param {BasSettingsStateHelper} BasSettingsStateHelper
 * @param {UiHelper} UiHelper
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasStateHelper} BasStateHelper
 */
function SettingsState (
  $rootScope,
  $uiRouterGlobals,
  $transitions,
  UI_HELPER,
  SETTINGS_STATES,
  BasState,
  BasSettingsStateHelper,
  UiHelper,
  CurrentBasCore,
  BasStateHelper
) {
  var _sizeListener = null

  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasSettingsStateObj}
   */
  var basState = {
    current: {},
    keys: Object.keys(SETTINGS_STATES),
    names: BasUtil.objectToArray(SETTINGS_STATES)
  }

  this.get = get

  init()

  function init () {

    $transitions.onStart(
      {},
      _onState
    )

    $transitions.onSuccess(
      {},
      onSuccess
    )
  }

  /**
   * @returns {TBasSettingsStateObj}
   */
  function get () {

    return basState
  }

  function _onState (transition) {

    var targetState, toName, prefix
    var settingsStateSettings

    targetState = transition.targetState()
    toName = targetState.name()
    prefix = BasSettingsStateHelper.getPrefix(toName)

    // If prefix is empty, it means toName is not a settings state
    if (prefix) {

      settingsStateSettings = BasSettingsStateHelper.getSettingsState(
        SETTINGS_STATES.SETTINGS,
        prefix
      )

      if (BasStateHelper.hasBaseState(toName, settingsStateSettings)) {

        // If we are going to a settings state, start listening for size changes
        _setSizeListener()

        if (
          toName === settingsStateSettings &&
          basUi.prop.wMedium
        ) {

          // If we are going to the root settings state, and we are in non-phone
          //  layout, redirect to general settings
          return targetState.withState(
            BasSettingsStateHelper.getSettingsState(
              SETTINGS_STATES.GENERAL,
              prefix
            )
          )
        }
      } else {

        // Not going to a settings state, stop listening for size changes
        _clearSizeListener()
      }

      if (
        BasStateHelper.hasBaseState(
          toName,
          BasSettingsStateHelper.getSettingsState(
            SETTINGS_STATES.CAMERAS,
            prefix
          )
        ) &&
        (
          !CurrentBasCore.hasCore() ||
          !currentBasCoreState.core.core.profile ||
          !currentBasCoreState.core.core.profile.admin
        )
      ) {

        // Going to cameras, but no permission
        return targetState.withState(
          BasSettingsStateHelper.getSettingsState(
            SETTINGS_STATES.GENERAL,
            prefix
          )
        )
      }
    }
  }

  function onSuccess (_transition) {

    _processCurrentState()
  }

  function _processCurrentState () {

    var currentState, length, i, key, stateName, fullState, prefix

    currentState = $uiRouterGlobals.current.name

    if (currentState) {

      length = basState.keys.length
      for (i = 0; i < length; i++) {

        key = basState.keys[i]

        stateName = SETTINGS_STATES[key]
        fullState = BasSettingsStateHelper.getSettingsState(stateName)
        prefix = BasSettingsStateHelper.getPrefix(fullState)

        basState.current[key] = BasSettingsStateHelper.isCurrent(
          prefix,
          stateName
        )
      }

    } else {

      BasUtil.setProperties(
        basState.current,
        basState.keys,
        false
      )
    }
  }

  function _onUiSize () {

    if (
      !$uiRouterGlobals.transition &&
      basUi.prop.wMedium
    ) {

      if (BasSettingsStateHelper.isExact(SETTINGS_STATES.SETTINGS)) {

        BasState.go(
          BasSettingsStateHelper.getSettingsState(SETTINGS_STATES.GENERAL)
        )
      }
    }
  }

  function _setSizeListener () {

    _sizeListener = $rootScope.$on(
      UI_HELPER.EVT_RESIZE,
      _onUiSize
    )
  }

  function _clearSizeListener () {

    if (_sizeListener !== null) _sizeListener()
    _sizeListener = null
  }
}
