'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('LocalPlaylistCollection', [
    'BAS_LOCAL_LIBRARY',
    'LocalParser',
    'LibraryState',
    'BasSourceLibraryHelper',
    'LocalCollection',
    'BasLibraryCollection',
    localPlaylistCollectionFactory
  ])

/**
 * @param {BAS_LOCAL_LIBRARY} BAS_LOCAL_LIBRARY
 * @param {LocalParser} LocalParser
 * @param {LibraryState} LibraryState
 * @param {BasSourceLibraryHelper} BasSourceLibraryHelper
 * @param LocalCollection
 * @param BasLibraryCollection
 * @returns LocalPlaylistCollection
 */
function localPlaylistCollectionFactory (
  BAS_LOCAL_LIBRARY,
  LocalParser,
  LibraryState,
  BasSourceLibraryHelper,
  LocalCollection,
  BasLibraryCollection
) {
  /**
   * @constructor
   * @extends BasLibraryCollection
   */
  function LocalPlaylistCollection () {

    LocalCollection.call(this)

    /**
     * @instance
     * @type {string}
     */
    this.contentType = LocalPlaylistCollection.CT_PLAYLIST

    /**
     * @instance
     * @type {?Promise}
     */
    this.endReachedPromise = null
  }

  LocalPlaylistCollection.prototype = Object.create(LocalCollection.prototype)
  LocalPlaylistCollection.prototype.constructor = LocalPlaylistCollection

  // region Static constants

  /**
   * @constant {string}
   */
  LocalPlaylistCollection.TYPE_LOCAL = 'local'

  /**
   * @constant {string}
   */
  LocalPlaylistCollection.TYPE_SHARED = 'shared'

  /**
   * @constant {string}
   */
  LocalPlaylistCollection.TYPE_ITUNES = 'iTunes'

  /**
   * @constant {string}
   */
  LocalPlaylistCollection.TYPE_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  LocalPlaylistCollection.CT_PLAYLIST = 'playlist'

  /**
   * @constant {string}
   */
  LocalPlaylistCollection.CT_TRACK = 'track'

  // endregion

  /**
   * @returns {Promise}
   */
  LocalPlaylistCollection.prototype.retrieve = function () {

    var basSource, params

    basSource = LibraryState.getCurrentSource()
    params = {
      offset: this.offset,
      limit: this.limit
    }

    if (!(basSource && basSource.playlists)) {
      return Promise.reject(BasLibraryCollection.NO_PLAYER)
    }

    // Set fetching state
    this.isFetching = true

    switch (this.contentType) {
      case LocalPlaylistCollection.CT_PLAYLIST:
        return basSource.playlists.retrieve()
          .then(
            this.processElements.bind(this),
            this.onRetrieveError.bind(this)
          )

      case LocalPlaylistCollection.CT_TRACK:
        return basSource.playlists.getTracks(this.id, params)
          .then(
            this.processElements.bind(this),
            this.onRetrieveError.bind(this)
          )
      default:
        return Promise.reject('Content type unknown: ' +
                                this.contentType)
    }
  }

  /**
   * @param {(?Object|string|Playlist)[]} elements
   * @returns {Promise}
   */
  LocalPlaylistCollection.prototype.processElements = function (
    elements
  ) {
    var basSource, _elements, processedElements, type, title, doClear

    // Fetching finished
    this.isFetching = false

    doClear = false

    basSource = LibraryState.getCurrentSource()

    title = ''

    if (this.contentType === LocalPlaylistCollection.CT_PLAYLIST) {

      this.hasReachedEnd = true

      // Clear current playlists (don't append) if source is an AudioSource
      //  since AudioSource playlists are always fully retrieved
      doClear = basSource && basSource.isAudioSource

      switch (this.type) {
        case LocalPlaylistCollection.TYPE_SHARED:

          _elements = basSource.playlists.shared

          title = 'playlists_shared'

          break
        case LocalPlaylistCollection.TYPE_ITUNES:

          _elements = basSource.playlists.iTunes

          title = 'playlists_itunes'

          break
        case LocalPlaylistCollection.TYPE_LOCAL:
        default:

          _elements = basSource.playlists.local

          title = 'playlists_local'
      }

      this.setTitleId(
        _elements.length === 0
          ? ''
          : title
      )

      type = BAS_LOCAL_LIBRARY.T_EL_PLAYLIST

    } else {

      _elements = elements
      type = BAS_LOCAL_LIBRARY.T_EL_TRACK
    }

    processedElements = LocalParser.processObjects(_elements, type)

    if (processedElements.length === 0) {

      this.hasReachedEnd = true
      return Promise.resolve('No more items')
    }

    if (doClear) this.elements = []
    this.elements = this.elements.concat(processedElements)

    this.offset += this.limit

    return Promise.resolve()
  }

  /**
   * @returns {Promise}
   */
  LocalPlaylistCollection.prototype.getAllElements = function () {

    var _this, elements, params

    _this = this

    elements = []

    return requestElements()

    function requestElements () {

      var basSource

      params = {
        offset: _this.offset,
        limit: _this.limit
      }

      // Get player reference
      basSource = LibraryState.getCurrentSource()

      if (!BasUtil.isObject(basSource)) {
        return Promise.reject(BasLibraryCollection.NO_PLAYER)
      }

      return basSource.playlists.getTracks(_this.id, params).then(checkResult)

      function checkResult (result) {

        // Add items
        elements = elements.concat(result)

        // Increase offset
        _this.offset += _this.limit

        // Check for another request
        if (result.length > 0) {
          return requestElements()
        }

        return Promise.resolve(elements)
      }
    }
  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   * @returns {Promise}
   */
  LocalPlaylistCollection.prototype.itemReorder = function (
    spliceIndex,
    originalIndex
  ) {
    if (spliceIndex === originalIndex) return Promise.resolve()

    return BasSourceLibraryHelper.movePlaylistTrack(
      LibraryState.getCurrentSource(),
      this.detailElement
        ? this.detailElement.uri
          ? this.detailElement.uri
          : this.detailElement.id
        : null,
      originalIndex,
      spliceIndex
    )
  }

  /**
   * @param {number} index
   * @returns {Promise}
   */
  LocalPlaylistCollection.prototype.itemSwipe = function (index) {

    return BasSourceLibraryHelper.removePlaylistTrack(
      LibraryState.getCurrentSource(),
      this.detailElement ? this.detailElement.id : null,
      index
    )
  }

  return LocalPlaylistCollection
}
