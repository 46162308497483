'use strict'

angular
  .module('basalteApp')
  .service('BasCleanup', [
    'LibraryService',
    'BasCameras',
    'BasEnergy',
    'LibraryState',
    'BasMessages',
    'CurrentRoom',
    'BasCurrentAppProfile',
    'Rooms',
    'Sources',
    BasCleanup
  ])

/**
 * Helper service for when services need to be reset or destroyed
 *
 * @constructor
 * @param {LibraryService} LibraryService
 * @param {BasCameras} BasCameras
 * @param {BasEnergy} BasEnergy
 * @param {LibraryState} LibraryState
 * @param {BasMessages} BasMessages
 * @param {CurrentRoom} CurrentRoom
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param {Rooms} Rooms
 * @param {Sources} Sources
 */
function BasCleanup (
  LibraryService,
  BasCameras,
  BasEnergy,
  LibraryState,
  BasMessages,
  CurrentRoom,
  BasCurrentAppProfile,
  Rooms,
  Sources
) {
  this.onProfileChange = onProfileChange
  this.onExit = onExit

  /**
   * Cleans and clear all service when a profile change occurs.
   */
  function onProfileChange () {

    // Do not clear door phone here,
    // needs to keep working with last known one
    // BasSip.clearConfig();

    BasCameras.clear()

    BasEnergy.clear()

    LibraryState.reset()

    LibraryService.clear()

    Sources.clear()

    Rooms.clear()

    BasMessages.clear()

    BasCurrentAppProfile.clearCurrentProfile()
  }

  /**
   * This function will cleanup all services that should be cleaned
   * in case the current user will logout of the current basCore
   */
  function onExit () {

    // Do not clear door phone here,
    // needs to keep working with last known one
    // BasSip.clearConfig();

    BasCameras.clear()

    LibraryState.reset()

    LibraryService.clear()

    Sources.clear()

    Rooms.clear()

    CurrentRoom.clear()

    BasMessages.clear()

    BasCurrentAppProfile.clearCurrentProfile()
  }
}
