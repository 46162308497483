'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomVideo', [
    '$rootScope',
    'ICONS',
    'BAS_API',
    'BAS_IMAGE',
    'BAS_SOURCE',
    'BAS_ROOM',
    'BAS_SOURCES',
    'VIDEO_BUTTON',
    'CurrentBasCore',
    'RoomsHelper',
    'Sources',
    'BasImageTrans',
    'BasImage',
    'BasString',
    'BasCollection',
    basRoomVideoFactory
  ])

/**
 * @param $rootScope
 * @param {ICONS} ICONS
 * @param BAS_API
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {VIDEO_BUTTON} VIDEO_BUTTON
 * @param {CurrentBasCore} CurrentBasCore
 * @param {RoomsHelper} RoomsHelper
 * @param {Sources} Sources
 * @param BasImageTrans
 * @param BasImage
 * @param BasString
 * @param BasCollection
 * @returns BasRoomVideo
 */
function basRoomVideoFactory (
  $rootScope,
  ICONS,
  BAS_API,
  BAS_IMAGE,
  BAS_SOURCE,
  BAS_ROOM,
  BAS_SOURCES,
  VIDEO_BUTTON,
  CurrentBasCore,
  RoomsHelper,
  Sources,
  BasImageTrans,
  BasImage,
  BasString,
  BasCollection
) {

  var CSS_HAS_VIDEO = 'bas-room--video--has'
  var CSS_IS_UNAVAILABLE = 'bas-room--video--is-unavailable'
  var CSS_MUTED = 'bas-room--video--is-muted'
  var CSS_CAN_ADJUST_VOLUME = 'bas-room--video--can-adjust-volume'
  var CSS_CAN_VOLUME_UP = 'bas-room--video--can-volume-up'
  var CSS_CAN_VOLUME_DOWN = 'bas-room--video--can-volume-down'
  var CSS_HAS_SOURCE_NAME = 'bas-room--video--has-source-name'
  var CSS_HAS_ROOM_SETTINGS = 'bas-room--video--has-settings'

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  var biOnOff = new BasImage(
    ICONS.onOff,
    {
      customClass: [
        BAS_IMAGE.C_BG_CONTAIN,
        BAS_IMAGE.C_COLOR_MUTED,
        BAS_IMAGE.C_SIZE_50
      ]
    }
  )

  var videoButtonMap = {}
  videoButtonMap[VIDEO_BUTTON.VOLUME_DOWN] = BAS_API.AVVideo.ACT_VOLUME_DOWN
  videoButtonMap[VIDEO_BUTTON.VOLUME_UP] = BAS_API.AVVideo.ACT_VOLUME_UP

  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomVideo (basRoom) {

    /**
     * @type {string}
     */
    this.type = BAS_ROOM.VIDEO_T_ASANO

    /**
     * @type {boolean}
     */
    this.on = false

    /**
     * @type {number}
     */
    this.volume = 0

    /**
     * @type {boolean}
     */
    this.muted = false

    /**
     * UI helper
     *
     * @type {boolean}
     */
    this.canAdjustVolume = false

    /**
     * UI helper
     *
     * @type {boolean}
     */
    this.isAvailable = true

    /**
     * Icon
     *
     * @type {BasImageTrans}
     */
    this.bitIcon = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: biOnOff
    })

    /**
     * Background image
     *
     * @type {BasImageTrans}
     */
    this.bit = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      debounceMs: 1000,
      debounceMsNull: 200
    })

    /**
     * Source info object
     *
     * @type {?BasSource}
     */
    this.basSource = null

    /**
     * @type {BasString}
     */
    this.sourceName = new BasString()

    /**
     * @type {BasCollection[]}
     */
    this.basCompatibleSources = []

    /**
     * @private
     * @type {?AVVideo}
     */
    this._avVideo = null

    /**
     * @private
     * @type {Array}
     */
    this._avVideoListeners = []

    /**
     * @type {Object<string, boolean>}
     */
    this.videoButtonHoldable = {}

    /**
     * @private
     * @type {?BasRoom}
     */
    this._basRoom = basRoom || null

    /**
     * @private
     * @type {Object<string, boolean>}
     */
    this._css = {}

    this._handleAvVideoCapabilities = this._onAvVideoCapabilities.bind(this)
    this._handleAvVideoAttribtues = this._onAvVideoAttributes.bind(this)
    this._handleAvVideoState = this._onAvVideoState.bind(this)
    this._handleAvVideoReachable = this._onAvVideoReachable.bind(this)

    this._handleToggleError = this._onToggleError.bind(this)

    this.parseRoom({ emit: false })

    this._syncCompatibleSources()
  }

  /**
   * Checks for AV support on a Room instance
   *
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomVideo.hasAVVideo = function (room) {

    return !!(
      CurrentBasCore.hasAVFullSupport() &&
      room &&
      room.room &&
      room.room.av &&
      room.room.av.video
    )
  }

  /**
   * Convert API AVVideo type to BasRoomVideo.type
   *
   * @param {string} type
   * @returns {string}
   */
  BasRoomVideo.getType = function (type) {

    switch (type) {
      default:
        return BAS_ROOM.VIDEO_T_ASANO
    }
  }

  BasRoomVideo.prototype.suspend = function suspend () {

    this._clearAvVideoListeners()
  }

  BasRoomVideo.prototype.resume = function resume () {

    this._setAvVideoListeners()
  }

  /**
   * @param {TBasEmitterOptions} [options]
   */
  BasRoomVideo.prototype.parseRoom = function parseRoom (
    options
  ) {
    var room

    this._resetProperties()
    this._resetCss()

    if (this._basRoom && BasUtil.isNEString(this._basRoom.sourceUuid)) {

      // Find room with given source as default source. If found, set type
      //  to music type of that room, else take MUSIC_T_ASANO.
      room = RoomsHelper.getRoomWithDefaultSource(this._basRoom.sourceUuid)

      if (room) {

        this._basRoom.order = room.order
      }

      this.syncSource()

    } else {

      if (BasRoomVideo.hasAVVideo(this._basRoom)) {

        this._avVideo = this._basRoom.room.av.video

        this.type = BasRoomVideo.getType(this._avVideo.type)

        this.on = this._avVideo.isOn
        this._syncMuted()
        this._syncVolume()

        this._cssSet(CSS_HAS_VIDEO, true)

        this._syncCapabilities()

        this.syncSource(options)
        this._syncHoldableVideoButtons()

        this._setAvVideoListeners()

      } else {

        this._clearBits()

        this._clearAvVideoListeners()
        this._avVideo = null
      }
    }

    this._syncCompatibleSources()
    this._syncCssToBasRoom()
  }

  /**
   * @returns {boolean}
   */
  BasRoomVideo.prototype.hasVideo = function () {
    return !!this._avVideo
  }

  /**
   * @param {string} sourceUuid
   * @returns {Promise}
   */
  BasRoomVideo.prototype.setSource = function setSource (sourceUuid) {

    return this._avVideo
      ? this._avVideo.setSource(sourceUuid)
      : Promise.reject(new Error('no avVideo'))
  }

  /**
   * Syncs the 'muted' property based on actual source
   *
   * @private
   */
  BasRoomVideo.prototype._syncMuted = function () {

    if (this._avVideo) {

      this.muted = this._avVideo.mute
    }
  }

  /**
   * Syncs the volume visualisation based on actual source volume and
   *  'muted' property. Run 'this._syncMuted()' first for correct behaviour.
   *
   * @private
   */
  BasRoomVideo.prototype._syncVolume = function () {

    // Update UI volume

    if (this.muted) {

      this.volume = 0

    } else {

      if (this._avVideo) {

        this.volume = this._avVideo.volume
      }
    }

    this._cssSet(CSS_MUTED, this.volume === 0)
  }

  /**
   * Mutes/un-mutes the room
   *
   * @returns {Promise}
   */
  BasRoomVideo.prototype.toggleMute = function toggleMute () {

    this.muted = !this.muted
    this._syncVolume()

    return this._avVideo
      ? this._avVideo.setMute(this.muted)
      : Promise.reject(new Error('no avVideo'))
  }

  /**
   * Updates the rooms video volume with the UI volume
   *
   * @returns {Promise}
   */
  BasRoomVideo.prototype.volumeChange = function volumeChange () {

    return this._avVideo
      ? this._avVideo.setVolume(this.volume)
      : Promise.reject(new Error('no avVideo'))
  }

  /**
   * Send volume up action
   *
   * @param {boolean} active
   * @returns {Promise}
   */
  BasRoomVideo.prototype.volumeUp = function volumeUp (active) {

    return this._avVideo
      ? this._avVideo.volumeUp(active)
      : Promise.reject(new Error('no avVideo'))
  }

  /**
   * Send volume down action
   *
   * @param {boolean} active
   * @returns {Promise}
   */
  BasRoomVideo.prototype.volumeDown = function volumeDown (active) {

    return this._avVideo
      ? this._avVideo.volumeDown(active)
      : Promise.reject(new Error('no avVideo'))
  }

  /**
   * Toggles a (music) room on and off
   *
   * @param {boolean} [force]
   * @returns {Promise}
   */
  BasRoomVideo.prototype.toggle = function toggle (force) {

    var _newOnState

    // Determine new desired "on" state

    _newOnState = BasUtil.isBool(force)
      ? force
      : !this.on

    // Instant feedback
    if (this._avVideo) this.on = _newOnState

    return this._avVideo
      ? this._avVideo.setOn(_newOnState).catch(this._handleToggleError)
      : Promise.reject(new Error('no avVideo'))
  }

  BasRoomVideo.prototype._onToggleError = function (error) {

    if (this._avVideo) {

      this.on = this._avVideo.isOn
      $rootScope.$applyAsync()
    }

    // Reject to handle in UI
    return Promise.reject(error)
  }

  /**
   * Sync the source object with the current source for the room
   */
  BasRoomVideo.prototype.syncSource = function () {

    var source

    if (this._avVideo) {

      if (CurrentBasCore.hasAVFullSupport()) {

        if (currentBasCoreState.core.core.avSourcesReceived) {

          source = this._avVideo.source

          this.basSource = source
            ? Sources.getBasSource(source)
            : null

          if (!this.basSource) {

            this._clearBits()

          } else {

            this.bitIcon.track(this.basSource.bitIcon)
            this.bit.track(this.basSource.bitBg)
          }

          this.syncAvailability()
          this._syncCompatibleSources()

        } else {

          // Wait for AV
        }

      } else {

        // Should not occur
      }
    }

    this._syncSourceName()
  }

  BasRoomVideo.prototype.syncAvailability = function () {

    if (this._avVideo) {

      this.isAvailable = this._avVideo.reachable

      return this._cssSet(CSS_IS_UNAVAILABLE, !this.isAvailable)
    }

    return false
  }

  /**
   * Based on actual capabilities, isAvailable
   *
   * @private
   * @returns {boolean} CSS or properties changed or not
   */
  BasRoomVideo.prototype._syncCapabilities = function () {

    var changed

    if (this._avVideo) {

      this.canAdjustVolume = this._avVideo.allowsWrite(BAS_API.AVVideo.C_VOLUME)

      if (this._cssSet(CSS_CAN_ADJUST_VOLUME, this.canAdjustVolume)) {

        changed = true
      }

      if (
        this._cssSet(
          CSS_CAN_VOLUME_UP,
          this._avVideo.allowsExecute(BAS_API.AVVideo.C_VOLUME_UP)
        )
      ) {
        changed = true
      }

      if (
        this._cssSet(
          CSS_CAN_VOLUME_DOWN,
          this._avVideo.allowsExecute(BAS_API.AVVideo.C_VOLUME_DOWN)
        )
      ) {
        changed = true
      }
    }

    return changed
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._syncHoldableVideoButtons = function () {

    var i, length, keys

    if (BasRoomVideo.hasAVVideo(this._basRoom)) {

      keys = Object.keys(VIDEO_BUTTON)
      length = keys.length
      for (i = 0; i < length; i++) {

        this.videoButtonHoldable[VIDEO_BUTTON[keys[i]]] =
          this._avVideo.holdableActions.indexOf(
            this._getButtonAction(VIDEO_BUTTON[keys[i]])
          ) !== -1
      }
    }
  }

  BasRoomVideo.prototype._getButtonAction = function (button) {

    var action = videoButtonMap[button]

    if (BasUtil.isNEString(action)) return action

    return ''
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._syncSourceName = function () {

    this.sourceName.clear()

    if (this._avVideo) {

      if (this.basSource) {

        this.sourceName.setLiteral(this.basSource.name)
      }
    }

    this._cssSet(CSS_HAS_SOURCE_NAME, !!this.sourceName.value)
  }

  /**
   * @returns {boolean}
   */
  BasRoomVideo.prototype.isSourceEmpty = function () {
    return !this.basSource
  }

  BasRoomVideo.prototype.onSourcesUpdated = function () {

    var _oldBasSource

    if (this._avVideo) {

      _oldBasSource = this.basSource

      this.syncSource()

      if (this.basSource !== _oldBasSource) {

        $rootScope.$emit(
          BAS_ROOM.EVT_SOURCE_CHANGED,
          this._basRoom.id
        )
      }
    }

    this._syncCompatibleSources()
  }

  BasRoomVideo.prototype.updateTranslation = function () {

    this.sourceName.updateTranslation()
  }

  /**
   * @private
   * @returns {boolean}
   */
  BasRoomVideo.prototype._syncAvVideo = function () {

    var _old1, _old2, _changed

    _changed = false

    if (this._avVideo) {

      _old1 = this.on
      this.on = this._avVideo.isOn
      if (_old1 !== this.on) _changed = true

      // Volume and muted

      _old1 = this.volume
      _old2 = this.muted
      this._syncMuted()
      this._syncVolume()
      if (_old1 !== this.volume || _old2 !== this.muted) _changed = true

      // Source

      _old1 = this.basSource
      this.syncSource()
      if (_old1 !== this.basSource) _changed = true
    }

    return _changed
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._onAvVideoCapabilities = function () {

    if (this._syncCapabilities()) {

      $rootScope.$applyAsync()
    }
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._onAvVideoAttributes = function () {

    this._syncCompatibleSources()
    this._syncHoldableVideoButtons()
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._syncCompatibleSources = function () {

    var length, i, sourceUuid, source
    var basVideoSources

    if (this._avVideo) {

      this.basCompatibleSources = []

      basVideoSources = new BasCollection()
      basVideoSources.setId(BAS_SOURCES.COL_ID_VIDEO_SOURCES)
      basVideoSources.setTitleTranslationId(BAS_SOURCES.TRANS_ID_SOURCES)

      length = this._avVideo.compatibleSources.length

      for (i = 0; i < length; i++) {

        sourceUuid = this._avVideo.compatibleSources[i]

        source = Sources.getBasSource(sourceUuid)

        if (source && source.type !== BAS_SOURCE.T_UNKNOWN_SOURCE) {

          basVideoSources.items.push(sourceUuid)
        }
      }

      if (basVideoSources.items.length) {

        this.basCompatibleSources.push(basVideoSources)
      }
    }
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._onAvVideoState = function () {

    var _oldBasSource

    _oldBasSource = this.basSource

    if (this._syncAvVideo()) {

      if (this.basSource !== _oldBasSource) {

        $rootScope.$emit(
          BAS_ROOM.EVT_SOURCE_CHANGED,
          this._basRoom.id
        )
      }

      $rootScope.$applyAsync()
    }
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._onAvVideoReachable = function () {

    if (this.syncAvailability()) {

      $rootScope.$emit(
        BAS_ROOM.EVT_AVAILABLE_CHANGED,
        this._basRoom.id
      )
      $rootScope.$applyAsync()
    }
  }

  /**
   * @returns {string[]}
   */
  BasRoomVideo.prototype.getCompatibleSources = function () {

    return this._avVideo
      ? this._avVideo.compatibleSources
      : []
  }

  /**
   * @param {string} sourceUuid
   * @returns {boolean}
   */
  BasRoomVideo.prototype.isCompatibleSource = function (sourceUuid) {

    return (
      this._avVideo &&
      BasUtil.isNEString(sourceUuid) &&
      this._avVideo.compatibleSources.indexOf(sourceUuid) !== -1
    )
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._setAvVideoListeners = function () {

    this._clearAvVideoListeners()

    if (this._avVideo) {

      this._avVideoListeners.push(BasUtil.setEventListener(
        this._avVideo,
        BAS_API.AVVideo.EVT_CAPABILITIES_CHANGED,
        this._handleAvVideoCapabilities
      ))
      this._avVideoListeners.push(BasUtil.setEventListener(
        this._avVideo,
        BAS_API.AVVideo.EVT_ATTRIBUTES_CHANGED,
        this._handleAvVideoAttribtues
      ))
      this._avVideoListeners.push(BasUtil.setEventListener(
        this._avVideo,
        BAS_API.AVVideo.EVT_STATE_CHANGED,
        this._handleAvVideoState
      ))
      this._avVideoListeners.push(BasUtil.setEventListener(
        this._avVideo,
        BAS_API.AVVideo.EVT_REACHABLE_CHANGED,
        this._handleAvVideoReachable
      ))
    }
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._clearAvVideoListeners = function () {

    BasUtil.executeArray(this._avVideoListeners)
    this._avVideoListeners = []
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._clearBits = function () {

    this.bitIcon.unTrack()
    this.bitIcon.setImage(null)

    this.bit.unTrack()
    this.bit.setImage(null)
  }

  /**
   * Returns if changed
   *
   * @private
   * @param {string} key
   * @param {boolean} value
   * @returns {boolean}
   */
  BasRoomVideo.prototype._cssSet = function (key, value) {

    var _old

    _old = this._css[key]
    this._css[key] = value
    if (this._basRoom) this._basRoom.css[key] = this._css[key]
    return _old !== this._css[key]
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._resetProperties = function () {

    this.volume = 0
    this.muted = false
    this.bass = 0
    this.treble = 0
    this.startupVolume = 0
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._syncCssToBasRoom = function () {

    if (this._basRoom) BasUtil.mergeObjects(this._basRoom.css, this._css)
  }

  /**
   * @private
   */
  BasRoomVideo.prototype._resetCss = function () {

    this._css[CSS_HAS_VIDEO] = false
    this._css[CSS_IS_UNAVAILABLE] = false
    this._css[CSS_MUTED] = false
    this._css[CSS_CAN_ADJUST_VOLUME] = false
    this._css[CSS_HAS_SOURCE_NAME] = false
    this._css[CSS_HAS_ROOM_SETTINGS] = false
  }

  BasRoomVideo.prototype.destroy = function destroy () {

    // Clear CSS on parent
    this._resetCss()
    this._syncCssToBasRoom()

    this.suspend()
    this._avVideo = null
    this._basRoom = null
  }

  return BasRoomVideo
}
