'use strict'

angular
  .module('basalteApp')
  .directive('basMusicWidget', [
    'BAS_HTML',
    basMusicWidgetDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basMusicWidget
 */
function basMusicWidgetDirective (
  BAS_HTML
) {
  return {
    restrict: 'A',
    controller: 'basMusicWidgetCtrl',
    controllerAs: 'musicWidget',
    bindToController: {
      room: '<',
      selectPlayerHandler: '<?',
      useSwipeControls: '<?',
      useOverlayControls: '<?',
      showPlayPauseOverlayControl: '<?',
      showForwardBackwardOverlayControls: '<?',
      showShuffleOverlayControl: '<?',
      showRepeatOverlayControl: '<?',
      showTurnOffMusicOverlayControl: '<?',
      showTitleOverlay: '<?',
      hideVolumeWithOverlay: '<?',
      useAdvancedFlipside: '<?',
      showStream: '<?'
    },
    template: BAS_HTML.musicWidget
  }
}
