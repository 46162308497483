'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasString', [
    'BasUtilities',
    basStringFactory
  ])

/**
 * @param {BasUtilities} BasUtilities
 * @returns BasString
 */
function basStringFactory (
  BasUtilities
) {
  /**
   * @constructor
   * @param {Object} [options]
   * @param {string} [options.literal]
   * @param {string} [options.key]
   */
  function BasString (options) {

    /**
     * @type {string}
     */
    this.value = ''

    /**
     * @type {string}
     */
    this.key = ''

    if (BasUtil.isObject(options)) {

      if (BasUtil.isNEString(options.literal)) {

        this.value = options.literal
      }

      if (BasUtil.isNEString(options.key)) this.key = options.key
    }

    this.updateTranslation()
  }

  /**
   * @param {string} key
   */
  BasString.prototype.setKey = function (key) {

    this.clear()

    if (BasUtil.isNEString(key)) this.key = key

    this.updateTranslation()
  }

  /**
   * Will strip "$$"
   * If key does not start with "$$":
   * Will encapsulate key in "${}" if there are no existing "${}" in the key.
   *
   * @returns {string}
   */
  BasString.prototype.getPartKey = function () {

    var result, value, idx

    result = ''

    if (this.key) {

      value = this.key.substring(0, 2)

      if (value === '$$') {

        result = this.key.substring(2)

      } else {

        idx = this.key.indexOf('${')

        if (idx > -1) {

          result = this.key

        } else {

          result = '${' + this.key + '}'
        }
      }
    }

    return result
  }

  /**
   * @param {string} literal
   */
  BasString.prototype.setLiteral = function (literal) {

    this.clear()

    if (BasUtil.isNEString(literal)) this.value = literal
  }

  BasString.prototype.updateTranslation = function () {

    if (BasUtil.isNEString(this.key)) {

      this.value = BasUtilities.basTranslate(this.key)
    }
  }

  BasString.prototype.clear = function () {

    this.value = ''
    this.key = ''
  }

  /**
   * @returns {string}
   */
  BasString.prototype.getString = function () {

    if (BasUtil.isNEString(this.key)) {

      return BasUtilities.translate(this.key)
    }

    return this.value
  }

  /**
   * @returns {BasString}
   */
  BasString.prototype.clone = function () {

    var newBasString

    newBasString = new BasString()
    newBasString.value = this.value
    newBasString.key = this.key

    return newBasString
  }

  return BasString
}
