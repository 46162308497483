'use strict'

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

var BasTrack = require('./bas_track')

/**
 * An object representing a Bluetooth source
 *
 * @constructor
 * @param {Object} config
 * @param {number} config.id
 * @param {string} config.uuid
 * @param {string} config.name
 * @param {BasCore} basCore
 * @since 0.2.1
 */
function Bluetooth (config, basCore) {

  EventEmitter.call(this)

  // Store reference to the BasCore instance
  this._basCore = basCore

  this._id = config.id
  this._uuid = config.uuid
  this._name = config.name

  this._clientName = ''
  this._error = false
  this._pairing = false
  this._paused = true

  this._song = new BasTrack()
}

Bluetooth.prototype = Object.create(EventEmitter.prototype)
Bluetooth.prototype.constructor = Bluetooth

/**
 * Client name has changed
 *
 * @event Bluetooth#EVT_CLIENT_NAME
 */

/**
 * Pairing state has changed
 *
 * @event Bluetooth#EVT_PAIRING
 * @param {boolean} state New state for pairing
 */

/**
 * Paused state has changed
 *
 * @event Bluetooth#EVT_PAUSED
 * @param {boolean} state New state for paused
 */

/**
 * Current song has changed
 *
 * @event Bluetooth#EVT_CURRENT_SONG
 */

/**
 * Error state has changed
 *
 * @event Bluetooth#EVT_ERROR
 * @param {boolean} state New state for error
 */

/**
 * @constant {string}
 */
Bluetooth.EVT_CLIENT_NAME = 'evtBluetoothClientName'

/**
 * @constant {string}
 */
Bluetooth.EVT_PAIRING = 'evtBluetoothPairing'

/**
 * @constant {string}
 */
Bluetooth.EVT_PAUSED = 'evtBluetoothPaused'

/**
 * @constant {string}
 */
Bluetooth.EVT_CURRENT_SONG = 'evtBluetoothCurrentSong'

/**
 * @constant {string}
 */
Bluetooth.EVT_ERROR = 'evtBluetoothError'

/**
 * @name Bluetooth#id
 * @type {number}
 * @readonly
 */
Object.defineProperty(Bluetooth.prototype, 'id', {
  get: function () {
    return this._id
  }
})

/**
 * @name Bluetooth#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Bluetooth.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name Bluetooth#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(Bluetooth.prototype, 'name', {
  get: function () {
    return this._name
  }
})

/**
 * @name Bluetooth#paused
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Bluetooth.prototype, 'paused', {
  get: function () {
    return this._paused
  }
})

/**
 * @name Bluetooth#pairing
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Bluetooth.prototype, 'pairing', {
  get: function () {
    return this._pairing
  }
})

/**
 * @name Bluetooth#error
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Bluetooth.prototype, 'error', {
  get: function () {
    return this._error
  }
})

/**
 * @name Bluetooth#clientName
 * @type {string}
 * @readonly
 */
Object.defineProperty(Bluetooth.prototype, 'clientName', {
  get: function () {
    return this._clientName
  }
})

/**
 * @name Bluetooth#currentSong
 * @type {Object}
 * @readonly
 */
Object.defineProperty(Bluetooth.prototype, 'currentSong', {
  get: function () {
    return this._song
  }
})

/**
 * Parse a message from the connected basCore
 *
 * @param {Object} obj
 */
Bluetooth.prototype.parse = function (obj) {

  var state, track, oldSong

  if (BasUtil.isObject(obj)) {

    if (BasUtil.isObject(obj[P.STATE])) {

      state = obj[P.STATE]

      if (BasUtil.safeHasOwnProperty(state, P.CLIENT_NAME) &&
        state[P.CLIENT_NAME] !== this._clientName) {

        this._clientName = state[P.CLIENT_NAME]
        this.emit(Bluetooth.EVT_CLIENT_NAME)
      }

      if (BasUtil.safeHasOwnProperty(state, P.ERROR) &&
        state[P.ERROR] !== this._error) {

        this._error = state[P.ERROR]
        this.emit(Bluetooth.EVT_ERROR)
      }

      if (BasUtil.safeHasOwnProperty(state, P.PAIRING) &&
        state[P.PAIRING] !== this._pairing) {

        this._pairing = state[P.PAIRING]
        this.emit(Bluetooth.EVT_PAIRING)
      }

      if (BasUtil.safeHasOwnProperty(state, P.PAUSED) &&
        state[P.PAUSED] !== this._paused) {

        this._paused = state[P.PAUSED]
        this.emit(Bluetooth.EVT_PAUSED, this._paused)
      }
    }

    if (Array.isArray(obj[P.TRACKS]) &&
      BasUtil.isObject(obj[P.TRACKS][0])) {

      track = obj[P.TRACKS][0]

      oldSong = this._song.hash()
      this._song.parse(track)

      if (oldSong !== this._song.hash()) {

        this.emit(Bluetooth.EVT_CURRENT_SONG)
      }
    }
  }
}

/**
 * Activate bluetooth pairing
 */
Bluetooth.prototype.activatePairing = function () {

  var msg = this._getBasCoreMessage()
  msg[P.SOURCE][P.STATE] = {}
  msg[P.SOURCE][P.STATE][P.PAIRING] = true

  this._basCore.send(msg)
}

/**
 * Disable bluetooth pairing
 */
Bluetooth.prototype.disablePairing = function () {

  var msg = this._getBasCoreMessage()
  msg[P.SOURCE][P.STATE] = {}
  msg[P.SOURCE][P.STATE][P.PAIRING] = false

  this._basCore.send(msg)
}

/**
 * Toggle play/pause. This will trigger an {@link Bluetooth#EVT_PAUSED} event.
 *
 * @param {boolean} [override] true = play, false = pause
 */
Bluetooth.prototype.togglePlayPause = function (override) {

  this.setState(
    BasUtil.isBool(override)
      ? override ? P.PLAY : P.PAUSE
      : P.PLAYPAUSE
  )
}

/**
 * Play the next song in the queue. This will trigger an
 * {@link Bluetooth#EVT_CURRENT_SONG} event.
 */
Bluetooth.prototype.next = function () {
  this.setState(P.NEXT)
}

/**
 * Play the previous song in the queue. This will trigger an
 * {@link Bluetooth#EVT_CURRENT_SONG} event.
 */
Bluetooth.prototype.previous = function () {
  this.setState(P.PREVIOUS)
}

/**
 * Send a single state command to the basCore
 *
 * @param {string} state The new state for the player
 */
Bluetooth.prototype.setState = function (state) {

  var msg = this._getBasCoreMessage()
  msg[P.SOURCE][P.STATE] = state

  this._basCore.send(msg)
}

/**
 * Creates a template basCore message for this device
 *
 * @protected
 * @returns {Object}
 */
Bluetooth.prototype._getBasCoreMessage = function () {

  var msg = {}
  msg[P.SOURCE] = {}
  msg[P.SOURCE][P.UUID] = this._uuid
  msg[P.SOURCE][P.ID] = this._id

  return msg
}

module.exports = Bluetooth
