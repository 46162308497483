'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasScenePreset', [
    'BAS_API',
    'ICONS',
    'BAS_SCENE_PRESETS',
    'BasUtilities',
    basScenePresetFactory
  ])

/**
 * @param BAS_API
 * @param {ICONS} ICONS
 * @param {BAS_SCENE_PRESETS} BAS_SCENE_PRESETS
 * @param {BasUtilities} BasUtilities
 * @returns BasScenePreset
 */
function basScenePresetFactory (
  BAS_API,
  ICONS,
  BAS_SCENE_PRESETS,
  BasUtilities
) {
  /**
   * @constructor
   * @param {string} [key]
   * @param {number} [number]
   */
  function BasScenePreset (key, number) {

    /**
     * @type {string}
     */
    this.key = BasUtil.isNEString(key) ? key : ''

    /**
     * @type {number}
     */
    this.number = BasUtil.isVNumber(number)
      ? number
      : BAS_API.Scene.TEMPLATES.CUSTOM

    /**
     * @type {string}
     */
    this.translationId = 'unknown'

    /**
     * @type {Object<string, string>}
     */
    this.images = {}

    /**
     * @type {string}
     */
    this.icon = ''

    /**
     * @type {string}
     */
    this.iconWText = ''

    /**
     * @type {string}
     */
    this.uiTitle = ''

    BasScenePreset._matchPreset(this)
    this.translate()
  }

  /**
   * Matches presets and fills in extra info.
   * Example: preset with key HOME, will get home image and translationId.
   *
   * @private
   * @param {BasScenePreset} preset
   */
  BasScenePreset._matchPreset = function _matchPreset (preset) {

    switch (preset.key) {
      case BAS_SCENE_PRESETS.K_UNKNOWN:
        preset.translationId = 'unknown'
        preset.icon = ICONS.defaultTiles
        preset.iconWText = ICONS.defaultTilesUp
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.CUSTOM]:
        preset.translationId = 'custom'
        preset.icon = ICONS.defaultTiles
        preset.iconWText = ICONS.defaultTilesUp
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.HOME]:
        preset.translationId = 'scene_home'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/home.jpg'
        preset.images.imgw400 = 'img/scenes/400/home.jpg'
        preset.images.imgw200 = 'img/scenes/200/home.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.AWAY]:
        preset.translationId = 'scene_away'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/away.jpg'
        preset.images.imgw400 = 'img/scenes/400/away.jpg'
        preset.images.imgw200 = 'img/scenes/200/away.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.RELAX]:
        preset.translationId = 'scene_relax'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/relax.jpg'
        preset.images.imgw400 = 'img/scenes/400/relax.jpg'
        preset.images.imgw200 = 'img/scenes/200/relax.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.PARTY]:
        preset.translationId = 'scene_party'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/champagne.jpg'
        preset.images.imgw400 = 'img/scenes/400/champagne.jpg'
        preset.images.imgw200 = 'img/scenes/200/champagne.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.COOKING]:
        preset.translationId = 'scene_cooking'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/cooking.jpg'
        preset.images.imgw400 = 'img/scenes/400/cooking.jpg'
        preset.images.imgw200 = 'img/scenes/200/cooking.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.DINING]:
        preset.translationId = 'scene_dinner'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/dining.jpg'
        preset.images.imgw400 = 'img/scenes/400/dining.jpg'
        preset.images.imgw200 = 'img/scenes/200/dining.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.WELCOME]:
        preset.translationId = 'scene_welcome'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/welcome.jpg'
        preset.images.imgw400 = 'img/scenes/400/welcome.jpg'
        preset.images.imgw200 = 'img/scenes/200/welcome.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.GUESTS]:
        preset.translationId = 'scene_guests'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/guests.jpg'
        preset.images.imgw400 = 'img/scenes/400/guests.jpg'
        preset.images.imgw200 = 'img/scenes/200/guests.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.EVENING]:
        preset.translationId = 'scene_evening'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/candles.jpg'
        preset.images.imgw400 = 'img/scenes/400/candles.jpg'
        preset.images.imgw200 = 'img/scenes/200/candles.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.GOOD_MORNING]:
        preset.translationId = 'scene_good_morning'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/coffee.jpg'
        preset.images.imgw400 = 'img/scenes/400/coffee.jpg'
        preset.images.imgw200 = 'img/scenes/200/coffee.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.MOVIE]:
        preset.translationId = 'scene_movie'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/popcorn.jpg'
        preset.images.imgw400 = 'img/scenes/400/popcorn.jpg'
        preset.images.imgw200 = 'img/scenes/200/popcorn.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.WORKOUT]:
        preset.translationId = 'scene_workout'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/workout.jpg'
        preset.images.imgw400 = 'img/scenes/400/workout.jpg'
        preset.images.imgw200 = 'img/scenes/200/workout.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.GOOD_NIGHT]:
        preset.translationId = 'scene_good_night'
        preset.images = {}
        preset.images.imgw600 = 'img/scenes/600/good_night.jpg'
        preset.images.imgw400 = 'img/scenes/400/good_night.jpg'
        preset.images.imgw200 = 'img/scenes/200/good_night.jpg'
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.ALL_ON]:
        preset.translationId = 'all_on'
        preset.icon = ICONS.onOff
        preset.iconWText = ICONS.onOff
        break
      case BAS_API.Scene.TEMPLATES_R[BAS_API.Scene.TEMPLATES.ALL_OFF]:
        preset.translationId = 'all_off'
        preset.icon = ICONS.onOff
        preset.iconWText = ICONS.onOff
        break
    }
  }

  /**
   * @returns {boolean}
   */
  BasScenePreset.prototype.isOn = function () {
    return this.number === BAS_API.Scene.TEMPLATES.ALL_ON
  }

  /**
   * @returns {boolean}
   */
  BasScenePreset.prototype.isOff = function () {
    return this.number === BAS_API.Scene.TEMPLATES.ALL_OFF
  }

  /**
   * @returns {boolean}
   */
  BasScenePreset.prototype.isCustom = function () {
    return this.number === BAS_API.Scene.TEMPLATES.CUSTOM
  }

  /**
   * @returns {boolean}
   */
  BasScenePreset.prototype.isHome = function () {
    return this.number === BAS_API.Scene.TEMPLATES.HOME
  }

  /**
   * @returns {boolean}
   */
  BasScenePreset.prototype.isAway = function () {
    return this.number === BAS_API.Scene.TEMPLATES.AWAY
  }

  BasScenePreset.prototype.translate = function () {

    this.uiTitle = BasUtilities.translate(this.translationId)
  }

  return BasScenePreset
}
