'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('projectSettingsCtrl', [
    '$rootScope',
    '$scope',
    'STATES',
    'SETTINGS_STATES',
    'BAS_CURRENT_CORE',
    'BAS_SOURCES',
    'BAS_ROOMS',
    'BasState',
    'CurrentBasCore',
    'BasAppDevice',
    'BasUtilities',
    projectSettingsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {STATES} STATES
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasState} BasState
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasAppDevice} BasAppDevice
 * @param {BasUtilities} BasUtilities
 */
function projectSettingsCtrl (
  $rootScope,
  $scope,
  STATES,
  SETTINGS_STATES,
  BAS_CURRENT_CORE,
  BAS_SOURCES,
  BAS_ROOMS,
  BasState,
  CurrentBasCore,
  BasAppDevice,
  BasUtilities
) {
  var project = this

  var CSS_INTEGRATOR_INFO = 'bas-integrator-access-info'

  var _listeners = []

  /**
   * @type {STATES}
   */
  project.STATES = STATES

  /**
   * @type {SETTINGS_STATES}
   */
  project.SETTINGS_STATES = SETTINGS_STATES

  /**
   * @type {BAS_ROOMS}
   */
  project.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {TCurrentBasCoreState}
   */
  project.currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasAppDeviceState}
   */
  project.basAppDeviceState = BasAppDevice.get()

  /**
   * @type {TBasStateObj}
   */
  project.basState = BasState.get()

  project.integratorAccess = false
  project.integratorTelPhone = ''
  project.integratorAccessInfoVisible = false

  project.goToRoomImages = goToRoomImages
  project.selectProjectImage = selectProjectImage
  project.toggleIntegratorAccess = toggleIntegratorAccess
  project.showIntegratorAccessInfo = showIntegratorAccessInfo
  project.close = back

  init()

  function init () {

    $scope.$on('$destroy', onDestroy)

    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _onMusicConfig
    ))
    _listeners.push($rootScope.$on(
      BAS_SOURCES.EVT_SOURCES_UPDATED,
      _onSourcesUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_SYSTEM,
      onSystemProperties
    ))

    if (CurrentBasCore.hasCore()) {

      _syncIntegratorAccess()
      _requestIntegratorInfo()
    }
  }

  function _onMusicConfig () {

    checkServerStatus()
  }

  function _onSourcesUpdated () {

    _scopeSync()
  }

  function onSystemProperties () {

    _syncIntegratorAccess()
    _scopeSync()
  }

  function checkServerStatus () {

    var _basServer

    if (CurrentBasCore.hasCore()) {

      _basServer = project.currentBasCoreState.core.core.server

      if (_basServer) {

        _basServer.getStatus().then(_scopeSync)
      }
    }
  }

  function goToRoomImages () {

    BasState.go(STATES.SETTINGS_PROJECT_ROOM_IMAGES)
  }

  function selectProjectImage () {

    BasState.go(STATES.SETTINGS_PROJECT_PROJECT_IMAGE)
  }

  function toggleIntegratorAccess () {

    var system

    if (
      !project.basAppDeviceState.isProLiveHosted &&
      CurrentBasCore.hasCore()
    ) {
      system = project.currentBasCoreState.core.core.system

      if (system) {

        project.integratorAccess = !project.integratorAccess
        system.toggleIntegratorAccess(project.integratorAccess)
      }
    }
  }

  function showIntegratorAccessInfo () {

    project.integratorAccessInfoVisible = true
    BasUtilities.waitForFrames(5, _settingScrollDown)
  }

  function _syncIntegratorAccess () {

    var system

    if (CurrentBasCore.hasCore()) {

      system = project.currentBasCoreState.core.core.system

      if (system) {

        project.integratorAccess = system.integratorAccess
      }
    }
  }

  function _requestIntegratorInfo () {

    var system

    if (
      CurrentBasCore.hasCore() &&
      project.currentBasCoreState.core.core.supportsCustomRoomImages
    ) {

      system = project.currentBasCoreState.core.core.system

      if (system) {

        system.requestIntegratorInfo().then(_onIntegratorInfo)
      }
    }
  }

  function _onIntegratorInfo () {

    var system

    if (CurrentBasCore.hasCore()) {

      system = project.currentBasCoreState.core.core.system

      if (system) {

        project.integratorTelPhone =
          BasUtilities.getTelPhone(system.integratorPhone)

        _scopeSync()
      }
    }
  }

  function _settingScrollDown () {

    var element

    element = document.getElementsByClassName(CSS_INTEGRATOR_INFO)[0]
    if (element) element.scrollIntoView()
  }

  function _scopeSync () {

    $scope.$applyAsync()
  }

  function onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }

  function back () {

    if (project.basState.current.SETTINGS_PROJECT_ROOM_IMAGES_ROOM) {

      BasState.go(STATES.SETTINGS_PROJECT_ROOM_IMAGES)

    } else if (project.basState.current.SETTINGS_PROJECT_PROJECT_IMAGE) {

      BasState.go(STATES.SETTINGS_PROJECT)
    }
  }
}
