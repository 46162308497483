'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('spotifySettingsCtrl', [
    '$rootScope',
    '$scope',
    'AppLink',
    'BAS_API',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_MODAL',
    'BAS_HTML',
    'Sources',
    'SourcesHelper',
    'BasInAppBrowser',
    'BasModal',
    'CurrentBasCore',
    'Logger',
    'ModalService',
    spotifySettingsCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param AppLink
 * @param BAS_API
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_HTML} BAS_HTML
 * @param {Sources} Sources
 * @param {SourcesHelper} SourcesHelper
 * @param BasInAppBrowser
 * @param {BasModal} BasModal
 * @param {CurrentBasCore} CurrentBasCore
 * @param Logger
 * @param {ModalService} ModalService
 */
function spotifySettingsCtrl (
  $rootScope,
  $scope,
  AppLink,
  BAS_API,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_MODAL,
  BAS_HTML,
  Sources,
  SourcesHelper,
  BasInAppBrowser,
  BasModal,
  CurrentBasCore,
  Logger,
  ModalService
) {
  var spotify = this

  var _inAppBrowser = null

  var _spotifySourceId = -1
  var _spotifyBarpListeners = []
  var _spotifyAudioSourceListeners = []

  var _listeners = []

  spotify.BAS_SOURCES = BAS_SOURCES
  spotify.BAS_SOURCE = BAS_SOURCE
  spotify.currentBasCoreState = CurrentBasCore.get()

  spotify.openSpotifyConnectInstructions = openSpotifyConnectInstructions
  spotify.openApp = openApp
  spotify.openLinkModal = openLinkModal

  $scope.$on('$destroy', onDestroy)

  init()

  function init () {

    _listeners.push($rootScope.$on(
      BAS_SOURCES.EVT_SOURCES_UPDATED,
      _onSourcesUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_SPOTIFY_TOKEN_CHANGED,
      _onSourceLinkChanged
    ))
    _listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_SPOTIFY_USER_UPDATED,
      _onSourceLinkChanged
    ))

    Sources.registerFor(BAS_SOURCE.COL_EVT_SPOTIFY)

    allSpotifyWebLinked()
  }

  function openSpotifyConnectInstructions () {

    AppLink.openPage('https://www.spotify.com/connect')
  }

  function openApp () {

    AppLink.openApp(AppLink.APP_SPOTIFY)
  }

  /**
   * Checks all Spotify Barps for their Spotify Web API link status
   */
  function allSpotifyWebLinked () {

    var sources, keys, i, length, source

    sources = CurrentBasCore.hasAVFullSupport()
      ? BAS_SOURCES.SOURCES.audioSources
      : BAS_SOURCES.SOURCES.spotify
    keys = Object.keys(sources)
    length = keys.length

    for (i = 0; i < length; i++) {

      source = BAS_SOURCES.SOURCES.sources[sources[keys[i]]]

      if (
        source &&
        (
          source.type === BAS_SOURCE.T_PLAYER ||
          source.type === BAS_SOURCE.T_ASANO
        ) &&
        source.spotify
      ) {

        source.spotify.linked().then(_onSourceLinkChanged)
      }
    }
  }

  function openLinkModal (sourceId) {

    var basSource = _getBasSource()

    if (basSource) basSource.spotify.linked().then(_onSpotifyLinked)

    function _onSpotifyLinked (result) {

      return ModalService.showModal({
        template: BAS_HTML.streamingServiceSettingsModal,
        controller: 'streamingServiceSettingsModalCtrl',
        controllerAs: 'modal',
        inputs: {
          sourceName: basSource.name,
          streamingServiceTransId: 'spotify',
          linkedName: result ? basSource.spotify.longLink : '',
          unlink: unlink,
          link: link
        }
      })
    }

    function unlink () {

      var basSourceToUnlink = _getBasSource()

      ModalService.closeModals()

      if (
        basSourceToUnlink &&
        basSourceToUnlink.spotify
      ) {

        basSourceToUnlink.spotify.unlink()
      }
    }

    function link () {

      var basSourceToLink = _getBasSource()

      ModalService.closeModals()

      if (
        basSourceToLink &&
        basSourceToLink.spotify
      ) {

        basSourceToLink.spotify.getLinkUrl().then(_onLinkUrl)
      }

      function _onLinkUrl (url) {

        Logger.info('Spotify Link URL', url)

        if (BasUtil.isNEString(url)) {

          _setSpotifyListenersFor(basSourceToLink)

          _closeInAppBrowser()
          _inAppBrowser = new BasInAppBrowser(url)
        }
      }
    }

    /**
     * @private
     * @returns {?BasSource}
     */
    function _getBasSource () {

      var _basSource = BAS_SOURCES.SOURCES.sources[sourceId]

      if (
        _basSource &&
        (
          (
            _basSource.type === BAS_SOURCE.T_BARP &&
            _basSource.subType === BAS_SOURCE.ST_SPOTIFY
          ) ||
          _basSource.type === BAS_SOURCE.T_ASANO
        ) &&
        _basSource.spotify
      ) {

        return _basSource
      }

      return null
    }
  }

  function _closeInAppBrowser () {

    if (BasUtil.isObject(_inAppBrowser)) {

      _inAppBrowser.closeInAppBrowser()
      _inAppBrowser = null
    }
  }

  function _onSourcesUpdated () {

    allSpotifyWebLinked()
    _setSpotifyBarpListeners()
  }

  function _onSourceLinkChanged () {

    ModalService.closeModals()

    $scope.$applyAsync()
  }

  /**
   * @private
   * @param {BasSource} basSource
   */
  function _setSpotifyListenersFor (basSource) {

    _spotifySourceId = basSource.getId()

    if (basSource.isAudioSource) {

      _setSpotifyAudioSourceListeners()

    } else {

      _setSpotifyBarpListeners()
    }
  }

  function _setSpotifyBarpListeners () {

    var basSource

    basSource = SourcesHelper.getSpotifySource(_spotifySourceId)

    _clearSpotifyBarpListeners()

    if (basSource &&
      basSource.source) {

      _spotifyBarpListeners.push(BasUtil.setEventListener(
        basSource.source,
        BAS_API.SpotifyBarp.EVT_LINK_FINISHED,
        _onLinkFinished
      ))

      _spotifyBarpListeners.push(BasUtil.setEventListener(
        basSource.source,
        BAS_API.SpotifyBarp.EVT_LINK_ERROR,
        _onLinkError
      ))
    }
  }

  function _setSpotifyAudioSourceListeners () {

    var basSource

    basSource = SourcesHelper.getAudioSource(_spotifySourceId)

    _clearSpotifyAudioSourceListeners()

    if (
      basSource &&
      basSource.source
    ) {

      _spotifyAudioSourceListeners.push(BasUtil.setEventListener(
        basSource.source,
        BAS_API.AudioSource.EVT_STREAMING_SERVICE_LINK_FINISHED,
        _onAudioSourceLinkFinished
      ))
    }
  }

  function _onLinkFinished () {

    _removeSpotifyBarpListeners()

    _closeInAppBrowser()
  }

  function _onLinkError (error) {

    if (error === BAS_SOURCE.ERR_FREE_SPOTIFY) {
      BasModal.show(BAS_MODAL.T_SPOTIFY_FREE_USER)
    }

    _removeSpotifyBarpListeners()

    _closeInAppBrowser()

    // TODO OAuth error modal
  }

  function _onAudioSourceLinkFinished (data) {

    if (data.streamingService === BAS_API.AudioSource.A_SS_SPOTIFY) {

      _removeSpotifyAudioSourceListeners()

      // Don't close inappbrowser, succes page will close itself after 5 seconds

      if (data.error) {

        _closeInAppBrowser()

        if (data.error === BAS_SOURCE.ERR_FREE_SPOTIFY) {

          BasModal.show(BAS_MODAL.T_SPOTIFY_FREE_USER)
        }
      }
    }
  }

  function _removeSpotifyBarpListeners () {

    _spotifySourceId = -1
    _clearSpotifyBarpListeners()
  }

  function _clearSpotifyBarpListeners () {

    BasUtil.executeArray(_spotifyBarpListeners)
    _spotifyBarpListeners = []
  }

  function _removeSpotifyAudioSourceListeners () {

    _spotifySourceId = -1
    _clearSpotifyAudioSourceListeners()
  }

  function _clearSpotifyAudioSourceListeners () {

    BasUtil.executeArray(_spotifyAudioSourceListeners)
    _spotifyBarpListeners = []
  }

  function onDestroy () {

    _removeSpotifyBarpListeners()

    BasUtil.executeArray(_listeners)
    _listeners = []

    Sources.unregisterFor(BAS_SOURCE.COL_EVT_SPOTIFY)
  }
}
