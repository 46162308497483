'use strict'

angular
  .module('basalteApp')
  .service('BasVideoHelper', [
    'ICONS',
    'BAS_IMAGE',
    'BasImageTrans',
    'BasImage',
    BasVideoHelper
  ])

/**
 * @constructor
 * @param {ICONS} ICONS
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param BasImageTrans
 * @param BasImage
 */
function BasVideoHelper (
  ICONS,
  BAS_IMAGE,
  BasImageTrans,
  BasImage
) {
  var defaultEmptyVideo = new BasImage(
    ICONS.externalTv,
    {
      customClass: [
        BAS_IMAGE.C_BG_CONTAIN,
        BAS_IMAGE.C_HEIGHT_90
      ]
    }
  )

  /**
   * @type {BasImageTrans}
   */
  this.videoMainBit = new BasImageTrans({
    transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
    defaultImage: defaultEmptyVideo
  })

  /**
   * @type {BasImageTrans}
   */
  this.videoWidgetBit = new BasImageTrans({
    transitionType: BasImageTrans.TRANSITION_TYPE_FADE_FAST,
    defaultImage: defaultEmptyVideo
  })
}
