'use strict'

angular
  .module('basalteApp')
  .constant('BAS_TUNE_IN', {
    BASE_URL: 'https://opml.radiotime.com',
    PATH_BROWSE: 'Browse.ashx',
    PATH_DESCRIBE: 'Describe.ashx',
    PATH_SEARCH: 'Search.ashx',
    PATH_CUSTOM_STATIONS: 'CustomStations',
    PATH_CUSTOM_RADIOS: '/api/radios',
    K_NAME: 'name',
    K_GENRE_NAME: 'genre_name',
    K_LOGO: 'logo',
    K_FREQUENCY: 'frequency',
    KEY_HAS_CUSTOM_STATIONS: 'hasStations',
    KEY_CUSTOM_STATIONS_COUNTRIES: 'countries',
    KEY_CACHED_STATIONS: 'cachedStations',
    KEY_CUSTOM_STATIONS_DATE: 'customStationsDate',
    KEY_CUSTOM_STATIONS: 'customStations',
    CS_KEY_COUNTRY: 'country',
    CS_KEY_DESCRIBE: 'describe',
    CS_KEY_LOGO: 'logo',
    CS_KEY_NAME: 'name',
    CS_LINK: 'https://customStations.basalte.be',
    EL_KEY_TYPE: 'type',
    EL_KEY_KEY: 'key',
    EL_KEY_TEXT: 'text',
    EL_KEY_URL: 'URL',
    EL_KEY_IMAGE: 'image',
    EL_ELEMENT_KEY: 'element',
    EL_KEY_GUIDE_ID: 'guide_id',
    EL_VAL_OUTLINE: 'outline',
    EL_VAL_STATION: 'station',
    EL_TYPE_LINK: 'link',
    EL_TYPE_AUDIO: 'audio',
    EL_KEY_ITEM: 'item',
    EL_KEY_LOCAL: 'local',
    EL_KEY_MUSIC: 'music',
    EL_KEY_LOCATION: 'location',
    EL_KEY_LANGUAGE: 'language',
    EL_KEY_POPULAR: 'popular',
    EL_KEY_PIVOT_GENRE: 'pivotGenre',
    EL_KEY_PIVOT_NAME: 'pivotName',
    EL_KEY_NEXT_STATIONS: 'nextStations',
    EL_CHILDREN_KEY: 'children',
    EL_BAS_PATH: 'basPath',
    EL_BAS_TRANSLATION: 'basNeedsTranslation',
    EL_KEY_STATIONS: 'stations',
    EL_KEY_PIVOT: 'pivot',
    ERROR_NO_CS: 'NO stored custom stations',
    ERROR_INPUT: 'Invalid input',
    ERROR_TUNEIN_RESULT: 'Invalid TuneIn result',
    ERROR_TUNEIN_RESULT_STATUS: 'TuneIn result Status ',
    PARAM_LOCALE: 'locale',
    PARAM_RENDER: 'render',
    PARAM_PARTNERID: 'partnerId',
    PARAM_SERIAL: 'serial',
    PARAM_QUERY: 'query',
    PARAM_ID: 'id'
  })
