'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasPlaylist', [
    'BAS_IMAGE',
    'ICONS',
    'BasImageTrans',
    'BasImage',
    'Logger',
    basPlaylist
  ])

function basPlaylist (
  BAS_IMAGE,
  ICONS,
  BasImageTrans,
  BasImage,
  Logger
) {
  var K_ID = 'id'
  var K_NAME = 'name'
  var K_TITLE = 'title'
  var K_SIZE = 'size'
  var K_LOCKED = 'locked'
  var K_OWNER = 'owner'
  var K_ITUNES = 'iTunes'
  var K_SHARED = 'shared'
  var K_LOCAL = 'shared'
  var K_URI = 'uri'
  var K_IMAGES = 'images'
  var K_ORIG = 'orig'
  var K_TYPE = 'type'

  var K_COVERART = 'coverart'
  var K_THUMBNAIL = 'thumbnail'

  var biSvgOpts = {
    customClass: [
      BAS_IMAGE.C_BG_CONTAIN,
      BAS_IMAGE.C_COLOR_MUTED,
      BAS_IMAGE.C_SIZE_70
    ]
  }

  var biPlaylist = new BasImage(ICONS.queue, biSvgOpts)

  /**
   * @constructor
   * @param {?Object} [data]
   */
  function BasPlaylist (data) {

    /**
     * @type {string}
     */
    this.id = ''

    /**
     * @type {string}
     */
    this.uri = ''

    /**
     * @type {string}
     */
    this.title = ''

    /**
     * @type {number}
     */
    this.numberOfSongs = 0

    /**
     * @type {string}
     */
    this.owner = ''

    /**
     * @type {string}
     */
    this.ownerUuid = ''

    /**
     * @type {boolean}
     */
    this.locked = false

    /**
     * @type {boolean}
     */
    this.editable = false

    /**
     * @type {boolean}
     */
    this.shareable = false

    /**
     * @type {boolean}
     */
    this.isSharing = false

    /**
     * @type {boolean}
     */
    this.iTunes = false

    /**
     * @type {BasImageTrans}
     */
    this.bitIcon = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: biPlaylist
    })

    /**
     * @type {BasImageTrans}
     */
    this.bit = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: biPlaylist
    })

    /**
     * @type {string[]}
     */
    this.coverArts = []

    this.handleInfo = this.parse.bind(this)
    this.handleCoverArt = this.parseCoverArt.bind(this)

    this.parse(data)
  }

  /**
   * Get the playlist with id from array of BasPlaylists
   *
   * @param {BasPlaylist[]} arr
   * @param {string} id
   * @returns {?BasPlaylist}
   */
  BasPlaylist.get = function (arr, id) {

    var i, length

    length = arr.length
    for (i = 0; i < length; i++) {

      if (arr[i].id === id) return arr[i]
    }

    return null
  }

  /**
   * @param {Object} data
   */
  BasPlaylist.prototype.parse = function (data) {

    if (BasUtil.isObject(data) &&
      BasUtil.isNEString(data[K_ID])) {

      if (!this.id) this.id = data[K_ID]

      if (this.id === data[K_ID]) {

        if (BasUtil.isNEString(data[K_NAME])) {
          this.title = data[K_NAME]
        }

        if (BasUtil.isPNumber(data[K_SIZE])) {
          this.numberOfSongs = data[K_SIZE]
        }

        if (BasUtil.isNEString(data[K_OWNER])) {
          this.owner = data[K_OWNER]
        }

        if (BasUtil.isBool(data[K_LOCKED])) {
          this.locked = data[K_LOCKED]
        }

        if (BasUtil.isBool(data[K_ITUNES])) {
          this.iTunes = data[K_ITUNES]
        }

        if (BasUtil.isBool(data[K_SHARED])) {
          this.isSharing = data[K_SHARED]
        }

        this.check()

      } else {

        Logger.error('BasPlaylist - Parse - ID mismatch', this, data)
      }
    } else if (
      BasUtil.isObject(data) &&
      BasUtil.isNEString(data[K_URI])
    ) {

      this.uri = data[K_URI]
      this.id = this.uri

      if (BasUtil.isPNumber(data[K_SIZE])) {
        this.numberOfSongs = data[K_SIZE]
      }

      if (BasUtil.isNEString(data[K_TITLE])) {
        this.title = data[K_TITLE]
      }

      if (data[K_TYPE] === K_ITUNES) {
        this.iTunes = true
      }

      if (data[K_TYPE] === K_SHARED) {
        this.isSharing = true
      } else if (data[K_TYPE] === K_LOCAL) {
        this.isSharing = false
      }

      if (BasUtil.isNEString(data[K_OWNER])) {
        this.ownerUuid = data[K_OWNER]
      }

      if (data[K_IMAGES] && BasUtil.isNEArray(data[K_IMAGES][K_ORIG])) {
        this.parseCoverArt(data[K_IMAGES][K_ORIG])
      }
    }
  }

  /**
   * Checks whether the playlist is editable, shareable
   *
   * @param {string} [sourceNameOrUuid] Source name or uuid to check against the
   * owner
   */
  BasPlaylist.prototype.check = function (sourceNameOrUuid) {

    var owned = (
      this.owner === sourceNameOrUuid ||
      this.ownerUuid === sourceNameOrUuid
    )

    // Editable

    if (this.iTunes) {

      this.editable = true

    } else {

      if (BasUtil.isNEString(sourceNameOrUuid)) {

        this.editable = owned
      }
    }

    // Shareable

    if (BasUtil.isNEString(sourceNameOrUuid)) {

      this.shareable = owned
    }
  }

  /**
   * @param {Object[]} result
   */
  BasPlaylist.prototype.parseCoverArt = function (result) {

    var biIcon, bi, coverArts, thumbnails, i, length

    coverArts = []
    thumbnails = []
    this.coverArts = []

    if (Array.isArray(result)) {

      length = result.length
      for (i = 0; i < length; i++) {

        if (BasUtil.isNEString(result[i])) {

          coverArts.push(result[i])
          thumbnails.push(result[i])
          this.coverArts.push(result[i])

        } else {

          if (result[i][K_COVERART]) {

            coverArts.push(result[i][K_COVERART])
          }

          if (result[i][K_THUMBNAIL]) {

            thumbnails.push(result[i][K_THUMBNAIL])
          }
        }
      }
    }

    if (BasUtil.isNEArray(coverArts) ||
        BasUtil.isNEArray(thumbnails)) {

      biIcon = new BasImage()
      biIcon.setQuad(thumbnails)

      bi = new BasImage()
      bi.setQuad(coverArts)

      this.bitIcon.setImage(biIcon)
      this.bit.setImage(bi)

    } else {

      this.bitIcon.setImage(null)
      this.bit.setImage(null)
    }
  }

  return BasPlaylist
}
