'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('localLibraryCtrl', [
    '$scope',
    '$uiRouterGlobals',
    'BAS_APP_PROFILE',
    'BAS_LIBRARY',
    'BasLibraryHeader',
    'BasLibraryPage',
    'LocalManager',
    'LibraryState',
    'LibraryService',
    'BasCurrentAppProfile',
    'CurrentBasCore',
    'CoverartHelper',
    localLibraryCtrl
  ])

/**
 *
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_APP_PROFILE} BAS_APP_PROFILE
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param BasLibraryHeader
 * @param BasLibraryPage
 * @param LocalManager
 * @param {LibraryState} LibraryState
 * @param {LibraryService} LibraryService
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CoverartHelper} CoverartHelper
 */
function localLibraryCtrl (
  $scope,
  $uiRouterGlobals,
  BAS_APP_PROFILE,
  BAS_LIBRARY,
  BasLibraryHeader,
  BasLibraryPage,
  LocalManager,
  LibraryState,
  LibraryService,
  BasCurrentAppProfile,
  CurrentBasCore,
  CoverartHelper
) {
  var root = this

  /**
   * @type {?PlayerLibraryState}
   */
  var libraryState = LibraryState.getCurrentState()

  /**
   * @type {?TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  root.BasLibraryPage = BasLibraryPage
  root.BasLibraryHeader = BasLibraryHeader
  root.historyType = BAS_APP_PROFILE.K_LOCAL

  /**
   * @type {BasCurrentAppProfileState}
   */
  root.profileSettings = BasCurrentAppProfile.get()

  /**
   * @type {LocalManager}
   */
  root.manager = libraryState.localManager

  init()

  function init () {

    var params, element, paramAlbum, paramArtist, paramAlbumUri, paramArtistUri

    $scope.$on('$destroy', onDestroy)

    if (!BasUtil.isObject(root.manager)) {

      root.manager = new LocalManager(libraryState)
      libraryState.localManager = root.manager
    }

    // Initialize service
    root.manager.resume()

    if (
      $uiRouterGlobals.params.type &&
      $uiRouterGlobals.params.album &&
      $uiRouterGlobals.params.artist
    ) {

      if (
        CurrentBasCore.hasCore() &&
        CurrentBasCore.hasAVFullSupport()
      ) {

        paramAlbum = decodeURIComponent($uiRouterGlobals.params.album)
        paramArtist = decodeURIComponent($uiRouterGlobals.params.artist)
        paramArtistUri = decodeURIComponent($uiRouterGlobals.params.artistUri)
        paramAlbumUri = decodeURIComponent($uiRouterGlobals.params.albumUri)

        element = {
          album: paramAlbum,
          artist: paramArtist,
          artistUri: paramArtistUri,
          albumUri: paramAlbumUri,
          covers: { large: '', small: '' },
          uri: $uiRouterGlobals.params.type === BAS_LIBRARY.SHORTCUT_ARTIST
            ? paramArtistUri
            : paramAlbumUri
        }

        switch ($uiRouterGlobals.params.type) {

          case BAS_LIBRARY.SHORTCUT_ALBUM:

            if (
              CurrentBasCore.hasCore() &&
              CurrentBasCore.hasAVFullSupport() &&
              currentBasCoreState.core.core.musicLibrary
            ) {

              currentBasCoreState.core.core.musicLibrary
                .getAlbumDetails(paramAlbumUri)
                .then(onAlbumDetails, onAlbumDetails)
            }

            break

          case BAS_LIBRARY.SHORTCUT_ARTIST:

            // Don't need to fetch extra information
            selectElement()
            break
        }

      } else {

        // Legacy

        params = {
          limit: 0,
          offset: 25
        }

        LibraryService.getAlbums(params).then(onAlbums)
      }
    }

    function onAlbumDetails (res) {

      var covers

      if (res.album) {

        covers = CoverartHelper.parseCoverArtImages(res.album.coverartImages)

        element.covers = {
          small: covers.thumbnail,
          large: covers.coverArt
        }
      }

      selectElement()
    }

    function selectElement () {

      root.manager.selectElement(element, $uiRouterGlobals.params.type)
    }
  }

  function onAlbums (result) {

    var element, paramAlbum, paramArtist, album, length, i

    paramAlbum = decodeURIComponent($uiRouterGlobals.params.album)
    paramArtist = decodeURIComponent($uiRouterGlobals.params.artist)
    length = result.length

    for (i = 0; i < length; i++) {

      album = result[i]
      if (album.name === paramAlbum &&
        album.artist === paramArtist) {

        break
      }
    }

    element = {
      album: paramAlbum,
      artist: paramArtist,
      covers: {
        small: album.thumbnailUrl,
        large: album.coverartUrl
      }
    }

    root.manager.selectElement(element, $uiRouterGlobals.params.type)

    $uiRouterGlobals.params.type = ''
    $uiRouterGlobals.params.album = ''
    $uiRouterGlobals.params.artist = ''
  }

  function onDestroy () {

    root.manager.suspend()
  }
}
