'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basLibraryImage', basLibraryImageDirective)

/**
 * @returns coverArtTemplate
 */
function basLibraryImageDirective () {
  return {
    restrict: 'A',
    scope: {},
    controller: [
      '$compile',
      '$scope',
      '$element',
      'BAS_HTML',
      'BAS_LIBRARY',
      'BAS_LOCAL_LIBRARY',
      'LibraryState',
      'LocalParser',
      controller
    ],
    controllerAs: 'basLibraryImageDir',
    bindToController: {
      element: '<?',
      collection: '<?',
      isDetail: '<?'
    }
  }

  /**
   * @param $compile
   * @param $scope
   * @param $element
   * @param {BAS_HTML} BAS_HTML
   * @param {BAS_LIBRARY} BAS_LIBRARY
   * @param {BAS_LOCAL_LIBRARY} BAS_LOCAL_LIBRARY
   * @param {LibraryState} LibraryState
   * @param {LocalParser} LocalParser
   */
  function controller (
    $compile,
    $scope,
    $element,
    BAS_HTML,
    BAS_LIBRARY,
    BAS_LOCAL_LIBRARY,
    LibraryState,
    LocalParser
  ) {
    var basLibraryImageDir = this

    var CSS_HAS_COVER = 'bas-library-has-cover'

    var _destroyed = false
    var _currentTemplate

    basLibraryImageDir.$onChanges = _onChanges
    basLibraryImageDir.$onDestroy = _onDestroy

    function _onChanges (changes) {

      if (changes.element) _syncElement()
    }

    function _syncElement () {

      _basLibraryImageTemplateFinish(
        _getBasLibraryImageTemplate(basLibraryImageDir.element)
      )

      // Local library playlist
      if (
        basLibraryImageDir.element &&
        basLibraryImageDir.element.libraryType === BAS_LIBRARY.TYPE_LOCAL &&
        basLibraryImageDir.element.type === BAS_LOCAL_LIBRARY.T_EL_PLAYLIST
      ) {
        _getLocalPlaylistCoverArt(basLibraryImageDir.element).then(
          _onBasLibraryImageTemplate,
          _onBasLibraryImageTemplateError
        )
      }
    }

    /**
     * @private
     * @param {(string|undefined)} result
     */
    function _onBasLibraryImageTemplate (result) {

      _basLibraryImageTemplateFinish(result)
    }

    function _onBasLibraryImageTemplateError () {

      _basLibraryImageTemplateFinish()
    }

    function _basLibraryImageTemplateFinish (template) {

      if (_destroyed) return

      $element.toggleClass(CSS_HAS_COVER, BasUtil.isNEString(template))

      basLibraryImageDir.coverSizeObj = basLibraryImageDir.isDetail
        ? { coverArtSize: 'large' }
        : basLibraryImageDir.collection

      if (_currentTemplate !== template) {

        _currentTemplate = template
        $element.html(template)
        $compile($element.contents())($scope)
      }
    }

    /**
     * @private
     * @param {BasLibraryElement} element
     * @returns {(string|undefined)}
     */
    function _getBasLibraryImageTemplate (element) {

      if (element && element.setAsanoFavouriteId) {

        // Radio
        if (element.hasRadioCover) return BAS_HTML.radioCoverArt

        // Multiple covers
        if (element.hasMultipleCovers) return BAS_HTML.multipleCoverArt

        // Basic
        if (element.hasCover) return BAS_HTML.basicCoverArt
      }
    }

    /**
     * @private
     * @param {BasLibraryElement} element
     * @returns {Promise}
     */
    function _getLocalPlaylistCoverArt (element) {

      var basSource = LibraryState.getCurrentSource()

      if (
        element &&
        basSource &&
        basSource.source &&
        basSource.source.database &&
        basSource.source.database.getCoverArt
      ) {
        return basSource.source.database.getCoverArt({
          playlist: element.id
        }).then(_onResult, _onError).then(_finally)
      }

      if (basSource.isAudioSource) {

        // Source uses new API, we already have cover art
        return Promise.resolve(_finally())
      }

      return Promise.reject(new Error('not an audio source'))

      function _onResult (result) {
        if (_destroyed) return
        LocalParser.processPlaylistCoverArt(element, result)
      }

      function _onError () {
        // Empty
      }

      function _finally () {
        if (_destroyed) return
        return _getBasLibraryImageTemplate(element)
      }
    }

    function _onDestroy () {
      _destroyed = true
    }
  }
}
