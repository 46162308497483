'use strict'

angular
  .module('basalteApp')
  .controller('homeScenesCtrl', [
    'BAS_ROOMS',
    homeScenesCtrl
  ])

/**
 * @param {BAS_ROOMS} BAS_ROOMS
 */
function homeScenesCtrl (BAS_ROOMS) {

  var homeScenes = this

  /**
   * @type {BasRooms}
   */
  homeScenes.rooms = BAS_ROOMS.ROOMS
}
