'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basMarquee')
  .directive('basMarquee', basMarquee)

function basMarquee () {

  var CSS_MARQUEE = 'bmq-marquee'
  var CSS_HAS_SUBTITLE = 'bmq-has-subtitle'
  var CSS_HAS_TITLE = 'bmq-has-title'
  var CSS_TOO_SMALL = 'bmq-too-small'
  var CSS_INVISIBLE = 'bmqc-invisible'

  return {
    restrict: 'EA',
    scope: {
      enabled: '@',
      title: '<',
      subtitle: '<',
      maskPercentage: '<',
      speed: '<',
      duration: '<',
      noDefaultMargin: '<',
      sharedState: '<'
    },
    template:
      '<div class="bmq-wrapper" ' +
      'ng-style="marquee.wrapperStyle" ' +
      'ng-class="marquee.css">' +
      '<div class="' + CSS_INVISIBLE + '">' +
      '<div class="bmq-el bmq-title" ' +
      'ng-bind="marquee.title"></div>' +
      '<div class="bmq-separator"></div>' +
      '<div class="bmq-el bmq-subtitle" ' +
      'ng-bind="marquee.subtitle"></div>' +
      '</div>' +
      '<div class="bmq-moving-box" ' +
      'ng-style="marquee.movingStyle">' +
      '<div class="bmqc bmq-1">' +
      '<div class="bmq-el bmq-title" ' +
      'ng-bind="marquee.delayTitle"></div>' +
      '<div class="bmq-separator"></div>' +
      '<div class="bmq-el bmq-subtitle" ' +
      'ng-bind="marquee.delaySubtitle"></div>' +
      '</div>' +
      '<div class="bmqc bmq-2">' +
      '<div class="bmq-el bmq-title" ' +
      'ng-bind="marquee.delayTitle"></div>' +
      '<div class="bmq-separator"></div>' +
      '<div class="bmq-el bmq-subtitle" ' +
      'ng-bind="marquee.delaySubtitle"></div>' +
      '</div>' +
      '</div>' +
      '</div>',
    controller: [
      '$window',
      '$scope',
      '$element',
      'BasMarqueeState',
      controller
    ],
    controllerAs: 'marquee',
    bindToController: true
  }

  /**
   * @param $window
   * @param $scope
   * @param $element
   * @param BasMarqueeState
   */
  function controller (
    $window,
    $scope,
    $element,
    BasMarqueeState
  ) {
    var marquee, state, titleElement, timeoutId, animationTimeoutId
    var listener

    var ANIMATION_DURATION_S = 10

    var ANIMATION_START_DELAY = 2000
    var ANIMATION_END_DELAY = 2000

    var DEBOUNCE_TIME = 200

    var eventListenerOptions = {
      capture: false,
      passive: true,
      once: false
    }

    /**
     * @type {BasMarqueeState}
     */
    state = new BasMarqueeState()
    state.setIsReadyCheck(isReady)
    state.setStartDelayedAnimation(onSharedStart)

    marquee = this

    marquee.delayTitle = marquee.title
    marquee.delaySubtitle = marquee.subtitle

    animationTimeoutId = 0

    marquee.css = {}
    _resetAllCss()

    marquee.movingStyle = {}
    _resetStyle()

    marquee.wrapperStyle = {}

    // Angular life-cycle hooks
    marquee.$postLink = postLink
    marquee.$onChanges = onChanges
    marquee.$onDestroy = onDestroy

    function postLink () {

      // Add resize listener
      listener = BasUtil.setDOMListener(
        $window,
        'resize',
        onResize,
        eventListenerOptions
      )

      titleElement = $element[0].getElementsByClassName(CSS_INVISIBLE)[0]

      checkConfig()
      checkMask()

      if (BasUtil.isObject(marquee.sharedState)) {

        marquee.sharedState.addMarqueeState(state)
      }

      waitForFrames(2, checkMarquee)
    }

    function onChanges () {

      if (marquee.css[CSS_MARQUEE] !== (marquee.enabled === 'true')) {

        checkConfig()
      }

      _resetEffectCss()
      checkMask()

      state.onAnimationDone()

      // After a pause/resume it can take up to 10 frames before the
      // correct title and subtitle have been set
      waitForFrames(10, checkMarquee)
    }

    function onDebouncedResize () {

      checkMarquee()
    }

    function onSharedStart () {

      // Stop any potential ongoing animation
      _resetStyle()

      if (state.shouldRun) {

        startDelayedAnimation(ANIMATION_START_DELAY)
      }

      $scope.$applyAsync()
    }

    /**
     * Checks if marquee is return to start
     * Always true if marquee is not necessary
     *
     * @returns {boolean}
     */
    function isReady () {

      return true
    }

    function checkConfig () {

      _resetConfigCss()
      _resetStyle()

      if (marquee.enabled === 'true') {

        marquee.css[CSS_MARQUEE] = true
      }
    }

    function checkWidth () {

      var containerWidth, titleWidth, maskWidth

      _resetEffectCss()

      if (titleElement) {

        containerWidth = $element[0].offsetWidth
        titleWidth = titleElement.offsetWidth

        maskWidth = BasUtil.isPNumber(marquee.maskPercentage)
          ? marquee.maskPercentage / 100
          : 0

        if (titleWidth > containerWidth * (1 - maskWidth)) {

          marquee.css[CSS_TOO_SMALL] = true
        }
      }

      // Check mask again
      checkMask()
    }

    function checkMask () {

      var mask, maskWidthHalf

      maskWidthHalf = BasUtil.isPNumber(marquee.maskPercentage)
        ? marquee.maskPercentage / 2
        : 0

      if (maskWidthHalf > 0) {

        mask = 'linear-gradient(' +
          'to right, ' +
          'transparent 0%, ' +
          'black ' + maskWidthHalf + '%, ' +
          'black ' + (100 - maskWidthHalf) + '%, ' +
          'transparent 100%)'

        marquee.wrapperStyle['webkitMask'] = mask
        marquee.wrapperStyle['mask'] = mask

        if (!marquee.noDefaultMargin ||
          (
            marquee.css[CSS_MARQUEE] &&
            marquee.css[CSS_TOO_SMALL]
          )
        ) {

          marquee.movingStyle['marginLeft'] = maskWidthHalf + '%'

        } else {

          marquee.movingStyle['marginLeft'] = 'unset'
        }

      } else {

        marquee.wrapperStyle['webkitMask'] = 'unset'
        marquee.wrapperStyle['mask'] = 'unset'

        marquee.movingStyle['marginLeft'] = 'unset'
      }
    }

    function checkMarquee () {

      state.shouldRun = calculate()

      if (state.shouldRun) {

        if (BasUtil.isObject(marquee.sharedState)) {

          if (state.started) {

            startDelayedAnimation(ANIMATION_START_DELAY)

          } else {

            // Wait for shared start
          }

        } else {

          startDelayedAnimation(ANIMATION_START_DELAY)
        }
      }

      $scope.$applyAsync()
    }

    /**
     * Calculate if animation could start
     *
     * @returns {boolean}
     */
    function calculate () {

      _resetStyle()

      checkWidth()

      marquee.css[CSS_HAS_TITLE] = !!marquee.title
      marquee.css[CSS_HAS_SUBTITLE] = !!marquee.subtitle
      marquee.delayTitle = marquee.title
      marquee.delaySubtitle = marquee.subtitle

      return (
        marquee.css[CSS_MARQUEE] &&
        marquee.css[CSS_TOO_SMALL]
      )
    }

    function startDelayedAnimation (delay) {

      clearTimeout(animationTimeoutId)
      animationTimeoutId = setTimeout(
        onAnimationPauseTimeout,
        delay
      )
    }

    function onAnimationPauseTimeout () {

      var width, duration, speed

      if (titleElement) {

        width = titleElement.offsetWidth

        speed = BasUtil.isPNumber(marquee.speed)
          ? marquee.speed
          : 0

        if (speed > 0) {

          duration = width / speed

        } else {

          duration = BasUtil.isPNumber(marquee.duration)
            ? marquee.duration
            : ANIMATION_DURATION_S
        }

        marquee.movingStyle['transition'] =
          'transform ' + duration + 's linear'
        marquee.movingStyle['transform'] =
          'translateX(-' + width + 'px)'
      }

      state.onAnimationStart()

      clearTimeout(animationTimeoutId)
      animationTimeoutId = setTimeout(
        onAnimationPlayingTimeout,
        duration * 1000
      )

      $scope.$applyAsync()
    }

    function onAnimationPlayingTimeout () {

      state.onAnimationDone()

      if (BasUtil.isObject(marquee.sharedState)) {

        // Wait for shared start

      } else {

        // Stop any potential ongoing animation
        _resetStyle()

        startDelayedAnimation(ANIMATION_END_DELAY)

        $scope.$applyAsync()
      }
    }

    /**
     * Fires on each resize event. Needs to be debounced
     */
    function onResize () {

      // Make sure previous timeout is cleared
      $window.clearTimeout(timeoutId)

      // Set a new timeout
      timeoutId = $window.setTimeout(
        onDebouncedResize,
        DEBOUNCE_TIME
      )
    }

    function onDestroy () {

      titleElement = null

      // Make sure previous timeout is cleared
      $window.clearTimeout(timeoutId)

      // Clear resize listener
      if (listener) listener()
      listener = null

      if (BasUtil.isObject(marquee.sharedState)) {

        marquee.sharedState.removeMarqueeState(state)
      }
    }

    function _resetConfigCss () {

      marquee.css[CSS_MARQUEE] = false
    }

    function _resetEffectCss () {

      marquee.css[CSS_TOO_SMALL] = false
    }

    function _resetAllCss () {

      _resetEffectCss()
      _resetConfigCss()
    }

    function _resetStyle () {

      clearTimeout(animationTimeoutId)
      marquee.movingStyle['transform'] = 'none'
      marquee.movingStyle['transition'] = 'none'
    }

    /**
     * @param {number} number
     * @param {Function} callback
     */
    function waitForFrames (
      number,
      callback
    ) {
      var count = 0

      if (BasUtil.isPNumber(number)) {

        $window.requestAnimationFrame(onFrame)

      } else {

        BasUtil.execute(callback)
      }

      function onFrame () {

        count++

        if (count >= number) {

          BasUtil.execute(callback)

        } else {

          $window.requestAnimationFrame(onFrame)
        }
      }
    }
  }
}
