'use strict'

angular
  .module('basalteApp')
  .component('thermostatDetail', {
    template: [
      'BAS_HTML',
      thermostatTemplate
    ],
    bindings: {
      thermostat: '<'
    },
    controller: 'thermostatDetailCtrl',
    controllerAs: 'basThermostat'
  })

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns {string}
 */
function thermostatTemplate (BAS_HTML) {

  return BAS_HTML.thermostatDetail
}
