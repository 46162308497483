'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basNetworkDebug', basNetworkDebug)

function basNetworkDebug () {

  return {
    restrict: 'AE',
    template: '' +
      '<div>' +
      'CONNECTION' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="basDebug.connectionType"></span>' +
      '</div>' +
      '<div class="bas-connect-stats-webrtc">' +
      'WebRTC mode' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="basDebug.webRTCMode"></span>' +
      '</div>' +
      '<div class="bas-connect-stats-list-item bas-connect-stats-webrtc"' +
      ' ng-repeat="candidate in basDebug.webRTCCandidates"' +
      ' ng-class="{' +
      '   \'bas-connect-stats-highlight\':' +
      '       basDebug.webRTCTransport.selectedCandidatePairId' +
      '       ===' +
      '       candidate.pair.id' +
      ' }">' +
      'candidate-pair' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="candidate.type"></span>' +
      ' l:' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="candidate.local.candidateType"></span>' +
      ' r:' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="candidate.remote.candidateType"></span>' +
      '</div>' +
      '<div>' +
      'Total time' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.totalTime' +
      '"></span> ms' +
      '</div>' +
      '<div>' +
      '# candidates' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.numberOfCandidates' +
      '"></span>' +
      '</div>' +
      '<div>' +
      '# retries' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.numberOfRetries' +
      '"></span>' +
      '</div>' +
      '<div>' +
      'Connect retry total' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.connectRetryTotal' +
      '"></span> ms' +
      '</div>' +
      '<div>' +
      'Connect time' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.connectTotal' +
      '"></span> ms' +
      '</div>' +
      '<div class="bas-connect-stats-webrtc">' +
      'Live subscriptions' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.liveSubscriptionsReady' +
      '"></span> ms' +
      '</div>' +
      '<div class="bas-connect-stats-webrtc">' +
      'Live ICE servers' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.liveIceServersReceived' +
      '"></span> ms' +
      '</div>' +
      '<div class="bas-connect-stats-webrtc">' +
      'Live ICE servers + subs' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.liveSubscriptionsAndIceServer' +
      '"></span> ms' +
      '</div>' +
      '<div class="bas-connect-stats-webrtc">' +
      'Live # local ICE cached' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.liveLocalIceCandidatesCached' +
      '"></span>' +
      '</div>' +
      '<div class="bas-connect-stats-webrtc">' +
      'Live Answer' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.liveAnswer' +
      '"></span> ms' +
      '</div>' +
      '<div class="bas-connect-stats-webrtc">' +
      'Live WebRTC' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.liveWebRTCTime' +
      '"></span> ms' +
      '</div>' +
      '<div class="bas-connect-stats-webrtc">' +
      'Live total' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="' +
      'basDebug.currentBasCoreState.core.core._server.basConnectStats' +
      '.liveTotal' +
      '"></span> ms' +
      '</div>' +
      '<div class="bas-connect-stats-list-item bas-connect-stats-webrtc"' +
      ' ng-repeat="' +
      'candidate in basDebug.currentBasCoreState.core.core._server' +
      '.basConnectStats.liveLocalIceCandidates' +
      '">' +
      'Local ICE C' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="candidate.type"></span>,' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="candidate.time"></span> ms' +
      '</div>' +
      '<div class="bas-connect-stats-list-item bas-connect-stats-webrtc"' +
      ' ng-repeat="' +
      'candidate in basDebug.currentBasCoreState.core.core._server' +
      '.basConnectStats.liveRemoteIceCandidates' +
      '">' +
      'Remote ICE C' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="candidate.type"></span>,' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="candidate.time"></span> ms' +
      '</div>' +
      '<div>' +
      'Using remote camera URL' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="basDebug.isRemote"></span>' +
      '</div>' +
      'Telemetry UUID' +
      ' <span class="font-weight--bold"' +
      ' ng-bind="basDebug.telemetryUuid"></span>' +
      '</div>',
    controller: [
      '$rootScope',
      '$scope',
      'BAS_API',
      'BAS_CURRENT_CORE',
      'CurrentBasCore',
      controller
    ],
    controllerAs: 'basDebug',
    link: link
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param BAS_API
   * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
   * @param {CurrentBasCore} CurrentBasCore
   */
  function controller (
    $rootScope,
    $scope,
    BAS_API,
    BAS_CURRENT_CORE,
    CurrentBasCore
  ) {
    var basDebug = this

    var CSS_CONNECT_TYPE_DEMO = 'bas-connect-type--demo'
    var CSS_CONNECT_TYPE_LOCAL = 'bas-connect-type--local'
    var CSS_CONNECT_TYPE_WEBRTC = 'bas-connect-type--webrtc'

    var _listeners

    /**
     * @type {TCurrentBasCoreState}
     */
    basDebug.currentBasCoreState = CurrentBasCore.get()

    /**
     * @type {string}
     */
    basDebug.connectionType = '-'

    /**
     * @type {boolean}
     */
    basDebug.isRemote = false

    /**
     * @type {string}
     */
    basDebug.webRTCModa = '-'

    /**
     * @type {*[]}
     */
    basDebug.webRTCCandidates = []

    /**
     * @type {?Object}
     */
    basDebug.webRTCTransport = null

    /**
     * @type {?string}
     */
    basDebug.telemetryUuid = window.basTModule.uuid

    basDebug.$postLink = _postLink
    basDebug.$onDestroy = _onDestroy

    function _postLink () {

      init()
    }

    function init () {

      _listeners = []

      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED,
        _onCoreChanged
      ))
      _listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
        _onCoreCoreConnected
      ))

      _syncConnectionInfo()
    }

    function _onCoreChanged () {

      _syncConnectionInfo()

      $scope.$applyAsync()
    }

    function _onCoreCoreConnected () {

      _syncConnectionInfo()

      $scope.$applyAsync()
    }

    function _syncConnectionInfo () {

      var _basServer, _peerConnection, _attrs

      basDebug.connectionType = '-'
      basDebug.isRemote = false
      basDebug.webRTCMode = '-'
      basDebug.webRTCCandidates = []
      basDebug.webRTCTransport = null

      _attrs = _getAttrs()

      if (_attrs) {

        _attrs.$removeClass(
          CSS_CONNECT_TYPE_DEMO + ' ' +
          CSS_CONNECT_TYPE_LOCAL + ' ' +
          CSS_CONNECT_TYPE_WEBRTC
        )
      }

      if (CurrentBasCore.hasCore()) {

        _basServer = basDebug.currentBasCoreState.core.core.server

        if (_basServer) {

          if (_basServer instanceof BAS_API.BasHttpServer) {

            basDebug.connectionType = 'WebSocket'

            if (_attrs) _attrs.$addClass(CSS_CONNECT_TYPE_LOCAL)

          } else if (_basServer instanceof BAS_API.BasRemoteServer) {

            basDebug.connectionType = 'WebRTC'
            basDebug.isRemote = true

            /**
             * @type {?RTCPeerConnection}
             */
            _peerConnection = _basServer._peerConnection

            if (_peerConnection) {

              _peerConnection.getStats().then(_onStats)
            }

            if (_attrs) _attrs.$addClass(CSS_CONNECT_TYPE_WEBRTC)

          } else if (_basServer instanceof BAS_API.BasDemoServer) {

            basDebug.connectionType = 'DEMO'

            if (_attrs) _attrs.$addClass(CSS_CONNECT_TYPE_DEMO)
          }
        }
      }

      function _onStats (result) {

        var _transport, _candidates, _mode
        var _candidate, _local, _remote
        var length, i, entry

        _candidates = []
        _transport = null

        length = result.length
        for (i = 0; i < length; i++) {

          entry = result[i]

          if (entry.type === 'candidate-pair') {

            if (entry.state === 'succeeded') {

              _local = result.get(entry.localCandidateId)
              _remote = result.get(entry.remoteCandidateId)

              _candidate = {
                pair: entry,
                local: _local,
                remote: _remote
              }

              if (_local.candidateType === 'relay' ||
                _remote.candidateType === 'relay') {

                _candidate.type = 'relay'

              } else if (_local.candidateType === 'host' ||
                _remote.candidateType === 'host') {

                _candidate.type = 'host'

              } else {

                _candidate.type = 'stun'
              }

              _candidates.push(_candidate)
            }

          } else if (entry.type === 'transport') {

            _transport = entry
          }
        }

        if (_transport && _transport.selectedCandidatePairId) {

          length = _candidates.length
          for (i = 0; i < length; i++) {

            entry = _candidates[i]

            if (entry.pair.id ===
              _transport.selectedCandidatePairId) {

              if (entry.local.candidateType === 'relay' ||
                entry.remote.candidateType === 'relay') {

                _mode = 'TURN'

              } else if (entry.local.candidateType === 'host' ||
                entry.remote.candidateType === 'host') {

                _mode = 'DIRECT'

              } else {

                _mode = 'STUN'
              }
            }
          }
        }

        basDebug.webRTCMode = _mode || '-'
        basDebug.webRTCCandidates = _candidates
        basDebug.webRTCTransport = _transport

        $scope.$applyAsync()
      }
    }

    function _getAttrs () {

      return basDebug.basAttrs
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = undefined
    }
  }

  function link (scope, _element, attrs) {

    scope.basDebug.basAttrs = attrs
  }
}
