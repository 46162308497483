'use strict'

angular
  .module('basalteApp')
  .constant('BAS_FAVOURITE', {
    T_UNKNOWN: 0,
    T_LOCAL_PLAYLIST: 1,
    T_RADIO: 2,
    T_DEEZER: 3,
    T_TIDAL: 4,
    T_SPOTIFY_CONNECT: 5,
    T_SPOTIFY: 6,
    T_SONOS: 7,
    T_VIDEO: 8,
    CT_DEEZER_PLAYLIST: 'playlist',
    CT_DEEZER_RADIO: 'radio',
    CT_TIDAL_PLAYLIST: 'playlist',
    SEP_DEEZER: ':',
    SEP_TIDAL: ':',
    SEP_SPOTIFY: ':',
    UUID_DEEZER_FLOW: 'deezerFlow',
    UUID_SPOTIFY_CONNECT_CURRENT: 'spotifyConnectCurrent',
    _K_NAME: 'name',
    _K_PLAYLIST: 'playlist',
    _K_GID: 'gid',
    _K_DEEZER_ID: 'deezerId',
    _K_TIDAL_ID: 'tidalId',
    _K_URI: 'uri',
    _K_SONOS: 'sonos',
    _K_AUDIO: 'audio',
    CSS_SHOW_DELETE: 'icon-show-delete',
    ERR_NO_PLAYLIST: 'No Playlist',
    ERR_WRONG_TYPE: 'Wrong favourite type'
  })
