'use strict'

angular
  .module('basalteApp')
  .service('LocalModel', [
    'BAS_LOCAL_LIBRARY',
    'LocalElement',
    'BasUtilities',
    LocalModel
  ])

/**
 * Helper class for creating LocalElements from Local Objects
 *
 * @constructor
 * @param {BAS_LOCAL_LIBRARY} BAS_LOCAL_LIBRARY
 * @param LocalElement
 * @param {BasUtilities} BasUtilities
 */
function LocalModel (
  BAS_LOCAL_LIBRARY,
  LocalElement,
  BasUtilities
) {

  var SECTION_MORE = 'more'

  /**
   * @constant {string}
   */
  this.SECTION_MORE = SECTION_MORE

  this.createSection = createSection

  /**
   * @param {string} sectionType
   * @param {string} [type]
   * @param {string} [query]
   * @returns {LocalElement}
   */
  function createSection (sectionType, type, query) {

    // Create new UI element for type
    var element = new LocalElement()

    // Set ID
    element.id = sectionType

    // Type
    element.type = BAS_LOCAL_LIBRARY.T_EL_SECTION

    // Subtype
    element.subType = sectionType

    // Customize according to section type
    switch (sectionType) {

      case SECTION_MORE:

        // Titles
        element.title = BasUtilities.translate('show_more')

        // Has more content
        element.setCanBrowse(true)
        element.searchData.type = type
        element.searchData.query = query

        break
    }

    return element
  }
}
