'use strict'

/**
 * An object representing a notification source
 *
 * @constructor
 * @param {Object} config
 * @param {number} config.id
 * @param {string} config.uuid
 * @param {string} config.name
 * @since 2.1.0
 */
function Notification (config) {

  this._id = config.id
  this._uuid = config.uuid
  this._name = config.name
}

/**
 * @name Notification#id
 * @type {number}
 * @readonly
 */
Object.defineProperty(Notification.prototype, 'id', {
  get: function () {
    return this._id
  }
})

/**
 * @name Notification#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Notification.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

/**
 * @name Notification#name
 * @type {string}
 * @readonly
 */
Object.defineProperty(Notification.prototype, 'name', {
  get: function () {
    return this._name
  }
})

module.exports = Notification
