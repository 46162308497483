'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomGenericDevices', [
    'BAS_API',
    'CurrentBasCore',
    'BasGenericDeviceV1',
    'BasGenericDeviceV2',
    basRoomGenericDevicesFactory
  ])

/**
 * @param BAS_API
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasGenericDeviceV1} BasGenericDeviceV1
 * @param {BasGenericDeviceV2} BasGenericDeviceV2
 * @returns BasRoomGenericDevices
 */
function basRoomGenericDevicesFactory (
  BAS_API,
  CurrentBasCore,
  BasGenericDeviceV1,
  BasGenericDeviceV2
) {
  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomGenericDevices (basRoom) {

    /**
     * All Devices
     *
     * @type {(BasGenericDeviceV1 | BasGenericDeviceV2)[]}
     */
    this.devices = []

    /**
     * @type {BasRoom}
     * @private
     */
    this._basRoom = basRoom

    if (this._basRoom) this._basRoom.cssSetHasGenericDevices(true)

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomGenericDevices.hasDevices = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.genericDevices)
    )
  }

  /**
   * Parse the raw room from the parent BasRoom
   */
  BasRoomGenericDevices.prototype.parseRoom = function parseRoom () {

    if (BasRoomGenericDevices.hasDevices(this._basRoom)) {

      this.syncDevices()
    }
  }

  BasRoomGenericDevices.prototype.syncDevices = function syncDevices () {

    var length, i, uuid, apiDevice

    this.resetDevices()

    if (BasRoomGenericDevices.hasDevices(this._basRoom)) {

      length = this._basRoom.room.genericDevices.length

      for (i = 0; i < length; i++) {

        uuid = this._basRoom.room.genericDevices[i]
        apiDevice = CurrentBasCore.getDevice(uuid)

        if (apiDevice) {
          if (apiDevice.type === BAS_API.Device.T_GENERIC_V2) {
            this.devices.push(new BasGenericDeviceV2(apiDevice))
          } else {
            this.devices.push(new BasGenericDeviceV1(apiDevice))
          }

        }
      }
    }

    this.checkDeviceChange()
  }

  /**
   * Checks if this BasRoomGenericDevices instance has any devices
   *
   * @returns {boolean}
   */
  BasRoomGenericDevices.prototype.hasDevices = function () {

    return BasUtil.isNEArray(this.devices)
  }

  /**
   * Checks whether a device in the room is active
   *
   * @returns {boolean}
   */
  BasRoomGenericDevices.prototype.areDevicesActive = function () {

    var i, length, device

    length = this.devices.length

    for (i = 0; i < length; i++) {

      device = this.devices[i]

      if (device && device.areControlsActive && device.areControlsActive()) {
        return true
      }
    }

    return false
  }

  /**
   * Checks whether a device in the room is active
   */
  BasRoomGenericDevices.prototype.checkDeviceChange = function () {

    if (this._basRoom) {

      this._basRoom.cssSetActiveGenericDevices(this.areDevicesActive())
    }
  }

  /**
   * Creates new BasGenericDevice instances from
   * current real BasGenericDevice instances
   *
   * @returns {(BasGenericDeviceV1|BasGenericDeviceV2)[]}
   */
  BasRoomGenericDevices.prototype.createEditableClones = function () {

    var length, i, device, clone, result

    result = []

    length = this.devices.length
    for (i = 0; i < length; i++) {

      device = this.devices[i]

      if (device.isEditable()) {

        clone = device.clone()

        clone.fillInUuid()
        clone.removeNonEditableControls()

        result.push(clone)
      }
    }

    return result
  }

  /**
   * Gets a BasGenericDevice for the uuid
   *
   * @param {string} uuid
   * @returns {?(BasGenericDeviceV1 | BasGenericDeviceV2)}
   */
  BasRoomGenericDevices.prototype.getDevice = function getDevice (uuid) {

    var i, length

    length = this.devices.length

    for (i = 0; i < length; i++) {

      if (this.devices[i].uuid === uuid) return this.devices[i]
    }

    return null
  }

  /**
   * Clears all devices
   */
  BasRoomGenericDevices.prototype.clearDevices = function clearDevices () {

    var i, length

    length = this.devices.length
    for (i = 0; i < length; i++) {

      if (this.devices[i]) this.devices[i].clear()
    }
  }

  BasRoomGenericDevices.prototype.suspendDevices = function suspendDevices () {

    var i, length

    length = this.devices.length
    for (i = 0; i < length; i++) {

      if (this.devices[i]) this.devices[i].suspend()
    }
  }

  /**
   * Resets the devices, removing them
   */
  BasRoomGenericDevices.prototype.resetDevices = function resetDevices () {

    this.clearDevices()

    this.devices = []
  }

  BasRoomGenericDevices.prototype.suspend = function suspend () {

    this.suspendDevices()
  }

  BasRoomGenericDevices.prototype.destroy = function destroy () {

    this.resetDevices()
  }

  BasRoomGenericDevices.prototype.updateTranslation = function () {

    var length, i, device

    length = this.devices.length
    for (i = 0; i < length; i++) {

      device = this.devices[i]
      if (device && device.updateTranslation) device.updateTranslation()
    }
  }

  return BasRoomGenericDevices
}
