'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('lisaTilesCtrl', [
    '$rootScope',
    '$scope',
    'BAS_ROOMS',
    'BAS_STATE',
    'STATES',
    'BAS_PREFERENCES',
    'BAS_LISA',
    'BasLisaUi',
    'BasTile',
    'BasState',
    'BasCameras',
    'BasTime',
    'CurrentRoom',
    'Weather',
    'BasPreferences',
    lisaTilesCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_STATE} BAS_STATE
 * @param {STATES} STATES
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BAS_LISA} BAS_LISA
 * @param {BasLisaUi} BasLisaUi
 * @param BasTile
 * @param {BasState} BasState
 * @param {BasCameras} BasCameras
 * @param {BasTime} BasTime
 * @param {CurrentRoom} CurrentRoom
 * @param {Weather} Weather
 * @param {BasPreferences} BasPreferences
 */
function lisaTilesCtrl (
  $rootScope,
  $scope,
  BAS_ROOMS,
  BAS_STATE,
  STATES,
  BAS_PREFERENCES,
  BAS_LISA,
  BasLisaUi,
  BasTile,
  BasState,
  BasCameras,
  BasTime,
  CurrentRoom,
  Weather,
  BasPreferences
) {

  var lisa = this

  var _listeners = []

  lisa.selectTile = selectTile

  lisa.showWeather = BasPreferences.getStartPageWidget() ===
        BAS_PREFERENCES.START_PAGE_WIDGET_WEATHER

  /**
   * @type {TCurrentRoomState}
   */
  lisa.room = CurrentRoom.get()

  /**
   * @type {TWeatherState}
   */
  lisa.weather = Weather.get()

  /**
   * @type {TBasTime}
   */
  lisa.time = BasTime.get()

  /**
   * @type {TBasLisaUi}
   */
  lisa.state = BasLisaUi.get()

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_LISA.EVT_LISA_UPDATED,
      _onLisaUpdated
    ))
  }

  function _onLisaUpdated () {

    $scope.$applyAsync()
  }

  function selectTile (tile) {

    if (BasUtil.isObject(tile)) {

      switch (tile.id) {
        case BasTile.ID_LISA_START:

          BasState.go(STATES.LISA, {
            view: BAS_STATE.S_LISA_VIEW_HOME
          })

          break
        case BAS_ROOMS.FUNCTIONS.SCENES:

          BasState.go(STATES.LISA_SCENES)

          break
        case BAS_ROOMS.FUNCTIONS.THERMOSTAT:

          BasState.go(STATES.LISA_THERMOSTAT)

          break
        case BAS_ROOMS.FUNCTIONS.LIGHTS:

          BasState.go(STATES.LISA_LIGHTS)

          break
        case BAS_ROOMS.FUNCTIONS.MUSIC:

          BasState.go(STATES.LISA_MUSIC)

          break
        case BAS_ROOMS.FUNCTIONS.SHADES:

          BasState.go(STATES.LISA_SHADES)

          break
        case BasTile.ID_CAMERAS:

          BasCameras.goToCameras()

          break
        case BasTile.ID_SETTINGS:

          BasState.go(STATES.SETTINGS)

          break
        case BasTile.ID_INTERCOM:

          BasState.go(STATES.LISA_INTERCOM)

          break
      }
    }
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
