'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

var Device = require('./device')

/**
 * @typedef {Object} TBasEnergyMeterGetDataOptions
 * @property {string} start ISO 8601 string
 * @property {string} [stop] ISO 8601 string, by default "now"
 * @property {number} [step = 3600000] time in ms
 * @property {number} [limit = 200] Maximum number of entries in data
 * @property {number} [offset = 0] Index for data points (combine with limit)
 */

/**
 * @typedef {Object} TBasEnergyMeterResult
 * @property {string} start ISO 8601 with timezone offset
 * @property {string} stop ISO 8601 with timezone offset
 * @property {number} step
 * @property {number} limit
 * @property {number} offset
 * @property {Array<Array<(string|number)>>} result
 */

/**
 * @constructor
 * @extends Device
 * @param {TDevice} device
 * @param {BasCore} basCore
 */
function EnergyDevice (device, basCore) {

  Device.call(this, device, basCore)

  this._realTimeUnit = BasUtil.isNEString(device[P.REAL_TIME_UNIT])
    ? device[P.REAL_TIME_UNIT]
    : ''
  this._totalUnit = BasUtil.isNEString(device[P.TOTAL_UNIT])
    ? device[P.TOTAL_UNIT]
    : ''
  this._resourceType = BasUtil.isNEString(device[P.RESOURCE_TYPE])
    ? device[P.RESOURCE_TYPE]
    : ''
  // TODO Legacy, remove check for number
  this._virtual = BasUtil.isVNumber(device[P.VIRTUAL])
    ? !!device[P.VIRTUAL]
    : BasUtil.isBool(device[P.VIRTUAL])
      ? device[P.VIRTUAL]
      : false
  // TODO Legacy, remove
  this._oldestTimestamp = BasUtil.isNEString(device[P.OLDEST_TIMESTAMP])
    ? device[P.OLDEST_TIMESTAMP]
    : ''

  this._stateOldestTimestamp = ''
  this._stateValue = 0
  this._stateTimestamp = ''

  this._handleData = this._onData.bind(this)

  this.parse(device, { emit: false })
}

EnergyDevice.prototype = Object.create(Device.prototype)
EnergyDevice.prototype.constructor = EnergyDevice

/**
 * @event EnergyDevice#EVT_STATE
 */

/**
 * @constant {string}
 */
EnergyDevice.EVT_STATE = 'evtBasEnergyDeviceStateChanged'

/**
 * @constant {string}
 */
EnergyDevice.T_ENERGY = P.ENERGY

/**
 * @constant {string}
 */
EnergyDevice.T_GAS = P.GAS

/**
 * @constant {string}
 */
EnergyDevice.T_WATER = P.WATER

/**
 * @constant {string}
 */
EnergyDevice.RT_CONSUMER = P.CONSUMER

/**
 * @constant {string}
 */
EnergyDevice.RT_GENERATOR = P.GENERATOR

/**
 * @constant {string}
 */
EnergyDevice.RT_STORAGE = P.STORAGE

/**
 * @constant {string}
 */
EnergyDevice.RT_NET = P.NET

Object.defineProperties(EnergyDevice.prototype, {

  /**
   * @name EnergyDevice#virtual
   * @type {boolean}
   * @readonly
   */
  virtual: {
    get: function () {
      return this._virtual
    }
  },

  /**
   * @name EnergyDevice#realTimeUnit
   * @type {string}
   * @readonly
   */
  realTimeUnit: {
    get: function () {
      return this._realTimeUnit
    }
  },

  /**
   * @name EnergyDevice#totalUnit
   * @type {string}
   * @readonly
   */
  totalUnit: {
    get: function () {
      return this._totalUnit
    }
  },

  /**
   * @name EnergyDevice#resourceType
   * @type {string}
   * @readonly
   */
  resourceType: {
    get: function () {
      return this._resourceType
    }
  },

  /**
   * @deprecated
   * @name EnergyDevice#oldestTimestamp
   * @type {string}
   * @readonly
   */
  oldestTimestamp: {
    get: function () {
      return this._oldestTimestamp
    }
  },

  /**
   * @name EnergyDevice#stateOldestTimestamp
   * @type {string}
   * @readonly
   */
  stateOldestTimestamp: {
    get: function () {
      return this._stateOldestTimestamp
    }
  },

  /**
   * @name EnergyDevice#stateValue
   * @type {number}
   * @readonly
   */
  stateValue: {
    get: function () {
      return this._stateValue
    }
  },

  /**
   * @name EnergyDevice#stateTimestamp
   * @type {string}
   * @readonly
   */
  stateTimestamp: {
    get: function () {
      return this._stateTimestamp
    }
  }
})

/**
 * @param {Object} msg
 * @param {TDeviceParseOptions} options
 * @returns {boolean}
 */
EnergyDevice.prototype.parse = function (
  msg,
  options
) {
  var valid, emit
  var value, _stateChanged

  emit = true
  valid = Device.prototype.parse.call(this, msg, options)

  if (BasUtil.isObject(options)) {

    if (BasUtil.isBool(options.emit)) emit = options.emit
  }

  if (valid) {

    if (BasUtil.isObject(msg[P.STATE])) {

      value = msg[P.STATE][P.OLDEST_TIMESTAMP]
      if (BasUtil.isNEString(value) && value !== this._stateOldestTimestamp) {
        _stateChanged = true
        this._stateOldestTimestamp = value
      }

      value = msg[P.STATE][P.VALUE]
      if (BasUtil.isVNumber(value) && value !== this._stateValue) {
        _stateChanged = true
        this._stateValue = value
      }

      value = msg[P.STATE][P.TIMESTAMP]
      if (BasUtil.isNEString(value) && value !== this._stateTimestamp) {
        _stateChanged = true
        this._stateTimestamp = value
      }
    }
  }

  if (emit) {
    if (_stateChanged) this.emit(EnergyDevice.EVT_STATE)
  }
}

/**
 * @param {TBasEnergyMeterGetDataOptions} options
 * @returns {Promise<TBasEnergyMeterResult>}
 */
EnergyDevice.prototype.getData = function (options) {
  var msg, _start, _stop, _step, _limit, _offset

  if (options) {

    _start = options.start
    _stop = options.stop
    _step = options.step
    _limit = options.limit
    _offset = options.offset

    if (BasUtil.isNEString(_start)) {

      msg = this._getBasCoreMessage()
      msg[P.DEVICE][P.ACTION] = P.GET_AGGREGATE_DATA
      msg[P.DEVICE][P.DATA] = {}
      msg[P.DEVICE][P.DATA][P.START] = _start
      if (_stop) msg[P.DEVICE][P.DATA][P.STOP] = _stop
      msg[P.DEVICE][P.DATA][P.STEP] = BasUtil.isPNumber(_step, false)
        ? _step
        : 3600000

      if (BasUtil.isVNumber(_limit)) msg[P.DEVICE][P.DATA][P.LIMIT] = _limit
      if (BasUtil.isVNumber(_offset)) msg[P.DEVICE][P.DATA][P.OFFSET] = _offset

      return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
        .then(Device.handleResponse)
        .then(this._handleData)
    }
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @private
 * @param result
 * @returns {(TBasEnergyMeterResult|Promise)}
 */
EnergyDevice.prototype._onData = function (result) {

  var _result

  if (result && result[P.DEVICE] && result[P.DEVICE][P.DATA]) {

    _result = {}
    _result.start = result[P.DEVICE][P.DATA][P.START]
    _result.stop = result[P.DEVICE][P.DATA][P.STOP]
    _result.step = result[P.DEVICE][P.DATA][P.STEP]
    _result.limit = result[P.DEVICE][P.DATA][P.LIMIT]
    _result.offset = result[P.DEVICE][P.DATA][P.OFFSET]
    _result.result = result[P.DEVICE][P.DATA][P.RESULT]
    return _result
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

module.exports = EnergyDevice
