'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasIntl', [
    '$rootScope',
    'moment',
    'BAS_INTL',
    'BAS_PREFERENCES',
    'BAS_CURRENT_CORE',
    'BasPreferences',
    'CurrentBasCore',
    'BasUtilities',
    BasIntl
  ])

/**
 * @typedef {Object} TBasIntlState
 * @property {string} timeFormat
 * @property {string} uiAM
 * @property {string} uiPM
 * @property {boolean} uiAMPMBefore
 */

/**
 * @param $rootScope
 * @param moment
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BasPreferences} BasPreferences
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasUtilities} BasUtilities
 */
function BasIntl (
  $rootScope,
  moment,
  BAS_INTL,
  BAS_PREFERENCES,
  BAS_CURRENT_CORE,
  BasPreferences,
  CurrentBasCore,
  BasUtilities
) {
  var MOMENT_FORMAT_AMPM = 'A'
  var MOMENT_FORMAT_HOUR_12_SHORT = 'h'
  var MOMENT_FORMAT_HOUR_12 = 'hh'
  var MOMENT_FORMAT_HOUR_24 = 'HH'
  var MOMENT_FORMAT_MINUTES = 'mm'

  var DEFAULT_PM = 'pm'
  var DEFAULT_PM_UPPER = 'PM'
  var DEFAULT_AM = 'am'
  var DEFAULT_AM_UPPER = 'AM'

  /**
   * @type {TBasIntlState}
   */
  var state = {
    timeFormat: BAS_INTL.TIME_FORMAT_24,
    uiAM: 'AM',
    uiPM: 'PM',
    uiAMPMBefore: false
  }
  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {?string}
   */
  var lastKnownSystemTimezone

  this.get = get
  this.format = format
  this.dateToString = dateToString
  this.getFirstDayOfWeek = getFirstDayOfWeek

  init()

  function init () {

    BAS_INTL.DAYS_WEEK = [
      BAS_INTL.DAYS.SUNDAY,
      BAS_INTL.DAYS.MONDAY,
      BAS_INTL.DAYS.TUESDAY,
      BAS_INTL.DAYS.WEDNESDAY,
      BAS_INTL.DAYS.THURSDAY,
      BAS_INTL.DAYS.FRIDAY,
      BAS_INTL.DAYS.SATURDAY
    ]

    _updateDaysOfWeek()
    _onTranslate()
    _updateTimeFormat()

    $rootScope.$on(
      '$translateChangeSuccess',
      _onTranslate
    )
    $rootScope.$on(
      BAS_PREFERENCES.EVT_TIME_FORMAT_PREFERENCE_CHANGED,
      _onTimeFormatPreferenceChanged
    )
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_SYSTEM,
      _onSystemProperties
    )
  }

  /**
   * @returns {TBasIntlState}
   */
  function get () {
    return state
  }

  /**
   * @param {Date} date
   * @param {string} mask
   * @param {string} [timezone]
   * @returns {string}
   */
  function format (date, mask, timezone) {

    var _moment

    _moment = moment(date)

    if (BasUtil.isNEString(timezone) && moment.tz.zone(timezone)) {

      // Set timezone if available
      _moment = _moment.tz(timezone)
    }

    return _moment.format(mask)
  }

  /**
   * Gives a string representation for a given Date object.
   *
   * If 'timezone' parameter is false, system timezone will be used.
   * If 'timezone' parameter is valid timezone string, this timezone will be
   *  used.
   * If 'timezone' parameter is not a valid timezone string or false, the last
   *  known server timezone will be used.
   *
   * @param {Date} date
   * @param {string} type
   * @param {(string|false)} [timezone]
   * @returns {string}
   */
  function dateToString (date, type, timezone) {

    var formatStr, _moment

    formatStr = ''

    if (BasUtil.isObject(date)) {

      switch (type) {

        case BAS_INTL.LOCALE_OPTION_SHORT:

          if (state.timeFormat === BAS_INTL.TIME_FORMAT_12) {

            formatStr = MOMENT_FORMAT_HOUR_12 + ':' + MOMENT_FORMAT_MINUTES

            if (state.uiAMPMBefore) {

              formatStr = MOMENT_FORMAT_AMPM + ' ' + formatStr

            } else {

              formatStr = formatStr + ' ' + MOMENT_FORMAT_AMPM
            }
          } else {

            formatStr = MOMENT_FORMAT_HOUR_24 + ':' + MOMENT_FORMAT_MINUTES
          }
          break

        case BAS_INTL.LOCALE_OPTION_SHORT_WITHOUT_MERIDIEM:

          formatStr = state.timeFormat === BAS_INTL.TIME_FORMAT_12
            ? MOMENT_FORMAT_HOUR_12 + ':' + MOMENT_FORMAT_MINUTES
            : MOMENT_FORMAT_HOUR_24 + ':' + MOMENT_FORMAT_MINUTES
          break

        case BAS_INTL.LOCALE_OPTION_MERIDIEM:

          formatStr = MOMENT_FORMAT_AMPM
          break

        case BAS_INTL.LOCALE_OPTION_LONG:

          formatStr = 'L'
          break

        case BAS_INTL.LOCALE_OPTION_HOUR:

          formatStr = state.timeFormat === BAS_INTL.TIME_FORMAT_12
            ? state.uiAMPMBefore
              ? MOMENT_FORMAT_AMPM + ' ' + MOMENT_FORMAT_HOUR_12_SHORT
              : MOMENT_FORMAT_HOUR_12_SHORT + ' ' + MOMENT_FORMAT_AMPM
            : MOMENT_FORMAT_HOUR_24 + ':' + MOMENT_FORMAT_MINUTES
          break

        case BAS_INTL.LOCALE_OPTION_DAY_MONTH:

          // TODO Make this better localized
          //  (use moment 'LLLL' format and remove extras?)
          formatStr = 'dddd, MMMM D'
          break
      }
    }

    _moment = moment(date)

    if (timezone === false) {

      // Don't change timezone

    } else if (BasUtil.isNEString(timezone) && moment.tz.zone(timezone)) {

      // Set given timezone if valid
      _moment = _moment.tz(timezone)

    } else if (lastKnownSystemTimezone) {

      // Set last known bas core timezone
      _moment = _moment.tz(lastKnownSystemTimezone)
    }

    return _moment.format(formatStr)
  }

  /**
   * Based on browser locale
   *
   * Sunday 0
   * Monday 1
   *
   * Defaults on 1 (Monday)
   *
   * @returns {number}
   */
  function getFirstDayOfWeek () {

    var locale, localMoment

    locale = BasUtilities.getWebLocale()

    if (BasUtil.isNEString(locale)) {

      localMoment = moment()

      localMoment.locale(locale)

      return localMoment.localeData().firstDayOfWeek()
    }

    return 1
  }

  function _updateDaysOfWeek () {

    var idx, i, length, dayIdx

    idx = getFirstDayOfWeek()

    BAS_INTL.DAYS_WEEK_INTL = []

    length = BAS_INTL.DAYS_WEEK.length
    for (i = 0; i < length; i++) {

      dayIdx = idx + i

      if (dayIdx >= length) dayIdx -= length

      BAS_INTL.DAYS_WEEK_INTL.push(BAS_INTL.DAYS_WEEK[dayIdx])
    }
  }

  function _onTranslate () {

    var locale, params, length, i, day

    locale = _getLanguageLocale()

    // Use default meridiem notation for Russian, actual Russian notation
    //  is too long and makes UI look bad.
    if (BasUtil.isNEString(locale) && locale.indexOf('ru') === 0) {

      params = {
        meridiem: _defaultMeridiem
      }
    }

    // Change Moment locale
    moment.locale(locale, params)

    // Set AM/PM ui representation for current locale
    state.uiAM = moment({ h: 6 }).format(MOMENT_FORMAT_AMPM)
    state.uiPM = moment({ h: 16 }).format(MOMENT_FORMAT_AMPM)

    // Chinese and japanese place meridiem notation before the time
    state.uiAMPMBefore = (
      locale.indexOf('zh') === 0 ||
      locale.indexOf('ja') === 0
    )

    length = BAS_INTL.DAYS_WEEK.length
    for (i = 0; i < length; i++) {

      day = BAS_INTL.DAYS_WEEK[i]

      BAS_INTL.DAYS_WEEK_TRANSLATED[day] =
        BasUtilities.translate('day_' + day)
      BAS_INTL.DAYS_WEEK_TRANSLATED_SHORT[day] =
        BasUtilities.translate('day_short2_' + day)
    }

    // Update html language attribute
    if (document.documentElement) document.documentElement.lang = locale

    _updateTimeFormat()
  }

  function _onTimeFormatPreferenceChanged () {

    _updateTimeFormat()
  }

  function _onSystemProperties () {

    if (
      CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.system &&
      BasUtil.isNEString(currentBasCoreState.core.core.system.timezone) &&
      moment.tz.zone(currentBasCoreState.core.core.system.timezone)
    ) {

      lastKnownSystemTimezone = currentBasCoreState.core.core.system.timezone
    }
  }

  function _updateTimeFormat () {

    var timeFormatPreference, old12Hr

    old12Hr = state.timeFormat

    timeFormatPreference = BasPreferences.getTimeFormat()

    switch (timeFormatPreference) {

      case BAS_PREFERENCES.TIME_FORMAT_12h:
        state.timeFormat = BAS_INTL.TIME_FORMAT_12
        break

      case BAS_PREFERENCES.TIME_FORMAT_24h:
        state.timeFormat = BAS_INTL.TIME_FORMAT_24
        break

      case BAS_PREFERENCES.TIME_FORMAT_LOCALE_DEFAULT:
        state.timeFormat = _localeUses12HrClock(
          _getLocale()
        )
          ? BAS_INTL.TIME_FORMAT_12
          : BAS_INTL.TIME_FORMAT_24
        break
      default:
        state.timeFormat = BAS_INTL.TIME_FORMAT_24
    }

    if (old12Hr !== state.timeFormat) {

      $rootScope.$emit(BAS_INTL.EVT_TIME_FORMAT_CHANGED)
    }
  }

  function _defaultMeridiem (hours, _minutes, isLower) {

    return hours > 11
      ? isLower ? DEFAULT_PM : DEFAULT_PM_UPPER
      : isLower ? DEFAULT_AM : DEFAULT_AM_UPPER
  }

  function _localeUses12HrClock (locale) {

    try {

      return new Intl.DateTimeFormat(locale, {
        hour: 'numeric'
      }).resolvedOptions().hour12

    } catch (e) {

      return false
    }
  }

  function _getLanguageLocale () {

    var language

    language = BasPreferences.getLanguage()
    if (!BasUtil.isNEString(language)) {

      language = BasUtilities.getSystemLanguage()
    }

    return BasUtilities.getLocaleParameter(language)
  }

  function _getLocale () {

    return BasUtilities.getWebLocale()
  }
}
