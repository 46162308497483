'use strict'

var BasUtil = require('@basalte/bas-util')

var P = require('./parser_constants')

/**
 * @typedef {Object} TMessageText
 * @property {string} [*]
 * @property {string} [nl]
 * @property {string} [pl]
 * @property {string} [ru]
 * @property {string} [en]
 * @property {string} [fr]
 * @property {string} [es]
 * @property {string} [de]
 */

/**
 * @constructor
 * @param {Object} obj
 */
function Message (obj) {

  /**
   * @type {string}
   */
  this._uuid = ''

  /**
   * @type {TMessageText}
   */
  this._body = {}

  /**
   * @type {TMessageText}
   */
  this._title = {}

  /**
   * @type {Object}
   * @property {BasUtil.BasVersion} min
   * @property {BasUtil.BasVersion} max
   */
  this._version = {}

  /**
   * @type {Object}
   * @property {number} min milliseconds UTC
   * @property {number} max milliseconds UTC
   */
  this._time = {}

  /**
   * @type {string}
   */
  this._origin = ''

  /**
   * @type {string}
   */
  this._type = ''

  this.parse(obj)
}

/**
 * @name Message#body
 * @type {TMessageText}
 * @readonly
 */
Object.defineProperty(Message.prototype, 'body', {
  get: function () {
    return this._body
  }
})

/**
 * @name Message#title
 * @type {TMessageText}
 * @readonly
 */
Object.defineProperty(Message.prototype, 'title', {
  get: function () {
    return this._title
  }
})

/**
 * @name Message#version
 * @type {Object}
 * @property {string} min
 * @property {string} max
 * @readonly
 */
Object.defineProperty(Message.prototype, 'version', {
  get: function () {
    return this._version
  }
})

/**
 * @name Message#time
 * @type {Object}
 * @property {number} min milliseconds UTC
 * @property {number} max milliseconds UTC
 * @readonly
 */
Object.defineProperty(Message.prototype, 'time', {
  get: function () {
    return this._time
  }
})

/**
 * @name Message#origin
 * @type {string}
 * @readonly
 */
Object.defineProperty(Message.prototype, 'origin', {
  get: function () {
    return this._origin
  }
})

/**
 * @name Message#type
 * @type {string}
 * @readonly
 */
Object.defineProperty(Message.prototype, 'type', {
  get: function () {
    return this._type
  }
})

/**
 * @name Message#uuid
 * @type {string}
 * @readonly
 */
Object.defineProperty(Message.prototype, 'uuid', {
  get: function () {
    return this._uuid
  }
})

Message.prototype.parse = function (obj) {

  if (BasUtil.isNEString(obj[P.UUID])) this._uuid = obj[P.UUID]
  if (BasUtil.isNEString(obj[P.TYPE])) this._type = obj[P.TYPE]

  if (BasUtil.isObject(obj[P.ORIGIN]) &&
    BasUtil.isNEString(obj[P.ORIGIN][P.TYPE])) {

    this._origin = obj[P.ORIGIN][P.TYPE]
  }

  if (BasUtil.isObject(obj[P.TIME])) {

    if (BasUtil.isNumber(obj[P.TIME][P.MIN])) {
      this._time.min = obj[P.TIME][P.MIN]
    }

    if (BasUtil.isNumber(obj[P.TIME][P.MAX])) {
      this._time.max = obj[P.TIME][P.MAX]
    }
  }

  if (BasUtil.isObject(obj[P.APP]) &&
    BasUtil.isObject(obj[P.APP][P.VERSION])) {

    if (BasUtil.isNEString(obj[P.APP][P.VERSION][P.MIN])) {
      this._version.min = new BasUtil.BasVersion(
        obj[P.APP][P.VERSION][P.MIN]
      )
    }

    if (BasUtil.isNEString(obj[P.APP][P.VERSION][P.MAX])) {
      this._version.max = new BasUtil.BasVersion(
        obj[P.APP][P.VERSION][P.MAX]
      )
    }
  }

  if (BasUtil.isObject(obj[P.CONTENT])) {

    if (BasUtil.isObject(obj[P.CONTENT][P.BODY])) {

      this._body = this.parseText(obj[P.CONTENT][P.BODY])
    }

    if (BasUtil.isObject(obj[P.CONTENT][P.TITLE])) {

      this._title = this.parseText(obj[P.CONTENT][P.TITLE])
    }
  }
}

/**
 * @param {Object} text
 * @returns {TMessageText}
 */
Message.prototype.parseText = function (text) {

  var obj = {}

  if (!BasUtil.isObject(text)) return obj

  if (BasUtil.isNEString(text[P.NL])) obj.nl = text[P.NL]
  if (BasUtil.isNEString(text[P.PL])) obj.pl = text[P.PL]
  if (BasUtil.isNEString(text[P.RU])) obj.ru = text[P.RU]
  if (BasUtil.isNEString(text[P.EN])) obj.en = text[P.EN]
  if (BasUtil.isNEString(text[P.FR])) obj.fr = text[P.FR]
  if (BasUtil.isNEString(text[P.ES])) obj.es = text[P.ES]
  if (BasUtil.isNEString(text[P.DE])) obj.de = text[P.DE]
  if (BasUtil.isNEString(text[P.V_WILDCARD])) {

    obj['*'] = text[P.V_WILDCARD]
  }

  return obj
}

module.exports = Message
