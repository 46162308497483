'use strict'

import * as BasUtil from '@basalte/bas-util'
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip
} from 'chart.js'

Chart.register(CategoryScale, LinearScale, BarController, BarElement, Tooltip)

angular
  .module('basalteApp')
  .directive('basEnergyChart', basEnergyChartDirective)

/**
 * @typedef {Object} TBasEnergyChartData
 * @property {number[]} data
 * @property {string[]} labels
 */

function basEnergyChartDirective () {

  return {
    controller: [
      'moment',
      '$element',
      'BAS_ENERGY',
      'BAS_UTILITIES',
      'BasEnergyHelper',
      'BasUtilities',
      controller
    ],
    controllerAs: 'basEnergyChart',
    bindToController: {
      basEnergyMeter: '<?',
      basEnergyChartDataPromise: '<?',
      basEnergyChartType: '@?',
      basEnergyChartShowTooltip: '<?',
      basEnergyChartShowLabel: '<?'
    }
  }

  /**
   * @param moment
   * @param element
   * @param {BAS_ENERGY} BAS_ENERGY
   * @param {BAS_UTILITIES} BAS_UTILITIES
   * @param {BasEnergyHelper} BasEnergyHelper
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    moment,
    element,
    BAS_ENERGY,
    BAS_UTILITIES,
    BasEnergyHelper,
    BasUtilities
  ) {
    var basEnergyChart = this

    var T_NO_ANIM_TIME_DIFF_MS = 500

    var GRID_COLOR = 'rgba(60, 60, 60, 1)'
    var BAR_COLOR = 'rgba(255, 136, 29, 1)'
    var BAR_COLOR_GREEN = 'rgba(139, 195, 74, 1)'
    var BAR_COLOR_BLUE = 'rgba(1, 169, 244, 1)'
    var AXE_LABEL_COLOR = 'rgba(180, 180, 180, 1)'

    var _chart = null
    var _tChartCreated = 0

    /**
     * @private
     * @type {?TBasEnergyChartData}
     */
    var _parsedData = null

    this.$onChanges = _onChanges
    this.$postLink = _onPostLink
    this.$onDestroy = _onDestroy

    function _onPostLink () {

      _createChart()
    }

    function _onChanges () {

      var _promise

      if (
        basEnergyChart.basEnergyChartDataPromise &&
        basEnergyChart.basEnergyChartDataPromise.then
      ) {
        _promise = basEnergyChart.basEnergyChartDataPromise
        basEnergyChart.basEnergyChartDataPromise.then(_onChartData, _empty)
      }

      if (_chart) {

        _chart.options.plugins.tooltip.enabled =
          !!basEnergyChart.basEnergyChartShowTooltip
        _updateChart()
      }

      /**
       * @private
       * @param {(TBasEnergyMeterResult|TBasEnergyMeterGroupedResults)} result
       */
      function _onChartData (result) {

        var _result, _unit

        _result = result

        if (basEnergyChart.basEnergyChartDataPromise === _promise) {

          if (basEnergyChart.basEnergyChartType === BAS_ENERGY.T_ID_HIST_YEAR) {
            _result = BasEnergyHelper.groupByMonth(_result)
          }

          _unit = _getDataUnit()

          if (_unit === BAS_ENERGY.U_WH) {
            _result = BasEnergyHelper.transformAllData(_result, _divideBy1000)
          }

          _parsedData = _parseData(_result)

          if (_chart) {
            _chart.data = _parsedData
            _updateChart()
          }
        }
      }
    }

    function _createChart () {

      var cfg

      cfg = {
        type: 'bar',
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              ticks: {
                fontColor: '#ffffff',
                padding: 10
              },
              grid: {
                color: GRID_COLOR,
                lineWidth: 1,
                drawTicks: false
              },
              border: {
                display: false,
                width: 1
              }
            },
            y: {
              title: {
                display: false,
                color: AXE_LABEL_COLOR
              },
              beginAtZero: true,
              ticks: {
                fontColor: '#ffffff',
                padding: 20,
                callback: _yTickCallback
              },
              grid: {
                color: GRID_COLOR,
                lineWidth: 1,
                drawTicks: false
              },
              border: {
                display: false,
                width: 1
              }
            }
          },
          plugins: {
            tooltip: {
              enabled: !!basEnergyChart.basEnergyChartShowTooltip,
              callbacks: {
                label: _tooltipLabelCallback
              }
            },
            legend: {
              display: false
            }
          }
        },
        plugins: [
          {
            afterDraw: _chartNoDataPlugin
          }
        ]
      }

      if (
        !_chart &&
        element[0]
      ) {

        if (_parsedData) {

          cfg.data = _parsedData
          // TODO get nice max y
          // cfg.options.scales.y.max = Math.ceil(_maxY )
        }

        // Chart.js v3.1.1 >=
        _chart = new Chart(
          element[0].getContext('2d'),
          cfg
        )
        _tChartCreated = Date.now()
      }
    }

    function _chartNoDataPlugin (chart) {
      var TXT_SIZE = 16
      var TXT_COLOR = '#aaaaaa'
      var ctx, data, dataset, dataLength

      dataLength = 0

      if (chart) {
        ctx = chart.ctx
        data = chart.data

        if (ctx && data && Array.isArray(data.datasets)) {
          dataset = data.datasets[0]
          if (dataset && Array.isArray(dataset.data)) {
            dataLength = dataset.data.length
          }
        }

        if (ctx && chart.chartArea && dataLength === 0) {
          // Show no data
          ctx.save()
          ctx.textAlign = 'center'
          ctx.font = '' + TXT_SIZE + 'px Roboto'
          ctx.fillStyle = TXT_COLOR
          ctx.fillText(
            BasUtilities.translate('no_data'),
            chart.chartArea.left + chart.chartArea.width / 2,
            chart.chartArea.top + chart.chartArea.height / 2 + TXT_SIZE / 2
          )
          ctx.restore()
        }
      }
    }

    /**
     * @private
     * @param {(TBasEnergyMeterResult|TBasEnergyMeterGroupedResults)} input
     * @returns {Object}
     */
    function _parseData (input) {

      var result, length, barsPerCategory, i, entry, t, v, max
      var dateStr, hh, categories, hNum, div, tmp, date, dayStr

      result = {
        labels: [],
        datasets: []
      }

      max = 0

      if (input.step === BAS_UTILITIES.T_1H_MS) {

        barsPerCategory = 3

        categories = {}

        for (i = 0; i < barsPerCategory; i++) {
          result.datasets.push({
            data: [],
            backgroundColor: _getBarColor(),
            barPercentage: 0.8,
            categoryPercentage: 1
          })
        }

        tmp = 0

        length = input.result.length
        for (i = 0; i < length; i++) {

          entry = input.result[i]
          t = entry[0]
          v = entry[1]

          dateStr = BasUtilities.extractIsoDateString(t)
          hh = BasUtilities.extractFromIsoDateString(t, 'HH')
          hNum = parseInt(hh, 10)
          if (BasUtil.isPNumber(hNum, true)) {
            div = hNum % (24 / barsPerCategory)
            if (div === 0) tmp = 0
            tmp += v
            if (div === ((24 / barsPerCategory) - 1)) {
              if (tmp > max) max = tmp
              result.datasets[Math.floor(hNum / (24 / barsPerCategory))]
                .data.push(tmp)
            }
          }

          if (!categories[dateStr]) {

            dateStr = BasUtilities.extractIsoDateString(t)

            date = new Date(dateStr + 'T12:00:00')
            dayStr = BasUtilities.getShortDay(date)
            result.labels.push(dayStr)

            categories[dateStr] = true
          }
        }

      } else if (input.step === BAS_UTILITIES.T_1D_MS) {

        switch (basEnergyChart.basEnergyChartType) {
          case BAS_ENERGY.T_ID_HIST_WEEK:
            barsPerCategory = 1
            break
          case BAS_ENERGY.T_ID_HIST_MONTH:
            barsPerCategory = 1
            break
          case BAS_ENERGY.T_ID_HIST_YEAR:
            barsPerCategory = 1
            break
          default:
            barsPerCategory = 1
        }

        for (i = 0; i < barsPerCategory; i++) {
          result.datasets.push({
            data: [],
            backgroundColor: _getBarColor(),
            barPercentage: 0.8,
            categoryPercentage: 1
          })
        }

        length = input.result.length
        for (i = 0; i < length; i++) {

          entry = input.result[i]
          t = entry[0]
          v = entry[1]

          if (
            basEnergyChart.basEnergyChartType === BAS_ENERGY.T_ID_HIST_MONTH
          ) {
            dayStr = t.substring(8, 10)
            tmp = parseInt(dayStr, 10)
            result.labels.push(BasUtil.isVNumber(tmp) ? '' + tmp : dayStr)
          } else {
            // Ignore timezone to avoid date shifting to previous day
            result.labels.push(
              BasUtilities.getShort2DayForDayIdx(
                moment.parseZone(t).day()
              )
            )
          }

          result.datasets[0].data.push(v)
          if (v > max) max = v
        }

      } else if (input.groupBy === BAS_ENERGY.T_ID_HIST_MONTH) {

        result.datasets.push({
          data: [],
          backgroundColor: _getBarColor(),
          barPercentage: 0.8,
          categoryPercentage: 1
        })

        length = input.result.length
        for (i = 0; i < length; i++) {

          entry = input.result[i]
          t = entry[0]
          v = entry[1]

          dayStr = BasEnergyHelper.getMonthId(t).substring(5)
          tmp = parseInt(dayStr, 10)
          date = BasUtil.isVNumber(tmp) && tmp > 0
            ? BasUtilities.translateMonthShort(tmp - 1)
            : '' + tmp
          result.labels.push(date)
          result.datasets[0].data.push(v)
        }
      }

      return result
    }

    function _divideBy1000 (x) {
      return BasUtil.isVNumber(x) ? x / 1000 : x
    }

    function _getCorrectedDataUnit () {

      var unit

      unit = _getDataUnit()

      return unit === BAS_ENERGY.U_WH ? BAS_ENERGY.U_KWH : unit
    }

    function _getDataUnit () {

      var dev

      dev = _getEnergyMeter()

      return dev ? dev.uiTotalUnit : ''
    }

    function _yTickCallback (value, _index, _values) {

      var unit

      unit = _getCorrectedDataUnit()

      return unit ? value + ' ' + unit : value
    }

    function _tooltipLabelCallback (context) {

      var unit

      unit = _getCorrectedDataUnit()

      return unit
        ? context.formattedValue + ' ' + unit
        : context.formattedValue
    }

    function _updateChart () {

      if (_chart && _chart.update) {

        if (_tChartCreated > 0) {
          _chart.update(
            Date.now() - _tChartCreated > T_NO_ANIM_TIME_DIFF_MS
              ? 'none'
              : undefined
          )
        } else {
          _chart.update()
        }
      }
    }

    function _getBarColor () {

      var dev

      dev = _getEnergyMeter()

      if (dev) {

        switch (dev.type) {
          case BAS_ENERGY.T_ID_ENERGY:
            if (!dev.consuming) {
              return BAR_COLOR_GREEN
            }
            break
          case BAS_ENERGY.T_ID_GAS:
            // No special color
            break
          case BAS_ENERGY.T_ID_WATER:
            return BAR_COLOR_BLUE
        }
      }

      return BAR_COLOR
    }

    /**
     * @private
     * @returns {?BasEnergyMeter}
     */
    function _getEnergyMeter () {
      return (
        basEnergyChart.basEnergyMeter &&
        basEnergyChart.basEnergyMeter.retrieveLast7Days
      )
        ? basEnergyChart.basEnergyMeter
        : null
    }

    function _destroyChart () {
      if (_chart && _chart.destroy) _chart.destroy()
      _chart = null
    }

    function _onDestroy () {

      _destroyChart()
      _parsedData = null
    }
  }

  function _empty () {
    // Empty
  }
}
