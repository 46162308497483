'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasGenericDeviceV1', [
    'BAS_API',
    'BasGenericDevice',
    'BasGenericDeviceControl',
    basGenericDeviceV1Factory
  ])

/**
 * @param BAS_API
 * @param BasGenericDevice
 * @param BasGenericDeviceControl
 * @returns {BasGenericDeviceV1}
 */
function basGenericDeviceV1Factory (
  BAS_API,
  BasGenericDevice,
  BasGenericDeviceControl
) {
  const CSS_SHOW_SEPARATOR = 'brs-show-separator'
  const CSS_SINGLE_CONTROL = 'brs-single-control'

  /**
   * @constructor
   * @extends BasGenericDevice
   *
   * @param {GenericDevice} [device]
   */
  function BasGenericDeviceV1 (device) {
    BasGenericDevice.call(this)
    /**
     * @type {BasGenericDeviceControl[]}
     */
    this.controls = []

    /**
     * @type {?BasGenericDeviceControl}
     */
    this.singleControl = null

    if (device) this.parseGenericDevice(device)
  }

  BasGenericDeviceV1.prototype = Object.create(BasGenericDevice.prototype)

  BasGenericDeviceV1.prototype.clone = function () {

    return new BasGenericDeviceV1(this.device)
  }

  /**
   * Removes non-editable controls from this generic device instance.
   * Useful for scenes visualization.
   */
  BasGenericDeviceV1.prototype.removeNonEditableControls = function () {

    let i, newControls, control

    control = this.singleControl

    // "ng-model" could create object that is not a BasGenericDeviceControl
    if (
      control &&
      BasUtil.isFunction(control.isEditable) &&
      BasUtil.isFunction(control.clear)
    ) {
      if (!control.isEditable()) {

        // Should not occur

        control.clear()
        this.singleControl = null
      }
    }

    if (Array.isArray(this.controls)) {

      newControls = []

      const controls = this.controls
      const length = controls.length
      for (i = 0; i < length; i++) {

        control = controls[i]

        // "ng-model" could create object that is not a
        // BasGenericDeviceControl
        if (
          control &&
          BasUtil.isFunction(control.isEditable) &&
          BasUtil.isFunction(control.clear)
        ) {
          if (control.isEditable()) {

            newControls.push(control)

          } else {

            control.clear()
          }
        }
      }

      this.controls = newControls
    }
  }

  /**
   * Checks whether the controls in a device are active
   *
   * @returns {boolean}
   */
  BasGenericDeviceV1.prototype.areControlsActive = function () {

    let i, control

    control = this.singleControl

    // "ng-model" could create object that is not a BasGenericDeviceControl
    if (control && BasUtil.isFunction(control.isActive)) {

      return control.isActive()
    }

    const length = this.controls.length
    for (i = 0; i < length; i++) {

      control = this.controls[i]

      // "ng-model" could create object that is not a
      // BasGenericDeviceControl
      if (control && BasUtil.isFunction(control.isActive)) {

        if (control.isActive()) return true
      }
    }

    return false
  }

  /**
   * @param {GenericDevice} device
   */
  BasGenericDeviceV1.prototype.parseGenericDevice = function (device) {

    if (device instanceof BAS_API.GenericDevice) {

      this.device = device
      this.uuid = device.uuid
      this.type = device.type

      this.setDeviceListeners()
      this.syncDevice()
    }
  }

  /**
   * @param {string} uuid
   * @returns {?BasGenericDeviceControl}
   */
  BasGenericDeviceV1.prototype.getControlById = function (uuid) {

    let i, control

    control = this.singleControl

    // "ng-model" could create object that is not a BasGenericDeviceControl
    if (control && BasUtil.isNEString(control.uuid)) {

      if (control.uuid === uuid) return control

    } else {

      const length = this.controls.length
      for (i = 0; i < length; i++) {

        control = this.controls[i]

        // "ng-model" could create object that is not a
        // BasGenericDeviceControl
        if (control && BasUtil.isNEString(control.uuid)) {

          if (control.uuid === uuid) return control
        }
      }
    }

    return null
  }

  /**
   * @param {GenericDevice} [device]
   */
  BasGenericDeviceV1.prototype.syncDevice = function (device) {

    const _device = device || this.device

    this.clearControls()
    this.resetCss()

    if (_device) {

      this.name = _device.name

      const controls = _device.controls
      const length = controls?.length

      if (length === 1) {

        this.singleControl = new BasGenericDeviceControl(
          controls[0],
          this
        )
        this.css[CSS_SINGLE_CONTROL] = true

        if (this.singleControl.name === '-') {

          this.singleControl.name = ''

        } else {

          this.css[CSS_SHOW_SEPARATOR] = true
        }

      } else {

        for (let i = 0; i < length; i++) {

          this.controls.push(new BasGenericDeviceControl(
            controls[i],
            this
          ))
        }

        if (this.onlyInputControls) this.removeNonEditableControls()
      }
    }

    this.updateTranslation()
  }

  /**
   * @returns {boolean}
   */
  BasGenericDeviceV1.prototype.isEditable = function () {

    let i, control

    if (this.controls) {

      const length = this.controls.length
      for (i = 0; i < length; i++) {

        control = this.controls[i]

        // "ng-model" could create object that is not a
        // BasGenericDeviceControl
        if (control && BasUtil.isFunction(control.isEditable)) {

          if (control.isEditable()) return true
        }
      }
    }

    control = this.singleControl

    // "ng-model" could create object that is not a BasGenericDeviceControl
    if (control && BasUtil.isFunction(control.isEditable)) {

      if (control.isEditable()) return true
    }

    return false
  }

  BasGenericDeviceV1.prototype.updateTranslation = function () {

    let i, control

    if (!BasUtil.isNEString(this.name)) {

      this.name = '-'
    }

    if (this.controls) {

      const length = this.controls.length
      for (i = 0; i < length; i++) {

        control = this.controls[i]

        // "ng-model" could create object that is not a
        // BasGenericDeviceControl
        if (control && BasUtil.isFunction(control.updateTranslation)) {

          control.updateTranslation()
        }
      }
    }

    control = this.singleControl

    // "ng-model" could create object that is not a BasGenericDeviceControl
    if (control && BasUtil.isFunction(control.updateTranslation)) {

      control.updateTranslation()
    }
  }

  /**
   * @param {boolean} mode
   */
  BasGenericDeviceV1.prototype.setOnlyInputControls = function (mode) {

    this.onlyInputControls = mode === true
    if (this.onlyInputControls) this.removeNonEditableControls()
  }

  /**
   * Clears the device listeners and the device reference
   */
  BasGenericDeviceV1.prototype.clear = function clear () {

    this.clearDeviceListeners()
    this.device = null
  }

  /**
   * Clears the controls
   */
  BasGenericDeviceV1.prototype.clearControls = function clear () {

    let i, control

    const length = this.controls.length
    for (i = 0; i < length; i++) {

      control = this.controls[i]

      // "ng-model" could create object that is not a
      // BasGenericDeviceControl
      if (control && BasUtil.isFunction(control.clear)) control.clear()
    }

    this.controls = []

    control = this.singleControl

    // "ng-model" could create object that is not a BasGenericDeviceControl
    if (control && BasUtil.isFunction(control.clear)) control.clear()

    this.singleControl = null
  }

  BasGenericDeviceV1.prototype.resetCss = function resetCss () {

    this.css[CSS_SHOW_SEPARATOR] = false
    this.css[CSS_SINGLE_CONTROL] = false
  }

  return BasGenericDeviceV1
}
