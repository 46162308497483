'use strict'

angular
  .module('basalteApp')
  .constant('BAS_CONNECT', {
    T_LOCAL: 'local',
    T_REMOTE: 'remote',
    T_DEMO: 'demo',
    PREFIX_LIVE_PROJECT: 'basalte_live_project:',
    ERR_INCOMPATIBLE_API: 'errBasConnectIncompatibleApi',
    ERR_NO_LEADER: 'errNoLeader',
    ERR_INVALID_CREDENTIALS: 'errBasConnectInvalidCredentials',
    ERR_USERS: 'errBasConnectUsers',
    ERR_LOGIN: 'errBasConnectLoginResult'
  })
