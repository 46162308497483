'use strict'

angular
  .module('basalteApp')
  .service('BasThermostatInit', [
    'BAS_API',
    'BAS_THERMOSTAT',
    'BasSchedulerFilter',
    BasThermostatInit
  ])

/**
 * @constructor
 * @param BAS_API
 * @param {BAS_THERMOSTAT} BAS_THERMOSTAT
 * @param BasSchedulerFilter
 */
function BasThermostatInit (
  BAS_API,
  BAS_THERMOSTAT,
  BasSchedulerFilter
) {
  var filter

  // All

  filter = new BasSchedulerFilter()
  filter.id = BAS_THERMOSTAT.ID_MODE_FILTER_ALL
  filter.translationId = 'all'
  filter.filter = filterModeAll

  BAS_THERMOSTAT.MODE_FILTERS[filter.id] = filter

  // All non-any

  filter = new BasSchedulerFilter()
  filter.id = BAS_THERMOSTAT.ID_MODE_FILTER_ALL_NON_ANY
  filter.translationId = 'all'
  filter.filter = filterModeAllNonAny

  BAS_THERMOSTAT.MODE_FILTERS[filter.id] = filter

  // Heating

  filter = new BasSchedulerFilter()
  filter.id = BAS_THERMOSTAT.ID_MODE_FILTER_HEATING
  filter.translationId = BAS_THERMOSTAT.MODE_HEATING
  filter.filter = filterModeHeating

  BAS_THERMOSTAT.MODE_FILTERS[filter.id] = filter

  // Cooling

  filter = new BasSchedulerFilter()
  filter.id = BAS_THERMOSTAT.ID_MODE_FILTER_COOLING
  filter.translationId = BAS_THERMOSTAT.MODE_COOLING
  filter.filter = filterModeCooling

  BAS_THERMOSTAT.MODE_FILTERS[filter.id] = filter

  // Auto

  filter = new BasSchedulerFilter()
  filter.id = BAS_THERMOSTAT.ID_MODE_FILTER_AUTO
  filter.translationId = BAS_THERMOSTAT.MODE_AUTO
  filter.filter = filterModeAuto

  BAS_THERMOSTAT.MODE_FILTERS[filter.id] = filter

  // Any

  filter = new BasSchedulerFilter()
  filter.id = BAS_THERMOSTAT.ID_MODE_FILTER_ANY
  filter.translationId = 'any'
  filter.filter = filterModeAny

  BAS_THERMOSTAT.MODE_FILTERS[filter.id] = filter

  /**
   * @param {BasThermostatSchedulerPoint} _point
   * @returns {boolean}
   */
  function filterModeAll (_point) {

    return true
  }

  /**
   * @param {BasThermostatSchedulerPoint} point
   * @returns {boolean}
   */
  function filterModeAllNonAny (point) {

    if (point) {

      if (point.mode === BAS_API.SetPoint.M_HEATING ||
        point.mode === BAS_API.SetPoint.M_COOLING ||
        point.mode === BAS_API.SetPoint.M_AUTO) {

        return true
      }
    }

    return false
  }

  /**
   * @param {BasThermostatSchedulerPoint} point
   * @returns {boolean}
   */
  function filterModeHeating (point) {

    return point && point.mode === BAS_API.SetPoint.M_HEATING
  }

  /**
   * @param {BasThermostatSchedulerPoint} point
   * @returns {boolean}
   */
  function filterModeCooling (point) {

    return point && point.mode === BAS_API.SetPoint.M_COOLING
  }

  /**
   * @param {BasThermostatSchedulerPoint} point
   * @returns {boolean}
   */
  function filterModeAuto (point) {

    return point && point.mode === BAS_API.SetPoint.M_AUTO
  }

  /**
   * @param {BasThermostatSchedulerPoint} point
   * @returns {boolean}
   */
  function filterModeAny (point) {

    return point && point.mode === BAS_API.SetPoint.M_ANY
  }
}
