'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasTile', [
    '$timeout',
    'BasUtilities',
    BasTileFactory
  ])

/**
 * @param $timeout
 * @param {BasUtilities} BasUtilities
 * @returns BasTile
 */
function BasTileFactory (
  $timeout,
  BasUtilities
) {
  /**
   * @constructor
   * @param {string} [id]
   */
  function BasTile (id) {

    /**
     * Used for view id
     *
     * @type {string}
     */
    this.id = BasUtil.isNEString(id) ? id : ''

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.translationKey = ''

    /**
     * @type {string}
     */
    this.icon = ''

    /**
     * @type {string}
     */
    this.colourStyle = ''

    /**
     * @type {boolean}
     */
    this.compileIcon = false

    /**
     * @type {Object}
     */
    this.css = {}
    this._resetCss()

    this._handleSelectTimeout = this._onSelectTimeout.bind(this)
  }

  /**
   * @constant {string}
   */
  BasTile.ID_CAMERAS = 'cameras'

  /**
   * @constant {string}
   */
  BasTile.ID_INTERCOM = 'intercom'

  /**
   * @constant {string}
   */
  BasTile.ID_SCHEDULER = 'scheduler'

  /**
   * @constant {string}
   */
  BasTile.ID_SETTINGS = 'settings'

  /**
   * @constant {string}
   */
  BasTile.ID_ENERGY = 'energy'

  /**
   * @constant {string}
   */
  BasTile.ID_WEATHER = 'weather'

  /**
   * @constant {string}
   */
  BasTile.ID_CALL_HISTORY = 'callHistory'

  /**
   * @constant {string}
   */
  BasTile.ID_TIMERS = 'timers'

  /**
   * @constant {string}
   */
  BasTile.ID_SECURITY = 'security'

  /**
   * @constant {string}
   */
  BasTile.ID_DOORS = 'doors'

  /**
   * @constant {string}
   */
  BasTile.ID_LISA_START = 'lisa_start'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_HIGHLIGHT = 'bas-tile-highlight'

  /**
   * @constant {string}
   */
  BasTile.CSS_SPINNER_VISIBLE = 'bas-tile-spinner-visible'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_MUSIC = 'bti-music'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_SVG_80 = 'bas-tile-svg-80'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_SVG_75 = 'bas-tile-svg-75'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_SVG_70 = 'bas-tile-svg-70'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_SVG_65 = 'bas-tile-svg-65'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_SVG_60 = 'bas-tile-svg-60'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_SVG_55 = 'bas-tile-svg-55'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_SVG_50 = 'bas-tile-svg-50'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_SVG_45 = 'bas-tile-svg-45'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_SVG_40 = 'bas-tile-svg-40'

  /**
   * @constant {string}
   */
  BasTile.CSS_TILE_TOP_OFFSET = 'bas-tile-top-offset'

  /**
   * @param {string} name
   */
  BasTile.prototype.setName = function (name) {

    if (BasUtil.isString(name)) {

      this.name = name
      this.translationKey = ''
    }
  }

  BasTile.prototype.select = function () {

    this.css[BasTile.CSS_SPINNER_VISIBLE] = true

    $timeout(this._handleSelectTimeout, 1000)
  }

  /**
   * @private
   */
  BasTile.prototype._onSelectTimeout = function () {

    this.css[BasTile.CSS_SPINNER_VISIBLE] = false
  }

  /**
   * @param {string} key
   */
  BasTile.prototype.setTranslation = function (key) {

    if (BasUtil.isNEString(key)) {

      this.translationKey = key
      this.name = BasUtilities.translate(key)
    }
  }

  BasTile.prototype.updateTranslation = function () {

    if (BasUtil.isNEString(this.translationKey)) {

      this.name = BasUtilities.translate(this.translationKey)
    }
  }

  /**
   * @param {string} css
   * @param {boolean} [force = true]
   */
  BasTile.prototype.toggleCss = function (css, force) {

    this.css[css] = BasUtil.isBool(force)
      ? force
      : BasUtil.isBool(this.css[css])
        ? !this.css[css]
        : true
  }

  /**
   * @private
   */
  BasTile.prototype._resetCss = function () {

    this.css[BasTile.CSS_TILE_HIGHLIGHT] = false
    this.css[BasTile.CSS_SPINNER_VISIBLE] = false
    this.css[BasTile.CSS_TILE_MUSIC] = false
    this.css[BasTile.CSS_TILE_SVG_60] = false
    this.css[BasTile.CSS_TILE_SVG_55] = false
    this.css[BasTile.CSS_TILE_SVG_50] = false
    this.css[BasTile.CSS_TILE_TOP_OFFSET] = false
  }

  return BasTile
}
