'use strict'

angular
  .module('basalteApp')
  .directive('basTouchstart', basTouchStart)

function basTouchStart () {
  return {
    controller: [
      '$scope',
      '$element',
      controller
    ]
  }

  function controller ($scope, $element) {

    init()

    function init () {

      $element.on('touchstart', _onTouchStart)
    }

    function _onTouchStart (event) {

      var method = $element.attr('bas-touchstart')
      $scope.$event = event
      $scope.$apply(method)
    }
  }
}
