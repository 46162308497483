'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basScrollHide', basScrollHide)

function basScrollHide () {
  return {
    restrict: 'A',
    bindToController: {
      scrollContainerClass: '@'
    },
    controller: [
      '$scope',
      '$element',
      'UiHelper',
      controller
    ]
  }

  /**
   *
   * @param $scope
   * @param $element
   * @param {UiHelper} UiHelper
   */
  function controller (
    $scope,
    $element,
    UiHelper
  ) {

    var scrollHide = this

    var scrollListener

    scrollHide.$postLink = _onPostLink

    function _onPostLink () {

      init()
    }

    function init () {

      var parentElement

      $scope.$on('$destroy', _onDestroy)

      parentElement = _getParentElement()

      if (parentElement) {

        scrollListener = BasUtil.setDOMListener(
          parentElement,
          'scroll',
          _onScroll,
          {
            capture: false,
            passive: true
          }
        )
      }
    }

    function _onScroll () {

      var parentElement

      parentElement = _getParentElement()

      if ($element[0] && parentElement) {

        const hide = parentElement.scrollTop < 10

        $element[0].style.opacity = hide
          ? 1
          : 0

        $element[0].style.pointerEvents = hide
          ? 'auto'
          : 'none'
      }
    }

    function _getParentElement () {

      if ($element && $element[0]) {

        if (BasUtil.isNEString(scrollHide.scrollContainerClass)) {

          return UiHelper.getElementParentWithClassName(
            $element[0],
            scrollHide.scrollContainerClass
          )

        } else {

          return $element[0].parentElement
        }
      }
    }

    function _onDestroy () {

      BasUtil.execute(scrollListener)
    }
  }
}
