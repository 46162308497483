'use strict'

angular
  .module('basalteApp')
  .directive('basRoomCollection', basRoomsDirective)

/**
 * @returns basRoomCollection
 */
function basRoomsDirective () {

  return {
    restrict: 'AE',
    scope: {},
    controller: [
      '$scope',
      '$element',
      '$compile',
      'BAS_HTML',
      'BAS_ROOMS',
      'BAS_ROOM',
      'BAS_SOURCES',
      'BAS_SOURCE',
      'BasAppDevice',
      'CurrentRoom',
      controller
    ],
    controllerAs: 'basRoomsCtrl',
    bindToController: {
      basRoomCollection: '<?',
      basRoomComponentKey: '<?',
      onSelectRoom: '&?',
      onSelectMusicZone: '&?',
      onSelectVideo: '&?',
      onSelectThermostat: '&?',
      onSelectIntercom: '&?',
      onLongPressRoom: '&?',
      onToggleMusic: '&?',
      onToggleVideo: '&?',
      onJoin: '&?',
      onAvRoomSelect: '&?'
    }
  }

  /**
   * @param $scope
   * @param $element
   * @param $compile
   * @param {BAS_HTML} BAS_HTML
   * @param {BAS_ROOMS} BAS_ROOMS
   * @param {BAS_ROOM} BAS_ROOM
   * @param {BAS_SOURCES} BAS_SOURCES
   * @param {BAS_SOURCE} BAS_SOURCE
   * @param {BasAppDevice} BasAppDevice
   * @param {CurrentRoom} CurrentRoom
   */
  function controller (
    $scope,
    $element,
    $compile,
    BAS_HTML,
    BAS_ROOMS,
    BAS_ROOM,
    BAS_SOURCES,
    BAS_SOURCE,
    BasAppDevice,
    CurrentRoom
  ) {
    var basRoomsCtrl = this

    /**
     * @type {BasRooms}
     */
    basRoomsCtrl.rooms = BAS_ROOMS.ROOMS

    /**
     * @type {BAS_ROOM}
     */
    basRoomsCtrl.BAS_ROOM = BAS_ROOM

    /**
     * @type {BasSources}
     */
    basRoomsCtrl.sources = BAS_SOURCES.SOURCES

    /**
     * @type {number}
     */
    basRoomsCtrl.dpi = BasAppDevice.getDpi()

    /**
     * @type {BAS_SOURCE}
     */
    basRoomsCtrl.BAS_SOURCE = BAS_SOURCE

    /**
     * @type {TCurrentRoomState}
     */
    basRoomsCtrl.currentRoom = CurrentRoom.get()

    basRoomsCtrl.$onChanges = _onChanges

    function _onChanges (changes) {

      if (changes.basRoomComponentKey) _syncTemplate()
    }

    function _syncTemplate () {

      switch (basRoomsCtrl.basRoomComponentKey) {
        case BAS_ROOMS.BC_MUSIC:
          _setTemplate(BAS_HTML.roomMusic)
          break
        case BAS_ROOMS.BC_THERMOSTATS:
          _setThermostats()
          _setTemplate(BAS_HTML.roomThermostat)
          break
        case BAS_ROOMS.BC_INTERCOM:
          _setTemplate(BAS_HTML.roomIntercom)
          break
        default:
          _setTemplate(BAS_HTML.roomTile)
      }
    }

    /**
     * @private
     * @param {string} template
     */
    function _setTemplate (template) {

      $element.html(template)
      $compile($element.contents())($scope)
    }

    /**
     * @private
     */
    function _setThermostats () {
      basRoomsCtrl.thermostats = []

      for (let i = 0; i < basRoomsCtrl.basRoomCollection.items.length; i++) {
        const roomId = basRoomsCtrl.basRoomCollection.items[i]
        const room = basRoomsCtrl.rooms.rooms[roomId]

        if (room?.thermostats?.thermostats) {

          for (let j = 0; j < room.thermostats.thermostats.length; j++) {
            const thermostat = room.thermostats.thermostats[j]

            if (room.thermostats.thermostats.length > 1) {
              thermostat.hasFriend = true
            }

            if (thermostat) {
              basRoomsCtrl.thermostats.push(thermostat)
            }
          }
        }
      }
    }
  }
}
