'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasLibraryCollection', [
    'BAS_LIBRARY',
    'BasUtilities',
    'Logger',
    BasLibraryCollectionFactory
  ])

/**
 * @param {BAS_LIBRARY} BAS_LIBRARY
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @returns BasLibraryCollection
 */
function BasLibraryCollectionFactory (
  BAS_LIBRARY,
  BasUtilities,
  Logger
) {
  var className = 'BasLibraryCollection'

  /**
   * Object that represents a collection, which is part of BasLibraryPage
   *
   * @constructor
   * @param [handler]
   */
  function BasLibraryCollection (handler) {

    /**
     * @instance
     * @type {string}
     */
    this.headerTitle = ''

    /**
     * @instance
     * @type {string}
     */
    this.headerTitleId = ''

    /**
     * @instance
     * @type {BasLibraryElement}
     */
    this.detailElement = null

    /**
     * @instance
     * @type {?BasLibraryDetail}
     */
    this.detail = null

    /**
     * @instance
     * @type {Array<BasLibraryElement>}
     */
    this.elements = []

    /**
     * @instance
     * @type {boolean}
     */
    this.isFetching = false

    /**
     * @instance
     * @type {boolean}
     */
    this.hasReachedEnd = false

    /**
     * @instance
     * @type {BasLibraryElement}
     */
    this.lastElement = null

    /**
     * @instance
     * @type {string}
     */
    this.coverArtSize = 'small'

    /**
     * @instance
     * @type {boolean}
     */
    this.watchFavourite = false

    /**
     * Scrollbar location
     *
     * @instance
     * @type {number}
     */
    this.scrollPercent = 0

    /**
     * @instance
     * @type {boolean}
     */
    this.hasTab = false

    /**
     * @instance
     * @type {string}
     */
    this.nameId = ''

    /**
     * @instance
     * @type {string}
     */
    this.titleNameId = ''

    /**
     * @instance
     * @type {boolean}
     */
    this.canGridView = false

    /**
     * @instance
     * @type {number}
     */
    this.gridView = BasLibraryCollection.VIEWS
      .indexOf(BasLibraryCollection.VIEW_GRID)

    /**
     * @instance
     * @type {boolean}
     */
    this.canEdit = false

    /**
     * @instance
     * @type {boolean}
     */
    this.isEditing = false

    /**
     * @type {?Function}
     */
    this.handler = handler || null

    /**
     * @instance
     * @type {string}
     */
    this.editTitle = ''

    /**
     * CSS classes
     *
     * @instance
     * @type {Object}
     */
    this.class = {}

    this.class[BasLibraryCollection.CLASS_HAS_HEADER] = false
    this.class[BasLibraryCollection.CLASS_GRID] = false
    this.class[BasLibraryCollection.CLASS_CAN_GRID] = false
    this.class[BasLibraryCollection.CLASS_IS_EDITING] = false

    this.handlePlaylists = this.onPlaylistChanged.bind(this)
  }

  // region Static constants

  /**
   * @constant {string}
   */
  BasLibraryCollection.VIEW_LIST = 'list'

  /**
   * @constant {string}
   */
  BasLibraryCollection.VIEW_GRID = 'grid'

  /**
   * @constant {number}
   */
  BasLibraryCollection.INDEX_LIST = 0

  /**
   * @constant {number}
   */
  BasLibraryCollection.INDEX_GRID = 1

  /**
   * @constant {string}
   */
  BasLibraryCollection.NO_UUID = 'No uuid'

  /**
   * @constant {string}
   */
  BasLibraryCollection.NO_PLAYER = 'No player'

  /**
   * @constant {string}
   */
  BasLibraryCollection.NOT_EDITING = 'Not editing'

  /**
   * @constant {Array}
   */
  BasLibraryCollection.VIEWS = [
    BasLibraryCollection.VIEW_LIST,
    BasLibraryCollection.VIEW_GRID
  ]

  /**
   * @constant {string}
   */
  BasLibraryCollection.CLASS_HAS_HEADER =
    'bas-library-collection-has-header'

  /**
   * @constant {string}
   */
  BasLibraryCollection.CLASS_GRID = 'bas-library-collection-grid'

  /**
   * @constant {string}
   */
  BasLibraryCollection.CLASS_CAN_GRID = 'bas-library-collection-can-grid'

  /**
   * @constant {string}
   */
  BasLibraryCollection.CLASS_IS_EDITING =
    'bas-library-collection-is-editing'

  /**
   * @constant {string}
   */
  BasLibraryCollection.CLASS_ORDER_CONTROL =
    'bas-library-element-order-control'

  // endregion

  // Instance methods

  /**
   * Set the header title
   * This will toggle the header UI for the collection
   *
   * @param {string} title
   */
  BasLibraryCollection.prototype.setTitle = function (title) {

    this.class[BasLibraryCollection.CLASS_HAS_HEADER] =
      BasUtil.isNEString(title)

    // Check input
    this.headerTitle = BasUtil.isNEString(title) ? title : ''
  }

  /**
   * Set the header title id
   * This will toggle the header UI for the collection
   *
   * @param {string} titleId
   */
  BasLibraryCollection.prototype.setTitleId = function (titleId) {

    // Check input
    this.headerTitleId = BasUtil.isNEString(titleId) ? titleId : ''

    this.setTitle(BasUtilities.translate(this.headerTitleId))
  }

  /**
   * @param {BasLibraryDetail} detail
   */
  BasLibraryCollection.prototype.setDetail = function (detail) {

    this.detail = BasUtil.isObject(detail) ? detail : null
  }

  /**
   * @param {BasLibraryElement} element
   */
  BasLibraryCollection.prototype.setDetailElement = function (element) {

    this.detailElement = BasUtil.isObject(element) ? element : null
  }

  /**
   * @param {string} nameId
   * @param {string} titleNameId
   */
  BasLibraryCollection.prototype.setName = function (
    nameId,
    titleNameId
  ) {
    this.nameId = BasUtil.isNEString(nameId) ? nameId : ''
    this.titleNameId =
      BasUtil.isNEString(titleNameId) ? titleNameId : ''
  }

  /**
   * @param {boolean} hasTab
   */
  BasLibraryCollection.prototype.setHasTab = function (hasTab) {

    this.hasTab = hasTab === true
  }

  BasLibraryCollection.prototype.setScrollPercent = function (percent) {
    this.scrollPercent = percent
  }

  BasLibraryCollection.prototype.setCanEdit = function (value) {

    this.canEdit = value === true
  }

  BasLibraryCollection.prototype.setIsEditing = function (value) {

    this.isEditing = value === true
    this.class[BasLibraryCollection.CLASS_IS_EDITING] = this.isEditing
  }

  BasLibraryCollection.prototype.setLoadEditing = function (value) {

    if (BasUtil.isObject(this.detail)) {
      this.detail.setShowSpinner(value)
    }
  }

  BasLibraryCollection.prototype.setLoadSelectionEditing = function (value) {

    if (BasUtil.isObject(this.detail)) {
      this.detail.setShowSelectionSpinner(value)
    }
  }

  BasLibraryCollection.prototype.refresh = function () {
    Logger.warn(className + ' - refresh NOT IMPLEMENTED')
  }

  /**
   * @returns {?Promise}
   */
  BasLibraryCollection.prototype.toggleEdit = function () {

    var _this, toEdit

    _this = this
    toEdit = !this.isEditing

    // Check if going to edit
    if (toEdit) {
      // Set spinner
      this.setLoadEditing(true)
      // Retrieve all data
      return this.retrieveAll().then(onRetrieve, onFail)
    } else {
      // Hide edit view
      this.setIsEditing(false)
      this.setLoadEditing(false)
      return null
    }

    function onRetrieve () {
      // Hide spinner
      _this.setLoadEditing(false)
      // Set edit title
      _this.editTitle = _this.detail.title
      // Show edit view
      _this.setIsEditing(true)
    }

    function onFail () {
      // Hide spinner
      _this.setLoadEditing(false)
    }
  }

  /**
   * @param {number} view
   */
  BasLibraryCollection.prototype.setGridView = function (view) {

    // Check for valid input
    if (BasUtil.isPNumber(view, true)) {

      this.gridView = view
      this.class[BasLibraryCollection.CLASS_GRID] =
        view === BasLibraryCollection.VIEWS
          .indexOf(BasLibraryCollection.VIEW_GRID)

    } else {

      // Default values
      this.gridView = BasLibraryCollection.VIEWS
        .indexOf(BasLibraryCollection.VIEW_LIST)
      this.class[BasLibraryCollection.CLASS_GRID] = false
    }

    if (view === BasLibraryCollection.VIEWS
      .indexOf(BasLibraryCollection.VIEW_GRID)) {

      // Need bigger covers for grid view
      this.coverArtSize = 'medium'
    } else {

      // Standard cover arts
      this.coverArtSize = 'small'
    }
  }

  /**
   * @param {boolean} value
   */
  BasLibraryCollection.prototype.setCanGridView = function (value) {

    this.canGridView = value === true
    this.class[BasLibraryCollection.CLASS_CAN_GRID] =
      value === true
  }

  BasLibraryCollection.prototype.onReachedEnd = function () {
    Logger.warn(className + ' - onReachedEnd NOT IMPLEMENTED')
  }

  BasLibraryCollection.prototype.beforeWait = function (event) {

    if (event &&
      event.target &&
      event.target.className &&
      event.target.className
        .indexOf(BasLibraryCollection.CLASS_ORDER_CONTROL) > -1) {

      event.preventDefault()
    }
  }

  /**
   * @param {number} spliceIndex
   * @param {number} originalIndex
   * @returns {Promise}
   */
  BasLibraryCollection.prototype.listReorder = function (
    spliceIndex,
    originalIndex
  ) {
    var element

    if (this.isEditing) {

      element = this.elements[originalIndex]

      // Update local
      this.elements.splice(originalIndex, 1)
      this.elements.splice(spliceIndex, 0, element)

      return this.itemReorder(spliceIndex, originalIndex)
    }

    return Promise.reject(BasLibraryCollection.NOT_EDITING)
  }

  // noinspection JSUnusedLocalSymbols
  /**
   * @abstract
   * @param {number} _spliceIndex
   * @param {number} _originalIndex
   * @returns {Promise}
   */
  BasLibraryCollection.prototype.itemReorder = function (
    _spliceIndex,
    _originalIndex
  ) {
    Logger.warn(className + ' - itemReorder NOT IMPLEMENTED')

    return Promise.reject('NOT IMPLEMENTED')
  }

  /**
   * @param {number} index
   * @returns {Promise}
   */
  BasLibraryCollection.prototype.listSwipe = function (index) {

    var promise

    if (this.isEditing) {

      promise = this.itemSwipe(index)

      // Remove from list
      this.elements.splice(index, 1)

      return promise
    }

    return Promise.reject(BasLibraryCollection.NOT_EDITING)
  }

  BasLibraryCollection.prototype.onPlaylistChanged = function () {
    if (this.handler) this.handler(BAS_LIBRARY.EVT_PLAYLIST_CHANGED)
  }

  // noinspection JSUnusedLocalSymbols
  /**
   * @abstract
   * @param {number} _index
   * @returns {Promise}
   */
  BasLibraryCollection.prototype.itemSwipe = function (_index) {
    Logger.warn(className + ' - itemSwipe NOT IMPLEMENTED')

    return Promise.reject('NOT IMPLEMENTED')
  }

  /**
   * Execute first retrieve
   *
   * @abstract
   * @returns {Promise}
   */
  BasLibraryCollection.prototype.retrieve = function () {
    Logger.warn(className + ' - retrieve NOT IMPLEMENTED')

    return Promise.reject('NOT IMPLEMENTED')
  }

  /**
   * @abstract
   * @returns {Promise}
   */
  BasLibraryCollection.prototype.retrieveAll = function () {
    Logger.warn(className + ' - retrieveAll NOT IMPLEMENTED')

    return Promise.reject('NOT IMPLEMENTED')

  }

  /**
   * @param {BasLibraryElement} element
   */
  BasLibraryCollection.prototype.setLastElement = function (element) {

    this.lastElement = BasUtil.isObject(element) ? element : null
  }

  BasLibraryCollection.prototype.translate = function () {

    if (BasUtil.isNEString(this.headerTitleId)) {
      this.headerTitle = BasUtilities.translate(this.headerTitleId)
    }
  }

  /**
   * Set elements for this collection.
   * If watch favourites is enabled,
   * they will be added to the watch list
   *
   * @param {Array<BasLibraryElement>} elements
   */
  BasLibraryCollection.prototype.setElements = function (elements) {

    this.elements = Array.isArray(elements) ? elements : []
  }

  /**
   * @abstract
   */
  BasLibraryCollection.prototype.destroy = function () {}

  /**
   * @abstract
   */
  BasLibraryCollection.prototype.suspend = function () {}

  /**
   * @abstract
   */
  BasLibraryCollection.prototype.resume = function () {}

  return BasLibraryCollection
}
