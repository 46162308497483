'use strict'

angular
  .module('basalteApp')
  .constant('BAS_DEEZER', {
    BASE_URL: 'https://api.deezer.com',
    ERR_INVALID_RESPONSE: 'Deezer request - Invalid response',
    ERR_INVALID_SOURCE: 'Invalid basSource',
    ERR_DEEZER_NOT_LINKED: 'Deezer not linked',
    LIMIT_MAX: 100,
    LIMIT_DEFAULT: 25,
    KEY_RESULT_IDS: 'resultIds',
    KEY_RESULT: 'result',
    K_NUM_OF_TRACKS: 'nb_tracks',
    K_TITLE: 'title',
    K_PICTURE_MEDIUM: 'picture_medium',
    FAV_TYPE_PLAYLIST: 'playlist',
    FAV_TYPE_RADIO: 'radio',
    REG_SLASH_MULTIPLE: /\/\/+/g,
    REG_SLASH_END: /$\//g
  })
