'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasThermostatControl', [
    'BAS_API',
    'BasUtilities',
    basThermostatControlFactory
  ])

/**
 * @param BAS_API
 * @param {BasUtilities} BasUtilities
 * @returns BasThermostatControl
 */
function basThermostatControlFactory (
  BAS_API,
  BasUtilities
) {
  var REQUEST_TIMEOUT = 2000

  var CSS_DEVICE_SHOW_TOGGLE = 'brs-show-toggle'
  var CSS_SHOW_SEPARATOR = 'brs-show-separator'

  /**
   * @constructor
   * @param {ThermostatControl} data
   * @param {BasThermostat} device
   */
  function BasThermostatControl (data, device) {

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {number}
     */
    this.subType = -1

    /**
     * @type {string}
     */
    this.subTypeStr = ''

    /**
     * @type {boolean}
     */
    this.includeInScene = false

    /**
     * @type {boolean}
     */
    this.active = false

    /**
     * @type {boolean}
     */
    this.canEdit = false

    /**
     * @type {Object}
     */
    this.css = {}
    this._resetCss()

    /**
     * @type {boolean}
     */
    this._canToggle = true

    this._device = device

    this._enableToggleInput = this.enableToggleInput.bind(this)

    if (data) this.parseData(data)
  }

  /**
   * Translate thermostat control type
   *
   * @param {number} key
   * @returns {string}
   */
  BasThermostatControl.getSubTypeStr = function (key) {

    // Compare strings with strings
    switch (key) {
      case BAS_API.ThermostatControl.TYPES.FLOOR_HEATING:
        return BasUtilities.translate('floor_heating')
      case BAS_API.ThermostatControl.TYPES.AIR_CONDITIONING:
        return BasUtilities.translate('air_conditioning')
      default:
        return ''
    }
  }

  /**
   * Generates simple name string (taking into account type, subType, ...)
   * format: TYPE_NAME - CUSTOM_NAME
   *
   * Needs to be retrieved again when translation changes!
   *
   * @returns {string}
   */
  BasThermostatControl.prototype.getName = function () {

    var result = ''

    if (this.subTypeStr) {

      result += this.subTypeStr

      if (this.name) result += ' - '
    }

    result += this.name

    return result
  }

  BasThermostatControl.prototype.toggle = function toggle (force) {

    if (this.canEdit && this._canToggle) {

      this.active = BasUtil.isBool(force)
        ? force
        : !this.active

      this.commit()

      this._canToggle = false

      this.toggleTimeout = setTimeout(
        this._enableToggleInput,
        REQUEST_TIMEOUT
      )
    }
  }

  BasThermostatControl.prototype.enableToggleInput = function () {

    // Clear timeouts
    clearTimeout(this.toggleTimeout)

    // Enable input
    this._canToggle = true
  }

  BasThermostatControl.prototype.onTriggerActive = function () {

    if (this.canEdit) {

      this.active = true
      this.commit()
    }
  }

  BasThermostatControl.prototype.onTriggerRelease = function () {

    if (this.canEdit) {

      this.active = false
      this.commit()
    }
  }

  /**
   * @param {boolean} [force]
   */
  BasThermostatControl.prototype.toggleInclude = function (force) {

    this.includeInScene = BasUtil.isBool(force)
      ? force
      : !this.includeInScene
  }

  BasThermostatControl.prototype.commit = function () {

    if (this.canEdit && this._device) {

      this._device.commit(this.uuid, this.active)
    }
  }

  /**
   * @param {ThermostatControl} [data]
   */
  BasThermostatControl.prototype.parseData = function (data) {

    this._resetCapabilities()

    if (BasUtil.isObject(data)) {

      this.name = data.name
      this.uuid = data.uuid
      this.subType = data.subType

      if (data.allowsRead(BAS_API.ThermostatControl.ACTIVE)) {

        this.active = data.active
        this._uiToggleToggle(true)

        if (data.allowsWrite(BAS_API.ThermostatControl.ACTIVE)) {

          this.canEdit = true
        }
      }
    }

    this.updateTranslation()
    this.enableToggleInput()

  }

  /**
   * @param {boolean} active
   */
  BasThermostatControl.prototype.parseSceneControl = function (active) {

    if (BasUtil.isBool(active)) {

      this.active = active
      this.canEdit = true
      this.includeInScene = true
    }
  }

  /**
   * @returns {boolean}
   */
  BasThermostatControl.prototype.getSceneValue = function () {

    return this.active
  }

  BasThermostatControl.prototype.updateTranslation = function () {

    this.subTypeStr = BasThermostatControl.getSubTypeStr(this.subType)

    this.css[CSS_SHOW_SEPARATOR] =
      (
        BasUtil.isNEString(this.subTypeStr) &&
        BasUtil.isNEString(this.name)
      )
  }

  /**
   * @private
   * @param {boolean} [force]
   */
  BasThermostatControl.prototype._uiToggleToggle = function (force) {

    this.css[CSS_DEVICE_SHOW_TOGGLE] =
      BasUtil.isBool(force)
        ? force
        : !this.css[CSS_DEVICE_SHOW_TOGGLE]
  }

  BasThermostatControl.prototype._resetCss = function resetCss () {

    this.css[CSS_DEVICE_SHOW_TOGGLE] = false
    this.css[CSS_SHOW_SEPARATOR] = false

  }

  BasThermostatControl.prototype._resetCapabilities = function () {

    this.canEdit = false
  }

  /**
   * Clears the device listeners and the device reference
   */
  BasThermostatControl.prototype.clear = function clear () {

    this._device = null
    this.includeInScene = false
    this._resetCss()
  }

  return BasThermostatControl
}
