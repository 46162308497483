'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasLegacyTidalCheck', [
    '$rootScope',
    'BAS_SOURCE',
    'BAS_SOURCES',
    'STATES',
    'BAS_MODAL',
    'BAS_CURRENT_CORE',
    'CurrentBasCore',
    'BasConditionsHelper',
    'BasState',
    'BasModal',
    BasLegacyTidalCheck
  ])

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {STATES} STATES
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasConditionsHelper} BasConditionsHelper
 * @param {BasState} BasState
 * @param {BasModal} BasModal
 */
function BasLegacyTidalCheck (
  $rootScope,
  BAS_SOURCE,
  BAS_SOURCES,
  STATES,
  BAS_MODAL,
  BAS_CURRENT_CORE,
  CurrentBasCore,
  BasConditionsHelper,
  BasState,
  BasModal
) {

  var MODAL_SHOW_DELAY_MS = 2000

  var currentBasCoreState = CurrentBasCore.get()

  var musicConfigWait
  var sharedStorageWait

  var showModalTimeoutId = 0

  this.check = check

  init()

  function init () {

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      _onCurrentCoreCoreConnected
    )
  }

  function check () {

    _clearWait()
    _clearShowModalTimeout()

    musicConfigWait = BasConditionsHelper.waitForMusicConfig(
      undefined,
      _checkSources
    )
    sharedStorageWait =
      BasConditionsHelper.waitForSharedStorageTidalLegacyAuthDontAsk(
        undefined,
        _checkSources
      )
  }

  /**
   * @private
   */
  function _checkSources () {

    var sources, keys, length, i, source, promises

    if (
      !CurrentBasCore.hasCore() ||
      (
        CurrentBasCore.hasAVFullSupport()
          ? !currentBasCoreState.core.core.avSourcesReceived
          : !currentBasCoreState.core.core.musicConfigReceived
      ) ||
      currentBasCoreState.core.core.sharedServerStorage.tidalLegacyAuthDontAsk
    ) {

      return
    }

    _clearWait()

    promises = []

    sources = BAS_SOURCES.SOURCES

    keys = Object.keys(sources.players)
    length = keys.length
    for (i = 0; i < length; i++) {

      source = sources.sources[sources.players[keys[i]]]

      if (
        source &&
        source.type === BAS_SOURCE.T_PLAYER &&
        source.tidal
      ) {

        promises.push(source.tidal.getLegacyAuth())
      }
    }

    BasUtil.promiseAll(promises)
      .then(_onHasLegacyAuthPromiseResults)
  }

  function _onHasLegacyAuthPromiseResults (results) {

    var length, i

    length = results.length
    for (i = 0; i < length; i++) {

      if (results[i].result === true) {

        // If any of the sources was previously logged in with TIDAL legacy
        //  authentication, show a modal that gives the user the option to
        //  go to the TIDAL streaming settings

        _showModalDelayed()
        break
      }
    }
  }

  function _showModalDelayed () {

    _clearShowModalTimeout()
    showModalTimeoutId = setTimeout(_showModal, MODAL_SHOW_DELAY_MS)
  }

  function _clearShowModalTimeout () {

    clearTimeout(showModalTimeoutId)
    showModalTimeoutId = 0
  }

  function _showModal () {

    // Only show if connected
    if (CurrentBasCore.hasCore()) {

      BasModal.show(BAS_MODAL.T_TIDAL_LEGACY_AUTH)
        .then(_onModal)
    }
  }

  function _onModal (modal) {

    modal.close.then(_onModalClose)
  }

  function _onModalClose (result) {

    if (result === BAS_MODAL.C_YES) {

      BasState.go(STATES.SETTINGS_MUSIC_STREAMING_TIDAL)

    } else if (result === BAS_MODAL.C_EXTRA) {

      if (
        CurrentBasCore.hasCore() &&
        currentBasCoreState.core.core.sharedServerStorage
      ) {

        currentBasCoreState.core.core.sharedServerStorage
          .updateTidalLegacyAuthDontAsk(true)
          .catch(onUpdateTidalLegacyAuthDontAskError)
      }
    } else {

      // 'Not now' -> Do nothing
    }
  }

  function onUpdateTidalLegacyAuthDontAskError () {

    // Empty
  }

  function _onCurrentCoreCoreConnected (
    _event,
    _basCoreContainer,
    isConnected
  ) {

    if (!isConnected) _clearShowModalTimeout()
  }

  function _clearWait () {

    if (sharedStorageWait) sharedStorageWait.abort()
    if (musicConfigWait) musicConfigWait.abort()

    sharedStorageWait = undefined
    musicConfigWait = undefined
  }
}
