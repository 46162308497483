'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasTimer', [
    'BAS_API',
    'BAS_INTL',
    'BasTimerDay',
    'CurrentBasCore',
    basTimerFactory
  ])

/**
 * @param BAS_API
 * @param {BAS_INTL} BAS_INTL
 * @param BasTimerDay
 * @param {CurrentBasCore} CurrentBasCore
 * @returns BasTimer
 */
function basTimerFactory (
  BAS_API,
  BAS_INTL,
  BasTimerDay,
  CurrentBasCore
) {
  var CSS_IS_ENABLED = 'bas-scheduler--is-enabled'

  /**
   * @constructor
   * @param {string} uuid
   * @param {BasRoom} basRoom
   */
  function BasTimer (uuid, basRoom) {

    /**
     * @type {string}
     */
    this.uuid = BasUtil.isNEString(uuid) ? uuid : ''

    /**
     * @type {string}
     */
    this.name = '-'

    /**
     * @type {boolean}
     */
    this.enabled = false

    /**
     * @type {boolean}
     */
    this.canChangeEnabledState = false

    /**
     * @type {Object<string, BasTimerDay>}
     */
    this.entries = {}

    /**
     * @type {string[]}
     */
    this.uiEntries = []

    /**
     * @type {Array}
     */
    this.daysUi = []

    /**
     * @type {Object<string,BasTimerDay>}
     */
    this.days = {}

    /**
     * @type {string}
     */
    this.type = BAS_API.Timer.T_ON_OFF

    /**
     * String representation of relevant data inside this Timer
     *
     * @type {string}
     */
    this.dataString = ''

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this.css = {}
    this._resetCss()

    this.parse()
  }

  /**
   * @param {Timer} [device]
   */
  BasTimer.prototype.parse = function (device) {

    var timerDevice, days, length, i, day, key

    timerDevice = BasUtil.isObject(device)
      ? device
      : this._getTimer()

    if (BasUtil.isObject(timerDevice)) {

      this.uiEntries = []
      this.entries = {}

      if (BasUtil.isNEString(timerDevice.type)) {

        this.type = timerDevice.type
      }

      if (BasUtil.isNEString(timerDevice.name)) {

        this.name = timerDevice.name
      }

      if (BasUtil.isBool(timerDevice.enabled)) {

        this.toggleEnabled(timerDevice.enabled)
      }

      this.canChangeEnabledState = timerDevice.allowsWrite(
        BAS_API.Timer.C_SCHEDULER
      )

      days = timerDevice.days

      if (BasUtil.isObject(days)) {

        length = BAS_INTL.DAYS_WEEK_INTL.length

        for (i = 0; i < length; i++) {

          key = BAS_INTL.DAYS_WEEK_INTL[i]

          if (BasUtil.isObject(days[key])) {

            day = new BasTimerDay(key, this.type)
            day.parse(days[key], this.type)

            this.entries[day.id] = day
            this.uiEntries.push(key)
          }
        }
      }
    }

    this.dataString = this.makeDataString()
  }

  BasTimer.prototype.toggleTimer = function () {

    if (this.canChangeEnabledState) {

      this.toggleEnabled()
      this.commit()
      this.updateTimer()
    }
  }

  /**
   * @param {string} dayId
   * @param {string[]} dayIds
   */
  BasTimer.prototype.copyDayToDays = function (
    dayId,
    dayIds
  ) {
    var length, i, sourceEntry, destinationEntry

    sourceEntry = this.entries[dayId]

    if (sourceEntry && Array.isArray(dayIds)) {

      length = dayIds.length
      for (i = 0; i < length; i++) {

        destinationEntry = this.entries[dayIds[i]]

        if (destinationEntry) {

          destinationEntry.setPointsFromEntry(sourceEntry)
        }
      }

      this.commit()
      this.updateTimer()
    }
  }

  BasTimer.prototype.toggleEnabled = function (force) {

    this.enabled = BasUtil.isBool(force) ? force : !this.enabled
    this.css[CSS_IS_ENABLED] = this.enabled
  }

  /**
   * @param {string} entryId
   */
  BasTimer.prototype.addNewPoint = function (entryId) {

    var entry

    entry = this.entries[entryId]

    if (entry) {

      entry.addNewPoint()
    }
  }

  /**
   * @param {string} _entryId
   * @param {BasTimerPoint} _point
   */
  BasTimer.prototype.changePoint = function (
    _entryId,
    _point
  ) {
    // Empty
  }

  /**
   * @param {string} entryId
   * @param {BasTimerPoint} point
   */
  BasTimer.prototype.deletePoint = function (
    entryId,
    point
  ) {
    var entry

    entry = this.entries[entryId]

    if (entry) {

      entry.deletePoint(point)
    }
  }

  BasTimer.prototype.updateTranslation = function updateTranslation () {

    var i, length, day

    length = this.daysUi.length
    for (i = 0; i < length; i++) {

      day = this.days[this.daysUi[i]]
      if (day) day.updateTranslation()
    }
  }

  /**
   * @returns {Promise}
   */
  BasTimer.prototype.updateTimer = function () {

    var timer = this._getTimer()

    if (BasUtil.isObject(timer)) {

      return timer.updateTimer()
    }

    return Promise.reject('No Device')
  }

  BasTimer.prototype.commit = function () {

    var timer = this._getTimer()

    if (BasUtil.isObject(timer)) {

      timer.setEnabled(this.enabled)
      timer.setDays(this.prepareDays())
      this.dataString = this.makeDataString()
    }
  }

  /**
   * @returns {Object<string,TTimerPoint[]>}
   */
  BasTimer.prototype.prepareDays = function () {

    var result, length, i, entryId, entry

    result = {}

    length = this.uiEntries.length
    for (i = 0; i < length; i++) {

      entryId = this.uiEntries[i]
      entry = this.entries[entryId]

      if (entry) result[entryId] = entry.generateApiDay()
    }

    return result
  }

  /**
   * @returns {string}
   */
  BasTimer.prototype.makeDataString = function () {

    var result, length, i, entryId, entry

    result = ''

    length = this.uiEntries.length
    for (i = 0; i < length; i++) {

      entryId = this.uiEntries[i]
      entry = this.entries[entryId]

      if (entry) {

        result += entryId
        result += entry.makeDataString()
      }
    }

    return result
  }

  /**
   * @private
   * @returns {?Timer}
   */
  BasTimer.prototype._getTimer = function () {

    var timerCtrl

    if (this._basRoom &&
      this._basRoom.room &&
      this._basRoom.room.timerCtrl) {

      timerCtrl = CurrentBasCore.getDevice(this._basRoom.room.timerCtrl)

      if (timerCtrl) return timerCtrl.getTimer(this.uuid)
    }

    return null
  }

  /**
   * @private
   */
  BasTimer.prototype._resetCss = function () {

    this.css[CSS_IS_ENABLED] = false
  }

  return BasTimer
}
