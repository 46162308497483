'use strict'

/**
 * @module tidal
 */

var EventEmitter = require('@gidw/event-emitter-js')

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')

var log = require('./logger')

/**
 * Holds necessary information for Tidal requests.
 *
 * @constructor
 * @extends EventEmitter
 * @param {number} id CobraNet ID
 * @param {BasCore} basCore
 * @since 1.9.0
 */
function Tidal (id, basCore) {

  EventEmitter.call(this)

  this._basCore = basCore
  this._id = id

  this._accessToken = undefined
  this._sessionId = undefined
  this._userId = undefined
  this._countryCode = undefined
  this._hasLegacyAuth = undefined

  this._clear()

  this._dirty = true

  this._handleLinkUrl = this._onLinkUrl.bind(this)
}

Tidal.prototype = Object.create(EventEmitter.prototype)
Tidal.prototype.constructor = Tidal

// region Events

/**
 * @event Tidal#EVT_LINK_CHANGED
 * @param {boolean} isLinked Current link state
 */

/**
 * Oauth redirect has reached the basCore
 *
 * @event Tidal#EVT_LINK_FINISHED
 * @param {boolean} finished
 * @since 3.6.1
 */

/**
 * Oauth failed
 *
 * @event Tidal#EVT_LINK_ERROR
 * @param {string} message
 * @since 3.6.1
 */

// endregion

/**
 * @constant {string}
 */
Tidal.EVT_LINK_CHANGED = 'tidalLinkChanged'

/**
 * @constant {string}
 * @since 3.6.1
 */
Tidal.EVT_LINK_FINISHED = 'tidalLinkFinished'

/**
 * @constant {string}
 * @since 3.6.1
 */
Tidal.EVT_LINK_ERROR = 'tidalLinkError'

/**
 * @constant {string}
 * @since 3.6.1
 */
Tidal.EVT_LINK_ERROR = 'tidalLinkError'

/**
 * @constant {string}
 * @since 3.6.1
 */
Tidal.ERR_INVALID_LINK_URL = 'errTidalInvalidLinkUrl'

/**
 * @constant {string}
 * @since 3.6.1
 */
Tidal.ERR_INVALID_RESPONSE = 'errTidalInvalidResponse'

/**
 * @constant {string}
 */
Tidal.ERR_EMPTY_USERNAME = 'tidalLoginEmptyUsername'

/**
 * @constant {string}
 */
Tidal.ERR_EMPTY_PASSWORD = 'tidalLoginEmptyPassword'

/**
 * @name Tidal#accessToken
 * @type {string}
 * @readonly
 */
Object.defineProperty(Tidal.prototype, 'accessToken', {
  get: function () {
    return this._accessToken
  }
})

/**
 * @name Tidal#sessionId
 * @type {string}
 * @readonly
 */
Object.defineProperty(Tidal.prototype, 'sessionId', {
  get: function () {
    return this._sessionId
  }
})

/**
 * @name Tidal#userId
 * @type {number}
 * @readonly
 */
Object.defineProperty(Tidal.prototype, 'userId', {
  get: function () {
    return this._userId
  }
})

/**
 * @name Tidal#countryCode
 * @type {string}
 * @readonly
 */
Object.defineProperty(Tidal.prototype, 'countryCode', {
  get: function () {
    return this._countryCode
  }
})

/**
 * @name Tidal#hasLegacyAuth
 * @type {string}
 * @readonly
 */
Object.defineProperty(Tidal.prototype, 'hasLegacyAuth', {
  get: function () {
    return this._hasLegacyAuth
  }
})

/**
 * @name Tidal#dirty
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Tidal.prototype, 'dirty', {
  get: function () {
    return this._dirty
  }
})

/**
 * @name Tidal#linked
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Tidal.prototype, 'linked', {
  get: function () {
    return (
      (
        BasUtil.isNEString(this._accessToken) ||
        BasUtil.isNEString(this._sessionId)
      ) &&
      BasUtil.isPNumber(this._userId)
    )
  }
})

/**
 * Parse Tidal messages
 *
 * @param {Object} obj
 * @param {TDeviceParseOptions} [options]
 */
Tidal.prototype.parse = function parse (obj, options) {

  var checkLink, prev, doEmit, session
  var emitLinkChanged, emitLinkFinished

  if (BasUtil.isObject(obj)) {

    // Only "false" when emit === "false"
    doEmit = !options || options.emit !== false

    emitLinkChanged = false
    emitLinkFinished = false

    // Get current linked string
    prev = this.getLinkedString()

    // Session
    if (BasUtil.isObject(obj[P.SESSION])) {

      // Valid session

      // Use session
      session = obj[P.SESSION]

      checkLink = false

      // Access token
      if (
        BasUtil.isString(session[P.ACCESS_TOKEN]) &&
        this._accessToken !== session[P.ACCESS_TOKEN]
      ) {

        checkLink = true
        this._accessToken = session[P.ACCESS_TOKEN]
      }

      // Legacy linked
      if (BasUtil.isBool(session[P.LINKED])) {

        checkLink = true

        if (!session[P.LINKED]) {

          this._clear()
        }
      }

      // Legacy session ID
      if (
        BasUtil.isString(session[P.SESSION_ID]) &&
        this._sessionId !== session[P.SESSION_ID]
      ) {

        checkLink = true
        this._sessionId = session[P.SESSION_ID]
      }

      // User ID
      if (BasUtil.isVNumber(session[P.USER_ID]) &&
                this._userId !== session[P.USER_ID]) {

        checkLink = true
        this._userId = session[P.USER_ID]
      }

      // Check country code
      if (
        BasUtil.isNEString(session[P.COUNTRY_CODE]) &&
        this._countryCode !== session[P.COUNTRY_CODE]
      ) {

        checkLink = true
        this._countryCode = session[P.COUNTRY_CODE]
      }

      // Emit if link has changed
      if (
        doEmit &&
        checkLink &&
        prev !== this.getLinkedString()
      ) {

        emitLinkChanged = true
      }

    } else if (obj[P.SESSION] === null) {

      // Logout
      this._clear()

      if (doEmit && prev !== this.getLinkedString()) {

        emitLinkChanged = true
      }
    }

    // Link finished
    if (BasUtil.isBool(obj[P.LINK_FINISHED])) {

      emitLinkFinished = true
    }

    if (BasUtil.isBool(obj[P.HAS_LEGACY_AUTH])) {

      this._hasLegacyAuth = obj[P.HAS_LEGACY_AUTH]
    }

    // Link errors
    if (BasUtil.isString(obj[P.LINK_ERROR])) {

      // Check for known errors
      switch (obj[P.LINK_ERROR]) {
        case P.LINK_ERROR_USER_DENIED:
        case P.LINK_ERROR_TOKEN:
        case P.LINK_ERROR_UNKNOWN_ERROR:

          this.emit(
            Tidal.EVT_LINK_ERROR,
            obj[P.LINK_ERROR]
          )

          break
        default:

          log.error(
            'Tidal - parse' +
                        ' - linkError' +
                        ' - Unknown error',
            obj
          )

          this.emit(
            Tidal.EVT_LINK_ERROR,
            obj[P.LINK_ERROR_UNKNOWN]
          )
      }
    }

    if (emitLinkChanged) {

      this.emit(Tidal.EVT_LINK_CHANGED, this.linked)
    }

    if (emitLinkFinished) {

      this.emit(
        Tidal.EVT_LINK_FINISHED,
        obj[P.LINK_FINISHED]
      )
    }
  }
}

/**
 * Clears the link information
 *
 * @private
 */
Tidal.prototype._clear = function () {

  this._accessToken = ''
  this._sessionId = ''
  this._userId = -1
  this._countryCode = ''
}

/**
 * Creates a string containing all "authentication" information
 *
 * @private
 * @returns {string}
 */
Tidal.prototype.getLinkedString = function () {
  return '' + (
    BasUtil.isNEString(this._accessToken)
      ? this._accessToken
      : this._sessionId
  ) + this._userId + this._countryCode
}

/**
 * @returns {Object}
 */
Tidal.prototype._getBasCoreMessage = function () {

  var msg = {}
  msg[P.PLAYER] = {}
  msg[P.PLAYER][P.ID] = this._id
  msg[P.PLAYER][P.TIDAL] = {}

  return msg
}

/**
 * @returns {Promise<Tidal>}
 */
Tidal.prototype.status = function status () {

  var _this, data

  _this = this

  data = this._getBasCoreMessage()

  data[P.PLAYER][P.TIDAL] = {}
  data[P.PLAYER][P.TIDAL][P.TYPE] = P.STATUS

  return this._basCore.requestRetry(data).then(onStatus)

  function onStatus (result) {

    if (_this.isValidTidalMessage(result)) {

      _this.parse(result[P.PLAYER][P.TIDAL])

      _this._dirty = false

      return _this

    } else {

      return Promise.reject(Tidal.ERR_INVALID_RESPONSE)
    }
  }
}

/**
 * Retrieve the Tidal link URL from the basCore
 *
 * @returns {Promise<string>}
 */
Tidal.prototype.linkUrl = function () {

  var data

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.TIDAL][P.TYPE] = P.LINK_URL

  return this._basCore.requestRetry(data).then(this._handleLinkUrl)
}

/**
 * @private
 * @param {Object} result
 * @returns {(string|Promise)}
 */
Tidal.prototype._onLinkUrl = function (result) {

  var _linkUrl

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  if (this.isValidTidalMessage(result)) {

    _linkUrl = result[P.PLAYER][P.TIDAL][P.LINK_URL]

    return BasUtil.isNEString(_linkUrl)
      ? this._basCore.getHTTPUrl(_linkUrl)
      : Promise.reject(Tidal.ERR_INVALID_LINK_URL)
  }

  return Promise.reject(Tidal.ERR_INVALID_RESPONSE)
}

/**
 * Attempt to login with Tidal account.
 *
 * @param {string} username
 * @param {string} password
 * @returns {Promise<?Object>} Resolves to session object (Tidal API)
 */
Tidal.prototype.login = function login (
  username,
  password
) {
  var _this, data

  _this = this

  if (!BasUtil.isNEString(username)) {

    return Promise.reject(Tidal.ERR_EMPTY_USERNAME)
  }

  if (!BasUtil.isNEString(password)) {

    return Promise.reject(Tidal.ERR_EMPTY_PASSWORD)
  }

  data = this._getBasCoreMessage()
  data[P.PLAYER][P.TIDAL] = {}
  data[P.PLAYER][P.TIDAL][P.TYPE] = P.LOGIN
  data[P.PLAYER][P.TIDAL][P.USERNAME] = username
  data[P.PLAYER][P.TIDAL][P.PASSWORD] = password

  return this._basCore.requestRetry(data, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(onLogin)

  function onLogin (result) {

    // Check result
    if (_this.isValidTidalMessage(result)) {

      _this._dirty = false

      _this.parse(result[P.PLAYER][P.TIDAL], { emit: false })

      return result[P.PLAYER][P.TIDAL][P.SESSION]
    }

    return Promise.reject(Tidal.ERR_INVALID_RESPONSE)
  }
}

/**
 * Logout current Tidal account.
 * This will cause en event.
 */
Tidal.prototype.logout = function logout () {

  var data = this._getBasCoreMessage()
  data[P.PLAYER][P.TIDAL] = {}
  data[P.PLAYER][P.TIDAL][P.TYPE] = P.LOGOUT

  this._basCore.send(data)
}

/**
 * Checks whether a basCore message is a valid Tidal message.
 *
 * @param {?Object} message
 * @returns {boolean}
 */
Tidal.prototype.isValidTidalMessage = function (message) {
  return (
    BasUtil.isObject(message) &&
    BasUtil.isObject(message[P.PLAYER]) &&
    message[P.PLAYER][P.ID] === this._id &&
    BasUtil.isObject(message[P.PLAYER][P.TIDAL])
  )
}

/**
 * Destructor
 */
Tidal.prototype.destroy = function destroy () {

  this.removeAllListeners()
  this._basCore = null
}

module.exports = Tidal
