'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basMarquee')
  .factory('BasMarqueeState', basMarqueeState)

function basMarqueeState () {

  /**
   * @constructor
   */
  function BasMarqueeState () {

    /**
     * @type {boolean}
     */
    this.playing = false

    /**
     * @type {boolean}
     */
    this.started = false

    /**
     * @type {boolean}
     */
    this.shouldRun = false

    /**
     * @private
     * @type {?Function}
     */
    this._isReadyCheck = null

    /**
     * @private
     * @type {?Function}
     */
    this._startDelayedAnimation = null

    /**
     * @private
     * @type {?Function}
     */
    this._onAnimationDone = null
  }

  BasMarqueeState.prototype.startDelayedAnimation = function () {
    this.started = true
    BasUtil.execute(this._startDelayedAnimation)
  }

  BasMarqueeState.prototype.onAnimationStart = function () {
    this.playing = true
  }

  BasMarqueeState.prototype.onAnimationDone = function () {
    this.playing = false
    this.started = false
    BasUtil.execute(this._onAnimationDone)
  }

  /**
   * Checks if state is ready to start animation
   *
   * @returns {boolean}
   */
  BasMarqueeState.prototype.isReady = function () {

    var result = BasUtil.isFunction(this._isReadyCheck)
      ? this._isReadyCheck()
      : false

    return !this.playing && result
  }

  BasMarqueeState.prototype.setIsReadyCheck = function (func) {
    this._isReadyCheck = func
  }

  BasMarqueeState.prototype.setStartDelayedAnimation = function (func) {
    this._startDelayedAnimation = func
  }

  BasMarqueeState.prototype.setOnAnimationDone = function (func) {
    this._onAnimationDone = func
  }

  return BasMarqueeState
}
