'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('addToPlaylistModalCtrl', [
    '$rootScope',
    '$scope',
    'UI_HELPER',
    'BAS_API',
    'BAS_SPLASH',
    'modalHelperService',
    'CurrentRoom',
    'BasSourceLibraryHelper',
    'close',
    'event',
    addToPlaylistModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {UI_HELPER} UI_HELPER
 * @param BAS_API
 * @param BAS_SPLASH
 * @param modalHelperService
 * @param {CurrentRoom} CurrentRoom
 * @param {BasSourceLibraryHelper} BasSourceLibraryHelper
 * @param close
 * @param event
 */
function addToPlaylistModalCtrl (
  $rootScope,
  $scope,
  UI_HELPER,
  BAS_API,
  BAS_SPLASH,
  modalHelperService,
  CurrentRoom,
  BasSourceLibraryHelper,
  close,
  event
) {
  var modal = this

  var basButtonClass = 'modal-target'

  // "Title" and "New playlist" fields
  var minimumNumberOfItems = 2

  var _listeners = []

  modal.style = modalHelperService.getModalStyle()

  /**
   * @type {?BasSource}
   */
  modal.source = null

  modal.addToPlaylist = addToPlaylist
  modal.addToNewPlaylist = addToNewPlaylist
  modal.close = close

  init()

  function init () {

    var basSource

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      UI_HELPER.EVT_RESIZE,
      _onResize
    ))

    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    basSource = CurrentRoom.getBasSource()

    if (basSource) modal.source = basSource

    // Calculate modal position
    modalHelperService.calcPosition(
      event,
      basButtonClass,
      modalHelperService.type.belowRight
    )

    // Check if modal should be shown
    if (modal.source &&
      BasUtil.isObject(modal.source.playlists) &&
      modal.source.queue &&
      modal.source.queue.type === BAS_API.Queue.TYPE_QUEUE) {

      // Retrieve Playlists to make sure playlist have loaded once
      modal.source.playlists.retrieve().then(_onPlaylistsRetrieved)

      _onPlaylistsRetrieved()

    } else {

      close()
    }
  }

  function addToPlaylist (playlist) {

    // Check playlist
    if (playlist && playlist.id) {

      // Check current queue
      if (
        modal.source &&
        modal.source.queue &&
        Array.isArray(modal.source.queue.queue)
      ) {

        // Add playlist
        if (modal.source.isAudioSource) {

          modal.source.source.addSongsToPlaylist(
            playlist.uri,
            _parseQueue(modal.source.queue.queue)
          )

        } else if (modal.source.database) {

          modal.source.database.addSongsToPlaylist(
            playlist.id,
            _parseQueue(modal.source.queue.queue)
          )
        }
      }
    }

    close()
  }

  function addToNewPlaylist () {

    // Check new playlist name
    if (BasUtil.isNEString(modal.newPlaylistName)) {

      // Check current queue
      if (modal.source &&
        modal.source.queue &&
        Array.isArray(modal.source.queue.queue)) {

        BasSourceLibraryHelper.addTracksToNewPlaylist(
          modal.source,
          modal.newPlaylistName,
          _parseQueue(modal.source.queue.queue)
        )
      }
    }

    close()
  }

  function _onPlaylistsRetrieved () {

    modalHelperService.calcHeight(
      minimumNumberOfItems,
      _numberOfModalItems()
    )
  }

  /**
   * @private
   * @returns {number}
   */
  function _numberOfModalItems () {

    var length

    // Set default length to minimum
    length = minimumNumberOfItems

    if (BasUtil.isObject(modal.source) &&
      BasUtil.isObject(modal.source.playlists)) {

      length += modal.source.playlists.editable.length
    }

    return length
  }

  function _parseQueue (queue) {

    var songs, i, length, entry

    songs = []

    length = queue.length
    for (i = 0; i < length; i++) {

      entry = queue[i]

      if (entry) {

        songs.push(entry.uri ? entry.uri : entry.file)
      }
    }

    return songs
  }

  function _onResize () {

    // Calculate modal position
    modalHelperService.calcPosition(
      event,
      basButtonClass,
      modalHelperService.type.belowRight
    )

    $scope.$applyAsync()
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
