'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('basLibraryContextCtrl', [
    '$scope',
    '$timeout',
    'BAS_API',
    'BAS_LIBRARY',
    'modalHelperService',
    'LocalHelper',
    'DeezerHelper',
    'TidalHelper',
    'SpotifyHelper',
    'LibraryState',
    'element',
    'collection',
    'selection',
    'handler',
    'libType',
    'BasUtilities',
    'Logger',
    'close',
    'event',
    basLibraryContextController
  ])

/**
 * @param $scope
 * @param $timeout
 * @param BAS_API
 * @param BAS_LIBRARY
 * @param modalHelperService
 * @param {LocalHelper} LocalHelper
 * @param {DeezerHelper} DeezerHelper
 * @param {TidalHelper} TidalHelper
 * @param {SpotifyHelper} SpotifyHelper
 * @param {LibraryState} LibraryState
 * @param {BasLibraryElement} element
 * @param {BasLibraryCollection} collection
 * @param {Selection} selection
 * @param {Function} handler
 * @param {string} libType
 * @param {BasUtilities} BasUtilities
 * @param Logger
 * @param close
 * @param event
 */
function basLibraryContextController (
  $scope,
  $timeout,
  BAS_API,
  BAS_LIBRARY,
  modalHelperService,
  LocalHelper,
  DeezerHelper,
  TidalHelper,
  SpotifyHelper,
  LibraryState,
  element,
  collection,
  selection,
  handler,
  libType,
  BasUtilities,
  Logger,
  close,
  event
) {
  var modal = this
  var controllerName = 'Bas Library Context Controller'

  // Display variables - Button Target HTML element class
  var basButtonClass = 'modal-target'

  // Time in ms to wait for closing context
  var CLOSE_DELAY = 500

  // Hold delay promise
  var delayPromise = null

  // Check to see if height is calculated already
  var heightIsCalculated = false

  // Check if modal is closing
  var isClosing = false

  /**
   * @type {TLibraryState}
   */
  var libState = LibraryState.get()

  var libTypeHelper = null

  Logger.debug(controllerName, element)

  modal.can = {
    playNow: false,
    playNext: false,
    addQueue: false,
    replaceQueue: false,
    addPlaylist: false,
    addAsanoFavourite: false,
    goToAlbum: false,
    goToArtist: false,
    deezer: false,
    tidal: false,
    spotify: false,
    favourite: false,
    clearSelection: false
  }

  modal.newPlaylistName = ''
  modal.playlists = []
  modal.showPlaylists = false

  // Id of the clicked item
  modal.elementId = ''

  // Make modalHelperService available
  modal.modalHelperService = modalHelperService

  // Set modal style
  modal.style = modalHelperService.getModalStyle()

  // Actions
  modal.close = closeModal
  modal.onModalReady = onModalReady
  modal.playAppend = playAppend
  modal.playNow = playNow
  modal.playNext = playNext
  modal.replaceNow = replaceNow
  modal.goPlaylists = goPlaylists
  modal.addToPlaylist = addToPlaylist
  modal.addToNewPlaylist = addToNewPlaylist
  modal.goToAlbum = goToAlbum
  modal.goToArtist = goToArtist
  modal.toggleFavourite = toggleFavourite
  modal.back = back
  modal.clearSelection = clearSelection

  modal.isFavourite = false

  // Init controller
  init()

  // region Init

  function init () {

    setLibTypeHelper()
    setFavouriteStrings()

    // Check element
    if (BasUtil.isObject(element)) elementCapabilities()

    // Check selection
    if (selection) selectionCapabilities()
  }

  function elementCapabilities () {

    if (element.type) {

      if (libTypeHelper && libTypeHelper.checkFavourite) {

        libTypeHelper.checkFavourite(
          element.type,
          element.id
        ).then(onFavourite)
      }
    }

    if (libTypeHelper && libTypeHelper.checkContextCapabilities) {

      libTypeHelper.checkContextCapabilities(
        element,
        collection,
        modal.can
      )
    }

    // Modal position relative to button
    modalHelperService.calcPosition(
      event,
      basButtonClass,
      modalHelperService.type.horizontal
    )
  }

  function selectionCapabilities () {

    var basSource, onlyReplace, hasQueueCapabilities, canPlayUri

    onlyReplace = false
    hasQueueCapabilities = false
    canPlayUri = false

    basSource = LibraryState.getCurrentSource()

    if (basSource) {

      hasQueueCapabilities = basSource.isAudioSource
        ? basSource.canAddToQueue
        : true

      canPlayUri = basSource.isAudioSource
        ? basSource.source.allowsExecute(
          BAS_API.AudioSource.C_PLAY_URI
        )
        : true
    }

    switch (libType) {
      case BAS_LIBRARY.TYPE_LOCAL:
      case BAS_LIBRARY.TYPE_DEEZER:
      case BAS_LIBRARY.TYPE_TIDAL:

        if (basSource &&
          basSource.source &&
          basSource.source.queue) {

          onlyReplace = (
            basSource.source.queue.type ===
            BAS_API.Queue.TYPE_STREAM ||
            basSource.source.queue.type ===
            BAS_API.Queue.TYPE_DEEZER_FLOW ||
            basSource.source.queue.type ===
            BAS_API.Queue.TYPE_DEEZER_RADIO
          )
        }

        modal.can.playNow = hasQueueCapabilities
        modal.can.playNext = !onlyReplace && hasQueueCapabilities
        modal.can.addQueue = !onlyReplace && hasQueueCapabilities
        modal.can.replaceQueue = !onlyReplace && hasQueueCapabilities
        modal.can.addPlaylist = true
        modal.can.clearSelection = true

        break
      case BAS_LIBRARY.TYPE_SPOTIFY:

        modal.can.playNow = canPlayUri
        modal.can.playNext = false
        modal.can.addQueue = false
        modal.can.replaceQueue = false
        modal.can.addPlaylist = true
        modal.can.clearSelection = true

        break
    }

    modalHelperService.calcPosition(
      event,
      basButtonClass,
      modalHelperService.type.verticalRight
    )
  }

  function setLibTypeHelper () {

    switch (libType) {
      case BAS_LIBRARY.TYPE_LOCAL:
        libTypeHelper = LocalHelper
        break
      case BAS_LIBRARY.TYPE_DEEZER:
        libTypeHelper = DeezerHelper
        break
      case BAS_LIBRARY.TYPE_SPOTIFY:
        libTypeHelper = SpotifyHelper
        break
      case BAS_LIBRARY.TYPE_TIDAL:
        libTypeHelper = TidalHelper
        break
    }
  }

  function setFavouriteStrings () {

    switch (libType) {
      case BAS_LIBRARY.TYPE_DEEZER:
        modal.saveFavourite =
          BasUtilities.translate('save_deezer_favourite')
        modal.removeFavourite =
          BasUtilities.translate('remove_deezer_favourite')
        break
      case BAS_LIBRARY.TYPE_SPOTIFY:
        modal.saveFavourite =
          BasUtilities.translate('save_spotify_favourite')
        modal.removeFavourite =
          BasUtilities.translate('remove_spotify_favourite')
        break
      case BAS_LIBRARY.TYPE_TIDAL:
        modal.saveFavourite =
          BasUtilities.translate('save_tidal_favourite')
        modal.removeFavourite =
          BasUtilities.translate('remove_tidal_favourite')
        break
    }

  }

  // endregion

  // region Play

  function playAppend () {
    play(BAS_API.Queue.ADD_OPTIONS.end)
  }

  function playNow () {

    var source = LibraryState.getCurrentSource()

    if (
      source &&
      source.isAudioSource &&
      !source.canAddToQueue
    ) {

      play()

    } else {

      play(BAS_API.Queue.ADD_OPTIONS.now)
    }

  }

  function playNext () {
    play(BAS_API.Queue.ADD_OPTIONS.next)
  }

  function replaceNow () {
    play(BAS_API.Queue.ADD_OPTIONS.replaceNow)
  }

  function play (type) {

    if (element && libTypeHelper && libTypeHelper.play) {
      libTypeHelper.play(element, type)
    }

    if (selection && libTypeHelper && libTypeHelper.playSelection) {

      libTypeHelper.playSelection(selection.selectedItems, type)
    }

    // Close modal
    clearSelection()
  }

  // endregion

  // region Playlists

  function goPlaylists () {

    if (element) modal.elementId = element.id

    if (libTypeHelper && libTypeHelper.getPlaylists) {

      modal.showPlaylists = true
      libTypeHelper.getPlaylists().then(onPlaylists)
      calcHeight()
    }

    function onPlaylists (playlists) {

      if (Array.isArray(playlists)) {

        modal.playlists = playlists
        calcHeight()
      }
    }
  }

  function addToPlaylist (playlist) {

    if (playlist) {

      if (element &&
        libTypeHelper &&
        libTypeHelper.addElementToPlaylist) {

        libTypeHelper.addElementToPlaylist(playlist.id, element)
          .then(onPlaylistChanged)

      } else if (selection &&
        libTypeHelper &&
        libTypeHelper.addToPlaylist) {

        libTypeHelper.addToPlaylist(
          playlist.id,
          selection.selectedItems
        ).then(onPlaylistChanged)
      }
    }

    clearSelection()
  }

  function addToNewPlaylist () {

    if (element &&
      libTypeHelper &&
      libTypeHelper.addElementToNewPlaylist) {

      libTypeHelper.addElementToNewPlaylist(
        modal.newPlaylistName,
        element
      ).then(onPlaylistChanged)

    } else if (selection &&
      libTypeHelper &&
      libTypeHelper.addToNewPlaylist) {

      libTypeHelper.addToNewPlaylist(
        modal.newPlaylistName,
        selection.selectedItems
      ).then(onPlaylistChanged)
    }

    clearSelection()
  }

  function onPlaylistChanged () {

    handler(BAS_LIBRARY.EVT_PLAYLIST_CHANGED)
  }

  function back () {

    modal.playlists = []
    modal.showPlaylists = false

    calcHeight()
  }

  // endregion

  // region Shortcuts

  function goToAlbum () {

    var manager = getManager()

    // Browse to album
    if (BasUtil.isObject(manager)) {
      manager.selectElement(element, BAS_LIBRARY.SHORTCUT_ALBUM)
    }

    // Close modal
    closeModal()
  }

  function goToArtist () {

    var manager = getManager()

    // Browse to artist
    if (BasUtil.isObject(manager)) {
      manager.selectElement(element, BAS_LIBRARY.SHORTCUT_ARTIST)
    }

    // Close modal
    closeModal()
  }

  // endregion

  // region Favourite

  function toggleFavourite () {

    Logger.debug(controllerName + ' - toggleFavourite')

    if (element &&
      libTypeHelper &&
      libTypeHelper.addRemoveFavouriteId) {

      libTypeHelper.addRemoveFavouriteId(
        element.id,
        element.type,
        modal.isFavourite
      )
        .then(onFavouriteChanged)
    }

    delayedClose()

    /**
     * @param {boolean} result
     */
    function onFavouriteChanged (result) {
      Logger.debug(libType + ' - favourite', result)

      // Check modal still exists
      if (BasUtil.isObject(modal) &&
        BasUtil.isObject($scope)) {

        // Change favourite state
        modal.isFavourite = result

        $scope.$applyAsync()
      }
    }
  }

  function onFavourite (result) {

    // Check Closing flag and result
    if (isClosing !== true &&
      typeof result === 'boolean') {

      // Set Deezer favourite state
      modal.isFavourite = result === true

      // Set UI properties
      modal.can.deezer = libType === BAS_LIBRARY.TYPE_DEEZER
      modal.can.spotify = libType === BAS_LIBRARY.TYPE_SPOTIFY
      modal.can.tidal = libType === BAS_LIBRARY.TYPE_TIDAL
      modal.can.favourite = true

      // Check if height was calculated already
      if (heightIsCalculated) calcHeight()
    }
  }

  // endregion

  // region Height Calculations

  function calcHeight () {

    // Height is calculated from now on
    heightIsCalculated = true

    // Calculate modal height
    modalHelperService.calcHeight(2, numberOfItems())

    $scope.$applyAsync()
  }

  /**
   * @returns {number}
   */
  function numberOfItems () {

    var keys, i, length
    var count = 0

    if (modal.showPlaylists === true &&
      Array.isArray(modal.playlists)) {

      return isPlaylist() && modal.playlists.length > 0
        ? modal.playlists.length + 1
        : modal.playlists.length + 2
    }

    keys = Object.keys(modal.can)
    length = keys.length

    for (i = 0; i < length; i += 1) {

      if (modal.can[keys[i]] === true) {

        count += 1
      }
    }

    return count
  }

  // endregion

  // region Helper

  function isPlaylist () {

    return (libTypeHelper && libTypeHelper.isPlaylist)
      ? libTypeHelper.isPlaylist(element)
      : false
  }

  function onModalReady () {

    BasUtilities.waitFrames(5).then(calcHeight)
  }

  function clearCloseDelay () {

    return $timeout.cancel(delayPromise)
  }

  /**
   * @returns {?Object}
   */
  function getManager () {

    var currentState = libState.current

    if (currentState) {

      switch (libType) {
        case BAS_LIBRARY.TYPE_LOCAL:
          return currentState.localManager
        case BAS_LIBRARY.TYPE_DEEZER:
          return currentState.deezerManager
        case BAS_LIBRARY.TYPE_SPOTIFY:
          return currentState.spotifyManager
        case BAS_LIBRARY.TYPE_TIDAL:
          return currentState.tidalManager
      }
    }

    return null
  }

  // endregion

  // region Close

  function closeModal () {

    // Set flag
    isClosing = true

    // Close the modal
    close()
  }

  function clearSelection () {

    if (selection) selection.clear()

    // Set flag
    isClosing = true

    // Close the modal
    close()
  }

  function delayedClose () {

    // Make sure previous close was cancelled
    clearCloseDelay()

    // Set new delay
    delayPromise = $timeout(closeModal, CLOSE_DELAY)
  }

  // endregion
}
