'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('alarmService', [
    '$rootScope',
    '$translate',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_SOURCE',
    'BAS_SOURCES',
    'BAS_ROOMS',
    'BAS_FAVOURITE',
    'BAS_INTL',
    'BAS_MODAL',
    'BasIntl',
    'CurrentBasCore',
    'BasModal',
    'Sources',
    'SourcesHelper',
    'BasPreferences',
    'BasUtilities',
    Alarms
  ])

/**
 * @param $rootScope
 * @param $translate
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasIntl} BasIntl
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasModal} BasModal
 * @param {Sources} Sources
 * @param {SourcesHelper} SourcesHelper
 * @param {BasPreferences} BasPreferences
 * @param {BasUtilities} BasUtilities
 */
function Alarms (
  $rootScope,
  $translate,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_SOURCE,
  BAS_SOURCES,
  BAS_ROOMS,
  BAS_FAVOURITE,
  BAS_INTL,
  BAS_MODAL,
  BasIntl,
  CurrentBasCore,
  BasModal,
  Sources,
  SourcesHelper,
  BasPreferences,
  BasUtilities
) {
  var ID_ALARMS = 0
  var ID_ALARM_INFO = 1

  var TYPE_REPEATED = 'repeated'
  var TYPE_DATETIME = 'datetime'

  var CSS_WRONG_TIME = 'alarm-main-wrong-time'
  var CSS_WRONG_ZONE = 'alarm-main-wrong-zone'
  var CSS_WRONG_SOURCE = 'alarm-main-wrong-source'
  var CSS_WRONG_MUSIC = 'alarm-main-wrong-music'

  var STATE_RESUMED = 'resumed'
  var STATE_SUSPENDED = 'suspended'
  var state = STATE_SUSPENDED

  var firstDayWeekIndex = 0
  var lastLanguage

  var daysWeek = [
    {
      fullKey: 'day_sunday',
      shortKey: 'day_short2_sunday',
      full: 'sunday',
      short: 'sunday_short'
    },
    {
      fullKey: 'day_monday',
      shortKey: 'day_short2_monday',
      full: 'monday',
      short: 'monday_short'
    },
    {
      fullKey: 'day_tuesday',
      shortKey: 'day_short2_tuesday',
      full: 'tuesday',
      short: 'tuesday_short'
    },
    {
      fullKey: 'day_wednesday',
      shortKey: 'day_short2_wednesday',
      full: 'wednesday',
      short: 'wednesday_short'
    },
    {
      fullKey: 'day_thursday',
      shortKey: 'day_short2_thursday',
      full: 'thursday',
      short: 'thursday_short'
    },
    {
      fullKey: 'day_friday',
      shortKey: 'day_short2_friday',
      full: 'friday',
      short: 'friday_short'
    },
    {
      fullKey: 'day_saturday',
      shortKey: 'day_short2_saturday',
      full: 'saturday',
      short: 'saturday_short'
    }
  ]

  var localesDaysWeek = [
    0,
    1,
    2,
    3,
    4,
    5,
    6
  ]

  var listeners = []
  var userDeregistration
  var playerDeregistration = []

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  var user = null

  var inputTimeout

  var alarmWrapper = []

  var alarmControl = {}

  var alarmsLoadedCallback = function () {
  }
  var newAlarmCallback = function () {
  }

  var ID_MUSIC_PLAYLISTS = 0
  var ID_MUSIC_RADIOS = 1
  var ID_MUSIC_DEEZER = 2
  var ID_MUSIC_TIDAL = 3
  var ID_MUSIC_SPOTIFY = 4

  this.ID_MUSIC_PLAYLISTS = ID_MUSIC_PLAYLISTS
  this.ID_MUSIC_RADIOS = ID_MUSIC_RADIOS
  this.ID_MUSIC_DEEZER = ID_MUSIC_DEEZER
  this.ID_MUSIC_TIDAL = ID_MUSIC_TIDAL
  this.ID_MUSIC_SPOTIFY = ID_MUSIC_SPOTIFY

  init()

  function init () {

    clearAlarmWrapper()
  }

  function sortAlarmArray (a, b) {
    if (a.id && b.id) {
      return a.id - b.id
    } else {
      return 0
    }
  }

  function onTimeFormatChanged () {

    processAlarmInfo()
  }

  // Cleanup functions

  function clearAlarmWrapper (type) {
    switch (type) {
      case ID_ALARMS:
        alarmWrapper[ID_ALARMS] = []
        break
      case ID_ALARM_INFO:
        alarmWrapper[ID_ALARM_INFO] = {}
        break
      default:
        alarmWrapper[ID_ALARMS] = []
        alarmWrapper[ID_ALARM_INFO] = {}
    }
  }

  function clearPlayerListeners () {

    BasUtil.executeArray(playerDeregistration)
    playerDeregistration = []
  }

  function clearUser () {

    // Remove user listener
    if (BasUtil.isFunction(userDeregistration)) {

      userDeregistration()
      userDeregistration = null
    }

    // Clear reference
    user = null
  }

  function clearAlarmsLoadedCallback () {
    alarmsLoadedCallback = function () {
    }
  }

  function clearNewAlarmCallback () {
    newAlarmCallback = function () {
    }
  }

  function cleanUp () {
    clearAlarmsLoadedCallback()
    clearNewAlarmCallback()
    clearAlarmWrapper()
    clearUser()
    clearPlayerListeners()
  }

  // Locale functions

  function generateLocaleDaysWeek () {

    var length, i, dayIndex

    length = 7
    for (i = 0; i < length; i += 1) {

      dayIndex = firstDayWeekIndex + i
      if (dayIndex >= length) dayIndex = 0
      localesDaysWeek[i] = dayIndex
    }
  }

  function onFirstDayOfWeek (result) {

    firstDayWeekIndex = result
    generateLocaleDaysWeek()
  }

  function translateDays () {

    var length, i, day

    for (i = 0, length = daysWeek.length; i < length; i += 1) {
      day = daysWeek[i]
      day.full = BasUtilities.translate(day.fullKey)
      day.short = BasUtilities.translate(day.shortKey)
    }
  }

  function updateTranslations () {

    var language

    language = BasPreferences.getLanguage()

    if (lastLanguage !== language) {

      generateDays()
      getUser()
      updateAlarms()
      lastLanguage = language
    }
  }

  function generateDays () {

    onFirstDayOfWeek(BasIntl.getFirstDayOfWeek())

    // Translate all days
    $translate.onReady(translateDays)
  }

  function createRepeatedDaysArray (info) {

    if (info && info.repeatedDays) {

      info.repeatedDays[0] = false
      info.repeatedDays[1] = false
      info.repeatedDays[2] = false
      info.repeatedDays[3] = false
      info.repeatedDays[4] = false
      info.repeatedDays[5] = false
      info.repeatedDays[6] = false
    }
  }

  function setDaysFromDaysArray (object, daysArray) {

    if (object && Array.isArray(daysArray)) {

      object.sunday = daysArray[0]
      object.monday = daysArray[1]
      object.tuesday = daysArray[2]
      object.wednesday = daysArray[3]
      object.thursday = daysArray[4]
      object.friday = daysArray[5]
      object.saturday = daysArray[6]
    }
  }

  function getDaysString (alarm, info) {
    var days, daysLength
    days = ''

    if (alarm && alarm.type === TYPE_REPEATED) {

      if (firstDayWeekIndex === 0) {
        if (alarm.sunday) {
          days += daysWeek[0].short + ' '
          info.repeatedDays[0] = true
        }

        if (alarm.monday) {
          days += daysWeek[1].short + ' '
          info.repeatedDays[1] = true
        }

        if (alarm.tuesday) {
          days += daysWeek[2].short + ' '
          info.repeatedDays[2] = true
        }

        if (alarm.wednesday) {
          days += daysWeek[3].short + ' '
          info.repeatedDays[3] = true
        }

        if (alarm.thursday) {
          days += daysWeek[4].short + ' '
          info.repeatedDays[4] = true
        }

        if (alarm.friday) {
          days += daysWeek[5].short + ' '
          info.repeatedDays[5] = true
        }

        if (alarm.saturday) {
          days += daysWeek[6].short + ' '
          info.repeatedDays[6] = true
        }
      } else if (firstDayWeekIndex === 1) {
        if (alarm.monday) {
          days += daysWeek[1].short + ' '
          info.repeatedDays[1] = true
        }

        if (alarm.tuesday) {
          days += daysWeek[2].short + ' '
          info.repeatedDays[2] = true
        }

        if (alarm.wednesday) {
          days += daysWeek[3].short + ' '
          info.repeatedDays[3] = true
        }

        if (alarm.thursday) {
          days += daysWeek[4].short + ' '
          info.repeatedDays[4] = true
        }

        if (alarm.friday) {
          days += daysWeek[5].short + ' '
          info.repeatedDays[5] = true
        }

        if (alarm.saturday) {
          days += daysWeek[6].short + ' '
          info.repeatedDays[6] = true
        }

        if (alarm.sunday) {
          days += daysWeek[0].short + ' '
          info.repeatedDays[0] = true
        }
      }
    }

    daysLength = days.length
    if (daysLength) days = days.substring(0, daysLength - 1)

    return days
  }

  function onDateStringComplete (alarm, info, result) {

    if (alarm && info && result) {

      info.nameTotal = result + ' ' + info.repeatedDaysString
      info.name = result

      if (info.type === TYPE_DATETIME) {
        info.typeString = info.name
      } else if (info.type === TYPE_REPEATED) {
        info.typeString = info.repeatedDaysString
      }

      $rootScope.$applyAsync()
    }
  }

  // Alarms helper functions

  function getAlarmForId (alarmId) {

    var i, length

    // Check parameter
    if (
      typeof alarmId === 'number' &&
      Array.isArray(alarmWrapper[ID_ALARMS])
    ) {

      // Iterate over alarms
      length = alarmWrapper[ID_ALARMS].length
      for (i = 0; i < length; i += 1) {

        // Check if alarm matches ID
        if (alarmWrapper[ID_ALARMS][i] &&
          alarmId === alarmWrapper[ID_ALARMS][i].id) {
          return i
        }
      }
    }

    return -1
  }

  function zonesStringGenerator (zonesArray) {

    var zonesString, zonesStringLength, room, i, length

    zonesString = ''
    zonesStringLength = 0

    // Check if argument are valid
    if (Array.isArray(zonesArray)) {

      length = zonesArray.length
      for (i = 0; i < length; i += 1) {

        if (BasUtil.isNEString(zonesArray[i])) {

          room = BAS_ROOMS.ROOMS.rooms[zonesArray[i]]

          if (room && room.isRoom) zonesString += room.uiTitle + ' '
        }
      }

      zonesStringLength = zonesString.length

      if (zonesStringLength) {

        zonesString = zonesString.substring(0, zonesStringLength - 1)
      }
    }

    return zonesString
  }

  /**
   * @param {number} playerId
   * @returns {string}
   */
  function getPlayerName (playerId) {

    var player, playerName

    playerName = ''

    if (BasUtil.isPNumber(playerId)) {

      if (CurrentBasCore.hasCore()) {

        player = currentBasCoreState.core.core.playerForId(playerId)

        if (
          BasUtil.isObject(player) &&
          BasUtil.isNEString(player.name)
        ) {
          playerName = player.name
        }
      }
    }

    return playerName
  }

  function getSourceName (info, alarm) {

    var basSource, alarmMusicId, alarmMusicType, favourite

    if (info && alarm) {

      basSource = SourcesHelper.getPlayer(alarm.player)

      if (
        basSource &&
        basSource.favourites &&
        basSource.favourites.getFavouriteFromAlarmId
      ) {

        alarmMusicId =
          alarm.playlist ||
          alarm.tunein_gid ||
          alarm.deezer_id ||
          alarm.tidal_id ||
          alarm.spotify_preset ||
          ''

        if (alarm.playlist) {

          alarmMusicType = BAS_FAVOURITE.T_LOCAL_PLAYLIST

        } else if (alarm.tunein_gid) {

          alarmMusicType = BAS_FAVOURITE.T_RADIO

        } else if (alarm.deezer_id) {

          alarmMusicType = BAS_FAVOURITE.T_DEEZER

        } else if (alarm.tidal_id) {

          alarmMusicType = BAS_FAVOURITE.T_TIDAL

        } else if (alarm.spotify_preset) {

          alarmMusicType = BAS_FAVOURITE.T_SPOTIFY_CONNECT
        }

        favourite = basSource.favourites.getFavouriteFromAlarmId(
          alarmMusicId,
          alarmMusicType
        )

        if (favourite && favourite.ui && favourite.ui.title) {

          info.sourceName = favourite.ui.title
        }
      }
    }
  }

  function processAlarmZones (info) {

    var length, i

    if (info && Array.isArray(info.zones)) {

      info.zonesObj = {}
      length = info.zones.length
      for (i = 0; i < length; i += 1) info.zonesObj[info.zones[i]] = true
    }
  }

  function copyZones (info, alarm) {

    var length, i

    // Initiate zones array
    info.zones = []

    // Deep copy zones
    if (alarm && Array.isArray(alarm.zones)) {

      length = alarm.zones.length
      for (i = 0; i < length; i += 1) info.zones.push(alarm.zones[i])
    }
  }

  function checkAlarmEnable (alarmInfo) {
    var now

    if (alarmInfo) {

      // Check if at least a zone, source and a music source is selected
      alarmInfo.canEnable = !!(
        BasUtil.isNEArray(alarmInfo.zones) &&
        BasUtil.isPNumber(alarmInfo.player) &&
        (
          alarmInfo.tuneinId ||
          alarmInfo.playlistId ||
          alarmInfo.deezerId ||
          alarmInfo.tidalId ||
          alarmInfo.spotifyPreset
        )
      )

      // Check if time is valid
      if (alarmInfo.type === TYPE_DATETIME) {

        now = new Date()

        if (now.getTime() > alarmInfo.datetime) alarmInfo.canEnable = false
      }

      checkCss(alarmInfo)
    }
  }

  function processAlarm (info, alarm) {
    var date, dateString

    // Map alarm properties to info object
    info.id = alarm.id
    info.type = alarm.type
    info.enabled = alarm.enabled
    info.volume = alarm.volume
    info.hour = alarm.hour
    info.min = alarm.min
    info.playlistId = alarm.playlist
    info.tuneinId = alarm.tunein_gid
    info.deezerId = alarm.deezer_id
    info.tidalId = alarm.tidal_id
    info.spotifyPreset = alarm.spotify_preset
    info.datetime = alarm.datetime
    info.player = alarm.player
    info.css = {}

    // Deep copy zones
    copyZones(info, alarm)

    // Create a zonesObj for easy Id mapping
    processAlarmZones(info)

    // Display name
    info.nameTotal = ''
    info.name = ''

    // Create repeatedDays array
    info.repeatedDays = []
    createRepeatedDaysArray(info)

    // Create repeatedDays string
    info.repeatedDaysString = getDaysString(alarm, info)

    // Attributes
    info.zonesString = zonesStringGenerator(alarm.zones)
    info.playerName = getPlayerName(alarm.player)
    info.sourceName = ''
    getSourceName(info, alarm)

    // Check alarm type for name
    if (alarm.type === TYPE_DATETIME) {

      // Set type title for UI
      info.typeTitle = BasUtilities.translate('date')

      date = new Date(alarm.datetime)

      info.date = date

      // Don't take timezone into account, this date object is correctly
      //  represented in the current system timezone, since it was set using
      //  already timezone adjusted values

      info.uiDate = BasIntl.dateToString(
        info.date,
        BAS_INTL.LOCALE_OPTION_SHORT,
        false
      )

      dateString = BasIntl.dateToString(
        date,
        BAS_INTL.LOCALE_OPTION_LONG,
        false
      )

      onDateStringComplete(alarm, info, dateString)

    } else if (alarm.type === TYPE_REPEATED) {

      // Set type title for UI
      info.typeTitle = BasUtilities.translate('repeat')

      date = new Date()
      date.setHours(alarm.hour)
      date.setMinutes(alarm.min)
      date.setSeconds(0)
      date.setMilliseconds(0)

      info.date = date

      // Don't take timezone into account, this date object is correctly
      //  represented in the current system timezone, since it was set using
      //  already timezone adjusted values

      info.uiDate = BasIntl.dateToString(
        info.date,
        BAS_INTL.LOCALE_OPTION_SHORT,
        false
      )

      dateString = BasIntl.dateToString(
        date,
        BAS_INTL.LOCALE_OPTION_SHORT,
        false
      )

      onDateStringComplete(alarm, info, dateString)
    }

    // Check if alarm is valid to enable
    checkAlarmEnable(info)

    // Set alarm dirty state to false
    info.dirty = false
  }

  function processAlarmInfo () {
    var i, length, info, alarm

    // Iterate alarms
    length = alarmWrapper[ID_ALARMS].length
    for (i = 0; i < length; i += 1) {

      // Set temp variables
      info = {}
      alarm = alarmWrapper[ID_ALARMS][i]

      if (alarm) {

        // Populate alarmInfo object
        processAlarm(info, alarm)

        // Add alarmInfo object
        alarmWrapper[ID_ALARM_INFO][alarm.id] = info
      }
    }
  }

  function onAlarmsRetrieved (result) {

    if (Array.isArray(result)) {

      alarmWrapper[ID_ALARMS] = result

      // Sort alarms ascending depending on alarm.id
      alarmWrapper[ID_ALARMS].sort(sortAlarmArray)

      processAlarmInfo()
    }

    alarmsLoadedCallback()

    $rootScope.$applyAsync()
  }

  function getUser () {

    clearUser()

    user = CurrentBasCore.has()
      ? currentBasCoreState.core.getUser()
      : null

    // Check scope and user are valid
    if (user) {

      // Register listener - EVT_ALARMS_CHANGED
      userDeregistration = BasUtil.setEventListener(
        user,
        BAS_API.User.EVT_ALARMS_CHANGED,
        updateAlarms
      )
    }
  }

  function retrieveAlarms () {

    // Clear previous alarms
    clearAlarmWrapper()

    // Retrieve alarms
    if (user) {

      user.retrieveAlarms()
        .then(onAlarmsRetrieved)
        .catch(_ignore)
    }
  }

  function updateAlarms () {
    var currentUser

    // Check if core is valid
    if (CurrentBasCore.has()) {

      // Get a reference to current user from core
      currentUser = currentBasCoreState.core.getUser()
    }

    // If the connection was restored a new user was created.
    // Check if reference still points to the same user
    if (user !== currentUser) {

      // Clear previous user and listener
      clearUser()
    }

    if (!user) getUser()
    retrieveAlarms()
  }

  // Alarms controls

  function getZonesArray (zonesObj) {
    var zonesArray = []
    var i, length, keys

    if (zonesObj) {

      keys = Object.keys(zonesObj)
      length = keys.length
      for (i = 0; i < length; i += 1) {

        if (zonesObj[keys[i]] === true) zonesArray.push(keys[i])
      }
    }

    return zonesArray
  }

  function fillAlarmObject (alarm, info) {

    // Populate alarm object
    alarm.id = info.id
    alarm.type = info.type
    alarm.enabled = info.enabled
    alarm.hour = info.hour
    alarm.min = info.min
    alarm.player = info.player
    alarm.playlist = info.playlistId
    alarm.tunein_gid = info.tuneinId
    alarm.deezer_id = info.deezerId
    alarm.tidal_id = info.tidalId
    alarm.spotify_preset = info.spotifyPreset

    // Make sure that volume is of type 'number'
    alarm.volume = parseInt(info.volume)

    // Map date object to datetime for API
    alarm.datetime = info.date

    // Update zones array
    info.zones = getZonesArray(info.zonesObj)
    alarm.zones = info.zones

    setDaysFromDaysArray(alarm, info.repeatedDays)
  }

  function syncAlarmInfo (infoObject) {

    // Alarm object to pass to the API
    var alarm = {}

    if (infoObject) {
      fillAlarmObject(alarm, infoObject)
    }

    return alarm
  }

  function _onMusicConfig () {

    if (CurrentBasCore.hasCore()) {

      if (CurrentBasCore.hasAVFullSupport()) {

        if (
          currentBasCoreState.core.core.roomsReceived &&
          currentBasCoreState.core.core.avSourcesReceived
        ) {
          _syncAlarms()
        }

      } else {

        if (
          currentBasCoreState.core.core.musicConfigReceived
        ) {
          _syncAlarms()
        }
      }
    }
  }

  function _syncAlarms () {

    getUser()
    updateAlarms()
  }

  function _onServerSet () {

    cleanUp()
  }

  function _onUserCreated () {

    if (CurrentBasCore.hasCore()) {

      getUser()
      updateAlarms()
    }
  }

  function onUpdateSuccess (_method, _result) {

    // Empty
  }

  function onUpdateFail (_method, error) {

    if (error === BAS_API.CONSTANTS.ERR_NO_PERMISSION) {

      BasModal.show(BAS_MODAL.T_NO_PERMISSION)
    }
  }

  alarmControl.new = function () {

    var newAlarm, roomId

    newAlarm = {}
    newAlarm.enabled = false
    newAlarm.type = TYPE_DATETIME
    newAlarm.datetime = new Date()
    newAlarm.datetime.setSeconds(0)
    newAlarm.datetime.setMilliseconds(0)
    newAlarm.volume = 30
    newAlarm.zones = []

    if (CurrentBasCore.hasCore()) {

      roomId = currentBasCoreState.core.core.singleAudioRoomId

      if (roomId) {

        newAlarm.zones.push(roomId)
      }

      if (currentBasCoreState.core.core.hasSingleSource()) {

        newAlarm.player = currentBasCoreState.core.core.singleSourceId
      }
    }

    if (user) {

      user.updateAlarm(newAlarm)
        .then(
          onUpdateSuccess.bind(null, 'new'),
          onUpdateFail.bind(null, 'new')
        )
        .then(onNewAlarm)
    }
  }

  function onNewAlarm () {

    newAlarmCallback()
  }

  function updateInfo (alarmId) {

    var info, alarmUpdated

    // Get alarm reference
    info = alarmWrapper[ID_ALARM_INFO][alarmId]

    if (info && user) {

      // Get update alarm object for API
      alarmUpdated = syncAlarmInfo(info)

      // Update the alarm
      user.updateAlarm(alarmUpdated)
        .then(onUpdateSuccess.bind(null, 'updateInfo'))
        .catch(onUpdateFail.bind(null, 'updateInfo'))
    }
  }

  alarmControl.updateInfo = updateInfo

  function onUpdateEnable (alarmId, override) {
    var info = alarmWrapper[ID_ALARM_INFO][alarmId]
    var alarmUpdated

    if (info && user) {

      // Enabled
      if (typeof override === 'boolean') {
        info.enabled = override
      } else {
        info.enabled = !info.enabled
      }

      // Create API compatible alarm object
      alarmUpdated = syncAlarmInfo(info)

      // Update the alarm
      user.updateAlarm(alarmUpdated)
        .then(onUpdateSuccess.bind(null, 'enable after dirty'))
        .catch(onUpdateFail.bind(null, 'enable after dirty'))
    }
  }

  alarmControl.enable = function (alarmId, override) {
    var info = alarmWrapper[ID_ALARM_INFO][alarmId]
    var alarmUpdated

    if (info && user) {

      // Check if enable is valid
      if (info.canEnable) {

        // Check if alarm is dirty and needs to be updated first
        if (info.dirty) {

          // Create API compatible alarm object
          alarmUpdated = syncAlarmInfo(info)

          // Update the alarm
          user.updateAlarm(alarmUpdated)
            .then(onUpdateEnable.bind(null, alarmId, override))
            .catch(onUpdateFail.bind(null, 'enable - dirty'))
        } else {

          // Enabled
          if (typeof override === 'boolean') {
            info.enabled = override
          } else {
            info.enabled = !info.enabled
          }

          // Create API compatible alarm object
          alarmUpdated = syncAlarmInfo(info)

          // Update the alarm
          user.updateAlarm(alarmUpdated)
            .then(onUpdateSuccess.bind(null, 'enable'))
            .catch(onUpdateFail.bind(null, 'enable'))
        }
      } else {

        checkCss(info)
      }
    }
  }

  function checkCss (info) {

    var now

    resetCss(info)

    if (info.type === TYPE_DATETIME) {

      now = new Date()
      info.css[CSS_WRONG_TIME] = now.getTime() > info.datetime

    } else if (info.type === TYPE_REPEATED) {

      info.css[CSS_WRONG_TIME] =
        !info.repeatedDays ||
        !(
          info.repeatedDays[0] ||
          info.repeatedDays[1] ||
          info.repeatedDays[2] ||
          info.repeatedDays[3] ||
          info.repeatedDays[4] ||
          info.repeatedDays[5] ||
          info.repeatedDays[6]
        )
    }

    info.css[CSS_WRONG_ZONE] = !BasUtil.isNEArray(info.zones)
    info.css[CSS_WRONG_SOURCE] = !BasUtil.isPNumber(info.player)
    info.css[CSS_WRONG_MUSIC] = !(
      info.playlistId ||
      info.tuneinId ||
      info.deezerId ||
      info.spotifyPreset ||
      info.tidalId
    )

    $rootScope.$applyAsync()
  }

  function resetCss (info) {

    info.css[CSS_WRONG_TIME] = true
    info.css[CSS_WRONG_ZONE] = true
    info.css[CSS_WRONG_SOURCE] = true
    info.css[CSS_WRONG_MUSIC] = true
  }

  alarmControl.remove = function (alarmId) {

    var info

    info = alarmWrapper[ID_ALARM_INFO][alarmId]
    if (info && user) {

      // Show confirmation modal
      BasModal.show(BAS_MODAL.T_REMOVE)
        .then(onRemoveModalShown.bind(null, alarmId))
        .catch(_ignore)
    }
  }

  function onRemoveModalShown (alarmId, modal) {
    modal.close
      .then(onRemoveModalClose.bind(null, alarmId))
      .catch(_ignore)
  }

  function onRemoveModalClose (alarmId, result) {

    if (result === BAS_MODAL.C_YES) {

      user.removeAlarm(alarmId)
      removeAlarmId(alarmId)
    }
  }

  function removeAlarmId (alarmId) {

    var length, i

    length = alarmWrapper[ID_ALARMS].length
    for (i = 0; i < length; i++) {

      if (alarmWrapper[ID_ALARMS][i].id === alarmId) {

        alarmWrapper[ID_ALARMS].splice(i, 1)
        break
      }
    }

    alarmsLoadedCallback()
  }

  alarmControl.updateTime = function (alarmId, noUpdate) {

    clearTimeout(inputTimeout)

    inputTimeout = setTimeout(
      setTime.bind(null, alarmId, noUpdate),
      500
    )
  }

  function setTime (alarmId, noUpdate) {
    var info, alarmUpdated

    info = alarmWrapper[ID_ALARM_INFO][alarmId]

    if (info && user) {

      // Set hour and minutes
      info.hour = info.date.getHours()
      info.min = info.date.getMinutes()

      if (info.type === TYPE_DATETIME) info.datetime = info.date.getTime()

      if (!noUpdate) {

        alarmUpdated = syncAlarmInfo(info)

        // Update the alarm
        user.updateAlarm(alarmUpdated)
          .then(onUpdateSuccess.bind(null, 'updateTime'))
          .catch(onUpdateFail.bind(null, 'updateTime'))

      } else {

        // Check alarm enable state
        checkAlarmEnable(info)

        // Set alarm dirty
        info.dirty = true
      }
    }
  }

  alarmControl.type = function (alarmId, noUpdate) {
    var info, alarmUpdated

    info = alarmWrapper[ID_ALARM_INFO][alarmId]
    if (info && user) {

      if (info.type === TYPE_DATETIME) {

        info.type = TYPE_REPEATED

        // Set hour and minutes
        info.hour = info.date.getHours()
        info.min = info.date.getMinutes()

      } else if (info.type === TYPE_REPEATED) {

        info.type = TYPE_DATETIME
      }

      if (!noUpdate) {

        alarmUpdated = syncAlarmInfo(info)

        // Update the alarm
        user.updateAlarm(alarmUpdated)
          .then(onUpdateSuccess.bind(null, 'type'))
          .catch(onUpdateFail.bind(null, 'type'))

      } else {

        // Check alarm enable state
        checkAlarmEnable(info)

        // Set alarm dirty
        info.dirty = true
      }
    }
  }

  alarmControl.day = function (alarmId, dayIndex, noUpdate) {
    var info, alarmUpdated

    info = alarmWrapper[ID_ALARM_INFO][alarmId]
    if (info && user) {

      // Toggle day
      info.repeatedDays[dayIndex] = !info.repeatedDays[dayIndex]

      if (!noUpdate) {
        alarmUpdated = syncAlarmInfo(info)

        // Update the alarm
        user.updateAlarm(alarmUpdated)
          .then(onUpdateSuccess.bind(null, 'day'))
          .catch(onUpdateFail.bind(null, 'day'))

      } else {

        // Check alarm enable state
        checkAlarmEnable(info)

        // Set alarm dirty
        info.dirty = true
      }
    }
  }

  alarmControl.volume = function (alarmId) {
    var info, alarmUpdated

    info = alarmWrapper[ID_ALARM_INFO][alarmId]
    if (info && user) {

      alarmUpdated = syncAlarmInfo(info)

      // Update the alarm
      user.updateAlarm(alarmUpdated)
        .then(onUpdateSuccess.bind(null, 'volume'))
        .catch(onUpdateFail.bind(null, 'volume'))
    }
  }

  alarmControl.setZones = function (alarmId) {
    var info, alarmUpdated

    info = alarmWrapper[ID_ALARM_INFO][alarmId]
    if (info && user) {

      alarmUpdated = syncAlarmInfo(info)

      // Update the alarm
      user.updateAlarm(alarmUpdated)
        .then(onUpdateSuccess.bind(null, 'setZones'))
        .catch(onUpdateFail.bind(null, 'setZones'))
    }
  }

  alarmControl.setPlayer = function (alarmId, playerId, noUpdate) {
    var info = alarmWrapper[ID_ALARM_INFO][alarmId]
    var alarmUpdated

    if (info && user) {

      // Set the playerId
      info.player = playerId

      if (!noUpdate) {

        // Create alarm object for API
        alarmUpdated = syncAlarmInfo(info)

        // Update the alarm
        user.updateAlarm(alarmUpdated)
          .then(onUpdateSuccess.bind(null, 'setPlayer'))
          .catch(onUpdateFail.bind(null, 'setPlayer'))

      } else {

        // Check alarm enable state
        checkAlarmEnable(info)

        // Set dirty flag
        info.dirty = true
      }
    }
  }

  alarmControl.setMusic = function (alarmId, musicId, musicType, noUpdate) {
    var info = alarmWrapper[ID_ALARM_INFO][alarmId]
    var alarmUpdated

    if (info && user) {

      // Clear previous music choice
      info.playlistId = null
      info.tuneinId = null
      info.deezerId = null
      info.tidalId = null
      info.spotifyPreset = null

      switch (musicType) {
        case ID_MUSIC_PLAYLISTS:
          info.playlistId = musicId
          break
        case ID_MUSIC_RADIOS:
          info.tuneinId = musicId
          break
        case ID_MUSIC_DEEZER:
          info.deezerId = musicId
          break
        case ID_MUSIC_TIDAL:
          info.tidalId = musicId
          break
        case ID_MUSIC_SPOTIFY:
          info.spotifyPreset = musicId
          break
      }

      if (!noUpdate) {

        // Create object for API
        alarmUpdated = syncAlarmInfo(info)

        // Update the alarm
        user.updateAlarm(alarmUpdated)
          .then(onUpdateSuccess.bind(null, 'setMusic'))
          .catch(onUpdateFail.bind(null, 'setMusic'))

      } else {

        // Check alarm enable state
        checkAlarmEnable(info)

        // Set dirty flag
        info.dirty = true
      }
    }
  }

  function getCopyZones (alarmId) {

    var info, zonesObj, i, length, keys

    info = alarmWrapper[ID_ALARM_INFO][alarmId]
    zonesObj = {}

    if (info) {

      // Copy the zonesObj into a new obj (deep copy)
      keys = Object.keys(info.zonesObj)
      length = keys.length
      for (i = 0; i < length; i += 1) {

        if (typeof info.zonesObj[keys[i]] === 'boolean') {
          zonesObj[keys[i]] = info.zonesObj[keys[i]]
        }
      }
    }

    return zonesObj
  }

  function compareZones (zonesObj1, zonesObj2) {

    var i, length, keys1, keys2

    if (zonesObj1 && zonesObj2) {

      keys1 = Object.keys(zonesObj1)
      keys2 = Object.keys(zonesObj2)

      // Check keys 1
      length = keys1.length
      for (i = 0; i < length; i += 1) {

        // Check if key is true for zonesObj1
        if (
          typeof zonesObj1[keys1[i]] === 'boolean' &&
          zonesObj1[keys1[i]]
        ) {
          // Check if true for zonesObj2
          if (!zonesObj2[keys1[i]]) {
            return false
          }
        }
      }

      // Check keys 2
      length = keys2.length
      for (i = 0; i < length; i += 1) {

        // Check if key is true for zonesObj2
        if (
          typeof zonesObj2[keys2[i]] === 'boolean' &&
          zonesObj2[keys2[i]]
        ) {
          // Check if true for zonesObj1
          if (!zonesObj1[keys2[i]]) {
            return false
          }
        }
      }

      // Objects contain the same true keys
      return true

    } else {
      return false
    }
  }

  /**
   * ID_ALARMS
   *
   * @type {number}
   */
  this.ID_ALARMS = ID_ALARMS

  /**
   * ID_ALARM_INFO
   *
   * @type {number}
   */
  this.ID_ALARM_INFO = ID_ALARM_INFO

  /**
   * TYPE_DATETIME
   *
   * @type {number}
   */
  this.TYPE_DATETIME = TYPE_DATETIME

  /**
   * TYPE_REPEATED
   *
   * @type {number}
   */
  this.TYPE_REPEATED = TYPE_REPEATED

  /**
   * Set alarms loaded callback
   *
   * This callback will be called whenever alarms have been loaded
   *
   * @param callback
   */
  this.setAlarmsLoadedCallback = function (callback) {
    clearAlarmsLoadedCallback()

    if (BasUtil.isFunction(callback)) alarmsLoadedCallback = callback
  }

  /**
   * New alarm callback
   *
   * This callback will be called whenever a new alarm is created
   *
   * @param callback
   */
  this.setNewAlarmCallback = function (callback) {
    clearNewAlarmCallback()

    if (BasUtil.isFunction(callback)) newAlarmCallback = callback
  }

  /**
   * Get the daysWeek
   *
   * @returns {Array}
   */
  this.getDaysWeek = function () {
    return daysWeek
  }

  /**
   * Get the locale daysWeek mapping
   *
   * @returns {Array}
   */
  this.getLocalDaysWeek = function () {
    return localesDaysWeek
  }

  /**
   * Get the alarmWrapper
   *
   * @returns {Array} alarmWrapper
   */
  this.getAlarmWrapper = function () {
    return alarmWrapper
  }

  /**
   * Get alarm control
   *
   * @returns {Object}
   */
  this.getAlarmControl = function () {
    return alarmControl
  }

  /**
   * Get copy of zonesObj
   *
   * @param {number} alarmId
   */
  this.getCopyZones = getCopyZones

  /**
   * Compare 2 zonesObjects for equality on zone selection
   *
   * @param {Object} zonesObj1
   * @param {Object} zonesObj2
   */
  this.compareZones = compareZones

  /**
   * Get the index of the requested alarmId
   *
   * @param {number} alarmId
   */
  this.getAlarmForId = getAlarmForId

  /**
   * Suspend listening to events
   */
  this.suspend = function () {

    if (state !== STATE_SUSPENDED) {

      Sources.unregisterFor(BAS_SOURCE.COL_EVT_FAVOURITES)

      BasUtil.executeArray(listeners)
      listeners = []

      cleanUp()
      state = STATE_SUSPENDED
    }
  }

  /**
   * Resume listening to events
   */
  this.resume = function () {

    if (state !== STATE_RESUMED) {

      Sources.registerFor(BAS_SOURCE.COL_EVT_FAVOURITES)

      listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED,
        _onServerSet
      ))
      listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_USER_CREATED,
        _onUserCreated
      ))
      listeners.push($rootScope.$on(
        BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
        _onMusicConfig
      ))
      listeners.push($rootScope.$on(
        BAS_ROOMS.EVT_ROOMS_UPDATED,
        _onMusicConfig
      ))
      listeners.push($rootScope.$on(
        BAS_SOURCES.EVT_SOURCES_UPDATED,
        _onMusicConfig
      ))
      listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_FAVOURITES_UPDATED,
        _onMusicConfig
      ))
      listeners.push($rootScope.$on(
        '$translateChangeSuccess',
        updateTranslations
      ))
      listeners.push($rootScope.$on(
        BAS_INTL.EVT_TIME_FORMAT_CHANGED,
        onTimeFormatChanged
      ))

      _onMusicConfig()

      updateTranslations()
      state = STATE_RESUMED
    }
  }
}

function _ignore () {
  // Empty
}
